import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6822)"
            d="M494.769 223.4a2.715 2.715 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6822)"
            d="M511.859 243.487c-3.467-4.38-7.052-8.91-10.446-13.525l-.076-.103a27.638 27.638 0 00-22.095-11.101 27.273 27.273 0 00-16.377 5.426 27.533 27.533 0 00-5.678 38.474c3.404 4.582 6.681 9.336 9.851 13.933l2.187 3.159 42.874-34.356.713-.699-.953-1.208z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6822"
                x1="494.489"
                x2="418.152"
                y1="211.547"
                y2="258.39"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6822"
                x1="504.698"
                x2="428.361"
                y1="228.183"
                y2="275.026"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
