import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-2-stand-1">
        <path
            fill="#001489"
            d="M451.398 804.3v-.9c0-.1 0-.1.1-5.2 1.4-53 2.1-83 2.1-89.3-.2-17.5 3.3-45.3 11.5-67.8 3.7-10.2 4-21.6.7-32.1-14.1-44.6-31.7-110.2-39-160.5-4.1-28.3 15.6-54.7 43.9-58.8 2.5-.4 5-.5 7.5-.5 23.7 0 45.3 16 51.2 39 23.8 97.6 7 201 6.3 204-24.7 99.6-33.5 129.1-39.2 150.6-2.1 8.2-4 15.2-6.4 24.5l-.2.8-38.5-3.8Z"
        />
        <path
            fill="#009CDE"
            d="M516.596 707.801h-63v1.3c0 4.5-.4 21-1.1 49.2h50.7c3.3-12.1 7.6-27.9 13.4-50.5Zm-17.199 64.7h-47.2c-.1 5.2-.3 10.7-.4 16.6h43.2c.5-2 1.1-4.1 1.6-6.2.8-3.2 1.7-6.6 2.8-10.4Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-1-a)"
            d="M370.499 386.9c-9.2 15.9-16.1 25.8-17.5 44.3-.8 4-1.1 8-.9 12.2 1.6 38.4 8.7 89 19.4 139 .9 4.2 2.5 13.8 4 23.2.8 4.7 1.5 9.3 2.2 13.3 1.2 6.8.9 13.8-.9 20.8-5.9 24.1-7.2 59.8-2.8 83 .9 5.2 3.7 24.5 6.6 44.9 3.6 24.6 7.3 50.1 8 53.3l.2.8 41-2.8.1-.9c.9-12.8 1.5-22.9 2.2-32 2.3-34.5 6.7-55.3 13.3-160.2 8.3-28.1 13.5-101.6 15.6-134.9.7-11 5.2-21.3 12.7-29.3l24.8-29.5s24.6-11.7 33.3-45.2h-161.3Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-1-b)"
            d="M374 722.5c.9 5.2 3.7 24.5 6.6 44.9.3 1.8.5 3.6.8 5.4H433c1.3-14.2 2.9-28.3 5-50.4h-64v.1Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-1-c)"
            d="M374 722.5c.9 5.2 3.7 24.5 6.6 44.9.3 1.8.5 3.6.8 5.4H433c1.3-14.2 2.9-28.3 5-50.4h-64v.1Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-1-d)"
            d="M386 803.701h44.899c.4-6 .801-11.5 1.101-16.6h-48.4c.8 6 1.7 11.7 2.4 16.6Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-1-e)"
            d="M386 803.701h44.899c.4-6 .801-11.5 1.101-16.6h-48.4c.8 6 1.7 11.7 2.4 16.6Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-stand-1-a"
                x1={352.094}
                x2={531.613}
                y1={604.205}
                y2={604.205}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-1-b"
                x1={352.095}
                x2={531.614}
                y1={747.596}
                y2={747.596}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-1-c"
                x1={315.242}
                x2={474.412}
                y1={747.596}
                y2={747.596}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00283A" />
                <stop offset={0.009} stopColor="#004B6C" />
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-1-d"
                x1={352.094}
                x2={531.613}
                y1={795.412}
                y2={795.412}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-1-e"
                x1={314.653}
                x2={475.001}
                y1={795.412}
                y2={795.412}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00283A" />
                <stop offset={0.009} stopColor="#004B6C" />
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
