import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9438)"
            d="M494.779 223.29a2.718 2.718 0 01.821 2.483c-.805 4.666-13.808 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.207 108.207 0 0121.853 16.352z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9438)"
            d="M506.814 229.7c-4.634-2.393-9.426-4.866-14.031-7.469l-.124-.07a27.502 27.502 0 00-39.865 31.509 27.503 27.503 0 0013.051 16.513c4.122 2.301 8.131 4.723 12.216 7.211 0 0 6.215-29.066 28.753-47.694z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9438"
                x1="487.173"
                x2="466.571"
                y1="205.287"
                y2="283.793"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9438"
                x1="451.756"
                x2="564.467"
                y1="248.03"
                y2="248.03"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
