import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-3">
        <path
            d="M531.559 269.175a365.355 365.355 0 0 1-17.77-6.269 170.958 170.958 0 0 1-21.56-9.703 920.832 920.832 0 0 1-6.239-3.374c-4.718-2.567-9.598-5.222-14.483-7.638a31.797 31.797 0 0 1-16.128-18.432 31.782 31.782 0 0 1 1.63-24.438 31.824 31.824 0 0 1 28.707-17.821 31.69 31.69 0 0 1 14.164 3.324l.151.075c4.875 2.444 9.937 4.739 14.832 6.958 2.152.975 4.301 1.956 6.449 2.942a171.163 171.163 0 0 1 20.739 11.359c-4.718 7.021-13.827 40.129-10.492 63.017Z"
            fill="#001489"
        />
        <path
            d="m465.634 167.027 2.654.952c13.05 4.677 23.788 10.342 32.825 17.319a2.678 2.678 0 0 1 1.092 2.341c-.25 4.648-12.215 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
