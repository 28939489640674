import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-3-left">
        <path
            d="M500.776 185.176c-9.037-6.976-19.774-12.641-32.825-17.319l-2.654-.951L486.4 201.72l.857-.526c2.396-1.467 14.36-9.028 14.61-13.676a2.672 2.672 0 0 0-1.091-2.342Z"
            fill="#001489"
        />
        <path
            d="M508.341 285.256a508.24 508.24 0 0 1-12.562-11.403 231.243 231.243 0 0 1-19.069-19.793 987.54 987.54 0 0 1-5.354-6.303c-4.038-4.775-8.213-9.712-12.473-14.419a32 32 0 1 1 47.457-42.94l.094.104c4.244 4.734 8.723 9.406 13.054 13.923 1.905 1.987 3.806 3.978 5.703 5.972a231.271 231.271 0 0 1 17.701 21.032 498.095 498.095 0 0 1 11.428 15.668c-10.818.227-40.457 23.504-45.979 38.159Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
