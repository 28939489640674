import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-both-arms-option-3-left">
        <path
            d="M500.498 185.176c-9.037-6.976-19.774-12.641-32.826-17.319l-2.653-.951 21.103 34.814.857-.526c2.396-1.467 14.36-9.028 14.61-13.676a2.672 2.672 0 0 0-1.091-2.342Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-3-left-a)"
        />
        <path
            d="M505.415 191.173c6.087 6.79 12.5 13.287 18.771 19.91a229.158 229.158 0 0 1 17.626 20.943 507.014 507.014 0 0 1 16.028 22.381c5.116 7.666 10.07 15.476 14.495 23.764a23.178 23.178 0 0 1-18.845 34.041 23.178 23.178 0 0 1-14.592-3.925c-7.781-5.264-15.033-11.005-22.123-16.891a454.567 454.567 0 0 1-20.59-18.273 229.234 229.234 0 0 1-18.993-19.712c-5.933-6.928-11.727-13.982-17.846-20.744a31.005 31.005 0 0 1 2.188-43.786 30.988 30.988 0 0 1 34.077-5.026 30.986 30.986 0 0 1 9.709 7.213l.095.105Z"
            fill="#FCB47F"
        />
        <path
            d="M524.716 297.843a380.64 380.64 0 0 1-7.941-6.447 454.567 454.567 0 0 1-20.59-18.273 229.234 229.234 0 0 1-18.993-19.712c-5.933-6.928-11.727-13.982-17.846-20.744a31.005 31.005 0 0 1 2.188-43.786 30.988 30.988 0 0 1 34.077-5.026 30.986 30.986 0 0 1 9.709 7.213l.095.105c6.087 6.79 12.5 13.287 18.771 19.91a228.998 228.998 0 0 1 17.626 20.943 507.862 507.862 0 0 1 16.029 22.381c3.22 4.826 6.376 9.709 9.376 14.732"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M551.926 313.264a24.229 24.229 0 0 1-13.586-4.148c-8.541-5.776-16.353-12.092-22.204-16.95-6.768-5.651-13.517-11.64-20.635-18.313a231.243 231.243 0 0 1-19.069-19.793 987.54 987.54 0 0 1-5.354-6.303c-4.038-4.775-8.214-9.712-12.473-14.419a31.994 31.994 0 0 1-5.263-35.225 31.996 31.996 0 0 1 30.571-18.274 32.015 32.015 0 0 1 22.148 10.559l.094.104c4.245 4.734 8.724 9.406 13.055 13.923 1.905 1.987 3.806 3.978 5.702 5.972a231.444 231.444 0 0 1 17.702 21.032c5.888 7.767 11.142 15.104 16.056 22.422 4.221 6.325 9.687 14.748 14.547 23.85a24.165 24.165 0 0 1-.581 23.808 24.165 24.165 0 0 1-20.71 11.756v-.001Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-3-left-b)"
        />
        <path
            d="m506.062 190.398.093.104c4.245 4.734 8.724 9.406 13.055 13.923 1.905 1.987 3.806 3.978 5.703 5.972a231.407 231.407 0 0 1 17.701 21.032c5.889 7.767 11.142 15.104 16.057 22.423 4.22 6.324 9.687 14.748 14.546 23.85a24.176 24.176 0 0 1-5.151 29.348 24.182 24.182 0 0 1-29.726 2.067c-8.54-5.776-16.352-12.093-22.204-16.95-6.767-5.651-13.516-11.64-20.635-18.313a231.396 231.396 0 0 1-19.069-19.793 987.401 987.401 0 0 1-5.353-6.303c-4.038-4.775-8.214-9.712-12.474-14.419"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M556.325 314.391c-1.228 0-2.453-.091-3.666-.272-1.144-.177-2.318-.28-3.455-.378-.492-.043-.985-.086-1.478-.135a20.39 20.39 0 0 1-5.245-1.289 44.553 44.553 0 0 1-5.085-2.221 33.133 33.133 0 0 1-5.13-3.174 20.543 20.543 0 0 1-3.777-28.808 21.32 21.32 0 0 1 3.772-3.774 33.386 33.386 0 0 1 5.14-3.18 43.855 43.855 0 0 1 5.09-2.221 20.458 20.458 0 0 1 5.103-1.273 24.161 24.161 0 0 1 7.798-1.22 25.622 25.622 0 0 1 6.37.799 21.984 21.984 0 0 1 8.605 4.23 23.747 23.747 0 0 1 8.546 26.551 23.747 23.747 0 0 1-22.588 16.365Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-3-left-c)"
        />
        <path
            d="M570.367 271.475a23.755 23.755 0 0 1 8.546 26.552 23.751 23.751 0 0 1-22.588 16.364c-1.228 0-2.453-.091-3.666-.273-1.144-.176-2.318-.279-3.455-.377-.492-.043-.985-.086-1.478-.135a20.39 20.39 0 0 1-5.245-1.289 44.73 44.73 0 0 1-5.085-2.221 33.133 33.133 0 0 1-5.13-3.174 20.543 20.543 0 0 1-3.777-28.808 21.32 21.32 0 0 1 3.772-3.774 33.386 33.386 0 0 1 5.14-3.18M500.498 185.178c-9.037-6.976-19.774-12.641-32.825-17.319"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-3-left-a"
                x1={508.674}
                y1={233.025}
                x2={474.408}
                y2={157.121}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-3-left-b"
                x1={538.314}
                y1={313.45}
                x2={481.061}
                y2={171.619}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-3-left-c"
                x1={524.233}
                y1={290.419}
                x2={580.068}
                y2={290.419}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
