import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={7}>
        <path
            d="M410.269 241.74c2.635 6.999 5.655 13.852 8.509 20.768 2.876 6.908 2.799 8.636 4.715 15.908 1.974 7.25 3.753 14.574 5.261 22.001a205.618 205.618 0 0 1 3.442 22.69 19.227 19.227 0 0 1-12.338 19.629 19.228 19.228 0 0 1-22.254-6.5 205.395 205.395 0 0 1-12.478-19.261c-3.8-6.557-7.329-13.217-10.662-19.951a164.032 164.032 0 0 1-8.77-20.669c-2.453-7.068-2.304-8.824-4.976-15.809a26.502 26.502 0 0 1 15.284-34.219 26.5 26.5 0 0 1 34.219 15.284l.048.129Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m415.662 310.013 10.936-.622 10.93-.765a197.816 197.816 0 0 1 21.916-.192c3.661.187 7.319.271 10.985.567l5.497.38c1.833.131 3.667.33 5.501.492 7.337.7 14.681 1.566 22.048 2.991a12.328 12.328 0 0 1 9.98 11.595 12.329 12.329 0 0 1-8.982 12.384c-7.224 2.032-14.47 3.505-21.724 4.812-1.813.314-3.625.665-5.441.947l-5.445.836c-3.629.6-7.268.987-10.901 1.477a197.732 197.732 0 0 1-21.855 1.63l-10.956.146-10.95.289a18.5 18.5 0 0 1-1.539-36.967Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M397.604 336.237a205.41 205.41 0 0 1-12.478-19.262c-3.8-6.556-7.328-13.216-10.661-19.95a163.89 163.89 0 0 1-8.771-20.669c-2.453-7.068-2.303-8.824-4.975-15.809M410.219 241.609l.049.13c2.635 6.999 5.655 13.852 8.509 20.768 2.876 6.907 2.799 8.636 4.715 15.908 1.974 7.25 3.753 14.574 5.261 22.001.358 1.792.702 3.588 1.033 5.39"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M503.476 337.407c-7.224 2.032-13.472 2.94-20.726 4.247-1.813.313-3.626.664-5.441.947l-5.447.835c-3.629.6-7.266.987-10.899 1.478-7.25.946-14.547 1.49-21.857 1.63l-10.955.145-10.95.289c-6.819.18-14.566-3.378-19.599-10.744M426.602 309.391l10.929-.765a197.819 197.819 0 0 1 21.916-.192c3.662.187 7.319.271 10.986.567l5.497.38c1.832.131 3.667.33 5.501.492 6.998.643 13.96 1.641 20.857 2.991"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M504.977 334.999a70.24 70.24 0 0 0 24.613-2.101c2.726-.776 5.456-1.58 8.146-2.484l1.245-.256c10.65-1.735 20.374-7.713 28.691-14.399a2.67 2.67 0 0 0 .98-3.023 1.595 1.595 0 0 0 .553-1.797 2.53 2.53 0 0 0-1.209-1.435c-.801-.477-1.754-.115-2.679.006l-.122-.855a2.045 2.045 0 0 0-1.909-1.317 21.353 21.353 0 0 0-8.76 1.874c-3.146 1.433-6.174 3.165-9.471 4.203a24.328 24.328 0 0 1-17.951-1.416l-2.02-1.077c1.9-2.566 4.72-4.257 7.406-5.983a23.009 23.009 0 0 0 5.846-4.863 2.074 2.074 0 0 0-.231-2.803 2.087 2.087 0 0 0-2.518-.215c-3.271 2.005-7.404 2.974-10.922 4.399a84.88 84.88 0 0 0-11.796 5.347 22.56 22.56 0 0 0-8.986 9.092"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M525.083 310.922c1.9-2.566 4.72-4.257 7.406-5.983a23.014 23.014 0 0 0 5.847-4.863 2.075 2.075 0 0 0-.232-2.803 2.085 2.085 0 0 0-2.517-.215c-3.271 2.005-7.405 2.974-10.923 4.399a84.867 84.867 0 0 0-11.795 5.347 22.56 22.56 0 0 0-8.986 9.092M537.963 323.853c5.217-.073 9.997-2.81 14.325-5.724 3.749-2.525 7.466-5.292 11.675-6.841a2.038 2.038 0 0 0 1.232-2.628 2.035 2.035 0 0 0-1.909-1.316 21.352 21.352 0 0 0-8.76 1.873c-3.146 1.433-6.173 3.165-9.47 4.203a24.332 24.332 0 0 1-17.951-1.415"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m568.347 311.781-8.065 6.152a97.479 97.479 0 0 1-10.091 7.046c-6.382 3.701-13.506 5.904-20.601 7.925a70.24 70.24 0 0 1-24.613 2.101"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M567.671 315.766c-8.317 6.685-18.041 12.664-28.691 14.399"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
