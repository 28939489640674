import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-3-left">
        <path
            d="M500.667 185.176c-9.037-6.976-19.774-12.641-32.826-17.319l-2.653-.951 21.103 34.814.857-.526c2.396-1.467 14.36-9.028 14.61-13.676a2.672 2.672 0 0 0-1.091-2.342Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-3-left-a)"
        />
        <path
            d="M508.231 285.256a508.24 508.24 0 0 1-12.561-11.403 231.408 231.408 0 0 1-19.07-19.793 987.401 987.401 0 0 1-5.353-6.303c-4.038-4.775-8.214-9.712-12.473-14.419a31.986 31.986 0 0 1-8.299-23.09 31.995 31.995 0 0 1 10.492-22.179 32.012 32.012 0 0 1 23.115-8.23 32.015 32.015 0 0 1 22.148 10.559l.094.104c4.245 4.734 8.724 9.406 13.055 13.923 1.905 1.987 3.806 3.978 5.702 5.972a231.444 231.444 0 0 1 17.702 21.032 499.626 499.626 0 0 1 11.427 15.668c-10.818.227-40.456 23.504-45.979 38.159Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-3-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-3-left-a"
                x1={487.906}
                y1={157.148}
                x2={446.701}
                y2={344.522}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-3-left-b"
                x1={515.733}
                y1={166.942}
                x2={481.064}
                y2={324.592}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
