import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6565)"
            d="M361.071 276.986c-.342-1.89-.672-3.783-1.002-5.677-.834-4.778-1.697-9.718-2.738-14.527a27.5 27.5 0 0121.053-32.7 27.478 27.478 0 0120.717 3.745 27.465 27.465 0 0111.983 17.309l.031.145c1.022 4.848 2.262 9.74 3.462 14.472l.947 3.747-54.259 14.555-.194-1.069z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6565"
                x1="378.386"
                x2="389.447"
                y1="208.278"
                y2="279.845"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
