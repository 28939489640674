import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-3">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-3-a)"
            d="M505.083 266.24c-2.736.532-5.462 1.1-8.188 1.668-1.493.312-2.985.621-4.477.927-4.316.864-8.611 1.83-12.632 2.744-2.835.62-5.696 1.304-8.463 1.966a958.53 958.53 0 0 1-4.109.976c-9.194 2.23-17.343 4.398-24.916 6.63l-1.988.592a571.188 571.188 0 0 0-1.213-4.052c-2.65-8.62-5.624-17.368-9.089-26.736a242.56 242.56 0 0 0-11.068-25.884 1072.227 1072.227 0 0 0-3.804-7.58c-2.884-5.716-5.866-11.627-8.603-17.524l-.06-.128a32.15 32.15 0 0 0-28.986-18.405 31.722 31.722 0 0 0-13.582 3.042 31.788 31.788 0 0 0-16.498 18.101 31.807 31.807 0 0 0 1.135 24.466c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.236 5.18 3.361 7.766a242.507 242.507 0 0 0 12.737 25.1c4.959 8.675 9.755 16.571 14.666 24.142 4.253 6.517 9.979 15.055 16.564 23.298a24.297 24.297 0 0 0 16.492 8.948 23.432 23.432 0 0 0 11.587-.938l12.155-4.168 12.181-4.11c7.466-2.543 15.374-5.473 24.182-8.959a717.169 717.169 0 0 1 3.891-1.561c2.649-1.059 5.388-2.154 8.062-3.282 3.828-1.58 7.901-3.271 11.904-5.046a856.368 856.368 0 0 1 4.191-1.825 628.048 628.048 0 0 0 7.67-3.369c1.245-.584 2.495-1.157 3.744-1.731l-10.705-33.675c-1.347.25-2.694.501-4.059.745Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-3-a"
                x1={353.356}
                x2={447.911}
                y1={135.814}
                y2={283.283}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
