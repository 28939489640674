import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={6}>
        <path
            d="M410.516 238.667c1.282 7.751 2.97 15.433 4.483 23.145a180.769 180.769 0 0 1 3.051 23.385c.585 7.868.964 15.77 1.056 23.72a225.124 225.124 0 0 1-.861 24.042 19.162 19.162 0 0 1-28.04 15.067 19.163 19.163 0 0 1-8.448-8.937 225.029 225.029 0 0 1-8.671-22.441c-2.51-7.544-4.734-15.136-6.753-22.763a180.688 180.688 0 0 1-4.757-23.098c-1.09-7.783-2.007-15.595-3.328-23.339a26.501 26.501 0 0 1 52.247-8.911l.021.13Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m390.688 319.863 10.073-7.496 9.989-7.612a257.24 257.24 0 0 1 20.764-14.138c3.589-2.179 7.117-4.442 10.77-6.532l5.443-3.188c1.816-1.059 3.672-2.063 5.507-3.097 7.37-4.093 14.836-8.052 22.631-11.558a12.256 12.256 0 0 1 17.26 11.874 12.25 12.25 0 0 1-3.189 7.569c-5.766 6.308-11.86 12.164-18.052 17.885-1.556 1.419-3.089 2.869-4.663 4.263l-4.73 4.174c-3.127 2.816-6.379 5.461-9.57 8.19a262.75 262.75 0 0 1-19.92 15.304l-10.352 7.11-10.269 7.226a18.502 18.502 0 0 1-21.692-29.974Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M381.758 339.089a225.211 225.211 0 0 1-8.67-22.441c-2.51-7.544-4.734-15.137-6.753-22.763a180.878 180.878 0 0 1-4.758-23.099c-1.09-7.782-2.006-15.595-3.327-23.338-1.845-10.82 3.504-23.094 12.203-28.659M410.496 238.539l.022.13c1.282 7.751 2.97 15.433 4.483 23.145a180.769 180.769 0 0 1 3.051 23.385c.27 3.628.394 5.265.568 8.91"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M489.935 285.68c-5.767 6.308-11.86 12.164-18.052 17.885-1.556 1.419-3.09 2.869-4.663 4.263l-4.73 4.174c-3.127 2.817-6.379 5.461-9.57 8.19A262.75 262.75 0 0 1 433 335.496l-10.353 7.11-10.268 7.226c-8.357 5.881-20.695 5.654-30.625-10.746M400.762 312.367l9.989-7.612a257.064 257.064 0 0 1 20.764-14.138c3.589-2.179 7.117-4.442 10.77-6.532l5.443-3.188c1.816-1.059 3.672-2.063 5.506-3.097 7.37-4.093 14.837-8.052 22.632-11.558"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M537.069 272.491a29.454 29.454 0 0 0-1.484-.999l.187-1.005a4.405 4.405 0 0 0-.633-3.194 31.931 31.931 0 0 0-6.407-7.286l.68-1.311c1.805-2.737 2.508-6.397 1.055-9.336-1.585-3.208-5.232-4.81-8.703-5.677-6.772-1.692-15.066-4.189-20.687-.051a220.303 220.303 0 0 0-20.785 17.365c-12.453 11.502-8.881 24.475 4.873 26.991a16.174 16.174 0 0 0 16.482-7.102c6.4 1.378 15.439 3.79 21.23 7.815a3.17 3.17 0 0 0 4.248-.504l.059-.07a3.18 3.18 0 0 0 .418-3.509 3.177 3.177 0 0 0-.768-.975c-6.615-5.584-16.651-9.6-24.44-12.272 9.983 1.727 22.381 4.1 32.684 5.912a2.635 2.635 0 0 0 2.968-1.794l.013-.039a2.645 2.645 0 0 0-.99-2.959Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M480.293 260.997a220.43 220.43 0 0 1 20.785-17.365c5.622-4.138 13.917-1.641 20.689.051 3.471.867 7.117 2.469 8.702 5.676 1.452 2.94.75 6.6-1.055 9.337"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M506.492 253.82c6.035.335 12.226.806 17.691 3.389a24.691 24.691 0 0 1 11.061 10.459M507.053 262.641a107.488 107.488 0 0 1 15.348 2.966 54.502 54.502 0 0 1 14.666 6.886 2.641 2.641 0 0 1 .992 2.961l-.013.039a2.642 2.642 0 0 1-2.969 1.793c-10.302-1.812-22.701-4.185-32.683-5.911 7.79 2.672 17.829 6.689 24.444 12.276a3.175 3.175 0 0 1 .346 4.479l-.04.047a3.208 3.208 0 0 1-4.289.512c-5.791-4.016-14.817-6.424-21.209-7.8a16.165 16.165 0 0 1-16.482 7.102M515.234 252.469l5.172.102a3.06 3.06 0 0 1 2.064.548 2.046 2.046 0 0 1 .585 1.473 6.104 6.104 0 0 1-.26 1.604"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
