import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 614,
        height: 530,
        content: (
            <>
                <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    clipPath="url(#object-office-carts-a)"
                >
                    <path 
                        d="M57.998 254.726c0-17.815 14.442-32.257 32.257-32.257h255.256M223.252 254.725h389.353v32.257M612.604 468.286v61.176H223.251v-242.48H79.098v-32.257H223.25M435.435 235.889a81.775 81.775 0 0 0 17.95-20.8l119.84-198.29c4.16-6.89-.8-15.69-8.85-15.69l-106.029.003a78.532 78.532 0 0 0-67.212 37.914l-90.298 149.418"
                    />
                    <path
                        d="M345.512 222.469h21.27a80.49 80.49 0 0 0 68.888-38.859l99.723-166.23M124.648 307.328h53.053M124.648 380.277h53.053M108.4 359.932h259.957v72.95l25.973-.001h218.274v-72.949H394.33M79.098 432.881V314.977M476.941 307.328h53.052M476.941 380.277h53.052M612.604 286.982H394.33v72.949M247.852 503.693H145.187v25.77H121.84l-8-25.77H32.382l-8 25.77H1.035v-96.581H204.41v18.84H79.098v-18.841" 
                    />
                </g>
                <defs>
                    <clipPath id="object-office-carts-a">
                        <path fill="#fff" d="M.035.11h613.57v530.352H.034z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #1': {
        width: 614,
        height: 530,
        content: (
            <>
                <path
                    fill="url(#object-office-carts-a)"
                    d="M612.789 254.727H223.436v274.737h389.353V254.727Z"
                />
                <path
                    fill="url(#object-office-carts-b)"
                    d="M366.963 222.469a80.49 80.49 0 0 0 68.888-38.859l99.723-166.229a33.697 33.697 0 0 1 28.839-16.268H458.527a78.532 78.532 0 0 0-67.212 37.914l-110.86 183.441h86.508v.001Z"
                />
                <path
                    fill="url(#object-office-carts-c)"
                    d="M118.515 254.727H58.182c0-17.815 14.442-32.257 32.257-32.257h276.526a80.49 80.49 0 0 0 68.888-38.859l99.723-166.228a33.697 33.697 0 0 1 28.839-16.268h.142c8.05 0 13.012 8.795 8.848 15.685L453.569 215.094a82.095 82.095 0 0 1-70.261 39.633H118.515Z"
                />
                <path fill="url(#object-office-carts-d)" d="M223.433 254.727H79.281v178.155h144.152V254.727Z" />
                <path
                    fill="#E2E2E2"
                    d="M223.433 286.984H79.281v72.949h144.152v-72.949ZM223.433 359.934H79.281v72.949h144.152v-72.949Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M124.832 307.33h53.053M124.832 380.279h53.053"
                />
                <path fill="url(#object-office-carts-e)" d="M612.788 286.984H394.514v72.949h218.274v-72.949Z" />
                <path fill="url(#object-office-carts-f)" d="M612.788 359.934H394.514v72.949h218.274v-72.949Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M477.125 307.33h53.052M477.125 380.279h53.052"
                />
                <path fill="#E2E2E2" d="M223.435 432.883H1.219v70.812h222.216v-70.812Z" />
                <path
                    fill="#888B8D"
                    d="M1.219 529.462h23.347l8-25.769H1.219v25.769ZM122.023 529.462h23.347v-25.769h-31.347l8 25.769Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m280.455 222.47 110.86-183.44a78.533 78.533 0 0 1 67.212-37.915h105.886"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M564.415 1.115h.142c8.05 0 13.012 8.795 8.848 15.685L453.569 215.094a82.095 82.095 0 0 1-70.261 39.633H58.182c0-17.815 14.442-32.257 32.257-32.257h276.526a80.49 80.49 0 0 0 68.888-38.859L515.05 51.597"
                />
                <path fill="url(#object-office-carts-g)" d="M223.434 432.883h-78.063v70.812h78.063v-70.812Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M79.281 359.934h144.153M1.219 432.883h222.216M612.788 359.933H394.514v-72.949M422.436 286.984h190.352M612.788 359.934H394.514v72.949h218.274"
                />
                <defs>
                    <linearGradient
                        id="object-office-carts-a"
                        x1={555.992}
                        x2={212.666}
                        y1={0.114}
                        y2={275.086}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-b"
                        x1={463.541}
                        x2={268.005}
                        y1={136.169}
                        y2={20.211}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={0.969} stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-c"
                        x1={235.276}
                        x2={416.021}
                        y1={75.47}
                        y2={197.497}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#ECF3F9" />
                        <stop offset={0.443} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-d"
                        x1={212.671}
                        x2={88.367}
                        y1={343.804}
                        y2={343.804}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-e"
                        x1={676.507}
                        x2={378.655}
                        y1={446.613}
                        y2={234.403}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-f"
                        x1={647.047}
                        x2={371.931}
                        y1={499.906}
                        y2={301.338}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-g"
                        x1={465.985}
                        x2={122.658}
                        y1={628.659}
                        y2={433.122}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 614,
        height: 530,
        content: (
            <>
                <path
                    fill="url(#object-office-carts-a)"
                    d="M612.789 254.727H223.436v274.737h389.353V254.727Z"
                />
                <path
                    fill="url(#object-office-carts-b)"
                    d="M366.965 222.468a80.49 80.49 0 0 0 68.888-38.859l99.723-166.228a33.697 33.697 0 0 1 28.839-16.268H458.529a78.532 78.532 0 0 0-67.212 37.914l-110.86 183.441h86.508Z"
                />
                <path
                    fill="url(#object-office-carts-c)"
                    d="M118.515 254.726H58.182c0-17.815 14.442-32.257 32.257-32.257h276.527a80.49 80.49 0 0 0 68.888-38.859l99.723-166.229a33.697 33.697 0 0 1 28.839-16.268h.142c8.05 0 13.012 8.795 8.848 15.685L453.57 215.092a82.095 82.095 0 0 1-70.261 39.633H118.515v.001Z"
                />
                <path fill="url(#object-office-carts-d)" d="M223.433 254.727H79.281v178.155h144.152V254.727Z" />
                <path
                    fill="#E2E2E2"
                    d="M223.433 286.982H79.281v72.949h144.152v-72.949ZM223.433 359.932H79.281v72.949h144.152v-72.949Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M124.832 307.33h53.053M124.832 380.277h53.053"
                />
                <path fill="url(#object-office-carts-e)" d="M612.788 286.982H394.514v72.949h218.274v-72.949Z" />
                <path fill="url(#object-office-carts-f)" d="M612.788 359.932H394.514v72.949h218.274v-72.949Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M477.125 307.33h53.053M477.125 380.277h53.053"
                />
                <path fill="#E2E2E2" d="M223.435 432.881H1.219v70.812h222.216v-70.812Z" />
                <path
                    fill="#888B8D"
                    d="M1.219 529.462h23.347l8-25.769H1.219v25.769ZM122.023 529.462h23.347v-25.769h-31.347l8 25.769Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m280.455 222.468 110.86-183.44a78.534 78.534 0 0 1 67.213-37.915h105.886"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M564.416 1.113h.142c8.05 0 13.012 8.795 8.848 15.685L453.57 215.092a82.095 82.095 0 0 1-70.261 39.633H58.182c0-17.815 14.442-32.257 32.257-32.257h276.527a80.49 80.49 0 0 0 68.888-38.859l79.197-132.014"
                />
                <path fill="url(#object-office-carts-g)" d="M223.434 432.881h-78.063v70.812h78.063v-70.812Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M79.281 359.932h144.153M1.219 432.881h222.216M612.788 359.931H394.514v-72.949M422.436 286.982h190.352M612.788 359.932H394.514v72.949h218.274"
                />
                <defs>
                    <linearGradient
                        id="object-office-carts-a"
                        x1={555.992}
                        x2={212.666}
                        y1={470.623}
                        y2={275.086}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-b"
                        x1={463.542}
                        x2={268.006}
                        y1={136.168}
                        y2={20.21}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.969} stopColor="#E5B97F" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-c"
                        x1={235.276}
                        x2={416.021}
                        y1={75.469}
                        y2={197.496}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FFE4B8" />
                        <stop offset={0.443} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-d"
                        x1={212.671}
                        x2={88.367}
                        y1={343.804}
                        y2={343.804}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-e"
                        x1={676.507}
                        x2={378.655}
                        y1={446.611}
                        y2={234.402}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-f"
                        x1={647.047}
                        x2={371.931}
                        y1={499.904}
                        y2={301.336}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-g"
                        x1={465.984}
                        x2={122.658}
                        y1={628.657}
                        y2={433.121}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 614,
        height: 530,
        content: (
            <>
                <path
                    fill="url(#object-office-carts-a)"
                    d="M612.788 254.727H223.436v274.737h389.352V254.727Z"
                />
                <path
                    fill="url(#object-office-carts-b)"
                    d="M366.965 222.469a80.49 80.49 0 0 0 68.888-38.859l99.723-166.229a33.697 33.697 0 0 1 28.839-16.268H458.529a78.532 78.532 0 0 0-67.212 37.914l-110.86 183.441h86.508v.001Z"
                />
                <path
                    fill="url(#object-office-carts-c)"
                    d="M118.515 254.727H58.182c0-17.815 14.442-32.257 32.257-32.257h276.526a80.49 80.49 0 0 0 68.888-38.859l99.723-166.228a33.697 33.697 0 0 1 28.839-16.268h.142c8.05 0 13.012 8.795 8.848 15.685L453.569 215.094a82.095 82.095 0 0 1-70.261 39.633H118.515Z"
                />
                <path fill="url(#object-office-carts-d)" d="M223.433 254.727H79.281v178.155h144.152V254.727Z" />
                <path
                    fill="#E2E2E2"
                    d="M223.433 286.984H79.281v72.949h144.152v-72.949ZM223.433 359.934H79.281v72.949h144.152v-72.949Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M124.832 307.33h53.053M124.832 380.279h53.053"
                />
                <path fill="url(#object-office-carts-e)" d="M612.788 286.984H394.514v72.949h218.274v-72.949Z" />
                <path fill="url(#object-office-carts-f)" d="M612.788 359.934H394.514v72.949h218.274v-72.949Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M477.125 307.33h53.053M477.125 380.279h53.053"
                />
                <path fill="#E2E2E2" d="M223.435 432.883H1.219v70.812h222.216v-70.812Z" />
                <path
                    fill="#888B8D"
                    d="M1.219 529.462h23.347l8-25.769H1.219v25.769ZM122.023 529.462h23.348v-25.769h-31.348l8 25.769Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m280.455 222.47 110.86-183.44a78.533 78.533 0 0 1 67.212-37.915h105.886"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M564.415 1.115h.142c8.05 0 13.012 8.795 8.848 15.685L453.569 215.094a82.095 82.095 0 0 1-70.261 39.633H58.182c0-17.815 14.442-32.257 32.257-32.257h276.526a80.49 80.49 0 0 0 68.888-38.859L515.05 51.597"
                />
                <path fill="url(#object-office-carts-g)" d="M223.434 432.883h-78.063v70.812h78.063v-70.812Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M79.281 359.934h144.153M1.219 432.883h222.216M612.788 359.933H394.514v-72.949M422.436 286.984h190.351M612.788 359.934H394.514v72.949h218.274"
                />
                <defs>
                    <linearGradient
                        id="object-office-carts-a"
                        x1={555.991}
                        x2={212.666}
                        y1={470.623}
                        y2={275.086}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-b"
                        x1={463.542}
                        x2={268.006}
                        y1={136.169}
                        y2={20.211}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.969} stopColor="#EA9975" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-c"
                        x1={235.276}
                        x2={416.021}
                        y1={75.47}
                        y2={197.497}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FCCBBB" />
                        <stop offset={0.443} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-d"
                        x1={212.671}
                        x2={88.367}
                        y1={343.804}
                        y2={343.804}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-e"
                        x1={676.507}
                        x2={378.655}
                        y1={446.613}
                        y2={234.403}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-f"
                        x1={647.047}
                        x2={371.931}
                        y1={499.906}
                        y2={301.338}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-g"
                        x1={465.983}
                        x2={122.657}
                        y1={628.659}
                        y2={433.123}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 614,
        height: 530,
        content: (
            <>
                <g clipPath="url(#object-office-carts-a)">
                    <path
                        fill="url(#object-office-carts-b)"
                        d="M612.789 254.727H223.436v274.737h389.353V254.727Z"
                    />
                    <path
                        fill="url(#object-office-carts-c)"
                        d="M366.965 222.468a80.49 80.49 0 0 0 68.888-38.859l99.723-166.228a33.697 33.697 0 0 1 28.839-16.268H458.529a78.532 78.532 0 0 0-67.212 37.914l-110.86 183.441h86.508Z"
                    />
                    <path
                        fill="url(#object-office-carts-d)"
                        d="M118.515 254.726H58.182c0-17.815 14.442-32.257 32.257-32.257h276.527a80.49 80.49 0 0 0 68.888-38.859l99.723-166.229a33.697 33.697 0 0 1 28.839-16.268h.142c8.05 0 13.012 8.795 8.848 15.685L453.57 215.092a82.095 82.095 0 0 1-70.261 39.633H118.515v.001Z"
                    />
                    <path
                        fill="url(#object-office-carts-e)"
                        d="M223.433 254.727H79.281v178.155h144.152V254.727Z"
                    />
                    <path
                        fill="#E2E2E2"
                        d="M223.433 286.982H79.281v72.949h144.152v-72.949ZM223.433 359.932H79.281v72.949h144.152v-72.949Z"
                    />
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M124.832 307.33h53.053M124.832 380.277h53.053"
                    />
                    <path
                        fill="url(#object-office-carts-f)"
                        d="M612.788 286.982H394.514v72.949h218.274v-72.949Z"
                    />
                    <path
                        fill="url(#object-office-carts-g)"
                        d="M612.788 359.932H394.514v72.949h218.274v-72.949Z"
                    />
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M477.125 307.33h53.053M477.125 380.277h53.053"
                    />
                    <path fill="#E2E2E2" d="M223.435 432.881H1.219v70.812h222.216v-70.812Z" />
                    <path
                        fill="#888B8D"
                        d="M1.219 529.462h23.347l8-25.769H1.219v25.769ZM122.023 529.462h23.347v-25.769h-31.347l8 25.769Z"
                    />
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m280.455 222.468 110.86-183.44a78.534 78.534 0 0 1 67.213-37.915h105.886"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M564.416 1.113h.142c8.05 0 13.012 8.795 8.848 15.685L453.57 215.092a82.095 82.095 0 0 1-70.261 39.633H58.182c0-17.815 14.442-32.257 32.257-32.257h276.527a80.49 80.49 0 0 0 68.888-38.859l79.197-132.014"
                    />
                    <path
                        fill="url(#object-office-carts-h)"
                        d="M223.434 432.881h-78.063v70.812h78.063v-70.812Z"
                    />
                    <path
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M79.281 359.932h144.153M1.219 432.881h222.216M612.788 359.931H394.514v-72.949M422.436 286.982h190.352M612.788 359.932H394.514v72.949h218.274"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="object-office-carts-b"
                        x1={555.992}
                        x2={212.666}
                        y1={470.623}
                        y2={275.086}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-c"
                        x1={463.542}
                        x2={268.006}
                        y1={136.168}
                        y2={20.21}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.969} stopColor="#FFD100" />
                        <stop offset={1} stopColor="#FFD207" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-d"
                        x1={235.276}
                        x2={416.021}
                        y1={75.469}
                        y2={197.496}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FFF4C3" />
                        <stop offset={0.412} stopColor="#FFDE49" />
                        <stop offset={0.443} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-e"
                        x1={212.671}
                        x2={88.367}
                        y1={343.804}
                        y2={343.804}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-f"
                        x1={676.507}
                        x2={378.655}
                        y1={446.611}
                        y2={234.402}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-g"
                        x1={647.047}
                        x2={371.931}
                        y1={499.904}
                        y2={301.336}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-carts-h"
                        x1={465.984}
                        x2={122.658}
                        y1={628.657}
                        y2={433.121}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.229} stopColor="#9B9D9E" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <clipPath id="object-office-carts-a">
                        <path fill="#fff" d="M.219.113h613.569v529.35H.218z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-carts"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
