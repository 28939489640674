import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M487.432 221.026c7.325 2.382 14.777 4.373 22.174 6.532a173.892 173.892 0 0 1 21.729 7.914c7.109 3.056 14.153 6.31 21.108 9.84a217.272 217.272 0 0 1 20.513 11.69 19.19 19.19 0 0 1-11.353 35.215 217.29 217.29 0 0 1-23.478-2.491c-7.707-1.197-15.326-2.67-22.88-4.341a174.051 174.051 0 0 1-22.261-6.267c-7.266-2.568-14.477-5.304-21.815-7.649a26.503 26.503 0 0 1-17.175-33.309 26.502 26.502 0 0 1 33.31-17.176l.128.042Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m550.57 282.293-5.877-10.431-6.004-10.363a236.211 236.211 0 0 1-10.83-21.368c-1.613-3.666-3.316-7.282-4.833-11.001l-2.331-5.547c-.773-1.851-1.487-3.734-2.233-5.6-2.939-7.488-5.733-15.056-8.035-22.892a12.28 12.28 0 0 1 21.072-11.489c5.34 6.18 10.189 12.627 14.891 19.153 1.164 1.639 2.361 3.259 3.497 4.912l3.4 4.964c2.304 3.289 4.422 6.68 6.63 10.022a236.194 236.194 0 0 1 12.094 20.678l5.459 10.66 5.585 10.591a18.5 18.5 0 0 1-32.485 17.711Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M561.603 292.217a217.29 217.29 0 0 1-23.478-2.491c-7.707-1.197-15.326-2.67-22.88-4.341a174.051 174.051 0 0 1-22.261-6.267c-7.266-2.568-14.477-5.304-21.815-7.649a26.5 26.5 0 1 1 16.135-50.485l.128.042c7.325 2.382 14.776 4.372 22.174 6.532a165.396 165.396 0 0 1 10.982 3.59"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m544.693 271.862-6.004-10.363a236.211 236.211 0 0 1-10.83-21.368c-1.613-3.666-3.316-7.282-4.833-11.001l-2.331-5.547c-.773-1.851-1.487-3.734-2.233-5.6-2.939-7.488-5.733-15.056-8.035-22.892a12.28 12.28 0 0 1 21.072-11.489c5.34 6.18 10.189 12.627 14.891 19.153 1.164 1.639 2.361 3.259 3.497 4.912l3.4 4.964c2.304 3.289 4.422 6.68 6.63 10.022a236.194 236.194 0 0 1 12.094 20.678l5.459 10.66 5.585 10.591a18.454 18.454 0 0 1-7.735 24.996 25.084 25.084 0 0 1-13.718 2.643"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M527.083 152.291a10.14 10.14 0 0 0-5.316-4.022l-.09-.103a12.637 12.637 0 0 0-5.765-5.961 16.07 16.07 0 0 0-5.516-1.667 8.173 8.173 0 0 0-5.501 1.378 5.95 5.95 0 0 0-.671.577l-.777-.788a7.698 7.698 0 0 0-4.689-1.951 7.509 7.509 0 0 0-4.779 1.517c-.415 1.1-.008 2.958 1.031 3.507.733.384 1.521.65 2.337.787l.1.164a59.391 59.391 0 0 1 4.786 13.242c.91 3.927 1.343 8.333-.809 11.741l-2.574 3.007c-2.401-.588-4.32-2.331-6.191-3.946a17.358 17.358 0 0 0-4.043-3.124 2.327 2.327 0 0 0-2.709.643l-.029.034a2.325 2.325 0 0 0-.073 2.905c3.855 5.103 7.557 10.304 11.443 15.388a43.136 43.136 0 0 0 4.859 5.649 49.461 49.461 0 0 0 6.613 5.056c5.907 3.673 18.896 6.557 21.447-6.98 2.135-11.334 3.405-27.142-3.084-37.053Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M498.836 173.721c-2.401-.588-4.32-2.33-6.191-3.946a17.358 17.358 0 0 0-4.043-3.124 2.333 2.333 0 0 0-2.709.643l-.029.034a2.325 2.325 0 0 0-.073 2.905c3.855 5.103 7.558 10.304 11.443 15.388a43.081 43.081 0 0 0 4.859 5.649 31.866 31.866 0 0 0 7.543 5.056M501.297 173.766a14.447 14.447 0 0 1 8.919 3.653M501.41 170.718c2.153-3.408 1.72-7.814.81-11.741a59.417 59.417 0 0 0-4.786-13.243M508.522 148.827a3.307 3.307 0 0 1 .981-3.878 5.96 5.96 0 0 1 4.057-1.18 10.303 10.303 0 0 1 7.404 3.544M502.574 146.935a5.526 5.526 0 0 1 2.308-5.016 8.172 8.172 0 0 1 5.501-1.378c1.924.218 3.793.783 5.516 1.666M500.453 145.469a7.894 7.894 0 0 1-5.456-.691c-1.04-.549-1.447-2.406-1.032-3.506a7.504 7.504 0 0 1 4.779-1.518 7.696 7.696 0 0 1 4.689 1.951M530.154 189.348c1.667-11.728 3.405-27.142-3.084-37.053a10.15 10.15 0 0 0-8.066-4.504 6.808 6.808 0 0 0-3.461.733 4.47 4.47 0 0 0-1.749 1.704 1.865 1.865 0 0 0-.049 1.663 1.917 1.917 0 0 0 1.382 1.124c1.744.347 3.711.793 5.358 1.097.167 1.409.655 2.888.821 4.297M508.286 155.591a17.084 17.084 0 0 0-2.503-6.466M514.995 157.465l-.948-4.793"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.069 224.057c-8.492-8.307-18.696-15.185-30.909-21.252l15.604 34.205s19.388-8.959 15.305-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.805a132.211 132.211 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
