import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, string> = {
    black: '#2B2B2B',
    brown: '#7A5430',
    red: '#F46A25',
    yellow: '#E5BC56',
    gray: '#A0A4AC'
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-1" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color]}
            d="M129.9 30.7c9.3 2.2 12.9 13.6 9.3 14.4-1.2.3-2.6 0-3.7-.3-1.4-.3-2.8-.7-4.2-1.1-6.1-1.8-12.7-4.2-19.2-3.1-2.4.4-5.1 1.4-6.4 3.7-1.1 2-.7 4.4 0 6.5 2 5.6 6.9 10.7 5.8 17.1-.5 2.5-1.6 4.9-2.8 7.2-.3.4-.6.9-.9 1.3-2.7 4.3-1 10.7-1 15h-5.3c-1.1-4.4-4.7-7.2-8.3-8.8-3.6-1.6-7.2-1.5-9.6 1.6-2.2 2.9-1.7 9.3.2 13 2.3 4.5 5 8.4 9.4 11a91.482 91.482 0 01-.621 7.557c-.501 4.043-6.217 4.258-7.933.563-2.674-5.76-5.712-10.919-8.446-16.62-4.7-9.9-6.9-18.6-6.3-29.5 2.5-40.1 39.9-44.2 60-39.5z"
        ></path>
        <path
            stroke="#838891"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M76.4 99.5c-4.7-9.9-6.9-18.6-6.3-29.5 2.3-39.8 39.6-43.9 59.8-39.2"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.1c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
    </g>
);

export default SvgComponent;
