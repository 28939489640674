import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-1">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-1-a)"
            d="M402.216 267.287c.163-.709.324-1.417.482-2.122a202.242 202.242 0 0 0 4.208-25.364c.262-2.564.507-5.13.752-7.697.555-5.817 1.129-11.832 1.904-17.717l.02-.15a32.034 32.034 0 0 0-31.785-36.026c-16.055 0-27.732 8.746-33.587 27.397-3.588 11.428-4.119 42.659-4.386 51.426-.174 4.562-.28 8.953-.318 13.236 31.813-9.217 62.71-2.983 62.71-2.983Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-1-a"
                x1={376.38}
                x2={369.874}
                y1={266.014}
                y2={165.821}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
