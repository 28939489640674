import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-right-option-6">
        <path
            fill="url(#man-34-25-doctors-coat-arm-right-option-6-a)"
            d="M545.689 337.647c-1.456-.406-56.097-17.928-73.807-25.88-8.494-3.814-22.005-10.301-33.846-15.651a192.22 192.22 0 0 1-22.121-12.361c-6.786-4.233-13.565-8.78-20.728-13.905a189.122 189.122 0 0 1-19.359-15.57 892.505 892.505 0 0 1-5.461-5.055c-4.141-3.85-8.422-7.831-12.766-11.581a31.795 31.795 0 0 1-11.007-21.879 31.796 31.796 0 0 1 7.688-23.254 32.001 32.001 0 0 1 24.241-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.324 3.769 8.872 7.447 13.271 11.004a1735.31 1735.31 0 0 1 5.772 4.689 189.111 189.111 0 0 1 18.153 16.969c6.083 6.366 11.543 12.435 16.686 18.548 4.9 5.862 8.793 10.845 12.235 15.662l10.764 4.732 12.682 5.506c7.789 3.407 16.007 7.245 25.125 11.734 1.365.684 2.728 1.362 4.088 2.035 2.734 1.356 5.56 2.758 8.322 4.19 3.936 2.004 8.135 4.152 12.328 6.415 1.454.778 2.912 1.554 4.374 2.327 2.633 1.397 5.266 2.793 7.885 4.226 1.34.756 2.672 1.496 4.005 2.238-4.13 6.264-8.364 18.669-9.541 38.071Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m399.417 189.191.125.109c4.324 3.769 8.872 7.447 13.27 11.004 1.928 1.559 3.852 3.122 5.773 4.689a189.1 189.1 0 0 1 18.152 16.969c6.084 6.366 11.544 12.435 16.686 18.548 4.9 5.862 8.794 10.845 12.236 15.662l10.764 4.733 12.681 5.505c7.79 3.407 16.007 7.245 25.126 11.734 1.365.684 2.728 1.363 4.088 2.035 2.733 1.356 5.559 2.758 8.322 4.19 3.935 2.004 8.134 4.152 12.328 6.415 1.454.778 2.912 1.554 4.373 2.327 2.634 1.397 5.267 2.793 7.886 4.226 1.34.756 2.672 1.497 4.004 2.238-4.13 6.263-8.364 18.668-9.54 38.07-1.455-.406-56.096-17.928-73.807-25.88-8.494-3.814-22.005-10.301-33.845-15.651a192.234 192.234 0 0 1-22.122-12.361c-6.785-4.233-13.564-8.78-20.727-13.905a189.122 189.122 0 0 1-19.359-15.57 835.422 835.422 0 0 1-5.461-5.055c-4.141-3.85-8.423-7.831-12.766-11.581"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-right-option-6-a"
                x1={471.667}
                x2={385.787}
                y1={319.696}
                y2={69.865}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
