import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9070)"
            d="M479.234 236.679c-.181-4.781-.96-19.424-3.838-27.14a101.302 101.302 0 0118.685 14.4 1.708 1.708 0 01.536 1.598c-.513 2.967-8.018 7.689-15.383 11.142z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9070)"
            d="M463.887 273c-2.397-4.473-4.874-9.087-7.479-13.54a26.495 26.495 0 01-3.626-13.262 26.51 26.51 0 0126.472-26.628 26.613 26.613 0 0122.895 13.119l.069.119c2.575 4.451 5.379 8.912 7.875 12.876a96.987 96.987 0 00-25.526 10.239A93.32 93.32 0 00463.887 273z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.038"
            d="M502.149 232.695l.069.119c2.576 4.452 5.379 8.913 7.876 12.876a97.01 97.01 0 00-25.527 10.24 93.305 93.305 0 00-20.679 17.076c-2.397-4.473-4.874-9.087-7.48-13.54M484.266 215.758a138.95 138.95 0 019.79 8.217"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9070"
                x1="491.174"
                x2="458.86"
                y1="204.69"
                y2="272.569"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9070"
                x1="498.056"
                x2="465.742"
                y1="207.965"
                y2="275.844"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
