import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6436)"
            d="M413.765 316.165a19.475 19.475 0 01-12.965-4.939c-.274-.244-.526-.502-.779-.76a21.255 21.255 0 01-4.838-2.877 100.446 100.446 0 01-11.451-10.934c-3.309-3.646-6.478-7.513-9.69-11.826a74.788 74.788 0 01-7.949-12.869c-.674-1.386-1.331-2.783-1.989-4.18-1.433-3.045-2.915-6.192-4.549-9.176a27.536 27.536 0 0110.915-37.329 27.527 27.527 0 0113.183-3.383c10.064 0 20.14 5.559 24.968 14.379.023.042 6.125 11.679 6.913 13.001 2.267 3.782 4.161 7.534 6.33 11.221 3.905-4.393 7.495-7.809 11.682-11.973 1.021-1.007 2.03-2.019 3.04-3.03 2.058-2.061 4.186-4.193 6.358-6.231l4.754-4.55c1.029-.98 2.077-1.941 3.127-2.902.578-.53 1.155-1.061 1.73-1.592 6.235-5.711 11.143-9.993 15.92-13.888l1.617-1.319.015 2.086c.07 9.774 13.346 17.918 17.392 18.189l1.656.11-.872 1.413c-2.837 4.596-6.079 9.542-10.202 15.562-.45.648-.893 1.295-1.336 1.941-.805 1.174-1.61 2.348-2.435 3.503l-3.844 5.355c-1.705 2.425-3.513 4.828-5.262 7.15a791.46 791.46 0 00-2.579 3.445 286.273 286.273 0 01-16.727 20.368l-8.842 9.711-8.722 9.793a19.55 19.55 0 01-6.587 4.823 19.54 19.54 0 01-7.982 1.708z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6436"
                x1="345.027"
                x2="468.208"
                y1="292.674"
                y2="232.819"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
