import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 568,
        height: 373,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M510.983 30.098H347.211M184.721 30.096H1.504V1.559h565.172v28.537"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m184.72 30.097-5.56 28.54-60.89 312.84h16.61L220.969 30.09M389.011 58.638l60.9 312.84h-16.61l-78.89-312.84-7.2-28.54M265.961 30.098l9.83 341.38h16.6l9-312.84M301.391 58.637h87.62M220.969 30.09h44.991"
                />
            </>
        ),
    },
    'Color #1': {
        width: 568,
        height: 372,
        content: (
            <>
                <path fill="url(#object-office-bar-table-a)" d="M566.994 1.566H1.822v28.537h565.172V1.566Z" />
                <path
                    fill="url(#object-office-bar-table-b)"
                    d="M135.2 371.484h-16.608l66.444-341.38h36.25L135.2 371.483Z"
                />
                <path
                    fill="url(#object-office-bar-table-c)"
                    d="M433.62 371.484h16.608l-66.445-341.38h-36.25l86.087 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M276.104 371.484h16.608l9.821-341.38h-36.25l9.821 341.38Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m401.979 123.588-18.196-93.484h-36.25l23.574 93.484h30.872ZM197.709 123.588l23.574-93.484h-36.25l-18.195 93.484h30.871Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m299.844 123.588 2.689-93.484h-36.25l2.69 93.484h30.871Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M347.532 30.103H1.822V1.566h565.172v28.537H383.782"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-table-a"
                        x1={553.242}
                        x2={21.195}
                        y1={15.835}
                        y2={15.835}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.515} stopColor="#C2C9CF" />
                        <stop offset={0.556} stopColor="#CBD2D8" />
                        <stop offset={0.676} stopColor="#E3EAF0" />
                        <stop offset={0.75} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-b"
                        x1={118.592}
                        x2={221.286}
                        y1={200.794}
                        y2={200.794}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-c"
                        x1={347.533}
                        x2={450.228}
                        y1={200.794}
                        y2={200.794}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 568,
        height: 373,
        content: (
            <>
                <path fill="url(#object-office-bar-table-a)" d="M566.744 1.566H1.572v28.537h565.172V1.566Z" />
                <path
                    fill="url(#object-office-bar-table-b)"
                    d="M134.948 371.484H118.34l66.444-341.38h36.25l-86.086 341.38Z"
                />
                <path
                    fill="url(#object-office-bar-table-c)"
                    d="M433.368 371.484h16.608l-66.445-341.38h-36.25l86.087 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M275.854 371.484h16.608l9.821-341.38h-36.25l9.821 341.38Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m401.729 123.588-18.196-93.484h-36.25l23.574 93.484h30.872ZM197.461 123.588l23.574-93.484h-36.25l-18.195 93.484h30.871Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m299.594 123.588 2.689-93.484h-36.25l2.69 93.484h30.871Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M566.744 1.566H1.572v28.537h565.172V1.566Z"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-table-a"
                        x1={552.992}
                        x2={20.945}
                        y1={15.834}
                        y2={15.834}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.515} stopColor="#E5B97F" />
                        <stop offset={0.595} stopColor="#F0CB97" />
                        <stop offset={0.69} stopColor="#FBDDAF" />
                        <stop offset={0.75} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-b"
                        x1={0.572}
                        x2={1.572}
                        y1={200.794}
                        y2={200.794}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-c"
                        x1={347.281}
                        x2={449.976}
                        y1={200.794}
                        y2={200.794}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 568,
        height: 373,
        content: (
            <>
                <path fill="url(#object-office-bar-table-a)" d="M566.494 1.564H1.322v28.537h565.172V1.564Z" />
                <path
                    fill="url(#object-office-bar-table-b)"
                    d="M134.698 371.482H118.09l66.444-341.38h36.25l-86.086 341.38Z"
                />
                <path
                    fill="url(#object-office-bar-table-c)"
                    d="M433.118 371.482h16.608l-66.445-341.38h-36.25l86.087 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M275.604 371.482h16.608l9.821-341.38h-36.25l9.821 341.38Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m401.479 123.586-18.196-93.484h-36.25l23.574 93.484h30.872ZM197.211 123.586l23.574-93.484h-36.25l-18.195 93.484h30.871Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m299.344 123.586 2.689-93.484h-36.25l2.69 93.484h30.871Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M566.494 1.564H1.322v28.537h565.172V1.564Z"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-table-a"
                        x1={552.742}
                        x2={20.695}
                        y1={15.832}
                        y2={15.832}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.515} stopColor="#EA9975" />
                        <stop offset={0.616} stopColor="#F3B39A" />
                        <stop offset={0.699} stopColor="#F9C4B2" />
                        <stop offset={0.75} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-b"
                        x1={118.09}
                        x2={220.784}
                        y1={200.792}
                        y2={200.792}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-c"
                        x1={347.031}
                        x2={449.726}
                        y1={200.792}
                        y2={200.792}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 568,
        height: 373,
        content: (
            <>
                <path fill="url(#object-office-bar-table-a)" d="M566.449 1.563H1.277V30.1H566.45V1.561Z" />
                <path
                    fill="url(#object-office-bar-table-b)"
                    d="M134.651 371.482h-16.608l66.445-341.38h36.25l-86.087 341.38Z"
                />
                <path
                    fill="url(#object-office-bar-table-c)"
                    d="M433.074 371.482h16.608l-66.444-341.38h-36.25l86.086 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M275.559 371.482h16.608l9.821-341.38h-36.25l9.821 341.38Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m401.433 123.586-18.195-93.484h-36.25l23.574 93.484h30.871ZM197.164 123.586l23.574-93.484h-36.25l-18.195 93.484h30.871Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m299.298 123.586 2.69-93.484h-36.25l2.689 93.484h30.871Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M346.987 30.1H1.277V1.561H566.45V30.1H383.237"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-table-a"
                        x1={552.697}
                        x2={20.65}
                        y1={15.831}
                        y2={15.831}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.515} stopColor="#FFD100" />
                        <stop offset={0.517} stopColor="#FFD101" />
                        <stop offset={0.58} stopColor="#FFDD45" />
                        <stop offset={0.637} stopColor="#FFE77B" />
                        <stop offset={0.687} stopColor="#FFEEA2" />
                        <stop offset={0.726} stopColor="#FFF2BA" />
                        <stop offset={0.75} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-b"
                        x1={118.043}
                        x2={220.738}
                        y1={200.792}
                        y2={200.792}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-table-c"
                        x1={346.988}
                        x2={449.682}
                        y1={200.792}
                        y2={200.792}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-bar-table"
            {...attributes}
        >
            {style?.content}  
        </svg>
    );
}
