import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-both-arms-option-3-left">
        <path
            d="M501.052 185.176c-9.036-6.976-19.774-12.641-32.825-17.319l-2.654-.951 21.104 34.814.857-.526c2.396-1.467 14.36-9.028 14.61-13.676a2.677 2.677 0 0 0-1.092-2.342Z"
            fill="url(#man-34-25-jacket-both-arms-option-3-left-a)"
        />
        <path
            d="M552.482 313.264a24.23 24.23 0 0 1-13.587-4.148c-8.54-5.776-16.352-12.092-22.204-16.95-6.767-5.651-13.516-11.64-20.635-18.313a231.396 231.396 0 0 1-19.069-19.793 987.401 987.401 0 0 1-5.353-6.303c-4.038-4.775-8.214-9.712-12.474-14.419a32 32 0 1 1 47.457-42.94l.094.104c4.244 4.734 8.723 9.406 13.054 13.923 1.906 1.987 3.807 3.978 5.703 5.972a231.271 231.271 0 0 1 17.701 21.032c5.889 7.767 11.142 15.104 16.057 22.422 4.221 6.325 9.687 14.748 14.547 23.85a24.176 24.176 0 0 1-21.291 35.564v-.001Z"
            fill="url(#man-34-25-jacket-both-arms-option-3-left-b)"
        />
        <path
            d="M556.879 314.391c-1.227 0-2.452-.091-3.666-.272-1.143-.177-2.318-.28-3.454-.378a121.04 121.04 0 0 1-1.478-.135 20.407 20.407 0 0 1-5.246-1.289 44.708 44.708 0 0 1-5.085-2.221 33.18 33.18 0 0 1-5.13-3.174 20.546 20.546 0 0 1-3.777-28.808 21.326 21.326 0 0 1 3.773-3.774 33.338 33.338 0 0 1 5.14-3.18 43.855 43.855 0 0 1 5.09-2.221 20.458 20.458 0 0 1 5.103-1.273 24.157 24.157 0 0 1 7.798-1.22 25.628 25.628 0 0 1 6.37.799 21.998 21.998 0 0 1 8.605 4.23 23.75 23.75 0 0 1-14.043 42.916Z"
            fill="url(#man-34-25-jacket-both-arms-option-3-left-c)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-3-left-a"
                x1={488.291}
                y1={157.148}
                x2={447.087}
                y2={344.522}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-3-left-b"
                x1={532.075}
                y1={166.779}
                x2={490.87}
                y2={354.152}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-3-left-c"
                x1={524.788}
                y1={290.419}
                x2={580.622}
                y2={290.419}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
