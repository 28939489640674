import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9412)"
            d="M362.055 276.808c-.333-1.839-.654-3.681-.976-5.524l-.026-.146c-.835-4.787-1.699-9.738-2.745-14.568a26.492 26.492 0 013.618-19.957 26.488 26.488 0 0116.67-11.553 26.47 26.47 0 0131.51 20.288l.031.143c1.027 4.871 2.27 9.77 3.471 14.508l.704 2.79-52.257 14.02v-.001z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M410.107 245.345l.031.144c1.027 4.871 2.269 9.77 3.47 14.507l.705 2.79-52.258 14.02v-.001c-.332-1.839-.654-3.68-.976-5.523l-.025-.147c-.836-4.787-1.7-9.738-2.746-14.567a26.564 26.564 0 0110.796-27.404"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9412"
                x1="359.146"
                x2="409.71"
                y1="242.739"
                y2="266.354"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
