import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6405)"
            d="M428.674 342.731a24.809 24.809 0 01-12.492-3.56 31.573 31.573 0 01-6.375-5.172 209.337 209.337 0 01-15.112-17.466c-4.48-5.73-8.852-11.737-13.366-18.363a166.05 166.05 0 01-11.621-19.402c-1.48-2.896-2.719-5.598-3.918-8.21-1.616-3.524-3.138-6.84-5.17-10.527-3.731-6.769-4.394-15.657-1.775-23.777 2.25-6.974 6.654-12.452 12.083-15.027 4.648-2.205 8.297-3.188 11.831-3.188.769.001 1.538.046 2.302.134 10.682 1.226 19.458 6.735 24.077 15.115l1.384 2.544c2.952 5.431 5.501 10.122 8.802 15.76a165.955 165.955 0 0110.097 20.243c3.153 7.37 5.86 14.284 8.275 21.138.735 2.11 1.462 4.28 2.22 6.628l6.413-1.783a230.891 230.891 0 0123.133-5.035 411.67 411.67 0 003.471-.575l.329-.055c2.588-.437 5.236-.884 7.902-1.236l5.842-.835c1.25-.175 2.503-.324 3.757-.472 0 0 1.529-.181 2.147-.258 3.746-.442 9.434-1.08 15.579-1.507l.238-.017-.025.237a36.06 36.06 0 001.804 13.377c2.917 9.037 7.083 13.863 7.125 13.911l.177.202-.244.111c-6.132 2.81-11.962 5.14-16.356 6.856-.658.252-1.308.505-1.957.758-1.2.469-2.398.936-3.602 1.376l-5.563 2.018c-2.507.94-5.074 1.797-7.557 2.627-1.222.408-2.428.811-3.63 1.224a230.589 230.589 0 01-22.781 6.44l-11.497 2.554-11.443 2.687a19.793 19.793 0 01-4.504.525z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6405"
                x1="352.464"
                x2="516.416"
                y1="248.47"
                y2="345.193"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
