import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={9}>
        <path
            d="M497.876 226.549c4.455 4.406 9.198 8.52 13.817 12.759a115.173 115.173 0 0 1 12.801 13.795c3.96 4.91 7.772 9.97 11.382 15.237a148.357 148.357 0 0 1 10.019 16.624 19.53 19.53 0 0 1-3.511 22.488 19.53 19.53 0 0 1-22.424 3.9 148.431 148.431 0 0 1-16.794-9.731c-5.329-3.519-10.454-7.243-15.433-11.117a115.31 115.31 0 0 1-14.013-12.56c-4.319-4.546-8.514-9.217-12.996-13.596a26.501 26.501 0 0 1 18.205-45.456 26.506 26.506 0 0 1 18.829 7.541l.118.116Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M538.681 279.557c4.393 1.584 8.899 2.854 13.356 4.26a81.245 81.245 0 0 1 12.955 5.387c2.086 1.104 4.205 2.11 6.254 3.317l3.095 1.75c1.03.588 2.038 1.239 3.057 1.856a100.948 100.948 0 0 1 11.884 8.386 12.793 12.793 0 0 1 3.834 14.109 12.796 12.796 0 0 1-11.898 8.495 100.91 100.91 0 0 1-14.509-1.03c-1.18-.168-2.372-.301-3.541-.499l-3.504-.604c-2.35-.362-4.629-.925-6.942-1.391a81.137 81.137 0 0 1-13.439-4.03c-4.34-1.733-8.632-3.602-13.036-5.157a18.5 18.5 0 1 1 12.316-34.89l.118.041Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m497.758 226.43.118.115c4.455 4.407 9.198 8.52 13.817 12.76a115.164 115.164 0 0 1 12.801 13.794c3.96 4.911 7.772 9.971 11.382 15.238 1.975 2.905 3.905 5.854 5.739 8.902M581.217 327.117a100.91 100.91 0 0 1-14.509-1.03c-1.18-.168-2.372-.301-3.541-.499l-3.504-.604c-2.35-.363-4.628-.925-6.941-1.391a81.137 81.137 0 0 1-13.439-4.03c-4.34-1.733-12.332-4.968-19.322-8.211M543.744 281.258c2.758.872 5.535 1.69 8.293 2.56a81.23 81.23 0 0 1 12.954 5.386c2.086 1.104 4.206 2.111 6.255 3.318l3.095 1.75c1.03.587 2.037 1.239 3.056 1.855a100.967 100.967 0 0 1 11.885 8.387"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M616.981 319.724c-3.637-3.836-8.317-6.526-13.077-8.851a122.495 122.495 0 0 0-16.071-6.487c-9.08-2.312-11.216 5.265-9.423 16a31.766 31.766 0 0 0 10.911 21.214l.112.045a2.344 2.344 0 0 0-.227.346l-.02.035a2.348 2.348 0 0 0 .094 2.382l-.221.125a1.75 1.75 0 0 0-.865 1.287c-.065.532.318.963.6 1.418a16.392 16.392 0 0 0 15.928 7.373 6.954 6.954 0 0 0 2.785-.917 3.254 3.254 0 0 0 1.555-2.38l.035-.317c.357-.052.713-.113 1.069-.183a9.878 9.878 0 0 0 4.465-1.862c1.252-1.042 2.212-2.177 1.856-3.759l.063-.5c.924-.431 1.808-.94 2.645-1.523a5.513 5.513 0 0 0 2.277-2.628c.458-1.413-.105-2.929-.661-4.303l.271-.381c.348-.34.685-.687 1.011-1.04.52-.583.793-1.345.76-2.126-.134-4.817-2.529-9.444-5.872-12.968Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M587.834 304.391a122.495 122.495 0 0 1 16.071 6.487c4.76 2.325 9.44 5.015 13.077 8.851 3.343 3.524 5.739 8.151 5.873 12.97a3.003 3.003 0 0 1-.761 2.125 35.45 35.45 0 0 1-7.2 5.843"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M612.659 339.036a8.808 8.808 0 0 1 1.725-7.95 15.642 15.642 0 0 0-5.823-5.25"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M620.811 336.242c.556 1.375 1.119 2.89.661 4.303a5.516 5.516 0 0 1-2.277 2.629 18.02 18.02 0 0 1-21.285-.484 2.213 2.213 0 0 1-.709-2.538l.014-.038a2.256 2.256 0 0 1 2.748-1.352 15.55 15.55 0 0 0 8.869-.31"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M595.865 341.413c-1.32.04-2.636-.145-3.893-.548a2.34 2.34 0 0 0-2.765 1.126l-.019.035a2.33 2.33 0 0 0 .505 2.858c5.526 4.867 13.233 7.367 20.474 5.93a9.878 9.878 0 0 0 4.465-1.862c1.252-1.042 2.213-2.177 1.857-3.759"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M588.795 347.242a16.385 16.385 0 0 0 15.929 7.373 6.95 6.95 0 0 0 2.784-.917 3.257 3.257 0 0 0 1.555-2.38M582.654 317.878a6.845 6.845 0 0 0 6.889 4.17 6.856 6.856 0 0 0 6.095-5.264c.142-.612-.527-1.445-1.071-1.761a6.338 6.338 0 0 1-3.054-4.585M589.321 341.604a31.764 31.764 0 0 1-10.911-21.213M590.588 332.135A11.22 11.22 0 0 0 593.72 321"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.068 223.252c-8.491-8.307-18.695-15.185-30.908-21.252l15.604 34.205s19.387-8.958 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202a132.184 132.184 0 0 1 30.908 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
