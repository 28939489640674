import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={616}
            height={223}
            viewBox="0 0 616 223"
            fill="none"
            id="object-meals-8"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M614.423 206.053c0 8.284-6.716 15-15 15h-37.2c-8.284 0-15-6.716-15-15v-65.842h67.2v55.642"
            />
            <path
                fill="#C0F0FF"
                d="M607.422 180.633v25.421c0 4.415-3.585 8-8 8h-37.199c-4.415 0-8-3.585-8-8v-25.421h53.199Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M68.423 206.053c0 8.284-6.716 15-15 15h-37.2c-8.284 0-15-6.716-15-15v-65.842h67.2v55.642"
            />
            <path
                fill="#C0F0FF"
                d="M61.422 180.633v25.421c0 4.415-3.585 8-8 8h-37.2c-4.414 0-8-3.585-8-8v-25.421h53.2Z"
            />
            <path
                fill="url(#object-meals-8-a)"
                d="M283.813 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M283.813 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                fill="#FFD100"
                d="M242.219 163.676H202.32c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M202.32 163.676c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804h-24.345"
            />
            <path
                fill="url(#object-meals-8-b)"
                d="M286.337 182.318a40.7 40.7 0 0 1-36.117 21.937h-55.904a40.7 40.7 0 0 1-36.117-21.937c10.679 0 10.679-4 21.359-4 10.68 0 10.679 4 21.359 4 10.68 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M250.22 204.252h-55.904a40.7 40.7 0 0 1-36.117-21.937c5.294 0 7.964-.983 10.611-1.975M179.559 178.318c5.34 0 8.01 1 10.679 2 2.669 1 5.34 2 10.679 2 10.678 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4A40.67 40.67 0 0 1 270.224 199"
            />
            <path
                fill="url(#object-meals-8-c)"
                d="M346.813 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M346.813 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                fill="#FFD100"
                d="M305.219 163.676H265.32c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M265.32 163.676c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804h-24.345"
            />
            <path
                fill="url(#object-meals-8-d)"
                d="M349.337 182.318a40.7 40.7 0 0 1-36.117 21.937h-55.904a40.7 40.7 0 0 1-36.117-21.937c10.679 0 10.679-4 21.359-4 10.68 0 10.679 4 21.359 4 10.68 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M313.22 204.252h-55.904a40.7 40.7 0 0 1-36.117-21.937c5.294 0 7.964-.983 10.611-1.975M242.559 178.318c5.34 0 8.01 1 10.679 2 2.669 1 5.34 2 10.679 2 10.678 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4A40.67 40.67 0 0 1 333.224 199"
            />
            <path
                fill="url(#object-meals-8-e)"
                d="M516.063 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M516.063 149.587c-2.576-3.981-8.875-5.022-12.169-8.403-1.502-1.545-2.5-3.702-3.531-5.838l-.011-.011c-1.202-2.554-2.425-5.076-4.561-6.535-3.885-2.662-10.098-1.191-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.561-.923-9.701 2.854-14.519 2.854s-9.969-3.777-14.519-2.854c-4.7.944-7.973 6.428-12.287 8.252-4.378 1.846-10.592.375-14.476 3.037-2.136 1.459-3.37 4.003-4.582 6.557-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.421-12.169 8.403-1.857 2.873-1.503 6.924-1.855 10.631a2.962 2.962 0 0 0 1.328 2.778l37.837 24.51a7.904 7.904 0 0 1 3.607 6.634v3.636h41.274v-3.636a7.904 7.904 0 0 1 3.607-6.634l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.707.003-7.756-1.863-10.629Z"
            />
            <path
                fill="#FFD100"
                d="M474.469 163.676H434.57c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M434.57 163.676c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.181 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804h-24.345"
            />
            <path
                fill="url(#object-meals-8-f)"
                d="M518.587 182.318a40.7 40.7 0 0 1-36.117 21.937h-55.904a40.7 40.7 0 0 1-36.117-21.937c10.679 0 10.679-4 21.359-4 10.68 0 10.679 4 21.359 4 10.68 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M482.47 204.252h-55.904a40.7 40.7 0 0 1-36.117-21.937c5.294 0 7.964-.983 10.611-1.975M411.809 178.318c5.34 0 8.01 1 10.679 2 2.669 1 5.34 2 10.679 2 10.678 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4A40.67 40.67 0 0 1 502.474 199"
            />
            <path
                fill="#E4002B"
                d="M369.559 137.321c7.874 0 14.258-6.384 14.258-14.258 0-7.875-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m378.758 115.529 2.937-2.937"
            />
            <path
                fill="#E4002B"
                d="M369.559 70.196c7.874 0 14.258-6.384 14.258-14.258 0-7.875-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m378.758 48.404 2.937-2.937"
            />
            <path
                fill="#E4002B"
                d="M328.059 104.821c7.874 0 14.258-6.384 14.258-14.258 0-7.875-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m337.258 83.029 2.937-2.937"
            />
            <path
                fill="#E4002B"
                d="M409.309 104.821c7.874 0 14.258-6.384 14.258-14.258 0-7.875-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m418.508 83.029 2.937-2.937"
            />
            <path
                fill="url(#object-meals-8-g)"
                d="M384.264 203.992H148.5a56.544 56.544 0 0 0 40.475 17.06h297.298a56.544 56.544 0 0 0 40.475-17.06H384.264Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M466.843 221.052h19.431a56.544 56.544 0 0 0 40.475-17.06H148.5a56.544 56.544 0 0 0 40.475 17.06h245.784"
            />
            <path
                fill="#5BC2E7"
                d="M79.62 1.643h25.959a2 2 0 0 1 2 2V15.01h-29.96V3.643a2 2 0 0 1 2-2Z"
            />
            <path
                fill="url(#object-meals-8-h)"
                d="M65.668 38.87a46.61 46.61 0 0 0-9.988 28.825v46.3c0 1.234.46 2.425 1.296 3.335 6.182 6.73 6.182 17.141 0 23.872a4.929 4.929 0 0 0-1.296 3.335v64.066c0 6.876 5.574 12.45 12.45 12.45h48.937c6.876 0 12.45-5.574 12.45-12.45v-64.066a4.932 4.932 0 0 0-1.296-3.335c-6.181-6.73-6.181-17.141 0-23.872a4.929 4.929 0 0 0 1.296-3.335v-46.3a46.61 46.61 0 0 0-9.988-28.826H65.668Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M79.62 1.643h25.959a2 2 0 0 1 2 2V15.01h-29.96V3.643a2 2 0 0 1 2-2Z"
            />
            <path fill="#5BC2E7" d="M129.518 67.695H55.68v38.767h73.838V67.695Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M72.281 202.396h40.633M72.281 186.355h40.633M72.281 170.314h40.633M72.281 154.273h40.633M129.519 166.152v-21.616a4.932 4.932 0 0 0-1.296-3.335c-6.182-6.73-6.182-17.141 0-23.871a4.929 4.929 0 0 0 1.296-3.335v-46.3a46.617 46.617 0 0 0-13.128-32.43l-10.37-10.707a4.978 4.978 0 0 1-1.403-3.465V15.01H80.582v6.083a4.98 4.98 0 0 1-1.403 3.465l-3.347 3.456M65.664 38.865a46.62 46.62 0 0 0-9.984 28.83v46.3c0 1.234.46 2.425 1.296 3.335 6.182 6.73 6.182 17.141 0 23.871a4.929 4.929 0 0 0-1.296 3.335v64.066c0 6.876 5.574 12.45 12.45 12.45h48.938c6.876 0 12.45-5.574 12.45-12.45V191.92"
            />
            <defs>
                <linearGradient
                    id="object-meals-8-a"
                    x1={222.268}
                    x2={222.268}
                    y1={248.397}
                    y2={158.96}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-b"
                    x1={278.393}
                    x2={219.277}
                    y1={243.16}
                    y2={180.255}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-c"
                    x1={285.268}
                    x2={285.268}
                    y1={248.397}
                    y2={158.96}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-d"
                    x1={341.393}
                    x2={282.277}
                    y1={243.16}
                    y2={180.255}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-e"
                    x1={454.518}
                    x2={454.518}
                    y1={248.397}
                    y2={158.96}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-f"
                    x1={510.643}
                    x2={451.527}
                    y1={243.16}
                    y2={180.255}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-g"
                    x1={148.5}
                    x2={526.748}
                    y1={212.522}
                    y2={212.522}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-8-h"
                    x1={166.312}
                    x2={63.996}
                    y1={336.06}
                    y2={57.155}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
            </defs>
        </svg>
    );
}
