import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m347.043 788.883-10.867 30.822 6.807 3.934s3.312-4.864 5.641-8.549c1.614-2.554 4.919-4.339 7.646-2.308"
                fill="#EF0025"
            />
            <path
                d="m336.176 819.711 7.026 3.865"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M382.118 854.298a38.887 38.887 0 0 1-9.446-5.909l-4.191-3.55a24.353 24.353 0 0 1-8.358-15.063l-1.51-10.338a22.351 22.351 0 0 0-5.311-11.505l-4.038-4.606a13.896 13.896 0 0 1 .241-18.625l15.365-16.124 26.06 18.072 5.618 22.834-5.304 21.355a8.548 8.548 0 0 0 1.221 6.833l7.894 11.661a6.44 6.44 0 0 1-.05 7.384 6.455 6.455 0 0 1-7.826 2.156l-10.365-4.575Z"
                fill="#EF0025"
            />
            <path
                d="M396.875 859.147a6.41 6.41 0 0 1-4.393-.278l-10.365-4.575a38.857 38.857 0 0 1-9.446-5.909l-4.191-3.55a24.329 24.329 0 0 1-8.358-15.063l-1.51-10.333a22.328 22.328 0 0 0-5.311-11.51l-4.039-4.606a15.926 15.926 0 0 1-3.418-10.292"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m395.912 842.734 8.096 32.58 9.112.302 1.349-10.954"
                fill="#EF0025"
            />
            <path
                d="M425.854 876.379a15.027 15.027 0 0 1-12.214-6.062l-8.843-12.225a30.249 30.249 0 0 0-3.689-4.113 20.617 20.617 0 0 1-6.173-19.122l2.205-16.654h40.037s5.928 22.749 7.771 29.728c.416 1.576 15.794 14.013 15.794 14.013a8.284 8.284 0 0 1 2.584 6.195 8.769 8.769 0 0 1-9.049 8.24h-28.423Z"
                fill="url(#female-34-25-shoes-14-stand-2-a)"
            />
            <path
                d="M461.428 873.825a9.321 9.321 0 0 1-6.503 2.561h-29.07a15.033 15.033 0 0 1-12.215-6.062s-15.174-19.201-16.206-20.933M404.008 875.312h9.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-14-stand-2-a"
                    x1={391.539}
                    y1={849.639}
                    x2={442.286}
                    y2={846.169}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.07} stopColor="#EF0025" />
                    <stop offset={0.936} stopColor="#F6542F" />
                </linearGradient>
            </defs>
        </g>
    );
}
