import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8516)"
            d="M482.603 278.837c-5.01-2.65-10.19-5.391-15.366-7.903a27.531 27.531 0 01-12.731-36.748 27.679 27.679 0 0124.759-15.498 27.228 27.228 0 0111.988 2.766l.116.056c5.164 2.537 10.51 4.936 15.68 7.257-9.12 4.974-25.24 29.119-24.446 50.07z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8516"
                x1="451.748"
                x2="507.049"
                y1="248.762"
                y2="248.762"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
