import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6784)"
            d="M494.769 223.4a2.715 2.715 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6784)"
            d="M559.939 277.9a99.41 99.41 0 00-.14-2.877c-.03-.523-.061-1.047-.087-1.58a260.026 260.026 0 00-.261-3.977s-9.04.437-16.306-2.274c-9.912-3.698-13.311-9.001-13.311-9.001-.765 2.11-1.541 4.191-2.322 6.7a188.448 188.448 0 00-11.02-15.574 906.588 906.588 0 00-4.563-5.793c-3.477-4.395-7.142-8.976-10.513-13.558l-.077-.105a27.634 27.634 0 00-22.097-11.103 27.28 27.28 0 00-16.378 5.426 27.533 27.533 0 00-5.677 38.474c3.388 4.561 6.674 9.326 9.852 13.934 1.399 2.029 2.802 4.053 4.208 6.074a196.945 196.945 0 0015.319 19.263c5.796 6.538 11.33 12.43 16.917 18.014a248.354 248.354 0 0018.513 16.882c.401.324.842.645 1.38 1.006a19.288 19.288 0 0011.523 5.477c.714.079 1.433.118 2.152.118a19.46 19.46 0 0019.36-17.372c.443-4.031 1.058-8.121 1.654-12.076.262-1.74.524-3.48.773-5.224a128.06 128.06 0 001.253-17.608c-.004-.954.004-1.906.011-2.858.016-1.954.032-3.975-.05-5.972l-.113-4.416z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6784"
                x1="492.894"
                x2="370.58"
                y1="210.773"
                y2="304.46"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6784"
                x1="531.982"
                x2="409.669"
                y1="261.805"
                y2="355.492"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
