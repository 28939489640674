import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-office-5"
            {...attributes}
        >
            <path fill="#fff" d="M2570.05 0H.05v1472h2570z" />
            <path
                fill="#63666A"
                d="M1832.05 1193h-33.5v-28.6h49l-15.5 28.6ZM1608.75 1193h-33.5v-28.6h49l-15.5 28.6Z"
            />
            <path
                fill="url(#a)"
                d="M1663.85 895.398h134.2c13.8 0 25 11.2 25 25V1164.4h-209.2c-13.8 0-25-11.2-25-25V970.398c0-41.5 33.6-75 75-75Z"
            />
            <path
                fill="url(#b)"
                d="M1625.26 895.398h63.6-25c-41.4 0-75 33.6-75 75V1139.4c0 13.8 11.2 25 25 25h-38.7c-13.8 0-25-11.2-25-25V970.398c.1-41.5 33.7-75 75.1-75Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1613.86 1164.4h-38.7c-13.8 0-25-11.2-25-25V970.398c0-41.4 33.6-75 75-75h63.7"
            />
            <path
                fill="url(#c)"
                d="M1787.45 776h175.8-4.4c-14.1 0-26.5 10.6-30.6 26.1l-39.9 148.3c-4.1 15.5-16.6 26.1-30.6 26.1h-152l46.5-174.4c4.1-15.5 16.5-26.1 30.6-26.1h4.6Z"
            />
            <path
                fill="#FFF4C3"
                d="m1888.25 950.3 40-148.2c4.1-15.5 16.5-26.1 30.6-26.1h9.9c7.1 0 12.4 7.4 11.1 15.5l-16.6 108.3-75 50.5Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1963.25 776h-180.4c-14.1 0-26.5 10.6-30.6 26.1l-31.7 118.6"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1963.25 899.8 16.6-108.3c1.3-8.1-4-15.5-11.1-15.5h-9.9"
            />
            <path
                fill="url(#d)"
                d="M1613.95 1164.4h233.8V976.398h-218.9c-22.1 0-40 17.9-40 40.002v122.9c.1 13.9 11.3 25.1 25.1 25.1Z"
            />
            <path
                fill="url(#e)"
                d="M1895.35 895.398h56.9V1164.4h-106.9c-13.8 0-25-11.2-25-25V970.398c0-41.5 33.6-75 75-75Z"
            />
            <path
                fill="url(#f)"
                d="M1935.05 895.398h14c13.8 0 25 11.2 25 25V1154.4c0 5.5-4.5 10-10 10h-79c-13.8 0-25-11.2-25-25V970.398c0-41.5 33.6-75 75-75Z"
            />
            <path
                fill="#222731"
                d="M1928.25 1193h33.5v-28.6h-49l15.5 28.6ZM1702.05 1193h33.5v-28.6h-49l15.5 28.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1935.05 895.398h14c13.8 0 25 11.2 25 25V1154.4c0 5.5-4.5 10-10 10h-79c-13.8 0-25-11.2-25-25V970.398"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1856.25 1164.4h-10.9c-13.8 0-25-11.2-25-25V970.398c0-41.4 33.6-75 75-75h3.1M1775.25 976.398h-146.4c-22.1 0-40 17.9-40 40.002v122.9c0 13.8 11.2 25 25 25h121.7M1727.36 895.398h-38.5"
            />
            <path fill="#AEAEAF" d="M808.068 873.281h18.85v82.695h-18.85v-82.695Z" />
            <path fill="url(#g)" d="M739.203 937.131v-18.85h743.737v18.85H739.203Z" />
            <path
                fill="url(#h)"
                d="M692.051 813.2a39.395 39.395 0 0 1 38.6-5.73l131.74 51.26c25.03 9.66 51.32 14.55 78.15 14.55h136.009l-166.689-64.86a123.936 123.936 0 0 0-44.88-8.42h-132.02c-19.87.5-32.78 6.61-40.91 13.2Z"
            />
            <path
                fill="url(#i)"
                d="m1482.94 918.277 31.2.333c0-25.036-20.29-45.332-45.33-45.332H940.511c-26.827 0-53.119-4.896-78.145-14.553L730.619 807.47a39.37 39.37 0 0 0-44.197 11.106l-2.719 3.18c-4.94 5.77-2.691 14.7 4.389 17.45l16.641 6.48-.002-.003 103.339 40.21 18.851 7.333 19.25 7.49c30.21 11.65 61.95 17.56 94.34 17.56h542.429v.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1076.52 873.281.03.01"
            />
            <path
                fill="#616469"
                d="M776.902 1096.76V937.125h-18.85V1193.34h18.85v-77.72"
            />
            <path
                fill="#AEAEAF"
                d="M1447.42 937.133h18.85v256.217h-18.85V937.133ZM858.818 937.133h18.85v256.217h-18.85V937.133Z"
            />
            <path fill="url(#j)" d="M817.492 1115.62v-18.85h524.578v18.85H817.492Z" />
            <path fill="#3A383A" d="M1342.07 937.133h18.85v256.217h-18.85V937.133Z" />
            <path fill="url(#k)" d="M1360.92 1115.62v-18.85h86.5v18.85h-86.5Z" />
            <path fill="url(#l)" d="M776.902 1115.62v-18.85h40.59v18.85h-40.59Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m704.731 845.688 103.339 40.209 18.851 7.333 19.25 7.491c30.21 11.65 61.95 17.56 94.34 17.56h542.429l31.2.332c0-25.036-20.29-45.332-45.33-45.332H940.511c-26.827 0-53.119-4.896-78.145-14.552"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1076.55 873.283-166.718-64.86A123.787 123.787 0 0 0 864.949 800H732.934c-25.263.632-39.284 10.34-46.514 18.578l-2.716 3.177c-4.939 5.776-2.697 14.7 4.386 17.456l16.64 6.475"
            />
            <path
                fill="#616469"
                d="M1360.92 1096.76V937.125h-18.85V1193.34h18.85v-77.72"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#m)"
            >
                <path 
                    d="m2228.54 1174.96-.75 17.25h-14.84l-2.15-49.1M2255.94 1143.12l5.13 15.54 11.07 33.55h14.84l-9.94-41M2163.69 1151.21l-9.94 41h14.84l11.07-33.55" />
                <path 
                    d="M2288.73 1140.3c8.02-10.09 12.44-23.08 11.53-36.92-2.29-35.04-4.59-70.09-6.89-105.13h-146.02l-6.89 105.13c-1.28 19.57 8.09 37.44 23.23 47.83M2277.04 1151.21a53.33 53.33 0 0 1-15.97 7.45c-2.93.82-5.96 1.4-9.09 1.71-9.36.94-19.34 1.53-29.85 1.59-11.85.06-23.03-.54-33.44-1.6-3.1-.31-6.12-.89-9.03-1.7M2306.41 829.24c-6.96-22.407-7.65-37.827.88-49.677 5.81-8.061 17.86-9.925 24.98-5.08 8.14 5.537 10.5 16.058 8.95 24.762-4.91 27.547-21.74 53.869-42.79 73.701 0-43.579-19.81-65.913-37.5-65.913-7.07 0-14.37 4.052-14.37 12.736 0 19.652 40.11 20.997 36.05 79.291 21.62-20.264 44.64-31.313 61.22-31.313 14.49 0 25.22 8.578 25.22 21.936 0 12.695-9.68 17.116-21.25 17.116-13.38 0-51.8-5.253-79.03 21.979 7.59-35.561-14.59-80.211-34.24-80.211-10.16 0-15.34 7.768-15.34 13.958 0 25.326 42.5 31.222 39.87 94.295 30.42-15.537 46.59-20.829 58.72-20.829 4.79 0 20.96 3.145 20.96 17.545 0 8.046-8.98 16.547-19.6 16.547-18.54 0-29.6-4.223-48.12.505-10.86 2.77-17.58 10.937-21.94 15.909 1.48-44.33-21.52-91.457-40.98-88.352-11.08 1.768-3.53 38.335 21 88.352-35.19-59.667-106.72-78.373-151.38-71.361-36.32 5.704-39.99 38 10.12 29.777 33.97-5.574 87.12 1.305 106.07 41.583 1.85-31.698-32.77-97.383-92.89-97.383-29.37 0-40.36-8.337-40.36-20.084 0-7.958 8.78-15.158 29.37-15.158 37.92 0 87.03 34.232 99.66 75.031-10.61-54.947 2.85-103.899 43.33-132.379 20.09-14.135 19.43-39.867-.13-45.726-26.18-7.844-74.52 45.347-62.15 123.158-42.82-45.853-77.54-36.409-103.85-44.803-19-6.06-33.44-14.341-32.63-27.766.77-12.742 15.71-17.681 33.41-15.726 40.52 4.478 77.04 38.437 84.32 50.968 6.63-38.274 11.23-67.662.24-99.873-5.71-16.747-20.03-22.977-29.76-20.064-9.82 2.941-14.99 15.539-14.06 27.663 1.49 19.606 15.34 42.006 20.5 47.747M2255.94 1143.12h-45.15" />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#n)"
            >
                <path
                    d="m530.664 1179.05-.594 13.57h-11.676l-1.692-38.61M552.223 1154.02l4.036 12.22 8.71 26.38h11.676l-7.821-32.24M479.642 1160.38l-7.82 32.24h11.675l8.71-26.38" />
                <path 
                    d="M578.02 1151.81c6.309-7.94 9.787-18.15 9.071-29.03-1.802-27.55-3.611-55.11-5.421-82.66H466.785l-5.421 82.66c-1.007 15.38 6.365 29.43 18.277 37.6M568.824 1160.38a41.934 41.934 0 0 1-12.565 5.86 40.93 40.93 0 0 1-7.151 1.34c-7.365.74-15.217 1.21-23.486 1.26-9.323.04-18.119-.43-26.309-1.26-2.439-.25-4.815-.7-7.105-1.34M591.929 907.228c-5.478-17.618-6.016-29.742.697-39.06 4.566-6.338 14.051-7.803 19.652-3.994 6.401 4.354 8.264 12.626 7.043 19.47-3.862 21.659-17.106 42.355-33.67 57.948 0-34.265-15.586-51.825-29.499-51.825-5.566 0-11.307 3.186-11.307 10.014 0 15.452 31.555 16.509 28.358 62.344 17.012-15.933 35.122-24.621 48.168-24.621 11.405 0 19.844 6.745 19.844 17.248 0 9.981-7.619 13.457-16.718 13.457-10.527 0-40.758-4.13-62.183 17.282 5.977-27.961-11.478-63.067-26.933-63.067-8 0-12.075 6.107-12.075 10.974 0 19.913 33.442 24.549 31.371 74.142 23.935-12.217 36.656-16.378 46.197-16.378 3.772 0 16.497 2.473 16.497 13.798 0 6.32-7.072 13.01-15.422 13.01-14.592 0-23.288-3.32-37.864.39-8.54 2.18-13.828 8.6-17.263 12.51 1.166-34.852-16.93-71.906-32.239-69.465-8.719 1.39-2.778 30.142 16.524 69.465-27.686-46.911-83.969-61.619-119.104-56.105-28.578 4.484-31.46 29.875 7.965 23.412 26.725-4.383 68.542 1.026 83.449 32.693 1.454-24.92-25.777-76.566-73.083-76.566-23.106 0-31.753-6.555-31.753-15.792 0-6.257 6.907-11.918 23.106-11.918 29.836 0 68.475 26.915 78.413 58.994-8.349-43.203 2.24-81.692 34.087-104.085 15.808-11.113 15.291-31.346-.099-35.952-20.602-6.168-58.635 35.654-48.895 96.834-33.691-36.052-61.009-28.627-81.712-35.227-14.948-4.764-26.306-11.275-25.668-21.831.605-10.019 12.361-13.902 26.284-12.365 31.881 3.521 60.614 30.222 66.337 40.075 5.218-30.094 8.838-53.201.192-78.527-4.495-13.168-15.762-18.066-23.414-15.776-7.725 2.313-11.792 12.218-11.065 21.751 1.177 15.415 12.075 33.028 16.133 37.542M552.223 1154.02h-35.521" />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2470.05 643H414.551l-55.5 40.5H2222.05V703H350.051v28.5h168v105M498.547 757v40.5M534.547 757v40.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#o)"
            >
                <path 
                    d="M765.346 252.526v330.063h178.295V226H587.052v356.589h147.41M796.23 557.575V509.07M734.461 557.575V509.07" />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#p)"
            >
                <path 
                    d="M1137.35 252.526v330.063h178.29V226H959.052v356.589h147.408M1168.23 557.575V509.07M1106.46 557.575V509.07" />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#q)"
            >
                <path
                    d="M2138.15 252.526v330.063h178.3V226h-356.59v356.589h147.41M2169.04 557.575V509.07M2107.27 557.575V509.07" />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1597.84 305.297v31.913l-15.12 15.114M1597.84 262.594v14.489M1672.89 337.211h-14.48M1597.84 412.387v-14.489M1522.78 337.211h14.49"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1660.11 362.957c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                fill="#FCB47F"
                d="M1229.78 587.733c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.699 18.8 19.299 6.1 6.7 12 13.5 17.5 20.9 7.6 10.3 5.5 24.8-4.8 32.5-7.1 5.3-16.2 5.901-23.6 2.401-8.3-3.9-16.2-8.301-24-12.901-7.7-4.6-15.3-9.5-22.7-14.5-7.4-5-14.6-10.399-21.3-16.199-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5h.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1223.88 663.431c-4.4-3.3-8.8-6.7-12.9-10.3-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5l.1.1c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 4.6 5 9.1 10.1 13.4 15.5"
            />
            <path
                fill="url(#r)"
                d="m1192.18 566.934 2.7 1c13.1 4.7 23.8 10.3 32.8 17.3.8.6 5.9 5.4 5.8 6.4-.2 4.6-16.9 8.2-19.3 9.6l-.9.5-21.1-34.8Z"
            />
            <path
                fill="url(#s)"
                d="M1245.18 598.432c-9.3-6.4-17.3-16.1-28.9-18.2-20.4-4.5-40.6 14-37.9 34.7.9 15.8 15.3 24.1 25.9 33.6 6.2 5.4 12.5 10.8 19.1 15.7 1.5 1.1 56.8-35.3 56.8-35.3-10.8-12-28.6-25.8-35-30.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1310.87 1032.48c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.504-1-12.704l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.7 1.7-8.2 3.7-16.3 6.2-24.4.6-2 1.3-4 2-6.1.7-2 1.4-4 2.2-6 1.5-4 3.2-8 5.3-12l5.3-10.2c6.7-12.9 22.6-18 35.5-11.3 10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.6-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9 49.204c-1.6 8.7-9.9 14.4-18.5 12.8-7.1-1.3-12.2-7.1-13-13.9l-.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1314.27 872.377c10.2-2 20.4-4.5 30.8-8.3l3.3-1.2c6.4-2.3 12.1-6.7 16.1-12.9 9.6-15 5.2-35-9.8-44.6-8.9-5.7-18-10.4-27.1-14.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h80.1l-.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1311.07 1035.07-.3-2.6c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.495-1-12.695l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.7 1.7-8.2 3.7-16.3 6.2-24.4M1363.57 811.277c10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.6-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9 49.203"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1363.57 811.276c-8.9-5.7-26.9-16.3-36-20.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h71.1"
            />
            <path
                fill="#FCB47F"
                d="M1310.17 1032.68c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.1 3c4.3.6 8.5 1.9 12.4 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1310.17 1032.68c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.1 3c4.3.6 8.5 1.9 12.4 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                fill="#EF0025"
                d="M1393.27 1079.18c-2.8-1.5-8.1-4.4-12.5-6.5-7.4-3.6-14.9-10.1-21.2-16.5-2.2-2.2-4.2-4.4-6-6.6-3.4-.4-14.4 4.4-20.2 4.2-11.5-.4-19.6-5.6-26.6-5.8-.8 4.4-1.9 9.5-3.1 15.4-.6 2.7 0 5.5 1.6 7.8s4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5 2.7 0 5-2.1 5.1-4.9.2-2-.9-3.9-2.6-4.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1305.27 1071.08c1.6 2.3 4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5M1357.97 829.176h15.5"
            />
            <path
                fill="#FCB47F"
                d="M1210.77 465.077c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3.8 11.6.8 12.4l-8.6 6.2-46.7-18 2.6-11.6.4-13s-1.1-8.5-9.1-24c-10.1-19.5 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.3-9.6 40.9-2.7 56.6 13Z"
            />
            <path
                fill="url(#t)"
                d="M1217.57 489.379c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.7.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.6-18.1-.8-37.2 1.1-55.7Z"
            />
            <path
                fill="url(#u)"
                d="M1193.97 566.677c-.2-3.7-.4-7.4-.5-11.1-14.5 1.2-30.2-11.6-36.6-18.4-.9-1 2.2 13.8 5.3 24.1 7.2 3.3 22.7 8.7 31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1156.77 536.377c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 3.9 8.6 1.8 21.1-.7 30"
            />
            <path
                fill="#FCB47F"
                d="M1204.37 494.578c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1204.37 494.578c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="url(#v)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1218.87 491.077c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#w)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1196.57 488.977c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1213.47 506.276c.77 0 1.4-1.03 1.4-2.3 0-1.27-.63-2.3-1.4-2.3-.77 0-1.4 1.03-1.4 2.3 0 1.27.63 2.3 1.4 2.3ZM1187.87 506.276c.77 0 1.4-1.03 1.4-2.3 0-1.27-.63-2.3-1.4-2.3-.77 0-1.4 1.03-1.4 2.3 0 1.27.63 2.3 1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1208.87 530.377c-7.6 1.2-15.4.5-22.7-1.9M1155.67 503.376c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1225.27 712.974c-5-7.4-9.2-15-13.3-22.6-4.1-7.7-7.8-15.5-11.4-23.3-3.6-7.8-6.8-15.8-9.3-24-2.6-8.2-5-16.4-7.9-24.5"
            />
            <path
                fill="#FCB47F"
                d="M1099.17 783.677c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                fill="url(#x)"
                d="M1099.17 783.677c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1245.77 627.777c3.9 8.6 6.8 16.6 8.6 23.1 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103M1099.17 783.677c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-19.4c-.8-24 9.7-36.9 21.7-45.6 12.4-9 23-15.5 41.3-23.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1246.37 680.877c-3.6-6.2-7.7-12.9-11.1-16.9M1137.17 682.077c31.1 3.8 56.5-1.2 71.7-22.1M1237.27 759.575c3.6-1.3 7.1-1.4 10.3 0"
            />
            <path
                fill="#001489"
                d="M1314.27 783.375c-17.9-8.6-38.9-14.1-60.3-16.6-26.9-4.1-52.7 9.5-61.9 35.5-11.2 32.4 9.1 65 42.3 71.7h41.7s7.5-23.1 17.6-47.7c12.7-31.2 20.6-42.9 20.6-42.9Z"
            />
            <path
                fill="url(#y)"
                d="M1270.07 739.577c-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-5.9-21.5-25.4-62.9-52.5-82.8-.8-.6-1.9-1.4-3.1-2.2-.2-.2-.5-.3-.7-.5-1.9-1.3-4-2.6-5.3-3.4.1 1.4.3 4.8.3 4.8s0 .3.1.8c.6 3.5 2.7 18.2 1.4 23.1l-6.1-4.7-18.4-14.1c-19.9-8.3-32.3-21.7-32.3-21.7s-10.2 3.3-24.4 11.2c-.1 0-.1.1-.2.1-.9.5-1.9 1.1-2.8 1.6-1 .6-2.1 1.2-3.1 1.8-4.7 2.8-9.3 5.9-13.8 9.2-1 .7-1.9 1.4-2.9 2.1-.8.6-1.6 1.2-2.3 1.8l-.1.1c-12 9.4-18.8 24.5-18.5 40v.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5h.3v.2l.3-.2 144.6-9.8c5.6-3.8 11.8-8.6 17.8-13.3 5.4-4.3 10.7-8.6 15-12.1 2.3-1.9 4.5-3.5 6.2-4.7.2-12.1-.2-22.5-.9-30.8Z"
            />
            <path
                fill="#FCB47F"
                d="M1284.17 1015.87c-5.3-6.9-10.5-13.9-15.5-20.896-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-2.9-8-5.5-16.1-7.7-24.5-.6-2.1-1-4.2-1.5-6.3-.4-2.1-.9-4.2-1.3-6.4-.8-4.3-1.5-8.7-1.8-13.2l-.9-11.3c-1.2-14.5 9.7-27.2 24.2-28.4 11.8-.9 22.4 6.1 26.6 16.5l19 47.3 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.596c3.2 8.2-.9 17.4-9.1 20.6-6.7 2.6-14.2.3-18.4-5.1l-1.8-2Z"
            />
            <path
                fill="url(#z)"
                d="M1201.97 872.275c2.5-.4 3.1-.7 5.6-1.1 11.8-2.1 22.9-9.1 29.6-20.4 11.5-19.4 5.6-44.5-13-57.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7c-3.1-.3-6.3-.4-9.5-.2-28.2 1.6-51.1 24.4-52.8 52.6-1 17.7 6.1 33.7 17.9 44.6 8 7.4 18.6 11.4 29.5 11.4h66.6l-3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1285.67 1017.88-1.5-2c-5.3-6.9-10.5-13.9-15.5-20.903-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-1.6-4.4-3.1-8.8-4.5-13.2-1.2-3.7-2.2-7.5-3.2-11.2-.6-2.1-1-4.2-1.5-6.3M1242.27 820.777l14.9 39.2 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.603M1090.97 816.875c-1.1 18.2 6.5 34.6 18.9 45.6 7.8 6.9 18 10.5 28.4 10.5h54.6M1242.27 820.776c-2.2-10.8-8.4-20.7-18-27.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M1284.17 1015.88c3.4 10.79 6.8 20.89 7.4 32.19.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.41 3.8 2.5 8.4 3.3 12.9 4 8 1.19 16.1 1.8 24.1 2 4.4.09 7.9-3.81 7.2-8.21-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.1-33.39-23.3-50.09"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1284.17 1015.88c3.4 10.8 6.8 20.89 7.4 32.19.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.41 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.79 24.1 2 4.4.1 7.9-3.81 7.2-8.21-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.1-33.39-23.3-50.09M1219.47 816.176h15.5"
            />
            <path
                fill="url(#A)"
                d="M1204.77 779.875c-9.1-4.8-18.8-8.8-28.5-11.8-8.5-2.7-15.2-3.8-22.9-4.5-54.1-6.6-85.4 62.3-45.1 98.8 8.1 7.5 18.8 11.7 30.1 11.7h23.2s-28.8-24.2-10.4-68.7c14.4-34.9 53.6-25.5 53.6-25.5Z"
            />
            <path
                fill="url(#B)"
                d="M1302.87 1085.98c-4.7-4-9.2-12-10.8-19.2-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.2-4.3-.9-8.4-1.7-12.4 8.4-5.4 8.4-9.3 16.8-11.9 8.8-2.7 12.7 1.9 20.1 3.3 3.7 8.2 7.5 16.7 11.6 25l.5 1c4.2 8.5 8.5 17.2 9.9 26.4.4 2.3.9 6.9.7 11.1-.3 11.2-13.6 22.2-45.5-4.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1197.47 594.375c1.8 1.8 7.3 12.1 13 23.6M1138.77 550.176c7 8.1 22.3 18.2 32.2 21.6l24.6 19 8-19.5 7.3 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1171.07 571.777-9.5 13.1-20.9-22.6"
            />
            <path
                fill="#FCB47F"
                d="m1335.08 549.433-1.3-1.2c.7-2.5-1.1-5.1-3-6.8-5.2-4.9-12.6-7.3-19.6-6.5-5.4.6-10.5 3-15.3 5.6-7.1 3.9-13.9 8.3-20.3 13.3-11.2 7.3-4.7 21.1 12.4 23.2 11.6 1.4 24.1-11.2 28.7-18.2l3.2.2c2.2.3 4.4.5 6.4 1.6 1.9 1 3.5 3 3.5 5.2 0 1.2-.5 2.3-.8 3.4-.2.8-.2 1.6 0 2.3.2.7.9 1.1 1.6 1.1 1.3-.1 2.5-.8 3.4-1.7.6-.6 1-1.3 1.3-2.1l.9.2c1 .3 2.2-.2 2.9-1 .7-.8 1.1-1.8 1.4-2.8 1.3-5.8-1.3-11.8-5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1287.69 576.435c12.8 2.4 27.4-13.9 30.3-19.9M1275.59 553.735c6.4-5 13.2-9.401 20.3-13.301 4.8-2.6 9.8-4.999 15.3-5.599 7.1-.8 14.4 1.7 19.6 6.5 1.9 1.8 3.6 4.3 3 6.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1319.69 542.133c5.6 1.3 11.2 3.4 15.4 7.4 4.2 4 6.7 10 5.3 15.6-.3 1-.7 2.1-1.4 2.8-.7.8-1.9 1.2-2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1322.69 549.234c4 .3 7.9 2.4 10.4 5.6 2.5 3.2 3.5 7.5 2.8 11.5-.3 1.7-.9 3.3-2.1 4.5-.9 1-2.1 1.6-3.4 1.7-.7.1-1.4-.4-1.6-1.1-.2-.7-.2-1.5 0-2.3.3-1.1.8-2.2.8-3.4.1-2.2-1.5-4.2-3.5-5.2-1.9-1-4.2-1.3-6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M1107.38 580.936c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.3 3.8 8.4 1.7 16.7 3.7 25.1 5.9 8.3 2.3 16.6 4.8 24.8 8 12 4.7 17.9 18.2 13.2 30.1-3.1 8.1-10.3 13.4-18.3 14.6-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5-17-1.9-29.3-17.2-27.4-34.2 1.9-17 17.2-29.3 34.2-27.4h.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1190.98 603.736 9.6-6.301 9.5-6.4c6.4-4.2 12.9-8.3 19.5-12.1 3.3-1.9 6.6-3.9 10-5.8 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.499 10.4-5.199 7.9-3.7 17.2-.301 20.9 7.599 2.9 6.2 1.4 13.301-3.1 17.801-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.099-8.6 7.699-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.801-9.2 6.799c-10 7.4-24.1 5.3-31.5-4.7-7.4-10-5.3-24.1 4.7-31.5.1-.2.5-.499.8-.699Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1157.68 643.534c6.08 0 11-3.94 11-8.8 0-4.86-4.92-8.8-11-8.8-6.07 0-11 3.94-11 8.8 0 4.86 4.93 8.8 11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1157.68 636.636c1.27 0 2.3-.851 2.3-1.9 0-1.05-1.03-1.9-2.3-1.9-1.27 0-2.3.85-2.3 1.9 0 1.049 1.03 1.9 2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1107.18 580.938h.2c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.3 3.8 8.4 1.7 16.7 3.699 25.1 5.9 4.6 1.299 9.2 2.6 13.7 4.1M1216.78 640.637c-3.2 2.5-9.8 4.3-13.8 5-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5M1200.48 597.436l9.5-6.4c6.4-4.2 12.9-8.3 19.5-12.1 3.3-1.9 6.6-3.9 10-5.8 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.5 10.4-5.2M1287.88 582.336c-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.8-9.2 6.8"
            />
            <path
                fill="url(#C)"
                d="M1131.18 641.833c.9-1.7 3-2.2 4.7-1.1l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.9 1.7-3 2.2-4.7 1.1l-60.7-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.3Z"
            />
            <path
                fill="url(#D)"
                d="m1200.68 675.733-60.7-37.9c-1.3-.8-2.7-.7-3.7 0l-4 2.9c1-.7 2.5-.8 3.7 0l60.7 37.899c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.2.5-.6.8-.9 1.1l4-2.9c.4-.3.7-.6.9-1.1l16-31.299c.8-1.6.1-3.9-1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1177.58 716.131-60.7-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.301c.9-1.7 3-2.199 4.7-1.099l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1181.28 716.131 4-2.9c.4-.3.7-.6.9-1.1l16-31.3c.9-1.7.2-3.9-1.6-5l-60.7-37.901c-1.3-.8-2.7-.7-3.7 0l-4 2.901c1-.7 2.5-.8 3.7 0l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                fill="#231F20"
                d="M1121.48 676.032a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1125.18 669.333a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M1148.15 677.01c2.2-1.27 2.77-4.394 1.28-6.977-1.49-2.582-4.48-3.646-6.68-2.376-2.2 1.27-2.78 4.394-1.28 6.976 1.49 2.583 4.48 3.647 6.68 2.377Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1127.78 679.934a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1293.78 700.131-8.6.3c-2.9.1-5.8.2-8.6.3-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.6-17.4-1.1-5.8-.5-11.6-1.1-17.4-2-8.7-1.3-14.6-9.3-13.4-18 .9-6.4 5.6-11.3 11.4-12.9 5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.5 8.6-.8l8.6-.8c12.4-1.2 23.4 7.8 24.6 20.2 1.2 12.4-7.8 23.4-20.2 24.6-.6.2-1.2.2-1.7.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1303.28 656.633c5.5 5 11.6 11.8 12.3 18.8 1.2 12.4-7.8 23.4-20.2 24.6-.5 0-1 .1-1.5.1l-8.6.3c-2.9.1-5.8.2-8.6.3-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.6-17.4-1.1-5.8-.5-11.6-1.1-17.4-2M1222.38 666.63c5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.399 4.3-.599 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.501 8.6-.801l8.6-.8"
            />
            <path
                fill="#FCB47F"
                d="M1163.68 712.23c.3-.6.6-1.3.9-1.9l-.8-.9c-.9-1-1.3-2.3-1.3-3.6.1-3.7.9-8.1 2.1-11.2l-1.5-.9c-3.5-1.7-6.4-5-6.7-8.8-.3-4.2 2.4-8 5.4-11 5.8-5.8 12.7-13.3 20.9-12.5 10.6 1 21.1 2.7 31.5 5.1 19.5 4.1 23.7 19.4 11.3 30.2-5.5 5-14.3 6.1-20.9 2.7-5.6 5.2-13.3 13.1-16.8 20.7-.8 1.8-2.8 2.7-4.6 2h-.1c-2-.7-3-2.8-2.3-4.7 3.3-9.6 11.1-19.7 17.3-27.1-9.1 7.7-20.2 17.6-29.5 25.6-1.2 1-2.9 1-4.1 0-1.1-1-1.4-2.5-.8-3.7Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1217.78 667.43c-10.4-2.3-24.5-5-35.1-6-8.2-.8-15.1 6.7-20.9 12.5-3 3-5.7 6.8-5.4 11 .3 3.8 3.2 7.1 6.7 8.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1183.38 674.93c-5.9 4-11.9 8.2-15.9 14.1-3.4 5-5.1 11.2-4.9 17.2M1188.08 684.23c-4.8 3.9-9.6 7.7-13.7 12.2-3.6 3.9-8.3 10.3-10.7 15.8-.5 1.2-.2 2.701.8 3.601 1.2 1 2.9 1 4.1 0 9.3-8 20.4-17.901 29.5-25.601-6.3 7.4-14 17.501-17.3 27.101-.7 1.9.4 4.099 2.3 4.699h.1c1.8.6 3.8-.3 4.6-2.1 3.4-7.5 11.1-15.399 16.8-20.599 6.6 3.4 15.5 2.299 20.9-2.701M1173.68 678.832c-1.7 1.1-3.4 2.1-5.2 3.2-.7.5-1.5 1-1.8 1.8-.2.6 0 1.3.3 1.8.3.6.8 1 1.2 1.5"
            />
            <path
                fill="url(#E)"
                d="M1132.88 582.232c-2.6-.2-5.1-.4-7.7-.6-5.8-.5-11.9-.9-17.8-1.6h-.1c-1.2-.1-2.4-.2-3.6-.2-16.3 0-30 12.2-31.8 28.5-.9 8.5 1.5 16.8 6.8 23.5 5.3 6.7 13 10.9 21.5 11.8 5.9.7 11.9 1.5 17.7 2.4 2.5.4 5.1.7 7.6 1.1 1 .1 2.1.3 3.1.4l4.7-65.2c-.1-.1-.2-.1-.4-.1Z"
            />
            <g clipPath="url(#F)">
                <path
                    fill="url(#G)"
                    d="M1144.88 497.725h-7v-22.862c0-18.711 15.17-33.879 33.88-33.879h17.81c3.86 0 7 3.134 7 7h-24.81c-14.85 0-26.88 12.034-26.88 26.879v22.862Z"
                />
                <path
                    fill="#888B8D"
                    d="M1150.49 524.424c7.87-2.904 11.57-12.516 8.27-21.467-3.3-8.952-12.36-13.854-20.23-10.95-7.87 2.904-11.57 12.515-8.27 21.467 3.3 8.952 12.36 13.854 20.23 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1129.06 507.094c.15 9.391 7.2 17.504 15.73 18.121 8.53.617 15.33-6.495 15.17-15.886-.11-7.171-4.25-13.597-10-16.509"
                />
                <path
                    fill="#D9D9D6"
                    d="M1146.79 525.956c6.99-2.58 10.21-11.314 7.19-19.507-3.02-8.193-11.14-12.742-18.14-10.161-6.99 2.581-10.21 11.314-7.19 19.507 3.03 8.193 11.15 12.742 18.14 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1140.92 495.614c-7.62-.637-13.61 5.791-13.39 14.357.21 8.566 6.56 16.026 14.18 16.663 7.62.637 13.61-5.791 13.4-14.357M1177.63 447.984h-5.87c-14.85 0-26.88 12.034-26.88 26.879v15.879"
                />
            </g>
            <path
                fill="#FCB47F"
                d="M1686.65 688.575c9.2-7.1 20-12.7 32.6-17.2l-19.1 31.5c0 .1-17.9-10.8-13.5-14.3Z"
            />
            <path
                fill="url(#H)"
                d="m1721.55 669.574-2.7 1c-13.1 4.7-23.8 10.3-32.8 17.3-.8.6-1.09 1.4-1.09 2.3.2 4.6 12.19 12.2 14.59 13.7l.91.5 21.09-34.8Z"
            />
            <path
                fill="#FCB47F"
                d="M1698.66 685.973c-9.2 2.4-18.4 4.4-27.6 6.5-9.2 2.1-18.3 4.8-27.2 8-9 3.1-17.9 6.5-26.7 10.1-8.8 3.7-17.6 7.5-26.2 12.1-11.3 6-15.6 20-9.6 31.3 4.2 8 12.5 12.401 20.9 12.301 9.7-.2 19.3-1.101 28.8-2.101 9.5-1.1 18.9-2.5 28.2-4.1 9.4-1.6 18.6-3.6 27.7-6.2 9.1-2.6 18.2-5.4 27.3-7.7 16.6-4.3 26.6-21.2 22.3-37.7-4.3-16.6-21.2-26.6-37.7-22.3l-.2-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1618.36 744.974.5-7.7c.2-2.6.4-5.1.6-7.7.1-2.6.4-5.1.4-7.7 0-1.3.1-2.6.1-3.9v-3.9c-.1-5.2-.2-10.4-.5-15.6-.3-5.2-.7-10.4-1.4-15.7-1.1-8.7-9.1-14.9-17.8-13.8-6.1.8-11 5-13 10.5-1.8 5-3.3 10-4.7 15-1.4 5-2.7 10.1-3.8 15.1-.3 1.3-.6 2.5-.9 3.8-.3 1.3-.5 2.5-.8 3.8-.5 2.5-.8 5.1-1.3 7.6-.4 2.5-.7 5.1-1.1 7.6l-1.1 7.6c-1.8 12.3 6.7 23.7 19 25.6 12.3 1.9 23.7-6.7 25.6-19 .1-.4.2-1 .2-1.6Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1592.65 765.672c14.1.8 28.81-.6 38.31-1.6 9.5-1.1 18.89-2.5 28.19-4.1 9.4-1.6 18.6-3.6 27.7-6.2 9.1-2.6 18.2-5.4 27.3-7.7 16.6-4.3 26.61-21.2 22.31-37.7-4.3-16.6-21.2-26.6-37.7-22.3h-.11c-9.2 2.4-18.39 4.4-27.59 6.5-9.2 2.1-18.31 4.8-27.21 8-7 2.4-13.89 5-20.79 7.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1618.85 737.273c.2-2.6.4-5.1.6-7.7.1-2.6.4-5.1.4-7.7 0-1.3.1-2.6.1-3.9v-3.9c-.1-5.2-.2-10.4-.5-15.6-.3-5.2-.7-10.4-1.4-15.7M1587.25 679.473c-1.8 5-3.3 10-4.7 15-1.4 5-2.7 10.1-3.8 15.1-.3 1.3-.6 2.5-.9 3.8-.3 1.3-.5 2.5-.8 3.8-.5 2.5-.8 5.1-1.3 7.6-.4 2.5-.7 5.1-1.1 7.6l-1.1 7.6c-1.8 12.3 6.7 23.7 19 25.6"
            />
            <path
                fill="#FCB47F"
                d="M1579.06 655.274c3.6-4.5 8.1-8.2 13.2-11 2.1-1.2 4.3-2 6.6-2.4h.1c.2-4.9.4-9.8.5-14.7.1-3.2.1-6.3.2-9.4 0-1.5 1.2-2.8 2.7-3.1h.1c1.6-.2 3.1.8 3.5 2.3 1.4 5.2 1.6 10.7 1.8 16.1.3 6.4.7 12.9 3.4 18.7-.1-.2 1.4 3.5 1.3 3.3l2 4.2c.4-1.2.5-2.5.3-3.8-.2-1.8-.7-3.5-.9-5.3-.1-1-.1-2.1.1-3.1.3-1.2 1.3-2.1 2.6-2.2 1.2-.1 2.4.6 2.9 1.7.8 1.8 2 3.8 2.9 5.3 2.5 4.4 2.6 9.8 1.2 14.7-1.3 4.9-4 9.2-6.8 13.4-8.6 13.8-23.7 16.8-29.7 5.2-3.9-6.8-7-14.4-9.5-21.9-.8-2.8-.3-5.8 1.5-8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1616.76 679.973c2.8-4.2 5.5-8.6 6.8-13.4 1.3-4.9 1.2-10.3-1.2-14.7-.9-1.5-2.1-3.5-2.9-5.3-.5-1.1-1.7-1.8-2.9-1.7-1.3.1-2.4 1-2.6 2.2-.2 1-.2 2-.1 3.1.2 1.8.7 3.5.9 5.3.2 1.8 0 3.7-1 5.1M1610.86 651.274c.1.2.1.3.2.5-2.7-5.8-3.2-12.3-3.4-18.7-.2-5.4-.4-10.9-1.8-16.1-.4-1.6-1.9-2.6-3.5-2.3h-.1c-1.5.2-2.7 1.5-2.7 3.1-.1 3.2-.1 6.3-.2 9.4-.2 4.9-.4 9.8-.5 14.7M1600.46 645.176c2.9 2.6 5.9 5.4 7.4 9 1.5 3.6 1.3 7.7.3 11.4-.3 1.3-.8 2.6-1.8 3.4-1 .8-3.2 1.8-4.9-1.1M1593.66 650.676c2.8 1.9 4.6 5 5.8 8.2 1.1 3.2 1.6 6.6 2 9.9.2 1.2.3 2.6-.5 3.6-.8 1.1-2.5 1.3-3.8.8-1.3-.5-3.1-2.9-3.8-4.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1595.76 642.375c-6.5 2.9-12.3 7.4-16.8 12.9-1.8 2.2-2.4 5.3-1.4 8 2.5 7.6 5.6 15.1 9.5 21.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1585.96 656.875c4 4 7.6 10.4 8.4 16.2.2 1.1-.4 2.2-1.5 2.7h-.1c-1.2.6-2.6.1-3.3-1-1.8-2.7-4.2-5-6.8-7"
            />
            <path
                fill="url(#I)"
                d="M1572.65 739.973c1.2-7.4 2.1-15.6 3.4-22.9 1.2-6.4 3.3-14.6 4.8-20.2 0 0 10.1 4.3 22.4 1.6 12.2-2.7 16.5-10.1 16.5-10.1.7 6.7 1 12.6 1.2 19.6 18.6-7.5 38.7-14.4 58.3-18.4 6.3-1.4 12.9-2.9 19.2-4.6 2.7-.7 5.4-1 8.1-1 14.6 0 27.3 9.9 31 24 4.4 17.1-5.9 34.6-23 39-9.2 2.4-18.2 5.1-27.3 7.7-17.5 5.1-38.2 8.2-56.1 10.4-7.8.9-18.2 1.9-28.9 2.2-1.5 0-2.9-.1-4.4-.3-14.9 1.3-27.6-12.3-25.2-27Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1687.16 754.673c-17.5 5.1-38.2 8.2-56.1 10.4-7.8.9-18.2 1.9-28.9 2.2-1.5 0-2.9-.1-4.4-.3-14.8 1.4-27.5-12.2-25.2-26.9 1.2-7.4 2.1-15.6 3.4-22.9 1.2-6.4 4-15.6 5.8-20.6 0 0 6.1 4.3 20.2 2.3 13.8-1.9 16.8-10.1 16.8-10.1.9 5.6 1.7 12.3 1.9 19.3 18.6-7.5 38.7-14.4 58.3-18.4M1718.86 670.473c-13.1 4.7-23.8 10.3-32.8 17.3"
            />
            <path
                fill="#FCB47F"
                d="M1815.45 884.973c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                fill="url(#J)"
                d="M1815.45 884.973c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1668.95 729.074c-3.9 8.6-6.8 16.6-8.6 23.1-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1668.26 782.173c3.6-6.2 7.7-12.9 11.1-16.9M1777.55 783.373c-31.1 3.8-56.5-1.2-71.7-22.1M1677.36 860.872c-3.6-1.3-7-1.4-10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M1603.85 1133.77c.9-8.5 1.7-17 2.4-25.4.3-4.2.7-8.5 1-12.7l.8-12.7c.6-8.4 1-16.9 1.8-25.3.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.297-4.6-49.697-1.7-8.2-3.7-16.3-6.2-24.4-.6-2-1.3-4-2-6.1-.7-2-1.4-4-2.2-6-1.5-4-3.2-8-5.3-12l-5.3-10.2c-6.7-12.9-22.6-18-35.5-11.3-10.4 5.4-15.7 16.8-13.9 27.9l8.5 49.3 4.2 24.597 4.4 24.6c2.9 16.4 5.8 32.8 8.8 49.2l9 49.2c1.6 8.7 9.9 14.4 18.5 12.8 7.1-1.3 12.2-7.1 13-13.9l.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1600.45 973.674c-10.2-2-20.4-4.5-30.8-8.3l-3.3-1.2c-6.4-2.3-12.1-6.7-16.1-12.9-9.6-15-5.2-35 9.8-44.6 8.9-5.7 18-10.4 27.1-14.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.8 28.2-7.8 2.4-.5 4.8-1 7.2-1.3 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.3 7.3-.3 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 58-32.5 67.7l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-80.1l.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1603.55 1136.37.3-2.6c.9-8.5 1.7-17 2.4-25.4.3-4.2.7-8.5 1-12.7l.8-12.7c.6-8.4 1-16.9 1.8-25.3.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.298-4.6-49.698-1.7-8.2-3.7-16.3-6.2-24.4M1551.05 912.574c-10.4 5.4-15.7 16.8-13.9 27.9l8.5 49.3 4.2 24.596 4.4 24.6c2.9 16.4 5.8 32.8 8.8 49.2l9 49.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1551.05 912.573c8.9-5.7 26.9-16.3 36-20.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.8 28.2-7.8 2.4-.5 4.8-1 7.2-1.3 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.3 7.3-.3 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 58-32.5 67.7l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-71.1"
            />
            <path
                fill="#FCB47F"
                d="M1604.45 1133.97c.1 4.3 3.4 20.6 5.5 31.1 1.1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1604.45 1133.97c.1 4.3 3.4 20.6 5.5 31.1 1.1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                fill="#3A383A"
                d="M1528.45 1190.17c8.1 0 18.6-.1 21.9-.6 9.2-1.4 16-4.1 20.1-6.1 3.7-1.8 7.8-3 12.1-3.7l20.1-3c2.8-.4 5.2-1.9 6.7-4.2 1.6-2.3 2.2-5 1.6-7.8-3.5-16.8-5.4-27.8-5.5-31l-.4-13-36.3 7.1 2.4 10.4c-2.3 3.2-19.9 27.1-37.3 35.6-4.3 2.1-9.7 4.9-12.5 6.4-1.7.9-2.8 2.8-2.7 4.8.1 2.7 2.4 4.9 5.1 4.9 1.4.2 3 .2 4.7.2Z"
            />
            <path
                fill="#888B8D"
                d="M1521.45 1180.47c2.8-1.5 8.1-4.4 12.5-6.5 7.4-3.6 14.9-10.1 21.2-16.5 2.2-2.2 4.2-4.4 6-6.6 3.4-.4 14.4 4.4 20.2 4.2 11.5-.4 19.6-5.6 26.6-5.8.8 4.4 1.9 9.5 3.1 15.4.6 2.7 0 5.5-1.6 7.8s-4 3.8-6.7 4.2l-20.1 3c-4.3.6-8.4 1.9-12.1 3.7-4.1 2-10.9 4.7-20.1 6.2-.3 0-.7.1-1.1.1-5.1.5-18.1.6-25.4.5-2.7 0-5-2.1-5.1-4.9-.2-2 .8-3.9 2.6-4.8Z"
            />
            <path
                fill="#888B8D"
                d="M1609.35 1172.38c-1.6 2.29-4 3.8-6.7 4.2l-20.1 3c-4.3.6-8.4 1.9-12.1 3.7-4.1 2-10.9 4.69-20.1 6.19-.3 0-.7.11-1.1.11-5.1.5-18.1.6-25.4.5M1604.85 1133.87c1.1-9.5 7.4-86.9 8.3-101.5 1.1-17.9-1.8-37.398-6.2-57.098h73.4c33.2-6.7 53.5-39.3 42.3-71.7-9.3-26-35-39.6-61.9-35.5-21.4 2.5-42.3 8-60.3 16.6-13.8 6.3-26 11.7-40.9 21.1-2 1.3-5.4 2.9-7.4 4.1-11.5 7.1-18.2 17.7-15.9 30.7 2.8 16.5 9.9 58 12.7 73.898 2 11.3 10.6 102 11.4 123.1 8.7-.9 34.6-2.6 44.5-3.7Z"
            />
            <path
                fill="#FCB47F"
                d="M1703.85 566.374c-4.4 10.2-7.6 24.2-7.6 35.3 0 11.1 1.9 22.2 4.7 32.9 1.6 6.1 2.7 12.9 6.2 18 2.5 3.7 7.8 5.2 12.3 4.8.7-.1 1.3-.1 2-.2-.4 1.3-.8 11.6-.8 12.4l8.6 6.2 46.7-18-2.6-11.6-.4-13s1.1-8.5 9.1-24c10.1-19.5-3.1-37.8-3.1-37.8s-2.8-11.1-18.5-18c-22.3-9.6-40.9-2.7-56.6 13Z"
            />
            <path
                fill="url(#K)"
                d="M1697.05 590.675c2-.2 4-.4 6-.7 2-.2 4.1-.4 5.7.8 1.2.9 1.8 2.4 1.9 3.8.1 1.5.4 3 .1 4.4-2.2 12.9-1.5 21.9.9 24.5.5.6 1 1.3 1.1 2 .1.8-.1 1.5-.2 2.3-.9 4.2-1.8 8.5-2.7 12.7-.5 2.3-.8 4.7-1.1 7-.4 3.9.6 7.3 4.6 9.4-6.6-1.3-13.7-7.7-15.2-10.5-9.5-18.1.8-37.2-1.1-55.7Z"
            />
            <path
                fill="url(#L)"
                d="M1720.75 667.974c.2-3.7.4-7.4.5-11.1 14.5 1.2 30.2-11.6 36.6-18.4.9-1-2.2 13.8-5.3 24.1-7.3 3.3-22.8 8.7-31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1757.85 637.674c-9.3 11.5-23.7 18.3-38.4 19.7-5.6.6-13.7-1.9-18.2-6.6-7.2-7.7-9.7-22.7-6.1-37 1.2-4.9 2.2-7.5 2.1-11.3 0-2.9-.8-6.7-.4-9.8 1.1-9 1.3-18.7 8.9-27.4 25.7-29.5 60.1-12.6 69-1.5 8.2 10.2 12.1 23.8 9.7 36.6-1.6 8.7-5.8 16.7-9.5 24.7-3.9 8.6-1.8 21.1.7 30"
            />
            <path
                fill="url(#M)"
                d="M1739.11 641.788c-2.1-2.9-4.5-11.5-6.8-14.3-4.4-5.5-9.7-3.1-21.4-2.6-21.6.9-14.3 19.4-9.7 26.4 3 4.5 8.2 4.9 11.8 6.1 6.8 2.4 17.4-1.3 23.9-4.4 4.2-2 10.1-5.7 13.7-9.4-3.4 1-8.7 2.1-11.5-1.8Z"
            />
            <path
                fill="#FCB47F"
                d="M1710.25 595.875c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1710.25 595.875c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="url(#N)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1695.75 592.373c2.5-1.2 6.2-1.8 8.8-2.1"
            />
            <path
                stroke="url(#O)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1718.05 590.273c6.4 0 13.1.7 17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1701.15 607.573c-.77 0-1.4-1.03-1.4-2.3 0-1.271.63-2.3 1.4-2.3.77 0 1.4 1.029 1.4 2.3 0 1.27-.63 2.3-1.4 2.3ZM1726.75 607.573c-.77 0-1.4-1.03-1.4-2.3 0-1.271.63-2.3 1.4-2.3.78 0 1.4 1.029 1.4 2.3 0 1.27-.62 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1705.85 631.673c7.6 1.2 15.4.5 22.7-1.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1689.35 814.271c5-7.4 9.2-15 13.3-22.6 4.1-7.7 7.8-15.5 11.4-23.3 3.6-7.8 6.8-15.8 9.3-24 2.6-8.2 5-16.4 7.9-24.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1695.16 917.473h-15.5"
            />
            <path
                fill="url(#P)"
                d="M1644.55 840.873c1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 5.9-21.5 25.4-62.9 52.5-82.8.8-.6 1.9-1.4 3.1-2.2.2-.2.5-.3.7-.5 1.9-1.3 4-2.6 5.3-3.4-.1 1.4-.3 4.8-.3 4.8s0 .3-.1.8c-.6 3.5-2.7 18.2-1.4 23.1l6.1-4.7 18.4-14.1c19.9-8.3 32.3-21.7 32.3-21.7s10.2 3.3 24.4 11.2c.1 0 .1.1.2.1.9.5 1.9 1.1 2.8 1.6 1 .6 2.1 1.2 3.1 1.8 4.7 2.8 9.3 5.9 13.8 9.2 1 .7 1.9 1.4 2.9 2.1.8.6 1.6 1.2 2.3 1.8l.1.1c12 9.4 18.8 24.5 18.5 40v.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5h-.3v.2l-.3-.2-144.6-9.8c-5.6-3.8-11.8-8.6-17.8-13.3-5.4-4.3-10.7-8.6-15-12.1-2.3-1.9-4.5-3.5-6.2-4.7-.1-12.1.2-22.5.9-30.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1717.15 695.672c-1.8 1.8-7.3 12.1-13 23.6M1775.85 651.473c-7 8.1-22.3 18.2-32.2 21.6l-24.6 19-8-19.5-7.3 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1743.65 673.074 9.4 13.1 20.9-22.6M1697.55 727.875l-9.2 25.8c-2.6 7.3-4.4 14.8-5.3 22.4l-12.9 104.7M1643.55 871.574c0-12.1.3-22.5 1-30.7 1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 3.2-11.8 10.5-29.5 20.9-46.4M1784.15 654.473c14.9 6.6 28.7 14.5 41.4 24.7 11.9 9.6 18.8 24.6 18.5 40.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5"
            />
            <path
                fill="#FCB47F"
                d="M1630.56 1117.17c5.3-6.9 10.5-13.9 15.5-20.9 2.5-3.5 5.1-7 7.6-10.5l7.4-10.6c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.699 2.9-8 5.5-16.1 7.7-24.5.6-2.1 1-4.2 1.5-6.3.4-2.1.9-4.2 1.3-6.4.8-4.3 1.5-8.7 1.8-13.2l.9-11.3c1.2-14.5-9.7-27.2-24.2-28.4-11.8-.9-22.4 6.1-26.6 16.5l-19 47.3-9.5 23.7-9.3 23.699c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.6c-3.2 8.2.9 17.4 9.1 20.6 6.7 2.6 14.2.3 18.4-5.1l1.8-2Z"
            />
            <path
                fill="url(#Q)"
                d="M1712.66 973.572c-2.5-.4-3.1-.7-5.6-1.1-11.8-2.1-22.9-9.1-29.6-20.4-11.5-19.4-5.6-44.5 13-57.4l7.4-5.2c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.3 7.1-3.4 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.2 1.8-.6 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.7c3.1-.3 6.3-.4 9.5-.2 28.2 1.6 51.1 24.4 52.8 52.6 1 17.7-6.1 33.7-17.9 44.6-8 7.4-18.6 11.4-29.5 11.4h-66.6l3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1628.96 1119.17 1.5-2c5.3-6.9 10.5-13.9 15.5-20.9 2.5-3.5 5.1-7 7.6-10.5l7.4-10.6c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.697 1.6-4.4 3.1-8.8 4.5-13.2 1.2-3.7 2.2-7.5 3.2-11.2.6-2.1 1-4.2 1.5-6.3M1672.36 922.074l-14.9 39.2-9.5 23.7-9.3 23.696c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.6M1823.66 918.172c1.1 18.2-6.5 34.6-18.9 45.6-7.8 6.9-18 10.5-28.4 10.5h-54.6M1672.36 922.073c2.2-10.8 8.4-20.7 18-27.4l7.4-5.2c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.3 7.1-3.4 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.2 1.8-.6 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M1630.55 1117.17c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1630.55 1117.17c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.1"
            />
            <path
                fill="#3A383A"
                d="M1575.05 1190.18c8.1-.2 16.3-.9 24.3-2 4.5-.7 9.2-1.5 13.3-4.1 4.8-3.1 8.4-8.8 10-16 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.81.2-4.21.5-10.4 3.5-19.69 6.6-29.59l.7-2.2 4.2-6-30.3-19.8-4.7 11.5c-4.1 8.29-7.9 16.79-11.6 25-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3.3 4.7 1.8 6.5 1.5 1.8 3.8 2.8 6.2 2.8l-.1.1Z"
            />
            <path
                fill="url(#R)"
                d="M1611.76 1187.27c4.7-4 9.2-12 10.8-19.2 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.8.2-4.2.2-4.3.9-8.4 1.7-12.4-8.4-5.4-8.4-9.3-16.8-11.9-8.8-2.7-12.7 1.9-20.1 3.3-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3-.9 6.9-.7 11.1.3 11.2 13.6 22.2 45.5-4.7Z"
            />
            <path
                fill="url(#S)"
                d="M1639.16 1120.57c6.6-11 40.5-69.4 53.1-86.8 11.3-16.2 20.1-37.696 26.8-58.496h57.2c11.3 0 22-4.1 30.1-11.7 40.2-36.5 8.9-105.3-45.1-98.8-7.7.8-14.5 1.8-22.9 4.5-17.2 5.4-34.5 13.9-48.6 24.5-10.5 7.3-19.3 18.3-20.9 30.9l-12.4 36.2c-18.2 45.196-38.2 96.796-56 142.396 4.5 6.5 25.1 16.9 38.7 17.3Z"
            />
            <path
                fill="url(#T)"
                d="M1589.24 693.552c.4-.5.9-.7 1.6-.7h57.6c1.6 0 3.4 1.3 4 2.9l32.3 85.7c.3.9.2 1.7-.2 2.2l-4.6 5.6c.4-.5.5-1.3.2-2.2l-32.4-85.7c-.6-1.6-2.4-2.9-4-2.9h-57.6c-.7 0-1.2.2-1.6.7l4.7-5.6Z"
            />
            <path
                fill="url(#U)"
                d="M1586.24 698.453c-1.6 0-2.4 1.3-1.8 2.9l32.3 85.7c.6 1.6 2.4 2.9 4 2.9h57.6c1.6 0 2.4-1.3 1.8-2.9l-32.4-85.7c-.6-1.6-2.4-2.9-4-2.9h-57.5Z"
            />
            <path
                fill="#888B8D"
                d="M1626.64 730.051c-3.2 0-4.8 2.6-3.6 5.8 1.2 3.2 4.8 5.8 8 5.8s4.8-2.6 3.6-5.8c-1.2-3.2-4.8-5.8-8-5.8Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1640.14 705.352c-1.2 0-1.9 1-1.4 2.2.5 1.3 1.9 2.3 3.1 2.3 1.2 0 1.9-1 1.4-2.2-.5-1.3-1.8-2.3-3.1-2.3ZM1643.34 712.852c-1.2 0-1.9 1-1.4 2.2.5 1.2 1.8 2.2 3.1 2.2 1.2 0 1.9-1 1.4-2.2-.5-1.2-1.8-2.2-3.1-2.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1584.64 699.152 4.6-5.6c.4-.5.9-.7 1.6-.7h57.6c1.6 0 3.4 1.3 4 2.9l32.3 85.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1584.64 699.153c-.4.5-.5 1.3-.2 2.2l32.3 85.7c.6 1.6 2.4 2.9 4 2.9h57.6c1.6 0 2.4-1.3 1.8-2.9l-32.4-85.7c-.6-1.6-2.4-2.9-4-2.9h-38.7"
            />
            <path
                fill="#FCB47F"
                d="M1779.76 708.672c-1.7 7-3.8 14-5.8 21s-3.4 14.1-4.3 21.3c-1 7.2-1.7 14.5-2.2 21.8-.4 7.4-.7 14.8-.2 22.3.8 13 12 22.9 25 22.1 8-.5 14.9-5 18.7-11.3 3.9-6.5 7.2-13.2 10.2-19.9 3-6.7 5.7-13.5 8.2-20.4 2.5-6.8 4.6-13.8 6.1-20.9 1.5-7.1 2.8-14.2 4.6-21.3 4.1-16.6-6-33.4-22.6-37.6-16.6-4.1-33.4 6-37.6 22.6l-.1.3Z"
            />
            <path
                fill="#FCB47F"
                d="m1791.45 777.272-13-1.9-13-2c-8.7-1.3-17.4-2.4-26.1-3.3-4.4-.4-8.7-.9-13.1-1.3s-8.8-.8-13.2-1.1l-13.2-.9c-4.4-.2-8.8-.5-13.2-.6-8.7-.2-15.8 6.7-16 15.4-.1 7 4.4 13.1 10.7 15.2 4.2 1.4 8.4 2.6 12.7 3.9 4.2 1.2 8.5 2.4 12.7 3.6 4.2 1.2 8.5 2.3 12.8 3.4 4.3 1.1 8.5 2.2 12.8 3.2 8.5 2.1 17.1 4 25.7 5.7l12.9 2.5 12.9 2.5c12.2 2.4 24-5.6 26.4-17.8 2.4-12.2-5.6-24-17.8-26.4-.4 0-.7-.1-1-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1810.95 805.969c3.9-6.5 7.2-13.2 10.2-19.9 3-6.7 5.7-13.5 8.2-20.4 2.5-6.8 4.6-13.8 6.1-20.9 1.5-7.1 2.8-14.2 4.6-21.3M1779.86 708.57v.201c-1.7 7-3.8 14-5.8 21s-3.4 14.099-4.3 21.299c-.8 6.2-1.5 12.401-2 18.701"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1681.25 796.77c4.2 1.4 8.4 2.599 12.7 3.899 4.2 1.2 8.5 2.401 12.7 3.601 4.2 1.2 8.5 2.299 12.8 3.399 4.3 1.1 8.5 2.2 12.8 3.2 8.5 2.1 17.1 4 25.7 5.7l12.9 2.5 12.9 2.5c10.7 2.1 22-5.8 27.2-15.6M1774.36 774.67l-8.9-1.399c-8.7-1.3-17.4-2.401-26.1-3.301-4.4-.4-8.7-.9-13.1-1.3s-8.8-.8-13.2-1.1l-13.2-.9c-4.4-.2-8.8-.5-13.2-.6"
            />
            <path
                fill="url(#V)"
                d="M1699.85 765.773c2.8.2 5.7.4 8.5.6 1.6.1 3.1.2 4.7.3 4.5.3 9 .7 13.2 1.1 3 .2 6 .5 8.9.8 1.4.1 2.8.3 4.3.4 9.6 1 18.2 2.1 26.2 3.3l.9.1c.4-6.9 1.2-14 2.2-21.5.9-7.4 2.4-14.7 4.3-21.5l1.8-6.3c1.4-4.8 2.8-9.7 4-14.6v-.2c3.6-14.3 16.3-24.3 31-24.3 2.6 0 5.2.3 7.8 1 17.1 4.3 27.6 21.7 23.3 38.8-1.2 4.9-2.3 9.9-3.2 14.8-.4 2.1-.9 4.3-1.3 6.4-1.5 6.9-3.5 14-6.1 21-2.7 7.3-5.3 14-8.2 20.4-2.5 5.6-5.9 12.8-10.2 20-.8 1.3-1.7 2.6-2.8 3.7-4 7.8-12.2 12.8-20.9 12.8-1.5 0-3-.1-4.5-.4l-12.9-2.5-12.9-2.5c-7.9-1.5-16.3-3.4-25.8-5.7-1.4-.3-2.8-.7-4.2-1-2.8-.7-5.8-1.4-8.6-2.2-4.1-1.1-8.5-2.2-12.8-3.4-1.5-.4-3-.8-4.5-1.3-2.7-.8-5.5-1.5-8.2-2.3h-.2s7-9.4 7.2-18.6c.1-8.4-2.9-17.3-2.9-17.3.5 0 1.2.1 1.9.1Z"
            />
            <path
                fill="#FCB47F"
                d="M1676.45 794.571c-9.3-4.1-18.2-9.5-25.5-16.6-2.5-2.4-5-4.9-7.4-7.4l-1.2-1c-10.6-8.1-17.6-20.2-22.8-32.3-.6-1.3-.4-2.9.7-3.9-.5-.6-.1-1.7.4-2.3.5-.6 1.4-.8 2.1-.9 1.1-.1 2 .9 2.9 1.6l.6-.9c.8-.7 1.9-.8 2.8-.3 3.3 1.7 6.2 4.2 8.4 7.1 2.6 3.4 4.9 7 7.8 10.1 5.3 5.4 12.7 8.7 20.3 8.9h2.8c-.6-3.9-2.7-7.4-4.6-10.8-1.5-2.7-3-5.6-3.5-8.7-.2-1.3.6-2.6 1.9-2.9 1.1-.3 2.3.2 2.9 1.2 2.4 4.1 6.3 7.5 9.3 11.1 3.4 4.1 7 8.1 9.7 12.7 2.7 4.6 4.7 9.8 4.5 15.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1668.65 756.674c-.6-3.9-2.7-7.4-4.6-10.8-1.5-2.7-3-5.6-3.5-8.7-.2-1.3.6-2.6 1.9-2.9 1.1-.3 2.3.2 2.9 1.2 2.4 4.1 6.3 7.5 9.3 11.1 3.4 4.1 7 8.1 9.7 12.7 2.7 4.6 4.7 9.8 4.5 15.1M1647.15 763.272c-5.6-3.1-9.2-8.9-12.2-14.5-2.6-4.9-5-10.1-8.7-14.2-.9-1-.9-2.6.2-3.6.8-.7 1.9-.8 2.8-.3 3.3 1.7 6.2 4.2 8.4 7.1 2.6 3.4 4.9 7 7.8 10.1 5.3 5.4 12.7 8.7 20.3 8.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1621.15 732.473c1.7 3.8 3.5 7.6 5.2 11.4 2.1 4.6 4.2 9.2 6.9 13.5 4.8 7.7 11.2 14.3 17.8 20.6 7.3 7 16.2 12.5 25.5 16.6"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1619.55 737.273c5.1 12.1 12.2 24.2 22.8 32.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1840.95 723.772c-1.2 4.9-2.3 9.9-3.2 14.8-.4 2.1-.9 4.3-1.3 6.4-1.5 6.9-3.5 14-6.1 21-2.7 7.3-5.3 14-8.2 20.4-2.5 5.6-5.9 12.8-10.2 20-.8 1.3-1.7 2.6-2.8 3.7-4 7.8-12.2 12.8-20.9 12.8-1.5 0-3-.1-4.5-.4l-12.9-2.5-12.9-2.5c-7.9-1.5-16.3-3.4-25.8-5.7-1.4-.3-2.8-.7-4.2-1-2.8-.7-5.8-1.4-8.6-2.2-4.1-1.1-25.3-7.8-25.4-7.8 0 0 6-6.7 6.8-19.2.7-10-2.5-15-2.5-15 .7 0 24 .7 28.2 1.1 3 .2 6 .5 8.9.8 1.4.1 2.8.3 4.3.4 9.6 1 18.2 2.1 26.2 3.3l.9.1c.4-6.9 1.2-14 2.2-21.5.9-7.4 2.4-14.7 4.3-21.5l1.8-6.3c1.4-4.8 2.8-9.7 4-14.6v-.2"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <path
                fill="#A0A4AC"
                d="M1780.05 568.547c-.5 1-.4 1-.9 1.3-4.9 3.4-11 2.2-16.6 2.3-4.7.1-12.3 1.9-15.3 6.5-1 2.4-1.6 4.8-1.1 7.5.5 2.5 1.7 4.8 2.8 7.2 1.2 2.6 2.3 8.9 1.8 16.3h5.3s.7-3.1 5.5-7c4.8-3.8 8.9-3.7 11.6-.9 3.8 3.9 1.5 10.9-.1 14.7-2.2 5.1-4.6 7.6-9.1 11 .2 3.5.6 6.8 1.2 10.1.7 3.8 5.8 4.5 7.5 1.1 3.3-6.3 6.5-12.6 9.7-19.1 4.6-9.6 9.2-20 8.6-30.7-.6-11.1-5.5-17.5-10.9-20.3Z"
            />
            <path
                stroke="#838891"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1782.35 619.449c4.6-9.6 9.2-20 8.6-30.7-.6-11.1-5.5-17.5-10.9-20.3-.5 1-.4 1-.9 1.3-4.9 3.4-11 2.2-16.6 2.3-4.7.1-12.3 1.9-15.3 6.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1759.35 604.548c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={1823.01}
                    x2={1588.88}
                    y1={1029.86}
                    y2={1029.86}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.005} stopColor="#FFD101" />
                    <stop offset={0.533} stopColor="#FFEA8C" />
                    <stop offset={0.763} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={1619.56}
                    x2={1619.56}
                    y1={1132.34}
                    y2={924.012}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.374} stopColor="#FFD102" />
                    <stop offset={0.508} stopColor="#FFD309" />
                    <stop offset={0.604} stopColor="#FFD514" />
                    <stop offset={0.682} stopColor="#FFD825" />
                    <stop offset={0.748} stopColor="#FFDC3B" />
                    <stop offset={0.807} stopColor="#FFE157" />
                    <stop offset={0.86} stopColor="#FFE677" />
                    <stop offset={0.908} stopColor="#FFED9B" />
                    <stop offset={0.95} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1834.45}
                    x2={1834.45}
                    y1={952.971}
                    y2={773.309}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.099} stopColor="#FFD30D" />
                    <stop offset={0.283} stopColor="#FFDA30" />
                    <stop offset={0.532} stopColor="#FFE468" />
                    <stop offset={0.833} stopColor="#FFF1B5" />
                    <stop offset={0.886} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1718.34}
                    x2={1718.34}
                    y1={1154.57}
                    y2={993.121}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.097} stopColor="#FFD30C" />
                    <stop offset={0.273} stopColor="#FFD92D" />
                    <stop offset={0.509} stopColor="#FFE362" />
                    <stop offset={0.793} stopColor="#FFF0AB" />
                    <stop offset={0.881} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1886.32}
                    x2={1886.32}
                    y1={1132.34}
                    y2={924.012}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.374} stopColor="#FFD102" />
                    <stop offset={0.508} stopColor="#FFD309" />
                    <stop offset={0.604} stopColor="#FFD514" />
                    <stop offset={0.682} stopColor="#FFD825" />
                    <stop offset={0.748} stopColor="#FFDC3B" />
                    <stop offset={0.807} stopColor="#FFE157" />
                    <stop offset={0.86} stopColor="#FFE677" />
                    <stop offset={0.908} stopColor="#FFED9B" />
                    <stop offset={0.95} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1917.08}
                    x2={1917.08}
                    y1={1233.98}
                    y2={969.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.678} stopColor="#FFEA8C" />
                    <stop offset={0.971} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={1482.94}
                    x2={739.203}
                    y1={927.706}
                    y2={927.706}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={859.465}
                    x2={1055}
                    y1={856.344}
                    y2={749.481}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.969} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFD207" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1381.27}
                    x2={1057.13}
                    y1={686.274}
                    y2={895.46}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.36} stopColor="#FFF4C3" />
                    <stop offset={0.412} stopColor="#FFDE49" />
                    <stop offset={0.443} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1342.07}
                    x2={817.493}
                    y1={1106.19}
                    y2={1106.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={1364.22}
                    x2={1442.3}
                    y1={1106.19}
                    y2={1106.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.191} stopColor="#707276" />
                    <stop offset={1} stopColor="#AEAEAF" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={838.493}
                    x2={787.915}
                    y1={1106.19}
                    y2={1106.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={1192.19}
                    x2={1233.48}
                    y1={584.324}
                    y2={584.324}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={1178.09}
                    x2={1338.76}
                    y1={621.952}
                    y2={621.952}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={1210.95}
                    x2={1210.95}
                    y1={555.745}
                    y2={488.548}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={1169.6}
                    x2={1179.84}
                    y1={561.632}
                    y2={544.193}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={1221.36}
                    x2={1207.57}
                    y1={490.048}
                    y2={490.048}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={1199.05}
                    x2={1176.71}
                    y1={490.261}
                    y2={490.261}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={1070.68}
                    x2={1273.66}
                    y1={680.165}
                    y2={680.165}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={1204.04}
                    x2={998.601}
                    y1={837.561}
                    y2={769.957}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={1103.94}
                    x2={1174.45}
                    y1={894.789}
                    y2={731.965}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={1288.78}
                    x2={1348.54}
                    y1={1062.81}
                    y2={1062.81}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={1114.86}
                    x2={1198.56}
                    y1={678.428}
                    y2={678.428}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={1132.14}
                    x2={1202.58}
                    y1={676.714}
                    y2={676.714}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1144.92}
                    x2={1075.53}
                    y1={508.256}
                    y2={692.16}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="G"
                    x1={1167.22}
                    x2={1167.22}
                    y1={481.521}
                    y2={450.926}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.226} stopColor="#888B8D" />
                    <stop offset={0.859} stopColor="#D9D9D6" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1721.56}
                    x2={1684.98}
                    y1={686.958}
                    y2={686.958}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1738.48}
                    x2={1572.33}
                    y1={725.569}
                    y2={725.569}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1703.7}
                    x2={1703.7}
                    y1={657.041}
                    y2={589.845}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="L"
                    x1={1745.05}
                    x2={1734.82}
                    y1={662.929}
                    y2={645.49}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1723.46}
                    x2={1723.46}
                    y1={658.139}
                    y2={623.904}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.144} stopColor="#A0A4AC" stopOpacity={0} />
                    <stop offset={1} stopColor="#A0A4AC" stopOpacity={0.805} />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1693.29}
                    x2={1707.08}
                    y1={591.344}
                    y2={591.344}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1715.6}
                    x2={1737.94}
                    y1={591.558}
                    y2={591.558}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1843.97}
                    x2={1640.99}
                    y1={781.462}
                    y2={781.462}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1710.61}
                    x2={1916.05}
                    y1={938.858}
                    y2={871.253}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="R"
                    x1={1625.87}
                    x2={1566.11}
                    y1={1164.11}
                    y2={1164.11}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={1824.7}
                    x2={1600.57}
                    y1={992.445}
                    y2={992.445}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.039} stopColor="#888B8D" />
                    <stop offset={0.954} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="T"
                    x1={1684.94}
                    x2={1584.63}
                    y1={741.069}
                    y2={741.069}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={1680.3}
                    x2={1584.22}
                    y1={744.198}
                    y2={744.198}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={1810.52}
                    x2={1774.88}
                    y1={628.256}
                    y2={733.824}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <clipPath id="m">
                    <path fill="#fff" d="M2370.05 715h-336.818v478.211h336.818z" />
                </clipPath>
                <clipPath id="n">
                    <path fill="#fff" d="M642 817.406H377v376h265z" />
                </clipPath>
                <clipPath id="o">
                    <path fill="#fff" d="M944.641 225H586.052v358.589h358.589z" />
                </clipPath>
                <clipPath id="p">
                    <path fill="#fff" d="M1316.64 225H958.051v358.589h358.589z" />
                </clipPath>
                <clipPath id="q">
                    <path fill="#fff" d="M2317.45 225h-358.589v358.589h358.589z" />
                </clipPath>
                <clipPath id="F">
                    <path fill="#fff" d="M1126.52 440.984h70.049v86.693h-70.049z" />
                </clipPath>
                <radialGradient
                    id="x"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(1111.55 820.283) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="J"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 901.55 460.79) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
            </defs>
        </svg>
    );
}
