import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6372)"
            d="M541.444 345.693c-1.643-19.047-9.339-34.034-11.685-39.734-4.436-10.769-5.246-21.785-2.282-31.018 1.718-5.35 1.501-12.026-.645-19.843-5.893-21.468-25.107-63.041-52.274-82.895a89.652 89.652 0 00-9.312-5.855s1.383 9.149-2.195 14.191a9.773 9.773 0 01-5.68 3.396 22.506 22.506 0 01-3.997.36c-7.456.001-14.532-3.373-22.999-10.929-1.916-1.71-3.865-3.777-5.927-5.966-4.213-4.472-8.978-9.496-14.871-12.904-17.725 7.173-34.144 16.035-48.889 27.894a50.227 50.227 0 00-18.456 40.175l.457 19.058a327.337 327.337 0 0010.716 77.382c.032 3.805.033 7.485.035 11.171.008 20.126 5.373 84.666 5.373 84.666s70.531-10.553 97.519-7.586c33.729 3.709 63.99 2.378 75.052-13.643 0 0 12.474-19.943 10.06-47.92z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6372"
                x1="533.845"
                x2="338.643"
                y1="279.681"
                y2="286.187"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#ABADAD"></stop>
                <stop offset="0.973" stopColor="#888B8D"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
