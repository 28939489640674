import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-4">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-4-a)"
            d="M413.606 265.22c.086-3.028.134-6.084.142-9.149a161.97 161.97 0 0 0-1.334-22.991c-.293-2.281-.604-4.56-.915-6.839-.707-5.185-1.436-10.547-1.942-15.833l-.017-.17a31.89 31.89 0 0 0-31.801-28.781 32.038 32.038 0 0 0-31.875 35.225c.535 5.279.864 10.678 1.182 15.9.14 2.297.28 4.595.436 6.888a162.42 162.42 0 0 0 3.174 22.805 363.156 363.156 0 0 0 2.528 11.584l60.422-8.639Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-4-a"
                x1={383.792}
                x2={372.515}
                y1={268.347}
                y2={177.263}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
