import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-6-cycling-1-left-a)">
        <path
            d="M505.847 703.339c-2.206-2.284-6.431-6.612-9.932-9.937-14.997-14.242-24.745-46.885-24.528-47.293l21.022-39.488-45.686-7.855c-.536 2.388-1.018 4.838-1.484 7.207a543.74 543.74 0 0 1-.797 4 654.193 654.193 0 0 0-4.196 22.57s-6.62 13.826-15.021 28.738a9.988 9.988 0 0 0 1.122 11.415 9.994 9.994 0 0 0 3.2 2.472l18.265 8.928a41.173 41.173 0 0 1 10.39 7.175 70.668 70.668 0 0 0 17.34 11.936c3.65 1.814 17.264 6.186 25.054 8.63a5.16 5.16 0 0 0 6.284-2.902 5.16 5.16 0 0 0-1.033-5.596Z"
            fill="#E2E2E2"
        />
        <path
            d="M501.522 712.543a6.164 6.164 0 0 1-1.842-.294c-7.802-2.497-21.446-6.964-25.149-8.832a71.68 71.68 0 0 1-17.517-12.208 40.189 40.189 0 0 0-10.097-7.057l-17.903-8.878a11.44 11.44 0 0 1-5.837-6.456 10.891 10.891 0 0 1 .858-9.002c7.524-13.179 8.435-14.463 10.189-18.429a2.18 2.18 0 0 1 .25-.411 3.082 3.082 0 0 1 2.76-1.157 3.093 3.093 0 0 1 2.446 1.725c2.06 4.152 8.335 13.802 21.448 11.777a85.144 85.144 0 0 0 9.163-2.589 3.229 3.229 0 0 1 3.842 1.453c.495.945.876 1.946 1.132 2.982 1.001 3.745 7.03 23.685 20.837 36.948 3.398 3.263 7.486 7.492 9.731 9.84a6.445 6.445 0 0 1 1.867 4.723 6.169 6.169 0 0 1-6.178 5.865Z"
            fill="#63666A"
        />
        <path
            d="M459.461 679.872c-9.785-13.299-25.629-20.424-33.711-23.071-.558.983-1.125 1.982-1.715 3.014a10.891 10.891 0 0 0-.858 9.002 11.44 11.44 0 0 0 5.837 6.456l17.903 8.878a40.138 40.138 0 0 1 10.097 7.057 71.707 71.707 0 0 0 17.517 12.208c3.703 1.868 17.347 6.335 25.149 8.831a6.074 6.074 0 0 0 4.763-.439c-7.164-3.727-35.428-18.95-44.982-31.936Z"
            fill="#E2E2E2"
        />
        <defs>
            <clipPath id="man-34-25-shoes-6-cycling-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .094)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
