export const enum TGender {
    male = 'male',
    female = 'female',
}

export type TSettings = {
    gender: TGender;
}

export type TSkinColor = 'black' | 'white' | 'brown' | 'yellow';

type TRC = ({ option }: { option?: number }) => JSX.Element;
export type TSkinEl = ({ color }: { color: TSkinColor }) => JSX.Element;

type ASkinEl = [TSkinEl, TSkinEl?];
export type TLegsSkins = {
    stand1: [ASkinEl, ASkinEl?],
    stand2: [ASkinEl, ASkinEl?],
    walk1: [ASkinEl, ASkinEl?],
    walk2: [ASkinEl, ASkinEl?],
    cycling1?: [ASkinEl, ASkinEl?],
    cycling2?: [ASkinEl, ASkinEl?],
    sitting1?: [ASkinEl, ASkinEl?],
    sitting2?: [ASkinEl, ASkinEl?],
}

export type THairColor = 'gray' | 'black' | 'yellow' | 'brown' | 'red';

export interface IBodyClothes {
    text: string;
    disableLegsClothes?: boolean;
    allowWithoutSleeves?: boolean;
    normal?: {
        front: TRC;
        back?: TRC;
        rightArm: Record<string, TRC | null>;
        leftArm: Record<string, TRC | null>;
        bothArms?: Record<string, [TRC, TRC]>
    }
    sitting?: {
        front: TRC;
        front2?: TRC;
        back?: TRC;
        rightArm?: Record<string, TRC>;
        leftArm?: Record<string, TRC>;
        bothArms?: Record<string, [TRC, TRC]>
    }
    cycling1?: {
        front: TRC;
        back?: TRC;
        bothArms: Record<string, [TRC, TRC]>
    },
    cycling2?: {
        front: TRC;
        back?: TRC;
        bothArms?: Record<string, [TRC, TRC]>
    }
}

export type TBodyPosition = 'normal' | 'sitting' | 'cycling1' | 'cycling2';

export interface ILegs {
    name: string;
    skinOpt2?: 0 | 1;
    stand1?: [TRC, TRC?];
    stand2?: [TRC, TRC?];
    walk1?: [TRC, TRC?];
    walk2?: [TRC, TRC?];
    sitting1?: [TRC, TRC];
    sitting2?: [TRC, TRC];
    cycling1?: [TRC, TRC | null];
    cycling2?: [TRC, TRC | null];
}

export type TTabs = 'Character' | 'Objects' | 'Scenes';
export type TPages = 'builder';

export interface TPageContext {
    page: TPages,
    tab: TTabs,
}

export type TModal = '' | 'create';
export type THairStyle = [string, ({ color }: { color: THairColor }) => JSX.Element];
