import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-4-cycling-2-left-a)">
        <path
            d="M393.531 582.412c6.217-7.229 35.982-40.175 43.516-50.68 8.513-12.234 17.145-24.704 28.878-34.114l.018-.009c.647-.545 1.298-1.085 1.952-1.62-23.039-6.569-44.633-15.243-66.215-25.315-65.082-26.816-32.455-119.192 34.259-98.27 24.475 8.685 47.343 22.38 68.667 37.704 21.055 16.593 40.829 34.628 57.748 57.62a30.13 30.13 0 0 1 4.607 10.822 28.47 28.47 0 0 1 .332 13.697 27.234 27.234 0 0 1-10.497 17.392l-33.903 25.207c-32.416 24.129-69.38 50.954-102.393 74.817-5.355-5.059-18.294-18.287-26.969-27.251Z"
            fill="url(#man-34-25-pants-4-cycling-2-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-4-cycling-2-left-b"
                x1={366.323}
                y1={489.505}
                x2={567.966}
                y2={489.505}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <clipPath id="man-34-25-pants-4-cycling-2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
