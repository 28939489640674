import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9613)"
            d="M494.78 223.298a109.63 109.63 0 00-20.026-15.329 31.049 31.049 0 012.394 13.895 66.038 66.038 0 01-1.451 10.762l2.169 4.754a.986.986 0 00.562.523 1.004 1.004 0 00.768-.03c2.598-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9613)"
            d="M487.955 220.097l-.14-.047a27.498 27.498 0 00-34.703 17.558 27.5 27.5 0 0017.558 34.702c4.768 1.564 9.562 3.351 14.197 5.08l.822.304 14.556-53.822c-4.08-1.189-8.238-2.421-12.29-3.775z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9613"
                x1="496.299"
                x2="456.613"
                y1="185.398"
                y2="297.952"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9613"
                x1="495.033"
                x2="455.347"
                y1="184.946"
                y2="297.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
