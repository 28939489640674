import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6505)"
            d="M514.86 424.873l1.056 2.775c6.716-6.112 13.468-16.501 15.588-34.097-4.896 7.091-13.557 11.3-24.61 13.472a44.896 44.896 0 017.966 17.85z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6505)"
            d="M529.856 463.921l-14.811-38.862c-1.582-12.604-10.555-22.843-19.374-28.843-14.03-10.62-30.504-18.451-47.667-23.846a104.454 104.454 0 00-4.911-1.43 122.086 122.086 0 00-19.133-3.857c-21.109-2.564-42.301-.867-56.82 21.948-6.4 10.058-8.865 22.784-8.361 35.034a69.936 69.936 0 006.755 27.42c9.782 20.291 32.882 27.113 44.199 27.113 0 0-.132-.123-.356-.353.235.004.47.016.706.016h57.199c6.74 20.839 15.509 42.29 26.839 58.524 12.57 17.419 46.55 75.768 53.11 86.774 13.608-.379 34.211-10.789 38.595-17.2-17.735-45.677-37.789-97.195-55.97-142.438z"
        ></path>
        <path
            fill="url(#paint2_linear_10977_6505)"
            d="M540.771 612.665s8.244-.592 21.473-5.796a53.447 53.447 0 0018.886-12.582c-1.984-5.105-3.988-10.259-6.013-15.463 0 0-7.307 7.081-20.898 12.72-12.926 5.363-21.945 6.749-21.945 6.749a5841.966 5841.966 0 018.497 14.372z"
        ></path>
        <path
            fill="url(#paint3_linear_10977_6505)"
            d="M570.493 567.055l-1.922-4.93a14654.957 14654.957 0 00-19.051-48.617c-14.023 10.66-39.397 22.371-55.369 23.482 6.307 8.74 18.003 27.782 28.922 45.979 1.038 1.731 2.068 3.451 3.089 5.16 24.154-5.37 40.636-16.792 44.331-21.074z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6505"
                x1="502.653"
                x2="535.183"
                y1="411.863"
                y2="409.911"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6505"
                x1="529.967"
                x2="394.594"
                y1="407.161"
                y2="636.252"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_10977_6505"
                x1="532.274"
                x2="581.13"
                y1="595.744"
                y2="595.744"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_10977_6505"
                x1="473.388"
                x2="601.22"
                y1="751.407"
                y2="318.928"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
