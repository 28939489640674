import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M494.767 223.314a2.705 2.705 0 0 1 .821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0 1 21.853 16.353Z"
            fill="url(#a)"
        />
        <path
            d="M564.088 307.737c-1.351 0-2.699-.135-4.023-.404-9.158-1.884-17.899-4.495-24.49-6.559-7.623-2.416-15.341-5.13-23.599-8.299a202.147 202.147 0 0 1-22.757-10.146 892.563 892.563 0 0 1-6.622-3.485c-5.008-2.65-10.19-5.391-15.363-7.903a27.307 27.307 0 0 1-13.978-15.736 27.316 27.316 0 0 1 1.246-21.011 27.679 27.679 0 0 1 24.76-15.499 27.236 27.236 0 0 1 11.988 2.767l.117.057c5.162 2.536 10.508 4.935 15.678 7.255 2.277 1.022 4.552 2.049 6.824 3.081a201.661 201.661 0 0 1 21.999 11.71c7.568 4.557 14.455 8.974 21.047 13.498 5.686 3.933 13.12 9.221 20.233 15.281a19.962 19.962 0 0 1 7.002 13.698 19.96 19.96 0 0 1-4.736 14.637 20.102 20.102 0 0 1-15.326 7.058Z"
            fill="url(#b)"
        />
        <path
            d="M567.912 308.111a19.56 19.56 0 0 1-19.081-15.577l-.024-.122c-.647-3.249-1.466-6.528-2.258-9.698a483.007 483.007 0 0 1-1.044-4.23 86.483 86.483 0 0 1-2.162-14.367c-.046-.789-.103-1.568-.159-2.347-.116-1.607-.238-3.269-.261-4.937l-.113-3.617a61.719 61.719 0 0 1-.011-2.396c.003-.43.006-.86.005-1.29 15.316 8.008 27.461 3.092 33.099-6.702.17.407.34.803.51 1.198.316.734.631 1.468.918 2.207l1.309 3.397c.62 1.531 1.154 3.109 1.671 4.636.251.739.501 1.478.761 2.215a86.344 86.344 0 0 1 3.591 14.085c.237 1.434.459 2.869.682 4.304.502 3.229 1.02 6.569 1.686 9.81a19.36 19.36 0 0 1-2.816 14.656 19.377 19.377 0 0 1-12.356 8.371 19.604 19.604 0 0 1-3.947.404Z"
            fill="url(#c)"
        />
        <defs>
            <linearGradient
                id="a"
                x1={472.914}
                y1={222.636}
                x2={495.633}
                y2={222.636}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={451.727}
                y1={263.216}
                x2={584.215}
                y2={263.216}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={542.793}
                y1={275.47}
                x2={587.438}
                y2={275.47}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
