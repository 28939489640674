import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M465.6 644.601h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2Z"
                />
                <path
                    fill="#40C1E9"
                    d="M440 553.2h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.5 0 9.8 4.4 9.8 9.7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M454.6 644.1v-67.6c0-4.9-6.5-12.1-15.4-12.1h-92.3"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M346.9 553.301h92.3c15.1 0 26.4 12.2 26.4 23.1v67.6M345.3 728.1c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-a)"
                    d="M335 762.6c18.17 0 32.9-19.744 32.9-44.1 0-24.355-14.73-44.1-32.9-44.1-18.171 0-32.9 19.745-32.9 44.1 0 24.356 14.729 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-b)"
                    d="M239.4 718.5c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-c)"
                    d="M337.5 562.1c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4s-52.3-156.4-116.7-156.4Zm-12.4 293.7c-56.6 0-102.5-61.5-102.5-137.4S268.5 581 325.1 581c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M325.1 728.1c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m288.1 632-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.4-2.4.8-3.6ZM391.801 767.3c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M303.4 690.599c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-2-a"
                        x1={335.024}
                        x2={335.024}
                        y1={762.53}
                        y2={674.409}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-b"
                        x1={278.048}
                        x2={278.048}
                        y1={855.819}
                        y2={581.119}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-c"
                        x1={331.286}
                        x2={331.286}
                        y1={751.31}
                        y2={514.098}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.746} stopColor="#ABADAD" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M465.6 645.198h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2Z"
                />
                <path
                    fill="#FFF4C3"
                    d="M440 553.799h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.5-.1 9.8 4.3 9.8 9.7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M454.6 644.598v-67.6c0-4.9-6.5-12.1-15.4-12.1h-92.3"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M346.9 553.799h92.3c15.1 0 26.4 12.2 26.4 23.1v67.6M345.3 728.6c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-a)"
                    d="M335 763.1c18.17 0 32.9-19.744 32.9-44.1 0-24.355-14.73-44.1-32.9-44.1-18.171 0-32.9 19.745-32.9 44.1 0 24.356 14.729 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-b)"
                    d="M239.4 719c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.7-94.1-64.8-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-c)"
                    d="M337.5 562.6c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4s-52.3-156.4-116.7-156.4Zm-12.4 293.7c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M325.1 728.6c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m288.1 632.5-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.4-2.4.8-3.6ZM391.801 767.8c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.7-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M303.4 691.2c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-2-a"
                        x1={335.024}
                        x2={335.024}
                        y1={763.058}
                        y2={674.937}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-b"
                        x1={278.048}
                        x2={278.048}
                        y1={856.347}
                        y2={581.647}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-c"
                        x1={331.286}
                        x2={331.286}
                        y1={751.838}
                        y2={514.626}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.746} stopColor="#ABADAD" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M466.2 644.601h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2Z"
                />
                <path
                    fill="#0047A7"
                    d="M440.6 553.2H353c0-5.4 4.4-9.7 9.7-9.7h68.1c5.4 0 9.8 4.4 9.8 9.7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M455.2 644.1v-67.6c0-4.9-6.5-12.1-15.4-12.1h-92.3"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M347.4 553.301h92.3c15.1 0 26.4 12.2 26.4 23.1v67.6M345.901 728.1c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-a)"
                    d="M335.601 762.6c18.17 0 32.9-19.744 32.9-44.1 0-24.355-14.73-44.1-32.9-44.1-18.17 0-32.9 19.745-32.9 44.1 0 24.356 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-b)"
                    d="M240.001 718.5c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-c)"
                    d="M338 562.1c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4.1-86.4-52.2-156.4-116.7-156.4Zm-12.4 293.7c-56.6 0-102.5-61.5-102.5-137.4S269 581 325.6 581c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.4-5.5.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M325.6 728.1c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m288.7 632-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.3-2.4.8-3.6ZM392.401 767.3c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M304 690.599c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-2-a"
                        x1={335.581}
                        x2={335.581}
                        y1={762.53}
                        y2={674.409}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-b"
                        x1={278.605}
                        x2={278.605}
                        y1={855.819}
                        y2={581.119}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-c"
                        x1={331.842}
                        x2={331.842}
                        y1={751.31}
                        y2={514.098}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.746} stopColor="#ABADAD" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M466.2 645.198h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2Z"
                />
                <path
                    fill="#8E8B8F"
                    d="M440.6 553.799H353c0-5.4 4.4-9.7 9.7-9.7h68.1c5.4-.1 9.8 4.3 9.8 9.7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M455.2 644.598v-67.6c0-4.9-6.5-12.1-15.4-12.1h-92.3"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M347.4 553.799h92.3c15.1 0 26.4 12.2 26.4 23.1v67.6M345.901 728.6c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-a)"
                    d="M335.601 763.1c18.17 0 32.9-19.744 32.9-44.1 0-24.355-14.73-44.1-32.9-44.1-18.17 0-32.9 19.745-32.9 44.1 0 24.356 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-b)"
                    d="M240.001 719c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.7-94.1-64.8-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-2-c)"
                    d="M338 562.6c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4.1-86.4-52.2-156.4-116.7-156.4Zm-12.4 293.7c-56.6 0-102.5-61.5-102.5-137.4S269 581.5 325.6 581.5c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.4-5.5.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M325.6 728.6c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m288.7 632.5-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.3-2.4.8-3.6ZM392.401 767.8c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.7-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M304 691.2c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-2-a"
                        x1={335.581}
                        x2={335.581}
                        y1={763.058}
                        y2={674.937}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-b"
                        x1={278.605}
                        x2={278.605}
                        y1={856.347}
                        y2={581.647}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-2-c"
                        x1={331.842}
                        x2={331.842}
                        y1={751.838}
                        y2={514.626}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.746} stopColor="#ABADAD" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={900}
            height={900}
            viewBox={`0 0 ${900} ${900}`}
            fill="none"
            id="object-devices-wheelchair-2"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
