import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.499 279.902c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.787-9.422-18.228-14.919-23.949a47.595 47.595 0 0 0-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a22.004 22.004 0 0 1-8.678 1.436c-10.92 0-21.127-6.507-31.94-13.399-7.282-4.644-14.811-9.445-22.911-12.429a32.69 32.69 0 0 0-10.801-1.712 31.455 31.455 0 0 0-4.391.292c-.16.023-.314.078-.452.162a62.748 62.748 0 0 0-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.874 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a523.345 523.345 0 0 1-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.943-7.615-1.083-16.221-8.991-25.578Z"
            fill="url(#female-34-25-tshirt-2-a)"
        />
        <g filter="url(#female-34-25-tshirt-2-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0 0 12.049-48.824l-6.917-10.636"
                fill="url(#female-34-25-tshirt-2-c)"
            />
        </g>
        <defs>
            <radialGradient
                id="female-34-25-tshirt-2-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
            >
                <stop offset={0.439} stopColor="#FFD102" stopOpacity={0} />
                <stop offset={0.538} stopColor="#FFD102" stopOpacity={0.212} />
                <stop offset={0.742} stopColor="#FFD102" stopOpacity={0.632} />
                <stop offset={0.883} stopColor="#FFD102" stopOpacity={0.897} />
                <stop offset={0.949} stopColor="#FFD102" />
            </radialGradient>
            <linearGradient
                id="female-34-25-tshirt-2-a"
                x1={345.606}
                y1={316.992}
                x2={534.06}
                y2={316.992}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#FFD100" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <filter
                id="female-34-25-tshirt-2-b"
                x={402.73}
                y={251.023}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7201"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
