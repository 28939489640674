import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 248,
        height: 96,
        content: (
            <>
                <path
                    fill="#fff"
                    d="M246.919 1.852c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.669-9.368 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728C200.77 73.397 183 87.157 167.891 94.186H80.796c-15.12-7.03-32.88-20.79-47.892-37.664a159.187 159.187 0 0 1-4.905-5.728 170.406 170.406 0 0 1-8.94-11.91c-3.52-5.156-6.682-10.454-9.367-15.825C6.16 16.054 3.427 8.929 1.756 1.852h245.163Z"
                />
                <path
                    fill="url(#object-food-plate-a)"
                    d="M246.918 1.852c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063C97.992 80.32 57.881 40.22 48.787 1.852h198.131Z"
                />
                <path
                    fill="#00B140"
                    d="M238.994 23.059c-2.697 5.37-5.86 10.669-9.368 15.825H19.059c-3.52-5.156-6.683-10.454-9.368-15.825h229.303ZM220.687 50.793H27.998a158.323 158.323 0 0 0 4.905 5.728h182.879l4.905-5.728Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M203.518 69.08c19.87-18.515 37.715-43.21 43.404-67.225H1.758c9.088 38.366 49.204 78.47 79.034 92.332h87.096c6.171-2.867 12.782-6.858 19.468-11.724"
                />
                <defs>
                    <linearGradient
                        id="object-food-plate-a"
                        x1={48.787}
                        x2={246.918}
                        y1={-57.607}
                        y2={-57.607}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff" />
                        <stop offset={0.994} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        )
    },
    'Option #2': {
        width: 280,
        height: 95,
        content: (
            <>
                <path
                    fill="#fff"
                    d="M278.401 1.484H1.921c10.493 33.11 39.648 60.367 78.177 74.471V93.19h120.126V75.955c38.529-14.104 67.684-41.362 78.176-74.47h.001Z"
                />
                <path
                    fill="url(#object-food-plate-a)"
                    d="M34.066 1.484c19.642 27.574 57.93 58.645 91.442 74.471h74.716c38.529-14.104 67.684-41.362 78.176-74.47H34.066Z"
                />
                <mask
                    id="object-food-plate-b"
                    width={278}
                    height={93}
                    x={1}
                    y={1}
                    maskUnits="userSpaceOnUse"
                    style={{
                        maskType: 'luminance',
                    }}
                >
                    <path
                        fill="#fff"
                        d="M278.401 1.484H1.921c10.493 33.11 39.648 60.367 78.177 74.471V93.19h120.126V75.955c38.529-14.104 67.684-41.362 78.176-74.47h.001Z"
                    />
                </mask>
                <g mask="url(#object-food-plate-b)">
                    <path
                        fill="#8E8B8F"
                        d="M-5.861 29.923c-6.564 0-12.915-.706-18.771-2.375a9.5 9.5 0 0 1-6.533-11.74c1.438-5.047 6.695-7.973 11.74-6.533 13.579 3.87 35.2.93 60.887-8.276C73.455-10.468 91.268-32.38 104.274-48.38c1.894-2.33 3.684-4.53 5.401-6.556a9.5 9.5 0 1 1 14.487 12.294c-1.588 1.871-3.315 3.997-5.145 6.247C105.413-19.66 84.855 5.63 47.871 18.885 32.55 24.377 12.519 29.923-5.862 29.923Z"
                    />
                    <path
                        fill="#8E8B8F"
                        d="M-7.897 54.245c-.492.02-.982.038-1.477.055a9.501 9.501 0 0 1-.707-18.987l.057-.002c.452-.015.913-.032 1.363-.05C45.135 33.1 84.922 12.783 108.904-3.962c27.367-19.11 42.73-39.314 45.23-44.512 2.274-4.73 7.95-6.718 12.68-4.444a9.5 9.5 0 0 1 4.444 12.679c-4.455 9.262-22.37 31.53-51.475 51.855-26.066 18.2-69.302 40.287-127.679 42.63v-.001Z"
                    />
                    <path
                        fill="#616469"
                        d="M24.965 86.273a9.502 9.502 0 0 1-8.757-5.81 9.5 9.5 0 0 1 5.061-12.446c6.857-2.892 15.894-6.985 26.355-11.725 24.211-10.967 57.368-25.988 86.787-36.41 38.067-13.488 60.966-16.47 74.246-9.673 10.779 5.517 11.522 14.127 11.967 19.271.318 3.691.45 5.218 3.159 7.505 13.479 11.378 47.989-1.085 59.329-5.18a9.498 9.498 0 0 1 12.163 5.707 9.5 9.5 0 0 1-5.709 12.162c-26.151 9.446-58.657 18.19-78.039 1.83-8.707-7.35-9.412-15.513-9.833-20.39-.095-1.097-.237-2.754-.445-3.187-.008 0-.308-.324-1.248-.806-3.584-1.833-16.529-4.464-59.245 10.668-28.658 10.154-60.06 24.38-85.292 35.81-10.584 4.793-19.724 8.935-26.81 11.924a9.482 9.482 0 0 1-3.689.75Z"
                    />
                    <path
                        fill="#8E8B8F"
                        d="M264.855 34.313c-8.398 0-16.783-2.11-24.293-6.378-24.521-13.937-25.008-41.87-25.017-43.052a9.501 9.501 0 0 1 9.431-9.57h.07a9.5 9.5 0 0 1 9.498 9.396c.02.857.591 18.289 15.406 26.707 10.307 5.857 23.349 5.076 33.23-1.992 4.265-3.054 10.199-2.07 13.254 2.197 3.053 4.268 2.068 10.202-2.199 13.254a50.513 50.513 0 0 1-29.38 9.438Z"
                    />
                    <path
                        fill="#616469"
                        d="M43.155 82.23a9.503 9.503 0 0 1-8.79-5.89c-1.995-4.854.32-10.405 5.174-12.399 22.919-9.42 109.311-34.586 142.388-24.146 3.826 1.208 7.761 3.22 12.315 5.55 14.339 7.333 36.006 18.417 72.374 11.062 5.137-1.04 10.154 2.286 11.194 7.428 1.04 5.142-2.286 10.155-7.429 11.195-42.868 8.67-69.112-4.75-84.791-12.769-3.795-1.94-7.073-3.618-9.384-4.347C151.704 50.181 72.345 71 46.763 81.515a9.471 9.471 0 0 1-3.608.715Z"
                    />
                </g>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M103.129 93.19h97.095V75.954c38.529-14.104 67.684-41.362 78.176-74.47H1.922c10.492 33.108 39.647 60.366 78.176 74.47V93.19H91.38"
                />
                <defs>
                    <linearGradient
                        id="object-food-plate-a"
                        x1={238.863}
                        x2={49.389}
                        y1={41.573}
                        y2={26.921}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.006} stopColor="#D9D9D6" />
                        <stop offset={1} stopColor="#fff" />
                    </linearGradient>
                </defs>
            </>
        )
    },
    'Option #3': {
        width: 178,
        height: 80,
        content: (
            <>
                <path
                    fill="#fff"
                    d="M176.222 1.89c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-52.55c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h174.82Z"
                />
                <path
                    fill="url(#object-food-plate-a)"
                    d="M176.223 1.89c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-21.58c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14c-1.29-2.02-2.48-4.02-3.57-6-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h143.85Z"
                />
                <path
                    fill="#EF0E2E"
                    d="M171.702 17.242c-1.78 4.83-4.12 10.18-7.19 15.73h-151.4c-3.07-5.55-5.41-10.9-7.19-15.73h165.78Z"
                />
                <path
                    fill="#64CCC9"
                    d="M160.932 38.969c-1.73 2.71-3.65 5.43-5.77 8.14H22.452a107.07 107.07 0 0 1-5.77-8.14h144.25Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M142.487 60.623c23.592-20.993 31.168-47.194 33.734-58.728H1.401c2.564 11.534 10.141 37.734 33.733 58.728 9.672 8.608 19.484 14.07 27.403 17.566h52.547a106.408 106.408 0 0 0 15.546-8.463"
                />
                <defs>
                    <linearGradient
                        id="object-food-plate-a"
                        x1={32.373}
                        x2={176.223}
                        y1={40.041}
                        y2={40.041}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff" />
                        <stop offset={0.994} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        )
    },
    'Option #4': {
        width: 381,
        height: 20,
        content: (
            <>
                <path
                    fill="url(#object-food-plate-a)"
                    d="M236.764 1.492H1a56.544 56.544 0 0 0 40.475 17.06h297.298a56.544 56.544 0 0 0 40.475-17.06H236.764Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M319.343 18.552h19.431a56.544 56.544 0 0 0 40.475-17.06H1a56.544 56.544 0 0 0 40.475 17.06h245.784"
                />
                <defs>
                    <linearGradient
                        id="object-food-plate-a"
                        x1={1}
                        x2={379.248}
                        y1={10.022}
                        y2={10.022}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        )
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-plate"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
