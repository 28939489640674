import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 129,
        height: 234,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M127.151 35.183V1.836H21.478v231.031h21.134V95.815h21.135v137.052h21.135V95.815h21.134v137.052h21.135V60.573H1.34"
                />
            </>
        ),
    },
    'Outline #2': {
        width: 129,
        height: 234,
        content: (
            <>
                <path
                    d="M127.14 35.1829C127.14 16.7659 112.21 1.83594 93.793 1.83594H54.815C36.398 1.83594 21.468 16.7659 21.468 35.1829V232.867H42.603V95.8149H63.738V232.867H84.873V95.8149H106.008V232.867H127.143V60.5729H1.33203"
                    stroke="black"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </>
        ),
    },
    'Outline #3': {
        width: 242,
        height: 211,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m95.927 191.024-13.14-161.61M121.008 191.024V29.414M159.233 29.414l-13.145 161.61M121.007 29.414h-38.22M240.138 1.695c-22.005 0-40.765 15.955-44.298 37.675l-27.732 170.495h-94.2M70.848 191.025 46.177 39.369C42.644 17.65 23.884 1.695 1.879 1.695M70.848 191.023h25.08M121.008 191.023h25.08"
                />
            </>
        ),
    },
    'Outline #4': {
        width: 265,
        height: 198,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.43 196.868 33.23 1.328M101.852 196.868 72.932 1.328M122.361 196.868l-9.64-195.54M152.611 1.328l-9.65 195.54M192.59 1.328l-28.93 195.54M232.661 1.328l-48.21 195.54M112.722 1.328h-39.79M192.591 1.328h-39.98M263.68 1.328h-31.02M33.231 1.328H1.621M101.852 196.867h-20.42M184.45 196.867h-20.79M142.961 196.867h-20.6"
                />
            </>
        ),
    },
    'Outline #5': {
        width: 219,
        height: 183,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M217.212 20.66h-14.606a14 14 0 0 0-13.593 10.648l-37.12 150.558h-17.14l21.215-143.39c1.389-9.388-5.885-17.817-15.374-17.817h-.157c-8.286 0-15.114 6.5-15.523 14.776l-7.226 146.432h-16.983l-7.219-146.43C93.078 27.16 86.25 20.66 77.963 20.66c-9.489 0-16.763 8.43-15.374 17.816l21.207 143.392H66.961L29.85 31.31a14 14 0 0 0-13.593-10.649H1.164l.025-18.84h216.023"
                />
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-outdoor-trash"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
