import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="female-bold-34-25-legs-stand-2">
        <path
            d="M366.794 771.032a732.6 732.6 0 0 1 10.134-22.613c1.746-3.726 3.424-7.504 5.227-11.188l5.362-11.089c3.551-7.41 7.213-14.738 10.569-22.292 3.413-7.511 6.536-15.238 10.239-22.535 7.19-14.755 17.294-27.359 27.876-39.61a240.588 240.588 0 0 1 17.053-17.507c1.488-1.41 3.073-2.748 4.604-4.125 1.595-1.331 3.15-2.69 4.799-3.981a110.487 110.487 0 0 1 10.309-7.437l10.01-6.319a26.26 26.26 0 0 1 29.178.759 26.28 26.28 0 0 1 7.057 7.433 26.324 26.324 0 0 1-1.992 30.788l-30.957 37.336-15.488 18.662-15.6 18.578c-10.419 12.372-20.759 24.802-31.243 37.125l-31.39 37.018a15.928 15.928 0 0 1-26.801-16.551l1.054-2.452Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m392.539 790.038 31.39-37.017c10.484-12.324 20.824-24.754 31.243-37.125l15.601-18.579 15.487-18.661 30.957-37.337c3.538-3.454 5.33-10.749 5.33-10.749M367.473 769.448c3.262-7.623 5.967-13.57 9.452-21.028 1.747-3.726 3.424-7.504 5.228-11.188l5.361-11.089c3.551-7.41 7.214-14.737 10.569-22.292 3.414-7.511 6.536-15.237 10.239-22.535 7.19-14.755 17.295-27.359 27.877-39.61a240.38 240.38 0 0 1 17.053-17.507c1.487-1.41 3.072-2.747 4.604-4.125 1.594-1.33 6.4-5.307 8.049-6.597"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M366.791 771.031c-3.143 2.32-11.206 6.151-19.894 10.448a8.103 8.103 0 0 0-4.153 4.89 8.107 8.107 0 0 0 .71 6.377l12.528 21.997s.714 17.201 17.224 35.812c3.831 4.319 8.232 6.865 10.915 5.337 2.648-1.508.68-9.617-.999-14.925-5.751-18.183 3.415-47.396 3.415-47.396"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M366.791 771.031c-3.143 2.32-11.206 6.151-19.894 10.448a8.103 8.103 0 0 0-4.153 4.89 8.107 8.107 0 0 0 .71 6.377l12.528 21.997s.714 17.201 17.224 35.812c3.831 4.319 8.232 6.865 10.915 5.337 2.648-1.508.68-9.617-.999-14.925-5.751-18.183 3.415-47.396 3.415-47.396"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m471.077 628.359-14.594-42.872-3.663-10.717-3.774-10.7c-2.488-7.138-5.283-14.235-7.712-21.381-2.429-7.146-4.609-14.325-6.411-21.556-.936-3.61-1.71-7.243-2.572-10.864l-1.261-5.435-1.112-5.455c-.73-3.639-1.527-7.268-2.126-10.925-.631-3.652-1.331-7.294-1.834-10.964l-.819-5.495-.678-5.514c-.445-3.677-.777-7.37-.995-11.077a51.014 51.014 0 0 1 79.76-45.145 51.03 51.03 0 0 1 19.767 26.551l1.549 4.9c1.142 3.583 2.099 7.19 2.959 10.811l1.258 5.436 1.083 5.46c.775 3.632 1.308 7.297 1.926 10.951.635 3.652 1.125 7.323 1.611 10.994.969 7.344 1.662 14.725 2.248 22.12.582 7.396.91 14.827.915 22.301.012 7.473-.448 15.01-1.054 22.567l-.939 11.34c-.339 3.784-.768 7.579-1.148 11.368-1.738 15.185-4.017 30.444-8.155 45.954l-1.485 5.569a27.583 27.583 0 0 1-12.828 16.757 27.584 27.584 0 0 1-20.92 2.777 27.852 27.852 0 0 1-18.996-17.756Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m464.708 609.648-8.225-24.161-3.663-10.717-3.774-10.7c-2.488-7.138-5.283-14.235-7.712-21.381-2.429-7.146-4.609-14.325-6.411-21.556-.936-3.61-1.71-7.243-2.572-10.864l-1.261-5.435-1.112-5.455c-.73-3.639-1.527-7.268-2.126-10.925-.631-3.652-1.331-7.294-1.834-10.964l-.819-5.495-.678-5.514c-.445-3.677-.777-7.37-.995-11.077a51.014 51.014 0 0 1 79.76-45.145 51.03 51.03 0 0 1 19.767 26.551l1.549 4.9c1.142 3.583 2.099 7.19 2.959 10.811l1.258 5.436 1.083 5.46c.775 3.632 1.308 7.297 1.926 10.951.635 3.652 1.125 7.323 1.611 10.994.969 7.344 1.662 14.725 2.248 22.12.582 7.396.91 14.827.915 22.301.012 7.473-.448 15.01-1.054 22.567l-.939 11.34c-.339 3.784-.768 7.579-1.148 11.368-1.738 15.185-4.017 30.444-8.155 45.954l-1.485 5.569"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.312a90.41 90.41 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.616 131.422 44.512 24.407-21.936 36.193-41.867 40.779-59.909H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.312a90.41 90.41 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.616 131.422 44.512 24.407-21.936 36.193-41.867 40.779-59.909"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M399.498 815.717a726.05 726.05 0 0 1-6.054-23.917c-.937-3.988-1.961-7.974-2.827-11.964l-2.661-11.97c-1.803-7.98-3.468-15.963-5.513-23.935-1.974-7.973-4.309-15.935-5.923-23.92-3.497-15.962-3.373-32.041-2.656-48.14a238.257 238.257 0 0 1 2.542-24.195c.294-2.019.71-4.042 1.058-6.063.428-2.023.806-4.044 1.3-6.07.927-4.12 2.09-8.184 3.486-12.17l3.976-11.203a26.256 26.256 0 0 1 33.528-15.962 26.307 26.307 0 0 1 17.467 25.382l-1.171 48.263-.597 24.133-.737 24.136c-.515 16.092-.932 32.181-1.527 48.276l-1.709 48.281a15.932 15.932 0 0 1-5.06 11.089 15.928 15.928 0 0 1-26.225-7.462l-.697-2.589Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m381.893 623.527-10.337-43.165-2.598-10.791-2.711-10.787c-1.78-7.192-3.867-14.372-5.587-21.567-1.719-7.194-3.188-14.398-4.275-21.616-.579-3.608-.994-7.222-1.499-10.833l-.724-5.417-.574-5.422c-.37-3.616-.81-7.229-1.049-10.85-.27-3.619-.612-7.236-.754-10.86l-.279-5.433-.136-5.44c-.083-3.626-.053-7.257.092-10.892a51 51 0 0 1 16.322-35.47 51.012 51.012 0 0 1 36.622-13.54 51.038 51.038 0 0 1 47.876 40.045l1.107 5.129c.787 3.6 1.387 7.207 1.889 10.818l.72 5.417.545 5.424c.417 3.614.589 7.237.848 10.857.275 3.619.404 7.244.53 10.868.246 7.25.215 14.51.077 21.775-.144 7.264-.543 14.538-1.268 21.825-.717 7.286-1.912 14.59-3.253 21.9l-2.042 10.965c-.706 3.656-1.504 7.316-2.252 10.974-3.213 14.639-6.972 29.298-12.604 44.028l-2.097 5.486a27.585 27.585 0 0 1-35.615 15.913 27.854 27.854 0 0 1-16.974-19.341Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m400.196 818.307-.698-2.589a725.359 725.359 0 0 1-6.054-23.916c-.937-3.989-1.961-7.974-2.827-11.965l-2.661-11.97c-1.803-7.979-3.468-15.963-5.513-23.934-1.974-7.973-4.309-15.935-5.923-23.921-3.497-15.961-3.373-32.04-2.656-48.139a238.307 238.307 0 0 1 2.542-24.196c.294-2.018.71-4.041 1.058-6.062.428-2.023.806-4.045 1.3-6.07a109.55 109.55 0 0 1 3.486-12.17M437.221 621.586l-1.171 48.264-.597 24.132-.737 24.137c-.515 16.092-.931 32.18-1.527 48.275l-1.709 48.281M381.893 623.528l-10.337-43.166-2.598-10.791-2.711-10.786c-1.78-7.192-3.867-14.373-5.587-21.567-1.719-7.195-3.188-14.398-4.275-21.617-.579-3.607-.994-7.221-1.499-10.832l-.724-5.417-.574-5.423c-.37-3.615-.81-7.228-1.049-10.849-.27-3.619-.612-7.236-.754-10.86l-.279-5.434-.136-5.439c-.083-3.627-.053-7.257.092-10.892a51.099 51.099 0 0 1 1.803-11.658M452.281 441.484l1.107 5.129c.787 3.6 1.387 7.207 1.889 10.818l.72 5.417.545 5.424c.417 3.614.589 7.237.848 10.857.275 3.619.404 7.244.53 10.868.246 7.25.216 14.51.077 21.775-.143 7.264-.543 14.538-1.268 21.825-.717 7.286-1.912 14.59-3.253 21.9l-2.042 10.965c-.706 3.656-1.504 7.316-2.252 10.974a313.444 313.444 0 0 1-11.561 41.244"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M401.239 819.313c.46 3.878-.296 18.784-.862 28.46a8.105 8.105 0 0 0 4.878 7.907 8.081 8.081 0 0 0 3.208.661h43.739c5.774 0 10.552-1.331 10.552-4.418 0-3.047-9.357-5.834-13.975-8.944-7.637-5.143-18.276-23.667-18.276-23.667"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M401.239 819.313c.46 3.878-.296 18.784-.862 28.46a8.105 8.105 0 0 0 4.878 7.907 8.081 8.081 0 0 0 3.208.661h43.739c5.774 0 10.552-1.331 10.552-4.418 0-3.047-9.357-5.834-13.975-8.944-7.637-5.143-18.276-23.667-18.276-23.667"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
