import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m451.887 780.704-.06-.936c-3.165-49.247-3.937-57.958-4.191-60.82-.115-1.287-.116-1.319-.182-3.376l-.042-1.319c-1.189-23.832 8.998-64.579 21.848-87.506-2.207-6.138-26.955-74.954-28.192-78.228-11.043-29.224-19.303-69.605-21.02-87.52l-.064-.662 84.106-45.103.516.42a51.95 51.95 0 0 1 16.215 22.886c.314 1.065.677 2.039 1.135 3.271 1.642 4.419 4.217 11.852 9.007 34.303 16.76 78.554 1.484 145.274-25.44 267.83a24681.932 24681.932 0 0 0-7.874 35.972l-.172.788h-45.59Z"
            fill="#004F71"
        />
        <path
            d="m386.219 787.339-.18-.775c-10.331-44.6-12.605-53.238-13.46-56.49-.504-1.913-.569-2.159-1.4-6.273-5.527-25.231-2.51-70.11 6.452-96.236-.548-2.364-3.896-17.405-9.169-41.106-4.765-21.418-9.691-43.564-10.135-45.419-5.546-23.19-10.59-65.875-9.04-88.29a86.584 86.584 0 0 0-.363-16.973 69.826 69.826 0 0 1 2.22-27.69l.202-.735h170.127l-.025 1.83c-7.086 18.943-23.76 39.228-44.911 56.489a50.936 50.936 0 0 0-18.798 39.858c.049 11.419-.032 22.346-.992 32.004-1.532 15.545-7.65 45.712-14.595 69.69a145.106 145.106 0 0 0-5.612 36.894c-.829 34.637-3.081 103.994-4.351 142.254l-.031.967-45.939.001Z"
            fill="url(#female-34-25-pants-3-stand-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-3-stand-1-a"
                x1={536.822}
                y1={565.406}
                x2={340.045}
                y2={608.73}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0B86B8" />
                <stop offset={1} stopColor="#004F71" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
