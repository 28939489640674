import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6580)"
            d="M553.356 305.224l-.23-.102c-5.816-2.578-11.303-4.752-15.436-6.358-.694-.265-1.388-.534-2.08-.803l-.116-.045a182.684 182.684 0 00-3.652-1.389l-5.843-2.116c-2.627-.983-5.313-1.883-7.948-2.766-1.281-.43-2.562-.859-3.846-1.302a255.568 255.568 0 00-23.968-6.857l-12.074-2.761-10.313-2.483c-4.503-5.108-9.1-9.652-12.171-12.63-5.12-4.929-10.517-9.773-16.502-14.807a158.53 158.53 0 00-17.727-13.19c-1.857-1.192-3.723-2.371-5.598-3.556-4.229-2.673-8.602-5.437-12.783-8.315l-.119-.081a27.6 27.6 0 00-15.589-4.811 27.743 27.743 0 00-22.965 12.14 27.579 27.579 0 00-4.321 20.794 27.564 27.564 0 0011.65 17.76c4.203 2.862 8.367 5.941 12.393 8.918 1.771 1.31 3.542 2.62 5.319 3.915a158.034 158.034 0 0018.706 11.748c6.873 3.765 13.345 7.045 19.789 10.031a201.008 201.008 0 0020.949 8.441c1.801.576 21.05 6.248 30.791 8.395l2.254.497c7.451 1.646 15.156 3.347 22.645 4.651 1.313.223 2.622.455 3.932.687l.044-.246.025.259c2.708.481 5.508.979 8.283 1.374l6.159.944c1.293.194 2.591.363 3.89.532l.17.022c.714.093 1.428.185 2.149.283 4.153.538 9.695 1.22 15.74 1.738l.291.024-.02-.29c-.6-8.851 5.215-21.778 7.697-27.298l.425-.947z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6580"
                x1="557.243"
                x2="339.291"
                y1="322.053"
                y2="235.523"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
