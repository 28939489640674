import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6386)"
            d="M439.987 368.444c-6.508-.489-14.864-.22-21.372-.014-16.929.305-37.994 6.049-49.324 19.981-16.443 20.219-8.523 56.635 4.262 72.043a27.894 27.894 0 006.18 5.406 46.725 46.725 0 0030.675 11.431s-13.883-14.041-13.016-47.438c1.136-43.732 22.554-57.253 42.595-61.409z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6386"
                x1="401.87"
                x2="399.268"
                y1="479.908"
                y2="360.197"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729"></stop>
                <stop offset="0.225" stopColor="#312E30"></stop>
                <stop offset="0.564" stopColor="#434245"></stop>
                <stop offset="0.972" stopColor="#5F6267"></stop>
                <stop offset="0.999" stopColor="#616469"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
