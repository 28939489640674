import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m452.442 780.672-.06-.936c-3.165-49.247-3.938-57.957-4.192-60.819-.114-1.288-.115-1.319-.181-3.376l-.042-1.319c-1.19-23.832 8.998-64.579 21.847-87.506-2.207-6.138-26.955-74.954-28.191-78.228-11.043-29.224-19.304-69.605-21.021-87.52l-.063-.662 84.105-45.103.517.419a51.952 51.952 0 0 1 16.215 22.887c.314 1.064.677 2.038 1.135 3.271 1.642 4.419 4.489 10.882 9.586 33.266 18.036 79.204.905 146.31-26.018 268.866a26367.943 26367.943 0 0 0-7.875 35.973l-.172.787h-45.59Z"
            fill="#001489"
        />
        <path
            d="m386.773 787.307-.18-.775c-10.331-44.6-12.604-53.237-13.46-56.49-.504-1.913-.568-2.158-1.4-6.273-5.526-25.231-2.51-70.11 6.452-96.236-.547-2.364-3.895-17.405-9.168-41.106-4.765-21.417-9.692-43.564-10.135-45.418-5.546-23.19-10.59-65.876-9.04-88.291a86.584 86.584 0 0 0-.364-16.973 69.84 69.84 0 0 1 2.221-27.689l.201-.736h170.128l.273 1.941c-7.086 18.943-24.058 39.118-45.209 56.379-12.013 9.803-18.363 24.672-18.297 40.199a296.672 296.672 0 0 1-1.875 31.421c-1.532 15.545-6.794 45.553-13.74 69.531a148.517 148.517 0 0 0-6.085 37.294c-.829 34.638-2.44 103.904-3.71 142.164l-.031.967-46.581.091Z"
            fill="url(#female-34-25-pants-7-stand-1-a)"
        />
        <path
            d="M381.782 765.862h52.231c.172-5.928.347-12.19.522-18.651h-57.16c1.136 4.751 2.575 10.83 4.407 18.651Z"
            fill="url(#female-34-25-pants-7-stand-1-b)"
        />
        <path
            d="M371.732 723.771c.734 3.632.871 4.251 1.24 5.661h62.033c.16-6.19.319-12.44.475-18.651h-65.709a111.815 111.815 0 0 0 1.961 12.99Z"
            fill="url(#female-34-25-pants-7-stand-1-c)"
        />
        <path
            d="M506.879 740.273h-57.102c.34 4.955.753 11.073 1.252 18.651h51.754c1.116-5.09 2.218-10.106 3.296-15.013l.8-3.638ZM514.915 703.844h-66.831a94.606 94.606 0 0 0-.117 10.381l.042 1.319c.066 2.058.067 2.089.181 3.376.061.69.153 1.721.3 3.575h62.311c1.407-6.369 2.779-12.586 4.114-18.651Z"
            fill="#9E005D"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-7-stand-1-a"
                x1={362.034}
                y1={598.073}
                x2={461.052}
                y2={595.468}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#001489" />
                <stop offset={1} stopColor="#0147A7" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-1-b"
                x1={377.375}
                y1={756.536}
                x2={434.535}
                y2={756.536}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-1-c"
                x1={369.771}
                y1={720.107}
                x2={435.48}
                y2={720.107}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
