import React from 'react';

const SvgComponent = () => (
    <g id="female-bold-34-25-emotion-normal" transform="translate(316, 30)">
        <path
            d="M127.701 94.202c4.1-3.5 9.8-5.1 15.1-4.3M157.8 90.698c3.2-.2 6.5.6 9.2 2.3M141.5 131.398c4.2 2.2 9.2 3.1 13.9 2.5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M137.602 105.101c-.773 0-1.4-1.075-1.4-2.4 0-1.326.627-2.4 1.4-2.4.773 0 1.4 1.074 1.4 2.4 0 1.325-.627 2.4-1.4 2.4ZM161.202 105.101c-.774 0-1.4-1.075-1.4-2.4 0-1.326.626-2.4 1.4-2.4.773 0 1.4 1.074 1.4 2.4 0 1.325-.627 2.4-1.4 2.4Z"
            fill="#111011"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
