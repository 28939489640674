import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 248,
        height: 321,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M32.728 246.702H239.98l6.63-19.64h-209.2M37.408 227.064l36.8-154.22h33.87"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m32.728 246.707-17.29 72.46H1.568l51.88-264.94M183.47 35.61l55.523 283.555h-13.874l-63.204-264.94H53.447"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m132.482 72.844-5.266 246.324h-13.874l-5.266-246.324M197.104 35.609c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H56.272c-13.033 0-23.598 10.565-23.598 23.598 0 5.956 4.828 10.784 10.784 10.784H183.47"
                />
            </>
        ),
    },
    'Color #1': {
        width: 239,
        height: 320,
        content: (
            <>
                <path
                    fill="url(#object-office-bar-stool-a)"
                    d="m132.41 35.876-6.062 283.555h-13.874l-6.062-283.568 25.998.013Z"
                />
                <path fill="#AEAEAF" d="M220.462 227.328H18.357v18.616h202.105v-18.616Z" />
                <path
                    fill="#AEAEAF"
                    d="M56.22 35.876.697 319.431h13.874L82.22 35.863l-25.999.013ZM182.599 35.876l55.523 283.555h-13.874L156.6 35.863l25.999.013Z"
                />
                <path
                    fill="url(#object-office-bar-stool-b)"
                    d="M31.803 25.092c0 5.956 4.828 10.784 10.784 10.784h153.646c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H55.401c-13.033 0-23.598 10.565-23.598 23.598Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M82.22 35.878h114.013c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H55.401c-13.033 0-23.598 10.565-23.598 23.598 0 5.956 4.828 10.784 10.784 10.784H56.22"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-stool-a"
                        x1={119.411}
                        x2={119.411}
                        y1={45.451}
                        y2={324.863}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-stool-b"
                        x1={31.803}
                        x2={207.017}
                        y1={18.685}
                        y2={18.685}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#ECF3F9" />
                        <stop offset={0.468} stopColor="#C2C9CF" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 238,
        height: 320,
        content: (
            <>
                <path
                    fill="url(#object-office-bar-stool-a)"
                    d="m132.158 35.875-6.062 283.556h-13.874L106.16 35.863l25.998.012Z"
                />
                <path fill="#AEAEAF" d="M220.21 227.33H18.105v18.616H220.21V227.33Z" />
                <path
                    fill="#AEAEAF"
                    d="M55.97 35.875.447 319.431h13.874L81.97 35.863l-25.999.012ZM182.349 35.875l55.523 283.556h-13.874L156.35 35.863l25.999.012Z"
                />
                <path
                    fill="url(#object-office-bar-stool-b)"
                    d="M31.553 25.092c0 5.956 4.828 10.784 10.784 10.784h153.646c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H55.151c-13.033 0-23.598 10.565-23.598 23.598Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.553 25.092c0 5.956 4.828 10.784 10.784 10.784h153.646c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H55.151c-13.033 0-23.598 10.565-23.598 23.598v0Z"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-stool-a"
                        x1={119.159}
                        x2={119.159}
                        y1={45.451}
                        y2={324.863}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-stool-b"
                        x1={31.553}
                        x2={206.767}
                        y1={18.685}
                        y2={18.685}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FFE4B8" />
                        <stop offset={0.428} stopColor="#EBC38C" />
                        <stop offset={0.468} stopColor="#E5B97F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 238,
        height: 320,
        content: (
            <>
                <path
                    fill="url(#object-office-bar-stool-a)"
                    d="m131.906 35.875-6.062 283.556H111.97l-6.062-283.568 25.998.012Z"
                />
                <path fill="#AEAEAF" d="M219.959 227.328H17.853v18.616H219.96v-18.616Z" />
                <path
                    fill="#AEAEAF"
                    d="M55.718 35.875.195 319.431H14.07L81.717 35.863l-25.999.012ZM182.097 35.875l55.523 283.556h-13.874L156.098 35.863l25.999.012Z"
                />
                <path
                    fill="url(#object-office-bar-stool-b)"
                    d="M31.3 25.092c0 5.956 4.829 10.784 10.785 10.784H195.73c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H54.899C41.866 1.494 31.3 12.06 31.3 25.092Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.3 25.092c0 5.956 4.829 10.784 10.785 10.784H195.73c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H54.899C41.866 1.494 31.3 12.06 31.3 25.092v0Z"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-stool-a"
                        x1={118.907}
                        x2={118.907}
                        y1={45.451}
                        y2={324.863}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-stool-b"
                        x1={31.301}
                        x2={206.515}
                        y1={18.685}
                        y2={18.685}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FCCBBB" />
                        <stop offset={0.403} stopColor="#F0AB8F" />
                        <stop offset={0.468} stopColor="#EA9975" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 238,
        height: 320,
        content: (
            <>
                <path
                    fill="url(#object-office-bar-stool-a)"
                    d="m131.866 35.875-6.063 283.556H111.93l-6.063-283.568 25.999.012Z"
                />
                <path fill="#AEAEAF" d="M219.917 227.332H17.812v18.616h202.105v-18.616Z" />
                <path
                    fill="#AEAEAF"
                    d="M55.675 35.875.152 319.431h13.874L81.674 35.863l-25.999.012ZM182.054 35.875l55.522 283.556h-13.873L156.055 35.863l25.999.012Z"
                />
                <path
                    fill="url(#object-office-bar-stool-b)"
                    d="M31.259 25.094c0 5.956 4.828 10.784 10.784 10.784h153.646c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H54.855c-13.032 0-23.597 10.565-23.597 23.598Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.676 35.878h114.013c5.956 0 10.784-4.828 10.784-10.784 0-13.033-10.565-23.598-23.598-23.598H54.855c-13.032 0-23.597 10.565-23.597 23.598 0 5.956 4.828 10.784 10.784 10.784h13.634"
                />
                <defs>
                    <linearGradient
                        id="object-office-bar-stool-a"
                        x1={118.866}
                        x2={118.866}
                        y1={45.451}
                        y2={324.863}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bar-stool-b"
                        x1={31.259}
                        x2={206.474}
                        y1={18.687}
                        y2={18.687}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FFF4C3" />
                        <stop offset={0.331} stopColor="#FFEC96" />
                        <stop offset={0.433} stopColor="#FFD828" />
                        <stop offset={0.468} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-bar-stool"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
