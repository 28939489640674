import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-cycling-2-right">
        <g clipPath="url(#man-bold-34-25-arms-option-1-cycling-2-right-a)">
            <path
                d="M403.015 226.495c-.27 9.538-.104 19.086-.126 28.63-.011 9.554-.568 19.1-1.669 28.59-1.021 9.517-2.265 19.029-3.817 28.533-1.578 9.503-3.374 19-5.881 28.479a23.129 23.129 0 0 1-8.489 12.591 23.129 23.129 0 0 1-28.677-.751 23.122 23.122 0 0 1-7.819-13.018c-2.008-9.597-3.304-19.175-4.383-28.748-1.053-9.571-1.797-19.135-2.32-28.693a251.014 251.014 0 0 1-.17-28.638c.478-9.531 1.143-19.058 1.372-28.596a31.002 31.002 0 0 1 31.735-30.248 31 31 0 0 1 30.247 31.735l-.003.134Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="m371.206 316.247 10.024.675 10.027.61c3.34.273 6.684.438 10.02.806 1.669.152 3.337.344 5.004.543 1.668.185 3.335.394 5.001.627 3.333.457 6.668.855 9.998 1.377 3.332.486 6.663.984 9.99 1.583 3.328.582 6.658 1.114 9.985 1.737 3.324.689 6.651 1.278 9.972 2.07a15.8 15.8 0 0 1 12.124 15.969 15.802 15.802 0 0 1-13.294 15.009c-3.37.539-6.734.875-10.1 1.311-3.364.37-6.724.65-10.087.979-3.363.346-6.722.591-10.081.825-3.36.269-6.716.414-10.073.619-1.679.105-3.357.187-5.035.248a179.93 179.93 0 0 1-5.031.164c-3.354.115-6.701.027-10.051.048l-10.045-.148-10.047-.084a22.507 22.507 0 0 1-22.44-22.698 22.527 22.527 0 0 1 6.768-15.903 22.519 22.519 0 0 1 16.048-6.417c.421.004.908.024 1.323.05Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M345.827 336.008c-1.614-8.412-2.727-16.81-3.673-25.205-1.053-9.572-1.797-19.136-2.32-28.694a250.996 250.996 0 0 1-.17-28.637c.478-9.532 1.143-19.059 1.372-28.597M403.018 226.359l-.004.135c-.27 9.537-.104 19.086-.126 28.63a250.832 250.832 0 0 1-1.668 28.59c-1.022 9.517-2.56 20.785-4.112 30.289M450.056 357.254c-3.37.54-6.733.876-10.099 1.312-3.364.37-6.725.65-10.087.979-3.363.346-6.722.591-10.081.825-3.36.269-6.716.414-10.074.619-1.678.104-3.357.187-5.034.247-1.678.074-3.355.139-5.031.165-3.354.115-6.701.027-10.052.048l-10.044-.148-10.047-.084c-12.431-.104-22.055-11.71-23.68-25.209M391.257 317.531c3.34.274 6.683.439 10.019.806 1.67.152 3.337.344 5.005.544 1.668.185 3.335.394 5.001.626 3.333.458 6.668.856 9.998 1.377 3.332.487 6.663.985 9.99 1.583 3.328.583 6.658 1.114 9.984 1.737 3.324.689 6.652 1.278 9.972 2.07"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M493.103 345.367c-1.75-2.673-4.219-3.341-7.11-4.701-9.534-4.486-18.007-8.182-28.157-10.61-12.25-2.931-22.701 6.027-17.127 19.952.204.508.443 1.001.713 1.476a15.773 15.773 0 0 0 7.737 6.503c.303.128.61.247.92.357a15.83 15.83 0 0 0 3.401.8 20.642 20.642 0 0 1-.352 4.34h-4.284a20.713 20.713 0 0 0 .037 3.583 6.698 6.698 0 0 0 5.484 5.687 8.38 8.38 0 0 0 5.746-1.187l.323.318a13.942 13.942 0 0 0 2.346 2.825 4.221 4.221 0 0 0 3.815.925 7.654 7.654 0 0 0 3.511-1.986c.35-.324.681-.668.993-1.029-1.327 2.572-.13 5.569 2.338 5.579a26.498 26.498 0 0 0 9.65-1.616 10.126 10.126 0 0 0 4.11-2.603 4.73 4.73 0 0 0 1.082-4.56l-.192-.19a13.318 13.318 0 0 0 3.248-3.164 15.032 15.032 0 0 0 1.486-2.495 24.947 24.947 0 0 0 1.962-10.919v-.01c-.029-2.523-.297-5.164-1.68-7.275Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M457.836 330.047c9.69 1.992 18.624 6.124 28.157 10.61 2.891 1.36 5.359 2.028 7.11 4.701 1.383 2.112 1.651 4.752 1.68 7.277a24.957 24.957 0 0 1-1.962 10.928 12.848 12.848 0 0 1-8.11 7.186"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M484.64 350.117c.326 4.003.175 8.03-.45 11.997-.782 3.935-2.616 7.801-5.784 10.262a12.682 12.682 0 0 1-4.055 2.106M474.905 352c.454 3.808.477 7.654.067 11.467a17.097 17.097 0 0 1-4.866 10.168 7.64 7.64 0 0 1-3.511 1.986 4.293 4.293 0 0 1-3.814-.925M464.958 353.922c.597 2.775.763 5.625.49 8.45a13.314 13.314 0 0 1-3.392 7.632 8.835 8.835 0 0 1-7.691 2.737 6.668 6.668 0 0 1-5.484-5.688M440.71 349.992a16.458 16.458 0 0 0 14.363 9.252"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M452.224 367.102a16.78 16.78 0 0 0 1.064-7.641M473.437 378.184a24.42 24.42 0 0 0 9.65-1.616 10.135 10.135 0 0 0 4.11-2.602 4.742 4.742 0 0 0 1.082-4.56"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M589.817 344.963a6.43 6.43 0 0 0-2.108-3.429c-4.088-3.473-21.549-12.882-30.671-19.632-14.963-11.07-41.497 8.4-23.409 19.62 2.555 1.585 5.554 5.598 8.291 9.962h20.64c1.979 3.866 1.212 8.195-.455 12H548.6a138.932 138.932 0 0 1 1.549 3.166 12.112 12.112 0 0 0 7.953 6.533c2.344.578 4.798.432 7.204.636 2.407.204 4.936.851 6.567 2.632a4.395 4.395 0 0 0 1.786-2.429 4.718 4.718 0 0 0-.215-3.119l-.09-.219c.473-.111.93-.281 1.36-.507a7.652 7.652 0 0 0 1.63-1.171l.136.185a6.302 6.302 0 0 0 2.227 1.462 3.183 3.183 0 0 0 3.161-.42c.185-.135.36-.282.523-.44l.227.338c1.549 1.05 3.484 1.216 4.385-.645a36.155 36.155 0 0 0 2.694-7.748c.544-2.395.858-4.836.94-7.29a39.799 39.799 0 0 0-.82-9.485Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M557.591 321.906c9.013 6.172 25.982 16.128 30.118 19.544a5.144 5.144 0 0 1 1.865 3.96 43.781 43.781 0 0 1-5.615 22.143 8.059 8.059 0 0 1-2.09 2.596 3.113 3.113 0 0 1-3.162.42"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M589.817 344.883a39.453 39.453 0 0 1-2.814 24.523M583.034 349.398c-.203 5.468-1.394 11.533-4.181 16.241a11.274 11.274 0 0 1-4.138 4.455 4.96 4.96 0 0 1-5.725-.52M574.629 349.727a34.7 34.7 0 0 1-1.347 11.392 14.121 14.121 0 0 1-2.792 5.428c-1.297 1.581-3.129 2.98-5.174 2.986a7.682 7.682 0 0 1-6.724-3.936"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M562.159 350.75c2.488 4.064 1.709 8.73-.083 12.787M550.149 366.562a12.105 12.105 0 0 0 7.953 6.534c2.344.578 4.798.431 7.205.636 2.406.204 4.935.851 6.566 2.631M533.629 341.438c3.147 1.602 5.47 4.68 7.073 7.959"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="man-bold-34-25-arms-option-1-cycling-2-right-a">
                <path fill="#fff" transform="translate(.173 .61)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
