import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-normal">
        <path
            d="m346.315 453.379-.109-.876c-2.916-23.318 1.063-43.872 4.909-63.749 4.392-22.694 8.541-44.129 2.333-67.925a328.329 328.329 0 0 1-10.89-74.739l-.541-22.589c-.375-15.628 6.461-30.813 18.524-40.32 25.43-20.04 49.756-28.119 49.756-28.119s1.915.109 9.777 9.76c1.899 2.331 7.57 9.27 9.486 10.98 8.466 7.556 16.208 11.229 23.664 11.228 1.34 0 2.678-.121 3.997-.359a9.271 9.271 0 0 0 5.235-2.763c4.714-5.099 3.377-16.737 3.377-16.737a63.077 63.077 0 0 1 8.248 5.997c25.56 21.883 46.641 61.31 52.533 82.777 2.137 7.782 2.362 14.435.668 19.772-3.298 10.394-3.012 21.139.803 30.258a107.86 107.86 0 0 1 8.85 40.647c.291 11.563.003 104.819 0 105.76l-.003.997H346.315Z"
            fill="url(#man-34-25-tshirt-4-normal-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-normal-a"
                x1={342.002}
                y1={304.221}
                x2={537.065}
                y2={304.221}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
