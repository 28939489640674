import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={378}
            height={376}
            viewBox={`0 0 ${378} ${376}`}
            fill="none"
            id="object-kitchen-cooker"
            {...attributes}
        >
            <g
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#object-kitchen-cooker-a)"
            >
                <path 
                    d="M339.154 172.514v183.138H1.346v18.841h356.649V1.943H1.345v334.868" />
                <path
                    d="M339.155 172.514H20.188v164.297h356.648V95.461H20.187v58.212h318.968M339.155 60.156H20.188M212.478 124.566h-65.617" />
                <path
                    d="M75.852 137.566c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13ZM283.488 137.566c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13-7.179 0-13 5.821-13 13 0 7.18 5.821 13 13 13ZM75.852 44.049c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13ZM145.064 44.049c7.18 0 13-5.82 13-13s-5.82-13-13-13c-7.179 0-13 5.82-13 13s5.821 13 13 13ZM214.277 44.049c7.18 0 13-5.82 13-13s-5.82-13-13-13c-7.179 0-13 5.82-13 13s5.821 13 13 13ZM283.488 44.049c7.18 0 13-5.82 13-13s-5.82-13-13-13c-7.179 0-13 5.82-13 13s5.821 13 13 13Z" />
            </g>
            <defs>
                <clipPath id="object-kitchen-cooker-a">
                    <path fill="#fff" d="M.346.943h377.49v374.55H.346z" />
                </clipPath>
            </defs>
        </svg>
    );
}
