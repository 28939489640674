import { ILegs } from 'interfaces';

import Stand1 from './female-34-25-pants-6-stand-1';
import Stand2 from './female-34-25-pants-6-stand-2';
import Walk1 from './female-34-25-pants-6-walk-1';
import Walk2 from './female-34-25-pants-6-walk-2';
import sitting1Right from './female-34-25-pants-6-sitting-1-right';
import sitting1Left from './female-34-25-pants-6-sitting-1-left';

const pants6: ILegs = {
    name: 'Pants #6',
    stand1: [Stand1],
    stand2: [Stand2],
    walk1: [Walk1],
    walk2: [Walk2],
    sitting1: [sitting1Right, sitting1Left],
};

export default pants6;
