import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M409.998 243.517c1.946 6.918 4.288 13.724 6.46 20.578a151.497 151.497 0 0 1 5.063 20.975c1.266 7.112 2.33 14.28 3.116 21.528a190.65 190.65 0 0 1 1.248 22.06 19.287 19.287 0 0 1-5.796 13.581 19.288 19.288 0 0 1-13.7 5.506 19.493 19.493 0 0 1-16.094-8.969 191.077 191.077 0 0 1-10.544-19.418c-3.144-6.577-6.009-13.234-8.673-19.948a151.294 151.294 0 0 1-6.728-20.502c-1.759-6.971-3.348-13.991-5.332-20.899a26.499 26.499 0 0 1 38.325-30.579 26.508 26.508 0 0 1 7.899 6.716 26.51 26.51 0 0 1 4.716 9.232l.04.139Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m402.098 315.637 10.756-4.972 10.698-5.104a231.457 231.457 0 0 1 21.938-8.983c3.746-1.297 7.45-2.689 11.24-3.886l5.659-1.855c1.889-.614 3.804-1.166 5.704-1.752 7.624-2.297 15.316-4.44 23.236-6.074a12.287 12.287 0 0 1 13.702 7.033 12.285 12.285 0 0 1-3.938 14.891c-6.511 4.794-13.251 9.077-20.057 13.208-1.708 1.02-3.4 2.076-5.119 3.068l-5.164 2.966c-3.426 2.016-6.937 3.839-10.407 5.755a231.569 231.569 0 0 1-21.353 10.299l-10.949 4.537-10.892 4.669a18.499 18.499 0 0 1-24.192-9.478 18.499 18.499 0 0 1 9.138-24.322Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M390.291 338.774a190.661 190.661 0 0 1-10.543-19.417c-3.144-6.578-6.011-13.235-8.675-19.949a151.528 151.528 0 0 1-6.728-20.502c-1.759-6.971-3.347-13.991-5.331-20.899-2.525-8.791.1-18.778 4.634-26.077M409.957 243.375l.039.139c1.946 6.918 4.288 13.724 6.46 20.578a151.5 151.5 0 0 1 5.064 20.975 327.811 327.811 0 0 1 2.629 17.33M501.091 304.938c-6.512 4.794-13.251 9.077-20.058 13.208-1.707 1.02-3.399 2.075-5.119 3.068l-5.164 2.966c-3.425 2.016-6.937 3.838-10.406 5.755a231.702 231.702 0 0 1-21.354 10.298l-10.949 4.538-10.892 4.668c-9.392 4.026-21.093-.275-26.856-10.665M423.547 305.566a231.41 231.41 0 0 1 21.938-8.983c3.746-1.298 7.451-2.689 11.241-3.886l5.659-1.855c1.888-.615 3.803-1.166 5.704-1.753 7.624-2.296 15.316-4.44 23.235-6.073"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M499.667 304.02a71.557 71.557 0 0 0 21.553-13.332c2.14-1.981 4.271-3.99 6.32-6.074l1.022-.813c8.951-6.536 15.122-16.586 19.685-26.642a2.807 2.807 0 0 0-.479-3.263 1.676 1.676 0 0 0-.312-1.925 2.562 2.562 0 0 0-1.759-.774c-.95-.073-1.656.704-2.447 1.244l-.501-.738a2.06 2.06 0 0 0-2.346-.34 21.877 21.877 0 0 0-7.158 5.79c-2.225 2.786-4.205 5.796-6.748 8.285a24.649 24.649 0 0 1-17.06 6.983l-2.339-.066c.569-3.262 2.379-6.137 4.049-8.983a24.057 24.057 0 0 0 3.134-7.221 2.153 2.153 0 0 0-1.487-2.497 2.118 2.118 0 0 0-2.4.965c-2.079 3.375-5.418 6.187-7.987 9.136a87.838 87.838 0 0 0-8.353 10.422 23.664 23.664 0 0 0-4.081 12.601"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M507.098 272.358c.569-3.262 2.379-6.137 4.049-8.983a24.044 24.044 0 0 0 3.133-7.221 2.158 2.158 0 0 0-1.486-2.497 2.12 2.12 0 0 0-2.4.965c-2.079 3.375-5.419 6.187-7.988 9.136a87.838 87.838 0 0 0-8.353 10.422 23.662 23.662 0 0 0-4.08 12.601M524.76 278.409c4.738-2.48 7.863-7.233 10.495-11.942 2.28-4.078 4.419-8.367 7.563-11.753a2.138 2.138 0 0 0-.068-3.011 2.06 2.06 0 0 0-2.346-.34 21.877 21.877 0 0 0-7.158 5.79c-2.225 2.786-4.206 5.796-6.749 8.285a24.646 24.646 0 0 1-17.059 6.983"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m547.048 253.148-4.575 9.444a101.844 101.844 0 0 1-6.022 11.212c-4.152 6.389-9.665 11.73-15.234 16.887a71.557 71.557 0 0 1-21.553 13.332"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M548.247 257.156c-4.563 10.057-10.735 20.107-19.685 26.643"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
