import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6350)"
            d="M400.597 274.435a.994.994 0 01-.471-.118l-47.483-25.336a.99.99 0 01-.495-.622.995.995 0 01.116-.788l.943-1.518c2.871-4.619 5.839-9.395 8.579-14.184a27.505 27.505 0 0137.526-10.216 27.507 27.507 0 0112.88 16.647 27.513 27.513 0 01-2.664 20.879l-.066.114c-2.748 4.749-5.367 9.684-7.899 14.456l-.082.154a1.004 1.004 0 01-.884.532z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6350"
                x1="413.359"
                x2="372.475"
                y1="199.094"
                y2="268.168"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
