import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6750)"
            d="M494.77 222.602a109.64 109.64 0 00-20.026-15.329 31.051 31.051 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.007 1.007 0 00.91.585 1 1 0 00.42-.093c2.598-1.2 15.601-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6750)"
            d="M532.039 412.778c-6.06-8.26-11.323-16.682-16.414-24.826l-.128-.205c-10.565-16.788-16.215-29.272-23.368-45.077-1.185-2.618-2.406-5.316-3.693-8.128a12.39 12.39 0 01-.321-.78l-.061-.155c-10.786-15.509-23.593-41.841-28.622-58.828-.621-2.075-1.228-4.158-1.836-6.241-1.377-4.727-2.802-9.614-4.39-14.349a27.304 27.304 0 011.453-20.997 27.315 27.315 0 0115.874-13.82A27.474 27.474 0 01505.35 236.7c1.615 4.813 3.156 8.937 4.647 12.925 4.735 12.668 8.824 23.608 12.814 49.694 1.244 8.318 2.634 14.902 2.89 21.056a380.269 380.269 0 0131.724 79.299l.21.777-25.089 13.018-.507-.691z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6750"
                x1="505.122"
                x2="347.677"
                y1="196.208"
                y2="358.207"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6750"
                x1="565.457"
                x2="408.012"
                y1="254.849"
                y2="416.849"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
