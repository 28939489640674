import { SVG } from 'components/objects';

import outdoors1 from 'assets/scenes/scenes-outdoor-1';
import outdoors2 from 'assets/scenes/scenes-outdoor-2';
import outdoors3 from 'assets/scenes/scenes-outdoor-3';
import outdoors4 from 'assets/scenes/scenes-outdoor-4';
import outdoors5 from 'assets/scenes/scenes-outdoor-5';
import outdoors6 from 'assets/scenes/scenes-outdoor-6';

import office1 from 'assets/scenes/scenes-office-1';
import office2 from 'assets/scenes/scenes-office-2';
import office3 from 'assets/scenes/scenes-office-3';
import office4 from 'assets/scenes/scenes-office-4';
import office5 from 'assets/scenes/scenes-office-5';
import office6 from 'assets/scenes/scenes-office-6';

import kitchen1 from 'assets/scenes/scenes-kitchen-1';
import kitchen2 from 'assets/scenes/scenes-kitchen-2';
import kitchen3 from 'assets/scenes/scenes-kitchen-3';
import kitchen4 from 'assets/scenes/scenes-kitchen-4';
import kitchen5 from 'assets/scenes/scenes-kitchen-5';

import { SETTING_TYPES } from 'components/ObjectBuilder';

export type TCategoryOption = {
    name?: string;
    image?: (a? : SVG) => JSX.Element;
    variations?: string[];
    tag?: string;
    colorful?: string[];
    outline?: string[];
}

export const DOCTORS_OFFICE = {
    option1: {
        name: 'Option #1',
        image: office1,
    },
    option2: {
        name: 'Option #2',
        image: office2,
    },
    option3: {
        name: 'Option #3',
        image: office3,
    },
    option4: {
        name: 'Option #4',
        image: office4,
    },
    option5: {
        name: 'Option #5',
        image: office5,
    },
    option6: {
        name: 'Option #6',
        image: office6,
    },
};
export const KITCHEN = {
    option1: {
        name: 'Option #1',
        image: kitchen1,
    },
    option2: {
        name: 'Option #2',
        image: kitchen2,
    },
    option3: {
        name: 'Option #3',
        image: kitchen3,
    },
    option4: {
        name: 'Option #4',
        image: kitchen4,
    },
    option5: {
        name: 'Option #5',
        image: kitchen5,
    },
};
export const OUTDOOR = {
    option1: {
        name: 'Option #1',
        image: outdoors1,
    },
    option2: {
        name: 'Option #2',
        image: outdoors2,
    },
    option3: {
        name: 'Option #3',
        image: outdoors3,
    },
    option4: {
        name: 'Option #4',
        image: outdoors4,
    },
    option5: {
        name: 'Option #5',
        image: outdoors5,
    },
    option6: {
        name: 'Option #6',
        image: outdoors6,
    },
};

export type TDoctorsOffice = keyof typeof DOCTORS_OFFICE;
export type TKitchen = keyof typeof KITCHEN;
export type TOutdoor = keyof typeof OUTDOOR;
export type TObjects = TDoctorsOffice | TKitchen | TOutdoor;
export type TObjectSettings = { [key in typeof SETTING_TYPES as string]: TObjects }


