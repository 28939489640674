import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={664}
            height={376}
            fill="none"
            id="object-office-storage"
            {...attributes}
        >
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#object-office-storage-a)"
            >
                <path
                    d="M1.32 101.344V1.982h642.695V300.07H1.32V132.953M591.482 318.904H53.852M606.371 300.068H38.961" 
                />
                <path
                    d="M50.603 300.068h-20.89l-14.49 74.47h20.6l18.03-55.63M606.374 300.068h9.25l14.5 74.47h-20.61l-18.03-55.63M1.32 101.342h661.536v99.362H29.711M29.71 300.069v-99.36M142.477 34.342h60.253M442.602 34.342h60.253M142.477 133.701h60.253M442.602 133.701h60.253M142.477 233.061h60.253M442.602 233.061h60.253"
                />
            </g>
            <defs>
                <clipPath id="object-office-storage-a">
                    <path fill="#fff" d="M.32.982h663.536v374.554H.32z" />
                </clipPath>
            </defs>
        </svg>
    );
}
