import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9411)"
            d="M361.071 276.986c-.342-1.89-.672-3.783-1.002-5.677-.834-4.778-1.697-9.718-2.738-14.527a27.5 27.5 0 0121.053-32.7 27.473 27.473 0 0132.7 21.054l.031.145c1.022 4.848 2.262 9.74 3.462 14.472l.947 3.747-54.259 14.556-.194-1.07z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9411"
                x1="358.154"
                x2="410.78"
                y1="242.532"
                y2="267.111"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
