import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#female-34-25-shirt-1-arms-opt-2-right-a)">
            <path
                d="M451.728 316.89c-1.932-.118-3.866-.274-5.796-.361l-5.79-.25c-3.863-.21-7.712-.207-11.568-.306a219.365 219.365 0 0 0-22.455.668c.024-.121.05-.243.073-.364 1.465-7.524 2.639-15.057 3.605-22.597a167.78 167.78 0 0 0 1.581-22.662c.025-7.57-.128-15.145.13-22.707l.005-.136a26.502 26.502 0 0 0-44.677-20.042 26.496 26.496 0 0 0-8.301 18.505c-.22 7.564-.85 15.114-1.303 22.67a167.842 167.842 0 0 0 .148 22.716c.488 7.586 1.185 15.178 2.171 22.78a209.765 209.765 0 0 0 4.114 22.841 19.403 19.403 0 0 0 3.53 7.216 18.505 18.505 0 0 0 17.642 10.657l11.515-.549 11.523-.405a219.163 219.163 0 0 0 22.963-2.149c3.814-.576 7.634-1.05 11.441-1.736l5.714-.964c1.905-.326 3.805-.72 5.707-1.076a375.527 375.527 0 0 0 9.874-2.059s-2.386-6.192-2.386-11.252a49.173 49.173 0 0 1 2.926-17.387c-4.133-.434-8.261-.77-12.386-1.051Z"
                fill="url(#female-34-25-shirt-1-arms-opt-2-right-b)"
            />
            <path
                d="M453.699 348.64c3.294-.64 6.585-1.327 9.873-2.058 0 0-2.386-6.192-2.386-11.253a49.2 49.2 0 0 1 2.927-17.387 337.543 337.543 0 0 0-12.386-1.051M363.665 337.648a209.765 209.765 0 0 1-4.114-22.841c-.986-7.601-1.683-15.194-2.171-22.78a167.842 167.842 0 0 1-.148-22.716c.453-7.556 1.084-15.106 1.303-22.67.211-7.273 3.888-15.153 9.599-19.274M411.512 248.172l-.005.137c-.258 7.562-.106 15.137-.13 22.707a167.917 167.917 0 0 1-1.581 22.661 362.614 362.614 0 0 1-2.905 18.883M453.696 348.642c-1.903.356-3.803.75-5.707 1.076l-5.715.964c-3.807.686-7.627 1.16-11.441 1.737a219.43 219.43 0 0 1-22.963 2.148l-11.523.406-11.515.548c-10.206.487-18.984-7.176-21.172-17.873M405.52 316.685a219.207 219.207 0 0 1 23.052-.711c3.856.1 7.705.097 11.568.306l5.79.25c1.93.088 3.865.243 5.797.361"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m411.512 248.172-.005.137c-.258 7.562-.106 15.137-.13 22.707a167.917 167.917 0 0 1-1.581 22.661 362.614 362.614 0 0 1-2.905 18.883M453.696 348.642c-1.903.356-3.803.75-5.707 1.076l-5.715.964c-3.807.686-7.627 1.16-11.441 1.737a219.43 219.43 0 0 1-22.963 2.148l-11.523.406-11.515.548c-10.206.487-18.984-7.176-21.172-17.873M405.52 316.685a219.207 219.207 0 0 1 23.052-.711c3.856.1 7.705.097 11.568.306l5.79.25c1.93.088 3.865.243 5.797.361"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-2-right-b"
                x1={356.918}
                y1={288.223}
                x2={485.329}
                y2={288.223}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="female-34-25-shirt-1-arms-opt-2-right-a">
                <path fill="#fff" transform="translate(.637 .14)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
