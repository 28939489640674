import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-tshirt-2-cycling-2-a)">
        <path
            d="M499.878 279.004a196.037 196.037 0 0 0-6.026-44.017h-.007c-4.135-15.817-12.876-44.522-28.431-57.561-1.092 6.208-6.253 13.036-13.856 13.096-9.113.073-16.904-6.67-23.241-13.22-6.538-6.814-13.179-18.658-24.327-23.064-11.408 2.957-22.312 8.756-32.49 17.307-54.521 45.811-58.153 129.036-71.64 206.826 0 0 46.901 51.545 111.602 57.368 32.53 2.928 59.53-19.843 66.687-41.313 9.649-28.947 10.506-44.203 8.449-64.254-1.018-9.915 1.065-20.237 7.211-28.084 4.373-5.585 6.465-14.167 6.069-23.084Z"
            fill="url(#man-34-25-tshirt-2-cycling-2-b)"
        />
        <path
            d="M499.406 282.005c.016-.744.024-1.51.023-2.294l-.009-.835-.003-.235c-56.866-.68-104.107-16.29-134.421-29.755-12.645-5.617-26.005-12.009-34.861-17.019a241.875 241.875 0 0 0-5.792 16.723 169.39 169.39 0 0 0-1.209 3.996c9.519 5.986 26.646 15.275 45.617 23.672 27.831 12.319 70.499 26.533 121.836 28.315a18.036 18.036 0 0 0 2.544-1.8c3.937-4.688 6.08-11.67 6.275-20.768Z"
            fill="url(#man-34-25-tshirt-2-cycling-2-c)"
        />
        <path
            d="M335.17 220.493c48.764 23.942 90.887 42.611 164.025 45.072a221.002 221.002 0 0 0-5.309-30.77c-3.745-15.524-12.276-43.959-28.466-57.364-1.156 6.05-7.192 13.628-13.32 12.966-9.084-.738-16.905-6.67-23.241-13.22-6.538-6.814-13.18-18.658-24.328-23.064-11.407 2.957-22.423 8.732-32.71 17.151-14.472 11.843-26.949 28.092-36.651 49.229Z"
            fill="url(#man-34-25-tshirt-2-cycling-2-d)"
        />
        <path
            d="M316.592 278.574a790.573 790.573 0 0 0-2.779 13.684 347.893 347.893 0 0 0 41.906 21.859c29.826 13.248 76.033 28.573 131.673 29.713-.049-4.42-.344-8.742-.805-13.018-52.828-1.263-96.757-15.812-125.19-28.398a328.912 328.912 0 0 1-44.805-23.84Z"
            fill="url(#man-34-25-tshirt-2-cycling-2-e)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-2-b"
                x1={299.861}
                y1={295.116}
                x2={499.926}
                y2={295.116}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-2-c"
                x1={560.587}
                y1={271.976}
                x2={351.756}
                y2={266.755}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-2-d"
                x1={562.683}
                y1={212.609}
                x2={353.456}
                y2={207.379}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-2-e"
                x1={559.639}
                y1={315.145}
                x2={350.517}
                y2={309.917}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <clipPath id="man-34-25-tshirt-2-cycling-2-a">
                <path fill="#fff" transform="translate(.23 .602)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
