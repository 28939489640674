import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6362)"
            d="M413.252 251.399l.024-.125a27.496 27.496 0 00-11.937-28.157 27.519 27.519 0 00-20.699-3.919 27.512 27.512 0 00-17.399 11.879 27.505 27.505 0 00-4.005 9.966c-1.026 5.422-2.25 10.899-3.447 16.221 0 0 16.752.041 27.74 1.343 12.488 1.478 27.283 6.901 27.283 6.901.748-4.677 1.533-9.438 2.44-14.109z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6362"
                x1="364.491"
                x2="407.286"
                y1="225.741"
                y2="268.536"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
