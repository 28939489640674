import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-3-left">
        <path
            d="M501.327 185.18c-9.037-6.976-19.774-12.641-32.826-17.319l-2.653-.951 21.103 34.814.857-.526c2.396-1.468 14.36-9.028 14.61-13.677a2.668 2.668 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-3-left-a)"
        />
        <path
            d="M508.892 285.254a508.24 508.24 0 0 1-12.561-11.403 231.408 231.408 0 0 1-19.07-19.793 987.401 987.401 0 0 1-5.353-6.303c-4.038-4.775-8.214-9.712-12.473-14.419a32.002 32.002 0 0 1 2.202-45.26 31.992 31.992 0 0 1 35.233-5.161 32.001 32.001 0 0 1 10.021 7.481l.094.104c4.245 4.734 8.724 9.406 13.055 13.922 1.905 1.988 3.806 3.979 5.702 5.973a231.568 231.568 0 0 1 17.702 21.031 500.364 500.364 0 0 1 11.427 15.668c-10.818.228-40.456 23.505-45.979 38.16Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-3-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-3-left-a"
                x1={472.64}
                y1={213.817}
                x2={507.121}
                y2={104.515}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-3-left-b"
                x1={541.108}
                y1={168.114}
                x2={448.072}
                y2={306.042}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
