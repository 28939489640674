import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-3">
        <path
            d="M531.727 269.175a365.355 365.355 0 0 1-17.77-6.269 170.958 170.958 0 0 1-21.56-9.703 920.832 920.832 0 0 1-6.239-3.374c-4.718-2.567-9.598-5.222-14.484-7.638a31.797 31.797 0 0 1-16.127-18.432 31.782 31.782 0 0 1 1.63-24.438 31.824 31.824 0 0 1 28.707-17.821 31.69 31.69 0 0 1 14.164 3.324l.151.075c4.875 2.444 9.937 4.739 14.832 6.958 2.152.975 4.301 1.956 6.449 2.942a171.163 171.163 0 0 1 20.739 11.359c-4.718 7.021-13.827 40.129-10.492 63.017Z"
            fill="url(#man-34-25-jacket-left-option-3-a)"
        />
        <path
            d="m465.801 167.027 2.654.952c13.05 4.677 23.788 10.342 32.825 17.319a2.678 2.678 0 0 1 1.092 2.341c-.25 4.648-12.215 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-jacket-left-option-3-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-3-a"
                x1={505.269}
                y1={188.1}
                x2={470.168}
                y2={332.443}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-3-b"
                x1={486.253}
                y1={168.534}
                x2={443.747}
                y2={343.329}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
