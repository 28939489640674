import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-1-stand-2">
            <path
                fill="#EF0025"
                d="M373.94 857.972a5.293 5.293 0 0 1-.175-.241c-4.624-6.726-12.642-18.56-14.401-22.245a70.811 70.811 0 0 1-6.288-20.091 41.123 41.123 0 0 0-3.753-12.054l-8.87-18.292a9.909 9.909 0 0 1-.351-7.918 9.905 9.905 0 0 1 2.163-3.416 9.911 9.911 0 0 1 3.35-2.265c4.18-1.733 8.262-3.452 11.803-4.971a1.003 1.003 0 0 1 1.28.455 65.427 65.427 0 0 1 4.231 11.305c.205.668.408 1.335.615 1.998 1.873 6.002 4.275 12.066 9.127 15.744a32.927 32.927 0 0 0 6.203 3.426c1.074.499 2.184 1.014 3.255 1.578a24.419 24.419 0 0 1 2.864 1.769 1.004 1.004 0 0 1 .382 1.069c-2.732 10.284-5.63 24.966-3.926 36.51.702 4.75 1.36 10.777 1.69 13.948a5.162 5.162 0 0 1-2.467 4.944 5.167 5.167 0 0 1-6.732-1.253Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M377.228 859.899a5.14 5.14 0 0 1-3.463-2.17c-4.624-6.726-12.642-18.56-14.4-22.245a70.782 70.782 0 0 1-6.289-20.091 41.129 41.129 0 0 0-3.753-12.055l-8.87-18.292a9.917 9.917 0 0 1-.351-7.917"
            />
            <path
                fill="url(#man-34-25-shoes-1-stand-2-a)"
                d="M462.716 860.227h-57.648a9.907 9.907 0 0 1-7.277-3.139 9.877 9.877 0 0 1-2.129-3.438 9.874 9.874 0 0 1-.576-4.002c.192-3.288.403-7.12.591-10.979a.995.995 0 0 1 .794-.93.983.983 0 0 1 .435.005 15.028 15.028 0 0 0 12.593-2.604 30.37 30.37 0 0 0 3.72-3.647 39.912 39.912 0 0 1 3.132-3.187 21.111 21.111 0 0 1 18.02-4.47c.244.052.461.193.606.396 4.311 6.008 10.492 13.871 15.446 17.207 3.659 2.464 10.888 6.412 14.871 8.546a5.426 5.426 0 0 1 2.867 4.797 5.453 5.453 0 0 1-5.445 5.445Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.922 858.236a5.425 5.425 0 0 1-1.88 1.469 5.42 5.42 0 0 1-2.328.523h-57.648a9.906 9.906 0 0 1-7.277-3.138"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-1-stand-2-a"
                    x1="395.068"
                    x2="468.161"
                    y1="841.802"
                    y2="841.802"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset=".37" stopColor="#F86431" />
                    <stop offset="1" stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
