import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6580)"
            d="M385.711 219.824a31.546 31.546 0 00-.813-.011 27.353 27.353 0 00-27.473 26.703c-.152 5.23-.507 10.525-.82 15.199l-.074 1.095a130.304 130.304 0 0126.167-3.949c13.719-.672 29.552 2.476 29.552 2.476l-.002-.975c-.007-3.823.007-7.935.15-12.112l.004-.14a27.534 27.534 0 00-26.691-28.286z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6580"
                x1="389.379"
                x2="384.174"
                y1="211.509"
                y2="259.654"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
