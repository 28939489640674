import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11343_6439)"
            d="M420.59 236.787a891.565 891.565 0 00-4.822-3.06c-4.253-2.688-8.65-5.469-12.856-8.364l-.119-.082a27.5 27.5 0 00-30.944 45.471c4.204 2.86 8.371 5.942 12.4 8.923l1.777 1.314 35.508-43.597-.944-.605z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11343_6439"
                x1="362.584"
                x2="412.03"
                y1="241.642"
                y2="258.702"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
