import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-option-4">
        <path
            fill="url(#man-34-25-tshirt-arm-right-option-4-a)"
            d="M413.849 265.22c.085-3.028.133-6.084.141-9.149a161.97 161.97 0 0 0-1.334-22.991 876.48 876.48 0 0 0-.915-6.839c-.706-5.185-1.436-10.547-1.942-15.833l-.017-.17a31.885 31.885 0 0 0-10.339-20.535 31.884 31.884 0 0 0-21.462-8.246 32.038 32.038 0 0 0-31.875 35.225c.535 5.279.864 10.678 1.182 15.9.14 2.298.28 4.595.437 6.888a162.25 162.25 0 0 0 3.173 22.805 365.999 365.999 0 0 0 2.528 11.585l60.423-8.64Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-option-4-a"
                x1={339.855}
                x2={435.276}
                y1={214.027}
                y2={258.268}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.265} stopColor="#FFDA32" />
                <stop offset={0.866} stopColor="#FFF0AE" />
                <stop offset={0.965} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
