import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7024)"
            d="M478.221 236.781c-.166-4.363-.928-19.193-3.775-26.828l-1.016-2.726 2.477 1.525a102.497 102.497 0 0118.859 14.537 2.713 2.713 0 01.822 2.482c-.788 4.563-13.42 10.695-15.943 11.877l-1.368.641-.056-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7024)"
            d="M557.98 309.03a19.673 19.673 0 01-5.449-.77 20.394 20.394 0 01-3.397-.509 213.439 213.439 0 01-22.256-6.932c-6.887-2.523-13.84-5.341-21.261-8.62a169.662 169.662 0 01-20.319-10.417 718.327 718.327 0 01-5.833-3.535c-4.439-2.704-9.029-5.501-13.633-8.072a27.502 27.502 0 01-3.646-45.585 27.497 27.497 0 0130.459-2.437l.124.07c4.583 2.59 9.352 5.051 13.964 7.431 2.028 1.048 4.054 2.1 6.078 3.155a169.431 169.431 0 0119.472 11.938 374.142 374.142 0 0110.071 7.259 72.56 72.56 0 01.779-3.372l.934-3.847c.209-.852.446-1.698.685-2.544.125-.444.248-.889.37-1.334.92-3.278 1.745-5.968 2.6-8.473l.699-2.053 1.107 1.865c3.532 5.952 10.829 8.072 16.481 8.072 4.517 0 8.547-1.3 10.263-3.312l1.442-1.688.309 2.198c.263 1.864.51 3.902.755 6.228.046.474.095.939.144 1.404.092.868.182 1.736.246 2.603l.275 3.968c.154 1.792.211 3.608.266 5.365.027.857.055 1.714.093 2.574a103.559 103.559 0 01-.613 15.875 528.745 528.745 0 01-.582 4.7c-.451 3.564-.917 7.251-1.213 10.879a19.59 19.59 0 01-6.234 12.721 19.59 19.59 0 01-13.18 5.195z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7024"
                x1="451.894"
                x2="625.388"
                y1="214.252"
                y2="275.843"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7024"
                x1="446.162"
                x2="619.656"
                y1="230.397"
                y2="291.987"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
