import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={137}
            height={237}
            fill="none"
            id="object-food-milk"
            {...attributes}
        >
            <path
                fill="url(#object-food-milk-a)"
                d="M110.972 18.684h-84.52L1.82 59.863h84.521l24.631-41.18Z"
            />
            <path
                fill="#D2F0FA"
                d="M86.341 59.863H1.821V235.19h84.52V59.863ZM110.974 1h-84.52v17.684h84.52V1Z"
            />
            <path
                fill="#5BC2E7"
                d="m135.605 59.863-24.632-41.18-24.631 41.18v175.326h49.263V59.863Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M65.12 59.863H1.82V235.19h84.521M135.605 166.221V59.863l-24.632-41.18M86.342 235.19h49.263v-29.557M26.452 18.684 1.82 59.863"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M110.973 18.684V1h-84.52v17.684"
            />
            <defs>
                <linearGradient
                    id="object-food-milk-a"
                    x1={56.396}
                    x2={56.396}
                    y1={52.284}
                    y2={14.728}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2F0FA" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
            </defs>
        </svg>
    );
}
