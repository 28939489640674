import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-3-stand-2">
        <path
            d="M364.855 754.714c28.932-56.091 57.816-94.343 79.52-116.639 7.688-7.718 14.241-15.949 23.889-23.005-18.881-58.208-26.154-91.38-36.692-143.34-4.139-21.828-9.99-40.835 5.572-61.686 20.149-26.995 66.736-21.546 95.36-21.546-6.372 25.674-5.39 29.965-2.968 39.439 18.204 74.812 13.632 148.133 8.973 193.769.538 11.067-3.775 17.591-9.473 23.704-82.304 88.042-90.493 96.216-132.046 139.75"
            fill="#EF0025"
        />
        <path
            d="M370.739 387.121c-9.184 15.857-16.082 25.768-17.455 44.268a52.338 52.338 0 0 0-.918 12.188c1.617 38.358 8.676 89.007 19.37 138.96.899 4.214 2.477 13.845 4.004 23.16.765 4.673 1.53 9.335 2.211 13.345a50.747 50.747 0 0 1-.863 20.79c-5.946 24.084-7.154 59.754-2.814 82.955.89 5.227 3.685 24.48 6.644 44.865 3.573 24.615 7.269 50.068 7.97 53.279l.184.843 40.99-2.751.058-.873c.858-12.806 1.54-22.886 2.156-31.973 2.336-34.49 6.747-55.316 13.307-160.157 8.264-28.072 13.522-101.555 15.553-134.876a46.976 46.976 0 0 1 12.679-29.326l24.755-29.503s24.553-11.723 33.27-45.194H370.739Z"
            fill="url(#man-34-25-pants-3-stand-2-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-3-stand-2-a"
                x1={469.309}
                y1={604.448}
                x2={306.224}
                y2={604.448}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
