import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-1-both-arms-option-3-left">
        <path
            d="M500.944 185.18c-9.037-6.976-19.774-12.641-32.825-17.319l-2.654-.951 21.103 34.814.857-.526c2.396-1.468 14.36-9.028 14.61-13.677a2.668 2.668 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-3-left-a)"
        />
        <path
            d="M508.509 285.256a508.24 508.24 0 0 1-12.562-11.403 231.243 231.243 0 0 1-19.069-19.793 987.54 987.54 0 0 1-5.354-6.303c-4.038-4.775-8.214-9.712-12.473-14.419a32 32 0 1 1 47.457-42.94l.093.104c4.245 4.734 8.724 9.406 13.055 13.922 1.905 1.988 3.806 3.979 5.703 5.973a231.395 231.395 0 0 1 17.701 21.031 502.058 502.058 0 0 1 11.428 15.668c-10.819.228-40.457 23.505-45.979 38.16Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-3-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-3-left-a"
                x1={488.183}
                y1={157.152}
                x2={446.978}
                y2={344.526}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-3-left-b"
                x1={516.01}
                y1={166.942}
                x2={481.341}
                y2={324.592}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
