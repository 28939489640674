import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11325_7943)"
            d="M507.238 514.244c.126-5.601-.149-14.389.143-19.174-11.999.029-93.578.018-114.579.018-28.917-5.248-53.674-32.892-47.024-66.289 7.189-36.098 45.983-45.884 80.82-39.723 18.961 3.353 38.858 7.805 56.494 12.687 17.101 4.96 36.53 13.61 52.518 22.337 14.97 7.682 29.55 19.158 30.675 38.023 1.648 43.349 3.585 93.225 4.788 136.341l-48.206 3.094s-16.467-50.174-15.629-87.314z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11325_7943"
                x1="543.102"
                x2="418.186"
                y1="471.061"
                y2="515.302"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#222731"></stop>
                <stop offset="1" stopColor="#1E1F23"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
