import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-left">
        <path
            fill="#001489"
            d="m465.296 167.398 2.654.952c13.05 4.676 23.787 10.341 32.824 17.318a2.668 2.668 0 0 1 1.093 2.342c-.25 4.648-12.215 12.209-14.61 13.677l-.857.525-21.104-34.814Z"
        />
        <path
            fill="#001489"
            d="M544.647 275.991c-8.441-2.884-16.983-6.09-26.117-9.802a236.592 236.592 0 0 1-25.19-11.747 1023.99 1023.99 0 0 1-7.33-3.989c-5.555-3.036-11.298-6.176-17.036-9.072a32.032 32.032 0 0 1-14.154-42.984 31.857 31.857 0 0 1 28.593-17.592 31.663 31.663 0 0 1 14.391 3.437l.128.066c5.718 2.917 11.637 5.694 17.361 8.381 2.527 1.184 5.05 2.375 7.57 3.571a236.567 236.567 0 0 1 24.359 13.394c8.385 5.176 16.017 10.177 23.326 15.285-22.71.829-27.553 37.046-25.901 51.052Z"
        />
    </g>
);

export default SvgComponent;
