import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9111)"
            d="M514.432 258.427s-.293-.732-.362-.893a774.03 774.03 0 00-2.808-6.56c-2.14-4.969-4.353-10.106-6.34-15.226l-.049-.126a27.516 27.516 0 00-5.802-9.03 27.5 27.5 0 00-45.371 29.187c2.012 5.11 3.872 10.388 5.67 15.492.791 2.242 1.585 4.48 2.384 6.716.674 1.889 1.41 3.833 2.25 5.94l.406 1.019 50.382-25.663-.36-.856z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9111)"
            d="M494.779 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9111"
                x1="526.39"
                x2="423.594"
                y1="203.355"
                y2="307.018"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9111"
                x1="511.72"
                x2="408.924"
                y1="188.808"
                y2="292.471"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
