import React from 'react';

import { ReactComponent as AbbottLogo } from 'assets/layout/abbott-logo.svg';
import { ReactComponent as AbbottName } from 'assets/layout/abbott-name.svg';

function Logo() {
    return <div className="header-logo">
        <AbbottLogo className="logo-image" />
        <AbbottName className="logo-title" />
    </div>;
}

export default Logo;
