import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-3">
        <path
            d="M531.449 269.175a366.105 366.105 0 0 1-17.77-6.269 170.958 170.958 0 0 1-21.56-9.703 920.618 920.618 0 0 1-6.238-3.374c-4.719-2.567-9.599-5.222-14.484-7.638a31.797 31.797 0 0 1-16.127-18.432 31.784 31.784 0 0 1 1.63-24.438 31.824 31.824 0 0 1 28.707-17.821 31.695 31.695 0 0 1 14.163 3.323l.152.076c4.875 2.444 9.936 4.738 14.832 6.958 2.151.975 4.301 1.956 6.448 2.942a171.176 171.176 0 0 1 20.74 11.359c-4.719 7.021-13.827 40.129-10.493 63.017Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-3-a)"
        />
        <path
            d="m465.523 167.027 2.654.952c13.051 4.677 23.788 10.342 32.825 17.319a2.67 2.67 0 0 1 1.092 2.341c-.25 4.648-12.214 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-3-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-3-a"
                x1={504.992}
                y1={188.1}
                x2={469.891}
                y2={332.443}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-3-b"
                x1={485.976}
                y1={168.534}
                x2={443.47}
                y2={343.329}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
