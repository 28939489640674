import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m552.626 313.777-9.588.166-9.589.31a151.789 151.789 0 0 1-19.177-.72c-3.196-.339-6.392-.575-9.588-1.023l-4.794-.608c-1.598-.207-3.196-.482-4.794-.72 4.15-11.05 3.771-27.126 0-31.81 1.598-.238 3.196-.513 4.794-.72l4.794-.609c3.196-.448 6.392-.684 9.588-1.023a151.789 151.789 0 0 1 19.177-.72l9.589.31 9.588.167a18.493 18.493 0 0 1 12.987 5.646 18.504 18.504 0 0 1 5.192 13.175 18.535 18.535 0 0 1-18.179 18.179Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-1-right-a)"
        />
        <path
            d="M392.795 257.468c.021-.017.031-.042.054-.058l4.024-2.861a4.366 4.366 0 0 1 4.842-.028l1.714.829c.069-1.656.229-3.307.483-4.946 3.406-20.413 7.418-23.521 11.414-29.972a412.104 412.104 0 0 0-23.489-1.805c-31.634-3.417-42.573 42.423-13.059 52.981 0 0 2.174.624 5.585 1.451l7.224-14.178a4.035 4.035 0 0 1 1.208-1.413Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-1-right-b)"
        />
        <path
            d="M403.016 256.423c.082-1.851.264-3.696.543-5.527 2.638-17.834 8.963-26.626 11.939-29.891-7.781-.851-15.985-1.492-24.419-1.907a30.087 30.087 0 0 0-3.218-.176M379.113 270.672c.034.009 2.58.734 6.461 1.653"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M401.006 219.961c-10.735 8.458-16.807 30.614-15.058 52.128M395.367 247.648a164.052 164.052 0 0 0-1.217 9.557"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-1-right-a"
                x1={495.096}
                y1={295.277}
                x2={570.808}
                y2={295.277}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-1-right-b"
                x1={359.678}
                y1={276.632}
                x2={403.989}
                y2={217.363}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
