import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-2-cycling-2-left-a)">
        <path
            d="M393.531 582.412c6.217-7.229 35.983-40.175 43.516-50.68 8.514-12.234 17.145-24.704 28.878-34.114l.018-.009c.647-.545 1.298-1.085 1.953-1.62-23.04-6.569-44.634-15.243-66.215-25.315-65.083-26.816-32.455-119.192 34.258-98.27 24.475 8.685 47.343 22.38 68.668 37.704 21.054 16.593 40.828 34.628 57.747 57.62a30.112 30.112 0 0 1 4.607 10.822 28.47 28.47 0 0 1 .332 13.697 27.234 27.234 0 0 1-10.497 17.392l-33.902 25.207c-32.417 24.129-69.38 50.954-102.394 74.817-5.354-5.059-18.294-18.287-26.969-27.251Z"
            fill="#001489"
        />
        <path
            d="m442.035 594.077-30.864-31.651c-4.024 4.569-7.856 8.89-11.003 12.443l28.333 29.008c4.461-3.228 8.973-6.494 13.534-9.8Z"
            fill="url(#man-34-25-pants-2-cycling-2-left-b)"
        />
        <path
            d="m510.145 544.3-44.451-46.491c-11.614 9.387-20.19 21.77-28.647 33.921-1.393 1.942-3.549 4.654-6.172 7.826l35.348 36.948a7649.801 7649.801 0 0 0 43.922-32.204Z"
            fill="url(#man-34-25-pants-2-cycling-2-left-c)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-cycling-2-left-b"
                x1={400.168}
                y1={583.151}
                x2={442.035}
                y2={583.151}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-cycling-2-left-c"
                x1={430.875}
                y1={537.156}
                x2={510.145}
                y2={537.156}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <clipPath id="man-34-25-pants-2-cycling-2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
