import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-3">
        <path
            d="M499.365 185.794c7.001 3.509 14.197 6.626 21.309 9.913a169.295 169.295 0 0 1 20.621 11.294c6.666 4.182 13.232 8.563 19.662 13.219a212.402 212.402 0 0 1 18.743 15.067 23.431 23.431 0 0 1 5.396 7.374 23.43 23.43 0 0 1 .763 17.906 23.425 23.425 0 0 1-4.747 7.807 23.661 23.661 0 0 1-21.469 7.196 212.396 212.396 0 0 1-23.319-5.875c-7.591-2.324-15.044-4.925-22.399-7.723a169.063 169.063 0 0 1-21.44-9.649c-6.909-3.694-13.734-7.557-20.754-11.028a31.005 31.005 0 0 1-15.623-17.857 31 31 0 0 1 43.11-37.717l.147.073Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m547.179 251.417 1.136-9.021 1.07-9.028c.427-3.002.747-6.015 1.267-9.009.229-1.5.497-2.996.773-4.492.261-1.497.547-2.991.855-4.484 1.18-5.975 2.424-11.944 3.848-17.897a295.638 295.638 0 0 1 4.747-17.812 15.82 15.82 0 0 1 19.712-10.574 15.83 15.83 0 0 1 11.155 13.441 281.232 281.232 0 0 1 1.385 18.383c.303 6.113.426 12.209.485 18.299.026 1.524.031 3.046.015 4.565a147.103 147.103 0 0 1-.068 4.557c-.04 3.039-.282 6.059-.415 9.089l-.61 9.07-.547 9.076a22.51 22.51 0 1 1-44.942-2.707c.028-.468.079-.996.134-1.456Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M559.643 275.57a212.396 212.396 0 0 1-23.319-5.875c-7.591-2.324-15.044-4.925-22.399-7.723a169.063 169.063 0 0 1-21.44-9.649c-6.909-3.694-13.734-7.557-20.754-11.028a31.005 31.005 0 0 1-15.623-17.857 31 31 0 0 1 43.11-37.717l.147.073c7.001 3.509 14.196 6.627 21.308 9.913a169.018 169.018 0 0 1 20.621 11.295 399.44 399.44 0 0 1 7.52 4.834"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m548.312 242.394 1.071-9.027c.427-3.003.746-6.016 1.266-9.01.229-1.5.498-2.996.774-4.492.261-1.496.546-2.991.855-4.483 1.18-5.976 2.424-11.945 3.847-17.897a296.426 296.426 0 0 1 4.748-17.813M591.742 182.539a281.18 281.18 0 0 1 1.385 18.383c.302 6.113.426 12.209.484 18.299.027 1.524.032 3.045.015 4.565a147.105 147.105 0 0 1-.067 4.557c-.04 3.039-.282 6.058-.415 9.088l-.61 9.07-.547 9.077a22.513 22.513 0 0 1-23.825 21.117 84.172 84.172 0 0 1-8.517-1.126"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M609.506 189.493a12.812 12.812 0 0 0-5.261-9.735l-.557-.016c-.033-.49-.089-.98-.168-1.469-.639-3.906-3.07-7.677-6.757-9.117l-.534-.163a9.667 9.667 0 0 0-2.729-3.53c-2.622-2.08-6.181-2.532-9.527-2.435a36.016 36.016 0 0 0-15.776 4.171l-3.468 1.719c-2.287.927-3.545 3.533-3.563 6a15.955 15.955 0 0 0 2.064 7.035 42.998 42.998 0 0 1 2.443 5.361 2.652 2.652 0 0 0 1.809 1.721l.035.009a2.605 2.605 0 0 0 2.235-.41l.403.839a13.39 13.39 0 0 0 2.096 2.094c5.397 3.902 13.492 5.463 20.034 4.212a2.76 2.76 0 0 0 1.179 3.057l.04.025a2.738 2.738 0 0 0 3.675-.709 43.052 43.052 0 0 0 2.482-3.826l.266-.36a3.651 3.651 0 0 1 .677 3.488 19.83 19.83 0 0 1-3.168 6.368 2.405 2.405 0 0 0-.008 2.899l.028.036a2.386 2.386 0 0 0 1.721.938 2.395 2.395 0 0 0 1.844-.663 30.07 30.07 0 0 0 5.669-6.602 19.695 19.695 0 0 0 2.816-10.937Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M564.729 168.918c-2.287.927-3.545 3.533-3.563 6a15.942 15.942 0 0 0 2.064 7.035 43.051 43.051 0 0 1 2.443 5.36 2.643 2.643 0 0 0 1.809 1.722l.035.009a2.572 2.572 0 0 0 3.061-1.461 16.745 16.745 0 0 0 .208-12.913M568.197 167.199a36.044 36.044 0 0 1 15.776-4.171c3.346-.097 6.905.355 9.527 2.435 2.913 2.311 4.123 6.293 3.849 10a22.937 22.937 0 0 1-2.615 8.497 2.871 2.871 0 0 1-3.154 1.503l-.057-.012a2.883 2.883 0 0 1-2.256-3.28c.2-1.199.286-2.415.255-3.63a7.93 7.93 0 0 0-2.353-5.681"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M593.057 188.186a24.148 24.148 0 0 1-.773 7.593 2.759 2.759 0 0 0 1.179 3.057l.04.025a2.74 2.74 0 0 0 3.675-.709 37.07 37.07 0 0 0 4.619-8.274c1.442-3.687 2.361-7.698 1.723-11.605-.639-3.906-3.071-7.677-6.757-9.117"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M600.604 197.458a19.835 19.835 0 0 1-3.168 6.367 2.401 2.401 0 0 0-.008 2.899l.028.037a2.387 2.387 0 0 0 1.721.938 2.395 2.395 0 0 0 1.844-.663 30.07 30.07 0 0 0 5.669-6.602 19.683 19.683 0 0 0 2.816-10.937 12.785 12.785 0 0 0-5.261-9.735M572.25 191.57c5.397 3.902 13.493 5.463 20.034 4.211"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.284 186.091c-9.226-7.122-19.957-12.655-32.552-17.169l19.122 31.543s17.865-10.95 13.43-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.732 168.922a129.702 129.702 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
