import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-4-walk-2">
            <path
                d="M398.341 847.845c-23.427-3.047-35.307-14.226-36.92-15.846l-23.667-8.3a9.02 9.02 0 0 1-5.304-4.886 9.003 9.003 0 0 1-.083-7.204c5.935-14.207 8.508-20.969 9.135-23.789l34.643 10.248c.533.944 8.269 24.551 24.038 34.19 8.253 5.044 11.944 9.011 10.969 11.791-1.13 3.223-6.039 4.677-12.811 3.796Z"
                fill="#E2E2E2"
            />
            <path
                d="M377.756 800.907a3.11 3.11 0 0 1 2.03 1.678c4.123 8.639 11.63 23.139 20.92 28.818 11.766 7.192 12.136 10.85 11.391 12.975-1.316 3.75-6.506 5.416-13.884 4.456-15.833-2.06-26.493-7.811-32.33-11.908a59.992 59.992 0 0 0-14.651-7.443l-13.511-4.738a10.505 10.505 0 0 1-6.045-5.227 10.023 10.023 0 0 1-.231-8.297c2.573-6.16 4.708-11.403 6.349-15.59a3.216 3.216 0 0 1 3.171-2.096c4.32.336 5.085 9.34 12.287 11.284 7.659 2.068 20.679-4.89 24.504-3.912Z"
                fill="#63666A"
            />
            <path
                d="M411.644 845.328a16.372 16.372 0 0 1-7.323 1.349c-10.293.032-32.983-12.492-43.569-17.696-9.618-4.729-13.771-14.622-27.626-21.801-.538 1.302-1.097 2.647-1.681 4.045a10.01 10.01 0 0 0 .231 8.297 10.5 10.5 0 0 0 6.046 5.227l13.51 4.738a60.045 60.045 0 0 1 14.651 7.443c5.837 4.097 16.498 9.849 32.33 11.909 6.721.874 11.616-.437 13.431-3.511ZM441.175 860.886a9.046 9.046 0 0 1-6.656-2.87 9.053 9.053 0 0 1-2.474-6.804c.914-15.623 1.226-25.837.88-28.76l32.733-.6c.107.167 9.482 16.792 16.996 21.852 3.342 2.25 9.955 5.864 13.601 7.817a4.97 4.97 0 0 1 2.622 4.386 4.985 4.985 0 0 1-4.979 4.98l-52.723-.001Z"
                fill="#E2E2E2"
            />
            <path
                d="M466.2 824.805c1.544-.012 2.31.836 3.174 2.115 3.103 4.596 8.238 12.639 14.032 16.541 3.247 2.187 9.659 5.696 13.326 7.665a6.109 6.109 0 0 1 3.296 5.057 5.899 5.899 0 0 1-5.89 6.134h-54.466a10.46 10.46 0 0 1-7.404-2.918 9.963 9.963 0 0 1-2.956-7.725c.645-11.018.99-12.854 1.017-18.064.013-2.52.436-7.546 2.804-8.409 14.875 4.43 33.067-.396 33.067-.396Z"
                fill="#63666A"
            />
            <path
                d="M444.768 857.784c-5.969-2.419-12.757-9.857-15.084-12.55-.106 2.05-.225 4.199-.356 6.446a9.962 9.962 0 0 0 2.955 7.725 10.464 10.464 0 0 0 7.404 2.917h54.451a5.881 5.881 0 0 0 5.006-2.792c-14.504.113-47.44 1.066-54.376-1.746Z"
                fill="#E2E2E2"
            />
        </g>
    );
}
