import { IBodyClothes } from 'interfaces';

import Shirt2Normal from './man-34-25-shirt-2-normal';
import Shirt2Cycling1 from './man-34-25-shirt-2-cycling';
import Shirt2Cycling2 from './man-34-25-shirt-2-cycling-2';

import Shirt2RightArmDown from './man-34-25-shirt-2-arm-right-down';
import Shirt2RightArmOption1 from './man-34-25-shirt-2-arm-right-option-1';
import Shirt2RightArmOption2 from './man-34-25-shirt-2-arm-right-option-2';
import Shirt2RightArmOption3 from './man-34-25-shirt-2-arm-right-option-3';
import Shirt2RightArmOption4 from './man-34-25-shirt-2-arm-right-option-4';
import Shirt2RightArmOption5 from './man-34-25-shirt-2-arm-right-option-5';
import Shirt2RightArmOption6 from './man-34-25-shirt-2-arm-right-option-6';
import Shirt2RightArmOption7 from './man-34-25-shirt-2-arm-right-option-7';

import Shirt2LeftArmDown from './man-34-25-shirt-2-arm-left-down';
import Shirt2LeftArmUp from './man-34-25-shirt-2-arm-left-up';
import Shirt2LeftArmOption1 from './man-34-25-shirt-2-arm-left-option-1';
import Shirt2LeftArmOption2 from './man-34-25-shirt-2-arm-left-option-2';
import Shirt2LeftArmOption3 from './man-34-25-shirt-2-arm-left-option-3';
import Shirt2LeftArmOption4 from './man-34-25-shirt-2-arm-left-option-4';
import Shirt2LeftArmOption5 from './man-34-25-shirt-2-arm-left-option-5';
import Shirt2LeftArmOption6 from './man-34-25-shirt-2-arm-left-option-6';
import Shirt2LeftArmOption7 from './man-34-25-shirt-2-arm-left-option-7';

import Shirt2BothArmsOption1Right from './man-34-25-shirt-2-both-arms-option-1-right';
import Shirt2BothArmsOption1Left from './man-34-25-shirt-2-both-arms-option-1-left';
import Shirt2BothArmsOption2Right from './man-34-25-shirt-2-both-arms-option-2-right';
import Shirt2BothArmsOption2Left from './man-34-25-shirt-2-both-arms-option-2-left';
import Shirt2BothArmsOption3Right from './man-34-25-shirt-2-both-arms-option-3-right';
import Shirt2BothArmsOption3Left from './man-34-25-shirt-2-both-arms-option-3-left';
import Shirt2BothArmsOption1RightCycling from './man-34-25-shirt-2-both-arms-option-1-right-cycling';
import Shirt2BothArmsOption1LeftCycling from './man-34-25-shirt-2-both-arms-option-1-left-cycling';
import Shirt2BothArmsOption1RightCycling2 from './man-34-25-shirt-2-both-arms-option-1-right-cycling-2';
import Shirt2BothArmsOption1LeftCycling2 from './man-34-25-shirt-2-both-arms-option-1-left-cycling-2';

const shirt1: IBodyClothes = {
    text: 'Shirt #2',
    normal: {
        front: Shirt2Normal,
        rightArm: {
            down: Shirt2RightArmDown,
            option1: Shirt2RightArmOption1,
            option2: Shirt2RightArmOption2,
            option3: Shirt2RightArmOption3,
            option4: Shirt2RightArmOption4,
            option5: Shirt2RightArmOption5,
            option6: Shirt2RightArmOption6,
            option7: Shirt2RightArmOption7,
        },
        leftArm: {
            down: Shirt2LeftArmDown,
            up: Shirt2LeftArmUp,
            option1: Shirt2LeftArmOption1,
            option2: Shirt2LeftArmOption2,
            option3: Shirt2LeftArmOption3,
            option4: Shirt2LeftArmOption4,
            option5: Shirt2LeftArmOption5,
            option6: Shirt2LeftArmOption6,
            option7: Shirt2LeftArmOption7,
        },
        bothArms: {
            option1: [Shirt2BothArmsOption1Right, Shirt2BothArmsOption1Left],
            option2: [Shirt2BothArmsOption2Right, Shirt2BothArmsOption2Left],
            option3: [Shirt2BothArmsOption3Right, Shirt2BothArmsOption3Left],
        },
    },
    cycling1: {
        front: Shirt2Cycling1,
        bothArms: {
            option1: [Shirt2BothArmsOption1RightCycling, Shirt2BothArmsOption1LeftCycling],
        },
    },
    cycling2: {
        front: Shirt2Cycling2,
        bothArms: {
            option1: [Shirt2BothArmsOption1RightCycling2, Shirt2BothArmsOption1LeftCycling2],
        },
    },
};

export default shirt1;
