import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891']
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-2" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color][0]}
            d="M82.706 115.894c2.51 4.901 9.489 4.089 10.134-1.38.255-2.166.442-4.339.56-6.513-4.4-2.6-7.501-6.6-9.301-11.4-1.4-3.7-2.8-11.2.6-14.3 1.8-1.7 4.6-1.7 7-1.2 4.9 1 7.6 6 10 10.1h5.3c0-5 3.9-18.2 6.8-24.9.6-1.5.8-3.1.6-4.7-.6-3.6-1.3-7.6-1.7-10.5-.3-2.2 1.4-4.2 3.6-4.1 9.7.1 31.4.3 36 .3 1.3.5 3 2.4 3.5 3.6.8-5.9-3.8-25.2-42.5-25.2-18.8 0-28.5 2.2-32.2 14.2-10 2.6-14.5 18.9-14.3 29.8.2 10.9 3.9 21.5 8.6 31.4 2.358 5.074 4.82 9.937 7.307 14.793z"
        ></path>
        <path
            stroke={HAIR_COLOR[color][0]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M136.199 28.9c-5.9-1.9-13.4-3.1-23.1-3.1-18.8 0-28.5 2.2-32.2 14.2-10 2.6-14.5 18.9-14.3 29.8.2 10.9 3.9 21.5 8.6 31.401"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.101c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.101c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
    </g>
);

export default SvgComponent;
