import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#63666A"
                d="M577.734 672.023l9.999 31.115 5.611-.67s-.306-5.876-.681-10.219c-.26-3.01.228-7.656 3.629-7.689"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M587.733 703.138l5.612-.669"
            ></path>
            <path
                fill="#63666A"
                d="M592.68 674.713c-.364-8.495 1.694-17.526-6.19-26.353l30.876 1.89-1.763-3.492-34.115-2.297-3.968 21.126a13.898 13.898 0 009.687 15.909l5.868 1.758a18.82 18.82 0 014.235 1.792c-1.495-2.707-4.447-6.058-4.63-10.333zM637.742 686.625c-.865.025-1.725.141-2.565.347a21.456 21.456 0 00-10.773 7.025 14.641 14.641 0 00-3.049 10.874l.094.629.61.181a24.11 24.11 0 003.479.787l5.436.782c2.577.37 3.796-.115 6.472-.28l.969-.058-.03-.971a14.637 14.637 0 013.906-10.446 14.479 14.479 0 015.191-3.455l1.068-.229s-10.108-4.765-10.808-5.186z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M655.754 702.924c-.98 1.104-4.54 1.973-6.075 2.197-2.803.408-6.156.573-8.959.98-3.694.537-11.337 1.242-15.183.366-5.789-1.319-11.234-3.801-15.082-8.325l-6.767-7.954a22.32 22.32 0 00-10.613-6.931l-5.868-1.758a15.922 15.922 0 01-8.362-6.905"
            ></path>
        </g>
    );
}
