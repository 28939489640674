import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-5">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-5-a)"
            d="M487.454 263.4c-2.8.2-5.7.4-8.5.6-1.6.1-3.099.2-4.699.3-4.5.3-9 .7-13.2 1.1-3 .2-6 .5-8.9.8-1.4.1-2.801.3-4.301.4-9.6 1-18.199 2.1-26.199 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.801-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2c-3.6-14.3-16.3-24.3-31-24.3-2.6 0-5.199.3-7.799 1-17.1 4.3-27.601 21.7-23.301 38.8 1.2 4.9 2.301 9.9 3.201 14.8.4 2.1.899 4.3 1.299 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.301 14 8.201 20.4 2.5 5.6 5.899 12.8 10.199 20 .8 1.3 1.701 2.6 2.801 3.7 4 7.8 12.2 12.8 20.9 12.8 1.5 0 3-.1 4.5-.4l12.899-2.5 12.9-2.5c7.9-1.5 16.301-3.4 25.801-5.7 1.4-.3 2.799-.7 4.199-1 2.8-.7 5.8-1.4 8.6-2.2 4.1-1.1 8.501-2.2 12.801-3.4 1.5-.4 3-.8 4.5-1.3 2.7-.8 5.499-1.5 8.199-2.3h.201s-7.001-9.4-7.201-18.6c-.1-8.4 2.9-17.3 2.9-17.3-.5.1-1.2.1-1.9.1Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-5-a"
                x1={433.741}
                x2={401.644}
                y1={207.222}
                y2={313.921}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
