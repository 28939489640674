import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-4">
        <path
            d="m465.023 167.5 2.654.951c13.051 4.677 23.788 10.342 32.825 17.319.766.59 5.866 5.434 5.815 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.857.525-21.103-34.813Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-4-a)"
        />
        <path
            d="M524.078 220.735a926.01 926.01 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.616-9.445-16.021l-.073-.13a32.07 32.07 0 0 0-27.886-16.258 32.02 32.02 0 0 0-15.718 4.147 31.783 31.783 0 0 0-15.086 19.294 31.788 31.788 0 0 0 2.975 24.311c3.048 5.392 5.962 10.988 8.781 16.4 1.239 2.378 2.481 4.753 3.729 7.123a220.077 220.077 0 0 0 13.928 22.894c.722 1.057 1.44 2.1 2.154 3.13l46.678-47.455a202.61 202.61 0 0 0-5.885-10.539Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-4-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-4-a"
                x1={526.629}
                y1={100.547}
                x2={380.894}
                y2={383.341}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-4-b"
                x1={545.904}
                y1={110.48}
                x2={400.169}
                y2={393.275}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
