import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M583.001 648.706a738.018 738.018 0 01-3.261-23.727c-.478-3.95-1.035-7.905-1.447-11.851l-1.294-11.84c-.888-7.895-1.65-15.782-2.763-23.693l-1.66-11.865-.838-5.934-.643-5.92c-1.677-15.784-.015-31.339 2.194-46.857 1.19-7.753 2.704-15.485 4.666-23.185a144.846 144.846 0 017.712-22.976l4.133-9.364a24.414 24.414 0 0146.569 12.833l-5.724 46.616-2.873 23.308-3.001 23.298c-2.023 15.531-3.955 31.068-6.052 46.594l-6.219 46.582a14.726 14.726 0 01-14.438 12.772 14.722 14.722 0 01-14.706-12.463l-.355-2.328z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M611.153 487.686c-12.808 2.74-25.401 3.36-37.931 4.68 0 0-107.087-.542-110.05-1.08a52.3 52.3 0 01-23.942-91.806 52.305 52.305 0 0136.819-11.837l7.252.497c3.028.206 6.035.521 9.031.894l4.49.578 4.469.681c2.986.422 5.944.989 8.911 1.504 2.973.492 5.917 1.13 8.87 1.717 11.796 2.446 23.529 5.216 35.169 8.46 5.817 1.64 11.564 3.636 17.269 5.845a303.29 303.29 0 0117.009 7.177 160.56 160.56 0 014.217 1.978c1.405.663 2.805 1.35 4.2 2.061 2.799 1.378 5.563 2.94 8.346 4.4a163.475 163.475 0 0116.402 10.292l.87.636a29.876 29.876 0 0111.567 30.354 29.875 29.875 0 01-22.968 22.969z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M573.222 492.438s-107.087-.614-110.05-1.152a52.3 52.3 0 01-23.942-91.806 52.305 52.305 0 0136.819-11.837l7.252.497c3.028.206 6.035.521 9.031.894l4.49.578 4.469.681c2.986.422 5.944.989 8.911 1.504 2.973.492 5.917 1.13 8.87 1.717 11.796 2.446 23.529 5.216 35.169 8.46 5.817 1.64 11.564 3.636 17.269 5.845a303.29 303.29 0 0117.009 7.177 160.56 160.56 0 014.217 1.978c1.405.663 2.805 1.35 4.2 2.061 2.799 1.378 5.563 2.94 8.346 4.4a163.46 163.46 0 0116.401 10.292l.87.637c7.203 5.273 12.773 13.891 13.524 22.133M583.001 648.708a738.018 738.018 0 01-3.261-23.727c-.478-3.95-1.035-7.905-1.447-11.851l-1.294-11.84c-.888-7.895-1.65-15.782-2.763-23.693l-1.66-11.865-.838-5.934-.643-5.92c-1.677-15.784-.015-31.339 2.194-46.857a269.281 269.281 0 012.285-12.802M636.369 464.328l-5.724 46.616-2.873 23.308-3.001 23.298c-2.023 15.531-3.955 31.068-6.052 46.593l-6.219 46.583"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M613.864 645.369l16.81 35.087c1.647 3.732 3.373 4.866 7.059 6.613l13.823 6.551a5.412 5.412 0 013.073 5.422 5.423 5.423 0 01-4.828 4.878c-3.271.348-7.755.829-11.254 1.206-3.616.389-7.27.256-10.848-.397l-5.404-.984a23.355 23.355 0 01-14.153-8.519l-6.466-8.202c-2.79-3.539-5.334-4.315-9.599-5.769l-5.799-1.978a12.925 12.925 0 01-8.432-15.092l4.875-21.49"
        ></path>
        <path
            stroke="#000"
            strokeMiterlimit="10"
            d="M613.864 645.369l16.81 35.087c1.647 3.732 3.373 4.866 7.059 6.613l13.823 6.551a5.412 5.412 0 013.073 5.422 5.423 5.423 0 01-4.828 4.878c-3.271.348-7.755.829-11.254 1.206-3.616.389-7.27.256-10.848-.397l-5.404-.984a23.355 23.355 0 01-14.153-8.519l-6.466-8.202c-2.79-3.539-5.334-4.315-9.599-5.769l-5.799-1.978a12.925 12.925 0 01-8.432-15.092l4.875-21.49"
        ></path>
        <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M613.105 644.014l16.36 34.561a16.992 16.992 0 008.268 8.494l13.823 6.551a5.412 5.412 0 013.073 5.422 5.423 5.423 0 01-4.828 4.878c-3.271.348-7.755.829-11.254 1.206-3.616.389-7.27.256-10.848-.397l-5.403-.984a23.355 23.355 0 01-14.154-8.519l-6.465-8.202c-2.79-3.539-5.335-4.315-9.6-5.769l-5.799-1.978a12.925 12.925 0 01-8.432-15.092l4.875-21.49"
        ></path>
    </g>
);

export default SvgComponent;
