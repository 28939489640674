import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9439)"
            d="M479.234 236.749c-.182-4.782-.96-19.423-3.838-27.14a101.302 101.302 0 0118.685 14.4 1.706 1.706 0 01.535 1.598c-.512 2.969-8.018 7.691-15.382 11.142z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9439)"
            d="M477.416 275.831c-3.681-2.237-7.32-4.42-11.083-6.521a26.515 26.515 0 01-12.577-15.912 26.492 26.492 0 012.358-20.145 26.503 26.503 0 0136.057-10.219l.117.067c4.184 2.364 8.513 4.618 12.737 6.801-18.91 16.326-26.071 40.05-27.609 45.929z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M492.172 223.031l.119.067c3.691 2.086 7.446 4.06 11.213 6.011M485.83 280.936c-6.49-3.891-12.893-7.936-19.496-11.624M505.025 229.906c-18.91 16.325-26.07 40.05-27.609 45.929"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9439"
                x1="487.51"
                x2="469.53"
                y1="208.085"
                y2="276.598"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9439"
                x1="452.756"
                x2="559.759"
                y1="247.749"
                y2="247.749"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
