import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-2">
        <path
            d="m465.693 167.793 2.55 1.203c12.537 5.916 22.677 12.593 30.998 20.411.338.3.594.681.744 1.107.151.425.191.882.117 1.328-.698 4.602-13.338 10.972-15.864 12.202l-.904.439-17.641-36.69Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-2-a)"
        />
        <path
            d="M505.228 304.246a381.483 381.483 0 0 1-5.511-6.379c-5.351-6.358-10.614-13.032-16.091-20.407a204.777 204.777 0 0 1-14.282-21.579 854.277 854.277 0 0 1-3.848-6.751c-2.904-5.121-5.907-10.416-9.037-15.505a31.778 31.778 0 0 1-3.871-24.184 31.777 31.777 0 0 1 14.363-19.837 31.93 31.93 0 0 1 16.736-4.748 31.82 31.82 0 0 1 27.286 15.24l.08.13c3.106 5.105 6.452 10.189 9.688 15.106 1.426 2.165 2.846 4.333 4.261 6.505a204.761 204.761 0 0 1 12.71 22.547c4.068 8.226 7.614 15.951 10.836 23.609-12.879 2.965-36.626 25.736-43.32 36.253Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-2-b)"
        />
        <path
            d="M469.344 255.88a204.683 204.683 0 0 0 14.283 21.579c5.476 7.375 10.74 14.049 16.091 20.408a380.681 380.681 0 0 0 5.51 6.378c6.694-10.517 30.441-33.288 43.32-36.253-3.223-7.658-6.769-15.382-10.837-23.609a204.838 204.838 0 0 0-12.708-22.547 845.037 845.037 0 0 0-4.261-6.505c-3.236-4.917-6.582-10.001-9.688-15.106l-.08-.13a31.886 31.886 0 0 0-13.205-12.01c-8.042-7.247-17.707-13.513-29.525-19.089"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-2-a"
                x1={486.219}
                y1={162.512}
                x2={455.424}
                y2={322.994}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-2-b"
                x1={513.637}
                y1={170.528}
                x2={486.655}
                y2={311.134}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
