import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={973}
            viewBox="0 0 2144 973"
            fill="none"
            id="scene-office-6"
            {...attributes}
        >
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-6-a)"
            >
                <path 
                    d="M97.108 472.466v-18.841h18.841v18.841H97.108ZM17.582 472.466v-18.841h18.841v18.841H17.582ZM117.692 944.227h40.807c10.406 0 18.841 8.435 18.841 18.841H1.003c0-10.406 8.435-18.841 18.84-18.841h53.23M115.95 463.039h44.53M177.34 453.038v-11.96c0-5.53-4.48-10-10-10h-26.67M36.418 463.039h18.84" 
                />
                <path 
                    d="M123.258 431.078H10.998c-5.52 0-10 4.47-10 10v11.96c0 5.53 4.48 10 10 10h6.58M160.48 463.039v18.843M177.34 481.885v-40.807c0-5.523-4.478-10-10-10h-6.858M140.668 431.081v-5.769h-17.405v5.769"
                />
                <path 
                    d="M97.11 463.042H77.96l.002-7.917a7.484 7.484 0 0 0-7.484-7.484h-7.73a7.484 7.484 0 0 0-7.485 7.484v19.607H68.05v-11.69H55.26M73.076 944.227l32.19-.02-7.42-424.66M73.732 906.347l6.76-386.8"
                />
                <path 
                    d="M177.344 481.883v.84c0 11.2-7.77 20.9-18.71 23.32l-60.79 13.51M160.484 481.883H1.864c0 11.05 7.58 20.66 18.32 23.23l60.31 14.44" 
                />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M671 574.535v-30.652H235.968v30.652h412.674"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M258.325 593.372v323.057h390.316V574.531"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M258.326 745.483h371.475V593.375H258.326v152.108ZM629.801 745.484H239.485v152.108h390.316M483.609 617.375h-60.253M483.609 769.484h-60.253M1934.49 944.957l-.75 17.25h-14.84l-2.15-49.098M1961.89 913.117l5.13 15.54 11.07 33.55h14.84l-9.94-41M1869.64 921.211l-9.94 41h14.84l11.07-33.55"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1994.68 910.3c8.02-10.09 12.44-23.08 11.53-36.92-2.29-35.04-4.59-70.09-6.89-105.13H1853.3l-6.89 105.13c-1.28 19.57 8.09 37.44 23.23 47.83M1982.99 921.211a53.33 53.33 0 0 1-15.97 7.45c-2.93.82-5.96 1.4-9.09 1.71-9.36.94-19.34 1.53-29.85 1.59-11.85.06-23.03-.54-33.44-1.6-3.1-.31-6.12-.89-9.03-1.7M2012.36 599.24c-6.96-22.407-7.65-37.827.88-49.677 5.81-8.061 17.86-9.925 24.98-5.08 8.14 5.537 10.5 16.058 8.95 24.762-4.91 27.547-21.74 53.869-42.79 73.701 0-43.579-19.81-65.913-37.5-65.913-7.07 0-14.37 4.052-14.37 12.736 0 19.652 40.11 20.997 36.05 79.291 21.62-20.264 44.64-31.313 61.22-31.313 14.49 0 25.22 8.578 25.22 21.936 0 12.695-9.68 17.116-21.25 17.116-13.38 0-51.8-5.253-79.03 21.979 7.59-35.561-14.59-80.211-34.24-80.211-10.16 0-15.34 7.768-15.34 13.958 0 25.326 42.5 31.222 39.87 94.295 30.42-15.537 46.59-20.829 58.72-20.829 4.79 0 20.96 3.145 20.96 17.545 0 8.046-8.98 16.547-19.6 16.547-18.54 0-29.6-4.223-48.12.505-10.86 2.77-17.58 10.937-21.94 15.909 1.48-44.33-21.52-91.457-40.98-88.352-11.08 1.768-3.53 38.335 21 88.352-35.19-59.667-106.72-78.373-151.38-71.361-36.32 5.704-39.99 38 10.12 29.777 33.97-5.574 87.12 1.305 106.07 41.583 1.84-31.698-32.77-97.383-92.89-97.383-29.37 0-40.36-8.337-40.36-20.084 0-7.958 8.78-15.158 29.37-15.158 37.92 0 87.03 34.232 99.66 75.031-10.61-54.947 2.85-103.899 43.33-132.379 20.09-14.135 19.43-39.867-.13-45.726-26.19-7.844-74.52 45.347-62.15 123.158-42.82-45.853-77.54-36.409-103.85-44.803-19-6.06-33.44-14.341-32.63-27.766.77-12.742 15.71-17.681 33.41-15.726 40.52 4.478 77.04 38.437 84.32 50.968 6.63-38.274 11.23-67.662.24-99.873-5.71-16.747-20.03-22.977-29.76-20.064-9.82 2.941-14.99 15.539-14.06 27.663 1.49 19.606 15.34 42.006 20.5 47.747M1961.89 913.117h-45.15M1925.19 139.126v-25.321h-55.25v55.248h55.25v-16.219M1853.05 223.851v-5.597"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1925.19 139.131h36.7v59.814h-36.7v-12.987h-21.45v99.503c0 4.673-3.79 8.448-8.45 8.448-2.33 0-4.44-.941-5.97-2.475a8.373 8.373 0 0 1-2.48-5.973V132.332h-33.79v78.256M1847.3 230.982l-1.66 4.673c-1.48 4.188-1.43 8.825.44 12.861 1.61 3.463 4.13 5.687 6.96 5.687 2.84 0 5.36-2.224 6.96-5.687 1.88-4.036 1.93-8.673.44-12.861l-3.07-8.637c-.54-1.507-1.77-2.718-3.34-3.059a4.563 4.563 0 0 0-.99-.107M1847.31 230.977h5.49M1853.05 218.254h-5.74v-7.668h5.74M1897.56 121.27v5.692M1911.82 127.176l-4.03 4.025M1917.72 141.43h-5.69M1911.82 155.693l-4.03-4.025M1897.56 161.591v-5.693M1740.81 221.71h-110.57v-25.311h120.68V179.5h-120.68v-25.312h120.68"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1734.02 168.838v-31.475h67.53v84.347h-60.74M1708.68 128.91l-1.8-15.105h-13.31l-1.79 15.105a8.447 8.447 0 0 0 8.45 8.449M1658.53 137.36h-8.44v-13.869l-10.88-7.175v-2.511h31.93l-4.16 9.177v9.039M1717.12 221.71v35.509c0 4.666-3.78 8.448-8.44 8.448a8.447 8.447 0 0 1-8.45-8.448V137.363M1658.54 137.363v119.856c0 4.666 3.78 8.448 8.44 8.448 4.67 0 8.45-3.783 8.45-8.448V221.71"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1772.51 197.945h14.62v9.347h-14.62v-9.347ZM1489.49 164.644v-50.839h97.69v50.839h-31.11v-33.941h-49.68v16.899h62.82V164.5M1532.1 192.762h11.4M1506.38 164.648v28.118h11.4"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1569.21 164.5v28.261h-11.4M1543.5 192.762l4.72 15.66h4.87l4.72-15.66M1517.78 192.762l4.73 15.66h4.87l4.72-15.66"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-6-b)"
            >
                <path 
                    d="M443.705 113.526v330.063H622V87H265.411v356.589h147.41M474.59 418.575V370.07M412.82 418.575V370.07" 
                />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M866.785 55.297V87.21l-15.113 15.114M866.785 12.594v14.489M941.844 87.21h-14.488M866.785 162.387v-14.489M791.73 87.21h14.489"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M929.061 112.957a67.143 67.143 0 0 0 5.099-25.747c0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96C953 39.6 914.4 1 866.78 1s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 972H2142.05"
            />
            <defs>
                <clipPath id="scene-office-6-a">
                    <path fill="#fff" d="M178.344 424.312H0v539.755h178.344z" />
                </clipPath>
                <clipPath id="scene-office-6-b">
                    <path fill="#fff" d="M623 86H264.411v358.589H623z" />
                </clipPath>
            </defs>
        </svg>
    );
}
