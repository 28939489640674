import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g>
        <path
            d="M510.767 323.975c16.258-6.305 32.185-19.577 12.277-43.134-19.908-23.558-26.375-32.835-31.853-47.115-9.29-24.222-28.42-31.226-28.42-31.226-9.29 6.968-42.539.782-55.148-9.172l-19.771 11.891a61.32 61.32 0 0 0-26.166 73.107l18.824 52.893a36.515 36.515 0 0 1-6.6 36.016 110.855 110.855 0 0 0-18.887 31.923h167.8c7.668-30.163-4.789-55.045-12.056-75.183Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M499.32 384.527a13.776 13.776 0 0 1 10.286 0M415.27 300.331c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a31.5 31.5 0 0 0 18.534-21.325 31.511 31.511 0 0 0-6.485-27.5"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M522.822 399.159c7.669-30.163-4.788-55.045-12.054-75.183 16.257-6.305 32.184-19.577 12.276-43.134-19.908-23.558-26.375-32.835-31.853-47.115M407.623 193.328l-19.771 11.891a61.324 61.324 0 0 0-26.166 73.107l18.824 52.893a36.515 36.515 0 0 1-6.6 36.016 110.855 110.855 0 0 0-18.887 31.923"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
