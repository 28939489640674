import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={1001}
            viewBox="0 0 2144 1001"
            fill="none"
            id="scene-office-4"
            {...attributes}
        >
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 1000H2142.05M1492.85 467H89.852l-55.5 40.5H1244.85V527H25.352v28.5h168v105M173.848 581v40.5M209.848 581v40.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1721.97 252.295v-28.233h61.6v61.599h-61.6v-18.083M1802.41 346.756v-6.24"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1721.97 252.299h-40.92v66.69h40.92v-14.48h23.92v110.94c0 5.21 4.22 9.42 9.42 9.42 2.6 0 4.95-1.05 6.66-2.76a9.361 9.361 0 0 0 2.76-6.66v-170.73h37.68v87.251M1808.81 354.708l1.85 5.21c1.66 4.67 1.6 9.84-.48 14.34-1.8 3.86-4.61 6.34-7.77 6.34-3.16 0-5.97-2.48-7.76-6.34-2.09-4.5-2.15-9.67-.49-14.34l3.43-9.63c.6-1.68 1.97-3.03 3.72-3.41.37-.08.73-.12 1.1-.12M1808.81 354.703h-6.12"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1802.41 340.518h6.4v-8.549h-6.4M1752.78 232.383v6.347M1736.88 238.969l4.49 4.488M1730.3 254.859h6.34M1736.88 270.761l4.49-4.488M1752.78 277.339v-6.347M1947.33 321.307h123.28v-28.221h-134.55v-18.841h134.55v-28.222h-134.55"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1954.9 262.359v-35.093h-75.29v94.042h67.72M1983.16 217.842l2-16.842H2000l2 16.842c0 5.203-4.22 9.42-9.42 9.42M2039.06 227.263h9.42V211.8l12.13-8V201h-35.6l4.63 10.232v10.078M1973.74 321.308v39.59c0 5.203 4.22 9.42 9.42 9.42s9.42-4.218 9.42-9.42V227.266M2039.06 227.266v133.632c0 5.203-4.22 9.42-9.42 9.42s-9.42-4.218-9.42-9.42v-39.59"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1911.98 294.812h-16.29v10.422h16.29v-10.422Z"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1074.65 384.349h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1335.35 38v373.95H999.052V47.9H1304.6v336.45h-178.95"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1125.65 384.651 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-4-a)"
            >
                <path 
                    d="M300.346 27.526v330.063H122.051V1H478.64v356.589H331.23M269.461 332.575V284.07M331.23 332.575V284.07" 
                />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-4-b)"
            >
                <path 
                    d="M678.346 27.526v330.063H500.051V1H856.64v356.589H709.23M647.461 332.575V284.07M709.23 332.575V284.07" 
                />
            </g>
            <defs>
                <clipPath id="scene-office-4-a">
                    <path fill="#fff" d="M121.051 0H479.64v358.589H121.051z" />
                </clipPath>
                <clipPath id="scene-office-4-b">
                    <path fill="#fff" d="M499.051 0H857.64v358.589H499.051z" />
                </clipPath>
            </defs>
        </svg>
    );
}
