import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-normal">
        <path
            fill="url(#man-34-25-tshirt-3-normal-a)"
            d="m345.836 452.613-.109-.876c-2.915-23.317 1.064-43.872 4.91-63.749 4.392-22.693 8.54-44.129 2.332-67.925a328.384 328.384 0 0 1-10.89-74.739l-.541-22.589c-.375-15.628 6.461-30.813 18.525-40.319 25.43-20.041 49.755-28.119 49.755-28.119s1.916.108 9.777 9.759c1.9 2.331 7.57 9.271 9.486 10.981 8.467 7.556 16.208 11.228 23.664 11.227 1.341 0 2.678-.12 3.997-.359a9.27 9.27 0 0 0 5.236-2.763c4.714-5.098 3.377-16.737 3.377-16.737a63.212 63.212 0 0 1 8.248 5.997c25.56 21.883 46.64 61.31 52.533 82.777 2.137 7.783 2.361 14.435.668 19.773-3.298 10.393-3.013 21.139.802 30.258a107.855 107.855 0 0 1 8.851 40.646c.291 11.564.002 104.82 0 105.76l-.003.997H345.836Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-normal-a"
                x1={341.524}
                x2={536.587}
                y1={303.455}
                y2={303.455}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
