import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#EF0025"
            d="M494.764 222.602a109.64 109.64 0 00-20.026-15.329 31.024 31.024 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.013 1.013 0 00.563.522.982.982 0 00.767-.03c2.598-1.2 15.601-7.426 16.406-12.092a2.71 2.71 0 00-.822-2.483z"
        ></path>
        <path
            fill="#EF0025"
            d="M532.036 412.778c-6.059-8.26-11.323-16.682-16.414-24.826l-.128-.205c-10.564-16.788-16.214-29.272-23.368-45.077-1.184-2.617-2.405-5.316-3.692-8.128a12.39 12.39 0 01-.321-.78l-.06-.155c-10.787-15.509-23.594-41.841-28.623-58.828-.621-2.075-1.228-4.158-1.836-6.241-1.377-4.727-2.802-9.614-4.39-14.348a27.317 27.317 0 011.453-20.998 27.315 27.315 0 0115.874-13.82 27.47 27.47 0 0134.817 17.327c1.614 4.813 3.402 9.025 4.893 13.013 4.736 12.668 8.578 23.521 12.568 49.607 1.244 8.318 2.538 14.649 2.794 20.803a382.73 382.73 0 0131.82 79.552l.209.777-25.089 13.018-.507-.691z"
        ></path>
    </g>
);

export default SvgComponent;
