import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-2">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-2-a)"
            d="M369.61 286.481c-3.209-6.59-6.24-13.434-9.267-20.924a158.497 158.497 0 0 1-7.214-21.588 898.07 898.07 0 0 1-1.664-6.606c-1.251-5.02-2.544-10.21-4.018-15.246a32.038 32.038 0 0 1 21.726-39.7 32.067 32.067 0 0 1 9-1.296 32.166 32.166 0 0 1 30.699 23.021l.049.168c1.445 5.043 3.125 10.118 4.75 15.025.714 2.161 1.43 4.321 2.128 6.486a158.47 158.47 0 0 1 5.44 22.107 346.83 346.83 0 0 1 2.304 14.289c-11.29 1.467-42.808 11.877-53.933 24.264Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-2-a"
                x1={396.402}
                x2={366.908}
                y1={270.448}
                y2={182.833}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
