import React from 'react';

const SvgComponent = ({ position = 0 }: { position: number }) => (
    <g style={{ ...position ? { transform: 'translate(-30px, -45px)' } : {}}}>
        <path
            d="m470.599 707.501-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.201.5c-.8 2.2.401 4.7 2.701 5.4l50.9 17.1c2.3.8 4.699-.5 5.399-2.7.6-2.2-.599-4.6-2.699-5.3ZM630.7 406.801H591c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.3 5-5 5Z"
            fill="#63666A"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M402.999 745.601c-2.7 0-5.2-1.9-5.8-4.6l-63.8-274.4c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5l63.8 274.4c.8 3.2-1.3 6.5-4.5 7.2-.5 0-1 .1-1.4.1Z"
            fill="#D9D9D6"
        />
        <path
            d="m362.2 590.701-28.901-124.2c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.501 1.3 7.201 4.5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m315 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6Z"
            fill="#888B8D"
        />
        <path
            d="m315 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6ZM357.5 517.301l8.4 37.4"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M544.699 416.5c-1.1 0-2.101-.4-2.801-1.2l-9.399-9.4h-53.101c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.701c1.1 0 2.099.4 2.799 1.2l9.4 9.4h24.701l5.5-8.7c.7-1.2 1.999-1.9 3.399-1.9h11.101c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9h-28.5Z"
            fill="#D9D9D6"
        />
        <path
            d="M392.298 581.301c0-4.4-3.499-8-7.899-8h-.101l-17.5-.1-17.5.1h-.099c-2.2 0-4.3.9-5.8 2.7l-110.101 125.8-.099.1c-1.8 2.1-2.1 5.2-.5 7.7l22.2 33.8c1.8 2.7 5.4 3.5 8.2 1.8 2.8-1.8 3.7-5.5 1.9-8.3l-18.9-30.1 106.699-117.4 13.901.1h.099l-95.599 112.5-.1.1c-1.5 1.8-2 4.4-.9 6.7l12.9 27.8c1.4 2.9 4.9 4.3 7.8 3 3-1.3 4.4-4.9 3.1-7.9l-10.6-24.3 106.7-120.6c1.5-1.7 2.199-3.5 2.199-5.5Z"
            fill="url(#svg-bike-a)"
        />
        <path
            d="m306.398 681.401 84-94.9c1.2-1.4 2-3.2 2-5.2 0-4.4-3.499-8-7.899-8h-.101M352.898 589.301h14"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M573.199 549.201c-3.5 0-6.6-2.3-7.7-5.7l-30.7-104.6c-.7-2.5-.2-5.1 1.4-7.2l19.8-25.5c2.7-3.5 8.7-3 11.3.6 2.8 3.8 1.6 8.9-1.1 12.4l-14.8 19.1 29.6 100.6c1.2 4.2-1.2 8.7-5.4 9.9-.9.3-1.7.4-2.4.4Z"
            fill="#D9D9D6"
        />
        <path
            d="m539.999 456.601-5.2-17.6c-.7-2.5-.2-5.1 1.4-7.2l4.3-5.6"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M402.999 747.601c-3.6 0-6.9-2.5-7.8-6.2l-43.401-186.6c-.7-3 3.001-6 7.401-6 4.9 0 8.5 2.6 8.9 5.4l42.699 183.6c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2Z"
            fill="url(#svg-bike-b)"
        />
        <path
            d="m398.199 749.502-109.9-8.2c-4.1-.3-7.2-3.9-6.9-8 .3-4 3.7-7.1 7.7-6.9l110.2 3.2c5.5.2 9.9 4.8 9.7 10.3-.2 5.5-4.8 9.9-10.3 9.7-.1 0-.3 0-.5-.1Z"
            fill="url(#svg-bike-c)"
        />
        <path
            d="m582.699 538.1-19.6-66.8c-1.6-5.5-5.6-7.6-12.3-6.8-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-173.8 84.5c-3.6 1.8-5.2 6.1-3.5 9.9 1.7 3.8 6.2 5.4 9.9 3.7 0 0 137.4-62.5 169.6-77.1 3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2l148.8-208.9 2.3 7.9c1.3 4.4 5.3 7.2 9.6 7.2 1.3 0 2.6-.2 3.8-.8 4.8-2 7.2-7.4 5.7-12.4Z"
            fill="url(#svg-bike-d)"
        />
        <path
            d="m654.6 717.002-13.4-44.1-13.5-44.1-27-88.1c-1-3.2-4-5.6-7.5-5.6h-17.5l-11.3.1H558c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7 6.8.1h11.6l26.9 81.9 14.4 43.8 14.5 43.7c1 3.1 4.3 4.8 7.4 3.9 3.1-1 4.9-4.3 4-7.5Z"
            fill="url(#svg-bike-e)"
        />
        <path
            d="M641.599 727.802c3.976 0 7.199-4.298 7.199-9.6s-3.223-9.6-7.199-9.6c-3.977 0-7.201 4.298-7.201 9.6s3.224 9.6 7.201 9.6Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M631.4 762.302c18.17 0 32.9-19.745 32.9-44.1 0-24.356-14.73-44.1-32.9-44.1-18.17 0-32.9 19.744-32.9 44.1 0 24.355 14.73 44.1 32.9 44.1Z"
            fill="url(#svg-bike-f)"
        />
        <path
            d="M535.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
            fill="url(#svg-bike-g)"
        />
        <path
            d="M633.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.2-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.4-5.5.5-8.4.5Z"
            fill="url(#svg-bike-h)"
        />
        <path
            d="M621.399 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m584.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM688.2 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.401 2.3-.801 3.5l.301.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.1-2.8-1-3.6Z"
            fill="#FFB33B"
        />
        <path
            d="M599.799 690.302c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M267.7 727.802c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M257.399 776.101c23.859 0 43.2-25.923 43.2-57.9s-19.341-57.9-43.2-57.9-43.2 25.923-43.2 57.9 19.341 57.9 43.2 57.9Z"
            fill="url(#svg-bike-i)"
        />
        <path
            d="M161.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
            fill="url(#svg-bike-j)"
        />
        <path
            d="M259.899 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.2-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
            fill="url(#svg-bike-k)"
        />
        <path
            d="m210.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.4-2.4.8-3.6ZM314.299 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.5-1.2.1-2.8-1-3.6Z"
            fill="#FFB33B"
        />
        <path
            d="M247.499 798.202c-33 0-59.7-35.8-59.7-80.1s26.7-80.1 59.7-80.1c1.6 0 3.3.1 4.9.3 30.7 3.3 54.8 37.8 54.8 79.8s-24.1 76.5-54.8 79.8c-1.6.2-3.2.3-4.9.3Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m254.498 719.501-7.799-12.6c7.4-7.8 26.2-28 104.4-115.6l-10.9-12s-15.301 15.2-107.201 122.1l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1l11.301 16.8 11.299 16.8c1.8 2.6 5.4 3.4 8.1 1.8 2.8-1.7 3.7-5.4 2-8.2l-10.5-17.4Z"
            fill="#63666A"
        />
        <path
            d="m398.8 749.601-137.3-1.5c-4.1 0-7.5-3.4-7.4-7.6 0-4 3.3-7.3 7.3-7.4l137.2-3.5c5.5-.1 10.1 4.2 10.3 9.7.1 5.5-4.2 10.1-9.7 10.3h-.4ZM564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5 28.801 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7"
            fill="#63666A"
        />
        <path
            d="m376.398 588.801 34.4 148.9c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2-3.6 0-6.9-2.5-7.8-6.2l-35.301-152M356.099 573.301l-4.301-18.5c-.7-3 3.001-6 7.401-6 4.9 0 8.5 2.6 8.9 5.4l2.5 10.8M437.199 710.401l104.8-147.2"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M374.299 579.901s137.4-62.5 169.6-77.1c3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2M559.399 465.901c-2.1-1.4-5-1.8-8.6-1.4-5.6.6-8.899 5.6-7.299 11l1.6 5.3c-1.1.1-2.201.4-3.301.9l-171.199 83.3M581.799 534.9l-16.1-54.9M605.099 555.1l-4.4-14.3c-1-3.2-4-5.6-7.5-5.6h-.1l-11.3-.3M642.599 677.701l-14.9-48.8-11.4-37.2M603.299 600l10.9 33.2 13 39.3M608.199 679.901l-15.6-51-23.701-77.7 6.701.1h11.699"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5 28.801 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-8-26.4M349.599 730.801l-86.9 2.2-8.2-13.5-7.8-12.6c7.4-7.8 28-30 106.2-117.6"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M317.199 604.601s7.599-10.1-84.301 96.8l-.199.2c-1.9 2.2-2.2 5.5-.5 8.1 0 0 22.6 34.2 22.9 34.8 1.3 2.1 3.599 3.5 6.299 3.6l137.301 1.5h.4c5.5-.1 9.899-4.7 9.699-10.3-.1-5.5-4.7-9.9-10.3-9.7M564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M397.899 776.8c15.298 0 27.7-16.655 27.7-37.2s-12.402-37.2-27.7-37.2-27.7 16.655-27.7 37.2 12.402 37.2 27.7 37.2Z"
            fill="url(#svg-bike-l)"
        />
        <path
            d="M388.399 749.2c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M393.999 778c-15.9 0-28.7-17.2-28.7-38.5s12.9-38.5 28.7-38.5c.8 0 1.6 0 2.4.1 14.8 1.6 26.4 18.2 26.4 38.3 0 20.2-11.6 36.7-26.4 38.3-.8.3-1.6.3-2.4.3Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M393.999 789.902c-20.8 0-37.601-22.6-37.601-50.4 0-27.8 16.801-50.4 37.601-50.4 1 0 2.1.1 3.1.2 19.3 2.1 34.5 23.8 34.5 50.2 0 26.4-15.2 48.1-34.5 50.2-1 .2-2.1.2-3.1.2Z"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
            fill="#63666A"
        />
        <path
            d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M252.298 751.601c13.752 0 24.901-14.954 24.901-33.4 0-18.447-11.149-33.4-24.901-33.4s-24.9 14.953-24.9 33.4c0 18.446 11.148 33.4 24.9 33.4Z"
            fill="#fff"
        />
        <path
            d="M244.898 686.301c2.8-1.3 5.4-1.5 7.4-1.5 13.8 0 24.901 14.9 24.901 33.4s-11.101 33.4-24.901 33.4c-13.8 0-24.9-14.9-24.9-33.4"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M247.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
            fill="#E1E1DF"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M552.899 416.5h-8.3c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h7.7l1.9-2.2c2.7-3.5 8.7-3 11.3.6 2.8 3.8 2.1 8.2-.6 11.7l-15.3 19.8 8.1 27.5M571 408.5l5.5-8.7c.7-1.2 2-1.9 3.4-1.9H591c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M519.999 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.3 5-5 5ZM367.7 824.601l-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.7-2.2-.5-4.6-2.7-5.3Z"
            fill="#63666A"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="svg-bike-a"
                x1={302.345}
                y1={614.027}
                x2={322.731}
                y2={676.289}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.977} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="svg-bike-b"
                x1={339.625}
                y1={528.494}
                x2={412.337}
                y2={734.906}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.977} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="svg-bike-c"
                x1={413.173}
                y1={744.14}
                x2={284.151}
                y2={727.981}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.977} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="svg-bike-d"
                x1={441.208}
                y1={485.413}
                x2={506.832}
                y2={696.625}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.959} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="svg-bike-e"
                x1={571.362}
                y1={629.955}
                x2={632.75}
                y2={629.955}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.977} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="svg-bike-f"
                x1={631.37}
                y1={762.224}
                x2={631.37}
                y2={674.103}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset={1} stopColor="#D9D9D6" />
            </linearGradient>
            <linearGradient
                id="svg-bike-g"
                x1={574.394}
                y1={855.512}
                x2={574.394}
                y2={580.812}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset={1} stopColor="#D9D9D6" />
            </linearGradient>
            <linearGradient
                id="svg-bike-h"
                x1={627.631}
                y1={870.757}
                x2={627.631}
                y2={633.545}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="svg-bike-i"
                x1={257.435}
                y1={776.109}
                x2={257.435}
                y2={660.216}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset={1} stopColor="#D9D9D6" />
            </linearGradient>
            <linearGradient
                id="svg-bike-j"
                x1={200.459}
                y1={855.512}
                x2={200.459}
                y2={580.812}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset={1} stopColor="#D9D9D6" />
            </linearGradient>
            <linearGradient
                id="svg-bike-k"
                x1={253.696}
                y1={870.394}
                x2={253.696}
                y2={631.267}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="svg-bike-l"
                x1={397.863}
                y1={776.723}
                x2={397.863}
                y2={702.391}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F3F3F3" />
                <stop offset={1} stopColor="#D9D9D6" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
