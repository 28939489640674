import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7163)"
            d="M476.744 231.756c.76-3.49 1.23-7.037 1.406-10.605a33.848 33.848 0 00-1.403-11.518 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7163)"
            d="M586.122 300.471c-2.391-1.756-5.027-3.522-8.064-5.403-.277-.168-.553-.339-.83-.51l-.215-.133a62.835 62.835 0 00-2.043-1.231l-3.076-1.739c-1.403-.827-2.845-1.565-4.261-2.29-.682-.349-1.364-.698-2.051-1.062a82.887 82.887 0 00-13.155-5.468 524.95 524.95 0 00-4.047-1.252 863.533 863.533 0 01-3.563-1.1c-2.17-3.892-4.68-7.911-7.895-12.64-3.464-5.054-7.204-10.062-11.432-15.307a117.047 117.047 0 00-12.936-13.94 474.541 474.541 0 00-4.067-3.691l-.195-.176c-3.17-2.861-6.448-5.819-9.525-8.861l-.121-.121a27.597 27.597 0 00-19.388-7.898 27.549 27.549 0 00-19.854 8.359 27.577 27.577 0 00-7.896 19.717 27.567 27.567 0 008.357 19.526c3.113 3.04 6.143 6.283 9.072 9.42a638.658 638.658 0 003.889 4.138c4.366 4.607 9.1 8.851 14.155 12.689 5.32 4.14 10.395 7.796 15.515 11.177a151.185 151.185 0 0016.941 9.812c.575.275 1.172.523 1.826.76a19.736 19.736 0 004.582 2.346c3.034 1.07 6.079 2.319 9.061 3.543 1.308.536 2.615 1.072 3.928 1.596a82.825 82.825 0 0013.655 4.095c.747.15 1.492.311 2.236.472 1.539.333 3.13.676 4.74.925l3.509.605c.797.135 1.619.243 2.342.337l.213.029c.34.045.681.09 1.03.14 1.724.227 3.555.432 5.443.61l1.608.15-.257-1.596a24.963 24.963 0 012.127-12.633 23.113 23.113 0 0110.408-11.245l1.967-.877-1.733-1.273z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7163"
                x1="595.289"
                x2="435.675"
                y1="330.286"
                y2="182.816"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7163"
                x1="585.234"
                x2="425.619"
                y1="341.171"
                y2="193.701"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
