import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-7">
        <path
            d="M332.99 277.807c.669-7.981 1.754-16.261 2.343-21.158a202.168 202.168 0 0 1 4.447-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341a32.138 32.138 0 0 1 11.16-18.225 32.14 32.14 0 0 1 20.124-7.193c2.224.002 4.442.234 6.619.693a31.76 31.76 0 0 1 11.492 4.891 31.77 31.77 0 0 1 8.721 8.94 31.801 31.801 0 0 1 4.668 11.584 31.798 31.798 0 0 1-.156 12.488l-.031.148c-1.252 5.809-2.314 11.764-3.341 17.523-.451 2.531-.903 5.063-1.371 7.591a202.227 202.227 0 0 1-6.255 24.938 450.804 450.804 0 0 1-4.618 14.119c-16.132-6.679-42.807-10.294-59.73-6.174Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-7a)"
        />
        <path
            d="m408.337 219.662-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.451 2.531-.903 5.064-1.371 7.591a202.182 202.182 0 0 1-6.255 24.938 448.152 448.152 0 0 1-4.619 14.119c-16.132-6.68-42.807-10.294-59.73-6.174.669-7.981 1.754-16.261 2.343-21.157a202.184 202.184 0 0 1 4.447-25.318c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-7a"
                x1={378.092}
                y1={276.148}
                x2={354.67}
                y2={168.799}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
