import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-4">
        <g clipPath="url(#man-34-25-jacket-left-option-4-a)">
            <path
                d="m465.749 167.5 2.654.951c13.051 4.677 23.788 10.343 32.825 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.856.526-21.104-34.814Z"
                fill="url(#man-34-25-jacket-left-option-4-b)"
            />
            <path
                d="M566.991 237.739c-.709.026-1.52.044-2.414.044-7.345 0-20.984-1.213-29.808-9.335l-1.087-1s-.98 2.576-1.183 3.098c-.304.827-.603 1.656-.896 2.485-2.245-4.39-4.48-8.43-6.799-12.294a946.953 946.953 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.615-9.446-16.021l-.072-.129a31.998 31.998 0 0 0-43.604-12.111 31.998 31.998 0 0 0-12.111 43.604c3.048 5.393 5.962 10.989 8.781 16.401 1.238 2.378 2.481 4.752 3.729 7.123a219.891 219.891 0 0 0 13.928 22.893c5.364 7.86 10.525 14.987 15.78 21.791 4.549 5.854 10.662 13.51 17.61 20.807.486.508 1.042 1.03 1.697 1.594a23.476 23.476 0 0 0 14.235 8.528 23.48 23.48 0 0 0 27.315-18.955l1.819-10.071 1.887-10.085c.196-1.177.404-2.352.612-3.526.383-2.156.778-4.386 1.081-6.588.271-1.73.503-3.477.711-5.1.226-1.646.432-3.322.63-5.124.123-1.106.253-2.21.382-3.315.265-2.264.538-4.605.752-6.916.338-3.301.69-6.816.932-10.292.088-1.169.179-2.337.273-3.505.159-2.011.318-4.023.453-6.04l.074-1.107-1.109.042Z"
                fill="url(#man-34-25-jacket-left-option-4-c)"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-4-b"
                x1={489.54}
                y1={185.083}
                x2={620.311}
                y2={397.83}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-4-c"
                x1={483.186}
                y1={188.991}
                x2={613.957}
                y2={401.738}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <clipPath id="man-34-25-jacket-left-option-4-a">
                <path fill="#fff" transform="translate(.8 .688)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
