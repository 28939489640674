import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M481.526 297.676c-5.024.422-10.004 1.009-14.966 1.661-1.936.268-3.882.5-5.81.797l-5.781.905c-3.865.563-7.675 1.33-11.512 1.998a223.315 223.315 0 0 0-22.684 5.276l-.763.225a30.474 30.474 0 0 0-.01-.307c-.275-7.155-.834-14.248-1.598-21.298a145.84 145.84 0 0 0-3.577-20.876c-1.684-6.853-3.541-13.669-4.997-20.571l-.03-.144a26.498 26.498 0 0 0-31.511-20.288 26.496 26.496 0 0 0-16.669 11.554 26.498 26.498 0 0 0-3.619 19.956c1.494 6.894 2.585 13.875 3.85 20.817a146.011 146.011 0 0 0 5.27 20.514c2.186 6.746 4.576 13.448 7.249 20.09a184.425 184.425 0 0 0 9.148 19.684 19.762 19.762 0 0 0 4.936 5.784 18.495 18.495 0 0 0 19.437 7.054l11.293-2.825 11.329-2.687a223.37 223.37 0 0 0 22.314-6.668c3.663-1.323 7.353-2.546 10.987-3.975l5.469-2.081c1.822-.697 3.626-1.461 5.44-2.189 4.628-1.892 9.239-3.851 13.806-5.974-5.039-4.851-9.594-16.236-7.001-26.432Z"
            fill="url(#female-34-25-shirt-1-arm-opt-7-right-a)"
        />
        <path
            d="m409.797 245.344.031.143c1.455 6.903 3.313 13.719 4.996 20.572a145.83 145.83 0 0 1 3.578 20.875 315.393 315.393 0 0 1 1.443 17.84M383.517 337.676a184.35 184.35 0 0 1-9.148-19.685c-2.674-6.642-5.064-13.344-7.249-20.09a145.895 145.895 0 0 1-5.27-20.513c-1.265-6.943-2.356-13.923-3.85-20.817a26.472 26.472 0 0 1 6.952-23.946M417.849 309.177l2.926-.86a223.444 223.444 0 0 1 22.684-5.277c3.837-.668 7.648-1.435 11.513-1.997l5.78-.905c1.929-.298 3.875-.53 5.81-.798l14.966-1.66c-2.593 10.195 1.961 21.581 7.002 26.432l-13.806 5.974c-1.814.727-3.619 1.491-5.44 2.189l-5.469 2.081c-3.634 1.429-7.324 2.652-10.987 3.975a223.37 223.37 0 0 1-22.314 6.668l-11.329 2.687-11.293 2.825c-9.913 2.48-19.993-3.061-24.372-12.838"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-7-right-a"
                x1={357.393}
                y1={287.756}
                x2={488.528}
                y2={287.756}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
