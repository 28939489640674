import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-7">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-7-a)"
            d="M332.818 277.807c.669-7.981 1.755-16.261 2.343-21.158a202.168 202.168 0 0 1 4.447-25.317c.604-2.503 1.225-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.084-17.341a32.13 32.13 0 0 1 31.283-25.418c2.224.002 4.443.234 6.619.693a31.76 31.76 0 0 1 11.492 4.891 31.77 31.77 0 0 1 8.721 8.94 31.783 31.783 0 0 1 4.513 24.072l-.032.148c-1.252 5.809-2.314 11.764-3.341 17.523-.451 2.531-.902 5.063-1.371 7.591a202.227 202.227 0 0 1-6.255 24.938 450.804 450.804 0 0 1-4.618 14.119c-16.132-6.679-42.807-10.294-59.73-6.174Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-7-a"
                x1={341.722}
                x2={395.661}
                y1={199.404}
                y2={275.228}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
