import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#2B2729"
                d="M628.716 673.588c-2.939-1.236-8.541-3.558-13.041-5.226-16.981-6.296-35.478-26.135-39.648-30.777l-.208-22.831h-41.666l.892 3.322c1.775 6.693 3.64 13.4 5.54 19.937-.208 6.329-1.493 19.048-2.539 28.644a9.907 9.907 0 002.33 7.567 9.933 9.933 0 003.19 2.492 9.924 9.924 0 003.921 1.008l20.304 1.017a41.12 41.12 0 0112.374 2.507 70.775 70.775 0 0020.64 4.146c.318.018.7.027 1.137.027 5.148 0 18.055-1.225 25.292-1.954a5.16 5.16 0 004.63-5.338 5.174 5.174 0 00-3.148-4.541z"
            ></path>
            <path
                fill="url(#paint0_linear_10892_7192)"
                d="M628.445 672.915c-2.939-1.236-8.541-3.558-13.041-5.226-7.938-2.943-16.208-8.846-23.19-14.773a168.63 168.63 0 01-7.966-7.232c-4.688 1.386-11.718 9.924-18.41 9.951-13.085.054-22.265-5.783-26.397-6.09a958.238 958.238 0 01-1.665 16.439 9.883 9.883 0 002.33 7.566 9.898 9.898 0 007.111 3.5l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.73 70.73 0 0020.64 4.146c.318.018.7.027 1.136.027 5.149 0 18.056-1.224 25.292-1.954a5.159 5.159 0 004.63-5.338 5.167 5.167 0 00-3.148-4.54z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M540.376 674.227a9.933 9.933 0 003.19 2.492 9.924 9.924 0 003.921 1.008l20.304 1.017a41.12 41.12 0 0112.374 2.507 70.775 70.775 0 0020.64 4.146c.318.018.7.027 1.137.027 5.148 0 18.055-1.225 25.292-1.954a5.158 5.158 0 003.371-1.753 5.154 5.154 0 001.259-3.585"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7192"
                    x1="537.715"
                    x2="631.597"
                    y1="665.216"
                    y2="665.216"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#888B8D"></stop>
                    <stop offset="1" stopColor="#ABADAD"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
