import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M549.435 738.284a9014.77 9014.77 0 0 1-1.329-16.562l-1.881-23.435-2.02-23.409-4.062-46.816a27.18 27.18 0 0 0-.575-3.599 347.499 347.499 0 0 0 4.083-45.91c.026-1.389.057-2.78.091-4.172.058-2.554.118-5.109.139-7.661l.024-11.775c-.003-6.462-.094-14.967-.77-23.453-.583-7.403-1.498-15.154-2.719-23.04-1.39-8.934-2.673-16.158-4.036-22.736-.725-3.484-1.546-7.434-2.502-11.275-.239-.977-.472-1.956-.705-2.934-.648-2.72-1.318-5.532-2.107-8.269l-1.53-5.592-1.695-5.532c-1.289-4.125-2.549-7.733-3.848-11.027l-1.81-4.62c-7.76-19.674-.705-25.3-.705-27.686h-54.722s-25.974 4.289-34.377 15.353a51.695 51.695 0 0 0-8.913 18.239 51.69 51.69 0 0 0-1.199 20.265 200.116 200.116 0 0 0 1.896 11.43l1.126 5.671 1.259 5.608c.594 2.793 1.304 5.601 1.99 8.316.246.97.491 1.94.73 2.903.672 2.809 1.463 5.632 2.229 8.362.262.935 2.34 8.391 2.34 8.391l1.699 5.531c.406 1.299.802 2.6 1.198 3.9.715 2.35 1.454 4.779 2.248 7.152 2.249 6.933 4.92 14.095 8.165 21.895 1.639 3.94 3.412 7.916 5.127 11.761 1.43 3.207 2.908 6.524 4.3 9.798l4.626 10.783 4.52 10.813 17.683 42.451a107.823 107.823 0 0 0-1.816 10.714c-.195 1.444-.333 2.895-.467 4.297-.056.584-.112 1.168-.175 1.791-.045.702-.099 1.406-.151 2.11-.097 1.277-.198 2.599-.245 3.887a225.319 225.319 0 0 0 .095 23.806c1.155 17.548 2.94 32.176 7.897 46.507 1.653 5.049 8.455 29.355 9.612 33.967l52.582-10.002c-.43-5.446-.868-10.87-1.3-16.196Z"
            fill="#001489"
        />
        <path
            d="M349.88 408.688c-2.648 13.071-2.238 24.705-.148 38.512a193.57 193.57 0 0 0-1.515 11.31l-.575 5.667-.429 5.642c-.246 2.804-.385 5.655-.52 8.412-.048.982-.096 1.965-.147 2.939-.176 2.832-.241 5.712-.304 8.496-.022.964-.206 8.599-.206 8.599l.013 5.7c.011 1.338.01 2.676.01 4.015 0 2.419-.001 4.921.068 7.386.131 7.183.6 14.7 1.432 22.978.42 4.18.957 8.434 1.477 12.547.434 3.436.883 6.989 1.26 10.477l1.285 11.49 1.173 11.488 3.96 39.245a96.161 96.161 0 0 0-6.414 8.044c-.811 1.095-1.573 2.219-2.31 3.306-.315.463-.629.927-.97 1.423-.353.559-.714 1.116-1.074 1.672-.653 1.008-1.329 2.05-1.943 3.089a197.896 197.896 0 0 0-10.5 19.668c-6.775 14.994-11.692 27.869-13.66 41.953-.777 4.919-1.256 10.013-1.719 14.939-.236 2.501-3.478 28.991-3.478 28.991s14.097 6.108 25.374 9.867a137.04 137.04 0 0 0 23.128 5.498c2.57-8.426 22.239-59.498 22.239-59.498l8.608-20.207 17.194-40.424a27.09 27.09 0 0 0 1.303-3.894l1.289-2.492c8.178-15.814 14.068-31.844 18.29-44.056.43-1.299.863-2.597 1.3-3.895.802-2.39 1.602-4.779 2.367-7.178l3.453-11.079c1.879-6.084 4.271-14.118 6.096-22.303 1.6-7.14 2.982-14.702 4.111-22.479 1.273-8.806 2.151-15.979 2.762-22.573l.01-.109a43.4 43.4 0 0 1 18.06-31.406 157.814 157.814 0 0 0 12.84-10.235c20.889-18.462 32.63-35.648 38.559-51.525H349.88Z"
            fill="url(#female-34-25-pants-4-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-4-walk-1-a"
                x1={469.377}
                y1={582.997}
                x2={190.637}
                y2={588.872}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0147A7" />
                <stop offset={1} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
