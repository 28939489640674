import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6429)"
            d="M413.765 316.157a19.477 19.477 0 01-12.965-4.939 15.548 15.548 0 01-.779-.76v-.001a21.252 21.252 0 01-4.838-2.876 100.446 100.446 0 01-11.451-10.934c-3.309-3.645-6.478-7.513-9.69-11.826a74.788 74.788 0 01-7.949-12.869c-.673-1.386-1.331-2.783-1.989-4.18-1.433-3.044-2.915-6.192-4.549-9.177a27.508 27.508 0 0124.098-40.712c10.064 0 20.14 5.56 24.968 14.38.023.042 6.125 11.679 6.913 13.001 2.267 3.782 4.161 7.534 6.33 11.221 3.905-4.393 7.495-7.809 11.682-11.974 1.021-1.006 2.03-2.018 3.04-3.029 2.058-2.061 4.186-4.193 6.358-6.231l4.754-4.55c1.029-.98 2.078-1.941 3.127-2.902.578-.53 1.155-1.061 1.73-1.592 6.235-5.711 11.143-9.993 15.92-13.888l1.618-1.319.014 2.086c.07 9.774 13.346 17.918 17.392 18.189l1.656.11-.872 1.413c-2.837 4.597-6.079 9.542-10.202 15.562-.45.648-.893 1.294-1.336 1.941-.805 1.174-1.609 2.348-2.435 3.503l-3.843 5.356c-1.706 2.424-3.514 4.827-5.263 7.15a812.091 812.091 0 00-2.579 3.444 286.049 286.049 0 01-16.727 20.368l-8.842 9.711-8.722 9.794a19.52 19.52 0 01-14.569 6.53z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6429"
                x1="366.896"
                x2="450.173"
                y1="236.104"
                y2="267.333"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
