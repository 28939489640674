import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m451.888 780.704-.061-.936c-3.165-49.247-3.937-57.958-4.191-60.82-.115-1.287-.115-1.319-.182-3.376l-.042-1.319c-1.189-23.832 8.998-64.579 21.848-87.506-2.207-6.138-26.955-74.954-28.192-78.228-11.043-29.224-19.303-69.605-21.02-87.52l-.064-.662 84.106-45.103.516.42a51.936 51.936 0 0 1 16.215 22.886c.315 1.064.677 2.038 1.135 3.271 1.643 4.419 4.393 11.815 9.008 34.303 16.314 77.763 1.483 145.273-25.439 267.83a23928.568 23928.568 0 0 0-7.875 35.972l-.172.788h-45.59Z"
            fill="#009CDE"
        />
        <path
            d="m386.22 787.339-.18-.775c-10.331-44.6-12.604-53.238-13.46-56.49-.504-1.913-.568-2.159-1.4-6.273-5.526-25.231-2.51-70.11 6.453-96.236-.548-2.364-3.896-17.405-9.169-41.106-4.765-21.418-9.692-43.564-10.135-45.419-5.546-23.19-10.59-65.875-9.04-88.29a86.584 86.584 0 0 0-.363-16.973 69.808 69.808 0 0 1 2.221-27.69l.2-.735h170.127l-.505 1.35c-7.085 18.943-23.279 39.708-44.43 56.969a50.943 50.943 0 0 0-18.798 39.858 310.423 310.423 0 0 1-1.374 31.762c-1.532 15.545-7.268 45.954-14.213 69.932a145.1 145.1 0 0 0-5.612 36.894c-.829 34.637-3.081 103.994-4.351 142.254l-.031.967-45.94.001Z"
            fill="url(#female-34-25-pants-6-stand-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-6-stand-1-a"
                x1={469.601}
                y1={591.785}
                x2={360.3}
                y2={599.592}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#40C1E9" />
                <stop offset={1} stopColor="#0197D6" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
