import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-2">
        <path
            d="m465.139 167.793 2.55 1.203c12.537 5.916 22.676 12.593 30.997 20.411.338.3.594.681.745 1.106.15.426.19.883.116 1.329-.698 4.602-13.337 10.971-15.864 12.202l-.904.439-17.64-36.69Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-2-a)"
        />
        <path
            d="M504.673 304.247a380.352 380.352 0 0 1-5.51-6.38c-5.352-6.358-10.615-13.032-16.092-20.407a204.777 204.777 0 0 1-14.282-21.579 868.04 868.04 0 0 1-3.848-6.751c-2.904-5.121-5.907-10.416-9.037-15.505a31.805 31.805 0 0 1-3.871-24.184 31.77 31.77 0 0 1 5.195-11.357 31.773 31.773 0 0 1 9.168-8.48 31.933 31.933 0 0 1 16.736-4.748 31.81 31.81 0 0 1 27.286 15.24l.08.13c3.106 5.105 6.452 10.189 9.689 15.106 1.425 2.165 2.845 4.333 4.261 6.505a204.917 204.917 0 0 1 12.709 22.547c4.068 8.226 7.614 15.951 10.837 23.609-12.88 2.965-36.627 25.736-43.321 36.254Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-2-a"
                x1={485.665}
                y1={162.512}
                x2={454.869}
                y2={322.994}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-2-b"
                x1={513.082}
                y1={170.528}
                x2={486.101}
                y2={311.134}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
