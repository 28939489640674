import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-2">
        <path
            d="m465.248 167.793 2.55 1.203c12.537 5.916 22.676 12.593 30.998 20.411a2.664 2.664 0 0 1 .86 2.435c-.698 4.602-13.337 10.971-15.864 12.202l-.903.439-17.641-36.69Z"
            fill="url(#man-34-25-tshirt-2-arm-left-option-2-a)"
        />
        <path
            d="M504.783 304.245a381.483 381.483 0 0 1-5.511-6.379c-5.351-6.358-10.615-13.032-16.091-20.407a204.777 204.777 0 0 1-14.282-21.579 823.898 823.898 0 0 1-3.848-6.751c-2.904-5.121-5.907-10.416-9.038-15.505a32 32 0 0 1 22.2-48.362 31.991 31.991 0 0 1 32.315 14.833l.08.13c3.106 5.105 6.452 10.189 9.688 15.106 1.426 2.165 2.846 4.333 4.261 6.505a204.761 204.761 0 0 1 12.71 22.547c4.068 8.227 7.614 15.951 10.836 23.609-12.879 2.965-36.626 25.736-43.32 36.253Z"
            fill="url(#man-34-25-tshirt-2-arm-left-option-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-left-option-2-a"
                x1={519.196}
                y1={289.56}
                x2={473.221}
                y2={151.632}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-arm-left-option-2-b"
                x1={514.994}
                y1={290.961}
                x2={469.018}
                y2={153.033}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
