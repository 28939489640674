import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#EF0025"
                d="M668.529 672.962c-2.935-1.234-8.533-3.554-13.041-5.225-7.75-2.874-15.798-8.546-22.681-14.337-2.376-2-4.613-4.013-6.652-5.93-3.44-.082-13.912 5.794-19.66 6.152-11.52.72-20.071-3.664-27.048-3.185-.408 4.422-.94 9.65-1.588 15.594a10.008 10.008 0 002.333 7.57 10.014 10.014 0 003.193 2.483 10.01 10.01 0 003.916 1.014l20.303 1.017a41.12 41.12 0 0112.374 2.507 70.742 70.742 0 0020.64 4.146c.319.018.7.027 1.137.027 5.146 0 18.056-1.225 25.292-1.954a5.159 5.159 0 004.577-4.364 5.158 5.158 0 00-3.095-5.515z"
            ></path>
        </g>
    );
}
