import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={8}>
        <path
            d="M410.137 245.493c1.455 6.902 3.313 13.718 4.997 20.572a145.803 145.803 0 0 1 3.577 20.875c.764 7.05 1.323 14.144 1.599 21.298.326 7.235.225 14.482-.302 21.704a19.322 19.322 0 0 1-20.842 17.672 19.523 19.523 0 0 1-15.341-9.937 184.35 184.35 0 0 1-9.148-19.685c-2.673-6.641-5.064-13.344-7.249-20.09a146.036 146.036 0 0 1-5.271-20.513c-1.265-6.943-2.356-13.923-3.849-20.817a26.492 26.492 0 0 1 3.618-19.957 26.49 26.49 0 0 1 27.008-11.729 26.506 26.506 0 0 1 17.054 10.98 26.505 26.505 0 0 1 4.118 9.484l.031.143Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m398.708 314.75 11.206-3.148 11.17-3.285a223.669 223.669 0 0 1 22.685-5.277c3.837-.668 7.647-1.435 11.512-1.997l5.781-.905c1.928-.298 3.873-.529 5.809-.797 7.758-1.02 15.558-1.88 23.502-2.198a12.291 12.291 0 0 1 12.375 9.13 12.294 12.294 0 0 1-6.217 14.066c-7.056 3.663-14.256 6.786-21.498 9.747-1.813.728-3.619 1.491-5.44 2.189l-5.469 2.081c-3.634 1.429-7.324 2.652-10.987 3.975a223.388 223.388 0 0 1-22.315 6.668l-11.329 2.687-11.293 2.825a18.503 18.503 0 0 1-9.492-35.761Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m410.109 245.344.031.143c1.455 6.903 3.313 13.719 4.997 20.572a145.827 145.827 0 0 1 3.577 20.875 315.393 315.393 0 0 1 1.443 17.84M383.826 337.674a184.23 184.23 0 0 1-9.148-19.684c-2.674-6.642-5.064-13.345-7.249-20.09a145.74 145.74 0 0 1-5.271-20.514c-1.265-6.943-2.356-13.923-3.85-20.817-1.81-8.357 1.785-17.38 6.007-24.553M418.158 309.178l2.927-.861a223.588 223.588 0 0 1 22.684-5.277c3.836-.668 7.647-1.435 11.512-1.997l5.781-.905c1.928-.298 3.873-.529 5.809-.797 7.758-1.02 15.558-1.88 23.502-2.198a12.294 12.294 0 0 1 6.157 23.196c-7.055 3.663-14.255 6.786-21.497 9.747-1.814.728-3.619 1.491-5.44 2.189l-5.469 2.081c-3.635 1.429-7.324 2.652-10.987 3.975a223.388 223.388 0 0 1-22.315 6.668l-11.329 2.687-11.292 2.825c-9.913 2.48-19.994-3.06-24.373-12.838"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M556.499 306.676a2.523 2.523 0 0 0-.866-1.664c-.678-.642-1.685-.497-2.614-.582l.067-.86a2.035 2.035 0 0 0-1.575-1.703 21.346 21.346 0 0 0-8.957-.089c-3.384.71-6.717 1.737-10.162 2.029a24.328 24.328 0 0 1-17.204-5.31l-1.737-1.494c2.416-2.088 5.538-3.12 8.536-4.218a22.974 22.974 0 0 0 6.769-3.465 2.073 2.073 0 0 0 .486-2.627 2.069 2.069 0 0 0-2.508-.919c-3.63 1.24-7.876 1.282-11.621 1.901a84.858 84.858 0 0 0-12.679 2.637 22.56 22.56 0 0 0-10.759 6.904c-6.81 8.572-5.025 18.683 1.997 21.794 7.865 2.431 11.43 1.595 19.648 1.128 2.83-.16 5.387-1.053 8.21-1.347l1.272.023c10.771.638 21.568-3.067 31.146-7.77a2.67 2.67 0 0 0 1.618-2.736c.626-.137.96-.991.933-1.632Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M513.45 296.999c2.415-2.088 5.538-3.12 8.536-4.217a23 23 0 0 0 6.769-3.466 2.08 2.08 0 0 0 .389-2.785 2.086 2.086 0 0 0-2.411-.76c-3.63 1.24-7.876 1.281-11.62 1.901a84.981 84.981 0 0 0-12.679 2.636 24.958 24.958 0 0 0-8.516 4.592M523.188 312.437c5.106 1.07 10.369-.553 15.231-2.45 4.21-1.642 8.443-3.529 12.889-4.12a2.038 2.038 0 0 0 1.778-2.294 2.032 2.032 0 0 0-1.575-1.702 21.346 21.346 0 0 0-8.957-.09c-3.384.71-6.717 1.738-10.162 2.029a24.328 24.328 0 0 1-17.204-5.31M555.477 307.305l-9.216 4.237a97.723 97.723 0 0 1-11.388 4.667 87.992 87.992 0 0 1-21.554 3.93c-6.375.362-10.025.834-14.928.02"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M553.945 311.047c-9.578 4.703-17.406 7.402-28.687 7.842"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
