import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_10894_7481)"
                d="M611.539 693.443a198.748 198.748 0 01-24.267-2.024c-4.506-.658-9.243-1.518-13.262-4.125-4.836-3.134-8.376-8.808-9.969-15.975a79.801 79.801 0 01-1.411-14.44 274.953 274.953 0 00-.174-4.173c-.536-10.354-3.491-19.716-6.619-29.628l-.683-2.165-4.216-5.987 30.323-19.844 4.745 11.516c4.096 8.311 7.929 16.812 11.635 25.033 3.697 8.2 7.52 16.679 11.626 25.016l.492.999c4.17 8.457 8.481 17.203 9.948 26.44a8.087 8.087 0 01-1.832 6.523 8.086 8.086 0 01-6.155 2.836l-.181-.002z"
            ></path>
            <path
                fill="url(#paint1_linear_10894_7481)"
                d="M574.833 690.52c-4.73-4.001-9.199-12.035-10.792-19.202a79.891 79.891 0 01-1.411-14.44c-.051-1.393-.101-2.784-.174-4.173a75.919 75.919 0 00-1.743-12.428c8.409-5.42 8.414-9.315 16.795-11.926 8.819-2.747 12.723 1.88 20.133 3.279 3.697 8.2 7.52 16.678 11.626 25.015l.492.999c4.17 8.457 8.481 17.203 9.948 26.44a61.51 61.51 0 01.74 11.062c-.433 11.35-13.734 22.337-45.614-4.626z"
            ></path>
            <mask
                id="mask0_10894_7481"
                style={{ maskType: 'luminance' }}
                width="61"
                height="81"
                x="560"
                y="627"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    d="M574.833 690.52c-4.73-4.001-9.199-12.035-10.792-19.202a79.891 79.891 0 01-1.411-14.44c-.051-1.393-.101-2.784-.174-4.173a75.919 75.919 0 00-1.743-12.428c8.409-5.42 8.414-9.315 16.795-11.926 8.819-2.747 12.723 1.88 20.133 3.279 3.697 8.2 7.52 16.678 11.626 25.015l.492.999c4.17 8.457 8.481 17.203 9.948 26.44a61.51 61.51 0 01.74 11.062c-.433 11.35-13.734 22.337-45.614-4.626z"
                ></path>
            </mask>
            <g fill="#001489" mask="url(#mask0_10894_7481)">
                <path
                    d="M616.986 666.425c-2.158 9.519-14.243 15.022-23.116 15.853a60.183 60.183 0 01-20.39-2.029c-5.146-1.363-10.892-4.534-16.265-3.3a2.33 2.33 0 00-1.614 1.648 2.342 2.342 0 00.59 2.23c2.811 2.327 6.342 3.088 9.778 4.207a78.991 78.991 0 0012.423 3.236c7.709 1.189 16.02 2.13 23.574-.402 9.066-3.038 21.585-10.217 19.359-21.442-.432-2.18-3.86-2.112-4.339-.001z"></path>
                <path
                    d="M609.142 658.362c-2.991 7.366-13.203 9.38-20.227 10.226a44.309 44.309 0 01-15.918-.757c-4.733-1.175-9.04-3.747-13.988-3.849-2.677-.055-3.126 3.287-1.26 4.652 9.797 7.165 24.102 8.353 35.678 6.122 8.838-1.704 17.578-5.913 20.257-15.142.868-2.988-3.433-3.984-4.542-1.252z"></path>
                <path
                    d="M604.463 647.975c-4.258 6.726-11.454 7.735-18.874 7.702a63.406 63.406 0 01-12.966-1.37c-3.647-.779-7.127-2.054-10.878-2.214-2.992-.127-3.358 3.62-1.384 5.11 7.608 5.744 21.278 6.013 30.357 4.951 7.987-.934 14.271-4.354 17.57-11.944 1.112-2.558-2.26-4.706-3.825-2.235z"></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_10894_7481"
                    x1="550.938"
                    x2="619.808"
                    y1="644.263"
                    y2="644.263"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#001489"></stop>
                    <stop offset="0.672" stopColor="#00359C"></stop>
                    <stop offset="1" stopColor="#0047A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10894_7481"
                    x1="560.713"
                    x2="620.477"
                    y1="667.396"
                    y2="667.396"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#0197D6"></stop>
                    <stop offset="0.558" stopColor="#22ADE0"></stop>
                    <stop offset="1" stopColor="#40C1E9"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
