import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-down">
        <path
            fill="url(#man-34-25-tshirt-arm-right-down-a)"
            d="m317.559 267.7.3-.8c2.4-7.3 5.2-15 8.3-22.6 3.2-8.2 6.8-16 10.8-23.3 1.2-2.3 2.5-4.5 3.7-6.8 2.8-5.1 5.7-10.4 8.4-15.7 5.5-10.9 16.4-17.6 28.6-17.6 5 0 9.9 1.2 14.4 3.4 7.6 3.8 13.3 10.4 16 18.5 2.7 8.1 2 16.8-1.8 24.4l-.1.1c-2.7 5.3-5.2 10.8-7.7 16.1-1.1 2.3-2.2 4.7-3.3 7-3.5 7.5-7.7 15.1-12.4 22.5-4.5 7.1-9 14-13.6 20.4l-.5.7-51.1-26.3Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-down-a"
                x1={333.099}
                x2={431.619}
                y1={167.842}
                y2={391.088}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.121} stopColor="#FFDA30" />
                <stop offset={0.269} stopColor="#FFE364" />
                <stop offset={0.408} stopColor="#FFEA8D" />
                <stop offset={0.534} stopColor="#FFF0AB" />
                <stop offset={0.642} stopColor="#FFF3BD" />
                <stop offset={0.72} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
