import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9437)"
            d="M494.776 223.29a2.71 2.71 0 01.822 2.483c-.805 4.666-13.808 10.893-16.406 12.093l-.912.421s-.585-24.988-5.356-31.349a108.173 108.173 0 0121.852 16.352z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9437)"
            d="M506.811 229.697c-4.634-2.393-9.425-4.866-14.031-7.469l-.124-.07a27.5 27.5 0 00-26.813 48.021c4.122 2.302 8.13 4.724 12.216 7.211 0 0 6.215-29.066 28.752-47.693z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9437"
                x1="487.171"
                x2="466.569"
                y1="205.287"
                y2="283.793"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9437"
                x1="451.754"
                x2="564.465"
                y1="248.027"
                y2="248.027"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
