import { IBodyClothes } from 'interfaces';

import Tshirt3Normal from './man-34-25-tshirt-3-normal';
import Tshirt3Cycling1 from './man-34-25-tshirt-3-cycling';
import Tshirt3Cycling2 from './man-34-25-tshirt-3-cycling-2';
import Tshirt3Sitting from './man-34-25-tshirt-3-sitting';

import Tshirt3RightArmDown from './man-34-25-tshirt-3-arm-right-down';
import Tshirt3RightArmOption1 from './man-34-25-tshirt-3-arm-right-option-1';
import Tshirt3RightArmOption2 from './man-34-25-tshirt-3-arm-right-option-2';
import Tshirt3RightArmOption3 from './man-34-25-tshirt-3-arm-right-option-3';
import Tshirt3RightArmOption4 from './man-34-25-tshirt-3-arm-right-option-4';
import Tshirt3RightArmOption5 from './man-34-25-tshirt-3-arm-right-option-5';
import Tshirt3RightArmOption6 from './man-34-25-tshirt-3-arm-right-option-6';
import Tshirt3RightArmOption7 from './man-34-25-tshirt-3-arm-right-option-7';

import Tshirt3LeftArmDown from './man-34-25-tshirt-3-arm-left-down';
import Tshirt3LeftArmUp from './man-34-25-tshirt-3-arm-left-up';
import Tshirt3LeftArmOption1 from './man-34-25-tshirt-3-arm-left-option-1';
import Tshirt3LeftArmOption2 from './man-34-25-tshirt-3-arm-left-option-2';
import Tshirt3LeftArmOption3 from './man-34-25-tshirt-3-arm-left-option-3';
import Tshirt3LeftArmOption4 from './man-34-25-tshirt-3-arm-left-option-4';
import Tshirt3LeftArmOption5 from './man-34-25-tshirt-3-arm-left-option-5';
import Tshirt3LeftArmOption6 from './man-34-25-tshirt-3-arm-left-option-6';
import Tshirt3LeftArmOption7 from './man-34-25-tshirt-3-arm-left-option-7';

import Tshirt3BothArmsOption1Right from './man-34-25-tshirt-3-both-arms-option-1-right';
import Tshirt3BothArmsOption1Left from './man-34-25-tshirt-3-both-arms-option-1-left';
import Tshirt3BothArmsOption2Right from './man-34-25-tshirt-3-both-arms-option-2-right';
import Tshirt3BothArmsOption2Left from './man-34-25-tshirt-3-both-arms-option-2-left';
import Tshirt3BothArmsOption3Right from './man-34-25-tshirt-3-both-arms-option-3-right';
import Tshirt3BothArmsOption3Left from './man-34-25-tshirt-3-both-arms-option-3-left';

import Tshirt3BothArmsOption1Cycling1Left from './man-34-25-tshirt-3-both-arms-option-1-cycling-1-left';
import Tshirt3BothArmsOption1Cycling1Right from './man-34-25-tshirt-3-both-arms-option-1-cycling-1-right';

import Tshirt3BothArmsOption1Cycling2Right from './man-34-25-tshirt-3-both-arms-option-1-cycling-2-right';
import Tshirt3BothArmsOption1Cycling2Left from './man-34-25-tshirt-3-both-arms-option-1-cycling-2-left';

const tshirt3: IBodyClothes = {
    text: 'Tshirt #3',
    normal: {
        front: Tshirt3Normal,
        rightArm: {
            down: Tshirt3RightArmDown,
            option1: Tshirt3RightArmOption1,
            option2: Tshirt3RightArmOption2,
            option3: Tshirt3RightArmOption3,
            option4: Tshirt3RightArmOption4,
            option5: Tshirt3RightArmOption5,
            option6: Tshirt3RightArmOption6,
            option7: Tshirt3RightArmOption7,
        },
        leftArm: {
            down: Tshirt3LeftArmDown,
            up: Tshirt3LeftArmUp,
            option1: Tshirt3LeftArmOption1,
            option2: Tshirt3LeftArmOption2,
            option3: Tshirt3LeftArmOption3,
            option4: Tshirt3LeftArmOption4,
            option5: Tshirt3LeftArmOption5,
            option6: Tshirt3LeftArmOption6,
            option7: Tshirt3LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt3BothArmsOption1Right, Tshirt3BothArmsOption1Left],
            option2: [Tshirt3BothArmsOption2Right, Tshirt3BothArmsOption2Left],
            option3: [Tshirt3BothArmsOption3Right, Tshirt3BothArmsOption3Left],
        },
    },
    sitting: {
        front: Tshirt3Sitting,
        rightArm: {
            down: Tshirt3RightArmDown,
            option1: Tshirt3RightArmOption1,
            option2: Tshirt3RightArmOption2,
            option3: Tshirt3RightArmOption3,
            option4: Tshirt3RightArmOption4,
            option5: Tshirt3RightArmOption5,
            option6: Tshirt3RightArmOption6,
            option7: Tshirt3RightArmOption7,
        },
        leftArm: {
            down: Tshirt3LeftArmDown,
            up: Tshirt3LeftArmUp,
            option1: Tshirt3LeftArmOption1,
            option2: Tshirt3LeftArmOption2,
            option3: Tshirt3LeftArmOption3,
            option4: Tshirt3LeftArmOption4,
            option5: Tshirt3LeftArmOption5,
            option6: Tshirt3LeftArmOption6,
            option7: Tshirt3LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt3BothArmsOption1Right, Tshirt3BothArmsOption1Left],
            option2: [Tshirt3BothArmsOption2Right, Tshirt3BothArmsOption2Left],
            option3: [Tshirt3BothArmsOption3Right, Tshirt3BothArmsOption3Left],
        },
    },
    cycling1: {
        front: Tshirt3Cycling1,
        bothArms: {
            option1: [Tshirt3BothArmsOption1Cycling1Right, Tshirt3BothArmsOption1Cycling1Left],
        },
    },
    cycling2: {
        front: Tshirt3Cycling2,
        bothArms: {
            option1: [Tshirt3BothArmsOption1Cycling2Right, Tshirt3BothArmsOption1Cycling2Left],
        }
    },
};

export default tshirt3;
