import { IBodyClothes } from 'interfaces';

import P1 from './female-34-25-doctors-coat-2-p1';
import P2 from './female-34-25-doctors-coat-2-p2';
import ArmRightOpt1 from './female-34-25-doctors-coat-2-arm-opt-1-right';
import ArmRightOpt2 from './female-34-25-doctors-coat-2-arm-opt-2-right';
import ArmRightOpt3 from './female-34-25-doctors-coat-2-arm-opt-3-right';
import ArmRightOpt4 from './female-34-25-doctors-coat-2-arm-opt-4-right';
import ArmRightOpt5 from './female-34-25-doctors-coat-2-arm-opt-5-right';
import ArmRightOpt6 from './female-34-25-doctors-coat-2-arm-opt-6-right';
import ArmRightOpt7 from './female-34-25-doctors-coat-2-arm-opt-7-right';
import ArmRightOpt8 from './female-34-25-doctors-coat-2-arm-opt-8-right';
import ArmRightOpt9 from './female-34-25-doctors-coat-2-arm-opt-9-right';
import ArmLeftOpt1 from './female-34-25-doctors-coat-2-arm-opt-1-left';
import ArmLeftOpt2 from './female-34-25-doctors-coat-2-arm-opt-2-left';
import ArmLeftOpt3 from './female-34-25-doctors-coat-2-arm-opt-3-left';
import ArmLeftOpt4 from './female-34-25-doctors-coat-2-arm-opt-4-left';
import ArmLeftOpt5 from './female-34-25-doctors-coat-2-arm-opt-5-left';
import ArmLeftOpt6 from './female-34-25-doctors-coat-2-arm-opt-6-left';
import ArmLeftOpt7 from './female-34-25-doctors-coat-2-arm-opt-7-left';
import ArmLeftOpt8 from './female-34-25-doctors-coat-2-arm-opt-8-left';
import ArmLeftOpt9 from './female-34-25-doctors-coat-2-arm-opt-9-left';
import ArmLeftOpt10 from './female-34-25-doctors-coat-2-arm-opt-10-left';

import ArmsRightOpt1 from './female-34-25-doctors-coat-2-arms-opt-1-right';
import ArmsLeftOpt1 from './female-34-25-doctors-coat-2-arms-opt-1-left';
import ArmsRightOpt2 from './female-34-25-doctors-coat-2-arms-opt-2-right';
import ArmsLeftOpt2 from './female-34-25-doctors-coat-2-arms-opt-2-left';

const doctorsCoat2: IBodyClothes = {
    text: 'Doctor\'s coat',
    normal: {
        front: P1,
        back: P2,
        rightArm: {
            option1: ArmRightOpt1,
            option2: ArmRightOpt2,
            option3: ArmRightOpt3,
            option4: ArmRightOpt4,
            option5: ArmRightOpt5,
            option6: ArmRightOpt6,
            option7: ArmRightOpt7,
            option8: ArmRightOpt8,
            option9: ArmRightOpt9,
        },
        leftArm: {
            option1: ArmLeftOpt1,
            option2: ArmLeftOpt2,
            option3: ArmLeftOpt3,
            option4: ArmLeftOpt4,
            option5: ArmLeftOpt5,
            option6: ArmLeftOpt6,
            option7: ArmLeftOpt7,
            option8: ArmLeftOpt8,
            option9: ArmLeftOpt9,
            option10: ArmLeftOpt10,
        },
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        }
    },
};

export default doctorsCoat2;
