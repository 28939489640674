import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-1">
        <path
            d="m465.573 167.461 2.654.951c13.051 4.678 23.789 10.343 32.825 17.319a2.684 2.684 0 0 1 1.093 2.341c-.25 4.649-12.215 12.209-14.611 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-jacket-arm-left-option-1-a)"
        />
        <path
            d="M570.025 311.484c-1.833 0-3.791-.065-6.162-.207-2.904-.16-5.928-.455-9.801-.955a67 67 0 0 1-9.57-1.878l-4.6-1.296-4.601-1.222a23.45 23.45 0 0 1-7.603-3.57 24.738 24.738 0 0 1-3.572-2.066 210.217 210.217 0 0 1-18.476-15.053c-5.565-5.027-11.064-10.361-16.812-16.306a166.092 166.092 0 0 1-15.16-17.693 711.658 711.658 0 0 1-4.131-5.613c-3.13-4.275-6.368-8.696-9.712-12.905a31.796 31.796 0 0 1-6.74-23.546 31.797 31.797 0 0 1 11.883-21.416 31.664 31.664 0 0 1 19.89-6.949 31.841 31.841 0 0 1 25.072 12.092l.105.132c3.319 4.223 6.866 8.386 10.297 12.413a1636.56 1636.56 0 0 1 4.527 5.34 166.141 166.141 0 0 1 13.713 18.842c4.452 6.961 8.371 13.544 11.975 20.115 2.998 5.511 5.369 10.195 7.44 14.7l1.026.396a67.437 67.437 0 0 1 8.794 4.243c3.346 1.892 6.021 3.517 8.415 5.112.74.489 1.556 1.035 2.419 1.634-3.851 2.268-12.67 17.918-8.616 35.656Z"
            fill="url(#man-34-25-jacket-arm-left-option-1-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-left-option-1-a"
                x1={486.063}
                y1={158.204}
                x2={467.413}
                y2={323.89}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-arm-left-option-1-b"
                x1={524.86}
                y1={162.573}
                x2={506.209}
                y2={328.26}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
