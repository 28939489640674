import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6499)"
            d="M400.397 274.414a1.014 1.014 0 01-.472-.119l-47.482-25.335a1.004 1.004 0 01-.495-.623 1.006 1.006 0 01.116-.786l.943-1.52c2.86-4.6 5.817-9.358 8.579-14.184a27.52 27.52 0 0137.525-10.216 27.536 27.536 0 0112.868 16.65 27.535 27.535 0 01-2.652 20.876l-.065.115c-2.758 4.766-5.371 9.692-7.9 14.455l-.081.154a.978.978 0 01-.592.488 1.034 1.034 0 01-.292.045z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6499"
                x1="374.575"
                x2="394.526"
                y1="221.074"
                y2="280.929"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
