import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M499.804 328.973a50.14 50.14 0 0 0 4.936-4.754 38.465 38.465 0 0 1 29.239-10.806c15.165.045 22.342 12.391 15.006 22.143-6.688 6.992-13.774 13.66-21.86 18.954-8.085 5.293-17.236 9.192-27.024 10.291-.457.065-.919.07-1.377.014-.415-.06-.8-.255-1.095-.554a1.468 1.468 0 0 1-.217-1.62l.109-.366c-2.396.709-4.82 1.341-7.242 1.972a2.472 2.472 0 0 1-2.462-1.309 2.547 2.547 0 0 1 .108-2.719l.2-.437a18.2 18.2 0 0 0-1.507 1.191 1.995 1.995 0 0 1-2.246.171 1.988 1.988 0 0 1-.592-.526l-.026-.031a2.09 2.09 0 0 1-.171-2.488c3.086-4.53 7.646-7.833 12.275-10.757 1.45-.916 2.922-1.81 4.383-2.718l-.754.102a46.625 46.625 0 0 0-14.613 7.768 1.413 1.413 0 0 1-1.064.307 1.398 1.398 0 0 1-.957-.557 3.61 3.61 0 0 1-.097-3.687 21.844 21.844 0 0 1 6.887-8.357 61.898 61.898 0 0 1 7.775-5.014l-.31-.1a4.734 4.734 0 0 1-2.877-1.216 2.877 2.877 0 0 1-.866-2.838 9.129 9.129 0 0 0 6.439-2.059Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M533.98 313.413a38.461 38.461 0 0 0-29.238 10.806 50.14 50.14 0 0 1-4.936 4.754 9.129 9.129 0 0 1-6.439 2.059M516.084 321.891a91.887 91.887 0 0 1-14.766 11.03c-3.935 2.363-8.068 4.444-11.676 7.278a22.781 22.781 0 0 0-6.887 8.358 3.985 3.985 0 0 0 1.211 4.634l.023.017M510.449 336.992c-4.22 4.239-9.536 7.156-14.59 10.349-4.628 2.924-9.189 6.227-12.275 10.756a2.096 2.096 0 0 0 .172 2.488l.025.032a2.004 2.004 0 0 0 1.344.779 1.988 1.988 0 0 0 1.494-.425 33.1 33.1 0 0 1 8.979-5.093c3.746-1.606 14.033-6.54 20.101-11.603M490.281 364.244a122.47 122.47 0 0 0 13.494-4.076 59.971 59.971 0 0 0 17.432-10.457M500.102 364.8c9.788-1.099 18.938-4.998 27.024-10.291 8.085-5.294 15.171-11.962 21.858-18.954"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M475.286 319.264c-7.776-1.25-15.518-1.942-23.25-2.468-1.932-.118-3.866-.274-5.796-.361l-5.79-.25c-3.863-.209-7.712-.207-11.568-.306a219.097 219.097 0 0 0-22.455.669c.024-.122.05-.243.073-.365 1.465-7.524 2.639-15.057 3.605-22.597a167.93 167.93 0 0 0 1.582-22.661c.024-7.57-.128-15.145.13-22.707l.004-.137a26.501 26.501 0 0 0-15.642-24.947 26.497 26.497 0 0 0-37.336 23.41c-.219 7.564-.849 15.114-1.302 22.67a167.68 167.68 0 0 0 .148 22.716c.488 7.586 1.184 15.179 2.171 22.78a209.761 209.761 0 0 0 4.113 22.841 19.388 19.388 0 0 0 3.53 7.216 18.5 18.5 0 0 0 17.643 10.657l11.514-.548 11.523-.406a219.475 219.475 0 0 0 22.964-2.148c3.814-.577 7.633-1.05 11.44-1.737l5.715-.964c1.904-.326 3.805-.72 5.707-1.076 7.608-1.479 15.204-3.124 22.766-5.328a12.297 12.297 0 0 0 8.837-12.572 12.31 12.31 0 0 0-3.166-7.502 12.305 12.305 0 0 0-7.16-3.879Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M363.974 337.55a209.765 209.765 0 0 1-4.114-22.841c-.986-7.602-1.683-15.194-2.171-22.78a167.842 167.842 0 0 1-.148-22.716c.453-7.556 1.083-15.106 1.303-22.67.211-7.274 3.684-15.417 8.569-20.066M411.819 248.078l-.004.137c-.258 7.562-.105 15.137-.129 22.707a167.918 167.918 0 0 1-1.582 22.661 361.386 361.386 0 0 1-2.905 18.883M405.824 316.592c7.667-.642 15.362-.88 23.053-.712 3.856.1 7.706.097 11.568.307l5.79.25c1.93.087 3.865.242 5.797.361 7.732.525 15.474 1.217 23.25 2.467a12.3 12.3 0 0 1 1.489 23.954c-7.562 2.204-15.159 3.849-22.766 5.328-1.902.356-3.803.75-5.707 1.076l-5.715.964c-3.807.686-7.626 1.16-11.44 1.736a219.207 219.207 0 0 1-22.964 2.149l-11.523.405-11.514.549c-10.207.486-18.986-7.177-21.173-17.873"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m547.07 329.019-.595-.226.493-.277a2.51 2.51 0 0 0 1.178-2.489 2.723 2.723 0 0 0-2.132-2.051c-3.789-.523-7.2-2.475-10.66-4.105-8.015-3.776-16.864-7.999-25.65-6.862l-33.611 4.35c-18.158 2.787-14.627 23.958-.417 26.135 7.298 1.418 14.683 2.325 22.062 3.231 12.124 1.489 23.524 3.133 35.488 1.325a1.735 1.735 0 0 0 1.447-1.917l-.004-.038a1.728 1.728 0 0 0-1.409-1.507 108.448 108.448 0 0 1-23.674-7.885l10.405.833c7.24.58 14.539 1.158 21.748.279a3.31 3.31 0 0 0 2.402-1.168 2.99 2.99 0 0 0 .43-2.762l-.236-.64c.506.081 1.011.163 1.516.246a2.33 2.33 0 0 0 1.219-4.472Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m475.594 317.468 33.61-4.35c8.786-1.137 17.636 3.087 25.65 6.863 3.46 1.63 6.872 3.581 10.661 4.104M511.102 329.219a483.977 483.977 0 0 1 34.248 4.383 2.317 2.317 0 0 0 1.748-.412c.249-.181.461-.41.621-.673.16-.264.265-.557.311-.862a2.331 2.331 0 0 0-1.462-2.526 125.135 125.135 0 0 0-34.167-7.785M475.176 343.604c7.298 1.417 14.684 2.324 22.063 3.231 12.124 1.489 23.524 3.133 35.488 1.324a1.736 1.736 0 0 0 1.447-1.916l-.004-.039a1.739 1.739 0 0 0-1.41-1.507 108.351 108.351 0 0 1-23.673-7.885l10.405.834c7.24.58 14.539 1.158 21.748.278"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
