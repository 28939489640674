import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9790)"
            d="M420.043 237.349a869.14 869.14 0 00-4.821-3.059c-4.253-2.689-8.65-5.469-12.857-8.365l-.119-.082a27.503 27.503 0 00-38.207 7.264 27.499 27.499 0 007.263 38.207c4.204 2.86 8.371 5.942 12.4 8.924l1.778 1.313 35.507-43.597-.944-.605z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9790"
                x1="362.037"
                x2="411.483"
                y1="242.204"
                y2="259.264"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
