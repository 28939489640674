import React from 'react';

const SvgComponent = () => (
    <g data-pants={8}>
        <path
            d="M549.135 738.245c-.448-5.519-.892-11.039-1.33-16.562l-1.88-23.435-2.02-23.409-4.062-46.816a27.21 27.21 0 0 0-.576-3.599 347.67 347.67 0 0 0 4.084-45.91c.026-1.389.056-2.78.091-4.172.058-2.554.118-5.108.139-7.661l.023-11.775c-.002-6.462-.093-14.967-.77-23.453-.583-7.403-1.498-15.154-2.719-23.04-1.39-8.934-2.673-16.158-4.037-22.736-.724-3.484-1.545-7.434-2.501-11.275-.239-.977-.474-1.954-.705-2.933-.649-2.72-1.319-5.533-2.108-8.27l-1.53-5.592-1.694-5.532c-1.289-4.125-2.549-7.733-3.848-11.027l-1.81-4.62c-7.76-19.674-.706-25.3-.706-27.686h-54.721s-25.974 4.289-34.377 15.353a51.695 51.695 0 0 0-8.913 18.239 51.69 51.69 0 0 0-1.199 20.265 200.116 200.116 0 0 0 1.896 11.43l1.126 5.671 1.259 5.608c.594 2.793 1.304 5.601 1.99 8.317.246.97.491 1.94.73 2.902.671 2.809 1.463 5.632 2.229 8.362.262.935 2.34 8.391 2.34 8.391l1.698 5.532c.407 1.298.803 2.599 1.199 3.899.714 2.35 1.454 4.779 2.248 7.152 2.249 6.933 4.92 14.095 8.165 21.895 1.639 3.94 3.413 7.916 5.128 11.762 1.43 3.207 2.907 6.523 4.299 9.797l4.626 10.783 4.52 10.813 17.683 42.451a108.217 108.217 0 0 0-1.817 10.714 110.971 110.971 0 0 0-.466 4.297c-.056.584-.112 1.168-.175 1.791-.045.702-.099 1.406-.152 2.11-.096 1.277-.197 2.599-.245 3.887a225.855 225.855 0 0 0 .096 23.806c1.155 17.548 2.94 32.176 7.897 46.507 1.652 5.049 8.455 29.355 9.611 33.967l52.582-10.002c-.428-5.446-.868-10.87-1.298-16.196Z"
            fill="#888B8D"
        />
        <path
            d="M349.58 408.65c-2.648 13.07-2.238 24.703-.148 38.511a194.33 194.33 0 0 0-1.516 11.31l-.574 5.666-.43 5.643c-.246 2.804-.384 5.655-.519 8.412a655.97 655.97 0 0 1-.148 2.938c-.175 2.832-.24 5.712-.303 8.496-.022.964-.206 8.599-.206 8.599l.013 5.701c.011 1.339.01 2.675.01 4.014 0 2.419-.001 4.922.067 7.388.132 7.183.601 14.699 1.433 22.977.42 4.18.957 8.433 1.477 12.546.434 3.436.882 6.989 1.26 10.477l1.285 11.49 1.173 11.489 3.96 39.245a96.173 96.173 0 0 0-6.415 8.044c-.81 1.095-1.572 2.219-2.31 3.306-.314.463-.628.927-.97 1.423a188.72 188.72 0 0 1-1.073 1.672 89.679 89.679 0 0 0-1.944 3.089 197.887 197.887 0 0 0-10.499 19.668c-6.775 14.994-11.692 27.869-13.66 41.953-.777 4.919-1.257 10.012-1.72 14.939-.235 2.501-3.477 28.99-3.477 28.99s14.097 6.109 25.374 9.867c7.535 2.5 15.274 4.34 23.128 5.498 2.57-8.426 22.239-59.498 22.239-59.498l8.608-20.206 17.194-40.423a27.269 27.269 0 0 0 1.303-3.895l1.289-2.493c8.177-15.814 14.068-31.843 18.29-44.056.429-1.299.862-2.597 1.3-3.894.801-2.39 1.602-4.779 2.367-7.179l3.453-11.079c1.879-6.084 4.271-14.118 6.096-22.302 1.6-7.14 2.982-14.703 4.111-22.479 1.272-8.807 2.15-15.98 2.762-22.574l.01-.108a43.39 43.39 0 0 1 18.06-31.407 157.942 157.942 0 0 0 12.84-10.235c20.888-18.462 32.63-35.648 38.559-51.525l-171.749.002Z"
            fill="url(##female-34-25-pants-8-walk-1-a)"
        />
        <path
            d="M317.823 717.649c-.087.924-.584 5.123-1.177 10.049 19.256 9.491 40.606 15.326 52.028 18.038a2986.002 2986.002 0 0 1 6.654-17.685c-12.353-3.407-35.079-10.289-56.506-20.028-.387 3.218-.701 6.456-.999 9.626Z"
            fill="url(##female-34-25-pants-8-walk-1-b)"
        />
        <path
            d="M317.823 717.649c-.087.924-.584 5.123-1.177 10.049 19.256 9.491 40.606 15.326 52.028 18.038a2986.002 2986.002 0 0 1 6.654-17.685c-12.353-3.407-35.079-10.289-56.506-20.028-.387 3.218-.701 6.456-.999 9.626Z"
            fill="url(##female-34-25-pants-8-walk-1-c)"
        />
        <path
            d="M322.014 690.545c20.218 9.682 45.441 17.207 59.585 21.049 2.094-5.47 3.487-9.089 3.487-9.089l3.715-8.719c-13.606-4.383-36.892-12.183-60.572-21.489a151.51 151.51 0 0 0-6.215 18.248Z"
            fill="url(##female-34-25-pants-8-walk-1-d)"
        />
        <path
            d="M322.014 690.545c20.218 9.682 45.441 17.207 59.585 21.049 2.094-5.47 3.487-9.089 3.487-9.089l3.715-8.719c-13.606-4.383-36.892-12.183-60.572-21.489a151.51 151.51 0 0 0-6.215 18.248Z"
            fill="url(##female-34-25-pants-8-walk-1-e)"
        />
        <path
            d="M488.239 730.471c.765 2.337 2.633 8.799 4.533 15.524l56.221-9.499c-.4-4.938-.797-9.877-1.19-14.818l-.361-4.498-60.225 10.175c.326 1.041.664 2.08 1.022 3.116ZM545.925 698.247l-1.62-18.778-63.51 10.731c.539 6.762 1.232 13.105 2.234 19.2l62.938-10.634-.042-.519Z"
            fill="#63666A"
        />
        <defs>
            <linearGradient
                id="#female-34-25-pants-8-walk-1-a"
                x1={346.848}
                y1={576.376}
                x2={443.788}
                y2={585.485}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="#female-34-25-pants-8-walk-1-b"
                x1={472.053}
                y1={724.176}
                x2={193.314}
                y2={730.051}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="#female-34-25-pants-8-walk-1-c"
                x1={289.959}
                y1={708.683}
                x2={395.919}
                y2={746.255}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="#female-34-25-pants-8-walk-1-d"
                x1={471.322}
                y1={689.492}
                x2={192.581}
                y2={695.367}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="#female-34-25-pants-8-walk-1-e"
                x1={289.83}
                y1={669.584}
                x2={409.66}
                y2={712.075}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
