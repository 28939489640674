import React, { useState, useEffect } from 'react';
import { TSettings, TGender } from 'interfaces';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as CheckmarkIcon } from 'icons/checkmark.svg';
import { ReactComponent as MaleIcon } from 'icons/male.svg';
import { ReactComponent as FemaleIcon } from 'icons/female.svg';

interface TOptions {
    View: TOption[];
    'Body type': TOption[];
    Sex: TOption[];
    Age: TOption[];
}

type TOption = { name: string; disabled?: boolean; icon?: JSX.Element | null; }

const OPTIONS: TOptions = {
    View: [
        { name: 'Profile', icon: <CheckmarkIcon />, disabled: true },
        { name: '3/4', icon: <CheckmarkIcon />, },
        { name: 'Front', icon: <CheckmarkIcon />, disabled: true },
    ],
    'Body type': [
        { name: 'Athlete', icon: <CheckmarkIcon />, disabled: true },
        { name: 'Stout', icon: <CheckmarkIcon />, },
    ],
    Sex: [
        { name: 'Male', icon: <MaleIcon /> },
        { name: 'Female', icon: <FemaleIcon /> },
    ],
    Age: [
        { name: '0-1', icon: null, disabled: true },
        { name: '3+', icon: null, disabled: true },
        { name: '7+', icon: null, disabled: true },
        { name: '15+', icon: null, disabled: true },
        { name: '25+', icon: null },
        { name: '50+', icon: null, disabled: true },
    ],
};

const DEFAULT: Record<keyof TOptions, string> = {
    View: OPTIONS.View[1].name,
    'Body type': OPTIONS['Body type'][1].name,
    Age: OPTIONS.Age[4].name,
    Sex: OPTIONS.Sex[1].name,
};

function Options({
    option,
    selected,
    handleChange
}: {
    option: keyof TOptions;
    selected: string;
    handleChange: (i: Partial<Record<keyof TOptions, string>>) => void;
}) {
    return (
        <div key={option}>
            <h3 className="create-option-title">{option}</h3>
            <div className="create-options-wrap">
                {OPTIONS[option]?.map(({ name: attrName, disabled, icon }, _, array) => {
                    const checked = attrName === selected;
                    const newSetting: Partial<Record<keyof TOptions, TOption['name']>> =
                        { [option as keyof TOptions]: attrName };
                    return (
                        <label
                            className={`create-attribute ${array.length > 3
                                ? 'thin' : ''} ${disabled ? 'disabled' : ''} ${checked ? 'checked' : ''}`}
                            htmlFor={`attribute-select-${option}-${attrName}`}
                            key={`attribute-select-${option}-${attrName}`}
                            data-active={checked.toString()}
                        >
                            <input
                                id={`attribute-select-${option}-${attrName}`}
                                type="radio"
                                value={attrName}
                                checked={checked}
                                onChange={() => handleChange(newSetting)}
                                disabled={disabled}
                            />
                            {icon}
                            <p className="attribute-label-text">
                                {attrName}
                            </p>
                        </label>
                    );
                })}
            </div>
        </div>
    );
}

export default function Create({
    toggleModal,
    setBaseSettings,
}: {
    toggleModal: () => void;
    setBaseSettings?: (e: TSettings) => void;
}) {
    const navigate = useNavigate();
    const [settings, setSettings] = useState(DEFAULT);

    const applyEnabled = !Object.values(settings)?.reduce((acc, item) => acc || !item, false);

    useEffect(() => {
        cleanUp();
        return () => cleanUp();
    }, []);

    function cleanUp() {
        setSettings(DEFAULT);
    }

    function handleChange(option: Partial<Record<keyof TOptions, string>>) {
        setSettings((settings: Record<keyof TOptions, string>) => {
            return { ...settings, ...option };
        });
    }

    function handleApply() {
        const gender = settings['Sex']?.toLowerCase() as TGender;
        if (setBaseSettings && gender) setBaseSettings({
            gender,
        });
        toggleModal();
        navigate('/character');

    }

    return <>
        <h3 className="modal-title">Create new Character</h3>
        <div className="create-options">
            {Object.keys(OPTIONS).map((option) =>
                <Options
                    key={option}
                    option={option as keyof TOptions}
                    selected={settings[option as keyof TOptions]}
                    handleChange={handleChange}
                />
            )}
        </div>
        <div className="modal-divider"/>
        <div className="modal-navigation">
            <button
                className={`button-blue ${applyEnabled ? '' : 'disabled'}`}
                onClick={handleApply}
                disabled={!applyEnabled}
            >
                Apply
            </button>
        </div>
    </>;
}
