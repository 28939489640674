import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={551}
            height={835}
            viewBox={`0 0 ${551} ${835}`}
            fill="none"
            id="object-kitchen-fridge"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M544.581 823.704c-4.355 6.07-11.472 10.025-19.513 10.025H50.121c-13.255 0-24-10.745-24-24V25.939c0-13.255 10.745-24 24-24h474.947c13.255 0 24 10.745 24 24v782.779"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M123.742 396.396v18.841h18.841v-18.841M212.562 434.077v-18.841H180.73v18.841h31.832ZM236.887 371.387v-90.163c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v317.937h50.706V281.224c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v291.789M192.352 321.366v-25.011h-80.589v25.011h80.589ZM111.762 346.377h80.589"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M549.068 808.719H51.131V26.951h236.463V767.92"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M123.742 396.396H92.814v86.047h119.748v-67.206H180.73v48.512"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M236.887 371.387H1.109v136.069h236.463V396.398h-94.989"
            /> 
        </svg>
    );
}
