import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={248}
            height={164}
            fill="none"
            id="object-food-lentils-green"
            {...attributes}
        >
            <circle cx={32.422} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={55.268} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={78.113} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={100.959} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={123.805} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={146.65} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={169.496} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={192.342} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={215.188} cy={69.916} r={8.75} fill="#7CCC6C" />
            <circle cx={78.113} cy={28.916} r={8.75} fill="#7CCC6C" />
            <circle cx={100.959} cy={28.916} r={8.75} fill="#7CCC6C" />
            <circle cx={123.805} cy={28.916} r={8.75} fill="#7CCC6C" />
            <circle cx={146.65} cy={28.916} r={8.75} fill="#7CCC6C" />
            <circle cx={169.496} cy={28.916} r={8.75} fill="#7CCC6C" />
            <circle cx={44.172} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={66.672} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={89.172} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={111.672} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={134.172} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={156.672} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={179.172} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={201.672} cy={49.416} r={8.75} fill="#7CCC6C" />
            <circle cx={111.672} cy={9.416} r={8.75} fill="#7CCC6C" />
            <circle cx={134.172} cy={9.416} r={8.75} fill="#7CCC6C" />
            <path
                fill="#fff"
                d="M246.387 69.916c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.669-9.368 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.892 37.664H80.263c-15.12-7.029-32.88-20.789-47.892-37.664a159.187 159.187 0 0 1-4.905-5.728 170.406 170.406 0 0 1-8.94-11.91c-3.52-5.156-6.682-10.454-9.367-15.825-3.532-7.005-6.265-14.13-7.936-21.207h245.164Z"
            />
            <path
                fill="url(#object-food-lentils-green-a)"
                d="M246.387 69.916c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335h198.131Z"
            />
            <path
                fill="#00B140"
                d="M238.462 91.123c-2.697 5.37-5.86 10.669-9.368 15.825H18.528c-3.52-5.156-6.683-10.454-9.368-15.825h229.302ZM220.156 118.857H27.466a158.323 158.323 0 0 0 4.906 5.728H215.25l4.905-5.728Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M202.986 137.144c19.87-18.515 37.715-43.21 43.404-67.224H1.227c9.088 38.365 49.204 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
            />
            <defs>
                <linearGradient
                    id="object-food-lentils-green-a"
                    x1={48.256}
                    x2={246.387}
                    y1={116.083}
                    y2={116.083}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
