import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-cycling-2-right">
        <path
            d="M402.215 283.829a253.245 253.245 0 0 0 1.674-28.701c.007-2.87-.004-5.739-.014-8.609-.024-6.558-.049-13.339.14-19.993l.004-.14a32.04 32.04 0 0 0-32.006-32.769 32.193 32.193 0 0 0-31.976 31.233c-.16 6.652-.539 13.418-.905 19.96-.161 2.871-.317 5.74-.466 8.608-.487 9.577-.43 19.174.171 28.745.089 1.637.337 3.9.286 4.849 32.87 5.124 62.814-.665 62.814-.665.093-.837.186-1.67.278-2.518Z"
            fill="url(#man-34-25-tshirt-2-both-arms-option-1-cycling-2-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-both-arms-option-1-cycling-2-right-a"
                x1={423.033}
                y1={325.181}
                x2={331.949}
                y2={194.41}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.348} stopColor="#0047A7" />
                <stop offset={0.9} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
