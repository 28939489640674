import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6777)"
            d="M494.767 223.408a2.699 2.699 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6777)"
            d="M559.936 277.908c-.027-.957-.083-1.917-.139-2.877a247.11 247.11 0 00-.349-5.557s-9.04.437-16.305-2.274c-9.913-3.699-13.312-9.001-13.312-9.001-.764 2.11-1.541 4.191-2.322 6.7a187.637 187.637 0 00-11.02-15.574 823.286 823.286 0 00-4.563-5.793c-3.477-4.395-7.142-8.976-10.512-13.558l-.078-.105a27.634 27.634 0 00-22.097-11.103 27.272 27.272 0 00-16.376 5.426 27.528 27.528 0 00-5.677 38.474c3.387 4.561 6.673 9.325 9.851 13.933 1.399 2.029 2.802 4.054 4.208 6.075a196.945 196.945 0 0015.319 19.263c5.797 6.538 11.33 12.43 16.917 18.015a248.558 248.558 0 0018.513 16.881c.401.324.842.645 1.38 1.006a19.283 19.283 0 0011.523 5.477c.714.079 1.433.118 2.152.118a19.46 19.46 0 0019.36-17.372c.443-4.031 1.059-8.121 1.654-12.076.262-1.74.524-3.48.773-5.224a128.06 128.06 0 001.253-17.608c-.004-.954.004-1.906.012-2.858.015-1.954.031-3.975-.05-5.971l-.115-4.417z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6777"
                x1="567.268"
                x2="482.256"
                y1="349.502"
                y2="224.586"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6777"
                x1="565.774"
                x2="480.762"
                y1="350.519"
                y2="225.603"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
