import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-2-right">
        <path
            d="M406.027 182.333c-2.56-.22-5.122-.423-7.684-.626-5.833-.463-11.864-.941-17.769-1.623l-.15-.017a32.695 32.695 0 0 0-3.553-.196 31.944 31.944 0 0 0-31.769 28.488 31.782 31.782 0 0 0 1.06 12.444 31.776 31.776 0 0 0 5.77 11.076 31.798 31.798 0 0 0 21.462 11.802c5.904.651 11.89 1.531 17.68 2.381 2.542.374 5.084.748 7.626 1.103 1.032.148 2.088.263 3.132.395l4.674-65.179c-.159-.013-.321-.034-.479-.048Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-2-right-a)"
        />
        <path
            d="M351.932 231.879a31.794 31.794 0 0 0 21.462 11.802c5.903.651 11.889 1.531 17.679 2.381 2.543.374 5.085.748 7.626 1.103 1.033.148 2.088.263 3.132.395l4.674-65.179c-.159-.013-.32-.034-.479-.048-2.56-.22-5.121-.423-7.683-.626-5.834-.463-11.866-.941-17.771-1.623l-.15-.017a32.695 32.695 0 0 0-3.553-.196"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-2-right-a"
                x1={432.694}
                y1={219.146}
                x2={341.61}
                y2={206.134}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
