import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-cycling">
        <g clipPath="url(#man-34-25-tshirt-3-cycling-a)">
            <path
                d="M500.517 276.468a194.099 194.099 0 0 0-6.028-44.008h-.005c-.447-1.782-.914-3.54-1.402-5.275-.457-1.627-.932-3.23-1.425-4.808-.029-.093-.055-.19-.084-.282a164.117 164.117 0 0 0-3.35-9.696l-.004-.012c-.042-.11-.08-.226-.122-.335-8.133-17.24-16.178-28.429-19.845-32.192-2.484 2.532-8.368 3.263-11.93 3.235-9.114-.07-17.348-5.413-24.148-11.481-6.684-5.964-13.611-16.065-24.086-20.505-12.275 2.71-24.018 8.721-34.97 17.923-8.52 7.141-17.506 17.659-23.287 25.506-42.08 57.117-56.603 153.034-56.324 206.479 51.831 30.036 131.205 46.734 161.783 25.265 21.937-15.403 28.854-39.11 31.446-58.988 2.292-17.582 2.597-31.976.283-47.452a20.67 20.67 0 0 1 6.087-18.052c5.377-5.141 7.715-13.677 7.411-25.322Z"
                fill="url(#man-34-25-tshirt-3-cycling-b)"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-cycling-b"
                x1={293.503}
                y1={293.548}
                x2={500.543}
                y2={293.548}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <clipPath id="man-34-25-tshirt-3-cycling-a">
                <path fill="#fff" transform="translate(.41 .117)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
