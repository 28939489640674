import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7264)"
            d="M494.773 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7264)"
            d="M510.399 244.055c-2.42-3.874-4.923-7.88-7.207-11.911l-.079-.139a27.59 27.59 0 00-23.872-13.81 27.5 27.5 0 00-13.67 3.658 27.324 27.324 0 00-12.852 16.668 27.317 27.317 0 002.699 20.874c2.305 4.014 4.477 8.205 6.578 12.259.426.821.856 1.638 1.282 2.457 0 0 13.238-12.558 23.647-18.305a140.632 140.632 0 0124.359-10.327c-.296-.474-.589-.95-.885-1.424z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7264"
                x1="488.441"
                x2="438.128"
                y1="213.068"
                y2="256.441"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7264"
                x1="498.455"
                x2="448.142"
                y1="224.688"
                y2="268.062"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
