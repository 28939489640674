import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M582.261 636.685a773.35 773.35 0 01-2.372-25.408c-.325-4.226-.735-8.462-.988-12.681l-.823-12.662c-.577-8.444-1.017-16.873-1.836-25.345-.746-8.464-1.853-16.97-2.243-25.392-1.045-16.877 1.507-33.337 4.65-49.73 1.663-8.185 3.674-16.33 6.17-24.42.596-2.025 1.313-4.037 1.963-6.056.728-2.01 1.408-4.026 2.204-6.029a116.156 116.156 0 015.286-11.955l5.279-10.22a26.331 26.331 0 0131.766-12.888 26.339 26.339 0 0117.591 29.424l-8.452 49.29-4.237 24.643-4.377 24.627c-2.941 16.416-5.784 32.843-8.806 49.249l-8.986 49.227a15.935 15.935 0 01-26.504 8.823 15.924 15.924 0 01-5.005-9.923l-.28-2.574z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M585.647 476.523a205.632 205.632 0 0030.842-8.288l3.313-1.21a32.288 32.288 0 0020.988-26.8 32.282 32.282 0 00-14.69-30.708 251.296 251.296 0 00-27.072-14.738 889.96 889.96 0 00-13.707-6.324c-4.576-2.068-9.153-4.13-13.786-5.847a196.004 196.004 0 00-28.201-7.84c-2.379-.48-4.758-.957-7.154-1.327-2.395-.382-4.779-.827-7.193-1.093l-3.61-.457-3.632-.327c-2.424-.203-4.86-.333-7.318-.325a53.088 53.088 0 00-17.439 103.178l.52.182c2.798.983 5.624 1.797 8.467 2.506h80.097l-.425-.582z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M582.54 639.259l-.279-2.575a773.29 773.29 0 01-2.372-25.407c-.325-4.227-.735-8.463-.988-12.681l-.823-12.662c-.577-8.444-1.017-16.873-1.836-25.346-.746-8.463-1.853-16.97-2.243-25.392-1.045-16.876 1.507-33.337 4.65-49.729 1.663-8.185 3.674-16.331 6.17-24.42M635.035 415.473a26.387 26.387 0 0113.873 27.851l-8.453 49.289-4.237 24.644-4.377 24.627c-2.941 16.416-5.784 32.842-8.805 49.249l-8.987 49.227"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M635.035 415.474c-8.896-5.693-26.895-16.321-36.007-20.695a889.96 889.96 0 00-13.707-6.324c-4.576-2.068-9.153-4.13-13.786-5.847a196.004 196.004 0 00-28.201-7.84c-2.379-.48-4.758-.957-7.154-1.327-2.395-.382-4.779-.827-7.193-1.093l-3.61-.457-3.632-.327c-2.424-.203-4.86-.333-7.318-.325a53.088 53.088 0 00-17.439 103.178l.52.182c2.798.983 5.624 1.797 8.467 2.506h71.139M629.402 433.355h15.484"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M581.571 636.84c-.12 4.338-3.358 20.602-5.543 31.147a8.991 8.991 0 007.505 10.726l20.114 2.955a42.18 42.18 0 0112.371 3.769 69.664 69.664 0 0019.862 6.011c4.055.623 19.114.631 26.335.597a4.152 4.152 0 004.125-3.926 4.143 4.143 0 00-2.174-3.886c-2.957-1.591-8.194-4.372-12.443-6.429-19.074-9.232-37.981-36.238-37.981-36.238"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M581.571 636.84c-.12 4.338-3.358 20.602-5.543 31.147a8.991 8.991 0 007.505 10.726l20.114 2.955a42.18 42.18 0 0112.371 3.769 69.664 69.664 0 0019.862 6.011c4.055.623 19.114.631 26.335.597a4.152 4.152 0 004.125-3.926 4.143 4.143 0 00-2.174-3.886c-2.957-1.591-8.194-4.372-12.443-6.429-19.074-9.232-37.981-36.238-37.981-36.238"
        ></path>
    </g>
);

export default SvgComponent;
