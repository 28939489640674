import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-cycling">
        <g clipPath="url(#man-34-25-tshirt-2-cycling-a)">
            <path
                d="M500.186 276.468a194.042 194.042 0 0 0-6.027-44.008h-.005c-.447-1.782-.914-3.54-1.402-5.275-.457-1.627-.932-3.23-1.425-4.808-.029-.093-.055-.19-.084-.282a164.846 164.846 0 0 0-3.35-9.696l-.004-.012c-.042-.11-.08-.226-.122-.335-8.133-17.24-16.178-28.429-19.845-32.192-2.485 2.532-8.369 3.263-11.93 3.235-9.114-.07-17.348-5.413-24.148-11.481-6.684-5.964-13.611-16.065-24.086-20.505-12.275 2.71-24.019 8.721-34.97 17.923-8.52 7.141-17.506 17.659-23.287 25.506-42.08 57.117-56.603 153.034-56.324 206.479 51.831 30.036 131.204 46.734 161.783 25.265 21.937-15.403 28.854-39.11 31.446-58.988 2.292-17.582 2.597-31.976.283-47.452a20.67 20.67 0 0 1 6.087-18.052c5.377-5.141 7.715-13.677 7.41-25.322Z"
                fill="url(#man-34-25-tshirt-2-cycling-b)"
            />
            <path
                d="M500.195 278.937c-57.818-1.308-105.582-17.987-136.038-32.155a377.203 377.203 0 0 1-34.501-18.665 286.005 286.005 0 0 0-8.536 19.77 339.772 339.772 0 0 0 46.315 26.329c27.612 12.801 70.026 27.754 121.325 30.428a18.581 18.581 0 0 0 5.828-5.176l.064-.087a24.365 24.365 0 0 0 3.133-5.883c.027-.07.055-.139.081-.21a30.973 30.973 0 0 0 .632-1.919c.16-.539.312-1.085.45-1.649l.006-.025c.454-1.91.776-3.848.964-5.802.02-.192.04-.383.058-.578.052-.589.095-1.189.129-1.8.011-.212.02-.429.03-.644a60.46 60.46 0 0 0 .06-1.934Z"
                fill="url(#man-34-25-tshirt-2-cycling-c)"
            />
            <path
                d="M349.5 194.538a194.527 194.527 0 0 0-14.076 22.285c47.802 27.234 90.204 45.467 164.283 49.096l-.001-.012a209.184 209.184 0 0 0-5.547-33.447h-.005a184.406 184.406 0 0 0-2.875-10.243c-1.063-3.446-2.252-6.828-3.513-10.165-8.132-17.24-16.177-28.429-19.845-32.192-2.484 2.532-8.368 3.263-11.93 3.235-9.113-.07-17.347-5.413-24.148-11.481-6.684-5.964-13.61-16.065-24.086-20.505-23.541 4.605-44.582 24.448-58.257 43.429Z"
                fill="url(#man-34-25-tshirt-2-cycling-d)"
            />
            <path
                d="M312.271 273.641a394.414 394.414 0 0 0-3.725 13.148 408.29 408.29 0 0 0 45.2 25.053c29.963 13.938 76.676 30.307 133.232 32.081l.01-.435c.022-.956.039-1.91.044-2.855v-.184c.015-3.253-.064-6.438-.221-9.535-53.926-1.79-98.528-17.389-127.184-30.674a385.461 385.461 0 0 1-47.356-26.599Z"
                fill="url(#man-34-25-tshirt-2-cycling-e)"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-b"
                x1={293.172}
                y1={293.548}
                x2={500.213}
                y2={293.548}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-c"
                x1={652.192}
                y1={268.405}
                x2={339.902}
                y2={265.803}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-d"
                x1={652.677}
                y1={210.172}
                x2={340.387}
                y2={207.57}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-cycling-e"
                x1={651.837}
                y1={310.884}
                x2={339.548}
                y2={308.281}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <clipPath id="man-34-25-tshirt-2-cycling-a">
                <path fill="#fff" transform="translate(.08 .117)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
