import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={804}
            height={306}
            viewBox="0 0 804 306"
            fill="none"
            id="object-meals-3"
            {...attributes}
        >
            <path
                fill="url(#object-meals-3-a)"
                d="M386.404 259.304H107.1c-4.07 0-6.793-4.208-5.125-7.921 23.863-53.14 54.171-63.448 98.355-59.789 99.377-6.782 131.498 51.399 186.074 51.399v16.311Z"
            />
            <mask
                id="object-meals-3-b"
                width={286}
                height={70}
                x={101}
                y={190}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    d="M386.404 259.304H107.1c-4.07 0-6.793-4.208-5.125-7.921 23.863-53.14 54.171-63.448 98.355-59.789 99.377-6.782 131.498 51.399 186.074 51.399v16.311Z"
                />
            </mask>
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity={0.25}
                mask="url(#object-meals-3-b)"
            >
                <path strokeWidth={3} d="M200.33 164.168v122.56" />
                <path
                    strokeWidth={2}
                    d="M159.047 181.863c6.142 9.723 13.098 23.059 17.988 39.81 8.63 29.559 6.575 55.172 4.128 70.171M138.309 194.934c5.973 7.052 12.792 16.787 18.083 29.394 13.108 31.231 8.454 60.099 5.603 72.633M107.299 210.312c6.7 2.5 20.889 8.915 31.106 23.744 20.551 29.825 5.566 65.421 4.423 68.017"
                />
                <path
                    strokeWidth={2}
                    d="M76.287 225.696c2.817-.183 28.126-1.404 44.424 18.093 14.223 17.015 15.636 42.695 2.949 63.401M243.384 180.684c-6.11 9.958-13.439 24.457-17.988 43.054-6.819 27.877-4.109 51.543-1.425 65.271M280.54 195.426c-6.639 3.401-21.353 12.131-30.963 29.784-16.635 30.557-3.8 61.812-1.966 66.077M310.323 203.98c-5.745 2.345-23.894 10.603-35.092 30.963-14.994 27.264-5.527 54.419-3.981 58.625M339.222 214.594c-4.994 1.267-24.359 6.802-37.156 25.95-16.598 24.836-8.3 51.852-7.176 55.302M375.198 227.57c-3.033-.232-27.33-1.677-44.823 17.398-20.472 22.323-12.29 51.665-11.845 53.159"
                />
                <path
                    strokeWidth={2}
                    d="M402.623 221.379a757.751 757.751 0 0 0-79.324 66.939"
                />
            </g>
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M171.529 191.686c8.905-1.039 18.474-.947 28.801-.092 99.377-6.782 131.498 51.399 186.074 51.399v16.312H107.1c-4.07 0-6.793-4.208-5.125-7.921 11.129-24.783 23.66-40.25 38.346-49.329"
            />
            <g
                stroke="#E4002B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={4}
                clipPath="url(#object-meals-3-c)"
            >
                <path
                    d="m594.338 211.911 10.755-10.755M613.959 211.911l10.755-10.755M633.582 211.911l10.755-10.755M653.205 211.911l10.755-10.755M672.826 211.911l10.755-10.755M692.449 211.911l10.755-10.755M712.072 211.911l10.755-10.755M731.693 211.911l10.755-10.755M613.959 195.915l10.755-10.755M633.582 195.915l10.755-10.755M653.205 195.915l10.755-10.755M672.826 195.915l10.755-10.755M692.449 195.915l10.755-10.755M712.072 195.915l10.755-10.755M633.582 179.919l10.755-10.755M653.205 179.919l10.755-10.755M672.826 179.919l10.755-10.755M692.449 179.919l10.755-10.755M653.205 163.923l10.755-10.755M672.826 163.923l10.755-10.755"
                />
            </g>
            <path
                fill="#fff"
                d="M755.808 209.891c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-52.55c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h174.82Z"
            />
            <path
                fill="url(#object-meals-3-d)"
                d="M755.809 209.891c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-21.58c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h143.85Z"
            />
            <path
                fill="#EF0E2E"
                d="M751.288 225.242c-1.78 4.83-4.12 10.18-7.19 15.73h-151.4c-3.07-5.55-5.41-10.9-7.19-15.73h165.78Z"
            />
            <path
                fill="#64CCC9"
                d="M740.518 246.969c-1.73 2.71-3.65 5.43-5.77 8.14h-132.71a107.07 107.07 0 0 1-5.77-8.14h144.25Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M722.073 268.624c23.592-20.994 31.168-47.195 33.734-58.729H580.986c2.565 11.534 10.142 37.735 33.734 58.729 9.672 8.607 19.484 14.069 27.403 17.566h52.547a106.408 106.408 0 0 0 15.546-8.464"
            />
            <path
                fill="#5BC2E7"
                d="M32.431 1.652h33.23a2.561 2.561 0 0 1 2.56 2.56v14.552h-38.35V4.212a2.561 2.561 0 0 1 2.56-2.56Z"
            />
            <path
                fill="url(#object-meals-3-e)"
                d="M14.572 49.305A59.664 59.664 0 0 0 1.787 86.204v59.267c0 1.579.59 3.104 1.66 4.269 7.912 8.615 7.912 21.941 0 30.557a6.311 6.311 0 0 0-1.66 4.269v82.009c0 8.802 7.135 15.937 15.937 15.937h62.642c8.802 0 15.937-7.135 15.937-15.937v-82.009c0-1.579-.59-3.104-1.659-4.269-7.912-8.614-7.912-21.941 0-30.557a6.31 6.31 0 0 0 1.66-4.269V86.204a59.664 59.664 0 0 0-12.786-36.9H14.572Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M32.431 1.652h33.23a2.561 2.561 0 0 1 2.56 2.56v14.552h-38.35V4.212a2.561 2.561 0 0 1 2.56-2.56Z"
            />
            <path fill="#5BC2E7" d="M96.304 86.203H1.787v49.624h94.517V86.203Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M23.04 258.629h52.012M23.04 238.098h52.012M23.04 217.562h52.012M23.04 197.031h52.012M96.307 212.233v-27.67c0-1.579-.59-3.104-1.66-4.269-7.913-8.615-7.913-21.941 0-30.556a6.31 6.31 0 0 0 1.66-4.269V86.202A59.673 59.673 0 0 0 79.502 44.69L66.228 30.984a6.373 6.373 0 0 1-1.796-4.436v-7.786H33.664v7.786a6.375 6.375 0 0 1-1.796 4.436l-4.284 4.424M14.567 49.3a59.676 59.676 0 0 0-12.78 36.905v59.267c0 1.58.59 3.104 1.66 4.269 7.912 8.615 7.912 21.942 0 30.556a6.31 6.31 0 0 0-1.66 4.269v82.009c0 8.802 7.135 15.937 15.937 15.937h62.644c8.801 0 15.936-7.135 15.936-15.937v-21.354"
            />
            <path
                fill="url(#object-meals-3-f)"
                d="M490.476 257.488h-15.743a149.248 149.248 0 0 1-77.891-21.937H524.98a38.104 38.104 0 0 1-34.505 21.937h.001Z"
            />
            <path
                fill="url(#object-meals-3-g)"
                d="M490.133 221.586h-10.021a95.009 95.009 0 0 0-49.583 13.965h81.569a24.256 24.256 0 0 0-21.965-13.965Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M431.334 235.551h93.647a38.104 38.104 0 0 1-34.505 21.937h-15.743a149.248 149.248 0 0 1-77.891-21.937h11.375"
            />
            <path
                fill="url(#object-meals-3-h)"
                d="M400.476 218.488h-15.743a149.248 149.248 0 0 1-77.891-21.937H434.98a38.104 38.104 0 0 1-34.505 21.937h.001Z"
            />
            <path
                fill="url(#object-meals-3-i)"
                d="M400.133 182.586h-10.021a95.009 95.009 0 0 0-49.583 13.965h81.569a24.256 24.256 0 0 0-21.965-13.965Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M341.334 196.551h93.647a38.104 38.104 0 0 1-34.505 21.937h-15.743a149.248 149.248 0 0 1-77.891-21.937h11.375"
            />
            <g clipPath="url(#object-meals-3-j)">
                <path
                    fill="url(#object-meals-3-k)"
                    d="M431.467 258.793H48.91a91.645 91.645 0 0 0 65.676 27.747h482.403a91.644 91.644 0 0 0 65.676-27.747H431.467Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M565.462 286.54h31.529a91.644 91.644 0 0 0 65.676-27.747H48.91a91.645 91.645 0 0 0 65.676 27.747h398.815"
                />
            </g>
            <path
                fill="#009CDE"
                d="M256.151 187.692c7.874 0 14.258-6.384 14.258-14.258 0-7.875-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m252.613 162.84 7.074 7.074M259.687 162.84l-7.074 7.074"
            />
            <path
                fill="#009CDE"
                d="M220.869 176.684c7.875 0 14.258-6.384 14.258-14.258 0-7.874-6.383-14.258-14.258-14.258-7.874 0-14.258 6.384-14.258 14.258 0 7.874 6.384 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m217.332 151.832 7.074 7.074M224.406 151.832l-7.074 7.074"
            />
            <path
                fill="#fff"
                d="M802.616 273.662v15.956c0 8.003-6.489 14.491-14.492 14.491h-61.628c-8.003 0-14.492-6.488-14.492-14.491V137.301h90.612v118.286"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M802.616 273.662v15.956c0 8.003-6.489 14.491-14.492 14.491h-61.628c-8.003 0-14.492-6.488-14.492-14.491V137.301h90.612v118.286"
            />
            <path
                fill="#C0F0FF"
                d="M789.938 173.211v116.406c0 1-.812 1.811-1.812 1.811h-61.628c-1 0-1.812-.811-1.812-1.811V173.211h65.252Z"
            />
            <defs>
                <linearGradient
                    id="object-meals-3-a"
                    x1={112.445}
                    x2={363.813}
                    y1={225.117}
                    y2={225.117}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FB8735" />
                    <stop offset={0.63} stopColor="#F76430" />
                    <stop offset={1} stopColor="#F64E2E" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-d"
                    x1={611.959}
                    x2={755.809}
                    y1={248.041}
                    y2={248.041}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-e"
                    x1={143.403}
                    x2={12.432}
                    y1={429.728}
                    y2={72.712}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-f"
                    x1={396.843}
                    x2={524.981}
                    y1={246.52}
                    y2={246.52}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-g"
                    x1={440.789}
                    x2={555.61}
                    y1={218.793}
                    y2={269.572}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.127} stopColor="#FFEB94" />
                    <stop offset={0.269} stopColor="#FFE367" />
                    <stop offset={0.412} stopColor="#FFDC42" />
                    <stop offset={0.555} stopColor="#FFD725" />
                    <stop offset={0.696} stopColor="#FFD310" />
                    <stop offset={0.835} stopColor="#FFD104" />
                    <stop offset={0.969} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-h"
                    x1={306.843}
                    x2={434.981}
                    y1={207.52}
                    y2={207.52}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-i"
                    x1={350.789}
                    x2={465.61}
                    y1={179.793}
                    y2={230.572}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.127} stopColor="#FFEB94" />
                    <stop offset={0.269} stopColor="#FFE367" />
                    <stop offset={0.412} stopColor="#FFDC42" />
                    <stop offset={0.555} stopColor="#FFD725" />
                    <stop offset={0.696} stopColor="#FFD310" />
                    <stop offset={0.835} stopColor="#FFD104" />
                    <stop offset={0.969} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-3-k"
                    x1={48.91}
                    x2={662.665}
                    y1={272.667}
                    y2={272.667}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <clipPath id="object-meals-3-c">
                    <path fill="#fff" d="M592.338 151.168h152.111v62.744H592.338z" />
                </clipPath>
                <clipPath id="object-meals-3-j">
                    <path fill="#fff" d="M47.287 257.168h617v31h-617z" />
                </clipPath>
            </defs>
        </svg>
    );
}
