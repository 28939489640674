import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6797)"
            d="M574.751 555.742c-.158-11.596-.452-32.254-.603-44.102-.313-16.688-1.164-60.164-1.503-77.477-.245-12.186-9.457-22.938-20.092-28.873-11.834-6.605-24.396-13.56-33.239-17.64a166.586 166.586 0 00-41.601-13.245 291.617 291.617 0 00-37.908-5.247c-6.508-.489-14.864-.219-21.372-.013-16.929.305-37.994 6.049-49.325 19.981-16.443 20.219-8.522 56.635 4.263 72.043a27.894 27.894 0 006.18 5.406 46.73 46.73 0 0030.674 11.431h99.754c-.949 21.803-.307 44.855 4.474 63.937 3.625 14.722 17.504 80.769 19.239 90.094h45.47c-.211-23-4.088-57.453-4.411-76.295z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6797"
                x1="577.943"
                x2="349.924"
                y1="470.31"
                y2="550.941"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
