import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6530)"
            d="M403.395 352.069a22.335 22.335 0 01-16.272-6.77c-1.854-1.932-3.188-4.837-4.475-7.136a188.033 188.033 0 01-9.204-19.796c-2.565-6.375-4.944-12.968-7.273-20.158a147.626 147.626 0 01-5.303-20.647c-.381-2.09-.746-4.186-1.112-6.283-.831-4.768-1.691-9.699-2.73-14.497a27.323 27.323 0 013.755-20.711 27.315 27.315 0 0117.299-11.989 27.703 27.703 0 015.848-.629 27.646 27.646 0 0126.851 21.683l.031.142c1.014 4.809 2.244 9.659 3.434 14.349a779.22 779.22 0 011.555 6.197 147.358 147.358 0 013.603 21.016c.775 7.154 1.287 13.837 1.562 20.384a225.712 225.712 0 0122.322-5.166c1.252-.218 2.497-.446 3.742-.674 2.546-.465 5.179-.947 7.802-1.328l5.77-.905c1.256-.194 2.52-.36 3.782-.525.682-.09 1.363-.181 2.045-.273 5.832-.768 10.602-1.297 15.018-1.667l1.4-.119-.347 1.36c-2.396 9.424 1.625 20.556 6.726 25.466l1.051 1.013-1.323.615c-4.01 1.864-8.411 3.768-13.848 5.992-.646.259-1.285.52-1.922.78-1.178.482-2.355.963-3.54 1.417l-5.471 2.081c-2.454.965-4.976 1.852-7.415 2.709-1.196.42-2.394.841-3.588 1.273a225.473 225.473 0 01-22.419 6.699l-11.334 2.687-11.279 2.823c-1.55.388-3.142.586-4.74.587h-.001z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6530"
                x1="469.155"
                x2="338.384"
                y1="346.545"
                y2="243.75"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
