import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6507)"
            d="M365.401 271.954c-.672-1.383-1.328-2.777-1.984-4.17-1.435-3.048-2.919-6.2-4.554-9.187a27.502 27.502 0 0148.244-26.412l.154.286c1.6 3.006 3.422 5.976 5.183 8.849-8.363-4.08-43.495.8-47.043 30.634z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6507"
                x1="365.05"
                x2="382.616"
                y1="225.782"
                y2="247.252"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
