import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6602)"
            d="M411.503 238.5l-.022-.135a27.418 27.418 0 00-27.077-22.881 27.524 27.524 0 00-27.141 32.128c.92 5.392 1.656 10.918 2.367 16.262.173 1.292.35 2.582.525 3.873 0 0 7.923-11.686 25.923-18.084 19.876-7.064 27.833 1.673 27.833 1.673-.855-4.239-1.7-8.556-2.408-12.836z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6602"
                x1="356.869"
                x2="420.122"
                y1="241.616"
                y2="241.616"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
