import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={240}
            height={25}
            fill="none"
            id="object-food-green-onion"
            {...attributes}
        >
            <path
                fill="#00B140"
                d="M.447 14.816h194.021v8.23H8.676a8.233 8.233 0 0 1-8.229-8.23Z"
            />
            <path
                fill="#7CCC6C"
                d="M62.848 6.588h131.621v8.229H71.077a8.233 8.233 0 0 1-8.23-8.23Z"
            />
            <path
                fill="url(#object-food-green-onion-a)"
                d="M227.88 23.045h-33.411V6.587l30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006l.001-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m194.468 6.587 30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006h-33.411v-8.229M194.47 23.047H47.185"
            />
            <defs>
                <linearGradient
                    id="object-food-green-onion-a"
                    x1={147.839}
                    x2={234.518}
                    y1={40.208}
                    y2={6.024}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
