import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-1-left-cycling">
        <path
            d="M501.534 201.922a131.614 131.614 0 0 0-35.07-32.516l6.968 50.433s31.457-13.429 28.102-17.917Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-left-cycling-a)"
        />
        <path
            d="M505.009 205.348c-6.722-8.346-15.923-13.434-26.653-13.434a31.853 31.853 0 0 0-17.743 5.392 32.038 32.038 0 0 0-8.84 44.384c3.242 4.854 6.363 9.917 9.382 14.813 1.325 2.15 2.654 4.295 3.987 6.437a195.369 195.369 0 0 0 14.742 20.524 475.238 475.238 0 0 0 2.727 3.349l54.061-30.935c-5.639-12.687-21.999-38.532-31.663-50.53Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-left-cycling-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-left-cycling-a"
                x1={489.441}
                y1={168.374}
                x2={469.2}
                y2={236.037}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-left-cycling-b"
                x1={446.394}
                y1={239.363}
                x2={563.459}
                y2={239.363}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
