import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-1-stand-1">
            <path
                fill="#EF0025"
                d="M534.611 860.018c-.099 0-.199-.003-.298-.009-8.149-.467-22.414-1.38-26.404-2.248a70.79 70.79 0 0 1-19.731-7.339 41.136 41.136 0 0 0-11.826-4.42l-19.892-4.196a9.912 9.912 0 0 1-6.472-4.573 9.904 9.904 0 0 1-1.113-7.838c1.192-4.365 2.334-8.644 3.303-12.374a1 1 0 0 1 1.144-.732 65.396 65.396 0 0 1 11.527 3.582c.653.247 1.305.495 1.956.738 5.891 2.197 12.155 4.014 18.034 2.433a32.942 32.942 0 0 0 6.509-2.804c1.052-.543 2.139-1.104 3.241-1.605a24.299 24.299 0 0 1 3.153-1.18 1.003 1.003 0 0 1 1.079.352c6.454 8.46 16.282 19.746 26.45 25.472 4.183 2.355 9.35 5.528 12.058 7.21a5.163 5.163 0 0 1 2.396 4.98 5.16 5.16 0 0 1-5.114 4.551Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M538.151 858.603a5.143 5.143 0 0 1-3.838 1.407c-8.148-.467-22.414-1.38-26.403-2.248a70.797 70.797 0 0 1-19.732-7.339 41.136 41.136 0 0 0-11.826-4.42l-19.891-4.196a9.91 9.91 0 0 1-6.473-4.573"
            />
            <path
                fill="url(#man-34-25-shoes-1-stand-1-a)"
                d="M462.716 860.226h-57.648a9.907 9.907 0 0 1-7.277-3.138 9.886 9.886 0 0 1-2.705-7.44c.192-3.288.403-7.12.591-10.98a.995.995 0 0 1 .794-.93.983.983 0 0 1 .435.005 15.028 15.028 0 0 0 12.594-2.603 30.363 30.363 0 0 0 3.719-3.647 40.083 40.083 0 0 1 3.132-3.187 21.124 21.124 0 0 1 18.021-4.47 1 1 0 0 1 .605.396c4.312 6.008 10.492 13.871 15.447 17.207 3.659 2.464 10.887 6.412 14.871 8.546a5.43 5.43 0 0 1 2.867 4.797 5.45 5.45 0 0 1-5.446 5.444Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.922 858.236a5.425 5.425 0 0 1-1.88 1.469 5.42 5.42 0 0 1-2.328.523h-57.648a9.906 9.906 0 0 1-7.277-3.138"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-1-stand-1-a"
                    x1="395.068"
                    x2="468.161"
                    y1="841.802"
                    y2="841.802"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset=".37" stopColor="#F86431" />
                    <stop offset="1" stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
