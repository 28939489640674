import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-3">
        <path
            d="m465.357 167.027 2.654.952c13.051 4.677 23.788 10.342 32.825 17.319a2.67 2.67 0 0 1 1.092 2.341c-.25 4.648-12.214 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-3-a)"
        />
        <path
            d="M569.477 277.739a23.826 23.826 0 0 1-6.198-.826 24.802 24.802 0 0 1-3.866-.357 216.196 216.196 0 0 1-23.436-5.903c-7.274-2.228-14.621-4.761-22.465-7.746a170.958 170.958 0 0 1-21.56-9.703 897.18 897.18 0 0 1-6.238-3.374c-4.719-2.567-9.599-5.222-14.484-7.638a32.001 32.001 0 1 1 28.373-57.368l.151.076c4.876 2.444 9.937 4.738 14.832 6.958 2.152.975 4.302 1.956 6.449 2.942a171.039 171.039 0 0 1 20.74 11.359 382.584 382.584 0 0 1 9.975 6.48c1.196-5.956 2.267-10.876 3.345-15.385a296.94 296.94 0 0 1 2.762-10.909l.949-3.47.977 3.463c2.816 9.975 14.997 15.357 25.03 15.357 2.678.122 5.344-.44 7.746-1.632l1.425-.843.082 1.654c.266 5.372.416 11.029.486 18.339.028 1.623.033 3.126.014 4.588a144.548 144.548 0 0 1-.067 4.578c-.026 1.982-.14 3.999-.25 5.949-.059 1.05-.119 2.099-.165 3.149l-.612 9.094-.547 9.069a23.53 23.53 0 0 1-23.446 22.099h-.002Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-3-b)"
        />
        <path
            d="M471.23 242.191c4.886 2.417 9.766 5.071 14.484 7.639 2.078 1.13 4.156 2.26 6.239 3.374a170.81 170.81 0 0 0 21.56 9.702c7.843 2.986 15.191 5.519 22.464 7.746a215.964 215.964 0 0 0 23.436 5.903c1.278.22 2.571.34 3.867.357a23.647 23.647 0 0 0 6.198.826 23.53 23.53 0 0 0 23.448-22.099l.547-9.069.611-9.093c.047-1.05.106-2.1.165-3.15.11-1.95.225-3.967.25-5.948.054-1.564.064-3.13.068-4.579.018-1.462.013-2.965-.014-4.588-.07-7.31-.22-12.966-.486-18.338l-.082-1.655-1.426.843a15.804 15.804 0 0 1-7.746 1.632c-10.032 0-22.213-5.382-25.029-15.357l-.977-3.463-.95 3.47a296.944 296.944 0 0 0-2.761 10.909c-1.079 4.509-2.149 9.43-3.346 15.386a380.92 380.92 0 0 0-9.974-6.481 171.176 171.176 0 0 0-20.74-11.359 959.126 959.126 0 0 0-6.449-2.942c-4.445-2.015-9.021-4.099-13.476-6.293a3.262 3.262 0 0 0-.274-.265 107.374 107.374 0 0 0-20.926-12.487"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m547.848 239.036 1.071-9.027c.426-3.003.746-6.016 1.266-9.01.229-1.5.497-2.996.773-4.492.262-1.497.547-2.991.855-4.484"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-3-a"
                x1={534.835}
                y1={339.133}
                x2={472.377}
                y2={141.35}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-3-b"
                x1={562.128}
                y1={330.516}
                x2={499.67}
                y2={132.733}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
