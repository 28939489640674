import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-7">
        <path
            d="M499.891 186.165c-9.226-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-7-a)"
        />
        <path
            d="M538.204 246.392c-2.2-5.805-4.69-11.617-7.608-17.758a112.48 112.48 0 0 0-9.48-16.688c-1.11-1.621-2.236-3.232-3.362-4.843-2.46-3.518-5.003-7.156-7.318-10.843l-.147-.233a31.886 31.886 0 0 0-26.983-14.753 31.893 31.893 0 0 0-17.221 5.051 31.785 31.785 0 0 0-14.006 20.092 31.792 31.792 0 0 0 4.303 24.11c2.345 3.663 4.549 7.515 6.681 11.24.977 1.707 1.954 3.414 2.944 5.106a112.455 112.455 0 0 0 11.042 15.69c4.317 5.27 8.51 10.002 12.823 14.47 2.364 2.429 5.249 5.32 8.532 8.274 7.052-17.113 24.402-33.053 39.8-38.915Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-7-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-7-a"
                x1={504.599}
                y1={135.901}
                x2={422.189}
                y2={306.358}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-7-b"
                x1={532}
                y1={149.149}
                x2={449.59}
                y2={319.606}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
