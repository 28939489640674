import { ILegs } from 'interfaces';

import FShoes1Stand1 from './female-34-25-shoes-1-stand-1';
import FShoes1Stand2 from './female-34-25-shoes-1-stand-2';
import FShoes1Walk1 from './female-34-25-shoes-1-walk-1';
import FShoes1Walk2 from './female-34-25-shoes-1-walk-2';
import FShoes1SittingRight from './female-34-25-shoes-1-sitting-right';
import FShoes1SittingLeft from './female-34-25-shoes-1-sitting-left';

const shoes1: ILegs = {
    name: 'Option #1',
    stand1: [FShoes1Stand1],
    stand2: [FShoes1Stand2],
    walk1: [FShoes1Walk1],
    walk2: [FShoes1Walk2],
    sitting1: [FShoes1SittingRight, FShoes1SittingLeft],
};

export default shoes1;
