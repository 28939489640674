import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6605)"
            d="M465.574 317.031s-9.232-1.054-13.783-1.364c-.684-.042-1.368-.089-2.052-.134-1.254-.085-2.508-.171-3.763-.228l-5.778-.248c-2.626-.144-5.28-.189-7.846-.233-1.25-.022-2.5-.043-3.755-.076a225.15 225.15 0 00-4.72-.05c-5.51 0-10.888.39-16.349.801 1.323-6.912 2.349-13.965 3.334-21.66a173.599 173.599 0 001.682-23.05c.007-2.28-.001-4.56-.01-6.84-.02-5.196-.041-10.568.14-15.837l.004-.141a26.921 26.921 0 00-7.651-19.438 27.33 27.33 0 00-20.017-8.626 27.355 27.355 0 00-27.473 26.703c-.153 5.262-.511 10.616-.858 15.793-.153 2.282-.306 4.564-.442 6.842a169.57 169.57 0 00.147 22.844c.52 8.09 1.233 15.563 2.178 22.847.814 7.6 2.065 15.146 3.749 22.602a31.465 31.465 0 003.334 8.268c3.92 6.397 9.588 10.618 18.299 10.733h.001c.313 0 .627-.007.941-.022l11.503-.548 11.528-.406a221.272 221.272 0 0023.072-2.159c1.237-.187 2.474-.363 3.711-.538 2.542-.362 5.17-.736 7.747-1.201l5.717-.965c1.236-.21 2.47-.451 3.705-.691a331.82 331.82 0 012.024-.389 360.56 360.56 0 009.9-2.064l1.133-.253-.417-1.082c-.023-.061-2.32-6.074-2.32-10.893a48.78 48.78 0 012.85-17.002l.535-1.295z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6605"
                x1="442.679"
                x2="348.125"
                y1="367.985"
                y2="234.395"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
