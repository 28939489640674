import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7288)"
            d="M494.769 223.298a2.712 2.712 0 01.821 2.483c-.805 4.666-13.807 10.892-16.406 12.093l-.912.421s-.585-24.988-5.356-31.35a108.25 108.25 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7288)"
            d="M506.802 229.705c-4.633-2.393-9.425-4.866-14.031-7.469l-.123-.07a27.516 27.516 0 00-13.383-3.494 27.55 27.55 0 00-24.035 14.099 27.53 27.53 0 0010.604 37.417c4.122 2.301 8.13 4.723 12.216 7.21 0 0 6.215-29.066 28.752-47.693z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7288"
                x1="493.985"
                x2="419.382"
                y1="211.878"
                y2="256.987"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7288"
                x1="504.862"
                x2="430.26"
                y1="229.87"
                y2="274.978"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
