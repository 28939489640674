import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11325_7997)"
            d="M527.854 394.384l-.002-.142H355.829c-7.296 16.332-10.194 33.084-10.194 44.469 0 34.798 30.284 54.278 46.042 56.302 27.019 0 11.639-.108 35.712-.108 92.241-13.627 126.361-68.567 188.819-65.53-5.639-3.904-12.044-8.489-28.554-15.687-23.875-10.408-45.153-16.418-59.8-19.304z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11325_7997"
                x1="297.551"
                x2="644.497"
                y1="454.503"
                y2="417.878"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
