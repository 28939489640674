import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={288}
            height={71}
            fill="none"
            id="object-food-salmon"
            {...attributes}
        >
            <path
                fill="url(#object-food-salmon-a)"
                d="M286.117 69.636H6.813c-4.07 0-6.793-4.208-5.125-7.92 23.863-53.14 54.17-63.449 98.355-59.79 99.377-6.782 131.498 51.4 186.074 51.4v16.31Z"
            />
            <mask
                id="object-food-salmon-b"
                width={286}
                height={69}
                x={1}
                y={1}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    d="M286.117 69.636H6.813c-4.07 0-6.793-4.208-5.125-7.92 23.863-53.14 54.17-63.449 98.355-59.79 99.377-6.782 131.498 51.4 186.074 51.4v16.31Z"
                />
            </mask>
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity={0.25}
                mask="url(#object-food-salmon-b)"
            >
                <path strokeWidth={3} d="M100.043-25.5V97.06" />
                <path
                    strokeWidth={2}
                    d="M58.76-7.807c6.142 9.723 13.098 23.06 17.988 39.81 8.63 29.56 6.575 55.172 4.128 70.171M38.022 5.264c5.973 7.052 12.791 16.787 18.083 29.394 13.108 31.23 8.453 60.099 5.603 72.633M7.012 20.646c6.7 2.5 20.889 8.915 31.106 23.743 20.55 29.825 5.566 65.421 4.423 68.017"
                />
                <path
                    strokeWidth={2}
                    d="M-24 36.028c2.817-.183 28.126-1.404 44.424 18.093 14.223 17.015 15.636 42.695 2.949 63.401M143.096-8.984c-6.11 9.958-13.439 24.457-17.988 43.054-6.819 27.877-4.109 51.543-1.425 65.27M180.253 5.76c-6.639 3.4-21.353 12.13-30.963 29.784-16.635 30.557-3.8 61.812-1.966 66.077M210.036 14.31c-5.745 2.345-23.894 10.603-35.092 30.963-14.994 27.264-5.527 54.42-3.981 58.626M238.935 24.928c-4.994 1.267-24.359 6.802-37.156 25.95-16.598 24.836-8.3 51.852-7.176 55.302M274.911 37.902c-3.033-.232-27.33-1.677-44.823 17.398-20.472 22.323-12.29 51.665-11.845 53.159"
                />
                <path
                    strokeWidth={2}
                    d="M302.336 31.709a757.751 757.751 0 0 0-79.324 66.939"
                />
            </g>
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M71.242 2.018c8.905-1.039 18.474-.947 28.801-.092 99.377-6.782 131.498 51.399 186.074 51.399v16.312H6.813c-4.07 0-6.793-4.208-5.125-7.921 11.129-24.783 23.66-40.25 38.346-49.329"
            />
            <defs>
                <linearGradient
                    id="object-food-salmon-a"
                    x1={12.158}
                    x2={263.526}
                    y1={35.449}
                    y2={35.449}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FB8735" />
                    <stop offset={0.63} stopColor="#F76430" />
                    <stop offset={1} stopColor="#F64E2E" />
                </linearGradient>
            </defs>
        </svg>
    );
}
