import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6538)"
            d="M386.164 219.824a31.546 31.546 0 00-.813-.011 27.355 27.355 0 00-27.473 26.703c-.152 5.23-.507 10.525-.82 15.199l-.074 1.095a130.315 130.315 0 0126.167-3.949c13.719-.672 29.552 2.476 29.552 2.476l-.002-.975c-.007-3.823.007-7.935.15-12.112l.004-.14a27.534 27.534 0 00-26.691-28.286z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6538"
                x1="389.832"
                x2="384.627"
                y1="211.509"
                y2="259.654"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
