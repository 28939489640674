import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-5">
        <path
            d="m465.749 167.117 2.654.951c13.052 4.678 23.789 10.343 32.826 17.32a2.671 2.671 0 0 1 1.091 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-jacket-left-option-5-a)"
        />
        <path
            d="M565.693 334.9c-2.411 0-7.903-.658-12.747-1.238-3.5-.42-6.807-.816-8.395-.899a63.444 63.444 0 0 1-2.94-.183 60.133 60.133 0 0 1-2.922-.266c-1.256-.11-2.53-.31-3.762-.504-.656-.103-1.311-.207-1.97-.297-1.201-.19-2.376-.401-3.552-.613-.718-.13-1.437-.259-2.156-.383l-5.147-.839a24.16 24.16 0 0 1-18.925-15.637c-4.185-5.3-8.332-11.115-13.428-18.83-4.578-6.977-9.03-14.258-13.613-22.261a206.592 206.592 0 0 1-11.678-23.21 884.218 884.218 0 0 1-3.038-7.187c-2.289-5.45-4.656-11.086-7.174-16.532a32.012 32.012 0 0 1 5.475-35.186 31.989 31.989 0 0 1 22.266-10.294 31.995 31.995 0 0 1 30.351 18.622l.063.137c2.495 5.465 5.23 10.936 7.875 16.226 1.162 2.324 2.319 4.651 3.471 6.98a206.535 206.535 0 0 1 10.002 23.986c3.082 8.677 5.705 16.798 8.018 24.82 1.76 6.175 3.104 11.353 4.216 16.235.713.205 1.497.442 2.266.713l.988.302c14.341 4.387 22.699 7.116 24.842 8.112-17.205 4.067-16.699 31.465-8.275 38.226h-.111Z"
            fill="url(#man-34-25-jacket-left-option-5-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-5-a"
                x1={457.866}
                y1={201.821}
                x2={511.322}
                y2={154.385}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-5-b"
                x1={451.294}
                y1={257.742}
                x2={574.078}
                y2={257.742}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
