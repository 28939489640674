import { ILegs } from 'interfaces';

import cycling1Right from './man-34-25-pants-4-cycling-1-right';
import cycling1Left from './man-34-25-pants-4-cycling-1-left';
import cycling2Right from './man-34-25-pants-4-cycling-2-right';
import cycling2Left from './man-34-25-pants-4-cycling-2-left';

const pants4: ILegs = {
    name: 'Pants #4',
    cycling1: [cycling1Right, cycling1Left],
    cycling2: [cycling2Right, cycling2Left],
};

export default pants4;
