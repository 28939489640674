import { IBodyClothes } from 'interfaces';
import Shirt2 from './female-34-25-shirt-2';
import Shirt2Sitting from './female-34-25-shirt-2-sitting';

import Shirt2ArmRightOpt1 from './female-34-25-shirt-2-arm-opt-1-right';
import Shirt2ArmLeftOpt1 from './female-34-25-shirt-2-arm-opt-1-left';
import Shirt2ArmRightOpt2 from './female-34-25-shirt-2-arm-opt-2-right';
import Shirt2ArmLeftOpt2 from './female-34-25-shirt-2-arm-opt-2-left';
import Shirt2ArmRightOpt3 from './female-34-25-shirt-2-arm-opt-3-right';
import Shirt2ArmLeftOpt3 from './female-34-25-shirt-2-arm-opt-3-left';
import Shirt2ArmRightOpt4 from './female-34-25-shirt-2-arm-opt-4-right';
import Shirt2ArmLeftOpt4 from './female-34-25-shirt-2-arm-opt-4-left';
import Shirt2ArmRightOpt5 from './female-34-25-shirt-2-arm-opt-5-right';
import Shirt2ArmLeftOpt5 from './female-34-25-shirt-2-arm-opt-5-left';
import Shirt2ArmRightOpt6 from './female-34-25-shirt-2-arm-opt-6-right';
import Shirt2ArmLeftOpt6 from './female-34-25-shirt-2-arm-opt-6-left';
import Shirt2ArmRightOpt7 from './female-34-25-shirt-2-arm-opt-7-right';
import Shirt2ArmLeftOpt7 from './female-34-25-shirt-2-arm-opt-7-left';
import Shirt2ArmRightOpt8 from './female-34-25-shirt-2-arm-opt-8-right';
import Shirt2ArmLeftOpt8 from './female-34-25-shirt-2-arm-opt-8-left';
import Shirt2ArmRightOpt9 from './female-34-25-shirt-2-arm-opt-9-right';
import Shirt2ArmLeftOpt9 from './female-34-25-shirt-2-arm-opt-9-left';
import Shirt2ArmLeftOpt10 from './female-34-25-shirt-2-arm-opt-10-left';

import ArmsLeftOpt1 from './female-34-25-shirt-2-arms-opt-1-left';
import ArmsRightOpt1 from './female-34-25-shirt-2-arms-opt-1-right';
import ArmsLeftOpt2 from './female-34-25-shirt-2-arms-opt-2-left';
import ArmsRightOpt2 from './female-34-25-shirt-2-arms-opt-2-right';

const rightArm = {
    option1: Shirt2ArmRightOpt1,
    option2: Shirt2ArmRightOpt2,
    option3: Shirt2ArmRightOpt3,
    option4: Shirt2ArmRightOpt4,
    option5: Shirt2ArmRightOpt5,
    option6: Shirt2ArmRightOpt6,
    option7: Shirt2ArmRightOpt7,
    option8: Shirt2ArmRightOpt8,
    option9: Shirt2ArmRightOpt9,
};
const leftArm = {
    option1: Shirt2ArmLeftOpt1,
    option2: Shirt2ArmLeftOpt2,
    option3: Shirt2ArmLeftOpt3,
    option4: Shirt2ArmLeftOpt4,
    option5: Shirt2ArmLeftOpt5,
    option6: Shirt2ArmLeftOpt6,
    option7: Shirt2ArmLeftOpt7,
    option8: Shirt2ArmLeftOpt8,
    option9: Shirt2ArmLeftOpt9,
    option10: Shirt2ArmLeftOpt10,
};

const shirt2: IBodyClothes = {
    text: 'Shirt #2',
    normal: {
        front: Shirt2,
        rightArm,
        leftArm,
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
    sitting: {
        front: Shirt2Sitting,
        rightArm,
        leftArm,
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
};

export default shirt2;
