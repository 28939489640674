import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 827,
        height: 371,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M158.986 225.316a63.202 63.202 0 0 1 53.41 37.35l47.65 107.08h21.6l-48.53-170.82M31.717 183.629v186.118h21.6M789.397 1.977c14.46 0 25.08 13.56 21.63 27.6l-34.12 138.61a59.997 59.997 0 0 0 .54 30.74l48.54 170.82h-21.6l-47.66-107.08a63.175 63.175 0 0 0-53.4-37.35l-79.36-5.44c-9.42-.64-17.56 6.51-18.13 15.93l-8.19 133.94h-21.6v-186.12h149.795"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m158.986 132.576 42.255-101.853a46.373 46.373 0 0 1 42.893-28.746h545.263M693.914 70.008h-409.73M708.122 35.988h-409.97M541.167 183.629H64.688M31.717 183.627v-25.9h694.12v25.9M53.316 369.749l11.367-186.12M158.987 225.316H1.039"
                />
            </>
        ),
    },
    'Color #1': {
        width: 796,
        height: 370,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M165.685 166.893 230.656 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM147.385 225.319l-79.36-5.439a16.937 16.937 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM20.113 183.653v-.024H1.27v186.119h18.841V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M238.45 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.182-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M701.179 166.893 766.15 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM682.881 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM555.607 183.653v-.024h-18.841v186.119h18.841V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M773.945 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h-.001Z"
                />
                <path
                    fill="url(#object-outdoor-bench-1-a)"
                    d="M717.018 104.022h-545.01l42.61-102.045h544.337l-41.937 102.045Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M248.449 1.977h510.506l-41.937 102.045h-545.01l42.61-102.045M663.47 70.008H253.74M677.681 35.988h-409.97"
                />
                <path fill="#ECF3F9" d="M545.607 157.727H1.271v25.906h544.336v-25.906Z" />
                <path fill="#C2C9CF" d="M661.291 157.727H545.607v25.906h115.684v-25.906Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M661.292 183.631v-25.904H1.272v25.904h544.335"
                />
                <defs>
                    <linearGradient
                        id="object-outdoor-bench-1-a"
                        x1={674.709}
                        x2={236.916}
                        y1={122.506}
                        y2={-22.93}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 796,
        height: 370,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M165.435 166.893 230.406 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM147.135 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM19.861 183.653v-.024H1.021v186.119h18.84V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M238.199 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737H9.863v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347L238.2 369.749h-.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M700.929 166.893 765.9 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM682.629 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM555.357 183.653v-.024h-18.841v186.119h18.841V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M773.695 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h-.001Z"
                />
                <path
                    fill="url(#object-outdoor-bench-1-a)"
                    d="M716.768 104.022h-545.01l42.61-102.045h544.336l-41.936 102.045Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M248.198 1.977h510.506l-41.936 102.045h-545.01l42.61-102.045M663.22 70.008H253.49M677.431 35.988h-409.97"
                />
                <path fill="#FFE4B8" d="M545.357 157.727H1.021v25.906h544.336v-25.906Z" />
                <path fill="#E5B97F" d="M661.041 157.727H545.357v25.906h115.684v-25.906Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M661.04 183.631v-25.904H1.021v25.904h544.336"
                />
                <defs>
                    <linearGradient
                        id="object-outdoor-bench-1-a"
                        x1={674.459}
                        x2={236.666}
                        y1={122.506}
                        y2={-22.93}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.035} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 797,
        height: 370,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M166.185 166.893 231.156 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM147.885 225.319l-79.36-5.439a16.937 16.937 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM20.612 183.653v-.024H1.772v186.119h18.84V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M238.949 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h-.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M701.679 166.893 766.65 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM683.381 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM556.107 183.653v-.024h-18.841v186.119h18.841V183.653Z"
                />
                <path
                    fill="#888B8D"
                    d="M774.445 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h-.001Z"
                />
                <path
                    fill="url(#object-outdoor-bench-1-a)"
                    d="M717.518 104.022h-545.01l42.61-102.045h544.337l-41.937 102.045Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M248.949 1.977h510.506l-41.937 102.045h-545.01l42.61-102.045M663.97 70.008H254.24M678.181 35.988h-409.97"
                />
                <path fill="#FCCBBB" d="M546.108 157.727H1.771v25.906h544.337v-25.906Z" />
                <path fill="#EA9975" d="M661.791 157.727H546.107v25.906h115.684v-25.906Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M661.791 183.631v-25.904H1.771v25.904h544.337"
                />
                <defs>
                    <linearGradient
                        id="object-outdoor-bench-1-a"
                        x1={675.21}
                        x2={237.417}
                        y1={122.506}
                        y2={-22.93}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.207} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 796,
        height: 370,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M165.933 166.893 230.904 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM147.633 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM20.36 183.654v-.025H1.52v186.119h18.84V183.654Z"
                />
                <path
                    fill="#888B8D"
                    d="M238.698 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597l-67.774 164.916a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.182-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M701.427 166.893 766.398 8.798c-4.008-4.17-9.647-6.821-16.038-6.821l-67.774 164.916a27 27 0 0 1-24.973 16.737h18.841a26.999 26.999 0 0 0 24.973-16.737ZM683.129 225.319l-79.36-5.439a16.938 16.938 0 0 0-6.556.842l67.076 4.597a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h18.841l-47.656-107.081a63.188 63.188 0 0 0-53.407-37.347h-.001ZM555.855 183.654v-.025h-18.841v186.119h18.841V183.654Z"
                />
                <path
                    fill="#888B8D"
                    d="M774.193 369.749h21.6l-48.537-170.821a59.99 59.99 0 0 1-.545-30.741l34.121-138.613c3.456-14.038-7.171-27.597-21.628-27.597L691.43 166.893a27 27 0 0 1-24.973 16.737h-120.6v186.119h21.6l8.183-133.943c.575-9.421 8.715-16.569 18.131-15.924l79.36 5.439a63.188 63.188 0 0 1 53.407 37.347l47.656 107.081h-.001Z"
                />
                <path
                    fill="url(#object-outdoor-bench-1-a)"
                    d="M717.266 104.022h-545.01l42.61-102.045h544.337l-41.937 102.045Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M248.697 1.977h510.506l-41.937 102.045h-545.01l42.61-102.044M663.718 70.008h-409.73M677.929 35.988h-409.97"
                />
                <path fill="#FFF4C3" d="M545.856 157.723H1.52v25.906h544.336v-25.906Z" />
                <path fill="#FFD100" d="M661.539 157.723H545.855v25.906h115.684v-25.906Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M661.54 183.629v-25.906H1.52v25.906h544.336"
                />
                <defs>
                    <linearGradient
                        id="object-outdoor-bench-1-a"
                        x1={674.957}
                        x2={237.164}
                        y1={122.506}
                        y2={-22.93}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.698} stopColor="#FFEA8B" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-outdoor-bench-1"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
