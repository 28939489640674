import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-2">
        <path
            d="m465.416 167.793 2.55 1.203c12.537 5.916 22.676 12.593 30.998 20.411a2.664 2.664 0 0 1 .86 2.435c-.698 4.602-13.337 10.971-15.864 12.202l-.903.439-17.641-36.69Z"
            fill="url(#man-34-25-jacket-left-option-2-a)"
        />
        <path
            d="M504.951 304.243a384.896 384.896 0 0 1-5.511-6.379c-5.351-6.359-10.615-13.033-16.091-20.408a204.674 204.674 0 0 1-14.282-21.579 836.776 836.776 0 0 1-3.849-6.751c-2.903-5.121-5.906-10.416-9.037-15.505a31.805 31.805 0 0 1-3.871-24.184 31.787 31.787 0 0 1 14.363-19.837 31.933 31.933 0 0 1 16.737-4.748 31.815 31.815 0 0 1 27.285 15.24l.08.13c3.106 5.105 6.453 10.189 9.689 15.106 1.425 2.165 2.846 4.334 4.261 6.505a205.18 205.18 0 0 1 12.71 22.547c4.067 8.227 7.613 15.951 10.836 23.609-12.879 2.965-36.626 25.736-43.32 36.254Z"
            fill="url(#man-34-25-jacket-left-option-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-2-a"
                x1={481.202}
                y1={174.08}
                x2={526.6}
                y2={342.947}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-2-b"
                x1={480.925}
                y1={174.152}
                x2={526.323}
                y2={343.02}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
