import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6984)"
            d="M479.229 236.304c-.182-4.781-.96-19.424-3.838-27.14a101.214 101.214 0 0118.684 14.399 1.705 1.705 0 01.536 1.598c-.512 2.966-8.018 7.688-15.382 11.143z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6984)"
            d="M539.26 337.069c-.82 0-1.638-.014-2.453-.042a53.05 53.05 0 01-11.205-1.365c-1.086-.256-2.171-.525-3.256-.794-2.449-.608-4.981-1.236-7.485-1.693a19.397 19.397 0 01-12.242-7.69l-.038-.031c-.143-.12-.287-.242-.425-.367a169.141 169.141 0 01-14.186-15.053c-4.188-4.97-8.262-10.198-12.454-15.984a131.722 131.722 0 01-10.726-17.049 586.8 586.8 0 01-2.792-5.352c-2.1-4.053-4.273-8.245-6.578-12.258a27.507 27.507 0 012.069-30.487 27.505 27.505 0 0128.958-9.755A27.505 27.505 0 01503.115 232l.08.141c2.282 4.028 4.784 8.032 7.204 11.904 1.066 1.708 2.133 3.415 3.186 5.13a131.79 131.79 0 019.215 17.917c2.844 6.549 5.27 12.716 7.412 18.845a213.036 213.036 0 013.362 10.546 53.678 53.678 0 0110.311 2.679c.881.306 1.77.637 2.657.984.924.341 1.858.735 2.648 1.075l2.603 1.103c.575.246 1.14.52 1.705.793.305.147.61.294.915.437a69.155 69.155 0 0110.324 5.963l2.212 1.588-2.714.221c-6.567.532-11.76 3.466-14.623 8.261-2.728 4.566-2.924 10.305-.54 15.742l.571 1.303-1.42.097c-.332.022-.666.05-1 .076-.63.051-1.259.102-1.884.125l-2.835.09c-.765.03-1.536.052-2.303.052l-.941-.003z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6984"
                x1="468.497"
                x2="570.859"
                y1="208.541"
                y2="329.119"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6984"
                x1="453.567"
                x2="555.928"
                y1="221.219"
                y2="341.798"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
