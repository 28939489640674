import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6430)"
            d="M413.765 316.165a19.477 19.477 0 01-12.965-4.939c-.274-.244-.526-.502-.779-.76a21.255 21.255 0 01-4.838-2.877 100.446 100.446 0 01-11.451-10.934c-3.309-3.646-6.478-7.513-9.69-11.826a74.788 74.788 0 01-7.949-12.869 390.938 390.938 0 01-1.989-4.18c-1.433-3.044-2.915-6.192-4.549-9.177a27.533 27.533 0 0110.915-37.328 27.54 27.54 0 0113.183-3.384c10.064 0 20.14 5.56 24.968 14.38.023.042 6.125 11.679 6.913 13.001 2.267 3.782 4.161 7.534 6.33 11.221 3.905-4.393 7.495-7.809 11.682-11.974 1.021-1.006 2.03-2.018 3.04-3.029 2.058-2.061 4.186-4.193 6.358-6.231l4.754-4.55c1.029-.98 2.078-1.941 3.127-2.902.578-.53 1.155-1.061 1.73-1.592 6.235-5.711 11.143-9.993 15.92-13.888l1.618-1.319.014 2.086c.069 9.774 13.346 17.918 17.392 18.189l1.656.11-.872 1.413c-2.837 4.597-6.079 9.542-10.202 15.562-.45.648-.893 1.294-1.336 1.941-.805 1.174-1.609 2.347-2.435 3.503l-3.843 5.356c-1.706 2.424-3.514 4.826-5.263 7.149a857.02 857.02 0 00-2.579 3.444 286.067 286.067 0 01-16.727 20.369l-8.842 9.711-8.722 9.793a19.534 19.534 0 01-14.569 6.531z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6430"
                x1="472.2"
                x2="377.212"
                y1="266.108"
                y2="258.3"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
