import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#EF0025"
            d="M573.158 594.99l-3.29-40.5c-1.599-15.05-.144-29.603 2.332-46.994.639-4.164 1.447-8.349 2.313-12.579l-97.878-.164-4.512-.577c-2.94-.382-5.962-.853-8.984-1.401a53.305 53.305 0 019.456-105.752c1.217 0 2.451.043 3.668.127l7.252.497c2.761.188 5.733.482 9.085.899l4.495.578 4.492.685c2.216.314 4.456.713 6.622 1.1l2.31.408c2.228.369 4.475.826 6.65 1.268l2.244.453c12.695 2.634 24.22 5.406 35.242 8.478 5.417 1.528 11.095 3.45 17.361 5.876a307.34 307.34 0 0117.065 7.2 164.76 164.76 0 014.236 1.987c1.349.637 2.732 1.316 4.217 2.07 1.815.893 3.649 1.882 5.422 2.838.978.526 1.956 1.054 2.936 1.568a165.672 165.672 0 0116.527 10.37l.87.637a37.353 37.353 0 0112.066 14.988 30.52 30.52 0 012.151 15.905l-5.724 46.616-2.872 23.308-5.406 60.111h-50.346z"
        ></path>
    </g>
);

export default SvgComponent;
