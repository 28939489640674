import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6503)"
            d="M476.894 384.27a174.138 174.138 0 00-28.468-11.813 99.523 99.523 0 00-8.674-2.36 114.94 114.94 0 00-15.984-3.014c-21.11-2.564-42.302-.867-56.821 21.948-6.401 10.058-8.865 22.784-8.36 35.034a69.918 69.918 0 006.754 27.42c9.782 20.291 32.883 27.113 44.199 27.113 0 0-.106-.1-.283-.278.416.011.83.028 1.247.028h23.239s-27.614-28.204-10.433-68.658c13.7-32.258 53.584-25.42 53.584-25.42z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6503)"
            d="M514.666 424.873l1.056 2.775c6.716-6.112 13.468-16.501 15.588-34.097-4.896 7.091-13.557 11.3-24.61 13.472a44.896 44.896 0 017.966 17.85z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6503"
                x1="373.105"
                x2="443.941"
                y1="498.63"
                y2="335.069"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6503"
                x1="498.025"
                x2="541.959"
                y1="428.354"
                y2="391.644"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
