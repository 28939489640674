import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-1">
        <path
            d="m465.74 167.461 2.654.951c13.051 4.678 23.789 10.343 32.825 17.319a2.684 2.684 0 0 1 1.093 2.341c-.25 4.649-12.215 12.209-14.611 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-1-a)"
        />
        <path
            d="M505.808 285.237c-5.565-5.027-11.064-10.361-16.812-16.306a165.895 165.895 0 0 1-15.16-17.693 737.776 737.776 0 0 1-4.131-5.613c-3.13-4.275-6.368-8.696-9.712-12.905a31.796 31.796 0 0 1-6.74-23.546 31.79 31.79 0 0 1 11.883-21.416 31.666 31.666 0 0 1 19.89-6.949 31.841 31.841 0 0 1 25.072 12.092l.105.132c3.319 4.223 6.866 8.386 10.297 12.413a1636.56 1636.56 0 0 1 4.527 5.34 166.019 166.019 0 0 1 13.713 18.842c4.452 6.961 8.371 13.544 11.975 20.115-13.094 2.112-37.275 15.015-44.907 35.494Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-1-b)"
        />
        <path
            d="M459.994 232.72c3.344 4.209 6.582 8.63 9.712 12.905a1691.02 1691.02 0 0 0 4.131 5.613 166.01 166.01 0 0 0 15.16 17.693c5.748 5.945 11.247 11.279 16.812 16.306 7.632-20.479 31.813-33.382 44.906-35.494-3.604-6.571-7.522-13.154-11.975-20.115a166.019 166.019 0 0 0-13.713-18.842 717.041 717.041 0 0 0-4.527-5.34c-3.43-4.027-6.978-8.19-10.297-12.413l-.104-.133a31.943 31.943 0 0 0-11.466-9.073 120.972 120.972 0 0 0-30.237-15.413"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-1-a"
                x1={480.588}
                y1={270.1}
                x2={485.142}
                y2={147.787}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-1-b"
                x1={498.314}
                y1={326.704}
                x2={502.869}
                y2={204.39}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
