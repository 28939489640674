import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-1-stand-1">
            <path
                d="M520.492 860.431c-23.625 0-36.848-9.553-38.656-10.951l-24.54-5.177a9.015 9.015 0 0 1-5.89-4.161 9.003 9.003 0 0 1-1.012-7.133c.662-2.427 1.41-5.196 2.159-8.034l.323-1.225 1.117.599c7.054 3.785 15.323 7.998 24.194 10.089a47.833 47.833 0 0 0 10.929 1.305 35.21 35.21 0 0 0 14.942-3.079l.673-.312.494.552c5.053 5.643 10.127 9.624 15.082 11.832 8.835 3.938 13.007 7.396 12.399 10.278-.705 3.341-5.385 5.417-12.214 5.417Z"
                fill="url(#female-34-25-shoes-1-stand-1-a)"
            />
            <path
                d="M532.707 855.013c-.705 3.341-5.386 5.417-12.215 5.417-23.625 0-36.848-9.553-38.655-10.951l-24.54-5.177a9.02 9.02 0 0 1-5.891-4.161"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M405.717 860.931a9.008 9.008 0 0 1-6.622-2.855 9.008 9.008 0 0 1-2.462-6.77c.086-1.471.177-3.064.267-4.72l.068-1.268 1.216.364c1.066.311 2.171.465 3.282.459a19.749 19.749 0 0 0 8.397-2.189c1.862-.903 3.686-2.002 5.449-3.066a60.947 60.947 0 0 1 6.558-3.594 31.179 31.179 0 0 1 12.613-2.651c.75 0 1.508.027 2.255.08l.495.036.271.414c3.454 5.279 6.512 8.835 9.087 10.569a49.76 49.76 0 0 0 5.924 3.13c4.556 2.169 8.492 4.042 8.492 6.643 0 3.444-4.21 5.418-11.551 5.418h-43.739Z"
                fill="url(#female-34-25-shoes-1-stand-1-b)"
            />
            <path
                d="M461.008 855.516c0 3.443-4.21 5.418-11.551 5.418h-43.739a9.026 9.026 0 0 1-6.622-2.856"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-1-stand-1-a"
                    x1={450.067}
                    y1={842.09}
                    x2={532.764}
                    y2={842.09}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-1-stand-1-b"
                    x1={396.617}
                    y1={847.786}
                    x2={461.007}
                    y2={847.786}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
