import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-6">
        <path
            d="m501.216 185.927-.027-.02-.005-.004c-9.031-6.966-19.757-12.624-32.793-17.296l-2.654-.951 21.104 34.814.857-.526c2.396-1.468 14.36-9.028 14.61-13.676a2.677 2.677 0 0 0-1.092-2.341Z"
            fill="#001489"
        />
        <path
            d="M531.831 214.022c-.64-1.23-1.282-2.448-1.924-3.667-1.311-2.488-2.666-5.06-3.953-7.625-1.847-3.603-3.81-7.456-5.69-11.374-.328-.116-.656-.23-.985-.342-2.349-.803-4.704-1.589-7.059-2.376-5.353-1.787-10.888-3.636-16.251-5.652l-.149-.055a31.868 31.868 0 0 0-11.101-1.997 32.127 32.127 0 0 0-30.015 20.904 32.033 32.033 0 0 0 18.907 41.116c5.373 1.988 10.77 4.216 15.989 6.37 2.292.946 4.584 1.893 6.88 2.822a188.458 188.458 0 0 0 23.558 7.798c8.511 2.294 16.444 4.178 24.255 5.76-7.268-16.84-9.755-34.432-10.261-47.578a656.36 656.36 0 0 1-2.201-4.104Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
