import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11325_7996)"
            d="M527.61 394.399a6.63 6.63 0 00-.002-.141H355.584c-7.295 16.331-10.193 33.084-10.193 44.469 0 34.797 30.284 54.278 46.043 56.302 27.018 0 11.638-.109 35.711-.109 92.241-13.626 126.361-68.566 188.819-65.53-5.639-3.903-12.044-8.488-28.554-15.686-23.875-10.409-45.153-16.419-59.8-19.305z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11325_7996"
                x1="297.307"
                x2="644.253"
                y1="454.518"
                y2="417.893"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
