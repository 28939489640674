import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6873)"
            d="M494.767 223.314a2.699 2.699 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6873)"
            d="M564.088 307.737c-1.351 0-2.699-.135-4.023-.404-9.158-1.884-17.899-4.495-24.49-6.559-7.623-2.416-15.341-5.13-23.599-8.299a202.147 202.147 0 01-22.757-10.146 892.563 892.563 0 01-6.622-3.485c-5.008-2.65-10.19-5.391-15.363-7.903a27.307 27.307 0 01-13.978-15.736 27.316 27.316 0 011.246-21.011 27.679 27.679 0 0124.76-15.499 27.236 27.236 0 0111.988 2.767l.117.057c5.162 2.536 10.508 4.935 15.678 7.255 2.277 1.022 4.552 2.049 6.824 3.081a201.827 201.827 0 0121.999 11.709c7.568 4.558 14.455 8.975 21.047 13.499 5.686 3.933 13.12 9.221 20.233 15.281a19.962 19.962 0 017.002 13.698 19.96 19.96 0 01-4.736 14.637 20.102 20.102 0 01-15.326 7.058z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_6873)"
            d="M567.912 308.119a19.56 19.56 0 01-19.081-15.577l-.024-.122c-.647-3.249-1.466-6.528-2.258-9.698a466.171 466.171 0 01-1.044-4.231 86.455 86.455 0 01-2.162-14.366c-.046-.789-.103-1.568-.159-2.347-.116-1.607-.238-3.269-.261-4.937l-.113-3.618a61.667 61.667 0 01-.011-2.395c.003-.43.006-.86.005-1.29 15.316 8.007 27.461 3.092 33.099-6.702.17.407.34.802.51 1.198.316.734.631 1.467.918 2.207l1.309 3.396c.62 1.531 1.154 3.11 1.671 4.636.251.74.501 1.479.761 2.216a86.344 86.344 0 013.591 14.085c.237 1.433.459 2.868.682 4.303.502 3.23 1.02 6.57 1.686 9.81a19.364 19.364 0 01-2.816 14.657 19.377 19.377 0 01-12.356 8.371 19.604 19.604 0 01-3.947.404z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6873"
                x1="472.914"
                x2="495.633"
                y1="222.636"
                y2="222.636"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6873"
                x1="451.727"
                x2="584.215"
                y1="263.216"
                y2="263.216"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_6873"
                x1="542.793"
                x2="587.438"
                y1="275.478"
                y2="275.478"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
