import React, { useState } from 'react';
import { TUserCredentials, useAuth } from 'utils/useAuth';
import Logo from 'components/Header/Logo';

function LoginPage() {
    const { signin, error, setError } = useAuth();
    const [form, setForm] = useState<TUserCredentials>({ username: '', password: '' });

    function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        signin(form);
    }

    function onChange(e: { target: { name: string; value: string; }; }) {
        setError('');
        setForm(prev => ({ ...prev, [e.target.name]: e.target.value }));
    }

    return <div className="signin-page">
        <div className="signin-bg">
            <img src="/img/office.jpg" alt="office"/>
            <div className="signin-logo">
                <Logo />
            </div>
        </div>
        <div className="signin-wrap">
            <form className="signin-form" onSubmit={handleSubmit}>
                {error ? <div className="signin-error">
                    {error}
                </div> : null}
                <h1>SIGN IN</h1>

                <label>
                    <div>Username</div>
                    <input
                        type="text"
                        name="username"
                        onChange={onChange}
                        required
                        autoComplete="username"
                    />
                </label>
                <label>
                    <div>Password</div>
                    <input
                        type="password"
                        name="password"
                        onChange={onChange}
                        required
                        autoComplete="current-password"
                    />
                </label>

                <div className="signin-submit-wrap">
                    <input disabled={!!error} type="submit" value="Submit"/>
                </div>
            </form>
        </div>
    </div>;
}

export default LoginPage;
