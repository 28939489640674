import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M416.5 469.6c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2L419.3 469c-1 .4-1.9.6-2.8.6ZM291.699 469.6c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2l-23.9 10.1c-1 .4-1.9.6-2.8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M491.798 727.602c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="#A9ACAC"
                    d="M481.599 762.102c18.171 0 32.9-19.744 32.9-44.1 0-24.355-14.729-44.1-32.9-44.1-18.17 0-32.9 19.745-32.9 44.1 0 24.356 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-a)"
                    d="M385.897 718.002c0-72.1 41.401-131.2 94.101-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.6-5.7-94.101-64.8-94.101-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-b)"
                    d="M483.999 561.602c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.701 70-116.701 156.4s52.301 156.4 116.701 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.699-70 116.699-156.4s-52.199-156.4-116.699-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.3-5.5.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m434.698 631.502-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.3-2.3.8-3.6ZM538.399 766.902c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.3.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M500.798 685.502c6.3 8.3 10.2 19.8 10.2 32.5 0 23.9-13.8 43.6-31.2 45.5-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M449.998 690.202c6.2-10.8 16-17.8 27-17.8"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-c)"
                    d="M565.199 553.2H477.6c0-5.4 4.4-9.7 9.7-9.7h68.099c5.5 0 9.8 4.4 9.8 9.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M589.8 644.601h-11v-68.2c0-4.9-6.501-12.1-15.401-12.1H471.1v-11h92.299c15.1 0 26.401 12.2 26.401 23.1v68.2ZM346.999 809.201c-3 0-5.5-2.5-5.5-5.5v-275.2c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 1.2-.4 2.5-.8 3.7-1 4.3-.7 8.7-.1 12.7 1.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.4 5.4-5.4 5.4Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M471.7 809.2c-3 0-5.5-2.5-5.5-5.5V528.5c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 5.4-1.9 11.3-1.6 16.4.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.3 5.4-5.4 5.4ZM341.498 803.7c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.4h-11v224.3Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M346.698 478.301h-11.3c4 16.4 6.101 33.3 6.101 50.2v50.9h11v-50.9c0-16.9-2.001-33.7-5.801-50.2ZM466.199 803.7c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.4h-11v224.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M578.8 600.2v-23.7c0-4.9-6.501-12.1-15.401-12.1H471.1v-11h92.299c15.1 0 26.401 12.2 26.401 23.1v62"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-d)"
                    d="M604.698 644.402H355.299c-3.5 0-6.401-2.9-6.401-6.4 0-3.5 2.901-6.4 6.401-6.4h249.399c3.5 0 6.401 2.9 6.401 6.4 0 3.6-2.901 6.4-6.401 6.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-e)"
                    d="M584 842.4c0-11.2 6.7-20.3 15-20.6l-7.1-9.8c-9.5 4.2-16.4 16.3-16.4 30.4 0 15.6 8.3 28.6 19.3 31.5l3.6-10.9c-8.1-.8-14.4-9.7-14.4-20.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-f)"
                    d="M598.9 856.501c5.799 0 10.5-6.313 10.5-14.1s-4.701-14.1-10.5-14.1-10.5 6.313-10.5 14.1 4.701 14.1 10.5 14.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-g)"
                    d="M601.9 810.301h-5.5c-13.2 0-23.9 14.4-23.9 32.1 0 17.7 10.7 32.1 23.9 32.1h5.4c13.2 0 23.9-14.4 23.9-32.1.1-17.7-10.6-32.1-23.8-32.1Zm-20.7 32.1c0-11.4 6.9-20.6 15.4-20.6.4 0 .8 0 1.3.1 7.9.9 14.1 9.7 14.1 20.6 0 10.8-6.2 19.7-14.1 20.6-.4 0-.8.1-1.3.1-8.5-.2-15.4-9.4-15.4-20.8Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M597.5 827.801h.899c5.6.6 10 6.9 10 14.5s-4.4 13.9-10 14.5h-.899c-6 0-10.9-6.5-10.9-14.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M596.5 809.202H470.2c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5H591v-143.1H347c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h249.5c3 0 5.5 2.5 5.5 5.5v154.1c0 3-2.5 5.5-5.5 5.5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M467.699 723.5h-119.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h119.5c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM471.698 809.201h-156.8c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h156.8c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM620.6 745.102c-3 0-5.5 2.5-5.5 5.5v4.1H598c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v13.8H598c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v18.4l-23.099 31 7.299 5.8 24.701-31.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3.3-2.4-5.7-5.5-5.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="m499.499 812.6-24.7 33.8-9.5-8 24.8-34.5v-52.8c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1V804c-.1 3.1-1 6.1-2.8 8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M595.8 834.701c3.1 0 5.7 3.4 5.7 7.6 0 4.2-2.6 7.6-5.7 7.6s-5.7-3.4-5.7-7.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M493.8 765.602h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM493.8 790.4h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM545.099 847.3c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6ZM601.699 847.3c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-h)"
                    d="M652.3 848.801h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5-.1 2.7-2.3 5-5 5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M341.499 541.9v-13.4c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M352.5 723.5v74.6M352.5 655.102v41.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M318 446.001c4.4-.8 8.9-.2 13 1.8 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v91.9M466.198 528.5c0-22-3.399-43.9-10.199-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.301-1.4-7.901-.4-2.9 1-6-.5-7-3.3M466.199 712.4V675M466.199 798.1v-74.2M477.199 790.4v7.8M477.199 765.602v13.8M477.199 655.602v99"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M443.398 445.9c4.2-.6 8.4.1 12.3 2 5.2 2.5 9.001 7 10.801 12.5 7.1 22.1 10.699 45 10.699 68.2v97.2M352.5 644.102h102.3M352.5 712.5h113.8M466.198 723.5h-113.8M352.5 798.201h101.8M485.999 809.201H314.898c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h18.701M501.2 809.202h19.6m-168.3-154.1H591v143.1h-82.4m93.4-43.6v-105c0-3-2.5-5.5-5.5-5.5H465.7m144 188.3 14.4-18.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5v4.1m-25 54.6h6.4c3 0 5.5-2.5 5.5-5.5v-13.3h13.2v18.4l-11.2 15m-70.5-14.6h43.9m24.6-29.8v-13.8h13.2v13.8"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.199 790.402h12.9v13.5l-24.8 34.5 9.5 8 24.7-33.8c1.8-2.5 2.8-5.4 2.8-8.5v-52.9c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1v3.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.199 765.602h12.9v13.8M545.099 847.302c-1.9 0-3.8-1-4.8-2.8l-19.5-35.4M533.398 809.301l9.901 18M596.898 844.502l-19.5-35.4M590.1 809.201l16.4 30M419.2 469.001c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.7-.7 3.5-.7 5.1-.1"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-i)"
                    d="M340.499 580.401v-51.9c0-16.8-2-33.6-6-50l-.3-1.2h138.1l.2.8c3.8 16.5 5.8 33.5 5.8 50.5v51.9h-137.8v-.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M294.399 469.001c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.8-.8 3.7-.7 5.4 0"
                />
                <path
                    fill="#716E72"
                    d="M609.099 848.801h-74.201c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.201c2.7 0 5 2.2 5 5 0 2.7-2.2 5-5 5Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-j)"
                    d="M470.199 874.501c17.729 0 32.101-14.372 32.101-32.1 0-17.729-14.372-32.1-32.101-32.1-17.728 0-32.099 14.371-32.099 32.1 0 17.728 14.371 32.1 32.099 32.1Z"
                />
                <path
                    fill="#fff"
                    d="M470.199 862.8c11.266 0 20.4-9.133 20.4-20.4s-9.134-20.4-20.4-20.4c-11.267 0-20.4 9.133-20.4 20.4s9.133 20.4 20.4 20.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-k)"
                    d="M470.199 856.9c8.008 0 14.5-6.491 14.5-14.5 0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5c0 8.009 6.492 14.5 14.5 14.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M461.799 854.2c2.4 1.7 5.3 2.7 8.4 2.7 8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5-14.5 6.5-14.5 14.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M470.199 850.001a7.6 7.6 0 1 0 0-15.201 7.6 7.6 0 0 0 0 15.201Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-1-a"
                        x1={424.586}
                        x2={424.586}
                        y1={855.379}
                        y2={580.678}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-b"
                        x1={389.826}
                        x2={605.587}
                        y1={830.661}
                        y2={554.5}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-c"
                        x1={477.627}
                        x2={565.229}
                        y1={548.367}
                        y2={548.367}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#0197D6" />
                        <stop offset={0.019} stopColor="#0298D6" />
                        <stop offset={1} stopColor="#40C1E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-d"
                        x1={348.884}
                        x2={611.078}
                        y1={638.009}
                        y2={638.009}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.469} stopColor="#0197D6" />
                        <stop offset={0.617} stopColor="#40C1E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-e"
                        x1={587.197}
                        x2={587.197}
                        y1={873.661}
                        y2={825.039}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-f"
                        x1={598.926}
                        x2={598.926}
                        y1={856.447}
                        y2={828.321}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-g"
                        x1={599.24}
                        x2={599.24}
                        y1={873.661}
                        y2={825.04}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-h"
                        x1={609.993}
                        x2={667.29}
                        y1={843.823}
                        y2={843.364}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-i"
                        x1={357.935}
                        x2={502.036}
                        y1={528.847}
                        y2={528.847}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#0197D6" />
                        <stop offset={0.019} stopColor="#0298D6" />
                        <stop offset={1} stopColor="#40C1E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-j"
                        x1={438.124}
                        x2={502.247}
                        y1={842.384}
                        y2={842.384}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-k"
                        x1={455.675}
                        x2={484.696}
                        y1={842.384}
                        y2={842.384}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M416.5 470.1a6.91 6.91 0 0 1-6.4-4.299c-1.5-3.6.1-7.701 3.7-9.201l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.701-3.7 9.201L419.3 469.5c-1 .4-1.9.6-2.8.6ZM291.699 470.1a6.91 6.91 0 0 1-6.4-4.299c-1.5-3.6.1-7.701 3.7-9.201l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.701-3.7 9.201l-23.9 10.099c-1 .4-1.9.6-2.8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M491.798 728.2c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="#A9ACAC"
                    d="M481.599 762.7c18.171 0 32.9-19.744 32.9-44.1s-14.729-44.1-32.9-44.1c-18.17 0-32.9 19.744-32.9 44.1s14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-a)"
                    d="M385.897 718.599c0-72.1 41.401-131.2 94.101-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.6-5.8-94.101-64.9-94.101-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-b)"
                    d="M483.999 562.1c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.701 70-116.701 156.4s52.301 156.4 116.701 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.699-70 116.699-156.4 0-86.3-52.199-156.4-116.699-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.3-5.5.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m434.698 632.1-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM538.399 767.4c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M500.798 686c6.3 8.3 10.2 19.8 10.2 32.5 0 23.9-13.8 43.6-31.2 45.5-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M449.998 690.7c6.2-10.8 16-17.8 27-17.8"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-c)"
                    d="M565.199 553.801H477.6c0-5.4 4.4-9.7 9.7-9.7h68.099c5.5-.1 9.8 4.3 9.8 9.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M589.8 645.2h-11V577c0-4.9-6.501-12.1-15.401-12.1H471.1v-11h92.299c15.1 0 26.401 12.2 26.401 23.1v68.2ZM346.999 809.699c-3 0-5.5-2.5-5.5-5.5V529c0-22-3.4-43.901-10.2-64.801-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.399-7.9-.399-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 1.2-.4 2.5-.8 3.7-1 4.3-.7 8.7-.1 12.7 1.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 44.999 10.7 68.199V804.3c.1 2.9-2.4 5.399-5.4 5.399Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M471.7 809.7c-3 0-5.5-2.5-5.5-5.5V529c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 5.4-1.9 11.3-1.6 16.4.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.3 5.4-5.4 5.4ZM341.498 804.2c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.9h-11v224.3Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M346.698 478.801h-11.3c4 16.4 6.101 33.3 6.101 50.2v50.9h11v-50.9c0-16.9-2.001-33.7-5.801-50.2ZM466.199 804.2c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.9h-11v224.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M578.8 600.7V577c0-4.9-6.501-12.1-15.401-12.1H471.1v-11h92.299c15.1 0 26.401 12.2 26.401 23.1v62"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-d)"
                    d="M604.698 645.001H355.299c-3.5 0-6.401-2.9-6.401-6.4 0-3.5 2.901-6.4 6.401-6.4h249.399c3.5 0 6.401 2.9 6.401 6.4 0 3.5-2.901 6.4-6.401 6.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-e)"
                    d="M584 842.9c0-11.2 6.7-20.3 15-20.6l-7.1-9.8c-9.5 4.2-16.4 16.3-16.4 30.4 0 15.6 8.3 28.6 19.3 31.5l3.6-10.9c-8.1-.8-14.4-9.7-14.4-20.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-f)"
                    d="M598.9 857.001c5.799 0 10.5-6.313 10.5-14.1s-4.701-14.1-10.5-14.1-10.5 6.313-10.5 14.1 4.701 14.1 10.5 14.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-g)"
                    d="M601.9 810.801h-5.5c-13.2 0-23.9 14.4-23.9 32.1 0 17.7 10.7 32.1 23.9 32.1h5.4c13.2 0 23.9-14.4 23.9-32.1.1-17.7-10.6-32.1-23.8-32.1Zm-20.7 32.1c0-11.4 6.9-20.6 15.4-20.6.4 0 .8 0 1.3.1 7.9.9 14.1 9.7 14.1 20.6 0 10.8-6.2 19.7-14.1 20.6-.4 0-.8.1-1.3.1-8.5-.1-15.4-9.4-15.4-20.8Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M597.5 828.4h.899c5.6.6 10 6.9 10 14.5s-4.4 13.9-10 14.5h-.899c-6 0-10.9-6.5-10.9-14.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M596.5 809.7H470.2c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5H591V655.6H347c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h249.5c3 0 5.5 2.5 5.5 5.5v154.1c0 3-2.5 5.5-5.5 5.5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M467.699 724.1h-119.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h119.5c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM471.698 809.701h-156.8c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h156.8c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM620.6 745.6c-3 0-5.5 2.5-5.5 5.5v4.1H598c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199V780H598c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v18.4l-23.099 31 7.299 5.8 24.701-31.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3.3-2.4-5.7-5.5-5.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="m499.499 813.1-24.7 33.8-9.5-8 24.8-34.5v-52.8c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1v52.9c-.1 3.2-1 6.1-2.8 8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M595.8 835.301c3.1 0 5.7 3.4 5.7 7.6 0 4.2-2.6 7.6-5.7 7.6s-5.7-3.4-5.7-7.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M493.8 766.1h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM493.8 790.9h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM545.099 847.9c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6ZM601.699 847.9c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-h)"
                    d="M652.3 849.301h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5-.1 2.8-2.3 5-5 5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M341.499 542.4V529c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M352.5 724.1v74.6M352.5 655.6v41.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M318 446.601c4.4-.8 8.9-.2 13 1.8 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v91.9M466.198 529.1c0-22-3.399-43.9-10.199-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.301-1.4-7.901-.4-2.9 1-6-.5-7-3.3M466.199 713v-37.5M466.199 798.6v-74.2M477.199 790.9v7.8M477.199 766.1v13.8M477.199 656.1v99"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M443.398 446.4c4.2-.6 8.4.1 12.3 2 5.2 2.5 9.001 7 10.801 12.5 7.1 22.1 10.699 45 10.699 68.2v97.2M352.5 644.6h102.3M352.5 713.1h113.8M466.198 724.1h-113.8M352.5 798.701h101.8M485.999 809.701H314.898c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h18.701M501.2 809.7h19.6M352.5 655.6H591v143.1h-82.4m93.4-43.6V650c0-3-2.5-5.5-5.5-5.5H465.7m144 188.5 14.4-18.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5v4.1m-25 54.5h6.4c3 0 5.5-2.5 5.5-5.5v-13.3h13.2v18.4l-11.2 15m-70.5-14.6h43.9m24.6-29.8v-13.8h13.2v13.8"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.199 790.9h12.9v13.5l-24.8 34.5 9.5 8 24.7-33.8c1.8-2.5 2.8-5.4 2.8-8.5v-52.9c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1v3.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.199 766.1h12.9v13.8M545.099 847.901c-1.9 0-3.8-1-4.8-2.8l-19.5-35.4M533.398 809.801l9.901 18M596.898 845l-19.5-35.4M590.1 809.701l16.4 30M419.2 469.601c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.7-.7 3.5-.7 5.1-.1"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-i)"
                    d="M340.499 580.901v-51.9c0-16.8-2-33.6-6-50l-.3-1.2h138.1l.2.8c3.8 16.5 5.8 33.5 5.8 50.5v51.9h-137.8v-.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M294.399 469.6c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.8-.8 3.7-.7 5.4 0"
                />
                <path
                    fill="#716E72"
                    d="M609.099 849.301h-74.201c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.201c2.7 0 5 2.2 5 5s-2.2 5-5 5Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-j)"
                    d="M470.199 875.001c17.729 0 32.101-14.372 32.101-32.1 0-17.729-14.372-32.1-32.101-32.1-17.728 0-32.099 14.371-32.099 32.1 0 17.728 14.371 32.1 32.099 32.1Z"
                />
                <path
                    fill="#fff"
                    d="M470.199 863.3c11.266 0 20.4-9.133 20.4-20.4s-9.134-20.4-20.4-20.4c-11.267 0-20.4 9.133-20.4 20.4s9.133 20.4 20.4 20.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-k)"
                    d="M470.199 857.4c8.008 0 14.5-6.491 14.5-14.5 0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5c0 8.009 6.492 14.5 14.5 14.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M461.799 854.8c2.4 1.7 5.3 2.7 8.4 2.7 8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5-14.5 6.5-14.5 14.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M470.199 850.501a7.6 7.6 0 1 0 0-15.201 7.6 7.6 0 0 0 0 15.201Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-1-a"
                        x1={424.586}
                        x2={424.586}
                        y1={855.904}
                        y2={581.204}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-b"
                        x1={389.826}
                        x2={605.587}
                        y1={831.187}
                        y2={555.026}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-c"
                        x1={477.627}
                        x2={565.229}
                        y1={548.896}
                        y2={548.896}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FFD100" />
                        <stop offset={0.664} stopColor="#FFE985" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-d"
                        x1={348.884}
                        x2={611.078}
                        y1={638.537}
                        y2={638.537}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.469} stopColor="#FFD100" />
                        <stop offset={0.617} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-e"
                        x1={587.197}
                        x2={587.197}
                        y1={874.189}
                        y2={825.567}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-f"
                        x1={598.926}
                        x2={598.926}
                        y1={856.975}
                        y2={828.849}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-g"
                        x1={599.24}
                        x2={599.24}
                        y1={874.189}
                        y2={825.568}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-h"
                        x1={609.993}
                        x2={667.29}
                        y1={844.351}
                        y2={843.892}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-i"
                        x1={357.935}
                        x2={502.036}
                        y1={529.375}
                        y2={529.375}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FFD100" />
                        <stop offset={0.664} stopColor="#FFE985" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-j"
                        x1={438.124}
                        x2={502.247}
                        y1={842.912}
                        y2={842.912}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-k"
                        x1={455.675}
                        x2={484.696}
                        y1={842.911}
                        y2={842.911}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M417.1 469.6c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2L419.9 469c-1 .4-1.9.6-2.8.6ZM292.199 469.6c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2l-23.9 10.1c-.9.4-1.9.6-2.8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M492.399 727.602c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#A9ACAC"
                    d="M482.099 762.102c18.171 0 32.9-19.744 32.9-44.1 0-24.355-14.729-44.1-32.9-44.1-18.17 0-32.9 19.745-32.9 44.1 0 24.356 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-a)"
                    d="M386.499 718.002c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.7-94.1-64.8-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-b)"
                    d="M484.6 561.602c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4s-52.3-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.3-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m435.2 631.502-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.3-2.3.8-3.6ZM538.901 766.902c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.3.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M501.399 685.502c6.3 8.3 10.201 19.8 10.201 32.5 0 23.9-13.801 43.6-31.201 45.5-.9.1-1.899.2-2.799.2-18.8 0-34-20.4-34-45.6M450.5 690.202c6.2-10.8 16-17.8 27-17.8"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-c)"
                    d="M565.799 553.2h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.4 0 9.8 4.4 9.8 9.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M590.399 644.601h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2ZM347.499 809.201c-3 0-5.5-2.5-5.5-5.5v-275.2c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 1.2-.4 2.5-.8 3.7-1 4.3-.7 8.7-.1 12.7 1.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.3 5.4-5.4 5.4Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M472.3 809.2c-3 0-5.5-2.5-5.5-5.5V528.5c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 5.4-1.9 11.3-1.6 16.4.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.4 5.4-5.4 5.4ZM342 803.7c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.4h-11v224.3Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M347.3 478.301H336c4 16.4 6.1 33.3 6.1 50.2v50.9h11v-50.9c-.1-16.9-2-33.7-5.8-50.2ZM466.801 803.7c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.4h-11v224.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M579.399 600.2v-23.7c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v62"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-d)"
                    d="M605.198 644.402H355.799c-3.5 0-6.401-2.9-6.401-6.4 0-3.5 2.901-6.4 6.401-6.4h249.399c3.5 0 6.401 2.9 6.401 6.4 0 3.6-2.801 6.4-6.401 6.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-e)"
                    d="M584.5 842.4c0-11.2 6.7-20.3 15-20.6l-7.1-9.8c-9.5 4.2-16.4 16.3-16.4 30.4 0 15.6 8.3 28.6 19.3 31.5l3.6-10.9c-8-.8-14.4-9.7-14.4-20.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-f)"
                    d="M599.5 856.501c5.799 0 10.5-6.313 10.5-14.1s-4.701-14.1-10.5-14.1-10.5 6.313-10.5 14.1 4.701 14.1 10.5 14.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-g)"
                    d="M602.5 810.301H597c-13.2 0-23.9 14.4-23.9 32.1 0 17.7 10.7 32.1 23.9 32.1H602.399c13.2 0 23.901-14.4 23.901-32.1.1-17.7-10.6-32.1-23.8-32.1Zm-20.801 32.1c0-11.4 6.901-20.6 15.401-20.6.4 0 .799 0 1.299.1 7.9.9 14.101 9.7 14.101 20.6 0 10.8-6.201 19.7-14.101 20.6-.4 0-.799.1-1.299.1-8.5-.2-15.401-9.4-15.401-20.8Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M598 827.801h.899c5.6.6 10 6.9 10 14.5s-4.4 13.9-10 14.5H598c-6 0-10.9-6.5-10.9-14.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M597 809.202H470.7c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h120.8v-143.1h-244c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5H597c3 0 5.5 2.5 5.5 5.5v154.1c0 3-2.4 5.5-5.5 5.5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M468.299 723.5h-119.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h119.5c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM472.3 809.201H315.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h156.8c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM621.199 745.102c-3 0-5.5 2.5-5.5 5.5v4.1H598.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v13.8H598.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v18.4l-23.099 31 7.299 5.8 24.701-31.6c1.3-2.3 2-4.9 2-7.6v-55.7c.1-3.3-2.401-5.7-5.401-5.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="m499.999 812.6-24.7 33.8-9.5-8 24.8-34.5v-52.8c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1V804c0 3.1-1 6.1-2.8 8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M596.3 834.701c3.1 0 5.7 3.4 5.7 7.6 0 4.2-2.6 7.6-5.7 7.6s-5.7-3.4-5.7-7.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M494.399 765.602H475.1c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.299c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM494.399 790.4H475.1c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.299c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM545.599 847.3c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.8.4-1.8.6-2.7.6ZM602.199 847.3c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.8.4-1.8.6-2.7.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-h)"
                    d="M652.8 848.801h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5 0 2.7-2.2 5-5 5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M341.999 541.9v-13.4c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M353 723.5v74.6M353 655.102v41.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M318.5 446.001c4.4-.8 8.9-.2 13 1.8 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v91.9M466.8 528.5c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M466.799 712.4V675M466.799 798.1v-74.2M477.799 790.4v7.8M477.799 765.602v13.8M477.799 655.602v99"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M444 445.9c4.2-.6 8.4.1 12.3 2 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v97.2M353 644.102h102.3M353 712.5h113.8M466.8 723.5H353M353 798.201h101.8M486.499 809.201H315.398c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h18.701M501.7 809.202h19.6M353 655.102h238.5v143.1h-82.4m93.4-43.6v-105c0-3-2.5-5.5-5.5-5.5H466.2m144 188.3 14.4-18.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5v4.1m-25 54.6h6.4c3 0 5.5-2.5 5.5-5.5v-13.3h13.2v18.4l-11.2 15m-70.4-14.6H578m24.5-29.8v-13.8h13.2v13.8"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.799 790.402h12.899v13.5l-24.8 34.5 9.5 8 24.701-33.8c1.8-2.5 2.799-5.4 2.799-8.5v-52.9c0-3.4-2.699-6.1-6.099-6.1-3.4 0-6.101 2.7-6.101 6.1v3.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.799 765.602h12.9v13.8M545.599 847.302c-1.9 0-3.8-1-4.8-2.8l-19.5-35.4M534 809.301l9.9 18M597.398 844.502l-19.5-35.4M590.6 809.201l16.4 30M419.8 469.001c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.7-.7 3.5-.7 5.1-.1"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-i)"
                    d="M340.999 580.401v-51.9c0-16.8-2-33.6-6-50l-.3-1.2h138.1l.2.8c3.8 16.5 5.8 33.5 5.8 50.5v51.9h-137.8v-.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M294.999 469.001c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.8-.8 3.7-.7 5.4 0"
                />
                <path
                    fill="#716E72"
                    d="M609.7 848.801h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5 0 2.7-2.3 5-5 5Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-j)"
                    d="M470.699 874.501c17.729 0 32.101-14.372 32.101-32.1 0-17.729-14.372-32.1-32.101-32.1-17.728 0-32.099 14.371-32.099 32.1 0 17.728 14.371 32.1 32.099 32.1Z"
                />
                <path
                    fill="#fff"
                    d="M470.699 862.8c11.266 0 20.4-9.133 20.4-20.4s-9.134-20.4-20.4-20.4c-11.267 0-20.4 9.133-20.4 20.4s9.133 20.4 20.4 20.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-k)"
                    d="M470.699 856.9c8.008 0 14.5-6.491 14.5-14.5 0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5c0 8.009 6.492 14.5 14.5 14.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M462.399 854.2c2.4 1.7 5.3 2.7 8.4 2.7 8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5-14.5 6.5-14.5 14.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M470.699 850.001a7.6 7.6 0 1 0 0-15.201 7.6 7.6 0 0 0 0 15.201Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-1-a"
                        x1={425.143}
                        x2={425.143}
                        y1={855.379}
                        y2={580.678}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-b"
                        x1={390.384}
                        x2={606.144}
                        y1={830.661}
                        y2={554.5}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-c"
                        x1={478.182}
                        x2={565.785}
                        y1={548.367}
                        y2={548.367}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#001489" />
                        <stop offset={1} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-d"
                        x1={349.44}
                        x2={611.634}
                        y1={638.009}
                        y2={638.009}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.469} stopColor="#001489" />
                        <stop offset={0.617} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-e"
                        x1={587.752}
                        x2={587.752}
                        y1={873.661}
                        y2={825.039}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-f"
                        x1={599.482}
                        x2={599.482}
                        y1={856.447}
                        y2={828.321}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-g"
                        x1={599.795}
                        x2={599.795}
                        y1={873.661}
                        y2={825.04}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-h"
                        x1={610.549}
                        x2={667.845}
                        y1={843.823}
                        y2={843.364}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-i"
                        x1={358.491}
                        x2={502.592}
                        y1={528.847}
                        y2={528.847}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#001489" />
                        <stop offset={1} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-j"
                        x1={438.68}
                        x2={502.802}
                        y1={842.384}
                        y2={842.384}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-k"
                        x1={456.23}
                        x2={485.251}
                        y1={842.384}
                        y2={842.384}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M417.1 470.1a6.91 6.91 0 0 1-6.4-4.299c-1.5-3.6.1-7.701 3.7-9.201l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.701-3.7 9.201L419.9 469.5c-1 .4-1.9.6-2.8.6ZM292.199 470.1a6.91 6.91 0 0 1-6.4-4.299c-1.5-3.6.1-7.701 3.7-9.201l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.701-3.7 9.201l-23.9 10.099c-.9.4-1.9.6-2.8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M492.399 728.2c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#A9ACAC"
                    d="M482.099 762.7c18.171 0 32.9-19.744 32.9-44.1s-14.729-44.1-32.9-44.1c-18.17 0-32.9 19.744-32.9 44.1s14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-a)"
                    d="M386.499 718.599c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-b)"
                    d="M484.6 562.1c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.3-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.3-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m435.2 632.1-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM538.901 767.4c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M501.399 686c6.3 8.3 10.201 19.8 10.201 32.5 0 23.9-13.801 43.6-31.201 45.5-.9.1-1.899.2-2.799.2-18.8 0-34-20.4-34-45.6M450.5 690.7c6.2-10.8 16-17.8 27-17.8"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-c)"
                    d="M565.799 553.801h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.4-.1 9.8 4.3 9.8 9.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M590.399 645.2h-11V577c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2ZM347.499 809.699c-3 0-5.5-2.5-5.5-5.5V529c0-22-3.4-43.901-10.2-64.801-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.399-7.9-.399-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 1.2-.4 2.5-.8 3.7-1 4.3-.7 8.7-.1 12.7 1.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 44.999 10.7 68.199V804.3c.1 2.9-2.3 5.399-5.4 5.399Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M472.3 809.7c-3 0-5.5-2.5-5.5-5.5V529c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 5.4-1.9 11.3-1.6 16.4.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v275.2c.1 2.9-2.4 5.4-5.4 5.4ZM342 804.2c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.9h-11v224.3Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M347.3 478.801H336c4 16.4 6.1 33.3 6.1 50.2v50.9h11v-50.9c-.1-16.9-2-33.7-5.8-50.2ZM466.801 804.2c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V579.9h-11v224.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M579.399 600.7V577c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v62"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-d)"
                    d="M605.198 645.001H355.799c-3.5 0-6.401-2.9-6.401-6.4 0-3.5 2.901-6.4 6.401-6.4h249.399c3.5 0 6.401 2.9 6.401 6.4 0 3.5-2.801 6.4-6.401 6.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-e)"
                    d="M584.5 842.9c0-11.2 6.7-20.3 15-20.6l-7.1-9.8c-9.5 4.2-16.4 16.3-16.4 30.4 0 15.6 8.3 28.6 19.3 31.5l3.6-10.9c-8-.8-14.4-9.7-14.4-20.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-f)"
                    d="M599.5 857.001c5.799 0 10.5-6.313 10.5-14.1s-4.701-14.1-10.5-14.1-10.5 6.313-10.5 14.1 4.701 14.1 10.5 14.1Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-g)"
                    d="M602.5 810.801H597c-13.2 0-23.9 14.4-23.9 32.1 0 17.7 10.7 32.1 23.9 32.1H602.399c13.2 0 23.901-14.4 23.901-32.1.1-17.7-10.6-32.1-23.8-32.1Zm-20.801 32.1c0-11.4 6.901-20.6 15.401-20.6.4 0 .799 0 1.299.1 7.9.9 14.101 9.7 14.101 20.6 0 10.8-6.201 19.7-14.101 20.6-.4 0-.799.1-1.299.1-8.5-.1-15.401-9.4-15.401-20.8Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M598 828.4h.899c5.6.6 10 6.9 10 14.5s-4.4 13.9-10 14.5H598c-6 0-10.9-6.5-10.9-14.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M597 809.7H470.7c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h120.8V655.6h-244c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5H597c3 0 5.5 2.5 5.5 5.5v154.1c0 3-2.4 5.5-5.5 5.5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M468.299 724.1h-119.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h119.5c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM472.3 809.701H315.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h156.8c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM621.199 745.6c-3 0-5.5 2.5-5.5 5.5v4.1H598.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199V780H598.5c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.199v18.4l-23.099 31 7.299 5.8 24.701-31.6c1.3-2.3 2-4.9 2-7.6v-55.7c.1-3.3-2.401-5.7-5.401-5.7Z"
                />
                <path
                    fill="#D9D9D6"
                    d="m499.999 813.1-24.7 33.8-9.5-8 24.8-34.5v-52.8c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1v52.9c0 3.2-1 6.1-2.8 8.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M596.3 835.301c3.1 0 5.7 3.4 5.7 7.6 0 4.2-2.6 7.6-5.7 7.6s-5.7-3.4-5.7-7.6"
                />
                <path
                    fill="#D9D9D6"
                    d="M494.399 766.1H475.1c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.299c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM494.399 790.9H475.1c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.299c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5ZM545.599 847.9c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.8.4-1.8.6-2.7.6ZM602.199 847.9c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.8.4-1.8.6-2.7.6Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-h)"
                    d="M652.8 849.301h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5s-2.2 5-5 5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M341.999 542.4V529c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M353 724.1v74.6M353 655.6v41.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M318.5 446.601c4.4-.8 8.9-.2 13 1.8 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v91.9M466.8 529.1c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M466.799 713v-37.5M466.799 798.6v-74.2M477.799 790.9v7.8M477.799 766.1v13.8M477.799 656.1v99"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M444 446.4c4.2-.6 8.4.1 12.3 2 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v97.2M353 644.6h102.3M353 713.1h113.8M466.8 724.1H353M353 798.701h101.8M486.499 809.701H315.398c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h18.701M501.7 809.7h19.6M353 655.6h238.5v143.1h-82.4m93.4-43.6V650c0-3-2.5-5.5-5.5-5.5H466.2m144 188.5 14.4-18.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5v4.1m-25 54.5h6.4c3 0 5.5-2.5 5.5-5.5v-13.3h13.2v18.4l-11.2 15m-70.4-14.6H578m24.5-29.8v-13.8h13.2v13.8"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.799 790.9h12.899v13.5l-24.8 34.5 9.5 8 24.701-33.8c1.8-2.5 2.799-5.4 2.799-8.5v-52.9c0-3.4-2.699-6.1-6.099-6.1-3.4 0-6.101 2.7-6.101 6.1v3.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M477.799 766.1h12.9v13.8M545.599 847.901c-1.9 0-3.8-1-4.8-2.8l-19.5-35.4M534 809.801l9.9 18M597.398 845l-19.5-35.4M590.6 809.701l16.4 30M419.8 469.601c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.7-.7 3.5-.7 5.1-.1"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-i)"
                    d="M340.999 580.901v-51.9c0-16.8-2-33.6-6-50l-.3-1.2h138.1l.2.8c3.8 16.5 5.8 33.5 5.8 50.5v51.9h-137.8v-.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M294.999 469.6c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.8-.8 3.7-.7 5.4 0"
                />
                <path
                    fill="#716E72"
                    d="M609.7 849.301h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5s-2.3 5-5 5Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-j)"
                    d="M470.699 875.001c17.729 0 32.101-14.372 32.101-32.1 0-17.729-14.372-32.1-32.101-32.1-17.728 0-32.099 14.371-32.099 32.1 0 17.728 14.371 32.1 32.099 32.1Z"
                />
                <path
                    fill="#fff"
                    d="M470.699 863.3c11.266 0 20.4-9.133 20.4-20.4s-9.134-20.4-20.4-20.4c-11.267 0-20.4 9.133-20.4 20.4s9.133 20.4 20.4 20.4Z"
                />
                <path
                    fill="url(#object-devices-wheelchair-1-k)"
                    d="M470.699 857.4c8.008 0 14.5-6.491 14.5-14.5 0-8.008-6.492-14.5-14.5-14.5s-14.5 6.492-14.5 14.5c0 8.009 6.492 14.5 14.5 14.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M462.399 854.8c2.4 1.7 5.3 2.7 8.4 2.7 8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5-14.5 6.5-14.5 14.5"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M470.699 850.501a7.6 7.6 0 1 0 0-15.201 7.6 7.6 0 0 0 0 15.201Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-wheelchair-1-a"
                        x1={425.143}
                        x2={425.143}
                        y1={855.904}
                        y2={581.204}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-b"
                        x1={390.384}
                        x2={606.144}
                        y1={831.187}
                        y2={555.026}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-c"
                        x1={478.182}
                        x2={565.785}
                        y1={548.896}
                        y2={548.896}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#63666A" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-d"
                        x1={349.44}
                        x2={611.634}
                        y1={638.537}
                        y2={638.537}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.469} stopColor="#63666A" />
                        <stop offset={0.617} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-e"
                        x1={587.752}
                        x2={587.752}
                        y1={874.189}
                        y2={825.567}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-f"
                        x1={599.482}
                        x2={599.482}
                        y1={856.975}
                        y2={828.849}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-g"
                        x1={599.795}
                        x2={599.795}
                        y1={874.189}
                        y2={825.568}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-h"
                        x1={610.549}
                        x2={667.845}
                        y1={844.351}
                        y2={843.892}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#221E20" />
                        <stop offset={0.596} stopColor="#5E5B5E" />
                        <stop offset={0.999} stopColor="#89868A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-i"
                        x1={358.491}
                        x2={502.592}
                        y1={529.375}
                        y2={529.375}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#63666A" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-j"
                        x1={438.68}
                        x2={502.802}
                        y1={842.912}
                        y2={842.912}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-wheelchair-1-k"
                        x1={456.23}
                        x2={485.251}
                        y1={842.911}
                        y2={842.911}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={900}
            height={900}
            viewBox={`0 0 ${900} ${900}`}
            fill="none"
            id="object-devices-wheelchair-1"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
