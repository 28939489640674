import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.29 1.588H13.985c-2.609 0-5.21 2.188-5.814 4.886L1.65 35.702C1.047 38.4.277 45.118 8.487 45.118l55.251-4.53c2.61 0 5.211-2.188 5.814-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.785-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.019 6.163H18.563c-2.61 0-5.211 2.188-5.814 4.886l-1.296 5.81-5.226 23.417c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.609 0 5.21-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886l-.001.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.718 17.433c1.164-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.617 3.553.825.925 2.437.834 3.6-.204ZM29.468 17.433c1.164-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.617 3.553.825.925 2.437.834 3.6-.204Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m8.857 28.492-2.63 11.784c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.609 0 5.21-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886H49.23"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.859 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.172 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.305}
                        x2={79.449}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.435}
                        x2={68.07}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={0.133} stopColor="#9CA0A2" />
                        <stop offset={0.389} stopColor="#BEC3C8" />
                        <stop offset={0.627} stopColor="#D7DDE2" />
                        <stop offset={0.838} stopColor="#E6EDF3" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.324 1.588H14.021c-2.609 0-5.21 2.188-5.814 4.886L1.684 35.702C1.082 38.4.312 45.118 8.522 45.118l55.251-4.53c2.61 0 5.211-2.188 5.814-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.785-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.054 6.163H18.598c-2.609 0-5.211 2.188-5.814 4.886l-1.296 5.81-5.226 23.417c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.609 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886l-.001.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.751 17.433c1.165-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204ZM29.507 17.433c1.164-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.617 3.553.825.925 2.437.834 3.601-.204Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m8.892 28.492-2.63 11.784c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.609 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886h-27.79"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.894 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.207 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.34}
                        x2={79.484}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#50D5F9" />
                        <stop offset={1} stopColor="#50D5F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.4}
                        x2={68.105}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#50D5F9" />
                        <stop offset={0.226} stopColor="#7BDEF9" />
                        <stop offset={0.455} stopColor="#A1E5F9" />
                        <stop offset={0.667} stopColor="#BBEBF9" />
                        <stop offset={0.855} stopColor="#CCEEF9" />
                        <stop offset={1} stopColor="#D2F0FA" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.362 1.588H14.06c-2.609 0-5.21 2.188-5.814 4.886L1.723 35.702C1.121 38.4.351 45.118 8.561 45.118l55.251-4.53c2.61 0 5.211-2.188 5.814-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.785-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.093 6.163H18.637c-2.609 0-5.211 2.188-5.814 4.886l-1.296 5.81-5.226 23.417c-.602 2.7 1.024 4.886 3.633 4.886H68.39c2.609 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886l-.001.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.792 17.433c1.164-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204ZM29.544 17.433c1.165-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m8.931 28.492-2.63 11.784c-.602 2.7 1.024 4.886 3.633 4.886H68.39c2.609 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886h-27.79"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.933 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.246 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.378}
                        x2={79.523}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#3ACEC7" />
                        <stop offset={1} stopColor="#3ACEC7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.361}
                        x2={68.144}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#3ACEC7" />
                        <stop offset={0.223} stopColor="#64D6D2" />
                        <stop offset={0.452} stopColor="#8ADEDC" />
                        <stop offset={0.665} stopColor="#A4E4E3" />
                        <stop offset={0.855} stopColor="#B5E7E7" />
                        <stop offset={1} stopColor="#BBE9E9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.403 1.588H14.099c-2.608 0-5.21 2.188-5.814 4.886L1.762 35.702C1.16 38.4.39 45.118 8.6 45.118l55.252-4.53c2.608 0 5.21-2.188 5.813-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.784-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.132 6.163H18.676c-2.609 0-5.21 2.188-5.814 4.886l-1.296 5.81L6.34 40.275c-.602 2.7 1.024 4.886 3.633 4.886H68.43c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886v.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.83 17.433c1.164-1.038 1.44-2.629.615-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204ZM29.583 17.43c1.165-1.039 1.44-2.63.616-3.554-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.554.824.924 2.436.833 3.6-.205Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M8.97 28.492 6.34 40.276c-.602 2.7 1.024 4.886 3.633 4.886H68.43c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886h-27.79"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.972 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.285 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.418}
                        x2={79.563}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#82E56A" />
                        <stop offset={1} stopColor="#82E56A" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.322}
                        x2={68.183}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#82E56A" />
                        <stop offset={0.276} stopColor="#9EEC8B" />
                        <stop offset={0.557} stopColor="#B5F2A5" />
                        <stop offset={0.808} stopColor="#C4F5B6" />
                        <stop offset={1} stopColor="#C9F7BC" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.442 1.588H14.139c-2.61 0-5.211 2.188-5.814 4.886L1.802 35.702C1.2 38.4.43 45.118 8.64 45.118l55.25-4.53c2.61 0 5.212-2.188 5.815-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.785-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.171 6.163H18.715c-2.609 0-5.21 2.188-5.814 4.886l-1.296 5.81L6.38 40.275c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886v.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.868 17.433c1.165-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204ZM29.622 17.43c1.165-1.039 1.44-2.63.616-3.554-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.554.824.924 2.436.833 3.6-.205Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.01 28.492 6.38 40.276c-.603 2.7 1.023 4.886 3.632 4.886h58.456c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886h-27.79"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.011 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.324 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.458}
                        x2={79.602}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FF7E71" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.283}
                        x2={68.222}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FF7E71" />
                        <stop offset={0.112} stopColor="#FF8D82" />
                        <stop offset={0.374} stopColor="#FFAEA8" />
                        <stop offset={0.618} stopColor="#FFC5C2" />
                        <stop offset={0.834} stopColor="#FFD3D3" />
                        <stop offset={1} stopColor="#FFD9D9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 82,
        height: 47,
        content: (
            <>
                <path
                    fill="url(#object-devices-mobile-a)"
                    d="M72.482 1.588H14.178c-2.61 0-5.211 2.188-5.814 4.886L1.84 35.702C1.239 38.4.469 45.118 8.679 45.118l55.25-4.53c2.61 0 5.212-2.188 5.815-4.886 0 0 9.897-27.188 9.897-28.4 0-1.971-1.785-5.713-7.16-5.713v-.001Z"
                />
                <path
                    fill="url(#object-devices-mobile-b)"
                    d="M77.211 6.163H18.754c-2.609 0-5.21 2.188-5.814 4.886l-1.296 5.81-5.226 23.417c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886v.001Z"
                />
                <path
                    fill="#222731"
                    d="M21.907 17.433c1.165-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204ZM29.661 17.433c1.165-1.038 1.44-2.629.616-3.553-.824-.925-2.436-.834-3.6.204-1.165 1.038-1.44 2.629-.616 3.553.824.925 2.436.834 3.6-.204Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m9.048 28.492-2.63 11.784c-.602 2.7 1.024 4.886 3.633 4.886h58.456c2.61 0 5.211-2.188 5.814-4.886l6.523-29.228c.602-2.699-1.024-4.886-3.633-4.886h-27.79"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.05 45.164c-9.389 0-9.184-5.77-8.582-8.468L8.364 6.474c.602-2.7 3.205-4.886 5.814-4.886h57.347c5.732 0 7.644 4.329 8.961 6.406"
                />
                <defs>
                    <linearGradient
                        id="object-devices-mobile-a"
                        x1={1.497}
                        x2={79.642}
                        y1={23.353}
                        y2={23.353}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FFD100" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={-28.244}
                        x2={68.262}
                        y1={64.423}
                        y2={12.381}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#FFD100" />
                        <stop offset={0.009} stopColor="#FFD102" />
                        <stop offset={0.206} stopColor="#FFDB3C" />
                        <stop offset={0.396} stopColor="#FFE46C" />
                        <stop offset={0.575} stopColor="#FFEB92" />
                        <stop offset={0.741} stopColor="#FFF0AC" />
                        <stop offset={0.887} stopColor="#FFF2BD" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #7': {
        width: 112,
        height: 90,
        content: (
            <>
                <g clipPath="url(#object-devices-mobile-a)">
                    <path
                        fill="url(#object-devices-mobile-b)"
                        d="M109.681 9.088c1 0 .8 1.2-.4 2.5l-62.3 74.6c-1.2 1.4-2.9 2.5-3.8 2.5l-34.8-.6c-1 0-.8-1.2.4-2.5l62.4-74.5c1.2-1.4 2.9-2.5 3.8-2.5l34.7.5Z"
                    />
                    <path
                        fill="#888B8D"
                        d="M69.58 36.287c1.9 0 1.6 2.3-.7 5.1s-5.8 5-7.7 5c-1.9 0-1.6-2.3.7-5.1s5.7-5 7.7-5Z"
                        opacity={0.52}
                    />
                    <path
                        fill="#231F20"
                        d="M76.78 14.588c.9 0 .8 1.1-.3 2.4-1.1 1.3-2.7 2.4-3.6 2.3-.9 0-.8-1.1.3-2.4 1.1-1.2 2.7-2.3 3.6-2.3ZM71.082 21.086c.9 0 .8 1.1-.3 2.4-1.1 1.3-2.7 2.4-3.6 2.3-.9 0-.8-1.1.3-2.4 1-1.3 2.7-2.4 3.6-2.3Z"
                    />
                    <path
                        fill="url(#object-devices-mobile-c)"
                        d="M103.981 2.388c-.1-.1-.3-.2-.5-.2l-34.7-.6c-1 0-2.7 1.1-3.8 2.5l-62.4 74.5c-.9 1.1-1.2 2-.9 2.4l6.2 6.9c-.3-.4 0-1.3.9-2.3l62.4-74.5c1.2-1.4 2.9-2.5 3.8-2.5l34.7.6c.2 0 .4.1.5.2l-6.2-7Z"
                    />
                    <path
                        stroke="#231F20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M109.681 9.088c1 0 .8 1.2-.4 2.5l-62.3 74.6c-1.2 1.4-2.9 2.5-3.8 2.5l-34.8-.6c-1 0-.8-1.2.4-2.5l62.4-74.5c1.2-1.4 2.9-2.5 3.8-2.5l22.8.4"
                    />
                    <path
                        stroke="#231F20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m110.181 9.288-6.2-6.9c-.1-.1-.3-.2-.5-.2l-34.7-.6c-1 0-2.7 1.1-3.8 2.5l-62.4 74.5c-.9 1.1-1.2 2-.9 2.4"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={7.794}
                        x2={110.354}
                        y1={48.607}
                        y2={48.607}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={1} stopColor="#F3F3F3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-c"
                        x1={-25.827}
                        x2={82.811}
                        y1={44.747}
                        y2={44.747}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <clipPath id="object-devices-mobile-a">
                        <path fill="#fff" d="M.58.588h110.8v89.1H.58z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #8': {
        width: 103,
        height: 100,
        content: (
            <>
                <g clipPath="url(#object-devices-mobile-a)">
                    <path
                        fill="url(#object-devices-mobile-b)"
                        d="M96.5 2.288c-.4-.5-.9-.7-1.6-.7H37.3c-1.6 0-3.4 1.3-4 2.9L1 90.188c-.3.9-.2 1.7.2 2.2l4.6 5.6c-.4-.5-.5-1.3-.2-2.2l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.6c.701 0 1.201.2 1.601.7l-4.7-5.6Z"
                    />
                    <path
                        fill="url(#object-devices-mobile-c)"
                        d="M99.502 7.19c1.6 0 2.4 1.3 1.8 2.9l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.5Z"
                    />
                    <path
                        fill="#888B8D"
                        d="M59.102 38.787c3.2 0 4.8 2.6 3.6 5.8-1.2 3.2-4.8 5.8-8 5.8s-4.8-2.6-3.6-5.8c1.2-3.2 4.8-5.8 8-5.8Z"
                        opacity={0.52}
                    />
                    <path
                        fill="#231F20"
                        d="M45.601 14.088c1.2 0 1.9 1 1.4 2.2-.5 1.3-1.9 2.3-3.1 2.3-1.2 0-1.9-1-1.4-2.2.5-1.3 1.8-2.3 3.1-2.3ZM42.4 21.588c1.2 0 1.9 1 1.4 2.2-.5 1.2-1.8 2.2-3.1 2.2-1.2 0-1.9-1-1.4-2.2.5-1.2 1.8-2.2 3.1-2.2Z"
                    />
                    <path
                        stroke="#231F20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="m101.1 7.888-4.6-5.6c-.4-.5-.9-.7-1.6-.7H37.3c-1.6 0-3.4 1.3-4 2.9L1 90.188"
                    />
                    <path
                        stroke="#231F20"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M101.1 7.89c.4.5.5 1.3.2 2.2L69 95.79c-.6 1.6-2.4 2.9-4 2.9H7.4c-1.6 0-2.4-1.3-1.8-2.9L38 10.09c.6-1.6 2.4-2.9 4-2.9h38.7"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="object-devices-mobile-b"
                        x1={0.801}
                        x2={101.107}
                        y1={49.805}
                        y2={49.805}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-mobile-c"
                        x1={5.438}
                        x2={101.517}
                        y1={52.934}
                        y2={52.934}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={1} stopColor="#F3F3F3" />
                    </linearGradient>
                    <clipPath id="object-devices-mobile-a">
                        <path fill="#fff" d="M0 .588h102.5v99.1H0z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-mobile"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
