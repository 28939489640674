import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-2-cycling-1-left-a)">
        <path
            d="M550.369 455.122c-11.316-16.127-23.687-28.539-37.066-40.402a178.404 178.404 0 0 0-27.862-21.093 259.724 259.724 0 0 0-31.64-17.187 175.237 175.237 0 0 0-18.744-6.931c-23.831-8.154-51.341 3.155-62.38 25.821-14.535 28.308.666 62.345 30.133 72.868l3.665 1.623c7.346 3.138 14.973 6.062 22.42 8.766 9.1 3.178 17.38 6.032 27.022 8.522 7.772 1.994 16.444 3.962 24.259 5.91-.427.695-.85 1.393-1.271 2.093l-.014-.006c-9.27 15.753-17.242 31.834-20.745 47.724-1.785 7.451-2.881 15.04-4.319 22.538-4.055 20.699-9.255 41.698-12.761 62.408l38.128 17.955c16.653-36.214 39.804-88.222 55.084-115.59 5.595-10.023 24.869-38.84 24.869-38.84 5.37-10.374-1.247-27.427-8.778-36.179Z"
            fill="#001489"
        />
        <path
            d="m444.185 611.147 40.703 22.15c2.226-4.871 4.526-9.914 6.877-15.06l-44.029-23.96a1276.422 1276.422 0 0 0-3.551 16.87Z"
            fill="url(#man-34-25-pants-2-cycling-1-left-b)"
        />
        <path
            d="M503.796 592.05c8.136-17.56 16.296-34.755 23.457-48.713l-57.599-31.345c-5.125 10.266-9.249 20.592-11.508 30.838-1.749 7.297-2.838 14.728-4.232 22.075l49.882 27.145Z"
            fill="url(#man-34-25-pants-2-cycling-1-left-c)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-cycling-1-left-b"
                x1={444.185}
                y1={613.787}
                x2={491.765}
                y2={613.787}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-cycling-1-left-c"
                x1={453.914}
                y1={552.021}
                x2={527.253}
                y2={552.021}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <clipPath id="man-34-25-pants-2-cycling-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .094)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
