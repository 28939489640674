import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7354)"
            d="M494.768 223.336a109.59 109.59 0 00-20.026-15.328 31.03 31.03 0 012.394 13.894 65.926 65.926 0 01-1.451 10.763l2.169 4.754a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7354)"
            d="M503.362 224.732c-5.143-1.464-10.461-2.977-15.625-4.657l-.128-.041a27.494 27.494 0 00-29.496 8.356 27.514 27.514 0 00-3.434 30.464 27.488 27.488 0 0016.186 13.569c5.159 1.649 10.35 3.523 15.369 5.335l3.58 1.29 18.676-52.85s-3.758-1.076-5.128-1.466z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7354"
                x1="489.831"
                x2="439.517"
                y1="208.877"
                y2="297.793"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7354"
                x1="496.883"
                x2="446.57"
                y1="212.87"
                y2="301.785"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
