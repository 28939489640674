import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-4-walk-1">
            <path
                d="M602.377 849.918c-21.797 9.111-37.681 5.397-39.888 4.804l-24.638 4.688a9.02 9.02 0 0 1-7.04-1.568 9.013 9.013 0 0 1-3.684-6.191c-1.989-15.268-3.154-22.408-4.025-25.162l32.847-7.739c.935.549 24.036 15.416 40.206 16.759 9.639.8 14.852 1.807 15.403 4.7.638 3.355-2.88 7.075-9.181 9.709Z"
                fill="#E2E2E2"
            />
            <path
                d="M561.032 819.63a3.116 3.116 0 0 1 2.598.434c7.9 5.406 21.666 14.187 32.551 14.441 13.786.323 15.941 3.301 16.362 5.514.743 3.904-2.913 7.948-9.777 10.818-14.731 6.157-26.839 6.527-33.944 5.91a60.032 60.032 0 0 0-16.407.907l-14.066 2.676a10.504 10.504 0 0 1-7.851-1.491 10.005 10.005 0 0 1-4.36-7.062c-.863-6.62-1.646-12.227-2.325-16.671a3.206 3.206 0 0 1 .289-1.979 3.206 3.206 0 0 1 1.403-1.425c3.906-1.877 9.083 5.531 16.289 3.601 7.663-2.053 15.438-14.601 19.238-15.673Z"
                fill="#63666A"
            />
            <path
                d="M612.625 841.062a16.37 16.37 0 0 1-5.659 4.84c-8.889 5.189-34.801 5.732-46.569 6.538-10.693.732-19.247-5.745-34.835-5.009.188 1.397.379 2.84.575 4.343a10.01 10.01 0 0 0 4.361 7.063 10.498 10.498 0 0 0 7.851 1.49l14.065-2.675a60.042 60.042 0 0 1 16.408-.908c7.105.618 19.212.248 33.943-5.91 6.253-2.613 9.831-6.202 9.86-9.772ZM336.284 858.508a21.236 21.236 0 0 1-18.056-9.96l-17.576-27.872a9.143 9.143 0 0 1-.015-9.685l7.995-12.757s10.18 6.75 13.897 7.663c9.868 2.422 16.79 1.337 16.79 1.337l10.883 29.476a14.097 14.097 0 0 0 8.56 8.405l3.385 1.19a6.298 6.298 0 0 1 3.97 7.6 6.293 6.293 0 0 1-6.052 4.603h-23.781Z"
                fill="#E2E2E2"
            />
            <path
                d="M336.281 858.508a21.236 21.236 0 0 1-18.056-9.96l-17.576-27.872a9.102 9.102 0 0 1-.015-9.685l4.966-7.929 6.177 5.996a12.27 12.27 0 0 0 11.132 3.186l8.437-1.756c6.18-.868 9.487 1.084 11.168 5.53l1.482 4.012 6.202 16.68a14.14 14.14 0 0 0 8.558 8.405l3.149 1.106a6.472 6.472 0 0 1 4.426 6.366 6.293 6.293 0 0 1-6.27 5.921h-23.78Z"
                fill="#63666A"
            />
            <path
                d="M335.128 859.956a21.247 21.247 0 0 1-18.056-9.96l-17.576-27.873a9.143 9.143 0 0 1-.015-9.684s4.163-.269 8.121 6.617c5.004 8.705 8.15 28.12 22.988 34.699 16.753 7.427 31.572-.235 34.997 1.597-.751 2.711-3.867 4.604-6.679 4.604h-23.78Z"
                fill="#E2E2E2"
            />
        </g>
    );
}
