import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#clip0_11323_7734)">
            <path
                fill="url(#paint0_linear_11323_7734)"
                d="M524.444 279.949c-20.409-24.152-26.325-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.778 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.916-19.07 11.051-19.209 11.136a62.74 62.74 0 00-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.883 0 179.194.874 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a537.005 537.005 0 01-2.685-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M503.342 255.028c4.625 6.606 10.958 14.469 20.339 25.569 7.687 9.097 10.643 17.402 8.786 24.686-2.389 9.368-12.232 15.141-20.068 18.335l-.893.364.333.906a525.298 525.298 0 002.69 7.124c7.828 20.446 18.534 48.403 9.414 97.344-.477.002-1.245.004-2.476.004-13.212 0-63.14-.233-107.191-.439-31.908-.149-59.698-.279-67.585-.293 2.534-18.837 7.936-40.755 27.525-61.609 8.654-9.212 11.179-22.949 6.59-35.848l-18.824-52.893a61.743 61.743 0 0126.093-73.245c.011-.006.096-.061.245-.161a176.831 176.831 0 0118.233-10.731M464.055 202.156a143.834 143.834 0 0113.155 7.829"
            ></path>
            <g filter="url(#filter0_f_11323_7734)">
                <path
                    fill="url(#paint1_radial_11323_7734)"
                    d="M410.676 290.923l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.973 30.973 0 0012.048-48.825l-6.917-10.635"
                ></path>
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M407.48 194.531c11.037 8.027 21.707 14.536 31.632 17.961l24.553 18.979 8-19.516 7.309 6.637"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M439.11 212.493l-9.445 13.073-20.923-22.628M498.77 329.438c.701 5.497 2.455 10.966 3.87 16.409a138.373 138.373 0 01-3.191 80.043M468.188 235.766c10.048 9.026 20.173 21.446 27.519 31.822 7.347 10.376 11.804 18.941 9.682 30.569-1.445 7.92-5.864 15.449-6.755 23.414"
            ></path>
            <path
                fill="url(#paint2_linear_11323_7734)"
                d="M387.933 203.215l19.02-10.723 34.588 83.47a96.207 96.207 0 017.277 36.703v139.753a20 20 0 01-20 20h-84.916V435.41c0-40.411 29.574-61.975 29.574-88.19 0-39.308-18.173-58.477-18.173-80.958.001-45.552 32.63-63.047 32.63-63.047z"
            ></path>
            <path
                fill="#888B8D"
                d="M407.841 194.305c-16.336 17.315 38.232 95.539 38.232 95.539a167.965 167.965 0 00-11.188-30.883c-7.61-15.954-27.044-64.656-27.044-64.656z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M379.625 408.047h43.806"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11323_7734"
                width="91.355"
                height="77.898"
                x="402.676"
                y="251.07"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7734"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7734"
                x1="344.968"
                x2="532.553"
                y1="332.859"
                y2="299.986"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11323_7734"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.789 -1072.072) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#E2E2E2" stopOpacity="0"></stop>
                <stop offset="0.587" stopColor="#E2E2E2" stopOpacity="0.33"></stop>
                <stop offset="0.763" stopColor="#E2E2E2" stopOpacity="0.688"></stop>
                <stop offset="0.888" stopColor="#E2E2E2" stopOpacity="0.913"></stop>
                <stop offset="0.949" stopColor="#E2E2E2"></stop>
            </radialGradient>
            <linearGradient
                id="paint2_linear_11323_7734"
                x1="343.903"
                x2="448.818"
                y1="332.455"
                y2="332.455"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <clipPath id="clip0_11323_7734">
                <path
                    fill="#fff"
                    d="M0 0H900V900H0z"
                    transform="translate(.71 .21)"
                ></path>
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
