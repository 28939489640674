import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6890)"
            d="M494.78 223.228a2.7 2.7 0 01.822 2.482c-.805 4.666-13.808 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.14 108.14 0 0121.852 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6890)"
            d="M511.15 245.484c-2.547-4.043-5.433-8.626-8.066-13.178l-.071-.122a27.478 27.478 0 00-16.772-12.719 27.497 27.497 0 00-20.852 2.876 27.497 27.497 0 00-9.844 37.624c2.656 4.537 5.186 9.26 7.633 13.827l.51.951a91.19 91.19 0 0121.386-17.957 93.204 93.204 0 0126.624-10.432l-.548-.87z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6890"
                x1="494.171"
                x2="415.811"
                y1="212.281"
                y2="256.522"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6890"
                x1="502.535"
                x2="424.174"
                y1="227.088"
                y2="271.329"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
