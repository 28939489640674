import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-option-6">
        <path
            fill="url(#man-34-25-tshirt-arm-right-option-6-a)"
            d="M415.743 283.755c-6.786-4.233-13.565-8.78-20.728-13.905a189.122 189.122 0 0 1-19.359-15.57 861.921 861.921 0 0 1-5.461-5.055c-4.141-3.85-8.422-7.831-12.766-11.581a31.795 31.795 0 0 1-11.007-21.879 31.796 31.796 0 0 1 7.689-23.254 31.99 31.99 0 0 1 24.24-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.325 3.77 8.873 7.447 13.271 11.004 1.928 1.559 3.852 3.122 5.772 4.689a188.993 188.993 0 0 1 18.153 16.969c6.083 6.366 11.543 12.435 16.686 18.548-9.093 4.316-32.515 25.569-37.507 43.244Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-option-6-a"
                x1={359.587}
                x2={430.286}
                y1={195.981}
                y2={259.307}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
