import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-1-walk-2">
            <path
                d="M400.104 830.867c-6.574-4.359-12.373-11.942-16.875-19.236-18.356 4.335-36.627-5.966-45.259-14.615a925.373 925.373 0 0 1-4.875 10.715 9.002 9.002 0 0 0-.182 7.202 9.022 9.022 0 0 0 5.12 5.079l23.345 9.166c1.552 1.679 13.011 13.288 36.311 17.198 1.498.26 3.015.395 4.536.405 4.375 0 7.459-1.314 8.406-3.726 1.076-2.742-2.465-6.842-10.527-12.188Z"
                fill="url(#female-34-25-shoes-1-walk-2-a)"
            />
            <path
                d="M488.41 848.379a49.76 49.76 0 0 1-5.924-3.13c-3.668-2.47-8.118-8.233-11.673-13.413-10.535 0-18.126 13.311-37.968 13.311-.096 1.798-.202 3.687-.318 5.667a9.012 9.012 0 0 0 2.463 6.77 9.018 9.018 0 0 0 6.622 2.856h43.74c7.341 0 11.551-1.975 11.551-5.418-.001-2.601-3.936-4.474-8.493-6.643Z"
                fill="url(#female-34-25-shoes-1-walk-2-b)"
            />
            <path
                d="M434.988 857.578a9.01 9.01 0 0 0 3.014 2.118 9.01 9.01 0 0 0 3.609.738h43.74c7.34 0 11.55-1.975 11.55-5.418"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-1-walk-2-a"
                    x1={332.25}
                    y1={821.898}
                    x2={410.819}
                    y2={821.898}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-1-walk-2-b"
                    x1={432.511}
                    y1={846.139}
                    x2={496.902}
                    y2={846.139}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
