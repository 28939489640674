import React from 'react';

const SvgComponent = () => {
    return <g id="man-34-25-shorts-2-cycling-1-left">
        <g clipPath="url(#man-34-25-shorts-2-cycling-1-left-a)">
            <path
                d="M513.303 415.166a178.302 178.302 0 0 0-27.862-21.094 259.724 259.724 0 0 0-31.64-17.187 175.3 175.3 0 0 0-18.743-6.931c-23.832-8.153-51.342 3.155-62.381 25.821-14.535 28.308.666 62.345 30.133 72.868l3.666 1.623c7.345 3.138 14.972 6.062 22.42 8.766 9.1 3.179 17.379 6.032 27.021 8.522a643.88 643.88 0 0 0 9.377 2.316l29.259-31.335 30.405-32.563c-3.796-3.705-7.68-7.282-11.655-10.806Z"
                fill="#001489"
            />
        </g>
        <defs>
            <clipPath id="man-34-25-shorts-2-cycling-1-left-a">
                <path fill="#fff" transform="translate(.893 .54)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>;
};

export default SvgComponent;
