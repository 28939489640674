import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={563}
            height={168}
            viewBox="0 0 563 168"
            fill="none"
            id="object-meals-4"
            {...attributes}
        >
            <path
                fill="#fff"
                d="M90.46 135.757v15.616c0 7.833-6.35 14.184-14.184 14.184H15.957c-7.833 0-14.184-6.351-14.184-14.184V2.293H90.46v133.464Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M90.46 135.757v15.616c0 7.833-6.35 14.184-14.184 14.184H15.957c-7.833 0-14.184-6.351-14.184-14.184V2.293H90.46v115.771"
            />
            <path
                fill="#C0F0FF"
                d="M78.047 37.438v113.934c0 .978-.794 1.773-1.773 1.773H15.955a1.774 1.774 0 0 1-1.773-1.773V37.437h63.865Z"
            />
            <path
                fill="url(#object-meals-4-a)"
                d="m208.179 139.743-.664 3.335c-.239.024-.478.048-.708.073-.208.02-.396.044-.584.067a.968.968 0 0 0-.167.018 26.26 26.26 0 0 0-.98.111 92.54 92.54 0 0 0-1.857.212c-.145.012-.292.034-.427.047-.22.028-.43.057-.639.087-.167.018-.333.036-.512.061-.333.036-.669.081-1.004.126-.167.018-.335.045-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.069-.314.04-.647.075-1 .107-.239.024-.478.048-.739.077-.52.049-1.061.105-1.643.162-.29.024-.592.055-.904.085a366.5 366.5 0 0 1-1.994.184c-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.239 12.279-35.016 6.929-2.893-2.676 3.748-11.867 9.682-12.48-10.348 1.071-19.626 3.833-29.872 8.34-15.836 6.973-25.86-2.037-7.814-14.993-9.824 1.002-16.146 6.279-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277 2.184-10.975 23.729-5.086 32.245 1.156-2.51-3.069-5.11-6.349-5.705-10.271-.596-3.921.38-9.14 4.323-9.63 4.014-.497 8.437 3.768 11.005 6.9 6.259 7.639 10.079 14.934 18.771 19.621-11.711-18.879.999-23.364 12.959-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.731 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.319.559.315 1.063.599 1.542.857l.32.186c.118.074.247.141.367.206.129.067.247.141.375.217.32.186.632.36.954.536.155.092.302.172.449.253.182.108.365.215.557.325.12.065.247.141.377.208.504.284 1.036.583 1.635.906.265.155.559.315.863.478a.96.96 0 0 0 .147.08c.165.094.33.188.514.286.202.112.414.225.626.339l-.003-.002Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M70.476 105.442c6.666.917 14.063 3.918 18.274 7.004-2.51-3.069-5.11-6.349-5.705-10.271-.596-3.922.38-9.141 4.323-9.632 4.014-.496 8.437 3.77 11.005 6.901 6.259 7.64 10.079 14.935 18.771 19.622-11.711-18.879.999-23.365 12.959-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.731 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.319.559.315 1.063.599 1.542.857l.32.186c.118.074.247.141.367.206.127.076.257.143.375.217.32.186.632.36.954.536.145.09.292.17.449.253.182.108.365.215.557.325.12.065.249.131.377.208.504.284 1.036.583 1.635.906.275.157.559.315.863.478a.96.96 0 0 0 .147.08c.165.094.34.19.514.286.202.112.414.225.626.339l-.664 3.335c-.239.024-.478.048-.708.073-.198.022-.396.044-.584.067a.968.968 0 0 0-.167.018c-.343.034-.667.071-.98.111a92.54 92.54 0 0 0-1.857.212c-.147.022-.292.034-.427.047-.22.028-.43.057-.639.087a7.543 7.543 0 0 0-.512.061c-.333.036-.669.081-1.004.126-.167.018-.333.036-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.069-.314.04-.647.075-1 .107-.239.024-.488.046-.739.077-.52.049-1.061.105-1.643.162-.29.024-.592.055-.904.085a366.5 366.5 0 0 1-1.994.184c-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.239 12.279-35.016 6.929-2.893-2.676 3.748-11.867 9.682-12.48-10.348 1.071-19.626 3.833-29.872 8.34-15.836 6.973-25.86-2.037-7.814-14.993-9.824 1.002-16.146 6.279-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277"
            />
            <path
                stroke="#81C871"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M205.119 140.867 95.766 119.105"
            />
            <path
                fill="#E4002B"
                d="M178.258 90.727c7.874 0 14.258-6.384 14.258-14.258 0-7.874-6.384-14.258-14.258-14.258-7.874 0-14.258 6.383-14.258 14.258 0 7.874 6.384 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m187.457 68.933 2.937-2.937"
            />
            <path
                fill="#E4002B"
                d="M122.629 92.23c7.875 0 14.258-6.383 14.258-14.257 0-7.875-6.383-14.258-14.258-14.258-7.874 0-14.258 6.383-14.258 14.258 0 7.874 6.384 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m131.828 70.437 2.937-2.937"
            />
            <path
                fill="#E4002B"
                d="M151.145 63.715c7.874 0 14.258-6.383 14.258-14.258 0-7.874-6.384-14.258-14.258-14.258-7.875 0-14.258 6.384-14.258 14.258 0 7.875 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m160.344 41.921 2.937-2.937"
            />
            <g
                stroke="#E4002B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={4}
                clipPath="url(#object-meals-4-b)"
            >
                <path
                    d="m381.492 138.716 10.755-10.755M401.113 138.716l10.755-10.755M420.736 138.716l10.755-10.755M440.359 138.716l10.755-10.755M459.98 138.716l10.755-10.755M479.604 138.716l10.755-10.755M499.227 138.716l10.755-10.755M518.848 138.716l10.755-10.755M401.113 122.72l10.755-10.755M420.736 122.72l10.755-10.755M440.359 122.72l10.755-10.755M459.98 122.72l10.755-10.755M479.604 122.72l10.755-10.755M499.227 122.72l10.755-10.755M420.736 106.724l10.755-10.755M440.359 106.724l10.755-10.755M459.98 106.724l10.755-10.755M479.604 106.724l10.755-10.755M440.359 90.728l10.755-10.755M459.98 90.728l10.755-10.755"
                />
            </g>
            <path
                fill="url(#object-meals-4-c)"
                d="M294.845 73.707c-34.517 0-64.103 17.471-76.554 42.311-6.044 12.059 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M241.158 90.051c-9.909 6.985-17.804 15.867-22.867 25.967-6.044 12.059 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496-8.359 0-16.428 1.025-24.035 2.93M245.658 129.16l36.652-36.652M281.389 129.16l36.653-36.652M317.119 129.16l36.653-36.652"
            />
            <g clipPath="url(#object-meals-4-d)">
                <path
                    fill="url(#object-meals-4-e)"
                    d="M364.11 142.5H38.596c14.692 14.88 34.841 23.272 55.883 23.272H504.95c21.041 0 41.19-8.391 55.883-23.272H364.11Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M478.125 165.772h26.828c21.04 0 41.19-8.391 55.883-23.272H38.596c14.692 14.88 34.841 23.272 55.883 23.272h339.348"
                />
            </g>
            <path
                fill="url(#object-meals-4-f)"
                d="m265.917 139.595-.664 3.335c-.239.024-.478.048-.708.073-.208.02-.396.044-.584.067a.968.968 0 0 0-.167.018 26.26 26.26 0 0 0-.98.111 92.54 92.54 0 0 0-1.857.212c-.145.012-.292.034-.427.047-.22.028-.43.057-.639.087-.167.018-.333.036-.512.061-.333.036-.669.081-1.004.126-.167.018-.335.045-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.069-.314.04-.647.075-1 .107-.239.024-.478.048-.739.077-.52.049-1.061.105-1.643.162-.29.024-.592.055-.904.085a366.5 366.5 0 0 1-1.994.184c-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.239 12.279-35.016 6.929-2.893-2.676 3.748-11.867 9.682-12.48-10.348 1.071-19.626 3.833-29.872 8.34-15.836 6.973-25.86-2.037-7.814-14.993-9.824 1.002-16.146 6.279-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277 2.184-10.975 23.729-5.086 32.245 1.156-2.509-3.069-5.109-6.349-5.705-10.271-.596-3.922.381-9.141 4.323-9.631 4.014-.496 8.437 3.769 11.005 6.901 6.259 7.639 10.079 14.934 18.771 19.621-11.711-18.879.999-23.364 12.959-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.731 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.319.559.315 1.063.599 1.542.857l.32.186c.118.074.247.141.367.206.129.067.247.141.375.217.32.186.632.36.954.536.155.092.302.172.449.253.182.108.365.215.557.325.12.065.247.141.377.208.504.284 1.036.583 1.635.906.265.155.559.315.863.478a.96.96 0 0 0 .147.08c.165.094.33.188.514.286.202.112.414.225.626.339l-.003-.002Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M128.214 105.293c6.666.917 14.063 3.918 18.274 7.004-2.509-3.069-5.109-6.349-5.705-10.271-.596-3.922.381-9.14 4.323-9.63 4.014-.497 8.437 3.768 11.005 6.9 6.259 7.639 10.079 14.934 18.771 19.621-11.711-18.879.999-23.364 12.959-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.731 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.319.559.315 1.063.599 1.542.857l.32.186c.118.074.247.141.367.206.127.076.257.143.375.217.32.186.632.36.954.536.145.09.292.17.449.253.182.108.365.215.557.325.12.065.249.131.377.208.504.284 1.036.583 1.635.906.275.157.559.315.863.478a.96.96 0 0 0 .147.08c.165.094.34.19.514.286.202.112.414.225.626.339l-.664 3.335c-.239.024-.478.048-.708.073-.198.022-.396.044-.584.067a.968.968 0 0 0-.167.018c-.343.034-.667.071-.98.111a92.54 92.54 0 0 0-1.857.212c-.147.022-.292.034-.427.047-.22.028-.43.057-.639.087a7.543 7.543 0 0 0-.512.061c-.333.036-.669.081-1.004.126-.167.018-.333.036-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.069-.314.04-.647.075-1 .107-.239.024-.488.046-.739.077-.52.049-1.061.105-1.643.162-.29.024-.592.055-.904.085a366.5 366.5 0 0 1-1.994.184c-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.239 12.279-35.016 6.929-2.893-2.676 3.748-11.867 9.682-12.48-10.348 1.071-19.626 3.833-29.872 8.34-15.836 6.973-25.86-2.037-7.814-14.993-9.824 1.002-16.146 6.279-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277"
            />
            <path
                stroke="#81C871"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m262.857 140.719-109.353-21.762"
            />
            <defs>
                <linearGradient
                    id="object-meals-4-a"
                    x1={56.508}
                    x2={207.85}
                    y1={111.293}
                    y2={141.41}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-4-c"
                    x1={280.048}
                    x2={375.779}
                    y1={63.845}
                    y2={185.015}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-4-e"
                    x1={38.596}
                    x2={560.834}
                    y1={154.136}
                    y2={154.136}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-4-f"
                    x1={114.246}
                    x2={265.589}
                    y1={111.144}
                    y2={141.262}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <clipPath id="object-meals-4-b">
                    <path fill="#fff" d="M379.492 77.973h152.111v62.744H379.492z" />
                </clipPath>
                <clipPath id="object-meals-4-d">
                    <path fill="#fff" d="M37.215 141.137h525v26h-525z" />
                </clipPath>
            </defs>
        </svg>
    );
}
