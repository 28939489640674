import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-2-stand-2">
            <path
                d="M355.482 775.969c-1.716.856-3.549 1.757-5.45 2.693l-3.81 1.877a9.123 9.123 0 0 0-2.901 2.265 9.118 9.118 0 0 0-1.766 3.229 9.106 9.106 0 0 0 .798 7.164l12.412 21.794c.154 2.28 1.782 18.511 17.461 36.184 3.502 3.949 7.08 6.114 9.895 6.114a4.519 4.519 0 0 0 2.262-.571c2.559-1.458 2.378-6.873-.539-16.096a46.73 46.73 0 0 1-1.84-13.668c-14.691-13.716-22.987-34.047-26.522-50.985Z"
                fill="#FFD109"
            />
            <path
                d="M341.555 786.031a9.013 9.013 0 0 0 .796 7.168l12.413 21.793c.154 2.281 1.782 18.512 17.46 36.185 3.503 3.948 7.08 6.114 9.895 6.114"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M455.029 845.23a49.614 49.614 0 0 1-5.924-3.13c-3.668-2.47-8.118-8.232-11.673-13.412-10.535 0-18.126 13.311-37.968 13.311-.096 1.798-.202 3.687-.318 5.667a9.1 9.1 0 0 0 5.481 8.883 9.08 9.08 0 0 0 3.604.743h43.74c7.34 0 11.55-1.975 11.55-5.418 0-2.601-3.935-4.475-8.492-6.644Z"
                fill="url(#female-34-25-shoes-2-stand-2-a)"
            />
            <path
                d="M401.607 854.437a9.026 9.026 0 0 0 6.623 2.856h43.739c7.341 0 11.551-1.975 11.551-5.418"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-2-stand-2-a"
                    x1={399.13}
                    y1={842.99}
                    x2={463.521}
                    y2={842.99}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </g>
    );
}
