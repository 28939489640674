import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-right-option-4">
        <path
            fill="url(#man-34-25-doctors-coat-arm-right-option-4-a)"
            d="M387.027 325.93a23.66 23.66 0 0 1-22.76-17.725l-.062.036-.433-1.066a205.405 205.405 0 0 1-7.388-22.368c-1.96-7.157-3.698-14.526-5.314-22.532a162.271 162.271 0 0 1-3.173-22.805c-.157-2.293-.297-4.59-.437-6.888-.318-5.222-.647-10.62-1.182-15.9a32.039 32.039 0 0 1 28.616-35.06 32.85 32.85 0 0 1 3.259-.165 31.884 31.884 0 0 1 21.462 8.246 31.885 31.885 0 0 1 10.339 20.535l.017.17c.506 5.286 1.236 10.648 1.942 15.833.311 2.279.622 4.558.915 6.839a161.97 161.97 0 0 1 1.334 22.991 351.497 351.497 0 0 1-.488 17.685 601.618 601.618 0 0 1 22.485-3.391c1.468-.183 2.926-.373 4.385-.562 2.959-.384 6.02-.78 9.045-1.115 4.297-.51 8.883-1.045 13.467-1.476 1.6-.155 3.2-.315 4.803-.48 2.892-.293 5.783-.586 8.681-.842 1.498-.11 2.978-.23 4.459-.351 1.209-.098 2.435-.197 3.669-.291.504 11.328 3.54 25.497 7.253 33.597-1.327.443-2.648.874-3.949 1.299-1.419.464-2.837.927-4.253 1.401-2.786.887-5.565 1.733-8.344 2.58-1.541.469-3.082.941-4.623 1.414-4.387 1.371-8.826 2.65-12.995 3.842-2.914.857-5.89 1.673-8.769 2.462-1.418.389-2.835.78-4.253 1.173-9.581 2.595-18.161 4.726-26.236 6.516l-13.168 2.883-13.15 2.942a23.64 23.64 0 0 1-5.154.573Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m409.955 210.238.017.17c.506 5.287 1.235 10.648 1.942 15.833.311 2.279.622 4.558.915 6.839a161.978 161.978 0 0 1 1.334 22.991 352.757 352.757 0 0 1-.488 17.686c7.112-1.218 14.482-2.329 22.486-3.391 1.467-.184 2.925-.373 4.384-.562 2.959-.384 6.02-.78 9.045-1.116 4.297-.51 8.883-1.045 13.467-1.475a897.6 897.6 0 0 0 4.804-.481c2.892-.292 5.782-.585 8.68-.842 1.498-.11 2.978-.23 4.459-.35 1.209-.098 2.436-.197 3.67-.291.504 11.327 3.539 25.496 7.252 33.596-1.327.443-2.648.875-3.949 1.3-1.419.463-2.837.926-4.253 1.4a668.101 668.101 0 0 1-8.344 2.58c-1.541.47-3.082.941-4.623 1.415-4.387 1.371-8.826 2.65-12.995 3.841-2.914.857-5.89 1.674-8.769 2.463-1.418.388-2.836.779-4.253 1.173-9.581 2.595-18.161 4.726-26.236 6.516l-13.168 2.882-13.15 2.943a23.64 23.64 0 0 1-5.154.572 23.666 23.666 0 0 1-22.761-17.724l-.062.036-.434-1.067a205.544 205.544 0 0 1-7.387-22.368c-1.96-7.157-3.697-14.525-5.313-22.532a162.4 162.4 0 0 1-3.174-22.805 975.09 975.09 0 0 1-.436-6.888c-.318-5.221-.647-10.62-1.182-15.899"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-right-option-4-a"
                x1={453.376}
                x2={324.99}
                y1={358.306}
                y2={41.679}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
