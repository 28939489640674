import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-2-stand-2">
            <path
                fill="#2B2729"
                d="M396.14 817.871c.512 4.31-.328 20.872-.957 31.623a9.007 9.007 0 0 0 2.438 6.699 8.993 8.993 0 0 0 6.547 2.821h57.647a4.442 4.442 0 0 0 4.445-4.445 4.435 4.435 0 0 0-2.34-3.915c-3.792-2.032-11.23-6.09-14.957-8.599-8.486-5.714-20.307-24.184-20.307-24.184M374.408 759.254c-3.674 2.311-18.994 8.658-28.975 12.703a8.992 8.992 0 0 0-4.745 12.2l8.723 18.363a42.206 42.206 0 0 1 3.742 12.379 69.647 69.647 0 0 0 6.042 19.853c1.735 3.717 10.099 16.241 14.14 22.224a4.15 4.15 0 0 0 5.556 1.249 4.15 4.15 0 0 0 2.022-3.968c-.321-3.342-.92-9.242-1.572-13.917-2.925-20.988 9.017-51.715 9.017-51.715"
            />
            <path
                fill="#004F71"
                d="M373.042 857.757a5.312 5.312 0 0 1-.176-.241c-4.623-6.726-12.642-18.56-14.4-22.245a70.813 70.813 0 0 1-6.289-20.091 41.09 41.09 0 0 0-3.752-12.054l-8.871-18.292a9.908 9.908 0 0 1-.351-7.918 9.895 9.895 0 0 1 5.513-5.681c4.18-1.733 8.262-3.451 11.803-4.971a1.003 1.003 0 0 1 1.28.455 65.43 65.43 0 0 1 4.232 11.305c.205.668.408 1.335.615 1.999 1.872 6.001 4.275 12.066 9.126 15.743a32.966 32.966 0 0 0 6.204 3.426c1.073.499 2.183 1.014 3.255 1.578a24.324 24.324 0 0 1 2.863 1.769.997.997 0 0 1 .357.475c.068.191.077.398.026.594-2.732 10.284-5.63 24.966-3.926 36.51.701 4.75 1.36 10.777 1.69 13.948a5.164 5.164 0 0 1-2.468 4.944 5.164 5.164 0 0 1-6.731-1.253Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M376.329 859.685a5.148 5.148 0 0 1-3.463-2.171c-4.623-6.726-12.642-18.56-14.4-22.245a70.782 70.782 0 0 1-6.289-20.091 41.106 41.106 0 0 0-3.752-12.055l-8.871-18.292a9.899 9.899 0 0 1-.351-7.917"
            />
            <path
                fill="url(#man-34-25-shoes-2-stand-2-a)"
                d="M461.817 860.012H404.17a9.904 9.904 0 0 1-7.277-3.139 9.9 9.9 0 0 1-2.706-7.44c.193-3.288.404-7.12.591-10.979a1.003 1.003 0 0 1 1.23-.925 15.039 15.039 0 0 0 12.593-2.603 30.45 30.45 0 0 0 3.72-3.648 39.565 39.565 0 0 1 3.132-3.186 21.101 21.101 0 0 1 18.02-4.47c.244.051.46.192.605.395 4.312 6.008 10.493 13.871 15.447 17.207 3.659 2.464 10.887 6.412 14.871 8.546a5.437 5.437 0 0 1 2.867 4.797 5.453 5.453 0 0 1-5.446 5.445Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.024 858.021a5.435 5.435 0 0 1-4.209 1.993h-57.647a9.9 9.9 0 0 1-7.277-3.139"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-2-stand-2-a"
                    x1="394.17"
                    x2="467.263"
                    y1="841.588"
                    y2="841.588"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".001" stopColor="#004F71" />
                    <stop offset=".125" stopColor="#01567A" />
                    <stop offset="1" stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
