import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-2-left">
        <path
            d="M503.038 187.865c6.902 5.658 14.08 10.976 21.139 16.44a219.38 219.38 0 0 1 20.163 17.636c6.427 6.24 12.714 12.651 18.805 19.302a271.466 271.466 0 0 1 17.5 20.901 23.207 23.207 0 0 1-.651 28.506 23.208 23.208 0 0 1-27.794 6.363 271.277 271.277 0 0 1-23.99-12.945c-7.739-4.631-15.282-9.502-22.686-14.544a219.727 219.727 0 0 1-21.326-16.211c-6.771-5.818-13.423-11.781-20.351-17.406a31.001 31.001 0 0 1 16.339-54.901 30.997 30.997 0 0 1 22.741 6.768l.111.091Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M497.138 263.631a216.367 216.367 0 0 1-12.94-10.318c-6.771-5.818-13.423-11.781-20.351-17.406a31.002 31.002 0 0 1-4.527-43.607 31.01 31.01 0 0 1 20.866-11.294 30.997 30.997 0 0 1 22.741 6.768l.112.091c6.902 5.657 14.08 10.976 21.138 16.44a219.372 219.372 0 0 1 20.164 17.636c6.427 6.24 12.713 12.651 18.805 19.301 4.573 5.022 9.068 10.14 13.352 15.516"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.284 186.13c-9.226-7.122-19.957-12.655-32.552-17.169l19.122 31.544s17.866-10.95 13.43-14.375Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.732 168.961a129.702 129.702 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
