import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6373)"
            d="M360.329 277.189c-.342-1.89-.672-3.783-1.002-5.677-.834-4.778-1.697-9.718-2.738-14.527a27.496 27.496 0 0111.211-28.426 27.51 27.51 0 019.842-4.274 27.48 27.48 0 0128.432 11.206 27.471 27.471 0 014.267 9.848l.032.145c1.022 4.848 2.262 9.74 3.462 14.472l.946 3.747-54.258 14.556-.194-1.07z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6373"
                x1="377.644"
                x2="388.704"
                y1="208.481"
                y2="280.048"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
