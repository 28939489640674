import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={52}
            fill="none"
            id="object-food-blackberry"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.187 10.813a13.796 13.796 0 0 0 9.118 4.04 13.79 13.79 0 0 0-4.038-9.119 13.798 13.798 0 0 0-9.119-4.039 13.79 13.79 0 0 0 4.04 9.118ZM28.425 10.813a13.795 13.795 0 0 1-9.118 4.04 13.79 13.79 0 0 1 4.039-9.119 13.797 13.797 0 0 1 9.118-4.039 13.79 13.79 0 0 1-4.04 9.118Z"
            />
            <path
                fill="#001489"
                d="M14.65 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M23.96 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM5.338 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M14.65 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M23.96 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M33.273 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.994 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M19.306 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M28.617 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.994 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M19.306 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#001489"
                d="M28.617 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
        </svg>
    );
}
