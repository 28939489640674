import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-4-cycling-1-left-a)">
        <path
            d="M550.369 455.122c-11.316-16.127-23.688-28.539-37.067-40.402a178.35 178.35 0 0 0-27.861-21.093A259.736 259.736 0 0 0 453.8 376.44a175.14 175.14 0 0 0-18.743-6.931c-23.831-8.154-51.341 3.155-62.38 25.821-14.535 28.308.666 62.345 30.133 72.868l3.665 1.623c7.346 3.138 14.973 6.062 22.42 8.766 9.1 3.178 17.38 6.032 27.022 8.522 7.772 1.994 16.444 3.962 24.258 5.91-.426.695-.85 1.393-1.27 2.093l-.014-.006c-9.27 15.753-17.242 31.834-20.745 47.724-1.786 7.45-2.881 15.04-4.319 22.538-4.056 20.699-9.256 41.698-12.761 62.408l38.127 17.955c16.654-36.214 39.805-88.222 55.085-115.59 5.595-10.023 24.868-38.84 24.868-38.84 5.371-10.374-1.246-27.427-8.777-36.179Z"
            fill="#001489"
        />
        <defs>
            <clipPath id="man-34-25-pants-4-cycling-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .094)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
