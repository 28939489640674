import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-normal">
        <path
            fill="url(#man-34-25-tshirt-2-normal-a)"
            d="m345.723 452.535-.109-.876c-2.915-23.317 1.063-43.872 4.91-63.749 4.391-22.693 8.54-44.129 2.332-67.925a328.329 328.329 0 0 1-10.89-74.739l-.541-22.589c-.375-15.628 6.461-30.813 18.524-40.319 25.43-20.041 49.756-28.119 49.756-28.119s1.916.108 9.777 9.759c1.899 2.331 7.57 9.271 9.486 10.981 8.467 7.555 16.208 11.228 23.664 11.227 1.34 0 2.678-.12 3.997-.359a9.271 9.271 0 0 0 5.235-2.763c4.714-5.098 3.377-16.737 3.377-16.737a63.077 63.077 0 0 1 8.248 5.997c25.56 21.883 46.641 61.31 52.534 82.777 2.136 7.783 2.361 14.435.667 19.773-3.297 10.393-3.012 21.138.803 30.258a107.835 107.835 0 0 1 8.85 40.646c.291 11.564.003 104.819 0 105.76l-.003.997H345.723Z"
        />
        <path
            fill="url(#man-34-25-tshirt-2-normal-b)"
            d="M347.94 298.451h177.456a45.687 45.687 0 0 1 1.294-23.58c1.304-4.112 1.458-9.01.49-14.598H342.723a325.465 325.465 0 0 0 5.217 38.178Z"
        />
        <path
            fill="url(#man-34-25-tshirt-2-normal-c)"
            d="M522.89 245.678c-8.54-25.965-26.241-54.048-48.407-73.026a58.262 58.262 0 0 0-9.242-6.325s.761 6.641-.897 12.087a11.075 11.075 0 0 1-2.48 4.65 9.21 9.21 0 0 1-5.235 2.763 22.513 22.513 0 0 1-3.997.359c-7.456.001-15.198-3.672-23.664-11.227-1.916-1.71-7.587-8.65-9.486-10.981-7.861-9.65-9.777-9.759-9.777-9.759s-24.326 8.078-49.756 28.118c-12.063 9.507-18.899 24.692-18.524 40.32l.541 22.589c.003.143.01.288.014.432h180.91Z"
        />
        <path
            fill="url(#man-34-25-tshirt-2-normal-d)"
            d="M530.418 312.762H351.059c.575 2.404 1.168 4.81 1.797 7.22a95.463 95.463 0 0 1 1.942 9.348H534.74a106.286 106.286 0 0 0-4.322-16.568Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-normal-a"
                x1={341.411}
                x2={536.473}
                y1={303.377}
                y2={303.377}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-normal-b"
                x1={611.121}
                x2={310.109}
                y1={280.863}
                y2={278.26}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-normal-c"
                x1={611.803}
                x2={310.791}
                y1={201.988}
                y2={199.386}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-2-normal-d"
                x1={610.762}
                x2={309.75}
                y1={322.5}
                y2={319.897}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
