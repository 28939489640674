import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M537.376 493.175c-18.132 0-74.687.108-74.687.108l-63-.016c-1.196 0-9.073-1.244-10.241-1.502a52.245 52.245 0 0114.535-103.204l7.252.497c3.029.206 6.035.521 9.031.893l4.49.578 4.469.681c2.986.423 5.944.989 8.912 1.504 2.972.493 5.916 1.13 8.87 1.718 11.795 2.446 23.528 5.215 35.168 8.46 5.817 1.64 11.564 3.635 17.269 5.845a302.873 302.873 0 0117.01 7.176c1.41.634 2.815 1.298 4.216 1.978 1.405.663 2.805 1.351 4.2 2.062 2.8 1.377 5.563 2.939 8.347 4.4a163.393 163.393 0 0116.401 10.292l.87.637a29.874 29.874 0 016.457 41.755c-4.499 6.145-11.473 14.258-19.569 16.138z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M541.039 651.991a739.619 739.619 0 01-7.012-22.947c-1.103-3.83-2.285-7.653-3.322-11.49l-3.17-11.504c-2.139-7.668-4.152-15.346-6.515-22.993l-3.535-11.47-1.775-5.734-1.581-5.753c-4.178-15.343-5.023-30.994-5.323-46.696-.064-7.858.195-15.747.9-23.676a145.357 145.357 0 013.942-23.957l2.574-9.888a24.418 24.418 0 0148.03 5.218l1.8 47.021.89 23.511.76 23.524c.486 15.684 1.062 31.36 1.474 47.051l1.305 47.066a14.725 14.725 0 01-28.723 4.955l-.719-2.238z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M508.533 493.283H405.489c-18.392 0-35.824-6.359-46.834-21.091a61.658 61.658 0 01-.23-.309M447.006 394.43c11.796 2.446 23.528 5.215 35.168 8.46 5.817 1.64 11.564 3.635 17.27 5.845a303.158 303.158 0 0117.009 7.176c1.41.634 2.815 1.298 4.216 1.978 1.405.663 2.805 1.35 4.2 2.062 2.8 1.377 5.563 2.939 8.347 4.4a163.565 163.565 0 0116.401 10.291l.87.637a29.78 29.78 0 0111.857 19.417M539.787 648.079a752.187 752.187 0 01-5.76-19.036c-1.103-3.83-2.285-7.653-3.322-11.49l-3.17-11.504c-2.139-7.668-4.152-15.346-6.515-22.993l-3.535-11.47-1.775-5.734-1.581-5.753c-4.178-15.343-5.023-30.995-5.323-46.696-.064-7.858.194-15.747.9-23.676M564.253 461.102l1.8 47.021.89 23.511.76 23.523c.486 15.685 1.062 31.36 1.473 47.051l1.305 47.067"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M541.238 651.047c.101 3.905-2.023 18.679-3.478 28.262a8.092 8.092 0 004.13 8.321 8.097 8.097 0 003.134.953l6.968.644a133.6 133.6 0 0127.491 5.632 72.343 72.343 0 0029.969 2.45c5.734-.667 10.342-2.816 10.626-5.89.28-3.035-7.493-6.067-12.805-7.731-18.2-5.7-36.895-29.947-36.895-29.947"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M541.238 651.047c.101 3.905-2.023 18.679-3.478 28.262a8.092 8.092 0 004.13 8.321 8.097 8.097 0 003.134.953l6.968.644a133.6 133.6 0 0127.491 5.632 72.343 72.343 0 0029.969 2.45c5.734-.667 10.342-2.816 10.626-5.89.28-3.035-7.493-6.067-12.805-7.731-18.2-5.7-36.895-29.947-36.895-29.947"
        ></path>
    </g>
);

export default SvgComponent;
