import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-up">
        <path
            fill="#001489"
            d="m465.472 167.656 2.653.951c13.052 4.678 23.789 10.343 32.826 17.32a2.671 2.671 0 0 1 1.091 2.34c-.25 4.649-12.214 12.209-14.609 13.678l-.857.525-21.104-34.814Z"
        />
        <path
            fill="#001489"
            d="M507.881 187.677c-6.325-1.437-12.865-2.924-19.239-4.59a32.096 32.096 0 0 0-8.113-1.048 31.982 31.982 0 0 0-30.973 24.05 32.038 32.038 0 0 0 23.038 38.953c9.214 2.367 18.207 5.127 27.285 7.712 17.29 5.038 37.663 8.128 55.345 10.31l1.79-60.579c-15.963-6.068-32.706-11.461-49.133-14.808Z"
        />
    </g>
);

export default SvgComponent;
