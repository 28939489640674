import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-both-arms-option-2-left">
        <path
            d="M502.809 187.865c6.902 5.657 14.08 10.976 21.139 16.44a219.524 219.524 0 0 1 20.164 17.636c6.426 6.24 12.713 12.651 18.804 19.302a271.086 271.086 0 0 1 17.5 20.901 23.206 23.206 0 0 1-.651 28.506 23.202 23.202 0 0 1-27.794 6.363 271.418 271.418 0 0 1-23.99-12.945c-7.738-4.632-15.282-9.502-22.685-14.545a219.57 219.57 0 0 1-21.327-16.21c-6.771-5.818-13.423-11.781-20.351-17.406a31.01 31.01 0 0 1-11.294-20.866 31 31 0 0 1 50.374-27.268l.111.092Z"
            fill="#FCB47F"
        />
        <path
            d="M502.809 187.865c6.902 5.657 14.08 10.976 21.139 16.44a219.524 219.524 0 0 1 20.164 17.636c6.426 6.24 12.713 12.651 18.804 19.302a271.086 271.086 0 0 1 17.5 20.901 23.206 23.206 0 0 1-.651 28.506 23.202 23.202 0 0 1-27.794 6.363 271.418 271.418 0 0 1-23.99-12.945c-7.738-4.632-15.282-9.502-22.685-14.545a219.57 219.57 0 0 1-21.327-16.21c-6.771-5.818-13.423-11.781-20.351-17.406a31.01 31.01 0 0 1-11.294-20.866 31 31 0 0 1 50.374-27.268l.111.092Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-2-left-a)"
        />
        <path
            d="m502.697 187.773.112.092c6.902 5.657 14.08 10.976 21.139 16.44a219.513 219.513 0 0 1 20.164 17.635c6.427 6.24 12.713 12.652 18.805 19.302 4.573 5.022 9.068 10.139 13.352 15.516M496.909 263.631a216.383 216.383 0 0 1-12.94-10.319c-6.771-5.817-13.424-11.781-20.352-17.406"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m465.186 167.07 2.653.952c13.051 4.677 23.788 10.342 32.826 17.319.765.59 5.865 5.433 5.814 6.374-.25 4.648-16.937 8.175-19.333 9.644l-.857.525-21.103-34.814Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-2-left-b)"
        />
        <path
            d="M462.344 165.238a129.713 129.713 0 0 1 32.551 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-2-left-a"
                x1={452.157}
                y1={240.009}
                x2={584.992}
                y2={240.009}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-2-left-b"
                x1={465.186}
                y1={184.477}
                x2={506.48}
                y2={184.477}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
