import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-down">
        <path
            d="M391.656 186.014a30.995 30.995 0 0 0-34.186 4.214 31.009 31.009 0 0 0-7.443 9.535c-3.832 7.612-8.055 15.028-12.109 22.528-10.456 19.208-22.138 51.962-26.522 72.552a23.226 23.226 0 0 0-.467 3.817 22.447 22.447 0 0 0-.229 8.158c3.854 25.145 8.904 67.075 18.136 109.73l37.714-5.689c-1.279-18.703-4.686-39.281-5.648-54.999-.816-11.916-3.136-33.656-4.571-46.295 3.989-4.854 7.718-9.839 11.342-14.878 23.785-33.281 26.552-44.836 37.732-67.043a31 31 0 0 0-13.749-41.63Z"
            fill="url(#man-34-25-shirt-1-arm-right-downa)"
        />
        <path
            d="M405.408 227.648c-11.18 22.208-13.948 33.763-37.732 67.044-3.013 4.189-6.096 8.343-9.342 12.414M311.396 294.846c4.385-20.59 16.067-53.344 26.523-72.552 4.054-7.5 8.277-14.916 12.109-22.528M356.25 308.833c1.427 12.518 3.823 34.881 4.655 47.027.961 15.718 4.369 36.296 5.647 54.999l-37.714 5.689c-9.231-42.655-14.281-84.585-18.135-109.73a22.44 22.44 0 0 1 .4-8.927"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-downa"
                x1={215.581}
                y1={406.816}
                x2={503.147}
                y2={197.973}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.644} stopColor="#fff" />
                <stop offset={0.934} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
