import { FEMALE_DEFAULT_SETTINGS, MALE_DEFAULT_SETTINGS } from 'components/CharacterBuilder';

import rootSettings from 'components/parts';
import hashReducer, { Types } from 'components/hashReducer';
import { MBodyClothes } from 'components/parts/MaleBodySettings';
import { FBodyClothes } from 'components/parts/FemaleBodySettings';
import skinColor from 'components/parts/SkinColor';

const SKIN_COLOR = Object.keys(skinColor);

function randomInt(max) {
    return Math.floor(Math.random() * max);
}

function getRandomHash(gender) {
    const {
        BODY_CLOTHES,
        POSES,
        RIGHT_ARM_POSITION,
        EMOTIONS,
        LEFT_ARM_POSITION,
        NOSES,
        HAIR_STYLE,
        HAIR_STYLE_COLOR,
        SHOES,
        LEGS,
    } = rootSettings[gender];

    let state = { male: MALE_DEFAULT_SETTINGS, female: FEMALE_DEFAULT_SETTINGS }[gender];
    const BodyClothes = { male: MBodyClothes, female: FBodyClothes }[gender];

    let error = false;

    let result;

    function calc(type, cb, s) {
        return function (value) {
            let _state = hashReducer(s, { type, value });
            result = _state;

            if (!error && typeof cb === 'function') cb(_state);
        };
    }

    function Map(s, keys, type, cb) {
        calc(type, cb, s)(keys[randomInt(keys.length)]);
    }

    Map(state, Object.keys(POSES), Types.position,
        (s) => Map(s,
            BodyClothes.map(bodyWear => {
                return Object.keys(BODY_CLOTHES?.[bodyWear] || {}).includes(s[Types.bodyPosition])
                    ? bodyWear : false;
            }).filter(Boolean), Types.bodyClothes,
            (s) => Map(s, Object.keys(RIGHT_ARM_POSITION), Types.rightArmPosition,
                (s) => Map(s, Object.keys(LEFT_ARM_POSITION), Types.leftArmPosition,
                    (s) => Map(s, SKIN_COLOR, Types.skin,
                        (s) => Map(s, EMOTIONS.map((_, i) => i), Types.emotion,
                            (s) => Map(s, NOSES.map((_, i) => i), Types.nose,
                                (s) => Map(s, HAIR_STYLE.map((_, i) => i), Types.hairStyle,
                                    (s) => Map(s, HAIR_STYLE_COLOR, Types.hairStyleColor,
                                        (s) => Map(s,
                                            Object.keys(SHOES || {}).map(id => {
                                                return SHOES?.[id][s[Types.position]] ? id : null;
                                            }).filter(Boolean), Types.shoes,
                                            (s) => Map(s,
                                                Object.keys(LEGS || {}).map((id) => {
                                                    return Object.keys(LEGS?.[id] || {}).includes(s[Types.position])
                                                        ? id : null;
                                                }).filter(Boolean), Types.legsClothes
                                            )
                                        )
                                    )
                                )
                            )
                        )
                    )
                )
            )
        )
    );

    return result;
}

export default getRandomHash;
