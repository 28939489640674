import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-body">
        <g clipPath="url(#male-34-25-body-cycling-a)">
            <path
                d="M312.75 362.567c-2.746-53.065 9.199-198.392 105.403-211.907 26.407 24.131 33.71 24.765 47.76 26.186 1.657 1.923 5.291 6.936 6.892 9.04 19.499 25.627 27.032 70.306 26.531 93.697-.258 12.097-4.057 23.588-16.311 26.201 5.43 30.83 9.982 54.096-15.966 102.713"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M493.353 232.699c4.416 17.597 6.238 34.992 5.984 46.885-.259 12.097-4.058 23.588-16.312 26.201 5.43 30.83 9.982 54.095-15.966 102.713M312.75 362.569c-4.405-117.766 41.052-202.374 100.031-211.35"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M483.124 306.019c-1.656-6.94-3.728-14.524-5.84-19.331M384.17 290.305c30.851 5.268 56.521 1.569 72.65-18.641M445.183 392.502a13.775 13.775 0 0 1 10.247.889"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="male-34-25-body-cycling-a">
                <path
                    fill="#fff"
                    transform="translate(.245 .117)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
