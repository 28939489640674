import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-down">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-down-a)"
            d="m316.951 268.594.272-.813a427.078 427.078 0 0 1 8.287-22.63 202.392 202.392 0 0 1 10.785-23.335c1.218-2.253 2.453-4.502 3.687-6.75 2.82-5.133 5.735-10.44 8.407-15.749a31.855 31.855 0 0 1 28.606-17.618 31.673 31.673 0 0 1 14.366 3.425 31.801 31.801 0 0 1 15.996 18.547 31.77 31.77 0 0 1 1.545 12.393 31.773 31.773 0 0 1-3.348 12.032l-.071.138c-2.696 5.293-5.249 10.778-7.716 16.083a1853.315 1853.315 0 0 1-3.269 6.984 202.54 202.54 0 0 1-12.434 22.504 427.58 427.58 0 0 1-13.564 20.386l-.502.708-51.047-26.305Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-down-a"
                x1={400.006}
                x2={342.134}
                y1={349.905}
                y2={175.956}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
