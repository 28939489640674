import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#E2E2E2"
                d="M609.761 698.819c-23.445 2.915-37.745-4.933-39.712-6.098l-24.991-2.109a9.106 9.106 0 01-6.356-3.4 9.081 9.081 0 01-1.887-6.956c1.887-14.379 2.888-22.467 2.888-25.318l34.401 1.146c.752.781 18.324 21.542 33.537 27.183 9.069 3.363 13.82 5.735 13.572 8.669-.287 3.403-4.675 6.04-11.452 6.883z"
            ></path>
            <path
                fill="url(#paint0_linear_11369_7387)"
                d="M578.079 658.531a3.106 3.106 0 012.386 1.116c6.155 7.332 17.053 19.49 27.469 22.662 13.192 4.017 14.466 7.466 14.277 9.71-.334 3.96-4.942 6.873-12.326 7.791-15.844 1.97-27.605-.929-34.282-3.435a60.044 60.044 0 00-16.048-3.537l-14.266-1.205a10.497 10.497 0 01-7.161-3.547 10 10 0 01-2.302-7.974c.949-6.608 1.703-12.219 2.243-16.683a3.214 3.214 0 012.546-2.823c4.266-.757 7.261 7.769 14.72 7.848 7.933.084 18.796-9.913 22.744-9.923z"
            ></path>
            <path
                fill="#E2E2E2"
                d="M622.009 693.047a16.359 16.359 0 01-6.752 3.14c-9.956 2.608-35.06-3.836-46.612-6.224-10.495-2.17-16.993-10.708-32.204-14.19a1032.74 1032.74 0 01-.615 4.338 10.012 10.012 0 002.301 7.975 10.5 10.5 0 007.162 3.546l14.266 1.205a60.007 60.007 0 0116.048 3.538c6.677 2.505 18.438 5.404 34.282 3.434 6.726-.837 11.137-3.331 12.124-6.762z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11369_7387"
                    x1="578.973"
                    x2="578.973"
                    y1="658.531"
                    y2="700.46"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0147A7"></stop>
                    <stop offset="1" stopColor="#001489"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
