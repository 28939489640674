import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-1-cycling-1-left">
        <path
            d="M501.197 201.371a131.614 131.614 0 0 0-35.07-32.516l6.968 50.433s31.458-13.428 28.102-17.917Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-cycling-1-left-a)"
        />
        <path
            d="M504.672 204.793c-6.722-8.346-15.923-13.434-26.653-13.434a31.855 31.855 0 0 0-17.743 5.392 32.038 32.038 0 0 0-8.84 44.384c3.242 4.854 6.363 9.917 9.382 14.813 1.325 2.15 2.654 4.296 3.987 6.437a195.369 195.369 0 0 0 14.742 20.524 453.356 453.356 0 0 0 2.727 3.349l54.061-30.935c-5.638-12.687-21.999-38.532-31.663-50.53Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-cycling-1-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-cycling-1-left-a"
                x1={483.52}
                y1={171.806}
                x2={486.99}
                y2={349.637}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-cycling-1-left-b"
                x1={489.872}
                y1={171.681}
                x2={493.342}
                y2={349.513}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
