import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7091)"
            d="M400.796 274.774l-49.323-26.261s.447-.727.593-.961l.943-1.519c2.871-4.619 5.839-9.395 8.579-14.184a27.534 27.534 0 0137.526-10.217 27.535 27.535 0 0110.216 37.527l-.066.114c-2.748 4.749-8.332 15.237-8.468 15.501z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7091"
                x1="361.276"
                x2="403.782"
                y1="191.415"
                y2="298.981"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
