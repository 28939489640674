import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-3-walk-2">
            <path
                fill="#2B2729"
                d="M328.849 861.124a23.476 23.476 0 0 1-19.967-11.015l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.532-15.219 3.914-13.346 1.34-.644 29.495 12.145.539 1.316-3.429 8.048 13.483 36.256a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.864 6.864 0 0 1 2.152 11.724 6.865 6.865 0 0 1-4.427 1.619h-26.424ZM538.658 861.123a10.058 10.058 0 0 1-9.871-8.484c-2.587-16.915-4.582-27.909-5.619-30.956l-4.73-11.709.717-1.352 33.235-7.102 1.172.712 3.218 11.723c3.014 2.027 28.041 18.507 47.459 20.353 4.775.453 10.787 1.254 13.947 1.693a5.151 5.151 0 0 1 3.684 7.796 5.161 5.161 0 0 1-2.575 2.131c-7.635 2.882-21.041 7.84-25.042 8.665a74.849 74.849 0 0 1-15.058 1.586c-2.047 0-4.05-.095-5.953-.281a38.874 38.874 0 0 0-3.799-.185 42.36 42.36 0 0 0-8.803.946l-19.882 4.241c-.69.148-1.394.223-2.1.223Z"
            />
            <path
                fill="#63666A"
                d="M620.027 845.066c-.09.042-.183.082-.278.119-7.692 3.043-21.2 8.28-25.245 9.171-9.24 2.031-54.229 7.032-54.229 7.032a10.04 10.04 0 0 1-7.938-1.518 10.384 10.384 0 0 1-4.434-6.865c-.804-4.597-1.616-9.092-2.349-12.999a1.015 1.015 0 0 1 .732-1.174 65.376 65.376 0 0 1 12.153-1.568c.708-.048 1.415-.094 2.12-.144 6.37-.458 12.918-1.433 17.633-5.424a33.14 33.14 0 0 0 4.762-5.406c.73-.958 1.485-1.948 2.28-2.888a24.412 24.412 0 0 1 2.384-2.451 1.004 1.004 0 0 1 1.145-.13c9.613 5.166 23.558 11.537 35.394 12.556 4.87.419 11 1.184 14.22 1.602a5.366 5.366 0 0 1 4.369 3.64 5.265 5.265 0 0 1-2.719 6.447Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M622.664 842.23a5.16 5.16 0 0 1-2.915 2.957c-7.692 3.043-21.201 8.281-25.245 9.172-9.241 2.031-54.229 7.032-54.229 7.032a10.06 10.06 0 0 1-7.939-1.519"
            />
            <path
                fill="url(#man-34-25-shoes-3-walk-2-a)"
                d="M328.849 861.122a23.476 23.476 0 0 1-19.967-11.015l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.606-15.336a190.545 190.545 0 0 0 9.246 20.666c2.548 4.717 9.434 6.1 15.831 4.771 9.922-2.06 12.394-2.614 12.394-2.614l7.797 21.069a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.864 6.864 0 0 1 4.493 7.632 6.867 6.867 0 0 1-6.768 5.711h-26.424Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M355.272 861.124h-26.424a23.487 23.487 0 0 1-19.967-11.015l-19.529-30.968"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-3-walk-2-a"
                    x1="287.821"
                    x2="362.138"
                    y1="827.141"
                    y2="827.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".299" stopColor="#63666A" />
                    <stop offset="1" stopColor="#8E8B8F" />
                </linearGradient>
            </defs>
        </g>
    );
}
