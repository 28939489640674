import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6607)"
            d="M465.574 317.031s-9.232-1.054-13.783-1.363c-.684-.042-1.368-.089-2.052-.135-1.254-.085-2.508-.171-3.763-.228l-5.778-.248c-2.626-.143-5.28-.188-7.846-.233-1.25-.022-2.5-.043-3.755-.075a218.53 218.53 0 00-4.72-.051c-5.51 0-10.888.39-16.349.802 1.323-6.913 2.349-13.965 3.334-21.66a173.611 173.611 0 001.682-23.05c.007-2.28-.001-4.561-.01-6.841-.02-5.196-.041-10.568.14-15.837l.004-.14a26.921 26.921 0 00-7.651-19.439 27.33 27.33 0 00-20.017-8.626 27.355 27.355 0 00-27.473 26.703c-.153 5.262-.511 10.616-.858 15.793-.153 2.282-.306 4.564-.442 6.842a169.57 169.57 0 00.147 22.844c.52 8.09 1.233 15.563 2.178 22.847.814 7.6 2.065 15.146 3.749 22.602a31.437 31.437 0 003.334 8.268c3.92 6.397 9.588 10.618 18.299 10.734h.001c.313 0 .627-.008.941-.023l11.503-.548 11.528-.406a221.544 221.544 0 0023.072-2.159c1.237-.187 2.474-.363 3.711-.538 2.542-.362 5.17-.736 7.747-1.2l5.717-.965c1.236-.211 2.47-.451 3.705-.692.672-.131 1.345-.26 2.024-.388a364.284 364.284 0 009.9-2.065l1.133-.253-.417-1.082c-.023-.06-2.32-6.074-2.32-10.892a48.772 48.772 0 012.85-17.002l.535-1.296z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6607"
                x1="414.84"
                x2="408.334"
                y1="216.069"
                y2="356.6"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
