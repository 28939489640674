import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7051)"
            d="M475.685 232.623l.064-.319a63.78 63.78 0 001.387-10.444 32.862 32.862 0 00-1.357-11.177l-.834-2.628 2.325 1.482a106.653 106.653 0 0117.498 13.756 2.718 2.718 0 01.821 2.483c-.805 4.665-13.806 10.891-16.403 12.092l-.913.422-2.588-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7051)"
            d="M555.575 291.953a195.26 195.26 0 01-22.17-2.22c-6.93-1.056-13.964-2.387-21.508-4.067a154.131 154.131 0 01-20.929-6.013c-2.041-.744-4.08-1.504-6.12-2.264-4.633-1.728-9.424-3.514-14.19-5.077a27.519 27.519 0 01-15.966-13.715 27.506 27.506 0 01-1.591-20.988 27.486 27.486 0 0113.714-15.965 27.494 27.494 0 0120.988-1.592l.14.046c4.756 1.589 9.664 3.014 14.411 4.393 2.093.607 4.186 1.215 6.273 1.838a153.905 153.905 0 0120.387 7.667 336.73 336.73 0 0114.062 6.668l.185-1.126c.267-1.628.542-3.311.914-4.976l.746-3.638c.167-.803.364-1.603.56-2.402.104-.425.208-.849.308-1.274.755-3.08 1.445-5.617 2.174-7.992l.404-1.318 1.128.794c3.661 2.58 8.916 4 14.797 4a31.753 31.753 0 0012.454-2.359l1.123-.503.263 1.203a147.76 147.76 0 011.403 7.469c.068.441.14.873.211 1.304.134.812.267 1.625.372 2.436l.466 3.703c.239 1.672.385 3.372.525 5.015.069.802.137 1.603.216 2.405.459 4.956.51 9.942.153 14.907a441.144 441.144 0 01-.356 4.446c-.278 3.347-.565 6.808-.687 10.207a19.424 19.424 0 01-5.918 13.358 19.425 19.425 0 01-13.555 5.452h-.002a10.809 10.809 0 01-1.406-.068l-.176-.017a20.654 20.654 0 01-3.803.263z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7051"
                x1="596.157"
                x2="451.073"
                y1="284.286"
                y2="210.117"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7051"
                x1="596.663"
                x2="451.579"
                y1="283.292"
                y2="209.123"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
