import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-6">
        <path
            d="m500.51 185.993-.027-.02-.005-.004c-9.031-6.966-19.758-12.623-32.793-17.295l-2.654-.951 21.104 34.813.857-.525c2.396-1.469 14.36-9.029 14.61-13.677a2.677 2.677 0 0 0-1.092-2.341Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-6-a)"
        />
        <path
            d="M531.124 214.092c-.64-1.23-1.283-2.448-1.925-3.667-1.31-2.488-2.666-5.06-3.953-7.625-1.847-3.603-3.81-7.456-5.69-11.374a67.408 67.408 0 0 0-.985-.343c-2.348-.803-4.703-1.589-7.058-2.375-5.353-1.788-10.888-3.636-16.251-5.652l-.149-.056a32.004 32.004 0 0 0-41.117 18.907 32.004 32.004 0 0 0 18.907 41.117c5.374 1.988 10.77 4.216 15.989 6.37 2.293.946 4.585 1.892 6.881 2.821a188.284 188.284 0 0 0 23.557 7.799c8.511 2.294 16.444 4.178 24.256 5.76-7.269-16.84-9.756-34.432-10.262-47.578a656.865 656.865 0 0 1-2.2-4.104Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-6-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-6-a"
                x1={490.987}
                y1={155.636}
                x2={429.108}
                y2={337.804}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.076} stopColor="#0197D6" />
                <stop offset={0.371} stopColor="#22ADE0" />
                <stop offset={0.618} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-6-b"
                x1={521.795}
                y1={166.101}
                x2={459.915}
                y2={348.27}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.076} stopColor="#0197D6" />
                <stop offset={0.371} stopColor="#22ADE0" />
                <stop offset={0.618} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
