import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-1-walk-1">
            <path
                d="M412.344 848.828a4.315 4.315 0 0 1-.293-.056c-7.999-1.75-21.987-4.911-25.801-6.414a70.188 70.188 0 0 1-18.376-10.506 40.743 40.743 0 0 0-11.01-6.318l-19.037-7.359a9.96 9.96 0 0 1-5.682-5.633 10.273 10.273 0 0 1 .152-8.087c1.879-4.22 3.695-8.361 5.251-11.974a1.006 1.006 0 0 1 1.25-.56 64.77 64.77 0 0 1 10.849 5.426c.607.352 1.213.704 1.82 1.052 5.485 3.143 11.401 5.96 17.479 5.287 2.358-.35 4.67-.957 6.897-1.809 1.129-.383 2.296-.779 3.468-1.112a24.122 24.122 0 0 1 3.312-.696 1.001 1.001 0 0 1 1.013.525c5.041 9.554 12.974 22.49 22.132 29.867 3.769 3.035 8.38 7.049 10.795 9.172a5.314 5.314 0 0 1 1.577 5.403 5.217 5.217 0 0 1-5.796 3.792Z"
                fill="#EF0025"
            />
            <path
                d="M416.081 847.952a5.107 5.107 0 0 1-4.027.818c-7.999-1.75-21.987-4.912-25.801-6.415a70.188 70.188 0 0 1-18.376-10.506 40.743 40.743 0 0 0-11.01-6.318l-19.037-7.359a9.938 9.938 0 0 1-5.682-5.633"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M524.791 858.647a4.655 4.655 0 0 1-.289.066c-7.962 1.574-79.566 1.556-79.566 1.556a9.868 9.868 0 0 1-7.393-2.872 10.184 10.184 0 0 1-3.062-7.41c.038-4.579.051-9.061.036-12.96a.994.994 0 0 1 .915-1.004 64.126 64.126 0 0 1 12.006.64c.692.08 1.382.16 2.07.236 6.229.687 12.72.906 17.978-2.11a32.398 32.398 0 0 0 5.553-4.373c.875-.794 1.778-1.616 2.713-2.382a23.907 23.907 0 0 1 2.734-1.943.992.992 0 0 1 1.129.076c8.362 6.689 20.69 15.309 31.932 18.389 4.626 1.268 10.405 3.091 13.439 4.066a5.259 5.259 0 0 1 2.399 1.632 5.259 5.259 0 0 1 1.172 2.655 5.169 5.169 0 0 1-3.766 5.738Z"
                fill="url(#man-34-25-shoes-1-walk-1-a)"
            />
            <path
                d="M527.839 856.375a5.064 5.064 0 0 1-3.337 2.337c-7.962 1.574-79.566 1.556-79.566 1.556a9.868 9.868 0 0 1-7.393-2.872"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-1-walk-1-a"
                    x1={434.481}
                    y1={843.113}
                    x2={528.608}
                    y2={843.113}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
