import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 157,
        height: 229,
        content: (
            <>
                <g
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    clipPath="url(#object-kitchen-blender-1-a)"
                >
                    <path 
                        d="M79.227 183.357c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13Z" />
                    <path 
                        d="M89.585 130.832H23.438l-20 96.253h151.578l-20-96.253h-6.148M135.018 34.957v47.329M23.438 14.871V1.611H135.018l-18.841 18.84M42.172 125.843a60.002 60.002 0 0 1-18.734-43.557V34.957M116.178 20.451v61.84c0 21.76-11.88 33.82-26.59 48.54M135.016 34.957H23.438M23.438 14.873H13.29c-6.697 0-12.126 5.429-12.126 12.126v48.126M60.531 207.379h37.39M121.416 199.799v-39.41M37.037 199.799v-39.41" />
                    <path 
                        d="M135.017 82.287c0 33.639-22.51 54.792-46.597 78.879" />
                </g>
                <defs>
                    <clipPath id="object-kitchen-blender-1-a">
                        <path fill="#fff" d="M.164.611h155.853v227.473H.164z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #1': {
        width: 156,
        height: 228,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-1-a)"
                    d="M135.035 130.828H23.457L7.55 207.375h143.39l-15.906-76.547Z"
                />
                <path
                    fill="#888B8D"
                    d="m7.551 207.375-4.094 19.706h151.578l-4.094-19.706H7.551Z"
                />
                <path
                    fill="url(#object-kitchen-blender-1-b)"
                    d="M23.457 34.955v47.33a60 60 0 0 0 18.735 43.556l5.265 4.988h63.579l5.265-4.988a60.002 60.002 0 0 0 18.735-43.557V34.955H23.457Z"
                />
                <path
                    fill="#fff"
                    d="M79.246 183.354c7.18 0 13-5.821 13-13 0-7.18-5.82-13-13-13s-13 5.82-13 13c0 7.179 5.82 13 13 13Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M32.397 113.794a59.987 59.987 0 0 0 9.795 12.047l5.265 4.988h63.579l5.265-4.988a60.001 60.001 0 0 0 18.735-43.557V20.448l18.841-18.84H23.457v80.675M117.532 34.955H23.457M23.457 14.871H13.31c-6.697 0-12.126 5.43-12.126 12.126v48.126"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-1-a"
                        x1={167.43}
                        x2={46.167}
                        y1={0.607}
                        y2={0.607}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-1-b"
                        x1={132.184}
                        x2={20.606}
                        y1={82.892}
                        y2={82.892}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 156,
        height: 228,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-1-a)"
                    d="M135.073 130.83H23.493L7.589 207.377h143.39l-15.905-76.547Z"
                />
                <path
                    fill="#888B8D"
                    d="m7.588 207.377-4.094 19.706h151.579l-4.095-19.706H7.588Z"
                />
                <path
                    fill="url(#object-kitchen-blender-1-b)"
                    d="M23.494 34.957v47.329a60 60 0 0 0 18.735 43.557l5.265 4.988h63.579l5.265-4.988a60.002 60.002 0 0 0 18.735-43.557V34.957H23.494Z"
                />
                <path
                    fill="#fff"
                    d="M79.283 183.355c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M32.434 113.796a59.987 59.987 0 0 0 9.795 12.047l5.265 4.988h63.579l5.265-4.988a60.001 60.001 0 0 0 18.735-43.557V20.45l18.84-18.84H23.494v80.675M117.569 34.957H23.494"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M23.495 14.873H13.347c-6.697 0-12.126 5.429-12.126 12.126v48.126"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-1-a"
                        x1={167.468}
                        x2={46.204}
                        y1={273.252}
                        y2={141.884}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-1-b"
                        x1={132.222}
                        x2={20.643}
                        y1={82.894}
                        y2={82.894}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 156,
        height: 228,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-1-a)"
                    d="M135.114 130.832H23.534L7.63 207.379h143.39l-15.905-76.547Z"
                />
                <path
                    fill="#888B8D"
                    d="m7.63 207.379-4.095 19.706h151.579l-4.095-19.706H7.629Z"
                />
                <path
                    fill="url(#object-kitchen-blender-1-b)"
                    d="M23.535 34.959v47.329a60 60 0 0 0 18.735 43.557l5.265 4.988h63.579l5.265-4.988a60.002 60.002 0 0 0 18.735-43.557V34.959H23.535Z"
                />
                <path
                    fill="#fff"
                    d="M79.324 183.357c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M32.475 113.798a59.987 59.987 0 0 0 9.795 12.047l5.265 4.988h63.579l5.265-4.988a60.001 60.001 0 0 0 18.735-43.557V20.452l18.84-18.84H23.535v80.675M117.61 34.959H23.535"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M23.536 14.873H13.388c-6.697 0-12.126 5.429-12.126 12.126v48.126"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-1-a"
                        x1={167.509}
                        x2={46.245}
                        y1={273.254}
                        y2={141.886}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-1-b"
                        x1={132.263}
                        x2={20.684}
                        y1={82.896}
                        y2={82.896}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.099} stopColor="#FFD30D" />
                        <stop offset={0.283} stopColor="#FFDA30" />
                        <stop offset={0.532} stopColor="#FFE468" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                        <stop offset={0.886} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-kitchen-blender-1"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
