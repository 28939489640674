import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-5-stand-2">
            <path
                fill="#E2E2E2"
                d="M377.677 860.018a5.142 5.142 0 0 1-4.271-2.267c-4.572-6.768-12.497-18.673-14.219-22.362a70.708 70.708 0 0 1-6.126-20.14 41.128 41.128 0 0 0-3.654-12.085l-8.723-18.363a10.003 10.003 0 0 1 1.908-11.311 10.004 10.004 0 0 1 3.364-2.246c15.86-6.426 26.094-10.909 28.819-12.623l1.435.418 13.95 29.371.029.792c-.119.304-11.814 30.73-8.958 51.214.663 4.748 1.272 10.784 1.576 13.96a5.17 5.17 0 0 1-1.319 3.955 5.165 5.165 0 0 1-3.811 1.687Z"
            />
            <path
                fill="#63666A"
                d="M374.701 860.083a6.124 6.124 0 0 1-1.308-1.328c-4.801-6.638-13.137-18.327-15.011-22.027a71.738 71.738 0 0 1-6.864-20.218 40.134 40.134 0 0 0-3.943-11.67l-9.149-17.766a11.445 11.445 0 0 1-.87-8.66 10.9 10.9 0 0 1 6.03-6.739c13.874-6.148 15.369-6.642 19.134-8.796.768-.44 3.611-.918 4.738 1.42 0 0-5.049 16.36 9.845 24.147a108.407 108.407 0 0 0 10.423 3.842s1.845.978-.136 6.028c-1.415 3.609-8.387 23.239-5.136 42.107.8 4.642 1.583 10.471 1.998 13.693a6.46 6.46 0 0 1-.145 2.589 6.46 6.46 0 0 1-1.153 2.321 6.165 6.165 0 0 1-8.453 1.057Z"
            />
            <path
                fill="#E2E2E2"
                d="M360.212 808.835c.01-16.511-8.521-31.645-13.458-38.569-1.032.46-2.081.928-3.168 1.409a10.898 10.898 0 0 0-6.03 6.739c-.862 2.888-.549 6 .87 8.66l9.149 17.766a40.165 40.165 0 0 1 3.944 11.67 71.704 71.704 0 0 0 6.863 20.218c1.874 3.7 10.21 15.39 15.011 22.027a6.088 6.088 0 0 0 4.095 2.471c-3.557-7.25-17.285-36.27-17.276-52.391Z"
            />
            <path
                fill="#63666A"
                d="M431.504 820.211c1.688-.013 2.525.914 3.47 2.313 3.393 5.025 9.007 13.82 15.343 18.086 3.55 2.391 10.56 6.228 14.57 8.38a6.675 6.675 0 0 1 3.605 5.53 6.456 6.456 0 0 1-6.441 6.707H404.75a11.438 11.438 0 0 1-8.095-3.19 10.9 10.9 0 0 1-3.231-8.447c.704-12.047 1.082-14.055 1.111-19.751.014-2.756.477-8.25 3.065-9.194 16.265 4.843 33.904-.434 33.904-.434Z"
            />
            <path
                fill="#E2E2E2"
                d="M410.302 856.265c-6.526-2.645-13.948-10.778-16.492-13.722-.117 2.241-.247 4.59-.39 7.047a10.9 10.9 0 0 0 3.231 8.447 11.442 11.442 0 0 0 8.095 3.19h57.302a6.446 6.446 0 0 0 5.473-3.053c-15.859.124-49.635 1.166-57.219-1.909Z"
            />
        </g>
    );
}
