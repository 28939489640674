import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-7" transform="translate(316, 30)">
        <path
            d="M50.7 149.402c6.8-16.4 20.501-15.1 25.201-35.5 3.3-14.6-1.2-30.5 3.5-43.8 6-16.8 23.1-29.5 44.4-29.5 20.5.5 35.5 9.4 44.8 23 9.8 14.2 6.5 23.5-3.4 33.1.1-1.2.3-2.3.6-3.6.4-2.3.9-4.6.7-7-.6-6.3-4.8-14.4-12.1-20.8l-.7 2c-11 33.9-42.7 32.9-43.5 43.4 0 .6-.1 1-.2 1-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5-1 22.8-13.3 36.3-13.3 36.3 1.8 2.5 8.1 8 15.9 13.6.7 13.1-1.3 22.4-23.6 16.9-31.1-5.1-39.7-27.6-32.2-45.7Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M167.199 61.602c-9.4-12.4-23.9-20.5-43.4-21-21.3 0-38.3 12.7-44.4 29.5-4.8 13.3-.2 29.2-3.5 43.8-4.6 20.4-18.4 19-25.2 35.5"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110 111.7c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
