import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-2-left">
        <path
            d="m465.76 167.07 2.654.952c13.05 4.677 23.788 10.342 32.825 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.648-16.938 8.175-19.334 9.644l-.857.525-21.103-34.814Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-2-left-a)"
        />
        <path
            d="M518.718 198.591c-9.281-6.355-17.305-16.13-28.914-18.162-20.362-4.496-40.617 14.04-37.902 34.715.941 15.834 15.348 24.112 25.884 33.637 6.188 5.434 12.453 10.783 19.102 15.655l.002-.002c1.497 1.089 56.761-35.313 56.761-35.313-10.699-12-28.545-25.804-34.933-30.53Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-2-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-2-left-a"
                x1={488.647}
                y1={173.085}
                x2={452.791}
                y2={276.024}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-2-left-b"
                x1={515.026}
                y1={182.27}
                x2={479.171}
                y2={285.21}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
