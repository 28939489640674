import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6491)"
            d="M392.181 257.658c.02-.017.031-.042.054-.058l4.024-2.86a4.36 4.36 0 014.842-.029l2.352 1.466c.087-1.649.24-3.309.482-4.945 3.416-23.09 13.156-30.795 13.156-30.795-8.894-1.027-17.796-1.716-26.577-2.148-31.634-3.417-41.864 42.951-12.35 53.509 0 0 2.174.624 5.585 1.451l7.223-14.177a4.03 4.03 0 011.209-1.414z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M378.375 271.615s2.175.624 5.585 1.451l7.014-13.996a4.051 4.051 0 011.209-1.414c.021-.017.032-.042.054-.058l4.025-2.86a4.353 4.353 0 014.841-.029l2.352 1.466c.087-1.649.24-3.309.482-4.945 3.416-23.09 12.869-29.887 12.869-29.887-8.894-1.027-17.796-1.716-26.577-2.148"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6491"
                x1="358.953"
                x2="404.351"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
