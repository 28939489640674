import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-1-walk-2">
            <path
                fill="#EF0025"
                d="M620.027 845.066a4.91 4.91 0 0 1-.277.119c-7.693 3.043-21.201 8.281-25.246 9.171-9.24 2.031-54.229 7.032-54.229 7.032a10.04 10.04 0 0 1-7.939-1.518 10.39 10.39 0 0 1-4.433-6.865c-.804-4.596-1.616-9.092-2.349-12.998a1.017 1.017 0 0 1 .732-1.175 65.375 65.375 0 0 1 12.154-1.568c.708-.048 1.414-.094 2.119-.144 6.37-.458 12.918-1.433 17.633-5.424a33.14 33.14 0 0 0 4.762-5.406c.73-.957 1.485-1.948 2.28-2.888a24.412 24.412 0 0 1 2.384-2.451 1.004 1.004 0 0 1 1.145-.13c9.613 5.166 23.558 11.537 35.395 12.556 4.87.419 10.999 1.184 14.219 1.602a5.366 5.366 0 0 1 4.369 3.64 5.265 5.265 0 0 1-2.719 6.447Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M622.664 842.23a5.164 5.164 0 0 1-2.915 2.957c-7.692 3.043-21.201 8.281-25.245 9.171-9.241 2.031-54.229 7.032-54.229 7.032a10.044 10.044 0 0 1-7.939-1.518"
            />
            <path
                fill="url(#man-34-25-shoes-1-walk-2-a)"
                d="M328.849 861.122a23.476 23.476 0 0 1-19.967-11.015l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.606-15.336a190.545 190.545 0 0 0 9.246 20.666c2.548 4.717 9.434 6.1 15.831 4.771 9.922-2.06 12.394-2.614 12.394-2.614l7.797 21.069a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.882 6.882 0 0 1 4.341 8.31 6.893 6.893 0 0 1-2.463 3.628 6.892 6.892 0 0 1-4.153 1.405h-26.424Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M355.272 861.124h-26.424a23.487 23.487 0 0 1-19.967-11.015l-19.529-30.968"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-1-walk-2-a"
                    x1="287.821"
                    x2="362.138"
                    y1="827.141"
                    y2="827.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset=".37" stopColor="#F86431" />
                    <stop offset="1" stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
