import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-2-cycling-2-right-a)">
        <path
            d="M393.113 840.438c-8.162-.104-22.455-.383-26.477-1.074a70.706 70.706 0 0 1-20.039-6.453 41.148 41.148 0 0 0-12.012-3.89l-20.058-3.308a10 10 0 0 1-8.128-12.063c3.765-16.69 5.92-27.654 6.066-30.871l-3.367-21.113 38.963-.072-1.686 25.963c.183.271 18.506 27.229 36.961 36.566 4.301 2.176 9.594 5.112 12.366 6.667a5.152 5.152 0 0 1 .626 8.572 5.156 5.156 0 0 1-3.148 1.076h-.067Z"
            fill="#2B2729"
        />
        <path
            d="M393.499 840.421a4.921 4.921 0 0 1-.298.006c-8.162-.072-22.455-.295-26.481-.969a70.758 70.758 0 0 1-20.064-6.376 41.12 41.12 0 0 0-12.026-3.843l-20.071-3.229a10.003 10.003 0 0 1-6.684-4.251 9.994 9.994 0 0 1-1.492-7.779c.979-4.418 1.913-8.747 2.7-12.519a1.002 1.002 0 0 1 1.107-.787 65.367 65.367 0 0 1 11.687 3.02c.664.215 1.327.431 1.99.643 5.99 1.91 12.335 3.421 18.131 1.557a33.013 33.013 0 0 0 6.365-3.115c1.025-.593 2.083-1.207 3.16-1.76a24.326 24.326 0 0 1 3.092-1.331 1.008 1.008 0 0 1 1.095.3c6.855 8.138 17.218 18.936 27.651 24.163 4.292 2.15 9.607 5.07 12.393 6.618a5.156 5.156 0 0 1-2.255 9.652Z"
            fill="#004F71"
        />
        <path
            d="M396.966 838.834a5.145 5.145 0 0 1-3.765 1.591c-8.162-.072-22.455-.294-26.481-.968a70.79 70.79 0 0 1-20.064-6.376 41.09 41.09 0 0 0-12.026-3.843l-20.071-3.229a9.91 9.91 0 0 1-6.686-4.255"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <clipPath id="man-34-25-shoes-2-cycling-2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .621)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
