import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-right-option-1">
        <path
            fill="url(#man-34-25-doctors-coat-arm-right-option-1-a)"
            d="M365.777 333.826a23.47 23.47 0 0 1-20.811-12.548 23.271 23.271 0 0 1-2.696-9.965 23.827 23.827 0 0 1-.444-2.341 253.226 253.226 0 0 1-1.823-26.122c-.191-8.25-.115-16.693.232-25.816.266-8.768.798-39.998 4.386-51.426 5.856-18.651 17.532-27.397 33.587-27.397a32.033 32.033 0 0 1 31.785 36.026l-.019.15c-.776 5.885-1.35 11.9-1.905 17.717-.245 2.567-.49 5.133-.752 7.697a202.014 202.014 0 0 1-4.208 25.363c-.156.706-.317 1.413-.482 2.123 1.169-.502 2.339-1 3.511-1.496 1.4-.579 2.794-1.16 4.181-1.744 2.84-1.188 5.776-2.418 8.693-3.578 4.126-1.677 8.531-3.457 12.961-5.135 1.531-.587 3.062-1.178 4.594-1.773 2.803-1.085 5.605-2.17 8.424-3.221 1.461-.521 2.904-1.048 4.347-1.574 2.886-1.053 5.869-2.141 8.851-3.132a46.175 46.175 0 0 0 6.937 34.704c-1.284.838-2.567 1.679-3.848 2.523a713.3 713.3 0 0 1-7.616 4.802c-1.402.876-2.803 1.753-4.202 2.633-3.98 2.527-8.054 4.99-11.885 7.295-2.669 1.633-5.409 3.244-8.06 4.802a1535.23 1535.23 0 0 0-3.905 2.302c-8.835 5.155-16.786 9.58-24.314 13.529l-12.305 6.415-12.271 6.47a23.547 23.547 0 0 1-10.941 2.717h-.002Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m409.993 214.239-.019.15c-.776 5.884-1.35 11.9-1.905 17.717-.245 2.566-.49 5.132-.752 7.696a202.06 202.06 0 0 1-4.208 25.364 200.04 200.04 0 0 1-.482 2.122c1.169-.501 2.339-1 3.511-1.496 1.4-.579 2.794-1.16 4.181-1.743 2.84-1.189 5.776-2.418 8.693-3.578 4.126-1.677 8.531-3.458 12.961-5.136a901.167 901.167 0 0 0 4.594-1.773c2.803-1.084 5.605-2.169 8.424-3.221a657.978 657.978 0 0 0 4.347-1.573c2.886-1.053 5.869-2.142 8.851-3.133a46.18 46.18 0 0 0 6.937 34.705c-1.284.838-2.567 1.678-3.848 2.522a697.833 697.833 0 0 1-7.616 4.803c-1.402.876-2.803 1.753-4.202 2.632-3.98 2.527-8.054 4.99-11.885 7.296-2.669 1.633-5.409 3.244-8.06 4.802-1.303.765-2.604 1.532-3.905 2.301-8.835 5.156-16.786 9.58-24.314 13.53l-12.305 6.415-12.271 6.47a23.56 23.56 0 0 1-10.941 2.717h-.002a23.47 23.47 0 0 1-20.811-12.548 23.275 23.275 0 0 1-2.696-9.965 23.882 23.882 0 0 1-.444-2.341 253.226 253.226 0 0 1-1.823-26.122c-.191-8.25-.115-16.693.232-25.816.266-8.768.798-39.998 4.386-51.427"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-right-option-1-a"
                x1={405.523}
                x2={396.849}
                y1={373.462}
                y2={104.546}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.837} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
