import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-2-left">
        <path
            d="m465.305 166.914 2.65.962c13.031 4.731 23.745 10.441 32.753 17.455.763.594 5.844 5.457 5.789 6.398-.269 4.647-16.971 8.106-19.373 9.564l-.859.521-20.96-34.9Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-2-left-a)"
        />
        <path
            d="M518.134 198.659c-9.256-6.394-17.239-16.202-28.839-18.282-20.343-4.581-40.675 13.872-38.045 34.558.876 15.837 15.248 24.175 25.745 33.743 6.165 5.46 12.408 10.835 19.037 15.734l.002-.002c1.493 1.096 56.906-35.077 56.906-35.077-10.649-12.044-28.439-25.923-34.806-30.674Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-2-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-2-left-a"
                x1={500.774}
                y1={147.935}
                x2={422.448}
                y2={314.167}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-2-left-b"
                x1={528.403}
                y1={160.955}
                x2={450.077}
                y2={327.187}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
