import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-6">
        <path
            d="m500.622 185.415-.027-.02-.006-.004c-9.03-6.966-19.757-12.624-32.793-17.295l-2.653-.951 21.103 34.813.857-.525c2.396-1.469 14.36-9.029 14.61-13.677a2.666 2.666 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-tshirt-arm-left-option-6-a)"
        />
        <path
            d="M531.236 213.514c-.64-1.23-1.283-2.448-1.925-3.667-1.31-2.488-2.666-5.06-3.953-7.625-1.847-3.604-3.81-7.456-5.69-11.374a71.797 71.797 0 0 0-.985-.343c-2.349-.802-4.704-1.589-7.059-2.375-5.353-1.788-10.888-3.636-16.251-5.652l-.149-.056a32 32 0 1 0-22.209 60.024c5.373 1.988 10.769 4.216 15.988 6.37 2.293.946 4.585 1.892 6.881 2.821a188.338 188.338 0 0 0 23.557 7.799c8.511 2.294 16.445 4.177 24.256 5.76-7.268-16.839-9.758-34.437-10.264-47.583a684.843 684.843 0 0 1-2.197-4.099Z"
            fill="url(#man-34-25-tshirt-arm-left-option-6-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-left-option-6-a"
                x1={482.431}
                y1={186.089}
                x2={463.346}
                y2={350.908}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.121} stopColor="#FFDA30" />
                <stop offset={0.269} stopColor="#FFE364" />
                <stop offset={0.408} stopColor="#FFEA8D" />
                <stop offset={0.534} stopColor="#FFF0AB" />
                <stop offset={0.642} stopColor="#FFF3BD" />
                <stop offset={0.72} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-arm-left-option-6-b"
                x1={503.621}
                y1={188.543}
                x2={484.536}
                y2={353.362}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.121} stopColor="#FFDA30" />
                <stop offset={0.269} stopColor="#FFE364" />
                <stop offset={0.408} stopColor="#FFEA8D" />
                <stop offset={0.534} stopColor="#FFF0AB" />
                <stop offset={0.642} stopColor="#FFF3BD" />
                <stop offset={0.72} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
