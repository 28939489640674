import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M541.428 634.684c.295 4.33-1.373 20.828-2.54 31.535a8.992 8.992 0 008.497 9.958l20.304 1.018c4.331.2 8.606 1.066 12.674 2.569a69.617 69.617 0 0020.346 4.084c4.096.233 19.087-1.2 26.271-1.924a4.15 4.15 0 003.732-4.302 4.148 4.148 0 00-2.536-3.66c-3.096-1.301-8.575-3.568-13.002-5.21-19.868-7.366-41.271-32.44-41.271-32.44"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M541.428 634.684c.295 4.33-1.373 20.828-2.54 31.535a8.992 8.992 0 008.497 9.958l20.304 1.018c4.331.2 8.606 1.066 12.674 2.569a69.617 69.617 0 0020.346 4.084c4.096.233 19.087-1.2 26.271-1.924a4.15 4.15 0 003.732-4.302 4.148 4.148 0 00-2.536-3.66c-3.096-1.301-8.575-3.568-13.002-5.21-19.868-7.366-41.271-32.44-41.271-32.44"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M516.953 475.564c2.667-.591 5.341-1.248 8.012-1.869 10.7-2.629 7.02-5.129 17.926-9.31 5.58-2.161 8.187-6.354 12.102-11.444a33.831 33.831 0 00-6.197-47.439l-.77-.592a147.802 147.802 0 00-28.85-16.916 214.804 214.804 0 00-14.739-6.065 131.517 131.517 0 00-14.93-4.609c-5.015-1.248-10.074-2.16-15.12-3.17-5.054-.942-10.111-1.868-15.204-2.518-2.544-.346-5.081-.745-7.637-.986-2.555-.268-5.102-.59-7.669-.755l-3.844-.299-3.859-.187c-2.576-.11-5.158-.158-7.756-.086l-9.683.26a56.48 56.48 0 00-47.84 29.135 56.465 56.465 0 00.789 56.008 44.967 44.967 0 0038.818 21.718h102.392l4.059-.876z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M542.989 642.617a824.33 824.33 0 01-7.13-25.344c-1.117-4.23-2.32-8.452-3.366-12.686l-3.2-12.699c-2.162-8.463-4.186-16.937-6.59-25.383-2.332-8.451-5.026-16.876-7-25.354-4.216-16.935-4.818-34.133-4.828-51.373.087-8.627.524-17.279 1.447-25.967.203-2.17.526-4.349.784-6.523.335-2.18.623-4.355 1.025-6.539a123.29 123.29 0 012.934-13.145l3.067-10.728a26.407 26.407 0 0151.789 6.742l1.01 51.651.493 25.826.354 25.837c.212 17.226.523 34.445.655 51.677l.472 51.69a15.939 15.939 0 01-13.551 15.901 15.94 15.94 0 01-17.651-11.178l-.714-2.405z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M370.785 454.723c8.059 13.631 23.882 21.719 39.717 21.719h102.392l4.059-.876M548.796 405.502l-.77-.592a147.83 147.83 0 00-28.85-16.916 214.62 214.62 0 00-14.74-6.065 131.455 131.455 0 00-14.93-4.609c-5.014-1.248-10.073-2.16-15.119-3.17-5.055-.942-10.111-1.868-15.204-2.518-2.544-.346-5.081-.745-7.638-.986-2.554-.268-5.101-.59-7.669-.755l-3.843-.299-3.86-.187c-2.575-.11-5.157-.158-7.756-.086l-9.682.26M543.703 645.023l-.713-2.405a818.707 818.707 0 01-7.13-25.344c-1.118-4.23-2.32-8.452-3.366-12.686l-3.2-12.699c-2.162-8.463-4.186-16.937-6.591-25.383-2.332-8.451-5.026-16.876-7-25.354-4.215-16.935-5.337-41.152-4.74-60M548.796 405.504c14.658 5.74 22.894 16.836 23.125 28.116l1.01 51.651.493 25.826.354 25.836c.212 17.226.523 34.445.655 51.677l.472 51.69M549.392 426.137h15.483"
        ></path>
    </g>
);

export default SvgComponent;
