import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6358)"
            d="M366.377 271.813a398.425 398.425 0 01-1.984-4.17c-1.434-3.048-2.918-6.2-4.554-9.186a27.504 27.504 0 0110.916-37.328 27.499 27.499 0 0137.328 10.915l.155.287c1.6 3.005 3.421 5.975 5.182 8.848-8.362-4.079-43.495.8-47.043 30.634z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6358"
                x1="366.026"
                x2="383.592"
                y1="225.642"
                y2="247.112"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
