import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2251}
            height={976}
            viewBox="0 0 2251 976"
            fill="none"
            id="scene-office-5"
            {...attributes}
        >
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-5-a)"
            >
                <path 
                    d="m2007.54 949.957-.75 17.25h-14.84l-2.15-49.098M2034.94 918.117l5.13 15.54 11.07 33.55h14.84l-9.94-41M1942.69 926.211l-9.94 41h14.84l11.07-33.55"
                />
                <path 
                    d="M2067.73 915.3c8.02-10.09 12.44-23.08 11.53-36.92-2.29-35.04-4.59-70.09-6.89-105.13h-146.02l-6.89 105.13c-1.28 19.57 8.09 37.44 23.23 47.83M2056.04 926.211a53.33 53.33 0 0 1-15.97 7.45c-2.93.82-5.96 1.4-9.09 1.71-9.36.94-19.34 1.53-29.85 1.59-11.85.06-23.03-.54-33.44-1.6-3.1-.31-6.12-.89-9.03-1.7M2085.41 604.24c-6.96-22.407-7.65-37.827.88-49.677 5.81-8.061 17.86-9.925 24.98-5.08 8.14 5.537 10.5 16.058 8.95 24.762-4.91 27.547-21.74 53.869-42.79 73.701 0-43.579-19.81-65.913-37.5-65.913-7.07 0-14.37 4.052-14.37 12.736 0 19.652 40.11 20.997 36.05 79.291 21.62-20.264 44.64-31.313 61.22-31.313 14.49 0 25.22 8.578 25.22 21.936 0 12.695-9.68 17.116-21.25 17.116-13.38 0-51.8-5.253-79.03 21.979 7.59-35.561-14.59-80.211-34.24-80.211-10.16 0-15.34 7.768-15.34 13.958 0 25.326 42.5 31.222 39.87 94.295 30.42-15.537 46.59-20.829 58.72-20.829 4.79 0 20.96 3.145 20.96 17.545 0 8.046-8.98 16.547-19.6 16.547-18.54 0-29.6-4.223-48.12.505-10.86 2.77-17.58 10.937-21.94 15.909 1.48-44.33-21.52-91.457-40.98-88.352-11.08 1.768-3.53 38.335 21 88.352-35.19-59.667-106.72-78.373-151.38-71.361-36.32 5.704-39.99 38 10.12 29.777 33.97-5.574 87.12 1.305 106.07 41.583 1.85-31.698-32.77-97.383-92.89-97.383-29.37 0-40.36-8.337-40.36-20.084 0-7.958 8.78-15.158 29.37-15.158 37.92 0 87.03 34.232 99.66 75.031-10.61-54.947 2.85-103.899 43.33-132.379 20.09-14.135 19.43-39.867-.13-45.726-26.18-7.844-74.52 45.347-62.15 123.158-42.82-45.853-77.54-36.409-103.85-44.803-19-6.06-33.44-14.341-32.63-27.766.77-12.742 15.71-17.681 33.41-15.726 40.52 4.478 77.04 38.437 84.32 50.968 6.63-38.274 11.23-67.662.24-99.873-5.71-16.747-20.03-22.977-29.76-20.064-9.82 2.941-14.99 15.539-14.06 27.663 1.49 19.606 15.34 42.006 20.5 47.747M2034.94 918.117h-45.15" 
                />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-5-b)"
            >
                <path 
                    d="m309.664 954.053-.594 13.563h-11.676l-1.692-38.604M331.223 929.02l4.036 12.218 8.71 26.379h11.676l-7.821-32.237M258.642 935.383l-7.82 32.237h11.675l8.71-26.38"
                />
                <path 
                    d="M357.02 926.806a42.06 42.06 0 0 0 9.071-29.029c-1.802-27.551-3.611-55.109-5.421-82.66H245.785l-5.421 82.66c-1.007 15.387 6.365 29.438 18.277 37.607M347.824 935.383a41.952 41.952 0 0 1-12.565 5.857 41.788 41.788 0 0 1-7.151 1.345 249.432 249.432 0 0 1-23.486 1.25 247.498 247.498 0 0 1-26.309-1.258 42.182 42.182 0 0 1-7.105-1.337M370.929 682.228c-5.478-17.618-6.016-29.742.697-39.06 4.566-6.338 14.051-7.803 19.652-3.994 6.401 4.354 8.264 12.626 7.043 19.47-3.862 21.659-17.106 42.355-33.67 57.948 0-34.265-15.586-51.825-29.499-51.825-5.566 0-11.307 3.186-11.307 10.014 0 15.452 31.555 16.509 28.358 62.344 17.012-15.933 35.122-24.621 48.168-24.621 11.405 0 19.844 6.745 19.844 17.248 0 9.981-7.619 13.457-16.718 13.457-10.527 0-40.758-4.13-62.183 17.282 5.977-27.961-11.478-63.067-26.933-63.067-8 0-12.075 6.107-12.075 10.974 0 19.913 33.442 24.549 31.371 74.141 23.935-12.216 36.656-16.377 46.197-16.377 3.772 0 16.497 2.473 16.497 13.795 0 6.326-7.072 13.01-15.422 13.01-14.592 0-23.288-3.32-37.864.397-8.54 2.178-13.828 8.6-17.263 12.509 1.166-34.855-16.93-71.909-32.239-69.468-8.719 1.39-2.778 30.142 16.524 69.468-27.686-46.914-83.969-61.622-119.104-56.108-28.578 4.484-31.46 29.878 7.965 23.412 26.725-4.383 68.542 1.026 83.449 32.695 1.454-24.923-25.777-76.568-73.083-76.568-23.106 0-31.753-6.555-31.753-15.792 0-6.257 6.907-11.918 23.106-11.918 29.836 0 68.475 26.915 78.413 58.994-8.349-43.203 2.24-81.692 34.087-104.085 15.808-11.113 15.291-31.346-.099-35.952-20.602-6.168-58.635 35.654-48.895 96.834-33.691-36.052-61.009-28.627-81.712-35.227-14.948-4.764-26.306-11.275-25.668-21.831.605-10.019 12.361-13.902 26.284-12.365 31.881 3.521 60.614 30.222 66.337 40.075 5.218-30.094 8.838-53.201.192-78.527-4.495-13.168-15.762-18.066-23.414-15.776-7.725 2.313-11.792 12.218-11.065 21.751 1.177 15.415 12.075 33.028 16.133 37.542M331.223 929.02h-35.521"
                />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2249.05 418H193.551l-55.5 40.5H2001.05V478H129.051v28.5h168v105M277.547 532v40.5M313.547 532v40.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-5-c)"
            >
                <path
                    d="M544.346 27.526v330.063h178.295V1H366.052v356.589h147.41M575.23 332.575V284.07M513.461 332.575V284.07"
                />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-5-d)"
            >
                <path 
                    d="M916.346 27.526v330.063h178.294V1H738.052v356.589h147.41M947.23 332.575V284.07M885.461 332.575V284.07" 
                />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-5-e)"
            >
                <path 
                    d="M1917.15 27.526v330.063h178.3V1h-356.59v356.589h147.41M1948.04 332.575V284.07M1886.27 332.575V284.07"
                />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1376.84 80.297v31.913l-15.12 15.114M1376.84 37.594v14.489M1451.89 112.211h-14.48M1376.84 187.387v-14.489M1301.78 112.211h14.49"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1439.11 137.957c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 975H2142.05"
            />
            <defs>
                <clipPath id="scene-office-5-a">
                    <path fill="#fff" d="M2149.05 490h-336.818v478.211h336.818z" />
                </clipPath>
                <clipPath id="scene-office-5-b">
                    <path fill="#fff" d="M421 592.406H156v376h265z" />
                </clipPath>
                <clipPath id="scene-office-5-c">
                    <path fill="#fff" d="M723.641 0H365.052v358.589h358.589z" />
                </clipPath>
                <clipPath id="scene-office-5-d">
                    <path fill="#fff" d="M1095.64 0H737.051v358.589h358.589z" />
                </clipPath>
                <clipPath id="scene-office-5-e">
                    <path fill="#fff" d="M2096.45 0h-358.589v358.589h358.589z" />
                </clipPath>
            </defs>
        </svg>
    );
}
