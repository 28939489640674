import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-4">
        <path
            fill={SKIN_COLOR[color]}
            d="M364.355 306.8a202.147 202.147 0 0 1-7.351-22.26c-2.043-7.46-3.78-14.95-5.296-22.461a160.401 160.401 0 0 1-3.155-22.673c-.521-7.61-.854-15.238-1.622-22.823a31.005 31.005 0 0 1 6.827-22.724 30.999 30.999 0 0 1 54.858 16.48l.017.169c.726 7.59 1.887 15.136 2.861 22.701a160.326 160.326 0 0 1 1.326 22.853c-.022 7.663-.264 15.348-.813 23.063"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m382.756 280.264 13.27-2.365 13.258-2.428c8.843-1.594 17.738-2.929 26.663-4.114 4.47-.56 8.927-1.178 13.409-1.675 4.475-.531 8.952-1.051 13.449-1.473 4.494-.438 8.978-.924 13.48-1.322 4.515-.333 9.01-.764 13.545-.996a15.671 15.671 0 0 1 16.448 14.85 15.698 15.698 0 0 1-10.341 15.543c-4.273 1.538-8.586 2.877-12.879 4.313-4.306 1.372-8.63 2.655-12.945 3.987-4.311 1.348-8.641 2.597-12.974 3.836-4.327 1.272-8.676 2.423-13.015 3.633-8.69 2.354-17.411 4.559-26.184 6.504l-13.166 2.881-13.154 2.944a22.504 22.504 0 0 1-26.877-17.047 22.504 22.504 0 0 1 18.013-27.071Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M363.699 305.16a209.8 209.8 0 0 1-6.696-20.62c-2.043-7.46-3.78-14.95-5.296-22.462a160.389 160.389 0 0 1-3.156-22.672c-.521-7.61-.854-15.238-1.621-22.824m61.687-6.242.017.168c.726 7.59 1.888 15.136 2.861 22.701a160.334 160.334 0 0 1 1.326 22.853 370.29 370.29 0 0 1-.307 14.081"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M495.937 296.285c-4.273 1.538-8.586 2.877-12.879 4.314-4.306 1.371-8.63 2.654-12.944 3.986-4.311 1.348-8.642 2.597-12.975 3.836-4.326 1.272-8.676 2.423-13.015 3.633-8.69 2.354-17.411 4.559-26.184 6.504l-13.166 2.881-13.154 2.944c-12.129 2.715-22.828-3.479-27.921-19.223m40.207-28.705 5.379-.985c8.843-1.594 17.739-2.928 26.664-4.114 4.47-.56 8.927-1.178 13.409-1.675 4.475-.531 8.952-1.051 13.449-1.473 4.494-.437 8.978-.924 13.48-1.322 4.515-.333 9.01-.764 13.545-.995"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M567.464 279.406a2.968 2.968 0 0 0-1.019-1.958c-.797-.755-1.983-.585-3.076-.684l.079-1.013a2.395 2.395 0 0 0-1.852-2.003 25.14 25.14 0 0 0-10.538-.105c-3.98.836-7.903 2.044-11.956 2.387a28.62 28.62 0 0 1-20.24-6.247l-2.043-1.757c2.842-2.457 6.515-3.671 10.043-4.962a27.08 27.08 0 0 0 7.963-4.077 2.445 2.445 0 0 0 .456-3.277 2.455 2.455 0 0 0-2.835-.895c-4.271 1.46-9.266 1.508-13.671 2.237-5.016.831-10.086 1.517-14.917 3.102-4.831 1.584-9.479 4.154-12.657 8.122-8.012 10.085-5.912 21.981 2.35 25.64 9.252 2.86 13.446 1.877 23.115 1.327 3.329-.189 6.337-1.239 9.658-1.584l1.496.027c12.673.75 25.374-3.609 36.643-9.142a3.141 3.141 0 0 0 1.904-3.219c.736-.16 1.128-1.165 1.097-1.919Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M516.819 268.026c2.842-2.457 6.515-3.671 10.042-4.962a27.072 27.072 0 0 0 7.964-4.077 2.445 2.445 0 0 0 .456-3.277 2.455 2.455 0 0 0-2.835-.894c-4.271 1.458-9.266 1.507-13.671 2.236-5.016.831-10.086 1.517-14.917 3.102a29.356 29.356 0 0 0-10.018 5.402m34.432 20.626c6.009 1.259 12.2-.652 17.92-2.883 4.953-1.932 9.932-4.152 15.163-4.847a2.383 2.383 0 0 0 1.61-.911 2.39 2.39 0 0 0 .481-1.788 2.4 2.4 0 0 0-1.852-2.003 25.145 25.145 0 0 0-10.539-.105c-3.98.836-7.902 2.044-11.954 2.387a28.625 28.625 0 0 1-20.242-6.247m47.403 10.36-10.843 4.985a114.774 114.774 0 0 1-13.398 5.49 103.502 103.502 0 0 1-25.356 4.624c-7.501.426-11.795.981-17.563.022"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M564.462 284.543c-11.269 5.533-20.479 8.709-33.751 9.226"
        />
    </g>
);

export default SvgComponent;
