import React, { useCallback, useContext, useState } from 'react';

import { ReactComponent as HelpIcon } from 'icons/help.svg';
import { ReactComponent as AvatarIcon } from 'assets/layout/avatar.svg';
import { ReactComponent as DownloadIcon } from 'icons/download.svg';
import { PageContext } from '../App';

export default function Navbar() {
    const { content } = useContext(PageContext);
    const [svg, setSvg]  = useState('');
    const href = 'data:image/svg+xml;charset=utf-8,' + encodeURIComponent(svg);

    function hoverHandler() {
        let _svg = document.getElementById('svg-target')?.cloneNode(true) as SVGElement;
        if (_svg) {
            _svg.removeAttribute('id');
            _svg.removeAttribute('class');
            setSvg(_svg.outerHTML);
        }
    }

    const name = useCallback(() => {
        return {
            Character: 'character.svg',
            Objects: 'object.svg',
            Scenes: 'scene.svg',
        }[content.tab];
    }, [content.tab]);

    return <div className="navbar">
        <button className="navbar-help">
            <HelpIcon />
        </button>
        <div className="navbar-avatar">
            <AvatarIcon />
        </div>
        <a href={href} download={name()} className="button-blue" onMouseEnter={hoverHandler}>
            <DownloadIcon />
            <p className="export-label">Export</p>
        </a>
    </div>;
}
