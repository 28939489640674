import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M458.038 806.651a652.22 652.22 0 0 1-1.958-23.302c-.255-3.874-.596-7.76-.781-11.623l-.617-11.599c-.439-7.737-.742-15.453-1.423-23.225-.608-7.761-1.575-15.574-1.828-23.284-.771-15.457 2.043-30.396 5.444-45.25a213.537 213.537 0 0 1 6.553-22.071c.628-1.827 1.376-3.637 2.058-5.457.759-1.808 1.47-3.623 2.297-5.422a99.375 99.375 0 0 1 5.47-10.718l6.403-10.763a26.12 26.12 0 0 1 35.804-9.092 26.182 26.182 0 0 1 12.226 27.732l-9.233 44.702-4.628 22.349-4.767 22.33c-3.2 14.883-6.304 29.78-9.585 44.651l-9.765 44.625a15.917 15.917 0 0 1-27.42 7.204 15.928 15.928 0 0 1-3.967-8.967l-.283-2.82Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m474.855 632.793-16.14-42.662-4.049-10.662-4.16-10.644c-2.746-7.101-5.796-14.15-8.482-21.261-2.687-7.11-5.126-14.263-7.189-21.481-1.067-3.603-1.971-7.234-2.965-10.849l-1.457-5.429-1.309-5.454c-.861-3.637-1.789-7.264-2.52-10.924-.762-3.655-1.594-7.298-2.23-10.975l-1.018-5.504-.877-5.527a183.623 183.623 0 0 1-1.396-11.118 51.013 51.013 0 0 1 78.146-47.923 51.033 51.033 0 0 1 20.69 25.894l1.7 4.81c1.271 3.568 2.358 7.167 3.349 10.783l1.454 5.429 1.28 5.459c.906 3.63 1.571 7.302 2.322 10.959.766 3.654 1.389 7.333 2.008 11.012 1.234 7.36 2.194 14.766 3.048 22.191.85 7.426 1.446 14.894 1.722 22.418.283 7.523.095 15.126-.237 22.754l-.528 11.447c-.201 3.82-.493 7.655-.736 11.482-1.188 15.345-2.913 30.783-6.487 46.537l-1.267 5.582a27.57 27.57 0 0 1-32.986 20.787 27.85 27.85 0 0 1-19.686-17.131Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m458.321 809.474-.283-2.82a652.024 652.024 0 0 1-1.958-23.302c-.255-3.874-.596-7.76-.781-11.623l-.617-11.599c-.439-7.736-.742-15.453-1.423-23.225-.608-7.761-1.575-15.574-1.828-23.283-.771-15.458 2.043-30.397 5.444-45.25a213.39 213.39 0 0 1 6.553-22.071c.628-1.828 1.376-3.638 2.058-5.457.759-1.809 1.47-3.624 2.297-5.423a99.375 99.375 0 0 1 5.47-10.718M527.687 632.578l-9.233 44.702-4.628 22.35-4.767 22.329c-3.201 14.883-6.304 29.78-9.585 44.651l-9.765 44.625"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M470.518 621.331c-29.446-77.761-30.074-75.627-35.683-95.248-7.06-23.855-12.389-51.345-13.772-65.78a51.013 51.013 0 0 1 78.146-47.923 51.033 51.033 0 0 1 20.69 25.894c21.799 61.184 16.079 126.628 16.118 127.261-1.369 25.969-8.345 67.143-8.332 67.042"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M458.005 805c-.35 3.89-4.167 18.319-6.718 27.67a8.104 8.104 0 0 0 .903 6.352 8.096 8.096 0 0 0 5.241 3.701l24.769 5.225s13.341 10.881 38.22 10.881c5.773 0 10.599-1.603 11.236-4.623.629-2.982-6.742-6.892-11.827-9.158-17.42-7.764-30.12-33.813-30.12-33.813"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M458.005 805c-.35 3.89-4.167 18.319-6.718 27.67a8.104 8.104 0 0 0 .903 6.352 8.096 8.096 0 0 0 5.241 3.701l24.769 5.225s13.341 10.881 38.22 10.881c5.773 0 10.599-1.603 11.236-4.623.629-2.982-6.742-6.892-11.827-9.158-17.42-7.764-30.12-33.813-30.12-33.813"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M354.957 399.156a90.502 90.502 0 0 0-4.403 15.397c-10.396 55.41 52.309 115.616 131.423 44.511 24.406-21.935 36.192-41.866 40.779-59.908H354.957Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M354.957 399.156a90.502 90.502 0 0 0-4.403 15.397c-10.396 55.41 52.309 115.616 131.423 44.511 24.406-21.935 36.192-41.866 40.779-59.908"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M398.156 818.709a726.05 726.05 0 0 1-6.054-23.917c-.937-3.988-1.961-7.974-2.827-11.964l-2.661-11.97c-1.803-7.979-3.468-15.963-5.513-23.934-1.974-7.974-4.309-15.936-5.923-23.921-3.497-15.962-3.372-32.041-2.655-48.139a238.02 238.02 0 0 1 2.541-24.196c.294-2.019.71-4.042 1.059-6.062.427-2.024.805-4.045 1.3-6.07.926-4.121 2.09-8.184 3.486-12.17l3.976-11.204a26.26 26.26 0 0 1 33.528-15.962 26.31 26.31 0 0 1 17.467 25.382l-1.171 48.263-.597 24.133-.738 24.137c-.514 16.092-.931 32.181-1.526 48.275l-1.709 48.281a15.926 15.926 0 0 1-25.807 11.92 15.927 15.927 0 0 1-5.478-8.293l-.698-2.589Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m380.551 626.519-10.336-43.165-2.599-10.791-2.711-10.787c-1.78-7.192-3.867-14.372-5.587-21.567-1.719-7.194-3.188-14.398-4.275-21.616-.579-3.608-.994-7.222-1.499-10.832l-.724-5.418-.573-5.422c-.371-3.616-.811-7.229-1.049-10.849-.271-3.62-.612-7.237-.755-10.86l-.278-5.434-.137-5.44c-.083-3.626-.053-7.257.092-10.892a51.018 51.018 0 0 1 33.287-45.882 51.02 51.02 0 0 1 50.43 8.723 51.038 51.038 0 0 1 17.103 28.194l1.108 5.129c.786 3.6 1.386 7.207 1.889 10.818l.72 5.417.544 5.424c.417 3.614.59 7.237.848 10.857.275 3.619.404 7.243.53 10.868.247 7.25.216 14.51.077 21.775-.143 7.264-.543 14.538-1.267 21.825-.718 7.286-1.912 14.59-3.254 21.9l-2.042 10.965c-.706 3.656-1.504 7.316-2.252 10.974-3.213 14.639-6.971 29.298-12.603 44.029l-2.098 5.485a27.585 27.585 0 0 1-35.615 15.913 27.851 27.851 0 0 1-16.974-19.341Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m398.854 821.3-.698-2.59a725.359 725.359 0 0 1-6.054-23.916c-.937-3.989-1.961-7.974-2.827-11.965l-2.661-11.97c-1.803-7.979-3.468-15.962-5.513-23.933-1.974-7.974-4.309-15.936-5.923-23.921-3.497-15.962-3.372-32.041-2.655-48.14a237.97 237.97 0 0 1 2.541-24.195c.294-2.019.71-4.042 1.059-6.063.427-2.023.805-4.045 1.3-6.07.926-4.12 2.09-8.184 3.486-12.17M435.793 628.148l-1.085 44.697-.597 24.132-.737 24.137c-.515 16.092-.931 32.181-1.527 48.275l-1.708 48.281M450.943 444.484c.315 2.631 6.628 22.55 5.716 70.288-.708 35.898-11.664 84.18-21.419 109.692M380.553 626.519c-10.544-44.307-12.146-50.519-15.647-64.743-5.918-23.92-8.973-35.734-12.658-64.855a233.999 233.999 0 0 1-.177-55.648"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M398.42 822.305c.46 3.879-.295 18.784-.861 28.46a8.091 8.091 0 0 0 4.878 7.907 8.081 8.081 0 0 0 3.208.661h43.739c5.773 0 10.551-1.331 10.551-4.418 0-3.047-9.356-5.834-13.974-8.944-7.638-5.143-16.678-23.666-16.678-23.666"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M398.42 822.305c.46 3.879-.295 18.784-.861 28.46a8.091 8.091 0 0 0 4.878 7.907 8.081 8.081 0 0 0 3.208.661h43.739c5.773 0 10.551-1.331 10.551-4.418 0-3.047-9.356-5.834-13.974-8.944-7.638-5.143-16.678-23.666-16.678-23.666"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
