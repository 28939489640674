import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-4">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-4-a)"
            d="M386.199 325.93a23.66 23.66 0 0 1-22.76-17.725l-.062.036-.434-1.066a205.561 205.561 0 0 1-7.387-22.369c-1.96-7.156-3.698-14.525-5.314-22.531a162.42 162.42 0 0 1-3.174-22.805 975.09 975.09 0 0 1-.436-6.888c-.318-5.222-.647-10.62-1.182-15.9a32.039 32.039 0 0 1 28.615-35.06 32.87 32.87 0 0 1 3.26-.165 31.887 31.887 0 0 1 31.801 28.781l.017.17c.505 5.286 1.235 10.648 1.942 15.833.31 2.279.622 4.558.915 6.839a161.97 161.97 0 0 1 1.334 22.991 352.757 352.757 0 0 1-.488 17.686 599.477 599.477 0 0 1 22.485-3.392c1.467-.183 2.926-.373 4.385-.562 2.959-.384 6.02-.78 9.045-1.115 4.297-.51 8.883-1.045 13.467-1.476 1.6-.155 3.2-.315 4.803-.48a676.12 676.12 0 0 1 8.681-.842c1.498-.11 2.978-.23 4.459-.351 1.209-.098 2.435-.197 3.669-.291.504 11.328 3.54 25.497 7.253 33.597-1.327.443-2.648.874-3.949 1.299-1.419.464-2.837.927-4.253 1.401-2.786.887-5.565 1.733-8.344 2.58-1.542.469-3.082.941-4.623 1.414-4.387 1.371-8.826 2.65-12.995 3.842-2.914.857-5.89 1.673-8.769 2.463-1.418.388-2.836.779-4.253 1.172-9.581 2.595-18.161 4.726-26.236 6.516l-13.168 2.883-13.15 2.942a23.64 23.64 0 0 1-5.154.573Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-4-a"
                x1={308.568}
                x2={443.243}
                y1={169.974}
                y2={309.202}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
