import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6369)"
            d="M541.443 345.689c-1.643-19.047-9.339-34.034-11.686-39.734-4.435-10.769-5.245-21.785-2.281-31.018 1.718-5.35 1.501-12.026-.645-19.843-5.893-21.468-25.107-63.041-52.274-82.895a89.652 89.652 0 00-9.312-5.855s1.383 9.149-2.196 14.191a9.767 9.767 0 01-5.679 3.396 22.506 22.506 0 01-3.997.36c-7.456.001-14.533-3.373-23-10.929-1.916-1.71-3.864-3.777-5.926-5.965-4.214-4.473-8.978-9.497-14.872-12.905-17.725 7.173-34.143 16.035-48.888 27.894a50.224 50.224 0 00-18.456 40.175l.457 19.058a327.329 327.329 0 0010.715 77.382c.033 3.805.034 7.485.036 11.171.011 28.391 1.924 56.478 5.373 84.666 32.369-22.478 61.297-11.568 97.519-7.586 33.729 3.709 63.99 2.378 75.052-13.643 0 0 12.474-19.943 10.06-47.92z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6369)"
            d="M345.73 279.723h180.63a35.76 35.76 0 011.2-4.986c1.717-5.35 1.501-12.025-.646-19.842a140.507 140.507 0 00-4.493-13.348H342.775a354.635 354.635 0 002.955 38.176z"
        ></path>
        <path
            fill="url(#paint2_linear_10977_6369)"
            d="M442.647 181.6l-.105-.053c.034.016.069.036.104.052l.001.001z"
        ></path>
        <path
            fill="url(#paint3_linear_10977_6369)"
            d="M474.641 171.999a89.758 89.758 0 00-9.312-5.855s1.068 7.086-1.116 12.223a10.486 10.486 0 01-1.08 1.968 9.774 9.774 0 01-5.679 3.396 22.514 22.514 0 01-3.997.36 25.302 25.302 0 01-10.81-2.492h-.002l-.104-.053a45.097 45.097 0 01-7.403-4.548c-.056-.042-.113-.088-.17-.13a64.525 64.525 0 01-2.934-2.336c-.154-.13-.311-.271-.465-.404-.37-.317-.739-.632-1.112-.965-1.916-1.71-3.864-3.777-5.926-5.966l-.003-.002c-4.213-4.472-8.976-9.494-14.869-12.902-17.725 7.173-34.143 16.035-48.888 27.893a50.22 50.22 0 00-18.456 40.175l.11 4.587h173.724c-9.462-19.821-23.792-42.002-41.508-54.949z"
        ></path>
        <path
            fill="url(#paint4_linear_10977_6369)"
            d="M351.41 310.603h180.363a201.422 201.422 0 00-1.932-4.848 54.323 54.323 0 01-3.382-11.72H347.961a301.388 301.388 0 003.449 16.568z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6369"
                x1="311.254"
                x2="519.447"
                y1="292.127"
                y2="276.513"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#22ADE0"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6369"
                x1="262.006"
                x2="664.512"
                y1="262.123"
                y2="258.653"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_10977_6369"
                x1="261.379"
                x2="663.766"
                y1="183.136"
                y2="179.668"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_10977_6369"
                x1="261.405"
                x2="663.911"
                y1="192.357"
                y2="188.887"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint4_linear_10977_6369"
                x1="262.365"
                x2="664.871"
                y1="303.849"
                y2="300.379"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
