import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6536)"
            d="M403.391 352.069a22.335 22.335 0 01-16.272-6.77c-1.854-1.932-3.188-4.837-4.475-7.136a187.825 187.825 0 01-9.203-19.796c-2.566-6.375-4.945-12.968-7.274-20.158a147.626 147.626 0 01-5.303-20.647c-.38-2.09-.746-4.186-1.112-6.283-.831-4.768-1.691-9.699-2.73-14.497a27.323 27.323 0 013.755-20.711 27.318 27.318 0 0117.299-11.989 27.703 27.703 0 015.848-.629 27.646 27.646 0 0126.851 21.683l.031.142c1.014 4.809 2.244 9.659 3.434 14.349a779.22 779.22 0 011.555 6.197 147.358 147.358 0 013.603 21.016c.775 7.154 1.287 13.837 1.563 20.384a225.627 225.627 0 0122.321-5.166c1.252-.218 2.497-.446 3.742-.674 2.546-.465 5.179-.947 7.802-1.328l5.77-.905c1.257-.194 2.52-.36 3.782-.525.682-.09 1.363-.181 2.045-.273 5.832-.768 10.602-1.297 15.018-1.667l1.4-.119-.347 1.361c-2.396 9.424 1.625 20.555 6.726 25.465l1.052 1.013-1.324.615c-4.01 1.864-8.411 3.768-13.848 5.992-.646.259-1.285.52-1.922.78-1.178.482-2.355.963-3.54 1.417l-5.471 2.082c-2.454.964-4.976 1.851-7.415 2.708-1.196.42-2.394.841-3.588 1.273a225.207 225.207 0 01-22.419 6.699l-11.334 2.688-11.279 2.822c-1.55.389-3.142.586-4.74.587h-.001z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6536"
                x1="346.502"
                x2="480.526"
                y1="265.134"
                y2="328.893"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
