import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#3A383A"
                d="M658.485 693.361c-8.147 0-18.557-.107-21.889-.619a70.704 70.704 0 01-20.149-6.101 41.124 41.124 0 00-12.078-3.678l-20.113-2.955a9.914 9.914 0 01-6.744-4.163 9.891 9.891 0 01-1.596-7.755c3.473-16.752 5.434-27.751 5.524-30.973l.379-12.972 36.294 7.074-2.426 10.44c2.322 3.227 19.908 27.114 37.34 35.551 4.3 2.081 9.666 4.934 12.481 6.449a5.159 5.159 0 012.047 2.02c.478.853.704 1.824.651 2.801a5.156 5.156 0 01-5.119 4.871c-1.312.005-2.886.01-4.602.01z"
            ></path>
            <path
                fill="#004F71"
                d="M665.536 683.617c-2.8-1.517-8.142-4.376-12.464-6.482-7.431-3.622-14.882-10.058-21.162-16.497a161.236 161.236 0 01-6.037-6.555c-3.416-.419-14.414 4.399-20.17 4.191-11.535-.416-19.614-5.62-26.604-5.829a956.7 956.7 0 01-3.113 15.363 9.909 9.909 0 001.575 7.759 9.911 9.911 0 006.734 4.182l20.105 3.008c4.189.609 8.26 1.86 12.068 3.711a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.128.139 5.122.506 18.089.556 25.362.541a5.163 5.163 0 005.132-4.857 5.174 5.174 0 00-2.686-4.828z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M577.561 675.566a9.906 9.906 0 006.733 4.182l20.106 3.009a41.113 41.113 0 0112.068 3.71 70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.128.139 5.122.506 18.089.556 25.362.541"
            ></path>
        </g>
    );
}
