import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 74,
        height: 28,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M72.371 6.893h-71.1"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.073 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.916.632 7.962-4.253 9.037-10.912Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M44.61 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.036-10.912Z"
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.073 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.916.632 7.962-4.253 9.037-10.912ZM44.61 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.036-10.912Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={61.984}
                        x2={61.984}
                        y1={26.178}
                        y2={1.957}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.301} stopColor="#050D90" />
                        <stop offset={0.441} stopColor="#0332AD" />
                        <stop offset={0.58} stopColor="#0251C5" />
                        <stop offset={0.699} stopColor="#0264D3" />
                        <stop offset={0.782} stopColor="#026BD9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={37.521}
                        x2={37.521}
                        y1={26.178}
                        y2={1.958}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.301} stopColor="#050D90" />
                        <stop offset={0.441} stopColor="#0332AD" />
                        <stop offset={0.58} stopColor="#0251C5" />
                        <stop offset={0.699} stopColor="#0264D3" />
                        <stop offset={0.782} stopColor="#026BD9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 74,
        height: 28,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M72.862 6.896h-71.1"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.564 15.173c1.075-6.658-1.227-12.57-5.142-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.075 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.037-10.912Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M45.1 15.173c1.076-6.658-1.226-12.57-5.14-13.202-3.916-.632-7.962 4.253-9.038 10.912-1.075 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.037-10.912Z"
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.564 15.173c1.075-6.658-1.227-12.57-5.142-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.075 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.037-10.912ZM45.1 15.173c1.076-6.658-1.226-12.57-5.14-13.202-3.916-.632-7.962 4.253-9.038 10.912-1.075 6.659 1.226 12.57 5.142 13.202 3.915.632 7.96-4.253 9.037-10.912Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={62.474}
                        x2={62.474}
                        y1={26.179}
                        y2={1.958}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={38.011}
                        x2={38.011}
                        y1={26.178}
                        y2={1.957}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 74,
        height: 28,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M72.885 6.893h-71.1"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.587 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.916-.632-7.962 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.915.632 7.961-4.253 9.037-10.912Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M45.124 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.915.632 7.961-4.253 9.037-10.912Z"
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.587 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.916-.632-7.962 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.915.632 7.961-4.253 9.037-10.912ZM45.124 15.173c1.076-6.658-1.226-12.57-5.141-13.202-3.915-.632-7.961 4.253-9.037 10.912-1.076 6.659 1.226 12.57 5.141 13.202 3.915.632 7.961-4.253 9.037-10.912Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={62.497}
                        x2={62.497}
                        y1={26.178}
                        y2={1.957}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={38.034}
                        x2={38.034}
                        y1={26.177}
                        y2={1.956}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 75,
        height: 27,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M33.08 6.37H1.93M73.03 6.37h-3.79M57.55 6.37H44.77"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.726 14.65C70.8 7.99 68.499 2.08 64.584 1.448 60.67.815 56.624 5.7 55.547 12.359c-1.075 6.66 1.227 12.57 5.142 13.202 3.915.633 7.96-4.252 9.037-10.911Z"
                    opacity={0.6}
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M45.265 14.65C46.34 7.99 44.038 2.08 40.123 1.448c-3.915-.633-7.96 4.253-9.036 10.911-1.076 6.66 1.226 12.57 5.14 13.202 3.916.633 7.962-4.252 9.038-10.911Z"
                    opacity={0.6}
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.726 14.65C70.8 7.99 68.499 2.08 64.584 1.448 60.67.815 56.624 5.7 55.547 12.359c-1.075 6.66 1.227 12.57 5.142 13.202 3.915.633 7.96-4.252 9.037-10.911ZM45.265 14.65C46.34 7.99 44.038 2.08 40.123 1.448c-3.915-.633-7.96 4.253-9.036 10.911-1.076 6.66 1.226 12.57 5.14 13.202 3.916.633 7.962-4.252 9.038-10.911Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={62.637}
                        x2={62.637}
                        y1={25.654}
                        y2={1.433}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={38.176}
                        x2={38.176}
                        y1={25.654}
                        y2={1.434}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 74,
        height: 27,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M57.04 6.37H44.26M32.57 6.37H1.42M72.52 6.37h-3.79"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.218 14.65c1.075-6.659-1.226-12.57-5.142-13.202-3.915-.633-7.96 4.253-9.036 10.911-1.076 6.66 1.226 12.57 5.14 13.202 3.916.633 7.962-4.252 9.038-10.911Z"
                    opacity={0.7}
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M44.755 14.65C45.83 7.99 43.529 2.08 39.614 1.448 35.698.815 31.652 5.7 30.577 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.915.633 7.961-4.252 9.037-10.911Z"
                    opacity={0.7}
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.218 14.65c1.075-6.659-1.226-12.57-5.142-13.202-3.915-.633-7.96 4.253-9.036 10.911-1.076 6.66 1.226 12.57 5.14 13.202 3.916.633 7.962-4.252 9.038-10.911ZM44.755 14.65C45.83 7.99 43.529 2.08 39.614 1.448 35.698.815 31.652 5.7 30.577 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.915.633 7.961-4.252 9.037-10.911Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={62.128}
                        x2={62.128}
                        y1={25.655}
                        y2={1.434}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={37.666}
                        x2={37.666}
                        y1={25.654}
                        y2={1.433}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 74,
        height: 27,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M32.6 6.37H1.44M72.54 6.37h-3.79M57.06 6.37H44.29"
                />
                <path
                    fill="url(#object-devices-sunglasses-2-a)"
                    d="M69.241 14.65C70.317 7.99 68.015 2.08 64.1 1.448 60.185.815 56.139 5.7 55.063 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.916.633 7.961-4.252 9.037-10.911Z"
                    opacity={0.7}
                />
                <path
                    fill="url(#object-devices-sunglasses-2-b)"
                    d="M44.778 14.65c1.076-6.659-1.226-12.57-5.141-13.202C35.722.815 31.676 5.7 30.6 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.916.633 7.962-4.252 9.037-10.911Z"
                    opacity={0.7}
                />
                <path
                    stroke="#111011"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M69.241 14.65C70.317 7.99 68.015 2.08 64.1 1.448 60.185.815 56.139 5.7 55.063 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.916.633 7.961-4.252 9.037-10.911ZM44.778 14.65c1.076-6.659-1.226-12.57-5.141-13.202C35.722.815 31.676 5.7 30.6 12.359c-1.076 6.66 1.226 12.57 5.141 13.202 3.916.633 7.962-4.252 9.037-10.911Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-2-a"
                        x1={62.151}
                        x2={62.151}
                        y1={25.655}
                        y2={1.434}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-2-b"
                        x1={37.689}
                        x2={37.689}
                        y1={25.654}
                        y2={1.433}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-sunglasses-2"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
