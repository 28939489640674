import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="female-bold-34-25-nose-3" transform="translate(316, 30)">
        <path
            d="M153.8 96c-.1 5.8-1.4 15.9 6.5 19.9 1.9 1 2.7 4-1.1 6-1.2.6-2.2 1-3.4 1.4"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M153.8 96c-.1 5.8-1.4 15.9 6.5 19.9 1.9 1 2.7 4-1.1 6-1.2.6-2.2 1-3.4 1.4"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M153.8 96c-.1 5.8-1.4 15.9 6.5 19.9 1.9 1 2.7 4-1.1 6-1.2.6-2.2 1-3.4 1.4"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M153.8 96c-.1 5.8-1.4 15.9 6.5 19.9 1.9 1 2.7 4-1.1 6-1.2.6-2.2 1-3.4 1.4"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
