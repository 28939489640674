import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-2">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-2-a)"
            d="M403.951 322.412a23.588 23.588 0 0 1-20.241-11.586 24.94 24.94 0 0 1-3.232-3.95 201.045 201.045 0 0 1-11.282-20.395c-3.209-6.591-6.24-13.434-9.267-20.925a158.58 158.58 0 0 1-7.215-21.587c-.565-2.197-1.114-4.402-1.663-6.606-1.251-5.02-2.544-10.21-4.018-15.246a32.038 32.038 0 0 1 21.725-39.7 32.08 32.08 0 0 1 9.001-1.296 32.161 32.161 0 0 1 30.699 23.021l.048.168c1.446 5.043 3.125 10.118 4.75 15.025.715 2.161 1.431 4.321 2.129 6.486a158.47 158.47 0 0 1 5.44 22.107c1.444 7.943 2.54 15.347 3.349 22.628.169 1.567.327 3.136.475 4.707l6.087-.059c1.078-.007 2.154-.011 3.228-.01 7.34 0 15.101.166 23.729.507 1.47.07 2.934.131 4.396.194 2.978.127 6.057.259 9.091.444 4.324.232 8.934.487 13.487.845 1.575.117 3.152.23 4.732.34 2.916.206 5.831.413 8.748.658 1.496.146 2.977.281 4.459.417 2.966.27 6.034.549 9.061.916-4.334 9.615-2.527 22.411.887 32.966-2.999.529-6.042.972-8.985 1.4-1.477.215-2.954.43-4.43.656-2.903.401-5.791.762-8.68 1.124-1.582.197-3.163.397-4.745.6-4.56.604-9.133 1.105-13.427 1.567-3.012.348-6.08.645-9.046.932-1.457.141-2.914.284-4.371.43-9.845.921-18.641 1.556-26.897 1.944l-13.434.593-13.425.657c-.389.019-.776.028-1.162.028h-.001Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-2-a"
                x1={319.658}
                x2={448.477}
                y1={197.138}
                y2={294.078}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
