import { IBodyClothes } from 'interfaces';

import Dress1 from './female-34-25-dress-1';
import P1Sitting from './female-34-25-dress-1-p1-sitting';
import P2Sitting from './female-34-25-dress-1-p2-sitting';

const dress1: IBodyClothes = {
    text: 'dress #1',
    disableLegsClothes: true,
    allowWithoutSleeves: true,
    normal: {
        front: Dress1,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
    sitting: {
        front: P1Sitting,
        front2: P2Sitting,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
};

export default dress1;
