import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M494.767 223.4a2.699 2.699 0 0 1 .821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0 1 21.853 16.353Z"
            fill="url(#a)"
        />
        <path
            d="M559.936 277.9c-.027-.957-.083-1.917-.139-2.877a247.866 247.866 0 0 0-.349-5.557s-9.04.437-16.305-2.274c-9.913-3.699-13.312-9.001-13.312-9.001-.764 2.11-1.541 4.191-2.322 6.7a187.804 187.804 0 0 0-11.02-15.574 872.316 872.316 0 0 0-4.563-5.793c-3.477-4.395-7.142-8.976-10.512-13.558l-.078-.105a27.634 27.634 0 0 0-22.097-11.103 27.272 27.272 0 0 0-16.376 5.426 27.531 27.531 0 0 0-5.677 38.475c3.387 4.56 6.673 9.325 9.851 13.933 1.399 2.029 2.802 4.054 4.208 6.075a197.064 197.064 0 0 0 15.319 19.263c5.797 6.538 11.33 12.43 16.917 18.014a248.571 248.571 0 0 0 18.513 16.882c.401.323.842.644 1.38 1.006a19.288 19.288 0 0 0 11.523 5.477 19.47 19.47 0 0 0 15.16-4.859 19.465 19.465 0 0 0 6.352-12.395c.443-4.032 1.059-8.121 1.654-12.077.262-1.74.524-3.48.773-5.223a128.086 128.086 0 0 0 1.253-17.609c-.004-.954.004-1.905.012-2.858.015-1.954.031-3.975-.05-5.971l-.115-4.417Z"
            fill="url(#b)"
        />
        <defs>
            <linearGradient
                id="a"
                x1={472.01}
                y1={214.166}
                x2={560.058}
                y2={315.66}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="b"
                x1={465.686}
                y1={219.652}
                x2={553.735}
                y2={321.146}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
