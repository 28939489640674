import React from 'react';

const SvgComponent = () => (
    <g id="man-bold-34-25-emotion-wide-smile" transform="translate(329, 20)">
        <path
            stroke="url(#man-bold-34-25-emotion-wide-smile-a)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M161.949 74.3c-2.5-1.2-6.2-1.8-8.8-2.1"
        />
        <path
            stroke="url(#man-bold-34-25-emotion-wide-smile-b)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M139.65 72.3c-6.4 0-13.1.7-17.3 2.5"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M162.75 84.6c-4.2-1.3-8.4-1-12.5.9m-13.1-.9c-4.2-1.3-8.4-1-12.5.9"
        />
        <path
            fill="#fff"
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M128.65 109.9c6 10.2 16 12.4 23 3.9-7.3-.2-15-1.6-23-3.9Z"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-emotion-wide-smile-a"
                x1={164.453}
                x2={150.666}
                y1={73.299}
                y2={73.299}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-emotion-wide-smile-b"
                x1={142.146}
                x2={119.809}
                y1={73.512}
                y2={73.512}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
