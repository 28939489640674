import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 131,
        height: 43,
        content: (
            <>
                <path
                    fill="#FFD100"
                    d="M85.058.676H45.16c-7.623 0-13.803 6.18-13.803 13.804 0 7.623 6.18 13.804 13.803 13.804h39.9c7.623 0 13.803-6.18 13.803-13.804 0-7.624-6.18-13.804-13.803-13.804Z"
                />
                <path
                    fill="url(#object-food-scallop-a)"
                    d="M129.177 19.316A40.7 40.7 0 0 1 93.06 41.253H37.156A40.7 40.7 0 0 1 1.04 19.316c10.68 0 10.68-4 21.36-4s10.678 4 21.358 4 10.678-4 21.356-4 10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M93.06 41.252H37.156A40.7 40.7 0 0 1 1.04 19.315c5.294 0 7.964-.983 10.611-1.975M22.398 15.316c5.34 0 8.01 1 10.68 2 2.668 1 5.34 2 10.678 2 10.678 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4a40.67 40.67 0 0 1-16.114 16.682"
                />
                <defs>
                    <linearGradient
                        id="object-food-scallop-a"
                        x1={121.233}
                        x2={62.117}
                        y1={80.158}
                        y2={17.253}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.006} stopColor="#64CCC9" />
                        <stop offset={1} stopColor="#BBE9E9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Option #2': {
        width: 131,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-food-scallop-a)"
                    d="M126.813 33.587c-2.576-3.982-8.875-5.023-12.169-8.404-1.502-1.544-2.5-3.701-3.531-5.837l-.011-.012c-1.202-2.553-2.425-5.075-4.561-6.534-3.885-2.663-10.098-1.192-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.56-.923-9.7 2.854-14.519 2.854-4.818 0-9.969-3.777-14.519-2.854-4.7.944-7.973 6.428-12.287 8.252-4.378 1.845-10.592.374-14.476 3.037-2.136 1.458-3.37 4.002-4.582 6.556-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.422-12.169 8.404-1.857 2.872-1.503 6.923-1.855 10.63a2.962 2.962 0 0 0 1.328 2.779l37.837 24.51a7.904 7.904 0 0 1 3.607 6.633v3.636h41.274V78.14a7.904 7.904 0 0 1 3.607-6.633l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.708.003-7.757-1.863-10.63Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M126.813 33.587c-2.576-3.982-8.875-5.023-12.169-8.404-1.502-1.544-2.5-3.701-3.531-5.837l-.011-.012c-1.202-2.553-2.425-5.075-4.561-6.534-3.885-2.663-10.098-1.192-14.476-3.037-4.314-1.824-7.587-7.308-12.277-8.252-4.56-.923-9.7 2.854-14.519 2.854-4.818 0-9.969-3.777-14.519-2.854-4.7.944-7.973 6.428-12.287 8.252-4.378 1.845-10.592.374-14.476 3.037-2.136 1.458-3.37 4.002-4.582 6.556-1.009 2.136-2.018 4.282-3.52 5.827-3.294 3.381-9.594 4.422-12.169 8.404-1.857 2.872-1.503 6.923-1.855 10.63a2.962 2.962 0 0 0 1.328 2.779l37.837 24.51a7.904 7.904 0 0 1 3.607 6.633v3.636h41.274V78.14a7.904 7.904 0 0 1 3.607-6.633l37.834-24.508a2.964 2.964 0 0 0 1.328-2.782c-.356-3.708.003-7.757-1.863-10.63Z"
                />
                <path
                    fill="#FFD100"
                    d="M85.219 47.676h-39.9c-7.622 0-13.803 6.18-13.803 13.804 0 7.624 6.18 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M45.32 47.676c-7.623 0-13.804 6.18-13.804 13.804 0 7.624 6.18 13.804 13.804 13.804h39.899c7.624 0 13.804-6.18 13.804-13.804 0-7.624-6.18-13.804-13.804-13.804H60.874"
                />
                <path
                    fill="url(#object-food-scallop-b)"
                    d="M129.337 66.318A40.7 40.7 0 0 1 93.22 88.255H37.316A40.7 40.7 0 0 1 1.2 66.318c10.68 0 10.68-4 21.36-4s10.678 4 21.358 4 10.678-4 21.356-4 10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M93.22 88.252H37.316A40.7 40.7 0 0 1 1.2 66.315c5.294 0 7.964-.983 10.611-1.975M22.559 62.318c5.34 0 8.01 1 10.679 2 2.669 1 5.34 2 10.679 2 10.678 0 10.678-4 21.356-4s10.677 4 21.355 4 10.678-4 21.355-4 10.677 4 21.355 4A40.67 40.67 0 0 1 113.224 83"
                />
                <defs>
                    <linearGradient
                        id="object-food-scallop-a"
                        x1={65.268}
                        x2={65.268}
                        y1={132.397}
                        y2={42.959}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.031} stopColor="#FFD100" />
                        <stop offset={0.165} stopColor="#FFD104" />
                        <stop offset={0.304} stopColor="#FFD310" />
                        <stop offset={0.445} stopColor="#FFD725" />
                        <stop offset={0.588} stopColor="#FFDC42" />
                        <stop offset={0.731} stopColor="#FFE367" />
                        <stop offset={0.873} stopColor="#FFEB94" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-scallop-b"
                        x1={121.393}
                        x2={62.277}
                        y1={127.16}
                        y2={64.255}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-scallop"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
