import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-2">
        <path
            d="M404.779 322.412a23.588 23.588 0 0 1-20.241-11.586 24.94 24.94 0 0 1-3.232-3.95 201.045 201.045 0 0 1-11.282-20.395c-3.209-6.591-6.24-13.434-9.267-20.925a158.438 158.438 0 0 1-7.214-21.587 898.07 898.07 0 0 1-1.664-6.606c-1.25-5.02-2.544-10.21-4.018-15.246a32.038 32.038 0 0 1 21.726-39.7 32.069 32.069 0 0 1 9.001-1.296 32.166 32.166 0 0 1 30.698 23.021l.049.168c1.445 5.043 3.125 10.118 4.75 15.025.714 2.161 1.43 4.321 2.128 6.486a158.47 158.47 0 0 1 5.44 22.107c1.444 7.943 2.54 15.347 3.349 22.628.169 1.567.327 3.136.475 4.707l6.087-.059c1.078-.008 2.154-.011 3.228-.011 7.34 0 15.102.166 23.73.508 1.47.069 2.933.131 4.396.194 2.977.126 6.056.258 9.09.444 4.324.231 8.935.487 13.487.845 1.575.117 3.152.23 4.732.34 2.916.207 5.831.413 8.748.658 1.496.146 2.977.281 4.459.417 2.966.27 6.034.548 9.061.916-4.334 9.615-2.527 22.41.887 32.965-2.999.53-6.042.973-8.985 1.401-1.477.215-2.954.43-4.43.656-2.902.401-5.791.762-8.68 1.124-1.582.197-3.163.397-4.745.6-4.56.604-9.133 1.105-13.427 1.567-3.012.348-6.08.645-9.046.932-1.457.14-2.914.284-4.371.43-9.845.921-18.641 1.556-26.897 1.944l-13.434.593-13.424.657c-.39.019-.777.028-1.163.028h-.001Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-2a)"
        />
        <path
            d="M414.084 219.34c.715 2.161 1.43 4.321 2.129 6.486a158.47 158.47 0 0 1 5.44 22.107c1.444 7.943 2.54 15.347 3.349 22.628.169 1.567.327 3.136.475 4.706l6.087-.058c1.078-.008 2.154-.011 3.228-.011 7.34 0 15.102.166 23.73.508 1.47.069 2.933.131 4.396.193 2.977.127 6.056.259 9.09.445 4.324.231 8.934.487 13.487.844 1.575.117 3.152.231 4.732.341 2.916.206 5.831.412 8.748.658 1.496.146 2.977.281 4.459.417 2.966.27 6.034.548 9.061.916-4.334 9.615-2.527 22.41.887 32.965-2.999.53-6.042.973-8.985 1.4-1.477.216-2.954.431-4.43.657-2.903.401-5.791.762-8.68 1.124-1.581.197-3.163.397-4.745.6-4.56.604-9.133 1.105-13.426 1.567-3.012.348-6.08.645-9.046.932-1.457.14-2.914.284-4.372.43-9.845.92-18.64 1.556-26.896 1.944l-13.435.593-13.425.657c-.389.019-.776.028-1.161.028h-.002a23.59 23.59 0 0 1-20.241-11.586 24.906 24.906 0 0 1-3.232-3.95 201.42 201.42 0 0 1-11.282-20.394c-3.209-6.591-6.24-13.435-9.267-20.925a158.45 158.45 0 0 1-7.214-21.588c-.565-2.196-1.114-4.401-1.664-6.605-1.25-5.02-2.544-10.21-4.018-15.246"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-2a"
                x1={489.641}
                y1={342.097}
                x2={229.4}
                y2={47.157}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
