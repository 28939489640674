import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m347.93 788.695-12.18 29.318 3.688 3.426 9.751-12.1c1.847-2.292 5.328-1.858 7.144.082"
                fill="#EF0025"
            />
            <path
                d="m335.75 818.016 3.688 3.427"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M359.851 799.261c4.343-7.309 10.651-11.834 8.881-23.536l23.856 23.638.434-3.888-26.494-27.756-15.778 15.811a13.9 13.9 0 0 0-.596 18.616l4.242 5.178a23.045 23.045 0 0 1 3.09 4.066c.231-3.083.179-8.451 2.365-12.129ZM391.784 836.918a15.87 15.87 0 0 1-1.063-1.896l-.17-.352-.365-.143a32.43 32.43 0 0 0-2.983-1.017c-4.617-1.317-7.55-2.065-11.347-.757a14.639 14.639 0 0 0-8.502 7.434l-.266.577.411.486c.763.914 1.593 1.77 2.482 2.562l4.122 3.629c1.954 1.719 2.806 3.098 5.136 4.425l.843.481.506-.829c2.021-3.313 4.216-6.095 7.905-6.981 2.05-.47 4.178-.488 6.236-.052l2.377.488-5.322-8.055Z"
                fill="#EF0025"
            />
            <path
                d="M396.617 858.908c-1.425.388-5.336-1.147-6.744-1.8-2.569-1.192-5.597-2.591-8.166-3.784-3.386-1.571-9.963-6.564-12.703-9.402-4.125-4.271-7.324-9.328-8.069-15.22l-1.313-10.36a22.45 22.45 0 0 0-5.484-11.468l-3.556-4.824a15.928 15.928 0 0 1-3.222-10.355"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="m397.648 842.742 8.095 32.58 7.113.302-.65-9.371" fill="#EF0025" />
            <path
                d="M405.742 875.32h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m403.557 824.209 32.31 1.229-1.244-3.235-34.428-1.844-3.525 14.508a20.64 20.64 0 0 0 6.369 19.306 28.112 28.112 0 0 1 2.825 3.11l1.676 2.115.107-2.697c.092-2.336.361-4.733.621-7.05.51-4.545 1.038-9.246.297-13.886-.518-3.245-2.823-8.199-5.008-11.556Z"
                fill="url(#afemale-34-25-shoes-11-stand-2-)"
            />
            <path
                d="m448.219 852.083-.607-.013a67.009 67.009 0 0 0-13.665.537 34.618 34.618 0 0 0-9.828 2.673 18.263 18.263 0 0 0-7.917 6.684 18.005 18.005 0 0 0-2.258 5.821l-.094.437 1.524 2.107a15.035 15.035 0 0 0 12.215 6.063h1.074l-.008-1.008a12.332 12.332 0 0 1 4.338-9.398 16.284 16.284 0 0 1 6.888-3.135 51.296 51.296 0 0 1 7.891-.916l10.96-.694-10.513-9.158Z"
                fill="url(#bfemale-34-25-shoes-11-stand-2-)"
            />
            <path
                d="M459.311 873.833a9.32 9.32 0 0 1-6.503 2.561h-25.219a15.036 15.036 0 0 1-12.214-6.062s-15.175-19.202-16.207-20.934"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-11-stand-2-a"
                    x1={456.616}
                    y1={848.297}
                    x2={388.086}
                    y2={833.839}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-11-stand-2-b"
                    x1={452.565}
                    y1={867.504}
                    x2={384.035}
                    y2={853.046}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
            </defs>
        </g>
    );
}
