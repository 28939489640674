import { IBodyClothes } from 'interfaces';

import Dress2 from './female-34-25-dress-2';
import P1Sitting from './female-34-25-dress-2-p1-sitting';
import P2Sitting from './female-34-25-dress-2-p2-sitting';

const dress: IBodyClothes = {
    text: 'dress #2',
    disableLegsClothes: true,
    allowWithoutSleeves: true,
    normal: {
        front: Dress2,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
    sitting: {
        front: P1Sitting,
        front2: P2Sitting,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
};

export default dress;
