import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 408,
        height: 466,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M292.399 244.083v113.906c0 19.33-15.67 35-35 35h-91.684c-19.33 0-35-15.67-35-35V225.242h142.843M211.557 392.99v71.747"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M306.294 464.736v-75.588c0-8.284-6.716-15-15-15H131.82c-8.284 0-15 6.716-15 15v75.588M292.399 244.083H149.556V210.59c-34.391-51.063-95.885-31.37-127.626-39.114-19.078-4.654-27.004-23.539-15.762-34.781 7.138-7.137 22.55-7.673 48.758 4.674-9.979-6.189-16.535-21.462-8.589-28.295 9.484-8.154 26.147.127 47.495 28.169-3.788-10.484-3.126-17.628 3.538-19.579 15.662-4.584 31.073 19.452 42.441 58.61 18.695-95.495 0-104.084-7.074-104.084s-10.989 9.916-9.474 22.23c-3.979-22.925-16.105-49.061-30.315-46.41-8.753 1.635-12.295 12.457-6.19 23.674-9.725-21.411-30.67-28.756-39.915-16.168-4.253 5.79-1.455 16.408 15.063 28.895 22.515 17.021 35.655 13.162 61.357 29.905 38.551 25.112 50.589 51.158 55.453 88.085h31.326c-2.933-70.864 12.687-111.676 23.621-133.117 22.611-44.337 22.854-65.774 6.253-70.8-20.653-6.253-28.681 37.288-29.874 48.947-2.526-22.358-9.726-35.242-21.221-32.842-24.505 7.579.19 46.863 3.79 79.642-5.811-16.547-15.063-26.432-24.916-21.505-16.887 8.443 9.871 42.481 25.295 60.537-13.137-8.4-20.745-3.629-21.853 2.842-2.4 14.021 26.526 46.989 67.705 57.853 4.169-59.179 63.408-82.677 89.432-98.526 15.347-9.347 21.928-18.518 15.726-27.537-6.078-8.84-21.758-3.884-29.368 2.653 30.505-27.095 40.59-42.934 26.991-52.756-7.426-5.364-28.885-3.328-42.149 24.082 4.042-19.58 0-32.337-10.105-32.337-11.495 0-17.368 25.2-10.547 64.61-2.463-17.305-12.568-21.158-18.253-18.631-10.898 4.844-11.684 31.137-3.726 59.558-3.174-8.953-10.421-15.537-17.242-11.937-10.631 5.611-5.811 52.421 27.032 85.263 9.347-41.305 33.347-63.285 45.6-56.148 5.519 3.215 5.179 9.664-.442 19.579 18-20.147 42.842-31.419 50.594-20.582 3.88 5.424-.435 14.33-7.204 19.635 23.116-13.263 49.822-6.276 51.591 7.621 1.467 11.528-11.296 19.284-20.517 19.284-12.564 0-25.986-8.238-55.042-4.831-26.185 3.07-44.768 21.492-57.651 48.284"
                />
            </>
        ),
    },
    'Outline #2': {
        width: 338,
        height: 480,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m142.043 460.955.755 17.25h14.841l2.15-49.098M114.642 429.115l-5.13 15.54-11.07 33.55h-14.84l9.94-41M206.892 437.209l9.94 41h-14.84l-11.07-33.55"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.855 426.298c-8.02-10.09-12.44-23.08-11.53-36.92 2.29-35.04 4.59-70.09 6.89-105.13h146.02l6.89 105.13c1.28 19.57-8.09 37.44-23.23 47.83M93.543 437.209a53.33 53.33 0 0 0 15.97 7.45c2.93.82 5.96 1.4 9.09 1.71 9.36.94 19.34 1.53 29.85 1.59 11.85.06 23.03-.54 33.44-1.6 3.1-.31 6.12-.89 9.03-1.7M64.178 115.238c6.962-22.407 7.646-37.827-.886-49.677-5.804-8.061-17.859-9.925-24.978-5.08-8.136 5.537-10.503 16.058-8.952 24.762 4.91 27.547 21.742 53.869 42.795 73.701 0-43.579 19.81-65.913 37.494-65.913 7.074 0 14.371 4.052 14.371 12.736 0 19.652-40.106 20.997-36.043 79.291-21.623-20.264-44.64-31.313-61.222-31.313-14.496 0-25.222 8.578-25.222 21.936 0 12.695 9.684 17.116 21.25 17.116 13.378 0 51.802-5.253 79.034 21.979-7.597-35.561 14.589-80.211 34.232-80.211 10.168 0 15.347 7.768 15.347 13.958 0 25.326-42.505 31.222-39.872 94.295-30.422-15.537-46.59-20.829-58.717-20.829-4.795 0-20.968 3.145-20.968 17.545 0 8.046 8.988 16.547 19.601 16.547 18.547 0 29.6-4.223 48.126.505 10.854 2.77 17.576 10.937 21.941 15.909-1.482-44.33 21.518-91.457 40.977-88.352 11.081 1.768 3.53 38.335-21.003 88.352 35.19-59.667 106.726-78.373 151.383-71.361 36.323 5.704 39.986 38-10.124 29.777-33.968-5.574-87.117 1.305-106.065 41.583-1.847-31.698 32.764-97.383 92.89-97.383 29.368 0 40.358-8.337 40.358-20.084 0-7.958-8.779-15.158-29.368-15.158-37.922 0-87.032 34.232-99.663 75.031 10.611-54.947-2.847-103.899-43.326-132.38-20.091-14.134-19.434-39.866.126-45.725 26.186-7.844 74.526 45.347 62.147 123.158 42.821-45.853 77.543-36.409 103.857-44.803 18.998-6.06 33.434-14.341 32.624-27.766-.769-12.742-15.711-17.681-33.407-15.726-40.522 4.478-77.041 38.437-84.316 50.968-6.632-38.274-11.233-67.662-.244-99.873 5.714-16.747 20.034-22.977 29.76-20.064 9.818 2.94 14.987 15.539 14.063 27.663-1.496 19.606-15.347 42.006-20.505 47.747M114.645 429.115h45.147"
                />
            </>
        ),
    },
    'Outline #3': {
        width: 170,
        height: 609,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M93.093 323.509H51.154c-9.278 0-16.8-7.522-16.8-16.8v-89.305H151.83v89.305c0 9.278-7.522 16.8-16.8 16.8h-16.8"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M53.195 607.973v-52.906h98.633v-18.841H53.195V266.215h79.792v341.758M93.092 323.51v284.463M93.092 217.392v-61.769c-8.615 14.413-35.368 44.476-60.353 50.021 3.722-6.105 13.87-35.482 12.505-44.716-6.588 6.588-34.422 7.784-39.032 6.821a126.413 126.413 0 0 0 29.937-20.842 58.28 58.28 0 0 1-18.625-7.389 58.318 58.318 0 0 1-16.036-14.59 142.565 142.565 0 0 0 41.861 0 70.54 70.54 0 0 1-9.412-21.826 70.218 70.218 0 0 1-1.957-16.827 153.822 153.822 0 0 0 29.937 25.39 67.787 67.787 0 0 0 5.306-8.337 67.832 67.832 0 0 0 5.305-12.885 131.248 131.248 0 0 1 3.031 28.8 39.12 39.12 0 0 0 15.536-6.819 40.437 40.437 0 0 1-.947 12.085 40.414 40.414 0 0 1-4.358 11.031c6.268.896 15.576 3.021 25.567 8.514a74.825 74.825 0 0 1 15.739 11.57 47.158 47.158 0 0 0-.618-10.85 47.264 47.264 0 0 0-4.687-13.781 79.072 79.072 0 0 0 20.849 1.705 78.953 78.953 0 0 0 15.909-2.463 80.982 80.982 0 0 1-23.874-14.399 89.917 89.917 0 0 0 29.179-12.505 161.163 161.163 0 0 1-33.537-4.547 200.707 200.707 0 0 0 28.989-26.905c-14.3 4.939-42.468 9.992-48.91 9.108a107.643 107.643 0 0 0 13.642-31.074c-2.87 3.626-8.732 9.949-18.228 13.776a43.347 43.347 0 0 1-11.708 2.898c.231-2.708.27-7.314-1.516-12.6-2.14-6.339-5.814-10.433-7.858-12.412a83.733 83.733 0 0 1-13.743 32.969c-12.884 4.547-42.83.884-50.753-3.424a109.164 109.164 0 0 0 24.22-7.2 108.757 108.757 0 0 0 13.295-6.82c-3.36-3.733-8.208-10.095-11.368-19.156a56.02 56.02 0 0 1-3.03-15.329A119.239 119.239 0 0 0 71.01 36.636 81.202 81.202 0 0 1 75.18 1.395c1.711 3.51 4.736 8.553 9.92 13.238a43.003 43.003 0 0 0 7.99 5.71l21.121-18.947a83.74 83.74 0 0 1-.379 25.01c3.112.394 11.721-2.26 20.942-6.681 0 5.052-1.597 12.929-6.921 19.565 10.358 0 20.716-3.208 31.074-9.474a327.385 327.385 0 0 1-15.726 30.126 328.222 328.222 0 0 1-35.621 49.264l60.821 61.2a67.782 67.782 0 0 1-29.936-1.895 60.576 60.576 0 0 1-3.411 29.178l-15.537-19.705a57.54 57.54 0 0 1-10.232 17.053l-4.926-23.874"
                />
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-plant"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
