import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-4-walk-2">
            <path
                fill="#001489"
                d="M538.658 861.123a10.058 10.058 0 0 1-9.871-8.484c-2.587-16.915-4.582-27.909-5.619-30.956l-4.73-11.709.717-1.352 33.235-7.102 1.172.712 3.218 11.723c3.014 2.027 28.041 18.507 47.459 20.353 4.775.453 10.787 1.254 13.947 1.693a5.151 5.151 0 0 1 3.684 7.796 5.161 5.161 0 0 1-2.575 2.131c-7.635 2.882-21.041 7.84-25.042 8.665a74.849 74.849 0 0 1-15.058 1.586 61.17 61.17 0 0 1-5.953-.282 39.19 39.19 0 0 0-3.799-.184 42.36 42.36 0 0 0-8.803.946l-19.882 4.241c-.69.148-1.394.223-2.1.223Z"
            />
            <path
                fill="url(#man-34-25-shoes-4-walk-2-a)"
                d="M620.027 845.066c-.09.042-.183.081-.278.118-7.692 3.043-21.2 8.281-25.245 9.172-9.24 2.031-54.229 7.032-54.229 7.032a10.04 10.04 0 0 1-7.938-1.518 10.41 10.41 0 0 1-2.921-2.978 10.419 10.419 0 0 1-1.513-3.887c-.804-4.597-1.616-9.092-2.349-12.999a1.015 1.015 0 0 1 .732-1.174 65.376 65.376 0 0 1 12.153-1.568c.708-.048 1.415-.094 2.12-.144 6.37-.459 12.918-1.433 17.633-5.425a33.09 33.09 0 0 0 4.762-5.405c.73-.958 1.485-1.948 2.28-2.888a24.306 24.306 0 0 1 2.384-2.451 1.004 1.004 0 0 1 1.145-.131c9.613 5.166 23.558 11.538 35.394 12.557 4.87.419 11 1.184 14.22 1.602a5.373 5.373 0 0 1 4.369 3.64 5.265 5.265 0 0 1-2.719 6.447Z"
            />
            <mask
                id="man-34-25-shoes-4-walk-2-b"
                width="99"
                height="42"
                x="525"
                y="820"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M620.027 845.066c-.09.042-.183.081-.278.118-7.692 3.043-21.2 8.281-25.245 9.172-9.24 2.031-54.229 7.032-54.229 7.032a10.04 10.04 0 0 1-7.938-1.518 10.41 10.41 0 0 1-2.921-2.978 10.419 10.419 0 0 1-1.513-3.887c-.804-4.597-1.616-9.092-2.349-12.999a1.015 1.015 0 0 1 .732-1.174 65.376 65.376 0 0 1 12.153-1.568c.708-.048 1.415-.094 2.12-.144 6.37-.459 12.918-1.433 17.633-5.425a33.09 33.09 0 0 0 4.762-5.405c.73-.958 1.485-1.948 2.28-2.888a24.306 24.306 0 0 1 2.384-2.451 1.004 1.004 0 0 1 1.145-.131c9.613 5.166 23.558 11.538 35.394 12.557 4.87.419 11 1.184 14.22 1.602a5.373 5.373 0 0 1 4.369 3.64 5.265 5.265 0 0 1-2.719 6.447Z"
                />
            </mask>
            <g fill="#001489" mask="url(#man-34-25-shoes-4-walk-2-b)">
                <path
                    d="M565.886 872.001c-6.106-7.5-2.56-20.607 2.158-27.896a60.139 60.139 0 0 1 12.745-13.613c4.359-3.486 11.342-6.267 13.804-11.479a2.352 2.352 0 0 0-2.62-3.408c-3.986.86-7.506 3.979-10.829 6.254a73.314 73.314 0 0 0-8.782 6.884c-5.216 4.882-10.408 10.335-13.243 16.987-3.747 8.797-6.343 24.535 4.041 29.804a2.26 2.26 0 0 0 2.726-3.533Z"
                />
                <path
                    d="M578.62 864.486c-4.41-7.909 1.359-18.23 5.88-24.88a41.923 41.923 0 0 1 9.949-10.455c3.497-2.53 7.578-4.166 10.364-7.559 1.498-1.824.149-4.892-2.429-4.172-10.784 3.01-19.878 12.115-25.5 21.478-5.05 8.41-8.454 19.242-2.332 27.966 1.719 2.449 5.568.313 4.068-2.378Z"
                />
                <path
                    d="M591.074 860.109c-2.922-8.467 1.718-15.548 7.15-21.823 4.678-5.405 11.141-8.95 15.691-14.304a2.765 2.765 0 0 0-2.668-4.583c-8.45 2.164-16.084 11.097-20.952 17.928-5.52 7.748-7.919 15.71-3.269 24.488 1.159 2.187 4.875.689 4.048-1.706Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M622.664 842.23a5.164 5.164 0 0 1-2.915 2.957c-7.692 3.043-21.201 8.281-25.245 9.171-9.241 2.031-54.229 7.032-54.229 7.032a10.044 10.044 0 0 1-7.939-1.518"
            />
            <path
                fill="#001489"
                d="M328.849 861.121a23.465 23.465 0 0 1-11.41-2.921 23.468 23.468 0 0 1-8.557-8.094l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.532-15.219 4.227-14.413 31.457 12.953-3.825 8.979 13.483 36.256a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.863 6.863 0 0 1-2.275 13.343h-26.424Z"
            />
            <path
                fill="url(#man-34-25-shoes-4-walk-2-c)"
                d="M328.849 861.122a23.476 23.476 0 0 1-19.967-11.015l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.606-15.336a190.545 190.545 0 0 0 9.246 20.666c2.548 4.717 9.434 6.1 15.831 4.771 9.922-2.06 12.394-2.614 12.394-2.614l7.797 21.069a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.864 6.864 0 0 1 4.493 7.632 6.867 6.867 0 0 1-6.768 5.711h-26.424Z"
            />
            <mask
                id="man-34-25-shoes-4-walk-2-d"
                width="76"
                height="69"
                x="287"
                y="793"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M328.849 861.122a23.476 23.476 0 0 1-19.967-11.015l-19.529-30.968a10.051 10.051 0 0 1-.017-10.643l9.606-15.336a190.545 190.545 0 0 0 9.246 20.666c2.548 4.717 9.434 6.1 15.831 4.771 9.922-2.06 12.394-2.614 12.394-2.614l7.797 21.069a15.763 15.763 0 0 0 9.577 9.406l3.761 1.321a6.864 6.864 0 0 1 4.493 7.632 6.867 6.867 0 0 1-6.768 5.711h-26.424Z"
                />
            </mask>
            <g fill="#001489" mask="url(#man-34-25-shoes-4-walk-2-d)">
                <path
                    d="M286.385 844.806c1.1-9.527 12.79-16.121 21.23-17.917a60.053 60.053 0 0 1 17.913-.573c5.932.603 12.99 3.782 18.856 1.949a2.348 2.348 0 0 0 1.435-3.252 2.34 2.34 0 0 0-.881-.989c-3.306-2.048-7.43-2.427-11.207-3.194a77.365 77.365 0 0 0-11.941-1.633c-7.26-.326-14.943-.204-21.756 2.588-8.842 3.624-21.525 12.557-18.069 23.619.798 2.553 4.137 1.846 4.42-.598Z"
                />
                <path
                    d="M300.66 848.68c2.168-7.635 11.35-10.679 18.268-12.419a45.84 45.84 0 0 1 15.446-1.342c5.152.485 10.08 2.926 15.29 2.469 2.348-.207 3.545-3.332 1.26-4.652-10.446-6.034-24.78-5.911-36.024-2.437-8.54 2.639-17.118 7.634-18.782 17.128-.515 2.936 3.699 4.221 4.542 1.253Z"
                />
                <path
                    d="M312.499 854.512c3.485-7.123 10.228-9.108 17.658-9.909a66.571 66.571 0 0 1 12.225-.184c3.987.306 7.936 1.407 11.942 1.141a2.767 2.767 0 0 0 1.384-5.11c-8.294-4.967-21.607-3.815-30.674-1.828-8.138 1.783-14.064 6.044-16.583 14.184-.73 2.358 2.932 3.988 4.048 1.706Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M355.272 861.124h-26.424a23.487 23.487 0 0 1-19.967-11.015l-19.529-30.968"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-4-walk-2-a"
                    x1="525.536"
                    x2="623.021"
                    y1="841.17"
                    y2="841.17"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".029" stopColor="#009CDE" />
                    <stop offset=".9" stopColor="#39BDE8" />
                    <stop offset="1" stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="man-34-25-shoes-4-walk-2-c"
                    x1="287.821"
                    x2="362.138"
                    y1="827.141"
                    y2="827.141"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".029" stopColor="#009CDE" />
                    <stop offset=".9" stopColor="#39BDE8" />
                    <stop offset="1" stopColor="#40C1E9" />
                </linearGradient>
            </defs>
        </g>
    );
}
