import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-3">
        <path
            d="M531.004 269.175a365.73 365.73 0 0 1-17.77-6.269 170.958 170.958 0 0 1-21.56-9.703 897.18 897.18 0 0 1-6.238-3.374c-4.719-2.567-9.599-5.222-14.484-7.638a31.797 31.797 0 0 1-16.128-18.432 31.8 31.8 0 0 1 1.631-24.438 31.821 31.821 0 0 1 28.707-17.821 31.686 31.686 0 0 1 14.163 3.324l.151.075c4.876 2.444 9.937 4.738 14.832 6.958 2.152.975 4.302 1.956 6.449 2.942a171.176 171.176 0 0 1 20.74 11.359c-4.719 7.021-13.827 40.129-10.493 63.017Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-3-a)"
        />
        <path
            d="m465.078 167.027 2.654.952c13.051 4.677 23.788 10.342 32.825 17.319a2.67 2.67 0 0 1 1.092 2.341c-.25 4.648-12.214 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-3-b)"
        />
        <path
            d="M470.952 242.19c4.885 2.416 9.765 5.071 14.484 7.638 2.078 1.13 4.155 2.261 6.238 3.374a170.958 170.958 0 0 0 21.56 9.703 366.105 366.105 0 0 0 17.77 6.269c-3.334-22.888 5.774-55.996 10.494-63.017a171.039 171.039 0 0 0-20.74-11.359 749.379 749.379 0 0 0-6.448-2.943c-4.445-2.015-9.021-4.098-13.477-6.293a2.968 2.968 0 0 0-.274-.264c-9.037-6.977-19.774-12.642-32.825-17.32l-2.654-.951"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-3-a"
                x1={517.992}
                y1={392.718}
                x2={491.968}
                y2={163.706}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-3-b"
                x1={507.989}
                y1={393.853}
                x2={481.965}
                y2={164.841}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
