import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#EF0025"
                d="M574.527 673.461l8.749 31.226 7.846-.244s-.078-6.056-.281-10.411c-.141-3.018 1.61-6.267 5.008-6.165"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M583.275 704.686l7.993-.248"
            ></path>
            <path
                fill="#EF0025"
                d="M640.753 706.964c-3.662.724-7.41.916-11.127.571l-5.47-.507a24.352 24.352 0 01-15.481-7.556l-7.162-7.606a22.35 22.35 0 00-10.945-6.386l-5.95-1.46a13.898 13.898 0 01-10.476-15.402l5.344-24.259 41.448 2.089 13.855 36.061a8.545 8.545 0 004.916 4.901l13.152 5.033a6.43 6.43 0 014.169 6.539 6.43 6.43 0 01-5.157 5.792l-11.116 2.19z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M656.607 702.457l-.028.035a10.329 10.329 0 01-7.728 3.773l-24.694.766a24.334 24.334 0 01-15.482-7.555l-7.158-7.604a22.368 22.368 0 00-10.953-6.39l-5.945-1.46a15.922 15.922 0 01-8.699-6.475"
            ></path>
        </g>
    );
}
