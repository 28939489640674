import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1471}
            height={197}
            viewBox={`0 0 ${1471} ${197}`}
            fill="none"
            id="object-kitchen-table"
            {...attributes}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.629 1.6H1404.63l55.5 40.5H249.629v19.5H1469.13v28.5h-168v105M1320.63 115.6v40.5M1284.63 115.6v40.5"
            />
        </svg>
    );
}
