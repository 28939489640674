import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-2-cycling-1-right">
        <g clipPath="url(#man-34-25-pants-2-cycling-1-right-a)">
            <path
                d="m468.775 406.47-155.919-45.634c-10.94 10.327-16.554 23.259-18.722 41.476-2.014 16.914 1.205 30.27 6.072 45.687 7.572 22.753 15.75 45.227 25.573 67.002 9.643 21.094 32.167 68.537 32.547 69.335-1 1.426-3.49 5.224-4.791 7.232-11.88 19.511-22.26 44.525-27.233 66.539-2.964 13.12-10.166 63.927-11.075 79.322l40.186 15.395c12.565-30.552 27.094-66.443 39.3-96.854 5.082-12.84 18.902-46.81 24.083-60.104a44.37 44.37 0 0 0 2.494-11.06c1.809-19.494 1.334-38.721.445-54.385a411.25 411.25 0 0 0-5.592-52.441 37.156 37.156 0 0 1 17.227-38.443 22.578 22.578 0 0 1 2.279-1.211 75.71 75.71 0 0 0 33.696-30.38l-.57-1.476Z"
                fill="url(#man-34-25-pants-2-cycling-1-right-b)"
            />
            <path
                d="m316.896 720.627 44.96 16.485c2.057-5.029 4.138-10.129 6.244-15.3l-49.058-17.988a1064.982 1064.982 0 0 0-2.146 16.803Z"
                fill="url(#man-34-25-pants-2-cycling-1-right-c)"
            />
            <path
                d="M379.134 694.627c5.382-13.302 10.67-26.424 15.58-38.656 1.148-2.902 2.745-6.888 4.585-11.465l-61.198-22.44a220.425 220.425 0 0 0-11.798 36.041c-.753 3.33-1.778 9.094-2.904 16.084l55.735 20.436Z"
                fill="url(#man-34-25-pants-2-cycling-1-right-d)"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-pants-2-cycling-1-right-b"
                x1={293.524}
                y1={556.83}
                x2={469.345}
                y2={556.83}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-cycling-1-right-c"
                x1={316.896}
                y1={720.468}
                x2={368.1}
                y2={720.468}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-cycling-1-right-d"
                x1={323.398}
                y1={658.347}
                x2={399.299}
                y2={658.347}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <clipPath id="man-34-25-pants-2-cycling-1-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .219)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
