import { IBodyClothes, TBodyPosition, TSkinEl } from 'interfaces';

import BodySkinNormal from 'assets/female/skin-layer/female-bold-34-25-body-normal';
import BodySkinSitting from 'assets/female/skin-layer/female-bold-34-25-body-sitting';

import ArmRightSkinOption1 from 'assets/female/skin-layer/normal-arms/female-bold-34-25-arm-right-option-1';
import ArmRightSkinOption2 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-2';
import ArmRightSkinOption3 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-3';
import ArmRightSkinOption4 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-4';
import ArmRightSkinOption5 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-5';
import ArmRightSkinOption6 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-6';
import ArmRightSkinOption7 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-7';
import ArmRightSkinOption8 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-8';
import ArmRightSkinOption9 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-right-option-9';

import ArmLeftSkinOption1 from 'assets/female/skin-layer/normal-arms/female-bold-34-25-arm-left-option-1';
import ArmLeftSkinOption2 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-2';
import ArmLeftSkinOption3 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-3';
import ArmLeftSkinOption4 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-4';
import ArmLeftSkinOption5 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-5';
import ArmLeftSkinOption6 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-6';
import ArmLeftSkinOption7 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-7';
import ArmLeftSkinOption8 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-8';
import ArmLeftSkinOption9 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-9';
import ArmLeftSkinOption10 from 'assets/female/skin-layer/normal-arms/female-34-25-arm-left-option-10';

import ArmsRightSkinOption1 from 'assets/female/skin-layer/normal-arms/female-34-25-arms-option-1-right';
import ArmsLeftSkinOption1 from 'assets/female/skin-layer/normal-arms/female-34-25-arms-option-1-left';
import ArmsRightSkinOption2 from 'assets/female/skin-layer/normal-arms/female-34-25-arms-option-2-right';
import ArmsLeftSkinOption2 from 'assets/female/skin-layer/normal-arms/female-34-25-arms-option-2-left';

import FHeadSkin from 'assets/female/skin-layer/female-bold-34-25-head';

import dress1 from 'assets/female/clothes/body/dress/dress-1';
import dress2 from 'assets/female/clothes/body/dress/dress-2';
import dress3 from 'assets/female/clothes/body/dress/dress-3';
import dress4 from 'assets/female/clothes/body/dress/dress-4';
import dress5 from 'assets/female/clothes/body/dress/dress-5';
import shirt1 from 'assets/female/clothes/body/shirt-1';
import shirt2 from 'assets/female/clothes/body/shirt-2';
import shirt3 from 'assets/female/clothes/body/shirt-3';
import shirt4 from 'assets/female/clothes/body/shirt-4';
import shirt5 from 'assets/female/clothes/body/shirt-5';
import polo3 from 'assets/female/clothes/body/polo-3';
import polo4 from 'assets/female/clothes/body/polo-4';
import polo5 from 'assets/female/clothes/body/polo-5';
import doctorsCoat from 'assets/female/clothes/body/doctorscoat';
import doctorsCoat2 from 'assets/female/clothes/body/doctorscoat-2';
import tshirt1 from 'assets/female/clothes/body/tshirt-1';
import tshirt2 from 'assets/female/clothes/body/tshirt-2';
import tshirt3 from 'assets/female/clothes/body/tshirt-3';
import tshirt4 from 'assets/female/clothes/body/tshirt-4';
import tshirt5 from 'assets/female/clothes/body/tshirt-5';
import tshirt6 from 'assets/female/clothes/body/tshirt-6';
import jacket1 from 'assets/female/clothes/body/jacket-1';
import jacket2 from 'assets/female/clothes/body/jacket-2';
import jacket3 from 'assets/female/clothes/body/jacket-3';
import sport1 from 'assets/female/clothes/body/sport-1';

export const F_HEAD_SKIN = FHeadSkin;

export const F_BODY_SKIN_POSITION: Record<TBodyPosition, TSkinEl | null> = {
    normal: BodySkinNormal,
    sitting: BodySkinSitting,
    cycling1: null,
    cycling2: null
};

export const F_BODY_CLOTHES: Record<string, IBodyClothes> = {
    jacket1,
    jacket2,
    jacket3,
    dress1,
    dress2,
    dress3,
    dress4,
    dress5,
    tshirt1,
    tshirt2,
    tshirt3,
    tshirt4,
    tshirt5,
    tshirt6,
    shirt1,
    shirt2,
    shirt3,
    shirt4,
    shirt5,
    polo3,
    polo4,
    polo5,
    doctorsCoat,
    doctorsCoat2,
    sport1,
};

export const FBodyClothes = Object.keys(F_BODY_CLOTHES);

export const F_BOTH_ARMS_POSITION: Record<string, string> = {
    option1: 'Option #1',
    option2: 'Option #2',
};

export const F_BOTH_ARMS_SKINS: { normal: Record<string, [TSkinEl, TSkinEl]> } = {
    normal: {
        option1: [ArmsRightSkinOption1, ArmsLeftSkinOption1],
        option2: [ArmsRightSkinOption2, ArmsLeftSkinOption2],
    },
};

export const F_LEFT_ARM_POSITION: Record<string, string> = {
    option1: 'Option #1',
    option2: 'Option #2',
    option3: 'Option #3',
    option4: 'Option #4',
    option5: 'Option #5',
    option6: 'Option #6',
    option7: 'Option #7',
    option8: 'Option #8',
    option9: 'Option #9',
    option10: 'Option #10',
};

export const F_ARM_LEFT_SKINS: Record<string, TSkinEl> = {
    option1: ArmLeftSkinOption1,
    option2: ArmLeftSkinOption2,
    option3: ArmLeftSkinOption3,
    option4: ArmLeftSkinOption4,
    option5: ArmLeftSkinOption5,
    option6: ArmLeftSkinOption6,
    option7: ArmLeftSkinOption7,
    option8: ArmLeftSkinOption8,
    option9: ArmLeftSkinOption9,
    option10: ArmLeftSkinOption10,
};


export const F_RIGHT_ARM_POSITION: Record<string, string> = {
    option1: 'Option #1',
    option2: 'Option #2',
    option3: 'Option #3',
    option4: 'Option #4',
    option5: 'Option #5',
    option6: 'Option #6',
    option7: 'Option #7',
    option8: 'Option #8',
    option9: 'Option #9',
};
export const F_ARM_RIGHT_SKINS: Record<string, TSkinEl> = {
    option1: ArmRightSkinOption1,
    option2: ArmRightSkinOption2,
    option3: ArmRightSkinOption3,
    option4: ArmRightSkinOption4,
    option5: ArmRightSkinOption5,
    option6: ArmRightSkinOption6,
    option7: ArmRightSkinOption7,
    option8: ArmRightSkinOption8,
    option9: ArmRightSkinOption9,
};
