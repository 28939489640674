import { useCallback, useRef, useState } from 'react';

const maxHistory = 10;

export default function useStateHistory<T>(defaultValue: T) {
    const [state, setState] = useState(defaultValue);

    const lastSaved = useRef<T>(defaultValue);
    const rerender = useState({})[1];

    const actionHistory = useRef<T[]>([]);

    const push = useCallback((value: T) => {
        if (actionHistory.current.length < maxHistory) {
            actionHistory.current.push(value);
        } else {
            actionHistory.current = [...actionHistory.current.slice(1), value];
            lastSaved.current = actionHistory.current[0];
        }

        setState(value);
    }, [maxHistory]);

    const undo = useCallback(() => {
        if (actionHistory.current.length < 1) return;
        actionHistory.current.pop();
        const prev = actionHistory.current[actionHistory.current.length - 1];
        prev ? setState(prev) : setState(lastSaved.current);
        rerender({});
        return prev || lastSaved.current;
    }, [rerender]);

    const clearHistory = useCallback((value: T) => {
        actionHistory.current = [];
        setState(value);
        rerender({});
    }, []);

    return { state: state, push, undo, history: actionHistory.current, clearHistory };
}
