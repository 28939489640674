import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m449.355 831.797 5.423 32.229 7.861.144s.542-5.86.794-10.212c.175-3.016 1.326-5.694 4.696-5.238"
                fill="#EF0025"
            />
            <path
                d="m454.778 864.022 8.02-.022"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M507.69 877.094a38.873 38.873 0 0 1-11.027-1.6l-5.267-1.56a24.357 24.357 0 0 1-13.72-10.417l-5.548-8.853a22.359 22.359 0 0 0-9.498-8.389l-5.552-2.587a13.894 13.894 0 0 1-7.286-17.143l6.655-19.17 43.55 6.506 6.59 38.065a8.536 8.536 0 0 0 3.871 5.762l11.924 7.49a6.425 6.425 0 0 1 2.82 7.225 6.426 6.426 0 0 1-6.183 4.681l-11.329-.01Z"
                fill="#EF0025"
            />
            <path
                d="M523.151 875.589a6.413 6.413 0 0 1-4.133 1.517l-11.329-.01a38.832 38.832 0 0 1-11.026-1.601l-5.267-1.56a24.33 24.33 0 0 1-13.72-10.417l-5.546-8.848a22.324 22.324 0 0 0-9.5-8.393l-5.553-2.588a15.92 15.92 0 0 1-7.276-8.041"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m395.912 843.219 8.096 32.58 9.112.302 1.349-10.954"
                fill="#EF0025"
            />
            <path
                d="m398.93 821.898-3.016 13.645a19.663 19.663 0 0 0 6.071 18.375 29.055 29.055 0 0 1 3.725 4.218l8.739 12.08a14.047 14.047 0 0 0 11.404 5.649h25.219a7.806 7.806 0 0 0 8.05-7.271 7.204 7.204 0 0 0-2.241-5.411l-13.292-11.578a13.1 13.1 0 0 1-3.316-5.211l-10.305-25.824"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M425.854 876.864a15.032 15.032 0 0 1-12.214-6.063l-8.843-12.225a30.24 30.24 0 0 0-3.689-4.112 20.622 20.622 0 0 1-6.173-19.123l2.205-16.653h40.037s4.916 22.691 6.519 29.727a7.675 7.675 0 0 0 2.448 4.088l11.394 9.925a8.27 8.27 0 0 1 2.584 6.195 8.758 8.758 0 0 1-2.848 5.961 8.76 8.76 0 0 1-6.201 2.279l-25.219.001Z"
                fill="url(#female-34-25-shoes-14-stand-1-a)"
            />
            <path
                d="M457.577 874.31a9.328 9.328 0 0 1-6.503 2.561h-25.22a15.04 15.04 0 0 1-12.214-6.063s-15.174-19.201-16.206-20.933M404.008 875.797h9.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-14-stand-1-a"
                    x1={448.298}
                    y1={847.883}
                    x2={397.551}
                    y2={847.558}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.397} stopColor="#F3312B" />
                    <stop offset={1} stopColor="#EF0025" />
                </linearGradient>
            </defs>
        </g>
    );
}
