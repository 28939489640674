import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-4">
        <path
            d="m465.195 167.5 2.654.951c13.051 4.677 23.788 10.343 32.825 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.857.526-21.103-34.814Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-4-a)"
        />
        <path
            d="M532.236 325.713a24.413 24.413 0 0 1-17.544-7.516c-6.949-7.298-13.061-14.953-17.61-20.808-5.256-6.806-10.417-13.933-15.779-21.791a219.9 219.9 0 0 1-13.929-22.893 923.666 923.666 0 0 1-3.728-7.123c-2.819-5.412-5.733-11.008-8.78-16.4a32.038 32.038 0 0 1 12.11-43.605 32.009 32.009 0 0 1 15.717-4.147 32.075 32.075 0 0 1 27.886 16.258l.072.127c3.021 5.404 6.284 10.796 9.438 16.01 1.392 2.3 2.779 4.604 4.161 6.91a219.888 219.888 0 0 1 12.321 23.802c3.924 8.659 7.33 16.772 10.412 24.797 2.639 6.932 6.002 16.133 8.624 25.859a24.245 24.245 0 0 1-17.077 29.684 24.19 24.19 0 0 1-6.294.836Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-4-b)"
        />
        <path
            d="m509.709 198.184.071.126c4.338 7.758 9.056 15.303 13.611 22.94a207.244 207.244 0 0 1 6.052 10.804"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M467.512 169.395a129.68 129.68 0 0 1 32.551 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m512.921 299.912 2.398-9.955 2.336-9.969c.848-3.309 1.589-6.64 2.528-9.929.439-1.651.917-3.294 1.402-4.935.471-1.645.965-3.284 1.482-4.919 1.028-3.27 1.997-6.553 3.087-9.81 1.057-3.265 2.123-6.527 3.289-9.769 1.149-3.245 2.512-9.57 3.702-12.806 8.602 8.097 21.036 10.482 33.542 10.048-.218 3.442-.681 6.972-.939 10.405-.241 3.436-.58 6.851-.93 10.265-.316 3.42-.754 6.815-1.132 10.222a205.635 205.635 0 0 1-.627 5.099 191.502 191.502 0 0 1-.708 5.081c-.468 3.389-1.133 6.735-1.692 10.105l-1.883 10.064-1.821 10.078a22.51 22.51 0 1 1-44.034-9.275Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-4-c)"
        />
        <path
            d="M566.982 227.609c-.051 3.477-.301 6.91-.454 10.366-.217 3.441-.523 6.864-.78 10.296-.241 3.437-.58 6.852-.93 10.265-.316 3.42-.753 6.816-1.132 10.223a205.555 205.555 0 0 1-.627 5.098 186.971 186.971 0 0 1-.708 5.082c-.467 3.388-1.133 6.735-1.692 10.104l-1.883 10.065-1.821 10.077a22.508 22.508 0 0 1-26.152 18.149 22.43 22.43 0 0 1-14.059-8.715M517.656 279.989c.848-3.309 1.589-6.64 2.528-9.929.439-1.651.916-3.294 1.401-4.935.471-1.645.966-3.284 1.483-4.919 1.028-3.27 1.997-6.553 3.087-9.81 1.056-3.265 2.123-6.527 3.289-9.769 1.149-3.245 2.25-6.5 3.439-9.736 1.254-3.223 2.41-6.467 3.765-9.668"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M533.982 228.582c8.484 7.419 20.383 9.668 32.378 9.299"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-4-a"
                x1={465.195}
                y1={184.907}
                x2={506.49}
                y2={184.907}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-4-b"
                x1={450.728}
                y1={253.571}
                x2={556.444}
                y2={253.571}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-4-c"
                x1={512.291}
                y1={277.759}
                x2={566.687}
                y2={277.759}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
