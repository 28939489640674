import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-4">
        <path
            d="M413.778 265.22c.086-3.028.133-6.084.142-9.149a161.987 161.987 0 0 0-1.334-22.991c-.293-2.281-.605-4.56-.915-6.839-.707-5.185-1.437-10.547-1.942-15.833l-.017-.17a31.887 31.887 0 0 0-31.801-28.781 32.035 32.035 0 0 0-24.816 11.779 32.037 32.037 0 0 0-7.059 23.446c.535 5.279.863 10.678 1.182 15.9.14 2.298.28 4.595.436 6.888a162.252 162.252 0 0 0 3.174 22.805 366.043 366.043 0 0 0 2.527 11.585l60.423-8.64Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-4-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-4-a"
                x1={368.132}
                y1={179.209}
                x2={386.349}
                y2={264.221}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
