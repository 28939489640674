import React from 'react';
import shorts12Options from './shorts-1-2-options';

const SvgComponent = ({ option = 0 }: { option?: number}) => {
    return <g id="man-34-25-shorts-1-2-walk-2">
        <path
            fill={shorts12Options[option].fillColor}
            d="M533.805 449.799c-1.5-7.2-3.4-15-5.5-22.2-6.9-25.2-32.9-41.7-58.6-37.1-27.6 4.1-47.7 32.4-42.5 59.8.9 5.5 2.4 12 3.5 17.4 6.8 30.8 10.7 55.1 21.1 84.9h97.3c-1.7-38-8.4-67.8-15.3-102.8Z"
        />
        <path
            fill={`url(#${shorts12Options[option].walk2})`}
            d="M458.904 498.099c2.8-10 8.7-18.8 17.1-25 13.6-10 21.9-35.8 23.1-39.6 3.3-1.7 25.3-14.5 33.4-45.6l-1-1.3h-161.1l-.9.5c-.9 1.6-1.9 3.2-2.8 4.7-9.1 15.6-15.3 26.1-15.1 48.2-.2 2.3-.2 4.7-.2 7.1l-.3 7.1c-.7 26.6-.8 96.1 0 104.6h90.1c6-20.2 12.7-43.1 17.7-60.7Z"
        />
        <defs>
            <linearGradient
                id={shorts12Options[0].walk2}
                x1={289.182}
                x2={494.772}
                y1={470.843}
                y2={473.445}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#FFB91D" />
                <stop offset={0.493} stopColor="#FFC63E" />
                <stop offset={1} stopColor="#FFD766" />
            </linearGradient>
            <linearGradient
                id={shorts12Options[1].walk2}
                x1={289.501}
                x2={495.091}
                y1={470.843}
                y2={473.445}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.672} stopColor="#00359C" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
