import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-7">
        <path
            d="M500.508 186.165c-9.225-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-jacket-left-option-7-a)"
        />
        <path
            d="M538.822 246.393c-2.2-5.806-4.69-11.618-7.608-17.759a112.531 112.531 0 0 0-9.48-16.688 614.77 614.77 0 0 0-3.362-4.843c-2.46-3.518-5.003-7.156-7.318-10.843l-.147-.233a31.896 31.896 0 0 0-26.983-14.753 31.887 31.887 0 0 0-17.22 5.051 31.781 31.781 0 0 0-14.007 20.092 31.792 31.792 0 0 0 4.303 24.11c2.345 3.663 4.549 7.515 6.681 11.24.977 1.707 1.954 3.414 2.944 5.106a112.455 112.455 0 0 0 11.042 15.69c4.317 5.27 8.51 10.002 12.823 14.47 2.364 2.429 5.249 5.32 8.532 8.274 7.053-17.113 24.402-33.053 39.8-38.914Z"
            fill="url(#man-34-25-jacket-left-option-7-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-7-a"
                x1={505.216}
                y1={135.901}
                x2={422.807}
                y2={306.359}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-7-b"
                x1={532.618}
                y1={149.149}
                x2={450.208}
                y2={319.606}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
