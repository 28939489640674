import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6925)"
            d="M478.237 236.71c-.166-4.364-.928-19.194-3.775-26.828l-1.017-2.726 2.478 1.525a102.448 102.448 0 0118.859 14.536 2.701 2.701 0 01.823 2.483c-.788 4.558-13.419 10.694-15.944 11.877l-1.367.641-.057-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6925)"
            d="M524.736 343.438a19.603 19.603 0 01-15.48-7.691c-.228-.2-.454-.403-.674-.615a216.126 216.126 0 01-15.74-17.397c-4.676-5.704-9.263-11.704-14.026-18.344a171.81 171.81 0 01-12.303-19.416 769.036 769.036 0 01-3.279-6.088c-2.464-4.598-5.011-9.353-7.685-13.922a27.508 27.508 0 0123.709-41.395 28.014 28.014 0 0123.979 13.643l.069.12c2.653 4.586 5.31 9.124 8.092 13.541 1.227 1.949 2.45 3.9 3.668 5.855a171.64 171.64 0 0110.802 20.294c3.409 7.398 6.358 14.351 9.012 21.249a296.982 296.982 0 013.486 9.601c.846-.1 1.693-.197 2.541-.292 4.768-.522 9.561-.783 14.358-.781 1.196 0 2.393.016 3.592.047.98.032 1.952.052 2.925.073 1.985.042 4.039.086 6.077.226l4.475.245c.981.056 1.962.14 2.944.225.525.046 1.051.091 1.575.132 3.475.301 6.353.605 9.065.958l1.387.181-.619 1.254c-3.84 7.773-1.217 21.424 2.274 27.408l.641 1.097-1.216.365c-2.63.79-5.421 1.555-8.783 2.409-.52.127-1.033.258-1.546.388-.953.242-1.906.484-2.862.697l-4.393.969c-1.976.465-3.998.84-5.952 1.204-.955.177-1.909.354-2.863.542a132.722 132.722 0 01-17.837 2.181c-1.79.093-3.581.173-5.372.253-4.068.181-8.276.369-12.39.715a19.8 19.8 0 01-1.649.069h-.002z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6925"
                x1="471.396"
                x2="565.083"
                y1="209.504"
                y2="340.492"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6925"
                x1="468.595"
                x2="562.282"
                y1="211.514"
                y2="342.502"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
