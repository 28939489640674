import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7352)"
            d="M494.768 223.337a109.595 109.595 0 00-20.026-15.329 31.035 31.035 0 012.394 13.895 65.928 65.928 0 01-1.451 10.762l2.169 4.754c.055.12.133.228.229.318a.996.996 0 001.101.175c2.598-1.201 15.601-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7352)"
            d="M503.363 224.736c-5.143-1.464-10.462-2.978-15.625-4.657l-.129-.041a27.498 27.498 0 00-26.186 47.217 27.503 27.503 0 009.443 5.172c5.158 1.649 10.349 3.523 15.368 5.334l3.58 1.291 18.676-52.85s-3.758-1.076-5.127-1.466z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7352"
                x1="486.576"
                x2="426.72"
                y1="215.084"
                y2="304.867"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7352"
                x1="495.658"
                x2="435.802"
                y1="221.144"
                y2="310.927"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
