import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-2-walk-2">
            <path
                d="M400.102 830.867c-6.574-4.359-12.373-11.942-16.875-19.236-18.356 4.335-36.627-5.966-45.258-14.615a960.524 960.524 0 0 1-4.875 10.715 9.084 9.084 0 0 0-.182 7.206 9.113 9.113 0 0 0 5.12 5.075l23.344 9.166c1.552 1.679 13.012 13.288 36.311 17.198 1.498.26 3.015.395 4.536.405 4.375 0 7.459-1.314 8.406-3.726 1.076-2.742-2.465-6.842-10.527-12.188Z"
                fill="#FFD109"
            />
            <path
                d="M332.912 814.93a9.018 9.018 0 0 0 5.121 5.078l23.344 9.167c1.552 1.679 13.012 13.288 36.311 17.198 1.498.259 3.015.395 4.536.405 4.375 0 7.459-1.315 8.406-3.726"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M488.408 848.379a49.76 49.76 0 0 1-5.924-3.13c-3.668-2.47-8.119-8.233-11.673-13.413-10.535 0-18.126 13.311-37.968 13.311-.096 1.798-.202 3.687-.318 5.667a9.097 9.097 0 0 0 9.085 9.626h43.739c7.341 0 11.551-1.975 11.551-5.418 0-2.601-3.935-4.474-8.492-6.643Z"
                fill="url(#female-34-25-shoes-2-walk-2-a)"
            />
            <path
                d="M434.988 857.578a9.01 9.01 0 0 0 3.014 2.118 9.01 9.01 0 0 0 3.609.738h43.739c7.341 0 11.551-1.975 11.551-5.418"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-2-walk-2-a"
                    x1={432.509}
                    y1={846.139}
                    x2={496.9}
                    y2={846.139}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </g>
    );
}
