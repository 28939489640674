import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="m393.096 788.12-4.598 38.429a16.99 16.99 0 0 0 2.786 11.521l8.556 12.68a5.422 5.422 0 0 1-3.128 8.283 5.425 5.425 0 0 1-3.63-.314 8142.573 8142.573 0 0 0-10.298-4.696 37.866 37.866 0 0 1-9.132-5.869l-4.148-3.6a23.353 23.353 0 0 1-7.838-14.542l-1.384-10.351a23.36 23.36 0 0 0-5.402-12.09l-3.983-4.656a12.93 12.93 0 0 1 .434-17.283l15.145-16.007"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m393.096 788.12-4.598 38.429a16.99 16.99 0 0 0 2.786 11.521l8.556 12.68a5.422 5.422 0 0 1-3.128 8.283 5.425 5.425 0 0 1-3.63-.314 8142.573 8142.573 0 0 0-10.298-4.696 37.866 37.866 0 0 1-9.132-5.869l-4.148-3.6a23.353 23.353 0 0 1-7.838-14.542l-1.384-10.351a23.36 23.36 0 0 0-5.402-12.09l-3.983-4.656a12.93 12.93 0 0 1 .434-17.283l15.145-16.007"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M366.791 771.032a732.6 732.6 0 0 1 10.134-22.613c1.746-3.726 3.424-7.504 5.227-11.188l5.362-11.089c3.551-7.41 7.213-14.738 10.569-22.292 3.413-7.511 6.536-15.237 10.239-22.535 7.19-14.755 17.294-27.359 27.876-39.61a240.38 240.38 0 0 1 17.053-17.507c1.488-1.41 3.073-2.747 4.604-4.125 1.595-1.33 3.15-2.69 4.799-3.981a110.37 110.37 0 0 1 10.31-7.437l10.01-6.318a26.266 26.266 0 0 1 40.287 21.838 26.273 26.273 0 0 1-6.044 17.141l-30.958 37.336-15.488 18.662-15.601 18.578c-10.419 12.372-20.759 24.802-31.243 37.125l-31.39 37.018a15.928 15.928 0 0 1-27.33-5.486 15.93 15.93 0 0 1 .529-11.064l1.055-2.453Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m392.539 790.038 31.39-37.017c10.484-12.324 20.824-24.754 31.243-37.125l15.601-18.579 15.487-18.661 30.957-37.337c3.538-3.455 5.33-10.749 5.33-10.749M367.473 769.448c3.262-7.623 5.967-13.57 9.452-21.028 1.747-3.726 3.424-7.504 5.228-11.188l5.361-11.089c3.552-7.41 7.214-14.738 10.569-22.292 3.414-7.511 6.536-15.237 10.239-22.535 7.19-14.755 17.295-27.359 27.877-39.61a240.38 240.38 0 0 1 17.053-17.507c1.487-1.41 3.072-2.747 4.604-4.125 1.594-1.33 6.4-5.307 8.049-6.597"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m471.077 628.367-14.594-42.872-3.663-10.716-3.774-10.701c-2.489-7.138-5.283-14.234-7.712-21.38-2.429-7.146-4.609-14.325-6.412-21.556-.936-3.611-1.709-7.243-2.572-10.864l-1.26-5.435-1.112-5.456c-.73-3.638-1.528-7.268-2.126-10.924-.631-3.652-1.331-7.295-1.834-10.964l-.82-5.495-.678-5.515a181.775 181.775 0 0 1-.995-11.077 51.019 51.019 0 0 1 41.56-53.208 51.02 51.02 0 0 1 57.968 34.614l1.549 4.9c1.142 3.583 2.099 7.19 2.959 10.812l1.257 5.435 1.083 5.46c.776 3.633 1.308 7.298 1.927 10.952.634 3.651 1.124 7.322 1.611 10.994.968 7.343 1.662 14.724 2.248 22.12.582 7.396.909 14.826.915 22.3.012 7.474-.448 15.011-1.055 22.568l-.939 11.34c-.338 3.783-.768 7.579-1.147 11.368-1.739 15.185-4.018 30.444-8.155 45.954l-1.485 5.568a27.577 27.577 0 0 1-25.713 20.451 27.575 27.575 0 0 1-27.031-18.673Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m464.707 609.657-8.224-24.161-3.663-10.717-3.774-10.701c-2.489-7.137-5.283-14.234-7.712-21.38-2.429-7.145-4.609-14.325-6.412-21.556-.936-3.61-1.709-7.243-2.572-10.863l-1.26-5.436-1.112-5.456c-.73-3.638-1.528-7.268-2.126-10.924-.631-3.652-1.331-7.295-1.834-10.964l-.82-5.495-.678-5.515a181.775 181.775 0 0 1-.995-11.077 51.019 51.019 0 0 1 41.56-53.208 51.02 51.02 0 0 1 57.968 34.614l1.549 4.9c1.142 3.583 2.099 7.19 2.959 10.812l1.257 5.435 1.083 5.46c.776 3.633 1.308 7.298 1.927 10.952.634 3.651 1.124 7.322 1.611 10.994.968 7.343 1.662 14.724 2.248 22.12.582 7.396.909 14.826.915 22.3.012 7.474-.448 15.011-1.055 22.568l-.939 11.34c-.338 3.783-.768 7.579-1.147 11.368-1.739 15.185-4.018 30.444-8.155 45.954l-1.485 5.568"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.312a90.422 90.422 0 0 0-4.403 15.398c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.909H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.312a90.422 90.422 0 0 0-4.403 15.398c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.909"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M399.496 815.717a725.359 725.359 0 0 1-6.054-23.916c-.937-3.988-1.961-7.974-2.827-11.965l-2.661-11.97c-1.803-7.979-3.468-15.963-5.513-23.934-1.974-7.973-4.309-15.935-5.923-23.921-3.497-15.961-3.373-32.041-2.656-48.139a238.277 238.277 0 0 1 2.542-24.196c.294-2.019.71-4.042 1.058-6.062.428-2.024.806-4.045 1.3-6.07a109.55 109.55 0 0 1 3.486-12.17l3.976-11.204a26.26 26.26 0 0 1 45.13-7.767 26.255 26.255 0 0 1 5.865 17.187l-1.171 48.264-.597 24.132-.737 24.137c-.515 16.092-.932 32.18-1.527 48.275l-1.709 48.281a15.927 15.927 0 0 1-31.285 3.627l-.697-2.589Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m381.891 623.527-10.337-43.166-2.598-10.791-2.711-10.786c-1.779-7.193-3.867-14.373-5.587-21.567-1.719-7.195-3.188-14.399-4.275-21.617-.579-3.608-.994-7.222-1.499-10.832l-.724-5.417-.574-5.423c-.37-3.616-.81-7.229-1.049-10.849-.27-3.619-.611-7.236-.754-10.86l-.279-5.434-.136-5.439a173.55 173.55 0 0 1 .092-10.892 51.02 51.02 0 0 1 46.465-48.847 51.02 51.02 0 0 1 54.355 39.882l1.107 5.129c.787 3.6 1.387 7.207 1.889 10.818l.72 5.417.545 5.424c.417 3.613.589 7.236.848 10.856.275 3.619.404 7.244.53 10.869.246 7.25.215 14.51.077 21.774-.144 7.265-.543 14.539-1.268 21.825-.718 7.287-1.912 14.59-3.253 21.9l-2.042 10.966c-.706 3.656-1.505 7.316-2.252 10.973-3.214 14.639-6.972 29.299-12.604 44.029l-2.097 5.486a27.59 27.59 0 0 1-27.558 17.673 27.582 27.582 0 0 1-25.031-21.101Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m400.194 818.308-.698-2.589a726.05 726.05 0 0 1-6.054-23.917c-.937-3.988-1.961-7.974-2.827-11.964l-2.661-11.97c-1.803-7.98-3.468-15.963-5.513-23.934-1.974-7.974-4.309-15.936-5.923-23.921-3.497-15.962-3.373-32.041-2.656-48.14a238.257 238.257 0 0 1 2.542-24.195c.294-2.019.71-4.042 1.058-6.063.428-2.023.806-4.045 1.3-6.07.927-4.12 2.09-8.184 3.486-12.17M437.219 621.594l-1.171 48.263-.597 24.133-.737 24.137c-.515 16.091-.931 32.18-1.527 48.275l-1.708 48.281M381.891 623.528l-10.337-43.166-2.598-10.791-2.711-10.787c-1.779-7.192-3.867-14.372-5.587-21.567-1.719-7.194-3.188-14.398-4.275-21.616-.579-3.608-.994-7.222-1.499-10.832l-.724-5.417-.574-5.423c-.37-3.616-.81-7.229-1.049-10.849-.27-3.62-.611-7.236-.754-10.86l-.279-5.434-.136-5.439a173.55 173.55 0 0 1 .092-10.892 51.099 51.099 0 0 1 1.803-11.658M452.279 441.492l1.107 5.129c.787 3.6 1.387 7.207 1.889 10.818l.72 5.417.545 5.424c.417 3.614.589 7.236.848 10.856.275 3.619.404 7.244.53 10.869.246 7.25.216 14.51.077 21.774-.143 7.265-.543 14.539-1.268 21.826-.717 7.286-1.912 14.589-3.253 21.899l-2.042 10.966c-.706 3.656-1.504 7.316-2.252 10.973a313.414 313.414 0 0 1-11.561 41.245"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m401.16 821.463-3.016 13.645a19.653 19.653 0 0 0 6.071 18.375 28.95 28.95 0 0 1 3.726 4.218l8.738 12.08a14.056 14.056 0 0 0 11.405 5.649h25.219a7.812 7.812 0 0 0 8.05-7.272 7.224 7.224 0 0 0-2.241-5.41l-13.293-11.579a13.074 13.074 0 0 1-3.315-5.211l-10.687-28.474"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m401.16 821.463-3.016 13.645a19.653 19.653 0 0 0 6.071 18.375 28.95 28.95 0 0 1 3.726 4.218l8.738 12.08a14.056 14.056 0 0 0 11.405 5.649h25.219a7.81 7.81 0 0 0 8.05-7.272 7.224 7.224 0 0 0-2.241-5.41l-13.293-11.579a13.074 13.074 0 0 1-3.315-5.211l-10.687-28.474"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
