import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M476.742 231.756c.76-3.49 1.23-7.037 1.406-10.605.2-3.891-.276-7.788-1.403-11.518a105.91 105.91 0 0 1 17.337 13.629 1.706 1.706 0 0 1 .534 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458Z"
            fill="url(#female-34-25-shirt-1-arm-opt-9-left-a)"
        />
        <path
            d="M577.41 296.135c-1.02-.616-2.027-1.268-3.058-1.855l-3.094-1.75c-2.049-1.207-4.169-2.214-6.255-3.318a81.176 81.176 0 0 0-12.954-5.386c-2.69-.848-5.395-1.654-8.085-2.502-2.498-4.522-5.237-8.8-8.075-12.976-3.61-5.267-7.423-10.327-11.382-15.238a115.314 115.314 0 0 0-12.8-13.794c-4.619-4.24-9.363-8.353-13.818-12.759l-.118-.116a26.5 26.5 0 0 0-45.015 19.269 26.496 26.496 0 0 0 7.981 18.646c4.483 4.379 8.677 9.05 12.996 13.595a115.153 115.153 0 0 0 14.014 12.56c4.978 3.874 10.103 7.599 15.432 11.117a148.455 148.455 0 0 0 16.794 9.731c.608.288 1.23.544 1.864.768a18.519 18.519 0 0 0 4.422 2.287c4.404 1.554 8.696 3.424 13.036 5.157a81.232 81.232 0 0 0 13.439 4.03c2.313.466 4.592 1.028 6.942 1.391l3.504.604c1.169.197 2.361.331 3.541.499 1.787.235 3.586.437 5.397.605a25.933 25.933 0 0 1 2.213-13.333 23.986 23.986 0 0 1 11.049-11.891c-2.609-1.916-5.274-3.671-7.97-5.341Z"
            fill="url(#female-34-25-shirt-1-arm-opt-9-left-b)"
        />
        <path
            d="M581.229 327.125a100.91 100.91 0 0 1-14.509-1.03c-1.18-.169-2.372-.302-3.541-.499l-3.504-.604c-2.35-.363-4.628-.925-6.942-1.391a81.25 81.25 0 0 1-13.438-4.03c-4.34-1.733-12.332-4.968-19.322-8.212M543.756 281.266c2.757.872 5.534 1.69 8.292 2.56a81.149 81.149 0 0 1 12.955 5.386c2.085 1.104 4.205 2.111 6.254 3.318l3.095 1.75c1.03.587 2.038 1.239 3.057 1.855a100.838 100.838 0 0 1 11.884 8.387M497.771 226.438l.119.115c4.455 4.407 9.198 8.52 13.818 12.759a115.329 115.329 0 0 1 12.8 13.795c3.959 4.911 7.772 9.971 11.382 15.237 1.974 2.905 3.906 5.855 5.739 8.903"
            stroke="#231F20"
            strokeWidth={2.27}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-9-left-a"
                x1={504.875}
                y1={181.029}
                x2={440.346}
                y2={303.238}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-9-left-b"
                x1={452.754}
                y1={272.799}
                x2={593.872}
                y2={272.799}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
