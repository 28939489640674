import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-down">
        <path
            fill="url(#man-34-25-jacket-arm-right-down-a)"
            d="M327.957 415.8c-7.1-33-11.9-66.1-15.3-90.3-1-7.1-2-13.8-2.8-19.5-.4-2.8-.4-5.7.2-8.4.1-1.3.2-2.7.5-3.9 4.6-21.7 16.6-54.3 26.6-72.8 1.2-2.3 2.5-4.5 3.7-6.8 2.8-5.1 5.7-10.4 8.4-15.7 5.5-10.9 16.4-17.6 28.6-17.6 5 0 9.9 1.2 14.4 3.4 15.8 7.9 22.1 27.2 14.2 43-2.7 5.3-4.9 10-7 14.6-6.8 14.6-12.7 27.2-30.8 52.6-4.1 5.8-7.7 10.4-11.1 14.6 1.6 14.3 3.8 34.8 4.5 45.9.5 7.7 1.5 16.6 2.7 26 1.1 9.6 2.3 19.5 3 29l.1.9-39.6 6-.3-1Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-down-a"
                x1={308.626}
                x2={386.048}
                y1={277.1}
                y2={312.233}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.039} stopColor="#888B8D" />
                <stop offset={0.954} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
