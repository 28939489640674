import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-up">
        <path
            fill="#FFDBAE"
            d="M500.057 186.301c-9.2-7.1-20-12.7-32.6-17.2l19.1 31.5c.1 0 17.9-10.9 13.5-14.3Z"
        />
        <path
            fill="url(#man-34-25-tshirt-arm-left-up-a)"
            d="M507.558 187.201c-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.4-1-8.1-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.2 5.1 27.3 7.7 17.3 5 37.7 8.1 55.3 10.3l1.8-60.6c-15.9-6-32.6-11.4-49.1-14.8Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-left-up-a"
                x1={468.824}
                x2={554.054}
                y1={101.748}
                y2={376.302}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
