import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-1">
        <path
            fill={SKIN_COLOR[color]}
            d="M408.639 214.258c-1.113 8.45-1.792 16.956-2.659 25.438a200.062 200.062 0 0 1-4.188 25.24c-1.858 8.354-3.936 16.68-6.32 24.966a248.742 248.742 0 0 1-8.367 24.7 23.274 23.274 0 0 1-30.347 12.755 23.329 23.329 0 0 1-14.282-18.52 248.727 248.727 0 0 1-1.816-26.017c-.2-8.62-.094-17.2.232-25.751a200.267 200.267 0 0 1 2.364-25.476c1.318-8.424 2.823-16.824 3.894-25.279a31.003 31.003 0 0 1 22.585-26.007 30.994 30.994 0 0 1 32.671 10.913 31.008 31.008 0 0 1 6.252 22.888l-.019.15Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m355.776 289.977 12.539-5.943 12.51-6c8.352-3.978 16.821-7.719 25.359-11.324 4.284-1.773 8.541-3.599 12.854-5.314 4.297-1.747 8.599-3.483 12.946-5.13 4.34-1.661 8.657-3.367 13.014-4.992 4.388-1.565 8.73-3.22 13.164-4.694a15.665 15.665 0 0 1 19.8 9.923 15.688 15.688 0 0 1-5.929 17.801c-3.837 2.665-7.766 5.148-11.65 7.72-3.913 2.513-7.867 4.945-11.799 7.422-3.924 2.492-7.893 4.893-11.867 7.285-3.959 2.423-7.973 4.735-11.96 7.1-8.005 4.671-16.077 9.206-24.268 13.504l-12.303 6.415-12.275 6.471a22.509 22.509 0 0 1-32.905-17.83 22.507 22.507 0 0 1 12.77-22.414Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m408.658 214.109-.019.15c-1.113 8.45-1.792 16.957-2.658 25.439a200.454 200.454 0 0 1-4.188 25.24m-59.317 43.898a248.69 248.69 0 0 1-1.816-26.016c-.2-8.62-.094-17.2.232-25.752.26-8.56 3.245-41.299 4.317-49.756m126.825 66.993c-3.838 2.665-7.766 5.147-11.65 7.72-3.914 2.513-7.868 4.945-11.799 7.422-3.924 2.491-7.893 4.893-11.867 7.285-3.959 2.422-7.973 4.735-11.96 7.1-8.005 4.671-16.078 9.206-24.269 13.504l-12.303 6.414-12.275 6.471c-10.994 5.797-28.455 2.107-33.005-16.308m25.41-29.877 12.51-6c8.352-3.978 16.821-7.719 25.359-11.324 4.284-1.773 8.541-3.599 12.854-5.314 4.297-1.747 8.599-3.483 12.946-5.129 4.34-1.662 8.657-3.368 13.014-4.993 4.388-1.565 11.665-3.893 16.099-5.366"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M531.759 257.884a36.053 36.053 0 0 0-1.746-1.175l.219-1.182a5.182 5.182 0 0 0-.744-3.758 37.544 37.544 0 0 0-7.538-8.571l.8-1.542c2.123-3.221 2.95-7.526 1.242-10.985-1.865-3.774-6.155-5.658-10.239-6.678-7.967-1.991-17.726-4.928-24.339-.061a259.193 259.193 0 0 0-24.453 20.43c-14.651 13.532-10.448 28.795 5.733 31.754a19.024 19.024 0 0 0 19.392-8.356c7.529 1.622 18.163 4.459 24.976 9.195a3.731 3.731 0 0 0 4.997-.593l.07-.082a3.76 3.76 0 0 0 .715-1.273 3.75 3.75 0 0 0-.224-2.855 3.746 3.746 0 0 0-.903-1.147c-7.783-6.57-19.59-11.294-28.753-14.437 11.745 2.031 26.33 4.823 38.451 6.954a3.102 3.102 0 0 0 3.493-2.11l.015-.046a3.123 3.123 0 0 0-1.164-3.482Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M464.961 244.362a259.193 259.193 0 0 1 24.453-20.43c6.614-4.867 16.372-1.93 24.339.06 4.084 1.02 8.374 2.905 10.239 6.678 1.709 3.459.882 7.764-1.242 10.985"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M495.785 235.914c7.1.393 14.385.948 20.813 3.987a29.041 29.041 0 0 1 13.012 12.305m-33.163-5.913c6.095.724 12.13 1.89 18.057 3.489a64.128 64.128 0 0 1 17.253 8.102 3.112 3.112 0 0 1 1.166 3.483l-.014.046a3.107 3.107 0 0 1-3.493 2.11c-12.12-2.132-26.706-4.923-38.45-6.955 9.165 3.144 20.975 7.87 28.758 14.443a3.732 3.732 0 0 1 1.121 3.999 3.728 3.728 0 0 1-.715 1.271l-.046.055a3.77 3.77 0 0 1-5.046.603c-6.814-4.726-17.431-7.558-24.951-9.178a19.018 19.018 0 0 1-19.392 8.356m35.375-41.789 6.085.12a3.603 3.603 0 0 1 2.427.645 2.385 2.385 0 0 1 .689 1.733 7.143 7.143 0 0 1-.306 1.887"
        />
    </g>
);

export default SvgComponent;
