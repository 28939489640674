import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6377)"
            d="M415.442 257.421c-1.524-4.687-3.098-9.533-4.453-14.352l-.04-.137a27.498 27.498 0 00-34.052-18.941 27.5 27.5 0 00-18.811 34.124c1.379 4.799 2.587 9.744 3.757 14.526.377 1.545.758 3.088 1.143 4.629l52.64-19.277-.184-.572z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6377"
                x1="357.014"
                x2="426.921"
                y1="250.143"
                y2="250.143"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
