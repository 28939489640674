import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-2">
        <path
            d="m465.525 167.793 2.55 1.203c12.537 5.916 22.677 12.593 30.998 20.411a2.666 2.666 0 0 1 .861 2.435c-.698 4.602-13.338 10.971-15.864 12.202l-.904.439-17.641-36.69Z"
            fill="url(#man-34-25-jacket-left-option-5-a)"
        />
        <path
            d="M592.422 331.164c-2.602 0-5.262-.04-7.909-.118-5.068-.121-10.398-.378-17.277-.833a135.876 135.876 0 0 1-4.315-.314c-1.59-.126-2.936-.242-4.3-.396-1.866-.169-3.758-.429-5.588-.68-.976-.134-1.951-.268-2.929-.389-1.765-.242-3.513-.508-5.262-.774a867.563 867.563 0 0 0-3.232-.486l-8.477-1.195a23.563 23.563 0 0 1-13.83-7.16 24.46 24.46 0 0 1-1.869-1.595 256.379 256.379 0 0 1-17.885-19.358c-5.351-6.358-10.614-13.032-16.091-20.407a204.777 204.777 0 0 1-14.282-21.579 854.277 854.277 0 0 1-3.848-6.751c-2.904-5.121-5.907-10.416-9.037-15.505a31.983 31.983 0 0 1-3.871-24.184 32.002 32.002 0 0 1 26.07-24.178 31.99 31.99 0 0 1 23.825 5.678 32.001 32.001 0 0 1 8.49 9.155l.08.13c3.106 5.105 6.452 10.189 9.689 15.106 1.425 2.165 2.845 4.334 4.261 6.505a205.009 205.009 0 0 1 12.709 22.547c4.068 8.227 7.614 15.951 10.837 23.609 2.272 5.446 4.071 10.025 5.638 14.357 1.35.272 2.699.547 4.047.837.982.225 1.954.435 2.924.645 1.797.388 3.655.789 5.479 1.273 1.411.34 2.838.727 4.166 1.096a134.6 134.6 0 0 1 4.16 1.178c6.509 1.893 11.76 3.522 16.517 5.125 2.608.862 5.191 1.76 7.749 2.695-6.795 11.922-8.222 27.916-6.638 35.966h-.001Z"
            fill="url(#man-34-25-jacket-left-option-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-2-a"
                x1={486.051}
                y1={162.512}
                x2={455.256}
                y2={322.994}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-2-b"
                x1={541.063}
                y1={173.069}
                x2={510.268}
                y2={333.55}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
