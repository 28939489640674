import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-7">
        <path
            d="M500.34 186.165c-9.225-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="#001489"
        />
        <path
            d="M538.653 246.393c-2.2-5.806-4.69-11.618-7.608-17.759a112.531 112.531 0 0 0-9.48-16.688c-1.11-1.621-2.236-3.232-3.362-4.843-2.46-3.518-5.003-7.156-7.318-10.843l-.147-.233a31.896 31.896 0 0 0-26.983-14.753 31.887 31.887 0 0 0-17.22 5.051 31.781 31.781 0 0 0-14.007 20.092 31.786 31.786 0 0 0 4.304 24.11c2.344 3.663 4.548 7.515 6.68 11.24a590.237 590.237 0 0 0 2.945 5.106 112.287 112.287 0 0 0 11.042 15.69c4.316 5.27 8.509 10.002 12.822 14.47 2.364 2.429 5.249 5.32 8.532 8.274 7.053-17.113 24.402-33.053 39.8-38.914Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
