import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6532)"
            d="M403.391 352.069a22.335 22.335 0 01-16.272-6.77c-1.854-1.931-3.188-4.837-4.475-7.136a187.731 187.731 0 01-9.203-19.796c-2.566-6.375-4.945-12.967-7.274-20.158a147.612 147.612 0 01-5.303-20.646c-.38-2.09-.746-4.187-1.112-6.283-.831-4.769-1.691-9.7-2.73-14.498a27.311 27.311 0 013.755-20.71 27.322 27.322 0 0117.299-11.99 27.703 27.703 0 015.848-.629 27.646 27.646 0 0126.851 21.683l.031.142c1.014 4.809 2.244 9.659 3.434 14.349.524 2.065 1.048 4.129 1.555 6.197a147.358 147.358 0 013.603 21.016c.775 7.154 1.287 13.837 1.563 20.384a225.627 225.627 0 0122.321-5.166c1.252-.218 2.497-.445 3.742-.674 2.546-.465 5.179-.946 7.802-1.328l5.77-.904c1.257-.195 2.52-.36 3.782-.526.682-.09 1.364-.18 2.045-.273 5.832-.768 10.602-1.297 15.018-1.667l1.4-.118-.347 1.359c-2.396 9.424 1.625 20.556 6.726 25.466l1.052 1.013-1.324.615c-4.01 1.864-8.411 3.768-13.848 5.992-.646.259-1.285.52-1.922.78-1.178.482-2.355.963-3.54 1.417l-5.471 2.082c-2.454.964-4.976 1.851-7.415 2.709-1.196.42-2.394.84-3.588 1.272a225.207 225.207 0 01-22.419 6.699l-11.334 2.688-11.279 2.822c-1.55.389-3.142.586-4.74.587h-.001z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6532"
                x1="368.977"
                x2="448.784"
                y1="212.199"
                y2="344.055"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
