import { IBodyClothes } from 'interfaces';

import Shirt3Cycling1 from './man-34-25-shirt-3-cycling';
import Shirt3Cycling2 from './man-34-25-shirt-3-cycling-2';

import Shirt3BothArmsOption1RightCycling from './man-34-25-shirt-3-both-arms-option-1-right-cycling';
import Shirt3BothArmsOption1LeftCycling from './man-34-25-shirt-3-both-arms-option-1-left-cycling';
import Shirt3BothArmsOption1RightCycling2 from './man-34-25-shirt-3-both-arms-option-1-right-cycling-2';
import Shirt3BothArmsOption1LeftCycling2 from './man-34-25-shirt-3-both-arms-option-1-left-cycling-2';

const shirt3: IBodyClothes = {
    text: 'Shirt #3',
    cycling1: {
        front: Shirt3Cycling1,
        bothArms: {
            option1: [
                Shirt3BothArmsOption1RightCycling,
                Shirt3BothArmsOption1LeftCycling,
            ],
        },
    },
    cycling2: {
        front: Shirt3Cycling2,
        bothArms: {
            option1: [
                Shirt3BothArmsOption1RightCycling2,
                Shirt3BothArmsOption1LeftCycling2,
            ],
        },
    },
};

export default shirt3;
