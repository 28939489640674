import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7341)"
            d="M494.768 223.344a109.59 109.59 0 00-20.026-15.328 31.03 31.03 0 012.394 13.894 65.926 65.926 0 01-1.451 10.763l2.169 4.754a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7341)"
            d="M463.277 269.778a296.997 296.997 0 00-4.602-5.337 27.5 27.5 0 1141.166-36.475l.084.095a313.893 313.893 0 005.372 5.938l.707.766-42.08 35.78-.647-.767z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7341"
                x1="507.299"
                x2="369.371"
                y1="207.684"
                y2="277.298"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7341"
                x1="514.245"
                x2="376.318"
                y1="221.443"
                y2="291.057"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
