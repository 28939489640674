import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#E2E2E2"
                d="M574.08 689.848c-4.73-4.001-9.2-12.035-10.792-19.202a79.892 79.892 0 01-1.412-14.44c-.05-1.393-.168-2.783-.173-4.173-.021-5.802-1.955-13.682-3.057-17.82 8.409-5.42 27.867-11.068 35.277-9.669 3.697 8.2 10.484 23.092 14.59 31.429l.492.999c4.17 8.457 8.481 17.203 9.948 26.441.579 3.657.827 7.359.74 11.061-.433 11.35-13.734 22.338-45.613-4.626z"
            ></path>
            <path
                fill="#E2E2E2"
                d="M574.08 693.848c-4.73-4.001-9.2-12.035-10.792-19.202a79.884 79.884 0 01-1.412-14.44c-.05-1.393-.101-2.784-.173-4.173a76.044 76.044 0 00-1.744-12.428c8.409-5.42 8.414-9.315 16.795-11.926 8.819-2.747 12.723 1.881 20.133 3.279 3.697 8.2 7.52 16.679 11.626 25.015l.492.999c4.17 8.458 8.482 17.204 9.949 26.441.578 3.657.826 7.36.739 11.062-.433 11.349-13.734 22.337-45.613-4.627z"
            ></path>
            <path
                fill="#63666A"
                d="M574.08 689.848c-4.73-4.001-9.2-12.035-10.792-19.202a79.884 79.884 0 01-1.412-14.44c-.05-1.393-.101-2.784-.173-4.173a76.044 76.044 0 00-1.744-12.428c8.409-5.42 8.414-9.315 16.795-11.926 8.819-2.747 12.723 1.881 20.133 3.279 3.697 8.2 7.52 16.679 11.626 25.015l.492.999c4.17 8.458 8.482 17.204 9.949 26.441.578 3.657.826 7.36.739 11.062-.19 4.965-3.84 4.511-9.433 6.19-7.192 2.159-18.248 4.35-36.18-10.817z"
            ></path>
        </g>
    );
}
