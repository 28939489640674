import { IBodyClothes } from 'interfaces';

import Tshirt5 from './female-34-25-tshirt-5';
import Tshirt5Sitting from './female-34-25-tshirt-5-sitting';

import Opt1R from './female-34-25-tshirt-5-arm-opt-1-right';
import Opt2R from './female-34-25-tshirt-5-arm-opt-2-right';
import Opt3R from './female-34-25-tshirt-5-arm-opt-3-right';
import Opt4R from './female-34-25-tshirt-5-arm-opt-4-right';
import Opt5R from './female-34-25-tshirt-5-arm-opt-5-right';
import Opt6R from './female-34-25-tshirt-5-arm-opt-6-right';
import Opt7R from './female-34-25-tshirt-5-arm-opt-7-right';
import Opt8R from './female-34-25-tshirt-5-arm-opt-8-right';
import Opt9R from './female-34-25-tshirt-5-arm-opt-9-right';

import Opt1L from './female-34-25-tshirt-5-arm-opt-1-left';
import Opt2L from './female-34-25-tshirt-5-arm-opt-2-left';
import Opt3L from './female-34-25-tshirt-5-arm-opt-3-left';
import Opt4L from './female-34-25-tshirt-5-arm-opt-4-left';
import Opt5L from './female-34-25-tshirt-5-arm-opt-5-left';
import Opt6L from './female-34-25-tshirt-5-arm-opt-6-left';
import Opt7L from './female-34-25-tshirt-5-arm-opt-7-left';
import Opt8L from './female-34-25-tshirt-5-arm-opt-8-left';
import Opt9L from './female-34-25-tshirt-5-arm-opt-9-left';
import Opt10L from './female-34-25-tshirt-5-arm-opt-10-left';

import Opt1ArmsR from './female-34-25-tshirt-5-arms-opt-1-right';
import Opt1ArmsL from './female-34-25-tshirt-5-arms-opt-2-left';
import Opt2ArmsR from './female-34-25-tshirt-5-arms-opt-2-right';
import Opt2ArmsL from './female-34-25-tshirt-5-arms-opt-2-left';

const tshirt5: IBodyClothes = {
    text: 'Tshirt #5',
    allowWithoutSleeves: true,
    normal: {
        front: Tshirt5,
        leftArm: {
            option1: Opt1L,
            option2: Opt2L,
            option3: Opt3L,
            option4: Opt4L,
            option5: Opt5L,
            option6: Opt6L,
            option7: Opt7L,
            option8: Opt8L,
            option9: Opt9L,
            option10: Opt10L,
        },
        rightArm: {
            option1: Opt1R,
            option2: Opt2R,
            option3: Opt3R,
            option4: Opt4R,
            option5: Opt5R,
            option6: Opt6R,
            option7: Opt7R,
            option8: Opt8R,
            option9: Opt9R,
        },
        bothArms: {
            option1: [Opt1ArmsR, Opt1ArmsL],
            option2: [Opt2ArmsR, Opt2ArmsL],
        },
    },
    sitting: {
        front: Tshirt5Sitting,
        leftArm: {
            option1: Opt1L,
            option2: Opt2L,
            option3: Opt3L,
            option4: Opt4L,
            option5: Opt5L,
            option6: Opt6L,
            option7: Opt7L,
            option8: Opt8L,
            option9: Opt9L,
            option10: Opt10L,
        },
        rightArm: {
            option1: Opt1R,
            option2: Opt2R,
            option3: Opt3R,
            option4: Opt4R,
            option5: Opt5R,
            option6: Opt6R,
            option7: Opt7R,
            option8: Opt8R,
            option9: Opt9R,
        },
        bothArms: {
            option1: [Opt1ArmsR, Opt1ArmsL],
            option2: [Opt2ArmsR, Opt2ArmsL],
        },
    },
};

export default tshirt5;
