import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M417.399 347.979a22.642 22.642 0 0 1-14.1-4.626 33.365 33.365 0 0 1-5.806-6.527 208.71 208.71 0 0 1-12.541-19.355c-3.636-6.274-7.135-12.819-10.695-20.012a165.586 165.586 0 0 1-8.818-20.786c-1.12-3.226-1.697-5.333-2.253-7.369a78.34 78.34 0 0 0-2.712-8.408 26.51 26.51 0 0 1 6.524-30.003 26.506 26.506 0 0 1 9.336-5.507 27.383 27.383 0 0 1 9.82-1.824 27.667 27.667 0 0 1 25.69 17.683l.051.136c1.833 4.866 3.89 9.753 5.879 14.477a1478.27 1478.27 0 0 1 2.619 6.26 58.818 58.818 0 0 1 3.506 10.989c.333 1.403.713 3.006 1.251 5.047 2.104 7.724 3.828 14.942 5.274 22.056a311.406 311.406 0 0 1 1.468 7.848l6.26-.439c4.184-.27 8.453-.408 12.691-.408 3.082 0 6.225.073 9.34.216 1.196.061 2.387.11 3.577.16 2.435.103 4.953.208 7.443.408l5.486.379c1.196.086 2.392.2 3.589.314.643.062 1.287.124 1.93.181 2.786.265 5.254.525 7.557.795l1.188.138-.347 1.145c-3.301 10.884-.234 23.735 3.419 28.157l1.227 1.453-1.615.343c-2.946.625-6.341 1.07-10.017 1.731-.646.112-1.286.227-1.924.342-1.18.213-2.36.426-3.541.61l-5.448.835c-2.452.406-4.951.72-7.368 1.024a358.81 358.81 0 0 0-3.55.457 200.347 200.347 0 0 1-21.971 1.639l-10.96.145-10.938.289c-.174.004-.348.007-.521.007Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-6-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-6-right-a"
                x1={355.9}
                y1={245.296}
                x2={474.96}
                y2={348.742}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
