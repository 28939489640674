import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.595 3.56H1.715M58.7 2.98a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M49.178 1.576h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.9-3.899Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.647 1.577h7.447c3.003 0 5.722 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.45 1.678-10.01-3.414-10.01-8.943V5.524a3.948 3.948 0 0 1 3.947-3.948v.001Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M49.178 1.576h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.9-3.899v0ZM62.647 1.577h7.447c3.003 0 5.722 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.45 1.678-10.01-3.414-10.01-8.943V5.524a3.948 3.948 0 0 1 3.947-3.948v.001Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.569}
                        x2={40.569}
                        y1={16.065}
                        y2={6.66}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={67.042}
                        x2={67.042}
                        y1={16.066}
                        y2={6.661}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.407 3.56H1.527M58.512 2.98a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M48.99 1.576H37.131a9.256 9.256 0 0 0-9.256 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.899-3.899Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.46 1.577h7.447c3.003 0 5.722 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.451 1.678-10.011-3.414-10.011-8.943V5.524a3.948 3.948 0 0 1 3.948-3.948v.001Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M48.99 1.576H37.131a9.256 9.256 0 0 0-9.256 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.899-3.899v0ZM62.46 1.577h7.447c3.003 0 5.722 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.451 1.678-10.011-3.414-10.011-8.943V5.524a3.948 3.948 0 0 1 3.948-3.948v.001Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.381}
                        x2={40.381}
                        y1={18.296}
                        y2={5.164}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={66.855}
                        x2={66.855}
                        y1={18.297}
                        y2={5.165}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.877 3.56H1.997M58.98 2.98a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M49.459 1.576h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.9-3.899Z"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.928 1.577h7.448c3.002 0 5.721 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.452 1.678-10.012-3.414-10.012-8.943V5.524a3.948 3.948 0 0 1 3.949-3.948v.001Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M49.459 1.576h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.475a3.9 3.9 0 0 0-3.9-3.899v0ZM62.928 1.577h7.448c3.002 0 5.721 2.255 5.233 5.71-.515 3.642-1.672 10.841-6.617 12.363-5.452 1.678-10.012-3.414-10.012-8.943V5.524a3.948 3.948 0 0 1 3.949-3.948v.001Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.85}
                        x2={40.85}
                        y1={18.296}
                        y2={5.164}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={67.323}
                        x2={67.323}
                        y1={18.297}
                        y2={5.165}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.79 3.287H1.91M58.894 2.706a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M49.373 1.303h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.2a3.9 3.9 0 0 0-3.9-3.898Z"
                    opacity={0.7}
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.843 1.304h7.447c3.002 0 5.721 2.255 5.232 5.709-.515 3.643-1.672 10.842-6.617 12.364-5.45 1.678-10.01-3.414-10.01-8.943V5.25a3.948 3.948 0 0 1 3.947-3.948Z"
                    opacity={0.7}
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M49.373 1.303h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.2a3.9 3.9 0 0 0-3.9-3.898v0ZM62.843 1.304h7.447c3.002 0 5.721 2.255 5.232 5.709-.515 3.643-1.672 10.842-6.617 12.364-5.45 1.678-10.01-3.414-10.01-8.943V5.25a3.948 3.948 0 0 1 3.947-3.948v0Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.764}
                        x2={40.764}
                        y1={15.793}
                        y2={6.388}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={67.237}
                        x2={67.237}
                        y1={15.793}
                        y2={6.388}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.396} stopColor="#FFC614" />
                        <stop offset={1} stopColor="#FFB33B" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.267 3.803H1.387M58.371 3.222a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M48.85 1.818H36.99a9.256 9.256 0 0 0-9.256 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.717a3.9 3.9 0 0 0-3.899-3.899Z"
                    opacity={0.6}
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.32 1.82h7.446c3.003 0 5.722 2.254 5.233 5.708-.515 3.643-1.672 10.842-6.617 12.364-5.45 1.678-10.01-3.414-10.01-8.943V5.766a3.948 3.948 0 0 1 3.947-3.948v.001Z"
                    opacity={0.6}
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M48.85 1.818H36.99a9.256 9.256 0 0 0-9.256 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824V5.717a3.9 3.9 0 0 0-3.899-3.899ZM62.32 1.82h7.446c3.003 0 5.722 2.254 5.233 5.708-.515 3.643-1.672 10.842-6.617 12.364-5.45 1.678-10.01-3.414-10.01-8.943V5.766a3.948 3.948 0 0 1 3.947-3.948v.001Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.24}
                        x2={40.24}
                        y1={18.539}
                        y2={5.407}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={66.714}
                        x2={66.714}
                        y1={18.539}
                        y2={5.407}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#026BD9" />
                        <stop offset={0.205} stopColor="#025DCE" />
                        <stop offset={0.587} stopColor="#033AB2" />
                        <stop offset={1} stopColor="#050D90" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 77,
        height: 24,
        content: (
            <>
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M31.704 3.334H1.824M58.809 2.753a7.431 7.431 0 0 0-5.621 0"
                />
                <path
                    fill="url(#object-devices-sunglasses-1-a)"
                    d="M49.287 1.35h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824v-1.25a3.9 3.9 0 0 0-3.9-3.898Z"
                    opacity={0.7}
                />
                <path
                    fill="url(#object-devices-sunglasses-1-b)"
                    d="M62.757 1.35h7.447c3.003 0 5.722 2.256 5.233 5.71-.515 3.643-1.672 10.842-6.617 12.364-5.451 1.678-10.011-3.414-10.011-8.943V5.298a3.948 3.948 0 0 1 3.948-3.948Z"
                    opacity={0.7}
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M49.287 1.35h-11.86a9.256 9.256 0 0 0-9.255 9.256v4.776a6.94 6.94 0 0 0 6.94 6.94h2.25c8.74 0 15.824-7.085 15.824-15.824v-1.25a3.9 3.9 0 0 0-3.9-3.898ZM62.757 1.35h7.447c3.003 0 5.722 2.256 5.233 5.71-.515 3.643-1.672 10.842-6.617 12.364-5.451 1.678-10.011-3.414-10.011-8.943V5.298a3.948 3.948 0 0 1 3.948-3.948v0Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-sunglasses-1-a"
                        x1={40.679}
                        x2={40.679}
                        y1={18.071}
                        y2={4.939}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-sunglasses-1-b"
                        x1={67.152}
                        x2={67.152}
                        y1={18.071}
                        y2={4.939}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#686568" />
                        <stop offset={0.212} stopColor="#5F5C5F" />
                        <stop offset={0.549} stopColor="#494548" />
                        <stop offset={0.967} stopColor="#252123" />
                        <stop offset={1} stopColor="#221E20" />
                    </linearGradient>
                </defs> 
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-sunglasses-1"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
