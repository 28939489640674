import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6985)"
            d="M479.229 236.312c-.182-4.781-.96-19.424-3.838-27.14a101.176 101.176 0 0118.684 14.4 1.708 1.708 0 01.536 1.597c-.512 2.966-8.018 7.688-15.382 11.143z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6985)"
            d="M539.26 337.077c-.82 0-1.638-.014-2.453-.042a53.115 53.115 0 01-11.205-1.365c-1.086-.256-2.171-.526-3.256-.795-2.449-.608-4.981-1.235-7.485-1.692a19.396 19.396 0 01-12.242-7.691l-.038-.032c-.143-.12-.287-.24-.425-.365a169.153 169.153 0 01-14.186-15.054c-4.188-4.97-8.262-10.197-12.454-15.983a131.733 131.733 0 01-10.726-17.05 586.613 586.613 0 01-2.792-5.351c-2.1-4.053-4.273-8.245-6.578-12.259a27.507 27.507 0 012.069-30.486 27.508 27.508 0 0118.252-10.482 27.5 27.5 0 0127.374 13.578l.08.14c2.282 4.028 4.784 8.033 7.204 11.905 1.066 1.708 2.133 3.415 3.186 5.13A131.79 131.79 0 01522.8 267.1c2.844 6.548 5.27 12.715 7.412 18.844a213.395 213.395 0 013.362 10.547 53.586 53.586 0 0110.311 2.679c.881.306 1.77.637 2.658.984.923.341 1.857.735 2.647 1.075l2.603 1.103c.575.246 1.14.52 1.705.793.305.146.61.294.916.437a69.102 69.102 0 0110.323 5.962l2.213 1.589-2.715.221c-6.567.532-11.76 3.466-14.623 8.261-2.728 4.566-2.924 10.304-.54 15.742l.571 1.303-1.42.096c-.332.023-.666.05-1 .076-.63.052-1.259.103-1.884.125l-2.835.091c-.765.03-1.536.052-2.302.052l-.942-.003z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6985"
                x1="546.985"
                x2="474.117"
                y1="361.216"
                y2="202.469"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6985"
                x1="546.163"
                x2="473.296"
                y1="361.596"
                y2="202.849"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
