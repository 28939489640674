import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-2">
        <path
            d="M418.511 248.993a146.621 146.621 0 0 0-4.333-21.474 811.218 811.218 0 0 0-1.801-6.315c-1.377-4.777-2.8-9.717-3.992-14.619l-.043-.174a31.936 31.936 0 0 0-31.027-24.27 32.084 32.084 0 0 0-7.761.956 32.035 32.035 0 0 0-23.313 38.789c1.219 4.891 2.251 9.928 3.249 14.799.439 2.144.879 4.288 1.335 6.424a146.58 146.58 0 0 0 6.127 21.028c2.651 7.319 5.335 14.006 8.205 20.447 1.127 2.505 5.465-10.447 25.128-17.965 17.938-6.859 29.813-3.711 29.813-3.711-.414-4.5-.931-9.098-1.587-13.915Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-2-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-2-a"
                x1={367.073}
                y1={173.672}
                x2={390.495}
                y2={267.359}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
