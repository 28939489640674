import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7659)"
            d="M524.44 280.019c-20.409-24.152-26.325-32.894-31.648-46.771-2.986-7.786-9.421-18.228-14.918-23.949a47.493 47.493 0 00-4.724-2.974c4.064 7.051 4.949 18.802-6.423 23.57a21.989 21.989 0 01-8.678 1.436c-10.921 0-21.127-6.506-31.94-13.399-7.282-4.644-14.812-9.444-22.911-12.429a32.696 32.696 0 00-10.802-1.712 31.608 31.608 0 00-4.391.292c-.16.023-.314.078-.452.162a62.74 62.74 0 00-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.614 45.361-27.936 63.292 14.883 0 179.194.875 179.224.714 9.23-48.617-.85-76.583-9.312-98.687a518.743 518.743 0 01-2.686-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.943-7.614-1.082-16.22-8.991-25.578z"
        ></path>
        <path
            fill="#000"
            d="M392.4 205.791a30.63 30.63 0 0110.11 1.589c7.899 2.91 15.336 7.652 22.531 12.241 11.06 7.051 21.508 13.71 33.011 13.71a24.222 24.222 0 009.451-1.592 16.174 16.174 0 009.54-9.743 19.004 19.004 0 00.319-10.31 83.047 83.047 0 0113.566 22.278c5.421 14.133 11.4 22.982 31.988 47.346 7.467 8.835 10.354 16.84 8.581 23.793-2.286 8.964-11.853 14.549-19.475 17.656l-1.788.728.665 1.812c.87 2.373 1.798 4.795 2.695 7.137 7.742 20.22 18.312 47.833 9.516 95.99l-1.644.002c-13.211 0-63.146-.234-107.203-.44-30.509-.143-57.25-.268-66.426-.29 2.578-18.421 8.1-39.693 27.107-59.927 8.912-9.487 11.519-23.614 6.803-36.869l-18.824-52.892a60.734 60.734 0 0125.53-71.969 29.588 29.588 0 013.947-.25zm0-2a31.456 31.456 0 00-4.391.293c-.16.022-.314.077-.452.161a62.744 62.744 0 00-26.519 74.436l18.825 52.892c4.37 12.284 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 13.63 0 152.596.734 175.915.734 2.143 0 3.307-.006 3.309-.02 9.23-48.617-.85-76.583-9.313-98.687a531.766 531.766 0 01-2.685-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.942-7.615-1.083-16.221-8.991-25.578-20.409-24.152-26.325-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a47.493 47.493 0 00-4.724-2.974c4.065 7.051 4.949 18.802-6.423 23.57a22.005 22.005 0 01-8.678 1.437c-10.921 0-21.127-6.507-31.939-13.4-7.283-4.643-14.812-9.444-22.911-12.429a32.696 32.696 0 00-10.802-1.712z"
        ></path>
        <g filter="url(#filter0_f_11323_7659)">
            <path
                fill="url(#paint1_radial_11323_7659)"
                d="M410.676 290.993l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.265 14.814 1.029 21.605-1.697a30.978 30.978 0 0012.048-48.825l-6.917-10.635"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11323_7659"
                width="91.355"
                height="77.898"
                x="402.676"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7659"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7659"
                x1="345.547"
                x2="534.002"
                y1="317.109"
                y2="317.109"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11323_7659"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.981 -1072.037) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#E2E2E2" stopOpacity="0"></stop>
                <stop offset="0.587" stopColor="#E2E2E2" stopOpacity="0.33"></stop>
                <stop offset="0.763" stopColor="#E2E2E2" stopOpacity="0.688"></stop>
                <stop offset="0.888" stopColor="#E2E2E2" stopOpacity="0.913"></stop>
                <stop offset="0.949" stopColor="#E2E2E2"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
