import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6343)"
            d="M327.419 423.154c-4.989-28.45-7.237-61.76-6.501-96.328a19.418 19.418 0 01.257-3.203 21.399 21.399 0 01.708-5.517c5.222-19.797 17.772-49.371 27.974-65.926a760.492 760.492 0 013.688-5.962l.056-.09c2.86-4.601 5.795-9.325 8.504-14.06a27.786 27.786 0 0124.069-13.951 27.702 27.702 0 0113.729 3.66 27.535 27.535 0 0112.974 16.768 27.523 27.523 0 01-2.684 21.031c-2.696 4.713-4.927 8.91-7.086 12.969-7.023 13.212-13.064 24.577-31.109 47.038-4.305 5.324-7.936 9.563-11.424 13.331 1.029 27.278 1.675 56.597-2.541 88.692l-.13.989-30.3 1.606-.184-1.047z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6343"
                x1="317.664"
                x2="424.363"
                y1="280.962"
                y2="377.251"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
