import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8485)"
            d="M364.34 267.406c-1.462-3.107-2.951-6.27-4.604-9.289a26.514 26.514 0 01-3.101-9.864 26.512 26.512 0 015.693-19.468 26.51 26.51 0 0117.791-9.739 26.493 26.493 0 0119.468 5.693 26.494 26.494 0 016.639 7.926l.152.282c1.188 2.232 2.495 4.441 3.809 6.605a25.756 25.756 0 00-5.212-.478c-14.068 0-35.629 7.945-40.056 29.56l-.579-1.228z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M406.226 232.668l.152.281c1.188 2.233 2.495 4.442 3.809 6.605a25.827 25.827 0 00-5.212-.477c-14.068 0-35.629 7.945-40.056 29.56l-.579-1.229c-1.462-3.107-2.951-6.27-4.604-9.289a26.503 26.503 0 012.592-29.331 26.516 26.516 0 017.927-6.64"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8485"
                x1="363.806"
                x2="388.529"
                y1="231.147"
                y2="251.967"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
