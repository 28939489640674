import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt">
        <path
            fill="url(#man-34-25-tshirt-a)"
            d="M346.951 452.5c-.7 0-1.3-.5-1.3-1.2-2.8-23.2 1.1-43.7 4.9-63.4 4.4-23 8.6-44.6 2.1-68.8-6.5-23.9-10.1-50-10.8-77.5l-.5-19.1c-.4-15.6 4.4-28.6 17.9-40.2 19.2-16.5 43.6-25.9 47-26.8 18.5 14.6 35.4 20.7 35.4 20.7l21.9 16.3c1.1-8.2 2-23.5 1.8-25.5.3 0 5.9 3.7 8.1 5.4 27.2 19.9 46.6 61.3 52.5 82.8 2.1 7.8 2.4 14.5.6 19.8-3.3 10.3-2.9 21.1 1 30.6 5.6 13.6 8.3 26 8.7 40.3.3 11 0 95.8 0 105.4 0 .7-.6 1.3-1.3 1.3h-188v-.1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M406.352 156.1c12.3 10.3 25.5 16.8 35.5 20.2l24.6 19 8-19.5 7.299 6.6"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m441.853 176.3-9.5 13.1-20.9-22.7m57.297 32.6c4.3 4.3 9.6 14.6 10.5 16.6"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-a"
                x1={341.466}
                x2={536.536}
                y1={304.053}
                y2={304.053}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
