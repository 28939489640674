import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7328)"
            d="M494.78 222.547a109.624 109.624 0 00-20.026-15.328 31.045 31.045 0 012.394 13.894 66.035 66.035 0 01-1.451 10.763l2.169 4.754a1.001 1.001 0 001.33.493c2.598-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7328)"
            d="M508.181 234.769c-3.192-2.88-6.493-5.858-9.592-8.924l-.121-.119a27.498 27.498 0 00-38.889.458 27.5 27.5 0 00.458 38.888c2.165 2.114 4.287 4.327 6.367 6.534l43.672-35.118c-.632-.572-1.261-1.148-1.895-1.719z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7328"
                x1="516.612"
                x2="395.166"
                y1="188.537"
                y2="297.838"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7328"
                x1="523.65"
                x2="402.205"
                y1="196.357"
                y2="305.658"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
