import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M548.861 738.151c-.448-5.519-.891-11.04-1.33-16.562l-1.88-23.435-2.02-23.409-4.062-46.816a27.02 27.02 0 0 0-.576-3.599 347.5 347.5 0 0 0 4.084-45.91c.026-1.389.056-2.78.091-4.171.058-2.555.118-5.109.139-7.662l.024-11.775c-.003-6.462-.094-14.967-.77-23.453-.583-7.402-1.498-15.154-2.719-23.04-1.39-8.934-2.673-16.158-4.037-22.736-.724-3.484-1.545-7.434-2.501-11.275-.239-.977-.472-1.955-.705-2.933-.649-2.72-1.319-5.533-2.108-8.27l-1.53-5.592-1.694-5.532c-1.289-4.125-2.549-7.733-3.848-11.027l-1.81-4.62c-7.76-19.674-.706-25.3-.706-27.686h-54.721s-25.974 4.29-34.377 15.354a51.665 51.665 0 0 0-10.112 38.503 200.116 200.116 0 0 0 1.896 11.43l1.126 5.671 1.259 5.608c.594 2.793 1.304 5.601 1.99 8.317.246.97.491 1.94.73 2.902.671 2.809 1.463 5.632 2.229 8.362.262.935 2.34 8.391 2.34 8.391l1.698 5.532c.407 1.298.803 2.599 1.199 3.899.714 2.35 1.454 4.779 2.248 7.152 2.249 6.934 4.92 14.095 8.165 21.895 1.639 3.94 3.412 7.916 5.127 11.762 1.43 3.207 2.908 6.523 4.3 9.797l4.626 10.783 4.52 10.813 17.683 42.451a107.925 107.925 0 0 0-1.817 10.714 111.184 111.184 0 0 0-.466 4.297c-.056.584-.112 1.169-.175 1.791-.045.702-.099 1.406-.152 2.11-.096 1.278-.197 2.599-.245 3.887a225.587 225.587 0 0 0 .096 23.806c1.155 17.548 2.94 32.176 7.897 46.507 1.652 5.049 8.455 29.355 9.611 33.967l52.582-10.002c-.429-5.446-.868-10.87-1.299-16.196Z"
            fill="#009CDE"
        />
        <path
            d="M349.306 408.556c-2.648 13.07-2.238 24.704-.148 38.512a194.33 194.33 0 0 0-1.516 11.31l-.574 5.666-.43 5.642c-.246 2.804-.384 5.655-.519 8.412-.048.982-.096 1.965-.148 2.939-.175 2.832-.24 5.712-.303 8.496-.022.964-.206 8.6-.206 8.6l.013 5.699c.011 1.339.01 2.676.01 4.015 0 2.419-.001 4.921.067 7.387.132 7.183.601 14.699 1.433 22.978.42 4.18.957 8.433 1.477 12.546.434 3.436.882 6.989 1.26 10.477l1.285 11.49 1.173 11.488 3.96 39.245a96.378 96.378 0 0 0-6.415 8.044c-.81 1.095-1.572 2.219-2.31 3.306-.314.463-.628.927-.97 1.423-.353.56-.713 1.116-1.073 1.672a89.679 89.679 0 0 0-1.944 3.089 198.096 198.096 0 0 0-10.499 19.668c-6.775 14.994-11.692 27.869-13.66 41.953-.777 4.919-1.257 10.013-1.72 14.94-.235 2.501-3.477 28.99-3.477 28.99s14.097 6.108 25.374 9.867a136.92 136.92 0 0 0 23.128 5.498c2.57-8.425 22.239-59.498 22.239-59.498l8.608-20.207 17.194-40.423a27.145 27.145 0 0 0 1.303-3.895l1.289-2.492c8.177-15.814 14.068-31.844 18.29-44.056a710.98 710.98 0 0 1 1.3-3.895 510.235 510.235 0 0 0 2.367-7.178l3.453-11.079c1.879-6.084 4.271-14.118 6.096-22.302 1.6-7.14 2.982-14.703 4.111-22.479 1.272-8.807 2.15-15.98 2.762-22.574l.01-.108a43.385 43.385 0 0 1 18.06-31.407 157.814 157.814 0 0 0 12.84-10.235c20.888-18.462 32.63-35.648 38.559-51.525l-171.749.001Z"
            fill="url(#female-34-25-pants-6-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-6-walk-1-a"
                x1={468.803}
                y1={582.864}
                x2={190.063}
                y2={588.739}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#3EC0E9" />
                <stop offset={1} stopColor="#009CDE" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
