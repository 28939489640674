import { IBodyClothes } from 'interfaces';

import Tshirt1 from './man-34-25-tshirt-1-normal';
import Sitting1 from './man-34-25-tshirt-1-sitting';

import Tshirt1RightArmDown from './man-34-25-tshirt-1-arm-right-down';
import Tshirt1RightArmOption1 from './man-34-25-tshirt-1-arm-right-option-1';
import Tshirt1RightArmOption2 from './man-34-25-tshirt-1-arm-right-option-2';
import Tshirt1RightArmOption3 from './man-34-25-tshirt-1-arm-right-option-3';
import Tshirt1RightArmOption4 from './man-34-25-tshirt-1-arm-right-option-4';
import Tshirt1RightArmOption5 from './man-34-25-tshirt-1-arm-right-option-5';
import Tshirt1RightArmOption6 from './man-34-25-tshirt-1-arm-right-option-6';
import Tshirt1RightArmOption7 from './man-34-25-tshirt-1-arm-right-option-7';

import Tshirt1LeftArmDown from './man-34-25-tshirt-1-arm-left-down';
import Tshirt1LeftArmUp from './man-34-25-tshirt-1-arm-left-up';
import Tshirt1LeftArmOption1 from './man-34-25-tshirt-1-arm-left-option-1';
import Tshirt1LeftArmOption2 from './man-34-25-tshirt-1-arm-left-option-2';
import Tshirt1LeftArmOption3 from './man-34-25-tshirt-1-arm-left-option-3';
import Tshirt1LeftArmOption4 from './man-34-25-tshirt-1-arm-left-option-4';
import Tshirt1LeftArmOption5 from './man-34-25-tshirt-1-arm-left-option-5';
import Tshirt1LeftArmOption6 from './man-34-25-tshirt-1-arm-left-option-6';
import Tshirt1LeftArmOption7 from './man-34-25-tshirt-1-arm-left-option-7';

import Tshirt1BothArmsOption1Right from './man-34-25-tshirt-1-both-arms-option-1-right';
import Tshirt1BothArmsOption1Left from './man-34-25-tshirt-1-both-arms-option-1-left';
import Tshirt1BothArmsOption2Right from './man-34-25-tshirt-1-both-arms-option-2-right';
import Tshirt1BothArmsOption2Left from './man-34-25-tshirt-1-both-arms-option-2-left';
import Tshirt1BothArmsOption3Right from './man-34-25-tshirt-1-both-arms-option-3-right';
import Tshirt1BothArmsOption3Left from './man-34-25-tshirt-1-both-arms-option-3-left';

const tshirt1: IBodyClothes = {
    text: 'Tshirt #1',
    normal: {
        front: Tshirt1,
        rightArm: {
            down: Tshirt1RightArmDown,
            option1: Tshirt1RightArmOption1,
            option2: Tshirt1RightArmOption2,
            option3: Tshirt1RightArmOption3,
            option4: Tshirt1RightArmOption4,
            option5: Tshirt1RightArmOption5,
            option6: Tshirt1RightArmOption6,
            option7: Tshirt1RightArmOption7,
        },
        leftArm: {
            down: Tshirt1LeftArmDown,
            up: Tshirt1LeftArmUp,
            option1: Tshirt1LeftArmOption1,
            option2: Tshirt1LeftArmOption2,
            option3: Tshirt1LeftArmOption3,
            option4: Tshirt1LeftArmOption4,
            option5: Tshirt1LeftArmOption5,
            option6: Tshirt1LeftArmOption6,
            option7: Tshirt1LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt1BothArmsOption1Right, Tshirt1BothArmsOption1Left],
            option2: [Tshirt1BothArmsOption2Right, Tshirt1BothArmsOption2Left],
            option3: [Tshirt1BothArmsOption3Right, Tshirt1BothArmsOption3Left],
        },
    },
    sitting: {
        front: Sitting1,
        rightArm: {
            down: Tshirt1RightArmDown,
            option1: Tshirt1RightArmOption1,
            option2: Tshirt1RightArmOption2,
            option3: Tshirt1RightArmOption3,
            option4: Tshirt1RightArmOption4,
            option5: Tshirt1RightArmOption5,
            option6: Tshirt1RightArmOption6,
            option7: Tshirt1RightArmOption7,
        },
        leftArm: {
            down: Tshirt1LeftArmDown,
            up: Tshirt1LeftArmUp,
            option1: Tshirt1LeftArmOption1,
            option2: Tshirt1LeftArmOption2,
            option3: Tshirt1LeftArmOption3,
            option4: Tshirt1LeftArmOption4,
            option5: Tshirt1LeftArmOption5,
            option6: Tshirt1LeftArmOption6,
            option7: Tshirt1LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt1BothArmsOption1Right, Tshirt1BothArmsOption1Left],
            option2: [Tshirt1BothArmsOption2Right, Tshirt1BothArmsOption2Left],
            option3: [Tshirt1BothArmsOption3Right, Tshirt1BothArmsOption3Left],
        },
    },
};

export default tshirt1;
