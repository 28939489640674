import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6554)"
            d="M410.346 351.775a22.89 22.89 0 01-15.415-6.103 32.18 32.18 0 01-5.009-6.521 194.195 194.195 0 01-10.603-19.524c-3.019-6.315-5.865-12.861-8.703-20.014a153.357 153.357 0 01-6.769-20.629 681.174 681.174 0 01-1.565-6.336c-1.169-4.783-2.378-9.729-3.757-14.529a27.49 27.49 0 012.32-20.967 27.511 27.511 0 0116.491-13.154 27.497 27.497 0 0129.153 9.363 27.488 27.488 0 014.898 9.575l.04.137c1.356 4.819 2.931 9.666 4.453 14.353a812.762 812.762 0 011.998 6.201 153.021 153.021 0 015.097 21.11 327.068 327.068 0 012.793 18.758 234.384 234.384 0 0119.857-8.022 341.78 341.78 0 003.661-1.291c2.479-.882 5.041-1.794 7.61-2.606l5.648-1.851c1.231-.4 2.47-.773 3.709-1.147a427.39 427.39 0 002.013-.611c4.685-1.411 8.572-2.516 12.242-3.481l1.319-.346-.066 1.363c-.627 12.724 5.806 22.277 12.124 23.83l2.208.542-1.892 1.261c-3.54 2.359-7.41 4.806-12.18 7.701-.615.367-1.223.736-1.831 1.105a176.745 176.745 0 01-3.306 1.974l-5.166 2.966c-2.313 1.362-4.704 2.656-7.018 3.909-1.136.615-2.272 1.229-3.404 1.855a233.795 233.795 0 01-21.45 10.345l-10.953 4.54-10.88 4.663a19.347 19.347 0 01-7.667 1.581z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6554"
                x1="357.453"
                x2="496.093"
                y1="287.398"
                y2="287.398"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
