import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-1">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-1-a)"
            d="M364.949 333.826a23.47 23.47 0 0 1-20.811-12.548 23.287 23.287 0 0 1-2.697-9.965 24.144 24.144 0 0 1-.443-2.34 253.265 253.265 0 0 1-1.823-26.123c-.192-8.249-.115-16.693.232-25.816.266-8.768.798-39.998 4.386-51.426 5.855-18.651 17.532-27.397 33.587-27.397a32.033 32.033 0 0 1 31.785 36.026l-.02.15c-.775 5.885-1.35 11.9-1.904 17.717-.245 2.567-.49 5.133-.752 7.697a202.196 202.196 0 0 1-4.208 25.363c-.157.706-.317 1.413-.482 2.123 1.169-.502 2.339-1 3.511-1.496 1.4-.579 2.794-1.16 4.181-1.744 2.84-1.188 5.776-2.418 8.693-3.578 4.126-1.677 8.531-3.457 12.961-5.135 1.531-.587 3.062-1.178 4.594-1.773 2.803-1.085 5.605-2.17 8.424-3.221 1.461-.521 2.904-1.048 4.347-1.574 2.885-1.053 5.869-2.141 8.851-3.132a46.175 46.175 0 0 0 6.937 34.704c-1.284.838-2.567 1.679-3.848 2.523a723.604 723.604 0 0 1-7.616 4.803c-1.402.875-2.803 1.752-4.202 2.631-3.98 2.528-8.054 4.99-11.885 7.296-2.669 1.633-5.409 3.244-8.06 4.802a1535.23 1535.23 0 0 0-3.905 2.302c-8.835 5.155-16.786 9.58-24.314 13.529l-12.305 6.416-12.271 6.469a23.547 23.547 0 0 1-10.941 2.717h-.002Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-1-a"
                x1={326.905}
                x2={441.411}
                y1={224.241}
                y2={304.048}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.098} stopColor="#919495" />
                <stop offset={0.319} stopColor="#A0A2A3" />
                <stop offset={0.583} stopColor="#A8AAAB" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
