import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shirt-2-cycling-2-a)">
        <path
            d="M500.067 278.879a196.095 196.095 0 0 0-6.026-44.017h-.007c-4.136-15.817-12.24-41.378-27.795-54.417-2.096 5.783-10.482 18.361-10.482 18.361l-21.47-22.193c-8.169-4.265-19.157-12.217-29.109-22.5-11.407 2.957-22.312 8.756-32.489 17.307-54.522 45.811-58.153 129.036-71.64 206.826 0 0 40.56 41.401 95.458 53.193 48.768 10.476 80.925-14.199 87.425-55.258a217.796 217.796 0 0 0 1.786-53.441 22.29 22.29 0 0 1 6.399-18.205c5.688-5.466 8.422-15.014 7.95-25.656Z"
            fill="url(#man-34-25-shirt-2-cycling-2-b)"
        />
        <path
            d="M456.685 203.172c2.607 3.631 11.743 33.978 16.628 50.757a53.624 53.624 0 0 1 1.672 22.004l-2.505 18.977M405.762 154.055c9.671 9.628 19.214 17.699 28.495 22.609l21.344 22.527 10.904-18.054 7.107 7.112"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m434.257 176.664-11.342 11.467-17.196-25.576M471.516 300.082s-.182 69.255-20.183 121.895"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M395.142 157.5a87.418 87.418 0 0 0-22.454 13.918c-54.521 45.811-58.153 129.037-71.64 206.826 0 0 40.56 41.401 95.459 53.194 48.767 10.475 80.924-14.2 87.425-55.259a217.745 217.745 0 0 0 1.785-53.441 22.278 22.278 0 0 1 6.399-18.205c5.689-5.466 8.422-15.014 7.95-25.656a196.037 196.037 0 0 0-6.026-44.017h-.007"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-cycling-2-b"
                x1={301.049}
                y1={293.98}
                x2={500.119}
                y2={293.98}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="man-34-25-shirt-2-cycling-2-a">
                <path
                    fill="#fff"
                    transform="translate(.877 .602)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
