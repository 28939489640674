import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={588}
            height={169}
            viewBox="0 0 588 169"
            fill="none"
            id="object-meals-9"
            {...attributes}
        >
            <path
                fill="#fff"
                d="M585.46 135.479v15.617c0 7.833-6.351 14.184-14.184 14.184h-60.319c-7.833 0-14.184-6.351-14.184-14.184V2.016h88.687v133.463Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M585.46 135.479v15.617c0 7.833-6.351 14.184-14.184 14.184h-60.319c-7.833 0-14.184-6.351-14.184-14.184V2.016h88.687v115.771"
            />
            <path
                fill="#C0F0FF"
                d="M573.047 37.162v113.934c0 .979-.794 1.773-1.773 1.773h-60.319a1.773 1.773 0 0 1-1.773-1.773V37.162h63.865Z"
            />
            <path
                fill="url(#object-meals-9-a)"
                d="M387.992 150.011H108.688c-4.07 0-6.793-4.208-5.125-7.921 23.863-53.14 54.171-63.448 98.355-59.789C301.295 75.52 333.416 133.7 387.992 133.7v16.311Z"
            />
            <mask
                id="object-meals-9-b"
                width={285}
                height={70}
                x={103}
                y={81}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    d="M387.992 150.011H108.688c-4.07 0-6.793-4.208-5.125-7.921 23.863-53.14 54.171-63.448 98.355-59.789C301.295 75.52 333.416 133.7 387.992 133.7v16.311Z"
                />
            </mask>
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity={0.25}
                mask="url(#object-meals-9-b)"
            >
                <path strokeWidth={3} d="M201.918 54.875v122.56" />
                <path
                    strokeWidth={2}
                    d="M160.635 72.568c6.142 9.723 13.098 23.06 17.988 39.81 8.63 29.559 6.575 55.172 4.128 70.171M139.896 85.639c5.973 7.052 12.792 16.787 18.083 29.394 13.108 31.231 8.454 60.099 5.603 72.633M108.887 101.021c6.7 2.499 20.889 8.915 31.106 23.743 20.551 29.825 5.566 65.421 4.423 68.017"
                />
                <path
                    strokeWidth={2}
                    d="M77.875 116.403c2.817-.183 28.126-1.404 44.424 18.093 14.223 17.015 15.636 42.695 2.949 63.401M244.971 71.39c-6.11 9.959-13.439 24.458-17.988 43.055-6.819 27.877-4.109 51.543-1.425 65.271M282.128 86.135c-6.639 3.4-21.353 12.13-30.963 29.784-16.635 30.557-3.8 61.812-1.966 66.077M311.911 94.686c-5.745 2.344-23.894 10.603-35.092 30.963-14.994 27.264-5.527 54.419-3.981 58.625M340.81 105.303c-4.994 1.267-24.359 6.802-37.156 25.95-16.598 24.836-8.3 51.852-7.176 55.302M376.786 118.277c-3.033-.232-27.33-1.677-44.823 17.398-20.472 22.323-12.29 51.665-11.845 53.159"
                />
                <path
                    strokeWidth={2}
                    d="M404.211 112.084a757.751 757.751 0 0 0-79.324 66.939"
                />
            </g>
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M173.117 82.393c8.905-1.039 18.474-.947 28.801-.092 99.377-6.782 131.498 51.399 186.074 51.399v16.312H108.688c-4.07 0-6.793-4.208-5.125-7.921 11.129-24.783 23.66-40.25 38.346-49.329"
            />
            <path
                fill="url(#object-meals-9-c)"
                d="M311.264 149.992H75.5a56.544 56.544 0 0 0 40.475 17.06h297.298a56.544 56.544 0 0 0 40.475-17.06H311.264Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M393.843 167.052h19.431a56.544 56.544 0 0 0 40.475-17.06H75.5a56.544 56.544 0 0 0 40.475 17.06h245.784"
            />
            <path
                fill="url(#object-meals-9-d)"
                d="M279.193 121.538c23.572 13.388 30.985 20.671 53.926 14.35 22.942-6.322 91.505-38.292 87.374-53.285-4.131-14.993-79.393-7.332-102.334-1.011-22.942 6.322-27.354 19.503-38.965 39.946h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M279.193 121.538c23.572 13.388 30.985 20.671 53.926 14.35 22.942-6.322 91.505-38.292 87.374-53.285-4.131-14.993-79.393-7.332-102.334-1.011-22.942 6.322-27.354 19.503-38.965 39.946h-.001Z"
            />
            <path
                fill="url(#object-meals-9-e)"
                d="M293.441 118.092c21.411 21.411 27.231 31.453 53.811 31.453s109.894-14.082 109.894-31.453-83.315-31.453-109.895-31.453-35.241 12.884-53.81 31.453Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M430.575 100.885c-26.772-8.578-66.463-14.244-83.323-14.244-26.579 0-35.242 12.884-53.811 31.453 21.411 21.411 27.231 31.453 53.811 31.453s109.894-14.083 109.894-31.454c0-4.187-4.84-8.182-12.558-11.836"
            />
            <path
                stroke="#00B140"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M331.589 118.092h90.569M384.894 118.092l13.706-13.705M355.462 118.092l16.358-16.358M405.105 118.092l10.926 10.926M371.41 118.092l10.926 10.926"
            />
            <path
                fill="url(#object-meals-9-f)"
                d="M278.41 113.734c76.547 0 113.432 16.481 145.832 35.811h-93.979c-38.968 0-51.853-16.481-51.853-35.811Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M327.05 116.381c45.311 5.408 72.548 18.462 97.192 33.164h-93.979c-38.968 0-51.853-16.481-51.853-35.811 10.923 0 21.039.336 30.449.967"
            />
            <g
                stroke="#E4002B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={4}
                clipPath="url(#object-meals-9-g)"
            >
                <path
                    d="m163.17 92.77-10.755-10.754M143.549 92.77l-10.755-10.754M123.926 92.77l-10.755-10.754M104.303 92.77 93.548 82.017M84.682 92.77 73.927 82.017M65.059 92.77 54.304 82.017M45.435 92.77 34.68 82.017M25.814 92.77 15.06 82.017M143.549 76.775l-10.755-10.756M123.926 76.775l-10.755-10.756M104.303 76.775 93.548 66.019M84.682 76.775 73.927 66.019M65.059 76.775 54.304 66.019M45.435 76.775 34.68 66.019M123.926 60.778l-10.755-10.755M104.303 60.778 93.548 50.023M84.682 60.778 73.927 50.023M65.059 60.778 54.304 50.023M104.303 44.782 93.548 34.027M84.682 44.782 73.927 34.027"
                />
            </g>
            <path
                fill="#fff"
                d="M1.7 90.752c.84 3.78 2.22 9.13 4.52 15.35 1.78 4.83 4.12 10.18 7.19 15.73 1.1 1.98 2.29 3.98 3.58 6 1.73 2.71 3.65 5.43 5.77 8.14 3.61 4.61 7.8 9.17 12.68 13.51 9.67 8.61 19.48 14.07 27.4 17.57h52.55c7.91-3.5 17.729-8.96 27.399-17.57 4.88-4.34 9.07-8.9 12.68-13.51 2.12-2.71 4.031-5.43 5.771-8.14 1.29-2.02 2.48-4.02 3.57-6 3.07-5.55 5.41-10.9 7.19-15.73 2.31-6.22 3.68-11.57 4.52-15.35H1.7Z"
            />
            <path
                fill="url(#object-meals-9-h)"
                d="M1.699 90.752c.84 3.78 2.22 9.13 4.52 15.35 1.78 4.83 4.12 10.18 7.19 15.73 1.1 1.98 2.29 3.98 3.58 6a104.3 104.3 0 0 0 5.77 8.14c3.61 4.61 7.8 9.17 12.68 13.51 9.67 8.61 19.48 14.07 27.4 17.57h21.58c7.91-3.5 17.73-8.96 27.4-17.57 4.88-4.34 9.07-8.9 12.68-13.51 2.12-2.71 4.03-5.43 5.77-8.14 1.29-2.02 2.48-4.02 3.57-6 3.07-5.55 5.41-10.9 7.19-15.73 2.31-6.22 3.68-11.57 4.52-15.35H1.699Z"
            />
            <path
                fill="#EF0E2E"
                d="M6.22 106.102c1.78 4.83 4.12 10.18 7.19 15.73h151.4c3.07-5.55 5.41-10.9 7.19-15.73H6.22Z"
            />
            <path
                fill="#64CCC9"
                d="M16.99 127.832c1.73 2.71 3.65 5.43 5.77 8.14h132.71c2.12-2.71 4.03-5.43 5.77-8.14H16.99Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M35.434 149.485C11.843 128.491 4.267 102.29 1.702 90.755h174.82c-2.565 11.535-10.142 37.736-33.734 58.73-9.672 8.607-19.483 14.069-27.402 17.566H62.837a106.408 106.408 0 0 1-15.546-8.464"
            />
            <defs>
                <linearGradient
                    id="object-meals-9-a"
                    x1={114.033}
                    x2={365.401}
                    y1={115.824}
                    y2={115.824}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FB8735" />
                    <stop offset={0.63} stopColor="#F76430" />
                    <stop offset={1} stopColor="#F64E2E" />
                </linearGradient>
                <linearGradient
                    id="object-meals-9-c"
                    x1={75.5}
                    x2={453.748}
                    y1={158.522}
                    y2={158.522}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-9-d"
                    x1={354.718}
                    x2={369.876}
                    y1={84.34}
                    y2={24.846}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-9-e"
                    x1={357.922}
                    x2={401.501}
                    y1={136.721}
                    y2={93.142}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-9-f"
                    x1={315.008}
                    x2={365.408}
                    y1={149.935}
                    y2={124.545}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-9-h"
                    x1={145.549}
                    x2={1.699}
                    y1={128.902}
                    y2={128.902}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <clipPath id="object-meals-9-g">
                    <path fill="#fff" d="M165.17 32.027H13.059v62.744H165.17z" />
                </clipPath>
            </defs>
        </svg>
    );
}
