import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_11242_6500)"
                d="M650.556 682.89c-5.716-1.79-11.52-5.491-16.765-9.688-16.208 6.074-36.377 4.556-52.697-2.96-.394 2.77-.841 5.8-1.338 9.073a8.999 8.999 0 001.829 6.966 9.025 9.025 0 006.332 3.454l6.967.644a133.071 133.071 0 0127.286 5.591 70.433 70.433 0 0020.93 3.043 81.774 81.774 0 009.451-.555c6.784-.788 11.193-3.391 11.508-6.791.271-2.932-4.272-5.886-13.503-8.777z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M581.584 686.281a9.018 9.018 0 006.332 3.454l6.968.644a133.062 133.062 0 0127.285 5.591 70.434 70.434 0 0020.931 3.043 81.787 81.787 0 009.451-.555c6.783-.788 11.193-3.391 11.507-6.791"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11242_6500"
                    x1="579.65"
                    x2="664.07"
                    y1="684.628"
                    y2="684.628"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#004F71"></stop>
                    <stop offset="0.125" stopColor="#01567A"></stop>
                    <stop offset="1" stopColor="#0B86B8"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
