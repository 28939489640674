import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6411)"
            d="M428.673 342.738a24.798 24.798 0 01-12.493-3.559 31.573 31.573 0 01-6.375-5.172 209.498 209.498 0 01-15.112-17.466c-4.48-5.73-8.852-11.737-13.366-18.363a166.063 166.063 0 01-11.621-19.403c-1.48-2.896-2.719-5.597-3.918-8.21-1.616-3.523-3.137-6.839-5.17-10.527-3.731-6.768-4.394-15.656-1.775-23.776 2.25-6.975 6.654-12.452 12.083-15.027 4.648-2.205 8.297-3.188 11.831-3.188.769.001 1.538.045 2.302.134 10.683 1.226 19.458 6.735 24.077 15.115l1.384 2.543c2.952 5.432 5.501 10.123 8.803 15.761a165.89 165.89 0 0110.096 20.244c3.153 7.369 5.86 14.284 8.275 21.137.735 2.11 1.462 4.28 2.22 6.628l6.413-1.783a230.61 230.61 0 0123.133-5.034c1.162-.186 2.316-.381 3.471-.575l.329-.056c2.588-.437 5.236-.884 7.902-1.235l5.842-.836c1.25-.175 2.503-.323 3.757-.471 0 0 1.529-.181 2.147-.259 3.746-.442 9.434-1.079 15.579-1.507l.238-.017-.025.237a36.065 36.065 0 001.804 13.378c2.917 9.037 7.083 13.863 7.125 13.91l.176.202-.243.112c-6.133 2.81-11.962 5.14-16.356 6.855-.658.252-1.308.505-1.957.759-1.2.468-2.398.935-3.602 1.375l-5.563 2.018c-2.507.94-5.074 1.797-7.557 2.627-1.222.409-2.428.811-3.63 1.224a230.868 230.868 0 01-22.781 6.441l-11.497 2.553-11.443 2.688a19.733 19.733 0 01-4.503.523z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6411"
                x1="350.8"
                x2="472.608"
                y1="256.211"
                y2="309.284"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
