import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891']
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-3" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color][0]}
            d="M62.2 54.7c0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1 4.1.7 6.9 4.7 6.2 8.8-.5 3.1-2.8 5.4-5.7 6.1 2.1 1.7 3.3 4.4 2.7 7.3-.7 4.1-4.7 6.9-8.8 6.2-1.6-.3-2.9-1-4-2.1-1.6 1.1-3.6 1.6-5.7 1.2-.7-.1-1.3-.3-1.9-.6-10.1-4.4-15.3-8.4-20.7-10.7-17.1-7.1-11.7 3.1-23.9 2.3 1.3 5.3 1.2 10.2-3.8 16.8-3.6 4.7-1 11.2-1 15h-5.3c-1.2-4.7-5.9-9.4-10.8-10.3-2.4-.5-5.2 0-6.9 2.2-2.9 3.8-1.3 9.8.3 14.1 1.8 4.8 4.7 8.2 9.1 10.8a91.503 91.503 0 01-.695 8.144c-.522 3.995-5.94 4.178-7.677.543-1.712-3.583-4.053-8.085-8.028-16.887-3.7-8.3-7.1-15.1-6.3-27.5-3.5-.7-4.2-3.1-4.2-6.8 0-1.5.4-2.9 1.2-4.1-2.9-.7-5.2-3.6-5.2-7.1z"
        ></path>
        <path
            stroke={HAIR_COLOR[color][1]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M72.6 73.6c-3.5-.7-6.1-3.8-6.1-7.5 0-1.5.4-2.9 1.2-4.1-3.2-.9-5.5-3.8-5.5-7.3 0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.7c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.401 13.5 19.901"
        ></path>
    </g>
);

export default SvgComponent;
