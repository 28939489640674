import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6507)"
            d="M417.403 347.987a22.638 22.638 0 01-14.1-4.626 33.365 33.365 0 01-5.806-6.527 208.71 208.71 0 01-12.541-19.355c-3.637-6.275-7.135-12.819-10.695-20.012a165.74 165.74 0 01-8.819-20.786c-1.12-3.227-1.696-5.333-2.252-7.369a78.565 78.565 0 00-2.712-8.408 26.496 26.496 0 01.174-21.219 26.495 26.495 0 0115.686-14.291 27.379 27.379 0 019.82-1.824 27.667 27.667 0 0125.69 17.683l.051.136c1.832 4.866 3.89 9.753 5.879 14.477.878 2.085 1.751 4.172 2.618 6.26a58.747 58.747 0 013.507 10.989c.333 1.403.713 3.005 1.251 5.046 2.104 7.725 3.828 14.943 5.274 22.057a306.728 306.728 0 011.467 7.848l6.26-.439c4.185-.27 8.454-.408 12.692-.408 3.082 0 6.224.072 9.34.216 1.196.061 2.387.11 3.577.16 2.435.102 4.953.208 7.442.408l5.487.379c1.196.086 2.392.2 3.589.314.643.062 1.287.124 1.93.18 2.785.266 5.254.526 7.557.795l1.187.139-.346 1.145c-3.301 10.884-.234 23.735 3.419 28.157l1.226 1.452-1.614.343c-2.946.625-6.341 1.07-10.018 1.732-.645.111-1.285.227-1.923.342-1.18.213-2.36.426-3.541.609l-5.448.836c-2.452.406-4.951.72-7.368 1.024a358.81 358.81 0 00-3.55.457 200.099 200.099 0 01-21.972 1.638l-10.96.146-10.937.289c-.174.004-.348.007-.521.007z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6507"
                x1="367.158"
                x2="464.315"
                y1="220.795"
                y2="350.048"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
