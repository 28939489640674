import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-1-walk-1">
        <path
            d="M379.09 789.947s67.71-124.863 89.488-163.671c4.075-3.213 6.82-8.237 9.182-12.75 7.86-14.5 13.782-29.286 18.069-40.559 2.275-5.831 5.164-14.51 7.285-20.466 7.694-21.596 12.337-45.57 15.506-65.105a369.118 369.118 0 0 0 3.571-29.012c.478-5.069 11.14-71.357 11.14-71.357s-114.443 27.06-114.662 55.908c-1.16 10.943-1.915 22.456-2.4 33.37-2.19 40.217 1.158 81.111 2.69 121.31-21.602 21.566-43.126 52.686-51.677 80.943-4.211 15.626-20.557 78.995-23.942 94.173l35.75 17.216Z"
            fill="#888B8D"
        />
        <path
            d="M370.739 387.121c-9.134 15.77-16.008 25.657-17.433 43.962a56.387 56.387 0 0 0 .605 22.547c.087.819.173 1.636.272 2.482a12.658 12.658 0 0 0 2.069 5.627 73.256 73.256 0 0 0 1.367 3.587c3.655 10.757 7.928 21.871 12.178 32.311 13.693 34.393 31.354 67.428 48.381 100.406a47.392 47.392 0 0 1 4.021 32.004 293.878 293.878 0 0 0-4.023 100.307c3.903 29.248 6.483 58.973 12.289 87.583h51.181c-1.952-50.528-3.747-106.215-.112-156.231-.051-6.37 4.059-45.589 4.268-60.851.311-22.686-2.986-60.36-7.64-90.652-.688-3.414-1.378-6.749-2.068-10.006a47.697 47.697 0 0 1 8.141-37.602c9.079-12.734 14.335-30.28 14.335-30.28s24.553-11.723 33.269-45.194h-161.1Z"
            fill="url(#man-34-25-pants-1-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-1-walk-1-a"
                x1={357.156}
                y1={648.554}
                x2={627.806}
                y2={468.12}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
