import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M549.644 738.019c-.448-5.52-.891-11.041-1.329-16.562l-1.881-23.436-2.02-23.409-4.062-46.816a27.207 27.207 0 0 0-.575-3.599 347.499 347.499 0 0 0 4.083-45.91c.026-1.389.057-2.779.091-4.171.058-2.554.118-5.109.139-7.662l.024-11.775c-.003-6.462-.094-14.967-.77-23.452-.583-7.403-1.498-15.155-2.719-23.04-1.39-8.934-2.673-16.159-4.036-22.737-.725-3.484-1.546-7.434-2.502-11.275-.239-.977-.472-1.955-.705-2.933-.648-2.72-1.318-5.533-2.107-8.27l-1.53-5.592-1.695-5.531c-1.289-4.126-2.549-7.734-3.848-11.028l-1.81-4.62c-7.76-19.674-.705-25.3-.705-27.685h-54.722s-25.974 4.289-34.377 15.353a51.678 51.678 0 0 0-10.112 38.503 200.116 200.116 0 0 0 1.896 11.43l1.126 5.672 1.259 5.607c.594 2.793 1.304 5.602 1.99 8.317.246.97.491 1.94.73 2.903.672 2.808 1.463 5.631 2.229 8.361.262.935 2.34 8.392 2.34 8.392l1.699 5.531c.406 1.298.802 2.599 1.198 3.899.715 2.35 1.454 4.779 2.248 7.153 2.249 6.933 4.92 14.094 8.165 21.895 1.639 3.939 3.412 7.915 5.127 11.761 1.43 3.207 2.908 6.523 4.3 9.797l4.626 10.783 4.52 10.813 17.683 42.451a107.922 107.922 0 0 0-1.816 10.714c-.195 1.445-.333 2.895-.467 4.297-.056.584-.112 1.169-.175 1.791-.045.702-.099 1.406-.151 2.11-.097 1.278-.198 2.599-.245 3.887a225.318 225.318 0 0 0 .095 23.806c1.155 17.549 2.94 32.176 7.897 46.507 1.653 5.049 8.455 29.355 9.612 33.967l52.582-10.002a7640.86 7640.86 0 0 0-1.3-16.195Z"
            fill="#221E20"
        />
        <path
            d="M350.089 408.423c-2.648 13.071-2.238 24.704-.148 38.512a193.57 193.57 0 0 0-1.515 11.31l-.575 5.666-.429 5.642c-.246 2.804-.385 5.655-.52 8.412-.048.982-.096 1.965-.147 2.939-.176 2.832-.241 5.712-.304 8.497-.022.963-.206 8.599-.206 8.599l.013 5.699c.011 1.339.01 2.677.01 4.015 0 2.419-.001 4.921.068 7.387.131 7.183.6 14.699 1.432 22.978.42 4.18.957 8.433 1.477 12.546.434 3.436.883 6.99 1.26 10.477l1.285 11.49 1.173 11.489 3.96 39.245a96.31 96.31 0 0 0-6.414 8.043c-.811 1.095-1.573 2.219-2.31 3.306-.315.464-.629.927-.97 1.423-.353.56-.714 1.116-1.074 1.673-.653 1.007-1.329 2.049-1.943 3.088a198.007 198.007 0 0 0-10.5 19.668c-6.775 14.994-11.692 27.869-13.66 41.953-.777 4.919-1.256 10.014-1.719 14.94-.236 2.501-3.478 28.99-3.478 28.99s14.097 6.108 25.374 9.867a137.042 137.042 0 0 0 23.128 5.499c2.57-8.426 22.239-59.498 22.239-59.498l8.608-20.208 17.194-40.423a27.062 27.062 0 0 0 1.303-3.895l1.289-2.492c8.178-15.814 14.068-31.844 18.29-44.056.43-1.298.863-2.597 1.3-3.895a497.574 497.574 0 0 0 2.367-7.178l3.453-11.079c1.879-6.084 4.271-14.118 6.096-22.302 1.6-7.14 2.982-14.703 4.111-22.479 1.273-8.807 2.151-15.98 2.762-22.574l.01-.108a43.4 43.4 0 0 1 18.06-31.407 157.814 157.814 0 0 0 12.84-10.235c20.889-18.462 32.63-35.647 38.559-51.525l-171.749.001Z"
            fill="url(#female-34-25-pants-5-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-5-walk-1-a"
                x1={469.586}
                y1={582.731}
                x2={190.846}
                y2={588.606}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#222731" />
                <stop offset={1} stopColor="#221E20" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
