import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-office-1"
            {...attributes}
        >
            <path fill="#fff" d="M.05 0h2570v1472H.05z" />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2250.88 1148.12.75 17.25h14.84l2.15-49.1M2223.47 1116.28l-5.13 15.54-11.07 33.55h-14.84l9.94-41M2315.72 1124.38l9.94 41h-14.84l-11.07-33.55"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2190.69 1113.46c-8.02-10.09-12.44-23.08-11.53-36.92 2.29-35.04 4.59-70.09 6.89-105.126h146.02l6.89 105.126c1.28 19.57-8.09 37.44-23.23 47.83M2202.38 1124.38a53.52 53.52 0 0 0 15.96 7.45c2.93.82 5.96 1.4 9.09 1.71 9.36.94 19.34 1.53 29.85 1.58 11.85.06 23.03-.54 33.44-1.6 3.1-.31 6.12-.88 9.03-1.69M2173.01 802.404c6.96-22.407 7.65-37.827-.89-49.677-5.8-8.061-17.85-9.925-24.97-5.08-8.14 5.537-10.51 16.058-8.96 24.762 4.91 27.547 21.75 53.869 42.8 73.701 0-43.579 19.81-65.913 37.49-65.913 7.08 0 14.37 4.052 14.37 12.736 0 19.652-40.1 20.997-36.04 79.291-21.62-20.264-44.64-31.313-61.22-31.313-14.5 0-25.22 8.578-25.22 21.936 0 12.695 9.68 17.116 21.25 17.116 13.38 0 51.8-5.253 79.03 21.979-7.6-35.561 14.59-80.211 34.23-80.211 10.17 0 15.35 7.768 15.35 13.958 0 25.326-42.5 31.222-39.87 94.295-30.42-15.537-46.59-20.829-58.72-20.829-4.79 0-20.97 3.145-20.97 17.545 0 8.046 8.99 16.547 19.6 16.547 18.55 0 29.6-4.223 48.13.505 10.85 2.77 17.58 10.937 21.94 15.909-1.48-44.33 21.52-91.457 40.98-88.352 11.08 1.768 3.53 38.335-21 88.352 35.19-59.667 106.72-78.373 151.38-71.361 36.32 5.704 39.98 38-10.13 29.777-33.96-5.574-87.11 1.305-106.06 41.583-1.85-31.698 32.76-97.383 92.89-97.383 29.37 0 40.36-8.337 40.36-20.084 0-7.958-8.78-15.158-29.37-15.158-37.92 0-87.03 34.232-99.66 75.031 10.61-54.947-2.85-103.899-43.33-132.379-20.09-14.135-19.43-39.867.13-45.726 26.18-7.844 74.52 45.347 62.14 123.158 42.82-45.853 77.55-36.409 103.86-44.803 19-6.06 33.43-14.341 32.62-27.766-.76-12.742-15.71-17.681-33.4-15.726-40.52 4.478-77.04 38.437-84.32 50.968-6.63-38.274-11.23-67.662-.24-99.873 5.71-16.747 20.03-22.977 29.76-20.064 9.82 2.941 14.98 15.539 14.06 27.663-1.5 19.606-15.35 42.006-20.5 47.747M2223.48 1116.28h45.14M1916.18 248.586v31.913l-15.12 15.114M1916.18 205.883v14.489M1991.23 280.5h-14.48M1916.18 355.676v-14.488M1841.12 280.5h14.49"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1978.45 306.246c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <g
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#a)"
            >
                <path d="M1642.66 680.656h-578.53v-25.121h578.53" />
                <path d="M1018.34 680.658V303.344h624.32v352.193" />
            </g>
            <path
                fill="#63666A"
                d="M789.988 1193.38h33.5v-28.6h-49l15.5 28.6ZM1013.29 1193.38h33.5v-28.6h-49.001l15.501 28.6Z"
            />
            <path
                fill="url(#b)"
                d="M958.188 895.781h-134.2c-13.8 0-25 11.2-25 25v243.999h209.202c13.8 0 25-11.2 25-25V970.781c0-41.5-33.602-75-75.002-75Z"
            />
            <path
                fill="url(#c)"
                d="M996.787 895.781h-38.599c41.4 0 75.002 33.6 75.002 75v168.999c0 13.8-11.2 25-25 25h38.7c13.8 0 25-11.2 25-25V970.781c-.1-41.5-33.7-75-75.103-75Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1008.19 1164.78h38.7c13.8 0 25-11.2 25-25V970.781c0-41.4-33.6-75-75.003-75h-63.699"
            />
            <path
                fill="url(#d)"
                d="M834.587 776.383H663.188c14.1 0 26.5 10.6 30.6 26.1l39.9 148.3c4.099 15.5 16.599 26.1 30.599 26.1h152l-46.5-174.4c-4.1-15.5-16.499-26.1-30.599-26.1h-4.601Z"
            />
            <path
                fill="#ECF3F9"
                d="m733.789 950.683-40-148.2c-4.1-15.5-16.5-26.1-30.6-26.1h-9.9c-7.1 0-12.4 7.4-11.1 15.5l16.6 108.3 75 50.5Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M658.789 776.383h180.4c14.1 0 26.5 10.6 30.6 26.1l31.7 118.6"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m658.789 900.183-16.6-108.3c-1.3-8.1 4-15.5 11.1-15.5h9.9"
            />
            <path
                fill="url(#e)"
                d="M1008.09 1164.78H774.289V976.781h218.9c22.101 0 40.001 17.9 40.001 39.999v122.9c-.1 13.9-11.3 25.1-25.1 25.1Z"
            />
            <path
                fill="url(#f)"
                d="M726.689 895.781h-56.9v268.999h106.9c13.8 0 25-11.2 25-25V970.781c0-41.5-33.6-75-75-75Z"
            />
            <path
                fill="url(#g)"
                d="M686.988 895.781h-14c-13.8 0-25 11.2-25 25v233.999c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25V970.781c0-41.5-33.6-75-75-75Z"
            />
            <path
                fill="#222731"
                d="M693.789 1193.38h-33.5v-28.6h49l-15.5 28.6ZM919.988 1193.38h-33.5v-28.6h49l-15.5 28.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M686.988 895.781h-14c-13.8 0-25 11.2-25 25v233.999c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25V970.781"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M765.79 1164.78h10.9c13.8 0 25-11.2 25-25V970.781c0-41.4-33.6-75-75-75h-3.1M846.789 976.781h146.4c22.101 0 40.001 17.9 40.001 39.999v122.9c0 13.8-11.2 25-25 25H886.489M894.688 895.781h38.5"
            />
            <path
                fill="url(#h)"
                d="M2030.88 895.781h-40v120.999h40v148h15.06c13.81 0 25-11.19 25-25V935.838c0-22.123-17.93-40.057-40.06-40.057Z"
            />
            <path
                fill="url(#i)"
                d="M1990.88 895.781c22.08 0 40 17.923 40 40v80.999h-40V895.781Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2030.88 895.781h-40v120.999h40v148h15.06c13.81 0 25-11.19 25-25V935.838c0-22.123-17.93-40.057-40.06-40.057v0Z"
            />
            <path
                fill="url(#j)"
                d="M1195.05 895.781h40v120.999h-40v148h-15.06c-13.8 0-25-11.19-25-25V935.838c0-22.123 17.94-40.057 40.06-40.057Z"
            />
            <path
                fill="url(#k)"
                d="M1235.05 1016.78h-40v-80.999c0-22.077 17.92-40 40-40v120.999Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1235.05 1016.78h-40v148h-15.06c-13.8 0-25-11.19-25-25V935.838c0-22.123 17.94-40.057 40.06-40.057"
            />
            <path
                fill="#63666A"
                d="M1975.23 1193.38h33.5v-28.6h-49l15.5 28.6ZM1250.67 1193.38h-33.5v-28.6h49l-15.5 28.6Z"
            />
            <path
                fill="url(#l)"
                d="M1235.02 976.781h755.86c22.08 0 40 17.923 40 39.999v148h-835.86v-148c0-22.076 17.92-39.999 40-39.999Z"
            />
            <path
                fill="url(#m)"
                d="M1260.02 776.281h201.95c13.8 0 25 11.202 25 25v175.5h-251.95v-175.5c0-13.798 11.2-25 25-25Z"
            />
            <path
                fill="url(#n)"
                d="M1511.98 776.281h201.95c13.8 0 25 11.202 25 25v175.5h-251.95v-175.5c0-13.798 11.2-25 25-25Z"
            />
            <path
                fill="url(#o)"
                d="M1763.93 776.281h201.95c13.8 0 25 11.202 25 25v175.5h-251.95v-175.5c0-13.798 11.2-25 25-25Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1472.11 1016.78v122.54M1753.79 1016.78v122.54M1486.97 976.781v-175.5c0-13.807-11.19-25-25-25h-127.12l-30.32.102h-44.51c-13.81 0-25 11.193-25 25v175.5M1738.93 976.781v-175.5c0-13.807-11.19-25-25-25h-201.95c-13.81 0-25 11.193-25 25v175.5M1990.88 976.781v-175.5c0-13.807-11.19-25-25-25h-201.95c-13.81 0-25 11.193-25 25v175.5"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1583.44 976.781h-348.42c-22.09 0-40 17.909-40 39.999v148h835.86v-148c0-22.09-17.91-39.999-40-39.999h-31.14"
            />
            <path
                fill="url(#p)"
                d="m698.201 663.785-170.787 18.58v244.962h177.281V733.462l-6.494-69.677Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M704.695 754.742v172.585h-96.398"
            />
            <path
                fill="#CB865E"
                d="M529.989 718.944c-.571-9.711-8.974-42.267-16.075-68.397a312.7 312.7 0 0 1-10.858-74.514l-.532-22.204c-.375-15.639 5.902-30.06 18.093-39.862 16.278-13.085 31.837-20.475 49.942-28.194 32.194 15.595 42.147 16.424 56.019 13.777a130.34 130.34 0 0 1 6.411 4.394c26 19 45.966 59.673 52.16 82.235 3.203 11.668 2.84 23.765-8.16 29.765 14 28 25 49 14 103"
            />
            <path
                fill="#CB865E"
                d="M529.989 718.944c-.571-9.711-8.974-42.267-16.075-68.397a312.7 312.7 0 0 1-10.858-74.514l-.532-22.204c-.375-15.639 5.902-30.06 18.093-39.862 16.278-13.085 31.837-20.475 49.942-28.194 32.194 15.595 42.147 16.424 56.019 13.777a130.34 130.34 0 0 1 6.411 4.394c26 19 45.966 59.673 52.16 82.235 3.203 11.668 2.84 23.765-8.16 29.765 14 28 25 49 14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M666.039 542.949c9.252 15.606 15.961 31.758 19.11 43.23 3.203 11.668 2.841 23.765-8.159 29.765 14 28 25 49 14 103M529.991 718.941c-.572-9.712-8.975-42.267-16.076-68.397a312.751 312.751 0 0 1-10.858-74.514l-.473-19.37c-.779-24.024 9.663-36.864 21.703-45.56a190.79 190.79 0 0 1 41.284-23.264"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M677.152 616.139c-3.568-6.179-7.717-12.857-11.113-16.862M567.914 617.366c31.069 3.775 56.531-1.156 71.668-22.12M668.039 694.922a13.776 13.776 0 0 1 10.286 0"
            />
            <path
                fill="#CB865E"
                d="M681.999 1149.14a716.08 716.08 0 0 1-8.642-22.9c-1.369-3.83-2.824-7.64-4.123-11.48l-3.959-11.51c-2.668-7.66-5.198-15.35-8.107-22.98-2.837-7.64-6.032-15.23-8.512-22.92-5.228-15.35-6.87-31.21-7.925-47.16a234.74 234.74 0 0 1-.131-24.139c.071-2.023.262-4.064.387-6.095.202-2.042.356-4.077.626-6.129a107.971 107.971 0 0 1 2.128-12.385l2.761-11.655a26.24 26.24 0 0 1 43.961-12.628 26.235 26.235 0 0 1 7.711 16.412l4.136 47.709 2.056 23.86 1.917 23.87c1.256 15.93 2.608 31.83 3.784 47.77l3.603 47.78a15.95 15.95 0 0 1-3.053 10.64 15.965 15.965 0 0 1-9.241 6.08c-3.709.85-7.601.35-10.973-1.41a15.927 15.927 0 0 1-7.411-8.21l-.993-2.52Z"
            />
            <path
                fill="#CB865E"
                d="M642.381 962.441c-5.485-15.027-10.533-30.13-15.755-45.203l-7.765-22.622-3.889-11.309-1.949-5.654-1.852-5.671c-5.077-15.098-9.144-30.371-13.125-45.659a775.724 775.724 0 0 1-5.586-22.999l-1.299-5.767-1.184-5.786c-.791-3.858-1.606-7.711-2.263-11.592a50.887 50.887 0 0 1 39.012-58.143 50.892 50.892 0 0 1 60.144 35.85l1.348 4.888a233.226 233.226 0 0 1 2.864 11.487l1.266 5.772 1.138 5.795c.798 3.856 1.416 7.743 2.098 11.62.71 3.871 1.238 7.774 1.829 11.666 2.232 15.591 4.164 31.234 5.104 47.048.478 7.906.602 15.874.561 23.869l-.083 11.998c-.052 4.003-.191 8.022-.282 12.032a398.153 398.153 0 0 1-4.167 48.654l-.687 4.346a28.784 28.784 0 0 1-8.699 16.466 28.782 28.782 0 0 1-35.078 3.402 28.785 28.785 0 0 1-11.701-14.488Z"
            />
            <path
                fill="#CB865E"
                d="m682.992 1151.66-.993-2.52a716.08 716.08 0 0 1-8.642-22.9c-1.369-3.83-2.824-7.65-4.123-11.48l-3.959-11.51c-2.668-7.66-5.198-15.35-8.107-22.98-2.837-7.64-6.032-15.23-8.512-22.92-5.228-15.35-6.87-31.22-7.925-47.17a234.55 234.55 0 0 1-.131-24.13c.071-2.024.262-4.064.387-6.095.202-2.042.356-4.077.626-6.129a69.153 69.153 0 0 1 1.586-9.951"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m682.992 1151.66-.993-2.52a716.08 716.08 0 0 1-8.642-22.9c-1.369-3.83-2.824-7.65-4.123-11.48l-3.959-11.51c-2.668-7.66-5.198-15.35-8.107-22.98-2.837-7.64-6.032-15.23-8.512-22.92-5.228-15.35-6.87-31.22-7.925-47.17a234.55 234.55 0 0 1-.131-24.13c.071-2.024.262-4.064.387-6.095.202-2.042.356-4.077.626-6.129a69.153 69.153 0 0 1 1.586-9.951"
            />
            <path
                fill="#CB865E"
                d="m698.176 953.57 4.135 47.71 2.057 23.86 1.917 23.87c1.255 15.92 2.608 31.83 3.783 47.76l3.603 47.79"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m698.176 953.57 4.135 47.71 2.057 23.86 1.917 23.87c1.255 15.92 2.608 31.83 3.783 47.76l3.603 47.79"
            />
            <path
                fill="#CB865E"
                d="M681.98 746.258a50.554 50.554 0 0 1 4.89 11.63l1.348 4.888a231.546 231.546 0 0 1 2.864 11.487l1.267 5.772 1.138 5.794c.798 3.857 1.415 7.744 2.097 11.62.71 3.872 1.239 7.775 1.83 11.667 2.232 15.591 4.163 31.233 5.103 47.048.478 7.906.603 15.873.562 23.869l-.084 11.998c-.052 4.003-.191 8.021-.282 12.031a397.954 397.954 0 0 1-4.167 48.655"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M681.98 746.258a50.554 50.554 0 0 1 4.89 11.63l1.348 4.888a231.546 231.546 0 0 1 2.864 11.487l1.267 5.772 1.138 5.794c.798 3.857 1.415 7.744 2.097 11.62.71 3.872 1.239 7.775 1.83 11.667 2.232 15.591 4.163 31.233 5.103 47.048.478 7.906.603 15.873.562 23.869l-.084 11.998c-.052 4.003-.191 8.021-.282 12.031a397.954 397.954 0 0 1-4.167 48.655"
            />
            <path
                fill="#CB865E"
                d="M641.251 959.324c-5.485-15.028-9.403-27.013-14.624-42.086l-7.765-22.622-3.89-11.309-1.948-5.654-1.852-5.671c-5.077-15.098-9.144-30.371-13.125-45.659a774.965 774.965 0 0 1-5.586-22.999l-1.299-5.767-1.185-5.786c-.791-3.857-1.605-7.711-2.262-11.591"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M641.251 959.324c-5.485-15.028-9.403-27.013-14.624-42.086l-7.765-22.622-3.89-11.309-1.948-5.654-1.852-5.671c-5.077-15.098-9.144-30.371-13.125-45.659a774.965 774.965 0 0 1-5.586-22.999l-1.299-5.767-1.185-5.786c-.791-3.857-1.605-7.711-2.262-11.591M677.836 957.926h15.484"
            />
            <path
                fill="#CB865E"
                d="M683.266 1151.96c1.399 4.1 4.033 20.48 5.661 31.12a9.055 9.055 0 0 0 3.782 6.05 9.095 9.095 0 0 0 3.352 1.41 9.1 9.1 0 0 0 3.639-.02l19.883-4.24a42.085 42.085 0 0 1 12.908-.78c6.93.6 13.911.17 20.712-1.29 4.018-.83 18.136-6.07 24.891-8.62.99-.38 1.8-1.11 2.265-2.07.464-.95.548-2.04.234-3.05a4.232 4.232 0 0 0-1.276-1.94 4.075 4.075 0 0 0-2.117-.94c-3.326-.46-9.204-1.25-13.904-1.69-21.095-2.01-48.23-20.73-48.23-20.73"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M683.266 1151.96c1.399 4.1 4.033 20.48 5.661 31.12a9.055 9.055 0 0 0 3.782 6.05 9.095 9.095 0 0 0 3.352 1.41 9.1 9.1 0 0 0 3.639-.02l19.883-4.24a42.085 42.085 0 0 1 12.908-.78c6.93.6 13.911.17 20.712-1.29 4.018-.83 18.136-6.07 24.891-8.62.99-.38 1.8-1.11 2.265-2.07.464-.95.548-2.04.234-3.05a4.232 4.232 0 0 0-1.276-1.94 4.075 4.075 0 0 0-2.117-.94c-3.326-.46-9.204-1.25-13.904-1.69-21.095-2.01-48.23-20.73-48.23-20.73"
            />
            <path
                fill="#CB865E"
                d="M529.891 717.719c-12.574 21.71-20.878 32.254-16.556 68.991 3.318 28.202 88.978 13.576 105.251 18.258 23.684 9.539 39.136-42.055 39.136-42.055s24.553-11.724 33.269-45.194"
            />
            <path
                fill="#CB865E"
                d="M529.891 717.719c-12.574 21.71-20.878 32.254-16.556 68.991 3.318 28.202 88.978 13.576 105.251 18.258 23.684 9.539 39.136-42.055 39.136-42.055s24.553-11.724 33.269-45.194"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M529.891 717.719c-12.574 21.71-20.878 32.254-16.556 68.991 3.318 28.202 88.978 13.576 105.251 18.258 23.684 9.539 39.136-42.055 39.136-42.055s24.553-11.724 33.269-45.194"
            />
            <path
                fill="#CB865E"
                d="M458.854 1121.04a719.702 719.702 0 0 1 4.669-24.1c.844-3.99 1.609-8.01 2.518-11.97l2.67-11.91c1.754-7.95 3.635-15.84 5.166-23.89 1.598-8.01 2.862-16.17 4.79-24.05 3.608-15.85 10.542-30.265 18.02-44.447a235.927 235.927 0 0 1 12.563-20.69c1.123-1.691 2.357-3.335 3.53-5.004 1.245-1.639 2.445-3.297 3.752-4.909a108.63 108.63 0 0 1 8.316-9.466l8.427-8.465a26.25 26.25 0 0 1 33.936-2.782 26.246 26.246 0 0 1 8.124 33.066l-21.534 42.937-10.778 21.46-10.906 21.4c-7.293 14.27-14.495 28.57-21.861 42.8l-22.028 42.72a15.945 15.945 0 0 1-8.174 7.46 15.947 15.947 0 0 1-11.06.33 15.915 15.915 0 0 1-8.61-6.95 15.937 15.937 0 0 1-2.002-10.89l.472-2.65Z"
            />
            <path
                fill="#CB865E"
                d="M521.218 949.092c-1.66-15.212-2.878-30.389-4.271-45.58l-2.021-22.78-1.019-11.391-.513-5.696-.414-5.688c-1.247-15.179-1.473-30.278-1.61-45.371-.06-7.545.001-15.082.183-22.608l.144-5.645.26-5.635c.172-3.757.321-7.516.628-11.262a50.877 50.877 0 0 1 101.562 2.646l.128 5.303c.094 3.778.087 7.548-.02 11.31l-.177 5.642-.308 5.631c-.165 3.758-.512 7.501-.795 11.25-.254 3.75-.691 7.486-1.066 11.228-1.632 14.954-3.567 29.884-6.506 44.736-1.461 7.427-3.281 14.826-5.267 22.211l-3.002 11.077c-1.025 3.69-2.138 7.374-3.203 11.061a366.339 366.339 0 0 1-15.886 44.003l-1.874 4.193a28.823 28.823 0 0 1-30.78 16.706 28.82 28.82 0 0 1-24.173-25.341ZM458.383 1123.69l.472-2.65a719.702 719.702 0 0 1 4.669-24.1c.844-3.99 1.609-8.01 2.518-11.97l2.67-11.91c1.754-7.95 3.635-15.84 5.166-23.89 1.598-8.01 2.862-16.17 4.79-24.05 3.608-15.85 10.542-30.266 18.02-44.448a235.927 235.927 0 0 1 12.563-20.69c1.123-1.691 2.357-3.335 3.53-5.004 1.245-1.639 2.445-3.297 3.752-4.909a134.193 134.193 0 0 1 3.358-4.089"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m458.383 1123.69.472-2.65a719.702 719.702 0 0 1 4.669-24.1c.844-3.99 1.609-8.01 2.518-11.97l2.67-11.91c1.754-7.95 3.635-15.84 5.166-23.89 1.598-8.01 2.862-16.17 4.79-24.05 3.608-15.85 10.542-30.266 18.02-44.448a235.927 235.927 0 0 1 12.563-20.69c1.123-1.691 2.357-3.335 3.53-5.004 1.245-1.639 2.445-3.297 3.752-4.909a134.193 134.193 0 0 1 3.358-4.089"
            />
            <path
                fill="#CB865E"
                d="M579.811 949.492c-7.039 18.038-26.011 55.868-26.011 55.868l-10.778 21.46-10.906 21.4c-7.293 14.27-14.495 28.57-21.861 42.8l-22.028 42.72"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M579.811 949.492c-7.039 18.038-26.011 55.868-26.011 55.868l-10.778 21.46-10.906 21.4c-7.293 14.27-14.495 28.57-21.861 42.8l-22.028 42.72"
            />
            <path
                fill="#CB865E"
                d="m614.149 770.082.127 5.303c.094 3.778.088 7.548-.019 11.31l-.177 5.642-.308 5.632c-.165 3.757-.513 7.5-.796 11.249-.253 3.75-.691 7.487-1.065 11.228-1.632 14.954-3.568 29.884-6.507 44.736-1.461 7.427-3.28 14.826-5.266 22.211l-3.002 11.077c-1.025 3.69-2.139 7.374-3.204 11.061a383.346 383.346 0 0 1-14.12 39.96"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m614.149 770.082.127 5.303c.094 3.778.088 7.548-.019 11.31l-.177 5.642-.308 5.632c-.165 3.757-.513 7.5-.796 11.249-.253 3.75-.691 7.487-1.065 11.228-1.632 14.954-3.568 29.884-6.507 44.736-1.461 7.427-3.28 14.826-5.266 22.211l-3.002 11.077c-1.025 3.69-2.139 7.374-3.204 11.061a383.346 383.346 0 0 1-14.12 39.96"
            />
            <path
                fill="#CB865E"
                d="M520.211 939.37c-1.18-11.959-2.168-23.903-3.264-35.856l-2.021-22.78-1.019-11.391-.513-5.696-.414-5.688c-1.247-15.179-1.473-30.279-1.61-45.371-.06-7.546.001-15.082.183-22.609l.144-5.644.26-5.635c.172-3.757.321-7.516.628-11.262"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M520.211 939.37c-1.18-11.959-2.168-23.903-3.264-35.856l-2.021-22.78-1.019-11.391-.513-5.696-.414-5.688c-1.247-15.179-1.473-30.279-1.61-45.371-.06-7.546.001-15.082.183-22.609l.144-5.644.26-5.635c.172-3.757.321-7.516.628-11.262M552.023 957.754h15.484"
            />
            <path
                fill="#CB865E"
                d="m458.943 1124.29-9.605 15.33a9.018 9.018 0 0 0-1.373 4.8 8.972 8.972 0 0 0 1.387 4.78l19.529 30.97a22.663 22.663 0 0 0 8.203 7.74c3.344 1.84 7.1 2.81 10.919 2.81h26.423c1.283 0 2.531-.42 3.551-1.2a5.862 5.862 0 0 0 1.648-7.38 5.847 5.847 0 0 0-3.254-2.82l-3.76-1.32a16.823 16.823 0 0 1-6.206-3.87 16.826 16.826 0 0 1-3.978-6.13l-13.622-36.63"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m458.943 1124.29-9.605 15.33a9.018 9.018 0 0 0-1.373 4.8 8.972 8.972 0 0 0 1.387 4.78l19.529 30.97a22.663 22.663 0 0 0 8.203 7.74c3.344 1.84 7.1 2.81 10.919 2.81h26.423c1.283 0 2.531-.42 3.551-1.2a5.862 5.862 0 0 0 1.648-7.38 5.847 5.847 0 0 0-3.254-2.82l-3.76-1.32a16.823 16.823 0 0 1-6.206-3.87 16.826 16.826 0 0 1-3.978-6.13l-13.622-36.63"
            />
            <path
                fill="#2B2729"
                d="M488.001 1191.72c-3.99.01-7.917-.99-11.41-2.92a23.506 23.506 0 0 1-8.557-8.09l-19.529-30.97a10.078 10.078 0 0 1-1.532-5.32c-.003-1.88.522-3.73 1.516-5.32l9.532-15.22 3.913-13.35 1.341-.64 29.494 12.14.539 1.32-3.429 8.05 13.484 36.25a15.655 15.655 0 0 0 3.737 5.77 15.767 15.767 0 0 0 5.84 3.64l3.76 1.32a6.883 6.883 0 0 1 4.341 8.31 6.91 6.91 0 0 1-2.463 3.63 6.847 6.847 0 0 1-4.153 1.4h-26.424ZM697.811 1191.72a10.053 10.053 0 0 1-6.494-2.43 10.05 10.05 0 0 1-3.378-6.05c-2.586-16.92-4.582-27.91-5.619-30.96l-4.73-11.71.718-1.35 33.234-7.1 1.173.71 3.218 11.72c3.013 2.03 28.041 18.51 47.459 20.36 4.774.45 10.787 1.25 13.947 1.69a5.14 5.14 0 0 1 2.623 1.18 5.13 5.13 0 0 1 1.587 2.4 5.17 5.17 0 0 1-.294 3.79 5.21 5.21 0 0 1-2.808 2.56c-7.635 2.88-21.041 7.84-25.042 8.66a74.691 74.691 0 0 1-15.057 1.59c-2.047 0-4.05-.1-5.953-.28a36.83 36.83 0 0 0-3.799-.19c-2.96.01-5.911.32-8.804.95l-19.882 4.24c-.69.15-1.394.22-2.099.22Z"
            />
            <path
                fill="url(#q)"
                d="M779.179 1175.66c-.09.05-.183.09-.277.12-7.692 3.05-21.201 8.28-25.245 9.17-9.241 2.03-54.229 7.04-54.229 7.04a10.078 10.078 0 0 1-7.939-1.52 10.452 10.452 0 0 1-2.92-2.98 10.36 10.36 0 0 1-1.513-3.89c-.805-4.59-1.617-9.09-2.35-13-.049-.25-.001-.51.135-.73.136-.22.35-.37.598-.44 3.993-.9 8.062-1.43 12.153-1.57.708-.05 1.414-.09 2.119-.14 6.37-.46 12.918-1.44 17.633-5.43a32.886 32.886 0 0 0 4.762-5.4c.73-.96 1.485-1.95 2.28-2.89.737-.87 1.533-1.69 2.384-2.45.155-.14.347-.22.552-.25.205-.02.412.02.593.12 9.614 5.16 23.558 11.54 35.395 12.55 4.87.42 11 1.19 14.22 1.61a5.33 5.33 0 0 1 2.705 1.19 5.329 5.329 0 0 1 1.663 2.45 5.266 5.266 0 0 1-.134 3.74 5.264 5.264 0 0 1-2.585 2.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M781.816 1172.83a5.18 5.18 0 0 1-2.637 2.84c-.091.04-.184.08-.278.11-7.692 3.05-21.2 8.29-25.245 9.18-9.24 2.03-54.229 7.03-54.229 7.03a10.078 10.078 0 0 1-7.939-1.52"
            />
            <path
                fill="#004F71"
                d="M488.001 1191.72c-3.99.01-7.916-.99-11.41-2.92a23.514 23.514 0 0 1-8.557-8.09l-19.529-30.97a10.078 10.078 0 0 1-1.532-5.32c-.003-1.88.522-3.73 1.516-5.33l9.605-15.33c2.67 7.06 5.758 13.96 9.246 20.66 2.548 4.72 9.434 6.1 15.832 4.77 9.921-2.06 12.393-2.61 12.393-2.61l7.798 21.07a15.655 15.655 0 0 0 3.737 5.77 15.767 15.767 0 0 0 5.84 3.64l3.761 1.32a6.883 6.883 0 0 1 4.34 8.31 6.857 6.857 0 0 1-2.463 3.62 6.856 6.856 0 0 1-4.153 1.41h-26.424Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M514.424 1191.72H488c-3.99.01-7.917-.99-11.41-2.92a23.506 23.506 0 0 1-8.557-8.09l-19.529-30.97"
            />
            <path
                fill="#CB865E"
                d="M642.65 400.32c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3-.5 4.4-.5 5.1l-7.3 13.4-47.5-16s4.1-17.1 4.1-23.7c0-9.5-7-19.2-9.5-26.8-8.1-25 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.4-9.7 41-2.7 56.7 13Z"
            />
            <path
                fill="url(#r)"
                d="M625.251 490.819c-14.5 1.2-30.2-11.6-36.6-18.4-1.2-1.3 9.8 29.4 10.3 31.2.6 1.7 23.6 6.5 24.6 8 4.1 5.8 2.1-12.1 1.7-20.8Z"
            />
            <path
                fill="url(#s)"
                d="M649.451 424.519c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.8.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.1 1.1-55.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M588.649 471.619c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 1.7 3.7 4.1 16.1-1.4 32.4"
            />
            <path
                fill="#2B2B2B"
                d="M574.372 468.809c1.79 2.681 5.846 2.917 6.813-.157.825-2.625 1.219-5.348.762-7.633-5.4-3.8-9.3-9.6-10.7-16-.6-2.8-.8-6 .9-8.4 1.7-2.4 5-3.3 7.9-3 2.9.3 4.1 2.8 6.7 4.2 1.7-.4 4-2.6 4.7-4.2 2.5-6.2 3.2-13.2 1.8-19.7 3.3 1.1 7.6-.7 9.1-3.8.9-1.7 1-3.8 1.8-5.5 1.1-2.3 3.6-4 6.2-4.2 2.7-.2 5.2 1.1 7.5 2.6 2.2 1.5 4.3 3.3 6.8 4.4 2.5 1 5.6 1.2 7.7-.4.8.7.4.8 1.2 1.6 3.5 3.6 9.3 4.7 13.8 2.6.8 2.2 1.5 4.4 2 6.7 3.8 1 7.8-.9 9.9-4.1 2.1-3.2 2.6-7.3 2.4-11.2-.4-7.4-3.5-15.1-9.8-19.1-1.2-.8-2.7-1.5-4.1-1.2-1.5.2-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.9-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22 6.825 3.723 13.892 9.251 18.725 16.49Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M661.547 402.518c-.4-7.4-3.5-15.1-9.8-19.1-1.2-.8-2.7-1.5-4.1-1.2-1.5.2-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.9-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M587.547 438.619c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                stroke="url(#t)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M650.75 426.319c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#u)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M628.449 424.219c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M645.35 441.518c-.773 0-1.4-1.03-1.4-2.3 0-1.27.627-2.3 1.4-2.3.773 0 1.4 1.03 1.4 2.3 0 1.27-.627 2.3-1.4 2.3ZM619.748 441.518c-.773 0-1.4-1.03-1.4-2.3 0-1.27.627-2.3 1.4-2.3.773 0 1.4 1.03 1.4 2.3 0 1.27-.627 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M640.75 465.619c-7.6 1.2-15.4.5-22.7-1.9"
            />
            <path
                fill="#CB865E"
                d="M634.05 457.619c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M634.05 457.619c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                fill="url(#v)"
                d="m625 497.625 2.654.951c13.052 4.678 23.788 10.343 32.825 17.319a2.677 2.677 0 0 1 1.092 2.341c-.25 4.649-12.214 12.209-14.61 13.677l-.857.525L625 497.625Z"
            />
            <path
                fill="url(#w)"
                d="M773.933 568.077c-1.163-7.422-2.078-15.595-3.448-22.947-1.205-6.389-3.267-14.634-4.759-20.227a40.105 40.105 0 0 1-22.35 1.6c-12.209-2.701-16.485-10.073-16.485-10.073-.661 6.71-.972 12.581-1.161 19.614-18.645-7.495-38.711-14.403-58.321-18.398-6.326-1.438-12.866-2.924-19.239-4.59a32.102 32.102 0 0 0-8.113-1.048 31.98 31.98 0 0 0-30.974 24.05 32.038 32.038 0 0 0 23.038 38.953c9.214 2.367 18.207 5.127 27.286 7.711 17.535 5.11 38.248 8.218 56.102 10.404 7.822.896 18.172 1.949 28.854 2.154a24.137 24.137 0 0 0 4.41-.339c14.799 1.417 27.55-12.191 25.16-26.864Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M659.406 582.721c17.536 5.11 38.248 8.217 56.102 10.404 7.823.895 18.172 1.949 28.854 2.153a24.035 24.035 0 0 0 4.41-.339c14.8 1.418 27.55-12.191 25.161-26.864-1.164-7.422-2.079-15.595-3.448-22.946a156.917 156.917 0 0 0-5.82-20.616s-6.109 4.265-20.205 2.314c-13.833-1.916-16.807-10.085-16.807-10.085a154.024 154.024 0 0 0-1.924 19.301c-18.644-7.496-38.711-14.403-58.321-18.399M627.652 498.578c13.052 4.678 23.789 10.343 32.826 17.319"
            />
            <path
                fill="#CB865E"
                d="M647.918 514.024c9.152 2.392 18.412 4.361 27.626 6.513a246.62 246.62 0 0 1 27.239 8.007c8.963 3.12 17.87 6.455 26.7 10.088 8.823 3.658 17.592 7.528 26.182 12.086a23.135 23.135 0 0 1 11.549 26.235 23.132 23.132 0 0 1-22.835 17.327c-9.722-.186-19.267-1.061-28.757-2.147-9.483-1.111-18.889-2.52-28.239-4.145a246.368 246.368 0 0 1-27.7-6.226c-9.1-2.593-18.152-5.368-27.313-7.72a31.003 31.003 0 0 1-18.975-14.245 31.004 31.004 0 0 1 10.902-42.464 31.005 31.005 0 0 1 23.49-3.344l.131.035Z"
            />
            <path
                fill="#CB865E"
                d="m728.206 573.073-.538-7.705c-.195-2.567-.431-5.129-.602-7.698-.131-2.574-.369-5.135-.407-7.719a106.36 106.36 0 0 1-.064-3.875c-.017-1.292-.011-2.586.019-3.883.063-5.183.192-10.372.499-15.58.272-5.205.729-10.43 1.398-15.678a15.934 15.934 0 0 1 14.092-13.83 15.932 15.932 0 0 1 16.728 10.489 206.278 206.278 0 0 1 4.725 15.014c1.416 5.022 2.654 10.063 3.826 15.111.305 1.261.589 2.525.851 3.79.274 1.264.541 2.529.768 3.799.517 2.531.833 5.085 1.256 7.627.384 2.546.703 5.099 1.062 7.648l1.125 7.641a22.516 22.516 0 1 1-44.552 6.562 24.146 24.146 0 0 1-.186-1.713Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M753.949 593.779c-14.114.803-28.837-.56-38.327-1.646-9.483-1.111-18.889-2.52-28.239-4.145a246.368 246.368 0 0 1-27.7-6.226c-9.1-2.593-18.152-5.368-27.313-7.72a30.998 30.998 0 0 1-22.318-37.735 31 31 0 0 1 37.735-22.318l.132.035c9.151 2.392 18.412 4.361 27.625 6.513a246.62 246.62 0 0 1 27.239 8.007 556.11 556.11 0 0 1 20.84 7.724"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M727.668 565.367c-.195-2.567-.431-5.129-.602-7.698-.131-2.574-.369-5.136-.407-7.719-.05-1.289-.061-2.582-.064-3.875-.017-1.292-.011-2.587.019-3.884.063-5.182.192-10.371.499-15.58a210.88 210.88 0 0 1 1.398-15.677M759.331 507.594a206.124 206.124 0 0 1 4.725 15.014c1.416 5.022 2.654 10.063 3.826 15.111.305 1.261.589 2.524.851 3.79.274 1.264.541 2.529.768 3.798.517 2.532.833 5.086 1.256 7.627.384 2.547.703 5.1 1.062 7.648l1.126 7.642a22.52 22.52 0 0 1-18.996 25.557"
            />
            <path
                fill="#CB865E"
                d="M767.496 483.301a46.204 46.204 0 0 0-13.154-11.037 22.022 22.022 0 0 0-6.642-2.408l-.095-.018-.536-14.66c-.116-3.177-.116-6.294-.178-9.445a3.18 3.18 0 0 0-2.694-3.082l-.052-.008a3.193 3.193 0 0 0-3.547 2.333c-1.439 5.186-1.591 10.704-1.813 16.115-.261 6.372-.693 12.925-3.436 18.683.074-.163-1.418 3.489-1.344 3.326l-2.026 4.2a8.636 8.636 0 0 1-.318-3.759c.198-1.774.727-3.498.942-5.27a9.644 9.644 0 0 0-.073-3.061 2.938 2.938 0 0 0-4.313-1.867c-.53.303-.952.763-1.208 1.317-.822 1.841-2.045 3.789-2.908 5.321-2.47 4.387-2.57 9.815-1.236 14.67 1.334 4.855 3.981 9.239 6.803 13.409 8.557 13.779 23.665 16.766 29.749 5.205a119.454 119.454 0 0 0 9.518-21.916 8.504 8.504 0 0 0-1.439-8.048Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M729.832 508.081c-2.822-4.17-5.469-8.553-6.803-13.408-1.334-4.855-1.234-10.283 1.236-14.67.863-1.533 2.086-3.48 2.908-5.321a2.906 2.906 0 0 1 2.914-1.693 2.886 2.886 0 0 1 2.607 2.243 9.644 9.644 0 0 1 .073 3.061c-.215 1.772-.744 3.496-.942 5.27a7.32 7.32 0 0 0 1.028 5.13M735.737 479.308l-.221.489c2.743-5.757 3.174-12.311 3.435-18.683.222-5.41.374-10.928 1.813-16.114a3.19 3.19 0 0 1 3.547-2.333l.052.008a3.173 3.173 0 0 1 2.694 3.081c.062 3.151.062 6.268.178 9.445l.536 14.66M746.094 473.234c-2.899 2.62-5.889 5.367-7.374 8.982a17.88 17.88 0 0 0-.253 11.444 6.508 6.508 0 0 0 1.844 3.411c1.011.838 3.155 1.85 4.905-1.105"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M752.872 478.719a16.409 16.409 0 0 0-5.759 8.156 48.853 48.853 0 0 0-2.004 9.93c-.163 1.241-.291 2.626.46 3.628a3.19 3.19 0 0 0 3.752.754c1.264-.544 3.054-2.908 3.779-4.077"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M750.832 470.441a46.093 46.093 0 0 1 16.829 12.88 8.504 8.504 0 0 1 1.439 8.048 119.454 119.454 0 0 1-9.518 21.916"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M760.608 484.945a31.691 31.691 0 0 0-8.442 16.223 2.604 2.604 0 0 0 1.479 2.724l.058.027a2.645 2.645 0 0 0 3.33-.95 30.24 30.24 0 0 1 6.789-7.023"
            />
            <path
                fill="#CB865E"
                d="M659.868 516.689c-9.226-7.122-19.957-12.656-32.552-17.169l19.122 31.543s17.865-10.95 13.43-14.374Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M627.316 499.52a129.67 129.67 0 0 1 32.552 17.169"
            />
            <path
                fill="url(#x)"
                d="m625 497.625 2.654.951c13.052 4.678 23.788 10.343 32.825 17.319a2.677 2.677 0 0 1 1.092 2.341c-.25 4.649-12.214 12.209-14.61 13.677l-.857.525L625 497.625Z"
            />
            <path
                fill="url(#y)"
                d="M773.933 568.077c-1.163-7.422-2.078-15.595-3.448-22.947-1.205-6.389-3.267-14.634-4.759-20.227a40.105 40.105 0 0 1-22.35 1.6c-12.209-2.701-16.485-10.073-16.485-10.073-.661 6.71-.972 12.581-1.161 19.614-18.645-7.495-38.711-14.403-58.321-18.398-6.326-1.438-12.866-2.924-19.239-4.59a32.102 32.102 0 0 0-8.113-1.048 31.98 31.98 0 0 0-30.974 24.05 32.038 32.038 0 0 0 23.038 38.953c9.214 2.367 18.207 5.127 27.286 7.711 17.535 5.11 38.248 8.218 56.102 10.404 7.822.896 18.172 1.949 28.854 2.154a24.137 24.137 0 0 0 4.41-.339c14.799 1.417 27.55-12.191 25.16-26.864Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M659.406 582.721c17.536 5.11 38.248 8.217 56.102 10.404 7.823.895 18.172 1.949 28.854 2.153a24.035 24.035 0 0 0 4.41-.339c14.8 1.418 27.55-12.191 25.161-26.864-1.164-7.422-2.079-15.595-3.448-22.946a156.917 156.917 0 0 0-5.82-20.616s-6.109 4.265-20.205 2.314c-13.833-1.916-16.807-10.085-16.807-10.085a154.024 154.024 0 0 0-1.924 19.301c-18.644-7.496-38.711-14.403-58.321-18.399M627.652 498.578c13.052 4.678 23.789 10.343 32.826 17.319"
            />
            <path
                fill="#EF0025"
                d="M674.342 1152.03c-9.41-23.12-25.124-85.22-27.904-95.44-6.29-23.11-8.18-51.61-6.447-76.695.221-2.55.496-5.222.84-7.86a33.192 33.192 0 0 0-1.647-15.585c-7.266-20.128-14.333-41.636-21.267-61.51a795.812 795.812 0 0 1-27.73-97.163c-1.024-5.478-2.575-11.952-3.456-17.431-5.224-27.401 14.897-55.733 42.493-59.822 25.732-4.602 51.743 11.875 58.611 37.092a316.836 316.836 0 0 1 5.492 22.203c7.113 35.979 11.719 73.167 10.67 112.216-.411 19.098-.932 36.815-4.155 58.764a32.961 32.961 0 0 0-.217 7.758c5.46 60.333 13.79 127.803 21.995 185.933l-47.278 7.54Z"
            />
            <path
                fill="url(#z)"
                d="M690.991 716.719h-161.1l-.866.499a1029.38 1029.38 0 0 1-2.766 4.734c-9.149 15.582-15.331 26.123-15.083 48.174a62.66 62.66 0 0 0-.152 7.081l-.327 7.1c-.69 26.552-.47 53.041 1.702 79.428 2.009 21.883 3.965 45.353 6.078 67.733a33.059 33.059 0 0 1-6.441 22.833l-.074.1c-14.656 20.537-28.778 47.089-34.267 70.489-3.176 13.77-21.633 97.2-21.633 97.2l42.181 19.08s51.1-124.64 56.451-135.37l21.535-42.932a27.17 27.17 0 0 0 2.631-8.705c9.206-20.937 13.95-37.078 19.239-55.432 6.465-22.027 14.466-50.091 20.304-70.56a46.936 46.936 0 0 1 17.07-24.984c13.56-10.01 21.91-35.8 23.081-39.578 3.256-1.73 25.286-14.461 33.405-45.637l-.968-1.253Z"
            />
            <path
                fill="url(#A)"
                d="m505.774 783.347-.109-.876c-2.916-23.317 1.063-43.872 4.909-63.749 4.392-22.693 8.54-44.128 2.333-67.924a328.345 328.345 0 0 1-10.89-74.74l-.541-22.589c-.375-15.627 6.461-30.812 18.524-40.319 25.43-20.04 49.756-28.119 49.756-28.119s1.915.109 9.777 9.759c1.899 2.332 7.57 9.271 9.486 10.981 8.466 7.556 16.208 11.229 23.664 11.228a22.514 22.514 0 0 0 3.997-.36 9.27 9.27 0 0 0 5.235-2.762c4.714-5.099 3.377-16.737 3.377-16.737a63.07 63.07 0 0 1 8.248 5.996c25.56 21.883 46.641 61.31 52.533 82.778 2.137 7.782 2.362 14.434.668 19.772-3.298 10.394-3.013 21.139.803 30.258a107.86 107.86 0 0 1 8.85 40.647c.291 11.563.003 104.819 0 105.76l-.003.997-190.617-.001Z"
            />
            <path
                fill="url(#B)"
                d="M625.435 498.915s27.545 38.811 45.48 69.682c13.76 23.684 2.024 54.375 9.088 63.333 25.504 32.346 24.686 117.914 24.686 117.914v-43.765c0-42.296-10.605-60.945-15.375-73.636a44.91 44.91 0 0 1-2.379-22.435l.844-5.654a57.59 57.59 0 0 0-6.094-35.511l-17.087-32.181a96.351 96.351 0 0 0-30.154-33.965l-9.108-6.701.099 2.919Z"
            />
            <path
                fill="#fff"
                d="M627.93 499.52c27.881 17.212 28.083 18.737 26.897 24.744a53.669 53.669 0 0 1 8.456 4.076l11.054 47.572c-8.707-17.247-26.431-45.45-46.407-76.392Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M626.578 499.547s26.403 38.178 44.339 69.049c13.76 23.683 2.024 54.374 9.087 63.333 25.504 32.346 24.687 117.914 24.687 117.914v-43.766c0-42.296-10.606-60.945-15.375-73.635a44.926 44.926 0 0 1-2.38-22.436l.845-5.654a57.592 57.592 0 0 0-6.094-35.511l-6.146-11.575"
            />
            <path
                fill="url(#C)"
                d="m539.727 499.52 30.027-16.067 33.7 81.652a96.206 96.206 0 0 1 7.277 36.702v331.839H499.912l-.842-375.174a59.623 59.623 0 0 1 26.302-49.575l14.355-9.377Z"
            />
            <path
                fill="#fff"
                d="M569.754 483.453c-9.788 31.687-2.35 34.448 3.953 37.102-7.299 9.29-6.774 10.843-6.774 10.843l41.053 47.594-38.232-95.539Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m539.727 499.52 30.027-16.067 33.7 81.652a96.187 96.187 0 0 1 7.277 36.702v331.839H499.912l-.842-375.174a59.623 59.623 0 0 1 26.302-49.575l14.355-9.377ZM515.223 759.34h59.674"
            />
            <path
                fill="#CB865E"
                d="M567.028 549.766c-1.796 8.331-3.164 16.754-4.717 25.138a200.318 200.318 0 0 1-6.225 24.817c-2.531 8.175-5.279 16.304-8.328 24.368a249.029 249.029 0 0 1-10.347 23.939 23.278 23.278 0 0 1-44.013-9.373 249.001 249.001 0 0 1 .304-26.077c.501-8.608 1.304-17.151 2.324-25.648a200.307 200.307 0 0 1 4.427-25.2c1.998-8.289 4.179-16.538 5.934-24.879a31.003 31.003 0 0 1 36.719-23.952 30.999 30.999 0 0 1 23.953 36.719l-.031.148Z"
            />
            <path
                fill="#CB865E"
                d="m523.375 620.359 11.646 3.994 11.665 3.934c7.769 2.646 15.458 5.544 23.101 8.587 3.812 1.554 7.641 3.05 11.433 4.665 3.803 1.582 7.602 3.174 11.371 4.862 3.774 1.672 7.563 3.297 11.325 5.008 3.742 1.774 7.513 3.453 11.224 5.326a15.7 15.7 0 0 1 7.896 18.756 15.702 15.702 0 0 1-17.248 10.8c-4.112-.602-8.162-1.399-12.244-2.1-4.061-.764-8.095-1.614-12.144-2.417-4.055-.787-8.079-1.67-12.099-2.564-4.031-.861-8.024-1.839-12.035-2.761-8.003-1.907-15.96-3.959-23.837-6.263l-11.805-3.492-11.823-3.432a22.505 22.505 0 1 1 13.574-42.903Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M493.398 638.655a249.001 249.001 0 0 1 .304-26.077c.501-8.608 1.304-17.151 2.324-25.648a200.307 200.307 0 0 1 4.427-25.2c1.998-8.289 4.179-16.538 5.934-24.878M567.06 549.617l-.032.148c-1.795 8.332-3.164 16.754-4.716 25.138a200.277 200.277 0 0 1-6.226 24.817c-2.531 8.175-5.279 16.304-8.328 24.369M542.772 626.969l3.914 1.32c7.769 2.645 15.458 5.544 23.101 8.587 3.812 1.554 7.641 3.05 11.433 4.665 3.803 1.582 7.602 3.174 11.371 4.862 3.774 1.672 7.563 3.297 11.325 5.008 3.742 1.774 7.513 3.453 11.224 5.326a15.7 15.7 0 0 1 7.896 18.756 15.702 15.702 0 0 1-17.248 10.8c-4.112-.602-8.162-1.399-12.244-2.1-4.061-.764-8.095-1.614-12.144-2.417-4.055-.788-8.079-1.671-12.099-2.564-4.031-.861-8.024-1.839-12.035-2.761-8.003-1.907-15.96-3.959-23.837-6.263l-11.805-3.492-11.823-3.432c-10.096-2.93-16.799-12.85-16.401-24.609"
            />
            <path
                fill="#CB865E"
                d="m692.542 689.129-.627-.411.638-.193a2.955 2.955 0 0 0 1.985-2.559 3.204 3.204 0 0 0-1.927-2.898c-4.219-1.565-7.642-4.673-11.202-7.426-8.245-6.375-17.337-13.476-27.718-14.404l-39.714-3.548c-21.566-1.415-22.893 23.801-7.124 29.915 8.022 3.484 16.276 6.404 24.521 9.321 13.548 4.792 26.225 9.58 40.427 10.544a2.045 2.045 0 0 0 2.149-1.834l.005-.045a2.043 2.043 0 0 0-.272-1.249 2.043 2.043 0 0 0-.963-.841c-9.057-3.908-17.232-9.193-25.189-15.076l11.741 3.603c8.168 2.506 16.405 5.027 24.91 5.849a3.877 3.877 0 0 0 3.055-.731 3.51 3.51 0 0 0 1.198-3.063l-.109-.795c.56.222 1.119.444 1.678.668a2.74 2.74 0 0 0 3.446-1.27 2.736 2.736 0 0 0-.908-3.557Z"
            />
            <path
                fill="#CB865E"
                d="m613.371 657.691 39.714 3.549c10.381.927 19.472 8.029 27.718 14.404 3.56 2.752 6.983 5.861 11.202 7.425"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m613.371 657.691 39.714 3.549c10.381.927 19.472 8.029 27.718 14.404 3.56 2.752 6.983 5.861 11.202 7.425"
            />
            <path
                fill="#CB865E"
                d="M651.172 680.215a568.752 568.752 0 0 1 38.225 13.743 2.725 2.725 0 0 0 2.113-.029 2.735 2.735 0 0 0 1.462-1.526 2.743 2.743 0 0 0-1.037-3.273 147.27 147.27 0 0 0-37.268-17.63"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M651.172 680.215a568.752 568.752 0 0 1 38.225 13.743 2.725 2.725 0 0 0 2.113-.029 2.735 2.735 0 0 0 1.462-1.526 2.743 2.743 0 0 0-1.037-3.273 147.27 147.27 0 0 0-37.268-17.63M606.246 687.605c8.023 3.484 16.277 6.404 24.523 9.321 13.547 4.793 26.223 9.58 40.425 10.544a2.04 2.04 0 0 0 2.151-1.834l.004-.045a2.04 2.04 0 0 0-1.236-2.09c-9.057-3.907-17.232-9.193-25.188-15.075l11.74 3.602c8.169 2.507 16.405 5.027 24.91 5.85"
            />
            <path
                fill="url(#D)"
                d="M597.426 685.895c-1.35-.243-2.7-.486-4.052-.718-2.639-.496-5.249-1.025-7.86-1.554-1.436-.292-2.873-.581-4.31-.868-4.152-.806-8.265-1.711-12.121-2.568-2.711-.58-5.453-1.223-8.104-1.845a1321.89 1321.89 0 0 0-3.938-.917c-8.834-2.106-16.648-4.158-23.895-6.279l-11.807-3.493-11.818-3.43a23.422 23.422 0 0 1-16.933-23.578 24.256 24.256 0 0 1-.189-1.94c-.35-8.727-.249-17.467.305-26.184.48-8.248 1.242-16.657 2.33-25.711a202.184 202.184 0 0 1 4.447-25.318c.604-2.503 1.225-5.005 1.845-7.507 1.407-5.671 2.862-11.537 4.084-17.34a32.124 32.124 0 0 1 31.283-25.418c2.225.001 4.443.233 6.62.692a31.775 31.775 0 0 1 20.212 13.831 31.79 31.79 0 0 1 4.513 24.072l-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.452 2.531-.903 5.064-1.371 7.591a202.407 202.407 0 0 1-6.255 24.938c-2.697 8.711-5.428 16.7-8.346 24.419a389.723 389.723 0 0 1-1.186 3.07c7.008 2.397 14.422 5.159 22.648 8.434 1.247.508 2.491 1.01 3.731 1.507 2.536 1.02 5.158 2.074 7.724 3.167 3.639 1.513 7.522 3.138 11.388 4.869 1.323.587 2.65 1.17 3.98 1.75a576.768 576.768 0 0 1 7.35 3.261c1.247.591 2.482 1.165 3.717 1.738-6.047 8.502-10.818 24.008-10.62 33.658Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m568.038 549.822-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.452 2.531-.903 5.064-1.371 7.591a202.407 202.407 0 0 1-6.255 24.938c-2.697 8.711-5.428 16.7-8.346 24.419a389.723 389.723 0 0 1-1.186 3.07c7.008 2.397 14.422 5.159 22.648 8.434 1.247.508 2.491 1.01 3.731 1.507 2.536 1.02 5.158 2.074 7.724 3.167 3.639 1.513 7.522 3.138 11.388 4.869 1.323.587 2.65 1.17 3.98 1.75a576.768 576.768 0 0 1 7.35 3.261c1.247.591 2.482 1.165 3.717 1.738-6.046 8.502-10.818 24.008-10.62 33.658-1.35-.243-2.7-.486-4.052-.718-2.639-.496-5.249-1.025-7.86-1.554-1.436-.292-2.873-.581-4.31-.868-4.152-.806-8.265-1.711-12.121-2.568-2.711-.58-5.453-1.223-8.104-1.845a1321.89 1321.89 0 0 0-3.938-.917c-8.834-2.106-16.648-4.158-23.895-6.279l-11.807-3.493-11.818-3.43a23.422 23.422 0 0 1-16.933-23.578 24.256 24.256 0 0 1-.189-1.94c-.35-8.727-.249-17.467.305-26.184.48-8.248 1.242-16.657 2.33-25.711a202.184 202.184 0 0 1 4.447-25.318c.604-2.503 1.225-5.005 1.845-7.507 1.407-5.671 2.862-11.537 4.084-17.34"
            />
            <path
                fill="#FCB47F"
                d="M1723.33 1145.7c1.22-7.92 2.31-15.83 3.27-23.73.47-3.95 1.03-7.9 1.44-11.85l1.3-11.84c.89-7.9 1.65-15.78 2.76-23.69l1.66-11.87.84-5.93.64-5.92c1.68-15.79.02-31.34-2.19-46.86-1.19-7.752-2.71-15.483-4.67-23.183a144.212 144.212 0 0 0-7.71-22.977l-4.13-9.364a24.442 24.442 0 0 0-11.87-12.198 24.428 24.428 0 0 0-16.96-1.481 24.437 24.437 0 0 0-13.8 9.957 24.366 24.366 0 0 0-3.94 16.556l5.72 46.62 2.87 23.3 3.01 23.3c2.02 15.53 3.95 31.07 6.05 46.59l6.22 46.59a14.69 14.69 0 0 0 4.83 9.07c2.66 2.35 6.06 3.66 9.6 3.7 3.54.04 6.98-1.2 9.68-3.49a14.73 14.73 0 0 0 5.03-8.97l.35-2.33Z"
            />
            <path
                fill="#FCB47F"
                d="M1695.18 984.678c12.81 2.74 25.4 3.361 37.93 4.68 0 0 107.09-.542 110.05-1.08a52.332 52.332 0 0 0 32.81-20.486 52.365 52.365 0 0 0 9.76-37.423 52.31 52.31 0 0 0-18.62-33.897 52.315 52.315 0 0 0-36.82-11.837l-7.25.498c-3.03.205-6.04.52-9.04.893l-4.49.578-4.47.681c-2.98.423-5.94.989-8.91 1.504-2.97.493-5.91 1.13-8.87 1.717-11.79 2.447-23.53 5.216-35.17 8.46-5.81 1.64-11.56 3.636-17.26 5.845a301.755 301.755 0 0 0-17.01 7.177c-1.41.634-2.82 1.298-4.22 1.978-1.4.663-2.8 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.052 164.052 0 0 0-16.4 10.291l-.87.637a29.928 29.928 0 0 0-10.27 13.467 29.852 29.852 0 0 0-1.29 16.886 29.865 29.865 0 0 0 8.08 14.879 29.935 29.935 0 0 0 14.88 8.09Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1733.11 989.43s107.09-.614 110.05-1.152a52.332 52.332 0 0 0 32.81-20.486 52.365 52.365 0 0 0 9.76-37.423 52.31 52.31 0 0 0-18.62-33.897 52.315 52.315 0 0 0-36.82-11.837l-7.25.498c-3.03.205-6.04.52-9.04.893l-4.49.578-4.47.681c-2.98.423-5.94.989-8.91 1.504-2.97.493-5.91 1.13-8.87 1.717-11.79 2.447-23.53 5.216-35.17 8.46-5.81 1.64-11.56 3.636-17.26 5.845a301.755 301.755 0 0 0-17.01 7.177c-1.41.634-2.82 1.298-4.22 1.978-1.4.663-2.8 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.235 164.235 0 0 0-16.4 10.291l-.87.637c-7.2 5.274-12.77 13.891-13.52 22.133M1723.33 1145.7c1.22-7.92 2.31-15.83 3.27-23.73.47-3.95 1.03-7.9 1.44-11.85l1.3-11.84c.89-7.89 1.65-15.78 2.76-23.69l1.66-11.87.84-5.93.64-5.92c1.68-15.78.02-31.34-2.19-46.86-.66-4.271-1.42-8.539-2.29-12.799M1669.97 961.32l5.72 46.62 2.87 23.3 3.01 23.3c2.02 15.53 3.95 31.07 6.05 46.6l6.22 46.58"
            />
            <path
                fill="#FCB47F"
                d="M1722.77 1147.12c-.1 3.91 2.03 18.68 3.48 28.27.17 1.09.11 2.2-.17 3.27a8.151 8.151 0 0 1-3.96 5.05c-.97.53-2.03.85-3.13.95l-6.97.65c-9.34.9-18.55 2.79-27.49 5.63a72.486 72.486 0 0 1-29.97 2.45c-5.73-.67-10.34-2.82-10.63-5.89-.28-3.04 7.5-6.07 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1722.77 1147.12c-.1 3.91 2.03 18.68 3.48 28.27.17 1.09.11 2.2-.17 3.27a8.151 8.151 0 0 1-3.96 5.05c-.97.53-2.03.85-3.13.95l-6.97.65c-9.34.9-18.55 2.79-27.49 5.63a72.486 72.486 0 0 1-29.97 2.45c-5.73-.67-10.34-2.82-10.63-5.89-.28-3.04 7.5-6.07 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                fill="url(#E)"
                d="M1656.31 1178.9c5.72-1.79 11.52-5.49 16.77-9.69 16.2 6.07 36.37 4.56 52.69-2.96.4 2.77.84 5.8 1.34 9.07.19 1.23.13 2.48-.19 3.68-.31 1.2-.87 2.32-1.64 3.29a9.07 9.07 0 0 1-2.8 2.39 9.1 9.1 0 0 1-3.53 1.06l-6.97.65a132.3 132.3 0 0 0-27.28 5.59 70.36 70.36 0 0 1-20.93 3.04c-3.16 0-6.32-.19-9.45-.55-6.79-.79-11.2-3.39-11.51-6.8-.27-2.93 4.27-5.88 13.5-8.77Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1725.28 1182.29a9.07 9.07 0 0 1-2.8 2.39 9.1 9.1 0 0 1-3.53 1.06l-6.97.65a132.3 132.3 0 0 0-27.28 5.59 70.36 70.36 0 0 1-20.93 3.04c-3.16 0-6.32-.19-9.46-.55-6.78-.79-11.19-3.39-11.5-6.8"
            />
            <path
                fill="#004F71"
                d="m1733.18 1091.38 3.29-40.5c1.59-15.05.14-29.6-2.34-46.99-.63-4.168-1.44-8.352-2.31-12.582l97.88-.164 4.51-.578c2.94-.381 5.96-.852 8.99-1.4a53.362 53.362 0 0 0 42.92-61.96 53.19 53.19 0 0 0-18.25-31.399 53.216 53.216 0 0 0-37.8-12.266l-7.25.497c-2.76.188-5.73.482-9.09.899l-4.49.578-4.49.684c-2.22.314-4.46.714-6.62 1.1l-2.31.408c-2.23.37-4.48.827-6.65 1.269l-2.25.453c-12.69 2.633-24.22 5.406-35.24 8.478-5.42 1.527-11.1 3.45-17.36 5.876a308.77 308.77 0 0 0-17.07 7.2c-1.28.576-2.67 1.227-4.23 1.987-1.35.637-2.73 1.315-4.22 2.07-1.81.893-3.65 1.881-5.42 2.837-.98.527-1.96 1.054-2.94 1.569a166.269 166.269 0 0 0-16.53 10.37l-.87.636a37.325 37.325 0 0 0-12.06 14.989 30.496 30.496 0 0 0-2.15 15.904l5.72 46.615 2.88 23.31 5.4 60.11h50.35Z"
            />
            <path
                fill="#FCB47F"
                d="M1819.01 717.166c-7.33 2.382-14.78 4.373-22.18 6.533a173.863 173.863 0 0 0-21.73 7.914c-7.1 3.055-14.15 6.31-21.1 9.84a216.5 216.5 0 0 0-20.52 11.689 19.209 19.209 0 0 0-7.56 9.661 19.227 19.227 0 0 0-.17 12.269 19.236 19.236 0 0 0 7.3 9.862 19.21 19.21 0 0 0 11.79 3.424c7.86-.404 15.7-1.236 23.47-2.492 7.71-1.197 15.33-2.669 22.89-4.341a174.078 174.078 0 0 0 22.26-6.267c7.26-2.567 14.47-5.303 21.81-7.648a26.514 26.514 0 0 0 9.05-5.009 26.68 26.68 0 0 0 6.44-8.089 26.587 26.587 0 0 0 2.85-9.938c.29-3.468-.11-6.959-1.16-10.274a26.645 26.645 0 0 0-5.01-9.046c-2.25-2.657-5-4.845-8.09-6.44a26.538 26.538 0 0 0-9.94-2.855 26.47 26.47 0 0 0-10.27 1.166l-.13.041Z"
            />
            <path
                fill="#FCB47F"
                d="m1755.87 778.434 5.87-10.432 6.01-10.363a236.975 236.975 0 0 0 10.83-21.367c1.61-3.666 3.31-7.283 4.83-11.001l2.33-5.547c.78-1.851 1.49-3.735 2.24-5.6 2.94-7.488 5.73-15.057 8.03-22.892.79-2.691.64-5.572-.44-8.163-1.07-2.591-3-4.736-5.46-6.078a12.273 12.273 0 0 0-15.17 2.752c-5.34 6.179-10.19 12.626-14.89 19.153-1.17 1.638-2.36 3.258-3.5 4.911l-3.4 4.965c-2.3 3.289-4.42 6.68-6.63 10.021a236.197 236.197 0 0 0-12.09 20.678l-5.46 10.66-5.59 10.592a18.518 18.518 0 0 0-1.35 13.977 18.524 18.524 0 0 0 8.86 10.899 18.502 18.502 0 0 0 24.98-7.165Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1744.84 788.358c7.86-.404 15.7-1.236 23.47-2.492 7.71-1.197 15.33-2.669 22.89-4.341a174.078 174.078 0 0 0 22.26-6.267c7.26-2.567 14.47-5.303 21.81-7.648a26.514 26.514 0 0 0 9.05-5.009 26.68 26.68 0 0 0 6.44-8.089 26.587 26.587 0 0 0 2.85-9.938c.29-3.468-.11-6.959-1.16-10.274a26.645 26.645 0 0 0-5.01-9.046c-2.25-2.657-5-4.845-8.09-6.44a26.538 26.538 0 0 0-9.94-2.855 26.47 26.47 0 0 0-10.27 1.166l-.13.041c-7.33 2.382-14.78 4.373-22.18 6.533a165.33 165.33 0 0 0-10.98 3.59"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1761.74 768.002 6.01-10.363a236.975 236.975 0 0 0 10.83-21.367c1.61-3.666 3.31-7.283 4.83-11.001l2.33-5.547c.78-1.851 1.49-3.735 2.24-5.6 2.94-7.488 5.73-15.057 8.03-22.892.79-2.691.64-5.572-.44-8.163-1.07-2.591-3-4.736-5.46-6.078a12.273 12.273 0 0 0-15.17 2.752c-5.34 6.179-10.19 12.626-14.89 19.153-1.17 1.638-2.36 3.258-3.5 4.911l-3.4 4.965c-2.3 3.289-4.42 6.68-6.63 10.021a236.197 236.197 0 0 0-12.09 20.678l-5.46 10.66-5.59 10.592a18.477 18.477 0 0 0-1.33 14.105 18.397 18.397 0 0 0 3.45 6.349 18.326 18.326 0 0 0 5.62 4.541 25.067 25.067 0 0 0 13.72 2.644"
            />
            <path
                fill="#FCB47F"
                d="M1779.36 648.432a10.154 10.154 0 0 1 5.31-4.023l.09-.103a12.66 12.66 0 0 1 5.77-5.961 16.023 16.023 0 0 1 5.52-1.666 8.164 8.164 0 0 1 5.5 1.378c.23.175.46.368.67.577l.77-.788a7.728 7.728 0 0 1 4.69-1.951 7.502 7.502 0 0 1 4.78 1.517c.42 1.1.01 2.958-1.03 3.507a7.78 7.78 0 0 1-2.34.787l-.1.163a59.382 59.382 0 0 0-4.78 13.243c-.91 3.927-1.34 8.333.81 11.741l2.57 3.007c2.4-.589 4.32-2.331 6.19-3.947a17.381 17.381 0 0 1 4.05-3.124c.46-.198.97-.242 1.46-.127.48.116.92.386 1.24.77l.03.035c.34.404.54.911.55 1.439.01.528-.15 1.045-.47 1.465-3.86 5.104-7.56 10.304-11.45 15.388a42.788 42.788 0 0 1-4.86 5.649 49.04 49.04 0 0 1-6.61 5.057c-5.91 3.672-18.89 6.557-21.45-6.98-2.13-11.334-3.4-27.142 3.09-37.053Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1807.6 669.862c2.4-.589 4.32-2.331 6.19-3.946a17.385 17.385 0 0 1 4.05-3.125c.46-.198.97-.242 1.46-.126.49.115.92.385 1.25.769l.02.035c.34.404.54.912.55 1.439.01.528-.15 1.045-.47 1.466-3.86 5.103-7.56 10.304-11.44 15.387a43.547 43.547 0 0 1-4.86 5.649 31.769 31.769 0 0 1-7.55 5.057M1805.14 669.906c-3.3.161-6.45 1.451-8.92 3.654M1805.03 666.859c-2.15-3.408-1.72-7.815-.81-11.741a59.41 59.41 0 0 1 4.79-13.243M1797.92 644.968a3.306 3.306 0 0 0-.99-3.879 5.943 5.943 0 0 0-4.05-1.18 10.324 10.324 0 0 0-7.41 3.545M1803.87 643.075a5.563 5.563 0 0 0-.48-2.816 5.578 5.578 0 0 0-1.83-2.199 8.165 8.165 0 0 0-5.5-1.379c-1.93.219-3.79.784-5.52 1.667M1805.98 641.61c1.85.431 3.78.186 5.46-.691 1.04-.549 1.45-2.407 1.03-3.507a7.508 7.508 0 0 0-4.78-1.517 7.703 7.703 0 0 0-4.69 1.951M1776.29 685.489c-1.67-11.729-3.41-27.142 3.08-37.053a10.144 10.144 0 0 1 8.07-4.504 6.824 6.824 0 0 1 3.46.733 4.52 4.52 0 0 1 1.75 1.704c.13.254.21.537.22.826 0 .288-.05.575-.17.837-.13.285-.32.535-.56.731-.24.196-.52.33-.83.392-1.74.348-3.71.793-5.36 1.098-.16 1.409-.65 2.888-.82 4.297M1798.15 651.732a17.156 17.156 0 0 1 2.51-6.466M1791.44 653.605l.95-4.793"
            />
            <path
                fill="#FCB47F"
                d="M1812.37 720.198c8.49-8.308 18.7-15.185 30.91-21.253l-15.6 34.205s-19.39-8.958-15.31-12.952Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M1843.28 698.945a132.217 132.217 0 0 0-30.91 21.252"
            />
            <path
                fill="url(#F)"
                d="M1833.76 637.538c-.5-1.4-2.1-1.9-3.5-1.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7l8.1 22.3c.5 1.4 2.1 1.9 3.5 1.3l49.7-23.3c1.4-.7 2.2-2.3 1.7-3.7l-8.1-22.2Z"
            />
            <path
                fill="#444"
                d="m1830.36 636.238 9.8 27.2-49.7 23.3-9.8-27.2 49.7-23.3Z"
            />
            <path
                fill="url(#G)"
                d="m1777.86 656.937 49.7-23.2c1-.5 2.1-.3 2.8.3l2.8 2.6c-.7-.7-1.8-.8-2.8-.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7l8 22.2c.1.4.4.7.6.9l-2.8-2.5c-.3-.2-.5-.6-.6-.9l-8-22.3c-.5-1.4.3-3 1.7-3.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1790.46 686.736 49.7-23.3c1.4-.7 2.2-2.3 1.7-3.7l-8-22.3c-.5-1.4-2.1-1.9-3.5-1.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1787.56 686.337-2.8-2.5c-.3-.2-.5-.6-.6-.9l-8-22.3c-.5-1.4.2-3 1.7-3.7l49.7-23.2c1-.5 2.1-.3 2.8.3l2.8 2.6c-.7-.7-1.8-.8-2.8-.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7M1834.16 646.836l1.8 4.9M1784.56 670.438l2.2 6"
            />
            <path
                fill="url(#H)"
                d="M1811.67 719.477a109.327 109.327 0 0 1 20.03-15.329 30.932 30.932 0 0 0-2.4 13.895 66.13 66.13 0 0 0 1.45 10.762l-2.17 4.755a.9.9 0 0 1-.23.317.981.981 0 0 1-.72.267.991.991 0 0 1-.38-.092c-2.59-1.2-15.6-7.426-16.4-12.093-.09-.449-.05-.912.09-1.346.14-.434.39-.825.73-1.136Z"
            />
            <path
                fill="url(#I)"
                d="M1803.08 720.873c5.14-1.464 10.46-2.977 15.62-4.657l.13-.041a27.516 27.516 0 0 1 21.06 1.667 27.631 27.631 0 0 1 8.44 6.689 27.511 27.511 0 0 1 5.22 9.418 27.699 27.699 0 0 1 1.21 10.701 27.568 27.568 0 0 1-3 10.344 27.469 27.469 0 0 1-6.74 8.401 27.525 27.525 0 0 1-9.45 5.168c-5.15 1.65-10.35 3.524-15.36 5.335l-3.58 1.29-18.68-52.849s3.76-1.077 5.13-1.466Z"
            />
            <path
                fill="#FCB47F"
                d="M1795.67 820.646c-16.26-6.304-32.19-19.576-12.28-43.133 19.91-23.558 26.37-32.835 31.85-47.115 9.29-24.222 28.42-31.226 28.42-31.226 9.29 6.968 42.54.782 55.15-9.172l19.77 11.891a61.315 61.315 0 0 1 26.13 31.884 61.346 61.346 0 0 1 .04 41.223l-18.83 52.893a36.589 36.589 0 0 0-1.54 18.895 36.56 36.56 0 0 0 8.14 17.121 110.773 110.773 0 0 1 18.89 31.923h-167.8c-7.67-30.163 4.79-55.045 12.06-75.184Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1807.11 881.199a13.787 13.787 0 0 0-10.29 0M1891.16 797.003c-5.36 11.03-17.16 17.893-29.23 20.012-7.21 1.264-14.82 1.029-21.61-1.698a30.948 30.948 0 0 1-11.84-8.427 30.917 30.917 0 0 1-6.69-12.899 30.907 30.907 0 0 1-.08-14.532 31.053 31.053 0 0 1 6.56-12.967"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1783.61 895.831c-7.67-30.163 4.79-55.045 12.06-75.184-16.26-6.304-32.19-19.576-12.28-43.133 19.91-23.558 26.37-32.835 31.85-47.116M1898.81 690l19.77 11.891a61.322 61.322 0 0 1 26.17 73.107l-18.83 52.893a36.589 36.589 0 0 0-1.54 18.895 36.56 36.56 0 0 0 8.14 17.121 110.773 110.773 0 0 1 18.89 31.923"
            />
            <path
                fill="#FCB47F"
                d="M1951.41 895.828a90.493 90.493 0 0 1 4.4 15.397c10.4 55.41-52.31 115.615-131.42 44.511-24.41-21.935-36.19-41.866-40.78-59.908h167.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1951.41 895.828a90.493 90.493 0 0 1 4.4 15.397c10.4 55.41-52.31 115.615-131.42 44.511-24.41-21.935-36.19-41.866-40.78-59.908"
            />
            <path
                fill="#FCB47F"
                d="M1769.44 989.253c18.13 0 74.69.108 74.69.108s51.28-.014 63-.015c1.19 0 9.07-1.244 10.24-1.503a52.205 52.205 0 0 0 31.87-21.161 52.242 52.242 0 0 0 8.91-37.208 52.21 52.21 0 0 0-18.84-33.301 52.242 52.242 0 0 0-36.48-11.534l-7.25.497c-3.03.206-6.03.521-9.03.893l-4.49.578-4.47.681c-2.99.423-5.94.989-8.91 1.505-2.97.492-5.92 1.13-8.87 1.717-11.8 2.446-23.53 5.215-35.17 8.46-5.82 1.64-11.56 3.635-17.27 5.845a301.739 301.739 0 0 0-17.01 7.176c-1.41.634-2.81 1.298-4.21 1.978-1.41.663-2.81 1.351-4.2 2.062-2.8 1.378-5.57 2.939-8.35 4.4a163.107 163.107 0 0 0-16.4 10.292l-.87.637a29.853 29.853 0 0 0-11.88 19.54 29.888 29.888 0 0 0 5.42 22.216c4.5 6.144 11.47 14.257 19.57 16.137Z"
            />
            <path
                fill="#FCB47F"
                d="M1765.78 1148.07c2.47-7.64 4.8-15.29 7.01-22.95 1.1-3.83 2.28-7.65 3.32-11.49l3.17-11.5c2.14-7.67 4.15-15.35 6.52-22.99l3.53-11.47 1.78-5.74 1.58-5.75c4.18-15.35 5.02-31 5.32-46.7.06-7.86-.19-15.744-.9-23.674a144.95 144.95 0 0 0-3.94-23.957l-2.58-9.888a24.409 24.409 0 0 0-26.26-18.123 24.44 24.44 0 0 0-15.23 7.623 24.476 24.476 0 0 0-6.54 15.718l-1.8 47.021-.89 23.51-.76 23.52c-.48 15.69-1.06 31.36-1.47 47.06l-1.3 47.06c-.1 3.54 1.08 7 3.32 9.74 2.25 2.74 5.4 4.58 8.89 5.18 3.49.6 7.08-.08 10.11-1.9 3.03-1.83 5.3-4.7 6.4-8.06l.72-2.24Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1798.28 989.361h103.05c18.39 0 35.82-6.359 46.83-21.091.08-.103.15-.206.23-.309M1859.81 890.508c-11.79 2.446-23.53 5.215-35.17 8.46-5.81 1.64-11.56 3.635-17.27 5.845a303.843 303.843 0 0 0-17 7.176c-1.41.634-2.82 1.298-4.22 1.978-1.41.663-2.81 1.351-4.2 2.062-2.8 1.378-5.56 2.939-8.35 4.4a164.062 164.062 0 0 0-16.4 10.292l-.87.637a29.82 29.82 0 0 0-11.86 19.416M1767.03 1144.16a752.95 752.95 0 0 0 5.76-19.04c1.1-3.83 2.28-7.65 3.32-11.49l3.17-11.5c2.14-7.67 4.15-15.35 6.52-23l3.53-11.47 1.78-5.73 1.58-5.75c4.18-15.35 5.02-31 5.32-46.7.06-7.86-.19-15.746-.9-23.675M1742.56 957.18l-1.8 47.02-.89 23.51-.76 23.53c-.48 15.68-1.06 31.36-1.47 47.05l-1.31 47.06"
            />
            <path
                fill="#FCB47F"
                d="M1765.58 1147.12c-.1 3.91 2.02 18.68 3.48 28.27a8.129 8.129 0 0 1-1.63 6.2 8.07 8.07 0 0 1-5.64 3.07l-6.96.65c-9.34.9-18.56 2.79-27.5 5.63-9.7 2.9-19.91 3.74-29.96 2.45-5.74-.67-10.35-2.82-10.63-5.89-.28-3.04 7.49-6.07 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1765.58 1147.12c-.1 3.91 2.02 18.68 3.48 28.27a8.129 8.129 0 0 1-1.63 6.2 8.07 8.07 0 0 1-5.64 3.07l-6.96.65c-9.34.9-18.56 2.79-27.5 5.63-9.7 2.9-19.91 3.74-29.96 2.45-5.74-.67-10.35-2.82-10.63-5.89-.28-3.04 7.49-6.07 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                fill="url(#J)"
                d="M1782.33 776.738c20.4-24.152 26.32-32.894 31.64-46.771 2.99-7.787 9.42-18.228 14.92-23.949a47.236 47.236 0 0 1 4.73-2.974c-4.07 7.051-4.95 18.802 6.42 23.57a22.026 22.026 0 0 0 8.68 1.436c10.92 0 21.12-6.507 31.94-13.399 7.28-4.644 14.81-9.445 22.91-12.429a32.693 32.693 0 0 1 10.8-1.712 31.38 31.38 0 0 1 4.39.292c.16.023.31.078.45.162a62.747 62.747 0 0 1 26.52 74.436l-18.82 52.892c-4.37 12.283-2.11 25.795 6.37 34.828 20.64 21.97 25.62 45.362 27.94 63.293-14.88 0-179.2.874-179.23.714-9.23-48.617.85-76.583 9.32-98.687.94-2.464 1.84-4.812 2.68-7.11-8.09-3.299-18.17-9.259-20.66-19.014-1.94-7.615 1.09-16.221 9-25.578Z"
            />
            <g filter="url(#K)">
                <path
                    fill="url(#L)"
                    d="m1896.09 787.712-5.08 9.293c-5.36 11.03-17.16 17.893-29.23 20.012-7.21 1.264-14.82 1.029-21.61-1.698a30.945 30.945 0 0 1-11.84-8.426 30.923 30.923 0 0 1-6.69-12.899 30.907 30.907 0 0 1-.08-14.532 31.057 31.057 0 0 1 6.56-12.968l6.92-10.635"
                />
            </g>
            <path
                fill="url(#M)"
                d="M1699.33 1178.79c6-1.88 12.11-5.87 17.56-10.33 16.99 7.08 31.81 4.91 52.05-1.52.46 3.24.58 4.27 1.19 8.28a9.009 9.009 0 0 1-1.83 6.96c-.76.98-1.72 1.8-2.81 2.39-1.09.6-2.29.96-3.52 1.07l-6.97.64c-9.27.9-18.41 2.78-27.29 5.59a70.314 70.314 0 0 1-20.93 3.04c-3.16 0-6.31-.18-9.45-.55-6.78-.79-11.19-3.39-11.51-6.79-.27-2.93 4.28-5.89 13.51-8.78Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1768.3 1182.18c-.76.98-1.72 1.79-2.8 2.39a9.1 9.1 0 0 1-3.53 1.06l-6.97.65c-9.26.9-18.41 2.78-27.28 5.59a70.36 70.36 0 0 1-20.93 3.04c-3.16 0-6.32-.19-9.46-.55-6.78-.79-11.19-3.39-11.5-6.79"
            />
            <path
                fill="#FCB47F"
                d="M1824.87 626.479c1.41-4.724-1.21-10.013-1.21-15.21 1.23-9.261 13.9-23.646 24.65-26.985 18.46-5.728 33.31-1.407 44.54 6.258 3.24 2.214 26.64 23.438.73 66.891-11.08 17.266 5.23 32.566 5.23 32.566-4.69 6.464-39.36 33.253-53.23 28.629-13.88-4.623.78-20.394 3.04-27.093 1.49-4.418 2.37-8.206 1.96-11.514-6.3 1.627-10.49 1.905-14.41-.356-13.59-6.349-14.37-23.852-14.14-33.359.25-10.12 1.42-15.103 2.84-19.827Z"
            />
            <path
                fill="url(#N)"
                d="M1843.86 699.525c4.45-6.156 6.56-10.989 6.6-19.61 13.02-2.157 20.07-8.959 26.79-16.341 1.21-1.337-6.68 29.123-7.26 30.835-.58 1.712-1.39 2.179-2.34 3.716-4.31 6.968-24.45 2.309-23.79 1.4Z"
            />
            <path
                fill="url(#O)"
                d="M1825.43 617.957c1.65-2.074 9.8-2.899 10.26 4.216.61 9.199-5.2 23.632-2.88 26.204.77.85 2.72 2.541 2.82 3.682.36 4.074-.18 8.246-.43 12.334-.29 4.742-1.34 13.154 3.12 15.985-.44-.582-6.44-3.119-7.02-3.551-10.71-7.907-10.3-30.718-8.13-44.775.41-2.607 2.01-5.222 2.36-7.836.34-2.524-.99-5.146-.1-6.259Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1876.84 663.436c-8.19 14.378-32.28 20.999-42.23 15.605-8.72-4.723-12.78-14.241-12.3-33.178.23-9.511 1.14-14.657 2.56-19.381 1.42-4.724-1.39-9.7-.93-14.877.99-11.39 14.52-29.06 36.86-30.636 17.7-1.248 30.46 6.781 35.54 13.108 7.68 9.574 11.4 22.365 9.15 34.434-1.52 8.182-5.3 15.746-8.9 23.248-4.49 9.359-13.93 19.957 1.56 37.753"
            />
            <path
                fill="#FCB47F"
                d="M1835.7 622.176a32.143 32.143 0 0 1-5.74 16.51c-.98 1.47-2.11 2.987-2.16 4.752.03.911.28 1.801.73 2.594a5.724 5.724 0 0 0 1.85 1.961c1.02.684 2.12 1.218 3.29 1.583"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1835.7 622.176a32.143 32.143 0 0 1-5.74 16.51c-.98 1.47-2.11 2.987-2.16 4.752.03.911.28 1.801.73 2.594a5.724 5.724 0 0 0 1.85 1.961c1.02.684 2.12 1.218 3.29 1.583M1862.06 620.374a19.227 19.227 0 0 0-7.08-3.731c-2.6-.732-5.33-.909-8-.52M1831.96 616.837c-3.24-.207-6.46.588-9.23 2.276M1848.32 657.539a24.223 24.223 0 0 1-13.9 2.516"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1852.15 631.329c-.78 0-1.41-1.084-1.41-2.422s.63-2.423 1.41-2.423c.78 0 1.41 1.085 1.41 2.423s-.63 2.422-1.41 2.422ZM1828.55 631.329c-.78 0-1.41-1.084-1.41-2.422s.63-2.423 1.41-2.423c.78 0 1.41 1.085 1.41 2.423s-.63 2.422-1.41 2.422Z"
            />
            <path
                fill="#7A5430"
                d="M1916.54 600.321c-7.52-16.811-20.8-31.852-47.56-33.622-28.55-1.888-100.57-.645-45.99 62.42.12-.374.24-.743.35-1.101.2-.627.39-1.234.57-1.827.72-2.403.23-4.96-.3-7.668a25.427 25.427 0 0 1-.67-7.009c.51-5.763 4.08-12.98 10.22-19.07l.89 1.25a.2.2 0 0 1 .05.056c10.27 14.7 25.2 33.804 45.11 42.395.59.256.09 1.036.22.963.61-.337 1.01-7.08 6.49-11.338 10.52-8.183 21.49 8.961 7.92 22.912a18.59 18.59 0 0 1-8.32 5.005c.06 3.844.53 7.671 1.4 11.415.14.505.42.956.82 1.29a2.52 2.52 0 0 0 2.91.221c.44-.27.79-.673 1-1.152 2.6-6.202 5.75-12.16 9.4-17.804 20.79 3.926 23.67-29.032 15.49-47.336Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1915.18 642.541c7.39-9.53 7.07-29.444 1.36-42.219-7.53-16.811-20.8-31.852-47.56-33.622-17.29-1.143-50.56-1.146-61.96 13.052"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1879.43 637.11c.61-.337 1.01-7.08 6.49-11.338 10.52-8.183 21.49 8.961 7.92 22.912a18.59 18.59 0 0 1-8.32 5.005"
            />
            <path
                fill="url(#P)"
                d="M1799.61 1010.32c-.12-5.6.15-14.387-.14-19.172 12 .029 93.58.018 114.58.018 28.92-5.247 53.67-32.892 47.02-66.289-7.19-36.098-45.98-45.883-80.82-39.723-18.96 3.353-38.85 7.805-56.49 12.687-17.1 4.96-36.53 13.61-52.52 22.337-14.97 7.682-29.55 19.158-30.67 38.023-1.65 43.349-3.59 93.229-4.79 136.339l48.2 3.1s16.47-50.18 15.63-87.32Z"
            />
            <path
                fill="#FCB47F"
                d="M1897.66 755.608c3.94 6.803 7.52 13.811 11.26 20.731 3.73 6.935 7.9 13.618 12.5 20.008 4.55 6.45 9.29 12.796 14.27 18.998a224.096 224.096 0 0 0 15.95 18.029 19.156 19.156 0 0 0 23.09 3.049c3.65-2.1 6.51-5.344 8.13-9.23a19.21 19.21 0 0 0 .85-12.271 226.478 226.478 0 0 0-7.58-22.848c-2.85-7.425-5.96-14.704-9.25-21.88a180.747 180.747 0 0 0-11.01-20.87c-4.1-6.706-8.36-13.323-12.27-20.146a26.5 26.5 0 0 0-48.57 6.197 26.527 26.527 0 0 0 2.57 20.119l.06.114Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1952.02 791.748c6.08 0 11-4.788 11-10.694 0-5.906-4.92-10.695-11-10.695-6.07 0-11 4.789-11 10.695 0 5.906 4.93 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M1952.02 783.36c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.309a2.305 2.305 0 0 0 2.3 2.309Z"
            />
            <path
                fill="#FCB47F"
                d="m1947.64 822.582-.45 13.517-.6 13.514c-.36 9.01-.31 18.029.15 27.057.24 4.514.38 9.026.73 13.542l.47 6.773c.15 2.258.38 4.518.57 6.776.81 9.035 1.79 18.074 3.33 27.124a12.203 12.203 0 0 0 4.07 7.205 12.216 12.216 0 0 0 15.56.33 12.239 12.239 0 0 0 4.37-7.025c1.92-8.977 3.28-17.966 4.47-26.958.29-2.249.61-4.496.87-6.745l.75-6.748c.54-4.497.88-8.999 1.31-13.499.83-9 1.26-18.008 1.29-27.026l-.02-13.527.12-13.525a18.51 18.51 0 0 0-5.22-13.047 18.51 18.51 0 0 0-12.89-5.613 18.518 18.518 0 0 0-13.11 5.061 18.502 18.502 0 0 0-5.77 12.814Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1897.6 755.492.06.115c3.94 6.803 7.52 13.811 11.25 20.731a181.069 181.069 0 0 0 12.51 20.007c4.55 6.45 9.28 12.796 14.26 18.998 3.12 3.855 6.31 7.671 9.66 11.391M1984.33 820.107c.02-1.75-.19-3.496-.63-5.19a223.868 223.868 0 0 0-7.57-22.849c-2.85-7.424-5.96-14.704-9.25-21.879a180.666 180.666 0 0 0-11.01-20.87c-4.11-6.706-10.57-16.527-14.47-23.35M1975.83 931.39c1.92-8.978 3.29-17.967 4.48-26.959.29-2.249.61-4.496.87-6.745l.75-6.748c.54-4.497.87-8.999 1.31-13.498.83-9 1.26-18.009 1.29-27.027l-.02-13.527-.18-16.777M1947.41 829.406l-.82 20.206c-.36 9.011-.31 18.029.14 27.057.25 4.514.39 9.026.74 13.542l.46 6.774c.16 2.258.39 4.517.58 6.775.81 9.035 1.79 18.074 3.33 27.125"
            />
            <path
                fill="#FCB47F"
                d="M1941.05 967.14c-.1-2.286-.35-4.562-.76-6.813a38.532 38.532 0 0 1 1.5-15.861 38.298 38.298 0 0 1 7.79-13.895c10.72-12.747 22.94-13.499 26.7 1.627 1.45 9.565 2.41 19.248 1.67 28.884-.74 9.635-3.24 19.263-8.34 27.688-.23.402-.51.772-.83 1.1-.3.292-.69.478-1.11.529a1.458 1.458 0 0 1-1.41-.817l-.23-.309c-.9 2.33-1.88 4.637-2.85 6.94-.26.425-.64.762-1.1.967-.45.205-.95.269-1.44.184a2.553 2.553 0 0 1-1.3-.571 2.562 2.562 0 0 1-.79-1.174l-.23-.426c-.02.641-.01 1.282.03 1.921 0 .267-.04.532-.14.78s-.25.473-.44.663a1.992 1.992 0 0 1-1.44.589l-.04.001a2.08 2.08 0 0 1-1.26-.348 2.05 2.05 0 0 1-.81-1.035c-1.71-5.209-1.54-10.837-1.03-16.288.16-1.708.35-3.42.52-5.132l-.38.66a46.527 46.527 0 0 0-2.76 16.316c0 .191-.03.38-.1.557-.07.177-.18.339-.31.474-.13.135-.29.242-.47.314-.18.072-.36.107-.55.104a3.583 3.583 0 0 1-1.79-.678 3.52 3.52 0 0 1-1.19-1.495 21.872 21.872 0 0 1-2.42-10.556c.03-3.089.28-6.17.77-9.22l-.27.185a4.7 4.7 0 0 1-2.72 1.537 2.864 2.864 0 0 1-2.77-1.046 9.162 9.162 0 0 0 2.3-6.356Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1949.58 930.57a38.458 38.458 0 0 0-7.79 13.896 38.532 38.532 0 0 0-1.5 15.861c.41 2.251.66 4.527.76 6.812a9.163 9.163 0 0 1-2.3 6.358M1945.37 949.922a91.53 91.53 0 0 1-.27 18.428c-.53 4.56-1.4 9.103-1.36 13.69-.06 3.662.77 7.283 2.42 10.556a3.942 3.942 0 0 0 1.89 1.672c.79.337 1.68.407 2.52.198l.02-.007M1953.9 963.602c.78 5.93-.15 11.921-.71 17.873-.51 5.451-.67 11.079 1.03 16.289a2.1 2.1 0 0 0 2.08 1.383l.04-.002c.26.004.53-.047.77-.148.25-.101.48-.251.66-.441.19-.189.34-.415.44-.663.1-.247.15-.513.14-.78-.06-3.461.43-6.91 1.44-10.221 1.02-3.947 3.39-15.108 3.08-23.004M1963.18 996.211c1.92-4.293 3.59-8.692 5-13.177a59.917 59.917 0 0 0 2.36-20.19M1969.61 988.768c5.1-8.425 7.6-18.053 8.34-27.689.74-9.636-.22-19.318-1.67-28.884"
            />
            <path
                fill="url(#Q)"
                d="M1905.66 771.242a.981.981 0 0 0 .47-.118l47.49-25.336c.12-.064.22-.153.31-.26.08-.107.14-.231.18-.363a.923.923 0 0 0 .02-.405.885.885 0 0 0-.14-.381l-.94-1.52c-2.86-4.6-5.82-9.357-8.58-14.184a27.5 27.5 0 0 0-16.65-12.873 27.505 27.505 0 0 0-20.87 2.657 27.532 27.532 0 0 0-10.22 37.526l.07.115c2.75 4.766 5.37 9.692 7.9 14.455l.08.154c.06.117.14.221.24.304a1.033 1.033 0 0 0 .64.229Z"
            />
            <path
                fill="#FCB47F"
                d="M938.12 712.408c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 6.1 6.7 12 13.5 17.5 20.9 7.6 10.3 5.5 24.8-4.8 32.5-7.1 5.3-16.2 5.901-23.6 2.401-8.3-3.9-16.2-8.301-24-12.901-7.7-4.6-15.3-9.5-22.7-14.5-7.4-5-14.6-10.4-21.3-16.2-6.8-5.8-13.4-11.799-20.4-17.399-13.3-10.8-15.3-30.301-4.5-43.601s30.3-15.3 43.6-4.5h.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M932.22 788.107c-4.4-3.3-8.8-6.7-12.9-10.3-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5l.1.1c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 4.6 5 9.1 10.1 13.4 15.5"
            />
            <path
                fill="url(#R)"
                d="m900.52 691.605 2.7 1c13.1 4.7 23.8 10.301 32.8 17.301.8.6 5.899 5.4 5.799 6.4-.2 4.6-16.899 8.2-19.299 9.6l-.9.5-21.1-34.801Z"
            />
            <path
                fill="url(#S)"
                d="M953.52 723.107c-9.3-6.4-17.299-16.099-28.899-18.199-20.4-4.5-40.601 13.999-37.901 34.699.9 15.8 15.301 24.101 25.901 33.601 6.2 5.4 12.499 10.8 19.099 15.7 1.5 1.1 56.8-35.301 56.8-35.301-10.8-12-28.6-25.8-35-30.5Z"
            />
            <path
                fill="#FCB47F"
                d="M808.444 908.138c-.6-9.55-9-41.647-16.1-67.345-6.6-23.925-10.3-48.539-10.9-73.351l-.5-21.857c-.4-15.36 5.9-29.636 18.1-39.285 16.3-12.898 31.8-20.184 49.9-27.765 32.2 15.36 42.1 16.147 56 13.587 2.1 1.379 4.3 2.757 6.4 4.332 26 18.707 46 58.78 52.2 80.933 3.2 11.519 2.8 23.433-8.2 29.34 14 27.568 25 48.244 14 101.411"
            />
            <path
                fill="url(#T)"
                d="M808.444 908.138c-.6-9.55-9-41.647-16.1-67.345-6.6-23.925-10.3-48.539-10.9-73.351l-.5-21.857c-.4-15.36 5.9-29.636 18.1-39.285 16.3-12.898 31.8-20.184 49.9-27.765 32.2 15.36 42.1 16.147 56 13.587 2.1 1.379 4.3 2.757 6.4 4.332 26 18.707 46 58.78 52.2 80.933 3.2 11.519 2.8 23.433-8.2 29.34 14 27.568 25 48.244 14 101.411"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M954.945 754.641c3.9 8.467 6.8 16.344 8.6 22.743 3.2 11.52 2.8 23.433-8.2 29.341 14 27.568 25 48.244 14 101.411M808.445 908.138c-.6-9.55-9-41.648-16.1-67.345-6.6-23.925-10.3-48.54-10.9-73.351l-.5-19.101c-.8-23.63 9.7-36.331 21.7-44.896 12.4-8.862 23-15.261 41.3-22.941"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M955.545 806.925c-3.6-6.105-7.7-12.702-11.1-16.64M846.344 808.107c31.1 3.741 56.5-1.182 71.7-21.759M946.445 884.407c3.6-1.28 7.1-1.378 10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M807.547 909.516c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.558 83.7 14.867 103.7 15.261 31.5.689 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503"
            />
            <path
                fill="url(#U)"
                d="M807.547 909.516c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.558 83.7 14.867 103.7 15.261 31.5.689 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M807.547 909.516c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.558 83.7 14.867 103.7 15.261 31.5.689 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503M986.645 946.043h15.495"
            />
            <path
                fill="#FCB47F"
                d="M1027.35 995.361s12.1-7.778 22.8-11.716l3.2-1.182c6.7-2.461 12.5-7.088 16.4-13.685 8.9-15.064 3.7-34.46-11.6-43.223-9.5-5.415-19.1-9.846-28.7-13.981-4.8-2.068-9.7-4.037-14.5-5.908-4.8-1.969-9.7-3.839-14.6-5.513-9.804-3.249-19.704-5.415-29.704-7.188-2.5-.393-5-.886-7.5-1.181-2.5-.295-5-.689-7.5-.886l-3.8-.394-3.8-.295c-2.5-.197-5.1-.197-7.6-.197-22.1.492-42.4 14.67-49.4 36.528-8.8 27.469 6.8 56.81 34.7 65.474l.5.197c4.6 1.378 9.2 2.461 13.9 3.249h61.204c.5 0 25.6 0 26-.099Z"
            />
            <path
                fill="#FCB47F"
                d="M1019.15 1150.63c-1.1-8.37-2.1-16.74-2.9-25.01-.4-4.13-.9-8.37-1.2-12.5l-1.1-12.5c-.7-8.37-1.4-16.64-2.4-25.01-.9-8.37-2.2-16.74-2.8-25.01-1.4-16.64.8-32.98 3.6-49.23 1.5-8.074 3.3-16.246 5.7-24.221.6-1.969 1.2-4.036 1.8-6.006.7-1.969 1.3-4.036 2.1-6.006 1.5-4.036 3.1-7.975 5-11.913l5-10.141c6.4-12.898 22.2-18.215 35.2-11.913 10.6 5.119 16.1 16.245 14.5 27.174l-7.4 48.836-3.7 24.42-3.9 24.41c-2.6 16.25-5.1 32.49-7.8 48.84l-8 48.74c-1.4 8.56-9.6 14.37-18.3 12.99-7.1-1.08-12.4-6.69-13.3-13.49l-.1-2.46Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1067.35 931.265c-12.4-7.876-28.3-15.556-37.9-19.691-4.8-2.068-9.7-4.037-14.5-5.908-4.8-1.969-9.7-3.839-14.6-5.513-9.804-3.249-19.704-5.415-29.704-7.188-2.5-.393-5-.886-7.5-1.181-2.5-.295-5-.689-7.5-.886l-3.8-.394-3.8-.295c-2.5-.197-5.1-.197-7.6-.197-22.1.492-42.4 14.67-49.4 36.528-8.8 27.469 6.8 56.81 34.7 65.474l.5.197c4.6 1.378 9.2 2.461 13.9 3.249h61.204c.2 0 5.8-.099 11.8-.099M1019.55 1153.19l-.3-2.56c-1.1-8.37-2.1-16.74-2.9-25.01-.4-4.13-.9-8.37-1.2-12.5l-1.1-12.51c-.7-8.37-1.4-16.64-2.4-25-.9-8.37-2.2-16.74-2.8-25.01-1.4-16.64.8-32.99 3.6-49.23.1-.69.3-1.38.4-2.069M1067.35 931.266c10.6 5.119 16.1 16.245 14.5 27.174l-7.4 48.83-3.7 24.42-3.9 24.42c-2.6 16.25-5.1 32.49-7.8 48.84l-8 48.73"
            />
            <path
                fill="#FCB47F"
                d="M1018.85 1151.43c.3 4.23-1.4 20.47-2.5 31.01-.6 5.02 3.3 9.55 8.5 9.85l20.3.98c4.3.2 8.6.98 12.7 2.56 4.3 1.58 11.2 3.54 20.3 4.04 4.1.19 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.24-.1-1.57-1.1-2.95-2.5-3.64-3.1-1.28-8.6-3.54-13-5.12-19.9-7.29-41.3-31.9-41.3-31.9"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1018.85 1151.43c.3 4.23-1.4 20.47-2.5 31.01-.6 5.02 3.3 9.55 8.5 9.85l20.3.98c4.3.2 8.6.98 12.7 2.56 4.3 1.58 11.2 3.54 20.3 4.04 4.1.19 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.24-.1-1.57-1.1-2.95-2.5-3.64-3.1-1.28-8.6-3.54-13-5.12-19.9-7.29-41.3-31.9-41.3-31.9M1057.14 946.043h15.5"
            />
            <path
                fill="#EF0025"
                d="M1105.95 1189.13c-2.9-1.18-8.5-3.54-13-5.12-7.8-2.85-15.8-8.36-22.7-14.07-2.4-1.97-4.6-3.94-6.7-5.81-3.4-.1-13.9 5.71-19.7 6.1-11.5.69-20.1-3.64-27-3.15-.4 4.33-.9 9.45-1.6 15.36-.3 2.76.5 5.41 2.3 7.48 1.8 2.07 4.3 3.35 7.1 3.45l20.3.98c4.3.2 8.5 1.09 12.4 2.46 4.3 1.58 11.3 3.55 20.6 4.04h1.1c5.1 0 18.1-1.18 25.3-1.97 2.7-.29 4.7-2.56 4.6-5.22.1-1.97-1.2-3.74-3-4.53Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1017.64 1189.73c1.8 2.06 4.3 3.34 7.1 3.44l20.3.99c4.3.19 8.5 1.08 12.4 2.46 4.3 1.57 11.3 3.54 20.6 4.04h1.1c5.1 0 18.1-1.19 25.3-1.97"
            />
            <path
                fill="#001489"
                d="M953.345 888.053c-27.7-3.545-53.1 10.239-61.9 36.823-9 28.159 7.2 58.287 35.9 66.853 4.3 1.378 9 2.461 14.1 3.347h40.1s4.3-17.427 12.4-46.964c8.705-31.605 31.605-38.989 31.605-38.989-9.1-5.219-49.805-19.003-72.205-21.07Z"
            />
            <path
                fill="#FCB47F"
                d="M919.947 594.457c4.4 10.043 7.6 23.827 7.6 34.755 0 10.929-1.9 21.858-4.7 32.393-1.6 6.006-2.7 12.701-6.2 17.722-2.5 3.643-7.8 5.12-12.3 4.726-.7-.098-1.3-.098-2-.196.4 1.279.8 11.421.8 12.208l-8.6 6.105-46.7-17.723 2.6-11.421.4-12.799s-1.1-8.369-9.1-23.63c-10.1-19.199 3.1-37.217 3.1-37.217s2.8-10.929 18.5-17.723c22.4-9.452 40.9-2.658 56.6 12.8Z"
            />
            <path
                fill="url(#V)"
                d="M926.847 618.377c-2-.197-4-.394-6-.689-2-.197-4.1-.394-5.7.788-1.2.886-1.8 2.363-1.9 3.741-.1 1.477-.4 2.954-.1 4.332 2.2 12.701 1.5 21.562-.9 24.122-.5.591-1 1.28-1.1 1.969-.1.788.1 1.477.2 2.265.9 4.135 1.8 8.369 2.7 12.504.5 2.265.8 4.628 1.1 6.892.4 3.84-.6 7.188-4.6 9.255 6.6-1.28 13.7-7.581 15.2-10.338 9.5-17.821-.9-36.626 1.1-54.841Z"
            />
            <path
                fill="url(#W)"
                d="M903.149 694.483c-.2-3.643-.4-7.286-.5-10.929-14.5 1.182-30.2-11.421-36.6-18.116-.9-.985 2.2 13.587 5.3 23.728 7.3 3.249 22.8 8.566 31.8 5.317Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M866.046 664.654c9.3 11.323 23.7 18.018 38.4 19.397 5.6.59 13.7-1.871 18.2-6.499 7.2-7.581 9.7-22.349 6.1-36.429-1.2-4.824-2.2-7.384-2.1-11.126 0-2.855.8-6.596.4-9.649-1.1-8.861-1.3-18.411-8.9-26.977-25.7-29.045-60.1-12.406-69-1.477-8.2 10.043-12.1 23.433-9.7 36.036 1.6 8.566 5.8 16.442 9.5 24.319 3.9 8.467 1.8 20.774-.7 29.537"
            />
            <path
                fill="#FCB47F"
                d="M913.647 623.5c.2 5.809 2.5 11.421 5.7 16.246 1 1.476 2.1 2.953 2.2 4.725.1 1.773-1 3.643-2.6 4.53-2.5 1.28-5 1.87-6.9 2.166"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M913.647 623.5c.2 5.809 2.5 11.421 5.7 16.246 1 1.476 2.1 2.953 2.2 4.725.1 1.773-1 3.643-2.6 4.53-2.5 1.28-5 1.87-6.9 2.166"
            />
            <path
                stroke="url(#X)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M928.046 620.052c-2.5-1.182-6.2-1.772-8.8-2.068"
            />
            <path
                stroke="url(#Y)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M905.745 618.086c-6.4 0-13.1.689-17.3 2.461"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M922.646 635.021c.773 0 1.4-1.014 1.4-2.264 0-1.251-.627-2.265-1.4-2.265-.773 0-1.4 1.014-1.4 2.265 0 1.25.627 2.264 1.4 2.264ZM897.045 635.021c.773 0 1.4-1.014 1.4-2.264 0-1.251-.627-2.265-1.4-2.265-.774 0-1.4 1.014-1.4 2.265 0 1.25.626 2.264 1.4 2.264Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M918.044 658.746c-7.6 1.181-15.4.492-22.7-1.871M864.847 632.166c-1.9-1.576-3.8-3.25-6.2-4.136-2.3-.886-5.2-.886-7.1.69-2.1 1.673-2.6 4.726-2.5 7.482.5 8.271 5.9 16.147 13.5 19.593"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M934.545 838.624c-5-7.286-9.2-14.769-13.3-22.252-4.1-7.581-7.8-15.261-11.4-22.94-3.6-7.68-6.8-15.557-9.3-23.63-2.6-8.074-5-16.147-7.9-24.122"
            />
            <path
                fill="#FCB47F"
                d="M978.745 1151.43c.3 4.23-1.4 20.47-2.5 31.01-.6 5.02 3.3 9.55 8.5 9.85l20.295.98c4.3.2 8.6.98 12.7 2.56 4.3 1.58 11.2 3.54 20.3 4.04 4.1.19 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.24-.1-1.57-1.1-2.95-2.5-3.64-3.1-1.28-8.6-3.54-13-5.12-19.9-7.29-41.3-31.9-41.3-31.9"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M978.745 1151.43c.3 4.23-1.4 20.47-2.5 31.01-.6 5.02 3.3 9.55 8.5 9.85l20.295.98c4.3.2 8.6.98 12.7 2.56 4.3 1.58 11.2 3.54 20.3 4.04 4.1.19 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.24-.1-1.57-1.1-2.95-2.5-3.64-3.1-1.28-8.6-3.54-13-5.12-19.9-7.29-41.3-31.9-41.3-31.9"
            />
            <path
                fill="url(#Z)"
                d="M1065.85 1189.14c-2.9-1.19-8.5-3.55-13-5.12-7.9-2.86-16.2-8.67-23.2-14.58-2.9-2.46-5.6-4.92-8-7.08-4.7 1.37-11.7 9.74-18.4 9.84-13.102.1-22.302-5.71-26.402-6-.5 5.31-1.1 11.22-1.7 16.14-.3 2.76.5 5.42 2.3 7.49 1.8 2.06 4.3 3.34 7.1 3.44l20.302.99c4.3.19 8.5 1.08 12.4 2.46 4.3 1.57 11.3 3.54 20.6 4.03h1.1c5.1 0 18.1-1.18 25.3-1.97 2.7-.29 4.7-2.56 4.6-5.21.1-1.87-1.2-3.65-3-4.43Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M977.547 1189.72c1.8 2.07 4.3 3.35 7.1 3.45l20.303.98c4.3.2 8.5 1.09 12.4 2.46 4.3 1.58 11.3 3.55 20.6 4.04h1.1c5.1 0 18.1-1.18 25.3-1.97 2.7-.29 4.7-2.56 4.6-5.22"
            />
            <path
                fill="url(#aa)"
                d="M796.245 932.754c-5.2-41.451-5.2-61.93-5.2-81.72v-11.027c-6.5-23.532-10.1-49.131-10.7-76.207l-.5-18.805c-.4-15.359 6.4-30.227 18.5-39.58 25.7-19.888 49.6-27.47 49.6-27.47s12.4 13.194 32.3 21.366l24.5 18.411c1.5-5.415-1.5-23.531-1.5-23.531s-.2-3.249-.3-4.726c2.3 1.28 6.8 4.332 9.1 6.006 27.2 19.593 46.6 60.354 52.5 81.523 2.1 7.679 2.4 14.276.6 19.494-3 9.058-2.2 19.889 2.3 30.522 2.3 5.612 8.6 20.479 10.1 39.285 1.2 13.882-2.1 32.786-8.1 47.063l-.4.984-.9-.394c-5.7-2.559-29.7-7.285-62-7.285-31.6 0-76.1 4.627-108.4 26.682l-1.4.886-.1-1.477Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M906.645 721.758c1.8 1.772 7.3 11.913 13 23.236M848.043 678.238c7 7.975 22.3 17.92 32.2 21.267l24.6 18.707 8-19.199 7.3 6.498"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m880.244 699.506-9.4 12.898-21-22.252"
            />
            <path
                fill="url(#ab)"
                d="M954.245 994.682c2.7-.591 5.3-1.182 8-1.871 10.7-2.56 7-5.021 17.9-9.156 5.6-2.167 8.2-6.302 12.1-11.225 11.395-14.571 8.595-35.444-6.2-46.669l-.8-.59c-9.4-7.089-19.1-12.209-28.9-16.64-4.9-2.166-9.8-4.135-14.7-6.006-4.9-1.772-9.9-3.347-14.9-4.529-5-1.181-10.1-2.166-15.1-3.15-5.1-.886-10.1-1.871-15.2-2.462-2.5-.295-5.1-.689-7.6-.984-2.6-.296-5.1-.591-7.7-.788l-3.8-.295-3.9-.197c-2.6-.099-5.2-.197-7.8-.099l-9.7.296c-27.6.689-51.1 21.266-54.5 49.032-1.5 12.602 1.4 24.712 7.4 34.854 8.1 13.39 23 21.365 38.8 21.365h102.4l4.2-.886Z"
            />
            <path
                fill="#FCB47F"
                d="M980.245 1159.2c-2.5-8.27-4.9-16.64-7.1-24.91-1.1-4.13-2.3-8.36-3.4-12.5l-3.2-12.5c-2.2-8.37-4.2-16.64-6.6-25.01-2.3-8.37-5-16.64-7-25.01-4.2-16.64-4.8-33.57-4.8-50.61.1-8.47.5-17.031 1.4-25.597.2-2.166.5-4.234.8-6.4.3-2.166.6-4.332 1-6.399.7-4.333 1.7-8.566 2.9-12.898l3.1-10.535c4-13.784 18.6-21.759 32.6-17.821 11.395 3.249 18.895 13.39 19.095 24.516l1 50.904.5 25.4.4 25.4c.2 16.94.5 33.87.7 50.91l.5 50.9c.1 8.66-7 15.75-15.795 15.85-7.3.1-13.4-4.63-15.4-11.22l-.7-2.47Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M808.047 974.195c8.1 13.391 23.9 21.366 39.7 21.366h102.4l4.1-.886M986.147 925.757l-.8-.59c-9.4-7.089-19.1-12.209-28.9-16.64-4.9-2.166-9.8-4.135-14.7-6.006-4.9-1.772-9.9-3.347-14.9-4.529-5-1.181-10.1-2.166-15.1-3.15-5.1-.886-10.1-1.871-15.2-2.462-2.5-.295-5.1-.689-7.6-.984-2.6-.296-5.1-.591-7.7-.788l-3.8-.295-3.9-.197c-2.6-.099-5.2-.197-7.8-.099l-9.7.296M981.047 1161.56l-.7-2.36c-2.5-8.27-4.9-16.64-7.1-24.91-1.1-4.13-2.3-8.37-3.4-12.5l-3.2-12.51c-2.2-8.37-4.2-16.64-6.6-25.01-2.3-8.36-5-16.64-7-25-4.2-16.64-5.3-40.57-4.7-59.08M986.148 925.762c14.702 5.612 22.902 16.541 23.102 27.666l1 50.902.5 25.4.4 25.41c.2 16.93.5 33.86.7 50.9l.5 50.9"
            />
            <path
                fill="url(#ac)"
                d="M923.944 996.555h-76.1c-11.5 0-22.399-4.135-30.699-11.224-2.4-1.575-4.501-3.347-6.201-5.316-12.8-15.163-20.699-51.002-4.299-70.89 11.3-13.686 32.399-19.396 49.299-19.692 6.5-.196 14.9-.492 21.4 0 12.9.887 25.101 2.659 37.901 5.12 14.2 2.363 28.2 6.892 41.599 12.997 0 0-25.7 2.067-35 33.771-6 20.676 2.1 55.234 2.1 55.234Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M986.645 946.043h15.495"
            />
            <path
                fill="#FCB47F"
                d="m1043.42 674.105-1.3-1.2c.7-2.5-1.1-5.1-3-6.8-5.2-4.9-12.6-7.3-19.6-6.5-5.4.6-10.5 3-15.3 5.6-7.096 3.9-13.896 8.3-20.296 13.3-11.2 7.3-4.7 21.1 12.4 23.2 11.596 1.4 24.096-11.2 28.696-18.2l3.2.2c2.2.3 4.4.5 6.4 1.6 1.9 1 3.5 3 3.5 5.2 0 1.2-.5 2.3-.8 3.4-.2.8-.2 1.6 0 2.3.2.7.9 1.1 1.6 1.1 1.3-.1 2.5-.8 3.4-1.7.6-.6 1-1.3 1.3-2.1l.9.2c1 .3 2.2-.2 2.9-1 .7-.8 1.1-1.8 1.4-2.8 1.3-5.8-1.3-11.8-5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M996.027 701.107c12.803 2.4 27.403-13.9 30.303-19.9M983.926 678.407c6.4-5 13.2-9.401 20.304-13.301 4.8-2.6 9.8-4.999 15.3-5.599 7.1-.8 14.4 1.7 19.6 6.5 1.9 1.8 3.6 4.299 3 6.799"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1028.03 666.805c5.6 1.3 11.2 3.4 15.4 7.4 4.2 4 6.7 10 5.3 15.6-.3 1-.7 2.099-1.4 2.799-.7.8-1.9 1.2-2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1031.03 673.906c4 .3 7.9 2.4 10.4 5.6 2.5 3.2 3.5 7.5 2.8 11.5-.3 1.7-.9 3.3-2.1 4.5-.9 1-2.1 1.6-3.4 1.7-.7.1-1.4-.4-1.6-1.1-.2-.7-.2-1.5 0-2.3.3-1.1.8-2.2.8-3.4.1-2.2-1.5-4.2-3.5-5.2-1.9-1-4.2-1.3-6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M815.723 705.608c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.3 3.8 8.4 1.7 16.7 3.7 25.1 5.9 8.3 2.3 16.6 4.8 24.8 8 12 4.7 17.9 18.2 13.2 30.1-3.1 8.1-10.3 13.4-18.3 14.6-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5-17-1.9-29.3-17.2-27.4-34.2 1.9-17 17.2-29.3 34.2-27.4h.2Z"
            />
            <path
                fill="#FCB47F"
                d="m899.321 728.407 9.6-6.3 9.5-6.4c6.4-4.2 12.9-8.3 19.5-12.1 3.3-1.9 6.6-3.9 10-5.8 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.499 10.4-5.199 7.9-3.7 17.2-.301 20.9 7.599 2.899 6.2 1.399 13.3-3.1 17.8-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.801-9.2 6.799c-10 7.4-24.1 5.3-31.5-4.7-7.4-10-5.3-24.1 4.7-31.5.1-.2.5-.5.8-.7Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M866.023 768.206c6.076 0 11-3.94 11-8.8 0-4.86-4.924-8.801-11-8.801-6.075 0-11 3.941-11 8.801s4.925 8.8 11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M866.023 761.308c1.27 0 2.3-.851 2.3-1.9 0-1.05-1.03-1.9-2.3-1.9-1.271 0-2.3.85-2.3 1.9 0 1.049 1.029 1.9 2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M815.523 705.609h.2c8.5 1 17 1.501 25.5 2.301 8.5.7 16.9 1.999 25.3 3.799 8.4 1.7 16.7 3.7 25.1 5.9 4.6 1.3 9.2 2.6 13.7 4.1M925.123 765.309c-3.2 2.5-9.8 4.3-13.8 5-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.399-25.8.199-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5M908.824 722.107l9.5-6.399c6.4-4.2 12.9-8.301 19.5-12.101 3.3-1.9 6.6-3.899 10-5.799 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.5 10.4-5.2M996.221 707.008c-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.799-9.2 6.801"
            />
            <path
                fill="url(#ad)"
                d="M841.22 706.904c-2.6-.2-5.1-.4-7.7-.6-5.8-.5-11.9-.9-17.8-1.6h-.1c-1.2-.1-2.4-.2-3.6-.2-16.3 0-30 12.2-31.8 28.5-.9 8.5 1.5 16.8 6.8 23.5 5.3 6.7 13 10.9 21.5 11.8 5.9.7 11.9 1.5 17.7 2.4 2.5.4 5.1.7 7.6 1.1 1 .1 2.1.3 3.1.4l4.7-65.2c-.1-.1-.2-.1-.4-.1Z"
            />
            <path
                fill="url(#ae)"
                d="M839.522 766.505c.9-1.7 3-2.2 4.7-1.1l60.699 37.9c1.7 1.1 2.401 3.3 1.601 5l-16 31.3c-.9 1.7-3 2.2-4.7 1.1l-60.7-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.3Z"
            />
            <path
                fill="url(#af)"
                d="m909.021 800.404-60.7-37.899c-1.3-.8-2.7-.7-3.7 0l-4 2.899c1-.7 2.5-.8 3.7 0l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.2.5-.6.8-.9 1.1l4-2.9c.4-.3.7-.6.9-1.1l16-31.3c.8-1.6.1-3.9-1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m885.923 840.802-60.7-37.899c-1.7-1.1-2.4-3.3-1.6-5l16-31.301c.9-1.7 3-2.199 4.7-1.099l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m889.623 840.802 4-2.9c.4-.3.7-.6.9-1.1l16-31.299c.9-1.7.2-3.9-1.6-5l-60.7-37.901c-1.3-.8-2.7-.7-3.7 0l-4 2.901c1-.7 2.5-.8 3.7 0l60.7 37.899c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                fill="#231F20"
                d="M829.823 800.704a2.101 2.101 0 1 0-.002-4.202 2.101 2.101 0 0 0 .002 4.202Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M833.522 794.005a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M856.489 801.682c2.2-1.27 2.775-4.394 1.284-6.977-1.492-2.582-4.484-3.646-6.684-2.376s-2.775 4.394-1.284 6.976c1.492 2.583 4.484 3.647 6.684 2.377Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M836.123 804.606a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1002.12 824.803-8.598.299c-2.9.1-5.8.201-8.6.301-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.601-17.4-1.101-5.8-.5-11.6-1.1-17.4-2-8.7-1.3-14.6-9.3-13.4-18 .9-6.4 5.6-11.299 11.4-12.899 5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.501 8.6-.801l8.6-.799c12.398-1.2 23.398 7.8 24.598 20.2 1.2 12.4-7.8 23.399-20.2 24.599-.6.2-1.2.201-1.7.201Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1011.62 781.305c5.5 5 11.6 11.799 12.3 18.799 1.2 12.4-7.8 23.401-20.2 24.601-.5 0-1 .1-1.5.1l-8.597.299c-2.9.1-5.8.201-8.6.301-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.601-17.4-1.101-5.8-.5-11.6-1.1-17.4-2M930.723 791.302c5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.5 8.6-.8l8.6-.8"
            />
            <path
                fill="#FCB47F"
                d="M872.022 836.902c.3-.6.6-1.3.9-1.9l-.8-.9c-.9-1-1.3-2.3-1.3-3.6.1-3.7.9-8.1 2.1-11.2l-1.5-.9c-3.5-1.7-6.4-5-6.7-8.8-.3-4.2 2.4-8 5.4-11 5.8-5.8 12.7-13.3 20.9-12.5 10.6 1 21.1 2.7 31.5 5.1 19.5 4.1 23.7 19.4 11.3 30.2-5.5 5-14.3 6.1-20.9 2.7-5.6 5.2-13.3 13.1-16.8 20.7-.8 1.8-2.8 2.7-4.6 2h-.1c-2-.7-3-2.8-2.3-4.7 3.3-9.6 11.1-19.7 17.3-27.1-9.1 7.7-20.2 17.6-29.5 25.6-1.2 1-2.9 1-4.1 0-1.1-1-1.4-2.5-.8-3.7Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M926.122 792.102c-10.4-2.3-24.5-5-35.1-6-8.2-.8-15.1 6.7-20.9 12.5-3 3-5.7 6.8-5.4 11 .3 3.8 3.2 7.1 6.7 8.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M891.722 799.602c-5.9 4-11.9 8.2-15.9 14.1-3.4 5-5.1 11.2-4.9 17.2M896.423 808.902c-4.8 3.9-9.6 7.7-13.7 12.2-3.6 3.9-8.3 10.3-10.7 15.8-.5 1.2-.2 2.7.8 3.6 1.2 1 2.9 1 4.1 0 9.3-8 20.4-17.9 29.5-25.6-6.3 7.4-14 17.5-17.3 27.1-.7 1.9.4 4.1 2.3 4.7h.1c1.8.6 3.8-.3 4.6-2.1 3.4-7.5 11.1-15.4 16.8-20.6 6.6 3.4 15.5 2.3 20.9-2.7M882.022 803.504c-1.7 1.1-3.4 2.1-5.2 3.2-.7.5-1.5 1-1.8 1.8-.2.6 0 1.3.3 1.8.3.6.8 1 1.2 1.5"
            />
            <path
                fill="url(#ag)"
                d="M851.189 654.884c-.6-1-1-1.9-1.3-2.5-.6-1.2-1.1-2.5-1.7-3.7-3.1-6.7-6.3-13.5-7.7-20.8-2.3-12.2 1.3-25.6 9.5-35.8 8.2-10.2 34.5-22.7 57.2-7.6 1.2 2.9 1.1 5.5-.6 5.9-4.3 1-17-6.2-27.1-4.5-2.4.4-5.1 1.4-6.4 3.7-3.6 6.5 7.4 14.8 5.8 23.5-.5 2.5-1.6 4.9-2.8 7.2-4.3 6.6-2.5 8.6-2.5 17.3h-5.3c-1.1-3.5-4.2-7-7.8-8.5-3.6-1.6-7.5-2.2-9.9.9-2.5 3.3-1.4 9.5.1 13.4 1.6 4.3 5.6 9 10 12-.1 1.7-.2 3.3-.4 4.9-.4 3.7-5.7 4.2-6.8.6-.8-2.6-1.6-4.7-2.3-6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M865.094 632.283c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <defs>
                <linearGradient
                    id="b"
                    x1={798.988}
                    x2={1033.19}
                    y1={1030.28}
                    y2={1030.28}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.005} stopColor="#C2C9CF" />
                    <stop offset={0.533} stopColor="#ECF3F9" />
                    <stop offset={0.763} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1002.54}
                    x2={1002.54}
                    y1={1132.72}
                    y2={924.396}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.374} stopColor="#C2C9CF" />
                    <stop offset={0.508} stopColor="#C2C9CF" />
                    <stop offset={0.604} stopColor="#C2C9CF" />
                    <stop offset={0.682} stopColor="#C2C9CF" />
                    <stop offset={0.748} stopColor="#C2C9CF" />
                    <stop offset={0.807} stopColor="#C2C9CF" />
                    <stop offset={0.86} stopColor="#C2C9CF" />
                    <stop offset={0.908} stopColor="#ECF3F9" />
                    <stop offset={0.95} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={787.538}
                    x2={787.538}
                    y1={953.354}
                    y2={773.693}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.099} stopColor="#C2C9CF" />
                    <stop offset={0.283} stopColor="#C2C9CF" />
                    <stop offset={0.532} stopColor="#C2C9CF" />
                    <stop offset={0.833} stopColor="#ECF3F9" />
                    <stop offset={0.886} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={903.739}
                    x2={903.739}
                    y1={1154.95}
                    y2={993.505}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.097} stopColor="#C2C9CF" />
                    <stop offset={0.273} stopColor="#C2C9CF" />
                    <stop offset={0.509} stopColor="#C2C9CF" />
                    <stop offset={0.793} stopColor="#ECF3F9" />
                    <stop offset={0.881} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={735.739}
                    x2={735.739}
                    y1={12785.4}
                    y2={12785.4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.374} stopColor="#C2C9CF" />
                    <stop offset={0.508} stopColor="#C2C9CF" />
                    <stop offset={0.604} stopColor="#C2C9CF" />
                    <stop offset={0.682} stopColor="#C2C9CF" />
                    <stop offset={0.748} stopColor="#C2C9CF" />
                    <stop offset={0.807} stopColor="#C2C9CF" />
                    <stop offset={0.86} stopColor="#C2C9CF" />
                    <stop offset={0.908} stopColor="#ECF3F9" />
                    <stop offset={0.95} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={704.988}
                    x2={704.988}
                    y1={1234.36}
                    y2={969.687}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.007} stopColor="#C2C9CF" />
                    <stop offset={0.678} stopColor="#ECF3F9" />
                    <stop offset={0.971} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={2030.91}
                    x2={2030.91}
                    y1={1045.09}
                    y2={911.523}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.631} stopColor="#C2C9CF" />
                    <stop offset={1} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1930.24}
                    x2={2071.71}
                    y1={987.963}
                    y2={932.384}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.005} stopColor="#C2C9CF" />
                    <stop offset={0.533} stopColor="#ECF3F9" />
                    <stop offset={0.763} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1195.02}
                    x2={1195.02}
                    y1={1045.09}
                    y2={911.523}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.631} stopColor="#C2C9CF" />
                    <stop offset={1} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={1295.7}
                    x2={1154.22}
                    y1={987.963}
                    y2={932.384}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.005} stopColor="#C2C9CF" />
                    <stop offset={0.533} stopColor="#ECF3F9" />
                    <stop offset={0.763} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={1612.95}
                    x2={1612.95}
                    y1={1080.71}
                    y2={968.23}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.097} stopColor="#C2C9CF" />
                    <stop offset={0.273} stopColor="#C2C9CF" />
                    <stop offset={0.509} stopColor="#C2C9CF" />
                    <stop offset={0.793} stopColor="#ECF3F9" />
                    <stop offset={0.881} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={1361}
                    x2={1361}
                    y1={966.921}
                    y2={781.493}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.099} stopColor="#C2C9CF" />
                    <stop offset={0.283} stopColor="#C2C9CF" />
                    <stop offset={0.532} stopColor="#C2C9CF" />
                    <stop offset={0.833} stopColor="#ECF3F9" />
                    <stop offset={0.886} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={1612.95}
                    x2={1612.95}
                    y1={966.921}
                    y2={781.493}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.099} stopColor="#C2C9CF" />
                    <stop offset={0.283} stopColor="#C2C9CF" />
                    <stop offset={0.532} stopColor="#C2C9CF" />
                    <stop offset={0.833} stopColor="#ECF3F9" />
                    <stop offset={0.886} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={1864.91}
                    x2={1864.91}
                    y1={966.921}
                    y2={781.493}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2C9CF" />
                    <stop offset={0.099} stopColor="#C2C9CF" />
                    <stop offset={0.283} stopColor="#C2C9CF" />
                    <stop offset={0.532} stopColor="#C2C9CF" />
                    <stop offset={0.833} stopColor="#ECF3F9" />
                    <stop offset={0.886} stopColor="#ECF3F9" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={527.414}
                    x2={704.695}
                    y1={795.556}
                    y2={795.556}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={684.689}
                    x2={782.173}
                    y1={1171.77}
                    y2={1171.77}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={603.75}
                    x2={610.869}
                    y1={503.238}
                    y2={479.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.184} stopColor="#CB865E" />
                    <stop offset={0.409} stopColor="#C7835C" />
                    <stop offset={0.653} stopColor="#BA7B57" />
                    <stop offset={0.905} stopColor="#A46E4F" />
                    <stop offset={0.999} stopColor="#9A684B" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={642.816}
                    x2={642.816}
                    y1={490.946}
                    y2={423.749}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#CB865E" />
                    <stop offset={0.325} stopColor="#C7835C" />
                    <stop offset={0.604} stopColor="#BA7B57" />
                    <stop offset={0.891} stopColor="#A46E4F" />
                    <stop offset={0.999} stopColor="#9A684B" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={653.254}
                    x2={639.466}
                    y1={425.258}
                    y2={425.258}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={630.945}
                    x2={608.608}
                    y1={425.471}
                    y2={425.471}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={625}
                    x2={661.575}
                    y1={515.032}
                    y2={515.032}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={608.075}
                    x2={774.223}
                    y1={553.646}
                    y2={553.646}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={625}
                    x2={661.575}
                    y1={515.032}
                    y2={515.032}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={608.075}
                    x2={774.223}
                    y1={553.646}
                    y2={553.646}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={643.873}
                    x2={305.063}
                    y1={985.001}
                    y2={669.055}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={501.461}
                    x2={696.524}
                    y1={634.189}
                    y2={634.189}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#0197D6" />
                    <stop offset={0.558} stopColor="#22ADE0" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={625.336}
                    x2={704.692}
                    y1={622.92}
                    y2={622.92}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={552.916}
                    x2={720.121}
                    y1={728.825}
                    y2={637.09}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={576.237}
                    x2={479.947}
                    y1={693.828}
                    y2={477.828}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1727.22}
                    x2={1642.8}
                    y1={1180.64}
                    y2={1180.64}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="F"
                    x1={1841.43}
                    x2={1779.35}
                    y1={665.225}
                    y2={657.74}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="G"
                    x1={1830.05}
                    x2={1776.44}
                    y1={662.528}
                    y2={656.064}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1816.61}
                    x2={1866.92}
                    y1={705.018}
                    y2={793.933}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1809.56}
                    x2={1859.87}
                    y1={709.011}
                    y2={797.926}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1961.22}
                    x2={1772.76}
                    y1={813.828}
                    y2={813.828}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1770.23}
                    x2={1685.81}
                    y1={1180.93}
                    y2={1180.93}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1870.09}
                    x2={1857.06}
                    y1={697.386}
                    y2={675.694}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.216} stopColor="#FCB47F" />
                    <stop offset={0.433} stopColor="#F8B07C" />
                    <stop offset={0.667} stopColor="#EBA473" />
                    <stop offset={0.909} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1830.13}
                    x2={1830.13}
                    y1={680.377}
                    y2={616.496}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1737.27}
                    x2={1949.77}
                    y1={923.709}
                    y2={1072.47}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0B86B8" />
                    <stop offset={1} stopColor="#004F71" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1931.48}
                    x2={1911.53}
                    y1={717.902}
                    y2={777.758}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="R"
                    x1={900.529}
                    x2={941.823}
                    y1={708.996}
                    y2={708.996}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={886.426}
                    x2={1047.1}
                    y1={746.628}
                    y2={746.628}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={770.904}
                    x2={1067.25}
                    y1={952.409}
                    y2={952.409}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.453} stopColor="#E19A6D" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={920.164}
                    x2={920.164}
                    y1={683.723}
                    y2={617.562}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="W"
                    x1={878.81}
                    x2={888.811}
                    y1={689.519}
                    y2={672.214}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="X"
                    x1={930.568}
                    x2={916.781}
                    y1={619.042}
                    y2={619.042}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="Y"
                    x1={908.26}
                    x2={885.923}
                    y1={619.255}
                    y2={619.255}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="Z"
                    x1={975.135}
                    x2={1069.02}
                    y1={1181.57}
                    y2={1181.57}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1={779.881}
                    x2={977.802}
                    y1={806.228}
                    y2={806.228}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="ab"
                    x1={973.55}
                    x2={658.966}
                    y1={964.349}
                    y2={899.724}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ac"
                    x1={953.558}
                    x2={767.088}
                    y1={918.577}
                    y2={985.548}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="ad"
                    x1={853.265}
                    x2={783.868}
                    y1={632.928}
                    y2={816.831}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="ae"
                    x1={823.199}
                    x2={906.898}
                    y1={803.1}
                    y2={803.1}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="af"
                    x1={840.484}
                    x2={910.922}
                    y1={801.386}
                    y2={801.386}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1={907.558}
                    x2={837.659}
                    y1={556.945}
                    y2={649.704}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.023} stopColor="#E5BC56" stopOpacity={0.805} />
                    <stop offset={0.241} stopColor="#E7C26C" stopOpacity={0.692} />
                    <stop offset={0.694} stopColor="#EED1A5" stopOpacity={0.457} />
                    <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
                </linearGradient>
                <radialGradient
                    id="L"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-159.078 1001.308 218.001) scale(77.211 37.1881)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.437} stopColor="#64CCC9" stopOpacity={0} />
                    <stop offset={0.554} stopColor="#64CCC9" stopOpacity={0.258} />
                    <stop offset={0.746} stopColor="#64CCC9" stopOpacity={0.655} />
                    <stop offset={0.882} stopColor="#64CCC9" stopOpacity={0.904} />
                    <stop offset={0.949} stopColor="#64CCC9" />
                </radialGradient>
                <radialGradient
                    id="T"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(255.204 0 0 251.268 820.761 944.184)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <clipPath id="a">
                    <path fill="#fff" d="M1017 302h627v380h-627z" />
                </clipPath>
                <filter
                    id="K"
                    width={91.356}
                    height={77.894}
                    x={1812.73}
                    y={747.859}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_16235"
                        stdDeviation={4}
                    />
                </filter>
            </defs>
        </svg>
    );
}
