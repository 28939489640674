import { IBodyClothes } from 'interfaces';
import Shirt1 from './female-34-25-shirt-1';
import Shirt1Sitting from './female-34-25-shirt-1-sitting';

import Shirt1ArmRightOpt1 from './female-34-25-shirt-1-arm-opt-1-right';
import Shirt1ArmLeftOpt1 from './female-34-25-shirt-1-arm-opt-1-left';
import Shirt1ArmRightOpt2 from './female-34-25-shirt-1-arm-opt-2-right';
import Shirt1ArmLeftOpt2 from './female-34-25-shirt-1-arm-opt-2-left';
import Shirt1ArmRightOpt3 from './female-34-25-shirt-1-arm-opt-3-right';
import Shirt1ArmLeftOpt3 from './female-34-25-shirt-1-arm-opt-3-left';
import Shirt1ArmRightOpt4 from './female-34-25-shirt-1-arm-opt-4-right';
import Shirt1ArmLeftOpt4 from './female-34-25-shirt-1-arm-opt-4-left';
import Shirt1ArmRightOpt5 from './female-34-25-shirt-1-arm-opt-5-right';
import Shirt1ArmLeftOpt5 from './female-34-25-shirt-1-arm-opt-5-left';
import Shirt1ArmRightOpt6 from './female-34-25-shirt-1-arm-opt-6-right';
import Shirt1ArmLeftOpt6 from './female-34-25-shirt-1-arm-opt-6-left';
import Shirt1ArmRightOpt7 from './female-34-25-shirt-1-arm-opt-7-right';
import Shirt1ArmLeftOpt7 from './female-34-25-shirt-1-arm-opt-7-left';
import Shirt1ArmRightOpt8 from './female-34-25-shirt-1-arm-opt-8-right';
import Shirt1ArmLeftOpt8 from './female-34-25-shirt-1-arm-opt-8-left';
import Shirt1ArmRightOpt9 from './female-34-25-shirt-1-arm-opt-9-right';
import Shirt1ArmLeftOpt9 from './female-34-25-shirt-1-arm-opt-9-left';
import Shirt1ArmLeftOpt10 from './female-34-25-shirt-1-arm-opt-10-left';

import ArmsLeftOpt1 from './female-34-25-shirt-1-arms-opt-1-left';
import ArmsRightOpt1 from './female-34-25-shirt-1-arms-opt-1-right';
import ArmsLeftOpt2 from './female-34-25-shirt-1-arms-opt-2-left';
import ArmsRightOpt2 from './female-34-25-shirt-1-arms-opt-2-right';

const shirt1: IBodyClothes = {
    text: 'Shirt #1',
    normal: {
        front: Shirt1,
        rightArm: {
            option1: Shirt1ArmRightOpt1,
            option2: Shirt1ArmRightOpt2,
            option3: Shirt1ArmRightOpt3,
            option4: Shirt1ArmRightOpt4,
            option5: Shirt1ArmRightOpt5,
            option6: Shirt1ArmRightOpt6,
            option7: Shirt1ArmRightOpt7,
            option8: Shirt1ArmRightOpt8,
            option9: Shirt1ArmRightOpt9,
        },
        leftArm: {
            option1: Shirt1ArmLeftOpt1,
            option2: Shirt1ArmLeftOpt2,
            option3: Shirt1ArmLeftOpt3,
            option4: Shirt1ArmLeftOpt4,
            option5: Shirt1ArmLeftOpt5,
            option6: Shirt1ArmLeftOpt6,
            option7: Shirt1ArmLeftOpt7,
            option8: Shirt1ArmLeftOpt8,
            option9: Shirt1ArmLeftOpt9,
            option10: Shirt1ArmLeftOpt10,
        },
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
    sitting: {
        front: Shirt1Sitting,
        rightArm: {
            option1: Shirt1ArmRightOpt1,
            option2: Shirt1ArmRightOpt2,
            option3: Shirt1ArmRightOpt3,
            option4: Shirt1ArmRightOpt4,
            option5: Shirt1ArmRightOpt5,
            option6: Shirt1ArmRightOpt6,
            option7: Shirt1ArmRightOpt7,
            option8: Shirt1ArmRightOpt8,
            option9: Shirt1ArmRightOpt9,
        },
        leftArm: {
            option1: Shirt1ArmLeftOpt1,
            option2: Shirt1ArmLeftOpt2,
            option3: Shirt1ArmLeftOpt3,
            option4: Shirt1ArmLeftOpt4,
            option5: Shirt1ArmLeftOpt5,
            option6: Shirt1ArmLeftOpt6,
            option7: Shirt1ArmLeftOpt7,
            option8: Shirt1ArmLeftOpt8,
            option9: Shirt1ArmLeftOpt9,
            option10: Shirt1ArmLeftOpt10,
        },
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
};

export default shirt1;
