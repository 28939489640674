import { IBodyClothes } from 'interfaces';

import Shirt1 from './man-34-25-shirt-1';
import Shirt1Sitting from './man-34-25-shirt-1-sitting';

import Shirt1RightArmDown from './man-34-25-shirt-1-arm-right-down';
import Shirt1RightArmOption1 from './man-34-25-shirt-1-arm-right-option-1';
import Shirt1RightArmOption2 from './man-34-25-shirt-1-arm-right-option-2';
import Shirt1RightArmOption3 from './man-34-25-shirt-1-arm-right-option-3';
import Shirt1RightArmOption4 from './man-34-25-shirt-1-arm-right-option-4';
import Shirt1RightArmOption5 from './man-34-25-shirt-1-arm-right-option-5';
import Shirt1RightArmOption6 from './man-34-25-shirt-1-arm-right-option-6';
import Shirt1RightArmOption7 from './man-34-25-shirt-1-arm-right-option-7';

import Shirt1LeftArmDown from './man-34-25-shirt-1-arm-left-down';
import Shirt1LeftArmUp from './man-34-25-shirt-1-arm-left-up';
import Shirt1LeftArmOption1 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-1';
import Shirt1LeftArmOption2 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-2';
import Shirt1LeftArmOption3 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-3';
import Shirt1LeftArmOption4 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-4';
import Shirt1LeftArmOption5 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-5';
import Shirt1LeftArmOption6 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-6';
import Shirt1LeftArmOption7 from '../doctors-coat/man-34-25-doctors-coat-arm-left-option-7';

import Shirt1BothArmsOption1Right from './man-34-25-shirt-1-both-arms-option-1-right';
import Shirt1BothArmsOption1Left from './man-34-25-shirt-1-both-arms-option-1-left';
import Shirt1BothArmsOption2Right from '../doctors-coat/man-34-25-doctors-coat-both-arms-option-2-right';
import Shirt1BothArmsOption2Left from '../doctors-coat/man-34-25-doctors-coat-both-arms-option-2-left';
import Shirt1BothArmsOption3Right from '../doctors-coat/man-34-25-doctors-coat-both-arms-option-3-right';
import Shirt1BothArmsOption3Left from '../doctors-coat/man-34-25-doctors-coat-both-arms-option-3-left';


const shirt1: IBodyClothes = {
    text: 'Shirt #1',
    normal: {
        front: Shirt1,
        rightArm: {
            down: Shirt1RightArmDown,
            option1: Shirt1RightArmOption1,
            option2: Shirt1RightArmOption2,
            option3: Shirt1RightArmOption3,
            option4: Shirt1RightArmOption4,
            option5: Shirt1RightArmOption5,
            option6: Shirt1RightArmOption6,
            option7: Shirt1RightArmOption7,
        },
        leftArm: {
            down: Shirt1LeftArmDown,
            up: Shirt1LeftArmUp,
            option1: Shirt1LeftArmOption1,
            option2: Shirt1LeftArmOption2,
            option3: Shirt1LeftArmOption3,
            option4: Shirt1LeftArmOption4,
            option5: Shirt1LeftArmOption5,
            option6: Shirt1LeftArmOption6,
            option7: Shirt1LeftArmOption7,
        },
        bothArms: {
            option1: [Shirt1BothArmsOption1Right, Shirt1BothArmsOption1Left],
            option2: [Shirt1BothArmsOption2Right, Shirt1BothArmsOption2Left],
            option3: [Shirt1BothArmsOption3Right, Shirt1BothArmsOption3Left],
        },
    },
    sitting: {
        front: Shirt1Sitting,
        rightArm: {
            down: Shirt1RightArmDown,
            option1: Shirt1RightArmOption1,
            option2: Shirt1RightArmOption2,
            option3: Shirt1RightArmOption3,
            option4: Shirt1RightArmOption4,
            option5: Shirt1RightArmOption5,
            option6: Shirt1RightArmOption6,
            option7: Shirt1RightArmOption7,
        },
        leftArm: {
            down: Shirt1LeftArmDown,
            up: Shirt1LeftArmUp,
            option1: Shirt1LeftArmOption1,
            option2: Shirt1LeftArmOption2,
            option3: Shirt1LeftArmOption3,
            option4: Shirt1LeftArmOption4,
            option5: Shirt1LeftArmOption5,
            option6: Shirt1LeftArmOption6,
            option7: Shirt1LeftArmOption7,
        },
        bothArms: {
            option1: [Shirt1BothArmsOption1Right, Shirt1BothArmsOption1Left],
            option2: [Shirt1BothArmsOption2Right, Shirt1BothArmsOption2Left],
            option3: [Shirt1BothArmsOption3Right, Shirt1BothArmsOption3Left],
        },
    }
};

export default shirt1;
