import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M402.465 354.198a19.367 19.367 0 0 1-15.455-7.59l-.004-.003a20.436 20.436 0 0 1-5.465-7.1 228.514 228.514 0 0 1-8.711-22.543c-2.406-7.232-4.621-14.697-6.772-22.825-2.132-7.808-4.21-15.506-5.252-23.102-.325-2.326-2.655-17.875-3.32-23.306-1.897-15.506 8.006-29.299 22.954-31.849a27.825 27.825 0 0 1 4.655-.396 27.42 27.42 0 0 1 27.077 22.881l.024.138c.887 5.37 4.073 20.975 4.522 23.268a180.477 180.477 0 0 1 3.023 23.355c.29 3.912.527 7.931.707 11.939a262.77 262.77 0 0 1 11.236-7.306 381.32 381.32 0 0 0 3.467-2.13c2.397-1.481 4.872-3.01 7.331-4.417l5.431-3.182a192.84 192.84 0 0 1 3.576-2.018s1.357-.756 1.946-1.088c3.139-1.743 7.338-4.049 12.039-6.452l1.64-.838-.189 1.832c-1.013 9.766 7.411 20.799 13.655 24.104l1.217.644-.989.958a438.57 438.57 0 0 1-7.552 7.127c-.552.505-1.098 1.009-1.644 1.513-1.009.932-2.014 1.86-3.035 2.765l-4.73 4.174c-2.123 1.912-4.318 3.758-6.441 5.543a508.83 508.83 0 0 0-3.142 2.658 262.31 262.31 0 0 1-20 15.366l-10.355 7.112-10.26 7.219a19.338 19.338 0 0 1-11.184 3.549Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-5-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-5-right-a"
                x1={348.181}
                y1={254.985}
                x2={456.181}
                y2={323.949}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
