import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M494.768 222.602a109.64 109.64 0 0 0-20.026-15.329 31.037 31.037 0 0 1 2.394 13.895 65.926 65.926 0 0 1-1.451 10.763l2.169 4.754a1.002 1.002 0 0 0 1.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 0 0-.821-2.483Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-1-left-a)"
        />
        <path
            d="M532.036 412.778c-6.06-8.26-11.323-16.682-16.414-24.826l-.128-.205c-10.564-16.788-16.214-29.272-23.368-45.077-1.184-2.617-2.405-5.316-3.692-8.128-.118-.254-.22-.516-.321-.78l-.06-.155c-10.787-15.509-23.594-41.841-28.623-58.828-.621-2.074-1.228-4.158-1.836-6.241-1.377-4.727-2.802-9.614-4.39-14.348a27.304 27.304 0 0 1 1.453-20.998 27.315 27.315 0 0 1 15.874-13.82 27.474 27.474 0 0 1 34.817 17.328c1.615 4.813 3.156 8.936 4.647 12.925 4.735 12.668 8.824 23.608 12.814 49.694 1.244 8.318 2.634 14.902 2.89 21.056a380.327 380.327 0 0 1 31.725 79.299l.209.777-25.089 13.018-.508-.691Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-1-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-1-left-a"
                x1={480.642}
                y1={223.429}
                x2={503.141}
                y2={248.214}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-1-left-b"
                x1={459.682}
                y1={219.112}
                x2={576.275}
                y2={433.452}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
