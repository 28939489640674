import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#001489"
            d="M586.457 387.665c-17.918-8.617-38.901-14.118-60.267-16.65-26.948-4.109-52.664 9.529-61.917 35.508-11.186 32.376 9.097 65.034 42.303 71.702h41.653s7.466-23.113 17.551-47.728c12.758-31.141 20.677-42.832 20.677-42.832z"
        ></path>
    </g>
);

export default SvgComponent;
