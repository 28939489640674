import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.499 279.909c-20.41-24.152-26.326-32.893-31.649-46.77-2.986-7.787-9.421-18.228-14.918-23.949a47.493 47.493 0 0 0-4.724-2.974c4.064 7.051 4.949 18.802-6.423 23.57a22.007 22.007 0 0 1-8.678 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.282-4.644-14.811-9.445-22.911-12.429a32.69 32.69 0 0 0-10.801-1.712 31.455 31.455 0 0 0-4.391.292c-.16.022-.314.077-.452.162a62.748 62.748 0 0 0-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.795-6.375 34.828-20.638 21.97-25.615 45.362-27.937 63.293 14.883 0 179.195.874 179.225.713 9.23-48.617-.85-76.582-9.313-98.686-.944-2.465-1.843-4.812-2.685-7.11 8.093-3.299 18.17-9.26 20.658-19.014 1.942-7.615-1.083-16.221-8.991-25.579Z"
            fill="url(#female-34-25-tshirt-5-a)"
        />
        <g filter="url(#female-34-25-tshirt-5-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.209 1.264 14.814 1.029 21.605-1.698a31.508 31.508 0 0 0 18.534-21.325 31.507 31.507 0 0 0-6.485-27.499l-6.917-10.636"
                fill="url(#female-34-25-tshirt-5-c)"
            />
        </g>
        <defs>
            <radialGradient
                id="female-34-25-tshirt-5-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(72.12061 -27.5712 13.27944 34.7363 447.988 282.397)"
            >
                <stop offset={0.437} stopColor="#888B8D" stopOpacity={0} />
                <stop offset={0.669} stopColor="#888B8D" stopOpacity={0.501} />
                <stop offset={0.857} stopColor="#888B8D" stopOpacity={0.861} />
                <stop offset={0.949} stopColor="#888B8D" />
            </radialGradient>
            <linearGradient
                id="female-34-25-tshirt-5-a"
                x1={345.676}
                y1={336.548}
                x2={533.261}
                y2={303.675}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <filter
                id="female-34-25-tshirt-5-b"
                x={402.73}
                y={251.023}
                width={91.305}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_10320_8822"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
