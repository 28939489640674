import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11313_6397)"
            d="M524.499 279.902c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.777 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.748 62.748 0 00-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.875 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 01-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
        ></path>
        <g filter="url(#filter0_f_11313_6397)">
            <path
                fill="url(#paint1_radial_11313_6397)"
                d="M410.73 290.876l5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0012.049-48.824l-6.917-10.636"
            ></path>
        </g>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M407.531 194.484c11.037 8.028 21.706 14.536 31.632 17.961l24.552 18.979 8-19.516 7.31 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M439.165 212.447l-9.445 13.073-20.923-22.629M465.219 234.469c3.321 4.311 6.916 9.661 10.237 13.972"
        ></path>
        <defs>
            <filter
                id="filter0_f_11313_6397"
                width="91.356"
                height="77.898"
                x="402.73"
                y="251.023"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11313_6397"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11313_6397"
                x1="345.022"
                x2="532.607"
                y1="332.812"
                y2="299.939"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11313_6397"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#004F71" stopOpacity="0"></stop>
                <stop offset="0.522" stopColor="#004F71" stopOpacity="0.183"></stop>
                <stop offset="0.813" stopColor="#004F71" stopOpacity="0.77"></stop>
                <stop offset="0.949" stopColor="#004F71"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
