import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={231}
            height={91}
            fill="none"
            id="object-food-kale"
            {...attributes}
        >
            <path
                fill="url(#object-food-kale-a)"
                d="M178.761 77c-9.7-3.068-25.509-14.632-31.779-16.578-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.42 7.648C2.276 49.182-2.346 17.947 7.646 8.878c6.44-5.845 19.978-3.417 21.98-3.032 10.628 2.048 12.236 7.44 19.704 6.821 8.65-.716 9.238-8.177 19.43-10.588C78.46-.215 87.263 4.33 90.45 5.975c11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.44 36.018 9.61 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.79-41.479 17.85-50.726 14.925l.001.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M29.627 5.848c10.63 2.048 12.237 7.44 19.705 6.821 8.65-.716 9.238-8.177 19.43-10.588C78.46-.213 87.264 4.332 90.45 5.977c11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.44 36.018 9.61 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.79-41.479 17.85-50.726 14.925-9.7-3.067-25.509-14.63-31.779-16.577-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.42 7.648C2.276 49.183-2.346 17.948 7.646 8.88"
            />
            <path
                fill="url(#object-food-kale-b)"
                d="M183.508 87.874c-10.144-.772-28.175-8.421-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1.001-38.427-2.697-22.983-3.01-45.907 9.007-68.93 6.31-11.106-1.301-25.217-10.336-25.217-18.61 0-8.276 10.233-10.95 17.569-12.71 7.336-1.76 15.149-2.242 21.758-5.879 6.2-3.413 10.807-9.344 17.236-12.303 7.255-3.339 15.763-2.394 23.458-.257 7.695 2.137 15.113 5.395 23.012 6.576 16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112h-.002Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M48.507 45.667c2.944-2.464 5.882-4.926 9.35-6.522 7.256-3.339 15.764-2.394 23.459-.257s15.113 5.395 23.012 6.576c16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112-10.144-.772-28.175-8.42-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1-38.427-2.697-22.983-3.01-45.907 9.007-68.93 6.31-11.106-1.3-25.217-10.336-25.217-18.61 0-8.276 10.233-10.95 17.569-12.71 4.497-1.079 9.175-1.678 13.644-2.818"
            />
            <defs>
                <linearGradient
                    id="object-food-kale-a"
                    x1={86.937}
                    x2={61.674}
                    y1={13.327}
                    y2={-19.767}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-food-kale-b"
                    x1={134.014}
                    x2={75.403}
                    y1={81.793}
                    y2={41.372}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
            </defs>
        </svg>
    );
}
