import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-2-right">
        <path
            d="M405.677 182.08c-2.56-.22-5.121-.424-7.683-.627-5.834-.463-11.865-.941-17.77-1.623l-.15-.017a32.695 32.695 0 0 0-3.553-.196 31.946 31.946 0 0 0-31.768 28.488 31.8 31.8 0 0 0 6.83 23.52 31.785 31.785 0 0 0 21.462 11.802c5.903.652 11.889 1.531 17.679 2.382 2.543.373 5.085.747 7.626 1.103 1.033.147 2.088.263 3.132.394l4.674-65.179c-.159-.013-.32-.034-.479-.047Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-2-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-2-right-a"
                x1={348.026}
                y1={205.702}
                x2={416.556}
                y2={217.413}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
