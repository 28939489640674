import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shirt-2-cycling-a)">
        <path
            d="M500.517 277.024a194.058 194.058 0 0 0-6.028-44.008h-.005a178.058 178.058 0 0 0-6.387-20.408c-8.133-17.24-15.405-27.862-19.072-31.626-2.484 2.533-12.145 16.844-12.145 16.844s-14.096-17.06-20.024-23.133c-7.084-5.421-19.301-14.53-26.892-23.783-12.274 2.71-25.895 9.476-36.846 18.678-59.239 49.646-79.937 169.527-79.611 231.985 41.638 23.096 92.127 41.404 125.566 38.06 45.542-4.554 63.832-36.266 66.867-72 1.9-22.357 1.268-44.267-1.555-60.281l-.045-.251c10.878-2.733 16.614-13.372 16.177-30.077Z"
            fill="url(#man-34-25-shirt-2-cycling-b)"
        />
        <path
            d="M408.088 151.664c-12.275 2.71-24.019 8.721-34.97 17.923-59.239 49.646-78.636 162.37-79.828 232.744 45.689 23.167 89.971 39.951 124.916 37.301 45.759-3.47 63.161-34.585 66.826-68.329 2.85-26.238 2.753-44.666-.647-63.952l-.045-.251c10.878-2.733 16.615-13.372 16.177-30.077a194.098 194.098 0 0 0-6.027-44.008h-.005a178.261 178.261 0 0 0-6.388-20.408"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M457.867 202.168c2.4 3.772 9.816 34.584 13.751 51.611a53.61 53.61 0 0 1 .434 22.063l-3.567 18.806M409.784 150.273c9.115 10.157 18.19 18.75 27.18 24.175l20.045 23.69 11.901-17.413 5.761 8.018"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m436.963 174.448-11.967 10.812-15.733-26.502M462.663 325.777c-.809 17.53-6.724 68.358-13.012 102.145"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-cycling-b"
                x1={293.503}
                y1={295.47}
                x2={500.54}
                y2={295.47}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="man-34-25-shirt-2-cycling-a">
                <path fill="#fff" transform="translate(.41 .672)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
