import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={5}>
        <path
            d="M412.069 251.207c-1.594 8.21-2.784 16.498-4.149 24.752a204.838 204.838 0 0 1-5.574 24.479c-2.289 8.077-4.783 16.114-7.562 24.098-2.808 7.977-5.812 15.917-9.47 23.731a19.085 19.085 0 0 1-25.378 9.194 19.126 19.126 0 0 1-10.96-16.161c-.509-8.614-.363-17.101-.021-25.552.371-8.444 1.026-16.834 1.887-25.185a204.695 204.695 0 0 1 3.875-24.805c1.785-8.173 3.745-16.313 5.3-24.531a26.504 26.504 0 0 1 20.628-21.013 26.494 26.494 0 0 1 19.928 3.773 26.516 26.516 0 0 1 7.379 7.243 26.514 26.514 0 0 1 4.14 19.856l-.023.121Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m368.45 325.111 12.155-.319 12.152-.462c8.102-.271 16.21-.133 24.322.415 4.057.288 8.111.474 12.169.871l6.086.532c2.029.182 4.058.432 6.087.644 8.117.903 16.236 1.972 24.362 3.6a12.271 12.271 0 0 1 9.62 14.442 12.284 12.284 0 0 1-9.32 9.556c-8.083 1.831-16.173 3.104-24.264 4.209-2.023.263-4.046.564-6.069.796l-6.071.685c-4.046.499-8.095.785-12.143 1.175a246.487 246.487 0 0 1-24.304 1.024l-12.16-.157-12.158-.015a18.504 18.504 0 0 1-18.48-18.523 18.522 18.522 0 0 1 18.016-18.473Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m412.098 251.086-.024.122c-1.594 8.21-2.784 16.497-4.149 24.752a205.06 205.06 0 0 1-5.574 24.478 438.655 438.655 0 0 1-6.394 20.689M348.976 341.301c-.509-8.614-.363-17.102-.021-25.552.371-8.444 1.026-16.835 1.887-25.185a204.695 204.695 0 0 1 3.875-24.805c1.785-8.173 3.745-16.314 5.3-24.531a37.292 37.292 0 0 1 5.289-12.642M389.853 324.44l2.904-.11a241.054 241.054 0 0 1 24.321.415c4.057.288 8.112.474 12.169.871l6.086.532c2.029.182 4.059.432 6.088.644 8.116.903 16.236 1.972 24.362 3.6 3.19.639 5.996 2.52 7.8 5.229a12.267 12.267 0 0 1-1.43 15.367 12.275 12.275 0 0 1-6.071 3.402c-8.082 1.831-16.172 3.104-24.264 4.209-2.023.263-4.045.564-6.069.796l-6.07.685c-4.047.499-8.096.785-12.144 1.175a246.474 246.474 0 0 1-24.304 1.024l-12.16-.157-12.158-.015c-10.218-.012-20.527-8.6-19.936-20.806"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m534.99 356.435-.533-.349.542-.163a2.516 2.516 0 0 0 1.688-2.176 2.721 2.721 0 0 0-1.638-2.463c-3.586-1.33-6.496-3.972-9.522-6.312-7.009-5.419-14.737-11.455-23.56-12.243l-33.757-3.016c-18.331-1.203-19.459 20.231-6.056 25.427 6.82 2.961 13.835 5.443 20.844 7.923 11.516 4.074 22.29 8.143 34.362 8.962a1.733 1.733 0 0 0 1.828-1.558l.004-.039a1.737 1.737 0 0 0-1.05-1.776 108.423 108.423 0 0 1-21.41-12.814l9.98 3.062c6.943 2.13 13.944 4.273 21.173 4.972a3.306 3.306 0 0 0 2.598-.621 2.984 2.984 0 0 0 1.017-2.604l-.092-.676 1.427.568a2.314 2.314 0 0 0 1.795-.024 2.317 2.317 0 0 0 1.243-1.297 2.327 2.327 0 0 0-.883-2.783Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m467.695 329.711 33.757 3.016c8.824.789 16.552 6.825 23.56 12.243 3.026 2.34 5.936 4.982 9.522 6.312M499.828 348.861a483.488 483.488 0 0 1 32.492 11.681 2.32 2.32 0 0 0 3.038-1.321 2.33 2.33 0 0 0-.881-2.783 125.156 125.156 0 0 0-31.678-14.985M461.641 355.141c6.819 2.961 13.835 5.443 20.843 7.922 11.516 4.074 22.29 8.143 34.363 8.963.446.026.886-.122 1.226-.413.341-.29.556-.701.601-1.146l.004-.038a1.731 1.731 0 0 0-1.05-1.777 108.423 108.423 0 0 1-21.41-12.814l9.98 3.062c6.944 2.131 13.944 4.273 21.174 4.972"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
