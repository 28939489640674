import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-4-cycling-2-right-a)">
        <path
            d="M312.366 782.568c-4.673-24.879-6.951-51.019-11.221-76.107-6.668-32.108-1.696-68.763 10.559-99.965l.023-.016-.01-.004.01-.021a8.054 8.054 0 0 0 .577-4.371c-8.041-52.888-16.204-106.001-18.786-159.537-2.529-52.445 7.654-69.22 18.529-79.485l155.947 47.204a76.273 76.273 0 0 1-29.974 28.572 97.978 97.978 0 0 0-26.336 19.691 58.536 58.536 0 0 0-15.938 35.222 400.582 400.582 0 0 1-8.5 53.765c-4.2 18.831-7.959 35.35-15.896 57.067-4.38 25.68-8.502 65.144-12.435 90.712-3.589 15.795-5.37 80.792-5.722 89.477-7.743-.478-40.827-2.204-40.827-2.204Z"
            fill="url(#man-34-25-pants-4-cycling-2-right-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-4-cycling-2-right-b"
                x1={293.128}
                y1={573.917}
                x2={467.994}
                y2={573.917}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <clipPath id="man-34-25-pants-4-cycling-2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .621)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
