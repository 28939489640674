import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={77}
            height={222}
            fill="none"
            id="object-food-water"
            {...attributes}
        >
            <path
                fill="#5BC2E7"
                d="M25.62 1.434h25.96a2 2 0 0 1 2 2v11.368H23.62V3.434a2 2 0 0 1 2-2Z"
            />
            <path
                fill="url(#object-food-water-a)"
                d="M11.668 38.66A46.61 46.61 0 0 0 1.68 67.486v46.3c0 1.234.46 2.425 1.296 3.335 6.182 6.73 6.182 17.141 0 23.872a4.929 4.929 0 0 0-1.296 3.335v64.066c0 6.876 5.574 12.45 12.45 12.45h48.937c6.876 0 12.45-5.574 12.45-12.45v-64.066a4.932 4.932 0 0 0-1.296-3.335c-6.181-6.73-6.181-17.141 0-23.872a4.929 4.929 0 0 0 1.296-3.335v-46.3a46.61 46.61 0 0 0-9.988-28.826H11.668Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M25.62 1.434h25.96a2 2 0 0 1 2 2v11.368H23.62V3.434a2 2 0 0 1 2-2Z"
            />
            <path fill="#5BC2E7" d="M75.518 67.486H1.68v38.767h73.838V67.486Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M18.281 202.188h40.633M18.281 186.146h40.633M18.281 170.105h40.633M18.281 154.064h40.633M75.519 165.943v-21.616a4.932 4.932 0 0 0-1.296-3.335c-6.182-6.73-6.182-17.141 0-23.871a4.929 4.929 0 0 0 1.296-3.335v-46.3a46.617 46.617 0 0 0-13.128-32.43L52.021 24.35a4.978 4.978 0 0 1-1.403-3.465V14.8H26.582v6.083a4.98 4.98 0 0 1-1.403 3.465l-3.347 3.456M11.664 38.656a46.62 46.62 0 0 0-9.984 28.83v46.3c0 1.234.46 2.425 1.296 3.335 6.182 6.73 6.182 17.141 0 23.871a4.929 4.929 0 0 0-1.296 3.335v64.066c0 6.876 5.574 12.45 12.45 12.45h48.938c6.876 0 12.45-5.574 12.45-12.45v-16.682"
            />
            <defs>
                <linearGradient
                    id="object-food-water-a"
                    x1={112.312}
                    x2={9.996}
                    y1={335.851}
                    y2={56.946}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
            </defs>
        </svg>
    );
}
