import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6779)"
            d="M494.767 223.4a2.699 2.699 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6779)"
            d="M559.937 277.9c-.028-.957-.084-1.917-.139-2.877-.031-.523-.062-1.047-.088-1.58a260.026 260.026 0 00-.261-3.977s-9.04.437-16.305-2.274c-9.913-3.699-13.312-9.001-13.312-9.001-.764 2.11-1.541 4.191-2.322 6.7a188.448 188.448 0 00-11.02-15.574 906.588 906.588 0 00-4.563-5.793c-3.477-4.395-7.142-8.976-10.513-13.558l-.077-.105a27.634 27.634 0 00-22.097-11.103 27.275 27.275 0 00-16.377 5.426 27.529 27.529 0 00-5.677 38.474c3.387 4.561 6.673 9.325 9.851 13.933 1.399 2.029 2.802 4.054 4.208 6.075a196.945 196.945 0 0015.319 19.263c5.797 6.538 11.33 12.43 16.917 18.014a248.354 248.354 0 0018.513 16.882c.402.324.842.645 1.38 1.006a19.288 19.288 0 0011.523 5.477 19.84 19.84 0 002.152.118 19.46 19.46 0 0019.36-17.372c.443-4.031 1.058-8.121 1.654-12.076.262-1.74.524-3.48.773-5.224a128.06 128.06 0 001.253-17.608c-.004-.954.004-1.906.011-2.858.016-1.954.032-3.975-.05-5.972l-.113-4.416z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6779"
                x1="472.01"
                x2="560.058"
                y1="214.166"
                y2="315.66"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6779"
                x1="465.687"
                x2="553.735"
                y1="219.652"
                y2="321.146"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
