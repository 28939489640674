import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m449.355 831.469 5.423 28.797 6.08.144s.112-2.428.364-6.78c.175-3.016 1.326-7.544 4.696-7.088"
                fill="#FFD30C"
            />
            <path
                d="m454.779 860.609 5.65.144"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M463.474 837.762c.868-8.458 4.209-17.097-2.317-26.97l31.458 6.444-1.24-3.71-34.603-7.315-6.98 20.331a13.897 13.897 0 0 0 7.286 17.142l5.553 2.588a18.823 18.823 0 0 1 3.932 2.385c-1.089-2.895-3.525-6.639-3.089-10.895ZM509.344 857.201a15.958 15.958 0 0 1-1.762-1.273l-.303-.248-.39.023a32.42 32.42 0 0 0-3.135.332 21.455 21.455 0 0 0-11.675 5.395 14.648 14.648 0 0 0-4.589 10.319l.002.636.577.267c1.077.509 2.19.937 3.329 1.282l5.266 1.56c2.496.738 3.773.434 6.445.658l.967.082.11-.965a14.637 14.637 0 0 1 5.375-9.771 14.47 14.47 0 0 1 5.635-2.669l2.363-.557-8.215-5.071Z"
                fill="#FFD30C"
            />
            <path
                d="M521.81 874.796c-1.13.951-4.783.973-6.335.972-2.832-.001-6.168.002-9 0-3.733-.002-11.398-.41-15.077-1.832-5.538-2.142-10.566-5.385-13.72-10.417l-5.546-8.849a22.324 22.324 0 0 0-9.5-8.393l-5.553-2.588a15.916 15.916 0 0 1-7.276-8.041"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m397.842 850.984 6.166 24.813 7.112.302-.651-10.953"
                fill="#FFD30C"
            />
            <path
                d="M404.008 875.797h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M402.568 825.969h30.654l-1.244-3.235h-34.273l-2.771 12.607a20.64 20.64 0 0 0 6.369 19.307 28.121 28.121 0 0 1 2.825 3.11l1.676 2.114.106-2.696c.093-2.337.362-4.734.621-7.051.511-4.545 1.038-9.245.297-13.886a27.733 27.733 0 0 0-4.26-10.27Z"
                fill="url(#female-34-25-shoes-10-stand-1-a)"
            />
            <path
                d="m446.216 852.568-.339-.021a66.846 66.846 0 0 0-13.664.537 34.585 34.585 0 0 0-9.828 2.673 18.262 18.262 0 0 0-7.917 6.683 17.987 17.987 0 0 0-2.258 5.822l-.095.436 1.525 2.108a15.037 15.037 0 0 0 12.214 6.062h1.075l-.008-1.008a12.32 12.32 0 0 1 4.338-9.397 16.277 16.277 0 0 1 6.887-3.136 51.42 51.42 0 0 1 7.891-.916l10.691-.684-10.512-9.159Z"
                fill="url(#female-34-25-shoes-10-stand-1-b)"
            />
            <path
                d="M457.577 874.31a9.33 9.33 0 0 1-6.503 2.561h-25.219a15.041 15.041 0 0 1-12.215-6.063s-15.174-19.201-16.206-20.933"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-10-stand-1-a"
                    x1={394.522}
                    y1={841.303}
                    x2={433.222}
                    y2={841.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-10-stand-1-b"
                    x1={412.115}
                    y1={864.641}
                    x2={456.729}
                    y2={864.641}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </g>
    );
}
