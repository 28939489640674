import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-1">
        <path
            d="m465.463 167.461 2.654.951c13.051 4.678 23.788 10.343 32.825 17.319a2.681 2.681 0 0 1 1.092 2.341c-.25 4.649-12.215 12.209-14.611 13.678l-.856.525-21.104-34.814Z"
            fill="url(#man-34-25-jacket-left-option-1-a)"
        />
        <path
            d="M505.531 285.237c-5.565-5.027-11.064-10.361-16.812-16.306a165.78 165.78 0 0 1-15.16-17.693 687.326 687.326 0 0 1-4.131-5.613c-3.13-4.275-6.368-8.696-9.712-12.905a31.781 31.781 0 0 1-6.74-23.546 31.779 31.779 0 0 1 11.883-21.416 31.662 31.662 0 0 1 19.89-6.949 31.841 31.841 0 0 1 25.072 12.092l.105.132c3.318 4.223 6.866 8.386 10.297 12.413a1537.33 1537.33 0 0 1 4.527 5.341 166.032 166.032 0 0 1 13.712 18.841c4.453 6.961 8.371 13.544 11.976 20.115-13.095 2.112-37.276 15.015-44.907 35.494Z"
            fill="url(#man-34-25-jacket-left-option-1-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-1-a"
                x1={481.13}
                y1={189.097}
                x2={429.95}
                y2={408.567}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-1-b"
                x1={509.466}
                y1={195.707}
                x2={458.285}
                y2={415.177}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
