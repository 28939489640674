import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-5">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-5-a)"
            d="M417.996 248.993a146.752 146.752 0 0 0-4.333-21.474c-.589-2.108-1.196-4.212-1.802-6.315-1.376-4.777-2.8-9.717-3.991-14.618l-.043-.175a31.935 31.935 0 0 0-31.027-24.27 32.071 32.071 0 0 0-7.761.956 32.04 32.04 0 0 0-23.314 38.789c1.22 4.891 2.251 9.928 3.249 14.799.44 2.144.88 4.288 1.335 6.424a146.708 146.708 0 0 0 6.127 21.028c2.652 7.319 5.335 14.006 8.205 20.448 1.127 2.504 5.466-10.448 25.128-17.966 17.939-6.859 29.814-3.711 29.814-3.711a322.93 322.93 0 0 0-1.587-13.915Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-5-a"
                x1={364.975}
                x2={390.999}
                y1={180.653}
                y2={263.93}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
