import { IBodyClothes } from 'interfaces';

import Tshirt4Normal from './man-34-25-tshirt-4-normal';
import Tshirt4Cycling1 from './man-34-25-tshirt-4-cycling';
import Tshirt4Cycling2 from './man-34-25-tshirt-4-cycling-2';
import Tshirt4Sitting from './man-34-25-tshirt-4-sitting';

import Tshirt4RightArmDown from './man-34-25-tshirt-4-arm-right-down';
import Tshirt4RightArmOption1 from './man-34-25-tshirt-4-arm-right-option-1';
import Tshirt4RightArmOption2 from './man-34-25-tshirt-4-arm-right-option-2';
import Tshirt4RightArmOption3 from './man-34-25-tshirt-4-arm-right-option-3';
import Tshirt4RightArmOption4 from './man-34-25-tshirt-4-arm-right-option-4';
import Tshirt4RightArmOption5 from './man-34-25-tshirt-4-arm-right-option-5';
import Tshirt4RightArmOption6 from './man-34-25-tshirt-4-arm-right-option-6';
import Tshirt4RightArmOption7 from './man-34-25-tshirt-4-arm-right-option-7';

import Tshirt4LeftArmDown from './man-34-25-tshirt-4-arm-left-down';
import Tshirt4LeftArmUp from './man-34-25-tshirt-4-arm-left-up';
import Tshirt4LeftArmOption1 from './man-34-25-tshirt-4-arm-left-option-1';
import Tshirt4LeftArmOption2 from './man-34-25-tshirt-4-arm-left-option-2';
import Tshirt4LeftArmOption3 from './man-34-25-tshirt-4-arm-left-option-3';
import Tshirt4LeftArmOption4 from './man-34-25-tshirt-4-arm-left-option-4';
import Tshirt4LeftArmOption5 from './man-34-25-tshirt-4-arm-left-option-5';
import Tshirt4LeftArmOption6 from './man-34-25-tshirt-4-arm-left-option-6';
import Tshirt4LeftArmOption7 from './man-34-25-tshirt-4-arm-left-option-7';

import Tshirt4BothArmsOption1Right from './man-34-25-tshirt-4-both-arms-option-1-right';
import Tshirt4BothArmsOption1Left from './man-34-25-tshirt-4-both-arms-option-1-left';
import Tshirt4BothArmsOption2Right from './man-34-25-tshirt-4-both-arms-option-2-right';
import Tshirt4BothArmsOption2Left from './man-34-25-tshirt-4-both-arms-option-2-left';
import Tshirt4BothArmsOption3Right from './man-34-25-tshirt-4-both-arms-option-3-right';
import Tshirt4BothArmsOption3Left from './man-34-25-tshirt-4-both-arms-option-3-left';
import Tshirt4BothArmsOption1RightCycling from './man-34-25-tshirt-4-both-arms-option-1-right-cycling';
import Tshirt4BothArmsOption1LeftCycling from './man-34-25-tshirt-4-both-arms-option-1-left-cycling';
import Tshirt4BothArmsOption1RightCycling2 from './man-34-25-tshirt-4-both-arms-option-1-right-cycling-2';
import Tshirt4BothArmsOption1LeftCycling2 from './man-34-25-tshirt-4-both-arms-option-1-left-cycling-2';

const tshirt: IBodyClothes = {
    text: 'Tshirt #4',
    normal: {
        front: Tshirt4Normal,
        rightArm: {
            down: Tshirt4RightArmDown,
            option1: Tshirt4RightArmOption1,
            option2: Tshirt4RightArmOption2,
            option3: Tshirt4RightArmOption3,
            option4: Tshirt4RightArmOption4,
            option5: Tshirt4RightArmOption5,
            option6: Tshirt4RightArmOption6,
            option7: Tshirt4RightArmOption7,
        },
        leftArm: {
            down: Tshirt4LeftArmDown,
            up: Tshirt4LeftArmUp,
            option1: Tshirt4LeftArmOption1,
            option2: Tshirt4LeftArmOption2,
            option3: Tshirt4LeftArmOption3,
            option4: Tshirt4LeftArmOption4,
            option5: Tshirt4LeftArmOption5,
            option6: Tshirt4LeftArmOption6,
            option7: Tshirt4LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt4BothArmsOption1Right, Tshirt4BothArmsOption1Left],
            option2: [Tshirt4BothArmsOption2Right, Tshirt4BothArmsOption2Left],
            option3: [Tshirt4BothArmsOption3Right, Tshirt4BothArmsOption3Left],
        },
    },
    sitting: {
        front: Tshirt4Sitting,
        rightArm: {
            down: Tshirt4RightArmDown,
            option1: Tshirt4RightArmOption1,
            option2: Tshirt4RightArmOption2,
            option3: Tshirt4RightArmOption3,
            option4: Tshirt4RightArmOption4,
            option5: Tshirt4RightArmOption5,
            option6: Tshirt4RightArmOption6,
            option7: Tshirt4RightArmOption7,
        },
        leftArm: {
            down: Tshirt4LeftArmDown,
            up: Tshirt4LeftArmUp,
            option1: Tshirt4LeftArmOption1,
            option2: Tshirt4LeftArmOption2,
            option3: Tshirt4LeftArmOption3,
            option4: Tshirt4LeftArmOption4,
            option5: Tshirt4LeftArmOption5,
            option6: Tshirt4LeftArmOption6,
            option7: Tshirt4LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt4BothArmsOption1Right, Tshirt4BothArmsOption1Left],
            option2: [Tshirt4BothArmsOption2Right, Tshirt4BothArmsOption2Left],
            option3: [Tshirt4BothArmsOption3Right, Tshirt4BothArmsOption3Left],
        },
    },
    cycling1: {
        front: Tshirt4Cycling1,
        bothArms: {
            option1: [
                Tshirt4BothArmsOption1RightCycling, Tshirt4BothArmsOption1LeftCycling
            ],
        },
    },
    cycling2: {
        front: Tshirt4Cycling2,
        bothArms: {
            option1: [
                Tshirt4BothArmsOption1RightCycling2, Tshirt4BothArmsOption1LeftCycling2
            ],
        },
    },
};

export default tshirt;
