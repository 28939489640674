import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-2-cycling-2-right-a)">
        <path
            d="M312.366 782.568c-4.673-24.879-6.951-51.019-11.221-76.107-6.668-32.108-1.696-68.763 10.559-99.965l.023-.016-.01-.004.01-.021a8.054 8.054 0 0 0 .577-4.371c-8.041-52.888-16.204-106.001-18.786-159.537-2.529-52.445 7.654-69.22 18.529-79.485l155.947 47.204a76.282 76.282 0 0 1-29.969 28.57 98.04 98.04 0 0 0-26.043 19.38 71.744 71.744 0 0 1-14.662 12.198c-1.563 30.566-3.957 49.101-10.073 77.102-4.2 18.832-7.96 35.351-15.897 57.067-4.38 25.68-8.502 65.145-12.435 90.712-3.588 15.795-5.369 80.793-5.722 89.478-7.743-.479-40.827-2.205-40.827-2.205Z"
            fill="url(#man-34-25-pants-2-cycling-2-right-b)"
        />
        <path
            d="M353.753 768.76c.178-4.898.394-10.542.652-16.506l-46.93-1.168a948.692 948.692 0 0 0 2.389 16.617l43.889 1.057Z"
            fill="url(#man-34-25-pants-2-cycling-2-right-c)"
        />
        <path
            d="M298.436 666.785a154.334 154.334 0 0 0 2.709 39.675c.819 4.812 1.551 9.67 2.255 14.543l52.566 1.811c.8-11.969 1.778-22.366 2.949-27.521 1.191-7.741 2.399-16.759 3.627-26.28l-64.106-2.228Z"
            fill="url(#man-34-25-pants-2-cycling-2-right-d)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-cycling-2-right-b"
                x1={293.128}
                y1={573.917}
                x2={467.994}
                y2={573.917}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="c"
                x1={307.475}
                y1={759.923}
                x2={354.405}
                y2={759.923}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-cycling-2-right-d"
                x1={298.174}
                y1={694.8}
                x2={362.542}
                y2={694.8}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <clipPath id="man-34-25-pants-2-cycling-2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .621)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
