import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#63666A"
            d="M527.553 376.625l-173.201 15.792V458.3h157.281a20.003 20.003 0 0020-20v-36.515a17.964 17.964 0 00-.263-3.045l-3.817-22.115z"
        ></path>
    </g>
);

export default SvgComponent;
