import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#001489"
                d="M668.599 672.982c-2.936-1.234-8.534-3.554-13.041-5.225-7.751-2.874-15.799-8.546-22.681-14.337a177.016 177.016 0 01-18.001-17.612l3.488-21.66h-39.862s1.774 28.077 1.014 36.309c-.408 4.423-.94 9.65-1.587 15.595a9.99 9.99 0 005.526 10.052c1.222.601 2.555.946 3.915 1.014l20.304 1.018a41.09 41.09 0 0112.374 2.507 70.774 70.774 0 0020.64 4.145c.318.019.7.027 1.137.027 5.146 0 18.055-1.224 25.292-1.954a5.151 5.151 0 004.577-4.364 5.155 5.155 0 00-3.095-5.515z"
            ></path>
            <path
                fill="url(#paint0_linear_10892_7275)"
                d="M668.599 672.986c-2.936-1.235-8.534-3.555-13.041-5.226-7.751-2.873-15.799-8.546-22.681-14.337a160.24 160.24 0 01-6.652-5.93c-3.44-.081-13.912 5.794-19.66 6.153-11.52.719-20.072-3.665-27.049-3.186-.408 4.422-.94 9.65-1.587 15.595a10.007 10.007 0 005.526 10.052c1.222.601 2.555.946 3.915 1.014l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.736 70.736 0 0020.64 4.146c.318.019.7.027 1.137.027 5.146 0 18.055-1.224 25.292-1.954a5.153 5.153 0 003.974-7.651 5.15 5.15 0 00-2.492-2.227z"
            ></path>
            <mask
                id="mask0_10892_7275"
                style={{ maskType: 'luminance' }}
                width="95"
                height="38"
                x="577"
                y="647"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    d="M668.599 672.986c-2.936-1.235-8.534-3.555-13.041-5.226-7.751-2.873-15.799-8.546-22.681-14.337a160.24 160.24 0 01-6.652-5.93c-3.44-.081-13.912 5.794-19.66 6.153-11.52.719-20.072-3.665-27.049-3.186-.408 4.422-.94 9.65-1.587 15.595a10.007 10.007 0 005.526 10.052c1.222.601 2.555.946 3.915 1.014l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.736 70.736 0 0020.64 4.146c.318.019.7.027 1.137.027 5.146 0 18.055-1.224 25.292-1.954a5.153 5.153 0 003.974-7.651 5.15 5.15 0 00-2.492-2.227z"
                ></path>
            </mask>
            <g fill="#001489" mask="url(#mask0_10892_7275)">
                <path
                    d="M612.456 685.128c-2.192-9.995 7.314-20.466 15.422-24.854a63.802 63.802 0 0116.01-5.846c5.568-1.256 12.898-.696 17.602-4.185 1.465-1.087 1.582-3.772-.554-4.241-3.802-.834-7.755.135-11.575.654a75.546 75.546 0 00-11.814 2.354c-6.347 1.92-12.89 4.271-18.206 8.328-7.841 5.984-18.153 19.789-10.998 29.523a2.26 2.26 0 003.932-.185 2.26 2.26 0 00.181-1.548z"></path>
                <path
                    d="M627.165 684.061c-.488-9.09 9.061-15.762 16.114-19.782a42.894 42.894 0 0112.644-4.873c4.802-.977 9.628-.556 14.158-2.607 1.914-.866 1.44-4.181-.597-4.562-11.415-2.134-24.099 2.154-33.484 8.554-8.151 5.559-15.113 13.442-13.462 23.896.414 2.619 4.769 2.008 4.627-.626z"></path>
                <path
                    d="M640.305 685.057c.923-7.875 7.293-11.929 13.928-15.056 7.417-3.497 15.604-3.907 22.999-7.055 2.162-.921 1.53-4.567-.655-5.012-9.504-1.935-21.576 3.336-29.534 8.256-7.089 4.382-11.492 10.283-11.168 18.867.11 2.915 4.104 2.774 4.43 0z"></path>
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7275"
                    x1="577.868"
                    x2="671.75"
                    y1="666.156"
                    y2="666.156"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#009CDE"></stop>
                    <stop offset="0.9" stopColor="#39BDE8"></stop>
                    <stop offset="1" stopColor="#40C1E9"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
