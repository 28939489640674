import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-down">
        <path
            d="m317.467 268.598.271-.813a428.316 428.316 0 0 1 8.287-22.631 202.63 202.63 0 0 1 10.786-23.334c1.217-2.253 2.453-4.502 3.687-6.75 2.819-5.133 5.734-10.441 8.406-15.75a31.86 31.86 0 0 1 28.607-17.617 31.676 31.676 0 0 1 14.366 3.425 31.781 31.781 0 0 1 15.996 18.547 31.788 31.788 0 0 1-1.804 24.425l-.07.137c-2.696 5.293-5.249 10.779-7.717 16.083-1.085 2.332-2.174 4.66-3.268 6.985a202.63 202.63 0 0 1-12.434 22.504c-4.468 7.135-9.032 13.996-13.564 20.386l-.502.708-51.047-26.305Z"
            fill="url(#man-34-25-tshirt-4-arm-right-down-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-down-a"
                x1={337.583}
                y1={199.491}
                x2={415.005}
                y2={308.793}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.076} stopColor="#0197D6" />
                <stop offset={0.371} stopColor="#22ADE0" />
                <stop offset={0.618} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
