import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={567}
            height={206}
            viewBox="0 0 567 206"
            fill="none"
            id="object-meals-2"
            {...attributes}
        >
            <path fill="#C0F0FF" d="M512.639 168.594H.066v32.199H512.64v-32.199Z" />
            <path
                fill="url(#object-meals-2-a)"
                d="m460.735 97.668-131.109-74.53c-32.129-18.265-72.914-2.16-83.897 33.128-9.503 30.532 7.545 62.988 38.077 72.49l144 44.817c21.942 6.829 45.266-5.423 52.095-27.365 5.812-18.674-2.165-38.874-19.166-48.54Z"
            />
            <path
                fill="url(#object-meals-2-b)"
                d="M463.264 153.651c-6.355-1.978-9.904-8.734-7.926-15.09 1.978-6.355 8.734-9.904 15.09-7.926 17.555 5.464 53.001 9.347 63.531 3.815 14.571-7.654 19.745 16.857 11.21 21.341-21.299 11.188-67.489 2.347-81.905-2.14Z"
            />
            <path
                fill="url(#object-meals-2-c)"
                d="m460.734 97.67-8.128-4.619c1.811 12.149.997 24.942-2.954 37.639-5.223 16.781-15.18 30.78-27.946 40.987l6.098 1.898c21.943 6.829 45.267-5.423 52.096-27.365 5.812-18.673-2.165-38.874-19.166-48.54Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M262.943 29.814c-7.775 6.758-13.874 15.718-17.215 26.453-9.503 30.532 7.545 62.988 38.077 72.49l144 44.817c13.411 4.174 27.338 1.22 37.701-6.719M481.408 128.268c-1.701-12.547-9.095-24.017-20.675-30.6L329.625 23.136"
            />
            <path
                fill="url(#object-meals-2-d)"
                d="M285.76 88.526c-6.657 0-12.053-5.397-12.053-12.053 0-6.656 5.396-12.053 12.053-12.053 18.386 0 53.385-6.826 61.795-15.236 11.638-11.638 23.862 10.228 17.045 17.045-17.012 17.012-63.743 22.297-78.84 22.297Z"
            />
            <path
                fill="url(#object-meals-2-e)"
                d="M272.129 90.072c-50.113 0-90.738-18.92-90.738-42.259s40.625-42.258 90.738-42.258c50.114 0 50.114 84.517 0 84.517Z"
            />
            <path
                fill="url(#object-meals-2-f)"
                d="M272.129 145.181c-50.113 0-90.738-18.919-90.738-42.258 0-23.34 40.625-42.259 90.738-42.259 50.114 0 50.114 84.517 0 84.517Z"
            />
            <path
                fill="url(#object-meals-2-g)"
                d="M272.129 117.474c-50.113 0-90.738-18.92-90.738-42.258 0-23.34 40.625-42.259 90.738-42.259 50.114 0 50.114 84.516 0 84.516v.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M190.969 29.355c-6.128 5.692-9.578 12.117-9.578 18.918 0 4.797 1.718 9.406 4.879 13.702-3.161 4.297-4.879 8.906-4.879 13.702 0 4.797 1.718 9.406 4.879 13.703-3.161 4.297-4.879 8.905-4.879 13.701 0 23.339 40.625 42.259 90.738 42.259 30.562 0 42.485-31.433 35.771-55.96 2.418-8.837 2.418-18.568 0-27.404 6.715-24.528-5.208-55.96-35.771-55.96-23.497 0-44.908 4.16-61.024 10.984"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M307.902 89.375c-4.296 15.691-16.219 28.556-35.771 28.556"
            />
            <path
                stroke="#00B140"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={18}
                d="m167.926 130.016-67.072.491M167.923 130.017l-58.144-33.439"
            />
            <path
                fill="url(#object-meals-2-h)"
                d="M356.044 154.424 201.477 96.911c-24.258-9.027-50.077 8.914-50.077 34.798 0 20.506 16.624 37.129 37.129 37.129H353.45a7.44 7.44 0 0 0 2.594-14.414Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M321.609 168.838h31.838a7.441 7.441 0 0 0 2.595-14.414L201.476 96.911c-6.651-2.475-13.418-2.922-19.74-1.735M157.639 111.062c-3.912 5.824-6.239 12.878-6.239 20.648 0 20.505 16.624 37.129 37.129 37.129h106.793M206.816 168.836v-26.82M246.4 168.833v-19.106M285.982 168.837V155.82M325.566 168.841v-8.146"
            />
            <path
                fill="url(#object-meals-2-i)"
                d="M370.652 95.04h-5.048c-2.078 11.546-9.977 21.156-20.847 25.576-16.945 6.889-28.566 21.035-28.566 37.374 0 23.115 23.254 41.854 51.938 41.854s51.937-18.739 51.937-41.854c0-16.339-11.62-30.485-28.565-37.374-10.87-4.42-18.771-14.03-20.849-25.577Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M324.524 135.253c-5.27 6.545-8.333 14.354-8.333 22.741 0 23.115 23.254 41.854 51.938 41.854s51.937-18.739 51.937-41.854c0-16.339-11.62-30.485-28.565-37.374-10.87-4.42-18.769-14.03-20.847-25.577h-5.048c-2.078 11.547-9.977 21.157-20.847 25.577"
            />
            <path
                fill="url(#object-meals-2-j)"
                d="M369.326 95.04h-2.398c0 26.14-23.467 32.465-23.467 62.95 0 23.115 11.043 41.854 24.665 41.854s24.666-18.739 24.666-41.854c0-30.487-23.466-36.81-23.466-62.95Z"
            />
            <path
                fill="url(#object-meals-2-k)"
                d="M366.928 95.04c0 26.14-23.467 32.465-23.467 62.95 0 23.115 11.043 41.854 24.665 41.854V95.039h-1.198Z"
            />
            <path
                fill="#00B140"
                d="M44.514 168.609h194.021v8.229H52.743a8.233 8.233 0 0 1-8.23-8.229Z"
            />
            <path
                fill="#7CCC6C"
                d="M106.914 160.379h131.621v8.229H115.143a8.233 8.233 0 0 1-8.229-8.229Z"
            />
            <path
                fill="url(#object-meals-2-l)"
                d="M271.946 176.836h-33.411v-16.458l30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006l.001-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m238.534 160.378 30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006h-33.411v-8.229M238.536 176.836H91.252"
            />
            <defs>
                <linearGradient
                    id="object-meals-2-a"
                    x1={386.475}
                    x2={337.27}
                    y1={205.524}
                    y2={-1.084}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-b"
                    x1={458.1}
                    x2={546.76}
                    y1={129.687}
                    y2={157.281}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-c"
                    x1={448.526}
                    x2={466.06}
                    y1={166.259}
                    y2={109.922}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-d"
                    x1={273.707}
                    x2={366.562}
                    y1={67.179}
                    y2={67.179}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-e"
                    x1={230.222}
                    x2={204.644}
                    y1={31.343}
                    y2={13.48}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-f"
                    x1={242.588}
                    x2={264.106}
                    y1={118.629}
                    y2={17.132}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-g"
                    x1={308.843}
                    x2={243.885}
                    y1={138.084}
                    y2={68.254}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-h"
                    x1={170.539}
                    x2={337.992}
                    y1={180.732}
                    y2={96.02}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-i"
                    x1={442.769}
                    x2={340.314}
                    y1={224.042}
                    y2={131.706}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-j"
                    x1={309.942}
                    x2={377.616}
                    y1={147.442}
                    y2={147.442}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-k"
                    x1={350.102}
                    x2={468.685}
                    y1={93.37}
                    y2={93.37}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-2-l"
                    x1={191.905}
                    x2={278.584}
                    y1={193.999}
                    y2={159.815}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
