import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6586)"
            d="M553.355 305.224l-.231-.102c-5.816-2.578-11.303-4.752-15.436-6.358-.694-.265-1.388-.534-2.08-.804l-.116-.044a182.684 182.684 0 00-3.652-1.389l-5.843-2.116c-2.627-.983-5.313-1.883-7.948-2.766-1.281-.43-2.562-.859-3.846-1.302a255.266 255.266 0 00-23.968-6.857l-12.074-2.761-10.313-2.483c-4.503-5.108-9.1-9.652-12.171-12.63-5.12-4.929-10.517-9.773-16.502-14.807a158.53 158.53 0 00-17.727-13.19c-1.857-1.192-3.723-2.371-5.598-3.556-4.228-2.673-8.601-5.437-12.783-8.315l-.119-.082a27.599 27.599 0 00-15.589-4.81 27.746 27.746 0 00-22.965 12.139 27.59 27.59 0 00-4.321 20.795 27.564 27.564 0 0011.65 17.759c4.203 2.862 8.367 5.941 12.393 8.919 1.771 1.31 3.542 2.62 5.319 3.914a157.775 157.775 0 0018.706 11.748c6.873 3.765 13.345 7.046 19.789 10.032a201.008 201.008 0 0020.949 8.441c1.801.575 21.05 6.248 30.791 8.394l2.254.498c7.451 1.645 15.156 3.347 22.645 4.651 1.313.222 2.622.455 3.932.687l.044-.246.025.258c2.708.482 5.508.979 8.283 1.375l6.159.943c1.293.195 2.591.364 3.89.532l.17.023c.714.092 1.429.184 2.149.283 4.153.537 9.695 1.22 15.74 1.737l.291.025-.02-.291c-.6-8.851 5.215-21.778 7.697-27.298l.426-.946z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6586"
                x1="360.571"
                x2="559.799"
                y1="237.74"
                y2="340.102"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
