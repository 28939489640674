import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={155}
            height={58}
            fill="none"
            id="object-food-arugula"
            {...attributes}
        >
            <path
                fill="url(#object-food-arugula-a)"
                d="m153.013 48.247-.664 3.335c-.239.024-.478.048-.708.073-.208.02-.396.044-.584.067a.968.968 0 0 0-.167.018c-.343.034-.676.07-.98.111-.677.07-1.282.142-1.857.212-.145.012-.292.034-.427.047-.22.028-.43.057-.639.087-.167.018-.333.036-.512.061-.333.036-.669.081-1.004.126-.167.018-.335.045-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.07-.314.04-.647.074-1 .106-.239.024-.478.048-.739.077-.52.05-1.061.105-1.643.162-.29.024-.592.055-.904.085-.623.06-1.288.121-1.994.184-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.24 12.28-35.016 6.93-2.893-2.677 3.748-11.868 9.682-12.48-10.348 1.07-19.626 3.832-29.872 8.34-15.836 6.972-25.86-2.038-7.814-14.994-9.824 1.002-16.146 6.28-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277 2.184-10.975 23.729-5.086 32.245 1.156-2.51-3.069-5.11-6.349-5.705-10.27-.596-3.923.38-9.142 4.323-9.632 4.014-.496 8.437 3.77 11.005 6.901 6.259 7.64 10.079 14.934 18.77 19.621-11.71-18.879 1-23.364 12.96-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.73 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.32.559.314 1.063.598 1.542.856l.32.186c.118.074.247.141.367.206.129.067.247.141.375.217.32.186.632.36.954.536.155.092.302.172.449.253.182.108.365.215.557.325.12.065.247.141.377.208.504.284 1.036.583 1.635.906.265.155.559.315.863.478.045.03.1.061.147.08.165.094.33.188.514.286.202.112.414.225.626.34l-.003-.003Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15.31 13.947c6.666.917 14.063 3.918 18.274 7.004-2.51-3.069-5.11-6.349-5.705-10.27-.596-3.923.38-9.142 4.323-9.632 4.014-.496 8.437 3.77 11.005 6.901 6.259 7.64 10.079 14.934 18.77 19.621-11.71-18.879 1-23.364 12.96-10.86 7.74 8.086 15.254 14.19 24.403 19.141-5.247-2.839-7.864-13.872-4.166-15.236 7.385-2.73 19.986 20.23 29.695 19.807 1.728-.074 4.392-5.009 11.736-1.182a443.732 443.732 0 0 1 5.567 2.974c.212.114.404.223.598.323.332.178.644.352.938.513.202.112.395.221.579.32.559.314 1.063.598 1.542.856l.32.186c.118.074.247.141.367.206.127.076.257.143.375.217.32.186.632.36.954.536.145.09.292.17.449.253.182.108.365.215.557.325.12.065.249.131.377.208.504.284 1.036.583 1.635.906.275.157.559.315.863.478a.96.96 0 0 0 .147.08c.165.094.34.19.514.286.202.112.414.225.626.34l-.664 3.334c-.239.024-.478.048-.708.073-.198.022-.396.044-.584.067a.968.968 0 0 0-.167.018c-.343.034-.667.071-.98.111a92.54 92.54 0 0 0-1.857.212c-.147.022-.292.034-.427.047-.22.028-.43.057-.639.087a7.543 7.543 0 0 0-.512.061c-.333.036-.669.081-1.004.126-.167.018-.333.036-.512.061-.773.091-1.588.194-2.537.301-.208.02-.428.047-.657.073-.333.036-.688.077-1.063.115-.22.028-.437.046-.676.07-.314.04-.647.074-1 .106-.239.024-.488.046-.739.077-.52.05-1.061.105-1.643.162-.29.024-.592.055-.904.085a366.5 366.5 0 0 1-1.994.184c-8.25.724-8.822-4.855-10.39-5.585-8.808-4.108-29.24 12.28-35.016 6.93-2.893-2.677 3.748-11.868 9.682-12.48-10.348 1.07-19.626 3.832-29.872 8.34-15.836 6.972-25.86-2.038-7.814-14.994-9.824 1.002-16.146 6.28-24.853 10.94-3.572 1.91-9.29 4.157-12.808 2.162-3.454-1.962-2.358-7.158-.306-10.553 2.052-3.395 5.708-5.42 9.203-7.304-10.257 2.506-32.414-.303-30.23-11.277"
            />
            <path
                stroke="#81C871"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M149.953 49.373 40.6 27.611"
            />
            <defs>
                <linearGradient
                    id="object-food-arugula-a"
                    x1={1.342}
                    x2={152.684}
                    y1={19.797}
                    y2={49.914}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
            </defs>
        </svg>
    );
}
