import React, { useEffect, useMemo, useState } from 'react';
import Settings, { Option, OptionsWrapper } from 'components/Settings';
import Request from 'components/Controls/Request';
import PicWrapper from 'components/PicWrapper';
import {
    DOCTORS_OFFICE,
    KITCHEN,
    OUTDOOR,
    TObjects,
    TObjectSettings,
    TDoctorsOffice,
    TKitchen,
    TOutdoor,
} from 'components/scenes';

const DEFAULT_STATE: { [key in typeof SETTING_TYPES as string]: TObjects } = {
    doctorsOffice: 'option1',
};

export const SETTING_TYPES = {
    doctorsOffice: 'Doctor\'s office',
    kitchen: 'Kitchen',
    outdoor: 'Outdoor'
};
const settingsOptions = Object.values(SETTING_TYPES).sort();

const sections = {
    doctorsOffice: DOCTORS_OFFICE,
    kitchen: KITCHEN,
    outdoor: OUTDOOR,
};

export default function SceneBuilder() {
    const [state, setState] = useState<TObjectSettings>(DEFAULT_STATE);
    const [hoverState, setHoverState] = useState<TObjectSettings>(state);
    const [hover, setHover] = useState(false);

    const sortedOptions = useMemo(() => {
        return {
            doctorsOffice: (Object.keys(DOCTORS_OFFICE || {})).sort() as TDoctorsOffice[],
            kitchen: (Object.keys(KITCHEN || {})).sort() as TKitchen[],
            outdoor: (Object.keys(OUTDOOR || {})).sort() as TOutdoor[],
        };
    }, [DEFAULT_STATE]);

    const section = Object.keys(hoverState)[0] as keyof typeof SETTING_TYPES;
    const selectedSection = sections[section];
    const { [hoverState[section] as keyof typeof selectedSection]: selectedCategory } = selectedSection;
    const { image: Image }: { image?: Function } = selectedCategory;

    useEffect(() => {
        if (!hover) {
            setHoverState(state);
        }
    }, [hover]);

    return <div className="layout">
        <Settings>
            {settingsOptions.map((setting) => {
                const sectionName = Object.keys(SETTING_TYPES).find(
                    (key) => SETTING_TYPES[key as keyof typeof SETTING_TYPES] === setting
                ) as keyof typeof SETTING_TYPES;

                return <OptionsWrapper
                    title={setting}
                    key={setting}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}
                >
                    {sortedOptions[sectionName]?.map((id: TObjects): JSX.Element => {
                        const defineSection = sections[sectionName];
                        const defineOption = defineSection[id as keyof typeof defineSection]?.name;
                        return <Option
                            key={id as string}
                            onClick={() => setState({ [sectionName]: id })}
                            onHover={() => setHoverState({ [sectionName]: id })}
                            active={state[sectionName] === id}
                            text={defineOption || ''}
                        />;
                    })}
                </OptionsWrapper>;
            })}
            <Request/>
        </Settings>

        <PicWrapper preset={true}>
            {Image && <Image id="svg-target"/>}
        </PicWrapper>
    </div>;
}

