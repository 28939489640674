import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8518)"
            d="M481.574 277.17c-4.544-2.402-9.213-4.853-13.902-7.128a26.528 26.528 0 01-12.269-35.412 26.679 26.679 0 0123.86-14.935 26.251 26.251 0 0111.553 2.666l.113.056c4.623 2.27 9.375 4.424 14.016 6.51-9.171 6.564-23.294 28.379-23.371 48.243z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M481.574 277.17c-4.544-2.402-9.213-4.853-13.902-7.128a26.528 26.528 0 01-12.269-35.412 26.679 26.679 0 0123.86-14.935 26.251 26.251 0 0111.553 2.666l.113.056c4.623 2.27 9.375 4.424 14.016 6.51-9.171 6.564-23.294 28.379-23.371 48.243z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8518"
                x1="452.746"
                x2="504.944"
                y1="248.433"
                y2="248.433"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
