import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-1-right-cycling-2">
        <path
            d="M402.214 283.829a253.246 253.246 0 0 0 1.675-28.701c.006-2.87-.004-5.739-.015-8.609-.024-6.558-.049-13.339.14-19.994l.004-.14a32.04 32.04 0 0 0-32.006-32.768 32.193 32.193 0 0 0-31.976 31.233c-.16 6.652-.539 13.418-.905 19.96-.162 2.871-.317 5.74-.466 8.608-.487 9.577-.43 19.174.171 28.745.089 1.637.337 3.9.286 4.849 32.87 5.124 62.814-.665 62.814-.665.093-.837.187-1.671.278-2.518Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-right-cycling-2-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-right-cycling-2-a"
                x1={307.173}
                y1={198.407}
                x2={412.571}
                y2={280.383}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.179} stopColor="#0197D6" />
                <stop offset={0.546} stopColor="#22ADE0" />
                <stop offset={0.852} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
