import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m475.685 232.623.064-.319a63.791 63.791 0 0 0 1.387-10.445 32.862 32.862 0 0 0-1.357-11.177l-.834-2.627 2.325 1.482a106.653 106.653 0 0 1 17.498 13.756 2.718 2.718 0 0 1 .821 2.483c-.805 4.665-13.806 10.891-16.403 12.092l-.913.422-2.588-5.667Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-8-left-a)"
        />
        <path
            d="M555.575 291.953a195.032 195.032 0 0 1-22.17-2.22c-6.93-1.056-13.964-2.387-21.508-4.067a153.987 153.987 0 0 1-20.929-6.013c-2.041-.744-4.08-1.504-6.12-2.264-4.633-1.727-9.424-3.513-14.19-5.077a27.502 27.502 0 0 1-3.843-50.668 27.492 27.492 0 0 1 20.988-1.592l.14.046c4.756 1.589 9.664 3.014 14.411 4.393 2.093.607 4.186 1.215 6.273 1.838a154.185 154.185 0 0 1 20.387 7.667 338.003 338.003 0 0 1 14.062 6.668l.185-1.125c.267-1.628.542-3.312.914-4.977l.746-3.637c.167-.804.364-1.603.56-2.403.104-.424.208-.849.308-1.274.755-3.08 1.445-5.617 2.174-7.992l.404-1.318 1.128.794c3.661 2.58 8.916 4 14.797 4a31.753 31.753 0 0 0 12.454-2.359l1.123-.503.263 1.203c.485 2.231.931 4.605 1.403 7.469.068.442.14.873.211 1.304.134.812.267 1.625.372 2.436l.466 3.703c.239 1.673.385 3.372.525 5.015.069.802.137 1.604.216 2.405.459 4.957.51 9.942.153 14.907a441.144 441.144 0 0 1-.356 4.446c-.278 3.347-.565 6.809-.687 10.207a19.422 19.422 0 0 1-19.473 18.81h-.002a10.809 10.809 0 0 1-1.406-.068l-.176-.017a20.771 20.771 0 0 1-3.803.263Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-8-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-8-left-a"
                x1={458.809}
                y1={216.531}
                x2={584.375}
                y2={263.375}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-8-left-b"
                x1={457.119}
                y1={221.054}
                x2={582.685}
                y2={267.898}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
