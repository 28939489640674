import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-6" transform="translate(316, 30)">
        <path
            d="M64.4 91.2c.1-.1.1-.4-.1-.5-4.9-2.8-4.9-10.5.1-12.8.2-.1.2-.3.1-.5-4.1-3.8-2.2-11.2 3.2-12.3.2-.1.3-.3.2-.4-1.4-2.2-1.6-5-.2-7.3 1.3-2.3 3.8-3.7 6.4-3.8.2 0 .3-.2.3-.4-1.9-5.1 3.3-10.7 8.8-9.3.2 0 .4-.1.4-.3-.7-5.3 5.9-9.5 11-7 .2.1.4 0 .4-.2.7-5.3 8.1-7.8 12.5-4.3.2.1.4.1.5-.1 1-2.3 3.3-3.9 6.1-4.2 2.8-.3 5.5.9 7.2 2.9.1.2.4.2.5 0 3.1-4.3 11.2-3.3 13.4 1.7.1.2.3.2.5.1 4.1-3.4 11.8-.7 12.8 4.6 0 .2.3.3.5.2 2.4-1 5.3-.8 7.7.7 2.4 1.5 3.7 4.1 3.7 6.6 0 .2.2.4.4.3 5.4-1.2 11.1 4.5 9.5 9.6 0 .2.1.4.3.4 5.6.1 9.8 6.8 7 11.3-.1.2 0 .4.2.4 5.5 1.3 8 8.8 4 12.5 2.7 1.2 4.6 3.9 4.6 7.1 0 2.7-1.3 5-3.4 6.4-1.3.9-2.8 1.4-4.5 1.4h-.3v.6c0 4.3-3.5 7.8-7.8 7.8-1.4 0-2.7-.4-3.8-1-.1.3-.3.6-.4.8 0-.1-.1-.2-.1-.4l-.6-1.8c-.7-2.4-.2-5 .3-7.7.4-2.3.9-4.6.7-7-.5-5.5-3.7-12.2-9.2-18.1l-1.6.3h-.3c-2 .4-3.8 1.3-4.9 3-1.7 2.4-4.2 4-7.1 4.2-2.4.2-4.5 1.8-5.7 4-1.1 2.2-3.2 3.9-5.6 4.6-2 .6-3.7 2.1-4.5 4.1-.8 1.9-2.2 3.5-3.9 4.5-1.5.9-2.5 2.2-3.1 3.8-.8 2-2.6 3.6-4.6 4.8-3.4 2-5.6 5.5-5.7 9.4 0 .6-.1 1-.2 1-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5-.1 4.1-.5 7.2-1 9.7-.7 3.3-5 4.3-7.1 1.6-1.7-2.2-3-3.7-3.2-3.6-4.8.9-10.3-3.6-9.6-8.5 0-.2-.2-.4-.4-.3-2.6.2-5.4-.8-7.2-2.9-1.8-2.2-2.3-4.9-1.5-7.3.1-.2-.1-.4-.3-.4-5.6-.5-9.2-7.5-6-11.7.1-.2 0-.4-.2-.5-5.5-1.5-7.3-9-3.1-12.4Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M67.6 104.3c.1-.2 0-.4-.2-.5-5.4-1.7-7.3-9.2-3.1-12.6.1-.1.1-.4-.1-.5-4.9-2.8-4.9-10.5.1-12.8.2-.1.2-.3.1-.5-4.1-3.8-2.2-11.2 3.2-12.3.2-.1.3-.3.2-.4-1.4-2.2-1.5-5-.2-7.3s3.8-3.7 6.4-3.8c.2 0 .3-.2.3-.4-1.9-5.1 3.3-10.7 8.8-9.3.2 0 .4-.1.4-.3-.7-5.3 5.9-9.5 11-7 .2.1.4 0 .4-.2.7-5.3 8.1-7.8 12.5-4.3.2.1.4.1.5-.1 1-2.3 3.299-3.9 6.099-4.2 2.8-.3 5.501.9 7.201 2.9.1.2.4.2.5 0 3.1-4.3 11.199-3.3 13.399 1.7.1.2.3.2.5.1 4.1-3.4 11.801-.7 12.801 4.6 0 .2.3.3.5.2 2.4-1 5.299-.8 7.699.7 2.4 1.5 3.701 4.1 3.701 6.6 0 .2.2.4.4.3 5.4-1.2 11.1 4.5 9.5 9.6 0 .2.099.4.299.4 5.6.1 9.8 6.8 7 11.3-.1.2.001.4.201.4"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110 111.2c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
