import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-6-cycling-2-right-a)">
        <path
            d="M393.113 840.438c-8.162-.104-22.455-.383-26.477-1.074a70.706 70.706 0 0 1-20.039-6.453 41.148 41.148 0 0 0-12.012-3.89l-20.058-3.308a10 10 0 0 1-8.128-12.063c3.765-16.69 5.92-27.654 6.066-30.871l-3.367-21.113 38.963-.072-1.686 25.963c.183.271 18.506 27.229 36.961 36.566 4.301 2.176 9.594 5.112 12.366 6.667a5.152 5.152 0 0 1 .626 8.572 5.156 5.156 0 0 1-3.148 1.076h-.067Z"
            fill="#E2E2E2"
        />
        <path
            d="M394.577 841.103a6.13 6.13 0 0 1-1.843.281c-8.192.001-22.548-.095-26.645-.745a71.698 71.698 0 0 1-20.405-6.286 40.142 40.142 0 0 0-11.768-3.644l-19.757-2.997a11.44 11.44 0 0 1-7.527-4.37 10.89 10.89 0 0 1-1.927-8.835c3.149-14.845 3.625-16.346 4.086-20.658.02-.16.058-.317.113-.468a3.093 3.093 0 0 1 3.819-1.863c.5.157.951.438 1.312.817 3.228 3.327 12.146 10.604 24.018 4.679a85.194 85.194 0 0 0 7.937-5.26 3.236 3.236 0 0 1 4.102.213c.76.749 1.427 1.587 1.988 2.495 2.095 3.261 13.915 20.415 31.108 28.837 4.231 2.072 9.414 4.854 12.268 6.405a6.463 6.463 0 0 1 3.218 3.93 6.17 6.17 0 0 1-4.097 7.469Z"
            fill="#63666A"
        />
        <path
            d="M344.559 822.81c-13.373-9.684-30.636-11.639-39.139-11.697a1280.08 1280.08 0 0 1-.715 3.393 10.888 10.888 0 0 0 .004 4.627c.332 1.525.988 2.96 1.923 4.209a11.445 11.445 0 0 0 7.527 4.37l19.757 2.997a40.142 40.142 0 0 1 11.768 3.644 71.698 71.698 0 0 0 20.405 6.286c4.097.65 18.453.746 26.645.745a6.084 6.084 0 0 0 4.402-1.87c-7.96-1.367-39.519-7.249-52.577-16.704Z"
            fill="#E2E2E2"
        />
        <defs>
            <clipPath id="man-34-25-shoes-6-cycling-2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .621)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
