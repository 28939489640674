import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M21.91 57.075h-7V34.213C14.91 15.502 30.078.334 48.79.334H66.6a7 7 0 0 1 7 7H48.79c-14.846 0-26.88 12.034-26.88 26.879v22.862Z"
                />
                <path
                    fill="#C2C9CF"
                    d="M27.526 83.77c7.87-2.905 11.573-12.516 8.27-21.468-3.304-8.951-12.362-13.854-20.233-10.95-7.87 2.905-11.573 12.516-8.27 21.468 3.304 8.952 12.362 13.854 20.233 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.09 66.444c.154 9.39 7.197 17.504 15.73 18.12 8.535.618 15.329-6.494 15.175-15.885-.117-7.171-4.252-13.597-10-16.51"
                />
                <path
                    fill="#ECF3F9"
                    d="M23.822 85.306c6.994-2.58 10.213-11.314 7.19-19.507-3.024-8.193-11.144-12.742-18.138-10.161-6.994 2.58-10.212 11.314-7.189 19.507 3.023 8.193 11.144 12.742 18.137 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.951 54.964c-7.616-.637-13.612 5.79-13.394 14.356.218 8.567 6.569 16.027 14.185 16.664 7.616.637 13.613-5.791 13.396-14.358M54.662 7.334H48.79c-14.845 0-26.879 12.034-26.879 26.879v15.879"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.255}
                        x2={44.255}
                        y1={40.871}
                        y2={10.276}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#C2C9CF" />
                        <stop offset={0.859} stopColor="#ECF3F9" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M21.947 57.075h-7V34.213c0-18.711 15.168-33.879 33.88-33.879h17.81a7 7 0 0 1 7 7h-24.81c-14.846 0-26.88 12.034-26.88 26.879v22.862Z"
                />
                <path
                    fill="#50D5F9"
                    d="M27.56 83.77c7.871-2.905 11.574-12.516 8.27-21.468-3.303-8.951-12.361-13.854-20.232-10.95-7.87 2.905-11.572 12.516-8.27 21.468 3.304 8.952 12.363 13.854 20.233 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.127 66.444c.154 9.39 7.197 17.504 15.731 18.12 8.534.618 15.328-6.494 15.174-15.885-.117-7.171-4.252-13.597-10-16.51"
                />
                <path
                    fill="#D2F0FA"
                    d="M23.858 85.306c6.993-2.58 10.212-11.314 7.189-19.507-3.024-8.193-11.144-12.742-18.138-10.161-6.993 2.58-10.212 11.314-7.189 19.507 3.023 8.193 11.144 12.742 18.138 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.989 54.964c-7.616-.637-13.613 5.79-13.395 14.356.218 8.567 6.569 16.027 14.186 16.664 7.616.637 13.613-5.791 13.395-14.358M54.7 7.334h-5.874c-14.845 0-26.879 12.034-26.879 26.879v15.879"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.291}
                        x2={44.291}
                        y1={40.871}
                        y2={10.276}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#50D5F9" />
                        <stop offset={0.859} stopColor="#D2F0FA" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M21.986 57.075h-7V34.213C14.986 15.502 30.154.334 48.864.334h17.81a7 7 0 0 1 7 7h-24.81c-14.845 0-26.879 12.034-26.879 26.879v22.862h.001Z"
                />
                <path
                    fill="#3ACEC7"
                    d="M27.6 83.77c7.87-2.905 11.573-12.516 8.27-21.468-3.304-8.951-12.362-13.854-20.233-10.95-7.87 2.905-11.572 12.516-8.269 21.468C10.671 81.772 19.73 86.674 27.6 83.77Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.166 66.444c.154 9.39 7.197 17.504 15.731 18.12 8.534.618 15.328-6.494 15.174-15.885-.117-7.171-4.252-13.597-10-16.51"
                />
                <path
                    fill="#BBE9E9"
                    d="M23.897 85.306c6.993-2.58 10.212-11.314 7.189-19.507-3.023-8.193-11.144-12.742-18.138-10.161-6.993 2.58-10.212 11.314-7.189 19.507 3.024 8.193 11.144 12.742 18.138 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.028 54.964c-7.616-.637-13.613 5.79-13.395 14.356.218 8.567 6.569 16.027 14.186 16.664 7.616.637 13.613-5.791 13.395-14.358M54.738 7.334h-5.873c-14.845 0-26.879 12.034-26.879 26.879v15.879"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.33}
                        x2={44.33}
                        y1={40.871}
                        y2={10.276}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#3ACEC7" />
                        <stop offset={0.859} stopColor="#BBE9E9" stopOpacity={0} />
                    </linearGradient>
                </defs> 
            </>
        ),
    },
    'Color #4': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M22.023 57.075h-7V34.213c0-18.711 15.168-33.879 33.88-33.879h17.81a7 7 0 0 1 7 7h-24.81c-14.846 0-26.88 12.034-26.88 26.879v22.862Z"
                />
                <path
                    fill="#82E56A"
                    d="M27.637 83.77c7.87-2.905 11.573-12.516 8.27-21.468-3.304-8.951-12.362-13.854-20.232-10.95-7.87 2.905-11.573 12.516-8.27 21.468 3.304 8.952 12.362 13.854 20.232 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.205 66.444c.154 9.39 7.197 17.504 15.731 18.12 8.534.618 15.328-6.494 15.174-15.885-.117-7.171-4.252-13.597-10-16.51"
                />
                <path
                    fill="#C9F7BC"
                    d="M23.936 85.306c6.993-2.58 10.212-11.314 7.189-19.507-3.023-8.193-11.144-12.742-18.137-10.161-6.994 2.58-10.213 11.314-7.19 19.507 3.024 8.193 11.144 12.742 18.138 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.067 54.964c-7.616-.637-13.613 5.79-13.395 14.356.218 8.567 6.569 16.027 14.186 16.664 7.616.637 13.613-5.791 13.395-14.358M54.775 7.334h-5.873c-14.845 0-26.879 12.034-26.879 26.879v15.879"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.368}
                        x2={44.368}
                        y1={40.871}
                        y2={10.276}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#82E56A" />
                        <stop offset={0.859} stopColor="#C9F7BC" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M22.064 57.075h-7V34.213c0-18.711 15.169-33.879 33.88-33.879h17.81a7 7 0 0 1 7 7h-24.81c-14.846 0-26.88 12.034-26.88 26.879v22.862Z"
                />
                <path
                    fill="#FF7E71"
                    d="M27.676 83.77c7.87-2.905 11.573-12.516 8.27-21.468-3.304-8.951-12.362-13.854-20.232-10.95-7.87 2.905-11.573 12.516-8.27 21.468 3.304 8.952 12.362 13.854 20.232 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.242 66.444c.154 9.39 7.197 17.504 15.731 18.12 8.534.618 15.328-6.494 15.174-15.885-.117-7.171-4.252-13.597-10-16.51"
                />
                <path
                    fill="#FFD9D9"
                    d="M23.975 85.306c6.993-2.58 10.212-11.314 7.189-19.507-3.023-8.193-11.144-12.742-18.137-10.161-6.994 2.58-10.213 11.314-7.19 19.507 3.024 8.193 11.144 12.742 18.138 10.161Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.106 54.964C10.49 54.327 4.493 60.754 4.71 69.32c.218 8.567 6.569 16.027 14.186 16.664 7.616.637 13.613-5.791 13.395-14.358M54.815 7.334h-5.873c-14.846 0-26.88 12.034-26.88 26.879v15.879"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.408}
                        x2={44.408}
                        y1={40.871}
                        y2={10.276}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#FF7E71" />
                        <stop offset={0.859} stopColor="#FFD9D9" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 74,
        height: 90,
        content: (
            <>
                <path
                    fill="url(#object-devices-headphones-a)"
                    d="M22.104 57.071h-7V34.21C15.104 15.5 30.271.33 48.983.33h17.81a7 7 0 0 1 7 7h-24.81c-14.845 0-26.88 12.034-26.88 26.88V57.07Z"
                />
                <path
                    fill="#FFD100"
                    d="M27.717 83.766c7.87-2.904 11.573-12.516 8.27-21.468-3.304-8.951-12.362-13.854-20.232-10.95-7.87 2.905-11.573 12.516-8.27 21.468 3.304 8.952 12.362 13.854 20.232 10.95Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.283 66.44c.154 9.391 7.197 17.504 15.731 18.121 8.534.617 15.328-6.495 15.174-15.886-.117-7.171-4.252-13.597-10-16.509"
                />
                <path
                    fill="#FFF4C3"
                    d="M24.014 85.302c6.994-2.58 10.212-11.314 7.189-19.507-3.023-8.193-11.144-12.742-18.137-10.161-6.994 2.58-10.213 11.314-7.19 19.507C8.9 83.333 17.02 87.883 24.014 85.302Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.145 54.96c-7.616-.637-13.613 5.79-13.395 14.357.218 8.566 6.569 16.026 14.186 16.663 7.616.637 13.613-5.791 13.395-14.357M54.855 7.33h-5.872c-14.845 0-26.88 12.034-26.88 26.88v15.878"
                />
                <defs>
                    <linearGradient
                        id="object-devices-headphones-a"
                        x1={44.449}
                        x2={44.449}
                        y1={40.867}
                        y2={10.272}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.226} stopColor="#FFD100" />
                        <stop offset={0.859} stopColor="#FFF4C3" stopOpacity={0} />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-headphones"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
