import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7695)"
            d="M524.44 280.019c-20.409-24.152-26.325-32.894-31.648-46.771-2.986-7.786-9.421-18.228-14.918-23.949a47.493 47.493 0 00-4.724-2.974c4.064 7.051 4.949 18.802-6.423 23.57a21.989 21.989 0 01-8.678 1.436c-10.921 0-21.127-6.506-31.94-13.399-7.282-4.644-14.812-9.444-22.911-12.429a32.696 32.696 0 00-10.802-1.712 31.608 31.608 0 00-4.391.292c-.16.023-.314.078-.452.162a62.74 62.74 0 00-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.614 45.361-27.936 63.292 14.883 0 179.194.875 179.224.714 9.23-48.617-.85-76.583-9.312-98.687a518.743 518.743 0 01-2.686-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.943-7.614-1.082-16.22-8.991-25.578z"
        ></path>
        <g filter="url(#filter0_f_11323_7695)">
            <path
                fill="url(#paint1_radial_11323_7695)"
                d="M410.676 290.993l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.265 14.814 1.029 21.605-1.697a30.978 30.978 0 0012.048-48.825l-6.917-10.635"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11323_7695"
                width="91.355"
                height="77.898"
                x="402.676"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7695"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7695"
                x1="345.547"
                x2="534.002"
                y1="317.109"
                y2="317.109"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11323_7695"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.981 -1072.037) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#64CCC9" stopOpacity="0"></stop>
                <stop offset="0.554" stopColor="#64CCC9" stopOpacity="0.258"></stop>
                <stop offset="0.746" stopColor="#64CCC9" stopOpacity="0.655"></stop>
                <stop offset="0.882" stopColor="#64CCC9" stopOpacity="0.904"></stop>
                <stop offset="0.949" stopColor="#64CCC9"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
