import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={1357}
            height={799}
            viewBox={`0 0 ${1357} ${799}`}
            fill="none"
            id="object-office-workplace"
            {...attributes}
        >
            <g
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#object-office-kitchen-a)"
            >
                <path
                    stroke="#000"
                    d="M73.677 456.103H1355.85v-28.537H1.084v28.537c5.33 0 44.615-.15 44.615-.15v341.53H530.12V474.794"
                />
                <path
                    stroke="#000"
                    d="M511.278 493.634H26.858v-18.841h503.261M511.278 586.724H26.858v-18.841H1279.3v-88.022M511.279 586.724v-93.089M57.193 427.566V1.629h128.463M733.996 1.627h577.894v68.97H985.996v233.62h275.874V135.776H948.096v149.253h118.234V116.936h245.56v289.501M733.996 1.627v211.825H616.245V41.417h136.592v153.195H635.086V1.627h-364.55V328.01h155.75V102.934H251.695v244.099h155.75v-75.1h469.411v116.98h-89.81v-89.43M257.783 517.635h60.252M257.783 610.725h60.252"
                />
                <path
                    stroke="#222731"
                    d="M301.043 153.891h94.737M301.043 172.912h94.737M301.043 191.936h94.737M1095.22 181.176h137.75M1095.22 200.197h137.75M1095.22 219.219h137.75"
                />
            </g>
            <defs>
                <clipPath id="object-office-workplace-a">
                    <path fill="#fff" d="M.084.627h1356.76v797.856H.084z" />
                </clipPath>
            </defs>
        </svg>
    );
}

