import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-4">
        <path
            d="M413.435 265.22c.085-3.028.133-6.084.141-9.149a161.97 161.97 0 0 0-1.334-22.991c-.293-2.281-.604-4.56-.915-6.839-.706-5.185-1.436-10.547-1.942-15.833l-.017-.17a31.885 31.885 0 0 0-10.339-20.535 31.884 31.884 0 0 0-21.462-8.246 32.035 32.035 0 0 0-31.875 35.225c.535 5.279.864 10.679 1.182 15.9.14 2.298.28 4.595.437 6.888a162.271 162.271 0 0 0 3.173 22.805 363.156 363.156 0 0 0 2.528 11.584l60.423-8.639Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-4a)"
        />
        <path
            d="M345.693 216.682c.535 5.28.864 10.678 1.182 15.9.14 2.297.28 4.595.437 6.888a162.271 162.271 0 0 0 3.173 22.805 363.156 363.156 0 0 0 2.528 11.584l60.423-8.639c.085-3.027.133-6.084.141-9.149a161.987 161.987 0 0 0-1.334-22.991 876.48 876.48 0 0 0-.915-6.839c-.706-5.185-1.436-10.546-1.942-15.833l-.017-.17"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-4a"
                x1={385.537}
                y1={296.594}
                x2={372.525}
                y2={159.968}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
