import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11369_7145)"
            d="M507.236 513.416c.126-5.601-.149-14.913.143-19.698-11.999.028-93.578.542-114.578.542-28.918-5.248-53.675-32.892-47.024-66.289 7.188-36.098 45.982-45.884 80.819-39.723 18.961 3.353 38.858 7.805 56.494 12.686 17.101 4.96 36.53 13.611 52.518 22.338 14.97 7.682 29.55 19.157 30.675 38.023 1.648 43.349 3.585 93.225 4.788 136.341l-48.206 3.093s-16.467-50.173-15.629-87.313z"
        ></path>
        <path
            fill="url(#paint1_linear_11369_7145)"
            d="M517.656 582.733l52.85-4.15a7303.22 7303.22 0 00-.604-18.661l-56.654 4.449a409.734 409.734 0 004.408 18.362z"
        ></path>
        <path
            fill="url(#paint2_linear_11369_7145)"
            d="M517.656 582.733l52.85-4.15a7303.22 7303.22 0 00-.604-18.661l-56.654 4.449a409.734 409.734 0 004.408 18.362z"
        ></path>
        <path
            fill="url(#paint3_linear_11369_7145)"
            d="M507.701 528.261c.476 6.138 1.264 12.394 2.24 18.532l59.351-4.661c-.218-6.206-.441-12.425-.669-18.655l-60.922 4.784z"
        ></path>
        <path
            fill="url(#paint4_linear_11369_7145)"
            d="M507.701 528.261c.476 6.138 1.264 12.394 2.24 18.532l59.351-4.661c-.218-6.206-.441-12.425-.669-18.655l-60.922 4.784z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11369_7145"
                x1="434.173"
                x2="540.872"
                y1="507.785"
                y2="472.653"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#001489"></stop>
                <stop offset="1" stopColor="#0147A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11369_7145"
                x1="513.248"
                x2="570.506"
                y1="571.327"
                y2="571.327"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#AA0061"></stop>
                <stop offset="1" stopColor="#D7003C"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11369_7145"
                x1="513.248"
                x2="570.506"
                y1="571.327"
                y2="571.327"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#AA0061"></stop>
                <stop offset="1" stopColor="#D7003C"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_11369_7145"
                x1="507.701"
                x2="569.291"
                y1="535.135"
                y2="535.135"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#AA0061"></stop>
                <stop offset="1" stopColor="#D7003C"></stop>
            </linearGradient>
            <linearGradient
                id="paint4_linear_11369_7145"
                x1="507.701"
                x2="569.291"
                y1="535.135"
                y2="535.135"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#AA0061"></stop>
                <stop offset="1" stopColor="#D7003C"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
