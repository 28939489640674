import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={182}
            height={79}
            fill="none"
            id="object-food-spinach"
            {...attributes}
        >
            <path
                fill="url(#object-food-spinach-a)"
                d="M179.364 49.038c-23.572 13.388-30.985 20.67-53.926 14.35-22.942-6.322-91.505-38.292-87.374-53.285 4.13-14.993 79.393-7.332 102.334-1.011 22.942 6.322 27.354 19.503 38.965 39.946h.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M179.364 49.038c-23.572 13.388-30.985 20.67-53.926 14.35-22.942-6.322-91.505-38.292-87.374-53.285 4.13-14.993 79.393-7.332 102.334-1.011 22.942 6.322 27.354 19.503 38.965 39.946h.001Z"
            />
            <path
                fill="url(#object-food-spinach-b)"
                d="M165.115 45.592c-21.411 21.41-27.231 31.453-53.811 31.453S1.41 62.963 1.41 45.592s83.315-31.453 109.895-31.453 35.241 12.884 53.81 31.453Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M27.981 28.385c26.772-8.578 66.463-14.244 83.323-14.244 26.579 0 35.242 12.884 53.811 31.453-21.411 21.41-27.231 31.453-53.811 31.453S1.41 62.964 1.41 45.593c0-4.187 4.84-8.182 12.558-11.836"
            />
            <path
                stroke="#00B140"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M126.967 45.592H36.398M73.663 45.592 59.957 31.887M103.094 45.592 86.736 29.234M53.451 45.592 42.525 56.518M87.147 45.592 76.22 56.518"
            />
            <path
                fill="url(#object-food-spinach-c)"
                d="M180.146 41.234c-76.547 0-113.431 16.481-145.831 35.811h93.978c38.968 0 51.853-16.48 51.853-35.81Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M131.506 43.881c-45.31 5.408-72.547 18.462-97.191 33.164h93.978c38.968 0 51.853-16.48 51.853-35.81-10.923 0-21.039.335-30.449.966"
            />
            <defs>
                <linearGradient
                    id="object-food-spinach-a"
                    x1={103.839}
                    x2={88.681}
                    y1={11.84}
                    y2={-47.654}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-food-spinach-b"
                    x1={100.634}
                    x2={57.055}
                    y1={64.221}
                    y2={20.642}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-food-spinach-c"
                    x1={143.548}
                    x2={93.148}
                    y1={77.435}
                    y2={52.045}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
            </defs>
        </svg>
    );
}
