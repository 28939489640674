import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-4-stand-1">
            <path
                fill="#001489"
                d="M457.304 798.914c-.389 4.322-4.629 20.354-7.463 30.744a8.98 8.98 0 0 0 1.002 7.058 8.999 8.999 0 0 0 5.824 4.112l19.892 4.196a42.195 42.195 0 0 1 12.113 4.529 69.67 69.67 0 0 0 19.451 7.231c4.008.873 19.039 1.815 26.247 2.228a4.151 4.151 0 0 0 4.361-3.662 4.147 4.147 0 0 0-1.929-4.013c-2.852-1.772-7.908-4.872-12.021-7.188-18.464-10.397-35.661-38.523-35.661-38.523"
            />
            <mask
                id="man-34-25-shoes-4-stand-1-a"
                width="92"
                height="45"
                x="448"
                y="816"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M534.611 860.018c-.099 0-.199-.003-.298-.009-8.149-.467-22.414-1.38-26.404-2.248a70.78 70.78 0 0 1-19.731-7.339 41.131 41.131 0 0 0-11.827-4.42l-19.891-4.196a9.906 9.906 0 0 1-6.472-4.573 9.887 9.887 0 0 1-1.113-7.838c1.192-4.365 2.334-8.644 3.303-12.374a1 1 0 0 1 1.144-.732 65.396 65.396 0 0 1 11.527 3.582c.653.247 1.305.495 1.956.738 5.891 2.197 12.155 4.014 18.034 2.433a32.968 32.968 0 0 0 6.509-2.804c1.052-.543 2.139-1.104 3.241-1.605a24.299 24.299 0 0 1 3.153-1.18 1.003 1.003 0 0 1 1.079.352c6.454 8.46 16.282 19.746 26.45 25.472 4.183 2.355 9.35 5.528 12.058 7.21a5.163 5.163 0 0 1 2.396 4.98 5.16 5.16 0 0 1-5.114 4.551Z"
                />
            </mask>
            <g mask="url(#man-34-25-shoes-4-stand-1-a)">
                <path
                    fill="#009CDE"
                    d="M534.611 860.018c-.099 0-.199-.003-.298-.009-8.149-.467-22.414-1.38-26.404-2.248a70.78 70.78 0 0 1-19.731-7.339 41.131 41.131 0 0 0-11.827-4.42l-19.891-4.196a9.906 9.906 0 0 1-6.472-4.573 9.887 9.887 0 0 1-1.113-7.838c1.192-4.365 2.334-8.644 3.303-12.374a1 1 0 0 1 1.144-.732 65.396 65.396 0 0 1 11.527 3.582c.653.247 1.305.495 1.956.738 5.891 2.197 12.155 4.014 18.034 2.433a32.968 32.968 0 0 0 6.509-2.804c1.052-.543 2.139-1.104 3.241-1.605a24.299 24.299 0 0 1 3.153-1.18 1.003 1.003 0 0 1 1.079.352c6.454 8.46 16.282 19.746 26.45 25.472 4.183 2.355 9.35 5.528 12.058 7.21a5.163 5.163 0 0 1 2.396 4.98 5.16 5.16 0 0 1-5.114 4.551Z"
                />
                <path
                    fill="#001489"
                    d="M471.387 858.969c-1.829-9.858 7.897-19.897 15.93-23.965a63.138 63.138 0 0 1 16.155-5.338c5.455-1.057 12.783-.314 17.483-3.628 1.554-1.096 1.507-3.709-.554-4.241-3.711-.957-7.725-.107-11.499.303a77.187 77.187 0 0 0-11.753 1.998c-6.644 1.784-13.509 4.065-19.02 8.305-7.615 5.858-17.513 18.7-10.855 28.3a2.266 2.266 0 0 0 2.793.88 2.266 2.266 0 0 0 1.32-2.614Z"
                />
                <path
                    fill="#001489"
                    d="M485.974 858.362c-.188-8.306 8.143-14.108 14.58-17.872a44.218 44.218 0 0 1 12.927-5.193c5.036-1.09 10.336-.373 15.174-2.268 2.061-.808 2.664-4.277 0-4.814-11.546-2.326-24.288 1.486-33.943 7.87-7.619 5.038-14.697 12.477-13.449 22.277.371 2.909 4.781 3.122 4.711 0Z"
                />
                <path
                    fill="#001489"
                    d="M498.847 860.336c1.112-7.453 6.341-11.276 12.82-14.298a63.298 63.298 0 0 1 12.2-4.182c3.856-.91 7.923-1.054 11.619-2.514 2.32-.917 2.874-4.599 0-5.289-9.317-2.236-21.479 2.729-29.467 7.338-7.144 4.123-11.592 9.873-11.522 18.357.02 2.398 3.97 3.134 4.35.588Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M538.151 858.603a5.143 5.143 0 0 1-3.838 1.407c-8.148-.467-22.414-1.38-26.403-2.248a70.797 70.797 0 0 1-19.732-7.339 41.136 41.136 0 0 0-11.826-4.42l-19.892-4.196a9.912 9.912 0 0 1-6.472-4.573"
            />
            <path
                fill="#001489"
                d="M397.039 818.086c.511 4.31-.328 20.871-.957 31.623a8.99 8.99 0 0 0 5.42 8.785 8.994 8.994 0 0 0 3.565.735h57.647a4.447 4.447 0 0 0 2.104-8.36c-3.792-2.032-11.23-6.09-14.957-8.599-8.486-5.714-20.306-24.184-20.306-24.184"
            />
            <mask
                id="man-34-25-shoes-4-stand-1-b"
                width="74"
                height="38"
                x="395"
                y="823"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M462.716 860.226h-57.648a9.907 9.907 0 0 1-7.277-3.138 9.886 9.886 0 0 1-2.705-7.44c.192-3.288.403-7.12.591-10.98a.995.995 0 0 1 .794-.93.983.983 0 0 1 .435.005 15.028 15.028 0 0 0 12.593-2.603 30.3 30.3 0 0 0 3.72-3.647 40.083 40.083 0 0 1 3.132-3.187 21.124 21.124 0 0 1 18.021-4.47 1 1 0 0 1 .605.396c4.312 6.008 10.492 13.871 15.447 17.207 3.659 2.464 10.887 6.412 14.871 8.546a5.43 5.43 0 0 1 2.867 4.797 5.45 5.45 0 0 1-5.446 5.444Z"
                />
            </mask>
            <g mask="url(#man-34-25-shoes-4-stand-1-b)">
                <path
                    fill="#009CDE"
                    d="M462.716 860.226h-57.648a9.907 9.907 0 0 1-7.277-3.138 9.886 9.886 0 0 1-2.705-7.44c.192-3.288.403-7.12.591-10.98a.995.995 0 0 1 .794-.93.983.983 0 0 1 .435.005 15.028 15.028 0 0 0 12.593-2.603 30.3 30.3 0 0 0 3.72-3.647 40.083 40.083 0 0 1 3.132-3.187 21.124 21.124 0 0 1 18.021-4.47 1 1 0 0 1 .605.396c4.312 6.008 10.492 13.871 15.447 17.207 3.659 2.464 10.887 6.412 14.871 8.546a5.43 5.43 0 0 1 2.867 4.797 5.45 5.45 0 0 1-5.446 5.444Z"
                />
                <path
                    fill="#001489"
                    d="M408.487 864.249c-2.117-9.876 7.296-20.178 15.308-24.49a63.075 63.075 0 0 1 15.831-5.737c5.501-1.227 12.772-.651 17.411-4.111 1.469-1.097 1.576-3.756-.555-4.241-3.753-.855-7.676.102-11.454.608a74.82 74.82 0 0 0-11.694 2.302c-6.292 1.886-12.75 4.205-18.03 8.199-7.784 5.888-18.008 19.575-10.93 29.204a2.264 2.264 0 0 0 2.792.879 2.268 2.268 0 0 0 1.321-2.613Z"
                />
                <path
                    fill="#001489"
                    d="M420.371 862.711c-.435-8.558 8.156-14.799 14.735-18.757a43.313 43.313 0 0 1 13.742-5.508c4.783-.96 9.458-.631 14.003-2.556 1.931-.818 1.427-4.215-.596-4.562-11.815-2.028-24.675 2.226-34.24 9.131-7.625 5.505-13.815 12.993-12.271 22.879.409 2.621 4.76 2.005 4.627-.627Z"
                />
                <path
                    fill="#001489"
                    d="M430.496 863.154c.935-7.764 7.255-11.751 13.805-14.817 7.336-3.433 15.447-3.807 22.746-6.926 2.17-.927 1.523-4.554-.655-5.012-9.381-1.97-21.373 3.254-29.243 8.095-7.027 4.323-11.391 10.163-11.083 18.66.106 2.919 4.096 2.768 4.43 0Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.922 858.236a5.425 5.425 0 0 1-1.88 1.469 5.42 5.42 0 0 1-2.328.523h-57.648a9.906 9.906 0 0 1-7.277-3.138"
            />
        </g>
    );
}
