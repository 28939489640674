import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-6">
        <path
            d="M570.827 269.289c-.686 0-1.379-.03-2.057-.089a236.334 236.334 0 0 1-25.021-3.825c-7.811-1.582-15.744-3.466-24.255-5.76a188.458 188.458 0 0 1-23.558-7.798c-2.296-.929-4.588-1.876-6.88-2.822-5.219-2.154-10.616-4.382-15.989-6.37a32.033 32.033 0 0 1-18.907-41.116 32.136 32.136 0 0 1 30.015-20.904c3.79 0 7.549.677 11.101 1.997l.149.055c5.363 2.016 10.898 3.865 16.251 5.652 2.355.787 4.71 1.573 7.059 2.376.329.111.657.225.985.342l-.01-.022c-1.29-2.663-3.478-7.316-5.234-11.055-.784-1.667-3.196-6.778-3.196-6.778l2.448 1.073c1.508.639 3.132.956 4.769.933 9.88 0 21.723-9.759 22.609-16.627l.301-2.332 1.471 1.835c1.154 1.44 2.691 3.304 4.275 5.226 2.072 2.514 4.214 5.113 5.705 7.009 2.682 3.384 5.299 6.856 7.75 10.121 1.737 2.275 3.456 4.621 5.119 6.89.813 1.111 1.629 2.22 2.446 3.327 5.493 7.549 10.233 14.378 14.495 20.885l6.913 10.622 6.962 10.581a23.368 23.368 0 0 1 3.384 17.67 23.337 23.337 0 0 1-10.101 14.888 23.41 23.41 0 0 1-15.345 3.744 24.97 24.97 0 0 1-3.654.272Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-6-a)"
        />
        <path
            d="M473.067 242.625c5.373 1.988 10.77 4.216 15.989 6.37 2.292.946 4.585 1.892 6.881 2.822a188.443 188.443 0 0 0 23.557 7.798c8.511 2.294 16.444 4.178 24.256 5.76a236.812 236.812 0 0 0 25.021 3.825c.677.059 1.37.089 2.056.089a24.833 24.833 0 0 0 3.654-.273 23.42 23.42 0 0 0 15.345-3.744 23.349 23.349 0 0 0 10.101-14.887 23.356 23.356 0 0 0-3.385-17.67l-6.962-10.581-6.912-10.622c-4.262-6.507-9.002-13.336-14.495-20.885a644.816 644.816 0 0 1-2.446-3.327c-1.663-2.269-3.383-4.615-5.12-6.89-2.45-3.266-5.067-6.737-7.75-10.121-1.49-1.896-3.633-4.496-5.704-7.009-1.584-1.922-3.122-3.787-4.276-5.226l-1.471-1.835-.3 2.332c-.887 6.868-12.729 16.627-22.609 16.627a11.794 11.794 0 0 1-4.769-.933l-2.448-1.073s2.411 5.11 3.195 6.778c1.757 3.739 3.944 8.392 5.234 11.055l.01.022c-.328-.116-.656-.23-.985-.342-2.348-.803-4.703-1.589-7.058-2.376-4.527-1.512-9.179-3.074-13.753-4.737a121.15 121.15 0 0 0-30.076-15.297l-2.654-.951"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m549.884 246.275-6.519-10.864c-4.324-7.256-8.424-14.653-12.395-22.13-1.957-3.757-3.965-7.481-5.867-11.271-1.933-3.772-3.855-7.55-5.693-11.381"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-6-a"
                x1={483.036}
                y1={355.819}
                x2={542.241}
                y2={181.457}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.644} stopColor="#fff" />
                <stop offset={0.934} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
