import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={169}
            height={155}
            fill="none"
            id="object-devices-tablet"
            {...attributes}
        >
            <path
                fill="#63666A"
                d="M111.481 141.684H1.207l55.65-132.21h110.274l-55.65 132.21Z"
            />
            <path
                fill="url(#object-devices-tablet-a)"
                d="M122.04 153.62H11.766L56.86 9.475h110.274L122.04 153.62Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M56.86 9.475h110.275L122.04 153.62H11.766"
            />
            <path
                fill="#63666A"
                d="M132.632 18.898 138.529.057H91.131l-5.897 18.84h47.398Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1.207 141.684 55.65-132.21"
            />
            <defs>
                <linearGradient
                    id="object-devices-tablet-a"
                    x1={149.389}
                    x2={68.295}
                    y1={215.99}
                    y2={34.095}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.183} stopColor="#A8ABAC" />
                    <stop offset={0.424} stopColor="#CECFD0" />
                    <stop offset={0.648} stopColor="#E8E9E9" />
                    <stop offset={0.847} stopColor="#F9F9F9" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
            </defs>
        </svg>
    );
}
