import React from 'react';

const SvgComponent = () => (
    <g id="man-bold-34-25-emotion-confused" transform="translate(329, 20)">
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M147.35 115.4c-2.1-2.6-6.5-4.6-15.2-3.9"
        />
        <path
            fill="#111011"
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M156.549 89c-.773 0-1.4-1.03-1.4-2.3 0-1.27.627-2.3 1.4-2.3.774 0 1.4 1.03 1.4 2.3 0 1.27-.626 2.3-1.4 2.3Zm-25.6 0c-.773 0-1.399-1.03-1.399-2.3 0-1.27.626-2.3 1.399-2.3.774 0 1.401 1.03 1.401 2.3 0 1.27-.627 2.3-1.401 2.3Z"
        />
        <path
            stroke="url(#man-bold-34-25-emotion-confused-a)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M162.049 73.3c-2.7-.8-6.5-.7-9-.6"
        />
        <path
            stroke="url(#man-bold-34-25-emotion-confused-b)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M139.949 65.5c-6.1 2-12.2 4.9-15.6 7.9"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-emotion-confused-a"
                x1={164.453}
                x2={150.861}
                y1={71.769}
                y2={74.081}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-emotion-confused-b"
                x1={142.646}
                x2={121.471}
                y1={65.935}
                y2={73.046}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
