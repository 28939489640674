import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M516.143 288.631c-8.03.224-15.925.99-23.779 1.917-1.96.245-3.93.453-5.882.728l-5.856.837c-3.914.516-7.777 1.238-11.664 1.86a228.167 228.167 0 0 0-23.007 5.007l-7.524 2.092a324.03 324.03 0 0 0-2.562-7.695c-2.519-7.15-5.293-14.162-8.25-21.073a164.057 164.057 0 0 0-10.025-20.101c-3.782-6.459-6.559-11.639-10.136-18.21l-.066-.121a26.507 26.507 0 0 0-15.843-12.664 26.509 26.509 0 0 0-28.065 8.91 26.5 26.5 0 0 0-2.508 29.339c3.611 6.552 5.7 12.108 9.107 18.773a164.015 164.015 0 0 0 11.543 19.269c4.232 6.212 8.647 12.324 13.317 18.297a205.505 205.505 0 0 0 15.022 17.363 19.408 19.408 0 0 0 6.45 4.296 18.45 18.45 0 0 0 15.794 3.589l11.457-2.691 11.491-2.553a228.082 228.082 0 0 0 22.657-6.404c3.724-1.28 7.472-2.459 11.168-3.845l5.561-2.017c1.852-.676 3.688-1.418 5.534-2.124 7.367-2.877 14.693-5.914 21.885-9.495a12.292 12.292 0 0 0 1.95-20.79 12.293 12.293 0 0 0-7.769-2.494Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M409.963 333.16a205.505 205.505 0 0 1-15.022-17.363c-4.669-5.973-9.085-12.085-13.317-18.297a164.007 164.007 0 0 1-11.542-19.269c-3.407-6.665-5.496-12.221-9.108-18.774-5.298-9.613-2.412-24.478 5.273-33.652M407.391 233.875l.066.122c3.577 6.571 6.354 11.751 10.136 18.209a164.2 164.2 0 0 1 10.025 20.101c2.956 6.911 5.73 13.923 8.25 21.073.446 1.281.886 2.565 1.32 3.853M521.962 311.914c-7.192 3.58-14.518 6.618-21.885 9.494-1.846.706-3.682 1.449-5.534 2.125l-5.561 2.017c-3.696 1.386-7.444 2.565-11.168 3.845a228.353 228.353 0 0 1-22.657 6.404l-11.491 2.552-11.457 2.691c-6.633 1.559-16.3-1.526-22.244-7.884M434.613 302.135l11.341-3.154a228.442 228.442 0 0 1 23.007-5.007c3.887-.622 7.75-1.344 11.664-1.86l5.855-.836c1.953-.275 3.923-.483 5.883-.728 7.854-.928 15.749-1.694 23.779-1.917"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M585.207 267.956a3.925 3.925 0 0 0-1.977-.56 2.44 2.44 0 0 0-.072-1.442l-.013-.036a2.472 2.472 0 0 0-2.917-1.582 22.088 22.088 0 0 0-6.637 3.264c-2.69 1.82-5.228 3.904-8.163 5.295l-.897.192a33.074 33.074 0 0 1 6.794-6.992 1.931 1.931 0 0 0 .606-2.445 1.937 1.937 0 0 0-2.336-.975 32.811 32.811 0 0 0-10.254 6.294c-3.521 2.949-6.835 6.192-10.748 8.596-3.914 2.404-8.613 3.927-13.112 3.004l-1.755-.47c.298-2.332.16-4.699-.408-6.981a2.616 2.616 0 0 0-5.04-.012c-.913 3.315-3.133 6.227-5.195 9.045-2.432 3.322-4.835 7.054-4.682 11.167.308 11.63 3.389 17.095 10.127 14.43a212.737 212.737 0 0 0 23.879-15.001l.413-.072c9.188-3.047 17.024-9.101 24.662-15.048l6.265-4.879a6.228 6.228 0 0 0 1.995-2.138 2.099 2.099 0 0 0-.535-2.654Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M518.401 293.32c-.153-4.113 2.251-7.845 4.682-11.167 2.062-2.818 4.282-5.73 5.195-9.045a2.609 2.609 0 0 1 2.398-1.91 2.611 2.611 0 0 1 2.643 1.922c.971 3.775.69 7.763-.799 11.365M550.89 287.341c4.696-2.301 8.037-6.612 11.117-10.838 2.755-3.778 5.546-7.706 9.318-10.408a1.933 1.933 0 0 0 .606-2.445 1.937 1.937 0 0 0-2.336-.975 32.827 32.827 0 0 0-10.255 6.294c-3.52 2.949-6.834 6.192-10.747 8.596-3.914 2.403-8.613 3.927-13.113 3.004"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M528.527 307.755a212.902 212.902 0 0 0 40.832-28.663 130.593 130.593 0 0 1 12.852-10.232 2.492 2.492 0 0 0 .947-2.898l-.013-.037a2.476 2.476 0 0 0-2.917-1.581 22.086 22.086 0 0 0-6.637 3.263c-2.69 1.82-5.229 3.904-8.163 5.296"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M552.82 292.68c9.189-3.047 17.024-9.102 24.662-15.049l6.266-4.878a6.247 6.247 0 0 0 1.995-2.138 2.139 2.139 0 0 0-.535-2.654"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
