import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6549)"
            d="M365.397 271.954c-.672-1.383-1.328-2.777-1.984-4.17-1.435-3.048-2.918-6.2-4.554-9.187a27.497 27.497 0 0110.916-37.328 27.501 27.501 0 0137.328 10.916l.154.286c1.6 3.006 3.422 5.976 5.183 8.849-8.363-4.08-43.495.8-47.043 30.634z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6549"
                x1="365.046"
                x2="382.612"
                y1="225.782"
                y2="247.252"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
