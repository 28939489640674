import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7265)"
            d="M494.773 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7265)"
            d="M510.401 244.056c-2.42-3.874-4.923-7.88-7.207-11.911l-.079-.139a27.586 27.586 0 00-23.872-13.811 27.497 27.497 0 00-13.67 3.658 27.311 27.311 0 00-12.852 16.668 27.317 27.317 0 002.698 20.874c2.306 4.014 4.478 8.206 6.579 12.26.426.821.856 1.637 1.282 2.457 0 0 13.238-12.559 23.647-18.306a140.628 140.628 0 0124.359-10.326c-.296-.475-.589-.95-.885-1.424z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7265"
                x1="499.405"
                x2="421.332"
                y1="209.359"
                y2="251.865"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7265"
                x1="507.624"
                x2="429.552"
                y1="224.459"
                y2="266.965"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
