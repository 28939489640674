import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-right-option-5">
        <path
            fill="url(#man-34-25-doctors-coat-arm-right-option-5-a)"
            d="M487.158 263.4c-2.8.2-5.7.4-8.5.6-1.6.1-3.101.2-4.701.3-4.5.3-9 .7-13.2 1.1-3 .2-5.999.5-8.899.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.901.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.799-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2c-3.6-14.3-16.3-24.3-31-24.3-2.6 0-5.2.3-7.8 1-17.1 4.3-27.6 21.7-23.3 38.8 1.2 4.9 2.299 9.9 3.199 14.8.4 2.1.901 4.3 1.301 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.6 2.8 3.7 4 7.8 12.199 12.8 20.899 12.8 1.5 0 3-.1 4.5-.4l12.901-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.4 8.6-2.2 4.1-1.1 8.499-2.2 12.799-3.4 1.5-.4 3-.8 4.5-1.3 2.7-.8 5.501-1.5 8.201-2.3h.2s-7-9.4-7.2-18.6c-.1-8.4 2.9-17.3 2.9-17.3-.5.1-1.2.1-1.9.1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M346.057 221.4c1.2 4.9 2.3 9.9 3.2 14.8.4 2.1.9 4.3 1.3 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.6 2.8 3.7 4 7.8 12.2 12.8 20.9 12.8 1.5 0 3-.1 4.5-.4l12.9-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.4 8.6-2.2 4.1-1.1 25.3-7.8 25.4-7.8 0 0-6-6.7-6.8-19.2-.7-10 2.5-15 2.5-15-.7 0-24 .7-28.2 1.1-3 .2-6 .5-8.9.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-right-option-5-a"
                x1={418.278}
                x2={421.748}
                y1={350.099}
                y2={34.34}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
