import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6746)"
            d="M494.768 222.602a109.64 109.64 0 00-20.026-15.329 31.037 31.037 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.002 1.002 0 001.33.492c2.598-1.2 15.601-7.426 16.406-12.092a2.713 2.713 0 00-.822-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6746)"
            d="M532.041 412.778c-6.06-8.26-11.323-16.682-16.414-24.826l-.128-.205c-10.565-16.789-16.215-29.272-23.368-45.077-1.185-2.617-2.406-5.316-3.693-8.128-.118-.254-.22-.516-.321-.78l-.061-.155c-10.786-15.509-23.593-41.841-28.622-58.828-.621-2.074-1.228-4.158-1.836-6.241-1.378-4.727-2.802-9.614-4.39-14.348a27.304 27.304 0 011.453-20.998 27.339 27.339 0 016.552-8.5 27.338 27.338 0 019.322-5.32 27.474 27.474 0 0134.817 17.328c1.615 4.813 3.403 9.024 4.894 13.012 4.735 12.669 8.577 23.521 12.567 49.607 1.244 8.318 2.538 14.649 2.794 20.803a382.683 382.683 0 0131.82 79.553l.209.776-25.088 13.018-.507-.691z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6746"
                x1="488.141"
                x2="436.671"
                y1="213.359"
                y2="270.612"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6746"
                x1="549.164"
                x2="497.694"
                y1="268.216"
                y2="325.469"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
