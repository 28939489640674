import React from 'react';

const SvgComponent = () => (
    <g>

        <path
            fill="url(#paint0_linear_11313_6332)"
            d="M524.499 279.902c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.777 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.748 62.748 0 00-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.875 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 01-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
        ></path>
        <path
            fill="url(#paint1_linear_11313_6332)"
            d="M464.109 202.109a143.834 143.834 0 0113.155 7.829l-13.155-7.829z"
        ></path>
        <g filter="url(#filter0_f_11313_6332)">
            <path
                fill="url(#paint2_radial_11313_6332)"
                d="M410.73 290.876l5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0012.049-48.824l-6.917-10.636"
            ></path>
        </g>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M407.531 194.484c11.037 8.028 21.706 14.536 31.632 17.961l24.552 18.979 8-19.516 7.31 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M439.165 212.447l-9.445 13.073-20.923-22.629M498.824 329.391c.701 5.497 2.456 10.966 3.87 16.409a138.368 138.368 0 01-3.191 80.044M468.242 235.719c10.049 9.027 20.174 21.446 27.52 31.822 7.347 10.377 11.803 18.941 9.682 30.569-1.446 7.92-5.864 15.449-6.755 23.414"
        ></path>
        <defs>
            <filter
                id="filter0_f_11313_6332"
                width="91.356"
                height="77.898"
                x="402.73"
                y="251.023"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11313_6332"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11313_6332"
                x1="515.577"
                x2="358.782"
                y1="310.405"
                y2="314.309"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#40C1E9"></stop>
                <stop offset="1" stopColor="#0197D6"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11313_6332"
                x1="512.95"
                x2="356.155"
                y1="204.971"
                y2="208.875"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#40C1E9"></stop>
                <stop offset="1" stopColor="#0197D6"></stop>
            </linearGradient>
            <radialGradient
                id="paint2_radial_11313_6332"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.439" stopColor="#009CDE" stopOpacity="0"></stop>
                <stop offset="0.584" stopColor="#009CDE" stopOpacity="0.318"></stop>
                <stop offset="0.761" stopColor="#009CDE" stopOpacity="0.682"></stop>
                <stop offset="0.887" stopColor="#009CDE" stopOpacity="0.912"></stop>
                <stop offset="0.949" stopColor="#009CDE"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
