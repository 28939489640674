import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6798)"
            d="M575.022 555.418c-.158-11.596-.452-32.254-.604-44.101-.313-16.689-1.163-60.165-1.503-77.478-.245-12.186-9.456-22.937-20.091-28.873-11.835-6.605-24.396-13.56-33.24-17.64a166.525 166.525 0 00-41.6-13.245 291.632 291.632 0 00-37.909-5.247c-6.508-.489-14.863-.219-21.371-.013-16.929.305-37.995 6.049-49.325 19.981-16.443 20.219-8.522 56.635 4.263 72.043a27.871 27.871 0 006.18 5.406 46.73 46.73 0 0030.674 11.431h99.754c-.95 21.803-.308 44.855 4.473 63.936 3.626 14.723 17.504 80.77 19.239 90.095h45.47c-.211-23-4.087-57.453-4.41-76.295z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6798)"
            d="M531.758 620.731l47.362.106c-.233-5.289-.575-10.888-.964-16.57l-49.844-.115a4466.142 4466.142 0 013.446 16.579z"
        ></path>
        <path
            fill="url(#paint2_linear_10977_6798)"
            d="M514.724 541.616c1.849 7.51 6.366 28.373 10.578 48.251l51.815.098c-.985-13.105-1.941-25.592-2.095-34.55-.059-4.34-.137-9.948-.22-15.89l-60.598-.127c.171.742.337 1.488.52 2.218z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6798"
                x1="367.463"
                x2="617.353"
                y1="598.892"
                y2="390.65"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6798"
                x1="528.312"
                x2="579.12"
                y1="612.494"
                y2="612.494"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_10977_6798"
                x1="514.204"
                x2="577.116"
                y1="564.682"
                y2="564.682"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
