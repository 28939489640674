import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M493.684 223.908c-8.491-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.958 15.305-12.953Z"
            fill="url(#female-34-25-shirt-1-arm-opt-3-left-a)"
        />
        <path
            d="M462.775 202.656a132.19 132.19 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M585.668 284.764c-.963-4.683-1.598-9.432-2.375-14.152a85.093 85.093 0 0 0-3.547-13.914c-.806-2.276-1.511-4.572-2.424-6.826l-1.307-3.394c-.439-1.13-.946-2.247-1.416-3.372-3.267 6.676-19.152 13.778-31.18 6.304l-.001.11a492.938 492.938 0 0 0-8.455-5.226 199.783 199.783 0 0 0-21.891-11.653c-7.518-3.432-15.113-6.705-22.528-10.348l-.113-.055a26.502 26.502 0 0 0-35.412 12.269 26.514 26.514 0 0 0-1.199 20.247 26.497 26.497 0 0 0 13.468 15.164c7.432 3.608 14.684 7.585 22.014 11.402a199.83 199.83 0 0 0 22.651 10.098c7.742 2.971 15.577 5.755 23.538 8.278a250.001 250.001 0 0 0 24.391 6.533c1.293.261 2.609.388 3.928.379a18.491 18.491 0 0 0 16.815-5.032 18.501 18.501 0 0 0 5.043-16.812Z"
            fill="url(#female-34-25-shirt-1-arm-opt-3-left-b)"
        />
        <path
            d="M559.883 306.225a250.21 250.21 0 0 1-24.391-6.533c-7.961-2.523-15.796-5.307-23.538-8.279a199.824 199.824 0 0 1-22.651-10.097c-7.33-3.818-14.582-7.795-22.014-11.402M490.432 222.234l.112.055c7.415 3.643 15.011 6.916 22.529 10.349a199.775 199.775 0 0 1 21.891 11.652c1.346.811 2.689 1.628 4.028 2.452M574.599 243.102c.47 1.124.977 2.241 1.416 3.371l1.307 3.394c.913 2.254 1.618 4.55 2.424 6.826a85.12 85.12 0 0 1 3.547 13.914c.777 4.72 1.412 9.469 2.375 14.152a18.506 18.506 0 0 1-23.469 21.44M546.092 278.129a85.088 85.088 0 0 1-2.136-14.2c-.141-2.41-.384-4.8-.418-7.231l-.114-3.635c-.034-1.212-.001-2.438-.005-3.657"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M574.6 243.102c-3.267 6.675-19.153 13.778-31.18 6.303"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-3-left-a"
                x1={478.492}
                y1={214.109}
                x2={462.589}
                y2={293.627}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-3-left-b"
                x1={527.786}
                y1={223.969}
                x2={511.883}
                y2={303.487}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
