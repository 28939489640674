import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={42}
            height={26}
            fill="none"
            id="object-food-peanut"
            {...attributes}
        >
            <path
                fill="url(#object-food-peanut-a)"
                d="M41.711 13.014c0-6.992-8.885-12.66-19.846-12.66S.125 6.023.125 13.014c0 6.992 10.78 12.662 21.74 12.662 10.96 0 19.846-5.67 19.846-12.661Z"
            />
            <defs>
                <linearGradient
                    id="object-food-peanut-a"
                    x1={0.125}
                    x2={41.711}
                    y1={13.014}
                    y2={13.014}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
