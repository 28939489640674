import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#FFD109"
                d="M650.377 682.89c-5.717-1.79-11.52-5.491-16.766-9.688-16.208 6.074-36.377 4.556-52.696-2.96-.395 2.77-.842 5.8-1.339 9.073a9.11 9.11 0 00.196 3.675 9.107 9.107 0 004.445 5.674 9.105 9.105 0 003.52 1.071l6.968.644a133.062 133.062 0 0127.285 5.591 70.433 70.433 0 0020.93 3.043 81.791 81.791 0 009.452-.555c6.783-.788 11.193-3.391 11.507-6.791.271-2.932-4.272-5.886-13.502-8.777z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M581.404 686.281a9.004 9.004 0 006.332 3.454l6.968.644a133.048 133.048 0 0127.285 5.591 70.44 70.44 0 0020.931 3.043 81.787 81.787 0 009.451-.555c6.784-.788 11.193-3.391 11.507-6.791"
            ></path>
        </g>
    );
}
