import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 360,
        height: 359,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M179.818 27.542v330.063H1.523V1.015h356.589v356.59h-147.41M148.934 332.591v-48.505M210.703 332.591v-48.505"
                />
            </>
        ),
    },
    'Outline #2': {
        width: 879,
        height: 359,
        content: (
            <>
                <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    clipPath="url(#object-office-shelf-a)"
                >
                    <path
                        d="M858.987 245.007H449.238v-18.841h390.908M430.398 132.423h409.749v-18.841H430.398M430.398 19.841h428.59V1h-428.59M440.414 357.59H246.988" 
                    />
                    <path 
                        d="M216.104 27.526v330.063H1.809V1h438.605M185.219 332.579v-48.505M246.986 332.579v-48.505M430.398 132.424v206.325M430.398 19.842v93.742M858.988 338.75V19.842M858.988 338.748h-428.59M430.398 357.59h428.59M858.988 245.008h18.841V19.842h-18.841" 
                    />
                </g>
                <defs>
                    <clipPath id="object-office-shelf-a">
                        <path fill="#fff" d="M.809 0h878.02v358.589H.809z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-shelf"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
