import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M548.29 313.886a20.375 20.375 0 0 1-6.495-1.059 206.46 206.46 0 0 1-21.19-8.751c-6.525-3.085-13.079-6.467-20.04-10.339a163.723 163.723 0 0 1-18.936-12.041 685.011 685.011 0 0 1-5.4-4.011c-4.086-3.05-8.311-6.203-12.573-9.134a27.32 27.32 0 0 1-11.456-17.657 27.305 27.305 0 0 1 4.384-20.586 27.506 27.506 0 0 1 9.869-8.759 27.506 27.506 0 0 1 12.812-3.158 27.347 27.347 0 0 1 15.561 4.845l.117.08c4.247 2.955 8.687 5.796 12.981 8.543 1.891 1.209 3.779 2.424 5.664 3.642a163.68 163.68 0 0 1 17.95 13.476c6.074 5.143 11.55 10.084 16.734 15.097a206.708 206.708 0 0 1 15.664 16.742 20.266 20.266 0 0 1-2.811 28.488 20.281 20.281 0 0 1-12.835 4.582Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-1-left-a)"
        />
        <path
            d="m461.078 200.336 2.525 1.254c12.653 6.286 22.846 13.297 31.163 21.433a2.704 2.704 0 0 1 .822 2.483c-.805 4.666-13.808 10.892-16.406 12.093l-.913.421-17.191-37.684Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-1-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-1-left-a"
                x1={530.865}
                y1={248.402}
                x2={471.588}
                y2={305.077}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-1-left-b"
                x1={488.371}
                y1={203.958}
                x2={429.094}
                y2={260.632}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
