import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6604)"
            d="M465.574 317.032s-9.232-1.055-13.783-1.365c-.684-.042-1.368-.089-2.052-.134-1.254-.085-2.508-.171-3.763-.228l-5.778-.248c-2.626-.144-5.28-.189-7.846-.233-1.25-.022-2.5-.043-3.755-.076a225.148 225.148 0 00-4.72-.049c-5.51 0-10.888.39-16.349.801 1.323-6.912 2.349-13.966 3.334-21.66a173.626 173.626 0 001.682-23.051c.007-2.28-.001-4.56-.01-6.84-.02-5.196-.041-10.567.14-15.837l.004-.14a26.921 26.921 0 00-7.651-19.439 27.33 27.33 0 00-20.017-8.626 27.355 27.355 0 00-27.473 26.703c-.153 5.262-.511 10.616-.858 15.793-.153 2.282-.306 4.564-.442 6.842a169.57 169.57 0 00.147 22.844c.52 8.09 1.233 15.563 2.178 22.847.814 7.6 2.065 15.147 3.749 22.602a31.437 31.437 0 003.334 8.268c3.92 6.397 9.588 10.618 18.299 10.733h.001c.313 0 .627-.007.941-.022l11.503-.548 11.528-.406a221.542 221.542 0 0023.072-2.158 364.35 364.35 0 013.711-.539c2.542-.362 5.17-.736 7.747-1.201l5.717-.964c1.236-.211 2.47-.451 3.705-.691a360.513 360.513 0 0011.924-2.454l1.133-.253-.417-1.082c-.023-.06-2.32-6.074-2.32-10.892a48.782 48.782 0 012.85-17.003l.535-1.294z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6604"
                x1="414.84"
                x2="408.334"
                y1="216.069"
                y2="356.599"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
