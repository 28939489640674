import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_10894_7479)"
                d="M574.853 689.848c-4.73-4.001-9.2-12.035-10.792-19.202a79.884 79.884 0 01-1.412-14.44c-.05-1.393-.101-2.784-.173-4.173a76.044 76.044 0 00-1.744-12.428c8.41-5.42 8.414-9.315 16.795-11.926 8.819-2.747 12.723 1.881 20.133 3.279 3.697 8.2 7.521 16.679 11.626 25.015l.492.999c4.17 8.458 8.482 17.204 9.949 26.441.578 3.657.826 7.36.74 11.062-.434 11.349-13.735 22.337-45.614-4.627z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10894_7479"
                    x1="560.732"
                    x2="620.497"
                    y1="666.725"
                    y2="666.725"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E"></stop>
                    <stop offset="0.37" stopColor="#F86431"></stop>
                    <stop offset="1" stopColor="#FB8735"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
