import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6957)"
            d="M479.228 236.312c-.182-4.781-.96-19.424-3.837-27.14a101.329 101.329 0 0118.684 14.4 1.706 1.706 0 01.535 1.598c-.512 2.967-8.018 7.689-15.382 11.142z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6957)"
            d="M513.208 352.197a19.565 19.565 0 01-17.561-11.099 230.77 230.77 0 01-13.097-20.256c-3.844-6.603-7.55-13.481-11.334-21.03a183.686 183.686 0 01-9.467-21.822c-.805-2.234-1.6-4.473-2.385-6.719-1.798-5.103-3.657-10.38-5.67-15.489a27.328 27.328 0 01.368-21.044 27.32 27.32 0 0115.141-14.621 27.354 27.354 0 0110.07-1.92 27.434 27.434 0 0125.756 17.273l.048.126c1.988 5.12 4.04 10.413 6.18 15.381.941 2.185 1.877 4.372 2.807 6.56a188.307 188.307 0 018.016 22.708c2.339 8.109 4.31 15.536 5.964 22.988.629 2.876.97 5.358 1.441 7.776a84.763 84.763 0 0110.167-.219c.778.036 1.548.061 2.317.086 1.585.05 3.223.103 4.859.254l3.552.262c.85.066 3.978.267 6.163.465a32.44 32.44 0 014.365.868s-7.395 2.608-8.431 12.605c-.644 6.215 1.724 14.074 9.767 16.442l3.2.942-3.19.974c-.47.143-1.783.579-3.243 1.064-2.119.703-4.52 1.5-5.221 1.694l-3.455.948c-1.561.458-3.159.825-4.705 1.181-.749.172-1.498.344-2.246.527a83.906 83.906 0 01-14.154 2.109c-1.421.085-2.841.157-4.262.23-3.218.163-6.545.332-9.79.658a19.98 19.98 0 01-1.968.099l-.002-.001z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6957"
                x1="591.781"
                x2="459.058"
                y1="336.446"
                y2="201.988"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6957"
                x1="569.939"
                x2="437.216"
                y1="358.002"
                y2="223.544"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
