import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-1-both-arms-option-3-right">
        <path
            d="M403.338 266.316a202.233 202.233 0 0 0 3.728-25.437c.212-2.56.408-5.123.604-7.686.445-5.835.907-11.868 1.57-17.775l.016-.15a32.033 32.033 0 0 0-31.854-35.424 31.923 31.923 0 0 0-31.778 28.582c-.635 5.903-1.497 11.891-2.331 17.681-.366 2.545-.732 5.09-1.081 7.634a202.289 202.289 0 0 0-1.891 25.636c-.175 9.119-.091 17.562.257 25.816 11.927-7.94 37.662-13.434 61.011-9.82.976-4.5 1.468-7.67 1.749-9.057Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-3-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-3-right-a"
                x1={343.587}
                y1={196.694}
                x2={392.974}
                y2={270.535}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
