import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_7992)"
            d="M523.713 241.707a252.018 252.018 0 00-11.039-7.94c-6.217-4.042-12.51-7.918-18.645-12.184-30.738-19.737-60.63 23.426-31.286 45.234 6.151 4.233 12.001 8.756 17.973 13.145 16.778-16.556 27.332-26.966 42.997-38.255z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_7992)"
            d="M476.141 208.625a110.644 110.644 0 0118.627 14.398 2.708 2.708 0 01.822 2.483c-.805 4.666-13.808 10.892-16.407 12.093 0 0-14.647 1.881-13.641.722 11.755-13.547 12.47-21.795 10.599-29.696z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_7992"
                x1="486.707"
                x2="488.702"
                y1="210.281"
                y2="337.672"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_7992"
                x1="480.312"
                x2="482.706"
                y1="215.668"
                y2="368.576"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
