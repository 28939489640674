import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 876,
        height: 420,
        content: (
            <>
                <g
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    clipPath="url(#object-office-couch-a)"
                >
                    <path 
                        d="m784.875 389.92 15.5 28.6h33.5v-28.6M91.303 389.92l-15.5 28.6h-33.5v-28.6M297.246 241.92v122.536M578.932 241.92v122.536M784.874 389.92H91.303M293.269 1.521H85.159c-13.808 0-25 11.193-25 25v59.861M545.223 1.521H330.954c-7.732 0-15.524 4.651-18.843 14M545.223 1.521c7.732 0 15.524 4.651 18.843 14" 
                    />
                    <path 
                        d="M293.268 1.521c7.732 0 15.524 4.651 18.843 14M582.91 1.521h208.111c13.807 0 25 11.193 25 25v59.861M582.909 1.521c-7.732 0-15.524 4.651-18.843 14M42.303 389.92H26.316c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057h18.812l-.028 243.537h-40V241.92c0-22.091 17.91-40 40-40H816.02c22.091 0 40 17.909 40 40v122.537h-40V120.92h18.784c22.123 0 40.057 17.934 40.057 40.057V364.92c0 13.807-11.193 25-25 25h-15.987" 
                    />
                </g>
                <defs>
                    <clipPath id="object-office-couch-a">
                        <path fill="#fff" d="M.316.521h875.545V419.52H.316z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #1': {
        width: 919,
        height: 419,
        content: (
            <>
                <path
                    fill="url(#object-office-couch-a)"
                    d="M877.326 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-b)"
                    d="M837.326 120.92c22.077 0 40 17.923 40 40v81h-40v-121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M877.326 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057v0Z"
                />
                <path
                    fill="url(#object-office-couch-c)"
                    d="M41.49 120.92h40v121h-40v148H26.435c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-d)"
                    d="M81.492 241.92h-40v-81c0-22.077 17.923-40 40-40v121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.49 241.92h-40v148H26.435c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057"
                />
                <path
                    fill="#63666A"
                    d="M821.678 418.52h33.5v-28.6h-49l15.5 28.6ZM97.11 418.52h-33.5v-28.6h48.999l-15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-couch-e)"
                    d="M81.46 201.92h755.864c22.077 0 40 17.923 40 40v148H41.461v-148c0-22.077 17.923-40 40-40Z"
                />
                <path
                    fill="url(#object-office-couch-f)"
                    d="M106.461 1.42h201.954c13.798 0 25 11.202 25 25v175.5H81.461V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-g)"
                    d="M358.418 1.42h201.954c13.798 0 25 11.202 25 25v175.5H333.418V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-h)"
                    d="M610.371 1.42h201.954c13.798 0 25 11.202 25 25v175.5H585.371V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M318.551 241.922v122.536M600.234 241.922v122.536M333.416 201.92V26.42c0-13.807-11.193-25-25-25H181.288l-30.316.102h-44.511c-13.807 0-25 11.193-25 25v175.5M585.372 201.92V26.42c0-13.807-11.193-25-25-25H358.418c-13.807 0-25 11.193-25 25v175.5"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M837.325 201.92V26.42c0-13.807-11.193-25-25-25H610.371c-13.807 0-25 11.193-25 25v175.5"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M429.877 201.92H81.461c-22.091 0-40 17.909-40 40v148h835.863v-148c0-22.091-17.909-40-40-40h-31.146"
                />
                <defs>
                    <linearGradient
                        id="object-office-couch-a"
                        x1={877.354}
                        x2={877.354}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-b"
                        x1={776.68}
                        x2={918.154}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.005} stopColor="#C2C9CF" />
                        <stop offset={0.533} stopColor="#ECF3F9" />
                        <stop offset={0.763} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-c"
                        x1={41.463}
                        x2={41.463}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-d"
                        x1={142.138}
                        x2={0.664}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.005} stopColor="#C2C9CF" />
                        <stop offset={0.533} stopColor="#ECF3F9" />
                        <stop offset={0.763} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-e"
                        x1={459.392}
                        x2={459.392}
                        y1={305.847}
                        y2={193.369}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.097} stopColor="#C2C9CF" />
                        <stop offset={0.273} stopColor="#C2C9CF" />
                        <stop offset={0.509} stopColor="#C2C9CF" />
                        <stop offset={0.793} stopColor="#ECF3F9" />
                        <stop offset={0.881} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-f"
                        x1={207.438}
                        x2={207.438}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.099} stopColor="#C2C9CF" />
                        <stop offset={0.283} stopColor="#C2C9CF" />
                        <stop offset={0.532} stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                        <stop offset={0.886} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-g"
                        x1={459.395}
                        x2={459.395}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.099} stopColor="#C2C9CF" />
                        <stop offset={0.283} stopColor="#C2C9CF" />
                        <stop offset={0.532} stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                        <stop offset={0.886} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-h"
                        x1={711.348}
                        x2={711.348}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.099} stopColor="#C2C9CF" />
                        <stop offset={0.283} stopColor="#C2C9CF" />
                        <stop offset={0.532} stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                        <stop offset={0.886} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 919,
        height: 419,
        content: (
            <>
                <path
                    fill="url(#object-office-couch-a)"
                    d="M877.076 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-b)"
                    d="M837.076 120.92c22.077 0 40 17.923 40 40v81h-40v-121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M877.076 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057v0Z"
                />
                <path
                    fill="url(#object-office-couch-c)"
                    d="M41.24 120.92h40v121h-40v148H26.185c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-d)"
                    d="M81.24 241.92h-40v-81c0-22.077 17.923-40 40-40v121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.24 241.92h-40v148H26.185c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057"
                />
                <path
                    fill="#63666A"
                    d="M821.428 418.52h33.5v-28.6h-49l15.5 28.6ZM96.86 418.52h-33.5v-28.6h48.999l-15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-couch-e)"
                    d="M81.213 201.92h755.863c22.077 0 40 17.923 40 40v148H41.213v-148c0-22.077 17.923-40 40-40Z"
                />
                <path
                    fill="url(#object-office-couch-f)"
                    d="M106.213 1.42h201.954c13.798 0 25 11.202 25 25v175.5H81.213V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-g)"
                    d="M358.166 1.42H560.12c13.798 0 25 11.202 25 25v175.5H333.166V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-h)"
                    d="M610.121 1.42h201.954c13.798 0 25 11.202 25 25v175.5H585.121V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M318.301 241.922v122.536M599.984 241.922v122.536M333.166 201.92V26.42c0-13.807-11.193-25-25-25H181.038l-30.316.102h-44.511c-13.807 0-25 11.193-25 25v175.5M585.12 201.92V26.42c0-13.807-11.193-25-25-25H358.166c-13.807 0-25 11.193-25 25v175.5M837.075 201.92V26.42c0-13.807-11.193-25-25-25H610.121c-13.807 0-25 11.193-25 25v175.5"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M429.629 201.92H81.213c-22.091 0-40 17.909-40 40v148h835.863v-148c0-22.091-17.909-40-40-40H805.93"
                />
                <defs>
                    <linearGradient
                        id="object-office-couch-a"
                        x1={877.104}
                        x2={877.104}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#E5B97F" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-b"
                        x1={776.43}
                        x2={917.904}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.005} stopColor="#E5B97F" />
                        <stop offset={0.533} stopColor="#FFE4B8" />
                        <stop offset={0.763} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-c"
                        x1={41.213}
                        x2={41.213}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#E5B97F" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-d"
                        x1={141.886}
                        x2={0.412}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.005} stopColor="#E5B97F" />
                        <stop offset={0.533} stopColor="#FFE4B8" />
                        <stop offset={0.763} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-e"
                        x1={459.144}
                        x2={459.144}
                        y1={305.847}
                        y2={193.369}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.097} stopColor="#E5B97F" />
                        <stop offset={0.273} stopColor="#E5B97F" />
                        <stop offset={0.509} stopColor="#E5B97F" />
                        <stop offset={0.793} stopColor="#FFE4B8" />
                        <stop offset={0.881} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-f"
                        x1={207.19}
                        x2={207.19}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.099} stopColor="#E5B97F" />
                        <stop offset={0.283} stopColor="#E5B97F" />
                        <stop offset={0.532} stopColor="#E5B97F" />
                        <stop offset={0.833} stopColor="#FFE4B8" />
                        <stop offset={0.886} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-g"
                        x1={459.143}
                        x2={459.143}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.099} stopColor="#E5B97F" />
                        <stop offset={0.283} stopColor="#E5B97F" />
                        <stop offset={0.532} stopColor="#E5B97F" />
                        <stop offset={0.833} stopColor="#FFE4B8" />
                        <stop offset={0.886} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-h"
                        x1={711.098}
                        x2={711.098}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.099} stopColor="#E5B97F" />
                        <stop offset={0.283} stopColor="#E5B97F" />
                        <stop offset={0.532} stopColor="#E5B97F" />
                        <stop offset={0.833} stopColor="#FFE4B8" />
                        <stop offset={0.886} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 919,
        height: 419,
        content: (
            <>
                <path
                    fill="url(#object-office-couch-a)"
                    d="M877.826 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-b)"
                    d="M837.826 120.92c22.077 0 40 17.923 40 40v81h-40v-121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M877.826 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057v0Z"
                />
                <path
                    fill="url(#object-office-couch-c)"
                    d="M41.99 120.92h40v121h-40v148H26.935c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057Z"
                />
                <path
                    fill="url(#object-office-couch-d)"
                    d="M81.99 241.92h-40v-81c0-22.077 17.923-40 40-40v121Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M81.99 241.92h-40v148H26.935c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057"
                />
                <path
                    fill="#63666A"
                    d="M822.18 418.52h33.5v-28.6h-49l15.5 28.6ZM97.61 418.52h-33.5v-28.6h48.999l-15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-couch-e)"
                    d="M81.963 201.92h755.863c22.077 0 40 17.923 40 40v148H41.963v-148c0-22.077 17.923-40 40-40Z"
                />
                <path
                    fill="url(#object-office-couch-f)"
                    d="M106.963 1.42h201.954c13.798 0 25 11.202 25 25v175.5H81.963V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-g)"
                    d="M358.916 1.42H560.87c13.798 0 25 11.202 25 25v175.5H333.916V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    fill="url(#object-office-couch-h)"
                    d="M610.871 1.42h201.954c13.798 0 25 11.202 25 25v175.5H585.871V26.42c0-13.798 11.202-25 25-25Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M319.053 241.922v122.536M600.736 241.922v122.536M333.916 201.92V26.42c0-13.807-11.193-25-25-25H181.788l-30.316.102h-44.511c-13.807 0-25 11.193-25 25v175.5M585.872 201.92V26.42c0-13.807-11.193-25-25-25H358.918c-13.807 0-25 11.193-25 25v175.5"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M837.825 201.92V26.42c0-13.807-11.193-25-25-25H610.871c-13.807 0-25 11.193-25 25v175.5"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M430.379 201.92H81.963c-22.091 0-40 17.909-40 40v148h835.863v-148c0-22.091-17.909-40-40-40H806.68"
                />
                <defs>
                    <linearGradient
                        id="object-office-couch-a"
                        x1={877.855}
                        x2={877.855}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#EA9975" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-b"
                        x1={777.181}
                        x2={918.654}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.005} stopColor="#EA9975" />
                        <stop offset={0.533} stopColor="#FCCBBB" />
                        <stop offset={0.763} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-c"
                        x1={41.963}
                        x2={41.963}
                        y1={0.42}
                        y2={0.42}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.631} stopColor="#EA9975" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-d"
                        x1={142.636}
                        x2={1.163}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.005} stopColor="#EA9975" />
                        <stop offset={0.533} stopColor="#FCCBBB" />
                        <stop offset={0.763} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-e"
                        x1={459.895}
                        x2={459.895}
                        y1={305.847}
                        y2={193.369}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.097} stopColor="#EA9975" />
                        <stop offset={0.273} stopColor="#EA9975" />
                        <stop offset={0.509} stopColor="#EA9975" />
                        <stop offset={0.793} stopColor="#FCCBBB" />
                        <stop offset={0.881} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-f"
                        x1={207.94}
                        x2={207.94}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.099} stopColor="#EA9975" />
                        <stop offset={0.283} stopColor="#EA9975" />
                        <stop offset={0.532} stopColor="#EA9975" />
                        <stop offset={0.833} stopColor="#FCCBBB" />
                        <stop offset={0.886} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-g"
                        x1={459.894}
                        x2={459.894}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.099} stopColor="#EA9975" />
                        <stop offset={0.283} stopColor="#EA9975" />
                        <stop offset={0.532} stopColor="#EA9975" />
                        <stop offset={0.833} stopColor="#FCCBBB" />
                        <stop offset={0.886} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-h"
                        x1={711.848}
                        x2={711.848}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.099} stopColor="#EA9975" />
                        <stop offset={0.283} stopColor="#EA9975" />
                        <stop offset={0.532} stopColor="#EA9975" />
                        <stop offset={0.833} stopColor="#FCCBBB" />
                        <stop offset={0.886} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 919,
        height: 419,
        content: (
            <>
                <g clipPath="url(#object-office-couch-a)">
                    <path
                        fill="url(#object-office-couch-b)"
                        d="M877.572 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057Z"
                    />
                    <path
                        fill="url(#object-office-couch-c)"
                        d="M837.572 120.92c22.077 0 40 17.923 40 40v81h-40v-121Z"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M877.572 120.92h-40v121h40v148h15.057c13.807 0 25-11.193 25-25V160.977c0-22.123-17.934-40.057-40.057-40.057v0Z"
                    />
                    <path
                        fill="url(#object-office-couch-d)"
                        d="M41.737 120.92h40v121h-40v148H26.68c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057Z"
                    />
                    <path
                        fill="url(#object-office-couch-e)"
                        d="M81.736 241.92h-40v-81c0-22.077 17.923-40 40-40v121Z"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M81.737 241.92h-40v148H26.68c-13.807 0-25-11.193-25-25V160.977c0-22.123 17.934-40.057 40.057-40.057"
                    />
                    <path
                        fill="#63666A"
                        d="M821.924 418.52h33.5v-28.6h-49l15.5 28.6ZM97.356 418.52h-33.5v-28.6h48.999l-15.5 28.6Z"
                    />
                    <path
                        fill="url(#object-office-couch-f)"
                        d="M81.709 201.92h755.863c22.077 0 40 17.923 40 40v148H41.709v-148c0-22.077 17.923-40 40-40Z"
                    />
                    <path
                        fill="url(#object-office-couch-g)"
                        d="M106.709 1.42h201.954c13.798 0 25 11.202 25 25v175.5H81.709V26.42c0-13.798 11.202-25 25-25Z"
                    />
                    <path
                        fill="url(#object-office-couch-h)"
                        d="M358.662 1.42h201.954c13.798 0 25 11.202 25 25v175.5H333.662V26.42c0-13.798 11.202-25 25-25Z"
                    />
                    <path
                        fill="url(#object-office-couch-i)"
                        d="M610.617 1.42h201.954c13.798 0 25 11.202 25 25v175.5H585.617V26.42c0-13.798 11.202-25 25-25Z"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M318.797 241.922v122.536M600.48 241.922v122.536M333.662 201.92V26.42c0-13.807-11.193-25-25-25H181.534l-30.316.102h-44.511c-13.807 0-25 11.193-25 25v175.5M585.616 201.92V26.42c0-13.807-11.193-25-25-25H358.662c-13.807 0-25 11.193-25 25v175.5M837.571 201.92V26.42c0-13.807-11.193-25-25-25H610.617c-13.807 0-25 11.193-25 25v175.5"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M430.125 201.92H81.709c-22.091 0-40 17.909-40 40v148h835.863v-148c0-22.091-17.909-40-40-40h-31.146"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="object-office-couch-b"
                        x1={877.6}
                        x2={877.6}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.374} stopColor="#FFD102" />
                        <stop offset={0.508} stopColor="#FFD309" />
                        <stop offset={0.604} stopColor="#FFD514" />
                        <stop offset={0.682} stopColor="#FFD825" />
                        <stop offset={0.748} stopColor="#FFDC3B" />
                        <stop offset={0.807} stopColor="#FFE157" />
                        <stop offset={0.86} stopColor="#FFE677" />
                        <stop offset={0.908} stopColor="#FFED9B" />
                        <stop offset={0.95} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-c"
                        x1={776.926}
                        x2={918.4}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.005} stopColor="#FFD101" />
                        <stop offset={0.533} stopColor="#FFEA8C" />
                        <stop offset={0.763} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-d"
                        x1={41.709}
                        x2={41.709}
                        y1={270.226}
                        y2={136.662}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.374} stopColor="#FFD102" />
                        <stop offset={0.508} stopColor="#FFD309" />
                        <stop offset={0.604} stopColor="#FFD514" />
                        <stop offset={0.682} stopColor="#FFD825" />
                        <stop offset={0.748} stopColor="#FFDC3B" />
                        <stop offset={0.807} stopColor="#FFE157" />
                        <stop offset={0.86} stopColor="#FFE677" />
                        <stop offset={0.908} stopColor="#FFED9B" />
                        <stop offset={0.95} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-e"
                        x1={142.382}
                        x2={0.908}
                        y1={213.102}
                        y2={157.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.005} stopColor="#FFD101" />
                        <stop offset={0.533} stopColor="#FFEA8C" />
                        <stop offset={0.763} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-f"
                        x1={459.64}
                        x2={459.64}
                        y1={305.847}
                        y2={193.369}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.097} stopColor="#FFD30C" />
                        <stop offset={0.273} stopColor="#FFD92D" />
                        <stop offset={0.509} stopColor="#FFE362" />
                        <stop offset={0.793} stopColor="#FFF0AB" />
                        <stop offset={0.881} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-g"
                        x1={207.686}
                        x2={207.686}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.099} stopColor="#FFD30D" />
                        <stop offset={0.283} stopColor="#FFDA30" />
                        <stop offset={0.532} stopColor="#FFE468" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                        <stop offset={0.886} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-h"
                        x1={459.639}
                        x2={459.639}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.099} stopColor="#FFD30D" />
                        <stop offset={0.283} stopColor="#FFDA30" />
                        <stop offset={0.532} stopColor="#FFE468" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                        <stop offset={0.886} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-couch-i"
                        x1={711.594}
                        x2={711.594}
                        y1={192.06}
                        y2={6.632}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.099} stopColor="#FFD30D" />
                        <stop offset={0.283} stopColor="#FFDA30" />
                        <stop offset={0.532} stopColor="#FFE468" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                        <stop offset={0.886} stopColor="#FFF4C3" />
                    </linearGradient>
                    <clipPath id="object-office-couch-a">
                        <path fill="#fff" d="M.68.42h917.949v418.1H.679z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-couch"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
