import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M478.85 236.554c-.182-4.781-.96-19.424-3.838-27.14a101.296 101.296 0 0 1 18.684 14.4 1.7 1.7 0 0 1 .536 1.598c-.512 2.967-8.018 7.689-15.382 11.142Z"
            fill="url(#female-34-25-shirt-1-arm-opt-4-left-a)"
        />
        <path
            d="M585.399 304.576a210.5 210.5 0 0 0-9.014-.952c-1.499-.117-3.001-.27-4.497-.356l-4.488-.245c-2.995-.206-5.973-.2-8.96-.298a131 131 0 0 0-17.813.728c-1.113.124-2.226.255-3.338.385a289.498 289.498 0 0 0-3.732-10.328c-2.77-7.198-5.79-14.25-8.991-21.197a169.92 169.92 0 0 0-10.74-20.179c-4.014-6.473-8.182-12.858-11.994-19.449l-.069-.118a26.48 26.48 0 0 0-16.164-12.251 26.485 26.485 0 0 0-10.321-.615 26.484 26.484 0 0 0-17.504 10.245 26.5 26.5 0 0 0-1.752 29.393c3.846 6.571 7.336 13.35 10.981 20.039a169.91 169.91 0 0 0 12.235 19.308c4.458 6.216 9.096 12.327 13.985 18.292a212.266 212.266 0 0 0 15.664 17.313c.231.224.472.438.714.649a18.472 18.472 0 0 0 16.312 7.311c5.917-.497 11.861-.662 17.793-.97a131.113 131.113 0 0 0 17.696-2.163c2.932-.579 5.872-1.055 8.794-1.742l4.39-.968c1.462-.327 2.92-.721 4.38-1.079a210.756 210.756 0 0 0 8.741-2.397c-3.714-6.364-6.244-20.388-2.308-28.356Z"
            fill="url(#female-34-25-shirt-1-arm-opt-4-left-b)"
        />
        <path
            d="M537.127 303.85c1.167-.137 2.333-.271 3.5-.402 5.914-.646 11.865-.89 17.812-.727 2.987.096 5.966.091 8.961.297l4.488.245c1.496.085 2.998.24 4.497.356M578.967 335.328c-1.46.358-2.918.752-4.38 1.079l-4.389.967c-2.923.688-5.863 1.164-8.795 1.743a131.099 131.099 0 0 1-17.695 2.163c-5.933.308-11.877.473-17.794.97a18.445 18.445 0 0 1-13.955-4.71M501.764 232.562l.068.118c3.813 6.591 7.98 12.976 11.995 19.449a169.767 169.767 0 0 1 10.739 20.179c3.201 6.947 8.542 20.258 11.311 27.456M578.966 335.323a210.772 210.772 0 0 0 8.741-2.398c-3.714-6.364-6.244-20.388-2.309-28.356-3.01-.391-6.013-.692-9.013-.952"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M484.51 216.109a139.363 139.363 0 0 1 9.162 7.736"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-4-left-a"
                x1={490.789}
                y1={204.565}
                x2={458.476}
                y2={272.444}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-4-left-b"
                x1={452.388}
                y1={280.883}
                x2={587.707}
                y2={280.883}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
