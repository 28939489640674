import { IBodyClothes } from 'interfaces';

import Dress3 from './female-34-25-dress-3';
import P1Sitting from './female-34-25-dress-3-p1-sitting';
import P2Sitting from './female-34-25-dress-3-p2-sitting';

const dress3: IBodyClothes = {
    text: 'dress #3',
    disableLegsClothes: true,
    allowWithoutSleeves: true,
    normal: {
        front: Dress3,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
    sitting: {
        front: P1Sitting,
        front2: P2Sitting,
        leftArm: {},
        rightArm: {},
        bothArms: {},
    },
};

export default dress3;
