import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-6">
        <path
            fill={SKIN_COLOR[color]}
            d="M632.01 345.327c-2.648-5.232-7.101-9.454-11.95-12.815-5.428-3.763-11.427-6.612-17.106-9.986a56.978 56.978 0 0 1-10.245-7.491l-.607-.604c2.463-.577 5.004.395 7.3 1.455a74.243 74.243 0 0 0 12.29 3.468 2.46 2.46 0 0 0 2.733-1.605 2.492 2.492 0 0 0-1.523-3.159c-4.471-1.683-7.955-5.733-11.928-8.628a29.098 29.098 0 0 0-31.3-1.431c-19.136 10.312-25.349 20.716-17.221 31.222a90.967 90.967 0 0 0 36.362 22.319c3.088.998 6.679 1.77 9.404.008l3.939-3.691c1.461.302 2.969.297 4.428-.016a5.666 5.666 0 0 0 4.086-3.399l.413-.912a24.89 24.89 0 0 0 7.54.883 6.282 6.282 0 0 0 2.485-.505 2.624 2.624 0 0 0 1.529-1.916l.77-3.168a32.272 32.272 0 0 1 4.338 3.175 2.731 2.731 0 0 0 3.604.045 2.705 2.705 0 0 0 .659-3.249Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M598.343 333.086c4.863 3.443 10.538 5.506 16.033 7.808 4.753 1.99 9.522 4.257 13.369 7.636a2.732 2.732 0 0 0 3.605.045 2.704 2.704 0 0 0 .659-3.25c-2.648-5.232-7.102-9.453-11.95-12.815-5.428-3.763-11.428-6.612-17.106-9.985-5.679-3.374-11.158-7.395-14.844-12.877m4.176 29.961a86.746 86.746 0 0 0 12.269 7.94 29.047 29.047 0 0 0 14.072 3.394c.854.003 1.7-.169 2.485-.504a2.644 2.644 0 0 0 1.53-1.916m-33.414-.32a27.91 27.91 0 0 0 11.869 5.971c1.789.491 3.668.559 5.488.2a5.657 5.657 0 0 0 4.085-3.399m-58.216-15.221a90.974 90.974 0 0 0 36.362 22.319c3.088.998 6.679 1.771 9.404.009"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M592.1 314.431c2.463-.577 5.004.395 7.301 1.455a74.232 74.232 0 0 0 12.289 3.468 2.46 2.46 0 0 0 2.733-1.605 2.492 2.492 0 0 0-1.522-3.159c-4.471-1.683-7.956-5.733-11.928-8.628a29.104 29.104 0 0 0-31.301-1.431"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M398.54 190.052c6.207 5.411 12.699 10.492 19.068 15.716a186.664 186.664 0 0 1 18.058 16.881c5.714 5.979 11.283 12.126 16.65 18.505a233.237 233.237 0 0 1 15.296 20.065 23.32 23.32 0 0 1-.013 25.924 23.313 23.313 0 0 1-6.44 6.434 23.372 23.372 0 0 1-11.301 3.859 23.375 23.375 0 0 1-11.733-2.224 233.411 233.411 0 0 1-22.024-12.308c-7.074-4.412-13.945-9.056-20.672-13.868a187.112 187.112 0 0 1-19.263-15.492c-6.071-5.567-12.018-11.277-18.252-16.657a31 31 0 0 1 40.508-46.938l.118.103Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m463.017 256.251 12.66 5.567 12.686 5.507c8.448 3.695 16.794 7.634 25.082 11.714 4.131 2.071 8.285 4.087 12.392 6.216 4.12 2.098 8.235 4.206 12.313 6.407 4.083 2.186 8.186 4.326 12.253 6.549 4.043 2.285 8.124 4.477 12.127 6.857a15.66 15.66 0 0 1 5.457 21.466 15.681 15.681 0 0 1-17.325 7.173c-4.513-1.15-8.948-2.487-13.422-3.731-4.448-1.306-8.862-2.695-13.295-4.039-4.438-1.328-8.839-2.749-13.235-4.18-4.409-1.4-8.772-2.913-13.157-4.371-8.744-2.979-17.429-6.098-26.014-9.462l-12.863-5.08-12.887-5.021a22.5 22.5 0 0 1-14.331-20.493 22.514 22.514 0 0 1 22.024-22.977 22.5 22.5 0 0 1 9.535 1.898Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m398.422 189.949.118.103c6.207 5.411 12.699 10.492 19.068 15.716a186.792 186.792 0 0 1 18.059 16.881c5.714 5.979 11.282 12.126 16.649 18.505 3.554 4.252 7.046 8.577 10.38 13.084m-22.92 41.683a23.697 23.697 0 0 1-1.651-.709 233.42 233.42 0 0 1-22.024-12.309c-7.074-4.411-13.945-9.055-20.672-13.867a187.126 187.126 0 0 1-19.262-15.492c-6.071-5.568-12.019-11.277-18.253-16.657m192.75 96.823c-4.514-1.149-8.949-2.486-13.422-3.731-4.449-1.305-8.862-2.695-13.295-4.038-4.439-1.328-8.839-2.749-13.235-4.181-4.41-1.399-8.772-2.913-13.157-4.371-8.744-2.978-17.43-6.098-26.014-9.462 0 0-24.694-8.71-31.764-12.005m24.411-39.156 11.491 5.052 12.685 5.507c8.448 3.695 16.794 7.634 25.082 11.714 4.131 2.071 8.285 4.086 12.392 6.216 4.12 2.098 8.236 4.206 12.313 6.407 4.083 2.186 8.186 4.326 12.254 6.549 4.042 2.285 8.123 4.477 12.126 6.857"
        />
    </g>
);

export default SvgComponent;
