import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6987)"
            d="M479.229 236.312c-.182-4.782-.96-19.424-3.838-27.14a101.214 101.214 0 0118.684 14.399 1.708 1.708 0 01.536 1.598c-.512 2.966-8.018 7.688-15.382 11.143z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6987)"
            d="M539.26 337.07c-.82 0-1.638-.014-2.453-.042a53.05 53.05 0 01-11.205-1.366c-1.086-.255-2.171-.525-3.256-.794-2.449-.608-4.981-1.236-7.485-1.693a19.397 19.397 0 01-12.242-7.69l-.038-.032c-.143-.12-.287-.241-.425-.366a169.153 169.153 0 01-14.186-15.054c-4.188-4.97-8.261-10.197-12.454-15.983a131.722 131.722 0 01-10.726-17.049 586.8 586.8 0 01-2.792-5.352c-2.1-4.053-4.273-8.245-6.578-12.258a27.501 27.501 0 0147.696-27.39l.079.14c2.282 4.028 4.784 8.032 7.204 11.904 1.066 1.708 2.134 3.415 3.186 5.13a131.85 131.85 0 019.215 17.917c2.844 6.549 5.27 12.716 7.412 18.845a213.395 213.395 0 013.362 10.547 53.602 53.602 0 0110.311 2.678c.881.306 1.77.637 2.657.985.924.341 1.858.734 2.648 1.074l2.603 1.104c.575.246 1.14.519 1.705.792.305.147.61.294.915.437a69.155 69.155 0 0110.324 5.963l2.213 1.589-2.715.22c-6.567.533-11.76 3.466-14.623 8.261-2.728 4.567-2.924 10.305-.54 15.742l.571 1.303-1.42.097c-.332.022-.666.05-1 .076-.63.052-1.259.102-1.884.125l-2.835.091c-.765.029-1.536.052-2.302.052l-.942-.003z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6987"
                x1="468.497"
                x2="570.858"
                y1="208.548"
                y2="329.127"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6987"
                x1="453.567"
                x2="555.928"
                y1="221.219"
                y2="341.798"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
