import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-2">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-2-a)"
            d="M369.266 286.485c-3.209-6.591-6.24-13.434-9.267-20.925a158.405 158.405 0 0 1-7.214-21.588c-.565-2.196-1.114-4.401-1.664-6.605-1.25-5.019-2.544-10.21-4.018-15.246a32.037 32.037 0 0 1 21.726-39.7 32.033 32.033 0 0 1 9.001-1.296 32.158 32.158 0 0 1 19.157 6.432 32.16 32.16 0 0 1 11.541 16.589l.049.167c1.445 5.043 3.125 10.119 4.75 15.026.715 2.161 1.43 4.321 2.128 6.486a158.47 158.47 0 0 1 5.44 22.107 346.83 346.83 0 0 1 2.304 14.289c-11.29 1.467-42.808 11.877-53.933 24.264Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-2-a"
                x1={368.699}
                x2={393.856}
                y1={184.627}
                y2={267.036}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
