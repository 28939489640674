import { IBodyClothes } from 'interfaces';

import Tshirt2Normal from './man-34-25-tshirt-2-normal';
import Tshirt2Cycling1 from './man-34-25-tshirt-2-cycling';
import Tshirt2Cycling2 from './man-34-25-tshirt-2-cycling-2';
import Tshirt2Sitting from './man-34-25-tshirt-2-sitting';

import Tshirt2RightArmDown from './man-34-25-tshirt-2-arm-right-down';
import Tshirt2RightArmOption1 from './man-34-25-tshirt-2-arm-right-option-1';
import Tshirt2RightArmOption2 from './man-34-25-tshirt-2-arm-right-option-2';
import Tshirt2RightArmOption3 from './man-34-25-tshirt-2-arm-right-option-3';
import Tshirt2RightArmOption4 from './man-34-25-tshirt-2-arm-right-option-4';
import Tshirt2RightArmOption5 from './man-34-25-tshirt-2-arm-right-option-5';
import Tshirt2RightArmOption6 from './man-34-25-tshirt-2-arm-right-option-6';
import Tshirt2RightArmOption7 from './man-34-25-tshirt-2-arm-right-option-7';

import Tshirt2LeftArmDown from './man-34-25-tshirt-2-arm-left-down';
import Tshirt2LeftArmUp from './man-34-25-tshirt-2-arm-left-up';
import Tshirt2LeftArmOption1 from './man-34-25-tshirt-2-arm-left-option-1';
import Tshirt2LeftArmOption2 from './man-34-25-tshirt-2-arm-left-option-2';
import Tshirt2LeftArmOption3 from './man-34-25-tshirt-2-arm-left-option-3';
import Tshirt2LeftArmOption4 from './man-34-25-tshirt-2-arm-left-option-4';
import Tshirt2LeftArmOption5 from './man-34-25-tshirt-2-arm-left-option-5';
import Tshirt2LeftArmOption6 from './man-34-25-tshirt-2-arm-left-option-6';
import Tshirt2LeftArmOption7 from './man-34-25-tshirt-2-arm-left-option-7';

import Tshirt2BothArmsOption1Right from './man-34-25-tshirt-2-both-arms-option-1-right';
import Tshirt2BothArmsOption1Left from './man-34-25-tshirt-2-both-arms-option-1-left';
import Tshirt2BothArmsOption2Right from './man-34-25-tshirt-2-both-arms-option-2-right';
import Tshirt2BothArmsOption2Left from './man-34-25-tshirt-2-both-arms-option-2-left';
import Tshirt2BothArmsOption3Right from './man-34-25-tshirt-2-both-arms-option-3-right';
import Tshirt2BothArmsOption3Left from './man-34-25-tshirt-2-both-arms-option-3-left';

import Tshirt2BothArmsOption1Cycling1Left from './man-34-25-tshirt-2-both-arms-option-1-cycling-1-left';
import Tshirt2BothArmsOption1Cycling1Right from './man-34-25-tshirt-2-both-arms-option-1-cycling-1-right';
import Tshirt2BothArmsOption1Cycling2Left from './man-34-25-tshirt-2-both-arms-option-1-cycling-2-left';
import Tshirt2BothArmsOption1Cycling2Right from './man-34-25-tshirt-2-both-arms-option-1-cycling-2-right';

const tshirt2: IBodyClothes = {
    text: 'Tshirt #2',
    normal: {
        front: Tshirt2Normal,
        rightArm: {
            down: Tshirt2RightArmDown,
            option1: Tshirt2RightArmOption1,
            option2: Tshirt2RightArmOption2,
            option3: Tshirt2RightArmOption3,
            option4: Tshirt2RightArmOption4,
            option5: Tshirt2RightArmOption5,
            option6: Tshirt2RightArmOption6,
            option7: Tshirt2RightArmOption7,
        },
        leftArm: {
            down: Tshirt2LeftArmDown,
            up: Tshirt2LeftArmUp,
            option1: Tshirt2LeftArmOption1,
            option2: Tshirt2LeftArmOption2,
            option3: Tshirt2LeftArmOption3,
            option4: Tshirt2LeftArmOption4,
            option5: Tshirt2LeftArmOption5,
            option6: Tshirt2LeftArmOption6,
            option7: Tshirt2LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt2BothArmsOption1Right, Tshirt2BothArmsOption1Left],
            option2: [Tshirt2BothArmsOption2Right, Tshirt2BothArmsOption2Left],
            option3: [Tshirt2BothArmsOption3Right, Tshirt2BothArmsOption3Left],
        },
    },
    sitting: {
        front: Tshirt2Sitting,
        rightArm: {
            down: Tshirt2RightArmDown,
            option1: Tshirt2RightArmOption1,
            option2: Tshirt2RightArmOption2,
            option3: Tshirt2RightArmOption3,
            option4: Tshirt2RightArmOption4,
            option5: Tshirt2RightArmOption5,
            option6: Tshirt2RightArmOption6,
            option7: Tshirt2RightArmOption7,
        },
        leftArm: {
            down: Tshirt2LeftArmDown,
            up: Tshirt2LeftArmUp,
            option1: Tshirt2LeftArmOption1,
            option2: Tshirt2LeftArmOption2,
            option3: Tshirt2LeftArmOption3,
            option4: Tshirt2LeftArmOption4,
            option5: Tshirt2LeftArmOption5,
            option6: Tshirt2LeftArmOption6,
            option7: Tshirt2LeftArmOption7,
        },
        bothArms: {
            option1: [Tshirt2BothArmsOption1Right, Tshirt2BothArmsOption1Left],
            option2: [Tshirt2BothArmsOption2Right, Tshirt2BothArmsOption2Left],
            option3: [Tshirt2BothArmsOption3Right, Tshirt2BothArmsOption3Left],
        },
    },
    cycling1: {
        front: Tshirt2Cycling1,
        bothArms: {
            option1: [Tshirt2BothArmsOption1Cycling1Right, Tshirt2BothArmsOption1Cycling1Left],
        }
    },
    cycling2: {
        front: Tshirt2Cycling2,
        bothArms: {
            option1: [Tshirt2BothArmsOption1Cycling2Right, Tshirt2BothArmsOption1Cycling2Left],
        }
    }
};

export default tshirt2;
