import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7287)"
            d="M494.769 223.291a2.708 2.708 0 01.821 2.482c-.805 4.666-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7287)"
            d="M506.804 229.697c-4.634-2.392-9.425-4.866-14.031-7.468l-.124-.071a27.51 27.51 0 00-13.382-3.494 27.55 27.55 0 00-24.035 14.099 27.531 27.531 0 0010.604 37.417c4.122 2.301 8.13 4.723 12.216 7.211 0 0 6.215-29.067 28.752-47.694z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7287"
                x1="486.925"
                x2="440.081"
                y1="214.159"
                y2="262.448"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7287"
                x1="496.591"
                x2="449.748"
                y1="223.535"
                y2="271.824"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
