import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.495 279.902c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 0 0-17.209-9.819c4.064 7.051 7.778 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.748 62.748 0 0 0-26.519 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.882 0 179.194.875 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 0 1-2.685-7.11c8.093-3.299 18.171-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578Z"
            fill="url(#female-34-25-shirt-1-a)"
        />
        <path
            d="M503.392 254.981c4.626 6.606 10.959 14.469 20.339 25.569 7.688 9.097 10.644 17.402 8.787 24.686-2.389 9.368-12.232 15.141-20.068 18.335l-.893.364.333.906a512.27 512.27 0 0 0 2.69 7.124c7.828 20.446 18.534 48.403 9.414 97.344-.477.003-1.245.005-2.476.005-13.212 0-63.14-.234-107.191-.44-31.908-.149-59.698-.279-67.585-.293 2.533-18.837 7.936-40.755 27.525-61.609 8.654-9.212 11.178-22.949 6.59-35.848l-18.825-52.893a61.743 61.743 0 0 1 26.094-73.245c.011-.006.096-.061.245-.161a176.725 176.725 0 0 1 18.233-10.731M464.109 202.102a143.824 143.824 0 0 1 13.155 7.828"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-shirt-1-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0 0 12.049-48.824l-6.917-10.636"
                fill="url(#female-34-25-shirt-1-c)"
            />
        </g>
        <path
            d="M407.531 194.484c11.037 8.028 21.706 14.536 31.632 17.961l24.552 18.979 8-19.516 7.31 6.637"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m439.164 212.447-9.444 13.073-20.923-22.629M498.824 329.398c.701 5.498 2.456 10.967 3.87 16.41a138.364 138.364 0 0 1-3.191 80.043M468.242 235.711c10.049 9.027 20.174 21.446 27.52 31.822 7.347 10.377 11.803 18.941 9.682 30.57-1.446 7.92-5.864 15.448-6.755 23.413"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <radialGradient
                id="female-34-25-shirt-1-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
            >
                <stop offset={0.437} stopColor="#E2E2E2" stopOpacity={0} />
                <stop offset={0.587} stopColor="#E2E2E2" stopOpacity={0.33} />
                <stop offset={0.763} stopColor="#E2E2E2" stopOpacity={0.688} />
                <stop offset={0.888} stopColor="#E2E2E2" stopOpacity={0.913} />
                <stop offset={0.949} stopColor="#E2E2E2" />
            </radialGradient>
            <linearGradient
                id="female-34-25-shirt-1-a"
                x1={345.018}
                y1={332.812}
                x2={532.603}
                y2={299.939}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <filter
                id="female-34-25-shirt-1-b"
                x={402.73}
                y={251.023}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_10320_8825"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
