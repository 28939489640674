import 'styles/index.scss';

import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'components/App';
import { ProvideAuth } from 'utils/useAuth';
import ErrorBoundary from 'components/ErrorBoundary';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <ErrorBoundary>
        <React.StrictMode>
            <ProvideAuth>
                <App />
            </ProvideAuth>
        </React.StrictMode>
    </ErrorBoundary>
);
