import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9579)"
            d="M415.321 257.592c-1.524-4.686-3.098-9.533-4.453-14.351l-.041-.137a27.51 27.51 0 00-13.092-16.543 27.504 27.504 0 00-30.517 2.535 27.5 27.5 0 00-9.253 29.191c1.379 4.799 2.587 9.744 3.757 14.526.377 1.545.758 3.088 1.143 4.629l52.64-19.277-.184-.573z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9579"
                x1="356.893"
                x2="426.801"
                y1="250.315"
                y2="250.315"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
