import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={600}
            height={279}
            viewBox="0 0 600 279"
            fill="none"
            id="object-meals-5"
            {...attributes}
        >
            <path
                fill="url(#object-meals-5-a)"
                d="M230.674 208.434c-23.572 13.388-30.985 20.671-53.926 14.35-22.942-6.322-91.505-38.292-87.374-53.285 4.131-14.993 79.393-7.332 102.334-1.011 22.942 6.322 27.354 19.503 38.965 39.946h.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M230.674 208.434c-23.572 13.388-30.985 20.671-53.926 14.35-22.942-6.322-91.505-38.292-87.374-53.285 4.131-14.993 79.393-7.332 102.334-1.011 22.942 6.322 27.354 19.503 38.965 39.946h.001Z"
            />
            <path
                fill="url(#object-meals-5-b)"
                d="M216.426 204.988c-21.411 21.411-27.231 31.453-53.811 31.453S52.721 222.359 52.721 204.988s83.315-31.453 109.895-31.453 35.241 12.884 53.81 31.453Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M79.292 187.779c26.772-8.578 66.463-14.244 83.323-14.244 26.579 0 35.242 12.884 53.811 31.453-21.411 21.411-27.231 31.453-53.811 31.453S52.721 222.358 52.721 204.987c0-4.187 4.84-8.182 12.558-11.836"
            />
            <path
                stroke="#00B140"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M178.278 204.988H87.709M124.974 204.99l-13.706-13.705M154.405 204.991l-16.358-16.358M104.762 204.988l-10.926 10.926M138.457 204.988l-10.926 10.926"
            />
            <path
                fill="url(#object-meals-5-c)"
                d="M231.457 200.633c-76.547 0-113.432 16.481-145.832 35.811h93.979c38.968 0 51.853-16.481 51.853-35.811Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M182.817 203.28c-45.311 5.408-72.548 18.462-97.192 33.164h93.979c38.968 0 51.853-16.481 51.853-35.811-10.923 0-21.039.336-30.449.967"
            />
            <path
                fill="url(#object-meals-5-d)"
                d="M395.516 184.176c34.517 0 64.103 17.471 76.554 42.311 6.044 12.059-2.919 26.254-16.408 26.254H256.255c-10.499 0-15.604-12.729-8.096-20.069 28.72-28.075 110.04-48.496 147.357-48.496Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M449.203 200.52c9.909 6.985 17.804 15.867 22.867 25.967 6.044 12.059-2.919 26.254-16.408 26.254H256.255c-10.499 0-15.604-12.729-8.096-20.069 28.72-28.075 110.04-48.496 147.357-48.496 8.359 0 16.428 1.025 24.035 2.93M444.703 239.629l-36.652-36.652M408.973 239.629l-36.653-36.652M373.242 239.629l-36.653-36.652"
            />
            <path
                fill="url(#object-meals-5-e)"
                d="M383.223 131.295c-8.228-10.29-19.011-17.964-30.67-23.196a263.209 263.209 0 0 1-6.134-2.995c-17.666-9.002-40.126-17.518-67.123-17.518-26.997 0-49.457 8.516-67.123 17.518-1.52.779-4.585 2.268-6.134 2.995-11.66 5.231-22.442 12.906-30.67 23.196a6.604 6.604 0 0 0 0 8.122c2.044 2.617 4.089 4.762 6.134 6.743 7.212 7.003 15.658 12.505 24.537 16.453 10.28 4.774 37.341 20.519 73.257 20.519 35.915 0 62.976-15.744 73.256-20.519 8.878-3.948 17.325-9.45 24.537-16.453 2.044-1.981 4.089-4.126 6.134-6.743 1.902-2.435 1.82-5.791-.001-8.122Z"
            />
            <path
                fill="url(#object-meals-5-f)"
                d="M279.893 97.557c-33.642-.456-63.624 14.603-62.647 19.399.689 3.384 16.548.456 64.749.927 43.804.428 58.049 3.114 58.748-.057 1.032-4.683-27.997-19.824-60.85-20.269Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M212.173 105.104c-1.52.779-4.585 2.268-6.134 2.995-11.66 5.231-22.442 12.906-30.67 23.196a6.604 6.604 0 0 0 0 8.122c2.044 2.617 4.089 4.762 6.134 6.743 7.212 7.003 15.658 12.505 24.537 16.453 10.28 4.774 37.341 20.519 73.257 20.519 35.915 0 62.976-15.744 73.256-20.519 8.878-3.948 17.325-9.45 24.537-16.453 2.044-1.981 4.089-4.126 6.134-6.743 1.902-2.435 1.82-5.791 0-8.122-8.229-10.29-19.011-17.964-30.67-23.196a265.25 265.25 0 0 1-6.135-2.995c-17.665-9.002-40.125-17.518-67.122-17.518-15.78 0-30.011 2.91-42.639 7.126M225.625 68.68c0-11.198 6.767-11.198 6.767-22.395 0-11.198-6.767-11.2-6.767-22.398 0-11.2 6.767-11.2 6.767-22.399M275.914 68.68c0-11.198 6.767-11.198 6.767-22.395 0-11.198-6.767-11.2-6.767-22.398 0-11.2 6.767-11.2 6.767-22.399M326.201 68.68c0-11.198 6.767-11.198 6.767-22.395 0-11.198-6.767-11.2-6.767-22.398 0-11.2 6.767-11.2 6.767-22.399"
            />
            <g clipPath="url(#object-meals-5-g)">
                <path
                    fill="url(#object-meals-5-h)"
                    d="M328.41 252.688H1.656c14.748 14.879 34.974 23.271 56.096 23.271H469.79c21.12 0 41.346-8.39 56.095-23.271H328.41Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M442.86 275.959h26.93c21.12 0 41.347-8.39 56.096-23.271H1.656c14.748 14.879 34.974 23.271 56.096 23.271h340.641"
                />
            </g>
            <path
                fill="#00B140"
                d="M174.605 244.508h194.021v8.229H182.834a8.233 8.233 0 0 1-8.229-8.229Z"
            />
            <path
                fill="#7CCC6C"
                d="M237.006 236.281h131.621v8.229H245.235a8.233 8.233 0 0 1-8.229-8.229Z"
            />
            <path
                fill="url(#object-meals-5-i)"
                d="M402.038 252.738h-33.411V236.28l30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006l.001-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m368.626 236.28 30.889-5.26c6.909-1.626 13.527 3.615 13.527 10.713 0 6.078-4.927 11.006-11.006 11.006h-33.411v-8.229M368.628 252.742H221.344"
            />
            <path
                fill="#fff"
                d="M595.099 228.332c0-13.289-10.773-38.281-24.063-38.281s-24.063 24.992-24.063 38.281c0 13.289 10.773 24.063 24.063 24.063s24.063-10.773 24.063-24.063Z"
            />
            <path
                fill="url(#object-meals-5-j)"
                d="M573.812 190.426c4.048 7.711 6.532 16.777 6.532 23.153 0 13.29-10.773 24.063-24.063 24.063-2.789 0-5.465-.48-7.957-1.353 3.286 9.379 12.21 16.107 22.71 16.107 13.29 0 24.063-10.773 24.063-24.063 0-12.35-9.304-34.793-21.285-37.907Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M591.957 240.231c2-3.509 3.143-7.57 3.143-11.898-.001-13.29-10.774-38.282-24.064-38.282-13.29 0-24.063 24.992-24.063 38.281 0 13.289 10.773 24.063 24.063 24.063a23.95 23.95 0 0 0 12.428-3.454"
            />
            <path
                fill="url(#object-meals-5-k)"
                d="M580.395 255.516h-18.716a28.255 28.255 0 0 1-7.761 19.451c-.845.89-.214 2.36 1.014 2.36h32.21c1.228 0 1.859-1.469 1.014-2.36a28.255 28.255 0 0 1-7.761-19.451Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M580.395 255.516h-18.716a28.255 28.255 0 0 1-7.761 19.451c-.845.89-.214 2.36 1.014 2.36h32.21c1.228 0 1.859-1.469 1.014-2.36a28.255 28.255 0 0 1-7.761-19.451Z"
            />
            <path
                fill="url(#object-meals-5-l)"
                d="M543.375 231.797h55.326v.884c0 15.268-12.395 27.663-27.663 27.663s-27.663-12.395-27.663-27.663v-.884Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M562.469 231.797h36.232v.884c0 15.278-12.385 27.663-27.663 27.663M561.503 258.657c-10.58-3.884-18.128-14.049-18.128-25.976v-.884h8.709"
            />
            <defs>
                <linearGradient
                    id="object-meals-5-a"
                    x1={155.149}
                    x2={139.991}
                    y1={171.236}
                    y2={111.742}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-b"
                    x1={151.945}
                    x2={108.366}
                    y1={223.617}
                    y2={180.038}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-c"
                    x1={194.859}
                    x2={144.459}
                    y1={236.834}
                    y2={211.444}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-d"
                    x1={410.313}
                    x2={314.582}
                    y1={174.314}
                    y2={295.484}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-e"
                    x1={277.088}
                    x2={348.644}
                    y1={130.064}
                    y2={301.63}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#AA0061" />
                    <stop offset={1} stopColor="#D7003C" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-f"
                    x1={307.546}
                    x2={278.356}
                    y1={254.023}
                    y2={114.094}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-h"
                    x1={1.656}
                    x2={525.884}
                    y1={264.323}
                    y2={264.323}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-i"
                    x1={321.997}
                    x2={408.676}
                    y1={269.901}
                    y2={235.717}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-j"
                    x1={564.872}
                    x2={587.104}
                    y1={211.973}
                    y2={265.026}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-k"
                    x1={590.578}
                    x2={554.893}
                    y1={266.421}
                    y2={266.421}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-5-l"
                    x1={592.648}
                    x2={553.185}
                    y1={246.071}
                    y2={246.071}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <clipPath id="object-meals-5-g">
                    <path fill="#fff" d="M.27 251.324h527v26H.27z" />
                </clipPath>
            </defs>
        </svg>
    );
}
