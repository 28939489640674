import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={178}
            height={106}
            fill="none"
            id="object-food-oatmeal"
            {...attributes}
        >
            <path
                fill="url(#object-food-oatmeal-a)"
                d="M56.3 12.344 20.466 43.088h136.801l-35.837-30.744c-18.735-16.073-46.394-16.073-65.128 0Z"
            />
            <path
                fill="#fff"
                d="M176.277 27.738c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-52.55c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h174.82Z"
            />
            <path
                fill="url(#object-food-oatmeal-b)"
                d="M176.276 27.738c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-21.58c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h143.85Z"
            />
            <path
                fill="#EF0E2E"
                d="M171.757 43.088c-1.78 4.83-4.12 10.18-7.19 15.73h-151.4c-3.07-5.55-5.41-10.9-7.19-15.73h165.78Z"
            />
            <path
                fill="#64CCC9"
                d="M160.986 64.818c-1.73 2.71-3.65 5.43-5.77 8.14H22.506a107.07 107.07 0 0 1-5.77-8.14h144.25Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M142.54 86.471c23.592-20.994 31.168-47.195 33.734-58.729H1.454c2.564 11.534 10.141 37.735 33.733 58.73 9.672 8.606 19.484 14.068 27.403 17.565h52.547c4.664-2.06 9.985-4.8 15.546-8.464"
            />
            <defs>
                <linearGradient
                    id="object-food-oatmeal-a"
                    x1={180.277}
                    x2={49.838}
                    y1={183.53}
                    y2={-16.872}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-food-oatmeal-b"
                    x1={32.426}
                    x2={176.276}
                    y1={65.888}
                    y2={65.888}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
