import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7205)"
            d="M475.684 232.67l.065-.318a64.022 64.022 0 001.386-10.445 32.92 32.92 0 00-1.357-11.178l-.834-2.627 2.325 1.482a106.713 106.713 0 0117.498 13.757 2.715 2.715 0 01.821 2.482c-.805 4.667-13.806 10.892-16.404 12.093l-.912.421-2.588-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7205)"
            d="M540.283 335.501a20.186 20.186 0 01-11.254-3.415 244.562 244.562 0 01-19.749-15.059c-6.003-5.033-11.974-10.373-18.256-16.325a193.483 193.483 0 01-16.77-17.707 829.892 829.892 0 01-4.682-5.65c-3.524-4.275-7.17-8.695-10.899-12.904a27.5 27.5 0 1141.166-36.475l.085.095c3.72 4.24 7.657 8.419 11.465 12.46a1681.92 1681.92 0 014.999 5.328 193.1 193.1 0 0115.47 18.86c5.116 6.972 9.665 13.563 13.904 20.146a244.373 244.373 0 0112.469 21.48 20.123 20.123 0 01-8.147 26.604 20.118 20.118 0 01-9.801 2.562z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_7205)"
            d="M540.309 342.159a19.03 19.03 0 01-18.661-15.413 24.063 24.063 0 01-.317-6.329c.143-1.813.476-3.607.994-5.351a10.083 10.083 0 012.507-4.545c.324-.338.667-.678 1.009-1.018a20.77 20.77 0 002.104-2.294 19.602 19.602 0 0115.645-7.842 19.502 19.502 0 0115.617 31.162l-.24.315a20.995 20.995 0 00-1.657 2.641 49.62 49.62 0 01-.719 1.253 10.13 10.13 0 01-3.736 3.627 25.836 25.836 0 01-4.85 2.356c-2 .731-4.087 1.195-6.209 1.381-.498.038-.995.057-1.487.057z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7205"
                x1="468.651"
                x2="557.784"
                y1="210.223"
                y2="313.018"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7205"
                x1="457.082"
                x2="546.214"
                y1="220.254"
                y2="323.049"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_7205"
                x1="521.26"
                x2="563.081"
                y1="320.763"
                y2="320.763"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
