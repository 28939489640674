import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-office-4"
            {...attributes}
        >
            <path fill="#fff" d="M.05 0h2570v1472H.05z" />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05M1713.85 667H310.852l-55.5 40.5H1465.85V727H246.352v28.5h168v105M394.848 781v40.5M430.848 781v40.5"
            />
            <path fill="url(#a)" d="M1433.22 821H868.051v28.537h565.169V821Z" />
            <path
                fill="url(#b)"
                d="M1001.42 1190.92h-16.604l66.444-341.381h36.25l-86.09 341.381Z"
            />
            <path
                fill="url(#c)"
                d="M1299.85 1190.92h16.61l-66.45-341.381h-36.25l86.09 341.381Z"
            />
            <path
                fill="#3A383A"
                d="M1142.33 1190.92h16.61l9.82-341.381h-36.25l9.82 341.381Z"
            />
            <path
                fill="#E2E2E2"
                d="m1268.21 943.023-18.2-93.484h-36.25l23.58 93.484h30.87ZM1063.94 943.023l23.57-93.484h-36.25l-18.19 93.484h30.87Z"
            />
            <path
                fill="#AEAEAF"
                d="m1166.07 943.023 2.69-93.484h-36.25l2.69 93.484h30.87Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1213.76 849.537H868.051V821h565.169v28.537h-183.21"
            />
            <path
                fill="url(#d)"
                d="M1160.06 790.636v-17.331h-18.84v17.331c0 5.99-4.42 11.062-10.35 11.886l-75.16 10.436v8.526h189.85v-8.526l-75.15-10.436a11.994 11.994 0 0 1-10.35-11.886Z"
            />
            <path fill="url(#e)" d="M1274.36 625h-247.45v148.168h247.45V625Z" />
            <path fill="#E2E2E2" d="M1274.36 754.461h-247.45v18.705h247.45v-18.705Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1105.92 625h168.44v148.168h-114.3M1141.22 773.168h-114.31V625h25.2M1245.56 812.958l-75.15-10.436a11.994 11.994 0 0 1-10.35-11.886v-17.331M1055.71 821.484h189.85M1141.22 773.305v17.331c0 5.99-4.42 11.062-10.35 11.886l-75.16 10.436M1274.36 754.461h-238.2"
            />
            <path fill="#AEAEAF" d="M2178.48 871.285h-18.85v82.695h18.85v-82.695Z" />
            <path fill="url(#f)" d="M2247.34 935.131v-18.85H1503.6v18.85h743.74Z" />
            <path
                fill="url(#g)"
                d="M2294.5 811.2a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55H1910l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
            />
            <path
                fill="url(#h)"
                d="m1503.6 916.277-31.2.333c0-25.036 20.3-45.332 45.33-45.332h528.31c26.82 0 53.12-4.896 78.14-14.553l131.75-51.255c15.59-6.067 33.32-1.613 44.2 11.106h-.01l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48.01-.003-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H1503.6v.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1910.03 871.277-.03.01"
            />
            <path
                fill="#616469"
                d="M2209.64 1094.77V935.129h18.85v256.221h-18.85v-77.73"
            />
            <path
                fill="#AEAEAF"
                d="M1539.12 935.133h-18.85v256.217h18.85V935.133ZM2127.73 935.133h-18.85v256.217h18.85V935.133Z"
            />
            <path fill="url(#i)" d="M2169.05 1113.62v-18.85h-524.58v18.85h524.58Z" />
            <path fill="#3A383A" d="M1644.48 935.133h-18.85v256.217h18.85V935.133Z" />
            <path fill="url(#j)" d="M1625.62 1113.62v-18.85h-86.5v18.85h86.5Z" />
            <path fill="url(#k)" d="M2209.64 1113.62v-18.85h-40.59v18.85h40.59Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2281.82 843.684-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H1503.6l-31.2.333c0-25.036 20.3-45.332 45.33-45.332h528.31c26.82 0 53.12-4.896 78.14-14.553"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1910 871.283 166.72-64.86A123.712 123.712 0 0 1 2121.6 798h132.01c25.27.632 39.29 10.34 46.52 18.578l2.71 3.177c4.94 5.776 2.7 14.7-4.38 17.456l-16.64 6.475"
            />
            <path
                fill="#616469"
                d="M1625.62 1094.77V935.129h18.85v256.221h-18.85v-77.73"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1942.97 452.295v-28.233h61.6v61.599h-61.6v-18.083M2023.41 546.756v-6.24"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1942.97 452.299h-40.92v66.69h40.92v-14.48h23.92v110.94c0 5.21 4.22 9.42 9.42 9.42 2.6 0 4.95-1.05 6.66-2.76a9.361 9.361 0 0 0 2.76-6.66v-170.73h37.68v87.251M2029.81 554.708l1.85 5.21c1.66 4.67 1.6 9.84-.48 14.34-1.8 3.86-4.61 6.34-7.77 6.34-3.16 0-5.97-2.48-7.76-6.34-2.09-4.5-2.15-9.67-.49-14.34l3.43-9.63c.6-1.68 1.97-3.03 3.72-3.41.37-.08.73-.12 1.1-.12M2029.81 554.703h-6.12"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2023.41 540.518h6.4v-8.549h-6.4M1973.78 432.383v6.347M1957.88 438.969l4.49 4.488M1951.3 454.859h6.34M1957.88 470.761l4.49-4.488M1973.78 477.339v-6.347M2168.33 521.307h123.28v-28.221h-134.55v-18.841h134.55v-28.222h-134.55"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2175.9 462.359v-35.093h-75.29v94.042h67.72M2204.16 417.842l2-16.842H2221l2 16.842c0 5.203-4.22 9.42-9.42 9.42M2260.06 427.263h9.42V411.8l12.13-8V401h-35.6l4.63 10.232v10.078M2194.74 521.308v39.59c0 5.203 4.22 9.42 9.42 9.42s9.42-4.218 9.42-9.42V427.266M2260.06 427.266v133.632c0 5.203-4.22 9.42-9.42 9.42s-9.42-4.218-9.42-9.42v-39.59"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2132.98 494.812h-16.29v10.422h16.29v-10.422Z"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1295.65 584.349h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1556.35 238v373.95h-336.3V247.9h305.55v336.45h-178.95"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1346.65 584.651 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#l)"
            >
                <path 
                    d="M521.346 227.526v330.063H343.051V201H699.64v356.589H552.23M490.461 532.575V484.07M552.23 532.575V484.07" />
            </g>
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#m)"
            >
                <path
                    d="M899.346 227.526v330.063H721.051V201h356.589v356.589H930.23M868.461 532.575V484.07M930.23 532.575V484.07" />
            </g>
            <path
                fill="url(#n)"
                d="M608.051 1191.26h14l51.031-151.53h-24.206l-40.825 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m660.05 998.258-11.175 41.472h24.206l13.969-41.472h-27Z"
            />
            <path
                fill="url(#o)"
                d="M712.051 1191.26h14l16.967-151.53h-24.259l-6.708 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m720.55 999.258-1.792 40.472h24.259l4.533-40.472h-27Z"
            />
            <path
                fill="#AEAEAF"
                d="M789.031 998.258h-27l1.293 41.472h24.206l1.501-41.472Z"
            />
            <path
                fill="#3A383A"
                d="m763.324 1039.73 4.726 151.53h14l5.48-151.53h-24.206Z"
            />
            <path
                fill="#AEAEAF"
                d="m853.731 998.398-27.11.084 12.504 41.258h24.32l-9.714-41.342Z"
            />
            <path
                fill="#3A383A"
                d="m899.051 1191.26-35.606-151.53h-24.32l45.926 151.53h14Z"
            />
            <path
                fill="url(#p)"
                d="m848.055 979.204 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.691-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
            />
            <path
                fill="url(#q)"
                d="M661.445 980.076c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867l-27.243-100.619a52.824 52.824 0 0 0-39.303-36.786 240.32 240.32 0 0 0-40.622-5.389c-11.795-.549-23.087-.156-33.298.712-27.036 2.299-47.983 24.599-48.451 51.728l-.817 47.397c.263 13.544 1.991 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M661.444 980.076c45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H655.279c-18.807 0-33.961-15.418-33.637-34.222l1.661-96.338c.468-27.129 21.415-49.43 48.451-51.728 10.211-.868 21.503-1.261 33.298-.712a240.292 240.292 0 0 1 40.622 5.389 52.82 52.82 0 0 1 39.303 36.786l27.243 100.619"
            />
            <path
                fill="#FCB47F"
                d="M809.847 691.755c-9.2-7.1-20.001-12.7-32.601-17.2l19.101 31.5c0 .1 17.9-10.8 13.5-14.3Z"
            />
            <path
                fill="url(#r)"
                d="m774.945 672.754 2.701 1c13.1 4.7 23.799 10.3 32.799 17.3.8.6 1.1 1.4 1.1 2.3-.2 4.6-12.2 12.2-14.6 13.7l-.9.5-21.1-34.8Z"
            />
            <path
                fill="#FCB47F"
                d="M797.843 689.153c9.2 2.4 18.4 4.4 27.6 6.5 9.2 2.1 18.3 4.8 27.2 8 9 3.1 17.9 6.5 26.7 10.1 8.8 3.7 17.6 7.5 26.2 12.1 11.3 6 15.6 20 9.6 31.3-4.2 8-12.5 12.4-20.9 12.3-9.7-.2-19.3-1.1-28.8-2.1-9.5-1.1-18.9-2.5-28.2-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.6-21.2-22.3-37.7 4.3-16.6 21.2-26.6 37.7-22.3l.2-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m878.144 748.154-.5-7.7c-.2-2.6-.401-5.1-.601-7.7-.1-2.6-.399-5.1-.399-7.7 0-1.3-.101-2.6-.101-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7 1.1-8.7 9.1-14.9 17.8-13.8 6.1.8 11 5 13 10.5 1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.601 2.5.901 3.8.3 1.3.499 2.5.799 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.701 5.1 1.101 7.6l1.099 7.6c1.8 12.3-6.7 23.7-19 25.6-12.3 1.9-23.699-6.7-25.599-19-.1-.4-.2-1-.2-1.6Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M903.845 768.852c-14.1.8-28.801-.6-38.301-1.6-9.5-1.1-18.899-2.5-28.199-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.601-21.2-22.301-37.7 4.3-16.6 21.201-26.6 37.701-22.3h.1c9.2 2.4 18.4 4.4 27.6 6.5 9.2 2.1 18.3 4.8 27.2 8 7 2.4 13.9 5 20.8 7.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M877.647 740.453c-.2-2.6-.4-5.1-.6-7.7-.1-2.6-.4-5.1-.4-7.7 0-1.3-.1-2.6-.1-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7M909.245 682.652c1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.601 2.5.901 3.8.3 1.3.499 2.5.799 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.701 5.1 1.101 7.6l1.099 7.6c1.8 12.3-6.7 23.7-19 25.6"
            />
            <path
                fill="#FCB47F"
                d="M917.443 658.454c-3.6-4.5-8.101-8.2-13.201-11-2.1-1.2-4.299-2.001-6.599-2.401h-.101c-.2-4.9-.4-9.799-.5-14.699-.1-3.2-.099-6.3-.199-9.4 0-1.5-1.2-2.8-2.7-3.1h-.101c-1.6-.2-3.1.8-3.5 2.3-1.4 5.2-1.6 10.699-1.8 16.099-.3 6.4-.699 12.901-3.399 18.701.1-.2-1.401 3.499-1.301 3.299l-2 4.201c-.4-1.2-.5-2.5-.3-3.8.2-1.8.701-3.5.901-5.3.1-1 .099-2.101-.101-3.101a2.88 2.88 0 0 0-2.599-2.2c-1.2-.1-2.401.6-2.901 1.7-.8 1.8-1.999 3.8-2.899 5.3-2.5 4.4-2.6 9.8-1.2 14.7 1.3 4.9 3.999 9.201 6.799 13.401 8.6 13.8 23.701 16.8 29.701 5.2 3.9-6.8 7-14.4 9.5-21.9.8-2.8.3-5.8-1.5-8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M879.74 683.153c-2.8-4.2-5.5-8.601-6.8-13.401-1.3-4.9-1.2-10.3 1.2-14.7.9-1.5 2.1-3.5 2.9-5.3.5-1.1 1.7-1.8 2.9-1.7 1.3.1 2.4 1 2.6 2.2.2 1 .2 2 .1 3.1-.2 1.8-.7 3.501-.9 5.301-.2 1.8 0 3.699 1 5.099M885.642 654.454c-.1.2-.101.3-.201.5 2.7-5.8 3.201-12.3 3.401-18.7.2-5.4.4-10.9 1.8-16.1.4-1.6 1.9-2.6 3.5-2.3h.099c1.5.2 2.7 1.5 2.7 3.1.1 3.2.101 6.3.201 9.4.2 4.9.4 9.8.5 14.7M896.042 648.355c-2.9 2.6-5.9 5.4-7.4 9-1.5 3.6-1.299 7.7-.299 11.4.3 1.3.799 2.601 1.799 3.401 1 .8 3.2 1.799 4.9-1.101M902.84 653.855c-2.8 1.9-4.601 5-5.801 8.2-1.1 3.2-1.6 6.601-2 9.901-.2 1.2-.3 2.599.5 3.599.8 1.1 2.501 1.3 3.801.8 1.3-.5 3.1-2.9 3.8-4.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M900.742 645.555c6.5 2.9 12.3 7.4 16.8 12.9 1.8 2.2 2.4 5.3 1.4 8-2.5 7.6-5.6 15.1-9.5 21.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M910.539 660.055c-4 4-7.599 10.4-8.399 16.2-.2 1.1.4 2.2 1.5 2.7h.099c1.2.6 2.6.1 3.3-1 1.8-2.7 4.201-5 6.801-7"
            />
            <path
                fill="url(#s)"
                d="M923.846 743.152c-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-3.3-14.6-4.8-20.2 0 0-10.101 4.3-22.401 1.6-12.2-2.7-16.5-10.1-16.5-10.1-.7 6.7-1 12.6-1.2 19.6-18.6-7.5-38.7-14.4-58.3-18.4-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.399-1-8.099-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.199 5.1 27.299 7.7 17.5 5.1 38.201 8.2 56.101 10.4 7.8.9 18.199 1.9 28.899 2.2 1.5 0 2.901-.1 4.401-.3 14.9 1.3 27.6-12.3 25.2-27Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M809.344 757.853c17.5 5.1 38.199 8.2 56.099 10.4 7.8.9 18.201 1.9 28.901 2.2 1.5 0 2.899-.1 4.399-.3 14.8 1.4 27.5-12.2 25.2-26.9-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-4-15.6-5.8-20.6 0 0-6.1 4.3-20.2 2.3-13.8-1.9-16.8-10.1-16.8-10.1-.9 5.6-1.699 12.3-1.899 19.3-18.6-7.5-38.701-14.4-58.301-18.4M777.645 673.652c13.1 4.7 23.799 10.3 32.799 17.3"
            />
            <path
                fill="#FCB47F"
                d="M681.045 888.153c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                fill="url(#t)"
                d="M681.045 888.153c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M827.547 732.254c3.9 8.6 6.8 16.6 8.6 23.1 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M828.245 785.353c-3.6-6.2-7.7-12.9-11.1-16.9M718.945 786.553c31.1 3.8 56.501-1.2 71.701-22.1M819.145 864.052c3.6-1.3 6.999-1.4 10.299 0"
            />
            <path
                fill="#FCB47F"
                d="M892.646 1136.95c-.9-8.5-1.699-17-2.399-25.4-.3-4.2-.7-8.5-1-12.7l-.801-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.697 1.7-8.2 3.701-16.3 6.201-24.4.6-2 1.3-4 2-6.1.7-2 1.399-4 2.199-6 1.5-4 3.201-8 5.301-12l5.299-10.2c6.7-12.9 22.6-18 35.5-11.3 10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.199 24.597-4.401 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9 49.2c-1.6 8.7-9.9 14.4-18.5 12.8-7.1-1.3-12.2-7.1-13-13.9l-.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M896.047 976.854c10.2-2 20.4-4.5 30.8-8.3l3.3-1.2c6.4-2.3 12.101-6.7 16.101-12.9 9.6-15 5.199-35-9.801-44.6-8.9-5.7-18-10.4-27.1-14.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.799-.8-7.199-1.1l-3.601-.5-3.6-.3c-2.4-.2-4.899-.3-7.299-.3-21.8.1-42.301 13.7-49.901 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h80.1l-.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m892.95 1139.55-.3-2.6c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.5-1-12.7l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.698 1.7-8.2 3.7-16.3 6.2-24.4M945.449 915.754c10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.596-4.399 24.6c-2.9 16.4-5.801 32.8-8.801 49.2l-9 49.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M945.449 915.752c-8.9-5.7-26.9-16.3-36-20.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h71.1"
            />
            <path
                fill="#FCB47F"
                d="M892.046 1137.15c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.101 3c4.3.6 8.499 1.9 12.399 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.101.6 26.301.6 2.2 0 3.999-1.7 4.099-3.9.1-1.6-.799-3.1-2.199-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M892.046 1137.15c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.101 3c4.3.6 8.499 1.9 12.399 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.101.6 26.301.6 2.2 0 3.999-1.7 4.099-3.9.1-1.6-.799-3.1-2.199-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                fill="#3A383A"
                d="M968.049 1193.35c-8.1 0-18.6-.1-21.9-.6-9.2-1.4-16-4.1-20.1-6.1-3.7-1.8-7.801-3-12.101-3.7l-20.1-3c-2.8-.4-5.199-1.9-6.699-4.2-1.6-2.3-2.2-5-1.6-7.8 3.5-16.8 5.4-27.8 5.5-31l.399-13 36.301 7.1-2.401 10.4c2.3 3.2 19.901 27.1 37.301 35.6 4.3 2.1 9.7 4.9 12.5 6.4 1.7.9 2.799 2.8 2.699 4.8-.1 2.7-2.399 4.9-5.099 4.9-1.4.2-3 .2-4.7.2Z"
            />
            <path
                fill="#888B8D"
                d="M975.047 1183.65c-2.8-1.5-8.1-4.4-12.5-6.5-7.4-3.6-14.9-10.1-21.2-16.5-2.2-2.2-4.2-4.4-6-6.6-3.4-.4-14.4 4.4-20.2 4.2-11.5-.4-19.6-5.6-26.6-5.8-.8 4.4-1.9 9.5-3.1 15.4-.6 2.7 0 5.5 1.6 7.8s4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5 2.7 0 5-2.1 5.1-4.9.2-2-.8-3.9-2.6-4.8Z"
            />
            <path
                fill="#888B8D"
                d="M887.148 1175.55c1.6 2.3 4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .701.1 1.101.1 5.1.5 18.099.6 25.399.5M891.647 1137.05c-1.1-9.5-7.401-86.9-8.301-101.5-1.1-17.9 1.801-37.398 6.201-57.098h-73.4c-33.2-6.7-53.501-39.3-42.301-71.7 9.3-26 35.001-39.6 61.901-35.5 21.4 2.5 42.3 8 60.3 16.6 13.8 6.3 26 11.7 40.9 21.1 2 1.3 5.399 2.9 7.399 4.1 11.5 7.1 18.201 17.7 15.901 30.7-2.8 16.5-9.9 57.998-12.7 73.898-2 11.3-10.6 102-11.4 123.1-8.7-.9-34.6-2.6-44.5-3.7Z"
            />
            <path
                fill="#FCB47F"
                d="M792.646 569.553c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.201-4.7 32.901-1.6 6.1-2.7 12.9-6.2 18-2.5 3.699-7.8 5.199-12.3 4.799-.7-.1-1.3-.1-2-.2.4 1.3.8 11.6.8 12.401l-8.6 6.199-46.7-18 2.6-11.6.4-13s-1.1-8.5-9.1-24c-10.1-19.5 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.3-9.6 40.9-2.7 56.6 13Z"
            />
            <path
                fill="url(#u)"
                d="M799.448 593.855c-2-.2-4-.4-6-.7-2-.2-4.099-.4-5.699.8-1.2.9-1.801 2.4-1.901 3.8-.1 1.5-.399 3-.099 4.4 2.2 12.9 1.499 21.9-.901 24.5-.5.6-.999 1.3-1.099 2-.1.8.099 1.5.199 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.801 4.7 1.101 7 .4 3.9-.601 7.3-4.601 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18.1-.8-37.2 1.1-55.7Z"
            />
            <path
                fill="url(#v)"
                d="M775.746 671.154c-.2-3.7-.4-7.4-.5-11.1-14.5 1.2-30.2-11.6-36.6-18.4-.9-1 2.2 13.8 5.3 24.1 7.3 3.3 22.8 8.7 31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M738.648 640.853c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.701 9.5 24.701 3.9 8.6 1.8 21.1-.7 30"
            />
            <path
                fill="#FCB47F"
                d="M786.249 599.055c.2 5.9 2.499 11.6 5.699 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M786.249 599.055c.2 5.9 2.499 11.6 5.699 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="url(#w)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M800.75 595.553c-2.5-1.2-6.201-1.8-8.801-2.1"
            />
            <path
                stroke="url(#x)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M778.448 593.453c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M795.349 610.752c.773 0 1.401-1.029 1.401-2.3 0-1.27-.628-2.3-1.401-2.3s-1.4 1.03-1.4 2.3c0 1.271.627 2.3 1.4 2.3ZM769.748 610.752c.773 0 1.4-1.029 1.4-2.3 0-1.27-.627-2.3-1.4-2.3-.773 0-1.4 1.03-1.4 2.3 0 1.271.627 2.3 1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M790.649 634.853c-7.6 1.2-15.4.5-22.7-1.9M737.546 607.853c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M807.146 817.451c-5-7.4-9.2-15-13.3-22.6-4.1-7.7-7.8-15.5-11.4-23.3-3.6-7.8-6.8-15.8-9.3-24-2.6-8.2-5-16.4-7.9-24.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M801.344 920.652h15.5"
            />
            <path
                fill="url(#y)"
                d="M851.947 844.053c-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-5.9-21.5-25.4-62.9-52.5-82.8-.8-.6-1.9-1.4-3.1-2.2-.2-.2-.5-.3-.7-.5-1.9-1.3-4-2.6-5.3-3.4.1 1.4.3 4.8.3 4.8s0 .3.1.8c.6 3.5 2.7 18.2 1.4 23.1l-6.1-4.7-18.4-14.1c-19.9-8.3-32.3-21.7-32.3-21.7s-10.2 3.3-24.4 11.2c-.1 0-.1.1-.2.1-.9.5-1.9 1.1-2.8 1.6-1 .6-2.1 1.2-3.1 1.8-4.7 2.8-9.3 5.9-13.8 9.2-1 .7-1.9 1.4-2.9 2.1-.8.6-1.6 1.2-2.3 1.8l-.1.1c-12 9.4-18.8 24.5-18.5 40v.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5h.3v.2l.3-.2 144.6-9.8c5.6-3.8 11.8-8.6 17.8-13.3 5.4-4.3 10.7-8.6 15-12.1 2.3-1.9 4.5-3.5 6.2-4.7.1-12.1-.2-22.5-.9-30.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M779.348 698.852c1.8 1.8 7.3 12.1 13 23.6M720.648 654.652c7 8.1 22.3 18.2 32.2 21.6l24.6 19 8-19.5 7.301 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m752.847 676.254-9.4 13.1-20.9-22.6M798.945 731.055l9.201 25.8c2.6 7.3 4.399 14.8 5.299 22.4l12.9 104.7M852.946 874.754c0-12.1-.3-22.5-1-30.7-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-3.2-11.8-10.5-29.5-20.9-46.4M712.347 657.652c-14.9 6.6-28.7 14.5-41.4 24.7-11.9 9.6-18.8 24.6-18.5 40.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5"
            />
            <path
                fill="#FCB47F"
                d="M865.942 1120.35c-5.3-6.9-10.5-13.9-15.5-20.9-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-2.9-8-5.5-16.1-7.7-24.5-.6-2.1-1-4.2-1.5-6.3-.4-2.1-.9-4.2-1.3-6.4-.8-4.3-1.5-8.7-1.8-13.2l-.9-11.3c-1.2-14.5 9.7-27.2 24.2-28.4 11.8-.9 22.4 6.1 26.6 16.5l19 47.3 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.6c3.2 8.2-.9 17.4-9.1 20.6-6.7 2.6-14.2.3-18.4-5.1l-1.8-2Z"
            />
            <path
                fill="url(#z)"
                d="M783.843 976.752c2.5-.4 3.101-.7 5.601-1.1 11.799-2.1 22.9-9.1 29.6-20.4 11.5-19.4 5.6-44.5-13-57.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.801-.6-1.799-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7c-3.1-.3-6.3-.4-9.5-.2-28.2 1.6-51.101 24.4-52.801 52.6-1 17.7 6.101 33.7 17.901 44.6 8 7.4 18.6 11.4 29.5 11.4h66.599l-3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m867.543 1122.35-1.5-2c-5.3-6.9-10.5-13.9-15.5-20.9-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.697-1.6-4.4-3.1-8.8-4.5-13.2-1.2-3.7-2.2-7.5-3.2-11.2-.6-2.1-1-4.2-1.5-6.3M824.145 925.254l14.899 39.2 9.5 23.7 9.3 23.696c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.6M672.844 921.352c-1.1 18.2 6.5 34.6 18.9 45.6 7.8 6.9 18 10.5 28.4 10.5h54.6M824.142 925.252c-2.2-10.8-8.4-20.7-18-27.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M865.945 1120.35c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.901-3.8 7.201-8.2-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.101-33.4-23.301-50.1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M865.945 1120.35c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.901-3.8 7.201-8.2-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.101-33.4-23.301-50.1"
            />
            <path
                fill="#3A383A"
                d="M921.446 1193.35c-8.1-.2-16.3-.9-24.3-2-4.5-.7-9.2-1.5-13.3-4.1-4.8-3.1-8.4-8.8-10-16-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.5-10.4-3.5-19.7-6.6-29.6l-.7-2.2-4.2-6 30.3-19.8 4.7 11.5c4.1 8.3 7.9 16.8 11.6 25 3.7 8.2 7.5 16.7 11.6 25l.5 1c4.2 8.5 8.5 17.2 9.9 26.4.4 2.3-.3 4.7-1.8 6.5-1.5 1.8-3.8 2.8-6.2 2.8l.1.1Z"
            />
            <path
                fill="url(#A)"
                d="M884.745 1190.45c-4.7-4-9.201-12-10.801-19.2-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.2-4.3-.899-8.4-1.699-12.4 8.4-5.4 8.399-9.3 16.799-11.9 8.8-2.7 12.7 1.9 20.1 3.3 3.7 8.2 7.501 16.7 11.601 25l.5 1c4.2 8.5 8.499 17.2 9.899 26.4.4 2.3.901 6.9.701 11.1-.3 11.2-13.6 22.2-45.5-4.7Z"
            />
            <path
                fill="url(#B)"
                d="M857.344 1123.75c-6.6-11-40.499-69.4-53.099-86.8-11.3-16.2-20.101-37.697-26.801-58.497h-57.199c-11.3 0-22-4.1-30.1-11.7-40.2-36.5-8.9-105.3 45.1-98.8 7.7.8 14.5 1.8 22.9 4.5 17.2 5.4 34.5 13.9 48.6 24.5 10.5 7.3 19.3 18.3 20.9 30.9l12.399 36.2c18.2 45.197 38.2 96.797 56 142.397-4.5 6.5-25.1 16.9-38.7 17.3Z"
            />
            <path
                fill="url(#C)"
                d="M899.413 694.645c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7c-.3.9-.2 1.7.2 2.2l4.6 5.6c-.4-.5-.5-1.3-.2-2.2l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.6c.7 0 1.2.2 1.6.7l-4.7-5.6Z"
            />
            <path
                fill="url(#D)"
                d="M902.412 699.547c1.6 0 2.4 1.3 1.8 2.9l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.5Z"
            />
            <path
                fill="#888B8D"
                d="M862.016 731.145c3.2 0 4.8 2.6 3.6 5.8-1.2 3.2-4.8 5.8-8 5.8s-4.8-2.6-3.6-5.8c1.2-3.2 4.8-5.8 8-5.8Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M848.513 706.445c1.2 0 1.9 1 1.4 2.2-.5 1.3-1.9 2.3-3.1 2.3-1.2 0-1.9-1-1.4-2.2.5-1.3 1.8-2.3 3.1-2.3ZM845.31 713.945c1.2 0 1.9 1 1.4 2.2-.5 1.2-1.8 2.2-3.1 2.2-1.2 0-1.9-1-1.4-2.2.5-1.2 1.8-2.2 3.1-2.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m904.014 700.245-4.6-5.6c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M904.012 700.247c.4.5.5 1.3.2 2.2l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h38.7"
            />
            <path
                fill="#FCB47F"
                d="M716.744 711.851c1.7 7 3.8 14 5.8 21s3.4 14.101 4.3 21.301c1 7.2 1.7 14.5 2.2 21.8.4 7.4.7 14.799.2 22.299-.8 13-12 22.9-25 22.1-8-.5-14.9-5-18.7-11.3-3.9-6.5-7.2-13.199-10.2-19.899-3-6.7-5.7-13.501-8.2-20.401-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3-4.1-16.6 6-33.399 22.6-37.599 16.6-4.1 33.4 5.999 37.6 22.599l.1.3Z"
            />
            <path
                fill="#FCB47F"
                d="m705.046 780.452 13-1.9 13-2c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.8-.5 13.2-.6 8.7-.2 15.8 6.7 16 15.4.1 7-4.4 13.1-10.7 15.2-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-12.2 2.4-24-5.6-26.4-17.8-2.4-12.2 5.6-24 17.8-26.4.4 0 .7-.1 1-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M685.545 809.148c-3.9-6.5-7.2-13.2-10.2-19.9-3-6.7-5.7-13.5-8.2-20.4-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3M716.645 711.75v.2c1.7 7 3.8 14 5.8 21s3.4 14.1 4.3 21.3c.8 6.2 1.5 12.4 2 18.7M815.247 799.949c-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-10.7 2.1-22-5.8-27.2-15.6M722.145 777.85l8.9-1.4c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.799-.5 13.199-.6"
            />
            <path
                fill="url(#E)"
                d="M796.646 768.952c-2.8.2-5.7.4-8.5.6-1.6.1-3.1.2-4.7.3-4.5.3-9 .7-13.2 1.1-3 .2-6 .5-8.9.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2c-3.6-14.3-16.3-24.3-31-24.3-2.6 0-5.2.3-7.8 1-17.1 4.3-27.6 21.7-23.3 38.8 1.2 4.9 2.3 9.9 3.2 14.8.4 2.1.9 4.3 1.3 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.6 2.8 3.7 4 7.8 12.2 12.8 20.9 12.8 1.5 0 3-.1 4.5-.4l12.9-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.4 8.6-2.2 4.1-1.1 8.5-2.2 12.8-3.4 1.5-.4 3-.8 4.5-1.3 2.7-.8 5.5-1.5 8.2-2.3h.2s-7-9.4-7.2-18.6c-.1-8.4 2.9-17.3 2.9-17.3-.5 0-1.2.1-1.9.1Z"
            />
            <path
                fill="#FCB47F"
                d="M820.047 797.751c9.3-4.1 18.2-9.5 25.5-16.6 2.5-2.4 5-4.9 7.4-7.4l1.2-1c10.6-8.1 17.6-20.2 22.8-32.3.6-1.3.4-2.9-.7-3.9.5-.6.1-1.7-.4-2.3-.5-.6-1.4-.8-2.1-.9-1.1-.1-2 .9-2.9 1.6l-.6-.9c-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 7-7.8 10.1-5.3 5.4-12.7 8.7-20.3 8.9h-2.8c.6-3.9 2.7-7.4 4.6-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.6-2.6-1.9-2.9-1.1-.3-2.3.2-2.9 1.2-2.4 4.1-6.3 7.5-9.3 11.1-3.4 4.1-7 8.1-9.7 12.7-2.7 4.6-4.7 9.8-4.5 15.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M827.847 759.854c.6-3.9 2.7-7.4 4.6-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.6-2.6-1.9-2.9-1.1-.3-2.3.2-2.9 1.2-2.4 4.1-6.3 7.499-9.3 11.099-3.4 4.1-7 8.101-9.7 12.701-2.7 4.6-4.7 9.8-4.5 15.1M849.346 766.452c5.6-3.1 9.2-8.9 12.2-14.5 2.6-4.9 5-10.101 8.7-14.201.9-1 .9-2.599-.2-3.599-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 6.999-7.8 10.099-5.3 5.4-12.7 8.701-20.3 8.901"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M875.345 735.652c-1.7 3.8-3.5 7.6-5.2 11.4-2.1 4.6-4.2 9.2-6.9 13.5-4.8 7.7-11.2 14.3-17.8 20.6-7.3 7-16.2 12.5-25.5 16.6"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M876.948 740.453c-5.1 12.1-12.2 24.2-22.8 32.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M655.547 726.952c1.2 4.9 2.3 9.899 3.2 14.799.4 2.1.9 4.301 1.3 6.401 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.599 2.8 3.699 4 7.8 12.2 12.801 20.9 12.801 1.5 0 3-.1 4.5-.4l12.9-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.401 8.6-2.201 4.1-1.1 25.3-7.799 25.4-7.799 0 0-6-6.701-6.8-19.201-.7-10 2.5-15 2.5-15-.7 0-24 .701-28.2 1.101-3 .2-6 .5-8.9.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2"
            />
            <path
                fill="#7A5430"
                d="M701.694 575.801c0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1 4.1.7 6.9 4.7 6.2 8.8-.5 3.1-2.8 5.4-5.7 6.1 2.1 1.7 3.3 4.4 2.7 7.3-.7 4.1-4.7 6.9-8.8 6.2-1.6-.3-2.9-1-4-2.1-1.6 1.1-3.6 1.6-5.7 1.2-.7-.1-1.3-.3-1.9-.6-10.1-4.4-15.3-8.4-20.7-10.7-17.1-7.1-11.7 3.1-23.9 2.3 1.3 5.3 1.2 10.2-3.8 16.8-3.6 4.7-1 11.2-1 15h-5.3c-1.2-4.7-5.9-9.4-10.8-10.3-2.4-.5-5.2 0-6.9 2.2-2.9 3.8-1.3 9.8.3 14.1 1.8 4.8 4.7 8.2 9.1 10.8-.3 7.4-1.5 14.8-3.6 21.9-3.3-12.1-4.4-11.5-12.8-30.1-3.7-8.3-7.1-15.1-6.3-27.5-3.5-.7-4.2-3.1-4.2-6.8 0-1.5.4-2.9 1.2-4.1-2.8-.7-5.2-3.6-5.2-7.1Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M712.196 594.701c-3.5-.7-6.1-3.8-6.1-7.5 0-1.5.4-2.9 1.2-4.1-3.2-.9-5.5-3.8-5.5-7.3 0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M737.793 607.9c-1.9-1.6-3.8-3.3-6.2-4.2-2.4-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="url(#F)"
                stroke="#222731"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M725.141 619.566c0 5.49 8.655 13.385 11.742 13.385.995 0 2.08-1.11 1.389-2.653-1.215-2.713-4.579-5.924-3.96-6.441a5.589 5.589 0 1 0-9.17-4.291h-.001Z"
            />
            <g clipPath="url(#G)">
                <path
                    fill="url(#H)"
                    d="M961.178 743.988h58.182l-31.18 70.36h-57.779l30.777-70.36Z"
                />
                <path
                    fill="url(#I)"
                    d="M930.575 814.348h57.605a9.163 9.163 0 0 1-8.362 5.475h-99.88a19.067 19.067 0 0 1-13.356-5.475h63.993Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M922.039 819.823h-42.158a19.065 19.065 0 0 1-13.356-5.475h42.1M988.18 814.348a9.163 9.163 0 0 1-8.362 5.475h-6.62"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m988.18 814.348 24.74-55.798 6.44-14.562h-58.182l-30.777 70.36a9.163 9.163 0 0 1-8.362 5.475h-7.665"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M981.965 776.081c1.452-2.912.29-5.3-2.613-5.417-2.904-.116-6.388 2.156-7.84 5.068-1.451 2.912-.29 5.3 2.613 5.417"
                />
            </g>
            <path
                fill="#FCB47F"
                d="M1559.56 396.881c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3-.5 4.4-.5 5.1l-7.3 13.4-47.5-16s4.1-17.1 4.1-23.7c0-9.5-7-19.2-9.5-26.8-8.1-25 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.5-9.6 41.1-2.6 56.7 13Z"
            />
            <path
                fill="url(#J)"
                d="M1542.26 487.378c-14.5 1.2-30.2-11.6-36.6-18.4-1.2-1.3 9.8 29.4 10.3 31.2.6 1.7 23.6 6.5 24.6 8 4.1 5.8 2.1-12 1.7-20.8Z"
            />
            <path
                fill="url(#K)"
                d="M1566.46 421.179c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.8.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.2 1.1-55.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1505.66 468.279c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 1.7 3.7 4.1 16.1-1.4 32.4"
            />
            <path
                stroke="url(#L)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1567.76 422.979c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#M)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1545.46 420.879c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1562.36 438.178c-.78 0-1.4-1.03-1.4-2.3 0-1.27.62-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3ZM1536.76 438.178c-.78 0-1.4-1.03-1.4-2.3 0-1.27.62-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1557.76 462.279c-7.6 1.2-15.4.5-22.7-1.9"
            />
            <path
                fill="#FCB47F"
                d="M1551.16 454.279c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1551.16 454.279c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                fill="#A0A4AC"
                d="M1483.86 399.277c.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.7.1 12.3 1.9 15.3 6.5 1 2.4 1.6 4.8 1.1 7.5-.5 2.5-1.7 4.8-2.8 7.2-1.2 2.6-2.3 8.9-1.8 16.3h-5.3s-.7-3.1-5.5-7c-4.8-3.8-8.9-3.7-11.6-.9-3.8 3.9-1.5 10.9.1 14.7 2.2 5.1 4.6 7.6 9.1 11-.2 3.5-.6 6.8-1.2 10.1-.7 3.8-5.8 4.5-7.5 1.1-3.3-6.3-6.5-12.6-9.7-19.1-4.6-9.6-9.2-20-8.6-30.7.6-11.1 5.5-17.5 10.9-20.3Z"
            />
            <path
                stroke="#838891"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1481.56 450.18c-4.6-9.6-9.2-20-8.6-30.7.6-11.1 5.5-17.5 10.9-20.3.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.7.1 12.3 1.9 15.3 6.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1504.55 435.279c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M1589.92 534.161c2.81 8.111 6.02 16.078 9.06 24.107a201.648 201.648 0 0 1 7.6 24.616c2.1 8.358 3.98 16.79 5.58 25.322a255.79 255.79 0 0 1 3.62 26.002 23.266 23.266 0 0 1-30.55 23.885 23.296 23.296 0 0 1-11.95-9.08 251.11 251.11 0 0 1-13.31-22.623c-4.06-7.676-7.82-15.453-11.37-23.303a202.47 202.47 0 0 1-9.34-24.01c-2.61-8.179-5.04-16.42-7.88-24.518a31.053 31.053 0 0 1-1.71-11.975 31.154 31.154 0 0 1 3.01-11.715 30.994 30.994 0 0 1 7.26-9.673 31.009 31.009 0 0 1 10.42-6.156 30.99 30.99 0 0 1 33.36 8.568 30.93 30.93 0 0 1 6.15 10.411l.05.142Z"
            />
            <path
                fill="#FCB47F"
                d="m1613.21 626.701 6.69 9.954 6.74 9.917c4.47 6.626 8.72 13.405 12.86 20.271 2.03 3.453 4.12 6.871 6.11 10.361 2.01 3.47 4.02 6.946 5.94 10.48 1.94 3.524 3.91 7.02 5.82 10.568 1.85 3.587 3.78 7.115 5.54 10.762a15.76 15.76 0 0 1 1.08 10.764 15.761 15.761 0 0 1-6.14 8.908 15.728 15.728 0 0 1-10.44 2.829 15.713 15.713 0 0 1-9.8-4.589c-2.85-2.877-5.54-5.87-8.31-8.806-2.71-2.974-5.35-6.001-8.03-8.998-2.7-2.989-5.31-6.035-7.91-9.088-2.63-3.032-5.16-6.137-7.74-9.206-5.11-6.178-10.09-12.444-14.85-18.863l-7.12-9.65-7.16-9.613a22.53 22.53 0 0 1-3.78-7.929 22.538 22.538 0 0 1-.45-8.77 22.486 22.486 0 0 1 8.82-14.797 22.499 22.499 0 0 1 32.13 5.495Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1589.88 534.02.04.141c2.81 8.111 6.02 16.079 9.06 24.108a203.445 203.445 0 0 1 7.61 24.615c2.09 8.359 3.97 16.79 5.57 25.323.71 3.891 2.08 12.862 2.67 16.798"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1573.28 649.013a253.609 253.609 0 0 1-13.32-22.624c-4.05-7.676-7.81-15.453-11.36-23.303a202.402 202.402 0 0 1-9.34-24.01c-2.61-8.179-5.04-16.42-7.88-24.517"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1637.61 726.927c-2.85-2.877-5.54-5.87-8.31-8.806-2.71-2.974-5.35-6.001-8.03-8.999-2.7-2.988-5.31-6.034-7.91-9.087-2.64-3.033-5.17-6.137-7.75-9.206-5.1-6.178-10.08-12.444-14.84-18.863l-7.12-9.65-7.17-9.613M1615.39 629.953l4.51 6.705 6.74 9.918c4.47 6.625 8.72 13.404 12.85 20.271 2.04 3.453 4.13 6.871 6.11 10.36 2.02 3.47 4.02 6.946 5.95 10.48 1.93 3.525 3.91 7.02 5.81 10.569 1.85 3.587 3.78 7.115 5.55 10.761"
            />
            <path
                fill="#FCB47F"
                d="M1663.18 709.758a312.88 312.88 0 0 1 12.1 37.552c2.22 9.558 2.78 19.638.05 29.12-1.48 5.135-4.43 12.659-4.05 18.329.02.276-.02.554-.1.817-.09.262-.23.504-.42.71l-.09.102a1.982 1.982 0 0 1-1.54.628 1.94 1.94 0 0 1-.81-.222 1.874 1.874 0 0 1-.65-.546c-2-2.741-3.23-5.958-3.59-9.328l-.66-1.327c-2.55 1.31-7.28 1.055-11.09-1.081a6.64 6.64 0 0 1-2.04-1.811 6.719 6.719 0 0 1-1.15-2.481 6.67 6.67 0 0 1 .33-4.114 6.712 6.712 0 0 1 2.67-3.145l2.19-1.354a2.128 2.128 0 0 1 1.61-.254c1.81.476 3.69.654 5.56.529-1.55-5.475-4.21-10.557-7.77-13.67 0 0-.43-.818-.55-.263-.61 2.786-1.32 5.581-3.04 7.899-2.06 2.78-6.64 4.499-9.86 3.025l-.82-1.522c3.17-2.927 4.36-7.315 4.32-11.473a52.9 52.9 0 0 0-1.94-12.308c-1.33-5.333-2.08-14.484-1.08-18.742"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1656.95 750.078a29.71 29.71 0 0 1 8.12 19.737c.08 4.627-.96 9.22-1.09 13.847-.13 4.627.81 9.564 4.17 12.915M1671.06 792.681c.09-5.589 2.71-10.844 4.26-16.25 2.73-9.482 2.17-19.562-.05-29.12-2.22-9.559-5.07-20.044-8.89-29.135M1640.3 730.828c-.68 5.086.76 8.655 1.54 12.743.78 4.087 1.89 8.15 1.93 12.308.04 4.158-1.14 8.545-4.31 11.472M1640.8 768.991c3.22 1.474 7.28-.362 9.34-3.142 2.06-2.78 2.67-6.246 3.39-9.56.72-3.314 1.72-6.781 4.29-9.152"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1661.26 769.759c-1.39-4.474-4-8.472-7.53-11.548M1651.88 784.023c3.35 2.264 8.35 3.209 11.54 1.568M1650.15 777.066c3.14 2.537 10.77 3.112 14.33 1.08M1655.08 771.143c3.69 1.233 6.37 1.393 9.99-.069"
            />
            <path
                fill="#FCB47F"
                d="M1577.06 517.376c-9.22-7.122-19.95-12.655-32.55-17.169l19.12 31.544s17.87-10.95 13.43-14.375Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1544.51 500.207a129.741 129.741 0 0 1 32.55 17.169"
            />
            <path
                fill="url(#N)"
                d="M1577.06 517.376c-9.22-7.122-19.95-12.655-32.55-17.169l19.12 31.543s17.87-10.949 13.43-14.374Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1557.29 505.359a115.181 115.181 0 0 1 19.77 11.352"
            />
            <path
                fill="url(#O)"
                d="M1665.29 707.273c-18.64-32.603-39.54-62.24-49.91-77.384-.75-7.34-1.91-14.538-3.22-21.683-7.57-40.506-14.06-50.753-22.29-74.187a30.976 30.976 0 0 0-15.82-17.674 30.999 30.999 0 0 0-23.69-1.305 31 31 0 0 0-17.68 15.829 30.967 30.967 0 0 0-1.3 23.69c7.59 21.616 9.09 34.916 28.59 71.831a250.972 250.972 0 0 0 13.31 22.623 25.058 25.058 0 0 0 3.54 4.15c8.86 12.05 31.37 46.294 56.95 76.672l31.52-22.562Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1614.95 625.994c-.73-5.998-1.71-11.909-2.79-17.786-7.57-40.506-12.78-51.515-22.29-74.187-4.5-10.751-9.63-13.99-9.63-13.99"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1573.28 649.013a250.988 250.988 0 0 1-13.31-22.624c-19.5-36.915-21.01-50.215-28.59-71.83M1615.07 629.434c10.26 15.002 31.4 44.913 50.22 77.839l-31.52 22.562c-25.9-30.759-50.93-67.5-59.56-79.134"
            />
            <path
                fill="url(#P)"
                d="m1615.4 664.473-170.79 18.58v244.962h177.28V734.15l-6.49-69.677Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1621.89 755.43v172.585h-96.4"
            />
            <path
                fill="#FCB47F"
                d="M1534.34 1132.83c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.31 3.16-29.97 7.11-44.517 2.06-7.314 4.52-14.51 7.36-21.557.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269 1.74-3.575 3.7-7.04 5.86-10.376l6.93-10.585a26.096 26.096 0 0 1 45.24 2.748 26.15 26.15 0 0 1 1.92 17.824l-10.88 43.824-5.44 21.91-5.59 21.89c-3.75 14.58-7.4 29.19-11.23 43.76l-11.4 43.73c-.95 3.65-3.18 6.85-6.27 9.02-3.1 2.17-6.86 3.16-10.63 2.81-3.76-.36-7.27-2.04-9.91-4.75a15.918 15.918 0 0 1-4.47-10.04l-.19-2.85Z"
            />
            <path
                fill="#FCB47F"
                d="M1554.63 962.643c-5.12-15.079-9.8-30.224-14.66-45.342l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.685c-4.71-15.14-8.41-30.431-12.02-45.735a764.466 764.466 0 0 1-5.02-23.014l-1.16-5.768-1.05-5.785c-.69-3.857-1.42-7.71-1.98-11.586a50.896 50.896 0 0 1 40.36-57.206 50.88 50.88 0 0 1 37.06 6.798 50.926 50.926 0 0 1 22.23 30.43l1.24 4.94c.96 3.817 1.82 7.649 2.58 11.496l1.13 5.773 1 5.792c.7 3.856 1.22 7.738 1.81 11.611.62 3.869 1.05 7.765 1.55 11.651 1.85 15.566 3.4 31.176 3.96 46.935.29 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.57 11.967a393.897 393.897 0 0 1-5.35 48.322l-.79 4.347a28.766 28.766 0 0 1-9.1 16.236 28.784 28.784 0 0 1-17.13 7.28 28.812 28.812 0 0 1-18-4.716 28.802 28.802 0 0 1-11.35-14.743Z"
            />
            <path
                fill="#FCB47F"
                d="M1554.63 962.643c-5.12-15.079-9.8-30.224-14.66-45.342l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.685c-4.71-15.14-8.41-30.431-12.02-45.735a764.466 764.466 0 0 1-5.02-23.014l-1.16-5.768-1.05-5.785c-.69-3.857-1.42-7.71-1.98-11.586a50.896 50.896 0 0 1 40.36-57.206 50.88 50.88 0 0 1 37.06 6.798 50.926 50.926 0 0 1 22.23 30.43l1.24 4.94c.96 3.817 1.82 7.649 2.58 11.496l1.13 5.773 1 5.792c.7 3.856 1.22 7.738 1.81 11.611.62 3.869 1.05 7.765 1.55 11.651 1.85 15.566 3.4 31.176 3.96 46.935.29 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.57 11.967a393.897 393.897 0 0 1-5.35 48.322l-.79 4.347a28.766 28.766 0 0 1-9.1 16.236 28.784 28.784 0 0 1-17.13 7.28 28.812 28.812 0 0 1-18-4.716 28.802 28.802 0 0 1-11.35-14.743Z"
                opacity={0.73}
            />
            <path
                fill="#FCB47F"
                d="m1534.53 1135.68-.19-2.85c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.31 3.16-29.97 7.11-44.517a209.88 209.88 0 0 1 7.36-21.558c.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1534.53 1135.68-.19-2.85c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.31 3.16-29.97 7.11-44.517a209.88 209.88 0 0 1 7.36-21.558c.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269"
            />
            <path
                fill="#FCB47F"
                d="m1610.35 963.523-10.87 43.827-5.45 21.91-5.59 21.89c-3.75 14.58-7.4 29.19-11.22 43.76l-11.41 43.72"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1610.35 963.523-10.87 43.827-5.45 21.91-5.59 21.89c-3.75 14.58-7.4 29.19-11.22 43.76l-11.41 43.72"
            />
            <path
                fill="#FCB47F"
                d="M1599.15 747.832c2.36 5.6 4.41 11.328 6.12 17.157 1.12 3.775 1.83 7.649 2.59 11.496l1.13 5.773.99 5.792c.71 3.856 1.23 7.738 1.82 11.611.61 3.869 1.05 7.765 1.54 11.651 1.86 15.566 3.41 31.176 3.97 46.935.28 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.58 11.967-.94 15.983-2.99 41.575-5.99 57.608"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1599.15 747.832c2.36 5.6 4.41 11.328 6.12 17.157 1.12 3.775 1.83 7.649 2.59 11.496l1.13 5.773.99 5.792c.71 3.856 1.23 7.738 1.82 11.611.61 3.869 1.05 7.765 1.54 11.651 1.86 15.566 3.41 31.176 3.97 46.935.28 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.58 11.967-.94 15.983-2.99 41.575-5.99 57.608"
            />
            <path
                fill="#FCB47F"
                d="M1554.63 962.643c-5.12-15.079-9.8-30.223-14.66-45.341l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.686c-4.71-15.14-8.41-30.431-12.02-45.735a796 796 0 0 1-5.03-23.013l-1.15-5.768-1.05-5.785c-.7-3.857-1.42-7.71-1.98-11.587"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1554.63 962.643c-5.12-15.079-9.8-30.223-14.66-45.341l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.686c-4.71-15.14-8.41-30.431-12.02-45.735a796 796 0 0 1-5.03-23.013l-1.15-5.768-1.05-5.785c-.7-3.857-1.42-7.71-1.98-11.587M1590.51 958.441H1606"
            />
            <path
                fill="#FCB47F"
                d="M1533.66 1130.2c-.39 4.32-4.63 20.35-7.47 30.74-.32 1.19-.4 2.42-.23 3.64.18 1.21.6 2.38 1.23 3.42a9.03 9.03 0 0 0 2.49 2.66c1 .71 2.14 1.2 3.34 1.45l19.89 4.2c4.25.88 8.33 2.41 12.11 4.53 6.09 3.37 12.65 5.8 19.45 7.23 4.01.87 19.04 1.81 26.25 2.23 1.06.06 2.1-.29 2.91-.97.81-.68 1.33-1.64 1.45-2.69a4.1 4.1 0 0 0-.38-2.29c-.34-.71-.88-1.31-1.55-1.73-2.85-1.77-7.9-4.87-12.02-7.19-18.46-10.39-35.66-38.52-35.66-38.52"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1533.66 1130.2c-.39 4.32-4.63 20.35-7.47 30.74-.32 1.19-.4 2.42-.23 3.64.18 1.21.6 2.38 1.23 3.42a9.03 9.03 0 0 0 2.49 2.66c1 .71 2.14 1.2 3.34 1.45l19.89 4.2c4.25.88 8.33 2.41 12.11 4.53 6.09 3.37 12.65 5.8 19.45 7.23 4.01.87 19.04 1.81 26.25 2.23 1.06.06 2.1-.29 2.91-.97.81-.68 1.33-1.64 1.45-2.69a4.1 4.1 0 0 0-.38-2.29c-.34-.71-.88-1.31-1.55-1.73-2.85-1.77-7.9-4.87-12.02-7.19-18.46-10.39-35.66-38.52-35.66-38.52"
            />
            <path
                fill="#FCB47F"
                d="M1447.09 718.406c-12.58 21.71-20.88 32.255-16.56 68.991 3.32 28.203 88.98 13.576 105.25 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                fill="#FCB47F"
                d="M1447.09 718.406c-12.58 21.71-20.88 32.255-16.56 68.991 3.32 28.203 88.98 13.576 105.25 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1447.09 718.406c-12.58 21.71-20.88 32.255-16.56 68.991 3.32 28.203 88.98 13.576 105.25 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                fill="#FCB47F"
                d="M1473.39 1149.37c.51 4.31-.33 20.87-.96 31.62a8.998 8.998 0 0 0 2.44 6.7c.84.89 1.85 1.6 2.98 2.09 1.13.48 2.34.73 3.56.73h57.65c1.18 0 2.31-.46 3.14-1.3a4.41 4.41 0 0 0 1.31-3.14c0-.8-.22-1.59-.63-2.28a4.488 4.488 0 0 0-1.71-1.64c-3.8-2.03-11.23-6.09-14.96-8.59-8.49-5.72-20.31-24.19-20.31-24.19"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1473.39 1149.37c.51 4.31-.33 20.87-.96 31.62a8.998 8.998 0 0 0 2.44 6.7c.84.89 1.85 1.6 2.98 2.09 1.13.48 2.34.73 3.56.73h57.65c1.18 0 2.31-.46 3.14-1.3a4.41 4.41 0 0 0 1.31-3.14c0-.8-.22-1.59-.63-2.28a4.488 4.488 0 0 0-1.71-1.64c-3.8-2.03-11.23-6.09-14.96-8.59-8.49-5.72-20.31-24.19-20.31-24.19"
            />
            <path
                fill="#FCB47F"
                d="M1473.47 1149.37a703.43 703.43 0 0 1-5.27-23.87c-.81-3.97-1.71-7.95-2.44-11.93l-2.27-11.93c-1.55-7.96-2.95-15.91-4.74-23.87-1.71-7.95-3.79-15.91-5.14-23.87-2.98-15.91-2.33-31.82-1.09-47.73.69-8.006 1.8-15.972 3.32-23.865.36-1.988.84-3.977 1.26-5.966.49-1.989.93-3.978 1.49-5.967 1.06-4.049 2.36-8.034 3.88-11.933l4.41-11.154a26.257 26.257 0 0 1 12.09-13.514 26.228 26.228 0 0 1 33.22 7.326 26.188 26.188 0 0 1 5.28 17.342l-2.74 47.731-1.37 23.87-1.52 23.86c-1.04 15.92-1.98 31.83-3.1 47.74l-3.27 47.73c-.26 3.8-1.87 7.37-4.54 10.08a15.964 15.964 0 0 1-10.02 4.7c-3.79.32-7.57-.73-10.65-2.96a15.916 15.916 0 0 1-6.16-9.19l-.63-2.63Z"
            />
            <path
                fill="#FCB47F"
                d="M1461 959.747c-3.55-15.367-6.66-30.755-9.94-46.134l-4.85-23.073-2.44-11.535-1.22-5.768-1.12-5.772c-3.14-15.387-5.25-30.82-7.28-46.258-1-7.719-1.88-15.443-2.64-23.173l-.56-5.798-.45-5.804c-.3-3.869-.62-7.736-.79-11.612a50.889 50.889 0 0 1 45.95-52.791 50.88 50.88 0 0 1 55.2 43.019l.75 5.09c.56 3.856 1.03 7.717 1.39 11.583l.53 5.8.4 5.806c.31 3.868.43 7.745.62 11.619.21 3.873.25 7.754.34 11.632.26 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.44 23.408l-1.6 11.721c-.55 3.908-1.2 7.82-1.79 11.73a387.834 387.834 0 0 1-10.26 47.062l-1.26 4.279a28.807 28.807 0 0 1-10.72 15.174 28.797 28.797 0 0 1-44.96-16.836Z"
            />
            <path
                fill="#FCB47F"
                d="M1461 959.747c-3.55-15.367-6.66-30.755-9.94-46.134l-4.85-23.073-2.44-11.535-1.22-5.768-1.12-5.772c-3.14-15.387-5.25-30.82-7.28-46.258-1-7.719-1.88-15.443-2.64-23.173l-.56-5.798-.45-5.804c-.3-3.869-.62-7.736-.79-11.612a50.889 50.889 0 0 1 45.95-52.791 50.88 50.88 0 0 1 55.2 43.019l.75 5.09c.56 3.856 1.03 7.717 1.39 11.583l.53 5.8.4 5.806c.31 3.868.43 7.745.62 11.619.21 3.873.25 7.754.34 11.632.26 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.44 23.408l-1.6 11.721c-.55 3.908-1.2 7.82-1.79 11.73a387.834 387.834 0 0 1-10.26 47.062l-1.26 4.279a28.807 28.807 0 0 1-10.72 15.174 28.797 28.797 0 0 1-44.96-16.836Z"
                opacity={0.73}
            />
            <path
                fill="#FCB47F"
                d="m1474.1 1152.01-.63-2.64c-1.9-7.95-3.66-15.91-5.27-23.86-.81-3.98-1.71-7.96-2.44-11.94l-2.27-11.93c-1.55-7.96-2.95-15.91-4.74-23.87-1.71-7.95-3.79-15.91-5.14-23.86-2.98-15.91-2.33-31.83-1.09-47.74a231.24 231.24 0 0 1 3.32-23.862c.36-1.989.84-3.978 1.26-5.967.49-1.989.93-3.978 1.49-5.966.51-1.91 1.06-3.82 1.67-5.73"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1474.1 1152.01-.63-2.64c-1.9-7.95-3.66-15.91-5.27-23.86-.81-3.98-1.71-7.96-2.44-11.94l-2.27-11.93c-1.55-7.96-2.95-15.91-4.74-23.87-1.71-7.95-3.79-15.91-5.14-23.86-2.98-15.91-2.33-31.83-1.09-47.74a231.24 231.24 0 0 1 3.32-23.862c.36-1.989.84-3.978 1.26-5.967.49-1.989.93-3.978 1.49-5.966.51-1.91 1.06-3.82 1.67-5.73"
            />
            <path
                fill="#FCB47F"
                d="m1517.47 958.441-2.74 47.729-1.38 23.87-1.52 23.87c-1.03 15.91-1.97 31.82-3.09 47.73l-3.27 47.73"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1517.47 958.441-2.74 47.729-1.38 23.87-1.52 23.87c-1.03 15.91-1.97 31.82-3.09 47.73l-3.27 47.73"
            />
            <path
                fill="#FCB47F"
                d="m1530.86 765.047.74 5.09a227.23 227.23 0 0 1 1.4 11.584l.53 5.8.4 5.805c.3 3.869.43 7.746.61 11.62.22 3.872.26 7.753.35 11.631.25 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.45 23.408l-1.59 11.721c-.55 3.909-1.2 7.82-1.79 11.73a387.891 387.891 0 0 1-10.26 47.063"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1530.86 765.047.74 5.09a227.23 227.23 0 0 1 1.4 11.584l.53 5.8.4 5.805c.3 3.869.43 7.746.61 11.62.22 3.872.26 7.753.35 11.631.25 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.45 23.408l-1.59 11.721c-.55 3.909-1.2 7.82-1.79 11.73a387.891 387.891 0 0 1-10.26 47.063"
            />
            <path
                fill="#FCB47F"
                d="M1461 959.749c-3.55-15.367-6.66-30.755-9.94-46.134l-4.86-23.073-2.43-11.535-1.22-5.768-1.13-5.772c-3.13-15.387-5.24-30.82-7.27-46.258-1-7.719-5.78-41.373-5.94-45.248"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1461 959.749c-3.55-15.367-6.66-30.755-9.94-46.134l-4.86-23.073-2.43-11.535-1.22-5.768-1.13-5.772c-3.13-15.387-5.24-30.82-7.27-46.258-1-7.719-5.78-41.373-5.94-45.248M1497.09 958.441h15.49"
            />
            <path
                fill="#FCB47F"
                d="M1447.18 719.632c-.57-9.712-8.97-42.267-16.07-68.398a312.854 312.854 0 0 1-10.86-74.513l-.53-22.205c-.38-15.638 5.9-30.06 18.09-39.861 16.28-13.086 31.84-20.476 49.94-28.194 32.2 15.594 42.15 16.424 56.02 13.777a124.64 124.64 0 0 1 6.41 4.394c26 19 45.97 59.672 52.16 82.234 3.21 11.669 2.84 23.766-8.16 29.766 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M1447.18 719.632c-.57-9.712-8.97-42.267-16.07-68.398a312.854 312.854 0 0 1-10.86-74.513l-.53-22.205c-.38-15.638 5.9-30.06 18.09-39.861 16.28-13.086 31.84-20.476 49.94-28.194 32.2 15.594 42.15 16.424 56.02 13.777a124.64 124.64 0 0 1 6.41 4.394c26 19 45.97 59.672 52.16 82.234 3.21 11.669 2.84 23.766-8.16 29.766 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M1583.23 543.637c9.26 15.605 15.97 31.758 19.11 43.229 3.21 11.668 2.85 23.765-8.15 29.765 14 28 25 49 14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1583.23 543.637c9.26 15.605 15.97 31.758 19.11 43.229 3.21 11.668 2.85 23.765-8.15 29.765 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M1447.19 719.628c-.57-9.711-8.98-42.267-16.08-68.397a312.842 312.842 0 0 1-10.85-74.513l-.48-19.37c-.78-24.024 9.67-36.865 21.71-45.56a190.903 190.903 0 0 1 41.28-23.265"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1447.19 719.628c-.57-9.711-8.98-42.267-16.08-68.397a312.842 312.842 0 0 1-10.85-74.513l-.48-19.37c-.78-24.024 9.67-36.865 21.71-45.56a190.903 190.903 0 0 1 41.28-23.265"
            />
            <path
                fill="#FCB47F"
                d="M1594.35 616.827c-3.57-6.179-7.72-12.857-11.12-16.862l11.12 16.862Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1594.35 616.827c-3.57-6.179-7.72-12.857-11.12-16.862"
            />
            <path
                fill="#FCB47F"
                d="M1485.11 618.054c31.07 3.775 56.53-1.156 71.67-22.12l-71.67 22.12Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1485.11 618.054c31.07 3.775 56.53-1.156 71.67-22.12"
            />
            <path
                fill="#FCB47F"
                d="M1585.23 695.609a13.787 13.787 0 0 1 10.29 0h-10.29Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1585.23 695.609a13.787 13.787 0 0 1 10.29 0"
            />
            <path
                fill="url(#Q)"
                d="m1527.96 1135.87-.04-.86c0-.05 0-.05.13-5.16 1.39-52.98 2.1-83.02 2.13-89.28-.23-17.53 3.25-45.324 11.54-67.849 3.76-10.325 4-21.599.68-32.074-14.06-44.607-31.69-110.19-39-160.478a51.946 51.946 0 0 1 9.79-38.477 51.94 51.94 0 0 1 34.1-20.326c2.49-.362 5.01-.544 7.52-.546 23.69 0 45.33 16.03 51.19 38.98 23.79 97.617 7.05 201.005 6.32 203.964-24.72 99.566-33.49 129.076-39.16 150.626-2.15 8.15-4 15.19-6.38 24.49l-.21.84-38.61-3.85Z"
            />
            <path
                fill="url(#R)"
                d="M1447.09 718.406c-9.19 15.857-16.09 25.768-17.46 44.268a52.448 52.448 0 0 0-.92 12.188c1.62 38.358 8.68 89.007 19.37 138.96.9 4.214 2.48 13.846 4.01 23.16.76 4.673 1.53 9.335 2.21 13.346a50.782 50.782 0 0 1-.86 20.79c-5.95 24.084-7.16 59.752-2.82 82.952.89 5.23 3.69 24.48 6.65 44.87 3.57 24.61 7.26 50.07 7.97 53.28l.18.84 40.99-2.75.06-.87c.86-12.81 1.54-22.89 2.15-31.98 2.34-34.49 6.75-55.31 13.31-160.155 8.26-28.072 13.52-101.555 15.55-134.876a46.993 46.993 0 0 1 12.68-29.326l24.76-29.503s24.55-11.723 33.27-45.194h-161.1Z"
            />
            <path
                fill="url(#S)"
                d="m1422.97 784.035-.11-.876c-2.92-23.317 1.06-43.872 4.91-63.749 4.39-22.693 8.54-44.129 2.33-67.925a328.885 328.885 0 0 1-10.89-74.739l-.54-22.589c-.37-15.628 6.46-30.813 18.53-40.319 25.43-20.041 49.75-28.119 49.75-28.119s1.92.108 9.78 9.759c1.9 2.331 7.57 9.271 9.48 10.981 8.47 7.555 16.21 11.228 23.67 11.227 1.34 0 2.68-.12 4-.359a9.27 9.27 0 0 0 5.23-2.763c4.71-5.098 3.38-16.737 3.38-16.737a64.052 64.052 0 0 1 8.25 5.997c25.56 21.883 46.64 61.31 52.53 82.777 2.14 7.783 2.36 14.435.67 19.773-3.3 10.393-3.02 21.138.8 30.258a107.95 107.95 0 0 1 8.85 40.646c.29 11.564 0 104.819 0 105.76v.997h-190.62Z"
            />
            <path
                fill="url(#T)"
                d="M1542.63 499.603s27.55 38.811 45.48 69.682c13.76 23.683 2.02 54.374 9.09 63.333 25.5 32.346 24.68 117.914 24.68 117.914v-43.766c0-42.296-10.6-60.945-15.37-73.635a44.893 44.893 0 0 1-2.38-22.436l.84-5.654a57.562 57.562 0 0 0-6.09-35.511l-17.09-32.18a96.38 96.38 0 0 0-30.15-33.966l-9.11-6.7.1 2.919Z"
            />
            <path
                fill="#fff"
                d="M1545.12 500.207c27.89 17.212 28.09 18.737 26.9 24.745a53.957 53.957 0 0 1 8.46 4.075l11.05 47.572c-8.71-17.246-26.43-45.449-46.41-76.392Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1543.77 500.234s26.41 38.178 44.34 69.049c13.76 23.684 2.03 54.375 9.09 63.333 25.5 32.346 24.69 117.914 24.69 117.914v-43.765c0-42.296-10.61-60.945-15.38-73.636a44.888 44.888 0 0 1-2.38-22.435l.85-5.654a57.636 57.636 0 0 0-6.1-35.511l-6.14-11.576"
            />
            <path
                fill="url(#U)"
                d="m1456.92 500.208 30.03-16.067 33.7 81.651a96.286 96.286 0 0 1 7.28 36.703v331.838h-110.82l-.84-375.173a59.487 59.487 0 0 1 6.95-28.076 59.623 59.623 0 0 1 19.35-21.5l14.35-9.376Z"
            />
            <path
                fill="#fff"
                d="M1486.95 484.141c-9.79 31.686-2.35 34.447 3.95 37.101-7.3 9.29-6.77 10.843-6.77 10.843l41.05 47.595-38.23-95.539Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1456.92 500.208 30.03-16.067 33.7 81.651a96.286 96.286 0 0 1 7.28 36.703v331.838h-110.82l-.84-375.173a59.487 59.487 0 0 1 6.95-28.076 59.623 59.623 0 0 1 19.35-21.5l14.35-9.376ZM1432.42 760.027h59.67"
            />
            <path
                fill="#FCB47F"
                d="M1484.69 535.102c2.07 7.244 4.57 14.366 6.88 21.541a156.526 156.526 0 0 1 5.41 21.97c1.35 7.453 2.5 14.967 3.34 22.568.91 7.681 1.36 15.408 1.36 23.142a23.642 23.642 0 0 1-1.87 8.972 23.553 23.553 0 0 1-5.16 7.573 23.483 23.483 0 0 1-16.68 6.725 23.771 23.771 0 0 1-11.1-2.941 23.705 23.705 0 0 1-8.41-7.809 198.567 198.567 0 0 1-11.23-20.286c-3.34-6.876-6.39-13.837-9.23-20.86a155.886 155.886 0 0 1-7.17-21.459c-1.88-7.301-3.58-14.655-5.7-21.887a31.04 31.04 0 0 1-1.06-12.049 31.089 31.089 0 0 1 3.62-11.539 31.129 31.129 0 0 1 7.77-9.274 30.946 30.946 0 0 1 10.72-5.597 31.012 31.012 0 0 1 23.59 2.558 30.994 30.994 0 0 1 9.27 7.765 30.981 30.981 0 0 1 5.6 10.724l.05.163Z"
            />
            <path
                fill="#FCB47F"
                d="m1480.99 606.914 13.45-.066 13.44-.13c8.97-.062 17.94.141 26.91.496 4.49.211 8.98.362 13.47.637 4.48.24 8.97.491 13.47.843 4.49.335 8.98.621 13.47.997 4.49.443 8.99.785 13.48 1.33 2.05.248 4.02.896 5.81 1.906a15.722 15.722 0 0 1 4.64 3.985 15.615 15.615 0 0 1 2.76 5.456c.55 1.982.71 4.054.46 6.097a15.699 15.699 0 0 1-4.18 8.887 15.754 15.754 0 0 1-8.65 4.658c-4.47.786-8.93 1.37-13.4 2.053-4.46.617-8.93 1.143-13.4 1.719-4.46.593-8.93 1.085-13.4 1.566-4.47.516-8.94.908-13.41 1.36-8.95.837-17.89 1.521-26.85 1.941l-13.43.593-13.43.656c-2.95.144-5.9-.294-8.68-1.291a22.335 22.335 0 0 1-7.53-4.516 22.448 22.448 0 0 1-5.23-7.054 22.493 22.493 0 0 1-2.14-8.519c-.14-2.952.3-5.903 1.3-8.686a22.484 22.484 0 0 1 4.51-7.531 22.496 22.496 0 0 1 7.06-5.229 22.476 22.476 0 0 1 9.5-2.158Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1484.64 534.938.04.164c2.08 7.243 4.58 14.366 6.89 21.54a156.56 156.56 0 0 1 5.41 21.97c1.35 7.453 2.49 14.968 3.34 22.568l.15 1.462M1458.45 636.843a198.272 198.272 0 0 1-11.22-20.286 329.67 329.67 0 0 1-9.23-20.86 155.916 155.916 0 0 1-7.18-21.458c-1.87-7.301-3.57-14.655-5.69-21.887M1589.52 642.012c-4.46.787-8.93 1.37-13.39 2.053-4.47.617-8.94 1.144-13.41 1.72-4.46.592-8.93 1.085-13.4 1.566-4.47.515-8.94.907-13.41 1.36-8.94.836-17.89 1.521-26.84 1.94l-13.44.593-13.43.657c-10.08.493-18.33-6.013-23.75-15.057M1494.44 606.848l13.45-.13c8.96-.062 17.93.141 26.9.496 4.49.211 8.98.362 13.47.637 4.49.24 8.98.491 13.47.843 4.49.335 8.98.621 13.47.997 4.5.443 8.99.785 13.49 1.33"
            />
            <path
                fill="#FCB47F"
                d="M1588.19 641.848c9.72.894 19.53.057 28.96-2.472 3.21-.913 6.42-1.858 9.58-2.922l1.47-.3c12.53-2.041 23.97-9.075 33.75-16.94.55-.391.96-.948 1.17-1.588.2-.641.2-1.331-.01-1.969.31-.238.55-.571.67-.951.11-.38.11-.787-.02-1.163a2.961 2.961 0 0 0-1.43-1.688c-.94-.562-2.06-.135-3.15.007l-.14-1.006a2.388 2.388 0 0 0-.88-1.125c-.4-.277-.88-.424-1.37-.424-3.55-.002-7.06.749-10.3 2.204-3.7 1.686-7.27 3.724-11.15 4.945a28.603 28.603 0 0 1-21.11-1.665l-2.38-1.268c2.23-3.019 5.55-5.008 8.71-7.039a26.986 26.986 0 0 0 6.88-5.721c.38-.484.56-1.091.51-1.702a2.447 2.447 0 0 0-.78-1.596 2.48 2.48 0 0 0-1.44-.624 2.432 2.432 0 0 0-1.52.372c-3.85 2.358-8.72 3.499-12.85 5.175-4.72 1.908-9.51 3.687-13.88 6.291-4.37 2.603-8.34 6.127-10.57 10.695"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1611.85 613.523c2.23-3.019 5.55-5.008 8.71-7.039a26.986 26.986 0 0 0 6.88-5.721 2.43 2.43 0 0 0 .51-1.702 2.447 2.447 0 0 0-.78-1.596 2.48 2.48 0 0 0-1.44-.624 2.432 2.432 0 0 0-1.52.372c-3.85 2.358-8.72 3.499-12.85 5.175-4.72 1.908-9.51 3.687-13.88 6.291-4.37 2.603-8.34 6.127-10.57 10.695M1627 628.727c6.14-.086 11.76-3.306 16.85-6.734 4.41-2.97 8.79-6.225 13.74-8.048.3-.106.57-.27.81-.483a2.437 2.437 0 0 0 .79-1.676 2.385 2.385 0 0 0-1.03-2.057c-.4-.277-.88-.425-1.37-.424a25.09 25.09 0 0 0-10.3 2.203c-3.7 1.687-7.27 3.725-11.15 4.946a28.598 28.598 0 0 1-21.11-1.666"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1662.75 614.527-9.49 7.238c-3.78 3.01-7.74 5.779-11.87 8.29-7.51 4.353-15.89 6.945-24.24 9.322a82.56 82.56 0 0 1-28.95 2.472"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1661.95 619.211c-9.78 7.865-21.22 14.899-33.75 16.94"
            />
            <path
                fill="url(#V)"
                d="M1481.09 652.923a23.603 23.603 0 0 1-11.66-3.116 23.623 23.623 0 0 1-8.58-8.469 25.008 25.008 0 0 1-3.24-3.951 202.782 202.782 0 0 1-11.28-20.394c-3.21-6.591-6.24-13.435-9.26-20.925a159.167 159.167 0 0 1-7.22-21.588c-.56-2.196-1.11-4.401-1.66-6.605-1.25-5.02-2.55-10.21-4.02-15.246a32.064 32.064 0 0 1 2.65-24.342 32.073 32.073 0 0 1 19.08-15.358 31.994 31.994 0 0 1 9-1.296c6.91.03 13.62 2.285 19.15 6.431a32.134 32.134 0 0 1 11.54 16.589l.05.168c1.45 5.043 3.13 10.118 4.75 15.026a767.18 767.18 0 0 1 2.13 6.486 158.49 158.49 0 0 1 5.44 22.106c1.45 7.944 2.54 15.348 3.35 22.628.17 1.568.33 3.137.48 4.707l6.08-.058c1.08-.008 2.16-.012 3.23-.011 7.34 0 15.1.166 23.73.508 1.47.069 2.93.131 4.4.193 2.97.127 6.05.259 9.09.445 4.32.231 8.93.487 13.48.844 1.58.118 3.16.231 4.74.341 2.91.206 5.83.412 8.74.657 1.5.147 2.98.282 4.46.417 2.97.27 6.04.549 9.06.916-4.33 9.616-2.52 22.411.89 32.966-3 .529-6.04.973-8.98 1.4-1.48.216-2.96.431-4.43.656-2.91.402-5.8.763-8.68 1.125-1.59.197-3.17.397-4.75.599-4.56.605-9.13 1.106-13.43 1.568-3.01.347-6.08.644-9.04.931-1.46.141-2.92.284-4.37.43-9.85.921-18.64 1.557-26.9 1.945l-13.43.593-13.43.657c-.39.018-.78.028-1.16.028v-.001Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1490.4 549.852c.71 2.161 1.43 4.321 2.12 6.486a158.456 158.456 0 0 1 5.44 22.106c1.45 7.944 2.54 15.348 3.35 22.628.17 1.568.33 3.136.48 4.707l6.08-.059c1.08-.007 2.16-.011 3.23-.01 7.34 0 15.1.166 23.73.508 1.47.069 2.94.131 4.4.193 2.98.127 6.05.259 9.09.444 4.32.232 8.93.488 13.49.845 1.57.117 3.15.231 4.73.341 2.91.206 5.83.412 8.75.657 1.49.147 2.97.281 4.45.417 2.97.27 6.04.549 9.07.916-4.34 9.616-2.53 22.411.88 32.966-3 .529-6.04.973-8.98 1.4-1.48.216-2.96.431-4.43.656-2.91.402-5.79.763-8.68 1.124-1.58.197-3.17.398-4.75.6-4.56.605-9.13 1.106-13.42 1.568a505.6 505.6 0 0 1-9.05.931c-1.46.141-2.91.284-4.37.43-9.85.921-18.64 1.557-26.9 1.945l-13.43.592-13.43.658c-.39.018-.77.028-1.16.028h0a23.577 23.577 0 0 1-11.65-3.116 23.576 23.576 0 0 1-8.59-8.47 24.938 24.938 0 0 1-3.23-3.95 202.865 202.865 0 0 1-11.29-20.394c-3.2-6.591-6.24-13.435-9.26-20.925a159.214 159.214 0 0 1-7.22-21.588c-.56-2.196-1.11-4.402-1.66-6.606-1.25-5.02-2.54-10.21-4.02-15.246"
            />
            <path
                fill="#EF0025"
                d="M1610.96 1191.3c-.1 0-.2 0-.3-.01-8.15-.46-22.41-1.38-26.4-2.24a70.901 70.901 0 0 1-19.73-7.34c-3.7-2.08-7.68-3.57-11.83-4.42l-19.89-4.2c-1.34-.27-2.6-.82-3.72-1.61a9.957 9.957 0 0 1-2.75-2.96 9.84 9.84 0 0 1-1.38-3.8c-.19-1.35-.1-2.73.26-4.04 1.19-4.36 2.34-8.64 3.31-12.37.06-.25.21-.46.42-.59.22-.14.47-.19.72-.15 3.94.84 7.8 2.04 11.53 3.59.65.24 1.3.49 1.95.73 5.89 2.2 12.16 4.02 18.04 2.44 2.26-.7 4.44-1.64 6.51-2.81 1.05-.54 2.13-1.1 3.24-1.6 1.02-.47 2.07-.86 3.15-1.18.19-.06.4-.06.59.01.2.06.37.18.49.34 6.45 8.46 16.28 19.75 26.45 25.47 4.18 2.36 9.35 5.53 12.06 7.21.83.52 1.49 1.26 1.92 2.14.42.89.59 1.87.47 2.84a5.192 5.192 0 0 1-1.69 3.25c-.94.84-2.16 1.3-3.42 1.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1614.5 1189.89a5.13 5.13 0 0 1-3.54 1.41h-.3c-8.15-.47-22.41-1.38-26.4-2.25a70.901 70.901 0 0 1-19.73-7.34 41.018 41.018 0 0 0-11.83-4.42l-19.89-4.2c-1.34-.27-2.6-.82-3.72-1.61a9.839 9.839 0 0 1-2.75-2.96"
            />
            <path
                fill="url(#W)"
                d="M1539.06 1191.51h-57.64c-1.37.01-2.72-.27-3.97-.81a9.852 9.852 0 0 1-3.31-2.33 9.706 9.706 0 0 1-2.13-3.43 9.975 9.975 0 0 1-.58-4.01c.2-3.29.41-7.12.59-10.98.01-.14.05-.29.12-.42a.987.987 0 0 1 1.11-.5c2.16.52 4.42.56 6.59.11a15.17 15.17 0 0 0 6.01-2.71c1.34-1.11 2.58-2.33 3.72-3.65.98-1.12 2.03-2.19 3.13-3.19a21.02 21.02 0 0 1 8.49-4.32c3.12-.78 6.38-.83 9.53-.15.24.05.46.19.6.4 4.32 6 10.5 13.87 15.45 17.2 3.66 2.47 10.89 6.42 14.87 8.55.87.46 1.59 1.16 2.1 2 .5.85.77 1.81.77 2.8 0 1.44-.58 2.82-1.6 3.85a5.433 5.433 0 0 1-3.85 1.59Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1543.27 1189.52c-.51.62-1.15 1.13-1.88 1.47-.73.35-1.52.52-2.33.52h-57.65c-1.36.01-2.71-.27-3.96-.81a9.967 9.967 0 0 1-3.31-2.32"
            />
            <path
                fill="#FCB47F"
                d="M1944.45 781.802c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                fill="url(#X)"
                d="M1944.45 781.802c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1797.95 625.902c-3.9 8.6-6.8 16.6-8.6 23.1-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1797.25 679.002c3.6-6.2 7.7-12.9 11.1-16.9M1906.55 680.202c-31.1 3.8-56.5-1.2-71.7-22.1M1806.35 757.7c-3.6-1.3-7-1.4-10.3 0"
            />
            <path
                fill="#FFDBAE"
                d="M1737.27 1030.65c.9-8.5 1.7-17 2.4-25.4.3-4.2.7-8.498 1-12.698l.8-12.7c.6-8.4 1-16.9 1.8-25.3.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.3-4.6-49.7-1.7-8.2-3.7-16.3-6.2-24.4-.6-2-1.3-4-2-6.1-.7-2-1.4-4-2.2-6-1.5-4-3.2-8-5.3-12l-5.3-10.2c-6.7-12.9-22.6-18-35.5-11.3-10.4 5.4-15.7 16.8-13.9 27.9l8.5 49.3 4.2 24.6 4.4 24.6c2.9 16.4 5.8 32.8 8.8 49.2l9 49.198c1.6 8.7 9.9 14.4 18.5 12.8 7.1-1.3 12.2-7.1 13-13.9l.4-2.5Z"
            />
            <path
                fill="#FFDBAE"
                d="M1733.87 870.451c-10.2-2-20.4-4.5-30.8-8.3l-3.3-1.2c-6.4-2.3-12.1-6.7-16.1-12.9-9.6-15-5.2-35 9.8-44.6 8.9-5.7 18-10.4 27.1-14.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.799 28.2-7.799 2.4-.5 4.8-1.001 7.2-1.301 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.299 7.3-.299 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 57.999-32.5 67.699l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-80.1l.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1736.97 1033.25.3-2.6c.9-8.5 1.7-17 2.4-25.4.3-4.2.7-8.499 1-12.699l.8-12.699c.6-8.4 1-16.901 1.8-25.301.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.3-4.6-49.7-1.7-8.2-3.7-16.3-6.2-24.4M1684.47 809.453c-10.4 5.4-15.7 16.801-13.9 27.901l8.5 49.299 4.2 24.6 4.4 24.601c2.9 16.4 5.8 32.799 8.8 49.199l9 49.197"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1684.47 809.451c8.9-5.7 26.9-16.3 36-20.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.799 28.2-7.799 2.4-.5 4.8-1.001 7.2-1.301 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.299 7.3-.299 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 57.999-32.5 67.699l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-71.1"
            />
            <path
                fill="#FFDBAE"
                d="M1737.97 1030.75c.1 4.3 3.4 20.6 5.5 31.1 1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1737.97 1030.75c.1 4.3 3.4 20.6 5.5 31.1 1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                fill="#E2E2E2"
                d="M1653.77 1076.55c2.8-1.5 8.2-4.2 12.6-6.3 7.5-3.5 15.1-9.8 21.5-16.1 8.8-8.7 15.4-17.5 16.5-19 0 0 22.8-2.7 35.2-4.2.3 6.5.7 12.8 1.3 16 .8 4.4 1.7 9.5 2.8 15.4.5 2.7-.1 5.5-1.7 7.7-1.6 2.3-4 3.7-6.8 4.1l-20.2 2.6c-4.3.6-8.4 1.7-12.1 3.5-4.1 1.9-11 4.5-20.2 5.8-.3 0-.7.1-1.1.1-5.1.4-18.1.2-25.4.1-2.7-.1-4.9-2.2-5-5-.2-1.9.8-3.8 2.6-4.7Z"
            />
            <path
                fill="#63666A"
                d="M1654.67 1087.05c.6.2 1.2.3 1.8.3 8.2.2 22.5.4 26.7-.2 9.4-1.3 16.3-3.9 20.5-5.8 3.6-1.7 7.6-2.8 11.8-3.4l19.8-2.6c3-.4 5.8-1.8 7.6-4.2 2-2.5 2.7-5.7 2.1-8.8-2.8-14.9-3.3-16.4-3.6-20.7-.1-.9-1.3-3.5-3.9-3.1 0 0-10.6 13.5-25.5 5.6-2.5-1.3-9.1-6.4-9.1-6.4s-1.9-1-4.9 3.5c-2.2 3.2-14.4 20.1-31.7 28.2-4.3 2-9.5 4.6-12.4 6.1-1.6.8-2.8 2.2-3.3 3.9-.8 3.3 1.1 6.6 4.1 7.6Z"
            />
            <path
                fill="#E2E2E2"
                d="M1705.07 1069.86c13.6-9.4 30.9-11.01 39.4-10.81.2 1.1.4 2.21.6 3.41.6 3.1-.2 6.3-2.1 8.8-1.8 2.4-4.6 3.8-7.6 4.2 0 0-42.8 10.5-52.2 11.8-4.1.6-18.5.3-26.7.2-.6 0-1.2-.1-1.8-.3-1-.3-1.8-.91-2.5-1.61 7.9-1.3 39.6-6.49 52.9-15.69Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1690.17 827.352h-15.5"
            />
            <path
                fill="#FFB91D"
                d="M1733.77 781.453c17.9-8.6 38.9-14.099 60.3-16.599 26.9-4.1 52.7 9.5 61.9 35.5 11.2 32.4-9.1 64.999-42.3 71.699h-41.7s-7.5-23.099-17.6-47.699c-12.7-31.2-20.6-42.901-20.6-42.901Z"
            />
            <path
                fill="#FCB47F"
                d="M1812.95 587.201c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.7-18.8 19.3-6.1 6.7-12 13.5-17.5 20.9-7.6 10.3-5.5 24.8 4.8 32.5 7.1 5.3 16.2 5.9 23.6 2.4 8.3-3.9 16.2-8.3 24-12.9 7.7-4.6 15.3-9.5 22.7-14.5 7.4-5 14.6-10.4 21.3-16.2 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5h-.1Z"
            />
            <path
                fill="url(#Y)"
                d="M1812.95 587.201c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.7-18.8 19.3-6.1 6.7-12 13.5-17.5 20.9-7.6 10.3-5.5 24.8 4.8 32.5 7.1 5.3 16.2 5.9 23.6 2.4 8.3-3.9 16.2-8.3 24-12.9 7.7-4.6 15.3-9.5 22.7-14.5 7.4-5 14.6-10.4 21.3-16.2 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5h-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1813.05 587.102-.1.1c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.699-18.8 19.299-4.6 5-9.1 10.1-13.4 15.5M1818.85 662.903c4.4-3.3 8.8-6.7 12.9-10.3 6.8-5.8 13.4-11.8 20.4-17.4"
            />
            <path
                fill="url(#Z)"
                d="m1850.65 566.402-2.7 1c-13.1 4.7-23.8 10.301-32.8 17.301-.8.6-5.9 5.4-5.8 6.4.2 4.6 16.9 8.2 19.3 9.6l.9.5 21.1-34.801Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1853.45 564.602c-12.6 4.5-23.1 10.4-32.6 17.2"
            />
            <path
                fill="#FCB47F"
                d="M1832.85 463.202c-4.4 10.2-7.6 24.2-7.6 35.3 0 11.1 1.9 22.2 4.7 32.9 1.6 6.1 2.7 12.9 6.2 18 2.5 3.7 7.8 5.2 12.3 4.8.7-.1 1.3-.1 2-.2-.4 1.3-.8 11.6-.8 12.4l8.6 6.2 46.7-18-2.6-11.6-.4-13s1.1-8.5 9.1-24c10.1-19.5-3.1-37.8-3.1-37.8s-2.8-11.1-18.5-18c-22.3-9.6-40.9-2.7-56.6 13Z"
            />
            <path
                fill="url(#aa)"
                d="M1826.05 487.504c2-.2 4-.4 6-.7 2-.2 4.1-.4 5.7.8 1.2.9 1.8 2.4 1.9 3.8.1 1.5.4 3 .1 4.4-2.2 12.9-1.5 21.9.9 24.5.5.6 1 1.3 1.1 2 .1.8-.1 1.5-.2 2.3-.9 4.2-1.8 8.5-2.7 12.7-.5 2.3-.8 4.7-1.1 7-.4 3.9.6 7.3 4.6 9.4-6.6-1.3-13.7-7.7-15.2-10.5-9.5-18.1.8-37.2-1.1-55.7Z"
            />
            <path
                fill="url(#ab)"
                d="M1849.75 564.802c.2-3.7.4-7.4.5-11.1 14.5 1.2 30.2-11.6 36.6-18.4.9-1-2.2 13.8-5.3 24.1-7.3 3.3-22.8 8.7-31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1886.85 534.502c-9.3 11.5-23.7 18.3-38.4 19.7-5.6.6-13.7-1.9-18.2-6.6-7.2-7.7-9.7-22.7-6.1-37 1.2-4.9 2.2-7.5 2.1-11.3 0-2.9-.8-6.7-.4-9.8 1.1-9 1.3-18.7 8.9-27.4 25.7-29.5 60.1-12.6 69-1.5 8.2 10.2 12.1 23.8 9.7 36.6-1.6 8.7-5.8 16.7-9.5 24.7-3.9 8.6-1.8 21.1.7 30"
            />
            <path
                fill="#FCB47F"
                d="M1839.25 492.703c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1839.25 492.703c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="url(#ac)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1824.75 489.202c2.5-1.2 6.2-1.8 8.8-2.1"
            />
            <path
                stroke="url(#ad)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1847.05 487.102c6.4 0 13.1.7 17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1830.15 504.401c-.77 0-1.4-1.03-1.4-2.3 0-1.27.63-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3ZM1855.75 504.401c-.77 0-1.4-1.03-1.4-2.3 0-1.27.63-2.3 1.4-2.3.78 0 1.4 1.03 1.4 2.3 0 1.27-.62 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1834.85 528.502c7.6 1.2 15.4.5 22.7-1.9M1887.95 501.501c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1818.35 711.103c5-7.4 9.2-15 13.3-22.6 4.1-7.7 7.8-15.5 11.4-23.3 3.6-7.8 6.8-15.8 9.3-24 2.6-8.2 5-16.4 7.9-24.5"
            />
            <path
                fill="url(#ae)"
                d="M1773.55 737.702c1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 5.9-21.5 25.4-62.9 52.5-82.8.8-.6 1.9-1.4 3.1-2.2.2-.2.5-.3.7-.5 1.9-1.3 4-2.6 5.3-3.4-.1 1.4-.3 4.8-.3 4.8s0 .3-.1.8c-.6 3.5-2.7 18.2-1.4 23.1l6.1-4.7 18.4-14.1c19.9-8.3 32.3-21.7 32.3-21.7s10.2 3.3 24.4 11.2c.1 0 .1.1.2.1.9.5 1.9 1.1 2.8 1.6 1 .6 2.1 1.2 3.1 1.8 4.7 2.8 9.3 5.9 13.8 9.2 1 .7 1.9 1.4 2.9 2.1.8.6 1.6 1.2 2.3 1.8l.1.1c12 9.4 18.8 24.5 18.5 40v.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5h-.3v.2l-.3-.2-144.6-9.8c-5.6-3.8-11.8-8.6-17.8-13.3-5.4-4.3-10.7-8.6-15-12.1-2.3-1.9-4.5-3.5-6.2-4.7-.1-12.1.2-22.5.9-30.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1846.15 592.5c-1.8 1.8-7.3 12.1-13 23.6M1904.85 548.301c-7 8.1-22.3 18.2-32.2 21.6l-24.6 19-8-19.5-7.3 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1872.65 569.902 9.4 13.1 20.9-22.6M1826.55 624.703l-9.2 25.8c-2.6 7.3-4.4 14.8-5.3 22.4l-12.9 104.7M1772.55 768.402c0-12.1.3-22.5 1-30.7 1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 3.2-11.8 10.5-29.5 20.9-46.4M1913.15 551.301c14.9 6.6 28.7 14.5 41.4 24.7 11.9 9.6 18.8 24.6 18.5 40.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5"
            />
            <path
                fill="#FCB47F"
                d="m1707.75 548.3 1.3-1.2c-.7-2.5 1.1-5.1 3-6.8 5.2-4.9 12.6-7.3 19.6-6.5 5.4.6 10.5 3 15.3 5.6 7.1 3.9 13.9 8.3 20.3 13.3 11.2 7.3 4.7 21.1-12.4 23.2-11.6 1.4-24.1-11.2-28.7-18.2l-3.2.2c-2.2.3-4.4.5-6.4 1.6-1.9 1-3.5 3-3.5 5.2 0 1.2.5 2.3.8 3.4.2.8.2 1.6 0 2.3-.2.7-.9 1.1-1.6 1.1-1.3-.1-2.5-.8-3.4-1.7-.6-.6-1-1.3-1.3-2.1l-.9.2c-1 .3-2.2-.2-2.9-1-.7-.8-1.1-1.8-1.4-2.8-1.3-5.8 1.2-11.8 5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1755.14 575.302c-12.8 2.4-27.4-13.9-30.3-19.9M1767.25 552.602c-6.4-5-13.2-9.4-20.3-13.3-4.8-2.6-9.8-5-15.3-5.6-7.1-.8-14.4 1.7-19.6 6.5-1.9 1.8-3.6 4.3-3 6.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1723.14 541c-5.6 1.3-11.2 3.4-15.4 7.4-4.2 4-6.7 10-5.3 15.6.3 1 .7 2.1 1.4 2.8.7.8 1.9 1.2 2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1720.05 548.102c-4 .3-7.9 2.4-10.4 5.6-2.5 3.2-3.5 7.5-2.8 11.5.3 1.7.9 3.3 2.1 4.5.9 1 2.1 1.599 3.4 1.699.7.1 1.4-.4 1.6-1.1.2-.7.2-1.5 0-2.3-.3-1.1-.8-2.199-.8-3.399-.1-2.2 1.5-4.201 3.5-5.201 1.9-1 4.2-1.3 6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M1935.45 579.803c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-8.3 2.3-16.6 4.8-24.8 8-12 4.7-17.9 18.2-13.2 30.1 3.1 8.1 10.3 13.4 18.3 14.6 8.7 1.3 17.4 1.9 26 2.2 8.6.3 17.2.4 25.8.2 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5 17-1.9 29.3-17.2 27.4-34.2-1.9-17-17.2-29.3-34.2-27.4h-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1851.85 602.603-9.6-6.301-9.5-6.399c-6.4-4.2-12.9-8.301-19.5-12.101-3.3-1.9-6.6-3.899-10-5.799-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2-7.9-3.7-17.2-.301-20.9 7.599-2.9 6.2-1.4 13.301 3.1 17.801 2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.099 8.6 7.699 2.9 2.6 5.8 5.101 8.7 7.601 2.9 2.5 5.9 4.899 8.8 7.399 5.9 4.9 11.9 9.601 18.1 14.101l9.3 6.8 9.2 6.8c10 7.4 24.1 5.299 31.5-4.701 7.4-10 5.3-24.1-4.7-31.5-.2-.2-.5-.499-.8-.699Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1885.05 642.401c-6.07 0-11-3.94-11-8.8 0-4.86 4.93-8.8 11-8.8 6.08 0 11 3.94 11 8.8 0 4.86-4.92 8.8-11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1885.05 635.503c-1.27 0-2.3-.851-2.3-1.9s1.03-1.9 2.3-1.9c1.27 0 2.3.851 2.3 1.9s-1.03 1.9-2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1935.55 579.805h-.2c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-4.6 1.3-9.2 2.6-13.7 4.1M1826.05 639.504c3.2 2.5 9.8 4.3 13.8 5 8.7 1.3 17.4 1.9 26 2.2 8.6.3 17.2.4 25.8.2 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5M1842.25 596.303l-9.5-6.4c-6.4-4.2-12.9-8.3-19.5-12.1-3.3-1.9-6.6-3.9-10-5.8-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2M1754.85 581.203c2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.1 8.6 7.7 2.9 2.6 5.8 5.1 8.7 7.6 2.9 2.5 5.9 4.9 8.8 7.4 5.9 4.9 11.9 9.6 18.1 14.1l9.3 6.8 9.2 6.8"
            />
            <path
                fill="url(#af)"
                d="M1909.85 581.103c2.6-.2 5.1-.4 7.7-.6 5.8-.5 11.9-.9 17.8-1.6h.1c1.2-.1 2.4-.2 3.6-.2 16.3 0 30 12.2 31.8 28.5.9 8.5-1.5 16.8-6.8 23.5-5.3 6.7-13 10.9-21.5 11.8-5.9.7-11.9 1.5-17.7 2.4-2.5.4-5.1.7-7.6 1.1-1 .1-2.1.3-3.1.4l-4.7-65.2c.1-.1.3-.1.4-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1963.95 630.603c-5.3 6.7-13 10.9-21.5 11.8-5.9.7-11.9 1.5-17.7 2.4-2.5.4-5.1.7-7.6 1.1-1 .1-2.1.3-3.1.4l-4.7-65.2h.5c2.6-.2 5.1-.4 7.7-.6 5.8-.5 11.9-.9 17.8-1.6h.1c1.2-.1 2.4-.2 3.6-.2M1925.85 580.102l13.3 62.299M1927.65 643.705l-4.9-30.9"
            />
            <path
                fill="#FCB47F"
                d="m1748.95 698.998 8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2 8.7-1.3 14.6-9.3 13.4-18-.9-6.4-5.6-11.3-11.4-12.9-5.7-1.6-11.4-3-17-4.2-5.7-1.2-11.4-2.3-17.1-3.3-1.4-.3-2.9-.5-4.3-.7-1.4-.2-2.9-.4-4.3-.6-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.5-8.6-.8l-8.6-.8c-12.4-1.2-23.4 7.8-24.6 20.2-1.2 12.4 7.8 23.4 20.2 24.6.7.2 1.3.2 1.7.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1739.55 655.5c-5.5 5-11.6 11.8-12.3 18.8-1.2 12.4 7.8 23.4 20.2 24.6.5 0 1 .1 1.5.1l8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2M1820.45 665.498c-5.7-1.6-11.4-3.001-17-4.201-5.7-1.2-11.4-2.299-17.1-3.299-1.4-.3-2.9-.501-4.3-.701-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.799"
            />
            <path
                fill="url(#ag)"
                d="m1748.95 698.999 8.6.3c2.9.1 5.8.201 8.6.3 2.9 0 5.8.201 8.6.101 1.4 0 2.9-.001 4.3-.101 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 2.2-.2 4.5-.399 6.7-.599-3.4-10.3-3.4-30.7 4.2-34.5-2.8-.7-5.7-1.301-8.5-1.901-5.7-1.2-11.4-2.3-17.1-3.3-1.4-.3-2.9-.5-4.3-.7-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.8c-12.4-1.2-23.4 7.8-24.6 20.2-1.2 12.4 7.8 23.4 20.2 24.6.7.1 1.3.1 1.7.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1739.55 655.5c-5.5 5-11.6 11.8-12.3 18.8-1.2 12.4 7.8 23.4 20.2 24.6.5 0 1 .1 1.5.1l8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2M1820.45 665.501c-5.7-1.6-11.4-3-17-4.2-5.7-1.2-11.4-2.3-17.1-3.3-1.4-.3-2.9-.5-4.3-.7-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.799"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1811.85 663.301s-11 6.8-4.2 34.5"
            />
            <path
                fill="url(#ah)"
                d="M1911.65 640.7c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.3c.9 1.7 3 2.2 4.7 1.1l60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3Z"
            />
            <path
                fill="url(#ai)"
                d="m1842.15 674.6 60.7-37.9c1.3-.8 2.7-.7 3.7 0l4 2.9c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.299c.2.5.6.801.9 1.101l-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.8-1.6-.1-3.9 1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1865.25 714.998 60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1861.55 714.998-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.9-1.7-.2-3.9 1.6-5l60.7-37.901c1.3-.8 2.7-.7 3.7 0l4 2.901c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                fill="#231F20"
                d="M1921.25 674.899a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1917.65 668.2a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M1894.64 675.846c-2.2-1.27-2.77-4.394-1.28-6.977 1.49-2.582 4.48-3.646 6.68-2.376 2.2 1.27 2.77 4.394 1.28 6.976-1.49 2.583-4.48 3.647-6.68 2.377Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1915.05 678.801a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="M1879.15 711.101c-.3-.6-.6-1.299-.9-1.899l.8-.901c.9-1 1.3-2.299 1.3-3.599-.1-3.7-.9-8.101-2.1-11.201l1.5-.9c3.5-1.7 6.4-5 6.7-8.8.3-4.2-2.4-8-5.4-11-5.8-5.8-12.7-13.3-20.9-12.5-10.6 1-21.1 2.7-31.5 5.1-19.5 4.1-23.7 19.4-11.3 30.2 5.5 5 14.3 6.1 20.9 2.7 5.6 5.2 13.3 13.1 16.8 20.7.8 1.8 2.8 2.7 4.6 2h.1c2-.7 3-2.8 2.3-4.7-3.3-9.6-11.1-19.699-17.3-27.099 9.1 7.7 20.2 17.599 29.5 25.599 1.2 1 2.9 1 4.1 0 1-1 1.3-2.5.8-3.7Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1825.05 666.301c10.4-2.3 24.5-5 35.1-6 8.2-.8 15.1 6.7 20.9 12.5 3 3 5.7 6.8 5.4 11-.3 3.8-3.2 7.1-6.7 8.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1859.45 673.801c5.9 4 11.9 8.2 15.9 14.1 3.4 5 5.1 11.2 4.9 17.2M1854.75 683.102c4.8 3.9 9.6 7.699 13.7 12.199 3.6 3.9 8.3 10.301 10.7 15.801.5 1.2.2 2.7-.8 3.6-1.2 1-2.9 1-4.1 0-9.3-8-20.4-17.9-29.5-25.6 6.3 7.4 14 17.5 17.3 27.1.7 1.9-.4 4.099-2.3 4.699h-.1c-1.8.6-3.8-.3-4.6-2.1-3.4-7.5-11.1-15.399-16.8-20.599-6.6 3.4-15.5 2.299-20.9-2.701M1869.05 677.703c1.7 1.1 3.4 2.1 5.2 3.2.7.5 1.5 1 1.8 1.8.2.6 0 1.3-.3 1.8s-.8 1-1.2 1.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1859.11 495.906h29.88M1832 495.325a7.49 7.49 0 0 1 2.82-.552c1.25.001 2.23.318 2.8.552"
            />
            <path
                fill="url(#aj)"
                d="M1841.53 493.922h11.85c5.12 0 9.26 4.144 9.26 9.256v4.776c0 3.833-3.11 6.94-6.94 6.94h-2.25c-8.74 0-15.82-7.085-15.82-15.824v-1.249a3.896 3.896 0 0 1 3.9-3.899Z"
                opacity={0.6}
            />
            <path
                fill="url(#ak)"
                d="M1828.06 493.923h-7.45c-3 0-5.72 2.255-5.23 5.709.51 3.643 1.67 10.842 6.61 12.364 5.45 1.678 10.01-3.414 10.01-8.943v-5.183c0-2.18-1.76-3.948-3.94-3.948v.001Z"
                opacity={0.6}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1841.53 493.922h11.85c5.12 0 9.26 4.144 9.26 9.256v4.776c0 3.833-3.11 6.94-6.94 6.94h-2.25c-8.74 0-15.82-7.085-15.82-15.824v-1.249a3.896 3.896 0 0 1 3.9-3.899ZM1828.06 493.923h-7.45c-3 0-5.72 2.255-5.23 5.709.51 3.643 1.67 10.842 6.61 12.364 5.45 1.678 10.01-3.414 10.01-8.943v-5.183c0-2.18-1.76-3.948-3.94-3.948v.001Z"
            />
            <path
                fill="#FFDBAE"
                d="M1763.97 1013.95c5.3-6.9 10.5-13.9 15.5-20.899 2.5-3.5 5.1-7 7.6-10.5l7.4-10.599c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.7 2.9-8 5.5-16.1 7.7-24.5.6-2.1 1-4.2 1.5-6.3.4-2.1.9-4.201 1.3-6.401.8-4.3 1.5-8.699 1.8-13.199l.9-11.301c1.2-14.5-9.7-27.199-24.2-28.399-11.8-.9-22.4 6.1-26.6 16.5l-19 47.3-9.5 23.7-9.4 23.7c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.598c-3.2 8.2.9 17.4 9.1 20.6 6.7 2.6 14.2.3 18.4-5.1l1.9-2Z"
            />
            <path
                fill="url(#al)"
                d="M1846.07 870.354c-2.5-.4-3.1-.701-5.6-1.101-11.8-2.1-22.9-9.099-29.6-20.399-11.5-19.4-5.6-44.5 13-57.4l7.4-5.201c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.299 7.1-3.399 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.201 1.8-.599 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.701c3.1-.3 6.3-.4 9.5-.2 28.2 1.6 51.1 24.401 52.8 52.601 1 17.7-6.1 33.699-17.9 44.599-8 7.4-18.6 11.401-29.5 11.401h-66.6l3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1762.47 1016.06 1.5-2c5.3-6.9 10.5-13.9 15.5-20.904 2.5-3.5 5.1-7 7.6-10.5l7.4-10.6c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.7 1.6-4.4 3.1-8.8 4.5-13.2 1.2-3.7 2.2-7.5 3.2-11.2.6-2.1 1-4.2 1.5-6.3M1805.87 818.855l-14.9 39.2-9.5 23.7-9.4 23.7c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.595M1957.07 815.055c1.1 18.2-6.5 34.6-18.9 45.6-7.8 6.9-18 10.5-28.4 10.5h-54.6M1805.87 818.854c2.2-10.8 8.4-20.7 18-27.4l7.4-5.2c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.3 7.1-3.4 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.2 1.8-.6 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.7"
            />
            <path
                fill="#FFDBAE"
                d="M1763.97 1013.95c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1763.97 1013.95c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.1M1828.57 814.352h-15.4"
            />
            <path
                fill="#E2E2E2"
                d="M1708.47 1086.95c8.1-.2 16.3-.9 24.3-2 4.5-.7 9.2-1.5 13.3-4.1 4.8-3.1 8.4-8.8 10-16 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.8.2-4.2.4-7.9 2.2-14.9 3.9-20.8l.4-1.5-1.6.3c-3.2.5-5.9.9-8.6.9-6.1 0-12.1-1.8-23.3-10.5l-1-.8-.5 1.2c-1.6 3.4-3.1 6.8-4.6 10.2-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3.3 4.7 1.8 6.5 1.5 1.8 3.8 2.8 6.2 2.8h.1Z"
            />
            <path
                fill="url(#am)"
                d="M1745.17 1084.05c4.7-4 9.2-12 10.8-19.2 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.8.2-4.2.2-4.3.9-8.4 1.7-12.4-8.4-5.4-8.4-9.3-16.8-11.9-8.8-2.7-12.7 1.9-20.1 3.3-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3-.9 6.9-.7 11.1.3 11.3 13.6 22.3 45.5-4.7Z"
            />
            <path
                fill="#E2E2E2"
                d="M1745.17 1084.05c4.7-4 9.2-12 10.8-19.2 1.1-4.8 1.2-9.7 1.4-14.4 0-.4-1.6 19.1-12.2 31.1-5.8 6.6-18.2 13.6-27.4 16-3.5.9-5.6-3.2-7.7-3.9-2.9-.9-5.8 1.7-7.2-.3-5-7.4-2-19.1-2.6-15.7-.4 2.3-.9 6.9-.7 11.1.4 11.3 13.7 22.3 45.6-4.7Z"
            />
            <path
                fill="url(#an)"
                d="M1843.37 777.953c9.1-4.8 18.8-8.8 28.5-11.8 8.5-2.7 15.2-3.8 22.9-4.5 54.1-6.6 85.4 62.3 45.1 98.8-8.1 7.5-18.8 11.7-30.1 11.7h-23.2s28.8-24.2 10.4-68.7c-14.4-34.9-53.6-25.5-53.6-25.5Z"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={1419.47}
                    x2={887.424}
                    y1={835.268}
                    y2={835.268}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.515} stopColor="#FFD100" />
                    <stop offset={0.517} stopColor="#FFD101" />
                    <stop offset={0.58} stopColor="#FFDD45" />
                    <stop offset={0.637} stopColor="#FFE77B" />
                    <stop offset={0.687} stopColor="#FFEEA2" />
                    <stop offset={0.726} stopColor="#FFF2BA" />
                    <stop offset={0.75} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={984.816}
                    x2={1087.51}
                    y1={1020.23}
                    y2={1020.23}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1213.76}
                    x2={1316.46}
                    y1={1020.23}
                    y2={1020.23}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1150.64}
                    x2={1150.64}
                    y1={778.848}
                    y2={816.033}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1277.98}
                    x2={1122.61}
                    y1={803.443}
                    y2={676.117}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1503.6}
                    x2={2247.34}
                    y1={925.706}
                    y2={925.706}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={2127.08}
                    x2={1931.55}
                    y1={854.344}
                    y2={747.481}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.969} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFD207" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={1605.28}
                    x2={1929.41}
                    y1={684.274}
                    y2={893.46}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.36} stopColor="#FFF4C3" />
                    <stop offset={0.412} stopColor="#FFDE49" />
                    <stop offset={0.443} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1644.47}
                    x2={2169.05}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1622.33}
                    x2={1544.25}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.191} stopColor="#707276" />
                    <stop offset={1} stopColor="#AEAEAF" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={2148.05}
                    x2={2198.63}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={608.051}
                    x2={673.082}
                    y1={814.005}
                    y2={814.005}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={712.051}
                    x2={743.018}
                    y1={814.005}
                    y2={814.005}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={680.266}
                    x2={810.488}
                    y1={1037.18}
                    y2={906.956}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.413} stopColor="#FFF4C3" />
                    <stop offset={0.453} stopColor="#FFEC96" />
                    <stop offset={0.544} stopColor="#FFD828" />
                    <stop offset={0.576} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={655.681}
                    x2={793.176}
                    y1={814.001}
                    y2={857.177}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.698} stopColor="#FFEA8B" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={774.944}
                    x2={811.519}
                    y1={690.138}
                    y2={690.138}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={758.02}
                    x2={924.169}
                    y1={728.748}
                    y2={728.748}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={792.804}
                    x2={792.804}
                    y1={660.221}
                    y2={593.024}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={751.446}
                    x2={761.683}
                    y1={666.109}
                    y2={648.67}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={803.21}
                    x2={789.423}
                    y1={594.524}
                    y2={594.524}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={780.901}
                    x2={758.565}
                    y1={594.737}
                    y2={594.737}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={652.526}
                    x2={855.511}
                    y1={784.641}
                    y2={784.641}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={785.891}
                    x2={580.45}
                    y1={942.037}
                    y2={874.433}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={870.629}
                    x2={930.394}
                    y1={1167.29}
                    y2={1167.29}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={671.798}
                    x2={895.929}
                    y1={995.625}
                    y2={995.625}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.039} stopColor="#888B8D" />
                    <stop offset={0.954} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={803.713}
                    x2={904.019}
                    y1={742.163}
                    y2={742.163}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={808.348}
                    x2={904.427}
                    y1={745.292}
                    y2={745.292}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={685.978}
                    x2={721.618}
                    y1={631.435}
                    y2={737.003}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1006.8}
                    x2={946.181}
                    y1={748.372}
                    y2={806.164}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={891.555}
                    x2={946.825}
                    y1={817.086}
                    y2={817.086}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.321} stopColor="#D9D9D6" />
                    <stop offset={0.726} stopColor="#AEAEAF" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1520.73}
                    x2={1527.85}
                    y1={499.864}
                    y2={476.316}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.184} stopColor="#FCB47F" />
                    <stop offset={0.409} stopColor="#F8B07C" />
                    <stop offset={0.653} stopColor="#EBA473" />
                    <stop offset={0.905} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1559.8}
                    x2={1559.8}
                    y1={487.574}
                    y2={420.377}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="L"
                    x1={1570.26}
                    x2={1556.47}
                    y1={421.919}
                    y2={421.919}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1547.95}
                    x2={1525.62}
                    y1={422.132}
                    y2={422.132}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1603.18}
                    x2={1554.17}
                    y1={628.01}
                    y2={489.648}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.203} stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1629.13}
                    x2={1583.59}
                    y1={881.993}
                    y2={515.053}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.203} stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1444.61}
                    x2={1621.89}
                    y1={796.243}
                    y2={796.243}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1502.86}
                    x2={1618.72}
                    y1={930.268}
                    y2={930.268}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="R"
                    x1={1545.66}
                    x2={1382.57}
                    y1={935.733}
                    y2={935.733}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={1418.66}
                    x2={1613.72}
                    y1={634.877}
                    y2={634.877}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#0197D6" />
                    <stop offset={0.558} stopColor="#22ADE0" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="T"
                    x1={1542.53}
                    x2={1621.89}
                    y1={623.607}
                    y2={623.607}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={1470.11}
                    x2={1637.32}
                    y1={729.512}
                    y2={637.777}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={1565.95}
                    x2={1305.71}
                    y1={672.609}
                    y2={377.669}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="W"
                    x1={1471.42}
                    x2={1544.51}
                    y1={1173.09}
                    y2={1173.09}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="Y"
                    x1={1863.64}
                    x2={1730.8}
                    y1={639.326}
                    y2={639.326}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="Z"
                    x1={1850.6}
                    x2={1809.31}
                    y1={583.793}
                    y2={583.793}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1={1832.7}
                    x2={1832.7}
                    y1={553.87}
                    y2={486.673}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ab"
                    x1={1874.05}
                    x2={1863.81}
                    y1={559.757}
                    y2={542.318}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ac"
                    x1={1822.29}
                    x2={1836.08}
                    y1={488.173}
                    y2={488.173}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="ad"
                    x1={1844.6}
                    x2={1866.94}
                    y1={488.386}
                    y2={488.386}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="ae"
                    x1={1972.97}
                    x2={1769.99}
                    y1={678.29}
                    y2={678.29}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="af"
                    x1={1897.87}
                    x2={1967.27}
                    y1={507.127}
                    y2={691.031}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1={1811.89}
                    x2={1727.15}
                    y1={676.855}
                    y2={676.855}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="ah"
                    x1={1927.94}
                    x2={1844.24}
                    y1={677.295}
                    y2={677.295}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="ai"
                    x1={1910.65}
                    x2={1840.21}
                    y1={675.581}
                    y2={675.581}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="aj"
                    x1={1850.13}
                    x2={1850.13}
                    y1={510.643}
                    y2={497.511}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#026BD9" />
                    <stop offset={0.205} stopColor="#025DCE" />
                    <stop offset={0.587} stopColor="#033AB2" />
                    <stop offset={1} stopColor="#050D90" />
                </linearGradient>
                <linearGradient
                    id="ak"
                    x1={1823.66}
                    x2={1823.66}
                    y1={510.643}
                    y2={497.511}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#026BD9" />
                    <stop offset={0.205} stopColor="#025DCE" />
                    <stop offset={0.587} stopColor="#033AB2" />
                    <stop offset={1} stopColor="#050D90" />
                </linearGradient>
                <linearGradient
                    id="al"
                    x1={1844.05}
                    x2={2049.49}
                    y1={835.684}
                    y2={768.08}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FFDBAE" />
                    <stop offset={0.511} stopColor="#E3C094" />
                    <stop offset={0.915} stopColor="#CDAB81" />
                </linearGradient>
                <linearGradient
                    id="am"
                    x1={1759.32}
                    x2={1699.55}
                    y1={1060.93}
                    y2={1060.93}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#888B8D" />
                </linearGradient>
                <linearGradient
                    id="an"
                    x1={1944.16}
                    x2={1873.64}
                    y1={892.912}
                    y2={730.088}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#FFB91D" />
                    <stop offset={0.493} stopColor="#FFC63E" />
                    <stop offset={1} stopColor="#FFD766" />
                </linearGradient>
                <radialGradient
                    id="t"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(693.401 924.759) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="F"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 365.472 309.497) scale(13.842)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={0.194} stopColor="#D3D3D0" />
                    <stop offset={0.446} stopColor="#C2C3C2" />
                    <stop offset={0.729} stopColor="#A8AAAA" />
                    <stop offset={0.999} stopColor="#888B8D" />
                </radialGradient>
                <radialGradient
                    id="X"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 966.05 409.204) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <clipPath id="l">
                    <path fill="#fff" d="M342.051 200H700.64v358.589H342.051z" />
                </clipPath>
                <clipPath id="m">
                    <path fill="#fff" d="M720.051 200h358.589v358.589H720.051z" />
                </clipPath>
                <clipPath id="G">
                    <path fill="#fff" d="M1019.95 743.406h-154v77h154z" />
                </clipPath>
            </defs>
        </svg>
    );
}
