import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-3">
        <path
            d="M531.11 269.175a365.355 365.355 0 0 1-17.77-6.269 171.036 171.036 0 0 1-21.561-9.703 920.618 920.618 0 0 1-6.238-3.374c-4.719-2.567-9.598-5.222-14.484-7.638a31.781 31.781 0 0 1-9.909-7.601 31.781 31.781 0 0 1-6.218-10.831 31.784 31.784 0 0 1 1.63-24.438 31.824 31.824 0 0 1 28.707-17.821 31.695 31.695 0 0 1 14.163 3.323l.152.076c4.875 2.444 9.937 4.738 14.832 6.958 2.152.975 4.301 1.956 6.448 2.942a171.176 171.176 0 0 1 20.74 11.359c-4.718 7.021-13.827 40.129-10.492 63.017Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-3-a)"
        />
        <path
            d="m465.185 167.027 2.653.952c13.051 4.677 23.788 10.342 32.826 17.319a2.667 2.667 0 0 1 1.091 2.341c-.25 4.648-12.214 12.208-14.609 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-3-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-3-a"
                x1={482.271}
                y1={170.549}
                x2={519.861}
                y2={274.934}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-3-b"
                x1={481.275}
                y1={170.906}
                x2={518.866}
                y2={275.291}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
