import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-option-5">
        <path
            fill="url(#man-34-25-tshirt-arm-right-option-5-a)"
            d="M418.058 248.5c-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2c-3.6-14.3-16.3-24.3-31-24.3-2.6 0-5.2.3-7.8 1-17.1 4.3-27.6 21.7-23.3 38.8 1.2 4.9 2.3 9.9 3.2 14.8.4 2.1.9 4.3 1.3 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 1.1 2.5 5.5-10.4 25.1-18 17.9-6.9 29.8-3.7 29.8-3.7-.3-4.3-.8-8.9-1.5-13.8Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-option-5-a"
                x1={360.54}
                x2={395.022}
                y1={157.835}
                y2={282.1}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
