import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891']
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-5" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color][0]}
            d="M77.6 50.8c.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.7.1 12.3 1.9 15.3 6.5 1 2.4 1.6 4.8 1.1 7.5-.5 2.5-1.7 4.8-2.8 7.2-1.2 2.6-2.3 8.9-1.8 16.3h-5.3s-.7-3.1-5.5-7c-4.8-3.8-8.9-3.7-11.6-.9-3.8 3.9-1.5 10.9.1 14.7 2.2 5.101 4.6 7.601 9.1 11.001-.152 3.747-.611 7.212-1.288 10.641-.71 3.602-5.543 4.076-7.22.81-3.298-6.427-6.613-12.756-9.792-19.451-4.6-9.6-9.2-20-8.6-30.7.5-11 5.4-17.4 10.8-20.2z"
        ></path>
        <path
            stroke={HAIR_COLOR[color][1]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M75.3 101.7c-4.6-9.6-9.2-20-8.6-30.7.6-11.1 5.5-17.5 10.9-20.3.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.7.1 12.3 1.9 15.3 6.5"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.8c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
    </g>
);

export default SvgComponent;
