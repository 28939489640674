import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9112)"
            d="M464.935 283.558a169.98 169.98 0 01-2.237-5.906 755.803 755.803 0 01-2.319-6.532l-.063-.181c-1.814-5.146-3.671-10.415-5.684-15.526a26.5 26.5 0 1149.312-19.423l.049.125c1.992 5.133 4.209 10.279 6.352 15.255.94 2.183 1.875 4.368 2.806 6.554.068.161.361.89.361.89l-48.577 24.744z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9112)"
            d="M479.236 236.312c-.182-4.781-.96-19.424-3.838-27.14a101.259 101.259 0 0118.685 14.4 1.706 1.706 0 01.535 1.598c-.512 2.967-8.018 7.689-15.382 11.142z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M513.15 257.93c-3.07-7.264-9.206-21.934-9.206-21.934-1.71-5.537-6.889-9.957-6.889-9.957"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.038"
            d="M486.012 216.719a139.58 139.58 0 018.069 6.851"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9112"
                x1="524.702"
                x2="425.583"
                y1="204.889"
                y2="304.846"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9112"
                x1="508.799"
                x2="418.768"
                y1="193.635"
                y2="284.426"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
