import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-left-up">
        <path
            d="m465.193 167.656 2.654.951c13.052 4.678 23.789 10.343 32.825 17.32a2.674 2.674 0 0 1 1.092 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-shirt-1-arm-left-upa)"
        />
        <path
            d="M614.129 238.108c-1.164-7.422-2.079-15.595-3.448-22.946-1.206-6.39-3.268-14.634-4.759-20.228a40.108 40.108 0 0 1-22.351 1.6c-12.209-2.701-16.484-10.073-16.484-10.073-.662 6.71-.973 12.582-1.162 19.615-18.645-7.496-38.711-14.404-58.321-18.399-6.325-1.437-12.865-2.924-19.239-4.59a32.102 32.102 0 0 0-8.113-1.048 31.98 31.98 0 0 0-30.973 24.05 32.033 32.033 0 0 0 23.038 38.953c9.213 2.367 18.206 5.127 27.285 7.712 17.535 5.11 38.248 8.217 56.102 10.404 7.823.895 18.172 1.949 28.854 2.153a24.042 24.042 0 0 0 4.41-.339c14.799 1.418 27.55-12.191 25.161-26.864Z"
            fill="url(#man-34-25-shirt-1-arm-left-upb)"
        />
        <path
            d="M499.602 252.752c17.535 5.11 38.248 8.218 56.102 10.404 7.822.896 18.172 1.949 28.854 2.153 1.477.023 2.953-.09 4.41-.338 14.799 1.417 27.55-12.191 25.16-26.865-1.163-7.422-2.078-15.595-3.448-22.946a156.748 156.748 0 0 0-5.82-20.615s-6.108 4.265-20.205 2.313c-13.833-1.916-16.807-10.085-16.807-10.085a153.835 153.835 0 0 0-1.923 19.301c-18.645-7.495-38.711-14.403-58.321-18.399M467.848 168.609c13.051 4.678 23.788 10.343 32.825 17.319"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-upa"
                x1={465.193}
                y1={185.063}
                x2={501.769}
                y2={185.063}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-upb"
                x1={448.27}
                y1={223.677}
                x2={614.418}
                y2={223.677}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
