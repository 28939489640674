import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6529)"
            d="M403.399 352.069a22.353 22.353 0 01-16.272-6.77c-1.854-1.932-3.188-4.837-4.476-7.136a188.227 188.227 0 01-9.203-19.796c-2.565-6.375-4.944-12.968-7.273-20.158a147.661 147.661 0 01-5.303-20.647c-.381-2.09-.746-4.186-1.112-6.283-.831-4.768-1.692-9.699-2.73-14.497a27.502 27.502 0 0121.054-32.7 27.473 27.473 0 0132.699 21.054l.031.142c1.014 4.809 2.244 9.659 3.434 14.349a825.046 825.046 0 011.555 6.197 147.358 147.358 0 013.603 21.016c.775 7.154 1.287 13.837 1.562 20.384a225.712 225.712 0 0122.322-5.166c1.252-.218 2.497-.446 3.742-.674 2.546-.465 5.178-.947 7.802-1.328l5.77-.905c1.256-.194 2.52-.36 3.782-.525l2.045-.273c5.832-.768 10.602-1.297 15.018-1.667l1.4-.119-.347 1.36c-2.396 9.424 1.625 20.556 6.726 25.466l1.051 1.013-1.323.615c-4.01 1.864-8.411 3.768-13.849 5.992-.645.259-1.284.52-1.921.78-1.178.482-2.355.963-3.54 1.417l-5.471 2.081c-2.454.965-4.976 1.852-7.415 2.709-1.196.42-2.394.841-3.588 1.273a225.473 225.473 0 01-22.419 6.699l-11.334 2.687-11.279 2.823c-1.55.388-3.142.586-4.74.587h-.001z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6529"
                x1="368.985"
                x2="448.792"
                y1="212.199"
                y2="344.055"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
