import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10320_8824)"
            d="M524.495 279.902c-20.409-24.152-26.325-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.778 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.069 11.052-19.209 11.136a62.747 62.747 0 00-26.519 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.882 0 179.194.875 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a531.766 531.766 0 01-2.685-7.11c8.093-3.299 18.171-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
        ></path>
        <g filter="url(#filter0_f_10320_8824)">
            <path
                fill="url(#paint1_radial_10320_8824)"
                d="M410.73 290.876l5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a31.5 31.5 0 0018.534-21.325 31.502 31.502 0 00-6.485-27.499l-6.917-10.636"
            ></path>
        </g>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M407.531 194.484c11.037 8.028 21.706 14.536 31.631 17.961l24.553 18.979 8-19.515 7.31 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M439.164 212.447l-9.444 13.073-20.923-22.629M465.219 234.469c3.321 4.311 6.916 9.661 10.237 13.972"
        ></path>
        <defs>
            <filter
                id="filter0_f_10320_8824"
                width="91.305"
                height="77.898"
                x="402.73"
                y="251.023"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_10320_8824"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_10320_8824"
                x1="345.019"
                x2="532.603"
                y1="332.812"
                y2="299.939"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_10320_8824"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.713 -1072.162) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#FFD100" stopOpacity="0"></stop>
                <stop offset="0.621" stopColor="#FFD100" stopOpacity="0.401"></stop>
                <stop offset="0.781" stopColor="#FFD100" stopOpacity="0.723"></stop>
                <stop offset="0.894" stopColor="#FFD100" stopOpacity="0.923"></stop>
                <stop offset="0.949" stopColor="#FFD100"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
