import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6527)"
            d="M416.227 257.585c-1.524-4.687-3.098-9.533-4.453-14.352l-.04-.137a27.494 27.494 0 00-23.333-19.832 27.501 27.501 0 00-29.53 35.015c1.379 4.8 2.587 9.744 3.757 14.526a868.53 868.53 0 001.144 4.629l52.64-19.277c-.063-.19-.124-.381-.185-.572z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6527"
                x1="357.799"
                x2="427.706"
                y1="250.307"
                y2="250.307"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
