import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m353.852 738.737 2.732-9.89a1252.05 1252.05 0 0 1 1.862-6.753c1.69-6.095 3.437-12.398 4.968-18.678a651.449 651.449 0 0 0 2.088-8.594c1.343-5.642 2.733-11.476 4.393-17.089 4.491-16.077 11.776-30.634 21.332-47.54 4.648-8.022 9.283-15.246 14.176-22.096a119.73 119.73 0 0 1 2.527-3.457c.466-.624.933-1.247 1.393-1.873.424-.541.824-1.055 1.224-1.57.947-1.218 1.925-2.477 2.946-3.697a135.3 135.3 0 0 1 5.817-6.697c-.611-7.743-1.183-15.598-1.737-23.199-.251-3.445-1.053-14.351-1.053-14.351-.36-4.945-.732-10.058-1.232-15.101l-.965-9.601-.491-4.8-.411-4.807c-.513-6.983-.696-13.227-.561-19.099.203-7.541.479-13.344.898-18.818.555-7.328 1.172-13.26 1.943-18.667l.644-4.623.791-4.634c.61-3.449 1.247-6.466 1.949-9.228a52.022 52.022 0 0 1 18.477-28.299c9.136-7.113 20.385-15.14 31.964-15.138h57.59c-4.988 19.471-6.581 30.777-5.514 53.832l.236 5.501c.244 6.322.064 12.653-.539 18.95-.519 5.962-1.29 12.05-2.358 18.612-.863 5.394-2.013 11.242-3.618 18.4-1.423 6.324-3.092 12.456-4.96 18.225-2.135 6.61-4.827 13.048-6.934 17.918l-1.327 3.048c-2.094 4.813-4.258 9.79-6.599 14.661a189.127 189.127 0 0 1-2.139 4.408 186.95 186.95 0 0 1-2.222 4.396c-.957 1.891-2.008 3.806-3.023 5.657-.562 1.024-1.124 2.048-1.674 3.074-2.836 4.956-5.936 10.222-9.486 15.352a27.256 27.256 0 0 1-3.064 9.662l-24.703 45.692-12.362 22.841-38.677 78.925-44.301-20.855Z"
            fill="#009CDE"
        />
        <path
            d="m352.454 400.398-.228.682c-4.005 11.726-6.021 27.272-3.938 39.398-2.882 9.287-1.095 19.198 1.386 28.6a175.384 175.384 0 0 0 3.084 10.414l1.712 5.142 1.836 5.059c.883 2.528 1.881 5.052 2.847 7.494.345.871.689 1.742 1.026 2.606.961 2.53 2.041 5.055 3.086 7.497.358.838 3.201 7.516 3.201 7.516l2.262 4.931c.541 1.158 1.07 2.32 1.6 3.483.954 2.091 1.94 4.254 2.973 6.357 2.957 6.166 6.354 12.485 10.385 19.317 2.036 3.451 4.207 6.918 6.307 10.271 1.751 2.795 3.562 5.687 5.283 8.55l5.709 9.424 5.606 9.469 18.009 30.5a116.477 116.477 0 0 0-1.075 3.122l-.006-.001c-.161.486-.313.971-.464 1.457l-.422 1.335a154.137 154.137 0 0 0-.884 3.01c-.438 1.48-.822 2.977-1.193 4.426-.158.613-.316 1.227-.485 1.876-.166.736-.341 1.472-.515 2.209a113.6 113.6 0 0 0-.908 4.046c-1.697 7.983-3.006 16.198-4.004 25.126-1.883 18.718-2.643 34.467-.231 50.474.757 5.622 7.63 66.428 7.63 66.428h48.29s1.813-36.595 2.731-47.22c.51-5.905 1.024-11.81 1.542-17.716l2.184-25.072 2.043-25.07 4.062-50.14a53.46 53.46 0 0 0-.131-5.91 55.746 55.746 0 0 1-.037-6.553 314.663 314.663 0 0 0-.896-42.411 564.996 564.996 0 0 1-.355-3.94 447.59 447.59 0 0 0-.67-7.14l-1.221-10.995c-.687-6.035-1.675-13.967-3.24-21.796-1.358-6.833-3.082-13.945-5.123-21.14-2.316-8.147-6.702-21.563-6.702-21.563a154.7 154.7 0 0 0 17.838-13.652c22.03-19.471 35.404-38.65 40.888-58.634l.347-1.264-171.139-.002Z"
            fill="url(#female-34-25-pants-6-walk-2-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-6-walk-2-a"
                x1={487.044}
                y1={584.406}
                x2={406.369}
                y2={590.912}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#40C1E9" />
                <stop offset={1} stopColor="#0197D6" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
