import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m330.623 801.227-8.154 30.286 4.244 2.707 7.415-13.658a4.545 4.545 0 0 1 7.041-1.204"
                fill="#63666A"
            />
            <path
                d="M375.419 859.534a38.864 38.864 0 0 1-10.305-4.238l-4.728-2.795a24.34 24.34 0 0 1-10.77-13.445l-3.224-9.933a22.315 22.315 0 0 0-7.169-10.454l-4.755-3.862a13.896 13.896 0 0 1-2.892-18.401l9.474-14.562.87.977c6.572 7.366 11.313 15.715 13.009 22.905.699 2.964 1.104 6.019 1.495 8.974a61.707 61.707 0 0 0 2.514 12.403 30.354 30.354 0 0 0 10.737 14.366 16.388 16.388 0 0 0 5.479 2.744c.846.218 1.72.315 2.594.288 1.81-.292 3.56-.883 5.178-1.748l.66-.353 8.98 9.176a6.428 6.428 0 0 1-6.161 10.727l-10.986-2.769Z"
                fill="#888B8D"
            />
            <path
                d="M390.782 861.838a6.417 6.417 0 0 1-4.377.464l-10.986-2.769a38.857 38.857 0 0 1-10.304-4.238l-4.729-2.795a24.34 24.34 0 0 1-10.77-13.445l-3.224-9.933a22.315 22.315 0 0 0-7.169-10.454l-4.755-3.863a15.925 15.925 0 0 1-5.099-9.571M322.469 831.508l4.244 2.707"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path d="m430.441 842.75 8.095 32.58 7.112.302-.65-10.954" fill="#63666A" />
            <path
                d="M460.334 876.402a15.033 15.033 0 0 1-12.215-6.062l-8.738-12.081a28.025 28.025 0 0 0-3.598-4.072 20.634 20.634 0 0 1-6.368-19.307l2.114-9.778 15.467 22.586c3.966 5.907 5.619 7.117 12.455 9.058 2.928.831 9.363.631 13.554-.361a14.305 14.305 0 0 0 7.428-4.555l11.585 10.137a8.27 8.27 0 0 1 2.584 6.195 8.764 8.764 0 0 1-9.049 8.24h-25.219Z"
                fill="url(#female-34-25-shoes-5-walk-2-a)"
            />
            <path
                d="M492.105 873.841a9.328 9.328 0 0 1-6.504 2.561h-25.219a15.029 15.029 0 0 1-12.214-6.062s-15.175-19.202-16.207-20.934M438.535 875.328h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-5-walk-2-a"
                    x1={429.003}
                    y1={850.752}
                    x2={494.606}
                    y2={850.752}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
            </defs>
        </g>
    );
}
