import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-down">
        <path
            fill={SKIN_COLOR[color]}
            d="M500.557 185.9c-9.2-7.1-20-12.7-32.6-17.2l19.1 31.5c.1 0 18-10.9 13.5-14.3Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M513.458 202.7c2.8 8.1 6 16.1 9.1 24.1 3.1 8 5.599 16.2 7.599 24.6 2.1 8.4 4.001 16.8 5.601 25.3 1.6 8.5 2.9 17.2 3.6 26 1 12.8-8.6 24-21.4 25-8.6.7-16.6-3.5-21.1-10.2-5-7.4-9.2-15-13.3-22.6-4.1-7.7-7.801-15.5-11.401-23.3-3.6-7.8-6.799-15.8-9.299-24-2.6-8.2-5-16.4-7.9-24.5-5.7-16.2 2.8-33.8 19-39.5 16.2-5.7 33.8 2.8 39.5 19v.1Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m536.757 295.2 6.7 10 6.699 9.9c4.5 6.6 8.7 13.4 12.9 20.3 2 3.5 4.1 6.9 6.1 10.4s4 6.9 5.9 10.5c1.9 3.5 3.9 7 5.8 10.6 1.8 3.6 3.8 7.1 5.5 10.8 3.8 7.8.5 17.2-7.3 21-6.2 3-13.4 1.5-18-3.1-2.9-2.9-5.499-5.9-8.299-8.8-2.7-3-5.4-6-8-9-2.7-3-5.301-6-7.901-9.1-2.6-3-5.2-6.1-7.7-9.2-5.1-6.2-10.1-12.4-14.8-18.9l-7.099-9.6-7.201-9.6c-7.4-10-5.4-24.1 4.6-31.5 10-7.4 24.1-5.4 31.5 4.6.1.1.401.4.601.7Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M513.355 202.5v.1c2.8 8.1 6.001 16.1 9.101 24.1 3.1 8 5.6 16.2 7.6 24.6 2.1 8.4 3.999 16.8 5.599 25.3.7 3.9 2.1 12.9 2.7 16.8"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M496.856 317.5c-5-7.4-9.199-15-13.299-22.6-4.1-7.7-7.801-15.5-11.401-23.3-3.6-7.8-6.8-15.8-9.3-24-2.6-8.2-4.999-16.4-7.899-24.5"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M561.156 395.4c-2.9-2.9-5.5-5.9-8.3-8.8-2.7-3-5.4-6-8-9-2.7-3-5.299-6-7.899-9.1-2.6-3-5.2-6.1-7.7-9.2-5.1-6.2-10.1-12.4-14.8-18.9l-7.101-9.6-7.2-9.6m38.801-22.7 4.5 6.7 6.699 9.9c4.5 6.6 8.701 13.4 12.901 20.3 2 3.5 4.099 6.9 6.099 10.4s4.001 6.9 5.901 10.5c1.9 3.5 3.899 7 5.799 10.6 1.8 3.6 3.8 7.1 5.5 10.8"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M586.657 378.3c3.8 9.1 9.901 28 12.101 37.6 2.2 9.6 2.8 19.6 0 29.1-1.5 5.1-4.401 12.7-4.101 18.3 0 .6-.1 1.1-.5 1.5l-.099.1c-.8.9-2.3.8-3-.1-2.1-2.8-3.3-6.4-3.6-9.3l-.7-1.3c-2.6 1.3-7.301 1.1-11.101-1.1-1.6-.9-2.799-2.5-3.199-4.3-.7-2.8.5-5.7 3-7.3l2.199-1.4c.5-.3 1.101-.4 1.601-.3 1.9.4 3.799.7 5.599.5-1.5-5.5-4.199-10.6-7.799-13.7 0 0-.4-.8-.6-.3-.6 2.8-1.3 5.6-3 7.9-2.1 2.8-6.6 4.5-9.9 3l-.8-1.5c3.2-2.9 4.4-7.3 4.3-11.5 0-4.2-.901-8.3-1.901-12.3-1.3-5.3-2.099-14.5-1.099-18.7"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M580.457 418.6c5.1 5.4 8 12.5 8.1 19.7.1 4.6-1 9.2-1.1 13.8-.1 4.6.799 9.6 4.199 12.9m2.901-3.8c.1-5.6 2.699-10.8 4.299-16.2 2.7-9.5 2.2-19.6 0-29.1s-5.099-20-8.899-29.1m-26.101 12.5c-.7 5.1.8 8.7 1.5 12.7.8 4.1 1.901 8.2 1.901 12.3 0 4.2-1.1 8.5-4.3 11.5m1.398 1.7c3.2 1.5 7.3-.4 9.3-3.1 2.1-2.8 2.701-6.2 3.401-9.6.7-3.4 1.699-6.8 4.299-9.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M584.758 438.3c-1.3-4.4-4-8.4-7.5-11.5m-1.801 25.7c3.3 2.3 8.3 3.2 11.5 1.6m-13.301-8.5c3.1 2.5 10.801 3.1 14.301 1.1m-9.4-7.1c3.7 1.2 6.4 1.4 10-.1"
        />
    </g>
);

export default SvgComponent;
