import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7076)"
            d="M392.587 257.658c.021-.017.032-.042.054-.058l4.025-2.86a4.353 4.353 0 014.841-.029l2.352 1.466c.087-1.649.24-3.309.482-4.945 3.416-23.09 13.157-30.795 13.157-30.795-8.895-1.027-17.796-1.716-26.578-2.148-31.633-3.417-41.864 42.951-12.35 53.509 0 0 2.175.624 5.585 1.451l7.223-14.177a4.051 4.051 0 011.209-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7076"
                x1="359.36"
                x2="404.757"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
