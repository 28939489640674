import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9110)"
            d="M514.431 258.429s-.293-.731-.362-.893a802.844 802.844 0 00-2.808-6.56c-2.14-4.968-4.353-10.106-6.34-15.226l-.049-.125a27.508 27.508 0 00-14.621-15.141 27.501 27.501 0 00-36.552 35.298c2.012 5.11 3.872 10.387 5.67 15.491.79 2.242 1.585 4.481 2.384 6.717a173.02 173.02 0 002.25 5.94l.406 1.018 50.382-25.663-.36-.856z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9110)"
            d="M494.776 222.853a2.707 2.707 0 01.822 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.14 108.14 0 0121.852 16.353z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9110"
                x1="489.341"
                x2="465.63"
                y1="276.017"
                y2="191.294"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9110"
                x1="498.777"
                x2="475.066"
                y1="273.375"
                y2="188.652"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
