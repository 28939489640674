import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-3-stand-1">
        <path
            d="m451.617 804.582-.043-.86c-.002-.052-.002-.052.131-5.16 1.386-52.98 2.102-83.017 2.128-89.278-.228-17.532 3.253-45.323 11.544-67.848a49.88 49.88 0 0 0 .679-32.074c-14.066-44.607-31.699-110.19-39.001-160.478a51.941 51.941 0 0 1 51.41-59.349c23.685 0 45.326 16.029 51.192 38.98 23.784 97.617 7.045 201.004 6.316 203.963-24.723 99.567-33.492 129.076-39.163 150.623-2.146 8.151-4 15.191-6.376 24.497l-.213.833-38.604-3.849Z"
            fill="url(#man-34-25-stand-1-pants-3-a)"
        />
        <path
            d="M370.739 387.121c-9.184 15.857-16.082 25.768-17.455 44.268a52.338 52.338 0 0 0-.918 12.188c1.617 38.358 8.676 89.007 19.37 138.96.899 4.214 2.477 13.845 4.004 23.16.765 4.673 1.53 9.335 2.211 13.345a50.747 50.747 0 0 1-.863 20.79c-5.946 24.085-7.154 59.754-2.814 82.955.89 5.227 3.685 24.48 6.644 44.865 3.573 24.615 7.269 50.068 7.97 53.279l.184.843 40.99-2.751.058-.873c.858-12.806 1.54-22.886 2.156-31.973 2.336-34.49 6.747-55.316 13.307-160.157 8.264-28.072 13.522-101.555 15.553-134.876a46.976 46.976 0 0 1 12.679-29.326l24.755-29.503s24.553-11.723 33.27-45.194H370.739Z"
            fill="url(#man-34-25-stand-1-pants-3-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-stand-1-pants-3-a"
                x1={426.512}
                y1={598.983}
                x2={542.372}
                y2={598.983}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
            <linearGradient
                id="man-34-25-stand-1-pants-3-b"
                x1={469.309}
                y1={604.448}
                x2={306.224}
                y2={604.448}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
