import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-down">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-down-a)"
            d="m315.639 268.59.271-.812a427.104 427.104 0 0 1 8.287-22.631 202.729 202.729 0 0 1 10.785-23.335c1.218-2.253 2.454-4.502 3.688-6.75 2.819-5.133 5.734-10.44 8.406-15.749a31.869 31.869 0 0 1 28.607-17.618 31.69 31.69 0 0 1 14.366 3.425 31.793 31.793 0 0 1 15.996 18.547 31.793 31.793 0 0 1-1.804 24.425l-.07.138c-2.696 5.293-5.249 10.778-7.717 16.083a1852.974 1852.974 0 0 1-3.267 6.984 202.54 202.54 0 0 1-12.434 22.504 428.344 428.344 0 0 1-13.565 20.386l-.501.708-51.048-26.305Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-down-a"
                x1={371.916}
                x2={356.286}
                y1={431.777}
                y2={140.721}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#0047A7" />
                <stop offset={0.602} stopColor="#0043A5" />
                <stop offset={0.708} stopColor="#00369D" />
                <stop offset={0.815} stopColor="#002191" />
                <stop offset={0.869} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
