import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <g clipPath="url(#clip0_10892_7282)">
                <path
                    fill="#E2E2E2"
                    d="M668.653 672.899c-2.935-1.234-8.533-3.554-13.041-5.225-7.75-2.874-15.798-8.546-22.681-14.338a177.1 177.1 0 01-18-17.611s-22.956-.87-35.377-1.358c.223 6.462.316 12.769.018 16.007a964.32 964.32 0 01-1.588 15.595 10.008 10.008 0 002.333 7.57 10 10 0 007.108 3.496l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.742 70.742 0 0020.64 4.146c.318.018.7.027 1.137.027 5.146 0 18.055-1.224 25.292-1.954a5.153 5.153 0 004.577-4.364 5.155 5.155 0 00-3.096-5.515z"
                ></path>
                <path
                    fill="#63666A"
                    d="M668.626 683.441a6.17 6.17 0 01-1.805.468c-8.15.834-22.441 2.199-26.582 1.969a71.671 71.671 0 01-20.939-4.177 40.138 40.138 0 00-12.077-2.427l-19.961-.972a11.44 11.44 0 01-7.932-3.581 10.898 10.898 0 01-2.816-8.593c1.622-15.089 1.942-16.63 1.962-20.967.004-.885 1.013-3.586 3.601-3.391 0 0 11.636 12.559 25.832 3.562a108.526 108.526 0 008.543-7.1s1.77-1.108 5.151 3.134c2.416 3.032 15.921 18.893 33.882 25.522 4.419 1.631 9.858 3.871 12.855 5.124a6.447 6.447 0 013.601 3.582 6.17 6.17 0 01-3.315 7.847z"
                ></path>
                <path
                    fill="#E2E2E2"
                    d="M617.006 670.33c-14.289-8.273-31.661-8.461-40.126-7.653a1417.12 1417.12 0 01-.366 3.448 10.898 10.898 0 002.816 8.593 11.44 11.44 0 007.932 3.581s43.537 7.054 52.976 7.576c4.142.23 18.433-1.136 26.583-1.97a6.083 6.083 0 004.189-2.309c-8.058-.548-40.052-3.189-54.004-11.266z"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_10892_7282">
                    <path
                        fill="#fff"
                        d="M0 0H900V900H0z"
                        transform="translate(.752 .098)"
                    ></path>
                </clipPath>
            </defs>
        </g>
    );
}
