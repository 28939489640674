import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-6">
        <path
            d="M531.858 214.01c-.64-1.23-1.282-2.448-1.924-3.667-1.311-2.488-2.666-5.06-3.953-7.625-1.847-3.604-3.81-7.456-5.69-11.374a71.797 71.797 0 0 0-.985-.343c-2.349-.803-4.704-1.589-7.059-2.375-5.353-1.788-10.888-3.637-16.251-5.652l-.149-.056a32.001 32.001 0 0 0-22.209 60.024c5.373 1.988 10.77 4.215 15.989 6.37 2.292.946 4.585 1.892 6.88 2.821a188.299 188.299 0 0 0 23.558 7.799c8.511 2.294 16.444 4.178 24.255 5.76-7.268-16.839-9.758-34.437-10.264-47.583a684.297 684.297 0 0 1-2.198-4.099Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-6-a)"
        />
        <path
            d="m501.245 185.911-.027-.02-.006-.004c-9.031-6.966-19.757-12.623-32.793-17.295l-2.653-.951 21.103 34.813.857-.525c2.396-1.469 14.36-9.029 14.61-13.677a2.666 2.666 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-6-b)"
        />
        <path
            d="M520.292 191.343c-.328-.116-.656-.23-.985-.342-2.349-.803-4.704-1.589-7.059-2.375-4.527-1.512-9.179-3.075-13.752-4.738a121.186 121.186 0 0 0-30.077-15.296l-2.653-.951M520.064 259.933c8.512 2.294 16.445 4.177 24.256 5.76-7.618-17.649-9.988-36.132-10.325-49.447"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-6-a"
                x1={543.659}
                y1={307.118}
                x2={451.294}
                y2={144.226}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.419} stopColor="#fff" />
                <stop offset={0.913} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-6-b"
                x1={569.088}
                y1={327.467}
                x2={457.184}
                y2={130.118}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.419} stopColor="#fff" />
                <stop offset={0.913} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
