import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#63666A"
                d="M574.525 673.461l8.749 31.226 7.846-.244s-.078-6.056-.281-10.411c-.141-3.018 1.61-6.267 5.009-6.165"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M583.274 704.686l7.993-.248"
            ></path>
            <path
                fill="#888B8D"
                d="M640.752 706.964c-3.663.724-7.41.916-11.128.571l-5.47-.507a24.357 24.357 0 01-15.481-7.556l-7.162-7.606a22.35 22.35 0 00-10.945-6.386l-5.949-1.46a13.898 13.898 0 01-10.476-15.402l5.344-24.259 41.448 2.089 13.854 36.061a8.545 8.545 0 004.916 4.901l13.152 5.033a6.44 6.44 0 014.19 6.08 6.456 6.456 0 01-5.178 6.252l-11.115 2.189z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M656.606 702.457l-.028.035a10.329 10.329 0 01-7.728 3.773l-24.694.766a24.338 24.338 0 01-15.482-7.555l-7.158-7.604a22.368 22.368 0 00-10.953-6.39l-5.945-1.46a15.922 15.922 0 01-8.699-6.475"
            ></path>
        </g>
    );
}
