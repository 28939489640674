import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 433,
        height: 420,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M253.939 120.9h77.07c55.23 0 100 44.77 100 100v143.99c0 13.81-11.19 25-25 25M205.96 389.889H68.52M18.064 120.9H179.97c.73 0 1.45.01 2.17.03 4.09.08 8.13.41 12.08.98 4.79.68 9.47 1.7 14.01 3.05 25.74 7.56 47.17 25.2 59.72 48.37a98.937 98.937 0 0 1 10.22 28.63c1.18 6.13 1.8 12.46 1.8 18.94v143.99c0 13.81-11.19 25-25 25M12.535 1.53c-7.11 0-12.47 7.44-11.17 15.51l16.7 103.859M68.518 389.891 53.032 418.5H19.508v-28.609M205.959 389.891l15.487 28.609h33.524v-28.609M357.002 389.891l15.486 28.609h33.525v-28.609M12.541 1.53h185.86c14.06 0 26.48 10.58 30.62 26.08l24.92 93.289"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M44.182 11.373c4.04 4.317 7.144 9.861 8.847 16.237l25.13 93.29 14.81 54.98c4.14 15.5 16.56 26.08 30.62 26.08h228.74c22.091 0 40 17.909 40 40v103.847c0 13.807-11.193 25-25 25h-10.327M357.004 370.807h-38.865v19.085h38.865"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.186 389.89c-5.524 0-10-4.477-10-10V184.555c0-13.807 11.192-25 25-25h129.13c44.183 0 80 35.817 80 80v106.252c0 13.807-11.193 25-25 25H56.924M17.607 389.889h1.9"
                />
            </>
        ),
    },
    'Color #1': {
        width: 433,
        height: 419,
        content: (
            <>
                <path
                    fill="#63666A"
                    d="M149.457 418.5h33.5v-28.6h-49l15.5 28.6ZM372.758 418.5h33.5v-28.6h-49l15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-armchair-a)"
                    d="M317.657 120.9h-134.2c-13.8 0-25 11.2-25 25v244h209.2c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-b)"
                    d="M356.256 120.9h-38.6c41.4 0 75 33.6 75 75v169c0 13.8-11.2 25-25 25h38.7c13.8 0 25-11.2 25-25v-169c-.1-41.5-33.7-75-75.1-75Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M367.656 389.9h38.7c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-63.7"
                />
                <path
                    fill="url(#object-office-armchair-c)"
                    d="M194.056 1.502h-171.4c14.1 0 26.5 10.6 30.6 26.1l39.9 148.3c4.1 15.5 16.6 26.1 30.6 26.1h152l-46.5-174.4c-4.1-15.5-16.5-26.1-30.6-26.1h-4.6Z"
                />
                <path
                    fill="#ECF3F9"
                    d="m93.258 175.802-40-148.2c-4.1-15.5-16.5-26.1-30.6-26.1h-9.9c-7.1 0-12.4 7.4-11.1 15.5l16.6 108.3 75 50.5Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.258 1.502h180.4c14.1 0 26.5 10.6 30.6 26.1l31.7 118.6"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m18.258 125.302-16.6-108.3c-1.3-8.1 4-15.5 11.1-15.5h9.9"
                />
                <path
                    fill="url(#object-office-armchair-d)"
                    d="M367.558 389.9h-233.8v-188h218.9c22.1 0 40 17.9 40 40v122.9c-.1 13.9-11.3 25.1-25.1 25.1Z"
                />
                <path
                    fill="url(#object-office-armchair-e)"
                    d="M86.158 120.9h-56.9v269h106.9c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-f)"
                    d="M46.457 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="#222731"
                    d="M53.258 418.5h-33.5v-28.6h49l-15.5 28.6ZM279.457 418.5h-33.5v-28.6h49l-15.5 28.6Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M46.457 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M125.259 389.9h10.9c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-3.1M206.258 201.9h146.4c22.1 0 40 17.9 40 40v122.9c0 13.8-11.2 25-25 25h-121.7M254.156 120.9h38.5"
                />
                <defs>
                    <linearGradient
                        id="object-office-armchair-a"
                        x1={158.457}
                        x2={392.657}
                        y1={255.401}
                        y2={255.401}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.005} stopColor="#C2C9CF" />
                        <stop offset={0.533} stopColor="#ECF3F9" />
                        <stop offset={0.763} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-b"
                        x1={362.006}
                        x2={362.006}
                        y1={357.84}
                        y2={149.515}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.374} stopColor="#C2C9CF" />
                        <stop offset={0.508} stopColor="#C2C9CF" />
                        <stop offset={0.604} stopColor="#C2C9CF" />
                        <stop offset={0.682} stopColor="#C2C9CF" />
                        <stop offset={0.748} stopColor="#C2C9CF" />
                        <stop offset={0.807} stopColor="#C2C9CF" />
                        <stop offset={0.86} stopColor="#C2C9CF" />
                        <stop offset={0.908} stopColor="#ECF3F9" />
                        <stop offset={0.95} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-c"
                        x1={147.006}
                        x2={147.006}
                        y1={178.473}
                        y2={-1.188}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.099} stopColor="#C2C9CF" />
                        <stop offset={0.283} stopColor="#C2C9CF" />
                        <stop offset={0.532} stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                        <stop offset={0.886} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-d"
                        x1={263.208}
                        x2={263.208}
                        y1={380.072}
                        y2={218.624}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.097} stopColor="#C2C9CF" />
                        <stop offset={0.273} stopColor="#C2C9CF" />
                        <stop offset={0.509} stopColor="#C2C9CF" />
                        <stop offset={0.793} stopColor="#ECF3F9" />
                        <stop offset={0.881} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-e"
                        x1={95.208}
                        x2={95.208}
                        y1={12010.6}
                        y2={12010.6}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.374} stopColor="#C2C9CF" />
                        <stop offset={0.508} stopColor="#C2C9CF" />
                        <stop offset={0.604} stopColor="#C2C9CF" />
                        <stop offset={0.682} stopColor="#C2C9CF" />
                        <stop offset={0.748} stopColor="#C2C9CF" />
                        <stop offset={0.807} stopColor="#C2C9CF" />
                        <stop offset={0.86} stopColor="#C2C9CF" />
                        <stop offset={0.908} stopColor="#ECF3F9" />
                        <stop offset={0.95} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-f"
                        x1={64.457}
                        x2={64.457}
                        y1={459.48}
                        y2={194.806}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.007} stopColor="#C2C9CF" />
                        <stop offset={0.678} stopColor="#ECF3F9" />
                        <stop offset={0.971} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 433,
        height: 419,
        content: (
            <>
                <path
                    fill="#63666A"
                    d="M149.207 418.5h33.5v-28.6h-49l15.5 28.6ZM372.508 418.5h33.5v-28.6h-49l15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-armchair-a)"
                    d="M317.407 120.9h-134.2c-13.8 0-25 11.2-25 25v244h209.2c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-b)"
                    d="M356.006 120.9h-38.6c41.4 0 75 33.6 75 75v169c0 13.8-11.2 25-25 25h38.7c13.8 0 25-11.2 25-25v-169c-.1-41.5-33.7-75-75.1-75Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M367.406 389.9h38.7c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-63.7"
                />
                <path
                    fill="url(#object-office-armchair-c)"
                    d="M193.808 1.502h-171.4c14.1 0 26.5 10.6 30.6 26.1l39.9 148.3c4.1 15.5 16.6 26.1 30.6 26.1h152l-46.5-174.4c-4.1-15.5-16.5-26.1-30.6-26.1h-4.6Z"
                />
                <path
                    fill="#FFE4B8"
                    d="m93.008 175.802-40-148.2c-4.1-15.5-16.5-26.1-30.6-26.1h-9.9c-7.1 0-12.4 7.4-11.1 15.5l16.6 108.3 75 50.5Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.008 1.502h180.4c14.1 0 26.5 10.6 30.6 26.1l31.7 118.6"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m18.008 125.302-16.6-108.3c-1.3-8.1 4-15.5 11.1-15.5h9.9"
                />
                <path
                    fill="url(#object-office-armchair-d)"
                    d="M367.308 389.9h-233.8v-188h218.9c22.1 0 40 17.9 40 40v122.9c-.1 13.9-11.3 25.1-25.1 25.1Z"
                />
                <path
                    fill="url(#object-office-armchair-e)"
                    d="M85.908 120.9h-56.9v269h106.9c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-f)"
                    d="M46.207 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="#222731"
                    d="M53.008 418.5h-33.5v-28.6h49l-15.5 28.6ZM279.207 418.5h-33.5v-28.6h49l-15.5 28.6Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M46.207 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M125.009 389.9h10.9c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-3.1M206.008 201.9h146.4c22.1 0 40 17.9 40 40v122.9c0 13.8-11.2 25-25 25h-121.7M253.906 120.9h38.5"
                />
                <defs>
                    <linearGradient
                        id="object-office-armchair-a"
                        x1={158.207}
                        x2={392.407}
                        y1={255.401}
                        y2={255.401}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.005} stopColor="#E5B97F" />
                        <stop offset={0.533} stopColor="#FFE4B8" />
                        <stop offset={0.763} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-b"
                        x1={361.756}
                        x2={361.756}
                        y1={357.84}
                        y2={149.515}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.762} stopColor="#E5B97F" />
                        <stop offset={0.911} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-c"
                        x1={146.758}
                        x2={146.758}
                        y1={178.473}
                        y2={-1.188}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.099} stopColor="#E5B97F" />
                        <stop offset={0.283} stopColor="#E5B97F" />
                        <stop offset={0.532} stopColor="#FFE4B8" />
                        <stop offset={0.833} stopColor="#FFE4B8" />
                        <stop offset={0.886} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-d"
                        x1={262.958}
                        x2={262.958}
                        y1={380.072}
                        y2={218.624}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.097} stopColor="#E5B97F" />
                        <stop offset={0.273} stopColor="#E5B97F" />
                        <stop offset={0.509} stopColor="#FFE4B8" />
                        <stop offset={0.793} stopColor="#FFE4B8" />
                        <stop offset={0.881} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-e"
                        x1={80.457}
                        x2={80.457}
                        y1={357.84}
                        y2={149.515}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.762} stopColor="#E5B97F" />
                        <stop offset={0.911} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-f"
                        x1={64.207}
                        x2={64.207}
                        y1={459.48}
                        y2={194.806}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.007} stopColor="#E5B97F" />
                        <stop offset={0.678} stopColor="#FFE4B8" />
                        <stop offset={0.971} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 433,
        height: 419,
        content: (
            <>
                <path
                    fill="#63666A"
                    d="M149.955 418.5h33.5v-28.6h-49l15.5 28.6ZM373.256 418.5h33.5v-28.6h-49l15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-armchair-a)"
                    d="M318.155 120.9h-134.2c-13.8 0-25 11.2-25 25v244h209.2c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-b)"
                    d="M356.754 120.9h-38.6c41.4 0 75 33.6 75 75v169c0 13.8-11.2 25-25 25h38.7c13.8 0 25-11.2 25-25v-169c-.1-41.5-33.7-75-75.1-75Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M368.154 389.9h38.7c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-63.7"
                />
                <path
                    fill="url(#object-office-armchair-c)"
                    d="M194.556 1.502h-171.4c14.1 0 26.5 10.6 30.6 26.1l39.9 148.3c4.1 15.5 16.6 26.1 30.6 26.1h152l-46.5-174.4c-4.1-15.5-16.5-26.1-30.6-26.1h-4.6Z"
                />
                <path
                    fill="#FCCBBB"
                    d="m93.756 175.802-40-148.2c-4.1-15.5-16.5-26.1-30.6-26.1h-9.9c-7.1 0-12.4 7.4-11.1 15.5l16.6 108.3 75 50.5Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M18.756 1.502h180.4c14.1 0 26.5 10.6 30.6 26.1l31.7 118.6"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m18.756 125.302-16.6-108.3c-1.3-8.1 4-15.5 11.1-15.5h9.9"
                />
                <path
                    fill="url(#object-office-armchair-d)"
                    d="M368.056 389.9h-233.8v-188h218.9c22.1 0 40 17.9 40 40v122.9c-.1 13.9-11.3 25.1-25.1 25.1Z"
                />
                <path
                    fill="url(#object-office-armchair-e)"
                    d="M86.656 120.9h-56.9v269h106.9c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-f)"
                    d="M46.955 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="#222731"
                    d="M53.756 418.5h-33.5v-28.6h49l-15.5 28.6ZM279.955 418.5h-33.5v-28.6h49l-15.5 28.6Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M46.955 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.799 0 25-11.2 25-25v-169"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M125.757 389.9h10.9c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-3.1M206.756 201.9h146.4c22.1 0 40 17.9 40 40v122.9c0 13.8-11.2 25-25 25h-121.7M254.654 120.9h38.5"
                />
                <defs>
                    <linearGradient
                        id="object-office-armchair-a"
                        x1={158.955}
                        x2={393.155}
                        y1={255.4}
                        y2={255.4}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.005} stopColor="#EA9975" />
                        <stop offset={0.533} stopColor="#FCCBBB" />
                        <stop offset={0.763} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-b"
                        x1={362.504}
                        x2={362.504}
                        y1={357.839}
                        y2={149.514}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.762} stopColor="#EA9975" />
                        <stop offset={0.95} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-c"
                        x1={147.506}
                        x2={147.506}
                        y1={178.472}
                        y2={-1.188}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.099} stopColor="#EA9975" />
                        <stop offset={0.283} stopColor="#EA9975" />
                        <stop offset={0.532} stopColor="#FCCBBB" />
                        <stop offset={0.833} stopColor="#FCCBBB" />
                        <stop offset={0.886} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-d"
                        x1={263.706}
                        x2={263.706}
                        y1={380.071}
                        y2={218.623}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.097} stopColor="#EA9975" />
                        <stop offset={0.273} stopColor="#EA9975" />
                        <stop offset={0.509} stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#FCCBBB" />
                        <stop offset={0.881} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-e"
                        x1={81.205}
                        x2={81.205}
                        y1={357.839}
                        y2={149.514}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.762} stopColor="#EA9975" />
                        <stop offset={0.95} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-f"
                        x1={64.955}
                        x2={64.955}
                        y1={459.479}
                        y2={194.805}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.007} stopColor="#EA9975" />
                        <stop offset={0.678} stopColor="#FCCBBB" />
                        <stop offset={0.971} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 433,
        height: 419,
        content: (
            <>
                <path
                    fill="#63666A"
                    d="M149.703 418.5h33.5v-28.6h-49l15.5 28.6ZM373.004 418.5h33.5v-28.6h-49l15.5 28.6Z"
                />
                <path
                    fill="url(#object-office-armchair-a)"
                    d="M317.903 120.9h-134.2c-13.8 0-25 11.2-25 25v244h209.2c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-b)"
                    d="M356.502 120.9h-63.6 25c41.4 0 75 33.6 75 75v169c0 13.8-11.2 25-25 25h38.7c13.8 0 25-11.2 25-25v-169c-.1-41.5-33.7-75-75.1-75Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M367.902 389.9h38.7c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-63.7"
                />
                <path
                    fill="url(#object-office-armchair-c)"
                    d="M194.304 1.502h-175.8 4.4c14.1 0 26.5 10.6 30.6 26.1l39.9 148.3c4.1 15.5 16.6 26.1 30.6 26.1h152l-46.5-174.4c-4.1-15.5-16.5-26.1-30.6-26.1h-4.6Z"
                />
                <path
                    fill="#FFF4C3"
                    d="m93.504 175.802-40-148.2c-4.1-15.5-16.5-26.1-30.6-26.1h-9.9c-7.1 0-12.4 7.4-11.1 15.5l16.6 108.3 75 50.5Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M18.504 1.502h180.4c14.1 0 26.5 10.6 30.6 26.1l31.7 118.6"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m18.504 125.302-16.6-108.3c-1.3-8.1 4-15.5 11.1-15.5h9.9"
                />
                <path
                    fill="url(#object-office-armchair-d)"
                    d="M367.804 389.9h-233.8v-188h218.9c22.1 0 40 17.9 40 40v122.9c-.1 13.9-11.3 25.1-25.1 25.1Z"
                />
                <path
                    fill="url(#object-office-armchair-e)"
                    d="M86.404 120.9h-56.9v269h106.9c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="url(#object-office-armchair-f)"
                    d="M46.703 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.8 0 25-11.2 25-25v-169c0-41.5-33.6-75-75-75Z"
                />
                <path
                    fill="#222731"
                    d="M53.504 418.5h-33.5v-28.6h49l-15.5 28.6ZM279.703 418.5h-33.5v-28.6h49l-15.5 28.6Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M46.703 120.9h-14c-13.8 0-25 11.2-25 25v234c0 5.5 4.5 10 10 10h79c13.8 0 25-11.2 25-25v-169"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M125.505 389.9h10.9c13.8 0 25-11.2 25-25v-169c0-41.4-33.6-75-75-75h-3.1M206.504 201.9h146.4c22.1 0 40 17.9 40 40v122.9c0 13.8-11.2 25-25 25h-121.7M254.402 120.9h38.5"
                />
                <defs>
                    <linearGradient
                        id="object-office-armchair-a"
                        x1={158.745}
                        x2={392.875}
                        y1={255.366}
                        y2={255.366}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.005} stopColor="#FFD101" />
                        <stop offset={0.533} stopColor="#FFEA8C" />
                        <stop offset={0.763} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-b"
                        x1={362.201}
                        x2={362.201}
                        y1={357.839}
                        y2={149.514}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.374} stopColor="#FFD102" />
                        <stop offset={0.508} stopColor="#FFD309" />
                        <stop offset={0.604} stopColor="#FFD514" />
                        <stop offset={0.682} stopColor="#FFD825" />
                        <stop offset={0.748} stopColor="#FFDC3B" />
                        <stop offset={0.807} stopColor="#FFE157" />
                        <stop offset={0.86} stopColor="#FFE677" />
                        <stop offset={0.908} stopColor="#FFED9B" />
                        <stop offset={0.95} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-c"
                        x1={147.303}
                        x2={147.303}
                        y1={178.472}
                        y2={-1.189}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.099} stopColor="#FFD30D" />
                        <stop offset={0.283} stopColor="#FFDA30" />
                        <stop offset={0.532} stopColor="#FFE468" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                        <stop offset={0.886} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-d"
                        x1={263.414}
                        x2={263.414}
                        y1={380.071}
                        y2={218.623}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.097} stopColor="#FFD30C" />
                        <stop offset={0.273} stopColor="#FFD92D" />
                        <stop offset={0.509} stopColor="#FFE362" />
                        <stop offset={0.793} stopColor="#FFF0AB" />
                        <stop offset={0.881} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-e"
                        x1={95.435}
                        x2={95.435}
                        y1={357.839}
                        y2={149.514}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.374} stopColor="#FFD102" />
                        <stop offset={0.508} stopColor="#FFD309" />
                        <stop offset={0.604} stopColor="#FFD514" />
                        <stop offset={0.682} stopColor="#FFD825" />
                        <stop offset={0.748} stopColor="#FFDC3B" />
                        <stop offset={0.807} stopColor="#FFE157" />
                        <stop offset={0.86} stopColor="#FFE677" />
                        <stop offset={0.908} stopColor="#FFED9B" />
                        <stop offset={0.95} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-armchair-f"
                        x1={64.681}
                        x2={64.681}
                        y1={459.479}
                        y2={194.805}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.678} stopColor="#FFEA8C" />
                        <stop offset={0.971} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-armchair"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
