import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9614)"
            d="M476.742 232.506a64.773 64.773 0 001.405-10.606 33.79 33.79 0 00-1.402-11.517 105.95 105.95 0 0117.336 13.628 1.706 1.706 0 01.535 1.598c-.522 3.028-8.33 7.884-15.838 11.354l-2.036-4.457z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9614)"
            d="M485.011 276.375c-4.579-1.707-9.309-3.467-14.027-5.016a26.484 26.484 0 01-15.386-13.216 26.494 26.494 0 013.545-29.231A26.494 26.494 0 01487.505 221l.135.045c3.866 1.292 7.853 2.48 11.385 3.512l-14.014 51.818z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M492.595 222.633c1.429.441 6.43 1.923 6.43 1.923l-14.013 51.818"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9614"
                x1="488.89"
                x2="465.548"
                y1="203.382"
                y2="309.717"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9614"
                x1="483.155"
                x2="458.111"
                y1="200.8"
                y2="314.892"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
