import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={249}
            height={177}
            fill="none"
            id="object-food-beans"
            {...attributes}
        >
            <path
                fill="#fff"
                d="M247.135 82.914c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.669-9.368 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.892 37.664H81.011c-15.12-7.029-32.88-20.789-47.892-37.664a159.19 159.19 0 0 1-4.905-5.728 170.404 170.404 0 0 1-8.94-11.91c-3.52-5.156-6.682-10.454-9.367-15.825-3.532-7.005-6.265-14.13-7.936-21.207h245.164Z"
            />
            <path
                fill="url(#object-food-beans-a)"
                d="M247.135 82.914c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335h198.131Z"
            />
            <path
                fill="#00B140"
                d="M239.21 104.121c-2.697 5.37-5.86 10.669-9.368 15.825H19.276c-3.52-5.156-6.683-10.454-9.368-15.825H239.21ZM220.904 131.855H28.214a158.325 158.325 0 0 0 4.906 5.728h182.879l4.905-5.728Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M203.732 150.142c19.87-18.515 37.715-43.21 43.404-67.224H1.973c9.088 38.365 49.204 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
            />
            <rect
                width={33}
                height={13}
                x={29.053}
                y={60.951}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={49.004}
                y={40.883}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={68.553}
                y={20.814}
                fill="#E4002B"
                rx={6.5}
            />
            <rect width={33} height={13} x={88.504} y={0.746} fill="#E4002B" rx={6.5} />
            <rect
                width={33}
                height={13}
                x={68.553}
                y={60.951}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={88.504}
                y={40.883}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={108.053}
                y={20.814}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={128.004}
                y={0.746}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={108.053}
                y={60.951}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={128.004}
                y={40.883}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={147.553}
                y={20.814}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={147.553}
                y={60.951}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={167.504}
                y={40.883}
                fill="#E4002B"
                rx={6.5}
            />
            <rect
                width={33}
                height={13}
                x={187.053}
                y={60.951}
                fill="#E4002B"
                rx={6.5}
            />
            <defs>
                <linearGradient
                    id="object-food-beans-a"
                    x1={49.004}
                    x2={247.135}
                    y1={129.081}
                    y2={129.081}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
