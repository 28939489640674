import React, { useState, useEffect, useRef } from 'react';
import { TGender } from 'interfaces';
import Controls from 'components/Controls';

type TProps = {
    setState?: (s: string) => void;
    gender?: TGender;
    historyUndo?: () => void;
    children: React.ReactNode;
    preset?: boolean;
    historyIsEmpty?: boolean;
}

function useWindowHeight() {
    const [windowHeight, setWindowHeight] = useState<number | undefined>();
    useEffect(() => {
        function handleResize() {
            setWindowHeight(window.innerHeight);
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    return windowHeight;
}

function PicWrapper({ setState, gender, historyIsEmpty, historyUndo, preset = false, children }: TProps) {
    const ref = useRef(null);
    const [scale, setScale] = useState(1);
    const [visible, setVisible] = useState(false);

    const windowHeight = useWindowHeight();

    useEffect(() => {
        if (windowHeight) {
            const _dif = (window.innerHeight - 81 - 84) / 900;
            if (_dif < 1) { // && (!preset || _dif < 0.5)
                setScale(_dif);
            }
        }
        if (!visible) setVisible(true);
    }, [windowHeight]);

    return <div className="pic-wrap">
        <div
            ref={ref}
            className={`pic ${preset ? 'centered' : ''}`}
            style={{
                transform: `scale(${scale})`,
                opacity: visible ? 1 : 0,
            }}
        >
            {preset ? children :
                <svg
                    id="svg-target"
                    className="pic-svg"
                    width="900" height="900"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    {children}
                </svg>
            }
        </div>
        {gender
            ? <Controls setState={setState} gender={gender} historyUndo={historyUndo} historyIsEmpty={historyIsEmpty}/>
            : null}
    </div>;
}

export default PicWrapper;
