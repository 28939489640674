import React, { ReactNode, useCallback, useContext } from 'react';
import { TGender } from 'interfaces';

import { PageContext } from 'components/App';
import { ReactComponent as UndoIcon } from 'icons/undo.svg';
import { ReactComponent as NoteIcon } from 'icons/note.svg';
import { ReactComponent as WandIcon } from 'icons/wand.svg';

import getRandomHash from 'utils/randomize';
import { hashString } from 'utils/hashUtil';

export default function Controls({
    setState,
    historyUndo = () => {},
    gender,
    historyIsEmpty = true
}: {
    setState?: (v: string) => void;
    gender?: TGender;
    historyUndo?: () => void;
    historyIsEmpty?: boolean;
}) {
    const { setModal } = useContext(PageContext);

    function newCharacter() {
        setModal('create');
    }

    const randomize = useCallback(() => {
        if (typeof setState === 'function' && gender) {
            const _st = getRandomHash(gender);
            if (_st) {
                setState(hashString(_st));
            }
        }
    }, [gender]);

    return <div className="controls">
        <ControlButton onClick={historyUndo} className="blue" disabled={historyIsEmpty}>
            <div className="icon-wrap">
                <UndoIcon className="icon-undo" />
            </div>
            <p>Undo</p>
        </ControlButton>
        <ControlButton onClick={randomize} className="purple" width={1}>
            <div className="icon-wrap">
                <WandIcon className="icon-randomize" />
            </div>
            <p>Randomize</p>
        </ControlButton>
        <ControlButton onClick={newCharacter} className="red">
            <div className="icon-wrap">
                <NoteIcon className="icon-new-char" />
            </div>
            <p>New Character</p>
        </ControlButton>
    </div>;
}

export function ControlButton({
    children,
    onClick,
    className = 'blue',
    disabled = false,
    width = 2,
} : {
    children : ReactNode,
    onClick: () => void,
    className?: string,
    disabled?: boolean,
    width?: number,
} ) {
    const buttonWidth = width === 1 ? 'medium' :  width === 0 ? 'short' : '';
    return (
        <button
            onClick={onClick}
            className={`controls-button ${className} ${buttonWidth}`}
            {...disabled? { disabled } : {}}
        >
            {children}
        </button>
    );
}
