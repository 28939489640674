import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9889)"
            d="M494.78 222.54a109.63 109.63 0 00-20.026-15.329 31.051 31.051 0 012.394 13.895 66.038 66.038 0 01-1.451 10.762l2.169 4.754a.986.986 0 00.562.523 1.004 1.004 0 00.768-.03c2.598-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9889)"
            d="M508.181 234.769c-3.192-2.88-6.493-5.859-9.592-8.924l-.121-.119a27.498 27.498 0 00-38.889.457 27.503 27.503 0 00.458 38.889c2.165 2.114 4.287 4.327 6.367 6.534l43.672-35.118c-.632-.572-1.262-1.148-1.895-1.719z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9889"
                x1="506.355"
                x2="435.512"
                y1="176.188"
                y2="310.356"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9889"
                x1="511.783"
                x2="440.939"
                y1="179.061"
                y2="313.23"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
