import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-p2">
        <path
            fill="url(#man-34-25-doctors-coat-a)"
            d="m538.452 333-170.8 18.6v244.9h177.3V402.6l-6.5-69.6Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M544.953 423.9v172.6h-96.4"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-a"
                x1={367.702}
                x2={544.983}
                y1={464.742}
                y2={464.742}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={1} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
