import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-3-stand-2">
            <path
                fill="#2B2729"
                d="M396.14 817.871c.512 4.31-.328 20.872-.957 31.623a9.007 9.007 0 0 0 2.438 6.699 8.993 8.993 0 0 0 6.547 2.821h57.647a4.442 4.442 0 0 0 4.445-4.445 4.435 4.435 0 0 0-2.34-3.915c-3.792-2.032-11.23-6.09-14.957-8.599-8.486-5.714-20.307-24.184-20.307-24.184M374.408 759.254c-3.674 2.311-18.994 8.658-28.975 12.703a8.992 8.992 0 0 0-4.745 12.2l8.723 18.363a42.203 42.203 0 0 1 3.742 12.38 69.631 69.631 0 0 0 6.042 19.852c1.735 3.718 10.099 16.242 14.14 22.225a4.154 4.154 0 0 0 7.144-.441 4.141 4.141 0 0 0 .434-2.278c-.321-3.343-.92-9.243-1.572-13.918-2.925-20.987 9.017-51.715 9.017-51.715"
            />
            <path
                fill="#63666A"
                d="M384.094 802.535a1.004 1.004 0 0 1 .382 1.069c-2.732 10.284-5.63 24.967-3.926 36.51.702 4.75 1.36 10.777 1.69 13.948a5.167 5.167 0 0 1-2.468 4.944 5.159 5.159 0 0 1-6.906-1.494c-4.623-6.726-12.642-18.56-14.4-22.245a70.813 70.813 0 0 1-6.289-20.091 41.09 41.09 0 0 0-3.752-12.054l-8.871-18.292a9.908 9.908 0 0 1-.351-7.918 9.895 9.895 0 0 1 5.513-5.681c4.18-1.733 8.262-3.451 11.803-4.971a.998.998 0 0 1 .729-.022.999.999 0 0 1 .551.477 65.427 65.427 0 0 1 4.231 11.305c.205.668.408 1.335.615 1.999 1.873 6.001 4.275 12.065 9.127 15.743a32.989 32.989 0 0 0 6.203 3.426c1.074.499 2.184 1.014 3.255 1.578a24.419 24.419 0 0 1 2.864 1.769Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M376.329 859.685a5.148 5.148 0 0 1-3.463-2.171c-4.623-6.726-12.642-18.56-14.4-22.245a70.813 70.813 0 0 1-6.289-20.091 41.106 41.106 0 0 0-3.752-12.055l-8.871-18.292a9.899 9.899 0 0 1-.351-7.917"
            />
            <path
                fill="url(#man-34-25-shoes-3-stand-2-a)"
                d="M461.817 860.011H404.17a9.903 9.903 0 0 1-7.277-3.138 9.895 9.895 0 0 1-2.706-7.44c.193-3.288.404-7.12.591-10.98a1.01 1.01 0 0 1 .398-.75 1.002 1.002 0 0 1 .832-.174 15.028 15.028 0 0 0 12.593-2.603 30.379 30.379 0 0 0 3.72-3.648 39.733 39.733 0 0 1 3.132-3.186 21.101 21.101 0 0 1 18.02-4.47c.245.051.46.192.606.395 4.311 6.008 10.492 13.871 15.446 17.207 3.659 2.464 10.888 6.412 14.871 8.546a5.433 5.433 0 0 1 2.867 4.797 5.448 5.448 0 0 1-5.446 5.444Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.024 858.021a5.444 5.444 0 0 1-4.209 1.993h-57.647a9.9 9.9 0 0 1-7.277-3.139"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-3-stand-2-a"
                    x1="394.17"
                    x2="467.263"
                    y1="841.587"
                    y2="841.587"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".299" stopColor="#63666A" />
                    <stop offset="1" stopColor="#8E8B8F" />
                </linearGradient>
            </defs>
        </g>
    );
}
