import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7161)"
            d="M476.744 231.756c.76-3.49 1.23-7.037 1.406-10.605a33.848 33.848 0 00-1.403-11.518 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7161)"
            d="M586.122 300.463c-2.391-1.756-5.027-3.523-8.064-5.403-.277-.168-.553-.339-.83-.51l-.215-.134c-.625-.387-1.333-.826-2.043-1.23l-3.076-1.739c-1.403-.828-2.845-1.565-4.261-2.29-.682-.349-1.364-.698-2.051-1.062a82.794 82.794 0 00-13.155-5.468 484.344 484.344 0 00-4.047-1.252 863.533 863.533 0 01-3.563-1.1c-2.17-3.892-4.68-7.911-7.895-12.641-3.464-5.054-7.204-10.061-11.432-15.306a117.047 117.047 0 00-12.936-13.94 491.726 491.726 0 00-4.067-3.691l-.195-.176c-3.17-2.861-6.448-5.819-9.525-8.861l-.121-.121a27.602 27.602 0 00-19.388-7.898A27.549 27.549 0 00459.404 226a27.58 27.58 0 00-7.896 19.717 27.56 27.56 0 008.357 19.526c3.113 3.04 6.143 6.284 9.072 9.42a613.688 613.688 0 003.889 4.138c4.366 4.608 9.1 8.851 14.155 12.689 5.32 4.14 10.395 7.796 15.515 11.177a151.347 151.347 0 0016.941 9.813c.575.274 1.172.523 1.826.76a19.771 19.771 0 004.582 2.345c3.034 1.07 6.079 2.32 9.061 3.543 1.308.536 2.615 1.073 3.928 1.597a82.918 82.918 0 0013.655 4.094c.747.15 1.492.311 2.236.472 1.539.333 3.13.677 4.74.925l3.509.606c.797.134 1.619.242 2.342.337l.213.028c.34.045.681.09 1.03.14 1.724.228 3.555.433 5.443.611l1.608.15-.257-1.596a24.96 24.96 0 012.127-12.633 23.109 23.109 0 0110.408-11.247l1.967-.877-1.733-1.272z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7161"
                x1="455.608"
                x2="634.524"
                y1="209.711"
                y2="316.409"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7161"
                x1="443.62"
                x2="622.535"
                y1="229.805"
                y2="336.504"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
