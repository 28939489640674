import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-down">
        <path
            fill="url(#man-34-25-doctors-coat-arm-left-down-a)"
            d="M499.857 185.9c-9.2-7.1-19.999-12.7-32.599-17.2l19.099 31.5c.1 0 18-10.9 13.5-14.3Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M480.156 173.9c7.2 3.3 13.701 7 19.801 11.4"
        />
        <path
            fill="url(#man-34-25-doctors-coat-arm-left-down-b)"
            d="M588.156 375.8c-18.6-32.6-39.499-62.2-49.899-77.4-.8-7.3-1.9-14.5-3.2-21.7-7.6-40.5-14.1-50.8-22.3-74.2-5.7-16.2-23.4-24.7-39.5-19-16.2 5.7-24.7 23.4-19 39.5 7.6 21.6 9.1 34.9 28.6 71.8 4.1 7.7 8.399 15.3 13.299 22.6 1.1 1.6 2.3 2.9 3.5 4.1 8.9 12 31.401 46.3 56.901 76.7l31.599-22.4Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M537.759 294.5c-.7-6-1.7-11.9-2.8-17.8-7.6-40.5-12.801-51.5-22.301-74.2-4.5-10.8-9.599-14-9.599-14"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M496.157 317.5c-5-7.4-9.2-15-13.3-22.6-19.5-36.9-20.999-50.2-28.599-71.8m83.598 74.8c10.3 15 31.401 44.9 50.201 77.8l-31.5 22.6c-25.9-30.8-50.9-67.5-59.6-79.1"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-down-a"
                x1={526.009}
                x2={476.996}
                y1={296.509}
                y2={158.148}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.203} stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-down-b"
                x1={551.962}
                x2={506.42}
                y1={550.492}
                y2={183.553}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.203} stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
