import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={178}
            height={137}
            fill="none"
            id="object-food-rice"
            {...attributes}
        >
            <g
                stroke="#E4002B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={4}
                clipPath="url(#object-food-rice-a)"
            >
                <path
                    d="M15.05 60.755 25.807 50M34.672 60.755 45.427 50M54.295 60.755 65.05 50M73.918 60.755 84.673 50M93.54 60.755 104.293 50M113.162 60.755 123.917 50M132.785 60.755 143.54 50M152.406 60.755 163.161 50M34.672 44.759l10.755-10.755M54.295 44.759 65.05 34.004M73.918 44.759l10.755-10.755M93.54 44.759l10.754-10.755M113.162 44.759l10.755-10.755M132.785 44.759l10.755-10.755M54.295 28.763 65.05 18.008M73.918 28.763l10.755-10.755M93.54 28.763l10.754-10.755M113.162 28.763l10.755-10.755M73.918 12.767 84.673 2.012M93.54 12.767l10.754-10.755"
                />
            </g>
            <path
                fill="#fff"
                d="M176.521 58.736c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-52.55c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51-2.12-2.71-4.03-5.43-5.77-8.14a107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h174.82Z"
            />
            <path
                fill="url(#object-food-rice-b)"
                d="M176.522 58.736c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-21.58c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51-2.12-2.71-4.03-5.43-5.77-8.14-1.29-2.02-2.48-4.02-3.57-6-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h143.85Z"
            />
            <path
                fill="#EF0E2E"
                d="M172.001 74.086c-1.78 4.83-4.12 10.18-7.19 15.73h-151.4c-3.07-5.55-5.41-10.9-7.19-15.73H172Z"
            />
            <path
                fill="#64CCC9"
                d="M161.23 95.816a104.32 104.32 0 0 1-5.77 8.14H22.75c-2.12-2.71-4.03-5.43-5.77-8.14h144.25Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M142.786 117.469c23.592-20.994 31.168-47.195 33.734-58.729H1.7C4.263 70.274 11.84 96.475 35.432 117.47c9.672 8.607 19.484 14.069 27.403 17.566h52.547a106.408 106.408 0 0 0 15.546-8.464"
            />
            <defs>
                <linearGradient
                    id="object-food-rice-b"
                    x1={32.672}
                    x2={176.522}
                    y1={96.886}
                    y2={96.886}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <clipPath id="object-food-rice-a">
                    <path fill="#fff" d="M13.05.012h152.112v62.744H13.05z" />
                </clipPath>
            </defs>
        </svg>
    );
}
  
