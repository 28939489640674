import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6809)"
            d="M523.715 241.715a252.018 252.018 0 00-11.039-7.94c-6.217-4.042-12.51-7.919-18.645-12.184-30.738-19.738-60.63 23.426-31.286 45.234 6.151 4.232 12.001 8.756 17.973 13.145 16.778-16.556 27.332-26.966 42.997-38.255z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6809)"
            d="M476.143 208.633a110.644 110.644 0 0118.627 14.398 2.713 2.713 0 01.822 2.483c-.805 4.666-13.808 10.892-16.407 12.093 0 0-14.647 1.88-13.641.722 11.756-13.547 12.47-21.795 10.599-29.696z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6809"
                x1="501.861"
                x2="455.596"
                y1="224.33"
                y2="264.811"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6809"
                x1="491.695"
                x2="445.43"
                y1="212.712"
                y2="253.194"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
