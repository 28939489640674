import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6370)"
            d="M541.444 345.689c-1.63-18.895-9.21-33.782-11.622-39.583l-.101-.247a50.974 50.974 0 01-3.99-19.385 40.25 40.25 0 011.891-11.779c1.718-5.35 1.36-12.27-.786-20.088-5.893-21.468-25.639-63.097-52.806-82.95-2.274-1.662-6.155-4.212-8.432-5.505.09 1.419 2.621 22.546 1.141 28.064l-24.451-18.732a100.675 100.675 0 01-32.259-21.687s-23.91 7.699-49.61 27.929c-12.029 9.47-18.829 24.613-18.456 40.175l.457 19.058a327.337 327.337 0 0010.716 77.382c.032 3.805.033 7.485.035 11.171.008 20.126 5.642 85.327 5.642 85.327s79.189-6.054 107.73-6.62c29.499 2.315 54.91-.225 64.841-14.609 0-.001 12.474-19.943 10.06-47.921z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M468.731 198.184c1.83 1.83 7.27 12.143 12.989 23.563M410.083 154.012c6.994 8.078 22.303 18.188 32.228 21.614l24.553 18.979 8-19.516 7.309 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M442.311 175.629l-9.445 13.074-20.923-22.629M488.332 231.367l9.181 25.768c2.586 7.257-.738 18.575.208 26.221 0 0 19.518 39.614 13.012 99.181M351.286 192.418a56.835 56.835 0 019.292-9.987c19.248-16.51 43.462-25.372 46.883-26.353M541.229 362.312c.618-5.521.69-11.089.215-16.624-1.63-18.895-9.21-33.783-11.622-39.583l-.101-.247a50.974 50.974 0 01-3.99-19.385"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M525.731 286.475a40.25 40.25 0 011.891-11.779c1.718-5.35 1.36-12.27-.786-20.088-2.88-10.492-9.07-25.801-17.818-41.03M341.963 221.898l.457 19.059a327.389 327.389 0 0010.716 77.382c.032 3.805.033 7.485.035 11.171.008 20.126 5.642 85.327 5.642 85.327"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6370"
                x1="533.671"
                x2="253.912"
                y1="342.189"
                y2="223.129"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
