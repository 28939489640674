import React, { createContext, Fragment, useState } from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { TGender, TModal, TPageContext, TSettings } from 'interfaces';

import Header from 'components/Header';
import CharacterBuilder from 'components/CharacterBuilder';
import ObjectBuilder from 'components/ObjectBuilder';
import SceneBuilder from 'components/SceneBuilder';
import ModalContainer from 'components/Modal';

import LoginPage from 'LoginPage';
import { useAuth } from 'utils/useAuth';

import DoctorsOffice from 'assets/scenes/scenes-office-4';

const DEFAULT_PAGE: TPageContext = {
    page: 'builder',
    tab: 'Character',
};

const ROUTE_MAP = {
    newChar: '',
    character: 'character',
    objects: 'objects',
    scenes: 'scenes'
};

const DEFAULT_CHAR_SETTINGS: TSettings = {
    gender: TGender.female,
};

export const PageContext = createContext({
    content: DEFAULT_PAGE,
    setContent: (i: TPageContext): void => {i;},
    showModal: '',
    setModal: (i?: TModal) : void => {i;},
    baseSettings: DEFAULT_CHAR_SETTINGS,
});

const Layout = () => {
    return <Fragment>
        <Header/>
        <Outlet />
    </Fragment>;
};

function NewCharPage({ setModal }: { setModal: () => void; }) {
    return <div className="initial-page">
        <DoctorsOffice width="80%" height="80%" />
        <button className="button-blue" onClick={setModal}>Create New Character</button>
    </div>;
}

export default function App() {
    const { user } = useAuth();
    const [baseSettings, setBaseSettings] = useState<TSettings>(DEFAULT_CHAR_SETTINGS);
    const [page, setPage] = useState<TPageContext>(DEFAULT_PAGE);
    const [showModal, setShowModal] = useState<TModal>('');

    function setModal(modal?: TModal) {
        const root = document.body as HTMLElement;
        setShowModal(modal || '');
        root.classList[modal ? 'add' : 'remove']('scroll-lock');
    }

    const contextValue: {
        content: TPageContext;
        setContent: (i: TPageContext) => void;
        showModal: TModal;
        setModal: (i?: TModal) => void;
        baseSettings: TSettings;
    } = {
        content: page,
        setContent: setPage,
        showModal,
        setModal,
        baseSettings,
    };

    if (!user) {
        return <LoginPage/>;
    }

    return <PageContext.Provider value={contextValue}>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route path="/" element={<NewCharPage setModal={() => setModal('create')} />} />
                    <Route path={ROUTE_MAP.character} element={<CharacterBuilder />}/>
                    <Route path={ROUTE_MAP.objects} element={<ObjectBuilder />}/>
                    <Route path={ROUTE_MAP.scenes} element={<SceneBuilder />}/>
                </Route>
            </Routes>
            <ModalContainer setBaseSettings={setBaseSettings}/>
        </BrowserRouter>
    </PageContext.Provider>;
}
