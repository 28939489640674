import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11279_6803)"
            d="M507.238 514.244c.126-5.601-.149-14.389.143-19.174-11.999.029-93.578.018-114.578.018-28.918-5.248-53.675-32.892-47.025-66.289 7.189-36.098 45.983-45.884 80.82-39.723 18.96 3.353 38.858 7.805 56.494 12.687 17.101 4.96 36.53 13.61 52.518 22.337 14.97 7.682 29.55 19.158 30.675 38.023 1.648 43.349 3.585 93.225 4.788 136.341l-48.206 3.094s-16.467-50.174-15.629-87.314z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11279_6803"
                x1="569.586"
                x2="357.077"
                y1="427.631"
                y2="576.387"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
