import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={25}
            height={25}
            fill="none"
            id="object-devices-sensor"
            {...attributes}
        >
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12.773 23.248c6.076 0 11-4.788 11-10.694 0-5.906-4.924-10.695-11-10.695-6.075 0-11 4.789-11 10.695 0 5.906 4.925 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M12.773 14.86c1.27 0 2.3-1.034 2.3-2.309a2.304 2.304 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.31a2.304 2.304 0 0 0 2.3 2.308Z"
            />
        </svg>
    );
}
