import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7017)"
            d="M478.223 236.781c-.166-4.363-.928-19.193-3.775-26.828l-1.016-2.726 2.477 1.525a102.53 102.53 0 0118.859 14.537 2.713 2.713 0 01.822 2.482c-.788 4.563-13.42 10.695-15.944 11.877l-1.367.641-.056-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7017)"
            d="M557.982 309.035c-.531 0-1.068-.021-1.606-.065a19.837 19.837 0 01-3.843-.705 20.398 20.398 0 01-3.396-.509 213.387 213.387 0 01-22.257-6.933c-6.887-2.522-13.84-5.341-21.261-8.619a169.574 169.574 0 01-20.318-10.417 701.486 701.486 0 01-5.833-3.535c-4.44-2.704-9.03-5.501-13.634-8.072a27.53 27.53 0 01-10.605-37.417 27.55 27.55 0 0124.036-14.099 27.512 27.512 0 0113.382 3.494l.124.071c4.583 2.59 9.352 5.05 13.964 7.43 2.029 1.048 4.055 2.1 6.078 3.155a169.564 169.564 0 0119.472 11.937 374.142 374.142 0 0110.071 7.259c.245-1.161.492-2.264.78-3.371l.933-3.848c.209-.852.447-1.697.685-2.544.125-.444.248-.889.37-1.334.92-3.278 1.745-5.968 2.6-8.473l.699-2.052 1.108 1.864c3.531 5.952 10.829 8.072 16.48 8.072 4.518 0 8.547-1.3 10.264-3.311l1.441-1.689.31 2.198c.262 1.865.51 3.902.755 6.228.045.475.094.939.143 1.404.092.868.183 1.737.246 2.603l.276 3.969c.153 1.792.211 3.607.265 5.364.028.857.055 1.715.093 2.574.203 5.3-.002 10.607-.612 15.875-.186 1.568-.384 3.134-.582 4.7-.451 3.564-.917 7.251-1.213 10.879a19.599 19.599 0 01-19.415 17.917z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7017"
                x1="487.395"
                x2="466.899"
                y1="205.529"
                y2="283.632"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7017"
                x1="451.745"
                x2="579.878"
                y1="263.85"
                y2="263.85"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
