import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1473}
            viewBox="0 0 2571 1473"
            fill="none"
            id="scenes-office-2"
            {...attributes}
        >
            <path fill="#fff" d="M.05.602h2570v1472H.05z" />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M308.285 372.045v-28.233h61.598v61.599h-61.598v-18.083M388.723 466.506v-6.24"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M308.283 372.049h-40.92v66.69h40.92v-14.48h23.92v110.94c0 5.21 4.22 9.42 9.42 9.42 2.6 0 4.95-1.05 6.66-2.76a9.361 9.361 0 0 0 2.76-6.66v-170.73h37.68v87.251M395.124 474.458l1.85 5.21c1.66 4.67 1.6 9.84-.48 14.34-1.8 3.86-4.61 6.34-7.77 6.34-3.16 0-5.97-2.48-7.76-6.34-2.09-4.5-2.15-9.67-.49-14.34l3.43-9.63c.6-1.68 1.97-3.03 3.72-3.41.37-.08.73-.12 1.1-.12M395.122 474.453H389"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M388.723 460.268h6.4v-8.549h-6.4M339.09 352.133v6.347M323.195 358.719l4.488 4.488M316.609 374.609h6.347M323.195 390.511l4.488-4.488M339.09 397.089v-6.347M607.862 480.12h123.284v-28.221H596.59v-18.841h134.556v-28.222H596.59"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M615.43 421.171v-35.093h-75.285v94.042h67.716M643.691 376.654l2-16.842h14.841l2 16.842a9.42 9.42 0 0 1-9.42 9.42M699.594 386.076h9.42v-15.463l12.127-8.001v-2.8h-35.598l4.63 10.233v10.077M634.27 480.12v39.59a9.42 9.42 0 0 0 18.84 0V386.078M699.594 386.078V519.71a9.42 9.42 0 0 1-18.84 0v-39.59"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M572.514 453.625h-16.295v10.421h16.295v-10.421ZM982.069 447.495v-56.683H873.145v56.683h34.694v-37.841h55.389v18.84h-70.042v18.842M934.562 478.844h-12.71M963.233 447.5v31.35h-12.71"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M893.184 447.336v31.51h12.71"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m921.851 478.844-5.26 17.46h-5.43l-5.27-17.46M950.523 478.844l-5.27 17.46h-5.43l-5.261-17.46"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M790.125 756.234H1945.12l55.5 40.5H998.729v19.5H2009.62v28.5h-168v105M1861.13 870.234v40.5M1825.13 870.234v40.5M1219.84 624.439h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1480.54 278.086v373.95h-336.3v-364.05h305.55v336.45h-178.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1270.84 624.733 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1858.05 287.133v31.913l-15.12 15.114M1858.05 244.43v14.489M1933.11 319.047h-14.49M1858.05 394.223v-14.489M1782.99 319.047h14.49"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1920.32 344.793c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <g
                stroke="#FFD101"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#a)"
            >
                <path 
                    d="M2054.24 653.325v-18.841h-18.85v18.841h18.85ZM2133.76 653.325v-18.841h-18.84v18.841h18.84ZM2033.65 1125.09h-40.81c-10.4 0-18.84 8.43-18.84 18.84h176.34c0-10.41-8.43-18.84-18.84-18.84h-53.23M2035.39 643.898h-44.53M1974 633.898v-11.96c0-5.531 4.48-10 10-10h26.67M2114.93 643.898h-18.84" />
                <path 
                    d="M2028.09 611.938h112.26c5.52 0 10 4.469 10 10v11.96c0 5.53-4.48 10-10 10h-6.58M1990.86 643.898v18.843M1974 662.744v-40.806c0-5.523 4.48-10 10-10h6.86M2010.68 611.94v-5.768h17.4v5.768" />
                <path 
                    d="M2054.23 643.901h19.15v-7.917a7.485 7.485 0 0 1 7.49-7.484h7.73c4.13 0 7.48 3.351 7.48 7.484v19.607h-12.79v-11.69h12.79M2078.27 1125.09l-32.19-.02 7.42-424.664M2077.61 1087.21l-6.76-386.804" />
                <path 
                    d="M1974 662.742v.84c0 11.2 7.77 20.9 18.71 23.32l60.79 13.51M1990.86 662.742h158.62c0 11.05-7.58 20.66-18.32 23.23l-60.31 14.44" />
            </g>
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M215.051 1170H2356.05"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1179.13 951.93-51.54 193h14l60-176h79.5l6.5 176h14l6.5-176h110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1268.07 951.93-22.48 193h-14l8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1410.88 939.244c-5.46-6.953-13.89-6.311-38.74-6.311-18.6 0-132.66.36-197.91.648-18.81.083-33.98-15.38-33.53-34.185l1.86-78.347c.64-26.787 21.22-48.985 47.92-51.334 10.42-.917 22-1.346 34.11-.782a240.3 240.3 0 0 1 40.63 5.39 52.837 52.837 0 0 1 39.3 36.775l46.07 157.835 54 175.997h14l-37.73-161.939M1239.81 951.93h-60.68M1418.59 951.93h-150.52"
            />
            <path
                fill="url(#b)"
                d="M420.342 878.191h389.353v274.739H420.342V878.191Z"
            />
            <path
                fill="url(#c)"
                d="M666.164 845.933a80.49 80.49 0 0 1-68.888-38.859l-99.723-166.228a33.697 33.697 0 0 0-28.839-16.268H574.6a78.532 78.532 0 0 1 67.212 37.914l110.86 183.441h-86.508Z"
            />
            <path
                fill="url(#d)"
                d="M914.612 878.191h60.333c0-17.815-14.442-32.257-32.257-32.257H666.161a80.49 80.49 0 0 1-68.888-38.859L497.55 640.846a33.697 33.697 0 0 0-28.839-16.268h-.142c-8.05 0-13.012 8.795-8.848 15.685l119.836 198.294a82.095 82.095 0 0 0 70.261 39.633h264.794v.001Z"
            />
            <path
                fill="url(#e)"
                d="M809.696 878.191h144.152v178.159H809.696V878.191Z"
            />
            <path
                fill="#E2E2E2"
                d="M809.696 910.445h144.152v72.949H809.696v-72.949ZM809.696 983.398h144.152v72.952H809.696v-72.952Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M908.297 930.797h-53.053M908.297 1003.74h-53.053"
            />
            <path fill="url(#f)" d="M420.343 910.445h218.274v72.949H420.343v-72.949Z" />
            <path fill="url(#g)" d="M420.343 983.398h218.274v72.952H420.343v-72.952Z" />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M556.004 930.797h-53.053M556.004 1003.74h-53.053"
            />
            <path fill="#E2E2E2" d="M809.694 1056.34h222.216v70.82H809.694v-70.82Z" />
            <path
                fill="#888B8D"
                d="M1031.91 1152.93h-23.35l-8-25.77h31.35v25.77ZM911.105 1152.93h-23.347v-25.77h31.347l-8 25.77Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m752.672 845.933-110.86-183.441a78.534 78.534 0 0 0-67.213-37.914H468.713"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M468.711 624.578h-.142c-8.05 0-13.012 8.795-8.848 15.685l119.836 198.294a82.095 82.095 0 0 0 70.261 39.633h325.127c0-17.815-14.442-32.257-32.257-32.257H666.161a80.49 80.49 0 0 1-68.888-38.859L518.076 675.06"
            />
            <path fill="url(#h)" d="M809.695 1056.34h78.063v70.82h-78.063v-70.82Z" />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M953.848 983.398H809.695M1031.91 1056.34H809.694M420.343 983.394h218.274v-72.949M610.695 910.445H420.343M420.343 983.398h218.274v72.952H420.343"
            />
            <path
                fill="#FCB47F"
                d="M1053.56 364.881c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3-.5 4.4-.5 5.1l-7.3 13.4-47.504-16s4.1-17.1 4.1-23.7c0-9.5-7-19.2-9.5-26.8-8.1-25 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.504-9.6 41.104-2.6 56.704 13Z"
            />
            <path
                fill="url(#i)"
                d="M1036.26 455.378c-14.5 1.2-30.2-11.6-36.602-18.4-1.2-1.3 9.802 29.4 10.302 31.2.6 1.7 23.6 6.5 24.6 8 4.1 5.8 2.1-12 1.7-20.8Z"
            />
            <path
                fill="url(#j)"
                d="M1060.46 389.179c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.8.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.2 1.1-55.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M999.657 436.279c9.303 11.5 23.703 18.3 38.403 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.103-12.6-69.003-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 1.7 3.7 4.1 16.1-1.4 32.4"
            />
            <path
                stroke="url(#k)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1061.76 390.979c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#l)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1039.46 388.879c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1056.36 406.178c-.78 0-1.4-1.03-1.4-2.3 0-1.27.62-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3ZM1030.76 406.178c-.78 0-1.4-1.03-1.4-2.3 0-1.27.62-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1051.76 430.279c-7.6 1.2-15.4.5-22.7-1.9"
            />
            <path
                fill="#FCB47F"
                d="M1045.16 422.279c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1045.16 422.279c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                fill="#A0A4AC"
                d="M977.858 367.277c.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.702.1 12.302 1.9 15.302 6.5 1 2.4 1.6 4.8 1.1 7.5-.5 2.5-1.7 4.8-2.8 7.2-1.2 2.6-2.3 8.9-1.8 16.3h-5.3s-.7-3.1-5.502-7c-4.8-3.8-8.9-3.7-11.6-.9-3.8 3.9-1.5 10.9.1 14.7 2.2 5.1 4.6 7.6 9.1 11-.2 3.5-.6 6.8-1.2 10.1-.7 3.8-5.8 4.5-7.5 1.1-3.3-6.3-6.5-12.6-9.7-19.1-4.6-9.6-9.2-20-8.6-30.7.6-11.1 5.5-17.5 10.9-20.3Z"
            />
            <path
                stroke="#838891"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M975.556 418.18c-4.6-9.6-9.2-20-8.6-30.7.6-11.1 5.5-17.5 10.9-20.3.5 1 .4 1 .9 1.3 4.9 3.4 11 2.2 16.6 2.3 4.704.1 12.304 1.9 15.304 6.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M998.555 403.279c-1.9-1.6-3.801-3.3-6.201-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M1083.92 502.161c2.81 8.111 6.02 16.078 9.06 24.107a201.648 201.648 0 0 1 7.6 24.616c2.1 8.358 3.98 16.79 5.58 25.322a255.79 255.79 0 0 1 3.62 26.002 23.266 23.266 0 0 1-30.55 23.885 23.296 23.296 0 0 1-11.95-9.08 251.11 251.11 0 0 1-13.31-22.623c-4.06-7.676-7.82-15.453-11.37-23.303a202.47 202.47 0 0 1-9.34-24.01c-2.61-8.179-5.04-16.42-7.88-24.518a31.053 31.053 0 0 1-1.71-11.975 31.154 31.154 0 0 1 3.01-11.715 30.994 30.994 0 0 1 7.26-9.673 31.009 31.009 0 0 1 10.42-6.156 30.99 30.99 0 0 1 33.36 8.568 30.93 30.93 0 0 1 6.15 10.411l.05.142Z"
            />
            <path
                fill="#FCB47F"
                d="m1107.21 594.701 6.69 9.954 6.74 9.917c4.47 6.626 8.72 13.405 12.86 20.271 2.03 3.453 4.12 6.871 6.11 10.361 2.01 3.47 4.02 6.946 5.94 10.48 1.94 3.524 3.91 7.02 5.82 10.568 1.85 3.587 3.78 7.115 5.54 10.762a15.76 15.76 0 0 1 1.08 10.764 15.761 15.761 0 0 1-6.14 8.908 15.728 15.728 0 0 1-10.44 2.829 15.713 15.713 0 0 1-9.8-4.589c-2.85-2.877-5.54-5.87-8.31-8.806-2.71-2.974-5.35-6.001-8.03-8.998-2.7-2.989-5.31-6.035-7.91-9.088-2.63-3.032-5.16-6.137-7.74-9.206-5.11-6.178-10.09-12.444-14.85-18.863l-7.12-9.65-7.16-9.613a22.53 22.53 0 0 1-3.78-7.929 22.538 22.538 0 0 1-.45-8.77 22.486 22.486 0 0 1 8.82-14.797 22.499 22.499 0 0 1 32.13 5.495Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1083.88 502.02.04.141c2.81 8.111 6.02 16.079 9.06 24.108a203.445 203.445 0 0 1 7.61 24.615c2.09 8.359 3.97 16.79 5.57 25.323.71 3.891 2.08 12.862 2.67 16.798"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1067.28 617.013a253.609 253.609 0 0 1-13.32-22.624c-4.05-7.676-7.81-15.453-11.36-23.303a202.402 202.402 0 0 1-9.34-24.01c-2.61-8.179-5.04-16.42-7.88-24.517"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1131.61 694.927c-2.85-2.877-5.54-5.87-8.31-8.806-2.71-2.974-5.35-6.001-8.03-8.999-2.7-2.988-5.31-6.034-7.91-9.087-2.64-3.033-5.17-6.137-7.75-9.206-5.1-6.178-10.08-12.444-14.84-18.863l-7.12-9.65-7.17-9.613M1109.39 597.953l4.51 6.705 6.74 9.918c4.47 6.625 8.72 13.404 12.85 20.271 2.04 3.453 4.13 6.871 6.11 10.36 2.02 3.47 4.02 6.946 5.95 10.48 1.93 3.525 3.91 7.02 5.81 10.569 1.85 3.587 3.78 7.115 5.55 10.761"
            />
            <path
                fill="#FCB47F"
                d="M1157.18 677.758a312.88 312.88 0 0 1 12.1 37.552c2.22 9.558 2.78 19.638.05 29.12-1.48 5.135-4.43 12.659-4.05 18.329.02.276-.02.554-.1.817-.09.262-.23.504-.42.71l-.09.102a1.982 1.982 0 0 1-1.54.628 1.94 1.94 0 0 1-.81-.222 1.874 1.874 0 0 1-.65-.546c-2-2.741-3.23-5.958-3.59-9.328l-.66-1.327c-2.55 1.31-7.28 1.055-11.09-1.081a6.64 6.64 0 0 1-2.04-1.811 6.719 6.719 0 0 1-1.15-2.481 6.67 6.67 0 0 1 .33-4.114 6.712 6.712 0 0 1 2.67-3.145l2.19-1.354a2.128 2.128 0 0 1 1.61-.254c1.81.476 3.69.654 5.56.529-1.55-5.475-4.21-10.557-7.77-13.67 0 0-.43-.818-.55-.263-.61 2.786-1.32 5.581-3.04 7.899-2.06 2.78-6.64 4.499-9.86 3.025l-.82-1.522c3.17-2.927 4.36-7.315 4.32-11.473a52.9 52.9 0 0 0-1.94-12.308c-1.33-5.333-2.08-14.484-1.08-18.742"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1150.95 718.078a29.71 29.71 0 0 1 8.12 19.737c.08 4.627-.96 9.22-1.09 13.847-.13 4.627.81 9.564 4.17 12.915M1165.06 760.681c.09-5.589 2.71-10.844 4.26-16.25 2.73-9.482 2.17-19.562-.05-29.12-2.22-9.559-5.07-20.044-8.89-29.135M1134.3 698.828c-.68 5.086.76 8.655 1.54 12.743.78 4.087 1.89 8.15 1.93 12.308.04 4.158-1.14 8.545-4.31 11.472M1134.8 736.991c3.22 1.474 7.28-.362 9.34-3.142 2.06-2.78 2.67-6.246 3.39-9.56.72-3.314 1.72-6.781 4.29-9.152"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1155.26 737.759c-1.39-4.474-4-8.472-7.53-11.548M1145.88 752.023c3.35 2.264 8.35 3.209 11.54 1.568M1144.15 745.066c3.14 2.537 10.77 3.112 14.33 1.08M1149.08 739.143c3.69 1.233 6.37 1.393 9.99-.069"
            />
            <path
                fill="#FCB47F"
                d="M1071.06 485.376c-9.22-7.122-19.95-12.655-32.55-17.169l19.12 31.544s17.87-10.95 13.43-14.375Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1038.51 468.207a129.741 129.741 0 0 1 32.55 17.169"
            />
            <path
                fill="url(#m)"
                d="M1071.06 485.376c-9.22-7.122-19.95-12.655-32.55-17.169l19.12 31.543s17.87-10.949 13.43-14.374Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1051.29 473.359a115.181 115.181 0 0 1 19.77 11.352"
            />
            <path
                fill="url(#n)"
                d="M1159.29 675.273c-18.64-32.603-39.54-62.24-49.91-77.384-.75-7.34-1.91-14.538-3.22-21.683-7.57-40.506-14.06-50.753-22.29-74.187a30.976 30.976 0 0 0-15.82-17.674 30.999 30.999 0 0 0-23.69-1.305 31 31 0 0 0-17.68 15.829 30.967 30.967 0 0 0-1.3 23.69c7.59 21.616 9.09 34.916 28.59 71.831a250.972 250.972 0 0 0 13.31 22.623 25.058 25.058 0 0 0 3.54 4.15c8.86 12.05 31.37 46.294 56.95 76.672l31.52-22.562Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1108.95 593.994c-.73-5.998-1.71-11.909-2.79-17.786-7.57-40.506-12.78-51.515-22.29-74.187-4.5-10.751-9.63-13.99-9.63-13.99"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1067.28 617.013a250.988 250.988 0 0 1-13.31-22.624c-19.5-36.915-21.01-50.215-28.59-71.83M1109.07 597.434c10.26 15.002 31.4 44.913 50.22 77.839l-31.52 22.562c-25.9-30.759-50.93-67.5-59.56-79.134"
            />
            <path
                fill="url(#o)"
                d="m1109.4 632.473-170.791 18.58v244.962h177.281V702.15l-6.49-69.677Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1115.89 723.43v172.585h-96.4"
            />
            <path
                fill="#FCB47F"
                d="M1028.34 1100.83c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.31 3.16-29.967 7.11-44.517 2.06-7.314 4.52-14.51 7.36-21.557.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269 1.74-3.575 3.7-7.04 5.86-10.376l6.93-10.585a26.096 26.096 0 0 1 45.24 2.748 26.15 26.15 0 0 1 1.92 17.824l-10.88 43.826-5.44 21.91-5.59 21.888c-3.75 14.58-7.4 29.19-11.23 43.76l-11.4 43.73c-.95 3.65-3.18 6.85-6.27 9.02-3.1 2.17-6.86 3.16-10.63 2.81-3.76-.36-7.27-2.04-9.91-4.75a15.918 15.918 0 0 1-4.47-10.04l-.19-2.85Z"
            />
            <path
                fill="#FCB47F"
                d="M1048.63 930.643c-5.12-15.079-9.8-30.224-14.66-45.342l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.685c-4.71-15.14-8.41-30.431-12.02-45.735a764.466 764.466 0 0 1-5.02-23.014l-1.16-5.768-1.05-5.785c-.695-3.857-1.416-7.71-1.979-11.586a50.883 50.883 0 0 1 8.779-36.645 50.896 50.896 0 0 1 31.58-20.561 50.88 50.88 0 0 1 37.06 6.798 50.926 50.926 0 0 1 22.23 30.43l1.24 4.94c.96 3.817 1.82 7.649 2.58 11.496l1.13 5.773 1 5.792c.7 3.856 1.22 7.738 1.81 11.611.62 3.869 1.05 7.765 1.55 11.651 1.85 15.566 3.4 31.176 3.96 46.935.29 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.57 11.967a393.897 393.897 0 0 1-5.35 48.322l-.79 4.347a28.766 28.766 0 0 1-9.1 16.236 28.784 28.784 0 0 1-17.13 7.28 28.812 28.812 0 0 1-18-4.716 28.802 28.802 0 0 1-11.35-14.743Z"
            />
            <path
                fill="#FCB47F"
                d="M1048.63 930.643c-5.12-15.079-9.8-30.224-14.66-45.342l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.685c-4.71-15.14-8.41-30.431-12.02-45.735a764.466 764.466 0 0 1-5.02-23.014l-1.16-5.768-1.05-5.785c-.695-3.857-1.416-7.71-1.979-11.586a50.883 50.883 0 0 1 8.779-36.645 50.896 50.896 0 0 1 31.58-20.561 50.88 50.88 0 0 1 37.06 6.798 50.926 50.926 0 0 1 22.23 30.43l1.24 4.94c.96 3.817 1.82 7.649 2.58 11.496l1.13 5.773 1 5.792c.7 3.856 1.22 7.738 1.81 11.611.62 3.869 1.05 7.765 1.55 11.651 1.85 15.566 3.4 31.176 3.96 46.935.29 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.57 11.967a393.897 393.897 0 0 1-5.35 48.322l-.79 4.347a28.766 28.766 0 0 1-9.1 16.236 28.784 28.784 0 0 1-17.13 7.28 28.812 28.812 0 0 1-18-4.716 28.802 28.802 0 0 1-11.35-14.743Z"
                opacity={0.73}
            />
            <path
                fill="#FCB47F"
                d="m1028.53 1103.68-.19-2.85c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.311 3.16-29.968 7.11-44.517a209.88 209.88 0 0 1 7.36-21.558c.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1028.53 1103.68-.19-2.85c-.5-7.74-.87-15.44-1.09-23.12-.11-3.84-.31-7.7-.35-11.53l-.19-11.49c-.15-7.67-.17-15.31-.56-23.02-.32-7.7-1-15.46-.97-23.1-.2-15.311 3.16-29.968 7.11-44.517a209.88 209.88 0 0 1 7.36-21.558c.7-1.781 1.51-3.541 2.26-5.312.82-1.758 1.6-3.524 2.49-5.269"
            />
            <path
                fill="#FCB47F"
                d="m1104.35 931.523-10.87 43.826-5.45 21.911-5.59 21.89c-3.75 14.58-7.4 29.19-11.22 43.76l-11.41 43.72"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1104.35 931.523-10.87 43.826-5.45 21.911-5.59 21.89c-3.75 14.58-7.4 29.19-11.22 43.76l-11.41 43.72"
            />
            <path
                fill="#FCB47F"
                d="M1093.15 715.832c2.36 5.6 4.41 11.328 6.12 17.157 1.12 3.775 1.83 7.649 2.59 11.496l1.13 5.773.99 5.792c.71 3.856 1.23 7.738 1.82 11.611.61 3.869 1.05 7.765 1.54 11.651 1.86 15.566 3.41 31.176 3.97 46.935.28 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.58 11.967-.94 15.983-2.99 41.575-5.99 57.608"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1093.15 715.832c2.36 5.6 4.41 11.328 6.12 17.157 1.12 3.775 1.83 7.649 2.59 11.496l1.13 5.773.99 5.792c.71 3.856 1.23 7.738 1.82 11.611.61 3.869 1.05 7.765 1.54 11.651 1.86 15.566 3.41 31.176 3.97 46.935.28 7.879.22 15.81-.02 23.766l-.37 11.937c-.15 3.983-.39 7.978-.58 11.967-.94 15.983-2.99 41.575-5.99 57.608"
            />
            <path
                fill="#FCB47F"
                d="M1048.63 930.643c-5.12-15.079-9.8-30.223-14.66-45.341l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.686c-4.71-15.14-8.41-30.431-12.02-45.735a796 796 0 0 1-5.03-23.013l-1.15-5.768-1.05-5.785c-.695-3.857-1.416-7.71-1.979-11.587"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1048.63 930.643c-5.12-15.079-9.8-30.223-14.66-45.341l-7.21-22.688-3.62-11.342-1.81-5.671-1.71-5.686c-4.71-15.14-8.41-30.431-12.02-45.735a796 796 0 0 1-5.03-23.013l-1.15-5.768-1.05-5.785c-.695-3.857-1.416-7.71-1.979-11.587M1084.51 926.441H1100"
            />
            <path
                fill="#FCB47F"
                d="M1027.66 1098.2c-.39 4.32-4.63 20.35-7.47 30.74-.32 1.19-.4 2.42-.23 3.64.18 1.21.6 2.38 1.23 3.42a9.03 9.03 0 0 0 2.49 2.66c1 .71 2.14 1.2 3.34 1.45l19.89 4.2c4.25.88 8.33 2.41 12.11 4.53 6.09 3.37 12.65 5.8 19.45 7.23 4.01.87 19.04 1.81 26.25 2.23 1.06.06 2.1-.29 2.91-.97.81-.68 1.33-1.64 1.45-2.69a4.1 4.1 0 0 0-.38-2.29c-.34-.71-.88-1.31-1.55-1.73-2.85-1.77-7.9-4.87-12.02-7.19-18.46-10.39-35.66-38.52-35.66-38.52"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1027.66 1098.2c-.39 4.32-4.63 20.35-7.47 30.74-.32 1.19-.4 2.42-.23 3.64.18 1.21.6 2.38 1.23 3.42a9.03 9.03 0 0 0 2.49 2.66c1 .71 2.14 1.2 3.34 1.45l19.89 4.2c4.25.88 8.33 2.41 12.11 4.53 6.09 3.37 12.65 5.8 19.45 7.23 4.01.87 19.04 1.81 26.25 2.23 1.06.06 2.1-.29 2.91-.97.81-.68 1.33-1.64 1.45-2.69a4.1 4.1 0 0 0-.38-2.29c-.34-.71-.88-1.31-1.55-1.73-2.85-1.77-7.9-4.87-12.02-7.19-18.46-10.39-35.66-38.52-35.66-38.52"
            />
            <path
                fill="#FCB47F"
                d="M941.086 686.406c-12.574 21.71-20.877 32.255-16.555 68.991 3.318 28.203 88.979 13.576 105.249 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                fill="#FCB47F"
                d="M941.086 686.406c-12.574 21.71-20.877 32.255-16.555 68.991 3.318 28.203 88.979 13.576 105.249 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M941.086 686.406c-12.574 21.71-20.877 32.255-16.555 68.991 3.318 28.203 88.979 13.576 105.249 18.259 23.69 9.539 39.14-42.056 39.14-42.056s24.55-11.723 33.27-45.194"
            />
            <path
                fill="#FCB47F"
                d="M967.386 1117.37c.512 4.31-.328 20.87-.957 31.62a9.056 9.056 0 0 0 .527 3.61c.42 1.15 1.07 2.2 1.911 3.09.842.89 1.857 1.6 2.982 2.09 1.126.48 2.339.73 3.565.73h57.646c1.18 0 2.31-.46 3.14-1.3a4.41 4.41 0 0 0 1.31-3.14c0-.8-.22-1.59-.63-2.28a4.488 4.488 0 0 0-1.71-1.64c-3.8-2.03-11.23-6.09-14.96-8.59-8.49-5.72-20.308-24.19-20.308-24.19"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M967.386 1117.37c.512 4.31-.328 20.87-.957 31.62a9.056 9.056 0 0 0 .527 3.61c.42 1.15 1.07 2.2 1.911 3.09.842.89 1.857 1.6 2.982 2.09 1.126.48 2.339.73 3.565.73h57.646c1.18 0 2.31-.46 3.14-1.3a4.41 4.41 0 0 0 1.31-3.14c0-.8-.22-1.59-.63-2.28a4.488 4.488 0 0 0-1.71-1.64c-3.8-2.03-11.23-6.09-14.96-8.59-8.49-5.72-20.308-24.19-20.308-24.19"
            />
            <path
                fill="#FCB47F"
                d="M967.47 1117.37a715.863 715.863 0 0 1-5.275-23.87c-.807-3.97-1.701-7.95-2.437-11.93l-2.272-11.93c-1.543-7.96-2.948-15.91-4.734-23.87-1.714-7.95-3.79-15.91-5.144-23.87-2.977-15.91-2.331-31.817-1.092-47.728a233.638 233.638 0 0 1 3.324-23.867c.36-1.988.841-3.977 1.255-5.966.493-1.989.937-3.978 1.497-5.967a107.918 107.918 0 0 1 3.878-11.933l4.414-11.154a26.235 26.235 0 0 1 45.306-6.188 26.188 26.188 0 0 1 5.28 17.342l-2.74 47.733-1.37 23.866-1.52 23.862c-1.04 15.92-1.98 31.83-3.1 47.74l-3.27 47.73a15.902 15.902 0 0 1-4.543 10.08 15.947 15.947 0 0 1-10.014 4.7c-3.792.32-7.572-.73-10.657-2.96a15.941 15.941 0 0 1-6.161-9.19l-.625-2.63Z"
            />
            <path
                fill="#FCB47F"
                d="M955 927.747c-3.55-15.367-6.656-30.755-9.939-46.134l-4.855-23.073-2.435-11.535-1.221-5.768-1.123-5.772c-3.136-15.387-5.248-30.82-7.272-46.258a753.423 753.423 0 0 1-2.647-23.173l-.563-5.798-.447-5.804c-.299-3.869-.621-7.736-.785-11.612a50.884 50.884 0 0 1 45.946-52.791 50.883 50.883 0 0 1 55.201 43.019l.75 5.09c.56 3.856 1.03 7.717 1.39 11.583l.53 5.8.4 5.806c.31 3.868.43 7.745.62 11.619.21 3.873.25 7.754.34 11.632.26 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.44 23.408l-1.6 11.721c-.55 3.908-1.2 7.82-1.79 11.73a387.834 387.834 0 0 1-10.26 47.062l-1.26 4.279a28.798 28.798 0 0 1-45.881 14.125A28.794 28.794 0 0 1 955 927.747Z"
            />
            <path
                fill="#FCB47F"
                d="M955 927.747c-3.55-15.367-6.656-30.755-9.939-46.134l-4.855-23.073-2.435-11.535-1.221-5.768-1.123-5.772c-3.136-15.387-5.248-30.82-7.272-46.258a753.423 753.423 0 0 1-2.647-23.173l-.563-5.798-.447-5.804c-.299-3.869-.621-7.736-.785-11.612a50.884 50.884 0 0 1 45.946-52.791 50.883 50.883 0 0 1 55.201 43.019l.75 5.09c.56 3.856 1.03 7.717 1.39 11.583l.53 5.8.4 5.806c.31 3.868.43 7.745.62 11.619.21 3.873.25 7.754.34 11.632.26 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.44 23.408l-1.6 11.721c-.55 3.908-1.2 7.82-1.79 11.73a387.834 387.834 0 0 1-10.26 47.062l-1.26 4.279a28.798 28.798 0 0 1-45.881 14.125A28.794 28.794 0 0 1 955 927.747Z"
                opacity={0.73}
            />
            <path
                fill="#FCB47F"
                d="m968.096 1120.01-.626-2.64a715.306 715.306 0 0 1-5.275-23.86c-.807-3.98-1.701-7.96-2.437-11.94l-2.272-11.93c-1.543-7.96-2.948-15.91-4.734-23.87-1.714-7.95-3.79-15.91-5.144-23.86-2.977-15.91-2.331-31.825-1.092-47.736a233.658 233.658 0 0 1 3.324-23.866c.36-1.989.841-3.978 1.255-5.967.493-1.989.937-3.978 1.497-5.966.51-1.91 1.061-3.82 1.671-5.73"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m968.096 1120.01-.626-2.64a715.306 715.306 0 0 1-5.275-23.86c-.807-3.98-1.701-7.96-2.437-11.94l-2.272-11.93c-1.543-7.96-2.948-15.91-4.734-23.87-1.714-7.95-3.79-15.91-5.144-23.86-2.977-15.91-2.331-31.825-1.092-47.736a233.658 233.658 0 0 1 3.324-23.866c.36-1.989.841-3.978 1.255-5.967.493-1.989.937-3.978 1.497-5.966.51-1.91 1.061-3.82 1.671-5.73"
            />
            <path
                fill="#FCB47F"
                d="m1011.47 926.441-2.74 47.733-1.38 23.866-1.52 23.87c-1.03 15.91-1.97 31.82-3.09 47.73l-3.271 47.73"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1011.47 926.441-2.74 47.733-1.38 23.866-1.52 23.87c-1.03 15.91-1.97 31.82-3.09 47.73l-3.271 47.73"
            />
            <path
                fill="#FCB47F"
                d="m1024.86 733.047.74 5.09a227.23 227.23 0 0 1 1.4 11.584l.53 5.8.4 5.805c.3 3.869.43 7.746.61 11.62.22 3.872.26 7.753.35 11.631.25 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.45 23.408l-1.59 11.721c-.55 3.909-1.2 7.82-1.79 11.73a387.891 387.891 0 0 1-10.26 47.063"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1024.86 733.047.74 5.09a227.23 227.23 0 0 1 1.4 11.584l.53 5.8.4 5.805c.3 3.869.43 7.746.61 11.62.22 3.872.26 7.753.35 11.631.25 15.519.2 31.052-.86 46.631-.52 7.789-1.4 15.595-2.45 23.408l-1.59 11.721c-.55 3.909-1.2 7.82-1.79 11.73a387.891 387.891 0 0 1-10.26 47.063"
            />
            <path
                fill="#FCB47F"
                d="M954.998 927.749c-3.55-15.367-6.657-30.755-9.939-46.134l-4.855-23.073-2.436-11.535-1.221-5.768-1.123-5.772c-3.135-15.387-5.248-30.82-7.272-46.258-1.003-7.719-5.778-41.373-5.941-45.248"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M954.998 927.749c-3.55-15.367-6.657-30.755-9.939-46.134l-4.855-23.073-2.436-11.535-1.221-5.768-1.123-5.772c-3.135-15.387-5.248-30.82-7.272-46.258-1.003-7.719-5.778-41.373-5.941-45.248M991.094 926.441h15.486"
            />
            <path
                fill="#FCB47F"
                d="M941.185 687.632c-.572-9.712-8.975-42.267-16.076-68.398a312.75 312.75 0 0 1-10.858-74.513l-.532-22.205c-.375-15.638 5.902-30.06 18.094-39.861 16.277-13.086 31.837-20.476 49.941-28.194 32.196 15.594 42.146 16.424 56.016 13.777a124.64 124.64 0 0 1 6.41 4.394c26 19 45.97 59.672 52.16 82.234 3.21 11.669 2.84 23.766-8.16 29.766 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M941.185 687.632c-.572-9.712-8.975-42.267-16.076-68.398a312.75 312.75 0 0 1-10.858-74.513l-.532-22.205c-.375-15.638 5.902-30.06 18.094-39.861 16.277-13.086 31.837-20.476 49.941-28.194 32.196 15.594 42.146 16.424 56.016 13.777a124.64 124.64 0 0 1 6.41 4.394c26 19 45.97 59.672 52.16 82.234 3.21 11.669 2.84 23.766-8.16 29.766 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M1077.23 511.637c9.26 15.605 15.97 31.758 19.11 43.229 3.21 11.668 2.85 23.765-8.15 29.765 14 28 25 49 14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1077.23 511.637c9.26 15.605 15.97 31.758 19.11 43.229 3.21 11.668 2.85 23.765-8.15 29.765 14 28 25 49 14 103"
            />
            <path
                fill="#FCB47F"
                d="M941.19 687.628c-.572-9.711-8.975-42.267-16.076-68.397a312.699 312.699 0 0 1-10.857-74.513l-.474-19.37c-.779-24.024 9.664-36.865 21.703-45.56a190.86 190.86 0 0 1 41.284-23.265"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M941.19 687.628c-.572-9.711-8.975-42.267-16.076-68.397a312.699 312.699 0 0 1-10.857-74.513l-.474-19.37c-.779-24.024 9.664-36.865 21.703-45.56a190.86 190.86 0 0 1 41.284-23.265"
            />
            <path
                fill="#FCB47F"
                d="M1088.35 584.827c-3.57-6.179-7.72-12.857-11.12-16.862l11.12 16.862Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1088.35 584.827c-3.57-6.179-7.72-12.857-11.12-16.862"
            />
            <path
                fill="#FCB47F"
                d="M979.109 586.054c31.071 3.775 56.531-1.156 71.671-22.12l-71.671 22.12Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M979.109 586.054c31.071 3.775 56.531-1.156 71.671-22.12"
            />
            <path
                fill="#FCB47F"
                d="M1079.23 663.609a13.787 13.787 0 0 1 10.29 0h-10.29Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1079.23 663.609a13.787 13.787 0 0 1 10.29 0"
            />
            <path
                fill="url(#p)"
                d="m1021.96 1103.87-.04-.86c0-.05 0-.05.13-5.16 1.39-52.98 2.1-83.02 2.13-89.28-.23-17.533 3.25-45.324 11.54-67.849 3.76-10.325 4-21.599.68-32.074-14.06-44.607-31.69-110.19-38.998-160.478a51.931 51.931 0 0 1 9.788-38.477 51.94 51.94 0 0 1 34.1-20.326c2.49-.362 5.01-.544 7.52-.546 23.69 0 45.33 16.03 51.19 38.98 23.79 97.617 7.05 201.005 6.32 203.964-24.72 99.566-33.49 129.076-39.16 150.626-2.15 8.15-4 15.19-6.38 24.49l-.21.84-38.61-3.85Z"
            />
            <path
                fill="url(#q)"
                d="M941.087 686.406c-9.184 15.857-16.083 25.768-17.455 44.268a52.23 52.23 0 0 0-.918 12.188c1.616 38.358 8.676 89.007 19.369 138.96.9 4.214 2.478 13.846 4.004 23.16.766 4.673 1.53 9.335 2.212 13.346a50.765 50.765 0 0 1-.863 20.79c-5.946 24.084-7.154 59.754-2.815 82.952.891 5.23 3.686 24.48 6.645 44.87 3.573 24.61 7.268 50.07 7.97 53.28l.184.84 40.99-2.75.06-.87c.86-12.81 1.54-22.89 2.15-31.98 2.34-34.49 6.75-55.31 13.31-160.155 8.26-28.072 13.52-101.555 15.55-134.876a46.993 46.993 0 0 1 12.68-29.326l24.76-29.503s24.55-11.723 33.27-45.194H941.087Z"
            />
            <path
                fill="url(#r)"
                d="m916.969 752.035-.109-.876c-2.915-23.317 1.063-43.872 4.91-63.749 4.391-22.693 8.54-44.129 2.332-67.925a328.329 328.329 0 0 1-10.89-74.739l-.541-22.589c-.375-15.628 6.461-30.813 18.524-40.319 25.43-20.041 49.756-28.119 49.756-28.119s1.916.108 9.777 9.759c1.899 2.331 7.57 9.271 9.482 10.981 8.47 7.555 16.21 11.228 23.67 11.227 1.34 0 2.68-.12 4-.359a9.27 9.27 0 0 0 5.23-2.763c4.71-5.098 3.38-16.737 3.38-16.737a64.052 64.052 0 0 1 8.25 5.997c25.56 21.883 46.64 61.31 52.53 82.777 2.14 7.783 2.36 14.435.67 19.773-3.3 10.393-3.02 21.138.8 30.258a107.95 107.95 0 0 1 8.85 40.646c.29 11.564 0 104.819 0 105.76v.997H916.969Z"
            />
            <path
                fill="url(#s)"
                d="M1036.63 467.603s27.55 38.811 45.48 69.682c13.76 23.683 2.02 54.374 9.09 63.333 25.5 32.346 24.68 117.914 24.68 117.914v-43.766c0-42.296-10.6-60.945-15.37-73.635a44.893 44.893 0 0 1-2.38-22.436l.84-5.654a57.562 57.562 0 0 0-6.09-35.511l-17.09-32.18a96.38 96.38 0 0 0-30.15-33.966l-9.11-6.7.1 2.919Z"
            />
            <path
                fill="#fff"
                d="M1039.12 468.207c27.89 17.212 28.09 18.737 26.9 24.745a53.957 53.957 0 0 1 8.46 4.075l11.05 47.572c-8.71-17.246-26.43-45.449-46.41-76.392Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1037.77 468.234s26.41 38.178 44.34 69.049c13.76 23.684 2.03 54.375 9.09 63.333 25.5 32.346 24.69 117.914 24.69 117.914v-43.765c0-42.296-10.61-60.945-15.38-73.636a44.888 44.888 0 0 1-2.38-22.435l.85-5.654a57.636 57.636 0 0 0-6.1-35.511l-6.14-11.576"
            />
            <path
                fill="url(#t)"
                d="m950.922 468.208 30.028-16.067 33.7 81.651a96.286 96.286 0 0 1 7.28 36.703v331.838H911.107l-.841-375.173a59.62 59.62 0 0 1 26.301-49.576l14.355-9.376Z"
            />
            <path
                fill="#fff"
                d="M980.949 452.141c-9.788 31.686-2.35 34.447 3.954 37.101-7.3 9.29-6.774 10.843-6.774 10.843l41.051 47.595-38.231-95.539Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m950.922 468.208 30.028-16.067 33.7 81.651a96.286 96.286 0 0 1 7.28 36.703v331.838H911.107l-.841-375.173a59.62 59.62 0 0 1 26.301-49.576l14.355-9.376ZM926.418 728.027h59.675"
            />
            <path
                fill="url(#u)"
                d="M1179.91 503.645c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7c-.3.9-.2 1.7.2 2.2l4.6 5.6c-.4-.5-.5-1.3-.2-2.2l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.6c.7 0 1.2.2 1.6.7l-4.7-5.6Z"
            />
            <path
                fill="url(#v)"
                d="M1182.91 508.547c1.6 0 2.4 1.3 1.8 2.9l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.5Z"
            />
            <path
                fill="#888B8D"
                d="M1142.52 540.145c3.2 0 4.8 2.6 3.6 5.8-1.2 3.2-4.8 5.8-8 5.8s-4.8-2.6-3.6-5.8c1.2-3.2 4.8-5.8 8-5.8Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1129.01 515.445c1.2 0 1.9 1 1.4 2.2-.5 1.3-1.9 2.3-3.1 2.3-1.2 0-1.9-1-1.4-2.2.5-1.3 1.8-2.3 3.1-2.3ZM1125.81 522.945c1.2 0 1.9 1 1.4 2.2-.5 1.2-1.8 2.2-3.1 2.2-1.2 0-1.9-1-1.4-2.2.5-1.2 1.8-2.2 3.1-2.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1184.51 509.245-4.6-5.6c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1184.51 509.247c.4.5.5 1.3.2 2.2l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h38.7"
            />
            <path
                fill="#FCB47F"
                d="M978.687 503.102c2.076 7.244 4.572 14.366 6.887 21.541a156.652 156.652 0 0 1 5.406 21.97c1.355 7.453 2.496 14.967 3.341 22.568a197.767 197.767 0 0 1 1.358 23.142 23.493 23.493 0 0 1-23.707 23.27 23.73 23.73 0 0 1-19.517-10.75 197.91 197.91 0 0 1-11.22-20.286c-3.348-6.876-6.399-13.837-9.237-20.86a156.71 156.71 0 0 1-7.173-21.459c-1.877-7.301-3.574-14.655-5.691-21.887a31.005 31.005 0 0 1 21.047-38.459 31.006 31.006 0 0 1 23.588 2.558 31.01 31.01 0 0 1 14.871 18.489l.047.163Z"
            />
            <path
                fill="#FCB47F"
                d="m974.993 574.914 13.445-.066 13.442-.13c8.97-.062 17.94.141 26.91.496 4.49.211 8.98.362 13.47.637 4.48.24 8.97.491 13.47.843 4.49.335 8.98.621 13.47.997 4.49.443 8.99.785 13.48 1.33 2.05.248 4.02.896 5.81 1.906a15.722 15.722 0 0 1 4.64 3.985 15.615 15.615 0 0 1 2.76 5.456c.55 1.982.71 4.054.46 6.097a15.699 15.699 0 0 1-4.18 8.887 15.754 15.754 0 0 1-8.65 4.658c-4.47.786-8.93 1.37-13.4 2.053-4.46.617-8.93 1.143-13.4 1.719-4.46.593-8.93 1.085-13.4 1.566-4.47.516-8.94.908-13.41 1.36-8.95.837-17.89 1.521-26.85 1.941l-13.428.593-13.43.656a22.501 22.501 0 0 1-16.217-5.807 22.506 22.506 0 0 1 15.008-39.177Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m978.637 502.938.047.164c2.076 7.243 4.572 14.366 6.887 21.54a156.69 156.69 0 0 1 5.407 21.97c1.354 7.453 2.495 14.968 3.34 22.568.053.487.104.974.155 1.462M952.454 604.843a197.517 197.517 0 0 1-11.221-20.286c-3.347-6.875-6.399-13.837-9.237-20.86a156.557 156.557 0 0 1-7.172-21.458c-1.878-7.301-3.575-14.655-5.691-21.887M1083.52 610.012c-4.46.787-8.93 1.37-13.39 2.053-4.47.617-8.94 1.144-13.41 1.72-4.46.592-8.93 1.085-13.4 1.566-4.47.515-8.94.907-13.41 1.36-8.94.836-17.89 1.521-26.84 1.94l-13.436.593-13.43.657c-10.085.493-18.337-6.013-23.751-15.057M988.441 574.848l13.449-.13c8.96-.062 17.93.141 26.9.496 4.49.211 8.98.362 13.47.637 4.49.24 8.98.491 13.47.843 4.49.335 8.98.621 13.47.997 4.5.443 8.99.785 13.49 1.33"
            />
            <path
                fill="#FCB47F"
                d="M1082.19 609.848c9.72.894 19.53.057 28.96-2.472 3.21-.913 6.42-1.858 9.58-2.922l1.47-.3c12.53-2.041 23.97-9.075 33.75-16.94.55-.391.96-.948 1.17-1.588.2-.641.2-1.331-.01-1.969.31-.238.55-.571.67-.951.11-.38.11-.787-.02-1.163a2.961 2.961 0 0 0-1.43-1.688c-.94-.562-2.06-.135-3.15.007l-.14-1.006a2.388 2.388 0 0 0-.88-1.125c-.4-.277-.88-.424-1.37-.424-3.55-.002-7.06.749-10.3 2.204-3.7 1.686-7.27 3.724-11.15 4.945a28.603 28.603 0 0 1-21.11-1.665l-2.38-1.268c2.23-3.019 5.55-5.008 8.71-7.039a26.986 26.986 0 0 0 6.88-5.721c.38-.484.56-1.091.51-1.702a2.447 2.447 0 0 0-.78-1.596 2.48 2.48 0 0 0-1.44-.624 2.432 2.432 0 0 0-1.52.372c-3.85 2.358-8.72 3.499-12.85 5.175-4.72 1.908-9.51 3.687-13.88 6.291-4.37 2.603-8.34 6.127-10.57 10.695"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1105.85 581.523c2.23-3.019 5.55-5.008 8.71-7.039a26.986 26.986 0 0 0 6.88-5.721 2.43 2.43 0 0 0 .51-1.702 2.447 2.447 0 0 0-.78-1.596 2.48 2.48 0 0 0-1.44-.624 2.432 2.432 0 0 0-1.52.372c-3.85 2.358-8.72 3.499-12.85 5.175-4.72 1.908-9.51 3.687-13.88 6.291-4.37 2.603-8.34 6.127-10.57 10.695M1121 596.727c6.14-.086 11.76-3.306 16.85-6.734 4.41-2.97 8.79-6.225 13.74-8.048.3-.106.57-.27.81-.483a2.437 2.437 0 0 0 .79-1.676 2.385 2.385 0 0 0-1.03-2.057c-.4-.277-.88-.425-1.37-.424a25.09 25.09 0 0 0-10.3 2.203c-3.7 1.687-7.27 3.725-11.15 4.946a28.598 28.598 0 0 1-21.11-1.666"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1156.75 582.527-9.49 7.238c-3.78 3.01-7.74 5.779-11.87 8.29-7.51 4.353-15.89 6.945-24.24 9.322a82.56 82.56 0 0 1-28.95 2.472"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1155.95 587.211c-9.78 7.865-21.22 14.899-33.75 16.94"
            />
            <path
                fill="url(#w)"
                d="M975.088 620.923a23.586 23.586 0 0 1-20.242-11.585 25.013 25.013 0 0 1-3.232-3.951 201.216 201.216 0 0 1-11.281-20.394c-3.209-6.591-6.24-13.435-9.267-20.925a158.318 158.318 0 0 1-7.215-21.588c-.565-2.196-1.114-4.401-1.663-6.605-1.251-5.02-2.544-10.21-4.018-15.246a32.035 32.035 0 0 1 21.725-39.7 32.038 32.038 0 0 1 9.001-1.296 32.167 32.167 0 0 1 30.699 23.02l.048.168c1.446 5.043 3.125 10.118 4.75 15.026.715 2.161 1.43 4.321 2.129 6.486a158.49 158.49 0 0 1 5.44 22.106c1.443 7.944 2.54 15.348 3.349 22.628.169 1.568.327 3.137.475 4.707l6.084-.058c1.08-.008 2.16-.012 3.23-.011 7.34 0 15.1.166 23.73.508 1.47.069 2.93.131 4.4.193 2.97.127 6.05.259 9.09.445 4.32.231 8.93.487 13.48.844 1.58.118 3.16.231 4.74.341 2.91.206 5.83.412 8.74.657 1.5.147 2.98.282 4.46.417 2.97.27 6.04.549 9.06.916-4.33 9.616-2.52 22.411.89 32.966-3 .529-6.04.973-8.98 1.4-1.48.216-2.96.431-4.43.656-2.91.402-5.8.763-8.68 1.125-1.59.197-3.17.397-4.75.599-4.56.605-9.13 1.106-13.43 1.568-3.01.347-6.08.644-9.04.931-1.46.141-2.92.284-4.37.43-9.85.921-18.64 1.557-26.9 1.945l-13.434.593-13.425.657c-.389.018-.777.028-1.162.028l-.001-.001Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M984.395 517.852c.715 2.161 1.43 4.321 2.128 6.486a158.175 158.175 0 0 1 5.44 22.106c1.444 7.944 2.54 15.348 3.349 22.628.169 1.568.328 3.136.476 4.707l6.082-.059c1.08-.007 2.16-.011 3.23-.01 7.34 0 15.1.166 23.73.508 1.47.069 2.94.131 4.4.193 2.98.127 6.05.259 9.09.444 4.32.232 8.93.488 13.49.845 1.57.117 3.15.231 4.73.341 2.91.206 5.83.412 8.75.657 1.49.147 2.97.281 4.45.417 2.97.27 6.04.549 9.07.916-4.34 9.616-2.53 22.411.88 32.966-3 .529-6.04.973-8.98 1.4-1.48.216-2.96.431-4.43.656-2.91.402-5.79.763-8.68 1.124-1.58.197-3.17.398-4.75.6-4.56.605-9.13 1.106-13.42 1.568a505.6 505.6 0 0 1-9.05.931c-1.46.141-2.91.284-4.37.43-9.85.921-18.64 1.557-26.9 1.945l-13.432.592-13.425.658a25.13 25.13 0 0 1-1.162.028h-.001a23.588 23.588 0 0 1-20.241-11.586 24.896 24.896 0 0 1-3.232-3.95 201.03 201.03 0 0 1-11.282-20.394c-3.209-6.591-6.24-13.435-9.267-20.925a158.497 158.497 0 0 1-7.214-21.588 885.514 885.514 0 0 1-1.664-6.606c-1.251-5.02-2.544-10.21-4.018-15.246"
            />
            <path
                fill="#EF0025"
                d="M1104.96 1159.3c-.1 0-.2 0-.3-.01-8.15-.46-22.41-1.38-26.4-2.24a70.901 70.901 0 0 1-19.73-7.34c-3.7-2.08-7.68-3.57-11.83-4.42l-19.89-4.2c-1.34-.27-2.6-.82-3.72-1.61a9.957 9.957 0 0 1-2.75-2.96 9.84 9.84 0 0 1-1.38-3.8c-.19-1.35-.1-2.73.26-4.04 1.19-4.36 2.34-8.64 3.31-12.37.06-.25.21-.46.42-.59.22-.14.47-.19.72-.15 3.94.84 7.8 2.04 11.53 3.59.65.24 1.3.49 1.95.73 5.89 2.2 12.16 4.02 18.04 2.44 2.26-.7 4.44-1.64 6.51-2.81 1.05-.54 2.13-1.1 3.24-1.6 1.02-.47 2.07-.86 3.15-1.18.19-.06.4-.06.59.01.2.06.37.18.49.34 6.45 8.46 16.28 19.75 26.45 25.47 4.18 2.36 9.35 5.53 12.06 7.21.83.52 1.49 1.26 1.92 2.14.42.89.59 1.87.47 2.84a5.192 5.192 0 0 1-1.69 3.25c-.94.84-2.16 1.3-3.42 1.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1108.5 1157.89a5.13 5.13 0 0 1-3.54 1.41h-.3c-8.15-.47-22.41-1.38-26.4-2.25a70.901 70.901 0 0 1-19.73-7.34 41.018 41.018 0 0 0-11.83-4.42l-19.89-4.2c-1.34-.27-2.6-.82-3.72-1.61a9.839 9.839 0 0 1-2.75-2.96"
            />
            <path
                fill="url(#x)"
                d="M1033.06 1159.51h-57.644a9.836 9.836 0 0 1-3.966-.81 9.836 9.836 0 0 1-3.311-2.33 9.79 9.79 0 0 1-2.129-3.43 9.937 9.937 0 0 1-.576-4.01c.192-3.29.403-7.12.59-10.98a1.005 1.005 0 0 1 .794-.93.919.919 0 0 1 .436.01c2.161.52 4.411.56 6.589.11a15.132 15.132 0 0 0 6.004-2.71 30.542 30.542 0 0 0 3.72-3.65 39.454 39.454 0 0 1 3.132-3.19 21.038 21.038 0 0 1 8.493-4.32c3.123-.78 6.378-.83 9.528-.15.24.05.46.19.6.4 4.32 6 10.5 13.87 15.45 17.2 3.66 2.47 10.89 6.42 14.87 8.55.87.46 1.59 1.16 2.1 2 .5.85.77 1.81.77 2.8 0 1.44-.58 2.82-1.6 3.85a5.433 5.433 0 0 1-3.85 1.59Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1037.27 1157.52c-.51.62-1.15 1.13-1.88 1.47-.73.35-1.52.52-2.33.52h-57.646a9.836 9.836 0 0 1-3.966-.81 9.96 9.96 0 0 1-3.311-2.32"
            />
            <path
                fill="#AEAEAF"
                d="M1774.03 1148.49h16.61l-44.86-35.84h-73.08l-44.86 35.84h16.61l41.32-19 23.47 15.22 23.47-15.22 41.32 19Z"
            />
            <path fill="url(#y)" d="M1718.66 1043.41v-45.098h-18.84v45.098h18.84Z" />
            <path
                fill="url(#z)"
                d="M1786.38 971.562v18.749c0 4.415-3.59 8-8 8H1640.1c-4.42 0-8-3.585-8-8v-18.749c0-8.831 7.17-16 16-16h122.28c8.83 0 16 7.169 16 16Z"
            />
            <path fill="url(#A)" d="M1724.52 1112.65v-69.24h-30.56v69.24h30.56Z" />
            <path
                fill="#818084"
                d="M1636.15 1160.99c6.9 0 12.5-5.6 12.5-12.5 0-6.91-5.6-12.5-12.5-12.5-6.91 0-12.5 5.59-12.5 12.5 0 6.9 5.59 12.5 12.5 12.5ZM1782.33 1160.99c6.91 0 12.5-5.6 12.5-12.5 0-6.91-5.59-12.5-12.5-12.5-6.9 0-12.5 5.59-12.5 12.5 0 6.9 5.6 12.5 12.5 12.5ZM1709.24 1160.99c6.9 0 12.5-5.6 12.5-12.5 0-6.91-5.6-12.5-12.5-12.5-6.91 0-12.5 5.59-12.5 12.5 0 6.9 5.59 12.5 12.5 12.5Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1675.98 998.311h102.4a8 8 0 0 0 8-8v-18.749c0-8.836-7.16-16-16-16H1648.1c-8.84 0-16 7.164-16 16v18.749a8 8 0 0 0 8 8h14.66"
            />
            <path
                fill="#CB865E"
                d="M1644.03 594.027c1.4-4.7-1.2-10-1.2-15.2 1.2-9.3 13.9-23.6 24.7-27 18.5-5.7 33.3-1.4 44.5 6.3 3.2 2.2 26.6 23.4.7 66.9-11.1 17.3 5.2 32.6 5.2 32.6-4.7 6.5-39.4 33.3-53.2 28.6-13.9-4.6-3.3-15.8 2.6-26.2.2-.3.3-.6.5-.9 2.1-4.2 2.4-8.2 2-11.5-6.9.5-10.7.9-14.6-1.3-14.9-5.9-16.4-21.6-16.1-31.1.1-10.2 3.5-16.5 4.9-21.2Z"
            />
            <path
                fill="url(#B)"
                d="M1658.53 676.028c2.2-9.1 13-16.2 11.1-28.5 13-2.2 20.1-9 26.8-16.3 1.2-1.3-6.7 29.1-7.3 30.8-.6 1.7-4.4 3.3-5.5 4.8-4 5.8-9.4 10.7-15.7 14-.8.4-1.6.9-2.6 1-.9.1-1.9-.1-2.5-.8-.7-.9-.7-2.1-.5-3.2.5-1.2-4-.7-3.8-1.8Z"
            />
            <path
                fill="url(#C)"
                d="M1644.62 585.528c1.7-2.1 9.8-2.9 10.3 4.2.6 9.2-5.2 23.6-2.9 26.2.8.8 2.7 2.5 2.8 3.7.4 4.1-.2 8.2-.4 12.3-.3 4.7-1.3 12.5 3.2 15.4-.4-.6-6.5-2.5-7.1-2.9-10.7-7.9-14.6-23.6-8.1-44.8.8-2.5 2-5.2 2.4-7.8.2-2.6-1.1-5.2-.2-6.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1696.03 631.027c-8.2 14.4-32.8 19.5-42.6 14.5-8.8-4.5-15.4-12.4-14.4-31.5.5-9.5 3.6-15.3 5-20 1.4-4.7-1.4-9.7-.9-14.9 1-11.4 14.5-29.1 36.9-30.6 17.7-1.2 30.5 6.8 35.5 13.1 7.7 9.6 11.4 22.4 9.2 34.4-1.5 8.2-5.3 15.7-8.9 23.2-4.5 9.4-13.9 20 1.6 37.8"
            />
            <path
                fill="#CB865E"
                d="M1622.02 700.804c-2.25 6.819-4.89 13.508-7.35 20.254a151.98 151.98 0 0 0-5.98 20.712c-1.57 7.042-2.95 14.149-4.05 21.349a192.532 192.532 0 0 0-2.21 21.963 19.363 19.363 0 0 0 5.2 13.822 19.31 19.31 0 0 0 13.45 6.099c3.21.088 6.4-.619 9.27-2.058a19.58 19.58 0 0 0 7.2-6.192 190.52 190.52 0 0 0 11.37-18.915c3.43-6.426 6.59-12.943 9.54-19.527a149.768 149.768 0 0 0 7.61-20.166c2.07-6.881 3.96-13.817 6.24-20.624a26.484 26.484 0 0 0-1.4-20.234 26.482 26.482 0 0 0-15.3-13.318 26.492 26.492 0 0 0-20.23 1.4 26.53 26.53 0 0 0-13.32 15.298l-.04.137Z"
            />
            <path
                fill="#CB865E"
                d="m1603.79 781.705-5.32 10.655-5.45 10.594a233.7 233.7 0 0 0-9.7 21.769c-1.42 3.723-2.93 7.401-4.25 11.172l-2.04 5.628c-.67 1.878-1.29 3.785-1.93 5.677-2.55 7.588-4.94 15.248-6.83 23.152-.65 2.726-.35 5.593.85 8.125a12.34 12.34 0 0 0 5.76 5.794 12.286 12.286 0 0 0 15.01-3.497c5.01-6.401 9.51-13.045 13.86-19.76 1.08-1.685 2.19-3.353 3.24-5.052l3.13-5.1c2.13-3.381 4.06-6.855 6.09-10.284a233.43 233.43 0 0 0 10.99-21.143l4.9-10.863 5.02-10.8a18.532 18.532 0 0 0 .65-14.027 18.519 18.519 0 0 0-9.39-10.441 18.498 18.498 0 0 0-24.59 8.401Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1637.55 796.753a192.54 192.54 0 0 0 11.38-18.915 320.167 320.167 0 0 0 9.53-19.527 149.717 149.717 0 0 0 7.61-20.166c2.07-6.88 3.96-13.817 6.24-20.624a26.437 26.437 0 0 0 1.31-10.256 26.505 26.505 0 0 0-9.03-18.158 26.582 26.582 0 0 0-8.98-5.138 26.466 26.466 0 0 0-10.25-1.312c-3.47.24-6.86 1.162-9.98 2.712a26.629 26.629 0 0 0-8.18 6.325 26.633 26.633 0 0 0-5.14 8.973l-.04.137c-2.25 6.819-4.88 13.508-7.35 20.254a150.528 150.528 0 0 0-5.97 20.712c-1.58 7.042-2.96 14.149-4.06 21.349-.84 5.645-1.57 11.33-1.96 17.125M1589.89 880.776c5-6.401 9.51-13.045 13.86-19.76 1.08-1.685 2.19-3.353 3.23-5.052l3.14-5.1c2.13-3.381 4.06-6.855 6.09-10.284a233.43 233.43 0 0 0 10.99-21.143l4.9-10.863 5.02-10.801M1601.8 784.523l-3.33 7.84-5.45 10.594a233.817 233.817 0 0 0-9.7 21.769c-1.41 3.723-2.93 7.401-4.25 11.172l-2.03 5.628c-.68 1.878-1.29 3.786-1.94 5.677-2.54 7.588-4.94 15.249-6.83 23.152"
            />
            <path
                fill="#CB865E"
                d="M1557.85 901.704c1.88-8.124 5.93-22.708 9.17-30.436 11.06-12.291 20.12-5.641 20.16 11.326.85 3.62.22 11.398-.92 15.932a45.72 45.72 0 0 0-1.64 10.462c-.03 3.534.97 7.263 3.67 9.751l-.7 1.294c-2.74 1.253-6.63-.208-8.38-2.571a16.966 16.966 0 0 1-2.58-6.714c-.1-.472-.47.223-.47.223-3.03 2.646-5.29 6.966-6.6 11.62 1.59.106 3.18-.046 4.72-.451.47-.111.96-.033 1.37.217l1.86 1.151a5.687 5.687 0 0 1 2 2.121c.48.869.72 1.845.7 2.835a5.674 5.674 0 0 1-.79 2.809c-.5.852-1.22 1.56-2.08 2.053-3.23 1.815-7.25 2.032-9.42.918l-.56 1.128a16.333 16.333 0 0 1-3.05 7.929c-.15.193-.33.351-.55.464-.21.113-.45.177-.69.188a1.688 1.688 0 0 1-1.31-.533l-.08-.087a1.93 1.93 0 0 1-.35-.603c-.08-.223-.1-.46-.08-.695.31-4.82-2.19-11.214-3.45-15.58-2.32-8.059-1.84-16.626.05-24.751Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1573.42 904.055a25.235 25.235 0 0 0-6.9 16.776c-.07 3.933.81 7.837.93 11.77.11 3.933-.69 8.129-3.55 10.978M1561.42 940.274c-.07-4.75-2.29-9.218-3.62-13.813-2.32-8.059-1.84-16.626.05-24.751a158.598 158.598 0 0 1 7.55-24.765M1587.57 887.695c.58 4.324-.64 7.357-1.31 10.831-.66 3.475-1.6 6.928-1.64 10.462-.03 3.534.97 7.264 3.67 9.752M1587.14 920.138c-2.73 1.253-6.18-.307-7.93-2.67-1.76-2.363-2.27-5.309-2.89-8.126-.61-2.816-1.45-5.763-3.65-7.78"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1569.76 920.792a21.385 21.385 0 0 1 6.4-9.815M1577.73 932.914c-2.85 1.924-7.09 2.727-9.81 1.333M1579.2 927c-2.67 2.156-9.16 2.645-12.19.917M1575.01 921.964a10.912 10.912 0 0 1-8.49-.058"
            />
            <path
                fill="#CB865E"
                d="M1632.4 686.963c8.49-8.307 18.69-15.185 30.9-21.252l-15.6 34.205s-19.39-8.958-15.3-12.953Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M1663.3 665.711a132.159 132.159 0 0 0-30.9 21.252"
            />
            <path
                fill="#CB865E"
                d="M1656.73 618.228c-3.2.6-9.8.8-9.8-5.5 0-4.3 6.5-6.8 8-10.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1656.73 618.228c-3.2.6-9.8.8-9.8-5.5 0-4.3 6.5-6.8 8-10.4M1680.53 585.726c-3.2-4.4-8.2-7.3-13.6-7.8M1651.23 584.429c-3.2-.2-6.5.6-9.2 2.3M1654.23 627.326c2.1-2.6 6.5-4.6 15.2-3.9"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1671.43 598.925c.77 0 1.4-1.075 1.4-2.4 0-1.326-.63-2.4-1.4-2.4-.78 0-1.4 1.074-1.4 2.4 0 1.325.62 2.4 1.4 2.4ZM1647.83 598.925c.77 0 1.4-1.075 1.4-2.4 0-1.326-.63-2.4-1.4-2.4-.78 0-1.4 1.074-1.4 2.4 0 1.325.62 2.4 1.4 2.4Z"
            />
            <path
                fill="#2B2B2B"
                d="M1744.23 584.727c-.1-.1-.1-.4.1-.5 4.9-2.8 4.9-10.5-.1-12.8-.2-.1-.2-.3-.1-.5 4.1-3.8 2.2-11.2-3.2-12.3-.2-.1-.3-.3-.2-.4 1.4-2.2 1.6-5 .2-7.3-1.3-2.3-3.8-3.7-6.4-3.8-.2 0-.3-.2-.3-.4 1.9-5.1-3.3-10.7-8.8-9.3-.2 0-.4-.1-.4-.3.7-5.3-5.9-9.5-11-7-.2.1-.4 0-.4-.2-.7-5.3-8.1-7.8-12.5-4.3-.2.1-.4.1-.5-.1-1-2.3-3.3-3.9-6.1-4.2-2.8-.3-5.5.9-7.2 2.9-.1.2-.4.2-.5 0-3.1-4.3-11.2-3.3-13.4 1.7-.1.2-.3.2-.5.1-4.1-3.4-11.8-.7-12.8 4.6 0 .2-.3.3-.5.2-2.4-1-5.3-.8-7.7.7-2.4 1.5-3.7 4.1-3.7 6.6 0 .2-.2.4-.4.3-5.4-1.2-11.1 4.5-9.5 9.6 0 .2-.1.4-.3.4-5.6.1-9.8 6.8-7 11.3.1.2 0 .4-.2.4-5.5 1.3-8 8.8-4 12.5a7.74 7.74 0 0 0-4.6 7.1c0 2.7 1.3 5 3.4 6.4 1.3.9 2.8 1.4 4.5 1.4h.3v.6c0 4.3 3.5 7.8 7.8 7.8 1.4 0 2.7-.4 3.8-1 .1.3.3.6.4.8 0-.1.1-.2.1-.4l.6-1.8c.7-2.4.2-5-.3-7.7-.4-2.3-.9-4.6-.7-7 .5-5.5 3.7-12.2 9.2-18.1l1.6.3h.3c2 .4 3.8 1.3 4.9 3 1.7 2.4 4.2 4 7.1 4.2 2.4.2 4.5 1.8 5.7 4 1.1 2.2 3.2 3.9 5.6 4.6 2 .6 3.7 2.1 4.5 4.1.8 1.9 2.2 3.5 3.9 4.5 1.5.9 2.5 2.2 3.1 3.8.8 2 2.6 3.6 4.6 4.8 3.4 2 5.6 5.5 5.7 9.4 0 .6.1 1 .2 1 .6-.3 1-7.1 6.5-11.3 10.5-8.2 21.5 9 7.9 22.9-2.8 3-6.3 4.4-8.3 5 .1 4.1.5 7.2 1 9.7.7 3.3 5 4.3 7.1 1.6 1.7-2.2 3-3.7 3.2-3.6 4.8.9 10.3-3.6 9.6-8.5 0-.2.2-.4.4-.3 2.6.2 5.4-.8 7.2-2.9 1.8-2.2 2.3-4.9 1.5-7.3-.1-.2.1-.4.3-.4 5.6-.5 9.2-7.5 6-11.7-.1-.2 0-.4.2-.5 5.5-1.5 7.3-9 3.1-12.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1741.03 597.827c-.1-.2 0-.4.2-.5 5.4-1.7 7.3-9.2 3.1-12.6-.1-.1-.1-.4.1-.5 4.9-2.8 4.9-10.5-.1-12.8-.2-.1-.2-.3-.1-.5 4.1-3.8 2.2-11.2-3.2-12.3-.2-.1-.3-.3-.2-.4 1.4-2.2 1.6-5 .2-7.3-1.3-2.3-3.8-3.7-6.4-3.8-.2 0-.3-.2-.3-.4 1.9-5.1-3.3-10.7-8.8-9.3-.2 0-.4-.1-.4-.3.7-5.3-5.9-9.5-11-7-.2.1-.4 0-.4-.2-.7-5.3-8.1-7.8-12.5-4.3-.2.1-.4.1-.5-.1-1-2.3-3.3-3.9-6.1-4.2-2.8-.3-5.5.9-7.2 2.9-.1.2-.4.2-.5 0-3.1-4.3-11.2-3.3-13.4 1.7-.1.2-.3.2-.5.1-4.1-3.4-11.8-.7-12.8 4.6 0 .2-.3.3-.5.2-2.4-1-5.3-.8-7.7.7-2.4 1.5-3.7 4.1-3.7 6.6 0 .2-.2.4-.4.3-5.4-1.2-11.1 4.5-9.5 9.6 0 .2-.1.4-.3.4-5.6.1-9.8 6.8-7 11.3.1.2 0 .4-.2.4M1698.62 604.727c.61-.3 1-7.1 6.5-11.3 10.5-8.2 21.51 9 7.91 22.9-2.8 3-6.3 4.4-8.3 5"
            />
            <path
                fill="#CB865E"
                d="M1543.36 1112.46c1.22-7.91 2.3-15.82 3.26-23.72.48-3.95 1.03-7.91 1.45-11.85l1.29-11.84c.89-7.9 1.65-15.79 2.76-23.7l1.66-11.86.84-5.94.64-5.92c1.68-15.78.02-31.335-2.19-46.853-1.19-7.754-2.7-15.485-4.67-23.185a144.201 144.201 0 0 0-7.71-22.976l-4.13-9.364a24.448 24.448 0 0 0-11.87-12.199 24.425 24.425 0 0 0-30.76 8.476 24.475 24.475 0 0 0-3.94 16.556l5.72 46.616 2.88 23.307 3 23.302c2.02 15.53 3.95 31.06 6.05 46.59l6.22 46.58c.47 3.51 2.19 6.73 4.84 9.08 2.65 2.35 6.06 3.66 9.6 3.69 3.54.04 6.97-1.2 9.67-3.49 2.7-2.28 4.49-5.47 5.03-8.97l.36-2.33Z"
            />
            <path
                fill="#CB865E"
                d="M1515.21 951.443c12.8 2.74 25.4 3.361 37.93 4.68 0 0 107.08-.541 110.05-1.079a52.285 52.285 0 0 0 32.8-20.487 52.267 52.267 0 0 0 9.76-37.422 52.31 52.31 0 0 0-18.62-33.897 52.295 52.295 0 0 0-36.82-11.837l-7.25.497c-3.03.206-6.04.521-9.03.894l-4.49.577-4.47.682c-2.99.422-5.95.988-8.91 1.504-2.98.492-5.92 1.13-8.87 1.717-11.8 2.446-23.53 5.216-35.17 8.46-5.82 1.64-11.57 3.636-17.27 5.845a302.479 302.479 0 0 0-17.01 7.176c-1.41.635-2.82 1.299-4.22 1.979-1.4.663-2.8 1.35-4.2 2.061-2.8 1.378-5.56 2.939-8.34 4.4a162.155 162.155 0 0 0-16.4 10.291l-.87.637a29.866 29.866 0 0 0-10.27 13.468 29.767 29.767 0 0 0-1.3 16.886 29.798 29.798 0 0 0 8.09 14.878 29.835 29.835 0 0 0 14.88 8.09Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1553.14 956.196s107.08-.614 110.05-1.152a52.285 52.285 0 0 0 32.8-20.487 52.267 52.267 0 0 0 9.76-37.422 52.31 52.31 0 0 0-18.62-33.897 52.295 52.295 0 0 0-36.82-11.837l-7.25.497c-3.03.206-6.04.521-9.03.894l-4.49.577-4.47.682c-2.99.422-5.95.988-8.91 1.504-2.98.492-5.92 1.13-8.87 1.717-11.8 2.446-23.53 5.216-35.17 8.46-5.82 1.64-11.57 3.636-17.27 5.845a302.479 302.479 0 0 0-17.01 7.176c-1.41.635-2.82 1.299-4.22 1.979-1.4.663-2.8 1.35-4.2 2.061-2.8 1.378-5.56 2.939-8.34 4.4a161.986 161.986 0 0 0-16.4 10.292l-.87.637c-7.21 5.273-12.78 13.891-13.53 22.133M1543.36 1112.47c1.22-7.92 2.3-15.83 3.26-23.73.48-3.95 1.03-7.91 1.45-11.85l1.29-11.84c.89-7.9 1.65-15.78 2.76-23.7l1.66-11.86.84-5.93.64-5.92c1.68-15.79.02-31.343-2.19-46.861-.66-4.275-1.42-8.542-2.28-12.802M1489.99 928.086l5.72 46.616 2.88 23.307 3 23.301c2.02 15.53 3.95 31.07 6.05 46.59l6.22 46.58"
            />
            <path
                fill="#CB865E"
                d="M1542.8 1113.89c-.1 3.91 2.02 18.68 3.48 28.26a8.19 8.19 0 0 1-1.63 6.21 8.16 8.16 0 0 1-5.64 3.07l-6.97.64c-9.33.91-18.55 2.8-27.49 5.63a72.29 72.29 0 0 1-29.97 2.45c-5.73-.66-10.34-2.81-10.62-5.89-.28-3.03 7.49-6.06 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1542.8 1113.89c-.1 3.91 2.02 18.68 3.48 28.26a8.19 8.19 0 0 1-1.63 6.21 8.16 8.16 0 0 1-5.64 3.07l-6.97.64c-9.33.91-18.55 2.8-27.49 5.63a72.29 72.29 0 0 1-29.97 2.45c-5.73-.66-10.34-2.81-10.62-5.89-.28-3.03 7.49-6.06 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                fill="#CB865E"
                d="M1615.69 787.412c-16.26-6.304-32.18-19.576-12.28-43.134 19.91-23.557 26.38-32.834 31.86-47.115 9.29-24.221 28.42-31.225 28.42-31.225 9.29 6.967 42.54.782 55.14-9.172l19.77 11.891a61.315 61.315 0 0 1 26.13 31.884 61.286 61.286 0 0 1 .04 41.223l-18.82 52.893a36.483 36.483 0 0 0-1.54 18.895 36.464 36.464 0 0 0 8.14 17.121 110.725 110.725 0 0 1 18.88 31.922h-167.8c-7.66-30.162 4.79-55.045 12.06-75.183Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1627.13 847.965a13.76 13.76 0 0 0-10.28 0M1711.18 763.768c-5.36 11.03-17.15 17.893-29.23 20.012-7.21 1.265-14.81 1.03-21.61-1.697a31.11 31.11 0 0 1-11.84-8.427 31.033 31.033 0 0 1-6.69-12.899 30.907 30.907 0 0 1-.07-14.532 30.82 30.82 0 0 1 6.56-12.967"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1603.63 862.596c-7.66-30.162 4.79-55.045 12.06-75.183-16.26-6.304-32.18-19.576-12.28-43.134 19.91-23.557 26.38-32.834 31.86-47.115M1718.83 656.766l19.78 11.891a61.343 61.343 0 0 1 26.12 31.884 61.286 61.286 0 0 1 .04 41.223l-18.82 52.893a36.483 36.483 0 0 0-1.54 18.895 36.464 36.464 0 0 0 8.14 17.121 110.725 110.725 0 0 1 18.88 31.922"
            />
            <path
                fill="#CB865E"
                d="M1771.43 862.594a89.56 89.56 0 0 1 4.4 15.397c10.4 55.41-52.3 115.615-131.42 44.511-24.4-21.936-36.19-41.867-40.78-59.908h167.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1771.43 862.594a89.56 89.56 0 0 1 4.4 15.397c10.4 55.41-52.3 115.615-131.42 44.511-24.4-21.936-36.19-41.867-40.78-59.908"
            />
            <path
                fill="#FFD109"
                d="M1476.33 1145.66c5.72-1.79 11.52-5.49 16.77-9.68 16.2 6.07 36.37 4.55 52.69-2.96.4 2.77.84 5.8 1.34 9.07a9.09 9.09 0 0 1-.19 3.67c-.32 1.2-.88 2.32-1.64 3.3-.76.97-1.72 1.78-2.81 2.38-1.09.59-2.28.95-3.52 1.07l-6.97.64c-9.26.91-18.41 2.78-27.28 5.59a70.37 70.37 0 0 1-20.93 3.05c-3.16-.01-6.32-.19-9.45-.56-6.79-.79-11.2-3.39-11.51-6.79-.27-2.93 4.27-5.89 13.5-8.78Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1545.3 1149.05a8.81 8.81 0 0 1-2.8 2.39c-1.09.6-2.29.96-3.53 1.07l-6.97.64c-9.26.91-18.41 2.78-27.28 5.59a70.323 70.323 0 0 1-20.93 3.05c-3.16-.01-6.32-.19-9.45-.56-6.79-.79-11.2-3.39-11.51-6.79"
            />
            <path
                fill="#CB865E"
                d="M1589.46 956.018c18.14 0 74.69.109 74.69.109l63-.016c1.2 0 9.07-1.244 10.24-1.502a52.263 52.263 0 0 0 31.88-21.161 52.239 52.239 0 0 0 8.9-37.209 52.24 52.24 0 0 0-18.83-33.3 52.253 52.253 0 0 0-36.48-11.535l-7.26.498c-3.02.205-6.03.52-9.03.893l-4.49.578-4.47.681c-2.98.423-5.94.989-8.91 1.504-2.97.493-5.91 1.13-8.87 1.718-11.79 2.446-23.53 5.215-35.17 8.459-5.81 1.64-11.56 3.636-17.27 5.845a304.61 304.61 0 0 0-17 7.177c-1.41.634-2.82 1.298-4.22 1.978-1.41.663-2.81 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.235 164.235 0 0 0-16.4 10.291l-.87.637a29.93 29.93 0 0 0-11.88 19.541 29.884 29.884 0 0 0 5.42 22.215c4.5 6.144 11.48 14.258 19.57 16.137Z"
            />
            <path
                fill="#CB865E"
                d="M1585.8 1114.83c2.47-7.63 4.81-15.28 7.01-22.94 1.11-3.83 2.29-7.66 3.33-11.49l3.17-11.51c2.13-7.66 4.15-15.34 6.51-22.99l3.53-11.47 1.78-5.73 1.58-5.76c4.18-15.34 5.02-30.991 5.32-46.692.07-7.858-.19-15.747-.9-23.677a144.967 144.967 0 0 0-3.94-23.957l-2.57-9.887a24.408 24.408 0 0 0-9.77-13.946 24.409 24.409 0 0 0-31.72 3.446c-4 4.285-6.32 9.864-6.54 15.718l-1.8 47.021-.89 23.511-.76 23.523c-.49 15.68-1.06 31.36-1.48 47.05l-1.3 47.07c-.1 3.54 1.08 6.99 3.33 9.73 2.24 2.74 5.4 4.58 8.89 5.18 3.48.61 7.07-.07 10.11-1.9 3.03-1.83 5.3-4.69 6.39-8.06l.72-2.24Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1618.31 956.127h103.04c18.39 0 35.82-6.359 46.83-21.091.08-.103.16-.206.23-.309M1679.83 857.273c-11.79 2.447-23.53 5.216-35.17 8.46-5.81 1.64-11.56 3.636-17.27 5.845a304.61 304.61 0 0 0-17 7.177c-1.41.634-2.82 1.298-4.22 1.978-1.41.663-2.81 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.235 164.235 0 0 0-16.4 10.291l-.87.637a29.781 29.781 0 0 0-11.86 19.416M1587.05 1110.92c2.01-6.33 3.93-12.68 5.76-19.03 1.11-3.83 2.29-7.66 3.33-11.49l3.17-11.51c2.13-7.66 4.15-15.34 6.51-22.99l3.53-11.47 1.78-5.73 1.58-5.76c4.18-15.34 5.02-30.992 5.32-46.693.07-7.859-.19-15.747-.9-23.677M1562.59 923.945l-1.8 47.021-.89 23.512-.76 23.522c-.49 15.69-1.07 31.36-1.48 47.05l-1.3 47.07"
            />
            <path
                fill="#CB865E"
                d="M1585.6 1113.89c-.1 3.91 2.03 18.68 3.48 28.26a8.01 8.01 0 0 1-.17 3.27 8.115 8.115 0 0 1-1.46 2.94c-.68.87-1.53 1.59-2.5 2.11-.97.53-2.03.86-3.13.96l-6.97.64c-9.34.91-18.55 2.8-27.49 5.63a72.318 72.318 0 0 1-29.97 2.45c-5.74-.66-10.34-2.81-10.63-5.89-.28-3.03 7.5-6.06 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1585.6 1113.89c-.1 3.91 2.03 18.68 3.48 28.26a8.01 8.01 0 0 1-.17 3.27 8.115 8.115 0 0 1-1.46 2.94c-.68.87-1.53 1.59-2.5 2.11-.97.53-2.03.86-3.13.96l-6.97.64c-9.34.91-18.55 2.8-27.49 5.63a72.318 72.318 0 0 1-29.97 2.45c-5.74-.66-10.34-2.81-10.63-5.89-.28-3.03 7.5-6.06 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                fill="url(#D)"
                d="M1519.35 1145.56c6.01-1.88 12.11-5.88 17.57-10.34 16.99 7.09 31.81 4.92 52.05-1.52.45 3.25.57 4.28 1.18 8.28a9.13 9.13 0 0 1-.19 3.68c-.32 1.2-.88 2.32-1.64 3.29-.77.98-1.72 1.79-2.81 2.38-1.09.59-2.29.96-3.52 1.07l-6.97.65a132.3 132.3 0 0 0-27.28 5.59 70.36 70.36 0 0 1-20.93 3.04c-3.16 0-6.32-.19-9.45-.55-6.79-.79-11.2-3.4-11.51-6.8-.27-2.93 4.27-5.88 13.5-8.77Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1588.32 1148.95c-.76.98-1.71 1.79-2.8 2.38-1.09.6-2.29.96-3.53 1.07l-6.97.64c-9.26.91-18.41 2.78-27.28 5.59a70.323 70.323 0 0 1-20.93 3.05c-3.16-.01-6.32-.19-9.45-.56-6.79-.79-11.2-3.39-11.51-6.79"
            />
            <path
                fill="url(#E)"
                d="M1614.01 788.096c-8.09-3.299-18.17-9.26-20.66-19.015-1.94-7.614 1.09-16.22 8.99-25.578 20.41-24.152 26.33-32.893 31.65-46.77 2.99-7.787 9.42-18.228 14.92-23.949.14-.147 1.35-.956 2.62-1.739.37-.235.76-.468 1.12-.68.36-.212.7-.403.99-.555-4.07 7.051-4.95 18.802 6.42 23.57a22.026 22.026 0 0 0 8.68 1.436c10.92 0 21.12-6.507 31.94-13.4 7.28-4.643 14.81-9.444 22.91-12.428a32.72 32.72 0 0 1 10.8-1.713c.77 0 1.53.025 2.26.074.25.017.48.052.73.075.47.043.95.08 1.4.144.15.023.29.073.41.146.01.007.03.007.04.015a60.812 60.812 0 0 1 6.73 4.71 62.58 62.58 0 0 1 5.01 4.512c.66.66 1.3 1.332 1.93 2.018.84.922 1.66 1.859 2.44 2.825l.09.107a62.869 62.869 0 0 1 7 10.813c.04.094.1.187.14.281a63.182 63.182 0 0 1 4.42 11.42c.29 1.032.56 2.069.8 3.114l.18.83a62.254 62.254 0 0 1-2.22 33.805v.015l-18.05 51.941c-4.37 12.278-2.88 26.719 5.59 35.753l.01.012c20.64 21.97 25.62 45.361 27.94 63.292-14.88 0-179.2.875-179.23.714-5.78-30.447-3.98-52.793.24-70.674 1.85-9.677 4.9-19.087 9.08-28.013v-.002c.94-2.462 1.84-4.806 2.68-7.102v-.004Z"
            />
            <g filter="url(#F)">
                <path
                    fill="url(#G)"
                    d="m1716.11 754.478-5.08 9.293c-5.35 11.03-17.15 17.893-29.23 20.012-7.21 1.264-14.81 1.029-21.61-1.698a31.567 31.567 0 0 1-11.79-8.469 31.64 31.64 0 0 1-6.74-12.857 31.507 31.507 0 0 1-.01-14.517 31.407 31.407 0 0 1 6.5-12.982l6.91-10.635"
                />
            </g>
            <g filter="url(#H)">
                <path
                    fill="url(#I)"
                    d="M1738.13 830.828c-13.27 13.843-34.13 18.961-52.35 22.249-23.31 4.204-46.66 4.704-69.8-.859-13.13-3.157-7.57-23.412 5.58-20.25 19.31 4.64 39.16 4.371 58.64.859 14.48-2.613 32.6-5.913 43.08-16.849 9.37-9.776 24.21 5.087 14.85 14.85Z"
                />
                <path
                    fill="url(#J)"
                    d="M1730.37 827.133c-15.42 2.199-30.85 3.182-46.39 1.659-15.55-1.523-30.2-6.011-45.12-10.36-12.95-3.773-7.44-24.044 5.58-20.25 12.99 3.784 26.01 8.284 39.54 9.61a171.47 171.47 0 0 0 40.81-.909c5.61-.8 11.28 1.377 12.92 7.333 1.35 4.925-1.69 12.111-7.34 12.917Z"
                />
            </g>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1723.59 668.992c-8.1 2.984-15.63 7.785-22.92 12.429-10.81 6.892-21.01 13.399-31.93 13.399a20.71 20.71 0 0 1-8.68-1.436c-10.51-4.721-10.92-12.989-8.37-19.636.29-.957.69-1.873 1.21-2.726M1664.23 694.711a241.112 241.112 0 0 1-16.77 23.121"
            />
            <path
                fill="url(#K)"
                d="M1599.02 857.228v-.142h172.02c7.3 16.332 10.2 33.084 10.2 44.469 0 34.798-30.29 54.278-46.04 56.302-27.02 0-11.64-.108-35.72-.108-92.24-13.627-126.36-68.567-188.81-65.53 5.63-3.904 12.04-8.489 28.55-15.687 23.87-10.408 45.15-16.418 59.8-19.304Z"
            />
            <path
                fill="#CB865E"
                d="M1714.01 714.145c1.6 8.21 2.79 16.498 4.15 24.752 1.36 8.266 3.22 16.44 5.58 24.478 2.29 8.077 4.78 16.115 7.56 24.098 2.81 7.977 5.81 15.917 9.47 23.732a19.056 19.056 0 0 0 10.78 9.852 19.106 19.106 0 0 0 14.6-.659 19.04 19.04 0 0 0 7.69-6.576 19.152 19.152 0 0 0 3.27-9.585c.5-8.613.36-17.101.02-25.551-.37-8.445-1.03-16.835-1.89-25.186a205.684 205.684 0 0 0-3.87-24.804c-1.79-8.174-3.75-16.314-5.31-24.531a26.464 26.464 0 0 0-3.86-9.589 26.5 26.5 0 0 0-7.25-7.379 26.476 26.476 0 0 0-19.85-4.141 26.496 26.496 0 0 0-21.01 20.628c-.72 3.406-.75 6.92-.1 10.339l.02.122Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1756.77 763.248c6.08 0 11-4.788 11-10.694 0-5.906-4.92-10.695-11-10.695-6.07 0-11 4.789-11 10.695 0 5.906 4.93 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M1756.77 754.86c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.309a2.305 2.305 0 0 0 2.3 2.309Z"
            />
            <path
                fill="#CB865E"
                d="m1757.63 788.048-12.15-.319-12.16-.462c-8.1-.271-16.2-.132-24.32.416-4.05.288-8.11.473-12.17.871l-6.08.532c-2.03.181-4.06.431-6.09.643-8.12.903-16.24 1.972-24.36 3.6-3.19.64-6 2.521-7.8 5.229a12.263 12.263 0 0 0 1.43 15.368 12.274 12.274 0 0 0 6.07 3.402c8.08 1.831 16.17 3.103 24.26 4.209 2.03.263 4.05.564 6.07.796l6.07.684c4.05.499 8.1.786 12.15 1.175 8.09.742 16.19 1.084 24.3 1.025l12.16-.158 12.16-.014a18.513 18.513 0 0 0 13.08-5.435 18.547 18.547 0 0 0 5.4-13.089 18.53 18.53 0 0 0-5.26-12.889 18.55 18.55 0 0 0-12.76-5.584Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1713.98 714.023.03.122c1.59 8.21 2.78 16.498 4.15 24.752a203.091 203.091 0 0 0 5.57 24.479c1.97 6.928 4.08 13.828 6.39 20.689M1777.11 804.238c.5-8.613.36-17.101.02-25.551-.37-8.445-1.03-16.835-1.89-25.186a205.684 205.684 0 0 0-3.87-24.804c-1.79-8.174-3.75-16.314-5.31-24.532a37.322 37.322 0 0 0-5.28-12.642M1736.23 787.377l-2.9-.11c-8.11-.271-16.21-.132-24.33.416-4.05.288-8.11.473-12.17.871l-6.08.532c-2.03.181-4.06.431-6.09.643-8.12.903-16.23 1.972-24.36 3.6-3.19.64-6 2.521-7.8 5.229a12.244 12.244 0 0 0-1.82 9.214c.47 2.321 1.6 4.458 3.25 6.154a12.274 12.274 0 0 0 6.07 3.402c8.08 1.831 16.17 3.103 24.26 4.209 2.03.263 4.05.564 6.07.796l6.07.684c4.05.499 8.1.786 12.15 1.175 8.09.742 16.19 1.084 24.3 1.025l12.16-.158 12.16-.014c10.22-.012 20.53-8.6 19.93-20.807"
            />
            <path
                fill="#CB865E"
                d="M1591.09 819.373c.18-.117.36-.233.53-.349l-.54-.164a2.518 2.518 0 0 1-1.69-2.175c.01-.525.17-1.037.46-1.474.29-.437.7-.78 1.18-.99 3.59-1.33 6.5-3.972 9.53-6.311 7-5.419 14.73-11.455 23.56-12.244l33.75-3.016c18.33-1.203 19.46 20.231 6.06 25.428-6.82 2.961-13.84 5.443-20.85 7.922-11.51 4.074-22.29 8.143-34.36 8.963-.45.026-.88-.122-1.22-.412a1.73 1.73 0 0 1-.61-1.147v-.038c-.04-.37.04-.742.23-1.061.19-.32.48-.571.82-.715a108.312 108.312 0 0 0 21.41-12.815l-9.98 3.063c-6.94 2.13-13.94 4.272-21.17 4.971a3.298 3.298 0 0 1-2.6-.621 2.905 2.905 0 0 1-.83-1.175 3.03 3.03 0 0 1-.19-1.429l.09-.676c-.47.189-.95.379-1.42.569a2.338 2.338 0 0 1-1.8-.025 2.321 2.321 0 0 1-1.24-1.297 2.343 2.343 0 0 1-.05-1.546c.16-.506.49-.942.93-1.236Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1658.39 792.648-33.76 3.017c-8.82.788-16.55 6.824-23.56 12.243-3.03 2.339-5.94 4.982-9.52 6.311M1626.25 811.799a485.231 485.231 0 0 0-32.49 11.681 2.304 2.304 0 0 1-1.79-.025 2.363 2.363 0 0 1-.76-.523 2.368 2.368 0 0 1-.49-.774 2.281 2.281 0 0 1-.04-1.546c.16-.506.48-.942.93-1.236a125.006 125.006 0 0 1 31.67-14.985M1664.44 818.078c-6.82 2.961-13.83 5.443-20.84 7.923-11.52 4.073-22.29 8.142-34.36 8.962a1.74 1.74 0 0 1-1.83-1.558l-.01-.039c-.03-.369.05-.741.24-1.061.19-.32.47-.571.81-.715a108.308 108.308 0 0 0 21.41-12.814l-9.98 3.062c-6.94 2.13-13.94 4.272-21.17 4.972"
            />
            <defs>
                <linearGradient
                    id="b"
                    x1={477.139}
                    x2={820.465}
                    y1={1094.09}
                    y2={898.55}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.229} stopColor="#9B9D9E" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={569.587}
                    x2={765.123}
                    y1={759.633}
                    y2={643.675}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFE4B8" />
                    <stop offset={0.969} stopColor="#E5B97F" />
                    <stop offset={1} stopColor="#E5B97F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={797.851}
                    x2={617.106}
                    y1={698.934}
                    y2={820.961}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.36} stopColor="#FFE4B8" />
                    <stop offset={0.443} stopColor="#E5B97F" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={820.458}
                    x2={944.762}
                    y1={967.268}
                    y2={967.268}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.229} stopColor="#9B9D9E" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={356.624}
                    x2={654.476}
                    y1={1070.07}
                    y2={857.864}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.229} stopColor="#9B9D9E" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={386.084}
                    x2={661.2}
                    y1={1123.37}
                    y2={924.802}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.229} stopColor="#9B9D9E" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={567.145}
                    x2={910.471}
                    y1={1252.12}
                    y2={1056.58}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.229} stopColor="#9B9D9E" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1014.73}
                    x2={1021.85}
                    y1={467.864}
                    y2={444.316}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.184} stopColor="#FCB47F" />
                    <stop offset={0.409} stopColor="#F8B07C" />
                    <stop offset={0.653} stopColor="#EBA473" />
                    <stop offset={0.905} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1053.8}
                    x2={1053.8}
                    y1={455.574}
                    y2={388.377}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={1064.26}
                    x2={1050.47}
                    y1={389.919}
                    y2={389.919}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={1041.95}
                    x2={1019.62}
                    y1={390.132}
                    y2={390.132}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={1097.18}
                    x2={1048.17}
                    y1={596.01}
                    y2={457.648}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.203} stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={1123.13}
                    x2={1077.59}
                    y1={849.993}
                    y2={483.053}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.203} stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={938.609}
                    x2={1115.89}
                    y1={764.243}
                    y2={764.243}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={996.859}
                    x2={1112.72}
                    y1={898.268}
                    y2={898.268}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={1039.66}
                    x2={876.572}
                    y1={903.733}
                    y2={903.733}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={912.657}
                    x2={1107.72}
                    y1={602.877}
                    y2={602.877}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#0197D6" />
                    <stop offset={0.558} stopColor="#22ADE0" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={1036.53}
                    x2={1115.89}
                    y1={591.607}
                    y2={591.607}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={964.112}
                    x2={1131.32}
                    y1={697.512}
                    y2={605.777}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={1084.21}
                    x2={1184.52}
                    y1={551.163}
                    y2={551.163}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={1088.85}
                    x2={1184.93}
                    y1={554.292}
                    y2={554.292}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={1059.95}
                    x2={799.709}
                    y1={640.609}
                    y2={345.669}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={965.416}
                    x2={1038.51}
                    y1={1141.09}
                    y2={1141.09}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={1699.82}
                    x2={1718.66}
                    y1={1020.86}
                    y2={1020.86}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.41} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={1632.1}
                    x2={1786.38}
                    y1={976.937}
                    y2={976.937}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.287} stopColor="#FFD100" />
                    <stop offset={0.424} stopColor="#FFBA2B" />
                    <stop offset={0.468} stopColor="#FFB33B" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={1693.95}
                    x2={1724.52}
                    y1={1078.03}
                    y2={1078.03}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.41} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={1687.04}
                    x2={1672.62}
                    y1={669.442}
                    y2={645.441}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#CB865E" />
                    <stop offset={0.325} stopColor="#C7835C" />
                    <stop offset={0.604} stopColor="#BA7B57" />
                    <stop offset={0.891} stopColor="#A46E4F" />
                    <stop offset={0.999} stopColor="#9A684B" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={1648.42}
                    x2={1648.42}
                    y1={647.316}
                    y2={584.052}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#CB865E" />
                    <stop offset={0.325} stopColor="#C7835C" />
                    <stop offset={0.604} stopColor="#BA7B57" />
                    <stop offset={0.891} stopColor="#A46E4F" />
                    <stop offset={0.999} stopColor="#9A684B" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={1590.26}
                    x2={1505.84}
                    y1={1147.69}
                    y2={1147.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1860.44}
                    x2={1487.67}
                    y1={800.788}
                    y2={761.437}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.192} stopColor="#EF0025" />
                    <stop offset={0.918} stopColor="#FFB33A" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1731.07}
                    x2={1618.65}
                    y1={834.546}
                    y2={834.546}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF0025" />
                    <stop offset={0.264} stopColor="#EF0025" stopOpacity={0.712} />
                    <stop offset={0.748} stopColor="#EF0025" stopOpacity={0.204} />
                    <stop offset={0.961} stopColor="#EF0025" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1727.66}
                    x2={1641.51}
                    y1={813.619}
                    y2={813.619}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF0025" />
                    <stop offset={0.264} stopColor="#EF0025" stopOpacity={0.712} />
                    <stop offset={0.748} stopColor="#EF0025" stopOpacity={0.204} />
                    <stop offset={0.961} stopColor="#EF0025" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1829.32}
                    x2={1482.38}
                    y1={917.346}
                    y2={880.721}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.192} stopColor="#EF0025" />
                    <stop offset={0.918} stopColor="#FFB33A" />
                </linearGradient>
                <filter
                    id="F"
                    width={91.305}
                    height={77.894}
                    x={1632.81}
                    y={714.625}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_16961"
                        stdDeviation={4}
                    />
                </filter>
                <filter
                    id="H"
                    width={148.625}
                    height={74.602}
                    x={1600.48}
                    y={789.711}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_16961"
                        stdDeviation={4}
                    />
                </filter>
                <radialGradient
                    id="G"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-159.078 908.299 218.011) scale(77.2109 37.1881)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.433} stopColor="#EF0025" stopOpacity={0} />
                    <stop offset={0.602} stopColor="#EF0025" stopOpacity={0.366} />
                    <stop offset={0.771} stopColor="#EF0025" stopOpacity={0.705} />
                    <stop offset={0.89} stopColor="#EF0025" stopOpacity={0.918} />
                    <stop offset={0.949} stopColor="#EF0025" />
                </radialGradient>
                <clipPath id="a">
                    <path fill="#fff" d="M1973 605.172h178.344v539.755H1973z" />
                </clipPath>
            </defs>
        </svg>
    );
}
