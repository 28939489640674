import { ILegs } from 'interfaces';

import Stand1 from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-stand-1';
import Stand2 from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-stand-2';
import Walk1 from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-walk-1';
import Walk2 from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-walk-2';
import Sitting1Left from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-sitting-1-left';
import Sitting1Right from 'assets/female/clothes/legs/pants-1/female-34-25-pants-1-sitting-1-right';

const pants1: ILegs = {
    name: 'Pants #1',
    stand1: [Stand1],
    stand2: [Stand2],
    walk1: [Walk1],
    walk2: [Walk2],
    sitting1: [Sitting1Right, Sitting1Left],
};

export default pants1;
