import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6834)"
            d="M476.143 208.922a110.644 110.644 0 0118.627 14.398 2.708 2.708 0 01.822 2.483c-.805 4.666-13.808 10.892-16.407 12.093 0 0-14.647 1.88-13.641.722 11.756-13.547 12.47-21.795 10.599-29.696z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6834)"
            d="M482.603 278.845c-5.01-2.651-10.191-5.391-15.366-7.904a27.504 27.504 0 0112.027-52.246 27.237 27.237 0 0111.989 2.767l.115.056c5.164 2.537 10.51 4.936 15.68 7.256-9.12 4.975-25.24 29.12-24.445 50.071z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6834"
                x1="492.836"
                x2="445.342"
                y1="213.191"
                y2="248.324"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6834"
                x1="504.04"
                x2="456.546"
                y1="228.337"
                y2="263.47"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
