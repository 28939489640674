import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#63666A"
            d="M527.498 376.672l-173.201 15.792v65.883h157.281a20 20 0 0020-20v-36.516a17.859 17.859 0 00-.263-3.044l-3.817-22.115z"
        ></path>
    </g>
);

export default SvgComponent;
