import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#2B2729"
                d="M628.281 673.588c-2.938-1.236-8.541-3.558-13.041-5.226-16.98-6.296-35.477-26.135-39.647-30.777l-.208-22.831h-41.666l.891 3.322a885.19 885.19 0 005.54 19.937c-.208 6.329-1.493 19.048-2.539 28.644a9.897 9.897 0 002.33 7.567 9.92 9.92 0 007.112 3.5l20.303 1.017a41.114 41.114 0 0112.374 2.507 70.775 70.775 0 0020.641 4.146c.318.018.7.027 1.136.027 5.149 0 18.056-1.225 25.292-1.954a5.16 5.16 0 004.63-5.338 5.164 5.164 0 00-3.148-4.541z"
            ></path>
            <path
                fill="url(#paint0_linear_10892_7190)"
                d="M628.01 672.915c-2.938-1.236-8.541-3.558-13.041-5.226-7.938-2.943-16.208-8.846-23.19-14.773a168.63 168.63 0 01-7.966-7.232c-4.688 1.386-11.718 9.924-18.41 9.951-13.086.054-22.265-5.783-26.397-6.09a958.238 958.238 0 01-1.665 16.439 9.883 9.883 0 002.33 7.566 9.898 9.898 0 007.111 3.5l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.736 70.736 0 0020.64 4.146c.318.018.7.027 1.136.027 5.149 0 18.056-1.224 25.292-1.954a5.159 5.159 0 004.63-5.338 5.167 5.167 0 00-3.148-4.54z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7190"
                    x1="537.28"
                    x2="631.162"
                    y1="665.216"
                    y2="665.216"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#004F71"></stop>
                    <stop offset="0.125" stopColor="#01567A"></stop>
                    <stop offset="1" stopColor="#0B86B8"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
