import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m431.256 843.742 8.096 31.59 7.112.302-.651-9.482"
                fill="#63666A"
            />
            <path
                d="M439.354 875.328h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m437.915 825.501 30.319-.032-1.245-3.235-34.75-.101-2.771 12.607a20.565 20.565 0 0 0 6.262 19.301c.924.861 5.42 5.364 5.42 5.364s.468-7.43.728-9.747c.51-4.545 1.038-9.245.297-13.886a27.77 27.77 0 0 0-4.26-10.271Z"
                fill="url(#female-34-25-shoes-8-walk-2-a)"
            />
            <path
                d="M480.865 852.147s-2.467-.202-3.869-.202a67.135 67.135 0 0 0-9.439.67 34.618 34.618 0 0 0-9.828 2.673 18.258 18.258 0 0 0-7.917 6.684 17.982 17.982 0 0 0-2.258 5.821l-.095.436 1.524 2.108a15.043 15.043 0 0 0 12.215 6.063h1.074l-.007-1.008a12.324 12.324 0 0 1 4.338-9.398 16.263 16.263 0 0 1 6.887-3.135 51.422 51.422 0 0 1 7.891-.917l9.997-.637-10.513-9.158Z"
                fill="url(#female-34-25-shoes-8-walk-2-b)"
            />
            <path
                d="M492.923 873.841a9.323 9.323 0 0 1-6.504 2.561H461.2a15.027 15.027 0 0 1-12.214-6.062s-15.174-19.202-16.207-20.934"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m330.243 802.852-8.36 30.23 4.226 2.736 7.507-13.608a4.549 4.549 0 0 1 5.277-2.161c.66.197 1.266.54 1.773 1.005"
                fill="#63666A"
            />
            <path
                d="m321.883 833.086 4.226 2.736"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M342.369 810.435c3.01-7.952 8.459-15.442 4.688-26.66l28.276 14.154-.245-3.904-31.5-16.048-12.09 18.108c-3.975 5.932-2.311 13.85 3.168 18.43l4.702 3.927a18.881 18.881 0 0 1 3.187 3.316c-.309-3.079-1.702-7.322-.186-11.323ZM380.582 839.113s-2.973-.574-3.979-.678a21.449 21.449 0 0 0-12.669 2.215 14.634 14.634 0 0 0-7.085 8.794l-.161.615.489.406a24.133 24.133 0 0 0 2.888 2.094l4.688 2.86c2.223 1.355 3.535 1.389 6.059 2.292l.914.328.354-.905a14.64 14.64 0 0 1 7.705-8.063c1.85-.801 9.453-.904 9.453-.904l-8.656-9.054Z"
                fill="#888B8D"
            />
            <path
                d="M389.237 861.205c-1.336.629-4.872-.289-6.372-.688-2.737-.729-5.961-1.583-8.698-2.312-3.607-.961-10.91-3.323-14.1-5.643-4.802-3.493-8.829-7.919-10.585-13.592l-3.087-9.977a22.326 22.326 0 0 0-7.025-10.551l-4.702-3.927a15.923 15.923 0 0 1-4.967-9.64"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-8-walk-2-a"
                    x1={429.046}
                    y1={840.769}
                    x2={468.233}
                    y2={840.769}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-8-walk-2-b"
                    x1={447.459}
                    y1={864.172}
                    x2={491.378}
                    y2={864.172}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
            </defs>
        </g>
    );
}
