import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M536.762 507.297c-.689-8.696-1.405-15.741-2.253-22.172-.453-3.419-.967-7.293-1.617-11.03-.16-.95-.316-1.9-.47-2.849-.435-2.671-.885-5.434-1.461-8.13l-1.089-5.49-1.26-5.442c-.964-4.059-1.939-7.619-2.979-10.882l-1.55-4.902a51.935 51.935 0 0 0-22.406-28.595h-54.161a52.053 52.053 0 0 0-24.91 47.553c.227 3.78.564 7.527 1.002 11.14l.68 5.54.818 5.483c.376 2.737.866 5.495 1.34 8.162.168.945.336 1.89.498 2.828.449 2.742 1.016 5.505 1.565 8.176.191.928 1.686 8.268 1.686 8.268l1.262 5.441c.305 1.282.599 2.564.893 3.846.529 2.308 1.076 4.693 1.681 7.028 1.704 6.831 3.808 13.909 6.436 21.636 1.327 3.905 2.784 7.854 4.194 11.672 1.175 3.183 2.39 6.474 3.521 9.719l3.023 8.57.748 2.121 3.662 10.715 9.297 27.312a129.425 129.425 0 0 0-2.796 2.181c-1.178.922-2.321 1.89-3.427 2.825-.464.393-.928.786-1.423 1.2-.538.484-1.082.962-1.627 1.441-.993.874-2.021 1.777-2.987 2.693-4.992 4.607-9.842 9.518-14.762 14.943a292.54 292.54 0 0 0-2.37 2.645c-11.861 13.732-21.165 25.76-28.011 39.811-2.479 4.885-4.73 10.033-6.908 15.011a580.131 580.131 0 0 1-3.353 7.571c-2.455 5.525-5.121 11.027-7.7 16.348-.955 1.97-1.907 3.941-2.856 5.914l-5.359 11.084a228.134 228.134 0 0 0-1.728 3.625l.033.167 33.415 31.37 21.946-27.73 36.62-46.271 15.488-18.662 30.958-37.337a27.451 27.451 0 0 0 5.634-11.663 28.799 28.799 0 0 0 1.167-3.45l1.485-5.568c4.51-16.906 6.741-33.516 8.184-46.112.135-1.344.274-2.689.416-4.034.257-2.45.514-4.9.733-7.351l.939-11.342c.5-6.228 1.071-14.431 1.057-22.649-.004-7.17-.313-14.699-.918-22.378Z"
            fill="#001489"
        />
        <path
            d="m399.758 697.671 40.19 40.19 11.653-14.724-43.52-43.52c-.19.379-.384.753-.57 1.135-2.479 4.885-4.73 10.033-6.908 15.011-.279.637-.566 1.271-.845 1.908Z"
            fill="#9E005D"
        />
        <path
            d="M351.492 407.805a83.542 83.542 0 0 0-1.534 6.611 68.063 68.063 0 0 0 1.168 29.89 53.104 53.104 0 0 0-.586 5.998 178.852 178.852 0 0 0-.092 10.957l.138 5.464.278 5.422c.106 2.701.324 5.432.535 8.074.075.94.15 1.881.221 2.813.179 2.717.474 5.465.759 8.122.099.921.87 8.216.87 8.216l.725 5.424c.179 1.281.346 2.562.514 3.844.301 2.295.612 4.668.986 6.999 1.028 6.822 2.431 13.92 4.292 21.699.938 3.927 2.002 7.909 3.031 11.759.859 3.218 1.749 6.546 2.559 9.82l2.708 10.777 2.598 10.79 10.339 43.174c.019.076.04.153.062.229a112.183 112.183 0 0 0-3.192 11.309 116.12 116.12 0 0 0-.937 4.311c-.122.597-.243 1.194-.377 1.827-.122.71-.253 1.421-.384 2.131a108.879 108.879 0 0 0-.677 3.944 237.886 237.886 0 0 0-2.552 24.308c-.804 18.046-.656 33.183 2.673 48.383 1.081 5.343 11.895 60.438 11.961 60.738l46.187-.332.502-14.181c.384-10.375.699-20.92 1.003-31.118.171-5.72.346-11.442.524-17.164l.737-24.142.597-24.133 1.172-48.265c.013-.553.001-1.104-.022-1.653a314.56 314.56 0 0 0 11.963-42.32c.265-1.296.535-2.592.808-3.888.495-2.366.99-4.732 1.447-7.102l2.043-10.969c1.105-6.023 2.475-13.965 3.265-21.98.695-6.992 1.123-14.36 1.272-21.906l.007-.368c.379-19.645 9.94-38.132 24.577-51.241l.245-.22c20.844-18.735 32.534-36.083 38.42-52.051H351.492Z"
            fill="url(#female-34-25-pants-7-stand-2-a)"
        />
        <path
            d="M383.211 758.627h51.328a4743.6 4743.6 0 0 0 .588-18.65h-55.584l3.668 18.65Z"
            fill="url(#female-34-25-pants-7-stand-2-b)"
        />
        <path
            d="M383.211 758.627h51.328a4743.6 4743.6 0 0 0 .588-18.65h-55.584l3.668 18.65Z"
            fill="url(#female-34-25-pants-7-stand-2-c)"
        />
        <path
            d="M375.616 720.1c.084.415.226 1.131.418 2.098h59.632l.127-4.155.442-14.496h-63.079a127.518 127.518 0 0 0 2.46 16.553Z"
            fill="url(#female-34-25-pants-7-stand-2-d)"
        />
        <path
            d="M375.616 720.1c.084.415.226 1.131.418 2.098h59.632l.127-4.155.442-14.496h-63.079a127.518 127.518 0 0 0 2.46 16.553Z"
            fill="url(#female-34-25-pants-7-stand-2-e)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-7-stand-2-a"
                x1={332.868}
                y1={595.847}
                x2={470.795}
                y2={591.943}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#001489" />
                <stop offset={1} stopColor="#0147A7" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-2-b"
                x1={369.282}
                y1={749.302}
                x2={447.046}
                y2={749.302}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-2-c"
                x1={554.316}
                y1={757.078}
                x2={360.002}
                y2={746.668}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-2-d"
                x1={358.938}
                y1={712.872}
                x2={448.331}
                y2={712.872}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-stand-2-e"
                x1={556.252}
                y1={720.914}
                x2={361.938}
                y2={710.504}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
