import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#female-34-25-pants-2-stand-2-a)">
            <path
                d="M536.357 507.383c-.69-8.696-1.406-15.741-2.254-22.172-.453-3.419-.966-7.293-1.616-11.03-.16-.95-.317-1.899-.47-2.849-.436-2.671-.886-5.434-1.462-8.13l-1.089-5.49-1.26-5.442c-.964-4.059-1.938-7.619-2.978-10.882l-1.55-4.902c-3.532-11.082-1.762-21.577-1.762-28.595-13.305 0-67.798.061-77.28.061-15.08 9.901-23.595 28.23-22.437 47.491.228 3.78.564 7.528 1.002 11.14l.681 5.54.818 5.484c.375 2.737.865 5.494 1.34 8.162.168.945.336 1.89.498 2.828.448 2.742 1.016 5.504 1.565 8.176l1.685 8.268 1.262 5.441c.305 1.282.599 2.564.893 3.846.53 2.308 1.077 4.693 1.682 7.028 1.703 6.831 3.808 13.909 6.435 21.636 1.327 3.905 2.785 7.854 4.195 11.672 1.175 3.183 2.39 6.474 3.52 9.718l3.771 10.693 3.663 10.714 9.297 27.312a129.425 129.425 0 0 0-2.796 2.181c-1.178.923-2.321 1.89-3.427 2.826-.465.392-.929.785-1.424 1.199-.538.484-1.08.964-1.627 1.441-.993.874-2.02 1.777-2.986 2.693a239.723 239.723 0 0 0-17.131 17.587c-11.862 13.732-21.165 25.76-28.012 39.811-2.479 4.885-4.73 10.033-6.907 15.011-1.106 2.529-2.212 5.058-3.354 7.571-2.454 5.525-5.121 11.027-7.7 16.348-.955 1.971-1.907 3.942-2.856 5.914l-5.359 11.085a231.266 231.266 0 0 0-1.773 3.719l33.493 31.443 58.566-74 15.488-18.662 30.958-37.338a27.453 27.453 0 0 0 5.634-11.662 28.917 28.917 0 0 0 1.167-3.45l1.485-5.568c4.51-16.906 6.742-33.517 8.184-46.113.135-1.343.273-2.688.416-4.033.257-2.45.514-4.9.733-7.351l.939-11.343c.5-6.227 1.071-14.43 1.058-22.648-.005-7.171-.314-14.7-.918-22.379Z"
                fill="#888B8D"
            />
            <path
                d="M351.084 407.891a83.747 83.747 0 0 0-1.534 6.611 68.062 68.062 0 0 0 1.168 29.89 52.896 52.896 0 0 0-.586 5.998 178.852 178.852 0 0 0-.092 10.957l.138 5.465.278 5.422c.106 2.7.324 5.432.535 8.073.076.94.15 1.882.221 2.813.179 2.717.474 5.465.759 8.122.099.921.87 8.217.87 8.217l.725 5.423c.179 1.281.347 2.563.514 3.844.301 2.295.612 4.669.986 6.999 1.028 6.823 2.431 13.921 4.292 21.7.938 3.927 2.002 7.908 3.031 11.759.859 3.218 1.749 6.545 2.559 9.82l2.708 10.777 2.598 10.79 10.339 43.173c.019.077.04.153.062.23a111.92 111.92 0 0 0-3.192 11.309 115.68 115.68 0 0 0-.937 4.311c-.122.597-.243 1.193-.377 1.826-.122.71-.253 1.421-.384 2.132a108.504 108.504 0 0 0-.677 3.944 237.605 237.605 0 0 0-2.552 24.308c-.804 18.046-.656 33.182 2.673 48.382 1.081 5.344 11.895 60.439 11.961 60.739l46.187-.332.502-14.181c.384-10.376.699-20.92 1.003-31.118.171-5.721.345-11.442.524-17.164l.737-24.142.597-24.133 1.172-48.267c.013-.553.001-1.103-.022-1.653a314.623 314.623 0 0 0 11.963-42.32c.265-1.296.534-2.592.807-3.888.496-2.366.99-4.731 1.448-7.102l2.043-10.968c1.105-6.023 2.475-13.966 3.265-21.981.695-6.991 1.123-14.36 1.272-21.905l.007-.368c.378-19.646 9.941-38.133 24.577-51.242l.245-.22c20.844-18.734 32.534-36.082 38.42-52.05H351.084Z"
                fill="url(#female-34-25-pants-2-stand-2-b)"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-pants-2-stand-2-b"
                x1={329.105}
                y1={659.705}
                x2={515.612}
                y2={466.259}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.93} stopColor="#ABADAD" />
            </linearGradient>
            <clipPath id="female-34-25-pants-2-stand-2-a">
                <path fill="#fff" transform="translate(.29 .46)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
