import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string]> = {
    white: ['#FFDBAE', '#E3C094', '#CDAB81'],
    black: ['#6C453A', '#634036', '#4D352C'],
    brown: ['#CB865E', '#B07553', '#9A684B'],
    yellow: ['#FCB47F', '#E19A6D', '#CB865E'],
};


const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-stand-2">
        <path
            fill={SKIN_COLOR[color][0]}
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-2-a)"
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M478.5 631.101c-5.1-15.1-9.8-30.2-14.7-45.3l-7.2-22.7-3.6-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6-4-27.8 15.2-53.6 43-57.7 26-3.8 50.201 12.8 56.601 37.7l1.199 4.9c1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-2.4 32-5.3 48.3l-.8 4.3c-2.9 15.6-17.9 26-33.5 23.1-10.5-1.9-18.7-9.5-22-19Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m527.9 428.501 1.2 4.9c1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-2 35.6-5 51.9m-62.8-14.2L456.7 563l-3.6-11.299-1.8-5.701-1.7-5.7c-4.7-15.1-8.4-30.399-12-45.699-1.8-7.7-3.5-15.3-5-23l-1.2-5.801-1-5.8c-.7-3.9-1.4-7.7-2-11.6"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-2-b)"
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M370.601 387.201c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89.001 13.6 105.301 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.599-11.7 33.299-45.2"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M397.501 817.801c-1.9-8-3.6-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.9 3.3-23.9.4-2 .8-4 1.3-6s.9-4 1.5-6c1.1-4 2.3-8 3.9-11.9l4.4-11.2c5.3-13.5 20.6-20.1 34-14.7 10.8 4.3 17.2 14.9 16.5 25.9l-2.7 47.7-1.4 23.9-1.5 23.9c-1 15.9-2 31.8-3.1 47.7l-3.3 47.7c-.6 8.8-8.2 15.4-17 14.8-7.1-.5-12.8-5.6-14.4-12.2l-.6-2.5Z"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M385.001 626.301c-3.5-15.4-6.7-30.8-9.9-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.8-17.5-11.2-19.8-21Z"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-2-c)"
            d="M385.001 626.301c-3.5-15.4-6.7-30.8-9.9-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.8-17.5-11.2-19.8-21Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m398.101 820.401-.6-2.6c-1.9-8-3.6-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.9 3.3-23.9.4-2 .8-4 1.3-6s2.7-9.9 3.2-11.9m56.901-5.199-2.6 47-1.4 23.899-1.5 23.9c-1 15.9-2 31.801-3.1 47.701l-3.3 47.699m25.4-384.3.699 5.1c.6 3.9 1.001 7.7 1.401 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.801 11.7c-2.6 15.6-6.199 33.4-10.899 49.1m-56.102.7c-3.5-15.4-6.699-30.8-9.899-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.101-5.8c-3.1-15.4-5.199-30.8-7.299-46.3-1-7.7-1.9-15.4-2.6-23.2l-.601-5.8-.399-5.8c-.3-3.9-.6-7.7-.8-11.6m67.301 184h15.5"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M396.9 818.101c.5 4.3-.3 20.9-1 31.6-.3 5.2 3.8 9.5 9 9.5h57.6c2.5 0 4.4-2.001 4.4-4.401 0-1.6-.9-3.1-2.3-3.9-3.8-2-11.2-6.1-15-8.6-8.5-5.7-20.3-24.199-20.3-24.199"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M429.501 818.101s11.801 18.499 20.301 24.199c3.7 2.5 11.2 6.6 15 8.6 1.4.8 2.299 2.3 2.299 3.9 0 2.5-1.999 4.401-4.399 4.401h-57.601c-5.2 0-9.3-4.4-9-9.5.6-10.8 1.5-27.3 1-31.6"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M371.6 764.001c3.7-7.4 7.4-14.6 11.3-21.8 1.9-3.6 3.8-7.2 5.8-10.8l5.9-10.7c3.9-7.1 8-14.2 11.7-21.5 3.8-7.2 7.3-14.7 11.4-21.7 7.9-14.2 18.7-26.1 29.9-37.6 5.7-5.7 11.6-11.1 17.9-16.3 1.6-1.3 3.2-2.501 4.8-3.801 1.7-1.2 3.3-2.499 5-3.599 3.4-2.4 6.9-4.7 10.7-6.7l10.5-5.701c12.7-7 28.7-2.299 35.6 10.401 5.6 10.2 3.7 22.5-3.8 30.5L495.5 679.8l-16.4 17.5-16.5 17.5c-11 11.6-22 23.301-33.1 34.901l-33.2 34.8c-6.1 6.4-16.2 6.6-22.5.5-5.2-4.9-6.3-12.5-3.3-18.6l1.1-2.4Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M535.3 626.301c.1 6.8-2.4 13.5-7.1 18.5l-32.8 35.1-16.4 17.5-16.5 17.5c-11 11.6-22 23.3-33.1 34.9l-35.3 36.6m-23.7-20 1.2-2.4c3.7-7.4 7.4-14.6 11.3-21.8 1.9-3.6 3.8-7.2 5.8-10.8l5.9-10.7c3.9-7.1 8-14.2 11.7-21.5 3.8-7.2 7.3-14.7 11.4-21.7 7.9-14.2 18.7-26.1 29.9-37.6 5.7-5.7 11.6-11.1 17.9-16.3 1.6-1.3 3.2-2.5 4.8-3.8 1.7-1.2 3.3-2.5 5-3.6"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M375.2 759.501c-3.7 2.3-19 8.7-29 12.7-4.8 1.9-7 7.5-4.7 12.2l8.7 18.4c1.9 3.9 3.201 8.1 3.701 12.4.6 4.5 2.2 11.5 6 19.9 1.7 3.7 10.099 16.2 14.099 22.2 1.2 1.8 3.7 2.4 5.6 1.2 1.4-.8 2.2-2.4 2-4-.3-3.3-.9-9.2-1.6-13.9-2.9-21 9-51.7 9-51.7"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M375.2 759.501c-3.7 2.3-19 8.7-29 12.7-4.8 1.9-7 7.5-4.7 12.2l8.7 18.4c1.9 3.9 3.201 8.1 3.701 12.4.6 4.5 2.2 11.5 6 19.9 1.7 3.7 10.099 16.2 14.099 22.2 1.2 1.8 3.7 2.4 5.6 1.2 1.4-.8 2.2-2.4 2-4-.3-3.3-.9-9.2-1.6-13.9-2.9-21 9-51.7 9-51.7m125.102-161.7h15.5"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-legs-stand-2-a"
                x1={322.339}
                x2={635.744}
                y1={431.385}
                y2={431.385}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.445} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.932} stopColor={SKIN_COLOR[color][0]} />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-legs-stand-2-b"
                x1={322.339}
                x2={635.744}
                y1={431.385}
                y2={431.385}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.445} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.932} stopColor={SKIN_COLOR[color][0]} />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-legs-stand-2-c"
                x1={484.702}
                x2={220.604}
                y1={585.258}
                y2={338.982}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.511} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.915} stopColor={SKIN_COLOR[color][2]} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
