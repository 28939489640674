import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-1">
        <path
            d="M402.044 267.288c.163-.709.324-1.417.482-2.123a202.03 202.03 0 0 0 4.208-25.364c.263-2.564.507-5.13.752-7.696.555-5.817 1.129-11.832 1.905-17.717l.019-.15a32.036 32.036 0 0 0-31.785-36.027c-16.055 0-27.732 8.746-33.587 27.396-3.588 11.429-4.119 42.66-4.386 51.427-.173 4.562-.28 8.953-.318 13.236 31.813-9.216 62.71-2.982 62.71-2.982Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-1a)"
        />
        <path
            d="M402.044 267.288c.163-.709.324-1.417.482-2.123a202.03 202.03 0 0 0 4.208-25.364c.263-2.564.507-5.13.752-7.696.555-5.817 1.129-11.832 1.905-17.717l.019-.15a32.036 32.036 0 0 0-31.785-36.027c-16.055 0-27.732 8.746-33.587 27.396-3.588 11.429-4.119 42.66-4.386 51.427-.173 4.562-.28 8.953-.318 13.236 31.813-9.216 62.71-2.982 62.71-2.982Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-1b)"
        />
        <path
            d="M344.035 205.609c-3.588 11.429-4.119 42.659-4.386 51.427a456.178 456.178 0 0 0-.319 13.346 155.367 155.367 0 0 1 32.464-4.484c12.651-.433 30.247 1.39 30.247 1.39.164-.708.324-1.416.482-2.122a202.044 202.044 0 0 0 4.208-25.363c.263-2.564.507-5.13.752-7.697.555-5.817 1.129-11.832 1.905-17.717l.019-.15"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-1a"
                x1={378.833}
                y1={374.305}
                x2={370.159}
                y2={105.389}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.837} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-1b"
                x1={369.854}
                y1={351.096}
                x2={379.613}
                y2={109.723}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
