import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-nose-3" transform="translate(329, 20)">
        <path
            fill={SKIN_COLOR[color]}
            d="M146.75 77.1c.2 5.1-2.1 13.3 5.6 17.4 5.2 2.7 1.1 6.4-.3 7.2-2.5 1.4-5 1.9-6.9 2.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M146.75 77.1c.2 5.1-2.1 13.3 5.6 17.4 5.2 2.7 1.1 6.4-.3 7.2-2.5 1.4-5 1.9-6.9 2.2"
        />
    </g>
);

export default SvgComponent;
