import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6371)"
            d="M541.443 345.689c-1.63-18.895-9.21-33.782-11.622-39.583l-.105-.257a55.634 55.634 0 01-2.741-8.609l-.07.007a42.228 42.228 0 01.717-22.552c1.717-5.35 1.36-12.27-.786-20.088-5.893-21.468-25.639-63.097-52.806-82.95-2.274-1.662-6.155-4.212-8.432-5.505.09 1.419 2.621 22.546 1.141 28.064l-24.451-18.732a100.675 100.675 0 01-32.259-21.687s-23.91 7.699-49.61 27.929c-12.029 9.47-18.829 24.613-18.456 40.175l.457 19.058a327.389 327.389 0 0010.716 77.382c.032 3.805.033 7.485.035 11.171.008 20.126.016 40.936 5.172 83.021l.201 1.645.187-.006.082.667 1.011-.702c9.742-.317 73.289-2.45 114.892-5.399 25.951 1.218 47.667-2.091 56.668-15.129 0 0 12.473-19.942 10.059-47.92z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M468.73 198.184c1.83 1.83 7.27 12.143 12.989 23.563M410.083 154.012c6.994 8.078 22.302 18.188 32.228 21.614l24.552 18.979 8-19.516 7.31 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M442.31 175.629l-9.444 13.074-20.923-22.629"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6371"
                x1="341.948"
                x2="541.754"
                y1="284.318"
                y2="284.318"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100"></stop>
                <stop offset="0.274" stopColor="#FFDA32"></stop>
                <stop offset="0.897" stopColor="#FFF0AE"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
