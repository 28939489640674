import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#EF0025"
                d="M665.646 683.523c-2.8-1.517-8.143-4.376-12.465-6.482-7.43-3.621-14.882-10.057-21.162-16.497a161.295 161.295 0 01-6.037-6.555c-3.415-.419-14.414 4.399-20.169 4.191-11.535-.416-19.614-5.62-26.605-5.828-.84 4.36-1.883 9.51-3.112 15.363a9.887 9.887 0 001.575 7.758 9.908 9.908 0 006.733 4.182l20.105 3.009c4.19.608 8.26 1.859 12.068 3.71a70.714 70.714 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.506 18.088.556 25.361.541a5.161 5.161 0 005.132-4.857 5.173 5.173 0 00-2.685-4.828z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M577.671 675.473a9.892 9.892 0 002.93 2.793 9.896 9.896 0 003.803 1.389l20.105 3.008c4.19.608 8.26 1.86 12.068 3.711a70.777 70.777 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.505 18.088.556 25.361.541"
            ></path>
        </g>
    );
}
