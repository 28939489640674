import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1473}
            viewBox="0 0 2571 1473"
            fill="none"
            id="scenes-kitchen-3"
            {...attributes}
        >
            <path fill="#fff" d="M.102.203h2570v1472H.102z" />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M734.523 1179.27c-4.355 6.07-11.472 10.03-19.513 10.03H240.063c-13.255 0-24.001-10.75-24.001-24V381.508c0-13.255 10.746-24 24.001-24H715.01c13.255 0 24 10.745 24 24v782.782"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M313.684 751.965v18.841h18.841v-18.841M402.504 789.646v-18.841h-31.832v18.841h31.832ZM426.828 726.957v-90.163c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v317.937h50.706V636.794c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v291.789M382.293 676.933v-25.011h-80.589v25.011h80.589ZM301.703 701.945h80.589"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M739.007 1164.29H241.07V382.52h236.463v740.97"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M313.686 751.965h-30.928v86.047h119.748v-67.206h-31.832v48.512"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M426.829 726.953H191.051v136.069h236.463V751.964h-94.989"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1057.2 624.099h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1317.9 277.75V651.7H981.602V287.65h305.548V624.1H1108.2"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1108.2 624.401 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5M1868.88 255.398h-192v133.5h-138l-93 58.5h304.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1541.88 255.398v112.5h117l115.5 79.5v36h-342v-34.5M799 721.898h1357.62l55.5 40.5H975v19.5h1246.12v28.5h-433v105M1807.63 835.898v40.5M1771.63 835.898v40.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <path fill="url(#a)" d="M1850.27 819.375H1285.1v28.537h565.17v-28.537Z" />
            <path
                fill="url(#b)"
                d="M1418.48 1189.29h-16.61l66.44-341.376h36.25l-86.08 341.376Z"
            />
            <path
                fill="url(#c)"
                d="M1716.9 1189.29h16.61l-66.45-341.376h-36.25l86.09 341.376Z"
            />
            <path
                fill="#3A383A"
                d="M1559.38 1189.29h16.61l9.82-341.376h-36.25l9.82 341.376Z"
            />
            <path
                fill="#E2E2E2"
                d="m1685.26 941.398-18.2-93.484h-36.25l23.58 93.484h30.87ZM1480.99 941.398l23.57-93.484h-36.25l-18.19 93.484h30.87Z"
            />
            <path
                fill="#AEAEAF"
                d="m1583.12 941.398 2.69-93.484h-36.25l2.69 93.484h30.87Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1630.81 847.912H1285.1v-28.537h565.17v28.537h-183.21"
            />
            <path
                fill="url(#d)"
                d="M1010.25 1189.3h14l51.03-151.53h-24.21l-40.82 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m1062.25 996.297-11.18 41.473h24.21l13.97-41.473h-27Z"
            />
            <path
                fill="url(#e)"
                d="M1114.25 1189.3h14l16.97-151.53h-24.26l-6.71 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m1122.75 997.297-1.79 40.473h24.26l4.53-40.473h-27Z"
            />
            <path
                fill="#AEAEAF"
                d="M1191.23 996.297h-27l1.29 41.473h24.21l1.5-41.473Z"
            />
            <path
                fill="#3A383A"
                d="m1165.52 1037.77 4.73 151.53h14l5.48-151.53h-24.21Z"
            />
            <path
                fill="#AEAEAF"
                d="m1255.93 996.438-27.11.083 12.5 41.249h24.32l-9.71-41.332Z"
            />
            <path
                fill="#3A383A"
                d="m1301.25 1189.3-35.61-151.53h-24.32l45.93 151.53h14Z"
            />
            <path
                fill="url(#f)"
                d="m1250.25 977.243 1.18.033-1.18-.033c-2.65.015-5.06.053-7.08.053-22.68 0-134.51.535-179.53.823-17.04.109-31.81-11.915-35-28.659-1.96-10.31-3.69-22.783-3.95-36.327l-.85 48.941c-.32 18.804 14.83 34.222 33.64 34.222h243.77c-6.95-18.254-34.11-19.147-51-19.053Z"
            />
            <path
                fill="url(#g)"
                d="M1063.64 978.115c45.02-.287 156.85-.823 179.53-.823 2.02 0 4.43-.038 7.09-.053l-10.06-.286a28.305 28.305 0 0 1-25.78-20.867l-27.24-100.619a52.828 52.828 0 0 0-39.3-36.786c-11.22-2.481-25-4.662-40.63-5.389-11.79-.549-23.08-.156-33.29.712-27.04 2.299-47.99 24.599-48.46 51.728l-.81 47.397c.26 13.544 1.99 26.017 3.95 36.327 3.19 16.744 17.96 28.768 35.01 28.659h-.01Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1063.64 978.115c45.02-.287 156.85-.823 179.53-.823 14.92 0 50.03-2.122 58.08 19h-243.77c-18.81 0-33.96-15.418-33.64-34.222l1.66-96.338c.47-27.129 21.42-49.43 48.45-51.728 10.21-.868 21.51-1.261 33.3-.712a240.28 240.28 0 0 1 40.62 5.389 52.838 52.838 0 0 1 39.31 36.786l27.24 100.619M1688.08 811.065c0 4.416-3.58 7.995-7.99 7.995h-19.83c-4.42 0-8-3.579-8-7.995v-35.096h35.82v29.659"
            />
            <path
                fill="#C0F0FF"
                d="M1684.35 797.516v13.55a4.267 4.267 0 0 1-4.27 4.264h-19.83a4.265 4.265 0 0 1-4.26-4.264v-13.55h28.36Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1397.04 811.065c0 4.416-3.58 7.995-7.99 7.995h-19.83c-4.42 0-8-3.579-8-7.995v-35.096h35.82v29.659"
            />
            <path
                fill="#C0F0FF"
                d="M1393.31 797.516v13.55a4.267 4.267 0 0 1-4.27 4.264h-19.82a4.267 4.267 0 0 1-4.27-4.264v-13.55h28.36Z"
            />
            <path
                fill="url(#h)"
                d="M1511.86 780.968c-1.38-2.122-4.73-2.677-6.49-4.479-.8-.824-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.71-1.619-2.3-.972-4.05-3.895-6.55-4.399-2.43-.492-5.17 1.522-7.74 1.522-2.57 0-5.31-2.014-7.74-1.522-2.5.504-4.25 3.427-6.55 4.399-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.44 3.495-.54 1.139-1.08 2.282-1.88 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.531-.8 3.691-.99 5.667-.05.588.22 1.159.71 1.481l20.17 13.064a4.209 4.209 0 0 1 1.92 3.536v1.939h22v-1.939c0-1.428.73-2.759 1.93-3.536l20.16-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.135-.99-5.666Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1511.86 780.968c-1.38-2.122-4.73-2.677-6.49-4.479-.8-.824-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.71-1.619-2.3-.972-4.05-3.895-6.55-4.399-2.43-.492-5.17 1.522-7.74 1.522-2.57 0-5.31-2.014-7.74-1.522-2.5.504-4.25 3.427-6.55 4.399-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.44 3.495-.54 1.139-1.08 2.282-1.88 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.531-.8 3.691-.99 5.667-.05.588.22 1.159.71 1.481l20.17 13.064a4.209 4.209 0 0 1 1.92 3.536v1.939h22v-1.939c0-1.428.73-2.759 1.93-3.536l20.16-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.135-.99-5.666Z"
            />
            <path
                fill="#FFD100"
                d="M1489.68 788.477h-21.26a7.355 7.355 0 0 0-7.36 7.358 7.356 7.356 0 0 0 7.36 7.358h21.26c4.07 0 7.36-3.295 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1468.42 788.477a7.355 7.355 0 0 0-7.36 7.358 7.356 7.356 0 0 0 7.36 7.358h21.26c4.07 0 7.36-3.295 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358h-12.97"
            />
            <path
                fill="url(#i)"
                d="M1513.2 798.417a21.685 21.685 0 0 1-19.25 11.694h-29.8c-8.1 0-15.52-4.509-19.25-11.694 5.69 0 5.69-2.132 11.38-2.132 5.7 0 5.7 2.132 11.39 2.132s5.69-2.132 11.38-2.132 5.69 2.132 11.39 2.132c5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.38 2.132Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1493.95 810.105h-29.8c-8.1 0-15.52-4.508-19.25-11.693 2.82 0 4.24-.524 5.65-1.053M1456.29 796.285c2.84 0 4.26.533 5.69 1.066 1.42.533 2.84 1.066 5.69 1.066 5.69 0 5.69-2.132 11.38-2.132 5.7 0 5.69 2.132 11.39 2.132 5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.38 2.132a21.63 21.63 0 0 1-8.59 8.892"
            />
            <path
                fill="url(#j)"
                d="M1545.44 780.968c-1.38-2.122-4.74-2.677-6.49-4.479-.8-.824-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.72-1.619-2.3-.972-4.04-3.895-6.54-4.399-2.43-.492-5.17 1.522-7.74 1.522-2.57 0-5.31-2.014-7.74-1.522-2.5.504-4.25 3.427-6.55 4.399-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.45 3.495-.53 1.139-1.07 2.282-1.87 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.531-.8 3.691-.99 5.667-.05.588.21 1.159.71 1.481l20.17 13.064a4.209 4.209 0 0 1 1.92 3.536v1.939h22v-1.939c0-1.428.72-2.759 1.92-3.536l20.17-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.135-.99-5.666Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1545.44 780.968c-1.38-2.122-4.74-2.677-6.49-4.479-.8-.824-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.72-1.619-2.3-.972-4.04-3.895-6.54-4.399-2.43-.492-5.17 1.522-7.74 1.522-2.57 0-5.31-2.014-7.74-1.522-2.5.504-4.25 3.427-6.55 4.399-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.45 3.495-.53 1.139-1.07 2.282-1.87 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.531-.8 3.691-.99 5.667-.05.588.21 1.159.71 1.481l20.17 13.064a4.209 4.209 0 0 1 1.92 3.536v1.939h22v-1.939c0-1.428.72-2.759 1.92-3.536l20.17-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.135-.99-5.666Z"
            />
            <path
                fill="#FFD100"
                d="M1523.26 788.477h-21.27c-4.06 0-7.35 3.294-7.35 7.358 0 4.063 3.29 7.358 7.35 7.358h21.27c4.07 0 7.36-3.295 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1501.99 788.477c-4.06 0-7.35 3.294-7.35 7.358 0 4.063 3.29 7.358 7.35 7.358h21.27c4.07 0 7.36-3.295 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358h-12.97"
            />
            <path
                fill="url(#k)"
                d="M1546.78 798.417a21.697 21.697 0 0 1-19.25 11.694h-29.8c-8.1 0-15.52-4.509-19.25-11.694 5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.39 2.132c5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.38 2.132c5.7 0 5.7-2.132 11.39-2.132s5.69 2.132 11.38 2.132Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1527.53 810.105h-29.8c-8.1 0-15.52-4.508-19.25-11.693 2.82 0 4.24-.524 5.65-1.053M1489.86 796.285c2.85 0 4.27.533 5.7 1.066 1.42.533 2.84 1.066 5.69 1.066 5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.38 2.132c5.7 0 5.7-2.132 11.39-2.132s5.69 2.132 11.38 2.132a21.692 21.692 0 0 1-8.59 8.892"
            />
            <path
                fill="url(#l)"
                d="M1635.65 780.96c-1.38-2.122-4.73-2.677-6.49-4.479-.8-.823-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.72-1.619-2.3-.972-4.04-3.895-6.54-4.398-2.43-.492-5.17 1.521-7.74 1.521-2.57 0-5.31-2.013-7.74-1.521-2.5.503-4.25 3.426-6.55 4.398-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.45 3.495-.53 1.139-1.07 2.283-1.87 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.532-.8 3.691-.99 5.667-.05.588.21 1.159.71 1.481l20.17 13.064a4.21 4.21 0 0 1 1.92 3.537v1.938h22v-1.938c0-1.429.73-2.76 1.92-3.537l20.17-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.134-.99-5.666Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1635.65 780.96c-1.38-2.122-4.73-2.677-6.49-4.479-.8-.823-1.33-1.973-1.88-3.112l-.01-.006c-.64-1.361-1.29-2.705-2.43-3.483-2.07-1.419-5.38-.635-7.72-1.619-2.3-.972-4.04-3.895-6.54-4.398-2.43-.492-5.17 1.521-7.74 1.521-2.57 0-5.31-2.013-7.74-1.521-2.5.503-4.25 3.426-6.55 4.398-2.33.984-5.64.2-7.71 1.619-1.14.778-1.8 2.134-2.45 3.495-.53 1.139-1.07 2.283-1.87 3.106-1.76 1.802-5.12 2.357-6.49 4.479-.99 1.532-.8 3.691-.99 5.667-.05.588.21 1.159.71 1.481l20.17 13.064a4.21 4.21 0 0 1 1.92 3.537v1.938h22v-1.938c0-1.429.73-2.76 1.92-3.537l20.17-13.063c.5-.322.77-.894.71-1.483-.19-1.976 0-4.134-.99-5.666Z"
            />
            <path
                fill="#FFD100"
                d="M1613.47 788.469h-21.26a7.355 7.355 0 0 0-7.36 7.358 7.355 7.355 0 0 0 7.36 7.358h21.26c4.07 0 7.36-3.294 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1592.21 788.469a7.355 7.355 0 0 0-7.36 7.358 7.355 7.355 0 0 0 7.36 7.358h21.26c4.07 0 7.36-3.294 7.36-7.358a7.355 7.355 0 0 0-7.36-7.358h-12.97"
            />
            <path
                fill="url(#m)"
                d="M1636.99 798.409a21.685 21.685 0 0 1-19.25 11.694h-29.8c-8.09 0-15.52-4.509-19.25-11.694 5.69 0 5.69-2.132 11.39-2.132 5.69 0 5.69 2.132 11.38 2.132s5.69-2.132 11.39-2.132c5.69 0 5.69 2.132 11.38 2.132s5.69-2.132 11.38-2.132 5.69 2.132 11.38 2.132Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1617.74 810.098h-29.8c-8.09 0-15.52-4.509-19.25-11.694 2.82 0 4.25-.524 5.66-1.052M1580.07 796.277c2.85 0 4.27.533 5.7 1.066 1.42.533 2.84 1.066 5.69 1.066 5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.39 2.132c5.69 0 5.69-2.132 11.38-2.132s5.69 2.132 11.38 2.132a21.7 21.7 0 0 1-8.59 8.893"
            />
            <path
                fill="#E4002B"
                d="M1557.55 774.415c4.2 0 7.6-3.403 7.6-7.6 0-4.198-3.4-7.6-7.6-7.6-4.19 0-7.6 3.402-7.6 7.6 0 4.197 3.41 7.6 7.6 7.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1562.46 762.8 1.56-1.566"
            />
            <path
                fill="#E4002B"
                d="M1557.55 738.638c4.2 0 7.6-3.403 7.6-7.6 0-4.198-3.4-7.6-7.6-7.6-4.19 0-7.6 3.402-7.6 7.6 0 4.197 3.41 7.6 7.6 7.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1562.46 727.023 1.56-1.566"
            />
            <path
                fill="#E4002B"
                d="M1535.43 757.095c4.2 0 7.6-3.403 7.6-7.6 0-4.198-3.4-7.6-7.6-7.6-4.2 0-7.6 3.402-7.6 7.6 0 4.197 3.4 7.6 7.6 7.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1540.34 745.48 1.56-1.566"
            />
            <path
                fill="#E4002B"
                d="M1578.74 757.095c4.2 0 7.6-3.403 7.6-7.6 0-4.198-3.4-7.6-7.6-7.6-4.2 0-7.6 3.402-7.6 7.6 0 4.197 3.4 7.6 7.6 7.6Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1583.64 745.48 1.57-1.566"
            />
            <path
                fill="url(#n)"
                d="M1565.4 809.965h-125.67a30.133 30.133 0 0 0 21.57 9.093h158.47c8.12 0 15.9-3.278 21.58-9.093h-75.95Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1609.41 819.058h10.36c8.13 0 15.9-3.278 21.58-9.093h-201.62a30.133 30.133 0 0 0 21.57 9.093h131.01"
            />
            <path
                fill="#5BC2E7"
                d="M1397.43 677.594h16.74c.71 0 1.29.577 1.29 1.289v7.33h-19.32v-7.33c0-.712.58-1.289 1.29-1.289Z"
            />
            <path
                fill="url(#o)"
                d="M1388.44 701.598a30.06 30.06 0 0 0-6.44 18.586v29.852c0 .796.3 1.564.84 2.151 3.98 4.339 3.98 11.052 0 15.392a3.174 3.174 0 0 0-.84 2.15v41.308a8.025 8.025 0 0 0 8.03 8.027h31.55c4.43 0 8.03-3.594 8.03-8.027v-41.308c0-.796-.3-1.564-.84-2.15-3.98-4.34-3.98-11.052 0-15.392.54-.586.84-1.354.84-2.151v-29.852a30.06 30.06 0 0 0-6.44-18.586h-34.73Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1397.43 677.594h16.74c.71 0 1.29.577 1.29 1.289v7.33h-19.32v-7.33c0-.712.58-1.289 1.29-1.289Z"
            />
            <path fill="#5BC2E7" d="M1429.61 720.184H1382v24.995h47.61v-24.995Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1392.71 807.031h26.2M1392.71 796.684h26.2M1392.71 786.344h26.2M1392.71 776.008h26.2M1429.61 783.662v-13.937c0-.796-.3-1.563-.84-2.15-3.98-4.339-3.98-11.052 0-15.391.54-.587.84-1.354.84-2.151v-29.852a30.06 30.06 0 0 0-8.47-20.91l-6.68-6.904c-.58-.599-.91-1.4-.91-2.234v-3.922h-15.5v3.922c0 .834-.32 1.635-.9 2.234l-2.16 2.228M1388.44 701.598a30.069 30.069 0 0 0-6.44 18.588v29.853c0 .796.3 1.564.84 2.15 3.98 4.34 3.98 11.052 0 15.392a3.174 3.174 0 0 0-.84 2.15v41.308a8.025 8.025 0 0 0 8.03 8.027h31.55c4.43 0 8.03-3.594 8.03-8.027v-10.756"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={1836.52}
                    x2={1304.47}
                    y1={833.643}
                    y2={833.643}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.515} stopColor="#FFD100" />
                    <stop offset={0.517} stopColor="#FFD101" />
                    <stop offset={0.58} stopColor="#FFDD45" />
                    <stop offset={0.637} stopColor="#FFE77B" />
                    <stop offset={0.687} stopColor="#FFEEA2" />
                    <stop offset={0.726} stopColor="#FFF2BA" />
                    <stop offset={0.75} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={1401.87}
                    x2={1504.56}
                    y1={1018.6}
                    y2={1018.6}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1630.81}
                    x2={1733.51}
                    y1={1018.6}
                    y2={1018.6}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1010.25}
                    x2={1075.28}
                    y1={812.044}
                    y2={812.044}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1114.25}
                    x2={1145.22}
                    y1={812.044}
                    y2={812.044}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1082.47}
                    x2={1212.69}
                    y1={1035.22}
                    y2={904.995}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.413} stopColor="#FFF4C3" />
                    <stop offset={0.453} stopColor="#FFEC96" />
                    <stop offset={0.544} stopColor="#FFD828" />
                    <stop offset={0.576} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={1057.88}
                    x2={1195.38}
                    y1={812.04}
                    y2={855.216}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.698} stopColor="#FFEA8B" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={1479.05}
                    x2={1479.05}
                    y1={833.637}
                    y2={785.964}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1508.97}
                    x2={1477.46}
                    y1={830.848}
                    y2={797.318}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1512.63}
                    x2={1512.63}
                    y1={833.637}
                    y2={785.964}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={1542.54}
                    x2={1511.03}
                    y1={830.848}
                    y2={797.318}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={1602.84}
                    x2={1602.84}
                    y1={833.63}
                    y2={785.956}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={1632.76}
                    x2={1601.25}
                    y1={830.84}
                    y2={797.31}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={1439.73}
                    x2={1641.35}
                    y1={814.512}
                    y2={814.512}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={1453.33}
                    x2={1387.36}
                    y1={893.217}
                    y2={713.388}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
            </defs>
        </svg>
    );
}
