import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-1-left">
        <path
            d="m465.74 167.402 2.654.952c13.05 4.676 23.788 10.341 32.825 17.318a2.67 2.67 0 0 1 1.092 2.342c-.25 4.648-12.214 12.209-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-lefta)"
        />
        <path
            d="M545.092 275.991c-8.441-2.884-16.984-6.09-26.118-9.802a236.592 236.592 0 0 1-25.19-11.747 1023.99 1023.99 0 0 1-7.33-3.989c-5.554-3.036-11.298-6.176-17.036-9.072a32.032 32.032 0 0 1-14.154-42.984 31.87 31.87 0 0 1 28.594-17.592 31.659 31.659 0 0 1 14.39 3.437l.128.066c5.719 2.917 11.638 5.694 17.362 8.381 2.526 1.184 5.05 2.375 7.57 3.571a236.552 236.552 0 0 1 24.358 13.394c8.385 5.176 16.017 10.177 23.326 15.285-22.709.829-27.553 37.046-25.9 51.052Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-leftb)"
        />
        <path
            d="M486.456 250.454c2.441 1.335 4.884 2.664 7.329 3.988a236.487 236.487 0 0 0 25.19 11.747c9.134 3.712 17.677 6.918 26.118 9.803-1.652-14.006 3.191-50.223 25.901-51.053-7.309-5.107-14.941-10.108-23.326-15.284a236.552 236.552 0 0 0-24.358-13.394 1004.107 1004.107 0 0 0-7.57-3.572c-4.74-2.225-9.609-4.517-14.391-6.894-.046-.04-.079-.086-.128-.123-9.037-6.977-19.775-12.642-32.825-17.318l-2.654-.952"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-lefta"
                x1={524.154}
                y1={307.994}
                x2={485.769}
                y2={183.079}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-leftb"
                x1={536.868}
                y1={304.087}
                x2={498.482}
                y2={179.172}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
