import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#E2E2E2"
                d="M652.706 698.983c-23.445 2.915-37.745-4.933-39.712-6.097l-24.991-2.11a9.094 9.094 0 01-8.243-10.356c1.402-14.305 2.889-20.884 2.889-25.318l34.718 1.378c.753.78 18.007 21.31 33.22 26.951 9.069 3.363 13.82 5.735 13.571 8.669-.287 3.403-4.675 6.041-11.452 6.883z"
            ></path>
            <path
                fill="#001489"
                d="M621.024 658.695a3.102 3.102 0 012.386 1.116c6.156 7.332 17.053 19.49 27.469 22.662 13.192 4.017 14.467 7.466 14.277 9.71-.334 3.96-4.942 6.873-12.326 7.791-15.844 1.97-27.605-.929-34.282-3.435a60.037 60.037 0 00-16.047-3.537l-14.267-1.205a10.499 10.499 0 01-7.161-3.547 9.997 9.997 0 01-2.016-3.737 9.996 9.996 0 01-.286-4.237c.95-6.608 1.703-12.219 2.244-16.683a3.206 3.206 0 012.545-2.823c4.267-.757 7.262 7.77 14.721 7.848 7.933.084 18.795-9.913 22.743-9.923z"
            ></path>
            <path
                fill="#E2E2E2"
                d="M664.954 693.211a16.355 16.355 0 01-6.751 3.14c-9.957 2.609-35.06-3.836-46.612-6.223-10.496-2.17-16.994-10.709-32.205-14.19a1056.8 1056.8 0 01-.615 4.337 10.012 10.012 0 002.302 7.975 10.503 10.503 0 007.161 3.547l14.267 1.204a60.038 60.038 0 0116.047 3.538c6.677 2.505 18.438 5.404 34.282 3.434 6.726-.837 11.137-3.331 12.124-6.762z"
            ></path>
        </g>
    );
}
