import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            width={971}
            height={570}
            viewBox="0 0 971 570"
            fill="none"
            id="object-outdoor-building"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M423.175 214.465h-44.006a9.568 9.568 0 0 1-9.581-9.58V11.041a9.567 9.567 0 0 0-9.58-9.58h-67.766a9.567 9.567 0 0 0-9.58 9.58v193.844a9.567 9.567 0 0 0 9.58 9.58h21.524a9.567 9.567 0 0 0 9.581-9.58v-66.68a9.567 9.567 0 0 0-9.581-9.58h-92.738a9.567 9.567 0 0 0-9.58 9.58v66.68a9.567 9.567 0 0 0 9.58 9.58h18.969a9.567 9.567 0 0 0 9.581-9.58V62.52l-85.841 61.059v108.642c0 5.301 4.28 9.58 9.581 9.58h6.259a9.567 9.567 0 0 0 9.58-9.58v-56.524a9.567 9.567 0 0 0-9.58-9.581h-37.428a9.567 9.567 0 0 0-9.58 9.581v22.162a9.568 9.568 0 0 1-9.58 9.581h-8.176a9.567 9.567 0 0 0-9.58 9.58v46.944a9.567 9.567 0 0 1-9.58 9.58H70.935a9.568 9.568 0 0 0-9.58 9.581v24.717a9.568 9.568 0 0 0 9.58 9.581H163.1c-14.626-9.325-24.398-25.931-25.165-43.24 11.241 6.323 20.758 15.456 27.656 26.378.574-17.692 8.877-34.745 22.29-46.305 4.088 15.52 3.002 31.36-5.11 45.283 10.922-11.049 25.612-17.5 41.132-18.522-10.921 16.031-20.119 27.336-37.299 36.406h113.304a9.567 9.567 0 0 1 9.58 9.58v14.754a9.567 9.567 0 0 1-9.58 9.58H11.026a9.568 9.568 0 0 0-9.58 9.581v27.464c0 5.301 4.279 9.58 9.58 9.58h215.623a9.567 9.567 0 0 1 9.58 9.58v44.837a9.567 9.567 0 0 1-9.58 9.58H44.493a9.567 9.567 0 0 0-9.58 9.58v87.246c0 5.301 4.28 9.58 9.58 9.58h90.567c-9.453-2.81-17.628-8.367-24.206-15.648-6.643-7.281-11.433-16.159-14.563-25.484 12.263 2.683 23.44 8.751 32.574 17.309-4.28-20.119.894-41.579 12.965-58.249 6.579 13.668 8.814 28.997 6.259 43.942 10.794-16.542 27.4-28.422 46.306-34.106-2.555 9.453-5.621 18.778-11.178 26.825-5.812 8.367-13.093 15.584-20.246 22.801 11.241-5.812 24.079-7.983 36.725-7.217-7.728 13.605-20.119 24.207-34.937 29.316H969.58"
            />
        </svg>
    );
}
