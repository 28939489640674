import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7074)"
            d="M392.774 257.658c.021-.017.032-.042.055-.058l4.024-2.86a4.358 4.358 0 014.841-.029l2.352 1.466c.088-1.649.24-3.309.483-4.945 3.415-23.09 13.156-30.795 13.156-30.795-8.894-1.027-17.796-1.716-26.577-2.148-31.634-3.417-41.865 42.951-12.351 53.509 0 0 2.175.624 5.585 1.451l7.224-14.177a4.038 4.038 0 011.208-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7074"
                x1="359.547"
                x2="404.945"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
