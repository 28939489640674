import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M402.464 353.202a18.388 18.388 0 0 1-14.665-7.203l-.068-.087-.085-.071a19.456 19.456 0 0 1-5.198-6.752 227.879 227.879 0 0 1-8.67-22.441c-2.397-7.202-4.606-14.649-6.754-22.763a181.587 181.587 0 0 1-4.757-23.098 968.19 968.19 0 0 1-.951-7.014c-.716-5.378-1.452-10.91-2.376-16.325a26.498 26.498 0 0 1 21.667-30.578 26.5 26.5 0 0 1 30.579 21.667l.022.131c.894 5.406 2.004 10.869 3.078 16.154.476 2.34.948 4.664 1.404 6.99a181.654 181.654 0 0 1 3.052 23.384c.29 3.914.527 7.921.706 11.917l.08 1.799 1.485-1.019a261.964 261.964 0 0 1 11.193-7.278 362.357 362.357 0 0 0 3.471-2.132c2.425-1.499 4.855-3 7.3-4.399l5.442-3.188a192.294 192.294 0 0 1 3.554-2.007c.652-.362 1.303-.725 1.952-1.09 3.199-1.776 7.363-4.061 12.003-6.432-1.078 10.402 7.83 21.728 14.183 25.09a435.662 435.662 0 0 1-7.535 7.112c-.551.503-1.101 1.008-1.649 1.516-1 .924-1.998 1.847-3.014 2.747l-4.729 4.173c-2.107 1.898-4.301 3.743-6.423 5.528-1.067.898-2.11 1.775-3.148 2.663a261.654 261.654 0 0 1-19.919 15.304l-10.353 7.11-10.269 7.225a18.343 18.343 0 0 1-10.608 3.367Z"
            fill="url(#female-34-25-shirt-1-arm-opt-5-right-a)"
        />
        <path
            d="M472.578 303.57c-1.556 1.42-3.09 2.869-4.663 4.264l-4.729 4.173c-3.128 2.817-6.38 5.461-9.571 8.19a262.762 262.762 0 0 1-19.92 15.305l-10.352 7.11-10.269 7.225c-8.356 5.88-20.694 5.655-30.625-10.746M382.448 339.089a225.211 225.211 0 0 1-8.67-22.441c-2.51-7.544-4.735-15.136-6.754-22.763a180.703 180.703 0 0 1-4.757-23.099c-1.09-7.782-2.006-15.594-3.327-23.338-1.845-10.82 4.335-23.046 13.299-27.167M411.184 238.539l.021.13c1.282 7.751 2.97 15.433 4.483 23.145a180.769 180.769 0 0 1 3.051 23.385c.27 3.628.395 5.265.569 8.91M453.926 277.8c3.198-1.777 7.363-4.061 12.002-6.433-1.078 10.403 7.83 21.728 14.183 25.091l-.467.452a446.517 446.517 0 0 1-7.068 6.66"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m401.453 312.366 9.989-7.612a257.064 257.064 0 0 1 20.764-14.138c3.589-2.179 7.117-4.442 10.771-6.532l5.442-3.188c1.816-1.059 3.672-2.063 5.507-3.097a402.613 402.613 0 0 1 11.571-6.213"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-5-right-a"
                x1={358.56}
                y1={284.845}
                x2={480.111}
                y2={284.845}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
