import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#clip0_10364_7999)">
            <path
                fill="url(#paint0_linear_10364_7999)"
                d="M494.071 223.744c-8.492-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.958 15.305-12.953z"
            ></path>
            <path
                fill="url(#paint1_linear_10364_7999)"
                d="M494.377 224.145c6.101 4.243 12.435 8.149 18.669 12.2a153.675 153.675 0 019.14 6.381c-13.124 8.395-24.112 25.021-34.522 42.081-7.229-4.771-17.315-12.863-23.438-17.075a26.497 26.497 0 01-6.815-36.852 26.5 26.5 0 0136.852-6.815l.114.08z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M494.262 224.062l.114.08c6.101 4.244 12.436 8.149 18.669 12.201M522.184 242.727c-13.124 8.395-24.112 25.021-34.522 42.081"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.038"
                d="M463.162 202.492a132.211 132.211 0 0130.909 21.252"
            ></path>
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_10364_7999"
                x1="463.162"
                x2="494.636"
                y1="219.595"
                y2="219.595"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_7999"
                x1="452.742"
                x2="522.186"
                y1="252.102"
                y2="252.102"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <clipPath id="clip0_10364_7999">
                <path
                    fill="#fff"
                    d="M0 0H900V900H0z"
                    transform="translate(.385 .547)"
                ></path>
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
