import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7078)"
            d="M392.478 257.658c.02-.017.031-.042.054-.058l4.024-2.86a4.358 4.358 0 014.841-.029l2.353 1.466c.087-1.649.24-3.309.482-4.945 3.415-23.09 13.156-30.795 13.156-30.795-8.894-1.027-17.796-1.716-26.577-2.148-31.634-3.417-41.864 42.951-12.35 53.509 0 0 2.174.624 5.584 1.451l7.224-14.177a4.03 4.03 0 011.209-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7078"
                x1="359.25"
                x2="404.648"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFB33B"></stop>
                <stop offset="0.82" stopColor="#FFCC09"></stop>
                <stop offset="0.971" stopColor="#FFD100"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
