import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={4}>
        <path
            d="M502.207 232.802c3.812 6.592 7.98 12.976 11.994 19.449a169.932 169.932 0 0 1 10.74 20.18c3.201 6.947 6.221 13.999 8.991 21.196a212.275 212.275 0 0 1 7.311 22.174 19.206 19.206 0 0 1-20.931 23.904 19.21 19.21 0 0 1-11.05-5.296 212.446 212.446 0 0 1-15.664-17.314c-4.889-5.965-9.527-12.076-13.985-18.291a169.923 169.923 0 0 1-12.235-19.309c-3.644-6.689-7.135-13.467-10.981-20.039a26.505 26.505 0 0 1-2.767-20.093 26.503 26.503 0 0 1 32.345-18.93 26.502 26.502 0 0 1 16.164 12.252l.068.117Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M523.284 305.493c5.92-.466 11.813-1.264 17.717-1.92 5.914-.646 11.865-.89 17.812-.727 2.987.096 5.965.091 8.96.297l4.488.245c1.497.085 2.999.24 4.497.356a156.6 156.6 0 0 1 18.072 2.447 12.498 12.498 0 0 1 9.733 14.755 12.635 12.635 0 0 1-7.786 9.166 156.706 156.706 0 0 1-17.437 5.337c-1.46.358-2.918.752-4.38 1.079l-4.39.968c-2.923.687-5.862 1.163-8.794 1.742a131.113 131.113 0 0 1-17.696 2.163c-5.933.308-11.876.473-17.793.97a18.5 18.5 0 0 1-3.101-36.87l.098-.008Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M509.262 334.409a212.446 212.446 0 0 1-15.664-17.314c-4.889-5.965-9.527-12.076-13.985-18.291a169.923 169.923 0 0 1-12.235-19.309c-3.645-6.689-7.135-13.467-10.981-20.039a26.5 26.5 0 0 1 45.741-26.771l.069.117c3.812 6.592 7.98 12.976 11.994 19.449a169.932 169.932 0 0 1 10.74 20.18c3.201 6.947 8.541 20.258 11.311 27.456M537.5 303.975c1.167-.137 2.333-.271 3.5-.402a131 131 0 0 1 17.813-.727c2.987.096 5.965.091 8.96.297l4.488.245c1.496.085 2.998.24 4.497.356 6.066.464 12.1 1.281 18.071 2.447M596.778 330.109a156.452 156.452 0 0 1-17.438 5.338c-1.46.357-2.917.752-4.38 1.079l-4.389.967c-2.923.688-5.862 1.164-8.794 1.742a130.972 130.972 0 0 1-17.696 2.163c-5.933.309-11.877.473-17.793.97a18.434 18.434 0 0 1-13.956-4.71"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M643.39 332.275c-.437-6.012-7.32-11.985-13.978-13.442l-.534-.058a18.742 18.742 0 0 0-2.742-2.57 8.24 8.24 0 0 0-5.032-1.775l-.63.064a13.2 13.2 0 0 0-.293-.334c-2.635-2.892-6.5-4.309-10.286-5.294-3.785-.986-7.712-1.666-11.168-3.499l-3.093 6.921a4.997 4.997 0 0 0-.652 3.93c.251 1.344.794 2.618 1.101 3.951a5.533 5.533 0 0 1-.328 3.992l-.198.485a1.107 1.107 0 0 0 .17 1.124 5.1 5.1 0 0 0 3.887 1.631l.149.193a7.602 7.602 0 0 0 2.493 1.715c4.995 2.043 10.525 2.21 15.916 2.45 5.391.24 10.946.626 15.775 3.036a35.714 35.714 0 0 0 3.836 1.89c1.272.453 4.208.87 5.815-.916.182-.203.293-.459.316-.73a3.52 3.52 0 0 0-.662-2.357l.047-.079a.497.497 0 0 0 .091-.328Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M598.234 305.516c3.456 1.833 7.383 2.513 11.168 3.498 3.786.986 7.651 2.403 10.286 5.294a32.365 32.365 0 0 1 3.757 5.871 15.822 15.822 0 0 0 3.436 4.404 1.903 1.903 0 0 1 .614 2.137 1.866 1.866 0 0 1-2.195 1.158 24.8 24.8 0 0 1-7.415-3.15 53.686 53.686 0 0 1-6.189-5.401M620.609 314.578a8.247 8.247 0 0 1 5.033 1.776 24.58 24.58 0 0 1 3.846 3.825l6.572 7.582a2.11 2.11 0 0 1 .73 1.701c-.209.931-1.471 1.101-2.408.92a9.51 9.51 0 0 1-5.048-2.756M604.685 318.273a20.51 20.51 0 0 1-1.495 5.5 6.933 6.933 0 0 1-4.069 3.778c-1.207-.038-2.994-.452-3.68-1.446M595.261 324.311a5.547 5.547 0 0 0 .328-3.993c-.308-1.333-.85-2.606-1.102-3.951a5.007 5.007 0 0 1 .653-3.929"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M637.925 332.367a5.473 5.473 0 0 0 3.993.847 1.207 1.207 0 0 0 .924-1.323c-.786-5.831-7.461-11.5-13.924-12.914M601.762 329.461c4.995 2.042 10.525 2.21 15.916 2.45 5.391.24 10.946.626 15.775 3.035a35.434 35.434 0 0 0 3.836 1.89 5.522 5.522 0 0 0 4.17.024M643.361 335.593a3.522 3.522 0 0 0-.603-2.765"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.071 223.94c-8.492-8.308-18.696-15.185-30.909-21.252l15.604 34.204s19.387-8.958 15.305-12.952Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.688a132.15 132.15 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
