import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-right-down">
        <path
            fill="url(#man-34-25-doctors-coat-arm-right-down-a)"
            d="M390.757 185.1c-15.3-7.7-33.9-1.5-41.6 13.7-3.8 7.6-8.1 15-12.1 22.5-10.5 19.2-22.1 52-26.5 72.6-.3 1.3-.4 2.5-.5 3.8-.6 2.6-.7 5.4-.2 8.2 3.9 25.1 8.9 67.1 18.1 109.7l37.7-5.7c-1.3-18.7-4.7-39.3-5.6-55-.8-11.9-3.1-33.7-4.6-46.3 4-4.9 7.7-9.8 11.3-14.9 23.8-33.3 26.6-44.8 37.7-67 7.8-15.3 1.6-33.9-13.7-41.6Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M404.559 226.7c-11.2 22.2-13.9 33.8-37.7 67-3 4.2-6.1 8.3-9.3 12.4m-47-12.2c4.4-20.6 16.1-53.3 26.5-72.6 4.1-7.5 8.3-14.9 12.1-22.5m6.199 109.1c1.4 12.5 3.8 34.9 4.7 47 1 15.7 4.4 36.3 5.6 55l-37.7 5.7c-9.2-42.7-14.3-84.6-18.1-109.7-.5-3.1-.3-6.1.4-8.9"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-right-down-a"
                x1={214.719}
                x2={502.285}
                y1={405.904}
                y2={197.061}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.644} stopColor="#fff" />
                <stop offset={0.934} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
