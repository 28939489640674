import { THairStyle, TSkinEl } from 'interfaces';

import EmotionNormal from 'assets/female/emotions/female-34-25-emotion-normal';
import EmotionWideSmile from 'assets/female/emotions/female-34-25-emotion-wide-smile';
import EmotionConfused from 'assets/female/emotions/female-34-25-emotion-confused';

import Nose1Skin from 'assets/female/noses/female-bold-34-25-nose-1';
import Nose2Skin from 'assets/female/noses/female-bold-34-25-nose-2';
import Nose3Skin from 'assets/female/noses/female-bold-34-25-nose-3';


import Haircut1 from 'assets/female/hairs/female-34-25-haircut-1';
import Haircut2 from 'assets/female/hairs/female-34-25-haircut-2';
import Haircut3 from 'assets/female/hairs/female-34-25-haircut-3';
import Haircut4 from 'assets/female/hairs/female-34-25-haircut-4';
import Haircut5 from 'assets/female/hairs/female-34-25-haircut-5';
import Haircut6 from 'assets/female/hairs/female-34-25-haircut-6';
import Haircut7 from 'assets/female/hairs/female-34-25-haircut-7';
import Haircut8 from 'assets/female/hairs/female-34-25-haircut-8';

export const F_NOSES: [string, TSkinEl][] = [
    ['Nose 1', Nose1Skin],
    ['Nose 2', Nose2Skin],
    ['Nose 3', Nose3Skin],
];

export const F_EMOTIONS: [string, () => JSX.Element][] = [
    ['Normal', EmotionNormal],
    ['Wide smile', EmotionWideSmile],
    ['Confused', EmotionConfused],
];

export const F_HAIR_STYLE: THairStyle[] = [
    ['Haircut 1', Haircut1],
    ['Haircut 2', Haircut2],
    ['Haircut 3', Haircut3],
    ['Haircut 4', Haircut4],
    ['Haircut 5', Haircut5],
    ['Haircut 6', Haircut6],
    ['Haircut 7', Haircut7],
    ['Haircut 8', Haircut8],
];
