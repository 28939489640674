import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-3">
        <path
            d="M430.592 250.955a242.552 242.552 0 0 0-11.067-25.884 1072.227 1072.227 0 0 0-3.804-7.58c-2.884-5.716-5.867-11.627-8.604-17.524l-.059-.129a32.147 32.147 0 0 0-28.987-18.404 31.718 31.718 0 0 0-13.581 3.042 31.788 31.788 0 0 0-16.498 18.101 31.807 31.807 0 0 0-1.884 12.347 31.804 31.804 0 0 0 3.019 12.119c2.765 5.889 5.385 11.975 7.918 17.862 1.115 2.591 2.235 5.18 3.36 7.765a242.523 242.523 0 0 0 12.737 25.101c2.135 3.735 4.24 7.32 6.337 10.802l55.041-26.647a544.967 544.967 0 0 0-3.928-10.971Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-3-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-3-a"
                x1={354.634}
                y1={176.434}
                x2={407.983}
                y2={270.121}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
