import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7685)"
            d="M524.444 280.019c-20.409-24.152-26.325-32.893-31.649-46.77-2.986-7.787-9.421-18.228-14.918-23.95a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.778 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.745 62.745 0 00-26.518 74.436l18.824 52.892c4.37 12.284 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.883 0 179.194.875 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a531.766 531.766 0 01-2.685-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7685)"
            d="M464.055 202.227a143.824 143.824 0 0113.155 7.828l-13.155-7.828z"
        ></path>
        <g filter="url(#filter0_f_11323_7685)">
            <path
                fill="url(#paint2_radial_11323_7685)"
                d="M410.676 290.993l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.265 14.814 1.029 21.605-1.697a30.978 30.978 0 0012.048-48.825l-6.917-10.635"
            ></path>
        </g>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M407.477 194.602c11.036 8.027 21.706 14.535 31.631 17.961l24.553 18.978 8-19.515 7.309 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M439.11 212.564l-9.445 13.073-20.923-22.629M498.77 329.508c.701 5.497 2.455 10.966 3.87 16.409a138.376 138.376 0 01-3.191 80.044M468.188 235.836c10.048 9.027 20.173 21.446 27.519 31.822 7.347 10.377 11.804 18.941 9.682 30.57-1.445 7.92-5.864 15.448-6.755 23.413"
        ></path>
        <defs>
            <filter
                id="filter0_f_11323_7685"
                width="91.355"
                height="77.898"
                x="402.676"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7685"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7685"
                x1="344.968"
                x2="532.553"
                y1="332.929"
                y2="300.056"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7685"
                x1="464.055"
                x2="477.21"
                y1="206.141"
                y2="206.141"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <radialGradient
                id="paint2_radial_11323_7685"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.981 -1072.037) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.439" stopColor="#64CCC9" stopOpacity="0"></stop>
                <stop offset="0.556" stopColor="#64CCC9" stopOpacity="0.258"></stop>
                <stop offset="0.747" stopColor="#64CCC9" stopOpacity="0.655"></stop>
                <stop offset="0.883" stopColor="#64CCC9" stopOpacity="0.904"></stop>
                <stop offset="0.949" stopColor="#64CCC9"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
