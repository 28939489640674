import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={92}
            height={40}
            fill="none"
            id="object-food-brazilian"
            {...attributes}
        >
            <path
                fill="url(#object-food-brazilian-a)"
                d="M91.784 11.129c0 10.793-20.36 28.429-45.474 28.429C21.195 39.558.836 21.92.836 11.128.836.338 21.196.476 46.31.476c25.114 0 45.474-.138 45.474 10.654Z"
            />
            <defs>
                <linearGradient
                    id="object-food-brazilian-a"
                    x1={0.837}
                    x2={91.784}
                    y1={0.475}
                    y2={0.475}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
