import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-1">
        <path
            d="m465.018 167.461 2.653.951c13.052 4.678 23.789 10.343 32.826 17.319a2.681 2.681 0 0 1 1.092 2.341c-.25 4.649-12.215 12.209-14.611 13.678l-.856.525-21.104-34.814Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-1-a)"
        />
        <path
            d="M569.47 311.484c-1.833 0-3.791-.065-6.162-.207-2.904-.16-5.928-.455-9.8-.955a66.973 66.973 0 0 1-9.57-1.878l-4.6-1.295-4.602-1.223a23.479 23.479 0 0 1-7.603-3.569 24.68 24.68 0 0 1-3.571-2.067 210.07 210.07 0 0 1-18.476-15.053c-5.565-5.027-11.064-10.361-16.813-16.306a166.01 166.01 0 0 1-15.16-17.693 737.776 737.776 0 0 1-4.131-5.613c-3.13-4.275-6.367-8.696-9.712-12.905a31.796 31.796 0 0 1-6.74-23.546 31.798 31.798 0 0 1 11.884-21.416 31.662 31.662 0 0 1 19.89-6.949 31.84 31.84 0 0 1 25.071 12.092l.105.132c3.319 4.223 6.867 8.386 10.297 12.413 1.514 1.777 3.023 3.557 4.527 5.34a166.019 166.019 0 0 1 13.713 18.842c4.453 6.961 8.371 13.544 11.975 20.115 2.999 5.511 5.369 10.195 7.44 14.7l1.026.396a67.45 67.45 0 0 1 8.795 4.243c3.345 1.892 6.02 3.517 8.414 5.112.741.489 1.556 1.035 2.419 1.634-3.85 2.268-12.67 17.918-8.616 35.656Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-1-b)"
        />
        <path
            d="M459.271 232.72c3.345 4.209 6.582 8.63 9.712 12.905 1.374 1.875 2.751 3.746 4.131 5.614a166.098 166.098 0 0 0 15.16 17.692c5.749 5.945 11.248 11.279 16.813 16.307a210.206 210.206 0 0 0 18.476 15.052 24.734 24.734 0 0 0 3.571 2.067 23.453 23.453 0 0 0 7.604 3.569l4.601 1.223 4.6 1.296a67.25 67.25 0 0 0 9.57 1.878c3.872.5 6.896.794 9.801.955 2.371.141 4.329.207 6.162.207-4.054-17.739 4.765-33.389 8.615-35.658a128.481 128.481 0 0 0-2.418-1.633c-2.394-1.595-5.069-3.22-8.415-5.112a67.388 67.388 0 0 0-8.795-4.243l-1.025-.396c-2.072-4.505-4.442-9.189-7.44-14.7-3.604-6.571-7.523-13.154-11.975-20.115a166.141 166.141 0 0 0-13.713-18.842 717.041 717.041 0 0 0-4.527-5.34c-3.431-4.027-6.979-8.19-10.297-12.413l-.105-.133a31.934 31.934 0 0 0-11.466-9.073 120.955 120.955 0 0 0-30.237-15.413"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-1-a"
                x1={485.508}
                y1={158.204}
                x2={466.857}
                y2={323.891}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-1-b"
                x1={524.305}
                y1={162.573}
                x2={505.655}
                y2={328.26}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
