import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M511.101 324.021c16.258-6.304 32.184-19.576 12.276-43.133-19.908-23.558-26.375-32.835-31.852-47.115-9.29-24.222-28.42-31.226-28.42-31.226-9.291 6.968-42.54.782-55.148-9.172l-19.771 11.891a61.317 61.317 0 00-26.167 73.107l18.824 52.893a36.515 36.515 0 01-6.6 36.016 110.852 110.852 0 00-18.886 31.923h167.799c7.668-30.163-4.788-55.045-12.055-75.184z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M499.654 384.574a13.776 13.776 0 0110.286 0M415.605 300.378c5.359 11.03 17.157 17.893 29.235 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.978 30.978 0 0012.048-48.825"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M523.156 399.206c7.668-30.163-4.788-55.045-12.055-75.184 16.258-6.304 32.184-19.576 12.277-43.133-19.908-23.558-26.375-32.835-31.853-47.116M407.956 193.375l-19.771 11.891a61.323 61.323 0 00-26.166 73.107l18.824 52.893a36.515 36.515 0 01-6.6 36.016 110.852 110.852 0 00-18.886 31.923"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M355.357 399.203a90.502 90.502 0 00-4.403 15.397c-10.396 55.41 52.309 115.616 131.422 44.511 24.407-21.935 36.193-41.866 40.78-59.908H355.357z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M355.357 399.203a90.502 90.502 0 00-4.403 15.397c-10.396 55.41 52.309 115.616 131.422 44.511 24.407-21.935 36.193-41.866 40.78-59.908"
        ></path>
    </g>
);

export default SvgComponent;
