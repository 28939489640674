import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M413.765 316.157a19.473 19.473 0 0 1-12.965-4.939 15.548 15.548 0 0 1-.779-.76 21.255 21.255 0 0 1-4.838-2.877 100.446 100.446 0 0 1-11.451-10.934c-3.309-3.646-6.478-7.513-9.69-11.826a74.788 74.788 0 0 1-7.949-12.869c-.674-1.386-1.331-2.783-1.989-4.18-1.433-3.044-2.915-6.192-4.549-9.177a27.533 27.533 0 0 1 10.915-37.328 27.54 27.54 0 0 1 13.183-3.384c10.064 0 20.14 5.561 24.968 14.381.023.041 6.125 11.678 6.913 13 2.267 3.782 4.161 7.535 6.33 11.221 3.905-4.393 7.495-7.809 11.682-11.974 1.021-1.007 2.03-2.018 3.04-3.029 2.058-2.062 4.186-4.193 6.358-6.231l4.754-4.55c1.029-.98 2.077-1.941 3.127-2.902.578-.53 1.155-1.061 1.73-1.592 6.235-5.711 11.143-9.993 15.92-13.888l1.617-1.319.015 2.086c.07 9.774 13.346 17.918 17.392 18.189l1.656.11-.872 1.413c-2.837 4.597-6.079 9.542-10.202 15.562-.45.648-.893 1.294-1.336 1.941a227.257 227.257 0 0 1-2.435 3.503l-3.844 5.356c-1.705 2.424-3.513 4.826-5.262 7.149a857.02 857.02 0 0 0-2.579 3.444 286.067 286.067 0 0 1-16.727 20.369l-8.842 9.711-8.722 9.793a19.534 19.534 0 0 1-14.569 6.531Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-3-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-3-right-a"
                x1={357.362}
                y1={245.094}
                x2={471}
                y2={270.251}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
