import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string, string]> = {
    white: ['#FFDBAE', '#CDAB81', '#E9C69B', '#FFDBAE'],
    black: ['#6C453A', '#4D352C', '#573A30', '#6C453A'],
    brown: ['#CB865E', '#9A684B', '#B57956', '#CB865E'],
    yellow: ['#FCB47F', '#CB865E', '#E6A070', '#FCB47F'],
};

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-body">
        <path
            fill={SKIN_COLOR[color][0]}
            d="M370.049 388.1c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
        />
        <path
            fill="url(#man-bold-34-25-body-a)"
            d="M370.049 388.1c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M505.051 212.1c9.3 15.6 17 31.8 20.1 43.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103m-160.9 0c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-19.4c-.8-24 9.7-36.9 21.7-45.6 12.4-9 23-15.5 41.3-23.3"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M517.151 285.3c-3.6-6.2-7.7-12.9-11.1-16.9m-98.102 18.2c31.1 3.8 56.5-1.2 71.7-22.1m28.402 99.6c3.6-1.3 7.1-1.4 10.3 0"
        />
        <defs>
            <radialGradient
                id="man-bold-34-25-body-a"
                cx={0}
                cy={0}
                r={1}
                gradientTransform="translate(382.351 424.771) scale(255.204)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.488} stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.932} stopColor={SKIN_COLOR[color][3]} />
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
