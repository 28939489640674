import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#888B8D"
            d="M494.768 222.602a109.64 109.64 0 00-20.026-15.329 31.037 31.037 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.013 1.013 0 00.563.522.997.997 0 00.767-.03c2.598-1.2 15.601-7.426 16.405-12.092a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="#888B8D"
            d="M532.039 412.777c-6.06-8.26-11.323-16.682-16.414-24.826l-.128-.206c-10.565-16.788-16.215-29.272-23.368-45.076-1.185-2.618-2.406-5.316-3.693-8.128a12.39 12.39 0 01-.321-.78l-.061-.155c-10.786-15.51-23.593-41.842-28.622-58.828a692.66 692.66 0 01-1.836-6.242c-1.377-4.726-2.802-9.614-4.39-14.348a27.489 27.489 0 011.391-21.054 27.507 27.507 0 0115.9-13.871 27.519 27.519 0 0110.671-1.36 27.51 27.51 0 0124.182 18.795c1.615 4.813 3.403 9.025 4.894 13.013 4.735 12.668 8.577 23.521 12.567 49.606 1.244 8.319 2.538 14.649 2.794 20.803a382.683 382.683 0 0131.82 79.553l.209.777-25.089 13.017-.506-.69z"
        ></path>
    </g>
);

export default SvgComponent;
