import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.473 28.866c5.69-3.81 11.41-5.55 16.94-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M129.853 23.316c37.8 0 66.73 81.24 14.65 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.7-111.83 2.94-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M21.572 47.9c1.41-2.01 2.92-3.87 4.54-5.59a43.422 43.422 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.449.16 12.65-8.82 23.62-21.28 25.81a228.779 228.779 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43-5.05-.89-9.53-3.22-13.06-6.52-.25-.23-.49-.47-.72-.7-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.255 28.255 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41-.36-.57-.7-1.16-1.01-1.76-.16-.31-.32-.62-.47-.94-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#888B8D"
                    d="M107.833 32.84c26.57 12.93 34.94 50.8 36.98 103.769.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.665 228.665 0 0 1-39.44 3.43h-22.46c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M39.873 32.358Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M36.502 34.08Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M33.762 35.777c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.353 41.027c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M21.572 47.898c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.313 53.047c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M17.533 54.457c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M16.153 57.148a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M23.852 114.738h76.55M62.122 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M84.583 185.639c14.7 0 27.96-1.42 39.44-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92h-22.46M84.583 185.637h-22.46"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M43.643 30.848c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M16.872 55.719c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.68-13.21 95.88-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05.15.32.31.63.47.94.31.6.65 1.19 1.01 1.76.08.14.17.27.26.41a27.125 27.125 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M62.122 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={43.606}
                        x2={188.364}
                        y1={100.963}
                        y2={209.342}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={76.164}
                        x2={220.922}
                        y1={57.477}
                        y2={165.856}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={74.16}
                        x2={218.918}
                        y1={60.152}
                        y2={168.531}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.457}
                        x2={215.215}
                        y1={65.097}
                        y2={173.476}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={65.993}
                        x2={210.751}
                        y1={71.061}
                        y2={179.44}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={60.671}
                        x2={205.43}
                        y1={78.17}
                        y2={186.549}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={57.142}
                        x2={201.901}
                        y1={82.883}
                        y2={191.262}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={56.131}
                        x2={200.889}
                        y1={84.233}
                        y2={192.611}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={53.681}
                        x2={198.439}
                        y1={87.507}
                        y2={195.887}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#D9D9D6" />
                        <stop offset={0.69} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#888B8D" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.197 28.859c5.69-3.81 11.41-5.55 16.94-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M129.578 23.309c37.8 0 66.73 81.24 14.65 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.7-111.83 2.94-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M21.297 47.891c1.41-2.01 2.92-3.87 4.54-5.59a43.422 43.422 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.45.16 12.65-8.82 23.62-21.28 25.81a228.787 228.787 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43a25.578 25.578 0 0 1-13.78-7.22c-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.255 28.255 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41-.36-.57-.7-1.16-1.01-1.76-.16-.31-.32-.62-.47-.94-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#C2C9CF"
                    d="M107.558 32.831c26.57 12.93 34.94 50.8 36.98 103.77.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.67 228.67 0 0 1-39.44 3.43h-22.46c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M39.598 32.35Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M36.227 34.072Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M33.486 35.77c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.078 41.02c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M21.297 47.89c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.038 53.04c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M17.258 54.45c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M15.878 57.14a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M23.576 114.73h76.55M61.846 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M84.308 185.631c14.7 0 27.96-1.42 39.44-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92h-22.46M84.308 185.629h-22.46"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M43.367 30.84c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M16.597 55.71c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.681-13.21 95.881-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05.15.32.31.63.47.94.31.6.65 1.19 1.01 1.76.08.14.17.27.26.41a27.125 27.125 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M61.846 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={43.331}
                        x2={188.089}
                        y1={100.954}
                        y2={209.333}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={75.889}
                        x2={220.647}
                        y1={57.468}
                        y2={165.847}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={73.885}
                        x2={218.643}
                        y1={60.145}
                        y2={168.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.181}
                        x2={214.94}
                        y1={65.089}
                        y2={173.468}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={65.718}
                        x2={210.475}
                        y1={71.052}
                        y2={179.43}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={60.395}
                        x2={205.154}
                        y1={78.161}
                        y2={186.539}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={56.867}
                        x2={201.627}
                        y1={82.874}
                        y2={191.254}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={55.856}
                        x2={200.615}
                        y1={84.224}
                        y2={192.604}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={53.405}
                        x2={198.163}
                        y1={87.499}
                        y2={195.876}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.922 28.859c5.69-3.81 11.41-5.55 16.94-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M130.302 23.309c37.8 0 66.731 81.24 14.651 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.701-111.83 2.941-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M22.022 47.891c1.41-2.01 2.92-3.87 4.54-5.59a43.422 43.422 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.45.16 12.65-8.82 23.62-21.28 25.81a228.796 228.796 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43a25.578 25.578 0 0 1-13.78-7.22c-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.255 28.255 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41-.36-.57-.7-1.16-1.01-1.76-.16-.31-.32-.62-.47-.94-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#FFD100"
                    d="M108.282 32.831c26.57 12.93 34.94 50.8 36.98 103.77.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.663 228.663 0 0 1-39.44 3.43h-22.46c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M40.322 32.35Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M36.951 34.072Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M34.21 35.77c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.802 41.02c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M22.021 47.89c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.762 53.04c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M17.982 54.45c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M16.602 57.14a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M24.3 114.73h76.551M62.571 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M85.032 185.631c14.7 0 27.96-1.42 39.44-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92h-22.46M85.032 185.629h-22.46"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M44.092 30.84c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M17.322 55.71c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.681-13.21 95.881-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05.15.32.31.63.47.94.31.6.65 1.19 1.01 1.76.08.14.17.27.26.41a27.127 27.127 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M62.57 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={44.057}
                        x2={188.815}
                        y1={100.954}
                        y2={209.333}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={76.614}
                        x2={221.372}
                        y1={57.468}
                        y2={165.847}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={74.61}
                        x2={219.368}
                        y1={60.145}
                        y2={168.523}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.907}
                        x2={215.665}
                        y1={65.089}
                        y2={173.468}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={66.443}
                        x2={211.2}
                        y1={71.052}
                        y2={179.43}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={61.12}
                        x2={205.876}
                        y1={78.161}
                        y2={186.534}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={57.594}
                        x2={202.356}
                        y1={82.874}
                        y2={191.262}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={56.58}
                        x2={201.337}
                        y1={84.224}
                        y2={192.598}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={54.131}
                        x2={198.887}
                        y1={87.499}
                        y2={195.876}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.302} stopColor="#FFEA8B" />
                        <stop offset={0.993} stopColor="#FFD101" />
                        <stop offset={1} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.959 28.859c5.69-3.81 11.41-5.55 16.94-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M130.34 23.309c37.8 0 66.73 81.24 14.65 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.7-111.83 2.94-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M22.059 47.891c1.41-2.01 2.92-3.87 4.54-5.59a43.422 43.422 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.45.16 12.65-8.82 23.62-21.28 25.81a228.794 228.794 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43a25.578 25.578 0 0 1-13.78-7.22c-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.255 28.255 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41-.36-.57-.7-1.16-1.01-1.76-.16-.31-.32-.62-.47-.94-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#FF7E71"
                    d="M108.319 32.831c26.57 12.93 34.94 50.8 36.98 103.77.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.66 228.66 0 0 1-39.44 3.43H62.61c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M40.36 32.35Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M36.988 34.072Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M34.248 35.77c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.84 41.02c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M22.058 47.89c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.8 53.04c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M18.02 54.45c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M16.64 57.14a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M24.338 114.73h76.55M62.608 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M85.07 185.631c14.7 0 27.959-1.42 39.439-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92H62.61M85.07 185.629H62.61"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M44.129 30.84c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M17.359 55.71c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.681-13.21 95.881-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05.15.32.31.63.47.94.31.6.65 1.19 1.01 1.76.08.14.17.27.26.41a27.125 27.125 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M62.608 27.941c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={44.093}
                        x2={188.851}
                        y1={100.954}
                        y2={209.333}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={76.65}
                        x2={221.408}
                        y1={57.468}
                        y2={165.847}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={74.647}
                        x2={219.405}
                        y1={60.145}
                        y2={168.524}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.944}
                        x2={215.702}
                        y1={65.089}
                        y2={173.468}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={66.48}
                        x2={211.237}
                        y1={71.052}
                        y2={179.431}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={61.158}
                        x2={205.916}
                        y1={78.161}
                        y2={186.54}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={57.628}
                        x2={202.388}
                        y1={82.874}
                        y2={191.253}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={56.618}
                        x2={201.377}
                        y1={84.224}
                        y2={192.603}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={54.167}
                        x2={198.925}
                        y1={87.499}
                        y2={195.877}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD9D9" />
                        <stop offset={0.544} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FF7E71" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.998 28.866c5.69-3.81 11.41-5.55 16.94-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M130.379 23.316c37.8 0 66.73 81.24 14.65 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.7-111.83 2.94-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M22.098 47.9c1.41-2.01 2.92-3.87 4.54-5.59a43.422 43.422 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.449.16 12.65-8.82 23.62-21.28 25.81a228.792 228.792 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43a25.578 25.578 0 0 1-13.78-7.22c-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.255 28.255 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41a27.444 27.444 0 0 1-1.48-2.7c-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#E5B97F"
                    d="M108.358 32.84c26.57 12.93 34.94 50.8 36.98 103.769.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.66 228.66 0 0 1-39.44 3.43h-22.46c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M40.398 32.358Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M37.027 34.08Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M34.287 35.777c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.879 41.027c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M22.097 47.898c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.838 53.047c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M18.058 54.457c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M16.679 57.148a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M24.377 114.738h76.55M62.647 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M85.108 185.639c14.7 0 27.96-1.42 39.44-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92h-22.46M85.108 185.637h-22.46"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M44.168 30.848c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M17.398 55.719c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.68-13.21 95.88-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05a27.444 27.444 0 0 0 1.48 2.7c.08.14.17.27.26.41a27.125 27.125 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M62.647 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={44.133}
                        x2={188.891}
                        y1={100.963}
                        y2={209.342}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={76.69}
                        x2={221.449}
                        y1={57.477}
                        y2={165.855}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={74.686}
                        x2={219.446}
                        y1={60.153}
                        y2={168.531}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.983}
                        x2={215.742}
                        y1={65.097}
                        y2={173.476}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={66.52}
                        x2={211.279}
                        y1={71.062}
                        y2={179.44}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={61.197}
                        x2={205.951}
                        y1={78.168}
                        y2={186.548}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={57.668}
                        x2={202.427}
                        y1={82.883}
                        y2={191.262}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={56.657}
                        x2={201.411}
                        y1={84.232}
                        y2={192.611}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={54.207}
                        x2={198.965}
                        y1={87.507}
                        y2={195.887}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.965} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 173,
        height: 187,
        content: (
            <>
                <path
                    fill="#888B8D"
                    d="M95.72 28.866c5.691-3.81 11.411-5.55 16.941-5.55h17.44c-2.87 0-5.8.47-8.74 1.46-4.42 1.47-8.88 4.11-13.28 8.06-3.75-1.84-7.86-3.17-12.36-3.97Z"
                />
                <path
                    fill="#63666A"
                    d="M130.101 23.316c37.8 0 66.73 81.24 14.65 139.85h-.01c.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79 23.41-48.92 4.23-102.43-23.7-111.83 2.94-.99 5.87-1.46 8.74-1.46Z"
                />
                <path
                    fill="url(#object-devices-bag-3-a)"
                    d="M21.82 47.9c1.41-2.01 2.92-3.87 4.54-5.59a43.421 43.421 0 0 1 5.15-4.71 42.943 42.943 0 0 1 5.24-3.52c1.09-.62 2.21-1.19 3.37-1.72 1.15-.53 2.34-1.01 3.56-1.44a48.9 48.9 0 0 1 7.76-2.04c.5-.09.99-.18 1.5-.25 1.58-.24 3.21-.42 4.89-.53 1.47-.1 2.99-.15 4.54-.15 49.67 0 59.77 51.2 60.73 128.449.16 12.65-8.82 23.62-21.28 25.81a228.779 228.779 0 0 1-39.45 3.43c-14.7 0-27.96-1.42-39.44-3.43-5.05-.89-9.53-3.22-13.06-6.52-.25-.23-.49-.47-.72-.7-.26-.27-.52-.53-.77-.81-.22-.24-.43-.49-.65-.74-.26-.32-.52-.64-.77-.97a28.256 28.256 0 0 1-1.1-1.56c-.09-.14-.18-.27-.26-.41-.36-.57-.7-1.16-1.01-1.76-.16-.31-.32-.62-.47-.94-.01-.02-.01-.04-.02-.05-.16-.34-.31-.68-.45-1.02-.15-.35-.28-.7-.41-1.05-1.07-2.9-1.63-6.03-1.59-9.28.49-40.2 3.47-73.34 13.21-95.88.5-1.15 1.01-2.28 1.54-3.37.23-.48.47-.96.72-1.43.21-.43.44-.85.66-1.26.05-.11.11-.22.18-.33.2-.37.39-.73.6-1.08a53.64 53.64 0 0 1 2.36-3.83c.29-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="#EA9975"
                    d="M108.081 32.84c26.57 12.93 34.94 50.8 36.98 103.769.25 6.38.41 12.98.49 19.79.03 2.33-.25 4.6-.81 6.77-2.46 9.62-10.31 17.25-20.47 19.04a228.664 228.664 0 0 1-39.44 3.43h-22.46c14.7 0 27.97-1.42 39.45-3.43 12.46-2.19 21.44-13.16 21.28-25.81-.96-77.25-11.06-128.45-60.73-128.45h22.46c3.86 0 7.49.31 10.89.92 4.5.8 8.61 2.13 12.36 3.97Z"
                />
                <path fill="url(#object-devices-bag-3-b)" d="M40.121 32.358Z" />
                <path fill="url(#object-devices-bag-3-c)" d="M36.75 34.08Z" />
                <path
                    fill="url(#object-devices-bag-3-d)"
                    d="M34.01 35.777c-.85.57-1.68 1.18-2.5 1.82.81-.65 1.64-1.25 2.5-1.82Z"
                />
                <path
                    fill="url(#object-devices-bag-3-e)"
                    d="M27.601 41.027c-.42.42-.83.84-1.24 1.28.4-.44.82-.86 1.24-1.28Z"
                />
                <path
                    fill="url(#object-devices-bag-3-f)"
                    d="M21.82 47.898c-.31.43-.61.87-.9 1.32.28-.45.59-.89.9-1.32Z"
                />
                <path
                    fill="url(#object-devices-bag-3-g)"
                    d="M18.56 53.047c-.21.35-.4.71-.6 1.08.19-.36.39-.73.6-1.08Z"
                />
                <path
                    fill="url(#object-devices-bag-3-h)"
                    d="M17.781 54.457c-.22.41-.45.83-.66 1.26.21-.42.43-.84.66-1.26Z"
                />
                <path
                    fill="url(#object-devices-bag-3-i)"
                    d="M16.401 57.148a87.63 87.63 0 0 0-1.54 3.37 65 65 0 0 1 1.54-3.37Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M24.1 114.738h76.55M62.37 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7M84.831 185.639c14.7 0 27.96-1.42 39.44-3.43 10.16-1.79 18.01-9.42 20.47-19.04.56-2.17.84-4.44.81-6.77-.08-6.81-.24-13.41-.49-19.79-2.04-52.97-10.41-90.84-36.98-103.77-3.75-1.84-7.86-3.17-12.36-3.97-3.4-.61-7.03-.92-10.89-.92h-22.46M84.831 185.637h-22.46"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M43.89 30.848c1.55-16.71 12.8-29.66 26.43-29.66 14.71 0 26.64 15.07 26.64 33.66M17.12 55.719c-.25.47-.49.95-.72 1.43a65 65 0 0 0-1.54 3.37c-9.74 22.54-12.72 55.68-13.21 95.88-.04 3.25.52 6.38 1.59 9.28.13.35.26.7.41 1.05.14.34.29.68.45 1.02.01.01.01.03.02.05.15.32.31.63.47.94.31.6.65 1.19 1.01 1.76.08.14.17.27.26.41a27.126 27.126 0 0 0 1.1 1.56c.25.33.51.65.77.97.22.25.43.5.65.74.25.28.51.54.77.81.23.23.47.47.72.7 3.53 3.3 8.01 5.63 13.06 6.52a228.34 228.34 0 0 0 39.44 3.43"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M62.37 27.95c-1.55 0-3.07.05-4.54.15-1.68.11-3.31.29-4.89.53-.51.07-1 .16-1.5.25a48.9 48.9 0 0 0-7.76 2.04c-1.22.43-2.41.91-3.56 1.44-1.16.53-2.28 1.1-3.37 1.72-.94.53-1.85 1.1-2.74 1.7-.86.57-1.69 1.17-2.5 1.82-1.36 1.05-2.66 2.2-3.91 3.43"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-3-a"
                        x1={43.855}
                        x2={188.613}
                        y1={100.963}
                        y2={209.342}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-b"
                        x1={76.413}
                        x2={221.171}
                        y1={57.477}
                        y2={165.855}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-c"
                        x1={74.409}
                        x2={219.169}
                        y1={60.153}
                        y2={168.531}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-d"
                        x1={70.706}
                        x2={215.464}
                        y1={65.097}
                        y2={173.476}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-e"
                        x1={66.244}
                        x2={211.006}
                        y1={71.062}
                        y2={179.446}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-f"
                        x1={60.921}
                        x2={205.676}
                        y1={78.168}
                        y2={186.551}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-g"
                        x1={57.389}
                        x2={202.147}
                        y1={82.883}
                        y2={191.256}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-h"
                        x1={56.382}
                        x2={201.138}
                        y1={84.232}
                        y2={192.619}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-3-i"
                        x1={53.931}
                        x2={198.69}
                        y1={87.507}
                        y2={195.89}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.793} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-bag-3"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
