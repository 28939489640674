import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={105}
            height={83}
            fill="none"
            id="object-food-shrimp"
            {...attributes}
        >
            <path
                fill="url(#object-food-shrimp-a)"
                d="M64.082 1.857c21.924 0 39.697 17.773 39.697 39.697 0 21.924-17.773 39.697-39.697 39.697-25.732 0-62.49-31.072-62.49-31.072h67.583a5.093 5.093 0 1 0 0-10.186h-5.093c-10.531 0-19.068-8.537-19.068-19.068 0-10.53 8.537-19.068 19.068-19.068Z"
            />
            <path
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="m65.174 1.867-7.03 37.18M97.555 20.207l-25.12 20.97M100.105 58.247l-26.08-11.62M76.945 79.118l-11.48-28.94M54.736 80.078l-.8-29.9"
                opacity={0.25}
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m36.984 50.178-5.25 20.55M95.738 17.597a39.52 39.52 0 0 1 8.041 23.957c0 21.924-17.773 39.697-39.697 39.697-25.732 0-62.49-31.072-62.49-31.072h67.583a5.093 5.093 0 1 0 0-10.186h-5.093c-10.531 0-19.068-8.537-19.068-19.068 0-10.53 8.537-19.068 19.068-19.068a39.53 39.53 0 0 1 17.52 4.066M1.592 50.18 34.36 60.452"
            />
            <defs>
                <linearGradient
                    id="object-food-shrimp-a"
                    x1={1.592}
                    x2={103.779}
                    y1={41.554}
                    y2={41.554}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FB8735" />
                    <stop offset={0.63} stopColor="#F76430" />
                    <stop offset={1} stopColor="#F64E2E" />
                </linearGradient>
            </defs>
        </svg>
    );
}
