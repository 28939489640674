import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-1-right">
        <g clipPath="url(#man-34-25-tshirt-4-both-arms-option-1-right-a)">
            <path
                d="M364.591 273.592a89.75 89.75 0 0 1-7.73-13.229 136.975 136.975 0 0 1-5.654-13.562 64.56 64.56 0 0 1-3.553-14.053 330.09 330.09 0 0 1-.557-4.385c-.391-3.194-.796-6.498-1.407-9.689a32.035 32.035 0 0 1 31.461-38.033 32.023 32.023 0 0 1 31.395 25.98l.065.353c.582 3.261 1.387 6.495 2.165 9.621l1.432 5.743c2.249-1.62-46.988 29.133-47.617 51.254Z"
                fill="url(#man-34-25-tshirt-4-both-arms-option-1-right-b)"
            />
            <path
                d="m385.928 241.761 10.925-8.488c7.3-5.639 14.765-11.072 22.326-16.386 3.801-2.631 7.565-5.309 11.406-7.89 3.82-2.608 7.646-5.208 11.536-7.729 3.879-2.534 7.728-5.107 11.632-7.609 3.947-2.451 7.83-4.98 11.841-7.35"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-right-b"
                x1={352.917}
                y1={220.775}
                x2={405.934}
                y2={237.274}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <clipPath id="man-34-25-tshirt-4-both-arms-option-1-right-a">
                <path fill="#fff" transform="translate(.693 .59)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
