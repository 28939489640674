import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string]> = {
    white: ['#FFDBAE', '#E3C094', '#CDAB81'],
    black: ['#6C453A', '#634036', '#4D352C'],
    brown: ['#CB865E', '#B07553', '#9A684B'],
    yellow: ['#FCB47F', '#E19A6D', '#CB865E'],
};


const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-walk-2">
        <path
            d="M522.504 819.099c-3-7.6-5.9-15.3-8.6-22.9-1.4-3.8-2.8-7.6-4.1-11.5l-4-11.5c-2.7-7.7-5.2-15.4-8.1-23-2.8-7.6-6-15.2-8.5-22.9-5.2-15.3-6.9-31.2-7.9-47.2-.4-8-.5-16-.1-24.1.1-2 .3-4.1.4-6.1.2-2 .4-4.1.6-6.1.5-4.1 1.1-8.2 2.1-12.4l2.8-11.7c3.3-14.1 17.5-22.8 31.6-19.5 11.3 2.7 19.1 12.3 20.1 23.3l4.1 47.7 2.1 23.9 1.9 23.9c1.3 15.9 2.6 31.8 3.8 47.8l3.6 47.8c.7 8.8-5.9 16.4-14.7 17.1-7.1.5-13.5-3.7-16-10l-1.1-2.6Z"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="M482.904 632.399c-5.5-15-10.5-30.1-15.8-45.2l-7.8-22.6-3.9-11.3-1.9-5.7-1.9-5.7c-5.1-15.1-9.1-30.4-13.1-45.7-2-7.6-3.8-15.3-5.6-23l-1.3-5.8-1.2-5.8c-.8-3.9-1.6-7.7-2.3-11.6-4.7-27.7 14-54 41.7-58.7 25.9-4.4 50.5 11.6 57.5 36.4l1.3 4.9c1.1 3.8 2 7.6 2.9 11.5l1.3 5.8 1.1 5.8c.8 3.9 1.4 7.7 2.1 11.6.7 3.9 1.2 7.8 1.8 11.7 2.2 15.6 4.2 31.2 5.1 47 .5 7.9.6 15.9.6 23.9l-.1 12-.3 12c-.6 16.1-1.6 32.2-4.2 48.7l-.7 4.3c-2.5 15.7-17.2 26.4-32.9 23.9-10.6-1.5-18.9-8.9-22.4-18.4Z"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="m523.504 821.599-1-2.5c-3-7.6-5.9-15.3-8.6-22.9-1.4-3.8-2.8-7.6-4.1-11.5l-4-11.5c-2.7-7.7-5.2-15.4-8.1-23-2.8-7.6-6-15.2-8.5-22.9-5.2-15.3-6.9-31.2-7.9-47.2-.4-8-.5-16-.1-24.1.1-2 .3-4.1.4-6.1.2-2 .4-4.1.6-6.1.5-4.1.6-5.8 1.6-10M538.703 623.499l4.1 47.7 2.1 23.9 1.9 23.9c1.3 15.9 2.6 31.8 3.8 47.8l3.6 47.8M522.504 416.199c2.1 3.6 3.7 7.5 4.9 11.6l1.3 4.9c1.1 3.8 2 7.6 2.9 11.5l1.3 5.8 1.1 5.8c.8 3.9 1.4 7.7 2.1 11.6.7 3.9 1.2 7.8 1.8 11.7 2.2 15.6 4.2 31.2 5.1 47 .5 7.9.6 15.9.6 23.9l-.1 12-.3 12c-.6 16.1-1.6 32.2-4.2 48.7M481.704 629.299c-5.5-15-9.4-27-14.6-42.1l-7.8-22.6-3.9-11.3-1.901-5.7-1.899-5.7c-5.1-15.1-9.101-30.4-13.101-45.7-2-7.6-3.799-15.3-5.599-23l-1.3-5.8-1.2-5.8c-.8-3.9-1.6-7.7-2.3-11.6M518.305 627.899h15.5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M523.805 821.899c1.4 4.1 3.999 20.5 5.699 31.1.8 5.1 5.701 8.5 10.801 7.4l19.9-4.2c4.2-.9 8.599-1.2 12.899-.8 4.5.4 11.701.6 20.701-1.3 4-.8 18.1-6.1 24.9-8.6 2.1-.8 3.1-3 2.5-5.1-.5-1.5-1.8-2.7-3.4-2.9-3.3-.5-9.2-1.2-13.9-1.7-21.1-2-48.2-20.7-48.2-20.7"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="M523.805 821.899c1.4 4.1 3.999 20.5 5.699 31.1.8 5.1 5.701 8.5 10.801 7.4l19.9-4.2c4.2-.9 8.599-1.2 12.899-.8 4.5.4 11.701.6 20.701-1.3 4-.8 18.1-6.1 24.9-8.6 2.1-.8 3.1-3 2.5-5.1-.5-1.5-1.8-2.7-3.4-2.9-3.3-.5-9.2-1.2-13.9-1.7-21.1-2-48.2-20.7-48.2-20.7"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M299.304 790.999c1.4-8.1 3-16.1 4.7-24.1.8-4 1.6-8 2.5-12l2.7-11.9c1.8-7.9 3.6-15.8 5.2-23.9 1.6-8 2.9-16.2 4.8-24 3.6-15.9 10.5-30.3 18-44.5 3.8-7.1 8-14 12.6-20.7 1.1-1.7 2.4-3.3 3.5-5 1.2-1.6 2.4-3.3 3.8-4.9 2.6-3.2 5.3-6.4 8.3-9.5l8.4-8.5c10.2-10.3 26.8-10.3 37.1-.1 8.2 8.2 9.9 20.5 4.9 30.4l-21.5 42.9-10.8 21.5-10.9 21.4c-7.3 14.3-14.5 28.6-21.9 42.8l-22 42.7c-4 7.8-13.6 10.9-21.5 6.9-6.3-3.3-9.6-10.2-8.4-16.9l.5-2.6Z"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="M361.704 618.999c-1.7-15.2-2.899-30.4-4.299-45.6l-2-22.8-1-11.4-.5-5.7-.401-5.7c-1.2-15.2-1.499-30.3-1.599-45.4-.1-7.5-.001-15.1.199-22.6l.1-5.6.3-5.6c.2-3.8.3-7.5.6-11.3 2.3-28 26.9-48.8 54.9-46.5 26.1 2.1 46 23.6 46.7 49.2l.1 5.3c.1 3.8.1 7.5 0 11.3l-.2 5.6-.3 5.6c-.2 3.8-.5 7.5-.8 11.2-.3 3.8-.699 7.5-1.099 11.2-1.6 15-3.6 29.9-6.5 44.7-1.5 7.4-3.301 14.8-5.301 22.2l-3 11.1c-1 3.7-2.099 7.4-3.199 11.1-4.5 14.7-9.401 29.4-15.901 44l-1.9 4.2c-6.5 14.5-23.5 21-38.1 14.5-9.6-4.1-15.7-13.2-16.8-23Z"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="M361.704 618.999c-1.7-15.2-2.899-30.4-4.299-45.6l-2-22.8-1-11.4-.5-5.7-.401-5.7c-1.2-15.2-1.499-30.3-1.599-45.4-.1-7.5-.001-15.1.199-22.6l.1-5.6.3-5.6c.2-3.8.3-7.5.6-11.3 2.3-28 26.9-48.8 54.9-46.5 26.1 2.1 46 23.6 46.7 49.2l.1 5.3c.1 3.8.1 7.5 0 11.3l-.2 5.6-.3 5.6c-.2 3.8-.5 7.5-.8 11.2-.3 3.8-.699 7.5-1.099 11.2-1.6 15-3.6 29.9-6.5 44.7-1.5 7.4-3.301 14.8-5.301 22.2l-3 11.1c-1 3.7-2.099 7.4-3.199 11.1-4.5 14.7-9.401 29.4-15.901 44l-1.9 4.2c-6.5 14.5-23.5 21-38.1 14.5-9.6-4.1-15.7-13.2-16.8-23Z"
            fill="url(#man-bold-34-25-legs-walk-2-a)"
        />
        <path
            d="m298.904 793.599.5-2.7c1.4-8.1 3-16.1 4.7-24.1.8-4 1.6-8 2.5-12l2.7-11.9c1.8-7.9 3.6-15.8 5.2-23.9 1.6-8 2.9-16.2 4.8-24 3.6-15.9 10.5-30.3 18-44.5 3.8-7.1 8-14 12.6-20.7 1.1-1.7 2.4-3.3 3.5-5 1.2-1.6 2.4-3.3 3.8-4.9 1.1-1.4 2.2-2.7 3.4-4.1M420.303 619.399c-7 18-26 55.9-26 55.9l-10.8 21.5-10.9 21.4c-7.3 14.3-14.5 28.6-21.9 42.8l-22 42.7M454.603 439.999l.1 5.3c.1 3.8.1 7.5 0 11.3l-.2 5.6-.3 5.6c-.2 3.8-.5 7.5-.8 11.2-.3 3.8-.7 7.5-1.1 11.2-1.6 15-3.6 29.9-6.5 44.7-1.5 7.4-3.3 14.8-5.3 22.2l-3 11.1c-1 3.7-2.1 7.4-3.2 11.1-4 13.4-8.5 26.7-14.1 40M360.704 609.299c-1.2-12-2.199-23.9-3.299-35.9l-2-22.8-1-11.4-.5-5.7-.401-5.7c-1.2-15.2-1.499-30.3-1.599-45.4-.1-7.5-.001-15.1.199-22.6l.1-5.6.3-5.6c.2-3.8.3-7.5.6-11.3M392.504 627.699h15.5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m299.403 794.199-9.6 15.3c-1.8 2.9-1.8 6.7 0 9.6l19.5 31c4.1 6.6 11.4 10.5 19.1 10.5h26.4c2.6 0 4.9-1.8 5.7-4.3.8-3-.8-6.1-3.7-7.1l-3.8-1.3c-4.7-1.7-8.4-5.3-10.2-10l-13.6-36.6"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="m299.403 794.199-9.6 15.3c-1.8 2.9-1.8 6.7 0 9.6l19.5 31c4.1 6.6 11.4 10.5 19.1 10.5h26.4c2.6 0 4.9-1.8 5.7-4.3.8-3-.8-6.1-3.7-7.1l-3.8-1.3c-4.7-1.7-8.4-5.3-10.2-10l-13.6-36.6"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-legs-walk-2-a"
                x1={465.574}
                y1={577.51}
                x2={225.655}
                y2={353.783}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.511} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.915} stopColor={SKIN_COLOR[color][2]} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
