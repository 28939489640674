import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-1">
        <path
            d="M508.754 193.653c4.768 6.066 9.879 11.862 14.842 17.775a164.312 164.312 0 0 1 13.632 18.731c4.178 6.532 8.182 13.203 11.945 20.064a206.667 206.667 0 0 1 10.325 21.344 23.444 23.444 0 0 1-12.952 30.52 23.682 23.682 0 0 1-22.353-2.618 206.612 206.612 0 0 1-18.381-14.977c-5.806-5.246-11.371-10.683-16.761-16.258a164.223 164.223 0 0 1-15.075-17.591c-4.606-6.195-9.064-12.506-13.864-18.547a30.99 30.99 0 0 1-6.53-22.81 30.984 30.984 0 0 1 11.512-20.747 30.996 30.996 0 0 1 43.557 4.982l.103.132Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m549.276 262.266 4.429 1.787 4.452 1.718a66.051 66.051 0 0 1 8.657 4.178c2.819 1.594 5.617 3.25 8.358 5.077a83.127 83.127 0 0 1 8.071 5.935 16.695 16.695 0 0 1 5.1 17.844 16.692 16.692 0 0 1-3.164 5.697 16.758 16.758 0 0 1-5.269 4.129 16.753 16.753 0 0 1-6.465 1.74c-3.338.171-6.684.14-10.018-.091-3.289-.182-6.521-.535-9.732-.95a66.077 66.077 0 0 1-9.433-1.85l-4.593-1.295-4.616-1.226a22.533 22.533 0 0 1-16.555-18.798 22.538 22.538 0 0 1 11.032-22.488 22.534 22.534 0 0 1 17.101-2.288c.897.244 1.78.539 2.645.881Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M524.193 299.47a206.8 206.8 0 0 1-18.381-14.978c-5.806-5.246-11.371-10.683-16.761-16.258a164.126 164.126 0 0 1-15.075-17.591c-4.606-6.194-9.064-12.506-13.864-18.547a30.99 30.99 0 0 1-6.53-22.81 30.984 30.984 0 0 1 11.512-20.747 31.005 31.005 0 0 1 34.328-2.837 30.987 30.987 0 0 1 9.229 7.82l.103.13c4.768 6.067 9.879 11.863 14.842 17.775a164.4 164.4 0 0 1 13.632 18.731c4.178 6.533 8.182 13.203 11.945 20.065 1.871 3.438 3.699 6.911 5.436 10.456"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M573.444 310.37c-3.338.17-6.683.14-10.018-.092-3.289-.182-6.521-.535-9.732-.95a66.075 66.075 0 0 1-9.433-1.849l-4.593-1.295s-9.361-2.412-15.475-6.715M553.705 264.051l4.452 1.718a65.986 65.986 0 0 1 8.657 4.178c2.819 1.593 5.617 3.25 8.358 5.076a83.46 83.46 0 0 1 8.071 5.935"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M631.159 269.648a3.237 3.237 0 0 0-1.985-2.331 3.039 3.039 0 0 0-2.57.525 3.276 3.276 0 0 0-1.297 2.191l-.724.182a2.661 2.661 0 0 0-1.271.974l-1.557 2.223-1.647 2.417a2.337 2.337 0 0 0-1.117-.75l-.04-.012a2.339 2.339 0 0 0-2.729 1.058 43.34 43.34 0 0 1-2.671 4.146l-.146.199a22.858 22.858 0 0 1-6.433-2.123l1.565-2.686a96.028 96.028 0 0 1 9.402-12.97 2.932 2.932 0 0 0-.243-4.063l-.051-.045a2.98 2.98 0 0 0-3.322-.443 47.398 47.398 0 0 0-15.369 12.009 38.178 38.178 0 0 0-13.919 7.78 68.66 68.66 0 0 1-6.819 5.125c-14.641 9.922-18.546 17.766-9.384 23.079 7.692 3.196 16.803 1.539 23.902-2.819.287-.177.572-.357.853-.541l.641.06c6.061.1 11.87-2.766 16.277-6.98l1.267.07a8.514 8.514 0 0 0 5.703-2.037 19.168 19.168 0 0 0 3.965-4.724 47.9 47.9 0 0 0 1.969-3.364l.751-.363a22.616 22.616 0 0 0 6.966-12.617 7.464 7.464 0 0 0 .033-3.17Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M582.713 280.147a38.161 38.161 0 0 1 16.241-9.999 47.426 47.426 0 0 1 15.405-12.027 2.952 2.952 0 0 1 3.287.461l.05.045a2.9 2.9 0 0 1 .262 4.041 96.138 96.138 0 0 0-9.421 12.992"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M602.709 275.52a22.597 22.597 0 0 0 10.695 4.951M593.401 292.719a40.649 40.649 0 0 0 10.498-8.295 2.513 2.513 0 0 1 2.91-.581 2.545 2.545 0 0 1 1.456 2.659 2.531 2.531 0 0 1-.372 1.008c-3.993 6.222-8.882 11.95-15.158 15.802-7.099 4.359-16.51 6.661-23.902 2.82-11.392-5.919-.774-17.532 5.032-19.191"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M610.427 284.084a43.152 43.152 0 0 0 5.793-7.958 2.343 2.343 0 0 1 2.731-1.06l.04.013a2.35 2.35 0 0 1 1.395 1.171 2.37 2.37 0 0 1 .146 1.816c-1.894 5.799-4.313 11.466-8.337 16.027-4.546 5.154-11.095 8.853-17.966 8.74"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m621.755 273.413 1.557-2.223a2.69 2.69 0 0 1 2.874-1.053l.045.012a2.678 2.678 0 0 1 1.92 3.258 53.666 53.666 0 0 1-6.711 15.756 19.15 19.15 0 0 1-3.965 4.724 8.516 8.516 0 0 1-5.704 2.037"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M629.175 267.316a3.24 3.24 0 0 1 1.986 2.331 7.464 7.464 0 0 1-.034 3.17 22.62 22.62 0 0 1-6.965 12.617"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M499.944 186.525c-9.226-7.123-19.957-12.656-32.551-17.17l19.121 31.544s17.866-10.95 13.43-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.393 169.355a129.684 129.684 0 0 1 32.551 17.17"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
