import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-5">
        <path
            d="M369.782 286.481c-3.209-6.59-6.24-13.434-9.267-20.924a158.639 158.639 0 0 1-7.215-21.588c-.565-2.197-1.114-4.402-1.663-6.606-1.251-5.02-2.544-10.21-4.018-15.246a32.038 32.038 0 0 1 21.725-39.7 32.08 32.08 0 0 1 9.001-1.296 32.161 32.161 0 0 1 30.699 23.021l.048.168c1.446 5.043 3.125 10.118 4.75 15.025.715 2.161 1.43 4.321 2.129 6.486a158.47 158.47 0 0 1 5.44 22.107 348.54 348.54 0 0 1 2.304 14.289c-11.29 1.467-42.809 11.877-53.933 24.264Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-5-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-5-a"
                x1={369.215}
                y1={184.623}
                x2={394.372}
                y2={267.033}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
