import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7230)"
            d="M475.683 232.67l.065-.318a64.034 64.034 0 001.386-10.446 32.912 32.912 0 00-1.357-11.177l-.834-2.627 2.325 1.482a106.713 106.713 0 0117.498 13.757 2.708 2.708 0 01.821 2.482c-.804 4.662-13.806 10.891-16.403 12.092l-.913.422-2.588-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7230)"
            d="M561.56 293.22a220.874 220.874 0 01-23.587-2.502c-7.384-1.146-14.888-2.57-22.947-4.353a175.532 175.532 0 01-22.379-6.302 767.48 767.48 0 01-6.564-2.353c-4.971-1.796-10.111-3.651-15.22-5.285a27.505 27.505 0 01-19.173-23.913 27.495 27.495 0 0114.866-26.803 27.495 27.495 0 0121.05-1.673l.129.041c5.101 1.658 10.355 3.154 15.436 4.599 2.239.637 4.477 1.279 6.713 1.927a168.45 168.45 0 0112.784 4.274c-.193-.454-.382-.91-.569-1.368l-2.328-5.537a183.407 183.407 0 01-1.466-3.658c-.256-.654-.514-1.307-.774-1.96-2.21-5.631-3.922-10.26-5.391-14.573l-.495-1.452 1.529.134c.476.042.954.061 1.434.061 9.237-.001 18.715-7.22 22.52-11.772l.792-.947.762.971c2.601 3.318 5.307 6.939 8.518 11.395.415.584.83 1.161 1.246 1.737.763 1.06 1.526 2.119 2.265 3.193l3.4 4.964c1.558 2.226 3.056 4.536 4.506 6.77a456.918 456.918 0 002.132 3.268 238.574 238.574 0 0112.149 20.768l5.461 10.664 5.58 10.581a19.512 19.512 0 011.38 14.862 19.51 19.51 0 01-9.534 11.484 26 26 0 01-14.225 2.758z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7230"
                x1="488.252"
                x2="463.645"
                y1="204.852"
                y2="304.745"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7230"
                x1="498.053"
                x2="537.956"
                y1="195.808"
                y2="318.988"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
