import { ILegs, TLegsSkins } from 'interfaces';

import LegsSkinStand1 from 'assets/male/skin-layer/normal-legs/man-bold-34-25-legs-stand-1';
import LegsSkinStand2 from 'assets/male/skin-layer/normal-legs/man-bold-34-25-legs-stand-2';
import LegsSkinWalk1 from 'assets/male/skin-layer/normal-legs/man-bold-34-25-legs-walk-1';
import LegsSkinWalk2 from 'assets/male/skin-layer/normal-legs/man-bold-34-25-legs-walk-2';
import LegsSkinCycling1Right from 'assets/male/skin-layer/cycling-legs/man-bold-34-25-legs-cycling-right';
import LegsSkinCycling1Left from 'assets/male/skin-layer/cycling-legs/man-bold-34-25-legs-cycling-left';
import LegsSkinCycling2Right from 'assets/male/skin-layer/cycling-legs/man-bold-34-25-legs-cycling2-right';
import LegsSkinCycling2Left from 'assets/male/skin-layer/cycling-legs/man-bold-34-25-legs-cycling2-left';
import SittingRight1 from 'assets/male/skin-layer/sitting-legs/female-34-25-legs-sitting-1-right';
import SittingLeft1 from 'assets/male/skin-layer/sitting-legs/female-34-25-legs-sitting-1-left';
import SittingRight2 from 'assets/male/skin-layer/sitting-legs/female-34-25-legs-sitting-2-right';
import SittingLeft2 from 'assets/male/skin-layer/sitting-legs/female-34-25-legs-sitting-2-left';

import pants1 from 'assets/male/clothes/legs/pants-1';
import pants2 from 'assets/male/clothes/legs/pants-2';
import pants3 from 'assets/male/clothes/legs/pants-3';
import pants4 from 'assets/male/clothes/legs/pants-4';

import shorts12 from 'assets/male/clothes/legs/shorts-1-2/index';
import shorts3 from 'assets/male/clothes/legs/shorts-3';

import shoes1 from 'assets/male/clothes/shoes/shoes-1';
import shoes2 from 'assets/male/clothes/shoes/shoes-2';
import shoes3 from 'assets/male/clothes/shoes/shoes-3';
import shoes4 from 'assets/male/clothes/shoes/shoes-4';
import shoes5 from 'assets/male/clothes/shoes/shoes-5';
import shoes6 from 'assets/male/clothes/shoes/shoes-6';

export const M_POSES = {
    stand1: 'Standing #1',
    stand2: 'Standing #2',
    walk1: 'Walking #1',
    walk2: 'Walking #2',
    cycling1: 'Cycling #1',
    cycling2: 'Cycling #2',
    sitting1: 'Sitting #1',
    sitting2: 'Sitting #2',
} as const;

export const M_LEGS: Record<string, ILegs> = {
    pants1,
    pants2,
    pants3,
    pants4,
    shorts1: shorts12.shorts1,
    shorts2: shorts12.shorts2,
    shorts3,
};

export const M_SHOES: Record<string, ILegs> = {
    shoes1,
    shoes2,
    shoes3,
    shoes4,
    shoes5,
    shoes6,
};

export const M_LEGS_SKINS: TLegsSkins = {
    stand1: [[LegsSkinStand1]],
    stand2: [[LegsSkinStand2]],
    walk1: [[LegsSkinWalk1]],
    walk2: [[LegsSkinWalk2]],
    cycling1: [[LegsSkinCycling1Right, LegsSkinCycling1Left]],
    cycling2: [[LegsSkinCycling2Right, LegsSkinCycling2Left]],
    sitting1: [[SittingRight1, SittingLeft1]],
    sitting2: [[SittingRight2, SittingLeft2]],
};
