import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="female-bold-34-25-legs-walk-1">
        <path
            d="M522.556 820.096a690.693 690.693 0 0 1-8.603-22.448c-1.363-3.752-2.812-7.491-4.104-11.253l-3.94-11.278c-2.655-7.514-5.174-15.047-8.069-22.526-2.824-7.49-6.006-14.927-8.475-22.468-5.201-15.043-6.819-30.61-7.849-46.262a226.07 226.07 0 0 1-.095-23.689c.074-1.986.268-3.99.396-5.983.205-2.006.363-4.004.635-6.019a104.667 104.667 0 0 1 2.146-12.164l2.874-11.847a26.195 26.195 0 0 1 31.63-19.28 26.245 26.245 0 0 1 13.684 8.397 26.242 26.242 0 0 1 6.236 14.794l4.061 46.814 2.019 23.409 1.881 23.429c1.23 15.623 2.558 31.231 3.709 46.865l3.529 46.892a15.92 15.92 0 0 1-14.682 17.072 15.933 15.933 0 0 1-15.957-9.899l-1.026-2.556Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m484.544 637.758-18.024-43.27-4.52-10.815-4.63-10.791c-3.06-7.2-6.421-14.336-9.422-21.549-3.001-7.212-5.756-14.476-8.138-21.82-1.226-3.664-2.29-7.362-3.443-11.041l-1.697-5.527-1.55-5.556c-1.022-3.708-2.11-7.401-3.003-11.135-.924-3.728-1.916-7.441-2.714-11.196l-1.261-5.618-1.121-5.647a193.818 193.818 0 0 1-1.887-11.37 51.01 51.01 0 0 1 43.634-57.453 51.033 51.033 0 0 1 54.361 31.784l1.81 4.618c1.428 3.621 2.675 7.281 3.825 10.961l1.693 5.526 1.521 5.563c1.067 3.698 1.894 7.446 2.806 11.177.928 3.727 1.713 7.484 2.495 11.242 1.558 7.516 2.845 15.09 4.027 22.686 1.177 7.597 2.102 15.247 2.71 22.965.614 7.715.762 15.529.766 23.374l-.024 11.773c-.033 3.93-.156 7.878-.229 11.816-.512 15.799-1.558 31.71-4.438 48.008l-.971 5.494a27.575 27.575 0 0 1-3.901 10.02 27.546 27.546 0 0 1-28.033 12.32 27.851 27.851 0 0 1-20.642-16.539ZM523.832 823.387c1.259 3.697 3.63 18.432 5.095 28.013a8.096 8.096 0 0 0 9.696 6.693l17.894-3.817a37.944 37.944 0 0 1 11.618-.702 62.71 62.71 0 0 0 18.641-1.16c3.616-.746 16.322-5.464 22.402-7.758a3.74 3.74 0 0 0-.805-7.202c-2.993-.414-8.284-1.117-12.513-1.519-18.986-1.804-43.407-18.654-43.407-18.654"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M523.832 823.387c1.259 3.697 3.63 18.432 5.095 28.013a8.096 8.096 0 0 0 9.696 6.693l17.894-3.817a37.944 37.944 0 0 1 11.618-.702 62.71 62.71 0 0 0 18.641-1.16c3.616-.746 16.322-5.464 22.402-7.758a3.74 3.74 0 0 0-.805-7.202c-2.993-.414-8.284-1.117-12.513-1.519-18.986-1.804-43.407-18.654-43.407-18.654M523.583 822.654l-1.027-2.556a690.693 690.693 0 0 1-8.603-22.448c-1.363-3.752-2.812-7.491-4.104-11.253l-3.94-11.278c-2.655-7.514-5.174-15.047-8.069-22.526-2.824-7.489-6.006-14.927-8.475-22.468-5.201-15.043-6.819-30.61-7.849-46.261a226.09 226.09 0 0 1-.095-23.69c.074-1.986.268-3.99.396-5.983.205-2.006.363-4.004.635-6.019a104.667 104.667 0 0 1 2.146-12.164M539.023 628.07l4.062 46.815 2.019 23.408 1.88 23.429c1.23 15.623 2.558 31.231 3.709 46.866l3.253 43.221M481.796 631.159l-15.276-36.671-4.52-10.815-4.63-10.791c-3.06-7.2-6.421-14.336-9.422-21.549-3.001-7.212-5.756-14.476-8.138-21.82-1.226-3.664-2.29-7.362-3.443-11.041l-1.697-5.527-1.55-5.556c-1.022-3.708-2.11-7.401-3.003-11.135-.924-3.728-1.916-7.441-2.714-11.196l-1.261-5.618-1.121-5.647a193.818 193.818 0 0 1-1.887-11.37 51.014 51.014 0 0 1 76.301-50.945 51.032 51.032 0 0 1 21.694 25.276l1.809 4.618c1.429 3.621 2.675 7.281 3.826 10.961l1.693 5.526 1.521 5.563c1.067 3.698 1.894 7.446 2.806 11.177.928 3.727 1.713 7.484 2.494 11.242 1.559 7.516 2.846 15.09 4.028 22.686 1.176 7.597 2.102 15.247 2.71 22.965.614 7.715.762 15.529.766 23.374l-.024 11.773c-.033 3.93-.156 7.878-.229 11.816a357.993 357.993 0 0 1-3.387 41.547"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.477a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.193-41.867 40.779-59.908H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.477a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.193-41.867 40.779-59.908"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M310.205 792.105a608.941 608.941 0 0 1 2.334-22.425c.456-3.713.831-7.455 1.355-11.145l1.511-11.091c.982-7.403 2.093-14.76 2.844-22.245.82-7.46 1.298-15.041 2.457-22.381 2.065-14.77 7.545-28.333 13.584-41.698a198.329 198.329 0 0 1 10.441-19.558c.949-1.603 2.01-3.164 3.01-4.749 1.074-1.558 2.101-3.132 3.238-4.667a93.28 93.28 0 0 1 7.31-9.041l8.813-9.523a26.019 26.019 0 0 1 36.77-1.425 26.084 26.084 0 0 1 6.271 29.282L392.95 681.86l-8.607 20.207-8.74 20.16c-5.849 13.432-11.604 26.898-17.529 40.303l-17.7 40.242a15.913 15.913 0 0 1-30.424-7.735l.255-2.932Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m358.259 630.12-4.636-45.952-1.173-11.489-1.286-11.5c-.829-7.663-1.966-15.354-2.735-23.013-.77-7.658-1.289-15.294-1.429-22.896-.104-3.805-.046-7.594-.077-11.392l-.013-5.693.137-5.68c.102-3.786.137-7.578.371-11.352.202-3.776.334-7.559.665-11.324l.43-5.654.573-5.641c.388-3.76.891-7.51 1.507-11.25a51.013 51.013 0 0 1 58.583-42.106 51.033 51.033 0 0 1 42.616 46.16l.397 5.02c.311 3.823.437 7.628.466 11.426l.01 5.693-.166 5.677c-.056 3.79-.357 7.558-.571 11.334-.198 3.777-.542 7.541-.889 11.305-.699 7.527-1.674 15.03-2.757 22.523-1.087 7.492-2.43 14.962-4.096 22.403-1.66 7.441-3.795 14.84-6.076 22.226l-3.452 11.076c-1.176 3.69-2.444 7.371-3.662 11.058-5.091 14.724-10.726 29.399-18.229 43.909l-2.599 5.026a27.56 27.56 0 0 1-37.145 11.823 27.834 27.834 0 0 1-10.102-9.009 27.84 27.84 0 0 1-4.662-12.708Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m410.143 641.443-17.193 40.422-8.607 20.207-8.74 20.159c-5.849 13.433-11.604 26.898-17.529 40.303l-17.7 40.243a15.914 15.914 0 0 1-20.971 8.158 15.923 15.923 0 0 1-9.453-15.893l.254-2.932a612.866 612.866 0 0 1 2.334-22.425c.457-3.714.832-7.456 1.356-11.146l1.511-11.091c.981-7.403 2.092-14.76 2.844-22.244.82-7.46 1.298-15.041 2.457-22.382 2.065-14.77 7.545-28.332 13.584-41.697a198.458 198.458 0 0 1 10.441-19.559c.948-1.602 2.01-3.164 3.01-4.748 1.073-1.558 2.101-3.132 3.238-4.668a93.28 93.28 0 0 1 7.31-9.041M356.905 616.698l-3.282-32.532-1.173-11.49-1.286-11.499c-.829-7.664-1.966-15.355-2.735-23.013-.77-7.659-1.289-15.295-1.429-22.897-.104-3.804-.046-7.593-.077-11.391l-.013-5.693.137-5.681c.102-3.785.137-7.577.371-11.351.202-3.777.334-7.56.665-11.325l.43-5.654.573-5.641c.388-3.76.891-7.51 1.507-11.25M452.657 467.781l.01 5.693-.165 5.678c-.057 3.79-.358 7.558-.572 11.333-.198 3.777-.542 7.541-.889 11.305-.698 7.528-1.673 15.03-2.756 22.523-1.087 7.493-2.43 14.962-4.097 22.403-1.66 7.441-3.795 14.84-6.076 22.226l-3.452 11.076c-1.176 3.69-2.444 7.372-3.661 11.058-5.092 14.724-10.727 29.4-18.229 43.91"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m310.337 797.445-8.645 13.802a8.106 8.106 0 0 0 .013 8.62l17.577 27.872a20.345 20.345 0 0 0 17.209 9.493h23.781a5.278 5.278 0 0 0 1.75-10.26l-3.384-1.189a15.124 15.124 0 0 1-9.166-9.001l-12.259-32.967"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m310.337 797.445-8.645 13.802a8.106 8.106 0 0 0 .013 8.62l17.577 27.872a20.345 20.345 0 0 0 17.209 9.493h23.781a5.278 5.278 0 0 0 1.75-10.26l-3.384-1.189a15.124 15.124 0 0 1-9.166-9.001l-12.259-32.967"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
