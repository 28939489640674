import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6835)"
            d="M476.143 208.914a110.644 110.644 0 0118.627 14.398 2.708 2.708 0 01.822 2.483c-.805 4.666-13.808 10.892-16.407 12.093 0 0-14.647 1.881-13.641.722 11.756-13.547 12.47-21.795 10.599-29.696z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6835)"
            d="M482.603 278.845c-5.01-2.651-10.19-5.391-15.365-7.904a27.53 27.53 0 01-12.732-36.747 27.679 27.679 0 0124.76-15.499 27.236 27.236 0 0111.988 2.767l.116.056c5.163 2.537 10.51 4.936 15.68 7.256-9.121 4.975-25.241 29.12-24.447 50.071z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6835"
                x1="492.836"
                x2="445.342"
                y1="213.183"
                y2="248.316"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6835"
                x1="504.041"
                x2="456.547"
                y1="228.337"
                y2="263.47"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
