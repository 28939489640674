import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M549.433 738.151c-.448-5.519-.891-11.039-1.33-16.562l-1.88-23.435-2.02-23.409-4.062-46.816a27.046 27.046 0 0 0-.576-3.599 347.5 347.5 0 0 0 4.084-45.909c.026-1.389.057-2.78.091-4.173.058-2.554.118-5.107.139-7.661l.024-11.775c-.003-6.462-.094-14.967-.77-23.453-.583-7.403-1.498-15.154-2.719-23.04-1.39-8.934-2.673-16.158-4.037-22.736-.724-3.484-1.545-7.434-2.501-11.275-.239-.977-.472-1.955-.705-2.933-.649-2.72-1.319-5.533-2.108-8.27l-1.53-5.592-1.694-5.532c-1.289-4.125-2.549-7.733-3.848-11.027l-1.81-4.62c-7.76-19.674-.706-25.3-.706-27.686h-54.721s-25.974 4.289-34.377 15.353a51.678 51.678 0 0 0-10.112 38.504 200.116 200.116 0 0 0 1.896 11.43l1.126 5.671 1.259 5.608c.594 2.793 1.304 5.601 1.99 8.317.246.97.491 1.94.73 2.902.671 2.809 1.463 5.633 2.229 8.363.262.935 2.34 8.39 2.34 8.39l1.698 5.533c.407 1.298.803 2.598 1.199 3.898.714 2.35 1.454 4.78 2.248 7.153 2.249 6.933 4.92 14.094 8.165 21.894 1.639 3.94 3.412 7.917 5.127 11.763 1.43 3.207 2.908 6.522 4.3 9.797l4.626 10.782 4.52 10.813 17.683 42.452a107.856 107.856 0 0 0-1.817 10.713 111.184 111.184 0 0 0-.466 4.297c-.056.584-.112 1.168-.175 1.792-.045.702-.099 1.406-.152 2.109-.096 1.277-.197 2.599-.245 3.887a225.586 225.586 0 0 0 .096 23.806c1.155 17.548 2.94 32.176 7.897 46.507 1.652 5.049 8.455 29.355 9.611 33.967l52.582-10.002c-.429-5.446-.868-10.87-1.299-16.196Z"
            fill="#001489"
        />
        <path
            d="M349.879 408.556c-2.648 13.07-2.238 24.704-.148 38.512a194.33 194.33 0 0 0-1.516 11.31l-.574 5.666-.43 5.642c-.246 2.804-.384 5.655-.519 8.412-.048.982-.096 1.965-.148 2.939-.175 2.832-.24 5.712-.303 8.496-.022.964-.206 8.599-.206 8.599l.013 5.7c.011 1.339.01 2.676.01 4.015 0 2.419-.001 4.921.067 7.387.132 7.183.601 14.699 1.433 22.978.42 4.18.957 8.433 1.477 12.546.434 3.436.882 6.989 1.26 10.477l1.285 11.49 1.173 11.488 3.96 39.245a95.969 95.969 0 0 0-6.415 8.044c-.81 1.095-1.572 2.219-2.31 3.306-.314.463-.628.927-.97 1.423a175.15 175.15 0 0 1-1.073 1.672 89.679 89.679 0 0 0-1.944 3.089 197.79 197.79 0 0 0-10.499 19.668c-6.775 14.994-11.692 27.869-13.66 41.953-.778 4.919-1.257 10.013-1.72 14.94-.235 2.501-3.477 28.99-3.477 28.99s14.097 6.108 25.374 9.867a137.005 137.005 0 0 0 23.127 5.498c2.57-8.426 22.24-59.498 22.24-59.498l8.608-20.207 17.194-40.423a27.269 27.269 0 0 0 1.303-3.895l1.288-2.492c8.177-15.814 14.069-31.844 18.29-44.056.429-1.299.863-2.598 1.3-3.895.801-2.39 1.602-4.779 2.367-7.178l3.453-11.079c1.879-6.084 4.271-14.118 6.096-22.302 1.6-7.14 2.982-14.703 4.111-22.479 1.272-8.807 2.15-15.98 2.762-22.574l.01-.108a43.4 43.4 0 0 1 18.06-31.407 157.814 157.814 0 0 0 12.84-10.235c20.888-18.462 32.63-35.648 38.559-51.525l-171.748.001Z"
            fill="url(#female-34-25-pants-7-walk-1-a)"
        />
        <path
            d="M318.122 717.555c-.087.924-.584 5.124-1.177 10.05 19.256 9.49 40.606 15.325 52.028 18.038a3028.028 3028.028 0 0 1 6.653-17.686c-12.352-3.407-35.078-10.289-56.506-20.027-.386 3.217-.7 6.455-.998 9.625Z"
            fill="url(#female-34-25-pants-7-walk-1-b)"
        />
        <path
            d="M318.122 717.555c-.087.924-.584 5.124-1.177 10.05 19.256 9.49 40.606 15.325 52.028 18.038a3028.028 3028.028 0 0 1 6.653-17.686c-12.352-3.407-35.078-10.289-56.506-20.027-.386 3.217-.7 6.455-.998 9.625Z"
            fill="url(#female-34-25-pants-7-walk-1-c)"
        />
        <path
            d="M322.312 690.452c20.219 9.681 45.441 17.207 59.585 21.048 2.094-5.47 3.488-9.089 3.488-9.089l3.714-8.719c-13.605-4.382-36.891-12.183-60.571-21.489a151.646 151.646 0 0 0-6.216 18.249Z"
            fill="url(#female-34-25-pants-7-walk-1-d)"
        />
        <path
            d="M322.312 690.452c20.219 9.681 45.441 17.207 59.585 21.048 2.094-5.47 3.488-9.089 3.488-9.089l3.714-8.719c-13.605-4.382-36.891-12.183-60.571-21.489a151.646 151.646 0 0 0-6.216 18.249Z"
            fill="url(#female-34-25-pants-7-walk-1-e)"
        />
        <path
            d="M488.542 730.378c.764 2.336 2.632 8.798 4.533 15.523l56.221-9.499c-.4-4.938-.797-9.877-1.19-14.817l-.361-4.499-60.225 10.176c.326 1.04.663 2.079 1.022 3.116ZM546.224 698.153l-1.62-18.778-63.51 10.731c.538 6.762 1.232 13.105 2.234 19.201l62.937-10.635-.041-.519Z"
            fill="#9E005D"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-7-walk-1-a"
                x1={347.146}
                y1={576.282}
                x2={444.086}
                y2={585.391}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#001489" />
                <stop offset={1} stopColor="#0147A7" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-walk-1-b"
                x1={472.352}
                y1={724.082}
                x2={193.612}
                y2={729.957}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-walk-1-c"
                x1={290.291}
                y1={708.618}
                x2={396.25}
                y2={746.19}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-walk-1-d"
                x1={471.62}
                y1={689.398}
                x2={192.88}
                y2={695.273}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-7-walk-1-e"
                x1={290.161}
                y1={669.519}
                x2={409.991}
                y2={712.01}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#AA0061" />
                <stop offset={1} stopColor="#D7003C" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
