import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-2" transform="translate(316, 30)">
        <path
            d="M59.2 98.702c1.6-1.3 3.5-2.4 4.3-4.3 1.1-2.7-.7-5.6-2.1-8.1-4.9-9-3.9-21.8 4.3-27.9 5.2-3.9 12.2-4.5 17.5-8.1 4.3-2.9 7.2-7.5 11.4-10.5 5-3.5 11.499-4.3 17.599-3.5 6.1.8 11.801 3.1 17.601 5.3-2.4-2.5-1.2-7 1.6-9s6.5-2.2 10-1.9c9.7.9 18.9 5 26.5 11 7.6 6 13.699 13.9 18.299 22.4 3.2 5.9 5.7 13.7.3 18.6-1.2 1.1-2.7 1.9-4.3 2.3-4.8.3-3.8 8.6-6.7 13-2.2 3.4-8.5 3.6-9.8 1.9-.3-.4-.4-.8-.5-1.3-.1-1.8.301-3.6.601-5.6.4-2.3.899-4.6.699-7-.1-1.5-.5-3.1-1-4.7l-.3-.1v-.1c-.4-.2-.7-.3-1-.5-3.1-1.5-3.899-2-7.799-1.3-4.4.8-7.501 4.8-11.801 6-4.1 1.1-8.4-.6-12.5-1.9-5.7-1.9-12.699-3.1-16.199 4.4-1 2.2-1.401 4.6-1.301 7 .2 5.2.3 12.8-3.9 16.6-.6.5-1.399.1-1.399-.7-.2-4-2.601-7.2-5.701-10.1-2.4-2.2-6.6-3-9.3-1.3-2.4 1.5-3.8 4.4-4.5 7.2-.6 2.4-.8 5.6-.1 8 .7 2 2 4.7 3.5 6.1 1.6 1.4 7 5.7 5.4 9.5-.4 1-1.4 1.8-2.6 2.3-5.3 2.2-16.4.4-21.2-.5-7.5-1.4-14.7-5.5-18.5-12.1-3.7-7-2.9-16.3 2.9-21.1Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M59.2 98.702c1.6-1.3 3.499-2.4 4.299-4.3 1.1-2.7-.7-5.6-2.1-8.1-4.9-9-3.9-21.8 4.3-27.9 5.2-3.9 12.2-4.5 17.5-8.1 4.3-2.9 7.2-7.5 11.4-10.5 5-3.5 11.5-4.3 17.6-3.5 6.1.8 11.8 3.1 17.6 5.3-2.4-2.5-1.2-7 1.6-9s6.5-2.2 10-1.9c9.7.9 18.9 5 26.5 11 7.6 6 13.7 13.9 18.3 22.4"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110 111.501c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
