import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9888)"
            d="M494.776 222.548a109.595 109.595 0 00-20.026-15.329 31.047 31.047 0 012.394 13.894 66.035 66.035 0 01-1.451 10.763l2.169 4.754a1.01 1.01 0 00.562.523 1.02 1.02 0 00.768-.03c2.597-1.201 15.6-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9888)"
            d="M508.178 234.769c-3.193-2.88-6.494-5.859-9.593-8.924l-.121-.119a27.5 27.5 0 10-38.431 39.346c2.165 2.114 4.287 4.327 6.367 6.535l43.673-35.119c-.633-.572-1.262-1.148-1.895-1.719z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9888"
                x1="502.627"
                x2="410.097"
                y1="201.768"
                y2="281.864"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9888"
                x1="509.601"
                x2="417.071"
                y1="209.823"
                y2="289.92"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
