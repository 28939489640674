import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-right">
        <path
            fill="url(#man-34-25-tshirt-2-both-arms-option-1-right-a)"
            d="M364.42 273.592a89.573 89.573 0 0 1-7.73-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053c-.198-1.457-.377-2.921-.557-4.385-.391-3.194-.795-6.498-1.407-9.689a32.035 32.035 0 0 1 31.461-38.033 32.028 32.028 0 0 1 31.395 25.98l.065.352c.582 3.262 1.387 6.495 2.166 9.621l1.431 5.744c2.249-1.62-46.988 29.133-47.616 51.254Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m385.756 241.761 10.925-8.488c7.3-5.639 14.765-11.072 22.326-16.387 3.801-2.631 7.565-5.308 11.406-7.889 3.82-2.609 7.646-5.208 11.536-7.729 3.879-2.535 7.728-5.108 11.633-7.61 3.946-2.451 7.829-4.979 11.84-7.349"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-both-arms-option-1-right-a"
                x1={391.854}
                x2={344.36}
                y1={241.53}
                y2={194.687}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
