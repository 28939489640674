import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-2-walk-1">
        <path
            fill="#001489"
            d="M379.102 790.601s67.7-124.901 89.5-163.701c4.1-3.2 6.8-8.199 9.2-12.799 7.9-14.5 13.8-29.301 18.1-40.601 2.3-5.8 5.2-14.5 7.3-20.5 7.7-21.6 12.3-45.6 15.5-65.1 1.6-9.5 2.8-19.4 3.6-29 .5-5.1 11.1-71.4 11.1-71.4s-114.4 27.1-114.7 55.9c-1.2 10.9-1.9 22.5-2.4 33.4-2.2 40.2 1.2 81.101 2.7 121.301-21.6 21.6-43.1 52.699-51.7 80.899-4.2 15.6-20.6 79.001-23.9 94.201l35.7 17.4Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-1-a)"
            d="m354.002 730.201 45.9 22.099c7.2-13.2 15.6-28.7 24.2-44.4l-57.1-27.5c-2.5 9.1-7.9 29.901-13 49.801Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-1-b)"
            d="M370.703 387.8c-9.1 15.8-16 25.7-17.4 44-1.4 7.4-1.1 15.1.6 22.5.1.8.2 1.6.3 2.5.2 2.1 1 4 2.1 5.6.4 1.2.9 2.4 1.4 3.6 3.7 10.8 7.9 21.9 12.2 32.3 13.7 34.4 31.4 67.401 48.4 100.401 5.1 9.9 6.4 21.2 4 32-7.3 32.9-8.3 68.099-4 100.299 3.9 29.201 6.5 59.001 12.3 87.601h51.2c-2-50.5-3.7-106.201-.1-156.201-.1-6.4 4.1-45.6 4.3-60.9.3-22.7-3-60.4-7.6-90.7-.7-3.4-1.4-6.7-2.1-10-2.8-13.1.4-26.699 8.1-37.599 9.1-12.7 14.3-30.301 14.3-30.301s24.6-11.699 33.3-45.199h-161.3v.099Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-1-c)"
            d="M481.001 803.5c-.2-5.5-.399-11-.599-16.6h-55.3c.8 5.5 1.6 11.1 2.5 16.6h53.399Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-1-d)"
            d="M479.402 722.101h-62.3c.3 3 .6 5.899 1 8.799 1.8 13.8 3.4 27.7 5.1 41.6h56.799c-.4-16.7-.699-33.599-.599-50.399Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-1-e)"
            d="m346.303 760.701 38.8 18.699c2.2-4 4.899-8.9 7.899-14.6l-42.599-20.5-4.1 16.401Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-walk-1-a"
                x1={354.009}
                x2={424.035}
                y1={716.328}
                y2={716.328}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-1-b"
                x1={391.591}
                x2={592.843}
                y1={622.832}
                y2={508.327}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.672} stopColor="#00359C" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-1-c"
                x1={425.141}
                x2={481.039}
                y1={795.179}
                y2={795.179}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-1-d"
                x1={417.115}
                x2={480.053}
                y1={747.363}
                y2={747.363}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-1-e"
                x1={346.317}
                x2={393.037}
                y1={761.869}
                y2={761.869}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
