import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': '#FFD100',
    'Option #2': '#7CCC6C',
    'Option #3': '#EF0025',
    'Option #4': '#001489',
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    return (
        <svg
            className="pic-svg"
            width={53} height={63} fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            <g id="object-food-items-1">
                <path stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M52.023 50.828c0 6.213-5.037 11.25-11.25 11.25h-27.9c-6.213 0-11.25-5.037-11.25-11.25V1.445h50.4v41.733"
                />
                <path
                    fill={STYLES[variation as keyof typeof STYLES]}
                    d="M46.772 31.766v19.066c0 3.311-2.689 6-6 6h-27.9c-3.31 0-6-2.689-6-6V31.766h39.9Z"
                />
            </g>
        </svg>
    );
}
