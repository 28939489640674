import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-7">
        <path
            d="M438.311 355.734c-1.35-.242-2.7-.485-4.053-.718a568.962 568.962 0 0 1-7.86-1.553c-1.436-.292-2.872-.582-4.31-.868-4.152-.806-8.264-1.711-12.121-2.569-2.71-.579-5.452-1.222-8.104-1.844-1.312-.308-2.624-.614-3.938-.917-8.834-2.106-16.647-4.159-23.894-6.279l-11.807-3.493-11.819-3.431a23.417 23.417 0 0 1-16.932-23.578 23.738 23.738 0 0 1-.189-1.939 252.991 252.991 0 0 1 .304-26.184c.48-8.248 1.243-16.657 2.33-25.712a202.353 202.353 0 0 1 4.448-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341a32.132 32.132 0 0 1 31.284-25.418c2.224.002 4.443.234 6.62.693a31.778 31.778 0 0 1 20.212 13.831 31.79 31.79 0 0 1 4.513 24.072l-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.452 2.531-.903 5.063-1.371 7.591a202.41 202.41 0 0 1-6.256 24.938c-2.696 8.711-5.427 16.7-8.345 24.419a433.357 433.357 0 0 1-1.186 3.07c7.007 2.397 14.421 5.159 22.648 8.434 1.247.508 2.491 1.01 3.731 1.507 2.536 1.02 5.158 2.074 7.724 3.167 3.638 1.513 7.522 3.138 11.388 4.869 1.323.587 2.65 1.17 3.98 1.75 2.453 1.075 4.907 2.15 7.35 3.261 1.246.591 2.481 1.165 3.717 1.738-6.047 8.502-10.818 24.008-10.62 33.657Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-7a)"
        />
        <path
            d="m408.923 219.662-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.452 2.531-.903 5.063-1.371 7.591a202.41 202.41 0 0 1-6.256 24.938c-2.696 8.711-5.427 16.7-8.345 24.419a433.357 433.357 0 0 1-1.186 3.07c7.007 2.397 14.421 5.159 22.648 8.434 1.247.508 2.491 1.01 3.731 1.507 2.536 1.02 5.158 2.074 7.724 3.167 3.638 1.513 7.522 3.138 11.388 4.869 1.323.587 2.65 1.17 3.98 1.75 2.453 1.075 4.907 2.15 7.35 3.261 1.246.591 2.482 1.165 3.717 1.738-6.047 8.502-10.818 24.008-10.62 33.657-1.35-.242-2.7-.485-4.053-.718a568.962 568.962 0 0 1-7.86-1.553c-1.436-.292-2.872-.582-4.31-.868-4.152-.806-8.264-1.711-12.121-2.569-2.71-.579-5.452-1.222-8.104-1.844-1.312-.308-2.624-.614-3.938-.917-8.834-2.106-16.647-4.159-23.894-6.279l-11.807-3.493-11.819-3.431a23.417 23.417 0 0 1-16.932-23.578 23.738 23.738 0 0 1-.189-1.939 252.991 252.991 0 0 1 .304-26.184c.48-8.248 1.243-16.657 2.33-25.712a202.353 202.353 0 0 1 4.448-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-7a"
                x1={417.121}
                y1={363.668}
                x2={320.832}
                y2={147.668}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
