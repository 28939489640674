import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9791)"
            d="M384.523 279.433c-4.029-2.98-8.197-6.064-12.432-8.946a26.506 26.506 0 01-11.037-27.299 26.496 26.496 0 0120.997-20.644 26.5 26.5 0 0119.859 4.126l.115.078c4.23 2.912 8.631 5.696 12.889 8.387l.361.227c1.487.941 2.972 1.884 4.455 2.83l-34.197 41.988-1.01-.747z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9791"
                x1="363.168"
                x2="410.575"
                y1="242.389"
                y2="258.746"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
