import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-down">
        <path
            fill="#001489"
            d="M500.165 186.369c-9.226-7.123-19.957-12.656-32.552-17.17l19.121 31.543s17.866-10.949 13.431-14.373Z"
        />
        <path
            fill="#001489"
            d="M481.966 295.431c-3.82-7.255-7.477-14.759-11.18-22.938a205.028 205.028 0 0 1-9.381-24.121 911.084 911.084 0 0 1-2.324-7.402c-1.753-5.626-3.567-11.442-5.548-17.086a31.994 31.994 0 0 1 8.802-34.501 31.994 31.994 0 0 1 35.254-5.004 31.994 31.994 0 0 1 16.33 18.303l.05.141c1.954 5.652 4.145 11.331 6.265 16.823.933 2.419 1.862 4.84 2.785 7.264a205.004 205.004 0 0 1 7.639 24.733c2.23 8.902 4.057 17.204 5.583 25.374l.376 2.073-54.263 17.08-.388-.739Z"
        />
    </g>
);

export default SvgComponent;
