import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m449.352 831.469 5.422 28.797 6.08.144s.112-2.428.364-6.78c.175-3.016 1.326-7.544 4.696-7.088"
                fill="#888B8D"
            />
            <path
                d="m454.775 860.609 5.65.144"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M463.468 837.762c.868-8.458 4.209-17.097-2.317-26.97l31.458 6.444-1.24-3.71-34.603-7.315-6.98 20.331a13.897 13.897 0 0 0 7.286 17.142l5.553 2.588a18.823 18.823 0 0 1 3.932 2.385c-1.089-2.895-3.525-6.639-3.089-10.895ZM509.34 857.201a15.958 15.958 0 0 1-1.762-1.273l-.303-.248-.39.023a32.42 32.42 0 0 0-3.135.332 21.455 21.455 0 0 0-11.675 5.395 14.648 14.648 0 0 0-4.589 10.319l.002.636.577.267a24.19 24.19 0 0 0 3.33 1.282l5.265 1.56c2.496.738 3.773.434 6.445.658l.967.082.11-.965a14.637 14.637 0 0 1 5.375-9.771 14.47 14.47 0 0 1 5.635-2.669l2.363-.557-8.215-5.071Z"
                fill="#888B8D"
            />
            <path
                d="M521.805 874.796c-1.13.951-4.783.973-6.335.972-2.832-.001-6.167.002-9 0-3.732-.002-11.397-.41-15.076-1.832-5.538-2.142-10.567-5.385-13.72-10.417l-5.547-8.849a22.315 22.315 0 0 0-9.499-8.393l-5.553-2.588a15.916 15.916 0 0 1-7.276-8.041"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m397.789 850.789 6.215 25.012 7.112.302-.651-10.954"
                fill="#63666A"
            />
            <path
                d="M404.004 875.797h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M402.565 825.969h30.653l-1.244-3.235h-34.273l-2.771 12.607a20.64 20.64 0 0 0 6.369 19.307 28.121 28.121 0 0 1 2.825 3.11l1.676 2.114.106-2.696c.093-2.337.362-4.734.621-7.051.511-4.545 1.039-9.245.297-13.886a27.748 27.748 0 0 0-4.259-10.27Z"
                fill="url(#female-34-25-shoes-8-stand-1-a)"
            />
            <path
                d="m446.212 852.568-.339-.021a66.846 66.846 0 0 0-13.664.537 34.585 34.585 0 0 0-9.828 2.673 18.262 18.262 0 0 0-7.917 6.683 18.01 18.01 0 0 0-2.258 5.822l-.095.436 1.525 2.108a15.037 15.037 0 0 0 12.215 6.062h1.074l-.008-1.008a12.32 12.32 0 0 1 4.338-9.397 16.28 16.28 0 0 1 6.888-3.136 51.393 51.393 0 0 1 7.89-.916l10.692-.684-10.513-9.159Z"
                fill="url(#female-34-25-shoes-8-stand-1-b)"
            />
            <path
                d="M457.573 874.31a9.33 9.33 0 0 1-6.503 2.561h-25.219a15.041 15.041 0 0 1-12.215-6.063s-15.174-19.201-16.206-20.933"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-8-stand-1-a"
                    x1={394.518}
                    y1={841.303}
                    x2={433.218}
                    y2={841.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-8-stand-1-b"
                    x1={412.111}
                    y1={864.641}
                    x2={456.725}
                    y2={864.641}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
            </defs>
        </g>
    );
}
