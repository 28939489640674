import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#clip0_11313_6435)">
            <path
                fill="url(#paint0_linear_11313_6435)"
                d="M524.499 279.902c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 00-17.209-9.819c4.064 7.051 7.777 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.748 62.748 0 00-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.875 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 01-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M503.396 254.981c4.626 6.606 10.959 14.469 20.339 25.569 7.688 9.097 10.644 17.402 8.786 24.686-2.388 9.368-12.231 15.141-20.067 18.335l-.894.364.333.906a525.683 525.683 0 002.691 7.124c7.828 20.446 18.534 48.403 9.414 97.344-.478.003-1.245.005-2.477.005-13.212 0-63.14-.234-107.19-.44-31.908-.149-59.698-.279-67.585-.293 2.533-18.837 7.936-40.755 27.525-61.609 8.654-9.212 11.178-22.949 6.59-35.848l-18.825-52.893a61.743 61.743 0 0126.094-73.245c.011-.006.096-.061.245-.161a176.725 176.725 0 0118.233-10.731M464.109 202.109a143.834 143.834 0 0113.155 7.829"
            ></path>
            <g filter="url(#filter0_f_11313_6435)">
                <path
                    fill="url(#paint1_radial_11313_6435)"
                    d="M410.73 290.876l5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0012.049-48.824l-6.917-10.636"
                ></path>
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M407.535 194.484c11.037 8.028 21.706 14.536 31.631 17.961l24.553 18.979 8-19.516 7.31 6.637"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M439.165 212.447l-9.445 13.073-20.923-22.629M498.824 329.391c.701 5.497 2.456 10.966 3.87 16.409a138.368 138.368 0 01-3.191 80.044M468.242 235.719c10.049 9.027 20.174 21.446 27.52 31.822 7.347 10.377 11.803 18.941 9.682 30.569-1.446 7.92-5.864 15.449-6.755 23.414"
            ></path>
            <path
                fill="url(#paint2_linear_11313_6435)"
                d="M387.988 203.168l19.02-10.723 34.588 83.47a96.206 96.206 0 017.276 36.703v139.753a20.003 20.003 0 01-20 20h-84.915v-37.007c0-40.412 29.574-61.976 29.574-88.19 0-39.309-18.173-58.478-18.173-80.959 0-45.552 32.63-63.047 32.63-63.047z"
            ></path>
            <path
                fill="url(#paint3_linear_11313_6435)"
                d="M407.896 194.258c-16.337 17.315 38.232 95.539 38.232 95.539a176.854 176.854 0 00-11.459-31.804c-7.61-15.955-26.773-63.735-26.773-63.735z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M379.68 408h43.806"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11313_6435"
                width="91.356"
                height="77.898"
                x="402.73"
                y="251.023"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11313_6435"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11313_6435"
                x1="345.022"
                x2="532.607"
                y1="332.812"
                y2="299.939"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11313_6435"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#E2E2E2" stopOpacity="0"></stop>
                <stop offset="0.587" stopColor="#E2E2E2" stopOpacity="0.33"></stop>
                <stop offset="0.763" stopColor="#E2E2E2" stopOpacity="0.688"></stop>
                <stop offset="0.888" stopColor="#E2E2E2" stopOpacity="0.913"></stop>
                <stop offset="0.949" stopColor="#E2E2E2"></stop>
            </radialGradient>
            <linearGradient
                id="paint2_linear_11313_6435"
                x1="343.957"
                x2="448.873"
                y1="332.408"
                y2="332.408"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_11313_6435"
                x1="404.844"
                x2="446.128"
                y1="242.027"
                y2="242.027"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <clipPath id="clip0_11313_6435">
                <path
                    fill="#fff"
                    d="M0 0H900V900H0z"
                    transform="translate(.766 .164)"
                ></path>
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
