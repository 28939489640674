import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={3}>
        <path
            d="M490.928 222.413c7.415 3.643 15.011 6.916 22.528 10.348a199.968 199.968 0 0 1 21.892 11.653c7.104 4.278 14.117 8.744 21.004 13.471a250.056 250.056 0 0 1 20.149 15.217 19.095 19.095 0 0 1 4.777 22.92 19.102 19.102 0 0 1-21.011 10.328 250.228 250.228 0 0 1-24.392-6.533c-7.961-2.524-15.795-5.308-23.538-8.279a199.83 199.83 0 0 1-22.651-10.098c-7.33-3.817-14.582-7.794-22.014-11.401a26.506 26.506 0 0 1-14.971-25.426 26.504 26.504 0 0 1 17.825-23.512 26.5 26.5 0 0 1 20.289 1.258l.113.054Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M549.787 292.22c-.932-4.689-2.191-9.312-3.309-13.963a85.105 85.105 0 0 1-2.137-14.2c-.141-2.41-.383-4.8-.418-7.231l-.115-3.635c-.034-1.212 0-2.438-.004-3.657a105.25 105.25 0 0 1 .987-14.831 12.763 12.763 0 0 1 17.736-9.813 12.764 12.764 0 0 1 5.787 5.057 105.223 105.223 0 0 1 6.669 13.284c.47 1.124.977 2.241 1.417 3.371l1.307 3.394c.913 2.254 1.617 4.55 2.422 6.826a85.02 85.02 0 0 1 3.548 13.914c.777 4.72 1.412 9.469 2.375 14.152a18.505 18.505 0 0 1-2.655 13.925 18.5 18.5 0 0 1-33.586-6.473l-.024-.12Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M560.268 306.35a249.85 249.85 0 0 1-24.391-6.533c-7.961-2.523-15.796-5.307-23.539-8.279a199.73 199.73 0 0 1-22.65-10.097c-7.33-3.818-14.582-7.795-22.014-11.402M490.816 222.359l.113.055c7.415 3.643 15.01 6.916 22.528 10.349a199.79 199.79 0 0 1 21.892 11.652c1.346.811 2.689 1.628 4.028 2.452M568.314 229.945a105.09 105.09 0 0 1 6.669 13.284c.47 1.124.978 2.241 1.417 3.371l1.307 3.394c.913 2.254 1.617 4.55 2.423 6.826a85.122 85.122 0 0 1 3.548 13.914c.777 4.72 1.412 9.47 2.375 14.152a18.506 18.506 0 0 1-23.469 21.44M546.478 278.257a85.105 85.105 0 0 1-2.137-14.2c-.141-2.41-.383-4.8-.418-7.231l-.115-3.635c-.034-1.212 0-2.438-.004-3.657a105.25 105.25 0 0 1 .987-14.831"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M537.143 227.042a2.598 2.598 0 0 1 .115-1.608 2.591 2.591 0 0 1 4.099-.968 40.851 40.851 0 0 0 3.028 2.429l.081-1.788c-.128-4.262 2.495-8.053 4.956-11.535 2.462-3.481 4.982-7.388 4.607-11.635a10.465 10.465 0 0 1 .098-3.604l.079-.597a8.959 8.959 0 0 1-2.362-5.85 3.04 3.04 0 0 1 2.376-3.059l.042-.009a2.958 2.958 0 0 1 2.982 1.041 13.253 13.253 0 0 0 4.763 3.796 4.279 4.279 0 0 1 2.334 2.861c.858 3.565 1.863 7.157 2.645 10.708.462 2.098 2.724 3.572 4.325 5.005 1.601 1.432 3.231 3.088 3.565 5.21v.217c.437.224.862.475 1.27.75a4.415 4.415 0 0 1 1.637 5.256 73.373 73.373 0 0 1-4.636 10.116 29.97 29.97 0 0 1-9.478 10.494 18.115 18.115 0 0 1-13.629 2.737 17.666 17.666 0 0 1-9.426-6.077 27.003 27.003 0 0 1-5.03-10.152 3.327 3.327 0 0 1 1.003-3.606l.556-.132Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M555.712 199.054a8.992 8.992 0 0 1-3.867-7.168 3.04 3.04 0 0 1 2.376-3.059l.042-.009a2.958 2.958 0 0 1 2.982 1.041 13.253 13.253 0 0 0 4.763 3.796c.578.272 1.088.67 1.491 1.166.404.495.693 1.074.843 1.695.857 3.565 1.863 7.157 2.645 10.708.462 2.098 2.724 3.572 4.325 5.005 1.601 1.432 3.23 3.088 3.564 5.21M554.03 201.938c.376 4.247-2.145 8.153-4.606 11.634-2.462 3.482-5.085 7.273-4.957 11.535M569.585 230.653c-2.502-1.941-7.759-4.107-10.566-5.186a2.672 2.672 0 0 0-3.068.85 2.676 2.676 0 0 0-.574 1.752 2.68 2.68 0 0 0 .69 1.709 46.17 46.17 0 0 0 7.804 6.979M552.585 230.701c-4.211-.545-8.796-4.096-11.227-6.238a2.582 2.582 0 0 0-3.514.099 2.588 2.588 0 0 0-.406 3.185c2.213 3.683 8.007 11.825 16.589 13.078"
            stroke="#231F20"
            strokeWidth={2.195}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M535.584 230.78a26.996 26.996 0 0 0 5.03 10.152 17.668 17.668 0 0 0 9.426 6.077 18.12 18.12 0 0 0 13.629-2.736 29.982 29.982 0 0 0 9.478-10.495 73.297 73.297 0 0 0 4.636-10.115 4.418 4.418 0 0 0-1.637-5.257c-2.566-1.738-7.162-3.228-13.365-3.057a2.512 2.512 0 0 0-2.185 1.414l-.016.032a2.46 2.46 0 0 0 .941 3.197c3.049 1.81 5.937 3.681 9.068 5.461"
            stroke="#231F20"
            strokeWidth={2.195}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.068 224.033c-8.491-8.307-18.695-15.184-30.908-21.252l15.605 34.205s19.386-8.958 15.303-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.781a132.184 132.184 0 0 1 30.908 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
