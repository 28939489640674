const shorts12Options = [
    {
        name: 'Shorts #1',
        fillColor: '#FFB91D',
        stand1: 'man-34-25-shorts-1-2-stand-1-a',
        stand2: 'man-34-25-shorts-1-2-stand-1-a',
        walk1: 'man-34-25-shorts-1-2-walk-1-a',
        walk2: 'man-34-25-shorts-1-2-walk-2-a',
    },
    {
        name: 'Shorts #3',
        fillColor: '#001489',
        stand1: 'man-34-25-shorts-1-2-stand-1-b',
        stand2: 'man-34-25-shorts-1-2-stand-1-b',
        walk1: 'man-34-25-shorts-1-2-walk-1-b',
        walk2: 'man-34-25-shorts-1-2-walk-2-b',
    },
];

export default shorts12Options;
