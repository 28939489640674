import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-2-right">
        <path
            d="m608.397 149.596-1.32-1.231c.653-2.533-1.072-5.058-2.98-6.848a25.027 25.027 0 0 0-19.627-6.515c-5.416.61-10.466 2.988-15.254 5.591a150.58 150.58 0 0 0-20.304 13.259c-11.209 7.27-4.721 21.083 12.432 23.196 11.611 1.43 24.087-11.207 28.703-18.228l3.161.238a17.86 17.86 0 0 1 6.358 1.584 6.074 6.074 0 0 1 3.465 5.21c-.147 1.16-.425 2.3-.827 3.398a4.236 4.236 0 0 0 0 2.31 1.54 1.54 0 0 0 1.604 1.051 5.347 5.347 0 0 0 3.417-1.713 7.356 7.356 0 0 0 1.346-2.099l.873.198a3.09 3.09 0 0 0 2.887-.982 6.236 6.236 0 0 0 1.391-2.837c1.388-5.584-1.144-11.629-5.325-15.582Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M561.012 176.549c12.83 2.433 27.442-13.909 30.292-19.908M548.91 153.853a150.616 150.616 0 0 1 20.304-13.26c4.788-2.603 9.838-4.981 15.254-5.591a25.011 25.011 0 0 1 19.626 6.516c1.909 1.789 3.633 4.314 2.981 6.847"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M593.016 142.234c5.611 1.272 11.202 3.409 15.383 7.362 4.181 3.953 6.712 9.997 5.324 15.581a6.231 6.231 0 0 1-1.391 2.837 3.088 3.088 0 0 1-2.886.982"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M596.057 149.344a14.679 14.679 0 0 1 10.416 5.565 14.681 14.681 0 0 1 2.81 11.47 8.87 8.87 0 0 1-2.06 4.517 5.34 5.34 0 0 1-3.416 1.713 1.536 1.536 0 0 1-1.604-1.051 4.236 4.236 0 0 1 0-2.31c.402-1.098.68-2.238.827-3.398a6.068 6.068 0 0 0-3.465-5.21 17.86 17.86 0 0 0-6.358-1.584"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M380.707 181.08c8.467.977 16.983 1.52 25.478 2.251a200.245 200.245 0 0 1 25.304 3.784c8.382 1.724 16.74 3.669 25.063 5.92a248.653 248.653 0 0 1 24.831 7.972 23.274 23.274 0 0 1 13.238 30.139 23.33 23.33 0 0 1-18.289 14.576 248.964 248.964 0 0 1-25.984 2.231c-8.615.338-17.196.37-25.752.18a200.172 200.172 0 0 1-25.511-1.956c-8.443-1.183-16.866-2.553-25.337-3.489a30.995 30.995 0 0 1-26.365-22.167 30.993 30.993 0 0 1 10.389-32.841 30.998 30.998 0 0 1 22.785-6.617l.15.017Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m464.321 203.901 9.565-6.331 9.528-6.384c6.367-4.236 12.884-8.255 19.49-12.149 3.322-1.92 6.61-3.888 9.968-5.756 3.339-1.895 6.684-3.782 10.087-5.586 3.393-1.818 6.758-3.676 10.174-5.461 3.455-1.73 6.852-3.541 10.365-5.187a15.734 15.734 0 0 1 20.921 7.577 15.768 15.768 0 0 1-3.149 17.823c-2.75 2.738-5.617 5.308-8.425 7.96-2.848 2.599-5.747 5.123-8.617 7.687-2.861 2.579-5.78 5.075-8.705 7.561-2.905 2.516-5.881 4.93-8.822 7.393-5.922 4.87-11.932 9.616-18.093 14.146l-9.262 6.763-9.227 6.816a22.506 22.506 0 1 1-26.747-36.207c.291-.215.651-.465.949-.665Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M431.088 243.675c6.061 0 10.974-3.922 10.974-8.759 0-4.838-4.913-8.76-10.974-8.76s-10.975 3.922-10.975 8.76c0 4.837 4.914 8.759 10.975 8.759Z"
            fill="#fff"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M431.088 236.774c1.287 0 2.33-.832 2.33-1.86 0-1.027-1.043-1.859-2.33-1.859-1.287 0-2.33.832-2.33 1.859 0 1.028 1.043 1.86 2.33 1.86Z"
            fill="#231F20"
        />
        <path
            d="m380.559 181.066.15.017c8.467.978 16.982 1.521 25.477 2.252 8.507.72 16.959 1.984 25.304 3.783 8.383 1.725 16.74 3.67 25.063 5.92 4.592 1.257 9.176 2.58 13.743 4.05M490.122 240.773c-3.2 2.526-9.762 4.346-13.788 4.95a248.652 248.652 0 0 1-25.983 2.232c-8.616.337-17.197.369-25.753.18a200.172 200.172 0 0 1-25.51-1.957c-8.444-1.183-16.867-2.553-25.338-3.489M473.887 197.569l9.528-6.384c6.366-4.236 12.884-8.255 19.49-12.149 3.322-1.92 6.609-3.888 9.968-5.755 3.339-1.896 6.684-3.782 10.086-5.586 3.394-1.818 6.758-3.677 10.175-5.461 3.454-1.73 6.851-3.542 10.365-5.187M561.272 182.449c-2.75 2.737-5.616 5.308-8.425 7.96-2.848 2.598-5.746 5.122-8.617 7.687-2.861 2.579-5.779 5.075-8.705 7.561-2.905 2.515-5.881 4.929-8.823 7.392-5.922 4.871-11.931 9.617-18.092 14.146l-9.263 6.764-9.226 6.815"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M404.555 242.027a3.206 3.206 0 0 1 4.683-1.106l60.744 37.87a3.951 3.951 0 0 1 1.57 5.004l-15.969 31.34a3.214 3.214 0 0 1-4.683 1.106l-60.744-37.87a3.958 3.958 0 0 1-1.57-5.004l15.969-31.34Z"
            fill="url(#man-bold-34-25-arms-option-2-right-a)"
        />
        <path
            d="m474.005 275.929-60.744-37.87a3.35 3.35 0 0 0-3.733-.005l-4.024 2.861a3.344 3.344 0 0 1 3.732.005l60.744 37.87a3.953 3.953 0 0 1 1.57 5.004l-15.968 31.34c-.222.441-.548.82-.949 1.107l4.025-2.861c.401-.286.727-.666.948-1.107l15.969-31.34a3.95 3.95 0 0 0-1.57-5.004Z"
            fill="url(#man-bold-34-25-arms-option-2-right-b)"
        />
        <path
            d="m450.9 316.241-60.744-37.87a3.958 3.958 0 0 1-1.57-5.004l15.969-31.34a3.219 3.219 0 0 1 2.092-1.609 3.2 3.2 0 0 1 2.591.503l60.744 37.87a3.95 3.95 0 0 1 1.57 5.004"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m454.637 316.241 4.025-2.861c.401-.286.726-.666.948-1.107l15.969-31.34a3.955 3.955 0 0 0-1.57-5.004l-60.744-37.87a3.351 3.351 0 0 0-3.733-.005l-4.024 2.861a3.344 3.344 0 0 1 3.732.005l60.744 37.87a3.95 3.95 0 0 1 1.57 5.004"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M394.869 276.18a2.115 2.115 0 1 0 0-4.23 2.115 2.115 0 0 0 0 4.23Z"
            fill="#231F20"
        />
        <path
            d="M398.521 269.461a2.115 2.115 0 1 0 .001-4.23 2.115 2.115 0 0 0-.001 4.23Z"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            opacity={0.52}
            d="M421.551 277.232c2.196-1.268 2.775-4.378 1.293-6.945s-4.464-3.62-6.661-2.352c-2.196 1.268-2.774 4.377-1.292 6.944s4.464 3.621 6.66 2.353Z"
            fill="#888B8D"
        />
        <path
            d="M401.092 280.078a2.115 2.115 0 1 0 0-4.23 2.115 2.115 0 0 0 0 4.23Z"
            fill="#231F20"
        />
        <path
            d="m567.163 300.286-8.639.26c-2.879.102-5.756.246-8.636.324-2.884.038-5.76.184-8.649.128a136.34 136.34 0 0 1-4.334-.076c-1.445-.03-2.891-.083-4.339-.159-5.787-.25-11.579-.566-17.383-1.062a268.297 268.297 0 0 1-17.44-1.963 15.85 15.85 0 0 1-13.371-17.991 15.873 15.873 0 0 1 11.38-12.946 255.816 255.816 0 0 1 17.045-4.182c5.692-1.235 11.396-2.29 17.104-3.28 1.426-.259 2.854-.497 4.282-.713a133.49 133.49 0 0 1 4.289-.632c2.858-.425 5.729-.649 8.593-.981 2.867-.292 5.739-.518 8.607-.785l8.603-.849a22.511 22.511 0 0 1 19.618 36.682 22.506 22.506 0 0 1-16.73 8.225Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M576.623 256.758c5.523 5.014 11.575 11.805 12.267 18.812a22.5 22.5 0 0 1-4.998 16.49 22.524 22.524 0 0 1-15.194 8.127c-.494.048-1.046.082-1.535.098l-8.64.26c-2.879.103-5.756.246-8.636.324-2.884.038-5.76.184-8.649.129a133.44 133.44 0 0 1-4.334-.077 131.316 131.316 0 0 1-4.339-.159c-5.787-.25-11.579-.566-17.382-1.061a268.254 268.254 0 0 1-17.441-1.964M495.754 266.802a255.825 255.825 0 0 1 17.045-4.183c5.692-1.234 11.396-2.29 17.103-3.28 1.427-.259 2.854-.497 4.283-.713 1.428-.229 2.857-.45 4.288-.631 2.858-.426 5.73-.649 8.594-.981 2.867-.292 5.738-.518 8.607-.786l8.602-.849"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M437.017 312.365a35.88 35.88 0 0 1 .9-1.903l-.792-.904a5.182 5.182 0 0 1-1.282-3.61 37.573 37.573 0 0 1 2.099-11.22l-1.476-.916c-3.472-1.683-6.383-4.96-6.682-8.807-.326-4.197 2.399-8.009 5.387-10.973 5.83-5.783 12.716-13.296 20.889-12.492a259.294 259.294 0 0 1 31.46 5.061c19.508 4.143 23.697 19.41 11.302 30.223a19.018 19.018 0 0 1-20.938 2.73c-5.642 5.242-13.332 13.116-16.768 20.669a3.728 3.728 0 0 1-1.903 1.913 3.728 3.728 0 0 1-2.695.131l-.102-.034a3.744 3.744 0 0 1-2.342-4.745c3.331-9.625 11.065-19.72 17.334-27.105-9.056 7.749-20.166 17.603-29.494 25.63a3.106 3.106 0 0 1-4.081-.028l-.036-.032a3.117 3.117 0 0 1-.78-3.588Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M491.12 267.543a323.215 323.215 0 0 0-35.06-6.003c-8.172-.804-15.059 6.709-20.889 12.492-2.988 2.964-5.713 6.776-5.387 10.973.299 3.846 3.21 7.123 6.682 8.806"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M456.707 275.094c-5.901 3.966-11.878 8.167-15.851 14.064a29.044 29.044 0 0 0-4.895 17.227M461.443 284.355a126.512 126.512 0 0 0-13.737 12.229 64.151 64.151 0 0 0-10.689 15.781 3.118 3.118 0 0 0 .778 3.59l.036.032a3.105 3.105 0 0 0 4.081.029c9.328-8.027 20.438-17.882 29.495-25.63-6.271 7.386-14.007 17.485-17.337 27.112a3.716 3.716 0 0 0-.189 1.446 3.735 3.735 0 0 0 2.533 3.291l.068.023a3.774 3.774 0 0 0 4.645-2.06c3.442-7.545 11.12-15.406 16.755-20.641a19.023 19.023 0 0 0 20.938-2.73M447.057 278.992l-5.169 3.213a3.602 3.602 0 0 0-1.756 1.795 2.4 2.4 0 0 0 .294 1.842 7.116 7.116 0 0 0 1.227 1.465"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-arms-option-2-right-a"
                x1={388.22}
                y1={278.58}
                x2={471.919}
                y2={278.58}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D9D6"/>
                <stop offset={1} stopColor="#F3F3F3"/>
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-arms-option-2-right- b"
                x1={405.504}
                y1={276.865}
                x2={475.942}
                y2={276.865}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D"/>
                <stop offset={1} stopColor="#ABADAD"/>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
