import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 478,
        height: 511,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M406.451 134.891h70.231v355.706l-8.082 18.85H332.383l-8.024-18.85v-85.931"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M297.983 116.163h119.558v-11.124h15.144v11.124h16.646l8.62 18.731H1.496V490.6l8.082 18.85"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m153.819 490.6-.014-336.975h304.146V490.6H343.09V153.625"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.578 509.45h136.157l8.084-18.85H20.227V153.625h114.847v305.901M324.36 404.668H176.746v18.731h185.076V27.501a7.832 7.832 0 1 0-15.664 0v25.768h-17.817V27.5c0-14.166 11.483-25.65 25.649-25.65s25.649 11.484 25.649 25.65v107.392"
                />
            </>
        ),
    },
    'Color #1': {
        width: 478,
        height: 509,
        content: (
            <>
                <path
                    fill="#C2C9CF"
                    d="M362.401 115.162V26.501a7.832 7.832 0 1 0-15.664 0v25.768H328.92V26.5c0-14.166 11.483-25.65 25.649-25.65s25.649 11.484 25.649 25.65v88.661h-17.817Z"
                />
                <path
                    fill="#818084"
                    d="M346.737 41.912H328.92V52.27h17.817V41.912ZM380.217 82.838H362.4v8.084h17.817v-8.084Z"
                />
                <path
                    fill="url(#object-office-sink-a)"
                    d="m458.27 133.892-8.62-18.731h-16.646v-11.124H417.86v11.124H298.302l-8.62 18.731H458.27Z"
                />
                <path
                    fill="url(#object-office-sink-b)"
                    d="M146.053 508.448H9.896l-8.082-18.85h152.31l-8.071 18.85Z"
                />
                <path
                    fill="url(#object-office-sink-c)"
                    d="M468.923 508.448H332.766l-8.082-18.85h152.309l-8.07 18.85Z"
                />
                <path fill="url(#object-office-sink-d)" d="M135.392 133.893H1.814v355.705h133.578V133.893Z" />
                <path fill="#C2C9CF" d="M458.27 152.623H324.678v336.974H458.27V152.623Z" />
                <path
                    fill="#ECF3F9"
                    d="M362.139 152.623H154.123v269.774h208.016V152.623Z"
                />
                <path
                    fill="#ECF3F9"
                    d="M154.124 133.893h-18.731v355.705h18.731V133.893ZM477 133.893h-18.73v355.705H477V133.893Z"
                />
                <path fill="#ECF3F9" d="M458.271 133.893H154.139v18.731h304.132v-18.731Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M324.678 489.598v-67.2M406.77 133.893H477v355.706"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.814 489.599V133.893h451.429M154.139 152.623h304.132M154.137 489.598l-.014-336.975M1.814 489.598h152.323M154.123 422.398H362.4M477.001 489.598H324.678M449.649 115.162H298.301"
                />
                <defs>
                    <linearGradient
                        id="object-office-sink-a"
                        x1={449.467}
                        x2={307.993}
                        y1={120.853}
                        y2={117.821}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-b"
                        x1={1.814}
                        x2={154.124}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-c"
                        x1={324.684}
                        x2={476.993}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-d"
                        x1={68.603}
                        x2={68.603}
                        y1={469.135}
                        y2={110.85}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 478,
        height: 509,
        content: (
            <>
                <path
                    fill="#C2C9CF"
                    d="M362.153 115.162V26.501a7.832 7.832 0 1 0-15.664 0v25.768h-17.817V26.5c0-14.166 11.483-25.65 25.649-25.65s25.649 11.484 25.649 25.65v88.661h-17.817Z"
                />
                <path
                    fill="#818084"
                    d="M346.489 41.912h-17.817V52.27h17.817V41.912ZM379.969 82.838h-17.817v8.084h17.817v-8.084Z"
                />
                <path
                    fill="url(#object-office-sink-a)"
                    d="m458.022 133.892-8.62-18.731h-16.646v-11.124h-15.144v11.124H298.054l-8.62 18.731h168.588Z"
                />
                <path
                    fill="url(#object-office-sink-b)"
                    d="M145.805 508.448H9.648l-8.082-18.85h152.309l-8.07 18.85Z"
                />
                <path
                    fill="url(#object-office-sink-c)"
                    d="M468.675 508.448H332.518l-8.082-18.85h152.309l-8.07 18.85Z"
                />
                <path fill="url(#object-office-sink-d)" d="M135.144 133.893H1.566v355.705h133.578V133.893Z" />
                <path fill="#E5B97F" d="M458.022 152.623H324.43v336.974h133.592V152.623Z" />
                <path
                    fill="#FFE4B8"
                    d="M361.891 152.623H153.875v269.774h208.016V152.623Z"
                />
                <path
                    fill="#FFE4B8"
                    d="M153.876 133.893h-18.731v355.705h18.731V133.893ZM476.753 133.893h-18.732v355.705h18.732V133.893Z"
                />
                <path fill="#FFE4B8" d="M458.021 133.893H153.889v18.731h304.132v-18.731Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M324.43 489.598v-67.2M406.521 133.893h70.232v355.706"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.566 489.599V133.893h451.428M153.889 152.623h304.132M153.889 489.598l-.014-336.975M1.566 489.598H153.89M153.875 422.398h208.277M476.753 489.598H324.43M449.401 115.162H298.053"
                />
                <defs>
                    <linearGradient
                        id="object-office-sink-a"
                        x1={449.219}
                        x2={307.745}
                        y1={120.853}
                        y2={117.821}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-b"
                        x1={1.566}
                        x2={153.875}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-c"
                        x1={324.436}
                        x2={476.745}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-d"
                        x1={68.355}
                        x2={68.355}
                        y1={469.135}
                        y2={110.85}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.035} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 478,
        height: 509,
        content: (
            <>
                <path
                    fill="#C2C9CF"
                    d="M361.901 115.162V26.501a7.832 7.832 0 1 0-15.664 0v25.768H328.42V26.5c0-14.166 11.483-25.65 25.649-25.65s25.649 11.484 25.649 25.65v88.661h-17.817Z"
                />
                <path
                    fill="#818084"
                    d="M346.239 41.912h-17.817V52.27h17.817V41.912ZM379.719 82.838h-17.817v8.084h17.817v-8.084Z"
                />
                <path
                    fill="url(#object-office-sink-a)"
                    d="m457.772 133.892-8.62-18.731h-16.646v-11.124h-15.144v11.124H297.804l-8.62 18.731h168.588Z"
                />
                <path
                    fill="url(#object-office-sink-b)"
                    d="M145.555 508.448H9.398l-8.082-18.85h152.309l-8.07 18.85Z"
                />
                <path
                    fill="url(#object-office-sink-c)"
                    d="M468.424 508.448H332.268l-8.082-18.85h152.309l-8.071 18.85Z"
                />
                <path fill="url(#object-office-sink-d)" d="M134.894 133.893H1.316v355.705h133.578V133.893Z" />
                <path fill="#EA9975" d="M457.772 152.623H324.18v336.974h133.592V152.623Z" />
                <path
                    fill="#FCCBBB"
                    d="M361.641 152.623H153.625v269.774h208.016V152.623Z"
                />
                <path
                    fill="#FCCBBB"
                    d="M153.626 133.893h-18.731v355.705h18.731V133.893ZM476.503 133.893h-18.732v355.705h18.732V133.893Z"
                />
                <path fill="#FCCBBB" d="M457.771 133.893H153.639v18.731h304.132v-18.731Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M324.18 489.598v-67.2M406.27 133.893h70.232v355.706"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.316 489.599V133.893h451.428M153.639 152.623h304.132M153.639 489.598l-.014-336.975M1.316 489.598H153.64M153.625 422.398h208.276M476.503 489.598H324.18M449.151 115.162H297.803"
                />
                <defs>
                    <linearGradient
                        id="object-office-sink-a"
                        x1={448.969}
                        x2={307.495}
                        y1={0.851}
                        y2={0.851}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-b"
                        x1={1.316}
                        x2={153.625}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-c"
                        x1={324.186}
                        x2={476.495}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-d"
                        x1={68.105}
                        x2={68.105}
                        y1={469.135}
                        y2={110.85}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.207} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 478,
        height: 509,
        content: (
            <>
                <path
                    fill="#C2C9CF"
                    d="M361.858 115.162V26.501a7.832 7.832 0 1 0-15.664 0v25.768h-17.817V26.5c0-14.166 11.483-25.65 25.649-25.65s25.649 11.484 25.649 25.65v88.661h-17.817Z"
                />
                <path
                    fill="#818084"
                    d="M346.194 41.912h-17.817V52.27h17.817V41.912ZM379.674 82.838h-17.817v8.084h17.817v-8.084Z"
                />
                <path
                    fill="url(#object-office-sink-a)"
                    d="m457.727 133.892-8.62-18.731h-16.646v-11.124h-15.144v11.124H297.759l-8.62 18.731h168.588Z"
                />
                <path
                    fill="url(#object-office-sink-b)"
                    d="M145.51 508.448H9.353l-8.082-18.85H153.58l-8.07 18.85Z"
                />
                <path
                    fill="url(#object-office-sink-c)"
                    d="M468.38 508.448H332.223l-8.082-18.85H476.45l-8.07 18.85Z"
                />
                <path fill="url(#object-office-sink-d)" d="M134.849 133.893H1.271v355.705H134.85V133.893Z" />
                <path
                    fill="#FFD100"
                    d="M457.727 152.623H324.135v336.974h133.592V152.623Z"
                />
                <path fill="#FCEB94" d="M361.596 152.623H153.58v269.774h208.016V152.623Z" />
                <path
                    fill="#FDF3BF"
                    d="M153.581 133.893H134.85v355.705h18.731V133.893ZM476.458 133.893h-18.731v355.705h18.731V133.893ZM457.726 133.893H153.594v18.731h304.132v-18.731Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M324.135 489.598v-67.2M406.227 133.893h70.231v355.706"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.271 489.599V133.893H452.7M153.594 152.623h304.132M153.594 489.598l-.014-336.975M1.271 489.598h152.323M153.58 422.398h208.277M476.458 489.598H324.135M449.106 115.162H297.758"
                />
                <defs>
                    <linearGradient
                        id="object-office-sink-a"
                        x1={448.924}
                        x2={307.45}
                        y1={120.853}
                        y2={117.821}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-b"
                        x1={1.271}
                        x2={153.58}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-c"
                        x1={324.141}
                        x2={476.45}
                        y1={499.023}
                        y2={499.023}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-sink-d"
                        x1={68.061}
                        x2={68.061}
                        y1={469.135}
                        y2={110.85}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.698} stopColor="#FFEA8B" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-sink"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
