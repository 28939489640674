import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={248}
            height={164}
            fill="none"
            id="object-food-lentils-red"
            {...attributes}
        >
            <circle cx={32.928} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={55.773} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={78.619} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={101.465} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={124.311} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={147.156} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={170.002} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={192.848} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={215.693} cy={69.916} r={8.75} fill="#E4002B" />
            <circle cx={78.619} cy={28.916} r={8.75} fill="#E4002B" />
            <circle cx={101.465} cy={28.916} r={8.75} fill="#E4002B" />
            <circle cx={124.311} cy={28.916} r={8.75} fill="#E4002B" />
            <circle cx={147.156} cy={28.916} r={8.75} fill="#E4002B" />
            <circle cx={170.002} cy={28.916} r={8.75} fill="#E4002B" />
            <circle cx={44.678} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={67.178} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={89.678} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={112.178} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={134.678} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={157.178} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={179.678} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={202.178} cy={49.416} r={8.75} fill="#E4002B" />
            <circle cx={112.178} cy={9.416} r={8.75} fill="#E4002B" />
            <circle cx={134.678} cy={9.416} r={8.75} fill="#E4002B" />
            <path
                fill="#fff"
                d="M246.892 69.916c-1.671 7.077-4.403 14.202-7.923 21.207-2.698 5.37-5.861 10.669-9.369 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.891 37.664H80.768c-15.12-7.029-32.879-20.789-47.892-37.664a159.19 159.19 0 0 1-4.905-5.728 170.404 170.404 0 0 1-8.939-11.91c-3.52-5.156-6.683-10.454-9.368-15.825C6.132 84.118 3.4 76.993 1.73 69.916h245.163Z"
            />
            <path
                fill="url(#object-food-lentils-red-a)"
                d="M246.893 69.916c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335h198.131Z"
            />
            <path
                fill="#00B140"
                d="M238.968 91.123c-2.697 5.37-5.86 10.669-9.368 15.825H19.034c-3.521-5.156-6.683-10.454-9.368-15.825h229.302ZM220.662 118.857H27.972a158.323 158.323 0 0 0 4.906 5.728h182.879l4.905-5.728Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M203.49 137.144c19.87-18.515 37.714-43.21 43.403-67.224H1.73c9.088 38.365 49.205 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
            />
            <defs>
                <linearGradient
                    id="object-food-lentils-red-a"
                    x1={48.762}
                    x2={246.893}
                    y1={116.083}
                    y2={116.083}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
