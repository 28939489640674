import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g clipPath="url(#man-bold-34-25-legs-cycling2-right-a)">
        <path
            d="M312.734 363.797c-18.245 17.221-17.435 42.417-15.445 61.33 2.973 28.241 58.159 40.28 75.564 47.597 24.145 10.415 49.507-29.143 49.507-29.143s26.877-4.232 44.78-33.825"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M312.734 363.797c-18.245 17.221-17.435 42.417-15.445 61.33 2.973 28.241 58.159 40.28 75.564 47.597 24.145 10.415 49.507-29.143 49.507-29.143s26.877-4.232 44.78-33.825"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M313.351 782.4a639.6 639.6 0 0 1-3.686-22.848c-.543-3.803-1.172-7.612-1.644-11.41l-1.478-11.399c-1.014-7.6-1.89-15.192-3.146-22.81-1.184-7.612-2.73-15.251-3.556-22.838-1.919-15.195-.224-30.136 2.063-45.035a208.948 208.948 0 0 1 4.892-22.247c.49-1.848 1.101-3.688 1.646-5.532.623-1.839 1.197-3.681 1.887-5.515a97.718 97.718 0 0 1 4.657-10.968l5.694-11.297a26.092 26.092 0 0 1 49.171 15.143l-5.881 44.767-2.952 22.383-3.092 22.373c-2.084 14.913-4.071 29.834-6.236 44.742l-6.418 44.73a15.912 15.912 0 0 1-15.462 13.652 15.914 15.914 0 0 1-15.956-13.07l-.503-2.821Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M313.425 603.13c-2.542-15.311-4.641-30.612-6.916-45.918l-3.343-22.956-1.68-11.479-.843-5.739-.746-5.737c-2.127-15.303-3.231-30.585-4.247-45.866a729.607 729.607 0 0 1-1.133-22.913l-.184-5.727-.068-5.724c-.047-3.816-.118-7.633-.028-11.446a50.88 50.88 0 0 1 101.556-3.223l.424 5.212c.314 3.821.526 7.641.638 11.458l.152 5.726.02 5.723c.054 3.816-.075 7.629-.14 11.443-.035 3.815-.254 7.626-.411 11.438-.759 15.246-1.822 30.486-3.892 45.707-1.026 7.61-2.412 15.214-3.965 22.814l-2.353 11.4c-.809 3.799-1.705 7.597-2.554 11.396a376.016 376.016 0 0 1-13.299 45.523l-1.584 4.244a28.807 28.807 0 0 1-55.404-5.356Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m369.851 605.645-5.881 44.767-2.952 22.382-3.092 22.373c-2.084 14.914-4.071 29.835-6.236 44.742l-6.418 44.73a15.918 15.918 0 0 1-31.418.582l-.503-2.82a639.6 639.6 0 0 1-3.686-22.848c-.543-3.803-1.172-7.612-1.644-11.41l-1.478-11.398c-1.014-7.601-1.89-15.192-3.146-22.81-1.184-7.613-2.73-15.251-3.556-22.839-1.919-15.194-.224-30.136 2.063-45.035a208.948 208.948 0 0 1 4.892-22.247c.49-1.848 1.101-3.688 1.646-5.532.623-1.839 3.5-9.57 4.19-11.404M313.425 603.13c-2.542-15.31-4.641-30.612-6.916-45.917l-3.343-22.957-1.68-11.478-.843-5.74-.746-5.737c-2.127-15.303-3.231-30.585-4.247-45.866a729.457 729.457 0 0 1-1.133-22.913l-.184-5.727-.068-5.724c-.047-3.816-.118-7.633-.028-11.446M395.794 416.402l.424 5.212c.313 3.821.526 7.641.638 11.458l.151 5.726.02 5.723c.054 3.816-.075 7.629-.14 11.443-.035 3.815-.254 7.626-.41 11.438-.759 15.246-1.823 30.486-3.892 45.707-1.027 7.61-2.413 15.214-3.966 22.814l-2.352 11.4c-.809 3.799-1.706 7.597-2.554 11.396a400.896 400.896 0 0 1-13.862 46.925"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M313.464 782.836c-.197 4.335-3.72 20.54-6.09 31.045a8.994 8.994 0 0 0 7.316 10.856l20.058 3.308a42.174 42.174 0 0 1 12.303 3.986 69.648 69.648 0 0 0 19.754 6.359c4.043.694 19.1.967 26.32 1.059a4.155 4.155 0 0 0 2.088-7.776c-2.929-1.642-8.116-4.515-12.329-6.646-18.908-9.566-37.338-36.9-37.338-36.9"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M313.464 782.836c-.197 4.335-3.72 20.54-6.09 31.045a8.994 8.994 0 0 0 7.316 10.856l20.058 3.308a42.174 42.174 0 0 1 12.303 3.986 69.648 69.648 0 0 0 19.754 6.359c4.043.694 19.1.967 26.32 1.059a4.155 4.155 0 0 0 2.088-7.776c-2.929-1.642-8.116-4.515-12.329-6.646-18.908-9.566-37.338-36.9-37.338-36.9"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <clipPath id="man-bold-34-25-legs-cycling2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .629)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
