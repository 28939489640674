import { IBodyClothes } from 'interfaces';
import shirt from './female-34-25-shirt-5';
import Sitting from './female-34-25-shirt-5-sitting';

import ArmRightOpt1 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-1-right';
import ArmLeftOpt1 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-1-left';
import ArmRightOpt2 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-2-right';
import ArmLeftOpt2 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-2-left';
import ArmRightOpt3 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-3-right';
import ArmLeftOpt3 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-3-left';
import ArmRightOpt4 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-4-right';
import ArmLeftOpt4 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-4-left';
import ArmRightOpt5 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-5-right';
import ArmLeftOpt5 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-5-left';
import ArmRightOpt6 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-6-right';
import ArmLeftOpt6 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-6-left';
import ArmRightOpt7 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-7-right';
import ArmLeftOpt7 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-7-left';
import ArmRightOpt8 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-8-right';
import ArmLeftOpt8 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-8-left';
import ArmRightOpt9 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-9-right';
import ArmLeftOpt9 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-9-left';
import ArmLeftOpt10 from '../tshirt-6/female-34-25-tshirt-6-arm-opt-10-left';

import ArmsLeftOpt1 from '../tshirt-6/female-34-25-tshirt-6-arms-opt-1-left';
import ArmsRightOpt1 from '../tshirt-6/female-34-25-tshirt-6-arms-opt-1-right';
import ArmsLeftOpt2 from '../tshirt-6/female-34-25-tshirt-6-arms-opt-2-left';
import ArmsRightOpt2 from '../tshirt-6/female-34-25-tshirt-6-arms-opt-2-right';

const rightArm = {
    option1: ArmRightOpt1,
    option2: ArmRightOpt2,
    option3: ArmRightOpt3,
    option4: ArmRightOpt4,
    option5: ArmRightOpt5,
    option6: ArmRightOpt6,
    option7: ArmRightOpt7,
    option8: ArmRightOpt8,
    option9: ArmRightOpt9,
};
const leftArm = {
    option1: ArmLeftOpt1,
    option2: ArmLeftOpt2,
    option3: ArmLeftOpt3,
    option4: ArmLeftOpt4,
    option5: ArmLeftOpt5,
    option6: ArmLeftOpt6,
    option7: ArmLeftOpt7,
    option8: ArmLeftOpt8,
    option9: ArmLeftOpt9,
    option10: ArmLeftOpt10,
};

const shirt5: IBodyClothes = {
    text: 'Shirt #5',
    normal: {
        front: shirt,
        rightArm,
        leftArm,
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
    sitting: {
        front: Sitting,
        rightArm,
        leftArm,
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    },
};

export default shirt5;
