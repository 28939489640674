import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7092)"
            d="M400.796 274.774l-49.323-26.261s.447-.727.593-.961l.943-1.519c2.871-4.619 5.839-9.395 8.579-14.184a27.534 27.534 0 0137.526-10.217 27.535 27.535 0 0110.216 37.527l-.066.114c-2.748 4.749-8.332 15.237-8.468 15.501z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7092"
                x1="370.554"
                x2="392.241"
                y1="211.556"
                y2="273.147"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
