import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m451.887 780.704-.06-.936c-3.165-49.247-3.938-57.958-4.192-60.82-.114-1.287-.115-1.319-.181-3.376l-.042-1.319c-1.19-23.832 8.998-64.579 21.847-87.506-2.207-6.138-26.955-74.954-28.191-78.228-11.043-29.224-19.304-69.605-21.02-87.52l-.064-.662 84.106-45.103.516.42a51.936 51.936 0 0 1 16.215 22.886c.315 1.064.677 2.038 1.135 3.271 1.643 4.419 4.393 11.815 9.008 34.303 16.314 77.763 1.483 145.273-25.44 267.83a26690.333 26690.333 0 0 0-7.875 35.972l-.171.788h-45.591Z"
            fill="#EF0025"
        />
        <path
            d="m386.22 787.339-.179-.775c-10.332-44.6-12.605-53.238-13.46-56.49-.504-1.913-.569-2.159-1.4-6.273-5.527-25.231-2.51-70.11 6.452-96.236-.548-2.364-3.896-17.405-9.169-41.106-4.765-21.418-9.692-43.564-10.135-45.419-5.546-23.19-10.59-65.875-9.04-88.29a86.584 86.584 0 0 0-.363-16.973 69.826 69.826 0 0 1 2.22-27.69l.202-.735h170.127l-.505 1.35c-7.086 18.943-23.28 39.708-44.431 56.969a50.943 50.943 0 0 0-18.798 39.858 310.423 310.423 0 0 1-1.374 31.762c-1.532 15.545-7.267 45.954-14.213 69.932a145.1 145.1 0 0 0-5.612 36.894c-.829 34.637-3.081 103.994-4.351 142.254l-.031.967-45.94.001Z"
            fill="url(#female-34-25-pants-1-stand-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-1-stand-1-a"
                x1={536.824}
                y1={565.406}
                x2={340.046}
                y2={608.73}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
