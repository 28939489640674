import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-2" transform="translate(316, 30)">
        <path
            d="M63.8 81.9C66 50.2 92 41 121.8 39c46.8-3.1 76.5 35.7 50.4 53.9-2.5 1.7-4.8 3.6-7.1 5.4-.2-1.9.2-3.9.6-6 .4-2.3.901-4.6.701-7-.5-5.5-3.701-12.3-9.301-18.2l-1.8.4c-24 4.9-22.2 23.2-39.2 32.9-3.5 2-5.7 5.6-5.9 9.6 0 .6-.1 1-.2.9-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.301 4.4 8.301 5-.1 4.1-.601 7.499-1.301 10.199-.8 3.3-5 4.2-7.1 1.5-21.6-28.7-33.3-34.499-31.7-57.3Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M67.783 68.841c-9.327-5.384-11.514-19.055-4.887-30.534 6.627-11.478 19.56-16.418 28.887-11.034 9.326 5.385 11.514 19.056 4.887 30.534-6.627 11.48-19.56 16.42-28.887 11.034Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M74.5 113.198c-7.9-10-11.7-17.6-10.7-31.2 2.2-31.7 28.2-40.9 58-42.9 34-2.2 59 17.6 60.1 35.6"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110 110.899c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
