import { IBodyClothes } from 'interfaces';
import Sport1 from './female-34-25-sport-1';

import ArmRightOpt1 from './female-34-25-sport-1-arm-opt-1-right';
import ArmLeftOpt1 from './female-34-25-sport-1-arm-opt-1-left';
import ArmRightOpt2 from './female-34-25-sport-1-arm-opt-2-right';
import ArmLeftOpt2 from './female-34-25-sport-1-arm-opt-2-left';
import ArmRightOpt3 from './female-34-25-sport-1-arm-opt-3-right';
import ArmLeftOpt3 from './female-34-25-sport-1-arm-opt-3-left';
import ArmRightOpt4 from './female-34-25-sport-1-arm-opt-4-right';
import ArmLeftOpt4 from './female-34-25-sport-1-arm-opt-4-left';
import ArmRightOpt5 from './female-34-25-sport-1-arm-opt-5-right';
import ArmLeftOpt5 from './female-34-25-sport-1-arm-opt-5-left';
import ArmRightOpt6 from './female-34-25-sport-1-arm-opt-6-right';
import ArmLeftOpt6 from './female-34-25-sport-1-arm-opt-6-left';
import ArmRightOpt7 from './female-34-25-sport-1-arm-opt-7-right';
import ArmLeftOpt7 from './female-34-25-sport-1-arm-opt-7-left';
import ArmRightOpt8 from './female-34-25-sport-1-arm-opt-8-right';
import ArmLeftOpt8 from './female-34-25-sport-1-arm-opt-8-left';
import ArmRightOpt9 from './female-34-25-sport-1-arm-opt-9-right';
import ArmLeftOpt9 from './female-34-25-sport-1-arm-opt-9-left';
import ArmLeftOpt10 from './female-34-25-sport-1-arm-opt-10-left';

import ArmsLeftOpt1 from './female-34-25-sport-1-arms-opt-1-left';
import ArmsRightOpt1 from './female-34-25-sport-1-arms-opt-1-right';
import ArmsLeftOpt2 from './female-34-25-sport-1-arms-opt-2-left';
import ArmsRightOpt2 from './female-34-25-sport-1-arms-opt-2-right';

const sport1: IBodyClothes = {
    text: 'Sport #1',
    normal: {
        front: Sport1,
        rightArm: {
            option1: ArmRightOpt1,
            option2: ArmRightOpt2,
            option3: ArmRightOpt3,
            option4: ArmRightOpt4,
            option5: ArmRightOpt5,
            option6: ArmRightOpt6,
            option7: ArmRightOpt7,
            option8: ArmRightOpt8,
            option9: ArmRightOpt9,
        },
        leftArm: {
            option1: ArmLeftOpt1,
            option2: ArmLeftOpt2,
            option3: ArmLeftOpt3,
            option4: ArmLeftOpt4,
            option5: ArmLeftOpt5,
            option6: ArmLeftOpt6,
            option7: ArmLeftOpt7,
            option8: ArmLeftOpt8,
            option9: ArmLeftOpt9,
            option10: ArmLeftOpt10,
        },
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        },
    }
};

export default sport1;
