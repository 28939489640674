import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#2B2729"
            d="M554.312 375.925a230.874 230.874 0 00-39.94-7.212c-27.686-3.551-53.134 10.4-61.853 37.401a54.118 54.118 0 00-2.237 21.086 54.121 54.121 0 0019.426 36.771 54.103 54.103 0 0018.678 10.037 109.652 109.652 0 0014.1 3.359l11.219-.001c6.032-28.208 18.899-69.124 40.607-101.441z"
        ></path>
    </g>
);

export default SvgComponent;
