import React from 'react';
import shorts12options from './shorts-1-2-options';

const SvgComponent = ({ option = 0 }: { option?: number}) => {
    return <g id="man-34-25-shorts-1-2-stand-1">
        <path
            fill={shorts12options[option].fillColor}
            d="M370.801 386.9h161.9l-1.9 11.4c-1.8 10.8-1.8 21.7-.1 32.5 3.3 20.1 14.6 100 14.6 119.8h-137.6"
        />
        <path
            fill={`url(#${shorts12options[option].stand1})`}
            d="M429.1 386.9h-58.9c-.7 0-1.4.3-1.8.9-3.7 5-21 30.5-16.3 70.4l7.4 100h99.6c.6-9.1.4-38.7.3-57.7-.1-10.9 3.6-21.5 10.5-30 11-13.6 26.8-33.6 28-37.4.2-.6.6-1 1.1-1.3 4.1-2.3 23.3-14.1 31.7-41.8.4-1.5-.7-2.9-2.2-2.9h-99.4v-.2Z"
        />
        <defs>
            <linearGradient
                id={shorts12options[0].stand1}
                x1={351.383}
                x2={530.694}
                y1={472.499}
                y2={472.499}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#FFB91D" />
                <stop offset={0.186} stopColor="#FFC02E" />
                <stop offset={0.518} stopColor="#FFCD4D" />
                <stop offset={0.801} stopColor="#FFD45F" />
                <stop offset={1} stopColor="#FFD766" />
            </linearGradient>
            <linearGradient
                id={shorts12options[1].stand1}
                x1={350.934}
                x2={530.245}
                y1={472.499}
                y2={472.499}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.389} stopColor="#002E98" />
                <stop offset={0.748} stopColor="#0040A3" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
