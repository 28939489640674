import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m475.684 232.67.065-.318a64.022 64.022 0 0 0 1.386-10.445 32.92 32.92 0 0 0-1.357-11.178l-.834-2.627 2.325 1.482a106.713 106.713 0 0 1 17.498 13.757 2.715 2.715 0 0 1 .821 2.482c-.805 4.667-13.806 10.892-16.404 12.093l-.912.421-2.588-5.667Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-2-left-a)"
        />
        <path
            d="M540.283 335.501a20.186 20.186 0 0 1-11.254-3.415 244.562 244.562 0 0 1-19.749-15.059c-6.003-5.033-11.974-10.373-18.256-16.325a193.353 193.353 0 0 1-16.77-17.707 829.892 829.892 0 0 1-4.682-5.65c-3.524-4.275-7.17-8.695-10.899-12.904a27.5 27.5 0 1 1 41.166-36.475l.085.095c3.72 4.24 7.657 8.419 11.465 12.46a1798.23 1798.23 0 0 1 4.999 5.328 193.218 193.218 0 0 1 15.47 18.86c5.116 6.972 9.665 13.564 13.904 20.147a244.211 244.211 0 0 1 12.469 21.479 20.126 20.126 0 0 1-.836 19.591 20.124 20.124 0 0 1-17.112 9.575Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-2-left-b)"
        />
        <path
            d="M540.309 342.159a19.03 19.03 0 0 1-18.661-15.413 24.063 24.063 0 0 1-.317-6.329c.143-1.813.476-3.607.994-5.351a10.083 10.083 0 0 1 2.507-4.545c.324-.338.667-.678 1.009-1.018a20.77 20.77 0 0 0 2.104-2.294 19.602 19.602 0 0 1 15.645-7.842 19.502 19.502 0 0 1 15.617 31.162l-.24.315a20.995 20.995 0 0 0-1.657 2.641 49.62 49.62 0 0 1-.719 1.253 10.123 10.123 0 0 1-3.736 3.627 25.836 25.836 0 0 1-4.85 2.356c-2 .731-4.087 1.195-6.209 1.381-.498.038-.995.057-1.487.057Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-2-left-c)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-2-left-a"
                x1={474.941}
                y1={216.931}
                x2={579.037}
                y2={341.847}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-2-left-b"
                x1={463.687}
                y1={226.309}
                x2={567.783}
                y2={351.225}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-2-left-c"
                x1={521.26}
                y1={320.763}
                x2={563.081}
                y2={320.763}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
