import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#63666A"
                d="M534.131 673.883l8.853 31.46h5.632s-.09-6.348-.307-10.702c-.15-3.017.508-7.642 3.908-7.551"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M542.984 705.336l5.633.126"
            ></path>
            <path
                fill="#888B8D"
                d="M595.785 710.77c-3.712.4-7.462.263-11.134-.407l-5.404-.985a24.33 24.33 0 01-14.76-8.883l-6.465-8.202c-2.677-3.396-5.045-4.047-9.137-5.442l-5.798-1.977a13.895 13.895 0 01-9.086-16.261l3.844-16.941s8.387 3.898 16.514 17.318c1.577 2.605 8.926 15.114 11.164 18.665 4.797 7.613 13.914 9.894 22.572 9.777 6.511-.088 11.699-4.609 11.699-4.609l9.321 4.542a6.434 6.434 0 013.58 6.88 6.432 6.432 0 01-5.646 5.318l-11.264 1.207z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M610.437 707.918a6.41 6.41 0 01-3.946 1.952l-11.264 1.207c-3.712.4-20.216 3.139-31.298-10.275l-6.465-8.201c-2.677-3.396-5.045-4.047-9.137-5.443l-5.798-1.976a15.927 15.927 0 01-8.098-7.213"
            ></path>
        </g>
    );
}
