import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.017 303.506H14.134c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.88 216.88 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.665 210.285a216.86 216.86 0 0 1 54.274-7.2 216.88 216.88 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.134c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.322 268.643H22.899a6 6 0 0 1-5.944-5.185l-7.289-53.173M120.487 210.285l-7.289 53.173a6 6 0 0 1-5.944 5.185M112.381 208.318 94.204 27.881C92.698 12.924 80.11 1.54 65.076 1.54s-27.622 11.385-29.13 26.342L17.77 208.318M53.518 253.293h23.116"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={125.298}
                        x2={28.287}
                        y1={305.562}
                        y2={227.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#888B8D" />
                        <stop offset={0.31} stopColor="#A2A4A4" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.74 303.506H14.857c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.884 216.884 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.388 210.285a216.86 216.86 0 0 1 54.274-7.2 216.884 216.884 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.857c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M91.045 268.643H23.622a6 6 0 0 1-5.944-5.185l-7.29-53.173M121.21 210.285l-7.289 53.173a6 6 0 0 1-5.944 5.185M113.104 208.318 94.928 27.881C93.421 12.924 80.832 1.54 65.8 1.54c-15.033 0-27.622 11.385-29.129 26.342L18.494 208.318M54.24 253.293h23.116"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={126.02}
                        x2={29.01}
                        y1={305.562}
                        y2={227.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.465 303.506H14.582c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.887 216.887 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.113 210.285a216.86 216.86 0 0 1 54.274-7.2 216.887 216.887 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.582c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.77 268.643H23.345a6 6 0 0 1-5.944-5.185l-7.289-53.173M120.934 210.285l-7.289 53.173a6 6 0 0 1-5.944 5.185M112.828 208.318 94.652 27.881C93.145 12.924 80.556 1.54 65.522 1.54c-15.032 0-27.621 11.385-29.128 26.342L18.217 208.318M53.965 253.293H77.08"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={125.746}
                        x2={28.735}
                        y1={305.562}
                        y2={227.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.698} stopColor="#FFEA8B" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.504 303.506H14.621c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.886 216.886 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.152 210.285a216.86 216.86 0 0 1 54.274-7.2 216.886 216.886 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.621c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.808 268.643H23.385a6 6 0 0 1-5.944-5.185l-7.289-53.173M120.973 210.285l-7.289 53.173a6 6 0 0 1-5.944 5.185M112.867 208.318 94.691 27.881C93.184 12.924 80.595 1.54 65.56 1.54c-15.032 0-27.621 11.385-29.128 26.342L18.256 208.318M54.004 253.293H77.12"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={125.785}
                        x2={28.774}
                        y1={305.562}
                        y2={227.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.456} stopColor="#FFA9A2" />
                        <stop offset={1} stopColor="#FFD9D9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.543 303.514H14.66c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.886 216.886 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.19 210.293a216.86 216.86 0 0 1 54.275-7.2 216.886 216.886 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.66c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.847 268.647H23.424a6 6 0 0 1-5.944-5.185l-7.289-53.173M121.012 210.289l-7.289 53.173a6 6 0 0 1-5.944 5.185M112.907 208.326 94.731 27.889C93.224 12.932 80.635 1.547 65.6 1.547c-15.032 0-27.621 11.385-29.128 26.342L18.297 208.326M54.043 253.301h23.116"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={125.823}
                        x2={28.813}
                        y1={305.57}
                        y2={227.254}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.035} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 131,
        height: 305,
        content: (
            <>
                <path
                    fill="url(#object-devices-bag-2-a)"
                    d="M116.265 303.514H14.382c-7.728 0-13.75-6.702-12.926-14.387l8.457-78.834a216.86 216.86 0 0 1 54.274-7.2 216.88 216.88 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.913 210.293a216.86 216.86 0 0 1 54.274-7.2 216.88 216.88 0 0 1 56.547 7.2l8.457 78.834c.824 7.684-5.197 14.387-12.926 14.387H14.382c-7.728 0-13.75-6.702-12.926-14.387l5.915-55.141"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.57 268.647H23.147a6 6 0 0 1-5.944-5.185l-7.289-53.173M120.735 210.289l-7.289 53.173a6 6 0 0 1-5.944 5.185M112.63 208.326 94.454 27.889C92.947 12.932 80.358 1.547 65.324 1.547c-15.032 0-27.621 11.385-29.128 26.342L18.02 208.326M53.766 253.301h23.116"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-2-a"
                        x1={125.545}
                        x2={28.535}
                        y1={305.57}
                        y2={227.254}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.207} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-bag-2"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
