import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6457)"
            d="M393.918 363.283l-12.103.018-12.207-.19a21.329 21.329 0 01-19.439-12.321c-1.245-2.768-1.521-6.379-1.707-9.393a254.378 254.378 0 01.192-25.684c.355-8.088.973-16.345 1.89-25.248.82-8.563 6.186-43.866 7.722-49.482 4.843-17.711 15.274-22.327 28.457-22.327 1.726.001 3.449.164 5.145.485a27.502 27.502 0 0121.905 32.135l-.024.125c-1.109 5.713-2.039 11.567-2.938 17.228-.397 2.5-.799 5-1.207 7.498a206.74 206.74 0 01-5.601 24.595c-2.258 7.971-4.56 15.36-7.022 22.537a245.74 245.74 0 0120.859.492c1.324.094 2.644.177 3.964.259 2.696.17 5.484.345 8.236.615l6.075.531c1.319.117 2.635.264 3.952.41.717.08 1.436.158 2.154.235 5.299.589 9.654 1.145 13.712 1.752l1.565.234-.883 1.313c-4.613 6.86-5.753 18.483-2.71 27.637l.37 1.113-1.159.19a404.437 404.437 0 01-10.466 1.555c-.728.094-1.449.192-2.169.291-1.307.179-2.614.357-3.921.507l-6.074.685c-2.729.336-5.507.58-8.195.817-1.321.116-2.643.232-3.965.359a246.31 246.31 0 01-22.409 1.038s-1.333-.003-1.999-.009z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6457"
                x1="376.775"
                x2="408.871"
                y1="209.917"
                y2="364.327"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
