import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m527.553 376.625-173.201 49.779V634.08h177.281V455.932c0-3.2-.088-6.399-.263-9.596l-3.817-69.711Z"
            fill="url(#female-34-25-doctors-coat-p2-a)"
        />
        <path
            d="M435.242 634.078h96.398v-178.21c0-2.118-.075-4.235-.225-6.35l-4.76-67.143"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-p2-a"
                x1={259.314}
                y1={505.352}
                x2={565.359}
                y2={505.352}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#63666A" />
                <stop offset={0.964} stopColor="#8E8B8F" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
