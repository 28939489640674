import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7266)"
            d="M494.773 222.861a2.708 2.708 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7266)"
            d="M510.4 244.055c-2.421-3.874-4.924-7.88-7.208-11.911l-.078-.139a27.504 27.504 0 00-16.668-12.829 27.505 27.505 0 00-33.707 19.356 27.5 27.5 0 002.679 20.862c2.306 4.015 4.478 8.206 6.579 12.26.425.821.855 1.637 1.282 2.457 0 0 13.237-12.559 23.647-18.306a140.618 140.618 0 0124.358-10.326c-.296-.474-.588-.95-.884-1.424z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7266"
                x1="499.405"
                x2="421.332"
                y1="209.367"
                y2="251.873"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7266"
                x1="507.623"
                x2="429.551"
                y1="224.458"
                y2="266.964"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
