import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#FFD30C"
                d="M536.74 681.594l6.57 23.344h5.632s-.091-6.348-.307-10.701c-.107-2.151.196-5.118 1.61-6.612"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M543.311 704.938l5.632.125"
            ></path>
            <path
                fill="url(#paint0_linear_11258_6669)"
                d="M548.421 678.586c-.364-8.495 1.694-17.525-6.19-26.352l31.652 1.909-1.627-3.47-33.912-3.47-5.083 22.258a13.9 13.9 0 009.687 15.909l5.868 1.758a18.848 18.848 0 014.236 1.792c-1.495-2.707-4.447-6.059-4.631-10.334z"
            ></path>
            <path
                fill="url(#paint1_linear_11258_6669)"
                d="M596.251 691.418a16.03 16.03 0 01-1.928-1.005l-.335-.202-.383.08c-.905.135-1.802.317-2.687.547a21.46 21.46 0 00-10.773 7.025 14.65 14.65 0 00-3.049 10.874l.094.629.61.181a24.11 24.11 0 003.479.787l5.436.782c2.577.37 3.796-.116 6.472-.28l.969-.059-.03-.97a14.641 14.641 0 013.906-10.446 14.479 14.479 0 015.191-3.455l1.645-.472-8.617-4.016z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M611.495 706.791c-.98 1.104-4.592 1.653-6.128 1.877-2.803.408-6.103.893-8.906 1.3-3.694.538-11.337 1.242-15.183.366-5.789-1.318-11.234-3.801-15.082-8.324l-6.767-7.954a22.312 22.312 0 00-10.612-6.932l-5.869-1.758a15.93 15.93 0 01-8.362-6.905"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11258_6669"
                    x1="533.018"
                    x2="573.883"
                    y1="668.062"
                    y2="668.062"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#FFD100"></stop>
                    <stop offset="1" stopColor="#FFF4C3"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_11258_6669"
                    x1="576.962"
                    x2="604.867"
                    y1="700.731"
                    y2="700.731"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#FFD100"></stop>
                    <stop offset="1" stopColor="#FFF4C3"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
