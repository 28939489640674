import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 131,
        height: 38,
        content: (
            <>
                <path
                    fill="url(#object-food-mussel-a)"
                    d="M95.208 36.252H79.465a149.248 149.248 0 0 1-77.89-21.938h128.137a38.104 38.104 0 0 1-34.505 21.938h.001Z"
                />
                <path
                    fill="url(#object-food-mussel-b)"
                    d="M94.866.35H84.845a95.009 95.009 0 0 0-49.583 13.965h81.569A24.256 24.256 0 0 0 94.866.35Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M36.066 14.315h93.647A38.104 38.104 0 0 1 95.208 36.25H79.465a149.248 149.248 0 0 1-77.89-21.936h11.374"
                />
                <defs>
                    <linearGradient
                        id="object-food-mussel-a"
                        x1={1.575}
                        x2={129.713}
                        y1={25.284}
                        y2={25.284}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#004F71" />
                        <stop offset={0.125} stopColor="#01567A" />
                        <stop offset={1} stopColor="#0B86B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-mussel-b"
                        x1={45.522}
                        x2={160.343}
                        y1={-2.443}
                        y2={48.336}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.127} stopColor="#FFEB94" />
                        <stop offset={0.269} stopColor="#FFE367" />
                        <stop offset={0.412} stopColor="#FFDC42" />
                        <stop offset={0.555} stopColor="#FFD725" />
                        <stop offset={0.696} stopColor="#FFD310" />
                        <stop offset={0.835} stopColor="#FFD104" />
                        <stop offset={0.969} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        )
    },
    'Option #2': {
        width: 131,
        height: 84,
        content: (
            <>
                <path
                    fill="url(#object-food-mussel-a)"
                    d="M7.195 23.903C.617 45.99 25.009 66.3 48.813 73.39c29.09 8.664 64.069.93 80.629-13.075-6.198-20.784-31.245-46.396-60.335-55.06-23.805-7.09-55.334-3.438-61.912 18.65Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M13.91 12.766c-3.067 3.011-5.394 6.702-6.715 11.137C.617 45.99 25.009 66.3 48.813 73.39c29.09 8.664 64.069.93 80.629-13.075-6.198-20.784-31.245-46.396-60.335-55.06-13.292-3.96-28.991-4.569-41.411-.49"
                />
                <path
                    fill="url(#object-food-mussel-b)"
                    d="M94.937 82.251H79.194A149.248 149.248 0 0 1 1.303 60.314H129.44a38.104 38.104 0 0 1-34.505 21.937Z"
                />
                <path
                    fill="url(#object-food-mussel-c)"
                    d="M82.862 38.291c-18.886 0-36.51 7.622-52.233 22.025h85.929c-2.881-14.766-14.81-22.025-33.696-22.025Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M129.442 60.315A38.104 38.104 0 0 1 94.937 82.25H79.194A149.248 149.248 0 0 1 1.303 60.314h128.139Z"
                />
                <defs>
                    <linearGradient
                        id="object-food-mussel-a"
                        x1={119.953}
                        x2={10.2}
                        y1={91.024}
                        y2={-3.378}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.006} stopColor="#64CCC9" />
                        <stop offset={1} stopColor="#BBE9E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-mussel-b"
                        x1={1.303}
                        x2={129.441}
                        y1={71.284}
                        y2={71.284}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#004F71" />
                        <stop offset={0.125} stopColor="#01567A" />
                        <stop offset={1} stopColor="#0B86B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-mussel-c"
                        x1={42.407}
                        x2={163.365}
                        y1={40.469}
                        y2={93.962}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.127} stopColor="#FFEB94" />
                        <stop offset={0.269} stopColor="#FFE367" />
                        <stop offset={0.412} stopColor="#FFDC42" />
                        <stop offset={0.555} stopColor="#FFD725" />
                        <stop offset={0.696} stopColor="#FFD310" />
                        <stop offset={0.835} stopColor="#FFD104" />
                        <stop offset={0.969} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        )
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-mussel"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
