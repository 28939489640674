import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7198)"
            d="M475.686 232.669l.064-.318a63.888 63.888 0 001.387-10.444 32.92 32.92 0 00-1.357-11.178l-.834-2.627 2.325 1.482a106.705 106.705 0 0117.497 13.757 2.7 2.7 0 01.822 2.482c-.805 4.667-13.806 10.892-16.405 12.093l-.911.421-2.588-5.668z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7198)"
            d="M540.285 335.501a20.186 20.186 0 01-11.254-3.415 244.562 244.562 0 01-19.749-15.059c-6.003-5.033-11.974-10.373-18.256-16.325a193.353 193.353 0 01-16.77-17.707 829.892 829.892 0 01-4.682-5.65c-3.524-4.275-7.17-8.695-10.899-12.904a27.5 27.5 0 1141.166-36.475l.085.095c3.72 4.24 7.657 8.419 11.465 12.46a1681.92 1681.92 0 014.999 5.328 193.218 193.218 0 0115.47 18.86c5.116 6.972 9.665 13.564 13.904 20.147a244.373 244.373 0 0112.469 21.48 20.114 20.114 0 01-8.147 26.603 20.118 20.118 0 01-9.801 2.562z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_7198)"
            d="M540.311 342.159a19.03 19.03 0 01-18.661-15.413 24.063 24.063 0 01-.317-6.329c.143-1.813.476-3.607.994-5.351a10.092 10.092 0 012.507-4.546c.324-.337.667-.677 1.009-1.017a20.868 20.868 0 002.104-2.294 19.602 19.602 0 0115.645-7.842 19.505 19.505 0 0119.411 21.219 19.497 19.497 0 01-3.794 9.943l-.24.315a20.995 20.995 0 00-1.657 2.641 49.62 49.62 0 01-.719 1.253 10.13 10.13 0 01-3.736 3.627 25.836 25.836 0 01-4.85 2.356c-2 .731-4.087 1.195-6.209 1.381-.498.038-.995.057-1.487.057z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7198"
                x1="484.643"
                x2="470.98"
                y1="223.799"
                y2="372.137"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7198"
                x1="511.088"
                x2="497.425"
                y1="226.235"
                y2="374.573"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_7198"
                x1="521.262"
                x2="563.083"
                y1="320.763"
                y2="320.763"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
