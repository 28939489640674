import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M409.336 259.194c-3.937 6.803-7.517 13.811-11.251 20.731a180.933 180.933 0 0 1-12.51 20.008c-4.55 6.45-9.28 12.796-14.263 18.998a225.624 225.624 0 0 1-15.947 18.029 19.167 19.167 0 0 1-31.226-6.181 19.168 19.168 0 0 1-.845-12.271 225.424 225.424 0 0 1 7.571-22.848c2.857-7.425 5.965-14.704 9.255-21.88a181.207 181.207 0 0 1 11.009-20.87c4.106-6.706 8.365-13.324 12.268-20.146a26.498 26.498 0 1 1 46.005 26.316l-.066.114Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m359.359 326.168.454 13.517.597 13.514c.361 9.01.313 18.029-.145 27.056-.243 4.515-.383 9.027-.736 13.543l-.465 6.773c-.159 2.258-.386 4.517-.576 6.776-.812 9.035-1.791 18.074-3.33 27.124a12.22 12.22 0 0 1-11.786 10.169 12.213 12.213 0 0 1-7.841-2.634 12.214 12.214 0 0 1-4.367-7.025c-1.921-8.977-3.283-17.966-4.479-26.958-.285-2.249-.609-4.496-.863-6.745l-.752-6.748c-.544-4.497-.875-8.999-1.31-13.499-.832-9-1.263-18.008-1.294-27.026l.023-13.527-.121-13.525a18.502 18.502 0 0 1 36.991-.785Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m409.404 259.078-.065.114c-3.937 6.804-7.517 13.812-11.251 20.732a181.069 181.069 0 0 1-12.51 20.007c-4.55 6.45-9.281 12.796-14.263 18.998-3.117 3.855-6.303 7.671-9.655 11.391M322.674 323.693c-.028-1.75.181-3.496.623-5.19a225.359 225.359 0 0 1 7.571-22.849c2.857-7.424 5.965-14.704 9.255-21.879a180.887 180.887 0 0 1 11.009-20.87c4.105-6.706 10.568-16.528 14.471-23.35M331.167 434.975c-1.921-8.977-3.284-17.966-4.479-26.958-.286-2.249-.609-4.496-.863-6.745l-.752-6.748c-.544-4.497-.876-8.999-1.31-13.498-.832-9-1.263-18.009-1.294-27.027l.022-13.527.183-16.777M359.59 332.992l.822 20.206c.361 9.011.312 18.029-.146 27.057-.243 4.514-.383 9.026-.735 13.542l-.465 6.774c-.159 2.258-.386 4.517-.576 6.775-.813 9.035-1.792 18.074-3.33 27.125"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M365.954 470.725c.095-2.285.347-4.561.753-6.812a38.46 38.46 0 0 0-9.285-29.757c-10.718-12.746-22.939-13.498-26.698 1.628-1.458 9.565-2.416 19.248-1.674 28.884.741 9.635 3.237 19.263 8.34 27.688.227.402.506.771.83 1.1.301.292.69.478 1.107.529a1.477 1.477 0 0 0 1.416-.817l.223-.309c.901 2.33 1.88 4.636 2.858 6.94.258.425.639.762 1.092.967.453.205.958.269 1.448.184a2.55 2.55 0 0 0 2.087-1.745l.225-.426a18.1 18.1 0 0 1-.024 1.921 1.983 1.983 0 0 0 .576 1.443 1.998 1.998 0 0 0 1.437.589l.04.001a2.092 2.092 0 0 0 2.076-1.383c1.705-5.209 1.538-10.837 1.03-16.288-.159-1.708-.35-3.42-.522-5.132l.38.66a46.622 46.622 0 0 1 2.764 16.316 1.411 1.411 0 0 0 .876 1.345c.176.072.365.107.556.104a3.604 3.604 0 0 0 2.979-2.173 21.847 21.847 0 0 0 2.418-10.556 62.033 62.033 0 0 0-.774-9.22l.268.185a4.737 4.737 0 0 0 2.719 1.537 2.864 2.864 0 0 0 1.534-.137c.49-.184.919-.498 1.243-.909a9.128 9.128 0 0 1-2.298-6.357Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M357.422 434.156a38.454 38.454 0 0 1 9.284 29.757 50.263 50.263 0 0 0-.753 6.812 9.135 9.135 0 0 0 2.298 6.358M361.626 453.508a91.752 91.752 0 0 0 .275 18.428c.53 4.56 1.402 9.103 1.36 13.69a22.767 22.767 0 0 1-2.417 10.556 3.98 3.98 0 0 1-4.41 1.87l-.028-.007M353.102 467.188c-.781 5.929.152 11.921.707 17.873.509 5.451.676 11.079-1.03 16.289a2.094 2.094 0 0 1-2.075 1.383l-.04-.002a1.98 1.98 0 0 1-1.438-.589 1.998 1.998 0 0 1-.576-1.443 33.106 33.106 0 0 0-1.444-10.221c-1.015-3.947-3.384-15.108-3.077-23.004M343.824 499.797a122.48 122.48 0 0 1-5.006-13.177 59.964 59.964 0 0 1-2.355-20.19M337.39 492.354c-5.103-8.425-7.599-18.053-8.34-27.689-.742-9.636.216-19.318 1.674-28.884"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
