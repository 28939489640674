import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891']
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-4" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color][0]}
            d="M96.5 27.3c.3-3.4 3.6-5.9 7-6.4s6.7.6 10 1.6c-1.5-1.7-.4-4.5 1.5-5.6 1.9-1.1 4.3-.8 6.5-.4 13.9 2.7 25.2 8.9 32.1 21.2 0 0 3.7 8.6 2 13.2-.5-1.2-2.2-3.2-2.5-3-4.7 2.9-11.1 2.4-16.2.3-17.1-7.1-29.9-9-32.7-5.4-5.2 6.6 7.3 13.1 8.8 19.4 1.3 5.3-2 9.6-5.2 14-3.2 4.5-1 10.6-1 15h-5.3c-2.5-5.9-6.3-9.4-11.2-10.4-2.4-.5-5.4.2-6.6 2.4-2.1 3.9-1.8 9.6.4 13.8 2.3 4.501 4.8 8.301 9.2 10.901a91.556 91.556 0 01-.533 6.827c-.63 5.433-7.528 6.2-10.031 1.338-2.547-4.946-5.082-9.897-7.536-15.065-4.7-9.9-8.4-20.4-8.6-31.4-.1-8.1-.2-36.9 29.9-42.3z"
        ></path>
        <path
            stroke={HAIR_COLOR[color][1]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M75.3 101.201c-4.7-9.9-8.4-20.4-8.6-31.4-.2-8.4-.2-37.1 29.8-42.5.3-3.4 3.6-5.9 7-6.4s6.7.6 10 1.6c-1.5-1.7-.4-4.5 1.5-5.6 1.9-1.1 4.3-.8 6.5-.4 13.9 2.7 25.2 8.9 32.1 21.2"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.2c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.401 13.5 19.901"
        ></path>
    </g>
);

export default SvgComponent;
