import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-kitchen-1"
            {...attributes}
        >
            <path fill="#fff" d="M2570.1 0H.1v1472h2570z" />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2272.1 740H327.102l-55.5 40.5H2024.1V800H262.602v28.5h168v105M411.098 854v40.5M447.098 854v40.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M830.727 1116.48H623.477l-6.63-19.64h209.2M826.047 1096.84l-36.8-154.223h-33.87M830.727 1116.48l17.29 72.46h13.87L810.007 924"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m679.985 905.383-55.523 283.557h13.874l63.204-264.941h108.468"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m730.973 942.617 5.266 246.323h13.874l5.266-246.323M666.351 905.382c-5.956 0-10.784-4.828-10.784-10.784 0-13.033 10.565-23.598 23.598-23.598h128.018c13.033 0 23.598 10.565 23.598 23.598 0 5.956-4.828 10.784-10.784 10.784H679.985"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2148.5 599.349h46.95v-309.9H1758.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1887.8 253v373.95h336.3V262.9h-305.55v336.45h178.95"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m2097.5 599.651-13.5-40.5h11.55c-11.85-8.1-18.15-19.35-18.15-19.35s16.35-1.5 31.5 6.6c.75.45 1.35.75 2.1 1.2-3.6-5.55-6.9-12-9.3-19.05-9.15-27.75-.3-55.5-.3-55.5s23.85 17.1 33 44.7c.9 2.85 1.65 5.85 2.25 8.7 10.8-17.4 27.75-26.55 27.75-26.55s4.35 23.25-5.85 44.4c-2.7 5.85-6.3 10.8-10.05 15.15h13.5l-13.5 40.5M1145.6 224h192v133.5h138l93 58.5h-304.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1472.6 224v112.5h-117L1240.1 416v36h342v-34.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#a)"
            >
                <path
                    d="M502.807 250.526v330.063h178.295V224H324.513v356.589h147.41M533.691 555.575V507.07M471.922 555.575V507.07" />
            </g>
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M861.887 288.297v31.913l-15.113 15.114M861.887 245.594v14.489M936.945 320.211h-14.488M861.887 395.387v-14.489M786.831 320.211h14.489"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M924.163 345.957a67.143 67.143 0 0 0 5.099-25.747c0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                fill="url(#b)"
                d="m1046.39 905.379 6.06 283.561h13.87l6.07-283.573-26 .012Z"
            />
            <path fill="#AEAEAF" d="M958.336 1096.84h202.104v18.61H958.336v-18.61Z" />
            <path
                fill="#AEAEAF"
                d="m1122.58 905.379 55.52 283.561h-13.87l-67.65-283.573 26 .012ZM996.2 905.379l-55.522 283.561h13.873l67.649-283.573-26 .012Z"
            />
            <path
                fill="url(#c)"
                d="M1147 894.598c0 5.956-4.83 10.784-10.79 10.784H982.565c-5.956 0-10.784-4.828-10.784-10.784 0-13.033 10.565-23.598 23.598-23.598H1123.4c13.03 0 23.6 10.565 23.6 23.598Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1096.58 905.382H982.565c-5.956 0-10.784-4.828-10.784-10.784 0-13.033 10.565-23.598 23.598-23.598H1123.4c13.03 0 23.6 10.565 23.6 23.598 0 5.956-4.83 10.784-10.79 10.784h-13.63"
            />
            <path
                fill="#AEAEAF"
                d="M1342 756.91V654.598c0-4.991 4.04-9.038 9.03-9.038 5 0 9.04 4.046 9.04 9.038v29.736h20.56v-29.736c0-16.347-13.25-29.598-29.6-29.598-16.34 0-29.59 13.251-29.59 29.598V756.91H1342Z"
            />
            <path fill="#818084" d="M1321.44 719.605H1342v9.329h-20.56v-9.329Z" />
            <path
                fill="url(#d)"
                d="m1231.37 778.526 9.94-21.615h19.21v-12.837H1278v12.837h137.96l9.95 21.615h-194.54Z"
            />
            <path fill="url(#e)" d="M1603.96 778.523h154.14V1189h-154.14V778.523Z" />
            <path fill="#FFD100" d="M1231.37 800.141h154.16V1189h-154.16V800.141Z" />
            <path fill="#FCEB94" d="M1342.3 800.141h240.04v311.309H1342.3V800.141Z" />
            <path
                fill="#FDF3BF"
                d="M1582.34 778.523h21.62V1189h-21.62V778.523ZM1209.76 778.523h21.61V1189h-21.61V778.523ZM1231.37 778.523h350.95v21.615h-350.95v-21.615Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1385.53 1189v-77.55M1290.8 778.523h-81.05V1189"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1758.1 1189V778.523h-520.93M1582.32 800.141h-350.95M1582.33 1189l.01-388.859M1582.34 1111.45H1342M1278 744.074h-17.48M1241.32 756.914h174.64"
            />
            <path
                fill="#FCB47F"
                d="M1079.3 585.655c6.9 5.7 14.1 10.999 21.1 16.399 7.1 5.4 13.8 11.301 20.2 17.601 6.4 6.2 12.7 12.699 18.8 19.299 6.1 6.7 12 13.5 17.5 20.9 7.6 10.3 5.5 24.8-4.8 32.5-7.1 5.3-16.2 5.901-23.6 2.401-8.3-3.9-16.2-8.301-24-12.901-7.7-4.6-15.3-9.5-22.7-14.5-7.4-5-14.6-10.399-21.3-16.199-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5h.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1073.4 661.353c-4.4-3.3-8.8-6.7-12.9-10.3-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5l.1.1c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 4.6 5 9.1 10.1 13.4 15.5"
            />
            <path
                fill="url(#f)"
                d="m1041.7 564.855 2.7 1c13.1 4.7 23.8 10.301 32.8 17.301.8.6 5.9 5.4 5.8 6.4-.2 4.6-16.9 8.2-19.3 9.6l-.9.5-21.1-34.801Z"
            />
            <path
                fill="url(#g)"
                d="M1094.7 596.354c-9.3-6.4-17.3-16.1-28.9-18.2-20.4-4.5-40.6 14-37.9 34.7.9 15.8 15.3 24.1 25.9 33.6 6.2 5.4 12.5 10.8 19.1 15.7 1.5 1.1 56.8-35.3 56.8-35.3-10.8-12-28.6-25.8-35-30.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1160.39 1030.4c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.502-1-12.702l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.7 1.7-8.2 3.7-16.3 6.2-24.4.6-2 1.3-4 2-6.1.7-2 1.4-4 2.2-6 1.5-4 3.2-8 5.3-12l5.3-10.2c6.7-12.9 22.6-18 35.5-11.3 10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.6-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9 49.202c-1.6 8.7-9.9 14.4-18.5 12.8-7.1-1.3-12.2-7.1-13-13.9l-.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1163.79 870.299c10.2-2 20.4-4.5 30.8-8.3l3.3-1.2c6.4-2.3 12.1-6.7 16.1-12.9 9.6-15 5.2-35-9.8-44.6-8.9-5.7-18-10.4-27.1-14.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h80.1l-.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1160.59 1033-.3-2.6c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.503-1-12.703l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.3 4.6-49.7 1.7-8.2 3.7-16.3 6.2-24.4M1213.09 809.199c10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.6-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9 49.201"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1213.09 809.198c-8.9-5.7-26.9-16.3-36-20.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h71.1"
            />
            <path
                fill="#FCB47F"
                d="M1159.69 1030.6c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.1 3c4.3.6 8.5 1.9 12.4 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1159.69 1030.6c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.1 3c4.3.6 8.5 1.9 12.4 3.8 4.1 2 10.8 4.6 19.9 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38-36.2-38-36.2"
            />
            <path
                fill="#EF0025"
                d="M1242.79 1077.1c-2.8-1.5-8.1-4.4-12.5-6.5-7.4-3.6-14.9-10.1-21.2-16.5-2.2-2.2-4.2-4.4-6-6.6-3.4-.4-14.4 4.4-20.2 4.2-11.5-.4-19.6-5.6-26.6-5.8-.8 4.4-1.9 9.5-3.1 15.4-.6 2.7 0 5.5 1.6 7.8s4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5 2.7 0 5-2.1 5.1-4.9.2-2-.9-3.9-2.6-4.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1154.79 1069c1.6 2.3 4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.1 2 10.9 4.7 20.1 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5M1207.49 827.098h15.5"
            />
            <path
                fill="#FCB47F"
                d="M1060.29 462.999c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3.8 11.6.8 12.4l-8.6 6.2-46.695-18 2.6-11.6.4-13s-1.1-8.5-9.1-24c-10.1-19.5 3.1-37.8 3.1-37.8s2.8-11.1 18.495-18c22.3-9.6 40.9-2.7 56.6 13Z"
            />
            <path
                fill="url(#h)"
                d="M1067.09 487.3c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.7.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.6-18.1-.8-37.2 1.1-55.7Z"
            />
            <path
                fill="url(#i)"
                d="M1043.5 564.599c-.2-3.7-.4-7.4-.5-11.1-14.5 1.2-30.2-11.6-36.6-18.4-.9-1 2.2 13.8 5.3 24.1 7.2 3.3 22.7 8.7 31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1006.3 534.299c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.104-12.6-69.004-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 3.9 8.6 1.8 21.1-.7 30"
            />
            <path
                fill="#FCB47F"
                d="M1053.89 492.5c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1053.89 492.5c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="url(#j)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1068.39 488.998c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#k)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1046.09 486.898c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1062.99 504.198c.78 0 1.4-1.03 1.4-2.3 0-1.271-.62-2.3-1.4-2.3-.77 0-1.4 1.029-1.4 2.3 0 1.27.63 2.3 1.4 2.3ZM1037.39 504.198c.78 0 1.4-1.03 1.4-2.3 0-1.271-.62-2.3-1.4-2.3-.77 0-1.4 1.029-1.4 2.3 0 1.27.63 2.3 1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1058.39 528.298c-7.6 1.2-15.4.5-22.7-1.9M1005.19 501.298c-1.9-1.6-3.8-3.3-6.195-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.495 19.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1074.79 710.896c-5-7.4-9.2-15-13.3-22.6-4.1-7.7-7.8-15.5-11.4-23.3-3.6-7.8-6.8-15.8-9.3-24-2.6-8.2-5-16.4-7.9-24.5"
            />
            <path
                fill="#FCB47F"
                d="M948.694 781.598c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.196 15.6 42.096 16.4 55.996 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                fill="url(#l)"
                d="M948.694 781.598c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.196 15.6 42.096 16.4 55.996 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1095.3 625.699c3.9 8.6 6.8 16.6 8.6 23.1 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103M948.695 781.598c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-19.4c-.8-24 9.7-36.9 21.7-45.6 12.4-9 23-15.5 41.3-23.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1095.9 678.798c-3.6-6.2-7.7-12.9-11.1-16.9M986.695 679.998c31.105 3.8 56.505-1.2 71.705-22.1M1086.8 757.497c3.6-1.3 7.1-1.4 10.3 0"
            />
            <path
                fill="#001489"
                d="M1163.79 781.297c-17.9-8.6-38.9-14.1-60.3-16.6-26.9-4.1-52.7 9.5-61.9 35.5-11.2 32.4 9.1 65 42.3 71.7h41.7s7.5-23.1 17.6-47.7c12.7-31.2 20.6-42.9 20.6-42.9Z"
            />
            <path
                fill="url(#m)"
                d="M1119.6 737.498c-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-5.9-21.5-25.4-62.9-52.5-82.8-.8-.6-1.9-1.4-3.1-2.2-.2-.2-.5-.3-.7-.5-1.9-1.3-4-2.6-5.3-3.4.1 1.4.3 4.8.3 4.8s0 .3.1.8c.6 3.5 2.7 18.2 1.4 23.1l-6.1-4.7-18.4-14.1c-19.9-8.3-32.305-21.7-32.305-21.7s-10.2 3.3-24.4 11.2c-.1 0-.1.1-.2.1-.9.5-1.9 1.1-2.8 1.6-1 .6-2.1 1.2-3.1 1.8-4.7 2.8-9.3 5.9-13.8 9.2-1 .7-1.9 1.4-2.9 2.1-.8.6-1.6 1.2-2.3 1.8l-.1.1c-12 9.4-18.8 24.5-18.5 40v.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5h.3v.2l.3-.2 144.605-9.8c5.6-3.8 11.8-8.6 17.8-13.3 5.4-4.3 10.7-8.6 15-12.1 2.3-1.9 4.5-3.5 6.2-4.7.2-12.1-.2-22.5-.9-30.8Z"
            />
            <path
                fill="#FCB47F"
                d="M1133.69 1013.8c-5.3-6.9-10.5-13.904-15.5-20.904-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-2.9-8-5.5-16.1-7.7-24.5-.6-2.1-1-4.2-1.5-6.3-.4-2.1-.9-4.2-1.3-6.4-.8-4.3-1.5-8.7-1.8-13.2l-.9-11.3c-1.2-14.5 9.7-27.2 24.2-28.4 11.8-.9 22.4 6.1 26.6 16.5l19 47.3 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.604c3.2 8.2-.9 17.4-9.1 20.6-6.7 2.6-14.2.3-18.4-5.1l-1.8-2Z"
            />
            <path
                fill="url(#n)"
                d="M1051.49 870.197c2.5-.4 3.1-.7 5.6-1.1 11.8-2.1 22.9-9.1 29.6-20.4 11.5-19.4 5.6-44.5-13-57.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7c-3.098-.3-6.298-.4-9.498-.2-28.2 1.6-51.1 24.4-52.8 52.6-1 17.7 6.1 33.7 17.9 44.6 8 7.4 18.6 11.4 29.5 11.4h66.598l-3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1135.19 1015.8-1.5-2c-5.3-6.9-10.5-13.902-15.5-20.902-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-1.6-4.4-3.1-8.8-4.5-13.2-1.2-3.7-2.2-7.5-3.2-11.2-.6-2.1-1-4.2-1.5-6.3M1091.79 818.699l14.9 39.2 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.601M940.492 814.797c-1.1 18.2 6.5 34.6 18.9 45.6 7.8 6.9 18 10.5 28.4 10.5h54.598M1091.79 818.698c-2.2-10.8-8.4-20.7-18-27.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M1133.7 1013.8c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.9-3.8 7.2-8.2-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.1-33.4-23.3-50.1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1133.7 1013.8c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.9-3.8 7.2-8.2-1.5-9.4-6-18.4-10.4-27.2-8.2-16.7-15.1-33.4-23.3-50.1M1068.99 814.098h15.5"
            />
            <path
                fill="url(#o)"
                d="M1054.3 777.797c-9.1-4.8-18.8-8.8-28.5-11.8-8.5-2.7-15.2-3.8-22.9-4.5-54.105-6.6-85.405 62.3-45.105 98.8 8.1 7.5 18.8 11.7 30.1 11.7h23.205s-28.805-24.2-10.4-68.7c14.4-34.9 53.6-25.5 53.6-25.5Z"
            />
            <path
                fill="url(#p)"
                d="M1152.39 1083.9c-4.7-4-9.2-12-10.8-19.2-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.2-4.3-.9-8.4-1.7-12.4 8.4-5.4 8.4-9.3 16.8-11.9 8.8-2.7 12.7 1.9 20.1 3.3 3.7 8.2 7.5 16.7 11.6 25l.5 1c4.2 8.5 8.5 17.2 9.9 26.4.4 2.3.9 6.9.7 11.1-.3 11.2-13.6 22.2-45.5-4.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1046.99 592.297c1.8 1.8 7.3 12.1 13 23.6M988.293 548.098c7 8.1 22.297 18.2 32.197 21.6l24.6 19 8-19.5 7.3 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1020.59 569.699-9.5 13.1-20.899-22.6"
            />
            <path
                fill="#FCB47F"
                d="m1184.61 547.355-1.3-1.2c.7-2.5-1.1-5.1-3-6.8-5.2-4.9-12.6-7.3-19.6-6.5-5.4.6-10.5 3-15.3 5.6-7.1 3.9-13.9 8.3-20.3 13.3-11.2 7.3-4.7 21.1 12.4 23.2 11.6 1.4 24.1-11.2 28.7-18.2l3.2.2c2.2.3 4.4.5 6.4 1.6 1.9 1 3.5 3 3.5 5.2 0 1.2-.5 2.3-.8 3.4-.2.8-.2 1.6 0 2.3.2.7.9 1.1 1.6 1.1 1.3-.1 2.5-.8 3.4-1.7.6-.6 1-1.3 1.3-2.1l.9.2c1 .3 2.2-.2 2.9-1 .7-.8 1.1-1.8 1.4-2.8 1.3-5.8-1.3-11.8-5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1137.21 574.357c12.8 2.4 27.4-13.9 30.3-19.9M1125.11 551.657c6.4-5 13.2-9.401 20.3-13.301 4.8-2.6 9.8-4.999 15.3-5.599 7.1-.8 14.4 1.7 19.6 6.5 1.9 1.8 3.6 4.299 3 6.799"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1169.21 540.055c5.6 1.3 11.2 3.4 15.4 7.4 4.2 4 6.7 10 5.3 15.6-.3 1-.7 2.099-1.4 2.799-.7.8-1.9 1.2-2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1172.21 547.156c4 .3 7.9 2.4 10.4 5.6 2.5 3.2 3.5 7.5 2.8 11.5-.3 1.7-.9 3.3-2.1 4.5-.9 1-2.1 1.6-3.4 1.7-.7.1-1.4-.4-1.6-1.1-.2-.7-.2-1.5 0-2.3.3-1.1.8-2.2.8-3.4.1-2.2-1.5-4.2-3.5-5.2-1.9-1-4.2-1.3-6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M956.906 578.858c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.304 3.8 8.4 1.7 16.7 3.7 25.1 5.9 8.3 2.3 16.6 4.8 24.8 8 12 4.7 17.9 18.2 13.2 30.1-3.1 8.1-10.3 13.4-18.3 14.6-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.604-.1-17.104-.7-25.504-2-8.4-1.2-16.9-2.6-25.3-3.5-17-1.9-29.3-17.2-27.4-34.2 1.9-17 17.2-29.3 34.2-27.4h.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1040.5 601.657 9.6-6.3 9.5-6.4c6.4-4.2 12.9-8.3 19.5-12.1 3.3-1.9 6.6-3.9 10-5.8 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.499 10.4-5.199 7.9-3.7 17.2-.301 20.9 7.599 2.9 6.2 1.4 13.3-3.1 17.8-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.801-9.2 6.799c-10 7.4-24.1 5.3-31.5-4.7-7.4-10-5.3-24.1 4.7-31.5.1-.2.5-.5.8-.7Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1007.21 641.456c6.07 0 11-3.94 11-8.8 0-4.86-4.93-8.801-11-8.801-6.08 0-11.003 3.941-11.003 8.801s4.923 8.8 11.003 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1007.21 634.558c1.27 0 2.3-.851 2.3-1.9 0-1.05-1.03-1.9-2.3-1.9-1.27 0-2.3.85-2.3 1.9 0 1.049 1.03 1.9 2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M956.707 578.859h.2c8.5 1 17 1.501 25.5 2.301 8.5.7 16.9 1.999 25.303 3.799 8.4 1.7 16.7 3.7 25.1 5.9 4.6 1.3 9.2 2.6 13.7 4.1M1066.31 638.559c-3.2 2.5-9.8 4.3-13.8 5-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.399-25.8.199-8.604-.1-17.104-.7-25.504-2-8.4-1.2-16.9-2.6-25.3-3.5M1050.01 595.357l9.5-6.399c6.4-4.2 12.9-8.301 19.5-12.101 3.3-1.9 6.6-3.899 10-5.799 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.5 10.4-5.2M1137.4 580.258c-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.799-9.2 6.801"
            />
            <path
                fill="url(#q)"
                d="M980.705 639.755c.9-1.7 3-2.2 4.7-1.1l60.705 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.9 1.7-3 2.2-4.7 1.1l-60.705-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.3Z"
            />
            <path
                fill="url(#r)"
                d="m1050.2 673.654-60.695-37.899c-1.3-.8-2.7-.7-3.7 0l-4 2.899c1-.7 2.5-.8 3.7 0l60.695 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.2.5-.6.8-.9 1.1l4-2.9c.4-.3.7-.6.9-1.1l16-31.3c.8-1.6.1-3.9-1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1027.11 714.052-60.703-37.899c-1.7-1.1-2.4-3.3-1.6-5l16-31.301c.9-1.7 3-2.199 4.7-1.099l60.703 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1030.81 714.052 4-2.9c.4-.3.7-.6.9-1.1l16-31.299c.9-1.7.2-3.9-1.6-5l-60.703-37.901c-1.3-.8-2.7-.7-3.7 0l-4 2.901c1-.7 2.5-.8 3.7 0l60.703 37.899c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                fill="#231F20"
                d="M971.006 673.954a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M974.706 667.255a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M997.673 674.932c2.2-1.27 2.777-4.394 1.283-6.977-1.491-2.582-4.483-3.646-6.683-2.376s-2.775 4.394-1.284 6.976c1.491 2.583 4.484 3.647 6.684 2.377Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M977.307 677.856a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1143.31 698.053-8.6.299c-2.9.1-5.8.201-8.6.301-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.601-17.4-1.101-5.8-.5-11.6-1.1-17.4-2-8.7-1.3-14.6-9.3-13.4-18 .9-6.4 5.6-11.299 11.4-12.899 5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.501 8.6-.801l8.6-.799c12.4-1.2 23.4 7.8 24.6 20.2 1.2 12.4-7.8 23.399-20.2 24.599-.6.2-1.2.201-1.7.201Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1152.81 654.555c5.5 5 11.6 11.799 12.3 18.799 1.2 12.4-7.8 23.401-20.2 24.601-.5 0-1 .1-1.5.1l-8.6.299c-2.9.1-5.8.201-8.6.301-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.601-17.4-1.101-5.8-.5-11.6-1.1-17.4-2M1071.91 664.552c5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.5 8.6-.8l8.6-.8"
            />
            <path
                fill="#FCB47F"
                d="M1013.21 710.152c.3-.6.6-1.3.9-1.9l-.8-.9c-.9-1-1.3-2.3-1.3-3.6.1-3.7.9-8.1 2.1-11.2l-1.5-.9c-3.5-1.7-6.4-5-6.7-8.8-.3-4.2 2.4-8 5.4-11 5.8-5.8 12.7-13.3 20.9-12.5 10.6 1 21.1 2.7 31.5 5.1 19.5 4.1 23.7 19.4 11.3 30.2-5.5 5-14.3 6.1-20.9 2.7-5.6 5.2-13.3 13.1-16.8 20.7-.8 1.8-2.8 2.7-4.6 2h-.1c-2-.7-3-2.8-2.3-4.7 3.3-9.6 11.1-19.7 17.3-27.1-9.1 7.7-20.2 17.6-29.5 25.6-1.2 1-2.9 1-4.1 0-1.1-1-1.4-2.5-.8-3.7Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1067.31 665.352c-10.4-2.3-24.5-5-35.1-6-8.2-.8-15.1 6.7-20.9 12.5-3 3-5.7 6.8-5.4 11 .3 3.8 3.2 7.1 6.7 8.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1032.91 672.852c-5.9 4-11.9 8.2-15.9 14.1-3.4 5-5.1 11.2-4.9 17.2M1037.61 682.152c-4.8 3.9-9.6 7.7-13.7 12.2-3.6 3.9-8.3 10.3-10.7 15.8-.5 1.2-.2 2.7.8 3.6 1.2 1 2.9 1 4.1 0 9.3-8 20.4-17.9 29.5-25.6-6.3 7.4-14 17.5-17.3 27.1-.7 1.9.4 4.1 2.3 4.7h.1c1.8.6 3.8-.3 4.6-2.1 3.4-7.5 11.1-15.4 16.8-20.6 6.6 3.4 15.5 2.3 20.9-2.7M1023.21 676.754c-1.7 1.1-3.4 2.1-5.2 3.2-.7.5-1.5 1-1.8 1.8-.2.6 0 1.3.3 1.8.3.6.8 1 1.2 1.5"
            />
            <path
                fill="url(#s)"
                d="M982.404 580.154c-2.6-.2-5.1-.4-7.7-.6-5.8-.5-11.9-.9-17.8-1.6h-.1c-1.2-.1-2.4-.2-3.6-.2-16.3 0-30 12.2-31.8 28.5-.9 8.5 1.5 16.8 6.8 23.5 5.3 6.7 13 10.9 21.5 11.8 5.9.7 11.9 1.5 17.7 2.4 2.5.4 5.1.7 7.6 1.1 1 .1 2.1.3 3.1.4l4.7-65.2c-.1-.1-.2-.1-.4-.1Z"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <path
                fill="#5BC2E7"
                d="M1470.29 613.141h19.55c.83 0 1.51.674 1.51 1.506v8.561h-22.56v-8.561c0-.832.67-1.506 1.5-1.506Z"
            />
            <path
                fill="url(#t)"
                d="M1459.78 641.176a35.094 35.094 0 0 0-7.52 21.708v34.868c0 .929.35 1.826.98 2.511 4.65 5.069 4.65 12.909 0 17.978a3.712 3.712 0 0 0-.98 2.512V769c0 5.178 4.2 9.375 9.38 9.375h36.85c5.18 0 9.38-4.197 9.38-9.375v-48.247c0-.93-.35-1.827-.98-2.512-4.65-5.068-4.65-12.909 0-17.978.63-.684.98-1.581.98-2.511v-34.868c0-7.909-2.68-15.547-7.52-21.708h-40.57Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1470.29 613.141h19.55c.83 0 1.51.674 1.51 1.506v8.561h-22.56v-8.561c0-.832.67-1.506 1.5-1.506Z"
            />
            <path fill="#5BC2E7" d="M1507.87 662.887h-55.61v29.194h55.61v-29.194Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1464.77 764.32h30.6M1464.77 752.242h30.6M1464.77 740.164h30.6M1464.77 728.086h30.6M1507.87 737.03v-16.279c0-.929-.35-1.826-.98-2.512-4.65-5.068-4.65-12.908 0-17.977.63-.684.98-1.581.98-2.511v-34.868c0-9.115-3.55-17.873-9.89-24.422l-7.81-8.064a3.73 3.73 0 0 1-1.05-2.609v-4.581h-18.11v4.581c0 .974-.37 1.91-1.05 2.609l-2.52 2.603M1459.78 641.168a35.105 35.105 0 0 0-7.52 21.711v34.868c0 .929.35 1.826.98 2.512 4.65 5.068 4.65 12.908 0 17.976a3.712 3.712 0 0 0-.98 2.512v48.247c0 5.178 4.2 9.376 9.38 9.376h36.85c5.18 0 9.38-4.198 9.38-9.376v-12.563"
            />
            <path
                fill="url(#u)"
                d="M1732.51 671.699h-41.57l-12.12 20.255h41.57l12.12-20.255Z"
            />
            <path
                fill="#D2F0FA"
                d="M1720.39 691.949h-41.57v86.237h41.57v-86.237ZM1732.51 663h-41.57v8.698h41.57V663Z"
            />
            <path
                fill="#5BC2E7"
                d="m1744.63 691.954-12.12-20.255-12.12 20.255v86.236h24.24v-86.236Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1709.96 691.949h-31.14v86.237h41.57M1744.62 744.268v-52.314l-12.11-20.255M1720.39 778.19h24.24v-14.538M1690.94 671.699l-12.12 20.255M1732.51 671.698V663h-41.57v8.698"
            />
            <defs>
                <linearGradient
                    id="b"
                    x1={1059.39}
                    x2={1059.39}
                    y1={914.955}
                    y2={1194.37}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.41} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1147}
                    x2={971.78}
                    y1={888.191}
                    y2={888.191}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.287} stopColor="#FFF4C3" />
                    <stop offset={0.331} stopColor="#FFEC96" />
                    <stop offset={0.433} stopColor="#FFD828" />
                    <stop offset={0.468} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1241.52}
                    x2={1404.78}
                    y1={763.479}
                    y2={759.981}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1681.03}
                    x2={1681.03}
                    y1={1165.38}
                    y2={751.933}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.698} stopColor="#FFEA8B" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1041.71}
                    x2={1083.01}
                    y1={582.246}
                    y2={582.246}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={1027.61}
                    x2={1188.29}
                    y1={619.874}
                    y2={619.874}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={1060.47}
                    x2={1060.47}
                    y1={553.666}
                    y2={486.47}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1019.12}
                    x2={1029.36}
                    y1={559.554}
                    y2={542.115}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1070.88}
                    x2={1057.09}
                    y1={487.969}
                    y2={487.969}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={1048.57}
                    x2={1026.24}
                    y1={488.183}
                    y2={488.183}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={920.201}
                    x2={1123.19}
                    y1={678.087}
                    y2={678.087}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={1053.57}
                    x2={848.125}
                    y1={835.483}
                    y2={767.878}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={953.461}
                    x2={1023.98}
                    y1={892.711}
                    y2={729.887}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={1138.3}
                    x2={1198.07}
                    y1={1060.73}
                    y2={1060.73}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={964.383}
                    x2={1048.08}
                    y1={676.35}
                    y2={676.35}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={981.667}
                    x2={1052.11}
                    y1={674.636}
                    y2={674.636}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={994.448}
                    x2={925.051}
                    y1={506.178}
                    y2={690.081}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={1535.58}
                    x2={1458.52}
                    y1={864.985}
                    y2={654.947}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={1705.66}
                    x2={1705.66}
                    y1={688.226}
                    y2={669.753}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2F0FA" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <radialGradient
                    id="l"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(961.076 818.205) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <clipPath id="a">
                    <path fill="#fff" d="M682.102 223H323.513v358.589h358.589z" />
                </clipPath>
            </defs>
        </svg>
    );
}
