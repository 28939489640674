import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6796)"
            d="M494.768 222.603a109.645 109.645 0 00-20.026-15.33 31.037 31.037 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6796)"
            d="M461.422 278.748a.997.997 0 01-.749-.337 1.018 1.018 0 01-.203-.356l-.208-.639c-.284-.876-.569-1.753-.831-2.637-.619-2.07-1.224-4.147-1.83-6.224-1.379-4.732-2.805-9.626-4.395-14.366a27.304 27.304 0 011.453-20.998 27.327 27.327 0 0115.874-13.82 27.321 27.321 0 0120.997 1.453 27.315 27.315 0 0113.82 15.875l.046.136.002.006c1.564 4.751 3.351 9.527 5.08 14.147.714 1.907 1.423 3.816 2.129 5.727.185.503.262.668.344.912l-51.149 21.045a.995.995 0 01-.38.076z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6796"
                x1="491.79"
                x2="433.091"
                y1="213.352"
                y2="250.075"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6796"
                x1="502.88"
                x2="444.181"
                y1="231.078"
                y2="267.801"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
