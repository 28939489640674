import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-2-walk-1">
            <path
                fill="#2B2729"
                d="M414.406 848.632c-.349 0-.698-.036-1.04-.106-7.995-1.651-21.978-4.636-25.793-6.075a70.716 70.716 0 0 1-18.451-10.137 41.167 41.167 0 0 0-11.056-6.097l-19.067-7.051a9.914 9.914 0 0 1-5.737-5.468 9.905 9.905 0 0 1 .043-7.917c6.863-15.676 11.057-26.032 11.81-29.16l2.103-10.846 1.403-.716 30.939 14.385.529 1.219-2.435 7.42c1.483 3.371 13.804 30.637 29.206 42.57 3.795 2.94 8.445 6.832 10.878 8.891a5.168 5.168 0 0 1 1.645 5.276 5.169 5.169 0 0 1-4.977 3.812Z"
            />
            <path
                fill="#004F71"
                d="M413.618 848.82a4.4 4.4 0 0 1-.296-.056c-8.045-1.757-22.115-4.931-25.951-6.438a70.815 70.815 0 0 1-18.489-10.524 41.148 41.148 0 0 0-11.078-6.329l-19.151-7.377a9.992 9.992 0 0 1-5.719-5.639 10.225 10.225 0 0 1 .146-8.088c1.885-4.219 3.708-8.358 5.27-11.97a1.01 1.01 0 0 1 1.256-.559 65.324 65.324 0 0 1 10.914 5.436c.612.353 1.221.706 1.832 1.054 5.518 3.148 11.47 5.971 17.582 5.303a33.016 33.016 0 0 0 6.934-1.803c1.135-.382 2.308-.777 3.487-1.109a24.364 24.364 0 0 1 3.33-.693 1.004 1.004 0 0 1 1.019.526c5.078 9.56 13.067 22.505 22.284 29.89 3.792 3.039 8.433 7.058 10.863 9.183a5.304 5.304 0 0 1 1.591 5.405 5.236 5.236 0 0 1-5.824 3.788Z"
            />
            <path
                fill="#2B2729"
                d="M444.701 860.229a9.91 9.91 0 0 1-7.277-3.139 9.877 9.877 0 0 1-2.129-3.438 9.874 9.874 0 0 1-.576-4.002c.999-17.083 1.341-28.251.962-31.446l.993-1.118h32.515l.74.326c.151.167 15.274 16.703 23.563 22.284 4.065 2.737 22.163 8.767 27.613 10.55a5.103 5.103 0 0 1 3.522 4.863 5.126 5.126 0 0 1-5.119 5.119l-74.807.001Z"
            />
            <path
                fill="url(#man-34-25-shoes-2-walk-1-a)"
                d="M524.791 858.647a4.655 4.655 0 0 1-.289.066c-7.963 1.573-79.567 1.556-79.567 1.556a9.863 9.863 0 0 1-7.392-2.872 10.184 10.184 0 0 1-3.062-7.41c.038-4.579.051-9.061.036-12.96a.994.994 0 0 1 .915-1.004 64.126 64.126 0 0 1 12.006.64c.692.08 1.382.16 2.071.236 6.228.687 12.72.906 17.978-2.11a32.525 32.525 0 0 0 5.553-4.373c.874-.794 1.778-1.616 2.712-2.382a23.795 23.795 0 0 1 2.735-1.943.987.987 0 0 1 1.128.076c8.362 6.689 20.69 15.308 31.932 18.389 4.626 1.267 10.406 3.091 13.44 4.066a5.264 5.264 0 0 1 3.571 4.286 5.168 5.168 0 0 1-3.767 5.739Z"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-2-walk-1-a"
                    x1="434.481"
                    x2="528.608"
                    y1="843.113"
                    y2="843.113"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".001" stopColor="#004F71" />
                    <stop offset=".125" stopColor="#01567A" />
                    <stop offset="1" stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
