import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6616)"
            d="M420.723 237.342a783.117 783.117 0 00-4.822-3.06c-4.253-2.689-8.65-5.469-12.856-8.364l-.119-.082a27.5 27.5 0 10-30.944 45.47c4.203 2.86 8.37 5.943 12.4 8.924l1.777 1.314 35.508-43.598-.944-.604z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6616"
                x1="362.717"
                x2="412.162"
                y1="242.196"
                y2="259.257"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
