import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="female-bold-34-25-nose-2" transform="translate(316, 30)">
        <path
            d="M151.901 125.103c3.2.6 9.8.8 9.8-5.5 0-4.3-6.5-6.8-8-10.4"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M151.901 125.103c3.2.6 9.8.8 9.8-5.5 0-4.3-6.5-6.8-8-10.4"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
