import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_7991)"
            d="M461.08 200.344l2.525 1.254c12.653 6.286 22.846 13.297 31.163 21.433a2.707 2.707 0 01.822 2.482c-.805 4.667-13.808 10.893-16.406 12.093l-.913.422-17.191-37.684z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_7991)"
            d="M487.112 285.644c-4.115-2.716-9.052-6.427-13.826-10.017-3.593-2.7-6.986-5.252-9.628-7.069a27.333 27.333 0 01-7.497-7.68 27.328 27.328 0 01-3.959-9.976 27.305 27.305 0 014.384-20.586 27.518 27.518 0 0122.681-11.918 27.353 27.353 0 0115.562 4.845l.116.081c4.247 2.955 8.687 5.795 12.98 8.542 1.891 1.21 3.78 2.424 5.665 3.643a157.372 157.372 0 019.197 6.42l1.15.866-1.212.776c-13.208 8.447-24.142 25.262-34.208 41.76l-.54.884-.865-.571z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_7991"
                x1="478.358"
                x2="439.82"
                y1="200.344"
                y2="252.064"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_7991"
                x1="485.507"
                x2="491.803"
                y1="203"
                y2="315.722"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
