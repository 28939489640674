import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6891)"
            d="M494.78 223.22a2.707 2.707 0 01.822 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.988-5.356-31.35a108.178 108.178 0 0121.852 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6891)"
            d="M511.15 245.484c-2.547-4.043-5.433-8.626-8.066-13.178l-.071-.122a27.478 27.478 0 00-16.772-12.719 27.497 27.497 0 00-20.852 2.876 27.5 27.5 0 00-9.844 37.624c2.656 4.538 5.186 9.26 7.633 13.828l.51.95a91.19 91.19 0 0121.386-17.957 93.173 93.173 0 0126.624-10.431l-.548-.871z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6891"
                x1="494.171"
                x2="415.81"
                y1="212.274"
                y2="256.514"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6891"
                x1="502.535"
                x2="424.173"
                y1="227.088"
                y2="271.329"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
