import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 166,
        height: 198,
        content: (
            <>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.805 71.53h126.828M141.979 60.916l10.579-33.554c.813-2.578-1.112-5.203-3.815-5.203H75.121V1.842"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M148.258 41h7.93c5.406 0 9.255 5.25 7.63 10.406l-12.383 39.276"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M92.25 117.084a22.487 22.487 0 0 1 21.341 15.397l21.15 63.633H15.501l14.883-44.792a22.49 22.49 0 0 1 21.341-15.397H88.84c17.213 0 32.459-11.111 37.73-27.498l21.689-67.429H1.984l21.69 67.43a39.58 39.58 0 0 0 13.851 19.498M92.25 117.084H62.316"
                />
            </>
        ),
    },
    'Color #1': {
        width: 165,
        height: 157,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-2-a)"
                    d="m10.824 31.531 11.87 36.9c5.27 16.386 20.515 27.496 37.73 27.496h27.435c17.213 0 32.459-11.11 37.73-27.497l11.869-36.899H10.824Z"
                />
                <path
                    fill="url(#object-kitchen-blender-2-b)"
                    d="M97.537 95.93H50.745a22.488 22.488 0 0 0-21.34 15.397h89.474a22.49 22.49 0 0 0-21.34-15.397h-.002Z"
                />
                <path
                    fill="#888B8D"
                    d="m133.763 156.12-14.884-44.792H29.403L14.52 156.12h119.243Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M22.693 68.431c5.27 16.387 20.516 27.498 37.73 27.498h27.436c17.213 0 32.459-11.111 37.73-27.498l21.689-67.429H1.004L18.097 54.14M123.206 31.531H10.824M147.278 1.002v.01l-6.28 19.91"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M147.277 1.002h7.93c5.406 0 9.255 5.25 7.63 10.406l-12.383 39.276"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-2-a"
                        x1={136.876}
                        x2={-19.25}
                        y1={86.178}
                        y2={4.325}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-2-b"
                        x1={142.97}
                        x2={31.56}
                        y1={145.841}
                        y2={84.452}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 165,
        height: 157,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-2-a)"
                    d="m10.863 31.535 11.87 36.9c5.27 16.386 20.515 27.496 37.73 27.496h27.435c17.213 0 32.459-11.11 37.73-27.497l11.869-36.899H10.863Z"
                />
                <path
                    fill="url(#object-kitchen-blender-2-b)"
                    d="M97.576 95.93H50.784a22.488 22.488 0 0 0-21.34 15.397h89.474a22.49 22.49 0 0 0-21.34-15.397h-.002Z"
                />
                <path
                    fill="#888B8D"
                    d="m133.802 156.12-14.884-44.792H29.443L14.559 156.12h119.243Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M22.732 68.435c5.271 16.387 20.516 27.498 37.73 27.498h27.436c17.213 0 32.459-11.111 37.73-27.498l21.689-67.43H1.043l17.093 53.14M123.245 31.535H10.863M147.317 1.006v.01l-6.28 19.91"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M147.316 1.006h7.93c5.406 0 9.255 5.25 7.63 10.406l-12.383 39.276"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-2-a"
                        x1={136.915}
                        x2={-19.211}
                        y1={86.182}
                        y2={4.329}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-2-b"
                        x1={143.009}
                        x2={31.599}
                        y1={-39.154}
                        y2={-39.154}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 165,
        height: 157,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-blender-2-a)"
                    d="m10.902 31.531 11.87 36.9c5.27 16.386 20.515 27.496 37.73 27.496h27.435c17.213 0 32.459-11.11 37.73-27.497l11.869-36.899H10.902Z"
                />
                <path
                    fill="url(#object-kitchen-blender-2-b)"
                    d="M97.614 95.93H50.82a22.488 22.488 0 0 0-21.34 15.397h89.474a22.49 22.49 0 0 0-21.34-15.397h-.001Z"
                />
                <path
                    fill="#888B8D"
                    d="m133.841 156.12-14.884-44.792H29.482L14.598 156.12H133.84Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M22.771 68.431c5.271 16.387 20.516 27.498 37.73 27.498h27.436c17.213 0 32.459-11.111 37.73-27.498l21.689-67.429H1.082L18.175 54.14M123.284 31.531H10.902M147.356 1.002v.01l-6.28 19.91"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M147.355 1.002h7.93c5.406 0 9.255 5.25 7.63 10.406l-12.383 39.276"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-blender-2-a"
                        x1={136.954}
                        x2={-19.172}
                        y1={86.178}
                        y2={4.325}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-blender-2-b"
                        x1={143.047}
                        x2={31.637}
                        y1={145.841}
                        y2={84.452}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-kitchen-blender-2"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
