import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={5}>
        <path
            d="M503.981 236.115c2.853 7.349 6.088 14.548 9.158 21.812a182.091 182.091 0 0 1 7.807 22.34c2.193 7.607 4.192 15.289 5.921 23.077a226.641 226.641 0 0 1 4.112 23.785 19.157 19.157 0 0 1-12.068 19.936 19.164 19.164 0 0 1-22.391-6.462 226.814 226.814 0 0 1-13.112-20.265c-4.013-6.895-7.755-13.896-11.303-20.973a182.205 182.205 0 0 1-9.419-21.71c-2.671-7.42-5.177-14.904-8.065-22.238a26.506 26.506 0 0 1 .334-20.296 26.507 26.507 0 0 1 34.892-13.744 26.491 26.491 0 0 1 14.086 14.616l.048.122Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M511.495 314.272c4.683-.439 9.335-1.211 14-1.839a82.443 82.443 0 0 1 14.116-.649c2.374.11 4.737.118 7.122.336l3.57.264c1.19.092 2.388.253 3.581.376 4.862.495 9.682 1.337 14.424 2.521a12.794 12.794 0 0 1 9.494 11.134 12.79 12.79 0 0 1-7.173 12.754 102.287 102.287 0 0 1-13.668 5.252c-1.147.35-2.292.738-3.442 1.058l-3.452.946c-2.298.674-4.616 1.136-6.924 1.701a82.45 82.45 0 0 1-13.977 2.082c-4.699.282-9.411.42-14.092.891a18.505 18.505 0 0 1-18.148-9.653 18.486 18.486 0 0 1-1.418-14.088 18.505 18.505 0 0 1 15.863-13.074l.124-.012Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M527.663 307.05a381.907 381.907 0 0 0-.795-3.711c-1.729-7.788-3.727-15.47-5.921-23.077a182.086 182.086 0 0 0-7.806-22.34c-3.07-7.264-9.206-21.934-9.206-21.934-1.71-5.536-6.89-9.957-6.89-9.957M496.521 340.601a226.746 226.746 0 0 1-13.113-20.265c-4.012-6.896-7.755-13.897-11.303-20.973a182.2 182.2 0 0 1-9.418-21.71c-2.671-7.42-5.177-14.904-8.066-22.239M570.629 339.172a102.34 102.34 0 0 1-13.668 5.252c-1.148.35-2.292.738-3.442 1.058l-3.453.946c-2.297.674-4.615 1.136-6.923 1.701a82.45 82.45 0 0 1-13.977 2.082c-4.699.282-9.412.42-14.092.891a18.476 18.476 0 0 1-16.08-6.58M525.494 312.433c4.678-.62 9.4-.837 14.115-.649 2.374.11 4.738.118 7.122.336l3.57.264c1.19.092 2.388.253 3.581.376 4.862.495 9.682 1.337 14.424 2.521"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M581.247 275.4c.26.516.512 1.059.755 1.622l1.021-.027a4.406 4.406 0 0 1 3.056 1.124 31.927 31.927 0 0 1 6.194 7.468l1.402-.467c2.986-1.354 6.71-1.476 9.386.418 2.92 2.067 3.932 5.919 4.246 9.483.612 6.954 1.781 15.537-3.185 20.442a220.38 220.38 0 0 1-20.402 17.814c-13.308 10.5-25.563 4.943-25.897-9.035a16.169 16.169 0 0 1 9.593-15.169c-.361-6.537-1.329-15.842-4.399-22.191a3.172 3.172 0 0 1 1.163-4.117l.078-.047a3.171 3.171 0 0 1 3.531.136c.338.245.624.555.842.911 4.481 7.407 6.878 17.948 8.299 26.058-.144-10.13-.55-22.746-.728-33.205a2.635 2.635 0 0 1 2.236-2.652l.04-.006a2.65 2.65 0 0 1 2.769 1.44Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M583.719 333.278a220.38 220.38 0 0 0 20.402-17.814c4.966-4.905 3.797-13.488 3.185-20.441-.314-3.564-1.325-7.417-4.245-9.484-2.677-1.894-6.401-1.772-9.387-.418"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M594.909 308.524c.614-6.012 1.116-12.202-.58-18.003a24.686 24.686 0 0 0-8.6-12.56M586.285 306.587c.203-5.213.026-10.435-.529-15.623a54.525 54.525 0 0 0-4.508-15.562 2.648 2.648 0 0 0-2.77-1.442l-.041.006a2.636 2.636 0 0 0-2.235 2.652c.179 10.459.583 23.075.727 33.205-1.421-8.112-3.819-18.656-8.302-26.063a3.182 3.182 0 0 0-1.969-1.427 3.172 3.172 0 0 0-2.401.385l-.052.032a3.205 3.205 0 0 0-1.178 4.156c3.062 6.348 4.029 15.638 4.389 22.167a16.174 16.174 0 0 0-7.095 6.131 16.174 16.174 0 0 0-2.498 9.038M597.607 300.092l.709-5.124a3.063 3.063 0 0 0-.218-2.123 2.049 2.049 0 0 0-1.365-.809 6.049 6.049 0 0 0-1.624.006"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.069 223.565c-8.491-8.308-18.696-15.185-30.909-21.253l15.605 34.206s19.387-8.959 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.162 202.312a132.158 132.158 0 0 1 30.908 21.253"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
