import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 438,
        height: 376,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.305 32.55V1.898h435.032V32.55H23.663"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M413.98 51.388v323.057H23.664V32.547"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M413.979 203.499H42.504V51.391h371.475v152.108ZM42.504 203.5H432.82v152.108H42.504M188.695 75.39h60.253M188.695 227.5h60.253"
                />
            </>
        ),
    },
    'Color #1': {
        width: 436,
        height: 374,
        content: (
            <>
                <path fill="#AEAEAF" d="M435.52.902H.488v30.652H435.52V.902Z" />
                <path fill="url(#object-office-nightstand-a)" d="M413.164 31.553H22.848V373.45h390.316V31.553Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M138.151 50.395h256.17v152.108H41.687V50.395h19.159"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M270.024 354.61H41.687V202.502h352.634V354.61h-33.855M187.879 74.394h60.253M187.879 226.502h60.253"
                />
                <defs>
                    <linearGradient
                        id="object-office-nightstand-a"
                        x1={382.849}
                        x2={37.249}
                        y1={346.378}
                        y2={44.736}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 436,
        height: 374,
        content: (
            <>
                <path fill="#AEAEAF" d="M435.52.9H.488v30.652H435.52V.9Z" />
                <path fill="url(#object-office-nightstand-a)" d="M413.162 31.553H22.846V373.45h390.316V31.553Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M394.322 50.393H41.687V202.5h352.635V50.393ZM394.322 202.502H41.687V354.61h352.635V202.502ZM187.877 74.393h60.253M187.877 226.502h60.253"
                />
                <defs>
                    <linearGradient
                        id="object-office-nightstand-a"
                        x1={382.847}
                        x2={37.247}
                        y1={346.378}
                        y2={44.736}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.035} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 436,
        height: 374,
        content: (
            <>
                <path fill="#AEAEAF" d="M435.52.902H.488v30.652H435.52V.902Z" />
                <path fill="url(#object-office-nightstand-a)" d="M413.162 31.555H22.846v341.898h390.316V31.555Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M394.322 50.395H41.687v152.108h352.635V50.395ZM394.322 202.504H41.687v152.108h352.635V202.504ZM187.877 74.394h60.253M187.877 226.504h60.253"
                />
                <defs>
                    <linearGradient
                        id="object-office-nightstand-a"
                        x1={382.847}
                        x2={37.247}
                        y1={346.38}
                        y2={44.738}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.207} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 436,
        height: 374,
        content: (
            <>
                <path fill="#AEAEAF" d="M435.52.9H.488v30.652H435.52V.9Z" />
                <path fill="url(#object-office-nightstand-a)" d="M413.164 31.553H22.848V373.45h390.316V31.553Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M138.15 50.393h256.172V202.5H41.687V50.393h19.159M270.025 354.61H41.688V202.502h352.634V354.61h-33.856M187.879 74.393h60.253M187.879 226.502h60.253"
                />
                <defs>
                    <linearGradient
                        id="object-office-nightstand-a"
                        x1={382.849}
                        x2={37.249}
                        y1={346.378}
                        y2={44.736}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.698} stopColor="#FFEA8B" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-nightstand"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
