import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={249}
            height={164}
            fill="none"
            id="object-food-millet"
            {...attributes}
        >
            <path
                fill="#FFD100"
                d="M33.115 78.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM55.96 78.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM78.807 78.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM101.652 78.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-17.5 0 8.75 8.75 0 0 0 8.75 8.75ZM124.498 78.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM147.344 78.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM170.189 78.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM193.035 78.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM215.881 78.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM78.807 37.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM101.652 37.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-17.5 0 8.75 8.75 0 0 0 8.75 8.75ZM124.498 37.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM147.344 37.453a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM170.189 37.453a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM44.865 57.953a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM67.365 57.953a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM89.865 57.953a8.75 8.75 0 1 0 0-17.5 8.75 8.75 0 0 0 0 17.5ZM112.365 57.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM134.865 57.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM157.365 57.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM179.865 57.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM202.365 57.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM112.365 17.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75ZM134.865 17.953a8.75 8.75 0 0 0 8.75-8.75 8.75 8.75 0 1 0-8.75 8.75Z"
            />
            <path
                fill="#fff"
                d="M247.08 69.703c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.67-9.368 15.826a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.892 37.664H80.956c-15.12-7.029-32.879-20.789-47.892-37.664a159.187 159.187 0 0 1-4.905-5.728 170.406 170.406 0 0 1-8.939-11.91c-3.521-5.156-6.683-10.454-9.368-15.826-3.532-7.005-6.265-14.13-7.936-21.207H247.08Z"
            />
            <path
                fill="url(#object-food-millet-a)"
                d="M247.08 69.703c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335H247.08Z"
            />
            <path
                fill="#00B140"
                d="M239.155 90.91c-2.697 5.37-5.86 10.67-9.368 15.826H19.221c-3.52-5.156-6.682-10.454-9.367-15.826h229.301ZM220.849 118.645H28.16a158.323 158.323 0 0 0 4.905 5.728h182.879l4.905-5.728Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M203.679 136.931c19.87-18.515 37.715-43.21 43.404-67.224H1.92c9.088 38.365 49.204 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
            />
            <defs>
                <linearGradient
                    id="object-food-millet-a"
                    x1={48.949}
                    x2={247.08}
                    y1={115.871}
                    y2={115.871}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
