import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-1">
        <path
            d="m465.186 167.461 2.653.951c13.052 4.678 23.789 10.343 32.826 17.319a2.682 2.682 0 0 1 1.092 2.341c-.25 4.649-12.215 12.209-14.611 13.678l-.856.525-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-1-a)"
        />
        <path
            d="M505.254 285.237c-5.565-5.027-11.064-10.361-16.813-16.306a166.208 166.208 0 0 1-15.16-17.693 737.776 737.776 0 0 1-4.131-5.613c-3.13-4.275-6.367-8.696-9.712-12.905a31.796 31.796 0 0 1-6.74-23.546 31.798 31.798 0 0 1 11.884-21.416 31.662 31.662 0 0 1 19.89-6.949 31.84 31.84 0 0 1 25.071 12.092l.106.132c3.318 4.223 6.866 8.386 10.296 12.413 1.514 1.777 3.023 3.557 4.527 5.34a166.019 166.019 0 0 1 13.713 18.842c4.453 6.961 8.371 13.544 11.975 20.115-13.094 2.112-37.275 15.015-44.906 35.494Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-1-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-1-a"
                x1={485.676}
                y1={158.204}
                x2={467.025}
                y2={323.89}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-1-b"
                x1={508.748}
                y1={164.436}
                x2={493.104}
                y2={303.41}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
