import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m297.676 828.164 4.215 35.581 8.716.273.633-20.02"
                fill="#004F71"
            />
            <path
                d="m301.891 863.742 8.771-.023"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M360.829 856.306a36.064 36.064 0 0 0-4.866-2.008 50.337 50.337 0 0 0-2.186-.8c-2.633-.914-3.619-1.256-4.56-4.246-2.662-8.456-1.002-39.386-.894-40.76l-42.975-8.687s-4.132 14.5-5.872 21.157l-.066.256c-.825 3.143-2.073 7.894.733 13.053 4.447 8.178 16.357 26.787 16.473 26.968a21.235 21.235 0 0 0 18.054 9.96h22.782c2.811 0 6.437-1.893 7.188-4.603.436-1.578 1.766-6.38-3.811-10.29Z"
                fill="url(#female-34-25-shoes-13-walk-1-a)"
            />
            <path
                d="M364.697 866.696c-.806 2.727-4.676 4.497-7.52 4.497H334.67a21.223 21.223 0 0 1-18.054-9.96c-.116-.18-12.026-18.79-16.473-26.968-2.806-5.16-1.558-9.91-.733-13.054"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m516.795 836.211 7.776 31.482h7.85s.11-6.056.042-10.415c-.048-3.021 1.803-6.214 5.197-6.007"
                fill="#004F71"
            />
            <path
                d="M524.57 867.695h7.996"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M581.952 871.758c-3.683.61-7.435.686-11.14.225l-5.451-.676a24.358 24.358 0 0 1-15.239-8.032l-6.923-7.825a22.352 22.352 0 0 0-10.741-6.722l-5.902-1.644a13.902 13.902 0 0 1-9.993-15.72l1.893-20.001 45.564-.707 12.729 35.597a8.535 8.535 0 0 0 4.762 5.051s10.539 3.718 14.015 5.059a7.145 7.145 0 0 1 4.798 7.463c-.121 3.031-4.204 5.592-7.194 6.088l-11.178 1.844Z"
                fill="#004F71"
            />
            <path
                d="M599.228 867.273a13.841 13.841 0 0 1-9.161 4.036h-24.706a24.33 24.33 0 0 1-15.24-8.032l-6.92-7.822a22.368 22.368 0 0 0-10.749-6.726l-5.897-1.643a15.925 15.925 0 0 1-8.494-6.742"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-13-walk-1-a"
                    x1={298.408}
                    y1={835.502}
                    x2={365.096}
                    y2={835.502}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
