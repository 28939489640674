import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-both-arms-option-1-right">
        <path
            fill="url(#man-34-25-jacket-both-arms-option-1-right-a)"
            d="M582.599 285.483a23.545 23.545 0 0 1-22.304-16.02l-2.1-6.237c-.271-.787-.55-1.577-.828-2.368a218.36 218.36 0 0 1-1.331-3.847c-.232-.742-.47-1.462-.709-2.182-.446-1.348-.908-2.742-1.284-4.159a77.99 77.99 0 0 1-.86-3.204 82.498 82.498 0 0 1-.786-3.249c-1.206-5.179-2.048-9.217-2.729-13.085 19.038 10.177 34.653-4.28 36.482-14.128 2.077 3.275 4.239 6.934 6.801 11.511a81.526 81.526 0 0 1 1.609 2.931 79.319 79.319 0 0 1 1.531 2.963c.662 1.274 1.26 2.613 1.838 3.909.31.694.619 1.387.94 2.077.56 1.257 1.089 2.507 1.617 3.757.327.773.655 1.544.985 2.314l2.647 6.013a23.355 23.355 0 0 1 .394 18 23.356 23.356 0 0 1-12.45 13.005 23.328 23.328 0 0 1-9.463 1.999Z"
        />
        <path
            fill="url(#man-34-25-jacket-both-arms-option-1-right-b)"
            d="M388.112 291.539a23.462 23.462 0 0 1-18.011-8.389 23.358 23.358 0 0 1-4.098-7.161 25.76 25.76 0 0 1-1.998-2.401 89.656 89.656 0 0 1-7.729-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053c-.198-1.457-.377-2.921-.557-4.385-.391-3.194-.796-6.498-1.407-9.689a32.035 32.035 0 0 1 31.461-38.033 32.028 32.028 0 0 1 31.395 25.98l.065.352c.582 3.262 1.387 6.495 2.166 9.621.355 1.428.71 2.856 1.046 4.287l.004.016a760.566 760.566 0 0 1 6.777-4.826 803.794 803.794 0 0 0 3.715-2.587c2.514-1.756 5.113-3.571 7.708-5.315 3.674-2.509 7.599-5.176 11.55-7.738 1.367-.893 2.733-1.791 4.098-2.692 2.505-1.65 5.01-3.3 7.538-4.92a638.292 638.292 0 0 0 3.878-2.434c1.077-.68 2.169-1.368 3.272-2.056-.426 12.123 9.333 24.973 18.868 29.554a1103.592 1103.592 0 0 0-3.216 3.272 695.871 695.871 0 0 1-6.445 6.326 2820.727 2820.727 0 0 0-3.501 3.417c-3.36 3.319-6.802 6.573-10.04 9.622-2.248 2.149-4.573 4.291-6.82 6.362a1968.237 1968.237 0 0 0-3.34 3.089c-7.522 6.891-14.336 12.881-20.838 18.32l-10.637 8.851-10.593 8.895a23.522 23.522 0 0 1-15.103 5.506Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-1-right-a"
                x1={549.667}
                x2={606.124}
                y1={251.244}
                y2={251.244}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-1-right-b"
                x1={362.935}
                x2={464.464}
                y1={221.505}
                y2={253.101}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
