import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6821)"
            d="M494.769 223.408a2.708 2.708 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6821)"
            d="M511.858 243.495c-3.466-4.38-7.051-8.91-10.445-13.525l-.076-.103a27.633 27.633 0 00-22.095-11.101 27.499 27.499 0 00-27.375 29.881 27.5 27.5 0 005.32 14.019c3.404 4.582 6.681 9.336 9.851 13.933l2.187 3.158 42.874-34.356.712-.698-.953-1.208z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6821"
                x1="494.489"
                x2="418.152"
                y1="211.555"
                y2="258.398"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6821"
                x1="504.698"
                x2="428.36"
                y1="228.191"
                y2="275.034"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
