import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-p1">
        <path
            fill="url(#man-34-25-doctors-coat-p1-a)"
            d="m346.051 452.5-.1-.9c-2.9-23.3 1.1-43.9 4.9-63.7 4.4-22.7 8.5-44.1 2.3-67.9-6.6-25.5-10.3-50.6-10.9-74.7l-.5-22.6c-.4-15.6 6.5-30.8 18.5-40.3 25.4-20 49.8-28.1 49.8-28.1s1.9.1 9.8 9.8c1.9 2.3 7.6 9.3 9.5 11 8.5 7.6 16.2 11.2 23.7 11.2 1.3 0 2.7-.1 4-.4 2.1-.4 3.9-1.3 5.2-2.8 4.7-5.1 3.4-16.7 3.4-16.7s3.9 2.3 8.2 6c25.6 21.9 46.6 61.3 52.5 82.8 2.1 7.8 2.4 14.4.7 19.8-3.3 10.4-3 21.1.8 30.3 5.8 13.8 8.5 26.3 8.8 40.6.3 11.6 0 104.8 0 105.8v1h-190.6v-.2Z"
        />
        <path
            fill="url(#man-34-25-doctors-coat-p1-b)"
            d="m380.051 168.7 30-16.1 33.7 81.7c4.8 11.6 7.3 24.1 7.3 36.7v331.8h-110.8l-.8-375.2c0-19.9 9.8-38.5 26.3-49.6l14.3-9.3Z"
        />
        <path
            fill="#fff"
            d="M410.052 152.6c-9.8 31.7-2.4 34.4 4 37.1-7.3 9.3-6.8 10.8-6.8 10.8l41.1 47.6-38.3-95.5Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m380.051 168.7 30-16.1 33.7 81.7c4.8 11.6 7.3 24.1 7.3 36.7v331.8h-110.8l-.8-375.2c0-19.9 9.8-38.5 26.3-49.6l14.3-9.3Zm-24.5 259.8h59.7"
        />
        <path
            fill="url(#man-34-25-doctors-coat-p1-c)"
            d="M465.751 168.1s27.5 38.8 45.5 69.7c13.8 23.7 2 54.4 9.1 63.3 25.5 32.4 24.7 117.9 24.7 117.9v-43.8c0-42.3-10.6-60.9-15.4-73.6-2.7-7.2-3.5-14.9-2.4-22.4l.8-5.7c1.8-12.2-.3-24.6-6.1-35.5l-17.1-32.2c-7.2-13.6-17.5-25.2-30.2-34l-9.1-6.7.2 3Z"
        />
        <path
            fill="#fff"
            d="M468.252 168.7c27.9 17.2 28.1 18.7 26.9 24.7 6 2.5 8.5 4.1 8.5 4.1l11.1 47.6c-8.8-17.2-26.5-45.5-46.5-76.4Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M466.852 168.7s26.4 38.2 44.3 69c13.8 23.7 1.999 54.4 9.099 63.3 25.6 32.5 24.801 118 24.801 118v-43.8c0-42.3-10.6-60.9-15.4-73.6-2.7-7.2-3.501-14.9-2.401-22.4l.801-5.7c1.8-12.2-.301-24.6-6.101-35.5l-6.099-11.6"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-p1-a"
                x1={341.775}
                x2={536.838}
                y1={303.375}
                y2={303.375}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.551} stopColor="#21AFE4" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-p1-b"
                x1={393.23}
                x2={560.435}
                y1={398.01}
                y2={306.275}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-p1-c"
                x1={465.653}
                x2={545.009}
                y1={292.107}
                y2={292.107}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
