import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11369_7144)"
            d="M506.513 513.416c.127-5.601-.149-14.913.143-19.698-11.998.028-93.578.542-114.578.542-28.917-5.248-53.675-32.892-47.024-66.289 7.188-36.098 45.982-45.884 80.819-39.723 18.961 3.353 38.858 7.805 56.494 12.686 17.101 4.96 36.53 13.611 52.518 22.338 14.97 7.682 29.55 19.157 30.675 38.023 1.648 43.349 3.585 93.225 4.788 136.341l-48.205 3.093s-16.468-50.173-15.63-87.313z"
        ></path>
        <path
            fill="url(#paint1_linear_11369_7144)"
            d="M516.933 582.733l52.85-4.15c-.191-6.142-.392-12.362-.603-18.661l-56.655 4.449a412.506 412.506 0 004.408 18.362z"
        ></path>
        <path
            fill="url(#paint2_linear_11369_7144)"
            d="M516.933 582.733l52.85-4.15c-.191-6.142-.392-12.362-.603-18.661l-56.655 4.449a412.506 412.506 0 004.408 18.362z"
        ></path>
        <path
            fill="url(#paint3_linear_11369_7144)"
            d="M506.979 528.261c.475 6.138 1.263 12.394 2.24 18.532l59.35-4.661c-.218-6.206-.441-12.425-.668-18.655l-60.922 4.784z"
        ></path>
        <path
            fill="url(#paint4_linear_11369_7144)"
            d="M506.979 528.261c.475 6.138 1.263 12.394 2.24 18.532l59.35-4.661c-.218-6.206-.441-12.425-.668-18.655l-60.922 4.784z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11369_7144"
                x1="433.45"
                x2="540.149"
                y1="507.785"
                y2="472.653"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11369_7144"
                x1="512.526"
                x2="569.784"
                y1="571.327"
                y2="571.327"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11369_7144"
                x1="512.526"
                x2="569.784"
                y1="571.327"
                y2="571.327"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_11369_7144"
                x1="506.979"
                x2="568.569"
                y1="535.135"
                y2="535.135"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <linearGradient
                id="paint4_linear_11369_7144"
                x1="506.979"
                x2="568.569"
                y1="535.135"
                y2="535.135"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
