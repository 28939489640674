import { ILegs } from 'interfaces';

import Stand1 from './female-34-25-pants-4-stand-1';
import Stand2 from './female-34-25-pants-4-stand-2';
import Walk1 from './female-34-25-pants-4-walk-1';
import Walk2 from './female-34-25-pants-4-walk-2';
import Sitting1Left from './female-34-25-pants-4-sitting-1-left';
import Sitting1Right from './female-34-25-pants-4-sitting-1-right';

const pants4: ILegs = {
    name: 'Pants #4',
    stand1: [Stand1],
    stand2: [Stand2],
    walk1: [Walk1],
    walk2: [Walk2],
    sitting1: [Sitting1Right, Sitting1Left]
};

export default pants4;
