import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 228,
        height: 115,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M156.227 113.603h50.927a28.163 28.163 0 0 0 19.747-8.082H66.811M47.15 105.521a13.524 13.524 0 0 0 12.381 8.082h60.698"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M132.524 105.523 87.044 1.889H1.099L47.15 105.523"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M71.838 54.872c2.082-2.708.29-7.58-4.005-10.882-4.294-3.302-9.463-3.783-11.545-1.075-2.082 2.708-.29 7.58 4.005 10.882 4.294 3.301 9.463 3.783 11.545 1.075Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M47.15 105.523 1.098 1.889h85.947l45.476 103.626a13.525 13.525 0 0 0 12.384 8.09h11.321"
                />
            </>
        ),
    },
    'Color #1': {
        width: 228,
        height: 115,
        content: (
            <>
                <path
                    fill="url(#object-devices-laptop-a)"
                    d="M86.95 1.889H1.005l46.052 103.634h85.374L86.95 1.889Z"
                />
                <path
                    fill="url(#object-devices-laptop-b)"
                    d="M132.213 105.521H47.055a13.524 13.524 0 0 0 12.38 8.082H207.06a28.163 28.163 0 0 0 19.747-8.082h-94.593Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M144.812 113.603h62.248a28.163 28.163 0 0 0 19.747-8.082h-62.22M47.055 105.521a13.524 13.524 0 0 0 12.38 8.082h9.745"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M47.056 105.523 10.52 23.305 1.004 1.889H86.95l45.476 103.626a13.525 13.525 0 0 0 12.384 8.09h11.321"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M56.243 49.137c-2.135-4.268-.406-7.837 3.862-7.971 4.268-.134 9.459 3.217 11.594 7.485 2.135 4.268.406 7.837-3.862 7.971"
                />
                <defs>
                    <linearGradient
                        id="object-devices-laptop-a"
                        x1={19.571}
                        x2={108.871}
                        y1={8.346}
                        y2={93.744}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-laptop-b"
                        x1={189.836}
                        x2={108.176}
                        y1={109.562}
                        y2={109.562}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.321} stopColor="#ECF3F9" />
                        <stop offset={0.726} stopColor="#C2C9CF" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-laptop"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
