import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6795)"
            d="M494.77 222.602a109.64 109.64 0 00-20.026-15.329 31.037 31.037 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.013 1.013 0 00.563.522.997.997 0 00.767-.03c2.598-1.2 15.601-7.426 16.405-12.092a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6795)"
            d="M461.423 278.747a1.004 1.004 0 01-.951-.693l-.208-.639c-.284-.876-.569-1.753-.831-2.637-.619-2.069-1.224-4.147-1.83-6.224-1.379-4.732-2.805-9.626-4.395-14.366a27.489 27.489 0 011.391-21.054 27.507 27.507 0 0115.9-13.871 27.519 27.519 0 0110.671-1.36 27.51 27.51 0 0124.182 18.795l.046.136.002.006c1.565 4.751 3.352 9.527 5.08 14.147.714 1.907 1.424 3.816 2.129 5.727.186.504.263.668.344.912l-51.149 21.045a.993.993 0 01-.381.076z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6795"
                x1="493.201"
                x2="422.503"
                y1="213.069"
                y2="253.84"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6795"
                x1="503.705"
                x2="433.006"
                y1="231.281"
                y2="272.052"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
