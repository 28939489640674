import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m348.57 789.469-13.472 28.324 3.687 3.427 9.752-12.101a4.552 4.552 0 0 1 3.592-1.693 4.54 4.54 0 0 1 3.551 1.776"
                fill="#63666A"
            />
            <path
                d="M382.137 854.889a38.888 38.888 0 0 1-9.373-6.024l-4.148-3.6a24.33 24.33 0 0 1-8.174-15.164l-1.384-10.351a22.32 22.32 0 0 0-5.171-11.574l-3.982-4.655a13.899 13.899 0 0 1 .467-18.621l11.94-12.619.68 1.118c5.138 8.429 8.3 17.494 8.674 24.872.154 3.042.003 6.12-.144 9.097a61.727 61.727 0 0 0 .24 12.653 30.368 30.368 0 0 0 7.976 16.064 16.412 16.412 0 0 0 4.895 3.685 9.271 9.271 0 0 0 2.5.75 16.594 16.594 0 0 0 5.409-.788l.713-.228 7.182 10.642a6.435 6.435 0 0 1-.14 7.382 6.453 6.453 0 0 1-7.851 2.061l-10.309-4.7Z"
                fill="#888B8D"
            />
            <path
                d="M396.835 859.919a6.413 6.413 0 0 1-4.39-.331l-10.308-4.701a38.884 38.884 0 0 1-9.373-6.023l-4.148-3.601a24.328 24.328 0 0 1-8.174-15.164l-1.384-10.351a22.32 22.32 0 0 0-5.171-11.573l-3.982-4.655a15.922 15.922 0 0 1-3.293-10.332M335.098 817.789l3.687 3.427"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m397.91 842.734 8.096 32.58 7.112.302-.651-10.954"
                fill="#63666A"
            />
            <path
                d="M406.006 875.312h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M427.804 876.387a15.027 15.027 0 0 1-12.214-6.063l-8.739-12.081a28.064 28.064 0 0 0-3.597-4.072 20.63 20.63 0 0 1-6.369-19.307l2.115-9.778 15.467 22.586c3.965 5.907 5.619 7.117 12.455 9.059 2.927.831 9.362.63 13.554-.362a14.307 14.307 0 0 0 7.427-4.554l11.586 10.136a8.284 8.284 0 0 1 2.584 6.196 8.769 8.769 0 0 1-9.049 8.24h-25.22Z"
                fill="url(#female-34-25-shoes-5-stand-2-a)"
            />
            <path
                d="M459.575 873.825a9.321 9.321 0 0 1-6.503 2.561h-25.219a15.04 15.04 0 0 1-6.835-1.566 15.04 15.04 0 0 1-5.38-4.496s-15.174-19.201-16.206-20.933"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-5-stand-2-a"
                    x1={396.473}
                    y1={850.736}
                    x2={462.076}
                    y2={850.736}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
            </defs>
        </g>
    );
}
