import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6368)"
            d="M541.444 345.165c-1.643-19.046-9.339-34.033-11.686-39.733-4.435-10.77-5.245-21.785-2.281-31.019 1.718-5.35 1.501-12.025-.645-19.843-5.893-21.467-25.107-63.041-52.274-82.894a89.66 89.66 0 00-9.312-5.856s1.383 9.15-2.196 14.192a9.773 9.773 0 01-5.679 3.396 22.412 22.412 0 01-3.997.359c-7.456.001-14.532-3.372-23-10.928-1.916-1.71-3.864-3.777-5.927-5.966-4.213-4.472-8.978-9.496-14.871-12.904-17.725 7.173-34.143 16.035-48.888 27.893a50.22 50.22 0 00-18.456 40.175l.457 19.059a327.336 327.336 0 0010.715 77.382c.033 3.804.034 7.485.036 11.171.011 28.39 1.925 56.477 5.373 84.666 32.372-22.479 61.294-11.569 97.519-7.586 33.729 3.709 63.99 2.378 75.052-13.644 0 0 12.474-19.942 10.06-47.92z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6368"
                x1="341.389"
                x2="533.968"
                y1="285.299"
                y2="280.094"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#22ADE0"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
