import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6336)"
            d="M327.621 423.114c-4.987-28.436-7.234-61.733-6.498-96.289a19.529 19.529 0 01.254-3.176 21.43 21.43 0 01.704-5.498c5.22-19.783 17.586-49.316 27.779-65.855 1.237-2.02 2.662-4.05 3.914-6.066 2.848-4.581 5.792-9.317 8.509-14.068a27.594 27.594 0 0123.896-13.85 27.512 27.512 0 0113.63 3.634 27.335 27.335 0 0112.88 16.647 27.336 27.336 0 01-2.664 20.879c-2.708 4.734-4.866 9.19-7.032 13.264-6.992 13.152-13.11 24.266-31.146 46.717-4.321 5.344-7.975 9.607-11.471 13.38 1.033 27.359 1.668 56.738-2.536 88.743l-.108.824-29.957 1.587-.154-.873z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6336"
                x1="419.366"
                x2="363.971"
                y1="192.502"
                y2="313.56"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
