import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-3">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-3-a)"
            d="M430.42 250.955a242.552 242.552 0 0 0-11.067-25.884 1031.017 1031.017 0 0 0-3.804-7.58c-2.884-5.716-5.866-11.627-8.604-17.524l-.059-.128a32.154 32.154 0 0 0-11.83-13.397 32.154 32.154 0 0 0-17.157-5.008 31.718 31.718 0 0 0-13.581 3.042 31.788 31.788 0 0 0-16.498 18.101 31.807 31.807 0 0 0-1.884 12.347 31.804 31.804 0 0 0 3.019 12.119c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.235 5.18 3.36 7.766a242.608 242.608 0 0 0 12.737 25.1c2.135 3.735 4.24 7.32 6.338 10.802l55.041-26.647a549.106 549.106 0 0 0-3.929-10.971Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-3-a"
                x1={407.816}
                x2={357.72}
                y1={275.886}
                y2={173.741}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
