import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6505)"
            d="M417.403 347.986a22.637 22.637 0 01-14.1-4.625 33.365 33.365 0 01-5.806-6.527 208.71 208.71 0 01-12.541-19.355c-3.637-6.274-7.135-12.819-10.695-20.012a165.74 165.74 0 01-8.819-20.786c-1.12-3.227-1.696-5.333-2.252-7.369a78.654 78.654 0 00-2.712-8.409 26.503 26.503 0 0115.86-35.509 27.379 27.379 0 019.82-1.824 27.667 27.667 0 0125.69 17.683l.051.136c1.832 4.866 3.89 9.753 5.879 14.477.878 2.085 1.751 4.172 2.618 6.26a58.747 58.747 0 013.507 10.989c.333 1.403.713 3.006 1.251 5.047 2.104 7.724 3.828 14.942 5.274 22.056a306.728 306.728 0 011.467 7.848l6.26-.439c4.185-.27 8.454-.408 12.692-.408 3.082 0 6.224.073 9.34.216 1.196.061 2.387.11 3.577.16 2.435.102 4.953.208 7.442.408l5.487.379c1.196.086 2.392.2 3.589.314.643.062 1.287.123 1.93.18 2.785.266 5.254.526 7.557.795l1.187.139-.346 1.145c-3.301 10.884-.234 23.735 3.419 28.157l1.226 1.452-1.614.343c-2.946.625-6.341 1.07-10.018 1.732-.645.112-1.285.227-1.923.342-1.18.213-2.36.426-3.541.609l-5.448.836c-2.452.406-4.951.72-7.368 1.024a358.81 358.81 0 00-3.55.457 200.099 200.099 0 01-21.972 1.638l-10.96.146-10.937.289c-.174.003-.348.006-.521.006z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6505"
                x1="484.931"
                x2="357.413"
                y1="361.339"
                y2="244.23"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
