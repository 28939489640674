import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6511)"
            d="M417.399 347.986a22.635 22.635 0 01-14.1-4.625 33.394 33.394 0 01-5.806-6.527 208.844 208.844 0 01-12.541-19.355c-3.636-6.275-7.135-12.82-10.695-20.012a165.586 165.586 0 01-8.818-20.786c-1.12-3.227-1.697-5.333-2.253-7.369a78.565 78.565 0 00-2.712-8.408 26.513 26.513 0 01.174-21.22 26.501 26.501 0 0115.686-14.29 27.383 27.383 0 019.82-1.824 27.667 27.667 0 0125.69 17.683l.051.136c1.832 4.866 3.89 9.753 5.879 14.477.878 2.085 1.751 4.172 2.618 6.26a58.747 58.747 0 013.507 10.989c.333 1.403.713 3.005 1.251 5.046 2.104 7.725 3.828 14.943 5.274 22.057a311.406 311.406 0 011.468 7.848l6.259-.439c4.185-.27 8.454-.408 12.692-.408 3.082 0 6.224.072 9.34.216 1.196.06 2.387.11 3.577.16 2.435.102 4.953.208 7.443.408l5.486.379c1.196.086 2.392.2 3.589.314.643.062 1.287.123 1.93.18 2.786.266 5.254.526 7.557.795l1.188.139-.347 1.145c-3.301 10.884-.234 23.735 3.419 28.157l1.227 1.453-1.615.342c-2.946.625-6.341 1.07-10.017 1.732-.646.112-1.286.227-1.924.342-1.18.213-2.36.426-3.541.609l-5.448.836c-2.452.406-4.951.72-7.368 1.024a358.81 358.81 0 00-3.55.457 200.099 200.099 0 01-21.971 1.638l-10.96.146-10.938.289a22.69 22.69 0 01-.521.006z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6511"
                x1="351.15"
                x2="492.114"
                y1="256.569"
                y2="337.244"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
