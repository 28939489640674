import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-up">
        <path
            fill="url(#man-34-25-jacket-arm-left-up-a)"
            d="m465.756 167.2 2.7 1c13.1 4.7 23.8 10.3 32.8 17.3.8.6 1.099 1.4 1.099 2.3-.2 4.6-12.199 12.2-14.599 13.7l-.901.5-21.099-34.8Z"
        />
        <path
            fill="url(#man-34-25-jacket-arm-left-up-b)"
            d="M614.756 237.7c-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-3.3-14.6-4.8-20.2 0 0-10.1 4.3-22.4 1.6-12.2-2.7-16.5-10.1-16.5-10.1-.7 6.7-1 12.6-1.2 19.6-18.6-7.5-38.7-14.4-58.3-18.4-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.4-1-8.1-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.2 5.1 27.3 7.7 17.5 5.1 38.2 8.2 56.1 10.4 7.8.9 18.2 1.9 28.9 2.2 1.5 0 2.9-.1 4.4-.3 14.8 1.2 27.5-12.4 25.2-27Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-left-up-a"
                x1={465.779}
                x2={502.353}
                y1={184.62}
                y2={184.62}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-arm-left-up-b"
                x1={448.854}
                x2={615.002}
                y1={223.232}
                y2={223.232}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
