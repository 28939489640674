import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#EF0025"
            d="M634.832 513.521c2.419-16.022 8.686-57.768 11.18-74.391 2.014-13.006-4.797-23.163-16.083-29.897-2.143-1.279-5.953-3.23-8.079-4.469-15.848-9.101-28.728-14.261-43.326-20.297-19.142-8.312-39.779-14.393-62.209-16.511-27.686-3.551-54.259 11.498-62.978 38.499a54.113 54.113 0 003.803 41.412 54.105 54.105 0 0032.064 26.481 109.67 109.67 0 0014.101 3.36l61.392-.005c.422-.027 6.147-.058 10.89-.081-3.663 19.315-6.03 37.887-4.583 55.111 1.17 14.685 5.648 89.391 5.938 99.006h44.385c.867-21.903 11.715-106.821 13.505-118.218z"
        ></path>
    </g>
);

export default SvgComponent;
