import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M536.652 493.19c-18.133 0-74.687.109-74.687.109l-63-.016c-1.196 0-9.073-1.244-10.241-1.502a52.246 52.246 0 01-21.945-91.671 52.245 52.245 0 0136.479-11.534l7.253.498c3.028.205 6.035.52 9.03.893l4.49.578 4.47.681c2.986.422 5.943.989 8.911 1.504 2.972.492 5.916 1.13 8.87 1.717 11.796 2.447 23.529 5.216 35.169 8.46 5.816 1.64 11.563 3.636 17.269 5.845a303.92 303.92 0 0117.009 7.177 160.56 160.56 0 014.217 1.978 153.6 153.6 0 014.2 2.062c2.799 1.377 5.563 2.939 8.346 4.4a163.565 163.565 0 0116.401 10.291l.87.637a29.879 29.879 0 016.457 41.756c-4.498 6.144-11.472 14.258-19.568 16.137z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M540.314 652.006a741.46 741.46 0 01-7.012-22.946c-1.102-3.83-2.285-7.654-3.322-11.49l-3.17-11.505c-2.139-7.667-4.151-15.346-6.514-22.992l-3.536-11.47-1.774-5.735-1.582-5.752c-4.178-15.344-5.023-30.995-5.322-46.696-.064-7.859.194-15.747.9-23.677a145.39 145.39 0 013.941-23.957l2.574-9.887a24.42 24.42 0 0148.031 5.218l1.8 47.021.89 23.511.76 23.523c.486 15.685 1.062 31.36 1.473 47.051l1.305 47.067a14.723 14.723 0 01-28.723 4.954l-.719-2.238z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M507.808 493.299H404.764c-18.392 0-35.823-6.359-46.834-21.091l-.23-.31M446.281 394.445c11.796 2.447 23.529 5.216 35.169 8.46 5.816 1.64 11.564 3.636 17.269 5.845a303.605 303.605 0 0117.009 7.177 160.56 160.56 0 014.217 1.978 153.6 153.6 0 014.2 2.062c2.799 1.377 5.563 2.939 8.346 4.4a163.286 163.286 0 0116.402 10.291l.87.637a29.784 29.784 0 0111.856 19.416M539.062 648.095a753.431 753.431 0 01-5.76-19.036c-1.102-3.83-2.285-7.654-3.322-11.49l-3.17-11.505c-2.139-7.667-4.151-15.346-6.514-22.992l-3.536-11.47-1.774-5.735-1.582-5.752c-4.178-15.344-5.023-30.995-5.322-46.696-.064-7.859.194-15.747.9-23.677M563.528 461.117l1.8 47.021.89 23.512.76 23.523c.486 15.684 1.062 31.36 1.473 47.051l1.306 47.066"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M570.357 650.346l16.81 35.086c1.646 3.732 3.372 4.866 7.058 6.613l13.823 6.551a5.423 5.423 0 013.074 5.423 5.432 5.432 0 01-4.829 4.877c-3.271.348-7.755.83-11.254 1.206-3.616.39-7.27.256-10.848-.396l-5.403-.985a23.348 23.348 0 01-14.154-8.518l-6.465-8.202c-2.79-3.54-5.334-4.315-9.599-5.77l-5.799-1.977a12.921 12.921 0 01-8.433-15.092l4.876-21.49"
        ></path>
        <path
            stroke="#000"
            strokeMiterlimit="10"
            d="M570.357 650.346l16.81 35.086c1.646 3.732 3.372 4.866 7.058 6.613l13.823 6.551a5.423 5.423 0 013.074 5.423 5.432 5.432 0 01-4.829 4.877c-3.271.348-7.755.83-11.254 1.206-3.616.39-7.27.256-10.848-.396l-5.403-.985a23.348 23.348 0 01-14.154-8.518l-6.465-8.202c-2.79-3.54-5.334-4.315-9.599-5.77l-5.799-1.977a12.921 12.921 0 01-8.433-15.092l4.876-21.49"
        ></path>
        <path
            stroke="#000"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M569.598 648.991l16.36 34.56a16.992 16.992 0 008.268 8.494l13.823 6.551a5.423 5.423 0 013.074 5.423 5.432 5.432 0 01-4.829 4.877c-3.271.348-7.755.83-11.254 1.206-3.616.39-7.27.256-10.848-.396l-5.403-.985a23.348 23.348 0 01-14.154-8.518l-6.465-8.202c-2.79-3.539-5.334-4.315-9.599-5.77l-5.799-1.977a12.924 12.924 0 01-8.433-15.092l4.875-21.49"
        ></path>
    </g>
);

export default SvgComponent;
