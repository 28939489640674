import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M410.342 351.775a22.893 22.893 0 0 1-15.415-6.103 32.18 32.18 0 0 1-5.009-6.521 194.195 194.195 0 0 1-10.603-19.524c-3.019-6.315-5.864-12.861-8.703-20.014a153.324 153.324 0 0 1-6.769-20.63 673.22 673.22 0 0 1-1.565-6.335c-1.169-4.783-2.378-9.729-3.757-14.529a27.503 27.503 0 0 1 18.811-34.121 27.497 27.497 0 0 1 29.154 9.363 27.502 27.502 0 0 1 4.897 9.575l.04.137c1.356 4.819 2.931 9.666 4.453 14.353a812.762 812.762 0 0 1 1.998 6.201 153.021 153.021 0 0 1 5.097 21.11 327.068 327.068 0 0 1 2.793 18.758 234.384 234.384 0 0 1 19.857-8.022c1.227-.425 2.445-.858 3.662-1.291 2.478-.882 5.041-1.794 7.609-2.606l5.648-1.851c1.231-.4 2.47-.773 3.709-1.147a427.39 427.39 0 0 0 2.013-.611c4.685-1.411 8.572-2.516 12.242-3.481l1.32-.346-.067 1.363c-.627 12.725 5.806 22.277 12.124 23.83l2.208.542-1.891 1.261c-3.54 2.359-7.41 4.806-12.18 7.701-.616.368-1.224.736-1.832 1.105a176.745 176.745 0 0 1-3.306 1.974l-5.166 2.967c-2.313 1.361-4.704 2.656-7.018 3.908-1.136.615-2.272 1.23-3.404 1.855a233.803 233.803 0 0 1-21.45 10.346l-10.953 4.539-10.88 4.663a19.347 19.347 0 0 1-7.667 1.581Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-8-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-8-right-a"
                x1={343.173}
                y1={261.323}
                x2={524.474}
                y2={347.203}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
