import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-1-both-arms-option-1-right">
        <path
            fill="url(#man-34-25-tshirt-1-both-arms-option-1-right-a)"
            d="M364.662 273.588a89.651 89.651 0 0 1-7.73-13.228 136.999 136.999 0 0 1-5.655-13.563 64.538 64.538 0 0 1-3.553-14.052 330.089 330.089 0 0 1-.556-4.385c-.391-3.194-.796-6.498-1.408-9.69a32.04 32.04 0 0 1 25.401-37.455 32.274 32.274 0 0 1 6.06-.578 32.026 32.026 0 0 1 31.395 25.98l.065.353c.582 3.262 1.387 6.495 2.166 9.621l1.431 5.744c2.249-1.62-46.988 29.133-47.616 51.253Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m385.998 241.761 10.925-8.488c7.3-5.639 14.765-11.072 22.326-16.387 3.801-2.631 7.565-5.308 11.406-7.89 3.82-2.608 7.647-5.207 11.536-7.729 3.88-2.534 7.728-5.107 11.633-7.609 3.946-2.451 7.83-4.979 11.841-7.349"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-1-right-a"
                x1={352.988}
                x2={406.005}
                y1={220.771}
                y2={237.27}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
