import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.499 279.902c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a164.835 164.835 0 0 0-17.209-9.819c4.064 7.051 7.777 20.062 2.996 32.059-2.793 1.171-20.036-16.018-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.739-4.033.917-19.07 11.052-19.209 11.136a62.748 62.748 0 0 0-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.875 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 0 1-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578Z"
            fill="url(#female-34-25-doctors-coat-p1-a)"
        />
        <g filter="url(#female-34-25-doctors-coat-p1-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0 0 12.049-48.824l-6.917-10.636"
                fill="url(#female-34-25-doctors-coat-p1-c)"
            />
        </g>
        <path
            d="M407.531 194.484c11.037 8.028 21.706 14.536 31.632 17.961l24.552 18.979 8-19.516 7.31 6.637"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m439.165 212.447-9.445 13.073-20.923-22.629M465.219 234.469c3.321 4.311 6.916 9.661 10.237 13.972"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m388.061 203.966 19.831-9.708 33.7 81.652a96.205 96.205 0 0 1 7.276 36.702v331.839H343.953V435.357c0-40.411 29.574-61.975 29.574-88.19 0-39.308-16.811-57.608-16.811-80.088.001-45.552 31.345-63.113 31.345-63.113Z"
            fill="url(#female-34-25-doctors-coat-p1-d)"
        />
        <path
            d="M407.896 194.258c-16.337 17.315 38.232 95.539 38.232 95.539l-38.232-95.539Z"
            fill="#fff"
        />
        <path
            d="M372.57 452.281h58.024"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m388.061 203.966 19.831-9.708 33.7 81.652a96.205 96.205 0 0 1 7.276 36.702v331.839H343.953V435.357c0-40.411 29.574-61.975 29.574-88.19 0-39.308-16.811-57.608-16.811-80.088.001-45.552 31.345-63.113 31.345-63.113Z"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-p1-a"
                x1={345.022}
                y1={332.812}
                x2={532.607}
                y2={299.939}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#21ACDF" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-p1-d"
                x1={343.954}
                y1={419.354}
                x2={448.869}
                y2={419.354}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <radialGradient
                id="female-34-25-doctors-coat-p1-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
            >
                <stop offset={0.437} stopColor="#009CDE" stopOpacity={0} />
                <stop offset={0.484} stopColor="#009CDE" stopOpacity={0.102} />
                <stop offset={0.715} stopColor="#009CDE" stopOpacity={0.581} />
                <stop offset={0.875} stopColor="#009CDE" stopOpacity={0.882} />
                <stop offset={0.949} stopColor="#009CDE" />
            </radialGradient>
            <filter
                id="female-34-25-doctors-coat-p1-b"
                x={402.73}
                y={251.023}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_10364_8757"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
