import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        content: (
            <>
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m470.399 707.501-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.7-2.2-.5-4.6-2.7-5.3ZM630.499 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.2 5-5 5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M402.8 745.601c-2.7 0-5.2-1.9-5.8-4.6l-63.8-274.4c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5l63.8 274.4c.8 3.2-1.3 6.5-4.5 7.2-.5 0-.9.1-1.4.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m362.1 590.701-28.9-124.2c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5"
                />
                <path
                    fill="#009CDE"
                    d="m314.799 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.2 1.2 6.3 1.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m314.799 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.2 1.2 6.3 1.6ZM357.299 517.301l8.5 37.4"
                />
                <path
                    fill="#D9D9D6"
                    d="M544.499 416.5c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h24.7l5.5-8.7c.7-1.2 2-1.9 3.4-1.9h11.1c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9h-28.5Z"
                />
                <path
                    fill="url(#object-devices-bicycle-a)"
                    d="M392.199 581.301c0-4.4-3.5-8-7.9-8h-.1l-17.5-.1-17.5.1h-.1c-2.2 0-4.3.9-5.8 2.7l-110.1 125.8-.1.1c-1.8 2.1-2.1 5.2-.5 7.7l22.2 33.8c1.8 2.7 5.4 3.5 8.2 1.8 2.8-1.8 3.7-5.5 1.9-8.3l-18.9-30.1 106.7-117.4 13.9.1h.1l-95.6 112.5-.1.1c-1.5 1.8-2 4.4-.9 6.7l12.9 27.8c1.4 2.9 4.9 4.3 7.8 3 3-1.3 4.4-4.9 3.1-7.9l-10.6-24.3 106.7-120.6c1.4-1.7 2.2-3.5 2.2-5.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m306.199 681.401 84-94.9c1.2-1.4 2-3.2 2-5.2 0-4.4-3.5-8-7.9-8h-.1M352.699 589.301h14.1"
                />
                <path
                    fill="#D9D9D6"
                    d="M573 549.201c-3.5 0-6.6-2.3-7.7-5.7l-30.7-104.6c-.7-2.5-.2-5.1 1.4-7.2l19.8-25.5c2.7-3.5 8.7-3 11.3.6 2.8 3.8 1.6 8.9-1.1 12.4l-14.8 19.1 29.6 100.6c1.2 4.2-1.2 8.7-5.4 9.9-.8.3-1.6.4-2.4.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m539.8 456.601-5.2-17.6c-.7-2.5-.2-5.1 1.4-7.2l4.3-5.6"
                />
                <path
                    fill="url(#object-devices-bicycle-b)"
                    d="M402.799 747.601c-3.6 0-6.9-2.5-7.8-6.2l-43.4-186.6c-.7-3 3-6 7.4-6 4.9 0 8.5 2.6 8.9 5.4l42.7 183.6c1 4.3-1.7 8.6-6 9.6-.6.1-1.2.2-1.8.2Z"
                />
                <path
                    fill="url(#object-devices-bicycle-c)"
                    d="m398.099 749.502-109.9-8.2c-4.1-.3-7.2-3.9-6.9-8 .3-4 3.7-7.1 7.7-6.9l110.2 3.2c5.5.2 9.9 4.8 9.7 10.3-.2 5.5-4.8 9.9-10.3 9.7-.2 0-.4 0-.5-.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-d)"
                    d="M582.6 538.1 563 471.3c-1.6-5.5-5.6-7.6-12.3-6.8-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-173.8 84.5c-3.6 1.8-5.2 6.1-3.5 9.9 1.7 3.8 6.2 5.4 9.9 3.7 0 0 137.4-62.5 169.6-77.1 3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3L396.1 733.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2l148.8-208.9 2.3 7.9c1.3 4.4 5.3 7.2 9.6 7.2 1.3 0 2.6-.2 3.8-.8 4.8-2 7.1-7.4 5.7-12.4Z"
                />
                <path
                    fill="url(#object-devices-bicycle-e)"
                    d="m654.399 717.002-13.4-44.1-13.5-44.1-27-88.1c-1-3.2-4-5.6-7.5-5.6h-17.5l-11.3.1h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7 6.8.1h11.6l26.9 81.9 14.4 43.8 14.5 43.7c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M641.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-f)"
                    d="M631.199 762.302c18.17 0 32.9-19.745 32.9-44.1 0-24.356-14.73-44.1-32.9-44.1-18.17 0-32.9 19.744-32.9 44.1 0 24.355 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-g)"
                    d="M535.599 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-h)"
                    d="M633.7 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.3-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M621.3 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m584.3 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.4-2.4.8-3.6ZM688.1 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.5-1.2.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M599.6 690.302c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M267.598 727.802c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-i)"
                    d="M257.3 776.101c23.858 0 43.2-25.923 43.2-57.9s-19.342-57.9-43.2-57.9c-23.859 0-43.2 25.923-43.2 57.9s19.341 57.9 43.2 57.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-j)"
                    d="M161.698 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-k)"
                    d="M259.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.3-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m210.399 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM314.1 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.399 798.202c-33 0-59.7-35.8-59.7-80.1s26.7-80.1 59.7-80.1c1.6 0 3.3.1 4.9.3 30.7 3.3 54.8 37.8 54.8 79.8s-24.1 76.5-54.8 79.8c-1.7.2-3.3.3-4.9.3Z"
                />
                <path
                    fill="#5BC2E7"
                    d="m254.299 719.501-7.8-12.6c7.4-7.8 26.2-28 104.4-115.6l-10.9-12s-15.3 15.2-107.2 122.1l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1l11.3 16.8 11.3 16.8c1.8 2.6 5.4 3.4 8.1 1.8 2.8-1.7 3.7-5.4 2-8.2l-10.5-17.4Z"
                />
                <path
                    fill="#5BC2E7"
                    d="m398.698 749.601-137.3-1.5c-4.1 0-7.5-3.4-7.4-7.6 0-4 3.3-7.3 7.3-7.4l137.2-3.5c5.5-.1 10.1 4.2 10.3 9.7.1 5.5-4.2 10.1-9.7 10.3h-.4ZM564.199 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m376.299 588.801 34.4 148.9c1 4.3-1.7 8.6-6 9.6-.6.1-1.2.2-1.8.2-3.6 0-6.9-2.5-7.8-6.2l-35.3-152M355.999 573.301l-4.3-18.5c-.7-3 3-6 7.4-6 4.9 0 8.5 2.6 8.9 5.4l2.5 10.8M437 710.401l104.9-147.2"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M374.199 579.901s137.4-62.5 169.6-77.1c3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2M559.3 465.901c-2.1-1.4-5-1.8-8.6-1.4-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-171.2 83.3M581.6 534.9 565.5 480M604.999 555.1l-4.4-14.3c-1-3.2-4-5.6-7.5-5.6h-.1l-11.3-.3M642.499 677.701l-14.9-48.8-11.4-37.2M603.199 600l10.9 33.2 12.9 39.3M607.999 679.901l-15.5-51-23.8-77.7 6.8.1h11.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M564.199 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-8-26.4M349.4 730.801l-86.8 2.2-8.2-13.5-7.8-12.6c7.4-7.8 28-30 106.2-117.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M317.099 604.601s7.6-10.1-84.3 96.8l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1 0 0 22.6 34.2 22.9 34.8 1.3 2.1 3.6 3.5 6.3 3.6l137.3 1.5h.4c5.5-.1 9.9-4.7 9.7-10.3-.1-5.5-4.7-9.9-10.3-9.7M564.199 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5"
                />
                <path
                    fill="url(#object-devices-bicycle-l)"
                    d="M397.7 776.8c15.298 0 27.7-16.655 27.7-37.2s-12.402-37.2-27.7-37.2-27.7 16.655-27.7 37.2 12.402 37.2 27.7 37.2Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M388.2 749.2c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.8 778c-15.9 0-28.7-17.2-28.7-38.5S378 701 393.8 701c.8 0 1.6 0 2.4.1 14.8 1.6 26.4 18.2 26.4 38.3 0 20.2-11.6 36.7-26.4 38.3-.8.3-1.6.3-2.4.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.799 789.902c-20.8 0-37.6-22.6-37.6-50.4 0-27.8 16.8-50.4 37.6-50.4 1 0 2.1.1 3.1.2 19.3 2.1 34.5 23.8 34.5 50.2 0 26.4-15.2 48.1-34.5 50.2-1 .2-2 .2-3.1.2Z"
                />
                <path
                    fill="#63666A"
                    d="m361.399 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m361.399 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    fill="#fff"
                    d="M252.199 751.601c13.752 0 24.9-14.954 24.9-33.4 0-18.447-11.148-33.4-24.9-33.4-13.752 0-24.9 14.953-24.9 33.4 0 18.446 11.148 33.4 24.9 33.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M244.699 686.301c2.8-1.3 5.4-1.5 7.4-1.5 13.8 0 24.9 14.9 24.9 33.4s-11.1 33.4-24.9 33.4c-13.8 0-24.9-14.9-24.9-33.4"
                />
                <path
                    fill="#E1E1DF"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.399 727.802c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M552.799 416.5h-8.3c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h7.7l1.9-2.2c2.7-3.5 8.7-3 11.3.6 2.8 3.8 2.1 8.2-.6 11.7l-15.3 19.8 8.1 27.5M570.799 408.5l5.5-8.7c.7-1.2 2-1.9 3.4-1.9h11.1c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9"
                />
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M519.8 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.2 5-5 5ZM367.599 824.601l-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.6-2.2-.5-4.6-2.7-5.3Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bicycle-a"
                        x1={302.206}
                        x2={322.591}
                        y1={614.027}
                        y2={676.289}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5BC2E7" />
                        <stop offset={0.977} stopColor="#D0EFFA" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-b"
                        x1={339.485}
                        x2={412.197}
                        y1={528.494}
                        y2={734.906}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5BC2E7" />
                        <stop offset={0.977} stopColor="#D0EFFA" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-c"
                        x1={413.034}
                        x2={284.011}
                        y1={744.14}
                        y2={727.981}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5BC2E7" />
                        <stop offset={0.977} stopColor="#D0EFFA" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-d"
                        x1={441.068}
                        x2={506.692}
                        y1={485.413}
                        y2={696.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5BC2E7" />
                        <stop offset={0.959} stopColor="#D0EFFA" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-e"
                        x1={571.22}
                        x2={632.608}
                        y1={629.955}
                        y2={629.955}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#5BC2E7" />
                        <stop offset={0.977} stopColor="#D0EFFA" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-f"
                        x1={631.229}
                        x2={631.229}
                        y1={762.224}
                        y2={674.103}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-g"
                        x1={574.253}
                        x2={574.253}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-h"
                        x1={627.491}
                        x2={627.491}
                        y1={870.757}
                        y2={633.545}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-i"
                        x1={257.295}
                        x2={257.295}
                        y1={776.109}
                        y2={660.216}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-j"
                        x1={200.317}
                        x2={200.317}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-k"
                        x1={253.556}
                        x2={253.556}
                        y1={870.394}
                        y2={631.267}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-l"
                        x1={397.723}
                        x2={397.723}
                        y1={776.723}
                        y2={702.391}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        content: (
            <>
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m470.599 707.501-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.201.5c-.8 2.2.401 4.7 2.701 5.4l50.9 17.1c2.3.8 4.699-.5 5.399-2.7.6-2.2-.499-4.6-2.699-5.3ZM630.7 406.801H591c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.3 5-5 5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M402.999 745.601c-2.7 0-5.2-1.9-5.8-4.6l-63.8-274.4c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5l63.8 274.4c.8 3.2-1.3 6.5-4.5 7.2-.5 0-1 .1-1.4.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m362.2 590.701-28.901-124.2c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.501 1.3 7.201 4.5"
                />
                <path
                    fill="#FFB33B"
                    d="m314.998 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m314.998 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6ZM357.5 517.301l8.4 37.4"
                />
                <path
                    fill="#D9D9D6"
                    d="M544.698 416.5c-1.1 0-2.1-.4-2.8-1.2l-9.399-9.4h-53.101c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h24.701l5.5-8.7c.7-1.2 1.999-1.9 3.399-1.9h11.101c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.901l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9h-28.5Z"
                />
                <path
                    fill="url(#object-devices-bicycle-a)"
                    d="M392.299 581.301c0-4.4-3.5-8-7.9-8h-.1l-17.5-.1-17.5.1h-.1c-2.2 0-4.3.9-5.8 2.7l-110.1 125.8-.1.1c-1.8 2.1-2.1 5.2-.5 7.7l22.2 33.8c1.8 2.7 5.4 3.5 8.2 1.8 2.8-1.8 3.7-5.5 1.9-8.3l-18.9-30.1 106.7-117.4 13.9.1h.1l-95.4 112.3-.1.1c-1.5 1.8-2 4.4-.9 6.7l12.9 27.8c1.4 2.9 4.9 4.3 7.8 3 3-1.3 4.4-4.9 3.1-7.9l-10.6-24.3 106.7-120.6c1.3-1.5 2-3.3 2-5.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m306.398 681.401 84-94.9c1.2-1.4 2-3.2 2-5.2 0-4.4-3.499-8-7.899-8h-.101M352.898 589.301h14"
                />
                <path
                    fill="#D9D9D6"
                    d="M573.199 549.201c-3.5 0-6.6-2.3-7.7-5.7l-30.7-104.6c-.7-2.5-.2-5.1 1.4-7.2l19.8-25.5c2.7-3.5 8.7-3 11.3.6 2.8 3.8 1.6 8.9-1.1 12.4l-14.8 19.1 29.6 100.6c1.2 4.2-1.2 8.7-5.4 9.9-.9.3-1.7.4-2.4.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m539.999 456.601-5.2-17.6c-.7-2.5-.2-5.1 1.4-7.2l4.3-5.6"
                />
                <path
                    fill="url(#object-devices-bicycle-b)"
                    d="M402.999 747.601c-3.6 0-6.9-2.5-7.8-6.2l-43.401-186.6c-.7-3 3.001-6 7.401-6 4.9 0 8.499 2.6 8.899 5.4l42.7 183.6c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2Z"
                />
                <path
                    fill="url(#object-devices-bicycle-c)"
                    d="m398.298 749.502-109.9-8.2c-4.1-.3-7.199-3.9-6.899-8 .3-4 3.7-7.1 7.7-6.9l110.199 3.2c5.5.2 9.9 4.8 9.7 10.3-.2 5.5-4.8 9.9-10.3 9.7-.2 0-.4 0-.5-.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-d)"
                    d="m582.699 538.1-19.6-66.8c-1.6-5.5-5.6-7.6-12.3-6.8-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-173.8 84.5c-3.6 1.8-5.2 6.1-3.5 9.9 1.7 3.8 6.2 5.4 9.9 3.7 0 0 137.4-62.5 169.6-77.1 3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2l148.8-208.9 2.3 7.9c1.3 4.4 5.3 7.2 9.6 7.2 1.3 0 2.6-.2 3.8-.8 4.8-2 7.2-7.4 5.7-12.4Z"
                />
                <path
                    fill="url(#object-devices-bicycle-e)"
                    d="m654.598 717.002-13.4-44.1-13.5-44.1-27-88.1c-1-3.2-4-5.6-7.5-5.6h-17.5l-11.3.1h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7 6.8.1h11.6l26.9 81.9 14.4 43.8 14.5 43.7c1 3.1 4.3 4.8 7.4 3.9 3.1-1 4.9-4.3 4-7.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M641.7 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-f)"
                    d="M631.4 762.302c18.17 0 32.9-19.745 32.9-44.1 0-24.356-14.73-44.1-32.9-44.1-18.17 0-32.9 19.744-32.9 44.1 0 24.355 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-g)"
                    d="M535.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-h)"
                    d="M633.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4.1-86.3-52.2-156.4-116.7-156.4Zm-12.3 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M621.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m584.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM688.198 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M599.799 690.302c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M267.7 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-i)"
                    d="M257.399 776.101c23.859 0 43.2-25.923 43.2-57.9s-19.341-57.9-43.2-57.9-43.2 25.923-43.2 57.9 19.341 57.9 43.2 57.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-j)"
                    d="M161.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-k)"
                    d="M259.899 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.2-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m210.598 631.701-.3-.8c-.8-1.9-3.199-2.5-4.799-1.1-10.7 9.1-19.601 21.7-25.901 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.801.5 4.601-1.2 6-13.6 14.499-25.1 24.599-33.4.9-1 1.3-2.4.8-3.6ZM314.299 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 798.202c-33 0-59.7-35.8-59.7-80.1s26.7-80.1 59.7-80.1c1.6 0 3.3.1 4.9.3 30.7 3.3 54.8 37.8 54.8 79.8s-24.1 76.5-54.8 79.8c-1.6.2-3.2.3-4.9.3Z"
                />
                <path
                    fill="#FFD100"
                    d="m254.498 719.501-7.8-12.6c7.4-7.8 26.2-28 104.4-115.6l-10.9-12s-15.3 15.2-107.2 122.1l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1l11.3 16.8 11.3 16.8c1.8 2.6 5.4 3.4 8.1 1.8 2.8-1.7 3.7-5.4 2-8.2l-10.5-17.4Z"
                />
                <path
                    fill="#FFD100"
                    d="m398.8 749.601-137.3-1.5c-4.1 0-7.5-3.4-7.4-7.6 0-4 3.3-7.3 7.3-7.4l137.2-3.5c5.5-.1 10.1 4.2 10.3 9.7.2 5.5-4.2 10.1-9.7 10.3h-.4ZM564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m376.398 588.801 34.4 148.9c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2-3.6 0-6.901-2.5-7.801-6.2l-35.3-152M356.098 573.301l-4.3-18.5c-.7-3 3.001-6 7.401-6 4.9 0 8.499 2.6 8.899 5.4l2.5 10.8M437.199 710.401l104.8-147.2"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M374.299 579.901s137.4-62.5 169.6-77.1c3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2M559.4 465.901c-2.1-1.4-5-1.8-8.6-1.4-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-171.2 83.3M581.799 534.9l-16.1-54.9M605.099 555.1l-4.4-14.3c-1-3.2-4-5.6-7.5-5.6h-.1l-11.3-.3M642.699 677.701l-15-48.8-11.4-37.2M603.299 600l10.9 33.2 13 39.3M608.198 679.901l-15.6-51-23.7-77.7 6.7.1H587.298"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-8-26.4M349.599 730.801l-86.9 2.2-8.2-13.5-7.8-12.6c7.4-7.8 28-30 106.2-117.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M317.199 604.601s7.6-10.1-84.3 96.8l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1 0 0 22.6 34.2 22.9 34.8 1.3 2.1 3.6 3.5 6.3 3.6l137.3 1.5h.4c5.5-.1 9.899-4.7 9.699-10.3-.2-5.6-4.699-9.9-10.299-9.7M564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5"
                />
                <path
                    fill="url(#object-devices-bicycle-l)"
                    d="M397.899 776.8c15.298 0 27.7-16.655 27.7-37.2s-12.402-37.2-27.7-37.2-27.7 16.655-27.7 37.2 12.402 37.2 27.7 37.2Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M388.399 749.2c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.999 778c-15.9 0-28.7-17.2-28.7-38.5s12.9-38.5 28.7-38.5c.8 0 1.6 0 2.4.1 14.8 1.6 26.4 18.2 26.4 38.3 0 20.2-11.6 36.7-26.4 38.3-.8.3-1.6.3-2.4.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.999 789.902c-20.8 0-37.601-22.6-37.601-50.4 0-27.8 16.801-50.4 37.601-50.4 1 0 2.099.1 3.099.2 19.3 2.1 34.5 23.8 34.5 50.2 0 26.4-15.2 48.1-34.5 50.2-1 .2-2.099.2-3.099.2Z"
                />
                <path
                    fill="#63666A"
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    fill="#fff"
                    d="M252.298 751.601c13.752 0 24.9-14.954 24.9-33.4 0-18.447-11.148-33.4-24.9-33.4-13.752 0-24.9 14.953-24.9 33.4 0 18.446 11.148 33.4 24.9 33.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M244.898 686.301c2.8-1.3 5.4-1.5 7.4-1.5 13.8 0 24.9 14.9 24.9 33.4s-11.1 33.4-24.9 33.4c-13.8 0-24.9-14.9-24.9-33.4"
                />
                <path
                    fill="#E1E1DF"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M552.899 416.5h-8.3c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h7.7l1.9-2.2c2.7-3.5 8.7-3 11.3.6 2.8 3.8 2.1 8.2-.6 11.7l-15.3 19.8 8.1 27.5M571 408.5l5.5-8.7c.7-1.2 2-1.9 3.4-1.9H591c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9"
                />
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M519.999 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.2 5-5 5ZM367.698 824.601l-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.7-2.2-.5-4.6-2.7-5.3Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bicycle-a"
                        x1={302.358}
                        x2={322.743}
                        y1={614.027}
                        y2={676.289}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.977} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-b"
                        x1={339.637}
                        x2={412.349}
                        y1={528.494}
                        y2={734.906}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.977} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-c"
                        x1={413.185}
                        x2={284.163}
                        y1={744.14}
                        y2={727.981}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.977} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-d"
                        x1={441.22}
                        x2={506.844}
                        y1={485.413}
                        y2={696.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.959} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-e"
                        x1={571.372}
                        x2={632.76}
                        y1={629.955}
                        y2={629.955}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.977} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-f"
                        x1={631.383}
                        x2={631.383}
                        y1={762.224}
                        y2={674.103}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-g"
                        x1={574.406}
                        x2={574.406}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-h"
                        x1={627.644}
                        x2={627.644}
                        y1={870.757}
                        y2={633.545}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-i"
                        x1={257.447}
                        x2={257.447}
                        y1={776.109}
                        y2={660.216}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-j"
                        x1={200.472}
                        x2={200.472}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-k"
                        x1={253.708}
                        x2={253.708}
                        y1={870.394}
                        y2={631.267}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-l"
                        x1={397.875}
                        x2={397.875}
                        y1={776.723}
                        y2={702.391}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        content: (
            <>
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m470.499 707.501-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.7-2.2-.5-4.6-2.7-5.3ZM630.599 406.801h-39.701c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.701c2.8 0 5 2.2 5 5s-2.2 5-5 5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M402.899 745.601c-2.7 0-5.199-1.9-5.799-4.6l-63.801-274.4c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5l63.8 274.4c.8 3.2-1.3 6.5-4.5 7.2-.5 0-.9.1-1.4.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m362.199 590.701-28.9-124.2c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5"
                />
                <path
                    fill="#002A3A"
                    d="m314.898 446.001 64.1 9.7c2.7.4 4.801 2.8 4.801 5.5 0 3.1-2.501 5.6-5.601 5.6h-65c-9.9 0-19.099-5.3-24.099-13.8l-3-5.2c-.9-1.5-1.001-3.3-.401-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.701 5.3c2 .7 4.199 1.2 6.299 1.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m314.898 446.001 64.1 9.7c2.7.4 4.801 2.8 4.801 5.5 0 3.1-2.501 5.6-5.601 5.6h-65c-9.9 0-19.099-5.3-24.099-13.8l-3-5.2c-.9-1.5-1.001-3.3-.401-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.701 5.3c2 .7 4.199 1.2 6.299 1.6ZM357.398 517.301l8.5 37.4"
                />
                <path
                    fill="#D9D9D6"
                    d="M544.599 416.5c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h24.7l5.5-8.7c.7-1.2 2-1.9 3.4-1.9h11.1c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9h-28.5Z"
                />
                <path
                    fill="url(#object-devices-bicycle-a)"
                    d="M392.299 581.301c0-4.4-3.5-8-7.9-8h-.1l-17.5-.1-17.5.1h-.1c-2.2 0-4.3.9-5.8 2.7l-110.1 125.8-.1.1c-1.8 2.1-2.1 5.2-.5 7.7l22.2 33.8c1.8 2.7 5.4 3.5 8.2 1.8 2.8-1.8 3.7-5.5 1.9-8.3l-18.9-30.1 106.7-117.4 13.9.1h.1l-95.6 112.5-.1.1c-1.5 1.8-2 4.4-.9 6.7l12.9 27.8c1.4 2.9 4.9 4.3 7.8 3 3-1.3 4.4-4.9 3.1-7.9l-10.6-24.3 106.7-120.6c1.4-1.7 2.2-3.5 2.2-5.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m306.299 681.401 84-94.9c1.2-1.4 2-3.2 2-5.2 0-4.4-3.5-8-7.9-8h-.1M352.799 589.301h14.1"
                />
                <path
                    fill="#D9D9D6"
                    d="M573.1 549.201c-3.5 0-6.601-2.3-7.701-5.7l-30.7-104.6c-.7-2.5-.199-5.1 1.401-7.2l19.799-25.5c2.7-3.5 8.7-3 11.3.6 2.8 3.8 1.601 8.9-1.099 12.4l-14.801 19.1 29.6 100.6c1.2 4.2-1.2 8.7-5.4 9.9-.8.3-1.599.4-2.399.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m539.899 456.601-5.2-17.6c-.7-2.5-.199-5.1 1.401-7.2l4.299-5.6"
                />
                <path
                    fill="url(#object-devices-bicycle-b)"
                    d="M402.899 747.601c-3.6 0-6.9-2.5-7.8-6.2l-43.4-186.6c-.7-3 3-6 7.4-6 4.9 0 8.5 2.6 8.9 5.4l42.7 183.6c1 4.3-1.7 8.6-6 9.6-.6.1-1.2.2-1.8.2Z"
                />
                <path
                    fill="url(#object-devices-bicycle-c)"
                    d="m398.199 749.502-109.9-8.2c-4.1-.3-7.2-3.9-6.9-8 .3-4 3.7-7.1 7.7-6.9l110.2 3.2c5.5.2 9.9 4.8 9.7 10.3-.2 5.5-4.8 9.9-10.3 9.7-.2 0-.4 0-.5-.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-d)"
                    d="m582.699 538.1-19.6-66.8c-1.6-5.5-5.6-7.6-12.3-6.8-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-173.8 84.5c-3.6 1.8-5.2 6.1-3.5 9.9 1.7 3.8 6.2 5.4 9.9 3.7 0 0 137.4-62.5 169.6-77.1 3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2l148.8-208.9 2.3 7.9c1.3 4.4 5.3 7.2 9.6 7.2 1.3 0 2.6-.2 3.8-.8 4.8-2 7.1-7.4 5.7-12.4Z"
                />
                <path
                    fill="url(#object-devices-bicycle-e)"
                    d="m654.499 717.002-13.4-44.1-13.5-44.1-27-88.1c-1-3.2-4-5.6-7.5-5.6h-17.5l-11.3.1h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7 6.8.1h11.6l26.9 81.9 14.4 43.8 14.5 43.7c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M641.599 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.201 4.298-7.201 9.6s3.224 9.6 7.201 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-f)"
                    d="M631.299 762.302c18.17 0 32.899-19.745 32.899-44.1 0-24.356-14.729-44.1-32.899-44.1-18.171 0-32.901 19.744-32.901 44.1 0 24.355 14.73 44.1 32.901 44.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-g)"
                    d="M535.698 718.201c0-72.1 41.401-131.2 94.101-136.9-2.8-.3-5.601-.5-8.401-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.601-.2 8.401-.5-52.7-5.8-94.101-64.9-94.101-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-h)"
                    d="M633.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.3-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M621.399 727.802c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m584.399 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.4-2.4.8-3.6ZM688.1 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M599.699 690.302c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M267.7 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-i)"
                    d="M257.399 776.101c23.859 0 43.2-25.923 43.2-57.9s-19.341-57.9-43.2-57.9-43.2 25.923-43.2 57.9 19.341 57.9 43.2 57.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-j)"
                    d="M161.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-k)"
                    d="M259.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4.1-86.3-52.2-156.4-116.7-156.4Zm-12.3 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m210.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM314.199 767.001c-1.6-1.1-3.799-.5-4.599 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.401 2.3-.801 3.5l.301.8c.8 1.9 3.199 2.5 4.799 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 798.202c-33 0-59.7-35.8-59.7-80.1s26.7-80.1 59.7-80.1c1.6 0 3.3.1 4.9.3 30.7 3.3 54.8 37.8 54.8 79.8s-24.1 76.5-54.8 79.8c-1.7.2-3.3.3-4.9.3Z"
                />
                <path
                    fill="#001489"
                    d="m254.399 719.501-7.8-12.6c7.4-7.8 26.2-28 104.4-115.6l-10.9-12s-15.3 15.2-107.2 122.1l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1l11.3 16.8 11.3 16.8c1.8 2.6 5.4 3.4 8.1 1.8 2.8-1.7 3.7-5.4 2-8.2l-10.5-17.4Z"
                />
                <path
                    fill="#001489"
                    d="m398.8 749.601-137.3-1.5c-4.1 0-7.5-3.4-7.4-7.6 0-4 3.3-7.3 7.3-7.4l137.2-3.5c5.5-.1 10.1 4.2 10.3 9.7.1 5.5-4.2 10.1-9.7 10.3h-.4ZM564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m376.299 588.801 34.4 148.9c1 4.3-1.7 8.6-6 9.6-.6.1-1.2.2-1.8.2-3.6 0-6.9-2.5-7.8-6.2l-35.3-152M355.999 573.301l-4.3-18.5c-.7-3 3-6 7.4-6 4.9 0 8.5 2.6 8.9 5.4l2.5 10.8M437.1 710.401l104.9-147.2"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M374.299 579.901s137.4-62.5 169.6-77.1c3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2M559.399 465.901c-2.1-1.4-4.999-1.8-8.599-1.4-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-171.2 83.3M581.699 534.9 565.6 480M605.099 555.1l-4.4-14.3c-1-3.2-4-5.6-7.5-5.6h-.1l-11.3-.3M642.599 677.701l-14.9-48.8-11.4-37.2M603.299 600l10.9 33.2 12.9 39.3M608.099 679.901l-15.5-51-23.8-77.7 6.8.1h11.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-8-26.4M349.5 730.801l-86.9 2.2-8.201-13.5-7.799-12.6c7.4-7.8 28-30 106.2-117.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M317.199 604.601s7.6-10.1-84.3 96.8l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1 0 0 22.6 34.2 22.9 34.8 1.3 2.1 3.6 3.5 6.3 3.6l137.3 1.5h.4c5.5-.1 9.9-4.7 9.7-10.3-.1-5.5-4.7-9.9-10.3-9.7M564.299 535.201h-6.4c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5"
                />
                <path
                    fill="url(#object-devices-bicycle-l)"
                    d="M397.8 776.8c15.298 0 27.7-16.655 27.7-37.2s-12.402-37.2-27.7-37.2c-15.299 0-27.7 16.655-27.7 37.2s12.401 37.2 27.7 37.2Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M388.3 749.2c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.899 778c-15.9 0-28.7-17.2-28.7-38.5s12.9-38.5 28.7-38.5c.8 0 1.6 0 2.4.1 14.8 1.6 26.4 18.2 26.4 38.3 0 20.2-11.6 36.7-26.4 38.3-.8.3-1.6.3-2.4.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.899 789.902c-20.8 0-37.6-22.6-37.6-50.4 0-27.8 16.8-50.4 37.6-50.4 1 0 2.1.1 3.1.2 19.3 2.1 34.5 23.8 34.5 50.2 0 26.4-15.2 48.1-34.5 50.2-1 .2-2 .2-3.1.2Z"
                />
                <path
                    fill="#63666A"
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    fill="#fff"
                    d="M252.199 751.601c13.752 0 24.9-14.954 24.9-33.4 0-18.447-11.148-33.4-24.9-33.4-13.752 0-24.9 14.953-24.9 33.4 0 18.446 11.148 33.4 24.9 33.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M244.799 686.301c2.8-1.3 5.4-1.5 7.4-1.5 13.8 0 24.9 14.9 24.9 33.4s-11.1 33.4-24.9 33.4c-13.8 0-24.9-14.9-24.9-33.4"
                />
                <path
                    fill="#E1E1DF"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M552.899 416.5h-8.3c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h7.7l1.9-2.2c2.7-3.5 8.7-3 11.3.6 2.8 3.8 2.1 8.2-.6 11.7l-15.3 19.8 8.1 27.5M570.898 408.5l5.5-8.7c.7-1.2 2.001-1.9 3.401-1.9h11.099c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.899l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9"
                />
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M519.899 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.2 5-5 5ZM367.7 824.601l-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.6-2.2-.5-4.6-2.7-5.3Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bicycle-a"
                        x1={302.302}
                        x2={322.687}
                        y1={614.027}
                        y2={676.289}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={0.977} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-b"
                        x1={339.581}
                        x2={412.293}
                        y1={528.494}
                        y2={734.906}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={0.977} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-c"
                        x1={413.129}
                        x2={284.107}
                        y1={744.14}
                        y2={727.981}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={0.977} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-d"
                        x1={441.164}
                        x2={506.788}
                        y1={485.413}
                        y2={696.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={0.959} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-e"
                        x1={571.316}
                        x2={632.704}
                        y1={629.955}
                        y2={629.955}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={0.977} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-f"
                        x1={631.325}
                        x2={631.325}
                        y1={762.224}
                        y2={674.103}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-g"
                        x1={574.349}
                        x2={574.349}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-h"
                        x1={627.587}
                        x2={627.587}
                        y1={870.757}
                        y2={633.545}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-i"
                        x1={257.391}
                        x2={257.391}
                        y1={776.109}
                        y2={660.216}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-j"
                        x1={200.415}
                        x2={200.415}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-k"
                        x1={253.653}
                        x2={253.653}
                        y1={870.394}
                        y2={631.267}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-l"
                        x1={397.819}
                        x2={397.819}
                        y1={776.723}
                        y2={702.391}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        content: (
            <>
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m470.599 707.501-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.201.5c-.8 2.2.401 4.7 2.701 5.4l50.9 17.1c2.3.8 4.699-.5 5.399-2.7.6-2.2-.599-4.6-2.699-5.3ZM630.7 406.801H591c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.3 5-5 5Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M402.999 745.601c-2.7 0-5.2-1.9-5.8-4.6l-63.8-274.4c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.5 1.3 7.2 4.5l63.8 274.4c.8 3.2-1.3 6.5-4.5 7.2-.5 0-1 .1-1.4.1Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m362.2 590.701-28.901-124.2c-.8-3.2 1.3-6.5 4.5-7.2 3.2-.8 6.501 1.3 7.201 4.5"
                />
                <path
                    fill="#888B8D"
                    d="m315 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m315 446.001 64.1 9.7c2.7.4 4.8 2.8 4.8 5.5 0 3.1-2.5 5.6-5.6 5.6h-65c-9.9 0-19.1-5.3-24.1-13.8l-3-5.2c-.9-1.5-1-3.3-.4-4.9l.2-.6c1.1-2.8 4.2-4.2 7-3.2l15.7 5.3c2 .7 4.1 1.2 6.3 1.6ZM357.5 517.301l8.4 37.4"
                />
                <path
                    fill="#D9D9D6"
                    d="M544.699 416.5c-1.1 0-2.101-.4-2.801-1.2l-9.399-9.4h-53.101c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.701c1.1 0 2.099.4 2.799 1.2l9.4 9.4h24.701l5.5-8.7c.7-1.2 1.999-1.9 3.399-1.9h11.101c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9h-28.5Z"
                />
                <path
                    fill="url(#object-devices-bicycle-a)"
                    d="M392.298 581.301c0-4.4-3.499-8-7.899-8h-.101l-17.5-.1-17.5.1h-.099c-2.2 0-4.3.9-5.8 2.7l-110.101 125.8-.099.1c-1.8 2.1-2.1 5.2-.5 7.7l22.2 33.8c1.8 2.7 5.4 3.5 8.2 1.8 2.8-1.8 3.7-5.5 1.9-8.3l-18.9-30.1 106.699-117.4 13.901.1h.099l-95.599 112.5-.1.1c-1.5 1.8-2 4.4-.9 6.7l12.9 27.8c1.4 2.9 4.9 4.3 7.8 3 3-1.3 4.4-4.9 3.1-7.9l-10.6-24.3 106.7-120.6c1.5-1.7 2.199-3.5 2.199-5.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m306.398 681.401 84-94.9c1.2-1.4 2-3.2 2-5.2 0-4.4-3.499-8-7.899-8h-.101M352.898 589.301h14"
                />
                <path
                    fill="#D9D9D6"
                    d="M573.199 549.201c-3.5 0-6.6-2.3-7.7-5.7l-30.7-104.6c-.7-2.5-.2-5.1 1.4-7.2l19.8-25.5c2.7-3.5 8.7-3 11.3.6 2.8 3.8 1.6 8.9-1.1 12.4l-14.8 19.1 29.6 100.6c1.2 4.2-1.2 8.7-5.4 9.9-.9.3-1.7.4-2.4.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m539.999 456.601-5.2-17.6c-.7-2.5-.2-5.1 1.4-7.2l4.3-5.6"
                />
                <path
                    fill="url(#object-devices-bicycle-b)"
                    d="M402.999 747.601c-3.6 0-6.9-2.5-7.8-6.2l-43.401-186.6c-.7-3 3.001-6 7.401-6 4.9 0 8.5 2.6 8.9 5.4l42.699 183.6c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2Z"
                />
                <path
                    fill="url(#object-devices-bicycle-c)"
                    d="m398.199 749.502-109.9-8.2c-4.1-.3-7.2-3.9-6.9-8 .3-4 3.7-7.1 7.7-6.9l110.2 3.2c5.5.2 9.9 4.8 9.7 10.3-.2 5.5-4.8 9.9-10.3 9.7-.1 0-.3 0-.5-.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-d)"
                    d="m582.699 538.1-19.6-66.8c-1.6-5.5-5.6-7.6-12.3-6.8-5.6.6-8.9 5.6-7.3 11l1.6 5.3c-1.1.1-2.2.4-3.3.9l-173.8 84.5c-3.6 1.8-5.2 6.1-3.5 9.9 1.7 3.8 6.2 5.4 9.9 3.7 0 0 137.4-62.5 169.6-77.1 3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2l148.8-208.9 2.3 7.9c1.3 4.4 5.3 7.2 9.6 7.2 1.3 0 2.6-.2 3.8-.8 4.8-2 7.2-7.4 5.7-12.4Z"
                />
                <path
                    fill="url(#object-devices-bicycle-e)"
                    d="m654.6 717.002-13.4-44.1-13.5-44.1-27-88.1c-1-3.2-4-5.6-7.5-5.6h-17.5l-11.3.1H558c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.7 87.5 28.8 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7 6.8.1h11.6l26.9 81.9 14.4 43.8 14.5 43.7c1 3.1 4.3 4.8 7.4 3.9 3.1-1 4.9-4.3 4-7.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M641.599 727.802c3.976 0 7.199-4.298 7.199-9.6s-3.223-9.6-7.199-9.6c-3.977 0-7.201 4.298-7.201 9.6s3.224 9.6 7.201 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-f)"
                    d="M631.4 762.302c18.17 0 32.9-19.745 32.9-44.1 0-24.356-14.73-44.1-32.9-44.1-18.17 0-32.9 19.744-32.9 44.1 0 24.355 14.73 44.1 32.9 44.1Z"
                />
                <path
                    fill="url(#object-devices-bicycle-g)"
                    d="M535.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-h)"
                    d="M633.799 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.2-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.7.4-5.5.5-8.4.5Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M621.399 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    fill="#FFB33B"
                    d="m584.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.3-2.4.8-3.6ZM688.2 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.401 2.3-.801 3.5l.301.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M599.799 690.302c6.2-10.8 16-17.8 27-17.8.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.5 31.2 45.5s-13.8 43.6-31.2 45.5c-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M267.7 727.802c3.977 0 7.2-4.298 7.2-9.6s-3.223-9.6-7.2-9.6c-3.976 0-7.2 4.298-7.2 9.6s3.224 9.6 7.2 9.6Z"
                />
                <path
                    fill="url(#object-devices-bicycle-i)"
                    d="M257.399 776.101c23.859 0 43.2-25.923 43.2-57.9s-19.341-57.9-43.2-57.9-43.2 25.923-43.2 57.9 19.341 57.9 43.2 57.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-j)"
                    d="M161.8 718.201c0-72.1 41.4-131.2 94.1-136.9-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.4s45.9 137.4 102.5 137.4c2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
                />
                <path
                    fill="url(#object-devices-bicycle-k)"
                    d="M259.899 561.701c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.4s52.3 156.4 116.7 156.4c2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.3-52.2-156.4-116.7-156.4Zm-12.4 293.8c-56.6 0-102.5-61.5-102.5-137.4s45.9-137.4 102.5-137.4c2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.9 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
                />
                <path
                    fill="#FFB33B"
                    d="m210.499 631.701-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-1 1.4-2.4.8-3.6ZM314.299 767.001c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.5-1.2.1-2.8-1-3.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 798.202c-33 0-59.7-35.8-59.7-80.1s26.7-80.1 59.7-80.1c1.6 0 3.3.1 4.9.3 30.7 3.3 54.8 37.8 54.8 79.8s-24.1 76.5-54.8 79.8c-1.6.2-3.2.3-4.9.3Z"
                />
                <path
                    fill="#63666A"
                    d="m254.498 719.501-7.799-12.6c7.4-7.8 26.2-28 104.4-115.6l-10.9-12s-15.301 15.2-107.201 122.1l-.2.2c-1.9 2.2-2.2 5.5-.5 8.1l11.301 16.8 11.299 16.8c1.8 2.6 5.4 3.4 8.1 1.8 2.8-1.7 3.7-5.4 2-8.2l-10.5-17.4Z"
                />
                <path
                    fill="#63666A"
                    d="m398.8 749.601-137.3-1.5c-4.1 0-7.5-3.4-7.4-7.6 0-4 3.3-7.3 7.3-7.4l137.2-3.5c5.5-.1 10.1 4.2 10.3 9.7.1 5.5-4.2 10.1-9.7 10.3h-.4ZM564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5 28.801 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-26.8-88.1-23.8-77.7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m376.398 588.801 34.4 148.9c1 4.3-1.7 8.6-6 9.6-.6.1-1.199.2-1.799.2-3.6 0-6.9-2.5-7.8-6.2l-35.301-152M356.099 573.301l-4.301-18.5c-.7-3 3.001-6 7.401-6 4.9 0 8.5 2.6 8.9 5.4l2.5 10.8M437.199 710.401l104.8-147.2"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M374.299 579.901s137.4-62.5 169.6-77.1c3.5-1.6 7.7.3 8.7 4 .9 3.2.4 6.6-1.6 9.3l-154.9 217.5c-3.2 4.5-2.2 10.7 2.3 13.9 1.8 1.3 3.8 1.9 5.8 1.9 3.1 0 6.2-1.5 8.2-4.2M559.399 465.901c-2.1-1.4-5-1.8-8.6-1.4-5.6.6-8.899 5.6-7.299 11l1.6 5.3c-1.1.1-2.201.4-3.301.9l-171.199 83.3M581.799 534.9l-16.1-54.9M605.099 555.1l-4.4-14.3c-1-3.2-4-5.6-7.5-5.6h-.1l-11.3-.3M642.599 677.701l-14.9-48.8-11.4-37.2M603.299 600l10.9 33.2 13 39.3M608.199 679.901l-15.6-51-23.701-77.7 6.701.1h11.699"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5 28.801 87.5c1 3.1 4.3 4.8 7.4 3.9 3.2-1 5-4.3 4-7.5l-8-26.4M349.599 730.801l-86.9 2.2-8.2-13.5-7.8-12.6c7.4-7.8 28-30 106.2-117.6"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M317.199 604.601s7.599-10.1-84.301 96.8l-.199.2c-1.9 2.2-2.2 5.5-.5 8.1 0 0 22.6 34.2 22.9 34.8 1.3 2.1 3.599 3.5 6.299 3.6l137.301 1.5h.4c5.5-.1 9.899-4.7 9.699-10.3-.1-5.5-4.7-9.9-10.3-9.7M564.298 535.201h-6.399c-.8 0-1.6.1-2.3.4-4.1 1.3-6.4 5.8-5 9.9l28.699 87.5"
                />
                <path
                    fill="url(#object-devices-bicycle-l)"
                    d="M397.899 776.8c15.298 0 27.7-16.655 27.7-37.2s-12.402-37.2-27.7-37.2-27.7 16.655-27.7 37.2 12.402 37.2 27.7 37.2Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M388.399 749.2c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6c-3.977 0-7.2 4.298-7.2 9.6s3.223 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.999 778c-15.9 0-28.7-17.2-28.7-38.5s12.9-38.5 28.7-38.5c.8 0 1.6 0 2.4.1 14.8 1.6 26.4 18.2 26.4 38.3 0 20.2-11.6 36.7-26.4 38.3-.8.3-1.6.3-2.4.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M393.999 789.902c-20.8 0-37.601-22.6-37.601-50.4 0-27.8 16.801-50.4 37.601-50.4 1 0 2.1.1 3.1.2 19.3 2.1 34.5 23.8 34.5 50.2 0 26.4-15.2 48.1-34.5 50.2-1 .2-2.1.2-3.1.2Z"
                />
                <path
                    fill="#63666A"
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="m361.499 827.3-15.2-8.2 28.4-82.4c1.1-3.1 4.4-4.7 7.5-3.7 3 1 4.6 4.1 3.8 7.1l-16.7 62.1"
                />
                <path
                    fill="#fff"
                    d="M252.298 751.601c13.752 0 24.901-14.954 24.901-33.4 0-18.447-11.149-33.4-24.901-33.4s-24.9 14.953-24.9 33.4c0 18.446 11.148 33.4 24.9 33.4Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M244.898 686.301c2.8-1.3 5.4-1.5 7.4-1.5 13.8 0 24.901 14.9 24.901 33.4s-11.101 33.4-24.901 33.4c-13.8 0-24.9-14.9-24.9-33.4"
                />
                <path
                    fill="#E1E1DF"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M247.499 727.802c3.976 0 7.2-4.298 7.2-9.6s-3.224-9.6-7.2-9.6-7.2 4.298-7.2 9.6 3.224 9.6 7.2 9.6Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M552.899 416.5h-8.3c-1.1 0-2.1-.4-2.8-1.2l-9.4-9.4h-53.1c-2.2 0-4-1.8-4-4s1.8-4 4-4h54.7c1.1 0 2.1.4 2.8 1.2l9.4 9.4h7.7l1.9-2.2c2.7-3.5 8.7-3 11.3.6 2.8 3.8 2.1 8.2-.6 11.7l-15.3 19.8 8.1 27.5M571 408.5l5.5-8.7c.7-1.2 2-1.9 3.4-1.9H591c2.2 0 4 1.8 4 4s-1.8 4-4 4h-8.9l-5.5 8.7c-.7 1.2-2 1.9-3.4 1.9"
                />
                <path
                    fill="#63666A"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M519.999 406.801h-39.7c-2.8 0-5-2.2-5-5s2.2-5 5-5h39.7c2.8 0 5 2.2 5 5s-2.3 5-5 5ZM367.7 824.601l-50.7-17.6c-2.2-.8-4.7.4-5.4 2.6l-.2.5c-.8 2.2.4 4.7 2.7 5.4l50.9 17.1c2.3.8 4.7-.5 5.4-2.7.7-2.2-.5-4.6-2.7-5.3Z"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bicycle-a"
                        x1={302.345}
                        x2={322.731}
                        y1={614.027}
                        y2={676.289}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#63666A" />
                        <stop offset={0.977} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-b"
                        x1={339.625}
                        x2={412.337}
                        y1={528.494}
                        y2={734.906}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#63666A" />
                        <stop offset={0.977} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-c"
                        x1={413.173}
                        x2={284.151}
                        y1={744.14}
                        y2={727.981}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#63666A" />
                        <stop offset={0.977} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-d"
                        x1={441.208}
                        x2={506.832}
                        y1={485.413}
                        y2={696.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#63666A" />
                        <stop offset={0.959} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-e"
                        x1={571.362}
                        x2={632.75}
                        y1={629.955}
                        y2={629.955}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#63666A" />
                        <stop offset={0.977} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-f"
                        x1={631.37}
                        x2={631.37}
                        y1={762.224}
                        y2={674.103}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-g"
                        x1={574.394}
                        x2={574.394}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-h"
                        x1={627.631}
                        x2={627.631}
                        y1={870.757}
                        y2={633.545}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-i"
                        x1={257.435}
                        x2={257.435}
                        y1={776.109}
                        y2={660.216}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-j"
                        x1={200.459}
                        x2={200.459}
                        y1={855.512}
                        y2={580.812}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-k"
                        x1={253.696}
                        x2={253.696}
                        y1={870.394}
                        y2={631.267}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#888B8D" />
                        <stop offset={1} stopColor="#ABADAD" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bicycle-l"
                        x1={397.863}
                        x2={397.863}
                        y1={776.723}
                        y2={702.391}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F3F3F3" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={900}
            height={900}
            viewBox={`0 0 ${900} ${900}`}
            fill="none"
            id="object-devices-bicycle"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
