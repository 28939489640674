import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 294,
        height: 379,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m53.13 184.402-51.54 193h14l60-176h79.5l6.5 176h14l6.5-176h110.5v-17"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m142.07 184.402-22.48 193h-14l8.224-193"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M284.877 171.717c-5.457-6.953-13.889-6.311-38.735-6.311-18.602 0-132.667.36-197.916.648-18.81.083-33.972-15.38-33.525-34.185l1.863-78.347c.637-26.787 21.22-48.985 47.912-51.334C74.9 1.27 86.48.842 98.589 1.406a240.258 240.258 0 0 1 40.629 5.39 52.823 52.823 0 0 1 39.3 36.775l46.071 157.835 54 176h14l-37.725-161.942M113.813 184.402H53.129M292.59 184.402H142.07"
                />
            </>
        ),
    },
    'Color #1': {
        width: 293,
        height: 378,
        content: (
            <>
                <path
                    fill="url(#object-office-chair-a)"
                    d="M.908 377.402h14L65.94 225.879H41.733L.908 377.402Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m52.907 184.402-11.175 41.477h24.206l13.97-41.477h-27Z"
                />
                <path
                    fill="url(#object-office-chair-b)"
                    d="M104.908 377.402h14l16.967-151.523h-24.259l-6.708 151.523Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m113.407 185.402-1.792 40.477h24.259l4.533-40.477h-27Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M181.889 184.402h-27l1.293 41.477h24.207l1.5-41.477Z"
                />
                <path
                    fill="#3A383A"
                    d="m156.182 225.879 4.726 151.523h14l5.481-151.523h-24.207Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m246.59 184.541-27.111.084 12.504 41.254h24.32l-9.713-41.338Z"
                />
                <path
                    fill="#3A383A"
                    d="m291.908 377.402-35.606-151.523h-24.32l45.926 151.523h14Z"
                />
                <path
                    fill="url(#object-office-chair-c)"
                    d="m240.912 165.35 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.69-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222H291.91c-6.954-18.254-34.115-19.147-50.997-19.053Z"
                />
                <path
                    fill="url(#object-office-chair-d)"
                    d="M54.303 166.226c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867L177.837 43.578a52.824 52.824 0 0 0-39.303-36.786 240.32 240.32 0 0 0-40.622-5.389c-11.795-.549-23.087-.156-33.298.712-27.036 2.3-47.983 24.6-48.451 51.728l-.817 47.397c.263 13.544 1.99 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M54.301 166.226c45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H48.136c-18.807 0-33.96-15.418-33.637-34.222l1.661-96.338c.468-27.129 21.415-49.43 48.451-51.728 10.211-.868 21.503-1.26 33.298-.712a240.292 240.292 0 0 1 40.622 5.39 52.82 52.82 0 0 1 39.303 36.785l27.243 100.619"
                />
                <defs>
                    <linearGradient
                        id="object-office-chair-a"
                        x1={0.908}
                        x2={65.939}
                        y1={301.64}
                        y2={301.64}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-b"
                        x1={104.908}
                        x2={135.875}
                        y1={-1236.73}
                        y2={-1236.73}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-c"
                        x1={73.123}
                        x2={203.345}
                        y1={223.325}
                        y2={93.103}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.413} stopColor="#ECF3F9" />
                        <stop offset={0.576} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-d"
                        x1={48.539}
                        x2={186.034}
                        y1={176.104}
                        y2={43.327}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 293,
        height: 378,
        content: (
            <>
                <path
                    fill="url(#object-office-chair-a)"
                    d="M.658 377.415h14L65.69 225.891H41.483L.658 377.415Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M52.657 184.414 41.482 225.89h24.206l13.97-41.476h-27Z"
                />
                <path
                    fill="url(#object-office-chair-b)"
                    d="M104.658 377.415h14l16.967-151.524h-24.259l-6.708 151.524Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m113.157 185.414-1.792 40.476h24.259l4.533-40.476h-27Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M181.639 184.414h-27l1.293 41.476h24.207l1.5-41.476Z"
                />
                <path
                    fill="#3A383A"
                    d="m155.932 225.891 4.726 151.524h14l5.481-151.524h-24.207Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m246.34 184.553-27.111.084 12.504 41.253h24.32l-9.713-41.337Z"
                />
                <path
                    fill="#3A383A"
                    d="m291.658 377.415-35.606-151.524h-24.32l45.926 151.524h14Z"
                />
                <path
                    fill="url(#object-office-chair-c)"
                    d="m240.66 165.362 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.69-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
                />
                <path
                    fill="url(#object-office-chair-d)"
                    d="M54.05 166.236c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867L177.584 43.588a52.824 52.824 0 0 0-39.303-36.786 240.32 240.32 0 0 0-40.622-5.389c-11.795-.549-23.087-.156-33.298.712-27.037 2.3-47.984 24.6-48.452 51.73l-.817 47.396c.263 13.544 1.99 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659v-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M15.188 95.606c-.188 15.729 1.622 30.237 3.857 41.972 3.19 16.744 17.96 28.768 35.004 28.659 45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H47.884c-18.807 0-33.96-15.418-33.637-34.222l1.661-96.338C16.376 26.726 37.323 4.425 64.36 2.127 74.57 1.26 85.862.867 97.657 1.415a240.292 240.292 0 0 1 40.622 5.39 52.82 52.82 0 0 1 39.303 36.785l27.243 100.619a28.306 28.306 0 0 0 25.774 20.867l9.102.291"
                />
                <defs>
                    <linearGradient
                        id="object-office-chair-a"
                        x1={0.658}
                        x2={1.658}
                        y1={301.653}
                        y2={301.653}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-b"
                        x1={104.658}
                        x2={135.625}
                        y1={301.653}
                        y2={301.653}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-c"
                        x1={72.871}
                        x2={203.093}
                        y1={223.337}
                        y2={93.114}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.413} stopColor="#FFE4B8" />
                        <stop offset={0.54} stopColor="#EBC38C" />
                        <stop offset={0.576} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-d"
                        x1={48.286}
                        x2={185.781}
                        y1={176.115}
                        y2={43.337}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#E5B97F" />
                        <stop offset={0.035} stopColor="#E5BA81" />
                        <stop offset={1} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 293,
        height: 378,
        content: (
            <>
                <path
                    fill="url(#object-office-chair-a)"
                    d="M.408 377.415h14L65.44 225.891H41.233L.408 377.415Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M52.407 184.414 41.232 225.89h24.206l13.97-41.476h-27Z"
                />
                <path
                    fill="url(#object-office-chair-b)"
                    d="M104.408 377.415h14l16.967-151.524h-24.259l-6.708 151.524Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m112.907 185.414-1.792 40.476h24.259l4.533-40.476h-27Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M181.389 184.414h-27l1.293 41.476h24.207l1.5-41.476Z"
                />
                <path
                    fill="#3A383A"
                    d="m155.682 225.891 4.726 151.524h14l5.481-151.524h-24.207Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m246.09 184.553-27.111.084 12.504 41.253h24.32l-9.713-41.337Z"
                />
                <path
                    fill="#3A383A"
                    d="m291.408 377.415-35.606-151.524h-24.32l45.926 151.524h14Z"
                />
                <path
                    fill="url(#object-office-chair-c)"
                    d="m240.41 165.362 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.69-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
                />
                <path
                    fill="url(#object-office-chair-d)"
                    d="M53.799 166.236c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867L177.333 43.588A52.824 52.824 0 0 0 138.03 6.802a240.32 240.32 0 0 0-40.622-5.389C85.613.864 74.32 1.257 64.11 2.125c-27.036 2.3-47.983 24.6-48.451 51.728l-.817 47.397c.263 13.544 1.99 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M14.938 95.605c-.188 15.729 1.622 30.237 3.857 41.972 3.19 16.744 17.96 28.768 35.004 28.659 45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H47.634c-18.807 0-33.96-15.418-33.637-34.222l1.661-96.338c.468-27.129 21.415-49.43 48.451-51.728 10.211-.868 21.503-1.26 33.298-.712a240.292 240.292 0 0 1 40.622 5.39 52.821 52.821 0 0 1 39.303 36.785l27.243 100.619a28.306 28.306 0 0 0 25.774 20.867l9.102.291"
                />
                <defs>
                    <linearGradient
                        id="object-office-chair-a"
                        x1={0.408}
                        x2={65.439}
                        y1={301.653}
                        y2={301.653}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-b"
                        x1={104.408}
                        x2={135.375}
                        y1={-1282.2}
                        y2={-1282.2}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-c"
                        x1={72.621}
                        x2={202.843}
                        y1={223.336}
                        y2={93.114}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.413} stopColor="#FCCBBB" />
                        <stop offset={0.518} stopColor="#F0AB8F" />
                        <stop offset={0.576} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-d"
                        x1={48.035}
                        x2={185.53}
                        y1={176.115}
                        y2={43.337}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#EA9975" />
                        <stop offset={0.207} stopColor="#EDA384" />
                        <stop offset={1} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 293,
        height: 378,
        content: (
            <>
                <path
                    fill="url(#object-office-chair-a)"
                    d="M.363 377.415h14l51.031-151.524H41.188L.363 377.415Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M52.362 184.414 41.188 225.89h24.207l13.968-41.476h-27Z"
                />
                <path
                    fill="url(#object-office-chair-b)"
                    d="M104.363 377.415h14l16.967-151.524h-24.259l-6.708 151.524Z"
                />
                <path
                    fill="#E2E2E2"
                    d="m112.862 185.414-1.792 40.476h24.259l4.533-40.476h-27Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M181.344 184.414h-27l1.293 41.476h24.206l1.501-41.476Z"
                />
                <path
                    fill="#3A383A"
                    d="m155.637 225.891 4.726 151.524h14l5.48-151.524h-24.206Z"
                />
                <path
                    fill="#AEAEAF"
                    d="m246.044 184.555-27.11.084 12.504 41.253h24.32l-9.714-41.337Z"
                />
                <path
                    fill="#3A383A"
                    d="m291.364 377.415-35.607-151.524h-24.319l45.926 151.524h14Z"
                />
                <path
                    fill="url(#object-office-chair-c)"
                    d="m240.367 165.36 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.69-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
                />
                <path
                    fill="url(#object-office-chair-d)"
                    d="M53.758 166.232c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867L177.292 43.584a52.824 52.824 0 0 0-39.303-36.786A240.322 240.322 0 0 0 97.367 1.41C85.572.86 74.28 1.253 64.069 2.121c-27.036 2.3-47.983 24.6-48.451 51.728l-.817 47.397c.263 13.544 1.99 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M53.756 166.232c45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H47.591c-18.807 0-33.96-15.418-33.637-34.222l1.661-96.338C16.083 26.72 37.03 4.42 64.066 2.121 74.277 1.253 85.57.861 97.364 1.41a240.293 240.293 0 0 1 40.622 5.39 52.82 52.82 0 0 1 39.303 36.785l27.243 100.619"
                />
                <defs>
                    <linearGradient
                        id="object-office-chair-a"
                        x1={0.363}
                        x2={65.394}
                        y1={0.162}
                        y2={0.162}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-b"
                        x1={104.363}
                        x2={135.33}
                        y1={0.162}
                        y2={0.162}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-c"
                        x1={72.578}
                        x2={202.8}
                        y1={223.335}
                        y2={93.112}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.413} stopColor="#FFF4C3" />
                        <stop offset={0.453} stopColor="#FFEC96" />
                        <stop offset={0.544} stopColor="#FFD828" />
                        <stop offset={0.576} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-chair-d"
                        x1={47.994}
                        x2={185.489}
                        y1={0.157}
                        y2={43.333}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.007} stopColor="#FFD101" />
                        <stop offset={0.698} stopColor="#FFEA8B" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-chair"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
