import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7340)"
            d="M494.768 223.337a109.595 109.595 0 00-20.026-15.329 31.035 31.035 0 012.394 13.895 65.928 65.928 0 01-1.451 10.762l2.169 4.754c.055.12.133.228.229.318a1.018 1.018 0 00.681.267.996.996 0 00.42-.092c2.598-1.201 15.601-7.426 16.406-12.093a2.714 2.714 0 00-.822-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7340)"
            d="M463.278 269.778a300.604 300.604 0 00-4.603-5.338 27.324 27.324 0 01-6.867-19.895 27.306 27.306 0 019.213-18.924 27.465 27.465 0 0118.228-6.918 27.528 27.528 0 0120.592 9.263l.085.095a314.14 314.14 0 005.371 5.938l.708.766-42.08 35.78-.647-.767z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7340"
                x1="507.299"
                x2="369.372"
                y1="207.676"
                y2="277.291"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7340"
                x1="514.246"
                x2="376.318"
                y1="221.443"
                y2="291.057"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
