import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11282_7075)"
            d="M392.884 257.658c.021-.017.032-.042.054-.058l4.024-2.86a4.36 4.36 0 014.842-.029l2.352 1.466c.087-1.649.24-3.309.482-4.945 3.416-23.09 13.157-30.795 13.157-30.795-8.895-1.027-17.797-1.716-26.578-2.148-31.633-3.417-41.864 42.951-12.35 53.509 0 0 2.174.624 5.585 1.451l7.223-14.177a4.041 4.041 0 011.209-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11282_7075"
                x1="359.657"
                x2="405.054"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
