import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m552.519 313.777-9.589.166-9.588.31a151.789 151.789 0 0 1-19.177-.72c-3.196-.339-6.392-.575-9.588-1.023l-4.795-.608c-1.598-.207-3.196-.482-4.794-.72 4.151-11.05 3.771-27.126 0-31.81 1.598-.238 3.196-.514 4.794-.72l4.795-.609c3.196-.448 6.392-.684 9.588-1.023a151.789 151.789 0 0 1 19.177-.72l9.588.31 9.589.167a18.5 18.5 0 0 1 18.178 18.821 18.533 18.533 0 0 1-18.178 18.179Z"
            fill="url(#female-34-25-shirt-1-arms-opt-1-right-a)"
        />
        <path
            d="M494.988 311.177c4.151-11.05 3.771-27.125 0-31.81"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M392.685 257.476c.021-.018.032-.042.055-.058l4.024-2.861a4.365 4.365 0 0 1 4.841-.028l1.715.829c.068-1.656.229-3.308.482-4.946 3.406-20.413 7.418-23.521 11.414-29.972a411.191 411.191 0 0 0-23.489-1.805c-31.633-3.417-42.573 42.423-13.059 52.981 0 0 2.175.624 5.585 1.451l7.224-14.178a4.054 4.054 0 0 1 1.208-1.413Z"
            fill="url(#female-34-25-shirt-1-arms-opt-1-right-b)"
        />
        <path
            d="M402.909 256.423c.082-1.851.263-3.696.543-5.527 2.638-17.834 8.962-26.626 11.939-29.891-7.782-.851-15.985-1.492-24.42-1.907a30.054 30.054 0 0 0-3.217-.176M379.004 270.672c.034.009 2.579.734 6.461 1.652"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M400.899 219.961c-10.735 8.458-16.807 30.614-15.058 52.128M395.26 247.648a162.23 162.23 0 0 0-1.217 9.557"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-1-right-a"
                x1={494.988}
                y1={295.276}
                x2={570.7}
                y2={295.276}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-1-right-b"
                x1={359.569}
                y1={276.641}
                x2={403.88}
                y2={217.371}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
