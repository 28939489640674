import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-2">
        <path
            fill={SKIN_COLOR[color]}
            d="M408.034 204.59c2.077 7.244 4.573 14.366 6.887 21.541a156.516 156.516 0 0 1 5.407 21.97c1.354 7.453 2.495 14.968 3.341 22.568a197.77 197.77 0 0 1 1.357 23.143 23.485 23.485 0 0 1-23.706 23.27 23.728 23.728 0 0 1-19.517-10.751 197.614 197.614 0 0 1-11.22-20.286c-3.348-6.876-6.399-13.837-9.238-20.86a156.738 156.738 0 0 1-7.172-21.458c-1.878-7.302-3.575-14.656-5.691-21.888a31.002 31.002 0 0 1 21.046-38.458 30.99 30.99 0 0 1 23.589 2.558 30.992 30.992 0 0 1 14.87 18.488l.047.163Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m404.34 276.403 13.446-.067 13.444-.13c8.963-.062 17.934.141 26.908.496 4.488.211 8.975.362 13.465.637 4.489.24 8.978.492 13.47.844 4.491.335 8.981.62 13.474.996 4.494.443 8.986.785 13.483 1.33a15.674 15.674 0 0 1 13.67 17.444 15.702 15.702 0 0 1-12.836 13.545c-4.461.787-8.928 1.37-13.392 2.053-4.466.617-8.935 1.143-13.402 1.719-4.466.593-8.935 1.085-13.405 1.566-4.469.516-8.941.908-13.411 1.36-8.943.837-17.889 1.521-26.843 1.941l-13.432.593-13.43.656a22.493 22.493 0 0 1-16.216-5.807 22.502 22.502 0 0 1-1.554-31.789 22.494 22.494 0 0 1 16.561-7.387Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m407.984 204.426.048.164c2.076 7.244 4.572 14.366 6.887 21.541a156.652 156.652 0 0 1 5.406 21.97c1.355 7.453 2.496 14.967 3.341 22.568.053.486.104.974.155 1.461m-42.02 34.202a197.91 197.91 0 0 1-11.22-20.286c-3.347-6.876-6.399-13.838-9.237-20.86a156.756 156.756 0 0 1-7.173-21.459c-1.877-7.301-3.574-14.655-5.691-21.887m164.386 89.661c-4.461.786-8.928 1.37-13.392 2.053-4.466.616-8.935 1.143-13.402 1.719-4.466.593-8.936 1.085-13.405 1.566-4.469.516-8.941.908-13.411 1.36-8.943.837-17.889 1.521-26.842 1.941l-13.433.592-13.429.657c-10.085.493-18.337-6.013-23.751-15.057m35.988-29.996 13.444-.13c8.963-.062 17.934.141 26.908.496 4.488.211 8.975.362 13.465.637 4.489.24 8.978.492 13.47.844 4.491.335 8.981.62 13.474.996 4.494.443 8.986.785 13.483 1.33"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M511.54 311.337a82.645 82.645 0 0 0 28.957-2.472c3.206-.914 6.418-1.858 9.584-2.923l1.465-.3c12.53-2.041 23.969-9.074 33.754-16.94a3.143 3.143 0 0 0 1.153-3.557 1.872 1.872 0 0 0 .651-2.113 2.97 2.97 0 0 0-1.422-1.688c-.943-.562-2.063-.136-3.152.006l-.144-1.006a2.398 2.398 0 0 0-2.246-1.549 25.13 25.13 0 0 0-10.306 2.204c-3.701 1.687-7.263 3.724-11.143 4.946a28.628 28.628 0 0 1-21.117-1.666l-2.378-1.268c2.235-3.019 5.554-5.007 8.714-7.039a27.057 27.057 0 0 0 6.877-5.721 2.438 2.438 0 0 0-.271-3.297 2.462 2.462 0 0 0-2.963-.253c-3.848 2.359-8.711 3.499-12.85 5.175-4.713 1.908-9.51 3.688-13.877 6.291-4.367 2.603-8.34 6.128-10.572 10.696"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M535.196 283.011c2.235-3.019 5.554-5.007 8.714-7.039a27.037 27.037 0 0 0 6.877-5.721 2.438 2.438 0 0 0-.271-3.297 2.462 2.462 0 0 0-2.963-.253c-3.848 2.359-8.711 3.499-12.85 5.175-4.713 1.908-9.51 3.688-13.877 6.291-4.367 2.603-8.34 6.128-10.572 10.696m40.093 9.353c6.138-.086 11.762-3.306 16.854-6.735 4.41-2.97 8.783-6.225 13.735-8.048a2.396 2.396 0 0 0 1.45-3.091 2.396 2.396 0 0 0-2.246-1.549 25.13 25.13 0 0 0-10.306 2.204c-3.701 1.686-7.263 3.724-11.142 4.945a28.62 28.62 0 0 1-21.118-1.666"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m586.097 284.016-9.489 7.237a114.982 114.982 0 0 1-11.871 8.29c-7.508 4.354-15.89 6.946-24.237 9.322a82.622 82.622 0 0 1-28.957 2.473"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M585.3 288.699c-9.784 7.866-21.224 14.899-33.753 16.94"
        />
    </g>
);

export default SvgComponent;
