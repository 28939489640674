import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string]> = {
    white: ['#FFDBAE', '#E3C094', '#CDAB81'],
    black: ['#6C453A', '#634036', '#4D352C'],
    brown: ['#CB865E', '#B07553', '#9A684B'],
    yellow: ['#FCB47F', '#E19A6D', '#CB865E'],
};


const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-walk-1">
        <path
            fill={SKIN_COLOR[color][0]}
            d="M420.202 606.001c-.9-14.4-1.4-28.8-2-43.1l-.9-21.601-.5-10.799-.2-5.4-.1-5.4c-.5-14.4 0-28.6.6-42.8.3-7.1.7-14.2 1.3-21.3l.4-5.301.5-5.299c.4-3.5.7-7.1 1.2-10.6 3.9-27.8 29.6-47.2 57.4-43.3 25.9 3.6 44.5 26.099 43.8 51.699l-.1 5.401c-.1 3.6-.3 7.099-.6 10.599l-.5 5.301-.6 5.3c-.3 3.5-.9 7-1.3 10.5s-1.1 7-1.6 10.5c-2.4 14-5 27.9-8.7 41.7-1.8 6.9-4 13.7-6.3 20.6l-3.5 10.2c-1.2 3.4-2.5 6.8-3.7 10.2-5.2 13.6-10.8 27.1-18 40.4l-2.3 4.299c-7.6 14-25.1 19.201-39.1 11.601-9.2-4.7-14.5-13.9-15.2-23.4Z"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M346.803 773.601c1.8-8 3.699-15.8 5.699-23.7 1-3.9 2-7.9 3-11.7l3.201-11.7c2.1-7.8 4.3-15.5 6.2-23.4 1.9-7.9 3.6-15.9 5.8-23.6 4.3-15.5 11.8-29.501 19.9-43.201 4.1-6.8 8.599-13.499 13.399-19.899 1.2-1.6 2.501-3.2 3.701-4.8 1.3-1.6 2.6-3.1 4-4.7 2.7-3.1 5.6-6.1 8.7-9l8.9-8.101c10.7-9.7 27.3-8.899 37 1.801 7.8 8.6 8.8 20.9 3.4 30.5l-23.3 41.5-11.7 20.699-11.8 20.701c-7.9 13.8-15.7 27.599-23.7 41.299l-23.8 41.301c-4.4 7.6-14.1 10.2-21.8 5.8-6.2-3.6-9.101-10.6-7.601-17.2l.801-2.6Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M517.804 422c2.7 6.5 4.1 13.6 3.9 21l-.1 5.4c-.1 3.6-.3 7.1-.6 10.6l-.5 5.3-.6 5.301c-.3 3.5-.9 7-1.3 10.5s-1.1 7-1.6 10.5c-2.4 14-5 27.899-8.7 41.699-1.8 6.9-4 13.7-6.3 20.6l-3.5 10.201c-1.2 3.4-2.5 6.799-3.7 10.199-5.2 13.6-21.8 45.2-21.8 45.2m-53.3-20.2c-.6-11.8-1-23.6-1.6-35.5l-.9-21.599-.5-10.801-.2-5.4-.1-5.399c-.5-14.4 0-28.601.6-42.801.3-7.1.7-14.2 1.3-21.3l.4-5.299.5-5.301c.4-3.5.7-7.1 1.2-10.6m52.5 184.1-26.4 45.201-11.7 20.699-11.8 20.7c-7.9 13.8-15.7 27.6-23.7 41.3l-23.8 41.301c-4.4 7.6-14.1 10.199-21.8 5.799-6.2-3.6-9.1-10.599-7.6-17.199l.6-2.701c1.8-8 3.7-15.8 5.7-23.7 1-3.9 2-7.899 3-11.699l3.2-11.701c2.1-7.8 4.3-15.5 6.2-23.4 1.9-7.9 3.6-15.9 5.8-23.6 4.3-15.5 11.8-29.499 19.9-43.199 4.1-6.8 8.6-13.501 13.4-19.901 1.2-1.6 2.5-3.2 3.7-4.8 1.3-1.6 2.6-3.1 4-4.7 2-2.3 2.8-3.3 5.1-5.5"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M345.903 777.5c-1 4.2-7.501 19.5-11.901 29.3-2.1 4.7.3 10.2 5.1 12l19.101 7.1c4.1 1.5 7.899 3.601 11.299 6.201 3.6 2.8 9.601 6.8 18.201 10 3.8 1.4 18.6 4.6 25.6 6 2.2.4 4.299-.9 4.799-3 .4-1.6-.099-3.201-1.299-4.301-2.6-2.2-7.101-6-10.801-8.9-16.8-13-29.699-43.299-29.699-43.299"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M345.903 777.5c-1 4.2-7.501 19.5-11.901 29.3-2.1 4.7.3 10.2 5.1 12l19.101 7.1c4.1 1.5 7.899 3.601 11.299 6.201 3.6 2.8 9.601 6.8 18.201 10 3.8 1.4 18.6 4.6 25.6 6 2.2.4 4.299-.9 4.799-3 .4-1.6-.099-3.201-1.299-4.301-2.6-2.2-7.101-6-10.801-8.9-16.8-13-29.699-43.299-29.699-43.299"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M435.703 818.4c-1.7-8.8-3.3-17.5-4.7-26.3-.7-4.399-1.5-8.8-2.2-13.1l-2-13.1c-1.4-8.8-2.6-17.5-4.2-26.3-1.5-8.8-3.4-17.5-4.6-26.3-2.6-17.5-1.6-34.9 0-52.4.9-8.7 2.1-17.4 3.9-26.1.4-2.2.9-4.3 1.4-6.5.5-2.2 1-4.3 1.6-6.5 1.2-4.3 2.5-8.7 4.2-13l4-10.3c5.2-13.6 20.5-20.4 34.2-15.2 11.1 4.3 17.7 15.3 16.9 26.6l-3.8 52.301-1.9 26.099-2.1 26.1c-1.4 17.4-2.7 34.801-4.2 52.301l-4.4 52.299c-.7 8.8-8.4 15.3-17.2 14.6-7.3-.6-13-6-14.3-12.8l-.6-2.4Z"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M428.903 617.801c-7-13.2-13.601-26.4-20.401-39.7l-10.099-19.9-5.1-9.9-2.5-5-2.4-5c-6.6-13.3-12.301-26.9-17.901-40.5-2.8-6.8-5.4-13.6-8-20.5l-1.9-5.2-1.799-5.2c-1.2-3.5-2.4-6.9-3.5-10.4-8.2-26.9 7-55.3 33.9-63.5 25-7.6 51.3 5 61.5 28.4l2.1 5c1.5 3.4 2.8 6.8 4.1 10.2l1.9 5.2 1.699 5.2c1.2 3.5 2.201 7 3.301 10.5s2.1 7 3.1 10.6c3.9 14.1 7.499 28.4 10.099 42.9 1.3 7.3 2.3 14.6 3.1 22l1.201 11.1c.4 3.7.7 7.5 1 11.2 1.2 15 1.9 30.1 1.1 45.6l-.2 4.7c-.8 15.9-14.4 28.1-30.3 27.3-10.5-.4-19.4-6.5-24-15.1Z"
        />
        <path
            fill="url(#man-bold-34-25-legs-walk-1-a)"
            d="M428.903 617.801c-7-13.2-13.601-26.4-20.401-39.7l-10.099-19.9-5.1-9.9-2.5-5-2.4-5c-6.6-13.3-12.301-26.9-17.901-40.5-2.8-6.8-5.4-13.6-8-20.5l-1.9-5.2-1.799-5.2c-1.2-3.5-2.4-6.9-3.5-10.4-8.2-26.9 7-55.3 33.9-63.5 25-7.6 51.3 5 61.5 28.4l2.1 5c1.5 3.4 2.8 6.8 4.1 10.2l1.9 5.2 1.699 5.2c1.2 3.5 2.201 7 3.301 10.5s2.1 7 3.1 10.6c3.9 14.1 7.499 28.4 10.099 42.9 1.3 7.3 2.3 14.6 3.1 22l1.201 11.1c.4 3.7.7 7.5 1 11.2 1.2 15 1.9 30.1 1.1 45.6l-.2 4.7c-.8 15.9-14.4 28.1-30.3 27.3-10.5-.4-19.4-6.5-24-15.1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m436.203 820.8-.5-2.4c-1.7-8.8-3.3-17.499-4.7-26.299-.7-4.4-1.5-8.801-2.2-13.101l-2-13.1c-1.4-8.8-2.6-17.499-4.2-26.299-1.5-8.8-3.4-17.501-4.6-26.301-2.6-17.5-1.6-34.9 0-52.4.9-8.7 2.1-17.4 3.9-26.1.4-2.2.9-4.3 1.4-6.5.5-2.2 1-4.3 1.6-6.5.3-1.3.7-2.6 1.1-3.9m58.2-7.9-3.8 52.3-1.901 26.1-2.099 26.1c-1.4 17.4-2.7 34.8-4.2 52.3l-4.4 52.301m-17.1-397.601 2.1 5c1.5 3.4 2.8 6.801 4.1 10.201l1.9 5.199 1.7 5.201c1.2 3.5 2.2 7 3.3 10.5 1.1 3.5 2.1 7 3.1 10.6 3.9 14.1 7.5 28.4 10.1 42.9 1.3 7.3 2.3 14.6 3.1 22l1.2 11.1c.4 3.7.7 7.499 1 11.199 1.2 15 2.5 32.9 1.9 54.6m-57.5 3.601c-6.2-11.8-12.101-23.6-18.201-35.4L398.403 558.3l-5.1-9.9-2.5-5-2.4-5c-6.6-13.3-12.301-26.9-17.901-40.5-2.8-6.8-5.4-13.6-8-20.5l-1.899-5.199-1.8-5.201c-1.2-3.5-2.4-6.899-3.5-10.399m108.9 153.6h15.4"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M436.504 818.701c.5 4.3-.3 20.899-1 31.599-.3 5.2 3.8 9.5 9 9.5h74.8c2.3 0 4.1-1.799 4.1-4.099 0-1.8-1.1-3.401-2.8-3.901-6.8-2.2-23.8-7.899-27.9-10.699-8.5-5.7-23.7-22.4-23.7-22.4"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M436.504 818.701c.5 4.3-.3 20.899-1 31.599-.3 5.2 3.8 9.5 9 9.5h74.8c2.3 0 4.1-1.799 4.1-4.099 0-1.8-1.1-3.401-2.8-3.901-6.8-2.2-23.8-7.899-27.9-10.699-8.5-5.7-23.7-22.4-23.7-22.4"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-legs-walk-1-a"
                x1={511.769}
                x2={227.386}
                y1={584.935}
                y2={319.744}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.511} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.915} stopColor={SKIN_COLOR[color][2]} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
