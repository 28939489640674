import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8186)"
            d="M494.769 223.4a2.715 2.715 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_8186)"
            d="M511.858 243.487c-3.466-4.38-7.051-8.91-10.445-13.525l-.076-.103a27.633 27.633 0 00-22.095-11.101 27.499 27.499 0 00-27.375 29.881 27.5 27.5 0 005.32 14.019c3.404 4.582 6.681 9.336 9.851 13.933l2.187 3.159 42.874-34.357.712-.698-.953-1.208z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8186"
                x1="496.842"
                x2="463.01"
                y1="171.789"
                y2="291.934"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_8186"
                x1="502.092"
                x2="468.26"
                y1="173.268"
                y2="293.412"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
