import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <g clipPath="url(#female-34-25-arms-option-1-left-a)">
            <path
                d="M494.377 224.144c6.101 4.244 12.435 8.15 18.669 12.201a161.952 161.952 0 0 1 17.843 13.395c5.698 4.825 11.277 9.823 16.691 15.059a202.796 202.796 0 0 1 15.586 16.657 19.243 19.243 0 0 1-8.832 30.472 19.244 19.244 0 0 1-12.217-.043 203.038 203.038 0 0 1-21.084-8.709c-6.809-3.22-13.453-6.678-19.978-10.307a161.86 161.86 0 0 1-18.828-11.972c-5.99-4.403-11.878-8.953-18.001-13.165a26.497 26.497 0 0 1-6.815-36.852 26.5 26.5 0 0 1 36.852-6.815l.114.079Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M494.377 224.144c6.101 4.244 12.435 8.15 18.669 12.201a161.952 161.952 0 0 1 17.843 13.395c5.698 4.825 11.277 9.823 16.691 15.059a202.796 202.796 0 0 1 15.586 16.657 19.243 19.243 0 0 1-8.832 30.472 19.244 19.244 0 0 1-12.217-.043 203.038 203.038 0 0 1-21.084-8.709c-6.809-3.22-13.453-6.678-19.978-10.307a161.86 161.86 0 0 1-18.828-11.972c-5.99-4.403-11.878-8.953-18.001-13.165a26.497 26.497 0 0 1-6.815-36.852 26.5 26.5 0 0 1 36.852-6.815l.114.079Z"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M494.069 223.744c-8.492-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.958 15.305-12.953Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M463.16 202.492a132.19 132.19 0 0 1 30.909 21.252"
                stroke="#111011"
                strokeWidth={2.038}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="female-34-25-arms-option-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.385 .547)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
