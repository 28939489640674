import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6776)"
            d="M494.767 223.4a2.705 2.705 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.147 108.147 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6776)"
            d="M559.937 277.9c-.028-.957-.084-1.917-.14-2.877-.03-.523-.061-1.047-.087-1.58a248.395 248.395 0 00-.262-3.977s-9.04.437-16.305-2.274c-9.912-3.699-13.311-9.001-13.311-9.001-.765 2.11-1.541 4.191-2.323 6.7a187.637 187.637 0 00-11.02-15.574 872.164 872.164 0 00-4.562-5.793c-3.477-4.395-7.143-8.976-10.513-13.558l-.077-.105a27.628 27.628 0 00-22.097-11.103 27.499 27.499 0 00-27.375 29.881 27.509 27.509 0 005.32 14.019c3.388 4.56 6.674 9.325 9.851 13.933 1.399 2.029 2.802 4.054 4.209 6.075a197.077 197.077 0 0015.319 19.264c5.797 6.538 11.33 12.43 16.917 18.014a248.571 248.571 0 0018.513 16.882c.402.323.842.644 1.38 1.006a19.294 19.294 0 0011.523 5.477 19.47 19.47 0 0015.16-4.859 19.465 19.465 0 006.352-12.395c.443-4.032 1.059-8.121 1.654-12.077.262-1.74.524-3.48.773-5.223a127.929 127.929 0 001.253-17.609c-.003-.954.004-1.905.012-2.857.015-1.955.031-3.976-.05-5.972l-.114-4.417z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6776"
                x1="472.01"
                x2="560.058"
                y1="214.166"
                y2="315.66"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6776"
                x1="465.687"
                x2="553.735"
                y1="219.652"
                y2="321.146"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
