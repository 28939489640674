import React, { useContext } from 'react';
import { ReactComponent as RequestIcon } from 'icons/letter.svg';
import { TTabs, TPageContext } from 'interfaces';
import { PageContext } from 'components/App';

const selectedPage : {
    [K in TTabs] : {
        name: string,
        example: string,
    }
} = {
    Character: {
        name: 'material',
        example: 'Women\'s sneakers',
    },
    Objects: {
        name: 'object',
        example: 'Spacious armchair',
    },
    Scenes: {
        name: 'scene',
        example: 'Public library',
    }
};

const mailTemplate = {
    email: 'request@abbott.com',
    subject: (n: string) => `Request for a new ${n}`,
    body: (n: string, e: string) => `Briefly describe the required ${n}. E.g. "${e}". 
    You can also attach any files, sketches, notes, etc.`,
};

export default function Request() {
    const context = useContext(PageContext);
    const { content } : { content : TPageContext } = context;
    const { tab } : { tab : TTabs } = content;

    const name = selectedPage[tab].name;

    return (
        <a
            className={'controls-button short blue request-button'}
            href={`mailto:${
                mailTemplate.email
            }?subject=${mailTemplate.subject(name)}&body=${mailTemplate.body(name, selectedPage[tab].example)}`}
        >
            <div className="icon-wrap">
                <RequestIcon className="icon-request" />
            </div>
            <p>Request {name}</p>
        </a>
    );
}
