import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6339)"
            d="M416.687 248.264c-1.498-2.248-2.928-4.916-4.737-8.294a658.26 658.26 0 00-1.981-3.68l-1.386-2.547c-4.586-8.32-13.305-13.79-23.922-15.009a20.216 20.216 0 00-2.281-.132c-3.548 0-7.06.947-11.744 3.169-5.379 2.551-9.745 7.984-11.978 14.907-2.603 8.069-1.944 16.899 1.76 23.618 2.037 3.698 3.559 7.015 5.17 10.528a540.838 540.838 0 001.57 3.393l.469.999.947-.564c.309-.183 31.069-18.428 47.645-24.902l1.158-.452-.69-1.034z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6339"
                x1="367.691"
                x2="411.499"
                y1="241.884"
                y2="262.703"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
