import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M544.717 189.711a12.378 12.378 0 0 0-17.082-3.826 204.186 204.186 0 0 0-16.932 12.211c-1.347 1.078-2.722 2.13-4.047 3.229l-3.965 3.306c-2.675 2.174-5.196 4.495-7.791 6.745a173.192 173.192 0 0 0-14.618 14.4l-6.823 7.66-.408.445c-4.98-1.67-9.982-3.112-14.994-4.455-7.438-1.969-14.905-3.649-22.392-5.12a169.038 169.038 0 0 0-22.591-3.107c-7.58-.535-15.178-.894-22.735-1.662l-.135-.013a26.503 26.503 0 0 0-28.921 23.834 26.502 26.502 0 0 0 23.834 28.922c7.561.729 15.081 1.867 22.619 2.828a168.998 168.998 0 0 0 22.762 1.385c7.63.024 15.282-.159 22.961-.63a211.67 211.67 0 0 0 23.153-2.564 19.266 19.266 0 0 0 4.373-1.338 18.419 18.419 0 0 0 12.422-5.532l7.168-7.333 7.273-7.234a173.624 173.624 0 0 0 13.573-15.39c2.103-2.716 4.28-5.36 6.304-8.151l3.082-4.142c1.025-1.383 1.999-2.814 3.001-4.219a203.656 203.656 0 0 0 11.259-17.579 12.392 12.392 0 0 0 1.566-6.383 12.39 12.39 0 0 0-1.916-6.287Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m390.203 219.523.136.014c7.557.768 15.154 1.126 22.734 1.662a169.05 169.05 0 0 1 22.592 3.106c7.487 1.472 14.954 3.152 22.392 5.12 4.555 1.221 9.103 2.519 13.633 3.999M483.328 271.724c-9.69 3.333-22.19 3.64-29.872 4.136-7.68.472-15.33.655-22.961.63-7.61.052-15.214-.411-22.762-1.384-7.538-.961-15.058-2.1-22.619-2.829a26.535 26.535 0 0 1-6.7-1.535M545.065 202.383a203.81 203.81 0 0 1-11.259 17.579c-1.002 1.405-1.976 2.835-3.001 4.219l-3.082 4.142c-2.023 2.79-4.201 5.435-6.304 8.151a173.624 173.624 0 0 1-13.573 15.39l-7.272 7.234-7.169 7.333a18.416 18.416 0 0 1-9.491 5.189M474.75 231.981l5.529-6.208a173.496 173.496 0 0 1 14.618-14.4c2.595-2.25 5.116-4.57 7.791-6.744l3.965-3.306c1.325-1.099 2.7-2.151 4.047-3.229a203.722 203.722 0 0 1 16.932-12.211"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m582.986 179.577-1.122-1.046c.555-2.153-.911-4.3-2.533-5.82a21.272 21.272 0 0 0-16.683-5.538c-4.604.518-8.896 2.539-12.966 4.752a127.885 127.885 0 0 0-17.258 11.27c-9.528 6.179-4.013 17.921 10.567 19.716 9.869 1.215 20.473-9.526 24.397-15.494l2.687.202c1.87.112 3.702.569 5.405 1.347a5.167 5.167 0 0 1 2.945 4.428 13.225 13.225 0 0 1-.703 2.888 3.6 3.6 0 0 0 0 1.964 1.302 1.302 0 0 0 1.363.893 4.54 4.54 0 0 0 2.904-1.456 6.238 6.238 0 0 0 1.145-1.784l.742.168a2.613 2.613 0 0 0 2.453-.835 5.296 5.296 0 0 0 1.183-2.411c1.18-4.746-.972-9.884-4.526-13.244Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M542.711 202.484c10.905 2.068 23.325-11.822 25.748-16.922M532.422 183.195a127.885 127.885 0 0 1 17.258-11.27c4.07-2.213 8.362-4.234 12.966-4.752a21.272 21.272 0 0 1 16.682 5.538c1.623 1.521 3.089 3.667 2.534 5.82"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M569.914 173.32c4.77 1.081 9.522 2.898 13.076 6.258 3.554 3.359 5.705 8.497 4.526 13.244a5.31 5.31 0 0 1-1.183 2.411 2.62 2.62 0 0 1-2.453.835"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M572.5 179.367a12.302 12.302 0 0 1 8.854 4.73 12.315 12.315 0 0 1 2.389 9.75 7.547 7.547 0 0 1-1.751 3.839 4.542 4.542 0 0 1-2.904 1.456 1.304 1.304 0 0 1-1.364-.893 3.616 3.616 0 0 1 0-1.963 13.17 13.17 0 0 0 .704-2.888 5.163 5.163 0 0 0-2.945-4.428 15.176 15.176 0 0 0-5.405-1.347"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m551.794 313.964-9.589.167-9.588.31a151.789 151.789 0 0 1-19.177-.72c-3.196-.339-6.392-.575-9.588-1.023l-4.794-.609c-1.598-.206-3.197-.482-4.795-.72a179.666 179.666 0 0 1-19.177-3.905 12.428 12.428 0 0 1-9.184-12 12.431 12.431 0 0 1 9.184-12 179.666 179.666 0 0 1 19.177-3.905c1.598-.238 3.197-.513 4.795-.72l4.794-.608c3.196-.448 6.392-.684 9.588-1.023a151.602 151.602 0 0 1 19.177-.72l9.588.31 9.589.166a18.505 18.505 0 0 1 12.875 31.47 18.504 18.504 0 0 1-12.875 5.53Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M475.086 283.464a179.615 179.615 0 0 1 19.177-3.905c1.598-.238 3.196-.513 4.794-.72l4.794-.608c3.196-.448 6.392-.684 9.589-1.023 6.37-.645 12.775-.885 19.177-.72l9.588.31M564.713 282.539a18.503 18.503 0 0 1-12.92 31.424l-9.588.166-9.588.31a151.588 151.588 0 0 1-19.177-.72c-3.197-.339-6.393-.575-9.589-1.023l-4.794-.608c-1.598-.207-3.196-.482-4.794-.72a179.863 179.863 0 0 1-19.177-3.905"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M391.645 259.531a3.208 3.208 0 0 1 4.683-1.106l60.744 37.87a3.951 3.951 0 0 1 1.57 5.004l-15.969 31.34a3.204 3.204 0 0 1-2.093 1.607 3.212 3.212 0 0 1-2.59-.501l-60.744-37.87a3.958 3.958 0 0 1-1.57-5.004l15.969-31.34Z"
            fill="url(#female-34-25-option-1-right-a)"
        />
        <path
            d="m461.095 293.433-60.744-37.87a3.351 3.351 0 0 0-3.733-.005l-4.024 2.861a3.344 3.344 0 0 1 3.732.005l60.744 37.87a3.953 3.953 0 0 1 1.57 5.004l-15.968 31.34c-.222.44-.548.82-.949 1.107l4.025-2.861c.401-.287.726-.666.948-1.107l15.969-31.34a3.95 3.95 0 0 0-1.57-5.004Z"
            fill="url(#female-34-25-option-1-right-b)"
        />
        <path
            d="m437.99 333.745-60.744-37.87a3.958 3.958 0 0 1-1.57-5.004l15.969-31.34a3.208 3.208 0 0 1 4.683-1.106l60.744 37.87a3.951 3.951 0 0 1 1.57 5.004"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m441.723 333.745 4.025-2.861c.401-.286.726-.666.948-1.107l15.969-31.34a3.95 3.95 0 0 0-1.57-5.004l-60.744-37.87a3.351 3.351 0 0 0-3.733-.005l-4.024 2.861a3.344 3.344 0 0 1 3.732.005l60.744 37.87a3.953 3.953 0 0 1 1.57 5.004"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M381.963 293.676a2.115 2.115 0 1 0 0-4.23 2.115 2.115 0 0 0 0 4.23Z"
            fill="#231F20"
        />
        <path
            d="M385.611 286.957a2.116 2.116 0 1 0 0-4.232 2.116 2.116 0 0 0 0 4.232Z"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            opacity={0.52}
            d="M408.641 294.735c2.196-1.268 2.775-4.377 1.293-6.944-1.483-2.567-4.464-3.62-6.661-2.352-2.196 1.267-2.775 4.377-1.292 6.944 1.482 2.567 4.464 3.62 6.66 2.352Z"
            fill="#888B8D"
        />
        <path
            d="M388.186 297.582a2.115 2.115 0 1 0 0-4.23 2.115 2.115 0 0 0 0 4.23Z"
            fill="#231F20"
        />
        <path
            d="M428.601 322.759c.23-.53.487-1.07.765-1.617l-.674-.768a4.403 4.403 0 0 1-1.089-3.068 31.941 31.941 0 0 1 1.783-9.537l-1.254-.78c-2.951-1.43-5.426-4.216-5.68-7.485-.277-3.568 2.039-6.808 4.579-9.327 4.956-4.916 10.809-11.302 17.756-10.619a220.5 220.5 0 0 1 26.741 4.303c16.582 3.521 20.142 16.497 9.606 25.689a16.168 16.168 0 0 1-17.797 2.32c-4.796 4.456-11.333 11.149-14.252 17.569a3.175 3.175 0 0 1-3.909 1.737l-.087-.03a3.175 3.175 0 0 1-1.99-4.033c2.832-8.181 9.405-16.762 14.734-23.039-7.698 6.586-17.141 14.963-25.07 21.786a2.639 2.639 0 0 1-3.469-.024l-.03-.028a2.64 2.64 0 0 1-.663-3.049Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M471.528 283.861a220.304 220.304 0 0 0-26.741-4.303c-6.947-.683-12.8 5.703-17.756 10.618-2.54 2.52-4.856 5.76-4.579 9.328.254 3.269 2.729 6.055 5.68 7.485"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M445.338 291.078c-5.015 3.372-10.096 6.942-13.473 11.955a24.686 24.686 0 0 0-4.161 14.643M449.364 298.953a107.62 107.62 0 0 0-11.676 10.394 54.512 54.512 0 0 0-9.086 13.415 2.648 2.648 0 0 0 .661 3.051l.031.027a2.64 2.64 0 0 0 3.469.024c7.929-6.823 17.372-15.199 25.07-21.785-5.33 6.278-11.906 14.862-14.736 23.045a3.166 3.166 0 0 0 .161 2.426 3.17 3.17 0 0 0 1.831 1.601l.058.02a3.208 3.208 0 0 0 3.948-1.752c2.926-6.412 9.452-13.094 14.242-17.544a16.172 16.172 0 0 0 17.797-2.32M437.133 294.391l-4.393 2.731a3.063 3.063 0 0 0-1.494 1.525 2.037 2.037 0 0 0 .25 1.566c.29.46.641.879 1.043 1.245"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M436.978 269.034c6.061 0 10.975-3.921 10.975-8.759 0-4.838-4.914-8.759-10.975-8.759s-10.974 3.921-10.974 8.759c0 4.838 4.913 8.759 10.974 8.759Z"
            fill="#fff"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M436.979 262.134c1.286 0 2.33-.833 2.33-1.86 0-1.027-1.044-1.86-2.33-1.86-1.287 0-2.331.833-2.331 1.86 0 1.027 1.044 1.86 2.331 1.86Z"
            fill="#231F20"
        />
        <defs>
            <linearGradient
                id="female-34-25-option-1-right-a"
                x1={375.31}
                y1={296.084}
                x2={459.01}
                y2={296.084}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#D9D9D6" />
                <stop offset={1} stopColor="#F3F3F3" />
            </linearGradient>
            <linearGradient
                id="female-34-25-option-1-right-b"
                x1={392.594}
                y1={294.369}
                x2={463.032}
                y2={294.369}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
