import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6461)"
            d="M393.914 363.283l-12.103.018-12.207-.19a21.331 21.331 0 01-19.438-12.321c-1.246-2.768-1.522-6.379-1.708-9.393a254.691 254.691 0 01.192-25.685c.355-8.087.973-16.344 1.89-25.247.82-8.563 6.186-43.866 7.722-49.482 4.843-17.711 15.274-22.327 28.457-22.327 1.727.001 3.449.164 5.146.485a27.502 27.502 0 0121.904 32.135l-.024.125c-1.109 5.713-2.039 11.567-2.938 17.228-.397 2.5-.799 5-1.207 7.498a206.589 206.589 0 01-5.601 24.595c-2.258 7.971-4.56 15.36-7.022 22.537a245.718 245.718 0 0120.859.492c1.324.094 2.644.177 3.964.259 2.696.17 5.484.345 8.236.615l6.076.53c1.318.118 2.634.264 3.951.411.718.08 1.436.158 2.154.235 5.299.589 9.654 1.145 13.712 1.752l1.565.234-.882 1.313c-4.614 6.86-5.754 18.483-2.711 27.637l.37 1.113-1.159.19a409.135 409.135 0 01-10.466 1.554c-.728.094-1.449.193-2.169.291-1.307.179-2.614.358-3.921.507l-6.073.686c-2.73.336-5.508.58-8.196.816-1.321.116-2.643.232-3.965.359a246.298 246.298 0 01-22.409 1.039s-1.333-.003-1.999-.009z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6461"
                x1="343.502"
                x2="466.466"
                y1="275.574"
                y2="328.923"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
