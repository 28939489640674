import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-p1">
        <path
            fill="url(#man-34-25-jacket-p1-a)"
            d="M347.849 452.5c-.7 0-1.3-.5-1.3-1.2-2.8-23.2 1.1-43.7 4.9-63.4 4.4-23 8.6-44.6 2.1-68.8-6.5-23.9-10.1-50-10.8-77.5l-.5-19.1c-.4-15.6 4.4-28.6 17.9-40.2 19.2-16.5 43.6-25.9 47-26.8 18.5 14.6 35.4 20.7 35.4 20.7l21.9 16.3c1.1-8.2 2-23.5 1.8-25.5.3 0 5.9 3.7 8.1 5.4 27.2 19.9 46.6 61.3 52.5 82.8 2.1 7.8 2.4 14.5.6 19.8-3.3 10.3-2.9 21.1 1 30.6 5.6 13.6 8.3 26 8.7 40.3.3 11 0 95.8 0 105.4 0 .7-.6 1.3-1.3 1.3h-188v-.1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M407.25 156.1c12.3 10.3 25.5 16.8 35.5 20.2l24.6 19 8-19.5 7.3 6.6"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m442.75 176.3-9.5 13.1-20.9-22.7m89.4 130.7 5.8 26.7c1.6 7.5 2.5 15.2 2.4 22.9l-.5 105.5m-39.302-252.8c3.2 3.2 17.101 31.6 24.601 47.4 3.2 6.7 4.999 14 5.199 21.4l.6 19.1"
        />
        <path
            fill="url(#man-34-25-jacket-p1-b)"
            d="M466.249 167s27.2 32.9 45.3 66.9c12.9 24.2 2 54.4 9.1 63.3 25.5 32.3 24.7 117.9 24.7 117.9v-43.8c0-42.3-10.6-60.9-15.4-73.6-2.7-7.2-3.5-14.9-2.4-22.4l2.1-5.6c1.8-12.2-1.7-24.6-7.3-35.5-19.9-38.5-56.3-69.9-56.3-69.9l.2 2.7Z"
        />
        <path
            fill="#63666A"
            d="M466.148 164.1c27.9 17.2 30.5 19.4 29.4 25.4 6 2.5 8.5 4.1 8.5 4.1l11.1 47.6c-8.3-16.4-48.7-74.2-48.7-74.2s-.2-1.5-.3-2.9Z"
        />
        <path
            fill="url(#man-34-25-jacket-p1-c)"
            d="m411.049 152.6 33.701 81.7c4.8 11.6 7.299 24.1 7.299 36.7v207.5c0 11-9 20-20 20H341.25l-.801-270.8c0-19.9 8.901-39 25.301-50.2 19.4-13.3 45.299-24.9 45.299-24.9Z"
        />
        <path
            fill="#63666A"
            d="M415.048 189.7c-7.3 9.3-6.799 10.8-6.799 10.8l41 47.6c-1.2-4.7-2.7-9.4-4.5-13.9l-33.701-81.7c-9.7 31.8-2.3 34.6 4 37.2Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M379.549 403.8h59.7"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-p1-a"
                x1={342.356}
                x2={537.426}
                y1={304.053}
                y2={304.053}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-p1-b"
                x1={466.065}
                x2={545.38}
                y1={289.606}
                y2={289.606}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-p1-c"
                x1={340.405}
                x2={452.066}
                y1={325.572}
                y2={325.572}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
