import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M537.239 299.938c-1.943-.741-3.877-1.518-5.828-2.229l-5.855-2.121c-3.893-1.455-7.839-2.705-11.759-4.054a255.967 255.967 0 0 0-23.844-6.824l-12.088-2.763-10.713-2.579c-3.956-4.515-8.11-8.743-12.346-12.849-5.334-5.137-10.831-10.034-16.447-14.758a156.222 156.222 0 0 0-17.59-13.089c-6.147-3.948-12.395-7.75-18.41-11.891l-.117-.08a26.504 26.504 0 0 0-19.858-4.125 26.501 26.501 0 0 0-21.086 30.982 26.503 26.503 0 0 0 11.125 16.96c6.037 4.108 11.843 8.556 17.748 12.858a156.248 156.248 0 0 0 18.568 11.66c6.437 3.525 12.991 6.877 19.71 9.99a196.867 196.867 0 0 0 20.808 8.387c1.838.584 3.751.895 5.679.922.564.212 1.14.402 1.734.562l11.97 3.225 11.935 3.363a250.446 250.446 0 0 0 24.2 5.428c4.088.693 8.15 1.485 12.265 2.072l6.155.942c2.053.31 4.123.554 6.184.834 4.761.617 9.541 1.164 14.349 1.6a44.443 44.443 0 0 1 1.621-13.89 45.35 45.35 0 0 1 6.152-12.672 370.655 370.655 0 0 0-14.262-5.861Z"
            fill="url(#female-34-25-shirt-1-arm-opt-9-right-a)"
        />
        <path
            d="M529.379 330.761c4.762.617 9.541 1.164 14.349 1.6 0 0-1.035-4.402 1.838-14.216 2.459-8.398 5.935-12.345 5.935-12.345-4.724-2.069-9.483-4.005-14.261-5.862M449.26 313.411a196.44 196.44 0 0 1-20.808-8.387c-6.719-3.113-13.274-6.466-19.711-9.99a156.189 156.189 0 0 1-18.567-11.66c-5.905-4.302-11.71-8.75-17.748-12.858M402.246 226.695l.117.08c6.015 4.141 12.263 7.943 18.41 11.891a156.245 156.245 0 0 1 17.59 13.088c5.615 4.725 11.114 9.622 16.447 14.759 3.08 2.986 6.121 6.028 9.073 9.201M529.383 330.764c-2.06-.28-4.13-.524-6.183-.834l-6.156-.943c-4.115-.586-8.177-1.378-12.264-2.071a255.989 255.989 0 0 1-24.201-5.429 279.77 279.77 0 0 1-29.044-7.495"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m465.816 279.047 12.053 2.902 12.088 2.763a250.515 250.515 0 0 1 23.844 6.823c3.92 1.35 7.866 2.599 11.759 4.055l5.855 2.121c1.951.711 3.885 1.488 5.828 2.229"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-9-right-a"
                x1={360.829}
                y1={277.234}
                x2={567.16}
                y2={277.234}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
