import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 250,
        height: 200,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M182.154 150.053H67.848M1.273 131.345V1.881h247.453v129.464M67.848 150.053h114.306"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M237.609 131.342H12.391v18.704l92.071.137M134.421 150.186v17.331a12 12 0 0 0 10.349 11.886l75.156 10.436v8.526H30.074v-8.526l13.894-1.929 30.207-4.195 31.056-4.312a12 12 0 0 0 10.349-11.886v-17.331"
                />
            </>
        ),
    },
    'Color #1': {
        width: 250,
        height: 200,
        content: (
            <>
                <path
                    fill="url(#object-devices-monitor-a)"
                    d="M134.326 167.517v-17.331h-18.841v17.331a12 12 0 0 1-10.349 11.886l-75.155 10.436v8.526h189.851v-8.526l-75.156-10.436a12 12 0 0 1-10.349-11.886h-.001Z"
                />
                <path fill="url(#object-devices-monitor-b)" d="M248.633 1.88H1.18V150.05h247.453V1.881Z" />
                <path fill="#ECF3F9" d="M248.633 131.342H1.18v18.705h247.453v-18.705Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M80.188 1.88h168.444V150.05H134.326M115.486 150.049H1.18V1.881h25.198M219.833 189.839l-75.156-10.436a12 12 0 0 1-10.349-11.886v-17.331M29.98 198.365h189.853M115.485 150.186v17.331a12 12 0 0 1-10.349 11.886l-75.155 10.436M248.632 131.342H10.434"
                />
                <defs>
                    <linearGradient
                        id="object-devices-monitor-a"
                        x1={124.905}
                        x2={124.905}
                        y1={155.729}
                        y2={192.914}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-monitor-b"
                        x1={252.249}
                        x2={96.881}
                        y1={180.324}
                        y2={52.998}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-monitor"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
