import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-1">
        <path
            d="M402.388 267.287c.163-.709.324-1.416.482-2.122a202.242 202.242 0 0 0 4.208-25.364c.262-2.564.507-5.13.752-7.697.555-5.817 1.129-11.832 1.904-17.717l.02-.15a32.034 32.034 0 0 0-31.785-36.026c-16.055 0-27.732 8.746-33.587 27.397-3.588 11.429-4.12 42.658-4.386 51.426-.174 4.562-.28 8.953-.318 13.236 31.813-9.217 62.71-2.983 62.71-2.983Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-1-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-1-a"
                x1={378.98}
                y1={155.023}
                x2={373.124}
                y2={266.275}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
