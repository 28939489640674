import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7762)"
            d="M527.498 376.672l-173.201 15.792v65.883h157.281a20 20 0 0020-20v-36.516a17.859 17.859 0 00-.263-3.044l-3.817-22.115z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7762"
                x1="354.297"
                x2="531.578"
                y1="417.51"
                y2="417.51"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#004F71"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
