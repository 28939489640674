import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#3A383A"
                d="M611.224 693.334a198.75 198.75 0 01-24.267-2.025c-4.507-.657-9.243-1.517-13.262-4.124-4.836-3.135-8.377-8.808-9.969-15.975a79.801 79.801 0 01-1.411-14.44 274.412 274.412 0 00-.174-4.173c-.536-10.354-3.492-19.716-6.62-29.628l-.682-2.165-4.217-5.988 30.324-19.843 4.745 11.515c4.095 8.312 7.928 16.813 11.634 25.033 3.698 8.2 7.521 16.679 11.626 25.016l.493.999c4.17 8.457 8.481 17.203 9.948 26.44a8.07 8.07 0 01-.2 3.463 8.08 8.08 0 01-7.787 5.897l-.181-.002z"
            ></path>
            <path
                fill="url(#paint0_linear_10894_7480)"
                d="M574.421 689.883c-4.73-4.001-9.199-12.035-10.792-19.202a79.882 79.882 0 01-1.411-14.44 275.496 275.496 0 00-.174-4.173 75.907 75.907 0 00-1.743-12.427c8.41-5.42 8.414-9.316 16.795-11.926 8.819-2.747 12.723 1.88 20.133 3.278 3.697 8.2 7.52 16.679 11.626 25.016l.492.999c4.17 8.457 8.481 17.203 9.948 26.44a61.51 61.51 0 01.74 11.062c-.433 11.349-13.734 22.337-45.614-4.627z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10894_7480"
                    x1="560.301"
                    x2="620.065"
                    y1="666.76"
                    y2="666.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#004F71"></stop>
                    <stop offset="0.125" stopColor="#01567A"></stop>
                    <stop offset="1" stopColor="#0B86B8"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
