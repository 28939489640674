import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-6">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-6-a)"
            d="M544.861 337.646c-1.456-.405-56.097-17.927-73.808-25.88-8.494-3.813-22.005-10.301-33.845-15.65a192.22 192.22 0 0 1-22.121-12.361c-6.786-4.232-13.565-8.78-20.728-13.905A189.248 189.248 0 0 1 375 254.281a892.685 892.685 0 0 1-5.461-5.056c-4.141-3.85-8.422-7.831-12.766-11.581a31.78 31.78 0 0 1-11.007-21.879 31.796 31.796 0 0 1 7.688-23.254 32.001 32.001 0 0 1 24.241-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.324 3.769 8.872 7.447 13.271 11.004a1844.81 1844.81 0 0 1 5.772 4.689 189.1 189.1 0 0 1 18.152 16.969c6.084 6.366 11.544 12.435 16.687 18.548 4.9 5.862 8.793 10.845 12.235 15.662l10.764 4.733 12.682 5.505c7.789 3.407 16.006 7.245 25.125 11.734 1.365.684 2.728 1.363 4.088 2.035 2.733 1.356 5.56 2.758 8.322 4.19 3.935 2.004 8.135 4.152 12.328 6.415 1.454.778 2.912 1.554 4.373 2.327 2.634 1.397 5.267 2.793 7.886 4.226 1.34.756 2.672 1.497 4.005 2.238-4.13 6.264-8.365 18.669-9.541 38.07Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-6-a"
                x1={367.364}
                x2={460.391}
                y1={180.861}
                y2={273.45}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
