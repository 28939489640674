import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M548.982 738.339c-.448-5.52-.891-11.04-1.329-16.562l-1.881-23.435-2.02-23.41-4.062-46.815a27.035 27.035 0 0 0-.576-3.6 347.33 347.33 0 0 0 4.084-45.91c.026-1.389.056-2.779.091-4.171.058-2.554.118-5.109.139-7.662l.024-11.774c-.003-6.462-.094-14.968-.77-23.453-.583-7.403-1.498-15.154-2.719-23.04-1.39-8.934-2.673-16.158-4.037-22.736-.724-3.485-1.545-7.434-2.501-11.276-.239-.977-.472-1.955-.705-2.933-.649-2.72-1.318-5.533-2.108-8.269l-1.53-5.593-1.694-5.531c-1.289-4.126-2.549-7.733-3.848-11.027l-1.81-4.62c-7.76-19.675-.706-25.3-.706-27.686h-54.721s-25.974 4.289-34.377 15.353a51.678 51.678 0 0 0-10.112 38.503 199.97 199.97 0 0 0 1.896 11.43l1.126 5.672 1.259 5.607c.594 2.794 1.304 5.602 1.99 8.317.246.97.491 1.94.73 2.903.672 2.809 1.463 5.631 2.229 8.361.262.935 2.34 8.392 2.34 8.392l1.698 5.531c.407 1.298.803 2.599 1.199 3.9.715 2.35 1.454 4.779 2.248 7.152 2.249 6.933 4.92 14.095 8.165 21.895 1.639 3.939 3.412 7.915 5.127 11.761 1.43 3.207 2.908 6.524 4.3 9.798l4.626 10.782 4.52 10.813 17.683 42.451a107.892 107.892 0 0 0-1.816 10.715 108.86 108.86 0 0 0-.467 4.297c-.056.584-.112 1.168-.175 1.791-.045.702-.099 1.405-.151 2.11-.097 1.277-.198 2.598-.246 3.886a225.606 225.606 0 0 0 .096 23.807c1.155 17.548 2.94 32.175 7.897 46.506 1.652 5.05 8.455 29.355 9.611 33.967l52.582-10.002c-.429-5.445-.868-10.869-1.299-16.195Z"
            fill="#888B8D"
        />
        <path
            d="M349.427 408.743c-2.648 13.071-2.238 24.704-.148 38.512a194.445 194.445 0 0 0-1.516 11.31l-.574 5.667-.429 5.642c-.247 2.803-.385 5.655-.52 8.411-.048.983-.096 1.966-.147 2.939-.176 2.832-.241 5.712-.304 8.497-.022.963-.206 8.599-.206 8.599l.013 5.699c.011 1.339.01 2.677.01 4.015 0 2.42-.001 4.922.067 7.387.132 7.183.601 14.699 1.433 22.978.42 4.18.957 8.433 1.477 12.546.434 3.437.882 6.99 1.26 10.478l1.285 11.49 1.173 11.488 3.96 39.245a96.161 96.161 0 0 0-6.414 8.044c-.811 1.094-1.573 2.218-2.31 3.306-.315.463-.629.926-.97 1.423-.354.559-.714 1.115-1.074 1.672a90.61 90.61 0 0 0-1.944 3.088 198.223 198.223 0 0 0-10.499 19.669c-6.775 14.993-11.692 27.868-13.66 41.953-.777 4.919-1.257 10.013-1.719 14.939-.236 2.501-3.478 28.99-3.478 28.99s14.097 6.109 25.374 9.868a137.017 137.017 0 0 0 23.128 5.498c2.57-8.426 22.239-59.498 22.239-59.498l8.608-20.208 17.194-40.423a27.09 27.09 0 0 0 1.303-3.894l1.289-2.493c8.177-15.814 14.068-31.843 18.29-44.055.429-1.299.863-2.598 1.3-3.896a497.574 497.574 0 0 0 2.367-7.178l3.453-11.079c1.879-6.084 4.271-14.117 6.096-22.302 1.6-7.14 2.982-14.702 4.111-22.479 1.273-8.806 2.151-15.979 2.762-22.574l.01-.108a43.395 43.395 0 0 1 18.06-31.406 157.826 157.826 0 0 0 12.84-10.236c20.889-18.461 32.63-35.647 38.559-51.525l-171.749.001Z"
            fill="url(#female-34-25-pants-2-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-2-walk-1-a"
                x1={468.924}
                y1={583.052}
                x2={190.184}
                y2={588.927}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#A7A9A9" />
                <stop offset={0.9} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
