import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g>
        <path
            d="M522.557 820.095a687.587 687.587 0 0 1-8.604-22.449c-1.363-3.751-2.812-7.491-4.104-11.253l-3.94-11.277c-2.655-7.514-5.174-15.047-8.069-22.526-2.824-7.49-6.006-14.927-8.475-22.468-5.201-15.043-6.819-30.61-7.849-46.262a226.07 226.07 0 0 1-.095-23.689c.074-1.986.268-3.99.396-5.983.206-2.006.363-4.004.635-6.019a104.667 104.667 0 0 1 2.146-12.164l2.874-11.848a26.192 26.192 0 0 1 27.437-19.941 26.195 26.195 0 0 1 24.113 23.853l4.061 46.814 2.02 23.409 1.88 23.429c1.23 15.623 2.558 31.231 3.709 46.865l3.529 46.892a15.927 15.927 0 0 1-12.25 16.698 15.93 15.93 0 0 1-18.389-9.525l-1.025-2.556Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m484.544 637.753-18.025-43.27-4.52-10.815-4.63-10.791c-3.06-7.2-6.421-14.336-9.422-21.548-3.001-7.213-5.756-14.477-8.138-21.82-1.225-3.664-2.29-7.362-3.443-11.042l-1.697-5.526-1.55-5.557c-1.021-3.707-2.11-7.4-3.002-11.134-.924-3.728-1.917-7.442-2.715-11.196l-1.261-5.618-1.121-5.647a194.653 194.653 0 0 1-1.886-11.37 51.018 51.018 0 0 1 37.622-56.262 51.022 51.022 0 0 1 60.373 30.592l1.809 4.618c1.429 3.622 2.675 7.281 3.825 10.961l1.693 5.527 1.522 5.563c1.067 3.698 1.894 7.446 2.806 11.176.927 3.728 1.712 7.485 2.494 11.242 1.558 7.517 2.846 15.09 4.027 22.687 1.177 7.597 2.103 15.247 2.71 22.964.614 7.716.762 15.53.766 23.374l-.024 11.773c-.033 3.93-.155 7.879-.229 11.817-.512 15.798-1.558 31.71-4.439 48.008l-.97 5.494a27.554 27.554 0 0 1-11.34 17.784 27.564 27.564 0 0 1-20.594 4.556 27.842 27.842 0 0 1-20.641-16.54Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m523.583 822.646-1.026-2.556a688.415 688.415 0 0 1-8.604-22.448c-1.363-3.752-2.812-7.491-4.104-11.253l-3.94-11.277c-2.655-7.514-5.174-15.048-8.069-22.527-2.824-7.489-6.006-14.926-8.475-22.468-5.201-15.043-6.819-30.61-7.849-46.261a226.09 226.09 0 0 1-.095-23.69c.074-1.986.268-3.99.396-5.983.206-2.005.363-4.004.635-6.019A104.667 104.667 0 0 1 484.598 636M539.023 628.062l4.062 46.815 2.019 23.408 1.88 23.429c1.23 15.623 2.558 31.231 3.709 46.866l3.253 43.221M481.795 631.155l-15.276-36.672-4.52-10.815-4.63-10.791c-3.06-7.2-6.421-14.336-9.422-21.548-3.001-7.213-5.756-14.477-8.138-21.82-1.225-3.664-2.29-7.362-3.443-11.042l-1.697-5.526-1.55-5.557c-1.021-3.707-2.11-7.4-3.002-11.134-.924-3.728-1.917-7.442-2.715-11.196l-1.261-5.618-1.121-5.647a194.653 194.653 0 0 1-1.886-11.37 51.018 51.018 0 0 1 37.622-56.262 51.022 51.022 0 0 1 60.373 30.592l1.809 4.618c1.429 3.622 2.675 7.281 3.825 10.961l1.693 5.527 1.522 5.563c1.067 3.698 1.894 7.446 2.806 11.176.927 3.728 1.712 7.485 2.494 11.242 1.558 7.517 2.846 15.09 4.027 22.687 1.177 7.597 2.103 15.247 2.71 22.964.614 7.716.762 15.53.766 23.374l-.024 11.773c-.033 3.93-.155 7.879-.229 11.817a358.178 358.178 0 0 1-3.387 41.546"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m552.961 811.758 19.692 33.319a16.994 16.994 0 0 0 9.212 7.46l14.499 4.874a5.422 5.422 0 0 1 3.692 5.022 5.425 5.425 0 0 1-4.219 5.414 8076.98 8076.98 0 0 0-11.033 2.525 37.917 37.917 0 0 1-10.82.886l-5.482-.34a23.353 23.353 0 0 1-15.06-6.79l-7.387-7.382a23.366 23.366 0 0 0-11.635-6.322l-5.992-1.279a12.924 12.924 0 0 1-10.154-13.993l2.307-21.915"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m552.961 811.758 19.692 33.319a16.994 16.994 0 0 0 9.212 7.46l14.499 4.874a5.422 5.422 0 0 1 3.692 5.022 5.425 5.425 0 0 1-4.219 5.414 8076.98 8076.98 0 0 0-11.033 2.525 37.917 37.917 0 0 1-10.82.886l-5.482-.34a23.353 23.353 0 0 1-15.06-6.79l-7.387-7.382a23.366 23.366 0 0 0-11.635-6.322l-5.992-1.279a12.924 12.924 0 0 1-10.154-13.993l2.307-21.915"
            stroke="#000"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.469a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.908H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.469a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.908"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M310.205 792.105a608.489 608.489 0 0 1 2.334-22.425c.456-3.714.831-7.456 1.355-11.146l1.512-11.09c.981-7.404 2.092-14.76 2.843-22.245.82-7.46 1.299-15.041 2.457-22.382 2.065-14.77 7.545-28.332 13.584-41.697a198.458 198.458 0 0 1 10.441-19.559c.949-1.602 2.01-3.164 3.01-4.748 1.074-1.558 2.101-3.132 3.238-4.668a93.538 93.538 0 0 1 7.31-9.04l8.813-9.523a26.016 26.016 0 0 1 33.235-4.172 26.02 26.02 0 0 1 9.807 32.029L392.95 681.86l-8.607 20.207-8.74 20.16c-5.848 13.433-11.604 26.898-17.528 40.303l-17.7 40.242a15.914 15.914 0 0 1-30.425-7.734l.255-2.933Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m358.259 630.111-4.636-45.952-1.173-11.489-1.286-11.5c-.829-7.663-1.966-15.355-2.735-23.013-.77-7.659-1.289-15.294-1.429-22.897-.104-3.804-.046-7.593-.077-11.391l-.013-5.693.137-5.68c.102-3.786.137-7.578.371-11.352.202-3.776.334-7.56.665-11.325l.43-5.653.572-5.642c.389-3.76.892-7.51 1.508-11.25a51.02 51.02 0 0 1 52.392-42.733 51.02 51.02 0 0 1 48.806 46.787l.398 5.02c.311 3.823.437 7.629.466 11.427l.01 5.693-.166 5.677c-.056 3.79-.357 7.558-.571 11.333-.198 3.778-.542 7.542-.889 11.306-.699 7.527-1.674 15.03-2.757 22.522-1.087 7.493-2.43 14.963-4.096 22.403-1.66 7.442-3.795 14.84-6.076 22.227l-3.452 11.076c-1.176 3.69-2.444 7.371-3.662 11.057-5.091 14.725-10.726 29.4-18.229 43.91l-2.599 5.026a27.572 27.572 0 0 1-6.708 8.406 27.575 27.575 0 0 1-20.103 6.398 27.57 27.57 0 0 1-10.334-2.981 27.852 27.852 0 0 1-14.764-21.717Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m410.144 641.442-17.194 40.422-8.607 20.207-8.74 20.16c-5.848 13.432-11.604 26.897-17.528 40.303l-17.7 40.242a15.915 15.915 0 0 1-30.425-7.735l.255-2.932a608.489 608.489 0 0 1 2.334-22.425c.456-3.713.831-7.456 1.355-11.146l1.512-11.09c.981-7.404 2.092-14.76 2.843-22.245.82-7.46 1.298-15.041 2.457-22.381 2.065-14.77 7.545-28.333 13.584-41.698a198.458 198.458 0 0 1 10.441-19.559c.949-1.602 2.01-3.164 3.01-4.748 1.074-1.558 2.101-3.132 3.238-4.668a93.388 93.388 0 0 1 7.31-9.04M356.905 616.698l-3.282-32.532-1.173-11.49-1.286-11.499c-.829-7.664-1.966-15.355-2.735-23.013-.77-7.659-1.289-15.295-1.429-22.897-.104-3.804-.046-7.593-.077-11.391l-.013-5.693.137-5.68c.102-3.786.137-7.578.371-11.352.202-3.776.334-7.56.665-11.325l.43-5.654.572-5.641c.389-3.76.892-7.51 1.508-11.25M452.655 467.781l.01 5.693-.165 5.678c-.057 3.79-.357 7.558-.572 11.333-.198 3.777-.542 7.541-.889 11.305-.698 7.527-1.673 15.03-2.756 22.523-1.087 7.493-2.43 14.962-4.097 22.403-1.66 7.441-3.794 14.84-6.076 22.226l-3.452 11.076c-1.176 3.69-2.444 7.372-3.661 11.058-5.092 14.724-10.727 29.4-18.229 43.91"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M310.56 799.68s-8.591 15.543-10.319 22.156c-.787 3.011-2.114 7.629.578 12.579 4.482 8.241 16.44 26.912 16.44 26.912a20.345 20.345 0 0 0 17.209 9.493h23.781a5.275 5.275 0 0 0 5.088-3.87 5.282 5.282 0 0 0-3.338-6.39l-3.384-1.189a13.826 13.826 0 0 1-8.555-9.198c-3.48-11.055-6.65-32.169-9.144-45.831"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M310.56 799.68s-8.591 15.543-10.319 22.156c-.787 3.011-2.114 7.629.578 12.579 4.482 8.241 16.44 26.912 16.44 26.912a20.345 20.345 0 0 0 17.209 9.493h23.781a5.275 5.275 0 0 0 5.088-3.87 5.282 5.282 0 0 0-3.338-6.39l-3.384-1.189a13.826 13.826 0 0 1-8.555-9.198c-3.48-11.055-6.65-32.169-9.144-45.831"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
