import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-down">
        <path
            fill={SKIN_COLOR[color]}
            d="M405.159 226.9c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-5 7-10.3 13.9-16.1 20.5-8.6 9.6-23.3 10.4-32.9 1.9-6.5-5.8-8.9-14.3-7.3-22.2 1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.7-16.2 8.8-24.2 3.1-8 6.7-15.7 10.7-23.2 4.1-7.5 8.3-14.9 12.1-22.5 7.7-15.3 26.3-21.4 41.6-13.7 15.3 7.7 21.4 26.3 13.7 41.6h.1Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m355.157 299.899 1.5 13.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9-.1 8.6-7.2 15.6-15.8 15.5-7.1-.1-13-4.9-14.9-11.4-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-1.9-12.3 6.5-23.8 18.8-25.7 12.3-1.9 23.8 6.5 25.7 18.8 0 .4.1.8.1 1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m405.256 226.7-.1.1c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-2.5 3.5-5.1 7-7.8 10.4m-48.398-10.2c1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.7-16.2 8.8-24.2 3.1-8 18.8-37.5 22.7-45.1"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M332.158 414.5c-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-.6-3.8-.5-8.6.7-12m44.699 13.2.8 6.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M333.061 419c-1 6.9-2.2 13.8-2.9 20.7-1.4 13.6.2 27.8 10.5 28.2l.5.7c1.9 2.2 4.7 3.8 8.9 3.9l1.6-.3c2.2 2.9 4.9 4.8 7.9 4.9l.6.2c.6 4 1 8.1 1 11.2 0 1 .7 1.9 1.7 2.1 1.2.3 2.5-.4 2.8-1.7 1.3-6 1.7-14.8 1.6-20.1 0-3-.3-6-.6-9l.2.7c.7 2.3 3 3.8 5.4 3.4l1.9-.3c-3.1-3.4-3.9-8.3-3.8-12.8.1-4.5 1-9.1 1-13.6-.1-8.4-2.3-17.8-8.2-23.9"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M362.559 412.8c5.9 6.1 8.7 16 8.8 24.5 0 4.6-.9 9.1-1 13.6-.1 4.5.8 9.4 3.8 12.8"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M367.261 436.6c-1.6 5.2-1.7 10.7-1.3 16.1.4 5.4 1.2 10.8 1.3 16.2.1 5.3-.3 14.2-1.6 20.1-.3 1.2-1.5 2-2.8 1.7-1-.3-1.7-1.1-1.7-2.1.1-6.1-1.7-15.8-2.8-21.1-1-4.5-1.9-9-2.9-13.5"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M345.96 454c-.3 10 5.5 22.7 13.5 23.1m-22.199-22.8c-.3 2.6-.6 17.7 12.7 18.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M340.56 467.9c-10.3-.4-11.9-14.6-10.5-28.2.7-6.9 1.9-13.8 2.9-20.7"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M405.159 226.9c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-5 7-10.3 13.9-16.1 20.5-8.6 9.6-23.3 10.4-32.9 1.9-6.5-5.8-8.9-14.3-7.3-22.2 1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.7-16.2 8.8-24.2 3.1-8 6.7-15.7 10.7-23.2 4.1-7.5 8.3-14.9 12.1-22.5 7.7-15.3 26.3-21.4 41.6-13.7 15.3 7.7 21.4 26.3 13.7 41.6h.1Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m355.157 299.899 1.5 13.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9-.1 8.6-7.2 15.6-15.8 15.5-7.1-.1-13-4.9-14.9-11.4-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-1.9-12.3 6.5-23.8 18.8-25.7 12.3-1.9 23.8 6.5 25.7 18.8 0 .4.1.8.1 1Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m405.256 226.7-.1.1c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-2.5 3.5-5.1 7-7.8 10.4m-48.398-10.2c1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.7-16.2 8.8-24.2 3.1-8 18.8-37.5 22.7-45.1"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M332.158 414.5c-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-.6-3.8-.5-8.6.7-12m44.699 13.2.8 6.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M333.061 419c-1 6.9-2.2 13.8-2.9 20.7-1.4 13.6.2 27.8 10.5 28.2l.5.7c1.9 2.2 4.7 3.8 8.9 3.9l1.6-.3c2.2 2.9 4.9 4.8 7.9 4.9l.6.2c.6 4 1 8.1 1 11.2 0 1 .7 1.9 1.7 2.1 1.2.3 2.5-.4 2.8-1.7 1.3-6 1.7-14.8 1.6-20.1 0-3-.3-6-.6-9l.2.7c.7 2.3 3 3.8 5.4 3.4l1.9-.3c-3.1-3.4-3.9-8.3-3.8-12.8.1-4.5 1-9.1 1-13.6-.1-8.4-2.3-17.8-8.2-23.9"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M362.559 412.8c5.9 6.1 8.7 16 8.8 24.5 0 4.6-.9 9.1-1 13.6-.1 4.5.8 9.4 3.8 12.8"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M367.261 436.6c-1.6 5.2-1.7 10.7-1.3 16.1.4 5.4 1.2 10.8 1.3 16.2.1 5.3-.3 14.2-1.6 20.1-.3 1.2-1.5 2-2.8 1.7-1-.3-1.7-1.1-1.7-2.1.1-6.1-1.7-15.8-2.8-21.1-1-4.5-1.9-9-2.9-13.5"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M345.96 454c-.3 10 5.5 22.7 13.5 23.1m-22.199-22.8c-.3 2.6-.6 17.7 12.7 18.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M340.56 467.9c-10.3-.4-11.9-14.6-10.5-28.2.7-6.9 1.9-13.8 2.9-20.7"
        />
    </g>
);

export default SvgComponent;
