import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-3-left">
        <path
            d="M505.474 191.173c6.088 6.79 12.5 13.287 18.772 19.91a229.319 229.319 0 0 1 17.626 20.943 507.842 507.842 0 0 1 16.028 22.381c5.115 7.666 10.069 15.476 14.494 23.764a23.18 23.18 0 0 1-33.436 30.116c-7.781-5.264-15.033-11.005-22.124-16.891a455.11 455.11 0 0 1-20.59-18.273 229.072 229.072 0 0 1-18.992-19.712c-5.934-6.928-11.728-13.982-17.846-20.744a30.991 30.991 0 0 1-7.975-22.346 30.994 30.994 0 0 1 10.162-21.44 30.991 30.991 0 0 1 22.346-7.975 30.989 30.989 0 0 1 11.731 2.949 30.99 30.99 0 0 1 9.71 7.213l.094.105Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M524.776 297.843a380.702 380.702 0 0 1-7.942-6.447 455.11 455.11 0 0 1-20.59-18.273 229.072 229.072 0 0 1-18.992-19.712c-5.934-6.928-11.728-13.982-17.846-20.744a30.991 30.991 0 0 1-7.975-22.346 30.994 30.994 0 0 1 10.162-21.44 30.991 30.991 0 0 1 22.346-7.975 30.989 30.989 0 0 1 11.731 2.949 30.99 30.99 0 0 1 9.71 7.213l.094.105c6.088 6.79 12.5 13.287 18.772 19.91a229.319 229.319 0 0 1 17.626 20.943 507.014 507.014 0 0 1 16.028 22.381c3.22 4.826 6.376 9.709 9.377 14.732"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M499.946 185.97c-9.226-7.122-19.957-12.655-32.551-17.169l19.121 31.543s17.866-10.95 13.43-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.395 168.801a129.713 129.713 0 0 1 32.551 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M552.865 313.128c-1.661-.257-3.322-.354-4.983-.518a19.287 19.287 0 0 1-4.983-1.227 43.953 43.953 0 0 1-4.983-2.175 31.834 31.834 0 0 1-4.982-3.08 19.537 19.537 0 0 1-6.971-20.568 19.55 19.55 0 0 1 3.377-6.838 20.343 20.343 0 0 1 3.594-3.594 31.985 31.985 0 0 1 4.982-3.08 44.133 44.133 0 0 1 4.983-2.175 19.285 19.285 0 0 1 4.983-1.226c1.661-.164 3.322-.262 4.983-.519a22.77 22.77 0 0 1 6.974 45 23.424 23.424 0 0 1-6.974 0Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M569.796 272.255a22.76 22.76 0 0 1 7.943 10.566 22.763 22.763 0 0 1-5.987 24.58 22.763 22.763 0 0 1-11.913 5.729 23.424 23.424 0 0 1-6.974 0c-1.661-.257-3.322-.354-4.983-.518a19.287 19.287 0 0 1-4.983-1.227 43.953 43.953 0 0 1-4.983-2.175 31.91 31.91 0 0 1-4.982-3.08 19.537 19.537 0 0 1-6.971-20.568 19.55 19.55 0 0 1 3.377-6.838 20.343 20.343 0 0 1 3.594-3.594 31.985 31.985 0 0 1 4.982-3.08 43.953 43.953 0 0 1 4.983-2.175 19.328 19.328 0 0 1 4.983-1.227"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
