import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-6">
        <path
            d="M415.673 283.755c-6.786-4.233-13.565-8.78-20.728-13.905a189.122 189.122 0 0 1-19.359-15.57 892.505 892.505 0 0 1-5.461-5.055c-4.141-3.85-8.423-7.831-12.766-11.581a31.78 31.78 0 0 1-11.007-21.879 31.785 31.785 0 0 1 7.688-23.254 31.992 31.992 0 0 1 24.241-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.324 3.77 8.872 7.447 13.271 11.004a1844.81 1844.81 0 0 1 5.772 4.689 189.1 189.1 0 0 1 18.152 16.969c6.084 6.366 11.544 12.435 16.686 18.548-9.093 4.316-32.514 25.569-37.506 43.244Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-6-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-6-a"
                x1={357.241}
                y1={190.288}
                x2={399.484}
                y2={232.332}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
