import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-right-option-7">
        <path
            d="M333.334 277.807c.669-7.981 1.754-16.261 2.342-21.158a202.171 202.171 0 0 1 4.448-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341a32.132 32.132 0 0 1 31.283-25.418c2.225.002 4.443.234 6.62.693a31.79 31.79 0 0 1 20.212 13.831 31.773 31.773 0 0 1 4.513 24.072l-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.452 2.531-.903 5.063-1.371 7.591a202.362 202.362 0 0 1-6.255 24.938 448.152 448.152 0 0 1-4.619 14.119c-16.131-6.679-42.806-10.294-59.729-6.174Z"
            fill="url(#man-34-25-tshirt-4-arm-right-option-7-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-right-option-7-a"
                x1={342.237}
                y1={199.404}
                x2={396.177}
                y2={275.228}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
