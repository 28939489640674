import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-1-right-cycling">
        <path
            d="M432.1 248.999a238.878 238.878 0 0 0-16.096-22.844c-1.74-2.19-3.489-4.373-5.248-6.55-3.989-4.958-8.114-10.085-11.999-15.252l-.084-.112a31.77 31.77 0 0 0-25.516-12.659 31.718 31.718 0 0 0-19.319 6.51 32.04 32.04 0 0 0-6.149 44.836c3.905 5.146 7.707 10.514 11.383 15.707 1.619 2.284 3.24 4.564 4.866 6.84a239.089 239.089 0 0 0 17.566 21.728 507.315 507.315 0 0 0 15.578 16.672l46.014-36.697a510.954 510.954 0 0 0-10.996-18.179Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-right-cycling-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-right-cycling-a"
                x1={333.866}
                y1={215.57}
                x2={441.543}
                y2={277.867}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.432} stopColor="#22ADE0" />
                <stop offset={0.768} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
