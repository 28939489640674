import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 134,
        height: 204,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M42.617 30.087V1.855h61.598v61.6H42.617V45.37M123.055 124.549v-6.24"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M42.615 30.092H1.695v66.69h40.92v-14.48h23.92v110.94c0 5.21 4.22 9.42 9.42 9.42 2.6 0 4.95-1.05 6.66-2.76a9.361 9.361 0 0 0 2.76-6.66V22.512h37.68v87.251M129.456 132.501l1.85 5.21c1.66 4.67 1.6 9.84-.48 14.34-1.8 3.86-4.61 6.34-7.77 6.34-3.16 0-5.97-2.48-7.76-6.34-2.09-4.5-2.15-9.67-.49-14.34l3.43-9.63c.6-1.68 1.97-3.03 3.72-3.41.37-.08.73-.12 1.1-.12M129.454 132.496h-6.122M123.055 118.311h6.4v-8.549h-6.4M73.422 10.176v6.347M57.527 16.762l4.488 4.488M50.941 32.652h6.347M57.527 48.554l4.488-4.488M73.422 55.132v-6.347"
                />
            </>
        ),
    },
    'Outline #2': {
        width: 194,
        height: 173,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M69.194 122.163h123.284V93.942H57.922V75.1h134.556V46.879H57.922"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M76.762 63.214V28.121H1.477v94.042h67.716M105.023 18.698l2-16.843h14.841l2 16.843a9.42 9.42 0 0 1-9.42 9.42M160.926 28.119h9.42V12.656l12.127-8v-2.8h-35.598l4.63 10.232v10.078M95.602 122.163v39.59a9.42 9.42 0 0 0 9.42 9.42 9.42 9.42 0 0 0 9.42-9.42V28.121M160.926 28.121v133.632a9.42 9.42 0 0 1-18.84 0v-39.59"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M33.846 95.668H17.55v10.421h16.295V95.668Z"
                />
            </>
        ),
    },
    'Outline #3': {
        width: 112,
        height: 109,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M110.401 58.538V1.855H1.477V58.54H36.17V20.695H91.56v18.841H21.517V58.38M62.894 89.887h-12.71M91.566 58.543v31.35h-12.71"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21.516 58.379v31.51h12.71"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m50.183 89.887-5.26 17.46h-5.43l-5.27-17.46M78.855 89.887l-5.27 17.46h-5.43l-5.26-17.46"
                />
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-plumbing"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
