import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8517)"
            d="M482.602 278.837c-5.01-2.65-10.191-5.391-15.366-7.903a27.53 27.53 0 01-12.732-36.748 27.679 27.679 0 0124.76-15.498 27.231 27.231 0 0111.988 2.766l.116.056c5.164 2.537 10.51 4.936 15.68 7.257-9.121 4.974-25.241 29.119-24.446 50.07z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8517"
                x1="451.746"
                x2="507.047"
                y1="248.763"
                y2="248.763"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
