import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10363_7791)"
            d="M400.397 274.414a1.008 1.008 0 01-.472-.119l-47.482-25.335a1.004 1.004 0 01-.494-.623.989.989 0 01.115-.786l.943-1.52c2.86-4.6 5.818-9.358 8.579-14.184a27.52 27.52 0 0137.525-10.216 27.532 27.532 0 0110.216 37.526l-.065.115c-2.758 4.766-5.371 9.692-7.899 14.455l-.081.154a.982.982 0 01-.593.488 1.034 1.034 0 01-.292.045z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M408.46 258.661l-.066.118c-2.75 4.752-5.358 9.663-7.878 14.413l-.118.221-47.484-25.336.943-1.519c2.859-4.597 5.815-9.35 8.598-14.214"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10363_7791"
                x1="413.159"
                x2="372.275"
                y1="199.073"
                y2="268.147"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#E2E2E2"></stop>
                <stop offset="0.966" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
