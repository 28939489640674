import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-3">
        <path
            fill={SKIN_COLOR[color]}
            d="M406.11 200.389c3.933 8.473 8.261 16.76 12.419 25.127a240.49 240.49 0 0 1 11.022 25.78c3.252 8.79 6.302 17.675 9.073 26.69 2.748 9.025 5.298 18.143 7.203 27.562a23.15 23.15 0 0 1-18.1 27.278 23.178 23.178 0 0 1-22.674-8.24c-5.999-7.508-11.351-15.318-16.507-23.219-5.132-7.913-9.985-15.956-14.636-24.092a240.329 240.329 0 0 1-12.688-25.002c-3.745-8.56-7.32-17.199-11.29-25.654a31.01 31.01 0 0 1 5.148-34.059 31.004 31.004 0 0 1 33.436-8.278 31.008 31.008 0 0 1 17.536 15.982l.058.125Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m418.41 289.384 12.338-3.608 12.318-3.668c8.22-2.422 16.521-4.591 24.867-6.616 4.183-.98 8.348-2.017 12.551-2.937 4.192-.952 8.388-1.894 12.613-2.74 4.221-.862 8.427-1.771 12.661-2.594 4.253-.76 8.476-1.615 12.76-2.276a15.687 15.687 0 0 1 17.896 13.11 15.724 15.724 0 0 1-8.515 16.437c-3.881 1.931-7.824 3.668-11.737 5.501-3.933 1.77-7.893 3.453-11.837 5.184-3.94 1.747-7.911 3.397-11.885 5.037-3.963 1.674-7.964 3.229-11.946 4.841-7.986 3.161-16.017 6.176-24.129 8.939l-12.179 4.11-12.16 4.17a22.524 22.524 0 0 1-17.192-1.073 22.496 22.496 0 0 1-11.398-12.915 22.498 22.498 0 0 1 6.396-24.175 22.506 22.506 0 0 1 8.578-4.727Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m406.055 200.262.058.125c3.933 8.472 8.261 16.76 12.419 25.127a240.316 240.316 0 0 1 11.022 25.78c3.251 8.79 6.302 17.675 9.073 26.689m-33.573 46.601c-5.998-7.508-11.351-15.317-16.507-23.219-5.131-7.912-9.984-15.955-14.636-24.092a240.32 240.32 0 0 1-12.687-25.002c-3.746-8.56-7.32-17.199-11.29-25.654m181.954 65.028a15.68 15.68 0 0 1-3.986 2.846c-3.881 1.932-7.824 3.669-11.737 5.502-3.932 1.77-7.892 3.453-11.837 5.184-3.94 1.746-7.91 3.397-11.885 5.037-3.963 1.673-7.963 3.228-11.946 4.841-7.986 3.16-16.017 6.176-24.129 8.939l-12.179 4.109-12.16 4.17c-9.476 3.25-21.28-.078-26.974-7.69m25.695-38.807 12.319-3.668c8.22-2.422 16.52-4.591 24.866-6.616 4.184-.98 8.349-2.017 12.551-2.937 4.192-.952 8.388-1.894 12.614-2.74 4.221-.862 8.427-1.771 12.66-2.594 4.253-.76 8.476-1.614 12.761-2.276"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M600.072 242.495a4.61 4.61 0 0 0-2.326-.659 2.871 2.871 0 0 0-.085-1.696l-.015-.043a2.908 2.908 0 0 0-3.432-1.861 25.997 25.997 0 0 0-7.808 3.84c-3.164 2.141-6.151 4.593-9.603 6.23l-1.056.226a38.925 38.925 0 0 1 7.993-8.227 2.275 2.275 0 0 0 .713-2.876 2.277 2.277 0 0 0-2.748-1.148c-4.467 1.51-8.427 4.359-12.064 7.405-4.142 3.47-8.04 7.286-12.645 10.113-4.604 2.828-10.133 4.62-15.426 3.534l-2.064-.552a22.294 22.294 0 0 0-.48-8.213 3.062 3.062 0 0 0-3.109-2.26 3.062 3.062 0 0 0-2.82 2.246c-1.075 3.9-3.687 7.326-6.113 10.641-2.86 3.908-5.687 8.299-5.507 13.139.362 13.681 3.986 20.11 11.913 16.976a250.374 250.374 0 0 0 28.094-17.65l.486-.084c10.81-3.585 20.028-10.708 29.014-17.704l7.371-5.739a7.336 7.336 0 0 0 2.347-2.516 2.473 2.473 0 0 0-.63-3.122Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M521.477 272.334c-.18-4.84 2.647-9.23 5.507-13.138 2.426-3.315 5.038-6.741 6.113-10.641a3.063 3.063 0 0 1 2.82-2.247 3.07 3.07 0 0 1 3.109 2.26c1.123 4.261.802 9.73-.94 13.372m21.613 3.36c5.524-2.707 9.455-7.778 13.079-12.75 3.24-4.445 6.525-9.066 10.962-12.245.443-.315.759-.778.889-1.306a2.27 2.27 0 0 0-.176-1.57 2.275 2.275 0 0 0-2.748-1.148c-4.467 1.51-8.427 4.359-12.064 7.405-4.142 3.47-8.04 7.286-12.645 10.113-4.604 2.828-10.133 4.62-15.426 3.535"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M533.391 289.311a250.52 250.52 0 0 0 48.037-33.722 153.594 153.594 0 0 1 15.119-12.037 2.927 2.927 0 0 0 1.115-3.41l-.016-.043a2.905 2.905 0 0 0-3.431-1.86c-2.8.832-5.44 2.13-7.808 3.839-3.165 2.141-6.152 4.593-9.604 6.23"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M561.969 271.577c10.81-3.585 20.028-10.708 29.014-17.704l7.371-5.739a7.346 7.346 0 0 0 2.347-2.516 2.515 2.515 0 0 0-.63-3.122"
        />
    </g>
);

export default SvgComponent;
