import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8196)"
            d="M494.055 224.119c-8.491-8.307-18.696-15.184-30.909-21.252l15.605 34.205s19.387-8.958 15.304-12.953z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_8196)"
            d="M511.268 244.372l-.209-.265c-3.479-4.395-7.075-8.94-10.464-13.549l-.076-.102a26.628 26.628 0 00-21.293-10.698 26.5 26.5 0 00-26.379 28.795 26.505 26.505 0 005.127 13.509c3.41 4.59 6.695 9.354 9.872 13.961l.101.147c.49.711.984 1.418 1.475 2.128l42.036-33.685-.19-.241z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M467.947 276.173c.49.711.984 1.418 1.475 2.128l42.036-33.684-.19-.242M500.598 230.562c3.55 4.827 7.278 9.522 10.997 14.224"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8196"
                x1="463.146"
                x2="494.62"
                y1="219.97"
                y2="219.97"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_8196"
                x1="387.957"
                x2="524.983"
                y1="372.281"
                y2="181.453"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
