import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#001489"
                d="M628.499 673.588c-2.938-1.236-8.541-3.558-13.041-5.226-16.98-6.296-35.478-26.135-39.648-30.777l-.208-22.831h-41.665l.891 3.322c1.775 6.693 3.64 13.4 5.54 19.937-.208 6.329-1.493 19.048-2.539 28.644a9.897 9.897 0 002.33 7.567 9.933 9.933 0 003.19 2.492 9.924 9.924 0 003.921 1.008l20.304 1.017a41.12 41.12 0 0112.374 2.507 70.775 70.775 0 0020.64 4.146c.319.018.7.027 1.137.027 5.148 0 18.055-1.225 25.292-1.954a5.162 5.162 0 004.63-5.338 5.166 5.166 0 00-3.148-4.541z"
            ></path>
            <path
                fill="url(#paint0_linear_10892_7191)"
                d="M628.499 673.59c-2.939-1.235-8.541-3.557-13.041-5.225-7.938-2.943-16.208-8.847-23.19-14.773a167.587 167.587 0 01-7.966-7.233c-4.689 1.386-11.718 9.925-18.41 9.952-13.086.053-22.265-5.783-26.397-6.09a946.986 946.986 0 01-1.666 16.438 9.904 9.904 0 002.33 7.567 9.913 9.913 0 007.112 3.5l20.304 1.017a41.15 41.15 0 0112.374 2.507 70.73 70.73 0 0020.64 4.146c.318.018.699.027 1.136.027 5.149 0 18.056-1.225 25.292-1.954a5.163 5.163 0 004.63-5.338 5.167 5.167 0 00-3.148-4.541z"
            ></path>
            <mask
                id="mask0_10892_7191"
                style={{ maskType: 'luminance' }}
                width="95"
                height="40"
                x="537"
                y="646"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    d="M628.499 673.59c-2.939-1.235-8.541-3.557-13.041-5.225-7.938-2.943-16.208-8.847-23.19-14.773a167.587 167.587 0 01-7.966-7.233c-4.689 1.386-11.718 9.925-18.41 9.952-13.086.053-22.265-5.783-26.397-6.09a946.986 946.986 0 01-1.666 16.438 9.904 9.904 0 002.33 7.567 9.913 9.913 0 007.112 3.5l20.304 1.017a41.15 41.15 0 0112.374 2.507 70.73 70.73 0 0020.64 4.146c.318.018.699.027 1.136.027 5.149 0 18.056-1.225 25.292-1.954a5.163 5.163 0 004.63-5.338 5.167 5.167 0 00-3.148-4.541z"
                ></path>
            </mask>
            <g fill="#001489" mask="url(#mask0_10892_7191)">
                <path
                    d="M570.819 685.034c-2.192-9.995 7.314-20.466 15.422-24.854a63.841 63.841 0 0116.01-5.846c5.568-1.256 12.899-.695 17.603-4.185 1.464-1.086 1.581-3.772-.555-4.241-3.802-.834-7.754.135-11.575.654a75.65 75.65 0 00-11.813 2.354c-6.348 1.92-12.891 4.272-18.206 8.328-7.841 5.984-18.154 19.789-10.999 29.524a2.264 2.264 0 002.792.879 2.264 2.264 0 001.321-2.613z"></path>
                <path
                    d="M585.528 683.967c-.488-9.09 9.061-15.762 16.114-19.782a42.894 42.894 0 0112.644-4.873c4.802-.977 9.628-.556 14.158-2.606 1.914-.867 1.441-4.182-.596-4.563-11.416-2.134-24.1 2.154-33.485 8.554-8.15 5.559-15.113 13.442-13.461 23.896.413 2.619 4.768 2.008 4.626-.626z"></path>
                <path
                    d="M598.668 684.967c.924-7.875 7.294-11.929 13.928-15.056 7.418-3.497 15.604-3.907 22.999-7.055 2.163-.921 1.53-4.567-.655-5.012-9.503-1.935-21.575 3.336-29.534 8.256-7.089 4.382-11.492 10.284-11.168 18.867.11 2.915 4.105 2.775 4.43 0z"></path>
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M540.159 674.227a9.912 9.912 0 007.111 3.5l20.304 1.017a41.12 41.12 0 0112.374 2.507 70.775 70.775 0 0020.64 4.146c.318.018.7.027 1.136.027 5.149 0 18.056-1.225 25.292-1.954a5.16 5.16 0 004.63-5.338"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7191"
                    x1="537.769"
                    x2="631.651"
                    y1="665.891"
                    y2="665.891"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#009CDE"></stop>
                    <stop offset="0.9" stopColor="#39BDE8"></stop>
                    <stop offset="1" stopColor="#40C1E9"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
