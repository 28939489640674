import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-5">
        <path
            d="M487.578 263.89c-2.848.166-5.69.369-8.53.573-1.556.111-3.11.22-4.663.328-4.484.286-8.973.677-13.178 1.053-2.966.241-5.966.543-8.867.834-1.424.143-2.848.286-4.281.424-9.62.996-18.18 2.07-26.169 3.284l-.938.145c-.449-6.921-1.17-13.973-2.198-21.538a146.884 146.884 0 0 0-4.333-21.474 857.839 857.839 0 0 0-1.802-6.315c-1.377-4.777-2.8-9.717-3.991-14.619l-.044-.174a31.938 31.938 0 0 0-31.028-24.27 32.07 32.07 0 0 0-7.76.956 32.035 32.035 0 0 0-23.313 38.789c1.219 4.891 2.251 9.928 3.249 14.799.44 2.144.879 4.288 1.334 6.424a146.712 146.712 0 0 0 6.128 21.028c2.651 7.319 5.335 14.006 8.205 20.447a187.536 187.536 0 0 0 10.241 19.998 24.895 24.895 0 0 0 2.825 3.74 23.67 23.67 0 0 0 20.89 12.779h.002a23.653 23.653 0 0 0 4.544-.443l12.899-2.529 12.919-2.469c7.926-1.537 16.35-3.399 25.758-5.692 1.388-.35 2.777-.695 4.167-1.036 2.831-.7 5.759-1.423 8.625-2.189 4.093-1.06 8.451-2.198 12.771-3.433 1.5-.423 3.001-.842 4.503-1.257 2.745-.762 5.491-1.524 8.245-2.329l.163-.049s-7.049-9.419-7.208-18.614c-.145-8.37 2.901-17.272 2.901-17.272-.688.034-1.372.07-2.066.101Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-5a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-5a"
                x1={418.71}
                y1={350.544}
                x2={422.18}
                y2={34.785}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
