import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-1-left">
        <path
            d="M576.929 284.582c-1.84 0-3.674-.209-5.468-.623-10.158-2.371-19.814-5.504-27.092-7.968-8.441-2.884-16.984-6.09-26.118-9.802a236.683 236.683 0 0 1-25.19-11.747c-2.446-1.319-4.89-2.648-7.33-3.989-5.554-3.036-11.298-6.176-17.036-9.072a32.036 32.036 0 0 1-14.154-42.984 31.866 31.866 0 0 1 28.594-17.592 31.663 31.663 0 0 1 14.391 3.437l.128.066c5.718 2.917 11.637 5.694 17.361 8.381 2.527 1.184 5.05 2.375 7.57 3.571a236.448 236.448 0 0 1 24.358 13.394c8.386 5.176 16.018 10.177 23.327 15.285 6.285 4.417 14.512 10.357 22.429 17.157a24.191 24.191 0 0 1 2.584 34.077 24.157 24.157 0 0 1-18.354 8.409Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-left-a)"
        />
        <path
            d="M592.701 242.095c-7.916-6.8-16.144-12.74-22.429-17.158-7.309-5.107-14.941-10.108-23.326-15.284a236.552 236.552 0 0 0-24.358-13.394 1004.107 1004.107 0 0 0-7.57-3.572c-4.74-2.224-9.609-4.517-14.391-6.894-.046-.04-.08-.086-.128-.123-9.038-6.977-19.775-12.642-32.825-17.318M468.697 241.379c5.739 2.895 11.482 6.036 17.036 9.072 2.442 1.335 4.885 2.665 7.33 3.988a236.586 236.586 0 0 0 25.19 11.748c9.135 3.711 17.677 6.918 26.118 9.802 7.278 2.464 16.935 5.597 27.092 7.968"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-left-a"
                x1={571.335}
                y1={323.318}
                x2={471.142}
                y2={133.342}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
