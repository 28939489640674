import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, string> = {
    black: '#2B2B2B',
    brown: '#7A5430',
    red: '#F46A25',
    yellow: '#E5BC56',
    gray: '#A0A4AC'
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-8" transform="translate(316, 30)">
        <path
            d="M92.1 121.7c-3-6.2-6-12.7-7.2-19.5-2.1-11.4 1.2-24 9-33.6 11.2-14 41.7-19.6 60.7-1.2-52.4-5.4-21.4 23.4-38.5 33-6.7 3.8-5.4 10.7-6 10.4-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5-.1 1.9-.2 3.6-.3 5.2-.4 3.7-5.6 4.3-6.8.8-1.3-3.7-2.8-7.7-4.8-11.7Z"
            fill="url(#female-34-25-haircut-8-a)"
        />
        <path
            d="M96.8 62.9c.1 0 .1-.1.2-.1-.1.1-.2.2-.4.3.1-.1.1-.2.2-.2Z"
            fill="url(#female-34-25-haircut-8-b)"
        />
        <path
            d="M110 110.899c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-haircut-8-a"
                x1={71.885}
                y1={95.618}
                x2={165.428}
                y2={95.618}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor={HAIR_COLOR[color]} stopOpacity={0.805} />
                <stop offset={0.328} stopColor={HAIR_COLOR[color]} stopOpacity={0.646} />
                <stop offset={0.962} stopColor={HAIR_COLOR[color]} stopOpacity={0.318} />
                <stop offset={0.996} stopColor={HAIR_COLOR[color]} stopOpacity={0.3} />
            </linearGradient>
            <linearGradient
                id="female-34-25-haircut-8-b"
                x1={71.892}
                y1={62.874}
                x2={165.411}
                y2={62.874}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor={HAIR_COLOR[color]} stopOpacity={0.805} />
                <stop offset={0.328} stopColor={HAIR_COLOR[color]} stopOpacity={0.646} />
                <stop offset={0.962} stopColor={HAIR_COLOR[color]} stopOpacity={0.318} />
                <stop offset={0.996} stopColor={HAIR_COLOR[color]} stopOpacity={0.3} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
