import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-option-2">
        <path
            fill="url(#man-34-25-tshirt-arm-right-option-2-a)"
            d="M369.852 286.481c-3.209-6.591-6.24-13.434-9.267-20.925a158.438 158.438 0 0 1-7.214-21.587 898.07 898.07 0 0 1-1.664-6.606c-1.25-5.019-2.544-10.21-4.018-15.246a32.037 32.037 0 0 1 21.726-39.7 32.069 32.069 0 0 1 9.001-1.296 32.158 32.158 0 0 1 19.157 6.432 32.16 32.16 0 0 1 11.541 16.589l.049.168c1.445 5.043 3.125 10.118 4.75 15.025.715 2.161 1.43 4.321 2.128 6.486a158.47 158.47 0 0 1 5.44 22.107 346.83 346.83 0 0 1 2.304 14.289c-11.29 1.467-42.808 11.877-53.933 24.264Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-option-2-a"
                x1={369.285}
                x2={394.442}
                y1={184.623}
                y2={267.033}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
