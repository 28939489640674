import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m354.139 738.877 2.732-9.89c.614-2.252 1.234-4.503 1.862-6.753 1.69-6.095 3.437-12.398 4.968-18.677a651.592 651.592 0 0 0 2.088-8.595c1.343-5.642 2.733-11.475 4.393-17.089 4.491-16.077 11.776-30.634 21.332-47.54 4.648-8.021 9.283-15.246 14.176-22.096a119.68 119.68 0 0 1 2.527-3.456c.466-.625.933-1.248 1.393-1.873.424-.541.824-1.056 1.224-1.571.947-1.218 1.925-2.477 2.946-3.697a136.221 136.221 0 0 1 5.817-6.697c-.611-7.743-1.183-15.597-1.737-23.199-.251-3.445-1.053-14.351-1.053-14.351-.36-4.945-.732-10.057-1.232-15.101l-.965-9.601-.491-4.799-.411-4.807c-.513-6.983-.696-13.227-.561-19.099.203-7.541.479-13.344.898-18.818.555-7.328 1.172-13.26 1.943-18.667l.644-4.623.791-4.634c.61-3.449 1.247-6.466 1.949-9.228a52.018 52.018 0 0 1 18.477-28.299c9.136-7.113 20.385-15.14 31.964-15.138h57.59c-4.988 19.471-6.581 30.777-5.514 53.832l.236 5.501c.244 6.321.064 12.652-.539 18.95-.519 5.962-1.29 12.05-2.358 18.612-.863 5.393-2.013 11.242-3.618 18.4-1.423 6.324-3.092 12.456-4.96 18.225-2.135 6.61-4.827 13.048-6.934 17.918l-1.327 3.048c-2.094 4.813-4.258 9.79-6.599 14.661a189.127 189.127 0 0 1-2.139 4.408 186.95 186.95 0 0 1-2.222 4.396c-.957 1.891-2.008 3.806-3.023 5.657-.562 1.024-1.124 2.048-1.674 3.074-2.836 4.956-5.936 10.222-9.486 15.352a27.289 27.289 0 0 1-3.064 9.662l-24.703 45.692-12.362 22.841-38.677 78.925-44.301-20.856Z"
            fill="#888B8D"
        />
        <path
            d="M363.703 703.562c-1.18 4.839-2.487 9.69-3.799 14.45l47.926 22.569 8.208-16.75-51.368-24.19a487.187 487.187 0 0 1-.967 3.921Z"
            fill="#63666A"
        />
        <path
            d="M363.703 703.562c-1.18 4.839-2.487 9.69-3.799 14.45l47.926 22.569 8.208-16.75-51.368-24.19a487.187 487.187 0 0 1-.967 3.921ZM370.181 677.877a171.864 171.864 0 0 0-1.171 4.151l54.851 25.831 8.209-16.75-57.267-26.968a143.672 143.672 0 0 0-4.622 13.736Z"
            fill="#63666A"
        />
        <path
            d="M370.181 677.877a171.864 171.864 0 0 0-1.171 4.151l54.851 25.831 8.209-16.75-57.267-26.968a143.672 143.672 0 0 0-4.622 13.736Z"
            fill="#63666A"
        />
        <path
            d="m352.741 400.539-.228.682c-4.005 11.726-6.021 27.272-3.938 39.398-2.882 9.287-1.095 19.198 1.386 28.6a175.384 175.384 0 0 0 3.084 10.414l1.712 5.142 1.836 5.059c.883 2.528 1.881 5.052 2.847 7.494.345.871.689 1.742 1.026 2.606.961 2.53 2.041 5.055 3.086 7.497.358.838 3.201 7.516 3.201 7.516l2.262 4.931c.541 1.158 1.07 2.32 1.6 3.483.954 2.091 1.94 4.254 2.973 6.357 2.957 6.166 6.354 12.485 10.385 19.317 2.036 3.451 4.207 6.918 6.307 10.271 1.751 2.795 3.562 5.687 5.283 8.55l5.709 9.424 5.606 9.469 18.009 30.5a107.802 107.802 0 0 0-1.075 3.122l-.006-.001c-.161.486-.313.971-.464 1.457l-.422 1.335a154.137 154.137 0 0 0-.884 3.01c-.438 1.48-.822 2.977-1.193 4.426-.158.613-.316 1.227-.485 1.876-.166.736-.341 1.472-.515 2.209a113.6 113.6 0 0 0-.908 4.046c-1.697 7.983-3.006 16.198-4.004 25.126-1.883 18.718-2.643 34.467-.231 50.474.757 5.622 7.63 66.428 7.63 66.428h48.29s1.813-36.595 2.731-47.22c.51-5.905 1.024-11.81 1.542-17.716l2.184-25.072 2.043-25.07 4.062-50.14a53.706 53.706 0 0 0-.131-5.91 55.746 55.746 0 0 1-.037-6.553 314.83 314.83 0 0 0-.896-42.411 509.984 509.984 0 0 1-.355-3.94 447.59 447.59 0 0 0-.67-7.14l-1.221-10.995c-.687-6.035-1.675-13.967-3.24-21.796-1.358-6.833-3.082-13.945-5.123-21.14-2.316-8.147-6.702-21.563-6.702-21.563a154.7 154.7 0 0 0 17.838-13.652c22.03-19.471 35.404-38.65 40.888-58.634l.347-1.264-171.139-.002Z"
            fill="url(#female-34-25-pants-8-walk-2-a)"
        />
        <path
            d="M419.848 758.846h51.92c.358-6.534.75-13.356 1.098-18.651h-55.145c.707 6.177 1.449 12.69 2.127 18.651Z"
            fill="url(#female-34-25-pants-8-walk-2-b)"
        />
        <path
            d="M419.848 758.846h51.92c.358-6.534.75-13.356 1.098-18.651h-55.145c.707 6.177 1.449 12.69 2.127 18.651Z"
            fill="url(#female-34-25-pants-8-walk-2-c)"
        />
        <path
            d="M414.702 714.334c.14 1.042.492 3.985.97 8.082h58.65l.573-6.59 1.051-12.06h-62.389c.245 3.532.614 7.044 1.145 10.568Z"
            fill="url(#female-34-25-pants-8-walk-2-d)"
        />
        <path
            d="M414.702 714.334c.14 1.042.492 3.985.97 8.082h58.65l.573-6.59 1.051-12.06h-62.389c.245 3.532.614 7.044 1.145 10.568Z"
            fill="url(#female-34-25-pants-8-walk-2-e)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-8-walk-2-a"
                x1={391.98}
                y1={591.351}
                x2={464.847}
                y2={588.749}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-walk-2-b"
                x1={500.276}
                y1={745.042}
                x2={419.601}
                y2={751.548}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-walk-2-c"
                x1={406.113}
                y1={749.521}
                x2={483.877}
                y2={749.521}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-walk-2-d"
                x1={497.352}
                y1={708.783}
                x2={416.677}
                y2={715.289}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-walk-2-e"
                x1={395.77}
                y1={713.091}
                x2={485.163}
                y2={713.091}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
