import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M458.343 806.652a652.08 652.08 0 0 1-1.958-23.303c-.255-3.873-.596-7.759-.781-11.622l-.617-11.599c-.44-7.737-.742-15.453-1.423-23.225-.608-7.761-1.575-15.574-1.828-23.284-.771-15.457 2.043-30.396 5.444-45.25a213.537 213.537 0 0 1 6.553-22.071c.628-1.827 1.376-3.637 2.057-5.457.76-1.808 1.471-3.623 2.297-5.422a99.74 99.74 0 0 1 5.47-10.718l6.404-10.763a26.119 26.119 0 0 1 31.899-10.997 26.124 26.124 0 0 1 16.131 29.637l-9.234 44.701-4.627 22.35-4.767 22.33c-3.201 14.883-6.304 29.78-9.585 44.651l-9.765 44.625a15.92 15.92 0 0 1-31.387-1.763l-.283-2.82Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m475.16 632.794-16.139-42.661-4.049-10.663-4.16-10.644c-2.746-7.101-5.796-14.149-8.483-21.26-2.686-7.111-5.125-14.264-7.189-21.482-1.066-3.603-1.971-7.233-2.964-10.849l-1.457-5.428-1.309-5.454c-.861-3.638-1.79-7.265-2.52-10.925-.762-3.655-1.594-7.298-2.23-10.975l-1.018-5.504-.877-5.527a183.623 183.623 0 0 1-1.396-11.118 51.018 51.018 0 0 1 39.69-54.66 51.023 51.023 0 0 1 59.146 32.632l1.7 4.81c1.271 3.568 2.358 7.167 3.349 10.783l1.454 5.429 1.28 5.459c.906 3.63 1.571 7.302 2.322 10.959.766 3.654 1.389 7.333 2.008 11.012 1.234 7.36 2.194 14.766 3.048 22.191.849 7.425 1.446 14.894 1.722 22.418.283 7.522.095 15.125-.237 22.753l-.528 11.447c-.201 3.82-.493 7.655-.735 11.482-1.189 15.346-2.914 30.783-6.488 46.537l-1.267 5.583a27.57 27.57 0 0 1-32.986 20.787 27.856 27.856 0 0 1-19.687-17.132Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m458.626 809.474-.283-2.82a652.22 652.22 0 0 1-1.958-23.302c-.255-3.874-.596-7.76-.781-11.623l-.617-11.599c-.44-7.736-.742-15.453-1.423-23.225-.608-7.761-1.575-15.574-1.828-23.283-.771-15.458 2.043-30.397 5.444-45.25a213.39 213.39 0 0 1 6.553-22.071c.628-1.828 1.376-3.638 2.057-5.457.76-1.809 1.471-3.624 2.297-5.423a99.74 99.74 0 0 1 5.47-10.718M527.991 632.578l-9.233 44.702-4.628 22.35-4.766 22.329c-3.201 14.883-6.304 29.78-9.585 44.652l-9.765 44.625"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M470.824 621.332c-29.446-77.761-30.074-75.627-35.683-95.248-7.06-23.855-12.389-51.345-13.772-65.78a51.023 51.023 0 0 1 39.69-54.66 51.023 51.023 0 0 1 59.146 32.632c21.799 61.184 16.08 126.628 16.118 127.261-1.369 25.968-8.344 67.142-8.332 67.042"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m490.013 811.232 11.73 36.883c1.237 3.887 2.83 5.2 6.308 7.333l13.039 7.997a5.42 5.42 0 0 1 2.473 5.722 5.43 5.43 0 0 1-5.324 4.331c-3.29-.006-7.8-.009-11.318-.01a37.9 37.9 0 0 1-10.743-1.56l-5.266-1.56a23.35 23.35 0 0 1-13.158-9.989l-5.546-8.849c-2.394-3.818-4.84-4.863-8.924-6.767l-5.553-2.589a12.927 12.927 0 0 1-6.763-15.91l7.156-20.842"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m490.013 811.232 10.73 34.883a16.996 16.996 0 0 0 7.308 9.333l13.039 7.997a5.42 5.42 0 0 1 2.473 5.722 5.43 5.43 0 0 1-5.324 4.331c-3.29-.006-7.8-.009-11.318-.01a37.9 37.9 0 0 1-10.743-1.56l-5.266-1.56a23.35 23.35 0 0 1-13.158-9.989l-5.546-8.849c-2.394-3.818-4.84-4.863-8.924-6.767l-5.553-2.589a12.927 12.927 0 0 1-6.763-15.91l7.156-20.842"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.148a90.41 90.41 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.616 131.422 44.512 24.407-21.936 36.192-41.867 40.779-59.909H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.148a90.41 90.41 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.616 131.422 44.512 24.407-21.936 36.192-41.867 40.779-59.909"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M398.463 818.709a726.05 726.05 0 0 1-6.054-23.917c-.937-3.988-1.961-7.974-2.827-11.964l-2.662-11.97c-1.802-7.979-3.467-15.963-5.512-23.934-1.974-7.974-4.309-15.936-5.924-23.921-3.496-15.962-3.372-32.041-2.655-48.139a238.56 238.56 0 0 1 2.541-24.196c.295-2.019.71-4.042 1.059-6.063.427-2.023.806-4.044 1.3-6.07.927-4.12 2.09-8.183 3.486-12.17l3.976-11.203a26.258 26.258 0 0 1 50.995 9.42l-1.171 48.263-.597 24.133-.738 24.136c-.514 16.093-.931 32.181-1.526 48.276l-1.709 48.281a15.927 15.927 0 0 1-31.285 3.627l-.697-2.589Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m380.858 626.518-10.337-43.165-2.598-10.791-2.711-10.787c-1.78-7.192-3.868-14.372-5.587-21.567-1.72-7.194-3.188-14.398-4.276-21.616-.579-3.608-.993-7.222-1.498-10.832l-.724-5.417-.574-5.423c-.371-3.616-.81-7.229-1.049-10.849-.271-3.62-.612-7.236-.754-10.86l-.279-5.434-.136-5.439c-.084-3.627-.053-7.258.091-10.893a51.023 51.023 0 0 1 100.82-8.964l1.108 5.129c.786 3.6 1.387 7.207 1.889 10.817l.72 5.417.545 5.424c.417 3.614.589 7.237.848 10.857.275 3.619.404 7.244.53 10.869.246 7.25.216 14.51.077 21.774-.144 7.265-.543 14.539-1.268 21.825-.718 7.286-1.912 14.59-3.253 21.9l-2.042 10.965c-.706 3.657-1.505 7.316-2.252 10.974-3.214 14.639-6.972 29.299-12.604 44.029l-2.097 5.486a27.585 27.585 0 0 1-52.589-3.429Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m399.16 821.3-.697-2.59a725.359 725.359 0 0 1-6.054-23.916c-.937-3.989-1.961-7.974-2.827-11.965l-2.662-11.97c-1.802-7.979-3.467-15.962-5.512-23.933-1.974-7.974-4.309-15.936-5.924-23.921-3.496-15.962-3.372-32.041-2.655-48.14a238.541 238.541 0 0 1 2.541-24.195c.295-2.019.71-4.042 1.059-6.063.427-2.023.806-4.045 1.3-6.07.927-4.12 2.09-8.184 3.486-12.17M436.099 628.148l-1.084 44.697-.597 24.132-.737 24.137c-.515 16.092-.932 32.181-1.527 48.275l-1.709 48.281M451.247 444.484c.316 2.631 6.629 22.55 5.717 70.288-.708 35.898-11.664 84.18-21.419 109.692M380.859 626.519c-10.543-44.307-12.146-50.519-15.646-64.743-5.918-23.92-8.973-35.734-12.659-64.855a234.051 234.051 0 0 1-.177-55.648"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m399.16 821.297-3.016 13.645a19.653 19.653 0 0 0 6.071 18.375 28.95 28.95 0 0 1 3.726 4.218l8.738 12.08a14.056 14.056 0 0 0 11.405 5.649h25.219a7.812 7.812 0 0 0 8.05-7.272 7.224 7.224 0 0 0-2.241-5.41l-13.293-11.579a13.074 13.074 0 0 1-3.315-5.211l-10.306-25.823"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m399.16 821.297-3.016 13.645a19.653 19.653 0 0 0 6.071 18.375 28.95 28.95 0 0 1 3.726 4.218l8.738 12.08a14.056 14.056 0 0 0 11.405 5.649h25.219a7.812 7.812 0 0 0 8.05-7.272 7.224 7.224 0 0 0-2.241-5.41l-13.293-11.579a13.074 13.074 0 0 1-3.315-5.211l-10.306-25.823"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
