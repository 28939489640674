import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m393.914 363.275-12.103.018-12.207-.19a21.329 21.329 0 0 1-19.438-12.321c-1.246-2.767-1.522-6.379-1.708-9.393a254.67 254.67 0 0 1 .192-25.684c.355-8.088.973-16.345 1.89-25.248.82-8.563 6.186-43.866 7.722-49.482 4.843-17.711 15.274-22.327 28.457-22.327 1.727.002 3.449.164 5.146.485a27.5 27.5 0 0 1 21.904 32.136l-.024.124c-1.109 5.713-2.039 11.567-2.938 17.228-.397 2.501-.799 5-1.207 7.498a206.544 206.544 0 0 1-5.601 24.595c-2.258 7.971-4.56 15.36-7.022 22.537a245.718 245.718 0 0 1 20.859.492c1.324.094 2.644.177 3.964.259 2.696.17 5.484.345 8.236.615l6.076.53c1.318.118 2.634.265 3.951.411.718.08 1.436.158 2.154.235 5.299.589 9.654 1.145 13.712 1.752l1.565.234-.882 1.313c-4.614 6.86-5.754 18.483-2.711 27.637l.37 1.113-1.159.19a409.135 409.135 0 0 1-10.466 1.554c-.728.094-1.449.193-2.169.291-1.307.179-2.614.358-3.921.507l-6.073.686c-2.73.336-5.508.58-8.196.816a486.32 486.32 0 0 0-3.965.36 246.655 246.655 0 0 1-22.409 1.038s-1.333-.003-1.999-.009Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-4-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-4-right-a"
                x1={343.075}
                y1={241.101}
                x2={439.364}
                y2={372.089}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
