import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-3-walk-2">
        <path
            d="M515.189 821.429c-9.41-23.119-25.123-85.218-27.903-95.433-6.29-23.111-8.18-51.611-6.447-76.699.221-2.55.496-5.221.84-7.86a33.218 33.218 0 0 0-1.647-15.585c-7.266-20.128-14.334-41.635-21.267-61.51a795.812 795.812 0 0 1-27.73-97.163c-1.024-5.478-2.575-11.952-3.457-17.43-5.224-27.402 14.898-55.733 42.494-59.822 25.732-4.602 51.743 11.874 58.61 37.091a316.287 316.287 0 0 1 5.493 22.203c7.112 35.98 11.719 73.167 10.67 112.216-.411 19.099-.932 36.815-4.156 58.764a32.964 32.964 0 0 0-.216 7.758c5.46 60.333 13.79 127.803 21.994 185.93l-47.278 7.54Z"
            fill="#EF0025"
        />
        <path
            d="M531.839 386.121H370.738l-.865.499a901.126 901.126 0 0 1-2.767 4.734c-9.149 15.582-15.33 26.123-15.083 48.174a62.665 62.665 0 0 0-.151 7.081l-.327 7.1c-.69 26.552-.47 53.041 1.702 79.428 2.008 21.883 3.965 45.353 6.078 67.734a33.06 33.06 0 0 1-6.441 22.833l-.074.1c-14.657 20.537-28.778 47.089-34.267 70.484-3.176 13.778-21.633 97.201-21.633 97.201l42.181 19.084s51.1-124.644 56.451-135.369l21.535-42.933a27.208 27.208 0 0 0 2.631-8.706c9.206-20.936 13.95-37.078 19.239-55.431 6.465-22.027 14.466-50.092 20.304-70.56a46.942 46.942 0 0 1 17.07-24.985c13.56-10.01 21.91-35.8 23.081-39.578 3.256-1.73 25.285-14.461 33.404-45.637l-.967-1.253Z"
            fill="url(#man-34-25-pants-3-walk-2-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-3-walk-2-a"
                x1={484.721}
                y1={654.403}
                x2={145.911}
                y2={338.458}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
