import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6870)"
            d="M494.767 223.314a2.705 2.705 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.147 108.147 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6870)"
            d="M564.09 307.729c-1.352 0-2.7-.135-4.024-.404-9.158-1.884-17.898-4.495-24.49-6.559-7.623-2.416-15.341-5.13-23.599-8.299a202.052 202.052 0 01-22.756-10.146 873.119 873.119 0 01-6.622-3.485c-5.009-2.65-10.19-5.391-15.364-7.902a27.514 27.514 0 01-8.554-6.478 27.51 27.51 0 01-6.891-19.888 27.51 27.51 0 012.713-10.382 27.679 27.679 0 0124.76-15.498 27.218 27.218 0 0111.988 2.766l.117.057c5.162 2.536 10.508 4.935 15.678 7.256 2.277 1.022 4.552 2.048 6.824 3.08a201.57 201.57 0 0121.999 11.71c7.568 4.557 14.455 8.974 21.047 13.499 5.686 3.932 13.12 9.221 20.233 15.28a20.096 20.096 0 015.826 22.256 20.095 20.095 0 01-18.885 13.137z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_6870)"
            d="M567.912 308.111a19.564 19.564 0 01-19.081-15.576l-.024-.122c-.647-3.249-1.466-6.528-2.258-9.699a474.332 474.332 0 01-1.044-4.23 86.483 86.483 0 01-2.162-14.367c-.046-.789-.103-1.568-.159-2.346-.116-1.607-.238-3.269-.261-4.937l-.113-3.618a61.71 61.71 0 01-.011-2.396c.003-.43.006-.86.005-1.29 15.316 8.008 27.461 3.092 33.099-6.702.17.407.34.803.51 1.198.316.734.631 1.468.918 2.207l1.309 3.397c.62 1.531 1.154 3.109 1.671 4.636.251.739.501 1.478.761 2.215a86.344 86.344 0 013.591 14.085c.237 1.434.459 2.869.682 4.304.502 3.229 1.02 6.569 1.686 9.81a19.5 19.5 0 01-8.162 20.071 19.492 19.492 0 01-7.01 2.956 19.604 19.604 0 01-3.947.404z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6870"
                x1="472.914"
                x2="495.633"
                y1="222.636"
                y2="222.636"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6870"
                x1="451.728"
                x2="584.216"
                y1="263.208"
                y2="263.208"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_6870"
                x1="542.793"
                x2="587.438"
                y1="275.47"
                y2="275.47"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
