import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9041)"
            d="M413.057 251.4l.024-.125a27.505 27.505 0 00-4.28-20.624 27.502 27.502 0 00-45.754.43 27.51 27.51 0 00-4.006 9.963c-1.027 5.423-2.25 10.899-3.447 16.221 0 0 16.752.042 27.74 1.343 12.488 1.479 27.283 6.902 27.283 6.902.749-4.678 1.533-9.439 2.44-14.11z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9041"
                x1="364.296"
                x2="407.091"
                y1="225.742"
                y2="268.537"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
