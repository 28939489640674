import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6608)"
            d="M411.153 241.252a27.658 27.658 0 00-10.116-12.806 27.656 27.656 0 00-15.574-4.876 27.407 27.407 0 00-9.82 1.823 27.326 27.326 0 00-9.092 5.703 27.324 27.324 0 00-6.192 8.767 27.306 27.306 0 00-.576 21.04 78.39 78.39 0 012.711 8.407c.557 2.037 1.133 4.142 2.254 7.373l.434 1.249 1.084-.758c12.678-8.857 37.278-17.368 50.619-17.513l1.853-.067-7.585-18.342z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6608"
                x1="357.953"
                x2="432.052"
                y1="250.751"
                y2="250.751"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
