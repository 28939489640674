import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10087)"
            d="M476.74 232.545c.76-3.49 1.23-7.037 1.406-10.605a33.848 33.848 0 00-1.403-11.518 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_10087)"
            d="M464.051 269.133a304.945 304.945 0 00-4.617-5.356 26.323 26.323 0 01-6.618-19.172 26.316 26.316 0 018.877-18.236 26.472 26.472 0 0117.565-6.666 26.519 26.519 0 0119.844 8.926l.087.098a309.718 309.718 0 005.384 5.951l-40.522 34.455z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M499.191 228.727a309.718 309.718 0 005.384 5.951l-40.522 34.455a304.945 304.945 0 00-4.617-5.356"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10087"
                x1="502.467"
                x2="422.66"
                y1="203.888"
                y2="273.936"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_10087"
                x1="507.256"
                x2="427.449"
                y1="209.35"
                y2="279.398"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
