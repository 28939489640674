import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M480.759 285.453c-4.103 1.078-8.162 2.252-12.203 3.469-1.9.586-3.816 1.138-5.704 1.753l-5.659 1.854c-3.79 1.197-7.494 2.589-11.24 3.886a232.116 232.116 0 0 0-21.023 8.567 321.774 321.774 0 0 0-2.946-20.079 151.353 151.353 0 0 0-5.063-20.975c-2.171-6.854-4.513-13.66-6.459-20.578l-.04-.139a26.501 26.501 0 1 0-50.94 14.631c1.984 6.907 3.572 13.927 5.331 20.899a151.574 151.574 0 0 0 6.728 20.502c2.664 6.714 5.53 13.371 8.674 19.948a190.88 190.88 0 0 0 10.544 19.418 19.73 19.73 0 0 0 5.388 5.541 18.508 18.508 0 0 0 21.468 5.124l10.891-4.668 10.949-4.538a231.33 231.33 0 0 0 21.354-10.299c3.47-1.916 6.981-3.738 10.407-5.755l5.164-2.966c1.72-.992 3.411-2.047 5.119-3.068 4.084-2.478 8.144-5.012 12.144-7.677-6.397-1.572-13.554-11.259-12.884-24.85Z"
            fill="url(#female-34-25-shirt-1-arm-opt-8-right-a)"
        />
        <path
            d="M390.759 338.606a190.759 190.759 0 0 1-10.544-19.417c-3.144-6.578-6.01-13.235-8.674-19.949a151.528 151.528 0 0 1-6.728-20.502c-1.759-6.971-3.347-13.991-5.331-20.898-2.525-8.792-.097-19.232 6.12-25.16M410.426 243.211l.04.139c1.946 6.918 4.288 13.724 6.459 20.578a151.497 151.497 0 0 1 5.063 20.975 328.854 328.854 0 0 1 2.63 17.33M481.502 317.984c-1.708 1.021-3.399 2.076-5.119 3.068l-5.164 2.966c-3.426 2.016-6.937 3.839-10.407 5.755a231.206 231.206 0 0 1-21.354 10.299l-10.949 4.537-10.891 4.669c-9.392 4.026-21.093-.275-26.856-10.666M424.016 305.398a231.601 231.601 0 0 1 21.94-8.983c3.745-1.297 7.449-2.689 11.239-3.886l5.659-1.855c1.888-.614 3.804-1.166 5.705-1.752M481.501 317.981c4.084-2.479 8.144-5.012 12.145-7.678-6.398-1.572-13.555-11.259-12.885-24.85-4.102 1.078-8.162 2.252-12.202 3.469"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-8-right-a"
                x1={358.445}
                y1={287.397}
                x2={493.643}
                y2={287.397}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
