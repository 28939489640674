import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9248)"
            d="M365.688 276.355c-1.109-3.197-1.68-5.286-2.232-7.305a78.954 78.954 0 00-2.743-8.505 26.514 26.514 0 0112.419-32.921 26.528 26.528 0 0112.327-3.054 26.661 26.661 0 0124.756 17.039l7.046 17.037-.417.016c-13.474.146-38.347 8.744-51.156 17.693z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M365.693 276.349c-2.453-7.068-2.304-8.824-4.976-15.809 0 0-4.193-11.279.555-20.274M410.218 241.609l.049.13 6.996 16.908-.416.015c-13.474.146-38.347 8.745-51.156 17.693"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9248"
                x1="358.95"
                x2="430.032"
                y1="250.462"
                y2="250.462"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
