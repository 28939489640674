import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string, string, string]> = {
    white: ['#FFDBAE', '#FBD7AA', '#EECA9E', '#D8B68B', '#CDAB81'],
    black: ['#6C453A', '#684338', '#5B3C32', '#5B3C32', '#4D352C'],
    brown: ['#CB865E', '#C7835C', '#BA7B57', '#A46E4F', '#9A684B'],
    yellow: ['#FCB47F', '#F8B07C', '#EBA473', '#D58F65', '#CB865E'],
};

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="female-bold-34-25-head" transform="translate(316, 30)">
        <path
            d="M165.401 100.496c-1.4-4.7 1.2-10 1.2-15.2-1.2-9.3-13.9-23.6-24.7-27-18.5-5.7-33.3-1.4-44.5 6.3-3.2 2.2-26.6 23.4-.7 66.9 11.1 17.3-5.2 32.6-5.2 32.6 4.7 6.5 39.4 33.3 53.2 28.6 13.9-4.6 3.3-15.8-2.6-26.2-.2-.3-.3-.6-.5-.9-2.1-4.2-2.4-8.2-2-11.5 6.9.5 10.7.9 14.6-1.3 14.9-5.9 16.4-21.6 16.1-31.1-.1-10.2-3.5-16.5-4.9-21.2Z"
            fill={SKIN_COLOR[color][0]}
        />
        <path
            d="M150.901 182.395c-2.2-9.1-13-16.2-11.1-28.5-13-2.2-20.1-9-26.8-16.3-1.2-1.3 6.7 29.1 7.3 30.8.6 1.7 4.4 3.3 5.5 4.8 4 5.8 9.4 10.7 15.7 14 .8.4 1.6.9 2.6 1 .9.1 1.9-.1 2.5-.8.7-.9.7-2.1.5-3.2-.2-1.1 4-.7 3.8-1.8Z"
            fill="url(#female-bold-34-25-head-a)"
        />
        <path
            d="M164.801 91.997c-1.7-2.1-9.8-2.9-10.3 4.2-.6 9.2 5.2 23.6 2.9 26.2-.8.8-2.7 2.5-2.8 3.7-.4 4.1.2 8.2.4 12.3.3 4.7 1.3 12.5-3.2 15.4.4-.6 6.5-2.5 7.1-2.9 10.7-7.9 14.6-23.6 8.1-44.8-.8-2.5-2-5.2-2.4-7.8-.2-2.6 1.1-5.2.2-6.3Z"
            fill="url(#female-bold-34-25-head-b)"
        />
        <path
            d="M113.402 137.398c8.2 14.4 32.8 19.5 42.6 14.5 8.8-4.5 15.4-12.4 14.4-31.5-.5-9.5-3.6-15.3-5-20-1.4-4.7 1.4-9.7.9-14.9-1-11.4-14.5-29.1-36.9-30.6-17.7-1.2-30.5 6.8-35.5 13.1-7.7 9.6-11.4 22.4-9.2 34.4 1.5 8.2 5.3 15.7 8.9 23.2 4.5 9.4 13.9 20-1.6 37.8"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-bold-34-25-head-a"
                x1={122.39}
                y1={175.877}
                x2={136.812}
                y2={151.876}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.322} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.598} stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.883} stopColor={SKIN_COLOR[color][3]} />
                <stop offset={0.999} stopColor={SKIN_COLOR[color][4]} />
            </linearGradient>
            <linearGradient
                id="female-bold-34-25-head-b"
                x1={161.009}
                y1={153.752}
                x2={161.009}
                y2={90.489}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.322} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.598} stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.883} stopColor={SKIN_COLOR[color][3]} />
                <stop offset={0.999} stopColor={SKIN_COLOR[color][4]} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
