import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#2B2729"
                d="M668.642 672.982c-2.935-1.234-8.533-3.554-13.041-5.225-7.75-2.874-15.798-8.546-22.681-14.337a177.111 177.111 0 01-18-17.612l3.488-21.66h-39.862s1.774 28.077 1.015 36.309c-.408 4.423-.941 9.65-1.588 15.595a9.99 9.99 0 005.526 10.052c1.222.601 2.555.946 3.915 1.014l20.303 1.018a41.083 41.083 0 0112.374 2.507 70.774 70.774 0 0020.641 4.145c.318.019.7.027 1.136.027 5.147 0 18.056-1.224 25.292-1.954a5.149 5.149 0 004.577-4.364 5.154 5.154 0 00-3.095-5.515z"
            ></path>
            <path
                fill="#888B8D"
                d="M668.667 672.958c-2.935-1.234-8.533-3.554-13.041-5.225-7.75-2.874-15.798-8.546-22.681-14.337-2.376-2-4.614-4.013-6.652-5.93-3.44-.082-13.912 5.794-19.66 6.152-11.52.72-20.072-3.664-27.048-3.185-.408 4.422-.94 9.65-1.588 15.594a10.008 10.008 0 002.333 7.57 10.003 10.003 0 007.109 3.497l20.303 1.017a41.12 41.12 0 0112.374 2.507 70.742 70.742 0 0020.64 4.146c.319.018.7.027 1.137.027 5.146 0 18.056-1.225 25.292-1.954a5.156 5.156 0 001.482-9.879z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M580.031 672.945a9.899 9.899 0 007.112 3.5l20.303 1.018a41.09 41.09 0 0112.374 2.507 70.774 70.774 0 0020.64 4.145c.319.019.7.028 1.137.028 5.147 0 18.056-1.225 25.292-1.955"
            ></path>
        </g>
    );
}
