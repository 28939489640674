import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={542}
            height={237}
            viewBox="0 0 542 237"
            fill="none"
            id="object-meals-6"
            {...attributes}
        >
            <path
                fill="url(#object-meals-6-a)"
                d="M110.771 18.82h-84.52L1.62 60H86.14l24.631-41.18Z"
            />
            <path
                fill="#D2F0FA"
                d="M86.14 60H1.62v175.326h84.52V60ZM110.773 1.137H26.252V18.82h84.521V1.137Z"
            />
            <path
                fill="#5BC2E7"
                d="m135.404 60-24.632-41.18L86.141 60v175.325h49.263V59.999Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M64.92 60H1.62v175.326h84.52M135.403 166.357V59.999L110.771 18.82M86.14 235.327h49.264V205.77M26.251 18.82 1.62 60M110.772 18.82V1.138h-84.52V18.82"
            />
            <path fill="#FFB33B" d="M496.974 216.898H212.006v17.06h284.968v-17.06Z" />
            <path
                fill="url(#object-meals-6-b)"
                d="M339.757 142.824h-35.611l106.397 72.801h35.611l-106.397-72.801Z"
            />
            <path fill="url(#object-meals-6-c)" d="M304.146 142.824v72.801h106.397l-106.397-72.801Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m357.344 154.858-17.587-12.034h-35.611v72.801h142.008l-60.747-41.566"
            />
            <path
                fill="url(#object-meals-6-d)"
                d="M321.365 180.387a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <path
                fill="url(#object-meals-6-e)"
                d="M321.24 206.789a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <path
                fill="url(#object-meals-6-f)"
                d="M347.766 206.855a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <path
                fill="url(#object-meals-6-g)"
                d="m165.432 143.629-35.836 30.744h136.801l-35.837-30.744c-18.735-16.073-46.394-16.073-65.128 0Z"
            />
            <path
                fill="#fff"
                d="M285.408 159.023c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-52.55c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h174.82Z"
            />
            <path
                fill="url(#object-meals-6-h)"
                d="M285.407 159.023c-.84 3.78-2.22 9.13-4.52 15.35-1.78 4.83-4.12 10.18-7.19 15.73-1.1 1.98-2.29 3.98-3.58 6-1.73 2.71-3.65 5.43-5.77 8.14-3.61 4.61-7.8 9.17-12.68 13.51-9.67 8.61-19.48 14.07-27.4 17.57h-21.58c-7.91-3.5-17.73-8.96-27.4-17.57-4.88-4.34-9.07-8.9-12.68-13.51a107.07 107.07 0 0 1-5.77-8.14 107.31 107.31 0 0 1-3.57-6c-3.07-5.55-5.41-10.9-7.19-15.73-2.31-6.22-3.68-11.57-4.52-15.35h143.85Z"
            />
            <path
                fill="#EF0E2E"
                d="M280.887 174.375c-1.78 4.83-4.12 10.18-7.19 15.73h-151.4c-3.07-5.55-5.41-10.9-7.19-15.73h165.78Z"
            />
            <path
                fill="#64CCC9"
                d="M270.117 196.102c-1.73 2.71-3.65 5.43-5.77 8.14h-132.71a107.07 107.07 0 0 1-5.77-8.14h144.25Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M251.671 217.756c23.592-20.994 31.168-47.195 33.734-58.729H110.584c2.565 11.534 10.142 37.735 33.734 58.729 9.672 8.607 19.484 14.069 27.403 17.566h52.547a106.408 106.408 0 0 0 15.546-8.464"
            />
            <g clipPath="url(#object-meals-6-i)">
                <path
                    fill="url(#object-meals-6-j)"
                    d="M188.006 144.023c7.456 0 13.5-3.134 13.5-7s-6.044-7-13.5-7-13.5 3.134-13.5 7 6.044 7 13.5 7Z"
                />
            </g>
            <path
                fill="url(#object-meals-6-k)"
                d="M212.006 130.023c13.531 0 24.5-5.82 24.5-13 0-7.179-10.969-13-24.5-13s-24.5 5.821-24.5 13c0 7.18 10.969 13 24.5 13Z"
            />
            <path
                fill="url(#object-meals-6-l)"
                d="M269.073 120.737c-1.755-2.169-12.97-1.239-32.569 7.153-19.599 8.391-28.012 15.865-27.654 18.633.629 4.859 19.167 6.673 35.496-.319 16.329-6.991 27.81-21.658 24.727-25.467Z"
            />
            <path
                fill="url(#object-meals-6-m)"
                d="M526.223 161.293h-61.627l8.74 66.797a8.316 8.316 0 0 0 8.246 7.237h27.654a8.317 8.317 0 0 0 8.246-7.237l8.741-66.797Z"
            />
            <path
                fill="#004F71"
                d="M490.453 161.291c-7.493-12.976-31.251-54.334-31.797-55.067a6.892 6.892 0 0 0-11.219 8.008l33.588 47.059h9.428Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M450.135 161.293h90.553M520.052 208.453l6.171-47.16h-61.627l8.74 66.797a8.316 8.316 0 0 0 8.246 7.237h27.654a8.317 8.317 0 0 0 8.246-7.237l.874-6.677"
            />
            <defs>
                <linearGradient
                    id="object-meals-6-a"
                    x1={56.195}
                    x2={56.195}
                    y1={52.42}
                    y2={14.864}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2F0FA" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-b"
                    x1={424.678}
                    x2={367.078}
                    y1={179.224}
                    y2={179.224}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-c"
                    x1={392.474}
                    x2={292.937}
                    y1={214.197}
                    y2={174.786}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-d"
                    x1={364.985}
                    x2={307.89}
                    y1={229.644}
                    y2={156.886}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-e"
                    x1={352.086}
                    x2={294.992}
                    y1={239.768}
                    y2={167.01}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-f"
                    x1={368.473}
                    x2={311.378}
                    y1={226.911}
                    y2={154.154}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-g"
                    x1={289.408}
                    x2={158.969}
                    y1={314.815}
                    y2={114.413}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-h"
                    x1={141.557}
                    x2={285.407}
                    y1={197.173}
                    y2={197.173}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-j"
                    x1={198.739}
                    x2={172.701}
                    y1={145.665}
                    y2={124.208}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-k"
                    x1={232.551}
                    x2={192.179}
                    y1={136.782}
                    y2={98.83}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-l"
                    x1={247.446}
                    x2={190.455}
                    y1={140.301}
                    y2={98.375}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-meals-6-m"
                    x1={531.201}
                    x2={485.673}
                    y1={254.402}
                    y2={168.702}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <clipPath id="object-meals-6-i">
                    <path fill="#fff" d="M174.506 130.023h27v14h-27z" />
                </clipPath>
            </defs>
        </svg>
    );
}
