import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6504)"
            d="M417.403 347.979a22.638 22.638 0 01-14.1-4.626 33.423 33.423 0 01-5.806-6.527 208.844 208.844 0 01-12.541-19.355c-3.637-6.275-7.135-12.82-10.695-20.012a165.74 165.74 0 01-8.819-20.786c-1.12-3.227-1.696-5.333-2.252-7.369a78.565 78.565 0 00-2.712-8.408 26.496 26.496 0 01.174-21.22 26.501 26.501 0 0115.686-14.291 27.378 27.378 0 019.82-1.823 27.674 27.674 0 0115.574 4.876 27.672 27.672 0 0110.116 12.807l.051.135c1.832 4.867 3.89 9.754 5.879 14.478.878 2.085 1.751 4.172 2.618 6.26a58.761 58.761 0 013.507 10.988 195.29 195.29 0 001.251 5.047c2.104 7.725 3.828 14.943 5.274 22.057a306.728 306.728 0 011.467 7.848l6.26-.439c4.185-.27 8.454-.408 12.692-.408 3.082 0 6.224.072 9.34.216 1.196.06 2.387.11 3.577.16 2.435.102 4.953.208 7.442.408l5.487.379c1.196.086 2.392.2 3.589.314.643.062 1.287.123 1.93.18 2.785.266 5.254.525 7.557.795l1.187.139-.346 1.144c-3.301 10.885-.234 23.736 3.419 28.158l1.226 1.453-1.614.342c-2.946.625-6.341 1.07-10.018 1.732-.645.111-1.285.226-1.923.342-1.18.213-2.36.425-3.541.609l-5.448.836c-2.452.405-4.951.72-7.368 1.023-1.183.149-2.367.297-3.55.457a199.854 199.854 0 01-21.972 1.639l-10.96.146-10.937.289c-.174.003-.348.007-.521.007z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6504"
                x1="367.158"
                x2="464.315"
                y1="220.787"
                y2="350.04"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
