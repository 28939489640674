import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={460}
            height={282}
            fill="none"
            id="object-food-turkey"
            {...attributes}
        >
            <g clipPath="url(#object-food-turkey-a)">
                <path
                    fill="url(#object-food-turkey-b)"
                    d="m373.961 60.165-16.928-33.347-133.916 62.958.018.016c-27.056 12.073-45.924 39.19-45.924 70.732 0 42.767 34.669 77.437 77.437 77.437 34.307 0 63.385-22.319 73.553-53.222l.017.015 45.744-124.59-.001.001Z"
                />
                <path
                    fill="url(#object-food-turkey-c)"
                    d="m177.317 60.376-80.88 22.9C40.469 99.122 1.836 150.213 1.836 208.381v31.785c0 22.184 17.984 40.168 40.168 40.168H314.48a141.213 141.213 0 0 0 86.539-29.624l57.575-44.65a37.04 37.04 0 0 0-28.713-13.642h-25.855l-50.151-70.156c-39.899-55.816-110.542-80.577-176.558-61.886Z"
                />
                <path
                    fill="url(#object-food-turkey-d)"
                    d="M180.246 242.633c0 20.82-16.88 37.7-37.7 37.7H42.006c-22.19 0-40.17-17.98-40.17-40.16v-7.21c0-18.01 6.87-35.35 19.2-48.48l66.95-71.26c6.85 17.8 5.4 37.74-3.95 54.37l-31.67 56.28 81.67-17.98.91-.2c2.46-.51 5-.77 7.6-.77 20.82 0 37.7 16.88 37.7 37.71Z"
                />
                <path
                    fill="url(#object-food-turkey-e)"
                    d="M402.628 24.644a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013 8.464 16.674 8.464 16.674 9.876-5.013c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364l.001-.002Z"
                />
                <path
                    fill="url(#object-food-turkey-f)"
                    d="m382.424 76.841-16.928-33.347-133.916 62.958.018.016c-27.056 12.073-45.924 39.19-45.924 70.732 0 42.767 34.669 77.437 77.437 77.437 34.307 0 63.385-22.319 73.553-53.222l.017.015 45.744-124.59-.001.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m277.586 64.17 79.45-37.35M383.836 55.153c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364 12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013M177.312 60.38c42.03-11.9 85.941-6.19 122.591 13.95M361.722 133.239l42.3 59.18h25.86c5.56 0 10.98 1.25 15.89 3.58s9.31 5.75 12.82 10.06l-57.57 44.65a141.352 141.352 0 0 1-36.46 20.45c-16 6.06-32.97 9.17-50.08 9.17H42.002c-22.18 0-40.17-17.98-40.17-40.16v-31.79c0-58.17 38.64-109.26 94.6-125.1l53.289-15.088"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m134.035 205.892.91-.2c2.46-.51 5-.77 7.6-.77 20.82 0 37.7 16.88 37.7 37.71 0 20.83-16.88 37.7-37.7 37.7"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m84.035 167.592-31.67 56.28 45.427-10.001 36.243-7.979.91-.2M1.836 232.963c0-18.01 6.87-35.35 19.2-48.48l66.95-71.26a63.956 63.956 0 0 1 4.2 25.913M300.453 245.055c16.98-9.367 30.067-24.922 36.222-43.625M231.576 106.451c-27.05 12.08-45.9 39.21-45.9 70.75 0 20.953 8.32 39.961 21.836 53.902"
                />
                <path
                    fill="url(#object-food-turkey-g)"
                    d="M411.091 41.316a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013 8.464 16.674 8.464 16.674 9.876-5.013c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364l.001-.002Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m382.423 76.84 9.876-5.013c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364 12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013M382.424 76.84 336.68 201.429M231.58 106.451l133.916-62.959"
                />
            </g>
            <defs>
                <linearGradient
                    id="object-food-turkey-b"
                    x1={371.298}
                    x2={234.877}
                    y1={73.69}
                    y2={175.248}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-food-turkey-c"
                    x1={1.836}
                    x2={458.594}
                    y1={167.276}
                    y2={167.276}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-food-turkey-d"
                    x1={110.756}
                    x2={33.451}
                    y1={259.551}
                    y2={171.13}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-food-turkey-e"
                    x1={309.254}
                    x2={397.548}
                    y1={82.925}
                    y2={26.462}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.166} stopColor="#FFD104" />
                    <stop offset={0.305} stopColor="#FFD310" />
                    <stop offset={0.446} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.732} stopColor="#FFE367" />
                    <stop offset={0.874} stopColor="#FFEB94" />
                    <stop offset={0.949} stopColor="#FFF0AF" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-food-turkey-f"
                    x1={379.761}
                    x2={243.34}
                    y1={90.366}
                    y2={191.924}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-food-turkey-g"
                    x1={317.717}
                    x2={406.011}
                    y1={99.598}
                    y2={43.134}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.166} stopColor="#FFD104" />
                    <stop offset={0.305} stopColor="#FFD310" />
                    <stop offset={0.446} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.732} stopColor="#FFE367" />
                    <stop offset={0.874} stopColor="#FFEB94" />
                    <stop offset={0.949} stopColor="#FFF0AF" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <clipPath id="object-food-turkey-a">
                    <path fill="#fff" d="M.832.111h458.76v281.221H.832z" />
                </clipPath>
            </defs>
        </svg>
    );
}
