import React, { useState } from 'react';
import Settings, { Option, Tag, OptionsWrapper } from 'components/Settings';
import Request from 'components/Controls/Request';
import PicWrapper from 'components/PicWrapper';
import {
    DEVICES,
    DOCTORS_OFFICE,
    FOOD_ITEMS,
    MEALS,
    KITCHEN,
    OUTDOOR,
    BACKGROUND,
    ACCESSORIES,
    TObjects,
    TObjectSettings,
    TDevices,
    TDoctorsOffice,
    TFoodItems,
    TMeals,
    TKitchen,
    TOutdoor,
    TBackground,
    TAccessories,
} from 'components/objects';
import {
    TCategoryOption,
} from 'components/scenes';

type ValueOf<T> = T[keyof T];

const DEFAULT_STATE: { [key in typeof SETTING_TYPES as string]: TObjects } = {
    devices: 'option2',
};

export const SETTING_TYPES = {
    devices: 'Devices',
    doctorsOffice: 'Doctor\'s office',
    foodItems: 'Food items',
    meals: 'Meals',
    kitchen: 'Kitchen',
    outdoor: 'Outdoor',
    background: 'Background',
    accessories: 'Accessories',
};
const styles = ['Detailed', 'Simple'];
const colorization = ['Outline', 'Colorful'];
const sections = {
    devices: DEVICES,
    doctorsOffice: DOCTORS_OFFICE,
    foodItems: FOOD_ITEMS,
    meals: MEALS,
    kitchen: KITCHEN,
    outdoor: OUTDOOR,
    background: BACKGROUND,
    accessories: ACCESSORIES,
};
const settingsOptions = Object.values(SETTING_TYPES).sort();
const findSection = (setting: ValueOf<typeof SETTING_TYPES>) => {
    return Object.keys(SETTING_TYPES).find(
        (key) => SETTING_TYPES[key as keyof typeof SETTING_TYPES] === setting
    ) as keyof typeof SETTING_TYPES;
};
const sortOptions: { [key in keyof typeof SETTING_TYPES]: TObjects[] } = {
    devices: (Object.keys(DEVICES || {})).sort() as TDevices[],
    doctorsOffice: (Object.keys(DOCTORS_OFFICE || {})).sort() as TDoctorsOffice[],
    foodItems: (Object.keys(FOOD_ITEMS || {})).sort() as TFoodItems[],
    meals: (Object.keys(MEALS || {})).sort() as TMeals[],
    kitchen: (Object.keys(KITCHEN || {})).sort() as TKitchen[],
    outdoor: (Object.keys(OUTDOOR || {})).sort() as TOutdoor[],
    background: (Object.keys(BACKGROUND || {})).sort() as TBackground[],
    accessories: (Object.keys(ACCESSORIES || {})).sort() as TAccessories[],
};

const byTag = settingsOptions.reduce((sectionMap, sectionItem) => {
    const availableSection = findSection(sectionItem);
    const sectionTagged = sortOptions[availableSection as keyof typeof sortOptions]?.reduce(
        (acc: Record<string, string[]>, item) => {
            const defineSection = sections[availableSection as keyof typeof sections];
            const defineOption = defineSection[item as keyof typeof defineSection] as TCategoryOption;
            const tag = defineOption?.tag;
            const untagged = acc.untagged || [];
            if (!tag) return { ...acc, untagged: [...untagged, item] };
            const tagGroup = acc[tag] || [];
            return { ...acc, [tag]: [...tagGroup, item] };
        }, {}
    );
    if (!sectionTagged) return sectionMap;
    return { ...sectionMap, [availableSection]: sectionTagged };
}, {});


export default function ObjectBuilder() {
    const [state, setState] = useState<TObjectSettings>(DEFAULT_STATE);
    const [detailed, setDetailed] = useState(false);
    const [outlined, setOutlined] = useState(true);
    const [variation, setVariation] = useState('');


    const section = Object.keys(state)[0] as keyof typeof SETTING_TYPES;
    const selectedSection = sections[section];
    const Image = selectedSection[state[section] as keyof typeof selectedSection]?.image;

    const sectionList = Object.keys(byTag);

    const option = Object.keys(state)[0];
    const settingType = SETTING_TYPES[option as keyof typeof SETTING_TYPES];
    const sectionName = findSection(settingType);
    const optionName = sections[sectionName];
    const optionParams = optionName[state[sectionName] as keyof typeof optionName];
    const { variations, colorful, outline } = optionParams as TCategoryOption;
    const detailsOptions = colorful || outline ? colorization : styles;
    const chooseStyle = {
        Colorful: colorful,
        Outline: outline,
    };

    function updateState({ type, value }: { type: keyof typeof SETTING_TYPES, value: TObjects }) {
        const selected = sections[type];
        const option = selected[value as keyof typeof selected] as TCategoryOption;
        const { variations, outline, colorful } = option;
        const detailVariation = (variations && variations.length > 0) ? variations[0] : '';
        const colorVariation = (outline && outline.length > 0)
            ? outline[0]
            : (colorful && colorful.length > 0)
                ? colorful[0]
                : '';
        setState({ [type]: value });
        setVariation(colorVariation || detailVariation);
        if (!variations) {
            setOutlined(!(!outline && colorful));
        }
    }

    function renderSetting(setting: ValueOf<typeof SETTING_TYPES>) {
        const tagMap = byTag[setting as keyof typeof byTag] || {};
        const tagOrder = Object.keys(tagMap)?.sort((a, b) => {
            if (b === 'untagged') return 1;
            if (a < b) return -1;
            if (a > b) return 1;
            return 0;
        });
        return (
            <OptionsWrapper title={SETTING_TYPES[setting as keyof typeof SETTING_TYPES]} key={setting}>
                <>
                    {tagOrder.map((tag) => {
                        const tagList = byTag[setting as keyof typeof byTag] || {};
                        const tagOption = tagList[tag as keyof typeof tagList] as TObjects[] || [];

                        return (
                            <React.Fragment key={`${tag}-wrapper`}>
                                {tag && tag !== 'untagged' && (
                                    <Tag text={tag} key={tag}/>
                                )}
                                {tagOption.map(
                                    (id: TObjects): JSX.Element => {
                                        const defineSection = sections[setting as keyof typeof sections];
                                        const defineOption = defineSection[id as keyof typeof defineSection]?.name;
                                        return (
                                            <Option
                                                key={id as string}
                                                onClick={() => updateState(
                                                    { type: setting as keyof typeof sections, value: id }
                                                )}
                                                active={state[setting] === id}
                                                text={defineOption ? defineOption : ''}
                                            />
                                        );
                                    }
                                )}
                            </React.Fragment>
                        );


                    })}
                </>
            </OptionsWrapper>
        );
    }

    function updateVariation(id: string, style: keyof typeof styles | keyof typeof colorization) {
        if ((style === styles[0] && !detailed) || (style === styles[1] && detailed)) {
            setDetailed(prev => !prev);
        }
        if (colorization.includes(style as string)) setOutlined(style === colorization[0]);
        setVariation(id);
    }

    function renderDetailItem(id: string, style: string): JSX.Element {
        return (
            <Option
                key={id}
                onClick={() => updateVariation(id, style as keyof typeof styles)}
                active={variation === id && (style === 'Detailed' ? detailed : !detailed)}
                text={id}
            />
        );
    }

    function switchToggle(title: string) {
        if (styles.includes(title)) setDetailed((prev) => !prev);
        else if (colorization.includes(title)) {
            const newVariation = outlined ? colorization[1] : colorization[0];
            const newStyle = chooseStyle[newVariation as keyof typeof chooseStyle];
            if (newStyle && newStyle.length > 0) setVariation(newStyle[0]);
            setOutlined((prev) => !prev);
        }
    }


    function renderDetail(style: string) {
        const hasColors = colorization.includes(style);
        const showExpand = hasColors
            ? !!chooseStyle[style as keyof typeof chooseStyle]
            : !!variations;
        const showToggle = hasColors
            ? !!outline && !!colorful
            : true;
        const toggle = hasColors
            ? (style === colorization[0] ? outlined : !outlined)
            : (style === styles[0] ? detailed : !detailed);
        const styleToRender = chooseStyle[style as keyof typeof chooseStyle] || variations || [];

        return !hasColors || styleToRender?.length > 0 ? (
            <OptionsWrapper
                title={style}
                showToggle={showToggle}
                toggle={toggle}
                showExpand={showExpand}
                key={style}
                switchToggle={switchToggle}
            >
                {styleToRender?.map(
                    (id: string) => renderDetailItem(id, style)
                )}
            </OptionsWrapper>
        ) : null;
    }

    return (
        <div className="layout symmetrical">
            <Settings>
                {sectionList.map((item) => renderSetting(item as keyof typeof sections))}
                <Request/>
            </Settings>

            <PicWrapper preset={true}>
                <Image
                    id="svg-target"
                    variation={variation ? variation : ''}
                    data-detailed={detailed.toString()}
                />
            </PicWrapper>

            <Settings>
                {detailsOptions.map((style: string) => renderDetail(style))}
            </Settings>
        </div>
    );
}
