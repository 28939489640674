import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#001489"
            d="M515.423 366.494c-27.686-3.551-53.134 10.4-61.854 37.402a54.095 54.095 0 003.803 41.412 54.116 54.116 0 0032.064 26.481 109.67 109.67 0 0014.101 3.36l40.053-.002s4.266-17.658 12.392-47.721c8.674-32.097 31.32-39.368 31.32-39.368-9-5.204-49.449-19.445-71.879-21.564z"
        ></path>
    </g>
);

export default SvgComponent;
