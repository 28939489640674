import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-5">
        <path
            d="m465.472 167.117 2.654.951c13.052 4.678 23.789 10.343 32.825 17.32a2.671 2.671 0 0 1 1.092 2.34c-.25 4.649-12.215 12.209-14.61 13.678l-.858.525-21.103-34.814Z"
            fill="#001489"
        />
        <path
            d="M489.472 295.209c-4.578-6.978-9.031-14.259-13.614-22.261a206.592 206.592 0 0 1-11.678-23.21 864.129 864.129 0 0 1-3.037-7.187c-2.29-5.45-4.657-11.087-7.174-16.533a32.032 32.032 0 0 1 15.616-42.475 31.754 31.754 0 0 1 13.409-2.961 32.115 32.115 0 0 1 29.067 18.579l.062.137c2.495 5.464 5.23 10.935 7.875 16.226 1.162 2.324 2.319 4.651 3.471 6.98a206.701 206.701 0 0 1 10.003 23.985c3.082 8.677 5.705 16.799 8.017 24.82 0 0-10.989 1.598-25.303 8.321-7.151 3.359-26.714 15.579-26.714 15.579Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
