import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6836)"
            d="M494.769 223.314a2.715 2.715 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6836)"
            d="M482.603 278.845c-5.01-2.651-10.19-5.391-15.366-7.904a27.533 27.533 0 01-12.731-36.747 27.679 27.679 0 0124.76-15.499 27.236 27.236 0 0111.988 2.767l.116.056c5.163 2.537 10.51 4.936 15.68 7.256-9.121 4.975-25.241 29.12-24.447 50.071z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6836"
                x1="485.051"
                x2="448.184"
                y1="215.178"
                y2="280.239"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6836"
                x1="490.271"
                x2="453.404"
                y1="218.14"
                y2="283.201"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
