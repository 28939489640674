import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={65}
            height={115}
            fill="none"
            id="object-food-pills"
            {...attributes}
        >
            <path fill="#fff" d="M59.612 1.02H5.64v10.667h53.973V1.02Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M59.612 1.02H5.64v10.667h53.973V1.02ZM63.591 35.883a8 8 0 0 0-2.469-5.78L54.84 24.09a4.998 4.998 0 0 1-1.543-3.611v-8.791H11.953v8.79a4.997 4.997 0 0 1-1.543 3.612l-6.283 6.014a8 8 0 0 0-2.469 5.78v69.305a8 8 0 0 0 8 8H55.59a8 8 0 0 0 8-8l.001-69.305Z"
            />
            <path
                fill="#001489"
                d="M13.016 105.914a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM26.088 105.914a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM39.162 105.914a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM52.236 105.914a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.553 94.356a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM32.625 94.356a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM45.7 94.356a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM13.016 82.797a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM26.088 82.797a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM39.162 82.797a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM52.236 82.797a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM19.553 71.238a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM32.625 71.238a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM45.7 71.238a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM13.016 59.68a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM26.088 59.68a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM39.162 59.68a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM52.236 59.68a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
            />
        </svg>
    );
}
