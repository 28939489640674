import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6705)"
            d="M548.29 313.894a20.375 20.375 0 01-6.495-1.059 206.685 206.685 0 01-21.19-8.751c-6.525-3.086-13.079-6.467-20.04-10.339a163.723 163.723 0 01-18.936-12.041 685.011 685.011 0 01-5.4-4.011c-4.086-3.05-8.311-6.203-12.573-9.135a27.333 27.333 0 01-7.497-7.68 27.328 27.328 0 01-3.959-9.976 27.305 27.305 0 014.384-20.586 27.518 27.518 0 0122.681-11.918 27.348 27.348 0 0115.561 4.846l.117.08c4.247 2.955 8.687 5.795 12.981 8.542a1397.8 1397.8 0 015.664 3.643 163.8 163.8 0 0117.95 13.475c6.074 5.144 11.55 10.085 16.734 15.098a206.542 206.542 0 0115.664 16.742 20.264 20.264 0 01-2.811 28.488 20.281 20.281 0 01-12.835 4.582z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6705)"
            d="M461.078 200.336l2.525 1.254c12.653 6.286 22.846 13.297 31.163 21.433a2.707 2.707 0 01.822 2.483c-.805 4.666-13.808 10.892-16.406 12.093l-.913.421-17.191-37.684z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6705"
                x1="530.865"
                x2="471.588"
                y1="248.41"
                y2="305.085"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6705"
                x1="488.371"
                x2="429.094"
                y1="203.958"
                y2="260.632"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
