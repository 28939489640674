import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M370.414 389.16c-12.574 21.71-8.774 55.309 4.166 70.903 18.134 21.853 83.735 15.063 103.724 15.48 31.521.657 19.94-41.189 19.94-41.189s24.818-11.723 33.534-45.194"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M370.414 389.16c-12.574 21.71-8.774 55.309 4.166 70.903 18.134 21.853 83.735 15.063 103.724 15.48 31.521.657 19.94-41.189 19.94-41.189s24.818-11.723 33.534-45.194"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M556.176 619.917a802.305 802.305 0 01-15.512-20.864c-2.52-3.508-5.119-6.979-7.575-10.517l-7.424-10.587c-4.975-7.045-9.825-14.149-15.021-21.092-5.13-6.974-10.589-13.795-15.393-20.92-9.852-14.137-16.419-29.801-22.447-45.715-2.93-7.996-5.542-16.14-7.713-24.488-.568-2.076-1.026-4.203-1.544-6.301-.448-2.132-.939-4.243-1.325-6.403a120.334 120.334 0 01-1.847-13.178l-.9-11.259a26.381 26.381 0 0120.267-27.782 26.38 26.38 0 0130.511 15.864l18.975 47.328 9.477 23.669 9.35 23.728c6.213 15.829 12.514 31.615 18.653 47.478l18.487 47.555a15.93 15.93 0 01-7.023 19.651 15.937 15.937 0 01-20.451-4.15l-1.545-2.017z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M474.05 476.316c2.535-.379 3.077-.695 5.618-1.131a42.966 42.966 0 0029.609-20.376c11.529-19.412 5.554-44.521-12.965-57.438l-7.419-5.175a113.5 113.5 0 00-14.043-8.207c-2.354-1.247-4.733-2.273-7.1-3.398-2.374-1.067-4.76-2.026-7.14-3.044-2.391-.914-4.782-1.832-7.177-2.704l-3.605-1.205-1.803-.6-1.811-.528a85.854 85.854 0 00-14.597-3.227l-6.345-.744a57.055 57.055 0 00-9.497-.24 56.159 56.159 0 00-51.247 69.404 56.162 56.162 0 0016.393 27.865 43.303 43.303 0 0029.461 11.402h66.648l-2.98-.654z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M557.721 621.931l-1.546-2.016a804.017 804.017 0 01-15.511-20.865c-2.52-3.508-5.119-6.979-7.575-10.517l-7.424-10.586c-4.975-7.046-9.826-14.15-15.021-21.093-5.13-6.974-10.589-13.795-15.394-20.92-9.851-14.137-16.418-29.801-22.446-45.715a293.03 293.03 0 01-4.499-13.25 252.966 252.966 0 01-3.215-11.239c-.567-2.075-1.025-4.202-1.544-6.3M514.308 424.789L529.229 464l9.477 23.669 9.35 23.727c6.212 15.829 12.513 31.616 18.652 47.478l18.488 47.555M363.025 420.941a56.027 56.027 0 0018.936 45.569 42.861 42.861 0 0028.387 10.463h54.577M514.307 424.791a44.052 44.052 0 00-17.995-27.42l-7.419-5.175a113.375 113.375 0 00-14.043-8.207c-2.354-1.248-4.733-2.274-7.1-3.398-2.374-1.067-4.76-2.026-7.14-3.044-2.391-.914-4.782-1.832-7.177-2.705l-3.605-1.205-1.803-.599-1.811-.528a85.952 85.952 0 00-14.597-3.228l-6.345-.743"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M556.176 619.914c3.386 10.788 6.808 20.9 7.394 32.191.32 6.172.22 12.416 1.562 18.449 1.341 6.033 4.35 11.99 9.536 15.352 3.796 2.462 8.386 3.321 12.863 3.974a196.74 196.74 0 0024.144 2.014 7.078 7.078 0 005.501-2.423 7.084 7.084 0 001.658-5.777c-1.5-9.447-6.03-18.383-10.35-27.156-8.228-16.709-15.06-33.4-23.288-50.108"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M556.176 619.914c3.386 10.788 6.808 20.9 7.394 32.191.32 6.172.22 12.416 1.562 18.449 1.341 6.033 4.35 11.99 9.536 15.352 3.796 2.462 8.386 3.321 12.863 3.974a196.74 196.74 0 0024.144 2.014 7.078 7.078 0 005.501-2.423 7.084 7.084 0 001.658-5.777c-1.5-9.447-6.03-18.383-10.35-27.156-8.228-16.709-15.06-33.4-23.288-50.108M491.527 420.223h15.484"
        ></path>
    </g>
);

export default SvgComponent;
