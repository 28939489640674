import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M532.288 388.05c11-54 0-75-14-103 11-6 11.362-18.097 8.159-29.766-6.193-22.562-26.159-63.234-52.159-82.234a125.154 125.154 0 00-6.412-4.394c-13.871 2.647-23.825 1.817-56.018-13.777-18.105 7.718-33.664 15.108-49.942 28.194-12.192 9.801-18.468 24.223-18.093 39.861l.532 22.205a312.75 312.75 0 0010.857 74.513c6.979 25.68 15.21 59.479 16.034 69.856l-.821.007c-12.574 21.71-8.774 55.308 4.166 70.902 25.861-5.52 123.665-25.709 123.665-25.709s25.335-13.135 34.052-46.606l-.02-.052z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M532.288 388.051c11-54 0-75-14-103 11-6 11.362-18.097 8.159-29.766a160.675 160.675 0 00-8.627-23.125M518.449 285.245c-3.567-6.179-7.716-12.857-11.112-16.862M409.212 286.475c31.069 3.775 56.531-1.155 71.668-22.12M509.337 364.031a13.776 13.776 0 0110.286 0M406.868 156.945a190.769 190.769 0 00-41.283 23.265c-12.04 8.695-22.483 21.535-21.704 45.56l.474 19.37a312.699 312.699 0 0010.857 74.513c7.101 26.13 15.505 58.686 16.076 68.397M498.256 434.71s24.818-11.724 33.534-45.194M370.424 389.516c-12.574 21.71-8.773 55.308 4.167 70.902"
        ></path>
    </g>
);

export default SvgComponent;
