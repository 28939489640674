import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={97}
            height={134}
            fill="none"
            id="object-food-yogurt"
            {...attributes}
        >
            <path
                fill="url(#object-food-yogurt-a)"
                d="M80.928 58.156H19.3l8.74 66.797a8.315 8.315 0 0 0 8.246 7.237h27.654a8.317 8.317 0 0 0 8.246-7.237l8.74-66.797Z"
            />
            <path
                fill="#004F71"
                d="M45.158 58.157C37.665 45.18 13.908 3.822 13.36 3.09a6.892 6.892 0 0 0-11.219 8.007l33.588 47.06h9.428Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4.84 58.156h90.553M74.757 105.316l6.17-47.16H19.302l8.74 66.797a8.315 8.315 0 0 0 8.246 7.237h27.654a8.317 8.317 0 0 0 8.246-7.237l.874-6.677"
            />
            <defs>
                <linearGradient
                    id="object-food-yogurt-a"
                    x1={85.906}
                    x2={40.378}
                    y1={151.265}
                    y2={65.565}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
            </defs>
        </svg>
    );
}
