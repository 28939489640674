import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M401.894 226.743c6.015 4.141 12.263 7.942 18.41 11.89a156.344 156.344 0 0 1 17.59 13.089c5.615 4.725 11.113 9.622 16.447 14.758a196.483 196.483 0 0 1 15.35 16.362 19.26 19.26 0 0 1 2.1 21.306 19.248 19.248 0 0 1-4.769 5.812 19.473 19.473 0 0 1-18.23 3.415 196.653 196.653 0 0 1-20.808-8.387c-6.718-3.113-13.274-6.466-19.711-9.99a156.317 156.317 0 0 1-18.567-11.66c-5.905-4.302-11.71-8.75-17.748-12.859a26.489 26.489 0 0 1-11.125-16.959 26.506 26.506 0 0 1 31.425-30.896 26.504 26.504 0 0 1 9.519 4.038l.117.081Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m465.348 279.009 12.052 2.902 12.088 2.763a250.81 250.81 0 0 1 23.844 6.823c3.92 1.35 7.866 2.599 11.759 4.055l5.855 2.121c1.951.711 3.885 1.488 5.828 2.229 7.76 3.015 15.478 6.192 23.058 9.91a12.262 12.262 0 0 1 5.607 16.407 12.272 12.272 0 0 1-11.538 6.849c-8.435-.366-16.732-1.273-24.988-2.342-2.06-.28-4.13-.524-6.183-.834l-6.156-.943c-4.115-.586-8.177-1.379-12.264-2.071a255.989 255.989 0 0 1-24.201-5.429l-11.934-3.363-11.97-3.225a18.49 18.49 0 0 1-11.223-8.635 18.506 18.506 0 0 1-1.83-14.042 18.53 18.53 0 0 1 22.196-13.175Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M448.795 313.38a196.653 196.653 0 0 1-20.808-8.387c-6.718-3.113-13.274-6.466-19.711-9.99a156.446 156.446 0 0 1-18.567-11.66c-5.905-4.302-11.71-8.75-17.748-12.859M401.777 226.664l.118.08c6.015 4.141 12.262 7.943 18.41 11.891a156.426 156.426 0 0 1 17.59 13.088c5.615 4.725 11.113 9.622 16.446 14.759 3.08 2.985 6.122 6.028 9.073 9.201M553.902 333.067c-8.435-.366-16.732-1.273-24.987-2.342-2.061-.28-4.13-.524-6.184-.834l-6.156-.943c-4.115-.586-8.177-1.379-12.264-2.071a255.989 255.989 0 0 1-24.201-5.429 279.77 279.77 0 0 1-29.044-7.495M465.352 279.008l12.052 2.901 12.088 2.763a250.815 250.815 0 0 1 23.844 6.824c3.92 1.35 7.866 2.599 11.759 4.055l5.855 2.121c1.951.711 3.885 1.488 5.828 2.229 7.76 3.015 15.478 6.191 23.058 9.91"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M616.409 343.206c-2.251-4.447-6.037-8.035-10.158-10.892-4.614-3.199-9.713-5.62-14.54-8.488a48.435 48.435 0 0 1-8.708-6.368l-.516-.513c2.093-.49 4.253.335 6.205 1.237a63.112 63.112 0 0 0 10.446 2.947 2.087 2.087 0 0 0 2.229-2.973 2.117 2.117 0 0 0-1.2-1.076c-3.8-1.431-6.762-4.873-10.139-7.334a24.745 24.745 0 0 0-26.605-1.216c-16.265 8.765-21.547 17.609-14.638 26.538a77.318 77.318 0 0 0 30.908 18.972c2.625.848 5.677 1.505 7.994.007l3.347-3.137a9.15 9.15 0 0 0 3.765-.014 4.816 4.816 0 0 0 3.473-2.889l.35-.776c2.085.577 4.247.83 6.409.75a5.364 5.364 0 0 0 2.113-.428 2.25 2.25 0 0 0 1.3-1.629l.654-2.693a27.296 27.296 0 0 1 3.687 2.699 2.323 2.323 0 0 0 3.064.038 2.295 2.295 0 0 0 .56-2.762Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M587.792 332.805c4.134 2.926 8.957 4.68 13.629 6.637 4.039 1.692 8.093 3.619 11.363 6.49a2.323 2.323 0 0 0 3.064.038 2.298 2.298 0 0 0 .56-2.762c-2.251-4.447-6.036-8.035-10.158-10.892-4.613-3.199-9.713-5.62-14.54-8.488-4.826-2.868-9.484-6.286-12.616-10.945M582.641 338.352a73.763 73.763 0 0 0 10.428 6.748 24.69 24.69 0 0 0 11.961 2.886 5.347 5.347 0 0 0 2.113-.429 2.24 2.24 0 0 0 1.3-1.629M580.047 345.656a23.699 23.699 0 0 0 10.089 5.075c1.52.418 3.117.476 4.664.17a4.81 4.81 0 0 0 3.473-2.889M548.785 335.07a77.318 77.318 0 0 0 30.908 18.972c2.625.848 5.677 1.505 7.994.007"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M582.486 316.945c2.093-.49 4.253.335 6.205 1.237a63.21 63.21 0 0 0 10.446 2.947 2.09 2.09 0 0 0 2.323-1.364 2.114 2.114 0 0 0-1.293-2.685c-3.8-1.43-6.763-4.873-10.139-7.334a24.742 24.742 0 0 0-26.606-1.216"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
