import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={2}>
        <g clipPath="url(#female-34-25-arm-left-option-2-a)">
            <path
                d="M500.612 230.565c4.834 6.574 9.999 12.903 15.022 19.337a195.038 195.038 0 0 1 13.853 20.199c4.266 6.994 8.363 14.112 12.227 21.402a244.829 244.829 0 0 1 10.664 22.557 19.111 19.111 0 0 1-18.151 26.239 19.115 19.115 0 0 1-11.604-4.246 244.657 244.657 0 0 1-18.435-16.813c-5.836-5.833-11.439-11.838-16.873-17.968a194.91 194.91 0 0 1-15.246-19.17c-4.678-6.689-9.212-13.484-14.078-20.034a26.498 26.498 0 0 1 25.141-42.018 26.496 26.496 0 0 1 17.405 10.413l.075.102Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M518.651 321.805c.669-5.793 1.005-11.623 1.485-17.437a126.383 126.383 0 0 1 2.673-17.304c.663-2.86 1.224-5.73 1.996-8.578l1.094-4.278c.368-1.425.805-2.843 1.204-4.265 1.61-5.76 3.56-11.419 5.839-16.948a12.528 12.528 0 0 1 6.827-6.72 12.52 12.52 0 0 1 9.58.075 12.647 12.647 0 0 1 7.442 9.332 151.166 151.166 0 0 1 1.923 17.821c.073 1.475.183 2.955.226 4.427l.115 4.414c.119 2.947.027 5.871.038 8.806a126.456 126.456 0 0 1-1.243 17.465c-.826 5.775-1.795 11.535-2.431 17.331a18.522 18.522 0 0 1-2.173 6.884 18.493 18.493 0 0 1-18.237 9.485 18.504 18.504 0 0 1-12.412-6.815 18.493 18.493 0 0 1-3.957-13.595l.011-.1Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M522.623 336.047a244.266 244.266 0 0 1-18.436-16.813c-5.836-5.833-11.438-11.838-16.873-17.968a194.91 194.91 0 0 1-15.246-19.17c-4.677-6.689-9.212-13.484-14.078-20.034M500.537 230.461l.075.102c4.835 6.573 9.999 12.902 15.022 19.336a187.27 187.27 0 0 1 10.165 14.275M556.791 255.68a151.378 151.378 0 0 1 1.923 17.821c.073 1.475.183 2.955.226 4.427l.115 4.414c.119 2.947.027 5.871.037 8.806a126.455 126.455 0 0 1-1.242 17.465c-.826 5.775-1.795 11.535-2.431 17.331a18.5 18.5 0 0 1-11.54 15.164 18.502 18.502 0 0 1-18.807-3.065M520.135 304.372a126.407 126.407 0 0 1 2.673-17.304c.663-2.859 1.224-5.73 1.995-8.577l1.094-4.278c.369-1.426.806-2.844 1.205-4.266A151.32 151.32 0 0 1 532.941 253"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m562.308 232.887-1.39-2.007a21.674 21.674 0 0 0-16.235-10.696l-.199-.209a19.131 19.131 0 0 0-4.486-5.436c-1.907-1.397-4.571-2.023-6.663-.922-1.611.848-2.589 2.518-3.483 4.104-1.454 2.58-3.097 5.114-4.585 7.686a1.09 1.09 0 0 0 .35 1.449 4.652 4.652 0 0 0 3.154.63 9.034 9.034 0 0 0 3.908-1.791c.362-.261.716-.535 1.06-.82l.178 1.707a12.193 12.193 0 0 1-2.571 6.933c-1.752-.263-2.915-1.873-4.23-3.059a10.463 10.463 0 0 0-6.348-2.626 2.545 2.545 0 0 0-2.521 1.7l-.017.045a2.527 2.527 0 0 0 1.469 3.236 12.105 12.105 0 0 1 5.612 4.543c1.779 2.626 2.815 5.673 4.22 8.518 1.405 2.844 3.329 5.625 6.194 6.988 6.978 4.408 13.288 6.104 18.432 3.055 4.381-3.366 6.831-9.332 6.761-14.856l-.038-.288a13.101 13.101 0 0 0 2.074-3.883 4.56 4.56 0 0 0-.646-4.001Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M538.628 219.323a20.717 20.717 0 0 1-5.767 6.372 9.042 9.042 0 0 1-3.907 1.791 4.311 4.311 0 0 1-3.934-1.326c1.595-2.832 3.419-5.608 5.015-8.44.893-1.586 1.871-3.256 3.482-4.103 2.092-1.1 4.757-.476 6.663.922a19.115 19.115 0 0 1 4.486 5.436M542.779 225.531a53.674 53.674 0 0 0-10.399 13.741 2.44 2.44 0 0 0 .658 3.067l.036.028a2.422 2.422 0 0 0 3.393-.423 51.721 51.721 0 0 1 12.677-11.378M551.138 227.742a76.32 76.32 0 0 1-9.09 13.667 2.462 2.462 0 0 0-.004 3.116l.029.037a2.484 2.484 0 0 0 3.408.437 66.217 66.217 0 0 0 12.58-12.202M544.865 220.188a21.663 21.663 0 0 1 16.235 10.696M561.101 241.062c.07 5.526-2.38 11.49-6.761 14.857"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M534.1 226.586a12.176 12.176 0 0 1-2.571 6.932c-1.751-.262-2.915-1.872-4.23-3.058a10.454 10.454 0 0 0-6.348-2.626 2.543 2.543 0 0 0-2.521 1.7l-.016.045a2.527 2.527 0 0 0 1.469 3.235 12.122 12.122 0 0 1 5.612 4.543c1.779 2.627 2.815 5.674 4.22 8.518 1.405 2.845 3.328 5.626 6.194 6.988M531.34 233.383l1.7.438M558.108 233.172a40.314 40.314 0 0 1-6.498 10.687 1.694 1.694 0 0 0-.108 2.065l.04.058c.226.325.561.558.944.657.383.1.789.06 1.144-.113 3.853-1.881 9.212-5.555 10.029-11.948"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M494.069 224.119c-8.492-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.958 15.305-12.953Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M463.16 202.867a132.19 132.19 0 0 1 30.909 21.252"
                stroke="#111011"
                strokeWidth={2.038}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="female-34-25-arm-left-option-2-a">
                <path
                    fill="#fff"
                    transform="translate(.385 .922)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
