import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={107}
            height={167}
            fill="none"
            id="object-office-tissue"
            {...attributes}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1.695 36.332v62.17c0 7.74 6.27 14 14 14h6.89M88.945 112.504h2.58c7.73 0 14-6.26 14-14M5.965 5.795c2.51-2.44 5.94-3.94 9.73-3.94h75.83c7.73 0 14 6.27 14 14v58.971"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m95.61 103.088-2.484-3.898a11.931 11.931 0 0 0-10.064-5.522H24.156a11.933 11.933 0 0 0-10.064 5.522l-2.483 3.898M5.965 5.793l13.59 14.48a13.68 13.68 0 0 0 9.98 4.32h48.16c3.77 0 7.38-1.56 9.97-4.32l5.9-6.28M19.223 74.824h86.305"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M29.045 93.668c-2.905 21.962-15.537 23.225-13.263 45.204.914 8.838-1.856 21.726-1.856 27.032 8.93-6.821 39.75-26.779 56.93-21.979 17.179 4.8 24.253 2.274 24.253 2.274 0-11.621 1.516-21.979-3.537-29.811-5.053-7.832-13.39-17.651-13.39-22.72H29.046v0Z"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M46.158 93.668c0 16.188 20.841 19.725 8.463 32.104l-12.379 12.379M64.727 101.137c4.674 4.674 9.347 19.958 9.347 19.958"
            />
        </svg>
    );
}
