import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6579)"
            d="M553.355 305.226l-.23-.102c-5.817-2.579-11.304-4.752-15.437-6.359-.694-.265-1.387-.534-2.08-.803l-.116-.045c-1.214-.471-2.428-.942-3.651-1.388l-5.843-2.116c-2.628-.983-5.314-1.884-7.948-2.767a435.558 435.558 0 01-3.847-1.301 255.576 255.576 0 00-23.968-6.858l-12.074-2.761-10.313-2.482c-4.502-5.109-9.1-9.653-12.17-12.63-5.12-4.93-10.518-9.774-16.502-14.808a158.542 158.542 0 00-17.728-13.19c-1.856-1.192-3.723-2.371-5.598-3.556-4.228-2.672-8.601-5.437-12.783-8.315l-.119-.081a27.74 27.74 0 00-20.83-4.389 27.775 27.775 0 00-10.067 4.054 27.756 27.756 0 00-.328 46.218c4.204 2.861 8.368 5.941 12.394 8.918 1.771 1.31 3.542 2.62 5.318 3.915a158.197 158.197 0 0018.706 11.748c6.873 3.764 13.346 7.045 19.789 10.031a201.113 201.113 0 0020.95 8.441c1.8.576 21.05 6.248 30.791 8.394l2.254.498c7.451 1.646 15.156 3.347 22.645 4.651 1.313.223 2.622.455 3.932.687l.044-.246.025.259c2.708.481 5.508.979 8.282 1.374l6.16.943c1.292.195 2.59.364 3.89.533l.17.022c.713.092 1.428.185 2.149.283 4.152.537 9.694 1.22 15.74 1.738l.291.024-.02-.29c-.6-8.852 5.215-21.779 7.697-27.299l.425-.945z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6579"
                x1="363.637"
                x2="526.938"
                y1="238.613"
                y2="325.143"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
