import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6495)"
            d="M392.29 257.658c.021-.017.032-.042.054-.058l4.025-2.86a4.353 4.353 0 014.841-.029l2.352 1.466c.087-1.649.24-3.309.482-4.945 3.416-23.09 13.157-30.795 13.157-30.795-8.895-1.027-17.796-1.716-26.577-2.148-31.634-3.417-41.865 42.951-12.351 53.509 0 0 2.175.624 5.585 1.451l7.224-14.177a4.038 4.038 0 011.208-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6495"
                x1="359.063"
                x2="404.46"
                y1="277.174"
                y2="216.451"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
