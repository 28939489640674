import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={940}
            viewBox="0 0 2144 940"
            fill="none"
            id="scene-office-1"
            {...attributes}
        >
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M94.285 141.045v-28.233h61.598v61.599H94.285v-18.083M174.723 235.506v-6.24"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M94.283 141.049h-40.92v66.69h40.92v-14.48h23.92v110.94c0 5.21 4.22 9.42 9.42 9.42 2.6 0 4.95-1.05 6.66-2.76a9.361 9.361 0 0 0 2.76-6.66v-170.73h37.68v87.251M181.124 243.458l1.85 5.21c1.66 4.67 1.6 9.84-.48 14.34-1.8 3.86-4.61 6.34-7.77 6.34-3.16 0-5.97-2.48-7.76-6.34-2.09-4.5-2.15-9.67-.49-14.34l3.43-9.63c.6-1.68 1.97-3.03 3.72-3.41.37-.08.73-.12 1.1-.12M181.122 243.453H175M174.723 229.268h6.4v-8.549h-6.4M125.09 121.133v6.347M109.195 127.719l4.488 4.488M102.609 143.609h6.347M109.195 159.511l4.488-4.488M125.09 166.089v-6.347M393.862 249.12h123.284v-28.221H382.59v-18.841h134.556v-28.222H382.59"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M401.43 190.171v-35.093h-75.285v94.042h67.716M429.691 145.654l2-16.842h14.841l2 16.842a9.42 9.42 0 0 1-9.42 9.42M485.594 155.076h9.42v-15.463l12.127-8.001v-2.8h-35.598l4.63 10.233v10.077M420.27 249.12v39.59a9.42 9.42 0 0 0 18.84 0V155.078M485.594 155.078V288.71a9.42 9.42 0 0 1-18.84 0v-39.59"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M358.514 222.625h-16.295v10.421h16.295v-10.421ZM768.069 216.495v-56.683H659.145v56.683h34.694v-37.841h55.389v18.84h-70.042v18.842M720.562 247.844h-12.71M749.233 216.5v31.35h-12.71"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M679.184 216.336v31.51h12.71"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m707.851 247.844-5.26 17.46h-5.43l-5.27-17.46M736.523 247.844l-5.27 17.46h-5.43l-5.261-17.46"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M576.125 525.234H1731.12l55.5 40.5H784.729v19.5H1795.62v28.5h-168v105M1647.13 639.234v40.5M1611.13 639.234v40.5M1005.84 393.439h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1266.54 47.086v373.95H930.239V56.986h305.551v336.45h-178.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1056.84 393.733 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.752-26.55-27.752-26.55s-4.351 23.25 5.849 44.4c2.7 5.85 6.303 10.8 10.053 15.15h-13.503l13.503 40.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1644.05 56.133v31.913l-15.12 15.114M1644.05 13.43v14.489M1719.11 88.047h-14.49M1644.05 163.223v-14.489M1568.99 88.047h14.49"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1706.32 113.793c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <g
                stroke="#FFD101"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-1-a)"
            >
                <path 
                    d="M1840.24 422.325v-18.841h-18.85v18.841h18.85ZM1919.76 422.325v-18.841h-18.84v18.841h18.84ZM1819.65 894.086h-40.81c-10.4 0-18.84 8.435-18.84 18.841h176.34c0-10.406-8.43-18.841-18.84-18.841h-53.23M1821.39 412.898h-44.53M1760 402.898v-11.96c0-5.531 4.48-10 10-10h26.67M1900.93 412.898h-18.84" 
                />
                <path 
                    d="M1814.09 380.938h112.26c5.52 0 10 4.469 10 10v11.96c0 5.53-4.48 10-10 10h-6.58M1776.86 412.898v18.843M1760 431.744v-40.806c0-5.523 4.48-10 10-10h6.86M1796.68 380.94v-5.768h17.4v5.768" 
                />
                <path 
                    d="M1840.23 412.901h19.15v-7.917a7.485 7.485 0 0 1 7.49-7.484h7.73c4.13 0 7.48 3.351 7.48 7.484v19.607h-12.79v-11.69h12.79M1864.27 894.086l-32.19-.02 7.42-424.66M1863.61 856.206l-6.76-386.8" 
                />
                <path 
                    d="M1760 431.742v.84c0 11.2 7.77 20.9 18.71 23.32l60.79 13.51M1776.86 431.742h158.62c0 11.05-7.58 20.66-18.32 23.23l-60.31 14.44"
                />
            </g>
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 939H2142.05"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m965.13 720.93-51.54 193h14l60-176h79.5l6.5 176h14l6.5-176h110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1054.07 720.93-22.48 193h-14l8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1196.88 708.244c-5.46-6.953-13.89-6.311-38.74-6.311-18.6 0-132.66.36-197.914.648-18.81.083-33.972-15.38-33.525-34.185l1.863-78.347c.637-26.787 21.22-48.985 47.912-51.334 10.424-.917 22.004-1.346 34.114-.782a240.3 240.3 0 0 1 40.63 5.39 52.837 52.837 0 0 1 39.3 36.775l46.07 157.835 54 176h14l-37.73-161.942M1025.81 720.93h-60.681M1204.59 720.93h-150.52"
            />
            <defs>
                <clipPath id="scene-office-1-a">
                    <path fill="#fff" d="M1759 374.172h178.344v539.755H1759z" />
                </clipPath>
            </defs>
        </svg>
    );
}
