import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-5">
        <path
            d="m465.022 167.117 2.655.951c13.052 4.678 23.789 10.343 32.825 17.32a2.671 2.671 0 0 1 1.092 2.341c-.25 4.648-12.215 12.208-14.611 13.677l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-5-a)"
        />
        <path
            d="M489.022 295.209c-4.578-6.978-9.03-14.259-13.613-22.261a206.768 206.768 0 0 1-11.679-23.21 899.408 899.408 0 0 1-3.037-7.187c-2.289-5.45-4.656-11.087-7.174-16.533a32.037 32.037 0 0 1 15.617-42.475 31.74 31.74 0 0 1 13.409-2.961 32.115 32.115 0 0 1 29.066 18.579l.063.137c2.494 5.464 5.23 10.935 7.875 16.225 1.162 2.325 2.319 4.651 3.47 6.98a206.382 206.382 0 0 1 10.003 23.987c3.083 8.677 5.706 16.797 8.018 24.819 0 0-10.99 1.598-25.303 8.321-7.151 3.359-26.715 15.579-26.715 15.579Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-5-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-5-a"
                x1={480.755}
                y1={161.926}
                x2={511.117}
                y2={343.662}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-5-b"
                x1={482.171}
                y1={161.691}
                x2={512.532}
                y2={343.425}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
