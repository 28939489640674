import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9283)"
            d="M479.234 236.312c-.181-4.781-.96-19.424-3.838-27.14a101.144 101.144 0 0118.685 14.399 1.706 1.706 0 01.536 1.598c-.512 2.966-8.018 7.688-15.383 11.143z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9283)"
            d="M462.893 271.197c-2.107-4.065-4.284-8.265-6.6-12.297a26.5 26.5 0 1145.961-26.393l.076.134c2.294 4.047 4.8 8.06 7.225 11.94l.21.337a143.438 143.438 0 00-23.315 10.015c-8.898 4.911-19.627 14.546-22.885 17.553l-.672-1.289z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M509.555 244.578l.21.337a143.43 143.43 0 00-23.315 10.016c-8.898 4.911-19.627 14.546-22.885 17.552l-.672-1.289"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M502.254 232.508l.077.135c1.906 3.364 3.932 6.66 5.978 9.944l1.455 2.333M465.682 276.543c-3.114-5.891-6.073-11.87-9.389-17.645"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.038"
            d="M484.744 215.758a139.07 139.07 0 019.313 7.851"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9283"
                x1="491.815"
                x2="453.357"
                y1="199.778"
                y2="298.381"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9283"
                x1="497.23"
                x2="458.772"
                y1="201.893"
                y2="300.495"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
