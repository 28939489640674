import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 66,
        height: 66,
        content: (
            <>
                <path
                    fill="url(#object-food-onion-a)"
                    d="M34.517 1.248h-3.025C30.247 8.167 25.514 13.925 19 16.573 8.848 20.701 1.885 29.177 1.885 38.967c0 13.85 13.933 25.078 31.12 25.078s31.12-11.228 31.12-25.078c0-9.79-6.963-18.266-17.116-22.394-6.513-2.648-11.246-8.406-12.491-15.325h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.878 25.343c-3.158 3.922-4.993 8.601-4.993 13.626 0 13.85 13.933 25.078 31.12 25.078s31.12-11.228 31.12-25.078c0-9.79-6.963-18.266-17.116-22.394-6.513-2.648-11.246-8.406-12.491-15.325h-3.025c-1.245 6.919-5.978 12.677-12.491 15.325"
                />
                <defs>
                    <linearGradient
                        id="object-food-onion-a"
                        x1={33.004}
                        x2={33.004}
                        y1={60.932}
                        y2={20.76}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Option #2': {
        width: 65,
        height: 66,
        content: (
            <>
                <path
                    fill="url(#object-food-onion-a)"
                    d="M33.757 1.248h-3.025c-1.245 6.919-5.978 12.677-12.491 15.325C8.088 20.701 1.125 29.177 1.125 38.967c0 13.85 13.933 25.078 31.12 25.078s31.12-11.228 31.12-25.078c0-9.79-6.963-18.266-17.116-22.394-6.513-2.648-11.247-8.406-12.492-15.325Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6.118 25.343c-3.158 3.922-4.993 8.601-4.993 13.626 0 13.85 13.933 25.078 31.12 25.078s31.12-11.228 31.12-25.078c0-9.79-6.963-18.266-17.116-22.394-6.513-2.648-11.246-8.406-12.491-15.325h-3.025c-1.245 6.919-5.978 12.677-12.491 15.325"
                />
                <path
                    fill="url(#object-food-onion-b)"
                    d="M32.963 1.248h-1.437c0 15.663-14.061 19.453-14.061 37.719 0 13.85 6.617 25.078 14.779 25.078 8.162 0 14.779-11.228 14.779-25.078 0-18.267-14.06-22.056-14.06-37.719Z"
                />
                <path
                    fill="url(#object-food-onion-c)"
                    d="M31.526 1.248c0 15.663-14.061 19.453-14.061 37.719 0 13.85 6.617 25.078 14.779 25.078V1.248h-.718Z"
                />
                <defs>
                    <linearGradient
                        id="object-food-onion-a"
                        x1={76.968}
                        x2={15.579}
                        y1={78.544}
                        y2={23.218}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-onion-b"
                        x1={-2.619}
                        x2={37.93}
                        y1={32.647}
                        y2={32.647}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.031} stopColor="#FFD100" />
                        <stop offset={0.165} stopColor="#FFD104" />
                        <stop offset={0.304} stopColor="#FFD310" />
                        <stop offset={0.445} stopColor="#FFD725" />
                        <stop offset={0.588} stopColor="#FFDC42" />
                        <stop offset={0.731} stopColor="#FFE367" />
                        <stop offset={0.873} stopColor="#FFEB94" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-onion-c"
                        x1={21.444}
                        x2={92.497}
                        y1={0.248}
                        y2={0.248}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.031} stopColor="#FFD100" />
                        <stop offset={0.165} stopColor="#FFD104" />
                        <stop offset={0.304} stopColor="#FFD310" />
                        <stop offset={0.445} stopColor="#FFD725" />
                        <stop offset={0.588} stopColor="#FFDC42" />
                        <stop offset={0.731} stopColor="#FFE367" />
                        <stop offset={0.873} stopColor="#FFEB94" />
                        <stop offset={1} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-onion"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
