import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-1-stand-2">
            <path
                d="M355.482 775.969c-1.715.856-3.548 1.757-5.45 2.693l-3.81 1.877a9.007 9.007 0 0 0-4.665 5.49 9.029 9.029 0 0 0 .796 7.168l12.413 21.793c.153 2.281 1.782 18.512 17.46 36.185 3.503 3.948 7.08 6.114 9.895 6.114a4.513 4.513 0 0 0 2.263-.572c2.559-1.457 2.377-6.872-.54-16.095a46.732 46.732 0 0 1-1.84-13.669c-14.691-13.715-22.986-34.046-26.522-50.984Z"
                fill="url(#female-34-25-shoes-1-stand-2-a)"
            />
            <path
                d="M455.029 845.23a49.47 49.47 0 0 1-5.924-3.13c-3.667-2.47-8.118-8.232-11.673-13.412-10.535 0-18.125 13.311-37.968 13.311-.096 1.798-.202 3.687-.317 5.667a9.006 9.006 0 0 0 2.462 6.769 9.004 9.004 0 0 0 6.622 2.856h43.74c7.341 0 11.551-1.974 11.551-5.417 0-2.601-3.936-4.475-8.493-6.644Z"
                fill="url(#female-34-25-shoes-1-stand-2-b)"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-1-stand-2-a"
                    x1={341.153}
                    y1={816.629}
                    x2={386.176}
                    y2={816.629}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-1-stand-2-b"
                    x1={399.13}
                    y1={842.99}
                    x2={463.522}
                    y2={842.99}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
