import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            className="pic-svg"
            width={574}
            height={210}
            viewBox="0 0 574 210"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            <g id="object-meals-1">
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2" 
                    d="M92.48 177.908v15.956c0 8.003-6.49 14.491-14.493 14.491H16.36c-8.003 0-14.492-6.488-14.492-14.491V41.547H92.48v118.286"
                />
                <path
                    fill="#C0F0FF"
                    d="M79.8 77.46v116.407c0 1-.812 1.811-1.812 1.811H16.36c-1 0-1.812-.811-1.812-1.811V77.461H79.8Z"
                />
                <path
                    fill="#E4002B" 
                    d="M59.065 95.016c7.874 0 14.258-6.384 14.258-14.258 0-7.874-6.384-14.258-14.258-14.258-7.875 0-14.258 6.383-14.258 14.258 0 7.874 6.383 14.258 14.258 14.258Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m68.265 73.226 2.937-2.937"
                />
                <circle
                    cx="115.115"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="137.961"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="160.807"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="183.652"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="206.498"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="229.344"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="252.189"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="275.035"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="297.881"
                    cy="115.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="160.807"
                    cy="74.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="183.652"
                    cy="74.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="206.498"
                    cy="74.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="229.344"
                    cy="74.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="252.189"
                    cy="74.969"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="126.865"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="149.365"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="171.865"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="194.365"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="216.865"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="239.365"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="261.865"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="284.365"
                    cy="95.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="194.365"
                    cy="55.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <circle
                    cx="216.865"
                    cy="55.469"
                    r="8.75"
                    fill="#E4002B"
                />
                <path
                    fill="#009CDE"
                    d="M245.477 55.47c7.874 0 14.258-6.384 14.258-14.259 0-7.874-6.384-14.258-14.258-14.258-7.875 0-14.258 6.384-14.258 14.258 0 7.875 6.383 14.258 14.258 14.258Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m241.94 30.617 7.074 7.074M249.014 30.617l-7.074 7.074"
                />
                <path
                    fill="#009CDE"
                    d="M202.196 29.461c7.874 0 14.258-6.383 14.258-14.258 0-7.874-6.384-14.258-14.258-14.258-7.875 0-14.258 6.384-14.258 14.258 0 7.875 6.383 14.258 14.258 14.258Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="m198.658 4.61 7.074 7.073M205.732 4.61l-7.074 7.073"
                />
                <path
                    fill="#fff"
                    d="M329.08 115.969c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.669-9.368 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.892 37.664h-87.096c-15.12-7.029-32.879-20.789-47.892-37.664a159.187 159.187 0 0 1-4.905-5.728 170.406 170.406 0 0 1-8.939-11.91c-3.521-5.156-6.683-10.454-9.368-15.825-3.532-7.005-6.265-14.13-7.936-21.207H329.08Z"
                />
                <path
                    fill="url(#object-meals-1-a)"
                    d="M329.08 115.969c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335H329.08Z"
                />
                <path
                    fill="#00B140"
                    d="M321.156 137.172c-2.697 5.37-5.86 10.669-9.368 15.825H101.222c-3.521-5.156-6.683-10.454-9.368-15.825h229.302ZM302.849 164.914H110.16a158.323 158.323 0 0 0 4.905 5.728h182.879l4.905-5.728Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M285.678 183.193c19.87-18.515 37.715-43.21 43.404-67.224H83.919c9.088 38.365 49.204 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
                />
                <path
                    fill="#fff"
                    d="M569.003 96.086h-53.974v10.667h53.974V96.086Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M569.003 96.086h-53.974v10.667h53.974V96.086Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M572.982 130.946a8 8 0 0 0-2.469-5.78l-6.283-6.013a4.998 4.998 0 0 1-1.543-3.612v-8.791h-41.343v8.791a4.998 4.998 0 0 1-1.543 3.612l-6.283 6.013a8 8 0 0 0-2.469 5.78v69.305a8 8 0 0 0 8 8h45.932a8 8 0 0 0 8-8l.001-69.305Z"
                />
                <path 
                    fill="#001489"
                    d="M522.406 200.984a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM535.479 200.984a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM548.553 200.984a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM561.627 200.984a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM528.943 189.422a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM542.016 189.422a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM555.09 189.422a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM522.406 177.867a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM535.479 177.867a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM548.553 177.867a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM561.627 177.867a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM528.943 166.312a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM542.016 166.312a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM555.09 166.312a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM522.406 154.75a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM535.479 154.75a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM548.553 154.75a4 4 0 1 0 0-8 4 4 0 0 0 0 8ZM561.627 154.75a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"
                />
                <g clipPath="url(#object-meals-1-b)">
                    <path
                        fill="url(#object-meals-1-c)"
                        d="M411.666 191.891H175.902a56.544 56.544 0 0 0 40.475 17.06h297.298a56.544 56.544 0 0 0 40.475-17.06H411.666Z"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M494.245 208.951h19.431a56.544 56.544 0 0 0 40.475-17.06H175.902a56.544 56.544 0 0 0 40.475 17.06h245.784"
                    />
                </g>
                <path
                    fill="url(#object-meals-1-d)"
                    d="M301.966 123.289c-34.517 0-64.103 17.471-76.554 42.311-6.044 12.059 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M248.279 139.633c-9.909 6.985-17.804 15.867-22.867 25.967-6.044 12.059 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496-8.359 0-16.428 1.025-24.035 2.93M252.779 178.738l36.652-36.652M288.51 178.738l36.653-36.652M324.24 178.738l36.653-36.652M429.683 125.604a22.19 22.19 0 0 0 14.666 6.496 22.184 22.184 0 0 0-6.495-14.667 22.184 22.184 0 0 0-14.666-6.495 22.177 22.177 0 0 0 6.495 14.666Z"
                />
                <path
                    fill="#EF0025"
                    d="M448.711 137.497a13.516 13.516 0 0 1-8.727 0c-12.291-4.215-28.947 2.986-28.947 21.308 0 19.746 14.74 32.251 33.31 32.251s33.31-12.505 33.31-32.251c0-18.322-16.655-25.523-28.946-21.308Z"
                />
                <defs>
                    <linearGradient
                        id="object-meals-1-a"
                        x1="130.949"
                        x2="329.08"
                        y1="162.136"
                        y2="162.136"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff" />
                        <stop
                            offset=".994"
                            stopColor="#D9D9D6"
                        />
                    </linearGradient>
                    <linearGradient
                        id="object-meals-1-c"
                        x1="175.902"
                        x2="554.15"
                        y1="200.421"
                        y2="200.421"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#fff" />
                        <stop
                            offset=".994" 
                            stopColor="#D9D9D6"
                        />
                    </linearGradient>
                    <linearGradient
                        id="object-meals-1-d" 
                        x1="287.169" 
                        x2="382.9" 
                        y1="113.427" 
                        y2="234.597"
                        gradientUnits="userSpaceOnUse">
                        <stop stopColor="#F64E2E" />
                        <stop
                            offset=".37"
                            stopColor="#F76430"
                        />
                        <stop
                            offset="1"
                            stopColor="#FB8735"
                        />
                    </linearGradient>
                    <clipPath 
                        id="object-meals-1-b">
                        <path 
                            fill="#fff" 
                            d="M174.902 190.891H555.15v19.06H174.902z" 
                        />
                    </clipPath>
                </defs>
            </g>   
        </svg>
    );
}
