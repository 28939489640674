import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#2B2729"
                d="M611.337 693.334a198.551 198.551 0 01-24.266-2.025c-4.507-.657-9.243-1.517-13.263-4.124-4.836-3.135-8.376-8.808-9.969-15.975a79.801 79.801 0 01-1.411-14.44 274.412 274.412 0 00-.174-4.173c-.536-10.354-3.491-19.716-6.619-29.628l-.683-2.165-4.217-5.988 30.324-19.843 4.745 11.515c4.096 8.312 7.928 16.813 11.634 25.033 3.698 8.2 7.52 16.679 11.626 25.016l.493.999c4.17 8.457 8.481 17.203 9.948 26.44a8.085 8.085 0 01-7.987 9.36l-.181-.002z"
            ></path>
            <path
                fill="url(#paint0_linear_10894_7482)"
                d="M574.534 689.883c-4.73-4.001-9.199-12.035-10.792-19.202a79.882 79.882 0 01-1.411-14.44c-.051-1.392-.101-2.784-.174-4.173a75.907 75.907 0 00-1.743-12.427c8.41-5.42 8.414-9.316 16.795-11.926 8.819-2.747 12.723 1.88 20.133 3.278 3.697 8.2 7.52 16.679 11.626 25.016l.492.999c4.17 8.457 8.481 17.203 9.948 26.44a61.51 61.51 0 01.74 11.062c-.433 11.349-13.734 22.337-45.614-4.627z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10894_7482"
                    x1="560.414"
                    x2="620.178"
                    y1="666.76"
                    y2="666.76"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#888B8D"></stop>
                    <stop offset="1" stopColor="#ABADAD"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
