import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6387)"
            d="M485.675 477.881h-76.116a46.73 46.73 0 01-30.675-11.431 27.894 27.894 0 01-6.18-5.406c-12.785-15.408-20.706-52.824-4.262-73.043 11.33-13.932 32.395-19.676 49.324-19.981 6.508-.206 14.864-.476 21.372.013a291.617 291.617 0 0137.908 5.247 166.566 166.566 0 0141.6 13.245s-25.68 2.077-35.005 34.27c-6.072 20.964 2.034 57.086 2.034 57.086z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6387"
                x1="312.828"
                x2="523.618"
                y1="530.708"
                y2="349.533"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#001489"></stop>
                <stop offset="0.672" stopColor="#00359C"></stop>
                <stop offset="1" stopColor="#0047A7"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
