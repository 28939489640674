import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-kitchen-4"
            {...attributes}
        >
            <path fill="#fff" d="M.102 0h2570v1472H.102z" />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M374 742.004 2048.4 742l55.5 40.5-1153.002.004v19.5L2112.9 802v28.5h-168v105M1963 856v40.5M1927 856v40.5M1417.72 632.112h50.85V296.48H995.34"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1135.38 257v405h364.22V267.722h-330.92v364.386h193.81"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1362.49 632.428-14.62-43.863h12.51c-12.84-8.772-19.66-20.956-19.66-20.956s17.71-1.625 34.11 7.148c.82.487 1.47.812 2.28 1.299-3.9-6.01-7.47-12.996-10.07-20.631-9.91-30.054-.33-60.109-.33-60.109s25.83 18.52 35.74 48.412c.98 3.087 1.79 6.336 2.44 9.422 11.7-18.844 30.05-28.754 30.05-28.754s4.71 25.18-6.33 48.086c-2.93 6.336-6.83 11.697-10.89 16.408h14.62l-14.62 43.863M928.5 262h-192v133.5h-138l-93 58.5H810"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M601.5 262v112.5h117L834 454v36H492v-34.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#a)"
            >
                <path 
                    d="M2009.19 252.526v330.063H1830.9V226h356.59v356.589h-147.41M1978.31 557.575V509.07M2040.08 557.575V509.07" />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1666.21 290.297v31.913l15.12 15.114M1666.21 247.594v14.489M1591.16 322.211h14.48M1666.21 397.387v-14.489M1741.27 322.211h-14.49"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1603.94 347.957c-3.29-7.931-5.1-16.626-5.1-25.747 0-37.21 30.17-67.37 67.38-67.37 37.21 0 67.37 30.16 67.37 67.37 0 37.21-30.17 67.37-67.37 67.37-18.6 0-35.45-7.54-47.64-19.73l-13.32 13.32c-15.6-15.6-25.26-37.15-25.26-60.96 0-47.61 38.6-86.21 86.22-86.21s86.21 38.6 86.21 86.21c0 47.61-38.6 86.21-86.21 86.21-12.11 0-23.64-2.49-34.09-7"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#b)"
            >
                <path 
                    d="M824.706 952.571v183.139H486.898v18.84h356.649V782H486.898v334.87" />
                <path 
                    d="M824.709 952.569H505.742v164.301h356.649V875.516H505.742v58.212h318.967M824.709 840.211H505.742M698.031 904.625h-65.617" />
                <path
                    d="M561.406 917.625c7.18 0 13-5.82 13-13s-5.82-13-13-13c-7.179 0-13 5.82-13 13s5.821 13 13 13ZM769.039 917.625c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13ZM561.406 824.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13-7.179 0-13 5.821-13 13 0 7.18 5.821 13 13 13ZM630.617 824.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13ZM699.828 824.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13ZM769.039 824.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13Z" />
            </g>
            <path
                fill="url(#c)"
                d="M681.051 1174.76h14l51.031-151.53h-24.206l-40.825 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m733.05 981.758-11.175 41.472h24.206l13.969-41.472h-27Z"
            />
            <path
                fill="url(#d)"
                d="M785.051 1174.76h14l16.967-151.53h-24.259l-6.708 151.53Z"
            />
            <path
                fill="#E2E2E2"
                d="m793.55 982.758-1.792 40.472h24.259l4.533-40.472h-27Z"
            />
            <path
                fill="#AEAEAF"
                d="M862.031 981.758h-27l1.293 41.472h24.206l1.501-41.472Z"
            />
            <path
                fill="#3A383A"
                d="m836.324 1023.23 4.726 151.53h14l5.48-151.53h-24.206Z"
            />
            <path
                fill="#AEAEAF"
                d="m926.731 981.898-27.11.084 12.504 41.258h24.32l-9.714-41.342Z"
            />
            <path
                fill="#3A383A"
                d="m972.051 1174.76-35.606-151.53h-24.32l45.926 151.53h14Z"
            />
            <path
                fill="url(#e)"
                d="m921.055 962.704 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.691-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
            />
            <path
                fill="url(#f)"
                d="M734.445 963.576c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867l-27.243-100.619a52.824 52.824 0 0 0-39.303-36.786 240.32 240.32 0 0 0-40.622-5.389c-11.795-.549-23.087-.156-33.298.712-27.036 2.299-47.983 24.599-48.451 51.728l-.817 47.397c.263 13.544 1.991 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659h-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M734.444 963.576c45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H728.279c-18.807 0-33.961-15.418-33.637-34.222l1.661-96.338c.468-27.129 21.415-49.43 48.451-51.728 10.211-.868 21.503-1.261 33.298-.712a240.292 240.292 0 0 1 40.622 5.389 52.82 52.82 0 0 1 39.303 36.786l27.243 100.619"
            />
            <path
                fill="#FCB47F"
                d="M882.847 675.255c-9.2-7.1-20.001-12.7-32.601-17.2l19.101 31.5c0 .1 17.9-10.8 13.5-14.3Z"
            />
            <path
                fill="url(#g)"
                d="m847.945 656.254 2.701 1c13.1 4.7 23.799 10.3 32.799 17.3.8.6 1.1 1.4 1.1 2.3-.2 4.6-12.2 12.2-14.6 13.7l-.9.5-21.1-34.8Z"
            />
            <path
                fill="#FCB47F"
                d="M870.843 672.653c9.2 2.4 18.4 4.4 27.6 6.5 9.2 2.1 18.3 4.8 27.2 8 9 3.1 17.9 6.5 26.7 10.1 8.8 3.7 17.6 7.5 26.2 12.1 11.3 6 15.6 20 9.6 31.3-4.2 8-12.5 12.4-20.9 12.3-9.7-.2-19.3-1.1-28.8-2.1-9.5-1.1-18.9-2.5-28.2-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.6-21.2-22.3-37.7 4.3-16.6 21.2-26.6 37.7-22.3l.2-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m951.144 731.654-.5-7.7c-.2-2.6-.401-5.1-.601-7.7-.1-2.6-.399-5.1-.399-7.7 0-1.3-.101-2.6-.101-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7 1.1-8.7 9.1-14.9 17.8-13.8 6.1.8 11 5 13 10.5 1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.601 2.5.901 3.8.3 1.3.499 2.5.799 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.701 5.1 1.101 7.6l1.099 7.6c1.8 12.3-6.7 23.7-19 25.6-12.3 1.9-23.699-6.7-25.599-19-.1-.4-.2-1-.2-1.6Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M976.845 752.352c-14.1.8-28.801-.6-38.301-1.6-9.5-1.1-18.899-2.5-28.199-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.601-21.2-22.301-37.7 4.3-16.6 21.201-26.6 37.701-22.3h.1c9.2 2.4 18.4 4.4 27.6 6.5 9.2 2.1 18.3 4.8 27.2 8 7 2.4 13.9 5 20.8 7.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M950.647 723.953c-.2-2.6-.4-5.1-.6-7.7-.1-2.6-.4-5.1-.4-7.7 0-1.3-.1-2.6-.1-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7M982.245 666.152c1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.601 2.5.901 3.8.3 1.3.499 2.5.799 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.701 5.1 1.101 7.6l1.099 7.6c1.8 12.3-6.7 23.7-19 25.6"
            />
            <path
                fill="#FCB47F"
                d="M990.443 641.954c-3.6-4.5-8.101-8.2-13.201-11-2.1-1.2-4.299-2.001-6.599-2.401h-.101c-.2-4.9-.4-9.799-.5-14.699-.1-3.2-.099-6.3-.199-9.4 0-1.5-1.2-2.8-2.7-3.1h-.101c-1.6-.2-3.1.8-3.5 2.3-1.4 5.2-1.6 10.699-1.8 16.099-.3 6.4-.699 12.901-3.399 18.701.1-.2-1.401 3.499-1.301 3.299l-2 4.201c-.4-1.2-.5-2.5-.3-3.8.2-1.8.701-3.5.901-5.3.1-1 .099-2.101-.101-3.101a2.88 2.88 0 0 0-2.599-2.2c-1.2-.1-2.401.6-2.901 1.7-.8 1.8-1.999 3.8-2.899 5.3-2.5 4.4-2.6 9.8-1.2 14.7 1.3 4.9 3.999 9.201 6.799 13.401 8.6 13.8 23.701 16.8 29.701 5.2 3.9-6.8 7-14.4 9.5-21.9.8-2.8.3-5.8-1.5-8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M952.74 666.653c-2.8-4.2-5.5-8.601-6.8-13.401-1.3-4.9-1.2-10.3 1.2-14.7.9-1.5 2.1-3.5 2.9-5.3.5-1.1 1.7-1.8 2.9-1.7 1.3.1 2.4 1 2.6 2.2.2 1 .2 2 .1 3.1-.2 1.8-.7 3.501-.9 5.301-.2 1.8 0 3.699 1 5.099M958.642 637.954c-.1.2-.101.3-.201.5 2.7-5.8 3.201-12.3 3.401-18.7.2-5.4.4-10.9 1.8-16.1.4-1.6 1.9-2.6 3.5-2.3h.099c1.5.2 2.7 1.5 2.7 3.1.1 3.2.101 6.3.201 9.4.2 4.9.4 9.8.5 14.7M969.042 631.855c-2.9 2.6-5.9 5.4-7.4 9-1.5 3.6-1.299 7.7-.299 11.4.3 1.3.799 2.601 1.799 3.401 1 .8 3.2 1.799 4.9-1.101M975.84 637.355c-2.8 1.9-4.601 5-5.801 8.2-1.1 3.2-1.6 6.601-2 9.901-.2 1.2-.3 2.599.5 3.599.8 1.1 2.501 1.3 3.801.8 1.3-.5 3.1-2.9 3.8-4.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M973.742 629.055c6.5 2.9 12.3 7.4 16.8 12.9 1.8 2.2 2.4 5.3 1.4 8-2.5 7.6-5.6 15.1-9.5 21.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M983.539 643.555c-4 4-7.599 10.4-8.399 16.2-.2 1.1.4 2.2 1.5 2.7h.099c1.2.6 2.6.1 3.3-1 1.8-2.7 4.201-5 6.801-7"
            />
            <path
                fill="url(#h)"
                d="M996.846 726.652c-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-3.3-14.6-4.8-20.2 0 0-10.101 4.3-22.401 1.6-12.2-2.7-16.5-10.1-16.5-10.1-.7 6.7-1 12.6-1.2 19.6-18.6-7.5-38.7-14.4-58.3-18.4-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.399-1-8.099-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.199 5.1 27.299 7.7 17.5 5.1 38.201 8.2 56.101 10.4 7.8.9 18.199 1.9 28.899 2.2 1.5 0 2.901-.1 4.401-.3 14.9 1.3 27.6-12.3 25.2-27Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M882.344 741.353c17.5 5.1 38.199 8.2 56.099 10.4 7.8.9 18.201 1.9 28.901 2.2 1.5 0 2.899-.1 4.399-.3 14.8 1.4 27.5-12.2 25.2-26.9-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-4-15.6-5.8-20.6 0 0-6.1 4.3-20.2 2.3-13.8-1.9-16.8-10.1-16.8-10.1-.9 5.6-1.699 12.3-1.899 19.3-18.6-7.5-38.701-14.4-58.301-18.4M850.645 657.152c13.1 4.7 23.799 10.3 32.799 17.3"
            />
            <path
                fill="#FCB47F"
                d="M754.045 871.653c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                fill="url(#i)"
                d="M754.045 871.653c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M900.547 715.754c3.9 8.6 6.8 16.6 8.6 23.1 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M901.245 768.853c-3.6-6.2-7.7-12.9-11.1-16.9M791.945 770.053c31.1 3.8 56.501-1.2 71.701-22.1M892.145 847.552c3.6-1.3 6.999-1.4 10.299 0"
            />
            <path
                fill="#FCB47F"
                d="M965.646 1120.45c-.9-8.5-1.699-17-2.399-25.4-.3-4.2-.7-8.5-1-12.7l-.801-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.297 4.6-49.697 1.7-8.2 3.701-16.3 6.201-24.4.6-2 1.3-4 2-6.1.7-2 1.399-4 2.199-6 1.5-4 3.201-8 5.301-12l5.299-10.2c6.7-12.9 22.604-18 35.504-11.3 10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.597-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9.004 49.2c-1.6 8.7-9.9 14.4-18.5 12.8-7.1-1.3-12.2-7.1-13-13.9l-.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M969.047 960.354c10.2-2 20.4-4.5 30.8-8.3l3.303-1.2c6.4-2.3 12.1-6.7 16.1-12.9 9.6-15 5.2-35-9.8-44.6-8.9-5.7-18.003-10.4-27.103-14.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.799-.8-7.199-1.1l-3.601-.5-3.6-.3c-2.4-.2-4.899-.3-7.299-.3-21.8.1-42.301 13.7-49.901 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h80.1l-.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m965.95 1123.05-.3-2.6c-.9-8.5-1.7-17-2.4-25.4-.3-4.2-.7-8.5-1-12.7l-.8-12.7c-.6-8.4-1-16.9-1.8-25.3-.7-8.5-1.9-17-2.2-25.4-1-16.9 1.5-33.298 4.6-49.698 1.7-8.2 3.7-16.3 6.2-24.4M1018.45 899.254c10.4 5.4 15.7 16.8 13.9 27.9l-8.5 49.3-4.2 24.596-4.4 24.6c-2.9 16.4-5.8 32.8-8.8 49.2l-9.001 49.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1018.45 899.252c-8.9-5.7-26.901-16.3-36.001-20.7-4.6-2.2-9.1-4.3-13.7-6.3-4.6-2.1-9.2-4.1-13.8-5.8-9.3-3.5-18.7-5.8-28.2-7.8-2.4-.5-4.8-1-7.2-1.3-2.4-.4-4.8-.8-7.2-1.1l-3.6-.5-3.6-.3c-2.4-.2-4.9-.3-7.3-.3-21.8.1-42.3 13.7-49.9 35.5-9.7 27.7 4.8 58 32.5 67.7l.5.2c2.8 1 5.6 1.8 8.5 2.5h71.1"
            />
            <path
                fill="#FCB47F"
                d="M965.046 1120.65c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.101 3c4.3.6 8.499 1.9 12.399 3.8 4.104 2 10.804 4.6 19.904 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38.003-36.2-38.003-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M965.046 1120.65c-.1 4.3-3.4 20.6-5.5 31.1-1.1 5.1 2.4 10 7.5 10.7l20.101 3c4.3.6 8.499 1.9 12.399 3.8 4.104 2 10.804 4.6 19.904 6 4.1.6 19.1.6 26.3.6 2.2 0 4-1.7 4.1-3.9.1-1.6-.8-3.1-2.2-3.9-3-1.6-8.2-4.4-12.4-6.4-19.1-9.2-38.003-36.2-38.003-36.2"
            />
            <path
                fill="#3A383A"
                d="M1041.05 1176.85c-8.1 0-18.6-.1-21.9-.6-9.2-1.4-16-4.1-20.101-6.1-3.7-1.8-7.801-3-12.101-3.7l-20.1-3c-2.8-.4-5.199-1.9-6.699-4.2-1.6-2.3-2.2-5-1.6-7.8 3.5-16.8 5.4-27.8 5.5-31l.399-13 36.302 7.1-2.402 10.4c2.302 3.2 19.902 27.1 37.302 35.6 4.3 2.1 9.7 4.9 12.5 6.4 1.7.9 2.8 2.8 2.7 4.8-.1 2.7-2.4 4.9-5.1 4.9-1.4.2-3 .2-4.7.2Z"
            />
            <path
                fill="#888B8D"
                d="M1048.05 1167.15c-2.8-1.5-8.1-4.4-12.5-6.5-7.4-3.6-14.9-10.1-21.2-16.5-2.2-2.2-4.2-4.4-6-6.6-3.4-.4-14.403 4.4-20.203 4.2-11.5-.4-19.6-5.6-26.6-5.8-.8 4.4-1.9 9.5-3.1 15.4-.6 2.7 0 5.5 1.6 7.8s4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.103 2 10.903 4.7 20.103 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5 2.7 0 5-2.1 5.1-4.9.2-2-.8-3.9-2.6-4.8Z"
            />
            <path
                fill="#888B8D"
                d="M960.148 1159.05c1.6 2.3 4 3.8 6.7 4.2l20.1 3c4.3.6 8.4 1.9 12.1 3.7 4.102 2 10.902 4.7 20.102 6.2.3 0 .7.1 1.1.1 5.1.5 18.1.6 25.4.5M964.647 1120.55c-1.1-9.5-7.401-86.9-8.301-101.5-1.1-17.9 1.801-37.398 6.201-57.098h-73.4c-33.2-6.7-53.501-39.3-42.301-71.7 9.3-26 35.001-39.6 61.901-35.5 21.4 2.5 42.3 8 60.3 16.6 13.8 6.3 26 11.7 40.903 21.1 2 1.3 5.4 2.9 7.4 4.1 11.5 7.1 18.2 17.7 15.9 30.7-2.8 16.5-9.9 58-12.7 73.898-2 11.3-10.6 102-11.4 123.1-8.7-.9-34.603-2.6-44.503-3.7Z"
            />
            <path
                fill="#FCB47F"
                d="M865.646 553.053c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.201-4.7 32.901-1.6 6.1-2.7 12.9-6.2 18-2.5 3.699-7.8 5.199-12.3 4.799-.7-.1-1.3-.1-2-.2.4 1.3.8 11.6.8 12.401l-8.6 6.199-46.7-18 2.6-11.6.4-13s-1.1-8.5-9.1-24c-10.1-19.5 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.3-9.6 40.9-2.7 56.6 13Z"
            />
            <path
                fill="url(#j)"
                d="M872.448 577.355c-2-.2-4-.4-6-.7-2-.2-4.099-.4-5.699.8-1.2.9-1.801 2.4-1.901 3.8-.1 1.5-.399 3-.099 4.4 2.2 12.9 1.499 21.9-.901 24.5-.5.6-.999 1.3-1.099 2-.1.8.099 1.5.199 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.801 4.7 1.101 7 .4 3.9-.601 7.3-4.601 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18.1-.8-37.2 1.1-55.7Z"
            />
            <path
                fill="url(#k)"
                d="M848.746 654.654c-.2-3.7-.4-7.4-.5-11.1-14.5 1.2-30.2-11.6-36.6-18.4-.9-1 2.2 13.8 5.3 24.1 7.3 3.3 22.8 8.7 31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M811.648 624.353c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.701 9.5 24.701 3.9 8.6 1.8 21.1-.7 30"
            />
            <path
                fill="#FCB47F"
                d="M859.249 582.555c.2 5.9 2.499 11.6 5.699 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M859.249 582.555c.2 5.9 2.499 11.6 5.699 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="url(#l)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M873.75 579.053c-2.5-1.2-6.201-1.8-8.801-2.1"
            />
            <path
                stroke="url(#m)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M851.448 576.953c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M868.349 594.252c.773 0 1.401-1.029 1.401-2.3 0-1.27-.628-2.3-1.401-2.3s-1.4 1.03-1.4 2.3c0 1.271.627 2.3 1.4 2.3ZM842.748 594.252c.773 0 1.4-1.029 1.4-2.3 0-1.27-.627-2.3-1.4-2.3-.773 0-1.4 1.03-1.4 2.3 0 1.271.627 2.3 1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M863.649 618.353c-7.6 1.2-15.4.5-22.7-1.9M810.546 591.353c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M880.146 800.951c-5-7.4-9.2-15-13.3-22.6-4.1-7.7-7.8-15.5-11.4-23.3-3.6-7.8-6.8-15.8-9.3-24-2.6-8.2-5-16.4-7.9-24.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M874.344 904.152h15.5"
            />
            <path
                fill="url(#n)"
                d="M924.947 827.553c-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-5.9-21.5-25.4-62.9-52.5-82.8-.8-.6-1.9-1.4-3.1-2.2-.2-.2-.5-.3-.7-.5-1.9-1.3-4-2.6-5.3-3.4.1 1.4.3 4.8.3 4.8s0 .3.1.8c.6 3.5 2.7 18.2 1.4 23.1l-6.1-4.7-18.4-14.1c-19.9-8.3-32.3-21.7-32.3-21.7s-10.2 3.3-24.4 11.2c-.1 0-.1.1-.2.1-.9.5-1.9 1.1-2.8 1.6-1 .6-2.1 1.2-3.1 1.8-4.7 2.8-9.3 5.9-13.8 9.2-1 .7-1.9 1.4-2.9 2.1-.8.6-1.6 1.2-2.3 1.8l-.1.1c-12 9.4-18.8 24.5-18.5 40v.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5h.3v.2l.3-.2 144.6-9.8c5.6-3.8 11.8-8.6 17.8-13.3 5.4-4.3 10.7-8.6 15-12.1 2.3-1.9 4.5-3.5 6.2-4.7.1-12.1-.2-22.5-.9-30.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M852.348 682.352c1.8 1.8 7.3 12.1 13 23.6M793.648 638.152c7 8.1 22.3 18.2 32.2 21.6l24.6 19 8-19.5 7.301 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m825.847 659.754-9.4 13.1-20.9-22.6M871.945 714.555l9.201 25.8c2.6 7.3 4.399 14.8 5.299 22.4l12.9 104.7M925.946 858.254c0-12.1-.3-22.5-1-30.7-1.6-19.1-9.5-32.4-11.9-38.1-4.4-10.8-5.2-21.8-2.3-31 1.7-5.3 1.5-12-.6-19.8-3.2-11.8-10.5-29.5-20.9-46.4M785.347 641.152c-14.9 6.6-28.7 14.5-41.4 24.7-11.9 9.6-18.8 24.6-18.5 40.2l.5 19.1c.7 27.5 4.3 53.5 10.7 77.4v11.2c0 20.1-.1 42.4 5 84.5"
            />
            <path
                fill="#FCB47F"
                d="M938.942 1103.85c-5.3-6.9-10.5-13.9-15.5-20.9-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.8-22.4-45.7-2.9-8-5.5-16.1-7.7-24.5-.6-2.1-1-4.2-1.5-6.3-.4-2.1-.9-4.2-1.3-6.4-.8-4.3-1.5-8.7-1.8-13.2l-.9-11.3c-1.2-14.5 9.7-27.2 24.2-28.4 11.8-.9 22.4 6.1 26.6 16.5l19 47.3 9.5 23.7 9.3 23.7c6.2 15.8 12.5 31.6 18.7 47.5l18.5 47.6c3.2 8.2-.9 17.4-9.1 20.6-6.7 2.6-14.2.3-18.4-5.1l-1.8-2Z"
            />
            <path
                fill="url(#o)"
                d="M856.843 960.252c2.5-.4 3.101-.7 5.601-1.1 11.799-2.1 22.9-9.1 29.6-20.4 11.5-19.4 5.6-44.5-13-57.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.801-.6-1.799-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7c-3.1-.3-6.3-.4-9.5-.2-28.2 1.6-51.101 24.4-52.801 52.6-1 17.7 6.101 33.7 17.901 44.6 8 7.4 18.6 11.4 29.5 11.4h66.599l-3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m940.543 1105.85-1.5-2c-5.3-6.9-10.5-13.9-15.5-20.9-2.5-3.5-5.1-7-7.6-10.5l-7.4-10.6c-5-7-9.8-14.1-15-21.1-5.1-7-10.6-13.8-15.4-20.9-9.9-14.1-16.4-29.797-22.4-45.697-1.6-4.4-3.1-8.8-4.5-13.2-1.2-3.7-2.2-7.5-3.2-11.2-.6-2.1-1-4.2-1.5-6.3M897.145 908.754l14.899 39.2 9.5 23.7 9.3 23.7c6.2 15.796 12.5 31.596 18.7 47.496l18.5 47.6M745.844 904.852c-1.1 18.2 6.5 34.6 18.9 45.6 7.8 6.9 18 10.5 28.4 10.5h54.6M897.142 908.752c-2.2-10.8-8.4-20.7-18-27.4l-7.4-5.2c-4.6-3.2-9.3-5.8-14-8.2-2.4-1.2-4.7-2.3-7.1-3.4-2.4-1.1-4.8-2-7.1-3l-7.2-2.7-3.6-1.2-1.8-.6-1.8-.5c-4.8-1.4-9.7-2.7-14.6-3.2l-6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M938.945 1103.85c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.905-3.8 7.205-8.2-1.5-9.4-6.004-18.4-10.404-27.2-8.2-16.7-15.101-33.4-23.301-50.1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M938.945 1103.85c3.4 10.8 6.8 20.9 7.4 32.2.3 6.2.2 12.4 1.6 18.4 1.3 6 4.4 12 9.5 15.4 3.8 2.5 8.4 3.3 12.9 4 8 1.2 16.1 1.8 24.1 2 4.4.1 7.905-3.8 7.205-8.2-1.5-9.4-6.004-18.4-10.404-27.2-8.2-16.7-15.101-33.4-23.301-50.1"
            />
            <path
                fill="#3A383A"
                d="M994.446 1176.85c-8.1-.2-16.3-.9-24.3-2-4.5-.7-9.2-1.5-13.3-4.1-4.8-3.1-8.4-8.8-10-16-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.5-10.4-3.5-19.7-6.6-29.6l-.7-2.2-4.2-6 30.3-19.8 4.7 11.5c4.1 8.3 7.9 16.8 11.6 25 3.7 8.2 7.5 16.7 11.6 25l.5 1c4.2 8.5 8.504 17.2 9.904 26.4.4 2.3-.3 4.7-1.8 6.5-1.504 1.8-3.804 2.8-6.204 2.8l.1.1Z"
            />
            <path
                fill="url(#p)"
                d="M957.745 1173.95c-4.7-4-9.201-12-10.801-19.2-1.1-4.8-1.2-9.7-1.4-14.4-.1-1.4-.1-2.8-.2-4.2-.2-4.3-.899-8.4-1.699-12.4 8.4-5.4 8.399-9.3 16.799-11.9 8.8-2.7 12.7 1.9 20.1 3.3 3.7 8.2 7.501 16.7 11.601 25l.5 1c4.2 8.5 8.495 17.2 9.895 26.4.4 2.3.9 6.9.7 11.1-.3 11.2-13.595 22.2-45.495-4.7Z"
            />
            <path
                fill="url(#q)"
                d="M930.344 1107.25c-6.6-11-40.499-69.4-53.099-86.8-11.3-16.2-20.101-37.697-26.801-58.497h-57.199c-11.3 0-22-4.1-30.1-11.7-40.2-36.5-8.9-105.3 45.1-98.8 7.7.8 14.5 1.8 22.9 4.5 17.2 5.4 34.5 13.9 48.6 24.5 10.5 7.3 19.3 18.3 20.9 30.9l12.399 36.2c18.2 45.2 38.2 96.797 56 142.397-4.5 6.5-25.1 16.9-38.7 17.3Z"
            />
            <path
                fill="url(#r)"
                d="M972.413 678.145c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7c-.3.9-.2 1.7.2 2.2l4.6 5.6c-.4-.5-.5-1.3-.2-2.2l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.6c.7 0 1.2.2 1.6.7l-4.7-5.6Z"
            />
            <path
                fill="url(#s)"
                d="M975.412 683.047c1.6 0 2.4 1.3 1.8 2.9l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h57.5Z"
            />
            <path
                fill="#888B8D"
                d="M935.016 714.645c3.2 0 4.8 2.6 3.6 5.8-1.2 3.2-4.8 5.8-8 5.8s-4.8-2.6-3.6-5.8c1.2-3.2 4.8-5.8 8-5.8Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M921.513 689.945c1.2 0 1.9 1 1.4 2.2-.5 1.3-1.9 2.3-3.1 2.3-1.2 0-1.9-1-1.4-2.2.5-1.3 1.8-2.3 3.1-2.3ZM918.31 697.445c1.2 0 1.9 1 1.4 2.2-.5 1.2-1.8 2.2-3.1 2.2-1.2 0-1.9-1-1.4-2.2.5-1.2 1.8-2.2 3.1-2.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m977.014 683.745-4.6-5.6c-.4-.5-.9-.7-1.6-.7h-57.6c-1.6 0-3.4 1.3-4 2.9l-32.3 85.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M977.012 683.747c.4.5.5 1.3.2 2.2l-32.3 85.7c-.6 1.6-2.4 2.9-4 2.9h-57.6c-1.6 0-2.4-1.3-1.8-2.9l32.4-85.7c.6-1.6 2.4-2.9 4-2.9h38.7"
            />
            <path
                fill="#FCB47F"
                d="M789.744 695.351c1.7 7 3.8 14 5.8 21s3.4 14.101 4.3 21.301c1 7.2 1.7 14.5 2.2 21.8.4 7.4.7 14.799.2 22.299-.8 13-12 22.9-25 22.1-8-.5-14.9-5-18.7-11.3-3.9-6.5-7.2-13.199-10.2-19.899-3-6.7-5.7-13.501-8.2-20.401-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3-4.1-16.6 6-33.399 22.6-37.599 16.6-4.1 33.4 5.999 37.6 22.599l.1.3Z"
            />
            <path
                fill="#FCB47F"
                d="m778.046 763.952 13-1.9 13-2c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.8-.5 13.2-.6 8.7-.2 15.8 6.7 16 15.4.1 7-4.4 13.1-10.7 15.2-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-12.2 2.4-24-5.6-26.4-17.8-2.4-12.2 5.6-24 17.8-26.4.4 0 .7-.1 1-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M758.545 792.648c-3.9-6.5-7.2-13.2-10.2-19.9-3-6.7-5.7-13.5-8.2-20.4-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3M789.645 695.25v.2c1.7 7 3.8 14 5.8 21s3.4 14.1 4.3 21.3c.8 6.2 1.5 12.4 2 18.7M888.247 783.449c-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-10.7 2.1-22-5.8-27.2-15.6M795.145 761.35l8.9-1.4c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.799-.5 13.199-.6"
            />
            <path
                fill="url(#t)"
                d="M869.646 752.452c-2.8.2-5.7.4-8.5.6-1.6.1-3.1.2-4.7.3-4.5.3-9 .7-13.2 1.1-3 .2-6 .5-8.9.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2c-3.6-14.3-16.3-24.3-31-24.3-2.6 0-5.2.3-7.8 1-17.1 4.3-27.6 21.7-23.3 38.8 1.2 4.9 2.3 9.9 3.2 14.8.4 2.1.9 4.3 1.3 6.4 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.6 2.8 3.7 4 7.8 12.2 12.8 20.9 12.8 1.5 0 3-.1 4.5-.4l12.9-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.4 8.6-2.2 4.1-1.1 8.5-2.2 12.8-3.4 1.5-.4 3-.8 4.5-1.3 2.7-.8 5.5-1.5 8.2-2.3h.2s-7-9.4-7.2-18.6c-.1-8.4 2.9-17.3 2.9-17.3-.5 0-1.2.1-1.9.1Z"
            />
            <path
                fill="#FCB47F"
                d="M893.047 781.251c9.3-4.1 18.2-9.5 25.5-16.6 2.5-2.4 5-4.9 7.4-7.4l1.2-1c10.6-8.1 17.6-20.2 22.8-32.3.6-1.3.4-2.9-.7-3.9.5-.6.1-1.7-.4-2.3-.5-.6-1.4-.8-2.1-.9-1.1-.1-2 .9-2.9 1.6l-.6-.9c-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 7-7.8 10.1-5.3 5.4-12.7 8.7-20.3 8.9h-2.8c.6-3.9 2.7-7.4 4.6-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.6-2.6-1.9-2.9-1.1-.3-2.3.2-2.9 1.2-2.4 4.1-6.3 7.5-9.3 11.1-3.4 4.1-7 8.1-9.7 12.7-2.7 4.6-4.7 9.8-4.5 15.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M900.847 743.354c.6-3.9 2.7-7.4 4.6-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.6-2.6-1.9-2.9-1.1-.3-2.3.2-2.9 1.2-2.4 4.1-6.3 7.499-9.3 11.099-3.4 4.1-7 8.101-9.7 12.701-2.7 4.6-4.7 9.8-4.5 15.1M922.346 749.952c5.6-3.1 9.2-8.9 12.2-14.5 2.6-4.9 5-10.101 8.7-14.201.9-1 .9-2.599-.2-3.599-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 6.999-7.8 10.099-5.3 5.4-12.7 8.701-20.3 8.901"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M948.345 719.152c-1.7 3.8-3.5 7.6-5.2 11.4-2.1 4.6-4.2 9.2-6.9 13.5-4.8 7.7-11.2 14.3-17.8 20.6-7.3 7-16.2 12.5-25.5 16.6"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M949.948 723.953c-5.1 12.1-12.2 24.2-22.8 32.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M728.547 710.452c1.2 4.9 2.3 9.899 3.2 14.799.4 2.1.9 4.301 1.3 6.401 1.5 6.9 3.5 14 6.1 21 2.7 7.3 5.3 14 8.2 20.4 2.5 5.6 5.9 12.8 10.2 20 .8 1.3 1.7 2.599 2.8 3.699 4 7.8 12.2 12.801 20.9 12.801 1.5 0 3-.1 4.5-.4l12.9-2.5 12.9-2.5c7.9-1.5 16.3-3.4 25.8-5.7 1.4-.3 2.8-.7 4.2-1 2.8-.7 5.8-1.401 8.6-2.201 4.1-1.1 25.3-7.799 25.4-7.799 0 0-6-6.701-6.8-19.201-.7-10 2.5-15 2.5-15-.7 0-24 .701-28.2 1.101-3 .2-6 .5-8.9.8-1.4.1-2.8.3-4.3.4-9.6 1-18.2 2.1-26.2 3.3l-.9.1c-.4-6.9-1.2-14-2.2-21.5-.9-7.4-2.4-14.7-4.3-21.5l-1.8-6.3c-1.4-4.8-2.8-9.7-4-14.6v-.2"
            />
            <path
                fill="#7A5430"
                d="M774.694 559.301c0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1 4.1.7 6.9 4.7 6.2 8.8-.5 3.1-2.8 5.4-5.7 6.1 2.1 1.7 3.3 4.4 2.7 7.3-.7 4.1-4.7 6.9-8.8 6.2-1.6-.3-2.9-1-4-2.1-1.6 1.1-3.6 1.6-5.7 1.2-.7-.1-1.3-.3-1.9-.6-10.1-4.4-15.3-8.4-20.7-10.7-17.1-7.1-11.7 3.1-23.9 2.3 1.3 5.3 1.2 10.2-3.8 16.8-3.6 4.7-1 11.2-1 15h-5.3c-1.2-4.7-5.9-9.4-10.8-10.3-2.4-.5-5.2 0-6.9 2.2-2.9 3.8-1.3 9.8.3 14.1 1.8 4.8 4.7 8.2 9.1 10.8-.3 7.4-1.5 14.8-3.6 21.9-3.3-12.1-4.4-11.5-12.8-30.1-3.7-8.3-7.1-15.1-6.3-27.5-3.5-.7-4.2-3.1-4.2-6.8 0-1.5.4-2.9 1.2-4.1-2.8-.7-5.2-3.6-5.2-7.1Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M785.196 578.201c-3.5-.7-6.1-3.8-6.1-7.5 0-1.5.4-2.9 1.2-4.1-3.2-.9-5.5-3.8-5.5-7.3 0-3 1.7-5.5 4.2-6.8-1-1.3-1.6-2.9-1.6-4.7 0-4.2 3.4-7.6 7.6-7.6.4 0 .8 0 1.2.1 0-.3-.1-.7-.1-1 0-4.2 3.4-7.6 7.6-7.6 1.1 0 2.1.2 3.1.7 0-.2 0-.4.1-.6.7-4.1 4.7-6.9 8.8-6.2 1.3.2 2.4.8 3.4 1.5.9-4 4.7-6.6 8.8-5.9 1.7.3 3.2 1.1 4.2 2.3 1.4-3.2 4.8-5.1 8.3-4.5 2.4.4 4.4 2 5.4 4 1.6-2.6 4.7-4.2 7.9-3.6 2.9.5 5.1 2.5 5.9 5.1 1.6-2.4 4.6-3.7 7.6-3.2 3.1.6 5.5 2.9 6.1 5.9 1.7-1.4 3.9-2.1 6.2-1.7 4.1.7 6.9 4.7 6.2 8.8v.2c1.3-.5 2.8-.7 4.2-.5 4.1.7 6.9 4.7 6.2 8.8-.2 1.1-.6 2.1-1.2 3 .3 0 .7 0 1 .1"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M810.793 591.4c-1.9-1.6-3.8-3.3-6.2-4.2-2.4-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="url(#u)"
                stroke="#222731"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M798.141 603.066c0 5.49 8.655 13.385 11.742 13.385.995 0 2.08-1.11 1.389-2.653-1.215-2.713-4.579-5.924-3.96-6.441a5.589 5.589 0 1 0-9.17-4.291h-.001Z"
            />
            <path
                fill="#AEAEAF"
                d="M1304.1 758.91V656.598c0-4.991-4.04-9.038-9.03-9.038-4.99 0-9.04 4.046-9.04 9.038v29.736h-20.56v-29.736c0-16.347 13.25-29.598 29.6-29.598 16.34 0 29.6 13.251 29.6 29.598V758.91h-20.57Z"
            />
            <path fill="#818084" d="M1324.67 721.605h-20.56v9.329h20.56v-9.329Z" />
            <path
                fill="url(#v)"
                d="m1414.74 780.526-9.95-21.615h-19.21v-12.837h-17.48v12.837h-137.96l-9.95 21.615h194.55Z"
            />
            <path fill="url(#w)" d="M1042.14 780.523H888V1191h154.14V780.523Z" />
            <path fill="#FFD100" d="M1414.74 802.141h-154.17V1191h154.17V802.141Z" />
            <path fill="#FCEB94" d="M1303.8 802.141h-240.04v311.309h240.04V802.141Z" />
            <path
                fill="#FDF3BF"
                d="M1063.76 780.523h-21.62V1191h21.62V780.523ZM1436.35 780.523h-21.62V1191h21.62V780.523Z"
            />
            <path fill="#FDF3BF" d="M1414.74 780.523h-350.96v21.615h350.96v-21.615Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1260.57 1191v-77.55M1355.3 780.523h81.05V1191"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M888 1191V780.523h520.93M1063.78 802.141h350.96M1063.77 1191l-.01-388.859M1063.76 1113.45h240.34M1368.11 746.074h17.47M1404.79 758.914h-174.65"
            />
            <path
                fill="url(#x)"
                d="m1522.71 907.379-6.06 283.561h-13.87l-6.07-283.573 26 .012Z"
            />
            <path fill="#AEAEAF" d="M1610.77 1098.84h-202.11v18.61h202.11v-18.61Z" />
            <path
                fill="#AEAEAF"
                d="M1446.52 907.379 1391 1190.94h13.87l67.65-283.573-26 .012ZM1572.9 907.379l55.52 283.561h-13.87l-67.65-283.573 26 .012Z"
            />
            <path
                fill="url(#y)"
                d="M1422.11 896.598c0 5.956 4.82 10.784 10.78 10.784h153.65c5.95 0 10.78-4.828 10.78-10.784 0-13.033-10.56-23.598-23.6-23.598H1445.7c-13.03 0-23.59 10.565-23.59 23.598Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1472.52 907.382h114.02c5.95 0 10.78-4.828 10.78-10.784 0-13.033-10.56-23.598-23.6-23.598H1445.7c-13.03 0-23.59 10.565-23.59 23.598 0 5.956 4.82 10.784 10.78 10.784h13.63"
            />
            <path
                fill="#FCB47F"
                d="M1381.23 1027.45c1.22-7.92 2.31-15.83 3.26-23.73.48-3.949 1.04-7.904 1.45-11.85l1.3-11.84c.88-7.895 1.65-15.782 2.76-23.693l1.66-11.865.84-5.934.64-5.92c1.68-15.784.01-31.339-2.2-46.857-1.19-7.753-2.7-15.484-4.66-23.184a144.212 144.212 0 0 0-7.71-22.977l-4.14-9.364a24.383 24.383 0 0 0-11.86-12.198 24.428 24.428 0 0 0-16.96-1.481 24.437 24.437 0 0 0-13.8 9.957 24.366 24.366 0 0 0-3.94 16.556l5.72 46.616 2.87 23.307 3 23.298c2.03 15.531 3.96 31.068 6.05 46.594l6.22 46.585c.47 3.51 2.19 6.73 4.84 9.07 2.65 2.35 6.06 3.66 9.6 3.7 3.54.04 6.98-1.2 9.68-3.49a14.73 14.73 0 0 0 5.03-8.97l.35-2.33Z"
            />
            <path
                fill="#FCB47F"
                d="M1353.08 866.428c12.81 2.74 25.4 3.361 37.93 4.68 0 0 107.09-.542 110.05-1.08a52.332 52.332 0 0 0 32.81-20.486 52.31 52.31 0 0 0 9.75-37.423 52.267 52.267 0 0 0-18.62-33.897 52.29 52.29 0 0 0-36.81-11.837l-7.26.498c-3.02.205-6.03.52-9.03.893l-4.49.578-4.47.681c-2.98.423-5.94.989-8.91 1.504-2.97.493-5.91 1.13-8.87 1.717-11.79 2.447-23.53 5.216-35.17 8.46-5.81 1.64-11.56 3.636-17.27 5.845a308.111 308.111 0 0 0-17.01 7.177c-1.41.634-2.81 1.298-4.21 1.978-1.41.663-2.81 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.052 164.052 0 0 0-16.4 10.291l-.87.637a29.858 29.858 0 0 0-10.27 13.467 29.943 29.943 0 0 0-1.3 16.886 29.96 29.96 0 0 0 8.09 14.879 29.935 29.935 0 0 0 14.88 8.09Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1391.01 871.18s107.09-.614 110.05-1.152a52.332 52.332 0 0 0 32.81-20.486 52.31 52.31 0 0 0 9.75-37.423 52.267 52.267 0 0 0-18.62-33.897 52.29 52.29 0 0 0-36.81-11.837l-7.26.498c-3.02.205-6.03.52-9.03.893l-4.49.578-4.47.681c-2.98.423-5.94.989-8.91 1.504-2.97.493-5.91 1.13-8.87 1.717-11.79 2.447-23.53 5.216-35.17 8.46-5.81 1.64-11.56 3.636-17.27 5.845a308.111 308.111 0 0 0-17.01 7.177c-1.41.634-2.81 1.298-4.21 1.978-1.41.663-2.81 1.35-4.2 2.062-2.8 1.377-5.56 2.939-8.35 4.4a164.235 164.235 0 0 0-16.4 10.291l-.87.637c-7.2 5.274-12.77 13.891-13.52 22.133M1381.23 1027.45c1.22-7.92 2.31-15.83 3.26-23.73.48-3.947 1.04-7.902 1.45-11.848l1.3-11.84c.88-7.895 1.65-15.782 2.76-23.693l1.66-11.865.84-5.933.64-5.92c1.68-15.784.01-31.34-2.2-46.858-.65-4.274-1.41-8.542-2.28-12.802M1327.87 843.07l5.72 46.616 2.87 23.308 3 23.298c2.03 15.531 3.96 31.068 6.05 46.594l6.22 46.584"
            />
            <path
                fill="#FCB47F"
                d="M1380.67 1028.88c-.1 3.9 2.03 18.67 3.48 28.26.17 1.09.11 2.2-.17 3.27a8.151 8.151 0 0 1-3.96 5.05c-.97.53-2.03.85-3.13.95l-6.97.65c-9.34.9-18.55 2.79-27.49 5.63a72.486 72.486 0 0 1-29.97 2.45c-5.74-.67-10.34-2.82-10.63-5.89-.28-3.04 7.5-6.07 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1380.67 1028.88c-.1 3.9 2.03 18.67 3.48 28.26.17 1.09.11 2.2-.17 3.27a8.151 8.151 0 0 1-3.96 5.05c-.97.53-2.03.85-3.13.95l-6.97.65c-9.34.9-18.55 2.79-27.49 5.63a72.486 72.486 0 0 1-29.97 2.45c-5.74-.67-10.34-2.82-10.63-5.89-.28-3.04 7.5-6.07 12.81-7.73 18.2-5.7 36.89-29.95 36.89-29.95"
            />
            <path
                fill="url(#z)"
                d="M1314.21 1060.65c5.72-1.79 11.52-5.49 16.76-9.69 16.21 6.07 36.38 4.56 52.7-2.96.4 2.77.84 5.8 1.34 9.07.19 1.23.13 2.48-.19 3.68-.31 1.2-.87 2.32-1.64 3.29-.76.98-1.72 1.79-2.8 2.39a9.1 9.1 0 0 1-3.53 1.06l-6.97.65a132.3 132.3 0 0 0-27.28 5.59 70.36 70.36 0 0 1-20.93 3.04c-3.16 0-6.32-.19-9.46-.55-6.78-.79-11.19-3.39-11.5-6.8-.27-2.93 4.27-5.88 13.5-8.77Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1383.18 1064.04c-.76.98-1.72 1.79-2.81 2.39-1.09.59-2.29.95-3.52 1.06l-6.97.65c-9.27.9-18.41 2.77-27.29 5.59a70.314 70.314 0 0 1-20.93 3.04c-3.15 0-6.31-.19-9.45-.55-6.78-.79-11.19-3.39-11.5-6.8"
            />
            <path
                fill="#004F71"
                d="m1391.07 973.13 3.29-40.5c1.6-15.049.15-29.602-2.33-46.994-.64-4.164-1.44-8.348-2.31-12.578l97.88-.164 4.51-.578c2.94-.381 5.96-.852 8.98-1.4a53.383 53.383 0 0 0 34.28-22.104 53.337 53.337 0 0 0 8.65-39.856 53.19 53.19 0 0 0-18.25-31.399 53.216 53.216 0 0 0-37.8-12.266l-7.25.497c-2.76.188-5.74.482-9.09.899l-4.49.578-4.49.684c-2.22.314-4.46.714-6.63 1.1l-2.31.408c-2.22.37-4.47.827-6.65 1.269l-2.24.453c-12.7 2.633-24.22 5.406-35.24 8.478-5.42 1.527-11.1 3.45-17.36 5.876a308.77 308.77 0 0 0-17.07 7.2c-1.28.576-2.67 1.227-4.23 1.987-1.35.637-2.74 1.315-4.22 2.07-1.82.893-3.65 1.881-5.42 2.837-.98.527-1.96 1.054-2.94 1.569a165.122 165.122 0 0 0-16.53 10.37l-.87.636a37.325 37.325 0 0 0-12.06 14.989 30.395 30.395 0 0 0-2.15 15.904l5.72 46.617 2.87 23.308 5.41 60.111 50.34-.001Z"
            />
            <path
                fill="#FCB47F"
                d="M1476.91 598.916c-7.33 2.382-14.78 4.373-22.18 6.533a173.863 173.863 0 0 0-21.73 7.914c-7.11 3.055-14.15 6.31-21.1 9.84a216.5 216.5 0 0 0-20.52 11.689 19.209 19.209 0 0 0-7.73 21.93 19.236 19.236 0 0 0 7.3 9.862 19.21 19.21 0 0 0 11.79 3.424c7.86-.404 15.7-1.236 23.47-2.492 7.71-1.197 15.33-2.669 22.88-4.341a173.62 173.62 0 0 0 22.26-6.267c7.27-2.567 14.48-5.303 21.82-7.648a26.561 26.561 0 0 0 15.49-13.098 26.488 26.488 0 0 0 1.68-20.212 26.62 26.62 0 0 0-5-9.046c-2.25-2.657-5-4.845-8.09-6.44a26.538 26.538 0 0 0-9.94-2.855c-3.47-.29-6.96.107-10.28 1.166l-.12.041Z"
            />
            <path
                fill="#FCB47F"
                d="m1413.77 660.184 5.87-10.432 6.01-10.363a236.975 236.975 0 0 0 10.83-21.367c1.61-3.666 3.31-7.283 4.83-11.001l2.33-5.547c.77-1.851 1.49-3.735 2.23-5.6 2.94-7.488 5.74-15.057 8.04-22.892.79-2.691.63-5.572-.44-8.163s-3-4.736-5.46-6.078a12.273 12.273 0 0 0-15.17 2.752c-5.34 6.179-10.19 12.626-14.89 19.153-1.17 1.638-2.36 3.258-3.5 4.911l-3.4 4.965c-2.31 3.289-4.42 6.68-6.63 10.021a236.297 236.297 0 0 0-12.1 20.678l-5.45 10.66-5.59 10.592a18.518 18.518 0 0 0-1.35 13.977 18.524 18.524 0 0 0 8.86 10.899 18.502 18.502 0 0 0 24.98-7.165Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1402.74 670.108c7.86-.404 15.7-1.236 23.47-2.492 7.71-1.197 15.33-2.669 22.88-4.341a173.62 173.62 0 0 0 22.26-6.267c7.27-2.567 14.48-5.303 21.82-7.648a26.561 26.561 0 0 0 15.49-13.098 26.488 26.488 0 0 0 1.68-20.212 26.62 26.62 0 0 0-5-9.046c-2.25-2.657-5-4.845-8.09-6.44a26.538 26.538 0 0 0-9.94-2.855c-3.47-.29-6.96.107-10.28 1.166l-.12.041c-7.33 2.382-14.78 4.373-22.18 6.533a165.33 165.33 0 0 0-10.98 3.59"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1419.64 649.752 6.01-10.363a236.975 236.975 0 0 0 10.83-21.367c1.61-3.666 3.31-7.283 4.83-11.001l2.33-5.547c.77-1.851 1.49-3.735 2.23-5.6 2.94-7.488 5.74-15.057 8.04-22.892.79-2.691.63-5.572-.44-8.163s-3-4.736-5.46-6.078a12.273 12.273 0 0 0-15.17 2.752c-5.34 6.179-10.19 12.626-14.89 19.153-1.17 1.638-2.36 3.258-3.5 4.911l-3.4 4.965c-2.31 3.289-4.42 6.68-6.63 10.021a236.297 236.297 0 0 0-12.1 20.678l-5.45 10.66-5.59 10.592a18.477 18.477 0 0 0-1.33 14.105 18.397 18.397 0 0 0 3.45 6.349 18.326 18.326 0 0 0 5.62 4.541 25.05 25.05 0 0 0 13.71 2.644"
            />
            <path
                fill="#FCB47F"
                d="M1437.26 530.182a10.115 10.115 0 0 1 5.31-4.023l.09-.103a12.66 12.66 0 0 1 5.77-5.961 16.036 16.036 0 0 1 5.51-1.666 8.205 8.205 0 0 1 5.51 1.378c.23.175.46.368.67.577l.77-.788a7.703 7.703 0 0 1 4.69-1.951 7.502 7.502 0 0 1 4.78 1.517c.42 1.1.01 2.958-1.03 3.507a7.78 7.78 0 0 1-2.34.787l-.1.163a59.382 59.382 0 0 0-4.78 13.243c-.91 3.927-1.35 8.333.81 11.741l2.57 3.007c2.4-.589 4.32-2.331 6.19-3.947a17.538 17.538 0 0 1 4.04-3.124c.47-.198.98-.242 1.47-.127.48.116.92.386 1.24.77l.03.035c.34.404.53.911.55 1.439a2.4 2.4 0 0 1-.47 1.465c-3.86 5.104-7.56 10.304-11.45 15.388a42.788 42.788 0 0 1-4.86 5.649 49.677 49.677 0 0 1-6.61 5.057c-5.91 3.672-18.9 6.557-21.45-6.98-2.13-11.334-3.4-27.142 3.09-37.053Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1465.5 551.612c2.4-.589 4.32-2.331 6.19-3.946a17.385 17.385 0 0 1 4.05-3.125c.46-.198.97-.242 1.46-.126.48.115.92.385 1.24.769l.03.035c.34.404.54.912.55 1.439.01.528-.15 1.045-.47 1.466-3.86 5.103-7.56 10.304-11.45 15.387a42.788 42.788 0 0 1-4.86 5.649 31.913 31.913 0 0 1-7.54 5.057M1463.04 551.656c-3.3.161-6.45 1.451-8.92 3.654M1462.93 548.609c-2.15-3.408-1.72-7.815-.81-11.741a58.918 58.918 0 0 1 4.79-13.243M1455.81 526.718c.29-.665.34-1.404.17-2.105-.18-.7-.58-1.323-1.15-1.774a5.943 5.943 0 0 0-4.05-1.18 10.324 10.324 0 0 0-7.41 3.545M1461.77 524.825a5.57 5.57 0 0 0-.49-2.816 5.472 5.472 0 0 0-1.82-2.199 8.165 8.165 0 0 0-5.5-1.379c-1.93.219-3.8.784-5.52 1.667M1463.88 523.36c1.85.431 3.78.186 5.46-.691 1.04-.549 1.45-2.407 1.03-3.507a7.508 7.508 0 0 0-4.78-1.517 7.703 7.703 0 0 0-4.69 1.951M1434.18 567.239c-1.66-11.729-3.4-27.142 3.09-37.053a10.144 10.144 0 0 1 8.07-4.504 6.824 6.824 0 0 1 3.46.733c.73.395 1.33.985 1.74 1.704.14.254.22.537.22.826.01.288-.04.575-.17.837-.12.285-.31.535-.55.731-.24.196-.52.33-.83.392-1.74.348-3.71.793-5.36 1.098-.16 1.409-.65 2.888-.82 4.297M1456.05 533.482a17.306 17.306 0 0 1 2.5-6.466M1449.34 535.355l.95-4.793"
            />
            <path
                fill="#FCB47F"
                d="M1470.27 601.948c8.49-8.308 18.7-15.185 30.91-21.253l-15.6 34.205s-19.39-8.958-15.31-12.952Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M1501.18 580.695a132.217 132.217 0 0 0-30.91 21.252"
            />
            <path
                fill="url(#A)"
                d="M1491.66 519.288c-.5-1.4-2.1-1.9-3.5-1.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7l8.1 22.3c.5 1.4 2.1 1.9 3.5 1.3l49.7-23.3c1.4-.7 2.2-2.3 1.7-3.7l-8.1-22.2Z"
            />
            <path
                fill="#444"
                d="m1488.26 517.988 9.8 27.2-49.7 23.3-9.8-27.2 49.7-23.3Z"
            />
            <path
                fill="url(#B)"
                d="m1435.76 538.687 49.7-23.2c1-.5 2.1-.3 2.8.3l2.8 2.6c-.7-.7-1.8-.8-2.8-.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7l8 22.2c.1.4.4.7.6.9l-2.8-2.5c-.3-.2-.5-.6-.6-.9l-8-22.3c-.5-1.4.3-3 1.7-3.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1448.36 568.486 49.7-23.3c1.4-.7 2.2-2.3 1.7-3.7l-8-22.3c-.5-1.4-2.1-1.9-3.5-1.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1445.46 568.087-2.8-2.5c-.3-.2-.5-.6-.6-.9l-8-22.3c-.5-1.4.2-3 1.7-3.7l49.7-23.2c1-.5 2.1-.3 2.8.3l2.8 2.6c-.7-.7-1.8-.8-2.8-.3l-49.7 23.2c-1.4.7-2.2 2.3-1.7 3.7M1492.06 528.586l1.8 4.9M1442.46 552.188l2.2 6"
            />
            <path
                fill="url(#C)"
                d="M1469.57 601.227a109.598 109.598 0 0 1 20.02-15.329 31.062 31.062 0 0 0-2.39 13.895 66.13 66.13 0 0 0 1.45 10.762l-2.17 4.755a.9.9 0 0 1-.23.317.955.955 0 0 1-.33.205 1.01 1.01 0 0 1-.77-.03c-2.6-1.2-15.6-7.426-16.4-12.093-.09-.449-.06-.912.09-1.346.14-.434.39-.825.73-1.136Z"
            />
            <path
                fill="url(#D)"
                d="M1460.98 602.623c5.14-1.464 10.46-2.977 15.62-4.657l.13-.041a27.522 27.522 0 0 1 21.06 1.667 27.631 27.631 0 0 1 8.44 6.689 27.511 27.511 0 0 1 5.22 9.418 27.699 27.699 0 0 1 1.21 10.701 27.568 27.568 0 0 1-3 10.344 27.469 27.469 0 0 1-6.74 8.401 27.525 27.525 0 0 1-9.45 5.168c-5.16 1.65-10.35 3.524-15.37 5.335l-3.58 1.29-18.67-52.849s3.76-1.077 5.13-1.466Z"
            />
            <path
                fill="#FCB47F"
                d="M1453.56 702.396c-16.25-6.304-32.18-19.576-12.27-43.133 19.91-23.558 26.37-32.835 31.85-47.115 9.29-24.222 28.42-31.226 28.42-31.226 9.29 6.968 42.54.782 55.15-9.172l19.77 11.891a61.315 61.315 0 0 1 26.13 31.884 61.346 61.346 0 0 1 .04 41.223l-18.83 52.893a36.483 36.483 0 0 0-1.54 18.895 36.56 36.56 0 0 0 8.14 17.121 110.773 110.773 0 0 1 18.89 31.923h-167.8c-7.67-30.163 4.79-55.045 12.05-75.184Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1465.01 762.949a13.787 13.787 0 0 0-10.29 0M1549.06 678.753c-5.36 11.03-17.16 17.893-29.24 20.012-7.2 1.264-14.81 1.029-21.6-1.698a30.948 30.948 0 0 1-11.84-8.427 30.917 30.917 0 0 1-6.69-12.899 30.907 30.907 0 0 1-.08-14.532 31.053 31.053 0 0 1 6.56-12.967"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1441.51 777.581c-7.67-30.163 4.79-55.045 12.05-75.184-16.25-6.304-32.18-19.576-12.27-43.133 19.91-23.558 26.37-32.835 31.85-47.116M1556.71 571.75l19.77 11.891a61.322 61.322 0 0 1 26.17 73.107l-18.83 52.893a36.483 36.483 0 0 0-1.54 18.895 36.56 36.56 0 0 0 8.14 17.121 110.773 110.773 0 0 1 18.89 31.923"
            />
            <path
                fill="#FCB47F"
                d="M1609.31 777.578a90.493 90.493 0 0 1 4.4 15.397c10.4 55.41-52.31 115.616-131.42 44.511-24.41-21.935-36.2-41.866-40.78-59.908h167.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1609.31 777.578a90.493 90.493 0 0 1 4.4 15.397c10.4 55.41-52.31 115.616-131.42 44.511-24.41-21.935-36.2-41.866-40.78-59.908"
            />
            <path
                fill="#FCB47F"
                d="M1427.34 871.003c18.13 0 74.68.108 74.68.108s51.29-.014 63-.015c1.2 0 9.08-1.244 10.25-1.503a52.26 52.26 0 0 0 31.87-21.161 52.242 52.242 0 0 0 8.91-37.208 52.253 52.253 0 0 0-18.84-33.301 52.242 52.242 0 0 0-36.48-11.534l-7.25.497c-3.03.206-6.04.521-9.03.893l-4.49.578-4.47.681c-2.99.423-5.95.989-8.91 1.505-2.98.492-5.92 1.13-8.87 1.717-11.8 2.446-23.53 5.215-35.17 8.46-5.82 1.64-11.57 3.635-17.27 5.845a301.739 301.739 0 0 0-17.01 7.176c-1.41.634-2.81 1.298-4.22 1.978-1.4.663-2.8 1.351-4.2 2.062-2.8 1.378-5.56 2.939-8.34 4.4a163.107 163.107 0 0 0-16.4 10.292l-.87.637a29.853 29.853 0 0 0-11.88 19.54 29.888 29.888 0 0 0 5.42 22.216c4.5 6.144 11.47 14.257 19.57 16.137Z"
            />
            <path
                fill="#FCB47F"
                d="M1423.68 1029.82c2.47-7.64 4.8-15.29 7.01-22.95 1.1-3.83 2.28-7.651 3.32-11.488l3.17-11.504c2.14-7.667 4.15-15.346 6.51-22.993l3.54-11.47 1.77-5.734 1.59-5.753c4.17-15.343 5.02-30.994 5.32-46.695.06-7.859-.2-15.747-.9-23.677a144.95 144.95 0 0 0-3.94-23.957l-2.58-9.888a24.409 24.409 0 0 0-26.26-18.123 24.44 24.44 0 0 0-15.23 7.623 24.476 24.476 0 0 0-6.54 15.718l-1.8 47.021-.89 23.512-.76 23.523c-.48 15.684-1.06 31.36-1.47 47.051l-1.31 47.064c-.09 3.54 1.09 7 3.33 9.74s5.4 4.58 8.89 5.18c3.49.6 7.08-.08 10.11-1.9 3.03-1.83 5.3-4.7 6.4-8.06l.72-2.24Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1456.18 871.111h103.05c18.39 0 35.82-6.359 46.83-21.091.08-.103.15-.206.23-.309M1517.71 772.258c-11.79 2.446-23.53 5.215-35.17 8.46-5.81 1.64-11.56 3.635-17.27 5.845a307.327 307.327 0 0 0-17.01 7.176c-1.41.634-2.81 1.298-4.21 1.978-1.41.663-2.81 1.351-4.2 2.062-2.8 1.378-5.57 2.939-8.35 4.4a164.062 164.062 0 0 0-16.4 10.292l-.87.637a29.781 29.781 0 0 0-11.86 19.416M1424.93 1025.91a752.95 752.95 0 0 0 5.76-19.04c1.1-3.83 2.28-7.652 3.32-11.489l3.17-11.504c2.14-7.668 4.15-15.346 6.51-22.993l3.54-11.47 1.77-5.734 1.59-5.753c4.17-15.343 5.02-30.994 5.32-46.696.06-7.858-.2-15.747-.9-23.676M1400.46 838.93l-1.8 47.021-.89 23.511-.76 23.523c-.48 15.685-1.06 31.36-1.47 47.051l-1.31 47.064"
            />
            <path
                fill="#FCB47F"
                d="M1423.48 1028.88c-.1 3.9 2.02 18.67 3.48 28.26a8.2 8.2 0 0 1-.18 3.27 7.993 7.993 0 0 1-1.46 2.93c-.68.87-1.53 1.59-2.49 2.12-.97.53-2.04.85-3.14.95l-6.97.65c-9.33.9-18.55 2.79-27.49 5.63a72.472 72.472 0 0 1-29.97 2.45c-5.73-.67-10.34-2.82-10.62-5.89-.28-3.04 7.49-6.07 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1423.48 1028.88c-.1 3.9 2.02 18.67 3.48 28.26a8.2 8.2 0 0 1-.18 3.27 7.993 7.993 0 0 1-1.46 2.93c-.68.87-1.53 1.59-2.49 2.12-.97.53-2.04.85-3.14.95l-6.97.65c-9.33.9-18.55 2.79-27.49 5.63a72.472 72.472 0 0 1-29.97 2.45c-5.73-.67-10.34-2.82-10.62-5.89-.28-3.04 7.49-6.07 12.8-7.73 18.2-5.7 36.9-29.95 36.9-29.95"
            />
            <path
                fill="url(#E)"
                d="M1440.22 658.488c20.41-24.152 26.33-32.894 31.65-46.771 2.99-7.787 9.42-18.228 14.92-23.949a48.257 48.257 0 0 1 4.72-2.974c-4.06 7.051-4.95 18.802 6.43 23.57a21.996 21.996 0 0 0 8.68 1.436c10.92 0 21.12-6.507 31.94-13.399 7.28-4.644 14.81-9.445 22.91-12.429a32.693 32.693 0 0 1 10.8-1.712c1.47-.005 2.93.092 4.39.292.16.023.31.078.45.162a62.747 62.747 0 0 1 26.52 74.436l-18.82 52.892c-4.37 12.283-2.11 25.795 6.37 34.828 20.64 21.97 25.62 45.362 27.94 63.293-14.89 0-179.2.874-179.23.714-9.23-48.617.85-76.583 9.32-98.687.94-2.464 1.84-4.812 2.68-7.11-8.09-3.299-18.17-9.259-20.66-19.014-1.94-7.615 1.09-16.221 8.99-25.578Z"
            />
            <g filter="url(#F)">
                <path
                    fill="url(#G)"
                    d="m1553.99 669.462-5.08 9.293c-5.36 11.03-17.16 17.893-29.24 20.012-7.2 1.264-14.81 1.029-21.6-1.698a30.945 30.945 0 0 1-11.84-8.426 30.923 30.923 0 0 1-6.69-12.899 30.907 30.907 0 0 1-.08-14.532 31.057 31.057 0 0 1 6.56-12.968l6.92-10.635"
                />
            </g>
            <path
                fill="url(#H)"
                d="M1357.22 1060.54c6.01-1.88 12.12-5.87 17.57-10.33 16.99 7.08 31.81 4.91 52.05-1.52.46 3.24.58 4.27 1.19 8.28a9.09 9.09 0 0 1-.19 3.67c-.32 1.2-.88 2.32-1.64 3.29-.76.98-1.72 1.8-2.81 2.39-1.09.6-2.29.96-3.53 1.07l-6.96.64c-9.27.9-18.41 2.78-27.29 5.59a70.314 70.314 0 0 1-20.93 3.04c-3.16 0-6.31-.18-9.45-.55-6.78-.79-11.19-3.39-11.51-6.79-.27-2.93 4.27-5.89 13.5-8.78Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1426.2 1063.93c-.76.98-1.72 1.79-2.81 2.39-1.09.59-2.29.95-3.52 1.06l-6.97.65c-9.27.9-18.41 2.78-27.29 5.59a70.314 70.314 0 0 1-20.93 3.04c-3.15 0-6.31-.19-9.45-.55-6.78-.79-11.19-3.39-11.5-6.79"
            />
            <path
                fill="#FCB47F"
                d="M1482.76 508.229c1.42-4.724-1.21-10.013-1.21-15.21 1.24-9.261 13.9-23.646 24.66-26.985 18.46-5.728 33.31-1.407 44.54 6.258 3.24 2.214 26.64 23.438.73 66.891-11.08 17.266 5.23 32.566 5.23 32.566-4.69 6.464-39.37 33.253-53.24 28.629-13.87-4.623.79-20.394 3.05-27.093 1.49-4.418 2.37-8.206 1.96-11.514-6.3 1.627-10.49 1.905-14.41-.356-13.59-6.349-14.37-23.852-14.14-33.359.25-10.12 1.42-15.103 2.83-19.827Z"
            />
            <path
                fill="url(#I)"
                d="M1501.75 581.275c4.46-6.156 6.57-10.989 6.6-19.61 13.03-2.157 20.08-8.959 26.8-16.341 1.21-1.337-6.68 29.123-7.26 30.835-.58 1.712-1.39 2.179-2.34 3.716-4.31 6.968-24.45 2.309-23.8 1.4Z"
            />
            <path
                fill="url(#J)"
                d="M1483.33 499.707c1.65-2.074 9.8-2.899 10.26 4.216.6 9.199-5.2 23.632-2.88 26.204.77.85 2.72 2.541 2.82 3.682.36 4.074-.18 8.246-.43 12.334-.29 4.742-1.34 13.154 3.12 15.985-.44-.582-6.44-3.119-7.02-3.551-10.71-7.907-10.3-30.718-8.13-44.775.4-2.607 2-5.222 2.36-7.836.34-2.524-.99-5.146-.1-6.259Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1534.73 545.186c-8.18 14.378-32.27 20.999-42.22 15.605-8.72-4.723-12.78-14.241-12.31-33.178.24-9.511 1.15-14.657 2.57-19.381 1.41-4.724-1.39-9.7-.93-14.877.99-11.39 14.52-29.06 36.86-30.636 17.69-1.248 30.46 6.781 35.54 13.108 7.68 9.574 11.39 22.365 9.15 34.434-1.52 8.182-5.3 15.746-8.9 23.248-4.5 9.359-13.93 19.957 1.56 37.753"
            />
            <path
                fill="#FCB47F"
                d="M1493.59 503.926a32.04 32.04 0 0 1-5.73 16.51c-.98 1.47-2.11 2.987-2.16 4.752.03.911.28 1.801.73 2.594a5.724 5.724 0 0 0 1.85 1.961c1.02.684 2.12 1.218 3.29 1.583"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1493.59 503.926a32.04 32.04 0 0 1-5.73 16.51c-.98 1.47-2.11 2.987-2.16 4.752.03.911.28 1.801.73 2.594a5.724 5.724 0 0 0 1.85 1.961c1.02.684 2.12 1.218 3.29 1.583M1519.96 502.124a19.226 19.226 0 0 0-15.08-4.251M1489.86 498.587c-3.24-.207-6.46.588-9.23 2.276M1506.21 539.289a24.175 24.175 0 0 1-13.89 2.516"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1510.05 513.079c-.78 0-1.41-1.084-1.41-2.422s.63-2.423 1.41-2.423c.78 0 1.41 1.085 1.41 2.423s-.63 2.422-1.41 2.422ZM1486.45 513.079c-.78 0-1.41-1.084-1.41-2.422s.63-2.423 1.41-2.423c.78 0 1.41 1.085 1.41 2.423s-.63 2.422-1.41 2.422Z"
            />
            <path
                fill="#7A5430"
                d="M1574.43 482.071c-7.52-16.811-20.79-31.852-47.55-33.622-28.55-1.888-100.57-.645-45.99 62.42.12-.374.24-.743.35-1.101.2-.627.39-1.234.57-1.827.72-2.403.23-4.96-.3-7.668a25.049 25.049 0 0 1-.67-7.009c.5-5.763 4.08-12.98 10.21-19.07l.9 1.25a.2.2 0 0 1 .05.056c10.27 14.7 25.2 33.804 45.1 42.395.6.256.1 1.036.23.963.61-.337 1.01-7.08 6.49-11.338 10.52-8.183 21.49 8.961 7.92 22.912a18.59 18.59 0 0 1-8.32 5.005c.06 3.844.53 7.671 1.4 11.415a2.51 2.51 0 0 0 4.73.359c2.6-6.202 5.75-12.16 9.4-17.804 20.79 3.926 23.67-29.032 15.48-47.336Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1573.08 524.291c7.39-9.53 7.07-29.444 1.35-42.219-7.52-16.811-20.79-31.852-47.55-33.622-17.3-1.143-50.56-1.146-61.97 13.052"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1537.33 518.86c.61-.337 1.01-7.08 6.49-11.338 10.52-8.183 21.49 8.961 7.92 22.912a18.645 18.645 0 0 1-8.32 5.005"
            />
            <path
                fill="url(#K)"
                d="M1457.51 892.072c-.12-5.601.15-14.389-.14-19.174 12 .029 93.58.018 114.58.018 28.91-5.247 53.67-32.892 47.02-66.289-7.19-36.098-45.98-45.883-80.82-39.723-18.96 3.353-38.86 7.805-56.49 12.687-17.1 4.96-36.53 13.61-52.52 22.337-14.97 7.682-29.55 19.158-30.68 38.023-1.64 43.349-3.58 93.225-4.78 136.341l48.2 3.094s16.47-50.174 15.63-87.314Z"
            />
            <path
                fill="#FCB47F"
                d="M1555.56 637.358c3.94 6.803 7.52 13.811 11.25 20.731a180.989 180.989 0 0 0 12.51 20.008c4.55 6.45 9.28 12.796 14.27 18.998a225.932 225.932 0 0 0 15.94 18.029 19.17 19.17 0 0 0 31.23-6.181 19.14 19.14 0 0 0 .84-12.271 223.932 223.932 0 0 0-7.57-22.848c-2.85-7.425-5.96-14.704-9.25-21.88a180.747 180.747 0 0 0-11.01-20.87c-4.11-6.706-8.37-13.323-12.27-20.146a26.5 26.5 0 0 0-48.57 6.197 26.482 26.482 0 0 0 2.57 20.119l.06.114Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1609.92 673.498c6.08 0 11-4.788 11-10.694 0-5.906-4.92-10.695-11-10.695-6.07 0-11 4.789-11 10.695 0 5.906 4.93 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M1609.92 665.11c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.309a2.305 2.305 0 0 0 2.3 2.309Z"
            />
            <path
                fill="#FCB47F"
                d="m1605.54 704.332-.45 13.517-.6 13.514c-.36 9.01-.31 18.029.14 27.057.25 4.514.39 9.026.74 13.542l.46 6.773c.16 2.258.39 4.518.58 6.776.81 9.035 1.79 18.074 3.33 27.124a12.241 12.241 0 0 0 4.06 7.205 12.257 12.257 0 0 0 7.73 2.964c2.84.061 5.61-.871 7.84-2.634a12.173 12.173 0 0 0 4.36-7.025c1.93-8.977 3.29-17.966 4.48-26.958.29-2.249.61-4.496.87-6.745l.75-6.748c.54-4.497.87-8.999 1.31-13.499.83-9 1.26-18.008 1.29-27.026l-.02-13.527.12-13.525a18.51 18.51 0 0 0-5.22-13.047 18.51 18.51 0 0 0-12.89-5.613 18.518 18.518 0 0 0-13.11 5.061 18.502 18.502 0 0 0-5.77 12.814Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1555.49 637.242.07.115c3.94 6.803 7.52 13.811 11.25 20.731a181.069 181.069 0 0 0 12.51 20.007c4.55 6.45 9.28 12.796 14.26 18.998 3.12 3.855 6.31 7.671 9.66 11.391M1642.22 701.857c.03-1.75-.18-3.496-.62-5.19a223.868 223.868 0 0 0-7.57-22.849c-2.86-7.424-5.96-14.704-9.25-21.879a182.23 182.23 0 0 0-11.01-20.87c-4.11-6.706-10.57-16.527-14.47-23.35M1633.73 813.14c1.92-8.978 3.28-17.967 4.48-26.959.29-2.249.61-4.496.86-6.745l.76-6.748c.54-4.497.87-8.999 1.31-13.498.83-9 1.26-18.009 1.29-27.027l-.02-13.527-.19-16.777M1605.31 711.156l-.82 20.206c-.36 9.011-.32 18.029.14 27.057.25 4.514.39 9.026.74 13.542l.46 6.774c.16 2.258.39 4.517.58 6.775.81 9.035 1.79 18.074 3.33 27.125"
            />
            <path
                fill="#FCB47F"
                d="M1598.94 848.89a48.707 48.707 0 0 0-.75-6.813 38.697 38.697 0 0 1 1.49-15.861 38.469 38.469 0 0 1 7.8-13.895c10.71-12.747 22.94-13.499 26.69 1.627 1.46 9.565 2.42 19.248 1.68 28.884-.74 9.635-3.24 19.263-8.34 27.688-.23.402-.51.772-.83 1.1-.3.292-.69.478-1.11.529-.29.019-.58-.048-.83-.194a1.42 1.42 0 0 1-.58-.623l-.23-.309c-.9 2.33-1.88 4.637-2.86 6.94-.25.425-.63.762-1.09.967-.45.205-.96.269-1.45.184a2.558 2.558 0 0 1-1.29-.571 2.487 2.487 0 0 1-.79-1.174l-.23-.426c-.02.641-.02 1.282.03 1.921 0 .267-.05.532-.14.78-.1.248-.25.473-.44.663a1.992 1.992 0 0 1-1.44.589l-.04.001c-.45.023-.89-.099-1.26-.348a2.05 2.05 0 0 1-.81-1.035c-1.71-5.209-1.54-10.837-1.03-16.288.16-1.708.35-3.42.52-5.132l-.38.66a46.527 46.527 0 0 0-2.76 16.316c0 .191-.03.38-.1.557-.07.177-.18.339-.31.474-.13.135-.29.242-.47.314-.18.072-.37.107-.56.104a3.584 3.584 0 0 1-1.78-.678 3.62 3.62 0 0 1-1.2-1.495 21.863 21.863 0 0 1-2.41-10.556c.02-3.089.28-6.17.77-9.22l-.27.185a4.7 4.7 0 0 1-2.72 1.537 2.864 2.864 0 0 1-2.77-1.046 9.107 9.107 0 0 0 2.29-6.356Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1607.48 812.32a38.481 38.481 0 0 0-7.8 13.896 38.697 38.697 0 0 0-1.49 15.861c.41 2.251.66 4.527.76 6.812a9.163 9.163 0 0 1-2.3 6.358M1603.27 831.672a91.53 91.53 0 0 1-.27 18.428c-.53 4.56-1.41 9.103-1.36 13.69-.06 3.662.77 7.283 2.41 10.556a4.01 4.01 0 0 0 1.9 1.672c.79.337 1.68.407 2.51.198l.03-.007M1611.8 845.352c.78 5.93-.16 11.921-.71 17.873-.51 5.451-.68 11.079 1.03 16.289.15.422.43.785.81 1.034.37.249.82.371 1.26.349l.04-.002a1.977 1.977 0 0 0 1.44-.589c.19-.189.34-.415.44-.663.1-.247.14-.513.14-.78-.06-3.461.43-6.91 1.44-10.221 1.02-3.947 3.39-15.108 3.08-23.004M1621.07 877.961c1.92-4.293 3.6-8.692 5.01-13.177a59.917 59.917 0 0 0 2.36-20.19M1627.51 870.518c5.1-8.425 7.6-18.053 8.34-27.689.74-9.636-.22-19.318-1.68-28.884"
            />
            <path
                fill="url(#L)"
                d="M1563.56 652.992c.16-.001.33-.041.47-.118l47.48-25.336c.12-.064.23-.153.32-.26.08-.107.14-.231.18-.363.03-.132.04-.269.02-.405a.885.885 0 0 0-.14-.381l-.94-1.52c-2.86-4.6-5.82-9.357-8.58-14.184a27.5 27.5 0 0 0-16.65-12.873 27.505 27.505 0 0 0-20.87 2.657 27.496 27.496 0 0 0-12.87 16.65 27.525 27.525 0 0 0 2.65 20.876l.07.115c2.75 4.766 5.37 9.692 7.89 14.455l.09.154a.948.948 0 0 0 .59.488c.09.029.19.044.29.045Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1186.58 772.565c0 4.416-3.58 7.995-7.99 7.995h-19.83c-4.42 0-8-3.579-8-7.995v-35.096h35.82v29.659"
            />
            <path
                fill="#C0F0FF"
                d="M1182.85 759.016v13.55a4.267 4.267 0 0 1-4.27 4.264h-19.83a4.265 4.265 0 0 1-4.26-4.264v-13.55h28.36Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1067.54 772.565c0 4.416-3.58 7.995-7.99 7.995h-19.83c-4.42 0-8-3.579-8-7.995v-35.096h35.82v29.659"
            />
            <path
                fill="#C0F0FF"
                d="M1063.81 759.016v13.55a4.267 4.267 0 0 1-4.27 4.264h-19.82a4.267 4.267 0 0 1-4.27-4.264v-13.55h28.36Z"
            />
            <path
                fill="#5BC2E7"
                d="M1067.93 639.094h16.74c.71 0 1.29.577 1.29 1.289v7.33h-19.32v-7.33c0-.712.58-1.289 1.29-1.289Z"
            />
            <path
                fill="url(#M)"
                d="M1058.94 663.098a30.06 30.06 0 0 0-6.44 18.586v29.852c0 .796.3 1.564.84 2.151 3.98 4.339 3.98 11.052 0 15.392a3.174 3.174 0 0 0-.84 2.15v41.308a8.025 8.025 0 0 0 8.03 8.027h31.55c4.43 0 8.03-3.594 8.03-8.027v-41.308c0-.796-.3-1.564-.84-2.15-3.98-4.34-3.98-11.052 0-15.392.54-.586.84-1.354.84-2.151v-29.852a30.06 30.06 0 0 0-6.44-18.586h-34.73Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1067.93 639.094h16.74c.71 0 1.29.577 1.29 1.289v7.33h-19.32v-7.33c0-.712.58-1.289 1.29-1.289Z"
            />
            <path fill="#5BC2E7" d="M1100.11 681.684h-47.61v24.995h47.61v-24.995Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1063.21 768.531h26.2M1063.21 758.184h26.2M1063.21 747.844h26.2M1063.21 737.508h26.2M1100.11 745.162v-13.937c0-.796-.3-1.563-.84-2.15-3.98-4.339-3.98-11.052 0-15.391.54-.587.84-1.354.84-2.151v-29.852a30.06 30.06 0 0 0-8.47-20.91l-6.68-6.904c-.58-.599-.91-1.4-.91-2.234v-3.922h-15.5v3.922c0 .834-.32 1.635-.9 2.234l-2.16 2.228M1058.94 663.098a30.069 30.069 0 0 0-6.44 18.588v29.853c0 .796.3 1.564.84 2.15 3.98 4.34 3.98 11.052 0 15.392a3.174 3.174 0 0 0-.84 2.15v41.308a8.025 8.025 0 0 0 8.03 8.027h31.55c4.43 0 8.03-3.594 8.03-8.027v-10.756"
            />
            <path
                fill="#888B8D"
                d="M1816.72 786.806c2.7 0 5.3-1.6 6.4-4.3 1.5-3.6-.1-7.7-3.7-9.2l-23.9-10.1c-3.6-1.5-7.7.1-9.2 3.7-1.5 3.6.1 7.7 3.7 9.2l23.9 10.1c1 .4 1.9.6 2.8.6ZM1941.52 786.806c2.7 0 5.3-1.6 6.4-4.3 1.5-3.6-.1-7.7-3.7-9.2l-23.9-10.1c-3.6-1.5-7.7.1-9.2 3.7-1.5 3.6.1 7.7 3.7 9.2l23.9 10.1c1 .4 1.9.6 2.8.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1741.43 1044.81c-3.98 0-7.2-4.3-7.2-9.6 0-5.31 3.22-9.6 7.2-9.6 3.97 0 7.19 4.29 7.19 9.6 0 5.3-3.22 9.6-7.19 9.6Z"
            />
            <path
                fill="#A9ACAC"
                d="M1751.62 1079.31c-18.17 0-32.9-19.75-32.9-44.1 0-24.36 14.73-44.105 32.9-44.105 18.17 0 32.9 19.745 32.9 44.105 0 24.35-14.73 44.1-32.9 44.1Z"
            />
            <path
                fill="url(#N)"
                d="M1847.33 1035.2c0-72.095-41.4-131.195-94.1-136.895 2.8-.3 5.59-.5 8.39-.5 56.6 0 102.5 61.5 102.5 137.395 0 75.9-45.9 137.4-102.5 137.4-2.8 0-5.59-.2-8.39-.5 52.6-5.7 94.1-64.8 94.1-136.9Z"
            />
            <path
                fill="url(#O)"
                d="M1749.22 878.805c2.1 0 4.1.1 6.2.2 2.1-.1 4.1-.2 6.2-.2 64.5 0 116.7 70 116.7 156.395 0 86.4-52.3 156.4-116.7 156.4-2.1 0-4.1-.1-6.2-.2-2.1.1-4.1.2-6.2.2-64.5 0-116.7-70-116.7-156.4 0-86.395 52.2-156.395 116.7-156.395Zm12.4 293.795c56.6 0 102.5-61.5 102.5-137.4 0-75.895-45.9-137.395-102.5-137.395-2.8 0-5.6.2-8.4.5-52.7 5.7-94.1 64.8-94.1 136.895 0 72.1 41.4 131.2 94.1 136.9 2.7.3 5.5.5 8.4.5Z"
            />
            <path
                fill="#FFB33B"
                d="m1798.52 948.703.3-.8c.8-1.9 3.2-2.5 4.8-1.1 10.7 9.1 19.6 21.7 25.9 36.5.6 1.3.1 2.8-1 3.6-1.6 1.1-3.8.5-4.6-1.2-6-13.6-14.5-25.1-24.6-33.4-1-.9-1.3-2.3-.8-3.6ZM1694.83 1084.1c1.6-1.1 3.8-.5 4.6 1.2 6.1 13.5 14.5 24.8 24.5 33 1 .9 1.4 2.3.8 3.5l-.3.8c-.8 1.9-3.2 2.5-4.8 1.2-10.6-9.1-19.5-21.4-25.8-36.1-.6-1.3-.1-2.8 1-3.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1732.42 1002.7c-6.3 8.3-10.2 19.8-10.2 32.5 0 23.9 13.8 43.6 31.2 45.5.9.1 1.9.2 2.8.2 18.8 0 34-20.4 34-45.6M1783.22 1007.41c-6.2-10.805-16-17.805-27-17.805"
            />
            <path
                fill="url(#P)"
                d="M1668.03 870.403h87.59c0-5.4-4.39-9.7-9.69-9.7h-68.1c-5.5 0-9.8 4.4-9.8 9.7Z"
            />
            <path
                fill="#D9D9D6"
                d="M1643.43 961.804h11v-68.2c0-4.9 6.5-12.1 15.4-12.1h92.29v-11h-92.29c-15.1 0-26.4 12.2-26.4 23.1v68.2ZM1886.22 1126.4c3 0 5.5-2.5 5.5-5.5V845.703c0-22 3.4-43.9 10.2-64.8.8-2.6 2.7-4.7 5.2-6 2.5-1.2 5.3-1.4 7.9-.4 2.9 1 6-.5 7-3.3 1-2.9-.5-6-3.3-7-1.2-.4-2.5-.8-3.7-1-4.3-.7-8.7-.1-12.7 1.9-5.2 2.5-9 7-10.8 12.5-7.1 22.1-10.7 45-10.7 68.2V1121c-.1 2.9 2.4 5.4 5.4 5.4Z"
            />
            <path
                fill="#D9D9D6"
                d="M1761.52 1126.4c3 0 5.5-2.5 5.5-5.5V845.703c0-22 3.4-43.9 10.2-64.8.8-2.6 2.7-4.7 5.2-6 2.5-1.2 5.3-1.4 7.9-.4 2.9 1 6-.5 7-3.3 1-2.9-.5-6-3.3-7-5.4-1.9-11.3-1.6-16.4.9-5.2 2.5-9 7-10.8 12.5-7.1 22.1-10.7 45-10.7 68.2V1121c-.1 2.9 2.3 5.4 5.4 5.4ZM1891.72 1120.91c0 3-2.5 5.5-5.5 5.5s-5.5-2.5-5.5-5.5V896.605h11v224.305ZM1886.52 795.504h11.3c-4 16.4-6.1 33.3-6.1 50.2v50.9h-11v-50.9c0-16.9 2-33.7 5.8-50.2Z"
            />
            <path
                fill="#D9D9D6"
                d="M1767.02 1120.91c0 3-2.5 5.5-5.5 5.5s-5.5-2.5-5.5-5.5V896.605h11v224.305Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1654.43 917.405v-23.7c0-4.9 6.5-12.1 15.4-12.1h92.29v-11h-92.29c-15.1 0-26.4 12.2-26.4 23.1v62"
            />
            <path
                fill="url(#Q)"
                d="M1628.52 961.605h249.4c3.5 0 6.4-2.9 6.4-6.4 0-3.5-2.9-6.4-6.4-6.4h-249.4c-3.5 0-6.4 2.9-6.4 6.4 0 3.6 2.9 6.4 6.4 6.4Z"
            />
            <path
                fill="url(#R)"
                d="M1649.22 1159.6c0-11.2-6.7-20.3-15-20.6l7.1-9.8c9.5 4.2 16.4 16.3 16.4 30.4 0 15.6-8.3 28.6-19.3 31.5l-3.6-10.9c8.1-.8 14.4-9.7 14.4-20.6Z"
            />
            <path
                fill="url(#S)"
                d="M1634.32 1173.7c-5.79 0-10.5-6.31-10.5-14.1 0-7.78 4.71-14.1 10.5-14.1 5.8 0 10.5 6.32 10.5 14.1 0 7.79-4.7 14.1-10.5 14.1Z"
            />
            <path
                fill="url(#T)"
                d="M1631.32 1127.5h5.5c13.2 0 23.9 14.4 23.9 32.1 0 17.7-10.7 32.1-23.9 32.1h-5.4c-13.2 0-23.9-14.4-23.9-32.1-.1-17.7 10.6-32.1 23.8-32.1Zm20.7 32.1c0-11.4-6.9-20.6-15.4-20.6-.4 0-.8 0-1.3.1-7.9.9-14.1 9.7-14.1 20.6 0 10.8 6.2 19.7 14.1 20.6.4 0 .8.1 1.3.1 8.5-.2 15.4-9.4 15.4-20.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1635.72 1145h-.9c-5.6.6-10 6.9-10 14.5s4.4 13.9 10 14.5h.9c6 0 10.9-6.5 10.9-14.6"
            />
            <path
                fill="#D9D9D6"
                d="M1636.72 1126.4h126.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-120.8V972.305h244c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-249.5c-3 0-5.5 2.5-5.5 5.5V1120.9c0 3 2.5 5.5 5.5 5.5Z"
            />
            <path
                fill="#D9D9D6"
                d="M1765.52 1040.7h119.5c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-119.5c-3 0-5.5 2.5-5.5 5.5s2.4 5.5 5.5 5.5ZM1761.52 1126.4h156.8c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-156.8c-3 0-5.5 2.5-5.5 5.5s2.4 5.5 5.5 5.5ZM1612.62 1062.3c3 0 5.5 2.5 5.5 5.5v4.1h17.11c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5h-17.2v13.8h17.2c3 0 5.5 2.5 5.5 5.5s-2.5 5.5-5.5 5.5h-17.2v18.4l23.09 31-7.29 5.8-24.71-31.6c-1.29-2.3-2-4.9-2-7.6V1068c0-3.3 2.4-5.7 5.5-5.7Z"
            />
            <path
                fill="#D9D9D6"
                d="m1733.72 1129.8 24.7 33.8 9.5-8-24.8-34.5v-52.8c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1v52.9c.1 3.1 1 6.1 2.8 8.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1637.43 1151.9c-3.1 0-5.7 3.4-5.7 7.6 0 4.2 2.6 7.6 5.7 7.6s5.69-3.4 5.69-7.6"
            />
            <path
                fill="#D9D9D6"
                d="M1739.42 1082.8h19.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-19.3c-3 0-5.5 2.5-5.5 5.5s2.4 5.5 5.5 5.5ZM1739.42 1107.61h19.3c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-19.3c-3 0-5.5 2.5-5.5 5.5s2.4 5.5 5.5 5.5ZM1688.12 1164.51c1.9 0 3.8-1 4.8-2.8l20.3-36.8c1.5-2.7.5-6-2.2-7.5s-6-.5-7.5 2.2l-20.3 36.8c-1.5 2.7-.5 6 2.2 7.5.9.4 1.8.6 2.7.6ZM1631.52 1164.51c1.9 0 3.8-1 4.8-2.8l20.3-36.8c1.5-2.7.5-6-2.2-7.5s-6-.5-7.5 2.2l-20.3 36.8c-1.5 2.7-.5 6 2.2 7.5.9.4 1.8.6 2.7.6Z"
            />
            <path
                fill="url(#U)"
                d="M1580.93 1166h74.19c2.71 0 5-2.2 5-5 0-2.7-2.2-5-5-5h-74.19c-2.7 0-5 2.2-5 5 .1 2.7 2.3 5 5 5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1891.72 859.103v-13.4c0-22 3.4-43.9 10.2-64.8.8-2.6 2.7-4.7 5.2-6 2.5-1.2 5.3-1.4 7.9-.4 2.9 1 6-.5 7-3.3M1880.72 1040.7v74.6M1880.72 972.305v41.595"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1915.22 763.202c-4.4-.8-8.9-.2-13 1.8-5.2 2.5-9 7-10.8 12.5-7.1 22.1-10.7 45-10.7 68.2v91.9M1767.02 845.703c0-22 3.4-43.9 10.2-64.8.8-2.6 2.7-4.7 5.2-6 2.5-1.2 5.3-1.4 7.9-.4 2.9 1 6-.5 7-3.3M1767.02 1029.6v-37.397M1767.02 1115.31v-74.2M1756.02 1107.61v7.8M1756.02 1082.8v13.8M1756.02 972.805v98.995"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1789.82 763.103c-4.2-.6-8.4.1-12.3 2-5.2 2.5-9 7-10.8 12.5-7.1 22.1-10.7 45-10.7 68.2v97.2M1880.72 961.305h-102.3M1880.72 1029.7h-113.8M1767.02 1040.7h113.8M1880.72 1115.4h-101.8M1747.22 1126.4h171.1c3 0 5.5-2.5 5.5-5.5s-2.5-5.5-5.5-5.5h-18.7M1732.02 1126.4h-19.6m168.3-154.095h-238.5V1115.4h82.4m-93.4-43.6V966.805c0-3 2.5-5.5 5.5-5.5h130.8m-144 188.295-14.4-18.6c-1.3-2.3-2-4.9-2-7.6v-55.7c0-3 2.5-5.5 5.5-5.5s5.5 2.5 5.5 5.5v4.1m25 54.6h-6.4c-3 0-5.5-2.5-5.5-5.5v-13.3h-13.2v18.4l11.2 15m70.5-14.6h-43.9m-24.6-29.8v-13.8h-13.2v13.8"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1756.02 1107.6h-12.9v13.5l24.8 34.5-9.5 8-24.7-33.8c-1.8-2.5-2.8-5.4-2.8-8.5v-52.9c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1v3.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1756.02 1082.8h-12.9v13.8M1688.12 1164.5c1.9 0 3.8-1 4.8-2.8l19.5-35.4M1699.82 1126.5l-9.9 18M1636.32 1161.7l19.5-35.4M1643.12 1126.4l-16.39 30M1814.02 786.202c.9.4 1.8.6 2.7.6 2.7 0 5.3-1.6 6.4-4.3 1.5-3.6-.1-7.7-3.7-9.2l-23.9-10.1c-1.7-.7-3.5-.7-5.1-.1"
            />
            <path
                fill="url(#V)"
                d="M1892.72 897.604v-51.9c0-16.8 2-33.6 6-50l.3-1.2h-138.1l-.2.8c-3.8 16.5-5.8 33.5-5.8 50.5v51.9h137.8v-.1Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1938.82 786.204c.9.4 1.8.6 2.7.6 2.7 0 5.3-1.6 6.4-4.3 1.5-3.6-.1-7.7-3.7-9.2l-23.9-10.1c-1.8-.8-3.7-.7-5.4 0"
            />
            <path
                fill="#716E72"
                d="M1624.12 1166h74.2c2.7 0 5-2.2 5-5 0-2.7-2.2-5-5-5h-74.2c-2.7 0-5 2.2-5 5 0 2.7 2.2 5 5 5Z"
            />
            <path
                fill="url(#W)"
                d="M1763.03 1191.7c-17.73 0-32.1-14.37-32.1-32.1 0-17.72 14.37-32.1 32.1-32.1 17.72 0 32.09 14.38 32.09 32.1 0 17.73-14.37 32.1-32.09 32.1Z"
            />
            <path
                fill="#fff"
                d="M1763.03 1180c-11.27 0-20.4-9.13-20.4-20.4 0-11.26 9.13-20.4 20.4-20.4 11.26 0 20.4 9.14 20.4 20.4 0 11.27-9.14 20.4-20.4 20.4Z"
            />
            <path
                fill="url(#X)"
                d="M1763.02 1174.11c-8 0-14.5-6.5-14.5-14.5 0-8.01 6.5-14.5 14.5-14.5 8.01 0 14.5 6.49 14.5 14.5 0 8-6.49 14.5-14.5 14.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1771.42 1171.41c-2.4 1.7-5.3 2.7-8.4 2.7-8 0-14.5-6.5-14.5-14.5s6.5-14.5 14.5-14.5 14.5 6.5 14.5 14.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1763.03 1167.2c-4.2 0-7.6-3.4-7.6-7.6 0-4.19 3.4-7.6 7.6-7.6 4.19 0 7.59 3.41 7.59 7.6 0 4.2-3.4 7.6-7.59 7.6Z"
            />
            <path
                fill="#FCB47F"
                d="M1732.25 664.854c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.7-18.8 19.3-6.1 6.7-12 13.499-17.5 20.899-7.6 10.3-5.5 24.8 4.8 32.5 7.1 5.3 16.2 5.901 23.6 2.401 8.3-3.9 16.2-8.301 24-12.901 7.7-4.6 15.3-9.5 22.7-14.5 7.4-5 14.6-10.399 21.3-16.199 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5h-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1738.15 740.552c4.4-3.3 8.8-6.7 12.9-10.3 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5l-.1.1c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.7-18.8 19.3-4.6 5-9.1 10.1-13.4 15.5"
            />
            <path
                fill="url(#Y)"
                d="m1769.85 644.051-2.7 1c-13.1 4.7-23.8 10.3-32.8 17.3-.8.6-5.9 5.4-5.8 6.4.2 4.6 16.9 8.2 19.3 9.6l.9.5 21.1-34.8Z"
            />
            <path
                fill="url(#Z)"
                d="M1716.85 675.553c9.3-6.4 17.3-16.1 28.9-18.2 20.4-4.5 40.6 14 37.9 34.7-.9 15.8-15.3 24.1-25.9 33.6-6.2 5.4-12.5 10.8-19.1 15.7-1.5 1.1-56.8-35.3-56.8-35.3 10.8-12 28.6-25.8 35-30.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1861.93 860.584c.6-9.551 9-41.648 16.1-67.345 6.6-23.925 10.3-48.54 10.9-73.351l.5-21.858c.4-15.359-5.9-29.636-18.1-39.284-16.3-12.898-31.8-20.184-49.9-27.766-32.2 15.36-42.1 16.148-56 13.588-2.1 1.378-4.3 2.756-6.4 4.332-26 18.707-46 58.779-52.2 80.932-3.2 11.52-2.8 23.433 8.2 29.34-14 27.569-25 48.245-14 101.412"
            />
            <path
                fill="url(#aa)"
                d="M1861.93 860.584c.6-9.551 9-41.648 16.1-67.345 6.6-23.925 10.3-48.54 10.9-73.351l.5-21.858c.4-15.359-5.9-29.636-18.1-39.284-16.3-12.898-31.8-20.184-49.9-27.766-32.2 15.36-42.1 16.148-56 13.588-2.1 1.378-4.3 2.756-6.4 4.332-26 18.707-46 58.779-52.2 80.932-3.2 11.52-2.8 23.433 8.2 29.34-14 27.569-25 48.245-14 101.412"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1715.43 707.086c-3.9 8.467-6.8 16.344-8.6 22.744-3.2 11.519-2.8 23.433 8.2 29.34-14 27.568-25 48.244-14 101.411M1861.93 860.583c.6-9.55 9-41.647 16.1-67.345 6.6-23.925 10.3-48.539 10.9-73.351l.5-19.1c.8-23.63-9.7-36.331-21.7-44.897-12.4-8.861-23-15.261-41.3-22.941"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1714.83 759.37c3.6-6.105 7.7-12.701 11.1-16.64M1824.03 760.552c-31.1 3.742-56.5-1.181-71.7-21.759M1723.93 836.853c-3.6-1.28-7.1-1.379-10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M1862.82 861.961c12.6 21.365 8.8 54.447-4.2 69.806-18.1 21.563-83.7 14.868-103.7 15.261-31.5.69-19.9-40.564-19.9-40.564s-24.8-11.52-33.5-44.503"
            />
            <path
                fill="url(#ab)"
                d="M1862.82 861.961c12.6 21.365 8.8 54.447-4.2 69.806-18.1 21.563-83.7 14.868-103.7 15.261-31.5.69-19.9-40.564-19.9-40.564s-24.8-11.52-33.5-44.503"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1862.82 861.961c12.6 21.365 8.8 54.447-4.2 69.806-18.1 21.563-83.7 14.868-103.7 15.261-31.5.69-19.9-40.564-19.9-40.564s-24.8-11.52-33.5-44.503M1683.73 898.488h-15.5"
            />
            <path
                fill="#FCB47F"
                d="M1643.02 947.807s-12.1-7.778-22.8-11.717l-3.2-1.181c-6.7-2.462-12.5-7.089-16.4-13.686-8.9-15.064-3.7-34.46 11.6-43.223 9.5-5.415 19.1-9.846 28.7-13.981 4.8-2.067 9.7-4.037 14.5-5.907 4.8-1.969 9.7-3.84 14.6-5.514 9.8-3.249 19.7-5.415 29.7-7.187 2.5-.394 5-.886 7.5-1.182 2.5-.295 5-.689 7.5-.886l3.8-.394 3.8-.295c2.5-.197 5.1-.197 7.6-.197 22.1.492 42.4 14.67 49.4 36.528 8.8 27.47-6.8 56.81-34.7 65.474l-.5.197c-4.6 1.379-9.2 2.462-13.9 3.249h-61.2c-.5 0-25.6 0-26-.098Z"
            />
            <path
                fill="#FCB47F"
                d="M1651.23 1103.08c1.1-8.37 2.09-16.74 2.89-25.01.4-4.14.9-8.37 1.2-12.51l1.1-12.5c.7-8.37 1.4-16.64 2.4-25.01.9-8.37 2.2-16.74 2.8-25.01 1.4-16.635-.79-32.979-3.59-49.225-1.5-8.073-3.31-16.245-5.71-24.22-.6-1.97-1.19-4.037-1.79-6.006-.7-1.969-1.31-4.037-2.11-6.006-1.5-4.037-3.1-7.975-5-11.914l-5-10.141c-6.4-12.898-22.19-18.214-35.19-11.913-10.6 5.12-16.1 16.245-14.5 27.174l7.39 48.835 3.7 24.418 3.91 24.418c2.6 16.24 5.1 32.49 7.8 48.83l8 48.74c1.4 8.57 9.59 14.38 18.29 13 7.1-1.09 12.4-6.7 13.3-13.49l.11-2.46Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1603.02 883.711c12.4-7.877 28.3-15.557 37.9-19.692 4.8-2.067 9.7-4.037 14.5-5.907 4.8-1.969 9.7-3.84 14.6-5.514 9.8-3.249 19.7-5.415 29.7-7.187 2.5-.394 5-.886 7.5-1.182 2.5-.295 5-.689 7.5-.886l3.8-.394 3.8-.295c2.5-.197 5.1-.197 7.6-.197 22.1.492 42.4 14.67 49.4 36.528 8.8 27.47-6.8 56.81-34.7 65.474l-.5.197c-4.6 1.379-9.2 2.462-13.9 3.249h-61.2c-.2 0-5.8-.098-11.8-.098M1650.82 1105.64l.3-2.56c1.1-8.37 2.1-16.74 2.9-25.01.4-4.14.9-8.37 1.2-12.51l1.1-12.5c.7-8.37 1.4-16.64 2.4-25.01.9-8.37 2.2-16.74 2.8-25.01 1.4-16.637-.8-32.981-3.6-49.226-.1-.69-.3-1.379-.4-2.068M1603.02 883.711c-10.6 5.12-16.1 16.245-14.5 27.174l7.4 48.835 3.7 24.418 3.9 24.422c2.6 16.24 5.1 32.49 7.8 48.83l8 48.74"
            />
            <path
                fill="#FCB47F"
                d="M1651.52 1103.87c-.3 4.23 1.4 20.48 2.5 31.02.6 5.02-3.3 9.55-8.5 9.84l-20.3.99c-4.3.19-8.6.98-12.7 2.56-4.3 1.57-11.2 3.54-20.3 4.03-4.1.2-19.1-1.18-26.3-1.87a4.05 4.05 0 0 1-3.7-4.23c.1-1.58 1.1-2.96 2.5-3.65 3.1-1.27 8.6-3.54 13-5.11 19.9-7.29 41.3-31.91 41.3-31.91"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1651.52 1103.87c-.3 4.23 1.4 20.48 2.5 31.02.6 5.02-3.3 9.55-8.5 9.84l-20.3.99c-4.3.19-8.6.98-12.7 2.56-4.3 1.57-11.2 3.54-20.3 4.03-4.1.2-19.1-1.18-26.3-1.87a4.05 4.05 0 0 1-3.7-4.23c.1-1.58 1.1-2.96 2.5-3.65 3.1-1.27 8.6-3.54 13-5.11 19.9-7.29 41.3-31.91 41.3-31.91M1613.23 898.488h-15.5"
            />
            <path
                fill="#EF0025"
                d="M1564.43 1141.58c2.9-1.18 8.5-3.54 13-5.12 7.8-2.86 15.8-8.37 22.7-14.08 2.4-1.97 4.6-3.94 6.7-5.81 3.4-.1 13.9 5.71 19.7 6.11 11.5.69 20.1-3.65 27-3.15.4 4.33.9 9.45 1.6 15.35.3 2.76-.5 5.42-2.3 7.49-1.8 2.07-4.3 3.35-7.1 3.44l-20.3.99c-4.3.19-8.5 1.08-12.4 2.46-4.3 1.57-11.3 3.54-20.6 4.04h-1.1c-5.1 0-18.1-1.19-25.3-1.97-2.7-.3-4.7-2.56-4.6-5.22-.1-1.97 1.2-3.74 3-4.53Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1652.73 1142.17c-1.8 2.07-4.3 3.35-7.1 3.45l-20.3.98c-4.3.2-8.5 1.09-12.4 2.46-4.3 1.58-11.3 3.55-20.6 4.04h-1.1c-5.1 0-18.1-1.18-25.3-1.97"
            />
            <path
                fill="#001489"
                d="M1717.03 840.498c27.7-3.545 53.1 10.239 61.9 36.823 9 28.159-7.2 58.287-35.9 66.853-4.3 1.378-9 2.461-14.1 3.347h-40.1s-4.3-17.427-12.4-46.964c-8.7-31.605-31.6-38.989-31.6-38.989 9.1-5.218 49.8-19.002 72.2-21.07Z"
            />
            <path
                fill="#FCB47F"
                d="M1750.42 546.902c-4.4 10.043-7.6 23.827-7.6 34.756 0 10.929 1.9 21.857 4.7 32.392 1.6 6.006 2.7 12.701 6.2 17.723 2.5 3.643 7.8 5.12 12.3 4.726.7-.099 1.3-.099 2-.197-.4 1.28-.8 11.421-.8 12.209l8.6 6.104 46.7-17.722-2.6-11.422-.4-12.799s1.1-8.369 9.1-23.63c10.1-19.199-3.1-37.217-3.1-37.217s-2.8-10.929-18.5-17.722c-22.4-9.452-40.9-2.659-56.6 12.799Z"
            />
            <path
                fill="url(#ac)"
                d="M1743.52 570.822c2-.196 4-.393 6-.689 2-.197 4.1-.394 5.7.788 1.2.886 1.8 2.363 1.9 3.741.1 1.477.4 2.954.1 4.332-2.2 12.701-1.5 21.563.9 24.123.5.59 1 1.279 1.1 1.969.1.787-.1 1.477-.2 2.264-.9 4.135-1.8 8.369-2.7 12.504-.5 2.265-.8 4.628-1.1 6.892-.4 3.84.6 7.188 4.6 9.255-6.6-1.28-13.7-7.581-15.2-10.338-9.5-17.82.9-36.626-1.1-54.841Z"
            />
            <path
                fill="url(#ad)"
                d="M1767.22 646.928c.2-3.643.4-7.285.5-10.928 14.5 1.181 30.2-11.422 36.6-18.117.9-.984-2.2 13.587-5.3 23.729-7.3 3.249-22.8 8.565-31.8 5.316Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1804.32 617.1c-9.3 11.322-23.7 18.018-38.4 19.396-5.6.591-13.7-1.871-18.2-6.498-7.2-7.582-9.7-22.35-6.1-36.43 1.2-4.824 2.2-7.384 2.1-11.125 0-2.856-.8-6.597-.4-9.649 1.1-8.861 1.3-18.412 8.9-26.978 25.7-29.045 60.1-12.405 69-1.476 8.2 10.042 12.1 23.432 9.7 36.035-1.6 8.566-5.8 16.442-9.5 24.319-3.9 8.467-1.8 20.775.7 29.537"
            />
            <path
                fill="#FCB47F"
                d="M1756.72 575.945c-.2 5.809-2.5 11.421-5.7 16.246-1 1.477-2.1 2.954-2.2 4.726-.1 1.772 1 3.643 2.6 4.529 2.5 1.28 5 1.871 6.9 2.166"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1756.72 575.945c-.2 5.809-2.5 11.421-5.7 16.246-1 1.477-2.1 2.954-2.2 4.726-.1 1.772 1 3.643 2.6 4.529 2.5 1.28 5 1.871 6.9 2.166"
            />
            <path
                stroke="url(#ae)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1742.33 572.497c2.5-1.181 6.2-1.772 8.79-2.067"
            />
            <path
                stroke="url(#af)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1764.63 570.531c6.4 0 13.1.689 17.3 2.462"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1747.73 587.467c-.78 0-1.4-1.014-1.4-2.265 0-1.251.62-2.264 1.4-2.264.77 0 1.39 1.013 1.39 2.264s-.62 2.265-1.39 2.265ZM1773.33 587.467c-.78 0-1.4-1.014-1.4-2.265 0-1.251.62-2.264 1.4-2.264.77 0 1.4 1.013 1.4 2.264s-.63 2.265-1.4 2.265Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1752.33 611.191c7.6 1.181 15.4.492 22.7-1.871"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1735.83 791.069c5-7.286 9.2-14.769 13.3-22.251 4.1-7.582 7.8-15.261 11.4-22.941 3.6-7.68 6.8-15.556 9.3-23.63 2.6-8.073 5-16.147 7.9-24.122"
            />
            <path
                fill="#FCB47F"
                d="M1691.63 1103.87c-.3 4.23 1.4 20.48 2.5 31.02.6 5.02-3.3 9.55-8.5 9.84l-20.3.99c-4.3.19-8.6.98-12.7 2.56-4.3 1.57-11.2 3.54-20.3 4.03-4.1.2-19.1-1.18-26.3-1.87a4.05 4.05 0 0 1-3.7-4.23c.1-1.58 1.1-2.96 2.5-3.65 3.1-1.27 8.6-3.54 13-5.11 19.9-7.29 41.3-31.91 41.3-31.91"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1691.63 1103.87c-.3 4.23 1.4 20.48 2.5 31.02.6 5.02-3.3 9.55-8.5 9.84l-20.3.99c-4.3.19-8.6.98-12.7 2.56-4.3 1.57-11.2 3.54-20.3 4.03-4.1.2-19.1-1.18-26.3-1.87a4.05 4.05 0 0 1-3.7-4.23c.1-1.58 1.1-2.96 2.5-3.65 3.1-1.27 8.6-3.54 13-5.11 19.9-7.29 41.3-31.91 41.3-31.91"
            />
            <path
                fill="url(#ag)"
                d="M1604.52 1141.58c2.9-1.18 8.5-3.54 13-5.12 7.9-2.85 16.2-8.66 23.2-14.57 2.9-2.46 5.6-4.92 8-7.09 4.7 1.38 11.7 9.75 18.4 9.85 13.1.1 22.3-5.71 26.4-6.01.5 5.32 1.1 11.22 1.7 16.15.3 2.75-.5 5.41-2.3 7.48-1.8 2.07-4.3 3.35-7.1 3.45l-20.3.98c-4.3.2-8.5 1.08-12.4 2.46-4.3 1.58-11.3 3.55-20.6 4.04h-1.1c-5.1 0-18.1-1.18-25.3-1.97-2.7-.3-4.7-2.56-4.6-5.22-.1-1.87 1.2-3.64 3-4.43Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1692.82 1142.17c-1.8 2.07-4.3 3.35-7.1 3.44l-20.3.99c-4.3.2-8.5 1.08-12.4 2.46-4.3 1.58-11.3 3.54-20.6 4.04h-1.1c-5.1 0-18.1-1.18-25.3-1.97-2.7-.3-4.7-2.56-4.6-5.22"
            />
            <path
                fill="url(#ah)"
                d="M1874.13 885.199c5.2-41.451 5.2-61.93 5.2-81.72v-11.027c6.5-23.531 10.1-49.13 10.7-76.206l.5-18.806c.4-15.359-6.4-30.226-18.5-39.58-25.7-19.888-49.6-27.469-49.6-27.469s-12.4 13.193-32.3 21.365l-24.5 18.412c-1.5-5.416 1.5-23.532 1.5-23.532s.2-3.249.3-4.726c-2.3 1.28-6.8 4.332-9.1 6.006-27.2 19.593-46.6 60.355-52.5 81.523-2.1 7.68-2.4 14.276-.6 19.495 3 9.058 2.2 19.888-2.3 30.522-2.3 5.612-8.6 20.479-10.1 39.284-1.2 13.883 2.1 32.787 8.1 47.063l.4.985.9-.394c5.7-2.56 29.7-7.286 62-7.286 31.6 0 76.1 4.627 108.4 26.682l1.4.886.1-1.477Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1763.73 674.203c-1.8 1.772-7.3 11.914-13 23.236M1822.33 630.684c-7 7.975-22.3 17.919-32.2 21.266l-24.6 18.707-8-19.199-7.3 6.498"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1790.13 651.951 9.4 12.898 21-22.251"
            />
            <path
                fill="url(#ai)"
                d="M1716.13 947.127c-2.7-.591-5.3-1.181-8-1.871-10.7-2.56-7-5.021-17.9-9.156-5.6-2.166-8.2-6.301-12.1-11.224-11.4-14.572-8.6-35.445 6.2-46.669l.8-.591c9.4-7.089 19.1-12.209 28.9-16.639 4.9-2.166 9.8-4.136 14.7-6.006 4.9-1.773 9.9-3.348 14.9-4.529 5-1.182 10.1-2.166 15.1-3.151 5.1-.886 10.1-1.871 15.2-2.461 2.5-.296 5.1-.69 7.6-.985 2.6-.295 5.1-.591 7.7-.788l3.8-.295 3.9-.197c2.6-.098 5.2-.197 7.8-.098l9.7.295c27.6.689 51.1 21.267 54.5 49.032 1.5 12.602-1.4 24.713-7.4 34.854-8.1 13.39-23 21.365-38.8 21.365h-102.4l-4.2-.886Z"
            />
            <path
                fill="#FCB47F"
                d="M1690.13 1111.65c2.5-8.27 4.9-16.64 7.1-24.91 1.1-4.14 2.3-8.37 3.4-12.5l3.2-12.51c2.2-8.37 4.2-16.64 6.6-25.01 2.3-8.37 5-16.64 7-25.01 4.2-16.635 4.8-33.569 4.8-50.603-.1-8.467-.5-17.033-1.4-25.599-.2-2.166-.5-4.233-.8-6.399-.3-2.166-.6-4.332-1-6.4-.7-4.332-1.7-8.566-2.9-12.898l-3.1-10.535c-4-13.784-18.6-21.759-32.6-17.821-11.4 3.249-18.9 13.39-19.1 24.516l-1 50.903-.5 25.402-.4 25.404c-.2 16.93-.5 33.87-.7 50.9l-.5 50.9c-.1 8.67 7 15.76 15.8 15.85 7.3.1 13.4-4.62 15.4-11.22l.7-2.46Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1862.33 926.641c-8.1 13.39-23.9 21.365-39.7 21.365h-102.4l-4.1-.886M1684.23 878.203l.8-.591c9.4-7.089 19.1-12.209 28.9-16.639 4.9-2.166 9.8-4.136 14.7-6.006 4.9-1.772 9.9-3.348 14.9-4.529 5-1.182 10.1-2.166 15.1-3.151 5.1-.886 10.1-1.871 15.2-2.461 2.5-.296 5.1-.69 7.6-.985 2.6-.295 5.1-.591 7.7-.788l3.8-.295 3.9-.197c2.6-.098 5.2-.197 7.8-.098l9.7.295M1689.33 1114.01l.7-2.36c2.5-8.27 4.9-16.64 7.1-24.91 1.1-4.14 2.3-8.37 3.4-12.51l3.2-12.5c2.2-8.37 4.2-16.64 6.6-25.01 2.3-8.37 5-16.64 7-25.01 4.2-16.638 5.3-40.563 4.7-59.073M1684.23 878.207c-14.7 5.612-22.9 16.541-23.1 27.667l-1 50.902-.5 25.402-.4 25.402c-.2 16.94-.5 33.87-.7 50.9l-.5 50.91"
            />
            <path
                fill="url(#aj)"
                d="M1746.43 949.001h76.1c11.5 0 22.4-4.135 30.7-11.224 2.4-1.576 4.5-3.348 6.2-5.317 12.8-15.163 20.7-51.001 4.3-70.89-11.3-13.685-32.4-19.396-49.3-19.691-6.5-.197-14.9-.493-21.4 0-12.9.886-25.1 2.658-37.9 5.12-14.2 2.363-28.2 6.892-41.6 12.996 0 0 25.7 2.068 35 33.771 6 20.676-2.1 55.235-2.1 55.235Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1683.73 898.488h-15.5"
            />
            <path
                fill="#FCB47F"
                d="m1626.95 626.55 1.3-1.2c-.7-2.5 1.1-5.1 3-6.8 5.2-4.9 12.6-7.3 19.6-6.5 5.4.6 10.5 3 15.3 5.6 7.1 3.9 13.9 8.3 20.3 13.3 11.2 7.3 4.7 21.1-12.4 23.2-11.6 1.4-24.1-11.2-28.7-18.2l-3.2.2c-2.2.3-4.4.5-6.4 1.6-1.9 1-3.5 3-3.5 5.2 0 1.2.5 2.3.8 3.4.2.8.2 1.6 0 2.3-.2.7-.9 1.1-1.6 1.1-1.3-.1-2.5-.8-3.4-1.7-.6-.6-1-1.3-1.3-2.1l-.9.2c-1 .3-2.2-.2-2.9-1-.7-.8-1.1-1.8-1.4-2.8-1.3-5.8 1.3-11.8 5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1674.34 653.552c-12.8 2.4-27.4-13.9-30.3-19.9M1686.45 630.852c-6.4-5-13.2-9.4-20.3-13.3-4.8-2.6-9.8-5-15.3-5.6-7.1-.8-14.4 1.7-19.6 6.5-1.9 1.8-3.6 4.3-3 6.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1642.34 619.25c-5.6 1.3-11.2 3.4-15.4 7.4-4.2 4-6.7 10-5.3 15.6.3 1 .7 2.1 1.4 2.8.7.8 1.9 1.2 2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1639.34 626.352c-4 .3-7.9 2.4-10.4 5.6-2.5 3.2-3.5 7.5-2.8 11.5.3 1.7.9 3.3 2.1 4.5.9 1 2.1 1.599 3.4 1.699.7.1 1.4-.4 1.6-1.1.2-.7.2-1.5 0-2.3-.3-1.1-.8-2.199-.8-3.399-.1-2.2 1.5-4.201 3.5-5.201 1.9-1 4.2-1.3 6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M1854.65 658.053c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-8.3 2.3-16.6 4.8-24.8 8-12 4.7-17.9 18.2-13.2 30.1 3.1 8.1 10.3 13.4 18.3 14.6 8.7 1.3 17.4 1.9 26 2.2 8.6.3 17.2.4 25.8.2 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5 17-1.9 29.3-17.2 27.4-34.2-1.9-17-17.2-29.3-34.2-27.4h-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1771.05 680.853-9.6-6.301-9.5-6.399c-6.4-4.2-12.9-8.301-19.5-12.101-3.3-1.9-6.6-3.899-10-5.799-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2-7.9-3.7-17.2-.301-20.9 7.599-2.9 6.2-1.4 13.301 3.1 17.801 2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.099 8.6 7.699 2.9 2.6 5.8 5.101 8.7 7.601 2.9 2.5 5.9 4.899 8.8 7.399 5.9 4.9 11.9 9.601 18.1 14.101l9.3 6.8 9.2 6.8c10 7.4 24.1 5.299 31.5-4.701 7.4-10 5.3-24.1-4.7-31.5-.1-.2-.5-.499-.8-.699Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1804.35 720.651c-6.08 0-11-3.94-11-8.8 0-4.86 4.92-8.8 11-8.8 6.07 0 11 3.94 11 8.8 0 4.86-4.93 8.8-11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1804.35 713.753c-1.27 0-2.3-.851-2.3-1.9s1.03-1.9 2.3-1.9c1.27 0 2.3.851 2.3 1.9s-1.03 1.9-2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1854.85 658.055h-.2c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-4.6 1.3-9.2 2.6-13.7 4.1M1745.25 717.754c3.2 2.5 9.8 4.3 13.8 5 8.7 1.3 17.4 1.9 26 2.2 8.6.3 17.2.4 25.8.2 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5M1761.55 674.553l-9.5-6.4c-6.4-4.2-12.9-8.3-19.5-12.1-3.3-1.9-6.6-3.9-10-5.8-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2M1674.15 659.453c2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.1 8.6 7.7 2.9 2.6 5.8 5.1 8.7 7.6 2.9 2.5 5.9 4.9 8.8 7.4 5.9 4.9 11.9 9.6 18.1 14.1l9.3 6.8 9.2 6.8"
            />
            <path
                fill="url(#ak)"
                d="M1829.15 659.349c2.6-.2 5.1-.4 7.7-.6 5.8-.5 11.9-.9 17.8-1.6h.1c1.2-.1 2.4-.2 3.6-.2 16.3 0 30 12.2 31.8 28.5.9 8.5-1.5 16.8-6.8 23.5-5.3 6.7-13 10.9-21.5 11.8-5.9.7-11.9 1.5-17.7 2.4-2.5.4-5.1.7-7.6 1.1-1 .1-2.1.3-3.1.4l-4.7-65.2c.1-.1.2-.1.4-.1Z"
            />
            <path
                fill="url(#al)"
                d="M1830.85 718.95c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.3c.9 1.7 3 2.2 4.7 1.1l60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3Z"
            />
            <path
                fill="url(#am)"
                d="m1761.35 752.85 60.7-37.9c1.3-.8 2.7-.7 3.7 0l4 2.9c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.299c.2.5.6.801.9 1.101l-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.8-1.6-.1-3.9 1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1784.45 793.248 60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1780.75 793.248-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.9-1.7-.2-3.9 1.6-5l60.7-37.901c1.3-.8 2.7-.7 3.7 0l4 2.901c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                fill="#231F20"
                d="M1840.55 753.149a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1836.85 746.45a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M1813.88 754.127c-2.2-1.27-2.77-4.394-1.28-6.976 1.49-2.583 4.48-3.647 6.68-2.377 2.2 1.27 2.78 4.394 1.29 6.977-1.5 2.582-4.49 3.647-6.69 2.376Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1834.25 757.051a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1668.25 777.248 8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2 8.7-1.3 14.6-9.3 13.4-18-.9-6.4-5.6-11.3-11.4-12.9-5.7-1.6-11.4-3-17-4.2-5.7-1.2-11.4-2.3-17.1-3.3-1.4-.3-2.9-.5-4.3-.7-1.4-.2-2.9-.4-4.3-.6-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.5-8.6-.8l-8.6-.8c-12.4-1.2-23.4 7.8-24.6 20.2-1.2 12.4 7.8 23.4 20.2 24.6.6.2 1.2.2 1.7.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1658.75 733.75c-5.5 5-11.6 11.8-12.3 18.8-1.2 12.4 7.8 23.4 20.2 24.6.5 0 1 .1 1.5.1l8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2M1739.65 743.748c-5.7-1.6-11.4-3.001-17-4.201-5.7-1.2-11.4-2.299-17.1-3.299-1.4-.3-2.9-.501-4.3-.701-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.799"
            />
            <path
                fill="#FCB47F"
                d="M1798.35 789.348c-.3-.6-.6-1.3-.9-1.9l.8-.901c.9-1 1.3-2.299 1.3-3.599-.1-3.7-.9-8.101-2.1-11.201l1.5-.899c3.5-1.7 6.4-5.001 6.7-8.801.3-4.2-2.4-8-5.4-11-5.8-5.8-12.7-13.3-20.9-12.5-10.6 1-21.1 2.7-31.5 5.1-19.5 4.1-23.7 19.401-11.3 30.201 5.5 5 14.3 6.099 20.9 2.699 5.6 5.2 13.3 13.1 16.8 20.7.8 1.8 2.8 2.7 4.6 2h.1c2-.7 3-2.8 2.3-4.7-3.3-9.6-11.1-19.699-17.3-27.099 9.1 7.7 20.2 17.599 29.5 25.599 1.2 1 2.9 1 4.1 0 1.1-1 1.4-2.499.8-3.699Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1744.25 744.547c10.4-2.3 24.5-5 35.1-6 8.2-.8 15.1 6.7 20.9 12.5 3 3 5.7 6.8 5.4 11-.3 3.8-3.2 7.101-6.7 8.801"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1778.65 752.047c5.9 4 11.9 8.2 15.9 14.1 3.4 5 5.1 11.2 4.9 17.2M1773.95 761.348c4.8 3.9 9.6 7.699 13.7 12.199 3.6 3.9 8.3 10.301 10.7 15.801.5 1.2.2 2.7-.8 3.6-1.2 1-2.9 1-4.1 0-9.3-8-20.4-17.9-29.5-25.6 6.3 7.4 14 17.5 17.3 27.1.7 1.9-.4 4.099-2.3 4.699h-.1c-1.8.6-3.8-.3-4.6-2.1-3.4-7.5-11.1-15.399-16.8-20.599-6.6 3.4-15.5 2.3-20.9-2.7M1788.35 755.949c1.7 1.1 3.4 2.1 5.2 3.2.7.5 1.5 1 1.8 1.8.2.6 0 1.3-.3 1.8-.3.6-.8 1-1.2 1.5"
            />
            <path
                fill="#D9D9D6"
                d="M1767.53 961.804h11v-68.2c0-4.9 6.5-12.1 15.4-12.1h92.3v-11h-92.3c-15.1 0-26.4 12.2-26.4 23.1v68.2Z"
            />
            <path
                fill="#40C1E9"
                d="M1793.13 870.403h87.6c0-5.4-4.4-9.7-9.7-9.7h-68.1c-5.5 0-9.8 4.4-9.8 9.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1778.53 961.302v-67.6c0-4.9 6.5-12.1 15.4-12.1h92.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1886.23 870.504h-92.3c-15.1 0-26.4 12.2-26.4 23.1v67.6M1887.83 1045.31c-3.98 0-7.2-4.3-7.2-9.6 0-5.31 3.22-9.6 7.2-9.6 3.97 0 7.2 4.29 7.2 9.6 0 5.3-3.23 9.6-7.2 9.6Z"
            />
            <path
                fill="url(#an)"
                d="M1898.13 1079.81c-18.17 0-32.9-19.75-32.9-44.1 0-24.36 14.73-44.105 32.9-44.105 18.17 0 32.9 19.745 32.9 44.105 0 24.35-14.73 44.1-32.9 44.1Z"
            />
            <path
                fill="url(#ao)"
                d="M1993.73 1035.7c0-72.095-41.4-131.195-94.1-136.895 2.8-.3 5.6-.5 8.4-.5 56.6 0 102.5 61.5 102.5 137.395 0 75.9-45.9 137.4-102.5 137.4-2.8 0-5.6-.2-8.4-.5 52.7-5.8 94.1-64.9 94.1-136.9Z"
            />
            <path
                fill="url(#ap)"
                d="M1895.63 879.305c2.1 0 4.1.1 6.2.2 2.1-.1 4.1-.2 6.2-.2 64.5 0 116.7 70 116.7 156.395 0 86.4-52.3 156.4-116.7 156.4-2.1 0-4.1-.1-6.2-.2-2.1.1-4.1.2-6.2.2-64.5 0-116.7-70-116.7-156.4 0-86.395 52.3-156.395 116.7-156.395Zm12.4 293.695c56.6 0 102.5-61.5 102.5-137.4 0-75.895-45.9-137.395-102.5-137.395-2.8 0-5.6.2-8.4.5-52.7 5.7-94.1 64.8-94.1 136.895 0 72.1 41.4 131.2 94.1 136.9 2.8.4 5.6.5 8.4.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1908.03 1045.31c-3.98 0-7.2-4.3-7.2-9.6 0-5.31 3.22-9.6 7.2-9.6 3.97 0 7.2 4.29 7.2 9.6 0 5.3-3.23 9.6-7.2 9.6Z"
            />
            <path
                fill="#FFB33B"
                d="m1945.03 949.203.3-.8c.8-1.9 3.2-2.5 4.8-1.1 10.7 9.1 19.6 21.7 25.9 36.5.6 1.3.1 2.8-1 3.6-1.6 1.1-3.8.5-4.6-1.2-6-13.6-14.5-25.1-24.6-33.4-1-.9-1.4-2.4-.8-3.6ZM1841.33 1084.5c1.6-1.1 3.8-.5 4.6 1.2 6.1 13.5 14.5 24.8 24.5 33 1 .9 1.4 2.3.8 3.5l-.3.8c-.8 1.9-3.2 2.5-4.8 1.2-10.6-9.1-19.5-21.4-25.8-36.1-.6-1.2-.2-2.8 1-3.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1929.73 1007.8c-6.2-10.796-16-17.796-27-17.796-.9 0-1.9.1-2.8.2-17.5 1.9-31.2 21.496-31.2 45.496 0 24 13.8 43.6 31.2 45.5.9.1 1.9.2 2.8.2 18.8 0 34-20.4 34-45.6"
            />
            <path
                fill="#7A5430"
                d="M1807.4 526.253c-.3-3.4-3.6-5.9-7-6.4s-6.7.6-10 1.6c1.5-1.7.4-4.5-1.5-5.6-1.9-1.1-4.3-.8-6.5-.4-13.9 2.7-25.2 8.9-32.1 21.2 0 0-3.7 8.6-2 13.2.5-1.2 2.2-3.2 2.5-3 4.7 2.9 11.1 2.4 16.2.3 17.1-7.1 29.9-9 32.7-5.4 5.2 6.6-7.3 13.1-8.8 19.4-1.3 5.3 2 9.6 5.2 14 3.2 4.5 1 10.6 1 15h5.3c2.7-6.4 7-10.1 12.5-10.6 2.2-.2 4.3.9 5.3 2.8 2 3.9 1.6 9.5-.5 13.6-2.3 4.5-4.8 8.3-9.2 10.9.2 3.6.5 7.2 1.1 10.7.6 3.8 5.7 4.6 7.5 1.2 3.2-6.2 6.4-12.4 9.5-18.8 4.7-9.9 8.4-20.4 8.6-31.4.2-8.1.2-36.9-29.8-42.3Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1828.6 600.153c4.7-9.9 8.4-20.4 8.6-31.4.2-8.4.2-37.1-29.8-42.5-.3-3.4-3.6-5.9-7-6.4s-6.7.6-10 1.6c1.5-1.7.4-4.5-1.5-5.6-1.9-1.1-4.3-.8-6.5-.4-13.9 2.7-25.2 8.9-32.1 21.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1805.6 585.251c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <defs>
                <linearGradient
                    id="c"
                    x1={681.051}
                    x2={746.082}
                    y1={797.505}
                    y2={797.505}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={785.051}
                    x2={816.018}
                    y1={797.505}
                    y2={797.505}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={753.266}
                    x2={883.488}
                    y1={1020.68}
                    y2={890.456}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.413} stopColor="#FFF4C3" />
                    <stop offset={0.453} stopColor="#FFEC96" />
                    <stop offset={0.544} stopColor="#FFD828" />
                    <stop offset={0.576} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={728.681}
                    x2={866.176}
                    y1={797.501}
                    y2={840.677}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.698} stopColor="#FFEA8B" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={847.944}
                    x2={884.519}
                    y1={673.638}
                    y2={673.638}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={831.02}
                    x2={997.169}
                    y1={712.248}
                    y2={712.248}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={865.804}
                    x2={865.804}
                    y1={643.721}
                    y2={576.524}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={824.446}
                    x2={834.683}
                    y1={649.609}
                    y2={632.17}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={876.21}
                    x2={862.423}
                    y1={578.024}
                    y2={578.024}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={853.901}
                    x2={831.565}
                    y1={578.237}
                    y2={578.237}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={725.526}
                    x2={928.511}
                    y1={768.141}
                    y2={768.141}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={858.891}
                    x2={653.45}
                    y1={925.537}
                    y2={857.933}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={943.629}
                    x2={1003.39}
                    y1={1150.79}
                    y2={1150.79}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={744.798}
                    x2={968.929}
                    y1={979.125}
                    y2={979.125}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.039} stopColor="#888B8D" />
                    <stop offset={0.954} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={876.713}
                    x2={977.019}
                    y1={725.663}
                    y2={725.663}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={881.348}
                    x2={977.427}
                    y1={728.792}
                    y2={728.792}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={758.978}
                    x2={794.618}
                    y1={614.935}
                    y2={720.503}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={1404.58}
                    x2={1241.32}
                    y1={765.479}
                    y2={761.981}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={965.072}
                    x2={965.072}
                    y1={1167.38}
                    y2={753.933}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.007} stopColor="#FFD101" />
                    <stop offset={0.698} stopColor="#FFEA8B" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={1509.71}
                    x2={1509.71}
                    y1={916.955}
                    y2={1196.37}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.41} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={1422.11}
                    x2={1597.32}
                    y1={890.191}
                    y2={890.191}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.287} stopColor="#FFF4C3" />
                    <stop offset={0.331} stopColor="#FFEC96" />
                    <stop offset={0.433} stopColor="#FFD828" />
                    <stop offset={0.468} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={1385.12}
                    x2={1300.7}
                    y1={1062.39}
                    y2={1062.39}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={1499.33}
                    x2={1437.25}
                    y1={546.975}
                    y2={539.49}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={1487.95}
                    x2={1434.34}
                    y1={544.278}
                    y2={537.814}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={1474.51}
                    x2={1524.82}
                    y1={586.768}
                    y2={675.683}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={1467.45}
                    x2={1517.77}
                    y1={590.761}
                    y2={679.676}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1619.12}
                    x2={1430.66}
                    y1={695.578}
                    y2={695.578}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1428.13}
                    x2={1343.71}
                    y1={1062.68}
                    y2={1062.68}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1527.99}
                    x2={1514.96}
                    y1={579.136}
                    y2={557.444}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.216} stopColor="#FCB47F" />
                    <stop offset={0.433} stopColor="#F8B07C" />
                    <stop offset={0.667} stopColor="#EBA473" />
                    <stop offset={0.909} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1488.03}
                    x2={1488.03}
                    y1={562.127}
                    y2={498.246}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1395.16}
                    x2={1607.67}
                    y1={805.459}
                    y2={954.216}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#0B86B8" />
                    <stop offset={1} stopColor="#004F71" />
                </linearGradient>
                <linearGradient
                    id="L"
                    x1={1589.38}
                    x2={1569.43}
                    y1={599.652}
                    y2={659.508}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.006} stopColor="#64CCC9" />
                    <stop offset={1} stopColor="#BBE9E9" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1123.83}
                    x2={1057.86}
                    y1={854.717}
                    y2={674.888}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1808.64}
                    x2={1808.64}
                    y1={1172.58}
                    y2={897.881}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1843.4}
                    x2={1627.64}
                    y1={1147.86}
                    y2={871.703}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#221E20" />
                    <stop offset={0.596} stopColor="#5E5B5E" />
                    <stop offset={0.999} stopColor="#89868A" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1755.6}
                    x2={1668}
                    y1={865.57}
                    y2={865.57}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#0197D6" />
                    <stop offset={0.019} stopColor="#0298D6" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1884.34}
                    x2={1622.14}
                    y1={955.212}
                    y2={955.212}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.469} stopColor="#0197D6" />
                    <stop offset={0.617} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="R"
                    x1={1646.03}
                    x2={1646.03}
                    y1={1190.86}
                    y2={1142.24}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={1634.3}
                    x2={1634.3}
                    y1={1173.65}
                    y2={1145.52}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="T"
                    x1={1633.98}
                    x2={1633.98}
                    y1={1190.86}
                    y2={1142.24}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={1623.23}
                    x2={1565.94}
                    y1={1161.03}
                    y2={1160.57}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#221E20" />
                    <stop offset={0.596} stopColor="#5E5B5E" />
                    <stop offset={0.999} stopColor="#89868A" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={1875.29}
                    x2={1731.19}
                    y1={846.05}
                    y2={846.05}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#0197D6" />
                    <stop offset={0.019} stopColor="#0298D6" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="W"
                    x1={1795.1}
                    x2={1730.98}
                    y1={1159.59}
                    y2={1159.59}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="X"
                    x1={1777.55}
                    x2={1748.53}
                    y1={1159.59}
                    y2={1159.59}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="Y"
                    x1={1769.84}
                    x2={1728.55}
                    y1={661.441}
                    y2={661.441}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="Z"
                    x1={1783.94}
                    x2={1623.27}
                    y1={699.073}
                    y2={699.073}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="ab"
                    x1={1899.47}
                    x2={1603.12}
                    y1={904.855}
                    y2={904.855}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.453} stopColor="#E19A6D" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </linearGradient>
                <linearGradient
                    id="ac"
                    x1={1750.21}
                    x2={1750.21}
                    y1={636.168}
                    y2={570.008}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ad"
                    x1={1791.56}
                    x2={1781.56}
                    y1={641.965}
                    y2={624.659}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ae"
                    x1={1739.8}
                    x2={1753.59}
                    y1={571.488}
                    y2={571.488}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="af"
                    x1={1762.11}
                    x2={1784.45}
                    y1={571.701}
                    y2={571.701}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1={1695.24}
                    x2={1601.35}
                    y1={1134.01}
                    y2={1134.01}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="ah"
                    x1={1890.49}
                    x2={1692.57}
                    y1={758.673}
                    y2={758.673}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="ai"
                    x1={1696.82}
                    x2={2011.41}
                    y1={916.794}
                    y2={852.169}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="aj"
                    x1={1716.81}
                    x2={1903.28}
                    y1={871.023}
                    y2={937.993}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="ak"
                    x1={1817.11}
                    x2={1886.5}
                    y1={585.374}
                    y2={769.277}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="al"
                    x1={1847.17}
                    x2={1763.47}
                    y1={755.545}
                    y2={755.545}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="am"
                    x1={1829.89}
                    x2={1759.45}
                    y1={753.831}
                    y2={753.831}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="an"
                    x1={1898.1}
                    x2={1898.1}
                    y1={1079.74}
                    y2={991.615}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="ao"
                    x1={1955.08}
                    x2={1955.08}
                    y1={1173.02}
                    y2={898.324}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="ap"
                    x1={1901.84}
                    x2={1901.84}
                    y1={1068.51}
                    y2={831.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.746} stopColor="#ABADAD" />
                </linearGradient>
                <radialGradient
                    id="i"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(766.401 908.259) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="u"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 401.972 301.247) scale(13.842)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={0.194} stopColor="#D3D3D0" />
                    <stop offset={0.446} stopColor="#C2C3C2" />
                    <stop offset={0.729} stopColor="#A8AAAA" />
                    <stop offset={0.999} stopColor="#888B8D" />
                </radialGradient>
                <radialGradient
                    id="G"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-159.078 819.342 190.458) scale(77.211 37.1881)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.437} stopColor="#64CCC9" stopOpacity={0} />
                    <stop offset={0.554} stopColor="#64CCC9" stopOpacity={0.258} />
                    <stop offset={0.746} stopColor="#64CCC9" stopOpacity={0.655} />
                    <stop offset={0.882} stopColor="#64CCC9" stopOpacity={0.904} />
                    <stop offset={0.949} stopColor="#64CCC9" />
                </radialGradient>
                <radialGradient
                    id="aa"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(-255.204 0 0 -251.268 1849.61 896.629)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <clipPath id="a">
                    <path fill="#fff" d="M1829.9 225h358.589v358.589H1829.9z" />
                </clipPath>
                <clipPath id="b">
                    <path fill="#fff" d="M485.898 781h377.49v374.55h-377.49z" />
                </clipPath>
                <filter
                    id="F"
                    width={91.356}
                    height={77.894}
                    x={1470.63}
                    y={629.609}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_18373"
                        stdDeviation={4}
                    />
                </filter>
            </defs>
        </svg>
    );
}
