import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-2">
        <path
            d="m464.971 167.793 2.55 1.203c12.537 5.916 22.676 12.593 30.997 20.411a2.674 2.674 0 0 1 .861 2.435c-.698 4.602-13.337 10.971-15.864 12.202l-.904.439-17.64-36.69Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-2-a)"
        />
        <path
            d="M591.868 331.164c-2.601 0-5.262-.04-7.909-.118-5.067-.121-10.398-.378-17.276-.833a135.73 135.73 0 0 1-4.316-.314c-1.59-.126-2.936-.242-4.3-.396-1.866-.169-3.758-.428-5.588-.68-.975-.134-1.951-.267-2.929-.388-1.765-.243-3.513-.509-5.261-.775a868.092 868.092 0 0 0-3.233-.486l-8.477-1.195a23.56 23.56 0 0 1-13.829-7.16 24.08 24.08 0 0 1-1.869-1.595 255.979 255.979 0 0 1-17.885-19.358c-5.352-6.358-10.615-13.032-16.092-20.407a204.933 204.933 0 0 1-14.282-21.579 886.983 886.983 0 0 1-3.848-6.751c-2.904-5.121-5.907-10.416-9.037-15.505a31.983 31.983 0 0 1-3.871-24.184 32.004 32.004 0 0 1 38.548-23.709 32.002 32.002 0 0 1 19.837 14.364l.081.13c3.106 5.105 6.452 10.189 9.688 15.106 1.425 2.165 2.846 4.334 4.261 6.505a205.18 205.18 0 0 1 12.71 22.547c4.067 8.227 7.613 15.951 10.836 23.609 2.272 5.446 4.071 10.025 5.638 14.357 1.35.272 2.7.547 4.047.837.983.225 1.954.435 2.924.645 1.797.388 3.655.789 5.479 1.273 1.411.34 2.838.727 4.166 1.095 1.331.354 2.734.751 4.16 1.179 6.509 1.893 11.76 3.522 16.517 5.125 2.608.862 5.191 1.76 7.749 2.695-6.794 11.922-8.222 27.916-6.638 35.966h-.001Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-2-b)"
        />
        <path
            d="M464.773 249.129c1.279 2.255 2.561 4.505 3.849 6.751a204.777 204.777 0 0 0 14.282 21.579c5.476 7.375 10.74 14.049 16.091 20.407a256.17 256.17 0 0 0 17.885 19.358 24.46 24.46 0 0 0 1.869 1.595 23.56 23.56 0 0 0 13.829 7.16l8.478 1.195c1.078.158 2.155.32 3.232.486 1.748.266 3.496.532 5.261.774.978.121 1.954.255 2.929.389 1.83.251 3.723.511 5.588.68 1.365.153 2.71.27 4.3.396 1.369.123 2.783.225 4.316.314 6.879.455 12.209.712 17.277.833 2.646.078 5.307.118 7.908.118h.002c-1.585-8.051-.157-24.044 6.638-35.967a267.312 267.312 0 0 0-7.749-2.694c-4.757-1.603-10.009-3.232-16.518-5.125a136.729 136.729 0 0 0-4.16-1.179 125.186 125.186 0 0 0-4.166-1.095c-1.823-.484-3.681-.885-5.478-1.273-.971-.21-1.942-.42-2.924-.645-1.348-.29-2.697-.565-4.048-.837-1.567-4.332-3.365-8.911-5.637-14.357-3.223-7.658-6.769-15.383-10.837-23.609a204.917 204.917 0 0 0-12.709-22.547 845.157 845.157 0 0 0-4.262-6.505c-3.236-4.917-6.582-10.001-9.688-15.106l-.08-.13a31.884 31.884 0 0 0-13.204-12.01c-8.043-7.247-17.708-13.512-29.526-19.089"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-2-a"
                x1={564.819}
                y1={332.617}
                x2={469.976}
                y2={159.123}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-2-b"
                x1={564.485}
                y1={332.801}
                x2={469.641}
                y2={159.307}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
