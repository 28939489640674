import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-5-walk-1">
            <path
                fill="#E2E2E2"
                d="M418.405 848.633a5.2 5.2 0 0 1-1.04-.107c-7.995-1.651-21.977-4.635-25.793-6.075a70.758 70.758 0 0 1-18.451-10.136 41.134 41.134 0 0 0-11.055-6.097l-19.068-7.052a9.986 9.986 0 0 1-5.735-5.463 9.987 9.987 0 0 1 .041-7.922c6.863-15.675 11.057-26.031 11.81-29.16l2.434-10.836 33.036 14.892-2.93 7.406c1.482 3.371 13.804 30.638 29.206 42.57 3.795 2.94 8.444 6.832 10.878 8.891a5.151 5.151 0 0 1 1.507 5.708 5.16 5.16 0 0 1-4.84 3.381Z"
            />
            <path
                fill="#63666A"
                d="M418.867 848.895a6.127 6.127 0 0 1-1.862-.09c-8.029-1.628-22.079-4.576-25.965-6.027a71.757 71.757 0 0 1-18.748-10.218 40.105 40.105 0 0 0-10.808-5.91l-18.767-6.866a11.449 11.449 0 0 1-6.508-5.779 10.902 10.902 0 0 1-.131-9.042c6.037-13.923 6.802-15.299 8.111-19.434.267-.844 2.033-3.122 4.446-2.166 0 0 7.373 15.452 23.603 11.086 3.493-1.236 6.92-2.65 10.268-4.237 0 0 2.02-.531 3.986 4.525 1.404 3.613 9.579 22.774 24.754 34.446 3.734 2.872 8.26 6.629 10.749 8.717a6.449 6.449 0 0 1 2.372 4.491 6.163 6.163 0 0 1-1.447 4.354 6.163 6.163 0 0 1-4.053 2.15Z"
            />
            <path
                fill="#E2E2E2"
                d="M373.485 821.022c-11.181-12.149-27.71-17.498-36.033-19.245-.446 1.038-.902 2.093-1.374 3.184a10.899 10.899 0 0 0 .131 9.042 11.443 11.443 0 0 0 6.508 5.778l18.767 6.866a40.16 40.16 0 0 1 10.808 5.91 71.731 71.731 0 0 0 18.748 10.219c3.886 1.451 17.936 4.399 25.965 6.027a6.08 6.08 0 0 0 4.686-.958c-7.529-2.921-37.289-14.96-48.206-26.823ZM448.701 860.228a9.993 9.993 0 0 1-9.983-10.578c.606-10.373.986-24.083.986-24.083s-.899-7.637-1.048-8.891l35.272.072c.152.167 15.274 17.367 23.563 22.948 4.066 2.738 22.165 8.768 27.614 10.55a5.113 5.113 0 0 1 3.457 5.672 5.118 5.118 0 0 1-5.054 4.311l-74.807-.001Z"
            />
            <path
                fill="#63666A"
                d="M529.692 861.618a6.12 6.12 0 0 1-1.773.576c-8.084 1.327-55.521-1.067-59.77-1.017l-19.982.239a11.449 11.449 0 0 1-8.135-3.094 10.898 10.898 0 0 1-3.331-8.407c.705-15.159.932-16.717.689-21.047-.05-.885.794-3.64 3.389-3.603 0 0 12.375 11.831 26 1.991a108.536 108.536 0 0 0 8.097-7.604s1.7-1.213 5.332 2.816c2.595 2.88 17.036 17.894 35.365 23.424 4.51 1.36 10.074 3.267 13.142 4.336a6.462 6.462 0 0 1 3.811 3.357 6.165 6.165 0 0 1-2.834 8.033Z"
            />
            <path
                fill="#E2E2E2"
                d="M477.374 851.66c-14.764-7.392-32.116-6.529-40.516-5.21-.05 1.129-.101 2.277-.157 3.464a10.905 10.905 0 0 0 3.331 8.407 11.44 11.44 0 0 0 8.135 3.094s71.668 2.105 79.752.779a6.098 6.098 0 0 0 2.283-.865 6.103 6.103 0 0 0 1.759-1.693c-8.076-.06-40.172-.758-54.587-7.976Z"
            />
        </g>
    );
}
