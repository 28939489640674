import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-down">
        <path
            d="M499.891 186.79c-9.226-7.122-19.957-12.655-32.552-17.169l19.121 31.543s17.866-10.949 13.431-14.374Z"
            fill="url(#man-34-25-tshirt-4-arm-left-down-a)"
        />
        <path
            d="M481.693 295.852c-3.82-7.255-7.477-14.759-11.18-22.939a205.028 205.028 0 0 1-9.381-24.121 923.411 923.411 0 0 1-2.324-7.401c-1.753-5.626-3.566-11.443-5.548-17.086a31.771 31.771 0 0 1 1.348-24.455 31.788 31.788 0 0 1 18.245-16.339 31.897 31.897 0 0 1 10.6-1.816 32.04 32.04 0 0 1 30.193 21.408l.05.14c1.954 5.652 4.145 11.331 6.265 16.823a1723.84 1723.84 0 0 1 2.785 7.264 204.825 204.825 0 0 1 7.639 24.733c2.23 8.902 4.057 17.205 5.583 25.375l.376 2.073-54.263 17.079-.388-.738Z"
            fill="url(#man-34-25-tshirt-4-arm-left-down-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-down-a"
                x1={482.851}
                y1={125.97}
                x2={488.055}
                y2={372.331}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-down-b"
                x1={491.334}
                y1={125.791}
                x2={496.539}
                y2={372.153}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
