import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_10894_7553)"
                d="M658.268 693.361c-8.146 0-18.557-.107-21.889-.619a70.698 70.698 0 01-20.148-6.101 41.142 41.142 0 00-12.078-3.678l-20.114-2.955a9.92 9.92 0 01-6.744-4.163 9.909 9.909 0 01-1.595-7.755c3.472-16.752 5.433-27.751 5.523-30.973l.379-12.972 36.294 7.074-2.426 10.44c2.322 3.227 19.908 27.114 37.34 35.551 4.3 2.081 9.666 4.934 12.482 6.449a5.172 5.172 0 012.698 4.821 5.162 5.162 0 01-5.119 4.871c-1.313.005-2.886.01-4.603.01z"
            ></path>
            <path
                fill="url(#paint1_linear_10894_7553)"
                d="M665.319 683.617c-2.8-1.517-8.142-4.376-12.464-6.482-7.43-3.622-14.882-10.058-21.162-16.497a161.963 161.963 0 01-6.037-6.555c-3.415-.419-14.414 4.399-20.169 4.191-11.536-.416-19.615-5.62-26.605-5.829a955.596 955.596 0 01-3.112 15.363 9.892 9.892 0 001.575 7.759 9.908 9.908 0 006.733 4.182l20.105 3.008c4.19.609 8.26 1.86 12.068 3.711a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.506 18.088.556 25.361.541a5.163 5.163 0 005.132-4.857 5.165 5.165 0 00-2.686-4.828z"
            ></path>
            <mask
                id="mask0_10894_7553"
                style={{ maskType: 'luminance' }}
                width="94"
                height="42"
                x="575"
                y="652"
                maskUnits="userSpaceOnUse"
            >
                <path
                    fill="#fff"
                    d="M665.319 683.617c-2.8-1.517-8.142-4.376-12.464-6.482-7.43-3.622-14.882-10.058-21.162-16.497a161.963 161.963 0 01-6.037-6.555c-3.415-.419-14.414 4.399-20.169 4.191-11.536-.416-19.615-5.62-26.605-5.829a955.596 955.596 0 01-3.112 15.363 9.892 9.892 0 001.575 7.759 9.908 9.908 0 006.733 4.182l20.105 3.008c4.19.609 8.26 1.86 12.068 3.711a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.506 18.088.556 25.361.541a5.163 5.163 0 005.132-4.857 5.165 5.165 0 00-2.686-4.828z"
                ></path>
            </mask>
            <g fill="#001489" mask="url(#mask0_10894_7553)">
                <path
                    d="M608.759 694.815c-2.192-9.994 7.313-20.465 15.422-24.854a63.88 63.88 0 0116.01-5.846c5.567-1.255 12.898-.695 17.602-4.185 1.465-1.086 1.581-3.772-.554-4.241-3.802-.834-7.755.135-11.576.655a75.534 75.534 0 00-11.813 2.354c-6.348 1.919-12.891 4.271-18.206 8.328-7.841 5.983-18.154 19.789-10.998 29.523a2.258 2.258 0 002.792.879 2.26 2.26 0 001.321-2.613z"></path>
                <path
                    d="M623.468 693.748c-.488-9.09 9.061-15.762 16.114-19.782a42.95 42.95 0 0112.644-4.873c4.802-.977 9.627-.556 14.157-2.606 1.914-.866 1.441-4.182-.596-4.562-11.415-2.135-24.1 2.153-33.484 8.553-8.151 5.559-15.114 13.443-13.462 23.896.414 2.619 4.768 2.008 4.627-.626z"></path>
                <path
                    d="M636.608 694.749c.923-7.876 7.293-11.929 13.928-15.057 7.418-3.497 15.604-3.907 22.999-7.055 2.163-.92 1.53-4.567-.655-5.012-9.503-1.935-21.575 3.337-29.534 8.256-7.089 4.382-11.492 10.284-11.168 18.868.11 2.914 4.105 2.774 4.43 0z"></path>
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M577.345 675.566a9.908 9.908 0 006.733 4.182l20.105 3.009c4.19.608 8.26 1.859 12.068 3.71a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.506 18.088.556 25.361.541"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10894_7553"
                    x1="575.487"
                    x2="667.998"
                    y1="658.752"
                    y2="658.752"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#001489"></stop>
                    <stop offset="0.672" stopColor="#00359C"></stop>
                    <stop offset="1" stopColor="#0047A7"></stop>
                </linearGradient>
                <linearGradient
                    id="paint1_linear_10894_7553"
                    x1="575.552"
                    x2="668.014"
                    y1="672.875"
                    y2="672.875"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.029" stopColor="#0197D6"></stop>
                    <stop offset="0.558" stopColor="#22ADE0"></stop>
                    <stop offset="1" stopColor="#40C1E9"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
