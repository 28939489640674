import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={233}
            height={72}
            fill="none"
            id="object-food-breast-cooked"
            {...attributes}
        >
            <path
                fill="url(#object-food-breast-cooked-a)"
                d="M80.19 1.986c-34.516 0-64.102 17.471-76.553 42.311-6.044 12.06 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M26.504 18.33C16.594 25.315 8.7 34.197 3.637 44.297c-6.044 12.06 2.919 26.254 16.408 26.254h199.407c10.499 0 15.604-12.729 8.096-20.069-28.72-28.075-110.04-48.496-147.357-48.496-8.36 0-16.428 1.025-24.035 2.93M31.004 57.437l36.652-36.652M66.734 57.437l36.653-36.652M102.465 57.437l36.653-36.652"
            />
            <defs>
                <linearGradient
                    id="object-food-breast-cooked-a"
                    x1={65.394}
                    x2={161.125}
                    y1={-7.876}
                    y2={113.294}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </svg>
    );
}
