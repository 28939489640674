import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6569)"
            d="M415.774 257.585c-1.524-4.687-3.098-9.533-4.453-14.352l-.04-.137a27.498 27.498 0 00-34.052-18.941 27.5 27.5 0 00-18.811 34.124c1.379 4.8 2.587 9.744 3.757 14.526.377 1.545.758 3.088 1.143 4.629l52.64-19.277-.184-.572z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6569"
                x1="357.346"
                x2="427.253"
                y1="250.307"
                y2="250.307"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
