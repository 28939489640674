import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#E2E2E2"
                d="M627.674 672.819c-2.938-1.235-8.541-3.557-13.041-5.225-16.98-6.296-35.477-26.135-39.647-30.778 0 0-21.449.363-35.442.428-.208 6.329-1.494 19.049-2.54 28.645a10.008 10.008 0 002.334 7.57 9.989 9.989 0 007.108 3.496l20.304 1.018a41.119 41.119 0 0112.374 2.506 70.73 70.73 0 0020.64 4.146c.318.019.7.027 1.136.027 5.149 0 18.056-1.224 25.292-1.954a5.153 5.153 0 003.975-7.651 5.155 5.155 0 00-2.493-2.228z"
            ></path>
            <path
                fill="url(#paint0_linear_10892_7193)"
                d="M627.531 683.359a6.13 6.13 0 01-1.805.467c-8.149.835-22.441 2.2-26.582 1.97a71.671 71.671 0 01-20.939-4.177 40.138 40.138 0 00-12.077-2.427l-19.96-.972a11.441 11.441 0 01-7.933-3.581 10.895 10.895 0 01-2.816-8.593c1.622-15.089 1.943-16.63 1.963-20.967.004-.886 1.012-3.586 3.6-3.391 0 0 11.636 12.559 25.832 3.562a108.526 108.526 0 008.543-7.1s1.77-1.108 5.151 3.134c2.416 3.032 15.921 18.893 33.882 25.522 4.419 1.631 9.858 3.871 12.855 5.124a6.452 6.452 0 013.601 3.582 6.169 6.169 0 01-3.315 7.847z"
            ></path>
            <path
                fill="#E2E2E2"
                d="M575.912 670.252c-14.289-8.273-31.661-8.462-40.127-7.653-.117 1.123-.238 2.266-.365 3.448a10.884 10.884 0 002.816 8.593 11.44 11.44 0 007.932 3.581s43.537 7.054 52.976 7.576c4.141.23 18.433-1.136 26.582-1.97a6.083 6.083 0 004.19-2.308c-8.058-.549-40.052-3.19-54.004-11.267z"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7193"
                    x1="535.355"
                    x2="631.252"
                    y1="661.921"
                    y2="661.921"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.299" stopColor="#63666A"></stop>
                    <stop offset="1" stopColor="#8E8B8F"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
