import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            d="M499.179 228.726c5.332 6.077 10.973 11.882 16.481 17.804a191.411 191.411 0 0 1 15.391 18.765c4.802 6.545 9.447 13.228 13.874 20.102a239.893 239.893 0 0 1 12.416 21.388 19.13 19.13 0 0 1-4.437 22.94 19.124 19.124 0 0 1-23.316 1.53 240.05 240.05 0 0 1-19.665-14.996c-6.266-5.253-12.315-10.698-18.207-16.282a191.347 191.347 0 0 1-16.689-17.62c-5.187-6.205-10.24-12.528-15.602-18.58a26.498 26.498 0 0 1 2.261-37.408 26.501 26.501 0 0 1 37.408 2.26l.085.097Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M529.588 331.255a240.05 240.05 0 0 1-19.665-14.996c-6.266-5.253-12.316-10.698-18.208-16.282a191.347 191.347 0 0 1-16.689-17.62c-5.187-6.205-10.239-12.528-15.601-18.58a26.498 26.498 0 0 1 2.261-37.408 26.502 26.502 0 0 1 37.408 2.26l.085.096c5.332 6.077 10.972 11.882 16.48 17.805a191.4 191.4 0 0 1 15.391 18.764c4.802 6.545 9.447 13.228 13.874 20.103a239.751 239.751 0 0 1 12.416 21.388"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.069 224.057c-8.491-8.307-18.696-15.185-30.909-21.252l15.605 34.205s19.387-8.959 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.805a132.19 132.19 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M558.178 330.232a38.751 38.751 0 0 0-2.442 3.991 9.116 9.116 0 0 1-3.381 3.277 25.082 25.082 0 0 1-4.689 2.28 23.3 23.3 0 0 1-5.944 1.323 18.03 18.03 0 0 1-19.09-14.546 23.357 23.357 0 0 1-.301-6.083c.139-1.737.46-3.455.955-5.125a9.105 9.105 0 0 1 2.263-4.129 38.811 38.811 0 0 0 3.2-3.414 18.501 18.501 0 1 1 29.429 22.426Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M557.405 306.571a18.503 18.503 0 0 1 .773 23.662 38.751 38.751 0 0 0-2.442 3.991 9.116 9.116 0 0 1-3.381 3.277 25.082 25.082 0 0 1-4.689 2.28 23.342 23.342 0 0 1-5.944 1.323 18.032 18.032 0 0 1-19.09-14.546 23.357 23.357 0 0 1-.301-6.083c.139-1.737.46-3.455.955-5.125a9.105 9.105 0 0 1 2.263-4.129 38.811 38.811 0 0 0 3.2-3.414 18.423 18.423 0 0 1 10.461-6.916"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
