import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-up">
        <path
            fill="url(#man-34-25-tshirt-3-arm-left-up-a)"
            d="m465.361 167.656 2.654.951c13.052 4.678 23.789 10.343 32.825 17.32a2.674 2.674 0 0 1 1.092 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
        />
        <path
            fill="url(#man-34-25-tshirt-3-arm-left-up-b)"
            d="M507.772 187.677c-6.325-1.437-12.865-2.924-19.239-4.59a32.102 32.102 0 0 0-8.113-1.048 31.98 31.98 0 0 0-30.973 24.05 32.033 32.033 0 0 0 23.038 38.953c9.213 2.367 18.206 5.127 27.285 7.712 17.29 5.038 37.663 8.128 55.344 10.31l1.79-60.579c-15.962-6.068-32.706-11.461-49.132-14.808Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-up-a"
                x1={483.649}
                x2={483.649}
                y1={109.893}
                y2={332.833}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-up-b"
                x1={502.671}
                x2={502.671}
                y1={109.895}
                y2={332.835}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
