import React from 'react';

const SvgComponent = () => (
    <g id="female-34-25-emotion-confused" transform="translate(316, 30)">
        <path
            d="M128.502 92.5c3.2-4.4 8.2-7.3 13.6-7.8M157.8 91.202c3.2-.2 6.5.6 9.2 2.3M154.801 134.1c-2.1-2.6-6.5-4.6-15.2-3.9"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M137.602 105.698c-.773 0-1.4-1.074-1.4-2.4 0-1.325.627-2.4 1.4-2.4.773 0 1.4 1.075 1.4 2.4 0 1.326-.627 2.4-1.4 2.4ZM161.202 105.698c-.774 0-1.4-1.074-1.4-2.4 0-1.325.626-2.4 1.4-2.4.773 0 1.4 1.075 1.4 2.4 0 1.326-.627 2.4-1.4 2.4Z"
            fill="#111011"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
