import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M476.73 232.505c.76-3.49 1.23-7.037 1.406-10.605a33.79 33.79 0 0 0-1.403-11.517 105.818 105.818 0 0 1 17.336 13.629 1.692 1.692 0 0 1 .535 1.597c-.522 3.028-8.329 7.884-15.837 11.354l-2.037-4.458Z"
            fill="url(#female-34-25-shirt-1-arm-opt-8-left-a)"
        />
        <path
            d="M580.331 243.501c-.244-2.465-.385-4.925-.738-7.394l-.466-3.7c-.159-1.234-.387-2.47-.577-3.705-.407-2.47-.855-4.943-1.394-7.419-7.174 3.209-20.295 3.86-28.235-1.736-.81 2.639-1.509 5.282-2.158 7.927-.285 1.216-.607 2.431-.861 3.649l-.75 3.654c-.543 2.434-.873 4.876-1.307 7.314-.003.025-.006.05-.011.075a320.906 320.906 0 0 0-15.234-7.263 152.14 152.14 0 0 0-20.257-7.618c-6.91-2.063-13.877-3.956-20.714-6.24l-.136-.046a26.494 26.494 0 0 0-28.362 7.912 26.508 26.508 0 0 0-6.323 19.272 26.504 26.504 0 0 0 9.157 18.098 26.508 26.508 0 0 0 9.006 5.078c6.85 2.247 13.57 4.884 20.346 7.353a152.378 152.378 0 0 0 20.8 5.975c7.072 1.576 14.209 2.952 21.437 4.054a191.775 191.775 0 0 0 22.048 2.21 19.643 19.643 0 0 0 3.744-.272c.316.028.628.07.949.082a18.5 18.5 0 0 0 19.143-17.834c.173-4.903.681-9.794 1.044-14.69a89.518 89.518 0 0 0-.151-14.736Z"
            fill="url(#female-34-25-shirt-1-arm-opt-8-left-b)"
        />
        <path
            d="M495.121 223.406c4.398 1.321 8.822 2.566 13.223 3.88a152.43 152.43 0 0 1 20.258 7.618 353.163 353.163 0 0 1 12.522 5.894M555.604 290.951a191.729 191.729 0 0 1-22.048-2.209c-7.228-1.102-14.366-2.479-21.437-4.054a152.35 152.35 0 0 1-20.801-5.975c-6.776-2.469-13.496-5.107-20.345-7.354M542.463 271.621l.004-.12c.206-4.903.077-9.818.093-14.727.025-4.92.456-9.83 1.288-14.68.433-2.438.763-4.881 1.306-7.315l.75-3.653c.254-1.218.576-2.433.862-3.649M578.55 228.703c.19 1.235.418 2.471.577 3.705l.466 3.701c.353 2.468.494 4.929.739 7.393.454 4.9.505 9.828.15 14.736-.363 4.896-.871 9.787-1.044 14.69a18.508 18.508 0 0 1-5.878 12.882 18.508 18.508 0 0 1-13.265 4.952M578.55 228.702c-.407-2.47-.855-4.943-1.394-7.419-7.174 3.209-20.294 3.86-28.235-1.736-.81 2.639-1.509 5.282-2.157 7.927"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-8-left-a"
                x1={488.878}
                y1={203.381}
                x2={465.536}
                y2={309.717}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-8-left-b"
                x1={452.725}
                y1={255.251}
                x2={580.715}
                y2={255.251}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
