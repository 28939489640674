import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7251)"
            d="M514.427 258.429s-.293-.731-.362-.893a802.844 802.844 0 00-2.808-6.56c-2.14-4.968-4.353-10.106-6.34-15.226l-.049-.125a27.492 27.492 0 00-14.621-15.141 27.493 27.493 0 00-21.044-.368 27.508 27.508 0 00-15.141 14.621 27.497 27.497 0 00-.367 21.045c2.012 5.11 3.872 10.387 5.67 15.491.79 2.242 1.585 4.481 2.384 6.717a173.02 173.02 0 002.25 5.94l.406 1.018 50.382-25.663-.36-.856z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7251)"
            d="M494.773 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7251"
                x1="489.337"
                x2="465.627"
                y1="276.017"
                y2="191.294"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7251"
                x1="498.773"
                x2="475.062"
                y1="273.375"
                y2="188.652"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
