import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M489.924 311.172c-2.511-.294-5.02-.553-7.528-.792-1.834-.162-3.669-.361-5.502-.492l-5.496-.38c-3.667-.296-7.324-.38-10.986-.567a197.819 197.819 0 0 0-21.916.192l-7.162.501c-.493-2.92-1.039-5.82-1.616-8.709-1.507-7.427-3.286-14.751-5.26-22.001-1.916-7.272-1.839-9.001-4.715-15.909-2.854-6.915-5.874-13.768-8.509-20.767l-.049-.13a26.505 26.505 0 0 0-13.944-14.728 26.5 26.5 0 0 0-35.558 33.664c2.671 6.985 2.522 8.741 4.976 15.809a163.738 163.738 0 0 0 8.77 20.669c3.333 6.734 6.861 13.394 10.661 19.95a205.62 205.62 0 0 0 12.479 19.262 19.427 19.427 0 0 0 5.969 5.244 18.437 18.437 0 0 0 13.631 5.5l10.95-.289 10.955-.146a197.75 197.75 0 0 0 21.856-1.63c3.634-.49 7.272-.878 10.9-1.478l5.446-.835c1.816-.283 3.628-.634 5.442-.947 3.272-.59 6.543-1.213 9.811-1.907-3.744-4.532-7.106-17.544-3.605-29.084Z"
            fill="url(#female-34-25-shirt-1-arm-opt-6-right-a)"
        />
        <path
            d="M483.715 342.161c-1.813.314-3.626.665-5.441.947l-5.446.836c-3.629.6-7.267.987-10.9 1.477a197.769 197.769 0 0 1-21.856 1.63l-10.956.146-10.949.289c-6.819.18-14.567-3.378-19.601-10.744M427.566 309.898l10.93-.764a197.82 197.82 0 0 1 21.916-.192c3.662.187 7.319.271 10.986.567l5.497.38c1.832.131 3.667.33 5.501.491M398.568 336.738a205.41 205.41 0 0 1-12.478-19.262c-3.8-6.556-7.328-13.216-10.662-19.95a163.885 163.885 0 0 1-8.77-20.669c-2.453-7.068-2.304-8.824-4.976-15.809 0 0-4.193-11.279.555-20.275M411.184 242.117l.048.13c2.636 6.998 5.656 13.851 8.51 20.767 2.876 6.908 2.799 8.637 4.715 15.909 1.973 7.25 3.752 14.574 5.26 22.001.357 1.791.702 3.588 1.033 5.39"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M483.717 342.158c3.272-.59 6.543-1.213 9.811-1.907-3.744-4.532-7.106-17.544-3.605-29.084-2.511-.294-5.02-.553-7.528-.792"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-6-right-a"
                x1={359.927}
                y1={286.287}
                x2={493.529}
                y2={286.287}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
