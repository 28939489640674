import React from 'react';

export default function SvgComponent() {
    return (
        <g clipPath="url(#man-34-25-shoes-2-cycling-1-left-a)">
            <path
                d="M471.387 646.109c-.217.408 9.531 33.051 24.528 47.293 3.501 3.325 7.726 7.653 9.932 9.937a5.156 5.156 0 0 1-5.251 8.498c-7.79-2.444-21.404-6.816-25.054-8.63a70.655 70.655 0 0 1-17.341-11.936 41.137 41.137 0 0 0-10.39-7.175l-18.265-8.928a10 10 0 0 1-5.157-6.011 9.983 9.983 0 0 1 .835-7.876c8.402-14.912 15.078-28.708 15.078-28.708l5.927-25.229 36.724 16.481-11.566 22.284Z"
                fill="#2B2729"
            />
            <path
                d="M501.34 711.494a5.139 5.139 0 0 1-.288-.079c-7.808-2.376-21.455-6.63-25.127-8.416a70.782 70.782 0 0 1-17.442-11.788 41.106 41.106 0 0 0-10.449-7.086l-18.339-8.772a9.995 9.995 0 0 1-4.442-13.851 1063.64 1063.64 0 0 0 6.13-11.245 1.003 1.003 0 0 1 1.284-.442 65.385 65.385 0 0 1 10.357 6.201c.576.395 1.151.789 1.726 1.179 5.206 3.526 10.865 6.769 16.951 6.62a32.98 32.98 0 0 0 6.987-1.188c1.15-.279 2.338-.569 3.528-.795a24.371 24.371 0 0 1 3.342-.403 1.005 1.005 0 0 1 .965.598c4.275 9.744 11.162 23.031 19.692 30.994 3.51 3.277 7.781 7.579 10.016 9.853a5.154 5.154 0 0 1-4.891 8.62Z"
                fill="#004F71"
            />
            <path
                d="M505.114 710.956a5.144 5.144 0 0 1-4.062.461c-7.808-2.377-21.455-6.631-25.127-8.416a70.782 70.782 0 0 1-17.442-11.788 41.08 41.08 0 0 0-10.449-7.086l-18.339-8.773a9.9 9.9 0 0 1-5.21-5.971"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <clipPath id="man-34-25-shoes-2-cycling-1-left-a">
                    <path
                        fill="#fff"
                        transform="translate(.893 .094)"
                        d="M0 0h900v900H0z"
                    />
                </clipPath>
            </defs>
        </g>
    );
}
