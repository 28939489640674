import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10363_7818)"
            d="M494.768 222.602a109.64 109.64 0 00-20.026-15.329 31.037 31.037 0 012.394 13.895 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.013 1.013 0 00.563.522.997.997 0 00.767-.03c2.598-1.2 15.601-7.426 16.405-12.092a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_10363_7818)"
            d="M461.422 278.754a1.003 1.003 0 01-.952-.692l-.208-.639c-.284-.877-.569-1.753-.831-2.637-.619-2.07-1.224-4.147-1.83-6.224-1.379-4.732-2.805-9.626-4.395-14.366a27.489 27.489 0 011.391-21.054 27.496 27.496 0 0115.9-13.871 27.505 27.505 0 0129.542 8.08 27.489 27.489 0 015.311 9.355l.046.136.002.005c1.565 4.752 3.352 9.528 5.08 14.148.714 1.907 1.424 3.816 2.129 5.726a1 1 0 01-.557 1.27l-50.248 20.688a.99.99 0 01-.38.075z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10363_7818"
                x1="480.642"
                x2="503.141"
                y1="223.429"
                y2="248.214"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.034" stopColor="#ABADAD"></stop>
                <stop offset="0.967" stopColor="#888B8D"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10363_7818"
                x1="466.376"
                x2="497.121"
                y1="233.944"
                y2="275.496"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.034" stopColor="#ABADAD"></stop>
                <stop offset="0.967" stopColor="#888B8D"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
