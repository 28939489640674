import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={118}
            height={36}
            fill="none"
            id="object-food-apricots"
            {...attributes}
        >
            <path
                fill="url(#object-food-apricots-a)"
                d="M117.464 11.345C116.109 6.558 97.074.21 59.058.21 21.043.21 2.008 6.558.652 11.345c-2.38 8.407 26.734 24.39 58.406 24.39 31.672 0 60.786-15.983 58.406-24.39Z"
            />
            <defs>
                <linearGradient
                    id="object-food-apricots-a"
                    x1={68.282}
                    x2={4.291}
                    y1={28.235}
                    y2={-80.487}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
