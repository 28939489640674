import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10363_7790)"
            d="M400.398 274.419c-.164 0-.326-.04-.471-.117l-47.483-25.336a1.012 1.012 0 01-.519-1.029.986.986 0 01.14-.381l.943-1.519c2.871-4.618 5.839-9.394 8.579-14.184a27.507 27.507 0 0116.647-12.88 27.513 27.513 0 0120.879 2.664 27.527 27.527 0 018.095 7.043 27.492 27.492 0 012.12 30.483l-.065.114c-2.748 4.749-5.367 9.684-7.899 14.456l-.083.155a.997.997 0 01-.883.531z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10363_7790"
                x1="413.16"
                x2="372.276"
                y1="199.078"
                y2="268.152"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#FFD100"></stop>
                <stop offset="0.966" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
