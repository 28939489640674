import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6553)"
            d="M413.053 251.399l.024-.125a27.496 27.496 0 00-11.937-28.157 27.515 27.515 0 00-20.7-3.919 27.515 27.515 0 00-17.398 11.879 27.505 27.505 0 00-4.005 9.966c-1.026 5.422-2.25 10.899-3.447 16.221 0 0 16.752.041 27.74 1.343 12.487 1.478 27.283 6.901 27.283 6.901.748-4.677 1.533-9.438 2.44-14.109z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6553"
                x1="364.292"
                x2="407.087"
                y1="225.741"
                y2="268.536"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
