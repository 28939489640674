import { IBodyClothes } from 'interfaces';

import P1 from './female-34-25-jacket-3-p1';
import P2 from './female-34-25-jacket-3-p2';
import P1Sitting from './female-34-25-jacket-3-p1-sitting';
import P2Sitting from './female-34-25-jacket-3-p2-sitting';
import RArmOp1 from './female-34-25-jacket-3-arm-opt-1-right';
import RArmOp2 from './female-34-25-jacket-3-arm-opt-2-right';
import RArmOp3 from './female-34-25-jacket-3-arm-opt-3-right';
import RArmOp4 from './female-34-25-jacket-3-arm-opt-4-right';
import RArmOp5 from './female-34-25-jacket-3-arm-opt-5-right';
import RArmOp6 from './female-34-25-jacket-3-arm-opt-6-right';
import RArmOp7 from './female-34-25-jacket-3-arm-opt-7-right';
import RArmOp8 from './female-34-25-jacket-3-arm-opt-8-right';
import RArmOp9 from './female-34-25-jacket-3-arm-opt-9-right';
import LArmOp1 from './female-34-25-jacket-3-arm-opt-1-left';
import LArmOp2 from './female-34-25-jacket-3-arm-opt-2-left';
import LArmOp3 from './female-34-25-jacket-3-arm-opt-3-left';
import LArmOp4 from './female-34-25-jacket-3-arm-opt-4-left';
import LArmOp5 from './female-34-25-jacket-3-arm-opt-5-left';
import LArmOp6 from './female-34-25-jacket-3-arm-opt-6-left';
import LArmOp7 from './female-34-25-jacket-3-arm-opt-7-left';
import LArmOp8 from './female-34-25-jacket-3-arm-opt-8-left';
import LArmOp9 from './female-34-25-jacket-3-arm-opt-9-left';
import LArmOp10 from './female-34-25-jacket-3-arm-opt-10-left';
import BLArmsOpt2 from './female-34-25-jacket-3-arms-opt-2-left';
import BRArmsOpt2 from './female-34-25-jacket-3-arms-opt-2-right';

const jacket3: IBodyClothes = {
    text: 'Jacket #3',
    normal: {
        front: P1,
        back: P2,
        rightArm: {
            option1: RArmOp1,
            option2: RArmOp2,
            option3: RArmOp3,
            option4: RArmOp4,
            option5: RArmOp5,
            option6: RArmOp6,
            option7: RArmOp7,
            option8: RArmOp8,
            option9: RArmOp9,
        },
        leftArm: {
            option1: LArmOp1,
            option2: LArmOp2,
            option3: LArmOp3,
            option4: LArmOp4,
            option5: LArmOp5,
            option6: LArmOp6,
            option7: LArmOp7,
            option8: LArmOp8,
            option9: LArmOp9,
            option10: LArmOp10,
        },
        bothArms: {
            option2: [BRArmsOpt2, BLArmsOpt2],
        }
    },
    sitting: {
        front: P1Sitting,
        back: P2Sitting,
        rightArm: {
            option1: RArmOp1,
            option2: RArmOp2,
            option3: RArmOp3,
            option4: RArmOp4,
            option5: RArmOp5,
            option6: RArmOp6,
            option7: RArmOp7,
            option8: RArmOp8,
            option9: RArmOp9,
        },
        leftArm: {
            option1: LArmOp1,
            option2: LArmOp2,
            option3: LArmOp3,
            option4: LArmOp4,
            option5: LArmOp5,
            option6: LArmOp6,
            option7: LArmOp7,
            option8: LArmOp8,
            option9: LArmOp9,
            option10: LArmOp10,
        },
        bothArms: {
            option2: [BRArmsOpt2, BLArmsOpt2],
        }
    },
};

export default jacket3;
