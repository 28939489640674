import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-4">
        <path
            d="m465.028 166.859 2.654.952c13.051 4.677 23.788 10.342 32.825 17.319.765.59 5.866 5.433 5.816 6.374-.25 4.648-16.938 8.175-19.334 9.644l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-4-a)"
        />
        <path
            d="M512.883 256.241a62.274 62.274 0 0 1 13.204-10.009 254.61 254.61 0 0 1 2.25-6.583c.406-1.148.809-2.299 1.21-3.454.473-1.359.961-2.716 1.445-4.073-2.889-5.1-5.866-10.288-6.909-12.025a926.01 926.01 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.616-9.445-16.021l-.073-.13a31.999 31.999 0 0 0-58.69 7.183 32.004 32.004 0 0 0 2.975 24.311c3.048 5.392 5.963 10.988 8.781 16.4 1.239 2.378 2.481 4.752 3.729 7.123 4.034 7.678 12.44 21.361 24.205 37.789 0 0 6.723-19.518 21.47-33.615Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-4-b)"
        />
        <path
            d="m509.543 197.543.071.126c4.338 7.759 9.056 15.303 13.611 22.94a206.864 206.864 0 0 1 6.052 10.805"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M467.345 168.754a129.713 129.713 0 0 1 32.551 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-4-a"
                x1={465.028}
                y1={184.266}
                x2={506.323}
                y2={184.266}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-4-b"
                x1={450.542}
                y1={235.324}
                x2={569.413}
                y2={235.324}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
