import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7053)"
            d="M475.685 232.623l.064-.319a63.794 63.794 0 001.387-10.444 32.868 32.868 0 00-1.357-11.178l-.834-2.627 2.325 1.482a106.605 106.605 0 0117.498 13.757 2.708 2.708 0 01.821 2.482c-.805 4.665-13.806 10.891-16.403 12.092l-.913.422-2.588-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7053)"
            d="M555.575 291.953a195.032 195.032 0 01-22.17-2.22c-6.93-1.056-13.964-2.387-21.508-4.067a154.131 154.131 0 01-20.929-6.013c-2.041-.744-4.08-1.504-6.12-2.264-4.633-1.728-9.424-3.514-14.19-5.077a27.519 27.519 0 01-15.966-13.715 27.5 27.5 0 0133.111-38.545l.14.046c4.756 1.589 9.664 3.014 14.411 4.392 2.093.608 4.186 1.216 6.273 1.839a154.045 154.045 0 0120.387 7.667 336.73 336.73 0 0114.062 6.668l.185-1.126c.267-1.628.542-3.311.914-4.977l.746-3.636c.167-.804.364-1.603.56-2.403.104-.424.208-.849.308-1.274.755-3.08 1.445-5.617 2.174-7.992l.404-1.318 1.128.794c3.661 2.58 8.916 4 14.797 4a31.753 31.753 0 0012.454-2.359l1.123-.503.263 1.203a147.76 147.76 0 011.403 7.469c.068.441.14.873.211 1.304.134.812.267 1.624.372 2.435l.466 3.704c.239 1.672.385 3.372.525 5.015.069.802.137 1.603.216 2.405.459 4.956.51 9.942.153 14.907a441.144 441.144 0 01-.356 4.446c-.278 3.347-.565 6.808-.687 10.207a19.424 19.424 0 01-5.918 13.358 19.425 19.425 0 01-13.555 5.452h-.002c-.233 0-.467-.004-.701-.013a10.155 10.155 0 01-.705-.055l-.176-.018a20.655 20.655 0 01-3.803.264z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7053"
                x1="458.809"
                x2="584.375"
                y1="216.531"
                y2="263.375"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7053"
                x1="457.119"
                x2="582.685"
                y1="221.054"
                y2="267.898"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
