import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7240)"
            d="M475.684 232.67l.065-.318a64.034 64.034 0 001.386-10.446 32.912 32.912 0 00-1.357-11.177l-.834-2.627 2.325 1.482a106.713 106.713 0 0117.498 13.757 2.708 2.708 0 01.821 2.482c-.804 4.662-13.806 10.891-16.403 12.092l-.913.422-2.588-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7240)"
            d="M561.561 293.22a220.865 220.865 0 01-23.588-2.502c-7.383-1.146-14.888-2.57-22.947-4.353a175.578 175.578 0 01-22.379-6.302 792.816 792.816 0 01-6.564-2.353c-4.971-1.796-10.111-3.651-15.22-5.285a27.514 27.514 0 01-16.179-13.571 27.512 27.512 0 01-1.786-21.041 27.493 27.493 0 0113.658-16.104 27.5 27.5 0 0121.05-1.673l.129.041c5.101 1.658 10.355 3.154 15.436 4.599 2.24.637 4.477 1.279 6.714 1.927a168.578 168.578 0 0112.783 4.274c-.192-.454-.382-.91-.569-1.368l-2.327-5.537a183.44 183.44 0 01-1.467-3.658c-.256-.654-.514-1.307-.773-1.96-2.21-5.631-3.923-10.26-5.392-14.573l-.494-1.452 1.529.134c.476.042.953.061 1.434.061 9.236-.001 18.715-7.22 22.52-11.772l.792-.947.762.971c2.6 3.318 5.307 6.939 8.518 11.395.414.584.83 1.161 1.245 1.737.764 1.06 1.527 2.119 2.265 3.193l3.4 4.964c1.558 2.226 3.057 4.536 4.506 6.77.708 1.091 1.416 2.182 2.133 3.268a238.564 238.564 0 0112.148 20.768l5.461 10.664 5.58 10.581a19.504 19.504 0 01-8.154 26.346 25.995 25.995 0 01-14.224 2.758z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7240"
                x1="488.253"
                x2="463.646"
                y1="204.852"
                y2="304.745"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#EF0025"></stop>
                <stop offset="0.936" stopColor="#F6542F"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7240"
                x1="451.722"
                x2="586.2"
                y1="241.568"
                y2="241.568"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#EF0025"></stop>
                <stop offset="0.936" stopColor="#F6542F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
