import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom';
import { ReactComponent as CrossIcon } from 'icons/cross.svg';

export default function Modal ({
    toggleModal,
    children,
} : {
    toggleModal: (() => void) | false,
    children: ReactNode
}) {
    return ReactDOM.createPortal(
        <div className="modal-area" onClick={event => event.stopPropagation()}>
            <div className="modal-window">
                {toggleModal ? <button className="modal-close" onClick={() => toggleModal()}>
                    <CrossIcon />
                </button> : null}
                {children}
            </div>
        </div>,
        document.body
    );
}
