import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M478.842 236.187c-.182-4.781-.96-19.424-3.838-27.14a101.216 101.216 0 0 1 18.685 14.4 1.7 1.7 0 0 1 .535 1.597c-.511 2.966-8.017 7.688-15.382 11.143Z"
            fill="url(#female-34-25-shirt-1-arm-opt-6-left-a)"
        />
        <path
            d="M563.767 310.211a67.156 67.156 0 0 0-10.164-5.869c-.863-.403-1.719-.843-2.587-1.215l-2.609-1.106a53.09 53.09 0 0 0-2.617-1.062 72.569 72.569 0 0 0-2.632-.975 52.527 52.527 0 0 0-10.751-2.721h-.003a211.075 211.075 0 0 0-3.523-11.114c-2.235-6.392-4.721-12.64-7.389-18.785a130.111 130.111 0 0 0-9.146-17.783c-3.486-5.679-7.125-11.27-10.408-17.064l-.076-.135a26.501 26.501 0 0 0-45.962 26.393c3.317 5.775 6.276 11.754 9.389 17.645a130.003 130.003 0 0 0 10.65 16.925c3.931 5.425 8.043 10.746 12.407 15.923a165.78 165.78 0 0 0 14.095 14.96c.172.156.351.302.528.452a18.421 18.421 0 0 0 11.684 7.393c3.629.662 7.198 1.651 10.792 2.499a52.594 52.594 0 0 0 11.009 1.339c.932.032 1.868.045 2.807.04.936.014 1.879-.012 2.824-.048l2.832-.09c.944-.034 1.901-.136 2.852-.2-5.205-11.87 1.554-24.231 15.998-25.402Z"
            fill="url(#female-34-25-shirt-1-arm-opt-6-left-b)"
        />
        <path
            d="m501.863 232.383.077.135c3.283 5.794 6.922 11.385 10.407 17.064a130.005 130.005 0 0 1 9.147 17.783c2.668 6.145 5.154 12.393 7.388 18.786.899 2.6 1.768 5.216 2.58 7.864M502.441 324.227a165.913 165.913 0 0 1-14.095-14.96c-4.364-5.178-8.476-10.499-12.407-15.923a130.014 130.014 0 0 1-10.65-16.926c-3.113-5.891-6.072-11.87-9.389-17.645M529.749 296.914c.887.112 1.773.226 2.658.349a52.527 52.527 0 0 1 10.751 2.721 68.37 68.37 0 0 1 2.632.975 53.09 53.09 0 0 1 2.617 1.062l2.609 1.106c.868.372 1.724.812 2.588 1.215a67.195 67.195 0 0 1 10.163 5.869m-15.998 25.402c-.95.064-1.908.166-2.852.2l-2.832.09c-.945.035-1.888.062-2.824.047a72.812 72.812 0 0 1-2.807-.04 52.594 52.594 0 0 1-11.009-1.339c-3.594-.848-7.163-1.837-10.792-2.498-4.084-.744-8.397-4.415-12.212-7.845"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M483.766 215.188c3.483 2.65 6.766 5.42 9.899 8.3"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M544.916 335.807c.945-.034 1.902-.136 2.852-.2-5.204-11.87 1.555-24.231 15.999-25.402a67.254 67.254 0 0 0-10.164-5.869"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-6-left-a"
                x1={494.462}
                y1={197.177}
                x2={405.546}
                y2={377.611}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-6-left-b"
                x1={536.192}
                y1={217.745}
                x2={447.277}
                y2={398.178}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
