import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-3-walk-1">
            <path
                d="M602.186 849.918c-21.797 9.111-37.681 5.397-39.889 4.804l-24.638 4.688a9.014 9.014 0 0 1-7.039-1.568 9.013 9.013 0 0 1-3.685-6.191c-1.989-15.268-3.154-22.408-4.025-25.162l32.848-7.739c.934.549 24.036 15.416 40.205 16.759 9.64.8 14.853 1.807 15.403 4.7.638 3.355-2.879 7.075-9.18 9.709Z"
                fill="#E2E2E2"
            />
            <path
                d="M560.841 819.63a3.111 3.111 0 0 1 2.598.434c7.9 5.406 21.665 14.187 32.551 14.441 13.786.323 15.94 3.301 16.361 5.514.743 3.904-2.912 7.948-9.777 10.818-14.731 6.157-26.838 6.527-33.943 5.91a60.042 60.042 0 0 0-16.408.907l-14.065 2.676a10.503 10.503 0 0 1-7.851-1.491 9.999 9.999 0 0 1-4.361-7.062c-.862-6.62-1.645-12.227-2.325-16.671a3.208 3.208 0 0 1 1.693-3.404c3.906-1.877 9.083 5.531 16.289 3.601 7.663-2.053 15.438-14.601 19.238-15.673Z"
                fill="#001489"
            />
            <path
                d="M612.433 841.062a16.36 16.36 0 0 1-5.659 4.84c-8.889 5.189-34.801 5.732-46.569 6.538-10.692.732-19.246-5.745-34.834-5.009.187 1.397.379 2.84.574 4.343a10.02 10.02 0 0 0 4.361 7.063 10.499 10.499 0 0 0 7.851 1.49l14.066-2.675a60.033 60.033 0 0 1 16.407-.908c7.105.618 19.213.248 33.943-5.91 6.254-2.613 9.832-6.202 9.86-9.772ZM336.092 858.508a21.235 21.235 0 0 1-18.055-9.96l-17.576-27.872a9.153 9.153 0 0 1-1.395-4.841 9.153 9.153 0 0 1 1.38-4.844l7.994-12.757s10.181 6.75 13.898 7.663c9.868 2.422 16.789 1.337 16.789 1.337l10.883 29.476a14.1 14.1 0 0 0 8.56 8.405l3.385 1.19a6.294 6.294 0 0 1 3.97 7.6 6.293 6.293 0 0 1-6.052 4.603h-23.781Z"
                fill="#E2E2E2"
            />
            <path
                d="M336.089 858.508a21.236 21.236 0 0 1-18.056-9.96l-17.576-27.872a9.092 9.092 0 0 1-1.402-4.84 9.092 9.092 0 0 1 1.388-4.845l4.966-7.929 6.177 5.996a12.266 12.266 0 0 0 11.132 3.186l8.436-1.756c6.181-.868 9.488 1.084 11.169 5.53l1.482 4.012 6.202 16.68a14.14 14.14 0 0 0 8.558 8.405l3.148 1.106a6.472 6.472 0 0 1 4.427 6.366 6.29 6.29 0 0 1-1.962 4.203 6.29 6.29 0 0 1-4.309 1.718h-23.78Z"
                fill="url(#female-34-25-shoes-3-walk-1-a)"
            />
            <path
                d="M334.936 859.956a21.247 21.247 0 0 1-18.055-9.96l-17.577-27.873a9.153 9.153 0 0 1-.014-9.684s4.163-.269 8.121 6.617c5.004 8.705 8.15 28.12 22.988 34.699 16.753 7.427 31.572-.235 34.997 1.597-.751 2.711-3.867 4.604-6.68 4.604h-23.78Z"
                fill="#E2E2E2"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-3-walk-1-a"
                    x1={376.487}
                    y1={848.585}
                    x2={315.223}
                    y2={830.785}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#012B97" />
                    <stop offset={1} stopColor="#001489" />
                </linearGradient>
            </defs>
        </g>
    );
}
