import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={145}
            height={76}
            fill="none"
            id="object-food-cheese"
            {...attributes}
        >
            <path
                fill="url(#object-food-cheese-a)"
                d="M37.095 1.389H1.485l106.396 72.8h35.611L37.095 1.39Z"
            />
            <path fill="url(#object-food-cheese-b)" d="M1.484 1.389v72.8h106.397L1.484 1.39Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M54.682 13.423 37.095 1.389H1.485v72.8h142.007L82.745 32.625"
            />
            <path
                fill="url(#object-food-cheese-c)"
                d="M18.703 38.953a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <path
                fill="url(#object-food-cheese-d)"
                d="M18.578 65.353a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <path
                fill="url(#object-food-cheese-e)"
                d="M45.103 65.418a6.33 6.33 0 1 0 0-12.66 6.33 6.33 0 0 0 0 12.66Z"
            />
            <defs>
                <linearGradient
                    id="object-food-cheese-a"
                    x1={122.016}
                    x2={64.416}
                    y1={37.789}
                    y2={37.789}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-food-cheese-b"
                    x1={89.812}
                    x2={-9.725}
                    y1={72.762}
                    y2={33.351}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-food-cheese-c"
                    x1={62.323}
                    x2={5.228}
                    y1={88.21}
                    y2={15.452}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-food-cheese-d"
                    x1={49.424}
                    x2={-7.67}
                    y1={98.332}
                    y2={25.574}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="object-food-cheese-e"
                    x1={65.81}
                    x2={8.715}
                    y1={85.474}
                    y2={12.717}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
