import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8484)"
            d="M365.397 271.954c-.672-1.383-1.328-2.777-1.984-4.17-1.435-3.048-2.918-6.2-4.554-9.187a27.501 27.501 0 0121.152-40.546 27.491 27.491 0 0120.202 5.908 27.517 27.517 0 016.89 8.226l.155.286c1.6 3.006 3.421 5.976 5.182 8.848-8.363-4.079-43.495.801-47.043 30.635z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8484"
                x1="363.757"
                x2="401.492"
                y1="236.618"
                y2="258.739"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
