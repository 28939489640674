import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-2-right">
        <path
            d="M405.855 182.337c-2.56-.22-5.121-.423-7.683-.626-5.834-.463-11.865-.941-17.77-1.623l-.15-.017a32.695 32.695 0 0 0-3.553-.196 31.946 31.946 0 0 0-31.769 28.488 31.8 31.8 0 0 0 6.83 23.52 31.798 31.798 0 0 0 21.462 11.802c5.904.651 11.89 1.531 17.68 2.381 2.543.374 5.085.748 7.626 1.103 1.032.148 2.088.263 3.132.395l4.674-65.179c-.159-.013-.321-.034-.479-.048Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-2-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-2-right-a"
                x1={346.266}
                y1={209.796}
                x2={409.158}
                y2={215.435}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
