import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-5" transform="translate(316, 30)">
        <path
            d="M72.9 74.8c7.5-16.8 20.8-31.9 47.6-33.6 28.5-1.9 100.6-.6 46 62.401-.1-.4-.2-.7-.3-1.1l-.6-1.8c-.7-2.4-.2-5 .3-7.7.4-2.3.9-4.6.7-7-.5-5.8-4.1-13-10.2-19.1l-.9 1.2-.1.1c-10.3 14.7-25.2 33.8-45.1 42.4-.6.3-.1 1-.2 1-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5-.1 2.4-.2 4.5-.5 6.3-.5 4-5.9 4.8-7.6 1.2-2.3-5-4.5-8.9-7.5-13.5-20.8 3.9-23.7-29.1-15.5-47.4Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M74.2 117c-7.4-9.5-7.1-29.4-1.4-42.2 7.5-16.8 20.8-31.9 47.6-33.6 17.3-1.1 50.6-1.1 62 13.1"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110 111.598c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
