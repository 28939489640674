import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6337)"
            d="M524.499 280.019c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.786-9.422-18.228-14.919-23.949a47.595 47.595 0 00-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a21.986 21.986 0 01-8.678 1.436c-10.92 0-21.127-6.506-31.94-13.399-7.282-4.644-14.811-9.444-22.911-12.429a32.69 32.69 0 00-10.801-1.712 31.608 31.608 0 00-4.391.292c-.16.023-.314.078-.452.162a62.748 62.748 0 00-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.375 34.829-20.638 21.97-25.615 45.361-27.937 63.292 14.883 0 179.195.875 179.225.714 9.23-48.617-.85-76.583-9.313-98.687a518.367 518.367 0 01-2.685-7.11c8.093-3.299 18.17-9.259 20.658-19.014 1.943-7.614-1.083-16.22-8.991-25.578z"
        ></path>
        <path
            fill="url(#paint1_linear_11342_6337)"
            d="M363.612 285.759h165.176a57.355 57.355 0 00-4.29-5.741c-20.409-24.152-26.325-32.893-31.648-46.77-2.987-7.786-9.422-18.228-14.919-23.949a47.595 47.595 0 00-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a21.986 21.986 0 01-8.678 1.436c-10.92 0-21.127-6.506-31.94-13.399-7.282-4.643-14.811-9.444-22.911-12.429a32.69 32.69 0 00-10.801-1.712 31.608 31.608 0 00-4.391.292c-.16.023-.314.078-.452.162a62.748 62.748 0 00-26.519 74.436l2.52 7.078z"
        ></path>
        <path
            fill="url(#paint2_linear_11342_6337)"
            d="M377.506 324.802h135.396l-.07-.185c8.094-3.299 18.171-9.26 20.659-19.014a18.6 18.6 0 00.539-5.415H368.746l8.76 24.614z"
        ></path>
        <path
            fill="url(#paint3_linear_11342_6337)"
            d="M382.072 344.531a32.918 32.918 0 01-5.916 18.651h149.2a163.442 163.442 0 00-5.174-18.651h-138.11z"
        ></path>
        <g filter="url(#filter0_f_11342_6337)">
            <path
                fill="url(#paint4_radial_11342_6337)"
                d="M410.73 290.993l5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.265 14.814 1.029 21.605-1.697a30.989 30.989 0 0018.534-21.326 30.985 30.985 0 00-6.485-27.499l-6.917-10.635"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11342_6337"
                width="91.356"
                height="77.898"
                x="402.73"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11342_6337"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11342_6337"
                x1="345.606"
                x2="534.06"
                y1="317.109"
                y2="317.109"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11342_6337"
                x1="407.702"
                x2="452.308"
                y1="176.462"
                y2="303.145"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11342_6337"
                x1="438.552"
                x2="464.168"
                y1="263.732"
                y2="336.481"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_11342_6337"
                x1="434.546"
                x2="458.031"
                y1="315.166"
                y2="381.863"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
            <radialGradient
                id="paint4_radial_11342_6337"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 989.008 -1072.183) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#63666A" stopOpacity="0"></stop>
                <stop offset="0.497" stopColor="#63666A" stopOpacity="0.129"></stop>
                <stop offset="0.804" stopColor="#63666A" stopOpacity="0.755"></stop>
                <stop offset="0.949" stopColor="#63666A"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
