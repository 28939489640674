import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6958)"
            d="M479.228 236.312c-.182-4.781-.96-19.424-3.837-27.14a101.286 101.286 0 0118.684 14.4 1.706 1.706 0 01.535 1.598c-.512 2.967-8.018 7.689-15.382 11.142z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6958)"
            d="M513.209 352.193a19.567 19.567 0 01-17.56-11.099 230.78 230.78 0 01-13.098-20.256c-3.844-6.603-7.55-13.481-11.334-21.03a183.686 183.686 0 01-9.467-21.822 752.17 752.17 0 01-2.385-6.719c-1.798-5.103-3.657-10.38-5.67-15.49a27.602 27.602 0 0151.335-20.311l.049.126c1.987 5.12 4.04 10.413 6.179 15.381.941 2.185 1.877 4.372 2.808 6.56a188.302 188.302 0 018.015 22.708c2.339 8.109 4.31 15.536 5.964 22.988.629 2.876.971 5.358 1.441 7.776a84.781 84.781 0 0110.167-.219c.779.036 1.548.061 2.318.086 1.585.05 3.222.103 4.858.254l3.552.262c.851.066 3.978.267 6.163.465 1.473.189 2.932.479 4.365.868 0 0-7.394 2.608-8.431 12.605-.644 6.215 1.724 14.074 9.767 16.442l3.2.942-3.19.974c-.47.143-1.783.579-3.243 1.064-2.119.703-4.52 1.5-5.221 1.694l-3.455.948c-1.56.458-3.159.825-4.705 1.181-.749.172-1.498.344-2.246.527a83.91 83.91 0 01-14.153 2.109c-1.421.085-2.842.157-4.263.229-3.218.163-6.545.332-9.79.658-.653.066-1.315.099-1.968.099h-.002z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6958"
                x1="474.142"
                x2="550.479"
                y1="202.769"
                y2="367.588"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6958"
                x1="469.121"
                x2="545.459"
                y1="205.087"
                y2="369.906"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
