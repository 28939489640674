import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-1-cycling-2-left">
        <path
            d="M503.718 206.951c-7.696-12.253-24.203-27.922-37.678-36.217l-2.115 9.542 9.65 41.17s33.123-9.749 30.143-14.495Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-cycling-2-left-a)"
        />
        <path
            d="M507.407 212.641c-6.323-11.804-16.237-18.813-29.623-18.813A32.019 32.019 0 0 0 463 197.452a32.012 32.012 0 0 0-15.27 39.364c2.043 5.596 3.921 11.368 5.738 16.949.801 2.46 1.6 4.92 2.416 7.372 1.432 4.347 6.976 22.847 8.882 27.472 0 0 19.87-8.576 36.352-12.697a165.258 165.258 0 0 1 26.422-3.94c-2.458-11.855-13.627-47.186-20.133-59.331Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-cycling-2-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-cycling-2-left-a"
                x1={463.925}
                y1={196.09}
                x2={503.907}
                y2={196.09}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-cycling-2-left-b"
                x1={445.77}
                y1={241.219}
                x2={539.833}
                y2={241.219}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
