import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-both-arms-option-1-left">
        <path
            fill="url(#man-34-25-jacket-both-arms-option-1-left-a)"
            d="m465.573 167.398 2.654.952c13.05 4.676 23.788 10.341 32.825 17.318a2.67 2.67 0 0 1 1.092 2.342c-.25 4.648-12.214 12.209-14.61 13.677l-.857.525-21.104-34.814Z"
        />
        <path
            fill="url(#man-34-25-jacket-both-arms-option-1-left-b)"
            d="M577.485 284.582c-1.84 0-3.675-.209-5.468-.623-10.158-2.371-19.814-5.504-27.092-7.968-8.441-2.884-16.984-6.09-26.118-9.802a236.592 236.592 0 0 1-25.19-11.747 1023.99 1023.99 0 0 1-7.33-3.989c-5.554-3.036-11.298-6.176-17.036-9.072a32.034 32.034 0 0 1-14.154-42.984 31.87 31.87 0 0 1 28.594-17.592 31.659 31.659 0 0 1 14.39 3.437l.128.066c5.719 2.917 11.638 5.694 17.362 8.381 2.526 1.184 5.05 2.375 7.57 3.571a236.552 236.552 0 0 1 24.358 13.394c8.385 5.176 16.017 10.177 23.326 15.285 6.285 4.417 14.513 10.357 22.429 17.157a24.192 24.192 0 0 1 2.585 34.077 24.161 24.161 0 0 1-18.354 8.409Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-1-left-a"
                x1={482.051}
                x2={463.834}
                y1={187.011}
                y2={280.047}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-1-left-b"
                x1={534.301}
                x2={516.085}
                y1={197.245}
                y2={290.281}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
