import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2570}
            height={1472}
            viewBox="0 0 2570 1472"
            fill="none"
            id="scenes-office-6"
            {...attributes}
        >
            <path fill="#fff" d="M0 0h2570v1472H0z" />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#a)"
            >
                <path
                    d="M318.108 700.466v-18.841h18.841v18.841h-18.841ZM238.582 700.466v-18.841h18.841v18.841h-18.841ZM338.692 1172.23h40.807c10.406 0 18.841 8.43 18.841 18.84H222.003c0-10.41 8.435-18.84 18.841-18.84h53.23M336.95 691.039h44.53M398.34 681.038v-11.96c0-5.53-4.48-10-10-10h-26.67M257.418 691.039h18.84" />
                <path 
                    d="M344.258 659.078h-112.26c-5.52 0-10 4.47-10 10v11.96c0 5.53 4.48 10 10 10h6.58M381.48 691.039v18.843M398.34 709.885v-40.807c0-5.523-4.478-10-10-10h-6.858M361.668 659.081v-5.769h-17.405v5.769" />
                <path 
                    d="M318.109 691.042h-19.15l.003-7.917a7.484 7.484 0 0 0-7.484-7.484h-7.731a7.484 7.484 0 0 0-7.484 7.484v19.607h12.786v-11.69h-12.79M294.076 1172.23l32.19-.02-7.42-424.663M294.732 1134.35l6.76-386.803" />
                <path
                    d="M398.344 709.883v.84c0 11.2-7.77 20.9-18.71 23.32l-60.79 13.51M381.484 709.883h-158.62c0 11.05 7.58 20.66 18.32 23.23l60.31 14.44" />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M892 802.535v-30.652H456.968v30.652h412.674"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M479.325 821.372v323.058h390.316V802.531"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M479.326 973.483h371.475V821.375H479.326v152.108ZM850.801 973.484H460.485v152.106h390.316M704.609 845.375h-60.253M704.609 997.484h-60.253M2155.49 1172.96l-.75 17.25h-14.84l-2.15-49.1M2182.89 1141.12l5.13 15.54 11.07 33.55h14.84l-9.94-41M2090.64 1149.21l-9.94 41h14.84l11.07-33.55"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2215.68 1138.3c8.02-10.09 12.44-23.08 11.53-36.92-2.29-35.04-4.59-70.09-6.89-105.13H2074.3l-6.89 105.13c-1.28 19.57 8.09 37.44 23.23 47.83M2203.99 1149.21a53.33 53.33 0 0 1-15.97 7.45c-2.93.82-5.96 1.4-9.09 1.71-9.36.94-19.34 1.53-29.85 1.59-11.85.06-23.03-.54-33.44-1.6-3.1-.31-6.12-.89-9.03-1.7M2233.36 827.24c-6.96-22.407-7.65-37.827.88-49.677 5.81-8.061 17.86-9.925 24.98-5.08 8.14 5.537 10.5 16.058 8.95 24.762-4.91 27.547-21.74 53.869-42.79 73.701 0-43.579-19.81-65.913-37.5-65.913-7.07 0-14.37 4.052-14.37 12.736 0 19.652 40.11 20.997 36.05 79.291 21.62-20.264 44.64-31.313 61.22-31.313 14.49 0 25.22 8.578 25.22 21.936 0 12.695-9.68 17.116-21.25 17.116-13.38 0-51.8-5.253-79.03 21.979 7.59-35.561-14.59-80.211-34.24-80.211-10.16 0-15.34 7.768-15.34 13.958 0 25.326 42.5 31.222 39.87 94.295 30.42-15.537 46.59-20.829 58.72-20.829 4.79 0 20.96 3.145 20.96 17.545 0 8.046-8.98 16.547-19.6 16.547-18.54 0-29.6-4.223-48.12.505-10.86 2.77-17.58 10.937-21.94 15.909 1.48-44.33-21.52-91.457-40.98-88.352-11.08 1.768-3.53 38.335 21 88.352-35.19-59.667-106.72-78.373-151.38-71.361-36.32 5.704-39.99 38 10.12 29.777 33.97-5.574 87.12 1.305 106.07 41.583 1.84-31.698-32.77-97.383-92.89-97.383-29.37 0-40.36-8.337-40.36-20.084 0-7.958 8.78-15.158 29.37-15.158 37.92 0 87.03 34.232 99.66 75.031-10.61-54.947 2.85-103.899 43.33-132.379 20.09-14.135 19.43-39.867-.13-45.726-26.19-7.844-74.52 45.347-62.15 123.158-42.82-45.853-77.54-36.409-103.85-44.803-19-6.06-33.44-14.341-32.63-27.766.77-12.742 15.71-17.681 33.41-15.726 40.52 4.478 77.04 38.437 84.32 50.968 6.63-38.274 11.23-67.662.24-99.873-5.71-16.747-20.03-22.977-29.76-20.064-9.82 2.941-14.99 15.539-14.06 27.663 1.49 19.606 15.34 42.006 20.5 47.747M2182.89 1141.12h-45.15"
            />
            <path fill="#AEAEAF" d="M1866.98 871.285h-18.85v82.695h18.85v-82.695Z" />
            <path fill="url(#b)" d="M1935.85 935.131v-18.85h-743.74v18.85h743.74Z" />
            <path
                fill="url(#c)"
                d="M1983 811.2a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55H1598.5l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
            />
            <path
                fill="url(#d)"
                d="m1192.11 916.277-31.2.333c0-25.036 20.29-45.332 45.33-45.332h528.3c26.83 0 53.12-4.896 78.15-14.553l131.74-51.255a39.374 39.374 0 0 1 44.2 11.106l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48v-.003l-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56h-542.43v.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1598.53 871.277-.03.01"
            />
            <path
                fill="#616469"
                d="M1898.15 1094.77V935.129H1917v256.221h-18.85v-77.73"
            />
            <path
                fill="#AEAEAF"
                d="M1227.63 935.133h-18.85v256.217h18.85V935.133ZM1816.23 935.133h-18.85v256.217h18.85V935.133Z"
            />
            <path fill="url(#e)" d="M1857.56 1113.62v-18.85h-524.58v18.85h524.58Z" />
            <path fill="#3A383A" d="M1332.98 935.133h-18.85v256.217h18.85V935.133Z" />
            <path fill="url(#f)" d="M1314.13 1113.62v-18.85h-86.5v18.85h86.5Z" />
            <path fill="url(#g)" d="M1898.15 1113.62v-18.85h-40.59v18.85h40.59Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1970.32 843.684-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56h-542.43l-31.2.333c0-25.036 20.29-45.332 45.33-45.332h528.3c26.83 0 53.12-4.896 78.15-14.553"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1598.5 871.283 166.72-64.86A123.791 123.791 0 0 1 1810.1 798h132.02c25.26.632 39.28 10.34 46.51 18.578l2.72 3.177c4.94 5.776 2.69 14.7-4.39 17.456l-16.64 6.475"
            />
            <path
                fill="#616469"
                d="M1314.13 1094.77V935.129h18.85v256.221h-18.85v-77.73"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2146.19 367.126v-25.321h-55.25v55.248h55.25v-16.219M2074.05 451.851v-5.597"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2146.19 367.131h36.7v59.814h-36.7v-12.987h-21.45v99.503c0 4.673-3.79 8.448-8.45 8.448-2.33 0-4.44-.941-5.97-2.475a8.373 8.373 0 0 1-2.48-5.973V360.332h-33.79v78.256M2068.3 458.982l-1.66 4.673c-1.48 4.188-1.43 8.825.44 12.861 1.61 3.463 4.13 5.687 6.96 5.687 2.84 0 5.36-2.224 6.96-5.687 1.88-4.036 1.93-8.673.44-12.861l-3.07-8.637c-.54-1.507-1.77-2.718-3.34-3.059a4.563 4.563 0 0 0-.99-.107M2068.31 458.977h5.49M2074.05 446.254h-5.74v-7.668h5.74M2118.56 349.27v5.692M2132.82 355.176l-4.03 4.025M2138.72 369.43h-5.69M2132.82 383.693l-4.03-4.025M2118.56 389.591v-5.693M1961.81 449.71h-110.57v-25.311h120.68V407.5h-120.68v-25.312h120.68"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1955.02 396.838v-31.475h67.53v84.347h-60.74M1929.68 356.91l-1.8-15.105h-13.31l-1.79 15.105a8.447 8.447 0 0 0 8.45 8.449M1879.53 365.36h-8.44v-13.869l-10.88-7.175v-2.511h31.93l-4.16 9.177v9.039M1938.12 449.71v35.509c0 4.666-3.78 8.448-8.44 8.448a8.447 8.447 0 0 1-8.45-8.448V365.363M1879.54 365.363v119.856c0 4.666 3.78 8.448 8.44 8.448 4.67 0 8.45-3.783 8.45-8.448V449.71"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1993.51 425.945h14.62v9.347h-14.62v-9.347ZM1710.49 392.644v-50.839h97.69v50.839h-31.11v-33.941h-49.68v16.899h62.82V392.5M1753.1 420.762h11.4M1727.38 392.648v28.118h11.4"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1790.21 392.5v28.261h-11.4M1764.5 420.762l4.72 15.66h4.87l4.72-15.66M1738.78 420.762l4.73 15.66h4.87l4.72-15.66"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#h)"
            >
                <path 
                    d="M664.705 341.526v330.063H843V315H486.411v356.589h147.41M695.59 646.575V598.07M633.82 646.575V598.07" />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1087.79 283.297v31.913l-15.12 15.114M1087.79 240.594v14.489M1162.84 315.211h-14.48M1087.79 390.387v-14.489M1012.73 315.211h14.49"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1150.06 340.957c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                fill="#FCB47F"
                d="M938.542 523.13c-9.2-7.1-20.001-12.7-32.601-17.2l19.101 31.5c0 .1 17.9-10.8 13.5-14.3Z"
            />
            <path
                fill="url(#i)"
                d="m903.641 504.129 2.7 1c13.1 4.7 23.8 10.3 32.8 17.3.8.6 1.099 1.4 1.099 2.3-.2 4.6-12.199 12.2-14.599 13.7l-.901.5-21.099-34.8Z"
            />
            <path
                fill="#FCB47F"
                d="M926.539 520.528c9.2 2.4 18.399 4.4 27.599 6.5 9.2 2.1 18.3 4.8 27.2 8 9 3.1 17.901 6.5 26.702 10.1 8.8 3.7 17.6 7.5 26.2 12.1 11.3 6 15.6 20 9.6 31.3-4.2 8-12.5 12.4-20.9 12.3-9.7-.2-19.3-1.1-28.802-2.1-9.5-1.1-18.9-2.5-28.2-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.6-21.2-22.3-37.7 4.3-16.6 21.2-26.6 37.7-22.3l.201-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1006.84 579.529-.5-7.7c-.2-2.6-.4-5.1-.6-7.7-.1-2.6-.4-5.1-.4-7.7 0-1.3-.1-2.6-.1-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7 1.1-8.7 9.1-14.9 17.8-13.8 6.1.8 11 5 13 10.5 1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.6 2.5.9 3.8.3 1.3.5 2.5.8 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.7 5.1 1.1 7.6l1.1 7.6c1.8 12.3-6.7 23.7-19 25.6-12.3 1.9-23.7-6.7-25.6-19-.1-.4-.2-1-.2-1.6Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1032.54 600.227c-14.1.8-28.8-.6-38.3-1.6-9.5-1.1-18.899-2.5-28.199-4.1-9.4-1.6-18.601-3.6-27.701-6.2-9.1-2.6-18.199-5.4-27.299-7.7-16.6-4.3-26.601-21.2-22.301-37.7 4.3-16.6 21.2-26.6 37.7-22.3h.101c9.2 2.4 18.399 4.4 27.599 6.5 9.2 2.1 18.3 4.8 27.2 8 7 2.4 13.9 5 20.8 7.7"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1006.34 571.828c-.2-2.6-.4-5.1-.6-7.7-.1-2.6-.4-5.1-.4-7.7 0-1.3-.1-2.6-.1-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7M1037.94 514.027c1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.6 2.5.9 3.8.3 1.3.5 2.5.8 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.7 5.1 1.1 7.6l1.1 7.6c1.8 12.3-6.7 23.7-19 25.6"
            />
            <path
                fill="#FCB47F"
                d="M1046.14 489.829c-3.6-4.5-8.1-8.2-13.2-11-2.1-1.2-4.3-2.001-6.6-2.401h-.1c-.2-4.9-.4-9.799-.5-14.699-.1-3.2-.1-6.3-.2-9.4 0-1.5-1.2-2.8-2.7-3.1h-.1c-1.6-.2-3.1.8-3.5 2.3-1.4 5.2-1.6 10.699-1.8 16.099-.3 6.4-.7 12.901-3.4 18.701.1-.2-1.4 3.499-1.3 3.299l-2 4.201c-.4-1.2-.5-2.5-.3-3.8.2-1.8.7-3.5.9-5.3.1-1 .1-2.101-.1-3.101-.3-1.2-1.3-2.1-2.6-2.2-1.2-.1-2.4.6-2.9 1.7-.8 1.8-2 3.8-2.9 5.3-2.5 4.4-2.6 9.8-1.2 14.7 1.3 4.9 4 9.201 6.8 13.401 8.6 13.8 23.7 16.8 29.7 5.2 3.9-6.8 7-14.4 9.5-21.9.8-2.8.3-5.8-1.5-8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1008.44 514.528c-2.8-4.2-5.5-8.601-6.8-13.401-1.3-4.9-1.2-10.3 1.2-14.7.9-1.5 2.1-3.5 2.9-5.3.5-1.1 1.7-1.8 2.9-1.7 1.3.1 2.4 1 2.6 2.2.2 1 .2 2 .1 3.1-.2 1.8-.7 3.501-.9 5.301-.2 1.8 0 3.699 1 5.099M1014.34 485.829c-.1.2-.1.3-.2.5 2.7-5.8 3.2-12.3 3.4-18.7.2-5.4.4-10.9 1.8-16.1.4-1.6 1.9-2.6 3.5-2.3h.1c1.5.2 2.7 1.5 2.7 3.1.1 3.2.1 6.3.2 9.4.2 4.9.4 9.8.5 14.7M1024.74 479.73c-2.9 2.6-5.9 5.4-7.4 9-1.5 3.6-1.3 7.7-.3 11.4.3 1.3.8 2.601 1.8 3.401 1 .8 3.2 1.799 4.9-1.101M1031.54 485.23c-2.8 1.9-4.61 5-5.81 8.2-1.1 3.2-1.6 6.601-2 9.901-.2 1.2-.3 2.599.5 3.599.8 1.1 2.51 1.3 3.81.8 1.3-.5 3.1-2.9 3.8-4.1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1029.44 476.93c6.5 2.9 12.3 7.4 16.8 12.9 1.8 2.2 2.4 5.3 1.4 8-2.5 7.6-5.6 15.1-9.5 21.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1039.23 491.43c-4 4-7.6 10.4-8.4 16.2-.2 1.1.4 2.2 1.5 2.7h.1c1.2.6 2.6.1 3.3-1 1.8-2.7 4.21-5 6.81-7"
            />
            <path
                fill="url(#j)"
                d="M1052.54 574.527c-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-3.3-14.6-4.8-20.2 0 0-10.1 4.3-22.4 1.6-12.2-2.7-16.5-10.1-16.5-10.1-.7 6.7-1 12.6-1.2 19.6-18.599-7.5-38.699-14.4-58.299-18.4-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.4-1-8.1-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.2 5.1 27.3 7.7 17.5 5.1 38.2 8.2 56.1 10.4 7.799.9 18.199 1.9 28.899 2.2 1.5 0 2.9-.1 4.4-.3 14.9 1.3 27.6-12.3 25.2-27Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M938.039 589.228c17.5 5.1 38.2 8.2 56.1 10.4 7.801.9 18.201 1.9 28.901 2.2 1.5 0 2.9-.1 4.4-.3 14.8 1.4 27.5-12.2 25.2-26.9-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-4-15.6-5.8-20.6 0 0-6.1 4.3-20.2 2.3-13.8-1.9-16.8-10.1-16.8-10.1-.9 5.6-1.7 12.3-1.9 19.3-18.601-7.5-38.702-14.4-58.302-18.4M906.34 505.027c13.1 4.7 23.8 10.3 32.8 17.3"
            />
            <path
                fill="url(#k)"
                d="m976.839 670.328-170.8 18.6v244.9h177.3v-193.9l-6.5-69.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M983.337 761.227v172.6h-96.399"
            />
            <path
                fill="#FCB47F"
                d="M808.542 724.227c-12.6 21.7-20.899 32.3-16.599 69 3.3 28.2 88.999 13.6 105.299 18.3 23.7 9.5 39.101-42.1 39.101-42.1s24.6-11.7 33.3-45.2"
            />
            <path
                fill="url(#l)"
                d="M808.542 724.227c-12.6 21.7-20.899 32.3-16.599 69 3.3 28.2 88.999 13.6 105.299 18.3 23.7 9.5 39.101-42.1 39.101-42.1s24.6-11.7 33.3-45.2"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M808.542 724.227c-12.6 21.7-20.899 32.3-16.599 69 3.3 28.2 88.999 13.6 105.299 18.3 23.7 9.5 39.101-42.1 39.101-42.1s24.6-11.7 33.3-45.2"
            />
            <path
                fill="#FCB47F"
                d="M895.738 1138.63c-.5-7.7-.9-15.4-1.1-23.1-.1-3.8-.3-7.7-.4-11.5l-.2-11.5c-.2-7.7-.2-15.3-.6-23-.3-7.7-1-15.5-1-23.1-.2-15.3 3.2-30 7.1-44.5 2.1-7.302 4.5-14.502 7.4-21.602.7-1.8 1.5-3.5 2.3-5.3.8-1.8 1.6-3.5 2.5-5.3 1.7-3.5 3.6-7 5.9-10.4l6.9-10.6c7.9-12.1 24.1-15.4 36.1-7.5 9.5 6.2 13.6 17.7 11 28.1l-10.9 43.802-5.4 21.9-5.6 21.9c-3.7 14.6-7.4 29.2-11.2 43.8l-11.4 43.7c-2.2 8.5-10.9 13.6-19.4 11.4-6.8-1.8-11.4-7.7-11.9-14.3l-.1-2.9Z"
            />
            <path
                fill="#FCB47F"
                d="M916.039 968.427c-5.1-15.1-9.8-30.2-14.7-45.3l-7.199-22.7-3.601-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6-4-27.8 15.2-53.6 43-57.7 26-3.8 50.201 12.8 56.601 37.7l1.2 4.9c1 3.8 1.799 7.6 2.599 11.5l1.101 5.8 1 5.8c.699 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.401 11.9c-.1 4-.399 8-.599 12-.9 16-2.4 32-5.3 48.3l-.801 4.3c-2.9 15.6-17.9 26-33.5 23.1-10.6-1.8-18.7-9.4-22-19Z"
            />
            <path
                fill="url(#m)"
                d="M916.039 968.427c-5.1-15.1-9.8-30.2-14.7-45.3l-7.199-22.7-3.601-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6-4-27.8 15.2-53.6 43-57.7 26-3.8 50.201 12.8 56.601 37.7l1.2 4.9c1 3.8 1.799 7.6 2.599 11.5l1.101 5.8 1 5.8c.699 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.401 11.9c-.1 4-.399 8-.599 12-.9 16-2.4 32-5.3 48.3l-.801 4.3c-2.9 15.6-17.9 26-33.5 23.1-10.6-1.8-18.7-9.4-22-19Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m895.938 1141.53-.2-2.9c-.5-7.7-.9-15.4-1.1-23.1-.1-3.8-.3-7.7-.4-11.5l-.2-11.5c-.2-7.7-.2-15.3-.6-23-.3-7.7-1-15.5-1-23.1-.2-15.3 3.2-30 7.1-44.5 2.1-7.303 4.5-14.503 7.4-21.603.7-1.8 1.5-3.5 2.3-5.3.8-1.8 1.6-3.5 2.5-5.3M971.738 969.328l-10.9 43.802-5.4 21.9-5.6 21.9c-3.7 14.6-7.4 29.2-11.2 43.8l-11.4 43.7M960.539 753.629s3.3 7.4 6.1 17.2c1.1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-3 41.6-6 57.6M916.04 968.428c-5.1-15.1-9.8-30.2-14.7-45.3l-7.2-22.7-3.6-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6M951.941 964.227h15.5"
            />
            <path
                fill="#2B2729"
                d="M895.041 1136.03c-.4 4.3-4.6 20.4-7.5 30.7-1.4 5 1.8 10.1 6.8 11.2l19.9 4.2c4.2.9 8.3 2.4 12.1 4.5 4 2.2 10.5 5.3 19.5 7.2 4 .9 19 1.8 26.2 2.2 2.2.1 4.1-1.5 4.4-3.7.2-1.6-.6-3.2-1.9-4-2.9-1.8-7.9-4.9-12-7.2-18.5-10.4-35.7-38.5-35.7-38.5"
            />
            <path
                fill="#63666A"
                d="M972.338 1197.13h-.3c-8.1-.5-22.4-1.4-26.4-2.2-9.1-2-15.8-5.1-19.7-7.3-3.6-2-7.6-3.5-11.8-4.4l-19.9-4.2c-2.7-.6-5-2.2-6.5-4.6-1.4-2.4-1.8-5.2-1.1-7.8 1.2-4.4 2.3-8.6 3.3-12.4.1-.5.6-.8 1.1-.7 4 .7 7.8 2.2 11.5 3.6.7.2 1.3.5 2 .7 5.9 2.2 12.2 4 18 2.4 2.3-.6 4.3-1.7 6.5-2.8 1.1-.5 2.1-1.1 3.2-1.6 1-.5 2.1-.9 3.2-1.2.4-.1.8 0 1.1.4 6.5 8.5 16.3 19.7 26.5 25.5 4.2 2.4 9.4 5.5 12.1 7.2 1.7 1 2.6 3 2.4 5-.4 2.5-2.6 4.4-5.2 4.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M975.94 1195.73c-.9.9-2.2 1.4-3.5 1.4h-.3c-8.1-.5-22.4-1.4-26.4-2.2-9.1-2-15.8-5.1-19.7-7.3-3.6-2-7.6-3.5-11.8-4.4l-19.9-4.2c-2.7-.6-5-2.2-6.5-4.6"
            />
            <path
                fill="#EF0025"
                d="M889.339 1141.73v-.9c0-.1 0-.1.1-5.2 1.4-53 2.1-83 2.1-89.3-.2-17.5 3.3-45.3 11.5-67.801 3.7-10.2 4-21.6.7-32.1-14.1-44.6-31.7-110.2-39-160.5-4.1-28.3 15.6-54.7 43.9-58.8 2.5-.4 5-.5 7.5-.5 23.7 0 45.3 16 51.2 39 23.8 97.6 7 201 6.3 204-24.7 99.601-33.5 129.101-39.2 150.601-2.1 8.2-4 15.2-6.4 24.5l-.2.8-38.5-3.8Z"
            />
            <path
                fill="#FCB47F"
                d="M834.843 1155.23c-1.9-8-3.7-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.903 3.3-23.903.4-2 .8-4 1.3-6s.9-4 1.5-6c1.1-4 2.3-8 3.9-11.9l4.4-11.2c5.3-13.5 20.6-20.1 34-14.7 10.8 4.3 17.2 14.9 16.5 25.9l-2.7 47.703-1.4 23.9-1.5 23.9c-1 15.9-2 31.8-3.1 47.7l-3.3 47.7c-.6 8.8-8.2 15.4-17 14.8-7.1-.5-12.8-5.6-14.4-12.2l-.6-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M822.443 965.526c-3.5-15.4-6.7-30.8-9.9-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.8-17.6-11.2-19.8-21Z"
            />
            <path
                fill="url(#n)"
                d="M822.443 965.526c-3.5-15.4-6.7-30.8-9.9-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.8-17.6-11.2-19.8-21Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m835.544 1157.83-.6-2.6c-1.9-8-3.7-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.904 3.3-23.904.4-2 .8-4 1.3-6s.9-4 1.5-6c.5-1.9 1.1-3.8 1.7-5.7M878.844 964.227l-2.7 47.703-1.4 23.9-1.5 23.9c-1 15.9-2 31.8-3.1 47.7l-3.3 47.7M892.24 770.824l.701 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.399 5.8c.3 3.9.401 7.7.601 11.6.2 3.9.3 7.8.3 11.6.3 15.5.199 31.1-.901 46.6-.5 7.8-1.399 15.6-2.399 23.4l-1.601 11.7-1.799 11.7c-2.6 15.6-5.6 31.3-10.3 47.1M822.442 965.527c-3.5-15.4-6.7-30.8-9.9-46.1l-4.9-23.1-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-5.8-41.4-5.9-45.2M858.543 964.227h15.4"
            />
            <path
                fill="#2B2729"
                d="M834.844 1155.23c.5 4.3-.3 20.9-1 31.6-.3 5.2 3.8 9.5 9 9.5h57.6c2.5 0 4.4-2 4.4-4.4 0-1.6-.9-3.1-2.3-3.9-3.8-2-11.2-6.1-15-8.6-8.5-5.7-20.301-24.2-20.301-24.2"
            />
            <path
                fill="url(#o)"
                d="M900.442 1197.32h-57.6c-2.8 0-5.4-1.1-7.3-3.1-1.9-2-2.9-4.7-2.7-7.4.2-3.3.4-7.1.6-11 0-.3.2-.6.4-.8.2-.2.5-.2.8-.2 4.4 1.1 9.099.1 12.599-2.6 1.4-1 2.501-2.3 3.701-3.6 1-1.1 2-2.2 3.1-3.2 4.7-4.1 11.6-5.8 18-4.5.2.1.5.2.6.4 4.3 6 10.5 13.9 15.4 17.2 3.7 2.5 10.9 6.4 14.9 8.5 1.8.9 2.9 2.8 2.9 4.8.1 3.1-2.4 5.5-5.4 5.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M904.643 1195.33c-1 1.2-2.5 2-4.2 2h-57.6c-2.8 0-5.4-1.1-7.3-3.1"
            />
            <path
                fill="url(#p)"
                d="M808.542 724.227c-9.2 15.9-16.1 25.8-17.5 44.3-.8 4-1.1 8-.9 12.2 1.6 38.4 8.7 89 19.4 139 .9 4.2 2.5 13.8 4 23.2.8 4.7 1.5 9.3 2.2 13.3 1.2 6.8.9 13.8-.9 20.8-5.9 24.103-7.2 59.803-2.8 83.003.9 5.2 3.7 24.5 6.6 44.9 3.6 24.6 7.3 50.1 8 53.3l.2.8 41-2.8.1-.9c.9-12.8 1.5-22.9 2.2-32 2.3-34.5 6.7-55.3 13.3-160.203 8.3-28.1 13.5-101.6 15.6-134.9.7-11 5.2-21.3 12.7-29.3l24.8-29.5s24.6-11.7 33.3-45.2h-161.3Z"
            />
            <path
                stroke="#FF1F1F"
                strokeMiterlimit={10}
                d="M815.441 549.828h107M815.941 549.828h105.7"
            />
            <path
                fill="#FCB47F"
                d="M920.943 404.231c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3.8 11.6.8 12.4l-8.6 6.2-46.7-18 2.6-11.6.4-13s-1.1-8.5-9.1-24c-10.1-19.5 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.3-9.7 40.9-2.7 56.6 13Z"
            />
            <path
                fill="url(#q)"
                d="M927.741 428.429c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.8.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.1 1.1-55.7Z"
            />
            <path
                fill="url(#r)"
                d="M904.043 505.829c-.2-3.7-.4-7.4-.5-11.1-14.5 1.2-30.2-11.6-36.6-18.4-.9-1 2.2 13.8 5.3 24.1 7.3 3.3 22.8 8.7 31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M866.945 475.529c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 3.9 8.6 1.8 21.1-.7 30"
            />
            <path
                fill="#FCB47F"
                d="M914.541 433.73c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M914.541 433.73c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
            />
            <path
                stroke="url(#s)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M929.042 430.229c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#t)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M906.741 428.129c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M923.642 445.428c.774 0 1.4-1.03 1.4-2.3 0-1.27-.626-2.3-1.4-2.3-.773 0-1.4 1.03-1.4 2.3 0 1.27.627 2.3 1.4 2.3ZM898.041 445.428c.773 0 1.4-1.03 1.4-2.3 0-1.27-.627-2.3-1.4-2.3-.774 0-1.4 1.03-1.4 2.3 0 1.27.626 2.3 1.4 2.3Z"
            />
            <path
                fill="#7A5430"
                d="M869.85 446.754c0 31.9 6.9 41.9 12 47 5.4 5.4 13.7 11.6 25.6 11.6 13.5 0 21.8-7.8 24.3-15.9 2.9-9.4.1-18-4.2-23.5-2.9-3.8-6-4.3-9.3-4.4-3.9 1.4-8.6 1.4-12.7.7-2.8-.5-6.3-1.5-9-.6-2.3.7-4 2.6-5.8 4.3-1.8 1.7-3.4 3.2-5.8 3-2.9-.3-4.9-3-6.5-5.4-1.5-2.2-3.3-11.5-3.3-16.8h-5.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M919.044 469.529c-7.6 1.2-15.4.5-22.7-1.9M865.843 442.529c-1.9-1.6-3.8-3.3-6.2-4.2-2.4-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M808.639 725.427c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
            />
            <path
                fill="url(#u)"
                d="M808.639 725.427c-.6-9.7-9-42.3-16.1-68.4-6.6-24.3-10.3-49.3-10.9-74.5l-.5-22.2c-.4-15.6 5.9-30.1 18.1-39.9 16.3-13.1 31.8-20.5 49.9-28.2 32.2 15.6 42.1 16.4 56 13.8 2.1 1.4 4.3 2.8 6.4 4.4 26 19 46 59.7 52.2 82.2 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M952.441 564.027c5.7 12.2 8.9 20 11.3 28.6 3.2 11.7 2.8 23.8-8.2 29.8 14 28 25 49 14 103M808.641 725.427c-.6-9.7-9.001-42.3-16.101-68.4-6.6-24.3-10.299-49.3-10.899-74.5l-.5-19.4c-.8-24 9.7-36.9 21.7-45.6 12.4-9 23-15.5 41.3-23.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M955.74 622.627c-3.6-6.2-7.699-12.9-11.099-16.9M846.539 623.928c31.1 3.8 56.5-1.2 71.7-22.1M946.641 701.427c3.6-1.3 7.1-1.4 10.3 0"
            />
            <path
                fill="url(#v)"
                d="m784.34 789.827-.1-.9c-2.9-23.3 1.1-43.9 4.9-63.7 4.4-22.7 8.5-44.1 2.3-67.9-6.6-25.5-10.3-50.6-10.9-74.7l-.5-22.6c-.4-15.6 6.5-30.7 18.5-40.3 14.8-11.9 28.8-19.1 46.6-26.8l.4-.2.4.2c6.3 3.4 11.3 8.7 15.7 13.4 2.1 2.2 4 4.3 5.9 6 8.5 7.6 16.2 11.2 23.7 11.2 1.3 0 2.7-.1 4-.4 2.1-.4 3.9-1.3 5.2-2.8 3.3-3.6 3.3-8.8 2.8-13.7l-.1-.6 1.9-1.2.5.3c2.2 1.4 4.4 2.9 6.5 4.5 27.2 19.9 46.6 61.3 52.5 82.8 2.1 7.8 2.4 14.4.7 19.8-3.3 10.4-3 21.1.8 30.3 5.8 13.8 8.5 26.3 8.8 40.6.3 11.6 0 104.8 0 105.8v1h-190.5v-.1Z"
            />
            <path
                fill="url(#w)"
                d="m818.342 506.03 30-16.1 33.7 81.7c4.8 11.6 7.3 24.1 7.3 36.7v331.8h-110.8l-.8-375.2c0-19.9 9.8-38.5 26.3-49.6l14.3-9.3Z"
            />
            <path
                fill="#fff"
                d="M848.343 489.93c-9.8 31.7-2.4 34.4 4 37.1-7.3 9.3-6.8 10.8-6.8 10.8l41.1 47.6-38.3-95.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m818.342 506.03 30-16.1 33.7 81.7c4.8 11.6 7.3 24.1 7.3 36.7v331.8h-110.8l-.8-375.2c0-19.9 9.8-38.5 26.3-49.6l14.3-9.3ZM793.844 765.828h59.7"
            />
            <path
                fill="url(#x)"
                d="M905.141 506.028s26.4 38.2 44.3 69c13.8 23.7 2 54.4 9.1 63.3 25.6 32.5 24.8 118 24.8 118v-43.8c0-42.3-10.6-60.9-15.4-73.6-2.7-7.2-3.5-14.9-2.4-22.4l.8-5.7c1.8-12.2-.301-24.6-6.101-35.5l-17.099-32.2c-7.2-13.6-17.5-25.2-30.2-34l-7.6-5.3-.2 2.2Z"
            />
            <path
                fill="#fff"
                d="M906.539 506.027c27.9 17.2 28.1 18.7 26.9 24.7 6 2.5 8.5 4.1 8.5 4.1l11.1 47.6c-8.8-17.2-26.5-45.5-46.5-76.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M905.141 506.027s26.4 38.2 44.3 69c13.8 23.7 2 54.4 9.1 63.3 25.6 32.5 24.8 118 24.8 118v-43.8c0-42.3-10.6-60.9-15.4-73.6-2.7-7.2-3.5-14.9-2.4-22.4l.8-5.7c1.8-12.2-.301-24.6-6.101-35.5l-6.099-11.6"
            />
            <path
                fill="#7A5430"
                d="M860.142 470.83c.5-2 .6-4 .3-5.8-5.4-3.8-9.3-9.6-10.7-16-.6-2.9-.8-6.1 1-8.5.5-.6 1.2-1.1 1.9-1.5 8.1-3.8 8.8.7 12.7 2.7 1.7-.4 4-2.6 4.7-4.2 2.5-6.2 3.2-13.2 1.8-19.7 3.3 1.1 7.6-.7 9.1-3.8.9-1.7 1-3.8 1.8-5.5 1.1-2.3 3.6-4 6.2-4.2 2.7-.2 5.2 1.1 7.5 2.6 2.2 1.5 4.3 3.3 6.8 4.4 2.5 1.1 5.6 1.2 7.7-.4.8.7.4.8 1.2 1.6 3.5 3.6 9.3 4.7 13.8 2.6.8 2.2 1.5 4.4 2 6.7 3.8 1 7.8-.9 9.9-4.1 2.1-3.2 2.6-7.3 2.4-11.2-.4-7.4-3.5-15.1-9.8-19.1-1.2-.8-2.7-1.5-4.1-1.2-1.4.3-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.9-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22 6.7 3.6 13.5 8.9 18.3 15.8 2 3.2 6.8 2.4 7.6-1.2Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M940.043 406.53c-.4-7.4-3.5-15.1-9.8-19.1-1.2-.8-2.7-1.5-4.1-1.2-1.4.3-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.9-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M866.042 442.63c-1.9-1.6-3.799-3.3-6.199-4.2-2.4-.9-5.201-.9-7.101.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M843.139 564.231c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-5 7-10.3 13.9-16.1 20.5-8.6 9.6-23.3 10.4-32.9 1.9-6.5-5.8-8.9-14.3-7.3-22.2 1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.7-16.2 8.8-24.2 3.1-8 6.7-15.7 10.7-23.2 4.1-7.5 8.3-14.9 12.1-22.5 7.7-15.3 26.3-21.4 41.6-13.7 15.3 7.7 21.4 26.3 13.7 41.6h.1Z"
            />
            <path
                fill="#FCB47F"
                d="m793.04 637.232 1.5 13.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9-.1 8.6-7.2 15.6-15.8 15.5-7.1-.1-13-4.9-14.9-11.4-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-1.9-12.3 6.5-23.8 18.8-25.7 12.3-1.9 23.8 6.5 25.7 18.8 0 .4.1.8.1 1Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m843.139 564.027-.1.1c-3.9 7.6-7.3 15.4-11 23.1-3.6 7.7-7.8 15.2-12.4 22.4-4.5 7.3-9.3 14.4-14.3 21.4-2.5 3.5-5.1 7-7.8 10.4M749.141 631.229c1.8-8.6 4.3-16.9 7-25.1 2.7-8.2 5.699-16.2 8.799-24.2 3.1-8 19-38.1 22.8-45.8"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M770.041 751.829c-1.3-4.5-2.3-9-3.5-13.5-1.1-4.5-2.1-9-3.2-13.5s-2.1-9-3-13.5c-1-4.5-1.9-9-2.8-13.6-1.8-9.1-3.5-18.1-4.9-27.2l-2.1-13.7-2.1-13.7c-.6-3.8-.5-8.6.7-12M793.84 644.328l.8 6.7 1.6 13.7c1 9.2 1.8 18.3 2.5 27.6.3 4.6.6 9.2.8 13.8.3 4.6.5 9.2.6 13.9.2 4.6.4 9.2.5 13.9.1 4.6.2 9.3.2 13.9"
            />
            <path
                fill="#FCB47F"
                d="M770.94 756.329c-1 6.9-2.2 13.8-2.9 20.7-1.4 13.6.2 27.8 10.5 28.2l.5.7c1.9 2.2 4.7 3.8 8.9 3.9l1.6-.3c2.2 2.9 4.9 4.8 7.9 4.9l.6.2c.6 4 1 8.1 1 11.2 0 1 .7 1.9 1.7 2.1 1.2.3 2.5-.4 2.8-1.7 1.3-6 1.7-14.8 1.6-20.1 0-3-.3-6-.6-9l.2.7c.7 2.3 3 3.8 5.4 3.4l1.9-.3c-3.1-3.4-3.9-8.3-3.8-12.8.1-4.6 1-9.1 1-13.6-.1-8.4-2.3-17.8-8.2-23.9"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M800.441 750.129c5.9 6.1 8.7 16 8.8 24.5 0 4.6-.9 9.1-1 13.6-.1 4.6.801 9.4 3.801 12.8"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M805.14 773.93c-1.6 5.2-1.7 10.7-1.3 16.1.4 5.4 1.2 10.8 1.3 16.2.1 5.3-.3 14.2-1.6 20.1-.3 1.2-1.5 2-2.8 1.7-1-.3-1.7-1.1-1.7-2.1.1-6.1-1.7-15.8-2.8-21.1-1-4.5-1.9-9-2.9-13.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M783.941 791.328c-.3 10 5.5 22.7 13.5 23.1M775.241 791.629c-.3 2.6-.6 17.7 12.7 18.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M778.54 805.228c-10.3-.4-11.9-14.6-10.5-28.2.7-6.9 1.9-13.8 2.9-20.7"
            />
            <path
                fill="url(#y)"
                d="M829.443 522.429c-15.3-7.7-33.9-1.5-41.6 13.7-3.8 7.6-8.1 15-12.1 22.5-10.5 19.2-22.1 52-26.5 72.6-.3 1.3-.4 2.5-.5 3.8-.6 2.6-.7 5.4-.2 8.2 3.9 25.1 8.9 67.1 18.1 109.7l37.7-5.7c-1.3-18.7-4.7-39.3-5.6-55-.8-11.9-3.1-33.7-4.6-46.3 4-4.9 7.7-9.8 11.3-14.9 23.8-33.3 26.6-44.8 37.7-67 7.7-15.3 1.6-33.9-13.7-41.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M843.145 564.027c-11.2 22.2-13.901 33.8-37.701 67-3 4.2-6.099 8.3-9.299 12.4M749.145 631.229c4.4-20.6 16.1-53.3 26.5-72.6 4.1-7.5 8.299-14.9 12.099-22.5M794.043 645.228c1.4 12.5 3.8 34.9 4.7 47 1 15.7 4.4 36.3 5.6 55l-37.7 5.7c-9.2-42.7-14.3-84.6-18.1-109.7-.5-3.1-.3-6.1.4-8.9"
            />
            <path
                fill="#FCB47F"
                d="M1419.13 585.405c-6.9 5.7-14.1 10.999-21.1 16.399-7.1 5.4-13.8 11.301-20.2 17.601-6.4 6.2-12.7 12.699-18.8 19.299-6.1 6.7-12 13.5-17.5 20.9-7.6 10.3-5.5 24.8 4.8 32.5 7.1 5.3 16.2 5.901 23.6 2.401 8.3-3.9 16.2-8.301 24-12.901 7.7-4.6 15.3-9.5 22.7-14.5 7.4-5 14.6-10.399 21.3-16.199 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5h-.1Z"
            />
            <path
                fill="url(#z)"
                d="M1419.13 585.405c-6.9 5.7-14.1 10.999-21.1 16.399-7.1 5.4-13.8 11.301-20.2 17.601-6.4 6.2-12.7 12.699-18.8 19.299-6.1 6.7-12 13.5-17.5 20.9-7.6 10.3-5.5 24.8 4.8 32.5 7.1 5.3 16.2 5.901 23.6 2.401 8.3-3.9 16.2-8.301 24-12.901 7.7-4.6 15.3-9.5 22.7-14.5 7.4-5 14.6-10.399 21.3-16.199 6.8-5.8 13.4-11.8 20.4-17.4 13.3-10.8 15.3-30.3 4.5-43.6s-30.3-15.3-43.6-4.5h-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1419.23 585.305-.1.1c-6.9 5.7-14.1 11-21.1 16.4-7.1 5.4-13.8 11.3-20.2 17.6-6.4 6.2-12.7 12.7-18.8 19.3-4.6 5-9.1 10.1-13.4 15.5M1425.03 661.106c4.4-3.3 8.8-6.7 12.9-10.3 6.8-5.8 13.4-11.8 20.4-17.4"
            />
            <path
                fill="url(#A)"
                d="m1456.83 564.605-2.7 1c-13.1 4.7-23.8 10.301-32.8 17.301-.8.6-5.9 5.4-5.8 6.4.2 4.6 16.9 8.2 19.3 9.6l.9.5 21.1-34.801Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1459.63 562.805c-12.6 4.5-23.1 10.4-32.6 17.2"
            />
            <path
                fill="#FCB47F"
                d="M1550.63 780.005c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                fill="url(#B)"
                d="M1550.63 780.005c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1404.13 624.105c-3.9 8.6-6.8 16.6-8.6 23.1-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1403.43 677.205c3.6-6.2 7.7-12.9 11.1-16.9M1512.73 678.405c-31.1 3.8-56.5-1.2-71.7-22.1M1412.53 755.903c-3.6-1.3-7-1.4-10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M1339.03 1028.8c.9-8.5 1.7-17 2.4-25.4.3-4.196.7-8.496 1-12.696l.8-12.7c.6-8.4 1-16.9 1.8-25.3.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.3-4.6-49.7-1.7-8.2-3.7-16.3-6.2-24.4-.6-2-1.3-4-2-6.1-.7-2-1.4-4-2.2-6-1.5-4-3.2-8-5.3-12l-5.3-10.2c-6.7-12.9-22.6-18-35.5-11.3-10.4 5.4-15.7 16.8-13.9 27.9l8.5 49.3 4.2 24.6 4.4 24.6c2.9 16.4 5.8 32.8 8.8 49.2l9 49.196c1.6 8.7 9.9 14.4 18.5 12.8 7.1-1.3 12.2-7.1 13-13.9l.4-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1335.63 868.705c-10.2-2-20.4-4.5-30.8-8.3l-3.3-1.2c-6.4-2.3-12.1-6.7-16.1-12.9-9.6-15-5.2-35 9.8-44.6 8.9-5.7 18-10.4 27.1-14.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.8 28.2-7.8 2.4-.5 4.8-1 7.2-1.3 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.3 7.3-.3 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 58-32.5 67.7l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-80.1l.4-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1338.73 1031.4.3-2.6c.9-8.5 1.7-17 2.4-25.4.3-4.197.7-8.497 1-12.697l.8-12.7c.6-8.4 1-16.9 1.8-25.3.7-8.5 1.9-17 2.2-25.4 1-16.9-1.5-33.3-4.6-49.7-1.7-8.2-3.7-16.3-6.2-24.4M1286.23 807.605c-10.4 5.4-15.7 16.8-13.9 27.9l8.5 49.3 4.2 24.6 4.4 24.6c2.9 16.4 5.8 32.8 8.8 49.2l9 49.205"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1286.23 807.604c8.9-5.7 26.9-16.3 36-20.7 4.6-2.2 9.1-4.3 13.7-6.3 4.6-2.1 9.2-4.1 13.8-5.8 9.3-3.5 18.7-5.8 28.2-7.8 2.4-.5 4.8-1 7.2-1.3 2.4-.4 4.8-.8 7.2-1.1l3.6-.5 3.6-.3c2.4-.2 4.9-.3 7.3-.3 21.8.1 42.3 13.7 49.9 35.5 9.7 27.7-4.8 58-32.5 67.7l-.5.2c-2.8 1-5.6 1.8-8.5 2.5h-71.1"
            />
            <path
                fill="#FCB47F"
                d="M1339.63 1029c.1 4.3 3.4 20.6 5.5 31.1 1.1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1339.63 1029c.1 4.3 3.4 20.6 5.5 31.1 1.1 5.1-2.4 10-7.5 10.7l-20.1 3c-4.3.6-8.5 1.9-12.4 3.8-4.1 2-10.8 4.6-19.9 6-4.1.6-19.1.6-26.3.6-2.2 0-4-1.7-4.1-3.9-.1-1.6.8-3.1 2.2-3.9 3-1.6 8.2-4.4 12.4-6.4 19.1-9.2 38-36.2 38-36.2"
            />
            <path
                fill="#3A383A"
                d="M1263.63 1085.2c8.1 0 18.6-.1 21.9-.6 9.2-1.4 16-4.1 20.1-6.1 3.7-1.8 7.8-3 12.1-3.7l20.1-3c2.8-.4 5.2-1.9 6.7-4.2 1.6-2.3 2.2-5 1.6-7.8-3.5-16.8-5.4-27.8-5.5-31l-.4-13-36.3 7.1 2.4 10.4c-2.3 3.2-19.9 27.1-37.3 35.6-4.3 2.1-9.7 4.9-12.5 6.4-1.7.9-2.8 2.8-2.7 4.8.1 2.7 2.4 4.9 5.1 4.9 1.4.2 3 .2 4.7.2Z"
            />
            <path
                fill="#888B8D"
                d="M1256.63 1075.5c2.8-1.5 8.1-4.4 12.5-6.5 7.4-3.6 14.9-10.1 21.2-16.5 2.2-2.2 4.2-4.4 6-6.6 3.4-.4 14.4 4.4 20.2 4.2 11.5-.4 19.6-5.6 26.6-5.8.8 4.4 1.9 9.5 3.1 15.4.6 2.7 0 5.5-1.6 7.8s-4 3.8-6.7 4.2l-20.1 3c-4.3.6-8.4 1.9-12.1 3.7-4.1 2-10.9 4.7-20.1 6.2-.3 0-.7.1-1.1.1-5.1.5-18.1.6-25.4.5-2.7 0-5-2.1-5.1-4.9-.2-2 .8-3.9 2.6-4.8Z"
            />
            <path
                fill="#888B8D"
                d="M1344.53 1067.41c-1.6 2.3-4 3.8-6.7 4.2l-20.1 3c-4.3.6-8.4 1.9-12.1 3.7-4.1 2-10.9 4.7-20.1 6.2-.3 0-.7.1-1.1.1-5.1.5-18.1.6-25.4.5M1340.03 1028.9c1.1-9.5 7.4-86.897 8.3-101.497 1.1-17.9-1.8-37.399-6.2-57.099h73.4c33.2-6.7 53.5-39.3 42.3-71.7-9.3-26-35-39.6-61.9-35.5-21.4 2.5-42.3 8-60.3 16.6-13.8 6.3-26 11.7-40.9 21.1-2 1.3-5.4 2.899-7.4 4.099-11.5 7.1-18.2 17.701-15.9 30.701 2.8 16.5 9.9 57.999 12.7 73.899 2 11.3 10.6 101.997 11.4 123.097 8.7-.9 34.6-2.6 44.5-3.7Z"
            />
            <path
                fill="#FCB47F"
                d="M1439.03 461.405c-4.4 10.2-7.6 24.2-7.6 35.3 0 11.1 1.9 22.2 4.7 32.9 1.6 6.1 2.7 12.9 6.2 18 2.5 3.7 7.8 5.2 12.3 4.8.7-.1 1.3-.1 2-.2-.4 1.3-.8 11.6-.8 12.4l8.6 6.2 46.7-18-2.6-11.6-.4-13s1.1-8.5 9.1-24c10.1-19.5-3.1-37.8-3.1-37.8s-2.8-11.1-18.5-18c-22.3-9.6-40.9-2.7-56.6 13Z"
            />
            <path
                fill="url(#C)"
                d="M1432.23 485.707c2-.2 4-.4 6-.7 2-.2 4.1-.4 5.7.8 1.2.9 1.8 2.4 1.9 3.8.1 1.5.4 3 .1 4.4-2.2 12.9-1.5 21.9.9 24.5.5.6 1 1.3 1.1 2 .1.8-.1 1.5-.2 2.3-.9 4.2-1.8 8.5-2.7 12.7-.5 2.3-.8 4.7-1.1 7-.4 3.9.6 7.3 4.6 9.4-6.6-1.3-13.7-7.7-15.2-10.5-9.5-18.1.8-37.2-1.1-55.7Z"
            />
            <path
                fill="url(#D)"
                d="M1455.93 563.005c.2-3.7.4-7.4.5-11.1 14.5 1.2 30.2-11.6 36.6-18.4.9-1-2.2 13.8-5.3 24.1-7.3 3.3-22.8 8.7-31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1493.03 532.705c-9.3 11.5-23.7 18.3-38.4 19.7-5.6.6-13.7-1.9-18.2-6.6-7.2-7.7-9.7-22.7-6.1-37 1.2-4.9 2.2-7.5 2.1-11.3 0-2.9-.8-6.7-.4-9.8 1.1-9 1.3-18.7 8.9-27.4 25.7-29.5 60.1-12.6 69-1.5 8.2 10.2 12.1 23.8 9.7 36.6-1.6 8.7-5.8 16.7-9.5 24.7-3.9 8.6-1.8 21.1.7 30"
            />
            <path
                fill="#FCB47F"
                d="M1445.43 490.906c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1445.43 490.906c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="url(#E)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1430.93 487.405c2.5-1.2 6.2-1.8 8.8-2.1"
            />
            <path
                stroke="url(#F)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1453.23 485.305c6.4 0 13.1.7 17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1436.33 502.604c-.77 0-1.4-1.03-1.4-2.3 0-1.27.63-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3ZM1461.93 502.604c-.77 0-1.4-1.03-1.4-2.3 0-1.27.63-2.3 1.4-2.3.77 0 1.4 1.03 1.4 2.3 0 1.27-.63 2.3-1.4 2.3Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1441.03 526.705c7.6 1.2 15.4.5 22.7-1.9"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1424.53 709.302c5-7.4 9.2-15 13.3-22.6 4.1-7.7 7.8-15.5 11.4-23.3 3.6-7.8 6.8-15.8 9.3-24 2.6-8.2 5-16.4 7.9-24.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1430.33 812.504h-15.5"
            />
            <path
                fill="url(#G)"
                d="M1379.73 735.905c1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 5.9-21.5 25.4-62.9 52.5-82.8.8-.6 1.9-1.4 3.1-2.2.2-.2.5-.3.7-.5 1.9-1.3 4-2.6 5.3-3.4-.1 1.4-.3 4.8-.3 4.8s0 .3-.1.8c-.6 3.5-2.7 18.2-1.4 23.1l6.1-4.7 18.4-14.1c19.9-8.3 32.3-21.7 32.3-21.7s10.2 3.3 24.4 11.2c.1 0 .1.1.2.1.9.5 1.9 1.1 2.8 1.6 1 .6 2.1 1.2 3.1 1.8 4.7 2.8 9.3 5.9 13.8 9.2 1 .7 1.9 1.4 2.9 2.1.8.6 1.6 1.2 2.3 1.8l.1.1c12 9.4 18.8 24.5 18.5 40v.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5h-.3v.2l-.3-.2-144.6-9.8c-5.6-3.8-11.8-8.6-17.8-13.3-5.4-4.3-10.7-8.6-15-12.1-2.3-1.9-4.5-3.5-6.2-4.7-.1-12.1.2-22.5.9-30.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1452.33 590.703c-1.8 1.8-7.3 12.1-13 23.6M1511.03 546.504c-7 8.1-22.3 18.2-32.2 21.6l-24.6 19-8-19.5-7.3 6.6"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1478.83 568.105 9.4 13.1 20.9-22.6M1432.73 622.906l-9.2 25.8c-2.6 7.3-4.4 14.8-5.3 22.4l-12.9 104.7M1378.73 766.605c0-12.1.3-22.5 1-30.7 1.6-19.1 9.5-32.4 11.9-38.1 4.4-10.8 5.2-21.8 2.3-31-1.7-5.3-1.5-12 .6-19.8 3.2-11.8 10.5-29.5 20.9-46.4M1519.33 549.504c14.9 6.6 28.7 14.5 41.4 24.7 11.9 9.6 18.8 24.6 18.5 40.2l-.5 19.1c-.7 27.5-4.3 53.5-10.7 77.4v11.2c0 20.1.1 42.4-5 84.5"
            />
            <path
                fill="#FCB47F"
                d="M1365.73 1012.2c5.3-6.9 10.5-13.898 15.5-20.898 2.5-3.5 5.1-7 7.6-10.5l7.4-10.6c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.7 2.9-8 5.5-16.1 7.7-24.5.6-2.1 1-4.2 1.5-6.3.4-2.1.9-4.2 1.3-6.4.8-4.3 1.5-8.7 1.8-13.2l.9-11.3c1.2-14.5-9.7-27.2-24.2-28.4-11.8-.9-22.4 6.1-26.6 16.5l-19 47.3-9.5 23.7-9.3 23.7c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.6c-3.2 8.198.9 17.398 9.1 20.598 6.7 2.6 14.2.3 18.4-5.1l1.8-2Z"
            />
            <path
                fill="url(#H)"
                d="M1447.83 868.603c-2.5-.4-3.1-.7-5.6-1.1-11.8-2.1-22.9-9.1-29.6-20.4-11.5-19.4-5.6-44.5 13-57.4l7.4-5.2c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.3 7.1-3.4 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.2 1.8-.6 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.7c3.1-.3 6.3-.4 9.5-.2 28.2 1.6 51.1 24.4 52.8 52.6 1 17.7-6.1 33.7-17.9 44.6-8 7.4-18.6 11.4-29.5 11.4h-66.6l3-.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1364.13 1014.2 1.5-2a750.8 750.8 0 0 0 15.5-20.895c2.5-3.5 5.1-7 7.6-10.5l7.4-10.6c5-7 9.8-14.1 15-21.1 5.1-7 10.6-13.8 15.4-20.9 9.9-14.1 16.4-29.8 22.4-45.7 1.6-4.4 3.1-8.8 4.5-13.2 1.2-3.7 2.2-7.5 3.2-11.2.6-2.1 1-4.2 1.5-6.3M1407.53 817.105l-14.9 39.2-9.5 23.7-9.3 23.7c-6.2 15.8-12.5 31.6-18.7 47.5l-18.5 47.6M1558.83 813.203c1.1 18.2-6.5 34.6-18.9 45.6-7.8 6.9-18 10.5-28.4 10.5h-54.6M1407.53 817.104c2.2-10.8 8.4-20.7 18-27.4l7.4-5.2c4.6-3.2 9.3-5.8 14-8.2 2.4-1.2 4.7-2.3 7.1-3.4 2.4-1.1 4.8-2 7.1-3l7.2-2.7 3.6-1.2 1.8-.6 1.8-.5c4.8-1.4 9.7-2.7 14.6-3.2l6.3-.7"
            />
            <path
                fill="#FCB47F"
                d="M1365.73 1012.2c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.097"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1365.73 1012.2c-3.4 10.8-6.8 20.9-7.4 32.2-.3 6.2-.2 12.4-1.6 18.4-1.3 6-4.4 12-9.5 15.4-3.8 2.5-8.4 3.3-12.9 4-8 1.2-16.1 1.8-24.1 2-4.4.1-7.9-3.8-7.2-8.2 1.5-9.4 6-18.4 10.4-27.2 8.2-16.7 15.1-33.4 23.3-50.097"
            />
            <path
                fill="#3A383A"
                d="M1310.23 1085.21c8.1-.2 16.3-.9 24.3-2 4.5-.7 9.2-1.5 13.3-4.1 4.8-3.1 8.4-8.8 10-16 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.8.2-4.2.5-10.4 3.5-19.7 6.6-29.6l.7-2.2 4.2-6-30.3-19.804-4.7 11.5c-4.1 8.304-7.9 16.804-11.6 25.004-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3.3 4.7 1.8 6.5 1.5 1.8 3.8 2.8 6.2 2.8l-.1.1Z"
            />
            <path
                fill="url(#I)"
                d="M1346.93 1082.3c4.7-4 9.2-12 10.8-19.2 1.1-4.8 1.2-9.7 1.4-14.4.1-1.4.1-2.8.2-4.2.2-4.3.9-8.4 1.7-12.4-8.4-5.4-8.4-9.3-16.8-11.9-8.8-2.7-12.7 1.9-20.1 3.3-3.7 8.2-7.5 16.7-11.6 25l-.5 1c-4.2 8.5-8.5 17.2-9.9 26.4-.4 2.3-.9 6.9-.7 11.1.3 11.2 13.6 22.2 45.5-4.7Z"
            />
            <path
                fill="url(#J)"
                d="M1374.33 1015.6c6.6-11 40.5-69.395 53.1-86.795 11.3-16.2 20.1-37.7 26.8-58.5h57.2c11.3 0 22-4.1 30.1-11.7 40.2-36.5 8.9-105.3-45.1-98.8-7.7.8-14.5 1.8-22.9 4.5-17.2 5.4-34.5 13.9-48.6 24.5-10.5 7.3-19.3 18.3-20.9 30.9l-12.4 36.2c-18.2 45.2-38.2 96.8-56 142.4 4.5 6.495 25.1 16.895 38.7 17.295Z"
            />
            <path
                fill="#FCB47F"
                d="m1313.93 546.503 1.3-1.2c-.7-2.5 1.1-5.1 3-6.8 5.2-4.9 12.6-7.3 19.6-6.5 5.4.6 10.5 3 15.3 5.6 7.1 3.9 13.9 8.3 20.3 13.3 11.2 7.3 4.7 21.1-12.4 23.2-11.6 1.4-24.1-11.2-28.7-18.2l-3.2.2c-2.2.3-4.4.5-6.4 1.6-1.9 1-3.5 3-3.5 5.2 0 1.2.5 2.3.8 3.4.2.8.2 1.6 0 2.3-.2.7-.9 1.1-1.6 1.1-1.3-.1-2.5-.8-3.4-1.7-.6-.6-1-1.3-1.3-2.1l-.9.2c-1 .3-2.2-.2-2.9-1-.7-.8-1.1-1.8-1.4-2.8-1.3-5.8 1.2-11.8 5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1361.32 573.505c-12.8 2.4-27.4-13.9-30.3-19.9M1373.43 550.805c-6.4-5-13.2-9.4-20.3-13.3-4.8-2.6-9.8-5-15.3-5.6-7.1-.8-14.4 1.7-19.6 6.5-1.9 1.8-3.6 4.3-3 6.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1329.32 539.203c-5.6 1.3-11.2 3.4-15.4 7.4-4.2 4-6.7 10-5.3 15.6.3 1 .7 2.1 1.4 2.8.7.8 1.9 1.2 2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1326.23 546.305c-4 .3-7.9 2.4-10.4 5.6-2.5 3.2-3.5 7.5-2.8 11.5.3 1.7.9 3.3 2.1 4.5.9 1 2.1 1.599 3.4 1.699.7.1 1.4-.4 1.6-1.1.2-.7.2-1.499 0-2.299-.3-1.1-.8-2.2-.8-3.4-.1-2.2 1.5-4.201 3.5-5.201 1.9-1 4.2-1.3 6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M1541.63 578.006c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-8.3 2.3-16.6 4.8-24.8 8-12 4.7-17.9 18.2-13.2 30.1 3.1 8.1 10.3 13.4 18.3 14.6 8.7 1.3 17.4 1.9 26 2.201 8.6.3 17.2.399 25.8.199 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5 17-1.9 29.3-17.199 27.4-34.2-1.9-17-17.2-29.3-34.2-27.4h-.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1458.03 600.806-9.6-6.3-9.5-6.4c-6.4-4.2-12.9-8.3-19.5-12.1-3.3-1.9-6.6-3.9-10-5.8-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2-7.9-3.7-17.2-.3-20.9 7.6-2.9 6.2-1.4 13.3 3.1 17.8 2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.1 8.6 7.7 2.9 2.6 5.8 5.1 8.7 7.6 2.9 2.5 5.9 4.9 8.8 7.4 5.9 4.9 11.9 9.6 18.1 14.1l9.3 6.8 9.2 6.8c10 7.4 24.1 5.3 31.5-4.7 7.4-10 5.3-24.1-4.7-31.5-.2-.2-.5-.5-.8-.7Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1491.23 640.604c-6.07 0-11-3.94-11-8.8 0-4.86 4.93-8.8 11-8.8 6.08 0 11 3.94 11 8.8 0 4.86-4.92 8.8-11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1491.23 633.706c-1.27 0-2.3-.851-2.3-1.9s1.03-1.9 2.3-1.9c1.27 0 2.3.851 2.3 1.9s-1.03 1.9-2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1541.73 578.008h-.2c-8.5 1-17 1.5-25.5 2.3-8.5.7-16.9 2-25.3 3.8-8.4 1.7-16.7 3.7-25.1 5.9-4.6 1.3-9.2 2.6-13.7 4.1M1432.23 637.707c3.2 2.5 9.8 4.3 13.8 5 8.7 1.3 17.4 1.9 26 2.2 8.6.3 17.2.4 25.8.2 8.6-.1 17.1-.7 25.5-2 8.4-1.2 16.9-2.6 25.3-3.5M1448.43 594.506l-9.5-6.4c-6.4-4.2-12.9-8.3-19.5-12.1-3.3-1.9-6.6-3.9-10-5.8-3.3-1.9-6.7-3.8-10.1-5.6-3.4-1.8-6.8-3.7-10.2-5.5-3.5-1.7-6.9-3.5-10.4-5.2M1361.03 579.406c2.7 2.7 5.6 5.3 8.4 8 2.8 2.6 5.7 5.1 8.6 7.7 2.9 2.6 5.8 5.1 8.7 7.6 2.9 2.5 5.9 4.9 8.8 7.4 5.9 4.9 11.9 9.6 18.1 14.1l9.3 6.8 9.2 6.8"
            />
            <path
                fill="url(#K)"
                d="M1516.03 579.306c2.6-.2 5.1-.4 7.7-.6 5.8-.5 11.9-.9 17.8-1.6h.1c1.2-.1 2.4-.2 3.6-.2 16.3 0 30 12.2 31.8 28.5.9 8.5-1.5 16.8-6.8 23.5-5.3 6.7-13 10.9-21.5 11.8-5.9.7-11.9 1.5-17.7 2.4-2.5.4-5.1.7-7.6 1.1-1 .1-2.1.3-3.1.4l-4.7-65.2c.1-.1.3-.1.4-.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1570.13 628.806c-5.3 6.7-13 10.9-21.5 11.8-5.9.7-11.9 1.5-17.7 2.4-2.5.4-5.1.7-7.6 1.1-1 .1-2.1.3-3.1.4l-4.7-65.2h.5c2.6-.2 5.1-.4 7.7-.6 5.8-.5 11.9-.9 17.8-1.6h.1c1.2-.1 2.4-.2 3.6-.2M1532.03 578.305l13.3 62.299M1533.83 641.908l-4.9-30.9"
            />
            <path
                fill="#FCB47F"
                d="m1355.13 697.201 8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2 8.7-1.3 14.6-9.3 13.4-18-.9-6.4-5.6-11.3-11.4-12.9-5.7-1.6-11.4-3-17-4.2-5.7-1.2-11.4-2.3-17.1-3.3-1.4-.3-2.9-.5-4.3-.7-1.4-.2-2.9-.4-4.3-.6-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.5-8.6-.8l-8.6-.8c-12.4-1.2-23.4 7.8-24.6 20.2-1.2 12.4 7.8 23.4 20.2 24.6.7.2 1.3.2 1.7.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1345.73 653.703c-5.5 5-11.6 11.8-12.3 18.8-1.2 12.4 7.8 23.4 20.2 24.6.5 0 1 .1 1.5.1l8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2M1426.63 663.701c-5.7-1.6-11.4-3.001-17-4.201-5.7-1.2-11.4-2.299-17.1-3.299-1.4-.3-2.9-.501-4.3-.701-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.5-8.6-.8l-8.6-.8"
            />
            <path
                fill="url(#L)"
                d="m1355.13 697.203 8.6.299c2.9.1 5.8.201 8.6.301 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.101 4.3-.201 5.8-.3 11.6-.6 17.4-1.1 2.2-.2 4.5-.399 6.7-.599-3.4-10.3-3.4-30.7 4.2-34.5-2.8-.7-5.7-1.301-8.5-1.901-5.7-1.2-11.4-2.299-17.1-3.299-1.4-.3-2.9-.501-4.3-.701-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.799c-12.4-1.2-23.4 7.799-24.6 20.199-1.2 12.4 7.8 23.4 20.2 24.6.7.1 1.3.101 1.7.101Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1345.73 653.703c-5.5 5-11.6 11.8-12.3 18.8-1.2 12.4 7.8 23.4 20.2 24.6.5 0 1 .1 1.5.1l8.6.3c2.9.1 5.8.2 8.6.3 2.9 0 5.8.2 8.6.1 1.4 0 2.9 0 4.3-.1 1.4 0 2.9-.1 4.3-.2 5.8-.3 11.6-.6 17.4-1.1 5.8-.5 11.6-1.1 17.4-2M1426.63 663.705c-5.7-1.6-11.4-3.001-17-4.201-5.7-1.2-11.4-2.299-17.1-3.299-1.4-.3-2.9-.501-4.3-.701-1.4-.2-2.9-.399-4.3-.599-2.9-.4-5.7-.6-8.6-1-2.9-.3-5.7-.501-8.6-.801l-8.6-.799"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1418.03 661.504s-11 6.8-4.2 34.5"
            />
            <path
                fill="url(#M)"
                d="M1517.83 638.903c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.3c.9 1.7 3 2.2 4.7 1.1l60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3Z"
            />
            <path
                fill="url(#N)"
                d="m1448.33 672.803 60.7-37.9c1.3-.8 2.7-.7 3.7 0l4 2.9c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5l16 31.3c.2.5.6.8.9 1.1l-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.8-1.6-.1-3.9 1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1471.43 713.201 60.7-37.9c1.7-1.1 2.4-3.3 1.6-5l-16-31.3c-.9-1.7-3-2.2-4.7-1.1l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1467.73 713.201-4-2.9c-.4-.3-.7-.6-.9-1.1l-16-31.3c-.9-1.7-.2-3.9 1.6-5l60.7-37.9c1.3-.8 2.7-.7 3.7 0l4 2.9c-1-.7-2.5-.8-3.7 0l-60.7 37.9c-1.7 1.1-2.4 3.3-1.6 5"
            />
            <path
                fill="#231F20"
                d="M1527.43 673.102a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1523.83 666.403a2.1 2.1 0 1 1 0-4.2 2.1 2.1 0 0 1 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M1500.82 674.049c-2.2-1.27-2.78-4.394-1.28-6.977 1.49-2.582 4.48-3.646 6.68-2.376 2.2 1.27 2.77 4.394 1.28 6.976-1.49 2.583-4.48 3.647-6.68 2.377Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1521.23 677.004a2.099 2.099 0 1 1 0-4.199 2.1 2.1 0 1 1 0 4.199Z"
            />
            <path
                fill="#FCB47F"
                d="M1485.33 709.305c-.3-.6-.6-1.3-.9-1.9l.8-.901c.9-1 1.3-2.299 1.3-3.599-.1-3.7-.9-8.1-2.1-11.2l1.5-.9c3.5-1.7 6.4-5.001 6.7-8.801.3-4.2-2.4-8-5.4-11-5.8-5.8-12.7-13.3-20.9-12.5-10.6 1-21.1 2.7-31.5 5.1-19.5 4.1-23.7 19.401-11.3 30.201 5.5 5 14.3 6.099 20.9 2.699 5.6 5.2 13.3 13.101 16.8 20.701.8 1.8 2.8 2.7 4.6 2h.1c2-.7 3-2.801 2.3-4.701-3.3-9.6-11.1-19.699-17.3-27.099 9.1 7.7 20.2 17.599 29.5 25.599 1.2 1 2.9 1 4.1 0 1-1 1.3-2.499.8-3.699Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1431.23 664.504c10.4-2.3 24.5-5 35.1-6 8.2-.8 15.1 6.7 20.9 12.5 3 3 5.7 6.8 5.4 11-.3 3.8-3.2 7.101-6.7 8.801"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1465.63 672.004c5.9 4 11.9 8.2 15.9 14.1 3.4 5 5.1 11.2 4.9 17.2M1460.93 681.305c4.8 3.9 9.6 7.699 13.7 12.199 3.6 3.9 8.3 10.301 10.7 15.801.5 1.2.2 2.7-.8 3.6-1.2 1-2.9 1-4.1 0-9.3-8-20.4-17.9-29.5-25.6 6.3 7.4 14 17.5 17.3 27.1.7 1.9-.4 4.099-2.3 4.699h-.1c-1.8.6-3.8-.3-4.6-2.1-3.4-7.5-11.1-15.399-16.8-20.599-6.6 3.4-15.5 2.3-20.9-2.7M1475.23 675.906c1.7 1.1 3.4 2.1 5.2 3.2.7.5 1.5 1 1.8 1.8.2.6 0 1.3-.3 1.8s-.8 1-1.2 1.5"
            />
            <path
                fill="url(#O)"
                stroke="#222731"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M855.492 454.331c0 5.49 8.655 13.385 11.742 13.385.995 0 2.08-1.11 1.389-2.653-1.215-2.713-4.579-5.924-3.96-6.441a5.589 5.589 0 1 0-9.17-4.291h-.001Z"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <path
                fill="#E5BC56"
                d="M1500.05 532.252c-.6-3.5-.9-7.1-1.1-10.7 4.4-2.6 7.5-6.6 9.3-11.4 1.4-3.7 2.8-11.2-.6-14.3-1.8-1.7-4.6-1.7-7-1.2-4.9 1-7.6 6-10 10.1h-5.3c0-5-3.9-18.2-6.8-24.9-.6-1.5-.8-3.1-.6-4.6.6-3.6 1.3-7.6 1.7-10.5.3-2.2-1.3-4.1-3.6-4.1-9.7.1-31.4.3-36 .3-1.3.5-3 2.4-3.5 3.6-.8-5.9 3.8-25.2 42.5-25.2 18.8 0 28.5 2.2 32.2 14.2 10 2.6 14.5 18.9 14.3 29.8-.2 10.9-3.9 21.5-8.6 31.4-3.1 6.4-6.3 12.6-9.5 18.8-1.6 3.3-6.8 2.5-7.4-1.3Z"
            />
            <path
                stroke="#D1942A"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1456.15 442.35c5.9-1.9 13.4-3.1 23.1-3.1 18.8 0 28.5 2.2 32.2 14.2 10 2.6 14.5 18.9 14.3 29.8-.2 10.9-3.9 21.5-8.6 31.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1463.84 495.797h29.88M1436.73 495.216a7.49 7.49 0 0 1 2.82-.552c1.25.001 2.23.318 2.81.552"
            />
            <path
                fill="url(#P)"
                d="M1446.26 493.812h11.86c5.11 0 9.25 4.145 9.25 9.257v4.776c0 3.833-3.11 6.94-6.94 6.94h-2.25c-8.74 0-15.82-7.085-15.82-15.824v-1.249c0-2.153 1.74-3.9 3.9-3.9Z"
                opacity={0.7}
            />
            <path
                fill="url(#Q)"
                d="M1432.79 493.814h-7.45c-3 0-5.72 2.255-5.23 5.709.51 3.643 1.67 10.842 6.61 12.364 5.45 1.678 10.01-3.414 10.01-8.943v-5.183c0-2.18-1.76-3.949-3.94-3.949v.002Z"
                opacity={0.7}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1446.26 493.812h11.86c5.11 0 9.25 4.145 9.25 9.257v4.776c0 3.833-3.11 6.94-6.94 6.94h-2.25c-8.74 0-15.82-7.085-15.82-15.824v-1.249c0-2.153 1.74-3.9 3.9-3.9v0ZM1432.79 493.814h-7.45c-3 0-5.72 2.255-5.23 5.709.51 3.643 1.67 10.842 6.61 12.364 5.45 1.678 10.01-3.414 10.01-8.943v-5.183c0-2.18-1.76-3.949-3.94-3.949v.002Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1494.05 499.65c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M810.396 636.854a190.49 190.49 0 0 1 2.209 21.963c.628 19.01-23.932 27.454-35.112 11.67-10.132-14.535-23.265-40.824-28.528-58.608-2.062-6.881-3.955-13.817-6.238-20.624a26.502 26.502 0 0 1 16.697-33.551 26.497 26.497 0 0 1 33.552 16.697c6.706 19.993 12.18 28.195 17.42 62.453Z"
            />
            <path
                fill="#FCB47F"
                d="M811.25 655.443c18.748 34.57 29.895 65.125 35.518 88.647 3.043 12.729-13.477 20.798-21.619 10.422-7.784-9.952-13.951-19.867-20.229-29.911-12.305-19.554-18.075-33.591-26.999-53.091a18.505 18.505 0 0 1 8.742-24.468 18.504 18.504 0 0 1 24.587 8.401Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M777.493 670.487a190.409 190.409 0 0 1-11.379-18.915c-3.427-6.426-6.58-12.943-9.535-19.527a150.96 150.96 0 0 1-7.614-20.166c-2.062-6.881-3.955-13.817-6.238-20.624a26.502 26.502 0 0 1 16.697-33.551 26.497 26.497 0 0 1 33.552 16.697l.046.137c2.246 6.819 4.882 13.509 7.35 20.254a151.098 151.098 0 0 1 5.972 20.712c1.575 7.042 2.951 14.15 4.052 21.35.844 5.645 1.569 11.329 1.963 17.124M825.15 754.513c-5.006-6.401-9.508-13.044-13.861-19.76-1.076-1.685-2.186-3.353-3.234-5.051l-3.134-5.1c-2.127-3.382-4.064-6.855-6.093-10.284a233.854 233.854 0 0 1-10.992-21.144l-4.892-10.862-5.022-10.8M813.246 658.262l3.327 7.84 5.45 10.593a233.894 233.894 0 0 1 9.695 21.769c1.419 3.723 2.93 7.402 4.25 11.172l2.038 5.629c.676 1.878 1.29 3.785 1.938 5.676 2.543 7.589 4.936 15.249 6.825 23.152"
            />
            <path
                fill="#FCB47F"
                d="M857.194 775.446c-1.887-8.124-5.932-22.708-9.172-30.436-11.058-12.291-20.127-5.64-20.168 11.327-.846 3.619-.216 11.398.92 15.931a45.086 45.086 0 0 1 1.643 10.462c.035 3.534-.972 7.264-3.667 9.751l.7 1.294c2.736 1.253 6.625-.208 8.38-2.571a16.948 16.948 0 0 0 2.578-6.714c.103-.472.474.223.474.223 3.023 2.646 5.284 6.966 6.6 11.62a14.75 14.75 0 0 1-4.724-.45 1.81 1.81 0 0 0-1.368.216l-1.86 1.151a5.727 5.727 0 0 0-2.704 4.956 5.728 5.728 0 0 0 2.868 4.862c3.233 1.815 7.258 2.032 9.427.918l.558 1.129a16.408 16.408 0 0 0 3.047 7.928 1.677 1.677 0 0 0 1.247.653 1.68 1.68 0 0 0 1.303-.534l.081-.086a1.732 1.732 0 0 0 .437-1.298c-.317-4.82 2.187-11.215 3.443-15.58 2.319-8.059 1.844-16.627-.043-24.752Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M841.621 777.797a25.29 25.29 0 0 1 6.902 16.776c.063 3.933-.818 7.837-.929 11.771-.11 3.933.684 8.129 3.545 10.978M853.615 814.012c.073-4.75 2.299-9.217 3.622-13.812 2.319-8.059 1.844-16.627-.043-24.752a158.685 158.685 0 0 0-7.557-24.764M827.468 761.438c-.582 4.323.646 7.356 1.306 10.831.661 3.474 1.608 6.927 1.643 10.461.035 3.535-.972 7.264-3.667 9.752M827.895 793.873c2.735 1.253 6.18-.308 7.935-2.67 1.754-2.363 2.27-5.31 2.881-8.126.612-2.817 1.458-5.764 3.65-7.78"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M845.28 794.531a21.44 21.44 0 0 0-6.397-9.816M837.312 806.656c2.842 1.924 7.092 2.727 9.807 1.333M835.844 800.738c2.663 2.156 9.15 2.645 12.18.918M840.027 795.702a10.914 10.914 0 0 0 8.49-.057"
            />
            <path
                fill="#FCB47F"
                d="M782.643 560.701c-8.492-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.959 15.305-12.953Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M751.734 539.449a132.19 132.19 0 0 1 30.909 21.252"
            />
            <path
                fill="url(#R)"
                d="M783.342 559.989a109.595 109.595 0 0 0-20.026-15.329 31.024 31.024 0 0 1 2.394 13.895 65.914 65.914 0 0 1-1.451 10.762l2.169 4.755a1.001 1.001 0 0 0 1.33.492c2.598-1.2 15.601-7.426 16.406-12.093a2.704 2.704 0 0 0-.822-2.482Z"
            />
            <path
                fill="url(#S)"
                d="M820.613 750.164c-6.06-8.26-11.323-16.683-16.414-24.827l-.128-.205c-10.564-16.788-16.215-29.272-23.368-45.077-1.185-2.617-2.405-5.316-3.692-8.128-.119-.254-.22-.516-.322-.78l-.06-.155c-10.786-15.509-23.594-41.841-28.622-58.828a883.296 883.296 0 0 1-1.836-6.241c-1.378-4.727-2.803-9.614-4.391-14.348a27.51 27.51 0 0 1 1.392-21.054 27.493 27.493 0 0 1 26.571-15.231 27.5 27.5 0 0 1 24.182 18.795c1.614 4.813 3.155 8.936 4.646 12.924 4.735 12.669 8.824 23.609 12.815 49.695 1.244 8.318 2.634 14.902 2.889 21.056A380.313 380.313 0 0 1 846 737.06l.209.776-25.089 13.018-.507-.69Z"
            />
            <path
                fill="url(#T)"
                d="m815.748 713.969-173.201 49.779v207.676h177.281V793.276c0-3.2-.088-6.399-.263-9.596l-3.817-69.711Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M723.434 971.426h96.398v-178.21c0-2.119-.075-4.236-.225-6.351l-4.76-67.142"
            />
            <path
                fill="#FCB47F"
                d="M746.684 1143.84a653.663 653.663 0 0 1-1.957-23.3c-.256-3.88-.597-7.76-.781-11.63l-.618-11.59c-.439-7.74-.742-15.46-1.422-23.23-.609-7.76-1.576-15.57-1.828-23.28-.771-15.46 2.043-30.4 5.444-45.25a213.54 213.54 0 0 1 6.552-22.074c.629-1.828 1.376-3.638 2.058-5.457.759-1.809 1.471-3.624 2.297-5.423a99.65 99.65 0 0 1 5.47-10.718l6.403-10.762a26.123 26.123 0 0 1 45.392.867 26.12 26.12 0 0 1 2.638 17.772l-9.232 44.705-4.628 22.35-4.767 22.33c-3.201 14.88-6.304 29.78-9.585 44.65l-9.765 44.62a15.927 15.927 0 0 1-5.923 9.28 15.92 15.92 0 0 1-10.522 3.21 15.916 15.916 0 0 1-10.096-4.37 15.93 15.93 0 0 1-4.846-9.88l-.284-2.82Z"
            />
            <path
                fill="#FCB47F"
                d="M763.501 969.982 747.36 927.32l-4.048-10.663-4.161-10.644c-2.746-7.1-5.795-14.149-8.482-21.26-2.687-7.111-5.126-14.264-7.189-21.482-1.066-3.602-1.971-7.233-2.964-10.848l-1.457-5.429-1.309-5.454c-.861-3.638-1.79-7.264-2.52-10.924-.763-3.655-1.594-7.298-2.23-10.975l-1.018-5.504-.877-5.528a183.49 183.49 0 0 1-1.396-11.117 51.021 51.021 0 0 1 98.835-22.029l1.7 4.811c1.271 3.567 2.359 7.166 3.35 10.782l1.453 5.429 1.28 5.459c.907 3.63 1.572 7.302 2.322 10.959.767 3.654 1.389 7.333 2.009 11.012 1.233 7.36 2.194 14.767 3.047 22.191.85 7.426 1.446 14.894 1.722 22.418.283 7.523.096 15.126-.237 22.754l-.528 11.446c-.201 3.82-.493 7.656-.735 11.483-1.188 15.345-2.914 30.783-6.488 46.537l-1.267 5.582a27.562 27.562 0 0 1-12.188 17.225 27.565 27.565 0 0 1-20.798 3.562 27.85 27.85 0 0 1-19.685-17.131Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m746.968 1146.66-.284-2.82a651.708 651.708 0 0 1-1.957-23.3c-.256-3.87-.597-7.76-.781-11.62l-.618-11.6c-.439-7.74-.742-15.46-1.422-23.23-.609-7.76-1.576-15.57-1.828-23.28-.771-15.46 2.043-30.4 5.444-45.25a213.724 213.724 0 0 1 6.552-22.072c.629-1.827 1.376-3.637 2.058-5.457.759-1.808 1.471-3.623 2.297-5.422a99.557 99.557 0 0 1 5.47-10.718M816.333 969.766l-9.233 44.704-4.628 22.35-4.766 22.33c-3.201 14.88-6.305 29.78-9.585 44.65l-9.766 44.62"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M759.164 958.52c-29.446-77.761-30.074-75.628-35.684-95.248-7.06-23.855-12.388-51.345-13.771-65.78a51.026 51.026 0 0 1 39.69-54.661 51.018 51.018 0 0 1 59.145 32.632c21.8 61.184 16.081 126.628 16.119 127.261-1.369 25.969-8.345 67.143-8.332 67.042"
            />
            <path
                fill="#FCB47F"
                d="M746.651 1142.19c-.35 3.89-4.166 18.32-6.717 27.67a8.078 8.078 0 0 0 .902 6.35 8.153 8.153 0 0 0 2.239 2.39 8.082 8.082 0 0 0 3.003 1.31l24.769 5.23s13.341 10.88 38.22 10.88c5.773 0 10.598-1.61 11.236-4.63.629-2.98-6.743-6.89-11.828-9.15-17.419-7.77-30.12-33.82-30.12-33.82"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M746.651 1142.19c-.35 3.89-4.166 18.32-6.717 27.67a8.078 8.078 0 0 0 .902 6.35 8.153 8.153 0 0 0 2.239 2.39 8.082 8.082 0 0 0 3.003 1.31l24.769 5.23s13.341 10.88 38.22 10.88c5.773 0 10.598-1.61 11.236-4.63.629-2.98-6.743-6.89-11.828-9.15-17.419-7.77-30.12-33.82-30.12-33.82"
            />
            <path
                fill="#001489"
                d="m740.56 1117.29-.061-.94c-3.165-49.24-3.937-57.95-4.191-60.82-.115-1.28-.116-1.31-.182-3.37l-.042-1.32c-1.19-23.83 8.998-64.58 21.848-87.507-2.207-6.138-26.955-74.954-28.192-78.228-11.043-29.224-19.303-69.605-21.02-87.52l-.064-.662 84.106-45.103.516.42a51.936 51.936 0 0 1 16.215 22.886c.315 1.064.677 2.038 1.135 3.271 1.643 4.419 4.24 11.847 9.008 34.303 16.907 79.638 1.483 145.273-25.44 267.83-2.523 11.48-5.148 23.48-7.875 35.97l-.172.79H740.56Z"
            />
            <path
                fill="url(#U)"
                d="M809.067 1197.02c-23.625 0-36.848-9.56-38.656-10.95l-24.54-5.18a9.25 9.25 0 0 1-3.373-1.47 9.13 9.13 0 0 1-2.515-2.69 9.077 9.077 0 0 1-1.244-3.46 9.141 9.141 0 0 1 .23-3.68c.662-2.42 1.41-5.19 2.159-8.03l.323-1.22 1.117.59c7.054 3.79 15.323 8 24.194 10.09 3.58.86 7.248 1.3 10.929 1.31a35.12 35.12 0 0 0 14.942-3.08l.673-.31.494.55c5.053 5.64 10.127 9.62 15.082 11.83 8.835 3.94 13.007 7.4 12.399 10.28-.705 3.34-5.385 5.42-12.214 5.42Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M821.281 1191.6c-.705 3.34-5.385 5.42-12.215 5.42-23.625 0-36.847-9.56-38.655-10.96l-24.54-5.17a8.944 8.944 0 0 1-3.379-1.47 8.949 8.949 0 0 1-2.512-2.69"
            />
            <path
                fill="#FCB47F"
                d="M770.145 466.991c-1.417-4.724 1.21-10.014 1.21-15.21-1.231-9.262-13.899-23.646-24.657-26.985-18.459-5.728-33.305-1.408-44.533 6.257-3.243 2.214-26.645 23.439-.737 66.892 11.087 17.266-5.224 32.566-5.224 32.566 4.684 6.464 39.361 33.253 53.232 28.629 13.871-4.624-.783-20.395-3.043-27.093-1.491-4.418-2.368-8.206-1.965-11.514 6.307 1.627 10.494 1.905 14.415-.357 13.589-6.348 14.367-23.851 14.137-33.358-.245-10.12-1.418-15.103-2.835-19.827Z"
            />
            <path
                fill="url(#V)"
                d="M751.157 540.033c-4.458-6.155-6.567-10.99-6.6-19.61-13.023-2.157-20.074-8.959-26.793-16.341-1.217-1.337 6.675 29.123 7.256 30.835.581 1.712 1.39 2.179 2.341 3.716 4.313 6.968 24.454 2.309 23.796 1.4Z"
            />
            <path
                fill="url(#W)"
                d="M769.587 458.469c-1.656-2.074-9.799-2.899-10.265 4.216-.602 9.199 5.205 23.632 2.882 26.203-.767.85-2.716 2.542-2.818 3.683-.364 4.074.175 8.246.426 12.334.292 4.742 1.342 13.154-3.117 15.984.436-.581 6.437-3.119 7.022-3.551 10.709-7.906 10.294-30.717 8.125-44.774-.402-2.607-2.001-5.222-2.354-7.836-.34-2.524.987-5.146.099-6.259Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M718.18 503.944c8.184 14.378 32.272 20.999 42.229 15.605 8.717-4.723 12.776-14.241 12.301-33.178-.239-9.511-1.146-14.657-2.563-19.381-1.417-4.724 1.385-9.7.931-14.877-.997-11.39-14.522-29.06-36.859-30.636-17.699-1.249-30.466 6.781-35.542 13.107-7.682 9.575-11.397 22.366-9.155 34.435 1.52 8.182 5.299 15.746 8.902 23.248 4.495 9.359 13.927 19.957-1.561 37.753"
            />
            <path
                fill="#FCB47F"
                d="M759.32 462.684a32.112 32.112 0 0 0 5.737 16.51c.978 1.47 2.111 2.986 2.162 4.751a5.662 5.662 0 0 1-.737 2.594 5.662 5.662 0 0 1-1.851 1.961 12.539 12.539 0 0 1-3.286 1.583"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M759.32 462.684a32.112 32.112 0 0 0 5.737 16.51c.978 1.47 2.111 2.986 2.162 4.751a5.662 5.662 0 0 1-.737 2.594 5.662 5.662 0 0 1-1.851 1.961 12.539 12.539 0 0 1-3.286 1.583M732.953 460.882a19.225 19.225 0 0 1 15.079-4.251M763.059 457.345a15.791 15.791 0 0 1 9.227 2.276M746.699 498.047a24.206 24.206 0 0 0 13.895 2.516"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M742.863 471.837c.779 0 1.41-1.084 1.41-2.422s-.631-2.423-1.41-2.423c-.778 0-1.41 1.085-1.41 2.423s.632 2.422 1.41 2.422ZM766.465 471.837c.779 0 1.41-1.084 1.41-2.422s-.631-2.423-1.41-2.423c-.779 0-1.41 1.085-1.41 2.423s.631 2.422 1.41 2.422Z"
            />
            <path
                fill="#7A5430"
                d="M678.48 440.833c7.52-16.811 20.793-31.853 47.558-33.622 28.544-1.888 100.57-.645 45.983 62.42-.116-.374-.233-.743-.347-1.102-.199-.627-.391-1.234-.569-1.827-.721-2.403-.227-4.959.296-7.667.553-2.293.78-4.653.674-7.009-.505-5.763-4.082-12.981-10.215-19.07l-.892 1.25a.5.5 0 0 0-.057.056c-10.265 14.7-25.196 33.804-45.1 42.395-.593.256-.096 1.036-.228.963-.606-.337-1.011-7.081-6.485-11.338-10.52-8.183-21.496 8.961-7.923 22.912a18.616 18.616 0 0 0 8.321 5.005 53.513 53.513 0 0 1-1.405 11.414 2.508 2.508 0 0 1-2.235 1.866 2.51 2.51 0 0 1-2.49-1.507 113.563 113.563 0 0 0-9.402-17.803c-20.793 3.926-23.673-29.032-15.484-47.336Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M679.837 483.049c-7.396-9.53-7.072-29.444-1.357-42.219 7.521-16.811 20.793-31.853 47.558-33.622 17.292-1.144 50.56-1.146 61.962 13.052"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M715.584 477.618c-.607-.338-1.011-7.081-6.486-11.339-10.52-8.182-21.495 8.962-7.923 22.913a18.624 18.624 0 0 0 8.322 5.005"
            />
            <path
                fill="#FCB47F"
                d="m696.204 530.508-19.771 11.891a61.321 61.321 0 0 0-26.166 73.107l18.823 52.893a36.522 36.522 0 0 1-6.599 36.016c-9.8 11.421-19.809 28.766-23.291 47.32-10.396 55.41 52.31 115.615 131.423 44.511 65.21-58.609 40.337-102.907 28.724-135.092 16.259-6.304 32.185-19.576 12.277-43.133-19.908-23.558-26.375-32.835-31.852-47.115-9.29-24.222-28.617-30.874-28.617-30.874-9.289 6.967-42.343.43-54.951-9.524Z"
            />
            <path
                fill="url(#X)"
                d="m696.204 530.508-19.771 11.891a61.321 61.321 0 0 0-26.166 73.107l18.823 52.893a36.522 36.522 0 0 1-6.599 36.016c-9.8 11.421-19.809 28.766-23.291 47.32-10.396 55.41 52.31 115.615 131.423 44.511 65.21-58.609 40.337-102.907 28.724-135.092 16.259-6.304 32.185-19.576 12.277-43.133-19.908-23.558-26.375-32.835-31.852-47.115-9.29-24.222-28.617-30.874-28.617-30.874-9.289 6.967-42.343.43-54.951-9.524Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m696.208 530.508-19.771 11.891a61.321 61.321 0 0 0-26.166 73.107l18.823 52.893a36.515 36.515 0 0 1-6.6 36.016c-9.8 11.421-21.71 28.508-23.29 47.32-6.352 75.662 65.727 102.575 131.423 44.51 65.695-58.064 40.337-102.907 28.724-135.091 16.258-6.304 32.486-19.834 12.277-43.133a282.835 282.835 0 0 1-20.843-26.789"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M787.902 721.707a13.776 13.776 0 0 1 10.286 0"
            />
            <path
                fill="#FCB47F"
                d="M697.352 596.112c-3.937 6.803-7.517 13.811-11.251 20.731a180.933 180.933 0 0 1-12.51 20.008c-4.55 6.45-9.28 12.796-14.263 18.998a225.624 225.624 0 0 1-15.947 18.029 19.167 19.167 0 0 1-31.227-6.182 19.165 19.165 0 0 1-.844-12.27 225.424 225.424 0 0 1 7.571-22.848c2.857-7.425 5.965-14.705 9.255-21.88a180.969 180.969 0 0 1 11.009-20.87c4.106-6.706 8.365-13.324 12.267-20.146a26.506 26.506 0 0 1 26.357-13.129 26.485 26.485 0 0 1 17.605 10.071 26.503 26.503 0 0 1 5.328 19.57 26.491 26.491 0 0 1-3.284 9.804l-.066.114Z"
            />
            <path
                fill="#FCB47F"
                d="m647.375 663.085.454 13.518.597 13.514c.361 9.01.313 18.029-.145 27.056-.243 4.515-.384 9.026-.736 13.543l-.465 6.773c-.159 2.258-.386 4.517-.576 6.776-.813 9.035-1.791 18.074-3.33 27.124a12.22 12.22 0 0 1-11.786 10.169 12.216 12.216 0 0 1-12.208-9.659c-1.921-8.977-3.283-17.966-4.479-26.958-.286-2.249-.609-4.496-.863-6.745l-.752-6.748c-.544-4.497-.876-8.999-1.31-13.499-.832-9-1.264-18.008-1.294-27.026l.022-13.527-.12-13.525a18.499 18.499 0 0 1 18.107-18.66 18.504 18.504 0 0 1 18.884 17.874Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m697.416 596-.066.114c-3.936 6.803-7.517 13.811-11.251 20.731a180.831 180.831 0 0 1-12.509 20.008c-4.55 6.45-9.281 12.796-14.263 18.998-3.117 3.855-6.303 7.671-9.655 11.391M610.686 660.619c-.029-1.75.181-3.497.623-5.191a225.667 225.667 0 0 1 7.57-22.848c2.857-7.424 5.966-14.704 9.255-21.88a180.963 180.963 0 0 1 11.01-20.869c4.105-6.707 10.568-16.528 14.471-23.351"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M619.178 771.897c-1.921-8.977-3.283-17.966-4.479-26.958-.285-2.249-.608-4.496-.863-6.745l-.752-6.748c-.544-4.497-.875-8.999-1.309-13.498-.832-9-1.264-18.009-1.295-27.027l.023-13.527.183-16.777M647.606 669.91l.821 20.206c.361 9.01.313 18.029-.145 27.057-.243 4.514-.383 9.026-.736 13.542l-.464 6.774c-.159 2.258-.387 4.517-.576 6.775-.813 9.035-1.792 18.074-3.33 27.125"
            />
            <path
                fill="#FCB47F"
                d="M653.966 807.644c.095-2.286.346-4.562.753-6.813a38.46 38.46 0 0 0-9.285-29.757c-10.718-12.746-22.939-13.498-26.698 1.628-1.458 9.565-2.416 19.248-1.674 28.883.741 9.636 3.237 19.264 8.34 27.689.227.402.506.772.83 1.1.301.292.69.478 1.106.529a1.475 1.475 0 0 0 1.417-.817l.223-.309c.901 2.33 1.88 4.636 2.857 6.94a2.475 2.475 0 0 0 2.541 1.151 2.55 2.55 0 0 0 2.087-1.745l.225-.426c.025.641.017 1.282-.024 1.921a1.983 1.983 0 0 0 1.234 1.883c.247.102.512.152.779.149l.04.001a2.088 2.088 0 0 0 2.076-1.383c1.705-5.209 1.538-10.837 1.03-16.288-.16-1.708-.351-3.42-.522-5.132l.379.66a46.622 46.622 0 0 1 2.764 16.316 1.412 1.412 0 0 0 1.432 1.449 3.61 3.61 0 0 0 2.98-2.173 21.836 21.836 0 0 0 2.417-10.557 61.764 61.764 0 0 0-.774-9.219l.269.185a4.737 4.737 0 0 0 2.719 1.537 2.877 2.877 0 0 0 2.777-1.046 9.128 9.128 0 0 1-2.298-6.356Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M645.438 771.074a38.467 38.467 0 0 1 9.284 29.757 50.104 50.104 0 0 0-.753 6.812 9.127 9.127 0 0 0 2.298 6.357M649.642 790.426a91.752 91.752 0 0 0 .275 18.428c.529 4.56 1.402 9.103 1.36 13.69a22.768 22.768 0 0 1-2.418 10.556 3.978 3.978 0 0 1-4.41 1.87l-.027-.007M641.118 804.109c-.782 5.93.151 11.922.707 17.874.508 5.451.675 11.079-1.031 16.288a2.087 2.087 0 0 1-2.075 1.383l-.04-.001a1.994 1.994 0 0 1-1.872-1.252 1.983 1.983 0 0 1-.141-.78 33.072 33.072 0 0 0-1.445-10.221c-1.015-3.947-3.383-15.108-3.077-23.004M631.836 836.719a122.778 122.778 0 0 1-5.007-13.177 59.963 59.963 0 0 1-2.354-20.19M625.402 829.275c-5.103-8.425-7.599-18.053-8.34-27.688-.742-9.636.216-19.319 1.674-28.884"
            />
            <path
                fill="url(#Y)"
                d="M689.355 611.751c-.165 0-.326-.04-.471-.117L641.4 586.298a1.012 1.012 0 0 1-.518-1.029.986.986 0 0 1 .14-.381l.943-1.519c2.871-4.618 5.839-9.394 8.579-14.184a27.509 27.509 0 0 1 16.647-12.879 27.493 27.493 0 0 1 28.974 9.706 27.505 27.505 0 0 1 2.12 30.483l-.065.115c-2.748 4.749-5.367 9.683-7.9 14.455l-.082.155a.999.999 0 0 1-.883.531Z"
            />
            <path
                fill="#FCB47F"
                d="M686.806 1155.9a725.56 725.56 0 0 1-6.053-23.92c-.937-3.99-1.961-7.97-2.827-11.96l-2.662-11.97c-1.802-7.98-3.467-15.97-5.513-23.94-1.973-7.97-4.309-15.93-5.923-23.92-3.496-15.96-3.372-32.04-2.655-48.14a238.04 238.04 0 0 1 2.541-24.194c.295-2.019.71-4.042 1.059-6.063.427-2.023.806-4.045 1.3-6.07.926-4.121 2.09-8.184 3.486-12.17l3.976-11.203a26.257 26.257 0 0 1 50.995 9.419l-1.171 48.261-.597 24.14-.738 24.13c-.514 16.09-.931 32.18-1.526 48.28l-1.709 48.28a15.93 15.93 0 0 1-14.084 15.26 16 16 0 0 1-10.756-2.63 15.955 15.955 0 0 1-6.445-9l-.698-2.59Z"
            />
            <path
                fill="#FCB47F"
                d="m669.202 963.706-10.337-43.165-2.599-10.791-2.71-10.786c-1.78-7.193-3.868-14.373-5.587-21.568-1.72-7.194-3.188-14.398-4.276-21.616-.579-3.608-.993-7.222-1.498-10.832l-.725-5.417-.573-5.423c-.371-3.616-.81-7.229-1.049-10.849-.271-3.619-.612-7.236-.754-10.86l-.279-5.434-.136-5.439c-.084-3.627-.053-7.258.091-10.893a51.024 51.024 0 0 1 81.168-39.161 51.022 51.022 0 0 1 19.652 30.197l1.108 5.129c.786 3.6 1.387 7.207 1.889 10.817l.72 5.418.545 5.423c.416 3.614.589 7.237.847 10.857.275 3.619.404 7.244.53 10.869.247 7.25.216 14.51.077 21.774-.143 7.265-.543 14.539-1.267 21.825-.718 7.286-1.912 14.59-3.253 21.9l-2.042 10.966c-.707 3.656-1.505 7.316-2.253 10.973-3.213 14.639-6.971 29.299-12.603 44.029l-2.098 5.486a27.583 27.583 0 0 1-52.588-3.429Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m687.504 1158.49-.698-2.59a725.56 725.56 0 0 1-6.053-23.92c-.937-3.99-1.961-7.97-2.827-11.96l-2.662-11.97c-1.802-7.98-3.467-15.97-5.513-23.94-1.973-7.97-4.309-15.93-5.923-23.92-3.496-15.96-3.372-32.04-2.655-48.14.434-8.1 1.282-16.176 2.541-24.193.295-2.019.71-4.042 1.059-6.062.427-2.024.806-4.045 1.3-6.07.926-4.121 2.09-8.184 3.486-12.17M724.443 965.336l-1.084 44.694-.597 24.13-.738 24.14c-.514 16.09-.931 32.18-1.526 48.28l-1.709 48.28M739.589 781.668c.316 2.631 6.628 22.55 5.716 70.287-.707 35.898-11.663 84.18-21.418 109.693M669.203 963.707c-10.544-44.307-12.146-50.519-15.646-64.743-5.919-23.92-8.974-35.735-12.659-64.856a234.043 234.043 0 0 1-.177-55.647"
            />
            <path
                fill="#FCB47F"
                d="M687.067 1159.49c.46 3.88-.296 18.79-.862 28.46-.064 1.1.097 2.21.475 3.24a8.05 8.05 0 0 0 1.719 2.79c.758.8 1.671 1.44 2.684 1.88 1.013.44 2.105.66 3.208.66h43.739c5.774 0 10.552-1.33 10.552-4.42 0-3.04-9.357-5.83-13.975-8.94-7.637-5.14-16.678-23.67-16.678-23.67"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M687.067 1159.49c.46 3.88-.296 18.79-.862 28.46-.064 1.1.097 2.21.475 3.24a8.05 8.05 0 0 0 1.719 2.79c.758.8 1.671 1.44 2.684 1.88 1.013.44 2.105.66 3.208.66h43.739c5.774 0 10.552-1.33 10.552-4.42 0-3.04-9.357-5.83-13.975-8.94-7.637-5.14-16.678-23.67-16.678-23.67"
            />
            <path
                fill="url(#Z)"
                d="m674.893 1123.93-.18-.78c-10.331-44.6-12.605-53.23-13.46-56.49-.504-1.91-.569-2.16-1.401-6.27-5.526-25.23-2.509-70.11 6.453-96.236-.548-2.364-3.896-17.405-9.169-41.106-4.765-21.417-9.692-43.564-10.135-45.418-5.546-23.19-10.59-65.876-9.04-88.291a86.584 86.584 0 0 0-.363-16.973 69.822 69.822 0 0 1 2.22-27.689l.201-.736h170.127l.88 1.869c-7.086 18.943-24.665 39.19-45.815 56.451-12.013 9.803-18.372 24.673-18.306 40.199a296.928 296.928 0 0 1-1.866 31.421c-1.532 15.545-7.268 45.953-14.213 69.932a145.1 145.1 0 0 0-5.612 36.894c-.829 34.633-3.081 103.993-4.351 142.253l-.031.97h-45.939Z"
            />
            <path
                fill="url(#aa)"
                d="M694.291 1197.52a9.093 9.093 0 0 1-9.084-9.62c.086-1.48.177-3.07.267-4.72l.068-1.27 1.216.36c1.066.31 2.171.47 3.282.46 2.927-.1 5.795-.84 8.397-2.19 1.862-.9 3.686-2 5.449-3.06 2.11-1.34 4.3-2.54 6.558-3.6a31.295 31.295 0 0 1 14.868-2.57l.495.04.271.41c3.454 5.28 6.512 8.84 9.087 10.57a49.183 49.183 0 0 0 5.924 3.13c4.556 2.17 8.492 4.04 8.492 6.64 0 3.45-4.21 5.42-11.551 5.42h-43.739Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M749.584 1192.1c0 3.44-4.21 5.42-11.551 5.42h-43.739a9.137 9.137 0 0 1-3.609-.74 8.95 8.95 0 0 1-3.013-2.12"
            />
            <path
                fill="url(#ab)"
                d="M812.69 617.249c-20.409-24.151-26.325-32.893-31.648-46.77-2.986-7.787-9.421-18.228-14.918-23.949a47.31 47.31 0 0 0-4.724-2.974c4.064 7.051 4.949 18.802-6.423 23.57a22.007 22.007 0 0 1-8.678 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.282-4.644-14.812-9.445-22.911-12.429a32.723 32.723 0 0 0-10.802-1.713 31.456 31.456 0 0 0-4.391.293c-.16.022-.314.077-.452.162a62.74 62.74 0 0 0-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.795-6.376 34.828-20.637 21.97-25.614 45.362-27.936 63.293 14.883 0 179.194.874 179.224.713 9.23-48.617-.85-76.582-9.313-98.686a542.35 542.35 0 0 1-2.685-7.11c8.094-3.299 18.171-9.26 20.658-19.015 1.943-7.614-1.082-16.22-8.991-25.578Z"
            />
            <g filter="url(#ac)">
                <path
                    fill="url(#ad)"
                    d="m698.926 628.22 5.079 9.293c5.358 11.03 17.156 17.893 29.235 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.97 30.97 0 0 0 18.608-35.858 30.978 30.978 0 0 0-6.56-12.967l-6.917-10.635"
                />
            </g>
            <path
                fill="url(#ae)"
                d="M631.148 982.798V772.705c0-22.639 9.187-39.447 17.291-54.276 6.591-12.06 12.284-22.475 12.284-33.914 0-22.212-5.36-37.523-10.088-51.032-3.608-10.306-6.576-19.208-6.723-29.057-.71-47.471 31.536-63.806 31.855-63.984l20.351-11.137 34.594 83.571a96.713 96.713 0 0 1 7.351 37.084v332.838H631.148Z"
            />
            <path
                fill="url(#af)"
                d="M697.025 531.109c-16.337 17.315 38.313 95.784 38.313 95.784a123.854 123.854 0 0 0-4.337-13.952c-3.053-8.126-7.584-17.767-9.47-22.482-5.915-14.788-24.506-59.35-24.506-59.35Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M660.766 789.621h58.024"
            />
            <path
                fill="#FCB47F"
                d="M659.329 655.849a225.44 225.44 0 0 1-15.948 18.029c-13.751 13.729-37.014.485-32.07-18.452 4.921-18.654 17.191-48.328 27.835-65.598 4.105-6.706 8.364-13.324 12.267-20.146a26.503 26.503 0 0 1 36.161-9.845 26.509 26.509 0 0 1 12.411 16.042 26.512 26.512 0 0 1 .718 10.315 26.51 26.51 0 0 1-3.285 9.804c-11.305 19.764-14.43 30.401-38.089 59.851Z"
            />
            <path
                fill="#FCB47F"
                d="M647.377 663.085c1.242 31.952 2.681 67.813-4.201 108.304-2.238 13.169-21.141 13.663-23.995.51-5.744-26.84-9.744-63.568-8.796-108.028a18.503 18.503 0 0 1 18.108-18.66 18.5 18.5 0 0 1 18.884 17.874Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m697.416 596-.066.114c-3.936 6.803-7.517 13.811-11.251 20.731a180.831 180.831 0 0 1-12.509 20.008c-4.55 6.45-9.281 12.796-14.263 18.998-3.117 3.855-6.303 7.671-9.655 11.391M610.686 660.619c-.029-1.75.181-3.497.623-5.191a225.667 225.667 0 0 1 7.57-22.848c2.857-7.424 5.966-14.704 9.255-21.88a180.963 180.963 0 0 1 11.01-20.869c4.105-6.707 10.568-16.528 14.471-23.351"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M619.178 771.897c-1.921-8.977-3.283-17.966-4.479-26.958-.285-2.249-.608-4.496-.863-6.745l-.752-6.748c-.544-4.497-.875-8.999-1.309-13.498-.832-9-1.264-18.009-1.295-27.027l.023-13.527.183-16.777M647.606 669.91l.821 20.206c.361 9.01.313 18.029-.145 27.057-.243 4.514-.383 9.026-.736 13.542l-.464 6.774c-.159 2.258-.387 4.517-.576 6.775-.813 9.035-1.792 18.074-3.33 27.125"
            />
            <path
                fill="#FCB47F"
                d="M653.966 807.644c.095-2.286.346-4.562.753-6.813a38.46 38.46 0 0 0-9.285-29.757c-10.718-12.746-22.939-13.498-26.698 1.628-1.458 9.565-2.416 19.248-1.674 28.883.741 9.636 3.237 19.264 8.34 27.689.227.402.506.772.83 1.1.301.292.69.478 1.106.529a1.475 1.475 0 0 0 1.417-.817l.223-.309c.901 2.33 1.88 4.636 2.857 6.94a2.475 2.475 0 0 0 2.541 1.151 2.55 2.55 0 0 0 2.087-1.745l.225-.426c.025.641.017 1.282-.024 1.921a1.983 1.983 0 0 0 1.234 1.883c.247.102.512.152.779.149l.04.001a2.088 2.088 0 0 0 2.076-1.383c1.705-5.209 1.538-10.837 1.03-16.288-.16-1.708-.351-3.42-.522-5.132l.379.66a46.622 46.622 0 0 1 2.764 16.316 1.412 1.412 0 0 0 1.432 1.449 3.61 3.61 0 0 0 2.98-2.173 21.836 21.836 0 0 0 2.417-10.557 61.764 61.764 0 0 0-.774-9.219l.269.185a4.737 4.737 0 0 0 2.719 1.537 2.877 2.877 0 0 0 2.777-1.046 9.128 9.128 0 0 1-2.298-6.356Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M645.438 771.074a38.467 38.467 0 0 1 9.284 29.757 50.104 50.104 0 0 0-.753 6.812 9.127 9.127 0 0 0 2.298 6.357M649.642 790.426a91.752 91.752 0 0 0 .275 18.428c.529 4.56 1.402 9.103 1.36 13.69a22.768 22.768 0 0 1-2.418 10.556 3.978 3.978 0 0 1-4.41 1.87l-.027-.007M641.118 804.109c-.782 5.93.151 11.922.707 17.874.508 5.451.675 11.079-1.031 16.288a2.087 2.087 0 0 1-2.075 1.383l-.04-.001a1.994 1.994 0 0 1-1.872-1.252 1.983 1.983 0 0 1-.141-.78 33.072 33.072 0 0 0-1.445-10.221c-1.015-3.947-3.383-15.108-3.077-23.004M631.836 836.719a122.778 122.778 0 0 1-5.007-13.177 59.963 59.963 0 0 1-2.354-20.19M625.402 829.275c-5.103-8.425-7.599-18.053-8.34-27.688-.742-9.636.216-19.319 1.674-28.884"
            />
            <path
                fill="url(#ag)"
                d="M615.881 760.137c-4.987-28.437-7.661-62.04-6.924-96.595a19.429 19.429 0 0 1 .253-3.177 21.437 21.437 0 0 1 .705-5.498c5.22-19.783 18.185-49.025 28.378-65.564a802.968 802.968 0 0 1 3.743-6.051c2.846-4.58 5.791-9.316 8.508-14.067a27.507 27.507 0 0 1 27.351-13.624 27.504 27.504 0 0 1 23.054 20.055 27.517 27.517 0 0 1-2.664 20.879c-2.708 4.734-4.445 9.515-6.611 13.59-6.991 13.151-13.531 23.941-31.567 46.391-4.321 5.345-7.226 9.366-10.722 13.139 2.374 27.409.92 56.98-3.285 88.984l-.108.824-29.957 1.587-.154-.873Z"
            />
            <path
                fill="#FCB47F"
                d="M1960.79 1142.24c.8-7.79 1.45-15.56 1.96-23.31.26-3.87.6-7.75.78-11.62l.62-11.6c.44-7.73.74-15.45 1.42-23.22.61-7.76 1.58-15.58 1.83-23.29.77-15.45-2.04-30.39-5.45-45.25a212.27 212.27 0 0 0-6.55-22.067c-.63-1.827-1.37-3.637-2.06-5.457-.76-1.808-1.47-3.623-2.29-5.422a99.923 99.923 0 0 0-5.47-10.718l-6.41-10.763a26.11 26.11 0 0 0-35.8-9.093 26.188 26.188 0 0 0-10.53 11.918 26.259 26.259 0 0 0-1.7 15.815l9.24 44.697 4.63 22.35 4.76 22.33c3.2 14.89 6.31 29.78 9.59 44.65l9.76 44.63c.9 4.12 3.41 7.72 6.96 10a15.911 15.911 0 0 0 20.46-2.8c2.23-2.49 3.62-5.63 3.97-8.96l.28-2.82Z"
            />
            <path
                fill="#FCB47F"
                d="m1943.98 968.379 16.14-42.662 4.04-10.662 4.16-10.645c2.75-7.1 5.8-14.149 8.49-21.26 2.68-7.111 5.12-14.264 7.19-21.481 1.06-3.603 1.97-7.234 2.96-10.849l1.46-5.429 1.31-5.454c.86-3.638 1.79-7.264 2.52-10.924.76-3.655 1.59-7.298 2.23-10.975l1.01-5.504.88-5.528c.58-3.686 1.04-7.392 1.4-11.117a50.842 50.842 0 0 0-2.01-19.804 51.04 51.04 0 0 0-9.42-17.53 51.187 51.187 0 0 0-15.42-12.587 51.044 51.044 0 0 0-51.3 1.998 51.094 51.094 0 0 0-20.69 25.894l-1.7 4.81c-1.27 3.568-2.36 7.167-3.35 10.783l-1.45 5.429-1.28 5.459c-.91 3.63-1.57 7.302-2.32 10.959-.77 3.654-1.39 7.333-2.01 11.012-1.24 7.36-2.2 14.766-3.05 22.191-.85 7.426-1.45 14.894-1.72 22.418-.28 7.523-.1 15.125.24 22.753l.52 11.447c.21 3.82.5 7.656.74 11.483 1.19 15.345 2.91 30.782 6.49 46.537l1.26 5.582a27.602 27.602 0 0 0 12.19 17.225 27.578 27.578 0 0 0 20.8 3.562 27.841 27.841 0 0 0 11.97-6.117 27.864 27.864 0 0 0 7.72-11.014Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1960.51 1145.06.28-2.82c.8-7.79 1.45-15.56 1.96-23.31.26-3.87.6-7.76.78-11.62l.62-11.6c.44-7.73.74-15.45 1.42-23.22.61-7.76 1.58-15.58 1.83-23.29.77-15.45-2.04-30.39-5.45-45.25a212.125 212.125 0 0 0-6.55-22.067c-.63-1.828-1.37-3.638-2.06-5.457-.76-1.809-1.47-3.624-2.29-5.423a99.923 99.923 0 0 0-5.47-10.718M1891.15 968.164l9.23 44.706 4.63 22.35 4.76 22.32c3.21 14.89 6.31 29.78 9.59 44.66l9.76 44.62"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1948.31 956.917c29.45-77.761 30.08-75.627 35.69-95.248 7.06-23.855 12.38-51.345 13.77-65.78a50.842 50.842 0 0 0-2.01-19.804 51.04 51.04 0 0 0-9.42-17.53 51.187 51.187 0 0 0-15.42-12.587 51.044 51.044 0 0 0-51.3 1.998 51.094 51.094 0 0 0-20.69 25.894c-21.8 61.184-16.08 126.628-16.12 127.261 1.37 25.969 8.35 67.143 8.33 67.042"
            />
            <path
                fill="#FCB47F"
                d="M1960.83 1140.59c.35 3.89 4.17 18.31 6.72 27.67.29 1.06.36 2.17.2 3.27a7.931 7.931 0 0 1-1.11 3.08 8.07 8.07 0 0 1-2.23 2.39c-.91.64-1.93 1.08-3.01 1.31l-24.77 5.22s-13.34 10.88-38.22 10.88c-5.77 0-10.59-1.6-11.23-4.62-.63-2.98 6.74-6.89 11.83-9.16 17.41-7.76 30.12-33.81 30.12-33.81"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1960.83 1140.59c.35 3.89 4.17 18.31 6.72 27.67.29 1.06.36 2.17.2 3.27a7.931 7.931 0 0 1-1.11 3.08 8.07 8.07 0 0 1-2.23 2.39c-.91.64-1.93 1.08-3.01 1.31l-24.77 5.22s-13.34 10.88-38.22 10.88c-5.77 0-10.59-1.6-11.23-4.62-.63-2.98 6.74-6.89 11.83-9.16 17.41-7.76 30.12-33.81 30.12-33.81"
            />
            <path
                fill="#FFD109"
                d="M1898.41 1195.42c23.63 0 36.85-9.56 38.66-10.96l24.54-5.17a9.03 9.03 0 0 0 3.38-1.47 9.181 9.181 0 0 0 3.76-6.15c.17-1.23.09-2.48-.24-3.68-.66-2.42-1.41-5.19-2.16-8.03l-.32-1.23-1.12.6c-7.05 3.79-15.32 8-24.19 10.09-3.59.86-7.25 1.29-10.93 1.31-5.15.08-10.25-.98-14.95-3.08l-.67-.31-.49.55c-5.06 5.64-10.13 9.62-15.08 11.83-8.84 3.94-13.01 7.4-12.4 10.28.7 3.34 5.38 5.42 12.21 5.42Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1886.2 1190c.7 3.34 5.38 5.41 12.21 5.41 23.63 0 36.85-9.55 38.66-10.95l24.54-5.17c1.21-.25 2.37-.75 3.38-1.47a8.99 8.99 0 0 0 2.51-2.7"
            />
            <path
                fill="#FCB47F"
                d="M1914.45 572.937c-2.24 6.819-4.88 13.508-7.34 20.253a150.607 150.607 0 0 0-5.98 20.712c-1.57 7.042-2.95 14.15-4.05 21.35a190.293 190.293 0 0 0-2.21 21.963 19.28 19.28 0 0 0 5.2 13.822c3.5 3.736 8.33 5.93 13.45 6.099 3.21.088 6.39-.619 9.27-2.058a19.488 19.488 0 0 0 7.19-6.192c4.16-6.08 7.96-12.395 11.38-18.915 3.43-6.426 6.58-12.944 9.54-19.528a151.078 151.078 0 0 0 7.61-20.166c2.06-6.88 3.96-13.817 6.24-20.623 1.11-3.3 1.55-6.785 1.31-10.256a26.5 26.5 0 0 0-9.03-18.158 26.582 26.582 0 0 0-8.98-5.138 26.49 26.49 0 0 0-20.23 1.4c-3.12 1.551-5.9 3.7-8.18 6.324a26.485 26.485 0 0 0-5.14 8.973l-.05.138Z"
            />
            <path
                fill="#FCB47F"
                d="m1896.23 653.844-5.32 10.656-5.45 10.593a233.934 233.934 0 0 0-9.7 21.769c-1.42 3.723-2.93 7.402-4.25 11.172l-2.03 5.629c-.68 1.878-1.29 3.785-1.94 5.676-2.54 7.589-4.94 15.249-6.83 23.152a12.455 12.455 0 0 0-.18 4.789c.25 1.593.82 3.12 1.66 4.494a12.327 12.327 0 0 0 3.26 3.516c1.3.947 2.78 1.627 4.35 2.002a12.286 12.286 0 0 0 12.53-4.379c5.01-6.401 9.51-13.044 13.86-19.76 1.08-1.685 2.19-3.353 3.24-5.051l3.13-5.1c2.13-3.382 4.07-6.855 6.09-10.284a233.671 233.671 0 0 0 11-21.144l4.89-10.862 5.02-10.8a18.53 18.53 0 0 0 1.71-7.014c.1-2.428-.27-4.852-1.11-7.134a18.505 18.505 0 0 0-3.75-6.168 18.418 18.418 0 0 0-5.83-4.262 18.529 18.529 0 0 0-13.9-.68 18.553 18.553 0 0 0-10.45 9.19Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1929.98 668.886c4.16-6.08 7.96-12.395 11.38-18.915 3.43-6.426 6.58-12.944 9.54-19.528a151.117 151.117 0 0 0 7.61-20.165c2.06-6.881 3.96-13.818 6.24-20.624 1.11-3.3 1.55-6.785 1.31-10.256a26.5 26.5 0 0 0-9.03-18.158 26.582 26.582 0 0 0-8.98-5.138 26.49 26.49 0 0 0-20.23 1.4c-3.12 1.551-5.9 3.7-8.18 6.324a26.485 26.485 0 0 0-5.14 8.973l-.05.137c-2.24 6.82-4.88 13.509-7.34 20.254a150.607 150.607 0 0 0-5.98 20.712c-1.57 7.042-2.95 14.15-4.05 21.35-.84 5.645-1.57 11.33-1.96 17.124M1882.33 752.912c5.01-6.401 9.51-13.045 13.86-19.76 1.08-1.685 2.19-3.354 3.24-5.052l3.13-5.1c2.13-3.381 4.06-6.855 6.09-10.284a233.43 233.43 0 0 0 10.99-21.143l4.9-10.863 5.02-10.8M1894.23 656.66l-3.32 7.84-5.45 10.594a233.8 233.8 0 0 0-9.7 21.768c-1.42 3.724-2.93 7.402-4.25 11.173l-2.04 5.628c-.67 1.878-1.29 3.785-1.93 5.677-2.55 7.588-4.94 15.248-6.83 23.152"
            />
            <path
                fill="#FCB47F"
                d="M1850.29 773.845c1.88-8.125 5.93-22.708 9.17-30.436 11.06-12.292 20.13-5.641 20.17 11.326.84 3.619.21 11.398-.92 15.931a45.21 45.21 0 0 0-1.65 10.462c-.03 3.534.98 7.264 3.67 9.752l-.7 1.293c-2.74 1.254-6.62-.208-8.38-2.571a16.96 16.96 0 0 1-2.58-6.714c-.1-.472-.47.224-.47.224-3.02 2.645-5.29 6.966-6.6 11.619 1.59.107 3.18-.045 4.72-.45.47-.112.96-.034 1.37.216l1.86 1.152a5.706 5.706 0 0 1 2.27 2.673c.46 1.107.56 2.33.28 3.497-.17.762-.51 1.48-.97 2.108a5.703 5.703 0 0 1-1.74 1.54c-3.24 1.815-7.26 2.032-9.43.918l-.56 1.128a16.333 16.333 0 0 1-3.05 7.929c-.14.193-.33.351-.55.464a1.64 1.64 0 0 1-1.4.066 1.688 1.688 0 0 1-.6-.411l-.08-.087a1.93 1.93 0 0 1-.35-.603c-.07-.224-.1-.46-.08-.694.31-4.82-2.19-11.215-3.45-15.58-2.32-8.06-1.84-16.628.05-24.752Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1865.86 776.195a25.238 25.238 0 0 0-6.9 16.777c-.07 3.933.82 7.837.93 11.77.11 3.933-.69 8.129-3.55 10.978M1853.87 812.407c-.08-4.75-2.3-9.218-3.63-13.813-2.32-8.059-1.84-16.627.05-24.751 1.84-8.447 4.36-16.73 7.55-24.765M1880.01 759.836c.58 4.323-.64 7.357-1.3 10.831-.66 3.474-1.61 6.928-1.65 10.462-.03 3.534.98 7.264 3.67 9.751M1879.59 792.271c-2.74 1.253-6.18-.307-7.94-2.67-1.75-2.363-2.27-5.309-2.88-8.126-.61-2.816-1.46-5.764-3.65-7.78"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1862.2 792.929a21.472 21.472 0 0 1 6.4-9.816M1870.17 805.051c-2.84 1.924-7.09 2.727-9.81 1.332M1871.64 799.137c-2.67 2.156-9.15 2.644-12.18.917M1867.45 794.101a10.912 10.912 0 0 1-8.49-.058"
            />
            <path
                fill="#FCB47F"
                d="M1924.83 559.1c8.49-8.307 18.7-15.185 30.91-21.252l-15.6 34.205s-19.39-8.958-15.31-12.953Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M1955.74 537.848a132.187 132.187 0 0 0-30.91 21.252"
            />
            <path
                fill="#FCB47F"
                d="M1937.33 465.385c1.42-4.724-1.21-10.013-1.21-15.21 1.23-9.261 13.9-23.646 24.66-26.984 18.46-5.729 33.3-1.408 44.53 6.257 3.24 2.214 26.65 23.438.74 66.892-11.09 17.265 5.22 32.565 5.22 32.565-4.68 6.464-39.36 33.253-53.23 28.63-13.87-4.624.78-20.395 3.04-27.094 1.49-4.417 2.37-8.206 1.97-11.514-6.31 1.628-10.5 1.905-14.42-.356-13.59-6.348-14.36-23.852-14.13-33.359.24-10.119 1.41-15.103 2.83-19.827Z"
            />
            <path
                fill="url(#ah)"
                d="M1956.32 538.431c4.46-6.155 6.57-10.989 6.6-19.61 13.02-2.156 20.07-8.958 26.79-16.341 1.22-1.337-6.67 29.124-7.25 30.836-.58 1.712-1.39 2.178-2.34 3.716-4.32 6.967-24.46 2.308-23.8 1.399Z"
            />
            <path
                fill="url(#ai)"
                d="M1937.89 456.864c1.66-2.075 9.8-2.899 10.27 4.216.6 9.198-5.21 23.631-2.88 26.203.76.85 2.71 2.542 2.81 3.682.37 4.075-.17 8.246-.42 12.334-.29 4.743-1.34 13.154 3.12 15.985-.44-.582-6.44-3.119-7.03-3.551-10.71-7.906-10.29-30.717-8.12-44.775.4-2.607 2-5.222 2.35-7.836.34-2.524-.98-5.146-.1-6.258Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1989.3 502.343c-8.18 14.377-32.27 20.998-42.23 15.604-8.72-4.722-12.77-14.241-12.3-33.177.24-9.512 1.15-14.657 2.56-19.381 1.42-4.725-1.38-9.7-.93-14.877 1-11.391 14.52-29.06 36.86-30.636 17.7-1.249 30.47 6.78 35.54 13.107 7.69 9.575 11.4 22.365 9.16 34.435-1.52 8.181-5.3 15.746-8.9 23.247-4.5 9.359-13.93 19.957 1.56 37.754"
            />
            <path
                fill="#FCB47F"
                d="M1948.16 461.082a32.056 32.056 0 0 1-5.74 16.511c-.97 1.47-2.11 2.986-2.16 4.751.03.911.29 1.801.74 2.594a5.623 5.623 0 0 0 1.85 1.961c1.01.685 2.12 1.218 3.29 1.583"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1948.16 461.082a32.056 32.056 0 0 1-5.74 16.511c-.97 1.47-2.11 2.986-2.16 4.751.03.911.29 1.801.74 2.594a5.623 5.623 0 0 0 1.85 1.961c1.01.685 2.12 1.218 3.29 1.583M1974.53 459.281a19.22 19.22 0 0 0-15.08-4.251M1944.42 455.743c-3.23-.207-6.46.588-9.23 2.277M1960.78 496.445a24.198 24.198 0 0 1-13.89 2.517"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1964.62 470.235c-.78 0-1.41-1.084-1.41-2.422s.63-2.422 1.41-2.422c.78 0 1.41 1.084 1.41 2.422s-.63 2.422-1.41 2.422ZM1941.02 470.235c-.78 0-1.41-1.084-1.41-2.422s.63-2.422 1.41-2.422c.77 0 1.41 1.084 1.41 2.422s-.64 2.422-1.41 2.422Z"
            />
            <path
                fill="#7A5430"
                d="M2029 439.227c-7.52-16.811-20.79-31.852-47.56-33.622-28.54-1.887-100.57-.645-45.98 62.42.12-.374.23-.742.35-1.101.2-.627.39-1.234.57-1.827.72-2.403.22-4.96-.3-7.668a25.045 25.045 0 0 1-.67-7.009c.5-5.763 4.08-12.98 10.21-19.07l.89 1.25c.02.017.04.036.06.056 10.26 14.7 25.2 33.804 45.1 42.396.59.255.1 1.036.23.962.6-.337 1.01-7.08 6.48-11.338 10.52-8.183 21.5 8.961 7.93 22.912a18.677 18.677 0 0 1-8.33 5.005c.06 3.844.53 7.671 1.41 11.415.13.505.42.956.82 1.29.4.335.89.536 1.41.575a2.52 2.52 0 0 0 1.49-.354c.45-.27.8-.673 1-1.152a113.996 113.996 0 0 1 9.41-17.804c20.79 3.927 23.67-29.032 15.48-47.336Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2027.64 481.447c7.4-9.53 7.08-29.443 1.36-42.219-7.52-16.811-20.79-31.852-47.56-33.622-17.29-1.143-50.56-1.146-61.96 13.052"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1991.9 476.016c.6-.337 1.01-7.08 6.48-11.338 10.52-8.183 21.5 8.962 7.93 22.912a18.677 18.677 0 0 1-8.33 5.005"
            />
            <path
                fill="#FCB47F"
                d="m2011.27 528.906 19.77 11.892a61.315 61.315 0 0 1 26.13 31.884 61.283 61.283 0 0 1 .04 41.222l-18.82 52.893a36.483 36.483 0 0 0-1.54 18.895 36.37 36.37 0 0 0 8.14 17.121c9.8 11.422 19.8 28.766 23.29 47.32 10.39 55.41-52.31 115.616-131.43 44.511-65.21-58.609-40.33-102.907-28.72-135.091-16.26-6.304-32.19-19.576-12.28-43.134 19.91-23.557 26.38-32.834 31.86-47.115 9.29-24.221 28.61-30.874 28.61-30.874 9.29 6.968 42.34.43 54.95-9.524Z"
            />
            <path
                fill="url(#aj)"
                d="m2011.27 528.906 19.77 11.892a61.315 61.315 0 0 1 26.13 31.884 61.283 61.283 0 0 1 .04 41.222l-18.82 52.893a36.483 36.483 0 0 0-1.54 18.895 36.37 36.37 0 0 0 8.14 17.121c9.8 11.422 19.8 28.766 23.29 47.32 10.39 55.41-52.31 115.616-131.43 44.511-65.21-58.609-40.33-102.907-28.72-135.091-16.26-6.304-32.19-19.576-12.28-43.134 19.91-23.557 26.38-32.834 31.86-47.115 9.29-24.221 28.61-30.874 28.61-30.874 9.29 6.968 42.34.43 54.95-9.524Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2011.27 528.906 19.77 11.892a61.315 61.315 0 0 1 26.13 31.884 61.283 61.283 0 0 1 .04 41.222l-18.82 52.893a36.483 36.483 0 0 0-1.54 18.895 36.37 36.37 0 0 0 8.14 17.121c9.8 11.422 21.71 28.509 23.29 47.32 6.35 75.663-65.73 102.575-131.43 44.511-65.69-58.064-40.33-102.907-28.72-135.091-16.26-6.305-32.49-19.834-12.28-43.134a283.386 283.386 0 0 0 20.85-26.788"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1919.58 720.105a13.787 13.787 0 0 0-10.29 0"
            />
            <path
                fill="#FCB47F"
                d="M2020.67 1154.29c2.17-7.96 4.18-15.93 6.06-23.91.94-3.99 1.96-7.98 2.82-11.97l2.67-11.97c1.8-7.98 3.46-15.96 5.51-23.93 1.97-7.97 4.31-15.94 5.92-23.92 3.5-15.96 3.37-32.04 2.66-48.14-.44-8.1-1.29-16.179-2.54-24.196-.3-2.019-.71-4.042-1.06-6.062-.43-2.024-.81-4.045-1.3-6.07-.93-4.121-2.09-8.184-3.49-12.17l-3.97-11.204a26.293 26.293 0 0 0-13.46-14.925 26.263 26.263 0 0 0-20.07-1.037 26.335 26.335 0 0 0-12.84 9.867 26.25 26.25 0 0 0-4.63 15.515l1.17 48.262.6 24.13.74 24.14c.51 16.09.93 32.18 1.52 48.28l1.71 48.28a15.92 15.92 0 0 0 16.48 15.35c3.4-.12 6.66-1.32 9.33-3.43 2.66-2.11 4.58-5.02 5.48-8.3l.69-2.59Z"
            />
            <path
                fill="#FCB47F"
                d="m2038.28 962.105 10.34-43.165 2.59-10.791 2.71-10.787c1.78-7.192 3.87-14.372 5.59-21.567 1.72-7.194 3.19-14.398 4.28-21.616.58-3.608.99-7.222 1.5-10.832l.72-5.418.57-5.422c.37-3.616.81-7.229 1.05-10.849.27-3.62.61-7.237.76-10.86l.28-5.434.13-5.44c.09-3.626.05-7.257-.09-10.892a51.052 51.052 0 0 0-4.63-19.358 51.008 51.008 0 0 0-28.66-26.524 50.988 50.988 0 0 0-19.65-3.128 51.071 51.071 0 0 0-30.78 11.851 51.033 51.033 0 0 0-17.1 28.194l-1.11 5.129c-.78 3.6-1.38 7.207-1.89 10.818l-.72 5.417-.54 5.424c-.42 3.614-.59 7.237-.85 10.857-.27 3.619-.4 7.243-.53 10.868-.25 7.25-.21 14.51-.08 21.775.15 7.264.55 14.538 1.27 21.825.72 7.286 1.91 14.59 3.25 21.9l2.05 10.965c.7 3.656 1.5 7.316 2.25 10.974 3.21 14.639 6.97 29.298 12.6 44.029l2.1 5.485a27.571 27.571 0 0 0 14.51 15.333 27.604 27.604 0 0 0 21.11.58 27.918 27.918 0 0 0 10.85-7.593 27.962 27.962 0 0 0 6.12-11.748Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2019.98 1156.89.69-2.59c2.17-7.97 4.18-15.94 6.06-23.92.94-3.99 1.96-7.97 2.82-11.96l2.67-11.97c1.8-7.98 3.46-15.97 5.51-23.94 1.97-7.97 4.31-15.93 5.92-23.92 3.5-15.96 3.37-32.04 2.66-48.14-.44-8.1-1.29-16.178-2.54-24.194-.3-2.019-.71-4.042-1.06-6.063-.43-2.023-.81-4.045-1.3-6.07-.93-4.12-2.09-8.184-3.49-12.17M1983.04 963.734l1.08 44.696.6 24.13.74 24.14c.51 16.09.93 32.18 1.52 48.28l1.71 48.28M1967.89 780.066c-.32 2.631-6.63 22.55-5.72 70.288.71 35.898 11.66 84.18 21.42 109.692M2038.28 962.105c10.54-44.307 12.14-50.519 15.64-64.743 5.92-23.92 8.98-35.734 12.66-64.856a234.358 234.358 0 0 0 .18-55.647"
            />
            <path
                fill="#FCB47F"
                d="M2020.41 1157.89c-.46 3.88.3 18.79.86 28.46.07 1.1-.1 2.21-.47 3.24a8.204 8.204 0 0 1-1.72 2.79c-.76.8-1.67 1.44-2.69 1.88-1.01.43-2.1.66-3.21.66h-43.73c-5.78 0-10.56-1.33-10.56-4.42 0-3.05 9.36-5.83 13.98-8.94 7.64-5.15 16.68-23.67 16.68-23.67"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2020.41 1157.89c-.46 3.88.3 18.79.86 28.46.07 1.1-.1 2.21-.47 3.24a8.204 8.204 0 0 1-1.72 2.79c-.76.8-1.67 1.44-2.69 1.88-1.01.43-2.1.66-3.21.66h-43.73c-5.78 0-10.56-1.33-10.56-4.42 0-3.05 9.36-5.83 13.98-8.94 7.64-5.15 16.68-23.67 16.68-23.67"
            />
            <path
                fill="url(#ak)"
                d="M2013.19 1195.92c1.24.01 2.47-.25 3.61-.74a8.948 8.948 0 0 0 3.01-2.12c.85-.89 1.51-1.96 1.94-3.12.42-1.17.6-2.41.52-3.65-.08-1.47-.18-3.06-.27-4.72l-.06-1.26-1.22.36a11.4 11.4 0 0 1-3.28.46c-2.93-.1-5.8-.85-8.4-2.19-1.86-.9-3.68-2-5.45-3.07a60.41 60.41 0 0 0-6.56-3.59 31.11 31.11 0 0 0-12.61-2.65c-.75 0-1.51.03-2.25.08l-.5.04-.27.41c-3.45 5.28-6.51 8.83-9.09 10.57-1.9 1.17-3.88 2.22-5.92 3.13-4.56 2.17-8.49 4.04-8.49 6.64 0 3.44 4.21 5.42 11.55 5.42h43.74Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1957.9 1190.5c0 3.44 4.21 5.42 11.55 5.42h43.74c1.24 0 2.47-.25 3.61-.74a8.948 8.948 0 0 0 3.01-2.12"
            />
            <path
                fill="url(#al)"
                d="M1894.79 615.644c20.41-24.152 26.32-32.894 31.64-46.771 2.99-7.786 9.43-18.228 14.92-23.949a48.402 48.402 0 0 1 4.73-2.974c-4.07 7.051-4.95 18.802 6.42 23.57a22.008 22.008 0 0 0 8.68 1.436c10.92 0 21.13-6.506 31.94-13.399 7.28-4.644 14.81-9.444 22.91-12.429a32.693 32.693 0 0 1 10.8-1.712c1.47-.005 2.94.093 4.4.293.15.023.31.078.44.161a62.744 62.744 0 0 1 26.52 74.436l-18.82 52.892c-4.37 12.283-2.11 25.796 6.37 34.829 20.64 21.97 27.94 53.587 27.94 71.668 0 34.797-1.58 70.306-22.47 115.37C2016.03 964.976 1933.32 1017 1879.58 1017c-7.33-130.426 5.76-209.858 11.93-238.665a77.356 77.356 0 0 0 1.23-24.424c-2.04-18.848-3.52-56.173 11.03-86.565 1.14-2.38 1.84-4.812 2.68-7.11-8.09-3.299-18.17-9.26-20.66-19.015-1.94-7.613 1.09-16.219 9-25.577Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2016.03 541.129c-8.1 2.984-15.63 7.785-22.91 12.429-10.81 6.892-21.02 13.399-31.94 13.399-2.96.147-5.92-.343-8.68-1.436-10.51-4.721-10.92-12.989-8.36-19.636.28-.957.69-1.873 1.21-2.726"
            />
            <g filter="url(#am)">
                <path
                    fill="url(#an)"
                    d="m2008.55 626.614-5.07 9.293c-5.36 11.03-17.16 17.893-29.24 20.012-7.21 1.265-14.81 1.03-21.6-1.697a31.533 31.533 0 0 1-11.8-8.469 31.64 31.64 0 0 1-6.74-12.857 31.507 31.507 0 0 1 6.49-27.499l6.91-10.635"
                />
            </g>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1956.67 566.852a239.645 239.645 0 0 1-16.77 23.121"
            />
            <g filter="url(#ao)">
                <path
                    fill="url(#ap)"
                    d="M2048.14 718.264c-10.15 27.674-36.96 46.753-60.77 62.166-26.06 16.876-54.83 29.523-85.55 34.747-5.59.95-11.31-1.48-12.92-7.333-1.38-5.044 1.71-11.96 7.33-12.917 28.08-4.774 53.96-15.731 77.93-30.953a241.832 241.832 0 0 0 31.91-23.501c8.61-7.691 17.82-16.88 21.82-27.791 1.95-5.321 7.13-8.926 12.92-7.334 5.12 1.409 9.29 7.566 7.33 12.916Z"
                />
                <path
                    fill="url(#aq)"
                    d="M2054.5 729.53c-7.04 28.185-24.31 52.842-43.01 74.562-18.21 21.151-38.75 41.011-62.3 56.148a167.367 167.367 0 0 1-43.7 20.039c-13.01 3.771-18.56-16.487-5.59-20.25 31.99-9.274 58.28-30.298 81.16-53.814 22.56-23.189 45.18-50.212 53.19-82.267 3.27-13.104 23.53-7.553 20.25 5.582Z"
                />
                <path
                    fill="url(#ar)"
                    d="M2035.45 717.788a277.488 277.488 0 0 1-58.1 27.28c-21.81 7.183-44.54 9.258-67.21 12.084-5.63.703-10.5-5.329-10.5-10.5 0-6.259 4.85-9.795 10.5-10.5 20.85-2.6 41.56-4.726 61.63-11.334a253.347 253.347 0 0 0 53.08-25.163c11.55-7.086 22.11 11.07 10.6 18.133Z"
                />
            </g>
            <path
                fill="#FCB47F"
                d="M2010.13 594.511c3.94 6.803 7.52 13.811 11.25 20.731a181.069 181.069 0 0 0 12.51 20.007c4.55 6.45 9.28 12.796 14.26 18.998a225.881 225.881 0 0 0 15.95 18.029 19.168 19.168 0 0 0 13.56 5.602 19.15 19.15 0 0 0 13.54-5.624 19.13 19.13 0 0 0 4.94-8.554c.87-3.232.88-6.637.03-9.875a223.868 223.868 0 0 0-7.57-22.849c-2.86-7.424-5.97-14.704-9.25-21.879a182.146 182.146 0 0 0-11.01-20.87c-4.11-6.706-8.37-13.324-12.27-20.147a26.53 26.53 0 0 0-16.04-12.411 26.498 26.498 0 0 0-27.92 9.353 26.432 26.432 0 0 0-4.61 9.255 26.399 26.399 0 0 0-.72 10.315 26.635 26.635 0 0 0 3.28 9.805l.07.114Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2064.27 621.248c-6.08 0-11-4.788-11-10.694 0-5.906 4.92-10.695 11-10.695 6.07 0 11 4.789 11 10.695 0 5.906-4.93 10.694-11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M2064.27 612.86c-1.27 0-2.3-1.034-2.3-2.309a2.305 2.305 0 0 1 2.3-2.309c1.27 0 2.3 1.034 2.3 2.309a2.305 2.305 0 0 1-2.3 2.309Z"
            />
            <path
                fill="#FCB47F"
                d="m2060.11 661.482-.46 13.517-.6 13.514c-.36 9.011-.31 18.029.15 27.057.24 4.514.38 9.026.74 13.542l.46 6.774c.16 2.258.39 4.517.58 6.775.81 9.035 1.79 18.074 3.33 27.125a12.21 12.21 0 0 0 14.09 9.999c2.41-.415 4.64-1.54 6.4-3.231a12.243 12.243 0 0 0 3.5-6.258c1.92-8.978 3.28-17.967 4.48-26.959.29-2.249.61-4.496.86-6.745l.75-6.748c.55-4.497.88-8.999 1.31-13.498.84-9 1.27-18.009 1.3-27.027l-.02-13.527.12-13.524a18.534 18.534 0 0 0-5.31-13.131 18.504 18.504 0 0 0-5.96-4.063 18.53 18.53 0 0 0-7.07-1.472 18.542 18.542 0 0 0-12.96 5.15 18.503 18.503 0 0 0-5.69 12.73Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2010.06 594.398.07.115c3.94 6.803 7.52 13.811 11.25 20.731a181.069 181.069 0 0 0 12.51 20.007c4.55 6.45 9.28 12.797 14.26 18.998 3.12 3.856 6.31 7.671 9.66 11.391M2096.79 659.018c.03-1.751-.18-3.497-.62-5.191a226.354 226.354 0 0 0-7.57-22.849c-2.86-7.424-5.97-14.704-9.26-21.879a180.666 180.666 0 0 0-11.01-20.87c-4.1-6.706-10.57-16.527-14.47-23.35"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2088.3 770.296c1.92-8.977 3.28-17.966 4.48-26.959.28-2.248.61-4.496.86-6.745l.75-6.747c.55-4.498.88-9 1.31-13.499.83-9 1.27-18.009 1.3-27.026l-.03-13.528-.18-16.776M2059.87 668.309l-.82 20.206c-.36 9.01-.31 18.029.15 27.056.24 4.514.38 9.026.73 13.543l.47 6.773c.16 2.258.39 4.517.57 6.776.82 9.035 1.8 18.074 3.33 27.124"
            />
            <path
                fill="#FCB47F"
                d="M2053.51 806.042a50.273 50.273 0 0 0-.75-6.813 38.454 38.454 0 0 1 9.28-29.756c10.72-12.746 22.94-13.498 26.7 1.627 1.46 9.566 2.42 19.248 1.68 28.884-.75 9.636-3.24 19.264-8.34 27.689-.23.401-.51.771-.84 1.1-.3.291-.69.477-1.1.528-.29.019-.58-.048-.83-.194a1.442 1.442 0 0 1-.59-.623l-.22-.309c-.9 2.33-1.88 4.637-2.86 6.94a2.472 2.472 0 0 1-2.54 1.151 2.485 2.485 0 0 1-1.29-.571 2.582 2.582 0 0 1-.8-1.174l-.22-.425c-.03.64-.02 1.281.02 1.92a1.9 1.9 0 0 1-.14.781 1.96 1.96 0 0 1-1.09 1.103c-.25.101-.51.152-.78.148l-.04.002c-.45.022-.89-.1-1.27-.349a2.165 2.165 0 0 1-.81-1.034c-1.7-5.21-1.53-10.838-1.03-16.289.16-1.708.35-3.42.53-5.131l-.38.66a46.532 46.532 0 0 0-2.77 16.316c.01.19-.03.379-.1.557-.07.177-.17.338-.3.473-.14.136-.3.243-.47.315-.18.071-.37.107-.56.104a3.638 3.638 0 0 1-1.79-.678 3.68 3.68 0 0 1-1.19-1.496 21.87 21.87 0 0 1-2.42-10.556c.03-3.088.29-6.17.78-9.22l-.27.186a4.76 4.76 0 0 1-2.72 1.536c-.51.095-1.04.048-1.53-.136a2.922 2.922 0 0 1-1.25-.91 9.112 9.112 0 0 0 2.3-6.356Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2062.04 769.473a38.445 38.445 0 0 0-7.79 13.895 38.359 38.359 0 0 0-1.49 15.861c.4 2.251.66 4.527.75 6.813a9.116 9.116 0 0 1-2.3 6.357M2057.84 788.824a91.54 91.54 0 0 1-.28 18.429c-.53 4.56-1.4 9.103-1.36 13.69-.05 3.662.78 7.283 2.42 10.556a3.986 3.986 0 0 0 4.41 1.869l.03-.007M2066.36 802.504c.78 5.93-.15 11.922-.7 17.874-.51 5.451-.68 11.079 1.03 16.288.15.423.43.785.8 1.034.38.25.82.372 1.27.349l.04-.001a1.987 1.987 0 0 0 1.44-.589c.19-.19.33-.415.43-.663.1-.248.15-.513.14-.78-.05-3.462.44-6.911 1.45-10.221 1.01-3.948 3.38-15.108 3.08-23.005M2075.64 835.117c1.92-4.292 3.59-8.692 5.01-13.177a59.91 59.91 0 0 0 2.35-20.19M2082.07 827.674c5.11-8.425 7.6-18.053 8.35-27.689.74-9.635-.22-19.318-1.68-28.883"
            />
            <defs>
                <linearGradient
                    id="b"
                    x1={1192.11}
                    x2={1935.85}
                    y1={925.706}
                    y2={925.706}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1815.59}
                    x2={1620.05}
                    y1={854.344}
                    y2={747.481}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.969} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFD207" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1293.78}
                    x2={1617.92}
                    y1={684.274}
                    y2={893.46}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.36} stopColor="#FFF4C3" />
                    <stop offset={0.412} stopColor="#FFDE49" />
                    <stop offset={0.443} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1332.98}
                    x2={1857.56}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1310.83}
                    x2={1232.75}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={0.191} stopColor="#707276" />
                    <stop offset={1} stopColor="#AEAEAF" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={1836.56}
                    x2={1887.14}
                    y1={1104.19}
                    y2={1104.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#AEAEAF" />
                    <stop offset={0.809} stopColor="#707276" />
                    <stop offset={0.999} stopColor="#616469" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={903.64}
                    x2={940.214}
                    y1={521.513}
                    y2={521.513}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={886.715}
                    x2={1052.86}
                    y1={560.123}
                    y2={560.123}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={806.014}
                    x2={983.295}
                    y1={802.07}
                    y2={802.07}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={760.186}
                    x2={1073.59}
                    y1={768.447}
                    y2={768.447}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.453} stopColor="#E19A6D" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={922.085}
                    x2={922.085}
                    y1={988.008}
                    y2={727.647}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.535} stopColor="#E19A6D" />
                    <stop offset={0.976} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={930.578}
                    x2={698.493}
                    y1={932.365}
                    y2={715.942}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.535} stopColor="#E19A6D" />
                    <stop offset={0.976} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={832.824}
                    x2={905.917}
                    y1={1178.91}
                    y2={1178.91}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={938.72}
                    x2={511.925}
                    y1={942.219}
                    y2={935.279}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={921.108}
                    x2={921.108}
                    y1={494.873}
                    y2={427.676}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={879.754}
                    x2={889.99}
                    y1={500.762}
                    y2={483.323}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={931.515}
                    x2={917.726}
                    y1={429.178}
                    y2={429.178}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={909.205}
                    x2={886.869}
                    y1={429.391}
                    y2={429.391}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={780.065}
                    x2={975.127}
                    y1={641.284}
                    y2={641.284}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#0197D6" />
                    <stop offset={0.558} stopColor="#22ADE0" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={777.675}
                    x2={889.336}
                    y1={715.065}
                    y2={715.065}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={905.184}
                    x2={983.299}
                    y1={630.159}
                    y2={630.159}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={752.3}
                    x2={885.023}
                    y1={636.419}
                    y2={635.118}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={1469.82}
                    x2={1336.98}
                    y1={637.529}
                    y2={637.529}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={1456.78}
                    x2={1415.49}
                    y1={581.996}
                    y2={581.996}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={1438.88}
                    x2={1438.88}
                    y1={552.073}
                    y2={484.876}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={1480.23}
                    x2={1469.99}
                    y1={557.96}
                    y2={540.522}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1428.47}
                    x2={1442.26}
                    y1={486.376}
                    y2={486.376}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="F"
                    x1={1450.78}
                    x2={1473.12}
                    y1={486.589}
                    y2={486.589}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="G"
                    x1={1579.15}
                    x2={1376.17}
                    y1={676.493}
                    y2={676.493}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E2E2E2" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1445.78}
                    x2={1651.23}
                    y1={833.889}
                    y2={766.285}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1361.05}
                    x2={1301.28}
                    y1={1059.14}
                    y2={1059.14}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1559.88}
                    x2={1335.75}
                    y1={887.476}
                    y2={887.476}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.039} stopColor="#888B8D" />
                    <stop offset={0.954} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1504.05}
                    x2={1573.45}
                    y1={505.331}
                    y2={689.234}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="L"
                    x1={1418.07}
                    x2={1333.33}
                    y1={675.058}
                    y2={675.058}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#E2E2E2" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1534.12}
                    x2={1450.42}
                    y1={675.499}
                    y2={675.499}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1516.83}
                    x2={1446.39}
                    y1={673.784}
                    y2={673.784}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1454.87}
                    x2={1454.87}
                    y1={508.302}
                    y2={498.897}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.396} stopColor="#FFC614" />
                    <stop offset={1} stopColor="#FFB33B" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1428.39}
                    x2={1428.39}
                    y1={508.303}
                    y2={498.898}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.396} stopColor="#FFC614" />
                    <stop offset={1} stopColor="#FFB33B" />
                </linearGradient>
                <linearGradient
                    id="R"
                    x1={769.216}
                    x2={791.715}
                    y1={560.816}
                    y2={585.6}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#5AC2E7" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={748.259}
                    x2={864.852}
                    y1={556.497}
                    y2={770.837}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#5AC2E7" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="T"
                    x1={547.509}
                    x2={853.554}
                    y1={842.696}
                    y2={842.696}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#63666A" />
                    <stop offset={0.964} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={738.642}
                    x2={821.339}
                    y1={1178.68}
                    y2={1178.68}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={724.919}
                    x2={737.953}
                    y1={537.894}
                    y2={516.202}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.216} stopColor="#FCB47F" />
                    <stop offset={0.433} stopColor="#F8B07C" />
                    <stop offset={0.667} stopColor="#EBA473" />
                    <stop offset={0.909} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="W"
                    x1={764.887}
                    x2={764.887}
                    y1={520.889}
                    y2={457.008}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="Y"
                    x1={702.117}
                    x2={661.233}
                    y1={536.41}
                    y2={605.484}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.033} stopColor="#888B8D" />
                    <stop offset={0.966} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="Z"
                    x1={647.095}
                    x2={723.433}
                    y1={936.965}
                    y2={930.025}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#001489" />
                    <stop offset={1} stopColor="#0147A7" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1={685.191}
                    x2={749.581}
                    y1={1184.38}
                    y2={1184.38}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="ab"
                    x1={633.868}
                    x2={821.453}
                    y1={673.888}
                    y2={641.015}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="ae"
                    x1={631.148}
                    x2={738.063}
                    y1={756.052}
                    y2={756.052}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#5AC2E7" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="af"
                    x1={719.263}
                    x2={709.504}
                    y1={624.841}
                    y2={539.612}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#5AC2E7" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1={597.85}
                    x2={642.09}
                    y1={583.538}
                    y2={644.261}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#5AC2E7" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <linearGradient
                    id="ah"
                    x1={1982.56}
                    x2={1969.52}
                    y1={536.292}
                    y2={514.6}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.216} stopColor="#FCB47F" />
                    <stop offset={0.433} stopColor="#F8B07C" />
                    <stop offset={0.667} stopColor="#EBA473" />
                    <stop offset={0.909} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ai"
                    x1={1942.59}
                    x2={1942.59}
                    y1={519.284}
                    y2={455.402}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ak"
                    x1={2022.29}
                    x2={1957.9}
                    y1={1182.77}
                    y2={1182.77}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="al"
                    x1={2111.45}
                    x2={1849.03}
                    y1={794.819}
                    y2={767.117}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.192} stopColor="#00B140" />
                    <stop offset={0.918} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="ap"
                    x1={2037.85}
                    x2={1899.58}
                    y1={761.137}
                    y2={759.208}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00B140" />
                    <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                    <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                    <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="aq"
                    x1={2043.85}
                    x2={1903.56}
                    y1={799.595}
                    y2={797.638}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00B140" />
                    <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                    <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                    <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
                </linearGradient>
                <linearGradient
                    id="ar"
                    x1={2030.14}
                    x2={1910.35}
                    y1={728.469}
                    y2={726.797}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#00B140" />
                    <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                    <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                    <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
                </linearGradient>
                <radialGradient
                    id="u"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="translate(820.943 762.098) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="B"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 769.14 408.306) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="O"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 430.647 226.879) scale(13.842)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={0.194} stopColor="#D3D3D0" />
                    <stop offset={0.446} stopColor="#C2C3C2" />
                    <stop offset={0.729} stopColor="#A8AAAA" />
                    <stop offset={0.999} stopColor="#888B8D" />
                </radialGradient>
                <radialGradient
                    id="X"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(110.37 0 0 104.101 658.284 660.22)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="ad"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(72.12043 -27.57113 13.27944 34.7363 736.365 619.736)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.437} stopColor="#F15A24" stopOpacity={0} />
                    <stop offset={0.605} stopColor="#F15A24" stopOpacity={0.367} />
                    <stop offset={0.773} stopColor="#F15A24" stopOpacity={0.707} />
                    <stop offset={0.891} stopColor="#F15A24" stopOpacity={0.918} />
                    <stop offset={0.949} stopColor="#F15A24" />
                </radialGradient>
                <radialGradient
                    id="aj"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(-110.37 0 0 -104.101 2049.19 658.56)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="an"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-159.079 1042.696 127.087) scale(77.2111 37.1881)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.437} stopColor="#00B140" stopOpacity={0} />
                    <stop offset={0.502} stopColor="#00B140" stopOpacity={0.144} />
                    <stop offset={0.719} stopColor="#00B140" stopOpacity={0.602} />
                    <stop offset={0.874} stopColor="#00B140" stopOpacity={0.889} />
                    <stop offset={0.949} stopColor="#00B140" />
                </radialGradient>
                <filter
                    id="ac"
                    width={91.356}
                    height={77.894}
                    x={690.926}
                    y={588.367}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_17800"
                        stdDeviation={4}
                    />
                </filter>
                <filter
                    id="am"
                    width={91.305}
                    height={77.894}
                    x={1925.25}
                    y={586.762}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_17800"
                        stdDeviation={4}
                    />
                </filter>
                <filter
                    id="ao"
                    width={182.285}
                    height={198.703}
                    x={1880.57}
                    y={690.043}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_17800"
                        stdDeviation={4}
                    />
                </filter>
                <clipPath id="a">
                    <path fill="#fff" d="M399.344 652.312H221v539.755h178.344z" />
                </clipPath>
                <clipPath id="h">
                    <path fill="#fff" d="M844 314H485.411v358.589H844z" />
                </clipPath>
            </defs>
        </svg>
    );
}
