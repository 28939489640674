import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-3">
        <path
            d="M430.248 250.955a242.75 242.75 0 0 0-11.066-25.884 1072.34 1072.34 0 0 0-3.805-7.58c-2.884-5.716-5.866-11.627-8.604-17.524l-.059-.128a32.151 32.151 0 0 0-28.987-18.405 31.721 31.721 0 0 0-13.581 3.042 31.788 31.788 0 0 0-16.498 18.101 31.807 31.807 0 0 0 1.135 24.466c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.235 5.18 3.36 7.766a242.507 242.507 0 0 0 12.737 25.1c2.135 3.735 4.24 7.32 6.338 10.802l55.041-26.647a551.36 551.36 0 0 0-3.929-10.971Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-3a)"
        />
        <path
            d="M348.783 227.044c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.236 5.18 3.361 7.766a242.492 242.492 0 0 0 12.737 25.099 544.026 544.026 0 0 0 6.337 10.803l55.042-26.647a551.504 551.504 0 0 0-3.93-10.971 242.838 242.838 0 0 0-11.066-25.884 1072.34 1072.34 0 0 0-3.805-7.58c-2.884-5.716-5.866-11.627-8.604-17.524l-.059-.128"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-3a"
                x1={404.04}
                y1={290.557}
                x2={369.341}
                y2={165.641}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
