import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-both-arms-option-1-left-cycling-2">
        <path
            d="M503.717 206.951c-7.696-12.253-24.202-27.922-37.678-36.217l-2.114 9.542 9.65 41.17s33.123-9.749 30.142-14.495Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-left-cycling-2-a)"
        />
        <path
            d="M507.407 212.641c-6.324-11.804-16.237-18.813-29.623-18.813a32.016 32.016 0 0 0-26.222 13.668 32 32 0 0 0-3.833 29.32c2.044 5.596 3.922 11.368 5.738 16.949.801 2.46 1.601 4.92 2.416 7.372 1.433 4.347 6.976 22.847 8.883 27.472l62.773-16.637c-2.458-11.855-13.626-47.186-20.132-59.331Z"
            fill="url(#man-34-25-tshirt-4-both-arms-option-1-left-cycling-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-left-cycling-2-a"
                x1={484.03}
                y1={195.628}
                x2={496.175}
                y2={377.796}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-both-arms-option-1-left-cycling-2-b"
                x1={483.072}
                y1={195.689}
                x2={495.217}
                y2={377.857}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
