import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={68}
            height={82}
            fill="none"
            id="object-food-apple-green"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19.592 15.73a22.19 22.19 0 0 0 14.666 6.497A22.183 22.183 0 0 0 27.762 7.56a22.19 22.19 0 0 0-14.666-6.496 22.183 22.183 0 0 0 6.496 14.666Z"
            />
            <path
                fill="#7CCC6C"
                d="M38.621 27.628a13.516 13.516 0 0 1-8.727 0C17.604 23.413.947 30.614.947 48.936c0 19.746 14.74 32.251 33.31 32.251s33.31-12.505 33.31-32.251c0-18.322-16.655-25.523-28.946-21.308Z"
            />
        </svg>
    );
}
