import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#E2E2E2"
                d="M664.913 683.934c-2.801-1.515-8.145-4.372-12.468-6.475-7.432-3.618-14.887-10.049-21.171-16.485a177.092 177.092 0 01-16.194-19.286s-22.761-3.11-35.075-4.809c-.41 6.452-.933 12.739-1.547 15.932a969.877 969.877 0 01-3.103 15.365 9.997 9.997 0 008.314 11.936l20.107 2.997a41.075 41.075 0 0112.07 3.704 70.757 70.757 0 0020.136 6.142c.315.05.694.096 1.129.139 5.122.503 18.088.545 25.361.527a5.153 5.153 0 004.982-3.896 5.155 5.155 0 00-2.541-5.791z"
            ></path>
            <path
                fill="#63666A"
                d="M663.855 694.423a6.116 6.116 0 01-1.841.289c-8.192.034-22.549-.003-26.648-.637a71.728 71.728 0 01-20.431-6.204 40.152 40.152 0 00-11.782-3.595l-19.769-2.918a11.444 11.444 0 01-7.545-4.339 10.9 10.9 0 01-1.962-8.827c3.088-14.858 3.558-16.361 4.002-20.675.09-.881 1.358-3.47 3.914-3.023 0 0 10.353 13.636 25.36 6.069 3.17-1.918 6.24-3.998 9.196-6.231 0 0 1.87-.929 4.821 3.623 2.108 3.253 13.998 20.358 31.225 28.711 4.239 2.055 9.433 4.815 12.293 6.355a6.46 6.46 0 013.234 3.917 6.163 6.163 0 01-.534 4.557 6.164 6.164 0 01-3.533 2.928z"
            ></path>
            <path
                fill="#E2E2E2"
                d="M613.764 676.331c-13.412-9.629-30.682-11.515-39.186-11.538a1265.24 1265.24 0 01-.701 3.396 10.9 10.9 0 001.963 8.827 11.44 11.44 0 007.544 4.339s42.639 11.275 51.982 12.717c4.099.634 18.456.671 26.648.637a6.063 6.063 0 002.39-.494 6.06 6.06 0 002.004-1.394c-7.965-1.333-39.548-7.088-52.644-16.49z"
            ></path>
        </g>
    );
}
