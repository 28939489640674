import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#female-34-25-shirt-1-arms-opt-2-left-a)">
            <path
                d="M476.73 232.545c.76-3.49 1.23-7.037 1.406-10.605a33.848 33.848 0 0 0-1.403-11.518 105.903 105.903 0 0 1 17.336 13.629 1.706 1.706 0 0 1 .535 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458Z"
                fill="url(#female-34-25-shirt-1-arms-opt-2-left-b)"
            />
            <path
                d="M499.178 228.726c5.332 6.077 10.973 11.882 16.48 17.804a191.304 191.304 0 0 1 15.392 18.765c4.802 6.544 9.446 13.228 13.874 20.102a240.381 240.381 0 0 1 12.416 21.388 19.12 19.12 0 0 1 1.073 14.599 19.13 19.13 0 0 1-9.565 11.082 19.161 19.161 0 0 1-19.26-1.21 240.285 240.285 0 0 1-19.665-14.997c-6.267-5.253-12.316-10.698-18.208-16.281a191.603 191.603 0 0 1-16.689-17.62c-5.187-6.206-10.24-12.529-15.601-18.58a26.5 26.5 0 1 1 39.668-35.149l.085.097Z"
                fill="url(#female-34-25-shirt-1-arms-opt-2-left-c)"
            />
            <path
                d="m499.093 228.625.085.096c5.332 6.077 10.972 11.882 16.48 17.805a191.4 191.4 0 0 1 15.391 18.764c4.802 6.545 9.447 13.228 13.874 20.103a239.751 239.751 0 0 1 12.416 21.388"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M558.178 330.232a38.667 38.667 0 0 0-2.443 3.992 9.107 9.107 0 0 1-3.381 3.276 25.043 25.043 0 0 1-4.688 2.28 23.307 23.307 0 0 1-5.945 1.323 18.03 18.03 0 0 1-19.089-14.546 23.357 23.357 0 0 1-.301-6.083c.139-1.737.459-3.455.955-5.125a9.095 9.095 0 0 1 2.263-4.129 38.962 38.962 0 0 0 3.2-3.414 18.5 18.5 0 1 1 29.658 22.125l-.229.301Z"
                fill="url(#female-34-25-shirt-1-arms-opt-2-left-d)"
            />
            <path
                d="M557.405 306.571a18.507 18.507 0 0 1 1.002 23.361l-.229.301a38.76 38.76 0 0 0-2.443 3.991 9.102 9.102 0 0 1-3.381 3.277 25.043 25.043 0 0 1-4.688 2.28 23.35 23.35 0 0 1-5.945 1.323 18.032 18.032 0 0 1-19.089-14.546 23.357 23.357 0 0 1-.301-6.083c.139-1.737.459-3.455.955-5.125a9.095 9.095 0 0 1 2.263-4.129 38.962 38.962 0 0 0 3.2-3.414 18.42 18.42 0 0 1 10.461-6.916"
                fill="url(#female-34-25-shirt-1-arms-opt-2-left-e)"
            />
            <path
                d="M557.405 306.571a18.507 18.507 0 0 1 1.002 23.361l-.229.301a38.76 38.76 0 0 0-2.443 3.991 9.102 9.102 0 0 1-3.381 3.277 25.043 25.043 0 0 1-4.688 2.28 23.35 23.35 0 0 1-5.945 1.323 18.032 18.032 0 0 1-19.089-14.546 23.357 23.357 0 0 1-.301-6.083c.139-1.737.459-3.455.955-5.125a9.095 9.095 0 0 1 2.263-4.129 38.962 38.962 0 0 0 3.2-3.414 18.42 18.42 0 0 1 10.461-6.916"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-2-left-b"
                x1={502.457}
                y1={203.888}
                x2={422.65}
                y2={273.936}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-2-left-c"
                x1={452.758}
                y1={277.103}
                x2={559.384}
                y2={277.103}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-2-left-d"
                x1={522.262}
                y1={320.762}
                x2={562.08}
                y2={320.762}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-2-left-e"
                x1={522.262}
                y1={321.025}
                x2={562.08}
                y2={321.025}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="female-34-25-shirt-1-arms-opt-2-left-a">
                <path fill="#fff" transform="translate(.385 .86)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
