import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-p2">
        <path
            fill="#63666A"
            d="m541.552 334-173.7 17.6v136.8h157.3c11 0 20-9 20-20l-.3-65.8-3.3-68.6Z"
        />
    </g>
);

export default SvgComponent;
