import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1472}
            viewBox="0 0 2571 1472"
            fill="none"
            id="scenes-kitchen-5"
            {...attributes}
        >
            <path fill="#fff" d="M.102 0h2570v1472H.102z" />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1266.79 528.321h-53.9V172.566h501.61"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1566.06 130.719V560H1180V142.084h350.76v386.232h-205.43"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1325.34 528.656 15.5-46.492h-13.26c13.6-9.299 20.83-22.213 20.83-22.213s-18.77-1.722-36.16 7.576c-.86.517-1.55.861-2.41 1.378 4.14-6.371 7.92-13.776 10.68-21.869 10.5-31.856.34-63.712.34-63.712s-27.38 19.63-37.88 51.314c-1.03 3.272-1.89 6.716-2.58 9.987-12.4-19.974-31.86-30.478-31.86-30.478s-4.99 26.69 6.72 50.97c3.1 6.715 7.23 12.398 11.53 17.391h-15.49l15.49 46.493M1673.95 108.367h192v133.5h138l93 58.5h-304.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2000.95 108.367v112.5h-117l-115.5 79.5v36h342v-34.5M233 594.867h2108.45l55.5 40.5H1335v19.5h1070.95v28.5h-168v105M2256.45 703.867v40.5M2220.45 703.867v40.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1752.66 461.449c4.4-3.016 9.69-4.74 15.3-4.74l39.71-.16v-11.315h18.84v11.315l39.71.16c11.78 0 22.2 7.62 25.77 18.841M1884.23 508.14v58.968c0 5.523-4.48 10-10 10h-114.28c-5.52 0-10-4.477-10-10v-91.557H1892M1884.23 494.391h-149.82M1899.76 494.391h-15.53"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1200H2363.05"
            />
            <path
                fill="url(#a)"
                d="M2161.31 1192.45h-14l-51.03-151.52h24.21l40.82 151.52Z"
            />
            <path
                fill="#E2E2E2"
                d="m2109.31 999.449 11.18 41.481h-24.21l-13.97-41.481h27Z"
            />
            <path
                fill="url(#b)"
                d="M2057.31 1192.45h-14l-16.96-151.52h24.25l6.71 151.52Z"
            />
            <path
                fill="#E2E2E2"
                d="m2048.81 1000.45 1.8 40.48h-24.26l-4.54-40.48h27Z"
            />
            <path
                fill="#AEAEAF"
                d="M1980.33 999.449h27l-1.29 41.481h-24.21l-1.5-41.481Z"
            />
            <path
                fill="#3A383A"
                d="m2006.04 1040.93-4.73 151.52h-14l-5.48-151.52h24.21Z"
            />
            <path
                fill="#AEAEAF"
                d="m1915.63 999.586 27.11.084-12.5 41.25h-24.32l9.71-41.334Z"
            />
            <path
                fill="#3A383A"
                d="m1870.31 1192.45 35.61-151.53h24.32l-45.93 151.53h-14Z"
            />
            <path
                fill="url(#c)"
                d="m1921.31 980.399-1.17.033 1.17-.033c2.65.015 5.06.053 7.09.053 22.68 0 134.5.535 179.52.823 17.04.109 31.82-11.915 35-28.659 1.97-10.31 3.69-22.783 3.96-36.327l.84 48.941c.33 18.804-14.83 34.222-33.64 34.222h-243.77c6.96-18.254 34.12-19.147 51-19.053Z"
            />
            <path
                fill="url(#d)"
                d="M2107.92 981.271c-45.02-.287-156.85-.823-179.53-.823-2.02 0-4.43-.038-7.08-.053l10.06-.286a28.303 28.303 0 0 0 25.77-20.867l27.24-100.619a52.842 52.842 0 0 1 39.31-36.786 240.28 240.28 0 0 1 40.62-5.389c11.79-.549 23.09-.156 33.3.712 27.03 2.299 47.98 24.6 48.45 51.729l.82 47.397c-.27 13.544-2 26.017-3.96 36.327-3.19 16.744-17.96 28.768-35 28.659v-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2146.78 910.639c.19 15.729-1.62 30.237-3.86 41.972-3.19 16.744-17.96 28.768-35 28.659-45.02-.287-156.84-.823-179.52-.823-14.93 0-50.04-2.122-58.09 19h243.77c18.81 0 33.97-15.418 33.64-34.222l-1.66-96.338c-.47-27.128-21.41-49.429-48.45-51.727-10.21-.868-21.5-1.261-33.3-.712a240.28 240.28 0 0 0-40.62 5.389 52.809 52.809 0 0 0-39.3 36.786l-27.25 100.619a28.303 28.303 0 0 1-25.77 20.867l-9.1.291"
            />
            <path
                fill="url(#e)"
                d="M685.355 1192.45h14l51.031-151.52H726.18l-40.825 151.52Z"
            />
            <path
                fill="#E2E2E2"
                d="m737.355 999.449-11.175 41.481h24.206l13.969-41.481h-27Z"
            />
            <path
                fill="url(#f)"
                d="M789.355 1192.45h14l16.967-151.52h-24.259l-6.708 151.52Z"
            />
            <path
                fill="#E2E2E2"
                d="m797.854 1000.45-1.792 40.48h24.259l4.533-40.48h-27Z"
            />
            <path
                fill="#AEAEAF"
                d="M866.336 999.449h-27l1.293 41.481h24.207l1.5-41.481Z"
            />
            <path
                fill="#3A383A"
                d="m840.629 1040.93 4.726 151.52h14l5.481-151.52h-24.207Z"
            />
            <path
                fill="#AEAEAF"
                d="m931.037 999.586-27.111.084 12.504 41.25h24.32l-9.713-41.334Z"
            />
            <path
                fill="#3A383A"
                d="m976.356 1192.45-35.606-151.53h-24.32l45.926 151.53h14Z"
            />
            <path
                fill="url(#g)"
                d="m925.356 980.399 1.171.033-1.171-.033c-2.653.015-5.061.053-7.088.053-22.68 0-134.505.535-179.523.823-17.045.109-31.816-11.915-35.004-28.659-1.963-10.31-3.691-22.783-3.954-36.327l-.844 48.941c-.324 18.804 14.83 34.222 33.637 34.222h243.773c-6.954-18.254-34.115-19.147-50.997-19.053Z"
            />
            <path
                fill="url(#h)"
                d="M738.747 981.271c45.018-.287 156.843-.823 179.523-.823 2.027 0 4.435-.038 7.088-.053l-10.06-.286a28.306 28.306 0 0 1-25.774-20.867l-27.243-100.619a52.824 52.824 0 0 0-39.303-36.786 240.32 240.32 0 0 0-40.622-5.389c-11.795-.549-23.087-.156-33.298.712-27.037 2.299-47.984 24.6-48.452 51.729l-.817 47.397c.263 13.544 1.991 26.017 3.954 36.327 3.189 16.744 17.96 28.768 35.004 28.659v-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M699.884 910.639c-.188 15.729 1.622 30.237 3.857 41.972 3.189 16.744 17.96 28.768 35.004 28.659 45.018-.287 156.843-.823 179.523-.823 14.923 0 50.038-2.122 58.085 19H732.58c-18.807 0-33.961-15.418-33.637-34.222l1.661-96.338c.468-27.128 21.415-49.429 48.451-51.727 10.211-.868 21.503-1.261 33.298-.712a240.292 240.292 0 0 1 40.622 5.389 52.82 52.82 0 0 1 39.303 36.786l27.243 100.619a28.306 28.306 0 0 0 25.774 20.867l9.102.291"
            />
            <path
                fill="#FCB47F"
                d="M847.314 570.385c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3-.5 4.4-.5 5.1l-7.3 13.4-47.5-16s4.1-17.1 4.1-23.7c0-9.5-7-19.2-9.5-26.8-8.1-25 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.5-9.6 41.1-2.6 56.7 13Z"
            />
            <path
                fill="url(#i)"
                d="M830.016 660.882c-14.5 1.2-30.2-11.6-36.6-18.4-1.2-1.3 9.8 29.4 10.3 31.2.6 1.7 23.6 6.5 24.6 8 4.1 5.8 2.1-12 1.7-20.8Z"
            />
            <path
                fill="url(#j)"
                d="M854.216 594.683c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.8.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.2 1.1-55.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M793.415 641.783c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 1.7 3.7 4.1 16.1-1.4 32.4"
            />
            <path
                fill="#F46A25"
                d="M795.615 613.383c-.7 21.8 3.1 36.5 8.2 41.6 5.4 5.4 8.8 9.1 21.5 9.1h13.8c12 0 16.8-7.2 17.1-15.7.4-10.9-.7-19.2-12.3-20.5-3.9 1.4-8.6 1.4-12.7.7-2.8-.5-5.7-1.5-8.4-.6-2.3.7-4 2.6-5.8 4.3-1.8 1.7-4 3.2-6.4 3-2.9-.3-4.9-3-6.5-5.4-1.7-2.5-3.3-16.5-3.3-16.5h-5.2Z"
            />
            <path
                fill="#FCB47F"
                d="M838.913 627.783c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M838.913 627.783c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
            />
            <path
                stroke="url(#k)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M855.516 596.385c-2.5-1.2-6.2-1.8-8.8-2.1"
            />
            <path
                stroke="url(#l)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M833.215 594.383c-6.4 0-13.1.7-17.3 2.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M856.312 606.683c-4.199-1.3-8.4-1-12.5.9M830.715 606.683c-4.2-1.3-8.4-1-12.5.9"
            />
            <path
                fill="#fff"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M822.215 631.98c6 10.2 16 12.4 23 3.9-7.3-.2-15-1.6-23-3.9Z"
            />
            <path
                fill="#FCB47F"
                d="M954.667 980.947s12.055-7.896 22.822-11.937l3.209-1.203a32.107 32.107 0 0 0 20.672-27.341c.5-6.046-.71-12.111-3.513-17.492a32.085 32.085 0 0 0-12.308-12.915 269.982 269.982 0 0 0-28.704-14.19 958.08 958.08 0 0 0-14.497-6.046c-4.836-1.974-9.674-3.944-14.56-5.567a213.215 213.215 0 0 0-29.651-7.265c-2.495-.431-4.99-.86-7.499-1.18-2.509-.333-5.008-.73-7.533-.946l-3.778-.382-3.796-.254c-2.532-.153-5.075-.233-7.637-.175a53.025 53.025 0 0 0-34.02 13.368 53.027 53.027 0 0 0 19.329 90.245l.504.158a109.002 109.002 0 0 0 13.888 3.314h61.212c.361-.065 25.499-.127 25.86-.192Z"
            />
            <path
                fill="#FCB47F"
                d="M946.539 1138.67a776.722 776.722 0 0 1-2.891-25.42c-.41-4.23-.907-8.47-1.246-12.69l-1.081-12.68c-.749-8.45-1.361-16.89-2.352-25.37-.92-8.47-2.2-16.98-2.761-25.41-1.39-16.9.826-33.46 3.634-49.958 1.496-8.242 3.341-16.45 5.67-24.613.554-2.043 1.23-4.075 1.839-6.113.687-2.031 1.326-4.066 2.08-6.09a116.963 116.963 0 0 1 5.041-12.098l5.043-10.305a26.347 26.347 0 0 1 45.985-2.41 26.367 26.367 0 0 1 3.73 17.9l-7.45 49.595-3.731 24.792-3.874 24.79c-2.605 16.52-5.113 33.05-7.799 49.56l-7.98 49.54a15.933 15.933 0 0 1-5.487 9.67 15.965 15.965 0 0 1-10.474 3.73 15.98 15.98 0 0 1-10.362-4.03 15.923 15.923 0 0 1-5.205-9.82l-.329-2.57Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M994.754 915.842c-12.382-8.03-28.268-15.783-37.909-19.973a958.08 958.08 0 0 0-14.497-6.046c-4.836-1.974-9.674-3.944-14.56-5.567a213.215 213.215 0 0 0-29.651-7.265c-2.495-.431-4.99-.86-7.499-1.18-2.509-.333-5.008-.73-7.533-.946l-3.778-.382-3.796-.254c-2.532-.153-5.075-.233-7.637-.175a53.025 53.025 0 0 0-34.02 13.368 53.027 53.027 0 0 0 19.329 90.245l.504.158a109.002 109.002 0 0 0 13.888 3.314h61.212c.17-.03 5.796-.06 11.758-.09M946.869 1141.24l-.33-2.56a777.319 777.319 0 0 1-2.891-25.43c-.41-4.23-.907-8.47-1.246-12.69l-1.081-12.68c-.749-8.45-1.361-16.89-2.352-25.37-.92-8.47-2.2-16.97-2.761-25.41-1.39-16.9.826-33.45 3.634-49.955.126-.696.255-1.391.386-2.086M994.755 915.844a26.38 26.38 0 0 1 11.735 11.424 26.428 26.428 0 0 1 2.74 16.148l-7.45 49.594-3.733 24.8-3.873 24.78c-2.606 16.52-5.113 33.05-7.799 49.56l-7.98 49.54"
            />
            <path
                fill="#FCB47F"
                d="M946.175 1139.38c.295 4.33-1.373 20.83-2.54 31.54a8.998 8.998 0 0 0 2.099 6.81 9.013 9.013 0 0 0 2.874 2.23c1.1.54 2.299.86 3.524.92l20.304 1.01c4.331.2 8.606 1.07 12.674 2.57a69.722 69.722 0 0 0 20.35 4.09c4.09.23 19.08-1.2 26.27-1.93a4.145 4.145 0 0 0 3.73-4.3 4.14 4.14 0 0 0-.74-2.2 4.148 4.148 0 0 0-1.8-1.46c-3.09-1.3-8.57-3.57-13-5.21-19.87-7.36-41.27-32.44-41.27-32.44"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M946.175 1139.38c.295 4.33-1.373 20.83-2.54 31.54a8.998 8.998 0 0 0 2.099 6.81 9.013 9.013 0 0 0 2.874 2.23c1.1.54 2.299.86 3.524.92l20.304 1.01c4.331.2 8.606 1.07 12.674 2.57a69.722 69.722 0 0 0 20.35 4.09c4.09.23 19.08-1.2 26.27-1.93a4.145 4.145 0 0 0 3.73-4.3 4.14 4.14 0 0 0-.74-2.2 4.148 4.148 0 0 0-1.8-1.46c-3.09-1.3-8.57-3.57-13-5.21-19.87-7.36-41.27-32.44-41.27-32.44M984.562 930.836h15.488"
            />
            <path
                fill="#EF0025"
                d="M1033.33 1177.73c-2.93-1.23-8.53-3.55-13.04-5.22-7.75-2.88-15.8-8.55-22.678-14.34-2.376-2-4.614-4.01-6.652-5.93-3.44-.08-13.912 5.79-19.66 6.15-11.52.72-20.071-3.66-27.048-3.18-.408 4.42-.94 9.65-1.588 15.59-.147 1.35-.016 2.73.385 4.03.401 1.3 1.064 2.5 1.948 3.54a9.924 9.924 0 0 0 3.193 2.48c1.223.6 2.555.95 3.915 1.02l20.304 1.01c4.229.2 8.403 1.04 12.374 2.51a70.715 70.715 0 0 0 20.637 4.15c.32.02.7.02 1.14.02 5.15 0 18.06-1.22 25.29-1.95 1.14-.12 2.21-.61 3.04-1.4a5.155 5.155 0 0 0 .94-6.25c-.56-1-1.44-1.78-2.5-2.23Z"
            />
            <path
                fill="#001489"
                d="M951.732 891.946c-17.918-8.617-38.9-14.118-60.266-16.65-26.948-4.109-52.665 9.529-61.917 35.508-11.187 32.377 9.097 65.034 42.302 71.702h41.654s7.466-23.113 17.55-47.727c12.759-31.142 20.677-42.833 20.677-42.833Z"
            />
            <path
                fill="#FCB47F"
                d="M896.786 892.296c11-54 0-75-14-103 11-6 11.362-18.097 8.159-29.766-6.193-22.562-26.159-63.234-52.159-82.234a124.717 124.717 0 0 0-6.412-4.394c-13.871 2.647-23.825 1.817-56.018-13.777-18.105 7.719-33.664 15.108-49.942 28.194-12.192 9.801-18.468 24.223-18.093 39.861l.532 22.205a312.692 312.692 0 0 0 10.858 74.513c6.978 25.68 15.209 59.479 16.033 69.856l-.821.007c-12.574 21.71-8.774 55.308 4.166 70.902 25.861-5.52 123.665-25.708 123.665-25.708s25.335-13.136 34.052-46.607l-.02-.052Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M896.785 892.297c11-54 0-75-14-103 11-6 11.362-18.097 8.159-29.766a160.68 160.68 0 0 0-8.628-23.125"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M882.948 789.491c-3.567-6.179-7.716-12.857-11.112-16.862M773.711 790.722c31.069 3.775 56.531-1.156 71.668-22.12M873.836 868.277a13.776 13.776 0 0 1 10.286 0M771.364 661.191a190.836 190.836 0 0 0-41.284 23.265c-12.04 8.695-22.482 21.536-21.703 45.56l.474 19.37a312.699 312.699 0 0 0 10.857 74.513c7.101 26.13 15.504 58.686 16.076 68.397M862.754 938.956s24.818-11.724 33.534-45.194M734.921 893.762c-12.574 21.71-8.773 55.308 4.167 70.902"
            />
            <path
                fill="#FCB47F"
                d="M862.511 689.476c8.215 4.192 16.628 7.994 24.955 11.964a234.512 234.512 0 0 1 24.258 13.339c7.875 4.862 15.65 9.922 23.285 15.257 7.623 5.358 15.148 10.91 22.35 17.097a23.174 23.174 0 0 1 2.477 32.667 23.205 23.205 0 0 1-22.839 7.463c-9.246-2.159-18.17-4.953-26.996-7.941-8.814-3.011-17.488-6.297-26.062-9.781a234.236 234.236 0 0 1-25.09-11.7c-8.122-4.376-16.158-8.92-24.392-13.075a30.99 30.99 0 0 1-15.48-17.98 31.014 31.014 0 0 1 1.768-23.661 31.009 31.009 0 0 1 17.981-15.48 31.014 31.014 0 0 1 23.661 1.768l.124.063Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M943.181 787.844a23.27 23.27 0 0 1-6.18-.578c-9.246-2.159-18.171-4.953-26.996-7.941-8.814-3.011-17.489-6.297-26.063-9.781a234.311 234.311 0 0 1-25.089-11.7c-8.122-4.376-16.158-8.92-24.392-13.074M862.391 689.414l.124.063c8.215 4.192 16.628 7.994 24.955 11.964a234.324 234.324 0 0 1 24.258 13.339"
            />
            <path
                fill="#FCB47F"
                d="M865.2 690.743c-9.226-7.122-19.957-12.655-32.552-17.169l19.122 31.543s17.866-10.949 13.43-14.374Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M832.648 673.574a129.702 129.702 0 0 1 32.552 17.169"
            />
            <path
                fill="url(#m)"
                d="m830.328 671.684 2.654.951c13.05 4.677 23.788 10.342 32.825 17.318a2.677 2.677 0 0 1 1.092 2.342c-.25 4.648-12.214 12.209-14.61 13.677l-.857.525-21.104-34.813Z"
            />
            <path
                fill="url(#n)"
                d="M909.683 780.273c-8.441-2.885-16.983-6.091-26.117-9.803a236.592 236.592 0 0 1-25.19-11.747c-2.447-1.319-4.89-2.648-7.33-3.988-5.554-3.037-11.298-6.177-17.036-9.073a32.034 32.034 0 0 1-14.154-42.984 31.857 31.857 0 0 1 28.594-17.592 31.647 31.647 0 0 1 14.39 3.438l.128.065c5.718 2.917 11.637 5.694 17.361 8.381 2.527 1.184 5.05 2.375 7.57 3.571a236.281 236.281 0 0 1 24.359 13.395c8.385 5.176 16.017 10.176 23.326 15.284-22.709.83-27.553 37.047-25.901 51.053Z"
            />
            <path
                fill="url(#o)"
                d="M906.479 849.861c-1.63-18.895-9.21-33.783-11.622-39.583l-.105-.257a55.526 55.526 0 0 1-2.741-8.609l-.071.006a42.229 42.229 0 0 1 .717-22.552c1.718-5.35 1.361-12.27-.785-20.087-5.893-21.468-25.64-63.097-52.807-82.951-2.273-1.661-6.155-4.212-8.432-5.504.09 1.419 2.621 22.546 1.142 28.063l-24.452-18.731a100.675 100.675 0 0 1-32.259-21.687s-23.91 7.699-49.609 27.929c-12.029 9.47-18.829 24.613-18.456 40.175l.457 19.058a327.337 327.337 0 0 0 10.716 77.382c.032 3.805.033 7.485.035 11.171.008 20.126.016 40.936 5.172 83.021l.201 1.645.187-.006.082.667 1.011-.702c9.742-.318 73.289-2.45 114.892-5.399 25.951 1.218 47.666-2.091 56.667-15.129 0 0 12.474-19.942 10.06-47.92Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M833.766 702.355c1.83 1.83 7.269 12.144 12.988 23.564M775.117 658.184c6.994 8.078 22.303 18.188 32.228 21.614l24.553 18.979 8-19.516 7.31 6.637"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m807.344 679.801-9.444 13.074-20.923-22.629"
            />
            <path
                fill="#FCB47F"
                d="M920.683 1124.52c-5.3-6.9-10.471-13.85-15.512-20.87-2.52-3.5-5.119-6.97-7.574-10.51l-7.425-10.59c-4.975-7.04-9.825-14.15-15.021-21.09-5.13-6.98-10.589-13.8-15.393-20.92-9.852-14.14-16.418-29.8-22.447-45.717-2.93-7.996-5.542-16.14-7.713-24.488-.568-2.075-1.026-4.202-1.544-6.301-.447-2.131-.939-4.242-1.325-6.402a120.611 120.611 0 0 1-1.847-13.178l-.9-11.26a26.378 26.378 0 0 1 50.778-11.917l18.976 47.328 9.477 23.669 9.35 23.726c6.212 15.83 12.513 31.62 18.652 47.48l18.488 47.55a15.95 15.95 0 0 1-7.023 19.66 15.91 15.91 0 0 1-11 1.73 15.94 15.94 0 0 1-9.452-5.88l-1.545-2.02Z"
            />
            <path
                fill="#FCB47F"
                d="M838.559 980.917c2.534-.379 3.077-.694 5.618-1.13a42.983 42.983 0 0 0 29.608-20.376c11.53-19.413 5.555-44.522-12.964-57.438l-7.419-5.175a113.313 113.313 0 0 0-14.044-8.207c-2.354-1.248-4.732-2.274-7.1-3.398-2.374-1.067-4.76-2.026-7.139-3.044-2.391-.914-4.782-1.832-7.178-2.705l-3.604-1.205-1.803-.599-1.811-.528a85.952 85.952 0 0 0-14.597-3.228l-6.345-.743a57.055 57.055 0 0 0-9.497-.24 56.156 56.156 0 0 0-49.562 37.119 56.16 56.16 0 0 0 14.708 60.15 43.31 43.31 0 0 0 29.461 11.402h66.648l-2.98-.655Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m922.229 1126.53-1.545-2.01a804.288 804.288 0 0 1-15.512-20.87c-2.52-3.51-5.119-6.98-7.574-10.52l-7.425-10.58c-4.975-7.05-9.825-14.15-15.021-21.09-5.13-6.98-10.589-13.8-15.393-20.92-9.852-14.14-16.419-29.81-22.447-45.719a292.199 292.199 0 0 1-4.498-13.25 250.673 250.673 0 0 1-3.215-11.239c-.568-2.076-1.026-4.202-1.544-6.301M878.816 929.391l14.921 39.21 9.478 23.669 9.35 23.73c6.212 15.83 12.513 31.61 18.652 47.48l18.488 47.55M727.534 925.543a56.033 56.033 0 0 0 18.936 45.569 42.86 42.86 0 0 0 28.386 10.462h54.578M878.816 929.392a44.058 44.058 0 0 0-17.995-27.42l-7.419-5.175a113.439 113.439 0 0 0-14.044-8.207c-2.354-1.247-4.732-2.273-7.1-3.398-2.374-1.066-4.76-2.025-7.139-3.044-2.391-.914-4.782-1.831-7.177-2.704l-3.605-1.205-1.803-.6-1.811-.528a85.854 85.854 0 0 0-14.597-3.227l-6.345-.743"
            />
            <path
                fill="#FCB47F"
                d="M920.684 1124.52c3.386 10.78 6.808 20.9 7.394 32.19.32 6.17.22 12.41 1.561 18.45 1.342 6.03 4.351 11.99 9.537 15.35 3.796 2.46 8.386 3.32 12.863 3.97a197.143 197.143 0 0 0 24.144 2.02c1.038.02 2.07-.18 3.02-.6a7.02 7.02 0 0 0 2.481-1.83 7.07 7.07 0 0 0 1.658-5.77c-1.5-9.45-6.03-18.39-10.35-27.16-8.228-16.71-15.06-33.4-23.288-50.11"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M920.684 1124.52c3.386 10.78 6.808 20.9 7.394 32.19.32 6.17.22 12.41 1.561 18.45 1.342 6.03 4.351 11.99 9.537 15.35 3.796 2.46 8.386 3.32 12.863 3.97a197.143 197.143 0 0 0 24.144 2.02c1.038.02 2.07-.18 3.02-.6a7.02 7.02 0 0 0 2.481-1.83 7.07 7.07 0 0 0 1.658-5.77c-1.5-9.45-6.03-18.39-10.35-27.16-8.228-16.71-15.06-33.4-23.288-50.11M856.035 924.824h15.484"
            />
            <path
                fill="url(#p)"
                d="M842.003 888.501a174.096 174.096 0 0 0-28.467-11.814 99.976 99.976 0 0 0-8.674-2.36 114.778 114.778 0 0 0-15.985-3.013c-21.109-2.565-42.301-.868-56.82 21.947-6.401 10.058-8.865 22.785-8.361 35.035a69.94 69.94 0 0 0 6.755 27.42c9.782 20.291 32.882 27.113 44.199 27.113 0 0-.107-.1-.283-.279.415.011.83.029 1.247.029h23.239s-27.614-28.205-10.433-68.658c13.7-32.259 53.583-25.42 53.583-25.42Z"
            />
            <path
                fill="url(#q)"
                d="m879.775 929.104 1.056 2.775c6.716-6.112 13.468-16.502 15.587-34.098-4.895 7.091-13.556 11.3-24.609 13.473a44.89 44.89 0 0 1 7.966 17.85Z"
            />
            <path
                fill="#FCB47F"
                d="m926.518 773.424-2.099-6.235c-.715-2.072-1.467-4.13-2.159-6.211-.654-2.096-1.409-4.153-1.975-6.282a78.08 78.08 0 0 1-.858-3.192 77.942 77.942 0 0 1-.779-3.222c-1.003-4.31-1.946-8.643-2.721-13.041a156.912 156.912 0 0 1-1.877-13.368 16.103 16.103 0 0 1 14.487-17.567 16.138 16.138 0 0 1 14.42 6.367c2.73 3.64 5.27 7.354 7.62 11.143 2.391 3.772 4.614 7.608 6.776 11.47.552.96 1.084 1.929 1.595 2.906a76.157 76.157 0 0 1 1.517 2.935c1.017 1.956 1.844 3.984 2.773 5.974.892 2.004 1.721 4.031 2.59 6.044l2.65 6.021a22.522 22.522 0 1 1-41.96 16.258Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M924.419 767.189c-.715-2.072-1.467-4.13-2.159-6.211-.654-2.096-1.409-4.153-1.975-6.282a78.08 78.08 0 0 1-.858-3.192 77.942 77.942 0 0 1-.779-3.222c-1.003-4.31-1.946-8.643-2.721-13.041a156.912 156.912 0 0 1-1.877-13.368 16.103 16.103 0 0 1 14.487-17.567 16.138 16.138 0 0 1 14.42 6.367c2.73 3.64 5.27 7.354 7.62 11.142 2.391 3.772 4.614 7.609 6.776 11.47.552.961 1.084 1.93 1.595 2.906a76.197 76.197 0 0 1 1.517 2.936c1.017 1.956 1.844 3.984 2.773 5.974.892 2.003 1.721 4.031 2.59 6.044l2.65 6.021a22.522 22.522 0 0 1-11.543 29.687 22.422 22.422 0 0 1-13.075 1.551"
            />
            <path
                fill="#FCB47F"
                d="M907.581 710.947a3.049 3.049 0 0 1 .826-2.921 3.042 3.042 0 0 1 4.143-.116 48.347 48.347 0 0 0 3.571 2.863l.096-2.108c-.152-5.025 2.941-9.496 5.843-13.601 2.903-4.106 5.875-8.711 5.432-13.719a12.334 12.334 0 0 1 .115-4.25l.093-.705a10.667 10.667 0 0 1-2.161-3.567 10.64 10.64 0 0 1-.623-3.331 3.582 3.582 0 0 1 .753-2.307 3.58 3.58 0 0 1 2.049-1.3l.048-.011a3.49 3.49 0 0 1 3.516 1.228 15.638 15.638 0 0 0 5.617 4.476 5.045 5.045 0 0 1 2.752 3.374c1.011 4.203 2.198 8.439 3.119 12.626.545 2.474 3.212 4.212 5.1 5.901 1.888 1.689 3.809 3.642 4.202 6.144v.255c.516.265 1.017.561 1.499.885a5.21 5.21 0 0 1 1.931 6.198 86.49 86.49 0 0 1-5.467 11.928 35.34 35.34 0 0 1-11.177 12.374c-4.724 3.03-10.603 4.494-16.07 3.227a20.825 20.825 0 0 1-11.114-7.166 31.817 31.817 0 0 1-5.932-11.97 3.923 3.923 0 0 1 1.183-4.252l.656-.155Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M929.475 677.944a10.695 10.695 0 0 1-3.936-5.121 10.607 10.607 0 0 1-.623-3.331 3.582 3.582 0 0 1 .753-2.307 3.58 3.58 0 0 1 2.048-1.3l.049-.011a3.488 3.488 0 0 1 3.516 1.228 15.63 15.63 0 0 0 5.617 4.476 5.036 5.036 0 0 1 2.752 3.374c1.011 4.203 2.197 8.439 3.119 12.626.545 2.474 3.212 4.212 5.1 5.901 1.887 1.689 3.809 3.642 4.202 6.144M927.492 681.344c.443 5.008-2.53 9.614-5.432 13.719-2.902 4.105-5.995 8.576-5.844 13.601M945.835 715.208c-2.951-2.289-9.151-4.843-12.46-6.116a3.16 3.16 0 0 0-3.618 1.002 3.166 3.166 0 0 0 .136 4.082 54.47 54.47 0 0 0 9.202 8.229M925.788 715.263c-4.966-.644-10.372-4.83-13.239-7.357a3.045 3.045 0 0 0-4.143.117 3.051 3.051 0 0 0-.479 3.755c2.61 4.343 9.442 13.944 19.56 15.421"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M905.742 715.352a31.846 31.846 0 0 0 5.932 11.97 20.835 20.835 0 0 0 11.114 7.166c5.467 1.267 11.346-.198 16.07-3.227a35.34 35.34 0 0 0 11.177-12.374 86.49 86.49 0 0 0 5.467-11.928 5.21 5.21 0 0 0-1.931-6.198c-3.026-2.049-8.446-3.806-15.759-3.605a2.961 2.961 0 0 0-2.578 1.668l-.017.037a2.907 2.907 0 0 0 1.109 3.769c3.594 2.135 7 4.341 10.692 6.44"
            />
            <path
                fill="#FCB47F"
                d="m887.396 671.725-.327-.328a33.343 33.343 0 0 0-.484-9.557 5.37 5.37 0 0 0-1.282-2.717 4.51 4.51 0 0 0-2.747-1.392l.051-.361a11.604 11.604 0 0 0-2.628-5.81c-3.09-3.498-8.286-4.333-12.915-3.741a2.887 2.887 0 0 0-2.246 1.466l-.069.569a2.913 2.913 0 0 0-2.613 1.149c-3.117 4.08-8.088 6.496-12.263 9.618a38.363 38.363 0 0 0-14.885 25.532c-2.801 19.801-.073 32.117 14.39 28.552l22.476-7.434c3.535-1.169 7.213-2.425 9.823-5.081a20.359 20.359 0 0 0 4.289-7.837 72.592 72.592 0 0 0 3.561-16.06 8.34 8.34 0 0 0-2.131-6.568Zm-19.504-4.027a15.41 15.41 0 0 1-10.088 2.882l.078-1.06c.362-3.692 3.045-6.666 5.451-9.49a32 32 0 0 0 2.056-2.642l.721.393a7.061 7.061 0 0 1 1.955 2.558l2.062 4.625.235.439a14.76 14.76 0 0 1-2.47 2.295Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M834.984 686.153a38.355 38.355 0 0 1 14.885-25.532c4.175-3.122 9.146-5.538 12.263-9.618a2.904 2.904 0 0 1 2.613-1.149l.046.004a2.929 2.929 0 0 1 2.5 3.772 19.139 19.139 0 0 1-3.962 6.4c-2.406 2.824-5.089 5.798-5.451 9.49"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M854.984 670.11a15.369 15.369 0 0 0 12.906-2.411 15.379 15.379 0 0 0 6.174-11.586"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M867.062 647.819c4.63-.592 9.826.243 12.916 3.74a13.168 13.168 0 0 1 2.805 9.596 39.07 39.07 0 0 1-2.445 9.936M869.531 657.246a9.307 9.307 0 0 1 3.405 3.19M868.062 660.34l2.063 4.625M886.583 661.84a33.766 33.766 0 0 1-3.423 22.499"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M889.523 678.293a72.59 72.59 0 0 1-3.56 16.06 20.37 20.37 0 0 1-4.289 7.838c-2.61 2.655-6.288 3.911-9.823 5.081l-22.476 7.433"
            />
            <path
                fill="#FCB47F"
                d="M772.325 711.484c.834 4.677 2.126 9.271 3.213 13.901a63.33 63.33 0 0 1 1.682 14.185c.117 4.863-.025 9.728-.426 14.575a87.547 87.547 0 0 1-2.456 14.95 24.915 24.915 0 0 1-30.495 17.645 24.87 24.87 0 0 1-13.754-9.46 87.671 87.671 0 0 1-7.642-13.082 136.96 136.96 0 0 1-5.61-13.458 63.306 63.306 0 0 1-3.504-13.848c-.641-4.714-1.079-9.465-1.973-14.131a30.999 30.999 0 0 1 47.846-31.495 31 31 0 0 1 13.119 20.218Z"
            />
            <path
                fill="#FCB47F"
                d="m739.652 754.479 10.966-8.438 10.926-8.488c7.3-5.639 14.764-11.072 22.325-16.387 3.801-2.631 7.565-5.308 11.406-7.89 3.82-2.608 7.647-5.207 11.536-7.729 3.879-2.534 7.727-5.107 11.632-7.609 3.947-2.451 7.831-4.979 11.842-7.35a15.658 15.658 0 0 1 23.53 15.174 15.686 15.686 0 0 1-4.105 8.985c-3.176 3.409-6.48 6.659-9.721 9.987-3.282 3.277-6.622 6.483-9.93 9.727-3.297 3.258-6.657 6.437-10.025 9.608-3.346 3.197-6.769 6.298-10.155 9.446-6.814 6.244-13.723 12.368-20.798 18.286l-10.636 8.849-10.596 8.898a22.503 22.503 0 0 1-35.933-10.458 22.508 22.508 0 0 1 6.987-24.01c.224-.187.52-.423.749-.601Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m772.254 711.086.073.399c.733 4.114 1.82 8.163 2.812 12.229M730.089 777.281a87.63 87.63 0 0 1-7.642-13.083 136.858 136.858 0 0 1-5.61-13.458 63.283 63.283 0 0 1-3.504-13.847c-.641-4.714-1.079-9.465-1.974-14.131M849.71 714.746c-3.176 3.409-6.48 6.659-9.721 9.987-3.282 3.276-6.622 6.482-9.929 9.727-3.298 3.258-6.658 6.437-10.026 9.607-3.346 3.198-6.769 6.299-10.155 9.447-6.814 6.243-13.723 12.368-20.798 18.286l-10.636 8.848-10.595 8.899a22.507 22.507 0 0 1-31.707-2.761 22.426 22.426 0 0 1-4.28-7.846M750.617 746.039l10.926-8.488c7.3-5.639 14.764-11.072 22.325-16.387 3.802-2.631 7.565-5.308 11.406-7.89 3.82-2.608 7.647-5.208 11.537-7.729 3.879-2.534 7.727-5.107 11.631-7.609 3.947-2.451 7.831-4.979 11.842-7.35"
            />
            <path
                fill="url(#r)"
                d="M729.281 777.866a89.75 89.75 0 0 1-7.73-13.229 136.735 136.735 0 0 1-5.654-13.562 64.506 64.506 0 0 1-3.554-14.053c-.198-1.457-.377-2.921-.556-4.385-.391-3.194-.796-6.498-1.408-9.69a32.037 32.037 0 0 1 31.461-38.033 32.027 32.027 0 0 1 31.395 25.981l.065.352c.582 3.262 1.387 6.495 2.166 9.621l1.431 5.744c2.25-1.62-46.987 29.133-47.616 51.254Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m750.617 746.038 10.925-8.488c7.3-5.639 14.765-11.072 22.326-16.386 3.802-2.631 7.565-5.309 11.406-7.89 3.82-2.609 7.647-5.208 11.537-7.729 3.879-2.534 7.727-5.107 11.632-7.61 3.946-2.45 7.83-4.979 11.841-7.349"
            />
            <path
                fill="url(#s)"
                d="M939.347 1194.25c-4.73-4-9.199-12.03-10.792-19.2a79.758 79.758 0 0 1-1.411-14.44c-.051-1.39-.102-2.78-.174-4.17-.237-4.18-.82-8.34-1.743-12.43 8.41-5.42 8.414-9.31 16.795-11.92 8.819-2.75 12.722 1.88 20.133 3.27 3.697 8.2 7.52 16.68 11.626 25.02l.492 1c4.17 8.46 8.481 17.2 9.948 26.44.579 3.66.826 7.36.74 11.06-.433 11.35-13.735 22.34-45.614-4.63Z"
            />
            <path
                fill="#F46A25"
                d="M823.912 553.284c9.3 2.2 12.9 13.6 9.3 14.4-1.2.3-2.6 0-3.7-.3-1.4-.3-2.8-.7-4.2-1.1-6.1-1.8-12.701-4.2-19.201-3.1-2.4.4-5.099 1.4-6.399 3.7-1.1 2-.7 4.4 0 6.5 2 5.6 6.9 10.7 5.8 17.1-.5 2.5-1.6 4.9-2.8 7.2-.3.4-.6.9-.9 1.3-2.7 4.3-1 10.7-1 15h-5.3c-1.1-4.4-4.7-7.2-8.3-8.8-3.6-1.6-7.201-1.5-9.601 1.6-2.2 2.9-1.699 9.3.201 13 2.3 4.5 5 8.4 9.4 11-.1 2.5-.301 5.1-.601 7.6-.5 4-5.899 4.8-7.599 1.1-3.5-7.7-7.7-14.3-11-22.5-3.2-7.9-4.501-16.5-3.901-25 2.8-39.3 39.701-43.3 59.801-38.7Z"
            />
            <path
                stroke="#DD4E10"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M770.414 622.081c-4.7-9.9-6.9-18.6-6.3-29.5 2.3-39.8 39.6-43.9 59.8-39.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M792.215 608.685c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M1962.17 688.079c-9.15 2.392-18.41 4.362-27.63 6.514a246.924 246.924 0 0 0-27.24 8.006c-8.96 3.12-17.87 6.455-26.7 10.088-8.82 3.659-17.59 7.528-26.18 12.086a23.102 23.102 0 0 0-6.99 5.706 23.118 23.118 0 0 0-5.19 16.927 23.227 23.227 0 0 0 2.59 8.645 23.16 23.16 0 0 0 8.71 9.087 23.138 23.138 0 0 0 12.17 3.197c9.72-.186 19.26-1.061 28.75-2.146 9.49-1.112 18.89-2.521 28.24-4.145 9.35-1.542 18.6-3.621 27.7-6.227 9.1-2.593 18.15-5.367 27.32-7.72a31.027 31.027 0 0 0 10.9-5.236c3.26-2.444 6-5.505 8.07-9.009a31.16 31.16 0 0 0 4.01-11.413c.57-4.03.35-8.134-.67-12.077a31.026 31.026 0 0 0-5.23-10.904 31.006 31.006 0 0 0-32.5-11.414l-.13.035Z"
            />
            <path
                fill="#FCB47F"
                d="m1881.88 747.127.54-7.705c.2-2.567.43-5.129.6-7.698.13-2.574.37-5.135.41-7.719.05-1.289.06-2.581.06-3.875.02-1.292.02-2.586-.01-3.883-.07-5.183-.2-10.372-.5-15.581-.28-5.205-.73-10.43-1.4-15.678a16.024 16.024 0 0 0-1.98-5.896 15.736 15.736 0 0 0-4.08-4.692 15.862 15.862 0 0 0-5.56-2.774c-2.02-.55-4.13-.696-6.21-.432a15.98 15.98 0 0 0-7.97 3.395 15.966 15.966 0 0 0-5.02 7.058 209.478 209.478 0 0 0-4.73 15.014c-1.41 5.022-2.65 10.064-3.82 15.112-.31 1.261-.59 2.524-.85 3.789a99.788 99.788 0 0 0-.77 3.799c-.52 2.531-.83 5.085-1.26 7.627-.38 2.546-.7 5.099-1.06 7.648l-1.12 7.641a22.18 22.18 0 0 0 .44 8.774 22.467 22.467 0 0 0 10.28 13.832 22.507 22.507 0 0 0 24.98-1.254 22.493 22.493 0 0 0 8.85-14.79c.08-.556.14-1.161.18-1.712Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1856.14 767.834c14.11.803 28.84-.56 38.33-1.645 9.48-1.112 18.89-2.521 28.24-4.145a247.038 247.038 0 0 0 27.7-6.227c9.1-2.593 18.15-5.368 27.31-7.72a30.979 30.979 0 0 0 10.9-5.236 30.9 30.9 0 0 0 8.07-9.01 30.795 30.795 0 0 0 4.01-11.412c.58-4.03.35-8.134-.66-12.077a30.868 30.868 0 0 0-5.24-10.904 30.859 30.859 0 0 0-9.01-8.07 30.942 30.942 0 0 0-11.41-4.008 31.021 31.021 0 0 0-12.08.664l-.13.034c-9.15 2.392-18.41 4.362-27.62 6.514a245.654 245.654 0 0 0-27.24 8.006 552.886 552.886 0 0 0-20.84 7.725"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1882.42 739.422c.2-2.567.43-5.129.6-7.698.13-2.574.37-5.136.41-7.719.05-1.289.06-2.581.06-3.875.02-1.292.02-2.586-.01-3.883-.07-5.183-.2-10.372-.5-15.581-.28-5.205-.73-10.43-1.4-15.678M1850.76 681.648a209.478 209.478 0 0 0-4.73 15.014c-1.41 5.022-2.65 10.064-3.82 15.112-.31 1.261-.59 2.524-.85 3.789a99.788 99.788 0 0 0-.77 3.799c-.52 2.531-.83 5.085-1.26 7.627-.38 2.546-.7 5.099-1.06 7.647l-1.12 7.642a22.18 22.18 0 0 0 .44 8.774 22.467 22.467 0 0 0 10.28 13.832 22.489 22.489 0 0 0 8.27 2.951"
            />
            <path
                fill="#FCB47F"
                d="M1842.59 657.355a46.228 46.228 0 0 1 13.16-11.036 22 22 0 0 1 6.64-2.408l.1-.018c.17-4.887.35-9.774.53-14.66.12-3.178.12-6.295.18-9.445.01-.749.29-1.469.78-2.033a3.2 3.2 0 0 1 1.91-1.049l.06-.008c.77-.114 1.56.061 2.21.491.66.43 1.13 1.085 1.33 1.841 1.44 5.187 1.59 10.705 1.81 16.115.27 6.373.7 12.926 3.44 18.683-.07-.162 1.42 3.489 1.34 3.327l2.03 4.2a8.7 8.7 0 0 0 .32-3.759c-.2-1.774-.73-3.498-.94-5.27a9.528 9.528 0 0 1 .07-3.061c.14-.604.46-1.149.93-1.553a2.9 2.9 0 0 1 1.68-.691c.6-.055 1.2.079 1.72.382.53.304.94.762 1.19 1.311.82 1.841 2.05 3.789 2.91 5.321 2.47 4.388 2.57 9.815 1.23 14.67-1.33 4.855-3.98 9.239-6.8 13.409-8.56 13.779-23.66 16.767-29.75 5.205a120.495 120.495 0 0 1-9.52-21.916 8.585 8.585 0 0 1-.31-4.208 8.669 8.669 0 0 1 1.75-3.84Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1880.26 682.136c2.82-4.17 5.47-8.553 6.8-13.408 1.34-4.855 1.23-10.283-1.24-14.67-.86-1.533-2.08-3.48-2.9-5.321a2.921 2.921 0 0 0-2.92-1.694c-.62.045-1.2.288-1.67.692-.47.404-.8.948-.93 1.552a9.53 9.53 0 0 0-.08 3.061c.22 1.772.75 3.496.94 5.27a7.285 7.285 0 0 1-1.02 5.13M1874.36 653.363l.22.489c-2.75-5.758-3.18-12.311-3.44-18.684-.22-5.41-.37-10.927-1.81-16.114a3.198 3.198 0 0 0-3.55-2.333l-.05.008a3.171 3.171 0 0 0-2.69 3.082c-.07 3.151-.06 6.267-.18 9.445l-.54 14.66M1863.99 647.289c2.9 2.62 5.89 5.366 7.38 8.981a17.938 17.938 0 0 1 .25 11.445 6.597 6.597 0 0 1-1.84 3.411c-1.02.838-3.16 1.85-4.91-1.105"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1857.22 652.773a16.44 16.44 0 0 1 5.76 8.156 49.441 49.441 0 0 1 2.01 9.93c.16 1.241.29 2.626-.47 3.628-.45.515-1.07.867-1.75 1.003-.67.135-1.37.048-2-.249-1.26-.544-3.05-2.908-3.78-4.077"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1859.26 644.496a46 46 0 0 0-16.83 12.88 8.455 8.455 0 0 0-1.75 3.84 8.47 8.47 0 0 0 .31 4.208 119.121 119.121 0 0 0 9.52 21.916"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1849.48 659a31.673 31.673 0 0 1 8.44 16.223 2.609 2.609 0 0 1-1.48 2.724l-.06.027a2.642 2.642 0 0 1-3.33-.95 30.082 30.082 0 0 0-6.79-7.023"
            />
            <path
                fill="#FCB47F"
                d="M1950.22 690.743c9.23-7.122 19.96-12.655 32.55-17.169l-19.12 31.543s-17.86-10.949-13.43-14.374Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1982.77 673.574a129.741 129.741 0 0 0-32.55 17.169"
            />
            <path
                fill="#001489"
                d="m1985.09 671.68-2.65.951c-13.06 4.677-23.79 10.342-32.83 17.319-.36.266-.66.62-.85 1.03-.19.409-.27.86-.24 1.311.25 4.648 12.21 12.209 14.61 13.677l.86.525 21.1-34.813Z"
            />
            <path
                fill="#001489"
                d="M1942.68 691.7c6.33-1.437 12.87-2.923 19.24-4.59a32.177 32.177 0 0 1 8.11-1.048c7.1.002 14 2.366 19.61 6.721a31.966 31.966 0 0 1 11.37 17.33 32.05 32.05 0 0 1-23.04 38.953c-9.22 2.366-18.21 5.127-27.29 7.711-17.29 5.039-37.66 8.128-55.34 10.31l-1.79-60.579c15.96-6.068 32.7-11.461 49.13-14.808Z"
            />
            <path
                fill="#FCB47F"
                d="M1918.64 892.296c-11-54 0-75 14-103-11-6-11.37-18.097-8.16-29.766 6.19-22.562 26.16-63.234 52.16-82.234a123.38 123.38 0 0 1 6.41-4.394c13.87 2.647 23.82 1.817 56.02-13.777 18.1 7.719 33.66 15.108 49.94 28.194 12.19 9.801 18.47 24.223 18.09 39.861l-.53 22.205a312.847 312.847 0 0 1-10.86 74.513c-6.98 25.68-15.21 59.479-16.03 69.856l.82.007c12.57 21.71 8.77 55.308-4.17 70.902-25.86-5.52-123.66-25.708-123.66-25.708s-25.34-13.136-34.05-46.607l.02-.052Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1918.64 892.297c-11-54 0-75 14-103-11-6-11.37-18.097-8.16-29.766a160.216 160.216 0 0 1 8.63-23.125"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1932.47 789.491c3.57-6.179 7.72-12.857 11.12-16.862M2041.71 790.722c-31.07 3.775-56.53-1.156-71.67-22.12M1941.59 868.277a13.787 13.787 0 0 0-10.29 0M2044.06 661.191a190.903 190.903 0 0 1 41.28 23.265c12.04 8.695 22.48 21.536 21.7 45.56l-.47 19.37a312.348 312.348 0 0 1-10.86 74.513c-7.1 26.13-15.5 58.686-16.07 68.397M1952.67 938.956s-24.82-11.724-33.54-45.194M2080.5 893.762c12.57 21.71 8.77 55.308-4.17 70.902"
            />
            <path
                fill="#FCB47F"
                d="M1860.75 980.947s-12.05-7.896-22.82-11.937l-3.21-1.203a32.127 32.127 0 0 1-14.29-10.68 32.075 32.075 0 0 1-6.37-16.661 32.141 32.141 0 0 1 3.5-17.492c2.8-5.382 7.07-9.86 12.31-12.915a270.167 270.167 0 0 1 28.71-14.19c4.82-2.068 9.66-4.066 14.49-6.046 4.84-1.974 9.68-3.944 14.56-5.567 9.7-3.127 19.61-5.555 29.66-7.265 2.49-.431 4.98-.86 7.49-1.18 2.51-.333 5.01-.73 7.54-.946l3.77-.382 3.8-.254c2.53-.153 5.08-.233 7.64-.175a53.044 53.044 0 0 1 34.02 13.368 53.044 53.044 0 0 1 17.29 32.205 53.041 53.041 0 0 1-7.66 35.742 53.005 53.005 0 0 1-28.96 22.298l-.5.158c-4.56 1.407-9.2 2.514-13.89 3.314h-61.22c-.36-.065-25.49-.127-25.86-.192Z"
            />
            <path
                fill="#FCB47F"
                d="M1868.88 1138.67c1.11-8.48 2.07-16.96 2.89-25.42.41-4.23.91-8.47 1.25-12.69l1.08-12.68c.75-8.45 1.36-16.89 2.35-25.37.92-8.47 2.2-16.98 2.76-25.41 1.39-16.9-.82-33.46-3.63-49.958-1.5-8.242-3.34-16.45-5.67-24.613-.55-2.043-1.23-4.075-1.84-6.113-.69-2.031-1.32-4.066-2.08-6.09a117.398 117.398 0 0 0-5.04-12.098l-5.04-10.305a26.342 26.342 0 0 0-31.5-13.57 26.337 26.337 0 0 0-18.22 29.06l7.45 49.595 3.73 24.792 3.88 24.79c2.6 16.52 5.11 33.05 7.8 49.56l7.98 49.54c.61 3.78 2.55 7.21 5.48 9.67 2.93 2.46 6.65 3.78 10.48 3.73 3.82-.06 7.5-1.49 10.36-4.03 2.86-2.54 4.7-6.03 5.2-9.82l.33-2.57Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1820.67 915.842c12.38-8.03 28.27-15.783 37.91-19.973 4.82-2.068 9.66-4.066 14.49-6.046 4.84-1.974 9.68-3.944 14.56-5.567 9.7-3.127 19.61-5.555 29.66-7.265 2.49-.431 4.98-.86 7.49-1.18 2.51-.333 5.01-.73 7.54-.946l3.77-.382 3.8-.254c2.53-.153 5.08-.233 7.64-.175a53.044 53.044 0 0 1 34.02 13.368 53.044 53.044 0 0 1 17.29 32.205 53.041 53.041 0 0 1-7.66 35.742 53.005 53.005 0 0 1-28.96 22.298l-.5.158c-4.56 1.407-9.2 2.514-13.89 3.314h-61.22c-.17-.03-5.79-.06-11.75-.09M1868.55 1141.24l.33-2.56a763.8 763.8 0 0 0 2.89-25.43c.41-4.23.91-8.47 1.25-12.69l1.08-12.68c.75-8.45 1.36-16.89 2.35-25.37.92-8.47 2.2-16.97 2.76-25.41 1.39-16.9-.82-33.45-3.63-49.955-.13-.696-.25-1.391-.39-2.086M1820.67 915.844a26.366 26.366 0 0 0-14.47 27.572l7.44 49.594 3.73 24.8 3.88 24.78c2.6 16.52 5.11 33.05 7.8 49.56l7.98 49.54"
            />
            <path
                fill="#FCB47F"
                d="M1869.25 1139.38c-.3 4.33 1.37 20.83 2.54 31.54a9.044 9.044 0 0 1-2.1 6.81 9.08 9.08 0 0 1-2.88 2.23c-1.1.54-2.3.86-3.52.92l-20.3 1.01c-4.34.2-8.61 1.07-12.68 2.57a69.735 69.735 0 0 1-20.34 4.09c-4.1.23-19.09-1.2-26.28-1.93a4.1 4.1 0 0 1-2.71-1.41 4.139 4.139 0 0 1-.28-5.09c.45-.65 1.07-1.15 1.8-1.46 3.09-1.3 8.57-3.57 13-5.21 19.87-7.36 41.27-32.44 41.27-32.44"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1869.25 1139.38c-.3 4.33 1.37 20.83 2.54 31.54a9.044 9.044 0 0 1-2.1 6.81 9.08 9.08 0 0 1-2.88 2.23c-1.1.54-2.3.86-3.52.92l-20.3 1.01c-4.34.2-8.61 1.07-12.68 2.57a69.735 69.735 0 0 1-20.34 4.09c-4.1.23-19.09-1.2-26.28-1.93a4.1 4.1 0 0 1-2.71-1.41 4.139 4.139 0 0 1-.28-5.09c.45-.65 1.07-1.15 1.8-1.46 3.09-1.3 8.57-3.57 13-5.21 19.87-7.36 41.27-32.44 41.27-32.44M1830.86 930.836h-15.48"
            />
            <path
                fill="#001489"
                d="M1816.38 1017.97c-2.42-16.03-8.68-57.772-11.18-74.396-2.01-13.006 4.8-23.162 16.08-29.896 2.15-1.279 5.96-3.23 8.08-4.469 15.85-9.102 28.73-14.261 43.33-20.298 19.14-8.311 40.9-13.294 63.33-15.413 27.69-3.551 53.14 10.4 61.86 37.401a53.945 53.945 0 0 1 2.23 21.086 53.941 53.941 0 0 1-6.04 20.326 54.074 54.074 0 0 1-32.06 26.482 109.573 109.573 0 0 1-14.1 3.359l-61.39-.004c-.43-.028-6.15-.058-10.89-.082 3.66 19.314 6.03 37.884 4.58 55.114-1.17 14.68-5.65 89.39-5.94 99h-44.38c-.87-21.9-11.72-106.82-13.51-118.21Z"
            />
            <path
                fill="url(#t)"
                d="M1829.14 1125.33c-.41-4.79-.97-10.42-1.61-16.56l48.2-.15c-.36 6.25-.68 11.94-.92 16.57l-45.67.14Z"
            />
            <path
                fill="url(#u)"
                d="M1825.97 1094.46c-1.92-17-4.2-35.65-6.09-50.42l59.85-.2c-.78 11.42-2.04 31.7-3.16 50.46l-50.6.16Z"
            />
            <g clipPath="url(#v)">
                <path
                    fill="#FCB47F"
                    d="M1968.61 567.865c-4.4 10.2-7.6 24.2-7.6 35.3 0 11.1 1.9 22.2 4.7 32.9 1.6 6.1 2.7 12.9 6.2 18 2.5 3.7 7.8 5.2 12.3 4.8.7-.1 1.3-.1 2-.2-.4 1.3.5 4.4.5 5.1l7.3 13.4 47.5-16s-4.1-17.1-4.1-23.7c0-9.5 7-19.2 9.5-26.8 8.1-25-3.1-37.8-3.1-37.8s-2.8-11.1-18.5-18c-22.5-9.6-41.1-2.6-56.7 13Z"
                />
                <path
                    fill="url(#w)"
                    d="M1985.91 658.362c14.5 1.2 30.2-11.6 36.6-18.4 1.2-1.3-9.8 29.4-10.3 31.2-.6 1.7-23.6 6.5-24.6 8-4.1 5.8-2.1-12-1.7-20.8Z"
                />
                <path
                    fill="url(#x)"
                    d="M1961.71 592.164c2-.2 4-.4 6-.7 2-.2 4.1-.4 5.7.8 1.2.9 1.8 2.4 1.9 3.8.1 1.5.4 3 .1 4.4-2.2 12.9-1.5 21.9.9 24.5.5.6 1 1.3 1.1 2 .1.8-.1 1.5-.2 2.3-.9 4.2-1.8 8.5-2.7 12.7-.5 2.3-.8 4.7-1.1 7-.4 3.9.6 7.3 4.6 9.4-6.6-1.3-13.7-7.7-15.2-10.5-9.5-18 .8-37.2-1.1-55.7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M2022.51 639.264c-9.3 11.5-23.7 18.3-38.4 19.7-5.6.6-13.7-1.9-18.2-6.6-7.2-7.7-9.7-22.7-6.1-37 1.2-4.9 2.2-7.5 2.1-11.3 0-2.9-.8-6.7-.4-9.8 1.1-9 1.3-18.7 8.9-27.4 25.7-29.5 60.1-12.6 69-1.5 8.2 10.2 12.1 23.8 9.7 36.6-1.6 8.7-5.8 16.7-9.5 24.7-1.7 3.7-4.1 16.1 1.4 32.4"
                />
                <path
                    stroke="url(#y)"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={5}
                    d="M1960.41 593.963c2.5-1.2 6.2-1.8 8.8-2.1"
                />
                <path
                    stroke="url(#z)"
                    strokeLinecap="round"
                    strokeMiterlimit={10}
                    strokeWidth={5}
                    d="M1982.71 591.863c6.4 0 13.1.7 17.3 2.5"
                />
                <path
                    fill="#111011"
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M1965.81 609.163c.77 0 1.4-1.03 1.4-2.3 0-1.271-.63-2.301-1.4-2.301-.78 0-1.4 1.03-1.4 2.301 0 1.27.62 2.3 1.4 2.3ZM1991.41 609.163c.77 0 1.4-1.03 1.4-2.3 0-1.271-.63-2.301-1.4-2.301-.78 0-1.4 1.03-1.4 2.301 0 1.27.62 2.3 1.4 2.3Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M1970.41 633.263c7.6 1.2 15.4.5 22.7-1.9"
                />
                <path
                    fill="#FCB47F"
                    d="M1975.41 597.266c-.2 5.1 2.1 13.3-5.6 17.4-5.2 2.7-1.1 6.4.3 7.2 2.5 1.4 5 1.9 6.9 2.2"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M1975.41 597.266c-.2 5.1 2.1 13.3-5.6 17.4-5.2 2.7-1.1 6.4.3 7.2 2.5 1.4 5 1.9 6.9 2.2"
                />
                <path
                    fill="#7A5430"
                    d="M2029.61 635.062c-.6-2.2-.8-4.5-.4-6.4 5.4-3.8 9.3-9.6 10.7-16 .6-2.9.8-6.1-1-8.5-.5-.6-1.2-1.1-1.9-1.5-8.1-3.8-8.8.7-12.7 2.7-1.7-.4-4-2.6-4.7-4.2-2.5-6.2-3.2-13.2-1.8-19.7-3.3 1.1-7.6-.7-9.1-3.8-.9-1.7-1-3.8-1.8-5.5-1.1-2.3-3.6-4-6.2-4.2-2.7-.2-5.2 1.1-7.5 2.6-2.2 1.5-4.3 3.3-6.8 4.4-2.5 1-5.6 1.2-7.7-.4-.8.7-.4.8-1.2 1.6-3.5 3.6-9.3 4.7-13.8 2.6-.8 2.2-1.5 4.4-2 6.7-3.8 1-7.8-.9-9.9-4.1-2.1-3.2-2.6-7.3-2.4-11.2.4-7.4 3.5-15.1 9.8-19.1 1.2-.8 2.7-1.5 4.1-1.2 1.5.2 2.8 1.6 2.5 3 3.6-8.6 12.7-14.3 22-14.4 6.6-.1 5.5 5 5.5 5s1.7-.3 2.5-.6c11-4.8 23.9-5.2 35.2-1.1 5.1 1.8 10 4.6 13.1 8.9 3.1 4.3 4.3 10.4 1.9 15.2 3.7.7 8.3 2 11.6 3.9 4.9 2.8 8.1 8.3 8.2 13.9.1 5.6-2.9 11.2-7.6 14.2 6.4 2.2 10.3 15-2.8 22-6.8 3.7-13.9 9.2-18.7 16.4-1.8 2.9-6.2 2.1-7.1-1.2Z"
                />
                <path
                    stroke="#5B3114"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M1949.61 570.162c.4-7.4 3.5-15.1 9.8-19.1 1.2-.8 2.7-1.5 4.1-1.2 1.5.2 2.8 1.6 2.5 3 3.6-8.6 12.7-14.3 22-14.4 6.6-.1 5.5 5 5.5 5s1.7-.3 2.5-.6c11-4.8 23.9-5.2 35.2-1.1 5.1 1.8 10 4.6 13.1 8.9 3.1 4.3 4.3 10.4 1.9 15.2 3.7.7 8.3 2 11.6 3.9 4.9 2.8 8.1 8.3 8.2 13.9.1 5.6-2.9 11.2-7.6 14.2 6.4 2.2 10.3 15-2.8 22"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M2023.61 606.263c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
                />
                <path
                    fill="#E2E2E2"
                    d="M1782.14 1177.67c2.93-1.24 8.53-3.56 13.04-5.23 7.75-2.87 15.8-8.54 22.68-14.33 6.41-5.44 12.43-11.33 18-17.62 0 0 22.96-.87 35.38-1.35-.22 6.46-.32 12.77-.02 16 .41 4.43.94 9.65 1.59 15.6a9.95 9.95 0 0 1-.39 4.02 10.01 10.01 0 0 1-1.94 3.55c-.89 1.03-1.98 1.88-3.2 2.48-1.22.6-2.55.95-3.91 1.01l-20.31 1.02c-4.23.2-8.4 1.04-12.37 2.51-6.64 2.4-13.59 3.8-20.64 4.14-.32.02-.7.03-1.14.03-5.14 0-18.05-1.22-25.29-1.95a5.196 5.196 0 0 1-3.04-1.4 5.193 5.193 0 0 1-1.54-2.97 5.13 5.13 0 0 1 .61-3.28c.56-1 1.43-1.79 2.49-2.23Z"
                />
                <path
                    fill="#63666A"
                    d="M1782.16 1188.21c.58.24 1.19.4 1.81.46 8.15.84 22.44 2.2 26.58 1.97 7.15-.34 14.21-1.74 20.94-4.17 3.88-1.43 7.95-2.25 12.08-2.43l19.96-.97c3.01-.1 5.86-1.39 7.93-3.58 1.06-1.15 1.86-2.51 2.34-3.99.49-1.49.65-3.06.48-4.61-1.63-15.08-1.95-16.63-1.97-20.96 0-.89-1.01-3.59-3.6-3.39 0 0-11.63 12.55-25.83 3.56-2.97-2.22-5.82-4.59-8.54-7.1 0 0-1.77-1.11-5.15 3.13-2.42 3.03-15.92 18.89-33.88 25.52-4.42 1.64-9.86 3.87-12.86 5.13-.81.32-1.55.8-2.17 1.42-.62.62-1.1 1.35-1.43 2.16-.56 1.48-.53 3.13.09 4.59a6.148 6.148 0 0 0 3.22 3.26Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M1833.78 1175.1c14.29-8.27 31.67-8.46 40.13-7.65.12 1.12.24 2.26.37 3.44.17 1.55.01 3.12-.48 4.61-.48 1.48-1.28 2.84-2.34 3.99a11.44 11.44 0 0 1-7.93 3.58s-43.54 7.05-52.98 7.57c-4.14.23-18.43-1.13-26.58-1.97-.82-.08-1.61-.32-2.33-.72-.72-.4-1.35-.94-1.86-1.58 8.06-.55 40.05-3.19 54-11.27Z"
                />
                <path
                    fill="url(#A)"
                    d="M1908.94 849.861c1.65-19.047 9.34-34.034 11.69-39.734 4.43-10.769 5.24-21.785 2.28-31.019-1.72-5.35-1.5-12.025.65-19.842 5.89-21.468 25.1-63.041 52.27-82.895a89.625 89.625 0 0 1 9.31-5.855s-1.38 9.149 2.2 14.191a9.773 9.773 0 0 0 5.68 3.396c1.32.239 2.65.359 3.99.36 7.46.001 14.54-3.373 23-10.929 1.92-1.71 3.87-3.777 5.93-5.966 4.21-4.472 8.98-9.496 14.87-12.904 17.73 7.173 34.15 16.035 48.89 27.894a50.213 50.213 0 0 1 18.46 40.175l-.46 19.058a327.238 327.238 0 0 1-10.72 77.382c-.03 3.805-.03 7.485-.03 11.171-.01 28.391-1.93 56.477-5.37 84.666-32.37-22.478-61.3-11.568-97.52-7.586-33.73 3.709-63.99 2.378-75.06-13.644 0 0-12.47-19.942-10.06-47.919Z"
                />
                <path
                    fill="url(#B)"
                    d="M2104.66 783.895h-180.63a37.042 37.042 0 0 0-1.2-4.986c-1.72-5.35-1.51-12.025.64-19.843 1.27-4.521 2.77-8.976 4.49-13.347h179.65a355.912 355.912 0 0 1-2.95 38.176Z"
                />
                <path fill="url(#C)" d="m2007.74 685.772.1-.053-.1.052v.001Z" />
                <path
                    fill="url(#D)"
                    d="M1975.74 676.171a89.763 89.763 0 0 1 9.32-5.855s-1.07 7.086 1.11 12.222c.29.693.65 1.353 1.08 1.969a9.773 9.773 0 0 0 5.68 3.396c1.32.239 2.66.359 4 .36 3.74-.021 7.43-.872 10.81-2.492l.1-.053a45.38 45.38 0 0 0 7.41-4.548 65.738 65.738 0 0 0 3.1-2.466c.16-.13.31-.271.47-.404.37-.317.74-.633 1.11-.965 1.91-1.71 3.86-3.777 5.92-5.966l.01-.002c4.21-4.472 8.97-9.494 14.87-12.902 17.72 7.173 34.14 16.035 48.88 27.893a50.216 50.216 0 0 1 18.46 40.175l-.11 4.587h-173.72c9.46-19.821 23.79-42.002 41.5-54.949Z"
                />
                <path
                    fill="#fff"
                    stroke="#231F20"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1874.77 647.248c6.08 0 11-4.788 11-10.694 0-5.906-4.92-10.695-11-10.695-6.07 0-11 4.789-11 10.695 0 5.906 4.93 10.694 11 10.694Z"
                />
                <path
                    fill="#231F20"
                    d="M1874.77 638.86c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.309a2.305 2.305 0 0 0 2.3 2.309Z"
                />
                <path
                    fill="url(#E)"
                    d="M2098.98 814.775h-180.37c.77-1.995 1.44-3.644 1.94-4.848a54.89 54.89 0 0 0 3.38-11.72h178.5a304.207 304.207 0 0 1-3.45 16.568Z"
                />
                <path
                    fill="#FCB47F"
                    d="M2047.28 731.735c3.87 7.594 7.35 15.386 11 23.092a200.936 200.936 0 0 0 12.37 22.394c4.54 7.255 9.28 14.408 14.3 21.423a248.01 248.01 0 0 0 16.13 20.49c3.42 3.834 8 6.43 13.05 7.385 5.05.955 10.26.214 14.85-2.106 4.58-2.32 8.26-6.09 10.48-10.723a23.303 23.303 0 0 0 1.76-14.889 248.418 248.418 0 0 0-6.97-25.13c-2.69-8.192-5.65-16.245-8.82-24.197a198.584 198.584 0 0 0-10.73-23.226c-4.05-7.5-8.28-14.915-12.11-22.528a31.03 31.03 0 0 0-7.44-9.535 30.958 30.958 0 0 0-10.53-5.961 30.972 30.972 0 0 0-12-1.48c-4.06.3-8.02 1.397-11.66 3.227a31.071 31.071 0 0 0-9.53 7.443 30.866 30.866 0 0 0-5.96 10.525 30.928 30.928 0 0 0-1.48 12.004c.3 4.06 1.39 8.021 3.22 11.658l.07.134Z"
                />
                <path
                    fill="#FCB47F"
                    d="m2097.32 804.807-1.54 13.734-1.61 13.725c-1.04 9.153-1.82 18.342-2.45 27.55-.28 4.609-.63 9.21-.84 13.827-.26 4.613-.5 9.227-.64 13.855-.16 4.625-.37 9.244-.49 13.874-.05 4.64-.2 9.266-.15 13.919.04 3.765 1.43 7.39 3.93 10.21a15.644 15.644 0 0 0 9.65 5.146 15.65 15.65 0 0 0 17.14-11.245c1.27-4.476 2.34-8.98 3.51-13.47 1.11-4.499 2.12-9.01 3.18-13.514 1.08-4.502 2.06-9.018 3.03-13.535 1-4.512 1.88-9.041 2.82-13.562 1.81-9.05 3.47-18.121 4.87-27.227l2.06-13.664 2.13-13.655a22.51 22.51 0 0 0-18.79-25.652c-5.88-.916-11.9.541-16.71 4.053a22.504 22.504 0 0 0-8.98 14.678c-.04.289-.09.664-.12.953Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m2047.22 731.598.06.134c3.87 7.594 7.35 15.386 11 23.092a199.41 199.41 0 0 0 12.38 22.394c4.54 7.255 9.27 14.408 14.29 21.423 2.52 3.502 5.08 6.978 7.75 10.403M2141.22 798.799a248.49 248.49 0 0 0-6.97-25.13c-2.69-8.191-5.65-16.244-8.82-24.196-3.1-7.983-19.01-38.142-22.84-45.754M2120.32 919.403c1.27-4.476 2.34-8.98 3.51-13.47 1.1-4.499 2.12-9.01 3.18-13.514 1.08-4.502 2.06-9.018 3.03-13.535 1-4.512 1.88-9.041 2.82-13.562 1.81-9.05 3.47-18.121 4.87-27.227l2.06-13.664 2.13-13.655c.59-3.835.52-8.56-.7-11.975M2096.53 811.84l-.75 6.701-1.61 13.725c-1.04 9.153-1.82 18.342-2.45 27.55-.29 4.609-.63 9.21-.85 13.827-.25 4.613-.49 9.227-.63 13.855-.16 4.625-.37 9.244-.49 13.874-.05 4.64-.2 9.266-.15 13.919"
                />
                <path
                    fill="#FCB47F"
                    d="M2119.43 923.9c.98 6.9 2.2 13.77 2.93 20.7 1.41 13.568-.17 27.835-10.48 28.208l-.53.658a11.877 11.877 0 0 1-8.88 3.95l-1.58-.323c-2.18 2.857-4.88 4.766-7.87 4.914l-.62.162a74.132 74.132 0 0 0-1 11.228c0 .487-.17.96-.47 1.344-.3.384-.72.656-1.19.774l-.04.01c-.29.08-.59.1-.89.059-.3-.041-.59-.143-.85-.298a2.246 2.246 0 0 1-1.03-1.425 104.567 104.567 0 0 1-1.63-20.137c.04-3 .3-5.99.6-8.978l-.22.711a4.98 4.98 0 0 1-2.08 2.716 4.96 4.96 0 0 1-3.34.728l-1.95-.269c3.06-3.439 3.93-8.271 3.85-12.832-.09-4.56-1-9.084-.97-13.645.05-8.422 2.34-17.751 8.23-23.893"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2089.95 917.688c-5.89 6.142-8.71 16.044-8.76 24.466-.03 4.561.88 9.085.97 13.645.08 4.561-.79 9.393-3.85 12.831"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2085.26 941.496c1.62 5.165 1.68 10.676 1.3 16.067-.38 5.39-1.19 10.755-1.26 16.158a104.56 104.56 0 0 0 1.63 20.137 2.246 2.246 0 0 0 1.03 1.425c.26.155.55.257.85.298.3.041.6.021.89-.058l.04-.01c.48-.118.89-.391 1.19-.775.3-.383.47-.856.47-1.343-.08-6.149 1.67-15.752 2.81-21.07l2.89-13.51"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2106.47 958.879c.3 10.016-5.53 22.735-13.45 23.127M2115.16 959.164c.26 2.629.59 17.692-12.69 18.249"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2111.88 972.807c10.31-.373 11.89-14.64 10.48-28.209-.73-6.929-1.95-13.8-2.93-20.7"
                />
                <path
                    fill="url(#F)"
                    d="m2134.93 772.548-.28-.812c-2.44-7.347-5.23-14.961-8.28-22.631-3.1-7.999-6.7-15.793-10.79-23.335a988.918 988.918 0 0 0-3.69-6.75c-2.81-5.133-5.73-10.44-8.4-15.749a32.031 32.031 0 0 0-7.68-9.843 32.113 32.113 0 0 0-10.87-6.153 31.998 31.998 0 0 0-40.42 20.35 31.999 31.999 0 0 0 1.8 24.425l.07.138c2.7 5.293 5.25 10.778 7.72 16.083 1.08 2.331 2.17 4.659 3.27 6.984a202.998 202.998 0 0 0 12.43 22.504c4.47 7.136 9.03 13.996 13.57 20.386l.5.708 51.05-26.305Z"
                />
            </g>
            <path
                fill="#FCB47F"
                d="M1909.32 1139.38c-.29 4.33 1.37 20.83 2.54 31.54a9.04 9.04 0 0 1-.34 3.62 9.077 9.077 0 0 1-1.76 3.19c-.79.93-1.77 1.69-2.87 2.23s-2.3.86-3.53.92l-20.3 1.01c-4.33.2-8.61 1.07-12.67 2.57a69.735 69.735 0 0 1-20.35 4.09c-4.1.23-19.09-1.2-26.27-1.93-1.05-.1-2.03-.61-2.72-1.41a4.13 4.13 0 0 1-1.01-2.89c.03-.79.28-1.55.73-2.2.45-.65 1.08-1.15 1.8-1.46 3.1-1.3 8.58-3.57 13-5.21 19.87-7.36 41.28-32.44 41.28-32.44"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1909.32 1139.38c-.29 4.33 1.37 20.83 2.54 31.54a9.04 9.04 0 0 1-.34 3.62 9.077 9.077 0 0 1-1.76 3.19c-.79.93-1.77 1.69-2.87 2.23s-2.3.86-3.53.92l-20.3 1.01c-4.33.2-8.61 1.07-12.67 2.57a69.735 69.735 0 0 1-20.35 4.09c-4.1.23-19.09-1.2-26.27-1.93-1.05-.1-2.03-.61-2.72-1.41a4.13 4.13 0 0 1-1.01-2.89c.03-.79.28-1.55.73-2.2.45-.65 1.08-1.15 1.8-1.46 3.1-1.3 8.58-3.57 13-5.21 19.87-7.36 41.28-32.44 41.28-32.44"
            />
            <path
                fill="#FCB47F"
                d="M1933.8 980.264c-2.67-.592-5.35-1.249-8.02-1.87-10.7-2.629-7.02-5.128-17.92-9.31-5.58-2.161-8.19-6.354-12.1-11.444a33.828 33.828 0 0 1 6.19-47.439l.77-.591a148.096 148.096 0 0 1 28.85-16.916c4.89-2.225 9.8-4.247 14.74-6.066 4.88-1.831 9.87-3.37 14.93-4.609 5.02-1.248 10.08-2.16 15.12-3.17 5.06-.942 10.11-1.867 15.21-2.518 2.54-.346 5.08-.745 7.63-.986 2.56-.268 5.1-.59 7.67-.755l3.85-.299 3.85-.186c2.58-.11 5.16-.159 7.76-.086l9.68.26a56.454 56.454 0 0 1 47.84 29.134 56.467 56.467 0 0 1-.79 56.008 44.986 44.986 0 0 1-16.53 15.995 44.968 44.968 0 0 1-22.28 5.723h-102.4l-4.05-.875Z"
            />
            <path
                fill="#FCB47F"
                d="M1907.76 1147.32c2.52-8.44 4.9-16.89 7.13-25.35 1.12-4.23 2.32-8.45 3.37-12.68l3.2-12.7c2.16-8.47 4.18-16.94 6.59-25.39 2.33-8.45 5.02-16.87 7-25.35 4.21-16.93 4.81-34.13 4.83-51.373-.09-8.627-.53-17.279-1.45-25.967-.2-2.17-.53-4.348-.79-6.522-.33-2.18-.62-4.356-1.02-6.54-.74-4.432-1.72-8.82-2.93-13.145l-3.07-10.727a26.397 26.397 0 0 0-10.89-14.81 26.406 26.406 0 0 0-40.9 21.552l-1.01 51.65-.49 25.822-.36 25.84c-.21 17.23-.52 34.45-.65 51.68l-.48 51.69c-.03 3.84 1.32 7.56 3.81 10.48a15.97 15.97 0 0 0 9.75 5.42c3.79.57 7.67-.24 10.91-2.29 3.24-2.06 5.63-5.21 6.74-8.89l.71-2.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M2079.96 959.422c-8.05 13.631-23.88 21.719-39.71 21.719h-102.39l-4.06-.876M1901.95 910.201l.77-.591a148.096 148.096 0 0 1 28.85-16.916c4.89-2.225 9.8-4.247 14.74-6.066 4.89-1.831 9.87-3.37 14.93-4.609 5.02-1.248 10.08-2.16 15.12-3.17 5.06-.942 10.11-1.867 15.21-2.518 2.54-.346 5.08-.745 7.64-.986 2.55-.268 5.1-.59 7.66-.755l3.85-.299 3.86-.186c2.57-.11 5.15-.159 7.75-.086l9.69.26M1907.05 1149.72l.71-2.4c2.52-8.44 4.9-16.89 7.13-25.35 1.12-4.23 2.32-8.45 3.37-12.68l3.2-12.7c2.16-8.46 4.18-16.94 6.59-25.38 2.33-8.46 5.02-16.88 7-25.36 4.21-16.93 5.34-41.15 4.74-59.998M1901.95 910.203c-14.65 5.74-22.89 16.836-23.12 28.116l-1.01 51.651-.5 25.83-.35 25.83c-.21 17.23-.52 34.45-.65 51.68l-.48 51.69M1901.36 930.836h-15.48"
            />
            <path
                fill="#E2E2E2"
                d="M1822.44 1176.98c2.94-1.23 8.54-3.55 13.04-5.22 16.98-6.3 35.48-26.14 39.65-30.78 0 0 21.45.36 35.44.43.21 6.33 1.5 19.05 2.54 28.64.15 1.36.02 2.73-.38 4.03a9.99 9.99 0 0 1-1.95 3.54 9.891 9.891 0 0 1-3.19 2.48c-1.23.61-2.56.95-3.92 1.02l-20.3 1.02c-4.23.19-8.4 1.04-12.38 2.5a70.67 70.67 0 0 1-20.64 4.15c-.31.02-.7.03-1.13.03-5.15 0-18.06-1.23-25.29-1.96a5.136 5.136 0 0 1-3.04-1.4 5.138 5.138 0 0 1-1.54-2.96c-.17-1.13.04-2.29.6-3.29.56-1 1.44-1.78 2.49-2.23Z"
            />
            <path
                fill="url(#G)"
                d="M1822.59 1187.52c.57.25 1.18.41 1.8.47 8.15.83 22.44 2.2 26.58 1.97 7.15-.34 14.21-1.75 20.94-4.18 3.88-1.42 7.95-2.24 12.08-2.42l19.96-.98c3.01-.1 5.86-1.38 7.93-3.58 1.06-1.14 1.86-2.51 2.34-3.99.49-1.48.65-3.05.48-4.6-1.62-15.09-1.94-16.63-1.96-20.97-.01-.88-1.02-3.58-3.6-3.39 0 0-11.64 12.56-25.84 3.56-2.96-2.22-5.81-4.58-8.54-7.1 0 0-1.77-1.1-5.15 3.14-2.42 3.03-15.92 18.89-33.88 25.52-4.42 1.63-9.86 3.87-12.86 5.12-.81.33-1.55.81-2.17 1.43a6.57 6.57 0 0 0-1.43 2.16c-.56 1.48-.53 3.12.09 4.58a6.132 6.132 0 0 0 3.23 3.26Z"
            />
            <path
                fill="#E2E2E2"
                d="M1874.21 1174.42c14.29-8.28 31.66-8.47 40.12-7.66.12 1.13.24 2.27.37 3.45.17 1.55.01 3.12-.48 4.6-.48 1.49-1.28 2.85-2.34 3.99-2.07 2.2-4.92 3.48-7.93 3.59 0 0-43.54 7.05-52.98 7.57-4.14.23-18.43-1.13-26.58-1.97-.82-.08-1.61-.33-2.33-.72-.72-.4-1.35-.94-1.86-1.59 8.06-.55 40.05-3.19 54.01-11.26Z"
            />
            <path
                fill="url(#H)"
                d="M1875.72 1059.89c.16-11.6.45-32.25.6-44.1.32-16.689 1.17-60.165 1.51-77.478.24-12.186 9.45-22.938 20.09-28.873 11.83-6.605 24.39-13.56 33.24-17.64a166.392 166.392 0 0 1 41.6-13.245 291.647 291.647 0 0 1 37.91-5.247c6.51-.489 14.86-.219 21.37-.013 16.93.305 37.99 6.048 49.32 19.981 16.45 20.219 8.53 56.634-4.26 72.043a27.738 27.738 0 0 1-6.18 5.406 46.736 46.736 0 0 1-30.67 11.431h-99.76c.95 21.805.31 44.855-4.47 63.935-3.63 14.72-17.51 80.77-19.24 90.1h-45.47c.21-23 4.09-57.46 4.41-76.3Z"
            />
            <path
                fill="url(#I)"
                d="m1918.98 1125.2-47.36.11c.24-5.29.58-10.89.97-16.57l49.84-.12c-1.3 6.22-2.49 11.93-3.45 16.58Z"
            />
            <path
                fill="url(#J)"
                d="M1936.02 1046.09c-1.85 7.51-6.37 28.37-10.58 48.25l-51.81.1c.98-13.11 1.94-25.59 2.09-34.55.06-4.34.14-9.95.22-15.89l60.6-.13c-.17.74-.34 1.49-.52 2.22Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M907.555 666.276a13.282 13.282 0 0 0 8.777 3.888 13.278 13.278 0 0 0-3.888-8.777 13.277 13.277 0 0 0-8.776-3.887 13.274 13.274 0 0 0 3.887 8.776Z"
            />
            <path
                fill="#EF0025"
                d="M918.943 673.395a8.093 8.093 0 0 1-5.222 0c-7.355-2.522-17.323 1.787-17.323 12.751 0 11.816 8.821 19.3 19.934 19.3 11.113 0 19.933-7.484 19.933-19.3 0-10.964-9.966-15.273-17.322-12.751Z"
            />
            <g clipPath="url(#K)">
                <path
                    fill="#fff"
                    d="M1337.21 809.372v6.741c0 3.381-2.82 6.122-6.3 6.122h-26.82c-3.48 0-6.3-2.741-6.3-6.122v-64.347h39.42v57.606Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1337.21 809.372v6.741c0 3.381-2.82 6.122-6.3 6.122h-26.82c-3.48 0-6.3-2.741-6.3-6.122v-64.347h39.42v49.97"
                />
                <path
                    fill="#C0F0FF"
                    d="M1331.7 766.934v49.177c0 .422-.36.765-.79.765h-26.82a.773.773 0 0 1-.78-.765v-49.177h28.39Z"
                />
            </g>
            <path
                fill="url(#L)"
                d="M1240.79 813.102h-148.88c-2.17 0-3.62-2.243-2.73-4.222 12.72-28.325 28.87-33.82 52.43-31.87 52.97-3.615 70.09 27.398 99.18 27.398v8.694Z"
            />
            <mask
                id="M"
                width={153}
                height={38}
                x={1088}
                y={776}
                maskUnits="userSpaceOnUse"
                style={{
                    maskType: 'luminance',
                }}
            >
                <path
                    fill="#fff"
                    d="M1240.79 813.106h-148.87c-2.17 0-3.63-2.243-2.74-4.222 12.72-28.325 28.88-33.82 52.43-31.87 52.97-3.615 70.09 27.398 99.18 27.398v8.694Z"
                />
            </mask>
            <g
                stroke="#fff"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeOpacity={0.25}
                mask="url(#M)"
            >
                <path strokeWidth={3} d="M1141.61 762.395v65.328" />
                <path
                    strokeWidth={2}
                    d="M1119.6 771.824a83.515 83.515 0 0 1 9.59 21.22c4.6 15.756 3.5 29.409 2.2 37.404M1108.55 778.793c3.18 3.759 6.82 8.948 9.64 15.668 6.98 16.647 4.5 32.035 2.98 38.716M1092.02 786.988c3.57 1.332 11.13 4.752 16.58 12.656 10.95 15.898 2.97 34.872 2.36 36.256"
                />
                <path
                    strokeWidth={2}
                    d="M1075.49 795.191c1.5-.098 14.99-.749 23.68 9.644 7.58 9.069 8.33 22.758 1.57 33.795M1164.56 771.199a80.214 80.214 0 0 0-9.59 22.95c-3.64 14.859-2.19 27.474-.76 34.791M1184.36 779.055c-3.54 1.813-11.38 6.466-16.5 15.876-8.87 16.288-2.03 32.948-1.05 35.221M1200.24 783.613c-3.06 1.25-12.74 5.652-18.71 16.505-7.99 14.532-2.94 29.007-2.12 31.249M1215.64 789.277c-2.66.676-12.98 3.626-19.8 13.833-8.85 13.238-4.43 27.639-3.83 29.478M1234.82 796.191c-1.62-.124-14.57-.894-23.89 9.274-10.92 11.899-6.55 27.539-6.32 28.336"
                />
                <path
                    strokeWidth={2}
                    d="M1249.44 792.887a398.91 398.91 0 0 0-19.33 15.089 400.706 400.706 0 0 0-22.95 20.592"
                />
            </g>
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1126.25 777.059c4.75-.553 9.85-.504 15.36-.049 52.97-3.615 70.09 27.398 99.18 27.398v8.695h-148.88c-2.17 0-3.62-2.243-2.73-4.222 5.93-13.211 12.61-21.455 20.44-26.295"
            />
            <path
                fill="url(#N)"
                d="M1199.89 813.09h-125.67a30.139 30.139 0 0 0 21.58 9.093h158.47c8.12 0 15.9-3.278 21.57-9.093h-75.95Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1243.91 822.183h10.36c8.12 0 15.9-3.278 21.57-9.093h-201.62a30.139 30.139 0 0 0 21.58 9.093h131.01"
            />
            <path
                fill="url(#O)"
                d="M1182.8 797.937c12.56 7.136 16.51 11.018 28.74 7.649 12.23-3.37 48.78-20.411 46.58-28.403-2.21-7.992-42.32-3.908-54.55-.539-12.23 3.37-14.58 10.396-20.77 21.293Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1182.8 797.937c12.56 7.136 16.51 11.018 28.74 7.649 12.23-3.37 48.78-20.411 46.58-28.403-2.21-7.992-42.32-3.908-54.55-.539-12.23 3.37-14.58 10.396-20.77 21.293h0Z"
            />
            <path
                fill="url(#P)"
                d="M1190.39 796.102c11.41 11.412 14.52 16.765 28.68 16.765 14.17 0 58.58-7.506 58.58-16.765 0-9.26-44.41-16.766-58.58-16.766-14.16 0-18.78 6.868-28.68 16.766Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1263.49 786.929c-14.27-4.573-35.43-7.593-44.42-7.593-14.16 0-18.78 6.868-28.68 16.766 11.41 11.412 14.52 16.765 28.68 16.765 14.17 0 58.58-7.507 58.58-16.766 0-2.232-2.58-4.361-6.69-6.309"
            />
            <path
                stroke="#00B140"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={3}
                d="M1210.73 796.102H1259M1239.14 796.102l7.31-7.305M1223.45 796.098l8.72-8.719M1249.91 796.102l5.83 5.824M1231.95 796.102l5.82 5.824"
            />
            <path
                fill="url(#Q)"
                d="M1182.38 793.773c40.8 0 60.46 8.785 77.73 19.089h-50.09c-20.77 0-27.64-8.785-27.64-19.089Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1208.31 795.184c24.15 2.883 38.67 9.841 51.8 17.678h-50.09c-20.77 0-27.64-8.785-27.64-19.089 5.82 0 11.21.18 16.23.516"
            />
            <g
                stroke="#E4002B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={4}
                clipPath="url(#R)"
            >
                <path 
                    d="m1120.96 782.596-5.74-5.733M1110.5 782.596l-5.73-5.733M1100.04 782.596l-5.73-5.733M1089.58 782.596l-5.73-5.733M1079.12 782.596l-5.73-5.733M1068.66 782.596l-5.73-5.733M1058.2 782.596l-5.73-5.733M1047.74 782.596l-5.73-5.733M1110.5 774.069l-5.73-5.733M1100.04 774.069l-5.73-5.733M1089.58 774.069l-5.73-5.733M1079.12 774.069l-5.73-5.733M1068.66 774.069l-5.73-5.733M1058.2 774.069l-5.73-5.733M1100.04 765.545l-5.73-5.733M1089.58 765.545l-5.73-5.733M1079.12 765.545l-5.73-5.733M1068.66 765.545l-5.73-5.733M1089.58 757.018l-5.73-5.733M1079.12 757.018l-5.73-5.733" />
            </g>
            <path
                fill="#fff"
                d="M1034.89 781.52a62.77 62.77 0 0 0 2.41 8.182 60.432 60.432 0 0 0 3.83 8.384 61.62 61.62 0 0 0 1.91 3.199 54.937 54.937 0 0 0 3.07 4.338 55.468 55.468 0 0 0 6.76 7.202c5.16 4.589 10.39 7.5 14.61 9.365h28.01c4.22-1.865 9.45-4.776 14.6-9.365a54.126 54.126 0 0 0 6.76-7.202 57.87 57.87 0 0 0 3.08-4.338 55.035 55.035 0 0 0 1.9-3.199 59.282 59.282 0 0 0 3.83-8.384 59.756 59.756 0 0 0 2.41-8.182h-93.18Z"
            />
            <path
                fill="url(#S)"
                d="M1034.89 781.52a62.77 62.77 0 0 0 2.41 8.182 60.432 60.432 0 0 0 3.83 8.384 61.62 61.62 0 0 0 1.91 3.199 54.937 54.937 0 0 0 3.07 4.338 55.468 55.468 0 0 0 6.76 7.202c5.16 4.589 10.39 7.5 14.61 9.365h11.5c4.22-1.865 9.45-4.776 14.61-9.365a55.468 55.468 0 0 0 6.76-7.202 57.682 57.682 0 0 0 3.07-4.338 55.035 55.035 0 0 0 1.9-3.199 60.568 60.568 0 0 0 3.84-8.384 62.77 62.77 0 0 0 2.41-8.182h-76.68Z"
            />
            <path
                fill="#EF0E2E"
                d="M1037.3 789.703a60.44 60.44 0 0 0 3.83 8.385h80.7a59.29 59.29 0 0 0 3.83-8.385h-88.36Z"
            />
            <path
                fill="#64CCC9"
                d="M1043.04 801.285a54.766 54.766 0 0 0 3.07 4.339h70.74a57.68 57.68 0 0 0 3.08-4.339h-76.89Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1052.87 812.832c-12.58-11.191-16.61-25.157-17.98-31.305h93.18c-1.36 6.148-5.4 20.114-17.98 31.305-5.15 4.588-10.38 7.499-14.6 9.363h-28.01a56.36 56.36 0 0 1-8.29-4.511"
            />
            <path
                fill="#3A383A"
                d="M1756.85 1192.46H1777l-42.07-341.382h-39.79l61.71 341.382Z"
            />
            <path
                fill="url(#T)"
                d="M1535.26 1192.45h-20.15l42.06-341.376h39.79l-61.7 341.376Z"
            />
            <path
                fill="#3A383A"
                d="M1251.16 1192.45h20.15l-42.06-341.376h-39.79l61.7 341.376Z"
            />
            <path
                fill="url(#U)"
                d="M1029.57 1192.46h-20.15l42.07-341.382h39.78l-61.7 341.382Z"
            />
            <path
                fill="#AEAEAF"
                d="M1229.25 851.074h-39.79l16.89 93.484h34.42l-11.52-93.484Z"
            />
            <path
                fill="#E2E2E2"
                d="M1039.97 944.562h34.41l16.9-93.484h-39.79l-11.52 93.484Z"
            />
            <path
                fill="#AEAEAF"
                d="M1734.93 851.078h-39.79l16.9 93.484h34.41l-11.52-93.484Z"
            />
            <path
                fill="#E2E2E2"
                d="M1545.66 944.562h34.41l16.89-93.484h-39.78l-11.52 93.484Z"
            />
            <path fill="url(#V)" d="M1818.39 822.539H968.031v28.537h850.359v-28.537Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1818.39 822.539H968.031v28.537h850.359v-28.537Z"
            />
            <path fill="url(#W)" d="M1504.56 851.078h-204.63v57.074h204.63v-57.074Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1504.56 851.078h-204.63v57.074h204.63v-57.074ZM1441.16 879.613h31.33"
            />
            <path
                fill="#888B8D"
                d="M1514.53 786.806c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2l-23.9 10.1c-1 .4-1.9.6-2.8.6ZM1389.73 786.806c-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c3.6-1.5 7.7.1 9.2 3.7 1.5 3.6-.1 7.7-3.7 9.2l-23.9 10.1c-1 .4-1.9.6-2.8.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1589.82 1044.81c3.98 0 7.2-4.3 7.2-9.6 0-5.31-3.22-9.6-7.2-9.6-3.97 0-7.2 4.29-7.2 9.6 0 5.3 3.23 9.6 7.2 9.6Z"
            />
            <path
                fill="#A9ACAC"
                d="M1579.63 1079.31c18.17 0 32.9-19.75 32.9-44.1 0-24.36-14.73-44.105-32.9-44.105-18.17 0-32.9 19.745-32.9 44.105 0 24.35 14.73 44.1 32.9 44.1Z"
            />
            <path
                fill="url(#X)"
                d="M1483.92 1035.2c0-72.095 41.4-131.195 94.1-136.895-2.8-.3-5.59-.5-8.4-.5-56.59 0-102.5 61.5-102.5 137.395 0 75.9 45.91 137.4 102.5 137.4 2.81 0 5.6-.2 8.4-.5-52.6-5.7-94.1-64.8-94.1-136.9Z"
            />
            <path
                fill="url(#Y)"
                d="M1582.03 878.805c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.395 0 86.4 52.3 156.4 116.7 156.4 2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.395-52.2-156.395-116.7-156.395Zm-12.4 293.795c-56.6 0-102.5-61.5-102.5-137.4 0-75.895 45.9-137.395 102.5-137.395 2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.895 0 72.1-41.4 131.2-94.1 136.9-2.7.3-5.5.5-8.4.5Z"
            />
            <path
                fill="#FFB33B"
                d="m1532.73 948.703-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.3-2.3.8-3.6ZM1636.42 1084.1c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.3.1-2.8-1-3.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1598.83 1002.7c6.3 8.3 10.2 19.8 10.2 32.5 0 23.9-13.8 43.6-31.2 45.5-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6M1548.03 1007.41c6.2-10.805 16-17.805 27-17.805"
            />
            <path
                fill="url(#Z)"
                d="M1663.22 870.403h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.5 0 9.8 4.4 9.8 9.7Z"
            />
            <path
                fill="#D9D9D6"
                d="M1687.82 961.804h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2ZM1445.03 1126.4c-3 0-5.5-2.5-5.5-5.5V845.703c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 1.2-.4 2.5-.8 3.7-1 4.3-.7 8.7-.1 12.7 1.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2V1121c.1 2.9-2.4 5.4-5.4 5.4Z"
            />
            <path
                fill="#D9D9D6"
                d="M1569.73 1126.4c-3 0-5.5-2.5-5.5-5.5V845.703c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3-1-2.9.5-6 3.3-7 5.4-1.9 11.3-1.6 16.4.9 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2V1121c.1 2.9-2.3 5.4-5.4 5.4ZM1439.53 1120.91c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V896.605h-11v224.305ZM1444.73 795.504h-11.3c4 16.4 6.1 33.3 6.1 50.2v50.9h11v-50.9c0-16.9-2-33.7-5.8-50.2Z"
            />
            <path
                fill="#D9D9D6"
                d="M1564.23 1120.91c0 3 2.5 5.5 5.5 5.5s5.5-2.5 5.5-5.5V896.605h-11v224.305Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1676.82 917.405v-23.7c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v62"
            />
            <path
                fill="url(#aa)"
                d="M1702.73 961.605h-249.4c-3.5 0-6.4-2.9-6.4-6.4 0-3.5 2.9-6.4 6.4-6.4h249.4c3.5 0 6.4 2.9 6.4 6.4 0 3.6-2.9 6.4-6.4 6.4Z"
            />
            <path
                fill="url(#ab)"
                d="M1682.03 1159.6c0-11.2 6.7-20.3 15-20.6l-7.1-9.8c-9.5 4.2-16.4 16.3-16.4 30.4 0 15.6 8.3 28.6 19.3 31.5l3.6-10.9c-8.1-.8-14.4-9.7-14.4-20.6Z"
            />
            <path
                fill="url(#ac)"
                d="M1696.93 1173.7c5.79 0 10.5-6.31 10.5-14.1 0-7.78-4.71-14.1-10.5-14.1-5.8 0-10.5 6.32-10.5 14.1 0 7.79 4.7 14.1 10.5 14.1Z"
            />
            <path
                fill="url(#ad)"
                d="M1699.93 1127.5h-5.5c-13.2 0-23.9 14.4-23.9 32.1 0 17.7 10.7 32.1 23.9 32.1h5.4c13.2 0 23.9-14.4 23.9-32.1.1-17.7-10.6-32.1-23.8-32.1Zm-20.7 32.1c0-11.4 6.9-20.6 15.4-20.6.4 0 .8 0 1.3.1 7.9.9 14.1 9.7 14.1 20.6 0 10.8-6.2 19.7-14.1 20.6-.4 0-.8.1-1.3.1-8.5-.2-15.4-9.4-15.4-20.8Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1695.53 1145h.9c5.6.6 10 6.9 10 14.5s-4.4 13.9-10 14.5h-.9c-6 0-10.9-6.5-10.9-14.6"
            />
            <path
                fill="#D9D9D6"
                d="M1694.53 1126.4h-126.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h120.8V972.305h-244c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h249.5c3 0 5.5 2.5 5.5 5.5V1120.9c0 3-2.5 5.5-5.5 5.5Z"
            />
            <path
                fill="#D9D9D6"
                d="M1565.73 1040.7h-119.5c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h119.5c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM1569.73 1126.4h-156.8c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h156.8c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM1718.62 1062.3c-3 0-5.5 2.5-5.5 5.5v4.1h-17.1c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.2v13.8h-17.2c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5h17.2v18.4l-23.1 31 7.3 5.8 24.7-31.6c1.3-2.3 2-4.9 2-7.6V1068c0-3.3-2.39-5.7-5.5-5.7Z"
            />
            <path
                fill="#D9D9D6"
                d="m1597.53 1129.8-24.7 33.8-9.5-8 24.8-34.5v-52.8c0-3.4 2.7-6.1 6.1-6.1 3.4 0 6.1 2.7 6.1 6.1v52.9c-.1 3.1-1 6.1-2.8 8.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1693.82 1151.9c3.1 0 5.7 3.4 5.7 7.6 0 4.2-2.6 7.6-5.7 7.6s-5.7-3.4-5.7-7.6"
            />
            <path
                fill="#D9D9D6"
                d="M1591.83 1082.8h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM1591.83 1107.61h-19.3c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h19.3c3 0 5.5 2.5 5.5 5.5s-2.4 5.5-5.5 5.5ZM1643.13 1164.51c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6ZM1699.73 1164.51c-1.9 0-3.8-1-4.8-2.8l-20.3-36.8c-1.5-2.7-.5-6 2.2-7.5s6-.5 7.5 2.2l20.3 36.8c1.5 2.7.5 6-2.2 7.5-.9.4-1.8.6-2.7.6Z"
            />
            <path
                fill="url(#ae)"
                d="M1750.32 1166h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.21-5 5-5h74.2c2.7 0 5 2.2 5 5-.1 2.7-2.3 5-5 5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1439.53 859.103v-13.4c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M1450.53 1040.7v74.6M1450.53 972.305v41.595"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1416.03 763.202c4.4-.8 8.9-.2 13 1.8 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v91.9M1564.23 845.703c0-22-3.4-43.9-10.2-64.8-.8-2.6-2.7-4.7-5.2-6-2.5-1.2-5.3-1.4-7.9-.4-2.9 1-6-.5-7-3.3M1564.23 1029.6v-37.397M1564.23 1115.31v-74.2M1575.23 1107.61v7.8M1575.23 1082.8v13.8M1575.23 972.805v98.995"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1541.43 763.103c4.2-.6 8.4.1 12.3 2 5.2 2.5 9 7 10.8 12.5 7.1 22.1 10.7 45 10.7 68.2v97.2M1450.53 961.305h102.3M1450.53 1029.7h113.8M1564.23 1040.7h-113.8M1450.53 1115.4h101.8M1584.03 1126.4h-171.1c-3 0-5.5-2.5-5.5-5.5s2.5-5.5 5.5-5.5h18.7M1599.23 1126.4h19.6m-168.3-154.095h238.5V1115.4h-82.4m93.4-43.6V966.805c0-3-2.5-5.5-5.5-5.5h-130.8m144 188.295 14.4-18.6c1.3-2.3 2-4.9 2-7.6v-55.7c0-3-2.5-5.5-5.5-5.5s-5.5 2.5-5.5 5.5v4.1m-25 54.6h6.4c3 0 5.5-2.5 5.5-5.5v-13.3h13.2v18.4l-11.2 15m-70.5-14.6h43.9m24.6-29.8v-13.8h13.2v13.8"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1575.23 1107.6h12.9v13.5l-24.8 34.5 9.5 8 24.7-33.8c1.8-2.5 2.8-5.4 2.8-8.5v-52.9c0-3.4-2.7-6.1-6.1-6.1-3.4 0-6.1 2.7-6.1 6.1v3.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1575.23 1082.8h12.9v13.8M1643.13 1164.5c-1.9 0-3.8-1-4.8-2.8l-19.5-35.4M1631.43 1126.5l9.9 18M1694.93 1161.7l-19.5-35.4M1688.12 1126.4l16.4 30M1517.23 786.202c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.7-.7 3.5-.7 5.1-.1"
            />
            <path
                fill="url(#af)"
                d="M1438.53 897.604v-51.9c0-16.8-2-33.6-6-50l-.3-1.2h138.1l.2.8c3.8 16.5 5.8 33.5 5.8 50.5v51.9h-137.8v-.1Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1392.43 786.204c-.9.4-1.8.6-2.7.6-2.7 0-5.3-1.6-6.4-4.3-1.5-3.6.1-7.7 3.7-9.2l23.9-10.1c1.8-.8 3.7-.7 5.4 0"
            />
            <path
                fill="#716E72"
                d="M1707.13 1166h-74.2c-2.7 0-5-2.2-5-5 0-2.7 2.2-5 5-5h74.2c2.7 0 5 2.2 5 5 0 2.7-2.2 5-5 5Z"
            />
            <path
                fill="url(#ag)"
                d="M1568.22 1191.7c17.73 0 32.1-14.37 32.1-32.1 0-17.72-14.37-32.1-32.1-32.1-17.72 0-32.1 14.38-32.1 32.1 0 17.73 14.38 32.1 32.1 32.1Z"
            />
            <path
                fill="#fff"
                d="M1568.22 1180c11.27 0 20.4-9.13 20.4-20.4 0-11.26-9.13-20.4-20.4-20.4-11.26 0-20.4 9.14-20.4 20.4 0 11.27 9.14 20.4 20.4 20.4Z"
            />
            <path
                fill="url(#ah)"
                d="M1568.23 1174.11c8 0 14.5-6.5 14.5-14.5 0-8.01-6.5-14.5-14.5-14.5-8.01 0-14.5 6.49-14.5 14.5 0 8 6.49 14.5 14.5 14.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1559.83 1171.41c2.4 1.7 5.3 2.7 8.4 2.7 8 0 14.5-6.5 14.5-14.5s-6.5-14.5-14.5-14.5-14.5 6.5-14.5 14.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1568.22 1167.2c4.2 0 7.6-3.4 7.6-7.6 0-4.19-3.4-7.6-7.6-7.6-4.19 0-7.6 3.41-7.6 7.6 0 4.2 3.41 7.6 7.6 7.6Z"
            />
            <path
                fill="#FCB47F"
                d="M1599 664.854c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 6.1 6.7 12 13.499 17.5 20.899 7.6 10.3 5.5 24.8-4.8 32.5-7.1 5.3-16.2 5.901-23.6 2.401-8.3-3.9-16.2-8.301-24-12.901-7.7-4.6-15.3-9.5-22.7-14.5-7.4-5-14.6-10.399-21.3-16.199-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5h.1Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1593.1 740.552c-4.4-3.3-8.8-6.7-12.9-10.3-6.8-5.8-13.4-11.8-20.4-17.4-13.3-10.8-15.3-30.3-4.5-43.6s30.3-15.3 43.6-4.5l.1.1c6.9 5.7 14.1 11 21.1 16.4 7.1 5.4 13.8 11.3 20.2 17.6 6.4 6.2 12.7 12.7 18.8 19.3 4.6 5 9.1 10.1 13.4 15.5"
            />
            <path
                fill="url(#ai)"
                d="m1561.4 644.051 2.7 1c13.1 4.7 23.8 10.3 32.8 17.3.8.6 5.9 5.4 5.8 6.4-.2 4.6-16.9 8.2-19.3 9.6l-.9.5-21.1-34.8Z"
            />
            <path
                fill="url(#aj)"
                d="M1614.4 675.553c-9.3-6.4-17.3-16.1-28.9-18.2-20.4-4.5-40.6 14-37.9 34.7.9 15.8 15.3 24.1 25.9 33.6 6.2 5.4 12.5 10.8 19.1 15.7 1.5 1.1 56.8-35.3 56.8-35.3-10.8-12-28.6-25.8-35-30.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1469.32 860.584c-.6-9.551-9-41.648-16.1-67.345-6.6-23.925-10.3-48.54-10.9-73.351l-.5-21.858c-.4-15.359 5.9-29.636 18.1-39.284 16.3-12.898 31.8-20.184 49.9-27.766 32.2 15.36 42.1 16.148 56 13.588 2.1 1.378 4.3 2.756 6.4 4.332 26 18.707 46 58.779 52.2 80.932 3.2 11.52 2.8 23.433-8.2 29.34 14 27.569 25 48.245 14 101.412"
            />
            <path
                fill="url(#ak)"
                d="M1469.32 860.584c-.6-9.551-9-41.648-16.1-67.345-6.6-23.925-10.3-48.54-10.9-73.351l-.5-21.858c-.4-15.359 5.9-29.636 18.1-39.284 16.3-12.898 31.8-20.184 49.9-27.766 32.2 15.36 42.1 16.148 56 13.588 2.1 1.378 4.3 2.756 6.4 4.332 26 18.707 46 58.779 52.2 80.932 3.2 11.52 2.8 23.433-8.2 29.34 14 27.569 25 48.245 14 101.412"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1615.82 707.086c3.9 8.467 6.8 16.344 8.6 22.744 3.2 11.519 2.8 23.433-8.2 29.34 14 27.568 25 48.244 14 101.411M1469.32 860.583c-.6-9.55-9-41.647-16.1-67.345-6.6-23.925-10.3-48.539-10.9-73.351l-.5-19.1c-.8-23.63 9.7-36.331 21.7-44.897 12.4-8.861 23-15.261 41.3-22.941"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1616.42 759.37c-3.6-6.105-7.7-12.701-11.1-16.64M1507.22 760.552c31.1 3.742 56.5-1.181 71.7-21.759M1607.32 836.853c3.6-1.28 7.1-1.379 10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M1468.43 861.961c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.563 83.7 14.868 103.7 15.261 31.5.69 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503"
            />
            <path
                fill="url(#al)"
                d="M1468.43 861.961c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.563 83.7 14.868 103.7 15.261 31.5.69 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1468.43 861.961c-12.6 21.365-8.8 54.447 4.2 69.806 18.1 21.563 83.7 14.868 103.7 15.261 31.5.69 19.9-40.564 19.9-40.564s24.8-11.52 33.5-44.503M1647.52 898.488h15.5"
            />
            <path
                fill="#FCB47F"
                d="M1688.23 947.807s12.1-7.778 22.8-11.717l3.2-1.181c6.7-2.462 12.5-7.089 16.4-13.686 8.9-15.064 3.7-34.46-11.6-43.223-9.5-5.415-19.1-9.846-28.7-13.981-4.8-2.067-9.7-4.037-14.5-5.907-4.8-1.969-9.7-3.84-14.6-5.514-9.8-3.249-19.7-5.415-29.7-7.187-2.5-.394-5-.886-7.5-1.182-2.5-.295-5-.689-7.5-.886l-3.8-.394-3.8-.295c-2.5-.197-5.1-.197-7.6-.197-22.1.492-42.4 14.67-49.4 36.528-8.8 27.47 6.8 56.81 34.7 65.474l.5.197c4.6 1.379 9.2 2.462 13.9 3.249h61.2c.5 0 25.6 0 26-.098Z"
            />
            <path
                fill="#FCB47F"
                d="M1680.02 1103.08c-1.1-8.37-2.09-16.74-2.89-25.01-.4-4.14-.9-8.37-1.2-12.51l-1.1-12.5c-.7-8.37-1.4-16.64-2.4-25.01-.9-8.37-2.2-16.74-2.8-25.01-1.4-16.635.79-32.979 3.59-49.225 1.5-8.073 3.31-16.245 5.71-24.22.6-1.97 1.19-4.037 1.79-6.006.7-1.969 1.31-4.037 2.11-6.006 1.5-4.037 3.1-7.975 5-11.914l5-10.141c6.4-12.898 22.19-18.214 35.19-11.913 10.6 5.12 16.1 16.245 14.5 27.174l-7.39 48.835-3.7 24.418-3.91 24.418c-2.6 16.24-5.1 32.49-7.8 48.83l-8 48.74c-1.4 8.57-9.59 14.38-18.29 13-7.1-1.09-12.4-6.7-13.3-13.49l-.11-2.46Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1728.23 883.711c-12.4-7.877-28.3-15.557-37.9-19.692-4.8-2.067-9.7-4.037-14.5-5.907-4.8-1.969-9.7-3.84-14.6-5.514-9.8-3.249-19.7-5.415-29.7-7.187-2.5-.394-5-.886-7.5-1.182-2.5-.295-5-.689-7.5-.886l-3.8-.394-3.8-.295c-2.5-.197-5.1-.197-7.6-.197-22.1.492-42.4 14.67-49.4 36.528-8.8 27.47 6.8 56.81 34.7 65.474l.5.197c4.6 1.379 9.2 2.462 13.9 3.249h61.2c.2 0 5.8-.098 11.8-.098M1680.43 1105.64l-.3-2.56c-1.1-8.37-2.1-16.74-2.9-25.01-.4-4.14-.9-8.37-1.2-12.51l-1.1-12.5c-.7-8.37-1.4-16.64-2.4-25.01-.9-8.37-2.2-16.74-2.8-25.01-1.4-16.637.8-32.981 3.6-49.226.1-.69.3-1.379.4-2.068M1728.23 883.711c10.6 5.12 16.1 16.245 14.5 27.174l-7.4 48.835-3.7 24.418-3.9 24.422c-2.6 16.24-5.1 32.49-7.8 48.83l-8 48.74"
            />
            <path
                fill="#FCB47F"
                d="M1679.73 1103.87c.3 4.23-1.4 20.48-2.5 31.02-.6 5.02 3.3 9.55 8.5 9.84l20.3.99c4.3.19 8.6.98 12.7 2.56 4.3 1.57 11.2 3.54 20.3 4.03 4.1.2 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.23-.1-1.58-1.1-2.96-2.5-3.65-3.1-1.27-8.6-3.54-13-5.11-19.9-7.29-41.3-31.91-41.3-31.91"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1679.73 1103.87c.3 4.23-1.4 20.48-2.5 31.02-.6 5.02 3.3 9.55 8.5 9.84l20.3.99c4.3.19 8.6.98 12.7 2.56 4.3 1.57 11.2 3.54 20.3 4.03 4.1.2 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.23-.1-1.58-1.1-2.96-2.5-3.65-3.1-1.27-8.6-3.54-13-5.11-19.9-7.29-41.3-31.91-41.3-31.91M1718.02 898.488h15.5"
            />
            <path
                fill="#EF0025"
                d="M1766.82 1141.58c-2.9-1.18-8.5-3.54-13-5.12-7.8-2.86-15.8-8.37-22.7-14.08-2.4-1.97-4.6-3.94-6.7-5.81-3.4-.1-13.9 5.71-19.7 6.11-11.5.69-20.1-3.65-27-3.15-.4 4.33-.9 9.45-1.6 15.35-.3 2.76.5 5.42 2.3 7.49 1.8 2.07 4.3 3.35 7.1 3.44l20.3.99c4.3.19 8.5 1.08 12.4 2.46 4.3 1.57 11.3 3.54 20.6 4.04h1.1c5.1 0 18.1-1.19 25.3-1.97 2.7-.3 4.7-2.56 4.6-5.22.1-1.97-1.2-3.74-3-4.53Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1678.52 1142.17c1.8 2.07 4.3 3.35 7.1 3.45l20.3.98c4.3.2 8.5 1.09 12.4 2.46 4.3 1.58 11.3 3.55 20.6 4.04h1.1c5.1 0 18.1-1.18 25.3-1.97"
            />
            <path
                fill="#001489"
                d="M1614.22 840.498c-27.7-3.545-53.1 10.239-61.9 36.823-9 28.159 7.2 58.287 35.9 66.853 4.3 1.378 9 2.461 14.1 3.347h40.1s4.3-17.427 12.4-46.964c8.7-31.605 31.6-38.989 31.6-38.989-9.1-5.218-49.8-19.002-72.2-21.07Z"
            />
            <path
                fill="#FCB47F"
                d="M1580.83 546.902c4.4 10.043 7.6 23.827 7.6 34.756 0 10.929-1.9 21.857-4.7 32.392-1.6 6.006-2.7 12.701-6.2 17.723-2.5 3.643-7.8 5.12-12.3 4.726-.7-.099-1.3-.099-2-.197.4 1.28.8 11.421.8 12.209l-8.6 6.104-46.7-17.722 2.6-11.422.4-12.799s-1.1-8.369-9.1-23.63c-10.1-19.199 3.1-37.217 3.1-37.217s2.8-10.929 18.5-17.722c22.4-9.452 40.9-2.659 56.6 12.799Z"
            />
            <path
                fill="url(#am)"
                d="M1587.73 570.822c-2-.196-4-.393-6-.689-2-.197-4.1-.394-5.7.788-1.2.886-1.8 2.363-1.9 3.741-.1 1.477-.4 2.954-.1 4.332 2.2 12.701 1.5 21.563-.9 24.123-.5.59-1 1.279-1.1 1.969-.1.787.1 1.477.2 2.264.9 4.135 1.8 8.369 2.7 12.504.5 2.265.8 4.628 1.1 6.892.4 3.84-.6 7.188-4.6 9.255 6.6-1.28 13.7-7.581 15.2-10.338 9.5-17.82-.9-36.626 1.1-54.841Z"
            />
            <path
                fill="url(#an)"
                d="M1564.03 646.928c-.2-3.643-.4-7.285-.5-10.928-14.5 1.181-30.2-11.422-36.6-18.117-.9-.984 2.2 13.587 5.3 23.729 7.3 3.249 22.8 8.565 31.8 5.316Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1526.93 617.1c9.3 11.322 23.7 18.018 38.4 19.396 5.6.591 13.7-1.871 18.2-6.498 7.2-7.582 9.7-22.35 6.1-36.43-1.2-4.824-2.2-7.384-2.1-11.125 0-2.856.8-6.597.4-9.649-1.1-8.861-1.3-18.412-8.9-26.978-25.7-29.045-60.1-12.405-69-1.476-8.2 10.042-12.1 23.432-9.7 36.035 1.6 8.566 5.8 16.442 9.5 24.319 3.9 8.467 1.8 20.775-.7 29.537"
            />
            <path
                fill="#FCB47F"
                d="M1574.53 575.945c.2 5.809 2.5 11.421 5.7 16.246 1 1.477 2.1 2.954 2.2 4.726.1 1.772-1 3.643-2.6 4.529-2.5 1.28-5 1.871-6.9 2.166"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1574.53 575.945c.2 5.809 2.5 11.421 5.7 16.246 1 1.477 2.1 2.954 2.2 4.726.1 1.772-1 3.643-2.6 4.529-2.5 1.28-5 1.871-6.9 2.166"
            />
            <path
                fill="url(#ao)"
                d="M1545.29 621.476c2.1-2.9 4.5-11.5 6.8-14.3 4.4-5.5 9.7-3.1 21.4-2.6 21.6.9 14.3 19.4 9.7 26.4-3 4.5-8.2 4.9-11.8 6.1-6.8 2.4-17.4-1.3-23.9-4.4-4.2-2-10.1-5.7-13.7-9.4 3.4 1 8.7 2.1 11.5-1.8Z"
            />
            <path
                stroke="url(#ap)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1588.92 572.497c-2.5-1.181-6.2-1.772-8.8-2.067"
            />
            <path
                stroke="url(#aq)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1566.62 570.531c-6.4 0-13.1.689-17.3 2.462"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1583.52 587.467c.78 0 1.4-1.014 1.4-2.265 0-1.251-.62-2.264-1.4-2.264-.77 0-1.4 1.013-1.4 2.264s.63 2.265 1.4 2.265ZM1557.92 587.467c.78 0 1.4-1.014 1.4-2.265 0-1.251-.62-2.264-1.4-2.264-.77 0-1.4 1.013-1.4 2.264s.63 2.265 1.4 2.265Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1578.92 611.191c-7.6 1.181-15.4.492-22.7-1.871M1525.72 584.611c-1.9-1.575-3.8-3.249-6.2-4.135-2.3-.886-5.2-.886-7.1.689-2.1 1.674-2.6 4.726-2.5 7.483.5 8.27 5.9 16.147 13.5 19.593"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1595.42 791.069c-5-7.286-9.2-14.769-13.3-22.251-4.1-7.582-7.8-15.261-11.4-22.941-3.6-7.68-6.8-15.556-9.3-23.63-2.6-8.073-5-16.147-7.9-24.122"
            />
            <path
                fill="#FCB47F"
                d="M1639.62 1103.87c.3 4.23-1.4 20.48-2.5 31.02-.6 5.02 3.3 9.55 8.5 9.84l20.3.99c4.3.19 8.6.98 12.7 2.56 4.3 1.57 11.2 3.54 20.3 4.03 4.1.2 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.23-.1-1.58-1.1-2.96-2.5-3.65-3.1-1.27-8.6-3.54-13-5.11-19.9-7.29-41.3-31.91-41.3-31.91"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1639.62 1103.87c.3 4.23-1.4 20.48-2.5 31.02-.6 5.02 3.3 9.55 8.5 9.84l20.3.99c4.3.19 8.6.98 12.7 2.56 4.3 1.57 11.2 3.54 20.3 4.03 4.1.2 19.1-1.18 26.3-1.87 2.2-.2 3.8-2.07 3.7-4.23-.1-1.58-1.1-2.96-2.5-3.65-3.1-1.27-8.6-3.54-13-5.11-19.9-7.29-41.3-31.91-41.3-31.91"
            />
            <path
                fill="url(#ar)"
                d="M1726.73 1141.58c-2.9-1.18-8.5-3.54-13-5.12-7.9-2.85-16.2-8.66-23.2-14.57-2.9-2.46-5.6-4.92-8-7.09-4.7 1.38-11.7 9.75-18.4 9.85-13.1.1-22.3-5.71-26.4-6.01-.5 5.32-1.1 11.22-1.7 16.15-.3 2.75.5 5.41 2.3 7.48 1.8 2.07 4.3 3.35 7.1 3.45l20.3.98c4.3.2 8.5 1.08 12.4 2.46 4.3 1.58 11.3 3.55 20.6 4.04h1.1c5.1 0 18.1-1.18 25.3-1.97 2.7-.3 4.7-2.56 4.6-5.22.1-1.87-1.2-3.64-3-4.43Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1638.43 1142.17c1.8 2.07 4.3 3.35 7.1 3.44l20.3.99c4.3.2 8.5 1.08 12.4 2.46 4.3 1.58 11.3 3.54 20.6 4.04h1.1c5.1 0 18.1-1.18 25.3-1.97 2.7-.3 4.7-2.56 4.6-5.22"
            />
            <path
                fill="url(#as)"
                d="M1457.12 885.199c-5.2-41.451-5.2-61.93-5.2-81.72v-11.027c-6.5-23.531-10.1-49.13-10.7-76.206l-.5-18.806c-.4-15.359 6.4-30.226 18.5-39.58 25.7-19.888 49.6-27.469 49.6-27.469s12.4 13.193 32.3 21.365l24.5 18.412c1.5-5.416-1.5-23.532-1.5-23.532s-.2-3.249-.3-4.726c2.3 1.28 6.8 4.332 9.1 6.006 27.2 19.593 46.6 60.355 52.5 81.523 2.1 7.68 2.4 14.276.6 19.495-3 9.058-2.2 19.888 2.3 30.522 2.3 5.612 8.6 20.479 10.1 39.284 1.2 13.883-2.1 32.787-8.1 47.063l-.4.985-.9-.394c-5.7-2.56-29.7-7.286-62-7.286-31.6 0-76.1 4.627-108.4 26.682l-1.4.886-.1-1.477Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1567.52 674.203c1.8 1.772 7.3 11.914 13 23.236M1508.92 630.684c7 7.975 22.3 17.919 32.2 21.266l24.6 18.707 8-19.199 7.3 6.498"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1541.12 651.951-9.4 12.898-21-22.251"
            />
            <path
                fill="url(#at)"
                d="M1615.12 947.127c2.7-.591 5.3-1.181 8-1.871 10.7-2.56 7-5.021 17.9-9.156 5.6-2.166 8.2-6.301 12.1-11.224 11.4-14.572 8.6-35.445-6.2-46.669l-.8-.591c-9.4-7.089-19.1-12.209-28.9-16.639-4.9-2.166-9.8-4.136-14.7-6.006-4.9-1.773-9.9-3.348-14.9-4.529-5-1.182-10.1-2.166-15.1-3.151-5.1-.886-10.1-1.871-15.2-2.461-2.5-.296-5.1-.69-7.6-.985-2.6-.295-5.1-.591-7.7-.788l-3.8-.295-3.9-.197c-2.6-.098-5.2-.197-7.8-.098l-9.7.295c-27.6.689-51.1 21.267-54.5 49.032-1.5 12.602 1.4 24.713 7.4 34.854 8.1 13.39 23 21.365 38.8 21.365h102.4l4.2-.886Z"
            />
            <path
                fill="#FCB47F"
                d="M1641.12 1111.65c-2.5-8.27-4.9-16.64-7.1-24.91-1.1-4.14-2.3-8.37-3.4-12.5l-3.2-12.51c-2.2-8.37-4.2-16.64-6.6-25.01-2.3-8.37-5-16.64-7-25.01-4.2-16.635-4.8-33.569-4.8-50.603.1-8.467.5-17.033 1.4-25.599.2-2.166.5-4.233.8-6.399.3-2.166.6-4.332 1-6.4.7-4.332 1.7-8.566 2.9-12.898l3.1-10.535c4-13.784 18.6-21.759 32.6-17.821 11.4 3.249 18.9 13.39 19.1 24.516l1 50.903.5 25.402.4 25.404c.2 16.93.5 33.87.7 50.9l.5 50.9c.1 8.67-7 15.76-15.8 15.85-7.3.1-13.4-4.62-15.4-11.22l-.7-2.46Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1468.92 926.641c8.1 13.39 23.9 21.365 39.7 21.365h102.4l4.1-.886M1647.02 878.203l-.8-.591c-9.4-7.089-19.1-12.209-28.9-16.639-4.9-2.166-9.8-4.136-14.7-6.006-4.9-1.772-9.9-3.348-14.9-4.529-5-1.182-10.1-2.166-15.1-3.151-5.1-.886-10.1-1.871-15.2-2.461-2.5-.296-5.1-.69-7.6-.985-2.6-.295-5.1-.591-7.7-.788l-3.8-.295-3.9-.197c-2.6-.098-5.2-.197-7.8-.098l-9.7.295M1641.92 1114.01l-.7-2.36c-2.5-8.27-4.9-16.64-7.1-24.91-1.1-4.14-2.3-8.37-3.4-12.51l-3.2-12.5c-2.2-8.37-4.2-16.64-6.6-25.01-2.3-8.37-5-16.64-7-25.01-4.2-16.638-5.3-40.563-4.7-59.073M1647.02 878.207c14.7 5.612 22.9 16.541 23.1 27.667l1 50.902.5 25.402.4 25.402c.2 16.94.5 33.87.7 50.9l.5 50.91"
            />
            <path
                fill="url(#au)"
                d="M1584.82 949.001h-76.1c-11.5 0-22.4-4.135-30.7-11.224-2.4-1.576-4.5-3.348-6.2-5.317-12.8-15.163-20.7-51.001-4.3-70.89 11.3-13.685 32.4-19.396 49.3-19.691 6.5-.197 14.9-.493 21.4 0 12.9.886 25.1 2.658 37.9 5.12 14.2 2.363 28.2 6.892 41.6 12.996 0 0-25.7 2.068-35 33.771-6 20.676 2.1 55.235 2.1 55.235Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1647.52 898.488h15.5"
            />
            <path
                fill="#FCB47F"
                d="m1704.3 626.55-1.3-1.2c.7-2.5-1.1-5.1-3-6.8-5.2-4.9-12.6-7.3-19.6-6.5-5.4.6-10.5 3-15.3 5.6-7.1 3.9-13.9 8.3-20.3 13.3-11.2 7.3-4.7 21.1 12.4 23.2 11.6 1.4 24.1-11.2 28.7-18.2l3.2.2c2.2.3 4.4.5 6.4 1.6 1.9 1 3.5 3 3.5 5.2 0 1.2-.5 2.3-.8 3.4-.2.8-.2 1.6 0 2.3.2.7.9 1.1 1.6 1.1 1.3-.1 2.5-.8 3.4-1.7.6-.6 1-1.3 1.3-2.1l.9.2c1 .3 2.2-.2 2.9-1 .7-.8 1.1-1.8 1.4-2.8 1.3-5.8-1.3-11.8-5.4-15.8Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1656.91 653.552c12.8 2.4 27.4-13.9 30.3-19.9M1644.8 630.852c6.4-5 13.2-9.4 20.3-13.3 4.8-2.6 9.8-5 15.3-5.6 7.1-.8 14.4 1.7 19.6 6.5 1.9 1.8 3.6 4.3 3 6.8"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1688.91 619.25c5.6 1.3 11.2 3.4 15.4 7.4 4.2 4 6.7 10 5.3 15.6-.3 1-.7 2.1-1.4 2.8-.7.8-1.9 1.2-2.9 1"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1691.91 626.352c4 .3 7.9 2.4 10.4 5.6 2.5 3.2 3.5 7.5 2.8 11.5-.3 1.7-.9 3.3-2.1 4.5-.9 1-2.1 1.599-3.4 1.699-.7.1-1.4-.4-1.6-1.1-.2-.7-.2-1.5 0-2.3.3-1.1.8-2.199.8-3.399.1-2.2-1.5-4.201-3.5-5.201-1.9-1-4.2-1.3-6.4-1.6"
            />
            <path
                fill="#FCB47F"
                d="M1476.6 658.053c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.3 3.8 8.4 1.7 16.7 3.7 25.1 5.9 8.3 2.3 16.6 4.8 24.8 8 12 4.7 17.9 18.2 13.2 30.1-3.1 8.1-10.3 13.4-18.3 14.6-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5-17-1.9-29.3-17.2-27.4-34.2 1.9-17 17.2-29.3 34.2-27.4h.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1560.2 680.853 9.6-6.301 9.5-6.399c6.4-4.2 12.9-8.301 19.5-12.101 3.3-1.9 6.6-3.899 10-5.799 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.5 10.4-5.2 7.9-3.7 17.2-.301 20.9 7.599 2.9 6.2 1.4 13.301-3.1 17.801-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.099-8.6 7.699-2.9 2.6-5.8 5.101-8.7 7.601-2.9 2.5-5.9 4.899-8.8 7.399-5.9 4.9-11.9 9.601-18.1 14.101l-9.3 6.8-9.2 6.8c-10 7.4-24.1 5.299-31.5-4.701-7.4-10-5.3-24.1 4.7-31.5.1-.2.5-.499.8-.699Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1526.9 720.651c6.08 0 11-3.94 11-8.8 0-4.86-4.92-8.8-11-8.8-6.07 0-11 3.94-11 8.8 0 4.86 4.93 8.8 11 8.8Z"
            />
            <path
                fill="#231F20"
                d="M1526.9 713.753c1.27 0 2.3-.851 2.3-1.9s-1.03-1.9-2.3-1.9c-1.27 0-2.3.851-2.3 1.9s1.03 1.9 2.3 1.9Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1476.4 658.055h.2c8.5 1 17 1.5 25.5 2.3 8.5.7 16.9 2 25.3 3.8 8.4 1.7 16.7 3.7 25.1 5.9 4.6 1.3 9.2 2.6 13.7 4.1M1586 717.754c-3.2 2.5-9.8 4.3-13.8 5-8.7 1.3-17.4 1.9-26 2.2-8.6.3-17.2.4-25.8.2-8.6-.1-17.1-.7-25.5-2-8.4-1.2-16.9-2.6-25.3-3.5M1569.7 674.553l9.5-6.4c6.4-4.2 12.9-8.3 19.5-12.1 3.3-1.9 6.6-3.9 10-5.8 3.3-1.9 6.7-3.8 10.1-5.6 3.4-1.8 6.8-3.7 10.2-5.5 3.5-1.7 6.9-3.5 10.4-5.2M1657.1 659.453c-2.7 2.7-5.6 5.3-8.4 8-2.8 2.6-5.7 5.1-8.6 7.7-2.9 2.6-5.8 5.1-8.7 7.6-2.9 2.5-5.9 4.9-8.8 7.4-5.9 4.9-11.9 9.6-18.1 14.1l-9.3 6.8-9.2 6.8"
            />
            <path
                fill="url(#av)"
                d="M1502.1 659.349c-2.6-.2-5.1-.4-7.7-.6-5.8-.5-11.9-.9-17.8-1.6h-.1c-1.2-.1-2.4-.2-3.6-.2-16.3 0-30 12.2-31.8 28.5-.9 8.5 1.5 16.8 6.8 23.5 5.3 6.7 13 10.9 21.5 11.8 5.9.7 11.9 1.5 17.7 2.4 2.5.4 5.1.7 7.6 1.1 1 .1 2.1.3 3.1.4l4.7-65.2c-.1-.1-.2-.1-.4-.1Z"
            />
            <path
                fill="url(#aw)"
                d="M1500.4 718.95c.9-1.7 3-2.2 4.7-1.1l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.3c-.9 1.7-3 2.2-4.7 1.1l-60.7-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.3Z"
            />
            <path
                fill="url(#ax)"
                d="m1569.9 752.85-60.7-37.9c-1.3-.8-2.7-.7-3.7 0l-4 2.9c1-.7 2.5-.8 3.7 0l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5l-16 31.299c-.2.5-.6.801-.9 1.101l4-2.9c.4-.3.7-.6.9-1.1l16-31.3c.8-1.6.1-3.9-1.6-5Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1546.8 793.248-60.7-37.9c-1.7-1.1-2.4-3.3-1.6-5l16-31.3c.9-1.7 3-2.2 4.7-1.1l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1550.5 793.248 4-2.9c.4-.3.7-.6.9-1.1l16-31.3c.9-1.7.2-3.9-1.6-5l-60.7-37.901c-1.3-.8-2.7-.7-3.7 0l-4 2.901c1-.7 2.5-.8 3.7 0l60.7 37.9c1.7 1.1 2.4 3.3 1.6 5"
            />
            <path
                fill="#231F20"
                d="M1490.7 753.149a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1494.4 746.45a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#888B8D"
                d="M1517.37 754.127c2.2-1.27 2.77-4.394 1.28-6.976-1.49-2.583-4.48-3.647-6.68-2.377-2.2 1.27-2.78 4.394-1.29 6.977 1.5 2.582 4.49 3.647 6.69 2.376Z"
                opacity={0.52}
            />
            <path
                fill="#231F20"
                d="M1497 757.051a2.1 2.1 0 1 0 0-4.2 2.1 2.1 0 0 0 0 4.2Z"
            />
            <path
                fill="#FCB47F"
                d="m1663 777.248-8.6.3c-2.9.1-5.8.2-8.6.3-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.6-17.4-1.1-5.8-.5-11.6-1.1-17.4-2-8.7-1.3-14.6-9.3-13.4-18 .9-6.4 5.6-11.3 11.4-12.9 5.7-1.6 11.4-3 17-4.2 5.7-1.2 11.4-2.3 17.1-3.3 1.4-.3 2.9-.5 4.3-.7 1.4-.2 2.9-.4 4.3-.6 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.5 8.6-.8l8.6-.8c12.4-1.2 23.4 7.8 24.6 20.2 1.2 12.4-7.8 23.4-20.2 24.6-.6.2-1.2.2-1.7.2Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1672.5 733.75c5.5 5 11.6 11.8 12.3 18.8 1.2 12.4-7.8 23.4-20.2 24.6-.5 0-1 .1-1.5.1l-8.6.3c-2.9.1-5.8.2-8.6.3-2.9 0-5.8.2-8.6.1-1.4 0-2.9 0-4.3-.1-1.4 0-2.9-.1-4.3-.2-5.8-.3-11.6-.6-17.4-1.1-5.8-.5-11.6-1.1-17.4-2M1591.6 743.748c5.7-1.6 11.4-3.001 17-4.201 5.7-1.2 11.4-2.299 17.1-3.299 1.4-.3 2.9-.501 4.3-.701 1.4-.2 2.9-.399 4.3-.599 2.9-.4 5.7-.6 8.6-1 2.9-.3 5.7-.501 8.6-.801l8.6-.799"
            />
            <path
                fill="#FCB47F"
                d="M1532.9 789.348c.3-.6.6-1.3.9-1.9l-.8-.901c-.9-1-1.3-2.299-1.3-3.599.1-3.7.9-8.101 2.1-11.201l-1.5-.899c-3.5-1.7-6.4-5.001-6.7-8.801-.3-4.2 2.4-8 5.4-11 5.8-5.8 12.7-13.3 20.9-12.5 10.6 1 21.1 2.7 31.5 5.1 19.5 4.1 23.7 19.401 11.3 30.201-5.5 5-14.3 6.099-20.9 2.699-5.6 5.2-13.3 13.1-16.8 20.7-.8 1.8-2.8 2.7-4.6 2h-.1c-2-.7-3-2.8-2.3-4.7 3.3-9.6 11.1-19.699 17.3-27.099-9.1 7.7-20.2 17.599-29.5 25.599-1.2 1-2.9 1-4.1 0-1.1-1-1.4-2.499-.8-3.699Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1587 744.547c-10.4-2.3-24.5-5-35.1-6-8.2-.8-15.1 6.7-20.9 12.5-3 3-5.7 6.8-5.4 11 .3 3.8 3.2 7.101 6.7 8.801"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1552.6 752.047c-5.9 4-11.9 8.2-15.9 14.1-3.4 5-5.1 11.2-4.9 17.2M1557.3 761.348c-4.8 3.9-9.6 7.699-13.7 12.199-3.6 3.9-8.3 10.301-10.7 15.801-.5 1.2-.2 2.7.8 3.6 1.2 1 2.9 1 4.1 0 9.3-8 20.4-17.9 29.5-25.6-6.3 7.4-14 17.5-17.3 27.1-.7 1.9.4 4.099 2.3 4.699h.1c1.8.6 3.8-.3 4.6-2.1 3.4-7.5 11.1-15.399 16.8-20.599 6.6 3.4 15.5 2.3 20.9-2.7M1542.9 755.949c-1.7 1.1-3.4 2.1-5.2 3.2-.7.5-1.5 1-1.8 1.8-.2.6 0 1.3.3 1.8.3.6.8 1 1.2 1.5"
            />
            <path
                fill="#D9D9D6"
                d="M1563.72 961.804h-11v-68.2c0-4.9-6.5-12.1-15.4-12.1h-92.3v-11h92.3c15.1 0 26.4 12.2 26.4 23.1v68.2Z"
            />
            <path
                fill="#40C1E9"
                d="M1538.12 870.403h-87.6c0-5.4 4.4-9.7 9.7-9.7h68.1c5.5 0 9.8 4.4 9.8 9.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1552.72 961.302v-67.6c0-4.9-6.5-12.1-15.4-12.1h-92.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1445.02 870.504h92.3c15.1 0 26.4 12.2 26.4 23.1v67.6M1443.42 1045.31c3.98 0 7.2-4.3 7.2-9.6 0-5.31-3.22-9.6-7.2-9.6-3.97 0-7.2 4.29-7.2 9.6 0 5.3 3.23 9.6 7.2 9.6Z"
            />
            <path
                fill="url(#ay)"
                d="M1433.12 1079.81c18.17 0 32.9-19.75 32.9-44.1 0-24.36-14.73-44.105-32.9-44.105-18.17 0-32.9 19.745-32.9 44.105 0 24.35 14.73 44.1 32.9 44.1Z"
            />
            <path
                fill="url(#az)"
                d="M1337.52 1035.7c0-72.095 41.4-131.195 94.1-136.895-2.8-.3-5.6-.5-8.4-.5-56.6 0-102.5 61.5-102.5 137.395 0 75.9 45.9 137.4 102.5 137.4 2.8 0 5.6-.2 8.4-.5-52.7-5.8-94.1-64.9-94.1-136.9Z"
            />
            <path
                fill="url(#aA)"
                d="M1435.62 879.305c-2.1 0-4.1.1-6.2.2-2.1-.1-4.1-.2-6.2-.2-64.5 0-116.7 70-116.7 156.395 0 86.4 52.3 156.4 116.7 156.4 2.1 0 4.1-.1 6.2-.2 2.1.1 4.1.2 6.2.2 64.5 0 116.7-70 116.7-156.4 0-86.395-52.3-156.395-116.7-156.395ZM1423.22 1173c-56.6 0-102.5-61.5-102.5-137.4 0-75.895 45.9-137.395 102.5-137.395 2.8 0 5.6.2 8.4.5 52.7 5.7 94.1 64.8 94.1 136.895 0 72.1-41.4 131.2-94.1 136.9-2.8.4-5.6.5-8.4.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1423.22 1045.31c3.98 0 7.2-4.3 7.2-9.6 0-5.31-3.22-9.6-7.2-9.6-3.97 0-7.2 4.29-7.2 9.6 0 5.3 3.23 9.6 7.2 9.6Z"
            />
            <path
                fill="#FFB33B"
                d="m1386.22 949.203-.3-.8c-.8-1.9-3.2-2.5-4.8-1.1-10.7 9.1-19.6 21.7-25.9 36.5-.6 1.3-.1 2.8 1 3.6 1.6 1.1 3.8.5 4.6-1.2 6-13.6 14.5-25.1 24.6-33.4 1-.9 1.4-2.4.8-3.6ZM1489.92 1084.5c-1.6-1.1-3.8-.5-4.6 1.2-6.1 13.5-14.5 24.8-24.5 33-1 .9-1.4 2.3-.8 3.5l.3.8c.8 1.9 3.2 2.5 4.8 1.2 10.6-9.1 19.5-21.4 25.8-36.1.6-1.2.2-2.8-1-3.6Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1401.52 1007.8c6.2-10.796 16-17.796 27-17.796.9 0 1.9.1 2.8.2 17.5 1.9 31.2 21.496 31.2 45.496 0 24-13.8 43.6-31.2 45.5-.9.1-1.9.2-2.8.2-18.8 0-34-20.4-34-45.6"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={2161.31}
                    x2={2160.31}
                    y1={1116.69}
                    y2={1116.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={2057.31}
                    x2={2026.35}
                    y1={1116.69}
                    y2={1116.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={2089.1}
                    x2={1958.88}
                    y1={1038.37}
                    y2={908.151}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.413} stopColor="#FFE4B8" />
                    <stop offset={0.54} stopColor="#EBC38C" />
                    <stop offset={0.576} stopColor="#E5B97F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={2113.68}
                    x2={1976.19}
                    y1={991.15}
                    y2={858.372}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E5B97F" />
                    <stop offset={0.035} stopColor="#E5BA81" />
                    <stop offset={1} stopColor="#FFE4B8" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={685.355}
                    x2={686.355}
                    y1={1116.69}
                    y2={1116.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={789.355}
                    x2={820.322}
                    y1={1116.69}
                    y2={1116.69}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={757.567}
                    x2={887.789}
                    y1={1038.37}
                    y2={908.151}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.413} stopColor="#FFE4B8" />
                    <stop offset={0.54} stopColor="#EBC38C" />
                    <stop offset={0.576} stopColor="#E5B97F" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={732.983}
                    x2={870.478}
                    y1={991.15}
                    y2={858.372}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E5B97F" />
                    <stop offset={0.035} stopColor="#E5BA81" />
                    <stop offset={1} stopColor="#FFE4B8" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={808.491}
                    x2={815.61}
                    y1={673.368}
                    y2={649.82}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.184} stopColor="#FCB47F" />
                    <stop offset={0.409} stopColor="#F8B07C" />
                    <stop offset={0.653} stopColor="#EBA473" />
                    <stop offset={0.905} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={847.557}
                    x2={847.557}
                    y1={661.078}
                    y2={593.881}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="k"
                    x1={858.019}
                    x2={844.232}
                    y1={595.385}
                    y2={595.385}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={835.711}
                    x2={813.374}
                    y1={595.595}
                    y2={595.595}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={852.012}
                    x2={780.879}
                    y1={677.45}
                    y2={783.281}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={890.339}
                    x2={819.206}
                    y1={703.207}
                    y2={809.039}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={706.984}
                    x2={906.789}
                    y1={788.49}
                    y2={788.49}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={738.215}
                    x2={809.051}
                    y1={1002.86}
                    y2={839.3}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={863.134}
                    x2={907.068}
                    y1={932.584}
                    y2={895.874}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={717.607}
                    x2={770.624}
                    y1={725.049}
                    y2={741.548}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={925.227}
                    x2={984.991}
                    y1={1171.13}
                    y2={1171.13}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={1875.73}
                    x2={1827.53}
                    y1={1116.98}
                    y2={1116.98}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#009CDE" />
                    <stop offset={0.9} stopColor="#39BDE8" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={1879.73}
                    x2={1819.88}
                    y1={1069.15}
                    y2={1069.15}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#009CDE" />
                    <stop offset={0.9} stopColor="#39BDE8" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={2007.43}
                    x2={2000.31}
                    y1={670.848}
                    y2={647.301}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.184} stopColor="#FCB47F" />
                    <stop offset={0.409} stopColor="#F8B07C" />
                    <stop offset={0.653} stopColor="#EBA473" />
                    <stop offset={0.905} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={1968.36}
                    x2={1968.36}
                    y1={658.558}
                    y2={591.361}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={1957.9}
                    x2={1971.69}
                    y1={592.903}
                    y2={592.903}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="z"
                    x1={1980.21}
                    x2={2002.55}
                    y1={593.116}
                    y2={593.116}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="A"
                    x1={2139.13}
                    x2={1930.94}
                    y1={796.299}
                    y2={780.684}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#0197D6" />
                    <stop offset={0.558} stopColor="#22ADE0" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="B"
                    x1={2188.38}
                    x2={1785.87}
                    y1={766.295}
                    y2={762.825}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={2189.01}
                    x2={1786.62}
                    y1={687.308}
                    y2={683.84}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={2188.98}
                    x2={1786.47}
                    y1={696.528}
                    y2={693.059}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={2188.02}
                    x2={1785.52}
                    y1={808.021}
                    y2={804.551}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="F"
                    x1={2078.65}
                    x2={2094.28}
                    y1={935.735}
                    y2={644.679}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.501} stopColor="#0047A7" />
                    <stop offset={0.602} stopColor="#0043A5" />
                    <stop offset={0.708} stopColor="#00369D" />
                    <stop offset={0.815} stopColor="#002191" />
                    <stop offset={0.869} stopColor="#001489" />
                </linearGradient>
                <linearGradient
                    id="G"
                    x1={1914.76}
                    x2={1818.87}
                    y1={1166.09}
                    y2={1166.09}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.299} stopColor="#63666A" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={2083.28}
                    x2={1833.39}
                    y1={1103.36}
                    y2={895.123}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1922.43}
                    x2={1871.62}
                    y1={1116.97}
                    y2={1116.97}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#009CDE" />
                    <stop offset={0.9} stopColor="#39BDE8" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1936.54}
                    x2={1873.63}
                    y1={1069.15}
                    y2={1069.15}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#009CDE" />
                    <stop offset={0.9} stopColor="#39BDE8" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="L"
                    x1={1094.76}
                    x2={1228.75}
                    y1={794.879}
                    y2={794.879}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FB8735" />
                    <stop offset={0.63} stopColor="#F76430" />
                    <stop offset={1} stopColor="#F64E2E" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1074.22}
                    x2={1275.84}
                    y1={817.637}
                    y2={817.637}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1223.06}
                    x2={1231.13}
                    y1={778.109}
                    y2={746.396}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1224.76}
                    x2={1247.99}
                    y1={806.031}
                    y2={782.802}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="Q"
                    x1={1201.89}
                    x2={1228.75}
                    y1={813.07}
                    y2={799.536}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="S"
                    x1={1111.57}
                    x2={1034.89}
                    y1={801.855}
                    y2={801.855}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="T"
                    x1={967.029}
                    x2={968.029}
                    y1={1021.76}
                    y2={1021.76}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="U"
                    x1={1009.42}
                    x2={1091.27}
                    y1={1021.77}
                    y2={1021.77}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="V"
                    x1={1835.06}
                    x2={984.705}
                    y1={836.807}
                    y2={836.807}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.681} stopColor="#E5B97F" />
                    <stop offset={0.698} stopColor="#F0CB97" />
                    <stop offset={0.717} stopColor="#FBDDAF" />
                    <stop offset={0.729} stopColor="#FFE4B8" />
                </linearGradient>
                <linearGradient
                    id="W"
                    x1={1299.93}
                    x2={1504.56}
                    y1={879.615}
                    y2={879.615}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.215} stopColor="#FFE4B8" />
                    <stop offset={0.258} stopColor="#FBDDAF" />
                    <stop offset={0.328} stopColor="#F0CB97" />
                    <stop offset={0.386} stopColor="#E5B97F" />
                </linearGradient>
                <linearGradient
                    id="X"
                    x1={1522.61}
                    x2={1522.61}
                    y1={1172.58}
                    y2={897.881}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="Y"
                    x1={1487.85}
                    x2={1703.61}
                    y1={1147.86}
                    y2={871.703}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#221E20" />
                    <stop offset={0.596} stopColor="#5E5B5E" />
                    <stop offset={0.999} stopColor="#89868A" />
                </linearGradient>
                <linearGradient
                    id="Z"
                    x1={1575.65}
                    x2={1663.25}
                    y1={865.57}
                    y2={865.57}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#0197D6" />
                    <stop offset={0.019} stopColor="#0298D6" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="aa"
                    x1={1446.91}
                    x2={1709.11}
                    y1={955.212}
                    y2={955.212}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.469} stopColor="#0197D6" />
                    <stop offset={0.617} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="ab"
                    x1={1685.22}
                    x2={1685.22}
                    y1={1190.86}
                    y2={1142.24}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="ac"
                    x1={1696.95}
                    x2={1696.95}
                    y1={1173.65}
                    y2={1145.52}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="ad"
                    x1={1697.27}
                    x2={1697.27}
                    y1={1190.86}
                    y2={1142.24}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="ae"
                    x1={1708.02}
                    x2={1765.31}
                    y1={1161.03}
                    y2={1160.57}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#221E20" />
                    <stop offset={0.596} stopColor="#5E5B5E" />
                    <stop offset={0.999} stopColor="#89868A" />
                </linearGradient>
                <linearGradient
                    id="af"
                    x1={1455.96}
                    x2={1600.06}
                    y1={846.05}
                    y2={846.05}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#0197D6" />
                    <stop offset={0.019} stopColor="#0298D6" />
                    <stop offset={1} stopColor="#40C1E9" />
                </linearGradient>
                <linearGradient
                    id="ag"
                    x1={1536.15}
                    x2={1600.27}
                    y1={1159.59}
                    y2={1159.59}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="ah"
                    x1={1553.7}
                    x2={1582.72}
                    y1={1159.59}
                    y2={1159.59}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="ai"
                    x1={1561.41}
                    x2={1602.7}
                    y1={661.441}
                    y2={661.441}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="aj"
                    x1={1547.31}
                    x2={1707.98}
                    y1={699.073}
                    y2={699.073}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="al"
                    x1={1431.78}
                    x2={1728.13}
                    y1={904.855}
                    y2={904.855}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.453} stopColor="#E19A6D" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </linearGradient>
                <linearGradient
                    id="am"
                    x1={1581.04}
                    x2={1581.04}
                    y1={636.168}
                    y2={570.008}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="an"
                    x1={1539.69}
                    x2={1549.69}
                    y1={641.965}
                    y2={624.659}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="ao"
                    x1={1560.94}
                    x2={1560.94}
                    y1={637.827}
                    y2={603.591}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.144} stopColor="#2B2B2B" stopOpacity={0} />
                    <stop offset={1} stopColor="#2B2B2B" stopOpacity={0.805} />
                </linearGradient>
                <linearGradient
                    id="ap"
                    x1={1591.45}
                    x2={1577.66}
                    y1={571.488}
                    y2={571.488}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="aq"
                    x1={1569.14}
                    x2={1546.8}
                    y1={571.701}
                    y2={571.701}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="ar"
                    x1={1636.01}
                    x2={1729.9}
                    y1={1134.01}
                    y2={1134.01}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="as"
                    x1={1440.76}
                    x2={1638.68}
                    y1={758.673}
                    y2={758.673}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="at"
                    x1={1634.43}
                    x2={1319.84}
                    y1={916.794}
                    y2={852.169}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.504} stopColor="#E19A6D" />
                    <stop offset={0.915} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="au"
                    x1={1614.44}
                    x2={1427.97}
                    y1={871.023}
                    y2={937.993}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#001489" />
                    <stop offset={0.672} stopColor="#00359C" />
                    <stop offset={1} stopColor="#0047A7" />
                </linearGradient>
                <linearGradient
                    id="av"
                    x1={1514.14}
                    x2={1444.75}
                    y1={585.374}
                    y2={769.277}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFD100" />
                    <stop offset={0.274} stopColor="#FFDA32" />
                    <stop offset={0.897} stopColor="#FFF0AE" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="aw"
                    x1={1484.08}
                    x2={1567.78}
                    y1={755.545}
                    y2={755.545}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="ax"
                    x1={1501.36}
                    x2={1571.8}
                    y1={753.831}
                    y2={753.831}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <linearGradient
                    id="ay"
                    x1={1433.15}
                    x2={1433.15}
                    y1={1079.74}
                    y2={991.615}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="az"
                    x1={1376.17}
                    x2={1376.17}
                    y1={1173.02}
                    y2={898.324}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F3F3F3" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="aA"
                    x1={1429.41}
                    x2={1429.41}
                    y1={1068.51}
                    y2={831.303}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={0.746} stopColor="#ABADAD" />
                </linearGradient>
                <clipPath id="v">
                    <path fill="#fff" d="M2450.04 504.867h-900v900h900z" />
                </clipPath>
                <clipPath id="K">
                    <path fill="#fff" d="M1297 751h41v72h-41z" />
                </clipPath>
                <clipPath id="R">
                    <path fill="#fff" d="M1122.02 750.219h-81.081v33.445h81.081z" />
                </clipPath>
                <radialGradient
                    id="ak"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="matrix(255.204 0 0 251.268 1481.64 896.629)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
            </defs>
        </svg>
    );
}
