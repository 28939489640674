import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10363_7820)"
            d="M494.768 222.602a109.64 109.64 0 00-20.026-15.329 31.051 31.051 0 012.394 13.895 66.023 66.023 0 01-1.451 10.762l2.169 4.755a.997.997 0 00.949.584.987.987 0 00.381-.092c2.597-1.2 15.6-7.426 16.405-12.092a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M482.911 213.641c6.47 4.872 6.085 4.72 11.157 9.683a1.703 1.703 0 01.536 1.597c-.523 3.028-8.33 7.884-15.838 11.355"
        ></path>
        <path
            fill="url(#paint1_linear_10363_7820)"
            d="M461.42 278.751a.997.997 0 01-.951-.693l-.208-.639a104.71 104.71 0 01-.832-2.637c-.619-2.069-1.224-4.147-1.829-6.224-1.379-4.732-2.806-9.626-4.396-14.366a27.492 27.492 0 011.398-21.048 27.503 27.503 0 0136.94-12.39 27.505 27.505 0 0113.807 15.948l.045.136.002.006c1.565 4.751 3.352 9.527 5.08 14.147.714 1.907 1.424 3.816 2.129 5.727a1 1 0 01-.557 1.269L461.8 278.675a.991.991 0 01-.38.076z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M489.3 218.703c6.012 4.62 12.843 11.6 15.096 18.303v.14l.095.142c1.56 4.729 3.335 9.47 5.051 14.057.712 1.905 1.421 3.812 2.126 5.72l-50.243 20.688-.211-.64a106.53 106.53 0 01-.823-2.612 961.756 961.756 0 01-1.829-6.218c-1.379-4.733-2.804-9.626-4.408-14.406"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10363_7820"
                x1="480.642"
                x2="503.141"
                y1="223.429"
                y2="248.214"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.034" stopColor="#fff"></stop>
                <stop offset="0.967" stopColor="#E2E2E2"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10363_7820"
                x1="466.374"
                x2="497.119"
                y1="233.941"
                y2="275.492"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.034" stopColor="#fff"></stop>
                <stop offset="0.967" stopColor="#E2E2E2"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
