import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-3-walk-2">
            <path
                d="M398.15 847.845c-23.428-3.047-35.308-14.226-36.92-15.846l-23.667-8.3a9.012 9.012 0 0 1-5.304-4.886 9.021 9.021 0 0 1-.794-3.594 9.023 9.023 0 0 1 .71-3.61c5.936-14.207 8.508-20.969 9.136-23.789l34.642 10.248c.533.944 8.269 24.551 24.039 34.19 8.253 5.044 11.943 9.011 10.969 11.791-1.13 3.223-6.039 4.677-12.811 3.796Z"
                fill="#E2E2E2"
            />
            <path
                d="M377.564 800.907a3.107 3.107 0 0 1 2.03 1.678c4.124 8.639 11.63 23.139 20.92 28.818 11.766 7.192 12.136 10.85 11.391 12.975-1.315 3.75-6.505 5.416-13.884 4.456-15.832-2.06-26.493-7.811-32.33-11.908a60.003 60.003 0 0 0-14.65-7.443l-13.511-4.738a10.502 10.502 0 0 1-6.045-5.227 10.007 10.007 0 0 1-.231-8.297c2.573-6.16 4.707-11.403 6.348-15.59a3.212 3.212 0 0 1 3.171-2.096c4.32.336 5.085 9.34 12.287 11.284 7.66 2.068 20.679-4.89 24.504-3.912Z"
                fill="#001489"
            />
            <path
                d="M411.452 845.328a16.365 16.365 0 0 1-7.322 1.349c-10.293.032-32.984-12.492-43.569-17.696-9.619-4.729-13.771-14.622-27.627-21.801a974.54 974.54 0 0 1-1.681 4.045 10.01 10.01 0 0 0 .232 8.297 10.498 10.498 0 0 0 6.045 5.227l13.511 4.738a60.038 60.038 0 0 1 14.65 7.443c5.838 4.097 16.498 9.849 32.33 11.909 6.722.874 11.617-.437 13.431-3.511ZM440.983 860.886a9.046 9.046 0 0 1-6.655-2.87 9.043 9.043 0 0 1-2.474-6.804c.913-15.623 1.226-25.837.88-28.76l32.733-.6c.107.167 9.482 16.792 16.996 21.852 3.341 2.25 9.955 5.864 13.601 7.817a4.975 4.975 0 0 1 1.162 7.906 4.98 4.98 0 0 1-3.519 1.46l-52.724-.001Z"
                fill="#E2E2E2"
            />
            <path
                d="M466.009 824.805c1.544-.012 2.309.836 3.173 2.115 3.104 4.596 8.238 12.639 14.033 16.541 3.247 2.187 9.658 5.696 13.325 7.665a6.101 6.101 0 0 1 3.297 5.057 5.901 5.901 0 0 1-5.89 6.134H439.48a10.46 10.46 0 0 1-7.404-2.918 9.963 9.963 0 0 1-2.955-7.725c.645-11.018.99-12.854 1.016-18.064.013-2.52.437-7.546 2.804-8.409 14.876 4.43 33.068-.396 33.068-.396Z"
                fill="url(#female-34-25-shoes-3-walk-2-a)"
            />
            <path
                d="M444.576 857.784c-5.968-2.419-12.756-9.857-15.083-12.55-.107 2.05-.225 4.199-.356 6.446a9.967 9.967 0 0 0 2.955 7.725 10.464 10.464 0 0 0 7.404 2.917h54.451a5.891 5.891 0 0 0 5.006-2.792c-14.505.113-47.44 1.066-54.377-1.746Z"
                fill="#E2E2E2"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-3-walk-2-a"
                    x1={445.107}
                    y1={824.805}
                    x2={494.406}
                    y2={858.466}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#001489" />
                    <stop offset={1} stopColor="#012291" />
                </linearGradient>
            </defs>
        </g>
    );
}
