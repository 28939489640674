import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M494.768 222.602a109.64 109.64 0 0 0-20.026-15.329 31.037 31.037 0 0 1 2.394 13.895 65.914 65.914 0 0 1-1.451 10.762l2.169 4.755a1.013 1.013 0 0 0 .563.522.997.997 0 0 0 .767-.03c2.598-1.2 15.601-7.426 16.405-12.092a2.712 2.712 0 0 0-.821-2.483Z"
            fill="url(#female-34-25-shirt-1-arm-left-opt-1-a)"
        />
        <path
            d="M482.911 213.641c6.47 4.872 6.085 4.72 11.157 9.683a1.703 1.703 0 0 1 .536 1.597c-.523 3.028-8.33 7.884-15.838 11.355"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M523.991 320.535a193.953 193.953 0 0 0-2.169-21.062c-5.24-34.258-10.714-42.459-17.42-62.453a26.502 26.502 0 0 0-13.311-15.334 26.487 26.487 0 0 0-20.257-1.412 26.495 26.495 0 0 0-15.309 13.341 26.501 26.501 0 0 0-1.371 20.26c2.283 6.806 4.176 13.743 6.237 20.623 5.264 17.784 18.397 44.073 28.529 58.609l.024.031c.131.331.249.664.401.991 8.925 19.5 14.695 33.537 27 53.091 5.238 8.381 10.412 16.672 16.501 24.97l23.613-12.253c-5.891-21.901-16.238-49.054-32.468-79.402Z"
            fill="url(#female-34-25-shirt-1-arm-left-opt-1-b)"
        />
        <path
            d="M491.035 220.336c5.57 5.141 9.884 8.865 13.413 16.818 2.913 6.562 4.882 13.508 7.35 20.254a151.23 151.23 0 0 1 5.973 20.711c1.574 7.042 2.951 14.15 4.052 21.35.844 5.645 1.569 11.33 1.962 17.124M488.92 333.107a190.515 190.515 0 0 1-11.378-18.915c-3.428-6.426-6.581-12.944-9.536-19.527a151.065 151.065 0 0 1-7.614-20.166c-2.062-6.881-3.955-13.817-6.238-20.624M536.574 417.134c-5.006-6.401-9.509-13.044-13.861-19.76-1.076-1.684-2.186-3.353-3.234-5.051l-3.134-5.1c-2.128-3.381-4.064-6.855-6.094-10.284a234.102 234.102 0 0 1-10.991-21.144l-4.893-10.862-5.021-10.8M524.67 320.875l3.327 7.84 5.45 10.594a233.767 233.767 0 0 1 9.696 21.768c1.418 3.724 2.93 7.402 4.25 11.173l2.038 5.628c.675 1.878 1.289 3.785 1.937 5.677 2.543 7.588 4.936 15.248 6.825 23.151"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-left-opt-1-a"
                x1={480.642}
                y1={223.429}
                x2={503.14}
                y2={248.214}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.034} stopColor="#fff" />
                <stop offset={0.967} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-left-opt-1-b"
                x1={460.19}
                y1={220.039}
                x2={575.613}
                y2={432.227}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.064} stopColor="#fff" />
                <stop offset={0.916} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
