import { IBodyClothes } from 'interfaces';

import P1 from './female-34-25-doctors-coat-p1';
import P2 from './female-34-25-doctors-coat-p2';
import Shirt1ArmRightOpt1 from '../shirt-1/female-34-25-shirt-1-arm-opt-1-right';
import Shirt1ArmRightOpt2 from '../shirt-1/female-34-25-shirt-1-arm-opt-2-right';
import Shirt1ArmRightOpt3 from '../shirt-1/female-34-25-shirt-1-arm-opt-3-right';
import Shirt1ArmRightOpt4 from '../shirt-1/female-34-25-shirt-1-arm-opt-4-right';
import Shirt1ArmRightOpt5 from '../shirt-1/female-34-25-shirt-1-arm-opt-5-right';
import Shirt1ArmRightOpt6 from '../shirt-1/female-34-25-shirt-1-arm-opt-6-right';
import Shirt1ArmRightOpt7 from '../shirt-1/female-34-25-shirt-1-arm-opt-7-right';
import Shirt1ArmRightOpt8 from '../shirt-1/female-34-25-shirt-1-arm-opt-8-right';
import Shirt1ArmRightOpt9 from '../shirt-1/female-34-25-shirt-1-arm-opt-9-right';
import Shirt1ArmLeftOpt1 from '../shirt-1/female-34-25-shirt-1-arm-opt-1-left';
import Shirt1ArmLeftOpt2 from '../shirt-1/female-34-25-shirt-1-arm-opt-2-left';
import Shirt1ArmLeftOpt3 from '../shirt-1/female-34-25-shirt-1-arm-opt-3-left';
import Shirt1ArmLeftOpt4 from '../shirt-1/female-34-25-shirt-1-arm-opt-4-left';
import Shirt1ArmLeftOpt5 from '../shirt-1/female-34-25-shirt-1-arm-opt-5-left';
import Shirt1ArmLeftOpt6 from '../shirt-1/female-34-25-shirt-1-arm-opt-6-left';
import Shirt1ArmLeftOpt7 from '../shirt-1/female-34-25-shirt-1-arm-opt-7-left';
import Shirt1ArmLeftOpt8 from '../shirt-1/female-34-25-shirt-1-arm-opt-8-left';
import Shirt1ArmLeftOpt9 from '../shirt-1/female-34-25-shirt-1-arm-opt-9-left';
import Shirt1ArmLeftOpt10 from '../shirt-1/female-34-25-shirt-1-arm-opt-10-left';

import ArmsRightOpt1 from '../shirt-1/female-34-25-shirt-1-arms-opt-1-right';
import ArmsLeftOpt1 from '../shirt-1/female-34-25-shirt-1-arms-opt-1-left';
import ArmsRightOpt2 from '../shirt-1/female-34-25-shirt-1-arms-opt-2-right';
import ArmsLeftOpt2 from '../shirt-1/female-34-25-shirt-1-arms-opt-2-left';

const doctorsCoat: IBodyClothes = {
    text: 'Doctor\'s coat',
    normal: {
        front: P1,
        back: P2,
        rightArm: {
            option1: Shirt1ArmRightOpt1,
            option2: Shirt1ArmRightOpt2,
            option3: Shirt1ArmRightOpt3,
            option4: Shirt1ArmRightOpt4,
            option5: Shirt1ArmRightOpt5,
            option6: Shirt1ArmRightOpt6,
            option7: Shirt1ArmRightOpt7,
            option8: Shirt1ArmRightOpt8,
            option9: Shirt1ArmRightOpt9,
        },
        leftArm: {
            option1: Shirt1ArmLeftOpt1,
            option2: Shirt1ArmLeftOpt2,
            option3: Shirt1ArmLeftOpt3,
            option4: Shirt1ArmLeftOpt4,
            option5: Shirt1ArmLeftOpt5,
            option6: Shirt1ArmLeftOpt6,
            option7: Shirt1ArmLeftOpt7,
            option8: Shirt1ArmLeftOpt8,
            option9: Shirt1ArmLeftOpt9,
            option10: Shirt1ArmLeftOpt10,
        },
        bothArms: {
            option1: [ArmsRightOpt1, ArmsLeftOpt1],
            option2: [ArmsRightOpt2, ArmsLeftOpt2],
        }
    },
};

export default doctorsCoat;
