import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-5">
        <path
            d="M565.138 334.898c-2.412 0-7.904-.658-12.748-1.239-3.501-.419-6.807-.815-8.395-.898a64.83 64.83 0 0 1-2.94-.184 61.332 61.332 0 0 1-2.921-.265c-1.257-.11-2.531-.311-3.763-.505-.656-.103-1.311-.206-1.97-.296-1.201-.19-2.377-.402-3.552-.614-.719-.129-1.437-.259-2.157-.382l-5.146-.84a24.158 24.158 0 0 1-18.925-15.636c-4.185-5.3-8.332-11.115-13.428-18.83-4.578-6.978-9.03-14.259-13.613-22.261a206.6 206.6 0 0 1-11.679-23.21 899.408 899.408 0 0 1-3.037-7.187c-2.289-5.45-4.656-11.087-7.174-16.533a32.037 32.037 0 0 1 15.617-42.475 31.754 31.754 0 0 1 13.409-2.961 32.115 32.115 0 0 1 29.066 18.579l.063.137c2.494 5.464 5.23 10.935 7.875 16.226 1.162 2.324 2.319 4.651 3.47 6.98a206.353 206.353 0 0 1 10.003 23.985c3.082 8.677 5.705 16.799 8.018 24.82 1.76 6.176 3.104 11.353 4.216 16.235.713.205 1.497.443 2.266.713l.989.303c14.34 4.386 22.698 7.115 24.84 8.111-17.205 4.068-16.698 31.465-8.274 38.227h-.11Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-5-a)"
        />
        <path
            d="M504.638 189.277a31.878 31.878 0 0 1 7.145 9.884l.063.138c2.494 5.464 5.23 10.935 7.875 16.225 1.162 2.325 2.319 4.651 3.47 6.98a206.44 206.44 0 0 1 10.003 23.986c3.082 8.677 5.705 16.798 8.018 24.82 1.76 6.175 3.104 11.353 4.216 16.235.713.205 1.497.442 2.266.713l.989.302c14.34 4.387 22.698 7.116 24.84 8.112-17.205 4.067-16.698 31.465-8.274 38.227h-.11c-2.412 0-7.904-.658-12.748-1.239-3.501-.419-6.807-.815-8.395-.898a64.83 64.83 0 0 1-2.94-.184 61.332 61.332 0 0 1-2.921-.265c-1.257-.11-2.531-.311-3.763-.505-.656-.103-1.311-.206-1.97-.297-1.201-.19-2.376-.401-3.551-.613-.719-.129-1.437-.259-2.157-.382l-5.146-.84a24.162 24.162 0 0 1-18.925-15.636"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m465.193 167.117 2.655.951c13.051 4.678 23.789 10.343 32.825 17.32a2.671 2.671 0 0 1 1.092 2.34c-.25 4.649-12.215 12.209-14.611 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-5-b)"
        />
        <path
            d="M467.85 168.07c13.051 4.678 23.789 10.343 32.825 17.32"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-5-a"
                x1={547.201}
                y1={402.359}
                x2={489.514}
                y2={162.504}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.95} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-5-b"
                x1={538.163}
                y1={404.532}
                x2={480.476}
                y2={164.677}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.95} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
