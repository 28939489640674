import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M555.033 325.82c.447-4.068 1.063-8.16 1.659-12.117.262-1.737.524-3.475.772-5.215a127.008 127.008 0 0 0 1.243-17.47c-.004-.95.004-1.906.012-2.862.015-2.001.03-3.981-.049-5.924l-.116-4.432c-.027-.946-.083-1.895-.138-2.844l-.013-.235a257.492 257.492 0 0 0-.266-4.355c-2.578.036-9.68-.106-15.727-2.363-7.004-2.613-10.86-6.008-12.616-7.923a163.668 163.668 0 0 0-1.691 5.157l-.643 2.063-1.157-1.824a191.741 191.741 0 0 0-11.053-15.703 853.211 853.211 0 0 0-4.351-5.526l-.209-.265c-3.479-4.395-7.075-8.94-10.464-13.549l-.076-.102a26.628 26.628 0 0 0-21.293-10.698 26.5 26.5 0 0 0-26.379 28.795 26.505 26.505 0 0 0 5.127 13.509c3.41 4.59 6.695 9.354 9.872 13.961l.101.147a1554.82 1554.82 0 0 0 4.104 5.923 195.935 195.935 0 0 0 15.242 19.167c5.789 6.529 11.309 12.407 16.881 17.976a247.313 247.313 0 0 0 18.435 16.811c.377.305.795.608 1.31.954l.143.117a18.297 18.297 0 0 0 10.931 5.196 18.465 18.465 0 0 0 20.409-16.369Z"
            fill="url(#female-34-25-shirt-1-arm-left-opt-2-a)"
        />
        <path
            d="M457.605 261.942c3.41 4.59 6.695 9.354 9.872 13.962l.101.146c1.364 1.979 2.732 3.953 4.104 5.923a195.935 195.935 0 0 0 15.242 19.167c5.789 6.529 11.309 12.407 16.881 17.976a247.544 247.544 0 0 0 18.435 16.812c.378.304.795.607 1.31.953l.143.117a18.3 18.3 0 0 0 10.931 5.196c.678.075 1.36.112 2.043.112a18.462 18.462 0 0 0 18.366-16.481c.448-4.068 1.064-8.16 1.659-12.116.263-1.738.525-3.476.773-5.216a127.008 127.008 0 0 0 1.243-17.47c-.004-.95.004-1.906.012-2.862.014-2.001.03-3.981-.049-5.924l-.116-4.431c-.028-.946-.083-1.895-.138-2.845l-.014-.234a281.636 281.636 0 0 0-.265-4.356c-2.578.037-9.68-.106-15.727-2.362-7.005-2.614-10.86-6.009-12.616-7.924a163.76 163.76 0 0 0-1.691 5.158l-.643 2.063-1.157-1.825a192.085 192.085 0 0 0-11.053-15.703 873.762 873.762 0 0 0-4.352-5.526l-.209-.264c-3.478-4.396-7.074-8.941-10.463-13.549l-.077-.103"
            stroke="#231F20"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-left-opt-2-a"
                x1={422.324}
                y1={397.099}
                x2={559.351}
                y2={206.27}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
