import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-7">
        <path
            fill={SKIN_COLOR[color]}
            d="M407.571 219.605c-1.796 8.332-3.164 16.755-4.717 25.139a200.258 200.258 0 0 1-6.225 24.816c-2.531 8.175-5.279 16.304-8.328 24.369a249.029 249.029 0 0 1-10.347 23.939 23.276 23.276 0 0 1-31.283 10.246 23.315 23.315 0 0 1-12.73-19.619 249.023 249.023 0 0 1 .304-26.078c.501-8.607 1.304-17.15 2.324-25.647a200.307 200.307 0 0 1 4.427-25.2c1.998-8.289 4.179-16.538 5.934-24.879a31.012 31.012 0 0 1 13.399-19.581 31.005 31.005 0 0 1 34.443.38 31.004 31.004 0 0 1 12.83 31.968l-.031.147Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m363.918 290.198 11.646 3.995 11.665 3.933c7.769 2.646 15.458 5.544 23.101 8.588 3.812 1.553 7.641 3.05 11.433 4.665 3.803 1.581 7.602 3.173 11.371 4.861 3.774 1.672 7.563 3.297 11.325 5.008 3.742 1.775 7.513 3.454 11.224 5.326a15.712 15.712 0 0 1 7.841 9.11 15.71 15.71 0 0 1-.898 11.985 15.74 15.74 0 0 1-16.295 8.461c-4.112-.602-8.162-1.398-12.244-2.1-4.061-.764-8.095-1.614-12.144-2.417-4.055-.787-8.079-1.67-12.099-2.564-4.031-.86-8.024-1.839-12.035-2.76-8.003-1.907-15.96-3.959-23.837-6.264l-11.805-3.492-11.823-3.431a22.506 22.506 0 1 1 13.574-42.904Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M333.941 308.495a249.001 249.001 0 0 1 .304-26.077c.501-8.608 1.304-17.151 2.324-25.648a200.307 200.307 0 0 1 4.427-25.2c1.998-8.289 4.179-16.538 5.934-24.879m60.673 12.766-.032.148c-1.795 8.331-3.164 16.754-4.717 25.138a200.14 200.14 0 0 1-6.225 24.817c-2.531 8.175-5.279 16.304-8.328 24.368m-4.986 2.881 3.914 1.32c7.769 2.645 15.458 5.543 23.101 8.587 3.812 1.554 7.641 3.05 11.433 4.665 3.803 1.581 7.602 3.174 11.371 4.862 3.774 1.672 7.563 3.297 11.325 5.008 3.742 1.774 7.513 3.453 11.224 5.326a15.696 15.696 0 0 1 7.841 9.109 15.716 15.716 0 0 1-.898 11.985 15.742 15.742 0 0 1-16.295 8.462c-4.112-.603-8.162-1.399-12.244-2.1-4.061-.764-8.095-1.615-12.144-2.418-4.055-.787-8.079-1.67-12.099-2.563-4.031-.861-8.024-1.84-12.035-2.761-8.003-1.907-15.96-3.959-23.837-6.263l-11.805-3.493-11.823-3.431c-10.096-2.93-16.799-12.85-16.401-24.609"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m533.085 358.968-.627-.41.638-.193a2.955 2.955 0 0 0 1.985-2.559 3.207 3.207 0 0 0-1.927-2.898c-4.219-1.565-7.642-4.674-11.202-7.426-8.246-6.375-17.337-13.476-27.718-14.404l-39.714-3.548c-21.566-1.415-22.893 23.801-7.124 29.915 8.022 3.483 16.276 6.403 24.521 9.32 13.548 4.793 26.225 9.58 40.427 10.544a2.04 2.04 0 0 0 2.149-1.833l.005-.045a2.043 2.043 0 0 0-.272-1.249 2.043 2.043 0 0 0-.963-.841c-9.057-3.908-17.232-9.194-25.189-15.076l11.741 3.603c8.168 2.506 16.405 5.026 24.91 5.849a3.877 3.877 0 0 0 3.055-.731 3.51 3.51 0 0 0 1.198-3.063l-.109-.796c.56.222 1.119.445 1.678.669a2.727 2.727 0 0 0 2.113-.028 2.738 2.738 0 0 0 1.462-1.526 2.743 2.743 0 0 0-1.037-3.274Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m453.914 327.531 39.714 3.549c10.381.927 19.472 8.028 27.718 14.403 3.56 2.753 6.983 5.862 11.202 7.426m-40.833-2.854a568.752 568.752 0 0 1 38.225 13.743 2.744 2.744 0 0 0 2.113-.029 2.75 2.75 0 0 0 .885-.616 2.757 2.757 0 0 0 .633-2.729 2.74 2.74 0 0 0-1.093-1.454 147.236 147.236 0 0 0-37.268-17.63m-48.421 16.105c8.023 3.484 16.277 6.404 24.523 9.321 13.547 4.793 26.223 9.58 40.425 10.544a2.044 2.044 0 0 0 2.151-1.834l.004-.045a2.038 2.038 0 0 0-1.236-2.09c-9.057-3.908-17.232-9.193-25.188-15.075l11.74 3.602c8.169 2.507 16.405 5.027 24.91 5.849"
        />
    </g>
);

export default SvgComponent;
