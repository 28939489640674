import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={33}
            height={34}
            fill="none"
            id="object-food-almond"
            {...attributes}
        >
            <path
                fill="url(#object-food-almond-a)"
                d="M32.137 1.325C31.085.273 16.36-.683 7.867 7.809-.625 16.3-2.004 25.643 2.907 30.554c4.91 4.91 14.253 3.533 22.745-4.96 8.492-8.492 7.536-23.217 6.484-24.27Z"
            />
            <defs>
                <linearGradient
                    id="object-food-almond-a"
                    x1={0.079}
                    x2={32.812}
                    y1={-2.525}
                    y2={-2.525}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
