import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-left-up">
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M468.258 169.101c12.6 4.5 23.099 10.4 32.599 17.2"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M500.757 186.301c-9.2-7.1-20.001-12.7-32.601-17.2l19.101 31.5c.1 0 18-10.9 13.5-14.3Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M488.856 183.599c9.2 2.4 18.401 4.4 27.601 6.5 9.2 2.1 18.299 4.8 27.199 8 9 3.1 17.9 6.5 26.7 10.1 8.8 3.7 17.6 7.5 26.2 12.1 11.3 6 15.6 20 9.6 31.3-4.2 8-12.5 12.4-20.9 12.3-9.7-.2-19.299-1.1-28.799-2.1-9.5-1.1-18.901-2.5-28.201-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.599-21.2-22.299-37.7 4.3-16.6 21.199-26.6 37.699-22.3l.2-.2Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m569.156 242.7-.5-7.7c-.2-2.6-.399-5.1-.599-7.7-.1-2.6-.401-5.1-.401-7.7 0-1.3-.099-2.6-.099-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.7-10.4 1.4-15.7 1.1-8.7 9.1-14.9 17.8-13.8 6.1.8 11 5 13 10.5 1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.599 2.5.899 3.8.3 1.3.501 2.5.801 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.699 5.1 1.099 7.6l1.101 7.6c1.8 12.3-6.7 23.7-19 25.6-12.3 1.8-23.701-6.7-25.601-19-.1-.5-.2-1.1-.2-1.6Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M594.856 263.399c-14.1.8-28.8-.6-38.3-1.6-9.5-1.1-18.9-2.5-28.2-4.1-9.4-1.6-18.6-3.6-27.7-6.2-9.1-2.6-18.2-5.4-27.3-7.7-16.6-4.3-26.6-21.2-22.3-37.7 4.3-16.6 21.2-26.6 37.7-22.3h.1c9.2 2.4 18.401 4.4 27.601 6.5 9.2 2.1 18.299 4.8 27.199 8 7 2.4 13.901 5 20.801 7.7"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M568.557 234.999c-.2-2.6-.4-5.1-.6-7.7-.1-2.6-.4-5.1-.4-7.7 0-1.3-.1-2.6-.1-3.9v-3.9c.1-5.2.2-10.4.5-15.6.3-5.2.699-10.4 1.399-15.7m30.901-3.3c1.8 5 3.3 10 4.7 15 1.4 5 2.7 10.1 3.8 15.1.3 1.3.599 2.5.899 3.8.3 1.3.501 2.5.801 3.8.5 2.5.8 5.1 1.3 7.6.4 2.5.699 5.1 1.099 7.6l1.101 7.6c1.8 12.3-6.7 23.7-19 25.6"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M608.457 152.9c-3.6-4.5-8.101-8.2-13.201-11-2.1-1.2-4.3-2-6.6-2.4h-.1c-.2-4.9-.4-9.8-.5-14.7-.1-3.2-.1-6.3-.2-9.4 0-1.5-1.2-2.8-2.7-3.1h-.1c-1.6-.2-3.1.8-3.5 2.3-1.4 5.2-1.6 10.7-1.8 16.1-.3 6.4-.7 12.9-3.4 18.7.1-.2-1.4 3.5-1.3 3.3l-2 4.2c-.4-1.2-.5-2.5-.3-3.8.2-1.8.7-3.5.9-5.3.1-1 .1-2.1-.1-3.1a2.88 2.88 0 0 0-2.599-2.2c-1.2-.1-2.401.6-2.901 1.7-.8 1.8-2 3.8-2.9 5.3-2.5 4.4-2.599 9.8-1.199 14.7 1.3 4.9 3.999 9.2 6.799 13.4 8.6 13.8 23.701 16.8 29.701 5.2 3.9-6.8 7-14.4 9.5-21.9.8-2.7.3-5.8-1.5-8Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M570.756 177.7c-2.8-4.2-5.5-8.6-6.8-13.4-1.3-4.9-1.201-10.3 1.199-14.7.9-1.5 2.101-3.5 2.901-5.3.5-1.1 1.7-1.8 2.9-1.7 1.3.1 2.4 1 2.6 2.2.2 1 .199 2 .099 3.1-.2 1.8-.699 3.5-.899 5.3-.2 1.8 0 3.7 1 5.1m2.9-9.4c-.1.2-.099.3-.199.5 2.7-5.8 3.199-12.3 3.399-18.7.2-5.4.4-10.9 1.8-16.1.4-1.6 1.9-2.6 3.5-2.3h.101c1.5.2 2.7 1.5 2.7 3.1.1 3.2.099 6.3.199 9.4.2 4.9.4 9.8.5 14.7m-1.601 3.3c-2.9 2.6-5.901 5.4-7.401 9-1.5 3.6-1.3 7.7-.3 11.4.3 1.3.8 2.6 1.8 3.4 1 .8 3.201 1.8 4.901-1.1"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M593.757 148.3c-2.8 1.9-4.6 5-5.8 8.2-1.2 3.2-1.6 6.6-2 9.9-.2 1.2-.3 2.6.5 3.6.8 1.1 2.5 1.3 3.8.8 1.3-.5 3.1-2.9 3.8-4.1"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M591.756 140c6.5 2.9 12.3 7.4 16.8 12.9 1.8 2.2 2.3 5.3 1.4 8-2.5 7.6-5.6 15.1-9.5 21.9"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M601.556 154.5c-4 4-7.601 10.4-8.401 16.2-.2 1.1.4 2.2 1.5 2.7h.101c1.2.6 2.6.1 3.3-1 1.8-2.7 4.199-5 6.799-7"
        />
    </g>
);

export default SvgComponent;
