import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6432)"
            d="M413.765 316.165a19.473 19.473 0 01-12.965-4.939c-.274-.244-.527-.502-.78-.76a21.27 21.27 0 01-4.837-2.877 100.539 100.539 0 01-11.452-10.934c-3.308-3.646-6.477-7.513-9.69-11.826a74.845 74.845 0 01-7.948-12.869c-.674-1.386-1.331-2.783-1.989-4.18-1.433-3.044-2.915-6.192-4.549-9.177a27.533 27.533 0 0110.915-37.328 27.54 27.54 0 0113.183-3.384c10.064 0 20.14 5.56 24.967 14.38.024.042 6.126 11.679 6.914 13.001 2.267 3.782 4.161 7.534 6.33 11.221 3.905-4.393 7.495-7.809 11.682-11.974 1.021-1.007 2.03-2.018 3.04-3.029 2.058-2.062 4.186-4.193 6.357-6.231l4.755-4.55c1.029-.98 2.077-1.941 3.127-2.902.578-.53 1.155-1.061 1.73-1.592 6.235-5.711 11.143-9.993 15.92-13.888l1.617-1.319.015 2.086c.069 9.774 13.346 17.918 17.392 18.189l1.656.11-.872 1.413c-2.837 4.596-6.079 9.542-10.202 15.562-.45.648-.893 1.294-1.336 1.941-.805 1.173-1.61 2.347-2.435 3.503l-3.844 5.355c-1.705 2.425-3.513 4.827-5.262 7.15a734.698 734.698 0 00-2.579 3.444 286.049 286.049 0 01-16.727 20.368l-8.842 9.711-8.722 9.794a19.534 19.534 0 01-14.569 6.531z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6432"
                x1="366.895"
                x2="450.172"
                y1="236.112"
                y2="267.341"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
