import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6506)"
            d="M514.455 424.873l1.056 2.775c6.717-6.112 13.469-16.501 15.588-34.097-4.895 7.091-13.556 11.3-24.61 13.472a44.868 44.868 0 017.966 17.85z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6506)"
            d="M529.453 463.921l-14.812-38.862c-1.582-12.604-10.554-22.843-19.373-28.843-14.03-10.62-30.504-18.451-47.667-23.846a104.804 104.804 0 00-4.911-1.43 122.11 122.11 0 00-19.133-3.857c-21.109-2.564-42.301-.867-56.821 21.948-6.4 10.058-8.864 22.784-8.36 35.034a69.936 69.936 0 006.755 27.42c9.781 20.291 32.882 27.113 44.198 27.113 0 0-.131-.123-.356-.353.236.004.47.016.706.016h57.2c6.74 20.839 15.509 42.29 26.838 58.524 12.57 17.419 46.55 75.768 53.11 86.774 13.609-.379 34.211-10.789 38.596-17.2-17.735-45.677-37.79-97.195-55.97-142.438z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6506"
                x1="489.846"
                x2="533.436"
                y1="431.86"
                y2="401.932"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.04" stopColor="#888B8D"></stop>
                <stop offset="0.73" stopColor="#A2A5A5"></stop>
                <stop offset="0.954" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6506"
                x1="440.052"
                x2="536.992"
                y1="513.983"
                y2="463.236"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.04" stopColor="#888B8D"></stop>
                <stop offset="0.73" stopColor="#A2A5A5"></stop>
                <stop offset="0.954" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
