import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-right">
        <path
            fill={SKIN_COLOR[color]}
            d="m561.729 269.146-2.099-6.234c-.715-2.073-1.467-4.13-2.159-6.212-.654-2.095-1.409-4.152-1.975-6.282a77.221 77.221 0 0 1-.858-3.192 78.287 78.287 0 0 1-.779-3.221c-1.003-4.31-1.946-8.643-2.721-13.042a156.8 156.8 0 0 1-1.877-13.368 16.1 16.1 0 0 1 14.487-17.566 16.138 16.138 0 0 1 14.42 6.366c2.73 3.641 5.27 7.355 7.62 11.143 2.391 3.772 4.614 7.609 6.776 11.47.552.961 1.084 1.93 1.595 2.906a76.197 76.197 0 0 1 1.517 2.936c1.017 1.956 1.844 3.984 2.773 5.973.891 2.004 1.721 4.032 2.59 6.045l2.65 6.021a22.521 22.521 0 1 1-41.96 16.257Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M559.63 262.912c-.715-2.073-1.467-4.13-2.159-6.212-.654-2.095-1.409-4.152-1.975-6.282a77.221 77.221 0 0 1-.858-3.192 78.287 78.287 0 0 1-.779-3.221c-1.003-4.31-1.946-8.643-2.721-13.042a156.8 156.8 0 0 1-1.877-13.368 16.1 16.1 0 0 1 14.487-17.566 16.138 16.138 0 0 1 14.42 6.366c2.73 3.641 5.27 7.355 7.62 11.143 2.391 3.772 4.614 7.609 6.776 11.47.552.961 1.084 1.93 1.595 2.906a76.197 76.197 0 0 1 1.517 2.936c1.017 1.955 1.844 3.984 2.773 5.973.891 2.004 1.721 4.032 2.59 6.045l2.65 6.021a22.521 22.521 0 0 1-11.543 29.686 22.415 22.415 0 0 1-13.075 1.552"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M542.792 206.669a3.04 3.04 0 0 1 .826-2.92 3.04 3.04 0 0 1 4.143-.117 48.334 48.334 0 0 0 3.571 2.864l.096-2.108c-.152-5.025 2.941-9.496 5.843-13.602 2.903-4.105 5.875-8.711 5.432-13.719a12.335 12.335 0 0 1 .115-4.25l.093-.704a10.687 10.687 0 0 1-2.161-3.567 10.641 10.641 0 0 1-.623-3.332 3.582 3.582 0 0 1 2.802-3.606l.048-.011a3.496 3.496 0 0 1 3.516 1.228 15.63 15.63 0 0 0 5.617 4.476 5.034 5.034 0 0 1 2.752 3.373c1.011 4.204 2.197 8.44 3.119 12.627.545 2.474 3.212 4.212 5.1 5.901 1.888 1.688 3.809 3.641 4.202 6.144v.255c.516.265 1.017.56 1.499.884a5.214 5.214 0 0 1 1.931 6.199 86.596 86.596 0 0 1-5.467 11.927 35.335 35.335 0 0 1-11.177 12.375c-4.724 3.029-10.603 4.493-16.07 3.227a20.827 20.827 0 0 1-11.114-7.166 31.831 31.831 0 0 1-5.932-11.97 3.935 3.935 0 0 1-.064-2.309 3.93 3.93 0 0 1 1.247-1.944l.656-.155Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M564.686 173.667a10.7 10.7 0 0 1-3.936-5.121 10.612 10.612 0 0 1-.623-3.331 3.584 3.584 0 0 1 2.801-3.607l.049-.011a3.497 3.497 0 0 1 3.516 1.228 15.63 15.63 0 0 0 5.617 4.476 5.034 5.034 0 0 1 2.752 3.373c1.011 4.204 2.197 8.44 3.119 12.627.545 2.474 3.212 4.212 5.1 5.901 1.887 1.689 3.809 3.641 4.202 6.144m-24.58-18.28c.443 5.008-2.53 9.614-5.432 13.72-2.902 4.105-5.996 8.576-5.844 13.601m29.619 6.543c-2.951-2.288-9.151-4.842-12.46-6.115a3.16 3.16 0 0 0-3.618 1.002 3.165 3.165 0 0 0 .136 4.082 54.545 54.545 0 0 0 9.201 8.229m-13.306-7.143c-4.966-.643-10.372-4.83-13.239-7.356a3.044 3.044 0 0 0-4.143.116 3.054 3.054 0 0 0-.479 3.756c2.61 4.343 9.442 13.944 19.56 15.421"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M540.953 211.074a31.843 31.843 0 0 0 5.932 11.971 20.83 20.83 0 0 0 11.114 7.165c5.467 1.267 11.346-.197 16.07-3.226a35.352 35.352 0 0 0 11.177-12.375 86.532 86.532 0 0 0 5.467-11.927 5.21 5.21 0 0 0-1.931-6.199c-3.026-2.049-8.446-3.806-15.76-3.604a2.956 2.956 0 0 0-2.577 1.667l-.017.038a2.901 2.901 0 0 0 1.109 3.769c3.594 2.135 7 4.341 10.692 6.44"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m522.607 167.447-.327-.328a33.336 33.336 0 0 0-.484-9.556 5.366 5.366 0 0 0-1.282-2.717 4.504 4.504 0 0 0-2.747-1.392l.051-.362a11.608 11.608 0 0 0-2.628-5.81c-3.09-3.498-8.286-4.333-12.915-3.74a2.887 2.887 0 0 0-2.246 1.466l-.069.568a2.914 2.914 0 0 0-2.613 1.15c-3.117 4.08-8.088 6.496-12.263 9.618a38.356 38.356 0 0 0-14.885 25.531c-2.801 19.801-.073 32.117 14.39 28.553l22.476-7.434c3.535-1.169 7.213-2.425 9.823-5.081a20.37 20.37 0 0 0 4.289-7.838 72.592 72.592 0 0 0 3.561-16.06 8.352 8.352 0 0 0-2.131-6.568Zm-19.504-4.026a15.409 15.409 0 0 1-10.088 2.881l.078-1.059c.362-3.693 3.045-6.666 5.451-9.49a32 32 0 0 0 2.056-2.642l.721.393a7.05 7.05 0 0 1 1.955 2.557l2.062 4.626.235.439a14.76 14.76 0 0 1-2.47 2.295Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M470.195 181.875a38.356 38.356 0 0 1 14.885-25.531c4.175-3.123 9.146-5.539 12.263-9.618a2.904 2.904 0 0 1 2.613-1.149l.046.004a2.929 2.929 0 0 1 2.5 3.772 19.139 19.139 0 0 1-3.962 6.4c-2.406 2.824-5.089 5.798-5.451 9.49"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M490.195 165.833a15.384 15.384 0 0 0 12.906-2.411 15.379 15.379 0 0 0 6.174-11.586"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M502.273 143.542c4.63-.593 9.826.242 12.915 3.74a13.163 13.163 0 0 1 2.806 9.596 39.08 39.08 0 0 1-2.445 9.936m-10.807-13.845a9.296 9.296 0 0 1 3.404 3.19m-4.873-.097 2.063 4.626m16.458-3.126a33.765 33.765 0 0 1-3.423 22.499"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M524.734 174.016a72.603 72.603 0 0 1-3.56 16.06 20.359 20.359 0 0 1-4.289 7.837c-2.61 2.656-6.288 3.912-9.823 5.081l-22.476 7.434"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M407.536 207.206c.834 4.678 2.126 9.271 3.213 13.902a63.324 63.324 0 0 1 1.682 14.185c.117 4.862-.025 9.727-.426 14.575a87.58 87.58 0 0 1-2.456 14.95 24.92 24.92 0 0 1-20.795 18.271 24.89 24.89 0 0 1-9.7-.626 24.87 24.87 0 0 1-13.754-9.46 87.63 87.63 0 0 1-7.642-13.083 136.858 136.858 0 0 1-5.61-13.458 63.297 63.297 0 0 1-3.504-13.847c-.641-4.714-1.079-9.465-1.974-14.131a31.003 31.003 0 0 1 47.847-31.496 30.999 30.999 0 0 1 13.046 19.819l.073.399Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m374.863 250.202 10.966-8.438 10.926-8.488c7.3-5.64 14.764-11.073 22.325-16.387 3.801-2.631 7.565-5.308 11.406-7.89 3.82-2.608 7.646-5.208 11.536-7.729 3.879-2.534 7.727-5.107 11.632-7.609 3.947-2.451 7.831-4.98 11.842-7.35a15.662 15.662 0 0 1 21.449 5.516 15.68 15.68 0 0 1-2.024 18.643c-3.176 3.409-6.48 6.659-9.721 9.987-3.282 3.276-6.622 6.482-9.93 9.727-3.297 3.258-6.657 6.437-10.025 9.607-3.346 3.198-6.77 6.299-10.155 9.447-6.814 6.243-13.723 12.368-20.798 18.286l-10.636 8.848-10.596 8.899a22.488 22.488 0 0 1-16.425 5.186 22.505 22.505 0 0 1-12.521-39.654c.224-.187.52-.423.749-.601Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m407.465 206.809.073.399c.733 4.114 1.82 8.163 2.812 12.229m-45.05 53.567a87.681 87.681 0 0 1-7.642-13.083 136.858 136.858 0 0 1-5.61-13.458 63.291 63.291 0 0 1-3.504-13.848c-.641-4.713-1.079-9.464-1.974-14.131m138.351-8.015c-3.176 3.408-6.48 6.658-9.721 9.987-3.282 3.276-6.622 6.482-9.929 9.727-3.298 3.257-6.658 6.437-10.026 9.607-3.346 3.198-6.769 6.299-10.155 9.447-6.814 6.243-13.723 12.367-20.798 18.286l-10.636 8.848-10.595 8.899a22.522 22.522 0 0 1-16.426 5.186 22.504 22.504 0 0 1-15.281-7.947 22.426 22.426 0 0 1-4.28-7.846m18.754-32.902 10.926-8.487c7.3-5.64 14.764-11.073 22.325-16.387 3.802-2.631 7.565-5.309 11.406-7.89 3.82-2.608 7.647-5.208 11.536-7.729 3.88-2.534 7.728-5.107 11.632-7.609 3.947-2.451 7.831-4.98 11.842-7.35"
        />
    </g>
);

export default SvgComponent;
