import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.495 279.902c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.787-9.422-18.228-14.919-23.949a47.595 47.595 0 0 0-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a22.002 22.002 0 0 1-8.677 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.283-4.644-14.812-9.445-22.912-12.429a32.686 32.686 0 0 0-10.801-1.712 31.517 31.517 0 0 0-4.396.293 1.194 1.194 0 0 0-.448.161 62.75 62.75 0 0 0-26.518 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-27.936 53.587-27.936 71.668 0 34.797 1.58 70.306 22.466 115.37 35.183 75.911 123.115 127.936 176.852 127.936 12-152.929-10.981-209.859-17.145-238.666a77.093 77.093 0 0 1-1.231-24.424c2.036-18.848 3.521-56.173-11.03-86.566-1.14-2.38-1.843-4.811-2.686-7.11 8.094-3.298 18.171-9.259 20.658-19.014 1.943-7.614-1.083-16.22-8.991-25.577Z"
            fill="url(#female-34-25-dress-2-a)"
        />
        <path
            d="M403.258 205.391c8.1 2.984 15.629 7.785 22.911 12.428 10.812 6.893 21.019 13.4 31.94 13.4a20.755 20.755 0 0 0 8.678-1.436c10.512-4.721 10.92-12.99 8.364-19.636a11.584 11.584 0 0 0-1.207-2.727"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-2-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0 0 12.049-48.824l-6.917-10.636"
                fill="url(#female-34-25-dress-2-c)"
            />
        </g>
        <path
            d="M462.617 231.109a239.496 239.496 0 0 0 16.766 23.122"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-2-d)">
            <path
                d="M371.143 382.522c10.154 27.674 36.965 46.752 60.768 62.166 26.061 16.876 54.836 29.523 85.557 34.747 5.584.95 11.308-1.48 12.916-7.333 1.386-5.044-1.712-11.96-7.334-12.917-28.075-4.774-53.96-15.731-77.926-30.953a242.323 242.323 0 0 1-31.913-23.501c-8.601-7.691-17.814-16.88-21.818-27.791-1.952-5.322-7.123-8.926-12.916-7.334-5.126 1.409-9.297 7.566-7.334 12.916Z"
                fill="url(#female-34-25-dress-2-e)"
            />
            <path
                d="M364.784 393.796c7.041 28.184 24.314 52.841 43.011 74.562 18.207 21.151 38.751 41.011 62.299 56.148a167.276 167.276 0 0 0 43.704 20.039c13.005 3.771 18.558-16.487 5.583-20.25-31.983-9.274-58.275-30.298-81.155-53.815-22.561-23.188-45.184-50.211-53.192-82.267-3.274-13.104-23.531-7.552-20.25 5.583Z"
                fill="url(#female-34-25-dress-2-f)"
            />
            <path
                d="M383.836 382.049a277.38 277.38 0 0 0 58.099 27.28c21.812 7.184 44.54 9.259 67.206 12.085 5.637.702 10.5-5.329 10.5-10.5 0-6.259-4.846-9.795-10.5-10.5-20.85-2.6-41.559-4.726-61.623-11.335a253.273 253.273 0 0 1-53.083-25.163c-11.551-7.085-22.113 11.07-10.599 18.133Z"
                fill="url(#female-34-25-dress-2-g)"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-dress-2-a"
                x1={307.862}
                y1={459.35}
                x2={570.285}
                y2={431.647}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.192} stopColor="#EF0025"/>
                <stop offset={0.918} stopColor="#FFB33A"/>
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-2-e"
                x1={381.431}
                y1={425.395}
                x2={519.707}
                y2={423.466}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EF0025"/>
                <stop offset={0.264} stopColor="#EF0025" stopOpacity={0.712}/>
                <stop offset={0.748} stopColor="#EF0025" stopOpacity={0.204}/>
                <stop offset={0.961} stopColor="#EF0025" stopOpacity={0}/>
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-2-f"
                x1={375.438}
                y1={463.861}
                x2={515.721}
                y2={461.903}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EF0025"/>
                <stop offset={0.264} stopColor="#EF0025" stopOpacity={0.712}/>
                <stop offset={0.748} stopColor="#EF0025" stopOpacity={0.204}/>
                <stop offset={0.961} stopColor="#EF0025" stopOpacity={0}/>
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-2-g"
                x1={389.143}
                y1={392.731}
                x2={508.934}
                y2={391.059}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#EF0025"/>
                <stop offset={0.264} stopColor="#EF0025" stopOpacity={0.712}/>
                <stop offset={0.748} stopColor="#EF0025" stopOpacity={0.204}/>
                <stop offset={0.961} stopColor="#EF0025" stopOpacity={0}/>
            </linearGradient>
            <filter
                id="female-34-25-dress-2-b"
                x={402.73}
                y={251.023}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7168"
                />
            </filter>
            <filter
                id="female-34-25-dress-2-d"
                x={356.426}
                y={354.305}
                width={182.285}
                height={198.703}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix"/>
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7168"
                />
            </filter>
            <radialGradient
                id="female-34-25-dress-2-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
            >
                <stop offset={0.433} stopColor="#EF0025" stopOpacity={0}/>
                <stop offset={0.602} stopColor="#EF0025" stopOpacity={0.366}/>
                <stop offset={0.771} stopColor="#EF0025" stopOpacity={0.705}/>
                <stop offset={0.89} stopColor="#EF0025" stopOpacity={0.918}/>
                <stop offset={0.949} stopColor="#EF0025"/>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
