import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={257}
            height={114}
            fill="none"
            id="object-food-honeydew"
            {...attributes}
        >
            <path
                fill="url(#object-food-honeydew-a)"
                d="M209.858 1c-17.513 26.362-47.47 43.742-81.49 43.742-34.02 0-63.978-17.38-81.491-43.742H1c8.238 63.001 62.12 111.646 127.368 111.646 65.248 0 119.129-48.645 127.367-111.646h-45.877Z"
            />
            <path
                stroke="#FFB33B"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1 1c8.238 63.001 62.12 111.646 127.368 111.646 65.248 0 119.129-48.645 127.367-111.646"
            />
            <defs>
                <linearGradient
                    id="object-food-honeydew-a"
                    x1={128.367}
                    x2={128.367}
                    y1={1.471}
                    y2={106.283}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#C2E4BA" />
                    <stop offset={0.999} stopColor="#7CCC6C" />
                </linearGradient>
            </defs>
        </svg>
    );
}
