import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9612)"
            d="M494.776 223.298a109.595 109.595 0 00-20.026-15.329 31.047 31.047 0 012.394 13.894 66.035 66.035 0 01-1.451 10.763l2.169 4.754a1.01 1.01 0 00.562.523 1.02 1.02 0 00.768-.03c2.597-1.201 15.6-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9612)"
            d="M487.953 220.104l-.14-.046a27.499 27.499 0 00-26.492 46.99 27.519 27.519 0 009.347 5.27c4.768 1.564 9.562 3.351 14.197 5.08l.822.304 14.556-53.823c-4.08-1.189-8.238-2.421-12.29-3.775z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9612"
                x1="454.778"
                x2="495.116"
                y1="278.962"
                y2="196.769"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.034" stopColor="#ABADAD"></stop>
                <stop offset="0.967" stopColor="#888B8D"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9612"
                x1="451.726"
                x2="576.539"
                y1="248.191"
                y2="248.191"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
