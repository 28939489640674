import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6924)"
            d="M478.237 236.718c-.166-4.364-.928-19.194-3.775-26.828l-1.017-2.726 2.478 1.524a102.454 102.454 0 0118.859 14.537 2.705 2.705 0 01.823 2.483c-.788 4.558-13.419 10.693-15.944 11.877l-1.367.641-.057-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6924)"
            d="M524.736 343.438a19.603 19.603 0 01-15.48-7.691c-.228-.2-.454-.403-.674-.615a216.126 216.126 0 01-15.74-17.397c-4.676-5.704-9.263-11.704-14.026-18.344a171.81 171.81 0 01-12.303-19.416 769.036 769.036 0 01-3.279-6.088c-2.464-4.598-5.011-9.353-7.685-13.922a27.508 27.508 0 0123.709-41.395 28.014 28.014 0 0123.979 13.642l.069.121c2.653 4.585 5.31 9.124 8.092 13.541 1.227 1.949 2.45 3.9 3.668 5.855a171.64 171.64 0 0110.802 20.294c3.409 7.398 6.358 14.351 9.012 21.249a297.838 297.838 0 013.486 9.6c.846-.099 1.693-.196 2.541-.292a131.676 131.676 0 0117.95-.733c.98.031 1.952.052 2.925.073 1.985.042 4.039.086 6.077.226l4.475.244c.981.056 1.962.141 2.944.226.525.045 1.051.091 1.575.132 3.475.3 6.353.605 9.065.958l1.387.18-.619 1.254c-3.84 7.773-1.217 21.425 2.274 27.409l.641 1.097-1.216.364c-2.63.79-5.421 1.556-8.783 2.409-.52.128-1.033.258-1.546.388-.953.242-1.906.484-2.862.698l-4.393.968c-1.976.465-3.998.841-5.952 1.204-.955.177-1.909.355-2.863.543a132.559 132.559 0 01-17.837 2.18c-1.79.094-3.581.173-5.372.253-4.068.182-8.276.369-12.39.716a19.8 19.8 0 01-1.649.069h-.002z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6924"
                x1="600.992"
                x2="468.269"
                y1="341.812"
                y2="212.993"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6924"
                x1="586.341"
                x2="453.618"
                y1="356.906"
                y2="228.087"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
