import {
    IBodyClothes,
    ILegs,
    TBodyPosition,
    TGender,
    THairColor,
    TLegsSkins,
    TSkinColor,
} from 'interfaces';

import rootSettings from 'components/parts';
import { decodeHash } from 'utils/hashUtil';

export const enum Types {
    skin = 10,
    emotion,
    nose,
    hairStyle,
    hairStyleColor,
    position,
    bothArmsPosition,
    cyclingArmsPosition1,
    cyclingArmsPosition2,
    settingsSingleArm,
    settingsLegsOpt2,
    leftArmPosition,
    rightArmPosition,
    bodyPosition,
    legsClothes,
    bodyClothes,
    shoes,
    gender,
}

export type THashState = {
    [Types.skin]: TSkinColor;
    [Types.emotion]: number;
    [Types.nose]: number;
    [Types.hairStyle]: number;
    [Types.hairStyleColor]: THairColor;
    [Types.position]: keyof TLegsSkins;
    [Types.bothArmsPosition]: string;
    [Types.cyclingArmsPosition1]: string;
    [Types.cyclingArmsPosition2]: string;
    [Types.settingsSingleArm]: boolean;
    [Types.settingsLegsOpt2]: 0 | 1;
    [Types.leftArmPosition]: string;
    [Types.rightArmPosition]: string;
    [Types.bodyPosition]: TBodyPosition;
    [Types.legsClothes]: string | null;
    [Types.bodyClothes]: string;
    [Types.shoes]: string | null;
    [Types.gender]: TGender;
}

type ActionMap<M extends { [index: string]: any }> = {
    [Key in keyof M]: { type: Key; value: M[Key] }
}

type reducerActions = ActionMap<THashState>[keyof ActionMap<THashState>];

function hashReducer(state: THashState, action: reducerActions): THashState {
    const {
        BODY_CLOTHES,
        LEGS = {},
        SHOES,
    } = rootSettings[state[Types.gender]];

    const bodyClothes: IBodyClothes = BODY_CLOTHES[state[Types.bodyClothes]];

    if (action.value === state[action.type]) return state;

    switch (+action.type) {
        case Types.skin:
        case Types.emotion:
        case Types.nose:
        case Types.hairStyle:
        case Types.hairStyleColor:
        case Types.cyclingArmsPosition1:
        case Types.cyclingArmsPosition2:
            return {
                ...state,
                [action.type]: action.value,
            };
        case Types.legsClothes:
            return {
                ...state,
                ...bodyClothes?.disableLegsClothes
                    ? { [Types.legsClothes]: null }
                    : { [action.type]: action.value }
            };
        case Types.shoes:
            return {
                ...state,
                [Types.settingsLegsOpt2]: SHOES[action.value as keyof ILegs]?.skinOpt2 || 0,
                [action.type]: action.value,
            };
        case Types.bodyClothes:
            return {
                ...state,
                ...BODY_CLOTHES[action.value as keyof ILegs]?.disableLegsClothes
                    ? { [Types.legsClothes]: null }
                    : !state[Types.legsClothes]
                        ? { [Types.legsClothes]: Object.keys(LEGS)[0] }
                        : {},
                [action.type]: action.value,
            };
        case Types.position:
            return {
                ...state,
                [action.type]: action.value,
                [Types.bodyPosition]:
                    (action.value === 'cycling1' || action.value === 'cycling2')
                        ? action.value
                        : action.value === 'sitting1' || action.value === 'sitting2'
                            ? 'sitting'
                            : 'normal',
                ...state[Types.legsClothes] === null
                    && bodyClothes?.[action.value as 'normal' | 'sitting' | 'cycling1' | 'cycling2']
                    || state[Types.legsClothes] && LEGS[state[Types.legsClothes]]?.[action.value as keyof ILegs]
                    ? {}
                    : {
                        [Types.legsClothes]:
                            Object.keys(LEGS || {})
                                .filter(id => LEGS[id][action.value as keyof ILegs] && id)[0]
                    },
                ...state[Types.shoes]
                    ? !SHOES[state[Types.shoes]]?.[action.value as keyof ILegs]
                        ? {
                            [Types.shoes]:
                                Object.keys(SHOES).filter(id =>
                                    SHOES[id][action.value as keyof ILegs] && id
                                )[0] || null,
                            [Types.settingsLegsOpt2]: SHOES[action.value as keyof ILegs]?.skinOpt2 || 0
                        }
                        : {}
                    : {
                        [Types.shoes]:
                            Object.keys(SHOES).filter(id => SHOES[id][action.value as keyof ILegs] && id)[0] || null
                    },
                ...(action.value === 'cycling1' || action.value === 'cycling2')
                    ? !bodyClothes?.[action.value]?.bothArms
                        ? {
                            [Types.bodyClothes]: Object.keys(BODY_CLOTHES)
                                .filter(id =>
                                    BODY_CLOTHES[id][action.value as 'cycling1' | 'cycling2']?.bothArms && id
                                )[0]
                        }
                        : {}
                    : action.value === 'sitting1' || action.value === 'sitting2'
                        ? !bodyClothes.sitting
                            ? {
                                [Types.bodyClothes]:
                                    (Object.keys(BODY_CLOTHES) || []).filter(id => state[Types.settingsSingleArm]
                                        ? BODY_CLOTHES[id].sitting?.leftArm && id
                                        : BODY_CLOTHES[id].sitting?.bothArms && id)[0]
                            }
                            : {}
                        : !bodyClothes?.normal
                            ? {
                                [Types.bodyClothes]:
                                    (Object.keys(BODY_CLOTHES) || []).filter(id => state[Types.settingsSingleArm]
                                        ? BODY_CLOTHES[id].normal?.leftArm && id
                                        : BODY_CLOTHES[id].normal?.bothArms && id)[0]
                            }
                            : {},
            };
        case Types.bothArmsPosition:
            return {
                ...state,
                [Types.settingsSingleArm]: false,
                ... bodyClothes?.allowWithoutSleeves
                    || bodyClothes[state[Types.bodyPosition]]?.bothArms?.[action.value as string]
                    ? {}
                    : {
                        [Types.bodyClothes]:
                            Object.keys(BODY_CLOTHES)
                                .filter(id => BODY_CLOTHES[id][state[Types.bodyPosition]]?.bothArms && id)[0]
                    },
                [action.type]: action.value,
            };
        case Types.leftArmPosition:
        case Types.rightArmPosition:
            return {
                ...state,
                [Types.settingsSingleArm]: true,
                [action.type]: action.value,
            };
        case 0:
            return {
                ...state,
                ...decodeHash(action.value as string)
            };
        default:
            break;
    }

    if (process.env.NODE_ENV === 'development') {
        throw Error('Unknown action: ' + action.type + '. Value: ' + action.value);
    } else {
        return state;
    }
}

export default hashReducer;
