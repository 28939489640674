import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-4-walk-1">
            <path
                fill="#001489"
                d="M414.405 848.632c-.349 0-.698-.036-1.04-.106-7.995-1.651-21.977-4.636-25.793-6.075a70.732 70.732 0 0 1-18.451-10.137 41.161 41.161 0 0 0-11.055-6.097l-19.068-7.051a10 10 0 0 1-5.735-5.464 9.98 9.98 0 0 1 .041-7.921c6.863-15.676 11.057-26.032 11.81-29.16l2.102-10.846 1.404-.716 30.939 14.385.528 1.219-2.434 7.42c1.482 3.371 13.804 30.637 29.206 42.57 3.795 2.94 8.444 6.832 10.878 8.891a5.153 5.153 0 0 1 1.507 5.707 5.149 5.149 0 0 1-4.839 3.381Z"
            />
            <mask
                id="man-34-25-shoes-4-walk-1-a"
                width="89"
                height="58"
                x="331"
                y="792"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M413.728 849.062a5.156 5.156 0 0 1-.297-.055c-8.07-1.742-22.184-4.888-26.035-6.38a71.617 71.617 0 0 1-18.571-10.423 41.576 41.576 0 0 0-11.127-6.269l-19.222-7.308a10.106 10.106 0 0 1-5.751-5.579 10.122 10.122 0 0 1 .113-8.012c1.874-4.176 3.686-8.274 5.238-11.85a1.013 1.013 0 0 1 1.258-.553 66.18 66.18 0 0 1 10.96 5.385c.614.349 1.226.699 1.839 1.044 5.543 3.118 11.52 5.914 17.642 5.254 2.372-.343 4.7-.941 6.944-1.783 1.136-.378 2.31-.769 3.491-1.097a24.595 24.595 0 0 1 3.335-.685 1.01 1.01 0 0 1 1.023.521c5.126 9.465 13.181 22.283 22.447 29.597 3.812 3.009 8.479 6.989 10.922 9.093a5.212 5.212 0 0 1-.946 8.545 5.212 5.212 0 0 1-3.263.555Z"
                />
            </mask>
            <g mask="url(#man-34-25-shoes-4-walk-1-a)">
                <path
                    fill="#009CDE"
                    d="M413.728 849.062a5.156 5.156 0 0 1-.297-.055c-8.07-1.742-22.184-4.888-26.035-6.38a71.617 71.617 0 0 1-18.571-10.423 41.576 41.576 0 0 0-11.127-6.269l-19.222-7.308a10.106 10.106 0 0 1-5.751-5.579 10.122 10.122 0 0 1 .113-8.012c1.874-4.176 3.686-8.274 5.238-11.85a1.013 1.013 0 0 1 1.258-.553 66.18 66.18 0 0 1 10.96 5.385c.614.349 1.226.699 1.839 1.044 5.543 3.118 11.52 5.914 17.642 5.254 2.372-.343 4.7-.941 6.944-1.783 1.136-.378 2.31-.769 3.491-1.097a24.595 24.595 0 0 1 3.335-.685 1.01 1.01 0 0 1 1.023.521c5.126 9.465 13.181 22.283 22.447 29.597 3.812 3.009 8.479 6.989 10.922 9.093a5.212 5.212 0 0 1-.946 8.545 5.212 5.212 0 0 1-3.263.555Z"
                />
                <path
                    fill="#001489"
                    d="M350.695 838.375c-.313-10.453 11.066-19.134 20.122-21.877a64.482 64.482 0 0 1 18.005-2.695c5.53-.033 11.774 1.479 16.893-1.018 2.015-.983 1.085-3.551-.554-4.241-3.553-1.497-7.894-1.222-11.719-1.402a73.256 73.256 0 0 0-12.806.393c-6.689.846-13.585 1.953-19.516 5.368-8.629 4.968-19.764 15.913-14.618 26.608.98 2.036 4.257 1.017 4.193-1.136Z"
                />
                <path
                    fill="#001489"
                    d="M365.38 839.424c1.037-8.149 11-12.902 17.844-15.459a44.314 44.314 0 0 1 14.653-3.011c4.88-.088 9.69 1.281 14.5.1 2.592-.637 2.135-3.985 0-4.814-11.193-4.344-25.063-1.859-35.617 3.091-8.294 3.89-15.861 10.316-16.091 20.093-.074 3.115 4.34 2.914 4.711 0Z"
                />
                <path
                    fill="#001489"
                    d="M378.029 843.445c2.38-7.61 9.082-10.437 16.269-12.326a63.366 63.366 0 0 1 12.882-2.022c3.723-.19 7.409.138 11.073-.676 2.914-.648 2.271-4.367 0-5.289-8.834-3.584-22.137-.314-30.634 3.067-7.461 2.968-12.638 7.897-13.861 16.069-.413 2.755 3.398 3.97 4.271 1.177Z"
                />
            </g>
            <path
                fill="#001489"
                d="M444.701 860.229a9.993 9.993 0 0 1-9.983-10.578c.999-17.083.932-27.863.553-31.058l-3.416-12.741 37.573.162.5 11.399c.151.167 15.274 16.703 23.563 22.284 4.065 2.737 22.163 8.767 27.613 10.55a5.122 5.122 0 0 1 3.458 5.672 5.12 5.12 0 0 1-5.055 4.31h-74.806Z"
            />
            <path
                fill="#009CDE"
                d="M524.79 858.648c-7.978 1.603-79.855 1.621-79.855 1.621a10.185 10.185 0 0 1-10.454-10.282c.038-4.579.051-9.061.036-12.96a.997.997 0 0 1 .914-1.003c10.495-.833 22.435 4.162 32.055-1.235 3.018-1.725 5.514-4.504 8.266-6.755a23.784 23.784 0 0 1 2.734-1.943.992.992 0 0 1 1.129.076c8.362 6.689 20.69 15.309 31.932 18.389 4.625 1.268 10.405 3.091 13.439 4.066 4.843 1.535 4.779 8.742-.196 10.026Z"
            />
            <path
                fill="#001489"
                d="M476.106 842.894a66.724 66.724 0 0 1 15.967-7.652l.058-.017a139.343 139.343 0 0 1-6.904-4.76c-.214.086-.432.16-.644.249-6.479 2.721-13.196 5.969-18.282 10.925-4.453 4.34-9.273 11.586-10.418 18.602 2.109-.008 4.452-.019 6.967-.033 2.011-7.068 7.731-13.705 13.256-17.314Z"
            />
            <path
                fill="#001489"
                d="M491.911 844.954a43.95 43.95 0 0 1 8.735-4.788 97.839 97.839 0 0 1-7.346-4.205c-10.488 5.32-19.714 14.071-21.14 24.186 1.92-.015 3.883-.032 5.872-.051 2.423-6.267 8.973-11.711 13.879-15.142ZM503.309 849.563a64.631 64.631 0 0 1 9.404-4.684c-.395-.111-.785-.22-1.166-.324a53.588 53.588 0 0 1-8.324-3.129 76.74 76.74 0 0 0-7.73 5.258c-4.885 3.774-8.178 8.043-9.255 13.323 1.976-.024 3.947-.051 5.897-.081 2.263-4.432 6.58-7.592 11.174-10.363Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M527.839 856.375a5.064 5.064 0 0 1-3.337 2.337c-7.963 1.574-79.567 1.556-79.567 1.556a9.863 9.863 0 0 1-7.392-2.872"
            />
        </g>
    );
}
