import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m449.355 831.797 5.423 32.229 7.861.144s.542-5.86.794-10.212c.175-3.016 1.326-5.694 4.696-5.238"
                fill="#004F71"
            />
            <path
                d="m454.777 864.022 8.02-.022"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M507.691 877.094a38.873 38.873 0 0 1-11.027-1.6l-5.267-1.56a24.357 24.357 0 0 1-13.72-10.417l-5.548-8.853a22.359 22.359 0 0 0-9.498-8.389l-5.552-2.587a13.9 13.9 0 0 1-7.286-17.143l6.655-19.17 43.55 6.506 6.59 38.065a8.542 8.542 0 0 0 3.871 5.762l11.924 7.49a6.427 6.427 0 0 1-3.363 11.906l-11.329-.01Z"
                fill="#004F71"
            />
            <path
                d="M523.151 875.589a6.413 6.413 0 0 1-4.133 1.517l-11.329-.01a38.837 38.837 0 0 1-11.026-1.601l-5.267-1.56a24.33 24.33 0 0 1-13.72-10.417l-5.546-8.848a22.324 22.324 0 0 0-9.5-8.393l-5.553-2.588a15.92 15.92 0 0 1-7.276-8.041"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m398.93 821.898-3.016 13.645a19.663 19.663 0 0 0 6.071 18.375 28.996 28.996 0 0 1 3.725 4.218l8.739 12.08a14.056 14.056 0 0 0 11.404 5.649h25.219a7.806 7.806 0 0 0 8.05-7.271 7.204 7.204 0 0 0-2.241-5.411l-13.292-11.578a13.1 13.1 0 0 1-3.316-5.211l-10.305-25.824"
                fill="#FCB47F"
            />
            <path
                d="m395.912 843.219 8.096 32.58 9.112.302 1.349-10.954"
                fill="#004F71"
            />
            <path
                d="M425.854 876.864a15.032 15.032 0 0 1-12.214-6.063l-8.843-12.225a30.308 30.308 0 0 0-3.689-4.112 20.617 20.617 0 0 1-6.173-19.123l2.205-16.653h40.037s4.916 22.691 6.519 29.727a7.68 7.68 0 0 0 2.447 4.088l11.395 9.925a8.284 8.284 0 0 1 2.584 6.195 8.767 8.767 0 0 1-9.049 8.24l-25.219.001Z"
                fill="url(#female-34-25-shoes-13-stand-1-a)"
            />
            <path
                d="M457.577 874.31a9.33 9.33 0 0 1-6.503 2.561h-25.22a15.04 15.04 0 0 1-12.214-6.063s-15.174-19.201-16.206-20.933M404.008 875.797h9.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-13-stand-1-a"
                    x1={394.523}
                    y1={847.776}
                    x2={460.126}
                    y2={847.776}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
