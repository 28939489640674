import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M476.744 231.748a64.86 64.86 0 0 0 1.406-10.604 33.85 33.85 0 0 0-1.403-11.519 105.903 105.903 0 0 1 17.336 13.629 1.706 1.706 0 0 1 .535 1.598c-.522 3.029-8.331 7.885-15.838 11.354l-2.036-4.458Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-9-left-a)"
        />
        <path
            d="M586.122 300.462c-2.391-1.756-5.027-3.522-8.064-5.403-.277-.168-.553-.339-.83-.51l-.215-.133a62.835 62.835 0 0 0-2.043-1.231l-3.076-1.739c-1.403-.827-2.845-1.565-4.261-2.289-.682-.349-1.364-.698-2.051-1.062a82.892 82.892 0 0 0-13.155-5.469 524.872 524.872 0 0 0-4.047-1.251 733.152 733.152 0 0 1-3.563-1.101c-2.17-3.891-4.68-7.91-7.895-12.64-3.464-5.054-7.204-10.061-11.432-15.306a117.047 117.047 0 0 0-12.936-13.94 491.726 491.726 0 0 0-4.067-3.691l-.195-.176c-3.17-2.861-6.448-5.819-9.525-8.861l-.121-.121a27.602 27.602 0 0 0-19.388-7.898A27.549 27.549 0 0 0 459.404 226a27.58 27.58 0 0 0-7.896 19.717 27.56 27.56 0 0 0 8.357 19.526c3.113 3.04 6.143 6.284 9.072 9.42a613.688 613.688 0 0 0 3.889 4.138c4.366 4.608 9.1 8.851 14.155 12.689 5.32 4.14 10.395 7.796 15.515 11.177a151.347 151.347 0 0 0 16.941 9.813c.575.274 1.172.523 1.826.76a19.771 19.771 0 0 0 4.582 2.345c3.034 1.07 6.079 2.32 9.061 3.543 1.308.536 2.615 1.072 3.928 1.596a82.825 82.825 0 0 0 13.655 4.095c.747.15 1.492.311 2.236.472 1.539.333 3.13.677 4.74.925l3.509.606a64.12 64.12 0 0 0 2.342.336l.213.029c.34.045.681.09 1.03.14 1.724.227 3.555.432 5.443.611l1.608.15-.257-1.597a24.958 24.958 0 0 1 2.127-12.632 23.115 23.115 0 0 1 10.408-11.248l1.967-.876-1.733-1.273Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-9-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-9-left-a"
                x1={455.608}
                y1={209.703}
                x2={634.523}
                y2={316.402}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-9-left-b"
                x1={443.62}
                y1={229.805}
                x2={622.536}
                y2={336.503}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
