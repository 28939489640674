import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={8}>
        <path
            d="M487.63 221.045c6.836 2.284 13.803 4.178 20.713 6.24a152.365 152.365 0 0 1 20.257 7.618c6.615 2.956 13.164 6.11 19.621 9.54a191.905 191.905 0 0 1 19.011 11.383 19.287 19.287 0 0 1 4.854 26.842 19.49 19.49 0 0 1-16.483 8.283 191.768 191.768 0 0 1-22.049-2.209c-7.228-1.102-14.364-2.479-21.436-4.054a152.35 152.35 0 0 1-20.801-5.975c-6.776-2.469-13.497-5.107-20.346-7.354a26.488 26.488 0 0 1-15.384-13.216 26.477 26.477 0 0 1-1.534-20.224 26.496 26.496 0 0 1 23.175-18.163c3.47-.264 6.958.159 10.265 1.244l.137.045Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M542.465 271.497c.205-4.902.077-9.817.092-14.727.026-4.92.457-9.83 1.289-14.68.433-2.438.763-4.88 1.306-7.314l.75-3.654c.254-1.218.576-2.433.862-3.649a110.267 110.267 0 0 1 4.47-14.558 12.727 12.727 0 0 1 12.319-8.023 12.721 12.721 0 0 1 11.663 8.95 110.386 110.386 0 0 1 3.334 14.859c.19 1.235.418 2.471.577 3.705l.466 3.701c.353 2.468.494 4.929.738 7.393.455 4.9.506 9.828.151 14.736-.363 4.896-.871 9.787-1.045 14.69a18.497 18.497 0 0 1-32.024 11.956 18.5 18.5 0 0 1-4.952-13.265l.004-.12Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M555.603 290.951a191.751 191.751 0 0 1-22.048-2.209c-7.228-1.102-14.365-2.479-21.437-4.054a152.35 152.35 0 0 1-20.801-5.975c-6.776-2.469-13.496-5.107-20.345-7.354a26.507 26.507 0 0 1-9.008-5.077 26.517 26.517 0 0 1-9.156-18.098 26.512 26.512 0 0 1 14.461-25.65A26.5 26.5 0 0 1 487.494 221l.136.045c6.836 2.284 13.803 4.178 20.713 6.24a152.365 152.365 0 0 1 20.257 7.618 353.208 353.208 0 0 1 12.523 5.894"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m542.461 271.62.004-.12c.205-4.902.077-9.817.093-14.727.025-4.92.456-9.83 1.288-14.68.433-2.438.763-4.88 1.306-7.314l.75-3.654c.254-1.217.576-2.433.862-3.649a91.01 91.01 0 0 1 3.968-13.257M575.216 213.844a110.386 110.386 0 0 1 3.334 14.859c.19 1.235.418 2.471.577 3.705l.466 3.7c.353 2.469.494 4.929.738 7.394.455 4.899.506 9.828.151 14.736-.363 4.896-.871 9.786-1.044 14.69a18.49 18.49 0 0 1-5.878 12.881 18.511 18.511 0 0 1-13.265 4.953"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M581.941 186.824a38.456 38.456 0 0 0-11.052-9.13 18.062 18.062 0 0 0-5.539-1.943l-.08-.014-.632-12.347c-.137-2.676-.176-5.302-.268-7.957a2.662 2.662 0 0 0-2.273-2.562l-.044-.006a2.65 2.65 0 0 0-2.912 2.011c-1.128 4.39-1.184 9.042-1.299 13.604-.135 5.373-.41 10.901-2.611 15.788.059-.138-1.132 2.959-1.073 2.82l-1.627 3.566a7.377 7.377 0 0 1-.312-3.164c.142-1.497.558-2.957.714-4.453a8.232 8.232 0 0 0-.1-2.578 2.422 2.422 0 0 0-3.605-1.517 2.43 2.43 0 0 0-.982 1.124c-.658 1.563-1.648 3.219-2.344 4.522-1.993 3.729-2.007 8.304-.838 12.378a39.266 39.266 0 0 0 5.814 11.211c7.274 11.501 19.845 13.822 24.743 4.001a101.54 101.54 0 0 0 7.617-18.591 7.27 7.27 0 0 0 .205-3.545 7.272 7.272 0 0 0-1.502-3.218Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M551.016 208.191a39.263 39.263 0 0 1-5.813-11.211c-1.169-4.074-1.156-8.649.837-12.378.696-1.303 1.687-2.959 2.345-4.522a2.41 2.41 0 0 1 2.396-1.464 2.406 2.406 0 0 1 2.19 1.857c.168.849.203 1.719.101 2.578-.156 1.496-.573 2.956-.714 4.453a6.241 6.241 0 0 0 .918 4.31M555.544 183.869l-.177.415c2.202-4.888 2.476-10.416 2.612-15.789.114-4.562.17-9.213 1.298-13.602a2.655 2.655 0 0 1 2.912-2.012l.044.006a2.664 2.664 0 0 1 2.274 2.561c.092 2.655.131 5.281.267 7.958l.632 12.346M564.061 178.617c-2.371 2.246-4.816 4.599-6.002 7.664a15.298 15.298 0 0 0-.064 9.647 5.513 5.513 0 0 0 1.573 2.851c.85.693 2.64 1.518 4.055-.995"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M569.751 183.148a13.84 13.84 0 0 0-4.674 6.948 41.767 41.767 0 0 0-1.536 8.393 4.218 4.218 0 0 0 .429 3.052 2.618 2.618 0 0 0 3.122.587c1.041-.476 2.496-2.49 3.083-3.485"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M567.955 176.203a38.264 38.264 0 0 1 14.124 10.636 7.26 7.26 0 0 1 1.296 6.763 101.536 101.536 0 0 1-7.616 18.591"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M576.249 188.297a26.859 26.859 0 0 0-6.796 13.78 2.199 2.199 0 0 0 1.262 2.276l.048.022a2.174 2.174 0 0 0 2.75-.844 25.332 25.332 0 0 1 5.542-6.006"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.069 224.01c-8.491-8.307-18.696-15.185-30.909-21.252l15.605 34.205s19.388-8.959 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.758a132.19 132.19 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
