import React from 'react';

const SvgComponent = () => (
    <g id="man-bold-34-25-emotion-normal" transform="translate(329, 20)">
        <path
            stroke="url(#man-bold-34-25-emotion-normal-a)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M161.949 73.6c-2.5-1.2-6.2-1.8-8.8-2.1"
        />
        <path
            stroke="url(#man-bold-34-25-emotion-normal-b)"
            strokeLinecap="round"
            strokeMiterlimit={10}
            strokeWidth={5}
            d="M139.65 71.5c-6.4 0-13.1.7-17.3 2.5"
        />
        <path
            fill="#111011"
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M156.549 88.8c-.773 0-1.4-1.03-1.4-2.3 0-1.27.627-2.3 1.4-2.3.774 0 1.4 1.03 1.4 2.3 0 1.27-.626 2.3-1.4 2.3Zm-25.6 0c-.773 0-1.399-1.03-1.399-2.3 0-1.27.626-2.3 1.399-2.3.774 0 1.401 1.03 1.401 2.3 0 1.27-.627 2.3-1.401 2.3Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M151.949 112.9c-7.6 1.2-15.4.5-22.7-1.9"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-emotion-normal-a"
                x1={164.453}
                x2={150.666}
                y1={72.54}
                y2={72.54}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-emotion-normal-b"
                x1={142.146}
                x2={119.809}
                y1={72.753}
                y2={72.753}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#111011" />
                <stop offset={0.999} stopColor="#111011" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
