import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M476.73 232.553a64.883 64.883 0 0 0 1.406-10.606 33.815 33.815 0 0 0-1.403-11.517 105.903 105.903 0 0 1 17.336 13.629c.22.197.386.447.479.728.094.28.114.579.056.869-.522 3.026-8.329 7.883-15.837 11.355l-2.037-4.458Z"
            fill="url(#female-34-25-shirt-1-arm-opt-10-left-a)"
        />
        <path
            d="m583.056 264.58-5.585-10.592-5.459-10.66a236.334 236.334 0 0 0-12.095-20.677c-2.208-3.342-4.325-6.733-6.629-10.022l-3.4-4.965c-1.137-1.653-2.334-3.273-3.497-4.911-2.758-3.828-5.566-7.628-8.494-11.362-4.566 5.462-15.193 12.908-24.808 12.065 1.671 4.907 3.492 9.732 5.373 14.525.746 1.866 1.46 3.75 2.233 5.6l2.332 5.547a147.05 147.05 0 0 0 1.505 3.536c-4.904-1.925-9.873-3.646-14.926-5.104-7.398-2.16-14.849-4.151-22.174-6.533l-.128-.041a26.498 26.498 0 0 0-33.31 17.175 26.503 26.503 0 0 0 17.175 33.309c7.338 2.346 14.549 5.081 21.815 7.649a173.874 173.874 0 0 0 22.26 6.267c7.555 1.672 15.173 3.144 22.881 4.341a217.01 217.01 0 0 0 23.478 2.491 19.314 19.314 0 0 0 5.243-.518 18.496 18.496 0 0 0 9.047-2.439 18.497 18.497 0 0 0 6.688-6.561 18.494 18.494 0 0 0 .475-18.12Z"
            fill="url(#female-34-25-shirt-1-arm-opt-10-left-b)"
        />
        <path
            d="m487.303 220.984.128.042c7.325 2.382 14.776 4.373 22.174 6.532a165.396 165.396 0 0 1 10.982 3.59M561.604 292.217a217.013 217.013 0 0 1-23.478-2.492c-7.708-1.196-15.326-2.669-22.881-4.341a173.711 173.711 0 0 1-22.26-6.267c-7.266-2.567-14.477-5.303-21.815-7.648"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M561.602 292.219c4.73.436 9.487-.481 13.717-2.644a18.446 18.446 0 0 0 9.063-10.89 18.447 18.447 0 0 0-1.328-14.105l-5.584-10.592-5.459-10.66a236.498 236.498 0 0 0-12.095-20.678c-2.207-3.341-4.325-6.732-6.629-10.021l-3.4-4.965c-1.136-1.653-2.334-3.273-3.497-4.911l-8.494-11.362c-4.566 5.462-15.193 12.908-24.808 12.065l5.373 14.525c.746 1.866 1.46 3.749 2.233 5.6l2.332 5.547c1.516 3.718 3.22 7.335 4.833 11.001a236.062 236.062 0 0 0 10.83 21.367l6.003 10.363"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-10-left-a"
                x1={488.272}
                y1={207.485}
                x2={466.455}
                y2={296.051}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.033} stopColor="#E2E2E2" />
                <stop offset={0.966} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-10-left-b"
                x1={452.729}
                y1={241.812}
                x2={585.196}
                y2={241.812}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={0.936} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
