import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-4">
        <path
            d="M509.837 198.31c4.338 7.758 9.057 15.303 13.611 22.94a217.659 217.659 0 0 1 12.267 23.695c3.682 8.127 7.171 16.363 10.392 24.75a269.427 269.427 0 0 1 8.592 25.761 23.219 23.219 0 0 1-8.255 24.439 23.208 23.208 0 0 1-8.117 4.018 23.256 23.256 0 0 1-22.854-6.405 269.337 269.337 0 0 1-17.543-20.73c-5.491-7.11-10.715-14.371-15.744-21.741a217.745 217.745 0 0 1-13.869-22.795c-4.158-7.86-8.15-15.812-12.524-23.55a31 31 0 1 1 53.974-30.509l.07.127Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m512.981 299.914 2.399-9.956 2.335-9.969c.848-3.309 1.589-6.64 2.528-9.929.439-1.651.917-3.294 1.402-4.935a186.26 186.26 0 0 1 1.482-4.918c1.028-3.271 1.998-6.554 3.088-9.811 1.056-3.265 2.122-6.527 3.288-9.769 1.149-3.244 2.25-6.5 3.44-9.736 1.254-3.223 2.409-6.467 3.765-9.668a15.78 15.78 0 0 1 8.613-8.481 15.791 15.791 0 0 1 12.087.093 15.813 15.813 0 0 1 9.635 14.777c-.052 3.476-.302 6.91-.454 10.365-.218 3.441-.523 6.864-.78 10.297-.241 3.436-.58 6.852-.93 10.265-.317 3.42-.754 6.815-1.133 10.222a205.635 205.635 0 0 1-.627 5.099 187.45 187.45 0 0 1-.708 5.081c-.467 3.389-1.132 6.736-1.691 10.105l-1.883 10.064-1.821 10.078a22.513 22.513 0 0 1-26.153 18.149 22.51 22.51 0 0 1-17.882-27.423Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M515.473 317.508a269.337 269.337 0 0 1-17.543-20.73c-5.491-7.11-10.715-14.371-15.744-21.741a217.745 217.745 0 0 1-13.869-22.795c-4.158-7.86-8.15-15.812-12.524-23.55a31.002 31.002 0 0 1 23.221-46.025 31.008 31.008 0 0 1 30.753 15.516l.07.126c4.338 7.759 9.057 15.304 13.611 22.94a206.56 206.56 0 0 1 6.053 10.805"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M517.715 279.989c.848-3.309 1.589-6.64 2.528-9.929a183 183 0 0 1 1.402-4.935c.47-1.645.965-3.284 1.482-4.918 1.028-3.271 1.997-6.554 3.087-9.811 1.057-3.265 2.123-6.527 3.289-9.769 1.149-3.244 2.25-6.5 3.439-9.736 1.254-3.223 2.41-6.467 3.766-9.668a15.793 15.793 0 0 1 20.7-8.388 15.802 15.802 0 0 1 9.634 14.777c-.051 3.476-.301 6.91-.454 10.365-.217 3.441-.522 6.864-.779 10.297-.241 3.436-.581 6.852-.931 10.265-.316 3.42-.753 6.815-1.132 10.222a205.635 205.635 0 0 1-.627 5.099 187.45 187.45 0 0 1-.708 5.081c-.467 3.389-1.132 6.736-1.691 10.105l-1.884 10.064-1.82 10.078a22.513 22.513 0 0 1-26.153 18.149 22.43 22.43 0 0 1-14.058-8.716"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m572.394 200.313-1.636-2.361a25.503 25.503 0 0 0-19.1-12.584l-.234-.245c-1.492-2.348-3.035-4.752-5.278-6.396-2.243-1.644-5.378-2.38-7.839-1.085-1.895.997-3.046 2.962-4.097 4.828-1.711 3.036-3.644 6.017-5.394 9.043a1.28 1.28 0 0 0 .412 1.704 5.468 5.468 0 0 0 3.71.742 10.652 10.652 0 0 0 4.598-2.107c.426-.308.841-.63 1.247-.965l.209 2.009a14.334 14.334 0 0 1-3.025 8.156c-2.06-.309-3.43-2.203-4.976-3.598a12.306 12.306 0 0 0-7.469-3.091 2.991 2.991 0 0 0-1.814.509c-.535.36-.939.883-1.152 1.491l-.019.053a2.972 2.972 0 0 0 1.729 3.807 14.255 14.255 0 0 1 6.602 5.345c2.093 3.09 3.312 6.674 4.965 10.021 1.652 3.347 3.915 6.618 7.286 8.221 8.209 5.186 15.633 7.181 21.684 3.594 5.155-3.96 8.037-10.978 7.955-17.478l-.045-.338a15.41 15.41 0 0 0 2.44-4.568 5.37 5.37 0 0 0-.759-4.707Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M544.536 184.355a24.394 24.394 0 0 1-6.785 7.497 10.635 10.635 0 0 1-4.597 2.106 5.062 5.062 0 0 1-4.629-1.56c1.878-3.331 4.023-6.596 5.9-9.928 1.052-1.866 2.202-3.831 4.097-4.828 2.462-1.295 5.596-.559 7.839 1.085 2.243 1.644 3.787 4.048 5.278 6.395M549.419 191.66a63.168 63.168 0 0 0-12.234 16.166 2.873 2.873 0 0 0 .775 3.608l.042.033a2.846 2.846 0 0 0 3.992-.498 60.85 60.85 0 0 1 14.914-13.385M559.252 194.258a89.725 89.725 0 0 1-10.694 16.078 2.9 2.9 0 0 0-.004 3.667l.033.042a2.925 2.925 0 0 0 4.01.515 77.892 77.892 0 0 0 14.8-14.356M551.875 185.367a25.496 25.496 0 0 1 19.1 12.584M570.974 209.926c.082 6.5-2.799 13.518-7.954 17.478"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M539.209 192.898a14.329 14.329 0 0 1-3.025 8.156c-2.06-.309-3.429-2.203-4.976-3.598a12.308 12.308 0 0 0-7.469-3.09 2.991 2.991 0 0 0-2.966 2l-.019.053a2.975 2.975 0 0 0 1.728 3.806 14.25 14.25 0 0 1 6.602 5.345c2.093 3.09 3.312 6.675 4.965 10.022 1.653 3.346 3.916 6.617 7.287 8.22M535.963 200.898l2 .517M567.452 200.641a47.437 47.437 0 0 1-7.644 12.573 1.987 1.987 0 0 0-.128 2.43l.048.068a1.953 1.953 0 0 0 2.457.64c4.533-2.213 10.837-6.535 11.799-14.056"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.122 186.564c-9.226-7.123-19.957-12.656-32.552-17.169l19.121 31.543s17.866-10.95 13.431-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.57 169.395a129.655 129.655 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
