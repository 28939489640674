import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-7">
        <path
            d="M509.822 196.795c3.368 5.365 7.12 10.486 10.7 15.717a110.926 110.926 0 0 1 9.397 16.542c2.739 5.763 5.29 11.646 7.581 17.693a144.103 144.103 0 0 1 5.837 18.799 23.885 23.885 0 0 1-18.029 28.569 24.072 24.072 0 0 1-19.978-4.477 144.191 144.191 0 0 1-14.51-13.301c-4.491-4.653-8.723-9.471-12.766-14.407a110.944 110.944 0 0 1-10.95-15.558c-3.203-5.47-6.233-11.048-9.648-16.383a31.008 31.008 0 0 1 .68-34.44 31.011 31.011 0 0 1 19.984-12.789 30.981 30.981 0 0 1 23.175 5.087 31.001 31.001 0 0 1 8.383 8.72l.144.228Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m538.5 257.796 7.28 5.296c2.435 1.752 4.894 3.47 7.316 5.243 2.399 1.806 4.859 3.522 7.206 5.405 1.191.916 2.359 1.864 3.524 2.819 1.172.943 2.331 1.906 3.477 2.888 4.615 3.882 9.193 7.818 13.67 11.903a289.492 289.492 0 0 1 13.163 12.652 15.823 15.823 0 0 1-.293 22.375 15.844 15.844 0 0 1-17.112 3.278 276.464 276.464 0 0 1-16.62-7.555c-5.45-2.651-10.8-5.45-16.113-8.304a153.188 153.188 0 0 1-3.968-2.164 143.67 143.67 0 0 1-3.922-2.232c-2.618-1.485-5.121-3.136-7.686-4.698-2.542-1.595-5.047-3.245-7.576-4.861l-7.611-4.807a22.51 22.51 0 1 1 25.265-37.238Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M506.792 290.683c-.5-.33-.988-.679-1.462-1.045a144.191 144.191 0 0 1-14.51-13.301c-4.491-4.653-8.723-9.471-12.766-14.407a110.944 110.944 0 0 1-10.95-15.558c-3.203-5.47-6.233-11.048-9.648-16.383a31.008 31.008 0 0 1 .68-34.44 31.011 31.011 0 0 1 19.984-12.789 30.981 30.981 0 0 1 23.175 5.087 31.001 31.001 0 0 1 8.383 8.72l.144.228c3.368 5.365 7.12 10.486 10.7 15.717a110.843 110.843 0 0 1 9.398 16.542c2.738 5.763 5.289 11.646 7.58 17.693 1.455 3.888 2.835 7.823 4.029 11.876M576.731 329.656a276.152 276.152 0 0 1-16.621-7.555c-5.45-2.651-10.8-5.45-16.113-8.303a161.295 161.295 0 0 1-3.968-2.164 147.969 147.969 0 0 1-3.922-2.233c-2.617-1.485-5.121-3.136-7.685-4.698-2.543-1.595-5.047-3.245-7.576-4.86a117.1 117.1 0 0 1-14.055-9.159M544.057 261.84l1.723 1.253c2.435 1.753 4.894 3.47 7.316 5.243 2.399 1.806 4.859 3.522 7.206 5.405 1.191.916 2.359 1.865 3.524 2.82a143.69 143.69 0 0 1 3.477 2.887c4.615 3.882 9.193 7.818 13.67 11.903a290.037 290.037 0 0 1 13.163 12.652"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M623.055 317.418c-4.305-4.515-9.827-7.671-15.438-10.396a144.261 144.261 0 0 0-18.938-7.59c-10.689-2.69-13.14 6.269-10.95 18.941a37.706 37.706 0 0 0 12.988 25.008l.133.053c-.101.128-.19.265-.266.409l-.022.041a2.78 2.78 0 0 0 .129 2.815l-.259.148a2.052 2.052 0 0 0-1.007 1.524c-.073.628.381 1.136.716 1.672a19.283 19.283 0 0 0 18.776 8.637 8.107 8.107 0 0 0 3.266-1.096 3.829 3.829 0 0 0 1.81-2.818l.037-.374a24.4 24.4 0 0 0 1.255-.221 11.563 11.563 0 0 0 5.234-2.22c1.463-1.236 2.582-2.582 2.152-4.448l.069-.59a20.713 20.713 0 0 0 3.097-1.811 6.476 6.476 0 0 0 2.656-3.115c.527-1.67-.146-3.458-.809-5.079l.314-.451c.406-.403.8-.815 1.18-1.233a3.552 3.552 0 0 0 .879-2.514c-.196-5.691-3.047-11.145-7.002-15.292Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M588.678 299.434a144.054 144.054 0 0 1 18.937 7.59c5.612 2.724 11.134 5.881 15.438 10.395 3.956 4.148 6.807 9.602 7.002 15.293.045.92-.27 1.821-.878 2.514a41.489 41.489 0 0 1-8.417 6.934"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M618.123 340.244a10.417 10.417 0 0 1 1.965-9.397 18.487 18.487 0 0 0-6.885-6.175"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M627.684 336.91c.663 1.621 1.337 3.409.81 5.08a6.488 6.488 0 0 1-2.656 3.114 21.294 21.294 0 0 1-13.567 3.833 21.329 21.329 0 0 1-11.452-4.308 2.63 2.63 0 0 1-.853-2.995l.017-.045a2.64 2.64 0 0 1 3.22-1.609c3.438.885 7.061.744 10.42-.405"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M598.403 343.132c-1.551.053-3.1-.16-4.58-.629a2.735 2.735 0 0 0-3.24 1.342l-.022.041a2.774 2.774 0 0 0 .615 3.374c6.533 5.724 15.609 8.642 24.109 6.912a11.537 11.537 0 0 0 5.233-2.22c1.464-1.236 2.583-2.581 2.152-4.447"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M590.141 350.043a19.275 19.275 0 0 0 8.133 7.046 19.274 19.274 0 0 0 10.643 1.591 8.097 8.097 0 0 0 3.265-1.096 3.834 3.834 0 0 0 1.81-2.818M582.697 315.391a8.259 8.259 0 0 0 8.119 4.894 8.26 8.26 0 0 0 7.131-6.245c.163-.724-.63-1.704-1.271-2.075a7.522 7.522 0 0 1-3.626-5.403M590.716 343.382a37.7 37.7 0 0 1-12.987-25.007M592.131 332.194a13.28 13.28 0 0 0 3.594-13.167"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.122 186.165c-9.226-7.122-19.957-12.655-32.552-17.169l19.121 31.543s17.866-10.949 13.431-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.57 168.996a129.72 129.72 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
