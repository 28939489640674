import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-both-arms-option-1-right-cycling">
        <path
            d="M499.631 201.704a131.628 131.628 0 0 0-35.07-32.516l6.967 50.433s31.458-13.429 28.103-17.917Z"
            fill="url(#man-34-25-shirt-2-both-arms-option-1-right-cycling-a)"
        />
        <path
            d="M504.949 205.348c-6.721-8.346-15.922-13.434-26.653-13.434a32.004 32.004 0 0 0-26.583 49.776c3.242 4.854 6.363 9.917 9.382 14.813 1.325 2.15 2.654 4.295 3.987 6.437a195.514 195.514 0 0 0 14.742 20.524 505.18 505.18 0 0 0 2.727 3.349l54.061-30.935c-5.638-12.687-21.999-38.532-31.663-50.53Z"
            fill="url(#man-34-25-shirt-2-both-arms-option-1-right-cycling-b)"
        />
        <path
            d="m482.551 286.81 54.061-30.935c-5.639-12.687-21.999-38.532-31.663-50.53 0 0-6.47-7.807-11.096-9.47M492.174 192.61a128.094 128.094 0 0 0-27.613-23.422"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-both-arms-option-1-right-cycling-a"
                x1={464.56}
                y1={194.404}
                x2={499.881}
                y2={194.404}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-both-arms-option-1-right-cycling-b"
                x1={446.335}
                y1={239.363}
                x2={563.399}
                y2={239.363}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
