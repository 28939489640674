import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-cycling-2-left">
        <path
            d="M506.957 215.341c2.897 8.053 6.206 15.958 9.337 23.926a202.13 202.13 0 0 1 7.887 24.453c2.191 8.309 4.173 16.694 5.865 25.184a250.99 250.99 0 0 1 3.925 25.889 23.27 23.27 0 0 1-42.306 15.337 250.927 250.927 0 0 1-13.578-22.388c-4.142-7.603-7.994-15.31-11.637-23.093a201.788 201.788 0 0 1-9.616-23.825c-2.703-8.124-5.229-16.312-8.165-24.351a31 31 0 1 1 58.236-21.274l.052.142Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m521.025 299.615 4.909 2.265 4.936 2.199a84.136 84.136 0 0 1 9.585 5.122c3.116 1.904 6.209 3.87 9.235 6.003a103.625 103.625 0 0 1 8.901 6.844 16.444 16.444 0 0 1-1.837 26.155 16.45 16.45 0 0 1-9.594 2.661 103.857 103.857 0 0 1-11.173-1.119c-3.665-.522-7.264-1.21-10.838-1.96a84.359 84.359 0 0 1-10.49-2.842l-5.1-1.783-5.128-1.716a22.535 22.535 0 0 1-2.866-41.501 22.532 22.532 0 0 1 17.172-1.235c.778.261 1.542.564 2.288.907Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M491.665 330.13a250.692 250.692 0 0 1-13.578-22.388c-4.142-7.603-7.994-15.31-11.637-23.093a201.788 201.788 0 0 1-9.616-23.825c-2.703-8.124-5.229-16.312-8.165-24.351a31 31 0 1 1 58.236-21.274l.051.142c2.898 8.053 6.206 15.958 9.337 23.926a201.763 201.763 0 0 1 7.887 24.452c2.191 8.31 4.173 16.695 5.866 25.185.819 4.177 1.59 8.371 2.255 12.604"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M547.16 350.86a103.857 103.857 0 0 1-11.173-1.119c-3.665-.522-7.264-1.21-10.838-1.96a84.359 84.359 0 0 1-10.49-2.842l-5.1-1.783-5.128-1.716c-4.425-1.482-9.255-5.917-12.766-11.315M530.87 304.078a84.22 84.22 0 0 1 9.585 5.122c3.116 1.905 6.208 3.871 9.234 6.003a103.687 103.687 0 0 1 8.902 6.844"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
