import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-5">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-5-a)"
            d="M418.34 248.993a146.884 146.884 0 0 0-4.333-21.474 857.839 857.839 0 0 0-1.802-6.315c-1.377-4.777-2.8-9.717-3.991-14.619l-.043-.174a31.943 31.943 0 0 0-31.028-24.27 32.07 32.07 0 0 0-7.76.956 32.037 32.037 0 0 0-23.314 38.789c1.219 4.891 2.251 9.928 3.249 14.799.439 2.144.88 4.288 1.335 6.424a146.58 146.58 0 0 0 6.127 21.028c2.651 7.319 5.335 14.006 8.205 20.447 1.127 2.505 5.465-10.447 25.128-17.965 17.938-6.859 29.813-3.711 29.813-3.711-.414-4.5-.931-9.098-1.586-13.915Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-5-a"
                x1={402.383}
                x2={362.479}
                y1={292.702}
                y2={177.328}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
