import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={978}
            viewBox="0 0 2144 978"
            fill="none"
            id="scene-kitchen-5"
            {...attributes}
        >
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M2051.1 517H106.102l-55.5 40.5H1803.1V577H41.602v28.5h168v105M190.098 631v40.5M226.098 631v40.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M609.727 893.476H402.477l-6.63-19.64h209.2"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m605.047 873.837-36.8-154.22h-33.87M609.727 893.48l17.29 72.46h13.87L589.007 701"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m458.985 682.383-55.523 283.556h13.874l63.204-264.94h108.468"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m509.973 719.617 5.266 246.324h13.874l5.266-246.324M445.351 682.382c-5.956 0-10.784-4.828-10.784-10.784 0-13.033 10.565-23.598 23.598-23.598h128.018c13.033 0 23.598 10.565 23.598 23.598 0 5.956-4.828 10.784-10.784 10.784H458.985"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1927.5 376.349h46.95v-309.9H1537.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1666.8 30v373.95h336.3V39.9h-305.55v336.45h178.95"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1876.5 376.651-13.5-40.5h11.55c-11.85-8.1-18.15-19.35-18.15-19.35s16.35-1.5 31.5 6.6c.75.45 1.35.75 2.1 1.2-3.6-5.55-6.9-12-9.3-19.05-9.15-27.75-.3-55.5-.3-55.5s23.85 17.1 33 44.7c.9 2.85 1.65 5.85 2.25 8.7 10.8-17.4 27.75-26.55 27.75-26.55s4.35 23.25-5.85 44.4c-2.7 5.85-6.3 10.8-10.05 15.15h13.5l-13.5 40.5M924.602 1H1116.6v133.5h138l93 58.5h-304.5"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1251.6 1v112.5h-117L1019.1 193v36h342v-34.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-kitchen-5-a)"
            >
                <path 
                    d="M281.807 27.526v330.063h178.295V1H103.513v356.589h147.41M312.691 332.575V284.07M250.922 332.575V284.07"
                />
            </g>
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M640.887 65.297V97.21l-15.113 15.114M640.887 22.594v14.489M715.945 97.21h-14.488M640.887 172.387v-14.489M565.831 97.21h14.489"
            />
            <path
                stroke="#BCBCBC"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M703.163 122.957a67.143 67.143 0 0 0 5.099-25.747c0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 977H2142.05"
            />
            <defs>
                <clipPath id="scene-kitchen-5-a">
                    <path fill="#fff" d="M461.102 0H102.513v358.589h358.589z" />
                </clipPath>
            </defs>
        </svg>
    );
}

