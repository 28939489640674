import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M456.129 336.307a229.882 229.882 0 0 0 22.657-6.406c1.19-.409 2.388-.809 3.585-1.209 2.527-.845 5.082-1.699 7.568-2.631l5.573-2.021c1.194-.436 2.383-.9 3.575-1.365.653-.255 1.305-.509 1.963-.761 4.093-1.598 9.446-3.734 15.13-6.3a46.992 46.992 0 0 1-6.544-13.291 38.67 38.67 0 0 1-1.924-12.687c-5.676.422-10.881 1.006-14.383 1.42-.616.077-1.233.15-1.898.228l-.255.03c-1.245.147-2.49.295-3.73.469l-5.842.835c-2.656.35-5.295.795-7.847 1.226l-.336.057c-1.163.196-2.326.392-3.496.579a229.553 229.553 0 0 0-23.007 5.008l-7.518 2.09-.294-.914a297.844 297.844 0 0 0-2.271-6.784c-2.403-6.822-5.1-13.713-8.246-21.062a164.617 164.617 0 0 0-10.026-20.105c-3.312-5.658-5.866-10.356-8.823-15.797l-1.383-2.542c-4.426-8.03-12.869-13.316-23.161-14.498a19.12 19.12 0 0 0-2.167-.126c-3.402 0-6.843.952-11.314 3.072-5.131 2.434-9.306 7.65-11.455 14.311-2.52 7.811-1.891 16.345 1.683 22.829 2.056 3.73 3.585 7.064 5.204 10.594 1.194 2.603 2.428 5.294 3.902 8.177a165.075 165.075 0 0 0 11.539 19.266c4.504 6.611 8.861 12.597 13.324 18.306a208.433 208.433 0 0 0 15.022 17.363 19.294 19.294 0 0 0 6.321 4.241l.234.138a18.629 18.629 0 0 0 15.687 3.503l11.445-2.688 11.508-2.555Z"
            fill="url(#female-34-25-shirt-1-arm-right-opt-2-a)"
        />
        <path
            d="m435.582 302.642 11.341-3.153a228.167 228.167 0 0 1 23.007-5.007c3.887-.622 7.75-1.344 11.664-1.86l5.855-.836c1.953-.275 3.923-.484 5.883-.728 7.854-.928 15.749-1.694 23.778-1.917"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M371.715 222.82c-5.131 2.434-9.306 7.65-11.455 14.311-2.52 7.811-1.891 16.346 1.683 22.829 2.056 3.73 3.585 7.064 5.204 10.594 1.194 2.603 2.428 5.294 3.902 8.177a165.075 165.075 0 0 0 11.539 19.266c4.504 6.611 8.861 12.597 13.324 18.306a208.433 208.433 0 0 0 15.022 17.363 19.294 19.294 0 0 0 6.321 4.241l.234.138a18.629 18.629 0 0 0 15.687 3.503l11.445-2.688 11.508-2.556a229.334 229.334 0 0 0 22.657-6.406c1.19-.408 2.387-.808 3.584-1.208 2.527-.845 5.083-1.699 7.569-2.631l5.573-2.021c1.194-.436 2.383-.9 3.575-1.366.652-.254 1.304-.508 1.963-.76 4.093-1.598 9.446-3.735 15.13-6.3a47.018 47.018 0 0 1-6.544-13.291 38.702 38.702 0 0 1-1.925-12.687c-5.675.421-10.881 1.006-14.383 1.42-.615.076-1.232.15-1.897.228l-.255.03c-1.245.147-2.49.295-3.73.468l-5.842.835c-2.656.35-5.295.796-7.847 1.227l-.336.057c-1.163.196-2.327.392-3.496.579a229.628 229.628 0 0 0-23.008 5.007l-7.517 2.09-.295-.914a297.853 297.853 0 0 0-2.27-6.783c-2.404-6.822-5.101-13.713-8.246-21.062a164.689 164.689 0 0 0-10.026-20.105c-3.313-5.658-5.866-10.357-8.823-15.797l-1.383-2.542"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-right-opt-2-a"
                x1={413.686}
                y1={356.801}
                x2={475.615}
                y2={153.773}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
