import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 206,
        height: 394,
        content: (
            <>
                <path
                    fill="#AEAEAF"
                    d="M163.239 360.879h16.33c4.284 0 8.307-3.473 8.985-7.757l15.681-99.006c1.393-8.793-4.607-15.921-13.4-15.921s-17.051 7.128-18.443 15.921l-15.681 99.006c-.678 4.284 2.244 7.757 6.528 7.757Z"
                />
                <path
                    fill="url(#object-devices-bag-1-a)"
                    d="M190.837 238.195H38.789c-8.793 0-17.05 7.128-18.443 15.921L4.664 353.122c-.679 4.284 2.245 7.757 6.528 7.757H179.57c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M190.837 238.195H38.789c-8.793 0-17.05 7.128-18.443 15.921L4.664 353.122c-.679 4.284 2.245 7.757 6.528 7.757H179.57c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m180.526 237.946-1.194-204.332C179.225 15.26 165.495 1.79 146.895 1.79c-18.6 0-36.597 13.471-42.518 31.825l-65.92 204.332"
                />
                <path
                    fill="url(#object-devices-bag-1-b)"
                    d="m1.715 305.035 8.065-50.919c1.393-8.793 9.65-15.921 18.443-15.921h162.615c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-39.957 17.313-123.892 33.639-162.615 0Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M190.838 238.195c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-20.449 8.86-52.417 17.462-83.948 19.241-30.08 1.696-59.762-2.817-78.667-19.241"
                />
                <path
                    fill="#BCBCBC"
                    d="M64.296 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.99 126.221c-.812 5.122 2.682 9.274 7.804 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547H64.296ZM142.296 238.328c-10.243 0-19.862 8.304-21.485 18.547L100.82 383.096c-.811 5.122 2.683 9.274 7.805 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547h-18.547Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M82.843 238.328c-10.243 0-19.862 8.304-21.485 18.547L41.367 383.096c-.811 5.122-5.62 9.274-10.742 9.274M108.625 392.37c5.122 0 9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547M28.223 238.195h162.615"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-1-a"
                        x1={138.159}
                        x2={190.741}
                        y1={305.029}
                        y2={312.868}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.356} stopColor="#AEAEAF" />
                        <stop offset={0.534} stopColor="#888B8D" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-1-b"
                        x1={96.276}
                        x2={96.276}
                        y1={282.039}
                        y2={239.975}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.505} stopColor="#888B8D" />
                        <stop offset={0.597} stopColor="#969899" />
                        <stop offset={0.746} stopColor="#A7A8A9" />
                        <stop offset={0.855} stopColor="#AEAEAF" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 206,
        height: 394,
        content: (
            <>
                <path
                    fill="#ECF3F9"
                    d="M162.964 360.879h16.33c4.284 0 8.307-3.473 8.985-7.757l15.681-99.006c1.393-8.793-4.607-15.921-13.4-15.921s-17.051 7.128-18.443 15.921l-15.681 99.006c-.678 4.284 2.244 7.757 6.528 7.757Z"
                />
                <path
                    fill="url(#object-devices-bag-1-a)"
                    d="M190.561 238.195H38.513c-8.793 0-17.051 7.128-18.443 15.921L4.389 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M190.561 238.195H38.513c-8.793 0-17.051 7.128-18.443 15.921L4.389 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m180.249 237.946-1.194-204.332C178.948 15.26 165.218 1.79 146.618 1.79c-18.6 0-36.597 13.471-42.518 31.825L38.18 237.946"
                />
                <path
                    fill="url(#object-devices-bag-1-b)"
                    d="m1.438 305.035 8.064-50.919c1.393-8.793 9.65-15.921 18.443-15.921h162.616c-8.795 0-17.052 7.128-18.444 15.921l-8.064 50.919c-39.957 17.313-123.892 33.639-162.615 0Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M190.561 238.195c-8.794 0-17.052 7.128-18.444 15.921l-8.064 50.919c-20.449 8.86-52.418 17.462-83.948 19.241-30.08 1.696-59.763-2.817-78.668-19.241"
                />
                <path
                    fill="#ECF3F9"
                    d="M64.019 238.328c-10.243 0-19.862 8.304-21.485 18.547L22.543 383.096c-.811 5.122 2.683 9.274 7.805 9.274s9.93-4.152 10.742-9.274l19.99-126.221c1.623-10.243 11.242-18.547 21.486-18.547H64.019ZM142.017 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.991 126.221c-.811 5.122 2.683 9.274 7.805 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547h-18.547Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M82.566 238.328c-10.243 0-19.862 8.304-21.485 18.547L41.09 383.096c-.811 5.122-5.62 9.274-10.742 9.274M108.346 392.37c5.122 0 9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547M27.945 238.195H190.56"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-1-a"
                        x1={137.883}
                        x2={190.465}
                        y1={305.03}
                        y2={312.868}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.356} stopColor="#ECF3F9" />
                        <stop offset={0.534} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-1-b"
                        x1={95.999}
                        x2={95.999}
                        y1={282.039}
                        y2={239.974}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.505} stopColor="#C2C9CF" />
                        <stop offset={0.614} stopColor="#D4DBE1" />
                        <stop offset={0.753} stopColor="#E5ECF2" />
                        <stop offset={0.855} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 206,
        height: 394,
        content: (
            <>
                <path
                    fill="#FFF4C3"
                    d="M162.688 360.879h16.33c4.284 0 8.307-3.473 8.985-7.757l15.681-99.006c1.393-8.793-4.607-15.921-13.4-15.921s-17.051 7.128-18.443 15.921l-15.681 99.006c-.678 4.284 2.244 7.757 6.528 7.757Z"
                />
                <path
                    fill="url(#object-devices-bag-1-a)"
                    d="M190.286 238.195H38.238c-8.793 0-17.051 7.128-18.443 15.921L4.114 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M190.286 238.195H38.238c-8.793 0-17.051 7.128-18.443 15.921L4.114 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m179.973 237.946-1.194-204.332C178.672 15.26 164.942 1.79 146.342 1.79c-18.6 0-36.597 13.471-42.518 31.825l-65.92 204.332"
                />
                <path
                    fill="url(#object-devices-bag-1-b)"
                    d="m1.162 305.035 8.065-50.919c1.393-8.793 9.65-15.921 18.443-15.921h162.615c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-39.957 17.313-123.892 33.639-162.615 0Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M190.285 238.195c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-20.449 8.86-52.417 17.462-83.948 19.241-30.08 1.696-59.762-2.817-78.667-19.241"
                />
                <path
                    fill="#FFF4C3"
                    d="M63.743 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.99 126.221c-.812 5.122 2.682 9.274 7.804 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547H63.743ZM141.743 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.991 126.221c-.81 5.122 2.683 9.274 7.805 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547h-18.547Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M82.29 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.99 126.221c-.812 5.122-5.62 9.274-10.743 9.274M108.07 392.37c5.122 0 9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547M27.67 238.195h162.615"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-1-a"
                        x1={137.608}
                        x2={190.19}
                        y1={305.029}
                        y2={312.868}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.356} stopColor="#FFF4C3" />
                        <stop offset={0.416} stopColor="#FFE884" />
                        <stop offset={0.534} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-1-b"
                        x1={95.724}
                        x2={95.724}
                        y1={282.039}
                        y2={239.975}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.505} stopColor="#FFD100" />
                        <stop offset={0.568} stopColor="#FFDA33" />
                        <stop offset={0.638} stopColor="#FFE366" />
                        <stop offset={0.705} stopColor="#FFEA8E" />
                        <stop offset={0.765} stopColor="#FFEFAB" />
                        <stop offset={0.817} stopColor="#FFF2BC" />
                        <stop offset={0.855} stopColor="#FFF4C3" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 206,
        height: 394,
        content: (
            <>
                <path
                    fill="#FFE4B8"
                    d="M162.764 360.879h16.33c4.284 0 8.307-3.473 8.985-7.757l15.681-99.006c1.393-8.793-4.607-15.921-13.4-15.921s-17.051 7.128-18.443 15.921l-15.681 99.006c-.678 4.284 2.244 7.757 6.528 7.757Z"
                />
                <path
                    fill="url(#object-devices-bag-1-a)"
                    d="M190.361 238.195H38.313c-8.793 0-17.051 7.128-18.443 15.921L4.188 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.378c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M190.361 238.195H38.313c-8.793 0-17.051 7.128-18.443 15.921L4.188 353.122c-.68 4.284 2.245 7.757 6.528 7.757h168.378c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m180.049 237.946-1.194-204.332C178.748 15.26 165.018 1.79 146.418 1.79c-18.6 0-36.597 13.471-42.518 31.825L37.98 237.946"
                />
                <path
                    fill="url(#object-devices-bag-1-b)"
                    d="m1.238 305.035 8.065-50.919c1.393-8.793 9.65-15.921 18.443-15.921h162.615c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-39.957 17.313-123.892 33.639-162.615 0Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M190.361 238.195c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-20.449 8.86-52.417 17.462-83.948 19.241-30.08 1.696-59.762-2.817-78.667-19.241"
                />
                <path
                    fill="#FFE4B8"
                    d="M63.82 238.328c-10.243 0-19.862 8.304-21.486 18.547l-19.99 126.221c-.812 5.122 2.682 9.274 7.804 9.274s9.932-4.152 10.743-9.274l19.99-126.221c1.623-10.243 11.242-18.547 21.486-18.547H63.819ZM141.818 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.991 126.221c-.811 5.122 2.683 9.274 7.805 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547h-18.547Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M82.366 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.99 126.221c-.812 5.122-5.62 9.274-10.743 9.274M108.146 392.37c5.122 0 9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547M27.746 238.195h162.615"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-1-a"
                        x1={137.683}
                        x2={190.265}
                        y1={305.029}
                        y2={312.868}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.356} stopColor="#FFE4B8" />
                        <stop offset={0.534} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-1-b"
                        x1={95.799}
                        x2={95.799}
                        y1={282.039}
                        y2={239.975}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.505} stopColor="#E5B97F" />
                        <stop offset={0.524} stopColor="#E7BC83" />
                        <stop offset={0.656} stopColor="#F4D2A0" />
                        <stop offset={0.771} stopColor="#FCDFB1" />
                        <stop offset={0.855} stopColor="#FFE4B8" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 206,
        height: 394,
        content: (
            <>
                <path
                    fill="#FCCBBB"
                    d="M163.487 360.879h16.33c4.284 0 8.307-3.473 8.985-7.757l15.681-99.006c1.393-8.793-4.607-15.921-13.4-15.921s-17.051 7.128-18.443 15.921l-15.681 99.006c-.678 4.284 2.244 7.757 6.528 7.757Z"
                />
                <path
                    fill="url(#object-devices-bag-1-a)"
                    d="M191.084 238.195H39.036c-8.793 0-17.05 7.128-18.443 15.921l-15.68 99.006c-.68 4.284 2.244 7.757 6.527 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeMiterlimit={10}
                    strokeWidth={2}
                    d="M191.084 238.195H39.036c-8.793 0-17.05 7.128-18.443 15.921l-15.68 99.006c-.68 4.284 2.244 7.757 6.527 7.757h168.377c4.284 0 8.307-3.473 8.986-7.757l15.681-99.006c1.393-8.793-4.606-15.921-13.399-15.921h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m180.772 237.946-1.194-204.332C179.471 15.26 165.741 1.79 147.141 1.79c-18.6 0-36.597 13.471-42.518 31.825l-65.92 204.332"
                />
                <path
                    fill="url(#object-devices-bag-1-b)"
                    d="m1.96 305.035 8.066-50.919c1.393-8.793 9.65-15.921 18.443-15.921h162.615c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-39.957 17.313-123.892 33.639-162.615 0Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M191.084 238.195c-8.794 0-17.051 7.128-18.443 15.921l-8.065 50.919c-20.449 8.86-52.417 17.462-83.948 19.241-30.08 1.696-59.762-2.817-78.667-19.241"
                />
                <path
                    fill="#FCCBBB"
                    d="M64.542 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.99 126.221c-.812 5.122 2.682 9.274 7.804 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547H64.542ZM142.54 238.328c-10.243 0-19.862 8.304-21.485 18.547l-19.991 126.221c-.811 5.122 2.683 9.274 7.805 9.274s9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547H142.54Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M83.09 238.328c-10.244 0-19.863 8.304-21.486 18.547l-19.99 126.221c-.812 5.122-5.62 9.274-10.743 9.274M108.869 392.37c5.122 0 9.931-4.152 10.742-9.274l19.991-126.221c1.622-10.243 11.241-18.547 21.485-18.547M28.469 238.195h162.615"
                />
                <defs>
                    <linearGradient
                        id="object-devices-bag-1-a"
                        x1={138.406}
                        x2={190.988}
                        y1={305.029}
                        y2={312.868}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.356} stopColor="#FCCBBB" />
                        <stop offset={0.514} stopColor="#EC9E7D" />
                        <stop offset={0.534} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-devices-bag-1-b"
                        x1={96.522}
                        x2={96.522}
                        y1={282.039}
                        y2={239.975}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.505} stopColor="#EA9975" />
                        <stop offset={0.565} stopColor="#EEA586" />
                        <stop offset={0.681} stopColor="#F5BAA3" />
                        <stop offset={0.782} stopColor="#FAC6B4" />
                        <stop offset={0.855} stopColor="#FCCBBB" />
                    </linearGradient>
                </defs> 
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-devices-bag-1"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
