import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-up">
        <path
            fill="url(#man-34-25-doctors-coat-arm-left-up-a)"
            d="m465.457 167.2 2.699 1c13.1 4.7 23.801 10.3 32.801 17.3.8.6 1.1 1.4 1.1 2.3-.2 4.6-12.2 12.2-14.6 13.7l-.9.5-21.1-34.8Z"
        />
        <path
            fill="url(#man-34-25-doctors-coat-arm-left-up-b)"
            d="M614.357 237.7c-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-3.3-14.6-4.8-20.2 0 0-10.1 4.3-22.4 1.6-12.2-2.7-16.5-10.1-16.5-10.1-.7 6.7-1 12.6-1.2 19.6-18.6-7.5-38.7-14.4-58.3-18.4-6.3-1.4-12.9-2.9-19.2-4.6-2.7-.7-5.4-1-8.1-1-14.6 0-27.3 9.9-31 24-4.4 17.1 5.9 34.6 23 39 9.2 2.4 18.2 5.1 27.3 7.7 17.5 5.1 38.2 8.2 56.1 10.4 7.8.9 18.2 1.9 28.9 2.2 1.5 0 2.9-.1 4.4-.3 14.9 1.2 27.6-12.4 25.2-27Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M499.855 252.3c17.5 5.1 38.201 8.2 56.101 10.4 7.8.9 18.199 1.9 28.899 2.2 1.5 0 2.901-.1 4.401-.3 14.8 1.4 27.5-12.2 25.2-26.9-1.2-7.4-2.1-15.6-3.4-22.9-1.2-6.4-4-15.6-5.8-20.6 0 0-6.1 4.3-20.2 2.3-13.8-1.9-16.8-10.1-16.8-10.1-.9 5.6-1.701 12.3-1.901 19.3-18.6-7.5-38.699-14.4-58.299-18.4m-39.9-19.1c13.1 4.7 23.801 10.3 32.801 17.3"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-up-a"
                x1={465.454}
                x2={502.029}
                y1={184.621}
                y2={184.621}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-up-b"
                x1={448.53}
                x2={614.678}
                y1={223.233}
                y2={223.233}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
