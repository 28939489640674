import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-3-right">
        <path
            d="M408.245 215.309c-.952 8.47-1.47 16.987-2.176 25.483a200.317 200.317 0 0 1-3.711 25.316c-1.7 8.387-3.619 16.75-5.846 25.08a249.072 249.072 0 0 1-7.899 24.854 23.273 23.273 0 0 1-30.1 13.326 23.32 23.32 0 0 1-14.63-18.246 248.889 248.889 0 0 1-2.308-25.977c-.362-8.614-.419-17.195-.255-25.751.083-8.537.711-17.06 1.882-25.517 1.158-8.447 2.503-16.873 3.415-25.348a30.996 30.996 0 0 1 22.089-26.43 31.004 31.004 0 0 1 23.59 2.537 30.993 30.993 0 0 1 14.888 18.475 30.98 30.98 0 0 1 1.077 12.047l-.016.151Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m365.692 289.499 14.5-.725 14.497-.789c9.666-.501 19.351-.738 29.046-.824 4.85-.009 9.695-.078 14.55-.023 4.852.02 9.705.051 14.565.183 4.858.114 9.714.18 14.575.335 4.867.222 9.726.344 14.599.668a15.65 15.65 0 0 1 13.781 10.599c.659 1.946.927 4.003.791 6.054a15.674 15.674 0 0 1-12.394 14.271c-4.78 1.005-9.574 1.806-14.362 2.708-4.791.835-9.59 1.579-14.384 2.374-4.794.812-9.594 1.523-14.396 2.222-4.799.734-9.606 1.345-14.41 2.016-9.611 1.274-19.234 2.397-28.875 3.255l-14.463 1.25-14.46 1.314a22.493 22.493 0 0 1-16.444-5.124 22.49 22.49 0 0 1-7.078-23.983 22.504 22.504 0 0 1 19.448-15.717c.276-.025.64-.049.914-.064ZM511.259 302.775a58.882 58.882 0 0 0 5.808-5.593 45.243 45.243 0 0 1 34.398-12.713c17.842.054 26.285 14.578 17.653 26.05-7.867 8.227-16.204 16.072-25.716 22.299-9.512 6.228-20.278 10.815-31.793 12.108a6.239 6.239 0 0 1-1.621.017 2.275 2.275 0 0 1-1.287-.652 1.73 1.73 0 0 1-.256-1.907l.128-.43c-2.818.833-5.671 1.577-8.52 2.32a2.912 2.912 0 0 1-2.896-1.541 3 3 0 0 1 .127-3.198l.235-.515a21.55 21.55 0 0 0-1.772 1.402 2.332 2.332 0 0 1-1.759.499 2.337 2.337 0 0 1-1.581-.917l-.03-.036a2.462 2.462 0 0 1-.201-2.927c3.63-5.33 8.995-9.215 14.44-12.655 1.707-1.078 3.439-2.129 5.158-3.198l-.889.119a54.885 54.885 0 0 0-17.19 9.14 1.665 1.665 0 0 1-1.252.361 1.657 1.657 0 0 1-1.126-.655c-.602-.849-1.001-2.23-.114-4.338a25.704 25.704 0 0 1 8.102-9.832 72.775 72.775 0 0 1 9.148-5.898l-.365-.118a5.574 5.574 0 0 1-3.385-1.43 3.386 3.386 0 0 1-1.019-3.339 10.739 10.739 0 0 0 7.575-2.423Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M551.465 284.469a45.243 45.243 0 0 0-34.398 12.713 58.782 58.782 0 0 1-5.809 5.593 10.73 10.73 0 0 1-7.574 2.423M530.412 294.445a107.896 107.896 0 0 1-17.372 12.976c-4.629 2.78-9.49 5.229-13.735 8.563a26.792 26.792 0 0 0-8.102 9.832 4.685 4.685 0 0 0 1.424 5.452l.026.021M523.782 312.211c-4.966 4.987-11.219 8.419-17.165 12.175-5.446 3.44-10.81 7.325-14.441 12.655a2.463 2.463 0 0 0 .201 2.927l.031.036a2.325 2.325 0 0 0 1.581.917 2.332 2.332 0 0 0 1.758-.499c3.003-2.652 6.873-4.412 10.563-5.993 4.407-1.888 16.509-7.694 23.648-13.65M500.053 344.273a144.076 144.076 0 0 0 15.876-4.795 70.55 70.55 0 0 0 20.507-12.302M511.607 344.926c11.516-1.293 22.282-5.88 31.794-12.107 9.512-6.228 17.849-14.073 25.716-22.299M408.263 215.16l-.017.15c-.952 8.47-1.47 16.987-2.176 25.484a200.275 200.275 0 0 1-3.711 25.315 458.624 458.624 0 0 1-3.945 17.666M344.404 314.013c-1.313-8.719-2.437-20.249-2.827-28.867-.362-8.614-.419-17.194-.255-25.751.083-8.537.711-17.06 1.882-25.516 1.158-8.447 2.503-16.874 3.414-25.348"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M484.203 319.248c-4.78 1.004-9.574 1.806-14.362 2.707-4.791.836-9.589 1.58-14.384 2.375-4.794.811-9.594 1.522-14.395 2.222-4.799.734-9.607 1.345-14.411 2.016-9.611 1.274-19.233 2.396-28.874 3.255l-14.464 1.25-14.459 1.314a22.497 22.497 0 0 1-16.445-5.124 22.51 22.51 0 0 1-8.005-15.251M390.682 288.203l4.008-.218c9.666-.501 19.351-.738 29.046-.824 4.85-.009 9.695-.078 14.55-.023 4.852.02 9.705.051 14.565.183 4.858.114 9.714.18 14.575.335 4.866.222 9.726.344 14.599.668"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m566.633 303.22-.701-.268.583-.324a2.954 2.954 0 0 0 1.4-2.921 3.213 3.213 0 0 0-2.497-2.425c-4.454-.637-8.457-2.951-12.519-4.888-9.407-4.487-19.795-9.505-30.137-8.216l-39.566 4.931c-21.378 3.178-17.342 28.105-.637 30.745 8.578 1.708 17.263 2.817 25.939 3.924 14.255 1.819 27.657 3.817 41.742 1.755a2.04 2.04 0 0 0 1.713-2.247l-.004-.045a2.04 2.04 0 0 0-1.651-1.781c-9.679-1.904-18.787-5.341-27.807-9.407l12.237 1.038c8.514.722 17.097 1.443 25.584.449a3.89 3.89 0 0 0 2.832-1.361 3.52 3.52 0 0 0 .522-3.248l-.274-.754c.594.099 1.188.198 1.782.299a2.732 2.732 0 0 0 2.793-1.264 2.754 2.754 0 0 0 .043-2.802 2.753 2.753 0 0 0-1.377-1.19Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M485.928 288.77c13.188-1.644 23.054-2.819 36.243-4.463 10.343-1.289 20.73 3.729 30.138 8.216 4.061 1.937 8.064 4.252 12.519 4.888M524.316 303.258a569.004 569.004 0 0 1 40.267 5.346c.358.062.724.052 1.078-.03a2.731 2.731 0 0 0 2.088-2.245 2.74 2.74 0 0 0-1.707-2.98A147.237 147.237 0 0 0 525.89 294M481.969 319.981c8.578 1.708 17.262 2.816 25.938 3.923 14.255 1.819 27.657 3.817 41.742 1.756a2.042 2.042 0 0 0 1.713-2.247l-.005-.045a2.033 2.033 0 0 0-1.649-1.781c-9.679-1.904-18.787-5.341-27.808-9.407l12.237 1.037c8.514.722 17.098 1.444 25.584.449"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
