import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-3">
        <path
            d="m465.911 167.027 2.654.952c13.051 4.677 23.788 10.342 32.825 17.319a2.67 2.67 0 0 1 1.092 2.341c-.25 4.648-12.214 12.208-14.61 13.676l-.857.526-21.104-34.814Z"
            fill="url(#man-34-25-jacket-left-option-5-a)"
        />
        <path
            d="M570.031 277.738a23.647 23.647 0 0 1-6.198-.826 24.802 24.802 0 0 1-3.866-.357 216.213 216.213 0 0 1-23.437-5.903c-7.273-2.227-14.621-4.761-22.464-7.746a170.958 170.958 0 0 1-21.56-9.703 920.832 920.832 0 0 1-6.239-3.374c-4.718-2.567-9.598-5.222-14.484-7.638a31.781 31.781 0 0 1-9.909-7.601 31.781 31.781 0 0 1-6.218-10.831 31.784 31.784 0 0 1-1.632-12.383 31.776 31.776 0 0 1 3.262-12.055 31.824 31.824 0 0 1 28.707-17.821 31.695 31.695 0 0 1 14.163 3.323l.152.076c4.875 2.444 9.937 4.738 14.832 6.958 2.152.975 4.301 1.956 6.449 2.942a171.163 171.163 0 0 1 20.739 11.359 385.205 385.205 0 0 1 9.975 6.48c1.197-5.956 2.267-10.876 3.346-15.385a296.944 296.944 0 0 1 2.761-10.909l.949-3.47.978 3.463c2.815 9.975 14.997 15.357 25.029 15.357 2.679.122 5.344-.44 7.746-1.632l1.426-.843.082 1.655c.266 5.372.415 11.028.485 18.338.028 1.623.033 3.126.015 4.588a144.624 144.624 0 0 1-.068 4.578c-.025 1.982-.14 3.999-.251 5.949-.059 1.05-.118 2.099-.165 3.15l-.611 9.093-.547 9.069a23.53 23.53 0 0 1-23.447 22.099Z"
            fill="url(#man-34-25-jacket-left-option-3-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-3-a"
                x1={486.363}
                y1={168.534}
                x2={443.857}
                y2={343.329}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-3-b"
                x1={536.538}
                y1={180.736}
                x2={494.032}
                y2={355.531}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
