import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={16}
            height={22}
            fill="none"
            id="object-devices-ipods"
            {...attributes}
        >
            <path
                fill="url(#object-devices-ipods-a)"
                stroke="#222731"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.23 7.08c0 5.49 8.655 13.384 11.742 13.384.995 0 2.08-1.11 1.39-2.653-1.216-2.713-4.58-5.924-3.96-6.44a5.589 5.589 0 1 0-9.17-4.291H1.23Z"
            />
            <defs>
                <radialGradient
                    id="object-devices-ipods-a"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-180 3.517 3.253) scale(13.842)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={0.194} stopColor="#D3D3D0" />
                    <stop offset={0.446} stopColor="#C2C3C2" />
                    <stop offset={0.729} stopColor="#A8AAAA" />
                    <stop offset={0.999} stopColor="#888B8D" />
                </radialGradient>
            </defs>
        </svg>
    );
}
