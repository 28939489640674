import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-6">
        <path
            d="M571.383 269.289c-.687 0-1.379-.03-2.057-.089a236.553 236.553 0 0 1-25.021-3.825c-7.812-1.582-15.744-3.466-24.256-5.76a188.443 188.443 0 0 1-23.557-7.798c-2.296-.929-4.588-1.876-6.881-2.822-5.218-2.154-10.615-4.382-15.989-6.37a32.04 32.04 0 0 1-18.907-41.116 32.138 32.138 0 0 1 30.016-20.905c3.789.001 7.549.678 11.101 1.998l.149.055c5.363 2.016 10.898 3.864 16.251 5.652 2.355.787 4.71 1.573 7.059 2.376.329.111.657.225.984.342l-.01-.022c-1.29-2.663-3.477-7.316-5.234-11.055-.783-1.667-3.195-6.778-3.195-6.778l2.448 1.073c1.508.639 3.132.956 4.769.933 9.88 0 21.722-9.759 22.609-16.627l.301-2.332 1.471 1.835c1.154 1.44 2.691 3.304 4.275 5.226 2.071 2.513 4.214 5.113 5.705 7.009 2.682 3.384 5.299 6.855 7.75 10.121 1.736 2.275 3.456 4.621 5.119 6.89.813 1.111 1.628 2.22 2.446 3.327 5.493 7.549 10.233 14.378 14.495 20.885l6.912 10.622 6.962 10.581a23.345 23.345 0 0 1 3.385 17.67 23.349 23.349 0 0 1-10.101 14.887 23.42 23.42 0 0 1-15.345 3.744c-1.21.181-2.431.272-3.654.273Z"
            fill="url(#man-34-25-jacket-left-option-6-a)"
        />
        <path
            d="m501.228 185.591-.027-.02-.006-.004c-9.03-6.966-19.757-12.624-32.792-17.296l-2.654-.951 21.104 34.814.856-.526c2.396-1.468 14.36-9.028 14.61-13.676a2.666 2.666 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-jacket-left-option-6-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-6-a"
                x1={483.592}
                y1={355.819}
                x2={542.797}
                y2={181.457}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-6-b"
                x1={429.797}
                y1={337.548}
                x2={489.001}
                y2={163.187}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
