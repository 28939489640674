import {
    M_ARM_LEFT_SKINS,
    M_ARM_RIGHT_SKINS,
    M_BODY_CLOTHES,
    M_BODY_SKIN_POSITION,
    M_BOTH_ARMS_CYCLING_POSITION_1,
    M_BOTH_ARMS_CYCLING_POSITION_2,
    M_BOTH_ARMS_POSITION,
    M_BOTH_ARMS_SKINS,
    M_LEFT_ARM_POSITION,
    M_RIGHT_ARM_POSITION,
    M_HEAD_SKIN,
} from 'components/parts/MaleBodySettings';
import {
    F_ARM_LEFT_SKINS,
    F_ARM_RIGHT_SKINS,
    F_BODY_CLOTHES,
    F_BODY_SKIN_POSITION,
    F_LEFT_ARM_POSITION,
    F_RIGHT_ARM_POSITION,
    F_BOTH_ARMS_POSITION,
    F_BOTH_ARMS_SKINS,
    F_HEAD_SKIN
} from 'components/parts/FemaleBodySettings';

import { HAIR_STYLE_COLOR, M_EMOTIONS, M_HAIR_STYLE, M_NOSES } from 'components/parts/MaleHeadSettings';
import { F_EMOTIONS, F_HAIR_STYLE, F_NOSES } from 'components/parts/FemaleHeadSettings';

import { M_LEGS, M_LEGS_SKINS, M_POSES, M_SHOES } from 'components/parts/MaleLegsSettings';
import { F_POSES, F_SHOES, F_LEGS, F_LEGS_SKIN } from 'components/parts/FemaleLegsSettings';
import { IBodyClothes, ILegs, TBodyPosition, TGender, THairColor, THairStyle, TLegsSkins, TSkinEl } from 'interfaces';

const rootSettings: Record<TGender, {
    BODY_CLOTHES: Record<string, IBodyClothes>;
    HEAD_SKIN: TSkinEl;
    ARM_LEFT_SKINS: Record<string, TSkinEl>;
    ARM_RIGHT_SKINS: Record<string, TSkinEl>;
    BODY_SKIN_POSITION: Record<TBodyPosition, TSkinEl | null>;
    LEFT_ARM_POSITION: Record<string, string>;
    RIGHT_ARM_POSITION: Record<string, string>;
    BOTH_ARMS_POSITION: Record<string, string> | null;
    BOTH_ARMS_SKINS: {
        normal: Record<string, [TSkinEl, TSkinEl]>,
        cycling1?: Record<string, [TSkinEl, TSkinEl]>,
        cycling2?: Record<string, [TSkinEl, TSkinEl]>
    } | null;
    BOTH_ARMS_CYCLING_POSITION_1: Record<string, string> | null;
    BOTH_ARMS_CYCLING_POSITION_2: Record<string, string> | null;
    LEGS: Record<string, ILegs>;
    POSES: Record<string, string>;
    SHOES: Record<string, ILegs>;
    LEGS_SKINS: TLegsSkins;
    NOSES: [string, TSkinEl][];
    EMOTIONS: [string, (() => JSX.Element)][];
    HAIR_STYLE_COLOR: THairColor[];
    HAIR_STYLE: THairStyle[];
    gender: TGender;
}> = {
    [TGender.male]: {
        BODY_CLOTHES: M_BODY_CLOTHES,
        HEAD_SKIN: M_HEAD_SKIN,
        ARM_LEFT_SKINS: M_ARM_LEFT_SKINS,
        ARM_RIGHT_SKINS: M_ARM_RIGHT_SKINS,
        BODY_SKIN_POSITION: M_BODY_SKIN_POSITION,
        LEFT_ARM_POSITION: M_LEFT_ARM_POSITION,
        RIGHT_ARM_POSITION: M_RIGHT_ARM_POSITION,
        BOTH_ARMS_POSITION: M_BOTH_ARMS_POSITION,
        BOTH_ARMS_SKINS: M_BOTH_ARMS_SKINS || {},
        BOTH_ARMS_CYCLING_POSITION_1: M_BOTH_ARMS_CYCLING_POSITION_1,
        BOTH_ARMS_CYCLING_POSITION_2: M_BOTH_ARMS_CYCLING_POSITION_2,
        LEGS: M_LEGS,
        POSES: M_POSES,
        SHOES: M_SHOES,
        LEGS_SKINS: M_LEGS_SKINS,
        NOSES: M_NOSES,
        EMOTIONS: M_EMOTIONS,
        HAIR_STYLE_COLOR: HAIR_STYLE_COLOR,
        HAIR_STYLE: M_HAIR_STYLE,
        gender: TGender.male,
    },
    [TGender.female]: {
        BODY_CLOTHES: F_BODY_CLOTHES,
        HEAD_SKIN: F_HEAD_SKIN,
        ARM_LEFT_SKINS: F_ARM_LEFT_SKINS,
        ARM_RIGHT_SKINS: F_ARM_RIGHT_SKINS,
        BODY_SKIN_POSITION: F_BODY_SKIN_POSITION,
        LEFT_ARM_POSITION: F_LEFT_ARM_POSITION,
        RIGHT_ARM_POSITION: F_RIGHT_ARM_POSITION,
        BOTH_ARMS_POSITION: F_BOTH_ARMS_POSITION,
        BOTH_ARMS_SKINS: F_BOTH_ARMS_SKINS,
        BOTH_ARMS_CYCLING_POSITION_1: null,
        BOTH_ARMS_CYCLING_POSITION_2: null,
        LEGS: F_LEGS,
        POSES: F_POSES,
        SHOES: F_SHOES,
        LEGS_SKINS: F_LEGS_SKIN,
        NOSES: F_NOSES,
        EMOTIONS: F_EMOTIONS,
        HAIR_STYLE_COLOR: HAIR_STYLE_COLOR,
        HAIR_STYLE: F_HAIR_STYLE,
        gender: TGender.female,
    },
};

export default rootSettings;
