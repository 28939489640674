import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8898)"
            d="M360.621 263.745c-.712-5.343-1.447-10.868-2.373-16.297a26.506 26.506 0 0112.01-26.885 26.506 26.506 0 0136.543 8.316 26.513 26.513 0 013.693 9.658l.021.128c.576 3.48 1.235 6.946 1.892 10.26a22.626 22.626 0 00-12.033-3.015 44.128 44.128 0 00-14.632 2.815c-13.896 4.94-21.831 12.891-24.916 16.54l-.205-1.52z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M360.658 264.02c-.737-5.535-1.471-11.071-2.409-16.572-1.846-10.82 4.335-23.046 13.299-27.167M410.492 238.539l.022.13c.54 3.263 1.151 6.514 1.792 9.76M412.408 248.926a22.623 22.623 0 00-12.032-3.016 44.174 44.174 0 00-14.632 2.815c-13.897 4.94-21.831 12.892-24.916 16.54"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8898"
                x1="359.773"
                x2="396.785"
                y1="221.5"
                y2="260.247"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
