import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10161)"
            d="M357.398 261.493c.31-4.627.654-9.816.803-14.948a26.364 26.364 0 0127.257-25.721 26.53 26.53 0 0125.721 27.257l-.005.138c-.14 4.103-.156 8.147-.151 11.915a151.675 151.675 0 00-24.533-2.367c-1.405 0-2.773.032-4.065.096a136.412 136.412 0 00-25.027 3.63z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M357.883 253.877c.137-2.442.25-4.885.321-7.329.211-7.274 3.889-15.154 9.599-19.275M411.182 248.078l-.004.137c-.079 2.318-.12 4.638-.139 6.958"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M411.174 248.221c-.14 4.103-.156 8.147-.151 11.915a151.563 151.563 0 00-24.533-2.367c-1.405 0-2.773.032-4.065.095a136.5 136.5 0 00-25.027 3.631c.31-4.627.654-9.816.803-14.948"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10161"
                x1="360.154"
                x2="400.743"
                y1="224.783"
                y2="265.373"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
