import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M479.229 236.312c-.182-4.781-.96-19.424-3.838-27.14a101.214 101.214 0 0 1 18.684 14.399 1.708 1.708 0 0 1 .536 1.598c-.512 2.966-8.018 7.688-15.382 11.143Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-6-left-a)"
        />
        <path
            d="M539.26 337.069c-.82 0-1.638-.014-2.453-.042a53.115 53.115 0 0 1-11.205-1.365c-1.086-.256-2.171-.526-3.256-.794-2.449-.609-4.981-1.237-7.485-1.694a19.389 19.389 0 0 1-12.242-7.69l-.038-.031c-.143-.12-.287-.241-.425-.366a169.153 169.153 0 0 1-14.186-15.054c-4.188-4.97-8.262-10.197-12.454-15.983a131.79 131.79 0 0 1-10.726-17.049 586.8 586.8 0 0 1-2.792-5.352c-2.1-4.053-4.273-8.245-6.578-12.258a27.507 27.507 0 0 1 2.069-30.487 27.505 27.505 0 0 1 28.958-9.755A27.505 27.505 0 0 1 503.115 232l.08.141c2.282 4.028 4.784 8.032 7.204 11.904 1.066 1.708 2.133 3.415 3.186 5.13a131.79 131.79 0 0 1 9.215 17.917c2.844 6.549 5.27 12.716 7.412 18.845a213.036 213.036 0 0 1 3.362 10.546 53.678 53.678 0 0 1 10.311 2.679c.881.306 1.77.637 2.657.984.924.341 1.858.735 2.648 1.075l2.603 1.103c.575.246 1.14.52 1.705.793.305.147.61.294.915.437a69.155 69.155 0 0 1 10.324 5.963l2.212 1.588-2.714.221c-6.567.532-11.76 3.466-14.623 8.261-2.728 4.566-2.924 10.305-.54 15.742l.571 1.303-1.42.097c-.332.022-.666.05-1 .076-.63.051-1.259.102-1.884.125l-2.835.09c-.765.03-1.536.052-2.303.052l-.941-.003Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-6-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-6-left-a"
                x1={468.497}
                y1={208.549}
                x2={570.859}
                y2={329.127}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-6-left-b"
                x1={453.567}
                y1={221.219}
                x2={555.928}
                y2={341.798}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
