import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-6-cycling-2-left-a)">
        <path
            d="M431.922 680.086a5.109 5.109 0 0 1-3.047-1c-6.576-4.831-18.038-13.371-20.911-16.277a70.756 70.756 0 0 1-12.544-16.906 41.147 41.147 0 0 0-7.506-10.152l-14.39-14.36a9.995 9.995 0 0 1 .405-14.54c11.135-9.925 18.741-17.021 21.828-20.355.454-.491 14.26-16.609 14.515-16.934l29.006 25.374c-.001.037-17.665 14.438-17.669 15.236-.037 6.243.311 33.704 8.806 49.957 2.224 4.259 4.826 9.733 6.178 12.617a5.152 5.152 0 0 1-2.19 6.704 5.16 5.16 0 0 1-2.481.636Z"
            fill="#E2E2E2"
        />
        <path
            d="M430.516 680.214a6.144 6.144 0 0 1-1.654-.86c-6.614-4.834-18.148-13.382-21.072-16.324a71.688 71.688 0 0 1-12.764-17.116 40.135 40.135 0 0 0-7.351-9.886l-14.182-14.078a11.445 11.445 0 0 1-3.498-7.97 10.903 10.903 0 0 1 3.658-8.27c11.302-10.127 12.571-11.058 15.488-14.267.111-.116.234-.221.368-.311a3.086 3.086 0 0 1 2.984-.226 3.095 3.095 0 0 1 1.776 2.409c.643 4.592 3.548 15.729 16.63 17.95 3.159.323 6.335.47 9.511.438a3.233 3.233 0 0 1 3.186 2.593c.172 1.053.216 2.123.133 3.187-.233 3.869-.812 24.693 8.099 41.638 2.192 4.169 4.735 9.473 6.124 12.41a6.453 6.453 0 0 1 .279 5.071 6.17 6.17 0 0 1-7.715 3.612Z"
            fill="#63666A"
        />
        <path
            d="M400.929 635.933c-5.083-15.709-17.866-27.474-24.697-32.538-.84.756-1.694 1.524-2.579 2.317a10.894 10.894 0 0 0-3.658 8.27 11.445 11.445 0 0 0 3.498 7.97l14.182 14.078a40.13 40.13 0 0 1 7.351 9.885 71.693 71.693 0 0 0 12.764 17.117c2.924 2.942 14.458 11.49 21.072 16.324a6.094 6.094 0 0 0 4.658 1.087c-5.62-5.799-27.629-29.171-32.591-44.51Z"
            fill="#E2E2E2"
        />
        <defs>
            <clipPath id="man-34-25-shoes-6-cycling-2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
