import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-3-stand-1">
            <path
                d="M405.519 860.676a9.136 9.136 0 0 1-9.13-9.675c.913-15.623 1.226-25.837.88-28.76l32.733-.6c.107.167 9.482 16.793 16.996 21.852 3.341 2.25 9.955 5.864 13.601 7.817a4.983 4.983 0 0 1 .703 8.313 4.975 4.975 0 0 1-3.06 1.053h-52.723Z"
                fill="#E2E2E2"
            />
            <path
                d="M430.542 824.594c1.544-.012 2.309.836 3.174 2.116 3.103 4.595 8.237 12.638 14.032 16.54 3.247 2.187 9.658 5.696 13.326 7.665a6.106 6.106 0 0 1 3.296 5.057 5.902 5.902 0 0 1-5.89 6.134h-54.467a10.462 10.462 0 0 1-7.404-2.918 9.963 9.963 0 0 1-2.955-7.725c.645-11.018.99-12.854 1.017-18.064.012-2.52.436-7.546 2.803-8.408 14.876 4.429 33.068-.397 33.068-.397Z"
                fill="url(#female-34-25-shoes-3-stand-1-a)"
            />
            <path
                d="M409.11 857.566c-5.969-2.42-12.757-9.857-15.084-12.55a988.542 988.542 0 0 1-.356 6.445 9.962 9.962 0 0 0 2.955 7.725 10.464 10.464 0 0 0 7.404 2.918h54.451a5.89 5.89 0 0 0 5.006-2.792c-14.504.113-47.44 1.066-54.376-1.746ZM520.494 860.433c-23.625 0-36.847-9.553-38.655-10.951l-24.54-5.177a9.097 9.097 0 0 1-6.902-11.295c4.053-14.853 5.731-21.89 5.99-24.768l35.674 5.693c.651.867 11.368 23.279 28.249 30.803 8.834 3.938 13.006 7.396 12.398 10.278-.704 3.341-5.384 5.417-12.214 5.417Z"
                fill="#E2E2E2"
            />
            <path
                d="M494.024 816.545a3.107 3.107 0 0 1 2.23 1.402c5.204 8.035 14.518 21.445 24.463 25.878 12.596 5.614 13.435 9.193 12.97 11.397-.82 3.889-5.752 6.21-13.193 6.21-15.966 0-27.279-4.328-33.596-7.638a60.069 60.069 0 0 0-15.488-5.491l-14.01-2.955a10.495 10.495 0 0 1-6.668-4.404 10.003 10.003 0 0 1-1.3-8.197c1.757-6.44 3.197-11.915 4.284-16.278a3.214 3.214 0 0 1 2.874-2.488c4.328-.225 6.248 8.606 13.64 9.605 7.862 1.062 19.875-7.518 23.794-7.041Z"
                fill="#001489"
            />
            <path
                d="M533.36 856.216a16.368 16.368 0 0 1-7.088 2.282c-10.202 1.36-34.319-8.132-45.488-11.927-10.147-3.448-15.542-12.723-30.207-18.055-.366 1.361-.746 2.766-1.145 4.228a10.003 10.003 0 0 0 1.3 8.198 10.497 10.497 0 0 0 6.669 4.403l14.009 2.956a59.995 59.995 0 0 1 15.488 5.491c6.317 3.31 17.631 7.638 33.596 7.638 6.778 0 11.463-1.931 12.866-5.214Z"
                fill="#E2E2E2"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-3-stand-1-a"
                    x1={393.636}
                    y1={843.35}
                    x2={464.375}
                    y2={843.35}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#001489" />
                    <stop offset={1} stopColor="#0147A7" />
                </linearGradient>
            </defs>
        </g>
    );
}
