import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-7">
        <path
            d="M500.231 186.165c-9.226-7.122-19.957-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-7-a)"
        />
        <path
            d="M538.545 246.392c-2.2-5.805-4.69-11.617-7.608-17.758a112.57 112.57 0 0 0-9.48-16.688c-1.11-1.621-2.236-3.232-3.362-4.843-2.46-3.518-5.004-7.156-7.318-10.843l-.148-.233a31.883 31.883 0 0 0-26.982-14.753 31.893 31.893 0 0 0-17.221 5.051 31.778 31.778 0 0 0-9.015 8.643 31.767 31.767 0 0 0-4.991 11.449 31.773 31.773 0 0 0-.263 12.486 31.766 31.766 0 0 0 4.566 11.624c2.344 3.663 4.549 7.515 6.681 11.24a565.208 565.208 0 0 0 2.944 5.106 112.455 112.455 0 0 0 11.042 15.69c4.317 5.27 8.51 10.002 12.823 14.47 2.364 2.429 5.249 5.32 8.532 8.274 7.052-17.113 24.402-33.053 39.8-38.915Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-7-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-7-a"
                x1={451.956}
                y1={346.457}
                x2={487.522}
                y2={161.686}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-7-b"
                x1={471.639}
                y1={350.246}
                x2={507.205}
                y2={165.475}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
