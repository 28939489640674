import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6918)"
            d="M478.235 236.718c-.166-4.364-.928-19.194-3.775-26.828l-1.017-2.726 2.478 1.524a102.454 102.454 0 0118.859 14.537 2.702 2.702 0 01.823 2.482c-.788 4.559-13.419 10.694-15.944 11.878l-1.367.641-.057-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6918)"
            d="M524.734 343.438a19.603 19.603 0 01-15.48-7.691c-.228-.2-.454-.403-.674-.615a216.126 216.126 0 01-15.74-17.397c-4.676-5.704-9.263-11.704-14.026-18.344a171.81 171.81 0 01-12.303-19.416 769.036 769.036 0 01-3.279-6.088c-2.464-4.598-5.011-9.353-7.685-13.922a27.508 27.508 0 0123.709-41.395 28.01 28.01 0 0123.978 13.642l.069.12c2.654 4.586 5.31 9.125 8.092 13.541 1.228 1.949 2.45 3.901 3.668 5.855a171.652 171.652 0 0110.802 20.295c3.409 7.398 6.358 14.351 9.013 21.249a300.118 300.118 0 013.485 9.6c.847-.099 1.694-.196 2.541-.292a131.69 131.69 0 0117.95-.733c.98.031 1.952.052 2.925.073 1.985.042 4.039.086 6.077.226l4.475.244c.981.056 1.963.141 2.944.226.526.045 1.051.091 1.575.132 3.475.3 6.353.605 9.066.958l1.386.18-.619 1.254c-3.84 7.773-1.216 21.425 2.275 27.409l.64 1.096-1.215.365c-2.63.79-5.421 1.556-8.784 2.409-.52.128-1.033.258-1.546.388-.953.242-1.906.484-2.862.698l-4.392.968c-1.977.465-3.998.841-5.953 1.204-.955.177-1.909.355-2.863.543a132.7 132.7 0 01-17.837 2.18c-1.79.094-3.581.173-5.372.253-4.068.182-8.275.369-12.39.716-.548.046-1.103.069-1.65.069z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6918"
                x1="491.578"
                x2="454.872"
                y1="201.756"
                y2="278.864"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6918"
                x1="451.768"
                x2="589.598"
                y1="281.004"
                y2="281.004"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
