import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6557)"
            d="M541.444 345.165c-1.644-19.046-9.339-34.033-11.686-39.733-4.435-10.77-5.245-21.785-2.281-31.019 1.718-5.35 1.501-12.025-.646-19.843-5.892-21.467-25.106-63.041-52.273-82.894a89.767 89.767 0 00-9.312-5.856s1.382 9.15-2.196 14.192a9.779 9.779 0 01-5.679 3.396 22.42 22.42 0 01-3.997.359c-7.456.001-14.533-3.372-23-10.928-1.916-1.71-3.865-3.777-5.927-5.966-4.213-4.472-8.978-9.496-14.871-12.904-17.725 7.173-34.144 16.035-48.889 27.893a50.228 50.228 0 00-18.456 40.175l.457 19.059a327.337 327.337 0 0010.716 77.382c.032 3.804.033 7.485.035 11.171.008 20.126 5.373 84.666 5.373 84.666s70.531-10.553 97.519-7.586c33.729 3.709 63.99 2.378 75.052-13.644 0 0 12.474-19.942 10.061-47.92z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6557)"
            d="M544.637 373.52c-.329-40.884-10.329-59.23-15.023-71.718a44.915 44.915 0 01-2.379-22.436l.844-5.654a57.897 57.897 0 00-5.671-35.681l-17-32a98.496 98.496 0 00-30.512-34.331l-7.721-4.899-.299 2.106s26.404 38.178 44.339 69.049c13.76 23.683 2.024 54.374 9.088 63.333 12.81 16.247 21.876 45.634 18.876 71.327l5.458.904z"
        ></path>
        <path
            fill="#fff"
            d="M468.225 168.879c27.881 17.212 28.083 18.737 26.897 24.745a53.576 53.576 0 018.456 4.075l11.054 47.572c-8.707-17.246-26.431-45.449-46.407-76.392z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M544.977 373.01c-.396-40.585-10.685-58.76-15.363-71.205a44.915 44.915 0 01-2.379-22.436l.844-5.654a57.592 57.592 0 00-6.094-35.511l-6.146-11.575"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M465.516 166.441s27.763 40.645 45.699 71.516c13.76 23.683 2.024 54.374 9.087 63.333 12.727 16.14 22.057 47.174 19.057 71.363"
        ></path>
        <path
            fill="url(#paint2_linear_10977_6557)"
            d="M380.023 168.88l30.028-16.068 33.7 81.652a96.206 96.206 0 017.276 36.703v331.838h-110.82l-.841-375.174a59.619 59.619 0 0126.301-49.575l14.356-9.376z"
        ></path>
        <path
            fill="#fff"
            d="M410.05 152.812c-9.787 31.687-2.35 34.448 3.954 37.102-7.299 9.29-6.774 10.843-6.774 10.843l41.053 47.595-38.233-95.54z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M380.023 168.88l30.028-16.068 33.7 81.652a96.206 96.206 0 017.276 36.703v331.838h-110.82l-.841-375.174a59.619 59.619 0 0126.301-49.575l14.356-9.376zM355.519 428.699h59.674"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6557"
                x1="311.255"
                x2="519.447"
                y1="291.604"
                y2="275.989"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#22ADE0"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6557"
                x1="466.876"
                x2="544.637"
                y1="270.16"
                y2="270.16"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_10977_6557"
                x1="393.213"
                x2="560.417"
                y1="398.184"
                y2="306.449"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
