import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2175}
            height={947}
            viewBox="0 0 2175 947"
            fill="none"
            id="scene-kitchen-1"
            {...attributes}
        >
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M544.523 925.273c-4.355 6.07-11.472 10.025-19.513 10.025H50.063c-13.256 0-24-10.745-24-24v-783.79c0-13.255 10.744-24 24-24H525.01c13.255 0 24 10.745 24 24v782.779"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M123.684 497.965v18.841h18.841v-18.841M212.504 535.646v-18.841h-31.832v18.841h31.832ZM236.828 472.957v-90.163c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v317.937h50.706V382.794c0-6.906 5.599-12.505 12.505-12.505 6.906 0 12.505 5.599 12.505 12.505v291.789M192.293 422.933v-25.011h-80.589v25.011h80.589ZM111.703 447.945h80.589"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M549.007 910.288H51.07V128.52h236.463v740.968"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M123.686 497.965H92.758v86.047h119.748v-67.206h-31.832v48.512"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M236.829 472.953H1.051v136.069h236.463V497.964h-94.989"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M867.203 370.099h-46.95v-309.9H1257.2"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1127.9 23.75V397.7H791.602V33.65h305.548V370.1H918.202"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m918.205 370.401 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5M1678.88 1.398h-192v133.5h-138l-93 58.5h304.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1351.88 1.398v112.5h117l115.5 79.5v36h-342v-34.5M609 467.898h1357.62l55.5 40.5H785v19.5h1246.12v28.5h-433v105M1617.63 581.898v40.5M1581.63 581.898v40.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M32.051 946H2173.05"
            />
        </svg>
    );
}

