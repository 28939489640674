import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-down">
        <path
            d="M391.087 186.014a31.014 31.014 0 0 0-23.662-1.747 31.006 31.006 0 0 0-17.968 15.496c-3.833 7.613-8.055 15.028-12.109 22.528a200.094 200.094 0 0 0-10.734 23.226 439.306 439.306 0 0 0-8.268 22.578l49.513 25.514a407.604 407.604 0 0 0 13.537-20.345 200.565 200.565 0 0 0 12.373-22.394c3.648-7.707 7.128-15.498 10.998-23.092l.068-.134a30.987 30.987 0 0 0 1.747-23.662 31.002 31.002 0 0 0-15.495-17.968Z"
            fill="url(#man-34-25-shirt-1-arm-right-downa)"
        />
        <path
            d="M348.405 199.289c-3.832 7.613-18.688 38.251-21.791 46.233a439.306 439.306 0 0 0-8.268 22.578l49.513 25.514a407.142 407.142 0 0 0 13.537-20.345 200.476 200.476 0 0 0 12.373-22.394c3.648-7.706 7.128-15.498 10.998-23.092l.068-.134"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-downa"
                x1={386.455}
                y1={323.455}
                x2={342.214}
                y2={162.106}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.419} stopColor="#fff" />
                <stop offset={0.913} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
