import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Juice': '#FFD100',
    'Yogurt': 'url(#object-food-juice-a)',
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={89}
            height={194}
            fill="none"
            id="object-food-juice"
            {...attributes}
        >
            <path fill="#fff" d="M81.957 1H7.164v14.781h74.793V1Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M81.957 1H7.164v14.781h74.793V1ZM87.47 49.312c0-3.025-1.236-5.918-3.421-8.01l-8.707-8.332a6.925 6.925 0 0 1-2.138-5.005V15.783h-57.29v12.182c0 1.89-.771 3.698-2.138 5.005L5.07 41.303a11.085 11.085 0 0 0-3.422 8.009v131.794c0 6.122 4.964 11.086 11.086 11.086h63.649c6.122 0 11.086-4.964 11.086-11.086l.001-131.794Z"
            />
            <path
                fill={STYLES[variation as keyof typeof STYLES]}
                d="M11.348 52.46v128.644c0 .764.62 1.386 1.385 1.386h63.65c.766 0 1.386-.622 1.386-1.386V52.461H11.348Z"
            />
            <defs>
                <linearGradient
                    id="object-food-juice-a"
                    x1={44.559}
                    x2={44.559}
                    y1={52.461}
                    y2={182.49}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#E3E3E1" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
            </defs>
        </svg>
    );
}
  
