import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-down">
        <path
            fill="url(#man-34-25-tshirt-arm-left-down-a)"
            d="M501.259 185.2c-9.4-7.9-29.9-17.3-35.2-18.2l19.9 32.1s19.6-10.3 15.3-13.9Z"
        />
        <path
            fill="url(#man-34-25-tshirt-arm-left-down-b)"
            d="M482.358 294.9c-3.8-7.3-7.501-14.8-11.201-22.9-3.7-8-6.899-16.1-9.399-24.1-.8-2.5-1.6-4.9-2.3-7.4-1.8-5.6-3.6-11.4-5.5-17.1-2.8-8.1-2.4-16.8 1.3-24.5 3.7-7.7 10.2-13.5 18.2-16.3 3.4-1.2 7-1.8 10.6-1.8 13.6 0 25.7 8.6 30.2 21.4v.1c2 5.7 4.1 11.3 6.3 16.8.9 2.4 1.9 4.8 2.8 7.3 3 7.8 5.6 16.1 7.6 24.7 2.2 8.9 4.1 17.2 5.6 25.4l.4 2.1-54.301 17.1-.299-.8Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-left-down-a"
                x1={466.144}
                x2={501.913}
                y1={183.032}
                y2={183.032}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-arm-left-down-b"
                x1={463.717}
                x2={550.898}
                y1={281.31}
                y2={165.617}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
