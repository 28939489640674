import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7164)"
            d="M476.744 231.748a64.86 64.86 0 001.406-10.604 33.85 33.85 0 00-1.403-11.519 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.598c-.522 3.029-8.331 7.885-15.838 11.354l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7164)"
            d="M586.122 300.462c-2.39-1.756-5.027-3.522-8.063-5.403a87.796 87.796 0 01-.83-.51l-.216-.133a62.835 62.835 0 00-2.043-1.231l-3.075-1.739c-1.404-.827-2.846-1.565-4.262-2.289-.682-.35-1.363-.698-2.051-1.062a82.892 82.892 0 00-13.155-5.469 511.613 511.613 0 00-4.047-1.251 732.775 732.775 0 01-3.562-1.101c-2.17-3.891-4.681-7.91-7.896-12.64-3.464-5.054-7.204-10.061-11.432-15.306a117.047 117.047 0 00-12.936-13.94 474.41 474.41 0 00-4.066-3.691l-.196-.177c-3.17-2.861-6.448-5.819-9.524-8.861l-.121-.12a27.752 27.752 0 00-38.782 39.704c3.114 3.04 6.143 6.283 9.073 9.419a667.627 667.627 0 003.888 4.139c4.366 4.607 9.1 8.851 14.156 12.689 5.32 4.14 10.394 7.795 15.514 11.176a151.295 151.295 0 0016.942 9.813c.574.274 1.172.523 1.825.76a19.795 19.795 0 004.582 2.346c3.034 1.07 6.079 2.319 9.061 3.542 1.308.536 2.616 1.073 3.928 1.597a83.048 83.048 0 0013.655 4.095c.748.15 1.493.311 2.237.472 1.538.332 3.13.676 4.739.924l3.509.606c.798.134 1.619.242 2.343.337l.212.028c.34.045.681.09 1.03.14 1.724.228 3.555.433 5.443.611l1.608.15-.257-1.596a24.96 24.96 0 012.127-12.633 23.11 23.11 0 0110.409-11.247l1.966-.877-1.733-1.272z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7164"
                x1="455.608"
                x2="634.524"
                y1="209.703"
                y2="316.401"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7164"
                x1="443.62"
                x2="622.536"
                y1="229.804"
                y2="336.503"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
