import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6502)"
            d="M448.225 372.451a125.853 125.853 0 00-24.054-5.368c-21.11-2.564-42.302-.867-56.821 21.948-6.401 10.058-8.864 22.784-8.36 35.034a69.919 69.919 0 006.755 27.42c9.781 20.291 31.365 26.863 44.179 26.863 0 0-16.517-16.447-14.802-49.192 1.627-31.06 24.541-55.293 53.103-56.705z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6502)"
            d="M515.069 424.873l1.056 2.775c6.716-6.112 13.469-16.501 15.588-34.097-4.896 7.091-13.557 11.3-24.61 13.472a44.882 44.882 0 017.966 17.85z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6502"
                x1="362.356"
                x2="432.553"
                y1="493.638"
                y2="331.554"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729"></stop>
                <stop offset="0.225" stopColor="#312E30"></stop>
                <stop offset="0.564" stopColor="#434245"></stop>
                <stop offset="0.972" stopColor="#5F6267"></stop>
                <stop offset="0.999" stopColor="#616469"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6502"
                x1="498.428"
                x2="542.362"
                y1="428.354"
                y2="391.644"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729"></stop>
                <stop offset="0.225" stopColor="#312E30"></stop>
                <stop offset="0.564" stopColor="#434245"></stop>
                <stop offset="0.972" stopColor="#5F6267"></stop>
                <stop offset="0.999" stopColor="#616469"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
