import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9042)"
            d="M409.814 264.172c-3.73-1.292-15.909-5.314-26.368-6.552-9.185-1.088-22.511-1.3-26.61-1.34 1.19-5.305 2.257-10.155 3.182-15.045a26.498 26.498 0 0130.967-21.108 26.498 26.498 0 0121.109 30.967l-.024.123c-.835 4.302-1.577 8.74-2.256 12.955z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M366.232 228.992a23.423 23.423 0 00-6.214 12.243c-.863 4.556-1.849 9.087-2.861 13.614l-.321 1.43c4.099.04 17.424.252 26.61 1.34 10.459 1.239 22.638 5.261 26.368 6.553 0 0 1.556-9.352 2.256-12.957l.023-.121"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9042"
                x1="364.973"
                x2="396.202"
                y1="205.842"
                y2="273.071"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
