import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-5">
        <path
            d="M418.168 248.993a146.884 146.884 0 0 0-4.333-21.474 857.839 857.839 0 0 0-1.802-6.315c-1.377-4.777-2.8-9.717-3.991-14.618l-.043-.175a31.935 31.935 0 0 0-31.028-24.27 32.07 32.07 0 0 0-7.76.956 32.037 32.037 0 0 0-23.314 38.789c1.219 4.891 2.251 9.928 3.249 14.799.44 2.144.88 4.288 1.335 6.424a146.708 146.708 0 0 0 6.127 21.028c2.651 7.319 5.335 14.006 8.205 20.447 2.429-2.604 5.465-10.447 25.128-17.965 17.939-6.859 29.814-3.711 29.814-3.711a322.75 322.75 0 0 0-1.587-13.915Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-5a)"
        />
        <path
            d="M365.771 283.391c2.475-3.343 6.936-10.182 24.17-16.772 14.763-5.644 25.419-4.511 28.73-3.933"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M375.906 304.056a184.114 184.114 0 0 1-10.181-19.884c-2.997-6.726-5.695-13.526-8.177-20.379a144.915 144.915 0 0 1-6.089-20.892c-1.511-7.091-2.839-14.228-4.592-21.26M407.027 206.648l.042.173c1.712 7.042 3.849 13.98 5.802 20.963a144.963 144.963 0 0 1 4.304 21.331 313.201 313.201 0 0 1 1.998 18.702"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-5a"
                x1={397.982}
                y1={292.467}
                x2={347.886}
                y2={112.9}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
