import { THairColor, THairStyle, TSkinEl } from 'interfaces';

import Nose1Skin from 'assets/male/skin-layer/man-bold-34-25-nose-1';
import Nose2Skin from 'assets/male/skin-layer/man-bold-34-25-nose-2';
import Nose3Skin from 'assets/male/skin-layer/man-bold-34-25-nose-3';
import EmotionNormal from 'assets/male/emotions/man-34-25-emotion-normal';
import EmotionWideSmile from 'assets/male/emotions/man-34-25-emotion-wide-smile';
import EmotionConfused from 'assets/male/emotions/man-34-25-emotion-confused';
import HairCut1 from 'assets/male/hairs/man-34-25-haircut-1';
import HairCut2 from 'assets/male/hairs/man-34-25-haircut-2';
import HairCut3 from 'assets/male/hairs/man-34-25-haircut-3';
import HairCut4 from 'assets/male/hairs/man-34-25-haircut-4';
import HairCut5 from 'assets/male/hairs/man-34-25-haircut-5';
import HairCut6 from 'assets/male/hairs/man-34-25-haircut-6';
import HairCut7 from 'assets/male/hairs/man-34-25-haircut-7';
import HairCut8 from 'assets/male/hairs/man-34-25-haircut-8';

export const M_NOSES: [string, TSkinEl][] = [
    ['Nose 1', Nose1Skin],
    ['Nose 2', Nose2Skin],
    ['Nose 3', Nose3Skin],
];

export const M_EMOTIONS: [string, () => JSX.Element][] = [
    ['Normal', EmotionNormal],
    ['Wide smile', EmotionWideSmile],
    ['Confused', EmotionConfused],
];

export const HAIR_STYLE_COLOR: THairColor[] = ['gray', 'brown', 'black', 'yellow', 'red'];

export const M_HAIR_STYLE: THairStyle[] = [
    ['Haircut 1', HairCut1],
    ['Haircut 2', HairCut2],
    ['Haircut 3', HairCut3],
    ['Haircut 4', HairCut4],
    ['Haircut 5', HairCut5],
    ['Haircut 6', HairCut6],
    ['Haircut 7', HairCut7],
    ['Haircut 8', HairCut8],
];
