import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891'],
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="female-34-25-haircut-1" transform="translate(316, 30)">
        <path
            d="M45.102 144.801c2-3.9 6.4-5.8 10.3-7.9 3.9-2.1 8-5.3 8.1-9.7.1-3.4-2.3-6.4-5-8.4s-5.9-3.5-8.4-5.7c-6.6-5.8-7.5-17-1.8-23.6 2.4-2.7 5.6-4.6 8.3-7.1 2.7-2.5 4.9-5.9 4.3-9.5-.5-2.9-2.7-5.1-4.1-7.7-3.2-5.7-2.1-13.3 1.9-18.4 4-5.2 10.7-8 17.2-8s13 2.4 18.3 6.2l2.1 5c-12.7 7.6-22.2 19.8-23.2 32.1-1.7 20.5 4 29.2 28 63.5-1.7 12.9-15.6 26.3-21.7 31.6-.8-.1-1.6-.2-2.3-.4-9.5-1.9-18.2-7-25.3-13.7-2.5-2.4-4.9-5-6.3-8.2-1.7-3.2-2-7-.4-10.1Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M76.935 67.27c-3.7-6.409.202-15.59 8.715-20.505 8.513-4.915 18.415-3.704 22.115 2.705 3.7 6.409-.202 15.589-8.715 20.504-8.513 4.915-18.414 3.705-22.115-2.704Z"
            fill="#FFD201"
        />
        <path
            d="M72.902 82c1-12.3 10.6-24.5 23.2-32.1 7.7-4.6 16.5-7.4 25.2-7.5 21.8-.2 40.3 16.8 42.2 32.5-1.4-2.3-3.1-4.7-5.1-6.9l-2.9-.2c-52.8-5.5-21.7 23.4-38.8 33-3.4 1.9-5.6 5.5-5.7 9.4 0 .6-.1 1-.2 1-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5-.4 13.9-3.9 17.1-3.9 17.1v.6c-24-34.3-29.6-43-27.9-63.5Z"
            fill={HAIR_COLOR[color][0]}
        />
        <path
            d="M85.901 123.701c-11.5-17.7-14.3-26.4-13-41.7 1.6-19.7 25.2-39.4 48.4-39.6 9.7-.1 18.7 3.2 25.9 8.3"
            stroke={HAIR_COLOR[color][1]}
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M110.801 111.2c-.6-.3-1-7.1-6.5-11.3-10.5-8.2-21.5 9-7.9 22.9 2.8 3 6.3 4.4 8.3 5"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
