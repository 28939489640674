import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9281)"
            d="M494.776 222.853a2.707 2.707 0 01.822 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.14 108.14 0 0121.852 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9281)"
            d="M510.406 244.055c-2.421-3.874-4.924-7.88-7.208-11.911l-.079-.139a27.5 27.5 0 00-47.695 27.389c2.305 4.015 4.478 8.206 6.579 12.26.425.821.855 1.637 1.282 2.457 0 0 13.237-12.559 23.647-18.306a140.618 140.618 0 0124.358-10.326c-.296-.474-.588-.95-.884-1.424z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9281"
                x1="487.171"
                x2="466.569"
                y1="204.85"
                y2="283.356"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9281"
                x1="451.76"
                x2="536.403"
                y1="246.153"
                y2="246.153"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
