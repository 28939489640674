import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={976}
            viewBox="0 0 2144 976"
            fill="none"
            id="scene-kitchen-3"
            {...attributes}
        >
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M153 517.004 1827.4 517l55.5 40.5-1153.002.004v19.5L1891.9 577v28.5h-168v105M1742 631v40.5M1706 631v40.5M1196.72 407.112h50.85V71.48H774.34"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M914.379 32v405H1278.6V42.722H947.682v364.386h193.808"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1141.49 407.428-14.62-43.863h12.51c-12.84-8.772-19.66-20.956-19.66-20.956s17.71-1.625 34.11 7.148c.82.487 1.47.812 2.28 1.299-3.9-6.01-7.47-12.996-10.07-20.631-9.91-30.054-.33-60.109-.33-60.109s25.83 18.52 35.74 48.412c.98 3.087 1.79 6.336 2.44 9.422 11.7-18.844 30.05-28.754 30.05-28.754s4.71 25.18-6.33 48.086c-2.93 6.336-6.83 11.697-10.89 16.408h14.62l-14.62 43.863M707.5 37h-192v133.5h-138l-93 58.5H589"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M380.5 37v112.5h117L613 229v36H271v-34.5"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-kitchen-3-a)"
            >
                <path
                    d="M1788.19 27.526v330.063H1609.9V1h356.59v356.589h-147.41M1757.31 332.575V284.07M1819.08 332.575V284.07"
                />
            </g>
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1445.21 65.297V97.21l15.12 15.114M1445.21 22.594v14.489M1370.16 97.21h14.48M1445.21 172.387v-14.489M1520.27 97.21h-14.49"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1382.94 122.957c-3.29-7.931-5.1-16.626-5.1-25.747 0-37.21 30.17-67.37 67.38-67.37 37.21 0 67.37 30.16 67.37 67.37 0 37.21-30.17 67.37-67.37 67.37-18.6 0-35.45-7.54-47.64-19.73l-13.32 13.32c-15.6-15.6-25.26-37.15-25.26-60.96 0-47.61 38.6-86.21 86.22-86.21s86.21 38.6 86.21 86.21c0 47.61-38.6 86.21-86.21 86.21-12.11 0-23.64-2.49-34.09-7"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 975H2142.05"
            />
            <g
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-kitchen-3-b)"
            >
                <path
                    d="M603.706 727.571v183.138H265.898v18.841h356.649V557H265.898v334.868" 
                />
                <path
                    d="M603.709 727.569H284.742v164.297h356.649v-241.35H284.742v58.212h318.967M603.709 615.211H284.742M477.031 679.625h-65.617" 
                />
                <path
                    d="M340.406 692.625c7.18 0 13-5.82 13-13s-5.82-13-13-13c-7.179 0-13 5.82-13 13s5.821 13 13 13ZM548.039 692.625c7.18 0 13-5.82 13-13s-5.82-13-13-13-13 5.82-13 13 5.82 13 13 13ZM340.406 599.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13-7.179 0-13 5.821-13 13 0 7.18 5.821 13 13 13ZM409.617 599.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13ZM478.828 599.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13ZM548.039 599.105c7.18 0 13-5.82 13-13 0-7.179-5.82-13-13-13s-13 5.821-13 13c0 7.18 5.82 13 13 13Z" 
                />
            </g>
            <defs>
                <clipPath id="scene-kitchen-3-a">
                    <path fill="#fff" d="M1608.9 0h358.589v358.589H1608.9z" />
                </clipPath>
                <clipPath id="scene-kitchen-3-b">
                    <path fill="#fff" d="M264.898 556h377.49v374.55h-377.49z" />
                </clipPath>
            </defs>
        </svg>
    );
}

