import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-pants-4-cycling-1-right-a)">
        <path
            d="m468.775 407.025-155.919-45.634c-10.94 10.327-16.554 23.259-18.722 41.476-2.014 16.914 1.205 30.27 6.072 45.686 7.572 22.754 15.75 45.228 25.573 67.002 9.643 21.094 32.167 68.538 32.547 69.336-1 1.425-3.49 5.224-4.791 7.232-11.88 19.511-22.26 44.524-27.233 66.539-2.964 13.12-10.166 63.927-11.075 79.322l40.186 15.394c12.565-30.551 27.094-66.442 39.3-96.853 5.082-12.84 18.902-46.81 24.083-60.104a44.37 44.37 0 0 0 2.494-11.06c1.809-19.494 1.334-38.721.445-54.385a411.25 411.25 0 0 0-5.592-52.441 37.158 37.158 0 0 1 17.227-38.444 22.57 22.57 0 0 1 2.279-1.21 75.71 75.71 0 0 0 33.696-30.38l-.57-1.476Z"
            fill="url(#man-34-25-pants-4-cycling-1-right-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-4-cycling-1-right-b"
                x1={293.524}
                y1={557.385}
                x2={469.345}
                y2={557.385}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <clipPath id="man-34-25-pants-4-cycling-1-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .773)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
