import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-cycling-right">
        <path
            d="M313.65 361.469c-18.245 17.221-17.435 42.417-15.445 61.33 2.973 28.241 58.158 40.28 75.564 47.597 24.145 10.415 49.507-29.143 49.507-29.143s26.877-4.232 44.78-33.825"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M313.65 361.469c-18.245 17.221-17.435 42.417-15.445 61.33 2.973 28.241 58.158 40.28 75.564 47.597 24.145 10.415 49.507-29.143 49.507-29.143s26.877-4.232 44.78-33.825"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M322.082 746.32c.413-7.451.961-14.859 1.645-22.222.343-3.682.603-7.39 1.013-11.05l1.17-10.999c.753-7.341 1.638-14.64 2.159-22.056.591-7.394.837-14.899 1.77-22.182 1.61-14.649 6.666-28.183 12.287-41.535a193.548 193.548 0 0 1 9.827-19.575c.898-1.606 1.91-3.175 2.86-4.764 1.024-1.565 2.002-3.145 3.09-4.689a91.412 91.412 0 0 1 7.022-9.106l8.695-9.83a25.991 25.991 0 0 1 33.049-4.936 25.99 25.99 0 0 1 10.586 31.694l-15.93 40.356-7.976 20.175-8.109 20.131c-5.429 13.414-10.764 26.858-16.269 40.247l-16.442 40.19a15.914 15.914 0 0 1-30.615-6.875l.168-2.974Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M365.111 595.043c-6.59-13.356-12.752-26.829-19.084-40.255l-9.432-20.157-4.723-10.076-2.365-5.038-2.27-5.063c-6.19-13.465-11.392-27.2-16.509-40.957a672.145 672.145 0 0 1-7.299-20.74l-1.729-5.211-1.617-5.242c-1.079-3.494-2.182-6.981-3.13-10.511a50.87 50.87 0 0 1 96.42-31.941l1.98 5.081a199.876 199.876 0 0 1 3.718 10.351l1.698 5.219 1.571 5.254c1.086 3.492 1.996 7.032 2.968 10.556 1 3.515 1.823 7.08 2.707 10.627 3.404 14.225 6.515 28.53 8.655 43.101 1.078 7.283 1.809 14.66 2.379 22.082l.833 11.138c.254 3.72.423 7.462.639 11.192a348.527 348.527 0 0 1-.421 45.578l-.4 4.696a28.835 28.835 0 0 1-54.589 10.316Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m321.914 749.297.169-2.974a593.85 593.85 0 0 1 1.645-22.223c.342-3.682.602-7.39 1.012-11.05l1.17-10.999c.754-7.341 1.638-14.64 2.16-22.056.59-7.393.836-14.899 1.769-22.182 1.61-14.649 6.667-28.183 12.288-41.534a193.452 193.452 0 0 1 9.826-19.576c.898-1.606 1.91-3.175 2.86-4.764 1.024-1.565 3.69-5.664 4.779-7.209M419.7 584.73a52.79 52.79 0 0 1-2.445 10.343l-15.93 40.356-7.976 20.174-8.109 20.132c-5.429 13.413-10.763 26.857-16.269 40.246l-16.442 40.19"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m393.373 399.855 1.98 5.081a201.15 201.15 0 0 1 3.718 10.351l1.698 5.22 1.571 5.254c1.086 3.492 1.996 7.032 2.968 10.555 1 3.516 1.823 7.08 2.706 10.628 3.405 14.225 6.516 28.53 8.656 43.1 1.078 7.283 1.809 14.661 2.379 22.083l.833 11.138c.254 3.719.423 7.462.639 11.192a348.704 348.704 0 0 1-.421 45.578l-.4 4.696M357.932 580.159c-4-8.448-7.92-16.919-11.905-25.37l-9.432-20.157-4.723-10.077-2.365-5.038-2.27-5.063c-6.19-13.465-11.392-27.2-16.509-40.957a672.145 672.145 0 0 1-7.299-20.74l-1.729-5.211-1.617-5.242c-1.079-3.494-2.182-6.981-3.13-10.511"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M322.06 745.094c-1.434 4.096-9.461 18.607-14.748 27.99a8.99 8.99 0 0 0 1.011 10.273 8.997 8.997 0 0 0 2.881 2.226l18.264 8.927a42.212 42.212 0 0 1 10.64 7.35 69.661 69.661 0 0 0 17.096 11.764c3.673 1.826 18.018 6.41 24.908 8.571a4.151 4.151 0 0 0 5.123-2.486 4.142 4.142 0 0 0-.89-4.363c-2.333-2.414-6.478-6.655-9.901-9.906-15.366-14.592-25.171-46.067-25.171-46.067"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M322.06 745.094c-1.434 4.096-9.461 18.607-14.748 27.99a8.99 8.99 0 0 0 1.011 10.273 8.997 8.997 0 0 0 2.881 2.226l18.264 8.927a42.212 42.212 0 0 1 10.64 7.35 69.661 69.661 0 0 0 17.096 11.764c3.673 1.826 18.018 6.41 24.908 8.571a4.151 4.151 0 0 0 5.123-2.486 4.142 4.142 0 0 0-.89-4.363c-2.333-2.414-6.478-6.655-9.901-9.906-15.366-14.592-25.171-46.067-25.171-46.067"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
