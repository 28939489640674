import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-left">
        <path
            fill={SKIN_COLOR[color]}
            d="M497.259 185.195c8.215 4.192 16.628 7.994 24.956 11.964a234.413 234.413 0 0 1 24.257 13.339c7.875 4.862 15.65 9.922 23.285 15.256 7.623 5.359 15.148 10.91 22.35 17.098a23.172 23.172 0 0 1 8.004 15.82 23.17 23.17 0 0 1-5.527 16.846 23.207 23.207 0 0 1-22.838 7.464c-9.247-2.159-18.171-4.953-26.997-7.941-8.814-3.011-17.488-6.297-26.062-9.782a234.23 234.23 0 0 1-25.09-11.699c-8.121-4.376-16.158-8.92-24.392-13.075a30.994 30.994 0 0 1-15.48-17.981 31.01 31.01 0 0 1 1.768-23.66 30.997 30.997 0 0 1 29.987-16.949c4.06.303 8.02 1.403 11.655 3.237l.124.063Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M577.925 283.563a23.27 23.27 0 0 1-6.18-.578c-9.246-2.159-18.17-4.953-26.996-7.941-8.814-3.011-17.489-6.297-26.063-9.782a234.304 234.304 0 0 1-25.089-11.699c-8.122-4.376-16.158-8.92-24.392-13.075m27.93-55.355.124.063c8.215 4.191 16.628 7.993 24.956 11.964a234.225 234.225 0 0 1 24.257 13.339"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M499.944 186.462c-9.226-7.122-19.957-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M467.393 169.293a129.713 129.713 0 0 1 32.551 17.169"
        />
    </g>
);

export default SvgComponent;
