import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-1-stand-2">
        <path
            fill="#888B8D"
            d="M364.902 754.801c28.9-56.1 57.8-94.3 79.5-116.6 7.7-7.7 14.2-15.9 23.9-23-18.9-58.2-26.2-91.4-36.7-143.3-4.1-21.8-10-40.8 5.6-61.7 20.1-27 66.7-21.5 95.4-21.5-6.4 25.7-5.4 30-3 39.4 18.2 74.8 13.6 148.1 9 193.8.5 11.1-3.8 17.6-9.5 23.7-82.3 88-90.5 96.2-132 139.7"
        />
        <path
            fill="url(#man-34-25-pants-1-stand-2-a)"
            d="M390.203 820.701c-.3-1.3-15.7-87.401-17.5-98.201-4.1-21.9-2-56.099 4.3-81.299 2.2-8.9 2.1-18.2-.1-27.1-20.4-81-23.5-156.7-23.7-157.9-4-34.3 2.7-45.801 13.9-64.901.9-1.5 1.8-3.099 2.8-4.699l.3-.5h163l-.3 1.299c-4.6 17.6-14.7 31.801-25.2 40.201-5.2 4.2-9.3 9.499-11.8 15.699-4.4 10.7-10.7 22.2-18.5 28.5-10.8 8.7-16.8 22.001-16.9 35.901-.2 25.1-2.1 40.599-4.2 57.299-4.6 32.6-10.2 55.9-11.5 60.3-.1 5.5-14 192.5-14 192.5"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-1-stand-2-a"
                x1={299.183}
                x2={551.863}
                y1={797.156}
                y2={307.661}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
