import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={113}
            height={229}
            fill="none"
            id="object-office-dryer"
            {...attributes}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M77.411 227.003v-28.261h-41.79v9.42c.934 1.158 8.291 9.964 20.822 9.999 5.655.015 10.268-1.761 13.72-3.83"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M111.337 23.633v203.369H1.695V26.97M1.695 1.148h109.642M20.535 208.162V45.812M92.496 19.992v188.171M20.535 19.992h58.897M1.695 1.148 20.536 19.99M111.337 1.148 92.497 19.99M1.695 26.969l18.841 18.84"
            />
        </svg>
    );
}
