import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6799)"
            d="M575.066 555.291c-.158-11.596-.451-32.254-.603-44.102-.313-16.689-1.164-60.164-1.503-77.477-.245-12.186-9.771-22.486-20.406-28.421-11.834-6.605-24.162-14.209-33.006-18.29a166.523 166.523 0 00-41.6-13.244 291.632 291.632 0 00-37.909-5.247c-6.508-.489-14.863-.219-21.371-.013-16.929.305-38.228 6.697-49.559 20.63-16.443 20.219-8.522 56.634 4.263 72.043a27.894 27.894 0 006.18 5.406 46.73 46.73 0 0030.674 11.431h99.754c-.949 21.802-.307 44.855 4.474 63.936 3.625 14.723 17.504 80.769 19.239 90.095h45.47c-.211-23-3.773-57.906-4.097-76.747z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6799"
                x1="481.937"
                x2="647.191"
                y1="504.296"
                y2="437.284"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.04" stopColor="#888B8D"></stop>
                <stop offset="0.954" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
