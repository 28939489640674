import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6582)"
            d="M553.357 305.224l-.231-.102c-5.816-2.578-11.303-4.752-15.436-6.358-.694-.265-1.388-.534-2.08-.803l-.116-.045c-1.214-.471-2.428-.943-3.652-1.388l-5.843-2.117c-2.627-.983-5.313-1.883-7.948-2.766a483.986 483.986 0 01-3.846-1.301 255.274 255.274 0 00-23.968-6.858l-12.074-2.761-10.313-2.483c-4.503-5.108-9.1-9.652-12.171-12.63-5.12-4.929-10.517-9.773-16.502-14.807a158.53 158.53 0 00-17.727-13.19c-1.857-1.192-3.723-2.371-5.598-3.556-4.229-2.673-8.602-5.437-12.783-8.315l-.119-.081a27.6 27.6 0 00-15.589-4.811 27.746 27.746 0 00-22.965 12.139 27.59 27.59 0 00-4.321 20.795 27.564 27.564 0 0011.65 17.76c4.203 2.861 8.367 5.941 12.393 8.918 1.771 1.31 3.542 2.62 5.319 3.915a158.034 158.034 0 0018.706 11.748c6.873 3.764 13.345 7.045 19.789 10.031a201.008 201.008 0 0020.949 8.441c1.801.576 21.05 6.248 30.791 8.394l2.254.498c7.451 1.645 15.156 3.347 22.645 4.651 1.313.222 2.622.455 3.932.687l.044-.246.025.259c2.708.481 5.508.979 8.283 1.374l6.159.943c1.293.195 2.591.364 3.89.533l.17.022c.714.092 1.429.185 2.149.284 4.153.537 9.695 1.22 15.74 1.737l.291.024-.02-.29c-.6-8.851 5.215-21.779 7.697-27.299l.426-.946z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6582"
                x1="363.639"
                x2="526.94"
                y1="238.611"
                y2="325.142"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
