import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-left-down">
        <path
            d="M500.062 186.79c-9.226-7.122-19.957-12.655-32.552-17.169l19.121 31.543s17.866-10.949 13.431-14.374Z"
            fill="url(#man-34-25-shirt-1-arm-left-downa)"
        />
        <path
            d="M480.289 174.773a115.17 115.17 0 0 1 19.773 11.352"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M588.289 376.687c-18.637-32.602-39.538-62.24-49.908-77.384-.751-7.34-1.914-14.537-3.226-21.682-7.564-40.506-14.054-50.754-22.282-74.187a30.993 30.993 0 0 0-27.544-20.684 30.991 30.991 0 0 0-11.975 1.704 30.988 30.988 0 0 0-17.675 15.829 30.985 30.985 0 0 0-1.306 23.69c7.59 21.616 9.096 34.916 28.591 71.831a252.391 252.391 0 0 0 13.318 22.624 24.797 24.797 0 0 0 3.537 4.149c8.863 12.05 31.373 46.294 56.949 76.672l31.521-22.562Z"
            fill="url(#man-34-25-shirt-1-arm-left-downb)"
        />
        <path
            d="M537.95 295.408c-.732-5.998-1.715-11.909-2.794-17.785-7.564-40.507-12.773-51.516-22.282-74.188-4.509-10.75-9.636-13.99-9.636-13.99"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M496.284 318.427a252.26 252.26 0 0 1-13.319-22.624c-19.495-36.915-21-50.215-28.59-71.83M538.07 298.848c10.261 15.002 31.397 44.913 50.219 77.839l-31.52 22.562c-25.896-30.759-50.932-67.5-59.558-79.134"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-downa"
                x1={526.18}
                y1={297.424}
                x2={477.168}
                y2={159.062}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.203} stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-downb"
                x1={552.134}
                y1={551.407}
                x2={506.592}
                y2={184.468}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.203} stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
