import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6810)"
            d="M523.715 241.706c-3.55-2.703-7.23-5.35-11.039-7.939-6.217-4.042-12.51-7.918-18.645-12.184-30.738-19.737-60.63 23.426-31.286 45.234 6.151 4.233 12.001 8.756 17.973 13.145 16.778-16.556 27.332-26.966 42.997-38.256z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6810)"
            d="M476.143 208.625a110.644 110.644 0 0118.627 14.398 2.708 2.708 0 01.822 2.483c-.805 4.666-13.808 10.892-16.407 12.093 0 0-14.647 1.881-13.641.722 11.755-13.547 12.469-21.795 10.599-29.696z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6810"
                x1="500.375"
                x2="449.629"
                y1="223.695"
                y2="277.478"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6810"
                x1="489.656"
                x2="438.909"
                y1="213.581"
                y2="267.364"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
