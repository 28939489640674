import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6555)"
            d="M410.346 351.775a22.89 22.89 0 01-15.415-6.103 32.18 32.18 0 01-5.009-6.521 194.195 194.195 0 01-10.603-19.524c-3.019-6.315-5.865-12.861-8.703-20.014a153.324 153.324 0 01-6.769-20.63 665.853 665.853 0 01-1.565-6.336c-1.169-4.782-2.378-9.728-3.757-14.527a27.491 27.491 0 012.32-20.967 27.499 27.499 0 0145.644-3.792 27.488 27.488 0 014.898 9.575l.04.137c1.356 4.819 2.931 9.666 4.453 14.353a812.762 812.762 0 011.998 6.201 153.021 153.021 0 015.097 21.11 327.199 327.199 0 012.793 18.758 234.384 234.384 0 0119.857-8.022c1.227-.425 2.445-.858 3.661-1.291 2.479-.882 5.041-1.794 7.61-2.605l5.648-1.851c1.231-.4 2.47-.774 3.709-1.148.672-.202 1.343-.405 2.013-.611 4.685-1.411 8.572-2.516 12.242-3.481l1.319-.346-.066 1.363c-.627 12.725 5.806 22.277 12.124 23.83l2.208.543-1.892 1.26c-3.54 2.36-7.41 4.807-12.18 7.701-.615.368-1.223.737-1.831 1.105a171.48 171.48 0 01-3.306 1.974l-5.166 2.967c-2.313 1.361-4.704 2.656-7.018 3.908-1.136.615-2.272 1.23-3.404 1.855a233.803 233.803 0 01-21.45 10.346l-10.953 4.539-10.88 4.663a19.347 19.347 0 01-7.667 1.581z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6555"
                x1="479.057"
                x2="351.539"
                y1="347.317"
                y2="245.823"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
