import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6339)"
            d="M327.621 423.114c-4.987-28.436-7.234-61.733-6.498-96.289a19.417 19.417 0 01.254-3.176 21.381 21.381 0 01.704-5.498c5.22-19.784 17.586-49.316 27.779-65.855 1.237-2.02 2.662-4.051 3.914-6.066 2.848-4.581 5.792-9.317 8.509-14.068a27.586 27.586 0 0123.896-13.85 27.512 27.512 0 0113.63 3.634 27.335 27.335 0 0112.88 16.647 27.34 27.34 0 01-2.664 20.879c-2.708 4.734-4.866 9.189-7.032 13.264-6.992 13.151-13.11 24.266-31.146 46.717-4.321 5.344-7.975 9.607-11.471 13.38 1.032 27.359 1.668 56.738-2.536 88.743l-.108.824-29.957 1.587-.154-.873z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6339"
                x1="315.246"
                x2="355.583"
                y1="264.53"
                y2="312.024"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
