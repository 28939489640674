import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.495 279.902c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.787-9.422-18.228-14.919-23.949a47.595 47.595 0 0 0-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a22.002 22.002 0 0 1-8.677 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.283-4.644-14.812-9.445-22.912-12.429a32.686 32.686 0 0 0-10.801-1.712 31.455 31.455 0 0 0-4.391.292c-.16.023-.314.078-.452.162a62.748 62.748 0 0 0-26.519 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.882 0 179.194.874 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a523.345 523.345 0 0 1-2.685-7.11c8.093-3.299 18.171-9.259 20.658-19.014 1.943-7.615-1.083-16.221-8.991-25.578Z"
            fill="url(#female-34-25-tshirt-1-a)"
        />
        <path
            d="M392.455 205.674a30.63 30.63 0 0 1 10.11 1.589c7.898 2.91 15.335 7.652 22.531 12.241 11.06 7.051 21.508 13.71 33.011 13.71a24.222 24.222 0 0 0 9.451-1.592 16.196 16.196 0 0 0 5.846-3.817 16.195 16.195 0 0 0 3.694-5.926 19.004 19.004 0 0 0 .318-10.31 83.022 83.022 0 0 1 13.566 22.278c5.422 14.132 11.4 22.982 31.989 47.345 7.467 8.836 10.354 16.84 8.58 23.793-2.285 8.965-11.852 14.55-19.475 17.657l-1.787.728.665 1.812c.87 2.373 1.798 4.795 2.695 7.137 7.742 20.22 18.312 47.833 9.516 95.99l-1.644.001c-13.211 0-63.147-.233-107.203-.44-30.509-.142-57.25-.268-66.426-.29 2.577-18.421 8.1-39.692 27.107-59.926 8.911-9.487 11.518-23.614 6.802-36.869l-18.824-52.892a60.738 60.738 0 0 1 25.53-71.969 29.614 29.614 0 0 1 3.948-.25Zm0-2a31.608 31.608 0 0 0-4.391.292c-.16.023-.314.078-.452.162a62.743 62.743 0 0 0-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.796-6.376 34.829-20.637 21.97-25.614 45.361-27.936 63.292 13.63 0 152.597.734 175.916.734 2.143 0 3.306-.007 3.309-.02 9.23-48.617-.85-76.583-9.313-98.687-.944-2.465-1.843-4.812-2.686-7.11 8.094-3.299 18.171-9.259 20.659-19.014 1.942-7.615-1.083-16.221-8.992-25.578-20.409-24.152-26.325-32.894-31.648-46.771-2.986-7.787-9.421-18.228-14.918-23.949a47.493 47.493 0 0 0-4.724-2.974c4.064 7.051 4.949 18.802-6.423 23.57a21.989 21.989 0 0 1-8.678 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.282-4.644-14.811-9.444-22.911-12.429a32.69 32.69 0 0 0-10.801-1.712Z"
            fill="#000"
        />
        <g filter="url(#female-34-25-tshirt-1-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a30.976 30.976 0 0 0 12.049-48.824l-6.917-10.636"
                fill="url(#female-34-25-tshirt-1-c)"
            />
        </g>
        <defs>
            <radialGradient
                id="female-34-25-tshirt-1-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.689 -1072.242) scale(77.211 37.1881)"
            >
                <stop offset={0.437} stopColor="#E2E2E2" stopOpacity={0} />
                <stop offset={0.587} stopColor="#E2E2E2" stopOpacity={0.33} />
                <stop offset={0.763} stopColor="#E2E2E2" stopOpacity={0.688} />
                <stop offset={0.888} stopColor="#E2E2E2" stopOpacity={0.913} />
                <stop offset={0.949} stopColor="#E2E2E2" />
            </radialGradient>
            <linearGradient
                id="female-34-25-tshirt-1-a"
                x1={345.602}
                y1={316.992}
                x2={534.057}
                y2={316.992}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <filter
                id="female-34-25-tshirt-1-b"
                x={402.73}
                y={251.023}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11313_6310"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
