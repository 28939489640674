import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6354)"
            d="M417.351 247.936c-1.498-2.248-2.928-4.916-4.737-8.294a693.744 693.744 0 00-1.981-3.681l-1.386-2.546c-4.585-8.32-13.305-13.79-23.922-15.009a20.472 20.472 0 00-2.282-.133c-3.547 0-7.06.948-11.742 3.169-5.38 2.552-9.745 7.985-11.979 14.908-2.603 8.069-1.945 16.899 1.759 23.618 2.038 3.698 3.56 7.015 5.171 10.528a540.838 540.838 0 001.57 3.393l.468.999.948-.564c.309-.183 31.069-18.428 47.644-24.902l1.158-.452-.689-1.034z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6354"
                x1="368.355"
                x2="412.162"
                y1="241.556"
                y2="262.375"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
