import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6922)"
            d="M478.237 236.718c-.166-4.364-.928-19.194-3.775-26.828l-1.017-2.726 2.478 1.524a102.497 102.497 0 0118.859 14.537 2.705 2.705 0 01.823 2.483c-.788 4.558-13.419 10.693-15.944 11.877l-1.367.641-.057-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6922)"
            d="M524.733 343.438a19.6 19.6 0 01-15.48-7.691c-.229-.2-.454-.404-.674-.615a216.108 216.108 0 01-15.74-17.397c-4.677-5.705-9.263-11.705-14.026-18.344a172.05 172.05 0 01-12.304-19.416 769.036 769.036 0 01-3.279-6.088c-2.464-4.598-5.011-9.353-7.685-13.922a27.505 27.505 0 019.934-37.678 27.51 27.51 0 0113.775-3.717 28.014 28.014 0 0123.979 13.643l.069.12c2.654 4.586 5.31 9.124 8.092 13.54a1501.4 1501.4 0 013.668 5.855 171.652 171.652 0 0110.802 20.295c3.409 7.398 6.358 14.351 9.012 21.249a297.838 297.838 0 013.486 9.6c.847-.099 1.694-.196 2.541-.292a131.676 131.676 0 0117.95-.733c.98.031 1.952.052 2.925.073 1.985.042 4.039.086 6.077.226l4.475.244c.981.056 1.962.141 2.944.226.525.045 1.051.091 1.575.132 3.475.301 6.353.605 9.066.958l1.386.18-.619 1.254c-3.84 7.773-1.217 21.425 2.275 27.409l.64 1.097-1.216.365c-2.63.79-5.421 1.555-8.783 2.408-.52.128-1.033.258-1.546.388-.953.242-1.906.484-2.862.698l-4.393.968c-1.976.466-3.998.841-5.952 1.204-.955.178-1.909.355-2.863.543a132.72 132.72 0 01-17.837 2.18c-1.79.094-3.581.173-5.372.253-4.068.182-8.275.37-12.39.716-.548.046-1.103.069-1.65.069z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6922"
                x1="471.396"
                x2="565.083"
                y1="209.512"
                y2="340.5"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6922"
                x1="468.592"
                x2="562.278"
                y1="211.514"
                y2="342.502"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
