import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10160)"
            d="M385.261 219.824a31.53 31.53 0 00-.812-.011 27.353 27.353 0 00-27.473 26.703c-.152 5.23-.507 10.525-.82 15.199l-.074 1.095a130.304 130.304 0 0126.167-3.949c13.719-.672 29.551 2.476 29.551 2.476l-.002-.975c-.006-3.823.008-7.935.15-12.112l.005-.14a27.536 27.536 0 00-26.692-28.286z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10160"
                x1="358.747"
                x2="401.253"
                y1="224.099"
                y2="266.605"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
