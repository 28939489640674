import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2186}
            height={1094}
            viewBox="0 0 2186 1094"
            fill="none"
            id="scene-kitchen-2"
            {...attributes}
        >
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1045.79 421.321h-53.897V65.566H1493.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1345.06 23.719V453H959.002V35.084h350.758v386.232h-205.43"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1104.34 421.656 15.5-46.492h-13.26c13.6-9.299 20.83-22.213 20.83-22.213s-18.77-1.722-36.16 7.576c-.86.517-1.55.861-2.41 1.378 4.14-6.371 7.92-13.776 10.68-21.869 10.5-31.856.34-63.712.34-63.712s-27.38 19.63-37.88 51.314c-1.03 3.272-1.89 6.716-2.58 9.987-12.4-19.974-31.86-30.478-31.86-30.478s-4.99 26.69 6.72 50.97c3.1 6.715 7.23 12.398 11.53 17.391h-15.49l15.49 46.493M1452.95 1.367h192v133.5h138l93 58.5h-304.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1779.95 1.367v112.5h-117l-115.5 79.5v36h342v-34.5M12 487.867h2108.45l55.5 40.5H1114v19.5h1070.95v28.5h-168v105M2035.45 596.867v40.5M1999.45 596.867v40.5"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1531.66 354.449c4.4-3.016 9.69-4.74 15.3-4.74l39.71-.16v-11.315h18.84v11.315l39.71.16c11.78 0 22.2 7.62 25.77 18.841M1663.23 401.14v58.968c0 5.523-4.48 10-10 10h-114.28c-5.52 0-10-4.477-10-10v-91.557H1671M1663.23 387.391h-149.82M1678.76 387.391h-15.53"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 1093H2142.05"
            />
        </svg>
    );
}

