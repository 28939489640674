import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2150}
            height={928}
            viewBox="0 0 2150 928"
            fill="none"
            id="scene-office-3"
            {...attributes}
        >
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1 454.953h1729.12l55.5 40.5H783.729v19.5H1794.62v28.5h-168v105M1646.13 568.953v40.5M1610.13 568.953v40.5M940.84 393.15h-46.95V83.25h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1201.54 46.797v373.95H865.239V56.697h305.551v336.45H991.839"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m991.837 393.444 13.503-40.5h-11.553c11.853-8.1 18.153-19.35 18.153-19.35s-16.352-1.5-31.502 6.6c-.75.45-1.351.75-2.101 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.301-55.5.301-55.5s-23.851 17.1-33 44.7c-.901 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.351 23.25 5.849 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1543.05 55.844v31.913l-15.12 15.114M1543.05 13.14v14.49M1618.11 87.758h-14.49M1543.05 162.934v-14.489M1467.99 87.758h14.49"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1605.32 113.504c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <g
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-3-a)"
            >
                <path
                    d="M1901.34 420.154v-18.842h-18.84v18.842h18.84ZM1980.86 420.153v-18.841h-18.84v18.841h18.84ZM1880.75 891.914h-40.8c-10.41 0-18.84 8.435-18.84 18.841h176.33c0-10.406-8.43-18.841-18.84-18.841h-53.23M1882.49 410.727h-44.53M1821.11 400.726v-11.96c0-5.53 4.48-10 10-10h26.67M1962.03 410.727h-18.84" 
                />
                <path 
                    d="M1875.19 378.766h112.26c5.52 0 10 4.47 10 10v11.96c0 5.53-4.48 10-10 10h-6.58M1837.96 410.727v18.843M1821.11 429.573v-40.807c0-5.523 4.47-10 10-10h6.85M1857.78 378.768V373h17.4v5.768"
                />
                <path 
                    d="M1901.34 410.729h19.15l-.01-7.917a7.485 7.485 0 0 1 7.49-7.484h7.73c4.13 0 7.48 3.351 7.48 7.484v19.607h-12.78v-11.69h12.79M1925.37 891.914l-32.19-.02 7.42-424.66M1924.71 854.034l-6.76-386.8" 
                />
                <path
                    d="M1821.1 429.57v.84c0 11.2 7.77 20.9 18.71 23.32l60.79 13.51M1837.96 429.57h158.62c0 11.05-7.58 20.66-18.32 23.23l-60.31 14.44" 
                />
            </g>
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m228.906 894.504.755 17.25h14.841l2.15-49.098M201.505 862.664l-5.13 15.54-11.07 33.55h-14.84l9.94-41M293.755 870.758l9.94 41h-14.84l-11.07-33.55"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M168.718 859.847c-8.02-10.09-12.44-23.08-11.53-36.92 2.29-35.04 4.59-70.09 6.89-105.13h146.02l6.89 105.13c1.28 19.57-8.09 37.44-23.23 47.83M180.406 870.758a53.33 53.33 0 0 0 15.97 7.45c2.93.82 5.96 1.4 9.09 1.71 9.36.94 19.34 1.53 29.85 1.59 11.85.06 23.03-.54 33.44-1.6 3.1-.31 6.12-.89 9.03-1.7M151.041 548.786c6.962-22.407 7.646-37.827-.886-49.677-5.804-8.061-17.859-9.925-24.978-5.08-8.136 5.537-10.503 16.058-8.952 24.762 4.909 27.547 21.742 53.869 42.795 73.701 0-43.579 19.81-65.913 37.494-65.913 7.074 0 14.371 4.052 14.371 12.736 0 19.652-40.106 20.997-36.043 79.291-21.623-20.264-44.641-31.313-61.222-31.313-14.496 0-25.222 8.578-25.222 21.936 0 12.695 9.684 17.116 21.249 17.116 13.379 0 51.803-5.253 79.035 21.979-7.597-35.561 14.589-80.211 34.232-80.211 10.168 0 15.347 7.768 15.347 13.958 0 25.326-42.505 31.222-39.872 94.295-30.422-15.537-46.591-20.829-58.717-20.829-4.795 0-20.968 3.145-20.968 17.545 0 8.046 8.988 16.547 19.601 16.547 18.547 0 29.6-4.223 48.126.505 10.854 2.77 17.576 10.937 21.941 15.909-1.482-44.33 21.518-91.457 40.977-88.352 11.081 1.768 3.53 38.335-21.003 88.352 35.19-59.667 106.726-78.373 151.383-71.361 36.323 5.704 39.986 38-10.124 29.777-33.968-5.574-87.117 1.305-106.065 41.583-1.847-31.698 32.764-97.383 92.89-97.383 29.368 0 40.358-8.337 40.358-20.084 0-7.958-8.779-15.158-29.368-15.158-37.922 0-87.032 34.232-99.663 75.031 10.611-54.947-2.847-103.899-43.326-132.379-20.091-14.135-19.434-39.867.126-45.726 26.186-7.844 74.526 45.347 62.147 123.158 42.821-45.853 77.543-36.409 103.857-44.803 18.998-6.06 33.434-14.341 32.624-27.766-.769-12.742-15.711-17.681-33.407-15.726-40.522 4.478-77.041 38.437-84.316 50.968-6.632-38.274-11.233-67.662-.244-99.873 5.714-16.747 20.034-22.977 29.76-20.064 9.818 2.941 14.987 15.539 14.063 27.663-1.496 19.606-15.347 42.006-20.505 47.747M201.508 862.664h45.147"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M7.051 927H2148.05"
            />
            <defs>
                <clipPath id="scene-office-3-a">
                    <path fill="#fff" d="M1820.1 372h178.344v539.755H1820.1z" />
                </clipPath>
            </defs>
        </svg>
    );
}
