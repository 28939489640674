import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#001489"
            d="M582.233 637.224c-1.069-9.525-7.397-86.881-8.267-101.55-1.118-17.886 1.846-37.37 6.236-57.14h-73.384c-33.205-6.668-53.489-39.326-42.302-71.703 9.252-25.979 34.969-39.616 61.917-35.507 21.366 2.531 42.348 8.032 60.266 16.65 13.79 6.308 26.028 11.741 40.906 21.13 2 1.28 5.352 2.871 7.397 4.132 11.494 7.084 18.152 17.697 15.857 30.687-2.832 16.522-9.946 58.011-12.689 73.934-2.025 11.338-10.614 101.981-11.409 123.114-8.811-.982-34.699-2.717-44.528-3.747z"
        ></path>
        <path
            fill="url(#paint0_linear_10894_7582)"
            d="M627.551 627.905c.348-4.836.79-10.443 1.293-16.521l-49.146-2.767c.525 6.259 1.013 11.973 1.426 16.678l46.427 2.61z"
        ></path>
        <path
            fill="url(#paint1_linear_10894_7582)"
            d="M630.063 597.118a3795.75 3795.75 0 014.756-50.249l-60.321-3.42c.858 11.792 2.464 32.01 4.004 50.754l51.561 2.915z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10894_7582"
                x1="579.698"
                x2="628.844"
                y1="618.261"
                y2="618.261"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10894_7582"
                x1="574.498"
                x2="634.819"
                y1="570.283"
                y2="570.283"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
