import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-down">
        <path
            fill="url(#man-34-25-jacket-arm-left-down-a)"
            d="M556.758 399c-19.4-23-36.8-47.9-48.3-64.4-3.6-5.2-6.501-9.3-8.601-12.2-1.4-1.3-2.599-2.7-3.599-4.3-5.4-8.1-10.001-16.4-13.401-22.7-14.7-27.9-19.1-42-23.8-57-1.5-4.7-3-9.6-4.9-15-2.8-8.1-2.399-16.8 1.301-24.5 3.7-7.7 10.199-13.5 18.199-16.3 3.4-1.2 7.001-1.8 10.601-1.8 13.6 0 25.7 8.6 30.2 21.4 2 5.6 3.799 10.5 5.699 15.2 5.9 15.2 10.9 28.3 16.7 59.2 1.6 8.5 2.5 15.1 3.2 21.5 1.1 1.7 2.4 3.5 3.8 5.5 11 16 29.501 42.8 46.101 71.8l.399.8-33 23.6-.599-.8Z"
        />
        <path
            fill="url(#man-34-25-jacket-arm-left-down-b)"
            d="M505.258 188.5c-5.5-6.4-24.8-17.7-38-22.2l20.099 33.9s21.501-7.4 17.901-11.7Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-left-down-a"
                x1={492.441}
                x2={617.494}
                y1={275.322}
                y2={333.676}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.791} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-arm-left-down-b"
                x1={473.733}
                x2={425.63}
                y1={175.882}
                y2={136.922}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.791} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
