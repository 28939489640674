import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-arm-left-option-7">
        <path
            d="M500.063 186.165c-9.226-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-7-a)"
        />
        <path
            d="M587.944 296.517a292.144 292.144 0 0 0-6.354-5.905c-3.891-3.55-8.245-7.34-13.694-11.924a152.48 152.48 0 0 0-3.493-2.901 146.07 146.07 0 0 0-3.533-2.825c-1.546-1.241-3.158-2.423-4.717-3.566-.84-.616-1.68-1.232-2.524-1.867a420.878 420.878 0 0 0-4.509-3.239 876.062 876.062 0 0 1-2.809-2.006l-3.482-2.532c-1.134-4.028-2.553-8.284-4.452-13.359-2.2-5.806-4.69-11.618-7.608-17.759a112.62 112.62 0 0 0-9.48-16.688c-1.11-1.621-2.236-3.232-3.362-4.843-2.46-3.518-5.004-7.156-7.318-10.843l-.147-.233a31.894 31.894 0 0 0-26.983-14.753 31.893 31.893 0 0 0-17.221 5.051 31.778 31.778 0 0 0-9.015 8.643 31.775 31.775 0 0 0-4.991 11.449 31.773 31.773 0 0 0-.263 12.486 31.766 31.766 0 0 0 4.566 11.624c2.344 3.663 4.549 7.515 6.681 11.24a565.208 565.208 0 0 0 2.944 5.106 112.455 112.455 0 0 0 11.042 15.69c4.317 5.27 8.51 10.002 12.823 14.47a146.722 146.722 0 0 0 14.615 13.395 25.26 25.26 0 0 0 4.524 2.786 23.708 23.708 0 0 0 3.46 2.666l7.607 4.804c.965.617 1.928 1.237 2.89 1.861 1.56 1.008 3.12 2.017 4.705 3.011.891.543 1.775 1.097 2.658 1.65 1.638 1.026 3.331 2.086 5.036 3.053 1.327.789 2.682 1.547 3.94 2.243 1.262.715 2.606 1.448 3.987 2.175 6.38 3.426 11.36 5.993 16.145 8.32a289.967 289.967 0 0 0 10.215 4.797c2.164.965 4.319 1.893 6.465 2.785 0 0-6.47-6.254-3.036-19.627a19.452 19.452 0 0 1 14.688-14.435Z"
            fill="url(#man-34-25-doctors-coat-arm-left-option-7-b)"
        />
        <path
            d="M456.555 230.528c2.344 3.663 4.549 7.515 6.68 11.24a605.659 605.659 0 0 0 2.945 5.106 112.287 112.287 0 0 0 11.042 15.69c4.316 5.27 8.51 10.002 12.822 14.47a146.964 146.964 0 0 0 14.616 13.395 25.282 25.282 0 0 0 4.523 2.785 23.715 23.715 0 0 0 3.46 2.667l7.607 4.804c.965.617 1.929 1.237 2.89 1.861 1.56 1.008 3.12 2.016 4.705 3.011.891.543 1.775 1.096 2.659 1.65 1.637 1.025 3.33 2.086 5.036 3.053a145.17 145.17 0 0 0 3.939 2.243c1.263.714 2.606 1.448 3.988 2.175 6.379 3.426 11.36 5.993 16.144 8.32a289.967 289.967 0 0 0 10.215 4.797c2.164.964 4.319 1.893 6.465 2.784 0 0-6.47-6.253-3.036-19.626a19.457 19.457 0 0 1 14.689-14.437 292.342 292.342 0 0 0-6.355-5.904c-3.891-3.55-8.244-7.341-13.694-11.925a155.588 155.588 0 0 0-3.493-2.901 146.42 146.42 0 0 0-3.532-2.825c-1.546-1.241-3.159-2.423-4.718-3.566-.84-.615-1.68-1.232-2.524-1.866-1.493-1.094-3.001-2.166-4.509-3.239a922.859 922.859 0 0 1-2.808-2.006l-3.482-2.533c-1.135-4.027-2.553-8.283-4.453-13.359-2.2-5.805-4.69-11.617-7.608-17.758a112.513 112.513 0 0 0-9.479-16.688c-1.11-1.621-2.237-3.232-3.363-4.843-2.46-3.518-5.003-7.156-7.318-10.843l-.147-.233a31.931 31.931 0 0 0-12.041-11.07 118.797 118.797 0 0 0-30.909-15.961"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-7-a"
                x1={537.587}
                y1={430.639}
                x2={471.659}
                y2={119.218}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-arm-left-option-7-b"
                x1={555.753}
                y1={426.794}
                x2={489.825}
                y2={115.372}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
