import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, [string, string]> = {
    black: ['#2B2B2B', '#111011'],
    brown: ['#7A5430', '#5B3114'],
    red: ['#F46A25', '#DD4E10'],
    yellow: ['#E5BC56', '#D1942A'],
    gray: ['#A0A4AC', '#838891']
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-6" transform="translate(329, 20)">
        <path
            fill={HAIR_COLOR[color][0]}
            d="M85.135 116.985c1.784 2.684 5.836 2.922 6.803-.153.826-2.624 1.22-5.347.763-7.632-5.4-3.8-9.3-9.6-10.7-16-.6-2.8-.8-6 .9-8.4 1.7-2.4 5-3.3 7.9-3 2.9.3 4.1 2.8 6.7 4.2 1.7-.4 4-2.6 4.7-4.2 2.5-6.2 3.199-13.2 1.799-19.7 3.3 1.1 7.601-.7 9.101-3.8.9-1.7 1-3.8 1.8-5.5 1.1-2.3 3.6-4 6.2-4.2 2.7-.2 5.2 1.1 7.5 2.6s4.3 3.3 6.8 4.4c2.5 1 5.6 1.2 7.7-.4.8.7.4.8 1.2 1.6 3.5 3.6 9.3 4.7 13.8 2.6.8 2.2 1.5 4.4 2 6.7 3.8 1 7.799-.9 9.899-4.1 2.1-3.2 2.601-7.3 2.401-11.2-.4-7.4-3.5-15.1-9.8-19.1-1.2-.8-2.701-1.5-4.101-1.2-1.5.2-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.899-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22 6.902 3.722 13.924 9.248 18.735 16.485z"
        ></path>
        <path
            stroke={HAIR_COLOR[color][1]}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M172.301 50.7c-.4-7.4-3.501-15.1-9.801-19.1-1.2-.8-2.699-1.5-4.099-1.2-1.5.2-2.8 1.6-2.5 3-3.6-8.6-12.7-14.3-22-14.4-6.6-.1-5.5 5-5.5 5s-1.7-.3-2.5-.6c-11-4.8-23.9-5.2-35.2-1.1-5.1 1.8-10 4.6-13.1 8.9-3.1 4.3-4.3 10.4-1.9 15.2-3.7.7-8.3 2-11.6 3.9-4.9 2.8-8.1 8.3-8.2 13.9-.1 5.6 2.9 11.2 7.6 14.2-6.4 2.2-10.3 15 2.8 22"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 86.8c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
    </g>
);

export default SvgComponent;
