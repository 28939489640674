import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={89}
            height={47}
            fill="none"
            id="object-food-date"
            {...attributes}
        >
            <path
                fill="url(#object-food-date-a)"
                d="M44.22 46.736c24.248 0 43.904-10.312 43.904-23.032S68.468.672 44.22.672C19.973.672.316 10.984.316 23.704S19.973 46.736 44.22 46.736Z"
            />
            <defs>
                <linearGradient
                    id="object-food-date-a"
                    x1={81.037}
                    x2={9.464}
                    y1={58.71}
                    y2={-9.343}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </svg>
    );
}
