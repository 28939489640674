import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2226}
            height={1008}
            viewBox="0 0 2226 1008"
            fill="none"
            id="scene-office-2"
            {...attributes}
        >
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m2029.88 955.121.75 17.25h14.84l2.15-49.098M2002.47 923.281l-5.13 15.54-11.07 33.55h-14.84l9.94-41M2094.72 931.375l9.94 41h-14.84l-11.07-33.55"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1969.69 920.464c-8.02-10.09-12.44-23.08-11.53-36.92 2.29-35.04 4.59-70.09 6.89-105.13h146.02l6.89 105.13c1.28 19.57-8.09 37.44-23.23 47.83M1981.38 931.375c4.8 3.3 10.16 5.84 15.96 7.45 2.93.82 5.96 1.4 9.09 1.71 9.36.94 19.34 1.53 29.85 1.59 11.85.06 23.03-.54 33.44-1.6 3.1-.31 6.12-.89 9.03-1.7M1952.01 609.404c6.96-22.407 7.65-37.827-.89-49.677-5.8-8.061-17.85-9.925-24.97-5.08-8.14 5.537-10.51 16.058-8.96 24.762 4.91 27.547 21.75 53.869 42.8 73.701 0-43.579 19.81-65.913 37.49-65.913 7.08 0 14.37 4.052 14.37 12.736 0 19.652-40.1 20.997-36.04 79.291-21.62-20.264-44.64-31.313-61.22-31.313-14.5 0-25.22 8.578-25.22 21.936 0 12.695 9.68 17.116 21.25 17.116 13.38 0 51.8-5.253 79.03 21.979-7.6-35.561 14.59-80.211 34.23-80.211 10.17 0 15.35 7.768 15.35 13.958 0 25.326-42.5 31.222-39.87 94.295-30.42-15.537-46.59-20.829-58.72-20.829-4.79 0-20.97 3.145-20.97 17.545 0 8.046 8.99 16.547 19.6 16.547 18.55 0 29.6-4.223 48.13.505 10.85 2.77 17.58 10.937 21.94 15.909-1.48-44.33 21.52-91.457 40.98-88.352 11.08 1.768 3.53 38.335-21 88.352 35.19-59.667 106.72-78.373 151.38-71.361 36.32 5.704 39.98 38-10.13 29.777-33.96-5.574-87.11 1.305-106.06 41.583-1.85-31.698 32.76-97.383 92.89-97.383 29.37 0 40.36-8.337 40.36-20.084 0-7.958-8.78-15.158-29.37-15.158-37.92 0-87.03 34.232-99.66 75.031 10.61-54.947-2.85-103.899-43.33-132.379-20.09-14.135-19.43-39.867.13-45.726 26.18-7.844 74.52 45.347 62.14 123.158 42.82-45.853 77.55-36.409 103.86-44.803 19-6.06 33.43-14.341 32.62-27.766-.76-12.742-15.71-17.681-33.4-15.726-40.52 4.478-77.04 38.437-84.32 50.968-6.63-38.274-11.23-67.662-.24-99.873 5.71-16.747 20.03-22.977 29.76-20.064 9.82 2.941 14.98 15.539 14.06 27.663-1.5 19.606-15.35 42.006-20.5 47.747M2002.48 923.281h45.14M1695.18 55.586v31.913l-15.12 15.114M1695.18 12.883v14.489M1770.23 87.5h-14.48M1695.18 162.676v-14.488M1620.12 87.5h14.49"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1757.45 113.246c3.29-7.931 5.1-16.626 5.1-25.747 0-37.21-30.17-67.37-67.38-67.37-37.21 0-67.37 30.16-67.37 67.37 0 37.21 30.17 67.37 67.37 67.37 18.6 0 35.45-7.54 47.64-19.73l13.32 13.32c15.6-15.6 25.26-37.15 25.26-60.96 0-47.61-38.6-86.21-86.22-86.21s-86.21 38.6-86.21 86.21c0 47.61 38.6 86.21 86.21 86.21 12.11 0 23.64-2.49 34.09-7"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 1007H2142.05"
            />
            <g
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#scene-office-2-a)"
            >
                <path d="M1421.66 487.656H843.133v-25.121h578.527" />
                <path d="M797.34 487.658V110.344h624.32v352.193" />
            </g>
            <defs>
                <clipPath id="scene-office-2-a">
                    <path fill="#fff" d="M796 109h627v380H796z" />
                </clipPath>
            </defs>
        </svg>
    );
}
