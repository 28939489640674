import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-2-cycling-2-left-a)">
        <path
            d="M431.922 680.086a5.102 5.102 0 0 1-3.047-1c-6.576-4.831-18.038-13.37-20.911-16.276a70.742 70.742 0 0 1-12.544-16.907 41.165 41.165 0 0 0-7.506-10.152l-14.39-14.36a9.99 9.99 0 0 1 .405-14.54c12.775-11.386 20.906-19.05 22.894-21.58l8.352-10.287 27.163 23.802-10.712 10.1c-.01.326-.791 32.913 8.789 51.243 2.224 4.259 4.826 9.733 6.178 12.617a5.152 5.152 0 0 1-2.19 6.704 5.149 5.149 0 0 1-2.481.636Z"
            fill="#2B2729"
        />
        <path
            d="M429.931 678.875a5.079 5.079 0 0 1-.248-.165c-6.658-4.722-18.262-13.069-21.182-15.923a70.785 70.785 0 0 1-12.825-16.695 41.142 41.142 0 0 0-7.675-10.024l-14.629-14.116a9.997 9.997 0 0 1 .161-14.545c3.327-3.066 6.567-6.086 9.368-8.732a1.002 1.002 0 0 1 1.358-.014 65.489 65.489 0 0 1 7.867 9.155c.422.557.844 1.111 1.266 1.665 3.825 4.989 8.17 9.854 13.991 11.635a32.93 32.93 0 0 0 7.004 1.08c1.18.099 2.399.2 3.599.36 1.114.148 2.216.374 3.298.674a1.003 1.003 0 0 1 .727.872c.978 10.595 3.315 25.378 8.892 35.628 2.295 4.217 4.99 9.649 6.392 12.512a5.151 5.151 0 0 1-1.182 6.092 5.153 5.153 0 0 1-6.182.541Z"
            fill="#004F71"
        />
        <path
            d="M433.683 679.555a5.145 5.145 0 0 1-4-.845c-6.657-4.722-18.262-13.069-21.181-15.923a70.754 70.754 0 0 1-12.825-16.694 41.107 41.107 0 0 0-7.676-10.025l-14.629-14.115a9.903 9.903 0 0 1-3.058-7.312"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <clipPath id="man-34-25-shoes-2-cycling-2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
