import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M327.419 423.154c-4.989-28.45-7.237-61.76-6.501-96.328a19.418 19.418 0 0 1 .257-3.203 21.399 21.399 0 0 1 .708-5.517c5.222-19.797 17.772-49.371 27.974-65.926a760.492 760.492 0 0 1 3.688-5.962l.056-.09c2.86-4.601 5.795-9.325 8.504-14.06a27.786 27.786 0 0 1 24.069-13.951 27.702 27.702 0 0 1 13.729 3.66 27.535 27.535 0 0 1 12.974 16.768 27.518 27.518 0 0 1-2.684 21.03c-2.696 4.713-4.927 8.91-7.086 12.969-7.023 13.212-13.064 24.577-31.109 47.039-4.305 5.324-7.936 9.562-11.424 13.33 1.029 27.278 1.675 56.598-2.541 88.693l-.13.989-30.3 1.605-.184-1.046Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-1-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-1-right-a"
                x1={321.844}
                y1={270.869}
                x2={386.904}
                y2={349.592}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
