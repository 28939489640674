import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-4-stand-2">
            <path
                fill="#001489"
                d="M397.039 818.086c.511 4.31-.328 20.871-.957 31.623a8.99 8.99 0 0 0 5.42 8.785 8.994 8.994 0 0 0 3.565.735h57.647a4.447 4.447 0 0 0 2.104-8.36c-3.792-2.032-11.23-6.09-14.957-8.599-8.486-5.714-20.306-24.184-20.306-24.184M375.306 759.469c-3.673 2.311-18.993 8.658-28.975 12.703a8.998 8.998 0 0 0-5.61 8.665 8.99 8.99 0 0 0 .866 3.535l8.722 18.363a42.176 42.176 0 0 1 3.742 12.379 69.638 69.638 0 0 0 6.042 19.853c1.735 3.718 10.099 16.241 14.14 22.225a4.153 4.153 0 0 0 5.556 1.248 4.15 4.15 0 0 0 2.022-3.967c-.32-3.343-.92-9.243-1.571-13.918-2.926-20.988 9.016-51.715 9.016-51.715"
            />
            <mask
                id="man-34-25-shoes-4-stand-2-a"
                width="47"
                height="94"
                x="339"
                y="766"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M373.253 857.829a5.3 5.3 0 0 1-.172-.243c-4.534-6.787-12.396-18.726-14.105-22.433a70.787 70.787 0 0 1-6.023-20.172 41.082 41.082 0 0 0-3.593-12.103l-8.628-18.408a9.917 9.917 0 0 1-.247-7.921 9.905 9.905 0 0 1 5.588-5.608c4.202-1.678 8.307-3.342 11.868-4.815a1.002 1.002 0 0 1 1.273.472 65.454 65.454 0 0 1 4.082 11.36c.196.67.39 1.34.588 2.006 1.793 6.026 4.116 12.121 8.918 15.863a33.002 33.002 0 0 0 6.158 3.508c1.067.512 2.17 1.042 3.234 1.621a24.244 24.244 0 0 1 2.84 1.806 1.001 1.001 0 0 1 .368 1.074c-2.867 10.247-5.959 24.89-4.408 36.455.639 4.758 1.218 10.794 1.506 13.969a5.168 5.168 0 0 1-2.533 4.911 5.164 5.164 0 0 1-6.714-1.342Z"
                />
            </mask>
            <g mask="url(#man-34-25-shoes-4-stand-2-a)">
                <path
                    fill="#009CDE"
                    d="M373.253 857.829a5.3 5.3 0 0 1-.172-.243c-4.534-6.787-12.396-18.726-14.105-22.433a70.787 70.787 0 0 1-6.023-20.172 41.082 41.082 0 0 0-3.593-12.103l-8.628-18.408a9.917 9.917 0 0 1-.247-7.921 9.905 9.905 0 0 1 5.588-5.608c4.202-1.678 8.307-3.342 11.868-4.815a1.002 1.002 0 0 1 1.273.472 65.454 65.454 0 0 1 4.082 11.36c.196.67.39 1.34.588 2.006 1.793 6.026 4.116 12.121 8.918 15.863a33.002 33.002 0 0 0 6.158 3.508c1.067.512 2.17 1.042 3.234 1.621a24.244 24.244 0 0 1 2.84 1.806 1.001 1.001 0 0 1 .368 1.074c-2.867 10.247-5.959 24.89-4.408 36.455.639 4.758 1.218 10.794 1.506 13.969a5.168 5.168 0 0 1-2.533 4.911 5.164 5.164 0 0 1-6.714-1.342Z"
                />
                <path
                    fill="#001489"
                    d="M335.825 806.942c6.44-7.151 19.344-6.1 27.403-2.621a58.138 58.138 0 0 1 16.157 10.989c3.978 3.752 7.607 9.711 13.05 11.252 1.58.448 3.503-1.161 2.854-2.854-1.382-3.606-4.377-6.181-6.981-8.972a74.87 74.87 0 0 0-8.602-8.148c-5.856-4.571-12.289-9.183-19.668-10.81-9.348-2.06-24.455-2.04-27.974 8.975-.674 2.109 2.262 3.853 3.761 2.189Z"
                />
                <path
                    fill="#001489"
                    d="M345.533 817.789c6.67-5.305 17.669-1.37 24.517 1.818a43.054 43.054 0 0 1 11.206 7.35c3.524 3.279 5.99 7.594 10.22 10.042 2.315 1.34 4.401-1.253 3.415-3.415-7.216-15.824-37.3-34.234-52.689-19.127-2.219 2.178 1.018 5.171 3.331 3.332Z"
                />
                <path
                    fill="#001489"
                    d="M351.764 829.322c7.698-4.096 15.289-.493 22.137 3.7 6.135 3.756 10.623 9.568 16.674 13.249 2.559 1.556 4.771-1.423 3.751-3.752-3.471-7.929-13.911-14.343-21.477-17.847-8.085-3.745-15.848-4.8-23.321.825-2.228 1.677-.348 5.199 2.236 3.825Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M376.516 859.801a5.147 5.147 0 0 1-3.435-2.217c-4.534-6.786-12.396-18.725-14.105-22.433a70.778 70.778 0 0 1-6.023-20.172 41.09 41.09 0 0 0-3.593-12.103l-8.628-18.408a9.917 9.917 0 0 1-.247-7.921"
            />
            <mask
                id="man-34-25-shoes-4-stand-2-b"
                width="74"
                height="38"
                x="395"
                y="823"
                maskUnits="userSpaceOnUse"
                style={{ maskType: 'luminance' }}
            >
                <path
                    fill="#fff"
                    d="M462.716 860.226h-57.648a9.907 9.907 0 0 1-7.277-3.138 9.901 9.901 0 0 1-2.705-7.44c.192-3.288.403-7.12.591-10.98a.995.995 0 0 1 .397-.75 1.008 1.008 0 0 1 .832-.175 15.028 15.028 0 0 0 12.593-2.603 30.3 30.3 0 0 0 3.72-3.647 40.083 40.083 0 0 1 3.132-3.187 21.124 21.124 0 0 1 18.021-4.47 1 1 0 0 1 .605.396c4.312 6.008 10.492 13.871 15.446 17.207 3.66 2.464 10.888 6.412 14.872 8.546a5.43 5.43 0 0 1 2.867 4.797 5.45 5.45 0 0 1-5.446 5.444Z"
                />
            </mask>
            <g mask="url(#man-34-25-shoes-4-stand-2-b)">
                <path
                    fill="#009CDE"
                    d="M462.716 860.226h-57.648a9.907 9.907 0 0 1-7.277-3.138 9.901 9.901 0 0 1-2.705-7.44c.192-3.288.403-7.12.591-10.98a.995.995 0 0 1 .397-.75 1.008 1.008 0 0 1 .832-.175 15.028 15.028 0 0 0 12.593-2.603 30.3 30.3 0 0 0 3.72-3.647 40.083 40.083 0 0 1 3.132-3.187 21.124 21.124 0 0 1 18.021-4.47 1 1 0 0 1 .605.396c4.312 6.008 10.492 13.871 15.446 17.207 3.66 2.464 10.888 6.412 14.872 8.546a5.43 5.43 0 0 1 2.867 4.797 5.45 5.45 0 0 1-5.446 5.444Z"
                />
                <path
                    fill="#001489"
                    d="M408.487 864.249c-2.117-9.876 7.296-20.178 15.308-24.49a63.075 63.075 0 0 1 15.831-5.737c5.501-1.227 12.772-.651 17.411-4.111 1.469-1.097 1.576-3.756-.555-4.241-3.753-.855-7.676.102-11.454.608a74.82 74.82 0 0 0-11.694 2.302c-6.292 1.886-12.75 4.205-18.03 8.199-7.784 5.888-18.008 19.575-10.93 29.204a2.264 2.264 0 0 0 2.792.879 2.264 2.264 0 0 0 1.321-2.613Z"
                />
                <path
                    fill="#001489"
                    d="M420.371 862.711c-.435-8.558 8.156-14.799 14.735-18.757a43.322 43.322 0 0 1 13.742-5.508c4.783-.96 9.458-.631 14.003-2.556 1.932-.818 1.428-4.215-.596-4.562-11.815-2.028-24.675 2.226-34.24 9.131-7.626 5.505-13.815 12.993-12.271 22.879.409 2.621 4.76 2.005 4.627-.627Z"
                />
                <path
                    fill="#001489"
                    d="M430.494 863.154c.936-7.764 7.255-11.751 13.805-14.817 7.336-3.433 15.447-3.807 22.746-6.926 2.17-.927 1.523-4.554-.655-5.012-9.381-1.97-21.373 3.254-29.243 8.095-7.026 4.323-11.391 10.163-11.083 18.66.106 2.919 4.096 2.768 4.43 0Z"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.922 858.236a5.425 5.425 0 0 1-1.88 1.469 5.42 5.42 0 0 1-2.328.523h-57.648a9.906 9.906 0 0 1-7.277-3.138"
            />
        </g>
    );
}
