import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shorts-2-cycling-2-left-a)">
        <path
            d="M504.607 410.108c-21.325-15.324-44.193-29.019-68.668-37.704-66.714-20.923-99.34 71.454-34.258 98.27a459.328 459.328 0 0 0 57.551 22.726l74.605-58.027a384.796 384.796 0 0 0-29.23-25.265Z"
            fill="#001489"
        />
        <defs>
            <clipPath id="man-34-25-shorts-2-cycling-2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
