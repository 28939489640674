import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M465.57 317.032s-9.232-1.055-13.783-1.365c-.684-.042-1.368-.089-2.052-.134-1.254-.085-2.508-.171-3.763-.228l-5.778-.248c-2.626-.143-5.28-.188-7.846-.233-1.25-.022-2.5-.043-3.755-.075-1.574-.034-3.147-.05-4.72-.05-5.51 0-10.888.389-16.349.801 1.324-6.913 2.349-13.966 3.334-21.66a173.611 173.611 0 0 0 1.682-23.05 917.64 917.64 0 0 0-.01-6.841c-.02-5.196-.04-10.568.14-15.837l.005-.14a26.94 26.94 0 0 0-7.652-19.439 27.33 27.33 0 0 0-20.016-8.626 27.355 27.355 0 0 0-27.474 26.703c-.152 5.262-.511 10.616-.858 15.793a937.913 937.913 0 0 0-.442 6.842c-.465 7.61-.415 15.242.148 22.844.52 8.09 1.232 15.563 2.177 22.847.814 7.6 2.066 15.146 3.749 22.602a31.437 31.437 0 0 0 3.334 8.268c3.92 6.397 9.588 10.618 18.299 10.733h.002c.312 0 .626-.007.941-.022l11.502-.548 11.528-.406a221.561 221.561 0 0 0 23.073-2.158c1.236-.188 2.473-.364 3.71-.539 2.542-.362 5.17-.736 7.748-1.2l5.716-.965c1.236-.211 2.47-.451 3.705-.692a364.284 364.284 0 0 0 11.924-2.453l1.133-.253-.417-1.082c-.023-.06-2.32-6.074-2.32-10.892a48.772 48.772 0 0 1 2.85-17.002l.535-1.295Z"
            fill="url(#female-34-25-doctors-coat-2-arms-opt-2-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arms-opt-2-right-a"
                x1={346.257}
                y1={236.893}
                x2={450.353}
                y2={370.917}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
