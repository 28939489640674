import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={6}>
        <path
            d="M502.319 232.634c3.283 5.794 6.922 11.385 10.408 17.064a129.999 129.999 0 0 1 9.146 17.783c2.668 6.145 5.154 12.393 7.389 18.785a165.772 165.772 0 0 1 5.7 19.749 19.416 19.416 0 0 1-9.375 20.904 19.424 19.424 0 0 1-22.765-2.573 165.905 165.905 0 0 1-14.095-14.96c-4.365-5.178-8.477-10.499-12.408-15.923a130.074 130.074 0 0 1-10.649-16.926c-3.113-5.891-6.072-11.87-9.389-17.645a26.5 26.5 0 0 1 29.9-38.778 26.5 26.5 0 0 1 16.061 12.385l.077.135Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M521.822 295.821c3.624.693 7.308 1.057 10.967 1.562a52.592 52.592 0 0 1 10.751 2.721c.881.307 1.758.632 2.632.975.878.324 1.749.689 2.618 1.063l2.608 1.105c.869.373 1.725.812 2.588 1.215a67.28 67.28 0 0 1 10.163 5.869 13.26 13.26 0 0 1 5.297 13.196 13.26 13.26 0 0 1-9.699 10.397 67.146 67.146 0 0 1-11.596 1.809c-.95.065-1.908.166-2.853.2l-2.831.09c-.945.036-1.888.062-2.824.047a70.849 70.849 0 0 1-2.807-.04 52.452 52.452 0 0 1-11.008-1.339c-3.595-.847-7.164-1.837-10.793-2.498a18.483 18.483 0 0 1-11.899-7.675 18.512 18.512 0 0 1-2.987-13.841 18.51 18.51 0 0 1 14.299-14.769 18.494 18.494 0 0 1 7.217-.116l.157.029Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M502.822 324.346a165.905 165.905 0 0 1-14.095-14.96c-4.365-5.178-8.477-10.499-12.408-15.923a130.074 130.074 0 0 1-10.649-16.926c-3.113-5.891-6.072-11.87-9.389-17.645a26.5 26.5 0 0 1 45.961-26.393l.077.135c3.283 5.795 6.922 11.385 10.408 17.064a129.999 129.999 0 0 1 9.146 17.783c2.668 6.145 5.154 12.394 7.389 18.786.898 2.6 1.767 5.216 2.58 7.864"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M530.129 297.031c.887.113 1.773.227 2.658.349a52.51 52.51 0 0 1 10.751 2.721c.881.306 1.759.631 2.633.975a52.53 52.53 0 0 1 2.617 1.063l2.608 1.105c.869.372 1.725.812 2.588 1.215a67.146 67.146 0 0 1 10.163 5.869 13.25 13.25 0 0 1 5.297 13.195 13.255 13.255 0 0 1-9.699 10.398 67.227 67.227 0 0 1-11.596 1.809c-.95.064-1.907.166-2.852.2l-2.831.09c-.945.036-1.889.062-2.824.047a72.783 72.783 0 0 1-2.807-.04 52.52 52.52 0 0 1-11.009-1.339c-3.595-.848-7.163-1.837-10.793-2.498-4.084-.744-8.396-4.415-12.211-7.845"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M607.333 301.738a2.752 2.752 0 0 0-1.688-1.981 2.575 2.575 0 0 0-2.184.446 2.772 2.772 0 0 0-1.103 1.862l-.615.155a2.25 2.25 0 0 0-1.08.828l-1.323 1.889-1.401 2.054a1.995 1.995 0 0 0-.95-.637l-.034-.01a1.981 1.981 0 0 0-2.319.899 36.84 36.84 0 0 1-2.271 3.525l-.124.169a19.432 19.432 0 0 1-5.467-1.805l1.329-2.283a81.744 81.744 0 0 1 7.993-11.025 2.49 2.49 0 0 0-.207-3.453l-.042-.038a2.533 2.533 0 0 0-2.825-.377 40.28 40.28 0 0 0-13.063 10.208 32.448 32.448 0 0 0-11.832 6.613 58.31 58.31 0 0 1-5.796 4.356c-12.445 8.434-15.764 15.1-7.976 19.617 6.539 2.717 14.283 1.308 20.317-2.396.244-.15.486-.304.725-.46l.545.051c5.152.085 10.09-2.35 13.835-5.932l1.076.059a7.24 7.24 0 0 0 4.849-1.732 16.276 16.276 0 0 0 3.37-4.015 40.516 40.516 0 0 0 1.673-2.86l.639-.309a19.227 19.227 0 0 0 5.921-10.724 6.356 6.356 0 0 0 .028-2.694Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M566.154 310.661a32.434 32.434 0 0 1 13.805-8.499 40.288 40.288 0 0 1 13.095-10.223 2.501 2.501 0 0 1 2.793.392l.042.038a2.461 2.461 0 0 1 .222 3.435 81.717 81.717 0 0 0-8.007 11.043"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M583.15 306.727a19.216 19.216 0 0 0 9.092 4.208M575.237 321.345a34.561 34.561 0 0 0 8.923-7.051 2.142 2.142 0 0 1 3.205.054 2.146 2.146 0 0 1 .506 1.712 2.167 2.167 0 0 1-.316.858c-3.394 5.288-7.55 10.156-12.884 13.431-6.034 3.705-14.033 5.661-20.316 2.397-9.684-5.031-.659-14.902 4.276-16.312"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M589.71 314.008a36.745 36.745 0 0 0 4.924-6.764 1.988 1.988 0 0 1 2.321-.901l.034.011a2.003 2.003 0 0 1 1.31 2.538c-1.61 4.93-3.666 9.746-7.086 13.624-3.864 4.381-9.431 7.524-15.272 7.428"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m599.339 304.936 1.323-1.889a2.29 2.29 0 0 1 2.442-.896l.039.01a2.277 2.277 0 0 1 1.632 2.77 45.608 45.608 0 0 1-5.704 13.393 16.28 16.28 0 0 1-3.37 4.016 7.242 7.242 0 0 1-4.847 1.731"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M605.646 299.75a2.757 2.757 0 0 1 1.688 1.981c.183.89.174 1.809-.028 2.694a19.233 19.233 0 0 1-5.921 10.725"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.068 223.572c-8.491-8.307-18.695-15.184-30.908-21.252l15.604 34.205s19.387-8.958 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.32a132.184 132.184 0 0 1 30.908 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
