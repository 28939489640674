import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10270)"
            d="M494.778 223.345a109.585 109.585 0 00-20.026-15.329 31.047 31.047 0 012.394 13.894 66.035 66.035 0 01-1.451 10.763l2.169 4.754a1.001 1.001 0 001.33.493c2.598-1.201 15.6-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_10270)"
            d="M503.371 224.737c-5.143-1.464-10.461-2.978-15.625-4.657l-.129-.041a27.494 27.494 0 00-29.486 8.361 27.5 27.5 0 0012.743 44.027c5.159 1.65 10.349 3.524 15.368 5.335l3.581 1.29 18.676-52.85s-3.759-1.075-5.128-1.465z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10270"
                x1="488.148"
                x2="463.858"
                y1="204.872"
                y2="303.474"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#FFD100"></stop>
                <stop offset="0.966" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_10270"
                x1="487.473"
                x2="463.184"
                y1="204.705"
                y2="303.308"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#FFD100"></stop>
                <stop offset="0.966" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
