import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={469}
            height={284}
            fill="none"
            id="object-devices-screen"
            {...attributes}
        >
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#object-devices-screen-a)"
            >
                <path d="M467.047 282.546H35.832v-18.704h431.215" />
                <path d="M1.7 282.547V1.611h465.346v262.232" />
            </g>
            <defs>
                <clipPath id="object-devices-screen-a">
                    <path fill="#fff" d="M.7.611h467.346v282.936H.7z" />
                </clipPath>
            </defs>
        </svg>
    );
}
