import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={30}
            height={30}
            fill="none"
            id="object-food-blueberry"
            {...attributes}
        >
            <path
                fill="#009CDE"
                d="M15.219 29.32c7.874 0 14.258-6.383 14.258-14.257 0-7.875-6.384-14.258-14.258-14.258C7.344.805.96 7.188.96 15.063c0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m11.682 4.467 7.074 7.074M18.756 4.467l-7.074 7.074"
            />
        </svg>
    );
}
