import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-tshirt-3-cycling-2-a)">
        <path
            d="M500.525 279.004a196.096 196.096 0 0 0-6.027-44.017h-.006c-4.136-15.817-12.877-44.522-28.431-57.561-1.092 6.208-6.253 13.036-13.856 13.096-9.114.073-16.904-6.67-23.241-13.22-6.538-6.814-13.18-18.658-24.328-23.064-11.407 2.957-22.312 8.756-32.489 17.307-54.522 45.811-58.153 129.036-71.64 206.826 0 0 46.901 51.545 111.602 57.368 32.53 2.928 59.53-19.843 66.686-41.313 9.649-28.947 10.507-44.203 8.449-64.254-1.017-9.915 1.066-20.237 7.211-28.084 4.374-5.585 6.465-14.167 6.07-23.084Z"
            fill="url(#man-34-25-tshirt-3-cycling-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-cycling-2-b"
                x1={300.507}
                y1={295.116}
                x2={500.572}
                y2={295.116}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <clipPath id="man-34-25-tshirt-3-cycling-2-a">
                <path
                    fill="#fff"
                    transform="translate(.877 .602)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
