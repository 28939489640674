import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m452.006 780.282-.06-.936c-3.165-49.247-3.937-57.958-4.191-60.82-.114-1.287-.115-1.319-.182-3.376l-.042-1.319c-1.189-23.831 8.998-64.579 21.848-87.506-2.207-6.137-26.955-74.954-28.191-78.228-11.044-29.224-19.304-69.605-21.021-87.52l-.063-.662 84.105-45.103.517.42a51.945 51.945 0 0 1 16.214 22.887c.314 1.064.677 2.038 1.135 3.271 1.642 4.418 4.489 10.882 9.586 33.265 18.036 79.204.905 146.31-26.018 268.867a24645.324 24645.324 0 0 0-7.875 35.972l-.172.788h-45.59Z"
            fill="#888B8D"
        />
        <path
            d="m386.338 786.917-.18-.775c-10.331-44.6-12.605-53.237-13.46-56.49-.504-1.913-.569-2.158-1.4-6.273-5.527-25.231-2.51-70.11 6.452-96.236-.548-2.364-3.896-17.405-9.169-41.106-4.765-21.417-9.691-43.564-10.135-45.418-5.546-23.19-10.59-65.876-9.04-88.291a86.506 86.506 0 0 0-.363-16.973 69.826 69.826 0 0 1 2.221-27.69l.201-.735h170.127l.273 1.94c-7.086 18.944-24.058 39.118-45.209 56.379-12.012 9.804-18.363 24.673-18.297 40.2a296.423 296.423 0 0 1-1.875 31.421c-1.532 15.544-6.794 45.552-13.74 69.53a148.608 148.608 0 0 0-6.085 37.295c-.829 34.637-2.44 103.903-3.71 142.163l-.031.967-46.58.092Z"
            fill="url(#female-34-25-pants-8-stand-1-a)"
        />
        <path
            d="M381.347 765.479h52.23c.173-5.928.347-12.19.522-18.651h-57.16c1.137 4.751 2.576 10.83 4.408 18.651Z"
            fill="url(#female-34-25-pants-8-stand-1-b)"
        />
        <path
            d="M371.298 723.381c.735 3.631.871 4.25 1.24 5.661h62.034c.16-6.19.319-12.44.475-18.651h-65.709a111.878 111.878 0 0 0 1.96 12.99Z"
            fill="url(#female-34-25-pants-8-stand-1-c)"
        />
        <path
            d="M506.443 739.883h-57.101c.34 4.954.752 11.072 1.251 18.651h51.754c1.116-5.09 2.218-10.106 3.296-15.014l.8-3.637ZM514.479 703.453h-66.83a94.61 94.61 0 0 0-.118 10.381l.042 1.32c.067 2.057.068 2.088.182 3.375.061.69.152 1.721.3 3.575h62.311a25096.79 25096.79 0 0 0 4.113-18.651Z"
            fill="#63666A"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-8-stand-1-a"
                x1={361.598}
                y1={597.683}
                x2={460.616}
                y2={595.078}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-stand-1-b"
                x1={376.939}
                y1={756.154}
                x2={434.1}
                y2={756.154}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
            <linearGradient
                id="female-34-25-pants-8-stand-1-c"
                x1={369.337}
                y1={719.716}
                x2={435.046}
                y2={719.716}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#63666A" />
                <stop offset={1} stopColor="#8E8B8F" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
