import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6479)"
            d="M402.469 354.206a19.38 19.38 0 01-15.455-7.59l-.004-.003a20.436 20.436 0 01-5.465-7.1 228.593 228.593 0 01-8.711-22.542c-2.406-7.233-4.621-14.698-6.772-22.826-2.132-7.808-4.21-15.506-5.252-23.102-.325-2.326-2.655-17.875-3.32-23.307-1.897-15.506 8.005-29.299 22.954-31.848a27.72 27.72 0 014.655-.396 27.415 27.415 0 0127.077 22.881l.024.138c.887 5.369 4.073 20.975 4.522 23.268a180.652 180.652 0 013.023 23.355c.29 3.912.527 7.931.707 11.939a262.77 262.77 0 0111.236-7.306 360.12 360.12 0 003.467-2.13c2.397-1.481 4.872-3.01 7.331-4.417l5.431-3.182a192.84 192.84 0 013.576-2.018s1.357-.756 1.946-1.088c3.139-1.743 7.338-4.049 12.039-6.452l1.64-.838-.189 1.832c-1.013 9.766 7.411 20.799 13.655 24.104l1.217.644-.989.958a434.65 434.65 0 01-7.552 7.127c-.552.505-1.098 1.009-1.644 1.513-1.009.932-2.014 1.86-3.035 2.765l-4.73 4.175c-2.123 1.911-4.318 3.757-6.441 5.543-1.065.896-2.106 1.771-3.142 2.657a262.31 262.31 0 01-20 15.366l-10.355 7.112-10.26 7.22a19.344 19.344 0 01-11.184 3.548z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6479"
                x1="444.316"
                x2="364.506"
                y1="331.252"
                y2="226.283"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
