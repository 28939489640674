import { IBodyClothes, TSkinEl, TBodyPosition } from 'interfaces';

import ArmRightSkinDown from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-down';
import ArmRightSkinOption1 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-1';
import ArmRightSkinOption2 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-2';
import ArmRightSkinOption3 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-3';
import ArmRightSkinOption4 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-4';
import ArmRightSkinOption5 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-5';
import ArmRightSkinOption6 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-6';
import ArmRightSkinOption7 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-right-option-7';

import ArmLeftSkinUp from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-up';
import ArmLeftSkinDown from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-down';
import ArmLeftSkinOption1 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-1';
import ArmLeftSkinOption2 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-2';
import ArmLeftSkinOption3 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-3';
import ArmLeftSkinOption4 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-4';
import ArmLeftSkinOption5 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-5';
import ArmLeftSkinOption6 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-6';
import ArmLeftSkinOption7 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arm-left-option-7';

import ArmsRightSkinOption1 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-1-right';
import ArmsLeftSkinOption1 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-1-left';
import ArmsRightSkinOption2 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-2-right';
import ArmsLeftSkinOption2 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-2-left';
import ArmsRightSkinOption3 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-3-right';
import ArmsLeftSkinOption3 from 'assets/male/skin-layer/normal-arms/man-bold-34-25-arms-option-3-left';

import ArmsRightSkinCycling1Option1 from 'assets/male/skin-layer/cycling-arms/man-34-25-arms-opt-1-cycling-1-right';
import ArmsLeftSkinCycling1Option1 from 'assets/male/skin-layer/cycling-arms/man-34-25-arms-opt-1-cycling-1-left';
import ArmsRightSkinCycling2Option1 from 'assets/male/skin-layer/cycling-arms/man-34-25-arms-opt-1-cycling-2-right';
import ArmsLeftSkinCycling2Option1 from 'assets/male/skin-layer/cycling-arms/man-34-25-arms-opt-1-cycling-2-left';

import BodySkinNormal from 'assets/male/skin-layer/man-bold-34-25-body-normal';
import BodySkinSitting from 'assets/male/skin-layer/man-bold-34-25-body-sitting';
import BodySkinCycling from 'assets/male/skin-layer/man-bold-34-25-body-cycling';

import MHeadSkin from 'assets/male/skin-layer/man-bold-34-25-head';

import jacket from 'assets/male/clothes/body/jacket';
import doctorsCoat from 'assets/male/clothes/body/doctors-coat';
import tshirt1 from 'assets/male/clothes/body/tshirt-1';
import tshirt2 from 'assets/male/clothes/body/tshirt-2';
import tshirt3 from 'assets/male/clothes/body/tshirt-3';
import tshirt4 from 'assets/male/clothes/body/tshirt-4';
import shirt1 from 'assets/male/clothes/body/shirt-1';
import shirt2 from 'assets/male/clothes/body/shirt-2';
import shirt3 from 'assets/male/clothes/body/shirt-3';

export const M_HEAD_SKIN = MHeadSkin;

export const M_LEFT_ARM_POSITION: Record<string, string> = {
    down: 'Down',
    up: 'Up',
    option1: 'Option #1',
    option2: 'Option #2',
    option3: 'Option #3',
    option4: 'Option #4',
    option5: 'Option #5',
    option6: 'Option #6',
    option7: 'Option #7',
};

export const M_RIGHT_ARM_POSITION: Record<string, string> = {
    down: 'Down',
    option1: 'Option #1',
    option2: 'Option #2',
    option3: 'Option #3',
    option4: 'Option #4',
    option5: 'Option #5',
    option6: 'Option #6',
    option7: 'Option #7',
};

export const M_BOTH_ARMS_POSITION: Record<string, string> = {
    option1: 'Option #1',
    option2: 'Option #2',
    option3: 'Option #3',
};

export const M_BOTH_ARMS_CYCLING_POSITION_1: Record<string, string> = {
    option1: 'Option #4',
};

export const M_BOTH_ARMS_CYCLING_POSITION_2: Record<string, string> = {
    option1: 'Option #5',
};

export const M_BODY_CLOTHES: Record<string, IBodyClothes> = {
    doctorsCoat,
    jacket,
    tshirt1,
    tshirt2,
    tshirt3,
    tshirt4,
    shirt1,
    shirt2,
    shirt3,
};

export const MBodyClothes = Object.keys(M_BODY_CLOTHES);

export const M_ARM_RIGHT_SKINS: Record<string, TSkinEl> = {
    down: ArmRightSkinDown,
    option1: ArmRightSkinOption1,
    option2: ArmRightSkinOption2,
    option3: ArmRightSkinOption3,
    option4: ArmRightSkinOption4,
    option5: ArmRightSkinOption5,
    option6: ArmRightSkinOption6,
    option7: ArmRightSkinOption7,
};

export const M_ARM_LEFT_SKINS: Record<string, TSkinEl> = {
    down: ArmLeftSkinDown,
    up: ArmLeftSkinUp,
    option1: ArmLeftSkinOption1,
    option2: ArmLeftSkinOption2,
    option3: ArmLeftSkinOption3,
    option4: ArmLeftSkinOption4,
    option5: ArmLeftSkinOption5,
    option6: ArmLeftSkinOption6,
    option7: ArmLeftSkinOption7,
};

export const M_BOTH_ARMS_SKINS: {
    normal: Record<string, [TSkinEl, TSkinEl]>,
    cycling1: Record<string, [TSkinEl, TSkinEl]>,
    cycling2: Record<string, [TSkinEl, TSkinEl]>
} = {
    normal: {
        option1: [ArmsRightSkinOption1, ArmsLeftSkinOption1],
        option2: [ArmsRightSkinOption2, ArmsLeftSkinOption2],
        option3: [ArmsRightSkinOption3, ArmsLeftSkinOption3],
    },
    cycling1: {
        option1: [ArmsRightSkinCycling1Option1, ArmsLeftSkinCycling1Option1],
    },
    cycling2: {
        option1: [ArmsRightSkinCycling2Option1, ArmsLeftSkinCycling2Option1],
    }
};

export const M_BODY_SKIN_POSITION: Record<TBodyPosition, TSkinEl | null> = {
    normal: BodySkinNormal,
    sitting: BodySkinSitting,
    cycling1: BodySkinCycling,
    cycling2: BodySkinCycling,
};
