import React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string]> = {
    white: ['#FFDBAE', '#E3C094', '#CDAB81'],
    black: ['#6C453A', '#634036', '#4D352C'],
    brown: ['#CB865E', '#B07553', '#9A684B'],
    yellow: ['#FCB47F', '#E19A6D', '#CB865E'],
};


const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-stand-1">
        <path
            fill={SKIN_COLOR[color][0]}
            d="M457.101 801.3c-.5-7.7-.9-15.4-1.1-23.1-.1-3.8-.3-7.7-.4-11.5l-.2-11.5c-.2-7.7-.2-15.3-.6-23-.3-7.7-1-15.5-1-23.1-.2-15.3 3.2-30 7.1-44.5 2.1-7.3 4.5-14.5 7.4-21.6.7-1.8 1.5-3.5 2.3-5.3.8-1.8 1.6-3.5 2.5-5.3 1.7-3.5 3.6-7 5.9-10.4l6.9-10.6c7.9-12.1 24.1-15.4 36.1-7.5 9.5 6.2 13.6 17.7 11 28.1l-10.9 43.8-5.4 21.9-5.6 21.9c-3.7 14.6-7.4 29.2-11.2 43.8l-11.4 43.7c-2.2 8.5-10.9 13.6-19.4 11.4-6.8-1.8-11.4-7.7-11.9-14.3l-.1-2.9Z"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M477.401 631.1c-5.1-15.1-9.8-30.2-14.7-45.3l-7.2-22.7-3.6-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6-4-27.8 15.2-53.6 43-57.7 26-3.8 50.2 12.8 56.6 37.7l1.2 4.9c1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-2.4 32-5.3 48.3l-.8 4.3c-2.9 15.6-17.9 26-33.5 23.1-10.5-1.9-18.7-9.5-22-19Z"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-1-a)"
            d="M477.401 631.1c-5.1-15.1-9.8-30.2-14.7-45.3l-7.2-22.7-3.6-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6-4-27.8 15.2-53.6 43-57.7 26-3.8 50.2 12.8 56.6 37.7l1.2 4.9c1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-2.4 32-5.3 48.3l-.8 4.3c-2.9 15.6-17.9 26-33.5 23.1-10.5-1.9-18.7-9.5-22-19Z"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m457.301 804.2-.2-2.9c-.5-7.7-.9-15.4-1.1-23.1-.1-3.8-.3-7.7-.4-11.5l-.2-11.5c-.2-7.7-.2-15.3-.6-23-.3-7.7-1-15.5-1-23.1-.2-15.3 3.2-30 7.1-44.5 2.1-7.3 4.5-14.5 7.4-21.6.7-1.8 1.5-3.5 2.3-5.3.8-1.8 1.6-3.5 2.5-5.3m60.001-.4-10.9 43.8-5.4 21.9-5.6 21.9c-3.7 14.6-7.401 29.2-11.201 43.8l-11.399 43.7M521.9 416.3s3.3 7.4 6.1 17.2c1.1 3.8 1.8 7.6 2.6 11.5l1.1 5.8 1 5.8c.7 3.9 1.2 7.7 1.8 11.6.6 3.9 1.1 7.8 1.5 11.7 1.9 15.6 3.4 31.2 4 46.9.3 7.9.2 15.8 0 23.8l-.4 11.9c-.1 4-.4 8-.6 12-.9 16-3 41.6-6 57.6m-55.599-1c-5.1-15.1-9.8-30.2-14.7-45.3l-7.2-22.7-3.6-11.3-1.8-5.7-1.7-5.7c-4.7-15.1-8.4-30.4-12-45.7-1.8-7.7-3.5-15.3-5-23l-1.2-5.8-1-5.8c-.7-3.9-1.4-7.7-2-11.6m86.1 178.4h15.5"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M456.4 798.7c-.4 4.3-4.6 20.4-7.5 30.7-1.4 5 1.801 10.1 6.801 11.2l19.9 4.2c4.2.9 8.3 2.4 12.1 4.5 4 2.2 10.5 5.3 19.5 7.2 4 .9 19 1.8 26.199 2.2 2.2.1 4.101-1.5 4.401-3.7.2-1.6-.6-3.2-1.901-4-2.9-1.8-7.9-4.9-12-7.2-18.5-10.4-35.699-38.5-35.699-38.5"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M456.4 798.7c-.4 4.3-4.6 20.4-7.5 30.7-1.4 5 1.801 10.1 6.801 11.2l19.9 4.2c4.2.9 8.3 2.4 12.1 4.5 4 2.2 10.5 5.3 19.5 7.2 4 .9 18.999 1.8 26.199 2.2 2.2.1 4.101-1.5 4.401-3.7.2-1.6-.601-3.2-1.901-4-2.9-1.8-7.9-4.9-12-7.2-18.5-10.4-35.699-38.5-35.699-38.5"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M396.199 817.8c.5 4.3-.3 20.9-1 31.6-.3 5.2 3.8 9.5 9 9.5h57.6c2.5 0 4.4-2 4.4-4.4 0-1.6-.9-3.1-2.3-3.9-3.8-2-11.2-6.1-15-8.6-8.5-5.7-20.3-24.2-20.3-24.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M396.199 817.8c.5 4.3-.3 20.9-1 31.6-.3 5.2 3.8 9.5 9 9.5h57.6c2.5 0 4.4-2 4.4-4.4 0-1.6-.9-3.1-2.3-3.9-3.8-2-11.2-6.1-15-8.6-8.5-5.7-20.3-24.2-20.3-24.2"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M369.9 386.9c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89 13.6 105.3 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.6-11.7 33.3-45.2"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-1-b)"
            d="M369.9 386.9c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89 13.6 105.3 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.6-11.7 33.3-45.2"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M369.9 386.9c-12.6 21.7-20.9 32.3-16.6 69 3.3 28.2 89 13.6 105.3 18.3 23.7 9.5 39.1-42.1 39.1-42.1s24.6-11.7 33.3-45.2"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M396.3 817.8c-1.9-8-3.7-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.9 3.3-23.9.4-2 .8-4 1.3-6s.9-4 1.5-6c1.1-4 2.3-8 3.9-11.9l4.4-11.2c5.3-13.5 20.6-20.1 34-14.7 10.8 4.3 17.2 14.9 16.5 25.9l-2.7 47.7-1.4 23.9-1.5 23.9c-1 15.9-2 31.8-3.1 47.7l-3.3 47.7c-.6 8.8-8.2 15.4-17 14.8-7.1-.5-12.8-5.6-14.4-12.2l-.6-2.5Z"
        />
        <path
            fill={SKIN_COLOR[color][0]}
            d="M383.8 628.2c-3.5-15.4-6.7-30.8-9.9-46.1L369 559l-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.9-17.5-11.2-19.8-21Z"
        />
        <path
            fill="url(#man-bold-34-25-legs-stand-1-c)"
            d="M383.8 628.2c-3.5-15.4-6.7-30.8-9.9-46.1L369 559l-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-1.9-15.4-2.6-23.2l-.6-5.8-.4-5.8c-.3-3.9-.6-7.7-.8-11.6-1.2-28.1 20.6-51.8 48.7-53 26.2-1.1 48.6 17.8 52.5 43.2l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1l-1.3 4.3c-4.5 15.3-20.5 24-35.8 19.5-10.2-2.9-17.5-11.2-19.8-21Z"
            opacity={0.73}
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="m396.9 820.5-.6-2.6c-1.9-8-3.7-15.9-5.3-23.9-.8-4-1.7-8-2.4-11.9l-2.3-11.9c-1.5-8-2.9-15.9-4.7-23.9-1.7-8-3.8-15.9-5.1-23.9-3-15.9-2.3-31.8-1.1-47.7.7-8 1.8-15.9 3.3-23.9.4-2 .8-4 1.3-6s.9-4 1.5-6c.5-1.9 1.1-3.8 1.7-5.7m57.099-6.2-2.7 47.7-1.4 23.9-1.5 23.9c-1 15.9-2 31.8-3.1 47.7l-3.3 47.7M453.6 433.5l.7 5.1c.6 3.9 1 7.7 1.4 11.6l.5 5.8.4 5.8c.3 3.9.4 7.7.6 11.6.2 3.9.3 7.8.3 11.6.3 15.5.2 31.1-.9 46.6-.5 7.8-1.4 15.6-2.4 23.4l-1.6 11.7-1.8 11.7c-2.6 15.6-5.6 31.3-10.3 47.1m-56.7 2.7c-3.5-15.4-6.7-30.8-9.9-46.1L369 559l-2.4-11.5-1.2-5.8-1.1-5.8c-3.1-15.4-5.2-30.8-7.3-46.3-1-7.7-5.8-41.4-5.9-45.2m68.8 182.5h15.5"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-legs-stand-1-a"
                x1={483.461}
                x2={483.461}
                y1={650.651}
                y2={390.29}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.543} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.976} stopColor={SKIN_COLOR[color][2]} />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-legs-stand-1-b"
                x1={321.558}
                x2={634.962}
                y1={431.091}
                y2={431.091}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor={SKIN_COLOR[color][2]} />
                <stop offset={0.445} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.932} stopColor={SKIN_COLOR[color][0]} />
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-legs-stand-1-c"
                x1={491.949}
                x2={259.865}
                y1={595.009}
                y2={378.586}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]} />
                <stop offset={0.543} stopColor={SKIN_COLOR[color][1]} />
                <stop offset={0.976} stopColor={SKIN_COLOR[color][2]} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
