import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-cycling-1-right">
        <path
            d="M432.099 249.444a238.825 238.825 0 0 0-16.096-22.844c-1.74-2.19-3.489-4.373-5.247-6.549-3.99-4.959-8.115-10.086-11.999-15.253l-.084-.112a31.773 31.773 0 0 0-25.516-12.658 31.708 31.708 0 0 0-19.319 6.51 32.038 32.038 0 0 0-6.15 44.835c3.905 5.146 7.707 10.515 11.384 15.707 1.618 2.284 3.24 4.564 4.865 6.84a239.26 239.26 0 0 0 17.566 21.728 507.376 507.376 0 0 0 15.579 16.673l46.014-36.697a511.037 511.037 0 0 0-10.997-18.18Z"
            fill="url(#man-34-25-tshirt-2-both-arms-option-1-cycling-1-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-both-arms-option-1-cycling-1-right-a"
                x1={472.069}
                y1={294.87}
                x2={336.093}
                y2={218.099}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.335} stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
