import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-option-2">
        <path
            d="m465.799 167.793 2.55 1.203c12.537 5.916 22.676 12.593 30.997 20.411.338.3.594.681.745 1.106.15.426.19.883.116 1.329-.698 4.602-13.337 10.971-15.864 12.202l-.903.439-17.641-36.69Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-2-a)"
        />
        <path
            d="M505.334 304.245a376.88 376.88 0 0 1-5.511-6.379c-5.352-6.358-10.615-13.032-16.091-20.407a204.786 204.786 0 0 1-14.283-21.579 886.983 886.983 0 0 1-3.848-6.751c-2.903-5.121-5.906-10.416-9.037-15.505a32 32 0 0 1 22.2-48.362 31.991 31.991 0 0 1 32.315 14.833l.08.13c3.106 5.105 6.452 10.189 9.688 15.106 1.425 2.165 2.846 4.333 4.261 6.505a205.088 205.088 0 0 1 12.71 22.547c4.068 8.226 7.613 15.951 10.836 23.609-12.879 2.965-36.626 25.736-43.32 36.253Z"
            fill="url(#man-34-25-tshirt-4-arm-left-option-2-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-2-a"
                x1={481.585}
                y1={174.08}
                x2={526.983}
                y2={342.948}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-option-2-b"
                x1={481.308}
                y1={174.155}
                x2={526.706}
                y2={343.022}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
