import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-nose-2" transform="translate(329, 20)">
        <path
            fill={SKIN_COLOR[color]}
            d="M145.148 105.7c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M145.148 105.7c4 .7 12.2 1.7 12.2-5.5 0-6.1-8.5-8.5-10-14.4"
        />
    </g>
);

export default SvgComponent;
