import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 187,
        height: 143,
        content: (
            <>
                <path
                    fill="url(#object-food-bell-pepper-a)"
                    d="M106.193 84.235c-6.656 0-12.052-5.397-12.052-12.053 0-6.656 5.396-12.053 12.052-12.053 18.386 0 53.385-6.826 61.796-15.236 11.638-11.638 23.862 10.228 17.045 17.045-17.012 17.012-63.743 22.297-78.841 22.297Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-b)"
                    d="M92.563 85.78c-50.114 0-90.739-18.919-90.739-42.258 0-23.339 40.625-42.258 90.74-42.258 50.113 0 50.113 84.517 0 84.517Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-c)"
                    d="M92.563 140.892c-50.114 0-90.739-18.919-90.739-42.258 0-23.34 40.625-42.259 90.74-42.259 50.113 0 50.113 84.517 0 84.517Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-d)"
                    d="M92.563 113.183c-50.114 0-90.739-18.92-90.739-42.258 0-23.34 40.625-42.259 90.74-42.259 50.113 0 50.113 84.516 0 84.516v.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.403 25.062C5.275 30.754 1.824 37.18 1.824 43.98c0 4.797 1.719 9.406 4.88 13.702-3.161 4.297-4.88 8.906-4.88 13.702 0 4.797 1.719 9.406 4.88 13.703-3.161 4.297-4.88 8.905-4.88 13.701 0 23.339 40.625 42.259 90.74 42.259 30.561 0 42.483-31.433 35.769-55.96 2.419-8.837 2.419-18.568 0-27.404 6.715-24.527-5.207-55.96-35.77-55.96-23.497 0-44.909 4.16-61.025 10.984"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M128.335 85.086c-4.295 15.691-16.219 28.556-35.77 28.556"
                />
                <defs>
                    <linearGradient
                        id="object-food-bell-pepper-a"
                        x1={94.141}
                        x2={186.996}
                        y1={62.888}
                        y2={62.888}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-b"
                        x1={50.656}
                        x2={25.078}
                        y1={27.052}
                        y2={9.189}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-c"
                        x1={63.022}
                        x2={84.539}
                        y1={114.34}
                        y2={12.843}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-d"
                        x1={129.277}
                        x2={64.319}
                        y1={133.793}
                        y2={63.963}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Option #2': {
        width: 187,
        height: 233,
        content: (
            <>
                <path
                    fill="url(#object-food-bell-pepper-a)"
                    d="M106.193 174.963c-6.656 0-12.052-5.396-12.052-12.053 0-6.656 5.396-12.052 12.052-12.052 18.386 0 53.385-6.826 61.796-15.237 11.638-11.638 23.862 10.228 17.045 17.045-17.012 17.012-63.743 22.297-78.841 22.297Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-b)"
                    d="M92.563 176.509c-50.114 0-90.739-18.919-90.739-42.258 0-23.339 40.625-42.259 90.74-42.259 50.113 0 50.113 84.517 0 84.517Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-c)"
                    d="M92.563 231.619c-50.114 0-90.739-18.92-90.739-42.259s40.625-42.258 90.74-42.258c50.113 0 50.113 84.517 0 84.517Z"
                />
                <path
                    fill="url(#object-food-bell-pepper-d)"
                    d="M92.563 203.912c-50.114 0-90.739-18.92-90.739-42.259s40.625-42.258 90.74-42.258c50.113 0 50.113 84.516 0 84.516v.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M11.403 115.793c-6.128 5.691-9.579 12.117-9.579 18.918 0 4.796 1.719 9.405 4.88 13.702-3.161 4.297-4.88 8.906-4.88 13.702 0 4.796 1.719 9.405 4.88 13.702-3.161 4.298-4.88 8.906-4.88 13.702 0 23.339 40.625 42.258 90.74 42.258 30.561 0 42.483-31.432 35.769-55.96 2.419-8.836 2.419-18.567 0-27.404 6.715-24.527-5.207-55.96-35.77-55.96-23.497 0-44.909 4.16-61.025 10.984"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M128.335 175.812c-4.295 15.692-16.219 28.557-35.77 28.557M12.315 68.543c0-11.197 6.766-11.197 6.766-22.395s-6.766-11.2-6.766-22.398c0-11.2 6.766-11.2 6.766-22.398M62.603 68.543c0-11.197 6.768-11.197 6.768-22.395s-6.767-11.2-6.767-22.398c0-11.2 6.767-11.2 6.767-22.398M112.891 68.543c0-11.197 6.767-11.197 6.767-22.395s-6.767-11.2-6.767-22.398c0-11.2 6.767-11.2 6.767-22.398"
                />
                <defs>
                    <linearGradient
                        id="object-food-bell-pepper-a"
                        x1={94.141}
                        x2={186.996}
                        y1={153.617}
                        y2={153.617}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-b"
                        x1={50.656}
                        x2={25.078}
                        y1={117.78}
                        y2={99.917}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-c"
                        x1={63.022}
                        x2={84.539}
                        y1={205.067}
                        y2={103.569}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-bell-pepper-d"
                        x1={129.277}
                        x2={64.319}
                        y1={224.521}
                        y2={154.691}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-bell-pepper"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
