import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-1-both-arms-option-2-left">
        <path
            d="m465.846 167.07 2.654.952c13.05 4.677 23.788 10.342 32.825 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.648-16.938 8.175-19.334 9.644l-.857.525-21.103-34.814Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-2-left-a)"
        />
        <path
            d="M518.804 198.591c-9.281-6.355-17.305-16.13-28.914-18.162-20.362-4.496-40.617 14.04-37.902 34.715.941 15.834 15.348 24.112 25.884 33.637 6.188 5.434 12.453 10.783 19.102 15.655l.002-.002c1.497 1.089 56.761-35.313 56.761-35.313-10.699-12-28.546-25.804-34.933-30.53Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-2-left-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-2-left-a"
                x1={487.576}
                y1={172.138}
                x2={473.48}
                y2={266.909}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-2-left-b"
                x1={509.259}
                y1={175.36}
                x2={495.163}
                y2={270.131}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
