import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2571}
            height={1473}
            viewBox="0 0 2571 1473"
            fill="none"
            id="scenes-kitchen-2"
            {...attributes}
        >
            <path fill="#fff" d="M.102.602h2570v1472H.102z" />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M572.68 567.298h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M833.379 220.949v373.95h-336.3v-364.05h305.55v336.45h-178.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m623.681 567.6 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5M1596.35 198.602h-192v133.5h-138l-93 58.5h304.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1269.35 198.602v112.5h117l115.5 79.5v36h-342v-34.5M294.102 629.102H1962.1l55.5 40.5H542.102v19.5H2026.6v28.5h-433v105M1613.11 743.102v40.5M1577.11 743.102v40.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1034.69 500.296v-28.329h44.67c11.58 0 20.97-9.387 20.97-20.967H997.539v20.967h16.191v43.032h27.91"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1041.65 514.997 4.31-14.704h55.03l12.95 106.834h-99.28l17.98-61.374"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1080.79 586.542c6.63 0 12-5.375 12-12.005 0-6.631-5.37-12.006-12-12.006s-12.01 5.375-12.01 12.006c0 6.63 5.38 12.005 12.01 12.005Z"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1080.79 562.536v-32.832H1001.05v7.726h-20.972v-44.86h20.972v7.727h33.65M549.13 997.5l-51.54 193h14l60-176h79.5l6.5 176h14l6.5-176h110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m638.07 997.5-22.48 193h-14l8.224-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M780.877 984.814c-5.457-6.953-13.889-6.311-38.735-6.311-18.602 0-132.667.36-197.916.648-18.81.083-33.972-15.38-33.525-34.185l1.863-78.347c.637-26.787 21.22-48.985 47.912-51.334 10.424-.917 22.004-1.346 34.113-.782a240.258 240.258 0 0 1 40.629 5.39 52.823 52.823 0 0 1 39.3 36.775l46.071 157.832 54 176h14l-37.725-161.94M609.813 997.5h-60.684M788.59 997.5H638.07M1665.05 997.5l51.54 193h-14l-60-176h-79.5l-6.5 176h-14l-6.5-176h-110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1576.11 997.5 22.48 193h14l-8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1433.3 984.814c5.46-6.953 13.89-6.311 38.74-6.311 18.6 0 132.66.36 197.91.648 18.81.083 33.98-15.38 33.53-34.185l-1.86-78.347c-.64-26.787-21.22-48.985-47.92-51.334-10.42-.917-22-1.346-34.11-.782a240.3 240.3 0 0 0-40.63 5.39 52.837 52.837 0 0 0-39.3 36.775l-46.07 157.832-54 176h-14l37.73-161.94M1604.37 997.5h60.68M1425.59 997.5h150.52M2031.05 997.5l51.54 193h-14l-60-176h-79.5l-6.5 176h-14l-6.5-176h-110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1942.11 997.5 22.48 193h14l-8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1799.3 984.814c5.46-6.953 13.89-6.311 38.74-6.311 18.6 0 132.66.36 197.91.648 18.81.083 33.98-15.38 33.53-34.185l-1.86-78.347c-.64-26.787-21.22-48.985-47.92-51.334-10.42-.917-22-1.346-34.11-.782a240.3 240.3 0 0 0-40.63 5.39 52.837 52.837 0 0 0-39.3 36.775l-46.07 157.832-54 176h-14l37.73-161.94M1970.37 997.5h60.68M1791.59 997.5h150.52"
            />
            <path fill="url(#a)" d="M1501.85 820.586H936.68v28.537h565.17v-28.537Z" />
            <path
                fill="url(#b)"
                d="M1070.05 1190.51h-16.6l66.44-341.385h36.25l-86.09 341.385Z"
            />
            <path
                fill="url(#c)"
                d="M1368.47 1190.51h16.61l-66.44-341.385h-36.25l86.08 341.385Z"
            />
            <path
                fill="#3A383A"
                d="M1210.96 1190.5h16.61l9.82-341.379h-36.25l9.82 341.379Z"
            />
            <path
                fill="#E2E2E2"
                d="m1336.84 942.609-18.2-93.484h-36.25l23.57 93.484h30.88ZM1132.57 942.609l23.57-93.484h-36.25l-18.19 93.484h30.87Z"
            />
            <path
                fill="#AEAEAF"
                d="m1234.7 942.605 2.69-93.484h-36.25l2.69 93.484h30.87Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1501.85 820.586H936.68v28.537h565.17v-28.537Z"
            />
            <path
                fill="#FCB47F"
                d="M1491.78 536.302c-2.8 8.1-6 16.1-9.1 24.1-3.1 8-5.6 16.2-7.6 24.6-2.1 8.4-4 16.8-5.6 25.3-1.6 8.5-2.9 17.2-3.6 26-1 12.8 8.6 24 21.4 25 8.6.7 16.6-3.5 21.1-10.2 5-7.4 9.2-15 13.3-22.6 4.1-7.7 7.8-15.5 11.4-23.3 3.6-7.8 6.8-15.8 9.3-24 2.6-8.2 5-16.4 7.9-24.5 5.7-16.2-2.8-33.8-19-39.5-16.2-5.7-33.8 2.8-39.5 19v.1Z"
            />
            <path
                fill="#FCB47F"
                d="m1468.48 628.802-6.7 10-6.7 9.9c-4.5 6.6-8.7 13.399-12.9 20.299-2 3.5-4.1 6.9-6.1 10.4s-4 6.9-5.9 10.5c-1.9 3.5-3.9 7-5.8 10.6-1.8 3.6-3.8 7.101-5.5 10.801-3.8 7.8-.5 17.2 7.3 21 6.2 3 13.4 1.5 18-3.1 2.9-2.9 5.5-5.901 8.3-8.801 2.7-3 5.4-6 8-9 2.7-3 5.3-5.999 7.9-9.099 2.6-3 5.2-6.1 7.7-9.2 5.1-6.2 10.1-12.4 14.8-18.9l7.1-9.6 7.2-9.601c7.4-10 5.4-24.1-4.6-31.5-10-7.4-24.1-5.399-31.5 4.601-.1.1-.4.4-.6.7Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1491.88 536.102v.1c-2.8 8.1-6 16.1-9.1 24.1-3.1 8-5.6 16.199-7.6 24.599-2.1 8.4-4 16.801-5.6 25.301-.7 3.9-2.1 12.899-2.7 16.799"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1508.48 651.103c5-7.4 9.2-15 13.3-22.6 4.1-7.7 7.8-15.5 11.4-23.3 3.6-7.8 6.8-15.8 9.3-24 2.6-8.2 5-16.4 7.9-24.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1444.08 729c2.9-2.9 5.5-5.899 8.3-8.799 2.7-3 5.4-6 8-9 2.7-3 5.3-6 7.9-9.1 2.6-3 5.2-6.101 7.7-9.201 5.1-6.2 10.1-12.4 14.8-18.9l7.1-9.6 7.2-9.599M1466.38 632.102l-4.5 6.7-6.7 9.9c-4.5 6.6-8.7 13.399-12.9 20.299-2 3.5-4.1 6.9-6.1 10.4s-4 6.9-5.9 10.5c-1.9 3.5-3.9 7-5.8 10.6-1.8 3.6-3.8 7.101-5.5 10.801"
            />
            <path
                fill="#FCB47F"
                d="M1418.58 711.902c-3.8 9.1-9.9 28-12.1 37.6-2.2 9.6-2.8 19.601-.1 29.101 1.5 5.1 4.4 12.699 4.1 18.299 0 .6.1 1.1.5 1.5l.1.1c.8.9 2.3.8 3-.1 2.1-2.8 3.3-6.399 3.6-9.299l.7-1.3c2.6 1.3 7.3 1.1 11.1-1.1 1.6-.9 2.8-2.501 3.2-4.301.7-2.8-.5-5.699-3-7.299l-2.2-1.4c-.5-.3-1.1-.401-1.6-.301-1.9.4-3.8.7-5.6.5 1.5-5.5 4.2-10.599 7.8-13.699 0 0 .4-.801.6-.301.6 2.8 1.3 5.601 3 7.901 2.1 2.8 6.6 4.5 9.9 3l.8-1.5c-3.2-2.9-4.4-7.3-4.3-11.5 0-4.2.9-8.301 1.9-12.301 1.3-5.3 2.1-14.499 1.1-18.699"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1424.78 752.203c-5.1 5.4-8 12.5-8.1 19.7-.1 4.6 1 9.2 1.1 13.8.1 4.6-.8 9.6-4.2 12.9M1410.68 794.803c-.1-5.6-2.7-10.8-4.3-16.2-2.7-9.5-2.2-19.601.1-29.101 2.2-9.6 5.1-20 8.9-29.1M1441.38 732.902c.7 5.1-.8 8.701-1.5 12.701-.8 4.1-1.9 8.099-1.9 12.299 0 4.2 1.1 8.5 4.3 11.5M1440.88 771.103c-3.2 1.5-7.3-.4-9.3-3.1-2-2.7-2.7-6.2-3.4-9.6-.7-3.4-1.7-6.8-4.3-9.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1420.48 771.902c1.3-4.4 4-8.4 7.5-11.5M1429.88 786.102c-3.3 2.3-8.3 3.2-11.5 1.6M1431.58 779.203c-3.1 2.5-10.8 3.1-14.3 1.1M1426.68 773.202c-3.7 1.2-6.4 1.4-10-.1"
            />
            <path
                fill="#FCB47F"
                d="M1547.38 1134.9c.5-7.7.9-15.4 1.1-23.1.1-3.8.3-7.7.4-11.5l.2-11.5c.2-7.7.2-15.3.6-23 .3-7.7 1-15.5 1-23.1.2-15.3-3.2-30-7.1-44.498-2.1-7.3-4.5-14.5-7.4-21.6-.7-1.8-1.5-3.5-2.3-5.3-.8-1.8-1.6-3.5-2.5-5.3-1.7-3.5-3.6-7-5.9-10.4l-6.9-10.6c-7.9-12.1-24.1-15.4-36.1-7.5-9.5 6.2-13.6 17.7-11 28.1l10.9 43.798 5.4 21.9 5.6 21.9c3.7 14.6 7.4 29.2 11.2 43.8l11.4 43.7c2.2 8.5 10.9 13.6 19.4 11.4 6.8-1.8 11.4-7.7 11.9-14.3l.1-2.9Z"
            />
            <path
                fill="#FCB47F"
                d="M1527.08 964.701c5.1-15.1 9.8-30.201 14.7-45.301l7.2-22.699 3.6-11.301 1.8-5.699 1.7-5.7c4.7-15.1 8.4-30.4 12-45.7 1.8-7.7 3.5-15.3 5-23l1.2-5.8 1-5.8c.7-3.9 1.4-7.7 2-11.6 4-27.8-15.2-53.601-43-57.701-26-3.8-50.2 12.801-56.6 37.701l-1.2 4.9c-1 3.8-1.8 7.6-2.6 11.5l-1.1 5.8-1 5.8c-.7 3.9-1.2 7.7-1.8 11.6-.6 3.9-1.1 7.799-1.5 11.699-1.9 15.6-3.4 31.201-4 46.901-.3 7.9-.2 15.8 0 23.8l.4 11.9c.1 4 .4 8 .6 12 .9 16 2.4 32 5.3 48.3l.8 4.3c2.9 15.6 17.9 26 33.5 23.1 10.6-1.8 18.8-9.4 22-19Z"
            />
            <path
                fill="url(#d)"
                d="M1527.08 964.701c5.1-15.1 9.8-30.201 14.7-45.301l7.2-22.699 3.6-11.301 1.8-5.699 1.7-5.7c4.7-15.1 8.4-30.4 12-45.7 1.8-7.7 3.5-15.3 5-23l1.2-5.8 1-5.8c.7-3.9 1.4-7.7 2-11.6 4-27.8-15.2-53.601-43-57.701-26-3.8-50.2 12.801-56.6 37.701l-1.2 4.9c-1 3.8-1.8 7.6-2.6 11.5l-1.1 5.8-1 5.8c-.7 3.9-1.2 7.7-1.8 11.6-.6 3.9-1.1 7.799-1.5 11.699-1.9 15.6-3.4 31.201-4 46.901-.3 7.9-.2 15.8 0 23.8l.4 11.9c.1 4 .4 8 .6 12 .9 16 2.4 32 5.3 48.3l.8 4.3c2.9 15.6 17.9 26 33.5 23.1 10.6-1.8 18.8-9.4 22-19Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1547.18 1137.8.2-2.9c.5-7.7.9-15.4 1.1-23.1.1-3.8.3-7.7.4-11.5l.2-11.5c.2-7.7.2-15.3.6-23 .3-7.7 1-15.5 1-23.1.2-15.3-3.2-30-7.1-44.5-2.1-7.3-4.5-14.5-7.4-21.6-.7-1.8-1.5-3.5-2.3-5.3-.8-1.8-1.6-3.5-2.5-5.3M1471.38 965.602l10.9 43.798 5.39 21.9 5.61 21.9c3.7 14.6 7.39 29.2 11.19 43.8l11.41 43.7M1482.58 749.898s-3.3 7.401-6.1 17.201c-1.1 3.8-1.8 7.6-2.6 11.5l-1.1 5.799-1 5.801c-.7 3.9-1.2 7.7-1.8 11.6-.6 3.9-1.1 7.8-1.5 11.7-1.9 15.6-3.4 31.199-4 46.899-.3 7.9-.2 15.801 0 23.801l.4 11.9c.1 4 .4 8 .6 12 .9 16 3 41.6 6 57.6M1527.08 964.702c5.1-15.1 9.8-30.201 14.7-45.301l7.2-22.699 3.6-11.301 1.8-5.699 1.7-5.701c4.7-15.1 8.4-30.399 12-45.699 1.8-7.7 3.5-15.3 5-23l1.2-5.801 1-5.799c.7-3.9 1.4-7.7 2-11.6M1491.18 960.5h-15.4"
            />
            <path
                fill="#FCB47F"
                d="M1548.08 1132.3c.4 4.3 4.6 20.4 7.5 30.7 1.4 5-1.8 10.1-6.8 11.2l-19.9 4.2c-4.2.9-8.3 2.4-12.1 4.5-4 2.2-10.5 5.3-19.5 7.2-4 .9-19 1.8-26.2 2.2-2.2.1-4.1-1.5-4.4-3.7-.2-1.6.6-3.2 1.9-4 2.9-1.8 7.9-4.9 12-7.2 18.5-10.4 35.7-38.5 35.7-38.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1548.08 1132.3c.4 4.3 4.6 20.4 7.5 30.7 1.4 5-1.8 10.1-6.8 11.2l-19.9 4.2c-4.2.9-8.3 2.4-12.1 4.5-4 2.2-10.5 5.3-19.5 7.2-4 .9-19 1.8-26.2 2.2-2.2.1-4.1-1.5-4.4-3.7-.2-1.6.6-3.2 1.9-4 2.9-1.8 7.9-4.9 12-7.2 18.5-10.4 35.7-38.5 35.7-38.5"
            />
            <path
                fill="url(#e)"
                d="M1522.88 628.504c3.8-7.3 7.5-14.8 11.2-22.9 3.7-8 6.9-16.1 9.4-24.1.8-2.5 1.6-4.9 2.3-7.4 1.8-5.6 3.6-11.4 5.5-17.1 2.8-8.1 2.4-16.8-1.3-24.5-3.7-7.7-10.2-13.5-18.2-16.3-3.4-1.2-7-1.6-10.6-1.8-14.3-1-25.7 8.6-30.2 21.4l-.1.1c-2 5.6-4.1 11.3-6.3 16.8-.9 2.4-1.9 4.8-2.8 7.3-3 7.8-5.6 16.1-7.6 24.7-2.2 8.9-4.1 17.2-5.6 25.4l-.4 2.1 54.3 17.1.4-.8Z"
            />
            <path
                fill="url(#f)"
                d="m1539.58 500.402-2.7 1c-13.1 4.7-23.8 10.301-32.8 17.301-.8.6-1.1 1.399-1.1 2.299.3 4.6 12.2 12.201 14.6 13.701l.9.5 21.1-34.801Z"
            />
            <path
                fill="#FCB47F"
                d="M1522.18 400.504c-4.4 10.2-7.6 24.2-7.6 35.3 0 11.1 1.9 22.2 4.7 32.9 1.6 6.1 2.7 12.9 6.2 18 2.5 3.7 7.8 5.2 12.3 4.8.7-.1 1.3-.1 2-.2-.4 1.3-.8 11.6-.8 12.4l8.6 6.2 46.7-18-2.6-11.6-.4-13s1.1-8.5 9.1-24c10.1-19.5-3.1-37.8-3.1-37.8s-2.8-11.1-18.5-18c-22.3-9.7-40.9-2.7-56.6 13Z"
            />
            <path
                fill="url(#g)"
                d="M1515.38 424.703c2-.2 4-.4 6-.7 2-.2 4.1-.4 5.7.8 1.2.9 1.8 2.4 1.9 3.8.1 1.5.4 3 .1 4.4-2.2 12.9-1.5 21.9.9 24.5.5.6 1 1.3 1.1 2 .1.8-.1 1.5-.2 2.3-.9 4.2-1.8 8.5-2.7 12.7-.5 2.3-.8 4.7-1.1 7-.4 3.9.6 7.3 4.6 9.4-6.6-1.3-13.7-7.7-15.2-10.5-9.5-18 .8-37.1-1.1-55.7Z"
            />
            <path
                fill="url(#h)"
                d="M1539.08 502.103c.2-3.7.4-7.4.5-11.1 14.5 1.2 30.2-11.6 36.6-18.4.9-1-2.2 13.8-5.3 24.1-7.3 3.3-22.8 8.7-31.8 5.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1576.18 471.803c-9.3 11.5-23.7 18.3-38.4 19.7-5.6.6-13.7-1.9-18.2-6.6-7.2-7.7-9.7-22.7-6.1-37 1.2-4.9 2.2-7.5 2.1-11.3 0-2.9-.8-6.7-.4-9.8 1.1-9 1.3-18.7 8.9-27.4 25.7-29.5 60.1-12.6 69-1.5 8.2 10.2 12.1 23.8 9.7 36.6-1.6 8.7-5.8 16.7-9.5 24.7-3.9 8.6-1.8 21.1.7 30"
            />
            <path
                fill="#FCB47F"
                d="M1528.58 430.004c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1528.58 430.004c-.2 5.9-2.5 11.6-5.7 16.5-1 1.5-2.1 3-2.2 4.8-.1 1.8 1 3.7 2.6 4.6 2.5 1.3 5 1.9 6.9 2.2"
            />
            <path
                stroke="url(#i)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1514.08 426.502c2.5-1.2 6.2-1.8 8.8-2.1"
            />
            <path
                stroke="url(#j)"
                strokeLinecap="round"
                strokeMiterlimit={10}
                strokeWidth={5}
                d="M1536.38 424.402c6.4 0 13.1.7 17.3 2.5"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1519.48 441.702c-.77 0-1.4-1.03-1.4-2.301 0-1.27.63-2.299 1.4-2.299.78 0 1.4 1.029 1.4 2.299 0 1.271-.62 2.301-1.4 2.301ZM1545.08 441.702c-.77 0-1.4-1.03-1.4-2.301 0-1.27.63-2.299 1.4-2.299.77 0 1.4 1.029 1.4 2.299 0 1.271-.63 2.301-1.4 2.301Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1524.18 465.803c7.6 1.2 15.4.499 22.7-1.901M1577.28 438.802c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <path
                fill="#FCB47F"
                d="M1634.58 721.7c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
            />
            <path
                fill="url(#k)"
                d="M1634.58 721.7c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-22.2c.4-15.6-5.9-30.1-18.1-39.9-16.3-13.1-31.8-20.5-49.9-28.2-32.2 15.6-42.1 16.4-56 13.8-2.1 1.4-4.3 2.8-6.4 4.4-26 19-46 59.7-52.2 82.2-3.2 11.7-2.8 23.8 8.2 29.8-14 28-25 49-14 103"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1490.68 560.301c-5.7 12.2-8.9 19.999-11.3 28.599-3.2 11.7-2.8 23.801 8.2 29.801-14 28-25 49-14 103M1634.58 721.7c.6-9.7 9-42.3 16.1-68.4 6.6-24.3 10.3-49.3 10.9-74.5l.5-19.4c.8-24-9.7-36.9-21.7-45.6-12.4-9-23-15.5-41.3-23.3"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1487.38 618.9c3.6-6.2 7.7-12.9 11.1-16.9M1596.58 620.202c-31.1 3.8-56.5-1.2-71.7-22.1M1496.48 697.7c-3.6-1.3-7.1-1.4-10.3 0"
            />
            <path
                fill="#FCB47F"
                d="M1634.68 720.5c12.6 21.7 20.9 32.3 16.6 69-3.3 28.2-89 13.6-105.3 18.3-23.7 9.5-39.1-42.1-39.1-42.1s-24.6-11.7-33.3-45.2"
            />
            <path
                fill="url(#l)"
                d="M1634.68 720.5c12.6 21.7 20.9 32.3 16.6 69-3.3 28.2-89 13.6-105.3 18.3-23.7 9.5-39.1-42.1-39.1-42.1s-24.6-11.7-33.3-45.2"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1634.68 720.5c12.6 21.7 20.9 32.3 16.6 69-3.3 28.2-89 13.6-105.3 18.3-23.7 9.5-39.1-42.1-39.1-42.1s-24.6-11.7-33.3-45.2"
            />
            <path
                fill="#FCB47F"
                d="M1608.38 1151.5c-.5 4.3.3 20.9 1 31.6.3 5.2-3.8 9.5-9 9.5h-57.6c-2.5 0-4.4-2-4.4-4.4 0-1.6.9-3.1 2.3-3.9 3.8-2 11.2-6.1 15-8.6 8.5-5.7 20.3-24.2 20.3-24.2"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1608.38 1151.5c-.5 4.3.3 20.9 1 31.6.3 5.2-3.8 9.5-9 9.5h-57.6c-2.5 0-4.4-2-4.4-4.4 0-1.6.9-3.1 2.3-3.9 3.8-2 11.2-6.1 15-8.6 8.5-5.7 20.3-24.2 20.3-24.2"
            />
            <path
                fill="#FCB47F"
                d="M1608.28 1151.5c1.9-8 3.7-15.9 5.3-23.9.8-4 1.7-8 2.4-11.9l2.3-11.9c1.5-8 2.9-15.9 4.7-23.9 1.7-8 3.8-15.9 5.1-23.9 3-15.9 2.3-31.8 1.1-47.7-.7-8-1.8-15.896-3.3-23.896-.4-2-.8-4-1.3-6s-.9-4-1.5-6c-1.1-4-2.3-8-3.9-11.9l-4.4-11.2c-5.3-13.5-20.6-20.1-34-14.7-10.8 4.3-17.2 14.9-16.5 25.9l2.7 47.696 1.4 23.9 1.5 23.9c1 15.9 2 31.8 3.1 47.7l3.3 47.7c.6 8.8 8.2 15.4 17 14.8 7.1-.5 12.8-5.6 14.4-12.2l.6-2.5Z"
            />
            <path
                fill="#FCB47F"
                d="M1620.68 961.803c3.5-15.4 6.7-30.8 9.9-46.1l4.9-23.1 2.4-11.5 1.2-5.8 1.1-5.8c3.1-15.4 5.2-30.8 7.3-46.3 1-7.7 1.9-15.4 2.6-23.2l.6-5.8.4-5.8c.3-3.9.6-7.7.8-11.6 1.2-28.1-20.6-51.8-48.7-53-26.2-1.1-48.6 17.8-52.5 43.2l-.7 5.1c-.6 3.9-1 7.7-1.4 11.6l-.5 5.8-.4 5.8c-.3 3.9-.4 7.7-.6 11.6-.2 3.9-.3 7.8-.3 11.6-.3 15.5-.2 31.1.9 46.6.5 7.8 1.4 15.6 2.4 23.4l1.6 11.7 1.8 11.7c2.6 15.6 5.6 31.3 10.3 47.1l1.3 4.3c4.5 15.3 20.5 24 35.8 19.5 10.2-2.8 17.6-11.2 19.8-21Z"
            />
            <path
                fill="url(#m)"
                d="M1620.68 961.803c3.5-15.4 6.7-30.8 9.9-46.1l4.9-23.1 2.4-11.5 1.2-5.8 1.1-5.8c3.1-15.4 5.2-30.8 7.3-46.3 1-7.7 1.9-15.4 2.6-23.2l.6-5.8.4-5.8c.3-3.9.6-7.7.8-11.6 1.2-28.1-20.6-51.8-48.7-53-26.2-1.1-48.6 17.8-52.5 43.2l-.7 5.1c-.6 3.9-1 7.7-1.4 11.6l-.5 5.8-.4 5.8c-.3 3.9-.4 7.7-.6 11.6-.2 3.9-.3 7.8-.3 11.6-.3 15.5-.2 31.1.9 46.6.5 7.8 1.4 15.6 2.4 23.4l1.6 11.7 1.8 11.7c2.6 15.6 5.6 31.3 10.3 47.1l1.3 4.3c4.5 15.3 20.5 24 35.8 19.5 10.2-2.8 17.6-11.2 19.8-21Z"
                opacity={0.73}
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1607.68 1154.1.6-2.6c1.9-8 3.7-15.9 5.3-23.9.8-4 1.7-8 2.4-11.9l2.3-11.9c1.5-8 2.9-15.9 4.7-23.9 1.7-8 3.8-15.9 5.1-23.9 3-15.9 2.3-31.8 1.1-47.7-.7-8-1.8-15.897-3.3-23.897-.4-2-.8-4-1.3-6s-.9-4-1.5-6c-.5-1.9-1.1-3.8-1.7-5.7M1564.28 960.504l2.7 47.696 1.4 23.9 1.5 23.9c1 15.9 2 31.8 3.1 47.7l3.3 47.7M1550.88 767.102l-.7 5.1c-.6 3.9-1 7.7-1.4 11.6l-.5 5.8-.4 5.799c-.3 3.9-.4 7.7-.6 11.6-.2 3.9-.3 7.801-.3 11.601-.3 15.5-.2 31.1.9 46.6.5 7.8 1.4 15.6 2.4 23.4l1.6 11.7 1.8 11.699c2.6 15.6 5.6 31.301 10.3 47.101M1620.68 961.804c3.5-15.4 6.7-30.8 9.9-46.1l4.9-23.1 2.4-11.5 1.2-5.8 1.1-5.8c3.1-15.4 5.2-30.8 7.3-46.3 1-7.7 5.8-41.4 5.9-45.2M1584.68 960.504h-15.5"
            />
            <path
                fill="#1E1F23"
                d="M1634.18 720.5h-161.9l1.9 11.4c1.8 10.8 1.8 21.7.1 32.5-3.3 20.1-14.6 100-14.6 119.8h137.6"
            />
            <path
                fill="url(#n)"
                d="M1555.48 757.202c8.1-19.9 20.4-36.6 20.4-36.6h60s22.2 26.599 17 71.299l-7.4 100h-99.6c-2-30.1-1.1-108.699 9.6-134.699Z"
            />
            <path
                fill="url(#o)"
                d="M1657.58 786.102c.7 0 1.3-.501 1.3-1.201 2.8-23.2-1.1-43.7-4.9-63.4-4.4-23-8.6-44.599-2.1-68.799 6.5-23.9 10.1-50 10.7-77.5l.5-19.1c.4-15.6-6.5-30.601-18.5-40.201-14.8-11.9-29.3-19.299-46.5-26.699-.2-.1-.4-.1-.5-.1-.2 0-.4.1-.6.2-3 1.6-6.5 4-10.2 6.5-7.8 5.3-16.7 11.299-24.2 13.099-.2 0-.4.101-.5.201l-23.3 18c-2-7.5-1.5-17.8 1.3-24.4.2-.4.1-.901-.1-1.301-.3-.4-.7-.599-1.1-.599-.3 0-.5.099-.7.199-2.4 1.5-4.7 3.101-7 4.801-27.2 19.9-46.6 61.3-52.5 82.8-2.1 7.8-2.4 14.499-.6 19.799 3.3 10.3 2.9 21.1-1 30.6-5.6 13.6-8.3 26.001-8.7 40.301-.3 11 0 95.8 0 105.4 0 .7.6 1.299 1.3 1.299h187.9v.101Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1562.68 509.898-24.6 19-8-19.5-7.3 6.601M1562.68 509.9l9.5 13.1 20.9-22.699M1535.88 532.898c-4.3 4.3-9.6 14.601-10.5 16.601"
            />
            <path
                fill="#FCB47F"
                d="M1600.08 560.5c3.9 7.6 7.3 15.4 11 23.1 3.6 7.7 7.8 15.2 12.4 22.4 4.5 7.3 9.3 14.4 14.3 21.4 5 7 10.3 13.9 16.1 20.5 8.6 9.6 23.3 10.401 32.9 1.901 6.5-5.8 8.9-14.301 7.3-22.201-1.8-8.6-4.3-16.9-7-25.1-2.7-8.2-5.7-16.199-8.8-24.199-3.1-8-6.7-15.701-10.7-23.201-4.1-7.5-8.3-14.9-12.1-22.5-7.7-15.3-26.3-21.4-41.6-13.7-15.3 7.7-21.4 26.3-13.7 41.6h-.1Z"
            />
            <path
                fill="#FCB47F"
                d="m1650.08 633.502-1.5 13.7-1.6 13.699c-1 9.2-1.8 18.301-2.5 27.601-.3 4.6-.6 9.2-.8 13.8-.3 4.6-.5 9.2-.6 13.9-.2 4.6-.4 9.2-.5 13.9-.1 4.6-.2 9.3-.2 13.9.1 8.6 7.2 15.6 15.8 15.5 7.1-.1 13-4.9 14.9-11.4 1.3-4.5 2.3-9 3.5-13.5 1.1-4.5 2.1-9 3.2-13.5s2.1-9 3-13.5c1-4.5 1.9-9 2.8-13.6 1.8-9.1 3.5-18.1 4.9-27.2l2.1-13.7 2.1-13.701c1.9-12.3-6.5-23.799-18.8-25.699-12.3-1.9-23.8 6.5-25.7 18.8 0 .4 0 .8-.1 1Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1668.27 622.248c6.08 0 11-4.788 11-10.694 0-5.906-4.92-10.695-11-10.695-6.07 0-11 4.789-11 10.695 0 5.906 4.93 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M1668.27 613.86c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309c-1.27 0-2.3 1.034-2.3 2.309a2.305 2.305 0 0 0 2.3 2.309Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m1599.98 560.301.1.099c3.9 7.6 7.3 15.4 11 23.1 3.6 7.7 7.8 15.2 12.4 22.4 4.5 7.3 9.3 14.401 14.3 21.401 2.5 3.5 5.1 7 7.8 10.4M1693.98 627.499c-1.8-8.6-4.3-16.9-7-25.1-2.7-8.2-5.7-16.2-8.8-24.2-3.1-8-18.6-37.4-22.5-45"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1673.08 748.099c1.3-4.5 2.3-9 3.5-13.5 1.1-4.5 2.1-9 3.2-13.5s2.1-9 3-13.5c1-4.5 1.9-9 2.8-13.6 1.8-9.1 3.5-18.1 4.9-27.2l2.1-13.7 2.1-13.701c.6-3.8.5-8.6-.7-12M1649.28 640.598l-.8 6.7-1.6 13.7c-1 9.2-1.8 18.3-2.5 27.6-.3 4.6-.6 9.199-.8 13.799-.3 4.6-.5 9.201-.6 13.901-.2 4.6-.4 9.2-.5 13.9-.1 4.6-.2 9.3-.2 13.9"
            />
            <path
                fill="#FCB47F"
                d="M1672.18 752.599c1 6.9 2.2 13.8 2.9 20.7 1.4 13.6-.2 27.8-10.5 28.2l-.5.7c-1.9 2.2-4.7 3.8-8.9 3.9l-1.6-.3c-2.2 2.9-4.9 4.8-7.9 4.9l-.6.199c-.6 4-1 8.101-1 11.201 0 1-.7 1.9-1.7 2.1-1.2.3-2.5-.4-2.8-1.7-1.3-6-1.7-14.801-1.6-20.101 0-3 .3-6 .6-9l-.2.701c-.7 2.3-3 3.8-5.4 3.4l-1.9-.3c3.1-3.4 3.9-8.301 3.8-12.801-.1-4.6-1-9.099-1-13.599.1-8.4 2.3-17.801 8.2-23.901"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1642.78 746.398c-5.9 6.1-8.7 16-8.8 24.5 0 4.6.9 9.101 1 13.601.1 4.6-.8 9.4-3.8 12.8"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1638.08 770.199c1.6 5.2 1.7 10.7 1.3 16.1-.4 5.4-1.2 10.8-1.3 16.2-.1 5.3.3 14.2 1.6 20.1.3 1.2 1.5 2 2.8 1.7 1-.3 1.7-1.1 1.7-2.1-.1-6.1 1.7-15.8 2.8-21.1 1-4.5 1.9-9 2.9-13.5"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1659.28 787.598c.3 10-5.5 22.7-13.5 23.1M1667.98 787.898c.3 2.6.6 17.701-12.7 18.201"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1664.68 801.498c10.3-.4 11.9-14.6 10.5-28.2-.7-6.9-1.9-13.8-2.9-20.7"
            />
            <path
                fill="#EF0025"
                d="M1470.78 1193.4h.3c8.1-.5 22.4-1.4 26.4-2.2 9.1-2 15.8-5.1 19.7-7.3 3.6-2 7.6-3.5 11.8-4.4l19.9-4.2c2.7-.6 5-2.2 6.5-4.6 1.4-2.4 1.8-5.2 1.1-7.8-1.2-4.4-2.3-8.6-3.3-12.4-.1-.5-.6-.8-1.1-.7-4 .7-7.8 2.2-11.5 3.6-.7.2-1.3.5-2 .7-5.9 2.2-12.2 4-18 2.4-2.3-.6-4.3-1.7-6.5-2.8-1.1-.5-2.1-1.1-3.2-1.6-1-.5-2.1-.9-3.2-1.2-.4-.1-.8 0-1.1.4-6.5 8.5-16.3 19.7-26.5 25.5-4.2 2.4-9.4 5.5-12.1 7.2-1.7 1-2.6 3-2.4 5 .4 2.5 2.6 4.4 5.2 4.4Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1467.18 1192c.9.9 2.2 1.4 3.5 1.4h.3c8.1-.5 22.4-1.4 26.4-2.2 9.1-2 15.8-5.1 19.7-7.3 3.6-2 7.6-3.5 11.8-4.4l19.9-4.2c2.7-.6 5-2.2 6.5-4.6"
            />
            <path
                fill="url(#p)"
                d="M1542.68 1193.6h57.6c2.8 0 5.4-1.1 7.3-3.1 1.9-2 2.9-4.7 2.7-7.4-.2-3.3-.4-7.1-.6-11 0-.3-.2-.6-.4-.8-.2-.2-.5-.2-.8-.2-4.4 1.1-9.1.1-12.6-2.6-1.4-1-2.5-2.3-3.7-3.6-1-1.1-2-2.2-3.1-3.2-4.7-4.1-11.6-5.8-18-4.5-.2.1-.5.2-.6.4-4.3 6-10.5 13.9-15.4 17.2-3.7 2.5-10.9 6.4-14.9 8.5-1.8.9-2.9 2.8-2.9 4.8-.1 3.1 2.4 5.5 5.4 5.5Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1538.48 1191.6c1 1.2 2.5 2 4.2 2h57.6c2.8 0 5.4-1.1 7.3-3.1"
            />
            <path
                fill="#7A5430"
                d="M1582.98 467.103c-.5-2-.6-4-.3-5.8 5.4-3.8 9.3-9.6 10.7-16 .6-2.9.8-6.1-1-8.5-.5-.6-1.2-1.1-1.9-1.5-8.1-3.8-8.8.7-12.7 2.7-1.7-.4-4-2.6-4.7-4.2-2.5-6.2-3.2-13.2-1.8-19.7-3.3 1.1-7.6-.7-9.1-3.8-.9-1.7-1-3.8-1.8-5.5-1.1-2.3-3.6-4-6.2-4.2-2.7-.2-5.2 1.1-7.5 2.6s-4.3 3.3-6.8 4.4c-2.5 1.1-5.6 1.2-7.7-.4-.8.7-.4.8-1.2 1.6-3.5 3.6-9.3 4.7-13.8 2.6-.8 2.2-1.5 4.4-2 6.7-3.8 1-7.8-.9-9.9-4.1-2.1-3.3-2.6-7.3-2.4-11.2.4-7.4 3.5-15.1 9.8-19.1 1.2-.8 2.7-1.5 4.1-1.2 1.4.3 2.8 1.6 2.5 3 3.6-8.6 12.7-14.3 22-14.4 6.6-.1 5.5 5 5.5 5s1.7-.3 2.5-.6c11-4.8 23.9-5.2 35.2-1.1 5.1 1.8 10 4.6 13.1 8.9 3.1 4.3 4.3 10.4 1.9 15.2 3.7.7 8.3 2 11.6 3.9 4.9 2.8 8.1 8.3 8.2 13.9.1 5.6-2.9 11.2-7.6 14.2 6.4 2.2 10.3 15-2.8 22-6.7 3.6-13.5 8.9-18.3 15.8-2 3.2-6.7 2.4-7.6-1.2Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1503.08 402.803c.4-7.4 3.5-15.1 9.8-19.1 1.2-.8 2.7-1.5 4.1-1.2 1.4.3 2.8 1.6 2.5 3 3.6-8.6 12.7-14.3 22-14.4 6.6-.1 5.5 5 5.5 5s1.7-.3 2.5-.6c11-4.8 23.9-5.2 35.2-1.1 5.1 1.8 10 4.6 13.1 8.9 3.1 4.3 4.3 10.4 1.9 15.2 3.7.7 8.3 2 11.6 3.9 4.9 2.8 8.1 8.3 8.2 13.9.1 5.6-2.9 11.2-7.6 14.2 6.4 2.2 10.3 15-2.8 22"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1577.08 438.904c1.9-1.6 3.8-3.3 6.2-4.2 2.3-.9 5.2-.9 7.1.7 2.1 1.7 2.6 4.8 2.5 7.6-.5 8.4-5.9 16.4-13.5 19.9"
            />
            <path
                fill="url(#q)"
                d="m1687.68 601.3-.3-.8c-2.4-7.3-5.2-15-8.3-22.6-3.2-8.2-6.8-16-10.8-23.3-1.2-2.3-2.5-4.5-3.7-6.8-2.8-5.1-5.7-10.4-8.4-15.7-5.5-10.9-16.4-17.6-28.6-17.6-5 0-9.9 1.2-14.4 3.4-7.6 3.8-13.3 10.4-16 18.5-2.7 8.1-2 16.8 1.8 24.4l.1.1c2.7 5.3 5.2 10.8 7.7 16.1 1.1 2.3 2.2 4.7 3.3 7 3.5 7.5 7.7 15.1 12.4 22.5 4.5 7.1 9 14 13.6 20.4l.5.7 51.1-26.3Z"
            />
            <path
                fill="#FCB47F"
                d="M854.088 556.795c-8.491-8.307-18.696-15.185-30.908-21.252l15.604 34.205s19.387-8.958 15.304-12.953Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2.038}
                d="M823.18 535.543a132.211 132.211 0 0 1 30.909 21.252"
            />
            <path
                fill="#FCB47F"
                d="M864.467 570.632c2.246 6.819 4.882 13.508 7.35 20.254a151.125 151.125 0 0 1 5.972 20.711c1.575 7.043 2.951 14.151 4.053 21.351a190.494 190.494 0 0 1 2.208 21.962 19.291 19.291 0 0 1-5.198 13.822 19.29 19.29 0 0 1-13.448 6.099 19.488 19.488 0 0 1-16.465-8.25 190.333 190.333 0 0 1-11.379-18.915c-3.428-6.426-6.581-12.944-9.535-19.527a151.018 151.018 0 0 1-7.615-20.166c-2.061-6.881-3.954-13.817-6.237-20.624a26.495 26.495 0 0 1 16.697-33.551 26.495 26.495 0 0 1 33.551 16.697l.046.137Z"
            />
            <path
                fill="#FCB47F"
                d="m882.691 651.539 5.321 10.656 5.451 10.593a234.158 234.158 0 0 1 9.695 21.769c1.418 3.723 2.93 7.402 4.25 11.173l2.038 5.628c.675 1.878 1.29 3.785 1.937 5.677 2.543 7.588 4.936 15.248 6.825 23.151a12.281 12.281 0 0 1-9.089 14.801 12.299 12.299 0 0 1-12.53-4.379c-5.006-6.401-9.508-13.044-13.861-19.76-1.076-1.684-2.186-3.353-3.234-5.051l-3.134-5.1c-2.128-3.381-4.064-6.855-6.093-10.284a233.845 233.845 0 0 1-10.991-21.144l-4.893-10.862-5.022-10.8a18.503 18.503 0 0 1 8.977-24.578 18.527 18.527 0 0 1 24.353 8.51Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M848.939 666.581a190.333 190.333 0 0 1-11.379-18.915c-3.428-6.426-6.581-12.944-9.535-19.527a151.018 151.018 0 0 1-7.615-20.166c-2.061-6.881-3.954-13.817-6.237-20.624a26.495 26.495 0 0 1 16.697-33.551 26.495 26.495 0 0 1 33.551 16.697l.046.137c2.246 6.819 4.882 13.508 7.35 20.254a151.125 151.125 0 0 1 5.972 20.711c1.575 7.043 2.951 14.15 4.053 21.35.844 5.645 1.568 11.33 1.962 17.125M896.591 750.607c-5.006-6.401-9.508-13.044-13.861-19.76-1.076-1.685-2.186-3.353-3.234-5.051l-3.134-5.1c-2.127-3.382-4.064-6.855-6.093-10.284a233.845 233.845 0 0 1-10.991-21.144l-4.893-10.863-5.022-10.8M884.688 654.355l3.326 7.84 5.451 10.594a234.04 234.04 0 0 1 9.695 21.769c1.418 3.723 2.93 7.401 4.25 11.172l2.038 5.628c.675 1.878 1.29 3.786 1.937 5.677 2.543 7.588 4.936 15.249 6.825 23.152"
            />
            <path
                fill="#FCB47F"
                d="M928.635 771.54c-1.887-8.125-5.932-22.708-9.172-30.436-11.058-12.292-20.127-5.641-20.168 11.327-.846 3.619-.216 11.397.921 15.931a45.184 45.184 0 0 1 1.643 10.462c.035 3.534-.973 7.263-3.668 9.751l.7 1.294c2.736 1.253 6.626-.208 8.38-2.571a16.964 16.964 0 0 0 2.579-6.714c.102-.472.474.223.474.223 3.022 2.646 5.284 6.966 6.6 11.619a14.7 14.7 0 0 1-4.725-.45 1.819 1.819 0 0 0-1.368.216l-1.86 1.152a5.714 5.714 0 0 0-2.552 6.17 5.656 5.656 0 0 0 2.716 3.648c3.234 1.816 7.259 2.032 9.427.919l.559 1.128a16.393 16.393 0 0 0 3.046 7.928 1.674 1.674 0 0 0 1.958.53c.225-.092.427-.232.592-.411l.081-.086a1.732 1.732 0 0 0 .436-1.298c-.316-4.82 2.188-11.215 3.444-15.58 2.319-8.06 1.844-16.627-.043-24.752Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M913.062 773.891a25.28 25.28 0 0 1 6.902 16.776c.064 3.933-.817 7.837-.928 11.77-.111 3.934.684 8.129 3.544 10.978M925.056 810.102c.073-4.75 2.3-9.217 3.622-13.812 2.32-8.06 1.845-16.627-.042-24.752a158.733 158.733 0 0 0-7.558-24.765M898.909 757.531c-.582 4.324.646 7.357 1.307 10.831.66 3.475 1.608 6.928 1.643 10.462.035 3.534-.973 7.264-3.668 9.752M899.336 789.966c2.735 1.254 6.181-.307 7.935-2.67 1.754-2.362 2.269-5.309 2.882-8.125.612-2.817 1.457-5.764 3.65-7.78"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M916.721 790.624a21.442 21.442 0 0 0-6.397-9.815M908.754 802.746c2.842 1.924 7.092 2.727 9.807 1.332M907.285 796.832c2.663 2.156 9.151 2.645 12.18.917M911.469 791.796a10.91 10.91 0 0 0 8.489-.058"
            />
            <path
                fill="url(#r)"
                d="M854.788 556.083a109.663 109.663 0 0 0-20.026-15.329 31.051 31.051 0 0 1 2.394 13.895 66.038 66.038 0 0 1-1.451 10.762l2.169 4.754a.986.986 0 0 0 .562.523 1.005 1.005 0 0 0 .768-.03c2.597-1.201 15.6-7.426 16.405-12.093a2.713 2.713 0 0 0-.821-2.482Z"
            />
            <path
                fill="url(#s)"
                d="M821.439 612.228a1 1 0 0 1-.951-.693l-.208-.639c-.284-.876-.569-1.753-.831-2.637a910.621 910.621 0 0 1-1.83-6.224c-1.379-4.732-2.806-9.626-4.396-14.366a27.324 27.324 0 0 1 1.454-20.998 27.32 27.32 0 0 1 15.874-13.82 27.323 27.323 0 0 1 20.996 1.454 27.3 27.3 0 0 1 8.5 6.553 27.296 27.296 0 0 1 5.32 9.322l.046.136.002.005c1.565 4.752 3.352 9.528 5.08 14.148.714 1.907 1.424 3.816 2.129 5.726a1 1 0 0 1-.558 1.269l-50.247 20.689c-.12.049-.249.075-.38.075Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m767.649 526.602-19.771 11.891a61.316 61.316 0 0 0-26.128 31.884 61.316 61.316 0 0 0-.038 41.223l18.824 52.893a36.515 36.515 0 0 1-6.6 36.015c-9.8 11.422-21.71 28.509-23.29 47.32-6.353 75.663 65.726 102.576 131.422 44.511 65.696-58.064 40.338-102.907 28.725-135.091 16.258-6.304 32.485-19.834 12.276-43.134a282.695 282.695 0 0 1-20.843-26.788"
            />
            <path
                fill="#FCB47F"
                d="m767.649 526.602-19.77 11.891a61.317 61.317 0 0 0-26.167 73.107l18.824 52.893a36.515 36.515 0 0 1-6.6 36.015c-9.8 11.422-19.809 28.767-23.29 47.32-10.397 55.41 52.309 115.616 131.422 44.512 65.21-58.609 40.338-102.908 28.725-135.092 16.258-6.304 32.184-19.576 12.276-43.134-19.907-23.557-26.375-32.834-31.852-47.115-9.29-24.221-28.616-30.874-28.616-30.874-9.29 6.968-42.343.43-54.952-9.523ZM818.13 1139.93a651.72 651.72 0 0 1-1.958-23.3c-.255-3.87-.596-7.76-.781-11.62l-.617-11.6c-.44-7.74-.742-15.45-1.423-23.23-.608-7.76-1.575-15.57-1.828-23.28-.771-15.46 2.043-30.4 5.444-45.25a213.464 213.464 0 0 1 6.553-22.071c.628-1.828 1.376-3.638 2.058-5.457.759-1.809 1.47-3.624 2.297-5.423a99.468 99.468 0 0 1 5.47-10.718l6.403-10.762a26.118 26.118 0 0 1 35.804-9.093 26.18 26.18 0 0 1 12.226 27.732l-9.233 44.702-4.628 22.35-4.767 22.33c-3.201 14.88-6.304 29.78-9.585 44.65l-9.765 44.63a15.892 15.892 0 0 1-6.96 9.99 15.921 15.921 0 0 1-11.992 2.15 15.86 15.86 0 0 1-8.468-4.94 15.941 15.941 0 0 1-3.967-8.97l-.283-2.82Z"
            />
            <path
                fill="#FCB47F"
                d="m834.946 966.074-16.139-42.661-4.049-10.663-4.16-10.644c-2.746-7.101-5.796-14.15-8.483-21.26-2.687-7.111-5.126-14.264-7.189-21.482-1.066-3.603-1.971-7.233-2.964-10.849l-1.457-5.428-1.309-5.455c-.861-3.637-1.79-7.264-2.52-10.924-.763-3.655-1.594-7.298-2.23-10.975l-1.018-5.504-.877-5.527a182.828 182.828 0 0 1-1.396-11.118 51.01 51.01 0 0 1 45.914-55.648 51.032 51.032 0 0 1 52.921 33.62l1.7 4.81c1.271 3.567 2.358 7.166 3.349 10.782l1.453 5.43 1.28 5.459c.907 3.63 1.572 7.301 2.322 10.958.767 3.654 1.39 7.333 2.009 11.013 1.234 7.36 2.194 14.766 3.048 22.191.849 7.425 1.446 14.894 1.722 22.418.282 7.522.095 15.125-.237 22.753l-.528 11.447c-.202 3.82-.494 7.655-.736 11.482-1.188 15.345-2.913 30.783-6.487 46.537l-1.267 5.582a27.57 27.57 0 0 1-32.986 20.787 27.84 27.84 0 0 1-19.686-17.131Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m818.413 1142.75-.283-2.82a651.72 651.72 0 0 1-1.958-23.3c-.255-3.87-.596-7.76-.781-11.62l-.617-11.6c-.44-7.74-.742-15.46-1.423-23.23-.608-7.76-1.575-15.57-1.828-23.28-.771-15.46 2.043-30.4 5.444-45.25a213.409 213.409 0 0 1 6.553-22.072c.628-1.828 1.376-3.638 2.058-5.457.759-1.809 1.47-3.624 2.297-5.423a99.56 99.56 0 0 1 5.47-10.718M887.774 965.859l-9.232 44.701-4.628 22.35-4.767 22.33c-3.201 14.88-6.304 29.78-9.585 44.65l-9.765 44.63"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M830.61 954.612c-29.446-77.761-30.074-75.627-35.684-95.248-7.06-23.855-12.388-51.345-13.771-65.78a51.01 51.01 0 0 1 45.914-55.648 51.035 51.035 0 0 1 52.921 33.62c21.799 61.183 16.08 126.627 16.119 127.26-1.369 25.969-8.345 67.143-8.332 67.043"
            />
            <path
                fill="#FCB47F"
                d="M818.093 1138.28c-.35 3.89-4.167 18.32-6.718 27.67a8.091 8.091 0 0 0 .903 6.35 8.185 8.185 0 0 0 2.238 2.4 8.062 8.062 0 0 0 3.003 1.3l24.769 5.23s13.341 10.88 38.22 10.88c5.773 0 10.599-1.6 11.236-4.62.629-2.98-6.743-6.89-11.828-9.16-17.419-7.76-30.119-33.81-30.119-33.81"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M818.093 1138.28c-.35 3.89-4.167 18.32-6.718 27.67a8.091 8.091 0 0 0 .903 6.35 8.185 8.185 0 0 0 2.238 2.4 8.062 8.062 0 0 0 3.003 1.3l24.769 5.23s13.341 10.88 38.22 10.88c5.773 0 10.599-1.6 11.236-4.62.629-2.98-6.743-6.89-11.828-9.16-17.419-7.76-30.119-33.81-30.119-33.81"
            />
            <path
                fill="#EF0025"
                d="m812 1113.38-.06-.93c-3.165-49.25-3.938-57.96-4.192-60.82-.114-1.29-.115-1.32-.181-3.38l-.042-1.32c-1.19-23.83 8.998-64.576 21.848-87.503-2.208-6.138-26.956-74.954-28.192-78.228-11.043-29.224-19.304-69.605-21.02-87.52l-.063-.662 84.105-45.103.517.419a51.95 51.95 0 0 1 16.214 22.887c.314 1.065.677 2.038 1.135 3.271 1.642 4.419 4.257 11.843 9.008 34.303 17.302 81.806 1.483 145.273-25.44 267.826-2.524 11.49-5.149 23.48-7.875 35.98l-.172.78H812Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M859.344 717.801a13.774 13.774 0 0 1 10.285 0"
            />
            <path
                fill="#FCB47F"
                d="M758.248 1151.99a725.58 725.58 0 0 1-6.054-23.92c-.937-3.98-1.961-7.97-2.827-11.96l-2.661-11.97c-1.803-7.98-3.468-15.96-5.513-23.93-1.974-7.98-4.309-15.94-5.924-23.93-3.496-15.96-3.372-32.04-2.655-48.13a238.428 238.428 0 0 1 2.541-24.2c.295-2.019.71-4.042 1.059-6.063.427-2.023.806-4.045 1.301-6.07.926-4.12 2.09-8.184 3.485-12.17l3.977-11.203a26.255 26.255 0 0 1 33.527-15.963 26.312 26.312 0 0 1 17.468 25.382l-1.172 48.267-.597 24.13-.737 24.14c-.515 16.09-.931 32.18-1.526 48.27l-1.709 48.28a15.936 15.936 0 0 1-16.48 15.36 16.001 16.001 0 0 1-9.327-3.44 15.923 15.923 0 0 1-5.479-8.29l-.697-2.59Z"
            />
            <path
                fill="#FCB47F"
                d="m740.643 959.801-10.337-43.166-2.598-10.791-2.711-10.786c-1.78-7.192-3.867-14.373-5.587-21.567-1.719-7.195-3.188-14.399-4.275-21.617-.579-3.608-.994-7.222-1.499-10.832l-.724-5.417-.574-5.423c-.371-3.616-.81-7.229-1.049-10.849-.271-3.619-.611-7.236-.754-10.86l-.279-5.434-.136-5.439a173.55 173.55 0 0 1 .092-10.892 51 51 0 0 1 16.322-35.471 51.012 51.012 0 0 1 36.622-13.539 51.032 51.032 0 0 1 47.876 40.045l1.107 5.128c.787 3.6 1.387 7.207 1.889 10.818l.72 5.417.545 5.424c.417 3.614.589 7.237.848 10.857.275 3.619.404 7.244.53 10.868.246 7.25.216 14.51.077 21.775-.143 7.265-.543 14.539-1.268 21.825-.717 7.286-1.912 14.59-3.253 21.9l-2.042 10.965c-.706 3.657-1.504 7.316-2.252 10.974-3.213 14.639-6.971 29.299-12.604 44.029l-2.097 5.485a27.586 27.586 0 0 1-35.616 15.913 27.849 27.849 0 0 1-16.973-19.34Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m758.945 1154.58-.697-2.59a731.443 731.443 0 0 1-6.054-23.91c-.937-3.99-1.961-7.98-2.827-11.97l-2.661-11.97c-1.803-7.98-3.468-15.96-5.513-23.93-1.974-7.98-4.309-15.94-5.924-23.92-3.496-15.97-3.372-32.05-2.655-48.14a238.439 238.439 0 0 1 2.541-24.199c.295-2.019.71-4.042 1.059-6.063.427-2.023.806-4.044 1.301-6.07.926-4.12 2.09-8.183 3.485-12.17M795.885 961.43l-1.085 44.7-.597 24.13-.737 24.13c-.515 16.1-.932 32.19-1.527 48.28l-1.709 48.28M811.031 777.762c.315 2.63 6.628 22.55 5.716 70.287-.708 35.898-11.664 84.18-21.419 109.693M740.644 959.8c-10.543-44.307-12.146-50.518-15.646-64.743-5.918-23.92-8.973-35.734-12.658-64.855a233.99 233.99 0 0 1-.178-55.647"
            />
            <path
                fill="#FCB47F"
                d="M758.512 1155.59c.46 3.87-.295 18.78-.861 28.46a8.097 8.097 0 0 0 2.194 6.03c.757.8 1.67 1.44 2.684 1.87 1.013.44 2.105.67 3.208.66h43.739c5.773 0 10.551-1.33 10.551-4.41 0-3.05-9.357-5.84-13.975-8.95-7.637-5.14-16.677-23.66-16.677-23.66"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M758.512 1155.59c.46 3.87-.295 18.78-.861 28.46a8.097 8.097 0 0 0 2.194 6.03c.757.8 1.67 1.44 2.684 1.87 1.013.44 2.105.67 3.208.66h43.739c5.773 0 10.551-1.33 10.551-4.41 0-3.05-9.357-5.84-13.975-8.95-7.637-5.14-16.677-23.66-16.677-23.66"
            />
            <path
                fill="url(#t)"
                d="M765.734 1193.62a9.14 9.14 0 0 1-3.609-.74 8.95 8.95 0 0 1-3.013-2.12 8.99 8.99 0 0 1-1.937-3.13 8.982 8.982 0 0 1-.525-3.64c.086-1.47.177-3.06.267-4.72l.069-1.27 1.215.37c1.066.31 2.172.46 3.282.45a19.67 19.67 0 0 0 8.398-2.18c1.861-.91 3.685-2.01 5.448-3.07a60.678 60.678 0 0 1 6.559-3.59 31.13 31.13 0 0 1 12.612-2.66c.75 0 1.509.03 2.256.08l.494.04.272.41c3.454 5.28 6.511 8.84 9.086 10.57a49.334 49.334 0 0 0 5.924 3.13c4.557 2.17 8.493 4.05 8.493 6.65 0 3.44-4.21 5.41-11.551 5.41l-43.74.01Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M821.025 1188.2c0 3.44-4.21 5.41-11.55 5.41h-43.74a8.955 8.955 0 0 1-3.609-.73 9.24 9.24 0 0 1-3.013-2.12"
            />
            <path
                fill="url(#u)"
                d="M880.507 1193.11c-23.625 0-36.847-9.55-38.655-10.95l-24.54-5.18a9.096 9.096 0 0 1-3.38-1.46 9.113 9.113 0 0 1-2.511-2.7 8.961 8.961 0 0 1-1.25-3.46 8.979 8.979 0 0 1 .239-3.67c.662-2.43 1.41-5.2 2.159-8.04l.323-1.22 1.116.6c7.055 3.78 15.323 8 24.195 10.09 3.58.85 7.247 1.29 10.928 1.3 5.146.08 10.247-.97 14.943-3.08l.673-.31.494.55c5.052 5.65 10.127 9.63 15.082 11.84 8.835 3.93 13.007 7.39 12.399 10.27-.705 3.34-5.386 5.42-12.215 5.42Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M892.723 1187.69c-.705 3.34-5.386 5.42-12.215 5.42-23.625 0-36.848-9.55-38.656-10.95l-24.54-5.18a9.084 9.084 0 0 1-3.379-1.46 9.087 9.087 0 0 1-2.511-2.7"
            />
            <path
                fill="url(#v)"
                d="m746.334 1120.02-.18-.78c-10.331-44.6-12.605-53.23-13.46-56.49-.504-1.91-.568-2.15-1.4-6.27-5.526-25.23-2.51-70.11 6.452-96.235-.548-2.364-3.895-17.406-9.169-41.106-4.765-21.418-9.691-43.564-10.135-45.419-5.546-23.19-10.59-65.876-9.04-88.29a86.506 86.506 0 0 0-.363-16.973 69.844 69.844 0 0 1 2.221-27.69l.201-.736h170.127l.422 1.351c-7.086 18.943-24.207 39.708-45.358 56.969-12.012 9.803-18.395 24.311-18.329 39.838a304.056 304.056 0 0 1-1.843 31.782 451.45 451.45 0 0 1-13.627 69.712 147.304 147.304 0 0 0-6.198 37.113c-.829 34.634-3.081 103.994-4.35 142.254l-.032.97h-45.939Z"
            />
            <path
                fill="#FCB47F"
                d="M841.591 463.081c-1.418-4.724 1.21-10.014 1.21-15.21-1.232-9.262-13.9-23.647-24.658-26.985-18.458-5.729-33.305-1.408-44.533 6.257-3.242 2.214-26.645 23.439-.737 66.892 11.087 17.265-5.224 32.566-5.224 32.566 4.684 6.464 39.362 33.252 53.233 28.629 13.871-4.624-.783-20.395-3.044-27.094-1.49-4.417-2.368-8.205-1.965-11.513 6.307 1.627 10.495 1.904 14.416-.357 13.588-6.348 14.366-23.852 14.136-33.358-.245-10.12-1.417-15.103-2.834-19.827Z"
            />
            <path
                fill="url(#w)"
                d="M822.598 536.127c-4.458-6.156-6.567-10.99-6.6-19.61-13.023-2.157-20.074-8.959-26.793-16.341-1.216-1.338 6.675 29.123 7.256 30.835.582 1.712 1.39 2.179 2.341 3.716 4.314 6.967 24.454 2.308 23.796 1.4Z"
            />
            <path
                fill="url(#x)"
                d="M841.028 454.559c-1.655-2.074-9.799-2.899-10.264 4.216-.602 9.199 5.204 23.632 2.882 26.203-.768.85-2.717 2.542-2.819 3.683-.364 4.074.176 8.245.427 12.334.292 4.742 1.341 13.154-3.117 15.984.436-.582 6.437-3.119 7.021-3.551 10.709-7.906 10.294-30.717 8.125-44.775-.402-2.607-2.001-5.222-2.353-7.836-.34-2.524.987-5.145.098-6.258Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M789.621 500.038c8.185 14.378 32.273 20.998 42.229 15.605 8.718-4.723 12.777-14.242 12.301-33.178-.238-9.511-1.145-14.657-2.563-19.381-1.417-4.724 1.385-9.7.932-14.877-.997-11.391-14.522-29.06-36.86-30.636-17.699-1.249-30.465 6.781-35.541 13.107-7.682 9.575-11.398 22.366-9.156 34.435 1.52 8.182 5.3 15.746 8.903 23.247 4.495 9.36 13.926 19.957-1.562 37.754"
            />
            <path
                fill="#FCB47F"
                d="M830.762 458.777a32.102 32.102 0 0 0 5.736 16.511c.979 1.47 2.112 2.987 2.162 4.751a5.667 5.667 0 0 1-2.588 4.555 12.548 12.548 0 0 1-3.285 1.583"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M830.762 458.777a32.102 32.102 0 0 0 5.736 16.511c.979 1.47 2.112 2.987 2.162 4.751a5.667 5.667 0 0 1-2.588 4.555 12.548 12.548 0 0 1-3.285 1.583M804.395 456.976a19.21 19.21 0 0 1 15.078-4.251M834.5 453.438a15.786 15.786 0 0 1 9.228 2.277M818.141 494.141a24.218 24.218 0 0 0 13.894 2.516"
            />
            <path
                fill="#111011"
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M814.305 467.931c.778 0 1.41-1.085 1.41-2.423s-.632-2.422-1.41-2.422c-.779 0-1.41 1.084-1.41 2.422s.631 2.423 1.41 2.423ZM837.906 467.931c.779 0 1.41-1.085 1.41-2.423s-.631-2.422-1.41-2.422c-.779 0-1.41 1.084-1.41 2.422s.631 2.423 1.41 2.423Z"
            />
            <path
                fill="#7A5430"
                d="M749.922 436.923c7.52-16.811 20.792-31.853 47.557-33.622 28.544-1.888 100.57-.645 45.984 62.42a268.76 268.76 0 0 0-.348-1.102c-.199-.627-.391-1.234-.569-1.827-.721-2.403-.227-4.96.296-7.668.553-2.293.78-4.652.674-7.008-.504-5.764-4.081-12.981-10.215-19.07l-.891 1.25a.503.503 0 0 0-.058.055c-10.265 14.7-25.195 33.805-45.099 42.396-.593.256-.096 1.036-.228.963-.606-.338-1.011-7.081-6.485-11.339-10.52-8.182-21.496 8.962-7.923 22.912a18.598 18.598 0 0 0 8.321 5.005 53.515 53.515 0 0 1-1.405 11.415 2.508 2.508 0 0 1-2.235 1.866 2.508 2.508 0 0 1-2.49-1.507 113.563 113.563 0 0 0-9.402-17.803c-20.793 3.926-23.673-29.033-15.484-47.336Z"
            />
            <path
                stroke="#5B3114"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M751.279 479.143c-7.396-9.53-7.073-29.444-1.357-42.219 7.52-16.811 20.792-31.853 47.557-33.623 17.293-1.143 50.56-1.146 61.963 13.053"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M787.026 473.712c-.607-.338-1.012-7.081-6.486-11.339-10.52-8.182-21.496 8.962-7.923 22.913a18.61 18.61 0 0 0 8.321 5.004"
            />
            <path
                fill="url(#y)"
                d="M884.135 613.339c-20.409-24.151-26.325-32.893-31.648-46.77-2.986-7.787-9.421-18.228-14.918-23.949a164.67 164.67 0 0 0-17.21-9.819c4.065 7.05 7.778 20.062 2.997 32.058-2.793 1.171-20.036-16.017-24.181-18.691-11.705-3.72-25.74-14.351-32.717-19.738-4.033.916-19.07 11.051-19.209 11.136a62.743 62.743 0 0 0-26.519 74.436l18.824 52.892c4.37 12.283 2.11 25.795-6.376 34.828-20.637 21.97-25.614 45.362-27.936 63.292 14.883 0 179.194.875 179.225.714 9.23-48.617-.85-76.582-9.313-98.686-.944-2.465-1.843-4.812-2.686-7.11 8.094-3.299 18.171-9.26 20.659-19.015 1.942-7.614-1.083-16.22-8.992-25.578Z"
            />
            <g filter="url(#z)">
                <path
                    fill="url(#A)"
                    d="m770.367 624.31 5.079 9.293c5.359 11.03 17.157 17.893 29.235 20.012 7.208 1.264 14.814 1.029 21.605-1.698a31.488 31.488 0 0 0 11.791-8.469 31.488 31.488 0 0 0 6.742-12.857 31.493 31.493 0 0 0-6.485-27.499l-6.916-10.635"
                />
            </g>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M767.172 527.922c11.036 8.027 21.706 14.535 31.631 17.961l24.553 18.979 8-19.516 7.309 6.637"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m798.801 545.884-9.445 13.073-20.922-22.629M824.852 567.906c3.32 4.311 6.916 9.662 10.237 13.973"
            />
            <g clipPath="url(#B)">
                <path
                    fill="#fff"
                    d="M899.861 594.229v6.773a6.152 6.152 0 0 1-6.152 6.151H867.55a6.152 6.152 0 0 1-6.152-6.151v-64.654h38.463v57.881Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M899.861 594.229v6.773a6.152 6.152 0 0 1-6.152 6.151H867.55a6.152 6.152 0 0 1-6.152-6.151v-64.654h38.463v50.208"
                />
                <path
                    fill="#C0F0FF"
                    d="M894.477 551.59v49.411a.77.77 0 0 1-.769.769h-26.16a.77.77 0 0 1-.769-.769V551.59h27.698Z"
                />
            </g>
            <path
                fill="#FCB47F"
                d="M770.914 572.175c1.283 7.751 2.971 15.433 4.484 23.145a180.769 180.769 0 0 1 3.051 23.385c.584 7.867.963 15.77 1.055 23.72a225.133 225.133 0 0 1-.86 24.042 19.166 19.166 0 0 1-36.488 6.13 225.123 225.123 0 0 1-8.671-22.441c-2.51-7.544-4.735-15.136-6.753-22.763a180.726 180.726 0 0 1-4.758-23.098c-1.09-7.783-2.006-15.595-3.327-23.339a26.498 26.498 0 0 1 21.668-30.579 26.503 26.503 0 0 1 30.578 21.668l.021.13Z"
            />
            <path
                fill="#fff"
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M739.773 628.748c6.076 0 11-4.788 11-10.694 0-5.906-4.924-10.695-11-10.695-6.075 0-11 4.789-11 10.695 0 5.906 4.925 10.694 11 10.694Z"
            />
            <path
                fill="#231F20"
                d="M739.773 620.36c1.27 0 2.3-1.034 2.3-2.309a2.305 2.305 0 0 0-2.3-2.309 2.304 2.304 0 0 0-2.3 2.309 2.304 2.304 0 0 0 2.3 2.309Z"
            />
            <path
                fill="#FCB47F"
                d="m751.086 653.371 10.073-7.496 9.99-7.612a257.223 257.223 0 0 1 20.763-14.138c3.59-2.179 7.117-4.442 10.771-6.532l5.442-3.188c1.817-1.059 3.673-2.063 5.507-3.097 7.37-4.094 14.837-8.052 22.632-11.558a12.253 12.253 0 0 1 14.07 19.442c-5.766 6.309-11.859 12.165-18.052 17.886-1.555 1.419-3.089 2.869-4.663 4.263l-4.729 4.173c-3.128 2.817-6.379 5.462-9.57 8.19a262.962 262.962 0 0 1-19.92 15.305l-10.353 7.11-10.269 7.226a18.502 18.502 0 0 1-21.692-29.974Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M742.157 672.597a225.211 225.211 0 0 1-8.67-22.441c-2.51-7.545-4.735-15.137-6.753-22.764a180.655 180.655 0 0 1-4.758-23.098c-1.09-7.783-2.006-15.595-3.327-23.339-1.846-10.82 3.503-23.093 12.203-28.658M770.895 572.047l.021.13c1.282 7.75 2.971 15.433 4.483 23.145a180.746 180.746 0 0 1 3.051 23.385c.27 3.628.395 5.265.569 8.91"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M850.333 619.188c-5.766 6.308-11.859 12.164-18.052 17.885-1.555 1.419-3.089 2.869-4.663 4.263l-4.729 4.173c-3.128 2.817-6.379 5.462-9.571 8.19a262.563 262.563 0 0 1-19.92 15.305l-10.352 7.11-10.269 7.226c-8.356 5.881-20.694 5.654-30.625-10.746M761.16 645.875l9.989-7.612a257.417 257.417 0 0 1 20.764-14.138c3.589-2.179 7.117-4.442 10.771-6.532l5.442-3.188c1.816-1.059 3.672-2.063 5.507-3.097 7.37-4.094 14.837-8.052 22.632-11.558"
            />
            <path
                fill="#FCB47F"
                d="M897.467 605.999a29.879 29.879 0 0 0-1.483-.999l.186-1.005a4.404 4.404 0 0 0-.632-3.194 31.936 31.936 0 0 0-6.408-7.286l.68-1.311c1.805-2.737 2.508-6.397 1.056-9.337-1.585-3.207-5.232-4.809-8.703-5.676-6.772-1.692-15.066-4.189-20.688-.051a220.278 220.278 0 0 0-20.785 17.365c-12.453 11.502-8.881 24.475 4.873 26.991a16.173 16.173 0 0 0 16.482-7.103c6.4 1.379 15.44 3.79 21.231 7.816a3.169 3.169 0 0 0 4.247-.504l.06-.07a3.188 3.188 0 0 0 .754-2.315 3.176 3.176 0 0 0-1.104-2.169c-6.615-5.585-16.652-9.6-24.44-12.272 9.982 1.727 22.381 4.1 32.683 5.912a2.641 2.641 0 0 0 2.969-1.794l.013-.039a2.652 2.652 0 0 0-.991-2.959Z"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M840.691 594.505a220.29 220.29 0 0 1 20.786-17.365c5.621-4.138 13.916-1.641 20.688.051 3.471.867 7.117 2.469 8.702 5.676 1.453 2.94.751 6.599-1.055 9.337"
            />
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M866.891 587.328c6.034.334 12.226.806 17.69 3.389a24.688 24.688 0 0 1 11.062 10.459M867.451 596.148a107.807 107.807 0 0 1 15.349 2.966A54.516 54.516 0 0 1 897.466 606a2.652 2.652 0 0 1 .991 2.961l-.013.039a2.635 2.635 0 0 1-2.968 1.794c-10.303-1.812-22.701-4.185-32.684-5.911 7.79 2.672 17.829 6.689 24.444 12.276a3.173 3.173 0 0 1 .346 4.479l-.04.047a3.205 3.205 0 0 1-4.288.512c-5.792-4.016-14.817-6.424-21.209-7.801a16.169 16.169 0 0 1-16.483 7.102M875.633 585.977l5.172.101a3.068 3.068 0 0 1 2.063.548 2.045 2.045 0 0 1 .585 1.474 6.009 6.009 0 0 1-.26 1.603"
            />
            <path
                fill="url(#C)"
                d="M719.57 594.647c-.238-1.519-.457-3.044-.676-4.568-.479-3.335-.974-6.783-1.663-10.118a27.502 27.502 0 0 1 11.489-28.315 27.493 27.493 0 0 1 20.613-4.255 27.494 27.494 0 0 1 21.763 21.449l.064.32c.649 3.342 1.52 6.715 2.363 9.978-6.801-6.35-41.821-11.977-53.953 15.509Z"
            />
            <path
                fill="url(#D)"
                d="M1145.6 700h-47.04l-13.71 22.917h47.04L1145.6 700Z"
            />
            <path
                fill="#D2F0FA"
                d="M1131.89 722.914h-47.04v97.572h47.04v-97.572ZM1145.6 690.156h-47.04v9.842h47.04v-9.842Z"
            />
            <path
                fill="#5BC2E7"
                d="M1159.31 722.917 1145.6 700l-13.71 22.917v97.571h27.42v-97.571Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1120.08 722.914h-35.23v97.572h47.04M1159.31 782.107v-59.19L1145.6 700M1131.89 820.488h27.42v-16.449M1098.56 700l-13.71 22.917M1145.6 699.998v-9.842h-47.04v9.842"
            />
            <path fill="#FFB33B" d="M1360.52 810.23h-158.59v9.495h158.59v-9.495Z" />
            <path
                fill="url(#E)"
                d="M1273.03 769.008h-19.82l59.21 40.515h19.82l-59.21-40.515Z"
            />
            <path fill="url(#F)" d="M1253.21 769.008v40.515h59.21l-59.21-40.515Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1282.82 775.705-9.79-6.697h-19.82v40.515h79.03l-33.81-23.132"
            />
            <path
                fill="url(#G)"
                d="M1262.79 789.913c1.95 0 3.53-1.578 3.53-3.523a3.526 3.526 0 0 0-7.05 0 3.525 3.525 0 0 0 3.52 3.523Z"
            />
            <path
                fill="url(#H)"
                d="M1262.72 804.604c1.95 0 3.52-1.577 3.52-3.523a3.517 3.517 0 0 0-3.52-3.522 3.524 3.524 0 0 0 0 7.045Z"
            />
            <path
                fill="url(#I)"
                d="M1277.48 804.639c1.95 0 3.53-1.577 3.53-3.523a3.526 3.526 0 0 0-7.05 0 3.524 3.524 0 0 0 3.52 3.523Z"
            />
            <path
                fill="url(#J)"
                d="m1176.02 769.451-19.95 17.109h76.14l-19.95-17.109c-10.42-8.945-25.82-8.945-36.24 0Z"
            />
            <path
                fill="#fff"
                d="M1242.78 778.023a65.979 65.979 0 0 1-2.51 8.543 64.366 64.366 0 0 1-4 8.754 67.797 67.797 0 0 1-2 3.339 57.258 57.258 0 0 1-3.21 4.53 57.82 57.82 0 0 1-7.05 7.519c-5.39 4.791-10.85 7.83-15.25 9.778h-29.25c-4.4-1.948-9.87-4.987-15.25-9.778a57.82 57.82 0 0 1-7.05-7.519 57.258 57.258 0 0 1-3.21-4.53 60.515 60.515 0 0 1-1.99-3.339 63.168 63.168 0 0 1-4-8.754 64.716 64.716 0 0 1-2.52-8.543h97.29Z"
            />
            <path
                fill="url(#K)"
                d="M1242.79 778.023a66.297 66.297 0 0 1-2.52 8.543 63.168 63.168 0 0 1-4 8.754 67.479 67.479 0 0 1-1.99 3.339 62.981 62.981 0 0 1-3.21 4.53 57.921 57.921 0 0 1-7.06 7.519c-5.38 4.791-10.84 7.83-15.25 9.778h-12.01c-4.4-1.948-9.87-4.987-15.25-9.778a57.141 57.141 0 0 1-7.05-7.519 60.171 60.171 0 0 1-3.22-4.53 60.236 60.236 0 0 1-1.98-3.339 63.168 63.168 0 0 1-4-8.754 64.716 64.716 0 0 1-2.52-8.543h80.06Z"
            />
            <path
                fill="#EF0E2E"
                d="M1240.27 786.566a64.31 64.31 0 0 1-4 8.754h-84.26a63.115 63.115 0 0 1-4-8.754h92.26Z"
            />
            <path
                fill="#64CCC9"
                d="M1234.27 798.656a57.258 57.258 0 0 1-3.21 4.53h-73.85a57.258 57.258 0 0 1-3.21-4.53h80.27Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1224.01 810.711c13.13-11.684 17.34-26.265 18.77-32.684h-97.29c1.43 6.419 5.65 21 18.78 32.684 5.38 4.79 10.84 7.83 15.25 9.776h29.24a59.268 59.268 0 0 0 8.65-4.711"
            />
            <g clipPath="url(#L)">
                <path
                    fill="url(#M)"
                    d="M1188.58 769.674c4.15 0 7.51-1.744 7.51-3.896 0-2.151-3.36-3.895-7.51-3.895s-7.51 1.744-7.51 3.895c0 2.152 3.36 3.896 7.51 3.896Z"
                />
            </g>
            <path
                fill="url(#N)"
                d="M1201.94 761.883c7.53 0 13.63-3.239 13.63-7.234 0-3.996-6.1-7.235-13.63-7.235-7.53 0-13.64 3.239-13.64 7.235 0 3.995 6.11 7.234 13.64 7.234Z"
            />
            <path
                fill="url(#O)"
                d="M1233.7 756.714c-.98-1.207-7.22-.689-18.13 3.981s-15.59 8.83-15.39 10.369c.35 2.705 10.67 3.714 19.75-.177 9.09-3.891 15.48-12.053 13.77-14.173Z"
            />
            <path
                fill="url(#P)"
                d="M1376.8 779.277h-34.3l4.87 37.174a4.623 4.623 0 0 0 4.59 4.027h15.39c2.32 0 4.28-1.723 4.59-4.027l4.86-37.174Z"
            />
            <path
                fill="#004F71"
                d="M1356.89 779.28c-4.16-7.221-17.39-30.238-17.69-30.645a3.837 3.837 0 0 0-5.35-.894 3.839 3.839 0 0 0-.89 5.35l18.69 26.189h5.24Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1334.46 779.277h50.39M1373.37 805.523l3.43-26.246h-34.3l4.87 37.174a4.623 4.623 0 0 0 4.59 4.027h15.39c2.32 0 4.28-1.723 4.59-4.027l.48-3.716"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M222.051 1201H2363.05"
            />
            <defs>
                <linearGradient
                    id="a"
                    x1={1488.1}
                    x2={956.053}
                    y1={834.854}
                    y2={834.854}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.515} stopColor="#EA9975" />
                    <stop offset={0.616} stopColor="#F3B39A" />
                    <stop offset={0.699} stopColor="#F9C4B2" />
                    <stop offset={0.75} stopColor="#FCCBBB" />
                </linearGradient>
                <linearGradient
                    id="b"
                    x1={1053.45}
                    x2={1156.14}
                    y1={1019.82}
                    y2={1019.82}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="c"
                    x1={1282.39}
                    x2={1385.08}
                    y1={1019.82}
                    y2={1019.82}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#616469" />
                    <stop offset={1} stopColor="#8E8B8F" />
                </linearGradient>
                <linearGradient
                    id="d"
                    x1={1521.05}
                    x2={1521.05}
                    y1={984.282}
                    y2={723.921}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.535} stopColor="#E19A6D" />
                    <stop offset={0.976} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="e"
                    x1={1510.67}
                    x2={1510.67}
                    y1={629.279}
                    y2={461.074}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#002A3A" />
                    <stop offset={0.885} stopColor="#024B6B" />
                    <stop offset={0.966} stopColor="#024E70" />
                </linearGradient>
                <linearGradient
                    id="f"
                    x1={1539.53}
                    x2={1485.32}
                    y1={517.823}
                    y2={517.823}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#002A3A" />
                    <stop offset={0.885} stopColor="#024B6B" />
                    <stop offset={0.966} stopColor="#024E70" />
                </linearGradient>
                <linearGradient
                    id="g"
                    x1={1522.03}
                    x2={1522.03}
                    y1={491.146}
                    y2={423.949}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="h"
                    x1={1563.38}
                    x2={1553.15}
                    y1={497.035}
                    y2={479.597}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.375} stopColor="#EFA876" />
                    <stop offset={0.943} stopColor="#CD885F" />
                    <stop offset={0.97} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="i"
                    x1={1511.62}
                    x2={1525.41}
                    y1={425.451}
                    y2={425.451}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="j"
                    x1={1533.93}
                    x2={1556.27}
                    y1={425.664}
                    y2={425.664}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#111011" />
                    <stop offset={0.999} stopColor="#111011" />
                </linearGradient>
                <linearGradient
                    id="l"
                    x1={1682.95}
                    x2={1369.55}
                    y1={764.721}
                    y2={764.721}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.453} stopColor="#E19A6D" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </linearGradient>
                <linearGradient
                    id="m"
                    x1={1512.56}
                    x2={1744.65}
                    y1={928.642}
                    y2={712.219}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.535} stopColor="#E19A6D" />
                    <stop offset={0.976} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="n"
                    x1={1540.08}
                    x2={1662.71}
                    y1={758.832}
                    y2={873.185}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#1E1F23" />
                    <stop offset={0.406} stopColor="#20232A" />
                    <stop offset={1} stopColor="#222731" />
                </linearGradient>
                <linearGradient
                    id="o"
                    x1={1663.07}
                    x2={1468.01}
                    y1={637.634}
                    y2={637.634}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#002A3A" />
                    <stop offset={0.917} stopColor="#024B6B" />
                    <stop offset={1} stopColor="#024E70" />
                </linearGradient>
                <linearGradient
                    id="p"
                    x1={1610.31}
                    x2={1537.22}
                    y1={1175.19}
                    y2={1175.19}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="q"
                    x1={1650.49}
                    x2={1623.46}
                    y1={625.146}
                    y2={471.879}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#024E70" />
                    <stop offset={0.973} stopColor="#002A3A" />
                </linearGradient>
                <linearGradient
                    id="r"
                    x1={840.661}
                    x2={863.16}
                    y1={556.91}
                    y2={581.694}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.034} stopColor="#FFF4C3" />
                    <stop offset={0.967} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="s"
                    x1={826.393}
                    x2={857.138}
                    y1={567.418}
                    y2={608.969}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.034} stopColor="#FFF4C3" />
                    <stop offset={0.967} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="t"
                    x1={756.634}
                    x2={821.025}
                    y1={1180.47}
                    y2={1180.47}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="u"
                    x1={810.083}
                    x2={892.78}
                    y1={1174.77}
                    y2={1174.77}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="v"
                    x1={718.74}
                    x2={820.234}
                    y1={930.308}
                    y2={929.441}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#EF0025" />
                    <stop offset={1} stopColor="#F6542F" />
                </linearGradient>
                <linearGradient
                    id="w"
                    x1={796.361}
                    x2={809.394}
                    y1={533.987}
                    y2={512.295}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.216} stopColor="#FCB47F" />
                    <stop offset={0.433} stopColor="#F8B07C" />
                    <stop offset={0.667} stopColor="#EBA473" />
                    <stop offset={0.909} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="x"
                    x1={836.329}
                    x2={836.329}
                    y1={516.979}
                    y2={453.097}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.068} stopColor="#FCB47F" />
                    <stop offset={0.325} stopColor="#F8B07C" />
                    <stop offset={0.604} stopColor="#EBA473" />
                    <stop offset={0.891} stopColor="#D58F65" />
                    <stop offset={0.999} stopColor="#CB865E" />
                </linearGradient>
                <linearGradient
                    id="y"
                    x1={704.659}
                    x2={892.244}
                    y1={666.249}
                    y2={633.377}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="C"
                    x1={728.354}
                    x2={757.953}
                    y1={560.381}
                    y2={592.586}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="D"
                    x1={1115.22}
                    x2={1115.22}
                    y1={718.699}
                    y2={697.798}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D2F0FA" />
                    <stop offset={1} stopColor="#fff" />
                </linearGradient>
                <linearGradient
                    id="E"
                    x1={1320.29}
                    x2={1288.23}
                    y1={789.265}
                    y2={789.265}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="F"
                    x1={1302.37}
                    x2={1246.97}
                    y1={808.728}
                    y2={786.795}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="G"
                    x1={1287.07}
                    x2={1255.29}
                    y1={817.325}
                    y2={776.834}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="H"
                    x1={1279.89}
                    x2={1248.11}
                    y1={822.957}
                    y2={782.466}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="I"
                    x1={1289.01}
                    x2={1257.23}
                    y1={815.801}
                    y2={775.31}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="J"
                    x1={1245.01}
                    x2={1172.42}
                    y1={864.719}
                    y2={753.192}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="K"
                    x1={1162.73}
                    x2={1242.79}
                    y1={799.255}
                    y2={799.255}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="M"
                    x1={1194.55}
                    x2={1180.06}
                    y1={770.588}
                    y2={758.646}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <linearGradient
                    id="N"
                    x1={1213.37}
                    x2={1190.9}
                    y1={765.645}
                    y2={744.524}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="O"
                    x1={1221.66}
                    x2={1189.94}
                    y1={767.602}
                    y2={744.269}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
                <linearGradient
                    id="P"
                    x1={1379.57}
                    x2={1354.23}
                    y1={831.094}
                    y2={783.4}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC2E7" />
                    <stop offset={0.183} stopColor="#6AC8E9" />
                    <stop offset={0.538} stopColor="#93D8F0" />
                    <stop offset={1} stopColor="#D2F0FA" />
                </linearGradient>
                <radialGradient
                    id="k"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(180 811.095 379.186) scale(255.204)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#CB865E" />
                    <stop offset={0.48} stopColor="#E6A070" />
                    <stop offset={0.932} stopColor="#FCB47F" />
                </radialGradient>
                <radialGradient
                    id="A"
                    cx={0}
                    cy={0}
                    r={1}
                    gradientTransform="rotate(-20.922 2071.504 -1879.38) scale(77.211 37.1881)"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.437} stopColor="#FFD100" stopOpacity={0} />
                    <stop offset={0.621} stopColor="#FFD100" stopOpacity={0.401} />
                    <stop offset={0.781} stopColor="#FFD100" stopOpacity={0.723} />
                    <stop offset={0.894} stopColor="#FFD100" stopOpacity={0.923} />
                    <stop offset={0.949} stopColor="#FFD100" />
                </radialGradient>
                <clipPath id="B">
                    <path fill="#fff" d="M860.629 535.578h40v72.343h-40z" />
                </clipPath>
                <clipPath id="L">
                    <path fill="#fff" d="M1181.07 761.883h15.026v7.791h-15.026z" />
                </clipPath>
                <filter
                    id="z"
                    width={91.305}
                    height={77.894}
                    x={762.367}
                    y={584.457}
                    colorInterpolationFilters="sRGB"
                    filterUnits="userSpaceOnUse"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur
                        result="effect1_foregroundBlur_13879_12688"
                        stdDeviation={4}
                    />
                </filter>
            </defs>
        </svg>
    );
}
