import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M469.376 213.602c-5.469 4.46-10.71 9.119-15.871 13.846-1.614 1.496-3.256 2.967-4.847 4.484l-4.761 4.556c-3.207 3.01-6.253 6.159-9.377 9.24a287.721 287.721 0 0 0-13.257 14.104l-.004-.013a73.499 73.499 0 0 0-6.315-13.534c-2.549-4.273-5.27-8.452-7.605-12.839a26.51 26.51 0 0 0-15.955-12.984 26.496 26.496 0 0 0-20.165 2.191 26.494 26.494 0 0 0-12.709 15.807 26.508 26.508 0 0 0 2.191 20.163c2.387 4.36 4.388 8.928 6.561 13.402a73.396 73.396 0 0 0 7.85 12.706c3.001 4.029 6.186 7.957 9.626 11.748a98.427 98.427 0 0 0 11.334 10.825 20.292 20.292 0 0 0 4.792 2.818c.285.289.563.583.87.857a18.504 18.504 0 0 0 26.121-1.51l8.73-9.802 8.84-9.708a281.264 281.264 0 0 0 16.665-20.293c2.612-3.526 5.302-6.984 7.832-10.582l3.843-5.354c1.278-1.787 2.505-3.62 3.759-5.427 3.488-5.091 6.915-10.236 10.177-15.523-4.641-.31-18.25-8.768-18.325-19.178Z"
            fill="url(#female-34-25-shirt-1-arm-opt-3-right-a)"
        />
        <path
            d="M396.072 307.309a98.689 98.689 0 0 1-11.335-10.825c-3.44-3.791-6.624-7.72-9.625-11.748a73.434 73.434 0 0 1-7.85-12.706c-2.173-4.475-4.174-9.043-6.561-13.402a26.501 26.501 0 0 1 1.018-27.167"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m407.191 233.172.149.275c2.335 4.387 5.056 8.566 7.605 12.838 1.279 2.134 4.055 8.055 5.106 10.312M491.785 225.922c-4.43 7.741-9.283 15.116-14.261 22.382-1.254 1.807-2.481 3.64-3.758 5.427l-3.843 5.354c-2.53 3.598-5.22 7.056-7.833 10.582a286.913 286.913 0 0 1-16.665 20.293l-8.839 9.708-8.73 9.802a18.506 18.506 0 0 1-26.122 1.51M408.395 275.193l8.37-10.114a280.78 280.78 0 0 1 17.754-19.35c3.124-3.082 6.17-6.23 9.377-9.24l4.761-4.557c1.59-1.516 3.233-2.987 4.846-4.483 6.495-5.949 13.116-11.789 20.159-17.261"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M487.705 232.781c-4.642-.311-18.251-8.769-18.326-19.179"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-3-right-a"
                x1={357.44}
                y1={264.633}
                x2={487.702}
                y2={264.633}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
