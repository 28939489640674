import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6611)"
            d="M465.57 317.031s-9.232-1.054-13.783-1.364c-.684-.042-1.368-.089-2.052-.134-1.254-.085-2.508-.171-3.763-.228l-5.778-.248c-2.626-.144-5.28-.188-7.846-.233-1.25-.022-2.5-.043-3.755-.076a225.15 225.15 0 00-4.72-.05c-5.51 0-10.888.39-16.349.802 1.324-6.913 2.349-13.966 3.334-21.66a173.611 173.611 0 001.682-23.05 917.64 917.64 0 00-.01-6.841c-.02-5.196-.04-10.568.14-15.837l.005-.14a26.937 26.937 0 00-7.652-19.438 27.307 27.307 0 00-19.204-8.616 27.528 27.528 0 00-.812-.011 27.355 27.355 0 00-27.474 26.703c-.152 5.262-.511 10.616-.858 15.793a937.913 937.913 0 00-.442 6.842c-.465 7.61-.415 15.242.148 22.844.52 8.09 1.232 15.563 2.177 22.847.814 7.6 2.066 15.146 3.749 22.602a31.437 31.437 0 003.334 8.268c3.92 6.397 9.588 10.618 18.299 10.733h.002c.312 0 .626-.007.941-.022l11.502-.548 11.528-.406a221.561 221.561 0 0023.073-2.158c1.236-.188 2.473-.364 3.71-.539 2.542-.362 5.17-.736 7.748-1.2l5.716-.965c1.236-.211 2.47-.451 3.705-.692.672-.131 1.345-.26 2.024-.388a364.284 364.284 0 009.9-2.065l1.133-.253-.417-1.082c-.023-.06-2.32-6.074-2.32-10.892a48.772 48.772 0 012.85-17.002l.535-1.296z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6611"
                x1="332.703"
                x2="516.173"
                y1="259.903"
                y2="358.795"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
