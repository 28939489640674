import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m518.389 839.578 6.51 31.265 5.005-.1s1.369-9.986 1.894-13.996c.381-2.918 1.401-5.023 4.243-4.258l1.465-1.571"
                fill="#EF0025"
            />
            <path
                d="M584.796 871.154a38.856 38.856 0 0 1-11.105.909l-5.482-.34a24.334 24.334 0 0 1-15.705-7.08l-7.387-7.382a22.325 22.325 0 0 0-11.138-6.052l-5.991-1.279a13.895 13.895 0 0 1-10.94-15.075l1.818-17.277 1.22.474c9.202 3.574 17.22 8.855 21.999 14.489 1.97 2.322 3.72 4.86 5.411 7.313a61.754 61.754 0 0 0 7.877 9.905 30.36 30.36 0 0 0 16.094 7.915c2.028.37 4.106.354 6.128-.046a9.278 9.278 0 0 0 2.441-.923 16.56 16.56 0 0 0 3.817-3.911l.429-.614 12.169 4.089a6.433 6.433 0 0 1 2.875 10.219 6.429 6.429 0 0 1-3.487 2.138l-11.043 2.528Z"
                fill="#EF0025"
            />
            <path
                d="M599.527 866.226a6.413 6.413 0 0 1-3.688 2.404l-11.043 2.527a38.893 38.893 0 0 1-11.105.91l-5.482-.341a24.336 24.336 0 0 1-15.705-7.08l-7.387-7.382a22.325 22.325 0 0 0-11.138-6.052l-5.991-1.278a15.925 15.925 0 0 1-8.892-6.207M524.9 870.842l5.005-.1"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m297.24 828.594 2.334 33.823 5.801.274 1.583-18.263"
                fill="#EF0025"
            />
            <path
                d="m299.574 862.414 5.856-.023"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M334.235 871.63a21.232 21.232 0 0 1-18.055-9.96c-.116-.181-12.025-18.79-16.472-26.969-2.806-5.159-1.559-9.91-.734-13.054l.067-.255c.974-3.729 3.649-9.485 5.867-13.923 5.351 8.06 9.359 21.687 12.413 31.579 2.196 7.112 7.126 9.714 14.265 11.819 4.175 1.23 11.799.555 16.808-3.053 5.132 4.338 10.668 7.438 12.951 9.542a8.713 8.713 0 0 1 2.693 9.776 6.5 6.5 0 0 1-6.297 4.498h-23.506Z"
                fill="url(#female-34-25-shoes-7-walk-1-a)"
            />
            <path
                d="M364.038 867.133a6.507 6.507 0 0 1-2.406 3.288 6.508 6.508 0 0 1-3.891 1.21h-23.506a21.232 21.232 0 0 1-18.055-9.96c-.116-.181-12.025-18.79-16.472-26.969-2.806-5.159-1.559-9.91-.734-13.054"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-7-walk-1-a"
                    x1={297.973}
                    y1={839.549}
                    x2={364.545}
                    y2={839.549}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
