import React from 'react';

export default function SvgComponent() {
    return (
        <g clipPath="url(#man-34-25-shoes-2-cycling-1-left-a)">
            <path
                d="M352.76 760.338s8.318 31.067 23.684 45.659c3.423 3.251 7.567 7.492 9.901 9.906a4.15 4.15 0 0 1 .89 4.363 4.15 4.15 0 0 1-5.124 2.486c-6.889-2.161-21.234-6.746-24.908-8.572a69.65 69.65 0 0 1-17.096-11.763 42.176 42.176 0 0 0-10.64-7.35l-18.264-8.928a9 9 0 0 1-4.642-5.409 8.99 8.99 0 0 1 .751-7.089c5.287-9.383 12.424-23.736 13.857-27.833l1.157-22.41 38.602 16.193-8.168 20.747Z"
                fill="#2B2729"
            />
            <path
                d="M382.207 823.789a4.883 4.883 0 0 1-.287-.079c-7.808-2.377-21.456-6.631-25.127-8.416a70.782 70.782 0 0 1-17.442-11.788 41.123 41.123 0 0 0-10.449-7.086l-18.051-8.635a10.406 10.406 0 0 1-5.392-5.791 9.912 9.912 0 0 1 .662-8.197c2.362-4.277 5.097-8.28 7.302-11.75 4.005 1.621 6.93 3.838 10.468 6.264.577.395 1.151.789 1.727 1.179 5.205 3.526 10.864 6.769 16.95 6.62a32.98 32.98 0 0 0 6.987-1.188c1.15-.279 2.339-.568 3.528-.795a25.569 25.569 0 0 1 4.158-.428c4.252 9.794 11.129 23.483 19.841 31.617 3.435 3.207 7.601 7.398 9.872 9.705a5.348 5.348 0 0 1 1.437 5.118 5.161 5.161 0 0 1-6.184 3.65Z"
                fill="#004F71"
            />
            <path
                d="M385.981 823.248a5.138 5.138 0 0 1-4.061.462c-7.808-2.377-21.456-6.631-25.127-8.416a70.785 70.785 0 0 1-17.442-11.789 41.123 41.123 0 0 0-10.449-7.086l-18.339-8.772a9.902 9.902 0 0 1-5.211-5.971"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <clipPath id="man-34-25-shoes-2-cycling-1-left-a">
                    <path
                        fill="#fff"
                        transform="translate(.893 .773)"
                        d="M0 0h900v900H0z"
                    />
                </clipPath>
            </defs>
        </g>
    );
}
