import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-5">
        <path
            d="M510.996 199.716c3.578 7.838 7.553 15.494 11.356 23.228a204.424 204.424 0 0 1 9.955 23.873c2.895 8.151 5.589 16.396 8.002 24.77a254 254 0 0 1 6.125 25.631 23.262 23.262 0 0 1-18.875 26.941 23.31 23.31 0 0 1-22.016-8.156 253.916 253.916 0 0 1-15.456-21.345c-4.78-7.285-9.281-14.7-13.58-22.206a204.568 204.568 0 0 1-11.626-23.105c-3.39-7.924-6.609-15.927-10.225-23.748a31.002 31.002 0 0 1 38.837-42.105 31 31 0 0 1 17.44 16.087l.063.135Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m530.744 284.617 5.563 1.505c1.858.487 3.725.933 5.577 1.443 1.845.55 3.712.993 5.537 1.634.918.29 22.171 6.72 25.739 8.378a126.127 126.127 0 0 1 10.298 5.268 16.52 16.52 0 0 1 7.166 8.105 16.52 16.52 0 0 1 .611 10.802 16.353 16.353 0 0 1-14.025 11.568c-4.011.391-7.977.574-11.904.576-3.934.036-17.318-1.931-21.2-2.135a62.068 62.068 0 0 1-2.906-.181 60.012 60.012 0 0 1-2.888-.262c-1.926-.17-3.81-.537-5.717-.799-1.898-.3-3.782-.666-5.674-.99l-5.155-.841c-12.057-1.966-20.797-13.017-19.261-25.137a22.54 22.54 0 0 1 16.997-19.069 22.52 22.52 0 0 1 11.242.135Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M506.035 318.743c-5.231-7.425-11.144-16.809-15.948-24.084-4.78-7.286-9.281-14.701-13.58-22.207a204.568 204.568 0 0 1-11.626-23.105c-3.39-7.924-6.608-15.927-10.224-23.748a31.004 31.004 0 0 1-.958-23.707 30.986 30.986 0 0 1 6.31-10.32 30.985 30.985 0 0 1 33.484-8.078 31 31 0 0 1 17.44 16.087l.063.136c3.578 7.838 7.553 15.494 11.356 23.228a204.41 204.41 0 0 1 9.955 23.872c2.895 8.152 5.588 16.397 8.001 24.77 1.235 4.332 2.417 8.688 3.484 13.096M545.021 288.684c.919.29 24.572 7.237 28.139 8.895a122.919 122.919 0 0 1 10.601 5.443"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M577.209 333.32c-4.011.391-7.977.574-11.903.576-3.934.036-17.319-1.931-21.201-2.135a62.025 62.025 0 0 1-2.905-.181 60.012 60.012 0 0 1-2.888-.262c-1.927-.17-3.81-.537-5.716-.799-1.898-.3-3.782-.666-5.675-.99l-5.69-.928a22.472 22.472 0 0 1-15.198-9.859"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M581.351 257.071c.228.263.456.535.683.815a1.57 1.57 0 0 0 1.644.537l.157-.043a5.181 5.181 0 0 1 3.809.404 39.574 39.574 0 0 1 8.364 5.886 1.552 1.552 0 0 0 1.905.18l.408-.261c3.017-2.403 7.231-3.612 10.829-2.22 3.926 1.519 6.187 5.624 7.568 9.6 2.697 7.756 6.496 17.213 2.241 24.236a259.34 259.34 0 0 1-18.159 26.184c-12.165 15.803-27.743 12.984-32.139-2.866a19.058 19.058 0 0 1 5.76-19.395c.241-.198.417-.463.507-.761.091-.298.091-.616.001-.914-2.314-7.265-5.989-16.968-11.077-23.044a3.73 3.73 0 0 1 .143-5.031l.076-.077a3.718 3.718 0 0 1 1.203-.826 3.727 3.727 0 0 1 2.863-.034 3.72 3.72 0 0 1 1.224.797 65.288 65.288 0 0 1 9.992 13.523 1.555 1.555 0 0 0 1.953.663 1.557 1.557 0 0 0 .9-1.855c-2.133-7.678-4.351-15.593-6.26-22.66a3.1 3.1 0 0 1 1.788-3.667l.045-.019a3.111 3.111 0 0 1 3.572.848Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M598.395 325.23a323.091 323.091 0 0 0 20.562-29.024c4.256-7.023.457-16.479-2.239-24.236-1.382-3.976-3.643-8.08-7.569-9.599-3.597-1.393-7.811-.184-10.829 2.22"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M606.455 290.934c-1.027-7.036-2.233-14.242-5.835-20.372a29.049 29.049 0 0 0-13.421-11.859M596.058 291.204a126.538 126.538 0 0 0-5.092-17.672 64.121 64.121 0 0 0-9.614-16.459 3.12 3.12 0 0 0-3.573-.85l-.045.019a3.105 3.105 0 0 0-1.789 3.667c1.908 7.067 4.128 14.982 6.261 22.659a1.556 1.556 0 0 1-.901 1.856 1.557 1.557 0 0 1-1.953-.663 65.277 65.277 0 0 0-9.991-13.522 3.734 3.734 0 0 0-4.086-.764 3.735 3.735 0 0 0-1.204.825l-.076.077a3.73 3.73 0 0 0-.143 5.03c5.088 6.076 8.763 15.779 11.077 23.045.09.299.09.616-.001.914-.09.298-.266.563-.507.761a19.06 19.06 0 0 0-5.76 19.395M607.111 280.545l-.664-6.05a3.605 3.605 0 0 0-.86-2.36 2.405 2.405 0 0 0-1.788-.531 7.136 7.136 0 0 0-1.852.474"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.12 186.181c-9.225-7.122-19.957-12.655-32.552-17.169l19.121 31.543s17.867-10.95 13.431-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.568 169.012a129.67 129.67 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
