import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 191,
        height: 223,
        content: (
            <>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M77.796 71.071v-39.79h62.739c16.266 0 29.451-13.186 29.451-29.45H25.607v29.45h22.737v60.442h39.212"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m87.556 91.726 6.052-20.654h77.3l18.19 150.06H49.648l25.254-86.207"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M142.537 192.216c9.313 0 16.863-7.55 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.313 7.55 16.863 16.863 16.863Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M142.536 158.495v-46.116H30.531v10.853H1.081V60.221h29.45v10.853h47.265"
                />
            </>
        ),
    },
    'Color #1': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M77.96 88.36V25.348H48.51V88.36h29.45Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.698 70.068V59.215H1.246v63.011h29.452v-10.853h89.557V70.068H30.698Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.246 122.226V59.215h29.452v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.247 70.066h77.305l18.19 150.063H51.289L95.247 70.066Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.29 220.129 95.246 70.066M76.132 220.129h114.609l-18.19-150.063H42.785"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.701 30.277H25.773V.827h144.379c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.7 122.224v-10.853h52.446M143.418 191.21c9.313 0 16.863-7.549 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.314 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.751}
                        x2={60.751}
                        y1={106.447}
                        y2={72.701}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.1}
                        x2={89.532}
                        y1={214.82}
                        y2={96.588}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.774}
                        x2={170.153}
                        y1={15.552}
                        y2={15.552}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M78.002 88.36V25.348H48.551V88.36h29.451Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.737 70.064V59.211H1.285v63.012h29.452v-10.854h89.557V70.064H30.737Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.285 122.223V59.211h29.452v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.284 70.066h77.305l18.19 150.063H51.326L95.284 70.066Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.326 220.129 95.284 70.066M76.171 220.129H190.78L172.59 70.066H42.824"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.74 30.277H25.812V.827h144.379c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.736 122.224v-10.853h52.447M143.457 191.214c9.313 0 16.863-7.55 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.313 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.79}
                        x2={60.79}
                        y1={106.443}
                        y2={72.698}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.137}
                        x2={89.569}
                        y1={214.82}
                        y2={96.589}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#50D5F9" />
                        <stop offset={0.506} stopColor="#93E3F9" />
                        <stop offset={1} stopColor="#D2F0FA" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.814}
                        x2={170.192}
                        y1={15.551}
                        y2={15.551}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M78.04 88.364V25.352H48.587v63.012H78.04Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.775 70.068V59.215H1.325v63.012h29.45v-10.854h89.558V70.068H30.775Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.324 122.227V59.215h29.451v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.323 70.07h77.305l18.19 150.063H51.365L95.323 70.07Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.365 220.133 95.323 70.07M76.208 220.133h114.609L172.627 70.07H42.861"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.779 30.277H25.851V.827H170.23c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.775 122.228v-10.853h52.448M143.496 191.214c9.313 0 16.863-7.55 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.313 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.829}
                        x2={60.829}
                        y1={106.447}
                        y2={72.702}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.176}
                        x2={89.608}
                        y1={214.824}
                        y2={96.593}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3ACEC7" />
                        <stop offset={0.502} stopColor="#7CDBD8" />
                        <stop offset={1} stopColor="#BBE9E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.852}
                        x2={170.231}
                        y1={15.551}
                        y2={15.551}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M78.078 88.36V25.348h-29.45V88.36h29.45Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.814 70.064V59.211H1.364v63.012h29.45v-10.854h89.558V70.064H30.814Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.363 122.223V59.211h29.451v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.362 70.066h77.305l18.19 150.063H51.404L95.362 70.066Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.404 220.129 95.362 70.066M76.247 220.129h114.609l-18.19-150.063H42.9"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.819 30.277H25.891V.827H170.27c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.814 122.224v-10.853h52.449M143.535 191.21c9.313 0 16.863-7.549 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.314 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.868}
                        x2={60.868}
                        y1={106.443}
                        y2={72.698}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.215}
                        x2={89.647}
                        y1={214.82}
                        y2={96.589}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.237} stopColor="#93E97E" />
                        <stop offset={1} stopColor="#C9F7BC" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.892}
                        x2={170.271}
                        y1={15.551}
                        y2={15.551}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M78.12 88.36V25.348H48.667V88.36H78.12Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.853 70.068V59.215H1.403v63.011h29.45v-10.853h89.558V70.068H30.853Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.402 122.226V59.215h29.451v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.401 70.066h77.305l18.19 150.063H51.443L95.401 70.066Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.443 220.129 95.401 70.066M76.286 220.129h114.609l-18.19-150.063H42.939"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.858 30.277H25.93V.827h144.379c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.854 122.224v-10.853h52.448M143.574 191.21c9.313 0 16.863-7.549 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.314 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.907}
                        x2={60.907}
                        y1={106.447}
                        y2={72.701}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.254}
                        x2={89.686}
                        y1={214.82}
                        y2={96.588}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.393} stopColor="#FFA29B" />
                        <stop offset={1} stopColor="#FFD9D9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.931}
                        x2={170.31}
                        y1={15.552}
                        y2={15.552}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 192,
        height: 222,
        content: (
            <>
                <path fill="#63666A" d="M78.158 88.364V25.352h-29.45v63.012h29.45Z" />
                <path
                    fill="url(#object-kitchen-mincer-a)"
                    d="M30.892 70.072V59.219H1.442v63.011h29.45v-10.853h89.558V70.072H30.892Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M1.441 122.23V59.219h29.451v10.853"
                />
                <path
                    fill="url(#object-kitchen-mincer-b)"
                    d="M95.44 70.07h77.305l18.19 150.063H51.482L95.44 70.07Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M51.482 220.133 95.44 70.07M76.326 220.133h114.609L172.745 70.07H42.978"
                />
                <path
                    fill="url(#object-kitchen-mincer-c)"
                    d="M140.897 30.277H25.969V.827h144.379c0 16.254-13.197 29.45-29.451 29.45Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M30.893 122.228v-10.853H83.34M143.613 191.214c9.313 0 16.863-7.55 16.863-16.863 0-9.313-7.55-16.863-16.863-16.863-9.313 0-16.863 7.55-16.863 16.863 0 9.313 7.55 16.863 16.863 16.863Z"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-mincer-a"
                        x1={60.946}
                        x2={60.946}
                        y1={-317.767}
                        y2={72.705}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-b"
                        x1={144.293}
                        x2={89.725}
                        y1={214.824}
                        y2={96.592}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-mincer-c"
                        x1={25.97}
                        x2={170.349}
                        y1={15.552}
                        y2={15.552}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={1} stopColor="#D9D9D6" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-kitchen-mincer"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
