import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={88}
            height={47}
            fill="none"
            id="object-food-prunes"
            {...attributes}
        >
            <g clipPath="url(#object-food-prunes-a)">
                <path
                    fill="url(#object-food-prunes-b)"
                    d="M43.904 46.064c24.247 0 43.904-10.312 43.904-23.032S68.151 0 43.904 0C19.656 0 0 10.312 0 23.032s19.657 23.032 43.904 23.032Z"
                />
            </g>
            <defs>
                <linearGradient
                    id="object-food-prunes-b"
                    x1={92.948}
                    x2={17.159}
                    y1={72.321}
                    y2={-3.847}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <clipPath id="object-food-prunes-a">
                    <path fill="#fff" d="M0 0h87.809v46.063H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
