import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={3}>
        <path
            d="M406.374 232.94c2.335 4.387 5.055 8.566 7.604 12.838a73.477 73.477 0 0 1 6.316 13.535c1.721 4.719 3.258 9.537 4.541 14.493a98.61 98.61 0 0 1 2.831 15.415 20.126 20.126 0 0 1-10.48 19.532 20.124 20.124 0 0 1-22.079-1.955 98.436 98.436 0 0 1-11.335-10.825c-3.44-3.791-6.624-7.72-9.625-11.748a73.457 73.457 0 0 1-7.85-12.706c-2.174-4.475-4.174-9.042-6.561-13.402a26.503 26.503 0 0 1 2.592-29.331 26.504 26.504 0 0 1 28.09-8.83 26.498 26.498 0 0 1 15.956 12.984Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m398.951 284.708 8.48-10.02 8.37-10.114a280.759 280.759 0 0 1 17.754-19.35c3.124-3.081 6.17-6.23 9.377-9.24l4.761-4.556c1.59-1.517 3.232-2.988 4.846-4.484 6.495-5.949 13.115-11.788 20.158-17.261a12.236 12.236 0 0 1 15.526.422 12.235 12.235 0 0 1 2.597 15.312c-4.43 7.742-9.283 15.116-14.261 22.382-1.254 1.808-2.48 3.64-3.758 5.427l-3.843 5.355c-2.53 3.597-5.221 7.055-7.833 10.581a287.207 287.207 0 0 1-16.665 20.294l-8.839 9.707-8.731 9.802a18.5 18.5 0 0 1-27.939-24.257Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M395.107 306.801a98.603 98.603 0 0 1-11.335-10.825c-3.44-3.791-6.624-7.72-9.625-11.748a73.434 73.434 0 0 1-7.85-12.706c-2.173-4.475-4.174-9.043-6.561-13.402a26.501 26.501 0 0 1 1.018-27.167M406.223 232.672l.149.275c2.335 4.387 5.056 8.566 7.605 12.838 1.279 2.134 4.054 8.055 5.105 10.312M490.82 225.422c-4.43 7.741-9.282 15.116-14.26 22.382-1.255 1.807-2.481 3.64-3.759 5.427l-3.843 5.354c-2.53 3.598-5.22 7.056-7.833 10.582a286.913 286.913 0 0 1-16.665 20.293l-8.839 9.708-8.73 9.802a18.504 18.504 0 0 1-26.121 1.51M407.43 274.693l8.37-10.114a280.549 280.549 0 0 1 17.754-19.35c3.124-3.082 6.17-6.23 9.377-9.24l4.761-4.557c1.59-1.516 3.232-2.987 4.846-4.483 6.495-5.949 13.115-11.789 20.158-17.261"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m519.43 193.253-.273-.273a27.798 27.798 0 0 0-.403-7.967 4.487 4.487 0 0 0-1.069-2.265 3.76 3.76 0 0 0-2.29-1.16l.042-.301a9.68 9.68 0 0 0-2.19-4.844c-2.575-2.916-6.907-3.612-10.766-3.118a2.405 2.405 0 0 0-1.873 1.222l-.057.474a2.436 2.436 0 0 0-2.179.958c-2.598 3.401-6.742 5.415-10.222 8.018a31.976 31.976 0 0 0-12.407 21.283c-2.335 16.506-.061 26.773 11.995 23.801l18.735-6.196c2.947-.975 6.013-2.022 8.189-4.236a16.966 16.966 0 0 0 3.575-6.533 60.502 60.502 0 0 0 2.969-13.388 6.96 6.96 0 0 0-1.776-5.475Zm-16.259-3.356a12.846 12.846 0 0 1-8.409 2.401l.065-.883c.302-3.078 2.538-5.557 4.544-7.911a26.764 26.764 0 0 0 1.713-2.202l.603.327a5.885 5.885 0 0 1 1.628 2.132l1.719 3.856.197.366a12.415 12.415 0 0 1-2.06 1.914Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M475.746 205.278a31.978 31.978 0 0 1 12.407-21.283c3.48-2.603 7.624-4.617 10.222-8.018a2.426 2.426 0 0 1 2.179-.958l.038.004a2.436 2.436 0 0 1 1.786 1.08 2.446 2.446 0 0 1 .299 2.065 15.958 15.958 0 0 1-3.303 5.334c-2.006 2.355-4.242 4.833-4.544 7.911"
            stroke="#231F20"
            strokeWidth={2.158}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M492.414 191.91a12.618 12.618 0 0 0 10.758-2.01 12.633 12.633 0 0 0 5.146-9.658"
            stroke="#231F20"
            strokeWidth={2.158}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M502.48 173.325c3.86-.494 8.191.202 10.767 3.118a10.98 10.98 0 0 1 2.339 8 32.57 32.57 0 0 1-2.039 8.282M504.543 181.188a7.758 7.758 0 0 1 2.837 2.659M503.316 183.758l1.72 3.856M518.756 185.016a28.148 28.148 0 0 1-2.854 18.754"
            stroke="#231F20"
            strokeWidth={2.158}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M521.206 198.727a60.48 60.48 0 0 1-2.969 13.387 16.962 16.962 0 0 1-3.575 6.534c-2.176 2.214-5.242 3.261-8.189 4.235l-18.735 6.197"
            stroke="#231F20"
            strokeWidth={2.158}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
