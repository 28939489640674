import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shirt-3-cycling-a)">
        <path
            d="M501.022 277.022a194.04 194.04 0 0 0-6.027-44.007h-.005c-.447-1.782-.914-3.54-1.402-5.276a172.98 172.98 0 0 0-1.425-4.807c-.029-.093-.055-.19-.084-.282a164.627 164.627 0 0 0-3.35-9.697l-.004-.011c-.042-.11-.08-.226-.122-.335-8.133-17.24-16.178-28.429-19.845-32.192-2.485 2.532-8.369 3.262-11.93 3.235-9.114-.07-17.348-5.413-24.148-11.481-6.684-5.965-13.611-16.066-24.086-20.505-12.275 2.71-24.019 8.721-34.97 17.923-8.52 7.141-17.506 17.659-23.287 25.506-42.08 57.117-56.603 153.034-56.324 206.478 51.831 30.036 131.204 46.735 161.783 25.265 21.937-15.403 28.854-39.11 31.446-58.988 2.292-17.581 2.597-31.976.283-47.452a20.672 20.672 0 0 1 6.087-18.052c5.377-5.14 7.715-13.677 7.41-25.322Z"
            fill="url(#man-34-25-shirt-3-cycling-b)"
        />
        <path
            d="M501.022 277.022a194.04 194.04 0 0 0-6.027-44.007h-.005c-.447-1.782-.914-3.54-1.402-5.276a172.98 172.98 0 0 0-1.425-4.807c-.029-.093-.055-.19-.084-.282a164.627 164.627 0 0 0-3.35-9.697l-.004-.011c-.042-.11-.08-.226-.122-.335-8.133-17.24-16.178-28.429-19.845-32.192-2.485 2.532-8.369 3.262-11.93 3.235-9.114-.07-17.348-5.413-24.148-11.481-6.684-5.965-13.611-16.066-24.086-20.505-12.275 2.71-24.019 8.721-34.97 17.923-8.52 7.141-17.506 17.659-23.287 25.506-42.08 57.117-56.603 153.034-56.324 206.478 51.831 30.036 131.204 46.735 161.783 25.265 21.937-15.403 28.854-39.11 31.446-58.988 2.292-17.581 2.597-31.976.283-47.452a20.672 20.672 0 0 1 6.087-18.052c5.377-5.14 7.715-13.677 7.41-25.322Z"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-3-cycling-b"
                x1={294.008}
                y1={294.102}
                x2={501.049}
                y2={294.102}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="man-34-25-shirt-3-cycling-a">
                <path
                    fill="#fff"
                    transform="translate(.916 .672)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
