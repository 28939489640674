import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-1-right">
        <path
            d="M364.248 273.592a89.573 89.573 0 0 1-7.73-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053 330.089 330.089 0 0 1-.556-4.385c-.392-3.194-.796-6.498-1.408-9.689a32.04 32.04 0 0 1 25.401-37.455 32.274 32.274 0 0 1 6.06-.578 32.02 32.02 0 0 1 31.395 25.98l.065.353c.582 3.261 1.387 6.495 2.166 9.621l1.431 5.743c2.249-1.62-46.988 29.133-47.616 51.254Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-righta)"
        />
        <path
            d="m408.202 206.621.066.353c.582 3.261 1.386 6.495 2.165 9.621l1.431 5.743c2.249-1.62-46.988 29.133-47.616 51.254a89.573 89.573 0 0 1-7.73-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053c-.198-1.457-.377-2.921-.557-4.385-.391-3.194-.795-6.498-1.407-9.689"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-righta"
                x1={398.729}
                y1={265.771}
                x2={320.006}
                y2={104.422}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
