import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7289)"
            d="M494.769 223.29a2.718 2.718 0 01.821 2.483c-.805 4.666-13.807 10.893-16.406 12.093l-.912.421s-.585-24.988-5.356-31.349a108.207 108.207 0 0121.853 16.352z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7289)"
            d="M506.803 229.697c-4.633-2.393-9.425-4.866-14.031-7.469l-.123-.07a27.502 27.502 0 00-39.837 31.509 27.5 27.5 0 0013.023 16.512c4.122 2.302 8.13 4.724 12.216 7.211 0 0 6.215-29.066 28.752-47.693z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7289"
                x1="493.985"
                x2="419.382"
                y1="211.871"
                y2="256.979"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7289"
                x1="504.863"
                x2="430.261"
                y1="229.862"
                y2="274.97"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
