import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, string> = {
    white: '#FFDBAE',
    black: '#6C453A',
    brown: '#CB865E',
    yellow: '#FCB47F'
};

export default SKIN_COLOR;
