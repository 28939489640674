import React from 'react';

const SvgComponent = () => {
    return <g id="man-34-25-shorts-2-walk-1">
        <path
            fill="#2B2729"
            d="M518.622 487.392a369.06 369.06 0 0 0 3.571-29.012c.478-5.069 11.14-71.357 11.14-71.357s-114.443 27.06-114.662 55.908c-1.16 10.944-1.915 22.456-2.4 33.37-.276 5.074-.572 15.254-.572 15.254l101.832 2.377s.745-4.41 1.091-6.54Z"
        />
        <path
            fill="url(#man-34-25-shorts-2-walk-1-a)"
            d="M475.064 495.421a35.25 35.25 0 0 1 6.919-29.874c10.456-12.767 16.587-33.232 16.587-33.232s24.552-11.723 33.269-45.194h-161.1c-9.134 15.77-16.008 25.657-17.433 43.962a56.37 56.37 0 0 0 .606 22.547c.086.818.172 1.636.271 2.482a12.662 12.662 0 0 0 2.069 5.627 73.647 73.647 0 0 0 1.367 3.587c3.655 10.757 7.928 21.871 12.178 32.311.634 1.591 1.289 3.174 1.939 4.76h104.821c-.495-2.36-.993-4.686-1.493-6.976Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-shorts-2-walk-1-a"
                x1={352.441}
                x2={531.839}
                y1={444.759}
                y2={444.759}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729" />
                <stop offset={0.225} stopColor="#312E30" />
                <stop offset={0.564} stopColor="#434245" />
                <stop offset={0.972} stopColor="#5F6267" />
                <stop offset={0.999} stopColor="#616469" />
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
