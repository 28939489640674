import { ILegs } from 'interfaces';

import stand1 from './female-34-25-pants-5-stand-1';
import stand2 from './female-34-25-pants-5-stand-2';
import walk1 from './female-34-25-pants-5-walk-1';
import walk2 from './female-34-25-pants-5-walk-2';
import sitting1Left from './female-34-25-pants-5-sitting-1-left';
import sitting1Right from './female-34-25-pants-5-sitting-1-right';

const pants5: ILegs = {
    name: 'Pants #5',
    stand1: [stand1],
    stand2: [stand2],
    walk1: [walk1],
    walk2: [walk2],
    sitting1: [sitting1Right, sitting1Left],
};

export default pants5;
