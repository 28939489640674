import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={180}
            height={541}
            fill="none"
            id="object-office-scales"
            {...attributes}
        >
            <g
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                clipPath="url(#object-office-scales-a)"
            >
                <path 
                    d="M81.93 49.009V30.168H63.09v18.841H81.93ZM161.457 49.009V30.168h-18.841v18.841h18.841ZM61.347 520.77H20.54c-10.406 0-18.84 8.435-18.84 18.841h176.336c0-10.406-8.435-18.841-18.841-18.841h-53.23M63.089 39.582h-44.53M1.7 29.581v-11.96c0-5.53 4.48-10 10-10h26.67M142.621 39.582h-18.84"
                />
                <path 
                    d="M55.781 7.621h112.26c5.52 0 10 4.47 10 10v11.96c0 5.53-4.48 10-10 10h-6.58M18.559 39.582v18.843M1.7 58.428V17.621c0-5.523 4.477-10 10-10h6.857M38.371 7.623V1.855h17.405v5.768" 
                />
                <path 
                    d="M81.93 39.585h19.15l-.003-7.917a7.484 7.484 0 0 1 7.484-7.484h7.731a7.484 7.484 0 0 1 7.484 7.484v19.607H110.99v-11.69h12.79M105.963 520.77l-32.19-.02 7.42-424.66M105.307 482.89l-6.76-386.8" 
                />
                <path 
                    d="M1.695 58.426v.84c0 11.2 7.77 20.9 18.71 23.32l60.79 13.51M18.555 58.426h158.62c0 11.05-7.58 20.66-18.32 23.23l-60.31 14.44" 
                />
            </g>
            <defs>
                <clipPath id="object-office-scales-a">
                    <path fill="#fff" d="M.695.855H179.04V540.61H.695z" />
                </clipPath>
            </defs>
        </svg>
    );
}
