import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#004F71"
                d="M577.555 672.023l9.998 31.115 5.612-.67s-.306-5.876-.682-10.219c-.26-3.01.228-7.656 3.629-7.689"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M587.553 703.138l5.611-.669"
            ></path>
            <path
                fill="#004F71"
                d="M592.5 674.713c-.365-8.495 1.693-17.526-6.191-26.353l30.876 1.89-1.763-3.492-34.114-2.297-3.969 21.126a13.898 13.898 0 009.687 15.909l5.868 1.758a18.848 18.848 0 014.236 1.792c-1.495-2.707-4.447-6.058-4.63-10.333zM637.563 686.625c-.865.025-1.725.141-2.566.347a21.453 21.453 0 00-10.772 7.025 14.65 14.65 0 00-3.05 10.874l.094.629.61.181c1.139.348 2.302.611 3.48.787l5.435.782c2.577.37 3.796-.115 6.473-.28l.968-.058-.03-.971a14.641 14.641 0 013.906-10.446 14.479 14.479 0 015.191-3.455l1.068-.229s-10.107-4.765-10.807-5.186z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M655.575 702.924c-.98 1.104-4.539 1.973-6.075 2.197-2.803.408-6.156.573-8.958.98-3.695.537-11.338 1.242-15.184.366-5.789-1.319-11.234-3.801-15.082-8.325l-6.767-7.954a22.318 22.318 0 00-10.612-6.931l-5.869-1.758a15.93 15.93 0 01-8.362-6.905"
            ></path>
        </g>
    );
}
