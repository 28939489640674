import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7353)"
            d="M494.768 223.344a109.59 109.59 0 00-20.026-15.328 31.03 31.03 0 012.394 13.894 65.914 65.914 0 01-1.451 10.762l2.169 4.755a1.013 1.013 0 00.563.522.997.997 0 00.767-.03c2.598-1.2 15.601-7.426 16.405-12.092a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7353)"
            d="M503.362 224.738c-5.143-1.464-10.461-2.978-15.625-4.657l-.129-.041a27.516 27.516 0 00-21.051 1.67 27.51 27.51 0 00-13.66 16.106 27.501 27.501 0 0017.968 34.612c5.159 1.65 10.349 3.524 15.368 5.335l3.58 1.29 18.677-52.85s-3.759-1.076-5.128-1.465z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7353"
                x1="489.831"
                x2="439.518"
                y1="208.885"
                y2="297.8"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7353"
                x1="496.883"
                x2="446.57"
                y1="212.875"
                y2="301.791"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
