import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={652}
            height={314}
            viewBox="0 0 652 314"
            fill="none"
            id="object-meals-7"
            {...attributes}
        >
            <path
                fill="url(#object-meals-7-a)"
                d="M74.018 187.566h-4.434c-1.824 10.141-8.76 18.579-18.306 22.46-14.88 6.05-25.085 18.472-25.085 32.82 0 20.298 20.42 36.753 45.609 36.753 25.188 0 45.608-16.455 45.608-36.753 0-14.348-10.205-26.77-25.085-32.82-9.545-3.881-16.483-12.319-18.307-22.46Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M33.51 222.876c-4.627 5.748-7.317 12.605-7.317 19.97 0 20.298 20.42 36.753 45.609 36.753 25.188 0 45.608-16.455 45.608-36.753 0-14.348-10.205-26.77-25.085-32.82-9.545-3.881-16.481-12.319-18.306-22.46h-4.433c-1.825 10.141-8.761 18.579-18.307 22.46"
            />
            <path
                fill="url(#object-meals-7-b)"
                d="M72.854 187.566h-2.106c0 22.955-20.607 28.51-20.607 55.28 0 20.298 9.697 36.753 21.66 36.753 11.961 0 21.659-16.455 21.659-36.753 0-26.771-20.606-32.325-20.606-55.28Z"
            />
            <path
                fill="url(#object-meals-7-c)"
                d="M70.748 187.566c0 22.955-20.607 28.51-20.607 55.28 0 20.298 9.697 36.753 21.66 36.753v-92.033h-1.053Z"
            />
            <g clipPath="url(#object-meals-7-d)">
                <path
                    fill="url(#object-meals-7-e)"
                    d="M405.972 280.836H1.715c18.246 20.03 43.27 31.327 69.401 31.327h509.768c26.13 0 51.154-11.295 69.402-31.327H405.972Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M547.568 312.163h33.318c26.13 0 51.154-11.295 69.401-31.327H1.715c18.246 20.03 43.27 31.327 69.401 31.327h421.439"
                />
            </g>
            <path
                fill="url(#object-meals-7-f)"
                d="M263.773 290.941c-9.7-3.067-25.509-14.631-31.779-16.577-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.421 7.648-16.585-9.971-21.207-41.205-11.215-50.274 6.44-5.845 19.978-3.417 21.979-3.032 10.629 2.048 12.237 7.439 19.705 6.821 8.65-.716 9.238-8.177 19.43-10.588 9.698-2.294 18.502 2.251 21.688 3.896 11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.439 36.018 9.609 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.789-41.479 17.849-50.726 14.925l.001.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M114.639 219.788c10.629 2.048 12.237 7.439 19.705 6.821 8.65-.716 9.238-8.177 19.43-10.588 9.698-2.294 18.502 2.251 21.688 3.896 11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.439 36.018 9.609 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.789-41.479 17.849-50.726 14.925-9.7-3.067-25.509-14.631-31.779-16.577-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.421 7.648-16.585-9.971-21.207-41.205-11.215-50.274"
            />
            <path
                fill="url(#object-meals-7-g)"
                d="M268.52 301.815c-10.144-.772-28.175-8.421-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1.001-38.427-2.697-22.983-3.01-45.907 9.007-68.929 6.31-11.107-1.301-25.218-10.336-25.218-18.611s10.233-10.949 17.569-12.709c7.336-1.76 15.149-2.242 21.758-5.879 6.2-3.413 10.807-9.344 17.236-12.303 7.255-3.339 15.763-2.394 23.458-.257 7.695 2.137 15.113 5.395 23.012 6.576 16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112l-.002-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M133.519 259.61c2.944-2.464 5.882-4.926 9.351-6.522 7.255-3.339 15.763-2.394 23.458-.257 7.695 2.137 15.113 5.395 23.012 6.576 16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112-10.144-.772-28.175-8.421-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1.001-38.427-2.697-22.983-3.01-45.907 9.007-68.929 6.31-11.107-1.301-25.218-10.336-25.218-18.611s10.233-10.949 17.569-12.709c4.497-1.079 9.175-1.678 13.644-2.818"
            />
            <path
                fill="url(#object-meals-7-h)"
                d="M406.517 286.648c-9.7-3.067-25.509-14.631-31.779-16.577-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.421 7.648-16.585-9.971-21.207-41.205-11.215-50.274 6.44-5.845 19.978-3.417 21.979-3.032 10.629 2.048 12.237 7.439 19.705 6.821 8.65-.716 9.238-8.177 19.43-10.588 9.698-2.294 18.502 2.251 21.688 3.896 11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.439 36.018 9.609 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.789-41.479 17.849-50.726 14.925l.001.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M257.383 215.495c10.629 2.048 12.237 7.439 19.705 6.821 8.65-.716 9.238-8.177 19.43-10.588 9.698-2.294 18.502 2.251 21.688 3.896 11.127 5.744 10.51 12.324 18.756 18.313 12.152 8.825 21.11.004 49.516 3.284 21.124 2.439 36.018 9.609 40.926 12.126 13.963 7.162 23.755 15.992 29.839 22.375-26.637 18.789-41.479 17.849-50.726 14.925-9.7-3.067-25.509-14.631-31.779-16.577-5.198-1.613-10.557 1.452-15.902 2.481-16.037 3.086-25.976-7.183-36.797-11.399-31.682-12.344-54.268 20.365-75.421 7.648-16.585-9.971-21.207-41.205-11.215-50.274"
            />
            <path
                fill="url(#object-meals-7-i)"
                d="M411.264 297.522c-10.144-.772-28.175-8.421-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1.001-38.427-2.697-22.983-3.01-45.907 9.007-68.929 6.31-11.107-1.301-25.218-10.336-25.218-18.611s10.233-10.949 17.569-12.709c7.336-1.76 15.149-2.242 21.758-5.879 6.2-3.413 10.807-9.344 17.236-12.303 7.255-3.339 15.763-2.394 23.458-.257 7.695 2.137 15.113 5.395 23.012 6.576 16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112l-.002-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M276.263 255.317c2.944-2.464 5.882-4.926 9.351-6.522 7.255-3.339 15.763-2.394 23.458-.257 7.695 2.137 15.113 5.395 23.012 6.576 16.083 2.404 32.062-4.002 48.25-5.553 18.308-1.754 44.321 13.766 76.911 21.85-14.147 16.168-30.568 27.284-45.979 26.112-10.144-.772-28.175-8.421-34.724-8.884-5.429-.384-9.947 3.824-14.915 6.046-11.926 5.332-25.474-1.001-38.427-2.697-22.983-3.01-45.907 9.007-68.929 6.31-11.107-1.301-25.218-10.336-25.218-18.611s10.233-10.949 17.569-12.709c4.497-1.079 9.175-1.678 13.644-2.818"
            />
            <path
                fill="url(#object-meals-7-j)"
                d="M455.143 287.054c9.7-3.067 25.509-14.631 31.779-16.577 5.198-1.613 10.557 1.452 15.902 2.481 16.037 3.086 25.976-7.183 36.797-11.399 31.682-12.344 54.268 20.365 75.421 7.648 16.585-9.971 21.207-41.205 11.215-50.274-6.44-5.845-19.978-3.417-21.979-3.032-10.629 2.048-12.237 7.439-19.705 6.821-8.65-.716-9.238-8.177-19.43-10.588-9.698-2.294-18.502 2.251-21.688 3.896-11.127 5.744-10.51 12.324-18.756 18.313-12.152 8.825-21.11.004-49.516 3.284-21.124 2.439-36.018 9.609-40.926 12.126-13.963 7.162-23.755 15.992-29.839 22.375 26.637 18.789 41.479 17.849 50.726 14.925l-.001.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M604.277 215.901c-10.629 2.048-12.237 7.439-19.705 6.821-8.65-.716-9.238-8.177-19.43-10.588-9.698-2.294-18.502 2.251-21.688 3.896-11.127 5.744-10.51 12.324-18.756 18.313-12.152 8.825-21.11.004-49.516 3.284-21.124 2.439-36.018 9.609-40.926 12.126-13.963 7.162-23.755 15.992-29.839 22.375 26.637 18.789 41.479 17.849 50.726 14.925 9.7-3.067 25.509-14.631 31.779-16.577 5.198-1.613 10.557 1.452 15.902 2.481 16.037 3.086 25.976-7.183 36.797-11.399 31.682-12.344 54.268 20.365 75.421 7.648 16.585-9.971 21.207-41.205 11.215-50.274"
            />
            <path
                fill="url(#object-meals-7-k)"
                d="M450.396 297.929c10.144-.772 28.175-8.421 34.724-8.884 5.429-.384 9.947 3.824 14.915 6.046 11.926 5.332 25.474-1.001 38.427-2.697 22.983-3.01 45.907 9.007 68.929 6.31 11.107-1.301 25.218-10.336 25.218-18.611s-10.233-10.949-17.569-12.709c-7.336-1.76-15.149-2.242-21.758-5.879-6.2-3.413-10.807-9.344-17.236-12.303-7.255-3.339-15.763-2.394-23.458-.257-7.695 2.137-15.113 5.395-23.012 6.576-16.083 2.404-32.062-4.002-48.25-5.553-18.308-1.754-44.321 13.766-76.911 21.85 14.147 16.168 30.568 27.284 45.979 26.112l.002-.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M585.397 255.724c-2.944-2.464-5.882-4.926-9.351-6.522-7.255-3.339-15.763-2.394-23.458-.257-7.695 2.137-15.113 5.395-23.012 6.576-16.083 2.404-32.062-4.002-48.25-5.553-18.308-1.754-44.321 13.766-76.911 21.85 14.147 16.168 30.568 27.284 45.979 26.112 10.144-.772 28.175-8.421 34.724-8.884 5.429-.384 9.947 3.824 14.915 6.046 11.926 5.332 25.474-1.001 38.427-2.697 22.983-3.01 45.907 9.007 68.929 6.31 11.107-1.301 25.218-10.336 25.218-18.611s-10.233-10.949-17.569-12.709c-4.497-1.079-9.175-1.678-13.644-2.818"
            />
            <path
                fill="url(#object-meals-7-l)"
                d="m516.014 60.425-16.928-33.347L365.17 90.036l.018.016c-27.056 12.073-45.924 39.19-45.924 70.732 0 42.767 34.669 77.437 77.437 77.437 34.307 0 63.385-22.319 73.553-53.222l.017.015 45.744-124.59-.001.001Z"
            />
            <path
                fill="url(#object-meals-7-m)"
                d="m319.37 60.636-80.88 22.9c-55.968 15.846-94.601 66.937-94.601 125.105v31.785c0 22.184 17.984 40.168 40.168 40.168h272.476a141.213 141.213 0 0 0 86.539-29.624l57.575-44.65a37.04 37.04 0 0 0-28.713-13.642h-25.855l-50.151-70.156C456.029 66.706 385.386 41.945 319.37 60.636Z"
            />
            <path
                fill="url(#object-meals-7-n)"
                d="M322.299 242.89c0 20.82-16.88 37.7-37.7 37.7h-100.54c-22.19 0-40.17-17.98-40.17-40.16v-7.21c0-18.01 6.87-35.35 19.2-48.48l66.95-71.26c6.85 17.8 5.4 37.74-3.95 54.37l-31.67 56.28 81.67-17.98.91-.2c2.46-.51 5-.77 7.6-.77 20.82 0 37.7 16.88 37.7 37.71Z"
            />
            <path
                fill="url(#object-meals-7-o)"
                d="M544.681 24.902a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013 8.464 16.674 8.464 16.674 9.876-5.013c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364l.001-.002Z"
            />
            <path
                fill="url(#object-meals-7-p)"
                d="M524.479 77.097 507.551 43.75l-133.916 62.958.018.016c-27.056 12.073-45.924 39.19-45.924 70.732 0 42.767 34.669 77.437 77.437 77.437 34.307 0 63.385-22.319 73.553-53.222l.017.015 45.744-124.59-.001.001Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m419.639 64.428 79.45-37.35M525.889 55.411c3.071-1.559 6.71-1.39 9.678.36 3.607 2.124 8.67 4.291 12.214 2.492 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364 12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.8-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013M319.365 60.636c42.03-11.9 85.94-6.19 122.59 13.95M503.775 133.497l42.3 59.18h25.86c5.56 0 10.98 1.25 15.89 3.58s9.31 5.75 12.82 10.06l-57.57 44.65a141.352 141.352 0 0 1-36.46 20.45c-16 6.06-32.97 9.17-50.08 9.17h-272.48c-22.18 0-40.17-17.98-40.17-40.16v-31.79c0-58.17 38.64-109.26 94.6-125.1l53.289-15.088"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m276.088 206.15.91-.2c2.46-.51 5-.77 7.6-.77 20.82 0 37.7 16.88 37.7 37.71 0 20.83-16.88 37.7-37.7 37.7"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m226.09 167.852-31.67 56.28 45.427-10.001 36.243-7.979.91-.2M143.889 233.22c0-18.01 6.87-35.35 19.2-48.48l66.95-71.26a63.956 63.956 0 0 1 4.201 25.913M442.506 245.312c16.98-9.366 30.067-24.922 36.222-43.624M373.629 106.711c-27.05 12.08-45.9 39.21-45.9 70.75 0 20.953 8.32 39.961 21.836 53.902"
            />
            <path
                fill="url(#object-meals-7-q)"
                d="M553.146 41.574a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.799-4.783 7.165-5.197 11.331-.34 3.428-2.351 6.465-5.422 8.024l-9.876 5.013 8.464 16.674 8.464 16.674 9.876-5.013c3.071-1.559 6.71-1.39 9.678.359 3.607 2.125 8.67 4.292 12.214 2.493 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364l.001-.002Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m524.478 77.1 9.876-5.013c3.071-1.56 6.71-1.39 9.678.359 3.607 2.125 8.67 4.292 12.214 2.493 6.075-3.084 8.501-10.51 5.417-16.585a12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364 12.303 12.303 0 0 0-8.151-6.412 12.303 12.303 0 0 0-.366-10.364c-3.084-6.076-10.51-8.5-16.585-5.416-3.544 1.799-4.783 7.165-5.197 11.33-.34 3.429-2.351 6.466-5.422 8.025l-9.876 5.013M524.476 77.098l-45.744 124.589M373.635 106.709 507.551 43.75"
            />
            <defs>
                <linearGradient
                    id="object-meals-7-a"
                    x1={137.346}
                    x2={47.377}
                    y1={300.848}
                    y2={219.765}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-b"
                    x1={20.706}
                    x2={80.133}
                    y1={233.584}
                    y2={233.584}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-c"
                    x1={55.972}
                    x2={160.105}
                    y1={186.101}
                    y2={186.101}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.165} stopColor="#FFD104" />
                    <stop offset={0.304} stopColor="#FFD310" />
                    <stop offset={0.445} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.731} stopColor="#FFE367" />
                    <stop offset={0.873} stopColor="#FFEB94" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-e"
                    x1={1.715}
                    x2={650.286}
                    y1={296.5}
                    y2={296.5}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-f"
                    x1={171.949}
                    x2={146.686}
                    y1={227.269}
                    y2={194.175}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-g"
                    x1={219.026}
                    x2={160.415}
                    y1={295.734}
                    y2={255.313}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-h"
                    x1={314.693}
                    x2={289.43}
                    y1={222.976}
                    y2={189.882}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-i"
                    x1={361.77}
                    x2={303.159}
                    y1={291.441}
                    y2={251.02}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-j"
                    x1={546.967}
                    x2={572.23}
                    y1={223.382}
                    y2={190.288}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-k"
                    x1={499.89}
                    x2={558.501}
                    y1={291.848}
                    y2={251.427}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#00B140" />
                    <stop offset={0.502} stopColor="#42BC59" />
                    <stop offset={1} stopColor="#81C871" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-l"
                    x1={513.351}
                    x2={376.93}
                    y1={73.95}
                    y2={175.508}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-m"
                    x1={143.889}
                    x2={600.647}
                    y1={167.536}
                    y2={167.536}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-n"
                    x1={252.809}
                    x2={175.504}
                    y1={259.808}
                    y2={171.387}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-o"
                    x1={451.307}
                    x2={539.601}
                    y1={83.183}
                    y2={26.72}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.166} stopColor="#FFD104" />
                    <stop offset={0.305} stopColor="#FFD310" />
                    <stop offset={0.446} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.732} stopColor="#FFE367" />
                    <stop offset={0.874} stopColor="#FFEB94" />
                    <stop offset={0.949} stopColor="#FFF0AF" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-p"
                    x1={521.816}
                    x2={385.395}
                    y1={90.622}
                    y2={192.18}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="object-meals-7-q"
                    x1={459.772}
                    x2={548.066}
                    y1={99.856}
                    y2={43.392}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.031} stopColor="#FFD100" />
                    <stop offset={0.166} stopColor="#FFD104" />
                    <stop offset={0.305} stopColor="#FFD310" />
                    <stop offset={0.446} stopColor="#FFD725" />
                    <stop offset={0.588} stopColor="#FFDC42" />
                    <stop offset={0.732} stopColor="#FFE367" />
                    <stop offset={0.874} stopColor="#FFEB94" />
                    <stop offset={0.949} stopColor="#FFF0AF" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
                <clipPath id="object-meals-7-d">
                    <path fill="#fff" d="M0 279h652v35H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
