import { SVGProps } from 'react';

import meal1 from 'assets/objects/meals/object-meals-1';
import meal2 from 'assets/objects/meals/object-meals-2';
import meal3 from 'assets/objects/meals/object-meals-3';
import meal4 from 'assets/objects/meals/object-meals-4';
import meal5 from 'assets/objects/meals/object-meals-5';
import meal6 from 'assets/objects/meals/object-meals-6';
import meal7 from 'assets/objects/meals/object-meals-7';
import meal8 from 'assets/objects/meals/object-meals-8';
import meal9 from 'assets/objects/meals/object-meals-9';

import foodWater from 'assets/objects/food/drinks/object-food-water';
import foodJuice from 'assets/objects/food/drinks/object-food-juice';

import foodMilk from 'assets/objects/food/dairy/object-food-milk';
import foodCheese from 'assets/objects/food/dairy/object-food-cheese';
import foodYogurt from 'assets/objects/food/dairy/object-food-yogurt';

import foodRice from 'assets/objects/food/starches/object-food-rice';
import foodQuinoa from 'assets/objects/food/starches/object-food-quinoa';
import foodMillet from 'assets/objects/food/starches/object-food-millet';
import foodOatmeal from 'assets/objects/food/starches/object-food-oatmeal';
import foodPotato from 'assets/objects/food/starches/object-food-potato';

import foodAlmond from 'assets/objects/food/protein/object-food-almond';
import foodBeans from 'assets/objects/food/protein/object-food-beans';
import foodBrazilian from 'assets/objects/food/protein/object-food-brazilian';
import foodBreastCooked from 'assets/objects/food/protein/object-food-breast-cooked';
import foodBreastRaw from 'assets/objects/food/protein/object-food-breast-raw';
import foodCashew from 'assets/objects/food/protein/object-food-cashew';
import foodEgg from 'assets/objects/food/protein/object-food-egg';
import foodHazelnut from 'assets/objects/food/protein/object-food-hazelnut';
import foodLentilsGreen from 'assets/objects/food/protein/object-food-lentils-green';
import foodLentilsRed from 'assets/objects/food/protein/object-food-lentils-red';
import foodPeanut from 'assets/objects/food/protein/object-food-peanut';
import foodSalmon from 'assets/objects/food/protein/object-food-salmon';
import foodShrimp from 'assets/objects/food/protein/object-food-shrimp';
import foodTofu from 'assets/objects/food/protein/object-food-tofu';
import foodTurkey from 'assets/objects/food/protein/object-food-turkey';
import foodMussel from 'assets/objects/food/protein/object-food-mussel';
import foodScallop from 'assets/objects/food/protein/object-food-scallop';

import foodAppleGreen from 'assets/objects/food/fruits/object-food-apple-green';
import foodAppleRed from 'assets/objects/food/fruits/object-food-apple-red';
import foodApricots from 'assets/objects/food/fruits/object-food-apricots';
import foodBlackberry from 'assets/objects/food/fruits/object-food-blackberry';
import foodBlueberry from 'assets/objects/food/fruits/object-food-blueberry';
import foodCloudberry from 'assets/objects/food/fruits/object-food-cloudberry';
import foodCranberry from 'assets/objects/food/fruits/object-food-cranberry';
import foodDate from 'assets/objects/food/fruits/object-food-date';
import foodHoneydew from 'assets/objects/food/fruits/object-food-honeydew';
import foodPrunes from 'assets/objects/food/fruits/object-food-prunes';
import foodRaisin from 'assets/objects/food/fruits/object-food-raisin';
import foodRaspberry from 'assets/objects/food/fruits/object-food-raspberry';

import foodArugula from 'assets/objects/food/vegetables/object-food-arugula';
import foodBellPepper from 'assets/objects/food/vegetables/object-food-bell-pepper';
import foodCarrot from 'assets/objects/food/vegetables/object-food-carrot';
import foodEggplant from 'assets/objects/food/vegetables/object-food-eggplant';
import foodGreenOnion from 'assets/objects/food/vegetables/object-food-green-onion';
import foodKale from 'assets/objects/food/vegetables/object-food-kale';
import foodOnion from 'assets/objects/food/vegetables/object-food-onion';
import foodSpinach from 'assets/objects/food/vegetables/object-food-spinach';

import foodJuiceGlass from 'assets/objects/food/dishes/object-food-juice-glass';
import foodCodler from 'assets/objects/food/dishes/object-food-codler';
import foodHoney from 'assets/objects/food/dishes/object-food-honey';
import foodMedicine from 'assets/objects/food/dishes/object-food-medicine';
import foodPills from 'assets/objects/food/dishes/object-food-pills';
import foodPlate from 'assets/objects/food/dishes/object-food-plate';
import foodWaterGlass from 'assets/objects/food/dishes/object-food-water-glass';

import accessoriesBag1 from 'assets/objects/accessories/object-accessories-bag-1';
import accessoriesBag2 from 'assets/objects/accessories/object-accessories-bag-2';
import accessoriesBag3 from 'assets/objects/accessories/object-accessories-bag-3';
import accessoriesBicycle from 'assets/objects/accessories/object-accessories-bicycle';
import accessoriesHelmet from 'assets/objects/accessories/object-accessories-helmet';
import accessoriesSunglasses1 from 'assets/objects/accessories/object-accessories-sunglasses-1';
import accessoriesSunglasses2 from 'assets/objects/accessories/object-accessories-sunglasses-2';
import accessoriesWheelchair1 from 'assets/objects/accessories/object-accessories-wheelchair-1';
import accessoriesWheelchair2 from 'assets/objects/accessories/object-accessories-wheelchair-2';

import devicesHeadphones from 'assets/objects/devices/object-devices-headphones';
import devicesIpad from 'assets/objects/devices/object-devices-ipad';
import devicesIpods from 'assets/objects/devices/object-devices-ipods';
import devicesLaptop from 'assets/objects/devices/object-devices-laptop';
import devicesMobile from 'assets/objects/devices/object-devices-mobile';
import devicesMonitor from 'assets/objects/devices/object-devices-monitor';
import devicesScreen from 'assets/objects/devices/object-devices-screen';
import devicesSensor from 'assets/objects/devices/object-devices-sensor';
import devicesTablet from 'assets/objects/devices/object-devices-tablet';

import kitchenBlender1 from 'assets/objects/kitchen/object-kitchen-blender-1';
import kitchenBlender2 from 'assets/objects/kitchen/object-kitchen-blender-2';
import kitchenCooker from 'assets/objects/kitchen/object-kitchen-cooker';
import kitchenFridge from 'assets/objects/kitchen/object-kitchen-fridge';
import kitchenHood from 'assets/objects/kitchen/object-kitchen-hood';
import kitchenMincer from 'assets/objects/kitchen/object-kitchen-mincer';
import kitchenPlant from 'assets/objects/kitchen/object-kitchen-plant';
import kitchenPot1 from 'assets/objects/kitchen/object-kitchen-pot-1';
import kitchenPot2 from 'assets/objects/kitchen/object-kitchen-pot-2';
import kitchenTable from 'assets/objects/kitchen/object-kitchen-table';
import kitchenWindow from 'assets/objects/kitchen/object-kitchen-window';

import officeArmchair from 'assets/objects/office/object-office-armchair';
import officeBarStool from 'assets/objects/office/object-office-bar-stool';
import officeBarTable from 'assets/objects/office/object-office-bar-table';
import officeBench from 'assets/objects/office/object-office-bench';
import officeCarts from 'assets/objects/office/object-office-carts';
import officeChair from 'assets/objects/office/object-office-chair';
import officeClock from 'assets/objects/office/object-office-clock';
import officeCouch from 'assets/objects/office/object-office-couch';
import officeDryer from 'assets/objects/office/object-office-dryer';
import officeNightstand from 'assets/objects/office/object-office-nightstand';
import officePlumbing from 'assets/objects/office/object-office-plumbing';
import officeScales from 'assets/objects/office/object-office-scales';
import officeShelf from 'assets/objects/office/object-office-shelf';
import officeSink from 'assets/objects/office/object-office-sink';
import officeSpinChair from 'assets/objects/office/object-office-spin-chair';
import officeStorage from 'assets/objects/office/object-office-storage';
import officeTable from 'assets/objects/office/object-office-table';
import officeTissue from 'assets/objects/office/object-office-tissue';
import officeWorkplace from 'assets/objects/office/object-office-workplace';

import outdoorBench1 from 'assets/objects/outdoor/object-outdoor-bench-1';
import outdoorBench2 from 'assets/objects/outdoor/object-outdoor-bench-2';
import outdoorBuilding from 'assets/objects/outdoor/object-outdoor-building';
import outdoorBush from 'assets/objects/outdoor/object-outdoor-bush';
import outdoorTrash from 'assets/objects/outdoor/object-outdoor-trash';
import outdoorTree from 'assets/objects/outdoor/object-outdoor-tree';

import backgroundOutdoors1 from 'assets/objects/background/object-background-outdoors-1';
import backgroundOutdoors2 from 'assets/objects/background/object-background-outdoors-2';
import backgroundOutdoors3 from 'assets/objects/background/object-background-outdoors-3';
import backgroundOutdoors4 from 'assets/objects/background/object-background-outdoors-4';
import backgroundOutdoors5 from 'assets/objects/background/object-background-outdoors-5';
import backgroundOutdoors6 from 'assets/objects/background/object-background-outdoors-6';

import backgroundOffice1 from 'assets/objects/background/object-background-office-1';
import backgroundOffice2 from 'assets/objects/background/object-background-office-2';
import backgroundOffice3 from 'assets/objects/background/object-background-office-3';
import backgroundOffice4 from 'assets/objects/background/object-background-office-4';
import backgroundOffice5 from 'assets/objects/background/object-background-office-5';
import backgroundOffice6 from 'assets/objects/background/object-background-office-6';

import backgroundKitchen1 from 'assets/objects/background/object-background-kitchen-1';
import backgroundKitchen2 from 'assets/objects/background/object-background-kitchen-2';
import backgroundKitchen3 from 'assets/objects/background/object-background-kitchen-3';
import backgroundKitchen4 from 'assets/objects/background/object-background-kitchen-4';
import backgroundKitchen5 from 'assets/objects/background/object-background-kitchen-5';

import { SETTING_TYPES } from 'components/ObjectBuilder';

export const DEVICES = {
    option1: {
        name: 'Headphones',
        image: devicesHeadphones,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ]
    },
    option2: {
        name: 'iPad',
        image: devicesIpad,
    },
    option3: {
        name: 'iPods',
        image: devicesIpods,
    },
    option4: {
        name: 'Screen',
        image: devicesScreen,
    },
    option5: {
        name: 'Monitor',
        image: devicesMonitor,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
        ],
    },
    option6: {
        name: 'Mobile phone',
        image: devicesMobile,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
            'Color #7',
            'Color #8',
        ],
    },
    option7: {
        name: 'Tablet',
        image: devicesTablet,
    },
    option8: {
        name: 'Sensor',
        image: devicesSensor,
    },
    option9: {
        name: 'Laptop',
        image: devicesLaptop,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
        ]
    },
};

export const DOCTORS_OFFICE = {
    option1: {
        name: 'Armchair',
        image: officeArmchair,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option2: {
        name: 'Bar stool',
        image: officeBarStool,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option3: {
        name: 'Bar table',
        image: officeBarTable,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option4: {
        name: 'Chair',
        image: officeChair,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option5: {
        name: 'Couch',
        image: officeCouch,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option6: {
        name: 'Sink',
        image: officeSink,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option7: {
        name: 'Table',
        image: officeTable,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option8: {
        name: 'Bench',
        image: officeBench,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option9: {
        name: 'Spin chair',
        image: officeSpinChair,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option10: {
        name: 'Shelf',
        image: officeShelf,
        outline: [
            'Outline #1',
            'Outline #2',
        ],
    },
    option11: {
        name: 'Scales',
        image: officeScales,
    },
    option12: {
        name: 'Dryer',
        image: officeDryer,
    },
    option13: {
        name: 'Tissue',
        image: officeTissue,
    },
    option14: {
        name: 'Nightstand',
        image: officeNightstand,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option15: {
        name: 'Carts',
        image: officeCarts,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option16: {
        name: 'Clock',
        image: officeClock,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
        ],
    },
    option17: {
        name: 'Integrated plumbing',
        image: officePlumbing,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
        ],
    },
    option18: {
        name: 'Storage',
        image: officeStorage,
    },
    option19: {
        name: 'Workplace',
        image: officeWorkplace,
    },
};

export const FOOD_ITEMS = {
    option1: {
        name: 'Glass of juice',
        image: foodJuiceGlass,
        variations: [
            'Option #1',
            'Option #2',
            'Option #3',
            'Option #4',
        ],
        tag: 'Dishes',
    },
    option2: {
        name: 'Milk',
        image: foodMilk,
        tag: 'Dairy',
    },
    option3: {
        name: 'Cheese',
        image: foodCheese,
        tag: 'Dairy',
    },
    option4: {
        name: 'Yogurt',
        image: foodYogurt,
        tag: 'Dairy',
    },
    option5: {
        name: 'Water',
        image: foodWater,
        tag: 'Drinks',
    },
    option6: {
        name: 'Brown rice',
        image: foodRice,
        tag: 'Starches',
    },
    option7: {
        name: 'Quinoa',
        image: foodQuinoa,
        tag: 'Starches',
    },
    option8: {
        name: 'Millet',
        image: foodMillet,
        tag: 'Starches',
    },
    option9: {
        name: 'Oatmeal',
        image: foodOatmeal,
        tag: 'Starches',
    },
    option10: {
        name: 'Baked sweet potato',
        image: foodPotato,
        tag: 'Starches',
    },
    option11: {
        name: 'Juice',
        image: foodJuice,
        variations: [
            'Juice',
            'Yogurt',
        ],
        tag: 'Drinks',
    },
    option12: {
        name: 'Almond',
        image: foodAlmond,
        tag: 'Protein',
    },
    option13: {
        name: 'Dry beans',
        image: foodBeans,
        tag: 'Protein',
    },
    option14: {
        name: 'Brazilian nut',
        image: foodBrazilian,
        tag: 'Protein',
    },
    option15: {
        name: 'Cooked chicken breast',
        image: foodBreastCooked,
        tag: 'Protein',
    },
    option16: {
        name: 'Raw chicken breast',
        image: foodBreastRaw,
        tag: 'Protein',
    },
    option17: {
        name: 'Cashew',
        image: foodCashew,
        tag: 'Protein',
    },
    option18: {
        name: 'White egg',
        image: foodEgg,
        tag: 'Protein',
    },
    option19: {
        name: 'Hazelnut',
        image: foodHazelnut,
        tag: 'Protein',
    },
    option20: {
        name: 'Green lentils',
        image: foodLentilsGreen,
        tag: 'Protein',
    },
    option21: {
        name: 'Red lentils',
        image: foodLentilsRed,
        tag: 'Protein',
    },
    option22: {
        name: 'Peanut',
        image: foodPeanut,
        tag: 'Protein',
    },
    option23: {
        name: 'Salmon',
        image: foodSalmon,
        tag: 'Protein',
    },
    option24: {
        name: 'Shrimp',
        image: foodShrimp,
        tag: 'Protein',
    },
    option25: {
        name: 'Tofu',
        image: foodTofu,
        tag: 'Protein',
    },
    option26: {
        name: 'Turkey',
        image: foodTurkey,
        tag: 'Protein',
    },
    option27: {
        name: 'Mussel',
        image: foodMussel,
        variations: [
            'Option #1',
            'Option #2',
        ],
        tag: 'Protein',
    },
    option28: {
        name: 'Scallop',
        image: foodScallop,
        variations: [
            'Option #1',
            'Option #2',
        ],
        tag: 'Protein',
    },
    option29: {
        name: 'Green apple',
        image: foodAppleGreen,
        tag: 'Fruits',
    },
    option30: {
        name: 'Red apple',
        image: foodAppleRed,
        tag: 'Fruits',
    },
    option31: {
        name: 'Apricots',
        image: foodApricots,
        tag: 'Fruits',
    },
    option32: {
        name: 'Blackberry',
        image: foodBlackberry,
        tag: 'Fruits',
    },
    option33: {
        name: 'Blueberry',
        image: foodBlueberry,
        tag: 'Fruits',
    },
    option34: {
        name: 'Cloudberry',
        image: foodCloudberry,
        tag: 'Fruits',
    },
    option35: {
        name: 'Cranberry',
        image: foodCranberry,
        tag: 'Fruits',
    },
    option36: {
        name: 'Date',
        image: foodDate,
        tag: 'Fruits',
    },
    option37: {
        name: 'Honeydew melon',
        image: foodHoneydew,
        tag: 'Fruits',
    },
    option38: {
        name: 'Prunes',
        image: foodPrunes,
        tag: 'Fruits',
    },
    option39: {
        name: 'Raisin',
        image: foodRaisin,
        tag: 'Fruits',
    },
    option40: {
        name: 'Raspberry',
        image: foodRaspberry,
        tag: 'Fruits',
    },
    option41: {
        name: 'Arugula',
        image: foodArugula,
        tag: 'Vegetables',
    },
    option42: {
        name: 'Bell pepper',
        image: foodBellPepper,
        tag: 'Vegetables',
        variations: [
            'Option #1',
            'Option #2',
        ],
    },
    option43: {
        name: 'Carrot',
        image: foodCarrot,
        tag: 'Vegetables',
        variations: [
            'Option #1',
            'Option #2',
        ],
    },
    option44: {
        name: 'Eggplant',
        image: foodEggplant,
        tag: 'Vegetables',
        variations: [
            'Option #1',
            'Option #2',
        ],
    },
    option45: {
        name: 'Green onion',
        image: foodGreenOnion,
        tag: 'Vegetables',
    },
    option46: {
        name: 'Kale',
        image: foodKale,
        tag: 'Vegetables',
    },
    option47: {
        name: 'Onion',
        image: foodOnion,
        tag: 'Vegetables',
        variations: [
            'Option #1',
            'Option #2',
        ],
    },
    option48: {
        name: 'Spinach',
        image: foodSpinach,
        tag: 'Vegetables',
    },

    option49: {
        name: 'Codler',
        image: foodCodler,
        tag: 'Dishes',
    },
    option50: {
        name: 'Honey',
        image: foodHoney,
        tag: 'Dishes',
    },
    option51: {
        name: 'Jar of medicine',
        image: foodMedicine,
        tag: 'Dishes',
    },

    option52: {
        name: 'Pills',
        image: foodPills,
        tag: 'Dishes',
    },
    option53: {
        name: 'Plate',
        image: foodPlate,
        tag: 'Dishes',
        variations: [
            'Option #1',
            'Option #2',
            'Option #3',
            'Option #4',
        ],
    },
    option54: {
        name: 'Glass of water',
        image: foodWaterGlass,
        tag: 'Dishes',
    },

};
export const MEALS = {
    option1: {
        name: 'Meal #1',
        image: meal1,
    },
    option2: {
        name: 'Meal #2',
        image: meal2,
    },
    option3: {
        name: 'Meal #3',
        image: meal3,
    },
    option4: {
        name: 'Meal #4',
        image: meal4,
    },
    option5: {
        name: 'Meal #5',
        image: meal5,
    },
    option6: {
        name: 'Meal #6',
        image: meal6,
    },
    option7: {
        name: 'Meal #7',
        image: meal7,
    },
    option8: {
        name: 'Meal #8',
        image: meal8,
    },
    option9: {
        name: 'Meal #9',
        image: meal9,
    },
};
export const KITCHEN = {
    option1: {
        name: 'Blender #1',
        image: kitchenBlender1,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
        ],
    },
    option2: {
        name: 'Blender #2',
        image: kitchenBlender2,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
        ],
    },
    option3: {
        name: 'Pot #1',
        image: kitchenPot1,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option4: {
        name: 'Pot #2',
        image: kitchenPot2,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option5: {
        name: 'Mincer',
        image: kitchenMincer,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option6: {
        name: 'Fridge',
        image: kitchenFridge,
    },
    option7: {
        name: 'Cooker',
        image: kitchenCooker,
    },

    option8: {
        name: 'Hood',
        image: kitchenHood,
    },
    option9: {
        name: 'Table',
        image: kitchenTable,
    },
    option10: {
        name: 'Window',
        image: kitchenWindow,
    },
    option11: {
        name: 'Plant',
        image: kitchenPlant,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
        ],
    },
};

export const OUTDOOR = {
    option1: {
        name: 'Bench #1',
        image: outdoorBench1,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ]
    },
    option2: {
        name: 'Bench #2',
        image: outdoorBench2,
        outline: [
            'Outline #1',
        ],
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ]
    },
    option3: {
        name: 'Building',
        image: outdoorBuilding,
    },
    option4: {
        name: 'Bush',
        image: outdoorBush,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
        ],
    },
    option5: {
        name: 'Trash can',
        image: outdoorTrash,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
            'Outline #4',
            'Outline #5',
        ],
    },
    option6: {
        name: 'Tree',
        image: outdoorTree,
        outline: [
            'Outline #1',
            'Outline #2',
            'Outline #3',
            'Outline #4',
        ],
    },
};

export const ACCESSORIES = {
    option1: {
        name: 'Bag #1',
        image: accessoriesBag1,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
        ],
    },
    option2: {
        name: 'Bag #2',
        image: accessoriesBag2,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option3: {
        name: 'Bag #3',
        image: accessoriesBag3,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option4: {
        name: 'Sunglasses #1',
        image: accessoriesSunglasses1,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option5: {
        name: 'Sunglasses #2',
        image: accessoriesSunglasses2,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
            'Color #5',
            'Color #6',
        ],
    },
    option6: {
        name: 'Wheelchair #1',
        image: accessoriesWheelchair1,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option7: {
        name: 'Wheelchair #2',
        image: accessoriesWheelchair2,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option8: {
        name: 'Bicycle',
        image: accessoriesBicycle,
        colorful: [
            'Color #1',
            'Color #2',
            'Color #3',
            'Color #4',
        ],
    },
    option9: {
        name: 'Helmet',
        image: accessoriesHelmet,
        colorful: [
            'Color #1',
            'Color #2',
        ],
    },
};

export const BACKGROUND = {
    option1: {
        name: 'Outdoors #1',
        image: backgroundOutdoors1,
    },
    option2: {
        name: 'Outdoors #2',
        image: backgroundOutdoors2,
    },
    option3: {
        name: 'Outdoors #3',
        image: backgroundOutdoors3,
    },
    option4: {
        name: 'Outdoors #4',
        image: backgroundOutdoors4,
    },
    option5: {
        name: 'Outdoors #5',
        image: backgroundOutdoors5,
    },
    option6: {
        name: 'Outdoors #6',
        image: backgroundOutdoors6,
    },
    option7: {
        name: 'Office #1',
        image: backgroundOffice1,
    },
    option8: {
        name: 'Office #2',
        image: backgroundOffice2,
    },
    option9: {
        name: 'Office #3',
        image: backgroundOffice3,
    },
    option10: {
        name: 'Office #4',
        image: backgroundOffice4,
    },
    option11: {
        name: 'Office #5',
        image: backgroundOffice5,
    },
    option12: {
        name: 'Office #6',
        image: backgroundOffice6,
    },
    option13: {
        name: 'Kitchen #1',
        image: backgroundKitchen1,
    },
    option14: {
        name: 'Kitchen #2',
        image: backgroundKitchen2,
    },
    option15: {
        name: 'Kitchen #3',
        image: backgroundKitchen3,
    },
    option16: {
        name: 'Kitchen #4',
        image: backgroundKitchen4,
    },
    option17: {
        name: 'Kitchen #5',
        image: backgroundKitchen5,
    },
};


export type TDevices = keyof typeof DEVICES;
export type TDoctorsOffice = keyof typeof DOCTORS_OFFICE;
export type TFoodItems = keyof typeof FOOD_ITEMS;
export type TMeals = keyof typeof MEALS;
export type TKitchen = keyof typeof KITCHEN;
export type TOutdoor = keyof typeof OUTDOOR;
export type TBackground = keyof typeof BACKGROUND;
export type TAccessories = keyof typeof ACCESSORIES
export type TObjects = TDevices | TDoctorsOffice | TFoodItems | TMeals
    | TKitchen | TOutdoor | TBackground | TAccessories;
export type TObjectSettings = { [key in typeof SETTING_TYPES as string]: TObjects }
interface IVariation {
    variation?: string,
}
export type SVG = SVGProps<SVGSVGElement> & IVariation
