import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m518.391 839.578 6.51 31.265 5.005-.1s1.369-9.986 1.893-13.996c.382-2.918 1.402-5.023 4.243-4.258l1.466-1.571M577.563 849.117s-6.831 7.663-11.458 22.41l7.587.541a54.414 54.414 0 0 0 11.105-.91 105.884 105.884 0 0 1 4.116-17.559l-11.35-4.482Z"
                fill="#EF0025"
            />
            <path
                d="m524.9 870.85 5.006-.1"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m525.365 817.658 32.428.375-2.747-4.627H519.19l-.322 4.177-1.818 17.276a13.893 13.893 0 0 0 10.94 15.076l11.441 2.797c-7.888-9.323-3.548-26.752-14.066-35.074Z"
                fill="#EF0025"
            />
            <path
                d="M599.529 866.226a6.413 6.413 0 0 1-3.688 2.404l-11.043 2.527a38.893 38.893 0 0 1-11.105.91l-5.482-.341a24.336 24.336 0 0 1-15.705-7.08l-7.387-7.382a22.325 22.325 0 0 0-11.138-6.052l-5.991-1.278a15.925 15.925 0 0 1-8.892-6.207"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m297.24 828.594 2.335 33.822 5.801.274 1.583-18.262"
                fill="#EF0025"
            />
            <path
                d="m299.574 862.422 5.856-.024"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M354.479 857.127c-2.476-2.332-5.205-8.784-6.994-12.308-4.651-1.373-31.067-3.09-34.784 12.272 1.244 1.805 3.856 5.882 5.868 7.825 2.481 2.396 4.283 2.494 4.283 2.494 5.784-10.699 19.265-10.338 31.627-10.283Z"
                fill="url(#female-34-25-shoes-11-walk-1-a)"
            />
            <path
                d="m341.357 813.153-.894-4.798c-15.181 4.229-33.732-4.293-33.732-4.293l-1.799 3.446-.025-.038c-2.217 4.439-4.892 10.194-5.866 13.924l-.067.255c-.825 3.143-2.072 7.894.734 13.053 1.62 2.979 4.23 7.342 6.899 11.694.213-.402.451-.794.632-1.212 7.294-16.85 2.822-28.905-.868-35.347 21.096 8.281 34.986 3.316 34.986 3.316Z"
                fill="url(#female-34-25-shoes-11-walk-1-b)"
            />
            <path
                d="M363.038 867.133a6.507 6.507 0 0 1-2.406 3.288 6.508 6.508 0 0 1-3.891 1.21h-22.506a21.235 21.235 0 0 1-18.055-9.96c-.116-.181-12.025-18.79-16.472-26.969-2.806-5.159-1.559-9.91-.734-13.054"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-11-walk-1-a"
                    x1={303.769}
                    y1={855.685}
                    x2={354.479}
                    y2={855.685}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-11-walk-1-b"
                    x1={297.973}
                    y1={825.229}
                    x2={457.085}
                    y2={825.229}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
