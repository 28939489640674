import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#888B8D"
            d="M581.314 637.189c-1.069-9.526-7.398-86.881-8.267-101.55-1.118-17.887 1.845-37.37 6.235-57.14h-73.383c-33.206-6.669-53.489-39.326-42.303-71.703 9.253-25.979 34.969-39.617 61.917-35.507 21.366 2.531 42.349 8.032 60.266 16.649 13.79 6.309 26.029 11.741 40.907 21.131 2 1.28 5.352 2.871 7.396 4.131 11.494 7.084 18.153 17.697 15.858 30.687a130005.95 130005.95 0 01-12.69 73.935c-2.024 11.337-10.614 101.98-11.409 123.114-8.81-.982-34.699-2.717-44.527-3.747z"
        ></path>
    </g>
);

export default SvgComponent;
