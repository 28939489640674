import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 168,
        height: 135,
        content: (
            <>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19.662 17.447a27.005 27.005 0 0 1 15.295-4.74l39.712-.16V1.232H93.51v11.315l39.712.16c11.777 0 22.2 7.62 25.773 18.841M151.229 64.138v58.968c0 5.523-4.477 10-10 10H26.955c-5.523 0-10-4.477-10-10V31.549h142.042M151.227 50.39H1.416M166.765 50.39h-15.536"
                />
            </>
        ),
    },
    'Color #1': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M158.9 30.548a27.049 27.049 0 0 0-25.773-18.841H34.863c-11.777 0-22.2 7.62-25.773 18.841H158.9h-.001Z"
                />
                <path fill="#63666A" d="M93.414 11.547V.232h-18.84v11.315h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M16.855 30.549H151.13v91.558c0 5.519-4.481 10-10 10H26.856c-5.52 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.857 49.389H1.32M166.67 49.389h-15.537M151.129 30.549v91.558c0 5.523-4.477 10-10 10H26.856c-5.524 0-10-4.477-10-10M9.09 30.548H158.9a27.049 27.049 0 0 0-25.773-18.841H34.864M16.855 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.174}
                        x2={24.45}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.478}
                        x2={46.956}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M158.937 30.548a27.049 27.049 0 0 0-25.773-18.841H34.9c-11.777 0-22.2 7.62-25.773 18.841h149.811-.001Z"
                />
                <path fill="#63666A" d="M93.451 11.547V.232h-18.84v11.316h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M16.895 30.549h134.274v91.558c0 5.519-4.481 10-10 10H26.894c-5.518 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.894 49.389H1.357M166.704 49.389h-15.536M151.169 30.549v91.558c0 5.523-4.477 10-10 10H26.894c-5.522 0-10-4.477-10-10M9.125 30.548h149.811a27.049 27.049 0 0 0-25.773-18.841H34.899M16.895 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.211}
                        x2={24.487}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#50D5F9" />
                        <stop offset={0.833} stopColor="#D2F0FA" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.518}
                        x2={46.995}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#50D5F9" />
                        <stop offset={0.833} stopColor="#D2F0FA" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M158.976 30.548a27.049 27.049 0 0 0-25.773-18.841H34.939c-11.777 0-22.2 7.62-25.773 18.841h149.811-.001Z"
                />
                <path fill="#63666A" d="M93.492 11.547V.232h-18.84v11.315h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M16.934 30.549h134.274v91.558c0 5.519-4.481 10-10 10H26.934c-5.52 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.933 49.389H1.396M166.744 49.389h-15.537M151.208 30.549v91.558c0 5.523-4.477 10-10 10H26.934c-5.523 0-10-4.477-10-10M9.166 30.548h149.811a27.049 27.049 0 0 0-25.773-18.841H34.94M16.934 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.25}
                        x2={24.527}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3ACEC7" />
                        <stop offset={0.833} stopColor="#BBE9E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.557}
                        x2={47.035}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3ACEC7" />
                        <stop offset={0.833} stopColor="#BBE9E9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M159.015 30.548a27.049 27.049 0 0 0-25.773-18.841H34.978c-11.777 0-22.2 7.62-25.773 18.841h149.811-.001Z"
                />
                <path fill="#63666A" d="M93.53 11.547V.232H74.687v11.315H93.53Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M16.973 30.549h134.274v91.558c0 5.519-4.481 10-10 10H26.973c-5.52 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.973 49.389H1.435M166.783 49.389h-15.537M151.245 30.549v91.558c0 5.523-4.477 10-10 10H26.971c-5.523 0-10-4.477-10-10M9.203 30.548h149.811a27.049 27.049 0 0 0-25.773-18.841H34.977M16.973 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.288}
                        x2={24.565}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.596}
                        x2={47.073}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M159.054 30.548a27.049 27.049 0 0 0-25.773-18.841H35.017c-11.777 0-22.2 7.62-25.773 18.841h149.811-.001Z"
                />
                <path fill="#63666A" d="M93.568 11.547V.232h-18.84v11.315h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M17.01 30.549h134.274v91.558c0 5.519-4.481 10-10 10H27.01c-5.52 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.01 49.389H1.476M166.822 49.389h-15.537M151.284 30.549v91.558c0 5.523-4.477 10-10 10H27.01c-5.523 0-10-4.477-10-10M9.242 30.548h149.811a27.049 27.049 0 0 0-25.773-18.841H35.016M17.01 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.327}
                        x2={24.604}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.633}
                        x2={47.111}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 168,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-2-a)"
                    d="M159.092 30.548a27.049 27.049 0 0 0-25.773-18.841H35.054c-11.777 0-22.2 7.62-25.773 18.841h149.811Z"
                />
                <path fill="#63666A" d="M93.607 11.547V.232h-18.84v11.315h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-2-b)"
                    d="M17.05 30.549h134.275v91.558c0 5.519-4.481 10-10 10H27.051c-5.52 0-10-4.481-10-10V30.549Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.05 49.389H1.515M166.86 49.389h-15.536M151.325 30.549v91.558c0 5.523-4.477 10-10 10H27.051c-5.523 0-10-4.477-10-10M9.281 30.548h149.811a27.049 27.049 0 0 0-25.773-18.841H35.054M17.05 104.191V30.549"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-2-a"
                        x1={140.366}
                        x2={24.642}
                        y1={21.127}
                        y2={21.127}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-2-b"
                        x1={154.674}
                        x2={47.151}
                        y1={153.761}
                        y2={38.649}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-kitchen-pot-2"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
