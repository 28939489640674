import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-both-arms-option-1-left-cycling">
        <path
            d="M432.099 248.999a238.868 238.868 0 0 0-16.095-22.844c-1.74-2.19-3.49-4.373-5.248-6.55-3.99-4.958-8.115-10.085-11.999-15.252l-.084-.112a31.773 31.773 0 0 0-25.516-12.659 31.714 31.714 0 0 0-19.319 6.51 32.04 32.04 0 0 0-6.15 44.836c3.905 5.146 7.707 10.514 11.384 15.707 1.618 2.284 3.24 4.564 4.865 6.84a239.375 239.375 0 0 0 17.567 21.728 506.234 506.234 0 0 0 15.578 16.672l46.014-36.697a511.02 511.02 0 0 0-10.997-18.179Z"
            fill="url(#man-34-25-shirt-2-both-arms-option-1-left-cycling-a)"
        />
        <path
            d="M347.688 242.929c3.906 5.146 7.707 10.515 11.384 15.707 1.618 2.285 3.24 4.565 4.865 6.84a239.27 239.27 0 0 0 17.567 21.728 507.379 507.379 0 0 0 15.578 16.673l46.014-36.697A510.97 510.97 0 0 0 432.1 249a239.004 239.004 0 0 0-16.096-22.844c-1.74-2.19-3.489-4.373-5.248-6.55-3.989-4.958-8.114-10.085-11.999-15.252l-.084-.112"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-both-arms-option-1-left-cycling-a"
                x1={333.969}
                y1={215.271}
                x2={418.547}
                y2={264.716}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={0.739} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
