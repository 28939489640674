import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-normal">
        <path
            d="M347.504 452.609a1.35 1.35 0 0 1-1.337-1.183c-2.842-23.193 1.118-43.655 4.948-63.443 4.443-22.958 8.641-44.642 2.106-68.775a327.298 327.298 0 0 1-10.75-77.507l-.457-19.059c-.373-15.562 4.385-28.572 17.89-40.156 19.248-16.51 43.568-25.857 46.988-26.838 18.543 14.639 35.404 20.657 35.404 20.657l21.867 16.265c1.119-8.161 2.004-23.46 1.79-25.527.253 0 5.852 3.693 8.127 5.355 27.167 19.853 46.641 61.31 52.534 82.778 2.146 7.817 2.363 14.493.645 19.842a44.418 44.418 0 0 0 .987 30.576 107.609 107.609 0 0 1 8.688 40.258c.277 11.02.03 95.793.001 105.423a1.344 1.344 0 0 1-1.344 1.334H347.504Z"
            fill="url(#man-34-25-shirt-1-normal-a)"
        />
        <path
            d="M406.893 156.141a115.106 115.106 0 0 0 35.469 20.233l24.553 18.979 8-19.516 7.309 6.637"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m442.361 176.368-9.444 13.073-20.923-22.629M347.504 452.608a1.35 1.35 0 0 1-1.337-1.184c-2.842-23.192 1.118-43.654 4.948-63.442 4.443-22.958 8.641-44.643 2.106-68.776a327.283 327.283 0 0 1-10.75-77.506l-.457-19.059c-.373-15.563 4.385-28.572 17.89-40.157M501.432 297.508l5.832 26.725a105.342 105.342 0 0 1 2.422 22.916l-.459 105.462M469.783 199.781c3.161 3.161 17.09 31.628 24.628 47.395a53.61 53.61 0 0 1 5.213 21.442l.602 19.132M498.041 197.328c14.484 19.882 24.608 43.398 28.575 57.852 2.147 7.818 2.364 14.493.646 19.843a44.41 44.41 0 0 0 .987 30.575 107.61 107.61 0 0 1 8.688 40.259c.277 11.019.03 95.793.001 105.423a1.343 1.343 0 0 1-1.344 1.334"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-normal-a"
                x1={542.971}
                y1={302.401}
                x2={326.971}
                y2={307.605}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
