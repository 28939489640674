import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-3-stand-1">
            <path
                fill="#2B2729"
                d="M456.406 798.699c-.389 4.322-4.629 20.354-7.464 30.744a8.99 8.99 0 0 0 3.49 9.716 8.99 8.99 0 0 0 3.336 1.454l19.892 4.197a42.181 42.181 0 0 1 12.113 4.528 69.645 69.645 0 0 0 19.452 7.231c4.008.874 19.038 1.815 26.247 2.228a4.152 4.152 0 0 0 4.361-3.661 4.153 4.153 0 0 0-1.929-4.014c-2.853-1.771-7.908-4.872-12.021-7.188-18.465-10.397-35.661-38.523-35.661-38.523"
            />
            <path
                fill="#63666A"
                d="M533.712 859.803c-.098 0-.198-.003-.298-.009-8.148-.467-22.414-1.38-26.403-2.248a70.766 70.766 0 0 1-19.732-7.339 41.136 41.136 0 0 0-11.826-4.42l-19.892-4.196a9.912 9.912 0 0 1-6.472-4.573 9.9 9.9 0 0 1-1.112-7.837 1087.31 1087.31 0 0 0 3.303-12.374 1 1 0 0 1 1.143-.733 65.38 65.38 0 0 1 11.528 3.582c.653.247 1.304.495 1.956.738 5.89 2.198 12.155 4.014 18.034 2.433a32.968 32.968 0 0 0 6.509-2.804c1.051-.543 2.138-1.104 3.241-1.605a24.23 24.23 0 0 1 3.152-1.18 1.003 1.003 0 0 1 1.079.352c6.454 8.46 16.283 19.746 26.45 25.472 4.184 2.355 9.351 5.528 12.059 7.21a5.16 5.16 0 0 1 2.395 4.98 5.16 5.16 0 0 1-5.114 4.551Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M537.253 858.388a5.145 5.145 0 0 1-3.838 1.407c-8.149-.466-22.414-1.38-26.404-2.248a70.748 70.748 0 0 1-19.731-7.339 41.136 41.136 0 0 0-11.826-4.42l-19.892-4.196a9.912 9.912 0 0 1-6.472-4.572"
            />
            <path
                fill="#2B2729"
                d="M396.14 817.871c.512 4.31-.328 20.872-.957 31.623a9.007 9.007 0 0 0 2.438 6.699 8.993 8.993 0 0 0 6.547 2.821h57.647a4.442 4.442 0 0 0 4.445-4.445 4.435 4.435 0 0 0-2.34-3.915c-3.792-2.032-11.23-6.09-14.957-8.599-8.486-5.714-20.307-24.184-20.307-24.184"
            />
            <path
                fill="url(#man-34-25-shoes-3-stand-1-a)"
                d="M461.817 860.011H404.17a9.903 9.903 0 0 1-7.277-3.138 9.895 9.895 0 0 1-2.706-7.44c.193-3.288.404-7.12.591-10.98a1.002 1.002 0 0 1 1.23-.925 15.028 15.028 0 0 0 12.593-2.602 30.379 30.379 0 0 0 3.72-3.648 39.733 39.733 0 0 1 3.132-3.186 21.116 21.116 0 0 1 18.02-4.47c.245.051.46.192.606.395 4.311 6.008 10.492 13.871 15.446 17.207 3.659 2.464 10.888 6.412 14.871 8.546a5.433 5.433 0 0 1 2.867 4.797 5.448 5.448 0 0 1-5.446 5.444Z"
            />
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M466.024 858.021a5.459 5.459 0 0 1-1.88 1.47 5.45 5.45 0 0 1-2.329.523h-57.647a9.9 9.9 0 0 1-7.277-3.139"
            />
            <defs>
                <linearGradient
                    id="man-34-25-shoes-3-stand-1-a"
                    x1="394.17"
                    x2="467.263"
                    y1="841.587"
                    y2="841.587"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset=".299" stopColor="#63666A" />
                    <stop offset="1" stopColor="#8E8B8F" />
                </linearGradient>
            </defs>
        </g>
    );
}
