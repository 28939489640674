import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M477.842 236.226c-.165-4.364-.927-19.194-3.775-26.829l-1.016-2.725 2.477 1.524a102.538 102.538 0 0 1 18.86 14.537 2.7 2.7 0 0 1 .821 2.483c-.786 4.558-13.419 10.693-15.943 11.877l-1.366.641-.058-1.508Z"
            fill="url(#female-34-25-shirt-1-arm-opt-5-left-a)"
        />
        <path
            d="M557.963 313.111c-1.194-.123-6.85-.756-8.041-.848l-3.57-.264c-2.385-.219-4.748-.226-7.122-.336a83.417 83.417 0 0 0-10.941.295c-.56-2.93-1.167-5.84-1.802-8.74-1.729-7.788-3.728-15.471-5.921-23.077a182.2 182.2 0 0 0-7.807-22.34c-3.07-7.264-6.306-14.463-9.158-21.812l-.048-.122a26.51 26.51 0 0 0-14.089-14.591 26.502 26.502 0 0 0-35.224 34.014c2.889 7.335 5.395 14.819 8.066 22.239a182.205 182.205 0 0 0 9.419 21.71c3.548 7.077 7.29 14.077 11.302 20.973a226.866 226.866 0 0 0 13.098 20.245 18.49 18.49 0 0 0 18.569 10.52c4.68-.47 9.393-.609 14.092-.89a82.454 82.454 0 0 0 13.976-2.082c2.308-.565 4.626-1.027 6.924-1.701l3.452-.947c1.15-.32 7.292-2.402 8.439-2.752-15.47-4.554-12.795-27.687-3.614-29.494Z"
            fill="url(#female-34-25-shirt-1-arm-opt-5-left-b)"
        />
        <path
            d="M556.579 344.305c-1.147.35-2.291.738-3.441 1.057l-3.453.947c-2.297.674-4.616 1.136-6.924 1.701a82.322 82.322 0 0 1-13.976 2.081c-4.699.282-9.412.421-14.092.892a18.489 18.489 0 0 1-16.08-6.581M525.113 312.316a82.443 82.443 0 0 1 14.116-.649c2.374.11 4.737.117 7.122.336l3.57.264c1.19.092 2.387.253 3.581.375M527.282 306.933a381.907 381.907 0 0 0-.795-3.711c-1.729-7.788-3.728-15.471-5.921-23.077a182.033 182.033 0 0 0-7.807-22.34c-3.07-7.264-9.206-21.934-9.206-21.934-1.709-5.537-6.889-9.957-6.889-9.957"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M553.138 345.36c1.15-.32 7.292-2.402 8.439-2.752-15.47-4.555-12.795-27.687-3.614-29.494-1.194-.123-6.85-.756-8.041-.848M485.781 215.219a98.817 98.817 0 0 1 8.608 7.515"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-5-left-a"
                x1={488.847}
                y1={208.651}
                x2={416.414}
                y2={324.458}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-5-left-b"
                x1={538.59}
                y1={239.759}
                x2={466.156}
                y2={355.567}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
