import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7339)"
            d="M494.768 223.336a109.59 109.59 0 00-20.026-15.328 31.03 31.03 0 012.394 13.894 65.926 65.926 0 01-1.451 10.763l2.169 4.754a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.483z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7339)"
            d="M463.278 269.778a300.604 300.604 0 00-4.603-5.338 27.32 27.32 0 01-6.867-19.895 27.306 27.306 0 019.213-18.924 27.465 27.465 0 0118.228-6.918 27.528 27.528 0 0120.592 9.263l.085.095a314.14 314.14 0 005.371 5.938l.708.766-42.08 35.78-.647-.767z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7339"
                x1="490.266"
                x2="431.279"
                y1="214.323"
                y2="256.396"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7339"
                x1="496.808"
                x2="437.82"
                y1="223.5"
                y2="265.572"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
