import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9068)"
            d="M494.776 223.22a2.704 2.704 0 01.822 2.483c-.805 4.666-13.808 10.892-16.406 12.093l-.912.421s-.585-24.988-5.356-31.35a108.178 108.178 0 0121.852 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9068)"
            d="M511.144 245.484c-2.546-4.043-5.433-8.626-8.066-13.178l-.071-.122a27.478 27.478 0 00-16.772-12.719 27.497 27.497 0 00-20.852 2.876 27.5 27.5 0 00-9.843 37.624c2.655 4.537 5.185 9.26 7.633 13.828l.509.95a91.181 91.181 0 0121.385-17.957 93.21 93.21 0 0126.625-10.432l-.548-.87z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9068"
                x1="487.171"
                x2="466.569"
                y1="205.217"
                y2="283.723"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9068"
                x1="491.713"
                x2="471.11"
                y1="206.412"
                y2="284.918"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
