import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11325_7985)"
            d="M512.776 324.611c8.094-3.299 18.171-9.259 20.658-19.014 1.943-7.615-1.083-16.221-8.991-25.578-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.786-9.422-18.228-14.919-23.949-.141-.146-1.35-.955-2.613-1.739a60.491 60.491 0 00-1.123-.68 25.017 25.017 0 00-.988-.555c4.065 7.051 4.95 18.802-6.423 23.57a21.986 21.986 0 01-8.678 1.436c-10.921 0-21.127-6.506-31.939-13.399-7.282-4.644-14.812-9.444-22.911-12.429a32.696 32.696 0 00-10.802-1.712c-.773 0-1.529.025-2.263.074-.25.016-.482.052-.727.074-.472.043-.95.08-1.4.144h-.006a1.177 1.177 0 00-.407.147c-.012.007-.027.007-.04.014a60.859 60.859 0 00-11.736 9.223 63.154 63.154 0 00-1.928 2.017 63.353 63.353 0 00-2.443 2.825c-.029.037-.061.071-.09.108a62.78 62.78 0 00-6.996 10.812c-.048.095-.102.187-.149.282a63.117 63.117 0 00-4.418 11.42 61.457 61.457 0 00-.795 3.114c-.062.276-.12.553-.178.83a62.192 62.192 0 002.214 33.804l.005.015 18.045 51.941c4.368 12.278 2.884 26.719-5.591 35.753l-.01.012c-20.638 21.97-25.615 45.361-27.936 63.292 14.883 0 179.194.875 179.224.714 5.78-30.447 3.987-52.793-.24-70.673a118.787 118.787 0 00-9.073-28.014l-.001-.002a530.578 530.578 0 01-2.682-7.102l-.002-.004z"
        ></path>
        <g filter="url(#filter0_f_11325_7985)">
            <path
                fill="url(#paint1_radial_11325_7985)"
                d="M410.675 290.993l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.265 14.814 1.029 21.605-1.697a30.978 30.978 0 0012.048-48.825l-6.917-10.635"
            ></path>
        </g>
        <g filter="url(#filter1_f_11325_7985)">
            <path
                fill="url(#paint2_linear_11325_7985)"
                d="M388.658 367.343c13.267 13.844 34.127 18.962 52.351 22.249 23.308 4.205 46.655 4.704 69.798-.858 13.135-3.157 7.573-23.412-5.582-20.25-19.304 4.64-39.161 4.371-58.634.858-14.484-2.613-32.603-5.913-43.084-16.848-9.369-9.776-24.206 5.087-14.849 14.849z"
            ></path>
            <path
                fill="url(#paint3_linear_11325_7985)"
                d="M396.414 363.649c15.42 2.199 30.855 3.182 46.395 1.659 15.547-1.523 30.197-6.012 45.123-10.361 12.949-3.773 7.44-24.044-5.582-20.25-12.987 3.784-26.008 8.285-39.54 9.61a171.388 171.388 0 01-40.813-.909c-5.607-.8-11.28 1.377-12.917 7.334-1.353 4.925 1.684 12.111 7.334 12.917z"
            ></path>
        </g>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M462.563 231.227a239.606 239.606 0 0016.766 23.121M403.2 205.508c8.1 2.984 15.629 7.785 22.911 12.429 10.813 6.892 21.019 13.399 31.94 13.399a20.755 20.755 0 008.678-1.436c10.512-4.721 10.92-12.99 8.364-19.636a11.584 11.584 0 00-1.207-2.727"
        ></path>
        <defs>
            <filter
                id="filter0_f_11325_7985"
                width="91.355"
                height="77.898"
                x="402.675"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11325_7985"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <filter
                id="filter1_f_11325_7985"
                width="148.626"
                height="74.602"
                x="377.679"
                y="326.227"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11325_7985"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11325_7985"
                x1="266.347"
                x2="639.117"
                y1="337.304"
                y2="297.953"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11325_7985"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 988.98 -1072.034) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.433" stopColor="#009CDE" stopOpacity="0"></stop>
                <stop offset="0.578" stopColor="#009CDE" stopOpacity="0.318"></stop>
                <stop offset="0.757" stopColor="#009CDE" stopOpacity="0.682"></stop>
                <stop offset="0.884" stopColor="#009CDE" stopOpacity="0.912"></stop>
                <stop offset="0.946" stopColor="#009CDE"></stop>
            </radialGradient>
            <linearGradient
                id="paint2_linear_11325_7985"
                x1="395.714"
                x2="508.137"
                y1="371.062"
                y2="371.062"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="1" stopColor="#009CDE" stopOpacity="0"></stop>
            </linearGradient>
            <linearGradient
                id="paint3_linear_11325_7985"
                x1="399.132"
                x2="485.28"
                y1="350.135"
                y2="350.135"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="1" stopColor="#009CDE" stopOpacity="0"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
