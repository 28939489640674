import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-6">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-6-a)"
            d="M415.501 283.755c-6.786-4.233-13.565-8.78-20.728-13.905a189.248 189.248 0 0 1-19.359-15.569 892.685 892.685 0 0 1-5.461-5.056c-4.141-3.85-8.422-7.831-12.766-11.581a31.795 31.795 0 0 1-11.007-21.879 31.796 31.796 0 0 1 7.688-23.254 32.001 32.001 0 0 1 24.241-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.324 3.769 8.872 7.447 13.271 11.004a1735.31 1735.31 0 0 1 5.772 4.689 189.111 189.111 0 0 1 18.153 16.969c6.083 6.366 11.543 12.435 16.686 18.548-9.093 4.316-32.515 25.569-37.507 43.244Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-6-a"
                x1={444.926}
                x2={337.36}
                y1={265.561}
                y2={184.886}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
