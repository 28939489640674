import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_11242_6491)"
                d="M607.401 682.402c-6.008-1.882-12.114-5.874-17.565-10.335-16.992 7.082-31.812 4.913-52.053-1.52-.454 3.246-.574 4.273-1.182 8.28a9.017 9.017 0 00.187 3.674 9.027 9.027 0 001.641 3.292 9.015 9.015 0 006.332 3.453l6.968.645a132.916 132.916 0 0127.285 5.591 70.47 70.47 0 0020.931 3.042 81.564 81.564 0 009.451-.555c6.784-.788 11.193-3.39 11.507-6.791.272-2.932-4.272-5.885-13.502-8.776z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M538.429 685.789a9.025 9.025 0 006.332 3.454l6.968.644c9.265.904 18.41 2.778 27.285 5.591a70.4 70.4 0 0020.93 3.042 81.803 81.803 0 009.452-.554c6.783-.788 11.192-3.391 11.507-6.791"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11242_6491"
                    x1="536.495"
                    x2="620.915"
                    y1="684.536"
                    y2="684.536"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#004F71"></stop>
                    <stop offset="0.125" stopColor="#01567A"></stop>
                    <stop offset="1" stopColor="#0B86B8"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
