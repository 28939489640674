import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.495 279.902c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.787-9.422-18.228-14.919-23.949a47.595 47.595 0 0 0-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a21.984 21.984 0 0 1-8.677 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.283-4.644-14.812-9.445-22.912-12.429a32.686 32.686 0 0 0-10.801-1.712 31.608 31.608 0 0 0-4.391.292c-.16.023-.314.078-.452.162a62.748 62.748 0 0 0-26.519 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.882 0 179.194.874 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a523.345 523.345 0 0 1-2.685-7.11c8.093-3.299 18.171-9.259 20.658-19.014 1.943-7.615-1.083-16.221-8.991-25.578Z"
            fill="url(#female-34-25-tshirt-6-a)"
        />
        <g filter="url(#female-34-25-tshirt-6-b)">
            <path
                d="m410.73 290.876 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.264 14.814 1.029 21.605-1.698a31.5 31.5 0 0 0 18.534-21.325 31.511 31.511 0 0 0-6.485-27.5l-6.917-10.635"
                fill="url(#female-34-25-tshirt-6-c)"
            />
        </g>
        <defs>
            <radialGradient
                id="female-34-25-tshirt-6-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.44 -1072.12) scale(77.211 37.1881)"
            >
                <stop offset={0.437} stopColor="#009CDE" stopOpacity={0} />
                <stop offset={0.484} stopColor="#009CDE" stopOpacity={0.102} />
                <stop offset={0.715} stopColor="#009CDE" stopOpacity={0.581} />
                <stop offset={0.875} stopColor="#009CDE" stopOpacity={0.882} />
                <stop offset={0.949} stopColor="#009CDE" />
            </radialGradient>
            <linearGradient
                id="female-34-25-tshirt-6-a"
                x1={345.672}
                y1={336.54}
                x2={533.257}
                y2={303.668}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#21ACDF" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <filter
                id="female-34-25-tshirt-6-b"
                x={402.73}
                y={251.023}
                width={91.305}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7121"
                />
            </filter>
        </defs>
    </g>
);

export default SvgComponent;
