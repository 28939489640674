import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="female-bold-34-25-arm-left-option-1">
        <path
            d="M504.989 237.812c2.245 6.819 4.882 13.508 7.35 20.253a151.139 151.139 0 0 1 5.972 20.712c1.575 7.042 2.951 14.15 4.052 21.35a190.268 190.268 0 0 1 2.208 21.963 19.286 19.286 0 0 1-5.197 13.822 19.307 19.307 0 0 1-13.449 6.099 19.498 19.498 0 0 1-16.465-8.25 190.524 190.524 0 0 1-11.379-18.915c-3.428-6.426-6.581-12.944-9.535-19.528a151.142 151.142 0 0 1-7.615-20.165c-2.061-6.881-3.954-13.818-6.237-20.624a26.502 26.502 0 0 1 36.931-32.152 26.5 26.5 0 0 1 13.318 15.298l.046.137Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m523.216 318.715 5.321 10.655 5.451 10.594a233.777 233.777 0 0 1 9.695 21.769c1.419 3.723 2.93 7.401 4.25 11.172l2.038 5.628c.676 1.878 1.29 3.786 1.937 5.677 2.543 7.588 4.937 15.249 6.826 23.152a12.284 12.284 0 0 1-14.732 14.817 12.281 12.281 0 0 1-6.887-4.395c-5.007-6.401-9.509-13.045-13.861-19.76-1.076-1.685-2.186-3.353-3.235-5.052l-3.133-5.1c-2.128-3.381-4.065-6.855-6.094-10.284a233.695 233.695 0 0 1-10.991-21.143l-4.893-10.863-5.022-10.8a18.5 18.5 0 0 1 22.759-25.318 18.504 18.504 0 0 1 10.571 9.251Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M489.46 333.761a190.524 190.524 0 0 1-11.379-18.915c-3.428-6.426-6.581-12.944-9.535-19.528a151.142 151.142 0 0 1-7.615-20.165c-2.061-6.881-3.954-13.818-6.237-20.624a26.502 26.502 0 0 1 36.931-32.152 26.5 26.5 0 0 1 13.318 15.298l.046.137c2.245 6.819 4.882 13.508 7.35 20.253a151.139 151.139 0 0 1 5.972 20.712c1.575 7.042 2.951 14.15 4.052 21.35.844 5.645 1.569 11.33 1.962 17.124M537.115 417.791c-5.006-6.401-9.508-13.045-13.861-19.76-1.076-1.685-2.186-3.354-3.234-5.052l-3.134-5.1c-2.128-3.381-4.064-6.855-6.093-10.284a233.704 233.704 0 0 1-10.992-21.143l-4.893-10.863-5.021-10.8M525.211 321.531l3.327 7.84 5.45 10.594a233.514 233.514 0 0 1 9.695 21.769c1.419 3.723 2.93 7.401 4.25 11.172l2.038 5.628c.676 1.878 1.29 3.786 1.937 5.677 2.543 7.588 4.937 15.248 6.826 23.152"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M569.16 438.72c-1.887-8.125-5.931-22.708-9.172-30.436-11.058-12.292-20.127-5.641-20.168 11.326-.846 3.619-.215 11.398.921 15.931a45.147 45.147 0 0 1 1.643 10.462c.035 3.534-.973 7.264-3.667 9.752l.7 1.293c2.735 1.254 6.625-.208 8.38-2.571a16.957 16.957 0 0 0 2.578-6.714c.103-.472.474.224.474.224 3.023 2.645 5.284 6.966 6.6 11.619a14.69 14.69 0 0 1-4.724-.45 1.813 1.813 0 0 0-1.368.216l-1.86 1.152a5.712 5.712 0 0 0-2.704 4.955 5.721 5.721 0 0 0 2.868 4.862c3.233 1.816 7.258 2.033 9.427.919l.558 1.128a16.394 16.394 0 0 0 3.047 7.928c.148.193.336.352.551.465a1.677 1.677 0 0 0 1.406.065c.225-.092.427-.232.592-.41l.081-.087a1.72 1.72 0 0 0 .437-1.298c-.317-4.82 2.188-11.214 3.444-15.58 2.318-8.059 1.843-16.627-.044-24.751Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M553.586 441.07a25.293 25.293 0 0 1 6.902 16.777c.063 3.933-.818 7.837-.929 11.77-.11 3.933.684 8.129 3.545 10.978M565.579 477.282c.074-4.75 2.3-9.218 3.623-13.813 2.319-8.059 1.844-16.627-.043-24.751a158.697 158.697 0 0 0-7.557-24.765M539.434 424.711c-.581 4.323.646 7.357 1.307 10.831.661 3.474 1.608 6.928 1.643 10.462.035 3.534-.973 7.264-3.667 9.751M539.861 457.146c2.736 1.253 6.181-.307 7.935-2.67 1.755-2.363 2.27-5.309 2.882-8.126.612-2.816 1.458-5.764 3.65-7.78"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M557.245 457.8a21.45 21.45 0 0 0-6.397-9.816M549.279 469.93c2.842 1.924 7.092 2.727 9.807 1.332M547.807 464.016c2.663 2.156 9.15 2.644 12.18.917M551.994 458.98a10.913 10.913 0 0 0 8.489-.058"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.61 223.979c-8.492-8.307-18.696-15.185-30.909-21.252l15.604 34.205s19.388-8.959 15.305-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.701 202.727a132.13 132.13 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
