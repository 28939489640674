import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-3-both-arms-option-1-right-cycling-2">
        <path
            d="M402.215 283.829a253.013 253.013 0 0 0 1.674-28.701c.007-2.87-.003-5.739-.014-8.609-.023-6.558-.049-13.339.141-19.994l.003-.14a32.04 32.04 0 0 0-32.006-32.768 32.193 32.193 0 0 0-31.976 31.233c-.16 6.652-.539 13.418-.905 19.96-.161 2.871-.317 5.74-.466 8.608-.487 9.577-.43 19.174.171 28.745.089 1.637.337 3.9.286 4.849 32.87 5.124 62.814-.665 62.814-.665.093-.837.187-1.671.278-2.518Z"
            fill="url(#man-34-25-shirt-3-both-arms-option-1-right-cycling-2-a)"
        />
        <path
            d="M340.037 224.852c-.16 6.652-.539 13.418-.905 19.96-.161 2.871-.317 5.74-.466 8.608-.487 9.577-.43 19.174.171 28.745.089 1.637.337 3.9.286 4.849 32.87 5.124 62.814-.665 62.814-.665.093-.837.187-1.671.278-2.518a253.013 253.013 0 0 0 1.674-28.701c.007-2.87-.003-5.739-.014-8.61-.023-6.557-.049-13.338.141-19.993l.003-.14"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-3-both-arms-option-1-right-cycling-2-a"
                x1={341.769}
                y1={186.219}
                x2={380.155}
                y2={272.098}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
