import React from 'react';

const SvgComponent = () => (
    <g id="female-34-25-dress">
        <path
            d="M523.96 280.183c-20.41-24.152-26.326-32.894-31.649-46.771-2.986-7.786-9.421-18.228-14.918-23.949a47.493 47.493 0 0 0-4.724-2.974c4.065 7.051 4.949 18.802-6.423 23.57a22.008 22.008 0 0 1-8.678 1.437c-10.921 0-21.127-6.507-31.939-13.4-7.283-4.643-14.812-9.444-22.912-12.429a32.69 32.69 0 0 0-10.801-1.712 31.517 31.517 0 0 0-4.396.293 1.194 1.194 0 0 0-.448.161 62.752 62.752 0 0 0-26.518 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-27.937 53.587-27.937 71.668 0 34.798 1.581 70.306 22.467 115.37 35.183 75.911 117.893 127.936 171.63 127.936 7.33-130.427-5.759-209.859-11.923-238.666a77.093 77.093 0 0 1-1.231-24.424c2.036-18.848 3.521-56.173-11.03-86.565-1.14-2.38-1.843-4.812-2.686-7.11 8.094-3.299 18.171-9.26 20.658-19.014 1.942-7.614-1.083-16.22-8.991-25.578Z"
            fill="url(#female-34-25-dress-a)"
        />
        <path
            d="M402.717 205.664c8.1 2.984 15.629 7.785 22.911 12.429 10.812 6.892 21.018 13.399 31.939 13.399a20.74 20.74 0 0 0 8.678-1.436c10.512-4.721 10.92-12.989 8.364-19.636a11.577 11.577 0 0 0-1.207-2.726"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-b)">
            <path
                d="m410.191 291.149 5.08 9.293c5.358 11.03 17.156 17.894 29.234 20.012 7.208 1.265 14.814 1.03 21.605-1.697a31.513 31.513 0 0 0 18.534-21.326 31.506 31.506 0 0 0-6.485-27.499l-6.917-10.635"
                fill="url(#female-34-25-dress-c)"
            />
        </g>
        <path
            d="M462.08 231.383a239.189 239.189 0 0 0 16.766 23.121"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-d)">
            <path
                d="M370.606 382.796c10.154 27.673 36.965 46.752 60.768 62.166 26.061 16.875 54.836 29.523 85.557 34.747 5.584.95 11.307-1.48 12.916-7.334 1.386-5.043-1.712-11.96-7.334-12.916-28.075-4.775-53.96-15.731-77.926-30.953a242.42 242.42 0 0 1-31.913-23.502c-8.601-7.691-17.814-16.88-21.818-27.791-1.952-5.321-7.124-8.926-12.916-7.334-5.126 1.409-9.297 7.566-7.334 12.917Z"
                fill="url(#female-34-25-dress-e)"
            />
            <path
                d="M364.245 394.062c7.041 28.184 24.314 52.841 43.011 74.562 18.207 21.15 38.751 41.01 62.299 56.148a167.276 167.276 0 0 0 43.704 20.039c13.005 3.771 18.558-16.488 5.583-20.25-31.983-9.275-58.275-30.298-81.155-53.815-22.561-23.189-45.185-50.211-53.192-82.267-3.274-13.104-23.531-7.553-20.25 5.583Z"
                fill="url(#female-34-25-dress-f)"
            />
            <path
                d="M383.296 382.323a277.414 277.414 0 0 0 58.1 27.28c21.812 7.184 44.54 9.258 67.206 12.084 5.637.703 10.5-5.329 10.5-10.5 0-6.258-4.847-9.795-10.5-10.5-20.851-2.6-41.559-4.726-61.623-11.334a253.3 253.3 0 0 1-53.083-25.163c-11.552-7.086-22.113 11.07-10.6 18.133Z"
                fill="url(#female-34-25-dress-g)"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-dress-a"
                x1={307.298}
                y1={459.358}
                x2={569.72}
                y2={431.656}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.192} stopColor="#00B140" />
                <stop offset={0.918} stopColor="#81C871" />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-e"
                x1={380.894}
                y1={425.668}
                x2={519.171}
                y2={423.739}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00B140" />
                <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-f"
                x1={374.899}
                y1={464.126}
                x2={515.182}
                y2={462.169}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00B140" />
                <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-g"
                x1={388.603}
                y1={393.004}
                x2={508.394}
                y2={391.333}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00B140" />
                <stop offset={0.035} stopColor="#00B140" stopOpacity={0.962} />
                <stop offset={0.678} stopColor="#00B140" stopOpacity={0.275} />
                <stop offset={0.961} stopColor="#00B140" stopOpacity={0} />
            </linearGradient>
            <filter
                id="female-34-25-dress-b"
                x={402.191}
                y={251.297}
                width={91.307}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_10320_8823"
                />
            </filter>
            <filter
                id="female-34-25-dress-d"
                x={355.887}
                y={354.578}
                width={182.289}
                height={198.695}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_10320_8823"
                />
            </filter>
            <radialGradient
                id="female-34-25-dress-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="matrix(72.12061 -27.5712 13.27944 34.7363 447.476 282.657)"
            >
                <stop offset={0.437} stopColor="#00B140" stopOpacity={0} />
                <stop offset={0.502} stopColor="#00B140" stopOpacity={0.144} />
                <stop offset={0.719} stopColor="#00B140" stopOpacity={0.602} />
                <stop offset={0.874} stopColor="#00B140" stopOpacity={0.889} />
                <stop offset={0.949} stopColor="#00B140" />
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
