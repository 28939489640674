import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-3">
        <path
            d="M505.911 266.24c-2.736.532-5.462 1.1-8.187 1.668-1.494.312-2.986.621-4.478.927-4.316.864-8.61 1.83-12.632 2.744-2.835.62-5.696 1.304-8.463 1.966a958.53 958.53 0 0 1-4.109.976c-9.193 2.23-17.343 4.398-24.916 6.63l-1.988.592a571.188 571.188 0 0 0-1.213-4.052c-2.65-8.62-5.624-17.368-9.089-26.736a242.757 242.757 0 0 0-11.067-25.884 1072.34 1072.34 0 0 0-3.805-7.58c-2.884-5.716-5.865-11.627-8.603-17.524l-.06-.128a32.148 32.148 0 0 0-28.986-18.405 31.726 31.726 0 0 0-13.582 3.042 31.788 31.788 0 0 0-16.498 18.101 31.806 31.806 0 0 0 1.135 24.466c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.236 5.18 3.361 7.766a242.507 242.507 0 0 0 12.737 25.1c4.959 8.675 9.755 16.571 14.666 24.142 4.253 6.518 9.979 15.055 16.564 23.298a24.308 24.308 0 0 0 16.492 8.949 23.453 23.453 0 0 0 11.587-.938l12.156-4.169 12.18-4.11c7.467-2.543 15.374-5.473 24.182-8.959a809.248 809.248 0 0 1 3.891-1.562c2.649-1.058 5.388-2.153 8.062-3.281 3.828-1.58 7.901-3.271 11.904-5.046a769.611 769.611 0 0 1 4.191-1.825 628.048 628.048 0 0 0 7.67-3.369 504.41 504.41 0 0 1 3.744-1.731l-10.705-33.675c-1.347.25-2.694.501-4.059.745Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-3a)"
        />
        <path
            d="M349.369 227.043c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.236 5.18 3.361 7.766a242.507 242.507 0 0 0 12.737 25.1c4.959 8.675 9.755 16.571 14.666 24.142 4.253 6.518 9.979 15.055 16.564 23.298a24.308 24.308 0 0 0 16.492 8.949 23.453 23.453 0 0 0 11.587-.938l12.156-4.169 12.18-4.11c7.467-2.543 15.374-5.473 24.182-8.959a769.994 769.994 0 0 1 3.892-1.562c2.648-1.058 5.387-2.153 8.061-3.281 3.828-1.58 7.901-3.271 11.904-5.046a769.611 769.611 0 0 1 4.191-1.825 628.048 628.048 0 0 0 7.67-3.369 504.41 504.41 0 0 1 3.744-1.731l-10.705-33.675c-1.347.251-2.694.502-4.06.746-2.736.532-5.462 1.1-8.187 1.668-1.494.312-2.986.621-4.478.927-4.316.864-8.61 1.83-12.632 2.744-2.835.62-5.695 1.304-8.462 1.966-1.366.327-2.734.654-4.11.976-9.193 2.23-17.343 4.398-24.916 6.63l-1.988.592a523.182 523.182 0 0 0-1.213-4.052c-2.65-8.62-5.624-17.368-9.089-26.736a242.844 242.844 0 0 0-11.067-25.884 1072.34 1072.34 0 0 0-3.805-7.58c-2.884-5.716-5.865-11.627-8.603-17.524l-.06-.128"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-3a"
                x1={346.319}
                y1={257.965}
                x2={537.944}
                y2={257.965}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
