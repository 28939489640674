import React from 'react';

const SvgComponent = () => {
    return <g id="man-34-25-shorts-2-stand-1">
        <path
            fill="#2B2729"
            d="M371.191 387.121h161.924l-1.9 11.39a99.904 99.904 0 0 0-.075 32.487c3.274 20.122 8.362 54.581 8.362 74.386H401.917"
        />
        <path
            fill="url(#man-34-25-shorts-2-stand-1-a)"
            d="M429.569 387.121h-59.944s-22.23 26.607-17.033 71.261l7.398 53.638h99.551c.288-4.368.513-8.786.655-13.227a33.738 33.738 0 0 1 14.812-26.6c14.655-10.143 23.563-39.878 23.563-39.878s24.553-11.723 33.269-45.194H429.569Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-shorts-2-stand-1-a"
                x1={351.805}
                x2={531.84}
                y1={449.571}
                y2={449.571}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729" />
                <stop offset={0.225} stopColor="#312E30" />
                <stop offset={0.564} stopColor="#434245" />
                <stop offset={0.972} stopColor="#5F6267" />
                <stop offset={0.999} stopColor="#616469" />
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
