import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-7">
        <path
            d="M500.785 186.165c-9.225-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-7-a)"
        />
        <path
            d="M539.1 246.393c-2.2-5.806-4.69-11.618-7.609-17.759a112.615 112.615 0 0 0-9.479-16.688c-1.11-1.621-2.237-3.232-3.363-4.843-2.46-3.518-5.003-7.156-7.317-10.843l-.148-.233a31.895 31.895 0 0 0-26.982-14.753 31.888 31.888 0 0 0-17.221 5.051 31.794 31.794 0 0 0-14.006 20.092 31.792 31.792 0 0 0 4.303 24.11c2.344 3.663 4.549 7.515 6.681 11.24a565.208 565.208 0 0 0 2.944 5.106 112.287 112.287 0 0 0 11.042 15.69c4.316 5.27 8.51 10.002 12.823 14.47 2.363 2.429 5.249 5.32 8.531 8.274 7.053-17.113 24.402-33.053 39.801-38.914Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-7-b)"
        />
        <path
            d="M463.957 241.768c.977 1.706 1.954 3.413 2.944 5.105a112.337 112.337 0 0 0 11.042 15.69c4.317 5.27 8.51 10.003 12.823 14.47 2.364 2.429 5.249 5.32 8.532 8.274 7.052-17.113 24.402-33.053 39.8-38.915-2.2-5.805-4.69-11.617-7.608-17.759a112.47 112.47 0 0 0-9.48-16.687 596.714 596.714 0 0 0-3.362-4.843c-2.46-3.518-5.003-7.157-7.318-10.843l-.147-.233a31.931 31.931 0 0 0-12.041-11.07 118.797 118.797 0 0 0-30.909-15.961"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-7-a"
                x1={452.511}
                y1={346.457}
                x2={488.077}
                y2={161.686}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-7-b"
                x1={472.194}
                y1={350.246}
                x2={507.76}
                y2={165.475}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
