import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-2">
        <path
            d="M509.537 200.747c4.46 7.33 9.292 14.433 13.964 21.634a202.798 202.798 0 0 1 12.648 22.439c3.819 7.722 7.448 15.561 10.815 23.56a252.238 252.238 0 0 1 9.053 24.639 23.277 23.277 0 0 1 .864 9.036 23.287 23.287 0 0 1-8.44 15.681 23.294 23.294 0 0 1-8.019 4.256 23.312 23.312 0 0 1-22.792-5.491 252.133 252.133 0 0 1-17.812-19.28c-5.589-6.64-10.915-13.441-16.052-20.357a203.064 203.064 0 0 1-14.217-21.48c-4.283-7.438-8.406-14.975-12.902-22.284a31.025 31.025 0 0 1-4.205-11.341 31.024 31.024 0 0 1 .455-12.087 31.016 31.016 0 0 1 5.046-10.993 30.989 30.989 0 0 1 32.297-11.975 31.009 31.009 0 0 1 19.218 13.915l.079.128Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="m540.588 280.674 8.38 1.777c2.796.577 5.6 1.113 8.392 1.714 2.784.64 5.588 1.174 8.357 1.907 1.39.335 2.773.71 4.154 1.092 1.384.368 2.764.759 4.14 1.173 5.513 1.603 11.015 3.27 16.486 5.115a263.796 263.796 0 0 1 16.329 6.005 15.847 15.847 0 0 1 8.631 8.531 15.852 15.852 0 0 1 .071 12.135 15.875 15.875 0 0 1-14.083 9.863 251.22 251.22 0 0 1-17.398.061c-5.772-.138-11.512-.451-17.241-.83a142.022 142.022 0 0 1-4.292-.313 129.371 129.371 0 0 1-4.277-.394c-2.852-.258-5.67-.715-8.506-1.065-2.829-.39-5.647-.845-8.472-1.259l-8.483-1.195a22.498 22.498 0 0 1-14.843-8.751 22.515 22.515 0 0 1 4.442-31.528 22.517 22.517 0 0 1 18.213-4.038Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M660.173 330.149c-.632-7.064-8.847-13.954-16.707-15.537l-.629-.058a22.075 22.075 0 0 0-3.276-2.969 9.7 9.7 0 0 0-5.954-1.99l-.74.088c-.115-.131-.232-.26-.352-.387-3.156-3.349-7.73-4.94-12.203-6.025-4.472-1.085-9.104-1.808-13.205-3.896l-3.503 8.202a5.882 5.882 0 0 0-.69 4.636c.322 1.576.985 3.064 1.372 4.625a6.522 6.522 0 0 1-.306 4.704l-.224.574a1.298 1.298 0 0 0 .223 1.318 5.99 5.99 0 0 0 4.604 1.842l.178.225a8.958 8.958 0 0 0 2.966 1.968c5.916 2.304 12.424 2.393 18.77 2.571 6.347.177 12.89.52 18.617 3.26a41.945 41.945 0 0 0 4.549 2.148c1.506.508 4.968.94 6.823-1.192.209-.242.335-.546.358-.865a4.146 4.146 0 0 0-.826-2.76l.054-.093a.59.59 0 0 0 .101-.389Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M606.529 299.551c4.101 2.088 8.734 2.811 13.206 3.896 4.473 1.085 9.047 2.676 12.203 6.025a37.999 37.999 0 0 1 4.535 6.832 18.602 18.602 0 0 0 4.129 5.113 2.24 2.24 0 0 1 .763 2.501 2.188 2.188 0 0 1-2.559 1.406 29.201 29.201 0 0 1-8.784-3.56 63.128 63.128 0 0 1-7.386-6.232M633.029 309.774a9.703 9.703 0 0 1 5.955 1.99 28.87 28.87 0 0 1 4.6 4.424l7.879 8.789a2.484 2.484 0 0 1 .891 1.987c-.226 1.1-1.708 1.324-2.813 1.13a11.192 11.192 0 0 1-5.992-3.142M614.368 314.43a24.162 24.162 0 0 1-1.65 6.498 8.148 8.148 0 0 1-4.712 4.525c-1.421-.022-3.532-.473-4.358-1.63M603.401 321.719a6.514 6.514 0 0 0 .307-4.703c-.387-1.562-1.05-3.049-1.372-4.626a5.876 5.876 0 0 1 .69-4.636"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M653.748 330.364a6.452 6.452 0 0 0 4.535.95c.583-.098 1.366-.399 1.313-.988-.632-7.064-8.847-13.955-16.707-15.537M611.148 327.648c5.916 2.304 12.424 2.393 18.77 2.571 6.346.177 12.89.52 18.617 3.26a41.938 41.938 0 0 0 4.549 2.147c1.58.624 3.34.605 4.906-.053"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M660.203 334.049a4.132 4.132 0 0 0-.764-3.24M517.63 316.501a252.133 252.133 0 0 1-17.812-19.28c-5.589-6.64-10.915-13.441-16.052-20.357a203.064 203.064 0 0 1-14.217-21.48c-4.283-7.438-8.406-14.975-12.902-22.284a31.025 31.025 0 0 1-4.205-11.341 31.024 31.024 0 0 1 .455-12.087 31.016 31.016 0 0 1 5.046-10.993 30.996 30.996 0 0 1 43.29-6.929 30.97 30.97 0 0 1 8.225 8.869l.079.128c4.46 7.33 9.292 14.433 13.964 21.634a202.875 202.875 0 0 1 12.648 22.438c3.819 7.723 7.448 15.562 10.815 23.561 1.672 4.007 3.297 8.042 4.824 12.137"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M601.445 329.985a251.22 251.22 0 0 1-17.398.06c-5.772-.137-11.512-.451-17.241-.83a142.022 142.022 0 0 1-4.292-.313 132.136 132.136 0 0 1-4.277-.393c-2.853-.258-5.67-.716-8.506-1.066-2.829-.39-5.647-.844-8.472-1.258l-8.483-1.196c-5.227-.736-10.879-4.013-15.147-8.489M553.963 283.457c.726.149 2.671.549 3.396.705 2.785.64 5.589 1.175 8.358 1.907 1.39.336 2.772.71 4.154 1.092 1.384.368 2.764.759 4.14 1.174 5.513 1.603 11.015 3.27 16.486 5.114a263.768 263.768 0 0 1 16.328 6.005"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M497.892 190.133c-8.494-7.981-18.64-14.525-30.74-20.235l15.984 33.244s18.84-9.172 14.756-13.009Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.152 169.898a129.68 129.68 0 0 1 30.74 20.235"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
