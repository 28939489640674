import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={65}
            height={141}
            fill="none"
            id="object-food-medicine"
            {...attributes}
        >
            <path fill="#fff" d="M59.546 1.219H5.572v10.667h53.974V1.219Z" />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M59.546 1.219H5.572v10.667h53.974V1.219ZM63.525 36.083a8 8 0 0 0-2.47-5.78l-6.282-6.013a4.998 4.998 0 0 1-1.543-3.612v-8.791H11.887v8.79a4.997 4.997 0 0 1-1.543 3.613L4.06 30.303a8 8 0 0 0-2.47 5.78v95.109a8 8 0 0 0 8 8h45.933a8 8 0 0 0 8-8v-95.11Z"
            />
            <path
                fill="#FFD100"
                d="M8.592 38.355v92.835c0 .551.448 1 1 1h45.933c.552 0 1-.449 1-1V38.356H8.592Z"
            />
        </svg>
    );
}
