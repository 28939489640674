import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M494.377 224.144c6.101 4.244 12.435 8.15 18.669 12.201a161.952 161.952 0 0 1 17.843 13.395c5.698 4.825 11.277 9.823 16.691 15.059a202.796 202.796 0 0 1 15.586 16.657 19.243 19.243 0 0 1-8.832 30.472 19.244 19.244 0 0 1-12.217-.043 203.038 203.038 0 0 1-21.084-8.709c-6.809-3.22-13.453-6.678-19.978-10.307a161.86 161.86 0 0 1-18.828-11.972c-5.99-4.403-11.878-8.953-18.001-13.165a26.497 26.497 0 0 1-6.815-36.852 26.5 26.5 0 0 1 36.852-6.815l.114.079Z"
            fill="url(#female-34-25-shirt-1-arms-opt-1-left-a)"
        />
        <path
            d="m494.261 224.062.115.08c6.101 4.244 12.435 8.149 18.668 12.201a161.754 161.754 0 0 1 17.843 13.394c5.698 4.826 11.278 9.823 16.692 15.059a203.12 203.12 0 0 1 15.586 16.657 19.245 19.245 0 0 1 .948 23.151 19.238 19.238 0 0 1-21.997 7.278 202.819 202.819 0 0 1-21.084-8.709c-6.809-3.219-13.453-6.677-19.978-10.307a161.72 161.72 0 0 1-18.828-11.971c-5.99-4.404-11.879-8.954-18.001-13.165"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.071 223.744c-8.492-8.307-18.696-15.184-30.909-21.252l15.604 34.205s19.388-8.958 15.305-12.953Z"
            fill="url(#female-34-25-shirt-1-arms-opt-1-left-b)"
        />
        <path
            d="M463.162 202.492a132.211 132.211 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-1-left-a"
                x1={526.077}
                y1={231.974}
                x2={497.595}
                y2={298.191}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arms-opt-1-left-b"
                x1={479.729}
                y1={212.04}
                x2={451.247}
                y2={278.257}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
