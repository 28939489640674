import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m354.586 738.12 2.732-9.89a1252.05 1252.05 0 0 1 1.862-6.753c1.69-6.096 3.437-12.398 4.969-18.678a663.759 663.759 0 0 0 2.087-8.595c1.344-5.641 2.733-11.475 4.393-17.088 4.491-16.077 11.776-30.634 21.332-47.54 4.648-8.022 9.283-15.247 14.176-22.096a121.653 121.653 0 0 1 2.527-3.457c.467-.624.934-1.247 1.393-1.873.425-.541.824-1.056 1.224-1.57.947-1.218 1.926-2.477 2.946-3.698a135.726 135.726 0 0 1 5.817-6.696 3994.1 3994.1 0 0 1-1.737-23.199c-.251-3.445-1.053-14.351-1.053-14.351-.36-4.945-.732-10.058-1.231-15.101l-.965-9.601-.492-4.8-.411-4.807c-.513-6.984-.696-13.227-.56-19.099.202-7.541.479-13.344.897-18.818.555-7.328 1.173-13.26 1.944-18.667l.644-4.623.79-4.634c.61-3.449 1.247-6.466 1.949-9.229a52.023 52.023 0 0 1 18.477-28.298c9.137-7.113 20.385-15.14 31.965-15.138h57.59c-4.988 19.471-6.581 30.777-5.515 53.832l.237 5.501a141.37 141.37 0 0 1-.54 18.95c-.519 5.962-1.29 12.05-2.358 18.612-.863 5.394-2.013 11.241-3.618 18.4-1.422 6.324-3.091 12.456-4.96 18.225-2.135 6.61-4.827 13.048-6.934 17.918l-1.327 3.048c-2.093 4.813-4.258 9.79-6.598 14.661a191.152 191.152 0 0 1-2.14 4.408 183.172 183.172 0 0 1-2.222 4.396c-.957 1.891-2.007 3.806-3.023 5.657-.562 1.024-1.124 2.048-1.674 3.074-2.836 4.956-5.936 10.222-9.486 15.352a27.242 27.242 0 0 1-3.064 9.662l-24.703 45.692-12.362 22.841-38.677 78.925-44.301-20.855Z"
            fill="#004F71"
        />
        <path
            d="m353.189 399.781-.229.682c-4.005 11.726-6.021 27.272-3.938 39.398-2.882 9.287-1.095 19.197 1.386 28.6a175.607 175.607 0 0 0 3.084 10.414l1.713 5.141 1.836 5.059c.882 2.529 1.881 5.053 2.846 7.495.345.871.69 1.741 1.027 2.606.96 2.53 2.04 5.055 3.085 7.497.359.838 3.201 7.516 3.201 7.516l2.263 4.93c.54 1.159 1.07 2.321 1.6 3.484.953 2.091 1.939 4.253 2.972 6.356 2.957 6.166 6.354 12.485 10.385 19.317 2.036 3.452 4.207 6.919 6.307 10.271 1.752 2.796 3.563 5.687 5.283 8.551l5.709 9.424 5.606 9.469 18.01 30.5a116.512 116.512 0 0 0-1.076 3.122l-.006-.001c-.161.485-.312.971-.464 1.457l-.422 1.335a145.58 145.58 0 0 0-.883 3.01c-.438 1.48-.823 2.977-1.194 4.425-.158.614-.315 1.228-.485 1.877-.166.736-.341 1.472-.515 2.209-.314 1.326-.64 2.698-.908 4.045-1.696 7.984-3.006 16.199-4.004 25.127-1.883 18.718-2.642 34.467-.23 50.474.757 5.622 7.63 66.428 7.63 66.428h48.289s1.814-36.595 2.731-47.22c.511-5.905 1.025-11.811 1.543-17.716l2.183-25.072 2.043-25.07 4.062-50.14a53.46 53.46 0 0 0-.13-5.91 55.53 55.53 0 0 1-.037-6.553 314.681 314.681 0 0 0-.897-42.412 570.253 570.253 0 0 1-.355-3.939c-.21-2.382-.422-4.763-.669-7.14l-1.221-10.995c-.687-6.036-1.676-13.967-3.24-21.796-1.359-6.833-3.082-13.945-5.123-21.14-2.317-8.147-6.703-21.563-6.703-21.563a154.839 154.839 0 0 0 17.838-13.652c22.03-19.472 35.405-38.65 40.888-58.634l.348-1.265-171.139-.001Z"
            fill="url(#female-34-25-pants-3-walk-2-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-3-walk-2-a"
                x1={487.778}
                y1={583.789}
                x2={407.104}
                y2={590.295}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0B86B8" />
                <stop offset={1} stopColor="#004F71" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
