import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8201)"
            d="M408.756 236.408l-1.383-2.542c-4.426-8.03-12.869-13.316-23.161-14.497a18.905 18.905 0 00-2.167-.127c-3.403 0-6.844.952-11.314 3.072-5.131 2.435-9.307 7.65-11.456 14.311-2.519 7.812-1.89 16.346 1.684 22.83 2.055 3.729 3.585 7.063 5.204 10.593.508 1.108 1.03 2.237 1.567 3.386 0 0 30.947-18.396 47.79-24.974-2.024-3.037-3.843-6.676-6.764-12.052z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M370.731 222.312c-5.131 2.435-9.307 7.651-11.456 14.312-2.519 7.811-1.89 16.345 1.684 22.829 2.055 3.729 3.585 7.063 5.204 10.593l1.567 3.386s29.79-18.251 47.429-24.758"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8201"
                x1="357.806"
                x2="419.736"
                y1="339.548"
                y2="136.521"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
