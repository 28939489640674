import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-3-walk-1">
        <path
            d="M379.09 789.947s67.71-124.863 89.488-163.671c4.075-3.213 6.821-8.237 9.182-12.75 7.86-14.5 13.783-29.286 18.07-40.559 2.274-5.831 5.163-14.51 7.284-20.466 7.694-21.596 12.337-45.57 15.506-65.105a369.118 369.118 0 0 0 3.571-29.012c.478-5.069 11.14-71.357 11.14-71.357s-114.442 27.06-114.662 55.908c-1.16 10.943-1.915 22.456-2.4 33.37-2.19 40.217 1.158 81.111 2.691 121.31-21.603 21.566-43.127 52.686-51.678 80.943-4.211 15.626-20.557 78.995-23.942 94.173l35.75 17.216Z"
            fill="#EF0025"
        />
        <path
            d="M370.739 387.121c-9.134 15.77-16.008 25.657-17.433 43.962a56.369 56.369 0 0 0 .606 22.547c.086.819.172 1.636.271 2.482a12.658 12.658 0 0 0 2.069 5.627 73.256 73.256 0 0 0 1.367 3.587c3.655 10.757 7.928 21.871 12.178 32.311 13.694 34.393 31.354 67.428 48.381 100.406a47.392 47.392 0 0 1 4.021 32.004 293.878 293.878 0 0 0-4.023 100.307c3.903 29.248 6.483 58.973 12.289 87.583h51.181c-1.952-50.528-3.747-106.215-.112-156.231-.051-6.37 4.059-45.589 4.268-60.851.311-22.686-2.986-60.36-7.64-90.652-.688-3.414-1.378-6.749-2.068-10.006a47.697 47.697 0 0 1 8.141-37.602c9.079-12.734 14.335-30.28 14.335-30.28s24.553-11.723 33.269-45.194h-161.1Z"
            fill="url(#man-34-25-pants-3-walk-1-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-3-walk-1-a"
                x1={556.427}
                y1={515.272}
                x2={209.439}
                y2={747.754}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
