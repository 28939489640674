import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-2-walk-2">
        <path
            fill="#001489"
            d="M514.69 821.429c-9.41-23.119-25.124-85.218-27.904-95.433-6.29-23.111-8.18-51.611-6.447-76.699.221-2.55.496-5.221.84-7.86a33.218 33.218 0 0 0-1.647-15.585c-7.266-20.128-14.333-41.635-21.267-61.51a795.812 795.812 0 0 1-27.73-97.163c-1.024-5.478-2.575-11.952-3.457-17.43-5.224-27.402 14.898-55.733 42.494-59.822 25.731-4.602 51.743 11.874 58.61 37.091a316.287 316.287 0 0 1 5.493 22.203c7.112 35.98 11.719 73.167 10.67 112.216-.411 19.099-.932 36.815-4.156 58.764a32.964 32.964 0 0 0-.216 7.758c5.46 60.333 13.79 127.803 21.995 185.93l-47.278 7.54Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-2-a)"
            d="m510.21 809.052 50.126-6.82a4246.529 4246.529 0 0 1-2.241-16.416l-52.919 7.2c1.714 5.719 3.413 11.163 5.034 16.036Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-2-b)"
            d="m501.126 779.121 55.081-7.494c-2.156-16.4-4.27-33.184-6.288-50.049l-62.064 8.444c2.464 9.365 7.664 29.397 13.271 49.099Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-2-c)"
            d="M531.339 386.121h-161.1l-.866.499a901.126 901.126 0 0 1-2.767 4.734c-9.149 15.582-15.329 26.123-15.083 48.174a62.665 62.665 0 0 0-.151 7.081l-.327 7.1c-.69 26.552-.47 53.041 1.702 79.428 2.008 21.883 3.965 45.353 6.078 67.734a33.06 33.06 0 0 1-6.441 22.833l-.074.1c-14.656 20.537-28.778 47.089-34.267 70.484-3.176 13.778-21.633 97.201-21.633 97.201l42.181 19.084s51.1-124.644 56.451-135.369l21.535-42.933a27.208 27.208 0 0 0 2.631-8.706c9.206-20.936 13.95-37.078 19.239-55.431 6.465-22.027 14.466-50.092 20.304-70.56a46.942 46.942 0 0 1 17.07-24.985c13.56-10.01 21.91-35.8 23.081-39.578 3.256-1.73 25.285-14.461 33.404-45.637l-.967-1.253Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-2-d)"
            d="m298.41 782.45 43.446 20.165c1.626-3.96 3.786-9.216 6.305-15.34l-46.081-21.388-3.67 16.563Z"
        />
        <path
            fill="url(#man-34-25-pants-2-walk-2-e)"
            d="m305.477 750.57 48.153 23.423c5.926-14.381 12.854-31.16 19.354-46.805l-56.205-27.34c-2.439 10.822-7.071 31.652-11.302 50.722Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-walk-2-a"
                x1={505.176}
                x2={560.336}
                y1={797.434}
                y2={797.434}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-2-b"
                x1={487.855}
                x2={556.207}
                y1={750.35}
                y2={750.35}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-2-c"
                x1={484.221}
                x2={145.411}
                y1={654.403}
                y2={338.458}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.672} stopColor="#00359C" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-2-d"
                x1={298.41}
                x2={348.161}
                y1={784.251}
                y2={784.251}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-walk-2-e"
                x1={305.476}
                x2={372.984}
                y1={736.921}
                y2={736.921}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
