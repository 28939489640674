import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7526)"
            d="M524.444 280.027c-20.409-24.152-26.325-32.894-31.649-46.771-2.986-7.787-9.421-18.228-14.918-23.949a47.493 47.493 0 00-4.724-2.974c4.065 7.051 4.95 18.802-6.423 23.57a22.004 22.004 0 01-8.678 1.436c-10.921 0-21.127-6.507-31.939-13.399-7.283-4.644-14.812-9.445-22.911-12.429a32.696 32.696 0 00-10.802-1.712 31.455 31.455 0 00-4.391.292c-.16.023-.314.078-.452.162a62.74 62.74 0 00-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-25.615 45.361-27.936 63.292 14.883 0 179.194.874 179.224.714 9.23-48.617-.85-76.583-9.313-98.687a537.005 537.005 0 01-2.685-7.11c8.094-3.299 18.171-9.259 20.658-19.014 1.942-7.615-1.083-16.221-8.991-25.578z"
        ></path>
        <g filter="url(#filter0_f_11323_7526)">
            <path
                fill="url(#paint1_radial_11323_7526)"
                d="M410.676 290.993l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.265 14.814 1.029 21.605-1.697a31.518 31.518 0 0018.533-21.326 31.493 31.493 0 00-6.485-27.499l-6.917-10.635"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11323_7526"
                width="91.306"
                height="77.898"
                x="402.676"
                y="251.141"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7526"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7526"
                x1="345.621"
                x2="533.206"
                y1="336.665"
                y2="303.793"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11323_7526"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="matrix(72.12061 -27.5712 13.27944 34.7363 447.933 282.514)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#888B8D" stopOpacity="0"></stop>
                <stop offset="0.669" stopColor="#888B8D" stopOpacity="0.501"></stop>
                <stop offset="0.857" stopColor="#888B8D" stopOpacity="0.861"></stop>
                <stop offset="0.949" stopColor="#888B8D"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
