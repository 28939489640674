import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-left-option-4">
        <path
            d="m464.949 167.504 2.654.951c13.051 4.677 23.788 10.342 32.825 17.319.765.591 5.866 5.433 5.815 6.374-.25 4.649-16.937 8.176-19.333 9.644l-.857.525-21.104-34.813Z"
            fill="url(#man-34-25-tshirt-arm-left-option-4-a)"
        />
        <path
            d="M520.381 264.841c.449-1.568.95-3.23 1.488-4.936.335-1.067.666-2.135.994-3.205.669-2.172 1.361-4.419 2.098-6.623 1.013-3.132 2.105-6.476 3.297-9.789.406-1.147.809-2.298 1.21-3.453.462-1.328.939-2.652 1.411-3.978-3.296-5.871-6.11-10.848-6.875-12.121a946.953 946.953 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.615-9.445-16.021l-.073-.129a32 32 0 0 0-55.715 31.493c3.048 5.392 5.963 10.989 8.781 16.401a1995.05 1995.05 0 0 0 3.729 7.122 220.077 220.077 0 0 0 13.928 22.894c.722 1.057 1.44 2.101 2.154 3.131l37.06-13.512.11-.378Z"
            fill="url(#man-34-25-tshirt-arm-left-option-4-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-left-option-4-a"
                x1={488.666}
                y1={125.72}
                x2={475.654}
                y2={355.022}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-arm-left-option-4-b"
                x1={496.151}
                y1={126.143}
                x2={483.139}
                y2={355.444}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
