import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={117}
            height={183}
            fill="none"
            id="object-food-honey"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M115.635 72.43H26.709v17.053h88.926V72.43ZM115.636 146.508V113.68a8 8 0 0 0-2.469-5.78l-6.283-6.013a4.997 4.997 0 0 1-1.543-3.612v-8.79H37.004v8.79a4.997 4.997 0 0 1-1.543 3.612l-6.283 6.013a8 8 0 0 0-2.469 5.78v59.509a8 8 0 0 0 8 8h72.926a8 8 0 0 0 8-8v-12.85"
            />
            <path
                fill="#FFD100"
                d="M33.709 127.945v45.242c0 .551.448 1 1 1h72.926c.552 0 1-.449 1-1v-45.242H33.709Z"
            />
            <path
                fill="#004F71"
                d="M55.293 72.43C46.013 56.356 16.584 5.126 15.907 4.219A8.535 8.535 0 0 0 4 2.229a8.536 8.536 0 0 0-1.989 11.909L43.616 72.43h11.677Z"
            />
        </svg>
    );
}
