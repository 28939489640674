import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-6-cycling-1-right-a)">
        <path
            d="M386.923 814.689c-2.206-2.285-6.431-6.613-9.932-9.937-14.997-14.243-23.878-46.742-23.661-47.149l16.274-40.136-44.603-7.807c-.216 3.036-2.458 26.932-3.679 34.232 0 0-6.62 13.826-15.021 28.737a10 10 0 0 0-.836 7.877 9.986 9.986 0 0 0 5.158 6.011l18.264 8.928a41.137 41.137 0 0 1 10.39 7.175 70.701 70.701 0 0 0 17.341 11.936c3.65 1.814 17.264 6.186 25.054 8.63a5.16 5.16 0 0 0 6.284-2.902 5.151 5.151 0 0 0-1.033-5.595Z"
            fill="#E2E2E2"
        />
        <path
            d="M383.29 824.73a6.155 6.155 0 0 1-1.841-.296c-7.8-2.505-21.438-6.987-25.14-8.859a71.693 71.693 0 0 1-17.504-12.226 40.105 40.105 0 0 0-10.09-7.068l-17.893-8.898a11.44 11.44 0 0 1-5.83-6.462 10.89 10.89 0 0 1 .868-9.001c7.538-13.171 8.45-14.454 10.208-18.419.069-.145.153-.283.251-.411a3.097 3.097 0 0 1 2.761-1.153 3.072 3.072 0 0 1 1.445.548c.428.302.772.708.999 1.179 2.057 4.155 8.32 13.811 21.436 11.8a85.378 85.378 0 0 0 9.166-2.579 3.23 3.23 0 0 1 3.84 1.457c.494.945.874 1.947 1.13 2.983.997 3.746 7.004 23.693 20.797 36.97 3.394 3.267 7.478 7.5 9.72 9.851a6.443 6.443 0 0 1 1.862 4.725 6.172 6.172 0 0 1-6.185 5.859Z"
            fill="#63666A"
        />
        <path
            d="M341.264 792.017c-9.77-13.31-25.607-20.451-33.686-23.107-.559.983-1.128 1.981-1.718 3.012a10.894 10.894 0 0 0-.868 9.001 11.441 11.441 0 0 0 5.83 6.463l17.893 8.897a40.157 40.157 0 0 1 10.09 7.068 71.712 71.712 0 0 0 17.504 12.226c3.702 1.872 17.34 6.354 25.14 8.859a6.1 6.1 0 0 0 2.426.271 6.103 6.103 0 0 0 2.338-.705c-7.16-3.735-35.409-18.989-44.949-31.985Z"
            fill="#E2E2E2"
        />
        <defs>
            <clipPath id="man-34-25-shoes-6-cycling-1-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .773)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
