import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10088)"
            d="M494.778 223.345a109.63 109.63 0 00-20.026-15.329 31.047 31.047 0 012.394 13.894 66.035 66.035 0 01-1.451 10.763l2.169 4.754a1.01 1.01 0 00.91.585 1 1 0 00.42-.092c2.597-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_10088)"
            d="M463.285 269.778a296.87 296.87 0 00-4.602-5.338 27.313 27.313 0 01-6.867-19.895 27.306 27.306 0 019.213-18.924 27.465 27.465 0 0118.228-6.918 27.528 27.528 0 0120.592 9.263l.084.095a313.893 313.893 0 005.372 5.938l.707.766-42.079 35.78-.648-.767z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10088"
                x1="502.629"
                x2="410.099"
                y1="202.565"
                y2="282.662"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_10088"
                x1="451.765"
                x2="563.417"
                y1="244.624"
                y2="244.624"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
