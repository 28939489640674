import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={213}
            height={185}
            fill="none"
            id="object-food-potato"
            {...attributes}
        >
            <path
                fill="url(#object-food-potato-a)"
                d="M210.59 131.524c-8.228-10.29-19.011-17.965-30.67-23.196a271.476 271.476 0 0 1-6.134-2.995c-17.666-9.002-40.126-17.519-67.123-17.519-26.997 0-49.457 8.517-67.123 17.519-1.52.778-4.585 2.267-6.134 2.995-11.66 5.231-22.442 12.906-30.67 23.196a6.603 6.603 0 0 0 0 8.121c2.044 2.618 4.09 4.763 6.134 6.743 7.212 7.004 15.659 12.505 24.537 16.453 10.28 4.775 37.342 20.519 73.257 20.519 35.915 0 62.976-15.743 73.256-20.519 8.879-3.948 17.325-9.449 24.537-16.453 2.044-1.98 4.089-4.126 6.134-6.743a6.602 6.602 0 0 0-.001-8.121Z"
            />
            <path
                fill="url(#object-food-potato-b)"
                d="M107.26 97.785c-33.641-.455-63.624 14.604-62.647 19.399.69 3.385 16.548.456 64.75.927 43.803.429 58.048 3.114 58.747-.056 1.033-4.684-27.997-19.824-60.85-20.27Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M39.54 105.333c-1.52.778-4.585 2.267-6.134 2.995-11.66 5.231-22.442 12.906-30.67 23.196a6.603 6.603 0 0 0 0 8.121c2.044 2.618 4.09 4.763 6.134 6.743 7.212 7.004 15.659 12.505 24.537 16.453 10.28 4.775 37.342 20.519 73.257 20.519 35.915 0 62.976-15.743 73.256-20.519 8.879-3.948 17.325-9.449 24.537-16.453 2.044-1.98 4.089-4.126 6.134-6.743a6.604 6.604 0 0 0 0-8.121c-8.229-10.29-19.011-17.965-30.67-23.196a271.476 271.476 0 0 1-6.134-2.995c-17.666-9.002-40.126-17.519-67.123-17.519-15.78 0-30.01 2.91-42.64 7.126M52.992 68.907c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398S59.76 12.914 59.76 1.715M103.281 68.907c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398 6.767-11.199 6.767-22.398M153.568 68.907c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398 6.767-11.199 6.767-22.398"
            />
            <defs>
                <linearGradient
                    id="object-food-potato-a"
                    x1={104.455}
                    x2={176.011}
                    y1={130.292}
                    y2={301.858}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.029} stopColor="#AA0061" />
                    <stop offset={1} stopColor="#D7003C" />
                </linearGradient>
                <linearGradient
                    id="object-food-potato-b"
                    x1={134.914}
                    x2={105.723}
                    y1={254.252}
                    y2={114.323}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F76430" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </svg>
    );
}
  
