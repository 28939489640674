import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="url(#paint0_linear_10892_7189)"
                d="M627.793 672.915c-2.938-1.236-8.541-3.558-13.041-5.226-7.938-2.943-16.208-8.846-23.19-14.773a168.135 168.135 0 01-7.966-7.232c-4.688 1.386-11.718 9.924-18.41 9.951-13.085.054-22.265-5.783-26.397-6.09a958.238 958.238 0 01-1.665 16.439 9.9 9.9 0 002.329 7.566 9.899 9.899 0 007.112 3.5l20.303 1.018a41.09 41.09 0 0112.375 2.507 70.76 70.76 0 0020.64 4.145c.318.019.7.027 1.136.027 5.149 0 18.056-1.224 25.292-1.954a5.159 5.159 0 004.63-5.337 5.168 5.168 0 00-3.148-4.541z"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M539.453 673.551a9.913 9.913 0 007.111 3.5l20.304 1.017a41.12 41.12 0 0112.374 2.507 70.742 70.742 0 0020.64 4.146c.319.018.7.027 1.137.027 5.148 0 18.056-1.225 25.292-1.954a5.163 5.163 0 004.63-5.338"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_10892_7189"
                    x1="537.063"
                    x2="630.945"
                    y1="665.216"
                    y2="665.216"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E"></stop>
                    <stop offset="0.37" stopColor="#F86431"></stop>
                    <stop offset="1" stopColor="#FB8735"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
