import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-arm-right-option-5">
        <path
            fill={SKIN_COLOR[color]}
            d="M407.357 206.4c1.7 7 3.8 14 5.8 21s3.4 14.1 4.3 21.3c1 7.2 1.7 14.5 2.2 21.8.4 7.4.7 14.8.2 22.3-.8 13-12 22.9-25 22.1-8-.5-14.9-5-18.7-11.3-3.9-6.5-7.2-13.2-10.2-19.9-3-6.7-5.7-13.5-8.2-20.4-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3-4.1-16.6 6-33.4 22.6-37.6 16.6-4.1 33.4 6 37.6 22.6l.1.3Z"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="m395.757 274.9 13-1.9 13-2c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.8-.5 13.2-.6 8.7-.2 15.8 6.7 16 15.4.1 7-4.4 13.1-10.7 15.2-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-12.2 2.4-24-5.6-26.4-17.8-2.4-12.2 5.6-24 17.8-26.4.3 0 .7-.1 1-.1Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M376.157 303.6c-3.9-6.5-7.2-13.2-10.2-19.9-3-6.7-5.7-13.5-8.2-20.4-2.5-6.8-4.6-13.8-6.1-20.9-1.5-7.1-2.8-14.2-4.6-21.3m60.3-14.9v.2c1.7 7 3.8 14 5.8 21s3.4 14.1 4.3 21.3c.8 6.2 1.5 12.4 2 18.7"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M505.856 294.4c-4.2 1.4-8.4 2.6-12.7 3.9-4.2 1.2-8.5 2.4-12.7 3.6-4.2 1.2-8.5 2.3-12.8 3.4-4.3 1.1-8.5 2.2-12.8 3.2-8.5 2.1-17.1 4-25.7 5.7l-12.9 2.5-12.9 2.5c-10.7 2.1-22-5.8-27.2-15.6m36.6-31.3 8.9-1.4c8.7-1.3 17.4-2.4 26.1-3.3 4.4-.4 8.7-.9 13.1-1.3s8.8-.8 13.2-1.1l13.2-.9c4.4-.2 8.8-.5 13.2-.6"
        />
        <path
            fill={SKIN_COLOR[color]}
            d="M510.657 292.2c9.3-4.1 18.2-9.5 25.5-16.6 2.5-2.4 5-4.9 7.4-7.4l1.2-1c10.6-8.1 17.6-20.2 22.8-32.3.6-1.3.4-2.9-.7-3.9.5-.6.1-1.7-.4-2.3-.5-.6-1.4-.8-2.1-.9-1.1-.1-2 .9-2.9 1.6l-.6-.9c-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 7-7.8 10.1-5.3 5.4-12.7 8.7-20.3 8.9h-2.8c.6-3.9 2.7-7.4 4.6-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.6-2.6-1.9-2.9-1.1-.3-2.3.2-2.9 1.2-2.4 4.1-6.3 7.5-9.3 11.1-3.4 4.1-7 8.1-9.7 12.7-2.7 4.6-4.7 9.8-4.5 15.1"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M518.557 254.4c.6-3.9 2.701-7.4 4.601-10.8 1.5-2.7 3-5.6 3.5-8.7.2-1.3-.601-2.6-1.901-2.9-1.1-.3-2.299.2-2.899 1.2-2.4 4.1-6.301 7.5-9.301 11.1-3.4 4.1-6.999 8.1-9.699 12.7s-4.7 9.8-4.5 15.1m41.599-11.2c5.6-3.1 9.2-8.9 12.2-14.5 2.6-4.9 5-10.1 8.7-14.2.9-1 .9-2.6-.2-3.6-.8-.7-1.9-.8-2.8-.3-3.3 1.7-6.2 4.2-8.4 7.1-2.6 3.4-4.9 7-7.8 10.1-5.3 5.4-12.7 8.7-20.3 8.9"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M565.957 230.2c-1.7 3.8-3.5 7.6-5.2 11.4-2.1 4.6-4.2 9.2-6.9 13.5-4.8 7.7-11.2 14.3-17.8 20.6-7.3 7-16.2 12.5-25.5 16.6"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M567.558 234.9c-5.1 12.1-12.2 24.2-22.8 32.3"
        />
    </g>
);

export default SvgComponent;
