import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M524.495 279.902c-20.409-24.152-26.325-32.894-31.648-46.771-2.987-7.787-9.422-18.228-14.919-23.949a47.595 47.595 0 0 0-4.723-2.974c4.064 7.051 4.949 18.802-6.424 23.57a21.984 21.984 0 0 1-8.677 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.283-4.644-14.812-9.444-22.912-12.429a32.686 32.686 0 0 0-10.801-1.712 31.517 31.517 0 0 0-4.396.293 1.194 1.194 0 0 0-.448.161 62.75 62.75 0 0 0-26.518 74.436l18.825 52.892c4.37 12.283 2.109 25.796-6.376 34.829-20.638 21.97-27.936 53.587-27.936 71.668 0 34.797 1.58 70.306 22.466 115.37 35.183 75.911 123.115 127.936 176.852 127.936 12-154.122-10.981-209.859-17.145-238.666a77.093 77.093 0 0 1-1.231-24.424c2.036-18.848 3.521-56.173-11.03-86.565-1.14-2.38-1.843-4.812-2.686-7.11 8.094-3.299 18.171-9.26 20.658-19.015 1.943-7.614-1.083-16.22-8.991-25.577Z"
            fill="url(#female-34-25-dress-5-a)"
        />
        <path
            d="M403.258 205.383c8.1 2.984 15.629 7.785 22.911 12.429 10.812 6.892 21.019 13.399 31.94 13.399a20.755 20.755 0 0 0 8.678-1.436c10.512-4.721 10.92-12.99 8.364-19.636a11.577 11.577 0 0 0-1.207-2.726"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-5-b)">
            <path
                d="m410.73 290.868 5.08 9.293c5.358 11.03 17.156 17.893 29.234 20.012 7.208 1.265 14.814 1.03 21.605-1.697a30.989 30.989 0 0 0 18.534-21.326 30.985 30.985 0 0 0-6.485-27.499l-6.917-10.635"
                fill="url(#female-34-25-dress-5-c)"
            />
        </g>
        <path
            d="M462.617 231.109a239.496 239.496 0 0 0 16.766 23.122"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g filter="url(#female-34-25-dress-5-d)">
            <path
                d="M371.143 382.514c10.154 27.674 36.965 46.753 60.768 62.167 26.061 16.875 54.836 29.522 85.557 34.746 5.584.95 11.308-1.48 12.916-7.333 1.386-5.044-1.712-11.96-7.334-12.917-28.075-4.774-53.96-15.73-77.926-30.952a242.42 242.42 0 0 1-31.913-23.502c-8.601-7.691-17.814-16.88-21.818-27.791-1.952-5.321-7.123-8.926-12.916-7.334-5.126 1.409-9.297 7.566-7.334 12.916Z"
                fill="url(#female-34-25-dress-5-e)"
            />
            <path
                d="M364.784 393.788c7.041 28.185 24.314 52.842 43.011 74.562 18.207 21.151 38.751 41.011 62.299 56.148a167.276 167.276 0 0 0 43.704 20.039c13.005 3.771 18.558-16.487 5.583-20.25-31.983-9.274-58.275-30.298-81.155-53.814-22.561-23.189-45.184-50.212-53.192-82.267-3.274-13.105-23.531-7.553-20.25 5.582Z"
                fill="url(#female-34-25-dress-5-f)"
            />
            <path
                d="M383.836 382.042a277.457 277.457 0 0 0 58.099 27.28c21.812 7.183 44.54 9.258 67.206 12.084 5.637.703 10.5-5.329 10.5-10.5 0-6.259-4.846-9.795-10.5-10.5-20.85-2.6-41.559-4.726-61.623-11.334a253.277 253.277 0 0 1-53.083-25.164c-11.551-7.085-22.113 11.071-10.599 18.134Z"
                fill="url(#female-34-25-dress-5-g)"
            />
        </g>
        <defs>
            <linearGradient
                id="female-34-25-dress-5-a"
                x1={369.463}
                y1={437.324}
                x2={523.745}
                y2={444.459}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#21ACDF" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-5-e"
                x1={381.431}
                y1={425.387}
                x2={519.707}
                y2={423.458}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.236} stopColor="#009CDE" />
                <stop offset={0.445} stopColor="#009CDE" stopOpacity={0.99} />
                <stop offset={0.554} stopColor="#009CDE" stopOpacity={0.954} />
                <stop offset={0.64} stopColor="#009CDE" stopOpacity={0.894} />
                <stop offset={0.715} stopColor="#009CDE" stopOpacity={0.808} />
                <stop offset={0.781} stopColor="#009CDE" stopOpacity={0.696} />
                <stop offset={0.842} stopColor="#009CDE" stopOpacity={0.559} />
                <stop offset={0.898} stopColor="#009CDE" stopOpacity={0.395} />
                <stop offset={0.95} stopColor="#009CDE" stopOpacity={0.211} />
                <stop offset={1} stopColor="#009CDE" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-5-f"
                x1={375.438}
                y1={463.853}
                x2={515.721}
                y2={461.895}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={1} stopColor="#009CDE" stopOpacity={0} />
            </linearGradient>
            <linearGradient
                id="female-34-25-dress-5-g"
                x1={389.142}
                y1={392.723}
                x2={508.934}
                y2={391.051}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={1} stopColor="#009CDE" stopOpacity={0} />
            </linearGradient>
            <filter
                id="female-34-25-dress-5-b"
                x={402.73}
                y={251.016}
                width={91.356}
                height={77.898}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7166"
                />
            </filter>
            <filter
                id="female-34-25-dress-5-d"
                x={356.426}
                y={354.297}
                width={182.285}
                height={198.703}
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                <feGaussianBlur
                    stdDeviation={4}
                    result="effect1_foregroundBlur_11282_7166"
                />
            </filter>
            <radialGradient
                id="female-34-25-dress-5-c"
                cx={0}
                cy={0}
                r={1}
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(-20.922 988.796 -1072.309) scale(77.2111 37.1882)"
            >
                <stop offset={0.433} stopColor="#009CDE" stopOpacity={0} />
                <stop offset={0.578} stopColor="#009CDE" stopOpacity={0.318} />
                <stop offset={0.757} stopColor="#009CDE" stopOpacity={0.682} />
                <stop offset={0.884} stopColor="#009CDE" stopOpacity={0.912} />
                <stop offset={0.946} stopColor="#009CDE" />
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
