import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7327)"
            d="M494.78 222.54a109.63 109.63 0 00-20.026-15.329 31.051 31.051 0 012.394 13.895 66.038 66.038 0 01-1.451 10.762l2.169 4.754a1.001 1.001 0 001.33.493c2.598-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7327)"
            d="M508.181 234.769c-3.192-2.88-6.493-5.859-9.592-8.924l-.121-.119a27.498 27.498 0 00-38.889.457 27.503 27.503 0 00.458 38.889c2.165 2.114 4.287 4.327 6.367 6.534l43.672-35.118c-.632-.572-1.261-1.148-1.895-1.719z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7327"
                x1="516.612"
                x2="395.166"
                y1="188.529"
                y2="297.83"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7327"
                x1="523.65"
                x2="402.205"
                y1="196.357"
                y2="305.658"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
