import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10284)"
            d="M486.582 276.821c-5.057-1.826-10.241-3.697-15.402-5.347a26.497 26.497 0 01-17.266-33.338 26.513 26.513 0 0113.14-15.508 26.494 26.494 0 0120.259-1.638l.13.042c5.173 1.682 10.499 3.198 15.648 4.664l4.111 1.175-17.987 50.898-2.633-.948z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_10284)"
            d="M476.74 232.553a64.883 64.883 0 001.406-10.606 33.84 33.84 0 00-1.403-11.517 105.903 105.903 0 0117.336 13.629 1.7 1.7 0 01.535 1.597c-.522 3.026-8.33 7.883-15.838 11.355l-2.036-4.458z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M487.314 220.984l.13.042c5.173 1.683 10.498 3.199 15.647 4.664l4.111 1.175-17.987 50.899-2.633-.949"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10284"
                x1="487.056"
                x2="463.664"
                y1="206.194"
                y2="301.154"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#E2E2E2"></stop>
                <stop offset="0.966" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_10284"
                x1="488.282"
                x2="466.465"
                y1="207.484"
                y2="296.05"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#E2E2E2"></stop>
                <stop offset="0.966" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
