import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={52}
            fill="none"
            id="object-food-cloudberry"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9.94 10.813a13.795 13.795 0 0 0 9.117 4.04 13.79 13.79 0 0 0-4.039-9.119A13.795 13.795 0 0 0 5.9 1.695a13.79 13.79 0 0 0 4.04 9.118ZM28.175 10.813a13.795 13.795 0 0 1-9.118 4.04 13.79 13.79 0 0 1 4.039-9.119 13.797 13.797 0 0 1 9.118-4.039 13.79 13.79 0 0 1-4.04 9.118Z"
            />
            <path
                fill="#FFB33B"
                d="M14.402 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M23.713 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM5.09 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M14.402 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M23.713 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M33.025 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.746 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M19.056 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M28.369 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.746 43.12a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.313Z"
            />
            <path
                fill="#FFB33B"
                d="M19.056 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#FFB33B"
                d="M28.369 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
        </svg>
    );
}
