import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shirt-3-cycling-2-a)">
        <path
            d="M499.757 279.215a196.037 196.037 0 0 0-6.026-44.017h-.007c-4.135-15.817-12.24-41.378-27.795-54.417a12.505 12.505 0 0 1-13.492 9.952c-9.083-.738-16.904-6.67-23.241-13.22-6.537-6.814-13.179-18.658-24.327-23.064-11.407 2.957-22.312 8.756-32.49 17.307-54.522 45.811-58.153 129.037-71.64 206.826 0 0 46.901 51.545 111.602 57.368 32.53 2.928 59.53-19.843 66.687-41.313 9.649-28.947 10.156-44.597 8.098-64.648a41.453 41.453 0 0 1 7.214-28.085c3.957-5.623 5.812-13.772 5.417-22.689Z"
            fill="url(#man-34-25-shirt-3-cycling-2-b)"
        />
        <path
            d="M499.757 279.215a196.037 196.037 0 0 0-6.026-44.017h-.007c-4.135-15.817-12.24-41.378-27.795-54.417a12.505 12.505 0 0 1-13.492 9.952c-9.083-.738-16.904-6.67-23.241-13.22-6.537-6.814-13.179-18.658-24.327-23.064-11.407 2.957-22.312 8.756-32.49 17.307-54.522 45.811-58.153 129.037-71.64 206.826 0 0 46.901 51.545 111.602 57.368 32.53 2.928 59.53-19.843 66.687-41.313 9.649-28.947 10.156-44.597 8.098-64.648a41.453 41.453 0 0 1 7.214-28.085c3.957-5.623 5.812-13.772 5.417-22.689Z"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-3-cycling-2-b"
                x1={300.739}
                y1={295.327}
                x2={499.809}
                y2={295.327}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <clipPath id="man-34-25-shirt-3-cycling-2-a">
                <path
                    fill="#fff"
                    transform="translate(.907 .602)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
