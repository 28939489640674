import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-cycling-1-left">
        <path
            d="M501.402 201.371a131.614 131.614 0 0 0-35.07-32.516l6.968 50.433s31.458-13.428 28.102-17.917Z"
            fill="#001489"
        />
        <path
            d="M504.876 204.793c-6.721-8.346-15.922-13.434-26.653-13.434a31.858 31.858 0 0 0-17.743 5.392 32.038 32.038 0 0 0-8.84 44.384c3.242 4.854 6.363 9.917 9.382 14.813 1.325 2.15 2.654 4.296 3.987 6.437a195.514 195.514 0 0 0 14.742 20.524 453.356 453.356 0 0 0 2.727 3.349l54.061-30.935c-5.638-12.687-21.999-38.532-31.663-50.53Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
