import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-4">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-4-a)"
            d="M413.263 265.22c.085-3.028.133-6.084.141-9.149a161.97 161.97 0 0 0-1.334-22.991 876.48 876.48 0 0 0-.915-6.839c-.706-5.185-1.436-10.547-1.942-15.833l-.017-.17a31.885 31.885 0 0 0-10.339-20.535 31.883 31.883 0 0 0-21.461-8.246 32.038 32.038 0 0 0-31.875 35.225c.534 5.279.863 10.678 1.181 15.9.14 2.297.28 4.595.437 6.888a162.126 162.126 0 0 0 3.174 22.805 363.2 363.2 0 0 0 2.527 11.584l60.423-8.639Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-4-a"
                x1={355.321}
                x2={393.056}
                y1={189.956}
                y2={262.823}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
