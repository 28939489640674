import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#888B8D"
            d="M573.159 594.99l-3.29-40.5c-1.599-15.049-.145-29.603 2.331-46.994.64-4.164 1.448-8.349 2.314-12.579l-97.878-.164-4.512-.577c-2.94-.382-5.962-.853-8.984-1.401a53.359 53.359 0 01-42.927-61.96 53.243 53.243 0 0156.051-43.665l7.251.497c2.761.188 5.734.482 9.086.899l4.495.579 4.492.684c2.216.314 4.455.713 6.621 1.099.77.138 1.54.274 2.31.409 2.228.369 4.476.826 6.65 1.268l2.244.454c12.696 2.633 24.221 5.405 35.243 8.477 5.417 1.528 11.095 3.45 17.361 5.876a307.312 307.312 0 0117.064 7.2c1.283.577 2.67 1.228 4.237 1.987a164.59 164.59 0 014.217 2.07c1.815.893 3.649 1.882 5.422 2.838.978.526 1.955 1.054 2.936 1.569a165.307 165.307 0 0116.527 10.37l.87.636a37.354 37.354 0 0112.065 14.989 30.505 30.505 0 012.152 15.904l-5.724 46.617-2.873 23.308-5.406 60.11h-50.345z"
        ></path>
    </g>
);

export default SvgComponent;
