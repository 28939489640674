import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-1-stand-1">
        <path
            fill="#888B8D"
            d="M451.302 804.3v-.9c0-.1 0-.1.1-5.2 1.4-53 2.1-83 2.1-89.3-.2-17.5 3.3-45.3 11.5-67.8 3.7-10.2 4-21.6.7-32.1-14.1-44.6-31.7-110.2-39-160.5-4.1-28.3 15.6-54.7 43.9-58.8 2.5-.4 5-.5 7.5-.5 23.7 0 45.3 16 51.2 39 23.8 97.6 7 201 6.3 204-24.7 99.6-33.5 129.1-39.2 150.6-2.1 8.2-4 15.2-6.4 24.5l-.2.8-38.5-3.8Z"
        />
        <path
            fill="url(#man-34-25-pants-1-stand-1-a)"
            d="M370.501 386.9c-9.2 15.9-16.1 25.8-17.5 44.3-.8 4-1.1 8-.9 12.2 1.6 38.4 8.7 89 19.4 139 .9 4.2 2.5 13.8 4 23.2.8 4.7 1.5 9.3 2.2 13.3 1.2 6.8.9 13.8-.9 20.8-5.9 24.1-7.2 59.8-2.8 83 .9 5.2 3.7 24.5 6.6 44.9 3.6 24.6 7.3 50.1 8 53.3l.2.8 41-2.8.1-.9c.9-12.8 1.5-22.9 2.2-32 2.3-34.5 6.7-55.3 13.3-160.2 8.3-28.1 13.5-101.6 15.6-134.9.7-11 5.2-21.3 12.7-29.3l24.8-29.5s24.6-11.7 33.3-45.2h-161.3Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-1-stand-1-a"
                x1={352.043}
                x2={531.562}
                y1={604.204}
                y2={604.204}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
