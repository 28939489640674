import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-right-option-7">
        <path
            fill="url(#man-34-25-tshirt-2-arm-right-option-7-a)"
            d="M333.162 277.807c.669-7.981 1.754-16.261 2.342-21.158a202.171 202.171 0 0 1 4.448-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341a32.132 32.132 0 0 1 31.284-25.418c2.224.002 4.442.234 6.619.693a31.79 31.79 0 0 1 20.213 13.831 31.808 31.808 0 0 1 4.668 11.584 31.798 31.798 0 0 1-.156 12.488l-.031.149c-1.252 5.808-2.314 11.763-3.341 17.522-.451 2.531-.903 5.063-1.371 7.591a202.227 202.227 0 0 1-6.255 24.938 450.804 450.804 0 0 1-4.618 14.119c-16.132-6.679-42.807-10.294-59.73-6.174Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-arm-right-option-7-a"
                x1={375.377}
                x2={363.232}
                y1={283.795}
                y2={170.156}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
