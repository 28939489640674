import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={233}
            height={72}
            fill="none"
            id="object-food-breast-raw"
            {...attributes}
        >
            <path
                fill="url(#object-food-breast-raw-a)"
                d="M80.476 1.768c-34.517 0-64.104 17.47-76.554 42.31-6.044 12.06 2.919 26.255 16.408 26.255h199.407c10.499 0 15.604-12.73 8.096-20.07C199.113 22.19 117.793 1.769 80.476 1.769Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M26.789 18.112c-9.91 6.985-17.805 15.867-22.867 25.967C-2.122 56.138 6.84 70.333 20.33 70.333h199.407c10.499 0 15.604-12.73 8.096-20.07C199.113 22.19 117.793 1.769 80.476 1.769c-8.36 0-16.428 1.025-24.035 2.93"
            />
            <defs>
                <linearGradient
                    id="object-food-breast-raw-a"
                    x1={81.743}
                    x2={235.596}
                    y1={37.905}
                    y2={115.21}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFF4C3" />
                    <stop offset={0.127} stopColor="#FFEB94" />
                    <stop offset={0.269} stopColor="#FFE367" />
                    <stop offset={0.412} stopColor="#FFDC42" />
                    <stop offset={0.555} stopColor="#FFD725" />
                    <stop offset={0.696} stopColor="#FFD310" />
                    <stop offset={0.835} stopColor="#FFD104" />
                    <stop offset={0.969} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
