import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-legs-cycling-left">
        <g clipPath="url(#man-bold-34-25-legs-cycling-left-a)">
            <path
                d="M441.232 633.157a630.134 630.134 0 0 1 4.189-22.537c.764-3.728 1.449-7.49 2.278-11.188l2.428-11.121c1.594-7.425 3.313-14.794 4.684-22.318 1.437-7.494 2.543-15.135 4.308-22.484 3.286-14.807 9.886-28.149 17.029-41.252a204.845 204.845 0 0 1 12.054-19.063c1.081-1.555 2.272-3.061 3.402-4.594 1.202-1.501 2.359-3.023 3.622-4.497a96.146 96.146 0 0 1 8.055-8.635l9.305-8.744a26.065 26.065 0 0 1 41.005 30.967l-20.53 39.706-10.275 19.848-10.404 19.792c-6.957 13.184-13.825 26.409-20.856 39.561l-21.022 39.489a15.915 15.915 0 0 1-27.699.704 15.915 15.915 0 0 1-2.053-10.782l.48-2.852Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M519.927 501.781a1073.098 1073.098 0 0 1-31.953-7.448l-15.902-3.871c-5.292-1.307-10.646-2.492-15.9-3.875a259.922 259.922 0 0 1-15.538-4.588c-2.557-.828-5.074-1.736-7.613-2.6l-3.8-1.313-3.761-1.39a356.057 356.057 0 0 1-14.915-5.811l-3.684-1.542-3.646-1.614a161.03 161.03 0 0 1-7.198-3.416 50.699 50.699 0 0 1 38.726-93.41l7.505 2.488c2.55.843 5.037 1.81 7.49 2.842l3.669 1.57 3.61 1.688c2.425 1.088 4.767 2.34 7.138 3.535 2.376 1.183 4.704 2.464 7.03 3.747 4.651 2.568 9.206 5.324 13.728 8.147 4.519 2.827 8.962 5.803 13.295 8.997 4.335 3.189 8.507 6.699 12.604 10.358 4.089 3.672 8.201 7.301 12.185 11.18a205.329 205.329 0 0 1 22.765 25.517l3.8 5.186a29.088 29.088 0 0 1-29.635 45.623Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="m440.751 636.007.482-2.852a628.322 628.322 0 0 1 4.188-22.537c.764-3.727 1.449-7.489 2.278-11.188l2.429-11.12c1.593-7.426 3.312-14.795 4.683-22.319 1.437-7.494 2.543-15.135 4.308-22.484 3.287-14.806 9.886-28.149 17.029-41.252a231.358 231.358 0 0 1 3.6-6.189M549.563 456.156c7.78 9.125 8.936 21.997 4.028 31.533l-20.53 39.705-10.276 19.848-10.404 19.792c-6.957 13.185-13.824 26.409-20.855 39.562l-21.022 39.488M487.974 494.333l-15.902-3.871c-5.292-1.307-10.646-2.492-15.9-3.875a259.922 259.922 0 0 1-15.538-4.588c-2.557-.828-5.074-1.736-7.613-2.6l-3.8-1.313-3.761-1.39a356.057 356.057 0 0 1-14.915-5.811l-3.684-1.542-3.646-1.614a161.03 161.03 0 0 1-7.198-3.416 50.699 50.699 0 0 1 38.726-93.41l7.505 2.488c2.55.843 5.037 1.81 7.49 2.842l3.669 1.57 3.61 1.688c2.425 1.088 4.767 2.34 7.138 3.535 2.376 1.183 4.704 2.464 7.03 3.747 4.651 2.568 9.206 5.324 13.728 8.147 4.519 2.827 8.962 5.803 13.295 8.997 4.335 3.189 8.507 6.699 12.604 10.358 4.089 3.672 8.201 7.301 12.185 11.18a205.329 205.329 0 0 1 22.765 25.517l3.8 5.186"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M440.843 634.227c-1.433 4.096-9.461 18.606-14.747 27.99a8.982 8.982 0 0 0-.752 7.089 8.996 8.996 0 0 0 4.643 5.409l18.264 8.928a42.212 42.212 0 0 1 10.64 7.35 69.63 69.63 0 0 0 17.096 11.763c3.673 1.826 18.019 6.41 24.908 8.572a4.156 4.156 0 0 0 4.233-6.849c-2.333-2.414-6.478-6.656-9.901-9.906-15.366-14.592-25.171-46.067-25.171-46.067"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M440.843 634.227c-1.433 4.096-9.461 18.606-14.747 27.99a8.982 8.982 0 0 0-.752 7.089 8.996 8.996 0 0 0 4.643 5.409l18.264 8.928a42.212 42.212 0 0 1 10.64 7.35 69.63 69.63 0 0 0 17.096 11.763c3.673 1.826 18.019 6.41 24.908 8.572a4.156 4.156 0 0 0 4.233-6.849c-2.333-2.414-6.478-6.656-9.901-9.906-15.366-14.592-25.171-46.067-25.171-46.067"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="man-bold-34-25-legs-cycling-left-a">
                <path fill="#fff" transform="translate(.893 .54)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
