import { ILegs } from 'interfaces';

import stand1 from './man-34-25-shoes-4-stand-1';
import stand2 from './man-34-25-shoes-4-stand-2';
import walk1 from './man-34-25-shoes-4-walk-1';
import walk2 from './man-34-25-shoes-4-walk-2';
import sitting1right from './man-34-25-shoes-4-sitting-1-right';
import sitting1left from './man-34-25-shoes-4-sitting-1-left';
import sitting2right from './man-34-25-shoes-4-sitting-2-right';
import sitting2left from './man-34-25-shoes-4-sitting-2-left';

const shoes4: ILegs = {
    name: 'Option #4',
    stand1: [stand1],
    stand2: [stand2],
    walk1: [walk1],
    walk2: [walk2],
    sitting1: [sitting1right, sitting1left],
    sitting2: [sitting2right, sitting2left],
};

export default shoes4;
