import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 176,
        height: 175,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21.723 74.668c5.06-26.72 25.91-47.85 52.48-53.34M101.293 153.332c26.09-5.26 46.73-25.589 52.41-51.52M40.273 134.938c9.19 9.189 21.02 15.729 34.26 18.399M101.625 21.328c26.56 5.49 47.41 26.62 52.47 53.34M25.636 113.042a67.24 67.24 0 0 1-3.53-11.23"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M26.955 148.256c-15.6-15.6-25.26-37.15-25.26-60.96 0-47.61 38.6-86.21 86.22-86.21s86.21 38.6 86.21 86.21c0 47.61-38.6 86.21-86.21 86.21-12.11 0-23.64-2.49-34.09-7"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M87.91 55.383v31.914l15.114 15.114M40.273 134.938l-13.32 13.319M74.203 21.328c.7 6.95 6.57 12.38 13.71 12.38 7.14 0 13.01-5.43 13.71-12.38M21.723 74.664c6.58 1.03 11.62 6.73 11.62 13.61 0 6.74-4.84 12.35-11.24 13.54M74.535 153.334c1.47-6.02 6.91-10.49 13.38-10.49 6.47 0 11.91 4.47 13.38 10.49M153.704 101.814c-6.39-1.2-11.22-6.81-11.22-13.54 0-6.87 5.03-12.57 11.61-13.61"
                />
            </>
        ),
    },
    'Outline #2': {
        width: 175,
        height: 175,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M87.34 55.383v31.913l15.113 15.114M87.34 19.93v14.488M19.969 87.297h14.489M87.34 154.669V140.18M154.711 87.297h-14.488"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M25.064 113.043a67.144 67.144 0 0 1-5.099-25.747c0-37.21 30.17-67.37 67.38-67.37 37.21 0 67.37 30.16 67.37 67.37 0 37.21-30.17 67.37-67.37 67.37-18.6 0-35.45-7.54-47.64-19.73l-13.32 13.32c-15.6-15.6-25.26-37.15-25.26-60.96 0-47.61 38.6-86.21 86.22-86.21s86.21 38.6 86.21 86.21c0 47.61-38.6 86.21-86.21 86.21-12.11 0-23.64-2.49-34.09-7"
                />
            </>
        ),
    },
    'Outline #3': {
        width: 175,
        height: 175,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M87.77 55.383v31.913l15.113 15.114M87.77 12.68v14.489M12.71 87.297H27.2M87.77 162.473v-14.489M162.825 87.297h-14.489"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M25.494 113.043a67.144 67.144 0 0 1-5.1-25.747c0-37.21 30.17-67.37 67.38-67.37 37.211 0 67.371 30.16 67.371 67.37 0 37.21-30.17 67.37-67.37 67.37-18.6 0-35.45-7.54-47.64-19.73l-13.32 13.32c-15.6-15.6-25.26-37.15-25.26-60.96 0-47.61 38.6-86.21 86.22-86.21s86.21 38.6 86.21 86.21c0 47.61-38.6 86.21-86.21 86.21-12.11 0-23.64-2.49-34.09-7"
                />
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-clock"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
