import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={38}
            fill="none"
            id="object-food-hazelnut"
            {...attributes}
        >
            <path
                fill="url(#object-food-hazelnut-a)"
                d="M33.19 32.187c7.593-7.593 3.692-28.323 2.26-29.755C34.018 1 13.287-2.901 5.695 4.69c-7.593 7.593-7.593 19.903 0 27.496s19.903 7.593 27.496 0h-.001Z"
            />
            <defs>
                <linearGradient
                    id="object-food-hazelnut-a"
                    x1={0}
                    x2={37.731}
                    y1={19.016}
                    y2={19.016}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
