import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-arm-right-option-3">
        <path
            fill="url(#man-34-25-tshirt-arm-right-option-3-a)"
            d="M430.662 250.955a242.75 242.75 0 0 0-11.066-25.884 1072.34 1072.34 0 0 0-3.805-7.58c-2.884-5.716-5.866-11.627-8.604-17.524l-.059-.128a32.148 32.148 0 0 0-28.986-18.405 31.722 31.722 0 0 0-13.582 3.042 31.788 31.788 0 0 0-16.498 18.101 31.807 31.807 0 0 0 1.135 24.466c2.766 5.889 5.385 11.976 7.918 17.862 1.115 2.592 2.235 5.18 3.361 7.766a242.301 242.301 0 0 0 12.736 25.1c2.135 3.735 4.24 7.32 6.338 10.802l55.041-26.647a549.106 549.106 0 0 0-3.929-10.971Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-arm-right-option-3-a"
                x1={363.145}
                x2={416.928}
                y1={182.366}
                y2={295.571}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
