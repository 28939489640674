import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#001489"
            d="M634.147 513.193c2.419-16.022 8.686-57.769 11.18-74.392 2.014-13.006-4.797-23.162-16.083-29.897-2.143-1.279-5.953-3.23-8.079-4.468-15.848-9.102-28.728-14.261-43.326-20.298-19.142-8.311-40.903-13.295-63.333-15.413-27.686-3.551-53.134 10.4-61.854 37.401a54.115 54.115 0 003.803 41.412 54.106 54.106 0 0032.064 26.482 109.833 109.833 0 0014.101 3.359l61.392-.005c.423-.027 6.147-.057 10.89-.081-3.663 19.315-6.03 37.887-4.583 55.111 1.17 14.686 5.648 89.392 5.937 99.006h44.386c.867-21.903 11.715-106.82 13.505-118.217z"
        ></path>
        <path
            fill="url(#paint0_linear_10892_7250)"
            d="M621.393 620.561c.413-4.792.966-10.428 1.61-16.564l-48.206-.153c.36 6.257.68 11.945.928 16.574l45.668.143z"
        ></path>
        <path
            fill="url(#paint1_linear_10892_7250)"
            d="M624.563 589.691c1.916-17.006 4.2-35.65 6.085-50.42l-59.847-.201c.784 11.414 2.044 31.7 3.158 50.456l50.604.165z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10892_7250"
                x1="574.797"
                x2="623.003"
                y1="612.203"
                y2="612.203"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10892_7250"
                x1="570.801"
                x2="630.648"
                y1="564.381"
                y2="564.381"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#009CDE"></stop>
                <stop offset="0.9" stopColor="#39BDE8"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
