import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9069)"
            d="M494.779 223.228a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9069)"
            d="M511.146 245.484c-2.546-4.043-5.433-8.626-8.066-13.178l-.071-.122a27.478 27.478 0 00-16.772-12.719 27.497 27.497 0 00-20.852 2.876 27.5 27.5 0 00-9.844 37.624c2.656 4.537 5.186 9.26 7.634 13.828l.509.95a91.21 91.21 0 0121.386-17.957 93.173 93.173 0 0126.624-10.431l-.548-.871z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9069"
                x1="487.173"
                x2="466.571"
                y1="205.224"
                y2="283.73"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9069"
                x1="491.715"
                x2="471.112"
                y1="206.412"
                y2="284.918"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
