import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-4-arm-left-up">
        <path
            d="m465.022 167.656 2.654.951c13.052 4.678 23.789 10.343 32.825 17.32a2.674 2.674 0 0 1 1.092 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-4-arm-left-up-a)"
        />
        <path
            d="M507.432 187.677c-6.325-1.437-12.865-2.924-19.239-4.59a32.096 32.096 0 0 0-8.113-1.048 31.985 31.985 0 0 0-19.606 6.72 31.985 31.985 0 0 0-11.367 17.33 32.033 32.033 0 0 0 23.038 38.953c9.214 2.367 18.206 5.127 27.285 7.712 17.29 5.038 37.663 8.128 55.344 10.31l1.79-60.579c-15.962-6.068-32.705-11.461-49.132-14.808Z"
            fill="url(#man-34-25-tshirt-4-arm-left-up-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-up-a"
                x1={479.502}
                y1={143.035}
                x2={506.394}
                y2={388.529}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-4-arm-left-up-b"
                x1={494.818}
                y1={140.539}
                x2={521.71}
                y2={386.033}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#0197D6" />
                <stop offset={0.558} stopColor="#22ADE0" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
