import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8187)"
            d="M494.769 223.408a2.713 2.713 0 01.822 2.482c-.805 4.666-13.808 10.893-16.407 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_8187)"
            d="M511.858 243.495c-3.467-4.38-7.051-8.91-10.445-13.525l-.076-.103a27.642 27.642 0 00-22.095-11.101 27.496 27.496 0 00-27.375 29.881 27.5 27.5 0 005.32 14.019c3.404 4.582 6.681 9.336 9.851 13.933l2.186 3.158 42.875-34.356.712-.698-.953-1.208z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8187"
                x1="494.897"
                x2="449.5"
                y1="186.029"
                y2="316.149"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_8187"
                x1="501.428"
                x2="456.03"
                y1="188.307"
                y2="318.428"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
