import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M479.222 236.749c-.182-4.782-.96-19.423-3.837-27.14a101.329 101.329 0 0 1 18.684 14.4 1.706 1.706 0 0 1 .535 1.598c-.512 2.97-8.018 7.691-15.382 11.142Z"
            fill="url(#female-34-25-shirt-1-arm-opt-7-left-a)"
        />
        <path
            d="M578.805 259.712c-.117-2.638-.132-5.267-.358-7.914l-.275-3.965c-.096-1.322-.26-2.65-.386-3.974a183.415 183.415 0 0 0-.751-6.194c-4.596 5.386-22.09 5.675-28.365-4.899-.953 2.796-1.792 5.602-2.581 8.412-.348 1.284-.732 2.566-1.048 3.853l-.938 3.862c-.42 1.617-.755 3.24-1.085 4.864a380.568 380.568 0 0 0-11.31-8.187 167.908 167.908 0 0 0-19.359-11.867c-6.7-3.517-13.486-6.88-20.071-10.602l-.119-.067a26.493 26.493 0 0 0-20.144-2.358 26.502 26.502 0 0 0-5.694 48.634c6.603 3.687 13.006 7.733 19.496 11.624a167.626 167.626 0 0 0 20.206 10.358c6.95 3.071 14.003 5.959 21.197 8.594a209.551 209.551 0 0 0 22.15 6.9c1.096.261 2.212.425 3.337.49a18.497 18.497 0 0 0 21.255-8.456 18.513 18.513 0 0 0 2.437-7.78c.426-5.219 1.184-10.41 1.799-15.614.606-5.215.809-10.468.607-15.714Z"
            fill="url(#female-34-25-shirt-1-arm-opt-7-left-b)"
        />
        <path
            d="m492.16 223.031.119.067c6.585 3.722 13.371 7.085 20.071 10.602a167.81 167.81 0 0 1 19.358 11.867 392.502 392.502 0 0 1 8.754 6.268M554.161 307.311a19.308 19.308 0 0 1-4.79-.53 209.534 209.534 0 0 1-22.149-6.9c-7.195-2.635-14.248-5.523-21.198-8.594a167.626 167.626 0 0 1-20.206-10.358c-6.489-3.891-12.892-7.937-19.496-11.624M556.458 307.976a18.498 18.498 0 0 0 19.941-16.935c.426-5.22 1.184-10.411 1.799-15.615.607-5.215.809-10.469.607-15.715-.117-2.637-.131-5.266-.357-7.913l-.275-3.965c-.096-1.322-.26-2.65-.387-3.974l-.75-6.194c-4.596 5.385-22.09 5.675-28.365-4.899l-2.582 8.412c-.347 1.284-.732 2.566-1.048 3.853l-.937 3.862c-.668 2.571-1.124 5.16-1.682 7.741a101.942 101.942 0 0 0-2.041 15.594c-.268 5.232-.393 10.477-.85 15.694l-.01.112"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-7-left-a"
                x1={487.498}
                y1={208.085}
                x2={469.518}
                y2={276.598}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-7-left-b"
                x1={452.736}
                y1={263.853}
                x2={578.879}
                y2={263.853}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
