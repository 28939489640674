import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M478.221 236.781c-.166-4.363-.928-19.193-3.775-26.828l-1.016-2.726 2.477 1.525a102.53 102.53 0 0 1 18.859 14.537c.335.311.587.701.731 1.135.143.435.175.898.091 1.348-.788 4.562-13.42 10.694-15.943 11.876l-1.368.641-.056-1.508Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-7-left-a)"
        />
        <path
            d="M557.981 309.035c-.532 0-1.069-.021-1.607-.065a19.837 19.837 0 0 1-3.843-.705 20.398 20.398 0 0 1-3.396-.509 213.387 213.387 0 0 1-22.257-6.933c-6.887-2.522-13.84-5.341-21.261-8.619a169.574 169.574 0 0 1-20.318-10.417 701.486 701.486 0 0 1-5.833-3.535c-4.44-2.704-9.029-5.501-13.634-8.072a27.534 27.534 0 0 1-10.604-37.417 27.54 27.54 0 0 1 24.035-14.099 27.519 27.519 0 0 1 13.383 3.494l.124.07c4.583 2.59 9.351 5.051 13.963 7.431 2.029 1.048 4.055 2.099 6.079 3.155a169.27 169.27 0 0 1 19.471 11.937 375.714 375.714 0 0 1 10.072 7.258c.244-1.161.491-2.263.779-3.371l.933-3.847c.209-.852.447-1.698.685-2.544.125-.445.248-.889.37-1.334.92-3.279 1.745-5.968 2.6-8.473l.699-2.053 1.108 1.865c3.531 5.952 10.829 8.072 16.48 8.072 4.518 0 8.547-1.3 10.264-3.312l1.441-1.688.31 2.198c.262 1.864.51 3.902.755 6.228.046.474.094.939.143 1.404.092.868.183 1.736.246 2.602l.276 3.969c.153 1.792.211 3.608.265 5.364.028.858.055 1.715.093 2.575a103.558 103.558 0 0 1-.612 15.875 615.087 615.087 0 0 1-.582 4.7c-.451 3.564-.917 7.251-1.213 10.879a19.596 19.596 0 0 1-19.414 17.917Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-7-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-7-left-a"
                x1={451.894}
                y1={214.252}
                x2={625.388}
                y2={275.843}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-7-left-b"
                x1={446.162}
                y1={230.401}
                x2={619.656}
                y2={291.991}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#5AC2E7" />
                <stop offset={1} stopColor="#D2F0FA" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
