import React from 'react';
import { THairColor } from 'interfaces';

const HAIR_COLOR: Record<THairColor, string> = {
    black: 'man-34-25-haircut-8a-black',
    brown: 'man-34-25-haircut-8a-brown',
    red: 'man-34-25-haircut-8a-red',
    yellow: 'man-34-25-haircut-8a-yellow',
    gray: 'man-34-25-haircut-8a-gray'
};

const SvgComponent = ({ color }: { color: THairColor }) => (
    <g id="man-34-25-haircut-8" transform="translate(329, 20)">
        <path
            fill={`url(#${HAIR_COLOR[color]})`}
            d="M84.4 108.4c-.6-1-1-1.9-1.3-2.5-.6-1.2-1.1-2.5-1.7-3.7-3.1-6.7-6.3-13.5-7.7-20.8-2.3-12.2 1.3-25.6 9.5-35.8 4.4-5.5 17.299-14.3 33.299-14.6 8.6-.2 16.601 2.2 23.901 7 1.2 2.9 1.1 5.5-.6 5.9-1.2.3-2.601 0-3.701-.3-1.4-.3-2.799-.7-4.199-1.1-6.1-1.8-12.7-4.2-19.2-3.1-2.4.4-5.1 1.4-6.4 3.7-1.1 2-.7 4.4 0 6.5 2 5.6 6.899 10.7 5.799 17.1-.5 2.5-1.599 4.9-2.799 7.2-.3.4-.6.9-.9 1.3-3.1 4.9-1.6 9-1.6 16h-5.301c-1.1-3.5-4.2-7-7.8-8.5-3.6-1.6-7.5-2.2-9.9.9-2.5 3.3-1.4 9.5.1 13.4 1.6 4.3 5.6 9 10 12-.394 6.807-1.47 13.03-3.323 19.629-.034.121-.153.144-.222.038a.231.231 0 01-.044-.148c.959-7.576-3.42-15.635-5.91-20.119z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit="10"
            strokeWidth="2"
            d="M98.3 85.8c-1.9-1.6-3.8-3.3-6.2-4.2-2.3-.9-5.2-.9-7.1.7-2.1 1.7-2.6 4.8-2.5 7.6.5 8.4 5.9 16.4 13.5 19.9"
        ></path>
        <defs>
            <linearGradient
                id={HAIR_COLOR.gray}
                x1={144.428}
                y1={10.688}
                x2={68.203}
                y2={111.842}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor="#A0A4AC" stopOpacity={0.805} />
                <stop offset={0.328} stopColor="#B6B3B5" stopOpacity={0.646} />
                <stop offset={0.962} stopColor="#EFDACD" stopOpacity={0.318} />
                <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
            </linearGradient>
            <linearGradient
                id={HAIR_COLOR.red}
                x1={144.79}
                y1={10.688}
                x2={68.564}
                y2={111.842}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor="#F46A25" stopOpacity={0.805} />
                <stop offset={0.184} stopColor="#F4793B" stopOpacity={0.721} />
                <stop offset={0.521} stopColor="#F39F74" stopOpacity={0.546} />
                <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
            </linearGradient>
            <linearGradient
                id={HAIR_COLOR.yellow}
                x1={145.108}
                y1={10.688}
                x2={68.882}
                y2={111.841}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor="#E5BC56" stopOpacity={0.805} />
                <stop offset={0.241} stopColor="#E7C26C" stopOpacity={0.692} />
                <stop offset={0.694} stopColor="#EED1A5" stopOpacity={0.457} />
                <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
            </linearGradient>
            <linearGradient
                id={HAIR_COLOR.brown}
                x1={144.471}
                y1={10.688}
                x2={68.246}
                y2={111.841}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor="#7A5430" stopOpacity={0.805} />
                <stop offset={0.194} stopColor="#8B6746" stopOpacity={0.716} />
                <stop offset={0.552} stopColor="#B6987F" stopOpacity={0.53} />
                <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
            </linearGradient>
            <linearGradient
                id={HAIR_COLOR.black}
                x1={145.223}
                y1={10.688}
                x2={68.998}
                y2={111.842}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.023} stopColor="#2B2B2B" stopOpacity={0.805} />
                <stop offset={0.163} stopColor="#413F3D" stopOpacity={0.732} />
                <stop offset={0.455} stopColor="#7A726C" stopOpacity={0.581} />
                <stop offset={0.871} stopColor="#D5C2B7" stopOpacity={0.365} />
                <stop offset={0.996} stopColor="#F2DCCE" stopOpacity={0.3} />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
