import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path d="m431.26 843.742 8.096 31.59 7.112.302-.651-9.482" fill="#EF0025" />
            <path
                d="M439.357 875.328h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m437.919 825.501 30.318-.032-1.244-3.235-34.75-.101-2.771 12.607a20.564 20.564 0 0 0 6.262 19.301c.924.861 5.42 5.364 5.42 5.364s.468-7.43.728-9.747c.51-4.545 1.038-9.245.296-13.886a27.752 27.752 0 0 0-4.259-10.271Z"
                fill="url(#female-34-25-shoes-11-walk-2-a)"
            />
            <path
                d="M480.869 852.147s-2.467-.202-3.869-.202a67.135 67.135 0 0 0-9.439.67 34.618 34.618 0 0 0-9.828 2.673 18.266 18.266 0 0 0-7.918 6.684 17.98 17.98 0 0 0-2.257 5.821l-.095.436 1.524 2.108a15.043 15.043 0 0 0 12.215 6.063h1.074l-.007-1.009a12.32 12.32 0 0 1 4.338-9.397 16.275 16.275 0 0 1 6.888-3.136 51.27 51.27 0 0 1 7.89-.916l9.997-.638-10.513-9.157Z"
                fill="url(#female-34-25-shoes-11-walk-2-b)"
            />
            <path
                d="M492.927 873.841a9.323 9.323 0 0 1-6.504 2.561h-25.219a15.029 15.029 0 0 1-12.214-6.062s-15.174-19.202-16.207-20.934"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m330.247 802.852-8.36 30.23 4.226 2.736 7.507-13.608a4.549 4.549 0 0 1 5.277-2.161c.659.197 1.265.54 1.773 1.005"
                fill="#EF0025"
            />
            <path
                d="m321.887 833.086 4.226 2.736"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M342.372 810.435c3.011-7.952 8.46-15.442 4.689-26.66l28.276 14.154-.245-3.904-31.5-16.048-12.09 18.108c-3.976 5.932-2.311 13.85 3.168 18.43l4.702 3.927a18.881 18.881 0 0 1 3.187 3.316c-.309-3.079-1.702-7.322-.187-11.323ZM380.585 839.113s-2.972-.574-3.978-.678a21.449 21.449 0 0 0-12.669 2.215 14.638 14.638 0 0 0-7.085 8.794l-.162.615.489.406c.91.768 1.876 1.468 2.889 2.094l4.688 2.86c2.223 1.355 3.535 1.389 6.059 2.292l.914.328.354-.905a14.64 14.64 0 0 1 7.705-8.063c1.85-.801 9.453-.904 9.453-.904l-8.657-9.054Z"
                fill="#EF0025"
            />
            <path
                d="M389.24 861.205c-1.335.629-4.872-.289-6.372-.688-2.736-.729-5.961-1.583-8.698-2.312-3.607-.961-10.91-3.323-14.1-5.643-4.802-3.493-8.828-7.919-10.584-13.592l-3.087-9.977a22.326 22.326 0 0 0-7.025-10.551l-4.702-3.927a15.923 15.923 0 0 1-4.967-9.64"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-11-walk-2-a"
                    x1={429.05}
                    y1={840.769}
                    x2={468.237}
                    y2={840.769}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
                <linearGradient
                    id="female-34-25-shoes-11-walk-2-b"
                    x1={447.463}
                    y1={864.172}
                    x2={491.382}
                    y2={864.172}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F6542F" />
                    <stop offset={0.679} stopColor="#F11528" />
                    <stop offset={0.9} stopColor="#EF0025" />
                </linearGradient>
            </defs>
        </g>
    );
}
