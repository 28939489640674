import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m430.609 842.75 8.095 32.579 9.113.302 1.349-10.953"
                fill="#004F71"
            />
            <path
                d="M460.551 876.395a15.027 15.027 0 0 1-12.214-6.063l-8.843-12.225a30.3 30.3 0 0 0-3.688-4.112 20.619 20.619 0 0 1-6.174-19.123l2.206-16.653h40.036s4.917 22.691 6.52 29.728a7.669 7.669 0 0 0 2.447 4.087l11.395 9.925a8.284 8.284 0 0 1 2.584 6.196 8.769 8.769 0 0 1-9.049 8.24h-25.22Z"
                fill="url(#female-34-25-shoes-13-walk-2-a)"
            />
            <path
                d="M492.274 873.833a9.32 9.32 0 0 1-6.503 2.561h-25.219a15.033 15.033 0 0 1-12.215-6.062s-15.174-19.202-16.206-20.934M438.707 875.328h9.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m329.722 798.766-6.925 31.94 7.246 3.05s2.677-5.24 4.527-9.187c1.282-2.736 4.345-4.795 7.304-3.121"
                fill="#004F71"
            />
            <path
                d="m322.797 830.703 7.455 2.956"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M372.704 859.27a38.853 38.853 0 0 1-10.112-4.68l-4.602-2.997a24.35 24.35 0 0 1-10.177-13.899l-2.792-10.068a22.361 22.361 0 0 0-6.709-10.75l-4.583-4.065a13.895 13.895 0 0 1-2.092-18.509l11.849-16.474 39.883 18.663-3.868 38.355c-.28 2.402 1.721 4.559 3.317 6.376l9.291 10.582a5.985 5.985 0 0 1 .63 7.06c-1.438 2.672-6.275 4.514-9.18 3.649l-10.855-3.243Z"
                fill="#004F71"
            />
            <path
                d="M387.954 862.241a6.413 6.413 0 0 1-4.394.274l-10.855-3.242a38.847 38.847 0 0 1-10.111-4.68l-4.603-2.997a24.336 24.336 0 0 1-10.177-13.899l-2.791-10.064a22.316 22.316 0 0 0-6.71-10.754l-4.583-4.065a15.924 15.924 0 0 1-4.679-9.783"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-13-walk-2-a"
                    x1={429.22}
                    y1={847.307}
                    x2={494.824}
                    y2={847.307}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
            </defs>
        </g>
    );
}
