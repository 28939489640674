import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-left">
        <g clipPath="url(#man-bold-34-25-arms-option-1-left-a)">
            <path
                d="M397.96 205.404c5.571 7.411 11.491 14.559 17.261 21.82a237.045 237.045 0 0 1 16.03 22.75c4.971 7.864 9.766 15.862 14.314 24.046 4.528 8.2 8.881 16.53 12.667 25.29a23.167 23.167 0 0 1 1.134 15.096 23.162 23.162 0 0 1-22.833 17.244 23.16 23.16 0 0 1-14.21-5.219c-7.39-6.038-14.212-12.504-20.86-19.102-6.627-6.614-13.009-13.413-19.213-20.346a236.913 236.913 0 0 1-17.498-21.642c-5.406-7.535-10.662-15.185-16.266-22.571a30.988 30.988 0 0 1-6.017-22.951 31 31 0 0 1 55.408-14.526l.083.111Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="m457.681 295.722 8.664 9.27 8.71 9.225c5.789 6.168 11.389 12.52 16.88 18.98 2.722 3.253 5.486 6.464 8.162 9.762 2.701 3.273 5.394 6.555 8.015 9.906 2.633 3.34 5.301 6.645 7.905 10.014 2.556 3.415 5.184 6.76 7.666 10.247a15.69 15.69 0 0 1-11.722 24.755 15.69 15.69 0 0 1-10.426-3.065c-3.435-2.555-6.724-5.252-10.085-7.879-3.313-2.674-6.562-5.411-9.846-8.113-3.296-2.691-6.52-5.451-9.737-8.22-3.241-2.744-6.393-5.575-9.589-8.364-6.343-5.625-12.577-11.357-18.622-17.274l-9.04-8.901-9.087-8.857a22.505 22.505 0 0 1 15.426-38.604 22.503 22.503 0 0 1 16.726 7.118Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="m397.876 205.293.084.111c5.571 7.411 11.491 14.559 17.261 21.82a237.045 237.045 0 0 1 16.03 22.75c4.971 7.864 9.766 15.862 14.314 24.046 2.645 4.79 6.513 12.249 8.961 17.189M424.454 327.622c-7.39-6.038-16.343-13.696-22.991-20.293-6.628-6.614-13.009-13.413-19.214-20.346a237.038 237.038 0 0 1-17.497-21.642c-5.406-7.536-10.662-15.185-16.267-22.571M501.535 394.815c-3.435-2.555-6.724-5.253-10.084-7.88-3.314-2.673-6.562-5.41-9.847-8.112-3.296-2.691-6.52-5.452-9.736-8.22-3.241-2.745-6.394-5.576-9.589-8.365-6.343-5.625-12.577-11.357-18.622-17.274l-9.041-8.901-10.162-8.442M456.947 294.973c.232.225.514.515.733.748l8.665 9.27 8.71 9.225c5.789 6.168 11.389 12.52 16.88 18.98 2.721 3.253 5.486 6.464 8.162 9.762 2.701 3.273 5.393 6.555 8.014 9.906 2.633 3.34 5.302 6.645 7.905 10.014 2.556 3.415 5.184 6.76 7.667 10.247"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M559.384 391.687c-1.75-2.673-4.219-3.341-7.11-4.701-9.533-4.486-18.007-8.182-28.157-10.61-12.25-2.931-22.701 6.028-17.126 19.952.204.508.442 1.001.712 1.477a15.775 15.775 0 0 0 7.737 6.502c.303.129.61.248.92.357a15.77 15.77 0 0 0 3.401.8 20.657 20.657 0 0 1-.352 4.341h-4.284a20.602 20.602 0 0 0 .038 3.582 6.7 6.7 0 0 0 5.484 5.688 8.382 8.382 0 0 0 5.745-1.187l.324.317a13.876 13.876 0 0 0 2.346 2.825 4.21 4.21 0 0 0 3.814.925 7.638 7.638 0 0 0 3.511-1.985c.35-.325.682-.668.993-1.029-1.327 2.571-.13 5.568 2.338 5.578a26.498 26.498 0 0 0 9.65-1.616 10.124 10.124 0 0 0 4.11-2.602 4.733 4.733 0 0 0 1.082-4.56l-.192-.19a13.323 13.323 0 0 0 3.248-3.165 14.923 14.923 0 0 0 1.486-2.495 24.946 24.946 0 0 0 1.963-10.918v-.01c-.03-2.523-.298-5.164-1.681-7.276Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M524.118 376.359c9.689 1.993 18.623 6.125 28.156 10.61 2.891 1.36 5.36 2.028 7.11 4.701 1.383 2.112 1.651 4.753 1.68 7.277a24.943 24.943 0 0 1-1.962 10.928 12.85 12.85 0 0 1-3.259 4.491 12.846 12.846 0 0 1-4.85 2.695"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M550.921 396.434c.326 4.002.175 8.03-.45 11.997-.782 3.935-2.616 7.8-5.784 10.262a12.68 12.68 0 0 1-4.055 2.105M541.186 398.316c.454 3.808.477 7.655.067 11.467a17.1 17.1 0 0 1-4.866 10.169 7.638 7.638 0 0 1-3.511 1.985 4.293 4.293 0 0 1-3.814-.925M531.24 400.238c.597 2.775.762 5.625.489 8.45a13.313 13.313 0 0 1-3.392 7.633 8.842 8.842 0 0 1-7.691 2.736 6.663 6.663 0 0 1-5.483-5.687M506.991 396.312a16.47 16.47 0 0 0 14.363 9.253"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M518.505 413.422a16.78 16.78 0 0 0 1.064-7.641M539.718 424.504a24.4 24.4 0 0 0 9.65-1.616 10.114 10.114 0 0 0 4.11-2.602 4.735 4.735 0 0 0 1.082-4.559"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M656.098 391.281a6.428 6.428 0 0 0-2.108-3.428c-4.088-3.474-22.317-12.4-31.44-19.149-14.962-11.07-40.728 7.916-22.64 19.137 2.555 1.585 5.554 5.598 8.291 9.962h20.64c1.979 3.866 1.212 8.195-.455 12h-13.505a138.932 138.932 0 0 1 1.549 3.166 12.107 12.107 0 0 0 7.953 6.533c2.344.578 4.799.431 7.205.636 2.406.204 4.935.851 6.566 2.632a4.39 4.39 0 0 0 1.786-2.429 4.705 4.705 0 0 0-.215-3.119l-.089-.219a5.759 5.759 0 0 0 1.36-.507 7.616 7.616 0 0 0 1.629-1.172l.136.185a6.275 6.275 0 0 0 2.227 1.462 3.183 3.183 0 0 0 3.161-.42c.185-.134.36-.281.523-.44l.228.338c1.548 1.051 3.484 1.217 4.384-.644a36.163 36.163 0 0 0 2.694-7.749c.544-2.394.859-4.835.94-7.289a39.812 39.812 0 0 0-.82-9.486Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M622.551 368.617c9.013 6.172 27.304 15.733 31.44 19.149a5.153 5.153 0 0 1 1.864 3.96 43.781 43.781 0 0 1-5.615 22.143 8.059 8.059 0 0 1-2.09 2.596 3.109 3.109 0 0 1-3.162.42"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M656.098 391.195a39.449 39.449 0 0 1-2.814 24.524M649.316 395.715c-.204 5.467-1.395 11.532-4.181 16.241a11.283 11.283 0 0 1-4.139 4.455 4.965 4.965 0 0 1-5.725-.52M640.91 396.047a34.709 34.709 0 0 1-1.347 11.393 14.096 14.096 0 0 1-2.792 5.427c-1.297 1.581-3.129 2.98-5.174 2.987a7.683 7.683 0 0 1-6.724-3.936"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M628.44 397.07c2.488 4.065 1.709 8.73-.083 12.788M616.431 412.883a12.109 12.109 0 0 0 7.952 6.533c2.345.578 4.799.432 7.205.636 2.406.204 4.935.851 6.567 2.632M599.911 387.754c3.146 1.603 5.469 4.68 7.072 7.959"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="man-bold-34-25-arms-option-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.454 .926)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
