import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-6">
        <path
            d="M415.329 283.755c-6.786-4.232-13.565-8.78-20.728-13.905a189.122 189.122 0 0 1-19.359-15.57 861.921 861.921 0 0 1-5.461-5.055c-4.141-3.85-8.422-7.831-12.766-11.581a31.795 31.795 0 0 1-11.007-21.879 31.778 31.778 0 0 1 7.689-23.254 31.99 31.99 0 0 1 24.24-11.093 31.992 31.992 0 0 1 20.893 7.775l.124.108c4.325 3.769 8.872 7.447 13.271 11.004a1759.43 1759.43 0 0 1 5.772 4.689 188.993 188.993 0 0 1 18.153 16.969c6.083 6.366 11.543 12.435 16.686 18.548-9.093 4.316-32.515 25.569-37.507 43.244Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-6a)"
        />
        <path
            d="m398.83 189.191.124.109c4.325 3.769 8.873 7.447 13.271 11.004 1.928 1.559 3.852 3.122 5.773 4.689a189.1 189.1 0 0 1 18.152 16.969c6.083 6.366 11.543 12.435 16.686 18.548-9.093 4.316-32.515 25.569-37.506 43.244-6.786-4.233-13.565-8.78-20.728-13.905a189.122 189.122 0 0 1-19.359-15.57 892.505 892.505 0 0 1-5.461-5.055c-4.141-3.85-8.423-7.832-12.766-11.582"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-6a"
                x1={408.586}
                y1={269.602}
                x2={383.212}
                y2={157.048}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
