import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6878)"
            d="M494.769 223.314a2.715 2.715 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6878)"
            d="M564.09 307.737c-1.351 0-2.699-.135-4.023-.404-9.158-1.884-17.899-4.495-24.49-6.559-7.623-2.416-15.341-5.13-23.599-8.299a202.147 202.147 0 01-22.757-10.146 856.327 856.327 0 01-6.622-3.485c-5.008-2.65-10.19-5.391-15.363-7.903a27.307 27.307 0 01-13.978-15.736 27.316 27.316 0 011.246-21.011 27.679 27.679 0 0124.76-15.499 27.236 27.236 0 0111.988 2.767l.117.058c5.162 2.535 10.508 4.934 15.678 7.254 2.277 1.022 4.552 2.049 6.824 3.081a201.753 201.753 0 0121.999 11.71c7.568 4.557 14.455 8.974 21.047 13.498 5.686 3.933 13.12 9.222 20.233 15.281a19.962 19.962 0 017.002 13.698 19.96 19.96 0 01-4.736 14.637 20.102 20.102 0 01-15.326 7.058z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_6878)"
            d="M567.914 308.118a19.56 19.56 0 01-19.081-15.576l-.024-.122c-.647-3.249-1.466-6.528-2.258-9.699a474.332 474.332 0 01-1.044-4.23 86.471 86.471 0 01-2.162-14.366c-.046-.79-.103-1.568-.159-2.347-.116-1.607-.238-3.269-.261-4.937l-.113-3.618a61.667 61.667 0 01-.011-2.395c.003-.43.006-.86.005-1.29 15.316 8.007 27.46 3.092 33.098-6.702.17.407.341.803.511 1.198.316.734.631 1.467.918 2.207l1.309 3.397c.62 1.53 1.154 3.109 1.671 4.635.251.74.501 1.479.761 2.216a86.344 86.344 0 013.591 14.085c.237 1.433.459 2.868.682 4.303.502 3.23 1.02 6.57 1.686 9.811a19.36 19.36 0 01-2.816 14.656 19.38 19.38 0 01-12.356 8.371 19.603 19.603 0 01-3.947.403z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6878"
                x1="492.894"
                x2="370.58"
                y1="210.687"
                y2="304.374"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6878"
                x1="527.056"
                x2="507.682"
                y1="228.421"
                y2="309.675"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_6878"
                x1="595.023"
                x2="528.517"
                y1="269.944"
                y2="285.27"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
