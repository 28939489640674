import React from 'react';

const SvgComponent = () => (
    <g id="female-34-25-emotion-wide-smile" transform="translate(316, 30)">
        <path
            d="M140.9 102.8c-4-1.1-8-.9-11.9.7M167.5 102.8c-4-1.1-8-.9-11.9.7M127.701 94.4c4.1-3.5 9.8-5.1 15.1-4.3M157.8 90.897c3.2-.2 6.5.6 9.2 2.3"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M136.501 130c4.6 7.8 13.4 9.8 20.2 3.8-6.4-.4-13.2-1.7-20.2-3.8Z"
            fill="#fff"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
