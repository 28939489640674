import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6485)"
            d="M402.465 354.199a19.381 19.381 0 01-15.455-7.591l-.004-.003a20.436 20.436 0 01-5.465-7.1 228.593 228.593 0 01-8.711-22.542c-2.406-7.232-4.621-14.697-6.772-22.825-2.132-7.808-4.21-15.507-5.252-23.103-.325-2.326-2.655-17.875-3.32-23.306-1.897-15.506 8.006-29.299 22.954-31.849a27.825 27.825 0 014.655-.396 27.415 27.415 0 0127.077 22.881l.024.139c.887 5.369 4.073 20.975 4.522 23.267a180.477 180.477 0 013.023 23.355c.29 3.912.527 7.931.707 11.939a262.77 262.77 0 0111.236-7.306 360.12 360.12 0 003.467-2.13c2.397-1.481 4.872-3.01 7.331-4.417l5.431-3.182c1.182-.689 2.379-1.353 3.576-2.018 0 0 1.357-.755 1.946-1.087 3.139-1.743 7.338-4.05 12.039-6.452l1.64-.838-.189 1.831c-1.013 9.766 7.411 20.8 13.655 24.104l1.217.644-.989.958a439.273 439.273 0 01-7.552 7.128 409.43 409.43 0 00-1.644 1.512 195.316 195.316 0 01-3.035 2.766l-4.73 4.174c-2.123 1.912-4.318 3.758-6.441 5.543-1.065.896-2.106 1.771-3.142 2.658a262.295 262.295 0 01-20 15.365l-10.355 7.113-10.26 7.219a19.352 19.352 0 01-11.184 3.549z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6485"
                x1="350.863"
                x2="460.164"
                y1="274.252"
                y2="300.927"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
