import React, { useContext } from 'react';
import Modal from './ModalWindow';
import Create from './Create';
import { PageContext } from 'components/App';
import { TSettings } from 'interfaces';

export default function ModalContainer({
    setBaseSettings,
} : {
    setBaseSettings?: (e: TSettings) => void,
}) {
    const { showModal, setModal, baseSettings } = useContext(PageContext);

    return showModal ? (
        <Modal toggleModal={!!baseSettings && setModal} >
            <Create toggleModal={setModal} setBaseSettings={setBaseSettings}/>
        </Modal>
    ) : null;
}
