import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={440}
            height={231}
            viewBox={`0 0 ${440} ${231}`}
            fill="none"
            id="object-kitchen-hood"
            {...attributes}
        >
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M438.34 1.91h-192v133.5h-138l-93 58.5h304.5"
            />
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M111.34 1.91v112.5h117l115.5 79.5v36h-342v-34.5"
            />
        </svg>
    );
}
