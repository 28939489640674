import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#EF0025"
            d="M581.781 637.189c-1.069-9.526-7.397-86.881-8.267-101.55-1.118-17.887 1.845-37.37 6.236-57.14h-73.384c-33.205-6.669-53.489-39.326-42.303-71.703 9.253-25.979 34.969-39.617 61.917-35.507 21.366 2.531 42.349 8.032 60.267 16.649 13.79 6.309 26.028 11.741 40.906 21.131 2 1.28 5.352 2.871 7.396 4.131 11.494 7.084 18.153 17.697 15.858 30.687-2.832 16.522-9.946 58.011-12.689 73.935-2.025 11.337-10.614 101.98-11.41 123.114-8.81-.982-34.699-2.717-44.527-3.747z"
        ></path>
    </g>
);

export default SvgComponent;
