import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-5">
        <path
            d="m465.361 167.117 2.655.951c13.051 4.678 23.789 10.343 32.825 17.32a2.671 2.671 0 0 1 1.092 2.341c-.25 4.648-12.215 12.208-14.611 13.677l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-5-a)"
        />
        <path
            d="M489.361 295.209c-4.578-6.978-9.03-14.259-13.613-22.261a206.6 206.6 0 0 1-11.679-23.21 899.408 899.408 0 0 1-3.037-7.187c-2.289-5.45-4.656-11.087-7.174-16.533a32.037 32.037 0 0 1 15.617-42.475 31.736 31.736 0 0 1 13.409-2.961 32.115 32.115 0 0 1 29.066 18.579l.063.137c2.494 5.464 5.23 10.935 7.875 16.225 1.162 2.325 2.319 4.651 3.47 6.98a206.382 206.382 0 0 1 10.003 23.987c3.082 8.677 5.705 16.797 8.018 24.819 0 0-10.99 1.598-25.303 8.321-7.151 3.359-26.715 15.579-26.715 15.579Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-5-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-5-a"
                x1={479.795}
                y1={128.035}
                x2={497.362}
                y2={352.493}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-5-b"
                x1={487.132}
                y1={127.461}
                x2={504.698}
                y2={351.919}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
