import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shoes-5-cycling-1-right-a)">
        <path
            d="M353.356 757.227c-.216.408 9.823 34.364 24.82 48.607 3.501 3.325 7.726 7.653 9.932 9.937a5.157 5.157 0 0 1-3.715 8.733 5.067 5.067 0 0 1-1.536-.236c-7.79-2.444-21.404-6.816-25.054-8.63a70.627 70.627 0 0 1-17.34-11.935 41.202 41.202 0 0 0-10.39-7.176l-18.265-8.927c-2.501-1.223-5.619-3.885-6.445-6.541a9.895 9.895 0 0 1 .836-7.872c8.402-14.912 15.022-28.738 15.022-28.738l32.135 12.778Z"
            fill="#E2E2E2"
        />
        <path
            d="M383.29 824.73a6.155 6.155 0 0 1-1.841-.296c-7.8-2.505-21.439-6.987-25.14-8.859a71.718 71.718 0 0 1-17.505-12.226 40.082 40.082 0 0 0-10.089-7.068l-17.893-8.898a11.44 11.44 0 0 1-5.83-6.462 10.895 10.895 0 0 1 .868-9.001c7.538-13.171 8.45-14.454 10.208-18.419.069-.145.153-.283.251-.411a3.097 3.097 0 0 1 2.761-1.153 3.09 3.09 0 0 1 2.444 1.727c2.056 4.155 8.32 13.811 21.436 11.8a85.296 85.296 0 0 0 9.165-2.579 3.232 3.232 0 0 1 3.84 1.457c.495.945.875 1.947 1.13 2.983.998 3.746 7.005 23.693 20.798 36.97 3.394 3.267 7.477 7.5 9.72 9.851a6.443 6.443 0 0 1 1.862 4.725 6.172 6.172 0 0 1-6.185 5.859Z"
            fill="#63666A"
        />
        <path
            d="M341.264 792.017c-9.77-13.31-25.607-20.451-33.686-23.107-.559.983-1.128 1.981-1.718 3.012a10.894 10.894 0 0 0-.868 9.001 11.441 11.441 0 0 0 5.83 6.463l17.893 8.897a40.157 40.157 0 0 1 10.09 7.068 71.712 71.712 0 0 0 17.504 12.226c3.702 1.872 17.34 6.354 25.14 8.859a6.1 6.1 0 0 0 2.426.271 6.103 6.103 0 0 0 2.338-.705c-7.16-3.735-35.409-18.989-44.949-31.985Z"
            fill="#E2E2E2"
        />
        <defs>
            <clipPath id="man-34-25-shoes-5-cycling-1-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .773)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
