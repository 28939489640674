import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={49}
            height={26}
            fill="none"
            id="object-food-raisin"
            {...attributes}
        >
            <g clipPath="url(#object-food-raisin-a)">
                <path
                    fill="url(#object-food-raisin-b)"
                    d="M24.136 25.322c13.33 0 24.136-5.668 24.136-12.661C48.272 5.669 37.466 0 24.136 0S0 5.669 0 12.661c0 6.993 10.806 12.661 24.136 12.661Z"
                />
            </g>
            <defs>
                <linearGradient
                    id="object-food-raisin-b"
                    x1={43.326}
                    x2={-3.664}
                    y1={28.292}
                    y2={-9.982}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#004F71" />
                    <stop offset={0.125} stopColor="#01567A" />
                    <stop offset={1} stopColor="#0B86B8" />
                </linearGradient>
                <clipPath id="object-food-raisin-a">
                    <path fill="#fff" d="M0 0h48.272v25.323H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
