import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-1-both-arms-option-2-right">
        <path
            d="M406.441 182.333c-2.56-.22-5.121-.423-7.683-.626-5.834-.463-11.865-.941-17.77-1.623l-.15-.017a32.695 32.695 0 0 0-3.553-.196 31.946 31.946 0 0 0-31.769 28.488 31.788 31.788 0 0 0 6.83 23.52 31.798 31.798 0 0 0 21.462 11.802c5.904.652 11.89 1.531 17.68 2.382 2.543.373 5.084.747 7.626 1.103 1.032.147 2.088.263 3.132.394l4.674-65.179c-.159-.013-.321-.034-.479-.048Z"
            fill="url(#man-34-25-tshirt-1-both-arms-option-2-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-1-both-arms-option-2-right-a"
                x1={348.79}
                y1={205.956}
                x2={417.32}
                y2={217.666}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
