import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-5-walk-2">
            <path
                fill="#E2E2E2"
                d="M538.654 861.122a10.058 10.058 0 0 1-9.87-8.484c-2.587-16.916-4.583-27.909-5.62-30.956l.737-1.3 31.801-6.784.777.154c.27.186 27.168 18.598 47.757 20.555 4.772.453 10.787 1.255 13.947 1.693a5.154 5.154 0 0 1 1.109 9.927c-7.635 2.882-21.04 7.839-25.042 8.665a74.777 74.777 0 0 1-15.058 1.586c-2.047 0-4.049-.095-5.954-.281a39.19 39.19 0 0 0-3.799-.185 42.35 42.35 0 0 0-8.802.946l-19.883 4.241c-.69.148-1.394.223-2.1.223Z"
            />
            <path
                fill="#63666A"
                d="M621.403 845.588a6.159 6.159 0 0 1-1.608.943c-7.61 3.032-20.983 8.256-25.029 9.168a71.715 71.715 0 0 1-21.283 1.71 40.128 40.128 0 0 0-12.28.969l-19.465 4.525a11.436 11.436 0 0 1-8.61-1.274 10.9 10.9 0 0 1-5.059-7.495c-2.568-14.956-2.681-16.527-3.848-20.703-.239-.854-.007-3.726 2.535-4.247 0 0 14.628 8.896 25.821-3.641a108.386 108.386 0 0 0 6.275-9.166s1.4-1.55 5.812 1.605c3.154 2.255 20.482 13.817 39.572 15.28 4.697.36 10.54 1.027 13.766 1.412a6.463 6.463 0 0 1 4.444 2.46 6.171 6.171 0 0 1-1.043 8.454Z"
            />
            <path
                fill="#E2E2E2"
                d="M568.166 847.101c-16.007-4.048-32.768.522-40.689 3.616.194 1.113.39 2.245.591 3.416a10.898 10.898 0 0 0 5.059 7.495 11.44 11.44 0 0 0 8.61 1.275s43.806-5.125 53.028-7.205c4.046-.912 17.419-6.136 25.029-9.167a6.084 6.084 0 0 0 3.398-3.367c-7.9 1.676-39.397 7.889-55.026 3.937ZM328.848 862.122a24.478 24.478 0 0 1-20.813-11.481l-19.53-30.969a11.066 11.066 0 0 1-.018-11.707l9.46-15.102 33.855 7.959 13.344 35.881a14.774 14.774 0 0 0 8.972 8.812l3.76 1.321a7.865 7.865 0 0 1-2.607 15.286h-26.423Z"
            />
            <path
                fill="#63666A"
                d="M328.848 862.122a24.478 24.478 0 0 1-20.813-11.481l-19.529-30.969a11.056 11.056 0 0 1-.018-11.707l9.605-15.336c.747.017 1.493.08 2.233.188 1.012.297 2.459 8.147 7.265 10.816 3.627 2.015 9.992-.876 14.873-1.156 4.133-.237 9.93-.277 11.151 3.108a696.968 696.968 0 0 0 3.735 10.052l7.797 21.068a14.766 14.766 0 0 0 8.97 8.81l3.761 1.321a7.87 7.87 0 0 1 5.147 8.743 7.86 7.86 0 0 1-7.753 6.544l-26.424-.001Z"
            />
            <path
                fill="#E2E2E2"
                d="M315.236 852.212c-5.348-5.759-14.598-40.174-24.418-47.966l-2.33 3.72a11.061 11.061 0 0 0 .018 11.707l19.529 30.969a24.485 24.485 0 0 0 8.919 8.437 24.488 24.488 0 0 0 11.894 3.044h26.424a7.874 7.874 0 0 0 7.233-4.826c-44.306-1.49-41.771.837-47.269-5.085Z"
            />
        </g>
    );
}
