import React from 'react';

export default function SvgComponent() {
    return (
        <g id="man-34-25-shoes-5-stand-1">
            <path
                fill="#E2E2E2"
                d="M537.33 849.909c-2.707-1.68-7.873-4.852-12.059-7.21-18.021-10.148-35.128-37.894-35.298-38.173l-33.665-6.28c-.289 3.207-2.929 14.064-7.433 30.571a9.886 9.886 0 0 0-.261 4.035 9.883 9.883 0 0 0 1.374 3.803 9.9 9.9 0 0 0 6.472 4.573l19.892 4.196a41.136 41.136 0 0 1 11.826 4.42 70.68 70.68 0 0 0 19.732 7.339c3.988.869 18.254 1.782 26.402 2.249a5.16 5.16 0 0 0 5.412-4.543 5.164 5.164 0 0 0-.477-2.836 5.165 5.165 0 0 0-1.917-2.144Z"
            />
            <path
                fill="#63666A"
                d="M536.174 860.042a6.136 6.136 0 0 1-1.855.193c-8.183-.39-22.518-1.17-26.579-2.015a71.667 71.667 0 0 1-20.082-7.253 40.16 40.16 0 0 0-11.58-4.2l-19.592-3.937a11.442 11.442 0 0 1-7.31-4.724 10.905 10.905 0 0 1-1.503-8.917c3.853-14.678 4.4-16.154 5.067-20.44.136-.875 1.536-3.394 4.066-2.816 0 0 9.633 14.153 25.012 7.373a108.348 108.348 0 0 0 9.506-5.747s1.915-.831 4.626 3.868c1.937 3.358 12.926 21.055 29.698 30.288 4.127 2.272 9.171 5.298 11.948 6.984a6.438 6.438 0 0 1 3.026 4.078 6.166 6.166 0 0 1-4.448 7.265Z"
            />
            <path
                fill="#E2E2E2"
                d="M487.086 839.386c-12.896-10.31-30.046-13.087-38.537-13.55-.284 1.094-.574 2.206-.876 3.355a10.908 10.908 0 0 0 1.503 8.917 11.445 11.445 0 0 0 7.311 4.724l19.591 3.937a40.13 40.13 0 0 1 11.58 4.2 71.73 71.73 0 0 0 20.082 7.253c4.061.845 18.396 1.625 26.579 2.015.625.03 1.25-.035 1.855-.193a6.122 6.122 0 0 0 2.632-1.465c-7.885-1.744-39.128-9.126-51.72-19.193Z"
            />
            <path
                fill="#E2E2E2"
                d="M429.902 818.52s11.821 18.47 20.307 24.184c3.727 2.51 11.165 6.566 14.957 8.599a4.434 4.434 0 0 1 2.34 3.915 4.441 4.441 0 0 1-4.445 4.444h-57.647a8.993 8.993 0 0 1-8.985-9.52c.629-10.751 1.469-27.313.958-31.622h32.515Z"
            />
            <path
                fill="#63666A"
                d="M431.504 820.211c1.688-.013 2.525.914 3.47 2.313 3.393 5.025 9.007 13.819 15.343 18.086 3.55 2.39 10.56 6.228 14.57 8.38a6.673 6.673 0 0 1 3.605 5.529 6.46 6.46 0 0 1-1.793 4.724 6.465 6.465 0 0 1-4.648 1.984H404.75a11.442 11.442 0 0 1-8.095-3.19 10.905 10.905 0 0 1-3.231-8.447c.704-12.047 1.082-14.055 1.111-19.751.014-2.756.477-8.251 3.065-9.194 16.265 4.843 33.904-.434 33.904-.434Z"
            />
            <path
                fill="#E2E2E2"
                d="M410.302 856.265c-6.526-2.645-13.948-10.778-16.492-13.722-.117 2.241-.247 4.59-.39 7.047a10.9 10.9 0 0 0 3.231 8.447 11.442 11.442 0 0 0 8.095 3.19h57.302a6.446 6.446 0 0 0 5.473-3.053c-15.859.124-49.635 1.166-57.219-1.909Z"
            />
        </g>
    );
}
