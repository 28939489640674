import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-3-both-arms-option-1-left-cycling-2">
        <path
            d="M503.718 206.951c-7.696-12.253-24.203-27.922-37.678-36.217l-2.115 9.542 9.65 41.17s33.123-9.749 30.143-14.495Z"
            fill="url(#man-34-25-shirt-3-both-arms-option-1-left-cycling-2-a)"
        />
        <path
            d="M507.407 212.641c-6.323-11.804-16.237-18.813-29.623-18.813a32.012 32.012 0 0 0-31.727 27.859 32.009 32.009 0 0 0 1.673 15.129c2.044 5.596 3.921 11.368 5.738 16.949.801 2.46 1.6 4.92 2.416 7.372 1.432 4.347 6.976 22.847 8.883 27.472l62.773-16.637c-2.458-11.855-13.627-47.186-20.133-59.331Z"
            fill="url(#man-34-25-shirt-3-both-arms-option-1-left-cycling-2-b)"
        />
        <path
            d="M503.718 206.951c-7.696-12.253-24.203-27.922-37.678-36.217"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-3-both-arms-option-1-left-cycling-2-a"
                x1={463.925}
                y1={196.09}
                x2={503.907}
                y2={196.09}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-3-both-arms-option-1-left-cycling-2-b"
                x1={445.77}
                y1={241.219}
                x2={539.833}
                y2={241.219}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
