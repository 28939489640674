import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7302)"
            d="M494.768 223.29a109.595 109.595 0 00-20.026-15.329 31.035 31.035 0 012.394 13.894 65.926 65.926 0 01-1.451 10.763l2.169 4.754a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7302)"
            d="M487.945 220.097l-.14-.047a27.499 27.499 0 00-33.111 38.545 27.522 27.522 0 006.619 8.446 27.516 27.516 0 009.347 5.269c4.768 1.564 9.562 3.351 14.198 5.08l.821.304 14.556-53.822c-4.08-1.189-8.238-2.421-12.29-3.775z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7302"
                x1="490.724"
                x2="448.218"
                y1="207.084"
                y2="283.422"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7302"
                x1="495.711"
                x2="453.204"
                y1="209.863"
                y2="286.2"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
