import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="female-bold-34-25-legs-walk-2">
        <path
            d="M344.924 780.256a831.115 831.115 0 0 1 6.35-25.693c1.123-4.251 2.168-8.54 3.355-12.76l3.505-12.69c2.312-8.472 4.747-16.885 6.839-25.461 2.156-8.545 3.986-17.245 6.467-25.636 4.721-16.897 12.715-32.242 21.244-47.332 4.349-7.505 9.014-14.86 14.119-22.007 1.252-1.798 2.612-3.545 3.913-5.32 1.371-1.741 2.699-3.504 4.131-5.216a124.367 124.367 0 0 1 9.062-10.054l7.902-7.796a26.419 26.419 0 0 1 44.358 13.153 26.416 26.416 0 0 1-2.568 18.216l-24.703 45.691-12.362 22.84-12.488 22.78c-8.347 15.177-16.605 30.396-25.024 45.539l-25.189 45.46a15.94 15.94 0 0 1-29.476-11.285l.565-2.429Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M421.265 595.452c-1.043-12.765-1.923-25.504-2.868-38.254-.476-6.376-.895-12.742-1.528-19.142l-.965-9.602-.492-4.803-.41-4.79c-.468-6.374-.702-12.712-.557-18.991.169-6.275.419-12.537.894-18.764.472-6.228 1.058-12.438 1.937-18.603l.645-4.625.786-4.604c.541-3.066 1.162-6.12 1.932-9.151a51.086 51.086 0 0 1 54.67-38.245 51.09 51.09 0 0 1 45.88 48.44l.236 5.498c.237 6.279.058 12.566-.537 18.82-.541 6.217-1.349 12.393-2.349 18.539-.984 6.148-2.242 12.253-3.607 18.342-1.369 6.088-2.992 12.137-4.936 18.135-1.937 6-4.346 11.926-6.9 17.83-2.578 5.9-5.092 11.81-7.909 17.673a186.488 186.488 0 0 1-2.134 4.393 178.09 178.09 0 0 1-2.213 4.382c-1.479 2.92-3.134 5.813-4.692 8.721a192.422 192.422 0 0 1-10.81 17.221l-3.979 5.522a27.704 27.704 0 0 1-43.84 1.448 27.702 27.702 0 0 1-6.254-15.39ZM345.44 782.328c-.989 3.778-7.14 17.377-11.204 26.177a8.088 8.088 0 0 0-.161 6.413 8.096 8.096 0 0 0 4.556 4.517l23.563 9.253s11.356 12.939 35.892 17.056c5.694.955 10.718.173 11.846-2.7 1.114-2.837-5.509-7.912-10.148-10.989-15.895-10.54-27.104-39.031-27.104-39.031"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M345.44 782.328c-.989 3.778-7.14 17.377-11.204 26.177a8.088 8.088 0 0 0-.161 6.413 8.096 8.096 0 0 0 4.556 4.517l23.563 9.253s11.356 12.939 35.892 17.056c5.694.955 10.718.173 11.846-2.7 1.114-2.837-5.509-7.912-10.148-10.989-15.895-10.54-27.104-39.031-27.104-39.031M345.889 776.115c1.705-7.226 3.5-14.41 5.384-21.552 1.123-4.251 2.168-8.54 3.355-12.76l3.505-12.69c2.311-8.472 4.747-16.885 6.839-25.461 2.156-8.545 3.986-17.245 6.467-25.636 4.721-16.897 12.715-32.242 21.244-47.332 4.349-7.505 9.014-14.86 14.119-22.007 1.252-1.798 2.612-3.545 3.913-5.32 1.371-1.741 2.699-3.504 4.131-5.216a124.367 124.367 0 0 1 9.062-10.054l7.902-7.796a26.419 26.419 0 0 1 44.358 13.153 26.416 26.416 0 0 1-2.568 18.216l-24.703 45.691-12.362 22.84-12.488 22.78c-8.347 15.177-16.604 30.396-25.024 45.539l-23.584 42.565"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M421.265 595.452c-1.043-12.765-1.923-25.504-2.868-38.254-.476-6.376-.895-12.742-1.528-19.142l-.965-9.602-.492-4.803-.41-4.79c-.468-6.374-.702-12.712-.557-18.991.169-6.275.419-12.537.894-18.764.472-6.228 1.058-12.438 1.937-18.603l.645-4.625.786-4.604c.541-3.066 1.162-6.12 1.932-9.151a51.086 51.086 0 0 1 54.67-38.245 51.09 51.09 0 0 1 45.88 48.44l.236 5.498c.237 6.279.058 12.566-.537 18.82-.541 6.217-1.349 12.393-2.349 18.539-.984 6.148-2.242 12.253-3.607 18.342-1.369 6.088-2.992 12.137-4.936 18.135-1.937 6-4.346 11.926-6.9 17.83-2.578 5.9-5.092 11.81-7.909 17.673a186.488 186.488 0 0 1-2.134 4.393 178.09 178.09 0 0 1-2.213 4.382c-1.479 2.92-3.134 5.813-4.692 8.721a192.422 192.422 0 0 1-10.81 17.221l-3.979 5.522a27.704 27.704 0 0 1-43.84 1.448 27.702 27.702 0 0 1-6.254-15.39Z"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M355.264 399.641a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.908H355.264Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M355.264 399.641a90.385 90.385 0 0 0-4.403 15.397c-10.397 55.41 52.309 115.615 131.422 44.511 24.407-21.936 36.192-41.867 40.779-59.908"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M433.191 815.007a783.843 783.843 0 0 1-4.609-25.246c-.696-4.205-1.48-8.413-2.105-12.617l-1.939-12.613c-1.321-8.41-2.504-16.815-4.069-25.231-1.492-8.414-3.346-16.838-4.478-25.242-2.534-16.816-1.446-33.536.235-50.241.933-8.35 2.217-16.69 3.987-25.018.415-2.083.95-4.162 1.42-6.243.547-2.079 1.046-4.16 1.662-6.237a117.625 117.625 0 0 1 4.209-12.45l4.308-10.598a26.35 26.35 0 0 1 50.673 12.051l-4.062 50.14-2.043 25.07-2.182 25.064c-1.479 16.71-2.859 33.423-4.418 50.13l-4.6 50.126a15.934 15.934 0 0 1-31.489 1.68l-.5-2.525Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M430.416 623.997 408.039 586.1l-5.608-9.47-5.714-9.433c-3.783-6.298-7.857-12.494-11.583-18.811-3.727-6.318-7.216-12.717-10.345-19.242-1.597-3.251-3.04-6.556-4.568-9.831l-2.26-4.923-2.118-4.974c-1.401-3.319-2.866-6.615-4.143-9.978-1.306-3.352-2.679-6.681-3.864-10.075l-1.839-5.07-1.704-5.117a169.436 169.436 0 0 1-3.065-10.352 51.022 51.022 0 0 1 93.767-38.079l2.588 4.618c1.794 3.182 3.412 6.426 4.937 9.702l2.257 4.925 2.09 4.983c1.445 3.304 2.658 6.688 3.953 10.044 1.31 3.351 2.483 6.749 3.652 10.149 2.334 6.801 4.406 13.693 6.375 20.62 1.966 6.929 3.689 13.942 5.104 21.061 1.423 7.118 2.395 14.392 3.227 21.714l1.221 10.993c.383 3.673.679 7.376 1.023 11.062 1.164 14.819 1.813 29.816.69 45.428l-.425 5.905a27.592 27.592 0 0 1-21.206 24.877 27.589 27.589 0 0 1-30.065-12.829Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M434.64 819.309c-1.68-8.419-4.655-21.136-6.058-29.547-.696-4.206-1.48-8.414-2.105-12.618l-1.939-12.613c-1.321-8.409-2.504-16.815-4.069-25.231-1.492-8.414-3.346-16.837-4.478-25.242-2.534-16.816-1.446-33.536.235-50.24.933-8.35 2.217-16.69 3.987-25.018.415-2.083.95-4.163 1.42-6.244.547-2.079 1.046-4.159 1.662-6.237a134.141 134.141 0 0 1 1.76-5.772M482.158 619.352l-3.735 46.108-2.043 25.07-2.182 25.066c-1.479 16.71-2.859 33.422-4.418 50.13l-5.501 54.593M425.272 615.292l-17.234-29.188-5.608-9.47-5.714-9.432c-3.783-6.298-7.857-12.494-11.584-18.812-3.726-6.317-7.215-12.717-10.344-19.242-1.598-3.25-3.04-6.555-4.568-9.83l-2.26-4.924-2.118-4.973c-1.401-3.319-2.867-6.616-4.143-9.978-1.306-3.352-2.679-6.681-3.864-10.076l-1.839-5.07-1.705-5.116a170.17 170.17 0 0 1-3.064-10.353M447.582 435.367c1.794 3.183 3.412 6.427 4.937 9.703l2.257 4.925 2.09 4.982c1.445 3.304 2.658 6.689 3.953 10.045 1.31 3.35 2.483 6.749 3.652 10.148 2.334 6.801 4.406 13.693 6.375 20.62 1.966 6.929 3.689 13.942 5.104 21.062 1.423 7.117 2.395 14.391 3.227 21.713l1.221 10.993c.383 3.673.679 7.376 1.023 11.062 1.164 14.819 1.813 29.816.69 45.429l-.425 5.904"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M434.59 822.797c.46 3.879-.295 18.784-.861 28.46a8.086 8.086 0 0 0 2.194 6.029 8.068 8.068 0 0 0 2.684 1.878 8.081 8.081 0 0 0 3.208.661h43.739c5.773 0 10.551-1.33 10.551-4.418 0-3.047-9.357-5.834-13.974-8.944-7.638-5.143-18.276-23.666-18.276-23.666"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M434.59 822.797c.46 3.879-.295 18.784-.861 28.46a8.086 8.086 0 0 0 2.194 6.029 8.068 8.068 0 0 0 2.684 1.878 8.081 8.081 0 0 0 3.208.661h43.739c5.773 0 10.551-1.33 10.551-4.418 0-3.047-9.357-5.834-13.974-8.944-7.638-5.143-18.276-23.666-18.276-23.666"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
