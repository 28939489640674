import React from 'react';

const SvgComponent = () => {
    return <g id="man-34-25-shorts-2-cycling-1-right">
        <g clipPath="url(#man-34-25-shorts-2-cycling-1-right-a)">
            <path
                d="M435.649 438.326a75.716 75.716 0 0 0 33.696-30.38l-.57-1.476-155.918-45.634c-10.94 10.327-16.554 23.259-18.723 41.476-2.014 16.914-3.089 30.94.94 48.795 5.278 23.392 13.707 45.517 23.53 67.292 2.834 6.2 6.782 14.676 10.952 23.576l91.302-23.205a10631.33 10631.33 0 0 1-3.529-48.692 32.512 32.512 0 0 1 16.654-30.92 24.59 24.59 0 0 1 1.666-.832Z"
                fill="url(#man-34-25-shorts-2-cycling-1-right-b)"
            />
        </g>
        <defs>
            <linearGradient
                id="man-34-25-shorts-2-cycling-1-right-b"
                x1={292.337}
                y1={451.406}
                x2={469.345}
                y2={451.406}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <clipPath id="man-34-25-shorts-2-cycling-1-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .219)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>;
};

export default SvgComponent;
