import { ILegs, TLegsSkins } from 'interfaces';

import Stand1 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-stand-1';
import Stand1Opt2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-stand-1-opt2';
import Stand2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-stand-2';
import Stand2Opt2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-stand-2-opt2';
import Walk1 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-walk-1';
import Walk1Opt2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-walk-1-opt2';
import Walk2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-walk-2';
import Walk2Opt2 from 'assets/female/skin-layer/normal-legs/female-34-25-legs-walk-2-opt2';
import SittingRight from 'assets/female/skin-layer/sittings-legs/female-34-25-legs-sitting-right';
import SittingLeft from 'assets/female/skin-layer/sittings-legs/female-34-25-legs-sitting-left';
import SittingRightOpt2 from 'assets/female/skin-layer/sittings-legs/female-34-25-legs-sitting-right-opt2';
import SittingLeftOpt2 from 'assets/female/skin-layer/sittings-legs/female-34-25-legs-sitting-left-opt2';

import pants1 from 'assets/female/clothes/legs/pants-1';
import pants2 from 'assets/female/clothes/legs/pants-2';
import pants3 from 'assets/female/clothes/legs/pants-3';
import pants4 from 'assets/female/clothes/legs/pants-4';
import pants5 from 'assets/female/clothes/legs/pants-5';
import pants6 from 'assets/female/clothes/legs/pants-6';
import pants7 from 'assets/female/clothes/legs/pants-7';
import pants8 from 'assets/female/clothes/legs/pants-8';

import shoes1 from 'assets/female/shoes/shoes-1';
import shoes2 from 'assets/female/shoes/shoes-2';
import shoes3 from 'assets/female/shoes/shoes-3';
import shoes4 from 'assets/female/shoes/shoes-4';
import shoes5 from 'assets/female/shoes/shoes-5';
import shoes6 from 'assets/female/shoes/shoes-6';
import shoes7 from 'assets/female/shoes/shoes-7';
import shoes8 from 'assets/female/shoes/shoes-8';
import shoes9 from 'assets/female/shoes/shoes-9';
import shoes10 from 'assets/female/shoes/shoes-10';
import shoes11 from 'assets/female/shoes/shoes-11';
import shoes12 from 'assets/female/shoes/shoes-12';
import shoes13 from 'assets/female/shoes/shoes-13';
import shoes14 from 'assets/female/shoes/shoes-14';

export const F_POSES = {
    stand1: 'Standing #1',
    stand2: 'Standing #2',
    walk1: 'Walking #1',
    walk2: 'Walking #2',
    sitting1: 'Sitting',
} as const;

export const F_LEGS: Record<string, ILegs> = {
    pants1,
    pants2,
    pants3,
    pants4,
    pants5,
    pants6,
    pants7,
    pants8,
};

export const F_SHOES: Record<string, ILegs> = {
    shoes1,
    shoes2,
    shoes3,
    shoes4,
    shoes5,
    shoes6,
    shoes7,
    shoes8,
    shoes9,
    shoes10,
    shoes11,
    shoes12,
    shoes13,
    shoes14,
};

export const F_LEGS_SKIN: TLegsSkins = {
    stand1: [[Stand1], [Stand1Opt2]],
    stand2: [[Stand2], [Stand2Opt2]],
    walk1: [[Walk1], [Walk1Opt2]],
    walk2: [[Walk2], [Walk2Opt2]],
    sitting1: [[SittingRight, SittingLeft], [SittingRightOpt2, SittingLeftOpt2]],
};
