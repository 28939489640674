import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7326)"
            d="M494.78 222.54a109.63 109.63 0 00-20.026-15.329 31.051 31.051 0 012.394 13.895 66.038 66.038 0 01-1.451 10.762l2.169 4.754a.986.986 0 00.562.523 1.004 1.004 0 00.768-.03c2.597-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7326)"
            d="M508.181 234.769c-3.192-2.88-6.493-5.859-9.592-8.924l-.121-.119a27.502 27.502 0 00-38.889.457 27.503 27.503 0 00.458 38.889c2.165 2.114 4.287 4.327 6.367 6.535l43.673-35.119c-.633-.572-1.262-1.148-1.896-1.719z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7326"
                x1="502.631"
                x2="410.101"
                y1="201.76"
                y2="281.856"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7326"
                x1="509.605"
                x2="417.074"
                y1="209.823"
                y2="289.92"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
