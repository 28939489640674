import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-pants-2-stand-2">
        <mask
            id="man-34-25-pants-2-stand-2-a"
            width={179}
            height={398}
            x={364}
            y={388}
            maskUnits="userSpaceOnUse"
            style={{
                maskType: 'luminance',
            }}
        >
            <path
                fill="#fff"
                d="M364.355 754.714c28.932-56.091 57.815-94.343 79.52-116.639 7.688-7.718 14.241-15.949 23.889-23.005-18.881-58.208-26.154-91.38-36.692-143.34-4.139-21.828-9.99-40.835 5.572-61.686 20.149-26.995 66.736-21.546 95.36-21.546-6.372 25.674-5.39 29.965-2.968 39.439 18.204 74.812 13.632 148.133 8.973 193.769.538 11.067-3.775 17.591-9.473 23.704-82.304 88.042-90.493 96.216-132.046 139.75"
            />
        </mask>
        <g mask="url(#man-34-25-pants-2-stand-2-a)">
            <path
                fill="#001489"
                d="M364.355 754.714c28.932-56.091 57.815-94.343 79.52-116.639 7.688-7.718 14.241-15.949 23.889-23.005-18.881-58.208-26.154-91.38-36.692-143.34-4.139-21.828-9.99-40.835 5.572-61.686 20.149-26.995 66.736-21.546 95.36-21.546-6.372 25.674-5.39 29.965-2.968 39.439 18.204 74.812 13.632 148.133 8.973 193.769.538 11.067-3.775 17.591-9.473 23.704-82.304 88.042-90.493 96.216-132.046 139.75"
            />
            <path
                fill="#009CDE"
                d="m487.496 728.528-108.494-84.481-30.989 39.798 108.494 84.48 30.989-39.797Zm-39.38 51.403-109.298-85.107-10.179 13.073 109.298 85.107 10.179-13.073Z"
            />
        </g>
        <path
            fill="url(#man-34-25-pants-2-stand-2-b)"
            d="M370.239 387.121c-9.184 15.857-16.082 25.768-17.454 44.268a52.284 52.284 0 0 0-.919 12.188c1.617 38.358 8.676 89.007 19.37 138.96.899 4.214 2.477 13.845 4.004 23.16.765 4.673 1.53 9.335 2.212 13.345a50.747 50.747 0 0 1-.864 20.79c-5.946 24.084-7.154 59.754-2.814 82.955.89 5.227 3.685 24.48 6.644 44.865 3.574 24.615 7.269 50.068 7.97 53.279l.184.843 40.99-2.751.059-.873c.857-12.806 1.54-22.886 2.155-31.973 2.336-34.49 6.747-55.316 13.307-160.157 8.264-28.072 13.522-101.555 15.553-134.876a46.987 46.987 0 0 1 12.679-29.326l24.755-29.503s24.554-11.723 33.27-45.194H370.239Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-2-c)"
            d="M373.772 722.789c.891 5.228 3.686 24.481 6.645 44.865l.784 5.407h51.603c1.26-14.247 2.919-28.344 4.983-50.44h-64.045l.03.168Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-2-d)"
            d="M373.772 722.789c.891 5.228 3.686 24.481 6.645 44.865l.784 5.407h51.603c1.26-14.247 2.919-28.344 4.983-50.44h-64.045l.03.168Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-2-e)"
            d="M385.72 803.939h44.854l1.12-16.568h-48.409c.877 6.011 1.706 11.665 2.435 16.568Z"
        />
        <path
            fill="url(#man-34-25-pants-2-stand-2-f)"
            d="M385.72 803.939h44.854l1.12-16.568h-48.409c.877 6.011 1.706 11.665 2.435 16.568Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-pants-2-stand-2-b"
                x1={351.82}
                x2={531.34}
                y1={604.448}
                y2={604.448}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-2-c"
                x1={351.818}
                x2={531.338}
                y1={747.841}
                y2={747.841}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-2-d"
                x1={314.966}
                x2={474.136}
                y1={747.841}
                y2={747.841}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00283A" />
                <stop offset={0.009} stopColor="#004B6C" />
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-2-e"
                x1={351.819}
                x2={531.339}
                y1={795.655}
                y2={795.655}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <linearGradient
                id="man-34-25-pants-2-stand-2-f"
                x1={314.378}
                x2={474.727}
                y1={795.655}
                y2={795.655}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#00283A" />
                <stop offset={0.009} stopColor="#004B6C" />
                <stop offset={0.029} stopColor="#009CDE" />
                <stop offset={0.9} stopColor="#39BDE8" />
                <stop offset={1} stopColor="#40C1E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
