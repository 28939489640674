import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arms-option-1-left">
        <g clipPath="url(#man-bold-34-25-arms-option-1-left-a)">
            <path
                d="M504.501 206.273c4.618 6.989 9.599 13.736 14.424 20.588a193.459 193.459 0 0 1 13.139 21.442c3.992 7.405 7.799 14.933 11.349 22.631a240.986 240.986 0 0 1 9.628 23.774 23.297 23.297 0 0 1-.058 14.964 23.299 23.299 0 0 1-23.609 15.683 23.3 23.3 0 0 1-13.816-5.748 240.775 240.775 0 0 1-18.182-18.092c-5.72-6.257-11.183-12.685-16.462-19.235a193.376 193.376 0 0 1-14.671-20.424c-4.445-7.104-8.734-14.312-13.386-21.278a31.005 31.005 0 0 1-4.662-23.291 30.988 30.988 0 0 1 13.192-19.754 31.005 31.005 0 0 1 34.482.033 30.996 30.996 0 0 1 8.547 8.579l.085.128Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="m549.502 288.723 9.381 9.095 9.424 9.047c6.266 6.05 12.352 12.295 18.335 18.65 2.968 3.203 5.978 6.362 8.903 9.611 2.948 3.224 5.89 6.456 8.762 9.762 2.884 3.294 5.802 6.551 8.658 9.875 2.811 3.373 5.691 6.671 8.432 10.119a15.684 15.684 0 0 1-.771 20.418 15.679 15.679 0 0 1-20.299 2.322c-3.647-2.471-7.155-5.091-10.732-7.637-3.531-2.596-7.001-5.257-10.505-7.882-3.515-2.613-6.961-5.299-10.4-7.994-3.463-2.67-6.842-5.43-10.26-8.146-6.793-5.481-13.482-11.075-19.992-16.862l-9.739-8.709-9.782-8.661a22.497 22.497 0 0 1-7.549-15.486 22.501 22.501 0 0 1 29.804-22.643 22.514 22.514 0 0 1 8.33 5.121Z"
                fill={SKIN_COLOR[color]}
            />
            <path
                d="M515.558 319.607a240.775 240.775 0 0 1-18.182-18.092c-5.72-6.257-11.183-12.685-16.462-19.235a193.376 193.376 0 0 1-14.671-20.424c-4.445-7.104-8.734-14.312-13.386-21.278a31.005 31.005 0 0 1-4.662-23.291 30.988 30.988 0 0 1 13.192-19.754 31.005 31.005 0 0 1 34.482.033 30.996 30.996 0 0 1 8.547 8.579l.085.128c4.618 6.989 9.599 13.737 14.424 20.588a193.551 193.551 0 0 1 13.139 21.442c3.992 7.405 7.799 14.933 11.349 22.632 2.026 4.431 3.993 8.901 5.823 13.463M600.327 387.623c-3.646-2.47-7.155-5.09-10.731-7.636-3.532-2.596-7.002-5.257-10.505-7.882-3.515-2.613-6.962-5.299-10.401-7.994-3.463-2.67-6.841-5.43-10.26-8.146-6.793-5.481-13.482-11.075-19.991-16.862l-9.739-8.709-13.142-10.785M548.756 288.031c.234.207.523.477.746.692l9.381 9.095 9.424 9.047c6.266 6.05 12.352 12.294 18.335 18.65 2.968 3.203 5.977 6.362 8.902 9.611 2.949 3.224 5.89 6.456 8.763 9.762 2.884 3.294 5.802 6.551 8.658 9.875 2.811 3.372 5.691 6.671 8.432 10.119"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <defs>
            <clipPath id="man-bold-34-25-arms-option-1-left-a">
                <path
                    fill="#fff"
                    transform="translate(.827 .266)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
