import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-1">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-1-a)"
            d="M401.872 267.287c.164-.709.324-1.417.482-2.123a202.014 202.014 0 0 0 4.208-25.363c.263-2.564.507-5.13.752-7.696.555-5.818 1.129-11.833 1.905-17.718l.019-.15a32.034 32.034 0 0 0-31.785-36.026c-16.055 0-27.731 8.746-33.587 27.397-3.588 11.428-4.119 42.658-4.386 51.426a459.052 459.052 0 0 0-.318 13.236c31.813-9.217 62.71-2.983 62.71-2.983Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-1-a"
                x1={364.667}
                x2={378.546}
                y1={172.064}
                y2={268.353}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.301} stopColor="#909394" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
