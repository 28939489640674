import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m379.154 740.493.078.073-.033-.168c-.014.032-.03.063-.045.095ZM422.199 455.443c.227 3.78.564 7.528 1.001 11.14l.681 5.54.818 5.484c.376 2.737.866 5.494 1.34 8.162.168.945.336 1.89.498 2.828.449 2.742 1.016 5.504 1.565 8.176.191.928 1.686 8.268 1.686 8.268l1.262 5.441c.305 1.282.599 2.564.893 3.846.529 2.308 1.076 4.693 1.681 7.028 1.704 6.831 3.808 13.909 6.435 21.636 1.328 3.905 2.785 7.854 4.195 11.672 1.175 3.183 2.39 6.474 3.521 9.718l3.023 8.57c.44-2.107.882-4.215 1.29-6.327l2.043-10.968c1.105-6.023 2.474-13.965 3.265-21.981.695-6.991 1.123-14.36 1.272-21.905l.007-.368c.378-19.645 9.941-38.132 24.577-51.242l.245-.22c15.215-13.675 25.536-26.608 32.299-38.809a51.685 51.685 0 0 0-14.525-13.241H447.11a52.056 52.056 0 0 0-24.911 47.552ZM435.386 718.128l.738-24.143.596-24.132.764-31.47c-.788.869-1.578 1.75-2.37 2.644-11.861 13.732-21.165 25.76-28.011 39.811-2.479 4.886-4.73 10.033-6.908 15.011a566.973 566.973 0 0 1-3.353 7.571c-2.455 5.526-5.121 11.028-7.7 16.348-.955 1.971-1.907 3.942-2.856 5.915l-5.359 11.085c-.586 1.196-1.16 2.41-1.728 3.624l.033.167 33.415 31.37 21.946-27.73c.091-2.988.18-5.964.268-8.907.171-5.721.346-11.442.525-17.164Z"
            fill="#221E20"
        />
        <path
            d="M536.356 507.383c-.69-8.696-1.406-15.741-2.254-22.172-.453-3.419-.967-7.293-1.617-11.03-.16-.95-.316-1.899-.47-2.849-.435-2.671-.885-5.434-1.461-8.13l-1.089-5.49-1.26-5.442c-.964-4.059-1.939-7.619-2.978-10.882l-1.55-4.902a52.173 52.173 0 0 0-7.883-15.354 51.68 51.68 0 0 0-14.524-13.241h-54.161a52.049 52.049 0 0 0-24.911 47.552c.227 3.78.564 7.528 1.002 11.14l.68 5.54.818 5.484c.376 2.737.866 5.494 1.34 8.162.168.945.337 1.89.498 2.828.449 2.742 1.016 5.504 1.565 8.176.191.928 1.686 8.268 1.686 8.268l1.262 5.441c.305 1.282.599 2.564.893 3.846.529 2.308 1.076 4.693 1.682 7.028 1.703 6.831 3.807 13.909 6.435 21.636 1.327 3.905 2.784 7.853 4.194 11.672 1.175 3.183 2.39 6.474 3.521 9.718l3.023 8.57.748 2.122 3.663 10.715 9.296 27.311a126.662 126.662 0 0 0-2.795 2.182c-1.179.922-2.322 1.89-3.428 2.825-.464.393-.928.786-1.423 1.2-.538.484-1.082.962-1.627 1.441-.993.874-2.02 1.777-2.987 2.693-4.991 4.607-9.842 9.518-14.762 14.943-.788.869-1.578 1.751-2.37 2.645-11.861 13.732-21.165 25.76-28.011 39.811-2.479 4.885-4.73 10.033-6.907 15.011-1.106 2.529-2.212 5.058-3.354 7.571-2.455 5.525-5.121 11.027-7.7 16.348-.955 1.97-1.907 3.942-2.856 5.914l-5.359 11.085a224.043 224.043 0 0 0-1.728 3.625l.033.167 33.415 31.37 21.946-27.73 36.62-46.271 15.488-18.662 30.958-37.338a27.453 27.453 0 0 0 5.634-11.662 28.917 28.917 0 0 0 1.167-3.45l1.485-5.568c4.509-16.906 6.741-33.516 8.184-46.113.135-1.343.273-2.688.416-4.033.257-2.45.513-4.9.733-7.351l.939-11.342c.5-6.228 1.071-14.431 1.057-22.649-.003-7.171-.312-14.7-.916-22.379Z"
            fill="#221E20"
        />
        <path
            d="M351.084 407.891a83.542 83.542 0 0 0-1.534 6.611 68.062 68.062 0 0 0 1.168 29.89 52.896 52.896 0 0 0-.586 5.998 178.852 178.852 0 0 0-.092 10.957l.138 5.464.278 5.422c.106 2.701.324 5.432.535 8.074.075.94.15 1.881.221 2.813.179 2.717.474 5.465.759 8.122.099.921.87 8.216.87 8.216l.725 5.424c.179 1.281.347 2.562.515 3.844.3 2.295.611 4.668.985 6.999 1.028 6.822 2.432 13.92 4.292 21.699.938 3.927 2.002 7.909 3.031 11.759.859 3.218 1.749 6.546 2.559 9.82l2.708 10.777 2.598 10.79 10.339 43.174c.019.076.04.153.062.229a112.183 112.183 0 0 0-3.192 11.309 116.12 116.12 0 0 0-.937 4.311c-.122.597-.243 1.194-.377 1.827-.122.71-.253 1.421-.384 2.131a108.879 108.879 0 0 0-.677 3.944 237.634 237.634 0 0 0-2.552 24.308c-.804 18.046-.656 33.183 2.673 48.383 1.081 5.343 11.895 60.438 11.961 60.738l46.187-.332.502-14.181c.384-10.375.699-20.92 1.003-31.118.171-5.72.345-11.442.524-17.164l.737-24.142.597-24.133 1.172-48.265c.013-.553.001-1.104-.022-1.653a314.706 314.706 0 0 0 11.963-42.32c.265-1.296.534-2.592.808-3.888.495-2.366.99-4.732 1.447-7.102l2.043-10.969c1.105-6.023 2.475-13.965 3.265-21.98.695-6.992 1.123-14.36 1.272-21.906l.007-.368c.379-19.645 9.94-38.132 24.577-51.241l.245-.22c20.844-18.735 32.534-36.083 38.42-52.051H351.084Z"
            fill="url(#female-34-25-pants-5-stand-2-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-5-stand-2-a"
                x1={488.055}
                y1={591.246}
                x2={377.019}
                y2={594.716}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#222731" />
                <stop offset={1} stopColor="#1E1F23" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
