import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6366)"
            d="M412.089 238.508l-.022-.135a27.418 27.418 0 00-27.077-22.881 27.52 27.52 0 00-27.141 32.128c.92 5.392 1.656 10.918 2.367 16.261.173 1.293.35 2.583.525 3.874 0 0 7.923-11.686 25.923-18.084 19.876-7.064 27.832 1.673 27.832 1.673-.854-4.239-1.699-8.556-2.407-12.836z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6366"
                x1="357.455"
                x2="420.708"
                y1="241.624"
                y2="241.624"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
