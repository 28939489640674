import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6991)"
            d="M479.227 236.312c-.182-4.781-.96-19.424-3.838-27.14a101.214 101.214 0 0118.684 14.399 1.708 1.708 0 01.536 1.598c-.512 2.966-8.018 7.688-15.382 11.143z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6991)"
            d="M539.258 337.069c-.82 0-1.638-.014-2.453-.042a53.05 53.05 0 01-11.205-1.365 290.983 290.983 0 01-3.256-.794c-2.449-.608-4.981-1.236-7.485-1.693a19.397 19.397 0 01-12.242-7.69l-.038-.031c-.143-.12-.287-.242-.425-.367a169.153 169.153 0 01-14.186-15.054c-4.188-4.97-8.261-10.197-12.454-15.983a131.79 131.79 0 01-10.726-17.049 586.8 586.8 0 01-2.792-5.352c-2.1-4.053-4.273-8.245-6.578-12.258a27.507 27.507 0 012.069-30.487 27.49 27.49 0 0118.252-10.482 27.5 27.5 0 0127.375 13.579l.079.14c2.282 4.028 4.784 8.032 7.204 11.904 1.066 1.708 2.134 3.415 3.186 5.13a131.79 131.79 0 019.215 17.917c2.844 6.549 5.27 12.716 7.412 18.845a213.373 213.373 0 013.362 10.546 53.659 53.659 0 0110.311 2.679c.881.306 1.77.637 2.658.985.923.341 1.857.734 2.647 1.074l2.604 1.104c.574.246 1.14.519 1.705.792.304.147.609.294.915.437a69.195 69.195 0 0110.323 5.963l2.213 1.589-2.715.22c-6.566.533-11.76 3.466-14.623 8.261-2.728 4.567-2.924 10.305-.54 15.742l.571 1.303-1.42.097c-.332.022-.666.05-1 .076-.63.052-1.259.103-1.884.125l-2.835.091c-.765.029-1.536.052-2.302.052l-.942-.004z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6991"
                x1="494.846"
                x2="405.931"
                y1="197.302"
                y2="377.736"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6991"
                x1="538.083"
                x2="447.182"
                y1="216.913"
                y2="401.376"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
