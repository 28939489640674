import { IBodyClothes } from 'interfaces';

import JacketP1 from './man-34-25-jacket-p1';
import JacketP2 from './man-34-25-jacket-p2';
import JacketP1Sitting from './man-34-25-jacket-p1-sitting';

import JacketRightArmDown from './man-34-25-jacket-arm-right-down';
import JacketRightArmOption1 from './man-34-25-jacket-arm-right-option-1';
import JacketRightArmOption2 from './man-34-25-jacket-arm-right-option-2';
import JacketRightArmOption3 from './man-34-25-jacket-arm-right-option-3';
import JacketRightArmOption4 from './man-34-25-jacket-arm-right-option-4';
import JacketRightArmOption5 from './man-34-25-jacket-arm-right-option-5';
import JacketRightArmOption6 from './man-34-25-jacket-arm-right-option-6';
import JacketRightArmOption7 from './man-34-25-jacket-arm-right-option-7';

import JacketLeftArmDown from './man-34-25-jacket-arm-left-down';
import JacketLeftArmUp from './man-34-25-jacket-arm-left-up';
import JacketLeftArmOption1 from './man-34-25-jacket-arm-left-option-1';
import JacketLeftArmOption2 from './man-34-25-jacket-arm-left-option-2';
import JacketLeftArmOption3 from './man-34-25-jacket-arm-left-option-3';
import JacketLeftArmOption4 from './man-34-25-jacket-arm-left-option-4';
import JacketLeftArmOption5 from './man-34-25-jacket-arm-left-option-5';
import JacketLeftArmOption6 from './man-34-25-jacket-arm-left-option-6';
import JacketLeftArmOption7 from './man-34-25-jacket-arm-left-option-7';

import JacketBothArmsOption1Right from './man-34-25-jacket-both-arms-option-1-right';
import JacketBothArmsOption1Left from './man-34-25-jacket-both-arms-option-1-left';
import JacketBothArmsOption3Right from './man-34-25-jacket-both-arms-option-3-right';
import JacketBothArmsOption3Left from './man-34-25-jacket-both-arms-option-3-left';

const jacket: IBodyClothes = {
    text: 'Jacket',
    normal: {
        front: JacketP1,
        back: JacketP2,
        rightArm: {
            down: JacketRightArmDown,
            option1: JacketRightArmOption1,
            option2: JacketRightArmOption2,
            option3: JacketRightArmOption3,
            option4: JacketRightArmOption4,
            option5: JacketRightArmOption5,
            option6: JacketRightArmOption6,
            option7: JacketRightArmOption7,
        },
        leftArm: {
            down: JacketLeftArmDown,
            up: JacketLeftArmUp,
            option1: JacketLeftArmOption1,
            option2: JacketLeftArmOption2,
            option3: JacketLeftArmOption3,
            option4: JacketLeftArmOption4,
            option5: JacketLeftArmOption5,
            option6: JacketLeftArmOption6,
            option7: JacketLeftArmOption7,
        },
        bothArms: {
            option1: [JacketBothArmsOption1Right, JacketBothArmsOption1Left],
            option3: [JacketBothArmsOption3Right, JacketBothArmsOption3Left],
        },
    },
    sitting: {
        front: JacketP1Sitting,
        back: JacketP2,
        rightArm: {
            down: JacketRightArmDown,
            option1: JacketRightArmOption1,
            option2: JacketRightArmOption2,
            option3: JacketRightArmOption3,
            option4: JacketRightArmOption4,
            option5: JacketRightArmOption5,
            option6: JacketRightArmOption6,
            option7: JacketRightArmOption7,
        },
        leftArm: {
            down: JacketLeftArmDown,
            up: JacketLeftArmUp,
            option1: JacketLeftArmOption1,
            option2: JacketLeftArmOption2,
            option3: JacketLeftArmOption3,
            option4: JacketLeftArmOption4,
            option5: JacketLeftArmOption5,
            option6: JacketLeftArmOption6,
            option7: JacketLeftArmOption7,
        },
        bothArms: {
            option1: [JacketBothArmsOption1Right, JacketBothArmsOption1Left],
            option3: [JacketBothArmsOption3Right, JacketBothArmsOption3Left],
        },
    },
};

export default jacket;
