import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-4-stand-2">
            <path
                d="M373.011 853.188c-17.067-16.337-20.013-32.38-20.352-34.641l-14.148-20.709a9.026 9.026 0 0 1-1.377-7.079 9.01 9.01 0 0 1 4.202-5.853c13.198-7.927 19.277-11.85 21.454-13.75l21.834 28.782c-.13 1.076-7.886 24.676-.894 41.785 3.659 8.953 4.282 14.336 1.85 15.997-2.82 1.927-7.636.19-12.569-4.532Z"
                fill="#E2E2E2"
            />
            <path
                d="M384.237 803.177a3.107 3.107 0 0 1 .642 2.555c-1.798 9.403-4.342 25.531-.223 35.61 5.216 12.765 3.347 15.931 1.488 17.201-3.282 2.242-8.45.509-13.825-4.636-11.533-11.04-16.713-21.99-18.988-28.75a60.002 60.002 0 0 0-7.391-14.676l-8.077-11.822a10.507 10.507 0 0 1-1.772-7.793 10.018 10.018 0 0 1 4.73-6.82c5.723-3.437 10.549-6.397 14.351-8.797a3.218 3.218 0 0 1 3.797.19c3.281 2.83-1.438 10.537 3.212 16.37 4.944 6.204 19.555 8.313 22.056 11.368Z"
                fill="#63666A"
            />
            <path
                d="M385.219 859.035a16.367 16.367 0 0 1-6.698-3.252c-8.31-6.073-19.168-29.606-24.612-40.071-4.946-9.508-2.43-19.938-9.337-33.931-1.205.73-2.452 1.483-3.751 2.263a10.004 10.004 0 0 0-4.73 6.821 10.5 10.5 0 0 0 1.773 7.792l8.076 11.822a60.008 60.008 0 0 1 7.391 14.677c2.275 6.759 7.455 17.709 18.988 28.749 4.897 4.687 9.616 6.532 12.9 5.13ZM407.925 859.16a9.046 9.046 0 0 1-6.656-2.87 9.062 9.062 0 0 1-2.474-6.804c.914-15.624 1.226-25.838.88-28.76l32.733-.601c.107.167 9.482 16.793 16.996 21.852 3.342 2.25 9.955 5.864 13.601 7.818a4.963 4.963 0 0 1 2.622 4.386 4.983 4.983 0 0 1-4.979 4.98l-52.723-.001Z"
                fill="#E2E2E2"
            />
            <path
                d="M432.947 823.078c1.544-.012 2.309.836 3.173 2.116 3.103 4.595 8.238 12.638 14.033 16.54 3.247 2.187 9.658 5.697 13.325 7.665a6.101 6.101 0 0 1 3.297 5.057 5.908 5.908 0 0 1-3.58 5.661 5.901 5.901 0 0 1-2.31.474h-54.467a10.464 10.464 0 0 1-7.404-2.918 9.967 9.967 0 0 1-2.955-7.725c.644-11.018.99-12.854 1.016-18.065.013-2.52.436-7.545 2.804-8.408 14.875 4.43 33.068-.397 33.068-.397Z"
                fill="#63666A"
            />
            <path
                d="M411.518 856.05c-5.969-2.419-12.757-9.857-15.083-12.55-.107 2.05-.226 4.198-.357 6.445a9.969 9.969 0 0 0 2.955 7.725 10.462 10.462 0 0 0 7.404 2.918h54.451a5.889 5.889 0 0 0 5.006-2.792c-14.504.113-47.44 1.066-54.376-1.746Z"
                fill="#E2E2E2"
            />
        </g>
    );
}
