import { IBodyClothes } from 'interfaces';

import P1 from './man-34-25-doctors-coat-p1';
import P2 from './man-34-25-doctors-coat-p2';
import P1Sitting from './man-34-25-doctors-coat-p1-sitting';

import ArmRightDown from './man-34-25-doctors-coat-arm-right-down';
import ArmRightOption1 from './man-34-25-doctors-coat-arm-right-option-1';
import ArmRightOption2 from './man-34-25-doctors-coat-arm-right-option-2';
import ArmRightOption3 from './man-34-25-doctors-coat-arm-right-option-3';
import ArmRightOption4 from './man-34-25-doctors-coat-arm-right-option-4';
import ArmRightOption5 from './man-34-25-doctors-coat-arm-right-option-5';
import ArmRightOption6 from './man-34-25-doctors-coat-arm-right-option-6';
import ArmRightOption7 from './man-34-25-doctors-coat-arm-right-option-7';

import ArmLeftUp from './man-34-25-doctors-coat-arm-left-up';
import ArmLeftDown from './man-34-25-doctors-coat-arm-left-down';
import ArmLeftOption1 from './man-34-25-doctors-coat-arm-left-option-1';
import ArmLeftOption2 from './man-34-25-doctors-coat-arm-left-option-2';
import ArmLeftOption3 from './man-34-25-doctors-coat-arm-left-option-3';
import ArmLeftOption4 from './man-34-25-doctors-coat-arm-left-option-4';
import ArmLeftOption5 from './man-34-25-doctors-coat-arm-left-option-5';
import ArmLeftOption6 from './man-34-25-doctors-coat-arm-left-option-6';
import ArmLeftOption7 from './man-34-25-doctors-coat-arm-left-option-7';

import BothArmsOption1Right from './man-34-25-doctors-coat-both-arms-option-1-right';
import BothArmsOption1Left from './man-34-25-doctors-coat-both-arms-option-1-left';
import BothArmsOption2Right from './man-34-25-doctors-coat-both-arms-option-2-right';
import BothArmsOption2Left from './man-34-25-doctors-coat-both-arms-option-2-left';
import BothArmsOption3Right from './man-34-25-doctors-coat-both-arms-option-3-right';
import BothArmsOption3Left from './man-34-25-doctors-coat-both-arms-option-3-left';

const doctorsCoat: IBodyClothes = {
    text: 'Doctor\'s coat',
    normal: {
        front: P1,
        back: P2,
        rightArm: {
            down: ArmRightDown,
            option1: ArmRightOption1,
            option2: ArmRightOption2,
            option3: ArmRightOption3,
            option4: ArmRightOption4,
            option5: ArmRightOption5,
            option6: ArmRightOption6,
            option7: ArmRightOption7,
        },
        leftArm: {
            down: ArmLeftDown,
            up: ArmLeftUp,
            option1: ArmLeftOption1,
            option2: ArmLeftOption2,
            option3: ArmLeftOption3,
            option4: ArmLeftOption4,
            option5: ArmLeftOption5,
            option6: ArmLeftOption6,
            option7: ArmLeftOption7,
        },
        bothArms: {
            option1: [BothArmsOption1Right, BothArmsOption1Left],
            option2: [BothArmsOption2Right, BothArmsOption2Left],
            option3: [BothArmsOption3Right, BothArmsOption3Left],
        }
    },
    sitting: {
        front: P1Sitting,
        back: P2,
        rightArm: {
            down: ArmRightDown,
            option1: ArmRightOption1,
            option2: ArmRightOption2,
            option3: ArmRightOption3,
            option4: ArmRightOption4,
            option5: ArmRightOption5,
            option6: ArmRightOption6,
            option7: ArmRightOption7,
        },
        leftArm: {
            down: ArmLeftDown,
            up: ArmLeftUp,
            option1: ArmLeftOption1,
            option2: ArmLeftOption2,
            option3: ArmLeftOption3,
            option4: ArmLeftOption4,
            option5: ArmLeftOption5,
            option6: ArmLeftOption6,
            option7: ArmLeftOption7,
        },
        bothArms: {
            option1: [BothArmsOption1Right, BothArmsOption1Left],
            option2: [BothArmsOption2Right, BothArmsOption2Left],
            option3: [BothArmsOption3Right, BothArmsOption3Left],
        }
    }
};

export default doctorsCoat;
