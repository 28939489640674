import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M409.153 258.986a26.493 26.493 0 0 0-9.844-36.16 26.512 26.512 0 0 0-29.374 2.043 26.484 26.484 0 0 0-6.787 7.801c-3.903 6.823-8.162 13.44-12.268 20.146-10.644 17.27-22.913 46.944-27.835 65.599a20.28 20.28 0 0 0-.671 5.32 18.557 18.557 0 0 0-.254 3.122c-.815 38.197 2.024 70.68 6.483 96.095l28.242-1.496c4.286-32.632 3.529-62.163 2.512-88.988 4.115-4.422 7.96-8.998 11.706-13.631 23.66-29.45 26.784-40.087 38.09-59.851Z"
            fill="url(#female-34-25-shirt-1-arm-right-opt-1a)"
        />
        <path
            d="m409.154 258.984-.065.115c-3.937 6.803-7.517 13.811-11.251 20.731a181.06 181.06 0 0 1-12.509 20.007c-4.55 6.45-9.281 12.797-14.264 18.998-3.117 3.855-6.303 7.671-9.655 11.391M322.421 323.603c-.029-1.751.18-3.497.622-5.191a225.667 225.667 0 0 1 7.57-22.848c2.857-7.425 5.966-14.705 9.256-21.88a180.969 180.969 0 0 1 11.009-20.87c4.105-6.706 13.479-21.812 17.381-28.634M330.913 434.882c-1.921-8.977-3.283-17.966-4.479-26.959-.285-2.248-.608-4.496-.863-6.745l-.752-6.747c-.544-4.498-.875-9-1.31-13.499-.832-9-1.263-18.009-1.294-27.026l.023-13.528.182-16.776M359.34 332.898l.822 20.206c.361 9.011.312 18.03-.146 27.057-.243 4.514-.383 9.026-.736 13.543l-.464 6.773c-.159 2.258-.387 4.517-.576 6.776-.813 9.035-1.792 18.073-3.33 27.124"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-right-opt-1a"
                x1={432.626}
                y1={163.407}
                x2={338.257}
                y2={369.637}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={0.936} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
