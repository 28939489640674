import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={43}
            height={24}
            fill="none"
            id="object-food-cashew"
            {...attributes}
        >
            <path
                fill="url(#object-food-cashew-a)"
                d="M38.068 17.933a28.087 28.087 0 0 1-9.323 4.28 27.365 27.365 0 0 1-9.974.627 26.82 26.82 0 0 1-9.318-2.918 26.168 26.168 0 0 1-7.464-5.92 6.29 6.29 0 0 1 .613-8.876 6.293 6.293 0 0 1 7.942-.263l.33.254c2.619 1.994 5.776 2.83 8.722 2.546 2.959-.267 5.568-1.667 7.297-3.625a9.02 9.02 0 0 1 12.732-.794 9.02 9.02 0 0 1-.733 14.11l-.823.579h-.001Z"
            />
            <defs>
                <linearGradient
                    id="object-food-cashew-a"
                    x1={0.442}
                    x2={42.676}
                    y1={12.014}
                    y2={12.014}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#FFB33B" />
                    <stop offset={0.82} stopColor="#FFCC09" />
                    <stop offset={0.971} stopColor="#FFD100" />
                </linearGradient>
            </defs>
        </svg>
    );
}
