import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7253)"
            d="M514.426 258.431s-.293-.732-.362-.893a787.971 787.971 0 00-2.808-6.559c-2.14-4.969-4.353-10.107-6.34-15.226l-.049-.126a27.503 27.503 0 00-35.66-15.495 27.497 27.497 0 00-15.514 35.652c2.013 5.11 3.872 10.387 5.671 15.491.79 2.242 1.585 4.481 2.384 6.717.674 1.889 1.41 3.833 2.25 5.94l.405 1.018 50.383-25.663-.36-.856z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7253)"
            d="M494.773 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7253"
                x1="505.221"
                x2="416.088"
                y1="232.444"
                y2="280.589"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7253"
                x1="494.293"
                x2="405.161"
                y1="212.209"
                y2="260.353"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.006" stopColor="#64CCC9"></stop>
                <stop offset="1" stopColor="#BBE9E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
