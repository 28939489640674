import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#004F71"
            d="M573.158 594.99l-3.29-40.5c-1.599-15.049-.145-29.603 2.332-46.994.639-4.164 1.447-8.348 2.313-12.578l-97.879-.164-4.511-.578c-2.94-.381-5.963-.853-8.984-1.401a53.359 53.359 0 01-42.927-61.96 53.243 53.243 0 0156.051-43.665l7.251.497c2.761.188 5.734.482 9.086.899l4.494.578 4.493.685c2.216.314 4.455.713 6.621 1.1l2.31.408c2.228.369 4.476.826 6.651 1.268l2.243.453c12.696 2.634 24.221 5.406 35.243 8.478 5.417 1.528 11.095 3.45 17.361 5.876a307.312 307.312 0 0117.064 7.2c1.282.577 2.67 1.228 4.237 1.987a164.59 164.59 0 014.217 2.07c1.815.893 3.648 1.882 5.422 2.838.977.526 1.955 1.054 2.936 1.568a165.672 165.672 0 0116.527 10.37l.87.637a37.35 37.35 0 0112.065 14.988 30.499 30.499 0 012.151 15.905l-5.723 46.616-2.873 23.309-5.406 60.11h-50.345z"
        ></path>
    </g>
);

export default SvgComponent;
