import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10269)"
            d="M494.774 223.337a109.595 109.595 0 00-20.026-15.329 31.049 31.049 0 012.394 13.895 65.928 65.928 0 01-1.451 10.762l2.169 4.754a1.001 1.001 0 001.33.493c2.597-1.201 15.6-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_10269)"
            d="M503.369 224.736c-5.143-1.464-10.462-2.978-15.625-4.657l-.129-.041a27.498 27.498 0 00-26.186 47.217 27.503 27.503 0 009.443 5.172c5.158 1.649 10.349 3.523 15.368 5.334l3.58 1.291 18.676-52.85s-3.758-1.076-5.127-1.466z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10269"
                x1="488.144"
                x2="463.854"
                y1="204.864"
                y2="303.467"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_10269"
                x1="487.471"
                x2="463.182"
                y1="204.704"
                y2="303.307"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.033" stopColor="#888B8D"></stop>
                <stop offset="0.966" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
