import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6481)"
            d="M402.469 354.206a19.367 19.367 0 01-15.455-7.59l-.004-.003a20.436 20.436 0 01-5.465-7.1 228.593 228.593 0 01-8.711-22.542c-2.406-7.233-4.621-14.698-6.772-22.826-2.132-7.808-4.21-15.506-5.252-23.102-.325-2.326-2.655-17.875-3.32-23.306-1.897-15.506 8.005-29.299 22.954-31.849a27.825 27.825 0 014.655-.396 27.415 27.415 0 0127.077 22.881l.024.138c.887 5.369 4.073 20.975 4.522 23.268a180.644 180.644 0 013.023 23.356c.29 3.911.527 7.93.707 11.938a262.77 262.77 0 0111.236-7.306 360.12 360.12 0 003.467-2.13c2.397-1.481 4.872-3.01 7.331-4.417l5.431-3.182c1.182-.689 2.379-1.353 3.576-2.018 0 0 1.357-.755 1.946-1.087 3.139-1.743 7.338-4.05 12.039-6.453l1.64-.837-.189 1.831c-1.013 9.766 7.411 20.8 13.655 24.104l1.217.644-.989.958a439.273 439.273 0 01-7.552 7.128 409.43 409.43 0 00-1.644 1.512 190.35 190.35 0 01-3.035 2.765l-4.73 4.175c-2.123 1.911-4.318 3.758-6.441 5.543-1.065.896-2.106 1.771-3.142 2.657a262.31 262.31 0 01-20 15.366l-10.355 7.112-10.26 7.22a19.344 19.344 0 01-11.184 3.548z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6481"
                x1="386.582"
                x2="442.1"
                y1="193.426"
                y2="347.836"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
