import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={52}
            fill="none"
            id="object-food-raspberry"
            {...attributes}
        >
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M10.19 10.813a13.795 13.795 0 0 0 9.117 4.04 13.79 13.79 0 0 0-4.039-9.119A13.797 13.797 0 0 0 6.15 1.695a13.79 13.79 0 0 0 4.04 9.118ZM28.425 10.813a13.795 13.795 0 0 1-9.118 4.04 13.79 13.79 0 0 1 4.039-9.119 13.797 13.797 0 0 1 9.118-4.039 13.79 13.79 0 0 1-4.04 9.118Z"
            />
            <path
                fill="#EF0025"
                d="M14.65 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M23.963 51.187a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM5.34 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M14.65 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M23.963 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M33.275 35.056a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.996 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M19.306 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M28.619 26.992a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.312ZM9.996 43.12a4.656 4.656 0 1 0 0-9.312 4.656 4.656 0 0 0 0 9.313Z"
            />
            <path
                fill="#EF0025"
                d="M19.306 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
            <path
                fill="#EF0025"
                d="M28.619 43.12a4.656 4.656 0 1 0 0-9.311 4.656 4.656 0 0 0 0 9.312Z"
            />
        </svg>
    );
}
