import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#004F71"
                d="M533.712 675.469l8.032 31.417 7.85-.064s.06-6.057-.043-10.414c-.072-3.021 1.753-6.23 5.148-6.05"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M541.744 706.893l7.996-.065"
            ></path>
            <path
                fill="url(#paint0_linear_11264_6728)"
                d="M599.155 710.481c-3.678.64-7.429.746-11.137.316l-5.457-.632a24.351 24.351 0 01-15.304-7.907l-6.986-7.769a22.362 22.362 0 00-10.796-6.634l-5.915-1.596a13.9 13.9 0 01-10.121-15.638l1.73-22.809 45.557 1.715 13.025 36.369a8.553 8.553 0 004.803 5.012l13.033 5.332a6.436 6.436 0 014.05 6.174 6.455 6.455 0 01-5.319 6.132l-11.163 1.935z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M615.108 706.334l-.029.034a10.34 10.34 0 01-7.812 3.595l-24.705.201a24.33 24.33 0 01-15.305-7.907l-6.983-7.765a22.366 22.366 0 00-10.804-6.638l-5.91-1.596a15.92 15.92 0 01-8.548-6.672"
            ></path>
            <defs>
                <linearGradient
                    id="paint0_linear_11264_6728"
                    x1="533.124"
                    x2="616.136"
                    y1="678.737"
                    y2="680.636"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset="0.001" stopColor="#004F71"></stop>
                    <stop offset="0.125" stopColor="#01567A"></stop>
                    <stop offset="1" stopColor="#0B86B8"></stop>
                </linearGradient>
            </defs>
        </g>
    );
}
