import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9580)"
            d="M363.791 276.119c-.294-1.18-.585-2.361-.874-3.544l-.029-.121c-1.163-4.752-2.365-9.666-3.738-14.444a26.52 26.52 0 0125.483-33.822 26.61 26.61 0 0125.457 19.191l.041.141c1.331 4.734 2.862 9.459 4.351 14.036l-50.691 18.563z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M410.132 243.516c1.331 4.734 2.862 9.459 4.35 14.036l-50.69 18.563c-.294-1.18-.585-2.362-.874-3.544M365.27 232.844c-6.217 5.927-8.645 16.368-6.12 25.16 1.248 4.347 3.767 14.565 3.767 14.565"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M410.094 243.375l.04.139c.973 3.459 2.045 6.89 3.145 10.313"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9580"
                x1="327.83"
                x2="406.77"
                y1="192.156"
                y2="279.77"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
