import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 271,
        height: 84,
        content: (
            <>
                <path
                    stroke="#00B140"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={18}
                    d="m76.86 43.223-67.073.491M76.857 43.224 18.713 9.785"
                />
                <path
                    fill="url(#object-food-carrot-a)"
                    d="M264.977 67.63 110.411 10.119c-24.258-9.027-50.077 8.914-50.077 34.798 0 20.506 16.623 37.13 37.129 37.13h164.92a7.44 7.44 0 0 0 2.595-14.415h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M230.542 82.045h31.839a7.44 7.44 0 0 0 2.594-14.414L110.409 10.118c-6.651-2.475-13.418-2.922-19.74-1.735M66.573 24.268c-3.913 5.823-6.239 12.878-6.239 20.647 0 20.506 16.623 37.129 37.129 37.129h106.793M115.75 82.045v-26.82M155.334 82.042V62.935M194.916 82.046V69.03M234.5 82.046V73.9"
                />
                <defs>
                    <linearGradient
                        id="object-food-carrot-a"
                        x1={79.473}
                        x2={246.925}
                        y1={93.939}
                        y2={9.227}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Option #2': {
        width: 271,
        height: 157,
        content: (
            <>
                <path
                    stroke="#00B140"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={18}
                    d="m76.86 116.953-67.073.492M76.857 116.955l-58.144-33.44"
                />
                <path
                    fill="url(#object-food-carrot-a)"
                    d="M264.977 141.361 110.411 83.849c-24.258-9.027-50.077 8.914-50.077 34.798 0 20.505 16.623 37.129 37.129 37.129h164.92a7.441 7.441 0 0 0 2.595-14.415h-.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M230.542 155.776h31.839a7.44 7.44 0 0 0 2.594-14.415L110.409 83.849c-6.651-2.475-13.418-2.923-19.74-1.736M66.573 98c-3.913 5.824-6.239 12.878-6.239 20.647 0 20.506 16.623 37.129 37.129 37.129h106.793M115.75 155.774v-26.821M155.334 155.771v-19.107M194.916 155.775v-13.017M234.5 155.778v-8.145M115.75 68.95c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398c0-11.2 6.767-11.2 6.767-22.398M166.039 68.95c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398c0-11.2 6.767-11.2 6.767-22.398M216.326 68.95c0-11.198 6.767-11.198 6.767-22.396s-6.767-11.199-6.767-22.398c0-11.2 6.767-11.2 6.767-22.398"
                />
                <defs>
                    <linearGradient
                        id="object-food-carrot-a"
                        x1={79.473}
                        x2={246.925}
                        y1={167.669}
                        y2={82.957}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F64E2E" />
                        <stop offset={0.37} stopColor="#F76430" />
                        <stop offset={1} stopColor="#FB8735" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-carrot"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
