import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={58}
            height={49}
            fill="none"
            id="object-food-codler"
            {...attributes}
        >
            <path
                fill="url(#object-food-codler-a)"
                d="M38.02 25.21H19.304a28.255 28.255 0 0 1-7.761 19.452c-.845.89-.214 2.36 1.014 2.36h32.21c1.228 0 1.859-1.47 1.014-2.36A28.255 28.255 0 0 1 38.02 25.21Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M38.02 25.21H19.304a28.255 28.255 0 0 1-7.761 19.452c-.845.89-.214 2.36 1.014 2.36h32.21c1.228 0 1.859-1.47 1.014-2.36A28.255 28.255 0 0 1 38.02 25.21Z"
            />
            <path
                fill="url(#object-food-codler-b)"
                d="M1 1.496h55.326v.884c0 15.268-12.395 27.663-27.663 27.663S1 17.648 1 2.38v-.884Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M20.094 1.496h36.232v.884c0 15.278-12.385 27.663-27.663 27.663M19.128 28.356C8.548 24.472 1 14.307 1 2.38v-.884h8.709"
            />
            <defs>
                <linearGradient
                    id="object-food-codler-a"
                    x1={48.203}
                    x2={12.518}
                    y1={36.116}
                    y2={36.116}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="object-food-codler-b"
                    x1={50.273}
                    x2={10.81}
                    y1={15.77}
                    y2={15.77}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
            </defs>
        </svg>
    );
}
