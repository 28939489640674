import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 677,
        height: 215,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M52.159 45.93 41.338 213.76h21.6L80.19 83.253c1.524-11.529 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h21.6L226.165 45.91h230.709M456.876 45.91l-10.821 167.85h21.6l17.253-130.507c1.524-11.529 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h21.6L630.882 45.91h44.366M1.773 27.64h673.475V1.734H1.773M1.773 27.64v18.288H52.16"
                />
            </>
        ),
    },
    'Color #1': {
        width: 677,
        height: 214,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M204.171 213.76h21.6l-12-186.12H42.121l-12 186.12h21.6L68.974 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L204.17 213.76h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M215.286 213.76h21.6l-12-186.12H53.236l-12 186.12h21.6L80.09 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M608.888 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M620.001 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L620 213.76h.001Z"
                />
                <path fill="#C2C9CF" d="M675.146 1.734H458.41V27.64h216.736V1.734Z" />
                <path fill="#D9D9D6" d="M451.842 27.64H47.631v18.288h404.211V27.64Z" />
                <path fill="#ECF3F9" d="M458.41 1.734H1.672V27.64H458.41V1.734Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M75.946 1.734H1.672V27.64h673.474V1.734H162.851"
                />
            </>
        ),
    },
    'Color #2': {
        width: 676,
        height: 214,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M203.921 213.76h21.6l-12-186.12H41.871l-12 186.12h21.6L68.724 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L203.92 213.76h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M215.036 213.76h21.6l-12-186.12H52.986l-12 186.12h21.6L79.84 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M608.636 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M619.751 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L619.75 213.76h.001Z"
                />
                <path fill="#E5B97F" d="M674.896 1.734H458.16V27.64h216.736V1.734Z" />
                <path fill="#D9D9D6" d="M451.592 27.64H47.381v18.288h404.211V27.64Z" />
                <path fill="#FFE4B8" d="M458.16 1.734H1.422V27.64H458.16V1.734Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M75.695 1.734H1.422V27.64h673.474V1.734H162.601"
                />
            </>
        ),
    },
    'Color #3': {
        width: 676,
        height: 214,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M203.671 213.76h21.6l-12-186.12H41.621l-12 186.12h21.6L68.474 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L203.67 213.76h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M214.786 213.76h21.6l-12-186.12H52.736l-12 186.12h21.6L79.59 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M608.388 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M619.501 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145L619.5 213.76h.001Z"
                />
                <path fill="#EA9975" d="M674.646 1.734H457.91V27.64h216.736V1.734Z" />
                <path fill="#D9D9D6" d="M451.342 27.64H47.131v18.288h404.211V27.64Z" />
                <path fill="#FCCBBB" d="M457.91 1.734H1.172V27.64H457.91V1.734Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M75.446 1.734H1.172V27.64h673.475V1.734H162.351"
                />
            </>
        ),
    },
    'Color #4': {
        width: 677,
        height: 214,
        content: (
            <>
                <path
                    fill="#D9D9D6"
                    d="M204.417 213.76h21.6l-12-186.12H42.367l-12 186.12h21.6L69.22 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M215.532 213.76h21.6l-12-186.12H53.482l-12 186.12h21.6L80.335 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#D9D9D6"
                    d="M609.134 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6l17.253-130.507c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path
                    fill="#888B8D"
                    d="M620.247 213.76h21.6l-12-186.12h-171.65l-12 186.12h21.6L485.05 83.253c1.524-11.53 11.354-20.145 22.983-20.145h71.976c11.629 0 21.459 8.616 22.983 20.145l17.254 130.507h.001Z"
                />
                <path fill="#FFD100" d="M675.392 1.734H458.656V27.64h216.736V1.734Z" />
                <path fill="#D9D9D6" d="M452.088 27.64H47.877v18.288h404.211V27.64Z" />
                <path fill="#FFF4C3" d="M458.656 1.734H1.918V27.64h456.738V1.734Z" />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M76.192 1.734H1.918V27.64h673.474V1.734H163.097"
                />
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-outdoor-bench-1"
            xmlns="http://www.w3.org/2000/svg"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
