import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7227)"
            d="M475.685 232.67l.065-.318a64.034 64.034 0 001.386-10.446 32.912 32.912 0 00-1.357-11.177l-.834-2.627 2.325 1.482a106.659 106.659 0 0117.498 13.757 2.708 2.708 0 01.821 2.482c-.804 4.662-13.806 10.891-16.403 12.092l-.912.422-2.589-5.667z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7227)"
            d="M561.558 293.22a220.874 220.874 0 01-23.587-2.502c-7.383-1.146-14.888-2.57-22.947-4.353a175.532 175.532 0 01-22.379-6.302 767.48 767.48 0 01-6.564-2.353c-4.971-1.796-10.111-3.651-15.22-5.285a27.309 27.309 0 01-16.07-13.591 27.331 27.331 0 01-1.753-20.975 27.413 27.413 0 0126.189-19.135c2.844.001 5.669.444 8.377 1.312l.129.041c5.101 1.658 10.355 3.154 15.436 4.599 2.24.637 4.477 1.279 6.714 1.927a168.578 168.578 0 0112.783 4.274c-.192-.454-.381-.91-.568-1.368l-2.328-5.537a183.407 183.407 0 01-1.466-3.658c-.257-.654-.515-1.307-.774-1.96-2.21-5.631-3.923-10.26-5.391-14.573l-.495-1.452 1.529.134c.476.042.953.061 1.434.061 9.237-.001 18.715-7.22 22.519-11.772l.792-.947.763.971c2.6 3.318 5.307 6.939 8.518 11.395.414.584.83 1.161 1.245 1.737.764 1.06 1.526 2.119 2.265 3.193l3.4 4.964c1.558 2.226 3.057 4.536 4.506 6.77.708 1.091 1.415 2.182 2.133 3.268a238.564 238.564 0 0112.148 20.768l5.461 10.664 5.58 10.581a19.378 19.378 0 011.38 14.862 19.424 19.424 0 01-9.534 11.484 25.999 25.999 0 01-14.225 2.758z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7227"
                x1="488.254"
                x2="463.647"
                y1="204.852"
                y2="304.745"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7227"
                x1="451.719"
                x2="586.198"
                y1="241.568"
                y2="241.568"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
