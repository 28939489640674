import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-nose-1" transform="translate(316, 30)">
        <path
            d="M154.1 96c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1.1 3.5-2.6 4.6-1 .7-2.1 1.2-3.3 1.6"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M154.1 96c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1.1 3.5-2.6 4.6-1 .7-2.1 1.2-3.3 1.6"
            stroke="#111011"
            strokeWidth={2}
            strokeMiterlimit={10}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
