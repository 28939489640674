import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-both-arms-option-2-right">
        <path
            d="M406.359 182.337c-2.56-.22-5.121-.423-7.683-.626-5.834-.463-11.865-.941-17.77-1.623l-.15-.017a32 32 0 0 0-27.928 52.5 31.998 31.998 0 0 0 20.899 11.114c5.903.652 11.89 1.532 17.68 2.382 2.542.373 5.084.747 7.626 1.103 1.032.147 2.088.263 3.132.394l4.673-65.178c-.159-.014-.32-.035-.479-.049Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-2-right-a)"
        />
        <path
            d="M352.266 231.883a31.782 31.782 0 0 0 21.461 11.802c5.903.651 11.89 1.531 17.68 2.381 2.543.374 5.085.747 7.626 1.103 1.032.148 2.088.263 3.132.395l4.673-65.179c-.158-.013-.32-.034-.478-.048-2.56-.22-5.122-.423-7.684-.627-5.833-.462-11.865-.941-17.769-1.622l-.15-.017a32.695 32.695 0 0 0-3.553-.196M390.438 181.41l-13.374 62.313M388.559 245.026l4.915-30.94"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m567.253 300.287-8.64.26c-2.879.103-5.755.246-8.636.324-2.883.038-5.76.184-8.649.129-1.442.003-2.887-.033-4.333-.077a131.316 131.316 0 0 1-4.339-.159c-5.788-.25-11.579-.566-17.383-1.061a276.865 276.865 0 0 1-6.694-.614c3.396-10.327 3.45-30.713-4.224-34.526a263.495 263.495 0 0 1 8.533-1.942c5.692-1.235 11.395-2.29 17.103-3.28 1.426-.26 2.854-.498 4.283-.714 1.428-.228 2.856-.45 4.288-.631 2.858-.425 5.73-.649 8.594-.981 2.867-.292 5.738-.518 8.606-.786l8.603-.849a22.51 22.51 0 0 1 24.592 20.195 22.512 22.512 0 0 1-21.704 24.712Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-2-right-b)"
        />
        <path
            d="M576.712 256.758c5.524 5.014 11.576 11.805 12.268 18.812a22.513 22.513 0 0 1-21.727 24.715l-8.64.26c-2.879.103-5.756.247-8.636.324-2.884.038-5.76.184-8.649.129a133.44 133.44 0 0 1-4.334-.077 131.316 131.316 0 0 1-4.339-.159c-5.787-.25-11.579-.566-17.382-1.061a268.333 268.333 0 0 1-17.441-1.964M495.842 266.802a255.674 255.674 0 0 1 17.045-4.183c5.692-1.234 11.396-2.29 17.103-3.28 1.427-.259 2.854-.497 4.283-.713 1.428-.229 2.857-.45 4.288-.631 2.858-.426 5.73-.649 8.594-.981 2.867-.292 5.738-.518 8.607-.786l8.602-.849"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M504.354 264.562s10.966 6.793 4.223 34.526"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-2-right-a"
                x1={418.374}
                y1={108.409}
                x2={348.977}
                y2={292.313}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-2-right-b"
                x1={504.355}
                y1={278.141}
                x2={589.091}
                y2={278.141}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
