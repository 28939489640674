import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11325_7998)"
            d="M527.612 394.399l-.001-.141H355.586c-7.295 16.331-10.193 33.084-10.193 44.469 0 34.797 30.284 54.278 46.043 56.302 27.018 0 11.638-.109 35.71-.109 92.242-13.626 126.362-68.566 188.82-65.53-5.639-3.903-12.044-8.488-28.554-15.686-23.875-10.409-45.153-16.419-59.8-19.305z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11325_7998"
                x1="297.308"
                x2="644.255"
                y1="454.518"
                y2="417.893"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
