import { ILegs } from 'interfaces';

import stand1 from './female-34-25-shoes-7-stand-1';
import stand2 from './female-34-25-shoes-7-stand-2';
import walk1 from './female-34-25-shoes-7-walk-1';
import walk2 from './female-34-25-shoes-7-walk-2';
import sittingRight from './female-34-25-shoes-7-sitting-right';
import sittingLeft from './female-34-25-shoes-7-sitting-left';

const shoes7: ILegs = {
    name: 'Option #7',
    skinOpt2: 1,
    stand1: [stand1],
    stand2: [stand2],
    walk1: [walk1],
    walk2: [walk2],
    sitting1: [sittingRight, sittingLeft],
};

export default shoes7;
