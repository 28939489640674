import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M583.001 649.308a738.018 738.018 0 01-3.261-23.727c-.478-3.95-1.035-7.906-1.447-11.851l-1.294-11.84c-.888-7.896-1.65-15.783-2.763-23.693l-1.66-11.866-.838-5.933-.643-5.92c-1.677-15.784-.015-31.34 2.194-46.858 1.19-7.753 2.704-15.484 4.666-23.184a144.823 144.823 0 017.712-22.977l4.133-9.363a24.416 24.416 0 0142.625-3.723 24.418 24.418 0 013.944 16.556l-5.724 46.616-2.873 23.307-3.001 23.298c-2.023 15.531-3.955 31.068-6.052 46.594l-6.219 46.582a14.724 14.724 0 01-29.144.31l-.355-2.328z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M611.153 488.287c-12.808 2.74-25.401 3.361-37.931 4.68 0 0-107.087-.542-110.05-1.079a52.304 52.304 0 01-42.562-57.91 52.301 52.301 0 0155.439-45.733l7.252.497c3.028.206 6.035.521 9.031.893l4.49.578 4.469.681c2.986.423 5.944.989 8.911 1.505 2.973.492 5.917 1.13 8.87 1.717 11.796 2.446 23.529 5.215 35.169 8.46 5.817 1.64 11.564 3.635 17.269 5.845a303.274 303.274 0 0117.009 7.176 160.56 160.56 0 014.217 1.978c1.405.663 2.805 1.351 4.2 2.062 2.799 1.378 5.563 2.939 8.346 4.4a163.466 163.466 0 0116.402 10.291l.87.637a29.878 29.878 0 01-11.401 53.322z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M573.222 493.039s-107.087-.614-110.05-1.151a52.304 52.304 0 01-42.562-57.91 52.301 52.301 0 0155.439-45.733l7.252.497c3.028.206 6.035.521 9.031.893l4.49.578 4.469.681c2.986.423 5.944.989 8.911 1.505 2.973.492 5.917 1.13 8.87 1.717 11.796 2.446 23.529 5.215 35.169 8.46 5.817 1.64 11.564 3.635 17.269 5.845a302.53 302.53 0 0117.009 7.176 160.56 160.56 0 014.217 1.978c1.405.663 2.805 1.351 4.2 2.062 2.799 1.378 5.563 2.939 8.346 4.4a163.28 163.28 0 0116.401 10.292l.87.637c7.203 5.273 12.773 13.891 13.524 22.133M583.001 649.31a738.018 738.018 0 01-3.261-23.727c-.478-3.95-1.035-7.906-1.447-11.851l-1.294-11.84c-.888-7.896-1.65-15.782-2.763-23.693l-1.66-11.866-.838-5.933-.643-5.92c-1.677-15.784-.015-31.339 2.194-46.858a269.733 269.733 0 012.285-12.802M636.369 464.93l-5.724 46.616-2.873 23.307-3.001 23.298c-2.023 15.531-3.955 31.068-6.052 46.594l-6.219 46.582"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M583.56 650.734c.102 3.905-2.023 18.68-3.477 28.263a8.087 8.087 0 001.63 6.204 8.112 8.112 0 005.633 3.07l6.968.644a133.49 133.49 0 0127.492 5.632 72.336 72.336 0 0029.968 2.449c5.735-.667 10.343-2.816 10.627-5.89.28-3.035-7.493-6.067-12.806-7.731-18.199-5.7-36.894-29.947-36.894-29.947"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M583.56 650.734c.102 3.905-2.023 18.68-3.477 28.263a8.087 8.087 0 001.63 6.204 8.112 8.112 0 005.633 3.07l6.968.644a133.49 133.49 0 0127.492 5.632 72.336 72.336 0 0029.968 2.449c5.735-.667 10.343-2.816 10.627-5.89.28-3.035-7.493-6.067-12.806-7.731-18.199-5.7-36.894-29.947-36.894-29.947"
        ></path>
    </g>
);

export default SvgComponent;
