import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_8205)"
            d="M416.367 247.905c-1.499-2.248-2.928-4.916-4.737-8.294-.607-1.133-1.26-2.354-1.982-3.681l-1.385-2.547c-4.586-8.319-13.305-13.79-23.923-15.008a20.217 20.217 0 00-2.281-.133c-3.548 0-7.06.948-11.743 3.169-5.379 2.552-9.745 7.985-11.978 14.907-2.603 8.07-1.945 16.899 1.759 23.619 2.037 3.697 3.559 7.015 5.17 10.528.51 1.111 1.034 2.242 1.571 3.393l.468.999.948-.564c.309-.183 31.068-18.428 47.644-24.902l1.158-.452-.689-1.034z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_8205"
                x1="367.371"
                x2="411.178"
                y1="241.524"
                y2="262.344"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
