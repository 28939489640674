import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11342_6340)"
            d="M392.161 257.158c.021-.017.032-.042.055-.058l4.024-2.86a4.356 4.356 0 014.841-.029l2.352 1.466c.088-1.649.241-3.309.483-4.945 3.416-23.09 13.156-30.795 13.156-30.795-8.895-1.027-17.796-1.716-26.577-2.148-31.634-3.417-41.865 42.951-12.351 53.509 0 0 2.175.624 5.585 1.451l7.224-14.177a4.038 4.038 0 011.208-1.414z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11342_6340"
                x1="358.934"
                x2="404.331"
                y1="276.674"
                y2="215.951"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#63666A"></stop>
                <stop offset="1" stopColor="#8E8B8F"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
