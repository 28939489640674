import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6955)"
            d="M479.228 236.312c-.182-4.781-.96-19.424-3.837-27.14a101.329 101.329 0 0118.684 14.4 1.706 1.706 0 01.535 1.598c-.512 2.967-8.018 7.689-15.382 11.142z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6955)"
            d="M513.208 352.196a19.564 19.564 0 01-17.561-11.098 230.92 230.92 0 01-13.097-20.256c-3.844-6.604-7.55-13.482-11.334-21.03a183.686 183.686 0 01-9.467-21.822 752.17 752.17 0 01-2.385-6.719c-1.798-5.103-3.657-10.38-5.67-15.49a27.32 27.32 0 01.368-21.044 27.33 27.33 0 0115.141-14.62 27.358 27.358 0 0110.07-1.92 27.434 27.434 0 0125.755 17.274l.049.125c1.987 5.12 4.04 10.413 6.179 15.381.942 2.185 1.877 4.372 2.808 6.56a188.13 188.13 0 018.015 22.708c2.339 8.109 4.31 15.536 5.964 22.988.629 2.876.971 5.359 1.442 7.777a84.29 84.29 0 0110.167-.22c.778.036 1.548.061 2.317.086 1.585.051 3.223.103 4.858.254l3.552.262c.851.066 3.978.267 6.164.466 1.473.188 2.931.478 4.364.867 0 0-7.394 2.609-8.431 12.606-.644 6.214 1.725 14.073 9.767 16.441l3.2.942-3.189.974c-.47.143-1.784.579-3.244 1.064-2.119.703-4.52 1.5-5.22 1.695l-3.455.947c-1.561.458-3.16.826-4.706 1.181-.749.172-1.498.344-2.246.527a83.808 83.808 0 01-14.153 2.109c-1.421.085-2.842.157-4.263.23-3.217.163-6.545.332-9.79.658-.653.065-1.315.099-1.967.099l-.002-.002z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6955"
                x1="474.142"
                x2="550.479"
                y1="202.769"
                y2="367.588"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6955"
                x1="469.12"
                x2="545.457"
                y1="205.09"
                y2="369.91"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
