import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 144,
        height: 135,
        content: (
            <>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M19.664 17.861a27.005 27.005 0 0 1 15.295-4.74l27.712-.16V1.646h18.841v11.316l27.712.16c11.777 0 22.2 7.62 25.773 18.84M127.229 43.71v79.811c0 5.523-4.477 10-10 10H26.955c-5.523 0-10-4.477-10-10V31.963h118.042M127.229 50.803H1.418M142.765 50.803h-15.536"
                />
            </>
        ),
    },
    'Color #1': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M134.9 30.962a27.049 27.049 0 0 0-25.773-18.84H34.863c-11.777 0-22.2 7.62-25.773 18.84H134.9h-.001Z"
                />
                <path fill="#63666A" d="M81.414 11.961V.646h-18.84v11.316h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M16.855 30.963H127.13v91.558c0 5.519-4.481 10-10 10H26.855c-5.518 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.857 49.803H1.32M142.668 49.803h-15.537M16.855 110.542v-79.58M127.129 30.963v91.558c0 5.523-4.477 10-10 10H26.855c-5.523 0-10-4.477-10-10M9.09 30.962H134.9a27.049 27.049 0 0 0-25.773-18.84H34.864"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.174}
                        x2={21.99}
                        y1={21.541}
                        y2={21.541}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.349}
                        x2={38.718}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#C2C9CF" />
                        <stop offset={0.833} stopColor="#ECF3F9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M134.937 30.962a27.049 27.049 0 0 0-25.773-18.84H34.9c-11.777 0-22.2 7.62-25.773 18.84h125.811-.001Z"
                />
                <path fill="#63666A" d="M81.453 11.961V.646h-18.84v11.316h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M16.895 30.963h110.274v91.558c0 5.519-4.481 10-10 10H26.895c-5.52 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.895 49.803H1.357M142.705 49.803h-15.537M16.895 110.542v-79.58M127.169 30.963v91.558c0 5.523-4.477 10-10 10H26.895c-5.523 0-10-4.477-10-10M9.127 30.962h125.811a27.049 27.049 0 0 0-25.773-18.84H34.901"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.211}
                        x2={22.027}
                        y1={21.541}
                        y2={21.541}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#50D5F9" />
                        <stop offset={0.833} stopColor="#D2F0FA" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.389}
                        x2={38.757}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#50D5F9" />
                        <stop offset={0.833} stopColor="#D2F0FA" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M134.976 30.962a27.049 27.049 0 0 0-25.773-18.84H34.939c-11.777 0-22.2 7.62-25.773 18.84h125.811-.001Z"
                />
                <path fill="#63666A" d="M81.49 11.961V.646H62.65v11.316h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M16.934 30.963h110.274v91.558c0 5.519-4.481 10-10 10H26.934c-5.52 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.933 49.803H1.396M142.743 49.803h-15.536M16.934 110.542v-79.58M127.208 30.963v91.558c0 5.523-4.477 10-10 10H26.934c-5.523 0-10-4.477-10-10M9.164 30.962h125.811a27.049 27.049 0 0 0-25.773-18.84H34.938"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.25}
                        x2={22.065}
                        y1={21.541}
                        y2={21.541}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3ACEC7" />
                        <stop offset={0.833} stopColor="#BBE9E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.427}
                        x2={38.796}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#3ACEC7" />
                        <stop offset={0.833} stopColor="#BBE9E9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M135.015 30.962a27.049 27.049 0 0 0-25.773-18.84H34.978c-11.777 0-22.2 7.62-25.773 18.84h125.811-.001Z"
                />
                <path fill="#63666A" d="M81.53 11.961V.646H62.687v11.316H81.53Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M16.973 30.963h110.274v91.558c0 5.519-4.481 10-10 10H26.973c-5.52 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M16.973 49.803H1.435M142.783 49.803h-15.537M16.973 110.542v-79.58M127.245 30.963v91.558c0 5.523-4.477 10-10 10H26.971c-5.523 0-10-4.477-10-10M9.203 30.962h125.811a27.049 27.049 0 0 0-25.773-18.84H34.977"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.288}
                        x2={22.104}
                        y1={21.541}
                        y2={21.541}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.466}
                        x2={38.835}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#82E56A" />
                        <stop offset={0.833} stopColor="#C9F7BC" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #5': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M135.053 30.962a27.049 27.049 0 0 0-25.773-18.84H35.015c-11.777 0-22.2 7.62-25.773 18.84h125.811Z"
                />
                <path fill="#63666A" d="M81.568 11.961V.646h-18.84v11.316h18.84Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M17.012 30.963h110.274v91.558c0 5.519-4.481 10-10 10H27.012c-5.52 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.012 49.803H1.475M142.821 49.803h-15.536M17.012 110.542v-79.58M127.286 30.963v91.558c0 5.523-4.477 10-10 10H27.012c-5.523 0-10-4.477-10-10M9.242 30.962h125.811a27.049 27.049 0 0 0-25.773-18.84H35.015"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.327}
                        x2={22.142}
                        y1={21.541}
                        y2={21.541}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.505}
                        x2={38.874}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FF7E71" />
                        <stop offset={0.833} stopColor="#FFD9D9" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #6': {
        width: 144,
        height: 134,
        content: (
            <>
                <path
                    fill="url(#object-kitchen-pot-1-a)"
                    d="M135.093 30.964a27.049 27.049 0 0 0-25.773-18.841H35.056c-11.777 0-22.2 7.62-25.773 18.841h125.811-.001Z"
                />
                <path fill="#63666A" d="M81.61 11.961V.646H62.767v11.316H81.61Z" />
                <path
                    fill="url(#object-kitchen-pot-1-b)"
                    d="M17.05 30.963h110.275v91.558c0 5.519-4.481 10-10 10H27.051c-5.52 0-10-4.481-10-10V30.963Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M17.05 49.805H1.515M142.861 49.805h-15.537M17.05 110.542v-79.58M127.325 30.963v91.558c0 5.523-4.477 10-10 10H27.051c-5.523 0-10-4.477-10-10"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9.283 30.964h125.811a27.049 27.049 0 0 0-25.773-18.841H35.057"
                />
                <defs>
                    <linearGradient
                        id="object-kitchen-pot-1-a"
                        x1={119.367}
                        x2={22.183}
                        y1={21.543}
                        y2={21.543}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                    <linearGradient
                        id="object-kitchen-pot-1-b"
                        x1={135.545}
                        x2={38.913}
                        y1={146.543}
                        y2={43.09}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFD100" />
                        <stop offset={0.833} stopColor="#FFF1B5" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-kitchen-pot-1"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
