import { THashState } from 'components/hashReducer';

export const hashString = function(state: THashState) {
    return Object.keys(state).map((k) => {
        return k + '-' + state[+k as keyof THashState];
    })
        .filter(Boolean)
        .join('&');
};

export function decodeHash(str: string) {
    return str.split('&').reduce((acc, cur) => {
        const [key, value] = cur.split('-');
        // @ts-ignore
        acc[key] = value === 'true' ? true : value === 'false' ? false : value === 'null' ? null : isNaN(value)
            ? value : +value;
        return acc;
    }, {});
}
