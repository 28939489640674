import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-left-option-7">
        <path
            d="M500.617 186.165c-9.225-7.122-19.956-12.655-32.551-17.169l19.122 31.543s17.865-10.949 13.429-14.374Z"
            fill="url(#man-34-25-jacket-left-option-5-a)"
        />
        <path
            d="M588.498 296.517a292.144 292.144 0 0 0-6.354-5.905c-3.891-3.55-8.245-7.34-13.695-11.924a148.276 148.276 0 0 0-3.493-2.901 144.004 144.004 0 0 0-3.532-2.825c-1.546-1.241-3.158-2.423-4.718-3.566-.84-.616-1.68-1.232-2.523-1.867-1.494-1.093-3.002-2.166-4.509-3.239a923.45 923.45 0 0 1-2.809-2.006l-3.482-2.533c-1.134-4.027-2.553-8.283-4.452-13.359-2.2-5.805-4.69-11.617-7.609-17.758a112.475 112.475 0 0 0-9.479-16.688c-1.11-1.621-2.237-3.232-3.363-4.843-2.46-3.518-5.003-7.156-7.317-10.843l-.148-.233a31.89 31.89 0 0 0-26.982-14.753 31.888 31.888 0 0 0-17.221 5.051 31.794 31.794 0 0 0-14.006 20.092 31.792 31.792 0 0 0 4.303 24.11c2.344 3.663 4.549 7.515 6.681 11.24a565.208 565.208 0 0 0 2.944 5.106 112.287 112.287 0 0 0 11.042 15.69c4.316 5.27 8.51 10.002 12.823 14.47a146.843 146.843 0 0 0 14.615 13.395 25.26 25.26 0 0 0 4.524 2.786 23.655 23.655 0 0 0 3.46 2.666l7.606 4.804c.966.617 1.929 1.237 2.89 1.861 1.56 1.008 3.12 2.017 4.705 3.011.892.543 1.775 1.096 2.659 1.65 1.637 1.026 3.331 2.086 5.036 3.053 1.326.789 2.681 1.547 3.94 2.243 1.262.714 2.606 1.448 3.987 2.175 6.379 3.426 11.36 5.993 16.144 8.32a290.853 290.853 0 0 0 10.216 4.797c2.163.965 4.318 1.893 6.464 2.784 0 0-6.47-6.253-3.035-19.626a19.455 19.455 0 0 1 14.688-14.435Z"
            fill="url(#man-34-25-jacket-left-option-7-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-left-option-7-a"
                x1={538.141}
                y1={430.639}
                x2={472.213}
                y2={119.218}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
            <linearGradient
                id="man-34-25-jacket-left-option-7-b"
                x1={556.307}
                y1={426.794}
                x2={490.379}
                y2={115.372}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
