import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-both-arms-option-3-right">
        <path
            d="M472.283 286.855a746.002 746.002 0 0 1-4.825-.197c-3.131-.1-6.26-.163-9.39-.226-1.731-.035-3.461-.072-5.191-.111-4.93-.133-9.91-.163-14.581-.182a320.932 320.932 0 0 0-4.045-.02c-1.917 0-3.834.011-5.751.022-1.588.01-3.18.017-4.773.022-9.078.08-17.308.287-25.039.632a430.814 430.814 0 0 0 4.651-20.479 202.246 202.246 0 0 0 3.727-25.438c.213-2.56.409-5.122.604-7.685.446-5.835.907-11.868 1.57-17.775l.016-.15a32.033 32.033 0 0 0-31.854-35.424 31.927 31.927 0 0 0-31.778 28.582c-.635 5.903-1.497 11.891-2.331 17.681-.366 2.545-.732 5.09-1.081 7.634a202.289 202.289 0 0 0-1.891 25.636c-.175 9.119-.091 17.562.257 25.816a253.109 253.109 0 0 0 2.318 26.083c.118.773.286 1.531.476 2.281.012.183.019.365.036.548a23.373 23.373 0 0 0 23.382 21.378c.714 0 1.438-.033 2.153-.097l14.455-1.315 14.467-1.25c8.877-.79 18.336-1.856 28.924-3.26 1.566-.219 3.132-.434 4.699-.644 3.187-.432 6.482-.879 9.717-1.374 4.61-.671 9.52-1.396 14.415-2.225 1.686-.279 3.372-.554 5.059-.825 3.112-.504 6.224-1.008 9.347-1.553 1.588-.298 3.178-.586 4.767-.876-1.564-12.065-2.649-22.619-2.51-35.209Z"
            fill="url(#man-34-25-jacket-both-arms-option-3-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-both-arms-option-3-right-a"
                x1={318.57}
                y1={208.722}
                x2={403.582}
                y2={284.192}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
