import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6335)"
            d="M327.621 423.133c-4.987-28.437-7.234-61.733-6.498-96.289a19.529 19.529 0 01.254-3.176 21.425 21.425 0 01.704-5.498c5.22-19.784 17.587-49.316 27.779-65.855 1.237-2.02 2.663-4.051 3.914-6.066 2.848-4.581 5.792-9.317 8.509-14.068a27.498 27.498 0 0137.526-10.215 27.496 27.496 0 0110.215 37.525c-2.708 4.734-4.866 9.19-7.032 13.264-6.991 13.152-13.11 24.267-31.145 46.717-4.322 5.344-7.975 9.607-11.471 13.381 1.032 27.358 1.668 56.737-2.536 88.742l-.108.824-29.958 1.587-.153-.873z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6335"
                x1="419.366"
                x2="363.972"
                y1="192.521"
                y2="313.578"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
