import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g data-option={7}>
        <path
            d="M492.281 223.094c6.584 3.721 13.37 7.084 20.07 10.601a167.593 167.593 0 0 1 19.358 11.868c6.238 4.338 12.374 8.858 18.368 13.631a209.759 209.759 0 0 1 17.415 15.326 19.219 19.219 0 0 1 3.161 22.994 19.223 19.223 0 0 1-21.282 9.265 210.057 210.057 0 0 1-22.148-6.9c-7.195-2.636-14.248-5.524-21.198-8.595a167.626 167.626 0 0 1-20.206-10.358c-6.49-3.891-12.892-7.937-19.496-11.624a26.503 26.503 0 0 1-10.218-36.057 26.497 26.497 0 0 1 36.056-10.218l.12.067Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M539.532 287.918c.458-5.217.582-10.462.85-15.695.278-5.242.961-10.456 2.042-15.593.558-2.581 1.013-5.17 1.681-7.741l.937-3.863c.317-1.287.701-2.568 1.049-3.853a124.166 124.166 0 0 1 5.215-15.325 12.639 12.639 0 0 1 23.915 2.023 124.21 124.21 0 0 1 2.566 15.983c.127 1.325.291 2.653.387 3.975l.275 3.965c.227 2.646.24 5.275.358 7.913.202 5.246-.001 10.5-.608 15.715-.614 5.203-1.373 10.395-1.798 15.614A18.531 18.531 0 0 1 574.44 298a18.506 18.506 0 0 1-24.943 8.051 18.515 18.515 0 0 1-9.166-10.832 18.504 18.504 0 0 1-.807-7.189l.008-.112Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M554.161 307.309a19.414 19.414 0 0 1-4.79-.53 210.057 210.057 0 0 1-22.148-6.9c-7.195-2.636-14.248-5.524-21.198-8.595a167.714 167.714 0 0 1-20.207-10.358c-6.489-3.891-12.891-7.937-19.495-11.624a26.515 26.515 0 0 1-12.577-15.912 26.492 26.492 0 0 1 2.359-20.145 26.486 26.486 0 0 1 15.912-12.577 26.487 26.487 0 0 1 20.144 2.359l.119.067c6.585 3.721 13.371 7.084 20.071 10.601a167.678 167.678 0 0 1 19.358 11.868 394.791 394.791 0 0 1 8.754 6.267"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m539.523 288.026.009-.112c.457-5.216.581-10.461.85-15.694a101.96 101.96 0 0 1 2.042-15.594c.558-2.58 1.013-5.17 1.68-7.741l.938-3.862c.316-1.287.701-2.569 1.049-3.853a96.408 96.408 0 0 1 4.487-13.568M575.22 227.867a124.256 124.256 0 0 1 2.567 15.984c.127 1.324.291 2.652.387 3.974l.274 3.965c.226 2.647.241 5.276.359 7.913.202 5.246-.001 10.5-.608 15.715-.614 5.204-1.373 10.395-1.799 15.615a18.499 18.499 0 0 1-19.941 16.935"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M591.78 230.374a10.881 10.881 0 0 0-4.472-8.275l-.472-.014a13.506 13.506 0 0 0-.144-1.248c-.543-3.32-2.609-6.526-5.743-7.75l-.454-.139a8.214 8.214 0 0 0-2.32-3c-2.228-1.769-5.254-2.153-8.098-2.07a30.634 30.634 0 0 0-13.409 3.545l-2.949 1.461a5.478 5.478 0 0 0-3.028 5.1c.113 2.103.714 4.15 1.755 5.98a36.65 36.65 0 0 1 2.076 4.556 2.248 2.248 0 0 0 1.538 1.463l.029.009a2.212 2.212 0 0 0 1.901-.35l.342.714a11.4 11.4 0 0 0 1.781 1.78 23.336 23.336 0 0 0 17.029 3.58c-.133.484-.107.997.074 1.464.18.468.505.866.928 1.135l.034.021a2.326 2.326 0 0 0 3.123-.602 36.315 36.315 0 0 0 2.111-3.253l.225-.306a3.11 3.11 0 0 1 .576 2.966 16.846 16.846 0 0 1-2.693 5.412 2.043 2.043 0 0 0-.007 2.464l.025.031a2.024 2.024 0 0 0 1.463.797 2.03 2.03 0 0 0 1.567-.563 25.57 25.57 0 0 0 4.818-5.612 16.73 16.73 0 0 0 2.394-9.296Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M553.719 212.883a5.475 5.475 0 0 0-3.028 5.1c.113 2.102.713 4.15 1.754 5.98a36.59 36.59 0 0 1 2.077 4.556 2.245 2.245 0 0 0 1.538 1.463l.029.008a2.184 2.184 0 0 0 2.602-1.242 14.239 14.239 0 0 0 .177-10.976M556.668 211.423a30.61 30.61 0 0 1 13.409-3.545c2.844-.083 5.87.301 8.099 2.07a9.918 9.918 0 0 1 3.271 8.5 19.52 19.52 0 0 1-2.223 7.222 2.44 2.44 0 0 1-2.681 1.277l-.048-.01a2.45 2.45 0 0 1-1.918-2.788 16.35 16.35 0 0 0 .217-3.085 6.736 6.736 0 0 0-2-4.829"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M577.799 229.262a20.526 20.526 0 0 1-.657 6.453 2.347 2.347 0 0 0 1.002 2.599l.035.021a2.323 2.323 0 0 0 3.123-.602 31.504 31.504 0 0 0 3.926-7.034 18.98 18.98 0 0 0 1.464-9.863c-.543-3.32-2.609-6.526-5.743-7.75"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M584.213 237.143a16.839 16.839 0 0 1-2.692 5.413 2.043 2.043 0 0 0-.006 2.464l.023.031a2.029 2.029 0 0 0 2.307.683c.27-.097.517-.25.723-.449a25.597 25.597 0 0 0 4.819-5.612 16.741 16.741 0 0 0 2.393-9.297 10.877 10.877 0 0 0-4.471-8.274M560.113 232.141a23.344 23.344 0 0 0 17.03 3.58"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M494.069 224.002c-8.491-8.307-18.696-15.185-30.909-21.252l15.605 34.205s19.387-8.959 15.304-12.953Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M463.16 202.75a132.19 132.19 0 0 1 30.909 21.252"
            stroke="#111011"
            strokeWidth={2.038}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
