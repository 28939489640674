import React, { useState } from 'react';

import { ReactComponent as ChevronIcon } from 'icons/chevron.svg';

type TOption = {
    text: string;
    onClick: () => void;
    active?: boolean;
    disabled?: boolean;
    onHover?: () => void;
};

type TProps = {
    title: string;
    opened?: boolean;
    disabled?: boolean;
    children: React.ReactNode;
    showToggle?: boolean;
    showExpand?: boolean;
    toggle?: boolean;
    switchToggle?: (i: string) => void;
    onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
    onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
};

export function OptionsWrapper({
    title,
    opened = false,
    disabled = false,
    children,
    showToggle = false,
    showExpand = true,
    toggle = false,
    switchToggle,
    onMouseEnter,
    onMouseLeave,
}: TProps) {
    const [active, setActive] = useState(opened);

    function handleToggle() {
        if (switchToggle) switchToggle(title);
    }

    return <div className={`options-wrapper ${active ? 'is-active' : ''}`} data-disabled={disabled.toString()}>
        <div className="options-header" onClick={() => setActive(p => !p)}>
            <div className="options-title">
                <h3>{title}</h3>
            </div>
            <div className="spacer"/>
            {showToggle &&
                <label className="switch">
                    <input type="checkbox" checked={toggle} onChange={handleToggle}/>
                    <span className="slider"></span>
                </label>}
            {showExpand &&
                <div className="options-controls">
                    <ChevronIcon data-active={active.toString()}/>
                </div>}

        </div>
        <div
            className="options"
            {...onMouseEnter ? { onMouseEnter: onMouseEnter } : {}}
            {...onMouseLeave ? { onMouseLeave: onMouseLeave } : {}}
        >{children}</div>
    </div>;
}

export function Option({ text, onClick, active = false, onHover, disabled = false }: TOption) {
    return <button
        className="option"
        data-active={active.toString()}
        data-disabled={disabled.toString()}
        {...disabled ? {} : { onClick }}
        {...onHover && !disabled ? { onMouseEnter: onHover } : {}}
    >
        <div className="option-checkmark"/>
        {text}
    </button>;
}

export function Tag({ text, capitalize = true }: { text: string, capitalize?: boolean }) {
    return <div className={`option ${capitalize ? 'capitalize' : ''}`}>
        {text}
    </div>;
}

function Settings({ children }: { children: React.ReactNode }) {
    return <div className="settings">
        {children}
    </div>;
}

export default Settings;
