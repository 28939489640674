import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6384)"
            d="M552.222 313.777l-9.589.166-9.588.31a151.789 151.789 0 01-19.177-.72c-3.196-.339-6.392-.575-9.588-1.023l-4.794-.608c-1.599-.207-3.197-.482-4.795-.72 4.151-11.05 3.772-27.126 0-31.81 1.598-.238 3.196-.513 4.795-.72l4.794-.609c3.196-.448 6.392-.684 9.588-1.023a151.789 151.789 0 0119.177-.72l9.588.31 9.589.167a18.493 18.493 0 0112.987 5.646 18.495 18.495 0 015.191 13.175 18.533 18.533 0 01-18.178 18.179z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6384)"
            d="M392.388 257.468c.021-.018.032-.042.055-.058l4.024-2.861a4.357 4.357 0 014.841-.028l1.715.829c.068-1.656.229-3.308.482-4.946 3.406-20.413 7.418-23.521 11.414-29.973-7.865-.84-15.724-1.422-23.488-1.804-31.634-3.417-42.574 42.423-13.06 52.981 0 0 2.175.624 5.585 1.451l7.224-14.178a4.044 4.044 0 011.208-1.413z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M402.612 256.423c.082-1.851.263-3.696.543-5.527 2.638-17.834 8.962-26.626 11.939-29.891-7.781-.851-15.985-1.492-24.42-1.907a30.054 30.054 0 00-3.217-.176M378.707 270.672c.034.009 2.579.734 6.461 1.652M400.602 219.961c-10.735 8.458-16.807 30.614-15.058 52.128M394.963 247.648a162.337 162.337 0 00-1.217 9.557"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6384"
                x1="494.691"
                x2="570.403"
                y1="295.277"
                y2="295.277"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6384"
                x1="359.272"
                x2="403.583"
                y1="276.632"
                y2="217.363"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
