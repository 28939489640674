import React from 'react';

import Navbar from 'components/Header/Navbar';
import Tabs from 'components/Header/Tabs';
import Logo from 'components/Header/Logo';

function Header() {
    return <header className="header-wrap">
        <div className="header">
            <Logo />
            <Tabs />
            <Navbar />
        </div>
    </header>;
}

export default Header;
