import React from 'react';

export default function SvgComponent() {
    return (
        <g id="ffemale-34-25-shoes-2-walk-1">
            <path
                d="M612.148 839.564a4.752 4.752 0 0 0-3.871-3.291c-2.843-.394-8.254-1.115-12.556-1.525a63.9 63.9 0 0 1-17.362-4.725c-11.889 8.839-34.949 11.153-52.279 11.042a996.988 996.988 0 0 1 1.626 10.297 8.998 8.998 0 0 0 8.964 7.68 9.335 9.335 0 0 0 1.929-.205l17.894-3.818a36.915 36.915 0 0 1 11.311-.64 63.743 63.743 0 0 0 18.94-1.176c3.608-.745 15.678-5.208 22.553-7.802a4.744 4.744 0 0 0 2.851-5.837Z"
                fill="#FFD109"
            />
            <path
                d="M531.525 857.467a8.96 8.96 0 0 0 5.144 1.573 9.267 9.267 0 0 0 1.929-.205l17.894-3.818a36.921 36.921 0 0 1 11.312-.64c6.336.556 12.72.159 18.94-1.175 3.607-.745 15.678-5.209 22.552-7.803a4.744 4.744 0 0 0 2.852-5.837"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m362.12 845.835-3.384-1.189a14.08 14.08 0 0 1-8.56-8.406s-2.816-7.752-3.94-10.597c-13.84-2.522-18.177 2.682-28.099-5.667-3.947-3.322-12.199-18.054-12.199-18.054l-5.326 8.6a9.153 9.153 0 0 0 .014 9.684l17.576 27.872a21.246 21.246 0 0 0 7.738 7.319 21.246 21.246 0 0 0 10.318 2.641h23.781a6.276 6.276 0 0 0 6.189-5.223 6.275 6.275 0 0 0-4.108-6.98Z"
                fill="url(#female-34-25-shoes-2-walk-1-a)"
            />
            <path
                d="m300.627 820.211 17.576 27.872a21.235 21.235 0 0 0 18.056 9.96h23.781a6.301 6.301 0 0 0 6.051-4.603"
                stroke="#111011"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-2-walk-1-a"
                    x1={299.232}
                    y1={829.98}
                    x2={366.317}
                    y2={829.98}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </g>
    );
}
