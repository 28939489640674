import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-right-option-2">
        <path
            d="M369.438 286.481c-3.209-6.59-6.24-13.434-9.267-20.924a158.497 158.497 0 0 1-7.214-21.588 898.07 898.07 0 0 1-1.664-6.606c-1.25-5.019-2.544-10.21-4.018-15.246a32.039 32.039 0 0 1 21.726-39.7 32.069 32.069 0 0 1 9.001-1.296 32.164 32.164 0 0 1 30.698 23.02l.049.168c1.445 5.043 3.125 10.118 4.75 15.026.714 2.161 1.43 4.321 2.128 6.486a158.49 158.49 0 0 1 5.44 22.106 346.83 346.83 0 0 1 2.304 14.289c-11.29 1.468-42.808 11.878-53.933 24.265Z"
            fill="url(#man-34-25-shirt-1-arm-right-option-2a)"
        />
        <path
            d="m408.7 204.145.049.167c1.445 5.044 3.125 10.119 4.75 15.026.714 2.161 1.43 4.321 2.128 6.486a158.47 158.47 0 0 1 5.44 22.107 348.54 348.54 0 0 1 2.304 14.289c-11.29 1.467-42.808 11.877-53.933 24.264-3.209-6.59-6.24-13.434-9.267-20.924a158.497 158.497 0 0 1-7.214-21.588c-.565-2.197-1.114-4.402-1.664-6.606-1.25-5.019-2.544-10.21-4.018-15.246"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-right-option-2a"
                x1={398.622}
                y1={267.356}
                x2={361.321}
                y2={181.476}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
