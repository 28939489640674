import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#2B2729"
                d="M658.575 693.518c-8.147 0-18.557-.108-21.889-.62a70.672 70.672 0 01-20.149-6.101 41.124 41.124 0 00-12.078-3.678l-20.113-2.955a9.989 9.989 0 01-6.742-4.159 9.995 9.995 0 01-1.598-7.759c3.473-16.752 5.434-27.751 5.524-30.973l.378-12.972 36.294 7.074-2.425 10.44c2.322 3.227 19.908 27.114 37.34 35.552 4.3 2.081 9.666 4.933 12.481 6.448a5.158 5.158 0 012.552 5.786 5.154 5.154 0 01-4.973 3.906c-1.312.005-2.886.011-4.602.011z"
            ></path>
            <path
                fill="#888B8D"
                d="M665.626 683.773c-2.8-1.517-8.142-4.376-12.464-6.482-7.431-3.621-14.882-10.057-21.163-16.497a161.245 161.245 0 01-6.036-6.555c-3.416-.419-14.414 4.399-20.17 4.191-11.535-.416-19.614-5.62-26.604-5.828a961.17 961.17 0 01-3.113 15.363 9.997 9.997 0 008.308 11.94l20.106 3.009c4.189.608 8.26 1.86 12.068 3.71a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.128.139 5.122.506 18.089.556 25.362.541a5.154 5.154 0 002.446-9.685z"
            ></path>
            <path
                fill="#888B8D"
                d="M577.651 675.723a9.904 9.904 0 006.733 4.182l20.106 3.008a41.1 41.1 0 0112.067 3.711 70.794 70.794 0 0020.133 6.154c.315.05.694.096 1.128.139 5.122.505 18.089.555 25.362.541"
            ></path>
            <path
                stroke="#111011"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M577.543 675.629a9.908 9.908 0 006.733 4.182l20.105 3.008c4.19.608 8.26 1.86 12.068 3.711a70.746 70.746 0 0020.132 6.154c.315.05.694.096 1.129.139 5.121.506 18.088.556 25.361.541"
            ></path>
        </g>
    );
}
