import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-right-option-6">
        <path
            fill="url(#man-34-25-tshirt-3-arm-right-option-6-a)"
            d="M415.157 283.755c-6.786-4.233-13.565-8.78-20.728-13.905a189.002 189.002 0 0 1-19.358-15.57 835.573 835.573 0 0 1-5.462-5.055c-4.14-3.85-8.422-7.831-12.766-11.581a31.804 31.804 0 0 1-11.007-21.879 31.796 31.796 0 0 1 7.689-23.254 31.988 31.988 0 0 1 24.24-11.093 31.996 31.996 0 0 1 20.893 7.774l.124.109c4.325 3.769 8.873 7.447 13.271 11.004 1.928 1.559 3.852 3.122 5.772 4.689a188.993 188.993 0 0 1 18.153 16.969c6.083 6.366 11.543 12.435 16.686 18.548-9.093 4.316-32.515 25.569-37.507 43.244Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-right-option-6-a"
                x1={352.106}
                x2={430.178}
                y1={190.807}
                y2={259.121}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
