import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Color #1': {
        width: 111,
        height: 64,
        content: (
            <>
                <g clipPath="url(#object-accessories-helmet-a)">
                    <path
                        fill="url(#object-accessories-helmet-b)"
                        d="M101.616 24.56c0-.1-.1-.3-.1-.4-1.4-4-3.7-7.8-7.3-9.9-2.8-1.6-4.8-2-4.8-2-1-1.3-2.1-2.5-3.4-3.6-5.1-4.5-12.1-6.8-18.8-6.3-.8.1-1.5.2-2.2.3l-3.8.7c-8.1-3-17.3-3.1-25.5-.3-1.8.6-3.6 1.5-5.2 2.5l-3.3 2c-.9.1-1.7.2-2.6.4-9.1 1.4-17.6 6.6-22.2 14.4l-1.4 2.8c4.1 2.8 4.7 6.8 4.2 10.6s-1.6 5.3-2.7 9c-1.6 5.5-2 10.3-.8 14.6.9 2.9 4.5 3.8 6.7 1.6 11-10.9 27.3-2.3 30.8-1.2 13.9 4.6 30.7-13 45.5-13 10.3 0 12.8 3.2 16.2 8.2.6.9 1.9 1.1 2.8.4 1-.9 2.2-2.2 3.1-4.1 2.2-4.9 3.2-14.3 1.7-18.5-1.1-3.2-3.4-6-6.3-7.8l-.6-.4Z"
                    />
                    <path
                        fill="#009CDE"
                        d="M86.017 43.164c-27.8-2.2-57.2-8.6-84.4-17.6 3.5 2.7 4.1 6.6 3.6 10.1-.5 3.8-1.6 5.3-2.7 9-1.6 5.5-2 10.3-.8 14.6.9 2.9 4.5 3.8 6.7 1.6 11-10.9 27.3-2.3 30.8-1.2 13.9 4.6 30.7-13 45.5-13 10.3 0 12.8 3.2 16.2 8.2.6.9 1.9 1.1 2.8.4 1-.9 2.2-2.2 3.1-4.1 1.1-2.4 1.8-5.8 2.2-9.3-7.1 2.2-16.2 1.8-23 1.3Z"
                    />
                    <path
                        fill="url(#object-accessories-helmet-c)"
                        d="M78.716 9.06c-3.1-2.5-5.5-4-11.3-6.8-.4 0-.8.1-1.2.1-1.7.2-3.3.7-4.6 1.1.2.1.4.2.6.2 8.9 3.5 16.5 10.6 20.6 19.2.4.8 1.2 1.4 2.1 1.4 1.6 0 2.8-1.5 2.3-3-1.3-4.9-3.8-8.4-8.5-12.2Zm-43-6c-1 .3-2 .7-3 1.1-1.5.6-3.4 2-4.9 3.3 7.9-.7 16 1.2 23.2 4.6 7.5 3.5 14.2 8.7 20.2 14.5.9.9 2.3 1 3.3.3 1.2-.9 1.5-2.6.5-3.7-5.7-7-19.6-18.1-39.3-20.1Zm58.6 11.1c-2-1.2-3.7-1.9-5-2.2 3.3 4.2 5.3 9.3 5.6 14.6 0 .6.3 1.1.9 1.4 1 .6 2.3-.1 2.5-1.2.1-1 .3-5.6-2.9-12-.4-.1-.7-.4-1.1-.6Zm11.9 13.9c-1.2-1.4-2.6-2.7-4.3-3.5 1.1 3.5 1.3 7.3.4 10.9-.1.6 0 1.3.4 1.7.8.9 2.2.9 2.9 0 .2-.2 1.8-2.9.6-9.1Zm-41 4c-5.5-3.5-10.7-7.3-16.3-10.7l-3.5-1.9c-1.5-.5-3.3-.2-4.6.7-2.3 1.5-4.5 3.2-6.7 4.8-.8.5-1.2 1.5-1 2.4.2.9.8 1.7 1.7 1.9 9.5 2.9 19.1 5.2 28.9 6.7.9.1 1.7-.3 2.2-1.1.6-.9.3-2.2-.7-2.8Zm-29-15.5c-.1-1.2-1-2.1-2.1-2.2-7.8-.9-18.8-.4-25.8 7.9l12.1 3.3c1.2.3 2.5.2 3.7-.3 4-1.9 7.8-4 11.2-6.7.6-.5.9-1.3.9-2Z"
                    />
                    <path
                        stroke="#111011"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M108.116 42.163c-7.1 2-14.7 1.6-22.1 1-28.7-2.3-57.2-8.4-84.5-17.7 3.2 2.1 4.1 6.5 3.6 10.3-.5 3.8-1.6 5.3-2.7 9-1.6 5.5-2 10.3-.8 14.6.9 2.9 4.5 3.8 6.7 1.6 11-10.9 27.3-2.3 30.8-1.2 13.9 4.6 30.7-13 45.5-13 10.3 0 12.8 3.2 16.2 8.2.6.9 1.9 1.1 2.8.4 1-.9 2.2-2.2 3.1-4.1 2.2-4.9 3.2-14.3 1.7-18.5-1.1-3.2-3.4-6-6.3-7.8"
                    />
                    <path
                        stroke="#111011"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M48.514 6.064c10.1 3 19.6 8.9 26.3 17 1 1.2.7 2.9-.5 3.7-1 .7-2.4.6-3.3-.3-6-5.8-12.6-11-20.2-14.5-8.2-3.9-17.5-5.7-26.4-4.1-8.9 1.6-17.4 6.7-22 14.5"
                    />
                    <path
                        stroke="#111011"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M78.516 9.063c4.2 3.1 7.1 7.4 8.6 12.2.5 1.5-.7 3.1-2.3 3-.9 0-1.7-.6-2.1-1.4-4.2-8.6-11.7-15.6-20.6-19.1-9.4-3.7-20.3-3.6-29.6.4"
                    />
                    <path
                        stroke="#111011"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M66.016 2.361c7.1-.8 14.6 1.5 20 6.2 5.2 4.5 8.4 11.2 8.7 18 0 .6.3 1.1.9 1.4 1 .6 2.3-.1 2.5-1.2.2-1.1.2-2.3 0-3.4"
                    />
                    <path
                        stroke="#111011"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeMiterlimit={10}
                        strokeWidth={2}
                        d="M94.117 14.166c3.4 2.5 6 6 7.3 9.9 1.2 3.6 1.4 7.6.6 11.4-.1.6 0 1.3.4 1.7.8.9 2.2.9 2.9 0 .2-.3.4-.5.5-.8M48.714 21.462c5.6 3.3 10.8 7.2 16.3 10.6 1 .6 1.3 1.8.7 2.8-.4.8-1.3 1.2-2.2 1.1-9.8-1.6-19.5-3.8-28.9-6.7-.9-.3-1.5-1-1.7-1.9-.2-.9.2-1.8 1-2.4 2.2-1.6 4.4-3.2 6.7-4.8 1.3-.9 3.1-1.2 4.6-.7M10.817 18.262c7-3.6 15.2-4.9 23-4 1.1.1 2 1 2.1 2.2.1.8-.3 1.5-.9 2-3.5 2.7-7.2 4.9-11.2 6.7-1.2.5-2.5.7-3.7.3l-12-3.2"
                    />
                </g>
                <defs>
                    <linearGradient
                        id="object-accessories-helmet-b"
                        x1={1.016}
                        x2={109.281}
                        y1={31.609}
                        y2={31.609}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.029} stopColor="#009CDE" />
                        <stop offset={0.351} stopColor="#21AFE4" />
                        <stop offset={0.627} stopColor="#40C1E9" />
                    </linearGradient>
                    <linearGradient
                        id="object-accessories-helmet-c"
                        x1={8.298}
                        x2={106.658}
                        y1={20.101}
                        y2={20.101}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.029} stopColor="#001489" />
                        <stop offset={1} stopColor="#0047A7" />
                    </linearGradient>
                    <clipPath id="object-accessories-helmet-a">
                        <path fill="#fff" d="M.016.064h110.3v63.1H.016z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 121,
        height: 71,
        content: (
            <>
                <path
                    fill="url(#object-accessories-helmet-a)"
                    d="M14.8 69.355c1.996-.185 4.19-8.62 11.916-9.337 5.786-.537 15.59 5.374 24.884 3.685 5.154-.937 14.038-3.68 19.78-6.537.11-.056 20.091-11.429 37.664-5.589 1.697.564 6.216-3.426 8.665-4.042 1.086-.26 1.472-2.025 1.421-2.569-.886-.18-3.108-2.172-3.108-2.172l-19.99-18.73s-92.026-8.05-94.55 3.131c-2.983 13.203 9.176 42.544 13.317 42.16Z"
                />
                <path
                    fill="url(#object-accessories-helmet-b)"
                    d="M119.133 44.967c-16.51-8.89-51 3.73-67.91 2.76-31.84-1.84-48.29-14.78-49.02-19.16-.11-.67-.21-2.57.01-3.07 1.1-2.42 2.87-5.25 4.48-6.4.32-.23.91-.66 1.75-1.22 2.08-1.39 5.65-3.65 10.36-6.04 6.27-3.18 13.172-6.158 22.583-8.078 3.505-.494 7.915.756 12.183 3.96 9.25-1.48 8.044-5.062 16.134-4.392.05 0 .97.43 1.02.43.72.08.56-1.5 1.28-1.39.23.03.48.07.71.11 20.41 3.41 32.81 16.22 39.6 26.9.29.44.57.88.82 1.32 4.77 7.85 5.99 12.7 6 14.27Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M2.625 24.299c-6.115 12.788 8.028 45.445 12.17 45.06 1.997-.184 4.19-8.62 11.917-9.336 5.786-.537 14.699 4.4 24.095 3.43 16.9-1.746 38.86-19.663 58.234-11.871 1.659.667 10.136-6.067 10.086-6.612"
                />
                <path
                    fill="#FFB33B"
                    d="M92.59 28.912c-9.86-4.9-18.99-10.98-28.88-15.84-5.14-2.46-10.144-5.357-14.718-8.107C60.073.165 69.2 1.532 73.68 3.972c7.588 4.133 14.98 8.92 20.56 15.58 1.1 1.36 2.15 2.76 3.11 4.23 2.03 3.13-1.5 6.89-4.76 5.13h.001ZM65.69 34.176c-9.945 2.125-19.518.864-29.126-2.32-5.95-1.971-14.783-6.561-16.94-9.456-1.937-2.6 1.985-5.432 4.611-5.18 3.053.295 11.93 2.594 14.916 3.502 8.653 2.63 17.477 4.282 26.54 6.453 3.73.82 3.72 6.19 0 7Z"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M64.402 1.752c12.38 0 24.257 11.141 29.837 17.801 1.1 1.36 2.15 2.76 3.11 4.23 2.03 3.13-1.5 6.89-4.76 5.13M36.564 31.856c-5.95-1.971-14.783-6.561-16.94-9.456-1.937-2.6 1.985-5.432 4.611-5.18 3.053.295 11.93 2.594 14.916 3.502 8.653 2.63 17.477 4.282 26.54 6.453 3.73.82 3.72 6.19 0 7"
                />
                <path
                    stroke="#111011"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M85.962 43.454c-12.012 2.199-24.395 5.303-34.743 4.27-18.377-1.832-31.665-6.5-39.736-10.954-5.864-3.237-9.448-5.11-9.448-10.054 0-6.962 14.391-16.534 35.018-21.763 13.278-3.366 27.497 9.17 44.593 18.033M64.402 1.754c3.77-.045 8.128.69 8.304.718 20.411 3.415 32.815 16.225 39.607 26.9.281.444.563.887.82 1.32 4.769 7.855 5.939 13.524 5.994 14.275"
                />
                <defs>
                    <linearGradient
                        id="object-accessories-helmet-a"
                        x1={50.769}
                        x2={108.045}
                        y1={39.878}
                        y2={29.985}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.068} stopColor="#FFF4C3" />
                        <stop offset={0.277} stopColor="#FFEA8F" />
                        <stop offset={0.612} stopColor="#FFDC42" />
                        <stop offset={0.851} stopColor="#FFD412" />
                        <stop offset={0.969} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-accessories-helmet-b"
                        x1={118.635}
                        x2={2.073}
                        y1={23.758}
                        y2={26.5}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.068} stopColor="#FFF4C3" />
                        <stop offset={0.277} stopColor="#FFEA8F" />
                        <stop offset={0.612} stopColor="#FFDC42" />
                        <stop offset={0.851} stopColor="#FFD412" />
                        <stop offset={0.969} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-accessories-helmet"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
