import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-2-left">
        <path
            d="M518.89 198.591c-9.282-6.355-17.305-16.13-28.914-18.162-20.362-4.496-40.617 14.04-37.902 34.715.941 15.834 15.348 24.112 25.884 33.637 6.188 5.434 12.453 10.783 19.102 15.655l.002-.002c1.497 1.089 56.761-35.313 56.761-35.313-10.699-12-28.546-25.804-34.933-30.53Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-2-left-a)"
        />
        <path
            d="m503.875 187.449.111.092c6.902 5.657 14.08 10.975 21.139 16.44a219.524 219.524 0 0 1 20.164 17.636 515.542 515.542 0 0 1 6.936 6.862l.982 1.186s-54.215 35.535-55.713 34.445"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m465.932 167.066 2.653.952c13.051 4.677 23.788 10.342 32.826 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.648-16.938 8.176-19.334 9.644l-.857.525-21.103-34.814Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-2-left-b)"
        />
        <path
            d="M463.09 165.234a129.684 129.684 0 0 1 32.551 17.17"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-2-left-a"
                x1={561.897}
                y1={137.676}
                x2={492.5}
                y2={228.761}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-2-left-b"
                x1={534.509}
                y1={116.808}
                x2={465.112}
                y2={207.892}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
