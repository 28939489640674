import { ILegs } from 'interfaces';

import stand12 from './man-34-25-shorts-1-2-stand-1';
import walk1 from './man-34-25-shorts-1-2-walk-1';
import walk2 from './man-34-25-shorts-1-2-walk-2';
import cycling1Right from './man-34-25-shorts-2-cycling-1-right';
import cycling1Left from './man-34-25-shorts-2-cycling-1-left';
import cycling2Right from './man-34-25-shorts-2-cycling-2-right';
import cycling2Left from './man-34-25-shorts-2-cycling-2-left';
import sitting1Right from './man-34-25-shorts-2-sitting-1-right';
import sitting1Left from './man-34-25-shorts-2-sitting-1-left';
import sitting2Right from './man-34-25-shorts-2-sitting-2-right';
import sitting2Left from './man-34-25-shorts-2-sitting-2-left';

const shorts1: ILegs = {
    name: 'Shorts #1',
    stand1: [stand12],
    stand2: [stand12],
    walk1: [walk1],
    walk2: [walk2],
};
const shorts2: ILegs = {
    name: 'Shorts #2',
    stand1: [() => stand12({ option: 1 })],
    stand2: [() => stand12({ option: 1 })],
    walk1: [() => walk1({ option: 1 })],
    walk2: [() => walk2({ option: 1 })],
    cycling1: [cycling1Right, cycling1Left],
    cycling2: [cycling2Right, cycling2Left],
    sitting1: [sitting1Right, sitting1Left],
    sitting2: [sitting2Right, sitting2Left],
};

export default { shorts2, shorts1 };
