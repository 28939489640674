import React, { createContext, useContext, useEffect, useState } from 'react';
import useLocalStorage from './useLocalStorage';

const users = process.env.REACT_APP_USERS || '{}';

export type TUserCredentials = {
    username: string;
    password: string;
};

const authContext = createContext({
    user: null,
    signin: () => {},
    signout: () => {},
    error: null,
    setError: () => {},
} as TParams);

export function ProvideAuth({ children }: { children: React.ReactNode; }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => useContext(authContext);

type TParams = {
    user: string | null;
    signin: ({ username, password }: TUserCredentials) => void;
    signout: () => void;
    error: string | null;
    setError: React.Dispatch<React.SetStateAction<string | null>>;
};

function getUrlParams() {
    return typeof window !== 'undefined' && new URLSearchParams(window.location.search) || {
        has: () => false,
        delete() {}
    };
}

function useProvideAuth(): TParams {
    const [user, setUser] = useLocalStorage('_abbott_auth', '');
    const [error, setError] = useState<string | null>(null);

    const signin = ({ username, password }: TUserCredentials) => {
        let _user;
        try {
            _user = (JSON.parse(users) || [])
                .filter((u: { username: string; password: string; }) => u.username === username)[0];
        } catch (e) {
            // eslint-disable-next-line no-console
            console.error(e);
        }

        if (_user?.password === password) {
            setError('');
            setUser(_user.username);
        } else {
            setError('Wrong username or password');
        }
    };

    const signout = () => {
        setUser('');
    };

    useEffect(() => {
        if (getUrlParams().has('signout')) {
            window.history.replaceState({}, document.title, '/');
            signout();
        }
    }, []);

    return {
        user,
        signin,
        signout,
        error,
        setError,
    };
}
