import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-3-right">
        <path
            d="M403.338 266.312a202.233 202.233 0 0 0 3.728-25.437c.212-2.561.408-5.123.604-7.686.445-5.835.907-11.868 1.57-17.775l.016-.15a32.033 32.033 0 0 0-31.854-35.424 31.924 31.924 0 0 0-31.778 28.582c-.635 5.903-1.497 11.892-2.331 17.682-.366 2.544-.732 5.09-1.081 7.633a202.289 202.289 0 0 0-1.891 25.636c-.175 9.119-.091 17.563.257 25.817 11.927-7.94 37.662-13.435 61.011-9.82.976-4.501 1.468-7.671 1.749-9.058Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-3-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-3-right-a"
                x1={343.587}
                y1={196.69}
                x2={392.974}
                y2={270.531}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
