import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m527.553 376.625-173.201 49.779V634.08h177.281V455.932c0-3.2-.088-6.399-.263-9.596l-3.817-69.711Z"
            fill="#64CCC9"
        />
    </g>
);

export default SvgComponent;
