import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-2-left">
        <path
            d="M517.976 198.591c-9.282-6.355-17.306-16.13-28.914-18.162-20.362-4.496-40.617 14.04-37.902 34.715.941 15.834 15.348 24.112 25.884 33.637 6.188 5.434 12.453 10.783 19.102 15.655l.001-.002c1.498 1.09 56.762-35.312 56.762-35.312-10.699-12-28.546-25.805-34.933-30.531Z"
            fill="#001489"
        />
        <path
            d="m465.018 167.066 2.653.952c13.051 4.677 23.788 10.342 32.826 17.319.765.59 5.866 5.433 5.815 6.374-.25 4.648-16.938 8.176-19.334 9.644l-.857.525-21.103-34.814Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
