import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 836,
        height: 396,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M707.346 157.035v18.85h-18.85v-18.85M688.496 94.277v62.75M656.569 297.816v96.58h-18.85v-96.58M656.566 297.822V157.035M637.715 297.822V157.035M67.996 157.035v140.787M67.995 316.672v77.73h-18.85V157.035"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M67.996 316.666h708.22v-18.85H67.996"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M173.346 157.035v237.367h-18.85V157.035M757.366 157.035v237.367h-18.85V157.035M757.367 157.027h18.85v-18.85H32.477v-18.85h542.43c32.39 0 64.13-5.91 94.34-17.56l19.25-7.49M154.496 157.035h-86.5M637.714 157.035h-464.37M738.516 157.035h-81.95M1.273 119.662c0-25.036 20.296-45.332 45.332-45.332h528.302c26.827 0 53.119-4.895 78.145-14.552L773.984 12.73"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M478.34 58.977 605.584 9.474a123.787 123.787 0 0 1 44.883-8.423h132.015c25.263.632 39.284 10.34 46.514 18.578l2.716 3.177c4.939 5.776 2.697 14.7-4.386 17.456l-16.64 6.475-103.34 40.21v70.086"
                />
            </>
        ),
    },
    'Color #1': {
        width: 836,
        height: 395,
        content: (
            <>
                <path fill="#AEAEAF" d="M707.53 74.336h-18.85v82.695h18.85V74.336Z" />
                <path fill="url(#object-office-bench-a)" d="M776.398 138.182v-18.85H32.658v18.85h743.74Z" />
                <path
                    fill="url(#object-office-bench-b)"
                    d="M823.549 14.253a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55h-136.01l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
                />
                <path
                    fill="url(#object-office-bench-c)"
                    d="m32.659 119.331-31.202.333c0-25.036 20.296-45.332 45.332-45.332H575.09c26.827 0 53.119-4.896 78.145-14.553L784.982 8.524a39.37 39.37 0 0 1 44.197 11.106h-.001l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48.002-.003-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659v.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m439.079 74.332-.03.01"
                />
                <path
                    fill="#616469"
                    d="M738.697 297.822v-159.64h18.85V394.402h-18.85v-77.73"
                />
                <path
                    fill="#AEAEAF"
                    d="M68.178 138.186h-18.85v256.217h18.85V138.186ZM656.782 138.186h-18.85v256.217h18.85V138.186Z"
                />
                <path fill="url(#object-office-bench-d)" d="M698.107 316.672v-18.85h-524.58v18.85h524.58Z" />
                <path fill="#3A383A" d="M173.53 138.186h-18.85v256.217h18.85V138.186Z" />
                <path fill="url(#object-office-bench-e)" d="M154.682 316.673V297.82H68.18v18.853h86.502Z" />
                <path fill="url(#object-office-bench-f)" d="M738.697 316.673V297.82h-40.59v18.853h40.59Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m810.87 46.738-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659l-31.202.333c0-25.036 20.296-45.332 45.332-45.332H575.09c26.827 0 53.119-4.896 78.145-14.553"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m439.049 74.338 166.719-64.86a123.787 123.787 0 0 1 44.883-8.423h132.015c25.263.632 39.284 10.34 46.514 18.578l2.716 3.177c4.939 5.776 2.697 14.7-4.386 17.456l-16.64 6.474"
                />
                <path
                    fill="#616469"
                    d="M154.678 297.822v-159.64h18.85V394.402h-18.85v-77.73"
                />
                <defs>
                    <linearGradient
                        id="object-office-bench-a"
                        x1={32.658}
                        x2={776.398}
                        y1={128.757}
                        y2={128.757}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-b"
                        x1={656.136}
                        x2={460.599}
                        y1={57.398}
                        y2={-49.465}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#ECF3F9" />
                        <stop offset={0.969} stopColor="#C2C9CF" />
                        <stop offset={1} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-c"
                        x1={134.332}
                        x2={458.467}
                        y1={-112.672}
                        y2={96.514}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#ECF3F9" />
                        <stop offset={0.443} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-d"
                        x1={173.527}
                        x2={698.107}
                        y1={307.247}
                        y2={307.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-e"
                        x1={151.383}
                        x2={73.305}
                        y1={307.247}
                        y2={307.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.191} stopColor="#707276" />
                        <stop offset={1} stopColor="#AEAEAF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-f"
                        x1={677.108}
                        x2={727.685}
                        y1={307.246}
                        y2={307.246}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 836,
        height: 395,
        content: (
            <>
                <path fill="#AEAEAF" d="M707.532 74.338h-18.85v82.695h18.85V74.338Z" />
                <path fill="url(#object-office-bench-a)" d="M776.398 138.184v-18.85H32.658v18.85h743.74Z" />
                <path
                    fill="url(#object-office-bench-b)"
                    d="M823.551 14.255a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55h-136.01l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
                />
                <path
                    fill="url(#object-office-bench-c)"
                    d="m32.659 119.332-31.202.333c0-25.036 20.296-45.332 45.332-45.332h528.302c26.827 0 53.119-4.896 78.145-14.553L784.983 8.526a39.37 39.37 0 0 1 44.197 11.106h-.001l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48.002-.003-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m439.079 74.332-.03.01"
                />
                <path
                    fill="#616469"
                    d="M738.699 297.824v-159.64h18.85V394.404h-18.85v-77.73"
                />
                <path
                    fill="#AEAEAF"
                    d="M68.178 138.186h-18.85v256.217h18.85V138.186ZM656.782 138.186h-18.85v256.217h18.85V138.186Z"
                />
                <path fill="url(#object-office-bench-d)" d="M698.109 316.674v-18.85h-524.58v18.85h524.58Z" />
                <path fill="#3A383A" d="M173.532 138.186h-18.85v256.217h18.85V138.186Z" />
                <path fill="url(#object-office-bench-e)" d="M154.682 316.675v-18.853H68.18v18.853h86.502Z" />
                <path fill="url(#object-office-bench-f)" d="M738.699 316.675v-18.853h-40.59v18.853h40.59Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m810.871 46.74-103.34 40.21-18.85 7.332-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659l-31.202.333c0-25.036 20.296-45.332 45.332-45.332h528.302c26.827 0 53.119-4.896 78.145-14.553L784.983 8.526a39.37 39.37 0 0 1 44.197 11.106"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M439.051 74.339 605.77 9.478a123.787 123.787 0 0 1 44.883-8.423h132.015c25.263.632 39.284 10.34 46.514 18.578l2.716 3.177c4.939 5.776 2.697 14.7-4.386 17.456l-16.64 6.475"
                />
                <path
                    fill="#616469"
                    d="M154.68 297.824v-159.64h18.85V394.404h-18.85v-77.73"
                />
                <defs>
                    <linearGradient
                        id="object-office-bench-a"
                        x1={0.456}
                        x2={1.456}
                        y1={128.76}
                        y2={128.76}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-b"
                        x1={656.137}
                        x2={460.601}
                        y1={57.399}
                        y2={-49.464}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFE4B8" />
                        <stop offset={0.969} stopColor="#E5B97F" />
                        <stop offset={1} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-c"
                        x1={134.333}
                        x2={458.468}
                        y1={-112.671}
                        y2={96.515}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FFE4B8" />
                        <stop offset={0.443} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-d"
                        x1={173.529}
                        x2={698.109}
                        y1={307.248}
                        y2={307.248}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-e"
                        x1={0.458}
                        x2={1.458}
                        y1={307.248}
                        y2={307.248}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.191} stopColor="#707276" />
                        <stop offset={1} stopColor="#AEAEAF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-f"
                        x1={677.109}
                        x2={727.686}
                        y1={307.247}
                        y2={307.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 836,
        height: 395,
        content: (
            <>
                <path fill="#AEAEAF" d="M707.532 74.338h-18.85v82.695h18.85V74.338Z" />
                <path fill="url(#object-office-bench-a)" d="M776.398 138.184v-18.85H32.658v18.85h743.74Z" />
                <path
                    fill="url(#object-office-bench-b)"
                    d="M823.551 14.255a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55h-136.01l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
                />
                <path
                    fill="url(#object-office-bench-c)"
                    d="m32.659 119.333-31.202.333c0-25.036 20.296-45.332 45.332-45.332h528.302c26.827 0 53.119-4.896 78.145-14.553L784.983 8.526a39.37 39.37 0 0 1 44.197 11.106h-.001l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48.002-.003-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659v.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m439.079 74.332-.03.01"
                />
                <path
                    fill="#616469"
                    d="M738.699 297.824v-159.64h18.85V394.404h-18.85v-77.73"
                />
                <path
                    fill="#AEAEAF"
                    d="M68.178 138.186h-18.85v256.217h18.85V138.186ZM656.782 138.186h-18.85v256.217h18.85V138.186Z"
                />
                <path fill="url(#object-office-bench-d)" d="M698.109 316.672v-18.85h-524.58v18.85h524.58Z" />
                <path fill="#3A383A" d="M173.532 138.186h-18.85v256.217h18.85V138.186Z" />
                <path fill="url(#object-office-bench-e)" d="M154.682 316.675v-18.853H68.18v18.853h86.502Z" />
                <path fill="url(#object-office-bench-f)" d="M738.699 316.675v-18.853h-40.59v18.853h40.59Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m810.871 46.74-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659l-31.202.333c0-25.036 20.296-45.332 45.332-45.332h528.302c26.827 0 53.119-4.896 78.145-14.553L784.983 8.526a39.37 39.37 0 0 1 44.197 11.106"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M439.051 74.338 605.77 9.478a123.787 123.787 0 0 1 44.883-8.423h132.015c25.263.632 39.284 10.34 46.514 18.578l2.716 3.177c4.939 5.776 2.697 14.7-4.386 17.456l-16.64 6.475"
                />
                <path
                    fill="#616469"
                    d="M154.68 297.824v-159.64h18.85V394.404h-18.85v-77.73"
                />
                <defs>
                    <linearGradient
                        id="object-office-bench-a"
                        x1={32.658}
                        x2={776.398}
                        y1={128.76}
                        y2={128.76}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-b"
                        x1={656.137}
                        x2={460.601}
                        y1={57.399}
                        y2={-49.465}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FCCBBB" />
                        <stop offset={0.969} stopColor="#EA9975" />
                        <stop offset={1} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-c"
                        x1={134.333}
                        x2={458.468}
                        y1={-112.67}
                        y2={96.515}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FCCBBB" />
                        <stop offset={0.443} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-d"
                        x1={173.529}
                        x2={698.109}
                        y1={307.247}
                        y2={307.247}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-e"
                        x1={151.383}
                        x2={73.305}
                        y1={307.248}
                        y2={307.248}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.191} stopColor="#707276" />
                        <stop offset={1} stopColor="#AEAEAF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-f"
                        x1={677.109}
                        x2={727.686}
                        y1={307.248}
                        y2={307.248}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 836,
        height: 395,
        content: (
            <>
                <path fill="#AEAEAF" d="M707.534 74.334h-18.85v82.695h18.85V74.334Z" />
                <path fill="url(#object-office-bench-a)" d="M776.398 138.184v-18.85H32.658v18.85h743.74Z" />
                <path
                    fill="url(#object-office-bench-b)"
                    d="M823.551 14.253a39.395 39.395 0 0 0-38.6-5.73l-131.74 51.26c-25.03 9.66-51.32 14.55-78.15 14.55h-136.01l166.69-64.86c14.31-5.56 29.53-8.42 44.88-8.42h132.02c19.87.5 32.78 6.61 40.91 13.2Z"
                />
                <path
                    fill="url(#object-office-bench-c)"
                    d="m32.66 119.329-31.203.333c0-25.036 20.296-45.332 45.332-45.332H575.09c26.827 0 53.119-4.895 78.145-14.552L784.983 8.522a39.37 39.37 0 0 1 44.197 11.106h-.001l2.72 3.18c4.94 5.77 2.69 14.7-4.39 17.45l-16.64 6.48.002-.002-103.34 40.21-18.85 7.332-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659v.001Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m439.081 74.334-.03.01"
                />
                <path
                    fill="#616469"
                    d="M738.699 297.818v-159.64h18.85V394.398h-18.85v-77.73"
                />
                <path
                    fill="#AEAEAF"
                    d="M68.178 138.186h-18.85v256.217h18.85V138.186ZM656.784 138.186h-18.85v256.217h18.85V138.186Z"
                />
                <path fill="url(#object-office-bench-d)" d="M698.109 316.668v-18.85h-524.58v18.85h524.58Z" />
                <path fill="#3A383A" d="M173.534 138.186h-18.85v256.217h18.85V138.186Z" />
                <path fill="url(#object-office-bench-e)" d="M154.68 316.671v-18.853H68.178v18.853h86.502Z" />
                <path fill="url(#object-office-bench-f)" d="M738.699 316.671v-18.853h-40.59v18.853h40.59Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m810.871 46.74-103.34 40.21-18.85 7.333-19.25 7.49c-30.21 11.65-61.95 17.56-94.34 17.56H32.659l-31.202.333c0-25.036 20.296-45.332 45.332-45.332H575.09c26.827 0 53.119-4.896 78.145-14.553"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M439.051 74.336 605.77 9.476a123.787 123.787 0 0 1 44.883-8.423h132.015c25.263.632 39.284 10.34 46.514 18.578l2.716 3.177c4.939 5.776 2.697 14.7-4.386 17.456l-16.64 6.475"
                />
                <path
                    fill="#616469"
                    d="M154.68 297.818v-159.64h18.85V394.398h-18.85v-77.73"
                />
                <defs>
                    <linearGradient
                        id="object-office-bench-a"
                        x1={32.658}
                        x2={776.398}
                        y1={128.759}
                        y2={128.759}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-b"
                        x1={656.137}
                        x2={460.6}
                        y1={57.397}
                        y2={-49.466}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#FFF4C3" />
                        <stop offset={0.969} stopColor="#FFD100" />
                        <stop offset={1} stopColor="#FFD207" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-c"
                        x1={134.332}
                        x2={458.467}
                        y1={-112.674}
                        y2={96.513}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.36} stopColor="#FFF4C3" />
                        <stop offset={0.412} stopColor="#FFDE49" />
                        <stop offset={0.443} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-d"
                        x1={173.528}
                        x2={698.108}
                        y1={307.243}
                        y2={307.243}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-e"
                        x1={151.382}
                        x2={73.304}
                        y1={307.244}
                        y2={307.244}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.191} stopColor="#707276" />
                        <stop offset={1} stopColor="#AEAEAF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-bench-f"
                        x1={677.108}
                        x2={727.686}
                        y1={307.244}
                        y2={307.244}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#AEAEAF" />
                        <stop offset={0.809} stopColor="#707276" />
                        <stop offset={0.999} stopColor="#616469" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-bench"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
