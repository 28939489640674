import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-2-stand-1">
            <path
                d="M520.49 860.431c-23.625 0-36.847-9.553-38.655-10.951l-24.54-5.177a9.086 9.086 0 0 1-5.888-4.158 9.088 9.088 0 0 1-1.014-7.136c.662-2.427 1.41-5.196 2.159-8.034l.323-1.225 1.116.599c7.055 3.785 15.324 7.998 24.195 10.089a47.824 47.824 0 0 0 10.928 1.305 35.214 35.214 0 0 0 14.943-3.079l.673-.312.494.552c5.053 5.643 10.127 9.624 15.082 11.832 8.835 3.938 13.007 7.396 12.399 10.278-.705 3.341-5.385 5.417-12.215 5.417Z"
                fill="#FFD109"
            />
            <path
                d="M532.707 855.013c-.705 3.341-5.386 5.417-12.215 5.417-23.625 0-36.847-9.553-38.655-10.951l-24.54-5.177a9.02 9.02 0 0 1-5.891-4.161"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M405.717 860.932a9.086 9.086 0 0 1-6.62-2.853 9.1 9.1 0 0 1-2.464-6.773c.086-1.471.177-3.064.266-4.72l.069-1.268 1.216.364c1.066.311 2.171.465 3.282.459a19.749 19.749 0 0 0 8.397-2.189c1.862-.903 3.686-2.002 5.448-3.066a61.057 61.057 0 0 1 6.559-3.594 31.174 31.174 0 0 1 12.612-2.651c.75 0 1.509.027 2.256.08l.494.036.272.414c3.454 5.279 6.512 8.835 9.087 10.569a49.76 49.76 0 0 0 5.924 3.13c4.556 2.169 8.492 4.042 8.492 6.643 0 3.444-4.21 5.418-11.551 5.418l-43.739.001Z"
                fill="url(#female-34-25-shoes-2-stand-1-a)"
            />
            <path
                d="M461.008 855.516c0 3.443-4.21 5.418-11.551 5.418h-43.739a9.026 9.026 0 0 1-6.622-2.856"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-2-stand-1-a"
                    x1={396.617}
                    y1={847.786}
                    x2={461.007}
                    y2={847.786}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#FFD100" />
                    <stop offset={1} stopColor="#FFF4C3" />
                </linearGradient>
            </defs>
        </g>
    );
}
