import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-down">
        <path
            fill="url(#man-34-25-tshirt-3-arm-left-down-a)"
            d="M500.229 186.79c-9.225-7.122-19.956-12.655-32.551-17.169l19.121 31.543s17.866-10.949 13.43-14.374Z"
        />
        <path
            fill="url(#man-34-25-tshirt-3-arm-left-down-b)"
            d="M482.033 295.852c-3.82-7.255-7.477-14.759-11.18-22.939a205.028 205.028 0 0 1-9.381-24.121 923.411 923.411 0 0 1-2.324-7.401c-1.753-5.626-3.567-11.443-5.548-17.086a31.771 31.771 0 0 1 1.348-24.455 31.788 31.788 0 0 1 18.245-16.339 31.892 31.892 0 0 1 10.6-1.816 32.04 32.04 0 0 1 30.193 21.408l.049.14c1.955 5.652 4.146 11.331 6.266 16.823.933 2.419 1.862 4.841 2.785 7.264a205.004 205.004 0 0 1 7.639 24.733c2.23 8.902 4.057 17.205 5.583 25.375l.376 2.073-54.263 17.079-.388-.738Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-down-a"
                x1={460.598}
                x2={508.742}
                y1={200.96}
                y2={158.236}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-down-b"
                x1={451.776}
                x2={536.684}
                y1={239.143}
                y2={239.143}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#ABADAD" />
                <stop offset={0.999} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
