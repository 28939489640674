import React from 'react';

const SvgComponent = () => (
    <g clipPath="url(#man-34-25-shorts-2-cycling-2-right-a)">
        <path
            d="M312.047 363.062c-10.875 10.265-20.371 26.22-20.444 78.726-.035 25.699 2.807 59.477 6.651 88.677l96.603 9.6c.94-14.263 1.311-31.997 2.578-46.32 1.167-13.204 5.1-25.615 14.245-35.211l.302-.319a98.059 98.059 0 0 1 26.043-19.379 76.276 76.276 0 0 0 29.969-28.57l-155.947-47.204Z"
            fill="url(#man-34-25-shorts-2-cycling-2-right-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-shorts-2-cycling-2-right-b"
                x1={291.603}
                y1={451.564}
                x2={467.994}
                y2={451.564}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489" />
                <stop offset={0.211} stopColor="#002794" />
                <stop offset={0.452} stopColor="#00399F" />
                <stop offset={0.708} stopColor="#0044A5" />
                <stop offset={1} stopColor="#0047A7" />
            </linearGradient>
            <clipPath id="man-34-25-shorts-2-cycling-2-right-a">
                <path
                    fill="#fff"
                    transform="translate(.893 .621)"
                    d="M0 0h900v900H0z"
                />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
