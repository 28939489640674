import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7605)"
            d="M524.44 280.417c-20.409-24.152-26.325-32.893-31.648-46.77-2.986-7.787-9.421-18.228-14.918-23.95a47.302 47.302 0 00-4.724-2.973c4.064 7.051 4.949 18.802-6.423 23.57a22.007 22.007 0 01-8.678 1.436c-10.921 0-21.127-6.507-31.94-13.399-7.282-4.644-14.812-9.445-22.911-12.429a32.723 32.723 0 00-10.802-1.713 31.456 31.456 0 00-4.391.293c-.16.022-.314.077-.452.162a62.74 62.74 0 00-26.518 74.436l18.824 52.892c4.37 12.283 2.109 25.795-6.376 34.828-20.638 21.97-25.614 45.362-27.936 63.292 14.883 0 179.194.875 179.224.714 9.23-48.617-.85-76.582-9.312-98.686a523.731 523.731 0 01-2.686-7.111c8.094-3.298 18.171-9.259 20.658-19.014 1.943-7.614-1.082-16.22-8.991-25.578z"
        ></path>
        <g filter="url(#filter0_f_11323_7605)">
            <path
                fill="url(#paint1_radial_11323_7605)"
                d="M410.676 291.392l5.079 9.293c5.359 11.03 17.156 17.893 29.235 20.012 7.208 1.264 14.814 1.029 21.605-1.698a31.507 31.507 0 0018.533-21.326 31.507 31.507 0 00-6.485-27.499l-6.917-10.635"
            ></path>
        </g>
        <defs>
            <filter
                id="filter0_f_11323_7605"
                width="91.306"
                height="77.898"
                x="402.676"
                y="251.539"
                colorInterpolationFilters="sRGB"
                filterUnits="userSpaceOnUse"
            >
                <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
                <feBlend
                    in="SourceGraphic"
                    in2="BackgroundImageFix"
                    result="shape"
                ></feBlend>
                <feGaussianBlur
                    result="effect1_foregroundBlur_11323_7605"
                    stdDeviation="4"
                ></feGaussianBlur>
            </filter>
            <linearGradient
                id="paint0_linear_11323_7605"
                x1="345.618"
                x2="533.203"
                y1="337.056"
                y2="304.183"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <radialGradient
                id="paint1_radial_11323_7605"
                cx="0"
                cy="0"
                r="1"
                gradientTransform="rotate(-20.922 989.809 -1071.713) scale(77.211 37.1881)"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.437" stopColor="#009CDE" stopOpacity="0"></stop>
                <stop offset="0.484" stopColor="#009CDE" stopOpacity="0.102"></stop>
                <stop offset="0.715" stopColor="#009CDE" stopOpacity="0.581"></stop>
                <stop offset="0.875" stopColor="#009CDE" stopOpacity="0.882"></stop>
                <stop offset="0.949" stopColor="#009CDE"></stop>
            </radialGradient>
        </defs>
    </g>
);

export default SvgComponent;
