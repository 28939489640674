import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_10159)"
            d="M386.031 219.824a31.546 31.546 0 00-.813-.011 27.355 27.355 0 00-27.473 26.703c-.152 5.23-.507 10.525-.82 15.199l-.073 1.095a130.295 130.295 0 0126.166-3.949c13.72-.672 29.552 2.476 29.552 2.476l-.002-.975c-.006-3.823.007-7.935.15-12.112l.004-.14a27.532 27.532 0 00-26.691-28.286z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_10159"
                x1="389.699"
                x2="384.494"
                y1="211.509"
                y2="259.654"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
