import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-left-down">
        <path
            d="M500.784 186.79c-9.225-7.122-19.957-12.655-32.552-17.169l19.122 31.543s17.866-10.949 13.43-14.374Z"
            fill="url(#man-34-25-shirt-1-arm-left-downa)"
        />
        <path
            d="M481.012 174.773a115.17 115.17 0 0 1 19.773 11.352"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M535.878 277.621c-1.593-8.533-3.477-16.964-5.571-25.323a203.112 203.112 0 0 0-7.603-24.615c-3.038-8.029-6.255-15.997-9.058-24.108l-.05-.141a31.012 31.012 0 0 0-15.828-17.675 30.994 30.994 0 0 0-23.692-1.305 30.989 30.989 0 0 0-17.675 15.828 31.012 31.012 0 0 0-1.304 23.691c2.843 8.098 5.273 16.339 7.882 24.518a203.149 203.149 0 0 0 9.338 24.01c3.489 7.708 7.183 15.344 11.153 22.884l52.624-16.564c-.072-.4-.142-.8-.216-1.2Z"
            fill="url(#man-34-25-shirt-1-arm-left-downb)"
        />
        <path
            d="M455.098 223.971c2.843 8.098 5.274 16.339 7.882 24.518a203.149 203.149 0 0 0 9.338 24.01c3.489 7.708 7.184 15.344 11.154 22.885l52.624-16.564-.217-1.201c-1.593-8.533-3.477-16.964-5.571-25.323a203.057 203.057 0 0 0-7.603-24.615c-3.038-8.029-6.414-17.2-8.726-23.249-3.923-10.258-10.018-14.991-10.018-14.991"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-downa"
                x1={503.946}
                y1={264.984}
                x2={483.778}
                y2={173.899}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-downb"
                x1={512.013}
                y1={156.422}
                x2={469.724}
                y2={372.422}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#E2E2E2" />
                <stop offset={0.966} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
