import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={93}
            height={167}
            fill="none"
            id="object-food-water-glass"
            {...attributes}
        >
            <g clipPath="url(#object-food-water-glass-a)">
                <path
                    fill="#fff"
                    d="M90.46 135.237v15.617c0 7.833-6.35 14.183-14.184 14.183H15.957c-7.833 0-14.184-6.35-14.184-14.183V1.774H90.46v133.463Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M90.46 135.237v15.617c0 7.833-6.35 14.183-14.184 14.183H15.957c-7.833 0-14.184-6.35-14.184-14.183V1.774H90.46v115.77"
                />
                <path
                    fill="#C0F0FF"
                    d="M78.047 36.918v113.934c0 .979-.794 1.773-1.773 1.773H15.955a1.773 1.773 0 0 1-1.773-1.773V36.918h63.865Z"
                />
            </g>
            <defs>
                <clipPath id="object-food-water-glass-a">
                    <path fill="#fff" d="M0 0h92.233v166.81H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
