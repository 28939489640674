import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m553.353 305.224-.23-.102c-5.817-2.578-11.304-4.752-15.437-6.358-.694-.265-1.388-.534-2.08-.804l-.116-.044a182.684 182.684 0 0 0-3.652-1.389l-5.842-2.116c-2.628-.983-5.314-1.883-7.949-2.766-1.281-.43-2.562-.859-3.846-1.302a255.266 255.266 0 0 0-23.968-6.857l-12.074-2.761-10.313-2.483c-4.503-5.108-9.1-9.652-12.171-12.63-5.12-4.929-10.517-9.773-16.502-14.807a158.539 158.539 0 0 0-17.727-13.191c-1.857-1.192-3.723-2.37-5.598-3.555-4.228-2.673-8.601-5.437-12.783-8.315l-.119-.081a27.6 27.6 0 0 0-15.589-4.811 27.746 27.746 0 0 0-22.965 12.139 27.59 27.59 0 0 0-4.321 20.795 27.573 27.573 0 0 0 11.65 17.759c4.203 2.862 8.367 5.941 12.393 8.919 1.771 1.31 3.542 2.62 5.319 3.914a157.775 157.775 0 0 0 18.706 11.748c6.873 3.765 13.345 7.046 19.789 10.032a201.008 201.008 0 0 0 20.949 8.441c1.801.575 21.05 6.248 30.791 8.394l2.254.498c7.451 1.645 15.156 3.347 22.645 4.651 1.313.222 2.622.455 3.932.687l.044-.246.025.258c2.708.482 5.508.979 8.283 1.375l6.159.943c1.293.195 2.591.364 3.89.532l.17.023c.714.092 1.429.184 2.149.283 4.153.537 9.695 1.22 15.74 1.737l.291.025-.02-.291c-.6-8.851 5.215-21.778 7.697-27.298l.426-.946Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-9-right-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-9-right-a"
                x1={356.961}
                y1={239.03}
                x2={592.913}
                y2={346.596}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
