import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={68}
            height={56}
            fill="none"
            {...attributes}
        >
            <g clipPath="url(#object-devices-ipad-a)">
                <path
                    fill="url(#object-devices-ipad-b)"
                    d="M9.2 5.263c.5-1.4 2.1-1.9 3.5-1.3l49.7 23.2c1.4.7 2.2 2.3 1.7 3.7l-8.1 22.3c-.5 1.4-2.1 1.9-3.5 1.3l-49.7-23.3c-1.4-.7-2.2-2.3-1.7-3.7l8.1-22.2Z"
                />
                <path
                    fill="#444"
                    d="m12.6 3.963-9.8 27.2 49.7 23.3 9.8-27.2-49.7-23.3Z"
                />
                <path
                    fill="url(#object-devices-ipad-c)"
                    d="m65.1 24.661-49.7-23.2c-1-.5-2.1-.3-2.8.3l-2.8 2.6c.7-.7 1.8-.8 2.8-.3l49.7 23.2c1.4.7 2.2 2.3 1.7 3.7l-8 22.2c-.1.4-.4.7-.6.9l2.8-2.5c.3-.2.5-.6.6-.9l8-22.3c.5-1.4-.3-3-1.7-3.7Z"
                />
                <path
                    stroke="#231F20"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m52.5 54.461-49.7-23.3c-1.4-.7-2.2-2.3-1.7-3.7l8-22.3c.5-1.4 2.1-1.9 3.5-1.3l49.7 23.2c1.4.7 2.2 2.3 1.7 3.7"
                />
                <path
                    stroke="#231F20"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m55.4 54.061 2.8-2.5c.3-.2.5-.6.6-.9l8-22.3c.5-1.4-.2-3-1.7-3.7l-49.7-23.2c-1-.5-2.1-.3-2.8.3l-2.8 2.6c.7-.7 1.8-.8 2.8-.3l49.7 23.2c1.4.7 2.2 2.3 1.7 3.7M8.8 14.56 7 19.46M58.401 38.162l-2.2 6"
                />
            </g>
            <defs>
                <linearGradient
                    id="object-devices-ipad-b"
                    x1={1.529}
                    x2={63.613}
                    y1={32.95}
                    y2={25.464}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#D9D9D6" />
                    <stop offset={1} stopColor="#F3F3F3" />
                </linearGradient>
                <linearGradient
                    id="object-devices-ipad-c"
                    x1={12.908}
                    x2={66.519}
                    y1={30.253}
                    y2={23.789}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop offset={0.001} stopColor="#888B8D" />
                    <stop offset={1} stopColor="#ABADAD" />
                </linearGradient>
                <clipPath id="object-devices-ipad-a">
                    <path fill="#fff" d="M0 .162h67.9v55.3H0z" />
                </clipPath>
            </defs>
        </svg>
    );
}
