import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6407)"
            d="M428.674 342.739a24.8 24.8 0 01-12.492-3.561 31.568 31.568 0 01-6.375-5.171 209.498 209.498 0 01-15.112-17.466c-4.48-5.73-8.852-11.737-13.366-18.363a166.063 166.063 0 01-11.621-19.403c-1.48-2.896-2.719-5.597-3.918-8.21-1.616-3.523-3.138-6.839-5.17-10.527-3.731-6.768-4.394-15.656-1.775-23.776 2.25-6.975 6.654-12.452 12.083-15.027 4.648-2.205 8.297-3.188 11.831-3.188.769.001 1.538.046 2.302.134 10.682 1.226 19.458 6.735 24.077 15.115l1.384 2.543c2.952 5.432 5.501 10.123 8.802 15.761a165.897 165.897 0 0110.097 20.244c3.153 7.369 5.86 14.284 8.275 21.137.735 2.11 1.462 4.28 2.22 6.628l6.412-1.783a230.689 230.689 0 0123.134-5.034c1.162-.186 2.316-.381 3.471-.575l.329-.056c2.588-.437 5.236-.884 7.902-1.235l5.842-.835c1.25-.175 2.503-.324 3.756-.472 0 0 1.53-.181 2.148-.258 3.746-.443 9.434-1.08 15.579-1.508l.238-.017-.025.237a36.085 36.085 0 001.803 13.378c2.918 9.036 7.084 13.863 7.126 13.91l.176.202-.243.112c-6.133 2.81-11.962 5.14-16.356 6.856-.658.251-1.308.505-1.957.758-1.2.469-2.398.935-3.602 1.375l-5.563 2.018c-2.507.94-5.074 1.798-7.557 2.627-1.222.409-2.428.811-3.63 1.224a230.666 230.666 0 01-22.781 6.44l-11.497 2.554-11.443 2.687a19.737 19.737 0 01-4.504.525z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6407"
                x1="352.464"
                x2="516.416"
                y1="248.478"
                y2="345.201"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
