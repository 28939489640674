import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M536.356 507.383c-.69-8.696-1.406-15.741-2.254-22.172-.453-3.419-.966-7.293-1.616-11.03-.16-.95-.317-1.899-.47-2.849-.436-2.671-.886-5.434-1.462-8.13l-1.089-5.49-1.26-5.442c-.964-4.059-1.939-7.619-2.978-10.882l-1.55-4.902c-3.533-11.082-1.762-21.577-1.762-28.595-13.305 0-67.798.061-77.28.061-15.08 9.901-23.595 28.23-22.437 47.491.228 3.78.564 7.528 1.002 11.14l.681 5.54.818 5.484c.375 2.737.865 5.494 1.34 8.162.168.945.336 1.89.498 2.828.448 2.742 1.016 5.504 1.565 8.176l1.685 8.268 1.262 5.441c.305 1.282.599 2.564.893 3.846.53 2.308 1.077 4.693 1.682 7.028 1.703 6.831 3.808 13.908 6.435 21.636 1.327 3.905 2.785 7.853 4.195 11.672 1.175 3.182 2.39 6.474 3.52 9.718l3.771 10.692 3.662 10.715 9.297 27.312a129.425 129.425 0 0 0-2.796 2.181c-1.178.922-2.321 1.89-3.427 2.825-.464.393-.928.786-1.423 1.2-.538.484-1.081.964-1.627 1.441-.993.874-2.02 1.777-2.987 2.693a239.538 239.538 0 0 0-17.131 17.588c-11.862 13.732-21.165 25.76-28.012 39.811-2.479 4.885-4.73 10.033-6.907 15.011-1.106 2.529-2.212 5.058-3.354 7.571-2.454 5.525-5.121 11.027-7.7 16.348-.955 1.97-1.907 3.942-2.856 5.914l-5.359 11.085a223.408 223.408 0 0 0-1.773 3.719l33.493 31.443 58.566-74 15.488-18.663 30.96-37.338a27.457 27.457 0 0 0 5.634-11.663 28.856 28.856 0 0 0 1.167-3.45l1.485-5.568c4.509-16.906 6.741-33.516 8.184-46.112.134-1.344.273-2.688.416-4.034.257-2.45.513-4.9.733-7.351l.939-11.342c.5-6.228 1.071-14.431 1.057-22.648-.005-7.171-.314-14.7-.918-22.379Z"
            fill="#EF0025"
        />
        <path
            d="M351.084 407.891a83.542 83.542 0 0 0-1.534 6.611 68.062 68.062 0 0 0 1.168 29.89 52.896 52.896 0 0 0-.586 5.998 178.819 178.819 0 0 0-.092 10.956l.138 5.465.278 5.422c.106 2.701.324 5.432.535 8.074.076.94.15 1.881.221 2.813.179 2.717.474 5.465.759 8.122.099.921.87 8.216.87 8.216l.725 5.424c.179 1.28.347 2.562.514 3.843.301 2.296.612 4.669.986 6.999 1.028 6.823 2.431 13.921 4.292 21.7.938 3.927 2.002 7.909 3.031 11.759.859 3.218 1.749 6.546 2.559 9.82l2.708 10.777 2.598 10.79 10.339 43.173c.019.077.04.154.062.23a112.013 112.013 0 0 0-3.192 11.31c-.352 1.444-.65 2.901-.937 4.311-.122.596-.243 1.193-.377 1.826-.122.71-.253 1.421-.384 2.132a108.45 108.45 0 0 0-.677 3.943 237.654 237.654 0 0 0-2.552 24.309c-.804 18.046-.656 33.182 2.673 48.382 1.081 5.344 11.895 60.439 11.961 60.739l46.187-.332.502-14.182c.384-10.375.699-20.92 1.003-31.117.171-5.721.345-11.442.524-17.164l.737-24.143.597-24.132 1.172-48.266c.013-.553.001-1.103-.022-1.653a314.623 314.623 0 0 0 11.963-42.32c.265-1.296.534-2.592.808-3.888.495-2.366.99-4.731 1.447-7.102l2.043-10.968c1.105-6.023 2.475-13.965 3.265-21.981.695-6.991 1.123-14.36 1.272-21.905l.007-.368c.379-19.645 9.941-38.133 24.577-51.242l.245-.219c20.844-18.735 32.534-36.083 38.42-52.051l-170.833-.001Z"
            fill="url(#female-34-25-pants-1-stand-2-a)"
        />
        <defs>
            <linearGradient
                id="female-34-25-pants-1-stand-2-a"
                x1={545.468}
                y1={598.837}
                x2={153.094}
                y2={579.721}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F" />
                <stop offset={0.679} stopColor="#F11528" />
                <stop offset={0.9} stopColor="#EF0025" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
