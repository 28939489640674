import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9282)"
            d="M494.779 222.853a2.715 2.715 0 01.821 2.482c-.805 4.667-13.808 10.893-16.406 12.094l-.912.421s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9282)"
            d="M510.406 244.051c-2.421-3.874-4.924-7.88-7.208-11.911l-.078-.139a27.505 27.505 0 00-27.374-13.579 27.49 27.49 0 00-18.252 10.481 27.494 27.494 0 00-2.07 30.487c2.305 4.014 4.478 8.206 6.579 12.26.425.821.855 1.637 1.281 2.457 0 0 13.238-12.559 23.647-18.306a140.628 140.628 0 0124.359-10.326c-.296-.474-.588-.95-.884-1.424z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9282"
                x1="513.66"
                x2="389.178"
                y1="185.523"
                y2="316.511"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9282"
                x1="524.023"
                x2="399.541"
                y1="195.372"
                y2="326.36"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
