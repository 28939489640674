import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={51}
            height={65}
            fill="none"
            id="object-food-egg"
            {...attributes}
        >
            <path
                fill="#fff"
                d="M49.653 39.281C49.653 25.992 38.88 1 25.59 1S1.527 25.992 1.527 39.281c0 13.289 10.773 24.063 24.063 24.063s24.063-10.773 24.063-24.063Z"
            />
            <path
                fill="url(#object-food-egg-a)"
                d="M28.367 1.375c4.048 7.711 6.532 16.777 6.532 23.153 0 13.29-10.773 24.063-24.063 24.063-2.79 0-5.465-.48-7.957-1.353 3.286 9.379 12.21 16.107 22.71 16.107 13.29 0 24.063-10.773 24.063-24.063 0-12.35-9.304-34.793-21.285-37.907Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M46.511 51.18c2-3.509 3.143-7.57 3.143-11.898C49.654 25.992 38.88 1 25.59 1 12.3 1 1.527 25.992 1.527 39.281c0 13.289 10.773 24.063 24.063 24.063a23.95 23.95 0 0 0 12.428-3.454"
            />
            <defs>
                <linearGradient
                    id="object-food-egg-a"
                    x1={19.427}
                    x2={41.659}
                    y1={22.922}
                    y2={75.975}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={1} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
