import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10364_9890)"
            d="M476.742 231.756c.76-3.49 1.23-7.037 1.406-10.605a33.848 33.848 0 00-1.403-11.518 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.597c-.522 3.03-8.331 7.886-15.838 11.355l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_10364_9890)"
            d="M466.499 270.248c-1.65-1.744-3.688-3.867-5.762-5.892a26.511 26.511 0 01-7.981-18.646 26.496 26.496 0 0116.07-24.673 26.492 26.492 0 0128.945 5.404l.117.115c3.125 3.091 6.43 6.072 9.625 8.955l1.027.931-42.041 33.806z"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M508.541 236.438L466.5 270.243c-1.65-1.744-3.688-3.867-5.762-5.891"
        ></path>
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2.27"
            d="M497.771 226.438l.119.115c4.455 4.407 9.199 8.52 13.818 12.759"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9890"
                x1="504.874"
                x2="440.346"
                y1="181.029"
                y2="303.238"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10364_9890"
                x1="510.292"
                x2="442.255"
                y1="181.479"
                y2="310.334"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#E2E2E2"></stop>
                <stop offset="1" stopColor="#fff"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
