import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g clipPath="url(#man-bold-34-25-legs-cycling2-left-a)">
        <path
            d="M399.197 584.465a562.962 562.962 0 0 1 12.65-17.665c2.154-2.89 4.253-5.845 6.454-8.68l6.561-8.554c4.356-5.725 8.802-11.344 13-17.254 4.246-5.854 8.257-11.985 12.735-17.565 8.782-11.366 19.91-19.97 31.421-28.123a183.648 183.648 0 0 1 18.217-11.112c1.571-.863 3.221-1.634 4.828-2.455 1.658-.762 3.284-1.56 4.985-2.27a87.447 87.447 0 0 1 10.543-3.866l12.914-3.754a25.911 25.911 0 0 1 32.43 30.917 25.91 25.91 0 0 1-9.738 14.755l-33.9 25.206-16.957 12.594-17.049 12.486c-11.38 8.307-22.697 16.689-34.13 24.934l-34.249 24.795a15.903 15.903 0 0 1-20.425-1.492 15.907 15.907 0 0 1-2.027-20.379l1.737-2.518Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M530.174 512.635a1313.913 1313.913 0 0 1-35.014-9.815l-17.429-5.052c-5.801-1.702-11.666-3.282-17.427-5.058a318.93 318.93 0 0 1-17.05-5.762c-2.808-1.023-5.574-2.125-8.363-3.184l-4.175-1.605-4.134-1.68a440.861 440.861 0 0 1-16.403-6.974l-4.054-1.83-4.015-1.903a199.92 199.92 0 0 1-7.932-3.992 50.754 50.754 0 0 1 4.137-91.64 50.758 50.758 0 0 1 37.275-.797l6.656 2.474c2.8 1.038 5.535 2.199 8.236 3.424l4.038 1.859 3.977 1.975c2.67 1.281 5.254 2.724 7.869 4.109 2.62 1.375 5.189 2.846 7.757 4.319 5.134 2.949 10.169 6.083 15.168 9.284 4.997 3.205 9.916 6.557 14.719 10.125 4.806 3.563 9.442 7.444 14 11.471 4.55 4.041 9.124 8.039 13.565 12.286a245.237 245.237 0 0 1 25.461 27.693l3.559 4.639a28.978 28.978 0 0 1 1.119 33.703 28.967 28.967 0 0 1-31.54 11.931Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M565.991 478.793c3.101 10.668-1.389 23.764-9.794 30.043l-33.9 25.205-16.957 12.594-17.049 12.487c-11.38 8.306-22.697 16.688-34.13 24.934l-34.249 24.795a15.908 15.908 0 0 1-22.452-21.872l1.736-2.517a566.301 566.301 0 0 1 12.65-17.666c2.155-2.89 4.254-5.845 6.455-8.68l6.561-8.554c4.356-5.724 8.801-11.343 13-17.253 4.245-5.854 8.256-11.985 12.734-17.566a107.333 107.333 0 0 1 15.974-16.363"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M477.731 497.768c-5.801-1.702-11.666-3.282-17.427-5.058a318.93 318.93 0 0 1-17.05-5.762c-2.808-1.023-5.574-2.125-8.363-3.184l-4.175-1.605-4.134-1.68a440.861 440.861 0 0 1-16.403-6.974l-4.054-1.83-4.015-1.903a199.92 199.92 0 0 1-7.932-3.992 50.754 50.754 0 0 1 4.137-91.64 50.758 50.758 0 0 1 37.275-.797l6.656 2.474c2.8 1.038 5.535 2.199 8.236 3.424l4.038 1.859 3.977 1.975c2.67 1.281 5.254 2.724 7.869 4.109 2.62 1.375 5.189 2.846 7.757 4.319 5.134 2.949 10.169 6.083 15.168 9.284 4.997 3.205 9.916 6.557 14.719 10.125 4.806 3.563 9.442 7.444 14 11.471 4.55 4.041 9.124 8.039 13.565 12.286a245.237 245.237 0 0 1 25.461 27.693l3.559 4.639a28.858 28.858 0 0 1 5.396 11.795"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M397.61 585.891c-2.683 3.411-14.975 14.542-23.015 21.708a8.994 8.994 0 0 0-.365 13.085l14.39 14.36a42.19 42.19 0 0 1 7.688 10.399 69.664 69.664 0 0 0 12.367 16.665c2.884 2.917 14.973 11.898 20.791 16.173a4.151 4.151 0 0 0 5.653-.693 4.15 4.15 0 0 0 .57-4.416c-1.426-3.04-3.975-8.395-6.161-12.579-9.814-18.78-8.902-51.734-8.902-51.734"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M397.61 585.891c-2.683 3.411-14.975 14.542-23.015 21.708a8.994 8.994 0 0 0-.365 13.085l14.39 14.36a42.19 42.19 0 0 1 7.688 10.399 69.664 69.664 0 0 0 12.367 16.665c2.884 2.917 14.973 11.898 20.791 16.173a4.151 4.151 0 0 0 5.653-.693 4.15 4.15 0 0 0 .57-4.416c-1.426-3.04-3.975-8.395-6.161-12.579-9.814-18.78-8.902-51.734-8.902-51.734"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <clipPath id="man-bold-34-25-legs-cycling2-left-a">
                <path fill="#fff" transform="translate(.893 .57)" d="M0 0h900v900H0z" />
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
