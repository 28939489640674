import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6615)"
            d="M415.995 257.591c-1.523-4.686-3.098-9.532-4.453-14.351l-.04-.137a27.612 27.612 0 00-26.418-19.915 27.522 27.522 0 00-7.605 1.075 27.531 27.531 0 00-18.84 34.023c1.379 4.799 2.587 9.744 3.757 14.526a977.25 977.25 0 001.144 4.629l52.64-19.277-.185-.573z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6615"
                x1="357.568"
                x2="427.475"
                y1="250.314"
                y2="250.314"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
