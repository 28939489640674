import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-1-cycling-2-left">
        <path
            d="M503.717 206.951c-7.696-12.253-24.202-27.922-37.678-36.217l-2.114 9.542 9.65 41.17s33.123-9.749 30.142-14.495Z"
            fill="#001489"
        />
        <path
            d="M507.407 212.641c-6.323-11.804-16.237-18.813-29.623-18.813A32.019 32.019 0 0 0 463 197.452a32.017 32.017 0 0 0-16.943 24.235 32.009 32.009 0 0 0 1.673 15.129c2.044 5.596 3.921 11.368 5.738 16.949.801 2.46 1.6 4.92 2.416 7.372 1.432 4.347 6.976 22.847 8.882 27.472l62.774-16.637c-2.458-11.855-13.627-47.186-20.133-59.331Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
