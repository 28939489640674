import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-6">
        <path
            d="m501.329 185.923-.027-.02-.006-.004c-9.031-6.966-19.757-12.624-32.793-17.295l-2.653-.952 21.103 34.814.857-.526c2.396-1.468 14.36-9.028 14.61-13.676a2.666 2.666 0 0 0-1.091-2.341Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-6-a)"
        />
        <path
            d="M531.942 214.022c-.64-1.23-1.282-2.449-1.924-3.668-1.311-2.488-2.666-5.06-3.954-7.624-1.846-3.604-3.81-7.457-5.69-11.375-.327-.116-.655-.23-.984-.342-2.349-.803-4.704-1.589-7.059-2.376-5.353-1.787-10.888-3.636-16.251-5.651l-.149-.056a32 32 0 1 0-22.209 60.023c5.373 1.989 10.77 4.216 15.989 6.37 2.292.947 4.584 1.893 6.88 2.822a188.294 188.294 0 0 0 23.558 7.798c8.511 2.294 16.444 4.178 24.255 5.76-7.268-16.84-9.755-34.431-10.261-47.577a685.793 685.793 0 0 1-2.201-4.104Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-6-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-6-a"
                x1={569.172}
                y1={327.479}
                x2={457.268}
                y2={130.129}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-6-b"
                x1={543.743}
                y1={307.13}
                x2={451.378}
                y2={144.238}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.416} stopColor="#ABADAD" />
                <stop offset={0.973} stopColor="#888B8D" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
