import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as UserIcon } from 'icons/user.svg';
import { ReactComponent as TableIcon } from 'icons/table.svg';
import { ReactComponent as FilmIcon } from 'icons/film_line.svg';

import { PageContext } from 'components/App';
import { TTabs } from 'interfaces';

const TABS: {
    title: TTabs,
    icon: JSX.Element,
    disabled?: boolean,
}[] = [
    {
        title: 'Character',
        icon: <UserIcon className="icon-character"/>,
    },
    {
        title: 'Objects',
        icon: <TableIcon className="icon-objects"/>,
    },
    {
        title: 'Scenes',
        icon: <FilmIcon className="icon-scenes"/>,
    },
];

export default function Tabs() {
    const context = useContext(PageContext);
    const activeTab: TTabs = context.content.tab;

    function selectTab(tabName: TTabs) {
        context.setContent({ ...context.content, tab: tabName });
    }

    return <div className="tabs">
        {TABS.map(({ title, icon, disabled }) => (
            <Tab
                title={title}
                icon={icon}
                key={title}
                selectTab={selectTab}
                active={activeTab === title}
                disabled={disabled || false}
            />
        ))}
    </div>;
}

function Tab({
    icon,
    title,
    selectTab,
    active,
    disabled = false,
}: {
    icon: JSX.Element,
    title: TTabs,
    selectTab: (title: TTabs) => void,
    active: boolean,
    disabled?: boolean,
}) {
    const nonInteractive = disabled || active;

    function handleClick() {
        if (!nonInteractive) selectTab(title);
    }

    return (
        <Link
            to={`/${title.toLowerCase()}`}
            className={`tab ${active ? 'active' : ''} ${nonInteractive ? 'disabled' : ''}`}
            key={title}
            onClick={handleClick}
        >
            <div className="tab-icon">{icon}</div>
            <h3>{title}</h3>
        </Link>

    );
}
