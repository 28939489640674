import React, { useContext, useEffect, useMemo, useReducer, useState } from 'react';

import { ILegs, TGender, THairColor, THairStyle, TLegsSkins, TSkinColor, TSkinEl } from 'interfaces';

import Settings, { Option, OptionsWrapper } from 'components/Settings';
import hashReducer, { THashState, Types } from 'components/hashReducer';
import Request from 'components/Controls/Request';
import PicWrapper from 'components/PicWrapper';
import rootSettings from 'components/parts';

import Bike from 'assets/bike';
import { PageContext } from 'components/App';
import useStateHistory from 'utils/useStateHistory';
import { decodeHash, hashString } from 'utils/hashUtil';

const SKIN_COLOR: TSkinColor[] = ['white', 'black', 'brown', 'yellow'];

export const FEMALE_DEFAULT_SETTINGS = {
    [Types.skin]: SKIN_COLOR[0],
    [Types.emotion]: 0,
    [Types.nose]: 0,
    [Types.hairStyle]: 0,
    [Types.hairStyleColor]: 'gray',
    [Types.bodyPosition]: 'normal',
    [Types.position]: 'stand1',
    [Types.bothArmsPosition]: 'option1',
    [Types.cyclingArmsPosition1]: 'option1',
    [Types.cyclingArmsPosition2]: 'option1',
    [Types.settingsSingleArm]: true,
    [Types.settingsLegsOpt2]: 0,
    [Types.leftArmPosition]: 'option1',
    [Types.rightArmPosition]: 'option1',
    [Types.legsClothes]: 'pants1',
    [Types.bodyClothes]: 'jacket2',
    [Types.shoes]: 'shoes1',
    [Types.gender]: TGender.female,
} as const;

export const MALE_DEFAULT_SETTINGS = {
    [Types.skin]: SKIN_COLOR[0],
    [Types.emotion]: 0,
    [Types.nose]: 0,
    [Types.hairStyle]: 0,
    [Types.hairStyleColor]: 'gray',
    [Types.position]: 'stand1',
    [Types.bothArmsPosition]: 'option1',
    [Types.cyclingArmsPosition1]: 'option1',
    [Types.cyclingArmsPosition2]: 'option1',
    [Types.settingsSingleArm]: true,
    [Types.settingsLegsOpt2]: 0,
    [Types.leftArmPosition]: 'option1',
    [Types.rightArmPosition]: 'option1',
    [Types.bodyPosition]: 'normal',
    [Types.legsClothes]: 'pants1',
    [Types.bodyClothes]: 'doctorsCoat',
    [Types.shoes]: 'shoes1',
    [Types.gender]: TGender.male,
} as const;

function CharacterBuilder() {
    const { baseSettings } = useContext(PageContext);

    const [hoverSettings, setHover] = useState(false);
    const [state, dispatch] = useReducer(hashReducer, baseSettings.gender === TGender.female
        ? FEMALE_DEFAULT_SETTINGS
        : MALE_DEFAULT_SETTINGS
    );

    const {
        BODY_CLOTHES,
        HEAD_SKIN: HeadSkin,
        ARM_LEFT_SKINS,
        ARM_RIGHT_SKINS,
        BODY_SKIN_POSITION,
        LEFT_ARM_POSITION,
        RIGHT_ARM_POSITION,
        BOTH_ARMS_POSITION,
        BOTH_ARMS_SKINS,
        BOTH_ARMS_CYCLING_POSITION_1,
        BOTH_ARMS_CYCLING_POSITION_2,
        LEGS = {},
        POSES,
        SHOES,
        LEGS_SKINS,
        NOSES,
        EMOTIONS,
        HAIR_STYLE_COLOR,
        HAIR_STYLE,
    } = useMemo(() => rootSettings[state[Types.gender]], [state[Types.gender]]);

    const { state: historyState, undo, push, clearHistory, history } = useStateHistory(hashString(state));

    const stateObject = useMemo(() => {
        return JSON.stringify(state, null, 2)
            .replaceAll('  "', '  ')
            .replaceAll('":', ':')
            .replaceAll('"', '\'');
    }, [state]);

    const bodyPositionIsNormal = state[Types.bodyPosition] === 'normal' || state[Types.bodyPosition] === 'sitting';

    const BodySkin = BODY_SKIN_POSITION[state[Types.bodyPosition]];
    const LeftArmSkin = ARM_LEFT_SKINS[state[Types.leftArmPosition]];
    const RightArmSkin = ARM_RIGHT_SKINS[state[Types.rightArmPosition]];

    const [BothArmsSkinRight, BothArmsSkinLeft] = BOTH_ARMS_SKINS && {
        normal: BOTH_ARMS_SKINS?.normal?.[state[Types.bothArmsPosition]],
        sitting: BOTH_ARMS_SKINS?.normal?.[state[Types.bothArmsPosition]],
        cycling1: BOTH_ARMS_SKINS?.cycling1?.[state[Types.cyclingArmsPosition1]],
        cycling2: BOTH_ARMS_SKINS?.cycling2?.[state[Types.cyclingArmsPosition2]],
    }[state[Types.bodyPosition]] || [null, null];

    const LegsSkinRight = LEGS_SKINS?.[state[Types.position]]?.[state[Types.settingsLegsOpt2] || 0]?.[0]
        || LEGS_SKINS[state[Types.position]]?.[0][0];
    const LegsSkinLeft = LEGS_SKINS[state[Types.position]]?.[state[Types.settingsLegsOpt2] || 0]?.[1];
    const NoseComponent = NOSES[state[Types.nose]][1];
    const EmotionComponent = EMOTIONS[state[Types.emotion]][1];
    const HaircutComponent = HAIR_STYLE[state[Types.hairStyle]]?.[1];

    const bodyClothesState = BODY_CLOTHES[state[Types.bodyClothes]];
    const bodyClothesNormal = bodyClothesState?.normal;
    const bodyClothesSitting = bodyClothesState?.sitting;
    const bodyClothesCycling1 = bodyClothesState?.cycling1;
    const bodyClothesCycling2 = bodyClothesState?.cycling2;

    const { Front, Front2, Back, leftArm, rightArm, bothArms } = {
        normal: {
            Front: bodyClothesNormal?.front,
            Back: bodyClothesNormal?.back,
            leftArm: bodyClothesNormal?.leftArm,
            rightArm: bodyClothesNormal?.rightArm,
            bothArms: bodyClothesNormal?.bothArms,
        },
        sitting: {
            Front: bodyClothesSitting?.front || null,
            Front2: bodyClothesSitting?.front2 || null,
            Back: bodyClothesSitting?.back || null,
            leftArm: bodyClothesSitting?.leftArm,
            rightArm: bodyClothesSitting?.rightArm,
            bothArms: bodyClothesSitting?.bothArms,
        },
        cycling1: {
            Front: bodyClothesCycling1?.front || null,
            Back: bodyClothesCycling1?.back,
            leftArm: undefined,
            rightArm: undefined,
            bothArms: bodyClothesCycling1?.bothArms
        },
        cycling2: {
            Front: bodyClothesCycling2?.front || null,
            Back: bodyClothesCycling2?.back,
            leftArm: undefined,
            rightArm: undefined,
            bothArms: bodyClothesCycling2?.bothArms
        }
    }[state[Types.bodyPosition]];

    const [LegsComponentR, LegsComponentL] = state[Types.legsClothes]
        && !BODY_CLOTHES?.[state[Types.bodyClothes]]?.disableLegsClothes
        && LEGS?.[state[Types.legsClothes]]?.[state[Types.position]]
        || [null, null];
    const [ShoesComponentR, ShoesComponentL] = state[Types.shoes] &&
        SHOES?.[state[Types.shoes]]?.[state[Types.position]] || [null, null];

    const [RightArm, LeftArm] = (state[Types.settingsSingleArm] && bodyPositionIsNormal)
        ? [rightArm?.[state[Types.rightArmPosition]], leftArm?.[state[Types.leftArmPosition]]]
        : bothArms?.[bodyPositionIsNormal
            ? state[Types.bothArmsPosition]
            : state[Types.cyclingArmsPosition1]
        ] || [];

    useEffect(() => {
        const _newState = hashString(baseSettings.gender === TGender.female
            ? FEMALE_DEFAULT_SETTINGS
            : MALE_DEFAULT_SETTINGS);
        clearHistory(_newState);
        dispatch({ type: 0, value: _newState });
    }, [baseSettings.gender]);

    useEffect(() => {
        if (!hoverSettings) {
            dispatch({ type: 0, value: historyState });
        }
    }, [hoverSettings]);

    const hStateObj = useMemo(() => {
        return decodeHash(historyState) as THashState;
    }, [historyState]);

    return <div className="layout">
        <Settings>
            {process.env.NODE_ENV === 'development' ? <pre className="debug-code">{stateObject}</pre> : null}
            <OptionsWrapper
                title="Arm Left"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                disabled={!bodyPositionIsNormal}
            >
                {Object.keys(LEFT_ARM_POSITION || {}).map((_position) => {
                    return <Option
                        key={_position}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.leftArmPosition, value: _position })}
                        active={hStateObj[Types.settingsSingleArm] && bodyPositionIsNormal
                            && _position === hStateObj[Types.leftArmPosition]}
                        text={LEFT_ARM_POSITION[_position]}
                    />;
                })}
            </OptionsWrapper>
            <OptionsWrapper
                title="Arm Right"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
                disabled={!bodyPositionIsNormal}
            >
                {Object.keys(RIGHT_ARM_POSITION || {}).map((_position) =>
                    <Option
                        key={_position}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.rightArmPosition, value: _position })}
                        active={hStateObj[Types.settingsSingleArm] && bodyPositionIsNormal
                            && _position === hStateObj[Types.rightArmPosition]}
                        text={RIGHT_ARM_POSITION[_position]}
                    />
                )}
            </OptionsWrapper>
            <OptionsWrapper
                title="Body"
                opened
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {Object.keys(BODY_CLOTHES).map((bodyWear) => {
                    return <Option
                        key={bodyWear}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.bodyClothes, value: bodyWear })}
                        active={hStateObj[Types.bodyClothes] === bodyWear}
                        text={BODY_CLOTHES[bodyWear].text}
                        disabled={
                            !Object.keys(BODY_CLOTHES[bodyWear] || {}).includes(state[Types.bodyPosition])
                            || (!state[Types.settingsSingleArm] && (!BODY_CLOTHES[bodyWear].allowWithoutSleeves &&
                            !BODY_CLOTHES[bodyWear][state[Types.bodyPosition]]
                                ?.bothArms?.[state[Types.bothArmsPosition]]))
                        }
                    />;
                })}
            </OptionsWrapper>
            <OptionsWrapper
                title="Both Arms"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {Object.keys(BOTH_ARMS_POSITION || {}).map((_position) => {
                    return <Option
                        key={_position}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.bothArmsPosition, value: _position })}
                        active={!hStateObj[Types.settingsSingleArm] && _position === hStateObj[Types.bothArmsPosition]}
                        text={BOTH_ARMS_POSITION?.[_position] || ''}
                        disabled={
                            !BODY_CLOTHES[state[Types.bodyClothes]]?.allowWithoutSleeves &&
                            !BODY_CLOTHES[state[Types.bodyClothes]]?.normal?.bothArms?.[_position]
                            || state[Types.bodyPosition] !== 'normal' && state[Types.bodyPosition] !== 'sitting'
                        }
                    />;
                })}
                {Object.keys(BOTH_ARMS_CYCLING_POSITION_1 || {}).map((_position) => {
                    return <Option
                        key={_position}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.cyclingArmsPosition1, value: _position })}
                        active={!bodyPositionIsNormal && _position === hStateObj[Types.cyclingArmsPosition1]}
                        text={BOTH_ARMS_CYCLING_POSITION_1?.[_position] || ''}
                        disabled={state[Types.bodyPosition] !== 'cycling1'}
                    />;
                })}
                {Object.keys(BOTH_ARMS_CYCLING_POSITION_2 || {}).map((_position) => {
                    return <Option
                        key={_position}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.cyclingArmsPosition2, value: _position })}
                        active={!bodyPositionIsNormal && _position === hStateObj[Types.cyclingArmsPosition2]}
                        text={BOTH_ARMS_CYCLING_POSITION_2?.[_position] || ''}
                        disabled={state[Types.bodyPosition] !== 'cycling2'}
                    />;
                })}
            </OptionsWrapper>
            <OptionsWrapper
                title="Emotion"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {EMOTIONS.map(([text]: [string, (() => JSX.Element)], index : number) => <Option
                    key={text}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.emotion, value: index })}
                    active={hStateObj[Types.emotion] === index}
                    text={text}
                />)}
            </OptionsWrapper>
            <OptionsWrapper
                title="Hair Color"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {HAIR_STYLE_COLOR.map((color : THairColor) => <Option
                    key={color}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.hairStyleColor, value: color })}
                    active={hStateObj[Types.hairStyleColor] === color}
                    text={color}
                />)}
            </OptionsWrapper>
            <OptionsWrapper
                title="Hair Style"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {HAIR_STYLE.map(([text]: THairStyle, index : number) => <Option
                    key={text}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.hairStyle, value: index })}
                    active={hStateObj[Types.hairStyle] === index}
                    text={text}
                />)}
            </OptionsWrapper>
            <OptionsWrapper
                title="Legs"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {Object.keys(LEGS || {}).map((id) => {
                    return <Option
                        key={id}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.legsClothes, value: id })}
                        active={hStateObj[Types.legsClothes] === id}
                        text={LEGS?.[id].name || ''}
                        disabled={BODY_CLOTHES[state[Types.bodyClothes]]?.disableLegsClothes ||
                            !Object.keys(LEGS?.[id] || {}).includes(state[Types.position])}
                    />;
                })}
            </OptionsWrapper>
            <OptionsWrapper
                title="Nose"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {NOSES.map(([text]: [string, TSkinEl], index : number) => <Option
                    key={text}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.nose, value: index })}
                    active={hStateObj[Types.nose] === index}
                    text={text}
                />)}
            </OptionsWrapper>
            <OptionsWrapper
                opened
                title="Pose"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {POSES && Object.keys(POSES).map((_position) => <Option
                    key={_position}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.position, value: _position as keyof TLegsSkins })}
                    active={_position === hStateObj[Types.position]}
                    text={POSES[_position]}
                />)}
            </OptionsWrapper>
            <OptionsWrapper
                title="Shoes"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {Object.keys(SHOES || {}).map((id) => {
                    return <Option
                        key={id}
                        onClick={() => push(hashString(state))}
                        onHover={() => dispatch({ type: Types.shoes, value: id })}
                        active={hStateObj[Types.shoes] === id}
                        text={SHOES[id] ? SHOES[id].name : ''}
                        disabled={!SHOES?.[id][state[Types.position] as keyof ILegs]}
                    />;
                })}
            </OptionsWrapper>
            <OptionsWrapper
                title="Skin"
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
            >
                {SKIN_COLOR.map(color => <Option
                    key={color}
                    onClick={() => push(hashString(state))}
                    onHover={() => dispatch({ type: Types.skin, value: color })}
                    active={hStateObj[Types.skin] === color}
                    text={color}
                />)}
            </OptionsWrapper>
            <Request />
        </Settings>

        <PicWrapper
            gender={state[Types.gender]}
            setState={(value: string) => dispatch({ type: 0, value })}
            historyIsEmpty={!history.length}
            historyUndo={() => {
                const _state = undo();
                if (_state) dispatch({ type: 0, value: _state });
            }}
        >
            {state[Types.settingsSingleArm] && bodyPositionIsNormal
                ? <LeftArmSkin color={state[Types.skin]}/>
                : BothArmsSkinLeft && <BothArmsSkinLeft color={state[Types.skin]}/>}
            {LeftArm ? <LeftArm /> : null}
            {Back ? <Back /> : null}
            <HeadSkin color={state[Types.skin]}/>
            <NoseComponent color={state[Types.skin]}/>
            <EmotionComponent/>
            <HaircutComponent color={state[Types.hairStyleColor]}/>
            {LegsSkinLeft ? <LegsSkinLeft color={state[Types.skin]}/> : null}
            {ShoesComponentL ? <ShoesComponentL /> : null}
            {LegsComponentL ? <LegsComponentL/> : null}
            {BodySkin ? <BodySkin color={state[Types.skin]}/> : null}
            {bodyPositionIsNormal ? null : <Bike position={state[Types.bodyPosition] === 'cycling2' ? 1 : 0}/>}
            {LegsSkinRight ? <LegsSkinRight color={state[Types.skin]}/> : null}
            {ShoesComponentR ? <ShoesComponentR /> : null}
            {LegsComponentR ? <LegsComponentR/> : null}
            {Front ? <Front/> : null}
            {Front2 ? <Front2/> : null}
            {(state[Types.settingsSingleArm] && bodyPositionIsNormal)
                ? <RightArmSkin color={state[Types.skin]}/>
                : BothArmsSkinRight && <BothArmsSkinRight color={state[Types.skin]}/>}
            {RightArm ? <RightArm /> : null}
        </PicWrapper>
    </div>;
}

export default CharacterBuilder;
