import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6823)"
            d="M494.769 223.399a2.718 2.718 0 01.821 2.483c-.805 4.666-13.807 10.892-16.406 12.093l-.912.421s-.585-24.988-5.356-31.349a108.207 108.207 0 0121.853 16.352z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6823)"
            d="M511.858 243.487c-3.466-4.38-7.051-8.91-10.445-13.525l-.076-.103a27.642 27.642 0 00-22.095-11.101 27.273 27.273 0 00-16.377 5.426 27.533 27.533 0 00-5.678 38.474c3.404 4.582 6.681 9.336 9.851 13.933l2.187 3.159 42.874-34.356.713-.699-.954-1.208z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6823"
                x1="485.626"
                x2="430.109"
                y1="214.969"
                y2="289.571"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6823"
                x1="495.906"
                x2="440.388"
                y1="222.62"
                y2="297.222"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
