import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' } : {color: TSkinColor}) => (
    <g id="man-bold-34-25-nose-1" transform="translate(329, 20)">
        <path
            fill={SKIN_COLOR[color]}
            d="M147.902 77.7c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M147.902 77.7c.2 5.9 2.5 11.6 5.7 16.5 1 1.5 2.1 3 2.2 4.8.1 1.8-1 3.7-2.6 4.6-2.5 1.3-5 1.9-6.9 2.2"
        />
    </g>
);

export default SvgComponent;
