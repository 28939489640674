import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={248}
            height={164}
            fill="none"
            id="object-food-quinoa"
            {...attributes}
        >
            <circle cx={32.781} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={55.627} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={78.473} cy={69.703} r={8.75} fill="#E4002B" />
            <circle cx={101.318} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={124.164} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={147.01} cy={69.703} r={8.75} fill="#E4002B" />
            <circle cx={169.855} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={192.701} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={215.547} cy={69.703} r={8.75} fill="#FFD100" />
            <circle cx={78.473} cy={28.703} r={8.75} fill="#FFD100" />
            <circle cx={101.318} cy={28.703} r={8.75} fill="#FFD100" />
            <circle cx={124.164} cy={28.703} r={8.75} fill="#FFD100" />
            <circle cx={147.01} cy={28.703} r={8.75} fill="#FFD100" />
            <circle cx={169.855} cy={28.703} r={8.75} fill="#E4002B" />
            <circle cx={44.531} cy={49.203} r={8.75} fill="#E4002B" />
            <circle cx={67.031} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={89.531} cy={49.203} r={8.75} fill="#E4002B" />
            <circle cx={112.031} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={134.531} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={157.031} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={179.531} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={202.031} cy={49.203} r={8.75} fill="#FFD100" />
            <circle cx={112.031} cy={9.203} r={8.75} fill="#E4002B" />
            <circle cx={134.531} cy={9.203} r={8.75} fill="#FFD100" />
            <path
                fill="#fff"
                d="M246.746 69.703c-1.671 7.077-4.404 14.202-7.924 21.207-2.697 5.37-5.86 10.669-9.368 15.825a169.936 169.936 0 0 1-8.939 11.91 158.323 158.323 0 0 1-4.905 5.728c-15.013 16.875-32.783 30.635-47.892 37.664H80.622c-15.12-7.029-32.879-20.789-47.892-37.664a159.187 159.187 0 0 1-4.905-5.728 170.406 170.406 0 0 1-8.939-11.91c-3.521-5.156-6.683-10.454-9.368-15.825-3.532-7.005-6.265-14.13-7.936-21.207h245.164Z"
            />
            <path
                fill="url(#object-food-quinoa-a)"
                d="M246.746 69.703c-9.082 38.368-49.205 78.467-79.028 92.335h-40.063c-29.835-13.867-69.946-53.966-79.04-92.335h198.131Z"
            />
            <path
                fill="#00B140"
                d="M238.822 90.91c-2.697 5.37-5.861 10.669-9.369 15.825H18.887c-3.52-5.156-6.683-10.454-9.367-15.825h229.302ZM220.515 118.645H27.826a158.323 158.323 0 0 0 4.905 5.728H215.61l4.905-5.728Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M203.345 136.931c19.87-18.515 37.715-43.21 43.404-67.224H1.586c9.088 38.365 49.204 78.469 79.034 92.332h87.096c6.171-2.868 12.782-6.859 19.468-11.725"
            />
            <defs>
                <linearGradient
                    id="object-food-quinoa-a"
                    x1={48.615}
                    x2={246.746}
                    y1={115.87}
                    y2={115.87}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#fff" />
                    <stop offset={0.994} stopColor="#D9D9D6" />
                </linearGradient>
            </defs>
        </svg>
    );
}
  
