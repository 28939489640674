import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11315_6573)"
            d="M420.496 237.342a814.321 814.321 0 00-4.821-3.06c-4.253-2.689-8.65-5.469-12.857-8.364l-.119-.082a27.5 27.5 0 10-30.944 45.47c4.204 2.86 8.371 5.943 12.4 8.924l1.778 1.314 35.507-43.598-.944-.604z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11315_6573"
                x1="362.49"
                x2="411.936"
                y1="242.196"
                y2="259.257"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
