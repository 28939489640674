import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-4-stand-1">
            <path
                d="M405.538 860.676a9.132 9.132 0 0 1-6.653-2.867 9.136 9.136 0 0 1-2.477-6.808c.914-15.623 1.226-25.837.88-28.76l32.733-.6c.107.167 9.482 16.793 16.996 21.852 3.342 2.25 9.955 5.864 13.601 7.817a4.985 4.985 0 0 1 2.471 5.602 4.981 4.981 0 0 1-4.828 3.764h-52.723Z"
                fill="#E2E2E2"
            />
            <path
                d="M430.562 824.594c1.544-.012 2.309.836 3.173 2.116 3.103 4.595 8.238 12.638 14.033 16.54 3.247 2.187 9.658 5.696 13.325 7.665a6.101 6.101 0 0 1 3.297 5.057 5.903 5.903 0 0 1-5.89 6.134h-54.467a10.46 10.46 0 0 1-7.404-2.918 9.963 9.963 0 0 1-2.955-7.725c.644-11.018.99-12.854 1.016-18.064.013-2.52.436-7.546 2.804-8.408 14.876 4.429 33.068-.397 33.068-.397Z"
                fill="#63666A"
            />
            <path
                d="M409.129 857.566c-5.969-2.42-12.757-9.857-15.083-12.55-.107 2.049-.226 4.198-.357 6.445a9.962 9.962 0 0 0 2.955 7.725 10.469 10.469 0 0 0 7.405 2.918H458.5a5.89 5.89 0 0 0 5.006-2.792c-14.505.113-47.441 1.066-54.377-1.746ZM520.514 860.433c-23.625 0-36.848-9.553-38.656-10.951l-24.54-5.177a9.086 9.086 0 0 1-5.888-4.158 9.095 9.095 0 0 1-1.014-7.137c4.053-14.853 5.732-21.89 5.99-24.768l35.675 5.693c.651.867 11.367 23.279 28.248 30.803 8.835 3.938 13.007 7.396 12.399 10.278-.705 3.341-5.385 5.417-12.214 5.417Z"
                fill="#E2E2E2"
            />
            <path
                d="M494.044 816.545a3.112 3.112 0 0 1 2.23 1.402c5.204 8.035 14.518 21.445 24.463 25.878 12.595 5.614 13.434 9.193 12.97 11.397-.82 3.889-5.753 6.21-13.193 6.21-15.966 0-27.28-4.328-33.597-7.638a60.056 60.056 0 0 0-15.488-5.491l-14.009-2.955a10.497 10.497 0 0 1-6.669-4.404 10.019 10.019 0 0 1-1.3-8.197c1.757-6.44 3.198-11.915 4.285-16.278a3.218 3.218 0 0 1 1.029-1.715 3.219 3.219 0 0 1 1.845-.773c4.328-.225 6.247 8.606 13.64 9.605 7.862 1.062 19.875-7.518 23.794-7.041Z"
                fill="#63666A"
            />
            <path
                d="M533.379 856.216a16.364 16.364 0 0 1-7.087 2.282c-10.203 1.36-34.319-8.132-45.488-11.927-10.148-3.448-15.542-12.723-30.208-18.055-.366 1.361-.746 2.766-1.145 4.228a10.019 10.019 0 0 0 1.3 8.198 10.502 10.502 0 0 0 6.669 4.403l14.009 2.956a59.981 59.981 0 0 1 15.488 5.491c6.318 3.31 17.631 7.638 33.597 7.638 6.777 0 11.462-1.931 12.865-5.214Z"
                fill="#E2E2E2"
            />
        </g>
    );
}
