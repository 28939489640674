import React from 'react';

const SvgComponent = () => {
    return <g id="man-34-25-shorts-2-walk-2">
        <path
            fill="#2B2729"
            d="M534.171 449.221c-1.507-7.244-3.428-15-5.493-22.203-6.867-25.217-32.879-41.693-58.61-37.091-27.596 4.089-47.717 32.42-42.494 59.822.882 5.478 2.433 11.952 3.457 17.43 2.612 11.819 5.471 16.492 8.577 28.214h102.036c-1.911-18.095-4.023-28.72-7.473-46.172Z"
        />
        <path
            fill="url(#man-34-25-shorts-2-walk-2-a)"
            d="M453.731 497.573a46.945 46.945 0 0 1 17.071-24.985c13.56-10.01 27.429-35.8 28.601-39.578 3.255-1.73 25.285-14.461 33.404-45.637l-.968-1.252H370.738l-.865.499a901.126 901.126 0 0 1-2.767 4.734c-9.149 15.582-15.33 26.123-15.083 48.174a62.665 62.665 0 0 0-.151 7.081l-.327 7.1c-.423 16.292-.499 32.56.004 48.796h100.775l1.407-4.932Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-shorts-2-walk-2-a"
                x1={215.443}
                x2={491.299}
                y1={498.576}
                y2={422.239}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#2B2729" />
                <stop offset={0.225} stopColor="#312E30" />
                <stop offset={0.564} stopColor="#434245" />
                <stop offset={0.972} stopColor="#5F6267" />
                <stop offset={0.999} stopColor="#616469" />
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
