import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="m475.685 232.67.065-.318a64.034 64.034 0 0 0 1.386-10.446 32.912 32.912 0 0 0-1.357-11.177l-.834-2.627 2.324 1.482a106.678 106.678 0 0 1 17.499 13.757 2.708 2.708 0 0 1 .821 2.482c-.804 4.662-13.806 10.891-16.404 12.092l-.912.422-2.588-5.667Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-10-left-a)"
        />
        <path
            d="M561.558 293.22a220.874 220.874 0 0 1-23.587-2.502c-7.383-1.146-14.888-2.57-22.947-4.353a175.532 175.532 0 0 1-22.379-6.302 767.48 767.48 0 0 1-6.564-2.353c-4.971-1.796-10.111-3.651-15.22-5.285a27.322 27.322 0 0 1-16.07-13.591 27.313 27.313 0 0 1-1.753-20.975 27.413 27.413 0 0 1 26.189-19.135c2.844.001 5.67.444 8.377 1.312l.129.041c5.101 1.658 10.355 3.154 15.436 4.599 2.24.637 4.477 1.279 6.714 1.927a168.578 168.578 0 0 1 12.783 4.274c-.192-.454-.382-.91-.568-1.368l-2.328-5.537a183.407 183.407 0 0 1-1.466-3.658c-.257-.654-.515-1.307-.774-1.96-2.21-5.631-3.923-10.26-5.391-14.573l-.495-1.452 1.529.134c.476.042.953.061 1.434.061 9.237-.001 18.715-7.22 22.52-11.772l.792-.947.762.971c2.601 3.318 5.307 6.939 8.518 11.395.415.584.83 1.161 1.246 1.737.763 1.06 1.526 2.119 2.264 3.193l3.4 4.964c1.558 2.226 3.057 4.536 4.507 6.77a456.918 456.918 0 0 0 2.132 3.268 238.564 238.564 0 0 1 12.148 20.768l5.461 10.664 5.58 10.581a19.378 19.378 0 0 1 1.38 14.862 19.421 19.421 0 0 1-9.533 11.484 26.002 26.002 0 0 1-14.226 2.758Z"
            fill="url(#female-34-25-doctors-coat-2-arm-opt-10-left-b)"
        />
        <defs>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-10-left-a"
                x1={488.254}
                y1={204.852}
                x2={463.647}
                y2={304.745}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
            <linearGradient
                id="female-34-25-doctors-coat-2-arm-opt-10-left-b"
                x1={451.719}
                y1={241.568}
                x2={586.198}
                y2={241.568}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.006} stopColor="#64CCC9" />
                <stop offset={1} stopColor="#BBE9E9" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
