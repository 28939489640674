import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';
const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-arm-left-option-6">
        <path
            d="M495.135 183.591c7.698 2.893 15.55 5.376 23.336 8.037a186.492 186.492 0 0 1 22.796 9.481c7.436 3.598 14.795 7.403 22.046 11.497a232.454 232.454 0 0 1 21.324 13.431 23.316 23.316 0 0 1 9.383 15.194c.49 3.025.379 6.117-.327 9.099a23.318 23.318 0 0 1-3.783 8.28 23.378 23.378 0 0 1-20.995 9.59 232.705 232.705 0 0 1-24.911-3.81c-8.161-1.653-16.215-3.594-24.191-5.744a186.577 186.577 0 0 1-23.44-7.759c-7.626-3.086-15.187-6.35-22.9-9.204a30.99 30.99 0 0 1-20.22-27.886 31 31 0 0 1 41.735-30.261l.147.055Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M576.711 268.139c-10.164.384-24.537-2.12-32.707-3.749-8.161-1.652-16.215-3.594-24.191-5.744a186.63 186.63 0 0 1-23.44-7.759c-7.626-3.086-15.187-6.35-22.9-9.204a31.006 31.006 0 0 1-18.316-39.831 30.998 30.998 0 0 1 39.832-18.316l.146.054c7.699 2.893 15.55 5.377 23.336 8.037"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m557.608 257.114-6.464-10.899-6.518-10.864c-4.325-7.257-8.425-14.653-12.396-22.13-1.957-3.757-3.964-7.482-5.867-11.272-1.933-3.772-3.855-7.55-5.693-11.381-1.851-3.822-3.745-7.618-5.562-11.461-1.761-3.88-3.606-7.705-5.28-11.639a15.69 15.69 0 0 1 8.298-20.582 15.73 15.73 0 0 1 17.989 4.15c2.803 3.227 5.434 6.563 8.149 9.845 2.66 3.317 5.242 6.683 7.867 10.022 2.639 3.33 5.192 6.713 7.736 10.103 2.574 3.371 5.042 6.808 7.561 10.212 4.982 6.846 9.835 13.772 14.463 20.839l6.911 10.619 6.964 10.585a22.498 22.498 0 0 1 3.241 16.918 22.492 22.492 0 0 1-9.671 14.254 22.498 22.498 0 0 1-16.918 3.241 22.51 22.51 0 0 1-14.81-10.56Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M536.115 151.039c2.804 3.227 5.434 6.563 8.15 9.845 2.659 3.317 5.241 6.683 7.866 10.022 2.639 3.33 5.193 6.713 7.737 10.103 2.573 3.371 5.042 6.808 7.561 10.212 4.982 6.846 9.834 13.772 14.462 20.838l6.911 10.62 6.965 10.585a22.515 22.515 0 0 1 3.24 16.918 22.5 22.5 0 0 1-9.671 14.254 22.403 22.403 0 0 1-12.625 3.706M551.142 246.216l-6.518-10.864c-4.324-7.257-8.425-14.653-12.395-22.131-1.957-3.756-3.965-7.481-5.868-11.271-1.933-3.772-3.855-7.55-5.692-11.381-1.852-3.822-3.746-7.618-5.563-11.462-1.76-3.879-3.606-7.705-5.28-11.638"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M533.586 116.875a11.931 11.931 0 0 0-6.254-4.732l-.106-.121a14.873 14.873 0 0 0-6.782-7.013 18.91 18.91 0 0 0-6.49-1.961 9.614 9.614 0 0 0-6.472 1.622 6.9 6.9 0 0 0-.79.679l-.914-.927a9.051 9.051 0 0 0-5.516-2.296 8.83 8.83 0 0 0-5.623 1.785c-.488 1.294-.009 3.48 1.214 4.125a9.188 9.188 0 0 0 2.748.927l.119.192a69.838 69.838 0 0 1 5.63 15.579c1.071 4.62 1.58 9.804-.952 13.813l-3.028 3.538c-2.825-.693-5.082-2.742-7.284-4.643-1.643-1.419-2.98-2.901-4.756-3.675a2.741 2.741 0 0 0-3.187.756l-.034.04a2.739 2.739 0 0 0-.086 3.418c4.535 6.004 8.891 12.122 13.462 18.103a50.691 50.691 0 0 0 5.717 6.646 58.17 58.17 0 0 0 7.78 5.949c6.949 4.32 22.23 7.714 25.231-8.212 2.513-13.334 4.007-31.932-3.627-43.592Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M500.354 142.084c-2.825-.692-5.082-2.742-7.283-4.642-1.644-1.419-2.98-2.902-4.757-3.676a2.746 2.746 0 0 0-3.186.757l-.035.04a2.742 2.742 0 0 0-.086 3.417c4.536 6.004 8.891 12.123 13.462 18.103a50.626 50.626 0 0 0 5.717 6.646 37.498 37.498 0 0 0 8.874 5.949M503.248 142.133a17 17 0 0 1 10.493 4.298M503.384 138.545c2.532-4.01 2.023-9.193.952-13.813a69.916 69.916 0 0 0-5.631-15.58M511.75 112.797a3.89 3.89 0 0 1 1.154-4.563 7.022 7.022 0 0 1 4.773-1.388 12.13 12.13 0 0 1 8.71 4.17M504.752 110.572a6.5 6.5 0 0 1 2.715-5.901 9.614 9.614 0 0 1 6.472-1.622 18.94 18.94 0 0 1 6.49 1.961M502.257 108.85a9.29 9.29 0 0 1-6.418-.813c-1.224-.646-1.703-2.832-1.215-4.126a8.832 8.832 0 0 1 5.623-1.785 9.049 9.049 0 0 1 5.516 2.296M537.2 160.464c1.962-13.798 4.007-31.931-3.628-43.591a11.94 11.94 0 0 0-9.49-5.299 8.003 8.003 0 0 0-4.071.863 5.258 5.258 0 0 0-2.058 2.004 2.202 2.202 0 0 0-.057 1.957 2.259 2.259 0 0 0 1.626 1.322c2.051.408 4.366.932 6.304 1.291.196 1.657.77 3.397.966 5.055M511.474 120.756a20.106 20.106 0 0 0-2.945-7.608M519.365 122.955l-1.115-5.639"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M500.12 186.384c-9.226-7.122-19.957-12.655-32.552-17.169l19.122 31.543s17.865-10.95 13.43-14.374Z"
            fill={SKIN_COLOR[color]}
        />
        <path
            d="M467.568 169.215a129.72 129.72 0 0 1 32.552 17.169"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </g>
);

export default SvgComponent;
