import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-jacket-arm-right-option-7">
        <path
            fill="url(#man-34-25-jacket-arm-right-option-7-a)"
            d="M437.483 355.734c-1.35-.242-2.7-.485-4.053-.717a568.952 568.952 0 0 1-7.86-1.554c-1.436-.292-2.872-.582-4.31-.868-4.152-.806-8.264-1.711-12.121-2.569-2.71-.579-5.452-1.222-8.104-1.844-1.312-.308-2.624-.614-3.938-.917-8.834-2.106-16.647-4.159-23.894-6.279l-11.807-3.493-11.819-3.431a23.417 23.417 0 0 1-16.932-23.578 23.302 23.302 0 0 1-.189-1.939 252.991 252.991 0 0 1 .304-26.184c.48-8.248 1.242-16.657 2.33-25.712a202.171 202.171 0 0 1 4.448-25.317c.604-2.503 1.224-5.005 1.845-7.507 1.407-5.672 2.862-11.537 4.083-17.341a32.127 32.127 0 0 1 31.284-25.418c2.224.002 4.443.234 6.62.693a31.778 31.778 0 0 1 20.212 13.831 31.79 31.79 0 0 1 4.513 24.072l-.032.149c-1.252 5.808-2.314 11.763-3.34 17.522-.452 2.531-.903 5.063-1.371 7.591a202.41 202.41 0 0 1-6.256 24.938c-2.697 8.711-5.427 16.7-8.346 24.419a354.072 354.072 0 0 1-1.186 3.07c7.008 2.397 14.422 5.159 22.649 8.434 1.247.508 2.491 1.01 3.731 1.507 2.535 1.02 5.158 2.074 7.723 3.167 3.639 1.513 7.523 3.138 11.389 4.869 1.323.587 2.65 1.17 3.98 1.75 2.453 1.075 4.907 2.15 7.35 3.261 1.246.591 2.481 1.165 3.717 1.738-6.047 8.502-10.818 24.008-10.62 33.657Z"
        />
        <defs>
            <linearGradient
                id="man-34-25-jacket-arm-right-option-7-a"
                x1={332.104}
                x2={432.184}
                y1={206.687}
                y2={347.373}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
