import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                fill="#004F71"
                d="M577.529 670.211l8.854 31.46h5.632s-.091-6.348-.307-10.701c-.15-3.018.508-7.643 3.907-7.552"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M586.383 701.672l5.632.125"
            ></path>
            <path
                fill="#004F71"
                d="M639.184 707.106c-3.712.4-7.462.263-11.135-.407l-5.404-.985a24.336 24.336 0 01-14.76-8.883l-6.464-8.202c-2.677-3.396-5.046-4.047-9.137-5.442l-5.799-1.977a13.895 13.895 0 01-9.085-16.261l3.844-16.941s8.387 3.898 16.513 17.318c1.578 2.605 8.927 15.114 11.164 18.665 4.797 7.613 13.914 9.894 22.572 9.777 6.512-.088 11.7-4.609 11.7-4.609l9.32 4.542a6.434 6.434 0 013.58 6.88 6.432 6.432 0 01-5.646 5.318l-11.263 1.207z"
            ></path>
            <path
                stroke="#231F20"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M654.501 704.114a6.412 6.412 0 01-3.946 1.951l-11.264 1.208c-3.711.4-20.215 3.139-31.298-10.275l-6.464-8.202c-2.677-3.396-5.046-4.047-9.137-5.443l-5.799-1.976a15.923 15.923 0 01-8.098-7.213"
            ></path>
        </g>
    );
}
