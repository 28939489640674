import React from 'react';

const SvgComponent = () => (
    <g>
        <g clipPath="url(#clip0_10364_9233)">
            <path
                fill="url(#paint0_linear_10364_9233)"
                d="M411.154 241.252a27.467 27.467 0 00-14.466-15.293 27.47 27.47 0 00-21.044-.566 27.5 27.5 0 00-15.86 35.51 78.166 78.166 0 012.711 8.407c.557 2.037 1.133 4.142 2.254 7.373l.434 1.249 1.084-.758c12.678-8.857 37.279-17.368 50.619-17.513l1.854-.068-7.586-18.341z"
            ></path>
        </g>
        <defs>
            <linearGradient
                id="paint0_linear_10364_9233"
                x1="357.954"
                x2="432.053"
                y1="250.751"
                y2="250.751"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <clipPath id="clip0_10364_9233">
                <path
                    fill="#fff"
                    d="M0 0H900V900H0z"
                    transform="translate(.004 .375)"
                ></path>
            </clipPath>
        </defs>
    </g>
);

export default SvgComponent;
