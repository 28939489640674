import React from 'react';
import { SVG } from 'components/objects';

export default function SvgComponent({ ...attributes } : SVG) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={29}
            height={30}
            fill="none"
            id="object-food-cranberry"
            {...attributes}
        >
            <path
                fill="#E4002B"
                d="M14.309 29.32c7.874 0 14.258-6.383 14.258-14.257 0-7.875-6.384-14.258-14.258-14.258C6.434.805.05 7.188.05 15.063c0 7.874 6.383 14.258 14.258 14.258Z"
            />
            <path
                stroke="#222731"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m23.508 7.529 2.937-2.937"
            />
        </svg>
    );
}
