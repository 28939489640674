import React from 'react';

export default function SvgComponent() {
    return (
        <g id="female-34-25-shoes-3-stand-2">
            <path
                d="M372.819 853.188c-17.066-16.337-20.012-32.38-20.352-34.641l-14.147-20.709a9.026 9.026 0 0 1-1.378-7.079 9.012 9.012 0 0 1 4.202-5.853c13.199-7.927 19.278-11.85 21.455-13.75l21.834 28.782c-.13 1.076-7.886 24.676-.894 41.785 3.659 8.953 4.281 14.336 1.85 15.997-2.82 1.927-7.636.19-12.57-4.532Z"
                fill="#E2E2E2"
            />
            <path
                d="M384.046 803.177c.287.353.494.765.605 1.206.11.442.123.902.036 1.349-1.797 9.403-4.342 25.531-.223 35.61 5.217 12.765 3.348 15.931 1.488 17.201-3.281 2.242-8.45.509-13.824-4.636-11.534-11.04-16.714-21.99-18.988-28.75a60.005 60.005 0 0 0-7.392-14.676l-8.076-11.822a10.498 10.498 0 0 1-1.772-7.793 10.002 10.002 0 0 1 4.73-6.82c5.722-3.437 10.549-6.397 14.351-8.797a3.215 3.215 0 0 1 3.797.19c3.281 2.83-1.438 10.537 3.211 16.37 4.945 6.204 19.555 8.313 22.057 11.368Z"
                fill="#001489"
            />
            <path
                d="M385.027 859.035a16.367 16.367 0 0 1-6.698-3.252c-8.31-6.073-19.168-29.606-24.612-40.071-4.946-9.508-2.43-19.938-9.336-33.931-1.206.73-2.452 1.483-3.751 2.263a9.997 9.997 0 0 0-4.73 6.821c-.46 2.729.177 5.53 1.772 7.792l8.076 11.822a60.047 60.047 0 0 1 7.392 14.677c2.275 6.759 7.454 17.709 18.988 28.749 4.896 4.687 9.616 6.532 12.899 5.13ZM407.733 859.16a9.046 9.046 0 0 1-6.655-2.87 9.048 9.048 0 0 1-2.474-6.804c.913-15.624 1.226-25.838.88-28.76l32.733-.601c.107.167 9.482 16.793 16.996 21.852 3.341 2.25 9.955 5.864 13.601 7.818a4.968 4.968 0 0 1 2.622 4.386 4.985 4.985 0 0 1-4.979 4.98l-52.724-.001Z"
                fill="#E2E2E2"
            />
            <path
                d="M432.755 823.078c1.544-.012 2.31.836 3.174 2.116 3.103 4.595 8.237 12.638 14.032 16.54 3.247 2.187 9.658 5.697 13.326 7.665a6.106 6.106 0 0 1 3.296 5.057 5.889 5.889 0 0 1-1.64 4.32 5.897 5.897 0 0 1-4.25 1.815h-54.467a10.466 10.466 0 0 1-7.404-2.918 9.967 9.967 0 0 1-2.955-7.725c.645-11.018.99-12.854 1.017-18.065.012-2.52.436-7.545 2.803-8.408 14.876 4.43 33.068-.397 33.068-.397Z"
                fill="url(#female-34-25-shoes-3-stand-2-a)"
            />
            <path
                d="M411.327 856.05c-5.969-2.419-12.757-9.857-15.084-12.55-.107 2.05-.225 4.198-.356 6.445a9.953 9.953 0 0 0 2.955 7.725 10.46 10.46 0 0 0 7.404 2.918h54.451a5.9 5.9 0 0 0 5.006-2.792c-14.505.113-47.44 1.066-54.376-1.746Z"
                fill="#E2E2E2"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-3-stand-2-a"
                    x1={431.218}
                    y1={823.078}
                    x2={466.588}
                    y2={860.594}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#001489" />
                    <stop offset={1} stopColor="#013EA2" />
                </linearGradient>
            </defs>
        </g>
    );
}
