import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7157)"
            d="M476.742 231.748a64.86 64.86 0 001.406-10.604 33.85 33.85 0 00-1.403-11.519 105.903 105.903 0 0117.336 13.629 1.706 1.706 0 01.535 1.598c-.522 3.029-8.331 7.885-15.838 11.354l-2.036-4.458z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7157)"
            d="M586.12 300.463c-2.391-1.756-5.027-3.522-8.064-5.403-.277-.168-.553-.339-.829-.51l-.216-.133a62.835 62.835 0 00-2.043-1.231l-3.076-1.739c-1.403-.827-2.845-1.565-4.261-2.29-.682-.349-1.364-.698-2.051-1.062a82.794 82.794 0 00-13.155-5.468 484.344 484.344 0 00-4.047-1.252 863.533 863.533 0 01-3.563-1.1c-2.17-3.892-4.68-7.911-7.895-12.641-3.464-5.054-7.204-10.061-11.432-15.306a117.047 117.047 0 00-12.936-13.94 476.08 476.08 0 00-4.067-3.691l-.195-.176c-3.17-2.861-6.448-5.819-9.525-8.861l-.121-.121a27.602 27.602 0 00-19.388-7.898A27.549 27.549 0 00459.402 226a27.58 27.58 0 00-7.896 19.717 27.568 27.568 0 008.357 19.526c3.114 3.04 6.143 6.284 9.072 9.42a613.688 613.688 0 003.889 4.138c4.366 4.608 9.1 8.851 14.155 12.689 5.32 4.14 10.395 7.796 15.515 11.177a151.347 151.347 0 0016.941 9.813c.575.274 1.172.523 1.826.76a19.775 19.775 0 004.582 2.346c3.034 1.07 6.079 2.319 9.061 3.542 1.308.536 2.615 1.073 3.928 1.597a82.918 82.918 0 0013.655 4.094c.747.15 1.492.312 2.237.473 1.538.332 3.129.676 4.739.924l3.509.606c.797.134 1.619.242 2.342.337l.213.028c.34.045.681.09 1.03.14 1.724.227 3.555.432 5.443.611l1.608.15-.257-1.597a24.958 24.958 0 012.127-12.632 23.115 23.115 0 0110.408-11.248l1.967-.876-1.733-1.272z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7157"
                x1="484.743"
                x2="435.586"
                y1="218.842"
                y2="291.131"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7157"
                x1="533.344"
                x2="484.188"
                y1="251.892"
                y2="324.181"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#FFD100"></stop>
                <stop offset="1" stopColor="#FFF4C3"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
