import React from 'react';

export default function SvgComponent() {
    return (
        <g>
            <path
                d="m449.354 831.797 5.422 32.229 5.65.144s.542-5.86.795-10.212c.174-3.016 1.326-7.543 4.696-7.087"
                fill="#EF0025"
            />
            <path
                d="M506.689 875.089a38.866 38.866 0 0 1-11.026-1.6l-5.267-1.56a24.331 24.331 0 0 1-13.72-10.417l-5.546-8.849c-2.297-3.664-4.582-4.565-8.5-6.393l-5.553-2.587a13.9 13.9 0 0 1-7.286-17.143l5.641-16.431s7.92 4.777 14.558 18.992c1.288 2.76 7.251 15.986 9.094 19.757 3.952 8.084 12.771 11.331 21.392 12.144 6.483.612 12.126-3.325 12.126-3.325l8.779 5.516a6.425 6.425 0 0 1 2.82 7.225 6.426 6.426 0 0 1-6.183 4.681l-11.329-.01Z"
                fill="#EF0025"
            />
            <path
                d="M522.15 873.589a6.423 6.423 0 0 1-4.133 1.517l-11.329-.01c-3.733-.001-20.436.949-30.014-13.577l-5.546-8.849c-2.297-3.664-4.582-4.566-8.5-6.393l-5.553-2.588a15.92 15.92 0 0 1-7.276-8.041M454.775 864.023l5.65.144"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="m395.91 843.219 8.096 32.58 7.112.302-.651-10.954"
                fill="#EF0025"
            />
            <path
                d="M425.853 876.871a15.036 15.036 0 0 1-12.214-6.062l-8.739-12.081a28.019 28.019 0 0 0-3.597-4.073 20.63 20.63 0 0 1-6.369-19.306l2.115-9.779 15.467 22.586c3.965 5.907 5.619 7.118 12.455 9.059 2.927.831 9.362.63 13.554-.362a14.295 14.295 0 0 0 7.427-4.554l11.586 10.137a8.284 8.284 0 0 1 2.584 6.195 8.766 8.766 0 0 1-9.05 8.24h-25.219Z"
                fill="url(#female-34-25-shoes-7-stand-1-a)"
            />
            <path
                d="M457.575 874.31a9.328 9.328 0 0 1-6.503 2.561h-25.219a15.041 15.041 0 0 1-12.215-6.063s-15.174-19.201-16.206-20.933M404.006 875.797h7.112"
                stroke="#231F20"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <defs>
                <linearGradient
                    id="female-34-25-shoes-7-stand-1-a"
                    x1={394.522}
                    y1={851.221}
                    x2={460.126}
                    y2={851.221}
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#F64E2E" />
                    <stop offset={0.37} stopColor="#F86431" />
                    <stop offset={1} stopColor="#FB8735" />
                </linearGradient>
            </defs>
        </g>
    );
}
