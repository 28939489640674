import * as React from 'react';
import { TSkinColor } from 'interfaces';

const SKIN_COLOR: Record<TSkinColor, [string, string, string, string, string]> = {
    white: ['#FFDBAE', '#FBD7AA', '#EECA9E', '#D8B68B', '#CDAB81'],
    black: ['#6C453A', '#684338', '#5B3C32', '#5B3C32', '#4D352C'],
    brown: ['#CB865E', '#C7835C', '#BA7B57', '#A46E4F', '#9A684B'],
    yellow: ['#FCB47F', '#F8B07C', '#EBA473', '#D58F65', '#CB865E'],
};

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g id="man-bold-34-25-head" transform="translate(329, 20)">
        <path
            fill={SKIN_COLOR[color][0]}
            d="M154.103 47.5c4.4 10.2 7.6 24.2 7.6 35.3 0 11.1-1.9 22.2-4.7 32.9-1.6 6.1-2.7 12.9-6.2 18-2.5 3.7-7.8 5.2-12.3 4.8-.7-.1-1.3-.1-2-.2.4 1.3-.5 4.4-.5 5.1l-7.3 13.4-47.5-16s4.1-17.1 4.1-23.7c0-9.5-7-19.2-9.5-26.8-8.1-25 3.1-37.8 3.1-37.8s2.8-11.1 18.5-18c22.4-9.6 41-2.6 56.7 13Z"
        />
        <path
            fill="url(#man-bold-34-25-head-a)"
            d="M136.704 138c-14.5 1.2-30.2-11.6-36.6-18.4-1.2-1.3 9.8 29.4 10.3 31.2.6 1.7 23.6 6.5 24.6 8 4.1 5.8 2.1-12 1.7-20.8Z"
        />
        <path
            fill="url(#man-bold-34-25-head-b)"
            d="M160.904 71.8c-2-.2-4-.4-6-.7-2-.2-4.1-.4-5.7.8-1.2.9-1.8 2.4-1.9 3.8-.1 1.5-.4 3-.1 4.4 2.2 12.9 1.5 21.9-.9 24.5-.5.6-1 1.3-1.1 2-.1.7.1 1.5.2 2.3.9 4.2 1.8 8.5 2.7 12.7.5 2.3.8 4.7 1.1 7 .4 3.9-.6 7.3-4.6 9.4 6.6-1.3 13.7-7.7 15.2-10.5 9.5-18-.8-37.2 1.1-55.7Z"
        />
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeMiterlimit={10}
            strokeWidth={2}
            d="M100.102 118.9c9.3 11.5 23.7 18.3 38.4 19.7 5.6.6 13.7-1.9 18.2-6.6 7.2-7.7 9.7-22.7 6.1-37-1.2-4.9-2.2-7.5-2.1-11.3 0-2.9.8-6.7.4-9.8-1.1-9-1.3-18.7-8.9-27.4-25.7-29.5-60.1-12.6-69-1.5-8.2 10.2-12.1 23.8-9.7 36.6 1.6 8.7 5.8 16.7 9.5 24.7 1.7 3.7 4.1 16.1-1.4 32.4"
        />
        <defs>
            <linearGradient
                id="man-bold-34-25-head-a"
                x1={115.203}
                x2={122.322}
                y1={150.486}
                y2={126.938}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.184} stopColor={SKIN_COLOR[color][0]}/>
                <stop offset={0.407} stopColor={SKIN_COLOR[color][1]}/>
                <stop offset={0.648} stopColor={SKIN_COLOR[color][2]}/>
                <stop offset={0.897} stopColor={SKIN_COLOR[color][3]}/>
                <stop offset={0.999} stopColor={SKIN_COLOR[color][4]}/>
            </linearGradient>
            <linearGradient
                id="man-bold-34-25-head-b"
                x1={154.269}
                x2={154.269}
                y1={138.195}
                y2={70.998}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.068} stopColor={SKIN_COLOR[color][0]}/>
                <stop offset={0.322} stopColor={SKIN_COLOR[color][1]}/>
                <stop offset={0.598} stopColor={SKIN_COLOR[color][2]}/>
                <stop offset={0.883} stopColor={SKIN_COLOR[color][3]}/>
                <stop offset={0.999} stopColor={SKIN_COLOR[color][4]}/>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
