import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-both-arms-option-1-right">
        <path
            d="M583.427 285.487a23.54 23.54 0 0 1-22.303-16.02l-2.101-6.238c-.271-.786-.55-1.576-.828-2.367a218.36 218.36 0 0 1-1.331-3.847c-.232-.742-.47-1.462-.709-2.182-.446-1.349-.908-2.742-1.284-4.159a79.624 79.624 0 0 1-.86-3.204 82.498 82.498 0 0 1-.786-3.249c-1.206-5.179-2.048-9.217-2.729-13.085 19.038 10.177 34.653-4.28 36.483-14.128 2.076 3.275 4.238 6.934 6.8 11.511a79.991 79.991 0 0 1 1.609 2.931 79.319 79.319 0 0 1 1.531 2.963c.663 1.273 1.26 2.613 1.838 3.909.31.694.619 1.387.94 2.076a212.68 212.68 0 0 1 1.617 3.758c.327.773.655 1.544.985 2.314l2.647 6.013a23.355 23.355 0 0 1 .394 18 23.356 23.356 0 0 1-12.449 13.005 23.336 23.336 0 0 1-9.464 1.999Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-right-a)"
        />
        <path
            d="M586.129 217.535c2.391 3.772 4.615 7.609 6.777 11.47a84.85 84.85 0 0 1 1.594 2.906 77.664 77.664 0 0 1 1.518 2.936c1.016 1.955 1.844 3.984 2.773 5.973.891 2.004 1.721 4.032 2.59 6.045l2.65 6.021a22.521 22.521 0 0 1-11.543 29.687 22.425 22.425 0 0 1-13.076 1.551M559.971 262.909c-.714-2.072-1.467-4.13-2.159-6.211-.654-2.096-1.409-4.152-1.976-6.282a79.261 79.261 0 0 1-.856-3.192 77.95 77.95 0 0 1-.78-3.222c-1.003-4.31-1.945-8.643-2.72-13.041"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M551.6 231.702c17.799 8.695 32.384-3.953 35.103-13.522"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M388.94 291.543a23.462 23.462 0 0 1-18.011-8.389 23.379 23.379 0 0 1-4.098-7.161 25.593 25.593 0 0 1-1.997-2.401 89.573 89.573 0 0 1-7.73-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053c-.198-1.457-.377-2.921-.557-4.385-.391-3.194-.795-6.498-1.407-9.689a32.04 32.04 0 0 1 25.401-37.455 32.266 32.266 0 0 1 6.06-.578 32.023 32.023 0 0 1 31.395 25.98l.065.352c.582 3.262 1.387 6.495 2.166 9.621.355 1.428.71 2.856 1.047 4.287l.003.016a760.566 760.566 0 0 1 6.777-4.826 889.702 889.702 0 0 0 3.715-2.587c2.514-1.756 5.113-3.571 7.708-5.316 3.674-2.508 7.599-5.175 11.55-7.737 1.367-.893 2.733-1.791 4.099-2.692a706.18 706.18 0 0 1 7.537-4.92 638.292 638.292 0 0 0 3.878-2.434c1.077-.68 2.17-1.368 3.272-2.056-.426 12.123 9.333 24.973 18.868 29.554a1103.592 1103.592 0 0 0-3.216 3.272 721.92 721.92 0 0 1-6.444 6.326 2262.805 2262.805 0 0 0-3.502 3.417c-3.36 3.319-6.802 6.573-10.04 9.622-2.248 2.149-4.572 4.291-6.82 6.362a1968.237 1968.237 0 0 0-3.34 3.089c-7.522 6.891-14.336 12.881-20.837 18.32l-10.638 8.851-10.592 8.895a23.527 23.527 0 0 1-15.104 5.506Z"
            fill="url(#man-34-25-shirt-1-both-arms-option-1-right-b)"
        />
        <path
            d="m408.789 206.62.065.353c.582 3.261 1.387 6.495 2.166 9.621.355 1.428.71 2.855 1.046 4.287l.004.015a781.55 781.55 0 0 1 6.777-4.826 733.016 733.016 0 0 0 3.715-2.587c2.514-1.756 5.113-3.571 7.708-5.315 3.674-2.509 7.599-5.176 11.55-7.737a1107.88 1107.88 0 0 0 4.098-2.693c2.505-1.65 5.01-3.3 7.538-4.92 1.305-.81 2.592-1.622 3.878-2.433 1.077-.68 2.169-1.369 3.272-2.057-.426 12.124 9.333 24.973 18.868 29.555a1103.229 1103.229 0 0 0-3.216 3.271 683.387 683.387 0 0 1-6.445 6.326 2820.727 2820.727 0 0 0-3.501 3.417c-3.36 3.32-6.802 6.573-10.04 9.622-2.248 2.149-4.573 4.291-6.82 6.363a1627.39 1627.39 0 0 0-3.34 3.089c-7.522 6.89-14.336 12.88-20.838 18.319l-10.637 8.852-10.593 8.894a23.525 23.525 0 0 1-25.042 3.312 23.46 23.46 0 0 1-8.073-6.195 23.358 23.358 0 0 1-4.098-7.161 25.76 25.76 0 0 1-1.998-2.401 89.656 89.656 0 0 1-7.729-13.229 136.742 136.742 0 0 1-5.655-13.562 64.56 64.56 0 0 1-3.553-14.053c-.198-1.457-.377-2.921-.557-4.385-.391-3.194-.796-6.498-1.407-9.689"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m385.881 240.894 10.926-8.488c7.299-5.639 14.764-11.072 22.325-16.386"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-right-a"
                x1={550.496}
                y1={251.248}
                x2={606.952}
                y2={251.248}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-both-arms-option-1-right-b"
                x1={486.255}
                y1={296.367}
                x2={276.761}
                y2={116.8}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
