import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6797)"
            d="M494.768 222.603a109.645 109.645 0 00-20.026-15.33 31.04 31.04 0 012.394 13.895 65.926 65.926 0 01-1.451 10.763l2.169 4.754a1.002 1.002 0 001.33.493c2.598-1.201 15.601-7.427 16.405-12.093a2.701 2.701 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6797)"
            d="M461.422 278.748a.997.997 0 01-.749-.338 1.014 1.014 0 01-.203-.355l-.208-.639c-.284-.877-.569-1.753-.831-2.637-.619-2.07-1.224-4.147-1.83-6.224-1.379-4.732-2.805-9.626-4.395-14.366a27.304 27.304 0 011.453-20.998 27.315 27.315 0 0115.874-13.82 27.33 27.33 0 0120.997 1.453 27.315 27.315 0 0113.82 15.875l.046.136.002.006c1.564 4.751 3.351 9.527 5.08 14.147.714 1.907 1.424 3.816 2.129 5.727a.998.998 0 01-.558 1.269l-50.247 20.688a.995.995 0 01-.38.076z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6797"
                x1="495.284"
                x2="425.019"
                y1="211.545"
                y2="253.617"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6797"
                x1="506.026"
                x2="435.761"
                y1="229.484"
                y2="271.556"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
