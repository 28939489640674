import React from 'react';
import shorts12Options from './shorts-1-2-options';

const SvgComponent = ({ option = 0 }: { option?: number}) => {
    return <g id="man-34-25-shorts-1-walk-1">
        <path
            fill={shorts12Options[option].fillColor}
            d="M522.904 459.4c1.2-7.4 10.2-71.699 10.2-71.699s-114.4 27.1-114.7 55.9c-1.2 10.9-1.9 22.499-2.4 33.399-1.5 27.2-.4 45.1.9 72.5h91.8c.7-2.2 12.7-80.6 14.2-90.1Z"
        />
        <path
            fill={`url(#${shorts12Options[option].walk2})`}
            d="M478.904 500.8c-.4-14 .4-26.699 8.1-37.599 9.1-12.7 11.3-30.301 11.3-30.301s24.6-11.699 33.3-45.199h-161.1c-9.1 15.8-16 25.7-17.4 44-1.4 7.4-1.1 15.1.6 22.5.1.8.2 1.6.3 2.5.2 2.1 1 3.999 2.1 5.599.4 1.2.9 2.4 1.4 3.6 3.7 10.8 7.9 21.901 12.2 32.301 8.2 20.7 12.2 40.499 18.5 60.799h95.7c-1.6-19.7-4.9-54.8-5-58.2Z"
        />
        <defs>
            <linearGradient
                id={shorts12Options[0].walk2}
                x1={352.222}
                x2={531.62}
                y1={473.426}
                y2={473.426}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#FFB91D"/>
                <stop offset={0.186} stopColor="#FFC02E"/>
                <stop offset={0.518} stopColor="#FFCD4D"/>
                <stop offset={0.801} stopColor="#FFD45F"/>
                <stop offset={1} stopColor="#FFD766"/>
            </linearGradient>
            <linearGradient
                id={shorts12Options[1].walk2}
                x1={352.481}
                x2={531.879}
                y1={473.426}
                y2={473.426}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.029} stopColor="#001489"/>
                <stop offset={0.389} stopColor="#002E98"/>
                <stop offset={0.748} stopColor="#0040A3"/>
                <stop offset={1} stopColor="#0047A7"/>
            </linearGradient>
        </defs>
    </g>;
};

export default SvgComponent;
