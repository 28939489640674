import React, { SVGProps } from 'react';

export default function SvgComponent(attributes? : SVGProps<SVGSVGElement>) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={2144}
            height={1005}
            viewBox="0 0 2144 1005"
            fill="none"
            id="scene-kitchen-4"
            {...attributes}
        >
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M351.68 370.298h-46.95v-309.9h436.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M612.379 23.95v373.949h-336.3V33.849h305.55V370.3h-178.95"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="m402.681 370.6 13.5-40.5h-11.55c11.85-8.1 18.15-19.35 18.15-19.35s-16.35-1.5-31.5 6.6c-.75.45-1.35.75-2.1 1.2 3.6-5.55 6.9-12 9.3-19.05 9.15-27.75.3-55.5.3-55.5s-23.85 17.1-33 44.7c-.9 2.85-1.65 5.85-2.25 8.7-10.8-17.4-27.75-26.55-27.75-26.55s-4.35 23.25 5.85 44.4c2.7 5.85 6.3 10.8 10.05 15.15h-13.5l13.5 40.5M1375.35 1.602h-192v133.5h-138l-92.998 58.5h304.498"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1048.35 1.602v112.5h117l115.5 79.5v36H938.852v-34.5M73.102 432.102H1741.1l55.5 40.5H321.102v19.5H1805.6v28.5h-433v105M1392.11 546.102v40.5M1356.11 546.102v40.5"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M813.695 303.296v-28.329h44.666c11.581 0 20.967-9.387 20.967-20.967H776.539v20.967h16.187v43.032h27.917"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m820.648 317.997 4.309-14.704h55.033l12.95 106.834h-99.28l17.98-61.374"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M859.787 389.542c6.63 0 12.005-5.375 12.005-12.005 0-6.631-5.375-12.006-12.005-12.006-6.631 0-12.006 5.375-12.006 12.006 0 6.63 5.375 12.005 12.006 12.005Z"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M859.787 365.536v-32.832h-79.741v7.726h-20.968v-44.86h20.968v7.727h33.649M328.13 800.5l-51.54 193h14l60-176h79.5l6.5 176h14l6.5-176h110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m417.07 800.5-22.48 193h-14l8.224-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M559.877 787.814c-5.457-6.953-13.889-6.311-38.735-6.311-18.602 0-132.667.36-197.916.648-18.81.083-33.972-15.38-33.525-34.185l1.863-78.347c.637-26.787 21.22-48.985 47.912-51.334 10.424-.917 22.004-1.346 34.113-.782a240.258 240.258 0 0 1 40.629 5.39 52.823 52.823 0 0 1 39.3 36.775l46.071 157.835 54 176h14l-37.725-161.942M388.813 800.5h-60.684M567.59 800.5H417.07M1444.05 800.5l51.54 193h-14l-60-176h-79.5l-6.5 176h-14l-6.5-176h-110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1355.11 800.5 22.48 193h14l-8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1212.3 787.814c5.46-6.953 13.89-6.311 38.74-6.311 18.6 0 132.66.36 197.91.648 18.81.083 33.98-15.38 33.53-34.185l-1.86-78.347c-.64-26.787-21.22-48.985-47.92-51.334-10.42-.917-22-1.346-34.11-.782a240.3 240.3 0 0 0-40.63 5.39 52.837 52.837 0 0 0-39.3 36.775l-46.07 157.835-54 176h-14l37.73-161.942M1383.37 800.5h60.68M1204.59 800.5h150.52M1810.05 800.5l51.54 193h-14l-60-176h-79.5l-6.5 176h-14l-6.5-176h-110.5v-17"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="m1721.11 800.5 22.48 193h14l-8.22-193"
            />
            <path
                stroke="#FFD201"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M1578.3 787.814c5.46-6.953 13.89-6.311 38.74-6.311 18.6 0 132.66.36 197.91.648 18.81.083 33.98-15.38 33.53-34.185l-1.86-78.347c-.64-26.787-21.22-48.985-47.92-51.334-10.42-.917-22-1.346-34.11-.782a240.3 240.3 0 0 0-40.63 5.39 52.837 52.837 0 0 0-39.3 36.775l-46.07 157.835-54 176h-14l37.73-161.942M1749.37 800.5h60.68M1570.59 800.5h150.52"
            />
            <path
                stroke="#FFD100"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeMiterlimit={10}
                strokeWidth={2}
                d="M1.051 1004H2142.05"
            />
        </svg>
    );
}

