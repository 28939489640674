import React from 'react';
import { TSkinColor } from 'interfaces';
import SKIN_COLOR from 'components/parts/SkinColor';

const SvgComponent = ({ color = 'white' }: { color: TSkinColor }) => (
    <g>
        <path
            fill={SKIN_COLOR[color]}
            d="M589.469 476.174s12.055-7.896 22.822-11.937l3.209-1.203a32.09 32.09 0 0020.668-27.342 32.088 32.088 0 00-15.817-30.406 269.982 269.982 0 00-28.704-14.19 933.709 933.709 0 00-14.496-6.046c-4.837-1.975-9.675-3.944-14.561-5.567a212.898 212.898 0 00-29.651-7.265c-2.494-.432-4.989-.86-7.499-1.181-2.509-.333-5.008-.729-7.532-.945l-3.779-.383-3.796-.254c-2.532-.152-5.075-.232-7.636-.175a53.036 53.036 0 00-51.311 45.573 53.033 53.033 0 0036.619 58.041l.504.158a108.83 108.83 0 0013.888 3.313h61.212c.362-.064 25.499-.126 25.86-.191z"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M581.338 633.901a779.08 779.08 0 01-2.89-25.423c-.41-4.231-.907-8.47-1.247-12.693l-1.081-12.677c-.749-8.455-1.361-16.896-2.352-25.373-.92-8.471-2.199-16.975-2.761-25.411-1.39-16.899.827-33.455 3.634-49.956 1.496-8.241 3.341-16.45 5.67-24.612.555-2.044 1.23-4.076 1.839-6.114.687-2.03 1.326-4.066 2.08-6.09a116.825 116.825 0 015.042-12.097l5.043-10.305a26.343 26.343 0 0149.712 15.49l-7.445 49.595-3.734 24.796-3.874 24.783c-2.605 16.52-5.112 33.049-7.798 49.561l-7.981 49.543a15.939 15.939 0 01-5.487 9.669 15.939 15.939 0 01-20.835-.301 15.94 15.94 0 01-5.206-9.823l-.329-2.562z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M629.556 411.068c-12.382-8.03-28.268-15.783-37.909-19.972a933.709 933.709 0 00-14.496-6.046c-4.837-1.975-9.675-3.944-14.561-5.567a212.898 212.898 0 00-29.651-7.265c-2.494-.432-4.989-.86-7.499-1.181-2.509-.333-5.008-.729-7.532-.945l-3.779-.383-3.796-.254c-2.532-.152-5.075-.232-7.636-.175a53.036 53.036 0 00-51.311 45.573 53.033 53.033 0 0036.619 58.041l.504.158a108.83 108.83 0 0013.888 3.313h61.212c.17-.03 5.797-.06 11.759-.089M581.668 636.466l-.33-2.562a779.093 779.093 0 01-2.891-25.423c-.41-4.231-.907-8.47-1.246-12.693l-1.082-12.677c-.749-8.455-1.36-16.896-2.352-25.373-.919-8.471-2.199-16.975-2.76-25.411-1.39-16.899.826-33.455 3.633-49.956.127-.695.255-1.391.387-2.086M629.556 411.07a26.397 26.397 0 0114.471 27.572l-7.445 49.595-3.734 24.796-3.873 24.783c-2.606 16.52-5.113 33.049-7.799 49.561l-7.981 49.543"
        ></path>
        <path
            fill={SKIN_COLOR[color]}
            d="M580.977 634.609c.295 4.33-1.373 20.829-2.54 31.535a8.989 8.989 0 002.1 6.813 8.982 8.982 0 006.397 3.146l20.304 1.018c4.332.2 8.607 1.066 12.674 2.569a69.65 69.65 0 0020.346 4.084c4.096.233 19.088-1.2 26.272-1.924a4.154 4.154 0 001.195-7.962c-3.095-1.301-8.575-3.569-13.001-5.21-19.869-7.366-41.272-32.44-41.272-32.44"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M580.977 634.609c.295 4.33-1.373 20.829-2.54 31.535a8.989 8.989 0 002.1 6.813 8.982 8.982 0 006.397 3.146l20.304 1.018c4.332.2 8.607 1.066 12.674 2.569a69.65 69.65 0 0020.346 4.084c4.096.233 19.088-1.2 26.272-1.924a4.154 4.154 0 001.195-7.962c-3.095-1.301-8.575-3.569-13.001-5.21-19.869-7.366-41.272-32.44-41.272-32.44M619.361 426.062h15.484"
        ></path>
    </g>
);

export default SvgComponent;
