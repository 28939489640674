import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-arm-left-option-5">
        <path
            d="M541.934 271.31c-2.313-8.022-4.936-16.143-8.018-24.82a206.367 206.367 0 0 0-10.003-23.986 924.29 924.29 0 0 0-3.471-6.98c-2.645-5.291-5.38-10.761-7.875-16.226l-.062-.137a32.123 32.123 0 0 0-29.067-18.579 31.754 31.754 0 0 0-13.409 2.961 32.043 32.043 0 0 0-16.591 18.009 32.041 32.041 0 0 0 .975 24.466c2.518 5.446 4.884 11.083 7.174 16.533a1757.38 1757.38 0 0 0 3.037 7.187 206.6 206.6 0 0 0 11.679 23.21c4.582 8.003 9.035 15.284 13.613 22.261 0 0 19.564-12.22 26.715-15.579 14.313-6.722 25.303-8.32 25.303-8.32Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-5-a)"
        />
        <path
            d="M541.934 271.31c-2.313-8.022-4.936-16.143-8.018-24.82a206.367 206.367 0 0 0-10.003-23.986 924.29 924.29 0 0 0-3.471-6.98c-2.645-5.291-5.38-10.761-7.875-16.226l-.062-.137a32.123 32.123 0 0 0-29.067-18.579 31.754 31.754 0 0 0-13.409 2.961 32.043 32.043 0 0 0-16.591 18.009 32.041 32.041 0 0 0 .975 24.466c2.518 5.446 4.884 11.083 7.174 16.533a1757.38 1757.38 0 0 0 3.037 7.187 206.6 206.6 0 0 0 11.679 23.21c4.582 8.003 9.035 15.284 13.613 22.261 0 0 19.564-12.22 26.715-15.579 14.313-6.722 25.303-8.32 25.303-8.32Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-5-b)"
        />
        <path
            d="M461.588 242.551a1757.38 1757.38 0 0 0 3.037 7.187 206.6 206.6 0 0 0 11.679 23.21c4.582 8.003 9.035 15.284 13.613 22.262 0 0 19.564-12.22 26.715-15.579 14.313-6.724 25.303-8.321 25.303-8.321-2.313-8.022-4.936-16.143-8.018-24.82a206.382 206.382 0 0 0-10.003-23.987 943.46 943.46 0 0 0-3.471-6.98c-2.645-5.29-5.38-10.761-7.875-16.225l-.062-.138"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="m465.916 167.117 2.654.951c13.052 4.678 23.789 10.343 32.826 17.32a2.669 2.669 0 0 1 1.091 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-shirt-2-arm-left-option-5-c)"
        />
        <path
            d="M468.572 168.07c13.052 4.678 23.789 10.343 32.825 17.32"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-5-a"
                x1={487.687}
                y1={127.463}
                x2={505.253}
                y2={351.92}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#FFD100" />
                <stop offset={0.274} stopColor="#FFDA32" />
                <stop offset={0.897} stopColor="#FFF0AE" />
                <stop offset={1} stopColor="#FFF4C3" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-5-b"
                x1={535.535}
                y1={405.34}
                x2={477.848}
                y2={165.485}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.95} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-arm-left-option-5-c"
                x1={538.886}
                y1={404.532}
                x2={481.199}
                y2={164.677}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.501} stopColor="#fff" />
                <stop offset={0.95} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
