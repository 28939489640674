import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            d="M456.056 328.045c-4.558-.681-9.114-1.24-13.669-1.746-2.029-.213-4.059-.463-6.088-.644l-6.085-.532c-4.058-.398-8.113-.583-12.169-.871a242.817 242.817 0 0 0-22.208-.459c2.744-7.901 5.212-15.855 7.478-23.847a205.033 205.033 0 0 0 5.574-24.479c1.365-8.254 2.555-16.542 4.149-24.752l.023-.121a26.502 26.502 0 0 0-21.108-30.967 26.498 26.498 0 0 0-30.967 21.108c-1.556 8.217-3.516 16.358-5.3 24.531a204.695 204.695 0 0 0-3.875 24.805c-.861 8.35-1.516 16.741-1.888 25.185-.342 8.45-.487 16.938.021 25.552a19.263 19.263 0 0 0 2.468 8.3 18.496 18.496 0 0 0 17.467 12.507l12.159.014 12.16.158c8.107.068 16.208-.274 24.304-1.025 4.048-.389 8.097-.676 12.144-1.175l6.07-.684c2.024-.232 4.046-.533 6.069-.796 3.481-.476 6.962-.982 10.441-1.551-3.31-9.962-1.72-21.745 2.83-28.511Z"
            fill="url(#female-34-25-shirt-1-arm-opt-4-right-a)"
        />
        <path
            d="M442.784 358.111c-2.024.263-4.046.564-6.07.796l-6.07.684c-4.047.499-8.096.786-12.144 1.176a246.487 246.487 0 0 1-24.304 1.024l-12.16-.158-12.158-.014c-10.218-.012-20.527-8.6-19.936-20.807M390.816 323.948l2.904-.11a241.079 241.079 0 0 1 24.322.415c4.056.288 8.111.473 12.168.871l6.086.532c2.029.181 4.059.431 6.088.644M413.059 250.594l-.024.121c-1.594 8.21-2.784 16.498-4.149 24.752a204.838 204.838 0 0 1-5.574 24.479 438.655 438.655 0 0 1-6.394 20.689M349.944 340.808c-.508-8.613-.363-17.101-.021-25.551.372-8.445 1.027-16.835 1.888-25.186a204.679 204.679 0 0 1 3.875-24.804c1.785-8.174 3.745-16.314 5.3-24.532a23.423 23.423 0 0 1 6.214-12.243M442.785 358.105a403.11 403.11 0 0 0 10.441-1.551c-3.31-9.962-1.72-21.745 2.83-28.51-4.558-.681-9.114-1.24-13.669-1.747"
            stroke="#231F20"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="female-34-25-shirt-1-arm-opt-4-right-a"
                x1={340.986}
                y1={230.767}
                x2={389.131}
                y2={303.201}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#E2E2E2" />
                <stop offset={1} stopColor="#fff" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
