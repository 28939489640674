import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-1-arm-left-up">
        <path
            d="m465.916 167.656 2.654.951c13.052 4.678 23.788 10.343 32.825 17.32a2.674 2.674 0 0 1 1.092 2.34c-.25 4.649-12.214 12.209-14.61 13.678l-.857.525-21.104-34.814Z"
            fill="url(#man-34-25-shirt-1-arm-left-upa)"
        />
        <path
            d="M508.327 187.677c-6.326-1.437-12.866-2.924-19.239-4.59a32.104 32.104 0 0 0-8.114-1.048 31.982 31.982 0 0 0-30.973 24.05 32.038 32.038 0 0 0 23.038 38.953c9.214 2.367 18.207 5.127 27.286 7.712 17.29 5.038 37.662 8.128 55.344 10.31l1.79-60.579c-15.963-6.068-32.706-11.461-49.132-14.808Z"
            fill="url(#man-34-25-shirt-1-arm-left-upb)"
        />
        <path
            d="M500.322 251.753c17.29 5.039 37.663 8.128 55.345 10.31l1.71-58.639c-15.962-6.067-32.705-11.46-49.132-14.807M468.57 168.609c13.052 4.678 23.789 10.343 32.826 17.319"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-upa"
                x1={477.178}
                y1={190.696}
                x2={496.696}
                y2={156.431}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-1-arm-left-upb"
                x1={448.993}
                y1={222.551}
                x2={615.141}
                y2={222.551}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
