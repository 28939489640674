import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#001489"
            d="M573.301 594.982l-3.29-40.5c-1.6-15.049-.145-29.603 2.331-46.994.639-4.164 1.447-8.348 2.313-12.578l-1.229.054s-89.912.52-92.15.26l-4.5-.478-4.511-.578c-2.94-.381-5.963-.853-8.984-1.401a53.306 53.306 0 01-43.571-57.194 53.305 53.305 0 0153.026-48.557c1.218 0 2.452.043 3.669.127l7.251.497c2.761.188 5.734.482 9.086.899l4.494.578 4.493.684c2.216.314 4.455.713 6.621 1.1l2.31.408c2.228.369 4.476.826 6.65 1.269l2.244.453c12.696 2.633 24.221 5.405 35.242 8.477 5.418 1.528 11.096 3.45 17.362 5.876a307.312 307.312 0 0117.064 7.2c1.282.577 2.67 1.228 4.237 1.987a164.59 164.59 0 014.217 2.07c1.815.893 3.648 1.882 5.422 2.838.977.527 1.955 1.054 2.936 1.569a165.307 165.307 0 0116.527 10.37l.87.636a37.362 37.362 0 0112.065 14.989 30.505 30.505 0 012.151 15.904l-5.723 46.616-2.872 23.308-5.406 60.111h-50.345z"
        ></path>
        <path
            fill="#9E005D"
            d="M571.78 576.283l53.524.267 1.676-18.643-56.716-.282 1.516 18.658z"
        ></path>
        <path
            fill="#9E005D"
            d="M571.78 576.283l53.524.267 1.676-18.643-56.716-.282 1.516 18.658zM569.354 539.832l59.225.296.472-5.253 1.65-13.388-60.116-.299c-.708 6.451-1.162 12.597-1.231 18.644z"
        ></path>
        <path
            fill="#9E005D"
            d="M569.354 539.832l59.225.296.472-5.253 1.65-13.388-60.116-.299c-.708 6.451-1.162 12.597-1.231 18.644z"
        ></path>
    </g>
);

export default SvgComponent;
