import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7300)"
            d="M494.768 223.29a109.595 109.595 0 00-20.026-15.329 31.037 31.037 0 012.394 13.895 65.928 65.928 0 01-1.451 10.762l2.169 4.754c.055.12.133.228.229.318.097.089.21.159.334.205a1.004 1.004 0 00.767-.03c2.598-1.201 15.601-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7300)"
            d="M487.945 220.096l-.14-.046a27.499 27.499 0 00-26.492 46.99 27.502 27.502 0 009.347 5.27c4.768 1.564 9.562 3.351 14.198 5.08l.821.304 14.556-53.823c-4.08-1.188-8.238-2.421-12.29-3.775z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7300"
                x1="487.086"
                x2="438.219"
                y1="215.267"
                y2="268.761"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7300"
                x1="497.644"
                x2="448.777"
                y1="224.914"
                y2="278.407"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.029" stopColor="#0197D6"></stop>
                <stop offset="0.558" stopColor="#21ACDF"></stop>
                <stop offset="1" stopColor="#40C1E9"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
