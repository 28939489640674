import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7021)"
            d="M478.221 236.789c-.166-4.363-.928-19.193-3.775-26.828l-1.016-2.727 2.477 1.526a102.454 102.454 0 0118.859 14.537 2.71 2.71 0 01.822 2.482c-.788 4.563-13.42 10.695-15.943 11.877l-1.368.641-.056-1.508z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7021)"
            d="M557.981 309.035c-.532 0-1.069-.021-1.607-.065a19.837 19.837 0 01-3.843-.705 20.398 20.398 0 01-3.396-.509 213.387 213.387 0 01-22.257-6.933c-6.887-2.522-13.84-5.341-21.261-8.619a169.574 169.574 0 01-20.318-10.417 923.404 923.404 0 01-5.833-3.535c-4.44-2.704-9.029-5.501-13.634-8.072a27.534 27.534 0 01-10.604-37.417 27.54 27.54 0 0124.035-14.099 27.519 27.519 0 0113.383 3.494l.124.07c4.583 2.59 9.351 5.051 13.963 7.431 2.029 1.048 4.055 2.099 6.079 3.155a169.403 169.403 0 0119.471 11.936 378.016 378.016 0 0110.072 7.259c.244-1.161.491-2.263.779-3.371l.933-3.847c.209-.852.447-1.698.685-2.544.125-.445.248-.889.37-1.334.92-3.279 1.745-5.968 2.6-8.473l.699-2.053 1.108 1.864c3.531 5.953 10.829 8.073 16.48 8.073 4.518 0 8.547-1.3 10.264-3.312l1.441-1.688.31 2.198c.262 1.864.51 3.901.755 6.228.046.474.094.939.143 1.404.092.868.183 1.736.246 2.603l.276 3.968c.153 1.792.211 3.608.265 5.365.028.857.055 1.714.093 2.574a103.563 103.563 0 01-.612 15.875 615.087 615.087 0 01-.582 4.7c-.451 3.564-.917 7.251-1.213 10.879a19.596 19.596 0 01-19.414 17.917z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7021"
                x1="451.894"
                x2="625.388"
                y1="214.26"
                y2="275.85"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7021"
                x1="446.162"
                x2="619.656"
                y1="230.402"
                y2="291.992"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#002A3A"></stop>
                <stop offset="0.535" stopColor="#013F5A"></stop>
                <stop offset="0.971" stopColor="#024E70"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
