import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="#2B2729"
            d="M556.982 377.14a217.017 217.017 0 00-31.427-6.043c-26.947-4.109-52.664 9.529-61.916 35.508-11.187 32.376 9.096 65.034 42.302 71.702 0 0 14.213-41.59 20.979-54.166 12.289-22.843 30.062-47.001 30.062-47.001z"
        ></path>
    </g>
);

export default SvgComponent;
