import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_6871)"
            d="M494.767 223.322a2.699 2.699 0 01.821 2.482c-.805 4.667-13.807 10.893-16.406 12.093l-.912.422s-.585-24.989-5.356-31.35a108.174 108.174 0 0121.853 16.353z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_6871)"
            d="M564.088 307.737c-1.351 0-2.699-.135-4.023-.404-9.158-1.884-17.899-4.495-24.49-6.559-7.623-2.416-15.341-5.13-23.599-8.299a202.147 202.147 0 01-22.757-10.146 892.563 892.563 0 01-6.622-3.485c-5.008-2.65-10.19-5.391-15.363-7.903a27.307 27.307 0 01-13.978-15.736 27.316 27.316 0 011.246-21.011 27.678 27.678 0 0124.759-15.499 27.237 27.237 0 0111.989 2.767l.117.057c5.162 2.536 10.508 4.935 15.678 7.255 2.277 1.022 4.552 2.049 6.824 3.081a201.753 201.753 0 0121.999 11.71c7.568 4.557 14.455 8.974 21.047 13.498 5.685 3.933 13.12 9.222 20.233 15.281a19.974 19.974 0 017.002 13.698 19.966 19.966 0 01-4.736 14.638 20.1 20.1 0 01-15.326 7.057z"
        ></path>
        <path
            fill="url(#paint2_linear_11323_6871)"
            d="M567.912 308.119a19.56 19.56 0 01-19.081-15.577l-.024-.122c-.647-3.249-1.466-6.528-2.258-9.698a483.007 483.007 0 01-1.044-4.23 86.483 86.483 0 01-2.162-14.367c-.046-.789-.103-1.568-.159-2.347-.116-1.607-.238-3.269-.261-4.937l-.113-3.617a61.719 61.719 0 01-.011-2.396c.003-.43.006-.86.005-1.29 15.316 8.008 27.461 3.092 33.099-6.702.17.407.34.803.51 1.198.316.734.631 1.467.918 2.207l1.309 3.397c.62 1.53 1.154 3.109 1.671 4.635.251.74.501 1.479.761 2.216a86.344 86.344 0 013.591 14.085c.237 1.433.459 2.868.682 4.303.502 3.23 1.02 6.57 1.686 9.81a19.364 19.364 0 01-2.816 14.657 19.377 19.377 0 01-12.356 8.371 19.604 19.604 0 01-3.947.404z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_6871"
                x1="556.839"
                x2="481.369"
                y1="307.805"
                y2="225.179"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_6871"
                x1="555.289"
                x2="479.819"
                y1="309.217"
                y2="226.59"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint2_linear_11323_6871"
                x1="542.793"
                x2="587.438"
                y1="275.477"
                y2="275.477"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
