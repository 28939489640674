import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 174,
        height: 209,
        content: (
            <>
                <g
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    clipPath="url(#object-office-spin-chair-a)"
                >
                    <path
                        fill="#fff"
                        d="M86.814 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5Z"
                    />
                    <path
                        d="M102.098 169.73v11.24l8.18-5.31 37.27 17.14"
                    />
                    <path
                        d="m19.379 183.51 30.9-24.69h73.07l30.91 24.69M77.394 44.486v63.935h18.841V44.486"
                    />
                    <path 
                        d="M96.236 44.486h59.72a8 8 0 0 0 8-8V17.738c0-8.837-7.163-16-16-16H25.674c-8.837 0-16 7.163-16 16v18.748a8 8 0 0 0 8 8h5.987M56.629 44.486h20.765M102.1 169.728V89.58M71.531 147.213v33.758M26.088 192.792c.09.61.14 1.23.14 1.87 0 6.91-5.6 12.5-12.5 12.5s-12.5-5.59-12.5-12.5 5.59-12.5 12.5-12.5c2.03 0 3.95.49 5.65 1.35M154.258 183.512c1.69-.86 3.61-1.35 5.65-1.35 6.9 0 12.5 5.6 12.5 12.5s-5.6 12.5-12.5 12.5-12.5-5.59-12.5-12.5c0-.63.05-1.26.14-1.86M102.1 89.58H71.531v32.085M26.088 192.792l37.262-17.126 8.18 5.305" 
                    />
                </g>
                <defs>
                    <clipPath id="object-office-spin-chair-a">
                        <path fill="#fff" d="M.229.738h173.18v207.427H.229z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
    'Color #1': {
        width: 172,
        height: 208,
        content: (
            <>
                <path
                    fill="#AEAEAF"
                    d="M150.791 194.665h16.61l-44.859-35.841H49.468L4.61 194.665h16.61l41.318-19 23.468 15.221 23.468-15.221 41.318 19Z"
                />
                <path fill="url(#object-office-spin-chair-a)" d="M95.426 89.581V44.486H76.585v45.095h18.84Z" />
                <path
                    fill="url(#object-office-spin-chair-b)"
                    d="M163.146 17.738v18.748c0 4.415-3.585 8-8 8H16.865c-4.415 0-8-3.585-8-8V17.738c0-8.83 7.168-16 16-16h122.281c8.831 0 16 7.17 16 16Z"
                />
                <path fill="url(#object-office-spin-chair-c)" d="M101.289 158.824V89.58H70.721v69.244h30.568Z" />
                <path
                    fill="#818084"
                    d="M12.914 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5ZM159.096 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5ZM86.006 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M52.746 44.486h102.399a8 8 0 0 0 8-8V17.738c0-8.837-7.163-16-16-16H24.863c-8.837 0-16 7.163-16 16v18.748a8 8 0 0 0 8 8h14.662"
                />
                <defs>
                    <linearGradient
                        id="object-office-spin-chair-a"
                        x1={76.586}
                        x2={95.426}
                        y1={0.738}
                        y2={0.738}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-b"
                        x1={8.864}
                        x2={163.146}
                        y1={23.112}
                        y2={23.112}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#ECF3F9" />
                        <stop offset={0.468} stopColor="#C2C9CF" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-c"
                        x1={70.721}
                        x2={101.289}
                        y1={124.202}
                        y2={124.202}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 172,
        height: 208,
        content: (
            <>
                <path
                    fill="#AEAEAF"
                    d="M150.791 194.665h16.61l-44.859-35.841H49.468L4.61 194.665h16.61l41.318-19 23.468 15.221 23.468-15.221 41.318 19Z"
                />
                <path fill="url(#object-office-spin-chair-a)" d="M95.426 89.581V44.486H76.585v45.095h18.84Z" />
                <path
                    fill="url(#object-office-spin-chair-b)"
                    d="M163.146 17.738v18.748c0 4.415-3.585 8-8 8H16.865c-4.415 0-8-3.585-8-8V17.738c0-8.83 7.168-16 16-16h122.281c8.831 0 16 7.17 16 16Z"
                />
                <path fill="url(#object-office-spin-chair-c)" d="M101.289 158.824V89.58H70.721v69.244h30.568Z" />
                <path
                    fill="#818084"
                    d="M12.914 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5ZM159.096 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5ZM86.006 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M52.746 44.486h102.399a8 8 0 0 0 8-8V17.738c0-8.837-7.163-16-16-16H24.863c-8.837 0-16 7.163-16 16v18.748a8 8 0 0 0 8 8h14.662"
                />
                <defs>
                    <linearGradient
                        id="object-office-spin-chair-a"
                        x1={76.585}
                        x2={95.426}
                        y1={67.033}
                        y2={67.033}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-b"
                        x1={8.864}
                        x2={163.146}
                        y1={23.112}
                        y2={23.112}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FFE4B8" />
                        <stop offset={0.428} stopColor="#EBC38C" />
                        <stop offset={0.468} stopColor="#E5B97F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-c"
                        x1={70.721}
                        x2={101.289}
                        y1={124.202}
                        y2={124.202}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 172,
        height: 208,
        content: (
            <>
                <path
                    fill="#AEAEAF"
                    d="M150.788 194.665h16.61l-44.858-35.841H49.465L4.607 194.665h16.61l41.317-19 23.468 15.221 23.468-15.221 41.318 19Z"
                />
                <path fill="url(#object-office-spin-chair-a)" d="M95.422 89.581V44.486H76.58v45.095h18.84Z" />
                <path
                    fill="url(#object-office-spin-chair-b)"
                    d="M163.143 17.738v18.748c0 4.415-3.585 8-8 8H16.861c-4.415 0-8-3.585-8-8V17.738c0-8.83 7.169-16 16-16h122.282c8.831 0 16 7.17 16 16Z"
                />
                <path fill="url(#object-office-spin-chair-c)" d="M101.287 158.824V89.58H70.719v69.244h30.568Z" />
                <path
                    fill="#818084"
                    d="M12.912 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5ZM159.094 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5ZM86.002 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5-12.5 5.596-12.5 12.5 5.596 12.5 12.5 12.5Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M52.744 44.486h102.399a8 8 0 0 0 8-8V17.738c0-8.837-7.163-16-16-16H24.861c-8.837 0-16 7.163-16 16v18.748a8 8 0 0 0 8 8h14.662"
                />
                <defs>
                    <linearGradient
                        id="object-office-spin-chair-a"
                        x1={76.582}
                        x2={95.423}
                        y1={0.738}
                        y2={0.738}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-b"
                        x1={8.862}
                        x2={163.143}
                        y1={23.112}
                        y2={23.112}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FCCBBB" />
                        <stop offset={0.403} stopColor="#F0AB8F" />
                        <stop offset={0.468} stopColor="#EA9975" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-c"
                        x1={70.718}
                        x2={101.287}
                        y1={124.202}
                        y2={124.202}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 172,
        height: 208,
        content: (
            <>
                <path
                    fill="#AEAEAF"
                    d="M150.791 194.665h16.61l-44.859-35.841H49.468L4.61 194.665h16.61l41.318-19 23.468 15.221 23.468-15.221 41.318 19Z"
                />
                <path fill="url(#object-office-spin-chair-a)" d="M95.426 89.581V44.486H76.585v45.095h18.84Z" />
                <path
                    fill="url(#object-office-spin-chair-b)"
                    d="M163.146 17.738v18.748c0 4.415-3.585 8-8 8H16.865c-4.415 0-8-3.585-8-8V17.738c0-8.83 7.168-16 16-16h122.281c8.831 0 16 7.17 16 16Z"
                />
                <path fill="url(#object-office-spin-chair-c)" d="M101.289 158.824V89.58H70.721v69.244h30.568Z" />
                <path
                    fill="#818084"
                    d="M12.914 207.166c6.904 0 12.5-5.596 12.5-12.5s-5.596-12.5-12.5-12.5c-6.903 0-12.5 5.596-12.5 12.5s5.597 12.5 12.5 12.5ZM159.096 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5ZM86.006 207.166c6.903 0 12.5-5.596 12.5-12.5s-5.597-12.5-12.5-12.5c-6.904 0-12.5 5.596-12.5 12.5s5.596 12.5 12.5 12.5Z"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M52.746 44.486h102.399a8 8 0 0 0 8-8V17.738c0-8.837-7.163-16-16-16H24.863c-8.837 0-16 7.163-16 16v18.748a8 8 0 0 0 8 8h14.662"
                />
                <defs>
                    <linearGradient
                        id="object-office-spin-chair-a"
                        x1={76.585}
                        x2={95.426}
                        y1={67.033}
                        y2={67.033}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-b"
                        x1={8.864}
                        x2={163.146}
                        y1={23.112}
                        y2={23.112}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.287} stopColor="#FFF4C3" />
                        <stop offset={0.331} stopColor="#FFEC96" />
                        <stop offset={0.433} stopColor="#FFD828" />
                        <stop offset={0.468} stopColor="#FFD100" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-spin-chair-c"
                        x1={70.721}
                        x2={101.289}
                        y1={124.202}
                        y2={124.202}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={0.41} stopColor="#8E8B8F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            fill="none"
            id="object-office-spin-chair"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
