import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-shirt-2-both-arms-option-3-left">
        <path
            d="M501.219 185.176c-9.037-6.976-19.774-12.641-32.825-17.319l-2.654-.951 21.104 34.814.856-.526c2.396-1.467 14.361-9.028 14.611-13.676a2.684 2.684 0 0 0-1.092-2.342Z"
            fill="url(#man-34-25-shirt-2-both-arms-option-3-left-a)"
        />
        <path
            d="M508.785 285.254a508.24 508.24 0 0 1-12.562-11.403 231.243 231.243 0 0 1-19.069-19.793 987.401 987.401 0 0 1-5.353-6.303c-4.039-4.775-8.215-9.712-12.474-14.419a32 32 0 1 1 47.457-42.94l.093.104c4.245 4.734 8.724 9.406 13.055 13.923 1.905 1.987 3.806 3.978 5.703 5.972a231.395 231.395 0 0 1 17.701 21.031 502.058 502.058 0 0 1 11.428 15.668c-10.818.228-40.456 23.505-45.979 38.16Z"
            fill="url(#man-34-25-shirt-2-both-arms-option-3-left-b)"
        />
        <path
            d="m506.784 190.398.094.104c4.244 4.734 8.723 9.406 13.054 13.923 1.906 1.987 3.807 3.978 5.703 5.972a231.407 231.407 0 0 1 17.701 21.032 499.264 499.264 0 0 1 11.428 15.668c-10.818.228-40.457 23.505-45.979 38.16a506.342 506.342 0 0 1-12.562-11.404 231.249 231.249 0 0 1-19.069-19.793 960.277 960.277 0 0 1-5.353-6.302M501.22 185.178c-9.037-6.976-19.774-12.641-32.825-17.319"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-shirt-2-both-arms-option-3-left-a"
                x1={542.395}
                y1={248.967}
                x2={481.238}
                y2={178.702}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
            <linearGradient
                id="man-34-25-shirt-2-both-arms-option-3-left-b"
                x1={533.571}
                y1={294.265}
                x2={474.366}
                y2={176.506}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
