import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Outline #1': {
        width: 854,
        height: 373,
        content: (
            <>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="m729.023 30.082 61.705 341.38h20.147l-42.063-341.38M625.678 58.613l-56.54 312.85h-20.15l35.024-284.31M223.336 30.082l61.705 341.38h20.147l-42.063-341.38M85.36 30.082l-3.52 28.53-38.54 312.85h20.15l56.541-312.85M223.334 30.082H85.364M729.023 30.082H333.809M50.686 30.08H1.91V1.543h850.358V30.08h-83.455"
                />
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M119.991 58.612H52.379v-28.53M625.679 58.612H538.439v28.54h-204.63v-57.07"
                />
            </>
        ),
    },
    'Color #1': {
        width: 853,
        height: 372,
        content: (
            <>
                <path
                    fill="#3A383A"
                    d="M790.047 371.468h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-a)"
                    d="M568.456 371.468h-20.147l42.063-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M284.359 371.468h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-b)"
                    d="M62.767 371.468H42.62l42.064-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M262.443 30.088h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M73.166 123.572h34.411l16.897-93.484H84.685l-11.519 93.484Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M768.131 30.088h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M578.854 123.572h34.41l16.897-93.484h-39.789l-11.518 93.484Z"
                />
                <path fill="url(#object-office-table-c)" d="M851.586 1.55H1.229v28.538h850.357V1.55Z" />
                <path fill="url(#object-office-table-d)" d="M537.757 30.088h-204.63v57.074h204.63V30.088Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M333.127 87.162h204.63V30.088M728.343 30.088H1.229V1.55h850.357v28.537h-83.454"
                />
                <defs>
                    <linearGradient
                        id="object-office-table-a"
                        x1={548.309}
                        x2={630.161}
                        y1={200.778}
                        y2={200.778}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-b"
                        x1={42.619}
                        x2={124.472}
                        y1={-1236.33}
                        y2={-1236.33}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-c"
                        x1={868.261}
                        x2={17.903}
                        y1={15.82}
                        y2={15.82}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.681} stopColor="#C2C9CF" />
                        <stop offset={0.69} stopColor="#CBD2D8" />
                        <stop offset={0.714} stopColor="#E3EAF0" />
                        <stop offset={0.729} stopColor="#ECF3F9" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-d"
                        x1={333.127}
                        x2={537.757}
                        y1={58.625}
                        y2={58.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.215} stopColor="#ECF3F9" />
                        <stop offset={0.269} stopColor="#E3EAF0" />
                        <stop offset={0.356} stopColor="#CBD2D8" />
                        <stop offset={0.386} stopColor="#C2C9CF" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #2': {
        width: 854,
        height: 372,
        content: (
            <>
                <path
                    fill="#3A383A"
                    d="M790.799 371.468h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-a)"
                    d="M569.208 371.468h-20.147l42.063-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M285.111 371.468h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-b)"
                    d="M63.52 371.468H43.37l42.064-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M263.195 30.088h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M73.916 123.572h34.411l16.897-93.484H85.435l-11.519 93.484Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M768.883 30.088h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M579.605 123.572h34.411l16.897-93.484h-39.789l-11.519 93.484Z"
                />
                <path fill="url(#object-office-table-c)" d="M852.338 1.55H1.98v28.538h850.358V1.55Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M852.338 1.55H1.98v28.538h850.358V1.55Z"
                />
                <path fill="url(#object-office-table-d)" d="M538.511 30.088h-204.63v57.074h204.63V30.088Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M538.511 30.088h-204.63v57.074h204.63V30.088ZM475.111 58.625h31.326"
                />
                <defs>
                    <linearGradient
                        id="object-office-table-a"
                        x1={0.981}
                        x2={1.981}
                        y1={200.778}
                        y2={200.778}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-b"
                        x1={43.371}
                        x2={125.224}
                        y1={200.778}
                        y2={200.778}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-c"
                        x1={869.012}
                        x2={18.654}
                        y1={15.819}
                        y2={15.819}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.681} stopColor="#E5B97F" />
                        <stop offset={0.698} stopColor="#F0CB97" />
                        <stop offset={0.717} stopColor="#FBDDAF" />
                        <stop offset={0.729} stopColor="#FFE4B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-d"
                        x1={333.881}
                        x2={538.511}
                        y1={58.625}
                        y2={58.625}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.215} stopColor="#FFE4B8" />
                        <stop offset={0.258} stopColor="#FBDDAF" />
                        <stop offset={0.328} stopColor="#F0CB97" />
                        <stop offset={0.386} stopColor="#E5B97F" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #3': {
        width: 854,
        height: 372,
        content: (
            <>
                <path
                    fill="#3A383A"
                    d="M790.549 371.466h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-a)"
                    d="M568.958 371.466h-20.147l42.063-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M284.861 371.466h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-b)"
                    d="M63.27 371.466H43.12l42.064-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M262.945 30.086h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M73.666 123.57h34.411l16.897-93.484H85.185L73.666 123.57Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M768.633 30.086h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M579.355 123.57h34.411l16.897-93.484h-39.789l-11.519 93.484Z"
                />
                <path fill="url(#object-office-table-c)" d="M852.088 1.549H1.73v28.537h850.358V1.549Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M852.088 1.549H1.73v28.537h850.358V1.549Z"
                />
                <path fill="url(#object-office-table-d)" d="M538.261 30.086h-204.63V87.16h204.63V30.086Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M538.261 30.086h-204.63V87.16h204.63V30.086ZM474.861 58.623h31.326"
                />
                <defs>
                    <linearGradient
                        id="object-office-table-a"
                        x1={548.811}
                        x2={630.663}
                        y1={200.776}
                        y2={200.776}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-b"
                        x1={43.121}
                        x2={124.974}
                        y1={-1281.81}
                        y2={-1281.81}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-c"
                        x1={868.762}
                        x2={18.404}
                        y1={15.817}
                        y2={15.817}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.681} stopColor="#EA9975" />
                        <stop offset={0.702} stopColor="#F3B39A" />
                        <stop offset={0.719} stopColor="#F9C4B2" />
                        <stop offset={0.729} stopColor="#FCCBBB" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-d"
                        x1={333.631}
                        x2={538.261}
                        y1={58.623}
                        y2={58.623}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.215} stopColor="#FCCBBB" />
                        <stop offset={0.252} stopColor="#F9C4B2" />
                        <stop offset={0.312} stopColor="#F3B39A" />
                        <stop offset={0.386} stopColor="#EA9975" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Color #4': {
        width: 854,
        height: 372,
        content: (
            <>
                <path
                    fill="#3A383A"
                    d="M790.498 371.466h20.147l-42.063-341.38h-39.789l61.705 341.38Z"
                />
                <path
                    fill="url(#object-office-table-a)"
                    d="M568.909 371.466h-20.147l42.063-341.38h39.789l-61.705 341.38Z"
                />
                <path
                    fill="#3A383A"
                    d="M284.811 371.466h20.147l-42.063-341.38h-39.79l61.706 341.38Z"
                />
                <path
                    fill="url(#object-office-table-b)"
                    d="M63.22 371.466H43.072l42.064-341.38h39.789L63.22 371.466Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M262.895 30.086h-39.79l16.897 93.484h34.412l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M73.62 123.57h34.409l16.898-93.484H85.138L73.62 123.57Z"
                />
                <path
                    fill="#AEAEAF"
                    d="M768.582 30.086h-39.789l16.897 93.484h34.411l-11.519-93.484Z"
                />
                <path
                    fill="#E2E2E2"
                    d="M579.309 123.57h34.411l16.897-93.484h-39.789l-11.519 93.484Z"
                />
                <path fill="url(#object-office-table-c)" d="M852.04 1.547H1.682v28.537H852.04V1.547Z" />
                <path fill="url(#object-office-table-d)" d="M538.212 30.086h-204.63V87.16h204.63V30.086Z" />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M333.582 87.16h204.63V30.086M728.796 30.084H1.682V1.547H852.04v28.537h-83.455"
                />
                <defs>
                    <linearGradient
                        id="object-office-table-a"
                        x1={548.762}
                        x2={630.614}
                        y1={200.776}
                        y2={200.776}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-b"
                        x1={43.072}
                        x2={124.925}
                        y1={0.549}
                        y2={0.549}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#616469" />
                        <stop offset={1} stopColor="#8E8B8F" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-c"
                        x1={868.714}
                        x2={18.356}
                        y1={15.815}
                        y2={15.815}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.681} stopColor="#FFD100" />
                        <stop offset={0.682} stopColor="#FFD101" />
                        <stop offset={0.695} stopColor="#FFDD45" />
                        <stop offset={0.706} stopColor="#FFE77B" />
                        <stop offset={0.716} stopColor="#FFEEA2" />
                        <stop offset={0.724} stopColor="#FFF2BA" />
                        <stop offset={0.729} stopColor="#FFF4C3" />
                    </linearGradient>
                    <linearGradient
                        id="object-office-table-d"
                        x1={333.582}
                        x2={538.212}
                        y1={58.623}
                        y2={58.623}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.215} stopColor="#FFF4C3" />
                        <stop offset={0.232} stopColor="#FFF2BA" />
                        <stop offset={0.261} stopColor="#FFEEA2" />
                        <stop offset={0.297} stopColor="#FFE77B" />
                        <stop offset={0.338} stopColor="#FFDD45" />
                        <stop offset={0.385} stopColor="#FFD101" />
                        <stop offset={0.386} stopColor="#FFD100" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            viewBox={`0 0 ${style?.width} ${style?.height}`}
            id="object-office-table"
            fill="none"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
