import React from 'react';
import { SVG } from 'components/objects';

const STYLES = {
    'Option #1': {
        width: 320,
        height: 118,
        content: (
            <>
                <path
                    fill="url(#object-food-eggplant-a)"
                    d="M219.593 33.783 72.259 1.581C36.153-6.311 1.996 21.187 1.996 58.144c0 31.977 25.922 57.9 57.9 57.9h150.811c22.981 0 41.611-18.63 41.611-41.61 0-19.557-13.62-36.474-32.725-40.65Z"
                />
                <path
                    fill="url(#object-food-eggplant-b)"
                    d="M238.645 86.485c-6.657 0-12.053-5.397-12.053-12.053 0-6.657 5.396-12.053 12.053-12.053 18.386 0 53.384-6.826 61.795-15.236 11.638-11.638 23.862 10.228 17.045 17.045-17.012 17.012-63.743 22.297-78.84 22.297Z"
                />
                <path
                    fill="url(#object-food-eggplant-c)"
                    d="m219.593 33.785-9.133-1.996c5.339 11.062 8.364 23.519 8.364 36.816 0 17.576-5.348 33.901-14.504 47.441h6.387c22.981 0 41.611-18.63 41.611-41.61 0-19.557-13.619-36.475-32.725-40.651Z"
                />
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M10.573 27.771c-5.416 8.763-8.577 19.13-8.577 30.374 0 31.977 25.922 57.9 57.9 57.9h150.811c14.047 0 26.467-6.959 34.002-17.619M248.426 56.86c-5.353-11.476-15.821-20.23-28.834-23.075L72.258 1.582"
                />
                <defs>
                    <linearGradient
                        id="object-food-eggplant-a"
                        x1={127.157}
                        x2={127.157}
                        y1={97.977}
                        y2={16.871}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#001489" />
                        <stop offset={1} stopColor="#0047A7" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-eggplant-b"
                        x1={226.592}
                        x2={319.447}
                        y1={65.138}
                        y2={65.138}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-eggplant-c"
                        x1={228.319}
                        x2={228.319}
                        y1={102.903}
                        y2={43.901}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                </defs>
            </>
        ),
    },
    'Option #2': {
        width: 319,
        height: 209,
        content: (
            <>
                <g clipPath="url(#object-food-eggplant-a)">
                    <path
                        fill="url(#object-food-eggplant-b)"
                        d="M218.594 125.561 71.263 93.358C35.157 85.467 1 112.964 1 149.922c0 31.977 25.922 57.9 57.899 57.9h150.815c22.98 0 41.61-18.629 41.61-41.61 0-19.557-13.62-36.474-32.73-40.651Z"
                    />
                    <path
                        fill="url(#object-food-eggplant-c)"
                        d="M237.644 178.26c-6.65 0-12.05-5.397-12.05-12.053 0-6.656 5.4-12.053 12.05-12.053 18.39 0 53.39-6.826 61.8-15.236 11.64-11.638 23.86 10.228 17.04 17.045-17.01 17.012-63.74 22.297-78.84 22.297Z"
                    />
                    <path
                        fill="url(#object-food-eggplant-d)"
                        d="m218.594 125.562-9.13-1.996c5.34 11.062 8.36 23.519 8.36 36.816 0 17.576-5.34 33.901-14.5 47.441h6.39c22.98 0 41.61-18.63 41.61-41.61 0-19.557-13.62-36.475-32.73-40.651Z"
                    />
                    <path
                        stroke="#222731"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M9.577 119.551C4.161 128.313 1 138.681 1 149.925c0 31.977 25.922 57.9 57.899 57.9h150.815c14.04 0 26.46-6.959 34-17.619M247.434 148.637c-5.36-11.475-15.83-20.23-28.84-23.075L71.262 93.359"
                    />
                </g>
                <path
                    stroke="#222731"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M71.258 68.993c0-11.198 6.767-11.198 6.767-22.396 0-11.197-6.767-11.199-6.767-22.398 0-11.2 6.767-11.2 6.767-22.398M121.547 68.993c0-11.198 6.767-11.198 6.767-22.396 0-11.197-6.767-11.199-6.767-22.398 0-11.2 6.767-11.2 6.767-22.398M171.834 68.993c0-11.198 6.767-11.198 6.767-22.396 0-11.197-6.767-11.199-6.767-22.398 0-11.2 6.767-11.2 6.767-22.398"
                />
                <defs>
                    <linearGradient
                        id="object-food-eggplant-b"
                        x1={179.744}
                        x2={71.365}
                        y1={250.612}
                        y2={67.959}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#004F71" />
                        <stop offset={0.125} stopColor="#01567A" />
                        <stop offset={1} stopColor="#0B86B8" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-eggplant-c"
                        x1={225.594}
                        x2={318.448}
                        y1={156.914}
                        y2={156.914}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                    <linearGradient
                        id="object-food-eggplant-d"
                        x1={227.324}
                        x2={227.324}
                        y1={194.681}
                        y2={135.678}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop offset={0.001} stopColor="#00B140" />
                        <stop offset={0.502} stopColor="#42BC59" />
                        <stop offset={1} stopColor="#81C871" />
                    </linearGradient>
                    <clipPath id="object-food-eggplant-a">
                        <path fill="#fff" d="M0 92h318.448v116.822H0z" />
                    </clipPath>
                </defs>
            </>
        ),
    },
};

export default function SvgComponent({ variation = '', ...attributes } : SVG) {
    const style = STYLES[variation as keyof typeof STYLES];
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={style?.width}
            height={style?.height}
            fill="none"
            id="object-food-eggplant"
            {...attributes}
        >
            {style?.content}
        </svg>
    );
}
