import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-arm-left-option-4">
        <path
            d="m465.473 167.5 2.653.951c13.051 4.677 23.788 10.342 32.826 17.319.765.591 5.865 5.433 5.815 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.857.525-21.103-34.813Z"
            fill="#001489"
        />
        <path
            d="M524.527 220.735a946.953 946.953 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.616-9.445-16.021l-.072-.13a32.085 32.085 0 0 0-27.887-16.258 32.02 32.02 0 0 0-15.718 4.147 31.788 31.788 0 0 0-15.086 19.294 31.788 31.788 0 0 0 2.975 24.311c3.048 5.392 5.963 10.988 8.781 16.4 1.239 2.378 2.482 4.753 3.729 7.123a219.914 219.914 0 0 0 13.929 22.894c.721 1.057 1.439 2.1 2.153 3.13l46.678-47.455a201.876 201.876 0 0 0-5.885-10.539Z"
            fill="#001489"
        />
        <path
            d="m465.473 167.5 2.653.951c13.051 4.677 23.788 10.342 32.826 17.319.765.591 5.865 5.433 5.815 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.857.525-21.103-34.813Z"
            fill="#001489"
        />
        <path
            d="M524.527 220.735a946.953 946.953 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.616-9.445-16.021l-.072-.13a32.085 32.085 0 0 0-27.887-16.258 32.02 32.02 0 0 0-15.718 4.147 31.788 31.788 0 0 0-15.086 19.294 31.788 31.788 0 0 0 2.975 24.311c3.048 5.392 5.963 10.988 8.781 16.4 1.239 2.378 2.482 4.753 3.729 7.123a219.914 219.914 0 0 0 13.929 22.894c.721 1.057 1.439 2.1 2.153 3.13l46.678-47.455a201.876 201.876 0 0 0-5.885-10.539Z"
            fill="#001489"
        />
    </g>
);

export default SvgComponent;
