import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6558)"
            d="M541.444 345.166c-1.63-18.895-9.21-33.783-11.622-39.584l-.101-.246a50.975 50.975 0 01-3.99-19.386 40.261 40.261 0 011.891-11.779c1.718-5.35 1.36-12.27-.786-20.087-5.892-21.468-25.639-63.097-52.806-82.951-2.273-1.661-6.155-4.212-8.432-5.504.09 1.419 2.621 22.546 1.142 28.063l-24.453-18.732a100.649 100.649 0 01-32.258-21.687s-23.91 7.699-49.61 27.929c-12.029 9.47-18.829 24.614-18.456 40.175l.457 19.059a327.389 327.389 0 0010.716 77.382c.032 3.804.033 7.485.035 11.171.008 20.126 5.642 85.326 5.642 85.326s79.189-6.053 107.73-6.62c29.499 2.315 54.91-.224 64.841-14.609 0 0 12.473-19.942 10.06-47.92z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M468.73 197.66c1.83 1.83 7.27 12.144 12.989 23.563M410.083 153.488c6.994 8.079 22.303 18.189 32.228 21.615l24.553 18.978 8-19.515 7.309 6.637"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M442.311 175.106l-9.444 13.074-20.923-22.629M488.332 230.844l9.181 25.767c2.586 7.257-.738 18.576.208 26.222 0 0 19.518 39.614 13.012 99.18M351.285 191.895a56.884 56.884 0 019.293-9.988c19.248-16.51 43.462-25.372 46.882-26.352M525.731 285.951a40.261 40.261 0 011.891-11.779c1.718-5.35 1.36-12.27-.786-20.087-2.88-10.493-9.07-25.802-17.817-41.03M341.963 221.375l.457 19.059a327.373 327.373 0 0010.716 77.381c.032 3.805.033 7.486.035 11.171.008 20.126 5.642 85.327 5.642 85.327"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M541.229 361.788c.618-5.52.69-11.088.215-16.623-1.63-18.895-9.21-33.783-11.622-39.584l-.101-.246a50.975 50.975 0 01-3.99-19.386"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6558)"
            d="M410.484 152.812l33.7 81.652a96.207 96.207 0 017.277 36.703v207.511a19.996 19.996 0 01-20 20h-90.82l-.842-270.847c-.044-19.876 8.392-39.268 25.85-51.737 20.118-14.371 44.835-23.282 44.835-23.282z"
        ></path>
        <path
            fill="#63666A"
            d="M414.437 189.914c-7.3 9.29-6.774 10.843-6.774 10.843l41.046 47.587a96.267 96.267 0 00-4.525-13.88l-33.701-81.652c-9.787 31.687-2.35 34.448 3.954 37.102z"
        ></path>
        <path
            stroke="#111011"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M378.964 403.977h59.675"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6558"
                x1="533.671"
                x2="253.912"
                y1="341.666"
                y2="222.605"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff"></stop>
                <stop offset="1" stopColor="#E2E2E2"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6558"
                x1="339.8"
                x2="451.461"
                y1="325.745"
                y2="325.745"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#888B8D"></stop>
                <stop offset="1" stopColor="#ABADAD"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
