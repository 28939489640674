import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_11323_7301)"
            d="M494.768 223.298a109.595 109.595 0 00-20.026-15.329 31.035 31.035 0 012.394 13.895 65.928 65.928 0 01-1.451 10.762l2.169 4.754c.055.12.133.228.229.318a.996.996 0 001.101.175c2.598-1.201 15.601-7.426 16.405-12.093a2.702 2.702 0 00-.821-2.482z"
        ></path>
        <path
            fill="url(#paint1_linear_11323_7301)"
            d="M487.945 220.097l-.14-.047a27.498 27.498 0 00-34.702 17.558 27.5 27.5 0 0017.557 34.702c4.768 1.564 9.562 3.351 14.198 5.08l.821.304 14.556-53.822c-4.08-1.189-8.238-2.421-12.29-3.775z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_11323_7301"
                x1="490.724"
                x2="448.218"
                y1="207.092"
                y2="283.43"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_11323_7301"
                x1="495.711"
                x2="453.205"
                y1="209.863"
                y2="286.2"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.001" stopColor="#004F71"></stop>
                <stop offset="0.125" stopColor="#01567A"></stop>
                <stop offset="1" stopColor="#0B86B8"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
