import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-1-right">
        <path
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-right-a)"
            d="M364.076 273.588a89.74 89.74 0 0 1-7.73-13.228 136.752 136.752 0 0 1-5.654-13.563 64.497 64.497 0 0 1-3.554-14.052c-.198-1.457-.377-2.921-.556-4.385-.391-3.194-.796-6.498-1.408-9.69a32.04 32.04 0 0 1 25.401-37.455 32.274 32.274 0 0 1 6.06-.578 32.026 32.026 0 0 1 31.395 25.98l.065.353c.582 3.261 1.387 6.495 2.166 9.621l1.431 5.743c2.25-1.619-46.987 29.134-47.616 51.254Z"
        />
        <path
            stroke="#231F20"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m385.412 241.761 10.925-8.488c7.3-5.639 14.765-11.072 22.326-16.387 3.801-2.631 7.565-5.308 11.406-7.89 3.82-2.608 7.647-5.207 11.536-7.729 3.88-2.534 7.728-5.107 11.633-7.609 3.946-2.451 7.83-4.979 11.841-7.349"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-right-a"
                x1={352.402}
                x2={405.419}
                y1={220.771}
                y2={237.27}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
