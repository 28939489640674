import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-doctors-coat-both-arms-option-3-right">
        <path
            d="M472.283 286.856a680.916 680.916 0 0 1-4.825-.198c-3.131-.1-6.26-.163-9.39-.226-1.731-.034-3.461-.071-5.191-.11-4.93-.133-9.91-.163-14.581-.183a363.737 363.737 0 0 0-4.045-.02c-1.917 0-3.834.011-5.751.022-1.588.01-3.18.017-4.773.022-9.078.08-17.308.287-25.039.632a430.814 430.814 0 0 0 4.651-20.479 202.246 202.246 0 0 0 3.727-25.438c.213-2.56.409-5.122.604-7.685.446-5.835.907-11.868 1.57-17.775l.016-.15a32.033 32.033 0 0 0-31.854-35.424 31.927 31.927 0 0 0-31.778 28.582c-.635 5.903-1.497 11.891-2.331 17.681-.366 2.545-.732 5.09-1.081 7.634a202.289 202.289 0 0 0-1.891 25.636c-.175 9.119-.091 17.562.257 25.816a253.109 253.109 0 0 0 2.318 26.083c.118.773.286 1.53.476 2.28.012.184.019.366.036.55a23.378 23.378 0 0 0 23.382 21.377c.714 0 1.438-.033 2.153-.097l14.455-1.315 14.467-1.25c8.877-.79 18.336-1.856 28.924-3.26 1.566-.219 3.132-.434 4.699-.644 3.187-.432 6.482-.879 9.717-1.374 4.61-.671 9.52-1.395 14.415-2.224 1.686-.28 3.372-.555 5.059-.826a755.16 755.16 0 0 0 9.347-1.553c1.588-.298 3.178-.586 4.767-.876-1.564-12.065-2.649-22.618-2.51-35.208Z"
            fill="url(#man-34-25-doctors-coat-both-arms-option-3-right-a)"
        />
        <path
            d="M345.623 208.422c-.634 5.903-1.496 11.892-2.329 17.682-.366 2.544-.733 5.09-1.082 7.633a202.289 202.289 0 0 0-1.891 25.636c-.175 9.119-.091 17.562.257 25.816a252.83 252.83 0 0 0 2.318 26.083c.118.773.286 1.53.475 2.28.013.184.02.366.037.55a23.373 23.373 0 0 0 23.382 21.377c.714 0 1.439-.033 2.154-.097l14.455-1.315 14.467-1.25c8.876-.79 18.335-1.856 28.923-3.26 1.566-.219 3.132-.434 4.699-.644 3.187-.432 6.482-.879 9.716-1.374 4.61-.671 9.521-1.395 14.415-2.225 1.687-.279 3.373-.554 5.06-.825a794.66 794.66 0 0 0 9.347-1.552c1.588-.299 3.177-.587 4.767-.876-1.565-12.065-2.65-22.619-2.511-35.208a752.149 752.149 0 0 1-4.825-.198c-3.131-.1-6.259-.163-9.389-.226-1.731-.034-3.462-.071-5.191-.11-4.931-.133-9.91-.163-14.581-.183a363.744 363.744 0 0 0-4.045-.021c-1.917 0-3.834.012-5.751.023-1.589.01-3.18.017-4.774.021-9.077.08-17.307.288-25.038.633a429.613 429.613 0 0 0 4.65-20.479 202.248 202.248 0 0 0 3.728-25.438c.212-2.56.408-5.123.604-7.685.445-5.835.906-11.868 1.57-17.775l.016-.151"
            stroke="#111011"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <defs>
            <linearGradient
                id="man-34-25-doctors-coat-both-arms-option-3-right-a"
                x1={436.092}
                y1={343.109}
                x2={290.358}
                y2={137.519}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#fff" />
                <stop offset={1} stopColor="#E2E2E2" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
