import React from 'react';

const SvgComponent = () => (
    <g>
        <path
            fill="url(#paint0_linear_10977_6504)"
            d="M515.262 424.873l1.056 2.775c6.717-6.112 13.469-16.501 15.588-34.097-4.895 7.091-13.557 11.3-24.61 13.472a44.868 44.868 0 017.966 17.85z"
        ></path>
        <path
            fill="url(#paint1_linear_10977_6504)"
            d="M530.259 463.921l-14.811-38.862c-1.582-12.604-10.554-22.843-19.373-28.843-14.03-10.62-30.504-18.451-47.667-23.846a104.804 104.804 0 00-4.911-1.43 122.133 122.133 0 00-19.133-3.857c-21.11-2.564-42.302-.867-56.821 21.948-6.4 10.058-8.864 22.784-8.36 35.034a69.935 69.935 0 006.754 27.42c9.782 20.291 32.883 27.113 44.199 27.113 0 0-.131-.123-.356-.353.236.004.47.016.706.016h57.2c6.74 20.839 15.509 42.29 26.838 58.524 12.57 17.419 46.55 75.768 53.11 86.774 13.609-.379 34.211-10.789 38.596-17.2-17.735-45.677-37.79-97.195-55.971-142.438z"
        ></path>
        <defs>
            <linearGradient
                id="paint0_linear_10977_6504"
                x1="526.147"
                x2="493.617"
                y1="411.251"
                y2="400.841"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
            <linearGradient
                id="paint1_linear_10977_6504"
                x1="530.37"
                x2="394.998"
                y1="407.161"
                y2="636.252"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F6542F"></stop>
                <stop offset="0.679" stopColor="#F11528"></stop>
                <stop offset="0.9" stopColor="#EF0025"></stop>
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
