import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-both-arms-option-1-cycling-1-right">
        <path
            d="M432.1 249.444a239.004 239.004 0 0 0-16.096-22.844c-1.74-2.19-3.49-4.373-5.248-6.549-3.99-4.959-8.115-10.086-11.999-15.253l-.084-.112a31.773 31.773 0 0 0-25.516-12.658 31.708 31.708 0 0 0-19.319 6.51 32.038 32.038 0 0 0-6.15 44.835c3.905 5.146 7.707 10.515 11.384 15.707 1.618 2.284 3.24 4.564 4.865 6.84a239.1 239.1 0 0 0 17.567 21.728 506.298 506.298 0 0 0 15.578 16.673l46.014-36.697a510.97 510.97 0 0 0-10.996-18.18Z"
            fill="url(#man-34-25-tshirt-3-both-arms-option-1-cycling-1-right-a)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-both-arms-option-1-cycling-1-right-a"
                x1={316.628}
                y1={219.035}
                x2={505.875}
                y2={295.984}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={0.518} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
