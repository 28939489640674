import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-3-arm-left-option-4">
        <path
            d="m465.109 166.844 2.654.951c13.051 4.677 23.788 10.342 32.825 17.319.765.59 5.866 5.433 5.816 6.374-.25 4.649-16.938 8.176-19.334 9.644l-.857.525-21.104-34.813Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-4-a)"
        />
        <path
            d="M520.541 264.182c.449-1.568.95-3.231 1.488-4.936.335-1.067.666-2.135.994-3.204.67-2.173 1.361-4.42 2.098-6.623 1.014-3.133 2.105-6.477 3.297-9.79.406-1.147.809-2.298 1.21-3.453.462-1.328.939-2.652 1.411-3.978-3.295-5.871-6.11-10.848-6.875-12.121a946.953 946.953 0 0 0-4.152-6.896c-3.158-5.219-6.423-10.615-9.445-16.021l-.072-.129a32.003 32.003 0 0 0-43.605-12.111 31.998 31.998 0 0 0-12.111 43.604c3.048 5.392 5.963 10.989 8.781 16.401a1995.05 1995.05 0 0 0 3.729 7.122 219.8 219.8 0 0 0 13.928 22.894c.722 1.057 1.44 2.101 2.154 3.131l37.06-13.512.11-.378Z"
            fill="url(#man-34-25-tshirt-3-arm-left-option-4-b)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-4-a"
                x1={465.109}
                y1={184.25}
                x2={506.404}
                y2={184.25}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
            <linearGradient
                id="man-34-25-tshirt-3-arm-left-option-4-b"
                x1={450.623}
                y1={229.422}
                x2={569.444}
                y2={229.422}
                gradientUnits="userSpaceOnUse"
            >
                <stop offset={0.001} stopColor="#888B8D" />
                <stop offset={1} stopColor="#ABADAD" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
