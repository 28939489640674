import React from 'react';

const SvgComponent = () => (
    <g id="man-34-25-tshirt-2-both-arms-option-2-right">
        <path
            d="M406.199 182.333c-2.56-.22-5.122-.423-7.684-.626-5.833-.463-11.864-.941-17.769-1.623l-.151-.017a32.663 32.663 0 0 0-3.552-.196 31.944 31.944 0 0 0-31.769 28.488 31.782 31.782 0 0 0 1.06 12.444 31.776 31.776 0 0 0 5.77 11.076 31.798 31.798 0 0 0 21.462 11.802c5.903.651 11.89 1.531 17.68 2.381 2.542.374 5.084.748 7.626 1.104 1.032.147 2.087.262 3.132.394l4.673-65.178c-.158-.014-.32-.035-.478-.049Z"
            fill="url(#man-34-25-tshirt-2-both-arms-option-2-righta)"
        />
        <defs>
            <linearGradient
                id="man-34-25-tshirt-2-both-arms-option-2-righta"
                x1={409.142}
                y1={215.606}
                x2={349.286}
                y2={207.799}
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#0047A7" />
                <stop offset={0.971} stopColor="#001489" />
            </linearGradient>
        </defs>
    </g>
);

export default SvgComponent;
